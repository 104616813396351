import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
* @author: Mario Alejandro Sepúlveda Rojas
*/
/**
 * 
**/
class CustomAutorizarConceptosAjustesInventario extends FormJaivana.form {


    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);
        this.arrayCampos = new Map();
        this.arrayObjetos = new Map();
        this.consultarTabla = this.consultarTabla.bind(this);
        this.successConsultarTabla = this.successConsultarTabla.bind(this);
        this.gridOptionsTabla = Object.assign({},this.gridOptions);
        this.setButtonAccion = this.setButtonAccion.bind(this);
        this.confirmEliminar = this.confirmEliminar.bind(this);
        this.validarEliminar = this.validarEliminar.bind(this);
        this.successEliminar = this.successEliminar.bind(this);
        this.validCrearRegis = this.validCrearRegis.bind(this);
        this.successValidar = this.successValidar.bind(this);
        this.successCrear = this.successCrear.bind(this);
        this.habilitarCamposAgregar = this.habilitarCamposAgregar.bind(this);
        this.ocultarCampos = this.ocultarCampos.bind(this);
    }

    initForm() {
        console.log("Formulario CustomAutorizarConceptosAjustesInventario,  @version: jdesk_1.01.0001, @author: Mario Alejandro Sepúlveda Rojas");

        this.arrayCampos.set(1,'autorizarconceptos_id');
        this.arrayCampos.set(2,'rejilla');
        this.arrayCampos.set(3,'btn_agregar');
        this.arrayCampos.set(4,'usuario_cod');
        this.arrayCampos.set(5,'nombre_u');
        this.arrayCampos.set(6,'concepto_cod');
        this.arrayCampos.set(7,'nombre_c');
        this.arrayCampos.set(8,'aceptar');
        this.arrayCampos.set(9,'cancelar');
        this.arrayCampos.set(10,'usuarios_id');
        this.arrayCampos.set(11,'conceptos_ajuste_id');

        this.arrayObjetos.set(1,'autorizarconceptos_id');
        this.arrayObjetos.set(2,'rejilla');
        this.arrayObjetos.set(3,'btn_agregar');
        this.arrayObjetos.set(4,'usuario_cod');
        this.arrayObjetos.set(5,'nombre_u');
        this.arrayObjetos.set(6,'concepto_cod');
        this.arrayObjetos.set(7,'nombre_c');
        this.arrayObjetos.set(8,'aceptar');
        this.arrayObjetos.set(9,'cancelar');
        this.arrayObjetos.set(10,'usuarios_id');
        this.arrayObjetos.set(11,'conceptos_ajuste_id');

        this.consultarTabla();
        this.getField("btn_agregar").setClick(this.habilitarCamposAgregar);
        this.getField(this.arrayCampos.get(9)).setClick(this.ocultarCampos);
        this.getField(this.arrayCampos.get(8)).setClick(()=>{
            this.validCrearRegis();
        });
    }

    consultarTabla() {
        let datos = { 
            datos: {} 
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-autconceptajustinvetario', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successConsultarTabla,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successConsultarTabla(data){

        this.establecerPropiedades('3','visible','true');

        if(data.estado_p === 200){
            this.gridOptionsTabla['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('accion',{cellRenderer:this.setButtonAccion,field:'accion'});
            this.getField(this.arrayCampos.get(2)).initData(this.gridOptionsTabla,configCell);

        } else if(data.estado_p === 404){
            this.getField(this.arrayCampos.get(2)).toggle(false);
            this.habilitarCamposAgregar();
        }
    }

    setButtonAccion(props) {
        let fragment = document.createDocumentFragment();
        let id = props.data.id;
        let button1 = document.createElement("input");

        button1.onclick = () => this.confirmEliminar(props);
        button1.setAttribute("id", 'button_delete_' + id);
        //button1.setAttribute("class", "buttonStyle");
        button1.setAttribute("type", "button");
        //button.classList.add(hover);
        button1.setAttribute("value", "Eliminar");
        button1.setAttribute("class","buttonStyle2");

        fragment.appendChild(button1);
        return this.createElementJaivana(fragment);
    }

    confirmEliminar(props){

        let idEliminar = props.data.id;//this.getField(this.arrayCampos.get(1)).getValue();

        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(()=>{
            this.validarEliminar(idEliminar);
        });
        this.getField('confirmModalCustom').toggle(true);
    }

    validarEliminar(idEliminar){
        this.getField('confirmModalCustom').toggle(false);

        let datos={ datos: {
            autorizarconceptos_id:idEliminar
        } };

        this.generalFormatPmv = { tipo_servicio: 'maes-autconceptajustinvetario', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage:false
        });
    }

    successEliminar(data){
        if(data.estado_p === 200){
            this.consultarTabla();
            this.ocultarCampos();
        }
    }

    validCrearRegis(){
        let id_usuario = this.getField(this.arrayCampos.get(10)).getValue();
        let id_concepto = this.getField(this.arrayCampos.get(11)).getValue();

        if(id_usuario !== '' && id_usuario !== ' ' && id_usuario !== undefined && id_usuario !== null && id_concepto !== '' && id_concepto !== ' ' && id_concepto !== undefined && id_concepto !== null){
            this.establecerPropiedades('8','disable','true');

        let datos = { 
            datos: {
                usuario_cod:this.getField(this.arrayCampos.get(4)).getValue(),
                concepto_cod:this.getField(this.arrayCampos.get(6)).getValue(),
            } 
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-autconceptajustinvetario', operacion: '20_1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successValidar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false

            });
        }else {
            if(id_usuario === '' ){//|| id_usuario === ' ' || id_usuario === undefined || id_usuario === null
                this.getField(this.arrayCampos.get(5)).setError(true,'* Este campo es requerido');
            }else if(id_concepto === '' ){//|| id_concepto === ' ' || id_concepto === undefined || id_concepto === null

                this.getField(this.arrayCampos.get(7)).setError(true,'* Este campo es requerido');
            }
        }
    }

    successValidar(data){
        if(data.estado_p === 200 && data.data[0].cuantos === 0){
            //console.log("puede crear");
            let id_usuario = this.getField(this.arrayCampos.get(10)).getValue();
            let id_concepto = this.getField(this.arrayCampos.get(11)).getValue();

            if(id_usuario !== '' && id_usuario !== ' ' && id_usuario !== undefined && id_usuario !== null && id_concepto !== '' && id_concepto !== ' ' && id_concepto !== undefined && id_concepto !== null){
                let datos = { 
                    datos: {
                        usuarios_id:this.getField(this.arrayCampos.get(10)).getValue(),
                        conceptos_ajuste_id:this.getField(this.arrayCampos.get(11)).getValue(),
                        usuario_cod:this.getField(this.arrayCampos.get(4)).getValue(),
                        concepto_cod:this.getField(this.arrayCampos.get(6)).getValue(),
                    } 
                };
                this.generalFormatPmv = { tipo_servicio: 'maes-autconceptajustinvetario', operacion: '5', operacion_tipo: 'consulta' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'POST',
                        body: datos,
                        success: this.successCrear,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage:false
        
                    });
            }
        } else {
            if(data.data[0].cuantos >= 1){
                this.establecerPropiedades('8','disable','false');
                this.alertGeneral.toggle(true, '* La relación de los campos código_usuario y código_concepto ya existe, No se permite duplicar','error');
            }
        }
    }

    successCrear(data){
        this.establecerPropiedades('8','disable','false');
        if(data.estado_p === 200){
            this.ocultarCampos();
            this.consultarTabla();
        }   
    }

    habilitarCamposAgregar() {
        this.establecerPropiedades('4,5,6,7,8,9','visible','true');
        //this.getField("usuario_cod").setVisible(true);
    }



    ocultarCampos(){
        this.establecerPropiedades('4,5,6,7,8,9','visible','false');
        this.establecerPropiedades('4,5,6,7,10,11','value','');
        this.establecerPropiedades('4,5,6,7','error',false);
    }

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            } else if(sPropiedad === 'error'){
                if(sValor === false){
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'')
                }
            }
        });
    }

    cargarCampos(data) {
        this.getField('autorizarconceptos_id').setValue(data.data[0].autorizarconceptos_id);
        this.getField('rejilla').setValue(data.data[0].rejilla);
        this.getField('btn_agregar').setValue(data.data[0].btn_agregar);
        this.getField('usuario_cod').setValue(data.data[0].usuario_cod);
        this.getField('nombre_u').setValue(data.data[0].nombre_u);
        this.getField('concepto_cod').setValue(data.data[0].concepto_cod);
        this.getField('nombre_c').setValue(data.data[0].nombre_c);
        this.getField('aceptar').setValue(data.data[0].aceptar);
        this.getField('cancelar').setValue(data.data[0].cancelar);
        this.getField('usuarios_id').setValue(data.data[0].usuarios_id);//
        this.getField('conceptos_ajuste_id').setValue(data.data[0].conceptos_ajuste_id);
        

        
    }

}
FormJaivana.addController('maes-autconceptajustinvetario', CustomAutorizarConceptosAjustesInventario);
export default CustomAutorizarConceptosAjustesInventario;