import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomDocumentosVendedores
 * @author: Cristian Ciro
 * @version: jdesk_1.01.0002
 **/
class CustomDocumentosVendedores extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);

        this.onClickSave = this.handlerClickSave.bind(this);

    }

    initForm() {
        console.log('Formulario CustomDocumentosVendedores,  @version: jdesk_1.01.0002, @author:Cristian Ciro');

        this.getField("nombre_cuenta_comercial").setOnChange(() => {
/*             if (this.getField("cuentas2_id").getValue() !== "") {
                if (this.getField("cuentas2_id").getValue() === this.getField("cuentas1_id").getValue()) {
                    this.getField("cuenta_comercial").setError(true, "Esta cuenta debe ser distinta a la cuenta administrativa.");
                } else {
                    this.getField("cuenta_comercial").setError(false, "");
                    this.getField("cuenta_administrativa").setError(false, "");
                }
            } */
            if (this.getField("nombre_cuenta_comercial").getValue() === "") {
                this.getField("cuenta_comercial").setError(false, "");
                this.getField("cuenta_administrativa").setError(false, "");
            }else{
                this.getField("nombre_cuenta_comercial").setError(false,"");
            }
        });

        this.getField("nombre_cuenta_administrativa").setOnChange(() => {
/*             if (this.getField("cuentas1_id").getValue() !== "") {
                if (this.getField("cuentas2_id").getValue() === this.getField("cuentas1_id").getValue()) {
                    this.getField("cuenta_administrativa").setError(true, "Esta cuenta debe ser distinta a la cuenta comercial.");
                } else {
                    this.getField("cuenta_administrativa").setError(false, "");
                    this.getField("cuenta_comercial").setError(false, "");
                }
            } */
            if (this.getField("nombre_cuenta_administrativa").getValue() === "") {
                this.getField("cuenta_administrativa").setError(false, "");
                this.getField("cuenta_comercial").setError(false, "");
            }else{
                this.getField("nombre_cuenta_administrativa").setError(false,"");
            }
        });

    }

    handlerClickSave() {
        
        this.dataForm.form.campos.forEach(element => {
            this.getField(element.nombre_campo_servicio).valid();
        });
        
            
        let field_valid = true;
        for (let campo in this.dataForm.form.campos) {
            if (!this.getField(this.dataForm.form.campos[campo].nombre_campo_servicio).valid()) {
                field_valid = false;
            }
        }
/*         if (this.getField("nombre_cuenta_administrativa").valid() && this.getField("nombre_cuenta_administrativa").valid() &&
            this.getField("cuentas1_id").getValue() === this.getField("cuentas2_id").getValue()) {
                this.getField("cuenta_administrativa").setError(true,"Esta cuenta debe ser distinta a la cuenta comercial.");
                field_valid = false;
        } */

        if (field_valid) {
            this.saveData();
        }
    }

}
FormJaivana.addController("maes-vendedorestiposdocumentos", CustomDocumentosVendedores);
export default CustomDocumentosVendedores