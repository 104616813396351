import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @description: CustomTransportadorasPoblaciones
 * @author: Mario A. Sepúlveda R.
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomTransportadorasPoblaciones extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm = this.initForm.bind(this);
       this.arrayObjetos=new Map();
       this.arrayCampos=new Map();
       this.bHayErrores=false;
       this.gridOptionsPoblaciones = Object.assign({},this.gridOptions);

       this.consultarTabla = this.consultarTabla.bind(this);
       this.succesTraerTabla = this.succesTraerTabla.bind(this);
       this.mostrarOpciones = this.mostrarOpciones.bind(this);
       this.ocultarCampos = this.ocultarCampos.bind(this);
       this.validarCodigoUnico = this.validarCodigoUnico.bind(this);
       this.successValidarCodigoUnico = this.successValidarCodigoUnico.bind(this);
       this.successCrear = this.successCrear.bind(this);
       this.validarPoblacionTransportadora= this.validarPoblacionTransportadora.bind(this);
       this.successPoblacionTransportadora= this.successPoblacionTransportadora.bind(this);

    }

    initForm() {

       console.log('Formulario CustomTransportadorasPoblaciones,  @version: jdesk_1.01.0001, @author:Mario A. Sepúlveda R. @updateBy Jose Albeiro Marin')


       this.arrayCampos.set(1,'id');
       this.arrayCampos.set(2,'nit');
       this.arrayCampos.set(3,'nombre_transportadora');
       this.arrayCampos.set(4,'seleccionar');
       this.arrayCampos.set(5,'rejilla');
       this.arrayCampos.set(6,'agregar');
       this.arrayCampos.set(7,'poblaciones');
       this.arrayCampos.set(8,'nombre');
       this.arrayCampos.set(9,'codigo_agregar');
       this.arrayCampos.set(10,'aceptar');
       this.arrayCampos.set(11,'cancelar');
       this.arrayCampos.set(12,'transportadoras_id');
       this.arrayCampos.set(13,'ciudades_id');
       this.arrayCampos.set(14,'poblaciones_id');


       this.arrayObjetos.set(1,'id');
       this.arrayObjetos.set(2,'nit');
       this.arrayObjetos.set(3,'nombre_transportadora');
       this.arrayObjetos.set(4,'seleccionar');
       this.arrayObjetos.set(5,'rejilla');
       this.arrayObjetos.set(6,'agregar');
       this.arrayObjetos.set(7,'poblaciones');
       this.arrayObjetos.set(8,'nombre');
       this.arrayObjetos.set(9,'codigo_agregar');
       this.arrayObjetos.set(10,'aceptar');
       this.arrayObjetos.set(11,'cancelar');
       this.arrayObjetos.set(12,'transportadoras_id');
       this.arrayObjetos.set(13,'ciudades_id');
       this.arrayObjetos.set(14,'poblaciones_id');

       this.getField(this.arrayCampos.get(4)).setClick(this.consultarTabla);
       this.getField(this.arrayCampos.get(3)).setOnChange(()=>{
            this.getField(this.arrayCampos.get(5)).toggle(false);
            this.establecerPropiedades('4','disable','false');
            this.establecerPropiedades('6,7,8,9,10,11','visible','false');
       });
       this.getField('agregar').setClick(this.mostrarOpciones);
        
       this.getField('cancelar').setClick(this.ocultarCampos);

       this.getField('aceptar').setClick(this.validarCodigoUnico);
    }


    consultarTabla(){
        //console.log("entro a consultar tabla");
        this.ocultarCampos();
        let transportadoras_id = this.getField(this.arrayCampos.get(12)).getValue();

        if(transportadoras_id !== '' && transportadoras_id !== ' ' && transportadoras_id !== null ){
            // this.establecerPropiedades('4','disable','true');
            this.getField('nombre_transportadora').setError(false,'');

            let datos={ datos: {
                transportadoras_id:this.getField(this.arrayCampos.get(12)).getValue()
            } };


            this.generalFormatPmv = { tipo_servicio: 'maes-transportapoblaciones', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesTraerTabla,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage:false
            });
        } else {
            this.getField('nombre_transportadora').setError(true,'* Este campo este campo es requerido');
        }
    }

    succesTraerTabla(data){
        if(data.estado_p === 200){
            //console.log("respuesta del servicio:",data);

            this.establecerPropiedades('4','disable','true');

            this.getField(this.arrayCampos.get(5)).toggle(true);      
            this.gridOptionsPoblaciones['rowData'] = data.data;
            this.getField(this.arrayCampos.get(5)).initData(this.gridOptionsPoblaciones);
            this.establecerPropiedades('6','visible','true');

        } else {
            if(data.estado_p===404) {
                this.getField(this.arrayCampos.get(5)).toggle(false);
                this.establecerPropiedades('4','disable','true');
                this.establecerPropiedades('6','visible','true');
               }
               else {
                 let respuesta=Object.values(data.data.errores);
                 let keys=Object.keys(data.data.errores);
                 this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    mostrarOpciones(){
        this.establecerPropiedades('7,8,9,10,11','visible','true');
    }



    ocultarCampos(){
        this.establecerPropiedades('7,8,9,10,11','visible','false');
        this.establecerPropiedades('7,8,9,13,14','value','');
        this.getField(this.arrayCampos.get(7)).setError(false,'');
        this.getField(this.arrayCampos.get(8)).setError(false,'');
        this.getField(this.arrayCampos.get(9)).setError(false,'');
    }


    validarCodigoUnico(){

        let codigo_agregar = this.getField(this.arrayCampos.get(9)).getValue();
        let poblaciones_id = this.getField(this.arrayCampos.get(14)).getValue();

        if(poblaciones_id === '' || poblaciones_id === undefined || poblaciones_id === null){
            this.getField(this.arrayCampos.get(8)).setError(true,"* Este campo es requeirdo");
        } else if(codigo_agregar === '' || codigo_agregar === undefined || codigo_agregar === null) {
            this.getField(this.arrayCampos.get(9)).setError(true,"* Este campo es requeirdo");
        }else {
            this.getField(this.arrayCampos.get(8)).setError(false,'');
            this.getField(this.arrayCampos.get(9)).setError(false,'');
            if(codigo_agregar !== '' && codigo_agregar !== ' ' && codigo_agregar !== undefined && codigo_agregar !== null){
                this.getField('aceptar').setDisabled(true);
                let datos={ datos: {
                    codigo_agregar:this.getField(this.arrayCampos.get(9)).getValue()
                } };
    
    
                this.generalFormatPmv = { tipo_servicio: 'maes-transportapoblaciones', operacion: '20', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successValidarCodigoUnico,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage:false
                });
            }
        }
    }

    successValidarCodigoUnico(data){
        if(data.estado_p === 200){
            this.getField('aceptar').setDisabled(false);
            this.alertGeneral.toggle(true,'El campo Código ya existen, no se permite duplicar',"error");
            
        }else if(data.estado_p === 404){
            this.getField('aceptar').setDisabled(false);
            this.validarPoblacionTransportadora();
        }
    }

    validarPoblacionTransportadora(){
                let datos={ datos: {
                    transportadoras_id:this.getField(this.arrayCampos.get(12)).getValue(),
                    poblaciones_id:this.getField(this.arrayCampos.get(14)).getValue()

                } };
    
    
                this.generalFormatPmv = { tipo_servicio: 'maes-transportapoblaciones', operacion: '21', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successPoblacionTransportadora,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage:false
            });
    }

    successPoblacionTransportadora(data){
        if(data.estado_p === 200){
        this.alertGeneral.toggle(true,'La relación de Nit y Población ya existe, no se permite duplicar',"error");

        }else if(data.estado_p === 404){
            this.crearTransportadora()
        }
    }
    crearTransportadora(){
        let poblaciones_id = this.getField(this.arrayCampos.get(14)).getValue();
        let transportadoras_id = this.getField(this.arrayCampos.get(12)).getValue();

        if(poblaciones_id !== '' && poblaciones_id !== ' ' && poblaciones_id !== undefined && poblaciones_id !== null &&
        transportadoras_id !=='' && transportadoras_id !== ' ' && transportadoras_id !== undefined && transportadoras_id !== null){

            let datos={ datos: {
                codigo_agregar:this.getField(this.arrayCampos.get(9)).getValue(),
                transportadoras_id:this.getField(this.arrayCampos.get(12)).getValue(),
                poblaciones_id:this.getField(this.arrayCampos.get(14)).getValue()
            } };


            this.generalFormatPmv = { tipo_servicio: 'maes-transportapoblaciones', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrear,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage:false
            });

            //console.log("datos del crear",datos);

        }else {
            this.getField('aceptar').setDisabled(false);
        }
    }
    

    successCrear(data){
        //console.log("entro a  success crear:",data);
        if(data.estado_p === 200){
            this.consultarTabla();
        }
    }

   //*** fin  los botones y los success



   establecerPropiedades(sObjetos,sPropiedad,sValor) 
   {
      const array = sObjetos.split(',');
      array.forEach((element) => 
      {
               //console.log(element);
               //console.log(this.arrayObjetos.get(parseInt(element)));
               if(sPropiedad==='disable') 
               { 
                   if(sValor==='true') { 
                       this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                   }
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                   }   
               }
               else if(sPropiedad==='visible') 
               { 
                   if(sValor==='true') { 
                       this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                   }
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                   }   
               }
               else if(sPropiedad==='value')
               { 
                   this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
               }  
               else if(sPropiedad==='valid')
               { 
                   if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                      //ok
                   }
                   else {
                       this.bHayErrores=true;
                   }
               }
               else if(sPropiedad==='foco')
               { 
                   if(sValor==='rejilla') { } 
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                   }
               }
     });     
  }


  cargarCampos(data) 
  {


    this.getField('id').setValue(data.data[0].id);
    this.getField('nit').setValue(data.data[0].nit);
    this.getField('nombre_transportadora').setValue(data.data[0].nombre);
    this.getField('seleccionar').setValue(data.data[0].seleccionar);
    this.getField('rejilla').setValue(data.data[0].rejilla);
    this.getField('agregar').setValue(data.data[0].agregar);
    this.getField('poblaciones').setValue(data.data[0].poblaciones);
    this.getField('nombre').setValue(data.data[0].nombre);
    this.getField('codigo_agregar').setValue(data.data[0].codigo_agregar);
    this.getField('aceptar').setValue(data.data[0].aceptar);
    this.getField('cancelar').setValue(data.data[0].cancelar);
    this.getField('transportadoras_id').setValue(data.data[0].transportadoras_id);
    this.getField('ciudades_id').setValue(data.data[0].ciudades_id);
    this.getField('poblaciones_id').setValue(data.data[0].poblaciones_id);

  }

}
FormJaivana.addController('maes-transportapoblaciones',CustomTransportadorasPoblaciones);
export default CustomTransportadorasPoblaciones;
