import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomHojasDeVida
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomHojasDeVida extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.generarPdfTabla3   =   this.generarPdfTabla3.bind(this);
    this.llenarTabla   =   this.llenarTabla.bind(this);
    this.sucessLlenarTabla   =   this.sucessLlenarTabla.bind(this);
    this.setButtonAccion   =   this.setButtonAccion.bind(this);
    this.mostrarModal   =   this.mostrarModal.bind(this);
    this.validarModal   =   this.validarModal.bind(this);
    this.todo=false;
    this.id_activo='';
    this.enviarCorreo = this.enviarCorreo.bind(this);
    this.successEnviarCorreo = this.successEnviarCorreo.bind(this);
    
  }

  initForm() {
    console.log("Formulario CustomHojasDeVida,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez, @updateBy Jose Albeiro Marin");
    this.llenarTabla();
    this.getField('bt_todo').setClick(()=>{this.todo=true;this.validarModal()});
    this.getField('bt_aceptar').setClick(()=>{this.todo=false;this.validarModal()});
    this.getField('refrescar').setClick(this.llenarTabla)
  }

  validarModal(){
    if(this.getField('email').valid()){
      if(this.getField('ch_asignaciones').getValue() ==='true' ||
        this.getField('ch_seriales').getValue()==='true' ||
        this.getField('ch_polizas').getValue()==='true' ||
        this.getField('ch_garantias').getValue()==='true' ||
        this.getField('ch_datos_basicos').getValue()==='true' ||
        this.getField('ch_datos_compra').getValue()==='true' ||
        this.getField('ch_imagenes_basicas').getValue()==='true' || this.todo===true)
      {
        this.enviarCorreo(this.id_activo);
      }else{
        this.alertGeneral.toggle(true,'Es necesario seleccionar almenos una opción...','error');
      }
    }
  }
  llenarTabla(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'maes-hojasdevida', operacion: '1', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessLlenarTabla,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  sucessLlenarTabla(data){
    if (data.estado_p === 200 )
    {
      let configCell = new Map();
      configCell.set('codigo',{cellStyle: {field:"código"}}); 
      configCell.set('accion', { cellRenderer: this.setButtonAccion, width: 80, sortable: false, filter: false, field: 'acción' });
      configCell.set('codigo', { headerName: 'Código'});
      configCell.set('descripcion', { headerName: 'Descripción'});

      this.getField('tb_activos_fijos').toggle(true)
      this.gridOptionsComponentes['rowData'] = data.data;
      this.getField('tb_activos_fijos').initData(this.gridOptionsComponentes, configCell);
    }
    else 
    {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'No se encontro.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }  
  }

  setButtonAccion(props) {
    let id = props.data.activosfijos_id;
    let button = document.createElement("input");
    button.onclick = () => this.mostrarModal(id);
    
    button.setAttribute("id", 'button_delete_' + id);
    button.setAttribute("class", "buttonStyle");
    button.setAttribute("type", "button");
    button.setAttribute("value", "Generar");
    return this.createElementJaivana(button);
}

  mostrarModal(id){
    this.getField('ch_asignaciones').setValueSwitch (false);
    this.getField('ch_seriales').setValueSwitch (false);
    this.getField('ch_polizas').setValueSwitch (false);
    this.getField('ch_garantias').setValueSwitch (false);
    this.getField('ch_datos_basicos').setValueSwitch (false);
    this.getField('ch_datos_compra').setValueSwitch (false);
    this.getField('ch_imagenes_basicas').setValueSwitch (false);
    this.getField('modal_hojas').handleClickOpen();
    this.id_activo=id;

  }

  generarPdfTabla3(id)
    {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos2={}
        if(this.todo===true){
          datos2={
            activosfijos_id:id,
            ch_asignaciones:true,
            ch_seriales:true,
            ch_polizas:true,
            ch_garantias:true,
            ch_datos_basicos:true,
            ch_datos_compra:true,
            ch_imagenes_basicas:true
           }
        }else{
          datos2={
            activosfijos_id:id,
            ch_asignaciones:this.getField('ch_asignaciones').getValue(),
            ch_seriales:this.getField('ch_seriales').getValue(),
            ch_polizas:this.getField('ch_polizas').getValue(),
            ch_garantias:this.getField('ch_garantias').getValue(),
            ch_datos_basicos:this.getField('ch_datos_basicos').getValue(),
            ch_datos_compra:this.getField('ch_datos_compra').getValue(),
            ch_imagenes_basicas:this.getField('ch_imagenes_basicas').getValue()
           }
        }
         let url = this.constant.formConfiguration();
         const myJSON = JSON.stringify(datos2);
         let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"maes-hojasdevida","operacion":"generarpdf","operacion_tipo":"consulta"}}';
         fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                  "Authorization": 'Bearer '+localStorage.getItem('token'), 
                  "Content-Type":'application/json'})})
         .then(response => {
          this.getField('confirmModalCustom').toggle(false);
         if(response.status!==200){
            return '';
         }
         const reader = response.body.getReader();
         return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                  return reader.read().then(({ done, value }) => {
                  if (done) {
                     controller.close();
                     return;}
                  controller.enqueue(value);
                  return pump();});}}})})
         .then(stream => new Response(stream))
         .then(response => response.blob())
         .then(blob => {
            if(blob.size>0){
              const pdf = new File([blob], 'name.pdf', {
                    type:'application/pdf'});
              window.open(URL.createObjectURL(pdf));
              this.getField('confirmModalCustom').toggle(false);
              this.getField('modal_hojas').handleClose();
            }else{
               this.alertGeneral.toggle(true,'No hay datos','error');
               this.getField('confirmModalCustom').toggle(false);
               this.getField('modal_hojas').handleClose();
            }},err=>{
               this.alertGeneral.toggle(true,'Error inesperado','error');
               this.getField('confirmModalCustom').toggle(false);
               this.getField('modal_hojas').handleClose();
            });
    }

    enviarCorreo(id){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información a enviar por correo... `);
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos = {}
      if(this.todo===true){
        datos = { datos: {
            activosfijos_id:id,
            ch_asignaciones:true,
            ch_seriales:true,
            ch_polizas:true,
            ch_garantias:true,
            ch_datos_basicos:true,
            ch_datos_compra:true,
            ch_imagenes_basicas:true,
            email:this.getField('email').getValue()
          }};
      }else{
        datos = { datos: {
            activosfijos_id:id,
            ch_asignaciones:this.getField('ch_asignaciones').getValue(),
            ch_seriales:this.getField('ch_seriales').getValue(),
            ch_polizas:this.getField('ch_polizas').getValue(),
            ch_garantias:this.getField('ch_garantias').getValue(),
            ch_datos_basicos:this.getField('ch_datos_basicos').getValue(),
            ch_datos_compra:this.getField('ch_datos_compra').getValue(),
            ch_imagenes_basicas:this.getField('ch_imagenes_basicas').getValue(),
            email:this.getField('email').getValue()
          }}
      }

      this.generalFormatPmv = { tipo_servicio: 'maes-hojasdevida', operacion: 'generarpdf', operacion_tipo: 'consulta' };
      this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv
        });
        this.service.hideProgress();
    }
  
    successEnviarCorreo(data){
       this.getField('confirmModalCustom').toggle(false);
       if(data.estado_p === 200){ 
          this.getField('modal_hojas').handleClose();
       }else{
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
       }
   }
   
}

FormJaivana.addController("maes-hojasdevida", CustomHojasDeVida);
export default CustomHojasDeVida;