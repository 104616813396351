import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomInventarioBalance
 * @author: Santiago Hernández Neira
 * @version: jdesk_1.01.0001.1
 **/

class CustomInventarioBalance extends FormJaivana.form {

    constructor(props) {
        super(props);

        this.initForm                                                           = this.initForm.bind(this);
        this.arrayObjetos                                                       = new Map();
        this.arrayCampos                                                        = new Map();
        this.opcionGeneral                                                      = this.opcionGeneral.bind(this); 
        this.validarCampos                                                      = this.validarCampos.bind(this);
        this.procesarGeneral                                                    = this.procesarGeneral.bind(this);
        this.successProcesarGeneral                                             = this.successProcesarGeneral.bind(this);
        this.codigo_sucursal                                                    = '';
        this.formatovista                                                       = this.formatovista.bind(this);
        this.operacion_actual                                                   = '';
        this.deshabilitarCorreo                                                 = this.deshabilitarCorreo.bind(this);
        this.imprimirBalance                                                    = this.imprimirBalance.bind(this);
        this.genearModalNormal                                                  = this.genearModalNormal.bind(this);
        this.sucessTraerDatosNormales                                           = this.sucessTraerDatosNormales.bind(this);
        this.gridOptionsComponentesBalanceNormal                                = Object.assign({},this.gridOptions);
        this.traerSaldoTotal                                                    = this.traerSaldoTotal.bind(this);
        this.sucessTotalSaldo                                                   = this.sucessTotalSaldo.bind(this);
        this.currencyFormattersaldofinal                                        = this.currencyFormattersaldofinal.bind(this);
        this.formatNumberSaldo                                                  = this.formatNumberSaldo.bind(this);
        this.limpiarTemporal                                                    = this.limpiarTemporal.bind(this);
        this.successDropTmp                                                     = this.successDropTmp.bind(this);
        this.generarPdfTabla                                                    = this.generarPdfTabla.bind(this);
        this.enviarCorreo                                                       = this.enviarCorreo.bind(this);
        this.successEnviarCorreo                                                = this.successEnviarCorreo.bind(this);
        this.generarExcelTabla                                                  = this.generarExcelTabla.bind(this);
        this.cancelar                                                           = this.cancelar.bind(this);
        this.opcionSoloSaldos                                                   = this.opcionSoloSaldos.bind(this);
        this.cancelarSoloSaldos                                                 = this.cancelarSoloSaldos.bind(this);
        this.limpiarTemporalSoloSaldos                                          = this.limpiarTemporalSoloSaldos.bind(this);
        this.successDropTmpSoloSaldos                                           = this.successDropTmpSoloSaldos.bind(this);


       

    }

    initForm(){
        console.log('Formulario CustomInventarioBalance,  @version: jdesk_1.01.0001.1, @author:Santiago Hernández N.');
        
        this.arrayCampos.set(1,'id');
        this.arrayCampos.set(2,'label2');
        this.arrayCampos.set(3,'operacion');
        this.arrayCampos.set(4,'label3');
        this.arrayCampos.set(5,'fecha');
        this.arrayCampos.set(6,'ch_niif');
        this.arrayCampos.set(7,'ch_pantalla');
        this.arrayCampos.set(8,'ch_pdf');
        this.arrayCampos.set(9,'ch_excel');
        this.arrayCampos.set(10,'radio_correo');
        this.arrayCampos.set(11,'email');
        this.arrayCampos.set(12,'bt_consultar');
        this.arrayCampos.set(13,'label4');
        this.arrayCampos.set(14,'bt_cancelar');
        




        
        /*this.arrayCampos.set(8,'codigo_sucursal');
        this.arrayCampos.set(9,'nombre_sucursal');
        this.arrayCampos.set(10,'ch_sucursales');*/
        
        
        this.arrayObjetos.set(1,'id');
        this.arrayObjetos.set(2,'label2');
        this.arrayObjetos.set(3,'operacion');
        this.arrayObjetos.set(4,'label3');
        this.arrayObjetos.set(5,'fecha');
        this.arrayObjetos.set(6,'ch_niif');
        this.arrayObjetos.set(7,'ch_pantalla');
        this.arrayObjetos.set(8,'ch_pdf');
        this.arrayObjetos.set(9,'ch_excel');
        this.arrayObjetos.set(10,'radio_correo');
        this.arrayObjetos.set(11,'email');
        this.arrayObjetos.set(12,'bt_consultar');
        this.arrayObjetos.set(13,'label4');
        this.arrayObjetos.set(14,'bt_cancelar');




        
        /*this.arrayObjetos.set(8,'codigo_sucursal');
        this.arrayObjetos.set(9,'nombre_sucursal');
        this.arrayObjetos.set(10,'ch_sucursales');*/



        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionGeneral();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionSoloSaldos();
        }

 
    }







    /////////////////////////////////////////////////////////// GENERAL //////////////////////////////////////////////
    
    opcionGeneral(){
       
        this.establecerPropiedades('2,3,4,5,6,7,8,9,10,11,12,13,14','visible','true');
        this.getField('operacion').setValue('INVENTARIO Y BALANCE GENERAL');
        this.getField('radio_correo').setValue("N");
        this.getField('radio_correo').setDisabled(false);
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.getField('ch_niif').setValueSwitch (false);
        this.getField('email').setValue("");
        this.formatovista();
        this.getField('bt_consultar').setClick(()=>{this.validarCampos()});
        let fechaActual = new Date();
        let cambioFormato = Date.parse(fechaActual);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fecha').setValue(campoFecha.split('T')[0]);
        
        this.getField('modal_balances').setCloseButton(()=>{           
            this.getField('confirmModalCustom').toggle(false);
                this.getField('bt_consultar').setDisabled(false);
                this.limpiarTemporal();
        });


        this.getField('bt_cancelar').setClick(()=>{this.cancelar()});


    }


    formatovista(){
        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
              this.getField('ch_pdf').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='excel';
              this.getField('radio_correo').setDisabled(false);
            }
        });
        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.getField('radio_correo').setDisabled(false);
            }
        });
        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pdf').setValueSwitch (false);
              this.operacion_actual='pantalla';
              this.deshabilitarCorreo();
            }else{
              this.getField('radio_correo').setDisabled(false);
            }
        });

        this.getField('radio_correo').setOnChange(()=>{
            if(this.getField("radio_correo").getValue()==='S'){
              this.getField('email').setDisabled(false);
            }else{
              this.getField('email').setDisabled(true);
              this.getField('email').setValue('');
              this.getField('email').setError(false,'');
            }
        })
    }

    deshabilitarCorreo(){
        this.getField('radio_correo').setValue("N");
        this.getField('radio_correo').setDisabled(true);
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
    }
    

    

    validarCampos(){
        let errores = 0;

        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }

        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false){
              errores++;
            }
        }


        if(errores===0){
            this.procesarGeneral();
        }

    }

    procesarGeneral(){
                    
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('bt_consultar').setDisabled(true);

        let datos = {  datos: { 
            fecha:this.getField('fecha').getValue(),
            ch_niif:this.getField('ch_niif').getValue(),
            sucursal_ingreso: this.codigo_sucursal
            }
        }


        this.generalFormatPmv = { tipo_servicio: 'cont-inventariobalances', operacion: 'procesargeneral', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successProcesarGeneral,
            error: this.error_,
            general: this.generalFormatPmv
        });

        //}
    }

    successProcesarGeneral(data){
        if(data.estado_p === 200){           

            this.imprimirBalance();

        }else {
            this.getField('confirmModalCustom').toggle(false);
            this.getField('bt_consultar').setDisabled(false);
        }
    }



    imprimirBalance(){

        if(this.operacion_actual!==''){
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreo();
            }else{
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTabla();
                }else if(this.operacion_actual==='pantalla'){
                    this.traerSaldoTotal();
                    this.genearModalNormal();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelTabla();
                } 
            }
        }else{
            this.getField('confirmModalCustom').toggle(false);
            this.getField('bt_consultar').setDisabled(false);
            this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
        }

    }


    traerSaldoTotal(){
        let datos={ datos: {usuarios_codigo: this.codigo_usuario}};
     
        this.generalFormatPmv = { tipo_servicio: 'cont-inventariobalances', operacion: 'total_saldo', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTotalSaldo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    sucessTotalSaldo(data){
        if(data.estado_p === 200){ 
            this.getField('total_saldo').setValue("".formatoPrecio(Number(data.data[0].xsaldo).toFixed(2)));
        }else{
            this.getField('total_saldo').setValue("".formatoPrecio(Number(0).toFixed(2)));
        }
    }


    genearModalNormal(){
        let datos={ datos: {usuarios_codigo: this.codigo_usuario}};

        this.getField("rejilla_balances").setTipoServicioOperacion("cont-inventariobalances-1_balance_normal");
        this.getField("rejilla_balances").sendRequest(this.sucessTraerDatosNormales, datos);
    }

    sucessTraerDatosNormales(data){

        if(data.estado_p === 200){ 
            this.getField('confirmModalCustom').toggle(false);
            this.getField('bt_consultar').setDisabled(false);



            let fecha_actual = new Date();
            let fechaActualFormateada = fecha_actual.toISOString().split('T')[0];
            let sucursales = "TODAS";

            let datosFicha = [];
            let data2 = 
                    {                  
                        "operacion": this.getField('operacion').getValue(),
                        "fecha_actual": fechaActualFormateada,
                        "fecha_balance": this.getField('fecha').getValue() ,
                        "sucursales": sucursales,
                        "usuario": this.codigo_usuario,
                    };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);




            let sumasaldopagina = 0;
            if(data.data && data.data.length>0){
                
                data.data.forEach(item => {
                    if (item.maneja_movimiento==='S'){
                        sumasaldopagina += item.saldo;
                    }
                })
   
            }


            this.getField('modal_balances').handleClickOpen();
            this.gridOptionsComponentesBalanceNormal['rowData'] = [];   
            let configCell = new Map();
            configCell.set('saldo',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattersaldofinal});         
            this.gridOptionsComponentesBalanceNormal['rowData'] = data.data;
            this.getField('rejilla_balances').initData(this.gridOptionsComponentesBalanceNormal,configCell);
            this.getField('total_saldo_pagina').setValue("".formatoPrecio(Number(sumasaldopagina).toFixed(2)));            
            this.getField('total_saldo_pagina').setVisible(true);
            this.getField('total_saldo').setVisible(true);

        }else if(data.estado_p === 404){
            this.getField('confirmModalCustom').toggle(false);
            this.getField('bt_consultar').setDisabled(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporal();
        }
        else{
            this.getField('confirmModalCustom').toggle(false);
            this.getField('bt_consultar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal();
        } 
    }

    currencyFormattersaldofinal(data)
    {
        let decimal = (data.data.saldo + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
        return (this.formatNumberSaldo(data.data.saldo) + "," + decimal);
        } else {
        return this.formatNumberSaldo(data.data.saldo); }
    }

    formatNumberSaldo(number)
    {
        let valor=Number(number).toString().split('.');
        return valor[0]
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    limpiarTemporal(){
        let datos={ datos: {usuarios_codigo: this.codigo_usuario}};

        this.generalFormatPmv = { tipo_servicio: 'cont-inventariobalances', operacion: 'droptmp', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDropTmp,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }


    successDropTmp(data){
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
            if(this.props.data[0].opcion_sub_seccion === 1){
                this.opcionGeneral();
            }else if(this.props.data[0].opcion_sub_seccion === 2){
                this.opcionSoloSaldos();
            }
        }else{            
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');  
            if(this.props.data[0].opcion_sub_seccion === 1){
                this.opcionGeneral();
            }else if(this.props.data[0].opcion_sub_seccion === 2){
                this.opcionSoloSaldos();
            } 
        }
    }


    generarPdfTabla(){
        let url = this.constant.formConfiguration();
        let sucursales="TODAS";
        let datos2 = {};

        datos2 = {
            fecha:this.getField('fecha').getValue(),
            operacion:this.operacion_actual,
            codigo_sucursal:sucursales
        };
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-inventariobalances","operacion":"imprimirbalance","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                 "Authorization": 'Bearer '+localStorage.getItem('token'), 
                 "Content-Type":'application/json'
            })
        })
        .then(response => {
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            })                            
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                const pdf = new File([blob], 'InventarioBalanceGeneral.pdf', {
                    type:'application/pdf'
                });
                window.open(URL.createObjectURL(pdf))
                this.getField('confirmModalCustom').toggle(false);
                this.getField('bt_consultar').setDisabled(false);
                this.limpiarTemporal();
            }else{
                this.alertGeneral.toggle(true,'No hay datos','error');
                this.getField('confirmModalCustom').toggle(false);
                this.getField('bt_consultar').setDisabled(false);
                this.limpiarTemporal();
            }
        });
    }


    enviarCorreo(){
        let sucursales="TODAS"
        
        let datos = { datos: {
            fecha:this.getField('fecha').getValue(),
            operacion:this.operacion_actual,
            codigo_sucursal:sucursales,
            radio_correo:this.getField('radio_correo').getValue(),
            email:this.getField('email').getValue(),
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-inventariobalances', operacion: 'imprimirbalance', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successEnviarCorreo,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }


    successEnviarCorreo(data){
        if(data.estado_p === 200){ 
            this.getField('confirmModalCustom').toggle(false);
            this.getField('bt_consultar').setDisabled(false);
            this.limpiarTemporal();
        }else{
           let respuesta=Object.values(data.data.errores);
           let keys=Object.keys(data.data.errores);
           this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           this.getField('confirmModalCustom').toggle(false);
           this.getField('bt_consultar').setDisabled(false);
           this.limpiarTemporal();
        }
    }


    generarExcelTabla(){
        let url = this.constant.formConfiguration();
        let sucursales="TODAS";
        let datos2 = {};

        datos2 = {
            fecha:this.getField('fecha').getValue(),
            operacion:this.operacion_actual,
            codigo_sucursal:sucursales
        };

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-inventariobalances","operacion":"imprimirbalance","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                 "Authorization": 'Bearer '+localStorage.getItem('token'), 
                 "Content-Type":'application/json'
            })
        })

        .then(response => {
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {    
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            })
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = 'InventarioBalanceGeneral.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                alert('Se descargo el archivo!');
                this.getField('confirmModalCustom').toggle(false);
                this.getField('bt_consultar').setDisabled(false);
                this.limpiarTemporal();
            }else{
                this.alertGeneral.toggle(true,'No hay datos','error');
                this.getField('confirmModalCustom').toggle(false);
                this.getField('bt_consultar').setDisabled(false);
                this.limpiarTemporal();
            }
        });
    }

    cancelar(){
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionGeneral();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionSoloSaldos();
        }
    }















    /////////////////////////////////////////////////////////// SOLO SALDOS //////////////////////////////////////////////
    opcionSoloSaldos(){
        this.establecerPropiedades('2,3,4,5,7,8,9,10,11,12,13,14','visible','true');
        this.getField('operacion').setValue('INVENTARIO Y BALANCE SÓLO SALDOS');
        this.getField('radio_correo').setValue("N");
        this.getField('radio_correo').setDisabled(false);
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.getField('email').setValue("");
        this.getField('ch_niif').setVisible(false);
        this.getField("modal_balances").setLabel("INVENTARIO Y BALANCE SÓLO SALDOS");
        
        this.formatovista();
        this.getField('bt_consultar').setClick(()=>{this.validarCampos()});
        let fechaActual = new Date();
        let cambioFormato = Date.parse(fechaActual);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fecha').setValue(campoFecha.split('T')[0]);
        
        this.getField('modal_balances').setCloseButton(()=>{           
            this.getField('confirmModalCustom').toggle(false);
                this.getField('bt_consultar').setDisabled(false);
                this.limpiarTemporalSoloSaldos();
        });
    
    
        this.getField('bt_cancelar').setClick(()=>{this.cancelarSoloSaldos()});    
    }


    cancelarSoloSaldos(){
        this.opcionSoloSaldos();
    }

    limpiarTemporalSoloSaldos(){
        let datos={ datos: {usuarios_codigo: this.codigo_usuario}};

        this.generalFormatPmv = { tipo_servicio: 'cont-inventariobalances', operacion: 'droptmp', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDropTmp,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }


    successDropTmpSoloSaldos(data){
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
            this.opcionSoloSaldos();  
        }else{            
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');  
            this.opcionSoloSaldos();  
        }
    }























    establecerPropiedades(sObjetos,sPropiedad,sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if(sPropiedad==='disable') { 
                if(sValor==='true') { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }   
            }
            else if(sPropiedad==='visible') { 
                if(sValor==='true') { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                } else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }   
            } else if(sPropiedad==='value') { 
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            } else if(sPropiedad==='valid') { 
                if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                } else {
                    this.bHayErrores=true;
                }
            } else if(sPropiedad==='foco') { 
                if(sValor==='rejilla') { } else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });     
    }

}
FormJaivana.addController("cont-inventariobalances",CustomInventarioBalance);
export default CustomInventarioBalance