import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAsignarProductosUnidades
 * @author: Anderson Acevedo Briñez
 * @updateBy 
 * @version: jdesk_1.01.0001
 **/
class CustomAsignarProductosUnidades extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.successGuardarArticuloUnidad = this.successGuardarArticuloUnidad.bind(this);
    this.guardarArticuloUnidad = this.guardarArticuloUnidad.bind(this);
    this.eliminarArticuloUnidad = this.eliminarArticuloUnidad.bind(this);
    this.successEliminarArticuloUnidad = this.successEliminarArticuloUnidad.bind(this);
    this.buscarArticulosUnidades =this.buscarArticulosUnidades.bind(this);
    this.succesConsulta  = this.succesConsulta.bind(this);
	  this.mostrarCampos	=	this.mostrarCampos.bind(this);
    this.ocultarCampos =  this.ocultarCampos.bind(this);
    this.ocultarCamposCancelar  = this.ocultarCamposCancelar.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);

    this.setButtonDelete = this.setButtonDelete.bind(this);
    this.confirmeliminarArticuloUnidad	=	this.confirmeliminarArticuloUnidad.bind(this);
    this.validarUnique	=	this.validarUnique.bind(this);
    this.succesConsultaUnique	=	this.succesConsultaUnique.bind(this);
    this.contiene_registros=false;
    this.numero_registros=0;
  }

  initForm() {
    console.log("Formulario CustomAsignarProductosUnidades,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez");
    this.getField('seleccionar').setClick(this.buscarArticulosUnidades);
	  this.getField('articulo_id').setOnChange((props)=>{
        if(this.getField('articulo_id').getValue()!==''){
          this.getField('seleccionar').setDisabled(false);
          this.ocultarCampos();
        }else{
          this.getField('seleccionar').setDisabled(true);
          this.ocultarCampos();
        }
    });
    this.getField('agregar').setClick(this.mostrarCampos);
    this.getField('aceptar').setClick(this.validarUnique);
    this.getField('cancelar').setClick(this.ocultarCamposCancelar);
    this.getField('seleccionar').setDisabled(true);
    this.ocultarCampos();
  }

  mostrarCampos(){
    this.getField('agregar').setDisabled(true);
    this.getField('codigo_unidad').setVisible(true);
      this.getField('nombre_unidad').setVisible(true);
      this.getField('descuento1').setVisible(true);
      this.getField('descuento2').setVisible(true);
      this.getField('descuento3').setVisible(true);
      this.getField('descuento4').setVisible(true);
      this.getField('descuento5').setVisible(true);
      this.getField('descuento6').setVisible(true);
      this.getField('descuento7').setVisible(true);
      this.getField('descuento8').setVisible(true);
      this.getField('descuento9').setVisible(true);
      this.getField('aceptar').setVisible(true);
      this.getField('cancelar').setVisible(true);
  }
  
  ocultarCampos(){
      this.getField('rejilla').toggle(false);
      this.getField('agregar').setVisible(false);
      this.getField('codigo_unidad').setVisible(false);
      this.getField('nombre_unidad').setVisible(false);
      this.getField('descuento1').setVisible(false);
      this.getField('descuento2').setVisible(false);
      this.getField('descuento3').setVisible(false);
      this.getField('descuento4').setVisible(false);
      this.getField('descuento5').setVisible(false);
      this.getField('descuento6').setVisible(false);
      this.getField('descuento7').setVisible(false);
      this.getField('descuento8').setVisible(false);
      this.getField('descuento9').setVisible(false);
      this.getField('aceptar').setVisible(false);
      this.getField('cancelar').setVisible(false);

      this.getField('codigo_unidad').setValue('');
      this.getField('nombre_unidad').setValue('');
      this.getField('unidad_id').setValue('');
      this.getField('descuento1').setValue('');
      this.getField('descuento2').setValue('');
      this.getField('descuento3').setValue('');
      this.getField('descuento4').setValue('');
      this.getField('descuento5').setValue('');
      this.getField('descuento6').setValue('');
      this.getField('descuento7').setValue('');
      this.getField('descuento8').setValue('');
      this.getField('descuento9').setValue('');

      this.getField('codigo_unidad').setError(false,'');
      this.getField('nombre_unidad').setError(false,'');
      this.getField('descuento1').setError(false,'');
      this.getField('descuento2').setError(false,'');
      this.getField('descuento3').setError(false,'');
      this.getField('descuento4').setError(false,'');
      this.getField('descuento5').setError(false,'');
      this.getField('descuento6').setError(false,'');
      this.getField('descuento7').setError(false,'');
      this.getField('descuento8').setError(false,'');
      this.getField('descuento9').setError(false,'');
  }

  ocultarCamposCancelar(){
    this.getField('agregar').setVisible(false);
    if(this.contiene_registros===true){
      this.getField('agregar').setDisabled(false);
      this.getField('agregar').setVisible(true);
      this.getField('codigo_unidad').setVisible(false);
      this.getField('nombre_unidad').setVisible(false);
      this.getField('descuento1').setVisible(false);
      this.getField('descuento2').setVisible(false);
      this.getField('descuento3').setVisible(false);
      this.getField('descuento4').setVisible(false);
      this.getField('descuento5').setVisible(false);
      this.getField('descuento6').setVisible(false);
      this.getField('descuento7').setVisible(false);
      this.getField('descuento8').setVisible(false);
      this.getField('descuento9').setVisible(false);
      this.getField('aceptar').setVisible(false);
      this.getField('cancelar').setVisible(false);
    }else{
      this.getField('agregar').setVisible(false);
    }
    this.getField('codigo_unidad').setValue('');
    this.getField('nombre_unidad').setValue('');
    this.getField('unidad_id').setValue('');
    this.getField('descuento1').setValue('');
    this.getField('descuento2').setValue('');
    this.getField('descuento3').setValue('');
    this.getField('descuento4').setValue('');
    this.getField('descuento5').setValue('');
    this.getField('descuento6').setValue('');
    this.getField('descuento7').setValue('');
    this.getField('descuento8').setValue('');
    this.getField('descuento9').setValue('');
    this.getField('codigo_unidad').setError(false,'');

      this.getField('nombre_unidad').setError(false,'');
      this.getField('descuento1').setError(false,'');
      this.getField('descuento2').setError(false,'');
      this.getField('descuento3').setError(false,'');
      this.getField('descuento4').setError(false,'');
      this.getField('descuento5').setError(false,'');
      this.getField('descuento6').setError(false,'');
      this.getField('descuento7').setError(false,'');
      this.getField('descuento8').setError(false,'');
      this.getField('descuento9').setError(false,'');
  }

  buscarArticulosUnidades(){
    if(this.getField('codigo_articulo').valid()
        && this.getField('nombre_articulo').valid()
        && this.getField('articulo_id').valid()){
        this.ocultarCampos();
        let datos={ 
            datos: {
              codigo_articulo:this.getField('codigo_articulo').getValue(),  
            }};
        this.generalFormatPmv = { tipo_servicio: 'maes-asignarunidadesaproductos', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesConsulta,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
  }

  succesConsulta(data){
      if (data.estado_p === 200 )
      {
          this.numero_registros=data.data.length;
          this.contiene_registros=true;
          this.getField('seleccionar').setDisabled(true);
          this.getField('agregar').setDisabled(false);
          this.getField('agregar').setVisible(true);
          this.getField('rejilla').toggle(true);
          let configCell = new Map();
          configCell.set('des1',{cellStyle:{textAlign:"right"}});
          configCell.set('des2',{cellStyle:{textAlign:"right"}});
          configCell.set('des3',{cellStyle:{textAlign:"right"}});
          configCell.set('des4',{cellStyle:{textAlign:"right"}});
          configCell.set('des5',{cellStyle:{textAlign:"right"}});
          configCell.set('des6',{cellStyle:{textAlign:"right"}});
          configCell.set('des7',{cellStyle:{textAlign:"right"}});
          configCell.set('des8',{cellStyle:{textAlign:"right"}});
          configCell.set('des9',{cellStyle:{textAlign:"right"}});
          configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'eliminar' });
          let datos=[]
          data.data.forEach(element => {
            let arreglo=
              {
                articulo_unidad_id: element.articulo_unidad_id,
                unidad_id: element.unidad_id,
                estadoactivo_id: element.estadoactivo_id,
                unidad: element.codigo_unidad,
                des1: element.descuento1,
                des2: element.descuento2,
                des3: element.descuento3,
                des4: element.descuento4,
                des5: element.descuento4,
                des6: element.descuento5,
                des7: element.descuento6,
                des8: element.descuento7,
                des9: element.descuento9
            };
          
            datos.push(arreglo);
          });
          this.gridOptionsComponentes['rowData'] = datos;
          this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
      }
      else 
      {
		  if (data.estado_p === 404 ){
        this.numero_registros=0;
        this.contiene_registros=false;
			  this.getField('rejilla').toggle(false);
			  this.getField('agregar').setVisible(false);
			  this.getField('agregar').setDisabled(true);
			  this.mostrarCampos();
		  }else{
        this.numero_registros=0;
			  this.getField('rejilla').toggle(false);
			  let respuesta=Object.values(data.data.errores);
			  let keys=Object.keys(data.data.errores);
			  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error'); 
		  }
      };
  }
  
  validarUnique(){

    if(this.getField('codigo_articulo').valid()&&
      this.getField('nombre_articulo').valid()&&
      this.getField('articulo_id').valid() &&
	    this.getField('codigo_unidad').valid() &&
      this.getField('nombre_unidad').valid() &&
      this.getField('unidad_id').valid() &&
      this.getField('descuento1').valid() &&
      this.getField('descuento2').valid() &&
      this.getField('descuento3').valid() &&
      this.getField('descuento4').valid() &&
      this.getField('descuento5').valid() &&
      this.getField('descuento6').valid() &&
      this.getField('descuento7').valid() &&
      this.getField('descuento8').valid() &&
      this.getField('descuento9').valid() 

      ){
        let datos={ 
            datos: {
              codigo_articulo:this.getField('codigo_articulo').getValue(),  
              codigo_unidad:this.getField('codigo_unidad').getValue()
            }};
        this.generalFormatPmv = { tipo_servicio: 'maes-asignarunidadesaproductos', operacion: '20', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesConsultaUnique,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
  }

  succesConsultaUnique(data){
      if (data.estado_p === 200 )
      {
        this.alertGeneral.toggle(true, 'Ya éxiste la relación codigo_unidad y codigo_articulo.', 'error');
      }
      else 
      {
        if(data.estado_p===404){
          this.guardarArticuloUnidad();
        }else{
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      };
  }

  guardarArticuloUnidad(){
    if(this.getField('codigo_articulo').valid()&&
      this.getField('nombre_articulo').valid()&&
      this.getField('articulo_id').valid() &&
	    this.getField('codigo_unidad').valid() &&
      this.getField('nombre_unidad').valid() &&
      this.getField('unidad_id').valid() &&
      this.getField('descuento1').valid() &&
      this.getField('descuento2').valid() &&
      this.getField('descuento3').valid() &&
      this.getField('descuento4').valid() &&
      this.getField('descuento5').valid() &&
      this.getField('descuento6').valid() &&
      this.getField('descuento7').valid() &&
      this.getField('descuento8').valid() &&
      this.getField('descuento9').valid() 

      ){
        let datos = { datos: {
          codigo_articulo:this.getField('codigo_articulo').getValue(),
          codigo_unidad:this.getField('codigo_unidad').getValue(),
          descuento1:this.getField('descuento1').getValue(),
          descuento2:this.getField('descuento2').getValue(),
          descuento3:this.getField('descuento3').getValue(),
          descuento4:this.getField('descuento4').getValue(),
          descuento5:this.getField('descuento5').getValue(),
          descuento6:this.getField('descuento6').getValue(),
          descuento7:this.getField('descuento7').getValue(),
          descuento8:this.getField('descuento8').getValue(),
          descuento9:this.getField('descuento9').getValue(),
          articulo_id:this.getField('articulo_id').getValue(),
          unidad_id:this.getField('unidad_id').getValue(),
          estadoactivo_id:this.getField('estadoactivo_id').getValue(),
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-asignarunidadesaproductos', operacion: '5', operacion_tipo: 'crear' };
        this.datos_anteriores=this.generalFormatPmv;
        this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.successGuardarArticuloUnidad,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }
  }

  successGuardarArticuloUnidad(data) 
  {

    if (data.estado_p === 200)
    {
        this.ocultarCampos();
        this.buscarArticulosUnidades();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  setButtonDelete(props) {
      let id = props.data.bancos_cuentas_articulo_id;
      let button = document.createElement("input");
      button.onclick = () => this.confirmeliminarArticuloUnidad(id);
      button.setAttribute("id", 'button_delete_' + id);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("type", "button");
      button.setAttribute("value", "eliminar");
      return this.createElementJaivana(button);
  }

  confirmeliminarArticuloUnidad(id) {

    if(this.numero_registros>1){
      this.ids = id;
      this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
      this.getField('confirmModalCustom').setClickDialog(this.eliminarArticuloUnidad);
      this.getField('confirmModalCustom').toggle(true);
    }else{
      this.alertGeneral.toggle(true, 'No es posible eliminar ya que solo éxiste un registro.', 'error');
    }
  }
  
  eliminarArticuloUnidad(){
    this.getField('confirmModalCustom').toggle(false);
    if(this.getField('codigo_articulo').valid()&&
		this.getField('nombre_articulo').valid()&&
		this.getField('articulo_unidad_id').valid()){
        let datos = { datos: {
          articulo_unidad_id:this.getField('articulo_unidad_id').getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-asignarunidadesaproductos', operacion: '7', operacion_tipo: 'eliminar' };
        
        
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminarArticuloUnidad,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }else{
      this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
    }
  }

  successEliminarArticuloUnidad(data) 
  {
   
    if (data.estado_p === 200)
    {
        this.buscarArticulosUnidades();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

}
FormJaivana.addController("maes-asignarunidadesaproductos", CustomAsignarProductosUnidades);
export default CustomAsignarProductosUnidades;