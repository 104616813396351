import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomConsultarPedidos
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0004.1
 **/
class CustomAdministradorFerricentro extends FormJaivana.form {

    /**
     * En esta función se setean los valores iniciales a usar, se instancian las funciones a usar.
     * @param {Obj} props Propiedades que hereda el componente del padre.
     */
    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.initModalUbicaciones       = this.initModalUbicaciones.bind(this);
        this.initElementsModal          = this.initElementsModal.bind(this);
        this.ubicacionproducto          = this.ubicacionproducto.bind(this);
        this.successUbicaciones         = this.successUbicaciones.bind(this);
        this.limpiarTablaUbicaciones    = this.limpiarTablaUbicaciones.bind(this);
        this.guardarDatosUbicacion      = this.guardarDatosUbicacion.bind(this);
        this.valorZona                  = this.valorZona.bind(this);
        this.successGuardarUbicacion    = this.successGuardarUbicacion.bind(this);
        this.id_cliente                 = 0;
        this.dataCliente                = []; 
        this.totalCantidadProductos     = 0;
        this.gridOptionsProducto        = Object.assign({},this.gridOptions); 
        this.gridOptionsProductoBodegas = Object.assign({},this.gridOptions); 
    }
    /**
     * Método que se ejecuta al inicio de renderizar el componente. Se encarga de configurar por defecto 
     * la vista principal
     */
    initForm() {
        console.log('Administrar Ubicaciones, @version: 1.01.0004.1, @author: Patricia Lopez Sanchez');
        this.getField('btnUbicaciones').setClick(this.initModalUbicaciones);

        this.getField('codigo_ubicacion').setOnChange(()=>{
            if(this.getField('codigo_ubicacion').getValue() !== ""){
                this.ubicacionproducto();
            }else{
                this.limpiarTablaUbicaciones();
            }
        });
    }
    
    initModalUbicaciones(){
        this.getField('modalUbicaciones').handleClickOpen(this.initElementsModal);
    }

    initElementsModal(){
        this.getField('codigo_ubicacion').setOnChange(()=>{
            if(this.getField('codigo_ubicacion').getValue() !== ""){
                this.ubicacionproducto();
            }else{
                this.limpiarTablaUbicaciones();
            }
        });
    }

    limpiarTablaUbicaciones(){
        if(this.getField('codigo_ubicacion').getValue() !== '')
            this.getField('codigo_ubicacion').loadData(this.getField('codigo_ubicacion').getValue());
        else
            this.getField('tablaUbicaciones').toggle(false);
    }

    ubicacionproducto(){
        let datos = { datos:{
            value: this.getField('codigo_ubicacion').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-administrarubicaciones', operacion: 'ubicacionproducto', operacion_tipo: 'consulta' };
        
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successUbicaciones,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successUbicaciones(data){
        console.log(data)
        console.log(data.data.length)
        if(data.data.length > 0){
            this.gridOptions['rowData'] = [];
            this.getField('tablaUbicaciones').initData(this.gridOptions);
            let configCell = new Map();
            configCell.set('zona',{cellRenderer:function(props){return props.data.zona},cellStyle: {backgroundColor: "rgb(238,238,238)",borderRadius:"5px",color:'rgb(0, 86, 201)' }, onCellValueChanged:this.valorZona, editable: true, field: 'zona' });
            configCell.set('estante',{cellRenderer:function(props){return props.data.estante},cellStyle: {backgroundColor: "rgb(238,238,238)",borderRadius:"5px",color:'rgb(0, 86, 201)' }, onCellValueChanged:this.valorZona, editable: true, field: 'estante' });
            configCell.set('fila',{cellRenderer:function(props){return props.data.fila},cellStyle: {backgroundColor: "rgb(238,238,238)",borderRadius:"5px",color:'rgb(0, 86, 201)' }, onCellValueChanged:this.valorZona, editable: true, field: 'fila' });
            this.gridOptions['rowData'] = data.data;
            this.getField('tablaUbicaciones').initData(this.gridOptions,configCell);
            this.getField('tablaUbicaciones').toggle(true);
        }
    }

    valorZona(props){
        let seguir = this.getField('tablaUbicaciones').cellValid(props.oldValue,props.newValue,'^[a-zA-Z0-9ñÑ\u0026\u00F1\u00D1]+$');
        if(seguir){
            this.getField('codigo_bodega').setValue(props.data.codigo_bodega);           
            this.getField('zona').setValue(props.data.zona);
            this.getField('estante').setValue(props.data.estante);
            this.getField('fila').setValue(props.data.fila);
            this.guardarDatosUbicacion(props.data);
        } else if(seguir !== null) {
            this.getField('codigo_ubicacion').setValue(this.getField('codigo').getValue());
        }
    }

    guardarDatosUbicacion(data){

        let datos = { datos:{
            id: this.getField('articulo_id').getValue(),
            zona: this.getField('zona').getValue().toString(),
            estante: this.getField('estante').getValue().toString(),
            fila: this.getField('fila').getValue().toString(),
            codigo: data.codigo,
            codigo_bodega: this.getField('codigo_bodega').getValue().toString()
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-administrarubicaciones', operacion: 'modificarubicacion', operacion_tipo: 'consulta' };
        
        this.service.send(
        {
            endpoint:this.constant.formConfiguration(),
            method:'PUT',
            body:datos,
            success:this.successGuardarUbicacion,
            error:this.error_,
            general:this.generalFormatPmv,
        });
    }

    successGuardarUbicacion(data){
        if(data.estado_p === 200){
            this.getField('fieldAlert').toggle(true,"OK","success");
        }
        else
            this.getField('codigo_ubicacion').setValue(this.getField('codigo').getValue());
    }
}
FormJaivana.addController("most-administrarubicaciones",CustomAdministradorFerricentro);
export default CustomAdministradorFerricentro;