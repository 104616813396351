import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomComprasVsContabilidad
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0001
 **/
class CustomComprasVsContabilidad extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;

    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.sucessconsultarDatos                                  = this.sucessconsultarDatos.bind(this);
    this.consultarDatos                                  = this.consultarDatos.bind(this);
    this.validarConsultar                                  = this.validarConsultar.bind(this);
    this.validarCamposFecha                                  = this.validarCamposFecha.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.crearTemporal                                  = this.crearTemporal.bind(this);
    this.successCrearTemporal                                  = this.successCrearTemporal.bind(this);
    this.arreglarDecimales                         =this.arreglarDecimales.bind(this);

    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.fecha_inicio='';
    this.dias_maximo='';
    
  }
  
  initForm() {
    console.log("Formulario CustomComprasVsContabilidad,  @version: jdesk_1.01.0002, @author: Anderson Estiven Acevedo Briñez");
    //nuevo para consulta


    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('consultar_desde').setValue(this.fecha_inicio);

    this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
    this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);
    this.getField('consultar').setClick(this.validarConsultar);


  }

  traerPropiedades(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'comp-comprasvscontabilidad', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
}

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
            this.dias_maximo=data.data.compras_vs_contabilidad_dias_devolver;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

  validarCamposFecha(){
    this.getField('consultar').setDisabled(false);
    this.getField("consultar_desde").setError(false,'');
    this.getField("consultar_hasta").setError(false,'');
    this.getField('tabla_vs').toggle(false);
    this.getField('total_xvalor').setVisible(false);
    this.getField('total_x14').setVisible(false);
    this.getField('total_xdife1').setVisible(false);
    let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
    let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
    let fecha_actual = new Date().toISOString().split('T')[0];
    if (fechaInicial > fechaFinal) {
        this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
        this.getField('consultar').setDisabled(true);
        return false;
    } 

    if(fechaFinal > fecha_actual){
        this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
        this.getField('consultar').setDisabled(true);
        return false;
    }
    if(fechaFinal > fecha_actual){
        this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
        this.getField('consultar').setDisabled(true);
        return false;
    }

    let diferencia=new Date(fechaFinal).getTime()-new Date(fechaInicial).getTime();
    diferencia = diferencia / 1000 / 60 / 60 / 24;
    if(diferencia >this.dias_maximo){
        this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
        this.getField('consultar').setDisabled(true); 
        return false;
    }

    this.getField('consultar_hasta').setError(false,"");
    return true;
}

  validarConsultar(){
    this.getField('tabla_vs').toggle(false);

    let errores=0;
    if(this.validarCamposFecha()===false)
        errores++;
    
    if(errores===0){
        this.crearTemporal();
    }

  }

  onSelectionChanged(){

  }

    crearTemporal(){
        this.mostrarMensajeGenerando();
        this.getField('consultar').setDisabled(true);
        let datos={ datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
        }};

        this.generalFormatPmv = { tipo_servicio: 'comp-comprasvscontabilidad', operacion: 'creartemporal', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrearTemporal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successCrearTemporal(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.consultarDatos();
        }else{
            if(data.estado_p===404) {
                this.mostrarMensajeNoHayDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }


  consultarDatos(){
        this.mostrarMensajeGenerando();
        this.getField('consultar').setDisabled(true);
        let datos={ datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
        }};
        //this.getField("tabla_vs").setTipoServicioOperacion("comp-comprasvscontabilidad-consultar");
        //this.getField("tabla_vs").sendRequest(this.sucessconsultarDatos, datos);
        
        this.generalFormatPmv = { tipo_servicio: 'comp-comprasvscontabilidad', operacion: 'consultar_pag', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessconsultarDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });

    }

    sucessconsultarDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            let configCell = new Map();
            configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xvalor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xvalor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('x14', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.x14) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xdife1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xdife1) }, cellStyle: {textAlign:"right"},width: 110});


            this.getField('tabla_vs').toggle(true);
            this.gridOptionsComponentes['rowData'] = [];
            
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('tabla_vs').initData(this.gridOptionsComponentes,configCell);
            let total1=0.0;
            let total2=0.0;
            let total3=0.0;
            data.data.forEach(element => {
                total1+=element.xvalor;
                total2+=element.x14;
                total3+=element.xdife1;
            });
            this.getField('total_xvalor').setVisible(true);
            this.getField('total_x14').setVisible(true);
            this.getField('total_xdife1').setVisible(true);
            this.getField('total_xvalor').setValue(this.arreglarDecimales(total1));
            this.getField('total_x14').setValue(this.arreglarDecimales(total2));
            this.getField('total_xdife1').setValue(this.arreglarDecimales(total3));
            
        }else{
            if(data.estado_p===404) {
                this.mostrarMensajeNoHayDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);
    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }

   
}


FormJaivana.addController("comp-comprasvscontabilidad", CustomComprasVsContabilidad);
export default CustomComprasVsContabilidad;