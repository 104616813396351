import FormJaivana from "dashboard_jaivana_v1";
/**
 *
 * @author Anderson Acevedo Briñez
 */
/**
 * Custom  Autorizar vendedores
 **/
class CustomAutorizarVendedores extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.traerDatos = this.traerDatos.bind(this);
    this.gridOptionsTodos = Object.assign({}, this.gridOptions);
    this.successTraerDatos= this.successTraerDatos.bind(this);
    this.setButtonCambiar = this.setButtonCambiar.bind(this);
    this.confirmCambiar = this.confirmCambiar.bind(this);
    this.successCambiar = this.successCambiar.bind(this);


  }

  initForm() {
    console.log("Formulario CustomAutorizarVendedores,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez");
    this.traerDatos();

  }


  traerDatos(){
    let datos = { datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'maes-autorizarvendedores', operacion: '1_1', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successTraerDatos,
              error: this.error_,
              general: this.generalFormatPmv
      });
  }

  successTraerDatos(data){
    if (data.estado_p === 200)
        {
            
          this.getField('tabla_autorizar_vendedores').toggle(true);
          let configCell = new Map();
          configCell.set('accion', { cellRenderer: this.setButtonCambiar, width: 110, sortable: false, filter: false, field: 'cambiar' });
          this.gridOptionsTodos['rowData'] = data.data;
          this.getField('tabla_autorizar_vendedores').initData(this.gridOptionsTodos, configCell);
        }
        else 
        {
           if(data.estado_p===404) {

           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           this.getField('tabla_autorizar_vendedores').toggle(false);
       }
    }

    setButtonCambiar(props) {
        let id = props.data.vendedores_id;
        let autorizado=props.data.autorizado
        let button = document.createElement("input");
        button.onclick = () => this.confirmCambiar(id,autorizado);
        
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "cambiar");
        return this.createElementJaivana(button);
    }
  
  
    confirmCambiar(id,autorizado) {
      let datos = {
          datos: {
              vendedores_id: id,
              autorizado: autorizado==='N'?'S':'N'
          }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-autorizarvendedores', operacion: '6', operacion_tipo: "modificar" };// parametros del servicio
      this.service.send(
          {
              endpoint: this.constant.formConfiguration(),
              method: 'PUT',
              body: datos,
              success: this.successCambiar,
              error: this.error_,
              general: this.generalFormatPmv //se envia el generales de arriba
          });
    }
  
  
    successCambiar(data) {
        if(data.estado_p === 200){
          this.traerDatos();
        }else 
        {
          if(data.estado_p===404) {

          }
          else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
          this.getField('tabla_autorizar_vendedores').toggle(false);
          this.traerDatos();
      }
    }


}
FormJaivana.addController("maes-autorizarvendedores", CustomAutorizarVendedores);
export default CustomAutorizarVendedores;