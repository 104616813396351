import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomConsecutivosModulos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.llenartabla                = this.llenartabla.bind(this);
        this.successMostrar             = this.successMostrar.bind(this);
        this.gridOptionsComponentes     = Object.assign({},this.gridOptions);
        this.setButtonModificar         = this.setButtonModificar.bind(this);
        this.Modificar                  = this.Modificar.bind(this);
        this.validar                    = this.validar.bind(this);
        this.actualizar                 = this.actualizar.bind(this);
        this.successModificar           = this.successModificar.bind(this);
        this.datos_nuevos               = "";
        this.insertarlog                = this.insertarlog.bind(this);
        this.successRegistrarLog        = this.successRegistrarLog.bind(this);
    }

    initForm(){
        console.log('Formulario CustomConsecutivosModulos,  @version: jdesk_10.0.001, @author:Patricia López Sánchez')
        this.llenartabla();
        this.getField('numero').setKeyUp(this.validar);
        this.getField('actualizar').setClick(this.actualizar);
        this.infoUsuario = JSON.parse(localStorage.getItem('res'));
        this.usuario_id = this.infoUsuario.id;
    }  

    llenartabla(){
        let datos = { datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'maes-consecutivosmodulos', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
               endpoint: this.constant.formConfiguration(),
               method:'GET',
               body: datos,
               success: this.successMostrar,
               error: this.error_,
               general: this.generalFormatPmv
        });
    }

    successMostrar(data){
        if(data.estado_p === 200){ 
            let nuevoArray=[];
            data.data.forEach(consecutivo => {
            let estado = "";
            if(consecutivo.estadoactivo_id===1) {  
                estado="Activo";
            }
            else{
                estado="Inactivo"; 
            } 
            let row ={
            codigo_consecutivo: consecutivo.codigo_consecutivo,
            detalle: consecutivo.detalle,
            numero: consecutivo.numero,
            codigo_sucursal: consecutivo.codigo_sucursal,
            codigo: consecutivo.codigo,
            estado: estado,
            id: consecutivo.id
            };
            nuevoArray.push(row); 
            });
            let configCell = new Map();
            this.getField('rejilla').toggle(true);
            this.gridOptionsComponentes['rowData'] = nuevoArray;
            configCell.set('Modificar', { cellRenderer: this.setButtonModificar, width: 110, sortable: false, filter: false, field: 'Modificar' });
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
          }else if (data.estado_p === 404){
            this.getField('rejilla').toggle(false);
          }
    }

    setButtonModificar(props) {
        let id = props.data.id;
        let detalle = props.data.detalle;
        let numero = props.data.numero;
        let codigo_sucursal = props.data.codigo_sucursal;
        let button = document.createElement("input");
        button.onclick = () => this.Modificar(id,detalle,numero,codigo_sucursal);
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Modificar");
        return this.createElementJaivana(button);
    }

    Modificar(id,detalle,numero,codigo_sucursal) {
        this.ids = id;
        this.getField("contenedorDatos1").setLabel(detalle);
        this.getField('contenedorDatos1').handleClickOpen();
        this.getField('numero').setValue(numero);
        this.numero = numero;
        this.codigo_sucursal = codigo_sucursal;
        this.getField('numero').setError(false, '');
    }

    validar(){
        if(this.getField('numero').getValue() < this.numero){
            this.getField('numero').setError(true, 'Error el número de consecutivo ingresado no puede ser menor al actual.');
        }else{
            this.getField('numero').setError(false, '');
            this.getField('numero').valid();
        }
    }

    actualizar(){
        if (this.getField('numero').valid()) {
            let datos = { datos: {consecutivos_id:this.ids, codigo_sucursal: this.codigo_sucursal,numero:this.getField('numero').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-consecutivosmodulos', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
            {
                   endpoint: this.constant.formConfiguration(),
                   method:'PUT',
                   body: datos,
                   success: this.successModificar,
                   error: this.error_,
                   general: this.generalFormatPmv
            });
        }
    }

    successModificar(data){
        if (data.estado_p === 200 )
        {
            this.insertarlog();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    insertarlog(){
            let datos = { datos: {
                nombre_tabla:'consecutivos',
                operacion:'modificacion consecutivos',
                registro_id:this.ids,
                datos_anteriores:String(this.numero),
                datos_nuevos:String(this.getField('numero').getValue()),
                fecha_modificacion:'now()',
                usuario_id:this.usuario_id
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'maes-consecutivosmodulos', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successRegistrarLog,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }
    
    successRegistrarLog(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'El consecutivo fue modificado con exito', 'success');
            this.getField('contenedorDatos1').handleClose();
            this.llenartabla();
        } else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
}
FormJaivana.addController("maes-consecutivosmodulos",CustomConsecutivosModulos);
export default CustomConsecutivosModulos