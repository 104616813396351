import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomAdministracionProductosDespiece
 * @author: Cristian Ciro
 * @version: jdesk_1.01.0001
 **/
class CustomAdministracionProductosDespiece extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);

        this.seleccionarProductos       = this.seleccionarProductos.bind(this);
        this.successComponentes         = this.successComponentes.bind(this);
        this.gridOptionsComponentes     = Object.assign({}, this.gridOptions);
        this.setButtonEliminar          = this.setButtonEliminar.bind(this);
        this.setButtonActivar           = this.setButtonActivar.bind(this);
        this.activarinactivar           = this.activarinactivar.bind(this);
        this.eliminar                   = this.eliminar.bind(this);
        this.successActivar             = this.successActivar.bind(this);
        this.limpiarCampos              = this.limpiarCampos.bind(this);
        this.limpiarCampos2             = this.limpiarCampos2.bind(this);
        this.mostrarCampos              = this.mostrarCampos.bind(this);
        this.crearComponente            = this.crearComponente.bind(this);
        this.successCrearComponente     = this.successCrearComponente.bind(this);
        this.successValidarUnique       = this.successValidarUnique.bind(this);
        this.successEliminar            = this.successEliminar.bind(this);
        this.validarEliminar            = this.validarEliminar.bind(this);
        this.idEliminar                 = 0;
        
    }

    initForm(){
        console.log('Formulario CustomAdministracionProductosDespiece,  @version: jdesk_1.01.0001, @author:Cristian Ciro')      
        this.getField("seleccionar").setClick(this.seleccionarProductos);
        this.getField("nombre_articulo").setOnChange(this.limpiarCampos);
        this.getField("agregar").setClick(this.mostrarCampos);
        this.getField("cancelar").setClick(this.limpiarCampos2);
        this.getField("aceptar").setClick(this.crearComponente);
 
    }

    mostrarCampos(){
        this.getField("codigo_articulo_componente").setVisible(true);
        this.getField("nombre_articulo_componente").setVisible(true);
        this.getField("cantidad_componente_crear").setVisible(true);
        this.getField("valor_componente_crear").setVisible(true);
        this.getField("factor_componente_crear").setVisible(true);
        this.getField("aceptar").setVisible(true);
        this.getField("cancelar").setVisible(true);
        this.getField("seleccionar").setDisabled(true);
        this.getField("agregar").setVisible(false);
    }

    limpiarCampos(){
        if(this.getField("nombre_articulo").getValue() !== '')
            this.getField("nombre_articulo").valid();
        this.getField("resumen_productos").toggle(false);
        this.getField("agregar").setVisible(false);
        this.getField("codigo_articulo_componente").setVisible(false);
        this.getField("nombre_articulo_componente").setVisible(false);
        this.getField("cantidad_componente_crear").setVisible(false);
        this.getField("valor_componente_crear").setVisible(false);
        this.getField("factor_componente_crear").setVisible(false);
        this.getField("aceptar").setVisible(false);
        this.getField("cancelar").setVisible(false);
        this.getField("seleccionar").setDisabled(false);
    }

    limpiarCampos2(){
        this.getField("codigo_articulo_componente").setVisible(false);
        this.getField("nombre_articulo_componente").setVisible(false);
        this.getField("cantidad_componente_crear").setVisible(false);
        this.getField("valor_componente_crear").setVisible(false);
        this.getField("factor_componente_crear").setVisible(false);
        this.getField("aceptar").setVisible(false);
        this.getField("cancelar").setVisible(false);
        this.getField("agregar").setVisible(true);

        this.getField("codigo_articulo_componente").setValue("");
        this.getField("nombre_articulo_componente").setValue("");
        this.getField("cantidad_componente_crear").setValue("");
        this.getField("valor_componente_crear").setValue("");
        this.getField("factor_componente_crear").setValue("");
        this.getField("aceptar").setValue("");
        this.getField("cancelar").setValue("");

        this.getField("codigo_articulo_componente").setError(false,"");
        this.getField("nombre_articulo_componente").setError(false,"");
        this.getField("cantidad_componente_crear").setError(false,"");
        this.getField("valor_componente_crear").setError(false,"");
        this.getField("factor_componente_crear").setError(false,"");
    }

    crearComponente(){
        if(this.getField("codigo_articulo_componente").valid() &
        this.getField("nombre_articulo_componente").valid() &
        this.getField("cantidad_componente_crear").valid() &
        this.getField("valor_componente_crear").valid() &
        this.getField("factor_componente_crear").valid()){

            let datos = { datos: {
                codigo_articulo:this.getField("codigo_articulo").getValue(),
                codigo_articulo_componente: this.getField("codigo_articulo_componente").getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-articulodespiece', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successValidarUnique,
                    error: this.error_,
                    showMessage:false,
                    general: this.generalFormatPmv
            });
        }
    }

    successValidarUnique(data){
        if(data.estado_p === 200){
            this.alertGeneral.toggle(true,"Ya existe la relación con este articulo. No se permite duplicar.",'error');
        }else{
            let datos = { datos: {
                codigo_articulo:this.getField("codigo_articulo").getValue(),
                codigo_articulo_componente: this.getField("codigo_articulo_componente").getValue(),
                cantidad_componente_crear: this.getField("cantidad_componente_crear").getValue(),
                valor_componente_crear: this.getField("valor_componente_crear").getValue(),
                factor_componente_crear: this.getField("factor_componente_crear").getValue(),
                articulo_id: this.getField("articulo_id").getValue(),
                despiece_id: this.getField("despiece_id").getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-articulodespiece', operacion: '5_1', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrearComponente,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successCrearComponente(data){
        if(data.estado_p === 200){
            this.limpiarCampos2();
            this.seleccionarProductos();
        }
    }

    seleccionarProductos(){
        this.limpiarCampos2();
        if(this.getField("codigo_articulo").valid() & this.getField("nombre_articulo").valid()){
            let datos = { datos: {
                value: this.getField('codigo_articulo').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-articulodespiece', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successComponentes,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successComponentes(data){
        if(data.estado_p === 200){
            this.getField("seleccionar").setDisabled(true);
            this.getField("agregar").setVisible(true);
            this.getField('resumen_productos').toggle(true);
            let configCell = new Map();
            configCell.set('costo_componente',{cellRenderer:function(props){return''.formatoPrecio(props.data.costo_componente)},field:'costo_componente'});
            configCell.set('accion', { cellRenderer: this.setButtonEliminar, width: 110, sortable: false, filter: false, field: 'accion' });
            configCell.set('accion_2', { cellRenderer: this.setButtonActivar, width: 110, sortable: false, filter: false, field: 'accion_2' });
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('resumen_productos').initData(this.gridOptionsComponentes, configCell);
        }else{
            this.getField("codigo_articulo_componente").setVisible(true);
            this.getField("nombre_articulo_componente").setVisible(true);
            this.getField("cantidad_componente_crear").setVisible(true);
            this.getField("valor_componente_crear").setVisible(true);
            this.getField("factor_componente_crear").setVisible(true);
            this.getField("aceptar").setVisible(true);
            this.getField("cancelar").setVisible(true);
            this.getField("agregar").setVisible(false);
            this.getField("resumen_productos").toggle(false);
            this.getField("seleccionar").setDisabled(true);
        }
    }

    setButtonEliminar(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = () => this.eliminar(id);
        
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    setButtonActivar(props) {
        let id = props.data.id;
        let estadoid = props.data.estadoactivo_id;
        let button = document.createElement("input");
        button.onclick = () => this.activarinactivar(estadoid,id);
        
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        if(estadoid === 1)
            button.setAttribute("value", "Inactivar");
        else
            button.setAttribute("value", "Activar");
        return this.createElementJaivana(button);
    }

    activarinactivar(valor,id){
        let datos = { datos: {
            estadoactivo_id: valor===1?2:1,
            articulo_id:id
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-articulodespiece', operacion: '2_1', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successActivar,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successActivar(data){
        if(data.estado_p === 200){
            this.limpiarCampos2();
            this.seleccionarProductos();
        }
    }

    eliminar(id){
        this.idEliminar = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.validarEliminar);
        this.getField('confirmModalCustom').toggle(true);
    }

    validarEliminar(){
        this.getField('confirmModalCustom').toggle(false);
        let datos = { datos: {
            articulo_id:this.idEliminar
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-articulodespiece', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successEliminar(data){
        if(data.estado_p === 200){
            this.limpiarCampos2();
            this.seleccionarProductos();
        }
    }


}
FormJaivana.addController("maes-articulodespiece",CustomAdministracionProductosDespiece);
export default CustomAdministracionProductosDespiece