import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAnalisisCostoPromedioCostoTradicional
 * @author: Patricia Lopez Sanchez y Anderson Acevedo
 * @version: jdesk_1.01.0002
 **/
class CustomAnalisisCostoPromedioCostoTradicional extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                   = this.initForm.bind(this);
        this.valor_inventario                           = this.valor_inventario.bind(this);
        this.successValorInventario                     = this.successValorInventario.bind(this);
        this.validarfechas                              = this.validarfechas.bind(this);
        this.validarcampo                               = this.validarcampo.bind(this);
        this.gridOptionsComponentes                     = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1                    = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes2                    = Object.assign({}, this.gridOptions);
        this.currencyFormatterGeneral                   = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                          = this.formatNumberSaldo.bind(this);
        this.kardex                                     = this.kardex.bind(this);
        this.successKardex                              = this.successKardex.bind(this);
        this.kardexGlobal                               = this.kardexGlobal.bind(this);
        this.successKardexGlobal                        = this.successKardexGlobal.bind(this);
        this.CostoVentas                                = this.CostoVentas.bind(this);
        this.successCostoVentas                         = this.successCostoVentas.bind(this);
        this.Ventas                                     = this.Ventas.bind(this);
        this.successVentas                              = this.successVentas.bind(this);
        this.DevVentas                                  = this.DevVentas.bind(this);
        this.successDevVentas                           = this.successDevVentas.bind(this);
        this.Compras                                    = this.Compras.bind(this);
        this.successCompras                             = this.successCompras.bind(this);
        this.DevCompras                                 = this.DevCompras.bind(this);
        this.successDevCompras                          = this.successDevCompras.bind(this);
        this.OrdenProduccion                            = this.OrdenProduccion.bind(this);
        this.successOrdenProduccion                     = this.successOrdenProduccion.bind(this);
        this.NotasProveedor                             = this.NotasProveedor.bind(this);
        this.successNotasProveedor                      = this.successNotasProveedor.bind(this);
        this.AjustesEntrada                             = this.AjustesEntrada.bind(this);
        this.successAjustesEntrada                      = this.successAjustesEntrada.bind(this);
        this.AjustesSalida                              = this.AjustesSalida.bind(this);
        this.successAjustesSalida                       = this.successAjustesSalida.bind(this);
        this.TrasladoEntrada                            = this.TrasladoEntrada.bind(this);
        this.successTrasladoEntrada                     = this.successTrasladoEntrada.bind(this);
        this.TrasladoSalida                             = this.TrasladoSalida.bind(this);
        this.successTrasladoSalida                      = this.successTrasladoSalida.bind(this);
        this.Detalle                                    = this.Detalle.bind(this);
        this.successDetalle                             = this.successDetalle.bind(this);
        this.Auditoria                                  = this.Auditoria.bind(this);
        this.successAuditoria                           = this.successAuditoria.bind(this);
        this.resumen                                    = this.resumen.bind(this);
        this.successresumen                             = this.successresumen.bind(this);
        this.generarPdfTabla3                           = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3                         = this.generarExcelTabla3.bind(this);
        this.enviarCorreoTerceros                       = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo                        = this.successEnviarCorreo.bind(this);
        this.generarPdfTablaOrden                       = this.generarPdfTablaOrden.bind(this);
        this.generarExcelTablaOrden                     = this.generarExcelTablaOrden.bind(this);
        this.enviarCorreoTercerosOrden                  = this.enviarCorreoTercerosOrden.bind(this);
        this.generarPdfTablaAjustes                     = this.generarPdfTablaAjustes.bind(this);
        this.generarExcelTablaAjustes                   = this.generarExcelTablaAjustes.bind(this);
        this.enviarCorreoTercerosAjustes                = this.enviarCorreoTercerosAjustes.bind(this);
        this.generarPdfTablaTraslado                    = this.generarPdfTablaTraslado.bind(this);
        this.generarExcelTablaTraslado                  = this.generarExcelTablaTraslado.bind(this);
        this.enviarCorreoTraslado                       = this.enviarCorreoTraslado.bind(this);
        this.generarPdfTablaNota                        = this.generarPdfTablaNota.bind(this);
        this.generarExcelTablaNota                      = this.generarExcelTablaNota.bind(this);
        this.enviarCorreoNota                           = this.enviarCorreoNota.bind(this);
        this.generarExcelTablaDevCompras                = this.generarExcelTablaDevCompras.bind(this);
        this.generarPdfTablaNota                        = this.generarPdfTablaDevCompras.bind(this);
        this.enviarCorreoDevCompras                     = this.enviarCorreoDevCompras.bind(this);
        this.isJson                                     = this.isJson.bind(this);
        this.imprimir                                   = this.imprimir.bind(this);
        this.generarModalLista                          = this.generarModalLista.bind(this);
        this.sucessTraerDatos                           = this.sucessTraerDatos.bind(this);
        this.generarModalListaOrden                     = this.generarModalListaOrden.bind(this);
        this.sucessTraerDatosOrden                      = this.sucessTraerDatosOrden.bind(this);
        this.generarModalListaTraslado                  = this.generarModalListaTraslado.bind(this);
        this.sucessTraerDatosTraslados                  = this.sucessTraerDatosTraslados.bind(this);
        this.genearModalNota                            = this.genearModalNota.bind(this);
        this.sucessTraerDatosNota                       = this.sucessTraerDatosNota.bind(this);
        this.genearModalDevCompras                      = this.genearModalDevCompras.bind(this);
        this.sucessTraerDatosDevCompras                 = this.sucessTraerDatosDevCompras.bind(this);
        this.generarModalListaAjustes                   = this.generarModalListaAjustes.bind(this);
        this.sucessTraerDatosAjuste                     = this.sucessTraerDatosAjuste.bind(this);
        this.abrirImprimir                              = this.abrirImprimir.bind(this);
        this.habilitarCorreo                            = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                         = this.deshabilitarCorreo.bind(this);
        this.enviarInforme                              = this.enviarInforme.bind(this);
        this.successPdf                                 = this.successPdf.bind(this);
        this.boton                                      = "";
        this.selectedRows                               = [];
        this.limpiar                                    = this.limpiar.bind(this);
        this.VerDocOriginal                             = this.VerDocOriginal.bind(this);
        this.tipo2                                      = "";
        this.ver_mas                                    = 'N';
        this.boton_excel                                = "";
        this.traerinformacion                           = this.traerinformacion.bind(this);
        this.success_traerinformacion                   = this.success_traerinformacion.bind(this);
        this.nombre_archivo                             = 'kardex';
        this.archivo_operacion                          = '';
        this.sucursal_ingreso                           = '';

        this.gridOptionsComponentesModCosto             = Object.assign({}, this.gridOptions);
        this.onSelectionChangedModCosto                 = this.onSelectionChangedModCosto.bind(this);
        this.gridOptionsComponentesModCosto['onSelectionChanged'] = this.onSelectionChangedModCosto;
        this.modificarCosto                             = this.modificarCosto.bind(this);
        this.sucessTraerDatosModCosto                   = this.sucessTraerDatosModCosto.bind(this);
        this.cambiarCosto                               = this.cambiarCosto.bind(this);
        this.successCambiarCosto                        = this.successCambiarCosto.bind(this);
        this.fila_seleccionada_mod                      = [];
        this.crearTemporalKardex                        = this.crearTemporalKardex.bind(this);
        this.successcrearTemporalKardex                 = this.successcrearTemporalKardex.bind(this);
    }

    initForm() {

        console.log("Formulario CustomModificarPrecio,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez y Anderson Acevedo");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.traerinformacion();

        this.getField('cancelar').setClick(() => { this.getField('modal_valor_inventario').handleClose(); });
        this.getField('cancelar1').setClick(() => { this.getField('modal_detalle').handleClose();});
        this.getField('btn_cancelar_mod_costo').setClick(() => { this.getField('modal_modificar_costo').handleClose(); });
        this.getField('btn_cancelar_mod_costo').setClick(() => { this.getField('modal_modificar_costo').handleClose(); });
        this.getField('bt_cancelar_mod').setClick(() => { this.getField('modal_confirm_mod').handleClose(); });
        this.getField("fecha_desde").setOnChange(this.limpiar);
        this.getField("fecha_hasta").setOnChange(this.limpiar);
        this.getField("nombre_bodega").setOnChange(this.limpiar);
        this.getField("generar_excel").setDisabled(true);

        this.getField("valor_inventario").setClick(()=>{this.validarcampo();
            this.boton_excel="valor_inventario";
        });
        this.getField("kardex").setClick(()=>{
            if(this.costo_promedio === 'S'){
                this.kardex();
                this.boton_excel="kardex";
            }else{
                this.servicio = 'archivoitems_kardex';
                this.crearTemporalKardex();
            }
        });
        this.getField("kardex_global").setClick(()=>{this.kardexGlobal();
            this.boton_excel="kardex_global";
        });
        this.getField("costo_ventas").setClick(()=>{this.CostoVentas();
            this.boton_excel="costo_ventas";
        });
        this.getField("ventas").setClick(()=>{this.Ventas();
            this.boton_excel="ventas";
        });
        this.getField("dev_ventas").setClick(()=>{this.DevVentas();
            this.boton_excel="dev_ventas";
        });
        this.getField("compras").setClick(()=>{this.Compras();
            this.boton_excel="compras";
        });
        this.getField("dev_compras").setClick(()=>{this.DevCompras();
            this.boton_excel="dev_compras";
        });
        this.getField("orden_produccion").setClick(()=>{this.OrdenProduccion();
            this.boton_excel="orden_produccion";
        });
        this.getField("notas_proveedor").setClick(()=>{this.NotasProveedor();
            this.boton_excel="notas_proveedor";
        });
        this.getField("ajustes_entrada").setClick(()=>{this.AjustesEntrada();
            this.boton_excel="ajustes_entrada";
        });
        this.getField("ajustes_salida").setClick(()=>{this.AjustesSalida();
            this.boton_excel="ajustes_salida";
        });
        this.getField("traslado_entrada").setClick(()=>{this.TrasladoEntrada();
            this.boton_excel="traslado_entrada";
        });
        this.getField("traslado_salida").setClick(()=>{this.TrasladoSalida();
            this.boton_excel="traslado_salida";
        });

        this.getField("detalle").setClick(()=>{
            this.boton='detalle';
            this.ver_mas='N';
            if(this.getField("bodega").valid() && this.getField("nombre_bodega").valid()){
                this.getField("nombre_articulo").setError(false,"");
                this.getField('modal_detalle').handleClickOpen();
            }
        });
        this.getField("ver_mas").setClick(()=>{
            if(this.selectedRows.length > 0){
                this.ver_mas='S';
                this.Detalle();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
            }
        });
        this.getField("ver_doc_original").setClick(()=>{
            if(this.selectedRows.length > 0){
                this.VerDocOriginal();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
            }
        });
        this.getField("auditoria").setClick(()=>{this.Auditoria();
            this.boton_excel="auditoria";
        });
        this.getField("composicion").setClick(()=>{
            this.limpiar();
            this.servicio = 'composicion';
            this.abrirImprimir();
        });

        this.getField("aceptar").setClick(()=>{
            if(this.getField("radio_tipo").valid()){
                this.tipo= this.getField("radio_tipo").getValue();
                this.getField('modal_valor_inventario').handleClose();
                this.valor_inventario();
            }
        });

        this.getField("aceptar1").setClick(()=>{
            if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid()){
                this.Detalle();
                this.getField('modal_detalle').handleClose();
            }
        });
        
        this.getField("grupos").setClick(()=>{
            this.boton="grupo";
            this.servicio="imprimirgrusubmartip";
            this.abrirImprimir();
        });

        this.getField("subgrupos").setClick(()=>{
            this.boton="subgrupo";
            this.servicio="imprimirgrusubmartip";
            this.abrirImprimir();
        });

        this.getField("tipos").setClick(()=>{
            this.boton="tipo_producto";
            this.servicio="imprimirgrusubmartip";
            this.abrirImprimir();
        });

        this.getField("marcas").setClick(()=>{
            this.boton="marca";
            this.servicio="imprimirgrusubmartip";
            this.abrirImprimir();
        });

        this.getField('nombre_articulo').setOnChange(()=>{
            if(this.getField("nombre_articulo").valid()){
                this.nombre_articulo = this.getField("nombre_articulo").getValue();
            }
        });

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('generar_archivo').setClick(()=>this.imprimir());
        this.getField('generar_excel').setClick(()=>{
            this.servicio = 'generar_excel';
            this.generarExcelTabla3();
        });
        this.getField('resumen').setClick(()=>{this.resumen();
            this.boton_excel="resumen";
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });

        this.getField('modificar_costo').setClick(() => {
            this.archivo_operacion = 'modificar_costo_pag';
            this.fila_seleccionada_mod=[];
            this.modificarCosto();
        });

        this.getField('btn_cancelar_mod_costo').setClick(() => {
            this.getField('modal_modificar_costo').handleClose();
        });

        this.getField('btn_aceptar_mod_costo').setClick(() => {
            if(this.fila_seleccionada_mod.length>0){
                this.getField('modal_confirm_mod').setLabel('Ingrese el nuevo costo para ' + this.fila_seleccionada_mod[0].codigo_articulo + 'en el documento ' +  this.fila_seleccionada_mod[0].numero_documento + ' del ' +  this.fila_seleccionada_mod[0].fecha_documento);
                this.getField('modal_confirm_mod').handleClickOpen();
                this.getField('nuevo_valor').setValue( this.fila_seleccionada_mod[0].valor_documento);
            }
        });

        this.getField('bt_cancelar_mod').setClick(() => {
            this.getField('modal_confirm_mod').handleClose();
        });

        this.getField('bt_aceptar_mod').setClick(() => {
            this.cambiarCosto();
        });
    }

    traerinformacion(){
        let datos={ datos: {
            sucursal_ingreso:this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-analisiscostoptradicional', operacion: 'informacion', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.success_traerinformacion,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    success_traerinformacion(data){
        if (data.estado_p === 200)
        {
            this.UrlSelect = data.data.UrlSelect;
            this.costeo_resumen_por_servicio = data.data.costeo_resumen_por_servicio;
            this.costo_promedio_DC = data.data.costo_promedio_DC;
            this.costeo_kardex_por_servicio = data.data.costeo_kardex_por_servicio;
            this.maneja_bodega = data.data.maneja_bodega;
            this.costo_promedio = data.data.log_costos;

            if(this.costo_promedio === 'S'){
                this.getField("modificar_costo").setVisible(false);
            }else{
                if(data.data.modificar_precio === 'S'){
                    this.getField("modificar_costo").setDisabled(false);
                }else{
                    this.getField("modificar_costo").setDisabled(true);
                }
                this.getField("kardex_global").setDisabled(true);
                this.getField("auditoria").setDisabled(true);
                this.getField("composicion").setDisabled(true);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else 
        {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }


    validarfechas(){
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("fecha_desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("fecha_hasta").getValue()).toISOString().split('T')[0];

        this.getField("fecha_desde").setError(false,"");
        this.getField("fecha_hasta").setError(false,"");
        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }

        if(fecha_hasta > fecha_actual){
            this.getField("fecha_hasta").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }

        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }
        this.fecha_actual = fecha_hasta;
        this.fecha_desde = fecha_desde;
        
        return true;

    }

    validarcampo(){
        this.limpiar();
        this.getField('bodega').setError(false,'');
        this.getField('nombre_bodega').setError(false,'');
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(this.costo_promedio==='S' && (!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid())){
            this.errores=1
        }
        else if(this.costo_promedio!=='S' && this.getField("bodega").getValue()!==''  &&( !this.getField("bodega").valid() || !this.getField("nombre_bodega").valid())){
            this.errores=1
        }
        
        if(this.errores === 0){
            if(this.costo_promedio==='S' || (this.getField("bodega").valid()  && this.getField("nombre_bodega").valid()))
                this.getField('modal_valor_inventario').handleClickOpen();
            else
                this.valor_inventario();
        }
    }

    valor_inventario(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            bodega:this.getField('bodega').getValue(),
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            tipo: this.getField('bodega').getValue()!=='' && this.getField('nombre_bodega').getValue()!==''?this.tipo:0,
            codigo_usuario:this.codigo_usuario
        }};

        this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-valor_inventario");
        this.getField("rejilla_varios").sendRequest(this.successValorInventario, datos);
    }

    successValorInventario(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {

            let configCell = new Map();

            if(this.tipo === 1){    
                configCell.set('precio1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.precio1) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('costopro_despues', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costopro_despues) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('saldo_despues', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_despues) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valorizado', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valorizado) }, cellStyle: {textAlign:"right"},width: 110});
            
                this.gridOptionsComponentes2['rowData'] = data.data;
                this.getField("rejilla_varios").toggle(true);
                this.getField("rejilla_varios").initData(this.gridOptionsComponentes2, configCell);
            }else{
                configCell.set('costo_real', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_real) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valorizado', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valorizado) }, cellStyle: {textAlign:"right"},width: 110});
                
                this.gridOptionsComponentes2['rowData'] = data.data;
                this.getField('rejilla_varios').toggle(true);
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes2, configCell);
            }

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_doc_original').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
                
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }
        }else {
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    kardex(){
        this.limpiar();
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            bodega:this.getField('bodega').getValue(),
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            codigo_usuario:this.codigo_usuario,
            costeo_kardex_por_servicio:this.costeo_kardex_por_servicio,
            UrlSelect:this.UrlSelect
        }};

        this.getField("rejilla").setTipoServicioOperacion("inve-analisiscostoptradicional-kardex");
        this.getField("rejilla").sendRequest(this.successKardex, datos);
    }

    successKardex(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {

            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla').toggle(true);
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_doc_original').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(false);
                this.getField('subgrupos').setDisabled(false);
                this.getField('marcas').setDisabled(false);
                this.getField('tipos').setDisabled(false);
            }else{
                this.getField('ver_mas').setVisible(false);
                this.getField('ver_mas').setDisabled(false);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    kardexGlobal(){
        this.limpiar();
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            bodega:this.getField('bodega').getValue(),
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            usuario:this.codigo_usuario
        }};

        this.getField("rejilla").setTipoServicioOperacion("inve-analisiscostoptradicional-kardex_global");
        this.getField("rejilla").sendRequest(this.successKardexGlobal, datos);
    }

    successKardexGlobal(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            let configCell = new Map();
            
            configCell.set('cn1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn1) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn1) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn2) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn2) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn3', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn3) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn3', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn3) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn4', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn4) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn4', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn4) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn5', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn5) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn5', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn5) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn6', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn6) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn6', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn6) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn7', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn7) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn7', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn7) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn8', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn8) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn8', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn8) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn9', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn9) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn9', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn9) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn10', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn10) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn10', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn10) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn11', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn11) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn11', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn11) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cn12', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cn12) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vn12', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vn12) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.gridOptionsComponentes1['rowData'] = data.data;
            this.getField('rejilla').toggle(true);
            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);
            this.getField("generar_excel").setDisabled(false);

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    
    CostoVentas(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_costo_ventas").setTipoServicioOperacion("inve-analisiscostoptradicional-costo_ventas");
            this.getField("rejilla_costo_ventas").sendRequest(this.successCostoVentas, datos);
        }
    }

    successCostoVentas(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            let configCell = new Map();
            configCell.set('xcosto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xcosto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xventa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xventa) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('utilidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.utilidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('rentabilidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.rentabilidad) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla_costo_ventas').toggle(true);
            this.getField('rejilla_costo_ventas').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_doc_original').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_costo_ventas').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_costo_ventas').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    Ventas(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-ventas");
            this.getField("rejilla_varios").sendRequest(this.successVentas, datos);
        }
    }

    successVentas(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="ventas";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };

            configCell.set('valor_documento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_documento) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo_real', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_real) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('descuento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.descuento) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('rentabilidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.rentabilidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('utilidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.utilidad) }, cellStyle: {textAlign:"right"},width: 110});
        
            this.getField('rejilla_varios').toggle(true);
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(true)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }
        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    DevVentas(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-dev_ventas");
            this.getField("rejilla_varios").sendRequest(this.successDevVentas, datos);
        }
    }

    successDevVentas(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="deVventas";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };

            configCell.set('valor_documento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_documento) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo_real', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_real) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('rentabilidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.rentabilidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('rejilla_varios').toggle(true);
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(true)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    Compras(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-compras");
            this.getField("rejilla_varios").sendRequest(this.successCompras, datos);
        }
    }

    successCompras(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="compras";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };
            configCell.set('valor_documento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_documento) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xtotal) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('rejilla_varios').toggle(true);
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    DevCompras(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-dev_compras");
            this.getField("rejilla_varios").sendRequest(this.successDevCompras, datos);
        }
    }

    successDevCompras(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="devcompras";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };
            configCell.set('valor_documento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_documento) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('nuevo_costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.nuevo_costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_total) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('rejilla_varios').toggle(true);
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }
        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    OrdenProduccion(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-orden_produccion");
            this.getField("rejilla_varios").sendRequest(this.successOrdenProduccion, datos);
        }
    }

    successOrdenProduccion(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="ordenproduccion";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };

            configCell.set('costo_unitario', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_unitario) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costopro_despues', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costopro_despues) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xtotal) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xcosto_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xcosto_total) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('rejilla_varios').toggle(true);
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }
        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    NotasProveedor(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla").setTipoServicioOperacion("inve-analisiscostoptradicional-notas_proveedor");
            this.getField("rejilla").sendRequest(this.successNotasProveedor, datos);
        }
    }

    successNotasProveedor(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="notasprov";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };
            this.getField('rejilla').toggle(true);
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    AjustesEntrada(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-ajustes_entrada");
            this.getField("rejilla_varios").sendRequest(this.successAjustesEntrada, datos);
        }
    }

    successAjustesEntrada(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="ajusteEntrada";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };

            configCell.set('valor_documento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_documento) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xtotal) }, cellStyle: {textAlign:"right"},width: 110});
           
            this.getField('rejilla_varios').toggle(true);
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    AjustesSalida(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-ajustes_salida");
            this.getField("rejilla_varios").sendRequest(this.successAjustesSalida, datos);
        }
    }

    successAjustesSalida(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="AjusteSalida";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };

            configCell.set('costo_real', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_real) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xtotal) }, cellStyle: {textAlign:"right"},width: 110});
           
            this.getField('rejilla_varios').toggle(true);
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }
        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    TrasladoEntrada(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-traslado_entrada");
            this.getField("rejilla_varios").sendRequest(this.successTrasladoEntrada, datos);
        }
    }

    successTrasladoEntrada(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="TrasEntrada";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };

            configCell.set('valor_documento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_documento) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xtotal) }, cellStyle: {textAlign:"right"},width: 110});
          
            this.getField('rejilla_varios').toggle(true);
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }
        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    TrasladoSalida(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }else if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                bodega:this.getField('bodega').getValue(),
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                usuario:this.codigo_usuario,
                costo_promedio:this.costo_promedio
            }};

            this.getField("rejilla_varios").setTipoServicioOperacion("inve-analisiscostoptradicional-traslado_salida");
            this.getField("rejilla_varios").sendRequest(this.successTrasladoSalida, datos);
        }
    }

    successTrasladoSalida(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.boton="TrasSalida";
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };

            configCell.set('costo_real', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_real) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xtotal) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_varios').toggle(true);
            this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }
        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_varios').initData(this.gridOptionsComponentes, configCell);
                this.getField('rejilla_varios').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    
    Detalle(){
        if(this.ver_mas !== 'S'){
            this.limpiar();
        }

        this.mostrarMensajeGenerando();
        let datos={ datos: {
            bodega:this.boton==="detalle" ?this.getField('bodega').getValue() : this.selectedRows[0].bodega,
            codigo_articulo:this.boton==="detalle" ? this.getField('codigo_articulo').getValue() : this.selectedRows[0].codigo_articulo,
            costo_promedio:this.costo_promedio
        }};

        this.getField("rejilla_detalle").setTipoServicioOperacion("inve-analisiscostoptradicional-detalle");
        this.getField("rejilla_detalle").sendRequest(this.successDetalle, datos);
    }

    successDetalle(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            this.getField('modal_detallado').handleClickOpen();

            this.getField('articulo').setValue(this.boton==="detalle"?this.nombre_articulo:this.selectedRows[0].nombre);
            let configCell = new Map();
            configCell.set('costo_unitario', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_unitario) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('saldo_antes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_antes) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('saldo_despues', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_despues) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costopro_antes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costopro_antes) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costopro_despues', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costopro_despues) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.gridOptionsComponentes1['rowData'] = data.data;
            this.getField('rejilla_detalle').toggle(true);
            this.getField('rejilla_detalle').initData(this.gridOptionsComponentes1, configCell);
            
            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes1['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_detalle').initData(this.gridOptionsComponentes1, configCell);
                this.getField('rejilla_detalle').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    Auditoria(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                costo_promedio:this.costo_promedio
            }};
    
            this.getField("rejilla").setTipoServicioOperacion("inve-analisiscostoptradicional-auditoria");
            this.getField("rejilla").sendRequest(this.successAuditoria, datos);
        }
    }

    successAuditoria(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data;

            this.getField('rejilla').toggle(true);
            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);

            if(this.costo_promedio === 'S'){
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_doc_original').setVisible(true);
                this.getField('ver_mas').setDisabled(false);
                this.getField('ver_doc_original').setDisabled(false)
                this.getField("generar_excel").setDisabled(false);
                this.getField('grupos').setVisible(true);
                this.getField('subgrupos').setVisible(true);
                this.getField('marcas').setVisible(true);
                this.getField('tipos').setVisible(true);
                this.getField('grupos').setDisabled(true);
                this.getField('subgrupos').setDisabled(true);
                this.getField('marcas').setDisabled(true);
                this.getField('tipos').setDisabled(true);
            }else{
                this.getField('ver_mas').setVisible(true);
                this.getField('ver_mas').setDisabled(true);
                this.getField("generar_excel").setDisabled(false);
                this.getField("ver_doc_original").setVisible(false);
                this.getField("grupos").setVisible(false);
                this.getField("subgrupos").setVisible(false);
                this.getField("marcas").setVisible(false);
                this.getField("tipos").setVisible(false);
            }

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes1['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_detalle').initData(this.gridOptionsComponentes1, configCell);
                this.getField('rejilla_detalle').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    resumen(){
        this.limpiar();
        this.errores=0;
        if(this.validarfechas()===false){
            this.errores=1
        }
        if(this.costo_promedio === "N"){
            if(!this.getField("bodega").valid() || !this.getField("nombre_bodega").valid()){
                this.errores=1
            }
        }
        
        if(this.errores === 0){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                bodega:this.getField('bodega').getValue(),
                codigo_usuario:this.codigo_usuario,
                costeo_resumen_por_servicio:this.costeo_resumen_por_servicio,
                UrlSelect:this.UrlSelect,
                costo_promedio_DC:this.costo_promedio_DC,
                sucursal_ingreso:this.codigo_sucursal,
                costo_promedio:this.costo_promedio
            }};
    
            this.getField("rejilla_otros").setTipoServicioOperacion("inve-analisiscostoptradicional-resumen");
            this.getField("rejilla_otros").sendRequest(this.successresumen, datos);
        }
    }

    successresumen(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200 && data.data.length > 0)
        {
            let configCell = new Map();

            configCell.set('debitos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.debitos) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('creditos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.creditos) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('parcial', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.parcial) }, cellStyle: {textAlign:"right"},width: 110});

            this.gridOptionsComponentes1['rowData'] = data.data;
            this.getField('rejilla_otros').toggle(true);
            this.getField('rejilla_otros').initData(this.gridOptionsComponentes1, configCell);
            this.getField("generar_excel").setDisabled(false);

        }else {
            this.mostrarMensajeNoHayDatos();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.gridOptionsComponentes1['rowData'] = [];
                let configCell = new Map();
                this.getField('rejilla_detalle').initData(this.gridOptionsComponentes1, configCell);
                this.getField('rejilla_detalle').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    limpiar(){
        this.getField('rejilla_detalle').toggle(false);
        this.getField('rejilla').toggle(false);
        this.getField('rejilla_varios').toggle(false);
        this.getField('rejilla_costo_ventas').toggle(false);
        this.getField('rejilla_otros').toggle(false);

        this.getField('ver_mas').setVisible(false);
        this.getField('ver_doc_original').setVisible(false);
        this.getField('grupos').setVisible(false);
        this.getField('subgrupos').setVisible(false);
        this.getField('marcas').setVisible(false);
        this.getField('tipos').setVisible(false);
    }

    VerDocOriginal(){
        this.tipo2 = this.selectedRows[0].tipo;
        if (this.tipo2 === "V") {
            this.abrirImprimir();
        } else if (this.tipo2 === "DV") {
                this.abrirImprimir();
        } else if (this.tipo2 ===  "C") {
                this.abrirImprimir();
        } else if (this.tipo2 ===  "DC") {
                this.abrirImprimir();
        } else if (this.tipo2 === "AJ") {
                this.abrirImprimir();
        } else if (this.tipo2 ===  "TR" || this.tipo2 ===  "TRE" || this.tipo2 ===  "TRS") {
                this.abrirImprimir();
        } else if (this.tipo2 ===  "OP") {
            this.abrirImprimir();
        } else if (this.tipo2 ===  "ND") {
                this.abrirImprimir();
        } else if (this.tipo2 ===  "REM"){
                this.abrirImprimir();
        }
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    imprimir(){
        let errores =0;
        if(this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    if(this.tipo2 === 'OP'){
                        this.enviarCorreoTercerosOrden();
                    }else if(this.tipo2 === 'AJ'){
                        this.enviarCorreoTercerosAjustes();
                    }else if(this.tipo2 === 'TR'){                       
                        this.enviarCorreoTraslado();
                    }else if(this.tipo2 === 'ND'){                       
                        this.enviarCorreoNota();
                    }else if(this.tipo2 === 'DC'){                       
                        this.enviarCorreoDevCompras();
                    }else if(this.tipo2 === 'C'){                       
                        this.enviarInforme();
                    }else{
                        this.enviarCorreoTerceros();
                    }
                }else{
                    if(this.operacion_actual==='pdf'){
                        if(this.tipo2 === 'OP'){
                            this.generarPdfTablaOrden();
                        }else if(this.tipo2 === 'AJ'){
                            this.generarPdfTablaAjustes();
                        }else if(this.tipo2 === 'TR'){
                            this.generarPdfTablaTraslado();
                        }else if(this.tipo2 === 'ND'){                       
                            this.generarPdfTablaNota();
                        }else if(this.tipo2 === 'DC'){                       
                            this.generarPdfTablaDevCompras();
                        }else if(this.tipo2 === 'C'){                       
                            this.enviarInforme();
                        }else{
                            this.generarPdfTabla3();
                        }
                    }else if(this.operacion_actual==='pantalla'){
                        if(this.tipo2 === 'OP'){
                            this.generarModalListaOrden();
                        }else if(this.tipo2 === 'AJ'){
                            this.generarModalListaAjustes();
                        }else if(this.tipo2 === 'TR'){
                            this.generarModalListaTraslado();
                        }else if(this.tipo2 === 'ND'){                       
                            this.genearModalNota();
                        }else if(this.tipo2 === 'DC'){                       
                            this.genearModalDevCompras();
                        }else if(this.tipo2 === 'C'){                       
                            this.enviarInforme();
                        }else{
                            this.generarModalLista();
                        }
                    }else if(this.operacion_actual==='excel'){
                        if(this.tipo2 === 'OP'){
                            this.generarExcelTablaOrden();
                        }else if(this.tipo2 === 'AJ'){
                            this.generarExcelTablaAjustes();
                        }else if(this.tipo2 === 'TR'){
                            this.generarExcelTablaTraslado();
                        }else if(this.tipo2 === 'ND'){                       
                            this.generarExcelTablaNota();
                        }else if(this.tipo2 === 'DC'){                       
                            this.generarExcelTablaDevCompras();
                        }else if(this.tipo2 === 'C'){                       
                            this.enviarInforme();
                        }else{
                            this.generarExcelTabla3();
                        }
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos = {}
        if(this.servicio === 'composicion'){
            datos = { datos : { 
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            }};
        }else if(this.servicio === "imprimirgrusubmartip"){
            datos = { datos : { 
                bodega:this.getField('bodega').getValue(),
                codigo_usuario:this.codigo_usuario,
                boton:this.boton,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            }};
        }else if(this.servicio === "archivoitems_kardex"){
            datos = {
                datos: {
                    sucursal_ingreso: this.sucursal_ingreso,
                    operacion: this.operacion_actual,
                    email: this.getField('email').getValue(),
                    radio_correo: this.getField('radio_correo').getValue(),
                }
            }
        }

        this.generalFormatPmv = { tipo_servicio: 'inve-analisiscostoptradicional', operacion: this.servicio+"_pag", operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla3()
    {       
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2;
        if(this.servicio === 'composicion'){
            datos2 = { 
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            };
        }else if(this.servicio === "imprimirgrusubmartip"){
            datos2 = { 
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                bodega:this.getField('bodega').getValue(),
                codigo_usuario:this.codigo_usuario,
                boton:this.boton,
                operacion: this.operacion_actual,
                codigo_sucursal:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            };
        }else if(this.servicio === "archivoitems_kardex"){
            datos2 = {
                sucursal_ingreso: this.codigo_sucursal,
                operacion: this.operacion_actual,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
            }
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-analisiscostoptradicional","operacion":"'+this.servicio+'_pag","operacion_tipo":"consulta"}}';
         fetch(url+datos,{
            
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2;
        if(this.servicio === 'composicion'){
            datos2 = { 
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            };
        }else if(this.servicio === "imprimirgrusubmartip"){
            datos2 = { 
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                bodega:this.getField('bodega').getValue(),
                codigo_usuario:this.codigo_usuario,
                boton:this.boton,
                operacion: this.operacion_actual,
                codigo_sucursal:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            };
        }else if(this.servicio === "generar_excel"){
            datos2 = { 
                fecha_desde:this.getField("fecha_desde").getValue(),
                fecha_hasta:this.getField("fecha_hasta").getValue(),
                codigo_usuario:this.codigo_usuario,
                boton_excel : this.boton_excel,
                tipo: this.tipo,
                bodega: this.getField("bodega").getValue(),
                costo_promedio:this.costo_promedio
            };
        }else if(this.servicio === "archivoitems_kardex"){
            datos2 = {
                sucursal_ingreso: this.codigo_sucursal,
                operacion: this.operacion_actual,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
            }
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-analisiscostoptradicional","operacion":"'+this.servicio+'_pag","operacion_tipo":"consulta"}}';
        
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'informe.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarModalLista(){
        this.mostrarMensajeGenerando();
        if(this.servicio === 'composicion'){
            let datos = { datos : { 
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_usuario:this.codigo_usuario,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            }};
            this.getField("tabla_consultar").setTipoServicioOperacion("inve-analisiscostoptradicional-"+this.servicio);
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatos, datos);

        }else if(this.servicio === "archivoitems_kardex"){
            let datos = {
                datos: {
                    sucursal_ingreso: this.codigo_sucursal,
                    operacion: this.operacion_actual,
                    radio_correo: this.getField('radio_correo').getValue(),
                }
            }
            this.getField("tabla_consultar").setTipoServicioOperacion('inve-analisiscostoptradicional-' + this.servicio);
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatos, datos);
        }else {
            let datos = { datos : { 
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                bodega:this.getField('bodega').getValue(),
                codigo_usuario:this.codigo_usuario,
                boton:this.boton,
                operacion: this.operacion_actual,
                sucursal_ingreso:this.codigo_sucursal,
                email: this.getField('email').getValue(),
            }};

            this.getField("tabla_consultar").setTipoServicioOperacion("inve-analisiscostoptradicional-"+this.servicio);
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatos, datos);
        }
    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista = data.data.datos_lista;
            let data2 =
            {
                "empresa": this.lista.empresa,
                "nit": this.lista.nit,
                "direccion": this.lista.direccion,
                "pbx": this.lista.pbx,
                "nit_xml": this.lista.nit_xml,
                "titulo": this.lista.titulo,
                "telefono": this.lista.telefono,
                "ciudad": this.lista.ciudad,
                "razon_social": this.lista.razon_social
            };

            datosFicha.push(data2);
            this.getField('lista_datos_general').setItemsFichas(datosFicha);

            this.getField('tabla_consultar').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data.datos_items;

            if(this.servicio=== "composicion"){
                configCell.set('antes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.antes) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Valor Anterior'});
                configCell.set('entradas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.entradas) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Valor Entradas'});
                configCell.set('salidas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.salidas) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Valor Salidas'});
                configCell.set('saldo_valorizado', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_valorizado) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Valor Saldo'});
                configCell.set('saldo_metros', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_metros) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Cantidad Saldo'});
                configCell.set('xpeso', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xpeso) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Peso (Kilos)'});
                
                this.getField('antes').setValue(this.lista.antes.toFixed(2));
                this.getField('entradas').setValue(this.lista.entradas.toFixed(2));
                this.getField('salidas').setValue(this.lista.salidas.toFixed(2));
                this.getField('saldo_valorizado').setValue(this.lista.saldo_valorizado.toFixed(2));
                
                this.getField('antes').setVisible(true);
                this.getField('entradas').setVisible(true);
                this.getField('salidas').setVisible(true);
                this.getField('saldo_valorizado').setVisible(true);

            }else if(this.servicio === "archivoitems_kardex"){

                this.getField('modal_general').setLabel('LISTADO KARDEX');
                this.getField('label_6').setVisible(false);

                configCell.set('cantidad_antes', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad_antes) }, cellStyle: { textAlign: "right" }, width: 110 });
                configCell.set('saldo_antes', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo_antes) }, cellStyle: { textAlign: "right" }, width: 110 });
                configCell.set('cantidad_entradas', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad_entradas) }, cellStyle: { textAlign: "right" }, width: 110 });
                configCell.set('saldo_entradas', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo_entradas) }, cellStyle: { textAlign: "right" }, width: 110 });
                configCell.set('cantidad_salidas', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad_salidas) }, cellStyle: { textAlign: "right" }, width: 110 });
                configCell.set('saldo_salidas', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo_salidas) }, cellStyle: { textAlign: "right" }, width: 110 });
                configCell.set('cantidad_saldo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad_saldo) }, cellStyle: { textAlign: "right" }, width: 110 });
                configCell.set('saldo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: { textAlign: "right" }, width: 110 });

            }else{
                configCell.set('antes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.antes) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_antes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_antes) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('entradas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.entradas) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_entradas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_entradas) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('salidas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.salidas) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_salidas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_salidas) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_saldo) }, cellStyle: {textAlign:"right"},width: 110});
                
                this.getField('antes').setValue(this.lista.antes.toFixed(2));
                this.getField('entradas').setValue(this.lista.entradas.toFixed(2));
                this.getField('salidas').setValue(this.lista.salidas.toFixed(2));
                this.getField('saldo_valorizado').setValue(this.lista.saldo.toFixed(2));
                
                this.getField('valor_antes').setValue(this.lista.valor_antes.toFixed(2));
                this.getField('valor_entradas').setValue(this.lista.valor_entradas.toFixed(2));
                this.getField('valor_salidas').setValue(this.lista.valor_salidas.toFixed(2));
                this.getField('valor_saldo').setValue(this.lista.valor_saldo.toFixed(2));
                
                this.getField('valor_antes').setVisible(true);
                this.getField('valor_entradas').setVisible(true);
                this.getField('valor_salidas').setVisible(true);
                this.getField('valor_saldo').setVisible(true);
                this.getField('antes').setVisible(true);
                this.getField('entradas').setVisible(true);
                this.getField('salidas').setVisible(true);
                this.getField('saldo_valorizado').setVisible(true);
            }
            this.getField('tabla_consultar').initData(this.gridOptionsComponentes, configCell);
            this.getField('modal_general').handleClickOpen();

        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    //////////////////////////////////ORDEN///////////////////////////////////////////////////////////////
    
    enviarCorreoTercerosOrden(){
        this.mostrarMensajeGenerando();
        let datos = {};
        this.op='P';
        
        this.sucursal=this.selectedRows[0].sucursal;
        this.numero=this.selectedRows[0].numero;
        this.producto=this.selectedRows[0].codigo_articulo;
        this.fecha=this.selectedRows[0].fecha;
        if(this.op=== 'P'){
            datos={datos: { 
                codigo_sucursal: this.sucursal,
                sucursal:this.sucursal_ingreso,
                fecha: this.fecha,
                numero: this.numero,
                producto:this.producto,
                operacion: this.operacion_actual,
                estado:9,
                op:this.op,
                email:this.getField('email').getValue(),
                radio_correo:this.getField('radio_correo').getValue(),
            }};
        }
        this.generalFormatPmv = { tipo_servicio: 'inve-consultarordenproduccion', operacion: "imprimir_pag", operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    generarPdfTablaOrden()
    {       
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 = {};
        this.op='P';
        this.sucursal=this.selectedRows[0].sucursal;
        this.numero=this.selectedRows[0].numero;
        this.producto=this.selectedRows[0].codigo_articulo;
        this.fecha=this.selectedRows[0].fecha;
        if(this.op=== 'P'){
            datos2={ 
                codigo_sucursal: this.sucursal,
                sucursal:this.sucursal_ingreso,
                fecha: this.fecha,
                numero: this.numero,
                producto:this.producto,
                operacion: this.operacion_actual,
                estado:9,
                op:this.op
            };
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultarordenproduccion","operacion":"imprimir_pag","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close(); 
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], 'ordenproduccion+.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarExcelTablaOrden()
    {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 = {};
        this.op='P';
        this.sucursal=this.selectedRows[0].sucursal;
        this.numero=this.selectedRows[0].numero;
        this.producto=this.selectedRows[0].codigo_articulo;
        this.fecha=this.selectedRows[0].fecha;
        if(this.op=== 'P'){
            datos2= { 
                codigo_sucursal: this.sucursal,
                fecha: this.fecha,
                sucursal:this.sucursal_ingreso,
                numero: this.numero,
                producto:this.producto,
                operacion: this.operacion_actual,
                estado:9,
                op:this.op
            };
        }
        
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultarordenproduccion","operacion":"imprimir_pag","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'ordenproduccion.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarModalListaOrden(){
        this.mostrarMensajeGenerando();
        let datos = {};
        this.op='P';
        if(this.op=== 'P'){
            datos={datos: { 
                codigo_sucursal: this.selectedRows[0].sucursal,
                sucursal:this.codigo_sucursal,
                fecha: this.selectedRows[0].fecha,
                numero: this.selectedRows[0].numero,
                producto:this.selectedRows[0].codigo_articulo,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op
            }};

            this.getField("tabla_consultar").setTipoServicioOperacion("inve-consultarordenproduccion-imprimir");
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatosOrden, datos);
        }        
    }

    sucessTraerDatosOrden(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.op = 'P';
            if(this.op==='P'){
                let datosFicha = [];
                this.lista=data.data.datos_lista;
                let cantidad = this.currencyFormatterGeneral(Number(this.lista.cantidad).toFixed(2));
                let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "sucursal": this.lista.sucursal,
                    "direccion":this.lista.direccion,
                    "orden_de_produccion_numero": this.lista.orden_numero,
                    "fecha_orden": this.lista.fecha,
                    "detalle": this.lista.detalle,
                    "articulo_a_producir": this.lista.producto,
                    "cantidad_a_producir": cantidad,
                };
                datosFicha.push(data2);
                console.log(datosFicha)
                this.getField('lista_datos_general2').setItemsFichas(datosFicha);
                let configCell = new Map();
                this.getField('tabla_consultar').toggle(true);
    
                this.gridOptionsComponentes['rowData'] = [];
                this.gridOptionsComponentes['rowData'] = data.data.datos_items;
                this.getField('total_valor').setVisible(true);
                this.getField('total_valor').setValue(this.currencyFormatterGeneral(Number(this.lista.costo_total).toFixed(2)));
    
                configCell.set('cantidad_por_unidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_por_unidad.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('factor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.factor.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad_presupuestada', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_presupuestada.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110,headerName: 'Cantidad_total'});
                configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('costo_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_item.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                this.getField('tabla_consultar').initData(this.gridOptionsComponentes,configCell);
            }
            this.getField('modal_general').handleClickOpen();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    /////////////////////////////////////////////////////////////////// AJUSTES //////////////////////////////////////////////////
    generarPdfTablaAjustes(){

        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let op='';
        let datos2={}
        op='detalleconsultajuste';
        datos2.numero=this.selectedRows[0].numero;
        datos2.codigo_sucursal=this.selectedRows[0].sucursal;
        datos2.fecha=this.selectedRows[0].fecha;
        datos2.codigo_concepto_ajuste=this.selectedRows[0].concepto+'';
        datos2.sucursal_ingreso=this.codigo_sucursal;
        datos2.operacion=this.operacion_actual;
        datos2.email=this.getField('email').getValue();
        datos2.radio_correo=this.getField('radio_correo').getValue();
        this.nombre_archivo='ajustes';

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-ajustesinventario","operacion":"'+op+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarExcelTablaAjustes()
    {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let op='';
        let datos2={}
        op='detalleconsultajuste';
        datos2.numero=this.selectedRows[0].numero;
        datos2.codigo_sucursal=this.selectedRows[0].sucursal;
        datos2.fecha=this.selectedRows[0].fecha;
        datos2.codigo_concepto_ajuste=this.selectedRows[0].concepto+'';
        datos2.sucursal_ingreso=this.codigo_sucursal;
        datos2.operacion=this.operacion_actual;
        datos2.email=this.getField('email').getValue();
        datos2.radio_correo=this.getField('radio_correo').getValue();
        this.nombre_archivo='ajustes';

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-ajustesinventario","operacion":"'+op+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = this.nombre_archivo+'.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    enviarCorreoTercerosAjustes(){
        this.mostrarMensajeGenerando();
        let op='';
        let datos={ datos: {}};
        op='detalleconsultajuste';
        datos.datos.numero=this.selectedRows[0].numero;
        datos.datos.codigo_sucursal=this.selectedRows[0].sucursal;
        datos.datos.fecha=this.selectedRows[0].fecha;
        datos.datos.codigo_concepto_ajuste=this.selectedRows[0].concepto+'';
        datos.datos.sucursal_ingreso=this.codigo_sucursal;
        datos.datos.operacion=this.operacion_actual;
        datos.datos.email=this.getField('email').getValue();
        datos.datos.radio_correo=this.getField('radio_correo').getValue();
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    generarModalListaAjustes(){
        this.mostrarMensajeGenerando();
        let enviarPeticion=true;
        let op='';
        let datos={ datos: {}};
        op='detalleconsultajuste';
        datos.datos.numero=this.selectedRows[0].numero;
        datos.datos.codigo_sucursal=this.selectedRows[0].sucursal;
        datos.datos.fecha=this.selectedRows[0].fecha;
        datos.datos.codigo_concepto_ajuste=this.selectedRows[0].concepto+'';
        datos.datos.sucursal_ingreso=this.codigo_sucursal;
        datos.datos.operacion=this.operacion_actual;
        
        if(enviarPeticion){
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatosAjuste,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    sucessTraerDatosAjuste(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit":this.lista.nit,
                    "direccion": this.lista.direccion,
                    "telefono": this.lista.telefono,
                    "ciudad": this.lista.ciudad,
                    "sucursal": this.lista.sucursal,
                    "bodega": this.lista.bodega,
                    "concepto": this.lista.concepto,
                    "cliente": this.lista.cliente,
                    "proveedor": this.lista.proveedor,
                    "titulo": this.lista.titulo,
                    "fechas_consulta":this.lista.fechas_consulta,
                    "numero": this.lista.numero,
                    "fecha": this.lista.fecha,
                    "referencia": this.lista.referencia,
                    "detalle": this.lista.detalle,
                    //"opcion": (this.getField('operacion_form').getValue()!=='Grabación de Ajustes' && this.getField('operacion_form').getValue()!=='Consulta de Ajustes')?this.ch_todos_descripcion:'',

                };
            datosFicha.push(data2);
            this.getField('lista_datos_general4').setItemsFichas(datosFicha);

            let configCell = new Map();
            
            this.getField('tabla_consultas').toggle(true);
            configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) },field: 'tarifa_iva', cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.gridOptionsComponentes['rowData'] = [];
            this.gridOptionsComponentes['rowData'] = data.data.datos_items;
            this.getField('valor_exento').setVisible(true);
            this.getField('valor_grabado').setVisible(true);
            this.getField('total_iva_consultar').setVisible(true);
            this.getField('valor_total').setVisible(true);
            this.getField('valor_exento').setValue(this.currencyFormatterGeneral(this.lista.basee));
            this.getField('valor_grabado').setValue(this.currencyFormatterGeneral(this.lista.baseg));
            this.getField('total_iva_consultar').setValue(this.currencyFormatterGeneral(this.lista.iva));
            this.getField('valor_total1').setValue(this.currencyFormatterGeneral(this.lista.valor));

            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('codigo', { headerName: 'Código'});
            configCell.set('costo', { headerName: 'Subtotal'});
            configCell.set('nombre', { headerName: 'Nombre_articulo'});
            configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('tabla_consultas').initData(this.gridOptionsComponentes,configCell);

            this.getField('modal_general2').handleClickOpen();
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    //////////////////////////////////////////TRASLADO  ////////////////////////////////////////////////////////////////////////////////
    enviarCorreoTraslado(){        
        this.mostrarMensajeGenerando();
        let op='';
        let datos={ datos: { } };

        datos.datos={};
        op='consultatraslado';
        datos.datos.numero=this.selectedRows[0].numero;
        datos.datos.codigo_sucursal=this.selectedRows[0].sucursal;
        datos.datos.fecha=this.selectedRows[0].fecha;
        datos.datos.sucursal_ingreso=this.codigo_sucursal;
        datos.datos.operacion=this.operacion_actual;
        datos.datos.email=this.getField('email').getValue();
        datos.datos.radio_correo=this.getField('radio_correo').getValue();

        this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    generarPdfTablaTraslado(){

        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let op='';
        let datos2={};

        op='consultatraslado';
        datos2.numero=this.selectedRows[0].numero;
        datos2.codigo_sucursal=this.selectedRows[0].sucursal;
        datos2.fecha=this.selectedRows[0].fecha;
        datos2.sucursal_ingreso=this.codigo_sucursal;
        datos2.operacion=this.operacion_actual;
        datos2.email=this.getField('email').getValue();
        datos2.radio_correo=this.getField('radio_correo').getValue();


        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultatraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarExcelTablaTraslado(){
        
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let op='';
        let datos2={};

        op='consultatraslado';
        datos2.numero=this.selectedRows[0].numero;
        datos2.codigo_sucursal=this.selectedRows[0].sucursal;
        datos2.fecha=this.selectedRows[0].fecha;
        datos2.sucursal_ingreso=this.codigo_sucursal;
        datos2.operacion=this.operacion_actual;
        datos2.email=this.getField('email').getValue();
        datos2.radio_correo=this.getField('radio_correo').getValue();

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultatraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'traslado.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo exitosamente!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarModalListaTraslado(){

        this.mostrarMensajeGenerando();
        let op='';
        let datos={ datos: {}};
        op='consultatraslado';
        datos.datos.numero=this.selectedRows[0].numero;
        datos.datos.codigo_sucursal=this.selectedRows[0].sucursal;
        datos.datos.fecha=this.selectedRows[0].fecha;            
        datos.datos.sucursal_ingreso=this.codigo_sucursal;
        datos.datos.operacion=this.operacion_actual;
        datos.datos.email=this.getField('email').getValue();
        datos.datos.radio_correo=this.getField('radio_correo').getValue();

        this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerDatosTraslados,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    sucessTraerDatosTraslados(data){

        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.getField('modal_general1').handleClickOpen();

            let datosFicha = [];
            this.lista=data.data.datos_lista;

            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit":this.lista.nit,
                    "direccion": this.lista.direccion,
                    "telefono": this.lista.telefono,
                    "ciudad": this.lista.ciudad,
                    "fecha": this.lista.fecha,
                    "numero": this.lista.numero,                    
                    "sucursal": this.lista.sucursal,
                    "bodega_origen": this.lista.bodega_origen,  
                    "nombre_bodega_origen": this.lista.nombre_bodega_origen,  
                    "bodega_destino": this.lista.bodega_destino,  
                    "nombre_bodega_destino": this.lista.nombre_bodega_destino,                  
                    "título": this.lista.titulo,
                    "fechas_consulta":this.lista.fechas_consulta,
                    "referencia": this.lista.referencia,
                    "detalle": this.lista.detalle,

                    "opcion": this.ch_todos_descripcion

                };
            datosFicha.push(data2);
            
            this.getField('lista_datos_general3').setItemsFichas(datosFicha);

            this.getField('valor_total').setValue(this.lista.valor_total);    
            this.getField('peso_total').setValue(this.lista.peso_total);

            

            let configCell = new Map();
            
                        
            this.gridOptionsComponentes['rowData'] = [];
            this.gridOptionsComponentes['rowData'] = data.data.datos_items; 
   
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('tabla_consulta').toggle(true);  
            this.getField('tabla_consulta').initData(this.gridOptionsComponentes,configCell);
    
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    ///////////////////////////////////////////// NOTAS PROVEEDORES ////////////////////////////////////////////////////////////////

    genearModalNota(){
        this.mostrarMensajeGenerando();
        let datos={};
        let op='';
        datos={ datos: {
            xfecha: this.selectedRows[0].fecha,
            xsucursal: this.selectedRows[0].sucursal,
            xnumero:  this.selectedRows[0].numero,
            sucursal_ingreso: this.codigo_sucursal,
            operacion: this.operacion_actual,
            tipo_opcion:"notasp",
        }};
        op='archivoitems';
        
        this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatosNota,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }
  
    sucessTraerDatosNota(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit_xml": this.lista.nit_xml,
                    "numero": this.lista.numero,
                    "fecha_nota": this.lista.fecha_nota,
                    "codigo_sucursal": this.lista.codigo_sucursal,
                    "nit": this.lista.nit,
                    "nproveedor": this.lista.nproveedor,
                    "factura": this.lista.factura,
                    "fechas_consulta": this.lista.fechas_consulta,
                    "fecha_sistema": this.lista.fecha_sistema,

                };
            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            datosFicha = [];
            this.getField('lista_datos_general').setItemsFichas(datosFicha);
            this.getField('lista_datos_general2').setItemsFichas(datosFicha);

            this.getField('tabla_consultar').toggle(true);
            this.getField('modal_general').setLabel("NOTA PROVEEDOR")
            this.getField('modal_general').handleClickOpen();
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data.datos_items;
            configCell.set('descuento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.descto_articulo) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('valor_total_result').setVisible(true);
            this.getField('total_valor').setVisible(false);
            this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total));

            this.getField('tabla_consultar').initData(this.gridOptionsComponentes,configCell);
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }
  
  
    enviarCorreoNota(){
        this.mostrarMensajeGenerando();
        let datos={};
        let op='';
        datos={ datos: {
            xfecha: this.selectedRows[0].fecha,
            xsucursal: this.selectedRows[0].sucursal,
            xnumero:  this.selectedRows[0].numero,
            sucursal_ingreso: this.codigo_sucursal,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
            tipo_opcion:"notasp",
        }};
        op='archivoitems';
        this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    generarPdfTablaNota()
    {       
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let url = this.constant.formConfiguration();
        let datos2= {};
        let op='';
        datos2= {
            xfecha: this.selectedRows[0].fecha,
            xsucursal: this.selectedRows[0].sucursal,
            xnumero:  this.selectedRows[0].numero,
            sucursal_ingreso: this.codigo_sucursal,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
            tipo_opcion:"notasp",
        };
        op='archivoitems';
        
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-notasproveedores","operacion":"'+op+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], 'Notas.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarExcelTablaNota()
    {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let url = this.constant.formConfiguration();
        let datos2= {};
        let op='archivoitems';
        datos2= {
            xfecha: this.selectedRows[0].fecha,
            xsucursal: this.selectedRows[0].sucursal,
            xnumero:  this.selectedRows[0].numero,
            sucursal_ingreso: this.codigo_sucursal,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
            tipo_opcion:"notasp",
        };
        
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-notasproveedores","operacion":"'+op+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'Notas.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    /////////////////////////////////////// DEVOLUCIONES COMPRAS ///////////////////////////////////////////////////////////////////

    generarExcelTablaDevCompras()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);

            let url = this.constant.formConfiguration();
            let datos2= {
                xnumero:this.selectedRows[0].numero,
                xfecha:this.selectedRows[0].fecha,
                xsucursal:this.selectedRows[0].sucursal,
                nit : this.selectedRows[0].nit,
                sede:this.selectedRows[0].sede,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                sucursal_ingreso: this.codigo_sucursal,
                operacion: this.operacion_actual
            };

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabardevolucion","operacion":"archivoitems","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = 'DevolucionCompras.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    genearModalDevCompras(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos={datos:{
            xnumero:this.selectedRows[0].numero,
            xfecha:this.selectedRows[0].fecha,
            xsucursal:this.selectedRows[0].sucursal,
            nit : this.selectedRows[0].nit,
            sede:this.selectedRows[0].sede,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_ingreso: this.codigo_sucursal,
            operacion: this.operacion_actual
        }}
        this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'archivoitems', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatosDevCompras,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  }

  sucessTraerDatosDevCompras(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "sucursal": this.lista.sucursal,
                    "direccion": this.lista.direccion_xml,
                    "telefono": this.lista.telefono_xml,
                    "nit": this.lista.nit_xml,
                    "ciudad": this.lista.ciudad_xml,
                    "devolucion_nro": this.lista.devolucion_nro,
                    "de_la_compra_nro": this.lista.de_la_compra_nro,
                    "fecha_compra": this.lista.fecha,
                    "f_documento": this.lista.f_documento,
                    "bodega": this.lista.bodega,

                };

            datosFicha.push(data2);
            this.getField('lista_datos1').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);

            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data.datos_items;
            //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
          
            this.getField('rejilla_items_result').initData(this.gridOptionsComponentes,configCell);


            this.getField('proveedor_result').setValue(this.lista.proveedor);
            this.getField('direcccion').setValue(this.lista.direccion_nit);
            this.getField('telefono').setValue(this.lista.telefono_nit);
            this.getField('ciudad').setValue(this.lista.ciudad_nit);

            this.getField('retencion_result').setValue(this.currencyFormatterGeneral(this.lista.retencion));
            this.getField('valor_exento_result').setValue(this.currencyFormatterGeneral(this.lista.valor_exento));
            this.getField('reteiva_result').setValue(this.currencyFormatterGeneral(this.lista.reteiva));
            this.getField('valor_grabado_result').setValue(this.currencyFormatterGeneral(this.lista.grabado));

            this.getField('reteica_result').setValue(this.currencyFormatterGeneral(this.lista.retencionica));
            //this.getField('cree_result').setValue(this.currencyFormatterGeneral(this.lista.cree));
            this.getField('aproximacion_result').setValue(this.currencyFormatterGeneral(this.lista.aproximacion));
            this.getField('iva_result').setValue(this.currencyFormatterGeneral(this.lista.total_iva));
            this.getField('fletes_result').setValue(this.currencyFormatterGeneral(this.lista.fletes));
            this.getField('valor_total_result1').setValue(this.currencyFormatterGeneral(this.lista.valor_total));

            
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }


    enviarCorreoDevCompras(){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos = {datos: {
            xnumero:this.selectedRows[0].numero,
            xfecha:this.selectedRows[0].fecha,
            xsucursal:this.selectedRows[0].sucursal,
            nit : this.selectedRows[0].nit,
            sede:this.selectedRows[0].sede,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_ingreso: this.codigo_sucursal,
            operacion: this.operacion_actual
      }};
      this.generalFormatPmv = { tipo_servicio: "comp-grabardevolucion", operacion: "archivoitems", operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successEnviarCorreo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  generarPdfTablaDevCompras()
    {       
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let url = this.constant.formConfiguration();
        let datos2= {
            xnumero:this.selectedRows[0].numero,
            xfecha:this.selectedRows[0].fecha,
            xsucursal:this.selectedRows[0].sucursal,
            nit : this.selectedRows[0].nit,
            sede:this.selectedRows[0].sede,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_ingreso: this.codigo_sucursal,
            operacion: this.operacion_actual
        };

        const myJSON = JSON.stringify(datos2);
        if(this.boton==='documentos')
            this.archivo_operacion='archivodocumento_pag';
        else if(this.boton==='tipos')
            this.archivo_operacion='archivoportipoproducto_pag';
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabardevolucion","operacion":"archivoitems","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], 'DevCompras.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    ////////////////////////////////////// COMPRAS ///////////////////////////////////////////////////////////////////////////

    enviarInforme(){

        this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Por favor espere, se está generando la información!`)
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setVisibleConfirm(true); 
        this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let body = {
            datos:{
                nit: this.selectedRows[0].nit,
                sede: this.selectedRows[0].sede,
                fecha_inicial: this.getField("fecha_desde").getValue(),
                fecha_final: this.getField("fecha_hasta").getValue(),
                numero: this.selectedRows[0].numero,
                codigo_sucursal: this.selectedRows[0].sucursal,
                sucursal_usuario: this.codigo_sucursal,
                radio_correo: this.getField("radio_correo").getValue(),
                email: this.getField("email").getValue(),
                operacion_archivo: this.operacion_actual,
                campo:"",
                fecha:this.selectedRows[0].fecha,
            }
        }

        if(this.operacion_actual === "pdf" || this.operacion_actual === "excel"){
            this.enviar_correo=false;
            let url = this.constant.formConfiguration();
            
            const myJSON = JSON.stringify(body.datos);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-consultadecompras","operacion":"5_1","operacion_tipo":"crear"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.enviar_correo=true;
                            this.successPdf(JSON.parse(res));
                        }else{
                            if(this.operacion_actual === "pdf"){
                                const pdf = new File([blob], 'Compras.pdf', {
                                    type:'application/pdf'});
                                window.open(URL.createObjectURL(pdf));

                            }else{
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.style.display = 'none';
                                a.href = url;
                                // the filename you want
                                a.download = 'Compras.xlsx';
                                document.body.appendChild(a);
                                a.click();
                                window.URL.revokeObjectURL(url);
                                alert('Se descargo el archivo!');
                            }
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
            });
        }else{
            this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: '5_1', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: body,
                success: this.successPdf,
                error: this.error_,
                general: this.generalFormatPmv
            });
            
        }
    }

    successPdf(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            if(this.enviar_correo){
                this.mostrarMensajeAdicional(data.data.mensaje);
                this.enviar_correo=false;
            }else{
                if(data.data !== undefined && data.data !== null ){
                    this.gridOptionsComponentes['rowData'] = data.data[0]["items"];
                    this.gridOptionsComponentes['onSelectionChanged']= this.onSelectionChangedRowTableMov2;

                    let configCell = new Map();

                    configCell.set('xnombre',{cellRenderer:(props) => {return props.data.xnombre},field:'nombre_articulo'});
                    configCell.set('cantidad',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.cantidad)},type: 'rightAligned',field:'cantidad'});
                    configCell.set('pacas',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.pacas)},type: 'rightAligned',field:'pacas'});
                    configCell.set('tarifa_iva',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.tarifa_iva)},type: 'rightAligned',field:'tarifa_iva'});
                    configCell.set('valor_iva',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.valor_iva)},type: 'rightAligned',field:'valor_iva'});
                    configCell.set('valor_item',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.valor_item)},type: 'rightAligned',field:'valor_item'});
                    configCell.set('peso',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.peso*props.data.cantidad/1000.0)},type: 'rightAligned',field:'peso_kg'});
                    configCell.set('costo',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.costo)},type: 'rightAligned'});

                    this.getField('rejilla_compras').initData(this.gridOptionsComponentes,configCell);

                    let datosFicha = [];
                    let data2 = 
                    {                  
                        "empresa": data.data[0]["parametros"].empresa_xml,
                        "direccion": data.data[0]["parametros"].direccion_xml,
                        "telefono": data.data[0]["parametros"].telefono_xml,
                        "ciudad": data.data[0]["parametros"].ciudad_xml ,
                        "nit": data.data[0]["parametros"].nit_xml,
                        "sucursal": data.data[0]["parametros"].cadena_sucursal,
                        "proveedor": data.data[0]["parametros"].nproveedor,
                        "nit_proveedor": data.data[0]["parametros"].nit+"-"+data.data[0]["parametros"].sede,
                        "direccion_proveedor":data.data[0]["parametros"].direccion,
                        "telefono_proveedor":data.data[0]["parametros"].telefono,
                        "ciudad_proveedor":data.data[0]["parametros"].ciudad,
                        "numero_compra": data.data[0]["parametros"].numero_compra

                    };

                    datosFicha.push(data2);
                    this.getField('lista_datos2').setItemsFichas(datosFicha);


                    this.getField("reteiva").setValue("".formatoPrecio(data.data[0]["parametros"].reteiva.toFixed(this.numero_decimales_compras)));
                    this.getField("reteica").setValue("".formatoPrecio(data.data[0]["parametros"].reteica.toFixed(this.numero_decimales_compras)));
                    this.getField("fletes").setValue("".formatoPrecio(data.data[0]["parametros"].fletes.toFixed(this.numero_decimales_compras)));
                    this.getField("base_exenta").setValue("".formatoPrecio(data.data[0]["parametros"].basee.toFixed(this.numero_decimales_compras)));
                    this.getField("base_gravada").setValue("".formatoPrecio(data.data[0]["parametros"].baseg.toFixed(this.numero_decimales_compras)));
                    this.getField("cree").setValue("".formatoPrecio(data.data[0]["parametros"].cree.toFixed(this.numero_decimales_compras)));
                    this.getField("iva").setValue("".formatoPrecio(data.data[0]["parametros"].total_iva.toFixed(this.numero_decimales_compras)));
                    this.getField("aproximacion").setValue("".formatoPrecio(data.data[0]["parametros"].aproximacion.toFixed(this.numero_decimales_compras)));
                    this.getField("retencion").setValue("".formatoPrecio(data.data[0]["parametros"].retencion.toFixed(this.numero_decimales_compras)));
                    this.getField("total").setValue("".formatoPrecio(data.data[0]["parametros"].valor_total.toFixed(this.numero_decimales_compras)));

                    this.getField("fecha1").setValue(data.data[0]["parametros"].fecha1);
                    this.getField("fecha2").setValue(data.data[0]["parametros"].fecha2);
                    this.getField("fecha3").setValue(data.data[0]["parametros"].fecha3);
                    this.getField("desc1").setValue("".formatoPrecio(data.data[0]["parametros"].desc1.toFixed(this.numero_decimales_compras)));
                    this.getField("desc2").setValue("".formatoPrecio(data.data[0]["parametros"].desc2.toFixed(this.numero_decimales_compras)));
                    this.getField("desc3").setValue("".formatoPrecio(data.data[0]["parametros"].desc3.toFixed(this.numero_decimales_compras)));


                    this.getField("modal_tablas").handleClickOpen();
                }
            }
        }else{
            this.mostrarMensajeNoHayDatos();
        }
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleConfirm(false); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    cambiarCosto(){
        if(this.getField('nuevo_costo').valid()){
            this.mostrarMensajeGuardando();
            let datos = { datos: {
                oid:this.fila_seleccionada_mod[0].oid+'',
                nuevo_valor:this.getField('nuevo_valor').getValue(),
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-analisiscostoptradicional', operacion: '6_costo', operacion_tipo: 'modificar' };
            this.datos_anteriores=this.generalFormatPmv;
            this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successCambiarCosto,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
        }
    }

    successCambiarCosto(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField('modal_confirm_mod').handleClose();
            //refreescar la tabla--->
            this.getField('modal_modificar_costo').handleClose();//no se si sea necesario cerrarlo
            this.modificarCosto();
        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    onSelectionChangedModCosto() {
        this.fila_seleccionada_mod = this.gridOptionsComponentesModCosto.api.getSelectedRows();
    }


    modificarCosto() {
        if (this.selectedRows.length > 0) {
            this.mostrarMensajeGenerando();
            let datos = {
                datos: {
                    codigo_articulo: this.selectedRows[0].codigo_articulo,
                    bodega: this.selectedRows[0].bodega,
                }
            }

            this.generalFormatPmv = { tipo_servicio: 'inve-analisiscostoptradicional', operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.sucessTraerDatosModCosto,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        } else {
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro de la tabla.', "error");
        }

    }

    sucessTraerDatosModCosto(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            let configCell = new Map();
            this.getField('modal_modificar_costo').setLabel('COSTOS. Detalle del artículo ' + data.data[0].codigo_articulo + ' Bodega ' + data.data[0].bodega + '');
            this.getField('modal_modificar_costo').handleClickOpen();

            this.getField('rejilla_mod_costos').toggle(true);
            this.gridOptionsComponentesModCosto['rowData'] = [];
            this.gridOptionsComponentesModCosto['rowData'] = data.data;
            configCell.set('valor_documento', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.valor_documento) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('costo_real', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo_real) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('cantidad', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('saldo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('oid', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.oid) }, cellStyle: { textAlign: "right" }, width: 110, hide: true });

            this.getField('rejilla_mod_costos').initData(this.gridOptionsComponentesModCosto, configCell);

        } else if (data.estado_p === 404) {
            let respuesta = Object.values(data.data.data.errores);
            this.alertGeneral.toggle(true, respuesta, 'error');
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }


    //kardex

    crearTemporalKardex() {
        if (this.getField('fecha_desde').valid() && this.getField('fecha_hasta').valid() && this.getField('bodega').valid()
            && this.getField('nombre_bodega').valid()) {
            this.mostrarMensajeGenerando();
            let datos = {
                datos: {
                    fecha_desde: this.getField('fecha_desde').getValue(),
                    fecha_hasta: this.getField('fecha_hasta').getValue(),
                    bodega: this.getField('bodega').getValue(),
                }
            };


            this.generalFormatPmv = { tipo_servicio: 'inve-analisiscostoptradicional', operacion: 'crear_temporal_kardex3', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successcrearTemporalKardex,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successcrearTemporalKardex(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.abrirImprimir();
        } else if (data.estado_p === 404) {
            this.mostrarMensajeNoHayDatos();
        } else {
            let respuesta = Object.values(data.data.errores);
            let keys = Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
        }
    }
	
	mostrarMensajeGuardando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
}
FormJaivana.addController("inve-analisiscostoptradicional", CustomAnalisisCostoPromedioCostoTradicional);
export default CustomAnalisisCostoPromedioCostoTradicional;