import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomConsultarOrdenesTerminadas
 * @author: Anderson Acevedo
 * @version: jdesk_1.01.0002
 **/
class CustomConsultarOrdenesTerminadas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.consultarorden                 = this.consultarorden.bind(this);
        this.validarfechasConsulta          = this.validarfechasConsulta.bind(this);
        this.successDatosCompra             = this.successDatosCompra.bind(this);
        this.gridOptionsTbPrincipal         = Object.assign({},this.gridOptions);
        this.gridOptionsConsultarModal      = Object.assign({},this.gridOptions);
        this.limpiar                        = this.limpiar.bind(this);       
        this.currencyFormatterGeneral       = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo              = this.formatNumberSaldo.bind(this); 
        this.setButtonVerPresentacion       = this.setButtonVerPresentacion.bind(this);
        this.abrirImprimir                  = this.abrirImprimir.bind(this);
        this.verPresentacion                = this.verPresentacion.bind(this);
        this.imprimir                       = this.imprimir.bind(this);
        this.setImprimirListado             = this.setImprimirListado.bind(this);
        this.habilitarCorreo                = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo             = this.deshabilitarCorreo.bind(this);
        this.mostrarMensajeEmailEnviado     = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo       = this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeNoHayDatos       = this.mostrarMensajeNoHayDatos.bind(this);
        this.generarPdfTabla3               = this.generarPdfTabla.bind(this);
        this.generarExcelTabla3             = this.generarExcelTabla.bind(this);
        this.enviarCorreoTerceros           = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo            = this.successEnviarCorreo.bind(this);
        this.isJson                         = this.isJson.bind(this);
        this.generarModalLista              = this.generarModalLista.bind(this);
        this.sucessTraerDatos               = this.sucessTraerDatos.bind(this);
        this.limpiarTodo                    = this.limpiarTodo.bind(this);
        this.traerPropiedades =this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades =this.sucesstraerPropiedades.bind(this);
        this.numero_decimales=0;
        this.dias_maximo=0;
        this.fecha='';
        this.selectedRows = [];
        this.sucursal='';
        this.numero='';
        this.producto='';

    }

    initForm() {
        console.log("Formulario CustomAsentarOrdenesProduccion,  @version: jdesk_1.01.0002, @author: Anderson Acevedo");
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedades();
        this.estado='9';
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.getField("desde").setValue(this.fecha_inicio);
        this.getField("consultar").setClick(this.consultarorden);
        this.getField("desde").setOnChange(this.validarfechasConsulta);
        this.getField("hasta").setOnChange(this.validarfechasConsulta);
        this.getField("numero").setOnChange(()=>{this.limpiar();this.validarfechasConsulta();});
        this.getField("producto").setOnChange(()=>{this.limpiar();this.validarfechasConsulta();});
        this.getField("codigo_sucursal").setOnChange(()=>{this.limpiar();this.validarfechasConsulta()});

        this.getField('generar_archivo').setClick(()=>this.imprimir());

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });

    }

    traerPropiedades(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'inve-consultarordenproduccion', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }
    
    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales=data.data.numero_decimales;
            this.dias_maximo=data.data.dias_devolver;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    limpiarTodo(){
        this.getField("numero").setValue("");
        this.getField("producto").setValue("");
        this.getField("codigo_sucursal").setValue("");
    }

    validarfechasConsulta(){
        this.limpiar();
        let fecha_actual =new Date().toISOString().split('T')[0];
        let consultar_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];

        if(consultar_desde > consultar_hasta){
            this.getField('consultar').setDisabled(true);
            this.getField("desde").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }

        if(consultar_hasta > fecha_actual){
            this.getField('consultar').setDisabled(true);
            this.getField("hasta").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }
        
        let diferencia=new Date(consultar_hasta).getTime()-new Date(consultar_desde).getTime();
        diferencia = diferencia / 1000 / 60 / 60 / 24;
        if(diferencia >this.dias_maximo){
            this.getField("desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('consultar').setDisabled(true); 
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("desde").setError(false,"");
        }
        return true;

    }

    limpiar(){
        this.getField('consultar').setDisabled(false);
        this.gridOptionsTbPrincipal['rowData'] = [];            
        let configCell = new Map();
        this.getField('rejilla').initData(this.gridOptionsTbPrincipal,configCell);
        this.getField('rejilla').toggle(false)
    }

    consultarorden(){
        this.getField('numero').setError(false,'');
        this.getField('producto').setError(false,'');     
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('desde').setError(false,'');
        this.getField('hasta').setError(false,'');
        let errores=0;
        if(this.validarfechasConsulta()===false)
            errores++;
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
            if(!this.getField('codigo_sucursal').valid())
                errores++;
        }
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' '){
            if(!this.getField('numero').valid())
                errores++;
        }

        
        if (errores===0) {
            let datos = { datos: {
                consultar_desde:this.getField("desde").getValue(),
                consultar_hasta:this.getField("hasta").getValue(),
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                numero:this.getField("numero").getValue(),
                producto:this.getField("producto").getValue(),
                estado:this.estado,
                codigo_usuario:this.codigo_usuario
            }};
            this.mostrarMensajeGenerando();
            this.getField("rejilla").setTipoServicioOperacion("inve-consultarordenproduccion-consultar");
            this.getField("rejilla").sendRequest(this.successDatosCompra, datos);
        }

    }
    
    successDatosCompra(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('consultar').setDisabled(true);
        if(data.estado_p === 200){
            let configCell = new Map();

            configCell.set('ver_presentacion', { cellRenderer: this.setButtonVerPresentacion, width: 180, sortable: false, filter: false, field: 'ver_presentacion' });
            configCell.set('imprimir_listado', { cellRenderer: this.setImprimirListado, width: 180, sortable: false, filter: false, field: 'imprimir_listado' });
            configCell.set('cantidad_producida', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_producida.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad_presupuestada', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_presupuestada.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_total.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.gridOptionsTbPrincipal['rowData'] = data.data;   
            this.gridOptionsTbPrincipal['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsTbPrincipal.api.getSelectedRows();
                this.sucursal=this.selectedRows[0].codigo_sucursal;
                this.numero=this.selectedRows[0].numero;
                this.producto=this.selectedRows[0].producto;
                this.fecha=this.selectedRows[0].fecha_ini;
            };          
            this.getField('rejilla').initData(this.gridOptionsTbPrincipal,configCell);


        }else{
            this.mostrarMensajeNoHayDatos();
            this.getField('rejilla').toggle(false);
        }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    setButtonVerPresentacion(props) {
        let datos = props.data;
        let button = document.createElement("input");
        button.onclick = () => this.verPresentacion(datos);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "ver presentación");
        return this.createElementJaivana(button);
    }

    setImprimirListado(props) {
        let datos = props.data;
        let button = document.createElement("input");
        button.onclick = () => this.imprimir_listado(datos);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "imprimir listado");
        return this.createElementJaivana(button);
    }

    verPresentacion(datos) {
        this.op='P'
        this.datos = datos;
        this.abrirImprimir();
    }

    imprimir_listado(datos) {
        this.op='I'
        this.datos = datos;
        this.abrirImprimir();
    }

    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    imprimir(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla();
                    }else if(this.operacion_actual==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos = {};
        if(this.op=== 'P'){
            datos={datos: { 
                codigo_sucursal: this.sucursal,
                sucursal:this.sucursal_ingreso,
                fecha: this.fecha,
                numero: this.numero,
                producto:this.producto,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op,
                email:this.getField('email').getValue(),
                radio_correo:this.getField('radio_correo').getValue(),
            }};
        }else{
            datos={datos: { 
                sucursal:this.sucursal_ingreso,
                desde: this.getField('desde').getValue(),
                hasta: this.getField('hasta').getValue(),
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                numero:this.getField("numero").getValue(),
                producto:this.getField("producto").getValue(),
                codigo_usuario: this.codigo_usuario,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op,
                email:this.getField('email').getValue(),
                radio_correo:this.getField('radio_correo').getValue(),
            }};
        }
        this.generalFormatPmv = { tipo_servicio: 'inve-consultarordenproduccion', operacion: "imprimir_pag", operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla()
    {       
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 = {};
        if(this.op=== 'P'){
            datos2={ 
                codigo_sucursal: this.sucursal,
                sucursal:this.sucursal_ingreso,
                fecha: this.fecha,
                numero: this.numero,
                producto:this.producto,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op
            };
        }else{
            datos2={ 
                sucursal:this.sucursal_ingreso,
                desde: this.getField('desde').getValue(),
                hasta: this.getField('hasta').getValue(),
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                numero:this.getField("numero").getValue(),
                producto:this.getField("producto").getValue(),
                codigo_usuario: this.codigo_usuario,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op
            };
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultarordenproduccion","operacion":"imprimir_pag","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close(); 
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], 'ordenproduccion+.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla()
    {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 = {};
        if(this.op=== 'P'){
            datos2= { 
                codigo_sucursal: this.sucursal,
                fecha: this.fecha,
                sucursal:this.sucursal_ingreso,
                numero: this.numero,
                producto:this.producto,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op
            };
        }else{
            datos2={
                sucursal:this.sucursal_ingreso,
                desde: this.getField('desde').getValue(),
                hasta: this.getField('hasta').getValue(),
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                numero:this.getField("numero").getValue(),
                producto:this.getField("producto").getValue(),
                codigo_usuario: this.codigo_usuario,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op
            };
        }
        
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultarordenproduccion","operacion":"imprimir_pag","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'ordenproduccion.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarModalLista(){
        this.mostrarMensajeGenerando();
        let datos = {};
        if(this.op=== 'P'){
            datos={datos: { 
                codigo_sucursal: this.sucursal,
                sucursal:this.sucursal_ingreso,
                fecha: this.fecha,
                numero: this.numero,
                producto:this.producto,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op
            }};

            this.getField("tabla_consultar").setTipoServicioOperacion("inve-consultarordenproduccion-imprimir");
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatos, datos);
        }else{
            datos={datos: { 
                sucursal:this.sucursal_ingreso,
                desde: this.getField('desde').getValue(),
                hasta: this.getField('hasta').getValue(),
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                numero:this.getField("numero").getValue(),
                producto:this.getField("producto").getValue(),
                codigo_usuario: this.codigo_usuario,
                operacion: this.operacion_actual,
                estado:this.estado,
                op:this.op
            }};

            this.getField("tabla_listado").setTipoServicioOperacion("inve-consultarordenproduccion-imprimir");
            this.getField("tabla_listado").sendRequest(this.sucessTraerDatos, datos);
        }   
        
    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            if(this.op==='P'){
                let datosFicha = [];
                this.lista=data.data.datos_lista;
                let cantidad = this.currencyFormatterGeneral(Number(this.lista.cantidad).toFixed(2));
                let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "direccion":this.lista.direccion,
                    "sucursal": this.lista.sucursal,
                    "bodega_origen": this.lista.bodega_origen,
                    "bodega_destino": this.lista.bodega_destino,
                    "orden_de_produccion_numero": this.lista.orden_numero,
                    "fecha_orden": this.lista.fecha,
                    "detalle": this.lista.detalle,
                    "articulo_a_producir": this.lista.producto,
                    "cantidad_a_producir": cantidad,
                };
                datosFicha.push(data2);

                let datosFicha1 = [];
                this.getField('lista_datos_imprimir').setItemsFichas(datosFicha1);
                this.getField('lista_datos_general').setItemsFichas(datosFicha);
                let configCell = new Map();
                this.getField('tabla_consultar').toggle(true);
    
                this.gridOptionsConsultarModal['rowData'] = [];
                this.gridOptionsConsultarModal['rowData'] = data.data.datos_items;
                this.getField('valor_total').setVisible(false);
                this.getField('total_valor').setVisible(true);
                this.getField('total_valor').setValue(this.currencyFormatterGeneral(Number(this.lista.costo_total).toFixed(2)));
    
                configCell.set('cantidad_por_unidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_por_unidad.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('factor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.factor.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad_presupuestada', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_presupuestada.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110,headerName: 'Cantidad_total'});
                configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('costo_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_item.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                this.getField('tabla_consultar').initData(this.gridOptionsConsultarModal,configCell);
            }else{
                let datosFicha = [];
                this.lista=data.data.datos_lista;
                let data2 =
                {
                    "empresa": this.lista.empresa,
                    "nit":this.lista.nit,
                    "fecha": this.lista.fecha,
                    "titulo": this.lista.titulo,
                    "codigo_usuario": this.lista.usuario
                };
                datosFicha.push(data2);
                let datosFicha1 = [];
                this.getField('lista_datos_general').setItemsFichas(datosFicha1);
                this.getField('lista_datos_imprimir').setItemsFichas(datosFicha);
                
                let configCell = new Map();
                this.getField('tabla_listado').toggle(true);
    
                this.gridOptionsConsultarModal['rowData'] = [];
                this.gridOptionsConsultarModal['rowData'] = data.data.datos_items;
                this.getField('total_valor').setVisible(false);
                this.getField('valor_total').setVisible(true);
                this.getField('valor_total').setValue(this.currencyFormatterGeneral(Number(this.lista.valor_total).toFixed(2)));

                configCell.set('cantidad_producida', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(Math.round(props.data.cantidad_producida.toFixed(this.numero_decimales))) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('costo_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(Math.round(props.data.costo_total.toFixed(this.numero_decimales))) }, cellStyle: {textAlign:"right"},width: 110,headerName: 'Valor_orden'});
                configCell.set('cantidad_por_unidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_por_unidad.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('factor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(Math.round(props.data.factor.toFixed(this.numero_decimales))) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad_presupuestada', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(Math.round(props.data.cantidad_presupuestada.toFixed(this.numero_decimales))) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('costo_unit', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(Math.round(props.data.costo_unit.toFixed(this.numero_decimales))) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(Math.round(props.data.valor_item.toFixed(this.numero_decimales))) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(Math.round(props.data.cantidad_total.toFixed(this.numero_decimales))) }, cellStyle: {textAlign:"right"},width: 110});
                this.getField('tabla_listado').initData(this.gridOptionsConsultarModal,configCell);

            }
            this.getField('modal_general').handleClickOpen();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

}
FormJaivana.addController("inve-consultaordenesterminadas", CustomConsultarOrdenesTerminadas);
export default CustomConsultarOrdenesTerminadas;