import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomCuadrarDiferenciasDocumentoContable extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                           = this.initForm.bind(this);
        this.calcular                           = this.calcular.bind(this);
        this.successTraerDatos                  = this.successTraerDatos.bind(this);
        this.contabilizar_diferencias           = this.contabilizar_diferencias.bind(this);
        this.successcontabilizar_diferencias    = this.successcontabilizar_diferencias.bind(this);
        this.validarRangoFecha                  = this.validarRangoFecha.bind(this);
    }

    initForm(){
        console.log('Formulario CustomCuadrarDiferenciasDocumentoContable,  @version: jdesk_1.01.0001.1, @author:Patricia López Sánchez')
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField("calcular").setClick(this.calcular);
        this.getField("asentar").setClick(this.contabilizar_diferencias);
    } 

    calcular(){
        //let fecha_actual = new Date().toISOString().split('T')[0];
        if (this.getField('codigo_sucursal').valid() && this.getField('nombre_sucursal').valid() && this.getField('centro_costo_codigo').valid()
        && this.getField('centro_costo_nombre').valid() && this.getField('codigo_documento').valid() && this.getField('nombre_documento').valid()
        && this.getField('fecha_inicial').valid() && this.getField('fecha_final').valid()) {
            if(this.validarRangoFecha(this.getField('fecha_inicial').getValue(), this.getField('fecha_final').getValue())){
                let datos = { datos: {
                    codigo_documento: this.getField('codigo_documento').getValue(),
                    centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                    codigo_sucursal:this.getField('codigo_sucursal').getValue(),
                    fecha_inicial:this.getField('fecha_inicial').getValue(),
                    fecha_final:this.getField('fecha_final').getValue()
                    //fecha_inicial:fecha_actual,
                    //fecha_final:fecha_actual
                }};
            
                this.generalFormatPmv = { tipo_servicio: 'cont-cudrardifedocucontable', operacion: 'procesar', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerDatos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }else{
                this.alertGeneral.toggle(true,'El rango de fechas no debe superar los 60 dias.','error');
            }

        }
    }

    successTraerDatos(data){
        if (data.estado_p === 200 )
        {
            if(data.data[0].xcuantos === null){
                this.getField('diferencia').setValue(0);
            }else{
                this.getField('diferencia').setValue(data.data[0].xcuantos);
            }
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error', 'No hay datos');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    contabilizar_diferencias(){
        //let fecha_actual = new Date().toISOString().split('T')[0];
        if (this.getField('codigo_sucursal').valid() && this.getField('nombre_sucursal').valid() && this.getField('centro_costo_codigo').valid()
        && this.getField('centro_costo_nombre').valid() && this.getField('codigo_documento').valid() && this.getField('nombre_documento').valid()
        && this.getField('fecha_inicial').valid() && this.getField('fecha_final').valid()) {
            if(this.validarRangoFecha(this.getField('fecha_inicial').getValue(), this.getField('fecha_final').getValue())){
                this.getField('confirmModalCustom').setTitleAndContent('¡Informativo!', ' Por favor espere. Se está realizando el proceso.');
                this.getField('confirmModalCustom').setClickDialog(() => {});
                this.getField("confirmModalCustom").setVisibleCancel('.');
                this.getField("confirmModalCustom").setButtonConfirm(`.`);
                this.getField('confirmModalCustom').toggle(true)

                let datos = { datos: {
                    codigo_documento: this.getField('codigo_documento').getValue(),
                    centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                    codigo_sucursal:this.getField('codigo_sucursal').getValue(),
                    fecha_inicial:this.getField('fecha_inicial').getValue(),
                    fecha_final:this.getField('fecha_final').getValue(),
                    //fecha_inicial:fecha_actual,
                    //fecha_final:fecha_actual,
                    usuario:this.codigo_usuario
                }};
            
                this.generalFormatPmv = { tipo_servicio: 'cont-cudrardifedocucontable', operacion: 'contabilizar_diferencias', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successcontabilizar_diferencias,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }else{
                this.alertGeneral.toggle(true,'El rango de fechas no debe superar los 60 dias.','error');
            }
        }
    }

    successcontabilizar_diferencias(data){
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true,'Finalizó el proceso','success');
            this.limpiar();
        }
        else 
        {
            if(data.estado_p===404) {
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, data.data.mensaje, 'error');
                this.limpiar();
            }
            else {
                this.getField('confirmModalCustom').toggle(false);
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
                this.limpiar();
            }
        }
    }

    limpiar(){
        let fecha_actual = new Date().toISOString().split('T')[0];
        this.getField('codigo_sucursal').setValue(''); 
        this.getField('nombre_sucursal').setValue(''); 
        this.getField('centro_costo_codigo').setValue('');
        this.getField('centro_costo_nombre').setValue(''); 
        this.getField('codigo_documento').setValue(''); 
        this.getField('nombre_documento').setValue('');
        this.getField('diferencia').setValue(0);
        this.getField('fecha_inicial').setValue(fecha_actual);
        this.getField('fecha_final').setValue(fecha_actual);
    }

    validarRangoFecha(fecha1, fecha2){
        let fechaMinima = new Date(fecha1).getTime();
        let fechaSelec = new Date(fecha2).getTime();

        let seguir = true;
        
        let diff = fechaSelec - fechaMinima;
        if(diff/(1000*60*60*24) > 60){
            seguir = false;
        }
        return seguir;
    }
}
FormJaivana.addController("cont-cudrardifedocucontable",CustomCuadrarDiferenciasDocumentoContable);
export default CustomCuadrarDiferenciasDocumentoContable