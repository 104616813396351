/**
 * @author Juan Camilo Villa 
 * @version jdesk_1.01.0001
 *  @description: CustomActivosFijosTraslados
 */

import FormJaivana from 'dashboard_jaivana_v1';

class CustomActivosFijosTraslados extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.validarEncabezado = this.validarEncabezado.bind(this);
        this.gridOptionsActivosFijosTraslados = Object.assign({}, this.gridOptions);


        this.allowedExtensions = /(.txt|.TXT)$/i;
        this.allowedImagesExtensions = /(.jpg|.jpeg|.png|.JPG|.JPEG|.PNG)$/i;
        this.archivoTXT = "";
        this.archivoImagen = "";
        this.accionVerImagen = this.accionVerImagen.bind(this);
        this.accionVerArchivo = this.accionVerArchivo.bind(this);

        this.successGuardar = this.successGuardar.bind(this);
        this.limpiarCampos = this.limpiarCampos.bind(this);
        this.consultarActivosFijosTraslados = this.consultarActivosFijosTraslados.bind(this);
        this.successActivosFijosTraslados = this.successActivosFijosTraslados.bind(this);
        this.setButtonTableActivosFijosTraslados = this.setButtonTableActivosFijosTraslados.bind(this);
        this.crearBotonTabla = this.crearBotonTabla.bind(this);

        ///Variables que trabajan en la Modal 
        this.gridOptionsActivosFijosTrasladosDetalle = Object.assign({}, this.gridOptions);
        this.btn_agregar_traslado_detalle = this.btn_agregar_traslado_detalle.bind(this);
        this.limpiarCamposModal = this.limpiarCamposModal.bind(this);
        this.arrayItems = [];
        this.btn_guardar = this.btn_guardar.bind(this);

        this.accionEliminar = this.accionEliminar.bind(this);
        this.successEliminar = this.successEliminar.bind(this);
        this.confirmarEliminacionRegistro = this.confirmarEliminacionRegistro.bind(this);

        this.crearBotonTablaItemModal = this.crearBotonTablaItemModal.bind(this);
        this.accionEliminarItemModal = this.accionEliminarItemModal.bind(this);
        this.setButtonTableModal = this.setButtonTableModal.bind(this);
    

        


        //PDF y Excel
        this.sucursal_ingreso = '';
        this.validarEncabezado = this.validarEncabezado.bind(this);
        this.btn_imprimir = this.btn_imprimir.bind(this);
        this.nombre_archivo_reporte = '';
        this.generarPdfTabla3 = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3 = this.generarExcelTabla3.bind(this);
        this.procesar = this.procesar.bind(this);
        this.isJson = this.isJson.bind(this);
        this.enviarCorreoTerceros = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo = this.successEnviarCorreo.bind(this);
        this.habilitarCorreo = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo = this.deshabilitarCorreo.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeEmailEnviado = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo = this.mostrarMensajeEmailFallo.bind(this);
        this.abrirImprimir = this.abrirImprimir.bind(this);
    }

    initForm() {
        console.log("CustomActivosFijosTraslados  @version: jdesk_1.01.0004, @author:Juan Camilo Villa Osorio");
        this.consultarActivosFijosTraslados();
        this.getField('rejilla').toggle(false);
        this.getField("btn_abrir_modal_item_detalle").setClick(() => this.validarEncabezado());

        this.getField('archivos').setOnChange((props) => {
            (props.target.files[0] !== undefined && props.target.files[0] !== " ") ?
            this.archivoTXT = props.target.files[0]: this.archivoTXT = "";
        });
        this.getField('imagenes').setOnChange((props) => {
            ((props.target.files[0] !== undefined && props.target.files[0] !== " ")) ?
            this.archivoImagen = props.target.files[0]: this.archivoImagen = "";

        });

        this.getField("btn_agregar_traslado_detalle").setClick(this.btn_agregar_traslado_detalle);

        this.getField("btn_guardar").setClick(this.btn_guardar);

        ///Pdf y excel
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        ///PDf y excel
        this.getField("btn_imprimir").setClick(this.btn_imprimir);
        this.getField('generar_archivo').setClick(()=>this.procesar());


    }

    /**
     * Valida si el formulario del encabezado está lleno
     */
    validarEncabezado() {
        if ((this.getField('archivos').getValue() !== "" && this.getField('archivos').getValue() !== null) && this.getField('fechasistema').valid() &&
            (this.getField('imagenes').getValue() !== "" && this.getField('imagenes').getValue() !== null) && this.getField('bodegaorigen_id').valid() &&
            this.getField('bodegadestino_id').valid() && this.getField('detalle').valid()) {

                if (!this.allowedExtensions.exec(this.archivoTXT.name)) {
                    this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .txt/.TXT', 'error');
                    this.getField('archivos').setError(false, "Formato incorrecto");
                } else if (this.archivoTXT.name.indexOf(' ') > 0) {
                    this.alertGeneral.toggle(true, 'El archivo (' + this.archivoTXT.name + ') No puede contener espacios.', 'error');
                    this.getField('archivos').setError(false);
                } else if (!this.allowedImagesExtensions.exec(this.archivoImagen.name)) {
                    this.alertGeneral.toggle(true, 'Solo se permiten imágenes con extensión .jpeg/.jpg/.png', 'error');
                    this.getField('imagenes').setError(false, "Formato incorrecto");
                } else if (this.archivoImagen.name.indexOf(' ') > 0) {
                    this.alertGeneral.toggle(true, 'La imagen (' + this.archivoImagen.name + ') No puede contener espacios.', 'error');
                    this.getField('imagenes').setError(false, 'Contiene espacios');
                }else{
                    this.getField('modal_agregar_item_traslado').handleClickOpen();
                }
            }else{
                this.alertGeneral.toggle(true, 'Debe llenar todos los campos', 'error');
            }
           
    }


    /**
     Envía todo el formulario al servidor
     */
    btn_guardar() {
        if ((this.getField('archivos').getValue() !== "" && this.getField('archivos').getValue() !== null) && this.getField('fechasistema').valid() &&
            (this.getField('imagenes').getValue() !== "" && this.getField('imagenes').getValue() !== null) && this.getField('bodegaorigen_id').valid() &&
            this.getField('bodegadestino_id').valid() && this.getField('detalle').valid()) {

            if (this.arrayItems.length > 0) {
                if (!this.allowedExtensions.exec(this.archivoTXT.name)) {
                    this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .txt/.TXT', 'error');
                    this.getField('archivos').setError(false, "Formato incorrecto");
                } else if (this.archivoTXT.name.indexOf(' ') > 0) {
                    this.alertGeneral.toggle(true, 'El archivo (' + this.archivoTXT.name + ') No puede contener espacios.', 'error');
                    this.getField('archivos').setError(false);
                } else if (!this.allowedImagesExtensions.exec(this.archivoImagen.name)) {
                    this.alertGeneral.toggle(true, 'Solo se permiten imágenes con extensión .jpeg/.jpg/.png', 'error');
                    this.getField('imagenes').setError(false, "Formato incorrecto");
                } else if (this.archivoImagen.name.indexOf(' ') > 0) {
                    this.alertGeneral.toggle(true, 'La imagen (' + this.archivoImagen.name + ') No puede contener espacios.', 'error');
                    this.getField('imagenes').setError(false, 'Contiene espacios');
                } else {
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está enviando la información... `);
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm('.'); 
                    this.getField('confirmModalCustom').toggle(true);
                    const data = {
                        _generales: { "tipo_servicio": "serlog-activosfijostraslados", "operacion": "subirarchivo_traslado", "operacion_tipo": "crear" },
                        archivo: this.archivoTXT,
                        imagen: this.archivoImagen,
                        detalle: this.getField('detalle').getValue(),
                        fechasistema: this.getField('fechasistema').getValue(),
                        bodegaorigen_id: this.getField('bodegaorigen_id').getValue(),
                        bodegadestino_id: this.getField('bodegadestino_id').getValue(),
                        items: JSON.stringify(this.arrayItems)
                    }

                    let url = this.constant.formConfiguration();
                    this.generalFormat = data._generales;
                    this.service.send({
                        endpoint: url,
                        method: 'POST',
                        body: data,
                        success: this.successGuardar,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: true,
                        showMessage: false
                    });
                }

            } else {
                this.alertGeneral.toggle(true, 'Debe agregar al menos 1 ítem', 'error');
            }

        } else {
            this.alertGeneral.toggle('Debe llenar todos los campos', 'error');
        }

    }

    /**
     * Contiene la respuesta del servicio. Si es 200 limpia todos los campos 
     * y actualiza la tabla del encabezado. De lo contrario mostrará un mensaje de error 
     * @param {objeto que contiene la respuesta de la petición} props 
     */
    successGuardar(props) {
        this.getField("confirmModalCustom").setVisibleConfirm(false);
        if (props.estado_p === 200) {
            this.getField("modal_agregar_item_traslado").handleClose();
            this.limpiarCampos();
            this.limpiarCamposModal();
            this.consultarActivosFijosTraslados();
            this.arrayItems = [];
            /* this.alertGeneral.toggle(true, "Envío exitoso", ); */
        } else {
            this.alertGeneral.toggle(true, props.data.mensaje, 'error');
        }
        this.getField('confirmModalCustom').toggle(false);

    }

    /**
     * Petición para traer todos los activosFijosTraslados
     */
    consultarActivosFijosTraslados() {
        this.datosConsultar = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijostraslados', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successActivosFijosTraslados,
            error: this.error_,
            general: this.generalFormatPmv
        });

    }

    /**
     * Arma la tabla con el resultado de la petición
     * @param {Respuesta de la petición} data 
     */
    successActivosFijosTraslados(data) {
        if (data.estado_p === 200) {
            this.getField('btn_imprimir').setVisible(true);
            let configCell = new Map();
            this.gridOptionsActivosFijosTraslados['rowData'] = data.data;
            configCell.set('detalle', { headerName: 'Detalle' });
            configCell.set('fechasistema', { headerName: 'Fecha' });
            configCell.set('bodegaorigen_id', { headerName: 'Código Bodega Origen' });
            configCell.set('bodegadestino_id', { headerName: 'Código Bodega Destino' });
            configCell.set('accion', { cellRenderer: this.setButtonTableActivosFijosTraslados, headerName: 'Acción', width: 400, sortable: false, filter: false });
            this.getField('rejilla').initData(this.gridOptionsActivosFijosTraslados, configCell);
        } else {
            this.gridOptionsActivosFijosTraslados['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsActivosFijosTraslados);
            this.getField('rejilla').toggle(false);
            this.getField('btn_imprimir').setVisible(false);
        }

    }

    /**
     * Establece la creación del botón en la tabla
     * @param {Contiene los valores de la fila seleccionada en la tabla} props 
     */
    setButtonTableActivosFijosTraslados(props) {
        try {
            let fragment = document.createDocumentFragment();
            let buttonImage = this.crearBotonTabla(props.data, 'Ver Imagen');
            let buttonArchive = this.crearBotonTabla(props.data, 'Archivos');
            let buttonDelete = this.crearBotonTabla(props.data, 'Eliminar');
            fragment.appendChild(buttonImage);
            fragment.appendChild(buttonArchive);
            fragment.appendChild(buttonDelete);
            return this.createElementJaivana(fragment)
        } catch (error) {

        }
    }


    crearBotonTabla(data, nombreBoton) {
        let button = document.createElement('input');
        button.onclick = () => { this.accionVerImagen(data) };
        switch (nombreBoton) {
            case 'Ver Imagen':
                if (data.imagenes !== undefined && data.imagenes !== null && data.imagenes !== "") {
                    button.setAttribute("class", "buttonStyleImg");
                    button.onclick = () => { this.accionVerImagen(data) };
                } else {
                    button.setAttribute("class", "buttonStyleImgDisabled");
                    button.setAttribute("disable", true);
                }
                break;
            case 'Archivos':
                if (data.archivos !== undefined && data.archivos !== null && data.archivos !== "") {
                    button.setAttribute("class", "buttonStyleActivos");
                    button.onclick = () => { this.accionVerArchivo(data) };
                } else {
                    button.setAttribute("class", "buttonStyleImgDisabled2");
                    button.setAttribute("disable", true);
                }
                break;
            default:
                button.setAttribute("class", "buttonStyleImg3");
                button.onclick = () => { this.confirmarEliminacionRegistro(data) };
                break;
        }
        button.setAttribute('value', nombreBoton);
        button.setAttribute('type', 'button');
        button.setAttribute("class", "buttonStyle2");
        button.setAttribute("id", `buttonVerImagen_${data.id}`);
        return button;

    }

    /**
     * Establece la creación del botón eliminar de la tabla de los items de la modal
     * @param {Contiene los valores de la fila seleccionada en la tabla} props 
     */
    setButtonTableModal(props) {
        try {
            let fragment = document.createDocumentFragment();
            let buttonDelete = this.crearBotonTablaItemModal(props, 'Eliminar');
            fragment.appendChild(buttonDelete);
            return this.createElementJaivana(fragment);
        } catch (error) {

        }
    }

    crearBotonTablaItemModal(data, nombreBoton) {
        let button = document.createElement('input');
        button.onclick = () => { this.accionEliminarItemModal(data) };

        button.setAttribute("class", "buttonDeleteModal");
        button.setAttribute('value', nombreBoton);
        button.setAttribute('type', 'button');
        button.setAttribute("class", "buttonStyle2");
        button.setAttribute("id", "buttonDeleteModal1");
        /* button.setAttribute("id", `buttonVerImagen_${data.id}`); */
        return button;

    }

    accionEliminarItemModal(data){
        let index = this.arrayItems.indexOf(data.data);
        
        this.arrayItems.splice(index,1);
        this.gridOptionsActivosFijosTrasladosDetalle['rowData'] = this.arrayItems;
        if (this.arrayItems.length > 0) {
            let configCell = new Map();
            configCell.set('accion', { cellRenderer: this.setButtonTableModal, headerName: 'Acción', width: 600, sortable: false, filter: false });
            this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosTrasladosDetalle, configCell);
            
        } else {
            this.gridOptionsActivosFijosTrasladosDetalle['rowData'] = [];
            this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosTrasladosDetalle);
            this.getField('rejilla_modal').toggle(false);
        }



    }




    confirmarEliminacionRegistro(data) {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(() => this.accionEliminar(data));
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ 
            this.getField('confirmModalCustom').toggle(false);
             });
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField('confirmModalCustom').toggle(true);
    }

    accionEliminar(data){

        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está eliminando el registro... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); 
        this.getField("confirmModalCustom").setButtonConfirm('.'); 
        this.getField('confirmModalCustom').toggle(true);
        let arrayNameImage = (data.imagenes.split('/'));
        let nameImage = arrayNameImage[arrayNameImage.length -1];
        let arrayNameArchive = (data.archivos.split('/'));
        let nameArchive = arrayNameArchive[arrayNameArchive.length -1];
        
        const datos = {
            nombre_archivo: nameArchive,
            nombre_imagen: nameImage,
            activos_fijos_ajustes_id : Number(data.activos_fijos_ajustes_id)
        }



        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijostraslados', operacion: 'eliminar_traslados', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv,
                formData: true,
                showMessage: false
        });


    }

    successEliminar(data){
        this.getField("confirmModalCustom").setButtonConfirm('.'); 
        if (data.estado_p === 200) {
            /* this.getField('confirmModalCustom').toggle(false); */
            this.consultarActivosFijosTraslados();
            this.limpiarCampos();
            this.limpiarCamposModal();
        }
        this.getField('confirmModalCustom').toggle(false);
            
    }


    /**
     * Mensaje que se mostrará al usuario cuando se está cargando una imagen ó un archivo 
     */
    mostrarMensajeCargando() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, ¡Se está cargando la vista previa! `);
        this.getField("confirmModalCustom").setVisibleCancel(true);
        this.getField("confirmModalCustom").setButtonConfirm('.');
        this.getField('confirmModalCustom').toggle(true);

    }


    /**
     * Acción del botón ver imagen para ver la imagen cargada
     */
    accionVerImagen(data) {
        this.mostrarMensajeCargando();
        let nameImage = data.imagenes;
        let urlImage = 'http://calidad.jaivanaweb.co:8084/remote.php/dav/files/admin/img_logisticos_activos_fijos_traslados/' + nameImage;
        let url = this.constant.formConfiguration();
        let datos = `?body={"datos":{"url":"${urlImage}"},"generales":{"tipo_servicio":"serlog-activosfijostraslados","operacion":"url_imagen_traslado","operacion_tipo":"consulta"}}`;

        fetch(url + datos, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();

                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .catch(error => {
            })
            .then(stream => new Response(stream))
            .catch(error => {
            })
            .then(response => {
                return response.blob()
            })
            .catch(error => {
            })
            .then(blob => {
                if (blob.size > 0) {
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",

                    });
                    window.open(URL.createObjectURL(image))
                } else {
                    this.alertGeneral.toggle(true, 'No hay datos', 'error');
                }
                this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
            })
            .catch(err => {
                return console.error(err);
            });

    }


    /**
     * Botón para ver el archivo de la tabla
     * @param {El objeto seleccionado en la tabla} data 
     */
    accionVerArchivo(data) {
        this.mostrarMensajeCargando();
        let operation = "url_archivo_traslado";
        let nameArchivo = data.archivos;
        let urlArchivo = 'http://calidad.jaivanaweb.co:8084/remote.php/dav/files/admin/archivos_logisticos_activos_fijos_traslados/' + nameArchivo;
        
        let urlLocal = this.constant.formConfiguration();
        let datos = `?body={"datos":{"url":"${urlArchivo}"},"generales":{"tipo_servicio":"serlog-activosfijostraslados","operacion":"${operation}","operacion_tipo":"consulta"}}`;

        fetch(urlLocal + datos, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();

                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .catch(error => {
            })
            .then(stream => new Response(stream))
            .catch(error => {
            })
            .then(response => {
                return response.blob()
            })
            .catch(error => {
            })
            .then(blob => {

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = nameArchivo;
                /* a.download = nameArchivo[2]; */
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
            })
            .catch(err => {
                return console.error(err);
            });
    }









    /**
     * Acción del botón Agregar Item.
     * Almacena los items que se van a ir agregando
     */
    btn_agregar_traslado_detalle() {
        if (this.getField("codigo_activo").getValue() !== "" && this.getField("nombre_activo").getValue() !== "" &&
            this.getField("bodega_id").getValue() !== "" && this.getField("detalle_item_traslado").getValue() !== "") {
            

            let arrayTemp = [];
            let item = {
                "detalle": this.getField("detalle_item_traslado").getValue(),
                /* "bodega": this.getField("bodega_id").getValue(), */
                "activo_id": this.getField('activos_fijos_basicos_logisticos_id').getValue(), ///Se debe cambiar
                "serialactivo": this.getField('codigo_activo').getValue(),
                "nombre": this.getField("nombre_activo").getValue(),
                "nombre_bodega": this.getField("nombre_bodega").getValue(),
                "codigo": this.getField("codigo_activo").getValue()
            };
            this.arrayItems.push(item);

            let configCell = new Map();

            arrayTemp = [...this.arrayItems];
            this.gridOptionsActivosFijosTrasladosDetalle['rowData'] = arrayTemp;

            configCell.set('id', { headerName: 'activo_id' });
            configCell.set('detalle', { headerName: 'Detalle' });
            configCell.set('serialactivo', { headerName: 'Serial Activo' });
            configCell.set('nombre', { headerName: 'Nombre de Activo' });
            configCell.set('nombre_bodega', { headerName: 'Nombre Bodega Activo', width: 300 });
            configCell.set('accion', { cellRenderer: this.setButtonTableModal, headerName: 'Acción', width: 400, sortable: false, filter: false });
            this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosTrasladosDetalle, configCell);
            this.limpiarCamposModal();
            /* item = {}; */

        } else {
            /*             this.gridOptionsActivosFijosTrasladosDetalle['rowData'] = [];
                        this.getField('rejilla').initData(this.gridOptionsActivosFijosTrasladosDetalle);
                        this.getField('rejilla').toggle(false); */
            this.alertGeneral.toggle(true, 'Debe llenar todos los campos', 'error');
        }
    }

    /**
     * Limpia los campos del encabezado y oculta la tabla
     */
    limpiarCampos() {
        
        this.getField('archivos').handleClickDelete();
        this.getField('imagenes').handleClickDelete();
        this.archivoTXT = '';
        this.archivoImagen = '';
        this.getField('detalle').setValue("");
        this.getField('fechasistema').setValue("");
        this.getField('bodegaorigen_id').setValue("");
        this.getField('bodegadestino_id').setValue("");

        /*         this.gridOptionsActivosFijosTraslados['rowData'] = [];
                this.gridOptionsActivosFijosTrasladosModal['rowData'] = [];
                this.getField('rejilla').initData(this.gridOptionsActivosFijosTraslados);
                this.getField('rejilla').toggle(false);
                this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosTrasladosModal);
                this.getField('rejilla_modal').toggle(false); */
    }



    /**
     * Limpia los campos que están en la modal
     */
    limpiarCamposModal() {
        this.getField("codigo_activo").setValue("");
        this.getField("nombre_activo").setValue("");
        this.getField("bodega_id").setValue("");
        this.getField("detalle_item_traslado").setValue("");
        this.getField("nombre_bodega").setValue("");
/*         this.gridOptionsActivosFijosTrasladosDetalle['rowData'] = [];
        this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosTrasladosDetalle) */
            /* this.arrayItems = []; */
    }

    btn_imprimir(){
        this.getField('modal_imprimir').handleClickOpen();
    }


    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        
        this.getField('email').setDisabled(false);
    }

    generarPdfTabla3()
    {       
            this.nombre_archivo_reporte = 'reporte_activos_fijos_traslados';
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='imprimir_traslados';
            let datos2={
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual,
                sucursal_ingreso: this.sucursal_ingreso
            }

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"serlog-activosfijostraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';

            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo_reporte+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='imprimir_traslados';
            let datos2={
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual,
                sucursal_ingreso: this.sucursal_ingreso
            }
            this.nombre_archivo_reporte = 'reporte_activos_fijos_traslados';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"serlog-activosfijostraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo_reporte+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    enviarCorreoTerceros(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let operation = 'imprimir_traslados';
        let datos={datos:{
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
            operacion_form: this.operacion_form+'',
            sucursal_ingreso:this.sucursal_ingreso,
        }}

        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijostraslados', operacion: operation, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){// Se comenta la validación de que sea diferente, ya que en la tarea de corrección "JQJD-1480" pidieron que si en "radio_correo" está seleccionado la opción de "NO", no fuera requerido digitar el correo, ya que en la tabla siempre va a ir 2 ítems, lo que significa que el pdf sólo va a tener una página.
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf ó Excel', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        /* this.getField('consultar').setDisabled(true); */
    
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }



}

FormJaivana.addController("serlog-activosfijostraslados", CustomActivosFijosTraslados);
export default CustomActivosFijosTraslados