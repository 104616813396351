import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAsignarConsultasUsuarios
 * @author: Anderson Acevedo Briñez
 * @updateBy 
 * @version: jdesk_1.01.0001
 **/
class CustomAsignarConsultasUsuarios extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.successModificarUsuariosAutorizados = this.successModificarUsuariosAutorizados.bind(this);
    this.modificarUsuariosAutorizados = this.modificarUsuariosAutorizados.bind(this);
    this.activarCampos  = this.activarCampos.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.setButtonAgregar          = this.setButtonAgregar.bind(this);
    this.consultarConsultas = this.consultarConsultas.bind(this);
    this.successConsultarConsultas = this.successConsultarConsultas.bind(this);
    this.id_actual='';
    this.agregar=false;
    this.id=0;
  }

  initForm() {
    console.log("Formulario CustomAsignarConsultasUsuarios,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez");
    this.limpiarCampos();
    this.getField('agregar').setClick(this.modificarUsuariosAutorizados);
    this.consultarConsultas();
    this.getField('cancelar').setClick(this.limpiarCampos);
    this.getField('nombre').setOnChange((props)=>{
        if(this.getField('nombre').getValue()==='' && this.getField('nombre').getValue()!==' '){
          this.getField('agregar').setDisabled(true);
        }else{
          this.getField('agregar').setDisabled(false);
        }
    });
    this.getField('consultas_id').setOnChange((props)=>{
      if(this.agregar===true && this.id===this.getField('consultas_id').getValue()){
        this.agregar=false;
      }else{
        this.limpiarCampos();
      }
  });
    
  }



  consultarConsultas(){
    let datos = { datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'heus-asignarconsultas', operacion: '1', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successConsultarConsultas,
              error: this.error_,
              general: this.generalFormatPmv
      });
   }

   successConsultarConsultas(data){
       if(data.estado_p === 200){ 
         this.getField('rejilla').toggle(true);
         let configCell = new Map();
         this.gridOptionsComponentes['rowData'] = data.data;
         configCell.set('accion', { cellRenderer: this.setButtonAgregar, width: 110, sortable: false, filter: false, field: 'Agregar' });
         this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
       }else if (data.estado_p === 404){
         this.getField('rejilla').toggle(false);
       }
   }

  limpiarCampos(){
    this.getField('codigo').setVisible(false);
    this.getField('nombre').setVisible(false);
    this.getField('agregar').setVisible(false);
    this.getField('cancelar').setVisible(false);
    this.getField('nombre').setValue('');
    this.getField('codigo').setValue('');
    
  }

  activarCampos(id){
    this.id=id;
    this.agregar=true;
    this.getField('confirmModalCustom').toggle(false);
    this.getField('codigo').setVisible(true);
    this.getField('nombre').setVisible(true);
    this.getField('agregar').setVisible(true);
    this.getField('cancelar').setVisible(true);
    this.getField('nombre').setValue('');
    this.getField('codigo').setValue('');
  }

  modificarUsuariosAutorizados(){
    let existe=false;
    let usuarios=this.getField('usuarios').getValue();
    let array=usuarios.split(',');
    array.forEach(element => {
      if(element===this.getField('codigo').getValue())
        existe=true;
    });
    if(existe===false){
      if(this.getField('codigo').valid()&&
      this.getField('nombre').valid()&&
      this.getField('consultas_id').valid()){
        let datos = { datos: {
          consultas_id:this.getField('consultas_id').getValue(),
          value:this.getField('codigo').getValue(),
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'heus-asignarconsultas', operacion: '6', operacion_tipo: 'modificar' };
        this.datos_anteriores=this.generalFormatPmv;
        this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.successModificarUsuariosAutorizados,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
      }
    }else{
      this.alertGeneral.toggle(true, 'El usuario ya se encuentra autorizado.', 'error');
    }
    
  }

  successModificarUsuariosAutorizados(data) 
  {

    if (data.estado_p === 200)
    {
        this.getField('rejilla').toggle(false);
        this.limpiarCampos();
        this.consultarConsultas();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  setButtonAgregar(props) {
    let id = props.data.consultas_id;
    let button = document.createElement("input");
    button.onclick = () => this.activarCampos(id);
    button.setAttribute("id", 'button_agregar_' + id);
    button.setAttribute("class", "buttonStyle");
    button.setAttribute("type", "button");
    button.setAttribute("value", "Agregar");
    return this.createElementJaivana(button);
}

}
FormJaivana.addController("heus-asignarconsultas", CustomAsignarConsultasUsuarios);
export default CustomAsignarConsultasUsuarios;