import FormJaivana from "dashboard_jaivana_v1";

/**

 *

 * @author Santiago Hernández

/** 

 * Custom  Asignar Planilla terceros

 **/

class CustomModificarArchivoXml extends FormJaivana.form {

    constructor(props) {

        super(props);

        this.initForm                                               = this.initForm.bind(this);
        this.traerDatos                                             = this.traerDatos.bind(this);
        this.successTraerDatos                                      = this.successTraerDatos.bind(this);
        this.gridOptionsTbPrincipal                                 = Object.assign({},this.gridOptions);
        this.gridOptionsTbPrincipal['onSelectionChanged']           = this.onSelectionChanged;
        this.setButtonReemplazarArchivo                             = this.setButtonReemplazarArchivo.bind(this);
        this.ReemplazarArchivo                                      = this.ReemplazarArchivo.bind(this);
        this.archivoUf                                              = "";
        this.sobrescribirArchivo                                    = this.sobrescribirArchivo.bind(this);
        this.successSubirArchivo                                    = this.successSubirArchivo.bind(this);
        this.url_servidor                                           = '';
        this.traerUrlSubirArchivos                                  = this.traerUrlSubirArchivos.bind(this);
        this.succesTraerUrlSubirArchivos                            = this.succesTraerUrlSubirArchivos.bind(this);
        this.successModificarPlantillaArchivo                       = this.successModificarPlantillaArchivo.bind(this);
        this.limpiar                                                = this.limpiar.bind(this);
    }

    initForm() {

        console.log("Formulario CustomModificarArchivoXml,  @version: jdesk_1.01.0003, @author: Santiago Hernández");
        this.getField("tercero_id").setOnChange(this.traerDatos);

        this.getField('archivo').setOnChange((props) => { 
            if(props.target.files[0] !== undefined && props.target.files[0] !== " " ){
                this.archivoUf = props.target.files[0];
                let array=props.target.files[0].name.split('.');
                this.extension=array[array.length-1];
                if(this.extension==='XLSX' || this.extension==='xlsx'){
                    this.getField('archivo').setError(false, '');
                }else{
                    this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                }
            }else{
                this.archivoUf = "";
                this.getField('archivo').setError(false, '');
            }
        });

        this.traerUrlSubirArchivos();
        this.getField("btnAceptar").setClick(this.sobrescribirArchivo);
        this.getField("btnCancelar").setClick(this.limpiar);
    }

    traerUrlSubirArchivos(){

        let datos={ 
            datos: {}
        };

        this.generalFormatPmv = { tipo_servicio: 'maes-integrararchivos', operacion: 'obtenerurl', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.succesTraerUrlSubirArchivos,
            error: this.error_,
            general: this.generalFormatPmv
        });

    }

    succesTraerUrlSubirArchivos(data){
        if (data.estado_p === 200 ){
            this.url_servidor=data.data[0].url_subir_archivo
        } else {
            this.url_servidor='';
        }
    }

    traerDatos(){
        if(this.getField("tercero_id").getValue() !== ""){
            let datos = { datos: {tercero_id:this.getField("tercero_id").getValue()}};
            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '1_3', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.getField('rejilla').toggle(false);
            this.getField("archivo").setVisible(false);
            this.getField("btnAceptar").setVisible(false);
            this.getField("btnCancelar").setVisible(false);
            this.getField("archivo").handleClickDelete(this);
            this.getField("archivo").setError(false, '');
        }
    }

    successTraerDatos(data){
        if(data.estado_p === 200){
            this.getField('rejilla').toggle(true);
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('sobrescribir_archivo', { cellRenderer: this.setButtonReemplazarArchivo, width: 110, sortable: false, filter: false, field: 'sobrescribir_archivo' });            
            this.getField('rejilla').initData(this.gridOptionsTbPrincipal,configCell);   
        }else{
            this.getField('rejilla').toggle(false);
        }        
    }

    onSelectionChanged(){
        //let rowDataTable = this.gridOptionsTbPrincipal.api.getSelectedRows();
    }

    setButtonReemplazarArchivo(props){
        let id = props.data.id;
        let nombre_archivo = props.data.nombre_archivo; 
    
        let button = document.createElement("input");
        button.onclick = () => this.ReemplazarArchivo(id,nombre_archivo);
        button.setAttribute("id", 'button_reemplazar_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Sobrescribir archivo");
        if (props.data.maneja_distribucion === "N" || props.data.maneja_distribucion === 'N'){
            button.setAttribute("disabled", "true");
            button.setAttribute("hidden", "true");
            button.setAttribute("class", "buttonDisabled");
        }
        return this.createElementJaivana(button);

    }

    ReemplazarArchivo(id,nombre_archivo){
        this.plantillas_electronicas_gastos_id = id;
        this.nombre_archivo = nombre_archivo;

        this.getField("archivo").setVisible(true);
        this.getField("btnAceptar").setVisible(true);
        this.getField("btnCancelar").setVisible(true);
        this.getField("archivo").handleClickDelete(this);
    }

    sobrescribirArchivo(){
        if(this.getField("nit").valid()  && this.getField("sede").valid() && this.getField("nombre").valid()){
            if(this.archivoUf !== ""){
                if(this.url_servidor!=='' && this.url_servidor!==''){
                    if(this.extension==='XLSX' || this.extension==='xlsx'){
                        const data = {
                            _generales: {"tipo_servicio":"subirarchivos","operacion":"xlsx_plantilla_terceros_reemplazar","operacion_tipo": "crear"},
                            archivo: this.archivoUf,
                            nombre_archivo: this.nombre_archivo,
                            plantillas_electronicas_gastos_id: this.plantillas_electronicas_gastos_id
                        }

                        let url = this.url_servidor+'/xlsx/api/protected';
                        this.generalFormat = data._generales;
                        this.service.send({
                            endpoint:url,
                            method: 'POST',
                            body: data,
                            success: this.successSubirArchivo,
                            error: this.error_,
                            general: this.generalFormat,
                            formData: true,
                            showMessage: false
                        });
                    }else{
                        this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                    }
                }else{
                    this.alertGeneral.toggle(true, 'Url del servidor mal configurada.', 'error'); 
                }  
            }else {
                this.alertGeneral.toggle(true, 'Debe cargar el archivo.', 'error');                
            }
        }
    }

    successSubirArchivo(data){
        if(data.estado_p === 200){
            let datos = { 
                datos: {
                    plantillas_electronicas_gastos_id:Number(data.data[0].plantillas_electronicas_gastos_id),
                    ruta_archivo:data.data[0].ruta_archivo,
                    nombre_archivo:data.data[0].nombre_archivo,
                }
            };

            this.generalFormatPmv = { tipo_servicio:  'cont-modificararchivodistrixml', operacion: 'modificararchivo', operacion_tipo: 'modificar' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successModificarPlantillaArchivo,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, 'Error al tratar de reemplazar el archivo.', 'error');     
        }    
    }

    successModificarPlantillaArchivo(data){
        if(data.estado_p === 200){
            this.getField("archivo").setVisible(false);
            this.getField("btnAceptar").setVisible(false);
            this.getField("btnCancelar").setVisible(false);
            this.getField("archivo").handleClickDelete(this);
            this.traerDatos();
        }else{
            this.alertGeneral.toggle(true, 'Error al actualizar el archivo.', 'error');     
        }   

    }

    limpiar(){
        this.getField("archivo").setVisible(false);
        this.getField("btnAceptar").setVisible(false);
        this.getField("btnCancelar").setVisible(false);
        this.getField("archivo").handleClickDelete(this);
        this.getField("archivo").setError(false, '');

        this.getField("nombre").setValue('');
        this.getField("sede").setValue('');
        this.getField("nit").setValue('');
        this.getField("nombre").setError(false, '');
        this.getField("sede").setError(false, '');
        this.getField("nit").setError(false, '');

        
        this.archivoUf = '';
        this.getField('rejilla').toggle(false);

    }

}

FormJaivana.addController("cont-modificararchivodistrixml", CustomModificarArchivoXml);
export default CustomModificarArchivoXml;