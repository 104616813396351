import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Anderson Acevedo
 * 
 * @version jdesk_1.01.0002
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomAgregarTransaccion extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);

        this.consultarHistoria = this.consultarHistoria.bind(this);
        this.gridOptionsTbPrincipal = Object.assign({}, this.gridOptions);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptionsTbPrincipal['onSelectionChanged'] = this.onSelectionChanged;

        this.gridOptionsTbTemporal = Object.assign({}, this.gridOptions);
        this.onSelectionChanged2 = this.onSelectionChanged.bind(this);
        this.gridOptionsTbTemporal['onSelectionChanged'] = this.onSelectionChanged2;

        this.ocultarCampos = this.ocultarCampos.bind(this);
        this.validarfechas = this.validarfechas.bind(this);

        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
        this.traerPropiedades = this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades = this.sucesstraerPropiedades.bind(this);
        this.successDatosPrincipal = this.successDatosPrincipal.bind(this);
        this.arreglarDecimales = this.arreglarDecimales.bind(this);
        this.procesar = this.procesar.bind(this);
        this.successCrearTemporal = this.successCrearTemporal.bind(this);
        this.succesActualizarItems = this.succesActualizarItems.bind(this);
        this.asentar = this.asentar.bind(this);
        this.successAsentar = this.successAsentar.bind(this);

        this.refrescarTabla = this.refrescarTabla.bind(this);
        this.successRefrescar = this.successRefrescar.bind(this);


        this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
        this.sucursal_ingreso = '';
        this.numero_decimales = 2;
        this.fecha_hoy = '';
        this.costo_administrativo=0;
        this.fila_seleccionada=[];
    }

    initForm() {
        console.log('Formulario CustomAgregarTransaccion,  @version: jdesk_1.01.0002, @author:Anderson Acevedo');
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedades();
        let hoy = new Date();
        let dia = hoy.getDate();
        let mes = hoy.getMonth() + 1;
        let año = hoy.getFullYear();
        if (mes < 10) {
            mes = '0' + mes;
        }
        if (dia < 10) {
            dia = '0' + dia;
        }
        this.fecha_hoy = año + '-' + mes + '-' + dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.getField('desde').setValue(this.fecha_hoy);
        this.getField('bt_ver_historia').setClick(this.consultarHistoria);
        this.getField('bt_ver_procesar').setClick(this.procesar);
        this.getField('bt_asentar').setClick(this.asentar);

        this.getField('codigo_articulo').setOnChange(()=>{
            this.getField('nombre_articulo').setError(false,'');
            this.ocultarCampos();
            
        });

        this.getField('select_bodega').setOnChange(()=>{
            this.ocultarCampos();
        });

        this.getField("desde").setOnChange(this.validarfechas);

    }

    traerPropiedades() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'inve-agregartransaccion', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    sucesstraerPropiedades(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.numero_decimales = data.data.numero_decimales;
            this.costo_administrativo=data.data.costo_administrativo;

            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.bodegas.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.codigo_bodega;
                dataOp['text'] = item.ubicacion;
                dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
                opciones.push(dataOp);
            });
            opciones.sort();
            this.getField('select_bodega').setOptions(opciones);

            opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.tipos.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.codigo;
                dataOp['text'] = item.codigo;
                dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
                opciones.push(dataOp);
            });
            opciones.sort();
            this.getField('select_tipo').setOptions(opciones);

            if(this.costo_administrativo==='S'){
                this.getField('costo_adm').setVisible(true);
            }else{
                this.getField('costo_adm').setVisible(false);
            }



        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    asentar(){ 
        if(this.gridOptionsTbTemporal['rowData']!==undefined  && this.gridOptionsTbTemporal['rowData'].length>0){
            let errores=0;
            if(!this.getField('numero_documento').valid())
                errores++;
            if(!this.getField('select_tipo').valid())
                errores++;
            if(!this.getField('cantidad').valid())
                errores++;
            if(!this.getField('orden_anterior').valid())
                errores++;
            if(!this.getField('orden_fin_hueco').valid())
                errores++;
            if(!this.getField('costo_unitario').valid())
                errores++;
            if(this.costo_administrativo==='S'){
                if(!this.getField('costo_adm').valid())
                    errores++;
            }
            if(!this.getField('codigo_sucursal').valid())
                errores++;
            if(!this.getField('nombre_sucursal').valid())
                errores++;
            if(!this.getField('ch_afecta_costo').valid())
                errores++;
            if(this.getField('orden_anterior').getValue()>this.getField('orden_fin_hueco').getValue()){
                this.mostrarMensajeAdicional('Orden inicial mayor que orden final.');
            }
            if(errores===0){
                    let datos={datos:{
                        sucursal_ingreso: this.sucursal_ingreso,
                        orden_anterior : this.fila_seleccionada[0].orden+'',
                        select_bodega : this.getField('select_bodega').getValue(),
                        codigo_articulo : this.getField('codigo_articulo').getValue(),
                        orden_fin_hueco: this.getField('orden_fin_hueco').getValue(),
                    }}
                    if(this.costo_administrativo==='S')
                        datos.datos.costo_adm=this.getField('costo_adm').getValue();
                    this.mostrarMensajeGenerando();
                    this.generalFormatPmv = { tipo_servicio: 'inve-agregartransaccion', operacion:  'asentar', operacion_tipo: 'crear' };
                    this.service.send(
                    {
                            endpoint: this.constant.formConfiguration(),
                            method:'POST',
                            body: datos,
                            success: this.successAsentar,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                    });
            }
        }else{
            this.mostrarMensajeAdicional('No hay resgistros para asentar.');
        }
    }

    successAsentar(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            if(data.data.mensaje_mostrar!==undefined){
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar); 
            }else{
                this.alertGeneral.toggle(true, 'Datos almacenados.', 'success');
                //actualizar items
                this.getField('orden_fin_hueco').setValue(data.data.orden_fin_hueco);
                    // -------------> VOY A COMENTAR ESTO YA QUE SE ACTUALIZA LA TABLA TEMPORAL,
                //PERO DESPUES DE ESO LIMPIA LA PANTALLA
                /*
                let datos={datos:{
                    sucursal_ingreso: this.sucursal_ingreso,
                    numero_documento: this.getField('numero_documento').getValue(),
                    select_tipo : this.getField('select_tipo').getValue(),
                    cantidad : this.getField('cantidad').getValue(),

                    orden_nuevo_registro : data.data.ultimo_orden_auxiliar,//diff para asentar  --> ultimo_orden_auxiliar

                    costo_unitario : this.getField('costo_unitario').getValue(),
                    codigo_sucursal : this.getField('codigo_sucursal').getValue(),
                    ch_afecta_costo : this.getField('ch_afecta_costo').getValue(),
                    fecha : this.getField('fecha').getValue(),
                    select_bodega : this.getField('select_bodega').getValue(),
                    codigo_articulo : this.getField('codigo_articulo').getValue(),
                    desde : this.getField('desde').getValue(),
                }}
                if(this.costo_administrativo==='S')
                    datos.datos.costo_adm=this.getField('costo_adm').getValue();
                this.mostrarMensajeGenerando();
                this.generalFormatPmv = { tipo_servicio: 'inve-agregartransaccion', operacion:  'actualizaritems', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.succesActualizarItems,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });*/
                this.consultarHistoria();


            }
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    procesar(){ 
        if(this.fila_seleccionada.length>0){
            let errores=0;
            if(!this.getField('numero_documento').valid())
                errores++;
            if(!this.getField('select_tipo').valid())
                errores++;
            if(!this.getField('cantidad').valid())
                errores++;
            if(!this.getField('orden_anterior').valid())
                errores++;
            if(!this.getField('costo_unitario').valid())
                errores++;
            if(this.costo_administrativo==='S'){
                if(!this.getField('costo_adm').valid())
                    errores++;
            }
            if(!this.getField('codigo_sucursal').valid())
                errores++;
            if(!this.getField('nombre_sucursal').valid())
                errores++;
            if(!this.getField('ch_afecta_costo').valid())
                errores++;
            if(errores===0){
                let mensaje = "Confirma que desea agregar la transacción de la referencia " + this.fila_seleccionada[0].codigo;
                mensaje += "\ndespués de la transacción de tipo " + this.fila_seleccionada[0].tipo + " de la fecha " + this.fila_seleccionada[0].fecha_documento;
                mensaje += "\nque ha seleccionado en el visor?";

                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Costo',mensaje);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    this.mostrarMensajeGenerando();
                    let datos={datos:{
                        sucursal_ingreso: this.sucursal_ingreso,
                        numero_documento: this.getField('numero_documento').getValue(),
                        select_tipo : this.getField('select_tipo').getValue(),
                        cantidad : this.getField('cantidad').getValue(),
                        orden_anterior : this.fila_seleccionada[0].orden+'',
                        costo_unitario : this.getField('costo_unitario').getValue(),
                        codigo_sucursal : this.getField('codigo_sucursal').getValue(),
                        ch_afecta_costo : this.getField('ch_afecta_costo').getValue(),
                        fecha : this.getField('fecha').getValue(),
                        select_bodega : this.getField('select_bodega').getValue(),
                        codigo_articulo : this.getField('codigo_articulo').getValue(),
                        desde : this.getField('desde').getValue(),
                    }}
                    if(this.costo_administrativo==='S')
                        datos.datos.costo_adm=this.getField('costo_adm').getValue();
                    this.mostrarMensajeGenerando();
                    this.generalFormatPmv = { tipo_servicio: 'inve-agregartransaccion', operacion:  'procesar', operacion_tipo: 'crear' };
                    this.service.send(
                    {
                            endpoint: this.constant.formConfiguration(),
                            method:'POST',
                            body: datos,
                            success: this.successCrearTemporal,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                    });
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }
        }else{
            this.mostrarMensajeAdicional('Debe seleccionar el registro anterior al que desea insertar.');
        }
    }

    successCrearTemporal(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            if(data.data.mensaje_mostrar!==undefined){
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
                
            }else{
                //actualizar items
                this.getField('orden_fin_hueco').setValue(data.data.orden_fin_hueco);

                let datos={datos:{
                    sucursal_ingreso: this.sucursal_ingreso,
                    numero_documento: this.getField('numero_documento').getValue(),
                    select_tipo : this.getField('select_tipo').getValue(),
                    cantidad : this.getField('cantidad').getValue(),

                    orden_nuevo_registro : data.data.orden_inicio_hueco,

                    costo_unitario : this.getField('costo_unitario').getValue(),
                    codigo_sucursal : this.getField('codigo_sucursal').getValue(),
                    ch_afecta_costo : this.getField('ch_afecta_costo').getValue(),
                    fecha : this.getField('fecha').getValue(),
                    select_bodega : this.getField('select_bodega').getValue(),
                    codigo_articulo : this.getField('codigo_articulo').getValue(),
                    desde : this.getField('desde').getValue(),
                }}
                if(this.costo_administrativo==='S')
                    datos.datos.costo_adm=this.getField('costo_adm').getValue();
                this.mostrarMensajeGenerando();
                this.generalFormatPmv = { tipo_servicio: 'inve-agregartransaccion', operacion:  'actualizaritems', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.succesActualizarItems,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });


            }
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    succesActualizarItems(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            if(data.data.mensaje_mostrar!==undefined){
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
            }else{
                this.refrescarTabla();
                
            }
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    onSelectionChanged() {
        this.fila_seleccionada = this.gridOptionsTbPrincipal.api.getSelectedRows();
        if(this.fila_seleccionada.length>0)
            this.getField('orden_anterior').setValue(this.fila_seleccionada[0].orden);
    }

    
    onSelectionChanged2() {

    }


    validarfechas() {
        this.getField('bt_ver_historia').setDisabled(false);
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
        this.ocultarCampos();

        this.getField("desde").setError(false, "");
        if (fecha_desde > fecha_actual) {
            this.getField("desde").setError(true, "No puede ser mayor a la fecha actual.");
            this.getField('bt_ver_historia').setDisabled(true);
            return false;
        }

    }


    ocultarCampos() {
        this.fila_seleccionada=[];
        this.getField('rejilla').toggle(false);
        this.getField('bt_ver_historia').setDisabled(false);

        //campos de abajo
        this.getField('numero_documento').setVisible(false);
        this.getField('select_tipo').setVisible(false);
        this.getField('fecha').setVisible(false);
        this.getField('cantidad').setVisible(false);
        this.getField('orden_anterior').setVisible(false);
        this.getField('orden_fin_hueco').setVisible(false);
        this.getField('costo_unitario').setVisible(false);
        this.getField('costo_adm').setVisible(false);
        this.getField('ch_afecta_costo').setVisible(false);
        this.getField('codigo_sucursal').setVisible(false);
        this.getField('nombre_sucursal').setVisible(false);
        this.getField('bt_ver_procesar').setVisible(false);
        this.getField('bt_asentar').setVisible(false);
        this.getField('rejilla_logs').toggle(false);


    }



    consultarHistoria() {
        let errores = 0;
        this.ocultarCampos();
        this.getField('desde').setError(false,'');
        if (this.validarfechas() === false)
            errores++;
        if(!this.getField('codigo_articulo').valid())
            errores++;
        if(!this.getField('nombre_articulo').valid())
            errores++;
        if(!this.getField('select_bodega').valid())
            errores++;
        if (errores === 0) {
            this.getField('bt_ver_historia').setDisabled(true);
            let datos = {
                datos: {
                    codigo_articulo: this.getField("codigo_articulo").getValue(),
                    select_bodega: this.getField("select_bodega").getValue(),
                    desde: this.getField("desde").getValue(),
                    sucursal_ingreso: this.sucursal_ingreso,
                }
            };
            this.mostrarMensajeGenerando();
            this.getField("rejilla").setTipoServicioOperacion('inve-agregartransaccion-historia');
            this.getField("rejilla").sendRequest(this.successDatosPrincipal, datos); 
        }
    }

    successDatosPrincipal(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            //limio la temporal
            this.gridOptionsTbTemporal['rowData']=[];
            //---->
            this.gridOptionsTbPrincipal['rowData'] = data.data.items;
            let configCell = new Map();
            configCell.set('costo_unitario', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costo_unitario) }, type: 'rightAligned' });
            configCell.set('cantidad', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.cantidad) }, type: 'rightAligned' });
            configCell.set('saldo_antes', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.saldo_antes) }, type: 'rightAligned' });
            configCell.set('saldo_despues', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.saldo_despues) }, type: 'rightAligned' });
            configCell.set('costopro_antes', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costopro_antes) }, type: 'rightAligned' });
            configCell.set('costopro_despues', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costopro_despues) }, type: 'rightAligned' });

            this.getField('rejilla').initData(this.gridOptionsTbPrincipal, configCell);
            this.getField('rejilla').toggle(true);

            //campos de abajo
            this.getField('numero_documento').setVisible(true);
            this.getField('select_tipo').setVisible(true);
            this.getField('fecha').setVisible(true);
            this.getField('cantidad').setVisible(true);
            this.getField('orden_anterior').setVisible(true);
            this.getField('orden_fin_hueco').setVisible(true);
            this.getField('costo_unitario').setVisible(true);
            if(this.costo_administrativo==='S'){
                this.getField('costo_adm').setVisible(true);
                this.getField('costo_adm').setValue('0');
            }
            this.getField('ch_afecta_costo').setVisible(true);
            this.getField('codigo_sucursal').setVisible(true);
            this.getField('nombre_sucursal').setVisible(true);
            this.getField('bt_ver_procesar').setVisible(true);
            this.getField('bt_asentar').setVisible(true);
            //limpiar errores
            this.getField('numero_documento').setError(false,'');
            this.getField('select_tipo').setError(false,'');
            this.getField('fecha').setError(false,'');
            this.getField('cantidad').setError(false,'');
            this.getField('orden_anterior').setError(false,'');
            this.getField('orden_fin_hueco').setError(false,'');
            this.getField('costo_unitario').setError(false,'');
            this.getField('costo_adm').setError(false,'');
            this.getField('ch_afecta_costo').setError(false,'');
            this.getField('codigo_sucursal').setError(false,'');
            this.getField('nombre_sucursal').setError(false,'');
            this.getField('bt_ver_procesar').setError(false,'');
            this.getField('bt_asentar').setError(false,'');
            //valores
            this.getField('numero_documento').setValue('');
            this.getField('select_tipo').setValue('');
            this.getField('fecha').setValue(this.fecha_hoy);
            this.getField('cantidad').setValue('0');
            this.getField('orden_anterior').setValue('');
            this.getField('orden_fin_hueco').setValue('');
            this.getField('costo_unitario').setValue('0');
            this.getField('ch_afecta_costo').setValue('0');
            this.getField('codigo_sucursal').setValue('');
            this.getField('nombre_sucursal').setValue('');

            if(data.data.sucursal!==undefined){
                this.getField('codigo_sucursal').setValue(data.data.sucursal[0].codigo_sucursal);
                this.getField('nombre_sucursal').setValue(data.data.sucursal[0].nombre_sucursal);
            }
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('bt_ver_historia').setDisabled(true);
        }
    }

    refrescarTabla() {
        this.mostrarMensajeGenerando();
        let datos = {
            datos: {
                codigo_articulo: this.getField("codigo_articulo").getValue(),
                select_bodega: this.getField("select_bodega").getValue(),
                desde: this.getField("desde").getValue(),
                sucursal_ingreso: this.sucursal_ingreso,
            }
        };
        this.mostrarMensajeGenerando();
        this.getField("rejilla_logs").setTipoServicioOperacion('inve-agregartransaccion-temporal');
        this.getField("rejilla_logs").sendRequest(this.successRefrescar, datos); 
    }

    successRefrescar(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.gridOptionsTbTemporal['rowData'] = data.data.items;
            let configCell = new Map();
            configCell.set('costo_unitario', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costo_unitario) }, type: 'rightAligned' });
            configCell.set('cantidad', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.cantidad) }, type: 'rightAligned' });
            configCell.set('saldo_antes', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.saldo_antes) }, type: 'rightAligned' });
            configCell.set('saldo_despues', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.saldo_despues) }, type: 'rightAligned' });
            configCell.set('costopro_antes', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costopro_antes) }, type: 'rightAligned' });
            configCell.set('costopro_despues', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costopro_despues) }, type: 'rightAligned' });

            this.getField('rejilla_logs').initData(this.gridOptionsTbTemporal, configCell);
            this.getField('rejilla_logs').toggle(true);
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('rejilla_logs').toggle(false);
        }
    }


    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > this.numero_decimales) {
                number = number.toFixed(this.numero_decimales); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


    mostrarMensajeAdicional(mensaje) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

    }

    mostrarMensajeNoHayDatos() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }


}
FormJaivana.addController("inve-agregartransaccion", CustomAgregarTransaccion);
export default CustomAgregarTransaccion