import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomModificarProducto
 * @author: Patricia Lopez Sanchez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomModificarProducto extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.validarcampos              = this.validarcampos.bind(this);
        this.validarcampos1             = this.validarcampos1.bind(this);
        this.validarcampos2             = this.validarcampos2.bind(this);
        this.validarcampos3             = this.validarcampos3.bind(this);
        this.validarcampos4             = this.validarcampos4.bind(this);
        this.validarcampos5             = this.validarcampos5.bind(this);
        this.cargariva                  = this.cargariva.bind(this);
        this.validarbarras              = this.validarbarras.bind(this);
        this.successTraerBarra          = this.successTraerBarra.bind(this);
        this.validarbarras2             = this.validarbarras2.bind(this);
        this.successTraerBarra2         = this.successTraerBarra2.bind(this);
        this.validarbarras3             = this.validarbarras3.bind(this);
        this.successTraerBarra3         = this.successTraerBarra3.bind(this);
        this.ModificarArticulo          = this.ModificarArticulo.bind(this);
        this.successArticulo            = this.successArticulo.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
        this.validarCodigoBarrasPrincipal   = this.validarCodigoBarrasPrincipal.bind(this);
        this.confirmarLimpiar           = this.confirmarLimpiar.bind(this);
        this.validarMarca               = this.validarMarca.bind(this);
        this.marca_id                   = true;
        this.successMarca               = this.successMarca.bind(this)

        this.categoria1_id              = true;
        this.validarCategoria1          = this.validarCategoria1.bind(this);
        this.successCategoria1          = this.successCategoria1.bind(this);

        this.categoria2_id              = true;
        this.validarCategoria2          = this.validarCategoria2.bind(this);
        this.successCategoria2          = this.successCategoria2.bind(this);

        this.categoria3_id              = true;
        this.validarCategoria3          = this.validarCategoria3.bind(this);
        this.successCategoria3          = this.successCategoria3.bind(this);

        this.categoria4_id              = true;
        this.validarCategoria4          = this.validarCategoria4.bind(this);
        this.successCategoria4          = this.successCategoria4.bind(this);

        this.tipo_producto_id           = true;
        this.validarTipoProducto        = this.validarTipoProducto.bind(this);
        this.successTipoProducto        = this.successTipoProducto.bind(this);
        this.limpiarCategoria1          = this.limpiarCategoria1.bind(this);
        this.limpiarCategoria2          = this.limpiarCategoria2.bind(this);
        this.limpiarCategoria3          = this.limpiarCategoria3.bind(this);

    }
    
    initForm(){
        console.log('Formulario CustomModificarProducto,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez, @updateBy Jose Albeiro Marin')

        this.arrayCampos.set(4,'nombre');
        this.arrayCampos.set(6,'alias');
        this.arrayCampos.set(7,'aceptar');
        this.arrayCampos.set(91,'contenedorDatos1');
        this.arrayCampos.set(92,'codigo_producto');
        this.arrayCampos.set(93,'nombre3');
        this.arrayCampos.set(94,'seleccionar');
        this.arrayCampos.set(96,'cancelar8');

        
        this.arrayObjetos.set(4,'nombre');
        this.arrayObjetos.set(6,'alias');
        this.arrayObjetos.set(7,'aceptar');
        this.arrayObjetos.set(91,'contenedorDatos1');
        this.arrayObjetos.set(92,'codigo_producto');
        this.arrayObjetos.set(93,'nombre3');
        this.arrayObjetos.set(94,'seleccionar');
        this.arrayObjetos.set(96,'cancelar8');
        
        this.establecerPropiedades('4,6,7','disable','true')
        this.establecerPropiedades('91,92,93,94,96','visible','true')

        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.getField('contenedorDatos1').handleClose();
                this.establecerPropiedades('4,6,7','disable','false')
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });     
        
        this.getField('contenedorDatos1').setCloseButton(()=>{this.establecerPropiedades('4,6,7','disable','true')})

        this.getField('aceptar').setClick(this.validarcampos);
        this.getField('cancelar').setClick(this.confirmarLimpiar);
        this.getField('aceptar1').setClick(this.validarcampos1);
        this.getField('cancelar1').setClick(this.confirmarLimpiar);
        this.getField('aceptar2').setClick(this.validarcampos2);
        this.getField('cancelar2').setClick(this.confirmarLimpiar);
        this.getField('aceptar3').setClick(this.validarcampos3);
        this.getField('cancelar3').setClick(this.confirmarLimpiar);
        this.getField('continuar').setClick(this.validarcampos4);
        this.getField('cancelar4').setClick(this.confirmarLimpiar);
        this.getField('continuar1').setClick(this.validarcampos5);
        this.getField('cancelar5').setClick(this.confirmarLimpiar);
        this.getField('continuar2').setClick(()=>{if(this.getField('barras').valid()){this.validarbarras()}})
        this.getField('cancelar6').setClick(this.confirmarLimpiar);
        this.getField('continuar3').setClick(this.ModificarArticulo);
        this.getField('cancelar7').setClick(this.confirmarLimpiar);
        this.getField('codigo_cat2').setDisabled(true)
        this.getField('codigo_cat3').setDisabled(true)
        this.getField('codigo_cat4').setDisabled(true)
        this.getField('presenta').setOnChange(()=>{if(this.getField('presenta').getValue() !== ''){this.getField('presenta').valid(false);}})
        this.getField('codigo_grupo').setOnChange(this.getField('nombre_grupo').setValue(''));
        this.getField('codigo_subgrupo').setOnChange(this.getField('nombre_subgrupo').setValue(''));
        this.getField('codigo_marca').setOnChange(()=>{this.getField('nombre_marca').setValue('');this.getField('marca_id').setValue('');});
        this.getField('codigo_clasificacion').setOnChange(this.getField('nombre_clasificacion').setValue(''));
        this.getField('codigo_cat1').setOnChange(this.limpiarCategoria1);
        this.getField('codigo_cat2').setOnChange(this.limpiarCategoria2);
        this.getField('codigo_cat3').setOnChange(this.limpiarCategoria3);
        this.getField('codigo_cat4').setOnChange(()=>{this.getField('nombre_categoria4').setValue('');this.getField('categoria4_id').setValue('')});
        this.getField('tarifas_retencion').setOnChange(()=>{if(this.getField('tarifas_retencion').getValue() !== ''){this.getField('tarifas_retencion').valid(false);}})
        this.getField('codigo_iva').setOnChange(()=>{this.getField('iva_ventas').setValue('');
        this.getField('iva_compras').setValue('');this.getField('iva').setValue('')});
        this.getField('iva_ventas').setOnChange(this.cargariva);
        this.getField('producto_importado').setOnChange(()=>{if(this.getField('producto_importado').getValue() !== ''){this.getField('producto_importado').valid(false);}})
        this.getField('TabJaivana_63').setClick(this.limpiar);
        this.getField('barras').setOnChange(this.validarCodigoBarrasPrincipal);
        this.getField('nombre_marca').setOnChange(this.validarMarca);
        this.getField('nombre_categoria1').setOnChange(this.validarCategoria1);
        this.getField('nombre_categoria2').setOnChange(this.validarCategoria2);
        this.getField('nombre_categoria3').setOnChange(this.validarCategoria3);
        this.getField('nombre_categoria4').setOnChange(this.validarCategoria4);
        this.getField('categoria1_id').setOnChange(()=>{this.getField('categoria1_id').getValue() !== '' ? this.getField('codigo_cat2').setDisabled(false) : this.getField('codigo_cat2').setDisabled(true)})
        this.getField('categoria2_id').setOnChange(()=>{this.getField('categoria2_id').getValue() !== '' ? this.getField('codigo_cat3').setDisabled(false) : this.getField('codigo_cat3').setDisabled(true)})
        this.getField('categoria3_id').setOnChange(()=>{this.getField('categoria3_id').getValue() !== '' ? this.getField('codigo_cat4').setDisabled(false) : this.getField('codigo_cat4').setDisabled(true)})
        this.getField('nombre_tipo').setOnChange(this.validarTipoProducto);

        this.getField('nombre3').setOnChange(()=>{
            this.marca_id=true;
            this.categoria1_id=true;
            this.categoria2_id=true;
            this.categoria3_id=true;
            this.categoria4_id=true;
            this.tipo_producto_id=true;
            });

    }
    validarTipoProducto(){
        // console.log("entroValidarTipoProducto",this.tipo_producto_id)
        if(this.tipo_producto_id === true){
            if(this.getField('nombre_tipo').getValue() !==''){
                let datos={ datos: {value: this.getField('codigo_tipo_producto').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-tiposproductos', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successTipoProducto,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }
    }

    successTipoProducto(data){
        if (data.estado_p === 200 ){
            // console.log("data",data)
            this.getField('tipo_producto_id').setValue(data.data[0].id);
            // console.log("tipoPro",data.data[0].id)
            // this.alertGeneral.toggle(true, 'Rspondio', 'success');
            this.tipo_producto_id = false
            // console.log('tipo_producto_id',this.tipo_producto_id )
        }else{
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    limpiarCategoria1(){
        if (this.getField('nombre_categoria1').getValue() !== '' && this.getField('nombre_categoria1').getValue() !== ' ')
            this.getField('nombre_categoria1').setValue('');
        if (this.getField('categoria1_id').getValue() !== '' && this.getField('categoria1_id').getValue() !== ' ')
            this.getField('categoria1_id').setValue('');
        this.getField('codigo_cat2').setDisabled(true)
        if (this.getField('codigo_cat2').getValue() !== '' && this.getField('codigo_cat2').getValue() !== ' ')
            this.getField('codigo_cat2').setValue('');
        this.getField('codigo_cat2').setError(false,'');
        this.limpiarCategoria2();
    }
    limpiarCategoria2(){
        if (this.getField('nombre_categoria2').getValue() !== '' && this.getField('nombre_categoria2').getValue() !== ' ')
            this.getField('nombre_categoria2').setValue('');
        if (this.getField('categoria2_id').getValue() !== '' && this.getField('categoria2_id').getValue() !== ' ')
            this.getField('categoria2_id').setValue('');
        this.getField('codigo_cat3').setDisabled(true)
        if (this.getField('codigo_cat3').getValue() !== '' && this.getField('codigo_cat3').getValue() !== ' ')
            this.getField('codigo_cat3').setValue('');
        this.getField('codigo_cat3').setError(false,'');
        this.limpiarCategoria3();
    }
    limpiarCategoria3(){
        if (this.getField('nombre_categoria3').getValue() !== '' && this.getField('nombre_categoria3').getValue() !== ' ')
            this.getField('nombre_categoria3').setValue('');
        if (this.getField('categoria3_id').getValue() !== '' && this.getField('categoria3_id').getValue() !== ' ')
            this.getField('categoria3_id').setValue('');
        this.getField('codigo_cat4').setDisabled(true)
        if (this.getField('codigo_cat4').getValue() !== '' && this.getField('codigo_cat4').getValue() !== ' ')
            this.getField('codigo_cat4').setValue('');
        this.getField('codigo_cat4').setError(false,'');
        if (this.getField('nombre_categoria4').getValue() !== '' && this.getField('nombre_categoria4').getValue() !== ' ')
            this.getField('nombre_categoria4').setValue('');
        if (this.getField('categoria4_id').getValue() !== '' && this.getField('categoria4_id').getValue() !== ' ')
            this.getField('categoria4_id').setValue('');
    }
    validarCategoria1(){
        // console.log("entroValidarCategoria1",this.categoria1_id)
        if (this.getField("codigo_producto").getValue() !== '' && this.getField("nombre3").getValue() !== '' && this.getField('codigo_cat1').getValue() !== '') {
            if(this.categoria1_id === true){
                this.getField('codigo_cat2').setDisabled(false)
                let datos={ datos: {value: this.getField('codigo_cat1').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-categoria1', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successCategoria1,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }
    }

    successCategoria1(data){
        if (data.estado_p === 200 )
        {
            // console.log("dataCategoria1",data)
            this.getField('categoria1_id').setValue(data.data[0].id);
            // console.log("cate1",data.data[0].id)
            // this.alertGeneral.toggle(true, 'Rspondio', 'success');
            this.categoria1_id = false
            // console.log('categoria1_id',this.categoria1_id )
        }else if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        } else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    validarCategoria2(){
        // console.log("entroValidarCategoria2",this.categoria2_id)
        if (this.getField("codigo_producto").getValue() !== '' && this.getField("nombre3").getValue() !== '' && this.getField('codigo_cat2').getValue() !== '') {
            if(this.categoria2_id === true){
                this.getField('codigo_cat3').setDisabled(false)
                let datos={ datos: {value: this.getField('codigo_cat2').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-categoria2', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successCategoria2,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }
    }

    successCategoria2(data){
        if (data.estado_p === 200 )
        {
            // console.log("dataCategoria2",data)
            this.getField('categoria2_id').setValue(data.data[0].categoria2_id);
            // console.log("cate2",data.data[0].categoria2_id)
            // this.alertGeneral.toggle(true, 'Rspondio', 'success');
            this.categoria2_id = false
            // console.log('categoria2_id',this.categoria2_id )
        }else if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        } else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    validarCategoria3(){
        // console.log("entroValidarCategoria3",this.categoria3_id)
        if (this.getField("codigo_producto").getValue() !== '' && this.getField("nombre3").getValue() !== '' && this.getField('codigo_cat3').getValue() !== '') {
            if(this.categoria3_id === true){
                this.getField('codigo_cat4').setDisabled(false)
                let datos={ datos: {value: this.getField('codigo_cat3').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-categoria3', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successCategoria3,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }
    }

    successCategoria3(data){
        if (data.estado_p === 200 ){
            // console.log("dataCategoria3",data)
            this.getField('categoria3_id').setValue(data.data[0].categoria3_id);
            // console.log("cate3",data.data[0].categoria3_id)
            // this.alertGeneral.toggle(true, 'Rspondio', 'success');
            this.categoria3_id = false
            // console.log('categoria3_id',this.categoria3_id )
        }else if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        } else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    validarCategoria4(){
        // console.log("entroValidarCategoria4",this.categoria4_id)
        if (this.getField("codigo_producto").getValue() !== '' && this.getField("nombre3").getValue() !== '' && this.getField('codigo_cat4').getValue() !== '') {
            if(this.categoria3_id === true){
                let datos={ datos: {value: this.getField('codigo_cat4').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-categoria4', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successCategoria4,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }
    }

    successCategoria4(data){
        if (data.estado_p === 200 ){
            // console.log("dataCategoria4",data)
            this.getField('categoria4_id').setValue(data.data[0].categoria4_id);
            // console.log("cate4",data.data[0].categoria4_id)
            // this.alertGeneral.toggle(true, 'Rspondio', 'success');
            this.categoria4_id = false
            // console.log('categoria4_id',this.categoria4_id )
        }else if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        } else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    
    validarMarca(){
        // console.log(this.marca_id)
        if(this.marca_id === true){
            if(this.getField('nombre_marca').getValue() !==''){
                let datos={ datos: {value: this.getField('codigo_marca').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-marcas', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successMarca,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }
    }

    successMarca(data){
        if (data.estado_p === 200 ){
            this.getField('marca_id').setValue(data.data[0].marcas_id);
            // console.log("marcaid",data.data[0].marcas_id)
            // this.alertGeneral.toggle(true, 'Rspondio', 'success');
            this.marca_id = false
            // console.log('marca_id',this.marca_id )
        }else{
           if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');  
            }else{
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    validarCodigoBarrasPrincipal(){
        this.getField('continuar2').setDisabled(false);   
    }

    validarcampos(){
        if (this.getField('nombre').valid() && this.getField('alias').valid()) {
            this.getField('TabJaivana_63').setNextTab()
        }
    }

    validarcampos1(){
        if (this.getField('codigo_grupo').valid() && this.getField('codigo_subgrupo').valid() && this.getField('codigo_subgrupo_1').valid() &&
            this.getField('codigo_marca').valid()  && this.getField('codigo_clasificacion').valid() &&
            this.getField('nombre_grupo').valid() && this.getField('nombre_subgrupo').valid() && this.getField('nombre_marca').valid() && 
            this.getField('nombre_clasificacion').valid() && this.getField('marca_id').valid()) {
            this.getField('TabJaivana_63').setNextTab()
        }
    }
    
    validarcampos2(){
        if (this.getField('nombre_categoria4').valid() && this.getField('categoria1_id').valid() && 
            this.getField('categoria2_id').valid() && this.getField('categoria3_id').valid() && this.getField('categoria4_id').valid()) {
            this.getField('TabJaivana_63').setNextTab()
        }
    }
    
    validarcampos3(){
        if (this.getField('presenta').valid() && this.getField('empaque').valid() &&
            this.getField('contenido').valid() && this.getField('peso').valid()) {
            this.getField('TabJaivana_63').setNextTab()
        }
    }
    
    validarcampos4(){
        if (this.getField('tarifas_retencion').valid() && this.getField('codigo_iva').valid() && this.getField('codigo_tipo_producto').valid() &&
            this.getField('nombre_tipo').valid() && this.getField('iva_ventas').valid() && 
            this.getField('iva_compras').valid() && this.getField('iva').valid() && this.getField('gravado').valid() && 
            this.getField('exento').valid() && this.getField('excluido').valid() && this.getField('tipo_producto_id').valid()) {
            this.getField('TabJaivana_63').setNextTab()
        }
    }

    validarcampos5(){
        if (this.getField('producto_importado').valid() && this.getField('partida_arancelaria').valid()) {
            this.getField('TabJaivana_63').setNextTab()
        }
    }

    cargariva(){
        if (this.getField('iva_ventas').getValue() !== 0) {
            this.getField('iva').setValue('S')
        }else{
            this.getField('iva').setValue('N')
        }
    }

    validarbarras(){
        let datos={ datos: {barras: this.getField('barras').getValue(), codigo_interno:this.getField('codigo_interno').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-modificararticulo', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerBarra,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successTraerBarra(data){
        if (data.estado_p === 200 )
        {
            this.getField('TabJaivana_63').setNextTab()
        }
        else 
        {
           if(data.estado_p===404) {
                this.validarbarras2();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    validarbarras2(){
        let datos={ datos: {barras: this.getField('barras').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-modificararticulo', operacion: '20_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerBarra2,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successTraerBarra2(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El Código de barras ya existe en la tabla artículo', 'error');
            this.getField('continuar2').setDisabled(true);   
            this.getField('barras').input.focus();
        }
        else 
        {
           if(data.estado_p===404) {
                this.validarbarras3();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }
    
    validarbarras3(){
        let datos={ datos: {barras: this.getField('barras').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-modificararticulo', operacion: '20_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerBarra3,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successTraerBarra3(data){
        if (data.estado_p === 200 )
        {
            this.getField('continuar2').setDisabled(true);   
            this.getField('barras').input.focus();
            this.alertGeneral.toggle(true, 'El Código de barras ya existe en la tabla barras artículo', 'error');
        }
        else 
        {
           if(data.estado_p===404) {
                this.getField('TabJaivana_63').setNextTab()
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    ModificarArticulo(){
        if (this.getField('codigo_interno').valid() && this.getField('codigo_alterno').valid()) {
            this.getField('vivac').setValue(this.getField('iva_ventas').getValue());
            this.getField('viva').setValue(this.getField('iva_ventas').getValue());
            this.datosAgregar={ datos:{}};
            this.datosAgregar.datos['articulos_id']=this.getField('articulos_id').getValue();
            this.datosAgregar.datos['nombre']=this.getField('nombre').getValue();
            this.datosAgregar.datos['codigo_subgrupo']=this.getField('codigo_subgrupo').getValue();
            this.datosAgregar.datos['codigo_grupo']=this.getField('codigo_grupo').getValue();
            this.datosAgregar.datos['codigo_interno']=this.getField('codigo_interno').getValue();
            this.datosAgregar.datos['peso']=this.getField('peso').getValue();
            this.datosAgregar.datos['codigo_subgrupo_1']=this.getField('codigo_subgrupo_1').getValue();
            this.datosAgregar.datos['exento']=this.getField('exento').getValue();
            this.datosAgregar.datos['barras']=this.getField('barras').getValue();
            this.datosAgregar.datos['codigo_marca']=this.getField('codigo_marca').getValue();
            this.datosAgregar.datos['partida_arancelaria']=this.getField('partida_arancelaria').getValue();
            this.datosAgregar.datos['codigo_clasificacion']=this.getField('codigo_clasificacion').getValue();
            this.datosAgregar.datos['marca_id']=this.getField('marca_id').getValue();
            this.datosAgregar.datos['gravado']=this.getField('gravado').getValue();
            this.datosAgregar.datos['alias']=this.getField('alias').getValue();
            this.datosAgregar.datos['excluido']=this.getField('excluido').getValue();
            this.datosAgregar.datos['contenido']=this.getField('contenido').getValue();
            this.datosAgregar.datos['codigo_tipo_producto']=this.getField('codigo_tipo_producto').getValue();
            this.datosAgregar.datos['tarifas_retencion']=this.getField('tarifas_retencion').getValue();
            this.datosAgregar.datos['vivac']=this.getField('vivac').getValue();
            this.datosAgregar.datos['producto_importado']=this.getField('producto_importado').getValue();
            this.datosAgregar.datos['viva']=this.getField('viva').getValue();
            this.datosAgregar.datos['codigo_iva']=this.getField('codigo_iva').getValue();
            this.datosAgregar.datos['empaque']=this.getField('empaque').getValue();
            this.datosAgregar.datos['iva']=this.getField('iva').getValue();
            this.datosAgregar.datos['presenta']=this.getField('presenta').getValue();
            this.datosAgregar.datos['codigo_cat1']=this.getField('codigo_cat1').getValue();
            this.datosAgregar.datos['codigo_cat2']=this.getField('codigo_cat2').getValue();
            this.datosAgregar.datos['codigo_cat3']=this.getField('codigo_cat3').getValue();
            this.datosAgregar.datos['codigo_cat4']=this.getField('codigo_cat4').getValue();
            this.datosAgregar.datos['categoria1_id']=this.getField('categoria1_id').getValue();
            this.datosAgregar.datos['categoria2_id']=this.getField('categoria2_id').getValue();
            this.datosAgregar.datos['categoria3_id']=this.getField('categoria3_id').getValue();
            this.datosAgregar.datos['categoria4_id']=this.getField('categoria4_id').getValue();
            this.datosAgregar.datos['tipo_producto_id']=this.getField('tipo_producto_id').getValue();
            this.datosAgregar.datos['codigo_alterno']=this.getField('codigo_alterno').getValue();
            let datos=this.datosAgregar;
                this.generalFormatPmv = { tipo_servicio: 'maes-modificararticulo', operacion: '6', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successArticulo,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
        }
    }

    successArticulo(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El Artículo se modifico correctamente', 'success');
            this.limpiar();
        }
        else 
        {
           if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }
    
    confirmarLimpiar(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
        this.getField('confirmModalCustom').setClickDialog(this.limpiar);
        this.getField('confirmModalCustom').toggle(true);

    }

    limpiar(){
        this.getField('confirmModalCustom').toggle(false);
        this.establecerPropiedades('91,92,93,94,96','visible','true')
        this.marca_id=true
        this.tipo_producto_id=true;
        this.categoria1_id=true;
        this.categoria2_id=true;
        this.categoria3_id=true;
        this.categoria4_id=true;


        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.getField('contenedorDatos1').handleClose();
                this.establecerPropiedades('4,6,7','disable','false')
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });   
        this.getField('TabJaivana_63').reInit();
        this.getField('nombre').setValue('');
        this.getField('alias').setValue('');
        this.getField('codigo_grupo').setValue('');
        this.getField('codigo_subgrupo').setValue('');
        this.getField('codigo_subgrupo_1').setValue('');
        this.getField('codigo_marca').setValue('');
        this.getField('marca_id').setValue('');
        this.getField('codigo_clasificacion').setValue('');
        this.getField('nombre_grupo').setValue('');
        this.getField('nombre_subgrupo').setValue('');
        this.getField('nombre_marca').setValue('');
        this.getField('marca_id').setValue('');
        this.getField('nombre_clasificacion').setValue('');
        this.getField('codigo_cat1').setValue('');
        this.getField('codigo_cat2').setValue('');
        this.getField('codigo_cat3').setValue('');
        this.getField('codigo_cat4').setValue('');
        this.getField('nombre_categoria1').setValue('');
        this.getField('categoria1_id').setValue('');
        this.getField('nombre_categoria2').setValue('');
        this.getField('categoria2_id').setValue('');
        this.getField('nombre_categoria3').setValue('');
        this.getField('categoria3_id').setValue('');
        this.getField('nombre_categoria4').setValue('');
        this.getField('categoria4_id').setValue('');
        this.getField('presenta').setValue('');
        this.getField('empaque').setValue('');
        this.getField('contenido').setValue('1');
        this.getField('peso').setValue('1');
        this.getField('nombre_tipo').setValue('');
        this.getField('gravado').setValue('');
        this.getField('exento').setValue('');
        this.getField('excluido').setValue('');
        this.getField('codigo_iva').setValue('');
        this.getField('codigo_tipo_producto').setValue('');
        this.getField('tarifas_retencion').setValue('');
        this.getField('tipo_producto_id').setValue('');
        this.getField('iva_ventas').setValue('');
        this.getField('iva_compras').setValue('');
        this.getField('iva').setValue('');
        this.getField('producto_importado').setValue('');
        this.getField('partida_arancelaria').setValue('');
        this.getField('barras').setValue('');   
        this.getField('codigo_interno').setValue(''); 
        this.getField('codigo_alterno').setValue('');      
        
        this.getField('nombre').valid(false);
        this.getField('alias').valid(false);
        this.getField('codigo_grupo').valid(false);
        this.getField('codigo_subgrupo').valid(false);
        this.getField('codigo_subgrupo_1').valid(false);
        this.getField('codigo_marca').valid(false);
        this.getField('marca_id').valid(false);
        this.getField('codigo_clasificacion').valid(false);
        this.getField('nombre_grupo').valid(false);
        this.getField('nombre_subgrupo').valid(false);
        this.getField('nombre_marca').valid(false);
        this.getField('marca_id').valid(false);
        this.getField('nombre_clasificacion').valid(false);
        this.getField('codigo_cat1').valid(false);
        this.getField('codigo_cat2').valid(false);
        this.getField('codigo_cat3').valid(false);
        this.getField('codigo_cat4').valid(false);
        this.getField('nombre_categoria1').valid(false);
        this.getField('categoria1_id').valid(false);
        this.getField('nombre_categoria2').valid(false);
        this.getField('categoria2_id').valid(false);
        this.getField('nombre_categoria3').valid(false);
        this.getField('categoria3_id').valid(false);
        this.getField('nombre_categoria4').valid(false);
        this.getField('categoria4_id').valid(false);
        this.getField('presenta').valid(false);
        this.getField('empaque').valid(false);
        this.getField('contenido').valid(false);
        this.getField('peso').valid(false);
        this.getField('nombre_tipo').valid(false);
        this.getField('gravado').valid(false);
        this.getField('exento').valid(false);
        this.getField('excluido').valid(false);
        this.getField('codigo_iva').valid(false);
        this.getField('codigo_tipo_producto').valid(false);
        this.getField('tarifas_retencion').valid(false);
        this.getField('tipo_producto_id').valid(false);
        this.getField('iva_ventas').valid(false);
        this.getField('iva_compras').valid(false);
        this.getField('iva').valid(false);
        this.getField('producto_importado').valid(false);
        this.getField('partida_arancelaria').valid(false);
        this.getField('barras').valid(false);
        this.getField('codigo_interno').valid(false);
        this.getField('codigo_alterno').valid(false);

    }

    
    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }
}
FormJaivana.addController("maes-modificararticulo",CustomModificarProducto);
export default CustomModificarProducto