import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Santiago Hernández N.
 *
 * @version jdesk_10.0.001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomRelacionarCuentasNiif extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.datosAgregar               = {datos:{}};

        this.habilitarsegundobuscador   = this.habilitarsegundobuscador.bind(this);
        this.validarcuentaid            = this.validarcuentaid.bind(this);
        this.abrirtablaprincipal        = this.abrirtablaprincipal.bind(this);
        this.gridOptionsTbPrincipal     = Object.assign({},this.gridOptions);
        this.successMostrar             = this.successMostrar.bind(this);
        this.setButtonEliminar          = this.setButtonEliminar.bind(this);
        this.Eliminar                   = this.Eliminar.bind(this);
        this.ConfirmDelete              = this.ConfirmDelete.bind(this);
        this.successEliminar            = this.successEliminar.bind(this);
        this.validarexiste              = this.validarexiste.bind(this);
        this.successValidar             = this.successValidar.bind(this);
        this.guardar                    = this.guardar.bind(this);
        this.successGrabar              = this.successGrabar.bind(this);    
        this.limpiar                    = this.limpiar.bind(this); 
    }

    initForm(){
     
        console.log('Formulario CustomRelacionarCuentasNiif,  @version: jdesk_10.0.001, @author:Santiago Hernández N.')
        this.abrirtablaprincipal();
        this.getField('nombre_cuenta_origen').setDisabled(true);
        this.getField('codigo_cuenta_destino').setDisabled(true);
        this.getField('nombre_cuenta_destino').setDisabled(true);
        this.getField('btn_agregar').setDisabled(true);    
        this.getField('nombre_cuenta_origen').setOnChange(()=> {this.habilitarsegundobuscador(); this.validarcuentaid();});
        this.getField('codigocuentaorigen_id').setOnChange(this.validarcuentaid);
        this.getField('codigocuentadestino_id').setOnChange(this.validarcuentaid);
        this.getField('nombre_cuenta_destino').setOnChange(this.validarcuentaid);
        this.getField('btn_agregar').setClick(this.validarexiste);

    }

    habilitarsegundobuscador(){
        if (this.getField('nombre_cuenta_origen').getValue() !== '' && this.getField('codigocuentaorigen_id').getValue() !== '') {
            this.getField('codigo_cuenta_destino').setDisabled(false);
        } else {
            this.getField('codigo_cuenta_destino').setDisabled(true);
            this.getField('codigo_cuenta_destino').setValue('');
            this.getField('nombre_cuenta_destino').setDisabled(true);
            this.getField('nombre_cuenta_destino').setValue('');
            this.getField('codigocuentadestino_id').setValue('');
        }
    }

    validarcuentaid(){
        if (this.getField('codigocuentaorigen_id').getValue() !== "" && this.getField('codigocuentadestino_id').getValue() !== "" &&
        this.getField('nombre_cuenta_origen').getValue() !== "" && this.getField('nombre_cuenta_destino').getValue() !== "") {
            if ((this.getField('codigocuentaorigen_id').getValue()===this.getField('codigocuentadestino_id').getValue())/*  && (this.getField('codigocuentaorigen_id').getValue()!=="") && (this.getField('codigocuentadestino_id').getValue()!=="") */){
    
                this.alertGeneral.toggle(true, 'La cuenta origen y cuenta destino no pueden ser iguales.', 'error');
    
            }else{
                if(this.getField('nombre_cuenta_destino')!==""){
                    this.getField('btn_agregar').setDisabled(false);
                }
            }
        }else{
            this.getField('btn_agregar').setDisabled(true);
        }
    }

    abrirtablaprincipal(){
        let datos = { datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'maes-relacuentasniif', operacion: '1_1', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successMostrar,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }
    successMostrar(data){
        if(data.estado_p === 200){
            this.getField('tb_relacioncuentasniif').toggle(true);
            let configCell = new Map();
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            configCell.set('eliminar', { cellRenderer: this.setButtonEliminar, width: 110, sortable: false, filter: false, field: 'eliminar' });
            this.getField('tb_relacioncuentasniif').initData(this.gridOptionsTbPrincipal,configCell);
        }else{
            this.getField('tb_relacioncuentasniif').toggle(false);
        }
    }

    setButtonEliminar(props){
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = () => this.Eliminar(id);
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    Eliminar(id){
      this.ids = id;
      this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
      this.getField('confirmModalCustom').setClickDialog(this.ConfirmDelete);
      this.getField('confirmModalCustom').toggle(true);
    }

    ConfirmDelete(){
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
              id: this.ids,
            }
        };

        this.generalFormatPmv = { tipo_servicio: 'maes-relacuentasniif', operacion: '7', operacion_tipo: "eliminar" };
        this.service.send(
          {
              endpoint: this.constant.formConfiguration(),
              method: 'DELETE',
              body: datos,
              success: this.successEliminar,
              error: this.error_,
              general: this.generalFormatPmv //se envia el generales de arriba
          });
    }



    successEliminar(data){
        if(data.estado_p === 200){
            this.abrirtablaprincipal();
        }else{
            this.alertGeneral.toggle(true, 'El registro no pudo ser eliminado.', 'error');
        }
    }

    validarexiste(){
        if((this.getField('codigo_cuenta_origen').valid()) && (this.getField('nombre_cuenta_origen').valid()) && (this.getField('codigocuentaorigen_id').valid()) 
        && (this.getField('codigo_cuenta_destino').valid()) && (this.getField('nombre_cuenta_destino').valid()) && (this.getField('codigocuentadestino_id').valid())){
        let datos = { datos: {
            codigocuentaorigen_id: this.getField('codigocuentaorigen_id').getValue(),
            codigocuentadestino_id: this.getField('codigocuentadestino_id').getValue()
        }};
          this.generalFormatPmv = { tipo_servicio: 'maes-relacuentasniif', operacion: '20', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successValidar,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
        } else {
          this.alertGeneral.toggle(true, 'Error en los datos ', 'error');
        }
    }
    successValidar(data){
      if(data.estado_p === 200){
        this.alertGeneral.toggle(true, 'Las cuentas origen y destino ingresadas ya existen, no se permite duplicar', "error");
      }else if(data.estado_p === 404){
        this.guardar();
      }
    }

    guardar(){
        this.datosAgregar.datos['codigocuentaorigen_id']=this.getField('codigocuentaorigen_id').getValue();
        this.datosAgregar.datos['codigocuentadestino_id']=this.getField('codigocuentadestino_id').getValue();
        let datos=this.datosAgregar;
        this.generalFormatPmv = { tipo_servicio: 'maes-relacuentasniif', operacion: '5', operacion_tipo: 'crear' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.successGrabar,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
      }

    successGrabar(data) {
        if (data.estado_p === 200)
        {
            this.limpiar();
            this.abrirtablaprincipal();
        }
        else
        {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    limpiar() {
        this.getField('codigo_cuenta_origen').setValue('');
        this.getField('nombre_cuenta_origen').setValue('');
        this.getField('codigocuentaorigen_id').setValue('');
        this.getField('codigo_cuenta_destino').setValue('');
        this.getField('nombre_cuenta_destino').setValue('');
        this.getField('codigocuentadestino_id').setValue('');
    }
}

FormJaivana.addController("maes-relacuentasniif",CustomRelacionarCuentasNiif);
export default CustomRelacionarCuentasNiif