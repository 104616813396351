import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomCuentasPorPagarPorEdades
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 **/
class CustomCuentasPorPagarPorEdades extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChangedPrincipal          = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']                            = this.onSelectionChangedPrincipal.bind(this);


    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.validarConsultar                                  = this.validarConsultar.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
    
    this.ocultarCampos                                  = this.ocultarCampos.bind(this);


    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);
    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.successCrearTemporal                         =this.successCrearTemporal.bind(this);
    this.successTraerDatosCartera                         =this.successTraerDatosCartera.bind(this);
    this.arreglarDecimales                         =this.arreglarDecimales.bind(this);

    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.boton='';
    this.fecha_inicio='';
    this.sucursal_ingreso='';
    this.fila_seleccionada=[];
  }
  
  initForm() {
    console.log("Formulario CustomCuentasPorPagarPorEdades,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.archivo_servicio='comp-cuentasporpagarporedades';
    //nuevo para consulta
    this.getField('consultar').setClick(()=>{
        this.boton='sucursalxcartera'
        this.validarConsultar();
    });

    this.getField('bt_toda_la_cartera').setClick(()=>{
        this.boton='toda'
        this.validarConsultar();
    });

    this.getField('codigo_sucursal').setOnChange(()=>{
        this.getField('nombre_sucursal').setError(false,'');
        this.ocultarCampos();
        
    });

    this.getField('select_cuentas').setOnChange(()=>{
        this.getField('select_cuentas').setError(false,'');
        this.ocultarCampos();
        
    });

    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('consultar_desde').setValue(this.fecha_inicio);
    this.getField('consultar_desde').setOnChange(()=>{
        this.validarCamposFecha();
    });
    //botones principal
    this.getField('bt_ver_detalle').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.nombre_archivo='lista_cartera_proveedores_edades';
            this.abrirImprimir();
        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        }
    });


    //imprimir
    this.getField('radio_correo').setValue("N");
    this.getField('generar_archivo').setClick(()=>{
        this.procesar();
    });

    //para balances globales
    this.getField('ch_excel').setOnChange((props)=>{
      if(this.getField('ch_excel').getValue()==='false'){
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='excel';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pdf').setOnChange((props)=>{
      if(this.getField('ch_pdf').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='pdf';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pantalla').setOnChange((props)=>{
      if(this.getField('ch_pantalla').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.operacion_actual='pantalla';
        this.deshabilitarCorreo();
      }else{
          this.habilitarCorreo();
      }
    });

  }


  validarCamposFecha(){
        this.getField('consultar').setDisabled(false);
        this.getField('bt_toda_la_cartera').setDisabled(false);
        this.getField("consultar_desde").setError(false,'');
        this.ocultarCampos();
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];

        if(fechaInicial > fecha_actual){
            this.getField('consultar_desde').setError(true,"¡Fecha desde, no puede ser mayor a la fecha actual!");
            this.getField('consultar').setDisabled(true);
            this.getField('bt_toda_la_cartera').setDisabled(true);
            return false;
        }

        this.getField('consultar_desde').setError(false,"");
        return true;
    }


  onSelectionChangedPrincipal(){
    this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
  }

  ocultarCampos(){
    this.fila_seleccionada=[];
    this.getField('consultar').setDisabled(false);
    this.getField('bt_toda_la_cartera').setDisabled(false);
    this.getField('rejilla_items').toggle(false);
    this.getField('bt_ver_detalle').setVisible(false);
  }

  traerPropiedades(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'comp-cuentasporpagarporedades', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
}

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



  validarConsultar(){

    this.getField('codigo_sucursal').setError(false,'');
    this.getField('nombre_sucursal').setError(false,'');
    this.getField('select_cuentas').setError(false,'');
    this.getField('consultar_desde').setError(false,'');

    let errores=0;
    if(this.validarCamposFecha()===false)
        errores++;
    if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ' && this.boton!=='toda'){
        if(!this.getField('codigo_sucursal').valid())
            errores++;
        if(!this.getField('nombre_sucursal').valid())
            errores++;
    }
    if(this.getField('select_cuentas').getValue()!=='' && this.getField('select_cuentas').getValue()!==' '){
        if(!this.getField('select_cuentas').valid())
            errores++;
    }
    if(errores===0){
        this.mostrarMensajeGenerando();
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            consultar_desde:this.getField('consultar_desde').getValue(),
        }}
        if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ' && this.boton!=='toda')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('select_cuentas').getValue()!=='' && this.getField('select_cuentas').getValue()!==' ')
            datos.datos.select_cuentas=this.getField('select_cuentas').getValue();
        this.mostrarMensajeGenerando();
        this.generalFormatPmv = { tipo_servicio: 'comp-cuentasporpagarporedades', operacion:  'generarcarteraxsucursal', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrearTemporal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }

  }

  successCrearTemporal(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        this.getField('consultar').setDisabled(true);
        this.getField('bt_toda_la_cartera').setDisabled(true);
        if(data.data.mensaje_mostrar!==undefined){
            this.mostrarMensajeGenerando();
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje_mostrar);
            this.getField('confirmModalCustom').setClickDialog(() => { 
                this.getField('confirmModalCustom').toggle(false); 
                let datos={datos:{
                    sucursal_ingreso: this.sucursal_ingreso,
                    input:'0',
                    consultar_desde:this.getField('consultar_desde').getValue(),
                }}
                if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ' && this.boton!=='toda')
                    datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                if(this.getField('select_cuentas').getValue()!=='' && this.getField('select_cuentas').getValue()!==' ')
                    datos.datos.select_cuentas=this.getField('select_cuentas').getValue();
                this.mostrarMensajeGenerando();
                this.generalFormatPmv = { tipo_servicio: 'comp-cuentasporpagarporedades', operacion:  'generarcarteraxsucursal', operacion_tipo: 'crear' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successCrearTemporal,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
            this.getField('confirmModalCustom').toggle(true);
        }else{
            let datos={datos:{
                sucursal_ingreso: this.sucursal_ingreso,
            }}
            this.mostrarMensajeGenerando();
            this.getField("rejilla_items").setTipoServicioOperacion('comp-cuentasporpagarporedades-1_cuentas');
            this.getField("rejilla_items").sendRequest(this.successTraerDatosCartera, datos); 
        }
    }else {
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }
  }

  successTraerDatosCartera(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){ 
            this.getField('rejilla_items').toggle(true);
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            configCell.set('cupo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cupo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('porvencer', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.porvencer) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vencido', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vencido) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('d1_30', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.d1_30) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('d31_60', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.d31_60) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('d61_90', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.d61_90) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('d91_100', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.d91_100) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('d101_120', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.d101_120) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('d121', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.d121) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('ventas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.ventas) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('devventas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.devventas) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('notas', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.notas) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cheques', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cheques) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('recaudo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.recaudo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('d1_d8', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.d1_d8) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_items').initData(this.gridOptionsComponentes,configCell);

            this.getField('bt_ver_detalle').setVisible(true);
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.mostrarMensajeNoHayDatos();
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
}

  onSelectionChanged(){
    
  }


    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }
    
      mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }

    //modal imprimir

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    abrirImprimir(){
            
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
  }

  procesar(){
    let errores =0;
    if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
        if(this.getField('email').valid()===false){
            errores++;
        }
    }
    if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
        errores++;
        this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
        this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
        this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
        this.operacion_actual='';
        this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
    }
    
    if(errores===0){
        if(this.operacion_actual!==''){
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreoTerceros();
            }else{
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTabla3();
                }else if(this.operacion_actual==='pantalla'){
                        this.genearModalGlobales();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelTabla3();
                } 
            }
        }else{
            this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
            this.getField('confirmModalCustom').toggle(false);
        } 
    }
}

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
            nit:this.fila_seleccionada[0].nit,
        }}
        if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ' && this.boton!=='toda')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('select_cuentas').getValue()!=='' && this.getField('select_cuentas').getValue()!==' ')
            datos.datos.select_cuentas=this.getField('select_cuentas').getValue();
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: 'archivoitems_pag', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        this.mostrarMensajeEmailEnviado();
    }else if(data.estado_p === 404){
        this.mostrarMensajeNoHayDatos();
    }else{
        this.mostrarMensajeEmailFallo();
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
    }

    genearModalGlobales(){
        this.mostrarMensajeGenerando();
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
            nit:this.fila_seleccionada[0].nit,
        }}
        if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ' && this.boton!=='toda')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('select_cuentas').getValue()!=='' && this.getField('select_cuentas').getValue()!==' ')
            datos.datos.select_cuentas=this.getField('select_cuentas').getValue();


        this.getField("rejilla_items_result").setTipoServicioOperacion('comp-cuentasporpagarporedades-archivoitems');
        this.getField("rejilla_items_result").sendRequest(this.sucessTraerDatos, datos); 
    }

  sucessTraerDatos(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "nit": this.lista.nit,
                    "titulo": this.lista.titulo,
                    "fecha_sistema": this.lista.fecha_sistema,
                    "usuario": this.lista.usuario,
                };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);
            this.getField('modal_result').setLabel('Cuentas por Pagar por Edades');
            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
            configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('dias', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.dias) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }

  generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2={
                sucursal_ingreso: this.sucursal_ingreso,
                operacion : this.operacion_actual,
                email : this.getField('email').getValue(),
                radio_correo : this.getField('radio_correo').getValue(),
                nit:this.fila_seleccionada[0].nit,
            }

            if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ' && this.boton!=='toda')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('select_cuentas').getValue()!=='' && this.getField('select_cuentas').getValue()!==' ')
                datos2.select_cuentas=this.getField('select_cuentas').getValue();

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"archivoitems_pag","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2={
                sucursal_ingreso: this.sucursal_ingreso,
                operacion : this.operacion_actual,
                email : this.getField('email').getValue(),
                radio_correo : this.getField('radio_correo').getValue(),
                nit:this.fila_seleccionada[0].nit,
            }

            if(this.getField('nombre_sucursal').getValue()!=='' && this.getField('nombre_sucursal').getValue()!==' ' && this.boton!=='toda')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('select_cuentas').getValue()!=='' && this.getField('select_cuentas').getValue()!==' ')
                datos2.select_cuentas=this.getField('select_cuentas').getValue();
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"archivoitems_pag","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }


    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }
   
}


FormJaivana.addController("comp-cuentasporpagarporedades", CustomCuentasPorPagarPorEdades);
export default CustomCuentasPorPagarPorEdades;