import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Cristian Ciro
 * 
 * @version jdesk_1.01.0002 
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomConsultas extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);

        this.abrirConsulta              = this.abrirConsulta.bind(this);
        this.gridOptionsTbPrincipal     = Object.assign({},this.gridOptions);
        this.successDatosQuery          = this.successDatosQuery.bind(this);
        this.guardarConsulta            = this.guardarConsulta.bind(this);
        this.successGuardarConsultas    = this.successGuardarConsultas.bind(this);
        this.eliminarConsulta           = this.eliminarConsulta.bind(this);
        this.successEliminarConsultas   = this.successEliminarConsultas.bind(this);
        this.selectedProceso            = [];

        this.successConsultas           = this.successConsultas.bind(this);
        this.gridOptionsTbConsultas     = Object.assign({},this.gridOptions);
        this.gridOptionsTbParametros    = Object.assign({},this.gridOptions);
        this.gridOptionsTbConfParametros= Object.assign({},this.gridOptions);
        this.onSelectionChangedRowTableMov  = this.onSelectionChangedRowTableMov.bind(this);
        this.onSelectionChangedRowTableMov2  = this.onSelectionChangedRowTableMov2.bind(this);
        this.bloquearCampos             = this.bloquearCampos.bind(this);
        this.habilitarCampos            = this.habilitarCampos.bind(this);

        this.cerrarModal                = this.cerrarModal.bind(this);
        this.ejecutarQuery              = this.ejecutarQuery.bind(this);
        this.queryFinal                 = "";

        this.parametroChange            = this.parametroChange.bind(this);
        this.parametroConfChange        = this.parametroConfChange.bind(this);
        this.consultarQuery             = this.consultarQuery.bind(this);

        this.exportarConsulta           = this.exportarConsulta.bind(this);
        this.successExportarConsulta    = this.successExportarConsulta.bind(this);
        this.configurarColumna          = this.configurarColumna.bind(this);
        this.successDatosColumna        = this.successDatosColumna.bind(this);

        this.guardarConfiguracion       = this.guardarConfiguracion.bind(this);
        this.successGuardarConfiguracion= this.successGuardarConfiguracion.bind(this);

        this.yaconfigurado              = false;
        this.setPatron                  = this.setPatron.bind(this);
        this.successParametros          = this.successParametros.bind(this);

        this.isDate                     = this.isDate.bind(this);
        this.existeFecha                = this.existeFecha.bind(this);
        this.guardarConfParametros      = this.guardarConfParametros.bind(this);
        this.setSelectTipo              = this.setSelectTipo.bind(this);
        this.createElementJaivana       = this.createElementJaivana.bind(this);

        this.enviarConfParametros       = this.enviarConfParametros.bind(this);
        this.successEnviarConfParametros= this.successEnviarConfParametros.bind(this);

        this.tipoOp                     = "";
        this.realizoConsulta            = false;

        this.parametrosQuery            = [];
        
        
    }

    initForm(){
        console.log('Formulario CustomConsultas,  @version: jdesk_1.01.0002, @author:Cristian Ciro');
        
        this.getField("btn_guardar_consulta").setDisabled(true);
        this.getField("btn_eliminar_consulta").setDisabled(true);
        this.getField("btn_ejecutar_query").setDisabled(true);
        this.getField("btn_exportar").setDisabled(true);
        this.getField("btn_configurar_parametros").setDisabled(true);

        this.getField("tipo").setDisabled(true);

        this.getField("numerico").setOnChange(() => {
            if(this.getField("numerico").getValue() === "false"){
                this.getField("separador_miles").setDisabled(false);
                this.getField("signo").setDisabled(false);
                this.getField("decimales").setDisabled(false);
            }else{
                this.getField("separador_miles").setDisabled(true);
                this.getField("signo").setDisabled(true);
                this.getField("decimales").setDisabled(true);
                this.getField("decimales").setError(false,'');

                this.getField("decimales").setValue("");
                this.getField("separador_miles").setValue(false);
                this.getField("signo").setValue(false);
            }
        });

        this.getField("tipo").setOnChange(() => {

            this.tipoOp = this.getField("tipo").getValue();
            if(this.realizoConsulta){
                if(this.tipoOp === "S"){    
                    this.getField("btn_exportar").setDisabled(false);
                }else{
                    this.getField("btn_exportar").setDisabled(true);
                }
            }
        });

        this.getField("titulo_consulta").setOnChange(props => {
            this.getField("titulo_consulta").valid();
        });

        this.getField("btn_abrir_consulta").setClick(this.abrirConsulta);
        this.getField("btn_exportar").setClick(this.exportarConsulta);

        this.getField("btn_guardar_consulta").setClick(() => {
            if(this.getField("txt_query").valid() && this.getField("titulo_consulta").valid() && this.getField("tipo").valid()){
                this.getField('confirmModalCustom').setTitleAndContent('Guardar/Actualizar', '¿Desea realizar esta acción?');
                this.getField('confirmModalCustom').setClickDialog(() => {this.guardarConsulta()});
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField("confirmModalCustom").setButtonConfirm(`Si`);
                this.getField("confirmModalCustom").setButtonCancel(`No`);
                this.getField('confirmModalCustom').toggle(true);
            }
        });

        this.getField("btn_eliminar_consulta").setClick(() => {
            if(this.getField("txt_query").valid() && this.getField("titulo_consulta").valid() && this.getField("tipo").valid()){
                this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta acción?');
                this.getField('confirmModalCustom').setClickDialog(() => {this.eliminarConsulta()});
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField("confirmModalCustom").setButtonConfirm(`Si`);
                this.getField("confirmModalCustom").setButtonCancel(`No`);
                this.getField('confirmModalCustom').toggle(true);
            }
        });

        this.getField("btn_aceptar").setClick(this.cerrarModal);
        this.getField("btn_ejecutar_query").setClick(this.ejecutarQuery);

        this.getField("btn_ejecutar_aceptar").setClick(this.consultarQuery);

        this.getField("btn_configurar_columna").setClick(this.guardarConfiguracion);
        this.getField("btn_configurar_parametros").setClick(this.guardarConfParametros);
        this.getField("btn_aceptar_configurar").setClick(this.enviarConfParametros);

    } 

    enviarConfParametros(){
        let valid = true;
        let parametros = [];
        this.gridOptionsTbConfParametros['rowData'].forEach(item => {
            if(item.tipo !== " " && item.descripcion !== ""){
                let paramN = 0;
                if(item.parametro.indexOf("?") !== -1){
                    paramN = parseInt(item.parametro.replaceAll("?",""));
                }else{
                    paramN = parseInt(item.parametro.replaceAll("K",""));
                }
                let param = {
                    numero_parametro: paramN,
                    tipo: item.tipo,
                    descripcion: item.descripcion,
                    consultas_id: parseInt(this.getField("codigo").getValue())
                }
                parametros.push(param);
                valid = true;
            }else{
                valid = false;
            }
        });

        if(valid){
            this.parametrosQuery = parametros;
            let body = {
                datos:{
                    parametros: parametros
                }
            }
    
            this.generalFormatPmv = { tipo_servicio: 'heus-consultas', operacion: "5_3", operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: body,
                success: this.successEnviarConfParametros,
                error: this.error_,
                showMessage: true,
                general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true,"Falta ingresar algún valor.","error");
        }
    }

    successEnviarConfParametros(data){
        if(data.estado_p === 200){
            this.getField("modal_configurar").handleClose();
            this.getField("btn_ejecutar_query").setDisabled(false);
        }else{
            this.getField("btn_ejecutar_query").setDisabled(true);
        }
    }

    guardarConfParametros(){

        let Ks;
        let letter = "K";
        if(this.getField("txt_query").getValue().indexOf("?") !== -1){
            Ks = this.getField("txt_query").getValue().split("?").length - 1;
            letter = "?";
        }
        else{
            Ks = this.getField("txt_query").getValue().split("K");

            for(let l=0; l<10; l++){
                if(this.getField("txt_query").getValue().indexOf("K"+(l+1)) === -1){
                    Ks = l;
                    break;
                }
            
            }
        }

        let dataP = [];
        if(this.parametrosQuery.length > 0){
            for(let i=0; i<Ks; i++){
                if(i < this.parametrosQuery.length){
                    let parametro = {
                        parametro: letter+this.parametrosQuery[i].numero_parametro,
                        descripcion:this.parametrosQuery[i].descripcion,
                        tipo:this.parametrosQuery[i].tipo
                    }
                    dataP.push(parametro);
                }else{
                    let parametro = {
                        parametro: letter+(i+1),
                        descripcion:"",
                        tipo:" "
                    }
                    dataP.push(parametro);
                }
                
            }

        }else{
            for(let i=0; i<Ks; i++){
                let parametro = {
                    parametro: letter+(i+1),
                    descripcion:"",
                    tipo:" "
                }
                dataP.push(parametro);
            }
        }
        this.gridOptionsTbConfParametros['rowData'] = dataP;
        this.gridOptionsTbConfParametros['onSelectionChanged']= this.onSelectionChangedRowTableMov2;
        let configCell = new Map();

        configCell.set('descripcion', { cellRenderer: function (props) { return props.data.descripcion }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.parametroConfChange, sortable: false, filter: false, field: 'descripcion' });
        configCell.set('tipo',{cellRenderer:this.setSelectTipo, sortable: false, filter: false ,field: 'tipo',width:280});

        this.getField('rejilla_configurar').initData(this.gridOptionsTbConfParametros,configCell);
        this.getField("modal_configurar").handleClickOpen();
    }

    setSelectTipo(props){
        let nombreOperario = props.data.tipo;
        let div = document.createElement("div");
        let select = document.createElement("select");
        div.setAttribute('class', 'custom-select');
        select.setAttribute('class', 'custom-select');
        select.onchange = (event) => this.grabarOpcion(props, event);
        select.setAttribute('id', 'mySelectOperario_');
        let opciones = this.opcionesSelect();

        opciones.map((item) => {  
            select.appendChild(item);
            return null;
        });
        //if(this.nombreOperario > 0)
        select.value = nombreOperario;
        div.appendChild(select)
        return this.createElementJaivana(div);
    }

    grabarOpcion(tipo, event){
        //tipo.setValue(event.target.value);
        tipo.setValue(event.target.value);
    }

    opcionesSelect(){
        let opcionN = document.createElement('option');
        opcionN.setAttribute('value',' ');
        opcionN.textContent = 'Ninguno';
        let datos = [opcionN];
        
        let opcion = document.createElement('option');
        opcion.setAttribute('value',"F");
        opcion.textContent = "Fecha";

        let opcion2 = document.createElement('option');
        opcion2.setAttribute('value',"N");
        opcion2.textContent = "Numero";

        let opcion3 = document.createElement('option');
        opcion3.setAttribute('value',"T");
        opcion3.textContent = "Texto";

        datos.push(opcion);
        datos.push(opcion2);
        datos.push(opcion3);
            
        return datos;
    }

    parametroConfChange(props){
        let seguir = this.getField('rejilla_configurar').cellValid(props.oldValue,props.newValue,'^[a-zA-Z0-9_\\u0020]+$',250);
        if(!seguir){
            props.data.descripcion = "";
            props.newValue = "";
            props.oldValue = "";
            let column = props.column.colDef.field;
            props.column.colDef.cellStyle = { backgroundColor: "rgb(251, 195, 195)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
            props.api.refreshCells({
                force: true,
                columns: [column],
                rowNodes: [props.node]
            });
        }else{
            let column = props.column.colDef.field;
            props.column.colDef.cellStyle = { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
            props.api.refreshCells({
                force: true,
                columns: [column],
                rowNodes: [props.node]
            });
        }
    }

    async exportarConsulta(){

        this.getField('confirmModalCustom').setTitleAndContent('Exportando...', 'Por favor espere mientras se exporta el archivo.');
        this.getField("confirmModalCustom").setVisibleCancel(true);
        this.getField("confirmModalCustom").setVisibleConfirm(true);
        this.getField('confirmModalCustom').toggle(true);

        let body = {
            generales:{ tipo_servicio: 'heus-consultas', operacion: "2_2", operacion_tipo: 'consulta' },
            datos:{
                xquery: this.queryFinal,
                xtipo: this.getField("tipo").getValue(),
                xcorreo: JSON.parse(localStorage.getItem('res')).email,
                xtitulo: this.getField("titulo_consulta").getValue()
            }
        }

        let url = this.constant.formConfiguration();

        var http = new XMLHttpRequest();

        let getField = this.getField;
        let succ = this.successExportarConsulta;

        http.open("POST", url, true);
        http.setRequestHeader("Accept","*/*");
        http.setRequestHeader("Content-type", "application/json");
        http.setRequestHeader("Access-Control-Allow-Origin","*");
        http.setRequestHeader('Authorization', 'Bearer '+localStorage.getItem("token"));
        http.timeout = 240000; // time in milliseconds

        http.onreadystatechange = () => {
            if(http.responseText !== ""){
                const buffer = http.response;
                let data ={};
                getField('confirmModalCustom').toggle(false);
                if(http.status !== 0){
                    data = JSON.parse(buffer);
                    succ(data);
                }else{
                    let data = {
                        data:{
                            errores:{
                                mensaje:"Tiempo de espera agotado."
                            },
                            mensaje:"Error en la petición"
                        },
                        estado_p:502
                    }
                    succ(data);
                }
            }else{
                let data = {
                    data:{
                        errores:{
                            mensaje:"Tiempo de espera agotado."
                        },
                        mensaje:"Error en la petición"
                    },
                    estado_p:0
                }
                succ(data);
            }
        }

        /*http.onload = () => {
            console.log("onload = ",http);

        };

        http.onprogress = (event) => {
            console.log(`Uploaded ${event}`);
          };

        http.onloadend = () => {
            console.log("onloadend = ",http);

        }
        http.ontimeout = () => {
            console.log("ontimeout = ",http);

        }*/
        http.send(JSON.stringify(body));
        
    }

    successExportarConsulta(data){
        this.habilitarCampos();
        if(this.getField("txt_query").getValue().indexOf("K1") !== -1 || this.getField("txt_query").getValue().indexOf("?") !== -1){
            this.getField("btn_ejecutar_query").setDisabled(false);
        }
        
        this.getField("tipo").setDisabled(false);
        let message = "";
        if(data.data !== undefined && data.data.errores){
            for(let error in data.data.errores){
                message += (message === "")?data.data.errores[error]:', '+data.data.errores[error];
            }
        }
        if(data.estado_p === 200){
            this.alertGeneral.toggle(true,message + " "+data.estado_p+":"+data.data.mensaje,"success");
        }else if(data.estado_p === 0){
            this.getField('confirmModalCustom').setTitleAndContent('Informativo', 'La consulta aún se esta ejecutando, puede continuar trabajando, al correo '+ JSON.parse(localStorage.getItem('res')).email +' se le enviará la consulta.');
            this.getField("confirmModalCustom").setButtonCancel(`Seguir trabajando`);
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setVisibleConfirm(true);
            this.getField('confirmModalCustom').toggle(true);
        
        }else{
            if(data.estado_p === 404){
                this.getField('confirmModalCustom').setTitleAndContent('Sin datos', 'La consulta no devolvió datos.');
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField("confirmModalCustom").setVisibleConfirm(true);
                this.getField("confirmModalCustom").setButtonCancel(`Confirmar`);
                this.getField('confirmModalCustom').toggle(true);
            }else{
                if(data.data.mensaje){
                    this.alertGeneral.toggle(true,message + " "+data.estado_p+":"+data.data.mensaje,"error");
                }else
                    this.alertGeneral.toggle(true,message + " "+data.estado_p+":"+data.mensaje,"error");
            }
        }

    }

    bloquearCampos(){
        this.getField("btn_abrir_consulta").setDisabled(true);
        this.getField("btn_guardar_consulta").setDisabled(true);
        this.getField("btn_eliminar_consulta").setDisabled(true);
        this.getField("btn_ejecutar_query").setDisabled(true);
        this.getField("btn_exportar").setDisabled(true);
        this.getField("tipo").setDisabled(true);
        this.getField('tabla_resultado').toggle(false);
        this.getField("btn_configurar_parametros").setDisabled(true);

    }

    habilitarCampos(){
        this.getField("btn_abrir_consulta").setDisabled(false);
        this.getField("btn_guardar_consulta").setDisabled(false);
        this.getField("btn_eliminar_consulta").setDisabled(true);
        //this.getField("btn_ejecutar_query").setDisabled(false);
        //this.getField("tipo").setDisabled(false);

    }

    eliminarConsulta(){
        this.getField('confirmModalCustom').toggle(false);
        if(this.getField("codigo").getValue() !== "" && this.getField("codigo").getValue() !== null){
            let body = {
                datos:{
                    xserial: this.getField("codigo").getValue(),
                }
            }
    
            this.generalFormatPmv = { tipo_servicio: 'heus-consultas', operacion: "7_1", operacion_tipo: 'eliminar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: body,
                success: this.successEliminarConsultas,
                error: this.error_,
                showMessage: true,
                general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, 'No se puede eliminar esta consulta.', 'error');
        }
        
    }

    successEliminarConsultas(data){
        if(data.estado_p === 200){
            this.bloquearCampos();
            this.getField("txt_query").setValue("");
            this.getField("titulo_consulta").setValue("");
            this.getField("titulo_consulta").setError(false,"");
            this.getField("btn_exportar").setDisabled(true);
            this.getField("tipo").setValue("");
            this.getField("btn_abrir_consulta").setDisabled(false);
        }
    }

    guardarConsulta(){
        this.getField('confirmModalCustom').toggle(false);
        
        let body = {
            datos:{
                xquery: this.getField("txt_query").getValue(),
                xtitulo: this.getField("titulo_consulta").getValue(),
                xopcion: this.getField("tipo").getValue(),
                xserial: this.getField("codigo").getValue()
            }
        }

        this.generalFormatPmv = { tipo_servicio: 'heus-consultas', operacion: "5_1", operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: body,
            success: this.successGuardarConsultas,
            error: this.error_,
            showMessage: true,
            general: this.generalFormatPmv
        });
    }

    successGuardarConsultas(data){
        if(data.estado_p === 200){
            this.bloquearCampos();
            this.getField("txt_query").setValue("");
            this.getField("titulo_consulta").setValue("");
            this.getField("titulo_consulta").setError(false,"");
            this.getField("btn_exportar").setDisabled(true);
            this.getField("tipo").setValue("");
            this.getField("btn_abrir_consulta").setDisabled(false);
        }
    }

    ejecutarQuery(){
        if(this.getField("txt_query").valid()){
            this.queryFinal = this.getField("txt_query").getValue();

            if(this.getField("tipo").getValue() === "U" && this.queryFinal.indexOf("delete") !== -1){
                this.alertGeneral.toggle(true,'Prohibido ejecutar este tipo de consultas!',"error");
            }else{
                let valid = true;
                for(let l=0; l<10; l++){
                    if(this.queryFinal.indexOf("K"+(l+1)) !== -1 || this.getField("txt_query").getValue().indexOf("?") !== -1){
                        valid=false;
                    }
                }
                if(valid){
                    this.gridOptionsTbParametros['rowData'] = [];
                    this.gridOptionsTbParametros['onSelectionChanged']= this.onSelectionChangedRowTableMov2;
                    this.consultarQuery();
                }else{
                    let Ks;
                    let letter = "K";
                    if(this.getField("txt_query").getValue().indexOf("?") !== -1){
                        Ks = this.getField("txt_query").getValue().split("?").length - 1;
                        letter = "?";
                    }
                    else{
                        Ks = this.getField("txt_query").getValue().split("K");
                        for(let l=0; l<10; l++){
                            if(this.getField("txt_query").getValue().indexOf("K"+(l+1)) === -1){
                                Ks = l;
                                break;
                            }
                        }
                    }

                    

                    let dataP = [];
                    if(this.parametrosQuery.length > 0){
                        for(let i=0; i<Ks; i++){
                            if(i < this.parametrosQuery.length){
                                let parametro = {
                                    numero:letter+this.parametrosQuery[i].numero_parametro,
                                    parametro: this.parametrosQuery[i].descripcion,
                                    valor:"",
                                    tipo:this.parametrosQuery[i].tipo
                                }
                                dataP.push(parametro);
                            }else{
                                let parametro = {
                                    numero:letter+(i+1),
                                    parametro: letter+(i+1),
                                    valor:"",
                                    tipo:"T"
                                }
                                dataP.push(parametro);
                            }
                            
                        }

                    }else{
                        for(let i=0; i<Ks; i++){
                            let parametro = {
                                numero:letter+(i+1),
                                parametro: letter+(i+1),
                                valor:"",
                                tipo:"T"
                            }
                            dataP.push(parametro);
                        }
                    }
                    this.gridOptionsTbParametros['rowData'] = dataP;
                    this.gridOptionsTbParametros['onSelectionChanged']= this.onSelectionChangedRowTableMov2;
                    let configCell = new Map();
        
                    configCell.set('valor', { cellRenderer: function (props) { return props.data.valor }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.parametroChange, sortable: false, filter: false, field: 'valor' });
        
                    this.getField('rejilla_ejecutar_query').initData(this.gridOptionsTbParametros,configCell);
                    this.getField("modal_ejecutar_query").handleClickOpen();
                }
                    /*for(let i=0; i<Ks.length-1; i++){
                        let parametro = {
                            parametro: "K"+(i+1),
                            valor:""
                        }
                        data.push(parametro);
                    }
        
                    this.gridOptionsTbParametros['rowData'] = data;
                    let configCell = new Map();
        
                    configCell.set('valor', { cellRenderer: function (props) { return props.data.valor }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.parametroChange, sortable: false, filter: false, field: 'valor' });
        
                    this.getField('rejilla_ejecutar_query').initData(this.gridOptionsTbParametros,configCell);
                    this.getField("modal_ejecutar_query").handleClickOpen();*/
            }
        }
    }

    successParametros(data){
        if(data.estado_p === 200){
            this.parametrosQuery = data.data;
            this.getField("btn_ejecutar_query").setDisabled(false);
        }else{
            this.parametrosQuery = [];
            this.getField("btn_ejecutar_query").setDisabled(true);
        }
    }

    configurarColumna = (props) => {

        let colum = props.columnApi.getAllGridColumns()[parseInt(props.event.target.ariaColIndex)-1]["colId"];
        let opciones = [
            {value:" ",text:"Ninguno"},
            {value:"I",text:"Izquierda"},
            {value:"D",text:"Derecha"},
            {value:"C",text:"Centrado"}
        ];
        this.getField("alineacion").setOptions(opciones);
        this.getField("campo").setValue(colum);
        this.getField("numerico").setValue(false);

        this.getField("decimales").setDisabled(true);
        this.getField("separador_miles").setDisabled(true);
        this.getField("signo").setDisabled(true);

        this.getField("decimales").setValue("");
        this.getField("separador_miles").setValue(false);
        this.getField("signo").setValue(false);

        let body = {
            datos:{
                id: parseInt(this.getField("codigo").getValue()),
                campo: this.getField("campo").getValue(),
            }
        }

        this.generalFormatPmv = { tipo_servicio: 'heus-consultas', operacion: "1_2", operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: body,
            success: this.successDatosColumna,
            error: this.error_,
            showMessage: false,
            general: this.generalFormatPmv
        });
    }

    successDatosColumna(data){
        if(data.estado_p === 200){
            this.yaconfigurado = true;
            this.getField("alineacion").setValue(data.data[0].alineacion);
            this.getField("ancho").setValue(data.data[0].ancho);
            this.setPatron(data.data[0].patron);
        }else{
            this.yaconfigurado = false;
        }

        this.getField("modal_configurar_columna").handleClickOpen();
    }

    setPatron(xpatron) {
        if (xpatron !== ".") {
            this.getField("numerico").setValue(true);
            this.getField("decimales").setDisabled(false);
            this.getField("separador_miles").setDisabled(false);
            this.getField("signo").setDisabled(false);
            if (xpatron.indexOf('$') !== -1) {
                this.getField("signo").setValue(true);
            }
            if (xpatron.indexOf(',') !== -1) {
                this.getField("separador_miles").setValue(true);
            }
            if (xpatron.indexOf('.') !== -1) {
                let sub = xpatron.substring(xpatron.indexOf('.') + 1);
                this.getField("decimales").setValue(sub.length);
            }else{
                this.getField("decimales").setValue("0");
            }
        }
    }

    guardarConfiguracion(){

        if(this.getField("alineacion").valid() && this.getField("ancho").valid()){
            let operacion = "";
            let operacion_tipo = "crear";
            let metodo = "";
            if(this.yaconfigurado){
                operacion = "6_1";
                metodo = "PUT";
                operacion_tipo = "modificar";
            }else{
                operacion = "5_2";
                metodo = "POST";
            }

            let patron = "";

            if(this.getField("numerico").getValue() === "true"){
                if(this.getField("signo").getValue() === "true"){
                    patron = "$";
                }
                if(this.getField("separador_miles").getValue() === "true"){
                    patron += "#,##";
                }
                if(this.getField("decimales").getValue() > 0){
                    patron += "0.";
                    for(let i=0; i<this.getField("decimales").getValue(); i++){
                        patron += "0";
                    }
                }else{
                    patron += "0.";
                }

            }else{
                patron = ".";
            }

            let body = {
                datos:{
                    codigo: this.getField("codigo").getValue(),
                    campo: this.getField("campo").getValue(),
                    signo: patron,
                    alineacion:this.getField("alineacion").getValue(),
                    ancho:this.getField("ancho").getValue(),
                }
            }

            this.generalFormatPmv = { tipo_servicio: 'heus-consultas', operacion: operacion, operacion_tipo: operacion_tipo };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:metodo,
                body: body,
                success: this.successGuardarConfiguracion,
                error: this.error_,
                showMessage: true,
                general: this.generalFormatPmv
            });
        }
    }

    successGuardarConfiguracion(data){
        if(data.estado_p === 200){
            this.getField("modal_configurar_columna").handleClose();
        }
    }

    parametroChange(props){
        if(props.data.tipo === "F"){
            let seguir = this.getField('rejilla_ejecutar_query').cellValid(props.oldValue,props.newValue,'',10);
            if(seguir){
                if(this.isDate(props.data.valor)){
                    if(!this.existeFecha(props.data.valor)){
                        props.data.valor = "";
                        props.newValue = "";
                        let column = props.column.colDef.field;
                        props.column.colDef.cellStyle = { backgroundColor: "rgb(251, 195, 195)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
                        props.api.refreshCells({
                            force: true,
                            columns: [column],
                            rowNodes: [props.node]
                        });
                        this.alertGeneral.toggle(true,props.data.parametro + ' debe ser menor a la fecha actual',"error");
                    }else{
                        let column = props.column.colDef.field;
                        props.column.colDef.cellStyle = { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
                        props.api.refreshCells({
                            force: true,
                            columns: [column],
                            rowNodes: [props.node]
                        });
                    }
                }else{
                    props.data.valor = "";
                    props.newValue = "";
                    let column = props.column.colDef.field;
                    props.column.colDef.cellStyle = { backgroundColor: "rgb(251, 195, 195)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
                    props.api.refreshCells({
                        force: true,
                        columns: [column],
                        rowNodes: [props.node]
                    });
                    this.alertGeneral.toggle(true,props.data.parametro + ' debe ser de tipo Fecha(dd/mm/aaaa)',"error");
                }
            }else{
                props.data.valor = "";
                props.newValue = "";
                let column = props.column.colDef.field;
                props.column.colDef.cellStyle = { backgroundColor: "rgb(251, 195, 195)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
                props.api.refreshCells({
                    force: true,
                    columns: [column],
                    rowNodes: [props.node]
                });
            }
        }else if(props.data.tipo === "N"){
            let seguir = this.getField('rejilla_ejecutar_query').cellValid(props.oldValue,props.newValue,'^[0-9]+$',20);
            if(!seguir){
                props.data.valor = "";
                props.newValue = "";
                let column = props.column.colDef.field;
                props.column.colDef.cellStyle = { backgroundColor: "rgb(251, 195, 195)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
                props.api.refreshCells({
                    force: true,
                    columns: [column],
                    rowNodes: [props.node]
                });
            }else{
                let column = props.column.colDef.field;
                props.column.colDef.cellStyle = { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
                props.api.refreshCells({
                    force: true,
                    columns: [column],
                    rowNodes: [props.node]
                });
            }           
        }else{
            let seguir = this.getField('rejilla_ejecutar_query').cellValid(props.oldValue,props.newValue,'^[^\\u0027\\u003F\\u00BF\\u0022\\u002F\\u005C\\u003D]+$',50);
            if(!seguir){
                props.data.valor = "";
                props.newValue = "";
                let column = props.column.colDef.field;
                props.column.colDef.cellStyle = { backgroundColor: "rgb(251, 195, 195)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
                props.api.refreshCells({
                    force: true,
                    columns: [column],
                    rowNodes: [props.node]
                });
            } else{
                let column = props.column.colDef.field;
                props.column.colDef.cellStyle = { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' };
                props.api.refreshCells({
                    force: true,
                    columns: [column],
                    rowNodes: [props.node]
                });
            }
        }
    }

    existeFecha(fecha){
        let fecha_actual = new Date();
        var fechaf = fecha.split("/");
        var day = fechaf[0];
        var month = fechaf[1];
        var year = fechaf[2];
        
        if(fecha_actual.getFullYear() < year){
            return false;
        }else{
            if(fecha_actual.getFullYear() === year){
                if(fecha_actual.getMonth()+1 < month){
                    return false;
                }else{
                    if(fecha_actual.getMonth()+1 === month){
                        if(fecha_actual.getDate() < day){
                            return false;
                        }
                    }else{
                        return true;
                    }
                }
            }else{
                return true;
            }
        }

        return true;
    }

    isDate(xvalor){
        var RegExPattern = /^\d{1,2}\/\d{1,2}\/\d{4,4}$/;
        if ((xvalor.match(RegExPattern)) && (xvalor !== '')) {
            let parts = xvalor.split("/");
            if(parseInt(parts[1]) > 12 ||  parseInt(parts[1]) === 0){
                return false;
            }
            if(parseInt(parts[0]) > 31 ||  parseInt(parts[0]) === 0){
                return false;
            }
            if(parseInt(parts[2]) === 0){
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    consultarQuery(){
        let valid = true;
        this.gridOptionsTbParametros['rowData'].forEach((item) => {
            if(item.valor === ""){
                valid = false;
            }else{
                if(item.numero.indexOf("?") !== -1){
                    this.queryFinal = this.queryFinal.replace("?", item.valor);
                }else
                    this.queryFinal = this.queryFinal.replaceAll(item.numero, item.valor);
            }
        });

        if(!valid){
            this.alertGeneral.toggle(true, 'Error: Falta digitar el valor de un parametro.', 'error');
        }else{
            for(let l=0; l<10; l++){
                if(this.queryFinal.indexOf("K"+(l+1)) !== -1 || this.queryFinal.indexOf("?") !== -1){
                    valid=false;
                }
            }
            if(valid){
                this.bloquearCampos();
                this.getField("modal_ejecutar_query").handleClose();

                let body = {
                    datos:{
                        xquery: this.queryFinal,
                        xtipo: this.getField("tipo").getValue()
                    }
                }

                if(this.queryFinal.indexOf("drop") > -1 || JSON.stringify(body).length > 10000 || this.queryFinal.indexOf("funcion_") > -1){
                    this.exportarConsulta();
                }else{
                    this.getField("tabla_resultado").setTipoServicioOperacion("heus-consultas-2_1");
                    this.getField("tabla_resultado").sendRequest(this.successDatosQuery, body, 'POST');
                }
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Error', 'Falta configurar una o mas costantes para poder realizar esta acción.');
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField("confirmModalCustom").setVisibleConfirm(true);
                this.getField("confirmModalCustom").setButtonCancel(`Confirmar`);
                this.getField('confirmModalCustom').toggle(true);
            }
            /*this.generalFormatPmv = { tipo_servicio: 'heus-consultas', operacion: "2_1", operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: body,
                success: this.successConsultas,
                error: this.error_,
                showMessage: false,
                general: this.generalFormatPmv
            });*/
        }
    }

    successDatosQuery(data){
        this.habilitarCampos();
        if(data.estado_p === 200){
            if(data.data.mensaje){

            }else{
                if(data.data[data.data.length-2]["xsql"]){
                    this.queryFinal = data.data[data.data.length-2]["xsql"];
                    this.realizoConsulta = true;

                    let configCell = new Map();

                    let data2 = [];
                    let cont = 0;
                    data.data.forEach(item => {
                        if(cont < data.data.length-2)
                            data2.push(item);
                    });

                    this.getField("btn_exportar").setDisabled(false);
                    this.gridOptionsTbPrincipal['rowData'] = data.data;
                    this.gridOptionsTbPrincipal['onSelectionChanged']= this.onSelectionChangedRowTableMov2;
                    this.gridOptionsTbPrincipal.onRowDoubleClicked = this.configurarColumna;
                    
                    
                    this.getField('tabla_resultado').initData(this.gridOptionsTbPrincipal,configCell);
                }else{
                    this.realizoConsulta = true;
                    this.getField("btn_exportar").setDisabled(false);

                    let configCell = new Map();

                    let keys = Object.keys(data.data[0]);

                    keys.forEach(item => {
                        if(item.indexOf("valor") !== -1 
                        || item.indexOf("costo") !== -1 
                        || item.indexOf("total") !== -1  
                        || item.indexOf("precio") !== -1
                        || item.indexOf("tarifa") !== -1
                        || item.indexOf("iva") !== -1
                        || item.indexOf("subtotal") !== -1
                        || item.indexOf("debito") !== -1
                        || item.indexOf("credito") !== -1
                        || item.indexOf("descuento") !== -1){
                            configCell.set(item,{cellRenderer:function(props){return''.formatoPrecio(parseFloat(props.data[item]).toFixed(2))},type: 'rightAligned',field:item});
                        }
                    });

                    this.gridOptionsTbPrincipal['rowData'] = data.data;
                    this.gridOptionsTbPrincipal['onSelectionChanged']= this.onSelectionChangedRowTableMov2;
                    this.gridOptionsTbPrincipal.onRowDoubleClicked = this.configurarColumna;
                    
                    
                    this.getField('tabla_resultado').initData(this.gridOptionsTbPrincipal,configCell);
                }
            }
        }else{
            this.getField('confirmModalCustom').setTitleAndContent('Sin datos', 'La consulta no devolvió datos.');
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setVisibleConfirm(true);
            this.getField("confirmModalCustom").setButtonCancel(`Confirmar`);
            this.getField('confirmModalCustom').toggle(true);
        }
        this.getField("btn_ejecutar_query").setDisabled(false);
        if(this.getField("txt_query").getValue().indexOf("K1") !== -1 || this.getField("txt_query").getValue().indexOf("?") !== -1){
            this.getField("btn_configurar_parametros").setDisabled(false);
        }
    }

    onSelectionChangedRowTableMov = () =>{
        this.selectedProceso = this.gridOptionsTbConsultas.api.getSelectedRows();
    }

    onSelectionChangedRowTableMov2 = () =>{

    }

    abrirConsulta(){
        this.realizoConsulta = false;
        this.getField("btn_abrir_consulta").setDisabled(true);
        this.bloquearCampos();
        let body = {
            datos:{}
        }

        this.generalFormatPmv = { tipo_servicio: 'heus-consultas', operacion: "1_1", operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: body,
            success: this.successConsultas,
            error: this.error_,
            showMessage: false,
            general: this.generalFormatPmv
        });
    }

    successConsultas(data){
        this.selectedProceso = [];
        this.getField("btn_abrir_consulta").setDisabled(false);
        if(this.getField("txt_query").getValue() !== ""){
            this.habilitarCampos();
        }
        if(data.estado_p === 200){
            this.gridOptionsTbConsultas['rowData'] = data.data;
            this.gridOptionsTbConsultas['onSelectionChanged']= this.onSelectionChangedRowTableMov;            
            let configCell = new Map();

            this.getField('rejilla_query').initData(this.gridOptionsTbConsultas,configCell);
            this.getField("modal_query").handleClickOpen();
        }
    }

    cerrarModal(){
        if(this.selectedProceso.length > 0){
            this.getField("txt_query").setValue(this.selectedProceso[0].query);
            
            this.getField("titulo_consulta").setValue(this.selectedProceso[0].titulo);
            this.getField("tipo").setValue(this.selectedProceso[0].tipo);
            this.getField("codigo").setValue(this.selectedProceso[0].id);

            this.tipoOp = this.selectedProceso[0].tipo;

            if(this.getField("txt_query").getValue().indexOf("UPDATE") !== -1 ||
            this.getField("txt_query").getValue().indexOf("update") !== -1 ||
            this.getField("txt_query").getValue().indexOf("INSERT") !== -1 ||
            this.getField("txt_query").getValue().indexOf("insert") !== -1 ||
            this.getField("txt_query").getValue().indexOf("DROP") !== -1 ||
            this.getField("txt_query").getValue().indexOf("drop") !== -1){
                this.getField("tipo").setDisabled(false);
            }else{
                this.getField("tipo").setDisabled(true);
            }

            this.getField("titulo_consulta").setError(false,"");

            if(this.getField("txt_query").getValue().indexOf("K1") !== -1 || this.getField("txt_query").getValue().indexOf("?") !== -1){
                this.getField("btn_configurar_parametros").setDisabled(false);

                // consumir servicio parametros
                let body = {
                    datos:{
                        id: parseInt(this.selectedProceso[0].id)
                    }
                }
        
                this.generalFormatPmv = { tipo_servicio: 'heus-consultas', operacion: "1_3", operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: body,
                    success: this.successParametros,
                    error: this.error_,
                    showMessage: false,
                    general: this.generalFormatPmv
                });

            }else{
                this.getField("btn_ejecutar_query").setDisabled(false);
            }

            this.getField("modal_query").handleClose();
            this.habilitarCampos();
        }
    }

}
FormJaivana.addController("heus-consultas",CustomConsultas);
export default CustomConsultas