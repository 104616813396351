import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Anderson Acevedo
 * 
 * @version jdesk_1.01.0002
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomListadoDePreciosPorGrupo extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);

        this.consultarCompra = this.consultarCompra.bind(this);
        this.gridOptionsTbPrincipal = Object.assign({}, this.gridOptions);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onSelectionChanged2 = this.onSelectionChanged2.bind(this);
        this.onSelectionChangedMas = this.onSelectionChangedMas.bind(this);
        this.gridOptionsTbPrincipal['onSelectionChanged'] = this.onSelectionChanged2;

        this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesItems['onSelectionChanged'] = this.onSelectionChanged;
        this.gridOptionsTbMas = Object.assign({}, this.gridOptions);
        this.gridOptionsTbMas['onSelectionChanged'] = this.onSelectionChangedMas;


        this.ocultarCampos = this.ocultarCampos.bind(this);
        this.validarfechas = this.validarfechas.bind(this);

        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
        this.traerPropiedades = this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades = this.sucesstraerPropiedades.bind(this);
        this.successCrearTemporal = this.successCrearTemporal.bind(this);
        this.successDatosPrincipal = this.successDatosPrincipal.bind(this);
        this.arreglarDecimales = this.arreglarDecimales.bind(this);

        
        //imprimir
        this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
        this.abrirImprimir                         =this.abrirImprimir.bind(this);
        this.isJson                         =this.isJson.bind(this);
        this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
        this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
        this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
        this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
        this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
        this.procesar                         =this.procesar.bind(this);
        this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
        this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
        this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
        this.successDatosVerMas                         =this.successDatosVerMas.bind(this);
        this.crearTemporalVerMas                         =this.crearTemporalVerMas.bind(this);
        this.successCrearTemporalVerMas                         =this.successCrearTemporalVerMas.bind(this);

        this.isJson = this.isJson.bind(this);

        this.nombre_archivo = 'listado_de_precios_por_grupo';

        this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
        this.operacion_actual = '';
        this.sucursal_ingreso = '';
        this.numero_decimales = 2;
        this.archivo_operacion='';
        this.fila_seleccionada=[];
    }

    initForm() {
        console.log('Formulario CustomListadoDePreciosPorGrupo,  @version: jdesk_1.01.0002, @author:Anderson Acevedo ');
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedades();

        this.getField("consultar").setClick(this.consultarCompra);

        this.getField('codigo_grupo_desde').setOnChange((props) => {
            this.getField('nombre_grupo_desde').setError(false,'');
            this.getField('consultar').setDisabled(false);
        });

        this.getField('codigo_grupo_hasta').setOnChange((props) => {
            this.getField('nombre_grupo_hasta').setError(false,'');
            this.getField('consultar').setDisabled(false);
        });


        //imprimir
        this.getField('radio_correo').setValue("N");
        this.getField('generar_archivo').setClick(() => {
            this.procesar();
        });

        //para balances globales
        this.getField('ch_excel').setOnChange((props) => {
            if (this.getField('ch_excel').getValue() === 'false') {
                this.getField('ch_pdf').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.operacion_actual = 'excel';
                this.habilitarCorreo();
            }
        });
        this.getField('ch_pdf').setOnChange((props) => {
            if (this.getField('ch_pdf').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.operacion_actual = 'pdf';
                this.habilitarCorreo();
            }
        });
        this.getField('ch_pantalla').setOnChange((props) => {
            if (this.getField('ch_pantalla').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pdf').setValueSwitch(false);
                this.operacion_actual = 'pantalla';
                this.deshabilitarCorreo();
            } else {
                this.habilitarCorreo();
            }
        });


    }

    traerPropiedades() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'inve-listadopreciosporgrupo', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    sucesstraerPropiedades(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.numero_decimales = data.data.numero_decimales;
            this.dias_maximo=data.data.dias_devolver;
        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



    onSelectionChanged() {

    }

    onSelectionChanged2() {
        this.fila_seleccionada = this.gridOptionsTbPrincipal.api.getSelectedRows();
    }

    onSelectionChangedMas() {
        this.fila_seleccionada_mas = this.gridOptionsTbMas.api.getSelectedRows();
    }


    validarfechas() {
        this.getField('consultar').setDisabled(false);
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];
        this.ocultarCampos();

        this.getField("desde").setError(false, "");
        this.getField("hasta").setError(false, "");
        if (fecha_desde > fecha_actual) {
            this.getField("desde").setError(true, "No puede ser mayor a la fecha actual.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        if (fecha_hasta > fecha_actual) {
            this.getField("hasta").setError(true, "No puede ser mayor a la fecha actual.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        if (fecha_desde > fecha_hasta) {
            this.getField("hasta").setError(true, "No puede ser menor a la fecha desde.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        let fecha = new Date(fecha_actual);
        fecha.setDate(fecha.getDate() - this.dias_maximo);
        fecha = fecha.toISOString().split('T')[0];
        if (fecha_desde < fecha) {
            this.getField("desde").setError(true, "No es posible devolverse más de " + this.dias_maximo + " días.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        this.getField('consultar').setDisabled(false);
        return true;

    }


    ocultarCampos() {
        this.getField('consultar').setDisabled(false);
    }



    consultarCompra() {
        let errores = 0;
        this.ocultarCampos();
        this.getField('codigo_grupo_desde').setError(false,'');
        this.getField('nombre_grupo_desde').setError(false,'');
        this.getField('codigo_grupo_hasta').setError(false,'');
        this.getField('nombre_grupo_hasta').setError(false,'');

        if (this.getField('codigo_grupo_desde').getValue() !== '' && this.getField('codigo_grupo_hasta').getValue() !== '') {
            if (!this.getField('codigo_grupo_desde').valid())
                errores++;
            if (!this.getField('nombre_grupo_desde').valid())
                errores++;
            if (!this.getField('codigo_grupo_hasta').valid())
                errores++;
            if (!this.getField('nombre_grupo_hasta').valid())
                errores++;
        }

        if (!this.getField('precio').valid()) {
                errores++;
        }

        if (errores === 0) {
            //this.getField("consultar").setDisabled(true);
            this.abrirImprimir();
        }
    }

    successCrearTemporal(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            let datos = {
                datos: {
                    sucursal_ingreso:this.sucursal_ingreso
                }
            };
            if(this.getField('bodega').getValue()==='' || this.getField('bodega').getValue()===' '){
                this.mostrarMensajeGenerando();
                this.getField("rejilla").setTipoServicioOperacion('inve-listadopreciosporgrupo-archivoitems');
                this.getField("rejilla").sendRequest(this.successDatosPrincipal, datos); 
            }else{
                this.mostrarMensajeGenerando();
                this.generalFormatPmv = { tipo_servicio: 'inve-listadopreciosporgrupo', operacion: 'archivoitems_pag', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successDatosPrincipal,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }
            
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }

    crearTemporalVerMas() {
        this.mostrarMensajeGenerando();
        let datos = {
            datos: {
                desde:this.fila_seleccionada[0].desde+'',
                hasta:this.fila_seleccionada[0].hasta+'',
                sucursal_ingreso:this.sucursal_ingreso
            }
        };
        if(this.getField('bodega').getValue()!=='' && this.getField('bodega').getValue()!==' ')
            datos.datos.bodega=this.getField('bodega').getValue();
        this.generalFormatPmv = { tipo_servicio: 'inve-listadopreciosporgrupo', operacion: 'generardatos_ver_mas', operacion_tipo: 'crear' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'POST',
                body: datos,
                success: this.successCrearTemporalVerMas,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successCrearTemporalVerMas(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.mostrarMensajeGenerando();
            let datos = {
                datos: {
                    desde:this.fila_seleccionada[0].desde+'',
                    hasta:this.fila_seleccionada[0].hasta+'',
                    sucursal_ingreso:this.sucursal_ingreso,
                    operacion:'pantalla'
                }
            };
            if(this.getField('bodega').getValue()!=='' && this.getField('bodega').getValue()!==' ')
                datos.datos.bodega=this.getField('bodega').getValue();
            this.getField("rejilla_ver_mas").setTipoServicioOperacion('inve-listadopreciosporgrupo-imprimir_detalle');
            this.getField("rejilla_ver_mas").sendRequest(this.successDatosVerMas, datos); 
        } else {
            this.mostrarMensajeNoHayDatos();
            this.ocultarCampos();
            this.getField('consultar').setDisabled(true);
        }
    }

    successDatosVerMas(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.fila_seleccionada_mas=[];
            this.getField('modal_mas').setLabel(data.data.datos_lista.titulo);
            this.getField('modal_mas').handleClickOpen();
            this.gridOptionsTbMas['rowData'] = data.data.datos_items;
            let configCell = new Map();
            configCell.set('costo', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costo) }, type: 'rightAligned' });
            configCell.set('cantidadu', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.cantidadu) }, type: 'rightAligned' });

            configCell.set('saldo', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.saldo) }, type: 'rightAligned' });
            configCell.set('valorizado', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.valorizado) }, type: 'rightAligned' });
            configCell.set('ventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.ventas) }, type: 'rightAligned' });
            configCell.set('devventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.devventas) }, type: 'rightAligned' });
            configCell.set('contenido', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.contenido) }, type: 'rightAligned' });
            configCell.set('rotacion_mensual', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.rotacion_mensual) }, type: 'rightAligned' });
            configCell.set('rotacion_diaria', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.rotacion_diaria) }, type: 'rightAligned' });
            configCell.set('dias', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.dias) }, type: 'rightAligned' });
            configCell.set('valor_ventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.valor_ventas) }, type: 'rightAligned' });
            configCell.set('costo_ventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costo_ventas) }, type: 'rightAligned' });
            configCell.set('valor_compras', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.valor_compras) }, type: 'rightAligned' });

            this.getField('rejilla_ver_mas').initData(this.gridOptionsTbMas, configCell);
            this.getField('rejilla_ver_mas').toggle(true);

        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }

    successDatosPrincipal(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField('dias_de_ventas').setVisible(false);
            this.getField('ventas_entre').setVisible(false);
            this.getField('costo_ventas').setVisible(false);
            this.getField('inventario').setVisible(false);
            this.getField('dias_inventario').setVisible(false);
            this.getField('inventario_meses').setVisible(false);
            this.getField('inventario_meses').setValue('');
            if(this.getField('bodega').getValue()==='' || this.getField('bodega').getValue()===' '){
                this.gridOptionsTbPrincipal['rowData'] = data.data;
                let configCell = new Map();
                configCell.set('ventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.ventas) }, type: 'rightAligned' });
                configCell.set('costo', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costo) }, type: 'rightAligned' });

                configCell.set('inventario', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.inventario) }, type: 'rightAligned' });
                configCell.set('dias', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.dias) }, type: 'rightAligned' });
                configCell.set('dias_mes', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.dias_mes) }, type: 'rightAligned' });

                this.getField('rejilla').initData(this.gridOptionsTbPrincipal, configCell);
                this.getField('rejilla').toggle(true);
            }else{
                this.getField('dias_de_ventas').setVisible(true);
                this.getField('ventas_entre').setVisible(true);
                this.getField('costo_ventas').setVisible(true);
                this.getField('inventario').setVisible(true);
                this.getField('dias_inventario').setVisible(true);
                this.getField('inventario_meses').setVisible(true);

                
                this.getField('ventas_entre').setValue(this.arreglarDecimales(data.data[0].ventas));
                this.getField('costo_ventas').setValue(this.arreglarDecimales(data.data[0].costo));
                this.getField('inventario').setValue(this.arreglarDecimales(data.data[0].inventario));

                let fecha_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
                let fecha_hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];
                let fechai = new Date(fecha_desde);
                let fechaf= new Date(fecha_hasta);

                let milidia = 86400000;
                let dias_ventas=0;
                dias_ventas = (fechaf.getTime()-fechai.getTime()+milidia) / milidia;
                this.getField('dias_de_ventas').setValue(dias_ventas);
                let dias_inventario=0;
                let xmeses=0;
                if (data.data[0].costo !== 0 && data.data[0].costo !== 0.0) {
                    dias_inventario= (dias_ventas * data.data[0].inventario)/ data.data[0].costo;
                    xmeses = dias_inventario / 30;
                    this.getField('inventario_meses').setValue(xmeses.toFixed(this.numero_decimales));
                } else {
                    dias_inventario = 0.0;
                }
                this.getField('dias_inventario').setValue(dias_inventario.toFixed(this.numero_decimales));
            }
            
           
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }


    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > this.numero_decimales) {
                number = number.toFixed(this.numero_decimales); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


    mostrarMensajeAdicional(mensaje) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

    }

    mostrarMensajeNoHayDatos() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo

    mostrarMensajeEmailEnviado() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //modal imprimir

    deshabilitarCorreo() {
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false, '');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo() {
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    abrirImprimir() {
        this.archivo_operacion='archivoitems_pag';
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false, '');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    procesar() {
        this.archivo_servicio = 'inve-listadopreciosporgrupo';
        let errores = 0;
        if (this.getField('radio_correo').getValue() === 'S' && this.operacion_actual !== 'pantalla') {
            if (this.getField('email').valid() === false) {
                errores++;
            }
        }
        if (this.getField('ch_excel').getValue() === 'false' && this.getField('ch_pdf').getValue() === 'false' && this.getField('ch_pantalla').getValue() === 'false') {
            errores++;
            this.getField('ch_excel').setError(true, '* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true, '* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true, '* Es necesario seleccionar una opción.');
            this.operacion_actual = '';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }

        if (errores === 0) {
            if (this.operacion_actual !== '') {
                if (this.getField('radio_correo').getValue() === 'S') {
                    this.enviarCorreoTerceros();
                } else {
                    if (this.operacion_actual === 'pdf') {
                        this.generarPdfTabla3();
                    } else if (this.operacion_actual === 'pantalla') {
                        this.genearModalGlobales();
                    } else if (this.operacion_actual === 'excel') {
                        this.generarExcelTabla3();
                    }
                }
            } else {
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            }
        }
    }

    genearModalGlobales() {
        this.mostrarMensajeGenerando();
        let datos = {
            datos: {
                sucursal_ingreso: this.sucursal_ingreso,
                operacion: this.operacion_actual,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                precio: this.getField('precio').getValue(),
            }
        }
        if (this.getField('codigo_grupo_desde').getValue() !== '' && this.getField('codigo_grupo_desde').getValue() !== ''){
            datos.datos.codigo_grupo_desde=this.getField('codigo_grupo_desde').getValue();
            datos.datos.nombre_grupo_desde=this.getField('nombre_grupo_desde').getValue();
            datos.datos.codigo_grupo_hasta=this.getField('codigo_grupo_hasta').getValue();
            datos.datos.nombre_grupo_hasta=this.getField('nombre_grupo_hasta').getValue();
        }
        this.getField("rejilla_items_result").setTipoServicioOperacion('inve-listadopreciosporgrupo-'+this.archivo_operacion.replace('_pag',''));
        this.getField("rejilla_items_result").sendRequest(this.sucessTraerDatos, datos);

    }

    sucessTraerDatos(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField('total_valor').setVisible(false);
            this.getField('total_articulos').setVisible(false);
            this.getField('total_saldo').setVisible(false);
            this.getField('total_inventario').setVisible(false);
            this.getField('total_ventas').setVisible(false);
            

            let datosFicha = [];
            this.lista = data.data.datos_lista;
            let data2 =
            {
                "empresa": this.lista.empresa,
                "nit": this.lista.nit,
                "razon_social": this.lista.razon_social,
                "direccion": this.lista.direccion,
                "ciudad": this.lista.ciudad,
                "telefono": this.lista.telefono,
                "titulo": this.lista.titulo,
            };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);
            this.getField('modal_result').setLabel('Listado de Precios por Grupo');
            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            configCell.set('precio', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.precio) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('viva', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.viva) }, cellStyle: { textAlign: "right" }, width: 110 });

            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems, configCell);
            
        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }


    enviarCorreoTerceros() {
        this.mostrarMensajeGenerando();
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos = {
            datos: {
                sucursal_ingreso: this.sucursal_ingreso,
                operacion: this.operacion_actual,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                precio: this.getField('precio').getValue(),
            }
        }
        if (this.getField('codigo_grupo_desde').getValue() !== '' && this.getField('codigo_grupo_desde').getValue() !== ''){
            datos.datos.codigo_grupo_desde=this.getField('codigo_grupo_desde').getValue();
            datos.datos.nombre_grupo_desde=this.getField('nombre_grupo_desde').getValue();
            datos.datos.codigo_grupo_hasta=this.getField('codigo_grupo_hasta').getValue();
            datos.datos.nombre_grupo_hasta=this.getField('nombre_grupo_hasta').getValue();
        }
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
    }

    successEnviarCorreo(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.mostrarMensajeEmailEnviado();
        } else if (data.estado_p === 404) {
            this.mostrarMensajeNoHayDatos();
        } else {
            this.mostrarMensajeEmailFallo();
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla3() {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 = {
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            precio: this.getField('precio').getValue(),
        }

        if (this.getField('codigo_grupo_desde').getValue() !== '' && this.getField('codigo_grupo_desde').getValue() !== ''){
            datos2.codigo_grupo_desde=this.getField('codigo_grupo_desde').getValue();
            datos2.nombre_grupo_desde=this.getField('nombre_grupo_desde').getValue();
            datos2.codigo_grupo_hasta=this.getField('codigo_grupo_hasta').getValue();
            datos2.nombre_grupo_hasta=this.getField('nombre_grupo_hasta').getValue();
        }
        const myJSON = JSON.stringify(datos2);
        let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"' + this.archivo_servicio + '","operacion":"' + this.archivo_operacion + '","operacion_tipo":"consulta"}}';
        fetch(url + datos, {
            method: 'GET',
            headers: new Headers({
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": 'application/json'
            })
        })
            .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if (blob.size > 0) {
                    blob.text().then(res => {
                        if (this.isJson(res)) {
                            this.successEnviarCorreo(JSON.parse(res));
                        } else {
                            const pdf = new File([blob], this.nombre_archivo + '.pdf', {
                                type: 'application/pdf'
                            });
                            window.open(URL.createObjectURL(pdf));
                        }
                    });
                } else {
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true, err, 'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3() {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 = {
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            precio: this.getField('precio').getValue(),
        }

        if (this.getField('codigo_grupo_desde').getValue() !== '' && this.getField('codigo_grupo_desde').getValue() !== ''){
            datos2.codigo_grupo_desde=this.getField('codigo_grupo_desde').getValue();
            datos2.nombre_grupo_desde=this.getField('nombre_grupo_desde').getValue();
            datos2.codigo_grupo_hasta=this.getField('codigo_grupo_hasta').getValue();
            datos2.nombre_grupo_hasta=this.getField('nombre_grupo_hasta').getValue();
        }
        const myJSON = JSON.stringify(datos2);
        let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"' + this.archivo_servicio + '","operacion":"' + this.archivo_operacion + '","operacion_tipo":"consulta"}}';
        fetch(url + datos, {
            method: 'GET',
            headers: new Headers({
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": 'application/json'
            })
        })
            .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if (blob.size > 0) {
                    blob.text().then(res => {
                        if (this.isJson(res)) {
                            this.successEnviarCorreo(JSON.parse(res));
                        } else {
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo + '.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        }
                    });
                } else {
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true, err, 'error');
            });
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }


}
FormJaivana.addController("inve-listadopreciosporgrupo", CustomListadoDePreciosPorGrupo);
export default CustomListadoDePreciosPorGrupo