import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomModificarDocumentos
 * @author: Santiago Hernández
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/

class CustomModificarDocumentos extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.habilitarSegundoBuscador   = this.habilitarSegundoBuscador.bind(this);
        this.habilitarBtnProcesar       = this.habilitarBtnProcesar.bind(this);
        this.abrirTbPrincipal           = this.abrirTbPrincipal.bind(this);
        this.successMostrarTbPrincipal  = this.successMostrarTbPrincipal.bind(this);
        this.gridOptionsTbPrincipal     = Object.assign({},this.gridOptions);
        this.crearBotones               = this.crearBotones.bind(this);
        this.crearBotonProcesar         = this.crearBotonProcesar.bind(this);
        this.accionProcesar             = this.accionProcesar.bind(this);
        this.successBuscar              = this.successBuscar.bind(this);        
        this.arrayTbPrincipal           = [];
        this.arrayTbPrincipalCopia      = [];
        this.crearBotones2              = this.crearBotones2.bind(this);    
        this.abrirTbPrincipal2          = this.abrirTbPrincipal2.bind(this);  
        this.crearBotonProcesar2        = this.crearBotonProcesar2.bind(this); 
        this.successModificar           = this.successModificar.bind(this);  
        this.LimpiarCampos              = this.LimpiarCampos.bind(this);

    }

    initForm(){
        console.log('Formulario CustomModificarDocumentos,  @version: jdesk_1.01.0001, @author:Santiago Hernández N, @updateBy Jose Albeiro Marin')      
        this.getField('codigo_documento_2').setDisabled(true);
        this.getField('nombre_documento_2').setDisabled(true);
        this.getField('procesar').setDisabled(true);

        this.getField('documentos_id').setOnChange(this.habilitarSegundoBuscador);
       
        this.getField('documentos_id_2').setOnChange(this.habilitarBtnProcesar);
        this.getField('procesar').setClick(this.abrirTbPrincipal);

        this.getField('codigo_documento').setOnChange(this.LimpiarCampos)
        this.getField('codigo_documento_2').setOnChange(this.LimpiarCampos)

    } 

    LimpiarCampos(){
    this.getField('tb_base_modificacion').toggle(false);
    this.arrayTbPrincipal = [];
    this.arrayTbPrincipalCopia = [];
    
    }

    habilitarSegundoBuscador(){
        if (this.getField('nombre_documento').getValue() !== '' && this.getField('documentos_id').getValue() !== '') {
            this.getField('codigo_documento_2').setDisabled(false);
            this.getField('codigo_documento_2').input.focus();
            this.getField('nombre_documento_2').setDisabled(false);   
        }else{
            this.getField('codigo_documento_2').setDisabled(true);
            this.getField('codigo_documento_2').setValue('');
            this.getField('nombre_documento_2').setDisabled(true);
            this.getField('nombre_documento_2').setValue('');
            this.getField('documentos_id_2').setValue('');
        }      
    }

    habilitarBtnProcesar(){
        if (this.getField('nombre_documento_2').getValue() !== '' && this.getField('documentos_id_2').getValue() !== '') {
            if (this.getField('documentos_id_2').getValue()===this.getField('documentos_id').getValue()){
                this.alertGeneral.toggle(true, 'Los documentos no pueden ser iguales.', 'error');
            }else{
                this.getField('procesar').setDisabled(false);
            }            
        }else{
            this.getField('procesar').setDisabled(true);
        }        
    }

    abrirTbPrincipal(){
        let datos = { datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-modificardocumentos', operacion: 'buscarxtablaorigen', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successMostrarTbPrincipal,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }

    successMostrarTbPrincipal(data){
        if(data.estado_p === 200){
            this.arrayTbPrincipal = data.data;
            this.getField('tb_base_modificacion').toggle(true);
            this.gridOptionsTbPrincipal['rowData'] = this.arrayTbPrincipal;
            let configCell = new Map();
            configCell.set('tabla', { cellRenderer: (props) => { return props.data.tabla_destino } });
            configCell.set('accion', { cellRenderer: (e) => { return this.crearBotones(e) }, field: 'accion', width: 250 });
            this.getField('tb_base_modificacion').initData(this.gridOptionsTbPrincipal,configCell);
            this.getField('procesar').setDisabled(true);
        }else{
            this.getField('tb_base_modificacion').toggle(false);
        }
    }

        /**
     * Se encarga de establecer el estilo de los botones de acuerdo al estado de los registros de la tabla
     * @param {La información que contiene el registro seleccionado} props 
     * @returns Un objeto de tipo fragment que contiene los dos botones (Buscar y modificar)
     * para renderizarlo en la tabla.
     */
    crearBotones(props) {
        let fragment = document.createDocumentFragment();
        let button = this.crearBotonProcesar(props.data, 'Buscar');
        let text1 = this.crearBotonProcesar(props.data, 'Text1');
        let buttonModificar = this.crearBotonProcesar(props.data, 'Modificar');
        let text2 = this.crearBotonProcesar(props.data, 'Text2');

        button.setAttribute("class","buttonStyle4");                       
        buttonModificar.setAttribute("class","buttonStyle4");
        
        fragment.appendChild(button);
        fragment.appendChild(text1);
        fragment.appendChild(buttonModificar);
        fragment.appendChild(text2);
        return this.createElementJaivana(fragment);
    }

    crearBotonProcesar(data, boton) {

        let button = document.createElement('input');
        switch (boton) {

            case 'Buscar':
                button.setAttribute("id", `boton_buscar_${data.id}`);
                button.setAttribute("class", "buttonStyle");
                button.setAttribute("type", "button");
                button.setAttribute("value", "Buscar");
                button.onclick = () => { this.accionProcesar(data, boton) };
                break;
            case 'Modificar':
                button.setAttribute("id", `boton_modificar_${data.id}`);
                button.setAttribute("class", "buttonStyle");
                button.setAttribute("type", "button");
                button.setAttribute("value", "Modificar");
                button.setAttribute("disabled", false);                
                button.onclick = () => { this.accionProcesar(data, boton) };
                break;
            case 'Text1':
                button.setAttribute("class","textStyle2");
                button.setAttribute("type", "text");
                button.setAttribute("disabled", false);
                //button.onblur  = () => { this.accionProcesar2(data, boton) };                  
                break;
            case 'Text2':
                button.setAttribute("class","textStyle2");
                button.setAttribute("type", "text");
                button.setAttribute("disabled", false);
                break;

            default:
            break;
        }

        
        return button;

    }

    accionProcesar(data, boton) {
        this.id = data.id;         
        if (boton === 'Buscar'){
            let datos = { datos: {
                tabla_destino: data.tabla_destino,
                campo_destino_1: data.campo_destino_1,
                codigo_documento: this.getField('codigo_documento').getValue()
            }};
              this.generalFormatPmv = { tipo_servicio: 'cont-modificardocumentos', operacion: 'buscarxtabladestino', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.successBuscar,
                      error: this.error_,
                      general: this.generalFormatPmv
              });

        }else if (boton === 'Modificar'){
            if (data.text1!==undefined){
                if (data.text1>0){

                    let datos = { datos: {
                        tabla_destino: data.tabla_destino,
                        campo_destino_1: data.campo_destino_1,
                        codigo_documento: this.getField('codigo_documento').getValue(),
                        codigo_documento_2: this.getField('codigo_documento_2').getValue(),
                        campo_destino_2: data.campo_destino_2,
                        documentos_id_2: this.getField('documentos_id_2').getValue(),


                    }};
                      this.generalFormatPmv = { tipo_servicio: 'cont-modificardocumentos', operacion: '6', operacion_tipo: 'modificar' };
                      this.service.send(
                      {
                              endpoint: this.constant.formConfiguration(),
                              method:'PUT',
                              body: datos,
                              success: this.successModificar,
                              error: this.error_,
                              general: this.generalFormatPmv
                      });
                }else{
                    this.alertGeneral.toggle(true, 'Los documentos a modificar no tienen ninguna relación.', 'error');
                }                
            }else {
                this.alertGeneral.toggle(true, 'Debe primero realizar la busqueda de los documentos..', 'error');
            }

        }
    }

    successBuscar(data){
        if(data.estado_p === 200){
            this.arrayTbPrincipalCopia = [];
            this.arrayTbPrincipal.forEach(item => {
                if (item.id===this.id){
                    item["text1"]=data.data[0].xcuantos  
                    item["text2"]=data.data[0].count               
                }
                this.arrayTbPrincipalCopia.push(item);
            });

            this.abrirTbPrincipal2(this.arrayTbPrincipalCopia);
        
        }else{
            this.alertGeneral.toggle(true, 'No hay datos relacionados para documentos', 'error');
        }
    }

    successModificar(data){
        if(data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Datos bien modificados', "success");
            this.arrayTbPrincipalCopia = [];
            this.arrayTbPrincipal.forEach(item => {
                if (item.id===this.id){
                    item["text2"]=data.data[0].count  
                    item["text1"]=data.data[0].xcuantos                            
                }
                this.arrayTbPrincipalCopia.push(item);
            });

            this.abrirTbPrincipal2(this.arrayTbPrincipalCopia);

        }else{

            this.alertGeneral.toggle(true, 'Error al actualizar', 'error');

        }
    }

    abrirTbPrincipal2(data){
        this.getField('tb_base_modificacion').toggle(true);
        this.gridOptionsTbPrincipal['rowData'] = data;
        let configCell = new Map();
        configCell.set('tabla', { cellRenderer: (props) => { return props.data.tabla_destino } });
        configCell.set('accion', { cellRenderer: (e) => { return this.crearBotones2(e) }, field: 'accion', width: 250 });
        this.getField('tb_base_modificacion').initData(this.gridOptionsTbPrincipal,configCell);
    }


    crearBotones2(props){
        let fragment = document.createDocumentFragment();
        let button = this.crearBotonProcesar2(props.data, 'Buscar');
        let text1 = this.crearBotonProcesar2(props.data, 'Text1');
        let buttonModificar = this.crearBotonProcesar2(props.data, 'Modificar');
        let text2 = this.crearBotonProcesar2(props.data, 'Text2');

        button.setAttribute("class","buttonStyle4");                       
        buttonModificar.setAttribute("class","buttonStyle4");
        
        fragment.appendChild(button);
        fragment.appendChild(text1);
        fragment.appendChild(buttonModificar);
        fragment.appendChild(text2);
        return this.createElementJaivana(fragment); 
    }

    crearBotonProcesar2(data, boton) {

        let button = document.createElement('input');
        switch (boton) {

            case 'Buscar':
                button.setAttribute("id", `boton_buscar_${data.id}`);
                button.setAttribute("class", "buttonStyle");
                button.setAttribute("type", "button");
                button.setAttribute("value", "Buscar");
                button.onclick = () => { this.accionProcesar(data, boton) };
                break;
            case 'Modificar':
                button.setAttribute("id", `boton_modificar_${data.id}`);
                button.setAttribute("class", "buttonStyle");
                button.setAttribute("type", "button");
                button.setAttribute("value", "Modificar");
                button.onclick = () => { this.accionProcesar(data, boton) };
                break;
            case 'Text1':
                button.setAttribute("class","textStyle2");
                button.setAttribute("type", "text");
                button.setAttribute("disabled", false);
                if (data.text1!==undefined){
                    button.setAttribute("value", data.text1);  
                }                              
                break;
            case 'Text2':
                button.setAttribute("class","textStyle2");
                button.setAttribute("type", "text");
                button.setAttribute("disabled", false);
                if (data.text2!==undefined){
                    button.setAttribute("value", data.text2);  
                }   
                break;

            default:
            break;
        }

        
        return button;
    }

}
FormJaivana.addController("cont-modificardocumentos",CustomModificarDocumentos);
export default CustomModificarDocumentos