/**
 * @description: Custom con funciones adicionales al formulario con código: cotizaciones, ID Formulario: 520, Sección: padre: Facturación(id: 37)- hijo: Facturación(id: 50)
 * @author: Juan Nieto
 * @updateBy @updateBy: Anderson Acevedo
 * @version: jdesk_1.01.0004,
 **/

import FormJaivana from 'dashboard_jaivana_v1';
class CustomCotizacionesMercancia extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm = this.initForm.bind(this);
    this.searchTercero = this.searchTercero.bind(this);
    this.successSearchTercero = this.successSearchTercero.bind(this);
    this.onSelectionChangedCodigosFacturacion = this.onSelectionChangedCodigosFacturacion.bind(this);
    this.gridOptionsCodigosFacturacion = Object.assign({}, this.gridOptions);
    this.gridOptionsCodigosFacturacion["onSelectionChanged"] = this.onSelectionChangedCodigosFacturacion;
    this.gridOptionsBodegasItem = Object.assign({}, this.gridOptions);
    this.setPrecioListaProspectos = this.setPrecioListaProspectos.bind(this);
    this.onSelectionChangedTerceros = this.onSelectionChangedTerceros.bind(this);
    this.gridOptionsTerceros = Object.assign({}, this.gridOptions);
    this.gridOptionsTerceros["onSelectionChanged"] = this.onSelectionChangedTerceros;
    this.gridOptionsContactos = Object.assign({}, this.gridOptions);
    this.clearCampos = this.clearCampos.bind(this);
    this.openModalProducto = this.openModalProducto.bind(this);
    this.savePedido = this.savePedido.bind(this);
    this.successSavePedido = this.successSavePedido.bind(this);
    this.loadPreciosCliente = this.loadPreciosCliente.bind(this);
    this.successLoadPreciosCliente = this.successLoadPreciosCliente.bind(this);
    this.clearCamposProducto = this.clearCamposProducto.bind(this);
    this.loadItemPrecio = this.loadItemPrecio.bind(this);
    this.validarDespachoMinimo = this.validarDespachoMinimo.bind(this);
    this.successValidarDespachoMinimo = this.successValidarDespachoMinimo.bind(this);
    this.successLoadItemPrecio = this.successLoadItemPrecio.bind(this);
    this.loadBodegasItem = this.loadBodegasItem.bind(this);
    this.successLoadBodegasItem = this.successLoadBodegasItem.bind(this);
    this.setButtonEliminarItem = this.setButtonEliminarItem.bind(this);
    this.setButtonEliminarContacto = this.setButtonEliminarContacto.bind(this);
    this.eliminarContactosConfirmar = this.eliminarContactosConfirmar.bind(this);
    this.confirmDeleteContactoEliminar = this.confirmDeleteContactoEliminar.bind(this);
    this.setButtonModificarItem = this.setButtonModificarItem.bind(this);
    this.modificarItem = this.modificarItem.bind(this);
    this.eliminarItem = this.eliminarItem.bind(this);
    this.eliminarItemConfirmar = this.eliminarItemConfirmar.bind(this);
    this.valueOrdenCotizacion = this.valueOrdenCotizacion.bind(this);
    this.agregarItems = this.agregarItems.bind(this);
    this.validarCostosItem = this.validarCostosItem.bind(this);
    this.onSelectionChangedItems = this.onSelectionChangedItems.bind(this);
    this.gridOptionsTablaItems = Object.assign({}, this.gridOptions);
    this.gridOptionsTablaItems["onSelectionChanged"] = this.onSelectionChangedItems;
    this.agregarDescuentoItem = this.agregarDescuentoItem.bind(this);
    //this.agregarNuevoContacto                               = this.agregarNuevoContacto.bind(this);
    this.crearPedido = this.crearPedido.bind(this);
    this.CloseModalProductos = this.CloseModalProductos.bind(this);
    this.recalcularPrecios = this.recalcularPrecios.bind(this);
    this.recalcularPreciosLista = this.recalcularPreciosLista.bind(this);
    this.recalcularPreciosCantidad = this.recalcularPreciosCantidad.bind(this);
    this.cleanValid = this.cleanValid.bind(this);
    this.cleanRecalcular = this.cleanRecalcular.bind(this);
    this.cleanRecalcularCant = this.cleanRecalcularCant.bind(this);
    //this.enterServicioTerceros                              = this.enterServicioTerceros.bind(this);
    //this.successTerceros                                    = this.successTerceros.bind(this);
    this.selectDirecciones = this.selectDirecciones.bind(this);
    this.successDirecciones = this.successDirecciones.bind(this);
    this.rowSelectedPedido = this.rowSelectedPedido.bind(this);
    this.aplicarDescuentoBloque = this.aplicarDescuentoBloque.bind(this);
    this.successDescuentoBloque = this.successDescuentoBloque.bind(this);
    this.currencyFormatterPrecioSinIva = this.currencyFormatterPrecioSinIva.bind(this);
    this.formatNumberPrecioSinIva = this.formatNumberPrecioSinIva.bind(this);
    this.enterServicioTerceros = this.enterServicioTerceros.bind(this);
    this.successTerceros = this.successTerceros.bind(this);
    this.successTercerosF9 = this.successTercerosF9.bind(this);
    this.nitPendiente = this.nitPendiente.bind(this);
    this.buscarNitPendiente = this.buscarNitPendiente.bind(this);
    this.successBuscarNitPendiente = this.successBuscarNitPendiente.bind(this);
    this.crearNitPendiente = this.crearNitPendiente.bind(this);
    this.retornarCodigosFacturacion = this.retornarCodigosFacturacion.bind(this);
    this.successLoadCodigoFacturacion = this.successLoadCodigoFacturacion.bind(this);
    this.currencyFormatterPrecioTotal = this.currencyFormatterPrecioTotal.bind(this);
    this.formatNumberPrecioTotal = this.formatNumberPrecioTotal.bind(this);
    this.recuperarDepto = this.recuperarDepto.bind(this);
    this.successRecuperarDepto = this.successRecuperarDepto.bind(this);
    this.recuperarCiudades = this.recuperarCiudades.bind(this);
    this.successRecuperarCiudades = this.successRecuperarCiudades.bind(this);
    this.setTipoNegocioProspecto = this.setTipoNegocioProspecto.bind(this);
    this.setSucursalesProspectos = this.setSucursalesProspectos.bind(this);
    this.agregarNuevoPendiente = this.agregarNuevoPendiente.bind(this);
    this.successAgregarNuevoPendiente = this.successAgregarNuevoPendiente.bind(this);
    this.calcularDV = this.calcularDV.bind(this);
    this.abrirModalContactos = this.abrirModalContactos.bind(this);
    this.validarDescuentoMaximo = this.validarDescuentoMaximo.bind(this);
    this.successValidarDescuentoMax = this.successValidarDescuentoMax.bind(this);
    this.successAbrirModalContactos = this.successAbrirModalContactos.bind(this);
    this.cerrarModalContactos = this.cerrarModalContactos.bind(this);
    this.confirmCloseAlertModal = this.confirmCloseAlertModal.bind(this);
    this.currencyFormatterPrecio = this.currencyFormatterPrecio.bind(this);
    this.currencyFormatterPrecioSinIvaConDescuento = this.currencyFormatterPrecioSinIvaConDescuento.bind(this);
    this.currencyFormatterValorIva = this.currencyFormatterValorIva.bind(this);
    this.formatterPrecio = this.formatterPrecio.bind(this);
    this.formatterPrecioSinIvaConDescuento = this.formatterPrecioSinIvaConDescuento.bind(this);
    this.formatterValorIva = this.formatterValorIva.bind(this);
    this.cerrarGrabarPorBodega = this.cerrarGrabarPorBodega.bind(this);
    this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
    this.mostrarMensajeGuardando = this.mostrarMensajeGuardando.bind(this);
    this.traerCorreo  = this.traerCorreo.bind(this);
    this.successtraerCorreo  = this.successtraerCorreo.bind(this);

    this.auxiliarArray = [];
    this.arrayOrigin = [];
    this.dAgregado = [];
    this.arrayDataCheckBox = [];
    this.datosCodigos = [];
    this.nuevoArray = [];
    this.datosArray = [];
    this.modificarArray = [];
    this.arrayTemporal = [];
    this.id_delete = 0;
    this.codigoDeFacturacion = "";
    this.idEliminarContacto = 0;
    this.opcionesSelect = [];
    this.TipoNegociosProspecto = "";
    this.cantidadDespacho = false;
    this.codigoNegocio = "";
    this.itemEnEdicion = '';
    this.btnCerrarModal = false;
    this.valorPedido = 0;
    this.totalIvaItems = 0;
    this.subtotalItems = 0;
    this.cantidad = 0;
    this.bodegaIngreso = '';
    this.bodegaSeleccionada = '';
    this.arrayContarItems = [];
    this.setDiasVigencia = this.setDiasVigencia.bind(this);
    this.FechaEntregaCot = this.FechaEntregaCot.bind(this);
    this.successFechaEntregaCot = this.successFechaEntregaCot.bind(this);
    this.retornaBodegasPorCodigoFacturacion = this.retornaBodegasPorCodigoFacturacion.bind(this);
    this.successBodegasCodFac = this.successBodegasCodFac.bind(this);
    this.cerrarEdicionModal = this.cerrarEdicionModal.bind(this);
    // this.validarPreciosItems                               = this.validarPreciosItems.bind(this);
    // this.successValidarPreciosItems                        = this.successValidarPreciosItems.bind(this);
    this.conservaBodegaSeleccionada = this.conservaBodegaSeleccionada.bind(this);
    this.gridOptionsProductos = Object.assign({}, this.gridOptions);
    this.modalCodigoProducto = this.modalCodigoProducto.bind(this);
    this.setButtonBodega1 = this.setButtonBodega1.bind(this);
    this.setButtonBodega2 = this.setButtonBodega2.bind(this);
    this.setButtonBodega3 = this.setButtonBodega3.bind(this);
    this.setButtonBodega4 = this.setButtonBodega4.bind(this);
    this.setButtonBodega5 = this.setButtonBodega5.bind(this);
    this.setButtonBodega6 = this.setButtonBodega6.bind(this);
    this.setButtonBodega7 = this.setButtonBodega7.bind(this);
    this.setButtonBodega8 = this.setButtonBodega8.bind(this);
    this.setButtonBodega9 = this.setButtonBodega9.bind(this);
    this.setButtonBodega10 = this.setButtonBodega10.bind(this);
    this.confirmarItemsModalProducto = this.confirmarItemsModalProducto.bind(this);
    this.tablaCodigoProducto = this.tablaCodigoProducto.bind(this);
    this.successTablaCodigoProducto = this.successTablaCodigoProducto.bind(this);
    this.closeModalListar = this.closeModalListar.bind(this);
    this.estadoModalListar = false;
    this.loadTablaItems = this.loadTablaItems.bind(this);
    this.setButtonCodigo = this.setButtonCodigo.bind(this);
    this.setButtonNombre = this.setButtonNombre.bind(this);
    this.setButtonBodegas = this.setButtonBodegas.bind(this);
    this.llamarModalListar = this.llamarModalListar.bind(this);
    this.loadTabla = false;
    this.idProducto = '';
    this.registroSeleccionado = '';
    this.estadoTablaProductosBodegas = [];
    this.productoModalListar = '';
    this.campo1ModalListar = '';
    this.campo2ModalListar = '';
    this.setBodega = '';
    this.habilitarBtnGrabarPorBodega = this.habilitarBtnGrabarPorBodega.bind(this);
    this.openModalGrabarPorBodega = this.openModalGrabarPorBodega.bind(this);
    this.gridOptionsGrabarPorBodega = Object.assign({}, this.gridOptions);
    this.opciones = [{ text: 'Ninguno', value: '', campos_cambian: {} }];
    this.seleccionBodegas = this.seleccionBodegas.bind(this);
    this.setBottomBodega = this.setBottomBodega.bind(this);
    this.setBottomCodigo = this.setBottomCodigo.bind(this);
    this.setBottomNombre = this.setBottomNombre.bind(this);
    this.setBottomCantidad = this.setBottomCantidad.bind(this);
    this.setBottomPrecioSinIva = this.setBottomPrecioSinIva.bind(this);
    this.bodegasArray = [];
    this.grabarPorBodega = this.grabarPorBodega.bind(this);
    this.successGrabarPorBodega = this.successGrabarPorBodega.bind(this);
    this.validarArrayPorBodegas = this.validarArrayPorBodegas.bind(this);
    this.setBottomEstado = this.setBottomEstado.bind(this);
    this.mensajeErrorNomBodega = this.mensajeErrorNomBodega.bind(this);
    this.estadoBodegasArray = false;
    this.modalCampoBCodigoProducto = this.modalCampoBCodigoProducto.bind(this);
    this.arrayAuxiliarCodigoProducto = [];// Esta variable global de tipo arreglo es la que va a contener el data de la respuesta de los servicios que se consumen en el campo de búsqueda (B) "Código Producto" con los campos específicos y que sólo que quieren mostrar
    this.onSelectionChangedTbCodProd = this.onSelectionChangedTbCodProd.bind(this);
    this.gridOptionsModalCodigoProducto = Object.assign({}, this.gridOptions);
    this.gridOptionsModalCodigoProducto["onSelectionChanged"] = this.onSelectionChangedTbCodProd;
    this.enterServicioCodigoProducto = this.enterServicioCodigoProducto.bind(this);// Esta función de tipo flecha se hace para capturar los eventos f9 y enter del campo de búsqueda "Código Producto"
    this.successEnterServicioCodigoProducto = this.successEnterServicioCodigoProducto.bind(this);
    this.estadoServicioCodigoProducto = true;// Esta variable global de tipo booleana va a manejar el estado del campo de búsqueda "Código Producto", como los servicios de "Enter" y "f9" se están consumiendo desde custom, esta variable va a servir; para que cuando se modifique el campo "Código Producto" despues de haberse consumido uno de los 3 servicios, se limpien los campos relacionados a este.
    this.nitDeProspectos = false; // Esta variable global va a servir para que cuando se le agregue un producto a ese prospecto seteado y se llenan los campos requeridos para llegar a el punto de hacer clic en el botón de "aceptar" no se consuma el servicio "pedidos-calcularfechaentrega" sí y sólo sí es un prospecto (Requerimiento de la tarea en jira: https://jaivana.atlassian.net/browse/JBAS-1654)
    this.concatenarNombre = this.concatenarNombre.bind(this);
    this.limpiarMensajesErrorModalProspectos = this.limpiarMensajesErrorModalProspectos.bind(this);
    this.traerPaises = this.traerPaises.bind(this);
    this.successTraerPaises = this.successTraerPaises.bind(this);
    this.validacionPrecioLista = true; // Esta variable de tipo booleana va a servir para controlar el mensaje de error para el campo "Precio Lista", si está en true; va a limpiar el mensaje de error que está en la función cleanValid(), de lo contrario; no va a mostrar
    this.tipoDocumento = this.tipoDocumento.bind(this);
    this.validarCamposApellidos = this.validarCamposApellidos.bind(this);
    this.calcularMargen = this.calcularMargen.bind(this);


    //nuevas--
    this.traerVendedor = this.traerVendedor.bind(this);
    this.successTraerVendedor = this.successTraerVendedor.bind(this);
    this.traerListaPrecios = this.traerListaPrecios.bind(this);
    this.traerNegocios = this.traerNegocios.bind(this);
    this.traerSucursalPendiente = this.traerSucursalPendiente.bind(this);
    this.agregarContacto = this.agregarContacto.bind(this);
    this.validarCosto = this.validarCosto.bind(this);


    this.gridOptionsTablaItems['onSelectionChanged'] = this.rowSelectedPedido;
    this.sucursal_ingreso = '';
    this.nombre_archivo_reporte = 'pedido';
    this.opcion = 'normal';
    this.email_imprimir = '';
  }


  initForm() {

    console.log("Cotización Mercancía @version 1.01.0051, @author Juan Nieto, @updateBy: @updateBy: Anderson Acevedo.");
    // Acá almacenamos la información del usuario logueado que queda en el localStorage en la variable global de tipo array "this.localStorageData":
    this.localStorageData = JSON.parse(localStorage.getItem("res"));
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

    //campos deshabilitados al inicio
    this.getField("btn_producto_bodega").setDisabled(true);
    this.getField("boton_agregar_item").setDisabled(true);
    this.getField("codigo_bodega_item").setDisabled(false);
    this.getField("direccion").setDisabled(false);
    this.getField("tarifa_iva_item").setDisabled(true);
    this.getField("valor_total_item").setDisabled(true);

    this.getField("subtotal").setDisabled(true);
    this.getField("valor_iva_final").setDisabled(true);
    this.getField("valor_pedido").setDisabled(true);

    this.getField("generar_archivo").setClick(() => {
      if (this.getField('email_imprimir').valid()) {
        this.email_imprimir = this.getField('email_imprimir').getValue();
        this.getField('modal_imprimir').handleClose();
        if (this.opcion === 'normal')
          this.savePedido();
        else
          this.grabarPorBodega();
      }
    });

    //botones de carga
    this.getField("sucursales_pendientes").setOnChange(this.traerSucursalPendiente);//nit pendiente
    this.getField("lista_precios_prospectos").setOnChange(this.traerListaPrecios);//nit pendiente
    this.getField("tipo_negocio_id").setOnChange(this.traerNegocios);//nit pendiente

    this.getField("agregar_contactos").setClick(this.agregarContacto);
    //this.getField("agregar_contactos").setSuccess(this.abrirModalContactos);
    this.getField("btn_contacto").setClick(this.abrirModalContactos);
    this.getField("cancelar_contactos").setClick(this.cerrarModalContactos);
    this.getField("cancelar_nuevo").setClick(this.cerrarModalContactos);
    this.getField("agregar_nuevo").setClick(this.agregarNuevoPendiente);
    this.getField("documento_nuevo").setOnBlur(this.calcularDV); this.opcion =
      this.getField("pais_id").setOnChange(this.recuperarDepto);
    this.getField("departamento_geografico_id").setOnChange(this.recuperarCiudades);
    this.retornarCodigosFacturacion();
    // this.getField("boton_agregar_item").setDisabled(true);
    this.getField("cantidad_item_520").setOnChange(this.recalcularPreciosCantidad);
    this.getField('codigo_bodega_item').setOnChange(this.recalcularPreciosCantidad);
    this.getField("codigo_bodega_item").setOnChange(this.loadItemPrecio);
    //this.getField('boton_buscar').setClick(this.searchTercero);
    this.getField("boton_productos").setClick(this.openModalProducto);
    //this.getField("boton_grabar").setClick(this.savePedido);

    this.getField("boton_grabar").setClick(() => {
      if (this.getField("observaciones").valid() && this.getField("subtotal").valid() && this.getField("valor_pedido").valid() &&
        this.getField("numero_de_item").valid() && this.getField("clase_de_pedido").valid() &&
        this.getField("orden_de_compra").valid()) {
        this.opcion = 'normal';
        this.traerCorreo();
        this.getField('modal_imprimir').handleClickOpen();
      }
    });



    this.getField("codigo_id_producto").setOnChange(this.loadPreciosCliente);
    this.getField("nombre_producto").setOnChange(this.clearCamposProducto);
    // this.getField("cantidad_item_520").setOnBlur(this.loadItemPrecio);
    this.getField("cantidad_item_520").setOnBlur(this.validarDespachoMinimo);
    this.getField("orden_cotizacion").setOnChange(this.valueOrdenCotizacion);
    // this.getField("detalle_item").setOnBlur(this.validarCostosItem);
    this.getField("boton_agregar_item").setClick(this.validarCosto);
    //this.getField("boton_agregar_item").setSuccess(this.validarCostosItem);
    //this.getField("modal_producto").setCloseButton(this.crearPedido);
    this.getField('modal_producto').setCloseButton(this.cerrarEdicionModal);
    //this.getField("boton_aceptar").setClick(this.crearPedido);
    this.getField("boton_aceptar").setClick(this.CloseModalProductos);
    //this.getField('codigo_facturacion_actual').setOnChange(this.codigoFacturacionPedido);
    this.getField("descuento_item").setOnChange(this.recalcularPrecios);
    this.getField("descuento_item").setOnBlur(this.validarDescuentoMaximo);/*this.recalcularPrecios*/
    this.getField("precio_lista").setOnChange(this.recalcularPreciosLista);
    this.getField("codigo_producto").setOnChange(() => { this.validacionPrecioLista = true; this.cleanValid(); this.getField("codigo_producto").valid() });
    //this.getField('orden_cotizacion').setOnChange();
    this.getField("observaciones").setOnChange(this.cleanValid);
    this.getField("clase_de_pedido").setOnChange(this.cleanValid);
    this.getField("orden_de_compra").setOnChange(this.cleanValid);
    this.getField("detalle_item").setOnChange(this.cleanValid);
    this.getField("cantidad_item_520").setOnChange(this.cleanValid);
    //this.getField("descuento_item").setOnChange(this.cleanValid);
    this.getField("precio_lista").setOnChange(this.cleanValid);
    this.getField("nit").setOnChange(this.cleanValid);
    this.getField("nit").setOnChange(this.clearCampos);
    this.getField("nit").setKeyUp(this.enterServicioTerceros);
    //this.getField('nit').setKeyUp(this.enterServicioTerceros);
    this.getField("sede").setOnChange(this.cleanValid);
    this.getField("nombre_cliente").setOnChange(this.searchTercero);
    this.getField("precio_lista").setOnChange(this.cleanRecalcular);
    //this.getField("descuento_item").setOnChange(this.cleanRecalcular);
    this.getField("cantidad_item_520").setOnChange(this.cleanRecalcularCant);
    this.getField("descuento_general").setVisible(false);
    this.getField("aplicar_descuento").setVisible(false);
    this.getField("aplicar_descuento").setClick(this.aplicarDescuentoBloque);
    this.getField("numero_de_item").setOnChange(this.FechaEntregaCot);
    this.getField("codigo_bodega_item").setOnChange(this.conservaBodegaSeleccionada);
    this.getField('listar_productos').setClick(this.modalCodigoProducto);
    this.getField('buscar_productos').setClick(this.tablaCodigoProducto);
    //this.getField('modal_codigo_producto').setCloseButton(this.closeModalListar);
    this.getField('codigo_grupo_producto').setOnChange(this.llamarModalListar);
    this.getField('btn_producto_bodega').setClick(this.openModalGrabarPorBodega);
    this.getField('btn_seleccionar').setClick(this.seleccionBodegas);
    this.getField('codigo_bodega').setOnChange(this.seleccionBodegas);
    this.getField('btn_grabar_por_bodega').setClick(this.validarArrayPorBodegas);
    this.getField('nombre_bodega').setOnChange(this.mensajeErrorNomBodega);
    this.getField('modal_grabar_por_bodega').setCloseButton(this.cerrarGrabarPorBodega);
    this.getField('codigo_producto').setKeyUp(this.enterServicioCodigoProducto);
    this.getField('nom1').setOnChange(this.concatenarNombre);
    this.getField('nom2').setOnChange(this.concatenarNombre);
    this.getField('ape1').setOnChange(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
    this.getField('ape2').setOnChange(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
    this.getField('tipo_documentos_id').setOnChange(() => {
      this.getField('tipo_documentos_id').getValue() !== '' ? this.getField('tipo_documentos_id').setError(false, '') : this.getField('tipo_documentos_id').valid();

    });
    this.getField('nombre_tipos_documentos').setOnChange(() => { this.tipoDocumento() });
    this.getField('nombre_cliente_nuevo').setOnChange(() => { this.getField('nombre_cliente_nuevo').getValue().length >= 1 ? this.getField('nombre_cliente_nuevo').setError(false, "") : this.getField('nombre_cliente_nuevo').valid(); });
    this.getField('ciudad_id').setOnChange(() => { this.getField('ciudad_id').getValue() !== '' ? this.getField('ciudad_id').setError(false, '') : this.getField('ciudad_id').valid() });
    this.getField('negocios_id').setOnChange(() => { this.getField('negocios_id').getValue() !== '' ? this.getField('negocios_id').setError(false, '') : this.getField('negocios_id').valid() });
    this.getField('calidad_agente_retenedor_ventas_id').setOnChange(() => { this.getField('calidad_agente_retenedor_ventas_id').getValue() !== '' ? this.getField('calidad_agente_retenedor_ventas_id').setError(false, '') : this.getField('calidad_agente_retenedor_ventas_id').valid() });
    this.getField('tipo_persona').setOnChange(() => { this.getField('tipo_persona').getValue() !== '' ? this.getField('tipo_persona').setError(false, '') : this.getField('tipo_persona').valid() });
    this.getField('sexo').setOnChange(() => { this.getField('sexo').getValue() !== '' ? this.getField('sexo').setError(false, '') : this.getField('sexo').valid() });
    this.traerPaises();
    this.getField("precio_lista").setOnBlur(this.calcularMargen);
  }

  //nuevas funciones-------------->


  validarCosto() {
    this.getField('codigo_producto').setError(false, '');
    this.getField('nombre_producto').setError(false, '');
    this.getField('codigo_bodega_item').setError(false, '');
    this.getField('precio_sin_iva_con_descuento').setError(false, '');
    this.getField('codigo_bodega_item').setError(false, '');

    if (this.getField('codigo_producto').valid() && this.getField('nombre_producto').valid()
      && this.getField('codigo_bodega_item').valid() && this.getField('codigo_bodega_item').valid()
    && this.getField('precio_lista').valid()
    && this.getField('descuento_item').valid()
    && this.getField('cantidad_item_520').valid()
    && this.getField('valor_total_item').valid()
    ) {
      this.getField('boton_agregar_item').setDisabled(true);
      let datos = {
        datos: {
          "codigo_producto": this.getField('codigo_producto').getValue(),
          "precio_sin_iva_con_descuento": this.getField('precio_lista').getValue(),
          "codigo_bodega": this.getField('codigo_bodega_item').getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'validarcosto', operacion_tipo: 'crear' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'POST',
          body: datos,
          success: this.validarCostosItem,
          error: this.error_,
          general: this.generalFormatPmv
        });
    }
  }


  agregarContacto() {
    this.getField('nit_contactos').setError(false, '');
    this.getField('sede_contactos').setError(false, '');
    this.getField('nombre_contacto').setError(false, '');
    this.getField('telefono_contactos').setError(false, '');
    this.getField('email_contactos').setError(false, '');
    this.getField('observacion_contactos').setError(false, '');

    if (this.getField('nit_contactos').valid() && this.getField('sede_contactos').valid()
      && this.getField('nombre_contacto').valid() && this.getField('telefono_contactos').valid()
      && this.getField('email_contactos').valid() && this.getField('observacion_contactos').valid()
    ) {
      this.getField('agregar_contactos').setDisabled(true);
      let datos = {
        datos: {
          "nit": this.getField('nit_contactos').getValue(),
          "sede": this.getField('sede_contactos').getValue(),
          "nombre": this.getField('nombre_contacto').getValue(),
          "telefonos": this.getField('telefono_contactos').getValue(),
          "email": this.getField('email_contactos').getValue(),
          "observaciones": this.getField('observacion_contactos').getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-terceroscontactos', operacion: '5', operacion_tipo: 'crear' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'POST',
          body: datos,
          success: this.abrirModalContactos,
          error: this.error_,
          general: this.generalFormatPmv
        });
    }
  }

  traerVendedor() {
    let datos = {
      datos: {
        value: this.getField("campo_carga_modal_favoritos").getValue(),
      }
    };
    this.generalFormatPmv = { tipo_servicio: 'maes-vendedores', operacion: '42_3', operacion_tipo: 'consulta' };
    this.service.send(
      {
        endpoint: this.constant.formConfiguration(),
        method: 'GET',
        body: datos,
        success: this.successTraerVendedor,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage: false,
      });
  }

  traerSucursalPendiente() {
    if (this.getField("sucursales_pendientes").getValue().toString().trim() !== '') {
      let datos = {
        datos: {
          value: this.getField("sucursales_pendientes").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-vendedores', operacion: '41_1', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.setSucursalesProspectos,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false,
        });
    }
  }

  traerNegocios() {
    if (this.getField("tipo_negocio_id").getValue().toString().trim() !== '') {
      let datos = {
        datos: {
          negocios_id: this.getField("tipo_negocio_id").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-negocios', operacion: '2', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.setTipoNegocioProspecto,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false,
        });
    }
  }



  successTraerVendedor(data) {
    if (data.estado_p === 200) {
      this.getField('nombre_vendedor').setValue(data.data[0].nombre);
    } else {
      if (data.estado_p === 404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta = Object.values(data.data.errores);
        let keys = Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
      }
    }
  }


  traerListaPrecios() {
    if (this.getField("lista_precios_prospectos").getValue().toString().trim() !== '') {
      let datos = {
        datos: {
          value: this.getField("lista_precios_prospectos").getValue(),//nit
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: '1_lista_precios', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.setPrecioListaProspectos,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false,
        });
    }
  }

  //funciones anteriores--------->


  calcularMargen() {
    if (this.getField("precio_lista").getValue() !== '' && this.getField("precio_lista").getValue() !== ' ' &&
      this.getField("costo").getValue() !== '' && this.getField("costo").getValue() !== ' ' && this.getField("precio_lista").valid()) {
      //let precio = this.getField("precio_lista").getValue();// no es necesario ya que se pidio cambiar el mensaje
      //let costo = this.getField("costo").getValue();
      //let margen= (precio - costo) / precio;
      //this.alertGeneral.toggle(true,`El margen del producto es: ${Math.round(margen*100)}%`,"warning");
      //this.alertGeneral.toggle(true,`Usted está superando el margen permitido.`,"warning");//nuevo mensaje 
      /* if (margen >= 0.8) {
        this.getField('confirmModalCustom').setTitleAndContent('Error', `Supera el 80% del margen, con un margen del ${Math.round(margen*100)}%`);
        this.getField('confirmModalCustom').setClickDialog(() => {this.getField("precio_lista").setValue(''); this.getField('precio_lista').input.focus(); this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      } */
    }
  }

  validarCamposApellidos() {
    if ((this.getField('ape1').getValue() !== '' && this.getField('ape1').getValue() !== '.' && this.getField('ape1').valid() && this.getField('ape2').valid()) ||
      (this.getField('ape2').getValue() !== '' && this.getField('ape2').getValue() !== '.' && this.getField('ape2').valid() && this.getField('ape1').valid())) {
      this.getField('ape1').setError(false, '');
      this.getField('ape2').setError(false, '');
    } else if (((this.getField('ape1').getValue()).replace(/\u0020/g, '') === '' || this.getField('ape1').getValue() === '.') &&
      ((this.getField('ape2').getValue()).replace(/\u0020/g, '') === '' || this.getField('ape2').getValue() === '.')) {
      this.getField('ape1').valid();
      this.getField('ape2').valid();
    }
  }

  tipoDocumento() {
    if (this.getField('nombre_tipos_documentos').getValue() !== '' && this.getField('tipo_documentos_id').getValue() !== '') {
      if (this.getField('nombre_tipos_documentos').getValue() === 'NIT') {
        this.getField('nombre_cliente_nuevo').setDisabled(false);
        this.getField('nom1').setDisabled(true);
        this.getField('nom2').setDisabled(true);
        this.getField('ape1').setDisabled(true);
        this.getField('ape2').setDisabled(true);
        this.getField('nombre_cliente_nuevo').setValue('');
        this.getField('nombre_cliente_nuevo').setError(false, '');
        this.getField('nom1').setValue('.');
        this.getField('nom2').setValue('.');
        this.getField('ape1').setValue('.');
        this.getField('ape2').setValue('.');
      } else {
        this.getField('nombre_cliente_nuevo').setDisabled(true);
        this.getField('nom1').setDisabled(false);
        this.getField('nom2').setDisabled(false);
        this.getField('ape1').setDisabled(false);
        this.getField('ape2').setDisabled(false);
        this.getField('nombre_cliente_nuevo').setValue('');
        this.getField('nombre_cliente_nuevo').setError(false, '');
        this.getField('nom1').setValue('.');
        this.getField('nom2').setValue('.');
        this.getField('ape1').setValue('.');
        this.getField('ape2').setValue('.');
      }
    }
  }

  traerPaises() { // Se consume desde Custom y no desde la configuración del campo select, ya que sólo se va a listar los de estadoactivo_id = 1
    /*if (localStorage.getItem('pmvLogin') && localStorage.getItem('sucursal_ingreso')) {
      let datos = { datos: {} };
      let generales = { "tipo_servicio": "ferr-12", "operacion": "1", "operacion_tipo": "consulta" }

      let url = this.pmvUrl.split('/pmvlogin');
      let endpointPmv = `${url[0]}/pmv/api/protected`;
      this.service.send(
          {
              endpoint: endpointPmv,
              method: 'GET',
              body: datos,
              success: this.successTraerPaises,
              error: this.error_,
              general: generales,
              pmvRequest: true
          }
      );
    } else {*/
    let datos = {
      datos: {
      }
    };
    this.generalFormatPmv = { tipo_servicio: 'maes-paises', operacion: '1_asc', operacion_tipo: 'consulta' };
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method: 'GET',
      body: datos,
      success: this.successTraerPaises,
      error: this.error_,
      general: this.generalFormatPmv,
    });
    //}
  }

  successTraerPaises(data) {
    if (data.estado_p === 200 && data.data.length >= 1) {
      let arrayFiltrar = [];
      let itemIncrement = 0;
      data.data.map((itemFiltrar) => {
        if (itemFiltrar.paises_estadoactivo_id === 1) {
          arrayFiltrar.push(data.data[itemIncrement]);
        }
        itemIncrement += 1;
        return null;
      });
      let opciones = [{ text: 'Ninguno', value: '', 'campos_cambian': {} }];
      arrayFiltrar.forEach(item => {
        let dataOp = {}
        dataOp['value'] = `${item.paises_id}`;
        dataOp['text'] = `${item.nombre}`;
        dataOp['campos_cambian'] = {};
        opciones.push(dataOp);
      })
      this.getField('pais_id').setOptions(opciones);
    } else {
      this.getField('ciudad_id').setOptions(this.opciones);
      this.getField('departamento_geografico_id').setOptions(this.opciones);
      this.getField('pais_id').setOptions(this.opciones);
    }
  }

  limpiarMensajesErrorModalProspectos() {
    this.getField('documento_nuevo').setError(false, "");
    this.getField('verifica').setError(false, "");
    this.getField('tipo_documentos_id').setError(false, "");
    this.getField('nom1').setError(false, "");
    this.getField('nom2').setError(false, "");
    this.getField('ape1').setError(false, "");
    this.getField('ape2').setError(false, "");
    this.getField('nombre_cliente_nuevo').setError(false, "");
    this.getField('direccion_nuevo').setError(false, "");
    this.getField('telefonos').setError(false, "");
    this.getField('pais_id').setError(false, "");
    this.getField('departamento_geografico_id').setError(false, "");
    this.getField('ciudad_id').setError(false, "");
    this.getField('negocios_id').setError(false, "");
    this.getField('calidad_agente_retenedor_ventas_id').setError(false, "");
    this.getField('tipo_persona').setError(false, "");
    this.getField('email').setError(false, "");
    this.getField('codigo_vendedor_prospecto').setError(false, "");
    this.getField('nombre_vendedor_prospecto').setError(false, "");
    this.getField('fecha_nacimiento').setError(false, "");
    this.getField('sexo').setError(false, "");
  }

  concatenarNombre() {
    let nom1 = this.getField('nom1').getValue();
    let nom2 = this.getField('nom2').getValue();
    let ape1 = this.getField('ape1').getValue();
    let ape2 = this.getField('ape2').getValue();
    let unionCampos = '';
    let arrayNombreFinal = [];
    let arrayUnionCampos = `${nom1},${nom2},${ape1},${ape2}`.split(/\u002C/g);// Se crea un array  partir de las comas (,), la expresión regular que tiene el "split" es del carácter coma.
    arrayUnionCampos.forEach((item) => {// La idea con este forEach es crear un arreglo sólo con nombres y apellidos con un valor diferente a punto (.)
      if (item.replace(/\u0020/g, '') !== '' && item.replace(/\u0020/g, '') !== '.') {// El replace se hace para eliminar los espacios
        arrayNombreFinal.push(item);
      }
    });
    let concatenarNombre = '';
    arrayNombreFinal.forEach((item) => {// Lo que se hace con este forEach es crear una cadena con los nombres y apellidos que están en el arreglo "arrayNombreFinal"
      concatenarNombre += `${item} `;
    });
    unionCampos = concatenarNombre.substring(0, concatenarNombre.length - 1);// Se quita el último carácter de la cadena, que para este caso sería un espacio.

    if (this.getField('nom1').valid() && this.getField('nom2').valid() && this.getField('ape1').valid() && this.getField('ape2').valid() && unionCampos.replace(/\u0020/g, '') !== '') {
      this.getField('nombre_cliente_nuevo').setValue(unionCampos);
    } else if (this.getField('nombre_cliente_nuevo').getValue() !== '') {
      this.getField('nombre_cliente_nuevo').setValue('');
      this.getField('nombre_cliente_nuevo').setError(false, '');
    }
  }

  onSelectionChangedTbCodProd() {
    // let filaSelecionada   =  this.gridOptionsModalCodigoProducto.api.getSelectedRows();
    // console.log("Este es el mensaje de onSelectionChangedTbCodProd: ", filaSelecionada[0]);
    this.getField('modal_modal_codigo_producto').handleClose();
    this.arrayAuxiliarCodigoProducto = [];
    this.gridOptionsModalCodigoProducto['rowData'] = [];
    this.getField('tb_modal_codigo_producto').initData(this.gridOptionsModalCodigoProducto);
  }

  /**
   * Función flecha que captura los eventos "enter" y "f9" del campo de búsqueda (B) "Código Producto"
   * Se consume el servicio de acuerdo al evento que se ejecute; ya sea "f9" o "enter"
   * @param {Contiene la infomación, valores o atributos de la tecla capturada por teclado} e 
   */
  enterServicioCodigoProducto = (e) => {
    if (this.getField('codigo_producto').valid()) {
      if (this.estadoServicioCodigoProducto === false) {
        this.getField('nombre_producto').setValue('');
        this.getField('codigo_id_producto').setValue('');
        this.getField('codigo_grupo_producto').setValue('');
        this.getField('codigo_clasificacion').setValue('');
        this.estadoServicioCodigoProducto = true;
      }
      this.arrayAuxiliarCodigoProducto = [];
      // console.log('Este es el evento:\n',e);
      let keycode = e.keyCode ? e.keyCode : e.which;
      switch (keycode) {
        case 120:
          if (this.getField("codigo_producto").getValue() !== "" && this.getField("codigo_facturacion").getValue() !== "") {
            /*if(localStorage.getItem('pmvLogin')){
              let datos = {datos: {
                value: this.getField("codigo_producto").getValue(),
                codigo_facturacion: this.getField("codigo_facturacion").getValue()
               }};
              let generales = {"tipo_servicio": "ferr-articulos", "operacion": "nombre_bodegas", "operacion_tipo":"consulta" }
  
              let url = this.pmvUrl.split('/pmvlogin');
              let endpointPmv = `${url[0]}/pmv/api/protected`;
              this.service.send(
                  {
                      endpoint: endpointPmv,
                      method: 'GET',
                      body: datos,
                      success:this.modalCampoBCodigoProducto,
                      error: this.error_,
                      general: generales,
                      pmvRequest: true
                  }
              );
            }else{*/
            let datos = {
              datos: {
                value: this.getField("codigo_producto").getValue(),
                codigo_facturacion: this.getField("codigo_facturacion").getValue()
              }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: 'nombre_bodegas', operacion_tipo: 'consulta' };
            this.service.send(
              {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.modalCampoBCodigoProducto,
                error: this.error_,
                general: this.generalFormatPmv
              });
            //}
          }
          break;
        case 13:
          if (this.getField("codigo_producto").getValue() !== "" && this.getField("codigo_facturacion").getValue() !== "") {
            /*if(localStorage.getItem('pmvLogin') && localStorage.getItem('sucursal_ingreso')){
              let datos = {datos: {
                value: this.getField("codigo_producto").getValue(),
                codigo_facturacion: this.getField("codigo_facturacion").getValue()
               }};
              let generales = {"tipo_servicio": "ferr-articulos", "operacion": "codigo_bodegas", "operacion_tipo":"consulta" }
  
              let url = this.pmvUrl.split('/pmvlogin');
              let endpointPmv = `${url[0]}/pmv/api/protected`;
              this.service.send(
                  {
                      endpoint: endpointPmv,
                      method: 'GET',
                      body: datos,
                      success:this.successEnterServicioCodigoProducto,
                      error: this.error_,
                      general: generales,
                      pmvRequest: true
                  }
              );
            }else{*/
            let datos = {
              datos: {
                value: this.getField("codigo_producto").getValue(),
                codigo_facturacion: this.getField("codigo_facturacion").getValue()
              }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: 'codigo_bodegas', operacion_tipo: 'consulta' };
            this.service.send(
              {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successEnterServicioCodigoProducto,
                error: this.error_,
                general: this.generalFormatPmv
              });
            //}
          }
          break;
        default:
          break;
      }
    }
  };

  /**
   * Esta función con la respuesta del servicio, va a setear los campos del formulario relacionados con el campo de búsqueda "Código Producto"
   * @param {Contiene la respuesta del servicio con ruta "articulo-codigo_bodegas" cuyo nombre acción es "520_articulo_codigo_bodegas"} data 
   */
  successEnterServicioCodigoProducto(data) {
    this.estadoServicioCodigoProducto = false;
    if (data.estado_p === 200 && data.data.length >= 1) {
      this.getField('codigo_producto').setValue(data.data[0].codigo);
      this.getField('nombre_producto').setValue(data.data[0].nombre);
      this.getField('codigo_id_producto').setValue(data.data[0].codigo);
      this.getField('codigo_grupo_producto').setValue(data.data[0].codigo_grupo);
    }
  }

  /**
   * Esta función es la que va a construir la modal del campo de búsqueda "Código Producto" cuando se realice una búsqueda por concidencia de nombre (f9)
   * Se construye desde custom; ya que el data de la respuesta del servicio, responde con muchos parámetros que no se quieren mostrar en la tabla la modal y cómo también se muestran los campos saldos en la tabla yeste son
   * dinámicos, esta función se encarga de construir un arreglo con estos campos dinámicos para despues dibujarlo en una tabla, dónde sólo se van a mostrar un total de 10 columnas en los saldos
   * @param {Contiene la respues de los servicios que se consumen en el campo de búsqueda} data 
   */
  modalCampoBCodigoProducto(data) {
    // console.log('Este es el data de modalCampoBCodigoProducto:\n',data.data);
    this.estadoServicioCodigoProducto = false;
    if (data.data.length >= 1) {
      let numberEncabezadoArray = Object.keys(data.data[0]);// Se hace para obtener las claves de los objetos con que responde el servicio, la idea de ello; es obtener los nombres de los saldos
      let bodegasPrint = (numberEncabezadoArray.length - 31);// Se hace para determinar la cantidad de columnas con saldo existen
      // console.log('numberEncabezadoArray: ',numberEncabezadoArray);
      // console.log('bodegasPrint: ',bodegasPrint);

      let encabezado1 = numberEncabezadoArray[30];
      let encabezado2 = numberEncabezadoArray[31];
      let encabezado3 = numberEncabezadoArray[32];
      let encabezado4 = numberEncabezadoArray[33];
      let encabezado5 = numberEncabezadoArray[34];
      let encabezado6 = numberEncabezadoArray[35];
      let encabezado7 = numberEncabezadoArray[36];
      let encabezado8 = numberEncabezadoArray[37];
      let encabezado9 = numberEncabezadoArray[38];
      let encabezado10 = numberEncabezadoArray[39];
      let encabezado11 = numberEncabezadoArray[40];

      let encabezado_campo_id = numberEncabezadoArray[0];
      let encabezado_campo_codigo = numberEncabezadoArray[1];
      let encabezado_campo_nombre = numberEncabezadoArray[2];
      let encabezado_campo_alias = numberEncabezadoArray[3];
      let encabezado_campo_rentabilidad = numberEncabezadoArray[4];
      let encabezado_campo_precio = numberEncabezadoArray[8];
      let encabezado_campo_precio2 = numberEncabezadoArray[9];
      let encabezado_campo_precio3 = numberEncabezadoArray[10];
      let encabezado_campo_marca = numberEncabezadoArray[16];
      let encabezado_campo_codigo_clasificacion = numberEncabezadoArray[28];

      if (bodegasPrint === 1) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);// Se hace para obtener todos los valores de cada objeto de la respuesta del servicio posición por posición dentro del arreglo de objetos
          let colum1CodigoProducto = numberValuesArray[30];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint === 2) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint === 3) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];
          let colum3CodigoProducto = numberValuesArray[32];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;
          let column3 = {};
          column3[encabezado3] = colum3CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint === 4) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];
          let colum3CodigoProducto = numberValuesArray[32];
          let colum4CodigoProducto = numberValuesArray[33];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;
          let column3 = {};
          column3[encabezado3] = colum3CodigoProducto;
          let column4 = {};
          column4[encabezado4] = colum4CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint === 5) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];
          let colum3CodigoProducto = numberValuesArray[32];
          let colum4CodigoProducto = numberValuesArray[33];
          let colum5CodigoProducto = numberValuesArray[34];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;
          let column3 = {};
          column3[encabezado3] = colum3CodigoProducto;
          let column4 = {};
          column4[encabezado4] = colum4CodigoProducto;
          let column5 = {};
          column5[encabezado5] = colum5CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint === 6) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];
          let colum3CodigoProducto = numberValuesArray[32];
          let colum4CodigoProducto = numberValuesArray[33];
          let colum5CodigoProducto = numberValuesArray[34];
          let colum6CodigoProducto = numberValuesArray[35];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;
          let column3 = {};
          column3[encabezado3] = colum3CodigoProducto;
          let column4 = {};
          column4[encabezado4] = colum4CodigoProducto;
          let column5 = {};
          column5[encabezado5] = colum5CodigoProducto;
          let column6 = {};
          column6[encabezado6] = colum6CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint === 7) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];
          let colum3CodigoProducto = numberValuesArray[32];
          let colum4CodigoProducto = numberValuesArray[33];
          let colum5CodigoProducto = numberValuesArray[34];
          let colum6CodigoProducto = numberValuesArray[35];
          let colum7CodigoProducto = numberValuesArray[36];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;
          let column3 = {};
          column3[encabezado3] = colum3CodigoProducto;
          let column4 = {};
          column4[encabezado4] = colum4CodigoProducto;
          let column5 = {};
          column5[encabezado5] = colum5CodigoProducto;
          let column6 = {};
          column6[encabezado6] = colum6CodigoProducto;
          let column7 = {};
          column7[encabezado7] = colum7CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, column7, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint === 8) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];
          let colum3CodigoProducto = numberValuesArray[32];
          let colum4CodigoProducto = numberValuesArray[33];
          let colum5CodigoProducto = numberValuesArray[34];
          let colum6CodigoProducto = numberValuesArray[35];
          let colum7CodigoProducto = numberValuesArray[36];
          let colum8CodigoProducto = numberValuesArray[37];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;
          let column3 = {};
          column3[encabezado3] = colum3CodigoProducto;
          let column4 = {};
          column4[encabezado4] = colum4CodigoProducto;
          let column5 = {};
          column5[encabezado5] = colum5CodigoProducto;
          let column6 = {};
          column6[encabezado6] = colum6CodigoProducto;
          let column7 = {};
          column7[encabezado7] = colum7CodigoProducto;
          let column8 = {};
          column8[encabezado8] = colum8CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, column7, column8, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint === 9) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];
          let colum3CodigoProducto = numberValuesArray[32];
          let colum4CodigoProducto = numberValuesArray[33];
          let colum5CodigoProducto = numberValuesArray[34];
          let colum6CodigoProducto = numberValuesArray[35];
          let colum7CodigoProducto = numberValuesArray[36];
          let colum8CodigoProducto = numberValuesArray[37];
          let colum9CodigoProducto = numberValuesArray[38];

          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;
          let column3 = {};
          column3[encabezado3] = colum3CodigoProducto;
          let column4 = {};
          column4[encabezado4] = colum4CodigoProducto;
          let column5 = {};
          column5[encabezado5] = colum5CodigoProducto;
          let column6 = {};
          column6[encabezado6] = colum6CodigoProducto;
          let column7 = {};
          column7[encabezado7] = colum7CodigoProducto;
          let column8 = {};
          column8[encabezado8] = colum8CodigoProducto;
          let column9 = {};
          column9[encabezado9] = colum9CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, column7, column8, column9, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }
      if (bodegasPrint >= 10) {
        for (let i = 0; i < data.data.length; i++) {
          let numberValuesArray = Object.values(data.data[i]);
          let colum1CodigoProducto = numberValuesArray[30];
          let colum2CodigoProducto = numberValuesArray[31];
          let colum3CodigoProducto = numberValuesArray[32];
          let colum4CodigoProducto = numberValuesArray[33];
          let colum5CodigoProducto = numberValuesArray[34];
          let colum6CodigoProducto = numberValuesArray[35];
          let colum7CodigoProducto = numberValuesArray[36];
          let colum8CodigoProducto = numberValuesArray[37];
          let colum9CodigoProducto = numberValuesArray[38];
          let colum10CodigoProducto = numberValuesArray[39];
          let colum11CodigoProducto = numberValuesArray[39];


          let valor_campo_id = numberValuesArray[0];
          let valor_campo_codigo = numberValuesArray[1];
          let valor_campo_nombre = numberValuesArray[2];
          let valor_campo_alias = numberValuesArray[3];
          let valor_campo_rentabilidad = numberValuesArray[4];
          let valor_campo_precio = numberValuesArray[8];
          let valor_campo_precio2 = numberValuesArray[9];
          let valor_campo_precio3 = numberValuesArray[10];
          let valor_campo_marca = numberValuesArray[16];
          let valor_campo_codigo_clasificacion = numberValuesArray[28];

          let dataOp = {
            tarifa_iva: data.data[i].tarifa_iva,
            presenta: data.data[i].presenta,
            referencia: data.data[i].referencia,
            codigo_marca: data.data[i].codigo_marca,
            codigo_linea: data.data[i].codigo_linea,
            nombre_linea: data.data[i].nombre_linea,
            codigo_grupo: data.data[i].codigo_grupo,
            nombre_grupo: data.data[i].nombre_grupo,
            codigo_subgrupo: data.data[i].codigo_subgrupo,
            nombre_subgrupo: data.data[i].nombre_subgrupo,
            estado_descripcion: data.data[i].estado_descripcion,
            // estado: data.data[i].estado,
          }
          let column1 = {};
          column1[encabezado1] = colum1CodigoProducto;
          let column2 = {};
          column2[encabezado2] = colum2CodigoProducto;
          let column3 = {};
          column3[encabezado3] = colum3CodigoProducto;
          let column4 = {};
          column4[encabezado4] = colum4CodigoProducto;
          let column5 = {};
          column5[encabezado5] = colum5CodigoProducto;
          let column6 = {};
          column6[encabezado6] = colum6CodigoProducto;
          let column7 = {};
          column7[encabezado7] = colum7CodigoProducto;
          let column8 = {};
          column8[encabezado8] = colum8CodigoProducto;
          let column9 = {};
          column9[encabezado9] = colum9CodigoProducto;
          let column10 = {};
          column10[encabezado10] = colum10CodigoProducto;
          let column11 = {};
          column11[encabezado11] = colum11CodigoProducto;

          let campo_id = {};
          campo_id[encabezado_campo_id] = valor_campo_id;
          let campo_codigo = {};
          campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
          let campo_nombre = {};
          campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
          let campo_alias = {};
          campo_alias[encabezado_campo_alias] = valor_campo_alias;
          let campo_rentabilidad = {};
          campo_rentabilidad[encabezado_campo_rentabilidad] = valor_campo_rentabilidad;
          let campo_precio = {};
          campo_precio[encabezado_campo_precio] = valor_campo_precio;
          let campo_precio2 = {};
          campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
          let campo_precio3 = {};
          campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
          let campo_marca = {};
          campo_marca[encabezado_campo_marca] = valor_campo_marca;
          let campo_codigo_calsificacion = {};
          campo_codigo_calsificacion[encabezado_campo_codigo_clasificacion] = valor_campo_codigo_clasificacion;
          let objetoFinal = Object.assign(campo_codigo, campo_nombre, campo_alias, campo_marca, campo_codigo_calsificacion, campo_rentabilidad, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, column7, column8, column9, column10, column11, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
          this.arrayAuxiliarCodigoProducto.push(objetoFinal);
        }
        // console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
      }

      if (this.arrayAuxiliarCodigoProducto.length >= 1) {
        this.getField('tb_modal_codigo_producto').toggle(true);
        this.gridOptionsModalCodigoProducto['rowData'] = this.arrayAuxiliarCodigoProducto;
        this.getField('modal_modal_codigo_producto').handleClickOpen();
        this.getField('tb_modal_codigo_producto').initData(this.gridOptionsModalCodigoProducto);
      }
    }
  }

  cerrarGrabarPorBodega() {
    this.gridOptionsBodegasItem.api.deselectAll();
    this.estadoBodegasArray = false;
    this.bodegasArray = [];
    
  }

  mensajeErrorNomBodega() {
    if (this.getField('nombre_bodega').getValue() !== '' && this.getField('nombre_bodega').getValue() !== ' ') {
      this.getField('nombre_bodega').setError(false, '');
    } else {
      this.getField('nombre_bodega').setError(true, 'Vuelve a seleccionar un código bodega');
    }
  }

  seleccionBodegas() {
    this.bodegasArray = [];
    if (this.getField('codigo_bodega').valid()) {
      this.estadoBodegasArray = true;
      this.openModalGrabarPorBodega();
    }
  }

  openModalGrabarPorBodega() {
    // console.log('Esta es el this.nuevoArray de openModalGrabarPorBodega: ',this.nuevoArray[0].bodega);
    let arrayFiltrar = [];
    this.nuevoArray.forEach(item => {
      arrayFiltrar.push(item.bodega);
    });
    let uniqueArr = [...new Set(arrayFiltrar)];
    // console.log('Este es la variable arrayFiltrar: ', arrayFiltrar);
    // console.log('Este es la variable uniqueArr: ', uniqueArr);
    let opciones = [{ text: 'Ninguno', value: '', campos_cambian: {} }];
    uniqueArr.forEach(item => {
      let dataOp = {}
      dataOp['value'] = `${item}`;
      dataOp['text'] = `${item}`;
      dataOp['campos_cambian'] = {};
      opciones.push(dataOp);
    });
    this.getField('codigo_bodega').setOptions(opciones);
    // this.getField('codigo_bodega').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar una bodega que se habia seleccionado con anterioridad, no se setee la última bodega seleccionada, sino que solo liste el select con las bodegas correspondientes a la tabla listada.
    if (this.getField('sede').getValue() !== '' && this.getField('nit').getValue() !== '' &&
      this.getField('nombre_cliente').getValue() !== '' && this.getField('sucursal_origen').getValue() !== ''
      && this.getField('sucursal_cartera').getValue() !== '') {
      let datosFicha = [];
      let data2 = {
        "data": [
          {
            "nit": this.getField('nit').getValue(),
            "sede": this.getField('sede').getValue(),
            "nombre": this.getField('nombre_cliente').getValue(),
          }
        ]
      };
      this.getField('lista_terceros_por_bodega').setVisible(true);
      datosFicha.push(data2.data[0]);
      this.getField('lista_terceros_por_bodega').setItemsFichas(datosFicha);
      this.getField('modal_grabar_por_bodega').handleClickOpen();
      this.getField('codigo_bodega').setError(false, '* Este campo es requerido');
      this.registroSeleccionadoBodega = '';
    } else {
      this.getField('sede').setError(true, "* Este campo es requerido.");
      this.getField('nit').setError(true, "* Este campo es requerido.");
      this.getField('nombre_cliente').setError(true, "* Este campo es requerido.");
      this.getField('lista_terceros_nit').setVisible(false);
    }
    if (this.nuevoArray.length >= 1) {
      this.gridOptionsGrabarPorBodega['rowData'] = this.nuevoArray;
      let configCell = new Map();
      configCell.set('bodega', { cellRenderer: this.setBottomBodega, field: 'bodega', width: 121 });
      configCell.set('codigo', { cellRenderer: this.setBottomCodigo, field: 'codigo', width: 271 });
      configCell.set('nombre', { cellRenderer: this.setBottomNombre, field: 'nombre', width: 388 });
      configCell.set('cantidad', { cellRenderer: this.setBottomCantidad, field: 'cantidad', width: 271 });
      configCell.set('precio_sin_iva', { cellRenderer: this.setBottomPrecioSinIva, field: 'precio_sin_iva', width: 271 });
      configCell.set('estado', { cellRenderer: this.setBottomEstado, field: 'estado', width: 97 });
      this.getField('tabla_modal_grabar_bodega').initData(this.gridOptionsGrabarPorBodega, configCell);
      this.getField('tabla_modal_grabar_bodega').toggle(true);
      let totalCantidadPorPrSinIva = 0;
      this.nuevoArray.forEach(item => {
        let multiplicacion = (item.cantidad * item.precio_sin_iva);
        totalCantidadPorPrSinIva += multiplicacion;
      });
      this.getField('total_cantidad_preciosiniva').setValue(totalCantidadPorPrSinIva.toFixed(2));
    } else {
      this.getField('tabla_modal_grabar_bodega').toggle(false);
    }
  }

  setBottomBodega(props) {
    let registroSeleccionadoBodega = '';
    if (this.getField('codigo_bodega').getValue() !== '' && this.getField('codigo_bodega').getValue() !== ' ') {
      registroSeleccionadoBodega = this.getField('codigo_bodega').getValue();
    }
    let bodega = props.data.bodega;
    if (props.data.bodega === registroSeleccionadoBodega) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%; width: 121px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = bodega;
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
      div.textContent = bodega;
      return this.createElementJaivana(div);
    }
  }

  setBottomCodigo(props) {
    let registroSeleccionadoBodega = '';
    if (this.getField('codigo_bodega').getValue() !== '' && this.getField('codigo_bodega').getValue() !== ' ') {
      registroSeleccionadoBodega = this.getField('codigo_bodega').getValue();
    }
    let codigo = props.data.codigo;
    if (props.data.bodega === registroSeleccionadoBodega) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%; width: 271px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = codigo;
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
      div.textContent = codigo;
      return this.createElementJaivana(div);
    }
  }

  setBottomNombre(props) {
    let registroSeleccionadoBodega = '';
    if (this.getField('codigo_bodega').getValue() !== '' && this.getField('codigo_bodega').getValue() !== ' ') {
      registroSeleccionadoBodega = this.getField('codigo_bodega').getValue();
    }
    let nombre = props.data.nombre;
    if (props.data.bodega === registroSeleccionadoBodega) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%; width: 388px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = nombre;
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
      div.textContent = nombre;
      return this.createElementJaivana(div);
    }
  }

  setBottomCantidad(props) {
    let registroSeleccionadoBodega = '';
    if (this.getField('codigo_bodega').getValue() !== '' && this.getField('codigo_bodega').getValue() !== ' ') {
      registroSeleccionadoBodega = this.getField('codigo_bodega').getValue();
    }
    let cantidad = props.data.cantidad;
    if (props.data.bodega === registroSeleccionadoBodega) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%; width: 271px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = cantidad;
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
      div.textContent = cantidad;
      return this.createElementJaivana(div);
    }
  }

  setBottomPrecioSinIva(props) {
    let registroSeleccionadoBodega = '';
    if (this.getField('codigo_bodega').getValue() !== '' && this.getField('codigo_bodega').getValue() !== ' ') {
      registroSeleccionadoBodega = this.getField('codigo_bodega').getValue();
    }
    let precio_sin_iva = props.data.precio_sin_iva;
    if (props.data.bodega === registroSeleccionadoBodega) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%; width: 271px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = precio_sin_iva;
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
      div.textContent = precio_sin_iva;
      return this.createElementJaivana(div);
    }
  }

  setBottomEstado(props) {
    let registroSeleccionadoBodega = '';
    if (this.getField('codigo_bodega').getValue() !== '' && this.getField('codigo_bodega').getValue() !== ' ') {
      registroSeleccionadoBodega = this.getField('codigo_bodega').getValue();
    }
    if (props.data.bodega === registroSeleccionadoBodega) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%; width: 97px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = 'Seleccionado';
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
      div.textContent = '.';
      return this.createElementJaivana(div);
    }
  }

  validarArrayPorBodegas() {
    this.bodegasArray = [];
    if (this.nuevoArray.length >= 1) {
      if (this.nuevoArray.length >= 1) {
        let registroSeleccionadoBodega = '';
        if (this.getField('codigo_bodega').getValue() !== '' && this.getField('codigo_bodega').getValue() !== ' ' && this.estadoBodegasArray === true) {
          this.alertGeneral.toggle(false, `Selecciona algún registro por código bodega`, "warning");
          registroSeleccionadoBodega = this.getField('codigo_bodega').getValue();
          for (let index = 0; index < this.nuevoArray.length; index++) {
            if (this.nuevoArray[index].bodega === registroSeleccionadoBodega) {
              // console.log('Este es el array this.nuevoArray: ',this.nuevoArray);
              this.bodegasArray.push(this.nuevoArray[index]);
              // console.log('Este es el array this.bodegasArray: ',this.bodegasArray);
            }
          }
        } else {
          this.alertGeneral.toggle(true, `Selecciona algún registro por código bodega`, "warning");
        }
      }
      if (this.bodegasArray.length >= 1) {
        let mensaje='Desea grabar el pedido con estas fechas? => '
        +'Fecha de Vencimiento : '+this.getField('fecha_vencimiento').getValue()
        +'. Fecha de Entrega : '+this.getField('fecha_entrega').getValue()
        +'. Fecha de Vigencia : '+this.getField('fecha_validez').getValue()+'.';
        this.getField('confirmModalCustom').setTitleAndContent("Mensajes", mensaje);
        this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            this.getField('confirmModalCustom').toggle(false);
            let errores=0;
            this.CloseModalProductos();//funcion de aceptar
            if(!this.getField("observaciones").valid()){
              errores++;
              this.mostrarMensajeAdicional('El campo observaciones no tiene un valor válido.');
            }else if(!this.getField("subtotal").valid()){
              errores++;
              this.mostrarMensajeAdicional('El campo subtotal no tiene un valor válido.');
            } else if(!this.getField("valor_pedido").valid()){
              errores++;
              this.mostrarMensajeAdicional('El campo valor_pedido no tiene un valor válido.');
            }else if(!this.getField("numero_de_item").valid()){
              errores++;
              this.mostrarMensajeAdicional('El campo numero_de_item no tiene un valor válido.');
            } else if(!this.getField("clase_de_pedido").valid()){
              errores++;
              this.mostrarMensajeAdicional('El campo clase_de_pedido no tiene un valor válido.');
            } else if(!this.getField("orden_de_compra").valid()){
              errores++;
              this.mostrarMensajeAdicional('El campo orden_de_compra no tiene un valor válido.');
            }

            if (errores===0) {
              //pedir correo
              this.opcion = 'bodega';
              this.getField('modal_imprimir').handleClickOpen();
            }
        });
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField("confirmModalCustom").setButtonCancel(`Cancelar`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      } else {
        this.estadoBodegasArray = false;
      }
    }else{
      this.mostrarMensajeAdicional('No hay datos a grabar.');
    }
  }

  grabarPorBodega() {
    if (this.bodegasArray.length >= 1) {
      //Si se llama a esta funcion, se preparan los array para ser enviado al servicio que crea finalmente el pedido
      this.itemEnEdicion = '';
      if (this.getField("observaciones").valid() && this.getField("subtotal").valid() && this.getField("valor_pedido").valid() &&
        this.getField("numero_de_item").valid() && this.getField("clase_de_pedido").valid() && this.getField("orden_de_compra").valid()) {
        //si hay valores en este campo, se crean el Json del servicio
        this.getField("btn_grabar_por_bodega").setDisabled(true);
        this.mostrarMensajeGuardando();
        let objetoDatos = {
          nit: this.getField("nit").getValue(),
          sede: this.getField("sede").getValue(),
          nombre: this.getField("nombre_cliente").getValue(),
          direccion: this.getField("direccion").getValue(),
          codigo_ciudad: this.getField("codigo_ciudad").getValue(),
          valor: this.getField("valor_pedido").getValue(),
          codigo_vendedor: this.getField("codigo_vendedor").getValue(),
          estado: "Z",
          observaciones: this.getField("observaciones").getValue(),
          vence: this.getField("fecha_vencimiento").getValue(),
          vigencia: this.getField("fecha_validez").getValue(),
          lista_precios: this.getField("precios").getValue(),
          codigo_facturacion: this.getField("codigo_facturacion").getValue(),
          fdespacho: this.getField("fecha_entrega").getValue(),
          sucursal_origen: this.getField("sucursal_origen").getValue(),
          sucursal_cartera: this.getField("sucursal_cartera").getValue(),
          fecha_entrega: this.getField("fecha_entrega").getValue(),
          direccion_entrega: this.getField("direccion").getValue(),
          clase_pedido: this.getField("clase_de_pedido").getValue(),
          orden_compra: this.getField("orden_de_compra").getValue(),
          tasa_cambio: this.getField("tasa_cambio").getValue(),
        };
        //this.datosArray.push(objetoDatos);
        let datos = {
          datos: {
            //se envia el objeto con los datos principales del pedido y el array con los datos de los items agregados
            pedido: objetoDatos,
            items: this.bodegasArray,
            email_imprimir: this.email_imprimir,
            sucursal_ingreso: this.sucursal_ingreso,
          }
        };

        this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'crear', operacion_tipo: 'crear' };
        this.service.send(
          {
            endpoint: this.constant.formConfiguration(),
            method: 'POST',
            body: datos,
            success: this.successGrabarPorBodega,
            error: this.error_,
            general: this.generalFormatPmv
          });
      }
    }
  }//si el pedido es creado con exito, se limpian todos los campos

  successGrabarPorBodega(data) {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('btn_grabar_por_bodega').setDisabled(false);
    
    if (data.estado_p === 200) {
      this.estadoBodegasArray = false;
      for (let index = 0; index < this.bodegasArray.length; index++) {
        //let indexItem = this.nuevoArray.indexOf(this.bodegasArray[index]);
        //this.nuevoArray.splice(indexItem, 1);
        this.nuevoArray.pop(this.bodegasArray[index]);
      }
      this.CloseModalProductos();//funcion de aceptar
      if (this.nuevoArray.length >= 1) {
        this.openModalGrabarPorBodega();
      } else {
        this.getField('modal_grabar_por_bodega').handleClose();
        this.getField('tabla_modal_grabar_bodega').toggle(false);
        this.getField('codigo_bodega').setOptions(this.opciones);
        this.getField('total_cantidad_preciosiniva').setValue('');
        /* this.clearCampos();
        this.clearCamposProducto();
        this.getField("nit").setValue("");
        this.getField("sede").setValue("");
        this.getField("nombre_cliente").setValue("");
        this.getField("observaciones").setValue(".");
        this.getField("subtotal").setValue("");
        this.getField("valor_iva_final").setValue("");
        this.getField("valor_pedido").setValue("");
        this.getField("numero_de_item").setValue("");
        this.getField("clase_de_pedido").setValue("");
        this.getField("orden_de_compra").setValue(".");
        this.getField("orden_cotizacion").setValue("");
        this.gridOptionsBodegasItem["rowData"] = []; //se limpia la tabla
        this.getField("tabla_bodega").toggle(false); //se oculta la tabla
        this.nuevoArray = []; //y se limpa el array principal para volver a ser cargados con un pedido nuevo
        let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
        this.getField("direccion").setOptions(opciones);
        let fechaLocal = new Date();
        let diasVigencia = 30;
        this.setDiasVigencia(fechaLocal, diasVigencia);
        this.getField("confirmModalCustom").setTitleAndContent("Cotización Mercancías",data.data.mensaje); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
        this.getField("confirmModalCustom").setClickDialog(this.confirmCloseAlertModal);
        this.getField("confirmModalCustom").toggle(true);
        this.getField("confirmModalCustom").setVisibleCancel(true); */
      }
      this.getField("confirmModalCustom").setTitleAndContent("Cotización Mercancías", data.data.mensaje);
      //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
      this.getField("confirmModalCustom").setClickDialog(this.confirmCloseAlertModal);
      this.getField("confirmModalCustom").toggle(true);
      this.getField("confirmModalCustom").setButtonConfirm("ACEPTAR");
      this.getField("confirmModalCustom").setVisibleCancel(true);
    }
  }

  habilitarBtnGrabarPorBodega() {
    if (this.nuevoArray.length >= 1) {
      // console.log('El arreglo está lleno: ', this.nuevoArray);
      // this.getField('btn_producto_bodega').setDisabled(false);
    } else {
      // console.log('El arreglo está vacío: ', this.nuevoArray);
      // this.getField('btn_producto_bodega').setDisabled(true);
    }
  }

  llamarModalListar() {
    if (this.getField('codigo_grupo_producto').getValue() !== '')
      this.estadoModalListar = false;
  }

  closeModalListar() {
    if (this.estadoModalListar === true && this.idProducto !== '' && this.idProducto !== ' ') {
      this.openModalProducto();
      this.getField('codigo_id_producto').setValue(this.idProducto);
      //this.loadPreciosCliente();
    }
  }

  tablaCodigoProducto() {
    this.estadoRefrescarTbCodProd = false;
    this.productoModalListar = this.getField('producto_modal_cod_producto').getValue();
    this.campo1ModalListar = this.getField('campo1').getValue();
    this.campo2ModalListar = this.getField('campo2').getValue();
    if (this.getField('producto_modal_cod_producto').getValue() !== '' &&
    this.getField('producto_modal_cod_producto').getValue() !== ' ' && this.getField('producto_modal_cod_producto').valid()) {
      this.getField('producto_modal_cod_producto').setError(false, "");
      let campoProducto = this.getField('producto_modal_cod_producto').getValue();
      let campo1 = this.getField('campo1').getValue();
      let campo2 = this.getField('campo2').getValue();
      let unionCampos = `${campoProducto},${campo1},${campo2}`;
      let splitCampos = unionCampos.replace(',,', ',');// Esta línea la colocamos para que, cuando la cadena que contiene la variable "unionCampos" no se llene uno de los dos campos y quede con ",," se reemplaza por una sola ","
      let bodegas = this.bodegaIngreso.split(',');
      if (bodegas.length <= 10) {
        this.getField('buscar_productos').setDisabled(true);
        let datos = {
          datos: {
            nombres: splitCampos,
            bodegas: this.bodegaIngreso,
          }
        };
        this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'traer_producto_bodegas_autorizadas_facturacion', operacion_tipo: 'consulta' };
        this.service.send(
          {
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successTablaCodigoProducto,
            error: this.error_,
            general: this.generalFormatPmv
          });
      } else {
        this.getField('buscar_productos').setDisabled(true);
        this.alertGeneral.toggle(true, `Bodegas configuradas ${bodegas.length}, sólo se permiten seleccionar las primeras 10 columnas`, "warning");

        let datos = {
          datos: {
            nombres: splitCampos,
            bodegas: this.bodegaIngreso,
          }
        };
        this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'traer_producto_bodegas_autorizadas_facturacion', operacion_tipo: 'consulta' };
        this.service.send(
          {
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successTablaCodigoProducto,
            error: this.error_,
            general: this.generalFormatPmv
          });
      }
    } else {
      this.getField('producto_modal_cod_producto').setError(true, "* Este campo es requerido.");
    }
  }

  successTablaCodigoProducto(data) {
    this.estadoTablaProductosBodegas = [];
    this.getField('buscar_productos').setDisabled(false);
    if (data.estado_p === 200 && data.data.length >= 1) {
      if (this.estadoTablaProductosBodegas) {
        // console.log('Cargando variable');
        this.estadoTablaProductosBodegas = data;
      }
      // console.log('estadoTablaProductosBodegas: ', this.estadoTablaProductosBodegas)
      // console.log('data: ', data)
      this.getField('tb_codigo_producto').toggle(false);
      let configCell = new Map();
      this.gridOptionsProductos['rowData'] = this.estadoTablaProductosBodegas.data;
      let numberColums = data.data[0];
      let numberColumsArray = Object.keys(numberColums);
      let bodegasPrint = (numberColumsArray.length - 3);
      // console.log('bodegasPrint: ',bodegasPrint);
      let colum1 = numberColumsArray[3];
      let colum2 = numberColumsArray[4];
      let colum3 = numberColumsArray[5];
      let colum4 = numberColumsArray[6];
      let colum5 = numberColumsArray[7];
      let colum6 = numberColumsArray[8];
      let colum7 = numberColumsArray[9];
      let colum8 = numberColumsArray[10];
      let colum9 = numberColumsArray[11];
      let colum10 = numberColumsArray[12];
      // console.log('colum1: ',colum1);
      if (bodegasPrint === 1) configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 520 });
      if (bodegasPrint === 2) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 280 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 280 });
      }
      if (bodegasPrint === 3) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 190 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 190 });
        configCell.set(colum3, { cellRenderer: this.setButtonBodega3, field: colum3, width: 190 });
      }
      if (bodegasPrint === 4) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 180 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 180 });
        configCell.set(colum3, { cellRenderer: this.setButtonBodega3, field: colum3, width: 180 });
        configCell.set(colum4, { cellRenderer: this.setButtonBodega4, field: colum4, width: 180 });
      }
      if (bodegasPrint === 5) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 170 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 170 });
        configCell.set(colum3, { cellRenderer: this.setButtonBodega3, field: colum3, width: 170 });
        configCell.set(colum4, { cellRenderer: this.setButtonBodega4, field: colum4, width: 170 });
        configCell.set(colum5, { cellRenderer: this.setButtonBodega5, field: colum5, width: 170 });
      }
      if (bodegasPrint === 6) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 160 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 160 });
        configCell.set(colum3, { cellRenderer: this.setButtonBodega3, field: colum3, width: 160 });
        configCell.set(colum4, { cellRenderer: this.setButtonBodega4, field: colum4, width: 160 });
        configCell.set(colum5, { cellRenderer: this.setButtonBodega5, field: colum5, width: 160 });
        configCell.set(colum6, { cellRenderer: this.setButtonBodega6, field: colum6, width: 160 });
      }
      if (bodegasPrint === 7) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 150 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 150 });
        configCell.set(colum3, { cellRenderer: this.setButtonBodega3, field: colum3, width: 150 });
        configCell.set(colum4, { cellRenderer: this.setButtonBodega4, field: colum4, width: 150 });
        configCell.set(colum5, { cellRenderer: this.setButtonBodega5, field: colum5, width: 150 });
        configCell.set(colum6, { cellRenderer: this.setButtonBodega6, field: colum6, width: 150 });
        configCell.set(colum7, { cellRenderer: this.setButtonBodega7, field: colum7, width: 150 });
      }
      if (bodegasPrint === 8) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 140 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 140 });
        configCell.set(colum3, { cellRenderer: this.setButtonBodega3, field: colum3, width: 140 });
        configCell.set(colum4, { cellRenderer: this.setButtonBodega4, field: colum4, width: 140 });
        configCell.set(colum5, { cellRenderer: this.setButtonBodega5, field: colum5, width: 140 });
        configCell.set(colum6, { cellRenderer: this.setButtonBodega6, field: colum6, width: 140 });
        configCell.set(colum7, { cellRenderer: this.setButtonBodega7, field: colum7, width: 140 });
        configCell.set(colum8, { cellRenderer: this.setButtonBodega8, field: colum8, width: 140 });
      }
      if (bodegasPrint === 9) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 130 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 130 });
        configCell.set(colum3, { cellRenderer: this.setButtonBodega3, field: colum3, width: 130 });
        configCell.set(colum4, { cellRenderer: this.setButtonBodega4, field: colum4, width: 130 });
        configCell.set(colum5, { cellRenderer: this.setButtonBodega5, field: colum5, width: 130 });
        configCell.set(colum6, { cellRenderer: this.setButtonBodega6, field: colum6, width: 130 });
        configCell.set(colum7, { cellRenderer: this.setButtonBodega7, field: colum7, width: 130 });
        configCell.set(colum8, { cellRenderer: this.setButtonBodega8, field: colum8, width: 130 });
        configCell.set(colum9, { cellRenderer: this.setButtonBodega9, field: colum9, width: 130 });
      }
      if (bodegasPrint >= 10) {
        configCell.set(colum1, { cellRenderer: this.setButtonBodega1, field: colum1, width: 120 });
        configCell.set(colum2, { cellRenderer: this.setButtonBodega2, field: colum2, width: 120 });
        configCell.set(colum3, { cellRenderer: this.setButtonBodega3, field: colum3, width: 120 });
        configCell.set(colum4, { cellRenderer: this.setButtonBodega4, field: colum4, width: 120 });
        configCell.set(colum5, { cellRenderer: this.setButtonBodega5, field: colum5, width: 120 });
        configCell.set(colum6, { cellRenderer: this.setButtonBodega6, field: colum6, width: 120 });
        configCell.set(colum7, { cellRenderer: this.setButtonBodega7, field: colum7, width: 120 });
        configCell.set(colum8, { cellRenderer: this.setButtonBodega8, field: colum8, width: 120 });
        configCell.set(colum9, { cellRenderer: this.setButtonBodega9, field: colum9, width: 120 });
        configCell.set(colum10, { cellRenderer: this.setButtonBodega10, field: colum10, width: 120 });
      }
      configCell.set('nombre', { cellRenderer: this.setButtonNombre, field: 'nombre', width: 555 });
      configCell.set('codigo', { cellRenderer: this.setButtonCodigo, field: 'codigo', width: 200 });
      configCell.set('bodegas', { cellRenderer: this.setButtonBodegas, field: '', width: 10 });
      /* if (this.registroSeleccionado !== '') {
        let seleccionado = this.registroSeleccionado;
        this.gridOptionsProductos.api.forEachNode(function (node) {
          node.setSelected(node.id === seleccionado);
        });
      }  */
      this.getField('tb_codigo_producto').initData(this.gridOptionsProductos, configCell);
      this.getField('tb_codigo_producto').toggle(true);
    } else {
      this.alertGeneral.toggle(false, ``, "warning");
      this.getField('tb_codigo_producto').toggle(false);
    }
  }

  setButtonBodegas(props) {
    // let bodegas = props.data.bodegas;
    if (props.data.codigo === this.registroSeleccionado) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;width: 10px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = '-';
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;");
      div.textContent = '-';
      return this.createElementJaivana(div);
    }
  }
  setButtonCodigo(props) {
    let codigo = props.data.codigo;
    if (props.data.codigo === this.registroSeleccionado) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;width: 200px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = codigo;
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
      div.textContent = codigo;
      return this.createElementJaivana(div);
    }
  }

  setButtonNombre(props) {
    let nombre = props.data.nombre;
    if (props.data.codigo === this.registroSeleccionado) {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%; width: 555px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
      div.textContent = nombre;
      return this.createElementJaivana(div);
    } else {
      let div = document.createElement('div');
      div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
      div.textContent = nombre;
      return this.createElementJaivana(div);
    }
  }

  setButtonBodega10(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);// los que hace Object.keys es devuelver un arreglo con todos los nombres de propiedades enumerables ("claves") propias (no en la cadena de prototipos) de un objeto 
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);// Object.values()  devuelve un array con los valores correspondientes a las propiedades enumerables de un objeto. Las propiedades son devueltas en el mismo orden a como lo haría un bucle for...in (la única diferencia es que un bucle for-in también enumera las propiedades en la cadena de prototipo de un objeto).
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable saldo: ', saldo);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }
  setButtonBodega9(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable saldo: ', saldo);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }
  setButtonBodega8(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable saldo: ', saldo);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }
  setButtonBodega7(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable saldo: ', saldo);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }
  setButtonBodega6(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable saldo: ', saldo);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }
  setButtonBodega5(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable saldo: ', saldo);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }
  setButtonBodega4(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable saldo: ', saldo);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }
  setButtonBodega3(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable saldo: ', saldo);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }

  setButtonBodega2(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable evento setButtonBodega2: ', evento);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }

  setButtonBodega1(props) {
    let saldoObjeto = props.data;
    let evento = props.column.colId;
    let saldoArrayPropiedades = Object.keys(saldoObjeto);
    let posicionArray = saldoArrayPropiedades.indexOf(evento);
    let saldoArrayValores = Object.values(saldoObjeto);
    let saldo = saldoArrayValores[posicionArray];
    // console.log('Esta es la variable evento setButtonBodega1: ', evento);
    if (saldo !== 0) {
      let button = document.createElement("input");
      button.onclick = () => this.confirmarItemsModalProducto(props, evento);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("buttonStyle", "width:60px; height: 20px%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
      button.setAttribute("type", "button");
      button.setAttribute("value", saldo);
      return this.createElementJaivana(button);
    } else if (saldo === 0) {
      let div = document.createElement('div');
      div.setAttribute("style", "heigth: 100%;padding-left: 45%;padding-top: 0%;heigth: 100%;padding-left: 45%;padding-top: 0%;font-size: 0.875rem;");
      div.textContent = saldo;
      return this.createElementJaivana(div);
    }
  }

  /**
    * @param {Este parámetro contiente toda la información del registro seleccionado en la tabla, que nos va a ser de ayuda para setear los campos requeridos al momento de que el usuario haga click sobre cualquier botón renderizado en la tabla} props 
    * @param {Este parámetro va a contener la columna ID del botón que el usuario le halla dado click, ejemplo: colId: "precio3"} evento 
    */
  confirmarItemsModalProducto(props, evento) {
    // console.log('Estas son las props:', props);
    // console.log('Este es el evento: ', evento);
    this.estadoModalListar = true;
    this.getField('codigo_producto').setValue(props.data.codigo);
    this.getField('nombre_producto').setValue(props.data.nombre);
    let bodega = evento.split('saldo');
    this.setBodega = bodega[1];
    this.getField('codigo_bodega_item').setValue(bodega[1]);
    //this.getField('codigo_id_producto').setValue(props.data.codigo);
    this.idProducto = props.data.codigo;
    this.registroSeleccionado = props.data.codigo;
    this.getField('modal_codigo_producto').handleClose();
    this.closeModalListar();
  }

  modalCodigoProducto() {
    if (this.getField('sede').getValue() !== '' && this.getField('nit').getValue() !== '' &&
      this.getField('nombre_cliente').getValue() !== '' && this.getField('sucursal_origen').getValue() !== ''
      && this.getField('sucursal_cartera').getValue() !== '') {
      let datosFicha = [];
      let data2 = {
        "data": [
          {
            "nit": this.getField('nit').getValue(),
            "sede": this.getField('sede').getValue(),
            "nombre": this.getField('nombre_cliente').getValue(),
          }
        ]
      };
      this.getField('lista_terceros_nit').setVisible(true);
      datosFicha.push(data2.data[0]);
      this.getField('lista_terceros_nit').setItemsFichas(datosFicha);
      this.getField('modal_codigo_producto').handleClickOpen();
      this.getField('producto_modal_cod_producto').setError(false, "");
      this.getField('producto_modal_cod_producto').setValue(this.productoModalListar);
      this.getField('campo1').setValue(this.campo1ModalListar);
      this.getField('campo2').setValue(this.campo2ModalListar);
      this.successTablaCodigoProducto(this.estadoTablaProductosBodegas);
    } else {
      this.getField('sede').setError(true, "* Este campo es requerido.");
      this.getField('nit').setError(true, "* Este campo es requerido.");
      this.getField('nombre_cliente').setError(true, "* Este campo es requerido.");
      this.getField('lista_terceros_nit').setVisible(false);
    }
  }

  onSelectionChangedItems() { }

  cerrarEdicionModal() {
    if (this.btnCerrarModal === false) {
      if (this.estadoModalListar === true && this.itemEnEdicion === '') {
        this.getField('modal_codigo_producto').handleClickOpen();
        this.getField('producto_modal_cod_producto').setError(false, "");
        this.getField('producto_modal_cod_producto').setValue(this.productoModalListar);
        this.getField('campo1').setValue(this.campo1ModalListar);
        this.getField('campo2').setValue(this.campo2ModalListar);
        this.successTablaCodigoProducto(this.estadoTablaProductosBodegas);

        //this.CloseModalProductos();
        this.btnCerrarModal = true;
        //this.productoModalListar = '';
        //this.campo1ModalListar = '';
        //this.campo2ModalListar = '';
        //this.estadoTablaProductosBodegas = [];
        this.registroSeleccionado = '';
        this.crearPedido();
        this.getField("modal_producto").handleClose();
      } else {
        this.crearPedido();
      }
    } else {
      this.estadoModalListar = false;
    }
    this.btnCerrarModal = false;
  }

  retornaBodegasPorCodigoFacturacion() {
    if (this.getField("codigo_facturacion").getValue() !== '' && this.getField("codigo_facturacion").getValue() !== ' ') {
      let datos = {
        datos: {
          value: this.getField("codigo_facturacion").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'bodegas_porcodigofacturacion', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successBodegasCodFac,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false
        });
    }
  }
  successBodegasCodFac(data) {
    if (data.data.length >= 1) {
      let opciones = [{ text: "Ninguno", value: " ", campos_cambian: { nombre_bodega: '' } }];
      data.data.forEach((item) => {
        let dataOp = {};
        dataOp["value"] = item.codigo_bodega;
        dataOp["text"] = item.codigo_bodega /*+ " - " + item.nciudad*/;
        dataOp["campos_cambian"] = { nombre_bodega: item.nombre, };
        opciones.push(dataOp);
      });
      this.getField("codigo_bodega_item").setOptions(opciones);
      if (this.bodegaIngreso !== '' && this.bodegaSeleccionada === '') {
        let bodega = [];
        if (this.setBodega === '') {
          bodega = this.bodegaIngreso.split(",");
          this.setBodega = bodega[0];
          this.getField("codigo_bodega_item").setValue(this.setBodega);
          data.data.forEach((item) => {
            if (item.codigo_bodega === bodega[0]) {
              this.getField("nombre_bodega").setValue(item.nombre);
              this.getField('nombre_bodega').setError(false, '');
            } else if (this.getField('nombre_bodega').getValue() === '') {
              this.getField('nombre_bodega').setError(true, 'Vuelve a seleccionar un código bodega');
            }
          });
        } else if (this.setBodega !== '') {
          this.getField("codigo_bodega_item").setValue(this.setBodega);
          data.data.forEach((item) => {
            if (item.codigo_bodega === this.setBodega) {
              this.getField("nombre_bodega").setValue(item.nombre);
              this.getField('nombre_bodega').setError(false, '');
            } else if (this.getField('nombre_bodega').getValue() === '') {
              this.getField('nombre_bodega').setError(true, 'Vuelve a seleccionar un código bodega');
            }
          });
        }
      } else if (this.bodegaSeleccionada !== '') {
        let setBodegaSelecionada = this.bodegaSeleccionada.split(",");
        this.getField("codigo_bodega_item").setValue(setBodegaSelecionada[0]);
        data.data.forEach((item) => {
          if (item.codigo_bodega === setBodegaSelecionada[0]) {
            this.getField("nombre_bodega").setValue(item.nombre);
          }
        });
      }
    } else {
      let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
      this.getField("codigo_bodega_item").setOptions(opciones);
      this.getField("nombre_bodega").setValue('');
    }
    this.getField("modal_producto").handleClickOpen();// Se abre la modal de "Producto" acá, porque uno de los requerimientos es que se debe abrir despues de que el campo "Código bodega" estuviera seteado
    this.getField("valor_total_item").setError(false, '');
    this.getField('codigo_producto').setError(false, "");
    this.getField('precio_lista').setError(false, "");
    this.getField('descuento_item').setError(false, "");
    this.getField('cantidad_item_520').setError(false, "");
    this.getField('detalle_item').setError(false, "");
    this.getField('tarifa_iva_item').setError(false, "");

  }

  conservaBodegaSeleccionada() {
    if (this.getField("codigo_bodega_item").getValue().toString().trim() !== '') {
      this.bodegaSeleccionada = this.getField("codigo_bodega_item").getValue();
    }
  }

  validarDescuentoMaximo() {
    if (this.getField("nit").valid() && this.getField("sede").valid() && this.getField("descuento_item").valid() &&
      this.getField("codigo_producto").valid() && this.getField("precio_lista").valid()) {
      let datos = {
        datos: {
          nit: this.getField("nit").getValue(),
          sede: this.getField("sede").getValue(),
          descuento: this.getField("descuento_item").getValue(),
          codigo: this.getField("codigo_producto").getValue(),
          // precios: this.getField("precio_lista").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'validardescuentomaximopedidos', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successValidarDescuentoMax,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false// Se deja showMessage en false ya que se va a manejar la alerta de forma manual, porque en la tarea "JBAS-2962" solicitaron que no se debe mostrar el valor del descuento máximo.
        });
    }
  }

  successValidarDescuentoMax(data) {
    if (data.data.ver_descuento_maximo === 'S') {
      if (data.estado_p !== 200) {
        // this.getField("confirmModalCustom").setTitleAndContent("Descuento Máximo Permitido",`${data.data.descuento_maximo}%`); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
        this.getField("confirmModalCustom").setTitleAndContent("Advertencia", `${data.data.mensaje}`); //En la tarea "JBAS-2962" solicitaron ocultar el valor del mensaje de descuento máximo y sólo mostrar el mensaje de "Descuento no autorizado"
        this.getField("confirmModalCustom").setClickDialog(this.confirmCloseAlertModal);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").toggle(true);
        this.getField("confirmModalCustom").setVisibleCancel(true);
        this.alertGeneral.toggle(true, data.data.mensaje, "error");
      } else if (data.estado_p === 200 && data.data.mensaje === 'Descuento autorizado.') {
        this.alertGeneral.toggle(true, data.data.mensaje, "success");
      }
    } else { //if(data.data.ver_descuento_maximo === 'N')
      this.service.alertError.toggle(false);
      //this.alertGeneral.toggle(false);
    }
  }
  confirmCloseAlertModal() {
    this.arrayAuxiliarCodigoProducto = [];
    this.gridOptionsModalCodigoProducto['rowData'] = [];
    this.getField('tb_modal_codigo_producto').initData(this.gridOptionsModalCodigoProducto);
    this.getField("confirmModalCustom").toggle(false);
  }

  setDiasVigencia(fecha, dias) {
    let fechaVigencia = new Date();
    fechaVigencia.setDate(fecha.getDate() + dias);
    let vigencia = fechaVigencia.toISOString();
    this.getField("fecha_vencimiento").setValue(vigencia.split("T")[0]);
    this.getField("fecha_validez").setValue(vigencia.split("T")[0]);
  }

  cerrarModalContactos() {
    this.getField("modal_contactos").handleClose();
    this.getField("modal_nuevo_nit").handleClose();
  }

  setSucursalesProspectos(data) {
    if (data.length >= 1) {
      this.getField("sucursal_cartera").setValue(data[0].sucursal_cartera);
      this.getField("sucursal_origen").setValue(data[0].sucursal_origen);
    } else {
      this.alertGeneral.toggle(true, "No existen sucursales cartera y origen configuradas", "error");
    }
  }

  setPrecioListaProspectos(data) {
    if (data.length >= 1) {
      this.getField("precios").setValue(data[0].lista_precios_pendientes);
    } else {
      this.alertGeneral.toggle(true, "No existe una lista de precios configurada", "error");
    }
  }

  setTipoNegocioProspecto(data) {
    if (data.length >= 1) {
      this.TipoNegociosProspecto = data[0].codigo_negocio;
    } else {
      this.alertGeneral.toggle(true, "No existe un código de Negocio Válido", "error");
      this.TipoNegociosProspecto = "";
    }
  }

  abrirModalContactos() {
    this.idEliminarContacto = 0;
    this.getField("telefono_contactos").setValue("");
    this.getField("email_contactos").setValue("");
    this.getField("observacion_contactos").setValue("");
    this.getField("nombre_contacto").setValue("");

    if (this.getField("nit").valid() && this.getField("sede").valid() && this.getField("nombre_cliente").valid()) {
      this.getField("modal_contactos").handleClickOpen();
      this.getField('nombre_contacto').setError(false, "");
      this.getField('telefono_contactos').setError(false, "");
      this.getField('email_contactos').setError(false, "");
      this.getField('observacion_contactos').setError(false, "");
      //520_terceros_contactos__nitsede
      let datos = {
        datos: {
          nit: this.getField("nit").getValue(),
          sede: parseInt(this.getField("sede").getValue()),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-terceroscontactos', operacion: '1_traernitsede', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successAbrirModalContactos,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false,
        });
    }
  }

  successAbrirModalContactos(data) {
    this.getField("nit_contactos").setValue(this.getField("nit").getValue());
    this.getField("sede_contactos").setValue(this.getField("sede").getValue());
    this.getField("nombre_contactos").setValue(this.getField("nombre_cliente").getValue());
    if (data.estado_p === 200) {
      if (data.data.length >= 1) {
        this.gridOptionsContactos["rowData"] = data.data;
        let configCell = new Map();
        configCell.set("eliminar", { cellRenderer: this.setButtonEliminarContacto, field: "eliminar", width: 100 });
        this.getField("tabla_contactos").initData(this.gridOptionsContactos, configCell);
      } else {
        this.getField("tabla_contactos").toggle(false);
      }
    } else {
      this.getField("tabla_contactos").toggle(false);
    }
  }

  setButtonEliminarContacto(props) {
    let button = document.createElement("input");
    button.onclick = () => this.eliminarContactosConfirmar(props.data.id);
    button.setAttribute("id", "button_aprove_");
    button.setAttribute("class", "buttonStyle"); //,"width:100%; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
    button.setAttribute("type", "button");
    button.setAttribute("value", "Eliminar");
    return this.createElementJaivana(button);
  }

  eliminarContactosConfirmar(id) {
    this.idEliminarContacto = id;
    this.getField("confirmModalCustom").setTitleAndContent("Eliminar", "Desea realizar esta operación?"); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
    this.getField("confirmModalCustom").setClickDialog(this.confirmDeleteContactoEliminar);
    this.getField("confirmModalCustom").toggle(true);
    this.getField("confirmModalCustom").setVisibleCancel(false);
  }

  confirmDeleteContactoEliminar() {
    this.getField("confirmModalCustom").toggle(false);
    if (this.idEliminarContacto >= 1) {
      let datos = {
        datos: {
          terceros_contactos_id: this.idEliminarContacto,
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-terceroscontactos', operacion: '7', operacion_tipo: 'eliminar' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'DELETE',
          body: datos,
          success: this.abrirModalContactos,
          error: this.error_,
          general: this.generalFormatPmv
        });
    }
  }
  agregarNuevoPendiente() {
    //520_terceros_pendientes_agregar
    if (this.getField("documento_nuevo").valid() && this.getField("verifica").valid() && this.getField("tipo_documentos_id").valid() &&
      this.getField("nom1").valid() && this.getField("nom2").valid() && this.getField("ape1").valid() &&
      this.getField("ape2").valid() && this.getField("nombre_cliente_nuevo").valid() && this.getField("direccion_nuevo").valid() &&
      this.getField("telefonos").valid() && this.getField("pais_id").valid() && this.getField("departamento_geografico_id").valid() &&
      this.getField("ciudad_id").valid() && this.getField("negocios_id").valid() && this.getField("calidad_agente_retenedor_ventas_id").valid() &&
      this.getField("tipo_persona").valid() && this.getField("email").valid() && this.getField("codigo_vendedor_prospecto").valid() &&
      this.getField("nombre_vendedor_prospecto").valid() && this.getField("fecha_nacimiento").valid() && this.getField("sexo").valid()) {
      if (this.getField('nombre_tipos_documentos').getValue() === 'NIT') {
        this.getField("agregar_nuevo").setDisabled(true);
        let datos = {
          datos: {
            nit: this.getField("documento_nuevo").getValue(),
            verifica: this.getField("verifica").getValue(),
            tipo_documentos_id: this.getField("tipo_documentos_id").getValue(),
            nombre: this.getField("nombre_cliente_nuevo").getValue(),
            ape1: this.getField("ape1").getValue(),
            ape2: this.getField("ape2").getValue(),
            nom1: this.getField("nom1").getValue(),
            nom2: this.getField("nom2").getValue(),
            direccion: this.getField("direccion_nuevo").getValue(),
            telefonos: this.getField("telefonos").getValue(),
            ciudad_id: this.getField("ciudad_id").getValue(),
            negocios_id: this.getField("negocios_id").getValue(),
            vendedor_id: this.getField("vendedor_id").getValue(),
            tipo_persona: this.getField("tipo_persona").getValue(),
            calidad_agente_retenedor_ventas_id: this.getField("calidad_agente_retenedor_ventas_id").getValue(),
            email: this.getField("email").getValue(),
            fecha_nacimiento: this.getField("fecha_nacimiento").getValue(),
            sexo: this.getField("sexo").getValue(),
          }
        };
        //520_terceros_pendientes_agregar
        this.generalFormatPmv = { tipo_servicio: 'maes-tercerospendientes', operacion: 'crear', operacion_tipo: 'crear' };
        this.service.send(
          {
            endpoint: this.constant.formConfiguration(),
            method: 'POST',
            body: datos,
            success: this.successAgregarNuevoPendiente,
            error: this.error_,
            general: this.generalFormatPmv
          });
        //this.service.send(this.getConstant().formSave, 'POST', data  ,this.successGuardarCliente,this.error_);
      }
      if (this.getField('nombre_tipos_documentos').getValue() !== 'NIT') {
        if ((this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
          this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '') ||
          (this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
            this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
          (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
            this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
          (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
            this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '')) {
          this.getField("agregar_nuevo").setDisabled(true);
          let datos = {
            datos: {
              nit: this.getField("documento_nuevo").getValue(),
              verifica: this.getField("verifica").getValue(),
              tipo_documentos_id: this.getField("tipo_documentos_id").getValue(),
              nombre: this.getField("nombre_cliente_nuevo").getValue(),
              ape1: this.getField("ape1").getValue(),
              ape2: this.getField("ape2").getValue(),
              nom1: this.getField("nom1").getValue(),
              nom2: this.getField("nom2").getValue(),
              direccion: this.getField("direccion_nuevo").getValue(),
              telefonos: this.getField("telefonos").getValue(),
              ciudad_id: this.getField("ciudad_id").getValue(),
              negocios_id: this.getField("negocios_id").getValue(),
              vendedor_id: this.getField("vendedor_id").getValue(),
              tipo_persona: this.getField("tipo_persona").getValue(),
              calidad_agente_retenedor_ventas_id: this.getField("calidad_agente_retenedor_ventas_id").getValue(),
              email: this.getField("email").getValue(),
              fecha_nacimiento: this.getField("fecha_nacimiento").getValue(),
              sexo: this.getField("sexo").getValue(),
            }
          };
          //520_terceros_pendientes_agregar

          this.generalFormatPmv = { tipo_servicio: 'maes-tercerospendientes', operacion: 'crear', operacion_tipo: 'crear' };
          this.service.send(
            {
              endpoint: this.constant.formConfiguration(),
              method: 'POST',
              body: datos,
              success: this.successAgregarNuevoPendiente,
              error: this.error_,
              general: this.generalFormatPmv
            });

          //this.service.send(this.getConstant().formSave, 'POST', data  ,this.successGuardarCliente,this.error_);
        } else {
          this.getField('confirmModalCustom').setTitleAndContent('Error', `Mínimo debe ir un nombre con un apellido`)
          this.getField('confirmModalCustom').setClickDialog(() => {
            this.getField('confirmModalCustom').toggle(false);
            this.getField('ape1').setError(true, '* Este campo es requerido.');
            this.getField('ape2').setError(true, '* Este campo es requerido.');
          });
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
        }
      }
    }
  }

  successAgregarNuevoPendiente(data) {
    this.getField("agregar_nuevo").setDisabled(false);
    if (data.estado_p === 200) {
      this.nitDeProspectos = true; // Si esta variable es igual a "true" no se va a consumir el servicio: "pedidos-calcularfechaentrega"
      this.getField("modal_nuevo_nit").handleClose();
      this.buscarNitPendiente();
    }
  }

  calcularDV() {
    if (this.getField("documento_nuevo").getValue() !== "" && this.getField("documento_nuevo").getValue() !== " ") {
      this.getField("verifica").setValue("".calcularDigitoVerificacion(this.getField("documento_nuevo").getValue()));
    }
  }

  recuperarDepto() {
    this.getField('pais_id').getValue() !== '' ? this.getField('pais_id').setError(false, '') : this.getField('pais_id').valid();
    if (this.getField("pais_id").getValue() !== "" && this.getField("pais_id").getValue() !== " ") {
      /*if(localStorage.getItem('pmvLogin') && localStorage.getItem('sucursal_ingreso')){
        let datos = {
            datos: {
                value: this.getField('pais_id').getValue(),
            }
        };
        let generales = {"tipo_servicio": "ferr-departamentos_geograficos", "operacion": "pais_id", "operacion_tipo":"consulta" }

        let url = this.pmvUrl.split('/pmvlogin');
        let endpointPmv = `${url[0]}/pmv/api/protected`;
        this.service.send(
            {
                endpoint: endpointPmv,
                method: 'GET',
                body: datos,
                success:this.successRecuperarDepto,
                error: this.error_,
                general: generales,
                pmvRequest: true
            }
        );
      }else{*/
      let datos = {
        datos: {
          pais_id: Number(this.getField("pais_id").getValue()),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-dptosgeografico', operacion: 'paisid', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successRecuperarDepto,
          error: this.error_,
          general: this.generalFormatPmv
        });
      //}
    } else {
      this.getField('departamento_geografico_id').setOptions(this.opciones);
      this.getField('ciudad_id').setOptions(this.opciones);
    }
  }

  successRecuperarDepto(data) {
    if (data.estado_p === 200 && data.data.length >= 1) {
      let arrayFiltrar = [];
      let itemIncrement = 0;
      data.data.map((itemFiltrar) => {
        if (itemFiltrar.estadoactivo_id === 1) {
          arrayFiltrar.push(data.data[itemIncrement]);
        }
        itemIncrement += 1;
        return null;
      });
      let opciones = [{ text: 'Ninguno', value: '', 'campos_cambian': {} }];
      arrayFiltrar.forEach(item => {
        let dataOp = {}
        dataOp['value'] = `${item.departamentos_geograficos_id}`;
        dataOp['text'] = `${item.nombre}`;
        dataOp['campos_cambian'] = {};
        opciones.push(dataOp);
      })
      this.getField('departamento_geografico_id').setOptions(opciones);
      this.getField('departamento_geografico_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un pais que se habia seleccionado con anterioridad, no se setee el mismo departamento, sino que solo liste el select con los departamentos correspondientes al país seleccionado.
      this.getField('departamento_geografico_id').setError(false, '');
    } else {
      this.getField('departamento_geografico_id').setOptions(this.opciones);
    }
  }

  recuperarCiudades() {
    this.getField('departamento_geografico_id').getValue() !== '' ? this.getField('departamento_geografico_id').setError(false, '') : this.getField('departamento_geografico_id').valid();
    if (this.getField("departamento_geografico_id").getValue() !== "" && this.getField("departamento_geografico_id").getValue() !== " ") {
      let datos = {
        datos: {
          departamento_geografico_id: Number(this.getField("departamento_geografico_id").getValue()),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: 'deptoid', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successRecuperarCiudades,
          error: this.error_,
          general: this.generalFormatPmv
        });
    } else {
      this.getField('ciudad_id').setOptions(this.opciones);
    }
  }

  successRecuperarCiudades(data) {
    if (data.estado_p === 200 && data.data.length >= 1) {
      let arrayFiltrar = [];
      let itemIncrement = 0;
      data.data.map((itemFiltrar) => {
        if (itemFiltrar.estadoactivo_id === 1) {
          arrayFiltrar.push(data.data[itemIncrement]);
        }
        itemIncrement += 1;
        return null;
      });
      //console.log('Ciudades: ', arrayFiltrar); 
      let opciones = [{ text: 'Ninguno', value: '', 'campos_cambian': {} }];
      arrayFiltrar.forEach(item => {
        let dataOp = {}
        dataOp['value'] = `${item.ciudades_id}`;
        dataOp['text'] = `${item.nombre}`;
        dataOp['campos_cambian'] = {};
        opciones.push(dataOp);
      })
      this.getField('ciudad_id').setOptions(opciones);
      this.getField('ciudad_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un departamento que se habia seleccionado con anterioridad, no se setee la misma ciudad, sino que solo liste el select con las ciudades correspondientes al departmento seleccionado.
      this.getField('ciudad_id').setError(false, '');
    } else {
      this.getField('ciudad_id').setOptions(this.opciones);
    }
  }

  nitPendiente(data) {
    /*if(data.length < 1 ){
            this.getField('confirmModalCustom').setTitleAndContent('Buscar','Nit no encontrado, Desea buscarlo en Pendientes?');
            this.getField('confirmModalCustom').setClickDialog(this.buscarNitPendiente);//funcion que se ejecuta al confirmar eliminacion
            this.getField('confirmModalCustom').toggle(true);
        }*/
  }

  enterServicioTerceros = (e) => {
    let keycode = e.keyCode ? e.keyCode : e.which;
    switch (keycode) {
      case 120:
        if (this.getField("nit").getValue() !== "") {
          /*if(localStorage.getItem('pmvLogin') && localStorage.getItem('sucursal_ingreso')){
            let datos = {datos: {
              value: this.getField("nit").getValue(),
              estado: "A",
             }};
            let generales = {"tipo_servicio": "ferr-terceros", "operacion": "nombre", "operacion_tipo":"consulta" }

            let url = this.pmvUrl.split('/pmvlogin');
            let endpointPmv = `${url[0]}/pmv/api/protected`;
            this.service.send(
                {
                    endpoint: endpointPmv,
                    method: 'GET',
                    body: datos,
                    success:this.successTercerosF9,
                    error: this.error_,
                    general: generales,
                    pmvRequest: true
                }
            );
          }else{*/
          let datos = {
            datos: {
              value: this.getField("nit").getValue(),
            }
          };
          this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: '40_4', operacion_tipo: 'consulta' };
          this.service.send(
            {
              endpoint: this.constant.formConfiguration(),
              method: 'GET',
              body: datos,
              success: this.successTercerosF9,
              error: this.error_,
              general: this.generalFormatPmv
            });
          //}
        }
        break;
      case 13:
        if (
          this.getField("nit").getValue() !== "" &&
          this.getField("nit").getValue() > 0
        ) {
          /*if(localStorage.getItem('pmvLogin') && localStorage.getItem('sucursal_ingreso')){
            let datos = {datos: {
              nit: this.getField("nit").getValue(),
              estado: "A",
             }};
            let generales = {"tipo_servicio": "ferr-1", "operacion": "2_2", "operacion_tipo":"consulta" }

            let url = this.pmvUrl.split('/pmvlogin');
            let endpointPmv = `${url[0]}/pmv/api/protected`;
            this.service.send(
                {
                    endpoint: endpointPmv,
                    method: 'GET',
                    body: datos,
                    success:this.successTerceros,
                    error: this.error_,
                    general: generales,
                    pmvRequest: true
                }
            );
          }else{*/
          let datos = {
            datos: {
              value: this.getField("nit").getValue(),
            }
          };
          this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: '42_4', operacion_tipo: 'consulta' };
          this.service.send(
            {
              endpoint: this.constant.formConfiguration(),
              method: 'GET',
              body: datos,
              success: this.successTerceros,
              error: this.error_,
              general: this.generalFormatPmv
            });
          //}
        }
        break;
      default:
        break;
    }
  };

  successTercerosF9(data) {
    if (data.data.length > 1) {
      this.nitDeProspectos = false; //Si esta variable es igual a "false" quiere decir que no es un propecto, ahí si consume el servicio: 520-Servicio_cotizacion_mercancia_calcular_fecha_entrega = pedidos-calcularfechaentrega el comportamiento debe ser normal a como se viene trabajando
      this.getField("modal_terceros").handleClickOpen();
      this.gridOptionsTerceros["rowData"] = data.model;
      let configCell = new Map();
      this.getField("tabla_terceros").initData(this.gridOptionsTerceros, configCell);
    } else if (data.data.length === 1) {
      this.nitDeProspectos = false; //Si esta variable es igual a "false" quiere decir que no es un propecto, ahí si consume el servicio: 520-Servicio_cotizacion_mercancia_calcular_fecha_entrega = pedidos-calcularfechaentrega el comportamiento debe ser normal a como se viene trabajando
      this.getField("sede").setValue(data.model[0].sede);
      this.getField("nit").setValue(data.model[0].nit);
      this.getField("nombre_cliente").setValue(data.model[0].nombre);
    }
  }

  successTerceros(data) {
    if (data.data.length > 1) {
      this.nitDeProspectos = false; // Si esta variable es igual a "false" quiere decir que no es un propecto, ahí si consume el servicio: 520-Servicio_cotizacion_mercancia_calcular_fecha_entrega = pedidos-calcularfechaentrega el comportamiento debe ser normal a como se viene trabajando
      this.getField("modal_terceros").handleClickOpen();
      this.gridOptionsTerceros["rowData"] = data.model;
      let configCell = new Map();
      this.getField("tabla_terceros").initData(this.gridOptionsTerceros, configCell);
    } else if (data.data.length === 1) {
      this.nitDeProspectos = false; // Si esta variable es igual a "false" quiere decir que no es un propecto, ahí si consume el servicio: 520-Servicio_cotizacion_mercancia_calcular_fecha_entrega = pedidos-calcularfechaentrega el comportamiento debe ser normal a como se viene trabajando
      this.getField("sede").setValue(data.model[0].sede);
      this.getField("nit").setValue(data.model[0].nit);
      this.getField("nombre_cliente").setValue(data.model[0].nombre);
    } else if (data.data.length < 1) {
      this.getField("confirmModalCustom").setTitleAndContent("Buscar", "Nit no encontrado, Desea buscarlo en Prospectos?");
      this.getField("confirmModalCustom").setClickDialog(this.buscarNitPendiente); //funcion que se ejecuta al confirmar eliminacion
      this.getField("confirmModalCustom").toggle(true);
      this.getField("confirmModalCustom").setVisibleCancel(false);
    }
  }

  onSelectionChangedTerceros() {
    //si se selecciona uno de los codigos de facturacion de la tabla
    let datos = this.gridOptionsTerceros.api.getSelectedRows();
    if(datos.length>0){
      this.getField("modal_terceros").handleClose();
      this.getField("sede").setValue(datos[0].sede);
      this.getField("nit").setValue(datos[0].nit);
      this.getField("nombre_cliente").setValue(datos[0].nombre);
    }
  }

  buscarNitPendiente() {
    this.nitDeProspectos = true; // Si esta variable es igual a "true" no se va a consumir el servicio: "pedidos-calcularfechaentrega"
    let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
    this.getField("direccion").setOptions(opciones);
    this.getField("confirmModalCustom").toggle(false);
    //520_retorna_terceros_pendientes_nit

    let datos = {
      datos: {
        value: this.getField("nit").getValue(),
      }
    };
    this.generalFormatPmv = { tipo_servicio: 'maes-tercerospendientes', operacion: '42', operacion_tipo: 'consulta' };//codigo exacto
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method: 'GET',
      body: datos,
      success: this.successBuscarNitPendiente,
      error: this.error_,
      general: this.generalFormatPmv,
    });
  }

  successBuscarNitPendiente(data) {
    if (data.estado_p === 200) {
      this.getField("nit").setValue(data.data[0].nit);
      this.getField("nombre_cliente").setValue(data.data[0].nombre);
      this.getField("sede").setValue(1);
      this.getField("telefono").setValue(data.data[0].telefonos);
      this.getField("codigo_vendedor").setValue(data.data[0].codigo_vendedor);
      if (this.getField("codigo_vendedor").getValue() !== '' && this.getField("codigo_vendedor").getValue() !== ' ') {
        this.getField("codigo_vendedor").setError(false, "");
      }
      this.getField("tasa_cambio").setValue(data.data[0].tasa_cambio);
      this.getField("codigo_ciudad").setValue(data.data[0].codigo_ciudad);
      if (this.getField('codigo_ciudad').getValue() !== '' && this.getField('codigo_ciudad').getValue() !== ' ') {
        this.getField('codigo_ciudad').setError(false, "");
      }
      this.getField("nombre_ciudad").setValue(data.data[0].nombre_ciudad);
      this.getField("nombre_vendedor").setValue(data.data[0].nombre_vendedor);
      let opciones2 = [
        {
          text: data.model[0].direccion,
          value: data.model[0].direccion,
          campos_cambian: {},
        },
      ];
      this.getField("direccion").setOptions(opciones2);
      this.getField("direccion").setValue(data.model[0].direccion);


      this.getField("tipo_negocio_id").setValue(parseInt(data.data[0].negocios_id));
      this.getField("lista_precios_prospectos").setValue(data.data[0].nit);
      this.getField("sucursales_pendientes").setValue(data.data[0].codigo_vendedor);
    } else {
      this.getField("confirmModalCustom").setTitleAndContent("Buscar", "Nit no encontrado en Prospectos, Desea Crearlo?");
      this.getField("confirmModalCustom").setClickDialog(this.crearNitPendiente); //funcion que se ejecuta al confirmar eliminacion
      this.getField("confirmModalCustom").toggle(true);
      this.getField("confirmModalCustom").setVisibleCancel(false);
    }
  }

  crearNitPendiente() {
    let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
    this.getField("direccion").setOptions(opciones);
    this.getField("confirmModalCustom").toggle(false);
    this.getField("documento_nuevo").setValue(this.getField("nit").getValue());
    this.calcularDV();
    this.getField("nom1").setValue(".");
    this.getField("nom2").setValue(".");
    this.getField("ape1").setValue(".");
    this.getField("ape2").setValue(".");
    this.getField("email").setValue(".");

    this.getField("nom1").setDisabled(true);
    this.getField("nom2").setDisabled(true);
    this.getField("ape1").setDisabled(true);
    this.getField("ape2").setDisabled(true);
    this.getField("nombre_cliente_nuevo").setDisabled(true);


    this.getField('sexo').setValue('M');
    let fechaModificada = `2000-01-01`;
    let cambioFormato = Date.parse(fechaModificada);
    let fecha = new Date(cambioFormato);
    let campoFecha = fecha.toISOString();
    // console.log('Fecha Modificada', campoFecha.split('T')[0]);
    this.getField('fecha_nacimiento').setValue(campoFecha.split('T')[0]);
    this.limpiarMensajesErrorModalProspectos();
    this.getField("modal_nuevo_nit").handleClickOpen();
  }

  retornarCodigosFacturacion() {
    let datos = {
      datos: {
        sucursal_ingreso: this.sucursal_ingreso
      }
    };
    this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'codigofacturacionpedidos_bodegas_autorizadas', operacion_tipo: 'consulta' };
    this.service.send(
      {
        endpoint: this.constant.formConfiguration(),
        method: 'GET',
        body: datos,
        success: this.successLoadCodigoFacturacion,
        error: this.error_,
        general: this.generalFormatPmv,
      });
  }

  successLoadCodigoFacturacion(data) {
    /*if(data.model && data.model.length === 1){                
                this.gridOptionsCodigosFacturacion['rowData'] = data.model;
                this.getField('tabla_codigos_facturacion').initData(this.gridOptionsCodigosFacturacion);
                this.getField('codigo_facturacion_actual').setValue(data.model[0].codigo_facturacion); 
                this.getField('codigo_facturacion').setValue(datos[0].codigo_facturacion);
            }else */ if (data.model && data.model.length > 0) {
      //this.getField('modal_tercero').handleClickOpen();
      this.getField("modal_principal").handleClose();
      this.gridOptionsCodigosFacturacion["rowData"] = data.model;
      this.gridOptionsCodigosFacturacion.paginationPageSize = 100;
      let configCell = new Map();
      //se agregan los siguientes botones a la tabla de datos
      this.getField("tabla_codigos_facturacion").initData(this.gridOptionsCodigosFacturacion, configCell);
    } else {
      //de lo contrario no se muestra tabla
      this.getField("tabla_codigos_facturacion").toggle(false);
      this.alertGeneral.toggle(true, "El usuario no posee códigos de facturación", "error");
    }
  }

  onSelectionChangedCodigosFacturacion(data) {
    //si se selecciona uno de los codigos de facturacion de la tabla
    this.datosCodigos = this.gridOptionsCodigosFacturacion.api.getSelectedRows();
    if (this.datosCodigos !== undefined && this.datosCodigos[0] !== undefined) {
      this.getField("modal_principal").handleClickOpen(); // y se abre el modal principal
      this.getField('nit').setError(false, "");
      this.getField('sede').setError(false, "");
      this.getField('nombre_cliente').setError(false, "");
      this.getField('codigo_ciudad').setError(false, "");
      this.getField('codigo_vendedor').setError(false, "");
      this.getField('observaciones').setError(false, "");
      this.getField('orden_de_compra').setError(false, "");
      this.getField("codigo_facturacion").setValue(this.datosCodigos[0].codigo_facturacion); //se carga el codigo en el campo
      this.getField("codigo_facturacion_actual").setValue(this.datosCodigos[0].codigo_facturacion); //se carga el codigo en el campo
      this.getField("nombre_sucursal").setValue(this.datosCodigos[0].detalle);
      this.gridOptionsCodigosFacturacion.api.deselectAll();
      this.getField("orden_de_compra").setValue(".");
      this.getField("observaciones").setValue(".");
      let fechaLocal = new Date();
      let diasVigencia = 30;
      this.setDiasVigencia(fechaLocal, diasVigencia);
      this.bodegaIngreso = this.datosCodigos[0].bodegas_autorizadas;
      //this.bodegaSeleccionada = this.datosCodigos[0].bodegas_autorizadas;
    }
  }

  selectDirecciones() {
    if (this.getField("sede").getValue() !== "" && this.getField("nit").getValue() !== "") {
      /*if(localStorage.getItem('pmvLogin')){
        let datos = {datos: {
          sede: this.getField("sede").getValue(),
          nit: this.getField("nit").getValue(),
        }};
        let generales = {"tipo_servicio": "ferr-terceros", "operacion": "direccionescliente", "operacion_tipo":"consulta" }

            let url = this.pmvUrl.split('/pmvlogin');
            let endpointPmv = `${url[0]}/pmv/api/protected`;
            this.service.send(
                {
                    endpoint: endpointPmv,
                    method: 'GET',
                    body: datos,
                    success:this.successDirecciones,
                    error: this.error_,
                    general: generales,
                    pmvRequest: true
                }
            );
    }else{*/

      let datos = {
        datos: {
          sede: this.getField("sede").getValue(),
          nit: this.getField("nit").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: 'direccionescliente', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successDirecciones,
          error: this.error_,
          general: this.generalFormatPmv,
        });
      //}
    }
  }
  successDirecciones(data) {
    if (data.data.length >= 1) {
      let opciones = [];
      data.data.forEach((item) => {
        let dataOp = {};
        dataOp["value"] = item.direccion;
        dataOp["text"] = item.direccion /*+ " - " + item.nciudad*/;
        dataOp["campos_cambian"] = {
          codigo_ciudad: item.codigo_ciudad,
          nombre_ciudad: item.nciudad,
        };
        opciones.push(dataOp);
      });
      this.getField("direccion").setOptions(opciones);
    } else {
      let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
      this.getField("direccion").setOptions(opciones);
    }
  } //codigo_ciudad nombre_ciudad
  cleanRecalcular() {
    this.getField("cantidad_item_520").setValue("");
    this.getField("valor_total_item").setValue("");
    this.getField("precio_facturacion").setValue("");
  }
  cleanRecalcularCant() {
    this.getField("valor_total_item").setValue("");
    this.getField("precio_facturacion").setValue("");
    this.getField("boton_agregar_item").setDisabled(true);
  }
  cleanValid() {
    this.getField("subtotal").setError(false, "");
    this.getField("valor_iva_final").setError(false, "");
    this.getField("numero_de_item").setError(false, "");
    this.getField("valor_pedido").setError(false, "");
    this.getField("orden_cotizacion").setError(false, "");
    this.getField("observaciones").setError(false, "");
    this.getField("clase_de_pedido").setError(false, "");
    this.getField("orden_de_compra").setError(false, "");
    this.getField("detalle_item").setError(false, "");
    this.getField("descuento_item").setError(false, "");
    this.getField("tarifa_iva_item").setError(false, "");
    this.getField("cantidad_item_520").setError(false, "");
    this.getField("valor_total_item").setError(false, "");
    this.getField("descuento_general").setError(false, "");
    if (this.validacionPrecioLista === true) {
      this.getField("precio_lista").setError(false, "");
    }
    this.getField("codigo_producto").setError(false, "");
    this.getField("nit").setError(false, "");
    this.getField("sede").setError(false, "");
    if (this.getField("detalle_item").valid() && this.cantidadDespacho === true) {
      this.getField("boton_agregar_item").setDisabled(false);
      this.getField("cantidad_item_520").setError(false, "");
    } else {
      this.getField("boton_agregar_item").setDisabled(true);
    }
  }
  recalcularPreciosCantidad() {
    //si se cambia la cantidad de items de una articulo, se limpian los totales y se recalculan
    this.getField("precio_facturacion").setValue("");
    this.getField("valor_total_item").setValue("");
  }
  recalcularPreciosLista() {
    //Si se cambia el precio de lista, se limpian los totales y se recalculan
    this.getField("descuento_item").setValue(0);
    this.getField("cantidad_item_520").setValue("");
    this.getField("precio_facturacion").setValue("");
    this.getField("valor_total_item").setValue("");
  }
  recalcularPrecios() {
    //Si se cambia el descuento, se limpian los totales y se recalculan
    this.getField("cantidad_item_520").setValue("");
    this.getField("precio_facturacion").setValue("");
    this.getField("valor_total_item").setValue("");
    this.cleanValid();
    this.cleanRecalcular();
  }

  FechaEntregaCot() {
    if (parseInt(this.getField("numero_de_item").getValue()) >= 1 && this.nitDeProspectos === false) {
      let datos = {
        datos: {
          numero_items: parseInt(this.getField("numero_de_item").getValue()),
          nit: this.getField("nit").getValue(),
          sede: this.getField("sede").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'calcularfechaentrega', operacion_tipo: 'consulta' };
      this.service.send({
        endpoint: this.constant.formConfiguration(),
        method: 'GET',
        body: datos,
        success: this.successFechaEntregaCot,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage: false
      });
    } else {
      // Función para sumar y restar días a una fecha en JavaScript, documentación: https://unipython.com/sumar-y-restar-dias-a-una-fecha-en-java-script/#:~:text=Funci%C3%B3n%20para%20sumar%20y%20restar%20d%C3%ADas%20a%20una%20fecha%20en%20Java%20Script&text=var%20d%20%3D%20new%20Date()%3B,adem%C3%A1s%20de%20entender%20el%20c%C3%B3digo.
      let fechaActual = new Date();
      fechaActual.setDate(fechaActual.getDate() + 30); // La fecha actual mas 30 días, según los requerimientos
      // console.log('Fecha actual mas 30 días:\n',fechaActual);
      let cambioFormato = Date.parse(fechaActual);
      let fecha = new Date(cambioFormato);
      let campoFecha = fecha.toISOString();
      this.getField('fecha_entrega').setValue(campoFecha.split('T')[0]);
    }
  }

  CloseModalProductos() {
    if (this.itemEnEdicion === '') {
      this.btnCerrarModal = true;
      this.productoModalListar = '';
      this.campo1ModalListar = '';
      this.campo2ModalListar = '';
      this.estadoTablaProductosBodegas = [];
      this.registroSeleccionado = '';
      this.crearPedido();
      this.getField("modal_producto").handleClose();
    } else {
      this.alertGeneral.toggle(true, "Esta Editando un ítem", "error");
    }
    //Se cierra el modal al dar click en boton aceptar
    this.nitDeProspectos = false; // Lo dejamos en "false" para dejar la variable en su estado inicial
  }

  successFechaEntregaCot(data) {
    if (data.estado_p === 200) {
      this.getField("fecha_entrega").setValue(data.data[0].fecha_entrega);
    }
  }


  validarCostosItem(data) {
    this.agregarItems();
  }
  /**
   * codigo_producto,codigo_producto;precio_sin_iva_con_descuento,precio_lista;codigo_bodega,codigo_bodega_item
   */

  loadTablaItems(data) {
    // console.log('Este es el data de loadTablaItems',data);
    this.getField("modal_producto").handleClickOpen();
    this.getField("valor_total_item").setError(false, '');
    this.getField('codigo_producto').setError(false, "");
    this.getField('precio_lista').setError(false, "");
    this.getField('descuento_item').setError(false, "");
    this.getField('cantidad_item_520').setError(false, "");
    this.getField('detalle_item').setError(false, "");
    this.getField('tarifa_iva_item').setError(false, "");
    //si el array2 esta vacio, quiere decir que el codigo ingresado no esta en el array principal y se procede a agregarlo
    /**
     * El Array es cargado en la tabla, junto con botones adicionales para las respectivas funciones
     *  */
    //El array global es cargado y actualizado con cada objeto que contiene la informacion de
    //los articulos agregados desde el formulario
    this.getField("codigo_producto").setDisabled(false); // se habilita el campo codigo
    this.modificarArray = []; //se limpia el array modificar, si este array esta lleno, quiere decir que en ese momento se esta modificando un item y no se podria agregar uno nuevo
    this.gridOptionsTablaItems["rowData"] = data; //y se recarga la tabla
    this.arrayOrigin = this.nuevoArray;
    let configCell = new Map();
    //precio sin iva      y     precio total    ------> para dar formato de número
    configCell.set(0, { field: "codigo", width: 110 });
    configCell.set(5, { field: "descuento", width: 120 });
    configCell.set(4, { field: "precio sin iva", valueFormatter: this.currencyFormatterPrecioSinIva, });
    configCell.set(8, { field: "precio total", valueFormatter: this.currencyFormatterPrecioTotal, });
    configCell.set(3, { field: "precio", valueFormatter: this.currencyFormatterPrecio });
    configCell.set(6, { field: "precio_sin_iva_con_descuento", valueFormatter: this.currencyFormatterPrecioSinIvaConDescuento });
    configCell.set(7, { field: "valor_iva", valueFormatter: this.currencyFormatterValorIva });
    configCell.set(1, { field: "nombre", width: 260 });
    configCell.set(2, { field: "cantidad", width: 110 });
    configCell.set(9, { headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, field: "Descuento en bloque", });
    configCell.set(10, { cellRenderer: this.setButtonEliminarItem, field: "Accion Eliminar", width: 150, });
    configCell.set(11, { cellRenderer: this.setButtonModificarItem, field: "Accion Modificar", width: 150, });
    this.gridOptionsTablaItems["suppressRowClickSelection"] = true;
    this.gridOptionsTablaItems["rowSelection"] = "multiple";
    this.gridOptionsTablaItems["enableRangeSelection"] = true;
    this.gridOptionsTablaItems["enableCellChangeFlash"] = true;
    // this.getField("descuento_general").setVisible(true);
    // this.getField("aplicar_descuento").setVisible(true);
    this.getField("tabla_item").initData(this.gridOptionsTablaItems, configCell);
    //this.gridOptionsTablaItems["onSelectionChanged"] = this.rowSelectedPedido;
    this.clearCamposProducto(); //una vez se carga un item, se limpian los campos para una nueva busqueda
    // Una vez se cargue la tabla con los registros nuevos, se oculta el campo "Descuento en bloque" y el botón "Aplicar"
    this.getField('descuento_general').setValue('');
    this.getField('descuento_general').setError(false, '');
    this.getField("descuento_general").setVisible(false);
    this.getField("aplicar_descuento").setVisible(false);
  }

  agregarItems() {
    /**
     * Se crea un arreglo que permite almacenar en un array los datos
     * de cada articulo agregago, este array se carga en una tabla
     */
    if (this.getField("detalle_item").getValue() !== "" && this.getField("cantidad_item_520").getValue() !== "" &&
      this.getField("cantidad_item_520").getValue() !== 0 && this.getField("codigo_producto").getValue() !== "" &&
      this.getField("precio_lista").getValue() !== "" && this.getField("precio_lista").getValue() !== 0 &&
      this.getField("valor_total_item").valid() && this.getField("tarifa_iva_item").valid()) {
      this.cantidadDespacho = false;
      this.itemEnEdicion = '';
      this.getField("detalle_item").setError(false, "");
      this.getField("cantidad_item_520").setError(false, "");
      this.getField("descuento_item").setError(false, "");
      this.getField("precio_lista").setError(false, "");
      this.getField("codigo_producto").setError(false, "");
      this.getField("boton_agregar_item").setDisabled(true);
      let subtotalSinIva = this.getField("precio_sin_iva").getValue() * this.getField("cantidad_item_520").getValue();
      let totalIvaItems = this.getField("valor_iva").getValue() * this.getField("cantidad_item_520").getValue();
      let ObjetoItem = {
        codigo: this.getField("codigo_producto").getValue(),
        nombre: this.getField("nombre_producto").getValue(),
        cantidad: this.getField("cantidad_item_520").getValue(),
        descuento: this.getField("descuento_item").getValue(),
        precio_sin_iva: this.getField("precio_sin_iva").getValue(),
        precio_total: this.getField("valor_total_item").getValue(),
        bodega: this.getField("codigo_bodega_item").getValue(),
        nombre_bodega_item: this.getField("nombre_bodega").getValue(),
        valor_iva: this.getField("valor_iva").getValue(),
        peso: this.getField("peso_grm").getValue(),
        precio: this.getField("precio_lista").getValue(),
        codigo_unidad: this.getField("unidad").getValue(),
        detalle: this.getField("detalle_item").getValue(),
        tarifa_iva: this.getField("tarifa_iva_item").getValue(),
        precio_sin_iva_con_descuento: this.getField("precio_sin_iva_con_descuento").getValue(),
        precio_mas_iva: this.getField("precio_mas_iva").getValue(),
        tasa_de_cambio: this.getField("tasa_de_cambio").getValue(),
        descuento_bloque: 0,
        codigo_facturacion: this.getField("codigo_facturacion").getValue(),
        subtotal: subtotalSinIva,
        totalIvaItems: totalIvaItems,
      };
      if (this.getField("codigo_producto").valid() && this.getField("nombre_producto").getValue() !== "" &&
        this.getField("cantidad_item_520").valid()) {
        this.arrayTemporal.push(ObjetoItem); //el item agregado se carga primero en un array temporal
        let codigoArray1 = this.getField("codigo_producto").getValue(); //el codigo del articulo se guarda en un array
        let codigoArray2 = []; // se crea un array vacio
        this.nuevoArray.forEach((item) => {
          //se recorre el array principal, y se busca si el codigo del articulo en el array1 ya se encuentra en el array principal
          if (item.codigo === codigoArray1) {
            codigoArray2.push(item); // si es asi, se carga ese codigo en el array2
          }
        });
        if (codigoArray2.length === 0) {
          this.nuevoArray.push(ObjetoItem);
          this.loadTablaItems(this.nuevoArray);
          this.habilitarBtnGrabarPorBodega();
          this.getField("codigo_producto").setValue(""); //se limpian los campos de codigo y nombre
          this.getField("nombre_producto").setValue("");
        } else {
          //si el array2 es diferente de 0, quiere decir que el articulo ya fue agregado y se muestra el mensaje
          this.alertGeneral.toggle(true, "El item ya fue agregado", "error");
        }
      } else {
        //si el objeto esta vacio, la tabla no se muestra
        //this.getField('tabla_item').toggle(false);
        ObjetoItem = {};
      }

      if (this.estadoModalListar === true) {
        //this.CloseModalProductos();
        this.btnCerrarModal = true;
        //this.productoModalListar = '';
        //this.campo1ModalListar = '';
        //this.campo2ModalListar = '';
        //this.estadoTablaProductosBodegas = [];
        this.registroSeleccionado = '';
        this.crearPedido();
        this.getField("modal_producto").handleClose();

        this.modalCodigoProducto();
        this.successTablaCodigoProducto(this.estadoTablaProductosBodegas);
      }
      
    } else if (this.getField("codigo_producto").getValue() === "") {
      this.getField("codigo_producto").setError(true, "* Este campo es requerido.");
      if (this.itemEnEdicion === '') {
        this.alertGeneral.toggle(true, "Faltan datos", "error");
      }
      this.getField("boton_agregar_item").setDisabled(true);
    } else if (this.getField("precio_lista").getValue() === "" || this.getField("precio_lista").getValue() === 0) {
      this.getField("precio_lista").setError(true, "* Este campo es requerido.");
      this.alertGeneral.toggle(true, "Faltan datos", "error");
      this.getField("boton_agregar_item").setDisabled(true);
    } else if (this.getField("detalle_item").getValue() === "") {
      this.getField("detalle_item").setError(true, "* Este campo es requerido.");
      this.alertGeneral.toggle(true, "Faltan datos", "error");
      this.getField("boton_agregar_item").setDisabled(true);
    } else if (this.getField("cantidad_item_520").getValue() === "" || this.getField("cantidad_item_520").getValue() === 0) {
      this.getField("cantidad_item_520").setError(true, "* Este campo es requerido.");
      this.alertGeneral.toggle(true, "Faltan datos", "error");
      this.getField("boton_agregar_item").setDisabled(true);
    }
  }
  currencyFormatterPrecioSinIva(data) {
    let decimal = (data.data.precio_sin_iva + "").split(".")[1];
    if (decimal !== 0 && decimal !== undefined) {
      return (this.formatNumberPrecioSinIva(data.data.precio_sin_iva) + "." + decimal);
    } else {
      return this.formatNumberPrecioSinIva(data.data.precio_sin_iva);
    }
  }
  formatNumberPrecioSinIva(number) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  currencyFormatterPrecioTotal(data) {
    let decimal = (data.data.precio_total + "").split(".")[1];
    if (decimal !== 0 && decimal !== undefined) {
      return (this.formatNumberPrecioTotal(data.data.precio_total) + "." + decimal);
    } else {
      return this.formatNumberPrecioTotal(data.data.precio_total);
    }
  }
  formatNumberPrecioTotal(number) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  currencyFormatterPrecio(data) {
    let decimal = (data.data.precio + "").split(".")[1];
    if (decimal !== 0 && decimal !== undefined) {
      return (this.formatterPrecio(data.data.precio) + "." + decimal);
    } else {
      return this.formatterPrecio(data.data.precio);
    }
  }
  currencyFormatterPrecioSinIvaConDescuento(data) {
    let decimal = (data.data.precio_sin_iva_con_descuento + "").split(".")[1];
    if (decimal !== 0 && decimal !== undefined) {
      return (this.formatterPrecioSinIvaConDescuento(data.data.precio_sin_iva_con_descuento) + "." + decimal);
    } else {
      return this.formatterPrecioSinIvaConDescuento(data.data.precio_sin_iva_con_descuento);
    }
  }
  currencyFormatterValorIva(data) {
    let decimal = (data.data.valor_iva + "").split(".")[1];
    if (decimal !== 0 && decimal !== undefined) {
      return (this.formatterValorIva(data.data.valor_iva) + "." + decimal);
    } else {
      return this.formatterValorIva(data.data.valor_iva);
    }
  }
  formatterPrecio(number) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  formatterPrecioSinIvaConDescuento(number) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  formatterValorIva(number) {
    return Math.floor(number)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  rowSelectedPedido() {
    this.arrayDataCheckBox = this.gridOptionsTablaItems.api.getSelectedRows();
    if (this.arrayDataCheckBox.length >= 1) {
      this.getField('descuento_general').setValue('');
      this.getField('descuento_general').setError(false, '');
      this.getField("descuento_general").setVisible(true);
      this.getField("aplicar_descuento").setVisible(true);
    } else {
      this.getField('descuento_general').setValue('');
      this.getField('descuento_general').setError(false, '');
      this.getField("descuento_general").setVisible(false);
      this.getField("aplicar_descuento").setVisible(false);
    }
    let ItemsDescuento = this.arrayDataCheckBox;
    this.agregarDescuentoItem(ItemsDescuento);
  }

  agregarDescuentoItem(ItemsDescuento) {
    this.dAgregado = ItemsDescuento;
  }

  aplicarDescuentoBloque() {
    if (this.dAgregado.length > 0 && this.itemEnEdicion === '') {
      if (this.getField("descuento_general").valid()) {
        this.getField("aplicar_descuento").setDisabled(true);
        let valorDescuento = this.getField("descuento_general").getValue();
        this.auxiliarArray = [];
        this.nuevoArray.forEach((items) => {
          let objectItems = {};
          objectItems = Object.assign({}, items);
          this.auxiliarArray.push(objectItems);
        });
        this.dAgregado.forEach((item, post) => {
          this.auxiliarArray.forEach((item2) => {
            if (item.codigo === item2.codigo) {
              item2.descuento_bloque = 1;
              item2.descuento = valorDescuento;
            }
          });
        });

        let datos = {
          datos: {
            productos: this.auxiliarArray,
          }
        };
        this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'actualizarprecioitems', operacion_tipo: 'modificar' };
        this.service.send(
          {
            endpoint: this.constant.formConfiguration(),
            method: 'PUT',
            body: datos,
            success: this.successDescuentoBloque,
            error: this.error_,
            general: this.generalFormatPmv
          });
      }
    } else if (this.itemEnEdicion === '') {
      this.alertGeneral.toggle(true, "Esta modificando un ítem", "error");
    } else {
      this.alertGeneral.toggle(true, "No ha seleccionado ningún item", "error");
    }
  }
  successDescuentoBloque(data) {
    this.getField("aplicar_descuento").setDisabled(false);
    if (data.estado_p === 200) {
      this.nuevoArray = data.data;
      this.openModalProducto();
      this.dAgregado = [];
      this.getField("descuento_general").setValue(0);
    }
  }

  setButtonEliminarItem(props) {
    /**
     * se crean las propiedades del boton para darle funcionalidad
     */
    let button = document.createElement("input");
    button.onclick = () => this.eliminarItemConfirmar(props.data.codigo); //funcion que se ejecuta al dar click al boton, el cual recibe por props el codigo del item
    button.setAttribute("id", "button_aprove_");
    button.setAttribute("class", "buttonStyle"); //,"width:100%; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
    button.setAttribute("type", "button");
    button.setAttribute("value", "Eliminar"); //nombre del boton
    return this.createElementJaivana(button);
  }
  eliminarItemConfirmar(codigo) {
    /**
     * Cuando se ejecuta esta funcion, recibe el codigo del item
     * y pide la confirmacion de eliminacion, a su vez, setea este codigo en un campo
     */
    this.id_delete = codigo;
    this.getField("confirmModalCustom").setTitleAndContent("Eliminar", "Desea realizar esta operación?");
    this.getField("confirmModalCustom").setClickDialog(this.eliminarItem); //funcion que se ejecuta al confirmar eliminacion
    this.getField("confirmModalCustom").toggle(true);
    this.getField("confirmModalCustom").setVisibleCancel(false);
    this.getField("codigo_eliminar").setValue(codigo);
  }
  eliminarItem() {
    if (this.modificarArray.length === 0) {
      //antes de eliminar, nos sercioramos de que el arrayModificar este vacio, de lo contrario, no se puede eliminar el item
      /**
       * Si se confirma la eliminacion, se oculta el aviso, y se crea un array vacio,
       * el cual sera cargado con los objetos del array original, exceptuando el codigo
       * del item seleccionado, que ahora esta seteado en el campo
       */
      this.getField("confirmModalCustom").toggle(false);
      let auxiliarArray = [];
      this.nuevoArray.forEach((item) => {
        if (item.codigo !== this.getField("codigo_eliminar").getValue()) {
          auxiliarArray.push(item);
        }
      });
      /**
       * Se carga nuevamente el array principal con la informacion del nuevo array,
       * y a su vez se refresca la tabla sin el item seleccionado
       */
      this.nuevoArray = auxiliarArray;
      this.habilitarBtnGrabarPorBodega();
      if (this.nuevoArray.length > 0) {
        this.gridOptionsTablaItems["rowData"] = this.nuevoArray;
        let configCell = new Map();
        configCell.set(0, { field: "codigo", width: 110 });
        configCell.set(5, { field: "descuento", width: 120 });
        configCell.set(4, { field: "precio sin iva", valueFormatter: this.currencyFormatterPrecioSinIva, });
        configCell.set(8, { field: "precio total", valueFormatter: this.currencyFormatterPrecioTotal, });
        configCell.set(3, { field: "precio", valueFormatter: this.currencyFormatterPrecio });
        configCell.set(6, { field: "precio_sin_iva_con_descuento", valueFormatter: this.currencyFormatterPrecioSinIvaConDescuento });
        configCell.set(7, { field: "valor_iva", valueFormatter: this.currencyFormatterValorIva });
        configCell.set(1, { field: "nombre", width: 260 });
        configCell.set(2, { field: "cantidad", width: 110 });
        configCell.set(9, { headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, field: "Descuento en bloque", });
        configCell.set(10, { cellRenderer: this.setButtonEliminarItem, field: "Accion Eliminar", width: 150, });
        configCell.set(11, { cellRenderer: this.setButtonModificarItem, field: "Accion Modificar", width: 150, });
        this.gridOptionsTablaItems["suppressRowClickSelection"] = true;
        this.gridOptionsTablaItems["rowSelection"] = "multiple";
        this.gridOptionsTablaItems["enableRangeSelection"] = true;
        this.gridOptionsTablaItems["enableCellChangeFlash"] = true;
        // this.getField("descuento_general").setVisible(true);
        // this.getField("aplicar_descuento").setVisible(true);
        this.getField("tabla_item").initData(this.gridOptionsTablaItems, configCell);
        this.gridOptionsTablaItems["onSelectionChanged"] = this.rowSelectedPedido;
        // Una vez se cargue la tabla con los registros nuevos, se oculta el campo "Descuento en bloque" y el botón "Aplicar"
        this.getField('descuento_general').setValue('');
        this.getField('descuento_general').setError(false, '');
        this.getField("descuento_general").setVisible(false);
        this.getField("aplicar_descuento").setVisible(false);
      } else {
        this.getField("tabla_item").toggle(false);
        this.getField("tabla_bodega").toggle(false);
        this.getField("descuento_general").setVisible(false);
        this.getField("aplicar_descuento").setVisible(false);
      }
    } else {
      this.alertGeneral.toggle(true, "Esta modificando un item", "error");
      this.getField("confirmModalCustom").toggle(false);
    }
  }
  setButtonModificarItem(props) {
    /**
     * se crean las propiedades del boton para darle funcionalidad
     */
    let button = document.createElement("input");
    /**
     * Al dar click en el boton modificar, se ejecuta esta funcion, pasandole por props
     * todas las propiedades del objeto/item seleccionado
     */
    button.onclick = () =>
      this.modificarItem(
        props.data.codigo,
        props.data.nombre,
        props.data.bodega,
        props.data.nombre_bodega_item,
        props.data.valor_iva,
        props.data.peso,
        props.data.precio,
        props.data.descuento,
        props.data.cantidad,
        props.data.codigo_unidad,
        props.data.detalle,
        props.data.tarifa_iva,
        props.data.precio_sin_iva,
        props.data.precio_mas_iva,
        props.data.precio_total,
        props.data.precio_sin_iva_con_descuento,
      );
    button.setAttribute("id", "button_aprove_");
    button.setAttribute("class", "buttonStyle"); //,"width:100%; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
    button.setAttribute("type", "button");
    button.setAttribute("value", "Modificar"); //nombre del boton
    return this.createElementJaivana(button);
  }
  modificarItem(
    codigo,
    nombre,
    bodega,
    nombre_bodega_item,
    valor_iva,
    peso,
    precio,
    descuento,
    cantidad,
    codigo_unidad,
    detalle,
    tarifa_iva,
    precio_sin_iva,
    precio_mas_iva,
    precio_total,
    precio_sin_iva_con_descuento
  ) {
    /**
     * Funcion que se ejecuta al dar click al boton, y que recibe todas las propiedades del objeto/item
     *  */

    if (this.modificarArray.length === 0) {
      //primero verificamos si el arrayModificar esta vacio, de lo contrario, no se puede modificar otro item
      this.getField("codigo_producto").setDisabled(true);
      this.getField("codigo_producto").setValue(codigo);
      this.getField("nombre_producto").setValue(nombre);
      //this.getField('codigo_bodega_item').setValue(bodega);
      //this.getField('nombre_bodega').setValue(nombre_bodega_item);
      this.getField("valor_iva").setValue(valor_iva);
      this.getField("peso_grm").setValue(peso);
      this.getField("precio_lista").setValue(precio);
      this.getField("descuento_item").setValue(descuento);
      this.getField("cantidad_item_520").setValue(cantidad);
      this.getField("unidad").setValue(codigo_unidad);
      this.getField("detalle_item").setValue(detalle);
      this.getField("tarifa_iva_item").setValue(tarifa_iva);
      this.getField("precio_facturacion").setValue(precio_sin_iva_con_descuento/* precio_sin_iva */);
      this.getField("valor_total_item").setValue(precio_total);
      this.getField("precio_sin_iva_con_descuento").setValue(precio_sin_iva_con_descuento);
      this.getField("precio_sin_iva").setValue(precio_sin_iva);
      this.getField("precio_mas_iva").setValue(precio_mas_iva);
      this.getField("valor_iva").setValue(valor_iva);
      this.getField('boton_agregar_item').setDisabled(false);
      this.itemEnEdicion = this.getField("nombre_producto").getValue();

      this.nuevoArray.forEach((item) => {
        if (item.codigo !== codigo) {
          this.modificarArray.push(item); //se carga en el arrayModificar el item que se esta modificando, para evitar hacer otras acciones en la tabla
        }
      });
      this.nuevoArray = this.modificarArray;//agregamos nuevamente el item modificado al arrayPrincipal y se recarga la Tabla
      this.habilitarBtnGrabarPorBodega();
      if (this.modificarArray.length > 0) {
        this.gridOptionsTablaItems["rowData"] = this.modificarArray;
        let configCell = new Map();
        configCell.set(0, { field: "codigo", width: 110 });
        configCell.set(5, { field: "descuento", width: 120 });
        configCell.set(4, { field: "precio sin iva", valueFormatter: this.currencyFormatterPrecioSinIva, });
        configCell.set(8, { field: "precio total", valueFormatter: this.currencyFormatterPrecioTotal, });
        configCell.set(3, { field: "precio", valueFormatter: this.currencyFormatterPrecio });
        configCell.set(6, { field: "precio_sin_iva_con_descuento", valueFormatter: this.currencyFormatterPrecioSinIvaConDescuento });
        configCell.set(7, { field: "valor_iva", valueFormatter: this.currencyFormatterValorIva });
        configCell.set(1, { field: "nombre", width: 260 });
        configCell.set(2, { field: "cantidad", width: 110 });
        configCell.set(9, { headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true, field: "Descuento en bloque", });
        configCell.set(10, { cellRenderer: this.setButtonEliminarItem, field: "Accion Eliminar", width: 150, });
        configCell.set(11, { cellRenderer: this.setButtonModificarItem, field: "Accion Modificar", width: 150, });
        this.gridOptionsTablaItems["suppressRowClickSelection"] = true;
        this.gridOptionsTablaItems["rowSelection"] = "multiple";
        this.gridOptionsTablaItems["enableRangeSelection"] = true;
        this.gridOptionsTablaItems["enableCellChangeFlash"] = true;
        this.getField("descuento_general").setVisible(true);
        this.getField("aplicar_descuento").setVisible(true);
        this.getField("tabla_item").initData(this.gridOptionsTablaItems, configCell);
        this.gridOptionsTablaItems["onSelectionChanged"] = this.rowSelectedPedido;
        // Una vez se cargue la tabla con los registros nuevos, se oculta el campo "Descuento en bloque" y el botón "Aplicar"
        this.getField('descuento_general').setValue('');
        this.getField('descuento_general').setError(false, '');
        this.getField("descuento_general").setVisible(false);
        this.getField("aplicar_descuento").setVisible(false);
      } else {
        // Una vez la tabla quede sin items que mostrar, se oculta el campo "Descuento en bloque" y el botón "Aplicar"
        this.getField('descuento_general').setValue('');
        this.getField('descuento_general').setError(false, '');
        this.getField("descuento_general").setVisible(false);
        this.getField("aplicar_descuento").setVisible(false);
        this.getField("tabla_item").toggle(false);
        this.getField("tabla_bodega").toggle(false);
      }
    } else {
      this.alertGeneral.toggle(true, "Ya está modificando un item", "error");
    }
  }

  valueOrdenCotizacion() {
    //se setea el estado del pedido en el campo, segun lo seleccionado en el select
    this.cleanValid();
    if (this.getField("orden_cotizacion").getValue() !== "") {
      this.getField("estado_pedido").setValue(this.getField("orden_cotizacion").getValue());
    } else {
      this.getField("estado_pedido").setValue("");
    }
  }

  clearCampos() {
    // cuando se llama esta funcion, se limpan los campos del modal principal
    let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
    this.getField("direccion").setOptions(opciones);
    this.getField("direccion").setValue("");
    this.getField("telefono").setValue("");
    this.getField("codigo_ciudad").setValue("");
    this.getField("nombre_ciudad").setValue("");
    this.getField("codigo_vendedor").setValue("");
    this.getField("nombre_vendedor").setValue("");
    this.getField("tasa_cambio").setValue("");
    this.getField("precios").setValue("");
    this.getField("codigo_negocio").setValue("");
    this.getField("tasa_de_cambio").setValue("");
    this.getField("observaciones").setValue(".");
    this.getField("subtotal").setValue("");
    this.getField("valor_iva_final").setValue("");
    this.getField("numero_de_item").setValue("");
    this.getField("valor_pedido").setValue("");

    this.getField("sede").setError(false, "");
    this.getField("nombre_cliente").setError(false, "");
    this.getField("direccion").setError(false, "");
    this.getField("telefono").setError(false, "");
    this.getField("codigo_ciudad").setError(false, "");
    this.getField("nombre_ciudad").setError(false, "");
    this.getField("codigo_vendedor").setError(false, "");
    this.getField("nombre_vendedor").setError(false, "");
    this.getField("tasa_cambio").setError(false, "");
    this.getField("observaciones").setError(false, "");
    this.getField("subtotal").setError(false, "");
    this.getField("valor_iva_final").setError(false, "");
    this.getField("valor_pedido").setError(false, "");
    this.getField("numero_de_item").setError(false, "");
    this.getField("precios").setError(false, "");
    this.getField("codigo_negocio").setError(false, "");
    this.getField("tasa_de_cambio").setError(false, "");

    this.nuevoArray = [];
    this.estadoTablaProductosBodegas = [];
    this.registroSeleccionado = '';
    this.TipoNegociosProspecto = "";
    this.codigoNegocio = "";
    this.bodegaSeleccionada = '';
    this.gridOptionsProductos['rowData'] = [];
    let configCell = new Map();
    this.getField('tb_codigo_producto').initData(this.gridOptionsProductos, configCell);
    this.getField('tb_codigo_producto').toggle(false);
    this.productoModalListar = '';
    this.campo1ModalListar = '';
    this.campo2ModalListar = '';
    // this.getField('btn_producto_bodega').setDisabled(true);
    this.bodegasArray = [];
    this.getField('tabla_modal_grabar_bodega').toggle(false);
    this.getField('codigo_bodega').setOptions(this.opciones);
    this.getField('total_cantidad_preciosiniva').setValue('');
    this.estadoBodegasArray = false;
  }
  clearCamposProducto() {
    //cuando se llama esta funcion, se limpan los campos del modal productos
    //this.getField('codigo_bodega_item').setValue('');
    //this.getField('nombre_bodega').setValue('');
    this.getField("tarifa_iva_item").setValue("");
    this.getField("peso_grm").setValue("");
    this.getField("precio_lista").setValue("");
    this.getField("unidad").setValue("");
    this.getField("precio_facturacion").setValue("");
    this.getField("valor_total_item").setValue("");
    this.getField("descuento_item").setValue(0);
    this.getField("cantidad_item_520").setValue("");
    //this.gridOptionsBodegasItem['rowData'] = [];
    //this.getField('tabla_bodega').toggle(false);
    this.getField("precio_sin_iva_con_descuento").setValue("");
    this.getField("precio_sin_iva").setValue("");
    this.getField("precio_mas_iva").setValue("");
    this.getField("detalle_item").setValue(".");
  }

  searchTercero() {
    //servicio que consulta los datos del Cliente
    this.getField("clase_de_pedido").setValue("1EST");
    if (this.getField("nit").getValue() !== "" && this.getField("sede").getValue() !== "" && this.getField("nombre_cliente").getValue() !== "") {
      this.getField("nit").setError(false, "");
      this.getField("sede").setError(false, "");
      this.getField("nombre_cliente").setError(false, "");

      /*if(localStorage.getItem('pmvLogin')){
        let datos = {datos: {
          nit: this.getField("nit").getValue(),
          sede: this.getField("sede").getValue(),
        }};
        let generales = {"tipo_servicio": "ferr-terceros", "operacion": "clientenitsede", "operacion_tipo":"consulta" }

            let url = this.pmvUrl.split('/pmvlogin');
            let endpointPmv = `${url[0]}/pmv/api/protected`;
            this.service.send(
                {
                    endpoint: endpointPmv,
                    method: 'GET',
                    body: datos,
                    success:this.successSearchTercero,
                    error: this.error_,
                    general: generales,
                    pmvRequest: true
                }
            );
    }else{*/

      let datos = {
        datos: {
          nit: this.getField("nit").getValue(),
          sede: this.getField("sede").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: 'clientenitsede', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successSearchTercero,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false,
        });
      //}
    } else if (this.getField("nit").getValue() === "" || this.getField("sede").getValue() === "") {
      this.getField("nit").setError(true, "* Este campo es requerido.");
      this.getField("sede").setError(true, "* Este campo es requerido.");
    }
  }
  successSearchTercero(data) {
    //si el cliente tiene datos, se setean en los campos respectivos
    this.getField("tasa_de_cambio").setValue(this.getField("tasa_cambio").getValue());
    if (data.estado_p === 200) {
      //console.log('datos del searchTercero:',data.data[0].estado_bloqueo);
      let estado_bloqueo = data.data[0].estado_bloqueo;
      if (estado_bloqueo !== null && estado_bloqueo === 'B') {
        //console.log('Este cliente no puede hacer pedidos');
        let mensaje = `El cliente ${data.data[0].nit} Sede ${data.data[0].sede} - ${data.data[0].nombre}.
          Está bloqueado por la siguiente razón: ${data.data[0].detalle}`
        this.getField("confirmModalCustom").setTitleAndContent('Imposible continuar', mensaje); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
        this.getField("confirmModalCustom").setClickDialog(() => {
          this.getField('confirmModalCustom').toggle(false);
        });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField("confirmModalCustom").toggle(true);
      } else {
        //this.getField('nombre_cliente').setValue(data.model[0].nombre);
        this.getField("direccion").setValue(data.model[0].direccion);
        this.getField("telefono").setValue(data.model[0].telefonos);
        this.getField("codigo_ciudad").setValue(data.model[0].codigo_ciudad);
        if (this.getField('codigo_ciudad').getValue() !== '' && this.getField('codigo_ciudad').getValue() !== ' ') {
          this.getField('codigo_ciudad').setError(false, "");
        }
        this.getField("nombre_ciudad").setValue(data.model[0].nciudad);
        this.getField("codigo_vendedor").setValue(data.model[0].codigo_vendedor);
        if (this.getField("codigo_vendedor").getValue() !== '' && this.getField("codigo_vendedor").getValue() !== ' ') {
          this.getField("codigo_vendedor").setError(false, "");
        }
        this.getField("nombre_vendedor").setValue(data.model[0].nvendedor);
        this.getField("tasa_cambio").setValue(data.model[0].tasa_cambio);
        this.getField("precios").setValue(data.model[0].precios);
        this.codigoNegocio = data.model[0].codigo_negocio;
        this.getField("codigo_negocio").setValue(data.model[0].codigo_negocio);
        this.getField("tasa_de_cambio").setValue(this.getField("tasa_cambio").getValue());
        this.getField("sucursal_origen").setValue(data.model[0].sucursal_origen);
        this.getField("sucursal_cartera").setValue(data.model[0].sucursal_cartera);
        this.selectDirecciones();

      }

    }
  }
  openModalProducto() {
    //Si se llama esta funcion, se abre el modal, siempre y cuando, la tasa de cambio sea diferente a vacio
    this.itemEnEdicion = '';
    this.modificarArray = [];
    if (this.TipoNegociosProspecto !== "") {
      this.getField("codigo_negocio").setValue(this.TipoNegociosProspecto);
    }
    this.getField('detalle_item').setError(false, "");
    this.getField('detalle_item').setValue('.');
    this.getField("lista_precios").setValue(this.getField("precios").getValue());
    if (this.getField("nit").valid() && this.getField("sede").valid() && this.getField("nombre_cliente").valid() &&
      this.getField("direccion").valid() && this.getField("telefono").valid() && this.getField("codigo_ciudad").valid() &&
      this.getField("nombre_ciudad").valid() && this.getField("codigo_vendedor").valid() &&
      this.getField("nombre_vendedor").valid() && this.getField("tasa_cambio").getValue() !== "") {
      this.getField("tasa_de_cambio").setValue(this.getField("tasa_cambio").getValue());
      // this.retornaBodegasPorCodigoFacturacion();
      this.getField("codigo_producto").setDisabled(false); // si el campo de codigo esta bloqueado, se habilita
      if (this.nuevoArray.length > 0) {
        //Si el arrayPricipal tiene items agregados, se busca nuevamente
        //this.searchTercero();//la informacion del cliente para poder seguir agregando items
        if (this.codigoNegocio !== "") {
          this.getField("codigo_negocio").setValue(this.codigoNegocio);
        }

        this.loadTabla = true;
        this.loadTablaItems(this.nuevoArray);
        this.clearCamposProducto(); //una vez se carga un item, se limpian los campos para una nueva busqueda 
        if (this.estadoModalListar === false) {
          this.getField("codigo_producto").setValue("");
          this.getField("nombre_producto").setValue("");
        }
      } else {
        //si el array esta vacio, solo se busca la informacion del cliente y se abre el modal, la tabla no se muestra
        //this.searchTercero();
        this.getField("descuento_general").setVisible(false);
        this.getField("aplicar_descuento").setVisible(false);
        if (this.codigoNegocio !== "") {
          this.getField("codigo_negocio").setValue(this.codigoNegocio);
        }
        // this.getField("modal_producto").handleClickOpen(); // Se comenta esta línea ya que lo que se requiere es que la modal "Producto" se abra una vez se setee el campo "codigo_bodega_item" que está dentro de la modal mencionada "Producto"
        this.getField('codigo_producto').setError(false, "");
        this.getField('precio_lista').setError(false, "");
        this.getField('descuento_item').setError(false, "");
        this.getField('cantidad_item_520').setError(false, "");
        this.getField('detalle_item').setError(false, "");
        this.getField('tarifa_iva_item').setError(false, "");
        this.getField('valor_total_item').setError(false, "");
        this.getField('descuento_general').setError(false, "");
      }
      this.retornaBodegasPorCodigoFacturacion();
    }

  }

  loadPreciosCliente() {
    //cuando se llama esta funcion, se buscan los precios de lista para este cliente en especifico
    if (this.getField("codigo_id_producto").getValue() !== "" && this.getField("codigo_producto").getValue() !== "") {
      /*if(localStorage.getItem('pmvLogin') && localStorage.getItem('sucursal_ingreso')){
        let datos = {
          datos: {
            codigo: this.getField("codigo_producto").getValue(),
            codigo_facturacion: this.getField("codigo_facturacion").getValue(),
            precios: this.getField("precios").getValue(),
            nit: this.getField("nit").getValue(),
            sede: this.getField("sede").getValue(),       
          }
        };
        let generales = {"tipo_servicio": "ferr-articulos", "operacion": "codigoarticulo", "operacion_tipo":"consulta" }
  
        let url = this.pmvUrl.split('/pmvlogin');
        let endpointPmv = `${url[0]}/pmv/api/protected`;
        this.service.send(
            {
                endpoint: endpointPmv,
                method: 'GET',
                body: datos,
                success:this.successLoadPreciosCliente,
                error: this.error_,
                general: generales,
                pmvRequest: true
            }
        );
      }else{*/

      let datos = {
        datos: {
          codigo: this.getField("codigo_producto").getValue(),
          codigo_facturacion: this.getField("codigo_facturacion").getValue(),
          precios: this.getField("precios").getValue(),
          nit: this.getField("nit").getValue(),
          sede: this.getField("sede").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: 'codigoarticulo', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successLoadPreciosCliente,
          error: this.error_,
          general: this.generalFormatPmv
        });
      //}
    }
  }
  successLoadPreciosCliente(data) {
    //si el cliente tiene una lista de precios, se setean los campos de ese articulo con la informacion de esa lista
    if (data.estado_p === 200) {
      //this.getField('codigo_bodega_item').setValue(data.model[0].bodega);
      //this.getField('nombre_bodega').setValue(data.model[0].nombre_bodega);
      this.getField("tarifa_iva_item").setValue(data.model[0].tarifa_iva);
      this.getField("peso_grm").setValue(data.model[0].peso);
      this.getField("precio_lista").setValue(data.model[0].precio);
      this.getField("costo").setValue(data.model[0].costo);
      this.calcularMargen();
      this.getField("unidad").setValue(data.model[0].unidad);
      if (this.getField("precio_lista").getValue() !== '' && this.getField("precio_lista").getValue() !== 0) {
        this.validacionPrecioLista = true;
      } else {
        this.validacionPrecioLista = false;
      }
    }
    this.getField('cantidad_item_520').input.focus();
  }
  loadItemPrecio() {
    //si se llama esta funcion, se envian los datos a un servicio que calcula los totales
    if (this.getField("nombre_producto").getValue() !== "" && this.getField("nombre_producto").valid() &&
      this.getField("cantidad_item_520").getValue() >= 0.01 && this.getField("codigo_producto").valid() &&
      this.getField("precio_lista").valid()) {
      this.validacionPrecioLista = true;
      let datos = {
        datos: {
          codigo: this.getField("codigo_producto").getValue(),
          codigo_facturacion: this.getField("codigo_facturacion").getValue(),
          descuento: this.getField("descuento_item").getValue(),
          cantidad: this.getField("cantidad_item_520").getValue(),
          precio: this.getField("precio_lista").getValue(),
          nit: this.getField('nit').getValue(),
          sede: this.getField('sede').getValue(),
        }
      };

      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'itempedidopreciosivapreferente', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successLoadItemPrecio,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false,
        });
    } else if (this.getField("precio_lista").getValue() === 0 || this.getField("precio_lista").getValue() === null ||
      this.getField("precio_lista").getValue() === '' || this.getField("precio_lista").getValue() === ' ') {
      this.getField("precio_lista").valid();
      this.getField("precio_lista").input.focus();
      this.validacionPrecioLista = false;
    }
  }
  successLoadItemPrecio(data) {
    //si el calculo es correcto, se setean los valores retornado por el servicio en los campos
    if (data.estado_p === 200) {
      this.getField("precio_facturacion").setValue(data.data.precio_sin_iva_con_descuento);
      this.getField("valor_total_item").setValue(data.data.precio_total);
      this.getField("precio_sin_iva_con_descuento").setValue(data.data.precio_sin_iva_con_descuento);
      this.getField("precio_sin_iva").setValue(data.data.precio_sin_iva);
      this.getField("precio_mas_iva").setValue(data.data.precio_con_iva);
      this.getField("valor_iva").setValue(data.data.valor_iva);
      this.loadBodegasItem();
      this.cleanValid();
    }
  }
  loadBodegasItem() {
    //al llamar a esta funcion, se buscan las cantidades en las bodegas de ese item requerido
    if (this.getField("codigo_facturacion").valid() && this.getField("nombre_producto").getValue() !== "") {
      let datos = {
        datos: {
          codigo: this.getField("codigo_producto").getValue(),
          codigo_facturacion: this.getField("codigo_facturacion").getValue(),
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'bodegasmostrar', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successLoadBodegasItem,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false,
        });
    }
  }
  successLoadBodegasItem(data) {
    //si el item tiene existencia en las bodegas, se carga la tabla de bodegas_items
    //cargar tabla
    // this.validarDespachoMinimo();
    if (data.estado_p === 200) {
      this.gridOptionsBodegasItem["rowData"] = data.model;
      let configCell = new Map();
      this.getField("tabla_bodega").initData(this.gridOptionsBodegasItem, configCell);
    } else {
      //de lo contrario, no se muestra la tabla de bodegas_item
      this.getField("tabla_bodega").toggle(false);
    }
  }
  validarDespachoMinimo() {
    if (this.getField('codigo_producto').getValue() !== '' && this.getField('cantidad_item_520').getValue() !== '' &&
      this.getField('cantidad_item_520').getValue() >= 0.01 && this.getField('codigo_bodega_item').getValue() !== '' && this.getField('cantidad_item_520').valid()) {
      let datos = {
        datos: {
          codigo_producto: this.getField("codigo_producto").getValue(),
          cantidad: this.getField("cantidad_item_520").getValue(),
          codigo_bodega: this.getField("codigo_bodega_item").getValue(),
          sucursal_ingreso: this.sucursal_ingreso
        }
      };
      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'validarunidadminimadespacho', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successValidarDespachoMinimo,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false,
        });
    }
  }
  successValidarDespachoMinimo(data) {
    if (data.estado_p === 200) {
      this.cantidadDespacho = true;
      this.loadItemPrecio();
      this.cleanValid();
    } else {
      this.cantidadDespacho = false;
      this.getField("boton_agregar_item").setDisabled(true);
      this.getField('cantidad_item_520').setValue('');
      this.getField('precio_facturacion').setValue('');
      this.getField('valor_total_item').setValue('');
      this.getField('cantidad_item_520').input.focus();
      this.getField('confirmModalCustom').setTitleAndContent('Cantidad', data.data.mensaje);
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }
  }
  crearPedido() {
    this.getField("boton_grabar").setDisabled(true);
    this.confirmCloseAlertModal();
    this.valorPedido = 0;
    this.totalIvaItems = 0;
    this.subtotalItems = 0;
    this.cantidad = 0;
    let mensaje = '';
    //al llamar a esta funcion, se envian los datos del pedido a un servicio para ser creado
    if (this.nuevoArray !== "") {
      let numero_de_items = this.nuevoArray.length;
      let valor_pedido = 0;
      let subtotalSinIva = 0;
      let totalIvaItems = 0;
      this.nuevoArray.forEach((item) => {
        //Se recorre el array
        //y se acumulan en las variables los totales de los items
        valor_pedido += item.precio_total;
        subtotalSinIva += item.precio_sin_iva_con_descuento * item.cantidad;
        totalIvaItems += (((item.precio_sin_iva_con_descuento * item.cantidad) * item.tarifa_iva) / 100);
        //this.validarPreciosItems(item);
      });
      if (this.itemEnEdicion !== '') {
        mensaje = `Terminado con Éxito, No guardo el ultimo el item ${this.itemEnEdicion} que estaba modificando`;
      } else {
        mensaje = "Terminado con Éxito"
      }
      this.getField("boton_grabar").setDisabled(false);
      this.getField("confirmModalCustom").setTitleAndContent("Calcular Totales", mensaje); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
      this.getField("confirmModalCustom").setClickDialog(this.confirmCloseAlertModal);
      this.getField("confirmModalCustom").toggle(true);
      this.getField("confirmModalCustom").setVisibleCancel(true);
      this.alertGeneral.toggle(false, 'Procesando... por favor espere', "warning");
      this.getField("subtotal").setValue(subtotalSinIva.toFixed(2)); //por ultimo los seteamos en los campos respectivos
      this.getField("valor_iva_final").setValue(totalIvaItems.toFixed(2));
      this.getField("valor_pedido").setValue(valor_pedido.toFixed(2));
      this.getField("numero_de_item").setValue(numero_de_items);
    }
    //this.getField("valor_iva_final").setValue(0);
    //this.getField("valor_pedido").setValue(0);
    //this.getField("subtotal").setValue(0);
  }

  /* validarPreciosItems(item){
    let body = {
      codigo: item.codigo,
      codigo_facturacion: item.codigo_facturacion,
      descuento: item.descuento,
      cantidad: item.cantidad,
      precio: item.precio,
      nit: this.getField('nit').getValue(),
      sede: this.getField('sede').getValue(),
    };
    this.generalFormat.nombre_accion = "520-Retorna_items_pedido_precio";
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method: "GET",
      body: body,
      success: this.successValidarPreciosItems,
      error: this.error_,
      general: this.generalFormat,
      showMessage: false,
    });
  }
  successValidarPreciosItems(data){
    if(data.estado_p === 200){
      this.alertGeneral.toggle(true,'Procesando... por favor espere',"warning");
      this.nuevoArray.map((item)=>{
        if(item.precio_sin_iva === data.data.precio_sin_iva &&
          item.precio_mas_iva === data.data.precio_con_iva &&
          item.precio_total   === data.data.precio_total){
            this.valorPedido += data.data.precio_total;
            this.totalIvaItems += data.data.valor_iva * item.cantidad //* this.cantidadItem;
            this.subtotalItems += data.data.precio_sin_iva * item.cantidad //* this.cantidadItem;
            this.arrayContarItems.push(data.data);
        }
        return null;
      });
      if(this.arrayContarItems.length < this.nuevoArray.length){
        this.getField("boton_grabar").setDisabled(true);
      }else{
        let mensaje = ''
        if(this.itemEnEdicion !== ''){ 
          mensaje = `Terminado con Éxito, No guardo el ultimo el item ${this.itemEnEdicion} que estaba modificando`;
        }else{
          mensaje = "Terminado con Éxito"          
        }                
        this.getField("boton_grabar").setDisabled(false);
        this.getField("confirmModalCustom").setTitleAndContent(
          "Calcular Totales",mensaje); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
        this.getField("confirmModalCustom").setClickDialog(this.confirmCloseAlertModal);
        this.getField("confirmModalCustom").toggle(true);
        this.getField("confirmModalCustom").setVisibleCancel(true);        
        this.alertGeneral.toggle(false,'Procesando... por favor espere',"warning");
      }

      this.getField("valor_iva_final").setValue(this.totalIvaItems.toFixed(2));
      this.getField("valor_pedido").setValue( this.valorPedido);
      this.getField("subtotal").setValue(this.subtotalItems.toFixed(2));
      this.getField("boton_grabar").setDisabled(false);
    }else{
      this.getField("valor_iva_final").setValue(0);
      this.getField("valor_pedido").setValue(0);
      this.getField("subtotal").setValue(0);
      this.getField("boton_grabar").setDisabled(true);
    }
  } */

  savePedido() {
    //Si se llama a esta funcion, se preparan los array para ser enviado al servicio que crea finalmente el pedido
    this.itemEnEdicion = '';
    if (this.getField("observaciones").valid() && this.getField("subtotal").valid() && this.getField("valor_pedido").valid() &&
      this.getField("numero_de_item").valid() && this.getField("clase_de_pedido").valid() &&
      this.getField("orden_de_compra").valid()) {
      //si hay valores en este campo, se crean el Json del servicio
      this.getField("boton_grabar").setDisabled(true);
      let objetoDatos = {
        nit: this.getField("nit").getValue(),
        sede: this.getField("sede").getValue(),
        nombre: this.getField("nombre_cliente").getValue(),
        direccion: this.getField("direccion").getValue(),
        codigo_ciudad: this.getField("codigo_ciudad").getValue(),
        valor: this.getField("valor_pedido").getValue(),
        codigo_vendedor: this.getField("codigo_vendedor").getValue(),
        estado: "Z",
        observaciones: this.getField("observaciones").getValue(),
        vence: this.getField("fecha_vencimiento").getValue(),
        vigencia: this.getField("fecha_validez").getValue(),
        lista_precios: this.getField("precios").getValue(),
        codigo_facturacion: this.getField("codigo_facturacion").getValue(),
        fdespacho: this.getField("fecha_entrega").getValue(),
        sucursal_origen: this.getField("sucursal_origen").getValue(),
        sucursal_cartera: this.getField("sucursal_cartera").getValue(),
        fecha_entrega: this.getField("fecha_entrega").getValue(),
        direccion_entrega: this.getField("direccion").getValue(),
        clase_pedido: this.getField("clase_de_pedido").getValue(),
        orden_compra: this.getField("orden_de_compra").getValue(),
        tasa_cambio: this.getField("tasa_cambio").getValue(),
      };
      //this.datosArray.push(objetoDatos);

      //this.datosArray.push(objetoDatos);

      let datos = {
        datos: {
          //se envia el objeto con los datos principales del pedido y el array con los datos de los items agregados
          pedido: objetoDatos,
          items: this.nuevoArray,
          email_imprimir: this.email_imprimir,
          sucursal_ingreso: this.sucursal_ingreso,
        }
      };

      this.generalFormatPmv = { tipo_servicio: 'fact-pedidosmercancia', operacion: 'crear', operacion_tipo: 'crear' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'POST',
          body: datos,
          success: this.successSavePedido,
          error: this.error_,
          general: this.generalFormatPmv
        });
    }
  } //si el pedido es creado con exito, se limpian todos los campos
  successSavePedido(data) {
    this.getField("boton_grabar").setDisabled(false);
    if (data.estado_p === 200) {
      this.clearCampos();
      this.clearCamposProducto();
      this.getField("nit").setValue("");
      this.getField("sede").setValue("");
      this.getField("nombre_cliente").setValue("");
      this.getField("observaciones").setValue(".");
      this.getField("subtotal").setValue("");
      this.getField("valor_iva_final").setValue("");
      this.getField("valor_pedido").setValue("");
      this.getField("numero_de_item").setValue("");
      this.getField("clase_de_pedido").setValue("");
      this.getField("orden_de_compra").setValue(".");
      this.getField("orden_cotizacion").setValue("");
      this.gridOptionsBodegasItem["rowData"] = []; //se limpia la tabla
      this.getField("tabla_bodega").toggle(false); //se oculta la tabla
      this.nuevoArray = []; //y se limpa el array principal para volver a ser cargados con un pedido nuevo
      let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
      this.getField("direccion").setOptions(opciones);
      let fechaLocal = new Date();
      let diasVigencia = 30;
      this.setDiasVigencia(fechaLocal, diasVigencia);
      this.getField("confirmModalCustom").setTitleAndContent("Cotización Mercancías", data.data.mensaje); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
      this.getField("confirmModalCustom").setClickDialog(this.confirmCloseAlertModal);
      this.getField("confirmModalCustom").toggle(true);
      this.getField("confirmModalCustom").setVisibleCancel(true);
    }
  }

  traerCorreo(){
    let datos = {
      datos: {
        //se envia el objeto con los datos principales del pedido y el array con los datos de los items agregados
        nit: this.getField("nit").getValue(),
        sede: this.getField("sede").getValue(),
      }
    };

    this.generalFormatPmv = { tipo_servicio: 'fact-cotizacionmercancia', operacion: 'traercorreo', operacion_tipo: 'consulta' };
    this.service.send(
      {
        endpoint: this.constant.formConfiguration(),
        method: 'GET',
        body: datos,
        success: this.successtraerCorreo,
        error: this.error_,
        general: this.generalFormatPmv
      });
  }

  successtraerCorreo(data){
    if (data.estado_p === 200 ){
        this.getField('email_imprimir').setValue(data.data[0].email);
    }else {
      this.alertGeneral.toggle(true, 'No se encontro un correo para el nit: '+this.getField("nit").getValue(), 'error');
    }
  }

  mostrarMensajeAdicional(mensaje){
      this.getField('confirmModalCustom').toggle(false);
      this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeGuardando(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{});  
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }


}
FormJaivana.addController("fact-cotizacionmercancia", CustomCotizacionesMercancia);
export default CustomCotizacionesMercancia;