import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @author Anderson Acevedo Briñez
 * @updateBy Sebastian Rios Echeverri
 * @version: jdesk_1.01.0001
*/
/**
 * CustomCertificadoRetencionIva
**/
class CustomCertificadoRetencionIva extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm = this.initForm.bind(this);
       this.arrayObjetos=new Map();
       this.arrayCampos=new Map();
       this.bHayErrores=false;
       this.bHayError=false;
       this.gridOptionsComponentes = Object.assign({},this.gridOptions);
       this.gridOptionsComponentes['onSelectionChanged']= this.onSelectionChanged;
       this.gridOptionsComponentesSoportes = Object.assign({},this.gridOptions);
       this.gridOptionsComponentesSoportes['onSelectionChanged']= this.onSelectionChanged;
       this.onSelectionChanged=this.onSelectionChanged.bind(this);
       this.onSelectionChangedSoportes=this.onSelectionChangedSoportes.bind(this);
       this.validarCampos=this.validarCampos.bind(this);
       this.ocultarTabla=this.ocultarTabla.bind(this);
       this.validarFechas=this.validarFechas.bind(this);
       this.datosTabla=[];
       this.consultarConNit = this.consultarConNit.bind(this);
       this.successconsultarConNit = this.successconsultarConNit.bind(this);
       this.validarCamposFecha = this.validarCamposFecha.bind(this);
       this.validarCuenta   =   this.validarCuenta.bind(this);
       this.habilitarBotones   =   this.habilitarBotones.bind(this);
       this.deshabilitarBotones   =   this.deshabilitarBotones.bind(this);
       this.mostrarModal   =   this.mostrarModal.bind(this);
       this.successMostrarModal   =   this.successMostrarModal.bind(this);
       this.enviarCorreo   =   this.enviarCorreo.bind(this);
       this.successEnviarCorreo   =   this.successEnviarCorreo.bind(this);
       this.limpiarCampos   =   this.limpiarCampos.bind(this);
       this.operacion='';
       this.codigo_sucursal='';
       this.fecha_inicio='';
       this.fecha_actual='';
       this.formatNumberSaldo   =   this.formatNumberSaldo.bind(this);
       this.currencyFormattervalor   =   this.currencyFormattervalor.bind(this);
       this.currencyFormatterxvalor   =   this.currencyFormatterxvalor.bind(this);
       this.currencyFormatterbase  =   this.currencyFormatterbase.bind(this);
       this.deshabilitarCorreo   =   this.deshabilitarCorreo.bind(this);
       this.habilitarCorreo   =   this.habilitarCorreo.bind(this);
       this.generarPdf   =   this.generarPdf.bind(this);
       this.crearTablaAcumulados   =   this.crearTablaAcumulados.bind(this);
       this.sucessCrearTablaAcumulados   =   this.sucessCrearTablaAcumulados.bind(this);
       this.operacion_actual='';
    }

    initForm() {

       console.log("Formulario CustomCertificadoRetencionIva,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez, @updateBy: Sebastian Rios Echeverri");
       this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
       let hoy= new Date();
       let dia=hoy.getDate();
       let mes=hoy.getMonth()+1;
       let año=hoy.getFullYear();
       if(mes<10){
           mes='0'+mes;
       }
       if(dia<10){
         dia='0'+dia;
       }
       this.fecha_inicio=año+'-'+mes+'-01';
       this.fecha_actual=año+'-'+mes+'-'+dia;
       this.getField('consultar_desde').setValue(this.fecha_inicio);
       this.getField('radio_correo').setValue("N");
       this.getField('generar').setClick(()=>{this.operacion='generar';this.validarCampos();});
       this.getField('soportes').setClick(()=>{this.operacion='soportes';this.validarCampos();});
       this.getField('acumulados').setClick(()=>{this.operacion='acumulados';this.validarCampos();});
       this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
       this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);
       this.getField('tercero_id').setOnChange(()=>{
            this.ocultarTabla();
            if(this.getField('tercero_id').getValue()!==''){
                this.consultarConNit();
            }
            
        });

        this.getField('cuenta_id').setOnChange(()=>{
            if(this.getField('cuenta_id').getValue()!==''){
                this.validarCuenta();
            }
            
        });

          this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
              this.getField('ch_pdf').setValueSwitch (false);
              this.operacion_actual='pantalla';
              this.deshabilitarCorreo();
            }else{
              this.habilitarCorreo();
            }
          });
    }

    crearTablaAcumulados(){
        let errores=false;
        if(this.operacion_actual!=='pantalla'){
            if(!this.getField('email').valid())
                errores=true;
        }
        if(errores===false){
            let datos = {  datos: { 
                consultar_desde:this.getField('consultar_desde').getValue(),
                consultar_hasta:this.getField('consultar_hasta').getValue(),
                codigo_sucursal:this.codigo_sucursal,
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-certificadoretencioniva', operacion: 'crearTablaAcumulados', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessCrearTablaAcumulados,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    sucessCrearTablaAcumulados(data)
    {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            if(this.operacion_actual!=='pantalla'){
                if(this.getField('radio_correo').getValue()==='S')
                    this.enviarCorreo();
                else
                    this.generarPdf();
            }
            else{
                this.mostrarModal();
            } 
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }


    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    onSelectionChanged(){
        
    }

    onSelectionChangedSoportes(){
        
    }

    validarCuenta(){
        if(this.getField('codigo_cuenta').valid()  && this.getField('nombre_cuenta').valid()){
            if(this.getField('cuenta_reteiva').getValue()==='S'){

            }else{
                this.getField('codigo_cuenta').setValue('');
                this.getField('nombre_cuenta').setValue('');
                this.alertGeneral.toggle(true,'* Es necesario seleccionar una cuenta que maneje cuenta_reteiva.',"error");
            }
        }
    }

    validarCamposFecha(){
        let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
        let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
        if (fechaInicial > fechaFinal) {
            this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
            return false;
        } else if (fechaFinal >= fechaInicial) {
            if(fechaInicial.split('-')[0]===fechaFinal.split('-')[0]){
                this.getField('consultar_hasta').setError(false, "");
                return true;
            }else{
                this.getField('consultar_hasta').setError(true, "* Las fechas tienen que ser del mismo año.");
                return false;
            }
        }
    }

    //*** definir los botones y los success
    validarCampos()
    {
        //acumulados solo email y fechas
        //generar nit opcional,   fechas,cuenta,email requeridos
        ///soporte nit opcional , fechas,cuenta requeridos
        this.getField('nit').setError(false,'');
        this.getField('consultar_hasta').setError(false,'');
        this.getField('consultar_desde').setError(false,'');
        this.getField('codigo_cuenta').setError(false,'');
        this.getField('nombre_cuenta').setError(false,'');
        this.getField('tarifa').setError(false,'');
        if(this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf o Pantalla.', 'error');
        }else{
            if(this.getField('consultar_hasta').valid() && this.getField('consultar_desde').valid()) {
                if(this.getField('consultar_hasta').getValue()>=this.getField('consultar_desde').getValue()){
                   if(this.validarFechas(this.getField('consultar_desde').getValue(),this.getField('consultar_hasta').getValue())===false){
                         let errores=false;
 
                         if(this.getField('nit').getValue()!==''){
                             if(!(this.getField('nit').valid() && this.getField('tercero_id').getValue()!=='' && this.getField('tercero_id').getValue()!==' ' )){
                                 errores=true;
                             }
                         }
                         if(errores===false){
                             if(this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
                                 errores++;
                                 this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
                                 this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
                                 this.operacion_actual='';
                                 this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf o Pantalla.', 'error');
                             }else{
                                 
                             }
                             if(this.operacion==='generar'){
                                if(this.getField('codigo_cuenta').valid()  && this.getField('nombre_cuenta').valid()
                                && this.getField('tarifa').valid() && this.getField('tarifa').getValue() >= 0.01){
                                    this.getField('tarifa').setError(false, '');
                                    if(this.operacion_actual!=='pantalla'){
                                        if(this.getField('email').valid()){
                                            if(this.getField('radio_correo').getValue()==='S')
                                                this.enviarCorreo();
                                            else
                                                this.generarPdf();
                                        }
                                    }else{
                                        this.mostrarModal();
                                    }
                                }else if(this.getField('tarifa').getValue() < 0.01){
                                    this.getField('tarifa').setError(true, '* El valor mínimo para tarifa, debe ser 0.01');
                                } 
                             }else if(this.operacion==='soportes'){
                                 if(this.getField('codigo_cuenta').valid()  && this.getField('nombre_cuenta').valid()){
                                     if(this.operacion_actual!=='pantalla'){
                                        if(this.getField('email').valid()){
                                            if(this.getField('radio_correo').getValue()==='S')
                                                this.enviarCorreo();
                                            else
                                                this.generarPdf();
                                        }
                                    }else{
                                         this.mostrarModal();
                                     }
                                 } 
                             }else{
                                 //acumulados solo necesita las fechas, nit es opcional
                                if(this.operacion_actual!=='pantalla'){
                                    if(this.getField('email').valid()){
                                        if(this.getField('radio_correo').getValue()==='S'){
                                            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se esta generando la información... `);
                                            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                                            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                                            this.getField('confirmModalCustom').toggle(true);
                                        }else{
                                            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Por favor espere. Si el archivo es demasiado grande, al finalizar se enviará el certificado en PDF al correo: `+this.getField('email').getValue());
                                            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                                            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                                            this.getField('confirmModalCustom').toggle(true);
                                        }
                                    }
                                }
                                else{
                                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se esta generando la información... `);
                                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                                    this.getField('confirmModalCustom').toggle(true);
                                } 
                                this.crearTablaAcumulados();
                             }
                         }else{
                             this.getField('nit').setError(true,'* Es necesario buscar un NIT valido');
                         }
                             
                     }else{
                         this.getField('consultar_hasta').setError(true, "* Las fechas tienen que ser del mismo año.");
                     }              
                }
                else{ 
                    this.getField('consultar_hasta').setError(true,'¡Fecha hasta, debe ser mayor o igual a fecha desde!');
                }
             }
              else{
                  this.alertGeneral.toggle(true,'Es necesario llenar los campos requeridos.',"error");
             }
        }
    }


    consultarConNit(){
        let datos={ 
                datos: { 
                    value: this.getField('nit').getValue()
                }};

        this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successconsultarConNit,
                error: this.error_,
                general: this.generalFormatPmv
        });     
    }

    successconsultarConNit(data){
        if (data.estado_p === 200){
             this.gridOptionsComponentes['rowData'] = [];
             let configCell = new Map();
             this.getField('tabla_nits').toggle(true);
             this.gridOptionsComponentes['rowData'] = data.data;
             this.getField('tabla_nits').initData(this.gridOptionsComponentes,configCell);
        } else {
            this.getField('tabla_nits').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            } else {

                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
       }
    }


    ocultarTabla()
    {
        this.getField('tabla_nits').toggle(false);
    }



    validarFechas(fecha1,fecha2){
        let anio = parseInt(fecha2.split('-')[0]);
        let anio2 = parseInt(fecha1.split('-')[0]);
        if(anio===anio2){
          return false;
        }else{
           return true;
        }
      }
    deshabilitarBotones(){
        this.getField('generar').setDisabled(true);
        this.getField('soportes').setDisabled(true);
        this.getField('acumulados').setDisabled(true);
    }

    habilitarBotones(){
        this.getField('generar').setDisabled(false);
        this.getField('soportes').setDisabled(false);
        this.getField('acumulados').setDisabled(false);
    }

    mostrarModal(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se esta generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.deshabilitarBotones();
        let datos = { datos: {
          consultar_desde:this.getField('consultar_desde').getValue(),
          consultar_hasta:this.getField('consultar_hasta').getValue(),
          codigo_cuenta:this.getField('codigo_cuenta').getValue(),
          codigo_sucursal:this.codigo_sucursal,
          operacion:this.operacion_actual
        }};
        if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' ')
            datos.datos['nit']=this.getField('nit').getValue();
        if(this.getField('email').getValue()!=='' && this.getField('email').getValue()!==' ')
            datos.datos['email']=this.getField('email').getValue();
        if(this.getField('tarifa').getValue()!=='' && this.getField('tarifa').getValue()!==' ')
            datos.datos['tarifa']=this.getField('tarifa').getValue();
        let operacion1='';
        if(this.operacion==='generar'){
            operacion1='enviarPdfCertificado';
        }else if(this.operacion==='acumulados'){
            operacion1='enviarPdfCertificadoAcumulado';
        }else{
            operacion1='modalCertificadoSoportes';
        }


        this.generalFormatPmv = { tipo_servicio: 'cont-certificadoretencioniva', operacion: operacion1, operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successMostrarModal,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
      }

      successMostrarModal(data){
        this.getField('confirmModalCustom').toggle(false);
        this.habilitarBotones();
        if (data.estado_p === 200){
            this.getField('tabla_soportes').toggle(false);
            this.getField('modal_soportes').handleClickOpen();
            this.gridOptionsComponentesSoportes['rowData'] = [];
            let datosFicha = [];
            let data2 = 
                    {
                        "empresa-nit": data.data.lista.empresanit,
                        "datos": data.data.lista.datos,
                        "titulo": data.data.lista.titulo,
                        "periodo": data.data.lista.periodo,
                    };
            datosFicha.push(data2);
            this.getField('lista_titulos').setItemsFichas(datosFicha);

            let configCell = new Map();
            if(this.operacion==='generar'){
                configCell.set('xvalor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterxvalor});
                configCell.set('base',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterbase});
            }else if(this.operacion==='acumulados'){
                configCell.set('xvalor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterxvalor});
                configCell.set('base',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterbase});
            }else{
                configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattervalor});
            }
            
            this.getField('tabla_soportes').toggle(true);
            this.gridOptionsComponentesSoportes['rowData'] = data.data.datos;
            this.getField('tabla_soportes').initData(this.gridOptionsComponentesSoportes,configCell);
            
            
            this.limpiarCampos();
        } else {
           this.getField('tabla_soportes').toggle(false);
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'No hay datos.', 'error');
           } else {

               let respuesta=Object.values(data.data.errores);
               let keys=Object.keys(data.data.errores);
               //this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
               this.alertGeneral.toggle(true, '('+keys+') - '+ respuesta, 'error');
            }
        }
      }

      formatNumberSaldo(number)
    {
        let valor=Number(number).toString().split('.');
            return valor[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    currencyFormattervalor(data)
    {
            let decimal = (data.data.valor + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.valor) + "," + decimal.substring(0, 2));
             } else {
             return this.formatNumberSaldo(data.data.valor); }
    }

    currencyFormatterxvalor(data)
    {
            let decimal = (data.data.xvalor + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.xvalor) + "," + decimal.substring(0, 2));
             } else {
             return this.formatNumberSaldo(data.data.xvalor); }
    }

    currencyFormatterbase(data)
    {       
            let decimal = (data.data.base + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.base) + "," + decimal.substring(0, 2));
             } else {
             return this.formatNumberSaldo(data.data.base); }
    }

      enviarCorreo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se esta generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.deshabilitarBotones();
        let op='enviarPdfCertificado';
        if(this.operacion==='acumulados')
            op='enviarPdfCertificadoAcumulado';
        else if(this.operacion==='soportes')
            op='modalCertificadoSoportes';
        let datos = { datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
            codigo_cuenta:this.getField('codigo_cuenta').getValue(),
            codigo_sucursal:this.codigo_sucursal,
            email:this.getField('email').getValue(),
            tarifa:this.getField('tarifa').getValue(),
            operacion:this.operacion_actual,
            radio_correo:this.getField('radio_correo').getValue(),
          }};
        if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' ')
            datos.datos['nit']=this.getField('nit').getValue();

        this.generalFormatPmv = { tipo_servicio: 'cont-certificadoretencioniva', operacion: op, operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successEnviarCorreo,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage:false
          });
      }

    
      successEnviarCorreo(data){
        this.habilitarBotones();
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.alertGeneral.toggle(true, 'Email enviado exitosamente..', 'success');
            this.limpiarCampos();
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            //this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            this.alertGeneral.toggle(true, '('+keys+') - '+ respuesta, 'error');
        }
     }

     limpiarCampos(){
        this.getField('nit').setValue('');
        this.getField('tercero_id').setValue('');
        this.getField('tercero_id').setValue('');
        this.getField('codigo_cuenta').setValue('');
        this.getField('nombre_cuenta').setValue('');
        this.getField('tarifa').setValue('');
        this.getField('email').setValue('');
        this.getField('consultar_desde').setValue(this.fecha_inicio);
        this.getField('consultar_hasta').setValue(this.fecha_actual);
        this.getField('consultar_desde').setError(false,'');
        this.getField('consultar_hasta').setError(false,'');
        this.habilitarCorreo();
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
     }

     generarPdf()
    {
            let url = this.constant.formConfiguration();
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Por favor espere. Si el archivo es demasiado grande, al finalizar se enviará el certificado en PDF al correo: `+this.getField('email').getValue());
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            //this.deshabilitarBotones();
            let operacion='enviarPdfCertificado';
            if(this.operacion==='acumulados')
            operacion='enviarPdfCertificadoAcumulado';
            else if(this.operacion==='soportes')
            operacion='modalCertificadoSoportes';
            let datos2= {
                    consultar_desde:this.getField('consultar_desde').getValue(),
                    consultar_hasta:this.getField('consultar_hasta').getValue(),
                    codigo_cuenta:this.getField('codigo_cuenta').getValue(),
                    codigo_sucursal:this.codigo_sucursal,
                    email:this.getField('email').getValue(),
                    tarifa:this.getField('tarifa').getValue(),
                    operacion:this.operacion_actual,
                    radio_correo:this.getField('radio_correo').getValue()
            };
            if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' ')
                datos2['nit']=this.getField('nit').getValue();

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-certificadoretencioniva","operacion":"'+operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                // console.log("estado---> ", response);
                /* if(response.status!==200){
                    return '';
                } */
                if(response.status===502){
                    this.alertGeneral.toggle(true,'El valor del campo tarifa es superior al permitido','error');
                    // return '';
                }else{
                    this.alertGeneral.toggle(true,'No hay datos','error');
                    // return '';
                }
                const reader = response.body.getReader();
                // console.log("reader---> ", reader);
                // console.log('response.body:\n', response.body.getReader());
                return new ReadableStream({
                start(controller) {
                return pump();
                function pump() {
                        return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;}
                        controller.enqueue(value);
                        return pump();});}}})
            })
            .then(stream => new Response(stream))
            .then(response => 
                response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                            this.limpiarCampos();
                        } 
                    });
                }else{
                    // console.log("response error----> ", blob); 
                    //this.alertGeneral.toggle(true,'No hay datos','error');
                }
            }).catch(err => this.alertGeneral.toggle(true,err,'error'));;
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    //*** fin  los botones y los success



    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }

}
FormJaivana.addController('cont-certificadoretencioniva',CustomCertificadoRetencionIva);
export default CustomCertificadoRetencionIva;


