import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @description: CustomConsultaDocumentos - Custom con funciones adicionales al Formulario
 * @author Cristian Ciro
 * @updateBy Patricia Lopez Sanchez
 * @version jdesk_1.01.0002
 **/
class CustomConsultaDocumentos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                           = this.initForm.bind(this);

        this.consultarDocumentos                = this.consultarDocumentos.bind(this);
        this.successConsulta1                   = this.successConsulta1.bind(this);
        this.changeNumero                       = this.changeNumero.bind(this);
        this.changePedido                       = this.changePedido.bind(this);
        this.changeNit                          = this.changeNit.bind(this);
        this.validarfechas                      = this.validarfechas.bind(this);
        this.changeDate                         = this.changeDate.bind(this);
        this.mostrarBotones                     = this.mostrarBotones.bind(this);
        this.habilitarBotones                   = this.habilitarBotones.bind(this);
        this.gridOptionsDoc                     = Object.assign({},this.gridOptions);
        this.gridOptionsDoc2                    = Object.assign({},this.gridOptions);
        this.gridOptionsXml                     = Object.assign({},this.gridOptions);
        this.gridOptionsModalContabilizar       = Object.assign({},this.gridOptions);
        this.gridOptionsDocumentosRecibidosXml  = Object.assign({},this.gridOptions);
        this.onSelection2                       = this.onSelection2.bind(this);
        this.onSelectionChangedRowTable2        = this.onSelectionChangedRowTable2.bind(this);
        this.selectedRows                       = [];
        this.arrayTemp                          = [];// Esta variable se va a usar para colocar los valores de la tabla "documentos_recibidos_xml" y reemplazarlos en el campo "formula" de la tabla "plantillas_electronicas_gastos_predefinidos" según las variables correspondientes.

        this.enviarAcuse                        = this.enviarAcuse.bind(this);
        this.confirmEnviarAcuse                 = this.confirmEnviarAcuse.bind(this);
        this.successEnviarAcuse                 = this.successEnviarAcuse.bind(this);

        this.enviarMos                          = this.enviarMos.bind(this);
        this.confirmEnviarMoS                   = this.confirmEnviarMoS.bind(this);
        this.enviarAceptacion                   = this.enviarAceptacion.bind(this);
        this.confirmEnviarAceptacion            = this.confirmEnviarAceptacion.bind(this);
        this.enviarReclamo                      = this.enviarReclamo.bind(this);
        this.confirmEnviarReclamo               = this.confirmEnviarReclamo.bind(this);
        this.verXml                             = this.verXml.bind(this);
        this.successVerXml                      = this.successVerXml.bind(this);
        this.verEvento                          = this.verEvento.bind(this);
        this.successVerEvento                   = this.successVerEvento.bind(this);
        this.verPdf                             = this.verPdf.bind(this);
        this.successVerPDF                      = this.successVerPDF.bind(this);
        this.descargarCufe                      = this.descargarCufe.bind(this);
        this.confirmDescargarCufe               = this.confirmDescargarCufe.bind(this);
        this.successCufe                        = this.successCufe.bind(this);
        this.createRequest                      = this.createRequest.bind(this);
        this.successCufe2                       = this.successCufe2.bind(this);
        this.modalContabilizar                  = this.modalContabilizar.bind(this);
        this.successPlantillasElectronicasGastos= this.successPlantillasElectronicasGastos.bind(this);
        this.cerrarModalContabilizar            = this.cerrarModalContabilizar.bind(this);
        this.dibujarTbModalContabilizar         = this.dibujarTbModalContabilizar.bind(this);
        this.successDibujarTbModalContabilizar  = this.successDibujarTbModalContabilizar.bind(this);
        this.documentosRecibidosXml             = this.documentosRecibidosXml.bind(this);
        this.successDocumentosRecibidosXml      = this.successDocumentosRecibidosXml.bind(this);
        this.contabilizar                       = this.contabilizar.bind(this);
        this.successContabilizar                = this.successContabilizar.bind(this);
        this.fechaDocumentoChange               = this.fechaDocumentoChange.bind(this);
        this.successFechaDocumentoChange        = this.successFechaDocumentoChange.bind(this);
        this.valorChange                        = this.valorChange.bind(this);
        this.habilitarBtnConfCont               = this.habilitarBtnConfCont.bind(this);
        this.dibujarTbContabilizar              = this.dibujarTbContabilizar.bind(this);

        this.codigo_sucursal                    = "";
        this.urlCufe                            = "";
        this.responseCufe                       = "";
        this.totalRequest                       = 0;
        this.totalResponse                      = 0;
        this.contadorValor                      = [];
        this.items                              = 0;
        this.valorPlantillas                    = '';
        this.fechaDocumento                     = '';
        this.venceDocumento                     = '';
        this.valorTbModificado                  = '';
        this.baseTbModificado                   = '';
        this.tarifaTbModificado                 = '';
        this.idPegpTbModificado                 = '';
        this.dataTbChange                       = [];
        this.editableCampoValor                 = false;
        this.editableCampoBase                  = true;
        this.editableCampoTarifa                = true;
        this.centroCostoChange                  = this.centroCostoChange.bind(this);
        this.successCentroCostoChamge           = this.successCentroCostoChamge.bind(this);
        this.venceDocumentoChange               = this.venceDocumentoChange.bind(this);
        this.tarifaChange                       = this.tarifaChange.bind(this);
        this.baseChange                         = this.baseChange.bind(this);
        this.manejaDecimales                    = this.manejaDecimales.bind(this);
        this.successManejaDecimales             = this.successManejaDecimales.bind(this);
        this.numeroDecimales                    = 0;
        this.cuentasAjustePesos                 = this.cuentasAjustePesos.bind(this);
        this.successCuentasAjustePesos          = this.successCuentasAjustePesos.bind(this);
        this.cuentasAjustePesosPositiva         = '';
        this.cuentasAjustePesosNegativa         = '';
        this.centroCostoAjustePesos             = '';
        this.dptoInternoAjustePesos             = '';
        this.rangoAjustePesos                   = this.rangoAjustePesos.bind(this);
        this.successRangoAjustePesos            = this.successRangoAjustePesos.bind(this);
        this.rangoAjustePesosDecimales          = 0;
        this.mostrarArchivo                     = this.mostrarArchivo.bind(this); 
        this.datosLista                         = [];
        this.successMostrarArchivo              = this.successMostrarArchivo.bind(this);
        this.ruta_archivo                       = '';
        this.nombre_archivo                     = '';
        this.gridOptionsArchivoPlanoXml         = Object.assign({},this.gridOptions);
        this.contabilizarArchivo                = this.contabilizarArchivo.bind(this);
        this.successContabilizarArchivo         = this.successContabilizarArchivo.bind(this);
        this.cerrarModalContabilizarArchivo     = this.cerrarModalContabilizarArchivo.bind(this);
        this.itemsArchivoPlano                  = [];
        this.documentos_recibidos_id            = '';
        this.verDistribución                    = this.verDistribución.bind(this);
        this.totaldistribucion                  = 0;
        this.basetotaldistribucion              = 0;
        this.successVerDistribucion             = this.successVerDistribucion.bind(this);
        this.gridOptionsTablaItemsDistribucion  = Object.assign({},this.gridOptions);        
        this.aceptarDistribución                = this.aceptarDistribución.bind(this);
        this.gridOptionsTablaItemsDistribucion['onSelectionChanged']                    = this.onSelectionChanged;
        this.onSelectionChanged                                                         = this.onSelectionChanged.bind(this);
        this.valorDistribucionChange                                                    = this.valorDistribucionChange.bind(this);
        this.valorTbDistribucionModificado                                              = '';
        this.idPegpTbDistribucionModificado                                             = '';
        this.successAceptarDistribucion                                                 = this.successAceptarDistribucion.bind(this);
        this.maneja_distribucion                                                        = false;
        this.currencyFormattervalor                                                     = this.currencyFormattervalor.bind(this);
        this.formatNumberValor                                                          = this.formatNumberValor.bind(this);
        this.currencyFormatterbase                                                      = this.currencyFormatterbase.bind(this);
        this.currencyFormattertarifa                                                    = this.currencyFormattertarifa.bind(this);
        this.guardarDistribucion                                                        = this.guardarDistribucion.bind(this);
        this.successGuardarDistribucion                                                 = this.successGuardarDistribucion.bind(this);
    }

    initForm(){
        console.log('Formulario CustomConsultaDocumentos,  @version: jdesk_1.01.0002, @author: Cristian Ciro, @author: Patricia Lopez Sanchez, @author: Santiago Hernández N.')
        this.getField("consultar").setClick(this.consultarDocumentos);

        this.getField('numero').setKeyUp(this.changeNumero);
        this.getField('pedido').setKeyUp(this.changePedido);
        this.getField('nit').setKeyUp(this.changeNit);
        this.getField('desde').setOnChange(this.changeDate);
        this.getField('hasta').setOnChange(this.changeDate);

        this.getField("enviar_acuse").setClick(this.enviarAcuse);
        this.getField("enviar_MoS").setClick(this.enviarMos);
        this.getField("enviar_aceptacion").setClick(this.enviarAceptacion);
        this.getField("enviar_reclamo").setClick(this.enviarReclamo);
        this.getField("ver_xml").setClick(this.verXml);
        this.getField("ver_eventos").setClick(this.verEvento);
        this.getField("ver_pdf").setClick(this.verPdf);
        this.getField("descargar_cufe").setClick(this.descargarCufe);
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.getField('contabilizar').setClick(this.modalContabilizar);
        this.getField('modal_contabilizar').setCloseButton(this.cerrarModalContabilizar);
        this.getField('plantillas').setOnChange(this.dibujarTbModalContabilizar);
        this.getField('btn_confcontabilizacion').setVisible(false);
        this.getField('btn_confcontabilizacion').setClick(this.contabilizar);
        this.getField('total_diferencia').setOnChange(this.habilitarBtnConfCont);
        this.getField("btn_verarchivo").setDisabled(true);
        this.getField('btn_verarchivo').setClick(this.mostrarArchivo);  
        this.getField('btn_contabilizar_archivo').setClick(this.contabilizarArchivo);   
        this.getField('modal_contabilizar_archivo').setCloseButton(this.cerrarModalContabilizarArchivo);
        this.getField('btn_contabilizar_archivo').setVisible(false); 

        this.getField("btn_ver_distribución").setDisabled(true);    
        this.getField("btn_ver_distribución").setClick(this.verDistribución);   
        //this.getField("btn_aceptar_distribucion").setVisible(false);     
        this.getField("btn_aceptar_distribucion").setClick(this.aceptarDistribución);
        this.getField("btn_guardar_distribucion").setClick(this.guardarDistribucion);
        
    }
    
    habilitarBtnConfCont(){
        if (this.getField('total_diferencia').valid() && this.getField('total_diferencia').getValue() !== '' && 
        this.getField('total_diferencia').getValue() !== ' ' && this.getField('total_diferencia').getValue() === 0) {
            this.getField('btn_confcontabilizacion').setVisible(true);
        }else{
            this.getField('btn_confcontabilizacion').setVisible(false);
        }
    }
    
    contabilizar(){
        if (this.getField('plantillas').getValue()!== '') {
            if (this.getField('total_debitos').valid() && this.getField('total_creditos').valid() && this.getField('total_diferencia').valid()) {
                if (this.getField('total_diferencia').getValue() !== '' && this.getField('total_diferencia').getValue() !== ' ' && this.getField('total_diferencia').getValue() === 0) {
                    if (this.getField('desde').getValue() !== '' && this.getField('numero_documento').getValue() !== '' && 
                    this.getField('detalle').getValue() !== '' 
                    && this.getField('plantillas_electronicas_gastos_id').getValue() !== ''
                    && (this.contadorValor.length === this.items)) {
                        let datos = { datos: {
                            codigo_sucursal: JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal,
                            fecha_documento: this.fechaDocumento !== '' ? this.fechaDocumento : this.selectedRows[0].fecha_documento,
                            vence_documento: this.venceDocumento !== '' ? this.venceDocumento : this.selectedRows[0].vence_documento,
                            numero_documento: this.getField('numero_documento').getValue(),
                            codigo_usuario: JSON.parse(localStorage.getItem('res')).codigo,
                            detalle: this.getField('detalle').getValue(),
                            referencia: this.getField('numero_documento').getValue(),
                            item: this.items,
                            plantillas_electronicas_gastos_id: this.getField('plantillas_electronicas_gastos_id').getValue(),
                            sucursal_id: JSON.parse(localStorage.getItem('sucursal_ingreso')).id,
                            documentos_recibidos_id: this.documentos_recibidos_id
                        }};
                        
                        datos['items'] = this.contadorValor;
        
                        // Se coloca "showMessage: false" ya que en la tarea "JQJD-1207" solicitaron que "Cuando contabilizamos en el mensaje de contabilización exitosa cambiar ese fieldAlert por un ConfirmDialog en el cual también indiquemos el numero de documento."
                        this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: 'contabilizar', operacion_tipo: 'crear' };
                        this.service.send(
                        {
                                endpoint: this.constant.formConfiguration(),
                                method:'POST',
                                body: datos,
                                success: this.successContabilizar,
                                error: this.error_,
                                general: this.generalFormatPmv,
                                showMessage: false
                        });
                    }
                }else{
                    this.getField('total_diferencia').setError(true,'Sólo se permite contabilizar, siempre y cuando la diferencia sea igual a 0.');
                }
            }
        }else{
            this.getField('plantillas').setError(true,'Seleccione una plantilla.');
        }
    }

    successContabilizar(data){
        if (data.estado_p === 200) {
            this.getField('confirmModalCustom').setTitleAndContent('Contabilización', `${data.data.mensaje}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('modal_contabilizar').handleClose();
                this.cerrarModalContabilizar();
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    
    documentosRecibidosXml(){
        this.gridOptionsDocumentosRecibidosXml['rowData'] = [];
        this.getField('tb_documentosrecibidosxml').initData(this.gridOptionsDocumentosRecibidosXml);
        this.getField('tb_documentosrecibidosxml').toggle(false);
        if (this.selectedRows.length >= 1) {
            this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
            this.datosAgregar.datos['numero_documento']=this.selectedRows[0].numero_documento.toString();
            this.datosAgregar.datos['nit_documento']=this.selectedRows[0].nit_documento.toString();
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: '1_4', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successDocumentosRecibidosXml,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                }
            );
        }
    }

    successDocumentosRecibidosXml(data){          
        this.basetotaldistribucion =data.data[0].tarifa===0 || data.data[0].tarifa===0.0 || data.data[0].tarifa===0.00 ? Number(data.data[0].baseg + data.data[0].basee).toFixed(2)  : Number((data.data[0].valor)/(data.data[0].tarifa/100)).toFixed(2);        
        this.totaldistribucion = data.data[0].total;

        this.manejaDecimales();
        this.cuentasAjustePesos();
        this.rangoAjustePesos();
        let dataGraficar = [];
        let objectGraficar = {};
        let asignacion = 0;
        let basee = 0;
        let baseg = 0;
        let cargo = 0;
        let total = 0;
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.arrayTemp = [];
            this.valorPlantillas = '';
            if (data.data[0].fecha_vencimiento !== null && data.data[0].fecha_vencimiento !== '') {
                this.venceDocumento = data.data[0].fecha_vencimiento;            
            }
            

            // Se comenta este fragmento de código, ya que en el requerimiento de la tarea "JQJD-905" solicitaron que El valor que tenemos en la segunda tabla debe ser el valor que se muestra arriba en la primer tabla.
            for (let i = 0; i < data.data.length; i++) {
                // Vamos a hallar el valor del total y a reemplazar los datos null que se listan en la tabla (parte 1):
                if (asignacion === 0 || asignacion === '' || asignacion === null) {
                    if (data.data[i].asignacion !== null && data.data[i].asignacion !== '') {
                        asignacion = data.data[i].asignacion;
                    }
                }
                
                if (basee === 0 || basee === '' || basee === null) {
                    if (data.data[i].basee !== null && data.data[i].basee !== '') {
                        basee = data.data[i].basee;
                    }
                }
                
                if (baseg === 0 || baseg === '' || baseg === null) {
                    if (data.data[i].baseg !== null && data.data[i].baseg !== '') {
                        baseg = data.data[i].baseg;
                    }
                }
                
                if (cargo === 0 || cargo === '' || cargo === null) {
                    if (data.data[i].cargo !== null && data.data[i].cargo !== '') {
                        cargo = data.data[i].cargo;
                    }
                }
                
                if (total === 0 || total === '' || total === null) {
                    if (data.data[i].total !== null && data.data[i].total !== '') {
                        total = data.data[i].total;
                    }
                }
            }

            // Vamos a hallar el valor del total y a reemplazar los datos null que se listan en la tabla (parte 2):
            data.data.forEach((item)=>{
                objectGraficar = {
                    asignacion: asignacion,
                    basee: basee,
                    baseg: item.tarifa===0 || item.tarifa===0.0 || item.tarifa===0.00 ? item.baseg : (item.valor)/(item.tarifa/100),
                    cargo: cargo,
                    tarifa: item.tarifa,
                    total: total,
                    valor: item.valor
                };

                dataGraficar.push(objectGraficar);

                let encabezadoBaseeTarifa = `basee${item.tarifa}`;
                let encabezadoBasegTarifa = `baseg${item.tarifa}`;
                let encabezadoIvaTarifa = `iva${item.tarifa}`;
                let encabezadoIvaValor = `valor${item.tarifa}`;

                let dataOp = {};
                dataOp[encabezadoBaseeTarifa] = basee;
                dataOp[encabezadoBasegTarifa] =  item.tarifa===0 || item.tarifa===0.0 || item.tarifa===0.00 ? item.baseg : (item.valor)/(item.tarifa/100);
                dataOp[encabezadoIvaTarifa] = item.valor;
                dataOp[encabezadoIvaValor] = item.valor;

                dataOp["asignacion"] = asignacion;
                dataOp["cargo"] = cargo;
                dataOp["total"] = total;
                this.arrayTemp.push(dataOp);
            });
            
            this.valorPlantillas = data.data[0].valor;

            if (this.selectedRows.length >= 1) {
                let data2 = {
                    "nit": this.selectedRows[0].nit_documento,
                    "sede": this.selectedRows[0].sede,
                    "nombre": this.selectedRows[0].nombre,
                    "numero_del_documento": this.selectedRows[0].numero_documento,
                    "fecha": this.selectedRows[0].fecha_documento,
                    "vence": this.selectedRows[0].vence_documento,
                    "total": "".formatoPrecio(total)
                };
                this.datosLista.push(data2);
                this.getField('listadocumentos').setItemsFichas(this.datosLista);
                this.getField('numero_documento').setValue(this.selectedRows[0].numero_documento);
                this.documentos_recibidos_id=this.selectedRows[0].id;
            }
            this.getField('modal_contabilizar').handleClickOpen();
            this.getField('tb_documentosrecibidosxml').toggle(true);
            this.gridOptionsDocumentosRecibidosXml['rowData'] = dataGraficar;
            let configCell = new Map();
            configCell.set('baseg', { cellRenderer: (props) => { return "".formatoPrecio(props.data.baseg) } });// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
            configCell.set('basee', { cellRenderer: (props) => { return "".formatoPrecio(props.data.basee) } });// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
            configCell.set('total', { cellRenderer: (props) => { return "".formatoPrecio(props.data.total) } });// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
            configCell.set('valor', { cellRenderer: (props) => { return "".formatoPrecio(props.data.valor) } });// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
            configCell.set('asignacion', {headerName: 'Asignación'});
            this.getField('tb_documentosrecibidosxml').initData(this.gridOptionsDocumentosRecibidosXml, configCell);
            if (this.getField('plantillas').getValue() !== '') {
                this.dibujarTbModalContabilizar();
            }
        }else{
            this.alertGeneral.toggle(true, 'No hay registros para este número documento', 'error');
            this.gridOptionsDocumentosRecibidosXml['rowData'] = [];
            this.getField('tb_documentosrecibidosxml').initData(this.gridOptionsDocumentosRecibidosXml);
            this.getField('tb_documentosrecibidosxml').toggle(false);
        }
    }
    
    dibujarTbModalContabilizar(){
        this.editableCampoValor = false;
        this.editableCampoTarifa = true;
        this.editableCampoBase = true;
        this.dataTbChange = [];
        this.gridOptionsModalContabilizar['rowData'] = [];
        this.getField('tb_modal_contabilizar').initData(this.gridOptionsModalContabilizar);
        this.getField('tb_modal_contabilizar').toggle(false);
        this.contadorValor = [];
        this.items = 0;
        this.getField('total_debitos').setVisible(false);
        this.getField('total_debitos').setValue('');
        this.getField('total_debitos').setError(false,'');
        this.getField('total_creditos').setVisible(false);
        this.getField('total_creditos').setValue('');
        this.getField('total_creditos').setError(false,'');
        this.getField('total_diferencia').setVisible(false);
        this.getField('total_diferencia').setValue('');
        this.getField('total_diferencia').setError(false,'');
        this.getField('detalle').setVisible(false);
        this.getField('detalle').setValue('');
        this.getField('detalle').setError(false,'');
        // Se comenta este fragmento de código, ya que en el requerimiento de la tarea "JQJD-905" solicitaron que El valor que tenemos en la segunda tabla debe ser el valor que se muestra arriba en la primer tabla.
        if (this.arrayTemp.length >= 1) {
        // if (this.valorPlantillas !== '') {
            if (this.getField('plantillas').getValue() !== '' && this.getField('plantillas').getValue() !== null &&
            this.getField('plantillas').getValue() !== undefined && this.getField('plantillas').getValue() !== ' ' &&
            this.getField('plantillas').getValue() !== '  ') {
                this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
                this.datosAgregar.datos['plantillas_electronicas_gastos_id']=parseInt(this.getField('plantillas').getValue());
                this.generalFormatPmv = { tipo_servicio: 'cont-configdocumenpredefinidos', operacion: '1_2', operacion_tipo: 'consulta'};
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: this.datosAgregar,
                        success: this.successDibujarTbModalContabilizar,
                        error: this.error_,
                        general: this.generalFormatPmv
                    }
                );
            }
        }else{
            this.documentosRecibidosXml();
        }
    }
    
    successDibujarTbModalContabilizar(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            
            if (data.data[0].ruta_archivo !== '.' && data.data[0].ruta_archivo !== '' &&data.data[0].nombre_archivo !== '.' && data.data[0].nombre_archivo !== ''){
                this.getField("btn_verarchivo").setDisabled(false);
                this.ruta_archivo = data.data[0].ruta_archivo;
                this.nombre_archivo = data.data[0].nombre_archivo;
            }else{
                this.getField("btn_verarchivo").setDisabled(true);
            }

            if (data.data[0].maneja_distribucion !== '.' && data.data[0].maneja_distribucion !== '' &&
                data.data[0].maneja_distribucion !== 'null' && data.data[0].maneja_distribucion !== 'N' && data.data[0].maneja_distribucion !== null){
                this.maneja_distribucion=true;
                this.getField("btn_ver_distribución").setDisabled(false);
            }else{
                this.maneja_distribucion=false;
                this.getField("btn_ver_distribución").setDisabled(true);
            }

            this.getField('total_debitos').setVisible(true);
            this.getField('total_creditos').setVisible(true);
            this.getField('total_diferencia').setVisible(true);
            this.getField('detalle').setVisible(true);
            this.getField('detalle').setValue(`${this.getField('nombre_plantilla').getValue()} ${this.selectedRows[0].numero_documento} ${this.selectedRows[0].fecha_documento} ${JSON.parse(localStorage.getItem('res')).codigo}`);
            this.items = data.data.length;
            this.getField('plantillas_electronicas_gastos_id').setValue(data.data[0].plantillas_electronicas_gastos_id);
            // Se comenta este fragmento de código, ya que en el requerimiento de la tarea "JQJD-905" solicitaron que El valor que tenemos en la segunda tabla debe ser el valor que se muestra arriba en la primer tabla.
            
            for (let i = 0; i < this.arrayTemp.length; i++) {
                let valuesArrayTemp = Object.values(this.arrayTemp[i]);
                let keysArrayTemp = Object.keys(this.arrayTemp[i]);
                let formulaComparar = "";
                let formulaCompararbasee = "";
                let formulaComparartarifa = "";
                for (let e = 0; e < data.data.length; e++) {
                    let formula = data.data[e].formula;
                    let formula_basee = data.data[e].formula_basee;
                    let formula_tarifa = data.data[e].formula_tarifa;
                    for (let u = 0; u < keysArrayTemp.length; u++) {
                        formula = formula.replaceAll(keysArrayTemp[u],Math.round(valuesArrayTemp[u]));               
                        formula_basee = formula_basee.replaceAll(keysArrayTemp[u],Math.round(valuesArrayTemp[u]));                       
                        formula_tarifa = formula_tarifa.replaceAll(keysArrayTemp[u],Math.round(valuesArrayTemp[u]));   

                        if (data.data[e]["valor"] === 'La fórmula no coincide con los datos y variables del XML' || 
                            data.data[e]["valor"] === '' || data.data[e]["valor"] === null  || data.data[e]["valor"] === undefined) {
                            if (!(formula === formulaComparar)) {
                                    formulaComparar = formula;
                                    try {
                                        
                                        // eslint-disable-next-line no-eval
                                        let resultado = eval(formula);
                                        // let resultado = Math.round(eval(formula));

                                        if(isNaN(resultado) || resultado ===Infinity){
                                            data.data[e]["valor"] = "La fórmula no coincide con los datos y variables del XML";                                                                                  
                                        }else{
                                            data.data[e]["valor"] = resultado;
                                        }            
                                        
                                    } catch (error) {
                                        data.data[e]["valor"] = "La fórmula no coincide con los datos y variables del XML"; 
                                    }
                              
                            }else if (formulaComparar === "."){
                                //data.data[e]["basee"] = "La fórmula no coincide con los datos y variables del XML";
                                data.data[e]["valor"] = 0;   
                            }
                        }

                        if (data.data[e]["basee"] === 'La fórmula no coincide con los datos y variables del XML' || 
                            data.data[e]["basee"] === '' || data.data[e]["basee"] === null  || data.data[e]["basee"] === undefined) {
                            if (!(formula_basee === formulaCompararbasee)) {
                                formulaCompararbasee = formula_basee;
                                try {
                                    
                                    // eslint-disable-next-line no-eval
                                    let resultado_basee = eval(formula_basee);
                                    // let resultado_basee = Math.round(eval(formula_basee));

                                    if(isNaN(resultado_basee) || resultado_basee ===Infinity){
                                        data.data[e]["basee"] = "La fórmula no coincide con los datos y variables del XML";                                                                                  
                                    }else{
                                        data.data[e]["basee"] = resultado_basee;
                                    }            
                                    
                                } catch (error) {
                                    data.data[e]["basee"] = "La fórmula no coincide con los datos y variables del XML"; 
                                }
                            }else if (formulaCompararbasee === "."){
                                //data.data[e]["basee"] = "La fórmula no coincide con los datos y variables del XML";
                                data.data[e]["basee"] = 0;   
                            }
                        }

                        if (data.data[e]["tarifa"] === 'La fórmula no coincide con los datos y variables del XML' || 
                            data.data[e]["tarifa"] === '' || data.data[e]["tarifa"] === null  || data.data[e]["tarifa"] === undefined) {
                            if (!(formula_tarifa === formulaComparartarifa)) {
                                formulaComparartarifa = formula_tarifa;
                                try {
                                    
                                    // eslint-disable-next-line no-eval
                                    let resultado_tarifa = eval(formula_tarifa);
                                    // let resultado_tarifa = Math.round(eval(formula_tarifa));

                                    if(isNaN(resultado_tarifa) || resultado_tarifa ===Infinity || data.data[e]["tarifa"] === "."){
                                        data.data[e]["tarifa"] = "La fórmula no coincide con los datos y variables del XML";                                        
                                    }else{
                                        data.data[e]["tarifa"] = resultado_tarifa;
                                    }            
                                    
                                } catch (error) {
                                    data.data[e]["tarifa"] = "La fórmula no coincide con los datos y variables del XML"; 
                                }
                            }else if (formulaComparartarifa === "."){
                                //data.data[e]["tarifa"] = "La fórmula no coincide con los datos y variables del XML";                                          
                                data.data[e]["tarifa"] = 0;
                            }
                        }
                    }
                }
            }
            this.dataTbChange = data;
            this.dibujarTbContabilizar(data);
        }else if (data.estado_p === 404) {
            this.getField('total_debitos').setVisible(false);
            this.getField('total_debitos').setValue('');
            this.getField('total_debitos').setError(false,'');
            this.getField('total_creditos').setVisible(false);
            this.getField('total_creditos').setValue('');
            this.getField('total_creditos').setError(false,'');
            this.getField('total_diferencia').setVisible(false);
            this.getField('total_diferencia').setValue('');
            this.getField('total_diferencia').setError(false,'');
            this.getField('detalle').setVisible(false);
            this.getField('detalle').setValue('');
            this.getField('detalle').setError(false,'');
            this.alertGeneral.toggle(true, 'La plantilla seleccionada, no cuenta con plantillas electrónicas gastos predefinidos', 'error');
            this.gridOptionsModalContabilizar['rowData'] = [];
            this.getField('tb_modal_contabilizar').initData(this.gridOptionsModalContabilizar);
            this.getField('tb_modal_contabilizar').toggle(false);
        }else{
            this.getField('total_debitos').setVisible(false);
            this.getField('total_debitos').setValue('');
            this.getField('total_debitos').setError(false,'');
            this.getField('total_creditos').setVisible(false);
            this.getField('total_creditos').setValue('');
            this.getField('total_creditos').setError(false,'');
            this.getField('total_diferencia').setVisible(false);
            this.getField('total_diferencia').setValue('');
            this.getField('total_diferencia').setError(false,'');
            this.getField('detalle').setVisible(false);
            this.getField('detalle').setValue('');
            this.getField('detalle').setError(false,'');
        }
    }
    
    dibujarTbContabilizar(data){
        this.contadorValor = [];
        let totalDebitos = 0;
        let totalCreditos = 0;

        let totalDebitosFinal = 0;
        let totalCreditosFinal = 0;


        for (let i = 0; i < data.data.length; i++){
            if (data.data[i]["valor"] < 0) {
                totalCreditos += Number(data.data[i]["valor"]);
            }else if (data.data[i]["valor"] > 0){
                totalDebitos += Number(data.data[i]["valor"]);
            }
        }
        
        let total_diferencia = (totalDebitos+totalCreditos).toFixed(this.numeroDecimales);

        if ((total_diferencia>=Number(-(this.rangoAjustePesosDecimales))) && (total_diferencia<0)){
            let itemTabla = {
                nit: data.data[0]["nit"],                
                sede: data.data[0]["sede"],
                fecha_documento: data.data[0]["fecha_documento"],
                vence_documento: data.data[0]["vence_documento"],
                formula: total_diferencia*(-1),
                valor: total_diferencia*(-1),
                formula_basee:'.',
                basee: 0,
                formula_tarifa: '.',
                tarifa: 0,
                codigo_cuenta: Number(this.cuentasAjustePesosNegativa),
                nombre_cuenta: 'AJUSTE AL PESO',
                codigo_centro_costo: this.centroCostoAjustePesos,
                nombre_centro_costo: 'DIRECCION GENERAL',
                codigo_departamento_interno: this.dptoInternoAjustePesos,
                nombre_departamento_interno: 'GENERICO.',

                plantillas_electronicas_gastos_id: data.data[0]["plantillas_electronicas_gastos_id"],
                cuentas_id: data.data[0]["cuentas_id"],
                centros_costo_id: data.data[0]["centros_costo_id"],
                tercero_id: data.data[0]["tercero_id"],
                documentos_id: data.data[0]["documentos_id"],
                departamento_interno_id: data.data[0]["departamento_interno_id"],
                maneja_documento_fuente: data.data[0]["maneja_documento_fuente"],
                tipo_fuente: data.data[0]["tipo_fuente"],
                codigo_documento: data.data[0]["codigo_documento"]

            };
            data.data.push(itemTabla);
            this.items = this.items+1;

        }else if ((total_diferencia>0) && (total_diferencia<=Number(this.rangoAjustePesosDecimales))){
            let itemTabla = {
                nit: data.data[0]["nit"],                
                sede: data.data[0]["sede"],
                fecha_documento: data.data[0]["fecha_documento"],
                vence_documento: data.data[0]["vence_documento"],
                formula: total_diferencia*(-1),
                valor: total_diferencia*(-1),
                formula_basee:'.',
                basee: 0,
                formula_tarifa: '.',
                tarifa: 0,
                codigo_cuenta: Number(this.cuentasAjustePesosPositiva),
                nombre_cuenta: 'AJUSTE AL PESO',
                codigo_centro_costo: this.centroCostoAjustePesos,
                nombre_centro_costo: 'DIRECCION GENERAL',
                codigo_departamento_interno: this.dptoInternoAjustePesos,
                nombre_departamento_interno: 'GENERICO.',

                plantillas_electronicas_gastos_id: data.data[0]["plantillas_electronicas_gastos_id"],
                cuentas_id: data.data[0]["cuentas_id"],
                centros_costo_id: data.data[0]["centros_costo_id"],
                tercero_id: data.data[0]["tercero_id"],
                documentos_id: data.data[0]["documentos_id"],
                departamento_interno_id: data.data[0]["departamento_interno_id"],
                maneja_documento_fuente: data.data[0]["maneja_documento_fuente"],
                tipo_fuente: data.data[0]["tipo_fuente"],
                codigo_documento: data.data[0]["codigo_documento"]                
            };
            data.data.push(itemTabla);
            this.items = this.items+1;
        }        

        for (let e = 0; e < data.data.length; e++) {
            // Solo entra a la validación cuando el campo valor de la tabla, es modificado:
            if (this.valorTbModificado !== '') {
                if (data.data[e]["id"] === this.idPegpTbModificado) {
                    data.data[e]["valor"] = Number(this.valorTbModificado).toFixed(2);
                }
            }else{
                data.data[e]["valor"] = Number(data.data[e]["valor"]).toFixed(2);
            }

            if (this.baseTbModificado !== '') {
                if (data.data[e]["id"] === this.idPegpTbModificado) {
                    data.data[e]["basee"] = Number(this.baseTbModificado).toFixed(2);
                }
            }else{
                data.data[e]["basee"] = Number(data.data[e]["basee"]).toFixed(2);
            }

            if (this.tarifaTbModificado !== '') {
                if (data.data[e]["id"] === this.idPegpTbModificado) {
                    data.data[e]["tarifa"] = Number(this.tarifaTbModificado).toFixed(2);
                }
            }else{
                data.data[e]["tarifa"] = Number(data.data[e]["tarifa"]).toFixed(2);
            }

    
            if (data.data[e]["valor"] === 'La fórmula no coincide con los datos y variables del XML') {
                this.editableCampoValor = false;
            }
            if (data.data[e]["basee"] === 'La fórmula no coincide con los datos y variables del XML') {
                this.editableCampoBase = false;
            }
            if (data.data[e]["tarifa"] === 'La fórmula no coincide con los datos y variables del XML') {
                this.editableCampoTarifa = false;
            }      

            if (this.venceDocumento==='' || this.venceDocumento === null || this.venceDocumento === undefined){
                if (this.selectedRows[0].vence_documento!==''){
                    this.venceDocumento = this.selectedRows[0].fecha_documento;
                }
            }

            data.data[e]["fecha_documento"] = this.fechaDocumento !== '' ? this.fechaDocumento : this.selectedRows[0].fecha_documento;// Para la tarea "JQJD-1207" solicitaron que se debía agregar la columna "Fecha" que es la fecha que se arrastra de la tabla principal del formulario.
            data.data[e]["vence_documento"] = this.venceDocumento !== '' ? this.venceDocumento : this.selectedRows[0].vence_documento;
            if (data.data[e]["valor"] < 0) {
                totalCreditosFinal += Number(data.data[e]["valor"]);
            }else if (data.data[e]["valor"] > 0){
                totalDebitosFinal += Number(data.data[e]["valor"]);
            }
           

        }

        this.contadorValor=data.data;        
        this.getField('tb_modal_contabilizar').toggle(true);   
        this.gridOptionsModalContabilizar['rowData'] = this.contadorValor;
    
        this.dataTbChange = data;
        let configCell = new Map();
        configCell.set('formula', { headerName: 'Fórmula'});
        configCell.set('formula_basee', { headerName: 'Fórmula Base'});
        configCell.set('formula_tarifa', { headerName: 'Fórmula Tarifa'});
        if (this.editableCampoValor) {
            configCell.set('valor',{cellRenderer: (props) => { return "".formatoPrecio(props.data.valor) }, cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)' },onCellValueChanged:this.valorChange,width:100,editable:true, field:'valor'});
        }else{
            configCell.set('valor',{cellRenderer: (props) => { return "".formatoPrecio(props.data.valor) }});
        }
        if (this.editableCampoBase) {
            configCell.set('basee',{cellRenderer: (props) => { return "".formatoPrecio(props.data.basee) }, cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)' },onCellValueChanged:this.baseChange,width:100,editable:true, field:'base'});
        }else{
            configCell.set('basee',{cellRenderer: (props) => { return "".formatoPrecio(props.data.basee) }});
        }
        if (this.editableCampoTarifa) {
            configCell.set('tarifa',{cellRenderer: (props) => { return "".formatoPrecio(props.data.tarifa) }, cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)' },onCellValueChanged:this.tarifaChange,width:100,editable:true, field:'tarifa'});
        }else{
            configCell.set('tarifa',{cellRenderer: (props) => { return "".formatoPrecio(props.data.tarifa) }});
        }

        configCell.set('fecha_documento',{cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)' },onCellValueChanged:this.fechaDocumentoChange,width:100,editable:true, field:'fecha_documento'});
        configCell.set('vence_documento',{cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)' },onCellValueChanged:this.venceDocumentoChange,width:100,editable:true, field:'vence_documento'});
        
        configCell.set('codigo_centro_costo',{cellStyle:{backgroundColor:"rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)' },onCellValueChanged:this.centroCostoChange,width:100,editable:true, field:'codigo_centro_costo'});
        this.getField('tb_modal_contabilizar').initData(this.gridOptionsModalContabilizar, configCell);

        this.getField('total_debitos').setValue((totalDebitosFinal).toFixed(this.numeroDecimales));
        this.getField('total_creditos').setValue((totalCreditosFinal).toFixed(this.numeroDecimales));
        
        let total_diferencia_Final = (totalDebitosFinal+totalCreditosFinal).toFixed(this.numeroDecimales);
        if (total_diferencia_Final < 0){
            total_diferencia_Final = total_diferencia_Final*(-1)
        }
        this.getField('total_diferencia').setValue(Number(total_diferencia_Final).toFixed(this.numeroDecimales));
        this.valorTbModificado = '';
        this.baseTbModificado = '';
        this.tarifaTbModificado = '';
        this.idPegpTbModificado = '';
    }
    
    valorChange(props){
        this.valorTbModificado = props.newValue;
        this.idPegpTbModificado = props.data.id;
        this.dibujarTbContabilizar(this.dataTbChange);
    }

    baseChange(props){
        this.baseTbModificado = props.newValue;
        this.idPegpTbModificado = props.data.id;
        this.dibujarTbContabilizar(this.dataTbChange);
    }

    tarifaChange(props){
        this.tarifaTbModificado = props.newValue;
        this.idPegpTbModificado = props.data.id;
        this.dibujarTbContabilizar(this.dataTbChange);
    }

    fechaDocumentoChange(props){
        this.fechaDocumento = props.newValue;
        let datosAgregar={ datos:{}};
        datosAgregar.datos['id']= this.selectedRows[0].id;
        datosAgregar.datos['fecha_documento']= props.newValue;
        datosAgregar.datos['nit']= this.selectedRows[0].nit_documento;
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: '6', operacion_tipo: 'modificar'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datosAgregar,
                success: this.successFechaDocumentoChange,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    venceDocumentoChange(props){
        this.venceDocumento = props.newValue;
        let datosAgregar={ datos:{}};
        datosAgregar.datos['id']= this.selectedRows[0].id;
        datosAgregar.datos['vence_documento']= props.newValue;
        datosAgregar.datos['nit']= this.selectedRows[0].nit_documento;
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: '6_1', operacion_tipo: 'modificar'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datosAgregar,
                success: this.successFechaDocumentoChange,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    centroCostoChange(props){
        this.centro_costo = props.newValue;
        let datosAgregar={ datos:{}};
        datosAgregar.datos['id']= props.data.id;
        datosAgregar.datos['centro_costo']= this.centro_costo;
        this.generalFormatPmv = { tipo_servicio: 'cont-configdocumenpredefinidos', operacion: 'modificarcentrocosto', operacion_tipo: 'modificar'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datosAgregar,
                success: this.successCentroCostoChamge,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );

    }

    successCentroCostoChamge(data){
        if (data.estado_p === 200) {
            this.dibujarTbModalContabilizar();
        }else{
            this.dibujarTbModalContabilizar();
        }
    }

    successFechaDocumentoChange(data){
        if (data.estado_p === 200) {
            this.dibujarTbModalContabilizar();
        }
    }
    
    cerrarModalContabilizar(){
        this.fechaDocumento = '';
        this.venceDocumento = '';
        this.getField('contabilizar').setVisible(false);
        this.arrayTemp = [];
        this.valorPlantillas = '';
        this.contadorValor = [];
        this.items = 0;
        this.valorTbModificado = '';
        this.baseTbModificado = '';
        this.tarifaTbModificado = '';
        this.idPegpTbModificado = '';
        this.dataTbChange = [];
        this.editableCampoValor = false;
        this.editableCampoBase = true;
        this.editableCampoTarifa = true;
        this.consultarDocumentos();
        this.getField('btn_verarchivo').setDisabled(true);
        this.getField('btn_ver_distribución').setDisabled(true);
        this.maneja_distribucion=false;
        this.datosLista=[];
    }
    
    modalContabilizar(){
        if (this.getField('nit_id').getValue() !== '') {
            this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
            this.datosAgregar.datos['tercero_id']=parseInt(this.getField('nit_id').getValue());
            this.generalFormatPmv = { tipo_servicio: 'cont-asignarplanilla', operacion: '1_2', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successPlantillasElectronicasGastos,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un documento de la tabla principal', 'warning');
            this.getField('plantillas').setVisible(false);
        }
    }
    
    successPlantillasElectronicasGastos(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.detalle;
                dataOp['campos_cambian'] = {nombre_plantilla: item.detalle};
                opciones.push(dataOp);
            });
            this.getField('plantillas').setOptions(opciones);
            this.getField('plantillas').setVisible(true);
            if (data.data.length === 1) {
                this.getField('plantillas').setValue(data.data[0].id);
                this.getField('nombre_plantilla').setValue(data.data[0].detalle);
                this.getField('detalle').setValue(`${data.data[0].detalle} ${this.selectedRows[0].numero_documento} ${this.selectedRows[0].fecha_documento}`);
            }else{
                this.documentosRecibidosXml();
            }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'El tercero seleccionado, no cuenta con plantillas electrónicas gastos', 'error');
            this.getField('plantillas').setVisible(false);
        }
    }
    
    onSelectionChangedRowTable2 = () =>{
        this.selectedRows = [];
        this.selectedRows = this.gridOptionsDoc.api.getSelectedRows();
        this.habilitarBotones(false);

        if (this.selectedRows.length >= 1) {
            if (this.selectedRows[0].estado_contabilizacion === 'CONTABILIZADO'){
                this.getField('contabilizar').setVisible(false);
            }else {
                this.getField('contabilizar').setVisible(true);
                this.getField('nit_id').setValue(this.selectedRows[0].nit_id);
            }
        }else{
            this.getField('contabilizar').setVisible(false);
        }
    }

    onSelection2 = () =>{
        let selectedRowsXml = this.gridOptionsXml.api.getSelectedRows();
        this.getField("text_xml").setValue(selectedRowsXml[0].factura_xml);

    }

    descargarCufe(){
        let datos={ 
            datos: {
                value: this.codigo_sucursal
            }
        };

        this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '1_3', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successCufe,
            error: this.error_,
            general: this.generalFormatPmv
        })
        
    }

    successCufe(data){
        if(data.estado_p === 200){
            this.urlCufe = data.data[0].valor;
            this.getField("modal_cufe").handleClickOpen();
            this.getField("aceptar_cufe").setClick(this.confirmDescargarCufe);
        }
    }

    confirmDescargarCufe(){
        this.responseCufe = '';
        this.totalRequest = 0;
        this.totalResponse = 0;
        if(this.getField("text_cufe").getValue().length > 0){
            this.getField("text_cufe").setError(false,'');
            let valores = this.getField("text_cufe").getValue().split("\n");
            this.totalRequest = valores.length;
            for(let i=0; i<valores.length; i++){
                let item = valores[i];
                if(item !== ''){
                    let url = this.urlCufe+"api/documentos/recibidos/descargar_desde_dian/"+item;
                    this.createRequest(url,this.successCufe2);
                }else
                    this.totalRequest -= 1;
            }
            
        }else{
            this.getField("text_cufe").setError(true,'Este campo acepta como minimo un caracter.');
        }
    }

    successCufe2(data){
        this.totalResponse += 1;
        this.responseCufe += data.data.mensaje+',';
        if(this.totalResponse === this.totalRequest){
            this.alertGeneral.toggle(true,this.responseCufe,'success');
            this.getField("modal_cufe").handleClose();
        }
    }

    createRequest(url, successR){
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
            xhr.setRequestHeader('Content-Type','application/json');
            xhr.onload = function()
            {
                const decoder = new TextDecoder("UTF-8");//new TextDecoder("iso-8859-1");
                const buffer = xhr.response;//new DataView(xhr.response);
                let data ={};
                if(buffer !== undefined && buffer !== null && buffer.byteLength > 0)
                {
                    try
                    {
                        data = JSON.parse(decoder.decode(buffer));
                        data["estado_p"] = xhr.status;
                        
                    }
                     catch (err)
                    {
                        this.alertGeneral.toggle(true, 'Tipo de documento.', 'error'); 
                    }
                }else{
                    data['data'] = {
                        mensaje: "Error descargando archivo"
                    };
                    data["estado_p"] = xhr.status;
                }
                successR(data);
            }
            xhr.send({});
    }

    verXml(){
        let datos={ 
            datos: {
                id: this.selectedRows[0].id
            }
        };

        this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successVerXml,
            error: this.error_,
            general: this.generalFormatPmv
        })
    }

    successVerXml(data){
        if(data.estado_p === 200){
            this.getField('modal_xml').handleClickOpen();
            let configCell = new Map();
            this.gridOptionsXml['rowData'] = data.data;
            this.gridOptionsXml['onSelectionChanged'] =this.onSelection2;
            this.getField('rejilla_xml').initData(this.gridOptionsXml, configCell);
        }
    }

    verPdf(){
        let datos={ 
            datos: {
                value: this.codigo_sucursal
            }
        };

        this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '1_3', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successVerPDF,
            error: this.error_,
            general: this.generalFormatPmv
        })
    }

    successVerPDF(data){
        if(data.estado_p === 200){
            let nombre_archivo = "";
            if(this.selectedRows[0].tipo_documento === "01"){
                nombre_archivo = "factura_recibidas";
                let urlpdf = data.data[0].valor  + "api/generar_pdf/" + this.selectedRows[0].id + "/" + nombre_archivo + "/true";
                window.open(urlpdf, '_blank', 'noreferrer');

            }else{
                this.alertGeneral.toggle(true, 'Tipo de documento debe ser 01.', 'error'); 
            }
        }
    }

    verEvento(){
        let datos={ 
            datos: {
                id: this.selectedRows[0].id
            }
        };

        this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successVerEvento,
            error: this.error_,
            general: this.generalFormatPmv
        })
    }

    successVerEvento(data){
        if(data.estado_p === 200){
            this.getField('modal_eventos').handleClickOpen();
            let configCell = new Map();
            this.gridOptionsDoc2['rowData'] = data.data;
            this.getField('rejilla_eventos').initData(this.gridOptionsDoc2, configCell);
            this.getField("text_xml").setValue(data.data[0].factura_xml);
        }
    }

    enviarReclamo(){
        /*this.getField('confirmModalCustom').setTitleAndContent('Enviar', 'Desea enviar el reclamo del número '+this.selectedRows[0].numero_documento+'?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmEnviarReclamo);
        this.getField('confirmModalCustom').toggle(true);*/
        this.getField("aceptar_reclamo").setClick(this.confirmEnviarReclamo);
        let opciones = [{'text':'Ninguno','value':'null','campos_cambian':{}}];

        opciones.push({
            value:"01",
            text:"Documento con inconsistencias.",
            campos_cambian:{}
        }); 
        opciones.push({
            value:"02",
            text:"Mercancía no entregada totalmente.",
            campos_cambian:{}
        }); 
        opciones.push({
            value:"03",
            text:"Mercancía no entregada parcialmente.",
            campos_cambian:{}
        }); 
        opciones.push({
            value:"04",
            text:"Servicio no prestado.",
            campos_cambian:{}
        }); 

        this.getField('concepto_reclamo').setOptions(opciones);
        this.getField("modal_reclamo").handleClickOpen();
    }

    confirmEnviarReclamo(){
        let nit_doc = this.selectedRows[0].nit_documento;
        let estado_reclamo = this.selectedRows[0].estado_reclamo_dian;
        let estado_acuse = this.selectedRows[0].estado_acuse_dian;
        let estado_aceptacion = this.selectedRows[0].estado_aceptacion_dian;
        let estado_recibo = this.selectedRows[0].estado_recibo_dian;

        if (this.getField('concepto_reclamo').getValue() !== 'null' && this.getField('concepto_reclamo').getValue() !== ''){
            if (estado_acuse === "a" && estado_recibo === "a" && estado_reclamo === "." && estado_aceptacion === ".") {
                let datos={ 
                    datos: {
                        concepto_reclamo:this.getField('concepto_reclamo').getValue(),
                        nit: nit_doc,
                        numero: this.selectedRows[0].numero_documento
                    }
                };
    
                this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '3_4', operacion_tipo: 'modificar' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successEnviarAcuse,
                    error: this.error_,
                    general: this.generalFormatPmv
                })
            }else{
                this.alertGeneral.toggle(true, 'El reclamo Dian para el documento #' + this.selectedRows[0].numero_documento + ' no puede ser enviado.', 'error'); 
            }
        }else {
            
            this.alertGeneral.toggle(true, 'La aceptación Dian para el documento #' + this.selectedRows[0].numero_documento + ' no puede ser enviada. Debe seleccionar un motivo de reclamo.', 'error'); 
        
        }

        this.getField('modal_reclamo').handleClose();
    }

    enviarAceptacion(){
        this.getField('confirmModalCustom').setTitleAndContent('Enviar', 'Quiere enviar la aceptación expresa del documento '+this.selectedRows[0].numero_documento+'?');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').setClickDialog(this.confirmEnviarAceptacion);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmEnviarAceptacion(){
        this.getField('confirmModalCustom').toggle(false);
        let nit_doc = this.selectedRows[0].nit_documento;
        let estado_reclamo = this.selectedRows[0].estado_reclamo_dian;
        let estado_acuse = this.selectedRows[0].estado_acuse_dian;
        let estado_aceptacion = this.selectedRows[0].estado_aceptacion_dian;
        let estado_recibo = this.selectedRows[0].estado_recibo_dian;

        if (estado_acuse === "a" && estado_recibo === "a" && estado_reclamo === "." && estado_aceptacion === "."){
            let datos={ 
                datos: {
                    nit: nit_doc,
                    numero: this.selectedRows[0].numero_documento
                }
            };

            this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '3_3', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successEnviarAcuse,
                error: this.error_,
                general: this.generalFormatPmv
            })
        }else{
            this.alertGeneral.toggle(true, 'La aceptación Dian para el documento #' + this.selectedRows[0].numero_documento + ' no puede ser enviada.', 'error'); 
        } 
    }

    enviarMos(){
        this.getField('confirmModalCustom').setTitleAndContent('Enviar', 'Quiere enviar el recibo de B&S del documento '+this.selectedRows[0].numero_documento+'?');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').setClickDialog(this.confirmEnviarMoS);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmEnviarMoS(){
        this.getField('confirmModalCustom').toggle(false);
        let nit_doc = this.selectedRows[0].nit_documento;
        let estado_acuse = this.selectedRows[0].estado_acuse_dian;
        let estado_recibo = this.selectedRows[0].estado_recibo_dian;
        if (estado_acuse === "a" && (estado_recibo === "." || estado_recibo === "e")) {
            let datos={ 
                datos: {
                    nit: nit_doc,
                    numero: this.selectedRows[0].numero_documento
                }
            };

            this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '3_2', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successEnviarAcuse,
                error: this.error_,
                general: this.generalFormatPmv
            })
        }else{
            this.alertGeneral.toggle(true, 'El recibo Dian para el documento #' + this.selectedRows[0].numero_documento + ' no puede ser enviado.', 'error'); 
        }
    }

    enviarAcuse(){
        this.getField('confirmModalCustom').setTitleAndContent('Enviar', 'Quiere enviar el acuse de recibo del documento '+this.selectedRows[0].numero_documento+'?');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').setClickDialog(this.confirmEnviarAcuse);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmEnviarAcuse(){
        this.getField('confirmModalCustom').toggle(false);
        let estado_a = this.selectedRows[0].estado_acuse_dian;
        if(estado_a === '.' || estado_a === 'e'){

            let datos={ 
                datos: {
                    nit: this.selectedRows[0].nit_documento,
                    numero: this.selectedRows[0].numero_documento
                }
            };

            this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '3_1', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successEnviarAcuse,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, 'El acuse Dian para el documento #' + this.selectedRows[0].numero_documento + ' no puede ser enviado, su estado es "'+estado_a+'"', 'error');
        }

    }

    successEnviarAcuse(data){
        if(data.estado_p === 200){
            this.consultarDocumentos();
            this.habilitarBotones(true);
        }
    }

    mostrarBotones(value){
        this.getField("ver_xml").setVisible(value);
        this.getField("enviar_acuse").setVisible(value);
        this.getField("enviar_MoS").setVisible(value);
        this.getField("enviar_aceptacion").setVisible(value);
        this.getField("enviar_reclamo").setVisible(value);
        this.getField("ver_eventos").setVisible(value);
        this.getField("ver_pdf").setVisible(value);
        this.getField("descargar_cufe").setVisible(value);
        this.getField("contabilizar").setVisible(value);
    }

    habilitarBotones(value){
        this.getField("ver_xml").setDisabled(value);
        this.getField("enviar_acuse").setDisabled(value);
        this.getField("enviar_MoS").setDisabled(value);
        this.getField("enviar_aceptacion").setDisabled(value);
        this.getField("enviar_reclamo").setDisabled(value);
        this.getField("ver_eventos").setDisabled(value);
        this.getField("ver_pdf").setDisabled(value);
        // this.getField("descargar_cufe").setDisabled(value);
        this.getField("contabilizar").setDisabled(value);
    }

    changeDate(){
        this.getField("consultar").setDisabled(false);
        this.getField('contabilizar').setVisible(false);
        this.getField('rejilla').toggle(false);
        this.mostrarBotones(false);
        this.validarfechas();
    }

    validarfechas(){
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("desde").getValue());
        let fecha_hasta = new Date(this.getField("hasta").getValue());

        this.getField("desde").setError(false,"");
        this.getField("hasta").setError(false,"");

        if(fecha_desde.getTime() > fecha_actual.getTime()){
            this.getField("desde").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }

        if(fecha_hasta.getTime() > fecha_actual.getTime()){
            this.getField("hasta").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }

        if(fecha_desde.getTime() > fecha_hasta.getTime()){
            this.getField("hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }
        return true;
    }

    changeNit(e){
        this.getField("consultar").setDisabled(false);
        this.getField('rejilla').toggle(false);
        this.mostrarBotones(false);
        if(this.getField('nit').getValue() === ''){
            this.getField('nit').setError(false,"");
            this.getField('nombre').setError(false,"");
        }else{
            this.getField("nit").changeKeyUp(e);
        }
    }

    changeNumero(){
        this.getField("consultar").setDisabled(false);
        this.getField('rejilla').toggle(false);
        this.mostrarBotones(false);
        if(this.getField('numero').getValue() === ''){
            this.getField('numero').setError(false,"");
        }else{
            this.getField('numero').valid();
        }
    }

    changePedido(){
        this.getField("consultar").setDisabled(false);
        this.getField('rejilla').toggle(false);
        this.mostrarBotones(false);
        if(this.getField('pedido').getValue() === ''){
            this.getField('pedido').setError(false,"");
        }else{
            this.getField('pedido').valid();
        }
    }

    consultarDocumentos(){
        if(this.validarfechas()){
            let seguir = true;
            let datos={ 
                datos: {
                    desde: this.getField('desde').getValue(), 
                    hasta: this.getField('hasta').getValue(),
                    nit:'.',
                    numero:'.',
                    pedido:'.'
                }
            };
    
            if(this.getField('nit').getValue() !== ''){
                if(this.getField('nit').valid() & this.getField('nombre').valid()){
                    datos.datos['nit'] = this.getField('nit').getValue();
                }else
                    seguir = false
            }
    
            if(this.getField('numero').getValue() !== ''){
                if(this.getField('numero').valid()){
                    datos.datos['numero'] = this.getField('numero').getValue();
                }else
                    seguir = false
            }
    
            if(this.getField('pedido').getValue() !== ''){
                if(this.getField('pedido').valid()){
                    datos.datos['pedido'] = this.getField('pedido').getValue();
                }else
                    seguir = false
            }
    
            if(seguir){
                this.generalFormatPmv = { tipo_servicio:  'cont-consultadocumentos', operacion: '1', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successConsulta1,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }
        }
    }

    successConsulta1(data){
        if(data.estado_p === 200){
            this.getField("consultar").setDisabled(true);
            let configCell = new Map();
            let createElement = this.createElementJaivana;
            this.gridOptionsDoc['rowData'] = data.data;
            this.gridOptionsDoc['onSelectionChanged'] =this.onSelectionChangedRowTable2;
            configCell.set('estado_acuse_dian', { cellRenderer: function (props) { 
                if (props.data.estado_acuse_dian === 'a') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_acuse_dian);
                    div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_acuse_dian === 'e'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_acuse_dian);
                    div.setAttribute("class","divStyle4");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_acuse_dian === 'p'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_acuse_dian);
                    div.setAttribute("class","divStyle1");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_acuse_dian === '.'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_acuse_dian);
                    div.setAttribute("class","divStyle2");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else{
                    return props.data.estado_acuse_dian
                }
            }, field: 'estado_acuse_dian' });

            configCell.set('estado_recibo_dian', { cellRenderer: function (props) { 
                if (props.data.estado_recibo_dian === 'a') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_recibo_dian);
                    div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_recibo_dian === 'e'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_recibo_dian);
                    div.setAttribute("class","divStyle4");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_recibo_dian === 'p'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_recibo_dian);
                    div.setAttribute("class","divStyle1");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_recibo_dian === '.'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_recibo_dian);
                    div.setAttribute("class","divStyle2");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else{
                    return props.data.estado_recibo_dian
                }
            }, field: 'estado_recibo_dian' });

            configCell.set('estado_aceptacion_dian', { cellRenderer: function (props) { 
                if (props.data.estado_aceptacion_dian === 'a') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_aceptacion_dian);
                    div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_aceptacion_dian === 'e'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_aceptacion_dian);
                    div.setAttribute("class","divStyle4");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_aceptacion_dian === 'p'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_aceptacion_dian);
                    div.setAttribute("class","divStyle1");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_aceptacion_dian === '.'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_aceptacion_dian);
                    div.setAttribute("class","divStyle2");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else{
                    return props.data.estado_aceptacion_dian
                }
            }, field: 'estado_aceptacion_dian' });

            configCell.set('estado_reclamo_dian', { cellRenderer: function (props) { 
                if (props.data.estado_reclamo_dian === 'a') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_reclamo_dian);
                    div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_reclamo_dian === 'e'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_reclamo_dian);
                    div.setAttribute("class","divStyle4");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_reclamo_dian === 'p'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_reclamo_dian);
                    div.setAttribute("class","divStyle1");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_reclamo_dian === '.'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_reclamo_dian);
                    div.setAttribute("class","divStyle2");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else{
                    return props.data.estado_reclamo_dian
                }
            }, field: 'estado_reclamo_dian' });

            configCell.set('estado_contabilizacion', { cellRenderer: function (props) { 
                if (props.data.estado_contabilizacion === 'CONTABILIZADO') {
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_contabilizacion);
                    div.setAttribute("class","divStyle3");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else if(props.data.estado_contabilizacion === 'NO CONTABILIZADO'){
                    let div = document.createElement("input");
                    div.setAttribute("id", `div_${props.data.id}`);
                    div.setAttribute("value",props.data.estado_contabilizacion);
                    div.setAttribute("class","divStyle4");
                    div.setAttribute("type","div");
                    div.setAttribute("disabled", true);
                    return createElement(div);
                }else{
                    return props.data.estado_contabilizacion
                }
            }, field: 'estado_contabilizacion' });

            this.getField('rejilla').initData(this.gridOptionsDoc, configCell);
            this.mostrarBotones(true);
            this.habilitarBotones(true);
        }else{
            this.getField('rejilla').toggle(false);
            this.mostrarBotones(false);
        }
    }

    manejaDecimales(){
        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.generalFormatPmv = {tipo_servicio: 'cont-consultadocumentos', operacion: 'manejodecimales', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successManejaDecimales,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

    successManejaDecimales(data){
        if(data.estado_p === 200){
            this.numeroDecimales = Number(data.data.numero_decimales);
        }else{
            this.alertGeneral.toggle(true, 'No hay decimales configurados para calcular los totales', 'error');
        }
    }

    cuentasAjustePesos(){
        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.generalFormatPmv = {tipo_servicio: 'cont-consultadocumentos', operacion: 'cuentasajustealpeso', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successCuentasAjustePesos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

    successCuentasAjustePesos(data){
        if(data.estado_p === 200){
            this.cuentasAjustePesosPositiva = data.data.cuenta_ajuste_peso_positivo;
            this.cuentasAjustePesosNegativa = data.data.cuenta_ajuste_peso_negativo;
            this.centroCostoAjustePesos = data.data.centro_costo_ajuste_peso;
            this.dptoInternoAjustePesos = data.data.departamento_interno_ajuste_peso;
        }else{
            this.alertGeneral.toggle(true, 'No hay cuentas ajuste al peso configurads para calcular los totales', 'error');
        } 
    }


    rangoAjustePesos(){
        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.generalFormatPmv = {tipo_servicio: 'cont-consultadocumentos', operacion: 'rangosajustealpeso', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successRangoAjustePesos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

    successRangoAjustePesos(data){
        if(data.estado_p === 200){
            this.rangoAjustePesosDecimales = data.data.rango_ajuste_al_peso;           
        }else{
            this.alertGeneral.toggle(true, 'No hay rango de decimales para calcular los totales', 'error');
        }
    }

    mostrarArchivo(){
        this.getField('modal_contabilizar_archivo').handleClickOpen();
        this.getField('listadocumentosarchivo').setItemsFichas(this.datosLista);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está descargando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);



        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.datosAgregar.datos['numero_documento']=this.getField('numero_documento').getValue();
        this.datosAgregar.datos['fecha_documento']=this.fechaDocumento !== '' ? this.fechaDocumento : this.selectedRows[0].fecha_documento;
        this.datosAgregar.datos['vence_documento']=this.venceDocumento;
        this.datosAgregar.datos['ruta_archivo']=this.ruta_archivo;
        this.datosAgregar.datos['nombre_archivo']=this.nombre_archivo;
        this.datosAgregar.datos['maneja_distribucion']=this.maneja_distribucion;
        this.datosAgregar.datos['detalle']=this.getField('detalle').getValue();
        this.generalFormatPmv = {tipo_servicio: 'cont-consultadocumentos', operacion: 'traerarchivo', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successMostrarArchivo,
                error: this.error_,
                general: this.generalFormatPmv               
            }
        );   
    }

    successMostrarArchivo(data){
        if(data.estado_p === 200){
            let valor_negativo = 0;
            let valor_positivo = 0;
            let diferencia = 0;

            data.data.forEach(item => {
                if(item.valor > 0){
                    valor_positivo +=  Math.round(item.valor);
                }else{
                    valor_negativo +=  Math.round(item.valor);
                }
            });
            diferencia =  Math.round(valor_negativo + valor_positivo);

            this.getField("total_credito").setValue(valor_negativo);
            this.getField("total_debito").setValue(valor_positivo);
            this.getField("diferencia").setValue(diferencia);

            if(diferencia !== 0){
                this.getField("btn_contabilizar_archivo").setDisabled(true);
            }else{
                this.getField("btn_contabilizar_archivo").setDisabled(false);
            }

            let configCell = new Map();        
            configCell.set('base',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterbase,maxWidth: 100});
            configCell.set('tarifa',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattertarifa,maxWidth: 100});
            configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattervalor,maxWidth: 100});

            this.itemsArchivoPlano = data.data;
            this.getField('confirmModalCustom').toggle(false);
            this.getField('rejilla_archivo_plano').toggle(true);
            this.gridOptionsArchivoPlanoXml['rowData'] = data.data;    
            this.getField('rejilla_archivo_plano').initData(this.gridOptionsArchivoPlanoXml,configCell);

            this.getField('btn_contabilizar_archivo').setVisible(true);
        }else{
            this.getField('confirmModalCustom').toggle(false);
            this.getField("btn_contabilizar_archivo").setDisabled(false);
            //this.alertGeneral.toggle(true, 'No se descargo el archivo correctamente', 'error');
        }
    }

    currencyFormatterbase(data){
        let decimal = (data.data.base + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {        
            return (this.formatNumberValor(data.data.base) + "," + decimal);
        } else {
            return this.formatNumberValor(data.data.base); 
        }
    }
    
    currencyFormattertarifa(data){
        let decimal = (data.data.tarifa + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {        
            return (this.formatNumberValor(data.data.tarifa) + "," + decimal);
        } else {
            return this.formatNumberValor(data.data.tarifa); 
        }
    }

    currencyFormattervalor(data){
        let decimal = (data.data.valor + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {        
            return (this.formatNumberValor(data.data.valor) + "," + decimal);
        } else {
            return this.formatNumberValor(data.data.valor); 
        }
    }
    
    formatNumberValor(number){
        let valor=Number(number).toString().split('.');
        return valor[0]
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    contabilizarArchivo(){
        let datos = { datos: { documentos_recibidos_id: this.documentos_recibidos_id }};
        datos['items'] = this.itemsArchivoPlano;
        // Se coloca "showMessage: false" ya que en la tarea "JQJD-1207" solicitaron que "Cuando contabilizamos en el mensaje de contabilización exitosa cambiar ese fieldAlert por un ConfirmDialog en el cual también indiquemos el numero de documento."
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: 'contabilizararchivo', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successContabilizarArchivo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successContabilizarArchivo(data){
        if (data.estado_p === 200) {
            this.getField('confirmModalCustom').setTitleAndContent('Contabilización', `${data.data.mensaje}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('modal_contabilizar').handleClose();
                this.getField('modal_contabilizar_archivo').handleClose();
                this.cerrarModalContabilizar();
                this.cerrarModalContabilizarArchivo();
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }else{
            if (data.data.errores !== null){
                let respuesta = Object.values(data.data.errores);
                let keys = Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
            }else{
                this.alertGeneral.toggle(true, 'Error en los datos, verifique su información', 'error');
            }

        }
    }

    cerrarModalContabilizarArchivo(){        
        this.getField('btn_contabilizar_archivo').setVisible(false);
        this.itemsArchivoPlano=[];
        //this.datosLista=[];
    }

    verDistribución(){
        this.getField('modal_registrar_distribucion').handleClickOpen();
        this.getField('listadocumentosarchivodistribucion').setItemsFichas(this.datosLista);

        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.datosAgregar.datos['total']=Number(this.totaldistribucion);
        this.datosAgregar.datos['plantillas_electronicas_gastos_id']=this.getField('plantillas_electronicas_gastos_id').getValue();
        this.datosAgregar.datos['documentos_recibidos_id']=this.documentos_recibidos_id;
        
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: 'consultardistribucion', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successVerDistribucion,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

    successVerDistribucion(data){
        if (data.estado_p === 200) {
            this.total_distribucion=0;
            for (let i = 0; i < data.data.length; i++){
                this.total_distribucion += Number(data.data[i]["valor"]);                
            }            
            this.getField("total_distribucion").setValue(this.total_distribucion.toFixed(2));
            this.getField("base_total_distribucion").setValue(this.basetotaldistribucion);
            
            this.gridOptionsTablaItemsDistribucion["rowData"] = data.data; //y se recarga la tabla
            let configCell = new Map();
            configCell.set('valor', { cellRenderer: function (props) { return props.data.valor}, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, cellDataType: false, onCellValueChanged: this.valorDistribucionChange, sortable: false, filter: false, field: 'valor' });                 
            this.gridOptionsTablaItemsDistribucion["onGridReady"] = (event) => event.api.sizeColumnsToFit(); 
            this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion,configCell);
            
            /*if (Number(total_distribucion)===Number(this.basetotaldistribucion)){
                this.getField("btn_aceptar_distribucion").setVisible(true);
            }else{
                this.getField("btn_aceptar_distribucion").setVisible(false);
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', ` ¡La distribución debe ser igual a la base de la factura! ` + Number(this.basetotaldistribucion));
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }*/


        }else{
            this.gridOptionsTablaItemsDistribucion["rowData"] = [];   
            this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion);
            this.getField("rejilla_distribucion").toggle(false);
            this.alertGeneral.toggle(true, 'Error al generar la distribución', 'error');
            //this.getField("btn_aceptar_distribucion").setVisible(false);
        }
    }

    onSelectionChanged(){
        //this.gridOptionsTablaItemsDistribucion.api.deselectAll();
    }

    valorDistribucionChange(props){
        if (props.data.detalle.includes("PLACA") || props.data.detalle.includes("placa")){
        }else{
            this.total_distribucion=0;
        }

        if(props.oldValue !== props.newValue && props.newValue !== ''){
            this.gridOptionsTablaItemsDistribucion['rowData'][props.node.rowIndex]['valor'] = props.newValue
            for (let e = 0; e < this.gridOptionsTablaItemsDistribucion['rowData'].length; e++) {
                if (this.gridOptionsTablaItemsDistribucion['rowData'][e]["detalle"].includes('PLACA') || this.gridOptionsTablaItemsDistribucion['rowData'][e]["detalle"].includes('placa')){

                } else if (props.data.detalle.includes("PLACA") || props.data.detalle.includes("placa")){

                } else{
                    this.total_distribucion += Number(this.gridOptionsTablaItemsDistribucion['rowData'][e]["valor"]);
                }
            }          
            this.getField("total_distribucion").setValue(this.total_distribucion.toFixed(2));
        }
    }



    aceptarDistribución(){        
        if (Number(this.total_distribucion)===Number(this.basetotaldistribucion)){
            if (this.gridOptionsTablaItemsDistribucion['rowData'].length>0){
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información a enviar por correo... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
    
                let datos = { datos: {
                    documentos_recibidos_id: this.documentos_recibidos_id,
                    nombre_archivo:this.nombre_archivo,
                }};
                
                datos['items'] = this.gridOptionsTablaItemsDistribucion['rowData'];
        
                // Se coloca "showMessage: false" ya que en la tarea "JQJD-1207" solicitaron que "Cuando contabilizamos en el mensaje de contabilización exitosa cambiar ese fieldAlert por un ConfirmDialog en el cual también indiquemos el numero de documento."
                this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: 'aceptardistribucion', operacion_tipo: 'crear' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successAceptarDistribucion,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }else{
            //this.getField("btn_aceptar_distribucion").setVisible(false);
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', ` ¡La distribución debe ser igual a la base de la factura! ` + Number(this.basetotaldistribucion));
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }


    }   

    successAceptarDistribucion(data){
        if (data.estado_p === 200) {
            this.getField('modal_registrar_distribucion').handleClose();
            //this.getField("btn_aceptar_distribucion").setVisible(false);
            this.getField('confirmModalCustom').toggle(false);
        }else{
            this.alertGeneral.toggle(true, 'Error en los datos, verifique su información', 'error');
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    guardarDistribucion(){
        if (Number(this.total_distribucion)===Number(this.basetotaldistribucion)){

            if (this.gridOptionsTablaItemsDistribucion['rowData'].length>0){
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información a enviar por correo... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
    
                let datos = { datos: {
                    documentos_recibidos_id: this.documentos_recibidos_id,
                }};
                
                datos['items'] = this.gridOptionsTablaItemsDistribucion['rowData'];
        
                // Se coloca "showMessage: false" ya que en la tarea "JQJD-1207" solicitaron que "Cuando contabilizamos en el mensaje de contabilización exitosa cambiar ese fieldAlert por un ConfirmDialog en el cual también indiquemos el numero de documento."
                this.generalFormatPmv = { tipo_servicio: 'cont-consultadocumentos', operacion: 'guardardistribucion', operacion_tipo: 'crear' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successGuardarDistribucion,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }else{
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', ` ¡La distribución debe ser igual a la base de la factura! ` + Number(this.basetotaldistribucion));
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }

    }

    successGuardarDistribucion(data){
        if (data.estado_p === 200) {
            //this.getField('modal_registrar_distribucion').handleClose();
            this.gridOptionsTablaItemsDistribucion['rowData'] = [];
            this.getField('confirmModalCustom').toggle(false);
        }else{
            this.alertGeneral.toggle(true, 'Error en los datos, verifique su información', 'error');
            this.getField('confirmModalCustom').toggle(false);
        }
    }


}
FormJaivana.addController("cont-consultadocumentos",CustomConsultaDocumentos);
export default CustomConsultaDocumentos