import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
 * @author Mario Alejandro Sepúlveda Rojas
 * @modified 19-04-2023
*/
/**
 * CustomProcesarArchivosMulticash
**/
class CustomProcesarArchivosMulticash extends FormJaivana.form {


   constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);
        this.arrayObjetos=new Map();
        this.arrayCampos=new Map();
        this.bHayErrores=false;
        this.gridOptionsArchivosMulticash = Object.assign({},this.gridOptions);
        this.consultarTabla=this.consultarTabla.bind(this);
        this.successConsultar = this.successConsultar.bind(this);
        this.setButtonAccion = this.setButtonAccion.bind(this);
        this.setClickContabilizar = this.setClickContabilizar.bind(this);
        this.successContabilizar = this.successContabilizar.bind(this);
        this.validarFechaMenorActual = this.validarFechaMenorActual.bind(this);
        this.cargarSelect = this.cargarSelect.bind(this);
        this.successCargarSelect = this.successCargarSelect.bind(this);
        //this.validarFechaMenor = this.validarFechaMenor.bind(this);

        this.selectionNit = this.selectionNit.bind(this);
        this.selectionFecha = this.selectionFecha.bind(this);
        this.selectionValor = this.selectionValor.bind(this);
        this.selectionNombre = this.selectionNombre.bind(this);
        this.selectionCodigo = this.selectionCodigo.bind(this);
        this.selectionNumero = this.selectionNumero.bind(this);
        this.limpiarCampos = this.limpiarCampos.bind(this);
        this.validRangoFechas = this.validRangoFechas.bind(this);
        this.registroSeleccionado = '';

        this.valid_bancos= false;
        this.valid_fecha_desde = true;
        this.valid_fecha_hasta = true;


        this.traerBancosMulticashArchivos               = this.traerBancosMulticashArchivos.bind(this);
        this.gridOptionsComponentes                     = Object.assign({}, this.gridOptions);
        this.successBancosMulticashArchivos             = this.successBancosMulticashArchivos.bind(this);
        this.setButton                                  = this.setButton.bind(this);
        this.selectionNombreCabecera                    = this.selectionNombreCabecera.bind(this);
        this.selectionNombreDetalle                     = this.selectionNombreDetalle.bind(this);
        this.traerBancosMulticashArchivosOriginal       = this.traerBancosMulticashArchivosOriginal.bind(this);
        this.setButtonContabilizar                      = this.setButtonContabilizar.bind(this);
        this.contabilizarArchivo                        = this.contabilizarArchivo.bind(this);
        this.successContabilizarArchivo                 = this.successContabilizarArchivo.bind(this);
        this.setButtonResumen                           = this.setButtonResumen.bind(this);
        this.setClickResumen                            = this.setClickResumen.bind(this);
        this.successResumen                             = this.successResumen.bind(this);
        this.gridOptionsArchivosMulticashResumen        = Object.assign({},this.gridOptions);
        this.setButtonConfiguracion                     = this.setButtonConfiguracion.bind(this);
        this.setClickConfiguracionConcepto              = this.setClickConfiguracionConcepto.bind(this);
        this.verificarActualizarConceptos               = this.verificarActualizarConceptos.bind(this);
        this.successVerificarActualizarConceptos        = this.successVerificarActualizarConceptos.bind(this);
    }

    initForm() {
        this.cargarSelect();

       console.log('CustomProcesarArchivosMulticash,  @version: jdesk_1.01.0001, @author: Mario Alejandro Sepúlveda Rojas, @updatedBy: Mario Alejandro Sepúlveda Rojas');

       this.arrayCampos.set(1,'id');
       this.arrayCampos.set(2,'fecha_desde');
       this.arrayCampos.set(3,'fecha_hasta');
       this.arrayCampos.set(4,'bancoscuentas_id');
       this.arrayCampos.set(5,'consultar');
       this.arrayCampos.set(6,'rejilla');

       this.arrayObjetos.set(1,'id');   
       this.arrayObjetos.set(2,'fecha_desde');
       this.arrayObjetos.set(3,'fecha_hasta');
       this.arrayObjetos.set(4,'bancoscuentas_id');
       this.arrayObjetos.set(5,'consultar');
       this.arrayObjetos.set(6,'rejilla');

       //this.getField('consultar').setClick(this.consultarTabla);
       this.getField('consultar').setClick(this.traerBancosMulticashArchivos);

       this.getField(this.arrayCampos.get(2)).setOnChange(()=>{
        this.limpiarCampos();
        this.establecerPropiedades('5','disable','false');

        let fechaDesde = this.getField(this.arrayCampos.get(2)).getValue();
        let validFechaDesde = this.validarFechaMenorActual(fechaDesde);
        if(!validFechaDesde){
            this.getField(this.arrayObjetos.get(2)).setError(true,'* La fecha debe ser menor o igual a la fecha actual');
            this.valid_fecha_desde =false;
        } else {
            this.getField(this.arrayObjetos.get(2)).setError(false,'');
            this.valid_fecha_desde=true;
        }

        if(this.valid_fecha_desde){
            let fd = 'fd'; // este parametro se envia para identificar desde cual setonchage se llama la funcion validarRangoFechas(); fecha_desde o fecha_hasta
            this.validRangoFechas(fd);
        }

       });

       this.getField(this.arrayCampos.get(4)).setOnChange(()=>{
        this.establecerPropiedades('5','disable','false');
        this.limpiarCampos();
        let bancos_cuentas_id = this.getField(this.arrayCampos.get(4));
        if(bancos_cuentas_id.valid()){
            this.valid_bancos = true;
        } else {
            this.valid_bancos = false;
        }

       });

       this.getField(this.arrayCampos.get(3)).setOnChange(()=>{
        this.establecerPropiedades('5','disable','false');

        this.getField('rejilla_resumen').toggle(false);
        this.getField('rejilla').toggle(false);
        this.getField('rejilla_cabecera').toggle(false);
        
        this.limpiarCampos();

        let validFechaHasta = this.validarFechaMenorActual(this.getField(this.arrayCampos.get(3)).getValue());

        if(!validFechaHasta){
            this.getField(this.arrayObjetos.get(3)).setError(true,'* La fecha debe ser menor o igual a la fecha actual');
            this.valid_fecha_hasta=false;

        } else {
            this.getField(this.arrayObjetos.get(3)).setError(false,'');
            this.valid_fecha_hasta=true;
        }

        if(this.valid_fecha_hasta){
            let fh = 'fh'; // este parametro se envia para identificar desde cual setonchage se llama la funcion validarRangoFechas(); fecha_desde o fecha_hasta
            this.validRangoFechas(fh);
        }

       });
    }

    traerBancosMulticashArchivos(){
        this.getField('rejilla').toggle(false);
        this.getField('rejilla_resumen').toggle(false);
        if (this.getField('bancoscuentas_id').valid()){
            let datos={ datos: {

                bancos_cuentas_id:this.getField(this.arrayCampos.get(4)).getValue(),
                fecha_desde:this.getField(this.arrayCampos.get(2)).getValue(),
                fecha_hasta:this.getField(this.arrayCampos.get(3)).getValue()
            
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-bancosmulticasharchivos', operacion: '2_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successBancosMulticashArchivos,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
        }

    }

    successBancosMulticashArchivos(data){    
        if(data.estado_p === 200){

            this.getField('rejilla_cabecera').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            configCell.set('contabilizar', { cellRenderer: this.setButtonContabilizar, width: 110, sortable: false, filter: false, field: 'contabilizar' });
            configCell.set('resumen', { cellRenderer: this.setButtonResumen, width: 164, sortable: false, filter: false, field: 'resumen' });
   
            configCell.set('archivo_cabecera',{cellRenderer:this.selectionNombreCabecera, width:164,field:'archivo_cabecera'});
            configCell.set('archivo_detalle',{cellRenderer:this.selectionNombreDetalle, width:164,field:'archivo_detalle'});
            configCell.set('accion', { cellRenderer: this.setButton, width: 110, sortable: false, filter: false, field: 'Acción' });
            this.getField('rejilla_cabecera').initData(this.gridOptionsComponentes,configCell);

            this.verificarActualizarConceptos();

        }else{
            this.alertGeneral.toggle(true, 'No hay archivos procesados.', 'error');
            this.getField('rejilla_cabecera').toggle(false);
        }
    }

    setButton(props){
        let fragment = document.createDocumentFragment();
        let numero_integracion = props.data.numero_integracion;
        let button = document.createElement("input");
        button.onclick = () => this.traerBancosMulticashArchivosOriginal(numero_integracion);
        button.setAttribute("id", 'button_ver_mas_' + numero_integracion);
        //button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ver más");
        button.setAttribute("style", "width: 110px;height: 20px;background-color: #303f9f;color: #fff;border: 1px solid #303f9f;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;border-radius: 3px;cursor: pointer;margin-right: 10px;");
        
        fragment.appendChild(button);
        return this.createElementJaivana(fragment);
    }

    setButtonContabilizar(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.id;

        let button = document.createElement("input");
        button.onclick = () => this.contabilizarArchivo(id);
        button.setAttribute("id", 'button_contabilizar_' + id);
        //button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Contabilizar");
        button.setAttribute("style", "width: 110px;height: 20px;background-color: #303f9f;color: #fff;border: 1px solid #303f9f;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;border-radius: 3px;cursor: pointer;margin-right: 10px;");
        
        if (props.data.estado === 'C' || props.data.estado === '.'){            
            button.setAttribute("disabled", true);
        }

        fragment.appendChild(button);
        return this.createElementJaivana(fragment);


    }

    contabilizarArchivo(id){

        if (id !== '' && id !== null){

            this.datosAgregar={ datos:{}};
            this.datosAgregar.datos['id']=id;
            this.datosAgregar.datos['sucursal_ingreso']=JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
            this.datosAgregar.datos['fecha_desde']=this.getField(this.arrayCampos.get(2)).getValue();
            this.datosAgregar.datos['fecha_hasta']=this.getField(this.arrayCampos.get(3)).getValue();

            let datos=this.datosAgregar;
            this.generalFormatPmv = { tipo_servicio: 'cont-bancosmulticasharchivosoriginal', operacion: 'contabilizar', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successContabilizarArchivo,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        
        }else{
            this.alertGeneral.toggle(true, 'No hay archivos procesados.', 'error');
        }
    
    }

    successContabilizarArchivo(data){

        if (data.estado_p === 200){

            this.traerBancosMulticashArchivos();

        }else{
            this.alertGeneral.toggle(true, 'Error al contabilizar el archivo.', 'error');
        }
    
    }

    traerBancosMulticashArchivosOriginal(numero_integracion){
        this.establecerPropiedades('5','disable','true');

        let bancos_cuentas_id = this.getField(this.arrayCampos.get(4));
        if((this.valid_fecha_desde) && (this.valid_fecha_hasta)){
            if(bancos_cuentas_id.valid()){

                let datos={ datos: {
                    numero_integracion:numero_integracion
                }};
                this.generalFormatPmv = { tipo_servicio: 'maes-procesarchmulticash', operacion: '1_1', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successConsultar,
                        error: this.error_,
                        general: this.generalFormatPmv,
                });
            } /* else {

                //this.getField(this.arrayCampos.get(4)).setError(true,'* Este campo es requerido','error');
            } */
        } else if(!this.valid_fecha_desde){
            //console.log("valid fecha desde--> : ",this.valid_fecha_desde);
        }else if(!this.valid_fecha_hasta){
            //console.log("valid fecha hasta--> : ",this.valid_fecha_hasta);
            this.getField(this.arrayObjetos.get(3)).setError(true,'* La fecha debe ser menor o igual a la fecha actual');
            //this.valid_fecha_hasta=false;

        }

    }

    selectionNombreCabecera(props){
        let archivo_cabecera = props.data.archivo_cabecera.split('/')[7];
   
        if (archivo_cabecera !== '') {    
            let div = document.createElement('div');        
            div.textContent = archivo_cabecera;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            return this.createElementJaivana(div);
        }
    } 
    
    selectionNombreDetalle(props){

        let archivo_detalle = props.data.archivo_detalle.split('/')[7];

        if (archivo_detalle !== '') {    
            let div = document.createElement('div');        
            div.textContent = archivo_detalle;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            return this.createElementJaivana(div);
        }
    }

    validRangoFechas(campo_fecha){
        let fecha_desde = new Date(this.getField(this.arrayCampos.get(2)).getValue());
        let  fd = new Date(fecha_desde.getFullYear()+ "/" + (fecha_desde.getMonth() +1) + "/" + fecha_desde.getDate());
        let fecha_hasta = new Date(this.getField(this.arrayCampos.get(3)).getValue());
        let  fh = new Date(fecha_hasta.getFullYear() + "/" + (fecha_hasta.getMonth() +1) + "/" + fecha_hasta.getDate() );
        
        let rango = Math.round(((fh.getTime() - fd.getTime()) / (1000*60*60*24)));

        //console.log("Rango de fechas debe se menor o igual a 60 dias",rango,fd,fh);
        if(campo_fecha === 'fd'){
            //console.log('entro a if rango');
            if(rango < -1){
                this.valid_fecha_desde = false;
                this.getField(this.arrayCampos.get(2)).setError(true,'* La fecha desde debe ser menor o igual a la fecha hasta');
            } else if(rango >= 59){
                this.valid_fecha_desde = false;
                this.getField(this.arrayCampos.get(2)).setError(true,'* El rango de fecha debe ser máximo de 60 días');
            } else{
                
                let validFechaHasta = this.validarFechaMenorActual(this.getField(this.arrayCampos.get(3)).getValue());//
                //console.log('valid?',validFechaHasta,this.getField(this.arrayCampos.get(3)).getValue());
        
                if(!validFechaHasta){
                    this.getField(this.arrayObjetos.get(3)).setError(true,'* La fecha debe ser menor o igual a la fecha actual');
                    this.valid_fecha_hasta=false;
        
                } else {
                    this.getField(this.arrayObjetos.get(3)).setError(false,'');
                    this.valid_fecha_hasta=true;
                }
                //this.getField(this.arrayCampos.get(3)).setError(false,'');
                this.getField(this.arrayCampos.get(2)).setError(false,'');
                this.valid_fecha_desde = true;
                //this.valid_fecha_hasta = true;

            }
        }else if(campo_fecha === 'fh'){
            //console.log('entro a if rango2');
            if(rango < 1){
                this.getField(this.arrayCampos.get(3)).setError(true,'* La fecha hasta debe ser mayor o igual a la fecha desde');
                this.valid_fecha_hasta = false;
            }else if(rango >= 61){
                this.getField(this.arrayCampos.get(3)).setError(true,'* El rango de fechas debe ser máximo de 60 días');
                this.valid_fecha_hasta = false;
            }else{
                this.valid_fecha_hasta = true;
                this.valid_fecha_desde = true;//

                this.getField(this.arrayCampos.get(3)).setError(false,'');
                this.getField(this.arrayCampos.get(2)).setError(false,'');

            }
        }
    }
   
    limpiarCampos() {
        this.getField('rejilla').toggle(false);
        this.getField('rejilla_resumen').toggle(false);
        this.getField('rejilla_cabecera').toggle(false);
        this.registroSeleccionado='';
    }

    cargarSelect(){
        let datos={ datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'maes-bancoscuentas', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarSelect,
                error: this.error_,
                general: this.generalFormatPmv,
        });
    }

    successCargarSelect(data){
        //console.log('entro al success cargar select');
        if(data.estado_p === 200){
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.bancos_cuentas_id;
            dataOp['text'] = `${item.numero_cuenta}-${item.nombre_banco_oficina}`;
            dataOp['campos_cambian'] = { };
            opciones.push(dataOp); })
            this.getField(this.arrayCampos.get(4)).setOptions(opciones);
        }
    }

    //*** definir los botones y los success
    consultarTabla() {
        this.establecerPropiedades('5','disable','true');

        //console.log('entro a consultarTabla',this.valid_fecha_desde,this.valid_fecha_hasta);
        let bancos_cuentas_id = this.getField(this.arrayCampos.get(4));
        if((this.valid_fecha_desde) && (this.valid_fecha_hasta)){
            if(bancos_cuentas_id.valid()){

                //console.log('Entro al if',fecha_desde.getValue(),fecha_hasta.getValue());
                let datos={ datos: {
                    fecha_desde:this.getField(this.arrayCampos.get(2)).getValue(),
                    fecha_hasta:this.getField(this.arrayCampos.get(3)).getValue(),
                    bancoscuentas_id:this.getField(this.arrayCampos.get(4)).getValue()
                } };
                this.generalFormatPmv = { tipo_servicio: 'maes-procesarchmulticash', operacion: '1_1', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successConsultar,
                        error: this.error_,
                        general: this.generalFormatPmv,
                });
            } /* else {
                //console.log('entro al else');
                //this.getField(this.arrayCampos.get(4)).setError(true,'* Este campo es requerido','error');
            } */
        } else if(!this.valid_fecha_desde){
            //console.log("valid fecha desde--> : ",this.valid_fecha_desde);
        }else if(!this.valid_fecha_hasta){
            //console.log("valid fecha hasta--> : ",this.valid_fecha_hasta);
            this.getField(this.arrayObjetos.get(3)).setError(true,'* La fecha debe ser menor o igual a la fecha actual');
            //this.valid_fecha_hasta=false;

        }
    }

    successConsultar(data){
        //this.establecerPropiedades('5','disable','false');

        if(data.estado_p === 200){

            let configCell = new Map();

            //configCell.set('nombre_archivo_cabecera',{cellRenderer:this.selectionNombre, width:164,field:'nombre_archivo_cabecera'});
            configCell.set('nit',{cellRenderer:this.selectionNit, width:164, field:'nit'});
            configCell.set('fecha',{cellRenderer:this.selectionFecha, width:164, field:'fecha'});
            configCell.set('valor',{cellRenderer:this.selectionValor, width:164, cellStyle:{textAlign:"right"}, field:'valor'});
            configCell.set('codigo_operacion',{cellRenderer:this.selectionCodigo, width:164, field:'codigo_operacion'});
            //configCell.set('numero_integracion',{cellRenderer:this.selectionNumero, width:164, field:'numero_integracion'}); 
            //configCell.set('accion', { cellRenderer: this.setButtonAccion, width: 164, sortable: false, filter: false, field: 'accion' });

            this.getField('rejilla').toggle(true);      
            this.gridOptionsArchivosMulticash['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptionsArchivosMulticash,configCell);            

        } else {
            this.getField('rejilla').toggle(false); 
            this.getField('rejilla_resumen').toggle(false);
        }
    }

    selectionNit(props){
        let nit = props.data.nit;
        if (this.registroSeleccionado !== '' && props.data.id === this.registroSeleccionado) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width:164px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = nit;
            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = nit;
            return this.createElementJaivana(div);
        }
      }

      selectionFecha(props){
        let fecha = props.data.fecha;
        if (this.registroSeleccionado !== '' && props.data.id === this.registroSeleccionado) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width:164px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = fecha;

            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = fecha;//total;
            return this.createElementJaivana(div);
        }
      }

      selectionValor(props){
        let valor = "".formatoPrecio(props.data.valor.toFixed(2));
        if (this.registroSeleccionado !== '' && props.data.id === this.registroSeleccionado) {
            let div = document.createElement('div');//height: 100%;width: 172px;position: fixed;margin-left: -4%;padding-right: 2%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;color: #0000ff;font-weight: bold;background-color: #B7E4FF;

            div.setAttribute("style", "height: 100%;width: 164px;position: fixed;margin-left: -3%;padding-right: 4%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;color: #0000ff;font-weight: bold;background-color: #B7E4FF;");//text-align:center;
            div.textContent = valor;

            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right:0%;font-size: 0.875rem;");//text-align:right;
            div.textContent = valor;//total;
            return this.createElementJaivana(div);
        }
      }

      selectionNombre(props){
        let nombre_archivo_cabecera = props.data.nombre_archivo_cabecera;
        if (this.registroSeleccionado !== '' && props.data.id === this.registroSeleccionado) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width:164px; position: fixed; margin-left:-1%; padding-right: 0%;padding-left: 1%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = nombre_archivo_cabecera;

            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = nombre_archivo_cabecera;
            return this.createElementJaivana(div);
        }
      }

      selectionCodigo(props){
        let codigo_operacion = props.data.codigo_operacion;
        if (this.registroSeleccionado !== '' && props.data.id === this.registroSeleccionado) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width:164px; position: fixed; padding-right: 0%;padding-left: 0%;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = codigo_operacion;

            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = codigo_operacion;
            return this.createElementJaivana(div);
        }
      }


      selectionNumero(props){
        let numero_integracion = props.data.numero_integracion;
        if (this.registroSeleccionado !== '' && props.data.id === this.registroSeleccionado) {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%; width:164px; position: fixed; padding-right: 0%;padding-left:0;padding-top: 0%;font-size: 0.875rem;text-align:left;color: #0000ff; font-weight: bold; background-color: #B7E4FF;");
            div.textContent = numero_integracion;

            return this.createElementJaivana(div);
        } else {
            let div = document.createElement('div');
            div.setAttribute("style", "height: 100%;padding-left: 0%;padding-top: 0%;padding-right: 0%;font-size: 0.875rem;text-align:left;");
            div.textContent = numero_integracion;
            return this.createElementJaivana(div);
        }
      }
      
    setButtonAccion(props) {
        let fragment = document.createDocumentFragment();
        let id = props.data.id;
        let button1 = document.createElement("input");
        button1.onclick = () => this.setClickContabilizar(props);
        button1.setAttribute("id", 'button_conta_' + id);
        //button1.setAttribute("class", "buttonStyle");
        button1.setAttribute("type", "button");
        //button.classList.add(hover);
        button1.setAttribute("value", "Contabilizar");
        button1.setAttribute("style", "width: 110px;height: 20px;background-color: #303f9f;color: #fff;border: 1px solid #303f9f;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;border-radius: 3px;cursor: pointer;margin-right: 10px;");

        fragment.appendChild(button1);
        return this.createElementJaivana(fragment);
    }

    setClickContabilizar(props){
        this.registroSeleccionado  = props.data.id;
        let datos={ datos: {
            bancos_multicash_ao_id:props.data.id,
            codigo_operacion:props.data.codigo_operacion,
            numero_integracion:props.data.numero_integracion
        } };

        this.generalFormatPmv = { tipo_servicio: 'cont-bancosmulticasharchivosoriginal', operacion: 'contabilizacion', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successContabilizar,
                error: this.error_,
                general: this.generalFormatPmv,
        });
    }

    successContabilizar(data){
        if(data.estado_p === 200){
            this.consultarTabla();
        }
    }

    validarFechaMenorActual(date){
        var x = new Date(date);//.toGMTString()
        //var x = new Date(y);
        var today = new Date();
        //console.log("fecha a validar x",x,"--fecha actual today",today);
        //console.log("dias fecha:",x.getTime()/ (1000*60*60*24),"dias hoy",today.getTime()/ (1000*60*60*24));
        if (x <= today)
            return true;
        else
            return false;
    }

    //*** fin  los botones y los success

    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                //console.log(element);
                //console.log(this.arrayObjetos.get(parseInt(element)));
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }

   cargarCampos(data) 
   {
          this.getField('id').setValue(data.data[0].id);
          this.getField('fecha_desde').setValue(data.data[0].fecha_desde);
          this.getField('fecha_hasta').setValue(data.data[0].fecha_hasta);
          this.getField('bancoscuentas_id').setValue(data.data[0].bancoscuentas_id);
          this.getField('consultar').setValue(data.data[0].consultar);
          this.getField('rejilla').setValue(data.data[0].rejilla);
   }

    setButtonResumen(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.id;
        let button1 = document.createElement("input");
        button1.onclick = () => this.setClickResumen(props);
        button1.setAttribute("id", 'button_conta_' + id);
        //button1.setAttribute("class", "buttonStyle");
        button1.setAttribute("type", "button");
        //button.classList.add(hover);
        button1.setAttribute("value", "Resumen");
        button1.setAttribute("style", "width: 110px;height: 20px;background-color: #303f9f;color: #fff;border: 1px solid #303f9f;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;border-radius: 3px;cursor: pointer;margin-right: 10px;");

        fragment.appendChild(button1);
        return this.createElementJaivana(fragment);
    }

    setClickResumen(props){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'maes-procesarchmulticash', operacion: 'resumen', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successResumen,
                error: this.error_,
                general: this.generalFormatPmv,
        });
    }

    successResumen(data){
        if(data.estado_p === 200){

            let configCell = new Map();
            configCell.set('configuracion', { cellRenderer: this.setButtonConfiguracion, width: 164, sortable: false, filter: false, field: 'configuracion' });

            this.getField('rejilla_resumen').toggle(true);      
            this.gridOptionsArchivosMulticashResumen['rowData'] = data.data;
            this.getField('rejilla_resumen').initData(this.gridOptionsArchivosMulticashResumen,configCell);            

        } else {
            this.getField('rejilla_reumen').toggle(false); 
        }
    }   

    setButtonConfiguracion(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.id;
        let button1 = document.createElement("input");
        button1.onclick = () => this.setClickConfiguracionConcepto(props);
        button1.setAttribute("id", 'button_conta_' + id);
        //button1.setAttribute("class", "buttonStyle");
        button1.setAttribute("type", "button");
        //button.classList.add(hover);
        button1.setAttribute("value", "Configurar");
        button1.setAttribute("style", "width: 110px;height: 20px;background-color: #303f9f;color: #fff;border: 1px solid #303f9f;padding-left: 5px;padding-right: 5px;padding-top: 2px;padding-bottom: 2px;border-radius: 3px;cursor: pointer;margin-right: 10px;");

        fragment.appendChild(button1);
        return this.createElementJaivana(fragment);
    }

    setClickConfiguracionConcepto(props){

        if (props.data.existe_codigo_operacion === 'S'){
            this.getField('consultar').handlerOpenForm(85,'Manejo Operaciones','maes-manejooperaciones','S',2);
        }else if (props.data.existe_codigo_operacion === 'N') {
            this.getField('consultar').handlerOpenForm(85,'Manejo Operaciones','maes-manejooperaciones','S',1);
        }

        
        this.getField('rejilla').toggle(false); 
        this.getField('rejilla_resumen').toggle(false);
        this.getField('rejilla_cabecera').toggle(false);
    }

    verificarActualizarConceptos(){
        this.datosAgregar={ datos:{}};
        let datos=this.datosAgregar;
        this.generalFormatPmv = { tipo_servicio: 'maes-procesarchmulticash', operacion: 'actualizarCodigoOperacion', operacion_tipo: 'modificar' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successVerificarActualizarConceptos,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successVerificarActualizarConceptos(data){
        if(data.estado_p === 200){
          
            this.alertGeneral.toggle(true, 'Conceptos configurados correctamente.', 'success');

        } else {

            this.alertGeneral.toggle(true, 'Conceptos configurados correctamente.', 'success');
        
        }
    }

}
FormJaivana.addController('maes-procesarchmulticash',CustomProcesarArchivosMulticash);
export default CustomProcesarArchivosMulticash;