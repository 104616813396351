import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomConsultaDePedidosCompras
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 * @updateBy Sebastian Rios Echeverri.
 **/
class CustomConsultaDePedidosCompras extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
    this.onSelectionChangedInicio                                  = this.onSelectionChangedInicio.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsComponentesInicial = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesInicial['onSelectionChanged']           = this.onSelectionChangedInicio;

    this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
    this.formatNumberSaldo                               = this.formatNumberSaldo.bind(this);
    this.primeraVez                               = this.primeraVez.bind(this);
    this.sucessPrimeraVez                               = this.sucessPrimeraVez.bind(this);
    this.primeraVez2                               = this.primeraVez2.bind(this);
    this.sucessPrimeraVez2                               = this.sucessPrimeraVez2.bind(this);
    this.eliminarRegistro                               = this.eliminarRegistro.bind(this);
    this.confirmDelete                               = this.confirmDelete.bind(this);
    this.refescarTabla                               = this.refescarTabla.bind(this);
    this.successEliminar                               = this.successEliminar.bind(this);
    this.sucessRefrescar                               = this.sucessRefrescar.bind(this);
    this.abrirModalAgregar                               = this.abrirModalAgregar.bind(this);

    this.sucursal_ingreso='';
    this.calcularDescuento                               = this.calcularDescuento.bind(this);
    this.calcularItem                               = this.calcularItem.bind(this);
    this.traerCostoBodega                               = this.traerCostoBodega.bind(this);
    this.sucessTraerCostoBodega                               = this.sucessTraerCostoBodega.bind(this);
    this.sucessAlterno                               = this.sucessAlterno.bind(this);
    this.traerPropiedades                               = this.traerPropiedades.bind(this);
    this.sucessTraerPropiedades                               = this.sucessTraerPropiedades.bind(this);
    this.validacionesAgregar                               = this.validacionesAgregar.bind(this);

    this.sucessValidaciones                               = this.sucessValidaciones.bind(this);
    this.validacionesAgregar2                               = this.validacionesAgregar2.bind(this);
    this.grabarItem                               = this.grabarItem.bind(this);
    this.sucessGrabarItem                               = this.sucessGrabarItem.bind(this);
    this.recalcularArticulo                               = this.recalcularArticulo.bind(this);
    this.sucessRecalcularArticulo                               = this.sucessRecalcularArticulo.bind(this);
    this.grabarItem2                               = this.grabarItem2.bind(this);
    this.archivo_valido=false;
    this.lista={};

    this.validarCuenta                         =this.validarCuenta.bind(this);
    this.sucessValidarCuenta                         =this.sucessValidarCuenta.bind(this);
    this.validarfechas                         =this.validarfechas.bind(this);
    this.sucessAutorizarOrden                         =this.sucessAutorizarOrden.bind(this);
    this.autorizarOrden                         =this.autorizarOrden.bind(this);
    this.grabarOrden                         =this.grabarOrden.bind(this);
    this.sucessGrabarOrden                         =this.sucessGrabarOrden.bind(this);
    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);

    this.consecutivo_grabado='';
    this.operacion_actual='';
    this.desactivar='';
    this.smartvvpa='';
    this.cerrar='';
    this.plano='';
    this.wms='';
    this.comex='';
    this.fecha_pedidos='';
    this.wms_externo='';
    this.smartvv='';
    this.fila_seleccionada={};


    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.fecha_hoy='';

    //nuevos
    this.validarCamposFecha                         =this.validarCamposFecha.bind(this);
    this.validarConsultar                         =this.validarConsultar.bind(this);
    this.consultarPedidos                         =this.consultarPedidos.bind(this);
    this.sucessConsultarPedidos                         =this.sucessConsultarPedidos.bind(this);
    this.ocultarCampos                         =this.ocultarCampos.bind(this);
    this.activarOrden                         =this.activarOrden.bind(this);
    this.sucessActivarOrden                         =this.sucessActivarOrden.bind(this);
    this.desactivarOrden                         =this.desactivarOrden.bind(this);
    this.sucessDesactivarOrden                         =this.sucessDesactivarOrden.bind(this);
    this.cerrarOrden                         =this.cerrarOrden.bind(this);
    this.cerrarOrden2                        =this.cerrarOrden2.bind(this);
    this.sucessCerrarOrden                         =this.sucessCerrarOrden.bind(this);
    this.setButtonBotonesTabla                         =this.setButtonBotonesTabla.bind(this);
    this.crearBotonTabla                         =this.crearBotonTabla.bind(this);
    this.accionProcesar                         =this.accionProcesar.bind(this);
    this.mostrarMensajeGuardando                         =this.mostrarMensajeGuardando.bind(this);
    this.mostrarMensajeGenerando                         =this.mostrarMensajeGenerando.bind(this);

    this.sucessTraerArticulo                         =this.sucessTraerArticulo.bind(this);
    this.traerInfoArticulo                         =this.traerInfoArticulo.bind(this);
    this.sucessTraerItems                         =this.sucessTraerItems.bind(this);
    this.traerItem                         =this.traerItem.bind(this);
    this.sucessInsertarItemsWms                         =this.sucessInsertarItemsWms.bind(this);
    this.insertarItemsWms                         =this.insertarItemsWms.bind(this);
    this.enviarAWMS                         =this.enviarAWMS.bind(this);
    this.sucessEnviarAWMS                         =this.sucessEnviarAWMS.bind(this);
    this.actuliazarFechaOrden                         =this.actuliazarFechaOrden.bind(this);
    this.sucessActuliazarFechaOrden                         =this.sucessActuliazarFechaOrden.bind(this);
    this.enviarComex                         =this.enviarComex.bind(this);
    this.sucessEnviarComex                         =this.sucessEnviarComex.bind(this);
    this.modificando='false';
    this.id_modificar='';
    this.confirmarAbrirImprimir                         =this.confirmarAbrirImprimir.bind(this);
    this.traerSaldos                         =this.traerSaldos.bind(this);
    this.sucessTraerSaldos                         =this.sucessTraerSaldos.bind(this);
    this.limpiarCamposModalAgregar                         =this.limpiarCamposModalAgregar.bind(this);
    this.abrirModalFechaEntrega                         =this.abrirModalFechaEntrega.bind(this);
    this.agregarItem = false;
    this.sqlFilterId = this.sqlFilterId.bind(this);
    this.valuesFilterModifiedId = this.valuesFilterModifiedId.bind(this);
    this.filterTextLoweCase = "";
    this.filterId = false;
    this.filterPaqueteId = false;
    this.numero_orden = "";
    
  }

  initForm() {
    console.log("Formulario CustomConsultaDePedidosCompras,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez, @updateBy: Sebastian Rios E.");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.getField('responsable_iva').setError(false,'');
    this.getField('agregar_item').setClick(()=>{this.modificando='false';this.id_modificar='';this.abrirModalAgregar()});
    this.getField('sede').setOnChange((props)=>{
      this.getField('sede').setError(false,'');
      this.getField('nombre').setError(false,'');
      this.getField('responsable_iva').setError(false,'');
      if(this.getField('sede').getValue()!=='' && this.getField('sede').getValue()!==' '){
        this.validarCuenta();
      }
    });

    this.getField('costo').setOnChange((props)=>{
      this.getField('nombre_articulo').setError(false,'');
      this.getField('tarifa_iva').setError(false,'');
      this.getField('responsable_iva').setError(false,'');
      if(this.getField('costo').getValue()!=='' && this.getField('costo').getValue()!==' '){
        this.traerCostoBodega();
      }
    });

    this.getField('descuento').setOnChange(this.calcularDescuento);
    this.getField('precio_sin_iva').setOnChange(this.calcularItem);
    this.getField('cantidad_articulo').setOnChange(this.calcularItem);
    this.getField('impuestos2').setOnChange(this.calcularItem);
    this.getField('cancelar_agregar').setClick(()=>{this.getField('modal_agregar').handleClose(); this.agregarItem = false;});
    this.getField('grabar_agregar').setClick(this.grabarItem);
    
    this.getField('fecha_orden').setDisabled(true);
    this.getField('fecha_entrega').setDisabled(true);
    this.getField('fecha_entrega_mod').setOnChange(this.validarfechas);
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('consultar_desde').setValue(this.fecha_inicio);


    this.getField('aceptar_autorizar').setClick(this.autorizarOrden);
    this.getField('cancelar_autorizar').setClick(()=>{this.getField('modal_autorizacion').handleClose()});
    this.traerPropiedades();


    this.getField('documento').setDisabled(true);
    this.getField('sucursal_autorizar').setDisabled(true);
      this.getField('fecha_autorizar').setDisabled(true);

      //imprimir
      this.getField('radio_correo').setValue("N");
      //this.getField('generar_archivo').setClick(()=>{this.procesar();});
      this.getField('generar_archivo').setClick(()=>this.procesar());
      //para balances globales
      this.getField('ch_excel').setOnChange((props)=>{
        if(this.getField('ch_excel').getValue()==='false'){
          this.getField('ch_pdf').setValueSwitch (false);
          this.getField('ch_pantalla').setValueSwitch (false);
          this.operacion_actual='excel';
          this.habilitarCorreo();
        }
      });
      this.getField('ch_pdf').setOnChange((props)=>{
        if(this.getField('ch_pdf').getValue()==='false'){
          this.getField('ch_excel').setValueSwitch (false);
          this.getField('ch_pantalla').setValueSwitch (false);
          this.operacion_actual='pdf';
          this.habilitarCorreo();
        }
      });
      this.getField('ch_pantalla').setOnChange((props)=>{
        if(this.getField('ch_pantalla').getValue()==='false'){
          this.getField('ch_excel').setValueSwitch (false);
          this.getField('ch_pdf').setValueSwitch (false);
          this.operacion_actual='pantalla';
          this.deshabilitarCorreo();
        }else{
            this.habilitarCorreo();
        }
      });
      this.getField('modal_imprimir').setCloseButton(()=>{
        this.getField('nit').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre').setValue('');
        this.getField('no_orden').setValue('');
        this.getField('observaciones').setValue('');
        this.getField('fecha_entrega').setValue(this.fecha_hoy);
        this.getField('total_subtotal').setValue(0);
        this.getField('valor_iva').setValue(0);
        this.getField('impuestos').setValue(0);
        this.getField('total_pedido').setValue(0);
      });
      //nuevo para consulta
      this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
      this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);
      this.getField('consultar').setClick(this.validarConsultar);

      this.getField('numero_orden').setOnChange((props)=>{
        this.getField('consultar').setDisabled(false);
        this.ocultarCampos();
      });

      this.getField('sede_consulta').setOnChange((props)=>{
        this.getField('nit_consulta').setError(false,'');
        this.getField('sede_consulta').setError(false,'');
        this.getField('nombre_consulta').setError(false,'');
        this.getField('consultar').setDisabled(false);
        this.ocultarCampos();
      });

      this.getField('nombre_sucursal').setOnChange((props)=>{
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('nombre_sucursal').setError(false,'');
        this.getField('consultar').setDisabled(false);
        this.ocultarCampos();
      });

      this.getField('select_clasificaciones').setOnChange((props)=>{
        this.ocultarCampos();
        this.getField('consultar').setDisabled(false);
        
      });

      //botones inciales click

      this.getField('imprimir').setClick(this.abrirImprimir);
      this.getField('bt_modificar').setClick(this.primeraVez2);
      this.getField('bt_activar').setClick(this.activarOrden);
      this.getField('bt_desactivar').setClick(this.desactivarOrden);
      this.getField('bt_cerrar').setClick(this.cerrarOrden);
      this.getField('enviar_comex').setClick(()=>{this.enviarComex()});
      this.getField('bt_enviar_wms').setClick(this.enviarAWMS);
      this.getField('bt_smartvv').setClick(this.insertarItemsWms);
      this.getField('bt_fecha_entrega').setClick(this.abrirModalFechaEntrega);
      this.getField('fecha_aceptar').setClick(()=>{this.actuliazarFechaOrden()});
      this.getField('fecha_cancelar').setClick(()=>{this.getField('modal_actualizar_fecha').handleClose()});
      this.getField('modal_modificar').setCloseButton(this.consultarPedidos);
      this.getField('tarifa_iva').setOnChange(()=>{this.getField('tarifa_iva').getValue() !== '' ? this.traerSaldos() : this.getField('tarifa_iva').setError(false,'');});
      this.getField('modal_agregar').setCloseButton(()=>{this.agregarItem = false;});
      this.getField('radio_correo').setOnChange(()=>{this.getField('email').setError(false,'');});
  }

  abrirModalFechaEntrega(){
    this.getField('modal_actualizar_fecha').handleClickOpen();
    this.getField('fecha_aceptar').setDisabled(false);
    this.getField('fecha_entrega_mod').setError(false,'');
    this.getField('fecha_entrega_mod').setValue(this.getField('fecha_entrega').getValue());
  }
  
  onSelectionChangedInicio(){
    this.fila_seleccionada = this.gridOptionsComponentesInicial.api.getSelectedRows();  
  }

  enviarComex(){
    if(this.fila_seleccionada.length>0){
      if(this.fila_seleccionada[0].estado!=="A"){
        this.alertGeneral.toggle(true,'No se puede realizar el envio comex, la orden de compra debe estar en estado A.',"error");
      }else{
        this.mostrarMensajeGuardando();
        let datos={ datos: {
          no_orden: this.fila_seleccionada[0].numero+'',
          sucursal_ingreso: this.fila_seleccionada[0].codigo_sucursal,
          fecha_orden:this.fila_seleccionada[0].fecha
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'enviarcomex', operacion_tipo: 'crear' };
        this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method:'POST',
          body: datos,
          success: this.sucessEnviarComex,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false
        });
      }
    }else{
      this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
    }
  }

sucessEnviarComex(data)
{
  this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200){
      this.consultarPedidos();
    }else 
    {
      if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No hay datos', 'error');
      }
      else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }
}

  enviarAWMS(){
    if (this.fila_seleccionada.length >= 1) {
      this.mostrarMensajeGuardando();
      let datos={ datos: {
          no_orden: this.fila_seleccionada[0].numero+'',
          sucursal_ingreso: this.fila_seleccionada[0].codigo_sucursal,
          bodega: this.fila_seleccionada[0].bodega
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'enviarawms', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessEnviarAWMS,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }else{
      this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
    }
  }

sucessEnviarAWMS(data)
{
  this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200){
      this.consultarPedidos();
    }else 
    {
      if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No hay datos', 'error');
      }
      else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }
}

  insertarItemsWms(){
    if (this.fila_seleccionada.length >= 1) {
      this.mostrarMensajeGuardando();
      let datos={ datos: {
        no_orden: this.fila_seleccionada[0].numero+'',
        sucursal_ingreso: this.fila_seleccionada[0].codigo_sucursal
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'smartvv', operacion_tipo: 'crear' };
      this.service.send(
      {
        endpoint: this.constant.formConfiguration(),
        method:'POST',
        body: datos,
        success: this.sucessInsertarItemsWms,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage: false
      });
    }else{
      this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
    }
  }

sucessInsertarItemsWms(data)
{
  this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200){
      this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El pedido fue enviado exitosamente.');
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); this.consultarPedidos();});
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }else 
    {
      if(data.data.mensaje_mostrar!==undefined){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje_mostrar);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }else{
        if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      }
      
  }
}

  traerItem(props){
    this.mostrarMensajeGenerando();
    let datos={ datos: {
      value: props.data.id+'',
      nit: this.fila_seleccionada[0].nit,
      sede: this.fila_seleccionada[0].sede,
      fecha_orden:this.fila_seleccionada[0].fecha,
      no_orden: this.fila_seleccionada[0].numero+'',
      sucursal_ingreso: this.fila_seleccionada[0].codigo_sucursal,
      codigo_articulo: props.data.codigo,
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: '1_item', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerItems,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
}

sucessTraerItems(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){ 
      this.getField('codigo_articulo').setValue(data.data[0].codigo);
      this.getField('costo').setValue(data.data[0].costo)
      this.getField('precio_sin_iva').setValue(data.data[0].costo);
      this.getField('cantidad_articulo').setValue(data.data[0].cantidad);
      this.getField('descuento').setValue(data.data[0].vr_descuento);
      this.traerInfoArticulo();
      this.getField('codigo_articulo').setDisabled(false);
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.mostrarMensajeNoHayDatos();
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
}

traerInfoArticulo(){
  this.mostrarMensajeGenerando();
  let datos={ datos: {
    value: this.getField('codigo_articulo').getValue()
  }};
  this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '42', operacion_tipo: 'consulta' };
  this.service.send(
  {
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessTraerArticulo,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false
  });
  
}

sucessTraerArticulo(data){
  if(data.estado_p === 200){ 
    this.getField('nombre_articulo').setValue(data.data[0].nombre);
    this.getField('tarifa_iva').setValue(data.data[0].vivac);
    this.getField('impuestos_articulo').setValue(data.data[0].impuesto1);
    this.getField('impuestos2').setValue(data.data[0].impuesto1);
    //this.getField('costo').setValue(data.data[0].costo);
    // this.traerSaldos(); // Se comenta, ya que se hace un setOnchange al campo "tarifa_iva" para consumir dicho servicio
  
    
  }else if(data.estado_p === 404){
      this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
      this.getField('confirmModalCustom').toggle(false);
  }
  else{
      let respuesta=Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      this.getField('confirmModalCustom').toggle(false);
  } 
}

traerSaldos(){
  let datos={ datos: {
    codigo_articulo: this.getField('codigo_articulo').getValue()
  }};
  this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'traersaldos', operacion_tipo: 'consulta' };
  this.service.send(
  {
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessTraerSaldos,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false
  });
}

sucessTraerSaldos(data){
  this.getField('confirmModalCustom').toggle(false);
  if(data.estado_p === 200 && data.data.length >= 1){ 
    this.getField('saldo1').setValue(data.data[0].saldo1);
    this.getField('saldo2').setValue(data.data[0].saldo2);
    this.getField('saldo3').setValue(data.data[0].saldo3);
    if (!this.agregarItem) {
      this.getField('modal_agregar').handleClickOpen();
      this.getField('codigo_articulo').setDisabled(true);
    }
  }else if(data.estado_p === 404){
    this.alertGeneral.toggle(true, 'No hay saldos para el artículo.', 'error');
  }else{
    let respuesta=Object.values(data.data.errores);
    this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
  }
}

  mostrarMensajeGuardando(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{});  
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);

  }

  mostrarMensajeGenerando(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{});  
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
    this.getField('consultar').setDisabled(true);

  }

  validarfechas(){
    this.getField('fecha_aceptar').setDisabled(false);
    let fecha_actual =new Date().toISOString().split('T')[0];
    let fecha_entrega_mod = this.getField("fecha_entrega_mod").getValue() !== '' ? 
    new Date(this.getField("fecha_entrega_mod").getValue()).toISOString().split('T')[0] : 
    "";

    this.getField("fecha_entrega_mod").setError(false,"");

    if(fecha_entrega_mod < fecha_actual){
      this.getField('fecha_aceptar').setDisabled(true);
      this.getField("fecha_entrega_mod").setError(true,"No puede ser menor a la fecha actual.");
      return false;
    }
    this.getField("fecha_entrega_mod").setError(false,"");
    return true;

}



actuliazarFechaOrden(){
  if(this.validarfechas()){
    this.mostrarMensajeGuardando();
    let datos={ datos: {
      numero_orden:Number(this.fila_seleccionada[0].numero),
      codigo_sucursal:this.fila_seleccionada[0].codigo_sucursal,
      estado:this.fila_seleccionada[0].estado,
      fecha_orden:this.fila_seleccionada[0].fecha,
      sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
      fecha_entrega_mod:this.getField('fecha_entrega_mod').getValue(),
      nit:this.fila_seleccionada[0].nit,
      sede:this.fila_seleccionada[0].sede,
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'modificar_entrega', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.sucessActuliazarFechaOrden,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }
}
sucessActuliazarFechaOrden(data)
{      
  this.getField('confirmModalCustom').toggle(false);
  if (data.estado_p === 200)
  {   
      this.getField('fecha_entrega').setValue(this.getField('fecha_entrega_mod').getValue());       
      this.getField('modal_actualizar_fecha').handleClose();
  }
  else 
  {
    if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'No hay datos', 'error');
    }
    else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }
}


  cerrarOrden(){
    if(this.fila_seleccionada.length>0){
      if(this.fila_seleccionada[0].estado==="C"){
        this.alertGeneral.toggle(true,'El pedido ya se encuentra cerrado.',"error");
      }else{
        this.mostrarMensajeGuardando();
        let datos={ datos: {
          numero_orden:this.fila_seleccionada[0].numero+'',
          codigo_sucursal:this.fila_seleccionada[0].codigo_sucursal,
          estado:this.fila_seleccionada[0].estado,
          fecha_orden:this.fila_seleccionada[0].fecha,
          sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'cerrarorden', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessCerrarOrden,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    }else{
        this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
    }
}

cerrarOrden2(input){
  if(this.fila_seleccionada.length>0){
    if(this.fila_seleccionada[0].estado==="C"){
      this.alertGeneral.toggle(true,'El pedido ya se encuentra cerrado.',"error");
    }else{
      this.mostrarMensajeGuardando();
      let datos={ datos: {
        numero_orden:this.fila_seleccionada[0].numero+'',
        codigo_sucursal:this.fila_seleccionada[0].codigo_sucursal,
        estado:this.fila_seleccionada[0].estado,
        fecha_orden:this.fila_seleccionada[0].fecha,
        sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
        input:input
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'cerrarorden', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'PUT',
              body: datos,
              success: this.sucessCerrarOrden,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }
  }else{
      this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
  }
  }

sucessCerrarOrden(data)
{   
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200)
    {
        if(data.data.confirmacion !==undefined){
          this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje);
          this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.cerrarOrden2('0') });
          this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
        }else{
          this.consultarPedidos();
        }
    }
    else 
    {
      if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'No hay datos', 'error');
      }
      else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
    }
}


    activarOrden(){
      if(this.fila_seleccionada.length>0){
        if(this.fila_seleccionada[0].estado==="A"){
          this.alertGeneral.toggle(true,'El pedido ya se encuentra activo.',"error");
        }else{
          this.getField('modal_autorizacion').handleClickOpen();
          this.getField('documento').setValue(this.fila_seleccionada[0].numero);
          this.getField('sucursal_autorizar').setValue(this.fila_seleccionada[0].codigo_sucursal);
          this.getField('fecha_autorizar').setValue(this.fila_seleccionada[0].fecha);
          this.getField('numero_autorizacion').setError(false,'');
        }
      }else{
          this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
      }
  }

  sucessActivarOrden(data)
  {   
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200)
      {
          this.getField('modal_autorizacion').handleClose();
          this.consultarPedidos();
      }
      else 
      {
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      }
  }

  desactivarOrden(){
    if(this.fila_seleccionada.length>0){
      if(this.fila_seleccionada[0].estado==="I"){
        this.alertGeneral.toggle(true,'El pedido ya se encuentra inactivo.',"error");
      }else{
        this.mostrarMensajeGuardando();
        let datos={ datos: {
          numero_orden:this.fila_seleccionada[0].numero+'',
          codigo_sucursal:this.fila_seleccionada[0].codigo_sucursal,
          estado:this.fila_seleccionada[0].estado,
          fecha_orden:this.fila_seleccionada[0].fecha,
          sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'desactivarorden', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessDesactivarOrden,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    }else{
        this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
    }
}

sucessDesactivarOrden(data)
{   
  this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200)
    {
        this.consultarPedidos();
    }
    else 
    {
      if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'No hay datos', 'error');
      }
      else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
    }
}

  validarCamposFecha(){
    this.fila_seleccionada={}
    this.getField('consultar').setDisabled(false);
    this.ocultarCampos();
    let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
    let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
    let fecha_actual = new Date().toISOString().split('T')[0];
    if (fechaInicial > fechaFinal) {
        this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
        this.getField('consultar').setDisabled(true);
        return false;
    } 

    if(fechaFinal > fecha_actual){
        this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
        this.getField('consultar').setDisabled(true);
        return false;
    }
    this.getField('consultar_hasta').setError(false,"");
    return true;
}

  validarConsultar(){
    this.getField('numero_orden').setError(false,'');
    this.getField('nit_consulta').setError(false,'');
    this.getField('sede_consulta').setError(false,'');
    this.getField('nombre_consulta').setError(false,'');
    this.getField('codigo_sucursal').setError(false,'');
    this.getField('nombre_sucursal').setError(false,'');

    let errores=0;
    if(!this.getField('consultar_hasta').valid())
        errores++;
    if(this.validarCamposFecha()===false)
        errores++;
    if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
        if(!this.getField('nit_consulta').valid())
            errores++;
        if(!this.getField('sede_consulta').valid())
            errores++;
        if(!this.getField('nombre_consulta').valid())
            errores++;
    }
    if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
        if(!this.getField('codigo_sucursal').valid())
            errores++;
        if(!this.getField('nombre_sucursal').valid())
            errores++;
    }
    if(this.getField('numero_orden').getValue()!=='' && this.getField('numero_orden').getValue()!==' '){
        if(!this.getField('numero_orden').valid())
            errores++;
    }
    if(!this.getField('select_clasificaciones').valid())
            errores++;

    if(errores===0){
        this.consultarPedidos();
    }

  }

  
  ocultarCampos(){
    this.gridOptionsComponentesInicial['rowData'] = [];
    this.getField('tabla_pedidos').toggle(false);

    this.getField('imprimir').setVisible(false);
    this.getField('bt_modificar').setVisible(false);
    this.getField('bt_activar').setVisible(false);
    this.getField('bt_desactivar').setVisible(false);
    this.getField('bt_cerrar').setVisible(false);
    this.getField('bt_enviar_wms').setVisible(false);
    this.getField('bt_smartvv').setVisible(false);
    this.getField('enviar_comex').setVisible(false);
    this.fila_seleccionada={};
}


  consultarPedidos(){
    this.mostrarMensajeGenerando();
    this.getField('consultar').setDisabled(true);
    let datos={ datos: {
        sucursal_ingreso: this.sucursal_ingreso,
        select_clasificaciones:this.getField('select_clasificaciones').getValue(),
        consultar_desde:this.getField('consultar_desde').getValue(),
        consultar_hasta:this.getField('consultar_hasta').getValue(),

    }};
    if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
        datos.datos.nit=this.getField('nit_consulta').getValue();
        datos.datos.sede=this.getField('sede_consulta').getValue();
    }
    if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
        datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
    if(this.getField('numero_orden').getValue()!=='' && this.getField('numero_orden').getValue()!==' ')
        datos.datos.numero_orden=this.getField('numero_orden').getValue();

    this.getField("tabla_pedidos").setTipoServicioOperacion("comp-consultapedidos-consultar");
    this.getField("tabla_pedidos").sendRequest(this.sucessConsultarPedidos, datos);
        
    /* this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'consultar', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessConsultarPedidos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    }); */
}

sucessConsultarPedidos(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        this.getField('imprimir').setVisible(true);
        this.getField('bt_modificar').setVisible(true);
        this.getField('bt_activar').setVisible(true);
        this.getField('bt_desactivar').setVisible(true);
        this.getField('bt_cerrar').setVisible(true);
        // this.getField('bt_enviar_wms').setVisible(true); // Se comenta esta línea, ya que estaban generando conflicto para mostrarse y/o ocultarse, ya que mas abajo está la validación para que se muestre u oculte dicho botón.
        // this.getField('bt_smartvv').setVisible(true); // Se comenta esta línea, ya que estaban generando conflicto para mostrarse y/o ocultarse, ya que mas abajo está la validación para que se muestre u oculte dicho botón.
        this.getField('enviar_comex').setVisible(true);
        

        if(this.wms_externo==='S')
            this.getField('bt_enviar_wms').setVisible(true);
        else 
            this.getField('bt_enviar_wms').setVisible(false);
        if(this.smartvv==='S')
              this.getField('bt_smartvv').setVisible(true);
        else 
            this.getField('bt_smartvv').setVisible(false);

        this.getField('tabla_pedidos').toggle(true);
        this.gridOptionsComponentesInicial['rowData'] = [];
        let configCell = new Map();
        configCell.set("numero",{
          filter: "agNumberColumnFilter",
          filterParams: {
              filterOptions: ['contains'],
              buttons: ['apply'],
              suppressAndOrCondition: true,
              closeOnApply: true,
              textCustomComparator:  (filter, value, filterText) => {
                this.filterTextLoweCase = filterText.toLowerCase();
                  this.filterTextLoweCase = filterText.toLowerCase();
                  return true;
              },
              debounceMs: 200,
              maxNumConditions: 1,
          }
        });

        this.gridOptionsComponentesInicial.onFilterChanged = (event) => this.sqlFilterId(event);
        this.gridOptionsComponentesInicial.onFilterModified = (event) => this.valuesFilterModifiedId(event);
        this.gridOptionsComponentesInicial['rowData'] = data.data;
        this.getField('tabla_pedidos').initData(this.gridOptionsComponentesInicial,configCell);
          
    }else{
        this.ocultarCampos();
        if(data.estado_p===404) {
            this.getField('consultar').setDisabled(false);
            this.getField('imprimir').setVisible(false);
            this.getField('bt_modificar').setVisible(false);
            this.getField('bt_activar').setVisible(false);
            this.getField('bt_desactivar').setVisible(false);
            this.getField('bt_cerrar').setVisible(false);
            this.getField('bt_enviar_wms').setVisible(false);
            this.getField('bt_smartvv').setVisible(false);
            this.getField('enviar_comex').setVisible(false);
            // this.alertGeneral.toggle(true, 'No hay datos', 'error'); // Se comenta, ya que la respuesta del servicio lo muestra.
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
}

sqlFilterId(e){
  if (this.filterTextLoweCase !== '') {
    if (this.filterTextLoweCase.toString().length >= 4) {
      this.numero_orden = parseInt(this.filterTextLoweCase);
      let datos = { datos: {
        numero_orden: parseInt(this.filterTextLoweCase),
        sucursal_ingreso: this.sucursal_ingreso,
        select_clasificaciones:this.getField('select_clasificaciones').getValue(),
        consultar_desde:this.getField('consultar_desde').getValue(),
        consultar_hasta:this.getField('consultar_hasta').getValue()
      }};
      if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
        datos.datos.nit=this.getField('nit_consulta').getValue();
        datos.datos.sede=this.getField('sede_consulta').getValue();
      }
      if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
          datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
      if(this.getField('numero_orden').getValue()!=='' && this.getField('numero_orden').getValue()!==' ')
          datos.datos.numero_orden=this.getField('numero_orden').getValue();
      
      this.getField("tabla_pedidos").setTipoServicioOperacion("comp-consultapedidos-consultarnum");
      this.getField("tabla_pedidos").sendRequest(this.sucessConsultarPedidos, datos);
      
      /* this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'consultarnum', operacion_tipo: 'consulta' };
      this.service.send(
      {
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.sucessConsultarPedidos,
        error: this.error_,
        general: this.generalFormatPmv
      }); */
    }else{
      this.alertGeneral.toggle(true, 'El número ingresado debe ser mayor a 4 digitos.', 'error');
    }
  }else{
    this.alertGeneral.toggle(true, 'Debe ingresar algún valor.', 'error');
  }
}

valuesFilterModifiedId(event){
  if(event.filterInstance.scalarFilterParams !== undefined && event.filterInstance.scalarFilterParams.column.colId === 'numero'){
    this.filterId = true;
    if(event.filterInstance.eValue1 !== undefined){
        this.filterTextLoweCase = event.filterInstance.eValue1.value;
    }else if(event.filterInstance.eValueFrom1 !== undefined){
        this.filterTextLoweCase = event.filterInstance.eValueFrom1.eInput.value;
    }else{
        this.filterTextLoweCase = event.filterInstance.eConditionBodies[0].children[0].children[1].children[0].value;
    }
  }else{
      this.filterId = false;
      this.filterPaqueteId = false;
  }
  
}

  deshabilitarCorreo(){
    this.getField('email').setDisabled(true);
    this.getField('email').setValue('');
    this.getField('email').setError(false,'');
    this.getField('radio_correo').setDisabled(true);
    this.getField('radio_correo').setValue("N");
}

habilitarCorreo(){
    this.getField('email').setDisabled(false);
    this.getField('radio_correo').setDisabled(false);
    this.getField('radio_correo').setValue("N");
}


  grabarOrden(){
      let datos={ datos: {
          select_direccion: this.getField('select_direccion').getValue(),
          nit: this.getField('nit').getValue(),
          nombre: this.getField('nombre').getValue(),
          sede: this.getField('sede').getValue(),
          fecha_orden: this.getField('fecha_orden').getValue(),
          fecha_entrega: this.getField('fecha_entrega').getValue(),
          total_pedido: this.getField('total_pedido').getValue(),
          valor_iva: this.getField('valor_iva').getValue(),
          impuestos: this.getField('impuestos').getValue(),
          observaciones: this.getField('observaciones').getValue(),
          referencia: this.getField('referencia').getValue(),
          codigo_interno: this.getField('codigo_interno').getValue(),
          sucursal_ingreso: this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'grabarorden', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarOrden,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  sucessGrabarOrden(data)
  {
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje',data.data.mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        if(data.data.imprimir===true){
          this.getField('modal_autorizacion').handleClose();
          this.consecutivo_grabado=data.data.consecutivo;
          this.abrirImprimir();
        }else{
          this.limpiarCampos();
        }
      }else 
      {
        if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
  }

  abrirImprimir(){
    if(this.fila_seleccionada.length>0){
      if (this.fila_seleccionada[0].estado !== 'A') {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Orden de compra inactiva.');
        this.getField('confirmModalCustom').setClickDialog(() => {
          this.confirmarAbrirImprimir();
          this.getField('confirmModalCustom').toggle(false);
        });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }else{
        this.confirmarAbrirImprimir();
      }
    }else{
      this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
    }
  }

  confirmarAbrirImprimir(){
    if(this.fila_seleccionada.length>0){
      // this.getField("modal_imprimir").setLabel("Orden de Compra No. "+this.consecutivo_grabado);
      this.getField("modal_imprimir").setLabel("Orden de Compra No. "+this.fila_seleccionada[0].numero);
      this.getField('modal_imprimir').handleClickOpen();
      this.getField('email').setValue('');
      this.getField('email').setError(false,'');
      this.getField('radio_correo').setValue("N");
      this.getField('ch_excel').setValueSwitch(false);
      this.getField('ch_pdf').setValueSwitch(false);
      this.getField('ch_pantalla').setValueSwitch(false);
      this.getField('email').setDisabled(false);
    }else{
      this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
    }
  }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'/*  || this.operacion_actual!=='pantalla' */){ // Se comenta la validación "que sea diferente a pantalla" ya que en la tarea "JQJD-1430" solicitaron que "Para la presente modal no se debe solicitar email como obligatorio teniendo en cuenta que los informes que genera no son tan extensos y por ende no afectaría el tamaño máximo permitido por el kafka, únicamente que requiera el correo cuando el usuario seleccione la opción de que desea que el informe le llegue por este medio.".
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.nombre_pdf='orden_compra';
                    this.nombre_excel='orden_compra';
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    genearModalGlobales(){
      this.mostrarMensajeGenerando();
      let datos={ datos: {
        nit: this.fila_seleccionada[0].nit,
        sede: this.fila_seleccionada[0].sede,
        fecha_orden:this.fila_seleccionada[0].fecha,
        no_orden: this.fila_seleccionada[0].numero+'',
        email: this.getField('email').getValue(),
        radio_correo: this.getField('radio_correo').getValue(),
        sucursal_ingreso: this.fila_seleccionada[0].codigo_sucursal,
        operacion: this.operacion_actual
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'archivoitems', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessTraerDatos,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
      
  }

  sucessTraerDatos(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit": this.lista.nit,
                    "direccion": this.lista.direccion,
                    "telefonos": this.lista.telefonos,
                    "ciudad": this.lista.ciudad,
                    "orden_de_compra": this.lista.orden_de_compra,
                    "bodega": this.lista.bodega,
                    "fecha_pedido": this.lista.fecha_pedido,
                    "fecha_entrega": this.lista.fecha_entrega,

                };
            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);

            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            configCell.set('codigo', {headerName: 'CÓDIGO'});
            configCell.set('nombre', {headerName: 'NOMBRE'});
            configCell.set('alias', {headerName: 'PRESENTACIÓN'});
            configCell.set('cantidad', {headerName: 'CANT.',cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {headerName: 'COSTO',cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_iva', {headerName: 'VR. IVA',cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_item', {headerName: 'VALOR ÍTEM',cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pendiente', {headerName: 'PEND.',cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pendiente) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('proveedor_result').setValue(this.lista.proveedor);
            this.getField('direcccion').setValue(this.lista.direccion_nit);
            this.getField('telefono').setValue(this.lista.telefono_nit);
            this.getField('ciudad').setValue(this.lista.ciudad_nit);
            this.getField('observaciones_result').setValue(this.lista.observa);
            this.getField('valor_extento').setValue(this.currencyFormatterGeneral(this.lista.valor_extento));
            this.getField('valor_grabado').setValue(this.currencyFormatterGeneral(this.lista.valor_grabado));
            this.getField('iva_result').setValue(this.currencyFormatterGeneral(this.lista.iva_result));
            this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total_result));

            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }


    enviarCorreoTerceros(){
      this.mostrarMensajeGenerando();
      let datos={ datos: {
          nit: this.fila_seleccionada[0].nit,
          sede: this.fila_seleccionada[0].sede,
          fecha_orden:this.fila_seleccionada[0].fecha,
          no_orden: this.fila_seleccionada[0].numero+'',
          email: this.getField('email').getValue(),
          radio_correo: this.getField('radio_correo').getValue(),
          sucursal_ingreso: this.fila_seleccionada[0].codigo_sucursal,
          operacion: this.operacion_actual
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'archivoitems', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successEnviarCorreo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }
  
  successEnviarCorreo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
          this.mostrarMensajeEmailEnviado();
      }else{
          this.mostrarMensajeEmailFallo();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      }
  }

  mostrarMensajeEmailEnviado(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeEmailFallo(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeNoHayDatos(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
}

  generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= {
              nit: this.fila_seleccionada[0].nit,
              sede: this.fila_seleccionada[0].sede,
              fecha_orden:this.fila_seleccionada[0].fecha,
              no_orden: this.fila_seleccionada[0].numero+'',
              email: this.getField('email').getValue(),
              radio_correo: this.getField('radio_correo').getValue(),
              sucursal_ingreso: this.fila_seleccionada[0].codigo_sucursal,
              operacion: this.operacion_actual
            };
            let op='archivoitems';
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabarpedidos","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= {
              nit: this.fila_seleccionada[0].nit,
              sede: this.fila_seleccionada[0].sede,
              fecha_orden:this.fila_seleccionada[0].fecha,
              no_orden: this.fila_seleccionada[0].numero+'',
              email: this.getField('email').getValue(),
              radio_correo: this.getField('radio_correo').getValue(),
              sucursal_ingreso: this.fila_seleccionada[0].codigo_sucursal,
              operacion: this.operacion_actual
            };
            let op='archivoitems';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabarpedidos","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_excel+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }


  autorizarOrden(){
    if (this.getField('numero_autorizacion').valid()) {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
                
      let datos={ datos: {
        documento:this.getField('documento').getValue(),
        sucursal_autorizar:this.getField('sucursal_autorizar').getValue(),
        fecha_autorizar:this.getField('fecha_autorizar').getValue(),
        numero_autorizacion:this.getField('numero_autorizacion').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'autorizarorden', operacion_tipo: 'consulta' };
      this.service.send(
      {
        endpoint: this.constant.formConfiguration(),
        method:'PUT',
        body: datos,
        success: this.sucessAutorizarOrden,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage: false
      });
    }
  }

  sucessAutorizarOrden(data)
  {   
      this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200)
      {
          if(data.data.autorizado===true){
            this.mostrarMensajeGuardando();
            let datos={ datos: {
              numero_orden:Number(this.fila_seleccionada[0].numero),
              codigo_sucursal:this.fila_seleccionada[0].codigo_sucursal,
              estado:this.fila_seleccionada[0].estado,
              fecha_orden:this.fila_seleccionada[0].fecha,
              sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'activarorden', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.sucessActivarOrden,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
          }else{
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje',data.data.mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
          }
      }
      else 
      {
        if(data.estado_p===404) {
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje',data.data.mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      }
  }


  validarCuenta(){
    let datos={ datos: {
      nit:this.getField('nit').getValue(),
      sede:this.getField('sede').getValue(),
      sucursal_ingreso:this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'validarcontable', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarCuenta,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  sucessValidarCuenta(data)
  {   
      if (data.estado_p === 200)
      {

      }
      else 
      {
        this.getField('nit').setValue('');
        this.getField('nombre').setValue('');
        this.getField('sede').setValue('');
         if(data.estado_p===404) {

              this.alertGeneral.toggle(true, 'No hay datos', 'error');
         }
         else {
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.errores.mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
         }
     }
  }


  onSelectionChanged(){

  }

  validacionesAgregar(){
    if(Number(this.getField('total_item').getValue()!==0)){
        this.getField('grabar_agregar').setDisabled(true);
        if(this.getField('codigo_articulo').valid() 
        && this.getField('nombre_articulo').valid()
        && this.getField('tarifa_iva').valid() 
        && this.getField('descuento').valid() 
        && this.getField('cantidad_articulo').valid()
        && this.getField('impuestos2').valid()){
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
          this.getField('confirmModalCustom').setClickDialog(()=>{});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);

            let datos={ datos: {
              sucursal_ingreso:this.sucursal_ingreso,
              select_direccion:this.getField('var_bodega').getValue(),
              costo:this.getField('costo').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessValidaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }else{
        this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
    }
      
  }

  validacionesAgregar2(input){
    this.getField('grabar_agregar').setDisabled(true);
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{});  
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);

    if(this.getField('codigo_articulo').valid()){
        let datos={ datos: {
              sucursal_ingreso:this.sucursal_ingreso,
              input:input,
              select_direccion:this.getField('var_bodega').getValue(),
              costo:this.getField('costo').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessValidaciones,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
}

  sucessValidaciones(data)
    {
      this.getField('confirmModalCustom').toggle(false);
      this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200)
        { 
            if(data.data.costo_pro!==undefined){
              //pedir el input
              let costo_pro=data.data.costo_pro;
              if(costo_pro===0){
                  this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El costo_pro_'+this.getField('var_bodega').getValue()+' es cero. ¿Está seguro de continuar?');
                  this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                  this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                  this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                  this.getField('confirmModalCustom').toggle(true);
              }else{
                  let valor_costo = Math.abs((costo_pro - this.getField('costo').getValue())/ costo_pro) * 100;
                  let margen_costo_promedio=data.data.margen_costo_promedio;

                  if (valor_costo > margen_costo_promedio) {
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El valor del costo sobrepasa el margen costo entrada. Margen costo entrada= '+margen_costo_promedio+'. Variación='+valor_costo+'. ¿Está seguro de continuar? ');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                  }else{
                    this.validacionesAgregar2(0)
                  }
              }
            }else{
              this.grabarItem();
            }
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    grabarItem(){
      if(this.getField('codigo_articulo').valid() 
      && this.getField('nombre_articulo').valid()
      && this.getField('tarifa_iva').valid() 
      && this.getField('descuento').valid() 
      && this.getField('cantidad_articulo').valid()
      && this.getField('impuestos2').valid()){
        this.mostrarMensajeGuardando();
        this.getField('grabar_agregar').setDisabled(true);
        if(Number(this.getField('total_item').getValue()!==0)){
        
    
          let datos={ datos: {
            bodega: this.getField('var_bodega').getValue(),
            codigo_articulo: this.getField('codigo_articulo').getValue(),
            cantidad_articulo: this.getField('cantidad_articulo').getValue(),
            nombre_articulo: this.getField('nombre_articulo').getValue(),
            tarifa_iva: this.getField('tarifa_iva').getValue(),
            costo: this.getField('precio_sin_iva').getValue(),
            impuestos_articulo: this.getField('impuestos_articulo').getValue(),
            valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
            valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
            valor_unidad: this.getField('valor_unidad').getValue(),
            total_item: this.getField('total_item').getValue(),
            sucursal_ingreso : this.fila_seleccionada[0].codigo_sucursal,
            modificando: this.modificando,
            no_orden:this.fila_seleccionada[0].numero+'',
            estado:this.fila_seleccionada[0].estado,
            fecha_orden:this.fila_seleccionada[0].fecha,
            nit:this.fila_seleccionada[0].nit,
            sede:this.fila_seleccionada[0].sede
          }};
          if(this.modificando){
            datos.datos.value=this.id_modificar;
          }
          
          this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'grabaritem_mod', operacion_tipo: 'crear' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.sucessGrabarItem,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
        }else{
          this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
        }
      }
        
    }

    grabarItem2(opcion){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      this.getField('grabar_agregar').setDisabled(true);

      let datos={ datos: {
        bodega: this.getField('var_bodega').getValue(),
        codigo_articulo: this.getField('codigo_articulo').getValue(),
        cantidad_articulo: this.getField('cantidad_articulo').getValue(),
        nombre_articulo: this.getField('nombre_articulo').getValue(),
        tarifa_iva: this.getField('tarifa_iva').getValue(),
        costo: this.getField('costo').getValue(),
        impuestos_articulo: this.getField('impuestos_articulo').getValue(),
        valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
        valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
        valor_unidad: this.getField('valor_unidad').getValue(),
        total_item: this.getField('total_item').getValue(),
        sucursal_ingreso : this.fila_seleccionada[0].codigo_sucursal,
        input:0

      }};
      
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'grabaritem', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarItem,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    sucessGrabarItem(data){
        this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false);
            if(data.data.input !== undefined){
                //preguntar si desea adicionar
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.recalcularArticulo() });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.getField('modal_agregar').handleClose();
                this.primeraVez();
            }
        }
        else 
        { 
            this.getField('confirmModalCustom').toggle(false);
            if(data.data.mensaje_mostrar!==undefined){
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje_mostrar);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }
            else if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }
  

    recalcularArticulo(){
      this.getField('grabar_agregar').setDisabled(true);
      let datos={ datos: {
        codigo_articulo:this.getField('codigo_articulo').getValue(),
        sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: '1_articulo', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessRecalcularArticulo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    sucessRecalcularArticulo(data)
    {
        if (data.estado_p === 200)
        {
          this.getField('cantidad_articulo').setValue(this.getField('cantidad_articulo').getValue()+data.data[0].cantidad);
          this.grabarItem2(0);
        }
        else 
        {
            this.getField('grabar_agregar').setDisabled(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

  abrirModalAgregar(){
    this.limpiarCamposModalAgregar();
    this.getField('modal_agregar').handleClickOpen();
    this.agregarItem = true;
    this.getField('codigo_articulo').setDisabled(false);
  }

  limpiarCamposModalAgregar(){
    this.getField('codigo_articulo').setError(false,'');
    this.getField('nombre_articulo').setError(false,'');
    this.getField('tarifa_iva').setError(false,'');
    this.getField('descuento').setError(false,'');
    this.getField('cantidad_articulo').setError(false,'');
    this.getField('impuestos2').setError(false,'');

    this.getField('precio_sin_iva').setError(false,'');
    this.getField('valor_sin_iva_ma_impuestos').setError(false,'');
    this.getField('total_iva').setError(false,'');
    this.getField('saldo1').setError(false,'');
    this.getField('valor_iva_unidad').setError(false,'');
    this.getField('total_impuestos').setError(false,'');
    this.getField('saldo2').setError(false,'');
    this.getField('valor_unidad').setError(false,'');
    this.getField('total_item').setError(false,'');
    this.getField('saldo3').setError(false,'');
    this.getField('costo_bodega').setError(false,'');


    this.getField('codigo_articulo').setValue('');
    this.getField('nombre_articulo').setValue('');
    this.getField('tarifa_iva').setValue('');
    this.getField('descuento').setValue(0);
    this.getField('cantidad_articulo').setValue(0);
    this.getField('impuestos2').setValue(0);


    this.getField('precio_sin_iva').setValue(0);
    this.getField('valor_sin_iva_ma_impuestos').setValue(0);
    this.getField('total_iva').setValue(0);
    this.getField('saldo1').setValue(0);
    this.getField('valor_iva_unidad').setValue(0);
    this.getField('total_impuestos').setValue(0);
    this.getField('saldo2').setValue(0);
    this.getField('valor_unidad').setValue(0);
    this.getField('total_item').setValue(0);
    this.getField('saldo3').setValue(0);
    this.getField('costo_bodega').setValue(0);
  }
  
  primeraVez2(){
    if(this.fila_seleccionada.length>0){
      if(!(this.fila_seleccionada[0].estado==='A' || this.fila_seleccionada[0].estado==='C')){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
          numero_orden:Number(this.fila_seleccionada[0].numero),//numero
          codigo_sucursal:this.fila_seleccionada[0].codigo_sucursal,//sucursal
          consultar_desde:this.fila_seleccionada[0].fecha,//fecha
          nit_consulta:this.fila_seleccionada[0].nit,//proveedor
          sede_consulta:Number(this.fila_seleccionada[0].sede)//sede
        }};

        this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'existeitemspedidos', operacion_tipo: 'consulta' };
        this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessPrimeraVez2,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false
        });
      }else{
        this.alertGeneral.toggle(true,'Orden de compra no puede ser modificada.',"error");
      }
        
    }else{
      this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
    }
  }

  sucessPrimeraVez2(data){
    if (data.estado_p === 200) {
      if(data.data[0].total > 0){
        this.getField('confirmModalCustom').toggle(false);
        const mensaje = 'No se puede modificar el pedido. Este pedido\nya ha sido recibido total o parcialmente.';
        this.getField('confirmModalCustom').setTitleAndContent('Pedidos', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }else{
        // Nota: No se consume el servicio de traer la bodega de la tabla "pedidos_compras" como está en escritorio, ya que dicho valor lo traemos de la tabla principal y se omite con el fin de reducir peticiones innecesarias al servidor.
        this.primeraVez();
      }
    }else{
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'No hay datos', 'error');
      }else{
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
   }
  }
  
  primeraVez(){
    let datos={ datos: {
      no_orden:this.fila_seleccionada[0].numero+'',
      nit:this.fila_seleccionada[0].nit+'',
      sede:this.fila_seleccionada[0].sede,
      estado:this.fila_seleccionada[0].estado,
      fecha_orden:this.fila_seleccionada[0].fecha,
      sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
    }};

    this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'cargarprimeravezmod', operacion_tipo: 'consulta' };
    this.service.send(
    {
      endpoint: this.constant.formConfiguration(),
      method:'GET',
      body: datos,
      success: this.sucessPrimeraVez,
      error: this.error_,
      general: this.generalFormatPmv,
      showMessage: false
    });
  }

    sucessPrimeraVez(data)
    {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200)
        {
          this.getField('modal_modificar').handleClickOpen();
          this.gridOptionsComponentes['rowData'] = [];
          let configCell = new Map();
          this.gridOptionsComponentes['rowData'] = data.data.datos;
          configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('accion', { cellRenderer: this.setButtonBotonesTabla, width: 250, sortable: false, filter: false });

          this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
          this.getField('rejilla').toggle(true);
          //llenar totales
          this.getField('total_subtotal').setValue(data.data.totales.subtotal.toFixed(2));
          this.getField('valor_iva').setValue(data.data.totales.total_iva.toFixed(2));
          this.getField('impuestos').setValue(data.data.totales.total_impuestos.toFixed(2));
          this.getField('total_pedido').setValue(data.data.totales.total_pedido.toFixed(2));
          //llenar datos terceros
          this.getField('nit').setDisabled(true);
          this.getField('nit').setValue(this.fila_seleccionada[0].nit);
          this.getField('sede').setValue(this.fila_seleccionada[0].sede);
          this.getField('responsable_iva').setValue(data.data.datos_tecero[0].responsable_iva);
          this.getField('nombre').setValue(data.data.datos_tecero[0].nombre);
          this.getField('estado').setValue(data.data.datos_tecero[0].estado);     
          this.getField('no_orden').setValue(this.fila_seleccionada[0].numero); 
          this.getField('observaciones').setValue(this.fila_seleccionada[0].observaciones);     
          this.getField('var_bodega').setValue(this.fila_seleccionada[0].bodega);     
          this.getField('fecha_orden').setValue(this.fila_seleccionada[0].fecha); 
          this.getField('fecha_entrega').setValue(this.fila_seleccionada[0].vence);    
          this.getField('fecha_entrega_mod').setValue(this.fila_seleccionada[0].vence);     
        }
        else 
        {
          this.getField('rejilla').toggle(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }

  }

  setButtonBotonesTabla(props){
      let fragment = document.createDocumentFragment();
      let buttonModificar = this.crearBotonTabla(props, `Modificar`);
      let buttonEliminar = this.crearBotonTabla(props, 'Eliminar');
      buttonModificar.setAttribute("class","buttonStyle2");
      buttonEliminar.setAttribute("class","buttonStyle2");
      fragment.appendChild(buttonModificar);
      fragment.appendChild(buttonEliminar);
      return this.createElementJaivana(fragment);
  }

crearBotonTabla(data, boton) {
    let button = document.createElement('input');
    switch (boton) {
        case 'Modificar':
            button.setAttribute("id", `buttonModificar${data}`);
            break;
        case 'Eliminar':
            button.setAttribute("id", `buttonEliminar_${data}`);
            break;
        default:
            break;
    }
    button.onclick = () => {this.accionProcesar(data, boton) };
    button.setAttribute("value", boton);
    button.setAttribute("type", "button");
    return button;
}

accionProcesar(data, boton) {
    if (boton === 'Eliminar') {
        this.eliminarRegistro(data);
    }else if (boton === 'Modificar'){
        this.agregarItem = false;
        this.modificando='true';
        this.id_modificar=data.data.id
        this.traerItem(data);
    }
}
  
  eliminarRegistro(props) {
      this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
      this.getField('confirmModalCustom').setClickDialog(()=>this.confirmDelete(props));
      this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
  }

  confirmDelete(props) {
    this.mostrarMensajeGuardando();
    this.getField('confirmModalCustom').toggle(false);
    let datos = {
        datos: {
          sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
          no_orden:this.fila_seleccionada[0].numero+'',
          nit:this.fila_seleccionada[0].nit,
          sede:this.fila_seleccionada[0].sede,
          fecha_orden:this.fila_seleccionada[0].fecha,
          value:props.data.id+'',
          codigo_articulo:props.data.codigo
        }
    };
    this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: '7_items_mod', operacion_tipo: "eliminar" };
    this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv
        });
  }
  
  successEliminar(data) {
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
        this.primeraVez(); 
      }else{
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }

  refescarTabla(){
    let datos={ datos: {
      sucursal_ingreso:this.fila_seleccionada[0].codigo_sucursal,
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'refrescar', operacion_tipo: 'consulta' };
    this.service.send({
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.sucessRefrescar,
        error: this.error_,
        general: this.generalFormatPmv
    });
  }

  sucessRefrescar(data)
    {
        if (data.estado_p === 200)
        {
          this.gridOptionsComponentes['rowData'] = [];
          let configCell = new Map();
          this.gridOptionsComponentes['rowData'] = data.data.result;

          configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });

          this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
          this.getField('rejilla').toggle(true);
          //llenar totales
          this.getField('total_subtotal').setValue(data.data.totales.subtotal.toFixed(2));
          this.getField('valor_iva').setValue(data.data.totales.valoriva.toFixed(2));
          this.getField('impuestos').setValue(data.data.totales.impuestos.toFixed(2));
          this.getField('total_pedido').setValue(data.data.totales.valortotal.toFixed(2));
             
        }
        else 
        {
          this.getField('rejilla').toggle(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }

  }

  limpiarCampos(){
      
    
  }

  currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    calcularDescuento(){
        var xcosto = this.getField('costo').getValue();
        var xdescuentoc = this.getField('descuento').getValue();
        var xdescuento = xcosto * (xdescuentoc / 100);
        let numero=Number(xcosto - xdescuento);
        this.getField('precio_sin_iva').setValue(numero);
    }

    calcularItem(){
        let xtarifa_iva = this.getField('tarifa_iva').getValue();
        let ximpuestos1 = this.getField('impuestos2').getValue();
        let xcantidad1 = this.getField('cantidad_articulo').getValue();
        let xcosto1 =  this.getField('precio_sin_iva').getValue();
        let xcosto_mas_impuestos = xcosto1 + ximpuestos1;
        let xvalor_iva = xcosto1 * (xtarifa_iva / 100);

        this.getField('valor_sin_iva_ma_impuestos').setValue(Number.parseFloat(xcosto_mas_impuestos).toFixed(2));
        this.getField('valor_iva_unidad').setValue(Number.parseFloat(xvalor_iva).toFixed(2));
        this.getField('valor_unidad').setValue(Number.parseFloat(xvalor_iva + xcosto_mas_impuestos).toFixed(2));

        //total iva
        let xtotal_iva = xvalor_iva * xcantidad1;
        this.getField('total_iva').setValue(Number.parseFloat(xtotal_iva).toFixed(2));
        //total_impuestos
        let xtotal_impuestos = ximpuestos1 * xcantidad1;
        this.getField('total_impuestos').setValue(Number.parseFloat(xtotal_impuestos).toFixed(2));
        this.getField('impuestos_articulo').setValue(this.getField('impuestos2').getValue());

        //total_item
        let xtotal_item = xtotal_impuestos + xtotal_iva;
        xtotal_item = xtotal_item + (xcosto1 * xcantidad1);
        this.getField('total_item').setValue(Number.parseFloat(xtotal_item).toFixed(2));

    }

    traerPropiedades(){
      let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-consultapedidos', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessTraerPropiedades,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }

    sucessTraerPropiedades(data){
      if (data.estado_p === 200 )
      {
            this.desactivar=data.data.desactivar;
            this.smartvvpa=data.data.smartvvpa;
            this.cerrar=data.data.cerrar;
            this.plano=data.data.plano;
            this.wms=data.data.wms;
            this.comex=data.data.comex;
            this.fecha_pedidos=data.data.fecha_pedidos;
            this.wms_externo=data.data.wms_externo;
            this.smartvv=data.data.smartvv;

            if(this.desactivar)
                this.getField('bt_desactivar').setDisabled(false);
            else 
                this.getField('bt_desactivar').setDisabled(true);
            if(this.cerrar)
                this.getField('bt_cerrar').setDisabled(false);
            else 
                this.getField('bt_cerrar').setDisabled(true);
            if(this.wms)
                this.getField('bt_enviar_wms').setDisabled(false);
            else 
                this.getField('bt_enviar_wms').setDisabled(true);
            if(this.comex)
                this.getField('enviar_comex').setDisabled(false);
            else 
                this.getField('enviar_comex').setDisabled(true);
            
            if(this.smartvvpa)
              this.getField('bt_smartvv').setDisabled(false);
            else 
              this.getField('bt_smartvv').setDisabled(true);
            /* if(this.smartvv==='S'){
                this.getField('bt_smartvv').setDisabled(false);
            }
            else {
                this.getField('bt_smartvv').setDisabled(true);
            } */
      }
      else 
      { 
            this.desactivar='';
            this.cerrar='';
            this.plano='';
            this.wms='';
            this.comex='';
            this.fecha_pedidos='';
            this.wms_externo='';
            this.smartvv='';
      }
  }

    traerCostoBodega(){
      let datos={ datos: {
        value:this.getField('var_bodega').getValue(),
        codigo_articulo:this.getField('codigo_articulo').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: '1_bodega_costo', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessTraerCostoBodega,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }

    sucessTraerCostoBodega(data){
      if (data.estado_p === 200 )
      {
          this.getField('costo_bodega').setValue(data.data[0].costo);
          //validar si es alterno
            let datos={ datos: {
              value:this.getField('var_bodega').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: '1_alterno', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessAlterno,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });

      }
      else 
      { 
          this.getField('costo_bodega').setValue(0);
          if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          }
          else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
      }
  }

  sucessAlterno(data){
    if (data.estado_p === 200 )
    {
        if(data.data[0].total>0){
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El artículo '+this.getField('codigo_articulo').getValue()+'\n '+this.getField('nombre_articulo').getValue()+' \nes alterno. ');
          this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false)});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("Ok"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);

          this.getField('codigo_articulo').setValue('');
          this.getField('nombre_articulo').setValue('');
          this.getField('tarifa_iva').setValue('');
          this.getField('descuento').setValue('');
          this.getField('precio_sin_iva').setValue(0);
          this.getField('costo').setValue('');
        }
    }
    else 
    { 
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
}



}
FormJaivana.addController("comp-consultapedidos", CustomConsultaDePedidosCompras);
export default CustomConsultaDePedidosCompras;