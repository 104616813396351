import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomCierrePasoSaldo
 * @author: Santiago Hernández Neira
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001.1
 **/

class CustomCierrePasoSaldo extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.arrayObjetos                   = new Map();
        this.arrayCampos                    = new Map();
        this.opcionConfigurar               = this.opcionConfigurar.bind(this);    
        this.successTraerCierres            = this.successTraerCierres.bind(this);
        this.gridOptionsComponentes         = Object.assign({},this.gridOptions);
        this.setButtonDelete                = this.setButtonDelete.bind(this);
        this.eliminarPermiso                = this.eliminarPermiso.bind(this);
        this.confirmDelete                  = this.confirmDelete.bind(this);
        this.successEliminar                = this.successEliminar.bind(this);
        this.recargarTabla                  = this.recargarTabla.bind(this);
        this.validarGrabar                  = this.validarGrabar.bind(this);
        this.successValidarUnique           = this.successValidarUnique.bind(this)
        this.successcrear                   = this.successcrear.bind(this);
        this.limpiar                        = this.limpiar.bind(this);

        this.opcionHacerCierre              = this.opcionHacerCierre.bind(this);
        this.validarCerrar                  = this.validarCerrar.bind(this);
        this.successcerrar                  = this.successcerrar.bind(this);
        this.codigo_sucursal                = '';
        this.cerrarAnio                     = this.cerrarAnio.bind(this);
        this.successcerraranio              = this.successcerraranio.bind(this);
        this.eliminarCierre                 = this.eliminarCierre.bind(this);
        this.successeliminarcierre          = this.successeliminarcierre.bind(this);

        this.opcionPasarSaldos              = this.opcionPasarSaldos.bind(this);
        this.procesarpasarsaldos            = this.procesarpasarsaldos.bind(this); 
        this.successprocesarpasarsaldos     = this.successprocesarpasarsaldos.bind(this);
        this.successEliminarPasarSaldo      = this.successEliminarPasarSaldo.bind(this);

        this.onSelectionChangedComponentes                   = this.onSelectionChangedComponentes.bind(this);
        this.gridOptionsComponentes["onSelectionChanged"]    = this.onSelectionChangedComponentes;
    
        this.opcionCrearAnioNuevo                            = this.opcionCrearAnioNuevo.bind(this);
        this.crearAnio                                       = this.crearAnio.bind(this);
        this.successcrearanio                                = this.successcrearanio.bind(this);
        this.validarCrearAnio                                = this.validarCrearAnio.bind(this);
        this.limpiarCrearAnio                                = this.limpiarCrearAnio.bind(this);

        this.opcionHacerCierreNiif                           = this.opcionHacerCierreNiif.bind(this);  
        this.validarCerrarNiif                               = this.validarCerrarNiif.bind(this);
        this.successcerrarniif                               = this.successcerrarniif.bind(this);
        this.cerrarAnioNiif                                  = this.cerrarAnioNiif.bind(this);
        this.successcerraranioniif                           = this.successcerraranioniif.bind(this);
        this.eliminarCierreNiif                              = this.eliminarCierreNiif.bind(this);
        this.successeliminarcierreNiif                       = this.successeliminarcierreNiif.bind(this);
        this.validarcancelarconfirmar                        = this.validarcancelarconfirmar.bind(this);

        this.opcionPasarSaldosNiif                           = this.opcionPasarSaldosNiif.bind(this);
        this.procesarpasarsaldosniif                         = this.procesarpasarsaldosniif.bind(this);
        this.successprocesarpasarsaldosniif                  = this.successprocesarpasarsaldosniif.bind(this);
        this.successEliminarPasarSaldoniif                   = this.successEliminarPasarSaldoniif.bind(this);
        this.successeliminarcierreNiifError                  = this.successeliminarcierreNiifError.bind(this);

        this.opcionPasarSaldosSubCentros                     = this.opcionPasarSaldosSubCentros.bind(this);
        this.procesarpasarsaldossubcentros                   = this.procesarpasarsaldossubcentros.bind(this);
        this.successprocesarpasarsaldossub                   = this.successprocesarpasarsaldossub.bind(this);
        this.successEliminarPasarSaldoSub                    = this.successEliminarPasarSaldoSub.bind(this);

    }

    initForm(){
        console.log('Formulario CustomCierrePasoSaldo,  @version: jdesk_1.01.0001.1, @author:Santiago Hernández N. @updateBy Jose Albeiro Marin');
        
        this.arrayCampos.set(1,'id');
        this.arrayCampos.set(4,'operacion');
        this.arrayCampos.set(5,'codigo_cuenta_origen');
        this.arrayCampos.set(6,'nombre_cuenta_origen');
        this.arrayCampos.set(7,'codigo_cuenta_destino');
        this.arrayCampos.set(8,'nombre_cuenta_destino');
        this.arrayCampos.set(9,'nit');
        this.arrayCampos.set(10,'sede');
        this.arrayCampos.set(11,'nombre');
        this.arrayCampos.set(13,'bt_actualizar');
        this.arrayCampos.set(14,'rejilla');

        this.arrayCampos.set(18,'label3');
        this.arrayCampos.set(19,'fecha');
        this.arrayCampos.set(20,'bt_cerrar');
        this.arrayCampos.set(21,'bt_eliminar_cierre');

        this.arrayCampos.set(23,'label4');
        this.arrayCampos.set(24,'fechapasarsaldo');
        this.arrayCampos.set(25,'label5');
        this.arrayCampos.set(26,'bt_procesarpasarsaldo');

        this.arrayCampos.set(30,'label6');
        this.arrayCampos.set(31,'anio');
        this.arrayCampos.set(33,'bt_crearanio');
        this.arrayCampos.set(34,'bt_cancelaranio');

        this.arrayCampos.set(36,'label8');
        this.arrayCampos.set(37,'fechaniif');
        this.arrayCampos.set(38,'bt_cerrarniif');
        this.arrayCampos.set(39,'bt_eliminar_cierreniif');

        this.arrayCampos.set(42,'label9');
        this.arrayCampos.set(43,'fechapasarsaldoniif');
        this.arrayCampos.set(44,'label10');
        this.arrayCampos.set(45,'bt_procesarpasarsaldoniif');

        this.arrayCampos.set(47,'label11');
        this.arrayCampos.set(48,'fechapasarsaldosub');
        this.arrayCampos.set(49,'label12');
        this.arrayCampos.set(50,'bt_procesarpasarsaldosub');




        this.arrayObjetos.set(1,'id');
        this.arrayObjetos.set(4,'operacion');
        this.arrayObjetos.set(5,'codigo_cuenta_origen');
        this.arrayObjetos.set(6,'nombre_cuenta_origen');
        this.arrayObjetos.set(7,'codigo_cuenta_destino');
        this.arrayObjetos.set(8,'nombre_cuenta_destino');
        this.arrayObjetos.set(9,'nit');
        this.arrayObjetos.set(10,'sede');
        this.arrayObjetos.set(11,'nombre');
        this.arrayObjetos.set(13,'bt_actualizar');
        this.arrayObjetos.set(14,'rejilla');

        this.arrayObjetos.set(18,'label3');
        this.arrayObjetos.set(19,'fecha');
        this.arrayObjetos.set(20,'bt_cerrar');
        this.arrayObjetos.set(21,'bt_eliminar_cierre');


        this.arrayObjetos.set(23,'label4');
        this.arrayObjetos.set(24,'fechapasarsaldo');
        this.arrayObjetos.set(25,'label5');
        this.arrayObjetos.set(26,'bt_procesarpasarsaldo');

        this.arrayObjetos.set(30,'label6');
        this.arrayObjetos.set(31,'anio');
        this.arrayObjetos.set(33,'bt_crearanio');
        this.arrayObjetos.set(34,'bt_cancelaranio');

        this.arrayObjetos.set(36,'label8');
        this.arrayObjetos.set(37,'fechaniif');
        this.arrayObjetos.set(38,'bt_cerrarniif');
        this.arrayObjetos.set(39,'bt_eliminar_cierreniif');

        this.arrayObjetos.set(42,'label9');
        this.arrayObjetos.set(43,'fechapasarsaldoniif');
        this.arrayObjetos.set(44,'label10');
        this.arrayObjetos.set(45,'bt_procesarpasarsaldoniif');

        this.arrayObjetos.set(47,'label11');
        this.arrayObjetos.set(48,'fechapasarsaldosub');
        this.arrayObjetos.set(49,'label12');
        this.arrayObjetos.set(50,'bt_procesarpasarsaldosub');



        this.establecerPropiedades('5,6,7,8,9,10,11','visible','false');

        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionConfigurar();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionHacerCierre();
        }else if(this.props.data[0].opcion_sub_seccion === 3){
            this.opcionPasarSaldos();
        }else if(this.props.data[0].opcion_sub_seccion === 4){
            this.opcionCrearAnioNuevo();
        }else if(this.props.data[0].opcion_sub_seccion === 5){
            this.opcionHacerCierreNiif();
        }else if(this.props.data[0].opcion_sub_seccion === 6){
            this.opcionPasarSaldosNiif();
        }else if(this.props.data[0].opcion_sub_seccion === 7){
            this.opcionPasarSaldosSubCentros();
        }

 
    }







    /////////////////////////////////////////////////////////// CONFIGURAR //////////////////////////////////////////////
    onSelectionChangedComponentes() {
    }
    
    opcionConfigurar(){
        this.establecerPropiedades('5,6,7,8,9,10,11,13','visible','true');
        this.getField('operacion').setValue('CONFIGURAR');
        this.getField('bt_actualizar').setClick(()=>{this.validarGrabar()});

        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-cierres', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successTraerCierres,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successTraerCierres(data){
        if (data.estado_p === 200){
            this.datosTabla=data.data;
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            configCell.set('codigo_cuenta', {headerName:'Codigo_cuenta_origen'});
            configCell.set('nombre_cuenta', {headerName:'Nombre_cuenta_origen'});

            configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
            this.gridOptionsComponentes['rowData'] = this.datosTabla;
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
        } else {
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
       }
    }

    setButtonDelete(props) {
        let id = props.data.cierres_id;        
        let button = document.createElement("input");
        button.onclick = () => this.eliminarPermiso(id);
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "eliminar");
        return this.createElementJaivana(button);
    }
    
    eliminarPermiso(id) {
        this.ids = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDelete);
        this.getField('confirmModalCustom').toggle(true);
    }
    
    confirmDelete() {
      this.getField('confirmModalCustom').toggle(false);
      let datos = {
          datos: {
            cierres_id: this.ids,
          }
      };
      this.generalFormatPmv = { tipo_servicio: 'cont-cierres', operacion: '7', operacion_tipo: "eliminar" };
      this.service.send(
          {
              endpoint: this.constant.formConfiguration(),
              method: 'DELETE',
              body: datos,
              success: this.successEliminar,
              error: this.error_,
              general: this.generalFormatPmv
          });
    }
    
    successEliminar(data) {
        if(data.estado_p === 200){
          this.recargarTabla();          
        }else{
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    recargarTabla(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-cierres', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successTraerCierres,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    validarGrabar(){
        if(this.getField('nombre_cuenta_origen').valid() && this.getField('nombre_cuenta_destino').valid() && this.getField('nombre').valid()){



            let datos = {  datos: { 
                codigo_cuenta:parseInt(this.getField('codigo_cuenta_origen').getValue()),
                codigo_cuenta_contra:parseInt(this.getField('codigo_cuenta_destino').getValue()),
                tercero_destino:this.getField('nit').getValue(),
                sede:parseInt(this.getField('sede').getValue()),
              }
            }


            this.generalFormatPmv = { tipo_servicio: 'cont-cierres', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarUnique,
                error: this.error_,
                general: this.generalFormatPmv
            });

        }
        else{
            this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
        }
    }


    successValidarUnique(data){
        if (data.data[0].xson===0){
            let datos = {  datos: { 
                codigo_cuenta:parseInt(this.getField('codigo_cuenta_origen').getValue()),
                codigo_cuenta_contra:parseInt(this.getField('codigo_cuenta_destino').getValue()),
                tercero_destino:this.getField('nit').getValue(),
                sede:parseInt(this.getField('sede').getValue()),
              }
            }
    
            this.generalFormatPmv = { tipo_servicio: 'cont-cierres', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successcrear,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, 'Ya éxiste esta configuración, no se permite duplicar.', 'error');
            this.limpiar();
        }
    }


    successcrear(data){
        if (data.estado_p === 200 ){
            this.alertGeneral.toggle(true, 'La configuración fue creada con exito', 'success');
            this.recargarTabla();
            this.limpiar();

        } else {
            if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error al crear la configuración', 'error');
            } else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    limpiar(){
        this.establecerPropiedades('5,6,7,8,9,10,11','value','');
        this.getField('sede').setValue('');
    }









    

    /////////////////////////////////////////////////////////// HACER CIERRE //////////////////////////////////////////////
    opcionHacerCierre(){
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.establecerPropiedades('18,19,20,21','visible','true');
        this.getField('operacion').setValue('HACER CIERRE');
        
        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-1";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fecha').setValue(campoFecha.split('T')[0]);

        this.getField('bt_cerrar').setClick(()=>{this.validarCerrar()});
        this.getField('bt_eliminar_cierre').setClick(()=>{this.eliminarCierre()});
        
        
    }

    validarCerrar(){
        this.getField('bt_cerrar').setDisabled(true);
        this.getField('bt_eliminar_cierre').setDisabled(true);
        let datos = {  datos: { 
            fecha:this.getField('fecha').getValue(),
            sucursal:this.codigo_sucursal,
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'hacercierrecerrar', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successcerrar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successcerrar(data){
        if (data.estado_p === 200 ){
            
            this.cerrarAnio();

        } else {
            this.getField('confirmModalCustom').setTitleAndContent('CIERRE', `${'!!! DESCUADRADO !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
                this.cerrarAnio();
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);            
        };
    }

    cerrarAnio(){
        
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            
        });
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let datos = {  datos: { 
            fecha:this.getField('fecha').getValue(),
            sucursal:this.codigo_sucursal,
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'hacercierrecerraranio', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successcerraranio,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    };

    successcerraranio(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('bt_cerrar').setDisabled(false);
        this.getField('bt_eliminar_cierre').setDisabled(false);
        if (data.estado_p === 200 ){

            this.getField('confirmModalCustom').setTitleAndContent('CIERRE', `${'!!! CIERRE TERMINADO !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
                //this.cerrarAnio();
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);    

        } else {
            if (data.data.mensaje==='Hay un Cierre Realizado, Eliminelo por Favor.'){
                this.getField('confirmModalCustom').setTitleAndContent('CIERRE', `${'!!! Hay un cierre realizado. Elimínelo por favor. !!!'}`);
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                    //this.cerrarAnio();
                });
                this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.alertGeneral.toggle(true, 'Error en el CIERRE.', 'error');
            }            
        };
    }

    eliminarCierre(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            
        });
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        this.getField('bt_cerrar').setDisabled(true);
        this.getField('bt_eliminar_cierre').setDisabled(true);
        let datos = {  datos: { 
            fecha:this.getField('fecha').getValue(),
            sucursal:this.codigo_sucursal,
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'eliminarcierre', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successeliminarcierre,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successeliminarcierre(data){
        this.getField('confirmModalCustom').toggle(false);

        this.getField('bt_cerrar').setDisabled(false);
        this.getField('bt_eliminar_cierre').setDisabled(false);
        if (data.estado_p === 200 ){

            this.getField('confirmModalCustom').setTitleAndContent('CIERRE', `${'!!! CIERRE ELIMINADO !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
                //this.cerrarAnio();
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);    

        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar el cierre.', 'error');
        };
    }














    /////////////////////////////////////////////////////////// PASAR SALDOS //////////////////////////////////////////////
    opcionPasarSaldos(){
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.establecerPropiedades('23,24,25,26','visible','true');
        this.getField('operacion').setValue('PASAR SALDOS');
        this.getField('bt_procesarpasarsaldo').setClick(()=>{this.procesarpasarsaldos()});   
        
        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-1";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fechapasarsaldo').setValue(campoFecha.split('T')[0]);


               
    }

    procesarpasarsaldos(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            
        });
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        this.getField('bt_procesarpasarsaldo').setDisabled(true);

        let datos = {  datos: { 
            fechapasarsaldo:this.getField('fechapasarsaldo').getValue(),
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'procesarpasasaldo', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successprocesarpasarsaldos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successprocesarpasarsaldos(data){
        if (data.estado_p === 200 ){
            this.getField('confirmModalCustom').toggle(false);

            let datos = {  datos: { 
                fechapasarsaldo:this.getField('fechapasarsaldo').getValue(),
              }
            }

            this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'eliminarpasasaldo', operacion_tipo: "eliminar" };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successEliminarPasarSaldo,
                error: this.error_,
                general: this.generalFormatPmv
            });

        } else {
            this.alertGeneral.toggle(true, 'Error al crear el paso saldo.', 'error');
            this.getField('bt_procesarpasarsaldo').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);

        };
    }

    successEliminarPasarSaldo(data){
        this.getField('bt_procesarpasarsaldo').setDisabled(false);
        if (data.estado_p === 200 ){

            this.getField('confirmModalCustom').setTitleAndContent('PASAR SALDOS', `${'!!! TERMINADO !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
                //this.cerrarAnio();
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true); 
            this.opcionPasarSaldos(); 

        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar saldos en 0.', 'error');
        };
    }










    
    /////////////////////////////////////////////////////////// CREAR NUEVO AÑO //////////////////////////////////////////////
    opcionCrearAnioNuevo(){
        
        this.getField('operacion').setValue('CREAR NUEVO AÑO');
        this.establecerPropiedades('30,31,33,34','visible','true');
        this.getField('bt_crearanio').setClick(()=>{this.validarCrearAnio()});  
        this.getField('bt_cancelaranio').setClick(()=>{this.limpiarCrearAnio()});   

    }

    validarCrearAnio(){
        if(this.getField('anio').valid()){            
            let anioactual = new Date().getFullYear();
            this.getField('anio').setError(false, "");
            if ((this.getField('anio').getValue()>anioactual) && (this.getField('anio').getValue()<anioactual+2)){
                let anio = this.getField('anio').getValue();
                this.getField('bt_crearanio').setDisabled(true);
                this.entro=false;
                this.getField('confirmModalCustom').setTitleAndContent('Se dispone a crear el año: '+this.getField('anio').getValue()+'', '¿Está seguro?');
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.entro=true; 
                    this.crearAnio(anio);
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
                this.getField('confirmModalCustom').toggle(true);
                this.validarcancelarconfirmar();

            }else{
                this.getField('anio').setError(true,'El año debe ser mayor al: ' + anioactual + ' y menor al: ' +parseInt(anioactual+2));              
            }

        }else{
            this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
        }

    }

    validarcancelarconfirmar(){
            if(this.entro=== false){
                this.limpiarCrearAnio();
            }
    }

    crearAnio(anio){
        let datos = {  datos: { 
            anio: anio
        }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'crearnuevoanio', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successcrearanio,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });

    }

    successcrearanio(data){
        if (data.estado_p === 200 ){

            this.alertGeneral.toggle(true, 'Se ha agregado al nuevo año correctamente.', 'success');
            this.limpiarCrearAnio();
        }else{
         
            this.alertGeneral.toggle(true, 'Ocurrio un error agregando el año!', 'error');
            this.limpiarCrearAnio();

        }
    }

    limpiarCrearAnio(){
        this.getField('bt_crearanio').setDisabled(false);
        this.getField('anio').setValue('');        
        this.getField('anio').setError(false,'');
        this.getField('anio').input.focus();
    }












    /////////////////////////////////////////////////////////// HACER CIERRE NIIF //////////////////////////////////////////////
    opcionHacerCierreNiif(){
        this.getField('operacion').setValue('HACER CIERRE NIIF');
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.establecerPropiedades('36,37,38,39','visible','true');
        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-01";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fechaniif').setValue(campoFecha.split('T')[0]);
        this.getField('bt_cerrarniif').setClick(()=>{this.validarCerrarNiif()});
        this.getField('bt_eliminar_cierreniif').setClick(()=>{this.eliminarCierreNiif()});
    }

    validarCerrarNiif(){
        this.getField('bt_cerrarniif').setDisabled(true);
        this.getField('bt_eliminar_cierreniif').setDisabled(true);

        let datos = {  datos: { 
            fechaniif:this.getField('fechaniif').getValue(),
            sucursalniif:this.codigo_sucursal,
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'hacercierrecerrarniif', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successcerrarniif,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }


    successcerrarniif(data){
        if (data.estado_p === 200 ){
            
            this.cerrarAnioNiif();

        } else {
            if (data.data.mensaje==='!!! DESCUADRADO !!!.'){
                this.getField('confirmModalCustom').setTitleAndContent('CIERRE NIIF', `${'!!! DESCUADRADO !!!'}`);
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                    this.cerrarAnioNiif();
                });
                this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true); 
            }else if (data.data.mensaje==='!!! Existen Cuentas sin MIGRAR !!!.'){
                this.getField('confirmModalCustom').setTitleAndContent('CIERRE NIIF', `${'!!! Existen Cuentas sin MIGRAR !!!'}`);
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                    this.getField('bt_cerrarniif').setDisabled(false);
                    this.getField('bt_eliminar_cierreniif').setDisabled(false);
                });
                this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true); 

            }else {
                this.alertGeneral.toggle(true, 'Error en el CIERRE NIIF.', 'error');
            }        
        };

    }

    cerrarAnioNiif(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            
        });
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let datos = {  datos: { 
            fechaniif:this.getField('fechaniif').getValue(),
            sucursalniif:this.codigo_sucursal,
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'hacercierrecerraranioniif', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successcerraranioniif,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });     
    }

    successcerraranioniif(data){        
        this.getField('confirmModalCustom').toggle(false);
        this.getField('bt_cerrarniif').setDisabled(false);
        this.getField('bt_eliminar_cierreniif').setDisabled(false);
        if (data.estado_p === 200 ){

            this.getField('confirmModalCustom').setTitleAndContent('CIERRE NIIF', `${'!!! CIERRE TERMINADO !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
                this.opcionHacerCierreNiif();
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);    

        } else {
            if (data.data.mensaje==='Hay un Cierre Realizado, Eliminelo por Favor.'){
                this.getField('confirmModalCustom').setTitleAndContent('CIERRE NIIF', `${'!!! Hay un cierre realizado. Elimínelo por favor. !!!'}`);
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                    this.opcionHacerCierreNiif();
                    //this.cerrarAnio();
                });
                this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else if (data.data.errores.Cierre==='Error al crear el movimiento, revise la configuracion_xml de xdocumento, xtercero, xnumero, xorigen para cierre_contable_niif'){
                let datos = {  datos: { 
                    fechaniif:this.getField('fechaniif').getValue(),
                    sucursalniif:this.codigo_sucursal,
                  }
                }
        
                this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'eliminarcierreniif', operacion_tipo: 'eliminar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'DELETE',
                        body: datos,
                        success: this.successeliminarcierreNiifError,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }else{
                this.alertGeneral.toggle(true, 'Error en el CIERRE NIIF.', 'error');
                this.opcionHacerCierreNiif();
            }            
        };
    }

    successeliminarcierreNiifError(data){

        if (data.estado_p === 200 ){
            this.getField('confirmModalCustom').setTitleAndContent('CIERRE NIIF', `${'!!! CIERRE TERMINADO, CON ERROR EN configuracion_xml!!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);      
                this.opcionHacerCierreNiif();          
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);  
        }else{
            this.alertGeneral.toggle(true, 'Error en el CIERRE NIIF.', 'error');
            this.opcionHacerCierreNiif();
        }
    }

    eliminarCierreNiif(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            
        });
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        this.getField('bt_cerrarniif').setDisabled(true);
        this.getField('bt_eliminar_cierreniif').setDisabled(true);
        let datos = {  datos: { 
            fechaniif:this.getField('fechaniif').getValue(),
            sucursalniif:this.codigo_sucursal,
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'eliminarcierreniif', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successeliminarcierreNiif,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successeliminarcierreNiif(data){
        this.getField('confirmModalCustom').toggle(false);

        this.getField('bt_cerrarniif').setDisabled(false);
        this.getField('bt_eliminar_cierreniif').setDisabled(false);
        if (data.estado_p === 200 ){

            this.getField('confirmModalCustom').setTitleAndContent('CIERRE NIIF', `${'!!! CIERRE ELIMINADO !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);   
                this.opcionHacerCierreNiif();             
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);    

        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar el cierre.', 'error');
            this.opcionHacerCierreNiif();
        };
    }










    
    /////////////////////////////////////////////////////////// PASAR SALDOS NIIF //////////////////////////////////////////////
    opcionPasarSaldosNiif(){

        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.establecerPropiedades('42,43,44,45','visible','true');
        this.getField('operacion').setValue('PASAR SALDOS NIIF');
        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-01";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fechapasarsaldoniif').setValue(campoFecha.split('T')[0]);
        this.getField('bt_procesarpasarsaldoniif').setClick(()=>{this.procesarpasarsaldosniif()});   


    }


    procesarpasarsaldosniif(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            
        });
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        this.getField('bt_procesarpasarsaldoniif').setDisabled(true);

        let datos = {  datos: { 
            fechapasarsaldoniif:this.getField('fechapasarsaldoniif').getValue(),
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'procesarpasasaldoniif', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successprocesarpasarsaldosniif,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });

    }


    successprocesarpasarsaldosniif(data){
        if (data.estado_p === 200 ){
            this.getField('confirmModalCustom').toggle(false);

            let datos = {  datos: { 
                fechapasarsaldoniif:this.getField('fechapasarsaldoniif').getValue(),
              }
            }

            this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'eliminarpasasaldoniif', operacion_tipo: "eliminar" };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successEliminarPasarSaldoniif,
                error: this.error_,
                general: this.generalFormatPmv
            });

        } else {
            this.alertGeneral.toggle(true, 'Error al crear el paso saldo.', 'error');
            this.getField('bt_procesarpasarsaldoniif').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);

        };
    }

    successEliminarPasarSaldoniif(data){
        this.getField('bt_procesarpasarsaldoniif').setDisabled(false);
        if (data.estado_p === 200 ){

            this.getField('confirmModalCustom').setTitleAndContent('PASAR SALDOS NIIF', `${'!!! TERMINADO !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true); 
            this.opcionPasarSaldosNiif(); 

        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar saldos en 0.', 'error');
        };
    }













    /////////////////////////////////////////////////////////// PASAR SALDOS SUB CENTROS //////////////////////////////////////////////
    opcionPasarSaldosSubCentros(){

        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.establecerPropiedades('47,48,49,50','visible','true');
        this.getField('operacion').setValue('PASAR SALDOS SUB CENTROS');
        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-01";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fechapasarsaldosub').setValue(campoFecha.split('T')[0]);
        this.getField('bt_procesarpasarsaldosub').setClick(()=>{this.procesarpasarsaldossubcentros()});
    }

    procesarpasarsaldossubcentros(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            
        });
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        this.getField('bt_procesarpasarsaldosub').setDisabled(true);

        let datos = {  datos: { 
            fechapasarsaldosub:this.getField('fechapasarsaldosub').getValue(),
          }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'procesarpasasaldosubcentros', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successprocesarpasarsaldossub,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successprocesarpasarsaldossub(data){

        
        if (data.estado_p === 200 ){
            this.getField('confirmModalCustom').toggle(false);

            let datos = {  datos: { 
                fechapasarsaldosub:this.getField('fechapasarsaldosub').getValue(),
              }
            }

            this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'eliminarpasasaldosubcentros', operacion_tipo: "eliminar" };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successEliminarPasarSaldoSub,
                error: this.error_,
                general: this.generalFormatPmv
            });

        } else {
            this.getField('bt_procesarpasarsaldosub').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);


            this.getField('confirmModalCustom').setTitleAndContent('PASAR SALDOS SUB-CENTROS', `${'!!! EL PROCESO NO SE REALIZO CORRECTAMENTE !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
                let datos = {  datos: { 
                    fechapasarsaldosub:this.getField('fechapasarsaldosub').getValue(),
                  }
                }
    
                this.generalFormatPmv = { tipo_servicio: 'cont-especialescierrepasosaldo', operacion: 'eliminarpasasaldosubcentrosdrop', operacion_tipo: "eliminar" };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method: 'DELETE',
                    body: datos,
                    success: this.successEliminarPasarSaldoSub,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true); 


        };

    }


    successEliminarPasarSaldoSub(data){
        this.getField('bt_procesarpasarsaldosub').setDisabled(false);
        if (data.estado_p === 200 ){

            this.getField('confirmModalCustom').setTitleAndContent('PASAR SALDOS SUB-CENTROS', `${'!!! TERMINADO !!!'}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true); 
            this.opcionPasarSaldosSubCentros(); 

        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar saldos en 0.', 'error');
            this.opcionPasarSaldosSubCentros(); 
        };
    }
    






    

    establecerPropiedades(sObjetos,sPropiedad,sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if(sPropiedad==='disable') { 
                if(sValor==='true') { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }   
            }
            else if(sPropiedad==='visible') { 
                if(sValor==='true') { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                } else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }   
            } else if(sPropiedad==='value') { 
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            } else if(sPropiedad==='valid') { 
                if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                } else {
                    this.bHayErrores=true;
                }
            } else if(sPropiedad==='foco') { 
                if(sValor==='rejilla') { } else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });     
    }

}
FormJaivana.addController("cont-especialescierrepasosaldo",CustomCierrePasoSaldo);
export default CustomCierrePasoSaldo