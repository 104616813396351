import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Cristian Ciro
 * 
 * @version jdesk_1.01.0002 
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomProcesarInformacionExogena extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);

        this.buscarDatos                = this.buscarDatos.bind(this);
        this.succesConsulta             = this.succesConsulta.bind(this);
        this.mostrarCampos	            = this.mostrarCampos.bind(this);
        this.ocultarCampos              = this.ocultarCampos.bind(this);

        this.validarSeleccionar         = this.validarSeleccionar.bind(this);

        this.gridOptionsComponentes     = Object.assign({}, this.gridOptions);
        this.gridOptionsDatos           = Object.assign({}, this.gridOptions);
        this.gridOptionsDatos2          = Object.assign({}, this.gridOptions);
        this.onSelectionChanged                                     = this.onSelectionChanged.bind(this);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.gridOptionsDatos['onSelectionChanged']                 = this.onSelectionChanged;
        this.gridOptionsDatos2['onSelectionChanged']                = this.onSelectionChanged;

        this.openModalFechas                                        = this.openModalFechas.bind(this);
        this.procesarTabla                                          = this.procesarTabla.bind(this);

        this.succesProcesarTabla                                    = this.succesProcesarTabla.bind(this);
        this.cuantias_menores                                       = 0;

        this.setButtonDetalles                                      = this.setButtonDetalles.bind(this);
        this.verDetalles                                            = this.verDetalles.bind(this);
        this.succesVerDetalles                                      = this.succesVerDetalles.bind(this);

        this.succesDatosColumnas                                    = this.succesDatosColumnas.bind(this);
        this.arrayColumnas                                          = [];

        this.modalExportar                                          = this.modalExportar.bind(this);
        this.mostrarMensajeNoHayDatos                               = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional                                = this.mostrarMensajeAdicional.bind(this);
        this.successPdf                                             = this.successPdf.bind(this);

        this.opcion_selected                                        = ".";
        this.operacion_actual_imprimir                              = "excel";
        this.closeModal                                             = this.closeModal.bind(this);
        this.realizar_operacion                                     = true;
        this.exportarInforme                                        = this.exportarInforme.bind(this);
        
    }

    initForm(){
        console.log('Formulario CustomProcesarInformacionExogena,  @version: jdesk_1.01.0002, @author:Cristian Ciro');

        let fecha_actual = new Date();

        let opciones = [{value:" ",text:"Ninguno"}];

        for(let a=0; a <= 2; a++){
            let fechas = {value:(fecha_actual.getFullYear()-a)+"",text:(fecha_actual.getFullYear()-a)+""};
            opciones.push(fechas);
        }

        this.getField("select_fecha").setOptions(opciones);

        this.getField('seleccionar').setClick(this.buscarDatos);
        
        this.getField('exoformato_id').setOnChange((props)=>{
            if(this.getField('exoformato_id').getValue()!==''){
                this.getField('seleccionar').setDisabled(false);
                this.ocultarCampos();
            }else{
                this.getField('exoformato_id').setDisabled(true);
                this.ocultarCampos();
            }
        });
        this.getField('exoconcepto_id').setOnChange((props)=>{
            if(this.getField('exoconcepto_id').getValue()!==''){
                this.getField('seleccionar').setDisabled(false);
                this.ocultarCampos();
            }else{
                this.getField('exoconcepto_id').setDisabled(true);
                this.ocultarCampos();
            }
        });

        this.getField('ch_nit_por_sede').setOnChange((props)=>{
            this.getField("ch_agrupado_por_nit").setValueSwitch(false);
            this.getField("rejilla_datos").toggle(false);
            if(this.realizar_operacion)
                if(this.getField('ch_nit_por_sede').getValue() === "false"){
                    this.procesarTabla("NPS");
                }else{
                    this.procesarTabla(".");
                }
        });

        this.getField('ch_agrupado_por_nit').setOnChange((props)=>{
            this.getField("ch_nit_por_sede").setValueSwitch(false);
            this.getField("rejilla_datos").toggle(false);
            if(this.realizar_operacion)
                if(this.getField('ch_agrupado_por_nit').getValue() === "false"){
                    this.procesarTabla("APN");
                }else{
                    this.procesarTabla(".");
                }
        });
        
        this.getField('ch_excel').setValueSwitch(true);

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual_imprimir='excel';
            }else{
                this.getField('ch_pdf').setValueSwitch(true);
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.operacion_actual_imprimir="txt";
            }else{
                this.getField('ch_excel').setValueSwitch(true);
            }
        });

        this.getField('btn_procesar').setClick(this.openModalFechas);
        this.getField('seleccionar').setDisabled(true);
        this.ocultarCampos();
        this.getField('nombre_formato').setOnChange(this.validarSeleccionar);
        this.getField('nombre_concepto').setOnChange(this.validarSeleccionar);


        this.getField('btn_aceptar').setClick(() => {this.procesarTabla(".")});
        this.getField('btn_exportar_excel').setClick(this.modalExportar);

        this.getField("btn_aceptar_exportar").setClick(this.exportarInforme);

        this.getField("modal_tabla").setHandlerClose(this.closeModal);

        /*this.gridOptionsDatos['rowData'] = [];
        let configCell = new Map();
        this.getField('rejilla_datos').initData(this.gridOptionsDatos,configCell);*/

    } 

    closeModal(){
        this.realizar_operacion = false;
        this.getField("modal_tabla").handleCloseButton();
    }

    modalExportar(){
        this.getField('ch_excel').setValueSwitch(true);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField("modal_reimprimir").handleClickOpen();
    }

    exportarInforme(){
        if(this.getField("correo").valid()){
            this.getField('btn_aceptar_exportar').setDisabled(true);
            this.getField('correo').setDisabled(true);

            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No recargue la página, por favor espere, se está generando la información...');
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(true);
            this.getField('confirmModalCustom').toggle(true);

            let datos = {
                exoconcepto_id: this.getField("exoconcepto_id").getValue(),
                exoformato_id:this.getField("exoformato_id").getValue(),
                fecha:this.getField("select_fecha").getValue(),
                email:this.getField("correo").getValue(),
                opcion: this.opcion_selected,
                tipo_archivo: this.operacion_actual_imprimir,
                cuantias: this.cuantias_menores,
            };
    
            let url = this.constant.formConfiguration();
            let operacion_exportar = "2_excel";
            /*if(this.operacion_actual_imprimir === "txt"){
                operacion_exportar = "2_txt";
            }*/

            const myJSON = JSON.stringify(datos);
            let datos2='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-procesarinfoexogena","operacion":"'+operacion_exportar+'","operacion_tipo":"consulta"}}';
            fetch(url+datos2,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        this.getField('btn_aceptar_exportar').setDisabled(false);
                        this.getField('correo').setDisabled(false);
                        this.getField("modal_reimprimir").handleClose();
                        if(this.isJson(res)){
                            //this.enviar_correo=true;
                            this.successPdf(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            if(this.operacion_actual_imprimir === "txt"){
                                a.download = 'info_exogena.txt';
                            }else
                                a.download = 'info_exogena.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                    this.getField('btn_aceptar_exportar').setDisabled(false);
                    this.getField('correo').setDisabled(false);
                }
            }).catch(err => {
                this.getField('btn_aceptar_exportar').setDisabled(false);
                this.getField('correo').setDisabled(false);
            });
        }
    }

    successPdf(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeAdicional(data.data.mensaje);            
        }else{
            this.mostrarMensajeNoHayDatos();
        }
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleConfirm(false); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleConfirm(false);
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    onSelectionChanged(){
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    procesarTabla(opcion){
        this.getField('btn_exportar_excel').setDisabled(true);

        this.getField('ch_nit_por_sede').setDisabled(true);
        this.getField('ch_agrupado_por_nit').setDisabled(true);

        if(this.getField("select_fecha").valid()){
            this.opcion_selected = opcion;
            this.getField("select_fecha").setDisabled(true);
            this.getField('btn_aceptar').setDisabled(true);
            let cuentas = [];

            this.gridOptionsComponentes['rowData'].forEach((item) => {
                let itemCuenta = {};

                itemCuenta["cuenta"] = item.codigo_cuenta;
                itemCuenta["tipo"] = item.tipo

                cuentas.push(itemCuenta);
            });

            let datos = { datos: {
                fecha:this.getField("select_fecha").getValue(),
                cuantias: this.cuantias_menores,
                exoconcepto_id: this.getField("exoconcepto_id").getValue(),
                exoformato_id:this.getField("exoformato_id").getValue(),
                opcion: opcion
            }};

            this.getField("rejilla_datos").setTipoServicioOperacion("cont-procesarinfoexogena-1_1");

            this.getField("rejilla_datos").sendRequest(this.succesProcesarTabla, datos);

            /*this.generalFormatPmv = { tipo_servicio: 'cont-procesarinfoexogena', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesProcesarTabla,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
        }
    }

    succesProcesarTabla(data){
        this.getField("select_fecha").setDisabled(false);
        this.getField('btn_aceptar').setDisabled(false);

        this.getField('ch_nit_por_sede').setDisabled(false);
        this.getField('ch_agrupado_por_nit').setDisabled(false);
        //this.getField("modal_fechas").handleClose();
        if(data.estado_p === 200){

            this.getField('btn_exportar_excel').setDisabled(false);
            let configCell = new Map();

            data.data.forEach((item,index) => {
                item["detalle"] = "";
            });

            for(let index in data.data[0]) {
                if(index.indexOf("valor") > -1){
                    configCell.set(index,{cellRenderer:function(props){return''.formatoPrecio(props.data[index].toFixed(0))},type: 'rightAligned',field:index});
                }
                if(index.indexOf("ciudad") > -1){
                    configCell.set(index,{cellRenderer:function(props){return props.data[index].substring(2, props.data[index].length)},field:index});
                }
            }

            this.gridOptionsDatos['rowData'] = data.data;

            /*configCell.set('valor',{cellRenderer:function(props){return''.formatoPrecio(props.data.valor.toFixed(0))},type: 'rightAligned',field:'valor'});
            configCell.set('valor_a',{cellRenderer:function(props){return''.formatoPrecio(props.data.valor_a.toFixed(0))},type: 'rightAligned',field:'valor_a'});
            configCell.set('valor_b',{cellRenderer:function(props){return''.formatoPrecio(props.data.valor_b.toFixed(0))},type: 'rightAligned',field:'valor_b'});
            configCell.set('valor_c',{cellRenderer:function(props){return''.formatoPrecio(props.data.valor_c.toFixed(0))},type: 'rightAligned',field:'valor_c'});
            configCell.set('codigo_ciudad',{cellRenderer:function(props){return props.data.codigo_ciudad.substring(2, props.data.codigo_ciudad.length)},field:'codigo_ciudad'});*/
            configCell.set('detalle', { cellRenderer: this.setButtonDetalles, width: 110, sortable: false, filter: false, field: 'detalle' });
            
            this.getField('rejilla_datos').initData(this.gridOptionsDatos,configCell);            

            this.getField("modal_tabla").handleClickOpen();
            this.realizar_operacion = true;
        }
    }

    setButtonDetalles(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.verDetalles(props.data);};
        button.setAttribute("class", "buttonStyle");

        if(props.data.nit === "222222222"){
            button.setAttribute('disabled',true);
            button.setAttribute('class','buttonDisbled');
        }
        
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ver Detalle");
        return this.createElementJaivana(button);
    }

    verDetalles(data){
        let cuentas = [];

        this.gridOptionsComponentes['rowData'].forEach((item) => {
            let itemCuenta = {};

            itemCuenta["cuenta"] = item.codigo_cuenta;
            itemCuenta["tipo"] = item.tipo

            cuentas.push(itemCuenta);
        });

        let datos = { datos: {
            cuentas: cuentas,
            fecha:this.getField("select_fecha").getValue(),
            cuantias: this.cuantias_menores,
            nit: data.nit
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-procesarinfoexogena', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesVerDetalles,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    succesVerDetalles(data){
        if(data.estado_p === 200){

            this.gridOptionsDatos2['rowData'] = data.data;
            let configCell = new Map();

            configCell.set('valor',{cellRenderer:function(props){return''.formatoPrecio(props.data.valor)},type: 'rightAligned',field:'valor'});

            this.getField('rejilla_detalles').initData(this.gridOptionsDatos2,configCell);

            this.getField("modal_detalles").handleClickOpen();
        }
    }

    openModalFechas(){
        this.getField("select_fecha").setDisabled(false);
        this.getField('btn_aceptar').setDisabled(false);
        this.getField("modal_fechas").handleClickOpen();
    }

    buscarDatos(){
        if(this.getField('codigo_formato').valid()
        && this.getField('nombre_formato').valid()
        && this.getField('codigo_concepto').valid()
        && this.getField('nombre_concepto').valid()){

            this.ocultarCampos();

            let datos = { datos: {
                exoconcepto_id: this.getField("exoconcepto_id").getValue(),
                exoformato_id:this.getField("exoformato_id").getValue()
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'cont-exogenaformatosconceptos', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesConsulta,
                    error: this.error_,
                    general: this.generalFormatPmv
            });

            this.generalFormatPmv = { tipo_servicio: 'cont-procesarinfoexogena', operacion: '1_3', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesDatosColumnas,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    succesDatosColumnas(data){
        if(data.estado_p === 200){
            this.arrayColumnas = data.data;
        }else{
            this.arrayColumnas = [];
        }
    }

    succesConsulta(data){
        if(data.estado_p === 200){
            if(data.data[0].valor_cuantias_menores !== null)
                this.cuantias_menores = data.data[0].valor_cuantias_menores;
            this.gridOptionsComponentes['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('valor_cuantias_menores',{cellRenderer:function(props){return''.formatoPrecio(props.data.valor_cuantias_menores !== null?props.data.valor_cuantias_menores:0)},type: 'rightAligned',field:'valor_cuantias_menores'});

            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
            this.mostrarCampos();
        }else{
            this.getField('rejilla').toggle(false);
        }
    }

    ocultarCampos(){
        this.getField('rejilla').toggle(false);
        this.getField('btn_procesar').setVisible(false);
    }

    mostrarCampos(){
        this.getField('btn_procesar').setVisible(true);
    }

    validarSeleccionar(){
        if(this.getField('nombre_formato').getValue() !=='' && this.getField('nombre_concepto').getValue() !=='' ){
          this.getField('seleccionar').setDisabled(false);
        }else{
          this.getField('seleccionar').setDisabled(true);
        }
    }

}
FormJaivana.addController("cont-procesarinfoexogena",CustomProcesarInformacionExogena);
export default CustomProcesarInformacionExogena