import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
* @author Anderson Acevedo Briñez
*/
/**
 * CustomGrabarMovimientoContable
**/
class CustomGrabarMovimientoContable extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm = this.initForm.bind(this);
       this.arrayObjetos=new Map();
       this.numeroActual='';
       this.seguirGrabar = false;
       this.arrayCampos=new Map();
       this.bHayErrores=false;
       this.bHayError=false;
       this.datosAgregar={ datos:{}};
       this.gridOptionsComponentes = Object.assign({},this.gridOptions);
       this.depto_interno_defecto='';
       this.placa_defecto='';
       this.gridOptions2 = Object.assign({},this.gridOptions)
       this.gridOptions3 = Object.assign({},this.gridOptions)
       this.parametroNumeroDecimales = 0;
       this.dataToSend   = [];
       this.items        = 0;
       this.clientesValidacion        = true;
       this.contador=0;
       this.valorAnterior=0;


       this.validarComprobante=this.validarComprobante.bind(this);

       this.traerConsecutivo=this.traerConsecutivo.bind(this);
       this.successServiceTraerClienteDocumentos=this.successServiceTraerClienteDocumentos.bind(this);

       this.traerReservadoContabilidad=this.traerReservadoContabilidad.bind(this);
       this.successTraerReservadoContabilidad=this.successTraerReservadoContabilidad.bind(this);

       this.cleanValid=this.cleanValid.bind(this);

       this.validarSucursal=this.validarSucursal.bind(this);

       this.traerBloqueoFechas=this.traerBloqueoFechas.bind(this);
       this.successTraerBloqueoFechas=this.successTraerBloqueoFechas.bind(this);

       this.cambioDeCuenta=this.cambioDeCuenta.bind(this);

       this.traerConfiguracion=this.traerConfiguracion.bind(this);
       this.successTraerConfiguracion=this.successTraerConfiguracion.bind(this);

       this.traerTarifaIvaTodos=this.traerTarifaIvaTodos.bind(this);
       this.successTraerTarifaIvaTodos=this.successTraerTarifaIvaTodos.bind(this);

       this.setDocumentoFuente=this.setDocumentoFuente.bind(this);

       this.setBanco=this.setBanco.bind(this);

       this.setBase=this.setBase.bind(this);

       this.cuentaIva=this.cuentaIva.bind(this);

       this.setNuevo=this.setNuevo.bind(this);

       this.setTotal=this.setTotal.bind(this);

       this.cuentaRetencion=this.cuentaRetencion.bind(this);

       this.definirServicio=this.definirServicio.bind(this);

       this.traerCarteraCliente=this.traerCarteraCliente.bind(this);
       this.successTraerCarteraCliente=this.successTraerCarteraCliente.bind(this);

       this.traerCarteraProveedor=this.traerCarteraProveedor.bind(this);
       this.successTraerCarteraProveedor=this.successTraerCarteraProveedor.bind(this);

       this.onSelectionChanged=this.onSelectionChanged.bind(this);

       this.contabilidadNumeroDecimales=this.contabilidadNumeroDecimales.bind(this);
       this.successContabilidadNumeroDecimales=this.successContabilidadNumeroDecimales.bind(this);

       this.validarFechas=this.validarFechas.bind(this);

       this.validarFechas2=this.validarFechas2.bind(this);

       this.grabarDatos=this.grabarDatos.bind(this);

       this.limpiarCampos=this.limpiarCampos.bind(this);

       this.updateTable=this.updateTable.bind(this);

       this.setButtonDelete=this.setButtonDelete.bind(this);

       this.deleteItem=this.deleteItem.bind(this);

       this.validarMovimiento=this.validarMovimiento.bind(this);

       this.validarGuardar=this.validarGuardar.bind(this);

       this.guardarDatos=this.guardarDatos.bind(this);
       this.successGuardarDatos=this.successGuardarDatos.bind(this);

       this.limpiar=this.limpiar.bind(this);

       this.generarPDF=this.generarPDF.bind(this);
       this.formatearValor= this.formatearValor.bind(this);
       this.numero_comprobante='';
       this.habilitar_radio_button=false;
       this.usar_numero_documento_sucursales=false;

    }

    initForm() {

       console.log("Formulario CustomGrabarMovimientoContable,  @version: jdesk_10.0.001, @author: Anderson Acevedo Briñez");


       this.arrayCampos.set(1,'id_movimiento_contable');
       this.arrayCampos.set(2,'codigo_sucursal');
       this.arrayCampos.set(3,'nombre_sucursal');
       this.arrayCampos.set(4,'fecha_sucursal');
       this.arrayCampos.set(5,'codigo_comprobante');
       this.arrayCampos.set(6,'nombre_comprobante');
       this.arrayCampos.set(7,'numero_documento_comprobante');
       this.arrayCampos.set(8,'nit');
       this.arrayCampos.set(9,'nit_sede');
       this.arrayCampos.set(10,'nit_nombre');
       this.arrayCampos.set(11,'sucursales_id');
       this.arrayCampos.set(12,'comprobante_id');
       this.arrayCampos.set(13,'nit_id');
       this.arrayCampos.set(14,'es_modificable');
       this.arrayCampos.set(15,'fecha_desde');
       this.arrayCampos.set(16,'fecha_hasta');
       this.arrayCampos.set(18,'cuenta_de_retefuente');
       this.arrayCampos.set(19,'cuenta_de_iva');
       this.arrayCampos.set(20,'nit_responsable');
       this.arrayCampos.set(21,'nit_responsable_sede');
       this.arrayCampos.set(22,'nit_responsable_nombre');
       this.arrayCampos.set(23,'codigo_cuenta');
       this.arrayCampos.set(24,'codigo_cuenta_nombre');
       this.arrayCampos.set(25,'codigo_cuenta_movimiento');
       this.arrayCampos.set(26,'codigo_cuenta_tipo');
       this.arrayCampos.set(27,'nit_responsable_id');
       this.arrayCampos.set(28,'cuenta_id');
       this.arrayCampos.set(29,'cuenta_maneja_documento');
       this.arrayCampos.set(30,'cuenta_maneja_banco');
       this.arrayCampos.set(31,'cuenta_maneja_base');
       this.arrayCampos.set(32,'cuenta_tipo_fuente');
       this.arrayCampos.set(33,'cuenta_de_reteiva');
       this.arrayCampos.set(34,'cuenta_de_reteica');
       this.arrayCampos.set(35,'dept_interno_codigo');
       this.arrayCampos.set(36,'dept_interno_nombre');
       this.arrayCampos.set(37,'vehiculo_codigo');
       this.arrayCampos.set(38,'vehiculo_modelo');
       this.arrayCampos.set(39,'centro_costo_codigo');
       this.arrayCampos.set(40,'centro_costo_nombre');
       this.arrayCampos.set(41,'doc_bancario_codigo');
       this.arrayCampos.set(42,'referencia');
       this.arrayCampos.set(43,'detalle');
       this.arrayCampos.set(44,'dept_interno_id');
       this.arrayCampos.set(45,'vehiculo_id');
       this.arrayCampos.set(46,'centro_costo_id');
       this.arrayCampos.set(52,'nuevo_documento');
       this.arrayCampos.set(53,'documento_fuente');
       this.arrayCampos.set(54,'buscar');
       this.arrayCampos.set(55,'documento_fecha');
       this.arrayCampos.set(56,'documento_fecha_vence');
       this.arrayCampos.set(57,'documento_llave');
       this.arrayCampos.set(58,'documento_base');
       this.arrayCampos.set(59,'iva_ventas');
       this.arrayCampos.set(60,'documento_tarifa');
       this.arrayCampos.set(61,'documento_valor');
       this.arrayCampos.set(62,'grabar');
       this.arrayCampos.set(64,'tabla_movimiento');
       this.arrayCampos.set(70,'total_debito');
       this.arrayCampos.set(71,'total_creditos');
       this.arrayCampos.set(72,'total_diferencia');
       this.arrayCampos.set(73,'guardar_todo');
       this.arrayCampos.set(79,'tabla_grabacion_mov_cliente');
       this.arrayCampos.set(80,'tabla_grabacion_mov_proveedores');
       this.arrayCampos.set(81,'modal_documento');

       this.arrayObjetos.set(1,'id_movimiento_contable');
       this.arrayObjetos.set(2,'codigo_sucursal');
       this.arrayObjetos.set(3,'nombre_sucursal');
       this.arrayObjetos.set(4,'fecha_sucursal');
       this.arrayObjetos.set(5,'codigo_comprobante');
       this.arrayObjetos.set(6,'nombre_comprobante');
       this.arrayObjetos.set(7,'numero_documento_comprobante');
       this.arrayObjetos.set(8,'nit');
       this.arrayObjetos.set(9,'nit_sede');
       this.arrayObjetos.set(10,'nit_nombre');
       this.arrayObjetos.set(11,'sucursales_id');
       this.arrayObjetos.set(12,'comprobante_id');
       this.arrayObjetos.set(13,'nit_id');
       this.arrayObjetos.set(14,'es_modificable');
       this.arrayObjetos.set(15,'fecha_desde');
       this.arrayObjetos.set(16,'fecha_hasta');
       this.arrayObjetos.set(18,'cuenta_de_retefuente');
       this.arrayObjetos.set(19,'cuenta_de_iva');
       this.arrayObjetos.set(20,'nit_responsable');
       this.arrayObjetos.set(21,'nit_responsable_sede');
       this.arrayObjetos.set(22,'nit_responsable_nombre');
       this.arrayObjetos.set(23,'codigo_cuenta');
       this.arrayObjetos.set(24,'codigo_cuenta_nombre');
       this.arrayObjetos.set(25,'codigo_cuenta_movimiento');
       this.arrayObjetos.set(26,'codigo_cuenta_tipo');
       this.arrayObjetos.set(27,'nit_responsable_id');
       this.arrayObjetos.set(28,'cuenta_id');
       this.arrayObjetos.set(29,'cuenta_maneja_documento');
       this.arrayObjetos.set(30,'cuenta_maneja_banco');
       this.arrayObjetos.set(31,'cuenta_maneja_base');
       this.arrayObjetos.set(32,'cuenta_tipo_fuente');
       this.arrayObjetos.set(33,'cuenta_de_reteiva');
       this.arrayObjetos.set(34,'cuenta_de_reteica');
       this.arrayObjetos.set(35,'dept_interno_codigo');
       this.arrayObjetos.set(36,'dept_interno_nombre');
       this.arrayObjetos.set(37,'vehiculo_codigo');
       this.arrayObjetos.set(38,'vehiculo_modelo');
       this.arrayObjetos.set(39,'centro_costo_codigo');
       this.arrayObjetos.set(40,'centro_costo_nombre');
       this.arrayObjetos.set(41,'doc_bancario_codigo');
       this.arrayObjetos.set(42,'referencia');
       this.arrayObjetos.set(43,'detalle');
       this.arrayObjetos.set(44,'dept_interno_id');
       this.arrayObjetos.set(45,'vehiculo_id');
       this.arrayObjetos.set(46,'centro_costo_id');
       this.arrayObjetos.set(52,'nuevo_documento');
       this.arrayObjetos.set(53,'documento_fuente');
       this.arrayObjetos.set(54,'buscar');
       this.arrayObjetos.set(55,'documento_fecha');
       this.arrayObjetos.set(56,'documento_fecha_vence');
       this.arrayObjetos.set(57,'documento_llave');
       this.arrayObjetos.set(58,'documento_base');
       this.arrayObjetos.set(59,'iva_ventas');
       this.arrayObjetos.set(60,'documento_tarifa');
       this.arrayObjetos.set(61,'documento_valor');
       this.arrayObjetos.set(62,'grabar');
       this.arrayObjetos.set(64,'tabla_movimiento');
       this.arrayObjetos.set(70,'total_debito');
       this.arrayObjetos.set(71,'total_creditos');
       this.arrayObjetos.set(72,'total_diferencia');
       this.arrayObjetos.set(73,'guardar_todo');
       this.arrayObjetos.set(79,'tabla_grabacion_mov_cliente');
       this.arrayObjetos.set(80,'tabla_grabacion_mov_proveedores');
       this.arrayObjetos.set(81,'modal_documento');
       this.arrayObjetos.set(85,'.');
       this.arrayObjetos.set(86,'.');

       this.getField('cuenta_id').setOnChange(this.validarMovimiento);
       this.getField('sucursales_id').setOnChange(this.validarSucursal);
       this.getField('comprobante_id').setOnChange(this.validarComprobante);
       this.getField('cuenta_tipo_fuente').setOnChange(this.setDocumentoFuente);
       this.getField('cuenta_maneja_banco').setOnChange(this.setBanco);
       this.getField('cuenta_maneja_base').setOnChange(this.setBase);
       this.getField('cuenta_de_iva').setOnChange(this.cuentaIva);
       this.getField('nuevo_documento').setOnChange(this.setNuevo);
       this.getField('documento_tarifa').setOnChange(this.setTotal);
       this.getField('documento_base').setOnChange(this.setTotal);
       this.getField('cuenta_de_retefuente').setOnChange(this.cuentaRetencion);
       this.getField('buscar').setClick(this.definirServicio);
       this.getField('fecha_sucursal').setOnChange(()=>{this.getField('fecha_sucursal').setError(false,'');this.getField('numero_documento_comprobante').setValue('')});
       this.getField('nit_id').setOnChange(()=>{if(this.getField('nit').valid() && this.getField('nit_id').getValue()!==''){this.getField('nit_sede').setError(false,'');this.getField('nit_nombre').setError(false,'');}});
       this.getField('nit_responsable_id').setOnChange(()=>{if(this.getField('nit_responsable').valid() && this.getField('nit_responsable_id').getValue()!==''){this.getField('nit_responsable_sede').setError(false,'');this.getField('nit_responsable_nombre').setError(false,'');}});
       this.getField('dept_interno_id').setOnChange(()=>{if(this.getField('dept_interno_codigo').valid() && this.getField('dept_interno_id').getValue()!==''){this.getField('dept_interno_nombre').setError(false,'');}});
       this.getField('vehiculo_id').setOnChange(()=>{if(this.getField('vehiculo_codigo').valid() && this.getField('vehiculo_id').getValue()!==''){this.getField('vehiculo_modelo').setError(false,'');}});
       this.getField('centro_costo_id').setOnChange(()=>{if(this.getField('centro_costo_codigo').valid() && this.getField('centro_costo_id').getValue()!==''){this.getField('centro_costo_nombre').setError(false,'');}});


       this.getField('iva_ventas').setOnChange(()=>{if(this.getField('iva_ventas').getValue()===''){this.getField('documento_tarifa').setValue('0')}});
       this.getField('nuevo_documento').setValue('S');
       this.getField('total_debito').setValue(0);
       this.getField('documento_tarifa').setValue(0);
       this.getField('total_creditos').setValue(0);
       this.getField('total_diferencia').setValue(0);
       this.gridOptions2['onSelectionChanged']= this.onSelectionChanged;
       this.gridOptions3['onSelectionChanged']= this.onSelectionChanged;
       this.getField('grabar').setClick(this.grabarDatos);
       this.getField('guardar_todo').setClick(this.validarGuardar);
       this.contabilidadNumeroDecimales();
       this.traerConfiguracion();
       this.traerTarifaIvaTodos();
       this.getField('documento_valor').setOnChange(this.formatearValor);
       this.establecerPropiedades('2','foco','.');
       this.establecerPropiedades('3,5,6,7,9,10,21,22,24,25,26,35,36,37,38,40,52,53,54,55,56,57,58,60,61,73,41','disable','true');

    }

    formatearValor(){
      
      let aux1=Number(this.valorAnterior.toString().replaceAll(',',''));
      let aux2=Number(this.getField('documento_valor').getValue().toString().replaceAll(',',''));
      if((Number(aux2)&&aux1!==aux2)){
         if((aux2>0)||(aux2<0&&this.getField('documento_valor').getValue().toString().length>4)){
            this.valorAnterior=''.formatoPrecio(aux2);
            this.getField('documento_valor').setValue(this.valorAnterior);
         }
         
      }
   }

    //*** definir los botones y los success
    validarComprobante()
    {
            if(this.getField('nombre_comprobante').getValue()!== '' && this.getField('nombre_comprobante').valid() && this.getField('nombre_sucursal').valid()) {
             this.getField("nombre_comprobante").setError(false,"* Este campo es requerido.");
             this.traerConsecutivo();}
             else{
             this.getField('numero_documento_comprobante').setValue('');}
    }

    traerConsecutivo()
    {
       let datos={ datos: { sucursal_id:this.getField('sucursales_id').getValue(), documento_id:this.getField('comprobante_id').getValue() }};
            this.generalFormatPmv = { tipo_servicio: 'cont-sucursalesdoc', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successServiceTraerClienteDocumentos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successServiceTraerClienteDocumentos(data)
    {
        if (data.estado_p === 200)
        {
            
            this.getField('numero_documento_comprobante').setValue(data.data[0].numero);
             this.getField('es_modificable').setValue(data.data[0].modificable);
             this.cleanValid();
             if(this.getField('es_modificable').getValue() ==='S'){
             this.usar_numero_documento_sucursales=false;
             this.numero_comprobante=data.data[0].numero;
             this.getField('numero_documento_comprobante').setValue('');
             this.establecerPropiedades('7','disable','false');}
             else{
             this.numero_comprobante=data.data[0].numero;
             this.establecerPropiedades('7','disable','true');
             this.numeroActual=''; 
             this.traerReservadoContabilidad();}
        }
        else 
        {
           if(data.estado_p===404) {
            this.establecerPropiedades('7','disable','true');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerReservadoContabilidad()
    {
       let datos={ datos: { reservados_documento_id:this.getField('comprobante_id').getValue(), reservados_sucursal_id:this.getField('sucursales_id').getValue(), reservados_fecha:this.getField('fecha_sucursal').getValue() }};
            this.generalFormatPmv = { tipo_servicio: 'cont-cnsctvsrsrvdscn', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerReservadoContabilidad,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successTraerReservadoContabilidad(data)
    {
        if (data.estado_p === 200)
        {
            this.usar_numero_documento_sucursales=false;
            let numero_consecutivo_usados=data.data[0].numero_consecutivo_usados;
             let numero_consecutivos=data.data[0].numero_consecutivos;
             let numero_actual=data.data[0].numero_actual;
             if(numero_consecutivo_usados < numero_consecutivos){
               this.getField('numero_documento_comprobante').setValue('0');
               this.numeroActual=numero_consecutivo_usados+numero_actual;
               this.numero_comprobante=this.numeroActual;
            }
             else{ this.getField('numero_documento_comprobante').setValue('-1');
             this.alertGeneral.toggle(true,'El documento no tiene consecutivos reservados',"error");}
        }
        else 
        {
           if(data.estado_p===404) {
            this.establecerPropiedades('7','disable','true');
             this.usar_numero_documento_sucursales=true;
             this.getField('numero_documento_comprobante').setValue('0');
             //this.alertGeneral.toggle(true,'El documento no tiene consecutivos reservados',"error");
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    cleanValid()
    {
            this.getField('codigo_cuenta_movimiento').setError(false,"* Cuenta mal configurada"); 
             this.getField('codigo_cuenta_tipo').setError(false,"* Cuenta mal configurada");
             this.getField("codigo_sucursal").setError(false,"* Este campo es requerido.");
             this.getField("nombre_sucursal").setError(false,"* Este campo es requerido.");
             this.getField("codigo_comprobante").setError(false,"* Este campo es requerido.");
             this.getField("nombre_comprobante").setError(false,"* Este campo es requerido.");
             this.getField("numero_documento_comprobante").setError(false,"* Este campo es requerido.");
             this.getField("codigo_cuenta").setError(false,"* Este campo es requerido.");
             this.getField("codigo_cuenta_nombre").setError(false,"* Este campo es requerido.");
             this.getField("nit").setError(false,"* Este campo es requerido.");
             this.getField("nit_sede").setError(false,"* Este campo es requerido.");
             this.getField("nit_nombre").setError(false,"* Este campo es requerido.");
             this.getField("nit_responsable").setError(false,"* Este campo es requerido.");
             this.getField("nit_responsable_sede").setError(false,"* Este campo es requerido.");
             this.getField("nit_responsable_nombre").setError(false,"* Este campo es requerido.");
             this.getField("dept_interno_codigo").setError(false,"* Este campo es requerido.");
             this.getField("dept_interno_nombre").setError(false,"* Este campo es requerido.");
             this.getField("centro_costo_codigo").setError(false,"* Este campo es requerido.");
             this.getField("centro_costo_nombre").setError(false,"* Este campo es requerido.");
             this.getField("fecha_sucursal").setError(false,"* Este campo es requerido.");
             this.getField("documento_fuente").setError(false,"* Este campo es requerido.");
             this.getField("detalle").setError(false,"* Este campo es requerido.");
             this.getField("documento_base").setError(false,"* Este campo es requerido.");
             this.getField("documento_tarifa").setError(false,"* Este campo es requerido.");
             this.getField("documento_valor").setError(false,"* Este campo es requerido.");
             this.getField("documento_fecha").setError(false,"* Este campo es requerido.");
             this.getField("cuenta_tipo_fuente").setError(false,"* Este campo es requerido.");
             this.getField("nuevo_documento").setError(false,"* Este campo es requerido.");
             this.getField("documento_fecha_vence").setError(false,"* Este campo es requerido.");
             this.getField("vehiculo_codigo").setError(false,"* Este campo es requerido.");
             this.getField("vehiculo_modelo").setError(false,"* Este campo es requerido.");
             this.getField("doc_bancario_codigo").setError(false,"* Este campo es requerido.");
             this.getField("documento_llave").setError(false,"* Este campo es requerido.");
             this.getField("referencia").setError(false,"* Este campo es requerido.");
    }

    validarSucursal()
    {
            if(this.getField('sucursales_id').getValue() !== '') {
             this.getField("nombre_sucursal").setError(false,"* Este campo es requerido.");
             this.getField("numero_documento_comprobante").setError(false,"* Este campo es requerido.");
             this.establecerPropiedades('5','disable','false');
             this.traerBloqueoFechas();}
             else{
             this.getField('codigo_comprobante').setValue(''); this.getField('nombre_comprobante').setValue(''); this.getField('numero_documento_comprobante').setValue(''); this.establecerPropiedades('5','disable','true');
            }
    }

    traerBloqueoFechas()
    {
       let datos={ datos: { sucursal_id:this.getField('sucursales_id').getValue() }};
            this.generalFormatPmv = { tipo_servicio: 'cont-bloqueofechas', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerBloqueoFechas,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerBloqueoFechas(data)
    {
        if (data.estado_p === 200)
        {
            
            this.getField('fecha_hasta').setValue(data.model[0].fecha_hasta);
             this.getField('fecha_desde').setValue(data.model[0].fecha_desde);
             this.alertGeneral.toggle(true,'Accion Exitosa',"success");
             this.seguirGrabar = true;
             this.getField('fecha_sucursal').setError(false,'');
        }
        else 
        {
           if(data.estado_p===404) {
            this.getField('fecha_hasta').setValue('');
             this.getField('fecha_desde').setValue('');
             this.getField('fecha_sucursal').setError(true,'* La sucursal no posee fechas permitidas');
             this.seguirGrabar = false;
             this.establecerPropiedades('5','disable','true');
             this.alertGeneral.toggle(true,'La suscursal no posee fechas permitidas',"error");
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    cambioDeCuenta()
    {
            if(this.getField('cuenta_id').getValue()!==''){
             if(this.getField('codigo_cuenta').getValue().startsWith("5")){ 
             this.establecerPropiedades('37','disable','false');
             this.getField('vehiculo_codigo').setValue("");
             this.getField('vehiculo_modelo').setValue(""); }
             else if(this.getField('codigo_cuenta').getValue().startsWith("6")){
             this.establecerPropiedades('37','disable','false');
             this.getField('vehiculo_codigo').setValue("");
             this.getField('vehiculo_modelo').setValue(""); }
             else if(this.getField('codigo_cuenta').getValue().startsWith("15")){
             this.establecerPropiedades('37','disable','false');
             this.getField('vehiculo_codigo').setValue("");
             this.getField('vehiculo_modelo').setValue(""); }
             else{
             this.establecerPropiedades('37','disable','true');
             this.getField('vehiculo_codigo').setValue(this.placa_defecto);
             this.getField('vehiculo_modelo').setValue("."); }
             if(!this.getField('codigo_cuenta').getValue().startsWith("5")){
             this.getField('dept_interno_codigo').setValue(this.depto_interno_defecto);
             this.getField('dept_interno_nombre').setValue(".");
             this.establecerPropiedades('35','disable','true');}
             else{
             this.getField('dept_interno_codigo').setValue("");
             this.getField('dept_interno_nombre').setValue("");
             this.establecerPropiedades('35','disable','false');}}
    }

    traerConfiguracion()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-confignxml', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerConfiguracion,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerConfiguracion(data)
    {
        if (data.estado_p === 200)
        {
            
            if(data !== undefined  && data.estado_p === 200){
             for(let i in data.model){
             if(data.model[i].propiedad ==="contabilidad_placa_vehiculo_por_defecto")
             this.placa_defecto = data.model[i].valor;
             else if( data.model[i].propiedad ==="contabilidad_dpto_interno_por_defecto") 
             this.depto_interno_defecto  = data.model[i].valor;  }}
        }
        else 
        {
           if(data.estado_p===404) {
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerTarifaIvaTodos()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-tarifasiva', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTarifaIvaTodos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerTarifaIvaTodos(data)
    {
        if (data.estado_p === 200)
        {
            
            let opciones = [{value:' ',text:'Ninguno'}]; let contiene = false; data.model.forEach(item => { let dataOp = {}; dataOp['value'] = parseInt(item.iva_ventas); dataOp['text'] = 'iva_ventas:'+item.iva_ventas; dataOp['campos_cambian'] = { documento_tarifa: item.iva_ventas }; opciones.push(dataOp); if(item.iva_ventas === 100) contiene = true; }); if(!contiene){ let dataOp = {}; dataOp['value'] = 100; dataOp['text'] = 'iva_ventas:100'; dataOp['campos_cambian'] = { documento_tarifa:100 }; opciones.push(dataOp); } this.getField('iva_ventas').setOptions(opciones);
        }
        else 
        {
           if(data.estado_p===404) {
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    setDocumentoFuente()
    {
         if(this.getField('cuenta_tipo_fuente').getValue()!==''){
            this.cleanValid();
             if(this.getField('cuenta_maneja_documento').getValue() === 'N'){ this.getField('documento_fuente').setValue(".");
               this.getField('documento_llave').setValue(0);
               this.establecerPropiedades('52,53,54,55,56','disable','true'); 
               this.habilitar_radio_button=false;
               }
             else if(this.getField('cuenta_tipo_fuente').getValue() === '.'){
               this.getField('documento_fuente').setValue(".");
               this.getField('documento_llave').setValue(0);
               this.establecerPropiedades('52,53,54,55,56','disable','true'); 
               this.habilitar_radio_button=false;
            }
             else if(this.getField('cuenta_tipo_fuente').getValue() === 'N'){
               this.getField('documento_fuente').setValue(".");
               this.getField('documento_llave').setValue(0);
               this.establecerPropiedades('52,53,54,55,56','disable','true'); 
               this.habilitar_radio_button=false;
            }
             else if(this.getField('cuenta_maneja_documento').getValue() === 'S'){
               this.getField('documento_fuente').setValue("");
               this.getField('documento_llave').setValue(0);
               if(this.getField('nuevo_documento').getValue()==='S'){
                  this.establecerPropiedades('54','disable','true');
                  this.establecerPropiedades('52,53,55,56','disable','false'); 
                  this.habilitar_radio_button=true;
               }else{
                  this.establecerPropiedades('53,55,56','disable','true'); 
                  this.establecerPropiedades('52,54','disable','false');
                  this.habilitar_radio_button=true;
               }
               
               
            }else{

            }
         }
    }

    setBanco()
    {
            if(this.getField('cuenta_maneja_banco').getValue()!==''){
             this.cleanValid();
             if(this.getField('cuenta_maneja_banco').getValue() === 'N'){
             this.establecerPropiedades('41','disable','true'); this.getField('doc_bancario_codigo').setValue('.');}
             else if(this.getField('cuenta_maneja_banco').getValue() === 'S')
             this.establecerPropiedades('41','disable','false'); else{
             this.establecerPropiedades('41','disable','true');
             this.getField('doc_bancario_codigo').setValue('');}}
    }

    setBase()
    {
            if(this.getField('cuenta_maneja_base').getValue()!==''){
             this.cleanValid();
             let base = this.getField('cuenta_maneja_base').getValue();
             if(base === 'S'){
             this.getField('documento_base').setValue(0);
             this.getField('documento_valor').setValue(0);
             this.establecerPropiedades('61','disable','true');
             this.establecerPropiedades('58,60','disable','false');
            } else if(base === 'N'){ this.getField('documento_base').setValue(0);
             this.getField('documento_tarifa').setValue(0);
             this.getField('documento_valor').setValue(0);
             this.establecerPropiedades('58,60','disable','true');
             this.establecerPropiedades('61','disable','false');}}
    }

    cuentaIva()
    {
            if(this.getField('cuenta_de_iva').getValue()!==''){
             //this.cleanValid();
             if(this.getField('cuenta_de_iva').getValue() === 'S'){
             this.establecerPropiedades('59','visible','true');
             this.getField('iva_ventas').setValue('');
             this.getField('documento_tarifa').setValue(0);
             this.establecerPropiedades('60','disable','true');}
             else{
             this.establecerPropiedades('59','visible','false');}}
    }

    setNuevo()
    {
            if(this.getField('nuevo_documento').getValue()!==''){
             this.cleanValid();
             if(this.getField('nuevo_documento').getValue() === 'S'){
             this.establecerPropiedades('54','disable','true');
             this.establecerPropiedades('53,55,56','disable','false');
             this.getField('documento_fuente').setValue("");

             this.getField('documento_fecha').setValue(' ');
             this.getField('documento_fecha_vence').setValue(' ');
             this.getField('documento_llave').setValue(0);
             this.getField('documento_base').setValue(0);}
             else{
             this.establecerPropiedades('54','disable','false');
             this.establecerPropiedades('53,55,56','disable','true');
             this.getField('documento_fuente').setValue('');
             this.getField('documento_llave').setValue('');}}
    }

    setTotal()
    {
             //this.cleanValid(); 
             let base = (!isNaN(parseFloat(this.getField('documento_base').getValue())))?this.getField('documento_base').getValue():0;
             let tarifa = (!isNaN(parseFloat(this.getField('documento_tarifa').getValue())))?this.getField('documento_tarifa').getValue():0;
             let total = (base*tarifa)/100;
             if (this.getField('documento_base').valid()&&this.getField('documento_tarifa').valid()) {
               if(parseInt(total) === 0){
                  this.getField('documento_valor').setValue(0);} 
               else {
                  this.getField('documento_valor').setValue(''.formatoPrecio(total.toFixed(Number(this.parametroNumeroDecimales))));}
            }
    }

    cuentaRetencion()
    {
             if(this.getField('cuenta_de_retefuente').getValue() !== undefined && this.getField('cuenta_de_reteiva').getValue() !== undefined && this.getField('cuenta_de_reteica').getValue() !== undefined){
               this.cleanValid();
               if(this.getField('cuenta_de_retefuente').getValue() === 'S' ){
                  if(this.getField('cuenta_de_iva').getValue() === 'S'){
                     this.establecerPropiedades('60','disable','true');
                  }
                  else{
                     this.establecerPropiedades('60','disable','false');
                  }
               }
               if(this.getField('cuenta_de_reteiva').getValue() === 'S'){
                  if(this.getField('cuenta_de_iva').getValue() === 'S'){
                     this.establecerPropiedades('60','disable','true');}
                  else{
                     this.establecerPropiedades('60','disable','false');
                  }
               }
               if(this.getField('cuenta_de_reteica').getValue() === 'S'){
                  if(this.getField('cuenta_de_iva').getValue() === 'S'){
                     this.establecerPropiedades('60','disable','true');}
                  else{ 
                     this.establecerPropiedades('60','disable','false');
                  }
               }
               if(this.getField('cuenta_de_retefuente').getValue() === 'N' && this.getField('cuenta_de_reteiva').getValue() === 'N' && this.getField('cuenta_de_reteica').getValue() === 'N' && this.getField('cuenta_de_iva').getValue() === 'N'){
                  this.getField('documento_tarifa').setValue(0);
               }
            }
    }

    definirServicio()
    {
            this.cleanValid();
             if(this.getField('nit').valid() & this.getField('nit_sede').valid() & this.getField('cuenta_tipo_fuente').valid()  & this.getField('codigo_cuenta_tipo').valid()){
             if(this.getField('codigo_cuenta').valid()){
             if(this.getField('cuenta_tipo_fuente').getValue() === 'C'){
             this.traerCarteraCliente();}
             else if(this.getField('cuenta_tipo_fuente').getValue() === 'P'){
             this.traerCarteraProveedor();}
             else{
             alert("El Nit y Sede seleccionados no manejan ningún documento"); 
             this.establecerPropiedades('54','disable','true');
             this.getField('documento_fuente').setValue(".");} }
             else{
             this.establecerPropiedades('54','disable','true');
             this.getField('documento_fuente').setValue(".");}}
    }

    traerCarteraCliente()
    {
       let datos={ datos: {  
         nit: this.getField('nit').getValue(), 
         sede:Number(this.getField('nit_sede').getValue()), 
         contable: Number(this.getField('codigo_cuenta').getValue())}};
            this.generalFormatPmv = { tipo_servicio: 'cont-ctrclientes', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerCarteraCliente,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerCarteraCliente(data)
    {
        if (data.estado_p === 200)
        {
            
            this.getField('modal_documento').handleClickOpen();
             this.getField('tabla_grabacion_mov_cliente').toggle(true); this.getField('tabla_grabacion_mov_proveedores').toggle(false); this.gridOptions2['rowData'] = data.data;
             let configCell = new Map();
             configCell.set('valor',{cellRenderer:function(props){return''.formatoPrecio(props.data.vaalor)},field:'valor'});
             configCell.set('saldo',{cellRenderer:function(props){return''.formatoPrecio(props.data.saldo)},field:'saldo'});
             this.getField('tabla_grabacion_mov_cliente').initData(this.gridOptions2,configCell);
        }
        else 
        {
           if(data.estado_p===404) {
            this.getField('modal_documento').handleClose();
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerCarteraProveedor()
    {
       let datos={ datos: {  nit: this.getField('nit').getValue(), sede: Number(this.getField('nit_sede').getValue()), contable: Number(this.getField('codigo_cuenta').getValue())}};
            this.generalFormatPmv = { tipo_servicio: 'cont-ctrproveedores', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerCarteraProveedor,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerCarteraProveedor(data)
    {
        if (data.estado_p === 200)
        {
            
            this.getField('modal_documento').handleClickOpen();
             this.getField('tabla_grabacion_mov_cliente').toggle(false); this.getField('tabla_grabacion_mov_proveedores').toggle(true); this.gridOptions3['rowData'] = data.data;
             let configCell = new Map();
             configCell.set('valor',{cellRenderer:function(props){return''.formatoPrecio(props.data.vaalor)},field:'valor'});
             configCell.set('saldo',{cellRenderer:function(props){return''.formatoPrecio(props.data.saldo)},field:'saldo'});
             this.getField('tabla_grabacion_mov_proveedores').initData(this.gridOptions3,configCell);
        }
        else 
        {
           if(data.estado_p===404) {
            this.getField('modal_documento').handleClose();
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    onSelectionChanged()
    {
            this.getField('modal_documento').handleClose();
    }

    contabilidadNumeroDecimales()
    {
       let datos={ datos: { }};
            this.generalFormatPmv = { tipo_servicio: 'cont-cnsctvsrsrvdscn', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successContabilidadNumeroDecimales,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successContabilidadNumeroDecimales(data)
    {
        if (data.estado_p === 200)
        {
            this.parametroNumeroDecimales = data.data[0].numero_decimales_contabilidad;
        }
        else 
        {
           if(data.estado_p===404) {
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validarFechas(fecha1,fecha2,actual)
    {
             let dia = parseInt(fecha1.split('-')[2]);
             let mes = parseInt(fecha1.split('-')[1]);
             let anio = parseInt(fecha1.split('-')[0]);
             let valid = true;
             if(actual === 1){
             let actual = new Date();
             let anio_actual = actual.getFullYear();
             let mes_actual = actual.getMonth()+1;
             let dia_actual = actual.getDate();
             if(anio > anio_actual)
             valid = true;
             else if(anio === anio_actual){
             if(mes > mes_actual-2)
             valid = true;
             else if(mes === mes_actual-2){
             if(dia >= dia_actual)
             valid = true;
             else
             valid = false;}
             else
             valid = false}
             else
             valid = false;}
             else{
             let dia2 = parseInt(fecha2.split('-')[2]);
             let mes2 = parseInt(fecha2.split('-')[1]);
             let anio2 = parseInt(fecha2.split('-')[0]);
             if(anio > anio2)
             valid = true;
             else if(anio === anio2){
             if(mes > mes2)
             valid = true;
             else if(mes === mes2){
             if(dia >= dia2)
             valid = true;
             else
             valid = false;}
             else
             valid = false}
             else
             valid = false;}
             return valid;
    }

    validarFechas2(fecha1,fecha2)
    {
             let dia = parseInt(fecha2.split('-')[2]);
             let mes = parseInt(fecha2.split('-')[1]);
             let anio = parseInt(fecha2.split('-')[0]);
             let valid = true;
             let dia2 = parseInt(fecha1.split('-')[2]);
             let mes2 = parseInt(fecha1.split('-')[1]);
             let anio2 = parseInt(fecha1.split('-')[0]);
             if(anio > anio2)
             valid = true;
             else if(anio === anio2){
             if(mes > mes2)
             valid = true;
             else if(mes === mes2){
             if(dia >= dia2)
             valid = true;
             else
             valid = false;}
             else
             valid = false}
             else
             valid = false;
             return valid;
    }

    grabarDatos()
    {
            if(this.getField('codigo_sucursal').valid() && this.getField('nombre_sucursal').valid() && this.getField('fecha_sucursal').valid() &&
             this.getField('codigo_comprobante').valid() && this.getField('nombre_comprobante').valid() && this.getField('numero_documento_comprobante').valid() &&
             this.getField('numero_documento_comprobante').getValue() !== '-1' &&
             this.getField('nit').valid() && this.getField('nit_sede').valid() && this.getField('nit_nombre').valid() &&
             this.getField('nit_responsable').valid() && this.getField('nit_responsable_sede').valid() && this.getField('nit_responsable_nombre').valid() &&
             this.getField('codigo_cuenta').valid() && this.getField('codigo_cuenta_nombre').valid() && this.getField('codigo_cuenta_movimiento').valid() && this.getField('codigo_cuenta_tipo').valid() &&
             this.getField('dept_interno_codigo').valid() && this.getField('dept_interno_nombre').valid() && this.getField('vehiculo_codigo').valid() && this.getField('vehiculo_modelo').valid() &&
             this.getField('centro_costo_codigo').valid() && this.getField('centro_costo_nombre').valid() &&
             this.getField('referencia').valid() && this.getField('detalle').valid() && this.getField('documento_fuente').valid() &&
             this.getField('documento_fecha').valid() && this.getField('documento_fecha_vence').valid() && this.getField('documento_base').valid() ) {
             this.cleanValid();       
             this.getField('fecha_sucursal').setError(false,'');
             let correctas = 0;
             let fecha_ac = "";
             let valid = false;
             let valid2 = true;
             if(this.getField('nuevo_documento').getValue() !== 'S'){
             valid = true;
             valid2 = true;}
             else if(this.getField('codigo_sucursal').getValue() !== '' && !this.seguirGrabar) {
               this.alertGeneral.toggle(true,'La sucursal seleccionada no posee fechas permitidas','error');
               this.getField('fecha_sucursal').setError(true,'* La sucursal no posee fechas permitidas');}
             else{
             if(this.getField('documento_fecha'))
             fecha_ac = this.getField('documento_fecha').getValue();
             if(fecha_ac.split("-")){
             if(this.validarFechas(this.getField('documento_fecha_vence').getValue(),this.getField('documento_fecha').getValue(),0)){
             valid = true;
             this.getField('documento_fecha_vence').setError(false,'');}
             else{
             valid = false;
             this.getField('documento_fecha_vence').setError(true,'* Esta fecha no puede ser menor a la fecha del documento fuente');}}
             else{
             if(this.validarFechas(this.getField('documento_fecha_vence').getValue(),this.getField('documento_fecha').getValue(),0)){
             valid = true;
             this.getField('documento_fecha_vence').setError(false,'');}
             else{
             valid = false;
             this.getField('documento_fecha_vence').setError(true,'* Esta fecha no puede ser menor a la fecha del documento fuente');}}
             if(fecha_ac.split("-")){
             if(this.validarFechas(this.getField('documento_fecha').getValue(),this.getField('documento_fecha').getValue(),1)){
             valid2 = true;
             this.getField('documento_fecha').setError(false,'');}
             else{
             valid2 = false;
             this.getField('documento_fecha').setError(true,'* Esta fecha no puede ser mayor a dos meses anteriores');}}
             else{
             if(this.validarFechas(this.getField('documento_fecha').getValue(),this.getField('documento_fecha').getValue(),1)){
             valid2 = true;
             this.getField('documento_fecha').setError(false,'');}
             else{
             valid2 = false;
             this.getField('documento_fecha').setError(true,'* Esta fecha no puede ser mayor a dos meses anteriores');}}}
             if(valid && valid2){
             if(this.getField('codigo_sucursal').valid() & this.getField('fecha_sucursal').valid() & this.getField('nombre_sucursal').valid() & this.getField('codigo_comprobante').valid() & this.getField('nombre_comprobante').valid() & this.getField('numero_documento_comprobante').valid())
             correctas++;
             if(this.getField('dept_interno_codigo').valid() & this.getField('dept_interno_nombre').valid() & this.getField('nit_responsable').valid() & this.getField('nit_responsable_sede').valid() & this.getField('nit_responsable_nombre').valid())
             correctas++;
             if(this.getField('nit').valid() & this.getField('nit_sede').valid() & this.getField('nit_nombre').valid() & this.getField('vehiculo_codigo').valid() & this.getField('vehiculo_modelo').valid())
             correctas++;
             if(this.getField('documento_fuente').valid() & this.getField('documento_base').valid() & this.getField('documento_tarifa').valid() & this.getField('documento_valor').valid())
             correctas++;
             if(this.getField('codigo_cuenta').valid() & this.getField('codigo_cuenta_nombre').valid() & this.getField('codigo_cuenta_movimiento').valid() & this.getField('centro_costo_codigo').valid() & this.getField('centro_costo_nombre').valid())
             correctas++;
             if(this.getField('fecha_desde').getValue() !== '' && this.getField('fecha_hasta').getValue() !== '')
             if(this.validarFechas2(this.getField('fecha_desde').getValue(),this.getField('fecha_sucursal').getValue()) && this.validarFechas2(this.getField('fecha_sucursal').getValue(),this.getField('fecha_hasta').getValue()))
             correctas++;
             else{
             this.getField('fecha_sucursal').setError(true,'* La fecha debe estar entre '+this.getField('fecha_desde').getValue()+' y '+this.getField('fecha_hasta').getValue());
             this.alertGeneral.toggle(true, '* La fecha debe estar entre '+this.getField('fecha_desde').getValue()+' y '+this.getField('fecha_hasta').getValue(),'error'); }
             if(correctas >= 6){
             this.establecerPropiedades('2,4,5,7','disable','true');
             let mdf = this.getField('cuenta_maneja_documento').getValue();
             let debito = (!isNaN(parseFloat(this.getField('total_debito').getValue())))?parseFloat(this.getField('total_debito').getValue()):0;
             let credito = (!isNaN(parseFloat(this.getField('total_creditos').getValue())))?parseFloat(this.getField('total_creditos').getValue()):0;
             if(parseFloat(this.getField('documento_valor').getValue()) > 0){
               let valor1 = debito+parseFloat(Number(this.getField('documento_valor').getValue().toString().replaceAll(',','')));
               this.getField('total_debito').setValue("".redondear(valor1,2));
              }
              else{
                let valor2 = credito+parseFloat(Number(this.getField('documento_valor').getValue().toString().replaceAll(',','')));
                this.getField('total_creditos').setValue("".redondear(valor2,2));
             }
             let total_dif = parseFloat(this.getField('total_debito').getValue()) + parseFloat(this.getField('total_creditos').getValue());
             total_dif= Number(total_dif.toFixed(2));
             this.getField('total_diferencia').setValue(total_dif.toFixed(2));
             let fecha_enviar = this.getField('fecha_sucursal').getValue();
             let datos = {};
             let valorEn=Number(this.getField('documento_valor').getValue().toString().replaceAll(',',''))
             let baseEnv = this.getField('documento_base').getValue();
             let tarifaEnv = this.getField('documento_tarifa').getValue();
             datos['codigo_cuenta']= parseInt(this.getField('codigo_cuenta').getValue());
             datos['centro_costo_codigo']= this.getField('centro_costo_codigo').getValue();
             datos['documento_base']= parseFloat(baseEnv);
             datos['documento_valor']= parseFloat(valorEn);
             datos['codigo_sucursal']= this.getField('codigo_sucursal').getValue();
             datos['codigo_comprobante']= this.getField('codigo_comprobante').getValue();
             datos['fecha_sucursal'] = fecha_enviar;
             if(this.getField('es_modificable').getValue()==='N'){
                datos['numero_documento_comprobante']= '0';
             }else{
                datos['numero_documento_comprobante']= this.getField('numero_documento_comprobante').getValue();
             }
             datos['nit']= this.getField('nit').getValue();
             datos['nit_id']= this.getField('nit_id').getValue();
             datos['nit_responsable']= this.getField('nit_responsable').getValue();
             datos['nit_sede']= parseInt(this.getField('nit_sede').getValue());
             datos['nit_responsable_sede']= parseInt(this.getField('nit_responsable_sede').getValue());
             datos['doc_bancario_codigo']= this.getField('doc_bancario_codigo').getValue();
             datos['sDocumentoNiif']= '.';
             datos['nCodigoCuentaNiif']= 0;
             datos['sCodigoUsuario']= 'gda';
             datos['sCodigoOrigen']= 'CONTA';
             datos['nValorNiif']= 0;
             datos['sDocumentoNit']= '.';
             datos['sDetalleNiif']= '.';
             datos['detalle']= this.getField('detalle').getValue();
             datos['cuenta_tipo_fuente']= this.getField('cuenta_tipo_fuente').getValue();
             
             
             datos['cuenta_maneja_documento'] = this.getField('cuenta_maneja_documento').getValue();
             datos['nuevo_documento'] = this.getField('nuevo_documento').getValue();
             datos['referencia'] = this.getField('referencia').getValue();
             datos['codigo_cuenta_nombre'] = this.getField('codigo_cuenta_nombre').getValue();
             datos['codigo_cuenta_movimiento'] = this.getField('codigo_cuenta_movimiento').getValue(); datos['codigo_cuenta_tipo'] = this.getField('codigo_cuenta_tipo').getValue();
             datos['centro_costo_nombre'] = this.getField('centro_costo_nombre').getValue();
             datos['nit_nombre'] = this.getField('nit_nombre').getValue();
             datos['nit_responsable_nombre'] = this.getField('nit_responsable_nombre').getValue();
             datos['cuenta_de_retefuente'] = this.getField('cuenta_de_retefuente').getValue();
             datos['cuenta_de_reteiva'] = this.getField('cuenta_de_reteiva').getValue();
             datos['cuenta_de_reteica'] = this.getField('cuenta_de_reteica').getValue();
             datos['cuenta_maneja_base'] = this.getField('cuenta_maneja_base').getValue();
             if(this.usar_numero_documento_sucursales===true){
               datos['es_modificable'] = 'S';
             }else{
               datos['es_modificable'] = this.getField('es_modificable').getValue();
             }
             datos['cuenta_id'] = this.getField('cuenta_id').getValue();
             
             datos['documento_fuente'] = this.getField('documento_fuente').getValue();
             datos['documento_fecha'] = this.getField('documento_fecha').getValue();
             datos['documento_fecha_vence'] = this.getField('documento_fecha_vence').getValue();
             datos['sNiif']= 'N';
             datos['dept_interno_codigo']= this.getField('dept_interno_codigo').getValue();
             datos['dept_interno_nombre'] = this.getField('dept_interno_nombre').getValue();
             datos['vehiculo_codigo'] = this.getField('vehiculo_codigo').getValue();
             datos['vehiculo_modelo'] = this.getField('vehiculo_modelo').getValue();
             datos['cuenta_de_iva'] = this.getField('cuenta_de_iva').getValue();
             datos['iva_ventas'] = this.getField('iva_ventas').getValue();
             datos['documento_tarifa'] = parseFloat(tarifaEnv);
             
             


             if(mdf === 'S')
             datos['documento_llave']=this.getField('documento_llave').getValue();
             else
             datos['documento_llave']='0';

             
             this.dataToSend.push(datos);
             this.updateTable();
             if(total_dif>=-0.99&&total_dif <= 0.99){
               this.getField('guardar_todo').setDisabled(false);
             }
             else{
               this.getField('guardar_todo').setDisabled(true);
             }
             this.limpiarCampos();}}} 
             else if (this.getField('numero_documento_comprobante').getValue() === '-1'){
             this.getField('numero_documento_comprobante').setError(true,'El campo ** Nro documento debe ser diferente de -1 **.');  
             this.alertGeneral.toggle(true,'Alguno de los campos requeridos no está diligenciado correctamente','error');  } else this.alertGeneral.toggle(true,'Alguno de los campos requeridos no está diligenciado correctamente','error');  
    }

    limpiarCampos()
    {
            this.cleanValid(); for(let ref in this.refs){ if(ref !== 'sucursales_id' && ref !== 'codigo_sucursal' && ref !== 'es_modificable' && ref !== 'nombre_sucursal' && ref !== 'comprobante_id' && ref !== 'codigo_comprobante' && ref !== 'nombre_comprobante' && ref !== 'numero_documento_comprobante' && ref !== 'fecha_sucursal' && ref !== 'nit' && ref !== 'nit_sede' && ref !== 'nit_nombre' && ref !== 'nit_responsable' && ref !== 'nit_responsable_sede'  && ref !== 'nit_responsable_nombre' && ref !== 'documento_fecha' && ref !== 'documento_fecha_vence' && ref !== 'iva_ventas' && ref !== 'fecha_desde' && ref !== 'fecha_hasta'){ if(this.getField(ref).valueDefault && this.getField(ref).valueDefault !== null && this.getField(ref).valueDefault !== undefined && this.getField(ref).valueDefault !== "") this.getField(ref).setValue(this.getField(ref).valueDefault); else if(this.getField(ref).setValue!==undefined && ref !== 'total_debito' && ref !== 'fecha_hasta' && ref !== 'total_creditos' && ref !== 'total_diferencia') this.getField(ref).setValue("");} else if(ref === 'iva_ventas') this.getField(ref).setValue('');} 
            this.getField('buscar').setDisabled(true); this.getField('nuevo_documento').setValue('S');
            this.getField('documento_tarifa').setValue(0);
            this.establecerPropiedades('5,7,53,55,56','disable','true');
    }

    updateTable()
    {
         this.cleanValid(); 
         if(this.dataToSend.length !== 0){ 
            let configCell = new Map(); 
            configCell.set(0,{cellRenderer:this.setButtonDelete,width:120,sortable:false,filter:false,field:'Operación 1'}); 
            configCell.set('documento_valor',{cellRenderer:function(props){return"".formatoPrecio(props.data.documento_valor)},width:120 ,field:'documento_valor'}); 
            configCell.set('documento_base',{cellRenderer:function(props){return"".formatoPrecio(props.data.documento_base)},width:120,field:'documento_base' }); 

            this.gridOptionsComponentes['rowData'] = this.dataToSend; 
            this.getField('tabla_movimiento').initData(this.gridOptionsComponentes,configCell)
         } else{ 
            this.getField('documento_valor').setValue(0); 
            this.getField('total_debito').setValue(0); 
            this.getField('total_creditos').setValue(0); 
            this.getField('total_diferencia').setValue(0); 
            this.getField('tabla_movimiento').toggle(false);
            this.getField('codigo_sucursal').setValue('');
            this.getField('nombre_sucursal').setValue('');
            this.getField('codigo_comprobante').setValue('');
            this.getField('nombre_comprobante').setValue('');
            this.getField('nit').setValue('');
            this.getField('nit_sede').setValue('');
            this.getField('nit_nombre').setValue('');
            this.getField('nit_responsable').setValue('');
            this.getField('nit_responsable_nombre').setValue('');
            this.getField('nit_responsable_sede').setValue('');
            this.getField('numero_documento_comprobante').setValue('');
            this.getField('fecha_sucursal').setValue(' ');
            this.limpiarCampos();
            this.establecerPropiedades('2','foco','.');
            this.establecerPropiedades('2,4,61','disable','false');
         }
    }

    setButtonDelete(props)
    {
            let id = props.data.id;
             let position = parseInt(props.node.id);
             let button = document.createElement("input"); 
             button.onclick = ()=>this.deleteItem(position);
             button.setAttribute("id",'button_delete_'+id);
             button.setAttribute("class","buttonStyle");
             button.setAttribute("type","button");
             button.setAttribute("value","Eliminar");
             return this.createElementJaivana(button);
    }

    deleteItem(pos)
    {
            let datosNuevos = [];
             for(let i = 0; i<this.dataToSend.length; i++){
             if(i !== pos)
             datosNuevos.push(this.dataToSend[i]);
             else{
             for(let ref in this.dataToSend[i]){
             if(this.getField(ref) !== undefined && ref !== 'documento_tarifa' && ref !== 'documento_base' && ref !== 'documento_valor' && 
             ref !== 'codigo_sucursal' && ref !== 'nombre_sucursal' && ref !== 'codigo_comprobante' && ref !== 'nombre_comprobante')
             this.getField(ref).setValue(this.dataToSend[i][ref]);}
             this.getField('documento_tarifa').setValue(this.dataToSend[i]['documento_tarifa']);
             this.getField('documento_base').setValue(this.dataToSend[i]['documento_base']);
             this.getField('documento_valor').setValue(this.dataToSend[i]['documento_valor']);
             this.getField('iva_ventas').setValue(this.dataToSend[i]['documento_tarifa']);
             
             if(this.dataToSend[i].documento_valor < 0){
               let valor1 = parseFloat(this.getField('total_creditos').getValue())-parseFloat(this.dataToSend[i].documento_valor)
               this.getField('total_creditos').setValue("".redondear(valor1,2));
             }
             else{
               let valor2 = parseFloat(this.getField('total_debito').getValue())-parseFloat(this.dataToSend[i].documento_valor)
               this.getField('total_debito').setValue("".redondear(valor2,2));
             }
             let total_dif = parseFloat(this.getField('total_debito').getValue()) + parseFloat(this.getField('total_creditos').getValue());
             total_dif= Number(total_dif.toFixed(2));
             this.getField('total_diferencia').setValue(total_dif.toFixed(2));
               if(total_dif >= -0.99&&total_dif <= 0.99){
               this.getField('guardar_todo').setDisabled(false);
               }
               else{
               this.getField('guardar_todo').setDisabled(true);
               }
             }}
             this.dataToSend = datosNuevos;
             this.updateTable();
             this.getField('fieldAlert').toggle(true,"Acción Exitosa","success");
    }

    validarMovimiento()
    {     
      if(this.getField('codigo_cuenta_movimiento').getValue() === ''){
         this.establecerPropiedades('35,37,41,52,53,54,55,56,57,41','disable','true');
         this.getField('doc_bancario_codigo').setValue('.');
         this.getField('vehiculo_codigo').setValue('');
         this.getField('vehiculo_modelo').setValue('');
         this.getField('vehiculo_id').setValue('');
         this.getField('dept_interno_codigo').setValue('');
         this.getField('dept_interno_nombre').setValue('');
         this.getField('dept_interno_id').setValue('');

         this.getField('documento_fuente').setValue('.');
         this.getField('documento_fecha').setValue(' ');
         this.getField('documento_fecha_vence').setValue(' ');
         this.getField('documento_llave').setValue(0);


         this.getField('dept_interno_codigo').setError(false,'* El campo es requerido');        this.getField('vehiculo_codigo').setError(false,'* El campo es requerido');
         this.getField('vehiculo_codigo').setError(false,'* El campo es requerido');        this.getField('vehiculo_codigo').setError(false,'* El campo es requerido');

      }
      if(this.getField('codigo_cuenta_movimiento').getValue() !== '' && this.getField('codigo_cuenta_tipo').getValue() !== ''){   
         this.cleanValid();
        
         this.setDocumentoFuente();
         let movimiento = this.getField('codigo_cuenta_movimiento').getValue();
         this.getField('codigo_cuenta_tipo').setValue(this.getField('cuenta_tipo_fuente').getValue());
         let tipo = this.getField('codigo_cuenta_tipo').getValue();
         let documento = this.getField('cuenta_maneja_documento').getValue();
            
         if(movimiento === 'S'){
             if(documento === 'S' && tipo === 'C'){
             this.getField('codigo_cuenta_movimiento').setError(false,"* Cuenta mal configurada"); 
             this.getField('codigo_cuenta_tipo').setError(false,"* Cuenta mal configurada");
             this.getField('grabar').setDisabled(false);}
             else if( documento === 'S' && tipo === 'P' ){
             this.getField('codigo_cuenta_movimiento').setError(false,"* Cuenta mal configurada"); 
             this.getField('codigo_cuenta_tipo').setError(false,"* Cuenta mal configurada");
             this.getField('grabar').setDisabled(false);}
             else if(documento === 'N' && tipo === 'N' ){
             this.getField('codigo_cuenta_movimiento').setError(false,"* Cuenta mal configurada"); 
             this.getField('codigo_cuenta_tipo').setError(false,"* Cuenta mal configurada");
             this.getField('grabar').setDisabled(false);}
             else{  
             this.getField('codigo_cuenta_movimiento').setError(true,"* Cuenta mal configurada"); 
             this.getField('codigo_cuenta_tipo').setError(true,"* Cuenta mal configurada");
             this.getField('grabar').setDisabled(true);}
            }
             else if(movimiento === 'N'){
             this.getField('codigo_cuenta_movimiento').setError(false,"* No Maneja Movimiento"); 
             this.getField('grabar').setDisabled(false);}
             else{ 
             this.getField('codigo_cuenta_movimiento').setError(true,"* Cuenta mal configurada"); 
             this.getField('codigo_cuenta_tipo').setError(true,"* Cuenta mal configurada");
             this.getField('grabar').setDisabled(true);}
         }
         this.cambioDeCuenta();
    }

    validarGuardar()
    {
            this.cleanValid();
             if (this.dataToSend.length >= 2) {
               this.getField('guardar_todo').setDisabled(true);
               this.guardarDatos();
            }
             else{
             this.alertGeneral.toggle(true, 'Mínimo deben existir dos registros en la tabla para poder guardar.', "error");}
    }

    guardarDatos()
    {
      this.items = 0;
      this.dataToSend.forEach((value,index)=>{
         let datos = this.dataToSend[index];
         datos['sItem']= this.items;
         this.dataToSend[index]=datos;
         this.items = this.items + 1;
      });

       let datos={ datos: {  data: this.dataToSend }};
            this.generalFormatPmv = { tipo_servicio: 'cont-grabarmvtcontab', operacion: '5_1', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successGuardarDatos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successGuardarDatos(data)
    {
        if (data.estado_p === 200)
        {
            this.generarPDF(data.data[0]);
            this.getField('fieldAlert').toggle(true,"Acción Exitosa","success");
            this.getField('fecha_sucursal').setValue(' ');
            this.establecerPropiedades('2,4,5,7','disable','false');
            this.limpiar();
            
        }
        else 
        {
           if(data.estado_p===404) {
            this.getField('modal_documento').handleClose();
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
       this.getField('guardar_todo').setDisabled(false);
    }

    limpiar()
    {
            this.cleanValid();
             for(let ref in this.refs){
             if(this.getField(ref) && this.getField(ref).valueDefault !== null && this.getField(ref).valueDefault !== undefined && this.getField(ref).valueDefault !== "")
             this.getField(ref).setValue(this.getField(ref).valueDefault);
             else if(this.getField(ref).setValue!==undefined && ref !== 'fecha_sucursal')
             this.getField(ref).setValue("");}
             this.getField('guardar_todo').setDisabled(true);
             this.items = 0;
             this.getField('doc_bancario_codigo').setDisabled(true);
             this.getField('buscar').setDisabled(true);
             this.getField('codigo_comprobante').setDisabled(true);
             this.getField('tabla_movimiento').toggle(false); this.dataToSend = [];
             this.getField('nuevo_documento').setValue('S');
             this.getField('total_debito').setValue(0);
             this.getField('documento_tarifa').setValue(0);
             this.getField('total_creditos').setValue(0);
             this.getField('total_diferencia').setValue(0);
             this.establecerPropiedades('2','foco','.');
             this.establecerPropiedades('2,4','disable','false');
             this.establecerPropiedades('5,7,35,37,52,53,54,55,56,57,58,60,61,73,41','disable','true');
    }


    generarPDF(data)
    {
            let url = this.constant.formConfiguration();
             let datos2={
             codigo_sucursal:data.codigo_sucursal,
             codigo_documento:data.codigo_documento,
             numero_documento:data.numero_documento, 
             fecha:data.fecha_documento}
             const myJSON = JSON.stringify(datos2);
             let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-grabarmvtcontab","operacion":"1","operacion_tipo":"crear_jasper"}}';
             // Fetch the original image
             fetch(url+datos,{
             method:'get', 
             headers:new Headers({ "Authorization":'Bearer '+localStorage.getItem('token'), 
             "Content-Type":'application/json'})})
             .then(response => {
             if(response.status!==200){
             return '';}
             const reader = response.body.getReader();
             return new ReadableStream({
             start(controller) {
             return pump();
             function pump() {
             return reader.read().then(({ done, value }) => {
             if (done) {
             controller.close();
             return;}
             // Enqueue the next data chunk into our target stream
             controller.enqueue(value);
             return pump();});}}})})
             .then(stream => new Response(stream))
             .then(response => response.blob())
             .then(blob => {
             if(blob.size>0){
             const pdf = new File([blob], 'name.pdf', {
             type:'application/pdf'});
             window.open(URL.createObjectURL(pdf))}
             else{
             this.alertGeneral.toggle(true,'No hay datos','error');}})
             .catch(err => {});
    }


    //*** fin  los botones y los success



    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }


   cargarCampos(data) 
   {
          this.getField('id_movimiento_contable').setValue(data.data[0].id_movimiento_contable);
          this.getField('codigo_sucursal').setValue(data.data[0].codigo_sucursal);
          this.getField('nombre_sucursal').setValue(data.data[0].nombre_sucursal);
          this.getField('fecha_sucursal').setValue(data.data[0].fecha_sucursal);
          this.getField('codigo_comprobante').setValue(data.data[0].codigo_comprobante);
          this.getField('nombre_comprobante').setValue(data.data[0].nombre_comprobante);
          this.getField('numero_documento_comprobante').setValue(data.data[0].numero_documento_comprobante);
          this.getField('nit').setValue(data.data[0].nit);
          this.getField('nit_sede').setValue(data.data[0].nit_sede);
          this.getField('nit_nombre').setValue(data.data[0].nit_nombre);
          this.getField('sucursales_id').setValue(data.data[0].sucursales_id);
          this.getField('comprobante_id').setValue(data.data[0].comprobante_id);
          this.getField('nit_id').setValue(data.data[0].nit_id);
          this.getField('es_modificable').setValue(data.data[0].es_modificable);
          this.getField('fecha_desde').setValue(data.data[0].fecha_desde);
          this.getField('fecha_hasta').setValue(data.data[0].fecha_hasta);
          this.getField('cuenta_de_retefuente').setValue(data.data[0].cuenta_de_retefuente);
          this.getField('cuenta_de_iva').setValue(data.data[0].cuenta_de_iva);
          this.getField('nit_responsable').setValue(data.data[0].nit_responsable);
          this.getField('nit_responsable_sede').setValue(data.data[0].nit_responsable_sede);
          this.getField('nit_responsable_nombre').setValue(data.data[0].nit_responsable_nombre);
          this.getField('codigo_cuenta').setValue(data.data[0].codigo_cuenta);
          this.getField('codigo_cuenta_nombre').setValue(data.data[0].codigo_cuenta_nombre);
          this.getField('codigo_cuenta_movimiento').setValue(data.data[0].codigo_cuenta_movimiento);
          this.getField('codigo_cuenta_tipo').setValue(data.data[0].codigo_cuenta_tipo);
          this.getField('nit_responsable_id').setValue(data.data[0].nit_responsable_id);
          this.getField('cuenta_id').setValue(data.data[0].cuenta_id);
          this.getField('cuenta_maneja_documento').setValue(data.data[0].cuenta_maneja_documento);
          this.getField('cuenta_maneja_banco').setValue(data.data[0].cuenta_maneja_banco);
          this.getField('cuenta_maneja_base').setValue(data.data[0].cuenta_maneja_base);
          this.getField('cuenta_tipo_fuente').setValue(data.data[0].cuenta_tipo_fuente);
          this.getField('cuenta_de_reteiva').setValue(data.data[0].cuenta_de_reteiva);
          this.getField('cuenta_de_reteica').setValue(data.data[0].cuenta_de_reteica);
          this.getField('dept_interno_codigo').setValue(data.data[0].dept_interno_codigo);
          this.getField('dept_interno_nombre').setValue(data.data[0].dept_interno_nombre);
          this.getField('vehiculo_codigo').setValue(data.data[0].vehiculo_codigo);
          this.getField('vehiculo_modelo').setValue(data.data[0].vehiculo_modelo);
          this.getField('centro_costo_codigo').setValue(data.data[0].centro_costo_codigo);
          this.getField('centro_costo_nombre').setValue(data.data[0].centro_costo_nombre);
          this.getField('doc_bancario_codigo').setValue(data.data[0].doc_bancario_codigo);
          this.getField('referencia').setValue(data.data[0].referencia);
          this.getField('detalle').setValue(data.data[0].detalle);
          this.getField('dept_interno_id').setValue(data.data[0].dept_interno_id);
          this.getField('vehiculo_id').setValue(data.data[0].vehiculo_id);
          this.getField('centro_costo_id').setValue(data.data[0].centro_costo_id);
          this.getField('nuevo_documento').setValue(data.data[0].nuevo_documento);
          this.getField('documento_fuente').setValue(data.data[0].documento_fuente);
          this.getField('buscar').setValue(data.data[0].buscar);
          this.getField('documento_fecha').setValue(data.data[0].documento_fecha);
          this.getField('documento_fecha_vence').setValue(data.data[0].documento_fecha_vence);
          this.getField('documento_llave').setValue(data.data[0].documento_llave);
          this.getField('documento_base').setValue(data.data[0].documento_base);
          this.getField('iva_ventas').setValue(data.data[0].iva_ventas);
          this.getField('documento_tarifa').setValue(data.data[0].documento_tarifa);
          this.getField('documento_valor').setValue(data.data[0].documento_valor);
          this.getField('grabar').setValue(data.data[0].grabar);
          this.getField('tabla_movimiento').setValue(data.data[0].tabla_movimiento);
          this.getField('total_debito').setValue(data.data[0].total_debito);
          this.getField('total_creditos').setValue(data.data[0].total_creditos);
          this.getField('total_diferencia').setValue(data.data[0].total_diferencia);
          this.getField('guardar_todo').setValue(data.data[0].guardar_todo);
          this.getField('tabla_grabacion_mov_cliente').setValue(data.data[0].tabla_grabacion_mov_cliente);
          this.getField('tabla_grabacion_mov_proveedores').setValue(data.data[0].tabla_grabacion_mov_proveedores);
          this.getField('modal_documento').setValue(data.data[0].modal_documento);
   }

}
FormJaivana.addController('cont-grabarmvtcontab',CustomGrabarMovimientoContable);
export default CustomGrabarMovimientoContable;


