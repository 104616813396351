import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Anderson Acevedo Briñez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomIntegrarArchivos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.habilitarBotones    =   this.habilitarBotones.bind(this);
        this.limpiarCampos = this.limpiarCampos.bind(this);
        this.llenarTabla    =   this.llenarTabla.bind(this);
        this.integrarArchivo    =   this.integrarArchivo.bind(this);
        this.gridOptionsComponentes     = Object.assign({},this.gridOptions);
        this.ordenarArray = this.ordenarArray.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.traerUrlSubirArchivos= this.traerUrlSubirArchivos.bind(this);
        this.succesTraerUrlSubirArchivos= this.succesTraerUrlSubirArchivos.bind(this);
        this.successData = this.successData.bind(this);
        this.url_servidor='';
        this.estadoUF = '';
        this.nombre_archivo='';
        this.datos_file='';
        this.archivo_subido=false;
        this.recien_creado=false;
    }

    initForm(){
        console.log('Formulario CustomIntegrarArchivos,  @version: jdesk_1.01.0001, @author:Anderson Acevedo Briñez')
        this.getField('integrar').setClick(this.integrarArchivo);
        this.getField('cancelar').setClick(this.limpiarCampos);
        this.getField('archivo').setOnChange((props) => { 
                        this.estadoUF = 'xlsx'; 
                        this.datos_file=props.target.files[0];
                        this.habilitarBotones();});
        this.getField('lista_tablas').setOnChange((props) => { this.getField('lista_tablas').setError(false,'');
                        this.nombre_archivo=this.getField('lista_tablas').getValue();
                        if(this.recien_creado===true){
                            this.getField('rejilla').toggle(true);
                            this.recien_creado=false;
                        }else{
                            this.getField('rejilla').toggle(false);
                        }
                        this.habilitarBotones() });
        this.getField('integrar').setDisabled(true);
        this.traerUrlSubirArchivos();

    }
    habilitarBotones(){
        if(this.getField('lista_tablas').valid()){
            if(this.nombre_archivo!=='' && this.nombre_archivo!==' ' && this.datos_file!=='' && this.datos_file!==undefined ) {
                if(this.nombre_archivo===this.datos_file.name){
                    this.getField('integrar').setDisabled(false);
                    this.uploadFile(this.datos_file);
                }else{
                    this.getField('integrar').setDisabled(true);
                    this.alertGeneral.toggle(true, 'La tabla a integrar tiene que ser el mismo archivo a subir.', "error");
                }
            
            }else{
                this.getField('integrar').setDisabled(true);
            }
        } 
    }

    llenarTabla(data){
        this.getField('confirmModalCustom').toggle(false);
        let array=[];
        if(data.estado_p===200){
            let values=Object.values(data.data.informacion);
            let keys=Object.keys(data.data.informacion);
            
            values.forEach((element,index )=> {
                if( typeof element==='string'){
                    let datos={
                        fila: keys[index],
                        detalle:   element,
                    }
                    array.push(datos);
                }else{
                    let values2=Object.values(element);
                    values2.forEach(element2 => {
                        let datos={
                            fila: keys[index],
                            detalle:   element2.toString(),
                        }
                        array.push(datos);
                    });
                }   
            });
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            array=this.ordenarArray(array);
            this.gridOptionsComponentes['rowData'] = array;
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
        }else{
            if(data.data.errores.mensaje !== undefined){
                let datos={
                    fila: 'mensaje',
                    detalle:   data.data.errores.mensaje,
                }
                array.push(datos);
                this.getField('rejilla').toggle(true);
                let configCell = new Map();
                this.gridOptionsComponentes['rowData'] = array;
                this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
            }else{
                let values=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                
                values.forEach((element,index )=> {
                    let values2=Object.values(element);
                    values2.forEach(element2 => {
                        let datos={
                            fila: keys[index],
                            detalle:   element2.toString(),
                        }
                        array.push(datos);
                    });
                });
                this.getField('rejilla').toggle(true);
                let configCell = new Map();
                array=this.ordenarArray(array);
                this.gridOptionsComponentes['rowData'] = array;
                this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
            }
            
        }
        this.getField('integrar').setDisabled(false);
        this.getField('cancelar').setDisabled(false);
        this.recien_creado=true;
        this.getField('lista_tablas').setValue('');
        this.getField('integrar').setDisabled(true);
        this.getField('lista_tablas').setError(false,'');
        this.estadoUF = '';
        this.nombre_archivo='';
        this.datos_file='';
        this.archivo_subido=false;

    }

    ordenarArray(array){
        let array2=[];
        let array3=[];
        array.forEach((element) => {
            if(array2.includes(element.fila)===false){
                array2.push(element.fila);
            }
        });
        array2.sort();
        array2.forEach((element) => {
            array.forEach((element2) => {
                if(element===element2.fila)
                    array3.push(element2);
            });
        });
        return array3;

    }

    limpiarCampos(){
        this.getField('lista_tablas').setValue('');
        //this.getField('archivo').setValue('');
        this.getField('rejilla').toggle(false);
        this.getField('integrar').setDisabled(true);
        this.getField('lista_tablas').setError(false,'');
        this.nombre_archivo='';
        this.datos_file='';
        this.archivo_subido=false;
      }

    integrarArchivo()
    {
        this.getField('rejilla').toggle(false);
            if(this.url_servidor!=='' && this.url_servidor!==''){
            if(this.nombre_archivo!=='' && this.nombre_archivo!==' '){
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, está procesando el archivo... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);


                this.getField('cancelar').setDisabled(true);
                this.getField('integrar').setDisabled(true);
                let url = this.url_servidor+'/xlsx/api/protected';
                let body={
                    datos:{
                        nombre_archivo: this.nombre_archivo
                    },
                    generales: {
                        tipo_servicio: 'subirarchivos',
                        operacion: 'procesarxlsx',
                        operacion_tipo: 'crear'
                    }
                }
                this.generalFormat = body.generales;
                this.service.send(
                    {
                        endpoint: url,
                        method: 'POST',
                        body: body,
                        success: this.llenarTabla,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: false,
                        showMessage: false
                    });
            }
        }else{
            this.alertGeneral.toggle(true, 'Url del servidor mal configurada.', 'error'); 
        }
    }

    traerUrlSubirArchivos(){

        let datos={ 
            datos: {}
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-integrararchivos', operacion: 'obtenerurl', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesTraerUrlSubirArchivos,
                error: this.error_,
                general: this.generalFormatPmv
        });
      }
    
      succesTraerUrlSubirArchivos(data){
          if (data.estado_p === 200 )
          {
              this.url_servidor=data.data[0].url_subir_archivo+':'+data.data[0].puerto_servidor_subir_archivo
          }
          else 
          {
            this.url_servidor='';
          }
      }

    uploadFile(e) {
        if(this.url_servidor!=='' && this.url_servidor!==''){
            if(e !== undefined && e !==''){
                let url = this.url_servidor+'/xlsx/api/protected';
                if (this.estadoUF === 'xlsx') {
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, está guardando el archivo... `);
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                }

                this.getField('rejilla').toggle(false);
                let file = e;
                this.nombre_archivo=file.name;
                const data = {
                    _generales: { "tipo_servicio": "subirarchivos", "operacion": this.estadoUF },
                    archivo: file//this.getField('subir_archivo').getValue(),
                }

                this.generalFormat = data._generales;
                this.service.send(
                    {
                        endpoint: url,
                        method: 'POST',
                        body: data,
                        success: this.successData,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: true,
                        showMessage: false
                    });
            }
        }else{
            this.alertGeneral.toggle(true, 'Url del servidor mal configurada.', 'error'); 
        }

    }

    successData(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'Archivo subido con éxito.', "success");
            this.archivo_subido=true;
            let array=[];
            let datos={
                fila: 'mensaje',
                detalle:   data.data.mensaje
            }
            array.push(datos);
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = array;
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
        }else {
            this.archivo_subido=false;
            let array=[];
            let mensaje='';
            if(data.data.errores.mensaje!==undefined){
                mensaje=data.data.errores.mensaje;
            }else if(data.data.errores.archivo!==undefined){
                mensaje=data.data.errores.archivo;
            }
            let datos={
                fila: 'mensaje',
                detalle:   mensaje
            }
            array.push(datos);
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = array;
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
        }
    }

    

}
FormJaivana.addController("maes-integrararchivos",CustomIntegrarArchivos);
export default CustomIntegrarArchivos