import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Santiago Hernández N.
 *
 * @version jdesk_10.0.001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomValidarCuentas extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.traerCuentasMs             = this.traerCuentasMs.bind(this);
        this.successTraerCuentasMs      = this.successTraerCuentasMs.bind(this);
        this.arrayCuentasMs             = [];
        this.traerNivel                 = this.traerNivel.bind(this);
        this.successTraerNivel          = this.successTraerNivel.bind(this);
        this.arrayNiveles               = [];
        this.validarArrays              = this.validarArrays.bind(this);
        this.procesarValidarCuentas     = this.procesarValidarCuentas.bind(this);
        this.traerCuentas               = this.traerCuentas.bind(this);
        this.successTraerCuentas        = this.successTraerCuentas.bind(this);
        this.arrayCuentas               = [];
        this.abrirtablainicial          = this.abrirtablainicial.bind(this) 
        this.gridOptionsTbInicial       = Object.assign({},this.gridOptions);
        this.errores                    = [];
        this.graficarerrores            = this.graficarerrores.bind(this);        
    }

    initForm(){
     
        console.log('Formulario CustomValidarCuentas,  @version: jdesk_10.0.001, @author:Santiago Hernández N.')            
        this.getField('btn_procesar').setDisabled(true);
        this.traerCuentas();
        this.traerCuentasMs();
        this.traerNivel();
        this.getField('btn_procesar').setClick(this.procesarValidarCuentas);
        this.abrirtablainicial(); 

    }

    traerCuentas(){
        let datos = { datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-validarcuentas', operacion: '1', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successTraerCuentas,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }

    successTraerCuentas(data){
       

        if (data.estado_p === 200)
        {
            this.arrayCuentas = data.data;
            this.validarArrays();

        }else{
            this.alertGeneral.toggle(true, 'No hay datos para cuentas', 'error');
        } 
        
    }


    traerCuentasMs(){

        let datos = { datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-validarcuentas', operacion: '1_1', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successTraerCuentasMs,
                  error: this.error_,
                  general: this.generalFormatPmv
          });

    }

    successTraerCuentasMs(data){
       

        if (data.estado_p === 200)
        {
            this.arrayCuentasMs = data.data;
            this.validarArrays();

        }else{
            this.alertGeneral.toggle(true, 'No hay datos para cuentas que manejan movimiento en S.', 'error');
        } 
        
    }


    traerNivel(){

       let datos = {datos: {}};
                
       this.generalFormatPmv = { tipo_servicio: 'maes-niveles', operacion: '1', operacion_tipo: 'consulta' };
       this.service.send(
       {
                 endpoint: this.constant.formConfiguration(),
                 method:'GET',
                 body: datos,
                 success: this.successTraerNivel,
                 error: this.error_,
                 general: this.generalFormatPmv
       });
             
    }

    successTraerNivel(data){

        if (data.estado_p === 200)
        {
            this.arrayNiveles = data.data;
            //this.validarArrays();
            let contador = 1;
            this.arrayNiveles.forEach(niveles => {
                niveles.id = contador++;
               
            });
            this.validarArrays();            
        }else{
            this.alertGeneral.toggle(true, 'No hay datos para niveles', 'error');
        } 

    }


    validarArrays(){    
        if (this.arrayCuentasMs.length>0 && this.arrayNiveles.length>0 && this.arrayCuentas.length>0){
            this.getField('btn_procesar').setDisabled(false);
        }
    }

    procesarValidarCuentas(){        
        this.getField('btn_procesar').setDisabled(true);
        if (this.arrayCuentasMs.length>0){
            let cuentaActual = this.arrayCuentasMs[0].codigo_cuenta_texto;
            let nivelActual = this.arrayCuentasMs[0].codigo_nivel;
            let nivelAnterior = nivelActual-1;
            let cuentaAnterior;
        

            if (nivelAnterior === 0){

                let indexItem = this.arrayCuentasMs.indexOf(this.arrayCuentasMs[0]);
                this.arrayCuentasMs.splice(indexItem, 1);
                this.procesarValidarCuentas();
                    
            }else{
                if (nivelAnterior===this.arrayNiveles[nivelAnterior-1].codigo_nivel){                        
                    cuentaAnterior=cuentaActual.substring(0,this.arrayNiveles[nivelAnterior-1].caracteres)
                    const resultado = this.arrayCuentas.find( cuenta => cuenta.codigo_cuenta_texto ===  cuentaAnterior);
                    
                    if (resultado!== undefined){

                        if (resultado.maneja_movimiento==='S'){
                            let encabezadoCuentaNivel = 'cuenta';
                            let encabezadoDetalle = 'detalle';
                            const resultadoErrores = this.errores.find( errores => errores.cuenta ===  cuentaAnterior +"-"+ nivelAnterior);

                            if (resultadoErrores===undefined){
                                let dataOp = {}
                                dataOp[encabezadoCuentaNivel] =  cuentaAnterior +"-"+ nivelAnterior;
                                dataOp[encabezadoDetalle] = "Error en la validación - Cuenta es de movimiento " + cuentaAnterior;                            
                                this.errores.push(dataOp);
                            }
                            
                            let indexItem = this.arrayCuentasMs.indexOf(this.arrayCuentasMs[0]);
                            this.arrayCuentasMs.splice(indexItem, 1);
                            this.procesarValidarCuentas();        
                        }else{
                            let indexItem = this.arrayCuentasMs.indexOf(this.arrayCuentasMs[0]);
                            this.arrayCuentasMs.splice(indexItem, 1);           
                            this.procesarValidarCuentas();    
                        }

                    } else {
                        let encabezadoCuentaNivel = 'cuenta';
                        let encabezadoDetalle = 'detalle';
                        const resultadoErrores = this.errores.find( errores => errores.cuenta ===  cuentaAnterior +"-"+ nivelAnterior);

                        if (resultadoErrores===undefined){
                            let dataOp = {}
                            dataOp[encabezadoCuentaNivel] =  cuentaAnterior +"-"+ nivelAnterior;
                            dataOp[encabezadoDetalle] = "Error en la validación - Cuenta no existe " + cuentaAnterior;                            
                            this.errores.push(dataOp);
                        }
                        
                        let indexItem = this.arrayCuentasMs.indexOf(this.arrayCuentasMs[0]);
                        this.arrayCuentasMs.splice(indexItem, 1);
    
                        this.procesarValidarCuentas();
                    }
                }else{

                    let encabezadoCuentaNivel = 'cuenta';
                    let encabezadoDetalle = 'detalle';
                    const resultadoErrores = this.errores.find( errores => errores.cuenta ===  cuentaAnterior +"-"+ nivelAnterior);

                    if (resultadoErrores===undefined){
                        let dataOp = {}
                        dataOp[encabezadoCuentaNivel] =  cuentaAnterior +"-"+ nivelAnterior;
                        dataOp[encabezadoDetalle] = "Error en la validación";                            
                        this.errores.push(dataOp);
                    }

                    let indexItem = this.arrayCuentasMs.indexOf(this.arrayCuentasMs[0]);
                    this.arrayCuentasMs.splice(indexItem, 1);        
                    this.procesarValidarCuentas();
                    
                }
            }
        }else{            
            this.graficarerrores();
        }

    }

    abrirtablainicial(){
        this.getField('tb_validarcuentas').toggle(true);
        let configCell = new Map();
        this.gridOptionsTbInicial['rowData'] = [{}];
        configCell.set('cuenta', {headerName:'cuenta-nivel'});
        this.getField('tb_validarcuentas').initData(this.gridOptionsTbInicial,configCell);
    }

    graficarerrores(){
        this.getField('tb_validarcuentas').toggle(true);
        let configCell = new Map();
        this.gridOptionsTbInicial['rowData'] = this.errores;
        configCell.set('cuenta', {headerName:'cuenta-nivel'});
        this.getField('tb_validarcuentas').initData(this.gridOptionsTbInicial,configCell);
    }

}

FormJaivana.addController("cont-validarcuentas",CustomValidarCuentas);
export default CustomValidarCuentas