import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
 * @author Mario A. Sepúlveda Rojas
 * @version jdesk_1.01.0001
 * @modified 31-05-2023
*/
/**
 * CustomActivosFijosCrear
**/
class CustomActivosFijosCrear extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm = this.initForm.bind(this);
       this.arrayObjetos=new Map();
       this.arrayCampos=new Map();
       this.traerNumeroConsecutivo = this.traerNumeroConsecutivo.bind(this);
       this.successTraerConsecutivo = this.successTraerConsecutivo.bind(this);
       this.grabarActivoFijo = this.grabarActivoFijo.bind(this);
       this.successGrabarActivoFijo = this.successGrabarActivoFijo.bind(this);
       this.limpiarCampos = this.limpiarCampos.bind(this);
       this.actualizarConsecutivo = this.actualizarConsecutivo.bind(this);
       this.successActualizarConsecutivo = this.successActualizarConsecutivo.bind(this);
       this.validarCodigoUnico = this.validarCodigoUnico.bind(this);
       this.successValidarCodigo = this.successValidarCodigo.bind(this);
       this.numeroConsecutivo='';
   }

   initForm(){
    console.log("Formulario CustomActivosFijosCrear,  @version: jdesk_1.01.0001, @author: Mario A. Sepúlveda Rojas, @updateby: Mario A. Sepúlveda Rojas");

    this.arrayCampos.set(1,'id');
    this.arrayCampos.set(2,'codigo_interno');
    this.arrayCampos.set(3,'descripcion');
    this.arrayCampos.set(4,'codigo');
    this.arrayCampos.set(5,'nombre_articulo');
    this.arrayCampos.set(6,'articulo_id');
    this.arrayCampos.set(7,'clase');
    this.arrayCampos.set(8,'estadoactivo_id');
    this.arrayCampos.set(9,'aceptar');
    this.arrayCampos.set(10,'cancelar');
    this.arrayCampos.set(11,'activo_fijo_clase_id');
    this.arrayCampos.set(12,'activofijo_numero');
    this.arrayCampos.set(17,'label2');
    this.arrayCampos.set(18,'operacion');

    this.arrayObjetos.set(1,'id');
    this.arrayObjetos.set(2,'codigo_interno');
    this.arrayObjetos.set(3,'descripcion');
    this.arrayObjetos.set(4,'codigo');
    this.arrayObjetos.set(5,'nombre_articulo');
    this.arrayObjetos.set(6,'articulo_id');
    this.arrayObjetos.set(7,'clase');
    this.arrayObjetos.set(8,'estadoactivo_id');
    this.arrayObjetos.set(9,'aceptar');
    this.arrayObjetos.set(10,'cancelar');
    this.arrayObjetos.set(11,'activo_fijo_clase_id');
    this.arrayObjetos.set(12,'activofijo_numero');
    this.arrayObjetos.set(17,'label2');
    this.arrayObjetos.set(18,'operacion');


    //activofijo_numero
    
    this.getField(this.arrayCampos.get(18)).setValue('AGREGAR');
    this.traerNumeroConsecutivo();

    this.getField(this.arrayCampos.get(9)).setClick(this.validarCodigoUnico);
    this.getField("cancelar").setClick(this.limpiarCampos);
    
    }

    traerNumeroConsecutivo(){
        let datos={ datos: {} };

        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijoscrear', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerConsecutivo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successTraerConsecutivo(data){
        if(data.estado_p === 200){
            //console.log('data respuesta: ',data);
            this.numeroConsecutivo = data.data[0].numero;

            let codigo_activo = (data.data[0].numero+1).toString();
            let concat = '';
            for(let i=0;i<data.data[0].activofijo_longitud-codigo_activo.length;i++){
                 concat = concat + '0';
            }
            codigo_activo = `${concat}${codigo_activo}`;
            //console.log('string ->',codigo_activo);

            this.getField(this.arrayCampos.get(2)).setValue(codigo_activo);        
        }
        
    }

    validarCodigoUnico(){

        let articulo_id = this.getField(this.arrayCampos.get(6)).getValue();
        let activo_fijo_clase_id = this.getField(this.arrayCampos.get(11)).getValue();

        if(articulo_id !== '' && articulo_id !== ' ' && articulo_id !== null && articulo_id !== undefined && this.getField(this.arrayCampos.get(4)).valid() &&
            this.getField(this.arrayCampos.get(3)).valid() && activo_fijo_clase_id !== '' && this.activo_fijo_clase_id !== ' ' && 
            activo_fijo_clase_id !== null && activo_fijo_clase_id !== undefined && this.getField(this.arrayCampos.get(8)).valid() && this.getField(this.arrayCampos.get(7)).valid()){ // this.getField(this.arrayCampos.get(4)).valid()
            
            this.getField(this.arrayCampos.get(9)).setDisabled(true);
            
            let datos={ datos: {
                codigo:this.getField(this.arrayCampos.get(4)).getValue(),
            } };

            //console.log("datos del validar:",datos);

            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijoscrear', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successValidarCodigo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            });
        }
    }

    successValidarCodigo(data){
        if(data.estado_p === 200){
            this.getField(this.arrayCampos.get(9)).setDisabled(false);
            //console.log("el campo codigo ya existe no se permite duplicar");
            this.alertGeneral.toggle(true, 'El campo Artículo base ya existe, no se permite duplicar.', 'error');
        }else if(data.estado_p === 404){
            this.grabarActivoFijo();
        }
    }

    grabarActivoFijo(){
        if(this.getField(this.arrayCampos.get(3)).valid() && this.getField(this.arrayCampos.get(6)).valid() && this.getField(this.arrayCampos.get(7)).valid() 
        && this.getField(this.arrayCampos.get(8)).valid() && this.getField(this.arrayCampos.get(11)).valid()){
            this.getField(this.arrayCampos.get(9)).setDisabled(true);

            let datos={ datos: {
                descripcion:this.getField(this.arrayCampos.get(3)).getValue(),
                codigo:this.getField(this.arrayCampos.get(4)).getValue(),
                activosfijos_clases_id:this.getField(this.arrayCampos.get(11)).getValue(),
                estadoactivo_id:this.getField(this.arrayCampos.get(8)).getValue()
            } };

            //console.log("datos del grbar:",datos);

            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijoscrear', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successGrabarActivoFijo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:true
            });
        }
    }

    successGrabarActivoFijo(data){
        this.getField(this.arrayCampos.get(9)).setDisabled(false);

        if(data.estado_p === 200){
            this.limpiarCampos();
            //console.log("entro a successGrabar",data);
            this.actualizarConsecutivo();
            
        }
    }

    actualizarConsecutivo(){
        let datos={ datos: {
            activofijo_numero: Number(this.numeroConsecutivo+1),
        } };

        //console.log("datos del grbar:",datos);

        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijoscrear', operacion: '6', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successActualizarConsecutivo,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage:false
        });
    }

    successActualizarConsecutivo(data){
        this.getField('aceptar').setDisabled(false);

        if(data.estado_p === 200){
            //console.log('entro a succes actualizar consecutivo ',data);
            this.traerNumeroConsecutivo();
        }
    }

    limpiarCampos(){
        this.getField(this.arrayCampos.get(3)).setValue('');
        this.getField(this.arrayCampos.get(4)).setValue('');
        this.getField(this.arrayCampos.get(5)).setValue('');
        this.getField(this.arrayCampos.get(6)).setValue('');
        this.getField(this.arrayCampos.get(7)).setValue('');
        this.getField(this.arrayCampos.get(8)).setValue('');
        this.getField(this.arrayCampos.get(11)).setValue('');
        this.getField('aceptar').setDisabled(false);

    }

establecerPropiedades(sObjetos,sPropiedad,sValor) 
{
   const array = sObjetos.split(',');
   array.forEach((element) => 
   {
            //console.log(element);
            //console.log(this.arrayObjetos.get(parseInt(element)));
            if(sPropiedad==='disable') 
            { 
                if(sValor==='true') { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }   
            }
            else if(sPropiedad==='visible') 
            { 
                if(sValor==='true') { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }   
            }
            else if(sPropiedad==='value')
            { 
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }  
            else if(sPropiedad==='valid')
            { 
                if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                   //ok
                }
                else {
                    this.bHayErrores=true;
                }
            }
            else if(sPropiedad==='foco')
            { 
                if(sValor==='rejilla') { } 
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }else if(sPropiedad === 'error'){
             if(sValor === false){
                 this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,''); // quita el error de cualquier campo
             } else {
                 this.getField(this.arrayObjetos.get(parseInt(element))).setError(true,'* Este campo es requerido','error'); // pone este mensaje de error por defecto a todos los campos que utilicen esta opcion

             }
         }
  });     
}

cargarCampos(data) 
{
       this.getField('id').setValue(data.data[0].id);
       this.getField('codigo_interno').setValue(data.data[0].codigo_interno);
       this.getField('descripcion').setValue(data.data[0].descripcion);
       this.getField('codigo').setValue(data.data[0].codigo);
       this.getField('nombre_articulo').setValue(data.data[0].nombre_articulo);
       this.getField('articulo_id').setValue(data.data[0].articulo_id);
       this.getField('clase').setValue(data.data[0].clase);
       this.getField('estadoactivo_id').setValue(data.data[0].estadoactivo_id);
       this.getField('aceptar').setValue(data.data[0].aceptar);
       this.getField('cancelar').setValue(data.data[0].cancelar);
       this.getField('activo_fijo_clase_id').setValue(data.data[0].activo_fijo_clase_id);
       this.getField('activofijo_numero').setValue(data.data[0].activofijo_numero);
       this.getField('label2').setValue(data.data[0].label2);
       this.getField('operacion').setValue(data.data[0].operacion);

       
}

}

FormJaivana.addController('maes-activosfijoscrear',CustomActivosFijosCrear);
export default CustomActivosFijosCrear;
