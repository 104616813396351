import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomInactivarActivarTerceros
 * @author: Santiago Hernández N
 * @version: jdesk_1.01.0001
 **/
class CustomInactivarActivarTerceros extends FormJaivana.form {
    constructor(props) {
        
        super(props);
        this.initForm                          = this.initForm.bind(this);
        this.validarEstado                     = this.validarEstado.bind(this);
        this.inactivar                         = this.inactivar.bind(this);
        this.successInactivar                  = this.successInactivar.bind(this);
        this.inactivartercero                  = this.inactivartercero.bind(this); 
        this.validarfechas                     = this.validarfechas.bind(this);
        this.errorfecha                        = false;
        this.saldocc                           = 0;
        this.saldocp                           = 0;
        this.successinactivartercero           = this.successinactivartercero.bind(this); 
        this.insertarlog                       = this.insertarlog.bind(this);
        this.limpiarCampos                     = this.limpiarCampos.bind(this);
        this.user_id='';
        this.successRegistrarLog               = this.successRegistrarLog.bind(this);
        this.activar                           = this.activar.bind(this);
        this.successactivartercero             = this.successactivartercero.bind(this);
        
    }

    initForm() {
        console.log("Formulario CustomInactivarActivarTerceros,  @version: jdesk_1.01.0001, @author: Santiago Hernández N., @updateBy Patricia Lopez");

        this.user_id = JSON.parse(localStorage.getItem('res')).id;
        this.getField('inactivar').setDisabled(true);
        this.getField('activar').setDisabled(true);
        this.getField('estado').setOnChange(this.validarEstado);
        this.getField('inactivar').setClick(this.inactivar);
        this.getField('fecha').setOnChange(this.validarfechas);
        this.getField('activar').setClick(this.activar);
    }   

    validarEstado(){
        if (this.getField('estado').getValue() !== ''){ 
            if (this.errorfecha===false){
                if (this.getField('estado').getValue()==='A'){
                    this.getField('inactivar').setDisabled(false);      
                    this.getField('activar').setVisible(false);              
                }else if (this.getField('estado').getValue()==='I'){
                    this.getField('activar').setDisabled(false);
                    this.getField('inactivar').setVisible(false); 
                }
            }else{
                this.alertGeneral.toggle(true, 'Verifique la fecha seleccionada.', 'error');
            }          

        }else{
            this.getField('detalle').setValue('');
            this.getField('inactivar').setDisabled(true);
            this.getField('activar').setDisabled(true);
        }
    }

    inactivar(){
        
        if (this.getField('nit_buscar').getValue() !== ''){

            let datos={ datos: {nit_buscar: this.getField('nit_buscar').getValue(), sede: this.getField('sede').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-inactivaractivarterceros', operacion: 'saldo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successInactivar,
                error: this.error_,
                general: this.generalFormatPmv
            });


        }else{
            this.getField('nit_buscar').setError(true, "* Este campo es requerido");            
        }
    }

    successInactivar(data){
        if (data.estado_p === 200 ) {
            let saldocc = data.data[0].saldocc;
            let saldocp = data.data[0].saldocp;

            if ((saldocc < -10) || (saldocc > 10)){
                this.alertGeneral.toggle(true, 'El saldo en cartera_clientes es de '+saldocc+', no se permite inactivar.', 'error');
            }else if ((saldocp < -10) || (saldocp > 10)){
                this.alertGeneral.toggle(true, 'El saldo en cartera_proveedores es de '+saldocp+', no se permite inactivar.', 'error');
            }else{
                  
                this.inactivartercero();                    
                
            }
        }else{
            this.alertGeneral.toggle(true, 'Error al consultar cartera_clientes', 'error');
        }
    }

    inactivartercero(){

        if(this.getField('nit_buscar').valid() && this.getField('sede').valid()&&
        this.getField('nombre').valid() && this.getField('detalle').valid()){

            let datos = { datos: {
                registro_id:this.getField('registro_id').getValue(), fecha: this.getField('fecha').getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'maes-inactivaractivarterceros', operacion: 'inactivar', operacion_tipo: 'modificar' };
            this.service.send({

                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successinactivartercero,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }

    }

    successinactivartercero(data){
        if (data.estado_p === 200 ) {
            this.insertarlog('inactivar');            
        } else{
            this.alertGeneral.toggle(true, 'Error al camiar el estado.', 'error');
        }   
    }

    activar(){
        if(this.getField('nit_buscar').valid() && this.getField('sede').valid()&&
        this.getField('nombre').valid() && this.getField('detalle').valid()){

            let datos = { datos: {
                registro_id:this.getField('registro_id').getValue(), fecha: this.getField('fecha').getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'maes-inactivaractivarterceros', operacion: 'activar', operacion_tipo: 'modificar' };

            this.service.send({

                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successactivartercero,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successactivartercero(data){
        if (data.estado_p === 200 ) {
            this.insertarlog('activar');            
        } else{
            this.alertGeneral.toggle(true, 'Error al camiar el estado.', 'error');
        }   
    }

    insertarlog(operacion){
        if(this.getField('detalle').valid()){
            if(operacion === 'activar'){
                this.estado ='A';
            }else{
                this.estado ='I';
            }

            let datos = { datos: {
                nombre_tabla:'nitsx_sedes',
                operacion:operacion,
                registro_id:this.getField('registro_id').getValue(),
                datos_anteriores:this.getField('detalle').getValue(),
                datos_nuevos:this.estado,
                fecha_modificacion:'now()',
                usuario_id:this.user_id
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'maes-inactivaractivarterceros', operacion: '5', operacion_tipo: 'crear' };
            
            
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successRegistrarLog,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
        }
    }

    successRegistrarLog(data){
        if (data.estado_p === 200) {
            this.limpiarCampos();
        } else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    limpiarCampos(){

        this.getField('nit_buscar').setValue('');
        this.getField('nombre').setValue('');
        this.getField('sede').setValue('');
        this.getField('registro_id').setValue('');
        this.getField('detalle').setValue('');
        this.getField('estado').setValue('');
        let fecha = new Date();
        let fecha1  =   fecha.toISOString();
        let fecha2 = fecha1.split('T')[0];

        this.getField('fecha').setValue(fecha2);
        this.errorfecha= false;

        this.getField('inactivar').setDisabled(true);
        this.getField('activar').setDisabled(true);
        this.getField('inactivar').setVisible(true); 
        this.getField('activar').setVisible(true); 

    }
    
    validarfechas(){
        let fechadesde = new Date(this.getField('fecha').getValue());
        let fecha = new Date();
        let fecha1  =   fecha.toISOString();
        let fecha2 = fecha1.split('T')[0];
        
        let fechadesde1  =   fechadesde.toISOString();
        let fechadesde2 = fechadesde1.split('T')[0];

        if (fechadesde2 > fecha2) {
            this.getField('fecha').setError(true, "¡La fecha debe ser menor ó igual a la fecha actual!");
            this.getField('inactivar').setDisabled(true);
            this.getField('activar').setDisabled(true);
            this.errorfecha = true;
        } else if (fecha2 >= fechadesde2) {
            this.getField('fecha').setError(false, "");
            this.getField('inactivar').setDisabled(false);
            this.getField('activar').setDisabled(false);
            if (fecha2 !== '' && this.getField('fecha').getValue().replace(/\u0020/g,'') !== '') {
                let fechaDesdeFormateada = this.getField('fecha').getValue();
                let fechaHastaoFormateada = fecha;
                let fechaRango = new Date(fechaHastaoFormateada) - new Date(fechaDesdeFormateada);
                let diferencia = fechaRango / 1000 / 31 / 60 / 24;

                if (Math.sign(diferencia) === -1) {
                    diferencia = Math.round(parseInt(diferencia.toString().slice(1)));
                }

                if (diferencia > 61 ) {
                    this.getField('fecha').setError(true, "¡La fecha debe ser menor a 31 días!");
                    this.getField('inactivar').setDisabled(true);
                    this.getField('activar').setDisabled(true);
                    this.errorfecha = true;
                }
            }
        }
    }

}

FormJaivana.addController("maes-inactivaractivarterceros", CustomInactivarActivarTerceros);
export default CustomInactivarActivarTerceros;