import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomDesarmarOfertas
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 **/
class CustomDesarmarOfertas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.desarmar                                               = this.desarmar.bind(this);
        this.initConsultar                                          = this.initConsultar.bind(this);
        this.crear_tabla                                            = this.crear_tabla.bind(this);
        this.success_crear_tabla                                    = this.success_crear_tabla.bind(this);
        this.agregar_articulo                                       = this.agregar_articulo.bind(this);
        this.success_agregar_articulo                               = this.success_agregar_articulo.bind(this);
        this.mostrar                                                = this.mostrar.bind(this);
        this.success_mostrar_articulo                               = this.success_mostrar_articulo.bind(this);
        this.gridOptionsComponentes1                                = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1['onSelectionChanged']          = this.onSelectionChanged;
        this.formatNumberSaldo                                      = this.formatNumberSaldo.bind(this);
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.setButtonDelete                                        = this.setButtonDelete.bind(this);
        this.eliminar                                               = this.eliminar.bind(this);
        this.confirmDelete                                          = this.confirmDelete.bind(this);
        this.successEliminar                                        = this.successEliminar.bind(this);
        this.grabar                                                 = this.grabar.bind(this);
        this.success_grabar                                         = this.success_grabar.bind(this);
        this.items                                                  = [];
        this.consultar                                              = this.consultar.bind(this);
        this.success_consultar                                      = this.success_consultar.bind(this);
        this.validarfechasConsulta                                  = this.validarfechasConsulta.bind(this);
        this.limpiar                                                = this.limpiar.bind(this);
        this.traerPropiedades                                       = this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades                                 = this.sucesstraerPropiedades.bind(this);

    }

    initForm() {
        console.log("Formulario CustomDesarmarOfertas,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        if(this.props.data[0].opcion_sub_seccion === 1){
            this.getField('operacion_form').setValue('Desarmar');
            this.desarmar();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.getField('operacion_form').setValue('Consultar');
            this.initConsultar();
        }
    }

    onSelectionChanged(){
        //nothing
    }

    desarmar(){
        this.crear_tabla();

        this.getField('codigo_articulo').setVisible(true);
        this.getField('nombre_articulo').setVisible(true);
        this.getField('bodega').setVisible(true);
        this.getField('nombre_bodega').setVisible(true);
        this.getField('cantidad').setVisible(true);
        this.getField('grabar').setVisible(true);
        this.getField('codigo_articulo1').setVisible(true);
        this.getField('nombre_articulo1').setVisible(true);
        this.getField('costo').setVisible(true);
        this.getField('cantidad1').setVisible(true);
        this.getField('actualizar').setVisible(true);

        this.getField('actualizar').setClick(this.agregar_articulo);
        this.getField('grabar').setClick(this.grabar);

    }

    crear_tabla(){
        let datos={ datos: {
            usuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-desarmarofertas', operacion: 'crear_temporal', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.success_crear_tabla,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    success_crear_tabla(data){
        if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }

    agregar_articulo(){
        if(this.getField('codigo_articulo1').valid() && this.getField('nombre_articulo1').valid() && this.getField('costo').valid() && 
            this.getField('cantidad1').valid()){

            let datos={ datos: {
                usuario:this.codigo_usuario,
                codigo_articulo1:this.getField('codigo_articulo1').getValue(),
                nombre_articulo1:this.getField('nombre_articulo1').getValue(),
                costo:this.getField('costo').getValue(),
                cantidad1:this.getField('cantidad1').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-desarmarofertas', operacion: 'agregar_articulo', operacion_tipo: 'crear' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.success_agregar_articulo,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    success_agregar_articulo(data){
        if (data.estado_p === 200 )
        {
            this.getField('codigo_articulo1').setValue("");
            this.getField('nombre_articulo1').setValue("");
            this.getField('costo').setValue("");
            this.getField('cantidad1').setValue("");
            this.mostrar();
        }else {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    mostrar(){
        let datos={ datos: {
            usuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-desarmarofertas', operacion: 'mostrar', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.success_mostrar_articulo,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    success_mostrar_articulo(data){
        if (data.estado_p === 200 )
        {
            this.getField('rejilla_ofertas').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data;
            this.items = data.data;
            configCell.set('costo_d', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_d) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad_d', { cellRenderer: function (props) { return props.data.cantidad_d}, cellStyle: { textAlign: "right"}, field: 'cantidad' });
            configCell.set('eliminar', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
            this.getField('rejilla_ofertas').initData(this.gridOptionsComponentes1, configCell);
        }else {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    setButtonDelete(props) {
        if(props.data.codigo_d !== "."){
            let codigo_articulo = props.data.codigo_d;
            let button = document.createElement("input");
            button.onclick = () => this.eliminar(codigo_articulo);
            button.setAttribute("codigo_articulo", 'button_delete_' + codigo_articulo);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("type", "button");
            button.setAttribute("value", "eliminar");
            return this.createElementJaivana(button);
        }
    }

    eliminar(codigo_articulo) {
        this.codigo_articulo = codigo_articulo;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDelete);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDelete() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
                codigo_articulo1: this.codigo_articulo,
                usuario:this.codigo_usuario
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-desarmarofertas', operacion: 'eliminar', operacion_tipo: "eliminar" };// parametros del servicio
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv //se envia el generales de arriba
        });
    }

    successEliminar(data) {
        if(data.estado_p === 200){
            this.gridOptionsComponentes1['rowData'] = [];
            this.getField('rejilla_ofertas').initData(this.gridOptionsComponentes1);
            this.getField('rejilla_ofertas').toggle(false);
            this.mostrar();
        }else{
          let keys=Object.keys(data.data.mensaje);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
        }
    }

    grabar(){
        if(this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid() && this.getField('bodega').valid() && 
        this.getField('nombre_bodega').valid() && this.getField('cantidad').valid()){
            if(this.items.length > 0){
                let datos={ datos: {
                    usuario:this.codigo_usuario,
                    codigo_articulo:this.getField('codigo_articulo').getValue(),
                    bodega:this.getField('bodega').getValue(),
                    cantidad:this.getField('cantidad').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'inve-desarmarofertas', operacion: 'grabar', operacion_tipo: 'crear' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.success_grabar,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }else{
                this.alertGeneral.toggle(true, 'Error debe agregar al menos un articulo adicional.', 'error');
            }
        }
    }

    success_grabar(data){
        if (data.estado_p === 200 )
        {
            this.getField('codigo_articulo').setValue("");
            this.getField('nombre_articulo').setValue("");
            this.getField('bodega').setValue("");
            this.getField('nombre_bodega').setValue("");
            this.getField('cantidad').setValue("");
            this.getField('codigo_articulo1').setValue("");
            this.getField('nombre_articulo1').setValue("");
            this.getField('costo').setValue("");
            this.getField('cantidad1').setValue("");
            this.getField('rejilla_ofertas').toggle(false);
        }else {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    //Formulario 2 consultar
    initConsultar(){
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.traerPropiedades();
        this.getField("desde").setValue(this.fecha_inicio);
        this.getField("desde").setOnChange(this.validarfechasConsulta);
        this.getField("hasta").setOnChange(this.validarfechasConsulta);
        this.getField('desde').setVisible(true);
        this.getField('hasta').setVisible(true);
        this.getField('numero').setVisible(true);
        this.getField('consultar').setVisible(true);

        this.getField('consultar').setClick(this.consultar);
    }

    validarfechasConsulta(){
        this.limpiar();
        let fecha_actual =new Date().toISOString().split('T')[0];
        let consultar_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];

        if(consultar_desde > consultar_hasta){
            this.getField('consultar').setDisabled(true);
            this.getField("desde").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }

        if(consultar_hasta > fecha_actual){
            this.getField('consultar').setDisabled(true);
            this.getField("hasta").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }
        
        let diferencia=new Date(consultar_hasta).getTime()-new Date(consultar_desde).getTime();
        diferencia = diferencia / 1000 / 60 / 60 / 24;
        if(diferencia >this.dias_maximo){
            this.getField("desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('consultar').setDisabled(true); 
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("desde").setError(false,"");
        }
        return true;

    }

    traerPropiedades(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'inve-consultarordenproduccion', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
        }
    
    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales=data.data.numero_decimales;
            this.dias_maximo=data.data.dias_devolver;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    limpiar(){
        this.getField('consultar').setDisabled(false);
        this.gridOptionsComponentes1['rowData'] = [];            
        let configCell = new Map();
        this.getField('rejilla').initData(this.gridOptionsComponentes1,configCell);
        this.getField('rejilla').toggle(false)
    }

    consultar(){
        this.getField('numero').setError(false,'');
        this.getField('desde').setError(false,'');
        this.getField('hasta').setError(false,'');
        let errores=0;
        if(this.validarfechasConsulta()===false)
            errores++;
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' '){
            if(!this.getField('numero').valid())
                errores++;
        }
        
        if (errores===0) {
            let datos = { datos: {
                consultar_desde:this.getField("desde").getValue(),
                consultar_hasta:this.getField("hasta").getValue(),
                numero:this.getField("numero").getValue(),
                usuario:this.codigo_usuario
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-desarmarofertas', operacion: 'consultar', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.success_consultar,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage:false
            });
            this.mostrarMensajeGenerando();
            
        }
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    success_consultar(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data;
            this.items = data.data;
            configCell.set('costo_d', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_d) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad_d', { cellRenderer: function (props) { return props.data.cantidad_d}, cellStyle: { textAlign: "right"}, field: 'cantidad' });
            configCell.set('eliminar', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);
        }else {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

}
FormJaivana.addController("inve-desarmarofertas", CustomDesarmarOfertas);
export default CustomDesarmarOfertas;