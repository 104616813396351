import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomColumnasFormatosYConceptos
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomColumnasFormatosYConceptos extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.successguardarColumna = this.successguardarColumna.bind(this);
    this.guardarColumna = this.guardarColumna.bind(this);
    this.successclonarColumna   = this.successclonarColumna.bind(this);
    this.clonarColumna          = this.clonarColumna.bind(this);
    this.eliminarCuenta = this.eliminarCuenta.bind(this);
    this.successEliminarColumna = this.successEliminarColumna.bind(this);
    this.buscarDatos =this.buscarDatos.bind(this);
    this.buscarDatos2 =this.buscarDatos2.bind(this);
    this.succesConsulta  = this.succesConsulta.bind(this);
    this.succesConsulta2  = this.succesConsulta2.bind(this);
	  this.mostrarCampos	=	this.mostrarCampos.bind(this);
    this.ocultarCampos =  this.ocultarCampos.bind(this);
    this.ocultarCamposCancelar  = this.ocultarCamposCancelar.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentes2 = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                             =   this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']         =   this.onSelectionChanged;
    this.gridOptionsComponentes2['onSelectionChanged']         =   this.onSelectionChanged;

    this.setButtonDelete = this.setButtonDelete.bind(this);
    this.confirmEliminarCuenta	=	this.confirmEliminarCuenta.bind(this);
    this.validarUnique	=	this.validarUnique.bind(this);
    this.succesConsultaUnique	=	this.succesConsultaUnique.bind(this);


    this.accionProcesar	=	this.accionProcesar.bind(this);
    this.modificarOrden	=	this.modificarOrden.bind(this);
    this.crearBotonTabla	=	this.crearBotonTabla.bind(this);
    this.setBotones	=	this.setBotones.bind(this);
    this.modificarOrden	=	this.modificarOrden.bind(this);
    this.sucessModificarOrden	=	this.sucessModificarOrden.bind(this);
    this.contiene_registros=false;
    this.validarSeleccionar = this.validarSeleccionar.bind(this);
    this.validarSeleccionar2 = this.validarSeleccionar2.bind(this);
    this.confirmacionCancelar = this.confirmacionCancelar.bind(this);
    this.total_registros      = 0;

    this.openModalClonar      = this.openModalClonar.bind(this);
  }

  initForm() {
    console.log("Formulario CustomColumnasFormatosYConceptos,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez. @update:Jose Albeiro Marin");
    this.getField('seleccionar').setClick(this.buscarDatos);
    this.getField('seleccionar_clonar').setClick(this.buscarDatos2);
	  this.getField('exoformato_id').setOnChange((props)=>{
        if(this.getField('exoformato_id').getValue()!==''){
          this.getField('seleccionar').setDisabled(false);
          this.ocultarCampos();
        }else{
          this.getField('exoformato_id').setDisabled(true);
          this.ocultarCampos();
        }
    });
    this.getField('exoconcepto_id').setOnChange((props)=>{
      if(this.getField('exoconcepto_id').getValue()!==''){
        this.getField('seleccionar').setDisabled(false);
        this.ocultarCampos();
      }else{
        this.getField('exoconcepto_id').setDisabled(true);
        this.ocultarCampos();
      }
  });
    this.getField('agregar').setClick(this.mostrarCampos);
    this.getField('clonar').setClick(this.openModalClonar);
    this.getField('aceptar').setClick(this.validarUnique);
    this.getField('cancelar').setClick(this.confirmacionCancelar);
    this.getField('seleccionar').setDisabled(true);
    this.getField('seleccionar_clonar').setDisabled(true);
    this.ocultarCampos();
    this.getField('nombre_formato').setOnChange(this.validarSeleccionar);
    this.getField('nombre_concepto').setOnChange(this.validarSeleccionar);

    this.getField('nombre_concepto_origen').setOnChange(this.validarSeleccionar2);

    this.getField("aceptar_clonar").setClick(this.clonarColumna);

  }

  openModalClonar(){

    this.getField("codigo_concepto_destino").setValue(this.getField("codigo_concepto").getValue());
    this.getField("codigo_formato_destino").setValue(this.getField("codigo_formato").getValue());

    this.getField("aceptar_clonar").setVisible(false);

    this.getField("modal_clonar").handleClickOpen();
  }

  validarSeleccionar(){
    if(this.getField('nombre_formato').getValue() !=='' && this.getField('nombre_concepto').getValue() !=='' ){
      this.getField('seleccionar').setDisabled(false);
    }else{
      this.getField('seleccionar').setDisabled(true);

    }
  }

  validarSeleccionar2(){
    if(this.getField('nombre_concepto_origen').getValue() !=='' ){
      this.getField('seleccionar_clonar').setDisabled(false);
    }else{
      this.getField('seleccionar_clonar').setDisabled(true);

    }
  }

  onSelectionChanged(){
    let rowDataTable = this.gridOptionsComponentes.api.getSelectedRows();
    this.getField('exogena_formatos_conceptos_columnas_id').setValue(rowDataTable[0].id);
    this.getField('orden').setValue(rowDataTable[0].orden);
}

  mostrarCampos(){
    this.getField('agregar').setDisabled(true);
    this.getField('codigo_columna').setVisible(true);
    this.getField('aceptar').setVisible(true);
    this.getField('cancelar').setVisible(true);
  }
  
  ocultarCampos(){
    this.getField('rejilla').toggle(false);
    this.getField('agregar').setVisible(false);
    this.getField('clonar').setVisible(false);
    this.getField('codigo_columna').setVisible(false);
    this.getField('aceptar').setVisible(false);
    this.getField('cancelar').setVisible(false);
    this.getField('codigo_columna').setValue('');
    this.getField('codigo_columna').setError(false, ''); 
  }

  confirmacionCancelar(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta acción?`)
    this.getField('confirmModalCustom').setClickDialog(() => { 
        this.ocultarCamposCancelar();
        this.getField('confirmModalCustom').toggle(false); 
    });
    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  ocultarCamposCancelar(){
    this.getField('agregar').setVisible(false); 
    this.getField('clonar').setVisible(false);
    if(this.contiene_registros===true){
      this.getField('agregar').setDisabled(false);
      this.getField('agregar').setVisible(true);
      this.getField('clonar').setVisible(true);
      this.getField('codigo_columna').setVisible(false);
      this.getField('aceptar').setVisible(false);
      this.getField('cancelar').setVisible(false);
    }else{
      this.getField('agregar').setVisible(false);
      this.getField('clonar').setVisible(false);
    }
    // this.getField('codigo_columna').setValue('');
    this.getField('codigo_formato').setValue('');
    this.getField('codigo_concepto').setValue('');
    this.getField('nombre_formato').setValue('');
    this.getField('nombre_concepto').setValue('');
    this.ocultarCampos();
  }

  buscarDatos(){
    if(this.getField('codigo_formato').valid()
        && this.getField('nombre_formato').valid()
        && this.getField('codigo_concepto').valid()
        && this.getField('nombre_concepto').valid()){
        this.ocultarCampos();
        let datos={ 
            datos: {
              exoconcepto_id:this.getField('exoconcepto_id').getValue(),  
              exoformato_id:this.getField('exoformato_id').getValue(),  
            }};
        this.generalFormatPmv = { tipo_servicio: 'cont-columnasformatoyconcepto', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesConsulta,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
  }

  succesConsulta(data){
      if (data.estado_p === 200 )
      {
        this.total_registros = data.data.length;
          this.contiene_registros=true;
          this.getField('seleccionar').setDisabled(true);
          this.getField('agregar').setDisabled(false);
          this.getField('agregar').setVisible(true);
          this.getField('clonar').setVisible(true);
          this.getField('rejilla').toggle(true);
          let configCell = new Map();
          configCell.set('accion', { cellRenderer: this.setBotones, width: 200, sortable: false, filter: false, field: 'Acción' });
          configCell.set('columna_descripcion', { headerName: 'Columna descripción'});
          this.gridOptionsComponentes['rowData'] = data.data;
          this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
      }
      else 
      {
		  if (data.estado_p === 404 ){
        this.contiene_registros=false;
			  this.getField('rejilla').toggle(false);
			  this.getField('agregar').setVisible(true);
        this.getField('clonar').setVisible(true);
			  this.getField('agregar').setDisabled(false);
        this.getField('seleccionar').setDisabled(true);
			  //this.mostrarCampos();
		  }else{
			  this.getField('rejilla').toggle(false);
			  let respuesta=Object.values(data.data.errores);
			  let keys=Object.keys(data.data.errores);
			  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error'); 
		  }
      };
  }

  buscarDatos2(){
    if(this.getField('codigo_concepto_origen').getValue() === this.getField('codigo_concepto').getValue()){
      this.getField('codigo_concepto_origen').setError(true,"Este valor debe ser distinto al código concepto destino.");
    }else{
      if(
        this.getField('codigo_concepto_origen').valid()
        && this.getField('nombre_concepto_origen').valid()){
        let datos={ 
            datos: {
              exoconcepto_id:this.getField('exoconcepto_id_origen').getValue(),  
              exoformato_id:this.getField('exoformato_id').getValue(),  
            }};
        this.generalFormatPmv = { tipo_servicio: 'cont-columnasformatoyconcepto', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesConsulta2,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    }
  }

  succesConsulta2(data){
    if (data.estado_p === 200 )
    {
      this.total_registros = data.data.length;
        this.getField('seleccionar_clonar').setDisabled(true);
        this.getField('aceptar_clonar').setVisible(true);
        this.getField('rejilla_clonar').toggle(true);
        let configCell = new Map();
        configCell.set('columna_descripcion', { headerName: 'Columna descripción'});
        this.gridOptionsComponentes2['rowData'] = data.data;
        this.getField('rejilla_clonar').initData(this.gridOptionsComponentes2, configCell);
    }
    else 
    {
    if (data.estado_p === 404 ){
      this.contiene_registros=false;
      this.getField('rejilla_clonar').toggle(false);
      this.getField('aceptar_clonar').setVisible(false);
      this.getField('seleccionar_clonar').setDisabled(true);
    }else{
      this.getField('rejilla_clonar').toggle(false);
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error'); 
    }
    };
}
  
  validarUnique(){
    if(this.getField('codigo_formato').valid()&&
      this.getField('nombre_formato').valid()&&
      this.getField('codigo_concepto').valid() &&
	  this.getField('nombre_concepto').valid() &&
      this.getField('codigo_columna').valid()){
        if(this.getField('exogenacolumna_id').getValue()!=='' && this.getField('exogenacolumna_id').getValue()!==' '){
            let datos={ 
              datos: {
                  exoconcepto_id:this.getField('exoconcepto_id').getValue(),  
                  exoformato_id:this.getField('exoformato_id').getValue(),
                  exogenacolumna_id:this.getField('exogenacolumna_id').getValue()
              }};
            this.generalFormatPmv = { tipo_servicio: 'cont-columnasformatoyconcepto', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesConsultaUnique,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false,
            });
        }else{
            this.getField('codigo_columna').setError(true,'* Es necesario buscar una columna.');
        }
    }
  }

  succesConsultaUnique(data){
      if (data.estado_p === 200 )
      {
        this.alertGeneral.toggle(true, 'Ya éxiste la relación entre la columna,el formato y el concepto.', 'error');
      }
      else 
      {
        if(data.estado_p===404){
          this.guardarColumna();
        }else{
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      };
  }

  guardarColumna(){
      let datos = { datos: {
            exoconcepto_id:this.getField('exoconcepto_id').getValue(),  
            exoformato_id:this.getField('exoformato_id').getValue(),
            exogenacolumna_id:this.getField('exogenacolumna_id').getValue(),
            orden:0 
      }};
      
      this.generalFormatPmv = { tipo_servicio: 'cont-columnasformatoyconcepto', operacion: '5_1', operacion_tipo: 'crear' };
      this.datos_anteriores=this.generalFormatPmv;
      this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successguardarColumna,
                error: this.error_,
                general: this.generalFormatPmv
        });
  }

  successguardarColumna(data) 
  {

    if (data.estado_p === 200)
    {
        this.ocultarCampos();
        this.buscarDatos();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  clonarColumna(){
    let datos = { datos: {
          exoconcepto_id_origen:this.getField('exoconcepto_id_origen').getValue(), 
          exoconcepto_id:this.getField('exoconcepto_id').getValue(),  
          exoformato_id:this.getField('exoformato_id').getValue(),
          orden:0 
    }};
    
    this.generalFormatPmv = { tipo_servicio: 'cont-columnasformatoyconcepto', operacion: '5_2', operacion_tipo: 'crear' };
    this.datos_anteriores=this.generalFormatPmv;
    this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.successclonarColumna,
              error: this.error_,
              general: this.generalFormatPmv
      });
}

successclonarColumna(data) 
{

  if (data.estado_p === 200)
  {
    this.getField("modal_clonar").handleClose();
    this.buscarDatos();
  }
  else 
  {
    let respuesta=Object.values(data.data.errores);
    let keys=Object.keys(data.data.errores);
    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
  }
}

  setBotones(props) {
    let fragment = document.createDocumentFragment();
    let buttonEliminar = this.crearBotonTabla(props.data, 'Eliminar');
    let buttonSubir = this.crearBotonTabla(props.data, 'Bajar orden');
    let buttonBjar = this.crearBotonTabla(props.data, 'Subir orden');
    buttonEliminar.setAttribute("class","buttonStyle2");
    buttonSubir.setAttribute("class","buttonStyle2");
    buttonBjar.setAttribute("class","buttonStyle2");

    if(props.data.orden === 1){
      buttonSubir.setAttribute('disabled',true);
      buttonSubir.setAttribute('class','buttonDisabled');
    }

    if(props.data.orden === this.total_registros){
      buttonBjar.setAttribute('disabled',true);
      buttonBjar.setAttribute('class','buttonDisabled');
    }

    fragment.appendChild(buttonEliminar);
    fragment.appendChild(buttonSubir);
    fragment.appendChild(buttonBjar);
    return this.createElementJaivana(fragment);
}

  crearBotonTabla(data, boton) {
    let button = document.createElement('input');
    switch (boton) {
        case 'Eliminar':
            button.setAttribute("id", `buttonEliminar_${data.id}`);
            break;

        case 'Subir orden':
            button.setAttribute("id", `buttonSubir_${data.id}`);
            break;
        case 'Bajar orden':
          button.setAttribute("id", `buttonBajar_${data.id}`);
          break;

        default:
            break;
    }
    button.onclick = () => { this.accionProcesar(data, boton) };
    button.setAttribute("value", boton);
    button.setAttribute("type", "button");
    return button;
}

  accionProcesar(data, boton) {
    this.getField('exogena_formatos_conceptos_columnas_id').setValue(data.id);
    if (boton === 'Eliminar') {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(() => { this.eliminarCuenta(data.id) });
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
        this.getField('confirmModalCustom').toggle(true);
    } else if (boton === 'Subir orden') {
        this.modificarOrden(data.orden+1, "subir");
    } else if (boton === 'Bajar orden') {
      if(data.orden<=0)
        this.alertGeneral.toggle(true, 'No es posible bajar el orden.', 'error');
      else
        this.modificarOrden(data.orden-1, "bajar");
    } else {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }
  }
  setButtonDelete(props) {
      let id = props.data.bancos_cuentas_terceros_id;
      let button = document.createElement("input");
      button.onclick = () => this.confirmEliminarCuenta(id);
      button.setAttribute("id", 'button_delete_' + id);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("type", "button");
      button.setAttribute("value", "eliminar");
      return this.createElementJaivana(button);
  }

  confirmEliminarCuenta(id) {
    this.ids = id;
    this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
    this.getField('confirmModalCustom').setClickDialog(this.eliminarCuenta);
    this.getField('confirmModalCustom').toggle(true);
  }
  
  eliminarCuenta(){
    this.getField('confirmModalCustom').toggle(false);
    if(this.getField('exogena_formatos_conceptos_columnas_id').getValue()!=='' &&
		  this.getField('exogena_formatos_conceptos_columnas_id').getValue()!==' '){
        let datos = { datos: {
          exogena_formatos_conceptos_columnas_id: parseInt(this.getField('exogena_formatos_conceptos_columnas_id').getValue())
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'cont-columnasformatoyconcepto', operacion: '7_1', operacion_tipo: 'eliminar' };
        
        
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminarColumna,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }else{
      this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
    }
  }

  modificarOrden(orden, opcion){
    let datos = { datos: {
          exogena_formatos_conceptos_columnas_id: parseInt(this.getField('exogena_formatos_conceptos_columnas_id').getValue()),
          orden:orden,
          opcion:opcion
    }};
    
    this.generalFormatPmv = { tipo_servicio: 'cont-columnasformatoyconcepto', operacion: '6_1', operacion_tipo: 'modificar' };
    this.datos_anteriores=this.generalFormatPmv;
    this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'PUT',
              body: datos,
              success: this.sucessModificarOrden,
              error: this.error_,
              general: this.generalFormatPmv
      });
}

sucessModificarOrden(data) 
{
  if (data.estado_p === 200)
  {
      this.ocultarCampos();
      this.buscarDatos();
  }
  else 
  {
    let respuesta=Object.values(data.data.errores);
    let keys=Object.keys(data.data.errores);
    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
  }
}
  successEliminarColumna(data) 
  {
   
    if (data.estado_p === 200)
    {
        this.buscarDatos();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

}
FormJaivana.addController("cont-columnasformatoyconcepto", CustomColumnasFormatosYConceptos);
export default CustomColumnasFormatosYConceptos;