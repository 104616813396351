import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomActivarProductos
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomActivarProductos extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.successactivarArticulo = this.successactivarArticulo.bind(this);
    this.activarArticulo = this.activarArticulo.bind(this);
    this.registrarLog = this.registrarLog.bind(this);
    this.successRegistrarLog = this.successRegistrarLog.bind(this);
    this.activarCampos  = this.activarCampos.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.datos_anteriores='';
    this.datos_nuevos='';
    this.user_id='';
    this.codigo_sucursal='';
    
  }

  initForm() {
    console.log("Formulario CustomActivarProductos,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez, @updateBy Jose Albeiro Marin Bernal");
    this.user_id = JSON.parse(localStorage.getItem('res')).id;
    this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.getField('seleccionar').setClick(this.activarCampos);
    this.getField('cancelar').setClick((props)=>{
      this.getField('codigo_articulo').setValue('');
      this.getField('nombre_articulo').setValue('');
      this.getField('articulo_id').setValue('');
    });
    this.getField('detalle').setDisabled(true);

    this.getField('aceptar').setClick(this.activarArticulo);
    this.getField('seleccionar').setDisabled(true);
    this.getField('aceptar').setDisabled(true);
    this.getField('cancelar').setDisabled(true);
    this.getField('articulo_id').setOnChange((props) => { 
                      if(this.getField('articulo_id').getValue()==='' || this.getField('articulo_id').getValue()===' '){
                        this.limpiarCampos();
                        this.getField('seleccionar').setDisabled(true);
                      }else{
                        this.getField('seleccionar').setDisabled(false);

                      }});

    this.getField('detalle').setVisible(false);
    this.getField('aceptar').setVisible(false);
    this.getField('cancelar').setVisible(false);
  }

  limpiarCampos(){
      this.getField('detalle').setValue('');
      this.getField('detalle').setDisabled(true);
      this.getField('aceptar').setDisabled(true);
      this.getField('cancelar').setDisabled(true);
      this.getField('detalle').setError(false, "");
      this.getField('codigo_articulo').setDisabled(false);
      this.getField('seleccionar').setDisabled(false);
      this.getField('detalle').setVisible(false);
      this.getField('aceptar').setVisible(false);
      this.getField('cancelar').setVisible(false);
    
  }

  activarCampos(){
    this.getField('detalle').setVisible(true);
    this.getField('aceptar').setVisible(true);
    this.getField('cancelar').setVisible(true);
    this.getField('detalle').setDisabled(false);
    this.getField('aceptar').setDisabled(false);
    this.getField('cancelar').setDisabled(false);
    this.getField('seleccionar').setDisabled(true);
    this.getField('codigo_articulo').setDisabled(true);
    this.getField('nombre_articulo').setDisabled(true);

  }

  activarArticulo(){
    if(this.getField('codigo_articulo').valid()&&
      this.getField('nombre_articulo').valid()&&
      this.getField('articulo_id').valid() &&
      this.getField('detalle').valid()){
        let datos = { datos: {
          registro_id:this.getField('articulo_id').getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-activarproductos', operacion: '6', operacion_tipo: 'modificar' };
        this.datos_anteriores=this.generalFormatPmv;
        this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.successactivarArticulo,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }
  }

  successactivarArticulo(data) 
  {

    if (data.estado_p === 200)
    {
        this.datos_nuevos=this.getField('detalle').getValue();//detalle va al campo datos_nuevo
        this.registrarLog();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  registrarLog(){
    if(this.datos_anteriores!=='' &&
      this.datos_nuevos!==''){
        let datos = { datos: {
          nombre_tabla:'articulo',
          operacion:'ACTIVAR',
          registro_id:this.getField('articulo_id').getValue(),
          datos_anteriores:JSON.stringify(this.datos_anteriores),
          datos_nuevos:this.datos_nuevos,
          fecha_modificacion:'now()',
          usuario_id:this.user_id
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-inactivarproductos', operacion: '5', operacion_tipo: 'crear' };
        
        
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successRegistrarLog,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }else{
      this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
    }
  }

  successRegistrarLog(data) 
  {
   
    if (data.estado_p === 200)
    {
        this.getField('codigo_articulo').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('articulo_id').setValue('');
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

}
FormJaivana.addController("maes-activarproductos", CustomActivarProductos);
export default CustomActivarProductos;