/**
 * @description Custom con funciones adicionales al formulario con código: cont-certificadoaportes, ID Formulario: 137, Sección: padre: Contabilidad(id: 5)- hijo: Certificados(id: 29)
 * @author Sebastian Rios Echeverri
 * @version jdesk_1.01.0001
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomCertificadosAportes extends FormJaivana.form {


    constructor(props) {

        super(props);
        // Variables de tablas:
        this.gridOptionsCuentas                                     =   Object.assign({},this.gridOptions);
        this.gridOptionsComponentesSoportes                         =   Object.assign({},this.gridOptions);
        this.gridOptionsComponentesSoportes['onSelectionChanged']   =   this.onSelectionChanged;
        this.onSelectionChanged                                     =   this.onSelectionChanged.bind(this);
       
        // Variables Globales:
        this.arrayObjetos                                           =   new Map();
        this.arrayCampos                                            =   new Map();
        this.valor                                                  =   "";
        this.operacion_actual                                       =   "";
       
        // Funciones:
        this.initForm                                               =   this.initForm.bind(this);
        this.cargarCuentas                                          =   this.cargarCuentas.bind(this);
        this.successCargarCuentas                                   =   this.successCargarCuentas.bind(this);
        this.formatearFechas                                        =   this.formatearFechas.bind(this);
        this.validaRangoFechas                                      =   this.validaRangoFechas.bind(this);
        this.habilitarCorreo                                        =   this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                                     =   this.deshabilitarCorreo.bind(this);
        this.generarPdf                                             =   this.generarPdf.bind(this);
        this.isJson                                                 =   this.isJson.bind(this);
        this.enviarCorreo                                           =   this.enviarCorreo.bind(this);
        this.successEnviarCorreo                                    =   this.successEnviarCorreo.bind(this);
        this.mostrarModal                                           =   this.mostrarModal.bind(this);
        this.successMostrarModal                                    =   this.successMostrarModal.bind(this);
        this.validarCampos                                          =   this.validarCampos.bind(this);        
        this.limpiarCampos                                          =   this.limpiarCampos.bind(this);        
    }

    initForm() {
        console.log('Formulario Aportes,  @version: jdesk_1.01.0001, @author: Sebastian Rios Echeverri');

        /* this.arrayCampos.set(1, '.');
        this.arrayCampos.set(2, 'nit');
        this.arrayCampos.set(3, 'sede');
        this.arrayCampos.set(4, 'nombre');
        this.arrayCampos.set(5, 'desde');
        this.arrayCampos.set(6, 'hasta');
        this.arrayCampos.set(7, 'btn_procesar');
        this.arrayCampos.set(8, 'tb_cuentas');


        this.arrayObjetos.set(1, '.');
        this.arrayObjetos.set(2, 'nit');
        this.arrayObjetos.set(3, 'sede');
        this.arrayObjetos.set(4, 'nombre');
        this.arrayObjetos.set(5, 'desde');
        this.arrayObjetos.set(6, 'hasta');
        this.arrayObjetos.set(7, 'btn_procesar');
        this.arrayObjetos.set(8, 'tb_cuentas'); */


        // Acciones dentro de initForm
        this.cargarCuentas();
        this.formatearFechas();
        this.getField('btn_procesar').setClick(this.validarCampos);
        this.getField('desde').setOnChange(this.validaRangoFechas);
        this.getField('hasta').setOnChange(this.validaRangoFechas);

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });
        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch(false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.getField('radio_correo').setDisabled(false);
            }
        });

        this.getField('radio_correo').setValue("N");
    }


    //*** definir los botones y los success
    onSelectionChanged(){}

    limpiarCampos(){
        this.getField('nit').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre').setValue('');
        this.formatearFechas();
        this.getField('ch_pantalla').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('radio_correo').setValue('');
        this.getField('email').setValue('');

        this.getField('nit').setError(false,'');
        this.getField('sede').setError(false,'');
        this.getField('nombre').setError(false,'');
        this.getField('ch_pantalla').setError(false,'');
        this.getField('ch_pdf').setError(false,'');
        this.getField('radio_correo').setError(false,'');
        this.getField('email').setError(false,'');
    }
    
    validarCampos(){
        if (this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid() &&
        this.validaRangoFechas() && this.valor !== '') {
            if(this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
                this.operacion_actual='';
                this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: PDF o Pantalla.', 'error');
            }else if(this.operacion_actual === 'pantalla'){
                this.mostrarModal();
            }else if(this.operacion_actual === 'pdf'){
                if (this.getField('email').valid()) {
                    if (this.getField('radio_correo').getValue() === 'S') {
                        if (this.getField('email').getValue() !== '') {
                            this.enviarCorreo();
                        }else{
                            this.getField('email').setError(true, '* Este campo es requerido.');
                        }
                    }else{
                        this.generarPdf();
                    }
                }
            }
        }
    }
    
    mostrarModal(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se esta generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        const MESES = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
        ];
        const date = new Date();
            
        let fechaDevolver = '';
        fechaDevolver = MESES[date.getMonth()]; // Devuelve el mes actual en formato de texto
        fechaDevolver += " "+date.getFullYear();
        // console.log('fechaDevolver:\n',fechaDevolver);

        let datosAgregar = { datos: {} }; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limpiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        datosAgregar.datos['sucursal_ingreso'] = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        datosAgregar.datos['nombre'] = this.getField('nombre').getValue();
        datosAgregar.datos['desde'] = this.getField('desde').getValue();
        datosAgregar.datos['hasta'] = this.getField('hasta').getValue();
        datosAgregar.datos['nit'] = this.getField('nit').getValue();
        datosAgregar.datos['sede'] = this.getField('sede').getValue();
        datosAgregar.datos['fecha_firma'] = fechaDevolver;
        datosAgregar.datos['valor'] = this.valor;
        datosAgregar.datos['radio_correo'] = this.getField('radio_correo').getValue();
        datosAgregar.datos['email'] = this.getField('email').getValue();
        datosAgregar.datos['operacion'] = this.operacion_actual;
        this.generalFormatPmv = { tipo_servicio: 'cont-certificadoaportes', operacion: 'procesar', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datosAgregar,
                success: this.successMostrarModal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successMostrarModal(data){
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200){
        this.getField('tabla_soportes').toggle(false);
        this.getField('modal_soportes').handleClickOpen();
        this.gridOptionsComponentesSoportes['rowData'] = [];
        let datosFicha = [];
        let data2 =
                {
                    "empresa-nit": data.data.lista.empresanit,
                    "datos": data.data.lista.datos,
                    "titulo": data.data.lista.titulo,
                    "periodo": data.data.lista.periodo,
                };
        datosFicha.push(data2);
        this.getField('lista_titulos').setItemsFichas(datosFicha);
        let configCell = new Map();
        this.getField('tabla_soportes').toggle(true);
        this.gridOptionsComponentesSoportes['rowData'] = data.data.datos;
        this.getField('tabla_soportes').initData(this.gridOptionsComponentesSoportes,configCell);
        this.limpiarCampos();
    } else {
        this.getField('tabla_soportes').toggle(false);
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        } else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    }
    
    enviarCorreo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se esta generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        // console.log("Entramos sebastian | procesar");
        const MESES = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
        ];
        const date = new Date();
            
        let fechaDevolver = '';
        fechaDevolver = MESES[date.getMonth()]; // Devuelve el mes actual en formato de texto
        fechaDevolver += " "+date.getFullYear();
        // console.log('fechaDevolver:\n',fechaDevolver);

        let datosAgregar = { datos: {} }; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limpiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        datosAgregar.datos['sucursal_ingreso'] = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        datosAgregar.datos['nombre'] = this.getField('nombre').getValue();
        datosAgregar.datos['desde'] = this.getField('desde').getValue();
        datosAgregar.datos['hasta'] = this.getField('hasta').getValue();
        datosAgregar.datos['nit'] = this.getField('nit').getValue();
        datosAgregar.datos['sede'] = this.getField('sede').getValue();
        datosAgregar.datos['fecha_firma'] = fechaDevolver;
        datosAgregar.datos['valor'] = this.valor;
        datosAgregar.datos['radio_correo'] = this.getField('radio_correo').getValue();
        datosAgregar.datos['email'] = this.getField('email').getValue();
        datosAgregar.datos['operacion'] = this.operacion_actual;
        this.generalFormatPmv = { tipo_servicio: 'cont-certificadoaportes', operacion: 'procesar', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datosAgregar,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    
    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.alertGeneral.toggle(true, 'Email enviado exitosamente..', 'success');
            this.limpiarCampos();
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    
    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }
    
    validaRangoFechas(){
        if (this.getField('desde').getValue() !== null && this.getField('hasta').getValue() !== null) {
            let fechaDesde = new Date(this.getField('desde').getValue());
            let fechaHasta = new Date(this.getField('hasta').getValue());
            let fechaDesdeFormateada = fechaDesde.toISOString().split('T')[0];
            let fechaHastaFormateada = fechaHasta.toISOString().split('T')[0];
            if (new Date(fechaHastaFormateada) >= new Date(fechaDesdeFormateada)) {
                // console.log("Entramos sebastian | validaRangoFechas");
                this.getField('hasta').setError(false, '');
                return true;
            }else{
                this.getField('hasta').setError(true, 'Fecha hasta debe ser mayor o igual que fecha desde');
                return false;
            }
        }
    }
    
    formatearFechas(){
        // Traer el último día del mes:
        let fechaHasta = new Date();
        let lastDayOfMonth = new Date(fechaHasta.getFullYear(), fechaHasta.getMonth()+1, 0);// La documentación la saqué de esta fuente: https://www.it-swarm-es.com/es/javascript/calcular-el-ultimo-dia-del-mes-en-javascript/958432457/#:~:text=var%20today%20%3D%20new%20Date(),getFullYear()%2C%20today.
        let campoFechaHasta = lastDayOfMonth.toISOString();
        this.getField('hasta').setValue(campoFechaHasta.split('T')[0]);
        this.getField('hasta').setError(false, '');
        
        // Traer el primer día del mes:
        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-1";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        //console.log('Fecha ms', campoFecha.split('T')[0]);
        this.getField('desde').setValue(campoFecha.split('T')[0]);
        this.getField('desde').setError(false, '');
    }
    
    generarPdf(){
        let url = this.constant.formConfiguration();
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Por favor espere. Se está generando el reporte en formato PDF`);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        // console.log("Entramos sebastian | procesar");
        const MESES = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio",
            "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre",
        ];
        const date = new Date();
            
        let fechaDevolver = '';
        fechaDevolver = MESES[date.getMonth()]; // Devuelve el mes actual en formato de texto
        fechaDevolver += " "+date.getFullYear();
        // console.log('fechaDevolver:\n',fechaDevolver);
        
        let datosAgregar = { datos: {} }; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limpiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        datosAgregar.datos['sucursal_ingreso'] = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        datosAgregar.datos['nombre'] = this.getField('nombre').getValue();
        datosAgregar.datos['desde'] = this.getField('desde').getValue();
        datosAgregar.datos['hasta'] = this.getField('hasta').getValue();
        datosAgregar.datos['nit'] = this.getField('nit').getValue();
        datosAgregar.datos['sede'] = this.getField('sede').getValue();
        datosAgregar.datos['fecha_firma'] = fechaDevolver;
        datosAgregar.datos['valor'] = this.valor;
        datosAgregar.datos['radio_correo'] = this.getField('radio_correo').getValue();
        datosAgregar.datos['email'] = this.getField('email').getValue();
        datosAgregar.datos['operacion'] = this.operacion_actual;
        const myJSON = JSON.stringify(datosAgregar.datos);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-certificadoaportes","operacion":"procesar","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => 
            response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                        this.limpiarCampos();
                    } 
                });
            }else{
                this.alertGeneral.toggle(true,'No hay datos','error');
                this.getField('confirmModalCustom').toggle(false);
            }
        }).catch(err => this.alertGeneral.toggle(true,err,'error'));
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    
    cargarCuentas(){
        let datosAgregar = { datos: {} }; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limpiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        datosAgregar.datos['sucursal_ingreso'] = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.generalFormatPmv = { tipo_servicio: 'cont-certificadoaportes', operacion: 'cargarcuentas', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datosAgregar,
                success: this.successCargarCuentas,
                error: this.error_,
                general: this.generalFormatPmv
                // showMessage: false
            }
        );
    }

    successCargarCuentas(data) {
        // console.log('Data successCargarCuentas:\n',data);
        if (data.estado_p === 200) {
            this.valor = data.data.valor;
            // console.log('this.valor:\n',this.valor);
            this.gridOptionsCuentas["rowData"] = data.data.data;
            this.getField('tb_cuentas').toggle(true);
            this.getField('tb_cuentas').initData(this.gridOptionsCuentas);
        }else{
            this.gridOptionsCuentas["rowData"] = [];
            this.getField('tb_cuentas').initData(this.gridOptionsCuentas);
            this.getField('tb_cuentas').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `LA SUCURSAL: ${JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal}-${JSON.parse(localStorage.getItem('sucursal_ingreso')).nombre}, no está configurada.`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.getField('btn_procesar').setDisabled(true); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    //*** fin  los botones y los success

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }
}
FormJaivana.addController('cont-certificadoaportes', CustomCertificadosAportes);
export default CustomCertificadosAportes;