import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAutorizacionModificacionDoc
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0001.1
 **/
class CustomAutorizacionModificacionDoc extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.gridOptionsComponentes         = Object.assign({},this.gridOptions);
        this.opcionOrigen                   = this.opcionOrigen.bind(this);
        this.traerDatos                     = this.traerDatos.bind(this);
        this.successgenerarModal            = this.successgenerarModal.bind(this); 
        this.setButtonDeleteOperaciones     = this.setButtonDeleteOperaciones.bind(this);
        this.eliminarOperaciones            = this.eliminarOperaciones.bind(this);
        this.confirmDeleteOperacionesCustom = this.confirmDeleteOperacionesCustom.bind(this);
        this.successDeleteoperaciones       = this.successDeleteoperaciones.bind(this);
        this.validar                        = this.validar.bind(this);
        this.successValidar                 = this.successValidar.bind(this);
        this.crear                          = this.crear.bind(this);
        this.successCrear                   = this.successCrear.bind(this);
    }

    initForm() {
        console.log("Formulario CustomAutorizacionModificacionDoc,  @version: jdesk_1.01.0001.01, @author: Patricia Lopez Sanchez");
        
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionOrigen();
        }
    
    }

    opcionOrigen(){
        this.traerDatos();
        this.getField("agregar").setClick(this.validar);
        this.getField("operacion").setValue('AUTORIZAR POR ORIGEN');
    }

    traerDatos(){
        let datos = {datos: {}};    
        this.generalFormatPmv = { tipo_servicio: 'cont-modificaciondocumentos', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successgenerarModal,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });   
    }
            
    successgenerarModal(data){
        if (data.estado_p === 200 )
        {
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            configCell.set('eliminar', { cellRenderer: this.setButtonDeleteOperaciones, sortable: false, filter: false, field: 'eliminar' });
            this.getField('rejilla').toggle(true);
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
        }
        else 
        {
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    setButtonDeleteOperaciones(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.eliminarOperaciones(id);
        button.setAttribute("id", 'button_Add_' + id);
        div.setAttribute('id', 'div_Add_' + id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    eliminarOperaciones(id) {
        this.id_delete = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDeleteOperacionesCustom);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDeleteOperacionesCustom() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = { datos: {
            id: this.id_delete
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-modificaciondocumentos', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
              endpoint: this.constant.formConfiguration(),
              method:'DELETE',
              body: datos,
              success: this.successDeleteoperaciones,
              error: this.error_,
              general: this.generalFormatPmv
        });
    }

    successDeleteoperaciones(data) {
        if (data.estado_p === 200) {
            this.getField("codigo_origen").setValue('');
            this.getField("origen").setValue('');
            this.getField("nombre_campo").setValue('');
            this.getField("nombre").setValue('');

            this.traerDatos(data);

            this.getField("codigo_origen").setError(false,'');
            this.getField("origen").setError(false,'');
            this.getField("nombre_campo").setError(false,'');
            this.getField("nombre").setError(false,'');
        }else{
            this.getField('rejilla').toggle(false);
        }
    } 

    validar(){
        if(this.getField("codigo_origen").valid() && this.getField("origen").valid() && this.getField("nombre_campo").valid() 
        && this.getField("nombre").valid() ){
            let datos = {datos: {
                codigo_origen: this.getField("codigo_origen").getValue(),
                nombre_campo: this.getField("nombre_campo").getValue()
            }};    
    
            this.generalFormatPmv = { tipo_servicio: 'cont-modificaciondocumentos', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }); 
       }
    }

    successValidar(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Error ya existe un registro con los datos que intenta agregar.', 'error');
        }
        else 
        {
            if(data.estado_p===404) {
                this.crear();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    crear(){
        let datos = {datos: {
            codigo_origen: this.getField("codigo_origen").getValue(),
            nombre_campo: this.getField("nombre_campo").getValue()
        }};    

        this.generalFormatPmv = { tipo_servicio: 'cont-modificaciondocumentos', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successCrear,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        }); 
    }

    successCrear(data){
        if (data.estado_p === 200 )
        {
            this.getField("codigo_origen").setValue('');
            this.getField("origen").setValue('');
            this.getField("nombre_campo").setValue('');
            this.getField("nombre").setValue('');

            
            this.getField("codigo_origen").setError(false,'');
            this.getField("origen").setError(false,'');
            this.getField("nombre_campo").setError(false,'');
            this.getField("nombre").setError(false,'');
            this.traerDatos(data);
        }
        else 
        {
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

   
}
FormJaivana.addController("cont-modificaciondocumentos", CustomAutorizacionModificacionDoc);
export default CustomAutorizacionModificacionDoc;