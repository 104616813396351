import FormJaivana from "dashboard_jaivana_v1";
/**
 *
 * @author Patricia Lopez Sanchez
 */
/**
 * Custom  CustomAnexoDetalladoTercerosRangos
 **/
class CustomAnexoDetalladoTercerosRangos extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                          = this.initForm.bind(this);
        this.validarsucursales                 = this.validarsucursales.bind(this);
        this.validarfechas                     = this.validarfechas.bind(this); 
        this.formatovista                      = this.formatovista.bind(this); 
        this.validarCampos                     = this.validarCampos.bind(this); 
        this.procesar                          = this.procesar.bind(this); 
        this.succesMostrarDatos                = this.succesMostrarDatos.bind(this);
        this.gridOptionsComponentes            = Object.assign({}, this.gridOptions);
        this.currencyFormattersaldoanterior    = this.currencyFormattersaldoanterior.bind(this);
        this.currencyFormatterdebito           = this.currencyFormatterdebito.bind(this);
        this.currencyFormattercredito          = this.currencyFormattercredito.bind(this);
        this.currencyFormattersaldofinal       = this.currencyFormattersaldofinal.bind(this);
        this.formatNumberSaldo                 = this.formatNumberSaldo.bind(this);
        this.successgenerarModal               = this.successgenerarModal.bind(this);
        this.generarExcelTabla                 = this.generarExcelTabla.bind(this);
        this.limpiarCampos                     = this.limpiarCampos.bind(this);
        this.deshabilitarCorreo                = this.deshabilitarCorreo.bind(this);
        this.enviarCorreo                      = this.enviarCorreo.bind(this);
        this.successEnviarCorreo               = this.successEnviarCorreo.bind(this);
        this.currencyFormattersucursaldetallada = this.currencyFormattersucursaldetallada.bind(this);
    }

    initForm() {
        console.log("Formulario CustomAnexoDetalladoTercerosRangos,  @version: jdesk_1.01.0001, @author:Patricia Lopez Sanchez");
        this.opcion="ANEXO AL BALANCE DETALLADO POR TERCEROS RANGOS";
        this.getField('operacion').setValue(this.opcion);
        this.getField('ch_sucursales').setValue(true);
        this.getField("fecha_desde").setOnChange(this.validarfechas);
        this.getField('sucursales').setOnChange(this.validarsucursales);
        this.getField('radio_correo').setValue("N");
        this.getField('labelCorreo').setVisible(true);
        this.formatovista();
        this.getField('consultar').setClick(this.validarCampos);
        this.getField('cancelar').setClick(this.limpiarCampos);
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;  
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
    }

    validarsucursales(){
        this.getField('sucursales').setKeyUp((props)=>{
            this.getField('sucursales').setError(false,'');
            if(this.getField('sucursales').getValue()!=='' && this.getField('sucursales').getValue()!==' '){
              this.getField('ch_sucursales').setDisabled(true);
              this.getField('ch_sucursales').setValueSwitch(false);
              this.getField('sucursales').valid();
            }else{
              this.getField('ch_sucursales').setDisabled(false);
              this.getField('ch_sucursales').setValueSwitch(true);
            }
        });
    }

    validarfechas(){
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("fecha_desde").getValue());

        this.getField("fecha_desde").setError(false,"");

        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }
        return true;

    }

    formatovista(){
        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
              this.getField('ch_pdf').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='excel';
              this.getField('radio_correo').setDisabled(false);
            }
          });
          this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.getField('radio_correo').setDisabled(false);
            }
          });
          this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pdf').setValueSwitch (false);
              this.operacion_actual='pantalla';
            }else{
              this.getField('radio_correo').setDisabled(false);
            }
          });
  
          this.getField('radio_correo').setOnChange(()=>{
            if(this.getField("radio_correo").getValue()==='S'){
              this.getField('email').setDisabled(false);
              this.getField('labelCorreo').setVisible(false);
            }else{
              this.getField('email').setValue('');
              this.getField('email').setError(false,'');
              this.getField('labelCorreo').setVisible(true);
            }
          })
    }
  
    deshabilitarCorreo(){
      this.getField('radio_correo').setValue("N");
      this.getField('radio_correo').setDisabled(true);
    }

    validarCampos(){
        let errores =0;
        
        let valFechas=this.validarfechas();
        if(valFechas===false){
          errores++;
        };
    
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }

        if(this.getField('sucursales').getValue()===''){
            this.getField('ch_sucursales').setValueSwitch(true);
        }

        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false){
              errores++;
            }
        }

        if(this.getField('email').valid()===false){
          errores++;
        }

        if(this.getField('cuenta_inicial').valid() && this.getField('cuenta_final').valid()){
            if(this.getField('nombre_cuentaf').getValue()!=='' && this.getField('nombre_cuentaf').getValue()!==' ' ){
                this.getField('cuenta_final').setError(false,'');
            }else{
              errores++;
              this.getField('cuenta_final').setError(true,'* Es necesario realizar la busqueda.');
            }
            if(this.getField('nombre_cuentai').getValue()!=='' && this.getField('nombre_cuentai').getValue()!==' ' ){
              this.getField('cuenta_inicial').setError(false,'');
            }else{
              errores++;
              this.getField('cuenta_inicial').setError(true,'* Es necesario realizar la busqueda.');
            }
        }else{
          errores++;
        }

        if(errores===0){
          this.procesar();
        }
    }

    procesar(){
       if(this.getField('radio_correo').getValue()==='S'){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información a enviar por correo... `);
        }else{
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        }
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos
        if(this.getField('sucursales').getValue()===''){
            datos={ datos: {
                sucursal_ingreso:this.codigo_sucursal,
                fecha_desde: this.getField('fecha_desde').getValue(),
                cierre: this.getField('ch_cierre').getValue(),
                niif:this.getField('ch_niif').getValue()
              }};
        }else{
            datos={ datos: {
                sucursal_ingreso:this.codigo_sucursal,
                fecha_desde: this.getField('fecha_desde').getValue(),
                sucursales: this.getField('sucursales').getValue(),
                cierre: this.getField('ch_cierre').getValue(),
                niif:this.getField('ch_niif').getValue()
              }};
        }
        
        this.generalFormatPmv = { tipo_servicio: 'cont-anexodetatercerorangos', operacion: 'xdetalladotercero', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesMostrarDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

            
    succesMostrarDatos(data){
      if (data.estado_p === 200 )
      {
        if(this.operacion_actual!==''){
            if(this.getField('radio_correo').getValue()==='S'){
              this.enviarCorreo();
            }else{
              if(this.operacion_actual==='pdf'){
                  this.generarPdfTabla();
              }else if(this.operacion_actual==='pantalla'){
                this.generarModal();
              }else if(this.operacion_actual==='excel'){
                  this.generarExcelTabla();
              } 
            }
          }else{
            this.getField('confirmModalCustom').toggle(false);
            this.getField('consultar').setDisabled(false);
            this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
          }
      } else {
        if(data.estado_p===404) {
          this.getField('consultar').setDisabled(false);
          this.alertGeneral.toggle(true, 'No se encontro.', 'error');
        } else {
          this.getField('confirmModalCustom').toggle(false);
          let respuesta=data.data.mensaje;
          this.alertGeneral.toggle(true, respuesta, 'error');
          this.getField('consultar').setDisabled(false);
          this.limpiarCampos();
          }
      }
    }

    generarModal(){
          let sucursales="";
          if(this.getField('sucursales').getValue()===''){
            sucursales="TODAS"
          }else{
            sucursales=this.getField('sucursales').getValue();
          }

          let datos = { datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            operacion:this.operacion_actual,
            codigo_sucursal:sucursales,
            email:this.getField('email').getValue(),
            cuenta_inicial:this.getField('cuenta_inicial').getValue(),
            cuenta_final:this.getField('cuenta_final').getValue()
          }};
          this.generalFormatPmv = { tipo_servicio: 'cont-anexodetatercerorangos', operacion: 'xmostrar', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successgenerarModal,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
    }

    successgenerarModal(data){
      this.getField('confirmModalCustom').toggle(false);
     if (data.estado_p === 200 )
      {  
        if(data.data.mensaje === 'Email enviado exitosamente...'){
          this.getField('confirmModalCustom').setTitleAndContent('¡Informativo!', '  No se muestra en pantalla porque el archivo supera su límite de tamaño, la información será enviada en PDF al correo: '+ this.getField('email').getValue());
          this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
          this.getField("confirmModalCustom").setVisibleCancel('.');
          this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
          this.getField('confirmModalCustom').toggle(true);
          this.limpiarCampos();
        }else{
            let fecha_actual = new Date();
            let fechaActualFormateada = fecha_actual.toISOString().split('T')[0];
            let sucursales = "";
            if(this.getField('sucursales').getValue()===''){
              sucursales="TODAS"
            }else{
              sucursales=this.getField('sucursales').getValue();
            }
            let datosFicha = [];
            let data2 = 
                    {                  
                        "nombre": data.data.lista.nombre_empresa,
                        "operacion": this.getField('operacion').getValue(),
                        "fecha_actual": fechaActualFormateada,
                        "desde": this.getField('fecha_desde').getValue() ,
                        "sucursales": sucursales,
                        "usuario": this.codigo_usuario,
                        "rango_cuentas": `DE LA CUENTA: ${this.getField('cuenta_inicial').getValue()} A LA CUENTA: ${this.getField('cuenta_final').getValue()}`,
                    };
            
            datosFicha.push(data2);
    
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('total_saldo_anterior').setValue(data.data.lista.total_antes.toFixed(2));
            this.getField('total_final').setValue(data.data.lista.total_actual.toFixed(2));
            this.getField('total_credito').setValue(data.data.lista.total_creditos.toFixed(2));
            this.getField('total_debito').setValue(data.data.lista.total_debitos.toFixed(2));
            this.getField('total_saldo_anterior').setVisible(true);
            this.getField('total_final').setVisible(true);
            this.getField('total_credito').setVisible(true);
            this.getField('total_debito').setVisible(true);
            this.getField('tabla_anexos').toggle(true);
            let configCell = new Map();
            configCell.set('cuenta',{cellStyle: this.currencyFormattersucursaldetallada});
            configCell.set('saldo_anterior',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattersaldoanterior});
            configCell.set('movimiento_debito',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterdebito});
            configCell.set('movimiento_credito',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercredito});
            configCell.set('saldo_final',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattersaldofinal});
            this.gridOptionsComponentes['rowData'] = data.data.datos_principal;
            this.getField('tabla_anexos').toggle(true);
            this.getField('tabla_anexos').initData(this.gridOptionsComponentes, configCell);
            this.getField('modal_anexos').handleClickOpen();
            this.limpiarCampos();
        }
      } else {
        if(data.estado_p===404) {
            this.getField('confirmModalCustom').toggle(true);
            this.alertGeneral.toggle(true, 'No hay datos', 'error');
            this.limpiarCampos();
        }
        else {
          this.getField('confirmModalCustom').toggle(true);
          this.limpiarCampos();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error - ' + respuesta, 'error');
        }
      }
    }

    generarPdfTabla()
    {  
      this.getField('confirmModalCustom').setTitleAndContent('¡Informativo!', ' Por favor espere. Si el archivo es demasiado grande, al finalizar se enviará el anexo en PDF al correo: '+ this.getField('email').getValue());
      this.getField('confirmModalCustom').setClickDialog(() => {});
      this.getField("confirmModalCustom").setVisibleCancel('.');
      this.getField("confirmModalCustom").setButtonConfirm(`.`);
      this.getField('confirmModalCustom').toggle(true)
        let url = this.constant.formConfiguration();
         let sucursales="";
         if(this.getField('sucursales').getValue()===''){
           sucursales="TODAS"
         }else{
           sucursales=this.getField('sucursales').getValue();
         }
         let datos2 = {};
         datos2 = {
            fecha_desde:this.getField('fecha_desde').getValue(),
            operacion:this.operacion_actual,
            codigo_sucursal:sucursales,
            email:this.getField('email').getValue(),
            cuenta_inicial:this.getField('cuenta_inicial').getValue(),
            cuenta_final:this.getField('cuenta_final').getValue()
         };

         this.limpiarCampos();

         const myJSON = JSON.stringify(datos2);
         let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-anexodetatercerorangos","operacion":"xmostrar","operacion_tipo":"consulta"}}';
         fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                  "Authorization": 'Bearer '+localStorage.getItem('token'), 
                  "Content-Type":'application/json'})})
         .then(response => {
         if(response.status!==200){
            this.getField('confirmModalCustom').toggle(false);
            if(response.status===502){
              this.alertGeneral.toggle(true,'Error al armar el pdf','error');
              // return '';
            }else{
                this.alertGeneral.toggle(true,'No hay datos','error');
                // return '';
            }
         }
         const reader = response.body.getReader();
         return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                  return reader.read().then(({ done, value }) => {
                  if (done) {
                     controller.close();
                     return;}
                  controller.enqueue(value);
                  return pump();});}}})})
         .then(stream => new Response(stream))
         .then(response => response.blob())
         .then(blob => {
            if(blob.size>0){
              blob.text().then(res=>{
                if(this.isJson(res)){
                    this.successEnviarCorreo(JSON.parse(res));
                }else{
                    const pdf = new File([blob], 'name.pdf', {
                        type:'application/pdf'});
                    window.open(URL.createObjectURL(pdf));
                    this.getField('confirmModalCustom').toggle(false);
                    this.limpiarCampos();
                } 
              });
            }else{
               this.getField('consultar').setDisabled(false);
               this.alertGeneral.toggle(true,'Acción exitosa','success');
          }})
          .catch(err => console.error("error",err));
    }

    generarExcelTabla(){
      this.getField('confirmModalCustom').toggle(false);
      this.getField('confirmModalCustom').setTitleAndContent('¡Informativo!', ' Por favor espere. Si el archivo es demasiado grande, al finalizar se enviará el anexo en EXCEL al correo: '+ this.getField('email').getValue());
      this.getField('confirmModalCustom').setClickDialog(() => {});
      this.getField("confirmModalCustom").setVisibleCancel('.');
      this.getField("confirmModalCustom").setButtonConfirm(`.`);
      this.getField('confirmModalCustom').toggle(true)
      this.email=this.getField('email').getValue();
         let url = this.constant.formConfiguration();
         let sucursales="";
          if(this.getField('sucursales').getValue()===''){
            sucursales="TODAS"
          }else{
            sucursales=this.getField('sucursales').getValue();
          }

         let datos2 = {};
         datos2 = {
            fecha_desde:this.getField('fecha_desde').getValue(),
            operacion:this.operacion_actual,
            codigo_sucursal:sucursales,
            email:this.getField('email').getValue(),
            cuenta_inicial:this.getField('cuenta_inicial').getValue(),
            cuenta_final:this.getField('cuenta_final').getValue()
         }

         this.limpiarCampos();
         const myJSON = JSON.stringify(datos2);
         let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-anexodetatercerorangos","operacion":"xmostrar","operacion_tipo":"consulta"}}';
         fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                  "Authorization": 'Bearer '+localStorage.getItem('token'), 
                  "Content-Type":'application/json'})})
         .then(response => {
         if(response.status!==200){
            this.getField('confirmModalCustom').toggle(false);
            if(response.status===502){
              this.alertGeneral.toggle(true,'Error al armar el excel','error');
              // return '';
            }else{
                this.alertGeneral.toggle(true,'No hay datos','error');
                // return '';
            }
         }
         const reader = response.body.getReader();
         return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                  return reader.read().then(({ done, value }) => {
                  if (done) {
                     controller.close();
                     return;}
                  controller.enqueue(value);
                  return pump();});}}})})
         .then(stream => new Response(stream))
         .then(response => response.blob())
         .then(blob => {
            if(blob.size>0){
              blob.text().then(res=>{
                if(this.isJson(res)){
                    this.successEnviarCorreo(JSON.parse(res));
                }else{
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  // the filename you want
                  a.download = 'anexos.xlsx';
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                  alert('Se descargo el archivo!');
                  this.getField('confirmModalCustom').toggle(false);
                  this.limpiarCampos();
                }
              });
            }else{
               this.getField('consultar').setDisabled(false);
               this.alertGeneral.toggle(true,'Acción exitosa','success');
            }})
            .catch(err => console.error(err));
    }

    enviarCorreo(){
        let sucursales="";
          if(this.getField('sucursales').getValue()===''){
            sucursales="TODAS"
          }else{
            sucursales=this.getField('sucursales').getValue();
          }

          let datos = { datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            operacion:this.operacion_actual,
            codigo_sucursal:sucursales,
            radio_correo:this.getField('radio_correo').getValue(),
            email:this.getField('email').getValue(),
            cuenta_inicial:this.getField('cuenta_inicial').getValue(),
            cuenta_final:this.getField('cuenta_final').getValue()
          }};

        this.generalFormatPmv = { tipo_servicio: 'cont-anexodetatercerorangos', operacion: 'xmostrar', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successEnviarCorreo,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
      }
    
      successEnviarCorreo(data){
         this.getField('confirmModalCustom').toggle(false); 
          if(data.estado_p === 200){ 
            if(data.data.mensaje === 'Email enviado exitosamente excel-pdf...'){
              this.alertGeneral.toggle(true, 'El archivo superó el tamaño para generar el excel, por lo tanto se envió un PDF al correo: '+ this.email, 'success');
              this.limpiarCampos();
            }else{
              this.alertGeneral.toggle(true, 'Email enviado exitosamente..', 'success');
              this.limpiarCampos();
            }
          }else{
            this.getField('confirmModalCustom').toggle(true);
            this.limpiarCampos();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error - ' + respuesta, 'error');
          }
     }

    isJson(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      
      return true;
    }

    formatNumberSaldo(number)
    {
            return Math.floor(number)
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    currencyFormatterdebito(data)
    {
            let decimal = (data.data.movimiento_debito + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.movimiento_debito) + "." + decimal);
             } else {
             return this.formatNumberSaldo(data.data.movimiento_debito); }
    }
    currencyFormattercredito(data)
    {
            let decimal = (data.data.movimiento_credito + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.movimiento_credito) + "." + decimal);
             } else {
             return this.formatNumberSaldo(data.data.movimiento_credito); }
    }

    currencyFormattersaldoanterior(data)
    {
            let decimal = (data.data.saldo_anterior + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.saldo_anterior) + "." + decimal);
             } else {
             return this.formatNumberSaldo(data.data.saldo_anterior); }
    }

    currencyFormattersaldofinal(data)
    {
            let decimal = (data.data.saldo_final + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.saldo_final) + "." + decimal);
             } else {
             return this.formatNumberSaldo(data.data.saldo_final); }
    }

    currencyFormattersucursaldetallada(data)
    {
      if (data.data.cuenta.includes('-')){
        return {textAlign:"right"};
      }
    }

    limpiarCampos(){
      let fecha_actual = new Date();
      let fechaActualFormateada = fecha_actual.toISOString().split('T')[0];
      this.getField('sucursales').setValue('');
      this.getField('cuenta_inicial').setValue('');
      this.getField('cuenta_final').setValue('');
      this.getField('nombre_cuentaf').setValue('');
      this.getField('nombre_cuentai').setValue('');
      this.getField('ch_sucursales').setValueSwitch(true);
      this.getField('ch_cierre').setValueSwitch(false);
      this.getField('ch_niif').setValueSwitch(false);
      this.getField('ch_excel').setValueSwitch(false);
      this.getField('ch_pdf').setValueSwitch(false);
      this.getField('ch_pantalla').setValueSwitch(false);
      this.getField('consultar').setDisabled(false);
      this.getField('ch_sucursales').setDisabled(false);
      this.getField('fecha_desde').setValue(fechaActualFormateada);
      this.deshabilitarCorreo();
    }

}
FormJaivana.addController("cont-anexodetatercerorangos", CustomAnexoDetalladoTercerosRangos);
export default CustomAnexoDetalladoTercerosRangos;