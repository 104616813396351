import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomGastosContabilizados
 * @author: Juan Camilo Villa
 * @version: jdesk_1.01.0002
 **/
class CustomGastosContabilizados extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.gridOptionsGastos          = Object.assign({},this.gridOptions);
        this.arrayConsecutivos          = [];
        
        this.habilitarCamposYBoton      = this.habilitarCamposYBoton.bind(this);
        
        this.habilitarBtnConsultar      = this.habilitarBtnConsultar.bind(this);
        this.consultarGastos            = this.consultarGastos.bind(this);
        this.successConsultarGastos     = this.successConsultarGastos.bind(this);
        this.setButtonTbRejillaImagenes = this.setButtonTbRejillaImagenes.bind(this);
        this.crearBotonTablaImagen      = this.crearBotonTablaImagen.bind(this);
        this.verImagen                  = this.verImagen.bind(this);
        this.limpiarCampos              = this.limpiarCampos.bind(this);

        
    }

    initForm(){
        console.log('Formulario CustomGastosContabilizados,  @version: jdesk_1.01.0002, @author:Juan Camilo Villa');
        this.getField('codigo_documento').setValue('RG');
        /* this.getField('').setOnChange(() => {this.habilitarCamposYBoton(); this.limpiarCampos()}); 
        {this.habilitarCamposYBoton(); this.limpiarCampos()} */

        this.getField('consultar').setClick(() => this.consultarGastos());
    }

    habilitarBtnConsultar(){
        if (this.getField("fecha_desde").valid() && this.getField("fecha_hasta").valid()) {
            

            /* this.getField("consultar").setVisible(false); */
            /* this.getField("consultar").setDisabled(false); */
        }else{
            /* this.getField("consultar").setDisabled(true); */

        }
    }


    habilitarCamposYBoton(){

        if (this.getField("fecha_desde").valid() && this.getField("fecha_hasta").valid() ) {
            this.getField('fecha_desde').setVisible(true);
            this.getField('fecha_hasta').setVisible(true);
            /* this.getField('numero_consecutivo').setVisible(true); */
        /*     this.getField("consultar").setVisible(true);
            this.getField("consultar").setDisabled(false); */
        }else{
            /* this.getField("consultar").setDisabled(true); */

        }

    }



    consultarGastos(){
        if(this.getField("fecha_desde").valid() && this.getField("fecha_hasta").valid()){
            /* this.getField("consultar").setDisabled(true); */
            this.datosAgregar={ 
                datos:{
                    fecha_desde: this.getField("fecha_desde").getValue(),
                    fecha_hasta: this.getField("fecha_hasta").getValue(),
                    codigo_documento: this.getField("codigo_documento").getValue()
                }
            };
            
            this.getField("rejilla").setTipoServicioOperacion('cont-contgastoscontabilizados-1_1');
            this.getField("rejilla").sendRequest(this.successConsultarGastos, this.datosAgregar);
   
            /*this.generalFormatPmv = { tipo_servicio: 'cont-contgastoscontabilizados', operacion: '1_1_total_pag', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: this.datosAgregar,
                    success: this.successConsultarGastos,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );*/
        }
    }

    successConsultarGastos(data){

        this.gridOptionsGastos['rowData'] = [];
        if (data.estado_p === 200) {
            /* this.getField("consultar").setDisabled(false); */
            let configCell = new Map();
            this.gridOptionsGastos['rowData'] = data.data.datos;
            configCell.set('fecha',{cellRenderer:function(props){return props.data.fecha_documento.split("T")[0]},field:'fecha_documento'});
            configCell.set('accion', {cellRenderer:this.setButtonTbRejillaImagenes, headerName: 'Acción', sortable: false, filter: false, width:600});
            this.getField('rejilla').initData(this.gridOptionsGastos, configCell);

        }else{
            this.gridOptionsGastos['rowData'] = [];
            this.getField('rejilla').toggle(false);
        }
    }

    setButtonTbRejillaImagenes(props){
        let fragment = document.createDocumentFragment();
        let imagenes = props.data.imagenes;

        if(props.data.imagenes.length === 0){
            let button = document.createElement('input');

            button.setAttribute("id", `buttonImagen1_${props.data.id}`);
            button.setAttribute("class","buttonStyleImgDisabled");

            button.setAttribute("value", "SIN IMAGEN");
            button.setAttribute("type", "button");
            fragment.appendChild(button);
        }else{
            for(let i=0; i<imagenes.length; i++){
                let buttonVerImagen1 = this.crearBotonTablaImagen(props.data, i);
    
                fragment.appendChild(buttonVerImagen1);
            }
        }
        
        return this.createElementJaivana(fragment);
    }

    crearBotonTablaImagen(data, pos) {
        let button = document.createElement('input');

        button.setAttribute("id", `buttonImagen1_${data.id}`);
        button.setAttribute("class","buttonStyleImg");

        button.onclick = () => { this.verImagen(data.imagenes[pos]) };

        button.setAttribute("value", "VER IMAGEN "+(pos+1));
        button.setAttribute("type", "button");
        return button;
    }

    verImagen(url_imagen){
        this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let url = this.constant.formConfiguration();
        let datos=`?body={"datos":{"url":"${url_imagen}"},"generales":{"tipo_servicio":"maes-documentosporvendedor","operacion":"url_imagen","operacion_tipo":"consulta"}}`;
        fetch(url+datos,{
            method:'get',
            headers: new Headers({
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json'
            })
        })
        .then(response => {
            if(response.status!==200){                    
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            })
        }).then(stream => new Response(stream))
            .then(response => {
                return response.blob()
            })
            .then(blob => {
                if(blob.size>0){
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",
                    });
                    window.open(URL.createObjectURL(image))
                }else{
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
                this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
            })
            .catch(err => console.error(err));
    }


    limpiarCampos(){
        if(this.getField("fecha_desde").valid() !== "" && this.getField("fecha_hasta").valid() !== ""  ){
            this.numeroConsecutivo();
            /* this.getField("consultar").setDisabled(false); */
            this.getField("consultar").setVisible(true);
            this.getField("fecha_desde").setVisible(true);
            this.getField("fecha_hasta").setVisible(true);
        }else{
            this.getField("fecha_desde").setVisible(false);
            this.getField("fecha_hasta").setVisible(false);
            this.gridOptionsGastos['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsGastos);
            this.getField('rejilla').toggle(false);
            /* this.getField("consultar").setDisabled(true); */
            this.getField("consultar").setVisible(false);
            
        }
    }

}
FormJaivana.addController("cont-contgastoscontabilizados",CustomGastosContabilizados);
export default CustomGastosContabilizados