import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Anderson Acevedo
 * 
 * @version jdesk_1.01.0002
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomResumenDeInventarioPorSucursal extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);

        this.consultarCompra = this.consultarCompra.bind(this);
        this.gridOptionsTbPrincipal = Object.assign({}, this.gridOptions);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptionsTbPrincipal['onSelectionChanged'] = this.onSelectionChanged;


        this.ocultarCampos = this.ocultarCampos.bind(this);
        this.validarfechas = this.validarfechas.bind(this);

        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
        this.traerPropiedades = this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades = this.sucesstraerPropiedades.bind(this);
        this.successDatosPrincipal = this.successDatosPrincipal.bind(this);
        this.arreglarDecimales = this.arreglarDecimales.bind(this);
        this.crearTemporalVerMas                         =this.crearTemporalVerMas.bind(this);
        this.successCrearTemporalVerMas                         =this.successCrearTemporalVerMas.bind(this);


        this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
        this.sucursal_ingreso = '';
        this.numero_decimales = 2;
        this.fecha_hoy='';
        this.fecha_inicio='';
    }

    initForm() {
        console.log('Formulario CustomResumenDeInventarioPorSucursal,  @version: jdesk_1.01.0002, @author:Anderson Acevedo ');
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedades();
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.getField('fecha').setValue(this.fecha_inicio);
        this.getField("consultar").setClick(this.consultarCompra);


        this.getField("fecha").setOnChange(this.validarfechas);


    }

    traerPropiedades() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'inve-resumeninventariosucursal', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    sucesstraerPropiedades(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.numero_decimales = data.data.numero_decimales;
            this.dias_maximo=data.data.dias_devolver;
        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



    onSelectionChanged() {

    }

    validarfechas() {
        this.getField('consultar').setDisabled(false);
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("fecha").getValue()).toISOString().split('T')[0];
        this.ocultarCampos();

        this.getField("fecha").setError(false, "");
        if (fecha_desde > fecha_actual) {
            this.getField("desde").setError(true, "No puede ser mayor a la fecha actual.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        this.getField('consultar').setDisabled(false);
        return true;

    }


    ocultarCampos() {
        this.getField('rejilla').toggle(false);
        this.getField('consultar').setDisabled(false);
    }



    consultarCompra() {
        let errores = 0;
        this.ocultarCampos();
        this.getField('fecha').setError(false,'');

        if (this.validarfechas()===false) {
                errores++;
        }
        if (errores === 0) {
            //this.getField("consultar").setDisabled(true);
            this.crearTemporalVerMas();
        }
    }

    crearTemporalVerMas() {
        this.mostrarMensajeGenerando();
        let datos = {
            datos: {
                fecha:this.getField('fecha').getValue(),
                sucursal_ingreso:this.sucursal_ingreso
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-resumeninventariosucursal', operacion: 'generardatos', operacion_tipo: 'crear' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'POST',
                body: datos,
                success: this.successCrearTemporalVerMas,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successCrearTemporalVerMas(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.mostrarMensajeGenerando();
            let datos = {
                datos: {
                    sucursal_ingreso:this.sucursal_ingreso,
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'inve-resumeninventariosucursal', operacion: 'archivoitems_pag', operacion_tipo: "consulta" };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successDatosPrincipal,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        } else {
            this.mostrarMensajeNoHayDatos();
            this.ocultarCampos();
            this.getField('consultar').setDisabled(true);
        }
    }

    successDatosPrincipal(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField('consultar').setDisabled(true);
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('inventario', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.inventario) }, type: 'rightAligned' });
            configCell.set('consignacion', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.consignacion) }, type: 'rightAligned' });
            configCell.set('remisiones', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.remisiones) }, type: 'rightAligned' });

            this.getField('rejilla').initData(this.gridOptionsTbPrincipal, configCell);
            this.getField('rejilla').toggle(true);
           
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }


    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > this.numero_decimales) {
                number = number.toFixed(this.numero_decimales); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


    mostrarMensajeAdicional(mensaje) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

    }

    mostrarMensajeNoHayDatos() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }


    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }


}
FormJaivana.addController("inve-resumeninventariosucursal", CustomResumenDeInventarioPorSucursal);
export default CustomResumenDeInventarioPorSucursal