import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomContabilidadPlantillasConfiguracion
 * @author: Santiago Hernández N.
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomContabilidadPlantillasConfiguracion extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                                 = this.initForm.bind(this);
        this.archivoUf                                                = "";
        this.enviarArchivo                                            = this.enviarArchivo.bind(this);
        this.url_servidor                                             = "";
        this.onClickNew                                               = this.handlerClickNew.bind(this);
        this.successSubirArchivo                                      = this.successSubirArchivo.bind(this);
        this.recargarPrimero                                          = this.recargarPrimero.bind(this);
        this.successDataPrimero                                       = this.successDataPrimero.bind(this);
        this.cancelarEnviarArchivo                                    = this.cancelarEnviarArchivo.bind(this);
        this.servicioTraerTodos                                       = this.servicioTraerTodos.bind(this);
        this.successDataGeneral                                       = this.successDataGeneral.bind(this);        
        this.gridOptionsTableGeneral                                  = Object.assign({},this.gridOptions);
        this.onSelectionChangedT                                      = this.onSelectionChangedT.bind(this);
        this.gridOptionsTableGeneral["onSelectionChanged"]            = this.onSelectionChangedT;
        this.dataTableSeleccion                                       = false;
        this.validarUnique                                            = this.validarUnique.bind(this);
        this.successValidarUnique                                     = this.successValidarUnique.bind(this);
        this.onClickChange                                            = this.handlerClickChange.bind(this);
        this.validarRegistroSeleccionadoModificar                     = this.validarRegistroSeleccionadoModificar.bind(this);
        this.validarModificar                                         = this.validarModificar.bind(this);
        this.mostrarMensajeSeleccionModificar                         = this.mostrarMensajeSeleccionModificar.bind(this);
        this.onClickShowTable                                         = this.handerClickShowTable.bind(this);
        this.modificar                                                = this.modificar.bind(this);
        this.filaSeleccionada                                         = [];
        this.successServicioModificar                                 = this.successServicioModificar.bind(this);
        this.onClickDelete                                            = this.handlerClickDelete.bind(this);
        this.validarRegistroSeleccionadoEliminar                      = this.validarRegistroSeleccionadoEliminar.bind(this);
        this.confirmarEliminacion                                     = this.confirmarEliminacion.bind(this);
        this.mostrarMensajeSeleccionEliminar                          = this.mostrarMensajeSeleccionEliminar.bind(this);
        this.prevalidarEliminar                                       = this.prevalidarEliminar.bind(this);
        this.successPrevalidarEliminar                                = this.successPrevalidarEliminar.bind(this);
    }

    initForm() {

        console.log('Formulario CustomContabilidadPlantillasConfiguracion,  @version: jdesk_1.01.0001, @author:Santiago Hernández N.  @updateBy: Jose Albeiro Marin');
        
        //this.buttons.get("btn_new").ref.current.setDisabled(false);
        this.getField("btn_agregar").setClick(this.validarUnique);
        this.getField("btn_cancelar").setClick(this.cancelarEnviarArchivo);
        this.getField("btn_cancelar_modificar").setClick(this.cancelarEnviarArchivo);

        this.getField("btn_modificar").setClick(this.modificar);

        this.getField('archivo_uf').setOnChange((props) => {
            if(props.target.files[0] !== undefined && props.target.files[0] !== " " ){
                this.archivoUf = props.target.files[0];

                let array=props.target.files[0].name.split('.');
                this.extension= array[array.length-1];
                
                
                if(this.extension==='XLSX' || this.extension==='xlsx'){
                    this.getField('archivo_uf').setError(false, '');
                    this.getField('archivo').setValue(props.target.files[0].name);
                }else{
                    // console.log("else extension....");
                    this.getField('archivo_uf').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                    this.getField('archivo').setValue(props.target.files[0].name);

                }
            }else{
                this.archivoUf = "";
                this.getField('archivo_uf').setError(false, '');
            }
              
        });

    }

    handlerClickNew(props) {
        this.buttons.get("btn_new").ref.current.setVisible(false);
        this.buttons.get("btn_show").ref.current.setVisible(false);
        this.buttons.get("btn_change").ref.current.setVisible(false);
        this.buttons.get("btn_delete").ref.current.setVisible(false);

        this.getField("btn_agregar").setVisible(true);
        this.getField('btn_agregar').setDisabled(false);
        this.getField('btn_cancelar').setVisible(true);
        this.getField('btn_cancelar').setDisabled(false);

        this.getField('tableShowData').toggle(false);

        this.getField('descripcion').setValue('');
        this.getField('estadoactivo_id').setValue('');
        this.getField('archivo').setValue('');
        this.getField('ruta_archivo').setValue('');

        this.getField("archivo_uf").setDisabled(false);
        this.getField("estadoactivo_id").setDisabled(false);
        this.getField("descripcion").setDisabled(false);
        this.getField("archivo_uf").handleClickDelete(this);


    }

    validarUnique(){
        // console.log("validarArchivo", this.archivoUf)
        if (
            // this.getField('archivo_uf').valid() && 
            this.getField('estadoactivo_id').valid() && 
            this.getField('descripcion').valid() ) {
            if(this.extension==='XLSX' || this.extension==='xlsx'){

                let datos = { datos: {} };
                datos.datos['archivo'] = this.getField('archivo').getValue();
                this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfiguracion', operacion: '20', operacion_tipo: 'consulta' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'GET',
                        body: datos,
                        success: this.successValidarUnique,
                        error: this.error_,
                        general: this.generalFormatPmv
                    }
                );
                }else{
                this.getField('archivo_uf').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                } 
            }

    }

    successValidarUnique(data){
        if (data.estado_p === 200) {

            this.alertGeneral.toggle(true, 'El archivo a subir ya existe, no se permite duplicar', 'error');

        } else {
             
            this.enviarArchivo();
        
        }
    }
    

    enviarArchivo(){
            if(this.archivoUf !== ""){
                if(this.extension==='XLSX' || this.extension==='xlsx'){
                    const data = {
                        _generales: {"tipo_servicio":"cont-plantillasconfiguracion","operacion":"5","operacion_tipo": "crear"},
                        archivo_uf: this.archivoUf,
                        archivo: this.getField('archivo').getValue(),
                        estadoactivo_id: this.getField('estadoactivo_id').getValue(),
                        descripcion: this.getField('descripcion').getValue()
                    }
                    this.generalFormat = data._generales;
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'POST',
                        body: data,
                        success: this.successSubirArchivo,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: true,
                        showMessage: false
                    });
                }else{
                    this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                } 
            }         
         
    }


    successSubirArchivo(data){
        if (data.estado_p === 200) {
            
            this.alertGeneral.toggle(true, 'Registro insertado exitosamente.', 'success');
            this.buttons.get("btn_new").ref.current.setVisible(true);
            this.buttons.get("btn_show").ref.current.setVisible(true);
            this.buttons.get("btn_change").ref.current.setVisible(true);
            this.buttons.get("btn_delete").ref.current.setVisible(true);

            this.getField("btn_agregar").setVisible(false);
            this.getField('btn_agregar').setDisabled(true);
            this.getField('btn_cancelar').setVisible(false);
            this.getField('btn_cancelar').setDisabled(true);

    
            this.getField('tableShowData').toggle(false);
    
            this.getField('descripcion').setValue('');
            this.getField('estadoactivo_id').setValue('');
            this.getField('archivo').setValue('');
            this.getField('ruta_archivo').setValue('');
    
            this.getField("archivo_uf").setDisabled(true);
            this.getField("estadoactivo_id").setDisabled(true);
            this.getField("descripcion").setDisabled(true);

            this.getField("archivo_uf").handleClickDelete(this);



            this.recargarPrimero();
            this.servicioTraerTodos();

        } else {
            let respuesta = Object.values(data.data.errores);
            let keys = Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
        }
    }


    recargarPrimero(){
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfiguracion', operacion: '4_1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successDataPrimero,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }


    successDataPrimero(data){
        if (data.estado_p === 200) {
            this.getField('estadoactivo_id').setValue(data.data[0].estadoactivo_id);
            this.getField('descripcion').setValue(data.data[0].descripcion);
            this.getField('archivo').setValue(data.data[0].archivo);
            this.getField('ruta_archivo').setValue(data.data[0].ruta_archivo);

        } else {//si no encuentra datos, no muestra la tabla 
            this.getField('tableShowData').toggle(false);

        }
    }

    servicioTraerTodos(){
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfiguracion', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successDataGeneral,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successDataGeneral(data){
        if (data.estado_p === 200 && data.model && data.model.length >= 1) {

            this.gridOptionsTableGeneral['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud

            //this.gridOptionsTableArticulosVarios['onSelectionChanged'] =this.onSelectionChanged;
            this.getField('tableShowData').initData(this.gridOptionsTableGeneral);//esta configuracion se carga en la tabla por defecto del crud


        } else {//si no encuentra datos, no muestra la tabla 
            this.getField('tableShowData').toggle(false);

        }
    }


    cancelarEnviarArchivo(){
        this.buttons.get("btn_new").ref.current.setVisible(true);
        this.buttons.get("btn_show").ref.current.setVisible(true);
        this.buttons.get("btn_change").ref.current.setVisible(true);
        this.buttons.get("btn_delete").ref.current.setVisible(true);

        this.getField("btn_agregar").setVisible(false);
        this.getField('btn_agregar').setDisabled(true);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_cancelar').setDisabled(true);

        
        this.getField("btn_modificar").setVisible(false);
        this.getField('btn_modificar').setDisabled(true);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setDisabled(true);


        this.getField('descripcion').setValue('');
        this.getField('estadoactivo_id').setValue('');
        this.getField('archivo').setValue('');
        this.getField('ruta_archivo').setValue('');

        this.getField('descripcion').setError(false, '');
        this.getField('estadoactivo_id').setError(false, '');
        this.getField('archivo').setError(false, '');
        this.getField('ruta_archivo').setError(false, '');
        this.getField('archivo_uf').setError(false, '');

        this.getField("archivo_uf").setDisabled(true);
        this.getField("estadoactivo_id").setDisabled(true);
        this.getField("descripcion").setDisabled(true);

        this.getField("archivo_uf").handleClickDelete(this);

        this.gridOptionsTableGeneral["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTableGeneral);
        this.getField('tableShowData').toggle(false);

        this.dataTableSeleccion = false;
        this.servicioTraerTodos();


        this.recargarPrimero();
    }

    handlerClickChange(props) {
        this.validarRegistroSeleccionadoModificar();

    }


    onSelectionChangedT() {
    
        this.filaSeleccionada = this.gridOptionsTableGeneral.api.getSelectedRows();
        this.getField('contabilidadplantillasconfiguracion_id').setValue(this.filaSeleccionada[0].contabilidadplantillasconfiguracion_id);
        this.getField('estadoactivo_id').setValue(this.filaSeleccionada[0].estadoactivo_id);
        this.getField('descripcion').setValue(this.filaSeleccionada[0].descripcion);
        this.getField('archivo').setValue(this.filaSeleccionada[0].archivo);
        this.getField('ruta_archivo').setValue(this.filaSeleccionada[0].ruta_archivo);

        this.dataTableSeleccion = true;

    }

    validarRegistroSeleccionadoModificar(){
        if (this.dataTableSeleccion) {
            
            this.validarModificar();

        } else {
            
            this.mostrarMensajeSeleccionModificar();
        }
    }


    validarModificar() {
        this.buttons.get("btn_new").ref.current.setVisible(false);
        this.buttons.get("btn_show").ref.current.setVisible(false);
        this.buttons.get("btn_change").ref.current.setVisible(false);
        this.buttons.get("btn_delete").ref.current.setVisible(false);

        this.getField('descripcion').setDisabled(false);
        this.getField('estadoactivo_id').setDisabled(false);
        this.getField('archivo_uf').setDisabled(false);

        this.getField('btn_modificar').setVisible(true);
        this.getField('btn_cancelar_modificar').setVisible(true);
        this.getField('btn_modificar').setDisabled(false);
        this.getField('btn_cancelar_modificar').setDisabled(false);

        this.getField('archivo').setValue('');

        this.gridOptionsTableGeneral['rowData'] = [];
        this.getField('tableShowData').initData(this.gridOptionsTableGeneral);
        this.getField('tableShowData').toggle(false);


    }

    mostrarMensajeSeleccionModificar() {// no se toca nada aqui está ya full

        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para modificar.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

    }

    handerClickShowTable(props) {


        this.servicioTraerTodos();
    
    }

    modificar(){
        if(this.getField("estadoactivo_id").valid() && this.getField("descripcion").valid()){

            if (this.getField("archivo").getValue()!==''){

                if(this.archivoUf !== ""){
                    if(this.extension==='XLSX' || this.extension==='xlsx'){
                        const data = {
                            _generales: {"tipo_servicio":"cont-plantillasconfiguracion","operacion":"6","operacion_tipo": "modificar"},
                            archivo_uf: this.archivoUf,
                            archivo: this.getField('archivo').getValue(),
                            estadoactivo_id: this.getField('estadoactivo_id').getValue(),
                            descripcion: this.getField('descripcion').getValue(),
                            contabilidadplantillasconfiguracion_id: this.getField('contabilidadplantillasconfiguracion_id').getValue()
                        }
                        this.generalFormat = data._generales;
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method: 'PUT',
                            body: data,
                            success: this.successServicioModificar,
                            error: this.error_,
                            general: this.generalFormat,
                            formData: true,
                            showMessage: false
                        });
                    }else{
                        this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                    } 
                } 

            }else{
                let datos = { datos: {} };
                datos.datos['descripcion'] = this.getField('descripcion').getValue();
                datos.datos['estadoactivo_id'] = this.getField('estadoactivo_id').getValue();
                datos.datos['contabilidadplantillasconfiguracion_id'] = this.getField('contabilidadplantillasconfiguracion_id').getValue();

                this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfiguracion', operacion: '6', operacion_tipo: 'modificar' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'PUT',
                        body: datos,
                        success: this.successServicioModificar,
                        error: this.error_,
                        general: this.generalFormatPmv
                    });
            }
        
        }
    }


    successServicioModificar(data){
        if (data.estado_p === 200) {

            this.alertGeneral.toggle(true, 'Registro actualizado exitosamente.', 'success');
            this.buttons.get("btn_new").ref.current.setVisible(true);
            this.buttons.get("btn_show").ref.current.setVisible(true);
            this.buttons.get("btn_change").ref.current.setVisible(true);
            this.buttons.get("btn_delete").ref.current.setVisible(true);

            this.getField("btn_agregar").setVisible(false);
            this.getField('btn_agregar').setDisabled(true);
            this.getField('btn_cancelar').setVisible(false);
            this.getField('btn_cancelar').setDisabled(true);

            this.getField("btn_modificar").setVisible(false);
            this.getField('btn_modificar').setDisabled(true);
            this.getField('btn_cancelar_modificar').setVisible(false);
            this.getField('btn_cancelar_modificar').setDisabled(true);

    
            this.getField('tableShowData').toggle(false);
    
            this.getField('descripcion').setValue('');
            this.getField('estadoactivo_id').setValue('');
            this.getField('archivo').setValue('');
            this.getField('ruta_archivo').setValue('');
    
            this.getField("archivo_uf").setDisabled(true);
            this.getField("estadoactivo_id").setDisabled(true);
            this.getField("descripcion").setDisabled(true);

            this.getField("archivo_uf").handleClickDelete(this);



            this.recargarPrimero();
            this.servicioTraerTodos();


        } else { 


            if (data.data.errores.error==='El archivo a actualizar ya existe, no se permite duplicar.'){
                this.alertGeneral.toggle(true,data.data.errores.error, 'error');
            } else {
                let respuesta = Object.values(data.data.errores);
                let keys = Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
            }

            

        }

    }

    handlerClickDelete(props){
        this.validarRegistroSeleccionadoEliminar();
    }

    validarRegistroSeleccionadoEliminar(){
        if (this.dataTableSeleccion) {
            this.confirmarEliminacion();
        } else {   
            this.mostrarMensajeSeleccionEliminar();
        }
    }

    mostrarMensajeSeleccionEliminar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para eliminar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmarEliminacion(){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(this.prevalidarEliminar);
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');       
        this.getField('confirmModalCustom').toggle(true);
    }

    prevalidarEliminar(){
        let datos={ datos: {
            contabilidadplantillasconfiguracion_id:this.filaSeleccionada[0].contabilidadplantillasconfiguracion_id
        }}    
        this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfiguracion', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successPrevalidarEliminar,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }


    successPrevalidarEliminar(data){

        if (data.estado_p === 200) {

            this.alertGeneral.toggle(true, 'Registro eliminado exitosamente.', 'success');

            this.getField('confirmModalCustom').toggle(false);
            this.recargarPrimero();
            this.servicioTraerTodos();

        } else { 

            let respuesta = Object.values(data.data.errores);
            let keys = Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');                        

        }

    }






}

FormJaivana.addController("cont-plantillasconfiguracion", CustomContabilidadPlantillasConfiguracion);
export default CustomContabilidadPlantillasConfiguracion