import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomConsultarArchivos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.infoUsuario                = "";
        this.fechaDesde                 = this.fechaDesde.bind(this);   
        this.validarfechas              = this.validarfechas.bind(this);
        this.registro1                  = this.registro1.bind(this);
        this.successRegistro1           = this.successRegistro1.bind(this);
        this.registro2                  = this.registro2.bind(this);
        this.successRegistro2           = this.successRegistro2.bind(this);
        this.DatosModal                  = this.DatosModal.bind(this);
        this.successDatosModal           = this.successDatosModal.bind(this);
        this.nuevoArray                 = [];
        this.mostrarTabla               = this.mostrarTabla.bind(this);
        this.gridOptionsComponentes     = Object.assign({},this.gridOptions);
        this.datos                      = {};  
        this.setCrearButtonRejilla      = this.setCrearButtonRejilla.bind(this); 
        this.continuar                  = true;
        this.mas60dias                  = false;
        this.menosdiadesde              = false;
    }

    initForm(){
        console.log('Formulario CustomConsultarArchivos,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez')
        
        this.infoUsuario = JSON.parse(localStorage.getItem('res'));
        this.infoUsuario = this.infoUsuario.codigo;
        this.fechaDesde();
        this.getField('fecha').setDisabled(true);
        this.getField('hora').setDisabled(true);
        this.getField('fecha_desde').setOnChange(this.validarfechas);
        this.getField('fecha_hasta').setOnChange(this.validarfechas);     
        this.getField('mirar_archivos').setClick(this.registro1);
        this.getField('todos').setClick(this.registro2);
        this.getField('volver').setClick(()=>{this.getField('modal').handleClose()});
    }

    // Mostrar siempre el primer día del mes (1):
    fechaDesde(){ 
        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-1";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fecha_desde').setValue(campoFecha.split('T')[0]);

    }

    validarfechas(){
        this.continuar = false;
        this.mas60dias = false;
        this.menosdiadesde = false;
        this.nuevoArray = [];
        this.getField('rejilla').toggle(false);
        this.getField('mirar_archivos').setDisabled(false);
        this.getField('todos').setDisabled(false);
        
        this.getField('fecha_hasta').setError(false, "");
        this.getField('fecha_desde').setError(false, "");
        let fecha = new Date(this.getField('fecha_hasta').getValue());
        let fecha1  =   fecha.toISOString();
        let fecha2 = fecha1.split('T')[0];
        let fechadesde = new Date(this.getField('fecha_desde').getValue());
        let fechadesde1  =   fechadesde.toISOString();
        let fechadesde2 = fechadesde1.split('T')[0];
        if (fecha2 < fechadesde2) {
            this.continuar = false;
            this.menosdiadesde = true
        }else if (fecha2 >= fechadesde2){
            this.getField('fecha_hasta').setError(false, "");
            if (this.getField('fecha_desde').getValue().replace(/\u0020/g,'') !== '' && this.getField('fecha_hasta').getValue().replace(/\u0020/g,'') !== '') {
                let fechaDesdeFormateada = this.getField('fecha_desde').getValue();
                let fechaHastaoFormateada = this.getField('fecha_hasta').getValue();
    
                let fechaRango = new Date(fechaDesdeFormateada) - new Date(fechaHastaoFormateada);
                let diferencia = fechaRango / 1000 / 60 / 60 / 24;
                if (Math.sign(diferencia) === -1) {
                    diferencia = Math.round(parseInt(diferencia.toString().slice(1)));
                }
                if (diferencia > 60 ) {
                    this.continuar = false;
                    this.mas60dias = true;
                }else{
                    this.continuar = true;
                }
            }
        }
    }

    registro1()
    {
        this.getField('fecha_hasta').setError(false, '');
        this.getField('fecha_desde').setError(false, '');
        if(this.continuar === true){
            let datos={ datos: {usuario: this.infoUsuario, fecha_desde: this.getField('fecha_desde').getValue(), fecha_hasta: this.getField('fecha_hasta').getValue() + " 23:58:00.41199"}};
            this.generalFormatPmv = { tipo_servicio: 'cont-archivosconfigsubir', operacion: '1_3', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successRegistro1,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else if (this.mas60dias === true){
            this.getField('fecha_hasta').setError(true, "¡La fecha debe ser menor a 60 días!");
        }else if (this.menosdiadesde === true){
            this.getField('fecha_hasta').setError(true, "¡La fecha debe ser mayor o igual a la fecha desde!");
        }
    }

    successRegistro1(data)
    {
        if (data.estado_p === 200 )
        {
            if(data.model && data.model.length>0){
            data.data.forEach(datos => {
                let dataOp = {}
                dataOp['id'] = datos.id;
                dataOp['Usuario'] = datos.usuario;
                dataOp['Referencia'] = datos.referencia;
                dataOp['Fecha'] = datos.fecha_sistema.split('T')[0];
                dataOp['Estado'] = datos.estado;
                dataOp['Fecha_Ultima_Actualizacion'] = datos.fecha_ultima_actualizacion;
                dataOp['Observaciones'] = datos.detalle_ultima_actualizacion;;
                
                this.nuevoArray.push(dataOp); })
            }
            this.mostrarTabla(this.nuevoArray);    
        }
        else 
        {
           if(data.estado_p===404) {
            this.getField('todos').setDisabled(true);
            this.getField('mirar_archivos').setDisabled(true);
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    registro2()
    {
        this.getField('fecha_hasta').setError(false, '');
        this.getField('fecha_desde').setError(false, '');
        if(this.continuar === true){
            let datos={ datos: {fecha_desde: this.getField('fecha_desde').getValue(), fecha_hasta: this.getField('fecha_hasta').getValue() + " 23:58:00.41199"}};
            this.generalFormatPmv = { tipo_servicio:  'cont-archivosconfigsubir', operacion: '1_4', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successRegistro2,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else if (this.mas60dias === true){
            this.getField('fecha_hasta').setError(true, "¡La fecha debe ser menor a 60 días!");
        }else if (this.menosdiadesde === true){
            this.getField('fecha_hasta').setError(true, "¡La fecha debe ser mayor o igual a la fecha actual!");
        }
    }

    successRegistro2(data)
    {
        if (data.estado_p === 200 )
        {
            if(data.model && data.model.length>0){
                data.data.forEach(datos => {
                    let dataOp = {}
                    dataOp['id'] = datos.id;
                    dataOp['Usuario'] = datos.usuario;
                    dataOp['Referencia'] = datos.referencia;
                    dataOp['Fecha'] = datos.fecha_sistema.split('T')[0];
                    dataOp['Estado'] = datos.estado;
                    dataOp['Fecha_Ultima_Actualizacion'] = datos.fecha_ultima_actualizacion;
                    dataOp['Observaciones'] = datos.detalle_ultima_actualizacion;;
                    
                this.nuevoArray.push(dataOp); })
            }
            this.mostrarTabla(this.nuevoArray);          
        }
        else 
        {
           if(data.estado_p===404) {
            this.getField('todos').setDisabled(true);
            this.getField('mirar_archivos').setDisabled(true);
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    mostrarTabla(data){
        this.getField('rejilla').toggle(true);
        let configCell = new Map();
        this.gridOptionsComponentes['rowData'] = data;
        configCell.set('Accion',{cellRenderer:this.setCrearButtonRejilla, field:'Accion'});
        this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
        this.getField('mirar_archivos').setDisabled(true);
        this.getField('todos').setDisabled(true);
    }

    setCrearButtonRejilla(props)
    {
        let id = props.data.id; 
        let button = document.createElement("input"); 
        button.onclick = ()=>{this.getField('modal').handleClickOpen(); this.DatosModal(id)};
        button.setAttribute("class","buttonStyle");
        button.setAttribute("type","button");
        button.setAttribute("value","Ver Más");
        return this.createElementJaivana(button);
    }

    DatosModal(id)
    {
        let datos={ datos: {id: id }};
        this.generalFormatPmv = { tipo_servicio:  'cont-archivosconfigsubir', operacion: '2_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDatosModal,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }


    successDatosModal(data){
        if (data.estado_p === 200 ){
            
            this.getField('horas').setValue(data.data[0].nhoras)
            this.getField('fecha').setValue(data.data[0].fecha_contabilizacion)
            this.getField('hora').setValue(data.data[0].hora_contabilizacion)
            this.getField('detalle').setValue(data.data[0].detalle)
            this.getField('archivo').setValue(data.data[0].nombre_archivo)  
        } 
        else {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }
}
FormJaivana.addController("cont-archivosconfigconsul",CustomConsultarArchivos);
export default CustomConsultarArchivos