import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomTrasladosFacturacion
 * @author: Patricia Lopez
 * @version: jdesk_1.01.0002
 **/
class CustomAuditoriaTraslados extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.gridOptionsComponentes                     = Object.assign({},this.gridOptions);
        this.initForm                                   = this.initForm.bind(this); 
        this.pendientesxrevisar                         = this.pendientesxrevisar.bind(this); 
        this.validarfechas                              = this.validarfechas.bind(this);    
        this.consulta                                   = this.consulta.bind(this);    
        this.successCrearTabla                          = this.successCrearTabla.bind(this);
        this.mostrarTabla                               = this.mostrarTabla.bind(this);
        this.successMostrarTabla                        = this.successMostrarTabla.bind(this);
        this.ver                                        = this.ver.bind(this);
        this.successver                                 = this.successver.bind(this);
        this.cambiar_estado                             = this.cambiar_estado.bind(this);
        this.successcambiar_estado                      = this.successcambiar_estado.bind(this);
        this.selectedRows                               = {};
        this.gridOptionsComponentesConsultarModal       = Object.assign({}, this.gridOptions);

        this.modificartrasladospendientes               = this.modificartrasladospendientes.bind(this);
        this.consultar                                  = this.consultar.bind(this);
        this.successConsulta1                           = this.successConsulta1.bind(this);
        this.modificar                                  = this.modificar.bind(this);
        this.successModificar                           = this.successModificar.bind(this);
        this.limpiar                                    = this.limpiar.bind(this);

        this.trasladosentresucursales                   = this.trasladosentresucursales.bind(this);
        this.consultaOrigen                             = this.consultaOrigen.bind(this);
        this.successconsultaOrigen                      = this.successconsultaOrigen.bind(this);
        this.currencyFormatter                          = this.currencyFormatter.bind(this);
        this.currencyFormattercontabilidad              = this.currencyFormattercontabilidad.bind(this);
        this.formatNumberSaldo                          = this.formatNumberSaldo.bind(this);
        this.trasladodestino                            = this.trasladodestino.bind(this);
        this.successtrasladodestino                     = this.successtrasladodestino.bind(this);
        this.traerdetalleOrigen                         = this.traerdetalleOrigen.bind(this);
        this.successtraerdetalleorigen                  = this.successtraerdetalleorigen.bind(this);
        this.detalle                                    = this.detalle.bind(this);
        this.successactualizarDetalle                   = this.successactualizarDetalle.bind(this);
        this.valor                                      = this.valor.bind(this);
        this.successactualizarvalor                     = this.successactualizarvalor.bind(this);

    }

    initForm(){
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';

        if(this.props.data[0].opcion_sub_seccion === 1){
            this.getField('operacion_form').setValue('Pendientes Por Revisar');
            this.pendientesxrevisar();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.getField('operacion_form').setValue('Modificar Traslados Pendientes');
            this.modificartrasladospendientes();
        }else if(this.props.data[0].opcion_sub_seccion === 3){
            this.getField('operacion_form').setValue('Traslados entre sucursales');
            this.trasladosentresucursales();
        }
    }

    pendientesxrevisar(){
        console.log("Formulario CustomTrasladosFacturacion,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.getField("fecha_desde").setValue(this.fecha_inicio);
        this.getField("fecha_desde").setVisible(true);
        this.getField("fecha_hasta").setVisible(true);
        this.getField("codigo_sucursal").setVisible(true);
        this.getField("codigo_sucursal_alterno").setVisible(true);
        this.getField("nombre_sucursal").setVisible(true);
        this.getField("nombre_sucursal_alterno").setVisible(true);
        this.getField("enviados").setVisible(true);
        this.getField("enviados").setClick(this.consulta);
        this.getField("ver_productos").setClick(this.ver);
        this.getField("marcar_para_contabilizar").setClick(this.cambiar_estado);
        this.getField("fecha_desde").setOnChange(()=>{
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes);
            this.getField('rejilla').toggle(false);
            this.getField("ver_productos").setVisible(false);
            this.getField("marcar_para_contabilizar").setVisible(false);
        });
        this.getField("fecha_hasta").setOnChange(()=>{
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes);
            this.getField('rejilla').toggle(false);
            this.getField("ver_productos").setVisible(false);
            this.getField("marcar_para_contabilizar").setVisible(false);
        });
        this.getField("nombre_sucursal").setOnChange(()=>{
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes);
            this.getField('rejilla').toggle(false);
            this.getField("ver_productos").setVisible(false);
            this.getField("marcar_para_contabilizar").setVisible(false);
        });
        this.getField("nombre_sucursal_alterno").setOnChange(()=>{
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes);
            this.getField('rejilla').toggle(false);
            this.getField("ver_productos").setVisible(false);
            this.getField("marcar_para_contabilizar").setVisible(false);
        });

    }

    validarfechas(){
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("fecha_desde").getValue());

        this.getField("fecha_desde").setError(false,"");

        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }
        return true;
    }

    consulta(){
        if(this.validarfechas){
            if(this.getField("codigo_sucursal").valid() && this.getField("nombre_sucursal").valid() 
            && this.getField("codigo_sucursal_alterno").valid() && this.getField("nombre_sucursal_alterno").valid() ){
                let datos = { datos: {
                    fecha_desde:this.getField('fecha_desde').getValue(),
                    fecha_hasta:this.getField('fecha_hasta').getValue(),
                    operacion:this.operacion_actual,
                    codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                    codigo_sucursal_alterno:this.getField("codigo_sucursal_alterno").getValue(),
                    sucursal_ingreso:this.codigo_sucursal,
                    codigousuario:this.codigo_usuario
                }};
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'consultar', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successCrearTabla,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }
    }

    successCrearTabla(data){
        if (data.estado_p === 200 )
        {
            this.mostrarTabla();
            
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error No hay datos', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                this.getField('rejilla').initData(this.gridOptionsComponentes);
                this.getField('rejilla').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    mostrarTabla(){
        let datos = { datos: {
            codigousuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'mostrar_tabla', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successMostrarTabla,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successMostrarTabla(data){
        if(data.estado_p === 200){

            this.getField("marcar_para_contabilizar").setVisible(true);
            this.getField("ver_productos").setVisible(true);
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data.data;
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = {};
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
            };
        }else{
            if(data.estado_p===404) {
                this.gridOptionsComponentes['rowData'] = [];
                this.getField('rejilla').initData(this.gridOptionsComponentes);
                this.getField('rejilla').toggle(false);
            }
            else {
               let respuesta=Object.values(data.data.errores);
               let keys=Object.keys(data.data.errores);
               this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    ver(){
        if(this.selectedRows.length > 0){
            let datos = { datos: {
                numero:this.selectedRows[0].numero,
                fecha:this.selectedRows[0].fecha
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'ver', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successver,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });        
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
        }
    }

    successver(data){
        if (data.estado_p === 200 )
        {
            this.getField('modal_general').handleClickOpen();
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "ajuste_numero": this.selectedRows[0].numero
                };
            datosFicha.push(data2);
            this.getField('lista_datos_general').setItemsFichas(datosFicha);

            let configCell = new Map();
            this.gridOptionsComponentesConsultarModal['rowData'] = [];
            this.gridOptionsComponentesConsultarModal['rowData'] = data.data.data;
            this.getField('tabla_consulta').initData(this.gridOptionsComponentesConsultarModal,configCell);

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error No hay datos', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    cambiar_estado(){
        if(this.selectedRows.length > 0){
            let datos = { datos: {
                numero:this.selectedRows[0].numero,
                numero_recibido:this.selectedRows[0].numero_recibido,
                fecha:this.selectedRows[0].fecha
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'cambiar_estado', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successcambiar_estado,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
        }
    }

    successcambiar_estado(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Estado Actualizado.', 'success');
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error No se actualizaron los datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    //////////////////////////Modificara traslados pendientes
    
    modificartrasladospendientes(){
        console.log("Formulario CustomTrasladosFacturacion,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");

        this.getField("label1").setVisible(true);
        this.getField("sucursal").setVisible(true);
        this.getField("nom_sucursal").setVisible(true);
        this.getField("numero").setVisible(true);
        this.getField("desde").setVisible(true);
        this.getField("consultar").setVisible(true);
        this.getField("cancelar").setVisible(true);
        this.getField("label2").setVisible(true);
        this.getField("sucursal_alterno").setVisible(true);
        this.getField("nom_sucursal_alterna").setVisible(true);
        this.getField("codigo_concepto").setVisible(true);
        this.getField("nombre_concepto").setVisible(true);
        this.getField("numero_alterno").setVisible(true);
        this.getField("modificar").setVisible(true);
        
        this.getField("consultar").setClick(this.consultar);
        this.getField("cancelar").setClick(this.limpiar);
        this.getField("modificar").setClick(this.modificar);


    }

    consultar(){
        if (this.getField("numero").getValue() !== 0) {
            if(this.getField("sucursal").valid() && this.getField("numero").valid() && this.getField("desde").valid()){
                this.getField("sucursal").setDisabled(true);
                this.getField("numero").setDisabled(true);
                this.getField("desde").setDisabled(true);
                
                let datos = { datos: {
                    numero:this.getField("numero").getValue(),
                    sucursal:this.getField("sucursal").getValue(),
                    desde:this.getField("desde").getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'consultar1', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successConsulta1,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }else{
            this.limpiar();
        }
    }

    successConsulta1(data){
        if (data.estado_p === 200 && data.data.data.length > 0)
        {
            this.getField("sucursal_alterno").setValue(data.data.data[0].codigo_sucursal_alterna);
            this.getField("nom_sucursal_alterna").setValue(data.data.nombre_sucursal);
            this.getField("codigo_concepto").setValue(data.data.data[0].proveedor);
            this.getField("nombre_concepto").setValue(data.data.nombre_concepto);
            this.getField("numero_alterno").setValue(data.data.data[0].numero_alterno);

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
                this.limpiar();
            }else if (data.estado_p === 200 && data.data.data.length === 0){
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
                this.limpiar();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    limpiar(){
        
        this.getField("sucursal").setDisabled(false);
        this.getField("numero").setDisabled(false);
        this.getField("desde").setDisabled(false);   

        this.getField("sucursal").setValue("");
        this.getField("numero").setValue("");
        this.getField("desde").setValue("");   
        this.getField("sucursal_alterno").setValue("");
        this.getField("codigo_concepto").setValue("");
        this.getField("numero_alterno").setValue("");
        this.getField("nom_sucursal").setValue("");
        this.getField("nom_sucursal_alterna").setValue("");
        this.getField("nombre_concepto").setValue("");

    }

    modificar(){
        if(this.getField("sucursal").valid() && this.getField("nom_sucursal").valid() && this.getField("numero").valid()
        && this.getField("sucursal_alterno").valid() && this.getField("codigo_concepto").valid() && this.getField("numero_alterno").valid()){
            if(this.getField("nombre_concepto").valid()){
                let datos = { datos: {
                    codigo_concepto:this.getField("codigo_concepto").getValue(),
                    sucursal_alterno:this.getField("sucursal_alterno").getValue(),
                    numero_alterno:this.getField("numero_alterno").getValue(),
                    numero:this.getField("numero").getValue(),
                    desde:this.getField("desde").getValue(),
                    sucursal:this.getField("sucursal").getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'modificar', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successModificar,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }
    }

    successModificar(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Datos actualizados.', 'success');
            this.limpiar();
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    ///////////////////////////// Traslados Entre Sucursales

    trasladosentresucursales(){
        console.log("Formulario CustomTrasladosFacturacion,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField("sucursal_origen").setVisible(true);
        this.getField("nombre_origen").setVisible(true);
        this.getField("sucursal_destino").setVisible(true);
        this.getField("nombre_destino").setVisible(true);
        this.getField("select_estado").setVisible(true);
        this.getField("consultar").setVisible(true);

        this.getField("consultar").setClick(this.consultaOrigen);
        this.getField("detalle").setClick(()=>{
        this.getField('numero_datos').getValue() !== "" ? this.getField('modal_detalle').handleClickOpen(): this.alertGeneral.toggle(true, 'Debe seleccionar un registro', 'error');
        });
        this.getField("valor").setClick(()=>{
            this.getField('numero_datos').getValue() !== "" ? this.getField('modal_valor').handleClickOpen(): this.alertGeneral.toggle(true, 'Debe seleccionar un registro', 'error');
        });
        this.getField("cancelar2").setClick(()=>{this.getField('modal_detalle').handleClose();});
        this.getField("aceptar").setClick(this.detalle);
        this.getField("aceptar2").setClick(this.valor);
        this.getField("cancelar3").setClick(()=>{this.getField('modal_valor').handleClose();});
        this.getField("numero_datos").setOnChange(this.trasladodestino);
        //this.getField("rejilla_origen").setVisible(true);
        //this.getField("rejilla_detalle_origen").setVisible(true);
        //this.getField("rejilla_destino").setVisible(true);
        //this.getField("rejilla_detalle_destino").setVisible(true);

    }

    consultaOrigen(){
        //if(this.getField("sucursal").valid() && this.getField("nom_sucursal").valid() && this.getField("numero").valid()
        //&& this.getField("sucursal_alterno").valid() && this.getField("codigo_concepto").valid() && this.getField("numero_alterno").valid()){
            let datos = { datos: {
                sucursal_origen:this.getField("sucursal_origen").getValue(),
                select_estado:this.getField("select_estado").getValue()
            }};
            this.getField("rejilla_origen").setTipoServicioOperacion("inve-trasladospendientes-consultarorigen");
            this.getField("rejilla_origen").sendRequest(this.successconsultaOrigen, datos);
        //}
    }

    successconsultaOrigen(data){
        if (data.estado_p === 200 )
        {
            console.log("datos ",data)
            this.getField("detalle").setVisible(true);
            this.getField("valor").setVisible(true);
            this.getField("contabilizar").setVisible(true);
            this.getField("numero_datos").setVisible(true);
            let configCell = new Map();
            configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercontabilidad});
            configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatter});

            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla_origen').initData(this.gridOptionsComponentes, configCell);
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows = {};
                this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
                this.getField("numero_datos").setValue(this.selectedRows[0].numero);
                this.getField("valor_contabilizar").setValue(this.selectedRows[0].valor);
            };
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    currencyFormatter(data)
    {
            let decimal = (data.data.valor + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.valor) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.valor); }
    }

    currencyFormattercontabilidad(data)
    {
            let decimal = (data.data.valor_contabilidad + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.valor_contabilidad) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.valor_contabilidad); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    detalle(){
        if(this.getField("detalle_cambio").getValue() !== ""){
            let hoy= new Date();
            let dia=hoy.getDate();
            let mes=hoy.getMonth()+1;
            let año=hoy.getFullYear();
            this.fecha_hoy=año+'-'+mes+'-'+dia;
            let datos = { datos: {
                detalle_cambio:this.getField("detalle_cambio").getValue(),
                fecha_modifica:this.fecha_hoy,
                usuario:this.codigo_usuario,
                numero_datos:this.getField("numero_datos").getValue(),
                fecha:this.selectedRows[0].fecha,
                sucursal_dato:this.selectedRows[0].sucursal
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'modificaDetalle', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successactualizarDetalle,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe ingresar un detalle.', 'error');
        }
    }

    successactualizarDetalle(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Datos actualizados.', 'success');
            this.getField('modal_detalle').handleClose();
            this.consultaOrigen();
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    valor(){
        if(this.getField("valor_contabilizar").getValue() !== "" && this.getField("valor_contabilizar").getValue() !== 0){
            let datos = { datos: {
                valor_contabilizar:this.getField("valor_contabilizar").getValue(),
                numero_datos:this.getField("numero_datos").getValue(),
                fecha:this.selectedRows[0].fecha,
                sucursal_dato:this.selectedRows[0].sucursal
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'valorcontabilizar', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successactualizarvalor,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe ingresar un valor.', 'error');
        }
    }

    successactualizarvalor(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Datos actualizados.', 'success');
            this.getField('modal_valor').handleClose();
            this.consultaOrigen();
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }
    
    trasladodestino(){
        let datos = { datos: {
            sucursal_origen:this.selectedRows[0].sucursal,
            numero_origen:this.selectedRows[0].numero
        }};
        this.getField("rejilla_destino").setTipoServicioOperacion("inve-trasladospendientes-consultardestino");
        this.getField("rejilla_destino").sendRequest(this.successtrasladodestino, datos);
        
    }
    
    successtrasladodestino(data){
        if (data.estado_p === 200 )
        {
            let configCell = new Map();
            configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercontabilidad});
            configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatter});
            
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla_destino').initData(this.gridOptionsComponentes, configCell);
            this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                this.selectedRows2 = {};
                this.selectedRows2 = this.gridOptionsComponentes.api.getSelectedRows();
            };
            this.traerdetalleOrigen();
        }else {
            this.traerdetalleOrigen();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traerdetalleOrigen(){
        let datos = { datos: {
            fecha_desde:this.selectedRows[0].fecha,
            numero_datos:this.selectedRows[0].numero
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladospendientes', operacion: 'traerdetalle', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successtraerdetalleorigen,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successtraerdetalleorigen(data){
        if (data.estado_p === 200 )
        {

            console.log(data);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }
    
}

FormJaivana.addController("inve-trasladospendientes",CustomAuditoriaTraslados);
export default CustomAuditoriaTraslados

