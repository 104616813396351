import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomTiposProductos
 * @author: Patricia López Sánchez 
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomTiposProductos extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.cambiarestado              = this.cambiarestado.bind(this);
        this.cambiarestado1             = this.cambiarestado1.bind(this);
        this.cambiarestado2             = this.cambiarestado2.bind(this);
        this.service_estadosino         = this.service_estadosino.bind(this);
        this.successTraerEstado         = this.successTraerEstado.bind(this);
        this.traersino                  = this.traersino.bind(this);
        this.tipos                      = this.tipos.bind(this);
    }

    initForm(){
        console.log('Formulario CustomTiposProductos,  @version: jdesk_10.0.001, @author:Patricia López Sánchez, @updateBy: Jose Albeiro Marin')

        this.service_estadosino();
        this.getField('tipo').setKeyUp(this.tipos);
        this.getField('excluido_id').setOnBlur(this.cambiarestado);
        this.getField('exento_id').setOnBlur(this.cambiarestado1);
        this.getField('gravado_id').setOnBlur(this.cambiarestado2);
        this.getField('comprar1').setOnChange(this.getField('comprar').setValue(this.getField('comprar1').getValue()));
        this.getField('vender1').setOnChange(this.getField('vender').setValue(this.getField('vender1').getValue()));
        this.getField('devolucion_compras1').setOnChange(this.getField('devolucion_compras').setValue(this.getField('devolucion_compras1').getValue()));
        this.getField('devolucion_ventas1').setOnChange(this.getField('devolucion_ventas').setValue(this.getField('devolucion_ventas1').getValue()));

    }

    tipos(){
        if((this.getField('tipo').getValue() === 'CR') || (this.getField('tipo').getValue() === 'CO')){
           this.getField('tipo').setError(false,'')
        }
        else if((this.getField('tipo').getValue() !== 'CR') || (this.getField('tipo').getValue() !== 'CO')){
            this.alertGeneral.toggle(true, 'Solo se permite digitar CR o CO en el campo tipo.', 'error');
            this.getField('tipo').setError(true,'*Solo se permite digitar CR o CO.')
        }
    }

    traersino(){
        if(this.getField('excluido').getValue() === 'N'){
            this.getField('excluido_id').setValue(2);
        }else{
            this.getField('excluido_id').setValue(1);
        }

        if(this.getField('exento').getValue() === 'N'){
            this.getField('exento_id').setValue(2);
        }else{
            this.getField('exento_id').setValue(1);
        }

        if(this.getField('gravado').getValue() === 'N'){
            this.getField('gravado_id').setValue(2);
        }else{
            this.getField('gravado_id').setValue(1);
        }
    }

    cambiarestado(){
        if (this.getField('excluido_id').getValue() === 1) { 
            this.getField('exento_id').setValue(2);
            this.getField('gravado_id').setValue(2);
            this.getField('exento').setValue('N');
            this.getField('gravado').setValue('N');
            this.getField('excluido').setValue('S');
            this.getField('excluido_id').setError(false, '')
            this.getField('exento_id').setError(false, '')
            this.getField('gravado_id').setError(false, '')
        } else if (this.getField('excluido_id').getValue() === 2) { 
            this.getField('excluido').setValue('N');
        }
    }

    cambiarestado1(){
        if (this.getField('exento_id').getValue() === 1) {
            this.getField('excluido_id').setValue(2);
            this.getField('gravado_id').setValue(2);
            this.getField('exento').setValue('S');
            this.getField('gravado').setValue('N');
            this.getField('excluido').setValue('N');
            this.getField('excluido_id').setError(false, '')
            this.getField('exento_id').setError(false, '')
            this.getField('gravado_id').setError(false, '')
        }else if (this.getField('exento_id').getValue() === 2) {
            this.getField('exento').setValue('N');
        }
    }

    cambiarestado2(){
        if (this.getField('gravado_id').getValue() === 1) {
            this.getField('exento_id').setValue(2);
            this.getField('excluido_id').setValue(2);
            this.getField('exento').setValue('N');
            this.getField('gravado').setValue('S');
            this.getField('excluido').setValue('N');
            this.getField('excluido_id').setError(false, '')
            this.getField('exento_id').setError(false, '')
            this.getField('gravado_id').setError(false, '')
        }else if (this.getField('gravado_id').getValue() === 2) {
            this.getField('gravado').setValue('N');
        }
    }

    service_estadosino()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: '1', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerEstado,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerEstado(data)
    {
        if (data.estado_p === 200)
        {
            if(data.model && data.model.length>0){
             let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.estadossino_id;
             dataOp['text'] = item.estadossino_siglas;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('excluido_id').setOptions(opciones);
             this.getField('exento_id').setOptions(opciones);
             this.getField('gravado_id').setOptions(opciones);
             }
             this.traersino();
        }
        else 
        {
           if(data.estado_p===404) {
                // console.log('no hay datos...');
           }
           else {
            //   console.log(data);
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
            //   console.log('Errores:'+respuesta);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

}
FormJaivana.addController("maes-tiposproductos",CustomTiposProductos);
export default CustomTiposProductos;