import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomStocksDeSeguridad
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 **/
class CustomStocksDeSeguridad extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
        this.onSelectionChangedPrincipal = this.onSelectionChangedPrincipal.bind(this);
        this.gridOptionsComponentes['onSelectionChanged'] = this.onSelectionChangedPrincipal.bind(this);


        this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesItems['onSelectionChanged'] = this.onSelectionChanged;


        this.gridOptionsComponentesMinimo = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesMinimo['onSelectionChanged'] = this.onSelectionChanged;
        this.gridOptionsComponentesMaximo = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesMaximo['onSelectionChanged'] = this.onSelectionChanged;

        this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
        this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
        this.validarAgregar = this.validarAgregar.bind(this);
        this.sucesstraerPropiedades = this.sucesstraerPropiedades.bind(this);
        this.sucesstraerPropiedades = this.sucesstraerPropiedades.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
        this.validarCodigo = this.validarCodigo.bind(this);
        this.sucessValidarCodigo = this.sucessValidarCodigo.bind(this);
        this.traerInfoArticulo = this.traerInfoArticulo.bind(this);
        this.sucessTraerArticulo = this.sucessTraerArticulo.bind(this);
        this.sucessTraerArticulo2 = this.sucessTraerArticulo2.bind(this);
        this.changeKeyUpCustom = this.changeKeyUpCustom.bind(this);
        this.sucesstraerStock = this.sucesstraerStock.bind(this);
        this.successAgregar = this.successAgregar.bind(this);

        this.traerMinimosYMaximos = this.traerMinimosYMaximos.bind(this);
        this.successMInimosMaximos = this.successMInimosMaximos.bind(this);
        this.setButtonModificar = this.setButtonModificar.bind(this);


        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.modificarStock = this.modificarStock.bind(this);
        this.successModificar = this.successModificar.bind(this);
        this.arreglarDecimales = this.arreglarDecimales.bind(this);

        this.numero_decimales_compras = 0;
        this.sucursal_ingreso = '';

        this.respuesta_completa = false;
        this.datos_articulo_aux = [];
        this.prioridad_barras = '';
        this.operacion_seleccionada='';
        this.datos_modificar=[];
        this.fila_seleccionada=[];

    }

    initForm() {
        console.log("Formulario CustomStocksDeSeguridad,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        //nuevo para consulta
        this.getField('agregar').setClick(this.validarAgregar);
        this.getField('criticos').setClick(()=>{
            if(this.getField('codigo_bodega').valid() && this.getField('nombre_bodega').valid()){
                this.traerMinimosYMaximos();
            }
        });

        this.getField('bt_cancelar').setClick(()=>{this.getField('modal_modificar').handleClose()});
        this.getField('bt_modificar').setClick(this.modificarStock);

        this.getField('nombre_articulo').setOnChange((props) => {
            this.getField('nombre_articulo').setError(false, '');
            this.getField('minimo').setValue('');
            this.getField('maximo').setValue('');
            this.getField('minimo').setError(false, '');
            this.getField('maximo').setError(false, '');
        });

        this.getField('nombre_bodega').setOnChange((props) => {
            this.getField('nombre_bodega').setError(false, '');
            this.getField('rejilla_seguros').toggle(false);
            this.getField('rejilla_criticos').toggle(false);
        });

        this.getField("codigo_articulo_aux").setOnChange(() => {
            if (this.getField('codigo_articulo_aux').getValue() !== '' && this.prioridad_barras === 'S' && this.cambiar_prioridad !== false) {
                this.validarCodigo();
            } else if (this.prioridad_barras !== 'S') {
                //aquí no tiene prioridad entonces trae directamente el artículo
                if (this.getField('codigo_articulo_aux').getValue() !== '' && this.respuesta_completa === true && this.datos_articulo_aux.length <= 1) {
                    this.sucessTraerArticulo2(this.datos_articulo_aux);
                } else if (this.datos_articulo_aux.length > 1 && this.getField("codigo_articulo_aux").getValue() === this.getField("codigo_articulo").getValue()) {
                    this.sucessTraerArticulo2(this.datos_articulo_aux);
                }
            }
        });

        this.getField("codigo_articulo").setOnChange(() => {
            this.respuesta_completa = false;
            this.getField('nombre_articulo').setError(false, '');
            this.getField('nombre_articulo').setValue('');
        });

        this.getField("codigo_articulo").setCustomSuccess((props) => {
            this.datos_articulo_aux = props;
            this.respuesta_completa = true;
            if (this.prioridad_barras !== 'S') {
                //para forzar a consumir de nuevo el onchange
                this.getField("codigo_articulo_aux").setValue('cod_aux');
            }
            if (props.length <= 1 && this.operacion_seleccionada !== 'F9') {
                if (this.prioridad_barras === 'S') {
                    this.cambiar_prioridad = false;
                    this.validarCodigo();
                } else {
                    this.cambiar_prioridad = true;
                }
            } else {
                this.cambiar_prioridad = true;
            }
        });

        //sobreescrivir keyups
        this.getField('codigo_articulo').setKeyUp((e) => { this.changeKeyUpCustom(e) });

        this.traerPropiedades();


    }

    changeKeyUpCustom = (e) =>{
        let keycode = (e.keyCode ? e.keyCode : e.which);
        //keycode 120 = f9_servicio;
        //keycode 13 = enter_servicio;
        if(keycode === 120){
            this.operacion_seleccionada = "F9";
        }else{
            this.operacion_seleccionada = "ENTER-BOTON"; 
        }
        this.getField("codigo_articulo").changeKeyUp(e);
    }

    validarCodigo() {
        if (this.getField("codigo_articulo").valid()) {
            let datos = {
                datos: {
                    codigo_articulo: this.getField("codigo_articulo").getValue(),
                    sucursal_ingreso: this.sucursal_ingreso,
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'inve-stocksdeseguridad', operacion: 'codigoauxiliar', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.sucessValidarCodigo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        }
    }

    sucessValidarCodigo(data) {
        if (data.estado_p === 200) {
            this.getField('codigo_articulo').setValue(data.data.codigo);
            this.traerInfoArticulo();
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el artículo.', 'error');
            }
            else {
                let respuesta = Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traerInfoArticulo() {
        this.mostrarMensajeGenerando();
        let datos = {
            datos: {
                value: this.getField('codigo_articulo').getValue()
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '42', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.sucessTraerArticulo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    sucessTraerArticulo(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField('nombre_articulo').setValue(data.data[0].nombre);
        } else if (data.estado_p === 404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el artículo.', 'error');
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    sucessTraerArticulo2(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.length > 0) {
            //eso es para evitar consumir dos veces el servicio traer articulo cuando prioridad barras esta en N
            //ya que no sería necesari, se guardo la respuesta dle primer servicio y con eso lleno los datos
            if (this.getField('codigo_articulo').getValue() !== '') {
                data.forEach((element) => {
                    if (element.codigo_interno === this.getField('codigo_articulo').getValue()) {
                        data[0] = element;
                        return;
                    }
                });
            }
            this.getField('nombre_articulo').setValue(data[0].nombre);
            this.datos_articulo_aux = [];
        } else {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el artículo.', 'error');
        }
    }




    modificarStock() {
        if(this.getField('min_nuevo').valid() && this.getField('max_nuevo').valid() ){
            if(this.getField('min_nuevo').getValue() > this.getField('max_nuevo').getValue()){
                this.mostrarMensajeAdicional('El valor mínimo no puede ser mayor al valor máximo.');
                return;
            }
            let datos = {
                datos: {
                    sucursal_ingreso: this.sucursal_ingreso,
                    codigo_articulo: this.datos_modificar.codigo,
                    codigo_bodega: this.datos_modificar.bodega,
                    minimo: this.getField('min_nuevo').getValue(),
                    maximo: this.getField('max_nuevo').getValue(),
                }
            }
            let op = 'modificarstock';
            this.mostrarMensajeGenerando();
            this.generalFormatPmv = { tipo_servicio: 'inve-stocksdeseguridad', operacion: op, operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successModificar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        }
    }

    successModificar(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField('modal_modificar').handleClose();
            this.traerStock();
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta = Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    abrirModalModificarStock() {
        this.getField('modal_modificar').setLabel(this.datos_modificar.nombre);
        this.getField('modal_modificar').handleClickOpen();
        this.getField('min_nuevo').setValue(this.datos_modificar.minimo);
        this.getField('max_nuevo').setValue(this.datos_modificar.maximo);
    }

    onSelectionChangedPrincipal() {
        this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
    }


    traerPropiedades() {
        let datos = { datos: {
            sucursal_ingreso:this.sucursal_ingreso
        } };
        this.generalFormatPmv = { tipo_servicio: 'inve-stocksdeseguridad', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    sucesstraerPropiedades(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.numero_decimales_compras = data.data.numero_decimales_compras;
            this.prioridad_barras = data.data.prioridad_barras;
            this.traerStock();
        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    traerStock() {
        this.mostrarMensajeGenerando();
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'inve-stocksdeseguridad', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.sucesstraerStock,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    sucesstraerStock(data) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('criticos').setVisible(false);
        if (data.estado_p === 200) {
            this.getField('rejilla').toggle(true);
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;

            configCell.set('minimo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.minimo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('maximo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.maximo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('accion', { cellRenderer: this.setButtonModificar, headerName: 'Acción', sortable: false, filter: false });

            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
            this.getField('criticos').setVisible(true);
        } else if (data.estado_p === 404) {
            this.mostrarMensajeNoHayDatos();
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    setButtonModificar(props) {
        let fragment = document.createDocumentFragment();
        let button1 = document.createElement("input");
        button1.onclick = () => {
            this.datos_modificar=props.data;
            this.abrirModalModificarStock();
        };
        button1.setAttribute("id", 'button_delete_' + props.data.codigo+props.data.bodega);
        button1.setAttribute("type", "button");
        button1.setAttribute("value", "Modificar");
        button1.setAttribute("class","buttonStyle7");
        fragment.appendChild(button1);
        return this.createElementJaivana(fragment);
    }

    validarAgregar() {

        this.getField('codigo_articulo').setError(false, '');
        this.getField('codigo_bodega').setError(false, '');
        this.getField('nombre_bodega').setError(false, '');
        this.getField('minimo').setError(false, '');
        this.getField('maximo').setError(false, '');
        this.getField('nombre_articulo').setError(false, '');

        let errores = 0;
        if (!this.getField('codigo_articulo').valid())
            errores++;
        if (!this.getField('nombre_articulo').valid())
            errores++;

        if (!this.getField('codigo_bodega').valid())
            errores++;
        if (!this.getField('nombre_bodega').valid())
            errores++;


        if (!this.getField('minimo').valid())
            errores++;
        if (!this.getField('maximo').valid())
            errores++;
        if(this.getField('minimo').getValue() > this.getField('maximo').getValue()){
            this.mostrarMensajeAdicional('El valor mínimo no puede ser mayor al valor máximo.');
            errores++;
        }

        if (errores === 0) {
            this.mostrarMensajeGenerando();
            let datos = {
                datos: {
                    sucursal_ingreso: this.sucursal_ingreso,
                    operacion: this.operacion_actual,
                    minimo: this.getField('minimo').getValue(),
                    maximo: this.getField('maximo').getValue(),
                    codigo_articulo: this.getField('codigo_articulo').getValue(),
                    codigo_bodega: this.getField('codigo_bodega').getValue(),
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'inve-stocksdeseguridad', operacion: 'agregarstock', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successAgregar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });

        }

    }

    
    successAgregar(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField('codigo_articulo').setValue('');
            this.getField('nombre_articulo').setValue('');
            this.getField('codigo_bodega').setValue('');
            this.getField('nombre_bodega').setValue('');
            this.getField('minimo').setValue('');
            this.getField('maximo').setValue('');
            this.traerStock();
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta = Object.values(data.data.errores);
                this.mostrarMensajeAdicional(respuesta);
                //this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traerMinimosYMaximos(){
        this.mostrarMensajeGenerando();
            let datos = {
            datos: {
                sucursal_ingreso: this.sucursal_ingreso,
                operacion: this.operacion_actual,
                codigo_bodega: this.getField('codigo_bodega').getValue(),
            }
        }
        this.generalFormatPmv = { tipo_servicio: 'inve-stocksdeseguridad', operacion: 'stocksxbodega', operacion_tipo: 'crear' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'POST',
                body: datos,
                success: this.successMInimosMaximos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
    }

    successMInimosMaximos(data) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('rejilla_seguros').toggle(false);
        this.getField('rejilla_criticos').toggle(false);
        if (data.estado_p === 200) {
            let configCell = new Map();
            
            configCell.set('minimo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.minimo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('maximo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.maximo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('saldo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: { textAlign: "right" }, width: 110 });

            if(data.data.minimos!==undefined && data.data.minimos.length>0){
                this.getField('rejilla_seguros').toggle(true);
                this.gridOptionsComponentesMinimo['rowData'] = [];
                this.gridOptionsComponentesMinimo['rowData'] = data.data.minimos;
                this.getField('rejilla_seguros').initData(this.gridOptionsComponentesMinimo, configCell);
            }

            if(data.data.maximos!==undefined && data.data.maximos.length>0){
                this.getField('rejilla_criticos').toggle(true);
                this.gridOptionsComponentesMaximo['rowData'] = [];
                this.gridOptionsComponentesMaximo['rowData'] = data.data.maximos;
                this.getField('rejilla_criticos').initData(this.gridOptionsComponentesMaximo, configCell);
            }
        } else if (data.estado_p === 404) {
            this.mostrarMensajeNoHayDatos();
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



    onSelectionChanged() {

    }


    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > this.numero_decimales_compras) {
                number = number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

    }

    mostrarMensajeNoHayDatos() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo


    mostrarMensajeAdicional(mensaje) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    arreglarDecimales(numero) {
        let numero_valor = Number(numero);
        let decimales = numero_valor.toString().split('.')[1];
        if (decimales !== undefined && decimales.length > 2) {
            numero_valor = numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }

}


FormJaivana.addController("inve-stocksdeseguridad", CustomStocksDeSeguridad);
export default CustomStocksDeSeguridad;