import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAuditoriaDeAutoretenedores
 * @author: Anderson Acevedo
 * @version:  jdesk_1.01.0001.1
 **/



class CustomAuditoriaDeAutoretenedores extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.validarfechas                                          = this.validarfechas.bind(this);
        this.cargarFechas                                           = this.cargarFechas.bind(this);
        this.limpiarErrores                                         = this.limpiarErrores.bind(this);
        this.crearTabla                                             = this.crearTabla.bind(this);
        this.sucessCrearTabla                                       = this.sucessCrearTabla.bind(this);
        this.limpiarCampos                                          = this.limpiarCampos.bind(this);
        this.successDeleteTmp                                       = this.successDeleteTmp.bind(this);
        this.limpiarTemporal                                        = this.limpiarTemporal.bind(this);
        this.validarCampos                                          = this.validarCampos.bind(this);
        this.successvalidarCampos                                   = this.successvalidarCampos.bind(this);
        this.gridOptionsComponentes                                 = Object.assign({},this.gridOptions);
        this.onSelectionChanged                                     = this.onSelectionChanged.bind(this);
        this.onSelectionChangedVacio                                     = this.onSelectionChangedVacio.bind(this);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.gridOptionsComponentesDev                                 = Object.assign({},this.gridOptions);
        this.gridOptionsComponentesDev['onSelectionChanged']           = this.onSelectionChangedVacio;
        this.gridOptionsComponentesCont                                 = Object.assign({},this.gridOptions);
        this.gridOptionsComponentesCont['onSelectionChanged']           = this.onSelectionChangedVacio;
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.codigo_sucursal ='';
        this.traerDias                                              = this.traerDias.bind(this);
        this.successTraerDias                                       = this.successTraerDias.bind(this);
        this.dias_maximo=0;
        this.sucessTraerDatos                                       = this.sucessTraerDatos.bind(this);
        this.filaSeleccionada=[];
        this.mostrarFactura                                       = this.mostrarFactura.bind(this);
        this.successMostrarFactura                                       = this.successMostrarFactura.bind(this);
        this.contabilizar                                       = this.contabilizar.bind(this);
        this.sucessContabilizar                                       = this.sucessContabilizar.bind(this);
        this.mostrarMensajes                                       = this.mostrarMensajes.bind(this);
        
        

    }



    initForm() {
        console.log("Formulario CustomAuditoriaDeAutoretenedores,  @version:  jdesk_1.01.0001.1, @author: Anderson Acevedo ");
        this.cargarFechas();
        this.getField("fecha_desde").setOnChange(this.validarfechas);
        this.getField("fecha_hasta").setOnChange(this.validarfechas);
        this.traerDias();
        this.getField('ch_fras_de_ventas').setValue(true);
        this.getField('ch_facturas').setValue(false);

        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.getField('ch_fras_de_ventas').setOnChange((props)=>{
            if(this.getField('ch_fras_de_ventas').getValue()==='false'){
                this.getField('ch_facturas').setValueSwitch (false);
                this.getField('rejilla').toggle(false);
                this.getField('ver_devolucion').setVisible(false);
                this.getField('procesar').setDisabled(false);
                this.getField('label_5').setVisible(false);
            }else{
                this.getField('ch_fras_de_ventas').setValueSwitch (true);
            }
          });
          this.getField('ch_facturas').setOnChange((props)=>{
            if(this.getField('ch_facturas').getValue()==='false'){
                this.getField('ch_fras_de_ventas').setValueSwitch (false);
                this.getField('rejilla').toggle(false);
                this.getField('ver_devolucion').setVisible(false);
                this.getField('procesar').setDisabled(false);
                this.getField('label_5').setVisible(false);
            }else{
                this.getField('ch_facturas').setValueSwitch (true);
            }
          });

        this.getField("procesar").setClick(this.validarCampos);
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.getField('nombre_sucursal').setOnChange(()=>{
            this.getField('nombre_sucursal').setError(false,'');
            this.getField('rejilla').toggle(false);
            this.getField('ver_devolucion').setVisible(false);
            this.getField('procesar').setDisabled(false);
            this.getField('label_5').setVisible(false);
        });

        this.getField("ver_devolucion").setClick(this.mostrarFactura);
        this.getField("cancelar").setClick(()=>{this.getField('modal_items').handleClose();this.gridOptionsComponentes.api.deselectAll();});
        this.getField("contabilizar").setClick(this.contabilizar);
    }

    mostrarFactura(){
        if(this.filaSeleccionada.length>0){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(() => {});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            let datos={ datos: {
                codigo_sucursal :this.getField('codigo_sucursal').getValue(),
                nit             :this.filaSeleccionada[0].nit,
                sede            :this.filaSeleccionada[0].sede,
                fecha_fact      :this.filaSeleccionada[0].fecha,
                num_fact        :this.filaSeleccionada[0].numero,
                numeros_dev     :this.filaSeleccionada[0].num_dev,
            }};
            this.generalFormatPmv = { tipo_servicio: 'cont-auditoriaautoretenedores', operacion: 'mostrarfactura', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successMostrarFactura,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        }
    }

    successMostrarFactura(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.getField('rejilla_bases_items').toggle(true);
            this.gridOptionsComponentesDev['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesDev['rowData'] = data.data.tabla_devoluciones;
            configCell.set('retencion', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.retencion) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('base', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.base) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla_bases_items').initData(this.gridOptionsComponentesDev,configCell);

            this.getField('rejilla_contable').toggle(true);
            this.gridOptionsComponentesCont['rowData'] = [];
            let configCell2= new Map();
            this.gridOptionsComponentesCont['rowData'] = data.data.tabla_contable;
            configCell2.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell2.set('base', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.base) }, cellStyle: {textAlign:"right"},width: 110});
            configCell2.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla_contable').initData(this.gridOptionsComponentesCont,configCell2);
            this.getField('rete3').setValue(data.data.rete_3);
            this.getField('rete1').setValue(data.data.rete_1);
            //this.getField("modal_items").setLabel("Factura No "+this.filaSeleccionada[0].numero+" *** "+"Dev. No "+this.filaSeleccionada[0].num_dev);
            this.getField("modal_items").setLabel("".concat("Factura No ",this.filaSeleccionada[0].numero+" *** ","Dev. No "+this.filaSeleccionada[0].num_dev));
            this.getField('modal_items').handleClickOpen();

        }else if(data.estado_p === 404){
            this.getField('rejilla_contable').toggle(false);
            this.getField('rejilla_bases_items').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            this.getField('rejilla_contable').toggle(false);
            this.getField('rejilla_bases_items').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    contabilizar(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => {});
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
            codigo_sucursal :this.getField('codigo_sucursal').getValue(),
            nit             :this.filaSeleccionada[0].nit,
            sede            :this.filaSeleccionada[0].sede,
            fecha_fact      :this.filaSeleccionada[0].fecha,
            num_fact        :this.filaSeleccionada[0].numero,
            numeros_dev     :this.filaSeleccionada[0].num_dev,
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-auditoriaautoretenedores', operacion: 'contabilizar', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessContabilizar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    sucessContabilizar(data){
        this.getField('confirmModalCustom').toggle(false);
          if (data.estado_p === 200 )
          {
            if(data.data.contabilizadas!== undefined && data.data.contabilizadas.length>0){
                let index=0;
                this.mostrarMensajes(data.data.contabilizadas,index);
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Terminado.');
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.getField('modal_items').handleClose();this.gridOptionsComponentes.api.deselectAll(); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }
          }
          else 
          {
            //let respuesta=Object.values(data.data.errores);
            //this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Error contabilizando.');
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
          }
          
      }

    mostrarMensajes(data,index){
        if(index<data.length){
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data[index].mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.mostrarMensajes(data,index+1) });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }else{
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Terminado.');
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.getField('modal_items').handleClose();this.gridOptionsComponentes.api.deselectAll(); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-auditoriaautoretenedores', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.auditoria_autoretenedores_dias_devolver;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    validarCampos(){
        this.filaSeleccionada=[]
        this.getField('rejilla').toggle(false);
        this.getField('ver_devolucion').setVisible(false);
        let errores=0;
        this.limpiarErrores();
        let valFechas=this.validarfechas();
        if(!this.getField('codigo_sucursal').valid())
            errores++;
        if(!this.getField('nombre_sucursal').valid())
            errores++;
        if(valFechas===false){
            errores++;
        };

        if(errores===0){
            let op='';
            if (this.getField('ch_facturas').getValue()==='true') {
                op='rbventas';
            } else {
                op='devventas';
            }
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(() => {});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            this.getField('procesar').setDisabled(true);
            let datos={ datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                codigo_sucursal:this.getField('codigo_sucursal').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'cont-auditoriaautoretenedores', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successvalidarCampos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }
    onSelectionChanged(){
        this.filaSeleccionada = this.gridOptionsComponentes.api.getSelectedRows();
    }
    
    onSelectionChangedVacio(){

    }

    successvalidarCampos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datos={ datos: {}};
            let op='';
            if (this.getField('ch_facturas').getValue()==='true') {
                op='1_ventas_pag';
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            } else {
                op='1_devventas_pag';
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-auditoriaautoretenedores', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerDatos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else if(data.estado_p === 404){
            this.getField('rejilla').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            //this.limpiarCampos();
            this.limpiarTemporal();
        }
        else{
            this.getField('rejilla').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal();
        } 
    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('procesar').setDisabled(true);
        if(data.estado_p === 200){ 
            this.getField('rejilla').toggle(true);
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            configCell.set('numero', {cellRenderer:(props) => {return ""+props.data.numero }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('retencion', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.retencion) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('base', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.base) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);

            if (this.getField('ch_fras_de_ventas').getValue()==='true') {
                this.getField('ver_devolucion').setVisible(true);
            }else{
                this.getField('label_5').setVisible(true);
            }
           this.limpiarTemporal();
        }else if(data.estado_p === 404){
            this.getField('rejilla').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporal();
        }
        else{
            this.getField('rejilla').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal();
        } 
    }


    validarfechas(){
        this.getField('procesar').setDisabled(false);
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("fecha_desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("fecha_hasta").getValue()).toISOString().split('T')[0];
        this.getField('rejilla').toggle(false);
        this.getField('ver_devolucion').setVisible(false);
        this.getField('label_5').setVisible(false);

        this.getField("fecha_desde").setError(false,"");
        this.getField("fecha_hasta").setError(false,"");
        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_hasta > fecha_actual){
            this.getField("fecha_hasta").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }
        
        let fecha=new Date(fecha_actual);
        fecha.setDate(fecha.getDate()-this.dias_maximo);
        fecha=fecha.toISOString().split('T')[0];
        if(fecha_desde < fecha){
            this.getField("fecha_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        this.getField('procesar').setDisabled(false); 
        return true;

    }


    cargarFechas(){

        //let fecha_desde = this.getField('fecha_desde').getValue();
        //let fecha_desde_nueva = fecha_desde.split('-',2)[0]+'-'+fecha_desde.split('-',2)[1]+'-01';
        this.getField('fecha_desde').setValue(this.fecha_hoy);
    }

    crearTabla(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue()
        }};
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        this.generalFormatPmv = { tipo_servicio: 'cont-auditoriaautoretenedores', operacion: 'iva', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessCrearTabla,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    sucessCrearTabla(data){
          if (data.estado_p === 200 )
          {
            let datos={ datos: { }};
            this.generalFormatPmv = { tipo_servicio: 'cont-auditoriaautoretenedores', operacion: '1_iva_pag', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successvalidarCampos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
          }
          else 
          {
            
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.limpiarCampos();
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
          }
          
      }

    limpiarTemporal(){
        let datos={ datos: {
        }};
        let op='';
        if (this.getField('ch_facturas').getValue()==='true') {
            op='7_rbventas';
        } else {
            op='7_devventas';
        }
        this.generalFormatPmv = { tipo_servicio: 'cont-auditoriaautoretenedores', operacion: op, operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successDeleteTmp(data){
        //tmp_conta1_codigousuario
        if (data.estado_p === 200){
            //this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    limpiarCampos(){
        this.getField('ch_fras_de_ventas').setValue(true);
        this.getField('ch_facturas').setValue(false);
        this.getField('nombre_sucursal').setValue('');
        this.getField('codigo_sucursal').setValue('');
        this.getField('procesar').setDisabled(false);
        this.getField('fecha_desde').setValue(this.fecha_hoy);
        this.getField('fecha_hasta').setValue(this.fecha_hoy);
        this.getField('ver_devolucion').setVisible(false);
        this.limpiarErrores();
        this.cargarFechas();
    }

      limpiarErrores(){
        this.getField('nombre_sucursal').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('fecha_desde').setError(false,'');
        this.getField('fecha_hasta').setError(false,'');
      }

      currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
    

}
FormJaivana.addController("cont-auditoriaautoretenedores", CustomAuditoriaDeAutoretenedores);
export default CustomAuditoriaDeAutoretenedores;