import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomUsuariosOpciones
 * @author: Jose Albeiro Marin 
 * @updateBy Sebastian Rios E.
 * @version: jdesk_1.01.0001
 **/
class CustomUsuariosOpciones extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                     = this.initForm.bind(this);
        this.habilitarBtnSeleccionar                      = this.habilitarBtnSeleccionar.bind(this);
        this.validarSelectPrincipal                       = this.validarSelectPrincipal.bind(this);
        this.validarSelectSeccion                         = this.validarSelectSeccion.bind(this);
          
        this.cargarSelectModuloPrincipal                  = this.cargarSelectModuloPrincipal.bind(this);
        this.successCargarSelectModuloPrincipal           = this.successCargarSelectModuloPrincipal.bind(this);
        this.gridOptionsUsuariosPmv                       = Object.assign({},this.gridOptions);

        this.cargarUsuariosPmv                            = this.cargarUsuariosPmv.bind(this);
        this.successCargarUsuariosPmv                     = this.successCargarUsuariosPmv.bind(this);
        this.eliminarUsuario                              = this.eliminarUsuario.bind(this);
        this.confirmEliminar                              = this.confirmEliminar.bind(this);
        this.eliminar                                     = this.eliminar.bind(this);
        this.successEliminar                              = this.successEliminar.bind(this);
        this.cargarSelectSecciones                        = this.cargarSelectSecciones.bind(this);
        this.successCargarSelectSecciones                 = this.successCargarSelectSecciones.bind(this);
        this.cargarSelectOpciones                         = this.cargarSelectOpciones.bind(this);
        this.successCargarSelectOpciones                  = this.successCargarSelectOpciones.bind(this);
        this.habilitarCampos                              = this.habilitarCampos.bind(this);
        this.confirmarCancelarAgregar                     = this.confirmarCancelarAgregar.bind(this);
        this.cancelarDatosAgregar                         = this.cancelarDatosAgregar.bind(this);
        this.crearOpcionesPorUsuario                      = this.crearOpcionesPorUsuario.bind(this);
        this.successCrearOpcionesPorUsuario               = this.successCrearOpcionesPorUsuario.bind(this);
        this.ocultarTablaCampos                           = this.ocultarTablaCampos.bind(this);
        this.traerOpcionesPorPrincipalAndSeccion          = this.traerOpcionesPorPrincipalAndSeccion.bind(this);
        this.validarUniqueUsuariospmvId                   = this.validarUniqueUsuariospmvId.bind(this);
        this.successUniqueUsuariospmvId                   = this.successUniqueUsuariospmvId.bind(this);  
        

    }

    initForm(){
        console.log('Formulario Usuarios Opciones,  @version: jdesk_1.01.0001, @author: Jose Albeiro Marin, @updateBy: Sebastian Rios E.');
        this.getField('seleccionar').setDisabled(true);
        this.getField('opciones').setDisabled(true);
        this.getField('seccion').setDisabled(true);
        this.getField('estadosactivos_id').setDisabled(true);
        this.cargarSelectModuloPrincipal();

        this.getField("principal").setOnChange(this.validarSelectPrincipal);
        this.getField("seccion").setOnChange(this.validarSelectSeccion);
        this.getField("seleccionar").setClick(this.cargarUsuariosPmv);
        this.getField('usuariospmv_id').setOnChange(this.habilitarBtnSeleccionar);

    
        this.getField("btn_agregar").setClick(this.habilitarCampos);
        this.getField("aceptar").setClick(this.validarUniqueUsuariospmvId);
        this.getField("cancelar").setClick(this.confirmarCancelarAgregar);
        this.getField("seccion_id").setOnChange(this.traerOpcionesPorPrincipalAndSeccion);
        this.getField("secciones_padre_id").setOnChange(this.cargarSelectSecciones)



    }
    habilitarBtnSeleccionar() {

        if (this.getField('usuariospmv_id').getValue() !== '' && this.getField('usuariospmv').valid()) {
            this.getField('seleccionar').setDisabled(false);
        } else {

            this.getField('seleccionar').setDisabled(true);            
            this.getField('rejilla').toggle(false);
            this.getField('aceptar').setVisible(false);
            // this.RefrescarSelects();
            this.ocultarTablaCampos();
        }
    }
    validarSelectPrincipal(){
        if(this.getField('principal').getValue() !== '') {// si es ninguno entra al if 
          this.getField('principal').setError(false,'');
          this.getField('seccion').setDisabled(false);
          this.getField('seccion').setValue('');  
        }else{
            this.getField('principal').valid();
            this.getField('seccion').setDisabled(true)
            this.getField('seccion').setValue('');  
            this.getField('seccion').setError(false,'');

            this.getField('estadosactivos_id').setDisabled(true);
            this.getField('estadosactivos_id').setValue('');  
            this.getField('estadosactivos_id').setError(false,'');

        }
    }

    validarSelectSeccion(){
        if(this.getField('seccion').getValue() !== '') {
          this.getField('seccion').setError(false,'');
          this.getField('opciones').setDisabled(false)
          this.getField('estadosactivos_id').setValue('');  

        }else{
        //   this.getField('seccion').valid();
          this.getField('opciones').setDisabled(true);
          this.getField('opciones').setValue('');  
          this.getField('opciones').setError(false,'');

          this.getField('estadosactivos_id').setDisabled(true);
          this.getField('estadosactivos_id').setValue('');  
          this.getField('estadosactivos_id').setError(false,'');
        }
    }

    traerOpcionesPorPrincipalAndSeccion(){
        if(this.getField('seccion').getValue() !== '') {
            this.cargarSelectOpciones();
        }
    }

    ocultarTablaCampos(){
        this.getField('rejilla').toggle(false);
        this.getField('seleccionar').setDisabled(false);
        this.getField("btn_agregar").setVisible(false);
        this.getField("principal").setVisible(false);
        this.getField("seccion").setVisible(false);
        this.getField("opciones").setVisible(false);
        this.getField("estadosactivos_id").setVisible(false);
        this.getField("aceptar").setVisible(false);
        this.getField("cancelar").setVisible(false);
        this.getField("principal").setValue('');
        this.getField("seccion").setValue('');
        this.getField("opciones").setValue('');
        this.getField("estadosactivos_id").setValue('');
        this.getField("principal").setError(false,'');
        this.getField("seccion").setError(false,'');
        this.getField("opciones").setError(false,'');
        this.getField("estadosactivos_id").setError(false,'');
        this.getField('seleccionar').setDisabled(true);            

    }
    cargarSelectModuloPrincipal(){
        let datos={ datos: {} };
        this.generalFormatPmv = { tipo_servicio: '100', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarSelectModuloPrincipal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successCargarSelectModuloPrincipal(data){
        //console.log('entro al success cargar select');
        if(data.estado_p === 200){
            // console.log("dataSelectPrincipal",data);
            // this.getField('secciones_padre_id').setValue(data.data[0].id);
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.id;
            dataOp['text'] = item.nombre
            dataOp['campos_cambian'] = {principal_id: item.nombre, secciones_padre_id:item.id };
            opciones.push(dataOp); })
            this.getField('principal').setOptions(opciones);
        }
    }

    cargarSelectSecciones(){
        if (this.getField('principal').getValue() !== '' && this.getField('principal').valid() && this.getField('secciones_padre_id').getValue() !== '' && this.getField('secciones_padre_id').valid()) {

        let datos={ datos: {secciones_padre_id:this.getField('secciones_padre_id').getValue()} };
        this.generalFormatPmv = { tipo_servicio: '100', operacion: '1_3', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarSelectSecciones,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    }

    successCargarSelectSecciones(data){
        //console.log('entro al success cargar select');
        if(data.estado_p === 200){
            // console.log("dataSelectSecciones",data);
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.id;
            dataOp['text'] = item.nombre
            dataOp['campos_cambian'] = {seccion_id: item.nombre  };
            opciones.push(dataOp); })
            this.getField('seccion').setOptions(opciones);
        }else if(data.estado_p === 404){
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            let dataOp = {}
            dataOp['value'] = this.getField('secciones_padre_id').getValue();
            dataOp['text'] = this.getField('principal_id').getValue()
            dataOp['campos_cambian'] = {seccion_id: this.getField('principal_id').getValue()  };
            opciones.push(dataOp);
            this.getField('seccion').setOptions(opciones);
        }else{
            this.getField('seccion').setDisabled(true);
        }
    }

    cargarSelectOpciones(){
        let datos={ datos: {
            secciones_id: Number(this.getField("seccion").getValue())
        } };
        this.generalFormatPmv = { tipo_servicio: '101', operacion: '1_4', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarSelectOpciones,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successCargarSelectOpciones(data){
        // console.log("Opciones",data);
        
        if(data.estado_p === 200 && data.data.length >= 1){   
            this.getField('opciones').setDisabled(false)
            this.getField('estadosactivos_id').setDisabled(false)

            // console.log("dataSelectOpciones",data);
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.id;
            dataOp['text'] = item.nombre_formulario
            dataOp['campos_cambian'] = {formulariosjdesk_id: item.id };
            opciones.push(dataOp); })
            this.getField('opciones').setOptions(opciones);
        }else if(data.estado_p === 404){
            this.getField('opciones').setDisabled(true)
            this.getField('opciones').setValue("");
            this.getField('estadosactivos_id').setDisabled(true)
            this.getField('estadosactivos_id').setValue("");
        }
    }

    cargarUsuariosPmv(){
        if(this.getField('usuariospmv_id').valid()){
        let datos={ 
            datos: {
                usuariospmv_id: this.getField("usuariospmv_id").getValue()
            }
        };
        this.generalFormatPmv = { tipo_servicio:  'heus-usuariosopciones', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successCargarUsuariosPmv,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        })
        }else{
        this.getField('usuariospmv').setError(true, '* Este campo es requerido.');
        }
    }

    successCargarUsuariosPmv(data){
        this.getField('btn_agregar').setVisible(true);
        // console.log("polizas",data);
        if(data.estado_p===200) {
            this.getField("btn_agregar").setVisible(true);
            this.getField('seleccionar').setDisabled(true);
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsUsuariosPmv['rowData'] = data.data;
            configCell.set('accion', { cellRenderer: this.eliminarUsuario, width: 110, sortable: false, filter: false, field: 'Acción' });
            configCell.set('descripcion', { headerName: 'Opción' }); 
            configCell.set('principal', { headerName: 'Módulo principal' }); 
            configCell.set('seccion', { headerName: 'Sección' }); 
            this.getField('rejilla').initData(this.gridOptionsUsuariosPmv, configCell);
        }else{
            this.getField('rejilla').toggle(false);
        }
        if(data.estado_p===404) {
            this.getField("btn_agregar").setVisible(true);
            this.getField('rejilla').toggle(false);
            this.getField('seleccionar').setDisabled(true);
        }
    }

    eliminarUsuario(props){
        // console.log("props",props)
        let jupo_id = props.data.jupo_id;
        let button = document.createElement("input");
        button.onclick = () => this.confirmEliminar(jupo_id);
        button.setAttribute("id", 'button_delete_' + jupo_id);
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        button.setAttribute("class","buttonStyle2");
        return this.createElementJaivana(button);

    }

    confirmEliminar(jupo_id){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(() => {this.eliminar(jupo_id);});
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminar(jupo_id){
        this.getField('confirmModalCustom').toggle(false);
            let datos={ 
                datos: {
                    jdeskusuariospmvopciones_id: jupo_id
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'heus-usuariosopciones', operacion: '7', operacion_tipo: 'eliminar'};
            this.service.send(
            {
                endpoint:this.constant.formConfiguration(),
                method:'DELETE',
                body:datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successEliminar(data){
        if (data.estado_p === 200) {
            this.cargarSelectModuloPrincipal();
            this.cargarSelectSecciones();
            this.cargarUsuariosPmv();
            this.cancelarDatosAgregar();
        }
    }
    habilitarCampos(){
        this.getField("principal").setVisible(true);
        this.getField("seccion").setVisible(true);
        this.getField("opciones").setVisible(true);
        this.getField("estadosactivos_id").setVisible(true);
        this.getField("btn_agregar").setVisible(false);
        this.getField("aceptar").setVisible(true);
        this.getField("cancelar").setVisible(true);
    }

    confirmarCancelarAgregar(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia','Desea realizar esta operación?');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField('confirmModalCustom').setClickDialog(()=>{
            this.cancelarDatosAgregar();
            this.getField('rejilla').toggle(false);
            this.getField("usuariospmv").setValue('');
            this.getField("usuariospmv").setError(false,'');
            this.getField("nombre").setValue('');
            this.getField("nombre").setError(false,'');
        });
        this.getField('confirmModalCustom').toggle(true);    
    }

    cancelarDatosAgregar(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField("principal").setVisible(false);
        this.getField("seccion").setVisible(false);
        this.getField("opciones").setVisible(false);
        this.getField("estadosactivos_id").setVisible(false);
        this.getField("btn_agregar").setVisible(true);
        this.getField("aceptar").setVisible(false);
        this.getField("cancelar").setVisible(false);
        this.getField("principal").setValue('');
        this.getField("seccion").setValue('');
        this.getField("opciones").setValue('');
        this.getField("estadosactivos_id").setValue('');
        this.getField("principal").setError(false,'');
        this.getField("seccion").setError(false,'');
        this.getField("opciones").setError(false,'');
        this.getField("estadosactivos_id").setError(false,'');

    }

    validarUniqueUsuariospmvId(){
        if(this.getField('principal').valid() && this.getField('seccion').valid() &&
        this.getField('opciones').valid() && this.getField('estadosactivos_id').valid()){
            let datos = { datos: {
                usuariospmv_id: this.getField('usuariospmv_id').getValue(),
                formulariosjdesk_id: this.getField('formulariosjdesk_id').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-usuariosopciones', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successUniqueUsuariospmvId,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            });
        }
    }

    successUniqueUsuariospmvId(data){
        // console.log("validarusuariopmv",data);
        if(data.estado_p === 200){
            this.alertGeneral.toggle(true, 'El usuariospmv_id y formulariosjdesk_id ingresado ya existe, no se permite duplicar', "error");
        }else if(data.estado_p === 404){
            this.crearOpcionesPorUsuario();
        }
    }

    crearOpcionesPorUsuario(){
        if(this.getField('principal').valid() && this.getField('seccion').valid() &&
           this.getField('opciones').valid() && this.getField('estadosactivos_id').valid()){
            let datos = { datos: {
              usuariospmv_id:this.getField('usuariospmv_id').getValue(),
              formulariosjdesk_id:this.getField('formulariosjdesk_id').getValue(),
              estadosactivos_id:this.getField("estadosactivos_id").getValue()

            }};
            
            this.generalFormatPmv = { tipo_servicio: 'heus-usuariosopciones', operacion: '5', operacion_tipo: 'crear' };
            this.datos_anteriores=this.generalFormatPmv;
            this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'POST',
                      body: datos,
                      success: this.successCrearOpcionesPorUsuario,
                      error: this.error_,
                      general: this.generalFormatPmv
              });
        }
        
    }
    successCrearOpcionesPorUsuario(data){
        // console.log("data",data)
        if(data.estado_p === 200){
            this.cargarSelectModuloPrincipal();
            this.cargarSelectSecciones();
            this.cargarUsuariosPmv();
            this.cancelarDatosAgregar();
        }
    }
}
FormJaivana.addController("heus-usuariosopciones",CustomUsuariosOpciones);
export default CustomUsuariosOpciones;