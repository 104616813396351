import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomCuentasBancariasTerceros
 * @author: Anderson Acevedo Briñez
 * @updateBy Anderson Acevedo
 * @version: jdesk_1.01.0001
 **/
class CustomCuentasBancariasTerceros extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.successGuardarCuenta = this.successGuardarCuenta.bind(this);
    this.guardarCuenta = this.guardarCuenta.bind(this);
    this.eliminarCuenta = this.eliminarCuenta.bind(this);
    this.successEliminarCuenta = this.successEliminarCuenta.bind(this);
    this.buscarCuentasBancariasTerceros =this.buscarCuentasBancariasTerceros.bind(this);
    this.succesConsulta  = this.succesConsulta.bind(this);
	  this.mostrarCampos	=	this.mostrarCampos.bind(this);
    this.ocultarCampos =  this.ocultarCampos.bind(this);
    this.ocultarCamposCancelar  = this.ocultarCamposCancelar.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                             =   this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']         =   this.onSelectionChanged;

    this.setButtonDelete = this.setButtonDelete.bind(this);
    this.confirmEliminarCuenta	=	this.confirmEliminarCuenta.bind(this);
    this.validarUnique	=	this.validarUnique.bind(this);
    this.succesConsultaUnique	=	this.succesConsultaUnique.bind(this);
    this.contiene_registros=false;
  }

  initForm() {
    console.log("Formulario CustomCuentasBancariasTerceros,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez, @updateBy Anderson Acevedo");
    this.getField('seleccionar').setClick(this.buscarCuentasBancariasTerceros);
	  this.getField('terceros_id').setOnChange((props)=>{
        if(this.getField('terceros_id').getValue()!==''){
          this.getField('seleccionar').setDisabled(false);
          this.ocultarCampos();
        }else{
          this.getField('seleccionar').setDisabled(true);
          this.ocultarCampos();
        }
    });
    this.getField('agregar').setClick(this.mostrarCampos);
    this.getField('aceptar').setClick(this.validarUnique);
    this.getField('cancelar').setClick(this.ocultarCamposCancelar);
    this.getField('seleccionar').setDisabled(true);
    this.ocultarCampos();
  }

  onSelectionChanged(){
    let rowDataTable = this.gridOptionsComponentes.api.getSelectedRows();
    this.getField('terceros_id').setValue(rowDataTable[0].terceros_id);
    this.getField('bancos_cuentas_terceros_id').setValue(rowDataTable[0].bancos_cuentas_terceros_id);
}

  mostrarCampos(){
    this.getField('agregar').setDisabled(true);
    this.getField('codigo_bancos_oficinas').setVisible(true);
    this.getField('nombre_banco_oficina').setVisible(true);
    this.getField('bancos_tipocuenta_id').setVisible(true);
    this.getField('numero_cuenta').setVisible(true);
    this.getField('certificacion_bancaria').setVisible(true);
    this.getField('aceptar').setVisible(true);
    this.getField('cancelar').setVisible(true);
  }
  
  ocultarCampos(){
    this.getField('rejilla').toggle(false);
    this.getField('agregar').setVisible(false);
    this.getField('codigo_bancos_oficinas').setVisible(false);
    this.getField('nombre_banco_oficina').setVisible(false);
    this.getField('bancos_tipocuenta_id').setVisible(false);
    this.getField('numero_cuenta').setVisible(false);
    this.getField('certificacion_bancaria').setVisible(false);
    this.getField('aceptar').setVisible(false);
    this.getField('cancelar').setVisible(false);

    this.getField('codigo_bancos_oficinas').setValue('');
    this.getField('nombre_banco_oficina').setValue('');
    this.getField('bancos_tipocuenta_id').setValue('');
    this.getField('numero_cuenta').setValue('');
    this.getField('certificacion_bancaria').setValue('');

  }

  ocultarCamposCancelar(){
    this.getField('agregar').setVisible(false);
    if(this.contiene_registros===true){
      this.getField('agregar').setDisabled(false);
      this.getField('agregar').setVisible(true);
      this.getField('codigo_bancos_oficinas').setVisible(false);
      this.getField('nombre_banco_oficina').setVisible(false);
      this.getField('bancos_tipocuenta_id').setVisible(false);
      this.getField('numero_cuenta').setVisible(false);
      this.getField('certificacion_bancaria').setVisible(false);
      this.getField('aceptar').setVisible(false);
      this.getField('cancelar').setVisible(false);
    }else{
      this.getField('agregar').setVisible(false);
    }
    this.getField('codigo_bancos_oficinas').setValue('');
    this.getField('nombre_banco_oficina').setValue('');
    this.getField('bancos_tipocuenta_id').setValue('');
    this.getField('numero_cuenta').setValue('');
    this.getField('certificacion_bancaria').setValue('');

  }

  buscarCuentasBancariasTerceros(){
    if(this.getField('nit').valid()
        && this.getField('nombre').valid()
        && this.getField('sede').valid()){
        this.ocultarCampos();
        let datos={ 
            datos: {
                terceros_id:this.getField('terceros_id').getValue(),  
            }};
        this.generalFormatPmv = { tipo_servicio: 'maes-cuentasbancariasterceros', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesConsulta,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
  }

  succesConsulta(data){
      if (data.estado_p === 200 )
      {
          this.contiene_registros=true;
          this.getField('seleccionar').setDisabled(true);
          this.getField('agregar').setDisabled(false);
          this.getField('agregar').setVisible(true);
          this.getField('rejilla').toggle(true);
          let configCell = new Map();
          configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'eliminar' });

          this.gridOptionsComponentes['rowData'] = data.data;
          this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
      }
      else 
      {
		  if (data.estado_p === 404 ){
        this.contiene_registros=false;
			  this.getField('rejilla').toggle(false);
			  this.getField('agregar').setVisible(false);
			  this.getField('agregar').setDisabled(true);
			  this.mostrarCampos();
		  }else{
			  this.getField('rejilla').toggle(false);
			  let respuesta=Object.values(data.data.errores);
			  let keys=Object.keys(data.data.errores);
			  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error'); 
		  }
      };
  }
  
  validarUnique(){
    if(this.getField('codigo_bancos_oficinas').valid()&&
      this.getField('nombre_banco_oficina').valid()&&
      this.getField('bancos_tipocuenta_id').valid() &&
	  this.getField('numero_cuenta').valid() &&
      this.getField('certificacion_bancaria').valid()){
        let datos={ 
            datos: {
              bancos_oficinas_id:this.getField('bancos_oficinas_id').getValue(),  
				        numero_cuenta:this.getField('numero_cuenta').getValue()
            }};
        this.generalFormatPmv = { tipo_servicio: 'maes-cuentasbancariasterceros', operacion: '20', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesConsultaUnique,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
  }

  succesConsultaUnique(data){
      if (data.estado_p === 200 )
      {
        this.alertGeneral.toggle(true, 'Ya éxiste la relación bancos_oficinas y numero_cuenta.', 'error');
      }
      else 
      {
        if(data.estado_p===404){
          this.guardarCuenta();
        }else{
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      };
  }

  guardarCuenta(){
    if(this.getField('codigo_bancos_oficinas').valid()&&
      this.getField('nombre_banco_oficina').valid()&&
      this.getField('bancos_tipocuenta_id').valid() &&
	    this.getField('numero_cuenta').valid() &&
      this.getField('certificacion_bancaria').valid()){
        let datos = { datos: {
          banco_id:this.getField('banco_id').getValue(),
          bancos_tipocuenta_id:this.getField('bancos_tipocuenta_id').getValue(),
          numero_cuenta:this.getField('numero_cuenta').getValue(),
          certificacion_bancaria:this.getField('certificacion_bancaria').getValue(),
          terceros_id:this.getField('terceros_id').getValue(),
          bancos_oficinas_id:this.getField('bancos_oficinas_id').getValue(),
          estadoactivo_id:this.getField('estadoactivo_id').getValue(),
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-cuentasbancariasterceros', operacion: '5', operacion_tipo: 'crear' };
        this.datos_anteriores=this.generalFormatPmv;
        this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.successGuardarCuenta,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }
  }

  successGuardarCuenta(data) 
  {

    if (data.estado_p === 200)
    {
        this.ocultarCampos();
        this.buscarCuentasBancariasTerceros();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  setButtonDelete(props) {
      let id = props.data.bancos_cuentas_terceros_id;
      let button = document.createElement("input");
      button.onclick = () => this.confirmEliminarCuenta(id);
      button.setAttribute("id", 'button_delete_' + id);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("type", "button");
      button.setAttribute("value", "eliminar");
      return this.createElementJaivana(button);
  }

  confirmEliminarCuenta(id) {
    this.ids = id;
    this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
    this.getField('confirmModalCustom').setClickDialog(this.eliminarCuenta);
    this.getField('confirmModalCustom').toggle(true);
  }
  
  eliminarCuenta(){
    this.getField('confirmModalCustom').toggle(false);
    if(this.getField('nit').valid()&&
		this.getField('sede').valid()&&
		this.getField('nombre').valid()&&
		this.getField('bancos_cuentas_terceros_id').valid()){
        let datos = { datos: {
          bancos_cuentas_terceros_id:this.getField('bancos_cuentas_terceros_id').getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-cuentasbancariasterceros', operacion: '7', operacion_tipo: 'eliminar' };
        
        
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminarCuenta,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }else{
      this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
    }
  }

  successEliminarCuenta(data) 
  {
   
    if (data.estado_p === 200)
    {
        this.buscarCuentasBancariasTerceros();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

}
FormJaivana.addController("maes-cuentasbancariasterceros", CustomCuentasBancariasTerceros);
export default CustomCuentasBancariasTerceros;