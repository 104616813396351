import FormJaivana from "dashboard_jaivana_v1";
/**
 *
 * @author Anderson Acevedo Briñez
 */
/**
 * Custom  CustomAnexosAlBalance
 **/
class CustomAnexosAlBalance extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.procesar                     = this.procesar.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.validarCampos = this.validarCampos.bind(this);
    this.llenarImpresoras = this.llenarImpresoras.bind(this);
    this.validarFechas = this.validarFechas.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.consultarFechas = this.consultarFechas.bind(this);
    this.consultarFechasCentroCostos = this.consultarFechasCentroCostos.bind(this);
    this.sucessConsultarFechasCentroCostos = this.sucessConsultarFechasCentroCostos.bind(this);
    this.sucessConsultarFechas = this.sucessConsultarFechas.bind(this);
    this.consultarFechasMayorisa = this.consultarFechasMayorisa.bind(this);
    this.sucessConsultarFechasMayorista = this.sucessConsultarFechasMayorista.bind(this);
    this.generarPdfTabla3 = this.generarPdfTabla3.bind(this);
    this.generarModal = this.generarModal.bind(this);
    this.successGenerarModal = this.successGenerarModal.bind(this);
    this.generarExcelTabla3 = this.generarExcelTabla3.bind(this);
    this.enviarCorreo = this.enviarCorreo.bind(this);
    this.successEnviarCorreo = this.successEnviarCorreo.bind(this);
    this.limpiarErrores = this.limpiarErrores.bind(this);
    this.operacion_actual='';
    this.llenarConsulta = this.llenarConsulta.bind(this);
    this.mismoaniodiferentemes = this.mismoaniodiferentemes.bind(this);
    this.successmismoaniodiferentemes = this.successmismoaniodiferentemes.bind(this);
    this.consultarFechasMayordiferente = this.consultarFechasMayordiferente.bind(this);
    this.sucessConsultarFechasMayordiferente = this.sucessConsultarFechasMayordiferente.bind(this);
    this.bloquearbotones = this.bloquearbotones.bind(this);
    this.deshabilitarCorreo = this.deshabilitarCorreo.bind(this);
    this.habilitarCorreo = this.habilitarCorreo.bind(this);
    this.fecha_inicio='';
    this.fecha_actual='';
  }

  initForm() {
    console.log("Formulario CustomAnexosAlBalance,  @version: jdesk_10.0.001, @author: Anderson Acevedo Briñez, @UpdateBy: Jose Albeiro Marin");
    this.llenarConsulta();
    this.getField('total_saldo_anterior').setDisabled(true);
    this.getField('total_final').setDisabled(true);
    this.getField('total_credito').setDisabled(true);
    this.getField('total_debito').setDisabled(true);
    this.getField('radio_correo').setValue("N");
    this.getField('email').setDisabled(true);
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
      dia='0'+dia;
    }
    this.fecha_inicio=año+'-'+mes+'-01';
    this.fecha_actual=año+'-'+mes+'-'+dia;
    this.getField('fecha_desde').setValue(this.fecha_inicio);
    this.getField('fecha_desde').setOnChange(()=>{
      this.validarFechas();
    });
    this.getField('fecha_hasta').setOnChange(()=>{
      this.validarFechas();
    });
    /*this.getField('codigo_sucursal').setKeyUp((props)=>{
      this.getField('codigo_sucursal').setError(false,'');
      if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
        this.getField('ch_sucursales').setDisabled(true);
        this.getField('ch_sucursales').setValueSwitch(false);
        this.getField('codigo_sucursal').valid();
      }else{
        this.getField('ch_sucursales').setDisabled(false);
        this.getField('ch_sucursales').setValueSwitch(true);
      }
    });*/
    this.llenarImpresoras();
    
    this.getField('cuenta_inicial').setKeyUp((props)=>{
      this.getField('cuenta_inicial').setError(false,'');
      this.getField('cuenta_final').setError(false,'');
      if((this.getField('cuenta_inicial').getValue()!=='' && this.getField('cuenta_inicial').getValue()!==' ')
      || (this.getField('cuenta_final').getValue()!=='' && this.getField('cuenta_final').getValue()!==' ')){
        this.getField('ch_cuentas').setDisabled(true);
        this.getField('ch_cuentas').setValueSwitch(false);
        this.getField('cuenta_inicial').valid();
        this.getField('cuenta_inicial').changeKeyUp(props);
      }else{
        this.getField('ch_cuentas').setDisabled(false);
        this.getField('ch_cuentas').setValueSwitch(true);
      }
    });

    this.getField('cuenta_final').setKeyUp((props)=>{
      this.getField('cuenta_final').setError(false,'');
      this.getField('cuenta_inicial').setError(false,'');
      if((this.getField('cuenta_final').getValue()!=='' && this.getField('cuenta_final').getValue()!==' ')
        || (this.getField('cuenta_inicial').getValue()!=='' && this.getField('cuenta_inicial').getValue()!==' ')){
          this.getField('ch_cuentas').setDisabled(true);
          this.getField('ch_cuentas').setValueSwitch(false);
          this.getField('cuenta_final').valid();
          this.getField('cuenta_final').changeKeyUp(props);
        }else{
          this.getField('ch_cuentas').setDisabled(false);
          this.getField('ch_cuentas').setValueSwitch(true);

        }
        
    });
    this.getField('ch_excel').setOnChange((props)=>{
      if(this.getField('ch_excel').getValue()==='false'){
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='excel';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pdf').setOnChange((props)=>{
      if(this.getField('ch_pdf').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='pdf';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pantalla').setOnChange((props)=>{
      if(this.getField('ch_pantalla').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.operacion_actual='pantalla';
        this.deshabilitarCorreo();
      }else{
        this.getField('radio_correo').setDisabled(false);
      }
    });

    this.getField('select_consulta').setOnChange(this.bloquearbotones);
    //this.getField('ch_cuentas').setValueSwitch(true);
    //this.getField('ch_sucursales').setValue(true);
    this.getField('procesar').setClick(this.validarCampos);
    
    this.getField('radio_correo').setOnChange(()=>{
      if(this.getField("radio_correo").getValue()==='S'){
        this.getField('email').setDisabled(false);
      }else{
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
      }
    })
  }

  deshabilitarCorreo(){
    this.getField('radio_correo').setValue("N");
    this.getField('radio_correo').setDisabled(true);
    this.getField('email').setDisabled(true);
    this.getField('email').setValue('');
    this.getField('email').setError(false,'');
  }

  habilitarCorreo(){
    this.getField('radio_correo').setDisabled(false);
  }

  llenarConsulta()
  {
      let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
      let data2 = {
          "data": [
              {
                  "codigo": '1',
                  "descripcion": 'Mismo año, mismo mes',
              },
              {
                  "codigo": '2',
                  "descripcion": 'Mismo año, diferente mes',
              }
          ]
      }
      data2.data.forEach(item => {
          let dataOp = {}
          dataOp['value'] = item.codigo;
          dataOp['text'] = item.descripcion;
          dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
          opciones.push(dataOp);
      });
      this.getField('select_consulta').setOptions(opciones);
  }

  bloquearbotones(){

    if(this.getField('select_consulta').getValue()==='1'){
      //console.log("bloquearbotonesva va en false el primer ch de centro costo para la op 1----> 1 ");
      this.getField('ch_pantalla').setDisabled(false);      
      this.getField('ch_pdf').setDisabled(false);
      this.getField('ch_excel').setDisabled(false);      
      this.getField('ch_excel').setValueSwitch(false);
      this.getField('ch_cuentas').setValueSwitch(true);      
      this.getField('ch_centros_costo').setDisabled(false);// habilitar centro costo en codición 1
      this.getField('ch_centros_costo').setValueSwitch(false);
      this.getField('radio_correo').setValue('N');
      this.getField('email').setDisabled(true);
      this.getField('radio_correo').setDisabled(false);
    }else if(this.getField('select_consulta').getValue()==='2'){
      this.getField('ch_pantalla').setDisabled(true);
      this.getField('ch_pdf').setDisabled(true);
      this.getField('ch_pantalla').setValueSwitch(false);
      this.getField('ch_pdf').setValueSwitch(false);
      this.getField('ch_excel').setDisabled(true);
      this.getField('ch_excel').setValueSwitch(true);
      this.getField('ch_cuentas').setValueSwitch(true);
      this.getField('ch_centros_costo').setValueSwitch(false);// si entra por la condición 2 de la consulta centro costo en off      
      this.getField('ch_centros_costo').setDisabled(true);// inhabilitar centro costo en codición 2
      this.operacion_actual='excel';
      this.getField('radio_correo').setValue('S');
      this.getField('radio_correo').setDisabled(true);
    }else if(this.getField('select_consulta').getValue()===''){
      //console.log("bloquearbotones va va en true en la condición de selecion de consulta costo para la op 1----> 2 ");
      this.getField('ch_pantalla').setDisabled(false);
      this.getField('ch_pdf').setDisabled(false);
      this.getField('ch_excel').setDisabled(false);
      this.getField('ch_excel').setValueSwitch(false);
      this.getField('ch_pdf').setValueSwitch(false);
      this.getField('ch_cuentas').setValueSwitch(true);
      this.getField('ch_centros_costo').setValueSwitch(false); // esta no es necesaria porque el select de consulta es requerido entonces se debe seleccionar si o si una opción
      this.getField('ch_pantalla').setValueSwitch(false);
      this.getField('radio_correo').setValue('N');
      this.getField('email').setDisabled(true);
      this.getField('radio_correo').setDisabled(false);
    }

  }
  
  validarFechas(){
    //let fecha_actual = new Date();
    let fecha_desde = new Date(this.getField("fecha_desde").getValue());
    let fecha_hasta = new Date(this.getField("fecha_hasta").getValue());

    this.getField("fecha_desde").setError(false,"");
    this.getField("fecha_hasta").setError(false,"");

    /*if(fecha_desde > fecha_actual){
        this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
        return false;
    }

    if(fecha_hasta > fecha_actual){
        this.getField("fecha_hasta").setError(true,"No puede ser mayor a la fecha actual.");
        return false;
    }*/

    if(this.getField('select_consulta').getValue()==='1'){
        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }

        let mes_desde = new Date(this.getField('fecha_desde').getValue()).toISOString().split('-')[1];
        let mes_hasta = new Date(this.getField('fecha_hasta').getValue()).toISOString().split('-')[1];
        if(mes_desde!==mes_hasta){
          this.getField('fecha_hasta').setError(true, "¡La fechas tienen que ser del mismo mes!");
          return false;
        }

        if (fecha_hasta >= fecha_desde) {
          if (fecha_hasta !== '' && this.getField('fecha_desde').getValue().replace(/\u0020/g,'') !== '') {
              let fechaDesdeFormateada = this.getField('fecha_desde').getValue();
              let fechaHastaoFormateada = this.getField('fecha_hasta').getValue();
              let fechaRango = new Date(fechaHastaoFormateada) - new Date(fechaDesdeFormateada);
              let diferencia = fechaRango / 1000 / 31 / 60 / 24;
              if (Math.sign(diferencia) === -1) {
                  diferencia = Math.round(parseInt(diferencia.toString().slice(1)));
                }
              if (diferencia > 60) {
                  this.getField('fecha_desde').setError(true, "¡La fecha debe ser menor o igual a 30 días!");
                  this.errorfecha = true;
                  return false;
              }else{
                  this.errorfecha = false;
              }
          }
      }
        return true; 

    }else if(this.getField('select_consulta').getValue()==='2'){

        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }

        if (fecha_hasta >= fecha_desde) {
          if (fecha_hasta !== '' && this.getField('fecha_desde').getValue().replace(/\u0020/g,'') !== '') {
              let anio_desde = this.getField("fecha_desde").getValue().slice(0, 4);
              let anio_hasta = this.getField("fecha_hasta").getValue().slice(0, 4);
              if (parseInt(anio_desde) < parseInt(anio_hasta)) {
                  this.getField('fecha_desde').setError(true, "¡Los años deben ser iguales!");
                  this.getField('fecha_hasta').setError(true, "¡Los años deben ser iguales!");
                  this.errorfecha = true;
                  return false;
              }else{
                  this.errorfecha = false;
              }
          }
        }

        return true; 
    }
}

  llenarImpresoras()
  {
      let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
      let data2 = {
          "data": [
              {
                  "codigo": '1',
                  "descripcion": 'Ho 1',
              },
              {
                  "codigo": '2',
                  "descripcion": 'Dell 1',
              },{
                  "codigo": '3',
                  "descripcion": 'Dell 2',
              }
          ]
      }
      data2.data.forEach(item => {
          let dataOp = {}
          dataOp['value'] = item.codigo;
          dataOp['text'] = item.descripcion;
          dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
          opciones.push(dataOp);
      });
      this.getField('select_impresora').setOptions(opciones);
  }

  validarCampos(){
    this.limpiarErrores();
    let errores =0;
    
    if(this.getField('select_consulta').valid()===false || this.getField('select_consulta').getValue()===''){
      errores++;
    }

    let valFechas=this.validarFechas();
    if(valFechas===false){
      errores++;
    };

    /*if(this.getField('select_impresora').valid()===false){
      errores++;
    }*/

    if(this.getField('cuenta_inicial').getValue()!=='' || this.getField('cuenta_final').getValue()!==''){
      if(this.getField('cuenta_inicial').valid() && this.getField('cuenta_final').valid()){
          if(this.getField('cuenta_final_id').getValue()!=='' && this.getField('cuenta_final_id').getValue()!==' ' ){
              this.getField('cuenta_final').setError(false,'');
          }else{
            errores++;
            this.getField('cuenta_final').setError(true,'* Es necesario realizar la busquedad.');
          }
          if(this.getField('cuenta_inicial_id').getValue()!=='' && this.getField('cuenta_inicial_id').getValue()!==' ' ){
            this.getField('cuenta_inicial').setError(false,'');
          }else{
            errores++;
            this.getField('cuenta_inicial').setError(true,'* Es necesario realizar la busquedad.');
          }
      }else{
        errores++;
      }
    }
    if(this.getField('radio_correo').getValue()==='S'){
      if(this.getField('email').valid()===false){
        errores++;
      }
    }
    /*if(this.getField('centro_costo_codigo').valid()===false || this.getField('centro_costo_nombre').valid()===false){
      errores++;
    }*/

    if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
        errores++;
        this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
        this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
        this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
        this.operacion_actual='';
        this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
    }
    if(errores===0){
      this.procesar();
    }
  }


  procesar(){
      this.getField('procesar').setDisabled(true);
      //mismo año, mismo mes todas las sucursales y centro de costos
      if(this.getField('select_consulta').getValue()==='1' && this.getField('ch_centros_costo').getValue()==='true' ){
        //console.log("se fue por consulta 1 con centro detallado en true ");        
        let año_desde=this.getField('fecha_desde').getValue().split('-')[0];
        let año_hasta=this.getField('fecha_hasta').getValue().split('-')[0];
        let mes_desde=this.getField('fecha_desde').getValue().split('-')[1];
        let mes_hasta=this.getField('fecha_hasta').getValue().split('-')[1];
        if(año_desde===año_hasta 
          && mes_desde===mes_hasta 
          && this.getField('ch_centros_costo').getValue()==='true'){
            this.consultarFechasCentroCostos();
        }
      }else if(this.getField('select_consulta').getValue()==='1'){
        //mismo año ,mismo mes todas las sucursales
        //console.log("se fue por consulta 1 sin centro detallo ");
        let año_desde=this.getField('fecha_desde').getValue().split('-')[0];
        let año_hasta=this.getField('fecha_hasta').getValue().split('-')[0];
        let mes_desde=this.getField('fecha_desde').getValue().split('-')[1];
        let mes_hasta=this.getField('fecha_hasta').getValue().split('-')[1];
        if(año_desde===año_hasta 
          && mes_desde===mes_hasta 
          /*&& this.getField('ch_sucursales').getValue()==='true'*/){
            this.consultarFechas();
        }      
      }else if(this.getField('select_consulta').getValue()==='2'){
        if(this.getField('radio_correo').getValue()==='S'){
          this.mismoaniodiferentemes();
        }else{
          this.getField('procesar').setDisabled(false);
          this.alertGeneral.toggle(true, 'Solo es posible enviar el anexo mediante email.', 'error');
        }
      }  
  }



  limpiarCampos(){
    this.getField('radio_correo').setValue("N");
    /*this.getField('codigo_sucursal').setValue('');
    this.getField('cuenta_inicial').setValue('');
    this.getField('cuenta_final').setValue('');
    this.getField('ch_centros_costo').setValueSwitch(false);
    this.getField('centro_costo_codigo').setValue('');
    this.getField('ch_cierre').setValueSwitch(false);
    this.getField('ch_tercero').setValueSwitch(false);
    this.getField('centro_costo_nombre').setValue('');*/
    this.getField('email').setValue('');
    this.getField('select_impresora').setValue('');
    this.getField('select_consulta').setValue('');
    this.getField('ch_excel').setValueSwitch(false);
    this.getField('ch_pdf').setValueSwitch(false);
    this.getField('ch_pantalla').setValueSwitch(false);
    this.getField('procesar').setDisabled(false);
    this.getField('fecha_desde').setValue(this.fecha_inicio);
    this.getField('fecha_hasta').setValue(this.fecha_actual);
  }

  limpiarErrores(){

    /*this.getField('codigo_sucursal').setError(false,'');
    this.getField('cuenta_inicial').setError(false,'');
    this.getField('cuenta_final').setError(false,'');
    this.getField('centro_costo_codigo').setError(false,'');
    this.getField('centro_costo_nombre').setError(false,'');*/
    this.getField('email').setError(false,'');
    this.getField('select_impresora').setError(false,'');
    this.getField('select_consulta').setError(false,'');
    this.getField('fecha_desde').setError(false,'');
    this.getField('fecha_hasta').setError(false,'');
  }

  consultarFechas(){
    if(this.getField('radio_correo').getValue()==='S'){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información a enviar por correo... `);
    }else{
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
    }
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      this.getField('procesar').setDisabled(true);
      let datos={ datos: {
          fecha_desde: this.getField('fecha_desde').getValue(),
          fecha_hasta: this.getField('fecha_hasta').getValue()
        }};
      
      this.generalFormatPmv = { tipo_servicio: 'cont-anexosbalance', operacion: 'mismoaniomes', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessConsultarFechas,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  sucessConsultarFechas(data){
      if (data.estado_p === 200 )
      {
        this.consultarFechasMayorisa(); 
      }
      else 
      {
        if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'No se encontro.', 'error');
        }
        else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
        this.getField('procesar').setDisabled(false);
        this.getField('confirmModalCustom').toggle(false);
      }  
  }

  consultarFechasCentroCostos(){
    if(this.getField('radio_correo').getValue()==='S'){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información a enviar por correo... `);
    }else{
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
    }
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      this.getField('procesar').setDisabled(true);
      let datos={ datos: {
          fecha_desde: this.getField('fecha_desde').getValue(),
          fecha_hasta: this.getField('fecha_hasta').getValue(),
          centro_costo: this.getField('ch_centros_costo').getValue(),
          //centro_costo: this.getField('maneja_movimiento').setValue('S')

        }};
      
      this.generalFormatPmv = { tipo_servicio: 'cont-anexosbalance', operacion: 'mismoaniomesdetcentrocosto', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessConsultarFechasCentroCostos,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });

  }

  sucessConsultarFechasCentroCostos(data){
    if (data.estado_p === 200 )
    {
      this.consultarFechasMayorisa(); 
    }
    else 
    {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'No se encontro.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      this.getField('procesar').setDisabled(false);
      this.getField('confirmModalCustom').toggle(false);
    }  
}
  consultarFechasMayorisa(){
    let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-anexosbalance', operacion: 'mismoaniomesmayorizar', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessConsultarFechasMayorista,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  }

  sucessConsultarFechasMayorista(data){
      if (data.estado_p === 200 )
      {
        
        if(this.operacion_actual!==''){
          if(this.getField('radio_correo').getValue()==='S'){
              this.enviarCorreo();
          }else{
              if(this.operacion_actual==='pdf'){
                this.generarPdfTabla3();
              }else if(this.operacion_actual==='pantalla'){
                this.generarModal();
              }else if(this.operacion_actual==='excel'){
                this.generarExcelTabla3();
              } 
          }
          
        }else{
          this.getField('procesar').setDisabled(false);
          this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
        }
      }
      else 
      {
        if(data.estado_p===404) {
          this.getField('procesar').setDisabled(false);
          this.alertGeneral.toggle(true, 'No se encontro.', 'error');
        }
        else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          this.getField('procesar').setDisabled(false);
          }
          this.getField('procesar').setDisabled(false);
          this.getField('confirmModalCustom').toggle(false);
      };
  }

  enviarCorreo(){
    let datos = { datos: {
      fecha_desde:this.getField('fecha_desde').getValue(),
      fecha_hasta:this.getField('fecha_hasta').getValue(),
      operacion:this.operacion_actual,
      radio_correo:this.getField('radio_correo').getValue(),
      email:this.getField('email').getValue(),
    }};
    this.generalFormatPmv = { tipo_servicio: 'cont-anexosbalance', operacion: 'generaroperacion', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successEnviarCorreo,
              error: this.error_,
              general: this.generalFormatPmv
      });
  }

  successEnviarCorreo(data){
     this.getField('confirmModalCustom').toggle(false);
     if(data.estado_p === 200){ 
       this.limpiarCampos();
     }else{
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        this.getField('procesar').setDisabled(false);
     }
 }

  generarModal(){
    let datos = {};
    // centro_costo = S & maneja_movimiento = S con fecha_desde & fecha_hasta y sin cuenta_inicial & cuenta_final
    if(this.getField('cuenta_inicial').getValue() ==='' & this.getField('cuenta_final').getValue() === '' && this.getField('ch_centros_costo').getValue()==='true' && this.getField('fecha_desde').getValue() !=='' & this.getField('fecha_hasta').getValue() !==''){
      datos =  { datos: {
        fecha_desde:this.getField('fecha_desde').getValue(),
        fecha_hasta:this.getField('fecha_hasta').getValue(),
        centro_costo:this.getField('ch_centros_costo').getValue(),        
        operacion:this.operacion_actual        
      }      
      };
      //console.log("se fue por generar modal ---> 1");
    // centro_costo = S & maneja_movimiento = S con fecha_desde & fecha_hasta y cuenta_inicial & cuenta_final
    }else if(this.getField('cuenta_inicial').getValue() !=='' & this.getField('cuenta_final').getValue() !=='' && this.getField('ch_centros_costo').getValue()==='true'){
      datos = { datos:{
        fecha_desde:this.getField('fecha_desde').getValue(),
        fecha_hasta:this.getField('fecha_hasta').getValue(),
        centro_costo:this.getField('ch_centros_costo').getValue(),
        //codigo_sucursal:'Todas',
        operacion:this.operacion_actual,
        cuenta_inicial: this.getField('cuenta_inicial').getValue(),
        cuenta_final: this.getField('cuenta_final').getValue()     
      }
      };
      //console.log("se fue por generar modal ---> 2");    
    }else if(this.getField('cuenta_inicial').getValue() ==='' & this.getField('cuenta_final').getValue() === ''){
      datos =  { datos: {
        fecha_desde:this.getField('fecha_desde').getValue(),
        fecha_hasta:this.getField('fecha_hasta').getValue(),
        //codigo_sucursal:'Todas',
        operacion:this.operacion_actual
      }
      };
      //console.log("se fue por generar modal ---> 3");
    }else{
      datos = { datos:{
        fecha_desde:this.getField('fecha_desde').getValue(),
        fecha_hasta:this.getField('fecha_hasta').getValue(),
        //codigo_sucursal:'Todas',
        operacion:this.operacion_actual,
        cuenta_inicial: this.getField('cuenta_inicial').getValue(),
        cuenta_final: this.getField('cuenta_final').getValue()
      }
      };
      //console.log("se fue por generar modal ---> 4");
    }

    this.generalFormatPmv = { tipo_servicio: 'cont-anexosbalance', operacion: 'generaroperacion', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successGenerarModal,
              error: this.error_,
              general: this.generalFormatPmv
      });
   }

   successGenerarModal(data){
    // console.log("generar modal encabezados ---> ", data);    
      this.getField('confirmModalCustom').toggle(false);
       if(data.estado_p === 200 && this.getField('ch_centros_costo').getValue()==='true'){ 
        //let cuentas = "";        
        if(this.getField('ch_centros_costo').getValue() !=='' && this.getField('ch_centros_costo').getValue()==='true' && this.getField('cuenta_inicial').getValue() !=='' & this.getField('cuenta_final').getValue() !== ''){
          // console.log("modal1 -->",cuentas);
          //cuentas=this.getField('cuenta_inicial').getValue()+"-"+this.getField('cuenta_final').getValue();
        }
        else{
          // console.log("modal2 -->",cuentas);
          //cuentas="todas"
        }      
        //llenar lista con codigo_centro_costo
        //nombre,operacion,fecha_actual,desde-hasta,sucursales,usuario
        let datosFicha = [];
        let data2 = 
                {                  
                    "nombre": data.data.lista.nombre_empresa,
                    "operacion": data.data.lista.operacion,
                    "fecha_actual": data.data.lista.fecha_actual,
                    "desde-hasta": data.data.lista.fechasdesdehasta,
                    "sucursales": data.data.lista.sucursales,
                    "usuario": data.data.lista.usuario,
                    "rango_cuentas": this.getField('cuenta_inicial').getValue() !=='' && this.getField('cuenta_final').getValue() !== '' ? this.getField('cuenta_inicial').getValue()+"-"+this.getField('cuenta_final').getValue() : "todas"
                    

                  };
        
        //
        //data2.put("rango_cuentas"=)
        datosFicha.push(data2);

        this.getField('lista_datos').setItemsFichas(datosFicha);
        this.getField('total_saldo_anterior').setValue(data.data.lista.total_antes);
        this.getField('total_final').setValue(data.data.lista.total_actual);
        this.getField('total_credito').setValue(data.data.lista.total_credito);
        this.getField('total_debito').setValue(data.data.lista.total_debito);
        this.getField('total_saldo_anterior').setVisible(true);
        this.getField('total_final').setVisible(true);
        this.getField('total_credito').setVisible(true);
        this.getField('total_debito').setVisible(true);
        this.getField('tabla_anexos').toggle(true);
        let arrayTemp = [];
        let objectTemp = {};
        data.data.datos.forEach((item)=>{
          objectTemp = {
            "codigo_cuenta": item.codigo_cuenta,
            "nombre": item.nombre,
            "codigo_centro_costo": item.codigo_centro_costo,
            "saldo_anterior": item.saldo_anterior,
            "movimiento_debito": item.movimiento_debito,
            "movimiento_credito": item.movimiento_credito,
            "saldo_final": item.saldo_final,
            "maneja_movimiento": item.maneja_movimiento,
            "detallado": item.detallado
          };
          arrayTemp.push(objectTemp);
        });
         let configCell = new Map();
         this.gridOptionsComponentes['rowData'] = [];
         this.getField('tabla_anexos').initData(this.gridOptionsComponentes);
         this.gridOptionsComponentes['rowData'] = arrayTemp;//los datos del servicio se cargan en la tabla por defecto de la respuesta del servicio si fuera desde el crud seria data.model
         this.getField('modal_anexos').handleClickOpen();
         configCell.set('codigo_cuenta', { headerName: 'Código de Cuenta'});
         configCell.set('codigo_centro_costo', { headerName: 'Código Centro de Costo'});           
         configCell.set('nombre', { headerName: 'Nombre'});
         configCell.set('saldo_anterior', { headerName: 'Saldo Anterior',cellStyle: {textAlign:"right"}});
         configCell.set('movimiento_debito', { headerName: 'Movimiento Débito',cellStyle: {textAlign:"right"}});
         configCell.set('movimiento_credito', { headerName: 'Movimiento Crédito',cellStyle: {textAlign:"right"}});
         configCell.set('saldo_final', { headerName: 'Saldo Final',cellStyle: {textAlign:"right"}});
         configCell.set('maneja_movimiento', { headerName: 'Maneja Movimiento'});
         configCell.set('detallado', { headerName: 'Detallado'});
         //se carga la configuracion de la tabla agregada en el modelo adicional se agrega el configcell el cual me permite renderizar
         this.getField('tabla_anexos').initData(this.gridOptionsComponentes, configCell);
         this.limpiarCampos();
      }else if (data.estado_p === 200){
        //let cuentas = "";
      if(this.getField('cuenta_inicial').getValue() !=='' & this.getField('cuenta_final').getValue() !== ''){
        // console.log("modal3 -->",cuentas);
        //cuentas=this.getField('cuenta_inicial').getValue()+"-"+this.getField('cuenta_final').getValue();
      }else{
        // console.log("modal4 -->",cuentas);
        //cuentas="todas"
      }  
        //llenar lista
        //nombre,operacion,fecha_actual,desde-hasta,sucursales,usuario
        let datosFicha = [];
        let data2 = 
                {                  
                    "nombre": data.data.lista.nombre_empresa,
                    "operacion": data.data.lista.operacion,
                    "fecha_actual": data.data.lista.fecha_actual,
                    "desde-hasta": data.data.lista.fechasdesdehasta,
                    "sucursales": data.data.lista.sucursales,
                    "usuario": data.data.lista.usuario,
                    "rango_cuentas": this.getField('cuenta_inicial').getValue() !=='' && this.getField('cuenta_final').getValue() !== '' ? this.getField('cuenta_inicial').getValue()+"-"+this.getField('cuenta_final').getValue() : "todas"
                    

                  };
        
        //
        //data2.put("rango_cuentas"=)
        datosFicha.push(data2);

        this.getField('lista_datos').setItemsFichas(datosFicha);
        this.getField('total_saldo_anterior').setValue(data.data.lista.total_antes);
        this.getField('total_final').setValue(data.data.lista.total_actual);
        this.getField('total_credito').setValue(data.data.lista.total_credito);
        this.getField('total_debito').setValue(data.data.lista.total_debito);
        this.getField('total_saldo_anterior').setVisible(true);
        this.getField('total_final').setVisible(true);
        this.getField('total_credito').setVisible(true);
        this.getField('total_debito').setVisible(true);
         this.getField('tabla_anexos').toggle(true);
         let configCell = new Map();
         configCell.set('saldo_anterior',{cellStyle: {textAlign:"right"}});
         configCell.set('movimiento_debito',{cellStyle: {textAlign:"right"}});
         configCell.set('movimiento_credito',{cellStyle: {textAlign:"right"}});
         configCell.set('saldo_final',{cellStyle: {textAlign:"right"}});

         this.gridOptionsComponentes['rowData'] = data.data.datos;
         this.getField('tabla_anexos').initData(this.gridOptionsComponentes, configCell);
         this.getField('modal_anexos').handleClickOpen();
         this.limpiarCampos();


      }else if (data.estado_p === 404){
          this.getField('modal_anexos').handleClickOpen();
          this.getField('tabla_anexos').toggle(false);
          this.getField('total_saldo_anterior').setVisible(true);
          this.getField('total_final').setVisible(true);
          this.getField('total_credito').setVisible(true);
          this.getField('total_debito').setVisible(true);
          this.getField('procesar').setDisabled(false);
          this.alertGeneral.toggle(true, 'No hay datos', 'error');
      }else{
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          this.getField('procesar').setDisabled(false);
        }
   }


  generarPdfTabla3()
    {
         let url = this.constant.formConfiguration();
         let datos2 = {};

        if(this.getField('cuenta_inicial').getValue() ==='' & this.getField('cuenta_final').getValue() === ''){
          datos2 = {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            //codigo_sucursal:'Todas',
            operacion:this.operacion_actual
          };
        }else{
          datos2 = {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            //codigo_sucursal:'Todas',
            operacion:this.operacion_actual,
            cuenta_inicial: this.getField('cuenta_inicial').getValue(),
            cuenta_final: this.getField('cuenta_final').getValue()
          };
        }
        if(this.getField('ch_centros_costo').getValue()==='true'){
          datos2.centro_costo= true;
        }
         const myJSON = JSON.stringify(datos2);
         let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-anexosbalance","operacion":"generaroperacion","operacion_tipo":"consulta"}}';
         fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                  "Authorization": 'Bearer '+localStorage.getItem('token'), 
                  "Content-Type":'application/json'})})
         .then(response => {
          this.getField('confirmModalCustom').toggle(false);
         if(response.status!==200){
            return '';
         }
         const reader = response.body.getReader();
         return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                  return reader.read().then(({ done, value }) => {
                  if (done) {
                     controller.close();
                     return;}
                  controller.enqueue(value);
                  return pump();});}}})})
         .then(stream => new Response(stream))
         .then(response => response.blob())
         .then(blob => {
            if(blob.size>0){
              const pdf = new File([blob], 'name.pdf', {
                    type:'application/pdf'});
              window.open(URL.createObjectURL(pdf))
              this.limpiarCampos();
            }else{
               this.alertGeneral.toggle(true,'No hay datos','error');
               this.getField('procesar').setDisabled(false);
            }});
    }

    generarExcelTabla3()
    {
         let url = this.constant.formConfiguration();
         let datos2 = {};

        if(this.getField('cuenta_inicial').getValue() ==='' & this.getField('cuenta_final').getValue() === ''){
          
          datos2 =  {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            //codigo_sucursal:'Todas',
            operacion:this.operacion_actual
          };
        }else{
          datos2 = {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            //codigo_sucursal:'Todas',
            operacion:this.operacion_actual,
            cuenta_inicial: this.getField('cuenta_inicial').getValue(),
            cuenta_final: this.getField('cuenta_final').getValue()
          };
        }
        if(this.getField('ch_centros_costo').getValue()==='true'){
          datos2.centro_costo= true;
        }
         const myJSON = JSON.stringify(datos2);
         let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-anexosbalance","operacion":"generaroperacion","operacion_tipo":"consulta"}}';
         fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                  "Authorization": 'Bearer '+localStorage.getItem('token'), 
                  "Content-Type":'application/json'})})
         .then(response => {
          this.getField('confirmModalCustom').toggle(false);
         if(response.status!==200){
            return '';
         }
         const reader = response.body.getReader();
         return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                  return reader.read().then(({ done, value }) => {
                  if (done) {
                     controller.close();
                     return;}
                  controller.enqueue(value);
                  return pump();});}}})})
         .then(stream => new Response(stream))
         .then(response => response.blob())
         .then(blob => {
            if(blob.size>0){
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = 'anexos.xlsx';
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              alert('Se descargo el archivo!');
              this.limpiarCampos();
            }else{
               this.alertGeneral.toggle(true,'No hay datos','error');
               this.getField('procesar').setDisabled(false);
            }});
    }

    mismoaniodiferentemes(){
      this.getField('procesar').setDisabled(true);
      this.getField('confirmModalCustom').setTitleAndContent('¡Trabaja tranquilo!', '  El proceso se está realizando en este momento, cuando finalice se enviará el anexo a tu correo.');
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
      this.getField("confirmModalCustom").setVisibleCancel('.');
      this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
      this.getField('confirmModalCustom').toggle(true)

      let datos={ datos: {fecha_desde: this.getField('fecha_desde').getValue(),fecha_hasta: this.getField('fecha_hasta').getValue(),}};
        this.generalFormatPmv = { tipo_servicio: 'cont-anexosbalance', operacion: 'mismoaniodiferentemes', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successmismoaniodiferentemes,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        this.service.hideProgress();
    }

    successmismoaniodiferentemes(data){
        if (data.estado_p === 200 )
        {
          this.consultarFechasMayordiferente(); 
        }
        else 
        {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
          this.getField('procesar').setDisabled(false);
        }
    }
  
    consultarFechasMayordiferente(){
      this.getField('procesar').setDisabled(false);
      let datos={};

      if(this.getField('cuenta_inicial').getValue() ==='' & this.getField('cuenta_final').getValue() === ''){
        datos = { datos: {
            radio_correo:this.getField('radio_correo').getValue(),
            email:this.getField('email').getValue(),
            operacion:this.operacion_actual,
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue()
          }
        }
      }else{
        datos = { datos: {
            radio_correo:this.getField('radio_correo').getValue(),
            email:this.getField('email').getValue(),
            operacion:this.operacion_actual,
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            cuenta_inicial: this.getField('cuenta_inicial').getValue(),
            cuenta_final: this.getField('cuenta_final').getValue()
          }
        }
      }

      this.limpiarCampos();
      this.generalFormatPmv = { tipo_servicio: 'cont-anexosbalance', operacion: 'mismoaniodiferentemesmayorizar', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessConsultarFechasMayordiferente,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
      this.service.hideProgress();

    }
    

    sucessConsultarFechasMayordiferente(data){
      if (data.estado_p === 200 )
      {
          this.alertGeneral.toggle(true, 'Email enviado éxitosamente.', 'success');
      }else {
          this.alertGeneral.toggle(true, 'Error al enviar el email.', 'error');
      }  
    }

}

FormJaivana.addController("cont-anexosbalance", CustomAnexosAlBalance);
export default CustomAnexosAlBalance;