import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomPlantillasConfiguracionItems
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomPlantillasConfiguracionItems extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.successguardarItem = this.successguardarItem.bind(this);
    this.guardarItem = this.guardarItem.bind(this);
    this.eliminarCuenta = this.eliminarCuenta.bind(this);
    this.successEliminarColumna = this.successEliminarColumna.bind(this);
    this.buscarDatos =this.buscarDatos.bind(this);
    this.succesConsulta  = this.succesConsulta.bind(this);
	  this.mostrarCampos	=	this.mostrarCampos.bind(this);
    this.ocultarCampos =  this.ocultarCampos.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                             =   this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']         =   this.onSelectionChanged;

    this.gridOptionsItems = Object.assign({}, this.gridOptions);
    this.onSelectionChangedItems                            =   this.onSelectionChangedItems.bind(this);
    this.gridOptionsItems['onSelectionChanged']         =   this.onSelectionChangedItems;

    this.setButtonDelete = this.setButtonDelete.bind(this);
    this.confirmEliminarCuenta	=	this.confirmEliminarCuenta.bind(this);
    this.validarUnique	=	this.validarUnique.bind(this);
    this.succesConsultaUnique	=	this.succesConsultaUnique.bind(this);


    this.accionProcesar	=	this.accionProcesar.bind(this);
    this.crearBotonTabla	=	this.crearBotonTabla.bind(this);
    this.setBotones	=	this.setBotones.bind(this);
    this.contiene_registros=false;
    this.validarCuenta	=	this.validarCuenta.bind(this);
    this.validarCentroCosto	=	this.validarCentroCosto.bind(this);
    this.validarOrigen	=	this.validarOrigen.bind(this);
    this.limpiarErrores	=	this.limpiarErrores.bind(this);
    this.ocultarCamposCancelar	=	this.ocultarCamposCancelar.bind(this);
    this.succesLlenarTablaItems = this.succesLlenarTablaItems.bind(this);

  }

  initForm() {
    console.log("Formulario CustomPlantillasConfiguracionItems,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez.  @updateBy: Jose Albeiro Marin");
    this.buscarDatos();
    this.getField('agregar').setClick(this.validarUnique);
    this.getField('cancelar').setClick(this.ocultarCamposCancelar);
    this.getField('btn_refrescar').setClick(this.buscarDatos);
    this.getField('btn_agregar_cuenta').setClick(this.validarCuenta);
    this.getField('btn_agregar_origen').setClick(this.validarOrigen);
    this.getField('btn_agregar_centro').setClick(this.validarCentroCosto);

    this.getField('origen').setKeyUp(()=>{
      if(this.getField('origen').getValue().toLowerCase()==='todos')
          this.getField('origen').setError(false,'');
        else
          this.getField('origen').valid();
        
    });
    this.getField('centro_costo').setKeyUp(()=>{
      if(this.getField('centro_costo').getValue().toLowerCase()==='todos')
          this.getField('centro_costo').setError(false,'');
        else
          this.getField('centro_costo').valid();
    });

    this.getField('cuenta').setOnChange((props) => {    
    if(this.getField('cuenta').getValue() !==''){
       this.getField('cuenta').setError(false,'');
    }});

    this.getField('origen').setOnChange((props) => {    
    if(this.getField('origen').getValue() !==''){
       this.getField('origen').setError(false,'');
    }});

    this.getField('centro_costo').setOnChange((props) => {    
    if(this.getField('centro_costo').getValue() !==''){
    this.getField('centro_costo').setError(false,'');
    }});

  }

  validarCuenta(){
    if(this.getField('cuenta_contable').valid() && this.getField('nombre_cuenta_contable').valid()){
      let valor_actual=this.getField('cuenta').getValue();
      if(valor_actual==='')
        this.getField('cuenta').setValue(this.getField('cuenta_contable').getValue());
      else {
        let array=this.getField('cuenta').getValue().split(',');
        let existe=0;
        array.forEach(element => {
          if(element===this.getField('cuenta_contable').getValue()){
            existe++;
          }
        });
        if(existe>0)
          this.alertGeneral.toggle(true, 'La cuenta ya se encuentra agregada.', 'error');
        else
          this.getField('cuenta').setValue(valor_actual+','+this.getField('cuenta_contable').getValue());
      }
      this.getField('cuenta_contable').setValue('');
      this.getField('nombre_cuenta_contable').setValue('');
    }
  }

  validarOrigen(){
    if(this.getField('codigo_origen').valid() && this.getField('nombre_origen').valid()){
      let valor_actual=this.getField('origen').getValue();
      if(valor_actual==='')
        this.getField('origen').setValue(this.getField('codigo_origen').getValue());
      else {
        let array=this.getField('origen').getValue().split(',');
        let existe=0;
        array.forEach(element => {
          if(element===this.getField('codigo_origen').getValue()){
            existe++;
          }
        });
        if(existe>0)
          this.alertGeneral.toggle(true, 'El origen ya se encuentra agregado.', 'error');
        else
          this.getField('origen').setValue(valor_actual+','+this.getField('codigo_origen').getValue());
      }
      this.getField('codigo_origen').setValue('');
      this.getField('nombre_origen').setValue('');
    }
  }

  validarCentroCosto(){
    if(this.getField('centro_costo_codigo').valid() && this.getField('centro_costo_nombre').valid()){
      let valor_actual=this.getField('centro_costo').getValue();
      if(valor_actual==='')
        this.getField('centro_costo').setValue(this.getField('centro_costo_codigo').getValue());
      else{
        let array=this.getField('centro_costo').getValue().split(',');
        let existe=0;
        array.forEach(element => {
          if(element===this.getField('centro_costo_codigo').getValue()){
            existe++;
          }
        });
        if(existe>0)
          this.alertGeneral.toggle(true, 'El centro costo ya se encuentra agregado.', 'error');
        else
          this.getField('centro_costo').setValue(valor_actual+','+this.getField('centro_costo_codigo').getValue());
      }
      this.getField('centro_costo_codigo').setValue('');
      this.getField('centro_costo_nombre').setValue('');
    }
  }


  onSelectionChangedItems(){
    let rowDataTable = this.gridOptionsItems.api.getSelectedRows();
    this.getField('contabilidad_plantillas_configuracion_items_id').setValue(rowDataTable[0].contabilidad_plantillas_configuracion_items_id);
  }

  onSelectionChanged(){
    let rowDataTable = this.gridOptionsComponentes.api.getSelectedRows();
    this.getField('contabilidadplantillasconfiguracion_id').setValue(rowDataTable[0].contabilidadplantillasconfiguracion_id);
  }

  mostrarCampos(){
    this.getField('rejilla_items').toggle(false);
    this.getField('btn_refrescar').setDisabled(true);
    this.getField('hoja').setVisible(true);
    this.getField('fila').setVisible(true);
    this.getField('columna').setVisible(true);
    this.getField('cuenta_contable').setVisible(true);
    this.getField('nombre_cuenta_contable').setVisible(true);
    this.getField('cuenta').setVisible(true);
    this.getField('btn_agregar_cuenta').setVisible(true);
    this.getField('codigo_origen').setVisible(true);
    this.getField('nombre_origen').setVisible(true);
    this.getField('btn_agregar_origen').setVisible(true);
    this.getField('origen').setVisible(true);
    this.getField('centro_costo_codigo').setVisible(true);
    this.getField('centro_costo_nombre').setVisible(true);
    this.getField('btn_agregar_centro').setVisible(true);
    this.getField('centro_costo').setVisible(true);
    this.getField('agregar').setVisible(true);
    this.getField('cancelar').setVisible(true);
  }
  
  limpiarErrores(){
    this.getField('hoja').setError(false,'');
    this.getField('fila').setError(false,'');
    this.getField('columna').setError(false,'');
    this.getField('cuenta_contable').setError(false,'');
    this.getField('nombre_cuenta_contable').setError(false,'');
    this.getField('cuenta').setError(false,'');
    this.getField('codigo_origen').setError(false,'');
    this.getField('nombre_origen').setError(false,'');
    this.getField('origen').setError(false,'');
    this.getField('centro_costo_codigo').setError(false,'');
    this.getField('centro_costo_nombre').setError(false,'');
    this.getField('centro_costo').setError(false,'');
  }

  ocultarCampos(){
    this.getField('btn_refrescar').setVisible(false);
    this.getField('rejilla').toggle(false);
    this.getField('rejilla_items').toggle(false);
    this.getField('hoja').setVisible(false);
    this.getField('fila').setVisible(false);
    this.getField('columna').setVisible(false);
    this.getField('cuenta_contable').setVisible(false);
    this.getField('nombre_cuenta_contable').setVisible(false);
    this.getField('cuenta').setVisible(false);
    this.getField('btn_agregar_cuenta').setVisible(false);
    this.getField('cuenta').setVisible(false);
    this.getField('codigo_origen').setVisible(false);
    this.getField('nombre_origen').setVisible(false);
    this.getField('btn_agregar_origen').setVisible(false);
    this.getField('origen').setVisible(false);
    this.getField('centro_costo_codigo').setVisible(false);
    this.getField('centro_costo_nombre').setVisible(false);
    this.getField('btn_agregar_centro').setVisible(false);
    this.getField('centro_costo').setVisible(false);
    this.getField('agregar').setVisible(false);
    this.getField('cancelar').setVisible(false);

    this.getField('hoja').setValue('');
    this.getField('fila').setValue('');
    this.getField('columna').setValue('');
    this.getField('cuenta_contable').setValue('');
    this.getField('cuenta').setValue('');
    this.getField('nombre_cuenta_contable').setValue('');
    this.getField('codigo_origen').setValue('');
    this.getField('nombre_origen').setValue('');
    this.getField('origen').setValue('');
    this.getField('centro_costo_codigo').setValue('');
    this.getField('centro_costo_nombre').setValue('');
    this.getField('centro_costo').setValue('');
    this.limpiarErrores();

  }

  ocultarCamposCancelar(){
    this.getField('btn_refrescar').setDisabled(false);
    this.getField('hoja').setVisible(false);
    this.getField('fila').setVisible(false);
    this.getField('columna').setVisible(false);
    this.getField('cuenta_contable').setVisible(false);
    this.getField('nombre_cuenta_contable').setVisible(false);
    this.getField('cuenta').setVisible(false);
    this.getField('btn_agregar_cuenta').setVisible(false);
    this.getField('cuenta').setVisible(false);
    this.getField('codigo_origen').setVisible(false);
    this.getField('nombre_origen').setVisible(false);
    this.getField('btn_agregar_origen').setVisible(false);
    this.getField('origen').setVisible(false);
    this.getField('centro_costo_codigo').setVisible(false);
    this.getField('centro_costo_nombre').setVisible(false);
    this.getField('btn_agregar_centro').setVisible(false);
    this.getField('centro_costo').setVisible(false);
    this.getField('agregar').setVisible(false);
    this.getField('cancelar').setVisible(false);

    this.getField('hoja').setValue('');
    this.getField('fila').setValue('');
    this.getField('columna').setValue('');
    this.getField('cuenta_contable').setValue('');
    this.getField('cuenta').setValue('');
    this.getField('nombre_cuenta_contable').setValue('');
    this.getField('codigo_origen').setValue('');
    this.getField('nombre_origen').setValue('');
    this.getField('origen').setValue('');
    this.getField('centro_costo_codigo').setValue('');
    this.getField('centro_costo_nombre').setValue('');
    this.getField('centro_costo').setValue('');
    this.limpiarErrores();

  }


  buscarDatos(){
        this.ocultarCampos();
        let datos={ datos: { }};
        this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfiguracion', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesConsulta,
                error: this.error_,
                general: this.generalFormatPmv
        });
  }

  succesConsulta(data){
    this.getField('btn_refrescar').setVisible(true);
    this.getField('btn_refrescar').setDisabled(false);
      if (data.estado_p === 200 )
      {
          this.contiene_registros=true;
          this.getField('rejilla').toggle(true);
          let configCell = new Map();
          configCell.set('descripcion', { headerName: 'Descripción'});
          configCell.set('accion', { cellRenderer: this.setBotones, width: 200, sortable: false, filter: false, field: 'Acción' });
          this.gridOptionsComponentes['rowData'] = data.data;
          this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
      }
      else 
      {
		  if (data.estado_p === 404 ){
                this.contiene_registros=false;
                this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
		  }else{
			  this.getField('rejilla').toggle(false);
			  let respuesta=Object.values(data.data.errores);
			  let keys=Object.keys(data.data.errores);
			  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error'); 
		  }
      };
  }
  
  llenarTablaItems(){
    let datos={ datos: {
      contabilidadplantillasconfiguracion_id:this.getField('contabilidadplantillasconfiguracion_id').getValue()
     }};
    this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfigitems', operacion: '1_1', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.succesLlenarTablaItems,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
    });
}

succesLlenarTablaItems(data){

    if (data.estado_p === 200 )
    {
      this.contiene_registros=true;
      this.getField('rejilla_items').toggle(true);
      let configCell = new Map();
      configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Acción' });
      this.gridOptionsItems['rowData'] = data.data;
      this.getField('rejilla_items').initData(this.gridOptionsItems, configCell);
  }
  else 
  {
  this.getField('rejilla_items').toggle(false);
  if (data.estado_p === 404 ){
            this.contiene_registros=false;
            this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
  }else{
    let respuesta=Object.values(data.data.errores);
    let keys=Object.keys(data.data.errores);
    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error'); 
  }
  };
}


  validarUnique(){
    let errores=false;
    this.limpiarErrores();
    this.getField('agregar').setDisabled(true);
    if(this.getField('hoja').valid()&&
      this.getField('fila').valid()&&
      this.getField('columna').valid() &&
      this.getField('cuenta').valid()){
        if(this.getField('origen').getValue().toLowerCase()==='todos'){
        }else{
            if(!this.getField('origen').valid()){
                errores=true;
            }
        }
        if(this.getField('centro_costo').getValue().toLowerCase()==='todos'){
        }else{
            if(!this.getField('centro_costo').valid()){
                errores=true;
            }
        }
     if(errores === false){
        if(this.getField('contabilidadplantillasconfiguracion_id').getValue()!=='' && this.getField('contabilidadplantillasconfiguracion_id').getValue()!==' '){
            let datos={ 
              datos: {
                contabilidadplantillasconfiguracion_id:this.getField('contabilidadplantillasconfiguracion_id').getValue(),  
                hoja:this.getField('hoja').getValue(),
                fila:this.getField('fila').getValue(),
                columna:this.getField('columna').getValue(),
                //se deja tipo por defecto en formula ya que no esta al momento de guarsdar
                tipo:'Formula',
                cuenta:this.getField('cuenta').getValue(),
              }};
            this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfigitems', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesConsultaUnique,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false,
            });
        }else{
            this.getField('codigo_columna').setError(true,'* Es necesario buscar una columna.');
        }
     }else{
      this.getField('agregar').setDisabled(false);
     }

    }else{
      this.getField('agregar').setDisabled(false);
    }
  }

  succesConsultaUnique(data){
      if (data.estado_p === 200 )
      {
        this.getField('agregar').setDisabled(false);
        this.alertGeneral.toggle(true, 'Ya éxiste la relación entre fila,columna,tipo y cuenta.', 'error');
      }
      else 
      {
        if(data.estado_p===404){
          this.guardarItem();
        }else{
          this.getField('agregar').setDisabled(false);
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      };
  }

  guardarItem(){
      let datos = { datos: {
        contabilidadplantillasconfiguracion_id:this.getField('contabilidadplantillasconfiguracion_id').getValue(),  
        hoja:this.getField('hoja').getValue(),
        fila:this.getField('fila').getValue(),
        columna:this.getField('columna').getValue(),
        cuenta:this.getField('cuenta').getValue(),
        origen:this.getField('origen').getValue(),
        centro_costo:this.getField('centro_costo').getValue(),
      }};
      
      this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfigitems', operacion: '5_planillas_items', operacion_tipo: 'crear' };
      this.datos_anteriores=this.generalFormatPmv;
      this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successguardarItem,
                error: this.error_,
                general: this.generalFormatPmv
        });
  }

  successguardarItem(data) 
  {
    this.getField('agregar').setDisabled(false);
    if (data.estado_p === 200)
    {
        this.ocultarCampos();
        this.buscarDatos();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  setBotones(props) {
    let fragment = document.createDocumentFragment();
    let buttonSubir = this.crearBotonTabla(props.data, 'Configurar');
    let buttonBjar = this.crearBotonTabla(props.data, 'Ver más');
    buttonSubir.setAttribute("class","buttonStyle2");
    buttonBjar.setAttribute("class","buttonStyle2");

    fragment.appendChild(buttonSubir);
    fragment.appendChild(buttonBjar);
    return this.createElementJaivana(fragment);
}

  crearBotonTabla(data, boton) {
    let button = document.createElement('input');
    switch (boton) {
        case 'Configurar':
            button.setAttribute("id", `buttonConfig_${data.id}`);
            break;
        case 'Ver más':
          button.setAttribute("id", `buttonVer_${data.id}`);
          break;

        default:
            break;
    }
    button.onclick = () => { this.accionProcesar(data, boton) };
    button.setAttribute("value", boton);
    button.setAttribute("type", "button");
    return button;
}

  accionProcesar(data, boton) {
    this.getField('contabilidadplantillasconfiguracion_id').setValue(data.contabilidadplantillasconfiguracion_id);
    if (boton === 'Configurar') {
        this.mostrarCampos();
    } else if (boton === 'Ver más') {
        this.ocultarCamposCancelar()
        this.llenarTablaItems();
    } else {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }
  }
  setButtonDelete(props) {
      let id = props.data.contabilidad_plantillas_configuracion_items_id;
      let button = document.createElement("input");
      button.onclick = () => this.confirmEliminarCuenta(id);
      button.setAttribute("id", 'button_delete_' + id);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("type", "button");
      button.setAttribute("value", "eliminar");
      return this.createElementJaivana(button);
  }

  confirmEliminarCuenta(id) {
    this.ids = id;
    this.getField('contabilidad_plantillas_configuracion_items_id').setValue(id);
    this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
    this.getField('confirmModalCustom').setClickDialog(this.eliminarCuenta);
    this.getField('confirmModalCustom').toggle(true);
  }
  
  eliminarCuenta(){
    this.getField('confirmModalCustom').toggle(false);
    if(this.getField('contabilidad_plantillas_configuracion_items_id').getValue()!=='' &&
		  this.getField('contabilidad_plantillas_configuracion_items_id').getValue()!==' '){
        let datos = { datos: {
          contabilidad_plantillas_configuracion_items_id:this.getField('contabilidad_plantillas_configuracion_items_id').getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'cont-plantillasconfigitems', operacion: '7', operacion_tipo: 'eliminar' };
        
        
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminarColumna,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }else{
      this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
    }
  }

  successEliminarColumna(data) 
  {
   
    if (data.estado_p === 200)
    {
        this.buscarDatos();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

}
FormJaivana.addController("cont-plantillasconfigitems", CustomPlantillasConfiguracionItems);
export default CustomPlantillasConfiguracionItems;