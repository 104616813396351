import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
 * @author Mario A. Sepúlveda R.
 * @version 1 
 * @modified 31-03-2023
*/
/**
 * CustomPoblaciones
**/
class CustomInformesTerceros extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm = this.initForm.bind(this);
       this.arrayObjetos=new Map();
       this.arrayCampos=new Map();
       this.bHayErrores=false;
       this.gridOptionsTerceros = Object.assign({},this.gridOptions);

       this.consultarInfoTerceros = this.consultarInfoTerceros.bind(this);
       this.successInfoTerceros = this.successInfoTerceros.bind(this);
       this.limpiarCampos = this.limpiarCampos.bind(this);
   }

    initForm() {
        console.log('Formulario Informes Terceros,  @version: jdesk_1.01.0001, @author:Mario A. Sepúlveda R.')

        this.arrayCampos.set(1,'id');
        this.arrayCampos.set(2,'vendedores');
        this.arrayCampos.set(3,'nombre_');
        this.arrayCampos.set(5,'radiobutton');
        this.arrayCampos.set(6,'consultar');
        this.arrayCampos.set(7,'rejilla');
        this.arrayCampos.set(8,'codigo_vendedor_p');
        this.arrayCampos.set(9,'codigo_cobrador');
        this.arrayCampos.set(10,'codigo_vendedor1_u');
        this.arrayCampos.set(11,'codigo_vendedor2');
        this.arrayCampos.set(12,'codigo_vendedor3');
        this.arrayCampos.set(14,'vendedores_id');

        this.arrayObjetos.set(1,'id');
        this.arrayObjetos.set(2,'vendedores');
        this.arrayObjetos.set(3,'nombre_');
        this.arrayObjetos.set(5,'radiobutton');
        this.arrayObjetos.set(6,'consultar');
        this.arrayObjetos.set(7,'rejilla');
        this.arrayObjetos.set(8,'codigo_vendedor_p');
        this.arrayObjetos.set(9,'codigo_cobrador');
        this.arrayObjetos.set(10,'codigo_vendedor1_u');
        this.arrayObjetos.set(11,'codigo_vendedor2');
        this.arrayObjetos.set(12,'codigo_vendedor3');
        this.arrayObjetos.set(14,'vendedores_id');

        this.getField('consultar').setClick(this.consultarInfoTerceros);
        this.getField(this.arrayCampos.get(14)).setOnChange(this.limpiarCampos);
        this.getField(this.arrayCampos.get(5)).setOnChange(this.limpiarCampos);
    }

    consultarInfoTerceros(){
        //this.limpiarCampos();

        let vendedores_id = this.getField('vendedores_id').getValue();
        let opcionRadio = this.getField('radiobutton').getValue();


        if(vendedores_id === ' ' || vendedores_id === '' || vendedores_id === undefined || vendedores_id === null){
            this.getField(this.arrayCampos.get(3)).setError(true,'*Este campo es requerido');
        } else if(opcionRadio === ' ' || opcionRadio === '' || opcionRadio === undefined || opcionRadio === null){
            this.getField('radiobutton').setError(true,'*Este campo es requerido');
        }else {
            this.getField(this.arrayCampos.get(6)).setDisabled(true);
            this.getField(this.arrayCampos.get(3)).setError(false,'');
            this.getField(this.arrayCampos.get(5)).setError(false,'');

            let datos = {};

            switch (opcionRadio) {
                case 'VP':
                    this.establecerPropiedades('8,9,10,11,12','value','');
                    this.getField(this.arrayCampos.get(8)).setValue(this.getField(this.arrayCampos.get(2)).getValue());
                    datos={ datos: {
                        codigo_vendedor_p:this.getField(this.arrayCampos.get(8)).getValue()
                        }
                    };
                    this.generalFormatPmv = { tipo_servicio: 'maes-informesterceros', operacion: '1_1', operacion_tipo: 'consulta' };
                    break;
                case 'CC':
                    this.establecerPropiedades('8,9,10,11,12','value','');
                    this.getField('codigo_cobrador').setValue(this.getField(this.arrayCampos.get(2)).getValue());
                    datos={ datos: {
                        codigo_cobrador:this.getField(this.arrayCampos.get(9)).getValue()
                        }
                    };
                    this.generalFormatPmv = { tipo_servicio: 'maes-informesterceros', operacion: '1_2', operacion_tipo: 'consulta' };
                    break;
                case 'A1':
                    this.establecerPropiedades('8,9,10,11,12','value','');
                    this.getField(this.arrayCampos.get(10)).setValue(this.getField(this.arrayCampos.get(2)).getValue());
                    datos={ datos: {
                        codigo_vendedor1_u:this.getField(this.arrayCampos.get(10)).getValue()
                        }
                    };
                    this.generalFormatPmv = { tipo_servicio: 'maes-informesterceros', operacion: '1_3', operacion_tipo: 'consulta' };
                    break;
                case 'A2':
                    this.establecerPropiedades('8,9,10,11,12','value','');
                    this.getField('codigo_vendedor2').setValue(this.getField(this.arrayCampos.get(2)).getValue());
                    datos={ datos: {
                        codigo_vendedor2:this.getField(this.arrayCampos.get(11)).getValue()
                        }
                    };
                    this.generalFormatPmv = { tipo_servicio: 'maes-informesterceros', operacion: '1_4', operacion_tipo: 'consulta' };
                    break;
                case 'A3':
                    this.establecerPropiedades('8,9,10,11,12','value','');
                    this.getField('codigo_vendedor3').setValue(this.getField(this.arrayCampos.get(2)).getValue());
                    datos={ datos: {
                        codigo_vendedor3:this.getField(this.arrayCampos.get(12)).getValue()
                        }
                    };
                    this.generalFormatPmv = { tipo_servicio: 'maes-informesterceros', operacion: '1_5', operacion_tipo: 'consulta' };
                    break;
                case 'T':
                    this.establecerPropiedades('9','value','');
                    this.establecerPropiedades('8,10,11,12','value',this.getField(this.arrayCampos.get(2)).getValue());

                    datos={ datos: {
                        codigo_vendedor_p:this.getField(this.arrayCampos.get(8)).getValue(),
                        codigo_vendedor1_u:this.getField(this.arrayCampos.get(10)).getValue(),                       
                        codigo_vendedor2:this.getField(this.arrayCampos.get(11)).getValue(),
                        codigo_vendedor3:this.getField(this.arrayCampos.get(12)).getValue()
                        }
                    };
                    this.generalFormatPmv = { tipo_servicio: 'maes-informesterceros', operacion: '1_6', operacion_tipo: 'consulta' };
                    break;
                default:
                    break;
            }

            //console.log('datos a enviar al servicio:',datos,this.generalFormatPmv);
            this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successInfoTerceros,
                    error: this.error_,
                    general: this.generalFormatPmv
            });

        }

    }

    successInfoTerceros(data){
        //console.log('datos:',data);
        this.getField(this.arrayCampos.get(6)).setDisabled(false);

        if(data.estado_p === 200){
            let configCell = new Map();

            configCell.set('nit', {width: 105, field: 'nit'});
            configCell.set('sede', {width: 85, cellStyle: {textAlign:"center"}, field: 'sede'});
            configCell.set('barrio', {width: 100, field: 'barrio'});
            configCell.set('telefonos', {width: 130, field: 'telefonos'});
            configCell.set('cupo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.cupo.toFixed(2)); }, cellStyle: {textAlign:"right"},width: 110, field: 'cupo'});//configCell.set('cupo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.cupo.toFixed(2)); }, cellStyle: {backgroundColor: "rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'},width: 100, field: 'cupo'});
            configCell.set('codigo_negocio', {width: 120, cellStyle: {textAlign:"center"}, field: 'codigo_negocio'});




            this.getField(this.arrayCampos.get(7)).toggle(true);      
            this.gridOptionsTerceros['rowData'] = data.data;
            this.getField(this.arrayCampos.get(7)).initData(this.gridOptionsTerceros,configCell);
            //this.gridOptionsTerceros.api.sizeColumnsToFit();//ajusta todas las columnas para que queden dentro de la ventana visible en la pantalla



        }else {
            this.getField(this.arrayCampos.get(7)).toggle(false);      
        }
    }

    limpiarCampos(){
        this.getField(this.arrayCampos.get(7)).toggle(false);
        
        this.getField(this.arrayCampos.get(3)).setError(false,'');
        this.getField(this.arrayCampos.get(5)).setError(false,'');

    }
    //*** fin  los botones y los success

    establecerPropiedades(sObjetos,sPropiedad,sValor) {
           const array = sObjetos.split(',');
           array.forEach((element) => {
                    //console.log(element);
                    //console.log(this.arrayObjetos.get(parseInt(element)));
                    if(sPropiedad==='disable') 
                    { 
                        if(sValor==='true') { 
                            this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                        }
                        else {
                            this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                        }   
                    }
                    else if(sPropiedad==='visible') 
                    { 
                        if(sValor==='true') { 
                            this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                        }
                        else {
                            this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                        }   
                    }
                    else if(sPropiedad==='value')
                    { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                    }  
                    else if(sPropiedad==='valid')
                    { 
                        if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                           //ok
                        }
                        else {
                            this.bHayErrores=true;
                        }
                    }
                    else if(sPropiedad==='foco')
                    { 
                        if(sValor==='rejilla') { } 
                        else {
                            this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                        }
                    }
          });     
       }
    
    
    cargarCampos(data) {
        this.getField('id').setValue(data.data[0].id);
        this.getField('vendedores').setValue(data.data[0].vendedores);
        this.getField('nombre_').setValue(data.data[0].nombre);
        this.getField('radiobutton').setValue(data.data[0].radiobutton);
        this.getField('consultar').setValue(data.data[0].consultar);
        this.getField('rejilla').setValue(data.data[0].rejilla);
        this.getField('codigo_vendedor_p').setValue(data.data[0].codigo_vendedor);
        this.getField('codigo_cobrador').setValue(data.data[0].codigo_cobrador);
        this.getField('codigo_vendedor1_u').setValue(data.data[0].codigo_vendedor1);
        this.getField('codigo_vendedor2').setValue(data.data[0].codigo_vendedor2);
        this.getField('codigo_vendedor3').setValue(data.data[0].codigo_vendedor3);
        this.getField('vendedores_id').setValue(data.data[0].vendedores_id);
    }
    
}

FormJaivana.addController('maes-informesterceros',CustomInformesTerceros);
export default CustomInformesTerceros;
    
    
    