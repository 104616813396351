import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
 * @author Mario A. Sepúlveda R.
 * @version jdesk_1.01.0001
 * @modified 02-06-2023
*/
/**
 * Custom ActivosFijosCompras
**/
class CustomActivosFijosCompras extends FormJaivana.form {


    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);
        this.arrayObjetos = new Map();
        this.arrayCampos = new Map();
        this.bHayErrores = false;
        this.gridOptionsPoblaciones = Object.assign({}, this.gridOptions);

        this.validarFecha = this.validarFecha.bind(this);
        this.validarFechaMenorActual = this.validarFechaMenorActual.bind(this);
        this.validarUnico = this.validarUnico.bind(this);
        this.successValidarUnico = this.successValidarUnico.bind(this);
        this.successCrear = this.successCrear.bind(this);
        this.confirmCancelar = this.confirmCancelar.bind(this);
        this.limpiarCampos = this.limpiarCampos.bind(this);
        this.validarRangoFecha = this.validarRangoFecha.bind(this);
        this.fechaValid = false;
    }

    initForm() {

        console.log('Formulario CustomActivosFijosCompras,  @version: jdesk_1.01.0001, @author:Mario A. Sepúlveda R.')

        this.arrayCampos.set(1, 'comprasactivfijo_id');
        this.arrayCampos.set(2, 'activofijo');
        this.arrayCampos.set(3, 'descripcion_');
        this.arrayCampos.set(5, 'activosfijos_id');
        this.arrayCampos.set(6, 'nit_tercero');
        this.arrayCampos.set(7, 'sede');
        this.arrayCampos.set(8, 'nombre');
        this.arrayCampos.set(9, 'numero_factura');
        this.arrayCampos.set(10, 'fecha_factura');
        this.arrayCampos.set(11, 'valor_sin_impuestos');
        this.arrayCampos.set(12, 'valor_con_impuestos');
        this.arrayCampos.set(13, 'aceptar');
        this.arrayCampos.set(14, 'terceros_id');
        this.arrayCampos.set(15, 'cancelar');

        this.arrayObjetos.set(1, 'comprasactivfijo_id');
        this.arrayObjetos.set(2, 'activofijo');
        this.arrayObjetos.set(3, 'descripcion_');
        this.arrayObjetos.set(5, 'activosfijos_id');
        this.arrayObjetos.set(6, 'nit_tercero');
        this.arrayObjetos.set(7, 'sede');
        this.arrayObjetos.set(8, 'nombre');
        this.arrayObjetos.set(9, 'numero_factura');
        this.arrayObjetos.set(10, 'fecha_factura');
        this.arrayObjetos.set(11, 'valor_sin_impuestos');
        this.arrayObjetos.set(12, 'valor_con_impuestos');
        this.arrayObjetos.set(13, 'aceptar');
        this.arrayObjetos.set(14, 'terceros_id');
        this.arrayObjetos.set(15, 'cancelar');

        this.getField(this.arrayCampos.get(10)).setOnChange(this.validarFecha);
        this.getField(this.arrayObjetos.get(13)).setClick(this.validarUnico);
        this.getField(this.arrayCampos.get(15)).setClick(this.confirmCancelar);
        this.validarFecha();
    }

    validarFecha(){
        //console.log("entro a validar fecha",this.getField(this.arrayCampos.get(10)).getValue());
        let fecha = this.getField(this.arrayCampos.get(10)).getValue();
        let validFecha = this.validarFechaMenorActual(fecha);

        if(!validFecha){
            //console.log("IF:",this.getField(this.arrayCampos.get(10)).getValue());
            this.fechaValid = false;
            this.getField(this.arrayObjetos.get(10)).setError(true,'*¡La fecha debe ser igual o inferior a 5 años de la fecha actual!');
            //this.establecerPropiedades('13','disable','true');
        } else {
            //console.log("ELSE:",this.getField(this.arrayCampos.get(10)).getValue());
            //this.getField(this.arrayObjetos.get(10)).setError(false,'');
            //this.establecerPropiedades('13','disable','false');
            this.validarRangoFecha(fecha);
        }

    }

    validarFechaMenorActual(date){
        //console.log("value fecha:",date);
        var x = new Date(date);
        var today = new Date();
        if (x <= today)
            return true;
        else
            return false;
    }

    validarRangoFecha(fecha){
        let fechaMinima = new Date();
        let fechaSelec = new Date(fecha);

        let mensajeError = '';
        //let monthStr = '';
        //let dateStr = '';

        fechaMinima.setDate(fechaMinima.getDate() - 1826);

        fechaSelec.setDate(fechaSelec.getDate() + 1);

        if(new Date(fechaSelec) <= new Date(fechaMinima) ){
            //(fechaMinima.getMonth()+1 ) < 10 ? monthStr = `0${fechaMinima.getMonth()+1}`: monthStr = `${fechaMinima.getMonth()+1}`; //para concantenar el 0 en el mes que sea menor a 10
            //(fechaMinima.getDate()) < 10 ? dateStr = `0${fechaMinima.getDate()}`: dateStr = `${fechaMinima.getDate()}`;//para concantenar el 0 en el que dia sea menor a 10 
            
            //mensajeError = `* La fecha mínima permitida es ${fechaMinima.getFullYear()}-${monthStr}-${dateStr}`
            mensajeError = '*¡La fecha debe ser igual o inferior a 5 años de la fecha actual!';
            //console.log("fecha minima: ", fechaMinima, mensajeError,fechaSelec);
            this.fechaValid =false;
            this.getField('fecha_factura').setError(true,mensajeError);
        } else {
            this.fechaValid = true;
            this.getField('fecha_factura').setError(false,'');
        }
        
    }

    validarUnico(){
        let terceros_id = this.getField(this.arrayCampos.get(14)).getValue();
        let activosfijos_id = this.getField(this.arrayCampos.get(5)).getValue();
        let numero_factura = this.getField(this.arrayCampos.get(9));
        //let fecha_factura = this.getField(this.arrayCampos.get(10));
        let valor_sin_impuestos = this.getField(this.arrayCampos.get(11));
        let valor_con_impuestos = this.getField(this.arrayCampos.get(12));

        //console.log("entro a validar unico",terceros_id,activosfijos_id,numero_factura.getValue(),fecha_factura.getValue(),valor_con_impuestos.getValue(),valor_sin_impuestos.getValue());

        if(terceros_id !== '' && terceros_id !== ' ' && terceros_id !== null && terceros_id !== undefined &&
          activosfijos_id !== '' && activosfijos_id !== ' ' && activosfijos_id !== null && activosfijos_id !== undefined &&
          numero_factura.valid() && valor_sin_impuestos.valid() && valor_con_impuestos.valid() && this.fechaValid){

            //this.establecerPropiedades('13','disable','true');
            //console.log("entro a  IF validar unico---",terceros_id,activosfijos_id,numero_factura.getValue());

            let datos={ datos: {
                activosfijos_id:activosfijos_id,
                numero_factura:numero_factura.getValue(),
                terceros_id:terceros_id,
            } };

            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijoscompras', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successValidarUnico,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            });
        }
    }

    successValidarUnico(data){
        if(data.estado_p === 200 ){
            if(data.data[0].cuantos === 0){
                let terceros_id = this.getField(this.arrayCampos.get(14)).getValue();
                let activosfijos_id = this.getField(this.arrayCampos.get(5)).getValue();
                let numero_factura = this.getField(this.arrayCampos.get(9));
                let fecha_factura = this.getField(this.arrayCampos.get(10));
                let valor_sin_impuestos = this.getField(this.arrayCampos.get(11));
                let valor_con_impuestos = this.getField(this.arrayCampos.get(12));
                
                let datos={ datos: {
                    activosfijos_id: activosfijos_id,
                    fecha_factura: fecha_factura.getValue(),
                    numero_factura: numero_factura.getValue(),
                    valor_sin_impuestos: valor_sin_impuestos.getValue(),
                    valor_con_impuestos: valor_con_impuestos.getValue(),
                    terceros_id: terceros_id
                } };
                //console.log('entro a if cuantos cero:');

                this.generalFormatPmv = { tipo_servicio: 'maes-activosfijoscompras', operacion: '5', operacion_tipo: 'crear' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successCrear,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        //showMessage:false
                });
                
            } else {
                this.alertGeneral.toggle(true,'* La relación activo_fijo_id, número_factura y tercero_id ya existe, No se permite duplicar','error')
            }
            //console.log("entro a validar unico",data);
            this.establecerPropiedades('13','disable','false');
        }else {
            this.establecerPropiedades('13','disable','false');
        }
    }

    successCrear(data){
        if(data.estado_p === 200){
            //console.log("creo exitosamente",data);
            this.limpiarCampos();
            this.establecerPropiedades('13','disable','false');
        }
    }

    confirmCancelar(){
        this.getField('confirmModalCustom').setTitleAndContent('Cancelar','Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.limpiarCampos);
        this.getField('confirmModalCustom').toggle(true);
    }

    limpiarCampos(){
        this.getField('confirmModalCustom').toggle(false);
        this.establecerPropiedades('2,3,5,6,7,8,9,10,11,12,14','value','');
        this.establecerPropiedades('2,3,5,6,7,8,9,10,11,12,14','setError',false);
        //this.establecerPropiedades('','visible','');
    }


    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            //console.log(element);
            //console.log(this.arrayObjetos.get(parseInt(element)));
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }else if(sPropiedad === 'setError'){
                if(sValor === false){
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
        });
    }


    cargarCampos(data) {
        this.getField('comprasactivfijo_id').setValue(data.data[0].comprasactivfijo_id);
        this.getField('activofijo').setValue(data.data[0].activofijo);
        this.getField('descripcion_').setValue(data.data[0].descripcion_);
        this.getField('activosfijos_id').setValue(data.data[0].activosfijos_id);
        this.getField('nit_tercero').setValue(data.data[0].nit_tercero);
        this.getField('sede').setValue(data.data[0].sede);
        this.getField('nombre').setValue(data.data[0].nombre);
        this.getField('numero_factura').setValue(data.data[0].numero_factura);
        this.getField('fecha_factura').setValue(data.data[0].fecha_factura);
        this.getField('valor_sin_impuestos').setValue(data.data[0].valor_sin_impuestos);
        this.getField('valor_con_impuestos').setValue(data.data[0].valor_con_impuestos);
        this.getField('aceptar').setValue(data.data[0].Aceptar);
        this.getField('terceros_id').setValue(data.data[0].terceros_id);
        this.getField('cancelar').setValue(data.data[0].cancelar);

    }

}
FormJaivana.addController('maes-activosfijoscompras', CustomActivosFijosCompras);
export default CustomActivosFijosCompras;
