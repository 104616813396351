import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomGastos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.gridOptions                = Object.assign({},this.gridOptions);
        this.opcionConfigurarGastos     = this.opcionConfigurarGastos.bind(this);
        this.opcionInformesGastos       = this.opcionInformesGastos.bind(this); 
        this.vercuentas                 = this.vercuentas.bind(this);
        this.successTraerCuenta         = this.successTraerCuenta.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
        this.validarcuenta              = this.validarcuenta.bind(this);
        this.successValidar             = this.successValidar.bind(this);
        this.guardarCuenta              = this.guardarCuenta.bind(this);
        this.successCrear               = this.successCrear.bind(this);
        this.validarCodigo              = this.validarCodigo.bind(this);
        this.validarCodigoGasto         = this.validarCodigoGasto.bind(this);
        this.successvalidarCodigoGasto  = this.successvalidarCodigoGasto.bind(this);
        this.successModificar           = this.successModificar.bind(this);
        this.successGuardar             = this.successGuardar.bind(this);
        this.dias_maximo=0;
        this.validarFechas                                          = this.validarFechas.bind(this);
        this.procesar                                               = this.procesar.bind(this);
        this.cargarFechas                                           = this.cargarFechas.bind(this);
        this.limpiarErrores                                         = this.limpiarErrores.bind(this);
        this.traerDias                                              = this.traerDias.bind(this);
        this.successTraerDias                                       = this.successTraerDias.bind(this);
        this.validarCampos                                          = this.validarCampos.bind(this);
        this.codigo_sucursal ='';
        this.sucessInforme                                          = this.sucessInforme.bind(this);
        this.limpiarCampos                                          = this.limpiarCampos.bind(this);
        this.successDeleteTmp                                       = this.successDeleteTmp.bind(this);
        this.limpiarTemporal                                        = this.limpiarTemporal.bind(this);
        this.successTraerDatos                                      = this.successTraerDatos.bind(this);
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                                      = this.formatNumberSaldo.bind(this);
        this.gridOptionsComponentes                                 = Object.assign({},this.gridOptions);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.ocultar                                                = this.ocultar.bind(this);
        this.limpiarGastos                                          = this.limpiarGastos.bind(this);
        this.sucessOnBlurCodigo                                     = this.sucessOnBlurCodigo.bind(this);
        this.preguntarModificar                                     = this.preguntarModificar.bind(this);
        this.deshabilitarGastos                                     = this.deshabilitarGastos.bind(this);
        this.usar_onblut=true;
        this.mostrarMensajeNoHayDatos                                    = this.mostrarMensajeNoHayDatos.bind(this);

        

    }

    initForm(){
        console.log('Formulario CustomGastos,  @version: jdesk_1.01.0001.1, @author:Patricia López Sánchez, @updateBy Anderson Acevedo')
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        if(this.props.data[0].opcion_sub_seccion === 1){
           this.opcionConfigurarGastos();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
           this.opcionInformesGastos();
        }

        //configurar gastos
        this.getField('descripcion').setDisabled(true);
        this.getField('descripcion_corta').setDisabled(true);
        this.getField('descripcion').setOnChange(()=>{this.getField('descripcion').setError(false,'');this.limpiar()});
        this.getField('descripcion_corta').setOnChange(()=>{this.getField('descripcion_corta').setError(false,'');this.limpiar()});
        this.getField("codigo_gasto").setOnBlur(this.validarCodigo);
        this.getField('aux_concepto').setOnChange((props)=>{
            if(this.getField('aux_concepto').getValue()!==''){
                this.preguntarModificar();
                this.vercuentas();
            }
            this.usar_onblut=true
                
        });
        this.getField('cuenta').setOnChange(()=>{this.getField('cuenta').setError(false,'');this.limpiar()});
        //informes
        this.getField('fecha').setValue(this.fecha_hoy);
        this.getField("fecha").setOnChange(()=>{this.validarFechas()});
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.traerDias();
        this.getField('procesar').setClick(this.validarCampos);
        this.getField('nombre_sucursal').setOnChange(()=>{this.getField('nombre_sucursal').setError(false,'');this.ocultar()});
        this.getField('centro_costo_nombre').setOnChange(()=>{this.getField('centro_costo_nombre').setError(false,'');this.ocultar()});
        this.getField('nombre_dptointerno').setOnChange(()=>{this.getField('nombre_dptointerno').setError(false,'');this.ocultar()});
        this.getField('select_gastos').setOnChange(this.ocultar);
        this.getField('ch_promedio').setOnChange(this.ocultar);

    }
    preguntarModificar(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El código gasto '+this.getField('codigo_gasto').getValue()+' ya existe, ¿Desea modificarlo?');
        this.getField('confirmModalCustom').setClickDialog(() => { 
            this.getField('descripcion').setDisabled(false);
            this.getField('descripcion_corta').setDisabled(false); 
            this.getField('confirmModalCustom').toggle(false); 
            this.getField('actualizar').setDisabled(false);
        });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.deshabilitarGastos();
    }
    ocultar(){
        this.getField('rejilla_informe').toggle(false);
        this.getField('procesar').setDisabled(false);
    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.informes_gastos_dias_devolver;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    validarFechas(){
        this.ocultar();
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("fecha").getValue()).toISOString().split('T')[0];

        this.getField("fecha").setError(false,"");

        if(fecha_desde > fecha_actual){
            this.getField("fecha").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        let fecha=new Date(fecha_actual);
        fecha.setDate(fecha.getDate()-this.dias_maximo);
        fecha=fecha.toISOString().split('T')[0];
        if(fecha_desde < fecha){
            this.getField("fecha").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        this.getField('procesar').setDisabled(false); 
        return true;

    }

    validarCampos(){
        this.limpiarErrores();
        let errores =0;
        let valFechas=this.validarFechas();
        if(valFechas===false){
          errores++;
        };
        if(this.getField('codigo_sucursal').getValue()!==''){
            if(!this.getField('codigo_sucursal').valid() ||  !this.getField('nombre_sucursal').valid()){
                errores++;
            }
        }
        if(this.getField('centro_costo_codigo').getValue()!=='' ){
            if(!this.getField('centro_costo_codigo').valid() ||  !this.getField('centro_costo_nombre').valid()){
                errores++;
            }
        }
        if(this.getField('codigo_dptointerno').getValue()!==''){
            if(!this.getField('codigo_dptointerno').valid() ||  !this.getField('nombre_dptointerno').valid()){
                errores++;
            }
        }
        if(!this.getField('select_gastos').valid() || this.getField('select_gastos').getValue()==='' || this.getField('select_gastos').getValue()===' '){
            this.getField('select_gastos').setError(true,'*Este campo es requerido.')
            errores++;
        }
        if(errores===0){
          this.procesar();
        }
      }
    
      procesar(){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField('confirmModalCustom').setClickDialog(() => {});
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            this.getField('procesar').setDisabled(true);
            let datos={ datos: {
                fecha: this.getField('fecha').getValue(),
                select_gastos: this.getField('select_gastos').getValue(),
                ch_promedio: this.getField('ch_promedio').getValue(),
                sucursal_ingreso:this.codigo_sucursal
            }};
            if(this.getField('codigo_sucursal').getValue()!=='')
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('centro_costo_codigo').getValue()!=='')
                datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue();
            if(this.getField('codigo_dptointerno').getValue()!=='')
                datos.datos.codigo_dptointerno=this.getField('codigo_dptointerno').getValue();
            this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: 'informesgastos', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessInforme,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
      }

      sucessInforme(data){
        if (data.estado_p === 200 )
        {

            let datos={ datos: {sucursal_ingreso: this.codigo_sucursal}};
            this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: '1_informe', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerDatos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
        else 
        { 
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.limpiarCampos();
                this.mostrarMensajeNoHayDatos();
            }
            else {
                //this.limpiarCampos();
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporal();
        }
    }

    successTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {   
            this.getField('rejilla_informe').toggle(true);
            this.gridOptionsComponentes['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('enero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.enero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('febrero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.febrero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('marzo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.marzo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('abril', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.abril) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('mayo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.mayo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('junio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.junio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('julio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.julio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('agosto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.agosto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('septiembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.septiembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('octubre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.octubre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('noviembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.noviembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('diciembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.diciembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xtotal) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('xpromedio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xpromedio) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla_informe').initData(this.gridOptionsComponentes,configCell);
            this.limpiarTemporal();
        }
        else 
        { 
            this.getField('rejilla_informe').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.limpiarCampos();
                this.mostrarMensajeNoHayDatos();
            }
            else {
                //this.limpiarCampos();
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporal();
        }
    }

    limpiarGastos(){
        this.usar_onblut=true;
        this.getField('codigo_gasto').setValue('');
        this.getField('descripcion').setValue('');
        this.getField('descripcion_corta').setValue('');
        this.getField('codigo_gasto').setDisabled(false);
        this.getField('descripcion').setDisabled(true);
        this.getField('descripcion_corta').setDisabled(true);
        this.getField('actualizar').setDisabled(false);
    }

    deshabilitarGastos(){
        this.getField('codigo_gasto').setDisabled(true);
        this.getField('descripcion').setDisabled(true);
        this.getField('descripcion_corta').setDisabled(true);
        this.getField('actualizar').setDisabled(true);
    }

    opcionConfigurarGastos(){
        this.getField('codigo_gasto').setVisible(true);
        this.getField('descripcion').setVisible(true);
        this.getField('descripcion_corta').setVisible(true);
        this.getField('actualizar').setVisible(true);
        this.getField('cancelar').setVisible(true);
        this.getField('codigo_cuenta').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('agregar_cuenta').setVisible(true);
        
        this.getField('codigo_gasto').setOnChange(this.limpiar);
        this.getField('cancelar').setClick(this.limpiarGastos);
        this.getField('agregar_cuenta').setClick(this.validarcuenta);
        this.getField('actualizar').setClick(this.validarCodigoGasto);

        this.getField("codigo_gasto").setCustomSuccess(()=>{this.usar_onblut=false});

        //informes gastos
        this.getField('fecha').setVisible(false);
        this.getField('codigo_sucursal').setVisible(false);
        this.getField('nombre_sucursal').setVisible(false);
        this.getField('centro_costo_codigo').setVisible(false);
        this.getField('centro_costo_nombre').setVisible(false);
        this.getField('codigo_dptointerno').setVisible(false);
        this.getField('nombre_dptointerno').setVisible(false);
        this.getField('select_gastos').setVisible(false);
        this.getField('ch_promedio').setVisible(false);
        this.getField('procesar').setVisible(false);


    }



    validarCodigo(){
        //cuando no sea buscado es decir enter,f9 o boton
        if(this.usar_onblut){
            if(this.getField('codigo_gasto').valid() && this.getField('aux_concepto').getValue()===''){
                let datos = { datos: {
                    value: this.getField('codigo_gasto').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'cont-conceptosgastos', operacion: '1', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessOnBlurCodigo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        } 
    }

        
   }

   sucessOnBlurCodigo(data){
        if(data.data.length === 0){
            this.alertGeneral.toggle(true, 'El código gasto '+this.getField('codigo_gasto').getValue()+' no se encontro, se puede crear.', 'success');
            this.operacion = 'creó'; 
            this.getField('descripcion').setDisabled(false);
            this.getField('descripcion_corta').setDisabled(false);
        }else if(data.data.length > 0){
            this.getField('descripcion').setValue(data.data[0].descripcion);
            this.getField('descripcion_corta').setValue(data.data[0].descripcion_corta);
            this.getField('aux_concepto').setValue(data.data[0].descripcion_corta);
            this.data=data;
        }
   }

    vercuentas(){
        if(this.getField('codigo_gasto').valid() && this.getField('descripcion').valid()){

            let datos = { datos: {
                codigo_gasto: this.getField('codigo_gasto').getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerCuenta,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        } 
    }

    successTraerCuenta(data){
        if (data.estado_p === 200 )
        {
            let configCell = new Map();
            this.getField('rejilla').toggle(true);
            this.gridOptions['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptions,configCell);
        }
        else 
        {
            if(data.estado_p===404) {
                this.getField('rejilla').toggle(false);
                this.alertGeneral.toggle(true, 'No hay cuentas relacionadas al código gasto '+this.getField('codigo_gasto').getValue(), 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    limpiar(){
        if(this.getField('descripcion').getValue() === ''){
            this.getField('rejilla').toggle(false);
        }
    }


    validarcuenta(){
        if(this.getField('codigo_gasto').valid() && this.getField('descripcion').valid() && this.getField('codigo_cuenta').valid() 
        && this.getField('nombre').valid()){

            let datos = { datos: {
                codigo_gasto: this.getField('codigo_gasto').getValue(),
                codigo_cuenta:this.getField('codigo_cuenta').getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        } 
    }

    successValidar(data){
        if (data.estado_p === 200 )
        {
            if(data.data[0].cuantos > 0){
                this.alertGeneral.toggle(true, 'Error la cuenta '+this.getField('codigo_cuenta').getValue()+' ya esta relacionada al código gasto '+this.getField('codigo_gasto').getValue(), 'error');
                this.getField('codigo_cuenta').setValue('');
                this.getField('nombre').setValue('');
            }else if(data.data[0].cuantos === 0){
                this.guardarCuenta();  
            }  
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error No hay datos', 'error');            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    guardarCuenta(){
        let datos = { datos: {
            codigo_gasto: this.getField('codigo_gasto').getValue(),
            codigo_cuenta:this.getField('codigo_cuenta').getValue()
        }};
    
        this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successCrear,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successCrear(data){
        if (data.estado_p === 200 )
        {
            this.vercuentas();
            this.getField('codigo_cuenta').setValue('');
            this.getField('nombre').setValue('');
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error al momento de insertar', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    validarCodigoGasto(){
        if(this.getField('codigo_gasto').valid() && this.getField('descripcion').valid() && this.getField('descripcion_corta').valid()){
            //Expresión que acepta números letras y guion medio entre palabras
            const expresion=/^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ]+(\u002D[[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ]+)*$/;
            if(this.getField('codigo_gasto').getValue().match(expresion)){
                let datos = { datos: {
                    value: this.getField('codigo_gasto').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'cont-conceptosgastos', operacion: '1', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successvalidarCodigoGasto,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }else{
                this.getField('codigo_gasto').setError(true,'* El campo acepta números, letras y guion medio(Excepto al iniciar, finalizar o seguido del mismo).')
            }    
        } 
    }

    successvalidarCodigoGasto(data){
        if (data.estado_p === 200 )
        {
            let datos = { datos: {
                codigo_gasto: this.getField('codigo_gasto').getValue(),
                descripcion: this.getField('descripcion').getValue(),
                descripcion_corta: this.getField('descripcion_corta').getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: 'modificarconceptosgastos', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successModificar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
        else 
        {
            if(data.estado_p===404) {
                let datos = { datos: {
                    codigo_gasto: this.getField('codigo_gasto').getValue(),
                    descripcion: this.getField('descripcion').getValue(),
                    descripcion_corta: this.getField('descripcion_corta').getValue()
                }};
            
                this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: 'guardarconceptosgastos', operacion_tipo: 'crear' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successGuardar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    successGuardar(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Se creo el registro.', 'success');
            this.deshabilitarGastos();
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error al momento de insertar', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }
  
    successModificar(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Se modifico el registro '+this.getField('codigo_gasto').getValue(), 'success');
            this.deshabilitarGastos();
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error al momento de modificar.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    opcionInformesGastos(){
        this.getField('codigo_gasto').setVisible(false);
        this.getField('descripcion').setVisible(false);
        this.getField('descripcion_corta').setVisible(false);
        this.getField('actualizar').setVisible(false);
        this.getField('cancelar').setVisible(false);
        this.getField('codigo_cuenta').setVisible(false);
        this.getField('nombre').setVisible(false);
        this.getField('agregar_cuenta').setVisible(false);


        this.getField('fecha').setVisible(true);
        this.getField('codigo_sucursal').setVisible(true);
        this.getField('nombre_sucursal').setVisible(true);
        this.getField('centro_costo_codigo').setVisible(true);
        this.getField('centro_costo_nombre').setVisible(true);
        this.getField('codigo_dptointerno').setVisible(true);
        this.getField('nombre_dptointerno').setVisible(true);
        this.getField('select_gastos').setVisible(true);
        this.getField('ch_promedio').setVisible(true);
        this.getField('procesar').setVisible(true);

    }

    limpiarErrores(){
        this.getField('fecha').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('nombre_sucursal').setError(false,'');
        this.getField('centro_costo_codigo').setError(false,'');
        this.getField('fecha').setError(false,'');
        this.getField('centro_costo_nombre').setError(false,'');
        this.getField('codigo_dptointerno').setError(false,'');
        this.getField('nombre_dptointerno').setError(false,'');
        this.getField('select_gastos').setError(false,'');
        this.getField('ch_promedio').setError(false,'');
      }

    cargarFechas(){
        this.getField('fecha').setValue(this.fecha_hoy);
    }

    limpiarTemporal(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-gastos', operacion: '7_1', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp(data){
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    limpiarCampos(){
        this.getField('fecha').setValue(this.fecha_hoy);
        this.getField('codigo_sucursal').setValue('');
        this.getField('nombre_sucursal').setValue('');
        this.getField('centro_costo_codigo').setValue('');
        this.getField('centro_costo_nombre').setValue('');
        this.getField('codigo_dptointerno').setValue('');
        this.getField('nombre_dptointerno').setValue('');
        this.getField('select_gastos').setValue('');
        this.getField('ch_promedio').setValue('');
        this.limpiarErrores();
        this.cargarFechas();
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

}
FormJaivana.addController("cont-gastos",CustomGastos);
export default CustomGastos