import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Cristian Ciro
 * 
 * @version jdesk_1.01.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomAdministracionProcesos extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                           = this.initForm.bind(this);

        this.gridOptionsTbPrincipal             = Object.assign({},this.gridOptions);
        this.gridOptionsActividades             = Object.assign({},this.gridOptions);
        this.gridOptionsFujoProceso             = Object.assign({},this.gridOptions);
        this.gridOptionsCondiciones             = Object.assign({},this.gridOptions);
        this.gridOptionsPreCondiciones             = Object.assign({},this.gridOptions);

        this.onSelectionChangedRowTableMov      = this.onSelectionChangedRowTableMov.bind(this);

        this.onSelectionChangedRowTableDefault  = this.onSelectionChangedRowTableDefault.bind(this);

        this.traerProcesosConfiguracion         = this.traerProcesosConfiguracion.bind(this);
        this.traerActividadesConfiguracion      = this.traerActividadesConfiguracion.bind(this);
        this.successProcesosConfiguracion       = this.successProcesosConfiguracion.bind(this);
        this.successActividadesConfiguracion    = this.successActividadesConfiguracion.bind(this);

        this.setButtonInactivar                 = this.setButtonInactivar.bind(this);
        this.inactivarProceso                   = this.inactivarProceso.bind(this);
        this.setButtonVerMas                    = this.setButtonVerMas.bind(this);
        this.showDiagram                        = this.showDiagram.bind(this);
        this.setButtonModificar                 = this.setButtonModificar.bind(this);

        this.successInactivarProceso            = this.successInactivarProceso.bind(this);
        this.modalProcesoConf                   = this.modalProcesoConf.bind(this);
        this.agregarProcesoConf                 = this.agregarProcesoConf.bind(this);
        this.agregarActividadConf               = this.agregarActividadConf.bind(this);
        this.successAgregararProceso            = this.successAgregararProceso.bind(this);
        this.successAgregararActividad          = this.successAgregararActividad.bind(this);

        this.siguienteTab                       = this.siguienteTab.bind(this);

        this.selectedProceso                    = undefined;

        this.setButtonEliminarAct                = this.setButtonEliminarAct.bind(this);
        this.setButtonSubir                     = this.setButtonSubir.bind(this);
        this.setButtonBajar                     = this.setButtonBajar.bind(this);

        this.eliminarActConf                    = this.eliminarActConf.bind(this);
        this.bajarActConf                       = this.bajarActConf.bind(this);
        this.subirActConf                       = this.subirActConf.bind(this);

        this.lengthArray                        = 0;
        this.allUser                            = [];

        this.getUsuarios                        = this.getUsuarios.bind(this);
        this.setSelectUsuarios                  = this.setSelectUsuarios.bind(this);
        this.traerUsuariosEncargados            = this.traerUsuariosEncargados.bind(this);

        this.successUsuariosEncargados          = this.successUsuariosEncargados.bind(this);
        this.valueChangeUsuarioEncargado        = this.valueChangeUsuarioEncargado.bind(this);

        this.siguienteTabFlujoProceso           = this.siguienteTabFlujoProceso.bind(this);
        this.siguienteTabCondiciones            = this.siguienteTabCondiciones.bind(this);
        this.successFlujoProcesoConf            = this.successFlujoProcesoConf.bind(this);

        this.setButtonEliminarCond               = this.setButtonEliminarCond.bind(this);
        this.setButtonVerMasCond                = this.setButtonVerMasCond.bind(this);
        this.crearFlujoProceso                  = this.crearFlujoProceso.bind(this);
        this.successFlujoProceso                = this.successFlujoProceso.bind(this);

        this.consultarPrecondiciones            = this.consultarPrecondiciones.bind(this);
        this.successPrecondicionesFlujo         = this.successPrecondicionesFlujo.bind(this);
        this.eliminarPrecondicionFlujo          = this.eliminarPrecondicionFlujo.bind(this);
        this.successEliminarPrecondicion        = this.successEliminarPrecondicion.bind(this);

        this.successAgregararUsuarioEncargado   = this.successAgregararUsuarioEncargado.bind(this);

        this.traerCondiciones                   = this.traerCondiciones.bind(this);
        this.successCondiciones                 = this.successCondiciones.bind(this);

        this.crearCondicion                     = this.crearCondicion.bind(this);
        this.crearCondicion2                    = this.crearCondicion2.bind(this);

        this.successCrearCondicion              = this.successCrearCondicion.bind(this);

        this.devolverCondiciones                = this.devolverCondiciones.bind(this);
        
    }

   
    initForm(){
        console.log('Formulario CustomAdministracionProcesos,  @version: jdesk_1.01.0001, @author:Cristian Ciro');
        this.traerProcesosConfiguracion(); 
        
        this.getField("agregar_proceso_configuracion").setDisabled(true);
        this.getField("agregar_proceso_configuracion").setClick(() => {this.getField("modal_conf_procesos").handleClickOpen()});
        this.getField("aceptar_proceso_configuracion").setClick(this.agregarProcesoConf);

        this.getField("btn_condiciones_devolver").setClick(this.devolverCondiciones);

        this.getField("btn_siguiente").setDisabled(true);
        this.getField("btn_siguiente").setClick(this.siguienteTab);

        this.getField("agregar_condiciones").setClick(() => {this.getField("modal_condiciones").handleClickOpen()});
        this.getField("agregar_precondiciones").setClick(() => {
            this.traerCondiciones();
            let opciones = [{value:" ",text:"Ninguno"}];
            let elementAnt = "";
            this.gridOptionsCondiciones['rowData'].forEach(element => {
                if(element.nombre !== elementAnt){
                    elementAnt = element.nombre;
                    let objectOption = {};
                    objectOption['value'] = element.id;
                    objectOption['text'] = element.nombre;
                    objectOption['campos_cambian'] = {/* separador_select: item.valor */ };
                    opciones.push(objectOption);
                }
                
            });
            this.getField('nombre_condicion2').setOptions(opciones);
            this.getField("modal_precondiciones").handleClickOpen()
        });

        this.getField("btn_aceptar_condicion").setClick(this.crearCondicion);
        this.getField("btn_aceptar_condicion2").setClick(this.crearCondicion2);

        this.getField("btn_siguiente2").setClick(() => this.siguienteTabFlujoProceso(false));
        this.getField("btn_condiciones").setClick(this.siguienteTabCondiciones);


        this.getField("btn_siguiente3").setClick(() => this.siguienteTabFlujoProceso(true));

        this.getField("agregar_actividades_configuracion").setClick(() => {this.getField("modal_act_configuracion").handleClickOpen()});
        this.getField("agregar_actividades_configuracion").setDisabled(true);
        this.getField("aceptar_actividad_configuracion").setClick(this.agregarActividadConf);

        this.getField("agregar_flujo_proceso").setDisabled(true);

        this.getField("agregar_flujo_proceso").setClick(() => {

            let opciones = [{value:" ",text:"Ninguno"}];
            this.gridOptionsActividades['rowData'].forEach(element => {
                let objectOption = {};
                objectOption['value'] = element.id;
                objectOption['text'] = element.nombre;
                objectOption['campos_cambian'] = {/* separador_select: item.valor */ };
                opciones.push(objectOption);
            });
            this.getField('actividad_inicia_id').setOptions(opciones);
            this.getField('actividad_genera_id').setOptions(opciones);
            this.getField("modal_flujo_proceso").handleClickOpen();
        });

        this.getField("aceptar_flujo_proceso").setClick(this.crearFlujoProceso);
        
    } 


    crearCondicion(){
        if(this.getField("nombre_condicion").valid() &
        this.getField("nombre_campo").valid() &
        this.getField("valor_campo").valid()){
            let datos = { datos: {
                condiciones: [
                    {
                        nombre:this.getField("nombre_condicion").getValue(),
                        precondiciones:[
                            {
                                nombre_campo:this.getField("nombre_campo").getValue(),
                                valor_campo:this.getField("valor_campo").getValue()
                            }
                        ]
                    }
                ]
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'proc-condiciones', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrearCondicion,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    crearCondicion2(){
        if(this.getField("nombre_condicion2").valid() &
        this.getField("nombre_campo2").valid() &
        this.getField("valor_campo2").valid()){
            let datos = { datos: {
                condicion_id:parseInt(this.getField("nombre_condicion2").getValue()),
                nombre_campo:this.getField("nombre_campo2").getValue(),
                valor_campo:this.getField("valor_campo2").getValue()
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'proc-precondiciones', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrearCondicion,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successCrearCondicion(data){
        if(data.estado_p === 200){
            this.getField("modal_condiciones").handleClose()
            this.getField("modal_precondiciones").handleClose()
            this.traerCondiciones();
        }
    }

    crearFlujoProceso(){
        if(this.getField("actividad_inicia_id").valid() &
        this.getField("actividad_genera_id").valid() &
        this.getField("condicion_id").valid()){
            if(this.getField("actividad_inicia_id").getValue() === this.getField("actividad_genera_id").getValue()){
                this.getField("actividad_genera_id").setError(true,"Debe ser distinto a la actividad inicia.");
            }else{
                let datos = { datos: {
                    actividades:[
                        {
                            procesos_actividad_inicia_id: parseInt(this.getField("actividad_inicia_id").getValue()),
                            procesos_actividad_genera_id: parseInt(this.getField("actividad_genera_id").getValue()),
                            condicion_id: parseInt(this.getField("condicion_id").getValue()),
                            orden:1
                        }
                    ]
                }};


                this.generalFormatPmv = { tipo_servicio: 'proc-flujoprocesoconfiguracion', operacion: '5', operacion_tipo: 'crear' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successFlujoProceso,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

            }

        }
    }

    successFlujoProceso(data){
        if(data.estado_p === 200){
            this.traerFlujoProcesoConf();

            this.getField("modal_flujo_proceso").handleClose();
        }
    }

    traerFlujoProcesoConf(){
        let actividades_conf = "";

        this.gridOptionsActividades['rowData'].forEach(element => {
            actividades_conf += element.id+",";
        });

        actividades_conf = actividades_conf.substring(0, actividades_conf.length - 1);
        //flujoprocesoconfiguracion
        let datos = { datos: {
            value: actividades_conf
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-flujoprocesoconfiguracion', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successFlujoProcesoConf,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    traerUsuariosEncargados(){
        let datos = { datos: {}};

        this.generalFormatPmv = { tipo_servicio: '13', operacion: '1', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successUsuariosEncargados,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }

    successUsuariosEncargados(data){
        if(data.estado_p === 200){
            this.allUser = data.data;
            this.traerActividadesConfiguracion();
        }
    }

    onSelectionChangedRowTableMov = () =>{
        this.selectedProceso = this.gridOptionsTbPrincipal.api.getSelectedRows();
        if(this.selectedProceso.length > 0){
            this.getField("btn_siguiente").setDisabled(false);
            this.getField("label_actividad_conf").setValue(this.selectedProceso[0].nombre);
            this.getField("label_flujo_conf").setValue(this.selectedProceso[0].nombre);
        }
    }

    onSelectionChangedRowTableDefault = () => {

    }

    siguienteTab(){
        this.getField('TabJaivana_111').setNextTab();
        this.getField('rejilla_actividades_configuracion').toggle(false);
        this.getField("btn_siguiente2").setDisabled(true);
        this.getField("btn_condiciones").setDisabled(true);
        this.traerUsuariosEncargados();
    }

    siguienteTabCondiciones(){
        if(this.gridOptionsActividades['rowData'].length >=2){
            let seguir = true;
            this.gridOptionsActividades['rowData'].forEach(element => {
                if(element.usuariospmv_id === null){
                    seguir = false;
                }
            });
    
            if(seguir){
                this.getField('TabJaivana_111').setNextTab();
                this.traerCondiciones();
            }else{
                this.alertGeneral.toggle(true, 'Debe llenar todos los campos de usuarios encargados.', 'error');
            }   
        }else{
            this.alertGeneral.toggle(true, 'Debe crear mínimo 2 actividades.', 'error');
        }
        
    }

    devolverCondiciones(){
        this.traerCondiciones();
        this.getField('TabJaivana_111').setTabNumber(3);
    }

    traerCondiciones(){
        // proc-condiciones
        this.getField('rejilla_condiciones').toggle(false);
        this.getField("agregar_condiciones").setDisabled(true);
        this.getField("agregar_precondiciones").setDisabled(true);
        this.getField("btn_siguiente3").setDisabled(true);
        let datos = { datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-condiciones', operacion: '2_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successCondiciones,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }


    successCondiciones(data){

        this.getField("agregar_condiciones").setDisabled(false);
        this.getField("agregar_precondiciones").setDisabled(false);
        this.getField("btn_siguiente3").setDisabled(false);

        if(data.estado_p === 200){
            this.gridOptionsCondiciones['rowData'] = data.data;
            this.gridOptionsCondiciones['onSelectionChanged'] = this.onSelectionChangedRowTableDefault;
            let configCell = new Map();
            //configCell.set('accion_1', { cellRenderer: this.setButtonVerMasCond, width: 110, sortable: false, filter: false, field: 'accion_1' });
            //configCell.set('accion_2', { cellRenderer: this.setButtonEliminarCond, width: 110, sortable: false, filter: false, field: 'accion_2' });

            this.getField('rejilla_condiciones').initData(this.gridOptionsCondiciones,configCell);
        }
    }

    siguienteTabFlujoProceso(cond){
        let seguir = true;
        let actividades_conf = "";

        if(this.gridOptionsActividades['rowData'].length >=2){
            this.gridOptionsActividades['rowData'].forEach(element => {
                if(element.usuariospmv_id === null){
                    seguir = false;
                }
                actividades_conf += element.id+",";
            });
    
            if(seguir){
                actividades_conf = actividades_conf.substring(0, actividades_conf.length - 1);
                if(cond)
                    this.getField('TabJaivana_111').setNextTab();
                else 
                    this.getField('TabJaivana_111').setTabNumber(4);
                this.getField('rejilla_flujo_proceso').toggle(false);
                //flujoprocesoconfiguracion
                let datos = { datos: {
                    value: actividades_conf
                }};
        
                this.generalFormatPmv = { tipo_servicio: 'proc-flujoprocesoconfiguracion', operacion: '1_2', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successFlujoProcesoConf,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
            }else{
                this.alertGeneral.toggle(true, 'Debe llenar todos los campos de usuarios encargados.', 'error');
            }
        }else{
            this.alertGeneral.toggle(true, 'Debe crear mínimo 2 actividades.', 'error');
        }
    }

    successFlujoProcesoConf(data){
        this.getField("agregar_flujo_proceso").setDisabled(false);
        if(data.estado_p === 200){
            this.gridOptionsFujoProceso['rowData'] = data.data;
            let configCell = new Map();
            this.gridOptionsFujoProceso['onSelectionChanged']= this.onSelectionChangedRowTableDefault;

            configCell.set('accion_1', { cellRenderer: this.setButtonVerMasCond, width: 110, sortable: false, filter: false, field: 'acción_1' });
            configCell.set('accion_2', { cellRenderer: this.setButtonEliminarCond, width: 110, sortable: false, filter: false, field: 'acción_2' });
            configCell.set('nombre_condicion', { cellRenderer:function(props){return props.data.nombre_condicion}, field: 'nombre_condición' });

            this.getField('rejilla_flujo_proceso').initData(this.gridOptionsFujoProceso,configCell);
        }else{
            this.lengthArray = 0;
            this.getField('rejilla_flujo_proceso').toggle(false);
        }
    }

    setButtonVerMasCond(props){
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.consultarPrecondiciones(props.data)};

        
        
        button.setAttribute("id", 'button_ver_mascond_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ver Mas");
        return this.createElementJaivana(button);
    }

    consultarPrecondiciones(data){
        let datos = { datos: {
            //value: id+""
            procesos_actividad_inicia_id:data.procesos_actividad_inicia_id,
            procesos_actividad_genera_id:data.procesos_actividad_genera_id
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-flujoprocesoconfiguracion', operacion: '2_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successPrecondicionesFlujo,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successPrecondicionesFlujo(data){
        if(data.estado_p === 200){
            this.gridOptionsPreCondiciones['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('nombre_condicion', { cellRenderer:function(props){return props.data.nombre_condicion},field: 'nombre_condición' });
            this.gridOptionsPreCondiciones['onSelectionChanged']= this.onSelectionChangedRowTableDefault;

            this.getField('rejilla_vermas_flujo_proceso').initData(this.gridOptionsPreCondiciones,configCell);
            this.getField('modal_vermas_flujo_proceso').handleClickOpen();
        }
    }

    setButtonEliminarCond(props){
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => {
                this.eliminarPrecondicionFlujo(id);
            });
            this.getField('confirmModalCustom').toggle(true);
        };
        
        button.setAttribute("id", 'button_eliminarcond_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    eliminarPrecondicionFlujo(id){
        this.getField('confirmModalCustom').toggle(false);
        let datos = { datos: {
            id: id
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-flujoprocesoconfiguracion', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminarPrecondicion,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successEliminarPrecondicion(data){
        if(data.estado_p === 200){
            this.traerFlujoProcesoConf();
        }
    }

    traerActividadesConfiguracion(){
        this.getField('rejilla_actividades_configuracion').toggle(false);
        this.getField("btn_siguiente2").setDisabled(true);
        this.getField("btn_condiciones").setDisabled(true);

        let id = this.selectedProceso[0].id;

        let datos = { datos: {
            value: id+""
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-procesosactividadesconfiguracion', operacion: '1_1', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successActividadesConfiguracion,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }

    successActividadesConfiguracion(data){
        this.getField("agregar_actividades_configuracion").setDisabled(false);
        if(data.estado_p === 200){
            this.getField("btn_siguiente2").setDisabled(false);
            this.getField("btn_condiciones").setDisabled(false);
            this.lengthArray = data.data.length;
            this.gridOptionsActividades['rowData'] = data.data;
            let configCell = new Map();
            this.gridOptionsActividades['onSelectionChanged']= this.onSelectionChangedRowTableDefault;
            

            configCell.set("usuario_encargado",{ cellRenderer: this.setSelectUsuarios, cellStyle: { backgroundColor: "rgb(238,238,238)",borderRadius:"5px",color:'rgb(0, 86, 201)' }, field: 'usuario_encargado' });
            configCell.set('accion_1', { cellRenderer: this.setButtonEliminarAct, width: 110, sortable: false, filter: false, field: 'acción_1' });
            configCell.set('accion_2', { cellRenderer: this.setButtonVerMas, width: 110, sortable: false, filter: false, field: 'acción_2' });
            configCell.set('subir', { cellRenderer: this.setButtonSubir, width: 110, sortable: false, filter: false, field: 'acción_2' });
            configCell.set('bajar', { cellRenderer: this.setButtonBajar, width: 110, sortable: false, filter: false, field: 'acción_3' });
            configCell.set('descripcion', {cellRenderer:function(props){return props.data.descripcion}, field: 'descripción' });


            this.getField('rejilla_actividades_configuracion').initData(this.gridOptionsActividades,configCell);
        }else{
            this.lengthArray = 0;
            this.getField('rejilla_actividades_configuracion').toggle(false);
        }
    }

    setButtonEliminarAct(props){
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => {
                this.eliminarActConf(id);
            });
            this.getField('confirmModalCustom').toggle(true);
        };
        
        button.setAttribute("id", 'button_eliminar_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    setSelectUsuarios(props){
        let maquina = props.data.usuariospmv_id;
        let select = document.createElement("select");
        let div = document.createElement('div');
        div.setAttribute('class', 'custom-select');
        select.setAttribute('class', 'custom-select');
        select.onchange = (event) => this.valueChangeUsuarioEncargado(event, props.data);
        select.setAttribute('id', 'mySelectOperario_');
        let opciones = this.getUsuarios();
        this.valor_idRec = 1;
        opciones.map((item) => {
            select.appendChild(item);
            if (parseInt(item.value) === maquina)
                this.valor_idRec = parseInt(item.value);
            return null;
        })
        div.appendChild(select)
        if(maquina !== null && maquina !== undefined)
            select.value = this.valor_idRec;
        return this.createElementJaivana(div);
    }

    valueChangeUsuarioEncargado(e,data){

        let valor = e.target.value;
        let id_reg = data.id;

        // proc-procesosactividadesencargadosconfiguracion
        let datos = { datos: {
            procesos_actividades_configuracion_id: id_reg,
            usuariospmv_id: parseInt(valor)
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-procesosactividadesencargadosconfiguracion', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successAgregararUsuarioEncargado,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successAgregararUsuarioEncargado(data){
        //if(data.estado_p !== 200){
            this.traerActividadesConfiguracion();
        //}
    }

    getUsuarios(){
        let opcionN = document.createElement('option');
        opcionN.setAttribute('value',' ');
        opcionN.textContent = 'Ninguno';

        let datos = [opcionN];
        this.allUser.map((item) =>{
            let opcion = document.createElement('option');
            opcion.setAttribute('value',parseInt(item.usuarios_id));
            opcion.textContent = item.usuarios_nombre;
            datos.push(opcion);
            return null;
           
        })
        return datos;
    }

    eliminarActConf(id){
        this.getField('confirmModalCustom').toggle(false);
        let datos = { datos: {
            id: id
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-procesosactividadesconfiguracion', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successAgregararActividad,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    setButtonBajar(props){
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.bajarActConf(id);};
        button.setAttribute("class", "buttonStyle");

        if(props.data.orden === 1){
            button.setAttribute('disabled',true);
            button.setAttribute('class','buttonDisabled');
        }
        
        button.setAttribute("id", 'button_bajar_' + id);
        button.setAttribute("type", "button");
        button.setAttribute("value", "Subir");
        return this.createElementJaivana(button);
    }

    bajarActConf(id){
        let datos = { datos: {
            id: id
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-procesosactividadesconfiguracion', operacion: '6_2', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successAgregararActividad,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    setButtonSubir(props){
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.subirActConf(id);};
        button.setAttribute("class", "buttonStyle");

        if(props.data.orden === this.lengthArray){
            button.setAttribute('disabled',true);
            button.setAttribute('class','buttonDisabled');
        }
        
        button.setAttribute("id", 'button_subir_' + id);
        button.setAttribute("type", "button");
        button.setAttribute("value", "Bajar");
        return this.createElementJaivana(button);
    }

    subirActConf(id){
        let datos = { datos: {
            id: id
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-procesosactividadesconfiguracion', operacion: '6_1', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successAgregararActividad,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    traerProcesosConfiguracion(){
        let datos = { datos: {}};

        this.generalFormatPmv = { tipo_servicio: 'proc-procesosconfiguracion', operacion: '1', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successProcesosConfiguracion,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }

    successProcesosConfiguracion(data){
        this.getField("agregar_proceso_configuracion").setDisabled(false);
        if(data.estado_p === 200){

            this.gridOptionsTbPrincipal['rowData'] = data.data;
            let configCell = new Map();
            this.gridOptionsTbPrincipal['onSelectionChanged']= this.onSelectionChangedRowTableMov;

            configCell.set('accion_1', { cellRenderer: this.setButtonInactivar, width: 110, sortable: false, filter: false, field: 'acción_1' });
            configCell.set('accion_2', { cellRenderer: this.setButtonVerMas, width: 110, sortable: false, filter: false, field: 'acción_2' });
            configCell.set('accion_3', { cellRenderer: this.setButtonModificar, width: 110, sortable: false, filter: false, field: 'acción_3' });
            configCell.set('descripcion', { cellRenderer:function(props){return props.data.descripcion},field: 'descripción' });

            this.getField('rejilla_procesos_configuracion').initData(this.gridOptionsTbPrincipal,configCell);
        }else{
            this.getField('rejilla_procesos_configuracion').toggle(false);
        }
    }

    setButtonInactivar(props){
        let id = props.data.id;
        let estado = props.data.estadosactivos_id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.inactivarProceso(id,estado);};
        
        button.setAttribute("id", 'button_inactivar_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");

        button.setAttribute("value", props.data.estadosactivos_id===1?"Inactivar":"Activar");
        return this.createElementJaivana(button);
    }

    inactivarProceso(id,estado){
        let datos = { datos: {
            estadosactivos_id: estado===1?2:1,
            id: id,
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-procesosconfiguracion', operacion: '6_1', operacion_tipo: 'modificar' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.successInactivarProceso,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }

    successInactivarProceso(data){
        if(data.estado_p === 200){
            this.traerProcesosConfiguracion();
        }
    }

    setButtonVerMas(props){
        let id = props.data.id;
        let diagrama = props.data.diagrama;
        let button = document.createElement("input");
        button.onclick = ()=>{this.showDiagram(diagrama);};
        
        button.setAttribute("id", 'button_inactivar_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ver mas");
        return this.createElementJaivana(button);
    }

    showDiagram(process_diagram){
        this.getField("modal_diagrama_conf_procesos").handleClickOpen();
        this.getField("img_diagrama").setValue(process_diagram);
    }

    setButtonModificar(props){
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.modalProcesoConf(props.data);};
        
        button.setAttribute("id", 'button_inactivar_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Modificar");
        return this.createElementJaivana(button);
    }

    modalProcesoConf(data){
        this.getField("id_proceso").setValue(data.id);
        this.getField("nombre").setValue(data.nombre);
        this.getField("descripcion").setValue(data.descripcion);
        this.getField("diagrama").setValue(data.diagrama);
        this.getField("estadosactivos_id").setValue(data.estadosactivos_id);
        this.getField("modal_conf_procesos").handleClickOpen();
    }

    agregarProcesoConf(){
        if(this.getField("nombre").valid() & 
        this.getField("descripcion").valid() & 
        this.getField("diagrama").valid() & 
        this.getField("estadosactivos_id").valid() 
        ){
            let datos = { datos: {
                nombre:this.getField("nombre").getValue(),
                descripcion:this.getField("descripcion").getValue(),
                diagrama:this.getField("diagrama").getValue(),
                estadosactivos_id: this.getField("estadosactivos_id").getValue(),
            }};
            let metodo = "POST";
            if(this.getField("id_proceso").getValue() !== ""){
                datos.datos["id"] = this.getField("id_proceso").getValue();
                metodo = "PUT";
                this.generalFormatPmv = { tipo_servicio: 'proc-procesosconfiguracion', operacion: '6', operacion_tipo: 'modificar' };
            }else{
                this.generalFormatPmv = { tipo_servicio: 'proc-procesosconfiguracion', operacion: '5', operacion_tipo: 'crear' };
            }
            
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:metodo,
                      body: datos,
                      success: this.successAgregararProceso,
                      error: this.error_,
                      general: this.generalFormatPmv
              });
        }
    }

    successAgregararProceso(data){
        if(data.estado_p === 200){
            this.traerProcesosConfiguracion();
            this.getField("modal_conf_procesos").handleClose();
        }
    }

    agregarActividadConf(){
        if(this.getField("nombre_act").valid() & 
        this.getField("descripcion_act").valid() & 
        this.getField("nombre_formulario").valid() & 
        this.getField("estadoactivo_id_actividad").valid() 
        ){
            //proc-procesosactividadesconfiguracion
            let datos = { datos: {
                nombre:this.getField("nombre_act").getValue(),
                descripcion:this.getField("descripcion_act").getValue(),
                proceso_configuracion_id:this.selectedProceso[0].id,
                estadosactivos_id: this.getField("estadoactivo_id_actividad").getValue(),
                formulario_jdesk_id:this.getField("id_formulario").getValue(),
                tabla:".",
                orden:2,
            }};
            let metodo = "POST";
            this.generalFormatPmv = { tipo_servicio: 'proc-procesosactividadesconfiguracion', operacion: '5', operacion_tipo: 'crear' };
            
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:metodo,
                body: datos,
                success: this.successAgregararActividad,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successAgregararActividad(data){
        if(data.estado_p === 200){
            this.traerActividadesConfiguracion();
            this.getField("modal_act_configuracion").handleClose();
        }
    }

   
}
FormJaivana.addController("proc-administracionprocesos",CustomAdministracionProcesos);
export default CustomAdministracionProcesos