import FormJaivana from "dashboard_jaivana_v1";
/*
* @description: CustonArticulosServicio
* @author: Patricia Lopez Sanchez
* @updateBy Jose Albeiro Marin
* @version: jdesk_1.01.0001.1
**/
class CustonArticulosServicio extends FormJaivana.form {
   constructor(props) {
       super(props);
       this.initForm                       = this.initForm.bind(this);
       this.limpiar                        = this.limpiar.bind(this);
       this.traercodigo                    = this.traercodigo.bind(this);
       this.successTraerDatos              = this.successTraerDatos.bind(this);
       this.llenarcampos                   = this.llenarcampos.bind(this);
       this.llenardatos                     = this.llenardatos.bind(this);
       this.consultarcuenta                = this.consultarcuenta.bind(this);
       this.successTraerCuenta             = this.successTraerCuenta.bind(this);
       this.operacion                      = '';    
       this.guardar                        = this.guardar.bind(this);
       this.successguardar                 = this.successguardar.bind(this);
       this.limpiar_errores                = this.limpiar_errores.bind(this);   
       this.validarnombre                  = this.validarnombre.bind(this);
       this.successValidarNombre           = this.successValidarNombre.bind(this);
       this.validarcancelarconfirmar       = this.validarcancelarconfirmar.bind(this);
       this.eliminarguion                  = this.eliminarguion.bind(this);
       this.validarCodigo                  = this.validarCodigo.bind(this);
       this.data = [];
   }

   initForm() {
       console.log("Formulario CustonArticulosServicio,  @version: jdesk_1.01.0001.1, @author: Patricia Lopez Sanchez.");

       this.getField("cancelar").setClick(this.limpiar);
       this.getField("codigo").setCustomSuccess(this.validarCodigo);
       this.getField("codigo").setOnBlur(this.traercodigo);
       this.getField("id").setOnChange(this.llenardatos);
       this.getField("precio").setKeyUp(()=>{this.eliminarguion("precio")});
       this.getField("iva").setKeyUp(()=>{this.eliminarguion("iva")});
       this.getField("iva").setOnBlur(()=>{this.getField("iva").getValue() === 0 || this.getField("iva").getValue() === ''? this.getField("iva").setError(false,''):this.getField("iva").valid()});
       this.getField("retefuente").setOnBlur(()=>{this.getField("retefuente").getValue() === 0 || this.getField("retefuente").getValue() === ''? this.getField("retefuente").setError(false,''):this.getField("retefuente").valid()});
       this.getField("retefuente").setKeyUp(()=>{this.eliminarguion("retefuente")});
       this.getField("reteiva").setOnBlur(()=>{this.getField("reteiva").getValue() === 0 || this.getField("reteiva").getValue() === ''? this.getField("reteiva").setError(false,''):this.getField("reteiva").valid()});
       this.getField("reteiva").setKeyUp(()=>{this.eliminarguion("reteiva")});
       this.getField("inc").setOnBlur(()=>{this.getField("inc").getValue() === 0 || this.getField("inc").getValue() === ''? this.getField("inc").setError(false,''):this.getField("inc").valid()});
       this.getField("inc").setKeyUp(()=>{this.eliminarguion("inc")});
       this.getField("reteica").setOnBlur(()=>{this.getField("reteica").getValue() === 0 || this.getField("reteica").getValue() === ''? this.getField("reteica").setError(false,''):this.getField("reteica").valid()});
       this.getField("reteica").setKeyUp(()=>{this.eliminarguion("reteica")});
       this.getField("Guardar").setClick(this.validarnombre);

   }

   validarCodigo(data){
        if(data.length === 0){
            this.alertGeneral.toggle(true, 'El código no se encontro, se puede crear.', 'success');
            this.operacion = 'creó'; 
        }if(data.length > 1){
            this.data=data;
        }
   }
   
   eliminarguion(campo){
        if(this.getField(campo).getValue() !== '' && this.getField(campo).getValue() === '-'){
            this.getField(campo).setValue(this.getField(campo).getValue().replace('-',''));
        }
   }
   
   traercodigo(){
        if(this.getField('nombre').getValue() === '' && this.getField('codigo').getValue() !== '' && this.getField('codigo').getValue().toString().length >= 4){
                let datos = { datos: {value: this.getField('codigo').getValue()}};
            
                this.generalFormatPmv = { tipo_servicio: 'cont-articuloservicios', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerDatos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
                    } 
   }

   successTraerDatos(data){
        if (data.estado_p === 200 )
        {
            this.entro=false;
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El código '+data.data[0].codigo+' ya existe, ¿Desea modificarlo?');
            this.getField('confirmModalCustom').setClickDialog(()=>{this.entro=true; this.llenarcampos(data);});
            this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField('confirmModalCustom').toggle(true);
            this.validarcancelarconfirmar();
            this.operacion = 'modificó'; 

        }
        else 
        {
            if(data.estado_p===404) {
                if (this.data.length === 0) {
                    this.alertGeneral.toggle(true, 'El código no se encontro, se puede crear.', 'success');
                    this.operacion = 'creó'; 
                }
            }
        }
   }

   llenardatos(){
        if (this.getField('id').getValue() !== '') {
            this.data.forEach(item => {
                if(item.id === this.getField('id').getValue()){
                    let dataTemp = [];
                    this.data = [];
                    dataTemp.push(item);
                    this.data["data"] = dataTemp;
                    this.data["estado_p"] = 200;
                }
                this.successTraerDatos(this.data)
            });
        }
    }

   validarcancelarconfirmar(){
        if(this.entro=== false){
            this.limpiar();
        }
    }

    llenarcampos(data){
        this.getField('confirmModalCustom').toggle(false);

        this.getField("codigo").setDisabled(true);
        this.getField("codigo").setValue(data.data[0].codigo);
        this.getField("nombre").setValue(data.data[0].nombre);
        this.getField("precio").setValue(data.data[0].precio);
        this.getField("aplica_descuento").setValue(data.data[0].aplica_descuento);
        this.getField("iva").setValue(data.data[0].iva);
        this.getField("inc").setValue(data.data[0].inc);
        this.getField("reteica").setValue(data.data[0].reteica);
        this.getField("reteiva").setValue(data.data[0].reteiva);
        this.getField("retefuente").setValue(data.data[0].retefuente);
        this.getField('cuenta_ingreso').setValue(data.data[0].cuenta_ingreso)
        this.getField("cuenta_devolucion").setValue(data.data[0].cuenta_devolucion);
        this.getField("cuenta_descuento").setValue(data.data[0].cuenta_descuento);
        this.getField("cuenta_retencion").setValue(data.data[0].cuenta_retencion);
        this.getField("cuenta_iva").setValue(data.data[0].cuenta_iva);
        this.getField("cuenta_reteica").setValue(data.data[0].cuenta_reteica);
        this.getField("cuenta_inc").setValue(data.data[0].cuenta_inc);
        this.getField("cuenta_reteiva").setValue(data.data[0].cuenta_reteiva);
        this.getField("cuenta_ic").setValue(data.data[0].cuenta_ic);

        if(this.getField('cuenta_ingreso').getValue() !==''){
            this.consultarcuenta(this.getField('cuenta_ingreso').getValue())
        }

        if(this.getField("cuenta_devolucion").getValue() !==''){
            this.consultarcuenta(this.getField('cuenta_devolucion').getValue())
        }

        if(this.getField("cuenta_descuento").getValue() !==''){
            this.consultarcuenta(this.getField('cuenta_descuento').getValue())
        }

        if(this.getField("cuenta_retencion").getValue() !==''){
           this.consultarcuenta(this.getField('cuenta_retencion').getValue())
        }

        if(this.getField("cuenta_iva").getValue() !==''){
            this.consultarcuenta(this.getField('cuenta_iva').getValue())
        }
        
        if(this.getField("cuenta_reteica").getValue() !==''){
            this.consultarcuenta(this.getField('cuenta_reteica').getValue())
        }

        if(this.getField("cuenta_inc").getValue() !==''){
            this.consultarcuenta(this.getField('cuenta_inc').getValue())
        }

        if(this.getField("cuenta_reteiva").getValue() !==''){
            this.consultarcuenta(this.getField('cuenta_reteiva').getValue())
        }

        if(this.getField("cuenta_ic").getValue() !==''){
            this.consultarcuenta(this.getField('cuenta_ic').getValue())
        }

        this.limpiar_errores();
   }

   consultarcuenta(cuenta){
        let datos = { datos: {value: cuenta.toString()}};

        this.generalFormatPmv = { tipo_servicio: 'cont-articuloservicios', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successTraerCuenta,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successTraerCuenta(data){
        if (data.estado_p === 200 )
        {

            this.nombre = data.data[0].nombre;
            if(this.getField('cuenta_ingreso').getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_base").setValue(this.nombre)
            }
            
            if(this.getField("cuenta_devolucion").getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_devolucion").setValue(this.nombre)
            }
    
            if(this.getField("cuenta_descuento").getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_descuento").setValue(this.nombre)
            }
    
            if(this.getField("cuenta_retencion").getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_retencion").setValue(this.nombre)
            }
    
            if(this.getField("cuenta_iva").getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_iva").setValue(this.nombre)
            }
            
            if(this.getField("cuenta_reteica").getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_reteica").setValue(this.nombre)
            }
    
            if(this.getField("cuenta_inc").getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_inc").setValue(this.nombre)
            }
    
            if(this.getField("cuenta_reteiva").getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_reteiva").setValue(this.nombre)
            }
    
            if(this.getField("cuenta_ic").getValue() === data.data[0].codigo_cuenta_texto){
                this.getField("nombre_ic").setValue(this.nombre)
            }
           
        }
        else 
        {
            if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
   }

   validarnombre(){
        if(this.getField('codigo').valid() && this.getField('nombre').valid() &&  this.getField('precio').valid() 
        && this.getField('iva').valid() && this.getField('reteiva').valid() && this.getField('inc').valid() 
        && this.getField('reteica').valid() && this.getField('retefuente').valid() && this.getField('cuenta_ingreso').valid()){
            let datos = { datos: {nombre: this.getField("nombre").getValue() }};
        
            this.generalFormatPmv = { tipo_servicio: 'cont-articuloservicios', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarNombre,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
   }

   successValidarNombre(data){
        if (data.estado_p === 200 )
        {
            if(this.operacion === 'modificó' && this.getField('codigo').getValue() === data.data[0].codigo){
                this.guardar();
            }else{
                this.alertGeneral.toggle(true, 'El nombre del artículo servicio ya existe.', 'error');
            }
        }
        else 
        {
            if(data.estado_p===404) {
              this.guardar();
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
   }

   guardar(){
        if(this.operacion === 'modificó' ){
            let datos = { datos: {
                id:this.getField('id').getValue(),
                codigo: this.getField('codigo').getValue(),
                nombre:this.getField("nombre").getValue(),
                precio:this.getField("precio").getValue(),
                aplica_descuento:this.getField("aplica_descuento").getValue(),
                cuenta_ingreso:this.getField('cuenta_ingreso').getValue() === '' ? 0 : parseInt(this.getField('cuenta_ingreso').getValue()),
                cuenta_devolucion:this.getField("cuenta_devolucion").getValue() === '' ? 0 : parseInt(this.getField('cuenta_devolucion').getValue()),
                cuenta_descuento:this.getField("cuenta_descuento").getValue() === '' ? 0 : parseInt(this.getField('cuenta_descuento').getValue()),
                cuenta_retencion:this.getField("cuenta_retencion").getValue() === '' ? 0 : parseInt(this.getField('cuenta_retencion').getValue()),
                cuenta_iva:this.getField("cuenta_iva").getValue() === '' ? 0 : parseInt(this.getField('cuenta_iva').getValue()),
                cuenta_reteica:this.getField("cuenta_reteica").getValue() === '' ? 0 : parseInt(this.getField('cuenta_reteica').getValue()),
                cuenta_inc:this.getField("cuenta_inc").getValue() === '' ? 0 : parseInt(this.getField('cuenta_inc').getValue()),
                cuenta_reteiva:this.getField("cuenta_reteiva").getValue() === '' ? 0 : parseInt(this.getField('cuenta_reteiva').getValue()),
                cuenta_ic:this.getField("cuenta_ic").getValue() === '' ? 0 : parseInt(this.getField('cuenta_ic').getValue()),
                inc:this.getField("inc").getValue(),
                reteica:this.getField("reteica").getValue(),
                reteiva:this.getField("reteiva").getValue(),
                retefuente:this.getField("retefuente").getValue(),
                iva:this.getField("iva").getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'cont-articuloservicios', operacion: '6_modificar', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successguardar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else if(this.operacion === 'creó' ){
            let datos = { datos: {
                codigo: this.getField('codigo').getValue(),
                nombre:this.getField("nombre").getValue(),
                precio:this.getField("precio").getValue(),
                aplica_descuento:this.getField("aplica_descuento").getValue(),
                cuenta_ingreso:this.getField('cuenta_ingreso').getValue() === '' ? 0 : parseInt(this.getField('cuenta_ingreso').getValue()),
                cuenta_devolucion:this.getField("cuenta_devolucion").getValue() === '' ? 0 : parseInt(this.getField('cuenta_devolucion').getValue()),
                cuenta_descuento:this.getField("cuenta_descuento").getValue() === '' ? 0 : parseInt(this.getField('cuenta_descuento').getValue()),
                cuenta_retencion:this.getField("cuenta_retencion").getValue() === '' ? 0 : parseInt(this.getField('cuenta_retencion').getValue()),
                cuenta_iva:this.getField("cuenta_iva").getValue() === '' ? 0 : parseInt(this.getField('cuenta_iva').getValue()),
                cuenta_reteica:this.getField("cuenta_reteica").getValue() === '' ? 0 : parseInt(this.getField('cuenta_reteica').getValue()),
                cuenta_inc:this.getField("cuenta_inc").getValue() === '' ? 0 : parseInt(this.getField('cuenta_inc').getValue()),
                cuenta_reteiva:this.getField("cuenta_reteiva").getValue() === '' ? 0 : parseInt(this.getField('cuenta_reteiva').getValue()),
                cuenta_ic:this.getField("cuenta_ic").getValue() === '' ? 0 : parseInt(this.getField('cuenta_ic').getValue()),
                inc:this.getField("inc").getValue(),
                reteica:this.getField("reteica").getValue(),
                reteiva:this.getField("reteiva").getValue(),
                retefuente:this.getField("retefuente").getValue(),
                iva:this.getField("iva").getValue()
            }};

            this.generalFormatPmv = { tipo_servicio: 'cont-articuloservicios', operacion: '5_crear', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successguardar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successguardar(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El artículo servicio se '+this.operacion+' de manera exitosa.', 'success');
            this.limpiar();
        }
        else 
        {
            if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'El código no se pudo crear.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

   limpiar(){
        this.operacion="";
        this.data = [];
        this.getField("codigo").setDisabled(false);

        this.getField('codigo').setValue('');
        this.getField("nombre").setValue('');
        this.getField("precio").setValue('');
        this.getField("iva").setValue('');
        this.getField("inc").setValue('');
        this.getField("reteica").setValue('');
        this.getField("reteiva").setValue('');
        this.getField("retefuente").setValue('');
        this.getField('cuenta_ingreso').setValue('')
        this.getField("nombre_base").setValue('');
        this.getField("cuenta_devolucion").setValue('');
        this.getField("nombre_devolucion").setValue('');
        this.getField("cuenta_descuento").setValue('');
        this.getField("nombre_descuento").setValue('');
        this.getField("cuenta_retencion").setValue('');
        this.getField("nombre_retencion").setValue('');
        this.getField("cuenta_iva").setValue('');
        this.getField("nombre_iva").setValue('');
        this.getField("cuenta_reteica").setValue('');
        this.getField("nombre_reteica").setValue('');
        this.getField("cuenta_inc").setValue('');
        this.getField("nombre_inc").setValue('');
        this.getField("cuenta_reteiva").setValue('');
        this.getField("nombre_reteiva").setValue('');
        this.getField("cuenta_ic").setValue('');
        this.getField("nombre_ic").setValue('');

        this.limpiar_errores();
    }

    limpiar_errores(){ 
        this.getField('codigo').setError(false,'');
        this.getField("nombre").setError(false,'');
        this.getField("precio").setError(false,'');
        this.getField("iva").setError(false,'');
        this.getField("inc").setError(false,'');
        this.getField("reteica").setError(false,'');
        this.getField("reteiva").setError(false,'');
        this.getField("retefuente").setError(false,'');
        this.getField('cuenta_ingreso').setError(false,'');
        this.getField("nombre_base").setError(false,'');
        this.getField("cuenta_devolucion").setError(false,'');
        this.getField("nombre_devolucion").setError(false,'');
        this.getField("cuenta_descuento").setError(false,'');
        this.getField("nombre_descuento").setError(false,'');
        this.getField("cuenta_retencion").setError(false,'');
        this.getField("nombre_retencion").setError(false,'');
        this.getField("cuenta_iva").setError(false,'');
        this.getField("nombre_iva").setError(false,'');
        this.getField("cuenta_reteica").setError(false,'');
        this.getField("nombre_reteica").setError(false,'');
        this.getField("cuenta_inc").setError(false,'');
        this.getField("nombre_inc").setError(false,'');
        this.getField("cuenta_reteiva").setError(false,'');
        this.getField("nombre_reteiva").setError(false,'');
        this.getField("cuenta_ic").setError(false,'');
        this.getField("nombre_ic").setError(false,'');
    }
}
FormJaivana.addController("cont-articuloservicios", CustonArticulosServicio);
export default CustonArticulosServicio;
