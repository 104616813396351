import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Daniel Felipe Aguirre O.
 *
 * @version jdesk_1.01.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomActivosFijosSeriales extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                                       = this.initForm.bind(this);
        this.servicioTraerTodos                                             = this.servicioTraerTodos.bind(this);
        this.successTbActivosFijosSeriales                                  = this.successTbActivosFijosSeriales.bind(this);
        this.setButtonTbActivosFijosSeriales                                = this.setButtonTbActivosFijosSeriales.bind(this);
        this.crearBotonTabla                                                = this.crearBotonTabla.bind(this);
        this.accionProcesar                                                 = this.accionProcesar.bind(this);
        this.validarEliminar                                                = this.validarEliminar.bind(this);
        this.successEliminar                                                = this.successEliminar.bind(this);
        this.gridOptionsTbActivosFijosSeriales                              = Object.assign({},this.gridOptions);
        
        this.btnAgregar                                                     = this.btnAgregar.bind(this);
        this.btnAceptarValidacion                                           = this.btnAceptarValidacion.bind(this);
        this.crearActivosFijosSeriales                                      = this.crearActivosFijosSeriales.bind(this);
        this.validarArchivosImagenes                                        = this.validarArchivosImagenes.bind(this);
        this.successCrearActivosFijosSeriales                               = this.successCrearActivosFijosSeriales.bind(this);
        this.habilitarBtnAceptar                                            = this.habilitarBtnAceptar.bind(this);
        this.confirmacionCancelar                                           = this.confirmacionCancelar.bind(this);
        this.btnCancelar                                                    = this.btnCancelar.bind(this);

        //variables globales
        this.estadoValidarArchivosImagenes                                  = false;
        this.enviar                                                         = false;
        this.imagen_url_1                                                   = "";
        this.archivoImagen1                                                 = "";
        this.allowedExtensions                                              = /(.jpg|.jpeg|.png|.JPG|.JPEG|.PNG)$/i;
    }

    initForm() {

        console.log('Formulario CustomActivosFijosSeriales,  @version: jdesk_1.01.0001, @author:Daniel Felipe Aguirre O.')
        this.servicioTraerTodos();
        this.getField('btn_agregar').setClick(this.btnAgregar);
        this.getField('btn_aceptar').setClick(this.btnAceptarValidacion);
        this.getField('btn_cancelar').setClick(this.confirmacionCancelar);
        
        // Para obtner el binario de las imagenes cargadas
        this.getField('imagen1_url').setOnChange((props) => {this.habilitarBtnAceptar(); this.validarArchivosImagenes(props); props.target !== '' && props.target !== undefined ? this.archivoImagen1 = props.target.files[0] : this.archivoImagen1 = '';});        
        
        //habilitar campos
        this.getField('codigo_activo_fijo').setOnChange(this.habilitarBtnAceptar);
        this.getField('descripcion_activo_fijo').setOnChange(this.habilitarBtnAceptar);
        this.getField('numero_serie').setOnChange(this.habilitarBtnAceptar);
        this.getField('modelo').setOnChange(this.habilitarBtnAceptar);
        this.getField('activosfijos_id').setOnChange(this.habilitarBtnAceptar);
    }

    servicioTraerTodos() { // traer todos los registros de la tabla activosfijos_seriales
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosseriales', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successTbActivosFijosSeriales,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successTbActivosFijosSeriales(data){
        
        if (data.estado_p === 200 && data.model && data.model.length >= 1) {

            let configCell = new Map();
            this.gridOptionsTbActivosFijosSeriales['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            configCell.set('accion', { cellRenderer: this.setButtonTbActivosFijosSeriales, headerName: 'Acción', sortable: false, filter: false });
            //se carga la configuracion de la tabla agregada en el modelo adicional se agrega el configcell el cual me permite renderizar 
            //botones dentro de la columna asiganada accion
            this.getField('tb_activosfijosseriales').initData(this.gridOptionsTbActivosFijosSeriales,configCell);
            this.getField('btn_agregar').setVisible(true);

        } else if (data.estado_p === 404) {//si no encuentra datos, no renderiza la tabla 
            
            this.getField('tb_activosfijosseriales').toggle(false);            
            this.getField('btn_agregar').setVisible(true); //habilita el botón agregar para que ejecute las acciones dentro de la función

        }
    }

    setButtonTbActivosFijosSeriales(props) {//renderización de botones en la tabla
        let fragment = document.createDocumentFragment();
        let buttonEliminar = this.crearBotonTabla(props.data, 'Eliminar');
        let buttonVerImagen = this.crearBotonTabla(props.data, 'Ver Imagen');
        buttonEliminar.setAttribute("class","buttonStyle2");
        buttonVerImagen.setAttribute("class","buttonStyle2");
        fragment.appendChild(buttonEliminar);
        fragment.appendChild(buttonVerImagen);
        return this.createElementJaivana(fragment);
    }

      /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      **/

    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.id}`);
                break;

            case 'Ver Imagen':
                button.setAttribute("id", `buttonVerImagen_${data.id}`);
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

      /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click ("Eliminar", "Ver Imagen")} boton 
      **/

    accionProcesar(data, boton) {//Aqui implemento las acciones que pueden ejecutar al oprimir los botones renderizados en la tabla
        
        if (boton === 'Eliminar') {
            
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.validarEliminar(data.id) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.getField('confirmModalCustom').toggle(true);

        }else if (boton === 'Ver Imagen') {
            
            this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);           

            let url = this.constant.formConfiguration();
            let datos=`?body={"datos":{"id":${data.id}},"generales":{"tipo_servicio":"maes-activosfijosseriales","operacion":"traerimagenurl1","operacion_tipo":"consulta"}}`;
            fetch(url+datos,{
                method:'get',
                headers: new Headers({
                'Authorization': 'Bearer '+localStorage.getItem("token"),
                'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if(response.status!==200){                    
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                    return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            }).then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    if(blob.size>0){

                        const image = new File([blob], "name.jpeg", {
                            type: "image/jpeg",
                        });
                        window.open(URL.createObjectURL(image))
                    }else{
                        this.alertGeneral.toggle(true,'No hay datos','error');
                    }
                    this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
                })
                .catch(err => console.error(err));
                this.operacion="";

        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    
    validarEliminar(id) {//Eliminar el registro seleccionado en la tabla

        this.getField('confirmModalCustom').toggle(false);

        if (id !== '') {
            let datos = {
                
                datos: {

                    activosfijos_seriales_id: id

                }
            };
            
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosseriales', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'DELETE',
                    body: datos,
                    success: this.successEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
            
                }
            );
        }
    }

    successEliminar(data) {

        if (data.estado_p === 200) {

            this.servicioTraerTodos();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar el formulario
        }
    }

    btnAgregar(){// Para mostrar y ocultar campos en la acción del botón agregar
        
        this.getField('btn_agregar').setVisible(false);
        this.getField('codigo_activo_fijo').setVisible(true); 
        this.getField('descripcion_activo_fijo').setVisible(true);
        this.getField('numero_serie').setVisible(true);
        this.getField('modelo').setVisible(true);
        this.getField('imagen1_url').setVisible(true);
        this.getField('btn_aceptar').setVisible(true);
        this.getField('btn_aceptar').setDisabled(true);
        this.getField('btn_cancelar').setVisible(true);
    }

    btnAceptarValidacion(){
        
        if (this.getField('codigo_activo_fijo').valid() && this.getField('descripcion_activo_fijo').valid() && this.getField('numero_serie').valid() &&
        this.getField('modelo').valid() && this.getField('activosfijos_id').getValue() !== '' && this.getField('imagen1_url').valid() && this.estadoValidarArchivosImagenes){
            let datos = { datos: {} };
            datos.datos['activosfijos_id'] = this.getField('activosfijos_id').getValue();
            datos.datos['numero_serie'] = this.getField('numero_serie').getValue();
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosseriales', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.crearActivosFijosSeriales,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false,
                }
            );
        }else if (this.estadoValidarArchivosImagenes === false) {

            this.getField('imagen1_url').setError(true, '* Valide el archivo adjunto.');
        }

    }

    crearActivosFijosSeriales(data){

        if (data.estado_p === 200 && data.data.length >= 1){

            this.alertGeneral.toggle(true, '* La relación de los campos código activo fijo y Número serie ya existe, No se permite duplicar','error');
        
        } else if (data.estado_p === 404){
            
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de 'confirmar' en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            if(this.enviar === false){

                const data = {
                    _generales: {"tipo_servicio":"maes-activosfijosseriales","operacion":"5","operacion_tipo": "crear"},
                    archivo: this.archivoImagen1,
                    numero_serie: this.getField('numero_serie').getValue(),
                    modelo: this.getField('modelo').getValue(),
                    activosfijos_id: this.getField('activosfijos_id').getValue(),
                }
                
                let url = this.constant.formConfiguration();
                this.generalFormat = data._generales;
                this.service.send(
                    {
                        endpoint:url,
                        method: 'POST',
                        body: data,
                        success: this.successCrearActivosFijosSeriales,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: true,
                        showMessage: false
                    });
            }else{
                this.enviar= false
            }
        } else {
            this.alertGeneral.toggle(true, "Error en la petición.", 'error');
        }
    }

    validarArchivosImagenes(props){        

        if (props.target.files.length >=1 ){

            if (!this.allowedExtensions.exec(props.target.files[0].name)){

                this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .jpeg/.jpg/.png','error');
                this.estadoValidarArchivosImagenes = false;
                
            }else if (props.target.files[0].name.indexOf(' ') > 0) {

                this.alertGeneral.toggle(true, "La imagen (" + props.target.files[0].name + ") no puede contener espacios.", 'error');
                
                this.estadoValidarArchivosImagenes = false;
            }else{
                this.estadoValidarArchivosImagenes = true;
            }    
        }
    }
    successCrearActivosFijosSeriales(data){         
        
        if (data.estado_p === 200){

            this.ruta_imagen = data.data.ruta;
            
            this.btnCancelar();
                            
        }else if(data.estado_p===404){           
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
        this.getField('confirmModalCustom').toggle(false);// cierra el confirmDialog despues de que responde estado_p 200 el servicio
    }

    habilitarBtnAceptar(){
        
        if (this.getField('codigo_activo_fijo').getValue() !== '' && this.getField('descripcion_activo_fijo').getValue() !== '' && this.getField('numero_serie').getValue() !== '' &&
        this.getField('modelo').getValue() !== '' && this.getField('activosfijos_id').getValue() !== '' && this.getField('imagen1_url').getValue() !== ''){
            
            this.getField('btn_aceptar').setDisabled(false);
        }else{            
            this.getField('btn_aceptar').setDisabled(true);
        }
    }

    confirmacionCancelar(){
        
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta acción?`)
        this.getField('confirmModalCustom').setClickDialog(() => { 
            this.btnCancelar();
            this.getField('confirmModalCustom').toggle(false); 
        });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    btnCancelar() {
        
        //Ocultar campos
        this.getField('codigo_activo_fijo').setVisible(false);
        this.getField('descripcion_activo_fijo').setVisible(false);
        this.getField('numero_serie').setVisible(false);
        this.getField('modelo').setVisible(false);
        this.getField('activosfijos_id').setVisible(false);
        this.getField('imagen1_url').setVisible(false);
        this.getField('btn_aceptar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        //Limpiar campos
        this.getField('codigo_activo_fijo').setValue('');
        this.getField('descripcion_activo_fijo').setValue('');
        this.getField('numero_serie').setValue('');
        this.getField('modelo').setValue('');
        this.getField('activosfijos_id').setValue('');
        this.getField('imagen1_url').setValue('');        
        //Borrar mensajes de error en los campos
        this.getField('codigo_activo_fijo').setError(false, '');
        this.getField('descripcion_activo_fijo').setError(false, '');
        this.getField('numero_serie').setError(false, '');
        this.getField('modelo').setError(false, '');
        this.getField('activosfijos_id').setError(false, '');
        this.getField('imagen1_url').setError(false, '');
        // Servicio que me recarga todos los registros dela tabla
        this.servicioTraerTodos()        
        // Resyablecer variables
        this.archivoImagen1 = "";        
    }


}

FormJaivana.addController("maes-activosfijosseriales", CustomActivosFijosSeriales);
export default CustomActivosFijosSeriales