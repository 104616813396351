import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomProductoServicio extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.successmodificar           = this.successmodificar.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
        this.actualizar                 = this.actualizar.bind(this);
    }

    initForm(){
        console.log('Formulario CustomClonarPantallasAutorizadas,  @version: jdesk_1.0.0001, @author:Patricia López Sánchez')

        this.getField('aceptar').setClick(this.actualizar);
        this.getField('manejacantidad').setOnChange(()=>{this.getField('manejacantidad').getValue() !== '' ? this.getField('aceptar').setDisabled(false) : this.getField('aceptar').setDisabled(true)});
    }

    actualizar(){
        if(this.getField('codigo_producto').valid() && this.getField('nombre3').valid() && this.getField('manejacantidad').valid()){
            let datos = {  datos: { 
                    manejacantidad:this.getField('manejacantidad').getValue(),
                    codigo_producto:this.getField('codigo_producto').getValue(),
                  }
                }
                this.generalFormatPmv = { tipo_servicio: 'maes-productoservicio', operacion: '6', operacion_tipo: 'modificar' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successmodificar,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successmodificar(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Registros actualizados con éxito', 'success');
            this.limpiar();

        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
               this.validarcampos();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    limpiar(){
        this.getField('manejacantidad').setValue('');
        this.getField('codigo_producto').setValue('');
        this.getField('nombre3').setValue('');
        this.getField('aceptar').setDisabled(true);
    }

}
FormJaivana.addController("maes-productoservicio",CustomProductoServicio);
export default CustomProductoServicio