import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomReemplazoFacturas
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0004
 **/
class CustomReemplazoFacturas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                   = this.initForm.bind(this);
        this.cleanCampos                                = this.cleanCampos.bind(this);//Funcion para limpiar los campos
        this.traerCodigoFacturacion                     = this.traerCodigoFacturacion.bind(this);
        this.successTraerCodigoFacturacion              = this.successTraerCodigoFacturacion.bind(this);
        this.habilitarBtnCargarFactura                  = this.habilitarBtnCargarFactura.bind(this);
        this.habilitarCampos                            = this.habilitarCampos.bind(this);//Funcion para habilitar campos deshabilitados
        this.retornaSedesNit                            = this.retornaSedesNit.bind(this);
        this.successRetornaSedeNit                      = this.successRetornaSedeNit.bind(this);
        this.CargarSaldo                                = this.CargarSaldo.bind(this);
        this.successCargarSaldo                         = this.successCargarSaldo.bind(this);
        this.buscarPedidosExcenciones                   = this.buscarPedidosExcenciones.bind(this);
        this.successBuscarPeidoExenciones               = this.successBuscarPeidoExenciones.bind(this);
        this.crearFacturaDocumental                     = this.crearFacturaDocumental.bind(this);
        this.successCrearFacturaDocumental              = this.successCrearFacturaDocumental.bind(this);

        this.opciones                                   = [{'text':'Ninguno','value':'','campos_cambian':{}}];
    }

    initForm() {

        console.log("Formulario CustomReemplazoFacturas,  @version: jdesk_1.01.0004, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField('codigo_causal').setDisabled(true);
        this.getField('descripcion_causal').setDisabled(true);
        this.getField('codigo_responsable').setDisabled(true);
        this.getField('nombre_responsable').setDisabled(true);
        this.getField('observacion_devolucion').setDisabled(true);
        this.getField('observacion_factura').setDisabled(true);
        this.getField('usuario').setDisabled(true);
        this.getField('nombre').setDisabled(true);
        this.getField('boton_grabar').setDisabled(true);
        this.getField('boton_cargar_factura').setDisabled(true);
        
        this.getField('factura_origen').setOnChange(()=>{this.cleanCampos(); this.habilitarBtnCargarFactura(); this.getField('codigo_facturacion').setValue(''); this.getField('nit').setValue('');});
        this.getField('nproveedor').setOnChange(this.retornaSedesNit);
        this.getField('sede').setOnChange(()=>{this.habilitarBtnCargarFactura();});
        this.getField('continuar').setClick(this.habilitarCampos);
        this.getField('factura_origen').setOnBlur(this.traerCodigoFacturacion);
        this.getField('codigo_facturacion').setOnChange(()=>{this.habilitarBtnCargarFactura(); this.limpiarNitSede();});
        this.getField('boton_cargar_factura').setClick(this.CargarSaldo);
        this.getField('boton_grabar').setClick(this.buscarPedidosExcenciones);

    }
    
    limpiarNitSede(){
        this.getField('nit').setValue('');
        this.getField('nit').setError(false,'');
        this.getField('sede').setValue('');
        this.getField('sede').setError(false,'');
    }

    cleanCampos() {
        this.numeroPedido = 0;
        this.getField('codigo_causal').setDisabled(true);
        this.getField('descripcion_causal').setDisabled(true);
        this.getField('codigo_responsable').setDisabled(true);
        this.getField('nombre_responsable').setDisabled(true);
        this.getField('observacion_devolucion').setDisabled(true);
        this.getField('observacion_factura').setDisabled(true);
        this.getField('usuario').setDisabled(true);
        this.getField('nombre').setDisabled(true);
        this.getField('boton_grabar').setDisabled(true);

        this.getField('codigo_causal').setValue('');
        this.getField('descripcion_causal').setValue('');
        this.getField('codigo_responsable').setValue('');
        this.getField('nombre_responsable').setValue('');
        this.getField('observacion_devolucion').setValue('');
        this.getField('observacion_factura').setValue('');
        this.getField('usuario').setValue('');
        this.getField('nombre').setValue('');
        this.getField('sede').setValue('');
        this.getField('nit_cliente').setValue('');
        this.getField('nombre_cliente').setValue('');

        this.getField('nproveedor').setValue('');
        this.getField('fecha').setValue('');
        this.getField('valor').setValue('');
        this.getField('codigo_sucursal').setValue('');
        this.getField('saldo').setValue('');

        this.getField('nproveedor').setError(false, "* Este campo es requerido.");
        this.getField('fecha').setError(false, "* Este campo es requerido.");
        this.getField('valor').setError(false, "* Este campo es requerido.");
        this.getField('codigo_sucursal').setError(false, "* Este campo es requerido.");
        this.getField('saldo').setError(false, "* Este campo es requerido.");
        let opcionesN = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
        this.getField('sede_final_cliente').setOptions(opcionesN);
        this.opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
    }

        
    traerCodigoFacturacion(){
        if (this.getField('factura_origen').getValue() !== '' && this.getField('factura_origen').getValue() !== ' ' && !(this.getField('nproveedor').getValue() !== '')) {
            let datos = { datos:{
                numero: this.getField('factura_origen').getValue(),
            }};

            this.generalFormatPmv = { tipo_servicio: "fact-facturac", operacion: "traercodigofacturacion", operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successTraerCodigoFacturacion,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage :false
            });
        }
    }

    successTraerCodigoFacturacion(data){
        if (data.estado_p === 200 && data.data.length >= 1) {

            this.getField('codigo_facturacion').setValue(data.data[0].codigo_facturacion);
            //this.getField('codigo_facturacion').setValue('');
            if (data.data.length === 1) {
                this.getField('codigo_facturacion').setValue(data.data[0].codigo_facturacion);
                this.getField('nit').setValue(data.data[0].nit);
                this.getField('sede').setValue(data.data[0].sede);
                this.getField('nproveedor').setValue(data.data[0].nproveedor);
                this.getField('codigo_facturacion').setDisabled(true);
            }else{
                this.getField('codigo_facturacion').setDisabled(false);
            }
        }else{
            this.getField('codigo_facturacion').setOptions(this.opciones);
            this.getField('codigo_facturacion').setDisabled(true);
        }
    }
    
    habilitarBtnCargarFactura(){
        if(this.getField('factura_origen').getValue() !== '' && this.getField('nit').getValue() !== '' &&
        this.getField('codigo_facturacion').getValue() !== '' && this.getField('sede').getValue() !== ''){
            this.getField('boton_cargar_factura').setDisabled(false);
        }else{
            this.getField('boton_cargar_factura').setDisabled(true);
        }
    }

    habilitarCampos() {
        if (this.getField('factura_origen').valid() && this.getField('nit').valid() &&
            this.getField('sede').valid() && this.getField('sede').getValue() !== '' && this.getField('nproveedor').valid() &&
            this.getField('nproveedor').getValue() !== '' && this.getField('fecha').getValue() !== '' && this.getField('saldo').getValue() !== '' &&
            this.getField('fecha').valid() && this.getField('valor').valid() && this.getField('valor').getValue() !== '' &&
            this.getField('codigo_sucursal').valid() && this.getField('codigo_sucursal').getValue() !== '' && this.getField('saldo').valid()) {
            let valorResta = (this.getField('valor').getValue() - this.getField('saldo').getValue());
            if ((this.getField('valor').getValue() === this.getField('saldo').getValue()) || (valorResta <= 10 && valorResta >= -10)) {
                this.alertGeneral.toggle(false, "Los campos Valor y Saldo Cartera deben ser Iguales o diferir por 10 pesos.", "error");
                this.getField('valor').setError(false, "* Los campos Valor y Saldo Cartera deben ser Iguales o diferir por 10 pesos.");
                this.getField('saldo').setError(false, "* Los campos Valor y Saldo Cartera deben ser Iguales o diferir por 10 pesos.");

                this.getField('codigo_causal').setDisabled(false);
                this.getField('descripcion_causal').setDisabled(false);
                this.getField('codigo_responsable').setDisabled(false);
                this.getField('nombre_responsable').setDisabled(false);
                this.getField('observacion_devolucion').setDisabled(false);
                this.getField('observacion_factura').setDisabled(false);
                this.getField('usuario').setDisabled(false);
                this.getField('nombre').setDisabled(false);
                this.getField('boton_grabar').setDisabled(false);
            } else {
                this.alertGeneral.toggle(true, "Los campos Valor y Saldo Cartera deben ser Iguales o diferir por 10 pesos.", "error");
                this.getField('valor').setError(true, "* Los campos Valor y Saldo Cartera deben ser Iguales o diferir por 10 pesos.");
                this.getField('saldo').setError(true, "* Los campos Valor y Saldo Cartera deben ser Iguales o diferir por 10 pesos.");
            }
        }
    }

    retornaSedesNit() {
        if (this.getField('nproveedor').getValue() !== '') {
            let datos = { datos: {
                value: this.getField('nit').getValue(),
                estado: 'A'
            }};

            this.generalFormatPmv = { tipo_servicio: "maes-terceros", operacion:"42_4", operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successRetornaSedeNit,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successRetornaSedeNit(data) {
        if (data.estado_p === 200) {
            this.sedeActual = this.getField('sede').getValue();
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.sede;
                dataOp['text'] = item.sede;
                dataOp['campos_cambian'] = {};
                opciones.push(dataOp);
            });

            this.getField('nit_cliente').setValue(data.data[0].nit);
            this.getField('nombre_cliente').setValue(data.data[0].nombre);
            this.getField('sede_final_cliente').setOptions(opciones);
            this.getField('sede_final_cliente').setValue(this.sedeActual);
            this.getField('boton_cargar_factura').setDisabled(false);
        }
    }

    CargarSaldo(){
        let datos = { datos: {
            nit: this.getField('nit').getValue(),
            factura_origen: this.getField('factura_origen').getValue()
        }};

        this.generalFormatPmv = { tipo_servicio: "fact-facturac", operacion:"saldo", operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successCargarSaldo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successCargarSaldo(data){
        if (data.estado_p === 200) {
            console.log(data);
            this.numeroPedido = data.data[0].pedido;
            this.getField('nit_cliente').setValue(data.data[0].nit);
            this.getField('nombre_cliente').setValue(data.data[0].nproveedor);
            this.getField('sede').setValue(data.data[0].sede);
            this.getField('fecha').setValue(data.data[0].fecha);
            this.getField('valor').setValue(data.data[0].valor);
            this.getField('codigo_sucursal').setValue(data.data[0].codigo_sucursal);
            this.getField('saldo').setValue(data.data[0].saldo === null ? 0 : data.data[0].saldo);
            this.getField('nproveedor').setValue(data.data[0].nproveedor);

        }else{
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            this.getField('rejilla').toggle(false)
        }
    }

    buscarPedidosExcenciones() {
        if (this.numeroPedido > 0 && this.numeroPedido !== undefined && this.getField('codigo_causal').valid() &&
        this.getField('codigo_sucursal').valid() &&
        this.getField('factura_origen').valid() &&
        this.getField('codigo_responsable').valid() &&
        this.getField('observacion_devolucion').valid() &&
        this.getField('observacion_factura').valid() &&
        this.getField('usuario').valid() &&
        this.getField('sede_final_cliente').valid() && this.getField('nombre').valid() && this.getField('nombre').getValue() !== '') {
            let datos = { datos:{
                value: String(this.numeroPedido)
            }};

            this.generalFormatPmv = { tipo_servicio: "fact-reemplazofacturas", operacion:"pedidoexencion", operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successBuscarPeidoExenciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
                });
        }
    }

    successBuscarPeidoExenciones(data){
        if(data.estado_p === 200){
            this.getField('confirmModalCustom').setTitleAndContent('Adertencia', `El pedido número: ${this.numeroPedido}, no se permite grabar porque se encuentra en pedidos exenciones.`);
            this.getField('confirmModalCustom').setClickDialog(() => { 
                this.cleanCampos();
                this.getField('factura_origen').setValue('');
                this.getField('nit').setValue('');
                this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); 
            this.getField('confirmModalCustom').toggle(true);
        } else if(data.estado_p === 404){
            this.crearFacturaDocumental();
        }
    }

    crearFacturaDocumental() {

        let datos = { datos:{
            factura_origen: this.getField('factura_origen').getValue(),
            codigo_sucursal: this.getField('codigo_sucursal').getValue(),
            observacion_devolucion: this.getField('observacion_devolucion').getValue(),
            observacion_factura: this.getField('observacion_factura').getValue(),
            codigo_causal: this.getField('codigo_causal').getValue(),
            codigo_responsable: this.getField('codigo_responsable').getValue(),
            usuario: this.getField('usuario').getValue(),
            sede_final_cliente: this.getField('sede_final_cliente').getValue(),
            codigo_facturacion: this.getField('codigo_facturacion').getValue(),
        }};

        this.generalFormatPmv = { tipo_servicio: "fact-reemplazofacturas", operacion:"crear", operacion_tipo: 'crear' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'POST',
                body: datos,
                success: this.successCrearFacturaDocumental,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successCrearFacturaDocumental(data) {
        if (data.estado_p === 200) {
            this.numeroPedido = 0;
            this.getField('codigo_causal').setDisabled(true);
            this.getField('descripcion_causal').setDisabled(true);
            this.getField('codigo_responsable').setDisabled(true);
            this.getField('nombre_responsable').setDisabled(true);
            this.getField('observacion_devolucion').setDisabled(true);
            this.getField('observacion_factura').setDisabled(true);
            this.getField('usuario').setDisabled(true);
            this.getField('nombre').setDisabled(true);
            this.getField('boton_grabar').setDisabled(true);

            this.getField('codigo_causal').setValue('');
            this.getField('descripcion_causal').setValue('');
            this.getField('codigo_responsable').setValue('');
            this.getField('nombre_responsable').setValue('');
            this.getField('observacion_devolucion').setValue('');
            this.getField('observacion_factura').setValue('');
            this.getField('usuario').setValue('');
            this.getField('nombre').setValue('');

            this.getField('factura_origen').setValue('');
            this.getField('nit').setValue('');
            this.getField('sede').setValue('');
            this.getField('nproveedor').setValue('');
            this.getField('fecha').setValue('');
            this.getField('valor').setValue('');
            this.getField('codigo_sucursal').setValue('');
            this.getField('saldo').setValue('');
            this.getField('nombre_cliente').setValue('');
            this.getField('nit_cliente').setValue('');
            let opcionesN = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            this.getField('sede_final_cliente').setOptions(opcionesN);

        }
    }

}
FormJaivana.addController("fact-reemplazofacturas", CustomReemplazoFacturas);
export default CustomReemplazoFacturas;