import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAuditoriaOrdenesDeCompa
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 **/
class CustomAuditoriaOrdenesDeCompa extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChangedPrincipal          = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']                            = this.onSelectionChangedPrincipal.bind(this);


    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.validarConsultar                                  = this.validarConsultar.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
    
    this.ocultarCampos                                  = this.ocultarCampos.bind(this);


    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);
    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.successCrearTemporal                         =this.successCrearTemporal.bind(this);
    this.successTraerDatosCartera                         =this.successTraerDatosCartera.bind(this);
    this.abrirModalCuentas                         =this.abrirModalCuentas.bind(this);
    this.generarFaltantes                         =this.generarFaltantes.bind(this);
    this.successFaltantes                         =this.successFaltantes.bind(this);
    this.arreglarDecimales                         =this.arreglarDecimales.bind(this);
    this.validarCamposFecha                         =this.validarCamposFecha.bind(this);

    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.boton='';
    this.fecha_inicio='';
    this.sucursal_ingreso='';

    this.nombre_archivo='';
    this.archivo_servicio='';
    this.archivo_operacion='';
    this.fila_seleccionada=[];
    this.cuenta_inicial='';
    this.cuenta_final='';
    this.operacion_form=0;
  }
  
  initForm() {
    console.log("Formulario CustomAuditoriaOrdenesDeCompa,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    if(this.props.data[0].opcion_sub_seccion === 1){                  
        this.operacion_form=1;
        this.getField('operacion_form2').setValue('Reporte de Diferencias entre Compras y Pedidos');
     }else if(this.props.data[0].opcion_sub_seccion === 2){  
        this.operacion_form=2;
        this.getField('operacion_form2').setValue('Resumen por Proveedor');
     }else if(this.props.data[0].opcion_sub_seccion === 3){            
        this.operacion_form=3;
        this.getField('operacion_form2').setValue('Pendientes Órdenes de Compra');
     } 

    //nuevo para consulta
    this.getField('consultar').setClick(this.validarConsultar);


    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('consultar_desde').setValue(this.fecha_inicio);

    this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
    this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);

    //botones principal
    this.getField('bt_ver_mas').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.boton='ver_mas';
            if(this.operacion_form===2)
                this.nombre_archivo='resumen_proveedor';
            else
                this.nombre_archivo='auditoria_ordenes_de_compra'; 
            this.archivo_operacion='ver_mas_pag';
            this.abrirImprimir();
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
        }
    });

    this.getField('bt_fantantes').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.boton='faltantes';
            this.nombre_archivo='auditoria_ordenes_de_compra'; 
            this.archivo_operacion='faltantes_pag';
            this.generarFaltantes();
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
        }
    });

    this.getField('bt_resumen').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.boton='resumen';
            this.archivo_operacion='resumen';
            this.genearModalGlobales();
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
        }
    });


    //imprimir
    this.getField('radio_correo').setValue("N");
    this.getField('generar_archivo').setClick(()=>{
        this.procesar();
    });

    //para balances globales
    this.getField('ch_excel').setOnChange((props)=>{
      if(this.getField('ch_excel').getValue()==='false'){
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='excel';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pdf').setOnChange((props)=>{
      if(this.getField('ch_pdf').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='pdf';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pantalla').setOnChange((props)=>{
      if(this.getField('ch_pantalla').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.operacion_actual='pantalla';
        this.deshabilitarCorreo();
      }else{
          this.habilitarCorreo();
      }
    });

  }

  validarCamposFecha(){
        this.getField('consultar').setDisabled(false);
        this.getField("consultar_desde").setError(false,'');
        this.getField("consultar_hasta").setError(false,'');
        this.ocultarCampos();
        let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
        let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
        let fecha_actual = new Date().toISOString().split('T')[0];
        if (fechaInicial > fechaFinal) {
            this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
            this.getField('consultar').setDisabled(true);
            return false;
        } 

        if(fechaFinal > fecha_actual){
            this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
            this.getField('consultar').setDisabled(true);
            return false;
        }
        if(fechaFinal > fecha_actual){
            this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        let diferencia=new Date(fechaFinal).getTime()-new Date(fechaInicial).getTime();
        diferencia = diferencia / 1000 / 60 / 60 / 24;
        if(diferencia >this.dias_maximo){
            this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('consultar').setDisabled(true); 
            return false;
        }

        this.getField('consultar_hasta').setError(false,"");
        return true;
    }

  generarFaltantes(){
    let datos={datos:{
        sucursal_ingreso: this.sucursal_ingreso,
        fecha: this.fila_seleccionada[0].fecha,
        consultar_desde: this.getField('consultar_desde').getValue(),
        consultar_hasta : this.getField('consultar_hasta').getValue(),
        operacion_form: this.operacion_form+'',
    }}
    this.mostrarMensajeGenerando();
    this.generalFormatPmv = { tipo_servicio: 'comp-auditoriaordenesdecompra', operacion:  'generarfaltantes', operacion_tipo: 'crear' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successFaltantes,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  successFaltantes(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        this.abrirImprimir();
    }else {
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }
  }

  abrirModalCuentas(){
    this.getField('modal_cuentas').handleClickOpen();
    this.getField('ch_imprimir_todo').setValueSwitch(false);
    this.getField('codigo_cuenta_inicial').setDisabled(false);
    this.getField('codigo_cuenta_final').setDisabled(false);
  }

  onSelectionChangedPrincipal(){
    this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
  }

  ocultarCampos(){
    this.fila_seleccionada=[];
    this.getField('consultar').setDisabled(false);
    this.getField('rejilla_items').toggle(false);
    this.getField('bt_ver_mas').setVisible(false);
    this.getField('bt_fantantes').setVisible(false);
    this.getField('bt_resumen').setVisible(false);
  }

  traerPropiedades(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'comp-auditoriaordenesdecompra', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
}

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



  validarConsultar(){
    let errores=0;
    if(this.validarCamposFecha()===false)
        errores++;
    if(errores===0){
        this.mostrarMensajeGenerando();
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            consultar_desde: this.getField('consultar_desde').getValue(),
            consultar_hasta : this.getField('consultar_hasta').getValue(),
            operacion_form: this.operacion_form+'',
        }}
        this.mostrarMensajeGenerando();
        this.generalFormatPmv = { tipo_servicio: 'comp-auditoriaordenesdecompra', operacion:  'generardatos', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrearTemporal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }

  }

  successCrearTemporal(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            consultar_desde: this.getField('consultar_desde').getValue(),
            consultar_hasta : this.getField('consultar_hasta').getValue(),
            operacion_form: this.operacion_form+'',
        }}
        this.mostrarMensajeGenerando();
        this.getField("rejilla_items").setTipoServicioOperacion('comp-auditoriaordenesdecompra-1_inicial');
        this.getField("rejilla_items").sendRequest(this.successTraerDatosCartera, datos); 
    }else {
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }
  }

  successTraerDatosCartera(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){ 
        this.getField('rejilla_items').toggle(true);
        this.gridOptionsComponentes['rowData'] = [];
        let configCell = new Map();
        this.gridOptionsComponentes['rowData'] = data.data;

        configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
        configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
        configCell.set('cantidad_pedida', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_pedida) }, cellStyle: {textAlign:"right"},width: 110});
        configCell.set('costo_pedido', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_pedido) }, cellStyle: {textAlign:"right"},width: 110});


        //fecha,numero,nit,sede,suc_factura,nproveedor,codigo,cantidad,subtotal1,xfaltante,narticulo,
        //pedido,cantidad_pedida,pendiente,xtotal,costo_pedido,fecha_pedido,suc_pedido

        if(this.operacion_form===1){
            //campos a mostrar -->nit,sede,numero,fecha,suc_factura,nproveedor,codigo,cantidad,
            //subtotal1,pedido,cantidad_pedida,costo_pedido,fecha_pedido,suc_pedido,narticulo


            configCell.set('xfaltante', {cellRenderer:function(props){return props.data.xfaltante}, hide: true});
            configCell.set('pendiente', {cellRenderer:function(props){return props.data.pendiente}, hide: true});
            configCell.set('xtotal', {cellRenderer:function(props){return props.data.xtotal}, hide: true});

        }else if(this.operacion_form===2){
            //fecha,numero,nit,sede,nproveedor,codigo,nombre,pendiente,xtotal
            configCell.set('suc_factura', {cellRenderer:function(props){return props.data.suc_factura}, hide: true});
            configCell.set('cantidad', {cellRenderer:function(props){return props.data.cantidad}, hide: true});
            configCell.set('subtotal1', {cellRenderer:function(props){return props.data.subtotal1}, hide: true});
            configCell.set('xfaltante', {cellRenderer:function(props){return props.data.xfaltante}, hide: true});
            configCell.set('pedido', {cellRenderer:function(props){return props.data.pedido}, hide: true});
            configCell.set('cantidad_pedida', {cellRenderer:function(props){return props.data.cantidad_pedida}, hide: true});
            configCell.set('costo_pedido', {cellRenderer:function(props){return props.data.costo_pedido}, hide: true});
            configCell.set('fecha_pedido', {cellRenderer:function(props){return props.data.fecha_pedido}, hide: true});
            configCell.set('suc_pedido', {cellRenderer:function(props){return props.data.suc_pedido}, hide: true});
        }else{
            //nit,sede,numero,fecha,suc_factura,nproveedor,codigo,cantidad,subtotal1,xfaltante,narticulo,cantidad_pedida
            configCell.set('pedido', {cellRenderer:function(props){return props.data.pedido}, hide: true});
            configCell.set('pendiente', {cellRenderer:function(props){return props.data.pendiente}, hide: true});
            configCell.set('xtotal', {cellRenderer:function(props){return props.data.xtotal}, hide: true});
            configCell.set('costo_pedido', {cellRenderer:function(props){return props.data.costo_pedido}, hide: true});
            configCell.set('fecha_pedido', {cellRenderer:function(props){return props.data.fecha_pedido}, hide: true});
            configCell.set('suc_pedido', {cellRenderer:function(props){return props.data.suc_pedido}, hide: true});
        }
        this.getField('rejilla_items').initData(this.gridOptionsComponentes,configCell);

        this.getField('bt_ver_mas').setVisible(true);
        if(this.operacion_form===2){
            this.getField('bt_fantantes').setVisible(false);
            this.getField('bt_resumen').setVisible(false);
        }else{
            this.getField('bt_fantantes').setVisible(true);
            this.getField('bt_resumen').setVisible(true);
        }
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.mostrarMensajeNoHayDatos();
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
}

  onSelectionChanged(){
    
  }


    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);
    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }
    
      mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }

    //modal imprimir

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    abrirImprimir(){
            
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
  }

    procesar(){
        this.archivo_servicio='comp-auditoriaordenesdecompra';
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    genearModalGlobales(){
        this.mostrarMensajeGenerando();
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
            consultar_desde : this.getField('consultar_desde').getValue(),
            consultar_hasta : this.getField('consultar_hasta').getValue(),
            operacion_form: this.operacion_form+'',
            fecha: this.fila_seleccionada[0].fecha,
        }}
        if(this.boton==='resumen'){
            this.generalFormatPmv = { tipo_servicio: 'comp-auditoriaordenesdecompra', operacion: this.archivo_operacion, operacion_tipo: "consulta" };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.sucessTraerDatos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            }); 
        }else{
            this.getField("rejilla_items_result").setTipoServicioOperacion('comp-auditoriaordenesdecompra-'+this.archivo_operacion.replace('_pag',''));
            this.getField("rejilla_items_result").sendRequest(this.sucessTraerDatos, datos); 
        }
  }

  sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            this.getField('neto').setVisible(false);
            this.getField('sobrante').setVisible(false);
            this.getField('faltante').setVisible(false);

            this.getField('valor_comprado').setVisible(false);
            this.getField('valor_pedido').setVisible(false);
            this.getField('diferencia').setVisible(false);
            this.getField('rejilla_items_result').toggle(false);

            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "nit": this.lista.nit,
                    "direccion": this.lista.direccion,
                    "pbx": this.lista.pbx,
                    "titulo": this.lista.titulo,
                    "fecha_sistema":this.lista.fecha_sistema
                };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            if(this.boton==='resumen'){
                this.getField('modal_result').handleClickOpen();
                this.getField('valor_comprado').setVisible(true);
                this.getField('valor_pedido').setVisible(true);
                this.getField('diferencia').setVisible(true);
                this.getField('valor_comprado').setValue(this.arreglarDecimales(this.lista.xcompras));
                this.getField('valor_pedido').setValue(this.arreglarDecimales(this.lista.xreal));
                this.getField('diferencia').setValue(this.arreglarDecimales(this.lista.diferencia));
            }else{
                this.getField('rejilla_items_result').toggle(true);
                this.getField('modal_result').handleClickOpen();
                this.gridOptionsComponentesItems['rowData'] = [];
                let configCell = new Map();
                this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
                //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
                configCell.set('cantidad_pedida', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_pedida) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('diferencia', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.diferencia) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('pendiente', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pendiente) }, cellStyle: {textAlign:"right"},width: 110});

                if(this.operacion_form===2){
                
                }else{
                    configCell.set('pendiente', {cellRenderer:function(props){return props.data.pendiente}, hide: true});
                }
                
                this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);
       
                
                this.getField('sobrante').setValue(this.arreglarDecimales(this.lista.sobrante));
                this.getField('faltante').setValue(this.arreglarDecimales(this.lista.faltante));
                this.getField('neto').setValue(this.arreglarDecimales(this.lista.neto));
                this.getField('neto').setVisible(true);
                if(this.operacion_form===2 || this.boton==='faltantes'){
                    this.getField('sobrante').setVisible(false);
                    this.getField('faltante').setVisible(false);
                }else{
                    this.getField('sobrante').setVisible(true);
                    this.getField('faltante').setVisible(true);
                }
            }
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }


    enviarCorreoTerceros(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos={datos:{
            sucursal_ingreso: this.sucursal_ingreso,
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
            consultar_desde : this.getField('consultar_desde').getValue(),
            consultar_hasta : this.getField('consultar_hasta').getValue(),
            operacion_form: this.operacion_form+'',
            fecha: this.fila_seleccionada[0].fecha,
        }}
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  }
  
  successEnviarCorreo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
          this.mostrarMensajeEmailEnviado();
      }else if(data.estado_p === 404){
        this.mostrarMensajeNoHayDatos();
      }else{
          this.mostrarMensajeEmailFallo();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      }
  }

  generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2={
                sucursal_ingreso: this.sucursal_ingreso,
                operacion : this.operacion_actual,
                email : this.getField('email').getValue(),
                radio_correo : this.getField('radio_correo').getValue(),
                consultar_desde : this.getField('consultar_desde').getValue(),
                consultar_hasta : this.getField('consultar_hasta').getValue(),
                operacion_form: this.operacion_form+'',
                fecha: this.fila_seleccionada[0].fecha,
            }
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+this.archivo_operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2={
                sucursal_ingreso: this.sucursal_ingreso,
                operacion : this.operacion_actual,
                email : this.getField('email').getValue(),
                radio_correo : this.getField('radio_correo').getValue(),
                consultar_desde : this.getField('consultar_desde').getValue(),
                consultar_hasta : this.getField('consultar_hasta').getValue(),
                operacion_form: this.operacion_form+'',
                fecha: this.fila_seleccionada[0].fecha,
            }

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+this.archivo_operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }
   
}


FormJaivana.addController("comp-auditoriaordenesdecompra", CustomAuditoriaOrdenesDeCompa);
export default CustomAuditoriaOrdenesDeCompa;