import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Daniel Felipe Aguirre O.
 *
 * @version jdesk_1.01.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomManejoArticulosVarios extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                                 = this.initForm.bind(this);
        this.camposCrud                                               = this.camposCrud.bind(this);
        this.onClickNew                                               = this.handlerClickNew.bind(this);
        this.onClickChange                                            = this.handlerClickChange.bind(this);
        this.onClickShowTable                                         = this.handerClickShowTable.bind(this);
        this.btnCancelar                                              = this.btnCancelar.bind(this);
        this.crearArticulosVarios                                     = this.crearArticulosVarios.bind(this);
        this.successCrearArticulosVarios                              = this.successCrearArticulosVarios.bind(this);
        this.successModificarConsecutivo                              = this.successModificarConsecutivo.bind(this);
        this.successNuevo                                             = this.successNuevo.bind(this);
        this.validacionCrearArticulosVarios                           = this.validacionCrearArticulosVarios.bind(this);
        this.servicioTraerPrimero                                     = this.servicioTraerPrimero.bind(this);
        this.successServicioTraerPrimero                              = this.successServicioTraerPrimero.bind(this);
        this.servicioTraerTodos                                       = this.servicioTraerTodos.bind(this);
        this.successDataTableArticulosVarios                          = this.successDataTableArticulosVarios.bind(this);
        this.onSelectionChangedT                                      = this.onSelectionChangedT.bind(this);
        this.gridOptionsTableArticulosVarios                          = Object.assign({},this.gridOptions);
        this.gridOptionsTableArticulosVarios["onSelectionChanged"]    = this.onSelectionChangedT;        
            
        this.validarRegistroSeleccionadoModificar                     = this.validarRegistroSeleccionadoModificar.bind(this);
        this.validarModificarArticulosVarios                          = this.validarModificarArticulosVarios.bind(this);
        this.mostrarMensajeSeleccionModificar                         = this.mostrarMensajeSeleccionModificar.bind(this);
        this.servicioModificarArticulosVarios                         = this.servicioModificarArticulosVarios.bind(this);
        this.successServicioModificarArticulosVarios                  = this.successServicioModificarArticulosVarios.bind(this);        
        
        this.dataTableArticulosVariosSeleccion                        = false;
        this.filaSeleccionada                                         = [];
        this.btnModificar                                             = false;
    }

    initForm() {

        console.log('Formulario CustomManejoArticulosVarios,  @version: jdesk_1.01.0001, @author:Daniel Felipe Aguirre O.')

        this.buttons.get("btn_new").ref.current.setDisabled(false);
        this.getField('btn_agregar').setClick(this.validacionCrearArticulosVarios);
        this.getField('btn_cancelar').setClick(this.btnCancelar);
        this.getField('btn_modificar').setClick(this.servicioModificarArticulosVarios);
        this.getField('btn_cancelar_modificar').setClick(() => { this.btnModificar = false; this.btnCancelar(); });
    }

    servicioTraerTodos() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'maes-manejoarticulosvarios', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successDataTableArticulosVarios,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    servicioModificarArticulosVarios() {

        if (this.getField('codigo_interno').valid() &&
            this.getField('descripcion').valid() && this.getField('tarifa_iva').valid() &&
            this.getField('costo').valid() && this.getField('codigo_grupo').valid() &&
            this.getField('nombre_grupo').valid() && this.getField('tipo_articulo').valid() &&
            this.getField('estadoactivo_id').valid() && this.getField('articulo_varios_id').getValue() !== '' && this.getField('grupos_id').getValue() !== '') {

            let datos = { datos: {} };

            datos.datos['descripcion'] = this.getField('descripcion').getValue();
            datos.datos['tarifa_iva'] = this.getField('tarifa_iva').getValue();
            datos.datos['costo'] = this.getField('costo').getValue();
            datos.datos['tipo_articulo'] = this.getField('tipo_articulo').getValue();
            datos.datos['estadoactivo_id'] = this.getField('estadoactivo_id').getValue();
            datos.datos['articulo_varios_id'] = this.getField('articulo_varios_id').getValue();
            datos.datos['grupos_id'] = this.getField('grupos_id').getValue();
            this.generalFormatPmv = { tipo_servicio: 'maes-manejoarticulosvarios', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successServicioModificarArticulosVarios,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successServicioModificarArticulosVarios(data) {

        if (data.estado_p === 200 && data.data.length >= 1) {

            this.dataTableArticulosVariosSeleccion = false;

            this.btnCancelar();
        } else {
            let respuesta = Object.values(data.data.errores);
            let keys = Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
        }

    }

    validarRegistroSeleccionadoModificar() {

        if (this.dataTableArticulosVariosSeleccion) {
            
            this.validarModificarArticulosVarios();

        } else {
            
            this.mostrarMensajeSeleccionModificar();
        }

    }

    validarModificarArticulosVarios() {
        

        this.buttons.get("btn_new").ref.current.setVisible(false);
        this.buttons.get("btn_show").ref.current.setVisible(false);
        this.buttons.get("btn_change").ref.current.setVisible(false);

        this.getField('articulo_varios_id').getValue();
        this.getField('grupos_id').getValue();
        this.getField('codigo_interno').getValue();
        this.getField('descripcion').getValue();
        this.getField('tarifa_iva').getValue();
        this.getField('costo').getValue();
        this.getField('codigo_grupo').getValue();
        this.getField('nombre_grupo').getValue();
        this.getField('tipo_articulo').getValue();
        this.getField('estadoactivo_id').getValue();

        this.getField('codigo_interno').setDisabled(false);
        this.getField('descripcion').setDisabled(false);
        this.getField('tarifa_iva').setDisabled(false);
        this.getField('costo').setDisabled(false);
        this.getField('codigo_grupo').setDisabled(false);
        this.getField('nombre_grupo').setDisabled(false);
        this.getField('tipo_articulo').setDisabled(false);
        this.getField('estadoactivo_id').setDisabled(false);

        this.getField('btn_modificar').setVisible(true);
        this.getField('btn_cancelar_modificar').setVisible(true);
        this.getField('btn_modificar').setDisabled(false);
        this.getField('btn_cancelar_modificar').setDisabled(false);







        this.gridOptionsTableArticulosVarios['rowData'] = [];




        this.getField('tableShowData').initData(this.gridOptionsTableArticulosVarios);
        this.getField('tableShowData').toggle(false);

        //this.camposCrud();

    }

    mostrarMensajeSeleccionModificar() {// no se toca nada aqui está ya full

        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para modificar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

    }

    onSelectionChangedT() {

        
        this.filaSeleccionada = this.gridOptionsTableArticulosVarios.api.getSelectedRows();
        this.getField('codigo_interno').setValue(this.filaSeleccionada[0].codigo_interno);
        this.getField('descripcion').setValue(this.filaSeleccionada[0].descripcion);
        this.getField('tarifa_iva').setValue(this.filaSeleccionada[0].tarifa_iva);
        this.getField('costo').setValue(this.filaSeleccionada[0].costo);
        this.getField('codigo_grupo').setValue(this.filaSeleccionada[0].codigo_grupo);
        this.getField('nombre_grupo').setValue(this.filaSeleccionada[0].nombre_grupo);
        this.getField('tipo_articulo').setValue(this.filaSeleccionada[0].tipo_articulo);
        this.getField('codigo_interno').setValue(this.filaSeleccionada[0].codigo_interno);
        this.getField('estadoactivo_id').setValue(this.filaSeleccionada[0].estadoactivo_id);
        this.getField('grupos_id').setValue(this.filaSeleccionada[0].grupos_id);
        this.getField('articulo_varios_id').setValue(this.filaSeleccionada[0].articulo_varios_id);
        this.dataTableArticulosVariosSeleccion = true;
    }

    successDataTableArticulosVarios(data) {

        if (data.estado_p === 200 && data.model && data.model.length >= 1) {

            this.gridOptionsTableArticulosVarios['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud

            //this.gridOptionsTableArticulosVarios['onSelectionChanged'] =this.onSelectionChanged;
            this.getField('tableShowData').initData(this.gridOptionsTableArticulosVarios);//esta configuracion se carga en la tabla por defecto del crud


        } else {//si no encuentra datos, no muestra la tabla 
            this.getField('tableShowData').toggle(false);

        }


    }


    servicioTraerPrimero() {

        let datos = { datos: {} };

        this.generalFormatPmv = { tipo_servicio: 'maes-manejoarticulosvarios', operacion: '3', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successServicioTraerPrimero,
                error: this.error_,
                general: this.generalFormatPmv
            });

    }

    successServicioTraerPrimero(data) {

        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('codigo_interno').setValue(data.data[0].codigo_interno);
            this.getField('descripcion').setValue(data.data[0].descripcion);
            this.getField('tarifa_iva').setValue(data.data[0].tarifa_iva);
            this.getField('costo').setValue(data.data[0].costo);
            this.getField('codigo_grupo').setValue(data.data[0].codigo_grupo);
            this.getField('nombre_grupo').setValue(data.data[0].nombre_grupo);
            this.getField('tipo_articulo').setValue(data.data[0].tipo_articulo);
            this.getField('codigo_interno').setValue(data.data[0].codigo_interno);
            this.getField('estadoactivo_id').setValue(data.data[0].estadoactivo_id);
            this.getField('grupos_id').setValue(data.data[0].grupos_id);
            this.getField('articulo_varios_id').setValue(data.data[0].articulo_varios_id);
        }
    }

    validacionCrearArticulosVarios() {
        if (this.getField('codigo_interno').valid() &&
            this.getField('descripcion').valid() && this.getField('tarifa_iva').valid() &&
            this.getField('costo').valid() && this.getField('codigo_grupo').valid() &&
            this.getField('nombre_grupo').valid() && this.getField('tipo_articulo').valid() &&
            this.getField('estadoactivo_id').valid() && this.getField('grupos_id').getValue() !== '') {
            let datos = { datos: {} };
            datos.datos['codigo_interno'] = this.getField('codigo_interno').getValue();
            this.generalFormatPmv = { tipo_servicio: 'maes-manejoarticulosvarios', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.crearArticulosVarios,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }

    }

    crearArticulosVarios(data) {
        if (data.estado_p === 200) {

            this.alertGeneral.toggle(true, "Ya existe la relación con este código. No se permite duplicar.", 'error');

        } else if (data.estado_p === 404) {
            let datos = { datos: {} };
            datos.datos['grupos_id'] = this.getField('grupos_id').getValue();
            datos.datos['codigo_interno'] = this.getField('codigo_interno').getValue();
            datos.datos['descripcion'] = this.getField('descripcion').getValue();
            datos.datos['tarifa_iva'] = this.getField('tarifa_iva').getValue();
            datos.datos['costo'] = this.getField('costo').getValue();
            datos.datos['tipo_articulo'] = this.getField('tipo_articulo').getValue();
            datos.datos['estadoactivo_id'] = this.getField('estadoactivo_id').getValue();
            this.generalFormatPmv = { tipo_servicio: 'maes-manejoarticulosvarios', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successCrearArticulosVarios,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        } else {
            this.alertGeneral.toggle(true, "Error en la petición.", 'error');
        }
    }

    successCrearArticulosVarios(data) {

        if (data.estado_p === 200 && data.data.length >= 1) {

            //this.btnCancelar();
            let datos = { datos: {} };
            this.generalFormatPmv = { tipo_servicio: 'maes-manejoarticulosvarios', operacion: 'actualizarconsecutivo', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successModificarConsecutivo,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

        } else {
            let respuesta = Object.values(data.data.errores);
            let keys = Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
        }
    }

    successModificarConsecutivo(data){

        if (data.estado_p === 200) {

            this.btnCancelar();           
                
        } else {            
            
            this.alertGeneral.toggle(true, 'Error al actualizar el consecutivo', 'error');
        }


    }

    btnCancelar() {

        this.getField('btn_agregar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.buttons.get("btn_new").ref.current.setDisabled(false);
        this.buttons.get("btn_show").ref.current.setDisabled(false);
        this.getField('codigo_interno').setDisabled(true);
        this.getField('descripcion').setDisabled(true);
        this.getField('tarifa_iva').setDisabled(true);
        this.getField('costo').setDisabled(true);
        this.getField('codigo_grupo').setDisabled(true);
        this.getField('nombre_grupo').setDisabled(true);
        this.getField('tipo_articulo').setDisabled(true);
        this.getField('estadoactivo_id').setDisabled(true);

        this.getField('codigo_interno').setError(false, '');
        this.getField('descripcion').setError(false, '');
        this.getField('tarifa_iva').setError(false, '');
        this.getField('costo').setError(false, '');
        this.getField('codigo_grupo').setError(false, '');
        this.getField('nombre_grupo').setError(false, '');
        this.getField('tipo_articulo').setError(false, '');
        this.getField('estadoactivo_id').setError(false, '');

        if (!this.btnModificar) {
            this.getField('articulo_varios_id').setValue('');
            this.getField('grupos_id').setValue('');
            this.getField('codigo_interno').setValue('');
            this.getField('descripcion').setValue('');
            this.getField('tarifa_iva').setValue('');
            this.getField('costo').setValue('');
            this.getField('codigo_grupo').setValue('');
            this.getField('nombre_grupo').setValue('');
            this.getField('tipo_articulo').setValue('');
            this.getField('estadoactivo_id').setValue('');
            this.servicioTraerPrimero();
        }

        this.buttons.get("btn_new").ref.current.setVisible(true);
        this.buttons.get("btn_show").ref.current.setVisible(true);
        this.buttons.get("btn_change").ref.current.setVisible(true);

        this.gridOptionsTableArticulosVarios["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTableArticulosVarios);
        this.getField('tableShowData').toggle(false);

        this.dataTableArticulosVariosSeleccion = false;
        this.servicioTraerTodos();

        this.btnModificar = false;
    }

    handlerClickChange(props) {

        this.btnModificar = true;
        
        this.validarRegistroSeleccionadoModificar();


    }

    handerClickShowTable(props) {


        this.servicioTraerTodos();
    }

    handlerClickNew(props) {

        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'maes-manejoarticulosvarios', operacion: 'traercodigo', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successNuevo,
                error: this.error_,
                general: this.generalFormatPmv
            });

    }

    successNuevo(data) {
        
        if (data.estado_p === 200) {

            let longitud = data.data[0].activofijo_longitud;
            let numero = data.data[0].activofijo_numero;

            let codigo = (numero + 1).toString();

            let cantidadCeros = longitud - codigo.length;            

            if (cantidadCeros >= 1) {
                let ceros = "";

                for (let i = 0; i < cantidadCeros; i++) {

                    ceros += "0";                    

                }
                
                this.getField('codigo_interno').setDisabled(false);
                this.getField('codigo_interno').setValue(ceros + codigo);

            } else {

                this.getField('codigo_interno').setDisabled(false);
                this.getField('codigo_interno').setValue(codigo);
            }

            this.getField('btn_agregar').setVisible(true);
            this.getField('btn_cancelar').setVisible(true);
            this.getField('btn_agregar').setDisabled(false);
            this.getField('btn_cancelar').setDisabled(false);
            this.getField('btn_modificar').setVisible(false);
            this.getField('btn_cancelar_modificar').setVisible(false);
            this.buttons.get("btn_new").ref.current.setVisible(false);
            this.buttons.get("btn_show").ref.current.setVisible(false);
            this.buttons.get("btn_change").ref.current.setVisible(false);

            this.getField('descripcion').setDisabled(false);
            this.getField('tarifa_iva').setDisabled(false);
            this.getField('costo').setDisabled(false);
            this.getField('codigo_grupo').setDisabled(false);
            this.getField('nombre_grupo').setDisabled(false);
            this.getField('tipo_articulo').setDisabled(false);
            this.getField('estadoactivo_id').setDisabled(false);
            this.getField('btn_agregar').setDisabled(false);
            this.getField('btn_cancelar').setDisabled(false);
            this.getField('tableShowData').toggle(false);

            this.getField('articulo_varios_id').setValue('');
            this.getField('grupos_id').setValue('');

            this.getField('descripcion').setValue('');
            this.getField('tarifa_iva').setValue('');
            this.getField('costo').setValue('');
            this.getField('codigo_grupo').setValue('');
            this.getField('nombre_grupo').setValue('');
            this.getField('tipo_articulo').setValue('');
            this.getField('estadoactivo_id').setValue('');
        } else {

            this.alertGeneral.toggle(true, 'Error al buscar activos_fijos_consecutivos', 'error');
        }

    }

    camposCrud() {


        this.getField('btn_agregar').setVisible(true);
        this.getField('btn_cancelar').setVisible(true);
        this.getField('btn_agregar').setDisabled(false);
        this.getField('btn_cancelar').setDisabled(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.buttons.get("btn_new").ref.current.setVisible(false);
        this.buttons.get("btn_show").ref.current.setVisible(false);
        this.buttons.get("btn_change").ref.current.setVisible(false);
        this.getField('codigo_interno').setDisabled(false);
        this.getField('descripcion').setDisabled(false);
        this.getField('tarifa_iva').setDisabled(false);
        this.getField('costo').setDisabled(false);
        this.getField('codigo_grupo').setDisabled(false);
        this.getField('nombre_grupo').setDisabled(false);
        this.getField('tipo_articulo').setDisabled(false);
        this.getField('estadoactivo_id').setDisabled(false);
        this.getField('btn_agregar').setDisabled(false);
        this.getField('btn_cancelar').setDisabled(false);
        this.getField('tableShowData').toggle(false);

        this.getField('articulo_varios_id').setValue('');
        this.getField('grupos_id').setValue('');
        this.getField('codigo_interno').setValue('');
        this.getField('descripcion').setValue('');
        this.getField('tarifa_iva').setValue('');
        this.getField('costo').setValue('');
        this.getField('codigo_grupo').setValue('');
        this.getField('nombre_grupo').setValue('');
        this.getField('tipo_articulo').setValue('');
        this.getField('estadoactivo_id').setValue('');

    }

}

FormJaivana.addController("maes-manejoarticulosvarios", CustomManejoArticulosVarios);
export default CustomManejoArticulosVarios