import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @description: CustomConsultarMovimientosPorReferencia
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomConsultarMovimientosPorReferencia extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm                                            = this.initForm.bind(this);
       this.arrayObjetos                                        =new Map();
       this.arrayCampos                                         =new Map();
       this.bHayErrores                                         =false;
       this.bHayError                                           =false;
       this.gridOptionsComponentes                              = Object.assign({},this.gridOptions);
       this.gridOptionsComponentes['onSelectionChanged']        = this.onSelectionChanged;
       this.valores_negativos                                   =0;
       this.valores_positivos                                   =0;
       this.gridOptionsCuentas                                  = Object.assign({},this.gridOptions);
       this.onSelectionChanged                                  =this.onSelectionChanged.bind(this);
       this.gridOptionsCuentas['onSelectionChanged']            =this.onSelectionChanged;
       this.validarCampos                                       =this.validarCampos.bind(this);
       this.ocultarTabla                                        =this.ocultarTabla.bind(this);
       this.validarFechas                                       =this.validarFechas.bind(this);
       this.id_data                                             = 0;
       this.fechaNit                                            ='';
       this.datosTabla                                          =[];
       this.consultarConReferencia                              = this.consultarConReferencia.bind(this);
       this.successConsultarConReferencia                       = this.successConsultarConReferencia.bind(this);
       this.validarCamposFecha                                  = this.validarCamposFecha.bind(this);
       this.mostrarModal                                        = this.mostrarModal.bind(this);
       this.sucessMostrarModal                                  = this.sucessMostrarModal.bind(this);

    }

    initForm() {

       console.log("Formulario CustomConsultarMovimientosPorReferencia,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez,  @updateBy Jose Albeiro Marin Bernal");

       this.arrayCampos.set(2,'consultar_desde');
       this.arrayCampos.set(3,'consultar_hasta');
       this.arrayCampos.set(4,'referencia');
       this.arrayCampos.set(5,'consultar_comprobante_id');
       this.arrayCampos.set(6,'bt_saldos');
       this.arrayCampos.set(10,'consultar');
       this.arrayCampos.set(12,'tabla_movimientos');
       this.arrayCampos.set(17,'tabla_cuentas');
       this.arrayCampos.set(18,'modal_movimientos');

       this.arrayObjetos.set(1,'.');
       this.arrayObjetos.set(2,'consultar_desde');
       this.arrayObjetos.set(3,'consultar_hasta');
       this.arrayObjetos.set(4,'referencia');
       this.arrayObjetos.set(5,'consultar_comprobante_id');
       this.arrayObjetos.set(6,'bt_saldos');
       this.arrayObjetos.set(10,'consultar');
       this.arrayObjetos.set(12,'tabla_movimientos');
       this.arrayObjetos.set(17,'tabla_cuentas');
       this.arrayObjetos.set(18,'modal_movimientos');
       this.arrayObjetos.set(86,'.');
       this.arrayObjetos.set(85,'.');
       this.getField('consultar').setClick(this.validarCampos)
       this.getField('consultar_comprobante_id').setOnChange(()=>{if(this.getField('consultar_codigo_comprobante').valid() && this.getField('consultar_comprobante_id').getValue()!==''){this.getField('consultar_nombre_comprobante').setError(false,'');}});

       this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
       this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);
       this.getField('referencia').setOnChange(()=>{
            this.getField('consultar').setDisabled(false);
            this.ocultarTabla();
        });
        this.getField('bt_saldos').setClick(this.mostrarModal)
    }

    mostrarModal(){
        this.getField('bt_saldos').setDisabled(true);
        let datos={ 
                datos: { 
                    consultar_desde: this.getField('consultar_desde').getValue(), 
                    consultar_hasta: this.getField('consultar_hasta').getValue(),
                    referencia: this.getField('referencia').getValue()
                }};

                this.generalFormatPmv = { tipo_servicio: 'cont-consultaporreferencia', operacion: '1_saldos_por_cuenta', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.sucessMostrarModal,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
    }

    sucessMostrarModal(data){
        if (data.estado_p === 200){
            // console.log("successSinReferencia",data);
            this.getField('bt_saldos').setDisabled(false);
            this.getField('modal_saldos').handleClickOpen();
             this.gridOptionsCuentas['rowData'] = [];
             let configCell = new Map();
             this.gridOptionsCuentas['rowData'] = data.data;
             configCell.set('saldo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.saldo.toFixed(2)); }, cellStyle: {textAlign:"right"},width: 110, field: 'saldo'});//configCell.set('cupo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.cupo.toFixed(2)); }, cellStyle: {backgroundColor: "rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'},width: 100, field: 'cupo'});
             this.getField('tabla_cuentas').initData(this.gridOptionsCuentas,configCell);
        } else {
            this.getField('bt_saldos').setDisabled(false);
            this.getField('tabla_cuentas').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            } else {

                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
       }
    }

    onSelectionChanged(){
        
    }

    validarCamposFecha(){
        this.getField('consultar').setDisabled(false);
        this.ocultarTabla();
        let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
        let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
        // console.log(fechaInicial.substring(0,4))
        
        if (fechaInicial >= fechaFinal) {
            this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor a fecha desde!");
            return false;
        }
        else if(fechaInicial.substring(0,4) > 2013){
            this.getField('referencia').setDisabled(false);
            this.getField('referencia').setValue('');
        }else{
            this.getField('referencia').setDisabled(true);
            this.getField('referencia').setValue('.');
            this.getField('referencia').setError(false, "");


        }
         if (fechaFinal > fechaInicial) {
            this.getField('consultar_hasta').setError(false, "");
            return true;
        }
    }

    //*** definir los botones y los success
    validarCampos()
    {       this.ocultarTabla()
            this.getField('referencia').setError(false,'');
             if(this.getField('consultar_hasta').valid() && this.getField('consultar_desde').valid() && this.getField('referencia').valid()) {
               if(this.getField('consultar_hasta').getValue()>=this.getField('consultar_desde').getValue()){
                  let fecha= new Date();
                  if(parseInt(this.getField('consultar_hasta').getValue().substring(0,4))>fecha.getFullYear()){
                     this.numero_busquedas=fecha.getFullYear()-parseInt(this.getField('consultar_desde').getValue().substring(0,4));
                  }
                  else{
                     this.numero_busquedas=parseInt(this.getField('consultar_hasta').getValue().substring(0,4))-parseInt(this.getField('consultar_desde').getValue().substring(0,4));
                  }
                  if(this.validarFechas(this.getField('consultar_desde').getValue(),this.getField('consultar_hasta').getValue())){
                     if(this.getField('referencia').getValue() !== '' && this.getField('referencia').valid() )
                        this.consultarConReferencia();
                  }else{
                     this.alertGeneral.toggle(true,'La diferencia en el rango de fechas no puede ser superior a dos años..',"error");
                  }
               }
               else{ 
                  this.getField('consultar_hasta').setError(true,'* El campo Fecha hasta debe ser mayor a Fecha desde.');
               }
            }
             else{
             this.alertGeneral.toggle(true,'Es necesario llenar los campos requeridos.',"error");
            }
    }

    consultarConReferencia(){
        // this.getField('consultar').setDisabled(true);
        let datos={ 
                datos: { 
                    consultar_desde: this.getField('consultar_desde').getValue(), 
                    consultar_hasta: this.getField('consultar_hasta').getValue(),
                    referencia: this.getField('referencia').getValue()
                }};

                

                this.getField("tabla_movimientos").setTipoServicioOperacion("cont-consultaporreferencia-1_traer_por_referencia");
                this.getField("tabla_movimientos").sendRequest(this.successConsultarConReferencia, datos);

    }

    successConsultarConReferencia(data){
        if (data.estado_p === 200){
            // console.log("successConReferencia",data);
            this.getField('consultar').setDisabled(true);
            this.datosTabla=data.data;
             this.gridOptionsComponentes['rowData'] = [];
             this.valores_negativos = 0;
             this.valores_positivos = 0;
             let configCell = new Map();
             configCell.set('id', {cellRenderer:function(props){return props.data.id}, hide: true, field: 'id' });
             this.getField('tabla_movimientos').toggle(true);
             this.gridOptionsComponentes['rowData'] = this.datosTabla;
             configCell.set('valor', {cellRenderer:(props) => {return "".formatoPrecio(props.data.valor.toFixed(2)); }, cellStyle: {textAlign:"right"},width: 110, field: 'valor'});//configCell.set('cupo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.cupo.toFixed(2)); }, cellStyle: {backgroundColor: "rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'},width: 100, field: 'cupo'});
             this.getField('tabla_movimientos').initData(this.gridOptionsComponentes,configCell);
             this.getField('bt_saldos').setVisible(true);
        } else {
            this.getField('consultar').setDisabled(false);
            this.getField('tabla_movimientos').toggle(false);
            if(data.estado_p===404) {
                this.getField('consultar').setDisabled(true);
                // this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            } else {

                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
       }
    }


    ocultarTabla()
    {
        this.establecerPropiedades('6','visible','false');
        this.getField('modal_saldos').handleClose();
        this.getField('tabla_cuentas').toggle(false);
        this.getField('tabla_movimientos').toggle(false);
    }



    validarFechas(fecha1,fecha2){
      let dia = parseInt(fecha2.split('-')[2]);
      let mes = parseInt(fecha2.split('-')[1]);
      let anio = parseInt(fecha2.split('-')[0]);
      //let valid = true;
      let dia2 = parseInt(fecha1.split('-')[2]);
      let mes2 = parseInt(fecha1.split('-')[1]);
      let anio2 = parseInt(fecha1.split('-')[0]);
      if(anio>=anio2){
         if(anio-anio2<=2){
            if(anio-anio2===2){
               if(mes<=mes2){
                  if(dia<=dia2){
                     return true;
                  }else{
                     return false;
                  }
               }else{
                  return true;
               }
            }else{
               return true;
            }
            
         }else{
            return false;
         }
      }else{
         return false;
      }
    }


    //*** fin  los botones y los success



    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }

}
FormJaivana.addController('cont-consultaporreferencia',CustomConsultarMovimientosPorReferencia);
export default CustomConsultarMovimientosPorReferencia;


