import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomClonarDocumentos
 * @author: Anderson Acevedo
 * @version:  jdesk_1.01.0001.1
 **/



class CustomClonarDocumentos extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.habilitar                                              = this.habilitar.bind(this);
        this.validarfechas                                          = this.validarfechas.bind(this);
        this.procesar                                               = this.procesar.bind(this);
        this.cargarFechas                                           = this.cargarFechas.bind(this);
        this.limpiarErrores                                         = this.limpiarErrores.bind(this);
        this.validarCamposCuadreCaja                                = this.validarCamposCuadreCaja.bind(this);
        this.crearTabla                                             = this.crearTabla.bind(this);
        this.sucessCrearTabla                                       = this.sucessCrearTabla.bind(this);
        this.limpiarCampos                                          = this.limpiarCampos.bind(this);
        this.successDeleteTmp                                       = this.successDeleteTmp.bind(this);
        this.limpiarTemporal                                        = this.limpiarTemporal.bind(this);
        this.clonarDocumento                                        = this.clonarDocumento.bind(this);
        this.sucessClonarDocumento                                  = this.sucessClonarDocumento.bind(this);
        this.habilitarDestino                                       = this.habilitarDestino.bind(this);
        this.deshabilitarDestino                                    = this.deshabilitarDestino.bind(this);
        this.validarDocumentoOrigen                                 = this.validarDocumentoOrigen.bind(this);
        this.successValidarDocumentoOrigen                          = this.successValidarDocumentoOrigen.bind(this);
        this.validarfechaOrigen                                     = this.validarfechaOrigen.bind(this);
        this.gridOptionsComponentes                                 = Object.assign({},this.gridOptions);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);

    }



    initForm() {
        console.log("Formulario CustomClonarDocumentos,  @version:  jdesk_1.01.0001.1, @author: Anderson Acevedo ");
        this.cargarFechas();
        this.getField("fecha_origen").setOnChange(()=>{this.deshabilitarDestino();this.validarfechaOrigen()});
        this.getField("fecha_destino").setOnChange(this.validarfechas);
        this.deshabilitarDestino();
        this.getField("ver_documento").setClick(this.validarDocumentoOrigen);
        this.getField("aceptar").setClick(()=>{this.getField('modal_documentos').handleClose();});
        this.getField('actualizando').setValue(false);
        this.getField("documento_origen").setOnChange(this.deshabilitarDestino);
        this.getField("numero_documento_origen").setOnChange(this.deshabilitarDestino);

        this.getField('nombre_documento_origen').setDisabled(true);
        this.getField('nombre_documento_destino').setDisabled(true);
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;

        this.getField('clonar').setClick(this.procesar);
    }



    validarDocumentoOrigen(){

        let errores=0;
        if(!this.getField('numero_documento_origen').valid())
            errores++;
        if(!this.getField('documento_origen').valid())
            errores++;
        if(!this.getField('nombre_documento_origen').valid())
            errores++;
        if(!this.validarfechaOrigen())
            errores++;

        if(errores===0){
            let datos={ datos: {
                value:this.getField('fecha_origen').getValue().substring(0,4),
                fecha_origen:this.getField('fecha_origen').getValue(),
                numero_documento_origen:this.getField('numero_documento_origen').getValue(),
                documento_origen:this.getField('documento_origen').getValue(),
            }};

            this.getField("rejilla_documentos").setTipoServicioOperacion("cont-clonardocumentos-1_1");
            this.getField("rejilla_documentos").sendRequest(this.successValidarDocumentoOrigen, datos);
        }
    }
    onSelectionChanged(){
        
    }

    successValidarDocumentoOrigen(data){
        if(data.estado_p === 200){ 
            this.getField('modal_documentos').handleClickOpen();
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110, field: 'valor'});

            this.getField('rejilla_documentos').initData(this.gridOptionsComponentes,configCell);

            this.habilitarDestino();
        }else if(data.estado_p === 404){
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No existe el documento.');
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel('.'); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    habilitarDestino(){
        this.getField('fecha_destino').setDisabled(false);
        this.getField('numero_documento_destino').setDisabled(false);
        this.getField('documento_destino').setDisabled(false);
        this.getField('clonar').setDisabled(false);
    }

    deshabilitarDestino(){
        this.getField('fecha_destino').setDisabled(true);
        this.getField('numero_documento_destino').setDisabled(true);
        this.getField('documento_destino').setDisabled(true);
        this.getField('clonar').setDisabled(true);
    }


    habilitar(){
        this.getField('ver_documento').setDisabled(false);
        //.getField('rejilla').toggle(false);

    }

    validarfechaOrigen(){
        this.habilitar();
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_origen = new Date(this.getField("fecha_origen").getValue()).toISOString().split('T')[0];

        this.getField("fecha_origen").setError(false,"");

        if(fecha_origen > fecha_actual){
            this.getField("fecha_origen").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('ver_documento').setDisabled(true); 
            return false;
        }
        this.getField('ver_documento').setDisabled(false); 
        return true;
    }

    validarfechas(){
        this.habilitar();
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_origen = new Date(this.getField("fecha_origen").getValue()).toISOString().split('T')[0];
        let fecha_destino = new Date(this.getField("fecha_destino").getValue()).toISOString().split('T')[0];

        this.getField("fecha_origen").setError(false,"");
        this.getField("fecha_destino").setError(false,"");

        if(fecha_destino > fecha_actual){
            this.getField("fecha_destino").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('clonar').setDisabled(true); 
            return false;
        }

        if(fecha_origen > fecha_destino){
            this.getField("fecha_destino").setError(true,"No puede ser menor a la fecha desde.");
            this.getField('clonar').setDisabled(true); 
            return false;
        }

        this.getField('clonar').setDisabled(false); 
        return true;

    }


    cargarFechas(){

        //let fecha_origen = this.getField('fecha_origen').getValue();
        //let fecha_origen_nueva = fecha_origen.split('-',2)[0]+'-'+fecha_origen.split('-',2)[1]+'-01';
        this.getField('fecha_origen').setValue(this.fecha_hoy);
    }


    procesar(){
        this.limpiarErrores();
        this.validarCamposCuadreCaja();
    }


    validarCamposCuadreCaja(){
        let errores =0;
        
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };

        if(errores===0){
            this.clonarDocumento();
        }
    }

    crearTabla(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('clonar').setDisabled(true);
        let datos={ datos: {
        }};
            this.generalFormatPmv = { tipo_servicio: 'cont-clonardocumentos', operacion: 'creartemporal', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessCrearTabla,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }
    
    sucessCrearTabla(data){
          if (data.estado_p === 200 )
          {
            this.clonarDocumento(); 
          }
          else 
          {
            this.limpiarCampos();
            this.getField('clonar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
          }
          
      }

      clonarDocumento(){
        let errores=0;
        if(!this.getField('numero_documento_destino').valid())
            errores++;
        if(!this.getField('documento_destino').valid())
            errores++;
        if(!this.getField('nombre_documento_destino').valid())
            errores++;
        if(!this.validarfechaOrigen())
            errores++;

        if(errores===0){
            let datos={ datos: {
                fecha_origen:this.getField('fecha_origen').getValue(),
                fecha_destino:this.getField('fecha_destino').getValue(),
                documento_origen:this.getField('documento_origen').getValue(),
                documento_destino:this.getField('documento_destino').getValue(),
                actualizando:this.getField('actualizando').getValue(),
                numero_documento_origen:this.getField('numero_documento_origen').getValue(),
                numero_documento_destino:this.getField('numero_documento_destino').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'cont-clonardocumentos', operacion: 'clonar', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessClonarDocumento,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    sucessClonarDocumento(data){
        if (data.estado_p === 200 )
        {
            if(data.data.existe===true){
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El documento destinó ya existe. ¿Desea reemplazarlo?');
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.getField('actualizando').setValue(true);this.clonarDocumento() });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("Si"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.alertGeneral.toggle(true, 'Registro clonado con éxito.', 'success');
                this.limpiarCampos();
            }
        }
        else 
        { 
            this.getField('clonar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.limpiarCampos();
            }
            else {
                //this.limpiarCampos();
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            //this.limpiarTemporal();
        }
    }

    limpiarTemporal(){
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-clonardocumentos', operacion: '7_1', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp(data){
        //tmp_conta1_codigousuario
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    limpiarCampos(){
        this.getField('actualizando').setValue(false);
        this.getField('documento_origen').setValue('');
        this.getField('documento_destino').setValue('');
        this.getField('numero_documento_origen').setValue('');
        this.getField('numero_documento_destino').setValue('');
        this.getField('nombre_documento_destino').setValue('');
        this.getField('nombre_documento_origen').setValue('');
        this.getField('clonar').setDisabled(false);
        this.getField('fecha_origen').setValue(this.fecha_hoy);
        this.getField('fecha_destino').setValue(this.fecha_hoy);
        this.limpiarErrores();
        this.cargarFechas();
    }

      limpiarErrores(){
        this.getField('documento_origen').setError(false,'');
        this.getField('documento_destino').setError(false,'');
        this.getField('numero_documento_origen').setError(false,'');
        this.getField('numero_documento_destino').setError(false,'');
        this.getField('nombre_documento_destino').setError(false,'');
        this.getField('nombre_documento_origen').setError(false,'');
      }

      currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
    

}
FormJaivana.addController("cont-clonardocumentos", CustomClonarDocumentos);
export default CustomClonarDocumentos;