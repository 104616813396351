import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @author Cristian Ciro
 * @updateBy Sebastian Rios E.
 * @version jdesk_1.01.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomExogenaFormatosConceptos extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                                                           = this.initForm.bind(this);
        this.consultarExogenoFormatoConceptos                                   = this.consultarExogenoFormatoConceptos.bind(this);
        this.successTablaPrincipal                                              = this.successTablaPrincipal.bind(this);
        this.gridOptionsTbPrincipal                                             = Object.assign({},this.gridOptions);
        this.setButtonEliminar                                                  = this.setButtonEliminar.bind(this);
        this.openModal                                                          = this.openModal.bind(this);
        this.grabarContabilidad                                                 = this.grabarContabilidad.bind(this);
        this.validarUnico                                                       = this.validarUnico.bind(this);
        this.successValidarUnico                                                = this.successValidarUnico.bind(this);
        this.successCrearFormatoCuentaConcepto                                  = this.successCrearFormatoCuentaConcepto.bind(this);
        this.limpiarCampos                                                      = this.limpiarCampos.bind(this);
        this.recargarTabla                                                      = this.recargarTabla.bind(this);
        this.definir                                                            = this.definir.bind(this);
        this.eliminarRegistro                                                   = this.eliminarRegistro.bind(this);
        this.successEliminar                                                    = this.successEliminar.bind(this);       
        this.formatoid                                                          = 0;
        this.conceptoid                                                         = 0;

    }


    initForm(){
        console.log('Formulario CustomExogenaFormatosConceptos,  @version: jdesk_1.01.0001, @author:Cristian Ciro, @updateBy: Sebastian Rios E.') 
        
        this.getField("btn_seleccionar").setClick(this.consultarExogenoFormatoConceptos);
        this.getField("btn_definir").setClick(this.definir);
        this.getField("btn_aceptar2").setClick(this.grabarContabilidad);

        this.getField("btn_cancelar2").setClick(() => {
            this.getField("modal_contabilidad").handleClose();
        });

        this.getField('exoformato_id').setOnChange(()=>{
            this.getField('btn_seleccionar').setDisabled(false);
            if (!this.getField('exoformato_id').getValue()!==''){
                this.getField('tabla_items').toggle(false);
            }
            //
        });
        this.getField('exoconcepto_id').setOnChange(()=>{
            this.getField('btn_seleccionar').setDisabled(false);
            if (!this.getField('exoconcepto_id').getValue()!==''){
                this.getField('tabla_items').toggle(false);
            }
        });
    
    }


    consultarExogenoFormatoConceptos(){
        if(this.getField("nombre_formato").valid() & this.getField("nombre_concepto").valid()){
            this.getField("btn_seleccionar").setDisabled(true);

            let datos = { datos: {
                exoconcepto_id: this.getField("exoconcepto_id").getValue(),
                exoformato_id:this.getField("exoformato_id").getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'cont-exogenaformatosconceptos', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTablaPrincipal,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successTablaPrincipal(data){

        if (this.formatoid!==0 && this.conceptoid!==0){
            this.formatoid=0;
            this.conceptoid=0;
        }

        if(data.estado_p === 200){
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            let configCell = new Map();

            configCell.set('accion_1', { cellRenderer: this.setButtonEliminar, width: 110, sortable: false, filter: false, field: 'accion_1' });
            this.getField('tabla_items').initData(this.gridOptionsTbPrincipal,configCell);

        }else{
            this.getField('tabla_items').toggle(false);
            this.getField("btn_seleccionar").setDisabled(false);
        }
    }

    
    setButtonEliminar(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.eliminarRegistro(props)};
        
        button.setAttribute("id", 'button_def_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    openModal(data){

        let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
        let data2 = {
            "data": [
                {
                    "codigo": 'S',
                    "descripcion": 'SALDO',
                },
                {
                    "codigo": 'D',
                    "descripcion": 'DEBITO',
                },
                {
                    "codigo": 'C',
                    "descripcion": 'CREDITO',
                },
                {
                    "codigo": 'M',
                    "descripcion": 'MOVIMIENTO',
                },
            ]
        }
        data2.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.codigo;
            dataOp['text'] = item.descripcion;
            dataOp['campos_cambian'] = {};
            opciones.push(dataOp);
        });
        this.getField('tipo').setOptions(opciones);

        this.getField("modal_contabilidad").handleClickOpen();
        
    }

    grabarContabilidad(){
        if(this.getField("nombre_cuenta_contable").valid() && this.getField("cuentas_id").valid() && this.getField("cierre_tipo").valid() && this.getField("tipo").valid()){ 
            this.validarUnico(this.getField("cuentas_id").getValue(),this.successValidarUnico);
        }
    }

    
    validarUnico(cuenta,funcionGuardar){
        let datos = { datos: {
            exoformato_id:this.getField("exoformato_id").getValue(),
            exoconcepto_id:this.getField("exoconcepto_id").getValue(),
            cuentas_id:cuenta,
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-exogenaformatosconceptos', operacion: '1_3', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: funcionGuardar,
                error: this.error_,
                showMessage: false,
                general: this.generalFormatPmv
        });
    }

    successValidarUnico(data){
        if(data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Error (mensaje) la relación de formato, concepto y cuenta ya existe, no se permite duplicar', 'error');
        }else{
            let datos = { datos: {
                exoformato_id:this.getField("exoformato_id").getValue(),
                exoconcepto_id:this.getField("exoconcepto_id").getValue(),
                cierre_tipo:this.getField("cierre_tipo").getValue(),
                cuentas_id:this.getField("cuentas_id").getValue(),
                tipo:this.getField("tipo").getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'cont-exogenaformatosconceptos', operacion: '5_1', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrearFormatoCuentaConcepto,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successCrearFormatoCuentaConcepto(data){
        if(data.estado_p === 200){
            this.formatoid=this.getField("exoformato_id").getValue();
            this.conceptoid=this.getField("exoconcepto_id").getValue();
            this.limpiarCampos();
        }
    }

    
    limpiarCampos(){
        this.getField("modal_contabilidad").handleClose();
        // this.getField("codigo_formato").setValue("");
        // this.getField("codigo_concepto").setValue("");
        // this.getField("nombre_formato").setValue("");
        // this.getField("nombre_concepto").setValue("");
        this.recargarTabla();
    }

    recargarTabla(){
        let datos = { datos: {
            exoconcepto_id:this.conceptoid,
            exoformato_id:this.formatoid,
        }};



        this.generalFormatPmv = { tipo_servicio: 'cont-exogenaformatosconceptos', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTablaPrincipal,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    definir(){        
        if(this.getField("nombre_formato").valid() & this.getField("nombre_concepto").valid()){
            this.openModal();
        }   
       
    }

    eliminarRegistro(props){
        this.formatoid=props.data.formatoid;
        this.conceptoid=props.data.conceptoid;
        let datos = { datos: {
            id:props.data.id,
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-exogenaformatosconceptos', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv
        });

    }

    successEliminar(data){
        if(data.estado_p === 200){
            this.recargarTabla();
        }else{
            this.alertGeneral.toggle(true, 'Error al eliminar el registro', 'error');
        }
    }   

}
FormJaivana.addController("cont-exogenaformatosconceptos",CustomExogenaFormatosConceptos);
export default CustomExogenaFormatosConceptos