import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomRecepcionMercancia
 * @author: Santiago Hernández Neira
 * @version: jdesk_1.01.0002
 **/

class CustomRecepcionMercancia extends FormJaivana.form {

    constructor(props) {
        
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.opcionRecepcionMercancia                               = this.opcionRecepcionMercancia.bind(this);
        this.traerConsecutivo                                       = this.traerConsecutivo.bind(this);
        this.successTraerConsecutivo                                = this.successTraerConsecutivo.bind(this);
        this.pesoencero                                             = this.pesoencero.bind(this);
        this.cancelar                                               = this.cancelar.bind(this)
        this.aceptar                                                = this.aceptar.bind(this);
        this.datosAgregar                                           = {datos:{}};
        this.codigo_sucursal                                        = '';
        this.successAceptar                                         = this.successAceptar.bind(this);
        this.opcionRecibirMercanciaPai                              = this.opcionRecibirMercanciaPai.bind(this);
        this.cargarrecepcion                                        = this.cargarrecepcion.bind(this);
        this.successTraerConsecutivo2                               = this.successTraerConsecutivo2.bind(this);
        this.grabarItem                                             = this.grabarItem.bind(this);
        this.arrayTemporal                                          = [];
        this.nuevoArray                                             = [];
        this.loadTablaItems                                         = this.loadTablaItems.bind(this);
        this.gridOptionsTablaItems                                  = Object.assign({}, this.gridOptions);
        this.setButtonEliminarItem                                  = this.setButtonEliminarItem.bind(this);
        this.eliminarItemConfirmar                                  = this.eliminarItemConfirmar.bind(this);
        this.eliminarItem                                           = this.eliminarItem.bind(this);
        this.limpiarCamposPai                                       = this.limpiarCamposPai.bind(this);
        this.limpiarCamposPai2                                      = this.limpiarCamposPai2.bind(this);
        this.cancelarPai                                            = this.cancelarPai.bind(this);
        this.aceptarPai                                             = this.aceptarPai.bind(this);
        this.sucessGrabarRecepcion                                  = this.sucessGrabarRecepcion.bind(this);
        this.abrirModalPai                                          = this.abrirModalPai.bind(this);
        this.confirmarRecepcionPai                                  = this.confirmarRecepcionPai.bind(this);
        this.sucessActualizarRecepcion                              = this.sucessActualizarRecepcion.bind(this);
        this.cargarSelect                                           = this.cargarSelect.bind(this);
        this.successCargarSelect                                    = this.successCargarSelect.bind(this);
        this.opcionConsultarRecepcion                               = this.opcionConsultarRecepcion.bind(this);
        this.cargarFechaInicial                                     = this.cargarFechaInicial.bind(this);
        this.validarFechas                                          = this.validarFechas.bind(this);
        this.validarFechasEnvio                                     = this.validarFechasEnvio.bind(this);
        
        this.consultarRecepcion                                     = this.consultarRecepcion.bind(this);
        this.successConsultarRecepcion                              = this.successConsultarRecepcion.bind(this);
        this.gridOptionsComponentes                                 = Object.assign({},this.gridOptions);
        this.setButtonVerMas                                        = this.setButtonVerMas.bind(this);
        this.DatosVerMas                                            = this.DatosVerMas.bind(this);
        this.successDatosVerMas                                     = this.successDatosVerMas.bind(this);
        this.gridOptionsComponentesFact                             = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesFact2                            = Object.assign({}, this.gridOptions);
        this.pesobasculaChange                                      = this.pesobasculaChange.bind(this);
        this.successActualizarPesoBascula                           = this.successActualizarPesoBascula.bind(this);
        this.resizeTable                                            = this.resizeTable.bind(this);


    }

    initForm(){
        console.log('Formulario CustomRecepcionMercancia,  @version: jdesk_1.01.0002, @author:Santiago Hernández N.');
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionRecepcionMercancia();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionRecibirMercanciaPai();
        }else if(this.props.data[0].opcion_sub_seccion === 3){
            this.opcionConsultarRecepcion();
        }

 
    }












    /////////////////////////////////////////////////////////////////////RECEPCIÓN DE MERCANCÍA/////////////////////////////////////////////////////////////////////
    opcionRecepcionMercancia(){    
        this.getField('operacion').setValue('RECEPCIÓN DE MERCANCÍA');
        
        this.getField('consecutivo').setVisible(true);
        this.getField('nit_proveedor').setVisible(true);
        this.getField('sede_proveedor').setVisible(true);
        this.getField('nombre_proveedor').setVisible(true);
        this.getField('nit_transportador').setVisible(true);
        this.getField('sede_transportador').setVisible(true);
        this.getField('nombre_transportador').setVisible(true);

        this.getField('fecha_recibido').setVisible(true);
        this.getField('fecha_envio').setVisible(true);
        this.getField('hora_recibido').setVisible(true);
        this.getField('numero_cajas').setVisible(true);
        this.getField('peso_guia').setVisible(true);
        this.getField('pesocero').setVisible(true);
        this.getField('peso_bascula').setVisible(true);
        this.getField('aceptar').setVisible(true);
        this.getField('cancelar').setVisible(true);

        this.getField('fecha_recibido').setOnChange((props) => {
            this.validarFechasEnvio();
        });
        this.getField('fecha_envio').setOnChange((props) => {
            this.validarFechasEnvio();
        });


        this.traerConsecutivo();
        this.getField('pesocero').setClick(this.pesoencero);
        this.getField('cancelar').setClick(this.cancelar);
        this.getField('aceptar').setClick(this.aceptar);

    }

    traerConsecutivo(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'comp-recepcionmercancia', operacion: 'traerconsecutico', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successTraerConsecutivo,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successTraerConsecutivo(data){
        if (data.estado_p === 200){
            this.getField('consecutivo').setValue(data.data[0].numero);
        } else {
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
       }
    }

    pesoencero(){
        this.getField('peso_bascula').setValue('0');
        this.getField('peso_bascula').setError(false,"");
    }

    cancelar(){
        this.getField('nit_proveedor').setValue('');
        this.getField('sede_proveedor').setValue('');
        this.getField('nombre_proveedor').setValue('');
        this.getField('nit_transportador').setValue('');
        this.getField('sede_transportador').setValue('');
        this.getField('nombre_transportador').setValue('');
        let fecha_actual = new Date();
        let fechaActualFormateada = fecha_actual.toISOString().split('T')[0];
        this.getField('fecha_envio').setValue(fechaActualFormateada);
        this.getField('fecha_recibido').setValue(fechaActualFormateada);
        const horaActual = new Intl.DateTimeFormat(undefined, { timeStyle: "short" }).format(new Date());

        this.getField('hora_recibido').setValue(horaActual);
        this.getField('numero_cajas').setValue('0');
        this.getField('peso_guia').setValue('0');
        this.getField('peso_bascula').setValue('0');

        this.getField('nit_proveedor').setError(false,'');
        this.getField('sede_proveedor').setError(false,'');
        this.getField('nombre_proveedor').setError(false,'');
        this.getField('nit_transportador').setError(false,'');
        this.getField('sede_transportador').setError(false,'');
        this.getField('nombre_transportador').setError(false,'');
        this.getField('fecha_envio').setError(false,'');
        this.getField('fecha_recibido').setError(false,'');
        this.getField('hora_recibido').setError(false,'');
        this.getField('numero_cajas').setError(false,'');
        this.getField('peso_guia').setError(false,'');
        this.getField('peso_bascula').setError(false,'');
    }

    aceptar(){
        if((this.getField('nit_proveedor').valid()) && (this.getField('sede_proveedor').valid()) && (this.getField('nit_transportador').valid()) 
        && (this.getField('sede_transportador').valid()) && (this.getField('fecha_envio').valid()) && (this.getField('fecha_recibido').valid())
        && (this.getField('numero_cajas').valid()) && (this.getField('peso_guia').valid()) && (this.getField('peso_bascula').valid())){

            if(this.validarFechasEnvio()){
                this.datosAgregar.datos['consecutivo']=this.getField('consecutivo').getValue();
                this.datosAgregar.datos['nit_proveedor']=this.getField('nit_proveedor').getValue();
                this.datosAgregar.datos['sede_proveedor']=this.getField('sede_proveedor').getValue();
                this.datosAgregar.datos['nit_transportador']=this.getField('nit_transportador').getValue();
                this.datosAgregar.datos['sede_transportador']=this.getField('sede_transportador').getValue();
                this.datosAgregar.datos['fecha_envio']=this.getField('fecha_envio').getValue();
                this.datosAgregar.datos['fecha_recibido']=this.getField('fecha_recibido').getValue();
                this.datosAgregar.datos['hora_recibido']=this.getField('hora_recibido').getValue();
                this.datosAgregar.datos['numero_cajas']=this.getField('numero_cajas').getValue();
                this.datosAgregar.datos['peso_guia']=this.getField('peso_guia').getValue();
                this.datosAgregar.datos['peso_bascula']=this.getField('peso_bascula').getValue();
                this.datosAgregar.datos['codigo_sucursal']=this.codigo_sucursal;
                let datos=this.datosAgregar;
                this.generalFormatPmv = { tipo_servicio: 'comp-recepcionmercancia', operacion: '5', operacion_tipo: 'crear' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successAceptar,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }else{
            this.alertGeneral.toggle(true, 'Error en los datos ', 'error');
        }
    }

    validarFechasEnvio(){
        let fechaInicial = new Date(this.getField('fecha_envio').getValue()).toISOString().split('T')[0];
        let fechaFinal = new Date(this.getField('fecha_recibido').getValue()).toISOString().split('T')[0];
        if (fechaInicial > fechaFinal) {
            this.getField('fecha_recibido').setError(true, "¡Fecha debe ser mayor o igual a fecha de envío!");
            return false;
        } else {
            this.getField('fecha_recibido').setError(false, "");
            return true;
        }
    }

    successAceptar(data){
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Recepción de Mercancía grabada con éxito.', 'success');
            this.cancelar();
            this.traerConsecutivo();
        } else {
            this.alertGeneral.toggle(true, 'Error en recepción de mercancía.', 'error');
            this.cancelar();
            this.traerConsecutivo();
       }
    }















    /////////////////////////////////////////////////////////////////////RECIBIR MERCANCÍA PAI/////////////////////////////////////////////////////////////////////    
    opcionRecibirMercanciaPai(){

        this.getField('operacion').setValue('RECIBIR MERCANCÍA PAI');        
        
        this.getField('select_consecutivo').setVisible(true);

        this.getField('select_consecutivo').setOnChange((props) => {
            this.getField('consecutivopai').setValue("");
            this.getField('nit_proveedor_pai').setValue("");
            this.getField('sede_proveedor_pai').setValue("");
            this.getField('nombre_proveedor_pai').setValue("");
            this.getField('cargar').setDisabled(false);
        });

        this.getField('cargar').setVisible(true);
        this.getField('consecutivopai').setVisible(true);
        this.getField('nit_proveedor_pai').setVisible(true);
        this.getField('nombre_proveedor_pai').setVisible(true);
        this.getField('sede_proveedor_pai').setVisible(true);
        this.getField('codigo_articulo').setVisible(true);
        this.getField('nombre_articulo').setVisible(true);
        this.getField('contenido').setVisible(true);
        this.getField('cantidad').setVisible(true);
        this.getField('grabar').setVisible(true);

        this.getField('cargar').setClick(this.cargarrecepcion);
        this.getField('grabar').setClick(this.grabarItem);
        this.getField('cancelar_pai').setClick(this.cancelarPai);
        this.getField('aceptar_pai').setClick(this.aceptarPai);
        this.getField('confirmar_recepcion_pai').setClick(this.confirmarRecepcionPai);

        this.cargarSelect();
        

    
    }

    cargarrecepcion(){
        if((this.getField('select_consecutivo').valid())){
            this.getField('cargar').setDisabled(true);
            let datos={ datos: {
                select_consecutivo:Number(this.getField('select_consecutivo').getValue())
            }}   
            this.generalFormatPmv = { tipo_servicio: 'comp-recepcionmercancia', operacion: 'traerrecepcionxconsecutivo', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerConsecutivo2,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Error en los datos ', 'error');
        }
    }


    successTraerConsecutivo2(data){

        if (data.estado_p === 200){

            this.getField('consecutivopai').setValue(data.data[0].consecutivo);
            this.getField('nit_proveedor_pai').setValue(data.data[0].nit_proveedor);
            this.getField('sede_proveedor_pai').setValue(data.data[0].sede_proveedor);
            this.getField('nombre_proveedor_pai').setValue(data.data[0].nombre_proveedor);

        } else {
            this.alertGeneral.toggle(true, 'Error al cargar el consecutivo.', 'error');
       }
    }

    grabarItem(){
        let ObjetoItem = {}

        if(this.getField("consecutivopai").valid() && this.getField("nit_proveedor_pai").valid() && this.getField("sede_proveedor_pai").valid() && 
        this.getField("nombre_proveedor_pai").valid() && this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid() &&
        this.getField("contenido").valid() && this.getField("cantidad").valid()){

            if (this.getField("cantidad").getValue()>0){

                ObjetoItem = {
                    codigo_articulo:this.getField("codigo_articulo").getValue(),
                    nombre_articulo:this.getField("nombre_articulo").getValue(),
                    contenido:this.getField("contenido").getValue(),
                    cantidad:this.getField("cantidad").getValue(),
                    consecutivo:this.getField("consecutivopai").getValue(),
                };

                this.arrayTemporal.push(ObjetoItem); //el item agregado se carga primero en un array temporal
                let codigoArray1 = this.getField("codigo_articulo").getValue(); //el codigo del articulo se guarda en un array
                let codigoArray2 = []; // se crea un array vacio
                this.nuevoArray.forEach((item) => {
                    //se recorre el array principal, y se busca si el codigo del articulo en el array1 ya se encuentra en el array principal
                    if (item.codigo_articulo === codigoArray1) {
                        codigoArray2.push(item); // si es asi, se carga ese codigo en el array2
                    }
                });

                if (codigoArray2.length === 0) {
                    this.nuevoArray.push(ObjetoItem);
                    this.loadTablaItems(this.nuevoArray);
                    this.limpiarCamposPai();

                    this.resizeTable();
                    
                } else {
                    //si el array2 es diferente de 0, quiere decir que el articulo ya fue agregado y se muestra el mensaje
                    this.alertGeneral.toggle(true, "El item ya fue agregado", "error");
                }

            }else{
                this.getField("cantidad").setError(true,'* Por favor ingrese una cantidad.');
            }

        }else{
            this.alertGeneral.toggle(true, 'Error en los datos ', 'error');
            ObjetoItem = {};
        }

    }

    resizeTable(){
        let newGrid = this.gridOptionsTablaItems;
        setTimeout( function(){
            newGrid.api.sizeColumnsToFit();
        } ,50);
    }

    loadTablaItems(data){
        /*this.gridOptionsTablaItems["rowData"] = [];
        this.getField("tb_articulos").initData(this.gridOptionsTablaItems);
        this.getField("tb_articulos").toggle(false);*/

        this.gridOptionsTablaItems["rowData"] = data; //y se recarga la tabla
        this.arrayOrigin = this.nuevoArray;
        let configCell = new Map();

        configCell.set('accion', { cellRenderer: this.setButtonEliminarItem, width: 220, sortable: false, filter: false, field: 'accion' });
        this.getField("tb_articulos").initData(this.gridOptionsTablaItems,configCell);

        this.getField('aceptar_pai').setVisible(true);
        this.getField('cancelar_pai').setVisible(true);

    }

    setButtonEliminarItem(props){
        /**
            * se crean las propiedades del boton para darle funcionalidad
        */
        let button = document.createElement("input");
        button.onclick = () => this.eliminarItemConfirmar(props.data.codigo_articulo); //funcion que se ejecuta al dar click al boton, el cual recibe por props el codigo del item
        button.setAttribute("id", "button_aprove_");
        button.setAttribute("class", "buttonStyle6"); //,"width:100%; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar"); //nombre del boton
        return this.createElementJaivana(button);
    }

    eliminarItemConfirmar(codigo_articulo){
        /**
         * Cuando se ejecuta esta funcion, recibe el codigo del item
         * y pide la confirmacion de eliminacion, a su vez, setea este codigo en un campo
        */
        this.id_delete = codigo_articulo;
        this.getField("confirmModalCustom").setTitleAndContent("Eliminar", "Desea realizar esta operación?");
        this.getField("confirmModalCustom").setClickDialog(this.eliminarItem); //funcion que se ejecuta al confirmar eliminacion
        this.getField("confirmModalCustom").toggle(true);
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("codigo_eliminar").setValue(codigo_articulo);
    }

    eliminarItem(){
        this.gridOptionsTablaItems["rowData"] = [];
        this.getField("tb_articulos").initData(this.gridOptionsTablaItems);
        this.getField("tb_articulos").toggle(false);
        this.getField("confirmModalCustom").toggle(false);
        let auxiliarArray = [];
        
        this.nuevoArray.forEach((item) => {
            if (item.codigo_articulo !== this.getField("codigo_eliminar").getValue()) {
                auxiliarArray.push(item);
            }
        });
        /**
         * Se carga nuevamente el array principal con la informacion del nuevo array,
         * y a su vez se refresca la tabla sin el item seleccionado
         */
        this.nuevoArray = auxiliarArray;
        
        if (this.nuevoArray.length > 0) {
            this.gridOptionsTablaItems["rowData"] = this.nuevoArray;
            let configCell = new Map();            
            configCell.set('accion', { cellRenderer: this.setButtonEliminarItem, width: 220, sortable: false, filter: false, field: 'accion' });
            this.getField("tb_articulos").initData(this.gridOptionsTablaItems,configCell);
            this.getField('aceptar_pai').setVisible(true);
            this.getField('cancelar_pai').setVisible(true);

        }else{
            this.getField("tb_articulos").toggle(false);
            this.getField('aceptar_pai').setVisible(false);
            this.getField('cancelar_pai').setVisible(false);
            this.limpiarCamposPai2();
        }
        this.resizeTable();
    }

    limpiarCamposPai(){
        this.getField('cargar').setDisabled(true);
        this.getField('codigo_articulo').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('contenido').setValue(0);
        this.getField('cantidad').setValue(0);
    }

    limpiarCamposPai2(){
        this.getField('select_consecutivo').setValue('');
        
        this.getField('consecutivopai').setValue('');
        this.getField('nit_proveedor_pai').setValue('');
        this.getField('nombre_proveedor_pai').setValue('');
        this.getField('sede_proveedor_pai').setValue('');

        this.getField('cargar').setDisabled(false);
        this.getField('codigo_articulo').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('contenido').setValue(0);
        this.getField('cantidad').setValue(0);  
    }

    cancelarPai(){
        this.getField('select_consecutivo').setValue('');        
        this.getField('consecutivopai').setValue('');
        this.getField('nit_proveedor_pai').setValue('');
        this.getField('nombre_proveedor_pai').setValue('');
        this.getField('sede_proveedor_pai').setValue('');
        this.getField('cargar').setDisabled(false);
        this.getField('codigo_articulo').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('contenido').setValue(0);
        this.getField('cantidad').setValue(0);
        this.gridOptionsTablaItems["rowData"] = [];
        this.getField("tb_articulos").initData(this.gridOptionsTablaItems);
        this.getField("tb_articulos").toggle(false);
        this.getField('aceptar_pai').setVisible(false);
        this.getField('cancelar_pai').setVisible(false);
        this.getField('radio_recepcion').setVisible(false);
        this.getField('confirmar_recepcion_pai').setVisible(false);
        this.arrayTemporal=[];
        this.nuevoArray=[];
    }

    aceptarPai(){
        let datos={ datos: {
            data: this.nuevoArray,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-recepcionmercancia', operacion: 'grabarrecepcionpai', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.sucessGrabarRecepcion,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }


    sucessGrabarRecepcion(data){
        
        if (data.estado_p === 200){

            this.abrirModalPai();
        
        } else {
            this.alertGeneral.toggle(true, 'Error al grabar.', 'error');
            this.cancelarPai();
            this.cargarSelect();
       }
    }

    abrirModalPai(){
        this.getField('modal_result').handleClickOpen();
        //this.getField('label6').setVisible(true);
        this.getField('radio_recepcion').setVisible(true);
        this.getField('confirmar_recepcion_pai').setVisible(true);

    }

    confirmarRecepcionPai(){

        let datos={ datos: {
            consecutivopai: this.getField('consecutivopai').getValue(),
            radio_recepcion:this.getField('radio_recepcion').getValue(),
            codigo_sucursal:this.codigo_sucursal,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-recepcionmercancia', operacion: 'actualizarrecepcionpai', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.sucessActualizarRecepcion,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    sucessActualizarRecepcion(data){
        if (data.estado_p === 200){
            this.cancelarPai();
            this.getField('modal_result').handleClose();
            this.cargarSelect();

        } else {
            this.alertGeneral.toggle(true, 'Datos no grabados. Entrada NO cerrada', 'error');
            this.cancelarPai();
            this.getField('modal_result').handleClose();
            this.cargarSelect();
       }
    }




    cargarSelect(){
        
        let datos={datos:{codigo_sucursal:this.codigo_sucursal}};

        this.generalFormatPmv = { tipo_servicio: 'comp-recepcionmercancia', operacion: 'traerconsecutivospai', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarSelect,
                error: this.error_,
                general: this.generalFormatPmv,
        });

    }


    successCargarSelect(data){
        if(data.estado_p === 200){
            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.consecutivo;
                dataOp['text'] = `${item.consecutivo}`;
                dataOp['campos_cambian'] = { };
                opciones.push(dataOp); 
            })
            this.getField('select_consecutivo').setOptions(opciones);
        }
    }
    


















    /////////////////////////////////////////////////////////////////////CONSULTAR RECEPCION/////////////////////////////////////////////////////////////////////    
    opcionConsultarRecepcion(){
        this.getField('operacion').setValue('CONSULTAR RECEPCIÓN');
        this.getField('fecha_inicial').setVisible(true);
        this.getField('fecha_final').setVisible(true);
        this.getField('consultar').setVisible(true);
        this.getField('fecha_inicial').setOnChange(this.validarFechas);
        this.getField('fecha_final').setOnChange(this.validarFechas);        
        this.cargarFechaInicial();
        this.getField('consultar').setClick(this.consultarRecepcion);
    }

    cargarFechaInicial(){

        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-01";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fecha_inicial').setValue(campoFecha.split('T')[0]);


    }

    validarFechas(){
        this.getField('tb_recepcion').toggle(false);
        this.getField('tb_fact_pda').toggle(false);
        if (this.getField('fecha_inicial').getValue() !== '' && this.getField('fecha_final').getValue() !== '') {
            let fecha_inicial = new Date(this.getField('fecha_inicial').getValue());
            let fecha_final = new Date(this.getField('fecha_final').getValue());
            let finicioFormateada = fecha_inicial.toISOString().split('T')[0];
            let ffinalFormateada = fecha_final.toISOString().split('T')[0];
    
            if (new Date(finicioFormateada) > new Date(ffinalFormateada)) {
                this.getField('fecha_final').setError(true, "¡La Fecha final debe ser mayor o igual a la fecha inicial!");
                this.getField('consultar').setDisabled(true);
                return false;
            }else if (new Date(finicioFormateada) <= new Date(ffinalFormateada)){
                this.getField('fecha_final').setError(false, "");
                this.getField('consultar').setDisabled(false);
                return true;
            }
        }
    }

    consultarRecepcion(){
        if(this.validarFechas()){
            this.getField('consultar').setDisabled(true);

            let datos={datos:{
                codigo_sucursal:this.codigo_sucursal,
                fecha_inicial:this.getField('fecha_inicial').getValue(),
                fecha_final:this.getField('fecha_final').getValue(),
            }};

            this.generalFormatPmv = { tipo_servicio: 'comp-recepcionmercancia', operacion: 'consultarrecepcion', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successConsultarRecepcion,
                    error: this.error_,
                    showMessage: false,
                    general: this.generalFormatPmv,
            });
        }
    }

    successConsultarRecepcion(data){
        if (data.estado_p === 200){
            this.datosTabla=data.data;
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('tb_recepcion').toggle(true);
            let configCell = new Map();      
            configCell.set('peso_bascula', { cellRenderer: function (props) { return props.data.peso_bascula }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.pesobasculaChange, sortable: true, filter: false, field: 'peso_bascula' });      
            configCell.set('accion', { cellRenderer: this.setButtonVerMas, width: 110, sortable: false, filter: false, field: 'accion' });
            this.gridOptionsComponentes['rowData'] = this.datosTabla;
            this.getField('tb_recepcion').initData(this.gridOptionsComponentes,configCell);
        } else { 
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
       }
    }

    setButtonVerMas(props){
        let fragment = document.createDocumentFragment();
        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        button2.onclick = () => this.DatosVerMas(props);
        button2.setAttribute("consecutivo", 'button_add_' + props.data.consecutivo);
        //button2.setAttribute("class", "buttonStyle");
        button2.setAttribute("type", "button");
        //button.classList.add(hover);
        button2.setAttribute("value", "Ver más");
        button2.setAttribute("class","buttonStyle2");

        fragment.appendChild(button2);
        return this.createElementJaivana(fragment);

    }



    DatosVerMas(data){
        if (data !== '' && data !== undefined) {
            this.consecutivo = data.data.consecutivo;
        }

        let datos={datos: {consecutivo: this.consecutivo}};
        this.generalFormatPmv = { tipo_servicio:  'comp-recepcionmercancia', operacion: 'consultarfacpda', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDatosVerMas,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false,
        });
    }

    successDatosVerMas(data){
        if (data.estado_p === 200 ) {
            this.datosTabla=data.data;
            this.gridOptionsComponentesFact['rowData'] = [];
            this.getField('tb_fact_pda').toggle(true);
           this.gridOptionsComponentesFact['rowData'] = this.datosTabla;
           if(this.datosTabla.length > 0)
                this.getField('tb_fact_pda').initData(this.gridOptionsComponentesFact);
        } else {
            this.alertGeneral.toggle(true, 'No hay datos, No se encuentra consecutivo para facturas_pda.', 'error');
            this.getField('tb_fact_pda').toggle(false);
        }
    }

    pesobasculaChange(props) {
        let seguir = this.getField('tb_recepcion').cellValid(props.oldValue, props.newValue,  /^(\d{1,15})(\.\d{1,2})?$/, 7);
        if (seguir) {
            let valor_actual = props.newValue;
            
            let datos={ datos: {
                consecutivo: props.data.consecutivo,
                peso_bascula: Number(valor_actual),
                codigo_sucursal:this.codigo_sucursal,
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-recepcionmercancia', operacion: 'actualizarpesobascula', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successActualizarPesoBascula,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

        } else if (seguir !== null)
            this.consultarRecepcion();
    }

    successActualizarPesoBascula(data){
        if (data.estado_p === 200 ) {
            this.consultarRecepcion();
        } else {
            this.alertGeneral.toggle(true, 'Error al actualizar peso bascula.', 'error');
            this.consultarRecepcion();
        }
    }




}


FormJaivana.addController("comp-recepcionmercancia",CustomRecepcionMercancia);
export default CustomRecepcionMercancia