import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomAutorizacionModificacionDeDocumento
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomAutorizacionModificacionDeDocumento extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                             = this.initForm.bind(this);
        this.arrayObjetos                         = new Map();
        this.arrayCampos                          = new Map();
        this.crearOrigen                          = this.crearOrigen.bind(this);
        this.successcrear                         = this.successcrear.bind(this);
        this.actualiarOrigen                      = this.actualiarOrigen.bind(this);
        this.successActualizarOrigen              = this.successActualizarOrigen.bind(this);
        this.formulario='';
        this.traerTodosOrigen                     = this.traerTodosOrigen.bind(this);
        this.successtraerTodosOrigen              = this.successtraerTodosOrigen.bind(this);
        this.traerPrimeroOrigen                   = this.traerPrimeroOrigen.bind(this);
        this.successTraerPrimeroOrigen            = this.successTraerPrimeroOrigen.bind(this);
        this.traerPorIdFormatos                   = this.traerPorIdFormatos.bind(this);
        this.successTraerPorIdOrigen              = this.successTraerPorIdOrigen.bind(this);
        this.confirmarEliminacionOrigen           = this.confirmarEliminacionOrigen.bind(this);
        this.confirmarEliminacionPorUsuario       = this.confirmarEliminacionPorUsuario.bind(this);
        this.eliminarPorIdOrigen                  = this.eliminarPorIdOrigen.bind(this);
        this.successEliminarPorIdOrigen           = this.successEliminarPorIdOrigen.bind(this);
        this.btnModificar                         = false;
        this.onClickChange                        = this.handlerClickChange.bind(this);
        this.onClickShowTable                     = this.handerClickShowTable.bind(this);
        this.onClickNew                           = this.handlerClickNew.bind(this);
        this.onClickDelete                        = this.handlerClickDelete.bind(this);
        this.onSelectionChanged                   = this.onSelectionChanged.bind(this);
        this.registro_seleccionado=false;
        this.mostrarMensajeSeleccionModificar     =   this.mostrarMensajeSeleccionModificar.bind(this);
        this.mostrarMensajeSeleccionEliminar      =   this.mostrarMensajeSeleccionEliminar.bind(this);
        this.successValidarUniqueOrigen           =   this.successValidarUniqueOrigen.bind(this);
        this.validacionUniqueOrigen               =   this.validacionUniqueOrigen.bind(this);

        this.traerTodosUsuario                    =   this.traerTodosUsuario.bind(this);
        this.successTraerTodosUsuario             =   this.successTraerTodosUsuario.bind(this);
        this.traerPrimeroUsuario                  =   this.traerPrimeroUsuario.bind(this);
        this.successTraerPrimeroUsuario           =   this.successTraerPrimeroUsuario.bind(this);
        this.validacionUniqueUsuario              =   this.validacionUniqueUsuario.bind(this);
        this.successValidarUniqueUsuario          =   this.successValidarUniqueUsuario.bind(this);
        this.traerPorIdUsuario                    =   this.traerPorIdUsuario.bind(this);
        this.successTraerPorIdUsuario             =   this.successTraerPorIdUsuario.bind(this);
        this.eliminarPorIdUsuario                 =   this.eliminarPorIdUsuario.bind(this);
        this.successEliminarPorIdUsuario          =   this.successEliminarPorIdUsuario.bind(this);
        this.crearUsuarioCampo                    =   this.crearUsuarioCampo.bind(this);
        this.successcrearUsuarioCampo             =   this.successcrearUsuarioCampo.bind(this);
        this.actualiarUsuarioCampo                =   this.actualiarUsuarioCampo.bind(this);
        this.successActualizarUsuarioCampo        =   this.successActualizarUsuarioCampo.bind(this);
        this.btnCancelarFormatos                  =   this.btnCancelarFormatos.bind(this);

    }

    initForm(){
        console.log('Formulario CustomAutorizacionModificacionDeDocumento,  @version: jdesk_1.01.0001, @author:Anderson Acevedo Briñez,  @updateBy Jose Albeiro Marin Bernal')

        this.arrayCampos.set(1,'codigo_origen');
        this.arrayCampos.set(2,'nombre_origen');
        this.arrayCampos.set(3,'codigo_campo');
        this.arrayCampos.set(4,'nombre_campo');
        this.arrayCampos.set(5,'modificar_origen_id');
        this.arrayCampos.set(6,'codigo_usuario');
        this.arrayCampos.set(7,'nombre_usuario');
        this.arrayCampos.set(8,'modificar_usuario_id');
        this.arrayCampos.set(30,'btn_agregar');
        this.arrayCampos.set(31,'btn_cancelar');
        this.arrayCampos.set(32,'btn_modificar');
        this.arrayCampos.set(33,'btn_cancelar_modificar');
        //this.arrayCampos.set(34,'operacion');

        this.arrayObjetos.set(1,'codigo_origen');
        this.arrayObjetos.set(2,'nombre_origen');
        this.arrayObjetos.set(3,'codigo_campo');
        this.arrayObjetos.set(4,'nombre_campo');
        this.arrayObjetos.set(5,'modificar_origen_id');
        this.arrayObjetos.set(6,'codigo_usuario');
        this.arrayObjetos.set(7,'nombre_usuario');
        this.arrayObjetos.set(8,'modificar_usuario_id');
        this.arrayObjetos.set(30,'btn_agregar');
        this.arrayObjetos.set(31,'btn_cancelar');
        this.arrayObjetos.set(32,'btn_modificar');
        this.arrayObjetos.set(33,'btn_cancelar_modificar');
        //this.arrayObjetos.set(34,'operacion');
        this.establecerPropiedades('1,2,3,4,5,6,7,8','visible','false')
        this.establecerPropiedades('1,2,3,4,5,6,7,8','disable','true')
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionOrigen();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionUsuario();
        }
        
        this.getField('btn_cancelar').setClick(()=>{
            if(this.formulario==='origen'){
                this.btnCancelarFormatos();
            }else{
                this.btnCancelarFormatos();
            }
        });
        this.getField('btn_agregar').setClick(()=>{
            if(this.formulario==='origen'){
                if(this.getField('codigo_origen').valid()&&this.getField('nombre_origen').valid()
                &&this.getField('codigo_campo').valid() &&this.getField('nombre_campo').valid())
                    this.validacionUniqueOrigen();
            }else{
                if(this.getField('codigo_usuario').valid()&&this.getField('nombre_usuario').valid()
                &&this.getField('codigo_campo').valid() &&this.getField('nombre_campo').valid())
                    this.validacionUniqueUsuario();
            }
        });
        this.getField('btn_modificar').setClick(()=>{
            if(this.formulario==='origen'){
                if(this.getField('codigo_origen').valid()&&this.getField('nombre_origen').valid()
                &&this.getField('codigo_campo').valid() &&this.getField('nombre_campo').valid())
                    this.validacionUniqueOrigen();
            }else{
                if(this.getField('codigo_usuario').valid()&&this.getField('nombre_usuario').valid()
                &&this.getField('codigo_campo').valid() &&this.getField('nombre_campo').valid())
                    this.validacionUniqueUsuario();
            }
        });
        this.getField('btn_cancelar_modificar').setClick(()=>{
            this.btnModificar = false
            if(this.formulario==='origen'){
                this.btnCancelarFormatos();
            }else{
                this.btnCancelarFormatos();
            }
        });

    } 

    onSelectionChanged = () =>{
        this.registro_seleccionado=true;
        let filaSeleccionada = this.gridOptionsTableDefault.api.getSelectedRows();
        if(this.formulario==='origen'){    
            this.getField("codigo_origen").setValue(filaSeleccionada[0].codigo_origen);
            this.getField("nombre_origen").setValue(filaSeleccionada[0].nombre_origen);
            this.getField("codigo_campo").setValue(filaSeleccionada[0].codigo_campo);
            this.getField("nombre_campo").setValue(filaSeleccionada[0].nombre_campo);
            this.getField("modificar_origen_id").setValue(filaSeleccionada[0].modificar_origen_id);
        }else{
            this.getField("codigo_usuario").setValue(filaSeleccionada[0].codigo_usuario);
            this.getField("nombre_usuario").setValue(filaSeleccionada[0].nombre_usuario);
            this.getField("codigo_campo").setValue(filaSeleccionada[0].codigo_campo);
            this.getField("nombre_campo").setValue(filaSeleccionada[0].nombre_campo);
            this.getField("modificar_usuario_id").setValue(filaSeleccionada[0].modificar_usuario_id);
        }
    }

    handerClickShowTable() {
        this.registro_seleccionado=false;
        if(this.formulario==='origen'){    
            this.traerTodosOrigen();
        }else{
            this.traerTodosUsuario();
        }
        
    }

    handlerClickChange() {
        this.btnModificar   = true;
        if(this.formulario==='origen'){
            if(this.registro_seleccionado){
                this.registro_seleccionado=false;
                this.establecerPropiedades('1,3','disable','false');
                this.establecerPropiedades('32,33','visible','true');
                this.buttons.get("btn_new").ref.current.setVisible(false);
                this.buttons.get("btn_show").ref.current.setVisible(false);
                this.buttons.get("btn_change").ref.current.setVisible(false);
                this.buttons.get("btn_delete").ref.current.setVisible(false);
                this.getField('tableShowData').toggle(false);
            }else{
                this.mostrarMensajeSeleccionModificar();
            }
        }else{
            if(this.registro_seleccionado){
                this.registro_seleccionado=false;
                this.establecerPropiedades('6,3','disable','false');
                this.establecerPropiedades('32,33','visible','true');
                this.buttons.get("btn_new").ref.current.setVisible(false);
                this.buttons.get("btn_show").ref.current.setVisible(false);
                this.buttons.get("btn_change").ref.current.setVisible(false);
                this.buttons.get("btn_delete").ref.current.setVisible(false);
                this.getField('tableShowData').toggle(false);
            }else{
                this.mostrarMensajeSeleccionModificar();
            }
        }
    }

    handlerClickNew() {
        this.btnModificar   = false;
        this.registro_seleccionado=false;
        if(this.formulario==='origen'){
            this.establecerPropiedades('1,3','disable','false');
            this.establecerPropiedades('30,31','visible','true');
            this.establecerPropiedades('1,2,3,4','value','');
        }else{
            this.establecerPropiedades('3,6','disable','false');
            this.establecerPropiedades('30,31','visible','true');
            this.establecerPropiedades('3,4,6,7','value','');
        }
        this.buttons.get("btn_new").ref.current.setVisible(false);
        this.buttons.get("btn_show").ref.current.setVisible(false);
        this.buttons.get("btn_change").ref.current.setVisible(false);
        this.buttons.get("btn_delete").ref.current.setVisible(false);
        this.getField('tableShowData').toggle(false);
        
    }

    handlerClickDelete() {
        if(this.formulario==='origen'){   
            if(this.registro_seleccionado){
                this.confirmarEliminacionOrigen();
                this.registro_seleccionado=false;
            } else{
                this.mostrarMensajeSeleccionEliminar();
            }
        }else{
            if(this.registro_seleccionado){
                this.confirmarEliminacionPorUsuario();
                this.registro_seleccionado=false;
            } else{
                this.mostrarMensajeSeleccionEliminar();
            }
        }
        
    }

    mostrarMensajeSeleccionModificar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para modificar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeSeleccionEliminar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para eliminar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    btnCancelarFormatos() {
        this.getField('confirmModalCustom').toggle(false);

        this.getField('btn_agregar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.buttons.get("btn_new").ref.current.setDisabled(false);
        this.buttons.get("btn_show").ref.current.setDisabled(false);
        this.buttons.get("btn_delete").ref.current.setDisabled(false);
        if(this.formulario==='origen'){
            this.establecerPropiedades('1,2,3,4', 'disable', 'true');
            this.establecerPropiedades('1,2,3,4','value','');
            this.establecerPropiedades('1,2,3,4','error','false');
            this.traerPrimeroOrigen();
            this.traerTodosOrigen();
        }else{
            this.establecerPropiedades('3,4,5,6', 'disable', 'true');
            this.establecerPropiedades('3,4,5,6','value','');
            this.establecerPropiedades('3,4,5,6','error','false');
            this.traerPrimeroUsuario();
            this.traerTodosUsuario();
        }
        
        
        this.buttons.get("btn_new").ref.current.setVisible(true);
        this.buttons.get("btn_show").ref.current.setVisible(true);
        this.buttons.get("btn_change").ref.current.setVisible(true);
        this.buttons.get("btn_delete").ref.current.setVisible(true);
        this.gridOptionsTableDefault["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTableDefault);
        this.getField('tableShowData').toggle(false);
        this.btnModificar = false;
    }

    btnCancelarFormatosOperacion() {
        this.getField('confirmModalCustom').toggle(false);

        this.getField('btn_agregar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.buttons.get("btn_new").ref.current.setDisabled(false);
        this.buttons.get("btn_show").ref.current.setDisabled(false);
        this.buttons.get("btn_delete").ref.current.setDisabled(false);
        if(this.formulario==='origen'){
            this.establecerPropiedades('1,2,3,4', 'disable', 'true');
            this.establecerPropiedades('1,2,3,4','value','');
            this.establecerPropiedades('1,2,3,4','error','false');
            this.traerPorIdFormatos();
            this.establecerPropiedades('5','value','');
            this.traerTodosOrigen();
        }else{  
            this.establecerPropiedades('3,4,6,7', 'disable', 'true');
            this.establecerPropiedades('3,4,6,7','value','');
            this.establecerPropiedades('3,4,6,7','error','false');
            this.traerPorIdUsuario();
            this.establecerPropiedades('8','value','');
            this.traerTodosUsuario();
        }
        this.buttons.get("btn_new").ref.current.setVisible(true);
        this.buttons.get("btn_show").ref.current.setVisible(true);
        this.buttons.get("btn_change").ref.current.setVisible(true);
        this.buttons.get("btn_delete").ref.current.setVisible(true);
        this.gridOptionsTableDefault["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTableDefault);
        this.getField('tableShowData').toggle(false);
        
        this.btnModificar = false;
    }


    opcionOrigen(){
        this.formulario='origen';
        //this.getField('operacion').setValue('Formatos');
        this.establecerPropiedades('1,2,3,4','visible','true');
        this.traerPrimeroOrigen();
    }

    opcionUsuario(){
        this.formulario='usuario';
        //this.getField('operacion').setValue('Conceptos');
        this.establecerPropiedades('3,4,6,7','visible','true');
        this.traerPrimeroUsuario();
        
    }

    traerTodosOrigen()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '1_origen', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successtraerTodosOrigen,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successtraerTodosOrigen(data)
    {
        if (data.estado_p === 200)
        {
            let configCell = new Map();
            configCell.set('codigo_usuario', {cellRenderer:function(props){return props.data.codigo_usuario}, hide: true, field: 'codigo_usuario' });
            configCell.set('nombre_usuario', {cellRenderer:function(props){return props.data.nombre_usuario}, hide: true, field: 'nombre_usuario' });
            this.gridOptionsTableDefault['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            this.gridOptionsTableDefault['onSelectionChanged'] =this.onSelectionChanged;
            this.getField('tableShowData').initData(this.gridOptionsTableDefault,configCell);
            

        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerPrimeroOrigen()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '3_origen', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerPrimeroOrigen,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerPrimeroOrigen(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_origen').setValue(data.data[0].codigo_origen);
            this.getField('nombre_origen').setValue(data.data[0].nombre_origen);
            this.getField('codigo_campo').setValue(data.data[0].codigo_campo);
            this.getField('nombre_campo').setValue(data.data[0].nombre_campo);
            this.getField('modificar_origen_id').setValue(data.data[0].modificar_origen_id);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validacionUniqueOrigen()
    {   
        this.getField('btn_agregar').setDisabled(true);
        this.getField('btn_modificar').setDisabled(true);
        let datos={ datos: {
            codigo_origen:this.getField('codigo_origen').getValue(),
            codigo_campo:this.getField('codigo_campo').getValue(),
        }};
        let operacion='20_origen';
        if(this.btnModificar){
            datos.datos['modificar_origen_id']=this.getField('modificar_origen_id').getValue();
            operacion='20_origen_modificar';
        } 
        this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarUniqueOrigen,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }

    successValidarUniqueOrigen(data)
    {
        if (data.estado_p === 200)
        {
            this.alertGeneral.toggle(true, 'Código campo ya se encuentra asignado al código origen.', 'error');
            this.getField('btn_agregar').setDisabled(false);
            this.getField('btn_modificar').setDisabled(false);
        }
        else 
        {
           if(data.estado_p===404) {
                if(this.btnModificar){
                    this.actualiarOrigen();
                }else{
                    this.crearOrigen();
                }
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerPorIdFormatos()
    {
       let datos={ datos: {
            modificar_origen_id:this.getField('modificar_origen_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '2_origen', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerPorIdOrigen,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerPorIdOrigen(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_origen').setValue(data.data[0].codigo_origen);
            this.getField('nombre_origen').setValue(data.data[0].nombre_origen);
            this.getField('codigo_campo').setValue(data.data[0].codigo_campo);
            this.getField('nombre_campo').setValue(data.data[0].nombre_campo);
            this.getField('modificar_origen_id').setValue(data.data[0].modificar_origen_id);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    confirmarEliminacionOrigen(){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(this.eliminarPorIdOrigen);
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');       
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminarPorIdOrigen()
    {
       let datos={ datos: {
            modificar_origen_id:this.getField('modificar_origen_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '7_origen', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminarPorIdOrigen,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successEliminarPorIdOrigen(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('confirmModalCustom').toggle(false);
            this.traerPrimeroOrigen();
            this.traerTodosOrigen();
        }
        else 
        {
           if(data.estado_p===404) {
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    crearOrigen(){
        let datos = {  datos: { 
            codigo_origen:this.getField('codigo_origen').getValue(),
            codigo_campo:this.getField('codigo_campo').getValue()
            }
        }
        this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '5_origen', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successcrear,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successcrear(data)
    {
        this.getField('btn_agregar').setDisabled(false);
        this.getField('btn_modificar').setDisabled(false);
        if (data.estado_p === 200 )
        {
            this.getField('modificar_origen_id').setValue(data.data[0].modificar_origen_id);
            this.btnCancelarFormatosOperacion();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualiarOrigen(){
        if(this.getField('modificar_origen_id').getValue()!=='' && this.getField('modificar_origen_id').getValue()!==' '){
            let datos = {  datos: { 
                    codigo_origen:this.getField('codigo_origen').getValue(),
                    codigo_campo:this.getField('codigo_campo').getValue(),
                    modificar_origen_id:this.getField('modificar_origen_id').getValue()
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '6_origen', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successActualizarOrigen,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else{
            this.getField('btn_agregar').setDisabled(false);
            this.getField('btn_modificar').setDisabled(false);
        }
    }

    successActualizarOrigen(data){
        this.getField('btn_agregar').setDisabled(false);
        this.getField('btn_modificar').setDisabled(false);
        if (data.estado_p === 200 )
        {
            this.getField('modificar_origen_id').setValue(data.data[0].modificar_origen_id);
            this.btnCancelarFormatosOperacion();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    //metodos conceptos

    traerTodosUsuario()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '1_usuario', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTodosUsuario,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerTodosUsuario(data)
    {
        if (data.estado_p === 200)
        {
            //armar tabla
            let configCell = new Map();
            configCell.set('codigo_origen', {cellRenderer:function(props){return props.data.codigo_origen}, hide: true, field: 'codigo_origen' });
            configCell.set('nombre_origen', {cellRenderer:function(props){return props.data.nombre_origen}, hide: true, field: 'nombre_origen' });
            configCell.set('archivo_fisico', {cellRenderer:function(props){return props.data.archivo_fisico}, hide: true, field: 'archivo_fisico' });
            
            this.gridOptionsTableDefault['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            this.gridOptionsTableDefault['onSelectionChanged'] =this.onSelectionChanged;
            this.getField('tableShowData').initData(this.gridOptionsTableDefault,configCell);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerPrimeroUsuario()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '3_usuario', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerPrimeroUsuario,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerPrimeroUsuario(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_usuario').setValue(data.data[0].codigo_usuario);
            this.getField('nombre_usuario').setValue(data.data[0].nombre_usuario);
            this.getField('codigo_campo').setValue(data.data[0].codigo_campo);
            this.getField('nombre_campo').setValue(data.data[0].codigo_usuario);
            this.getField('modificar_usuario_id').setValue(data.data[0].modificar_usuario_id);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validacionUniqueUsuario()
    {
        this.getField('btn_agregar').setDisabled(true);
        this.getField('btn_modificar').setDisabled(true);
       let datos={ datos: {
            codigo_usuario:this.getField('codigo_usuario').getValue(),
            codigo_campo:this.getField('codigo_campo').getValue()
       }};
       let operacion='20_usuario';
       if(this.btnModificar){
            datos.datos['modificar_usuario_id']=this.getField('modificar_usuario_id').getValue();
            operacion='20_usuario_modificar';
       } 
        this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarUniqueUsuario,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successValidarUniqueUsuario(data)
    {
        if (data.estado_p === 200)
        {
            this.alertGeneral.toggle(true, 'Código campo ya se encuentra asignado al código usuario.', 'error');
            this.getField('btn_agregar').setDisabled(false);
            this.getField('btn_modificar').setDisabled(false);
        }
        else 
        {
           if(data.estado_p===404) {
                if(this.btnModificar){
                    this.actualiarUsuarioCampo();
                }else{
                    this.crearUsuarioCampo();
                }
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerPorIdUsuario()
    {
       let datos={ datos: {
            modificar_usuario_id:this.getField('modificar_usuario_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '2_usuario', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerPorIdUsuario,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerPorIdUsuario(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_usuario').setValue(data.data[0].codigo_usuario);
            this.getField('nombre_usuario').setValue(data.data[0].nombre_usuario);
            this.getField('codigo_campo').setValue(data.data[0].codigo_campo);
            this.getField('nombre_campo').setValue(data.data[0].codigo_usuario);
            this.getField('modificar_usuario_id').setValue(data.data[0].modificar_usuario_id);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    confirmarEliminacionPorUsuario(){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(this.eliminarPorIdUsuario);
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');       
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminarPorIdUsuario()
    {
       let datos={ datos: {
            modificar_usuario_id:this.getField('modificar_usuario_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '7_usuario', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminarPorIdUsuario,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successEliminarPorIdUsuario(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('confirmModalCustom').toggle(false);
            this.traerPrimeroUsuario();
            this.traerTodosUsuario();
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
                this.getField('confirmModalCustom').toggle(false);

           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    crearUsuarioCampo(){
        let datos = {  datos: { 
            codigo_usuario:this.getField('codigo_usuario').getValue(),
            codigo_campo:this.getField('codigo_campo').getValue()
            }
        }
        this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '5_usuario', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successcrearUsuarioCampo,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successcrearUsuarioCampo(data)
    {
        this.getField('btn_agregar').setDisabled(false);
        this.getField('btn_modificar').setDisabled(false);
        if (data.estado_p === 200 )
        {
            this.getField('modificar_usuario_id').setValue(data.data[0].modificar_usuario_id);
            this.btnCancelarFormatosOperacion();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualiarUsuarioCampo(){
        if(this.getField('modificar_usuario_id').getValue()!=='' && this.getField('modificar_usuario_id').getValue()!==' '){
            let datos = {  datos: { 
                    codigo_usuario:this.getField('codigo_usuario').getValue(),
                    codigo_campo:this.getField('codigo_campo').getValue(),
                    modificar_usuario_id:this.getField('modificar_usuario_id').getValue()
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-autorizacionmoddocumentos', operacion: '6_usuario', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successActualizarUsuarioCampo,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else{
            this.getField('btn_agregar').setDisabled(false);
            this.getField('btn_modificar').setDisabled(false);
        }
    }

    successActualizarUsuarioCampo(data){
        this.getField('btn_agregar').setDisabled(false);
        this.getField('btn_modificar').setDisabled(false);
        if (data.estado_p === 200 )
        {
            this.getField('modificar_usuario_id').setValue(data.data[0].modificar_usuario_id);
            this.btnCancelarFormatosOperacion();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }



    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }

}
FormJaivana.addController("cont-autorizacionmoddocumentos",CustomAutorizacionModificacionDeDocumento);
export default CustomAutorizacionModificacionDeDocumento