import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @description: CustomAutorizarOrdenCompra
 * @author: Santiago Hernández N.
 * @version: jdesk_1.01.0002
 **/
class CustomAutorizarOrdenCompra extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                        = this.initForm.bind(this);
        this.setearBuscador                  = this.setearBuscador.bind(this);
        this.generarAutorizacion             = this.generarAutorizacion.bind(this);
        this.sucessGrabarAutorizacion        = this.sucessGrabarAutorizacion.bind(this);
        this.limpiar                         = this.limpiar.bind(this);
        this.limpiarAutorizacion             = this.limpiarAutorizacion.bind(this);

    }

    initForm() {
        console.log('Formulario CustomAutorizarOrdenCompra,  @version: jdesk_1.01.0002, @author: Santiago Hernández N.');
        this.setearBuscador();
        this.getField('generar_autorizacion').setClick(this.generarAutorizacion);
        this.getField('numero_documento').setOnChange(
            (props) => { 
                this.limpiarAutorizacion()
            });
    }


    setearBuscador(){
        this.getField('codigo_sucursal').setValue('1101');
    }

    generarAutorizacion(){

        let errores=0;

        if(!this.getField('nombre_sucursal').valid())
          errores++;
        if(!this.getField('numero_documento').valid())
          errores++;    
        if(!this.getField('fecha_documento').valid())
          errores++;
        if(!this.getField('detalle').valid())
          errores++;
        
        if(errores===0){
            let datos={ datos: {
                codigo_sucursal: this.getField('codigo_sucursal').getValue(),
                numero_documento: this.getField('numero_documento').getValue(),
                fecha_documento: this.getField('fecha_documento').getValue(),
                detalle: this.getField('detalle').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-autorizarordencompra', operacion: 'grabarautorizacion', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessGrabarAutorizacion,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Por favor, ingrese todos los datos.', 'error');
        }          
    }

    sucessGrabarAutorizacion(data){
        if (data.estado_p === 200){
            this.limpiar();
            this.getField('numero_autorizacion').setValue(data.data[0].aleatorio);
        } else {
            if(data.estado_p===404) {
                  this.alertGeneral.toggle(true, 'No hay datos para autorizar', 'error');
            } else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    limpiarAutorizacion(){
        this.getField('numero_autorizacion').setValue("");
    }

    limpiar(){
        this.getField('detalle').setValue('');
        this.getField('numero_documento').setValue('');
    }
}

FormJaivana.addController('comp-autorizarordencompra',CustomAutorizarOrdenCompra);
export default CustomAutorizarOrdenCompra;
