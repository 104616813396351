import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomCuentas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.validarmanejamovimiento    = this.validarmanejamovimiento.bind(this);
        this.validarnivel               = this.validarnivel.bind(this);
        this.successNivel               = this.successNivel.bind(this);
        this.datoscuentaPadre           = this.datoscuentaPadre.bind(this);
        this.successdatosCuentaPadre    = this.successdatosCuentaPadre.bind(this);
        this.registroCuentasPadre       = {};
        this.caracteres                 = this.caracteres.bind(this);
        this.successdatosCaracteres     = this.successdatosCaracteres.bind(this);
        this.Caracteres                 = 0;
        this.cantidaddigitos            = this.cantidaddigitos.bind(this);
        this.traerclase                 = this.traerclase.bind(this);
        this.successdatosClases         = this.successdatosClases.bind(this);
        this.validarcampos              = this.validarcampos.bind(this);
        this.opcionAgregar              = this.opcionAgregar.bind(this);
        this.opcionModificar            = this.opcionModificar.bind(this);
        this.cuenta_clase               = 0;
        this.cuenta_clase_nombre        = "";
        this.nivel_id                   = 0;
        this.maneja_tercero             = this.maneja_tercero.bind(this);
        this.cuenta_iva                 = this.cuenta_iva.bind(this);
        this.cuenta_retefuente          = this.cuenta_retefuente.bind(this);
        this.cuenta_reteiva             = this.cuenta_reteiva.bind(this);
        this.cuenta_reteica             = this.cuenta_reteica.bind(this);
        this.cuenta_caja                = this.cuenta_caja.bind(this);
        this.cuenta_banco               = this.cuenta_banco.bind(this);
        this.validarcampos1             = this.validarcampos1.bind(this);
        this.seguir                     = false;
        this.validarcuenta              = this.validarcuenta.bind(this);
        this.successdatosCuenta         = this.successdatosCuenta.bind(this);
        this.service_estadosino         = this.service_estadosino.bind(this);
        this.successTraerEstado         = this.successTraerEstado.bind(this);
        this.validarGrabar              = this.validarGrabar.bind(this);
        this.successcrear               = this.successcrear.bind(this);
        this.codigo_nivel               = 0;
        this.limpiar                    = this.limpiar.bind(this);
        this.eliminarvalid              = this.eliminarvalid.bind(this);
        this.tipo_cartera               ="";
        
        ///Modificar cuenta
        this.seleccionar                = this.seleccionar.bind(this);
        this.llenarencabezado           = this.llenarencabezado.bind(this);
        this.llenarencabezado1          = this.llenarencabezado1.bind(this);
        this.validarsubCuenta           = this.validarsubCuenta.bind(this);
        this.limpiar1                   = this.limpiar1.bind(this);
        this.validartipo                = this.validartipo.bind(this);
        this.cambiarTipo                = this.cambiarTipo.bind(this);
        this.tipo                       = this.tipo.bind(this);
        this.actualizarcuenta           = this.actualizarcuenta.bind(this);
        this.successactualizarcuenta    = this.successactualizarcuenta.bind(this);
    }

    initForm(){
        console.log('Formulario CustomCuentas,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez')
      

            this.arrayCampos.set(1,'id');
            this.arrayCampos.set(6,'contenedorDatosCuenta2');
            this.arrayCampos.set(8,'operacion');
            this.arrayCampos.set(10,'codigo_cuenta1');
            this.arrayCampos.set(11,'nombre');
            this.arrayCampos.set(12,'maneja_movimiento');
            this.arrayCampos.set(13,'codigo_nivel');
            //this.arrayCampos.set(14,'continuar1');
            this.arrayCampos.set(15,'contenedorDatosCuenta3');
            this.arrayCampos.set(16,'label4');
            this.arrayCampos.set(17,'numeros');
            this.arrayCampos.set(171,'numerossub');
            this.arrayCampos.set(18,'label5');
            this.arrayCampos.set(20,'maneja_movimiento2');
            this.arrayCampos.set(201,'maneja_movimiento2sub');
            this.arrayCampos.set(22,'clase1');
            this.arrayCampos.set(221,'clase1sub');
            this.arrayCampos.set(23,'continuar2');
            this.arrayCampos.set(231,'continuar2sub');
            this.arrayCampos.set(24,'contenedorDatosCuenta4');
            this.arrayCampos.set(26,'codigo_cuenta');
            this.arrayCampos.set(28,'manejamovimiento3');
            this.arrayCampos.set(30,'maneja_tercero');
            this.arrayCampos.set(32,'cuenta_iva');
            this.arrayCampos.set(34,'cuenta_retefuente');
            this.arrayCampos.set(36,'cuenta_reteiva');
            this.arrayCampos.set(38,'cuenta_reteica');
            this.arrayCampos.set(40,'maneja_base');
            this.arrayCampos.set(42,'cuenta_caja');
            this.arrayCampos.set(44,'cuenta_banco');
            this.arrayCampos.set(46,'nif');
            this.arrayCampos.set(47,'continuar3');
            this.arrayCampos.set(48,'Limpiar');
            this.arrayCampos.set(49,'contenedorDatosCuenta5');
            this.arrayCampos.set(51,'maneja_documento_fuente');
            this.arrayCampos.set(54,'continuar4');
            this.arrayCampos.set(53,'tipo_fuente');
            this.arrayCampos.set(55,'contenedorDatosCuenta6');
            this.arrayCampos.set(57,'estadoactivo_id');
            this.arrayCampos.set(59,'detallado');
            this.arrayCampos.set(60,'terminar');
            this.arrayCampos.set(61,'nombre1');
            this.arrayCampos.set(62,'estado');
            this.arrayCampos.set(63,'codigo_cuenta_texto');
            this.arrayCampos.set(71,'contenedorDatos1');
            this.arrayCampos.set(72,'codigo_cuenta2');
            this.arrayCampos.set(73,'nombre3');
            this.arrayCampos.set(74,'seleccionar');
            this.arrayCampos.set(76,'cancelar8');

     
            this.arrayObjetos.set(1,'id');
            this.arrayObjetos.set(6,'contenedorDatosCuenta2');
            this.arrayObjetos.set(8,'operacion');
            this.arrayObjetos.set(10,'codigo_cuenta1');
            this.arrayObjetos.set(11,'nombre');
            this.arrayObjetos.set(12,'maneja_movimiento');
            this.arrayObjetos.set(13,'codigo_nivel');
            //this.arrayObjetos.set(14,'continuar1');
            this.arrayObjetos.set(15,'contenedorDatosCuenta3');
            this.arrayObjetos.set(16,'label4');
            this.arrayObjetos.set(17,'numeros');
            this.arrayObjetos.set(171,'numerossub');
            this.arrayObjetos.set(18,'label5');
            this.arrayObjetos.set(20,'maneja_movimiento2');
            this.arrayObjetos.set(201,'maneja_movimiento2sub');
            this.arrayObjetos.set(22,'clase1');
            this.arrayObjetos.set(221,'clase1sub');
            this.arrayObjetos.set(23,'continuar2');
            this.arrayObjetos.set(231,'continuar2sub');
            this.arrayObjetos.set(24,'contenedorDatosCuenta4');
            this.arrayObjetos.set(26,'codigo_cuenta');
            this.arrayObjetos.set(28,'manejamovimiento3');
            this.arrayObjetos.set(30,'maneja_tercero');
            this.arrayObjetos.set(32,'cuenta_iva');
            this.arrayObjetos.set(34,'cuenta_retefuente');
            this.arrayObjetos.set(36,'cuenta_reteiva');
            this.arrayObjetos.set(38,'cuenta_reteica');
            this.arrayObjetos.set(40,'maneja_base');
            this.arrayObjetos.set(42,'cuenta_caja');
            this.arrayObjetos.set(44,'cuenta_banco');
            this.arrayObjetos.set(46,'nif');
            this.arrayObjetos.set(47,'continuar3');
            this.arrayObjetos.set(48,'limpiar');
            this.arrayObjetos.set(49,'contenedorDatosCuenta5');
            this.arrayObjetos.set(51,'maneja_documento_fuente');
            this.arrayObjetos.set(53,'tipo_fuente');
            this.arrayObjetos.set(54,'continuar4');
            this.arrayObjetos.set(57,'estadoactivo_id');
            this.arrayObjetos.set(55,'contenedorDatosCuenta6');
            this.arrayObjetos.set(59,'detallado');
            this.arrayObjetos.set(60,'terminar');
            this.arrayObjetos.set(61,'nombre1');
            this.arrayObjetos.set(62,'estado');
            this.arrayObjetos.set(63,'codigo_cuenta_texto');
            this.arrayObjetos.set(71,'contenedorDatos1');
            this.arrayObjetos.set(72,'codigo_cuenta2');
            this.arrayObjetos.set(73,'nombre3');
            this.arrayObjetos.set(74,'seleccionar');
            this.arrayObjetos.set(76,'cancelar8');

            this.establecerPropiedades('6,12,13,15,24,49,55','visible','false')
            if(this.props.data[0].opcion_sub_seccion === 1){
                this.opcionAgregar();
             }else if(this.props.data[0].opcion_sub_seccion === 2){
                this.opcionModificar();
             }      
    } 

    opcionAgregar(){
        this.service_estadosino();
        this.establecerPropiedades('6,15,24,49,55','visible','true');
        this.getField('operacion').setValue('AGREGAR');
        this.establecerPropiedades('8,11,12,13,171,201,221,231,26,28,30,32,34,36,38,40,42,44,46,47,48,51,53,54,57,59,60,61','disable','true')
        this.getField('codigo_cuenta1').setOnChange(()=>{this.getField('codigo_cuenta1').setError(false,'');this.getField('nombre').setError(false,'');
        this.establecerPropiedades('171,201,221,231,26,28,30,32,34,36,38,40,42,44,46,51,53,57,59,60,61','disable','true');
        this.establecerPropiedades('171,201,221,231,26,28,30,32,34,36,38,40,42,44,46,48,51,53,54,57,59,61','value','');
        //this.establecerPropiedades('14','disable','false');
        this.establecerPropiedades('231','disable','true');
        this.registroCuentasPadre = {};
        this.eliminarvalid();
        });
        
        this.getField('codigo_nivel').setOnChange(this.validarnivel);
        /*this.getField('nombre').setOnChange(()=>{
            if(this.getField('nombre').getValue() !== "")
                this.caracteres();
        });*/
        this.getField('numerossub').setKeyUp(this.cantidaddigitos);
        this.getField('maneja_movimiento2sub').setOnChange(()=>{this.establecerPropiedades('26,28,30,32,34,36,38,40,42,44,46,47,48,51,53,54,57,59,60,61','disable','true');
        this.establecerPropiedades('26,28,30,32,34,36,38,40,42,44,46,51,53,57,59,61','value',''); this.establecerPropiedades('231','disable','false');
        this.eliminarvalid();
        });
        this.getField('continuar2sub').setClick(()=>{this.validarcuenta()});
        this.getField('limpiar').setClick(()=>{this.establecerPropiedades('30,32,34,36,38,40,42,44,46,51,53,57,59','value','');
        this.establecerPropiedades('30,46,47,48','disable','false');
        this.establecerPropiedades('32,34,36,38,42,44,51,53,54,57,59,60','disable','true')
        this.eliminarvalid();
        });
        this.getField('maneja_tercero').setOnChange(()=>{this.maneja_tercero()});
        this.getField('nombre1').setOnBlur(()=>{this.llenarencabezado()});
        this.getField('cuenta_iva').setOnBlur(()=>{this.cuenta_iva()});
        this.getField('cuenta_retefuente').setOnBlur(()=>{this.cuenta_retefuente()});
        this.getField('cuenta_reteiva').setOnBlur(()=>{this.cuenta_reteiva()});
        this.getField('cuenta_reteica').setOnBlur(()=>{this.cuenta_reteica()});
        this.getField('cuenta_caja').setOnBlur(()=>{this.cuenta_caja()});
        this.getField('cuenta_banco').setOnBlur(()=>{this.cuenta_banco()});
        this.getField('nif').setOnChange(()=>{this.establecerPropiedades('51,53,54,57,59,60','disable','true'); this.establecerPropiedades('51,53,57,59','value','')});
        this.getField('continuar3').setClick(()=>{this.validarcampos1()});
        this.getField('maneja_documento_fuente').setOnChange(()=>{
                if(this.getField('maneja_documento_fuente').getValue() === 'S' && this.getField('clase1sub').getValue() === 1){this.getField('tipo_fuente').setValue('CUENTAS POR COBRAR');
                    this.tipo_cartera="C";
                    this.establecerPropiedades('57,59,60','disable','true'); this.establecerPropiedades('57,59','value','')}
                else if (this.getField('maneja_documento_fuente').getValue() === 'S' && this.getField('clase1sub').getValue() === 2){this.getField('tipo_fuente').setValue('CUENTAS POR PAGAR');
                    this.tipo_cartera="P";
                    this.establecerPropiedades('57,59,60','disable','true'); this.establecerPropiedades('57,59','value','')}
                else if (this.getField('maneja_documento_fuente').getValue() === 'N'){ this.getField('tipo_fuente').setValue('NINGUNO');
                    this.tipo_cartera="N";
                    this.establecerPropiedades('57,59,60','disable','true'); this.establecerPropiedades('57,59','value','');}
                else if (this.getField('maneja_documento_fuente').getValue() === '' ){
                    this.establecerPropiedades('57,59,60','disable','true'); this.establecerPropiedades('57,59','value','');this.getField('tipo_fuente').setValue('')
                }else if (this.getField('maneja_documento_fuente').getValue() === 'S' && this.getField('clase1sub').getValue() !== 1 && this.getField('clase1sub').getValue() !== 2){
                    this.getField('tipo_fuente').setValue('NINGUNO');
                    this.tipo_cartera="N";
                }
        });
        this.getField('continuar4').setClick(()=>{if(this.getField('maneja_documento_fuente').valid()){this.establecerPropiedades('57,59,60','disable','false');
        this.establecerPropiedades('57','value','1');this.establecerPropiedades('62','value','A'); this.getField('TabJaivana_9').setNextTab();}});
        this.getField('terminar').setClick(()=>{this.validarGrabar()});
        this.getField('TabJaivana_9').setClick(this.limpiar);
    }

    service_estadosino()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: '1', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerEstado,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerEstado(data)
    {
        if (data.estado_p === 200)
        {
            if(data.model && data.model.length>0){
             let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.estadossino_siglas;
             dataOp['text'] = item.estadossino_siglas;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('maneja_movimiento2').setOptions(opciones);
             this.getField('maneja_movimiento2sub').setOptions(opciones);
             this.getField('maneja_tercero').setOptions(opciones);
             this.getField('cuenta_iva').setOptions(opciones);
             this.getField('cuenta_retefuente').setOptions(opciones);
             this.getField('cuenta_reteiva').setOptions(opciones);
             this.getField('cuenta_reteica').setOptions(opciones);
             this.getField('maneja_base').setOptions(opciones);
             this.getField('cuenta_caja').setOptions(opciones);
             this.getField('cuenta_banco').setOptions(opciones);
             this.getField('nif').setOptions(opciones);
             this.getField('maneja_documento_fuente').setOptions(opciones);
             this.getField('detallado').setOptions(opciones);
             }
        }
        else 
        {
           if(data.estado_p===404) {
                // console.log('no hay datos...');
           }
           else {
            //   console.log(data);
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
            //   console.log('Errores:'+respuesta);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validarnivel(){
        if((this.getField('codigo_nivel').getValue() !== '')){
            let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'maes-niveles', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successNivel,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }   
    }

    successNivel(data){
        if (data.estado_p === 200 )
        {
            if(data.data[0].max > this.getField('codigo_nivel').getValue()){
                this.validarmanejamovimiento();
            }else{
                this.alertGeneral.toggle(true, 'La cuenta no es permitida', 'error');
            }
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    validarmanejamovimiento()
    {
      if(this.getField('maneja_movimiento').getValue() === 'S'){
        this.alertGeneral.toggle(true, 'Cuenta padre equivocada, maneja movimiento', 'error');
        this.establecerPropiedades('171,201,221,231','disable','true')
      }else{
        //this.getField('continuar1').setDisabled(false);
        this.datoscuentaPadre();
      }
    }

    datoscuentaPadre()
    {
        let datos={ datos: { value: this.getField('codigo_cuenta1').getValue() }};
        this.generalFormatPmv = { tipo_servicio: 'maes-cuentas', operacion: '42', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successdatosCuentaPadre,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successdatosCuentaPadre(data){
        if (data.estado_p === 200 )
        {
            this.registroCuentasPadre = {
                id:data.data[0].id,
                codigo_cuenta:data.data[0].codigo_cuenta,
                codigo_cuenta_texto:data.data[0].codigo_cuenta_texto,
                nombre:data.data[0].nombre,
                maneja_tercero:data.data[0].maneja_tercero,
                maneja_movimiento:data.data[0].maneja_movimiento,
                maneja_documento_fuente:data.data[0].maneja_documento_fuente,
                codigo_nivel:data.data[0].codigo_nivel,
                cuenta_de_banco:data.data[0].cuenta_banco,
                cuenta_de_retefuente:data.data[0].cuenta_retefuente,
                cuenta_de_reteiva:data.data[0].cuenta_reteiva,
                cuenta_de_reteica:data.data[0].cuenta_reteica,
                cuenta_de_iva:data.data[0].cuenta_iva,
                cuenta_de_caja:data.data[0].cuenta_caja,
                tipo_fuente:data.data[0].tipo_fuente,
                maneja_base:data.data[0].maneja_base,
                maneja_clase:data.data[0].maneja_clase,
                nif:data.data[0].nif
            }
            this.caracteres();
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    caracteres()
    {
        if(this.registroCuentasPadre.codigo_nivel !== undefined){
            this.codigo_nivel = (parseInt(this.registroCuentasPadre.codigo_nivel)+1);
            let datos={ datos: { codigo_nivel: this.codigo_nivel}};
            this.generalFormatPmv = { tipo_servicio: 'maes-niveles', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successdatosCaracteres,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successdatosCaracteres(data)
    {
        if (data.estado_p === 200 )
        {
            this.nivel_id=data.data[0].id
            this.Caracteres= data.data[0].caracteres - this.registroCuentasPadre.codigo_cuenta_texto.length;
            if(this.Caracteres === 0){
                this.alertGeneral.toggle(true, 'Cuenta mal configurada', 'error');
                this.establecerPropiedades('171,201,231','disable','true');
            }else if(this.Caracteres < 0){
                this.alertGeneral.toggle(true, 'Cuenta mal configurada', 'error');
                this.establecerPropiedades('171,201,231','disable','true');
            }else{
                //this.getField('TabJaivana_9').setNextTab();
                //this.establecerPropiedades('14','disable','true');
                this.establecerPropiedades('171,201,231','disable','false');
                this.traerclase();
            }
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    cantidaddigitos(){
        this.getField('numerossub').setError(false, '')
        if(this.getField('numerossub').getValue() !== ''){
            if((this.getField('numerossub').getValue()).toString().length > this.Caracteres){
               this.getField('numerossub').setError(true,'Solo se permite digitar '+this.Caracteres+' números');
               this.establecerPropiedades('23','disable','true');
            }else if((this.getField('numerossub').getValue()).toString().length < this.Caracteres){
                this.getField('numerossub').setError(true,'Se debe digitar '+this.Caracteres+' números');
                this.establecerPropiedades('23','disable','true');
            }else if ((this.getField('numerossub').getValue()).toString().length === this.Caracteres){
                this.establecerPropiedades('23','disable','false');
            }

            this.establecerPropiedades('26,28,30,32,34,36,38,40,42,44,46,47,48,51,53,54,57,59,60,61','disable','true');
            this.establecerPropiedades('26,28,30,32,34,36,38,40,42,44,46,51,53,57,59,61','value','')
            this.eliminarvalid();
        }else{
            this.getField('numerossub').setError(false, '')
        }
    }

    traerclase(){
        if(this.getField('nombre').getValue() !== ''){
            let datos={ datos: { clase: parseInt(this.getField('codigo_cuenta1').getValue().charAt(0))}};
            this.generalFormatPmv = { tipo_servicio: 'maes-cuentasclases', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successdatosClases,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else if(this.getField('nombre3').getValue() !== ''){
            let datos={ datos: { clase: parseInt(this.getField('codigo_cuenta2').getValue().charAt(0))}};
            this.generalFormatPmv = { tipo_servicio: 'maes-cuentasclases', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successdatosClases,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successdatosClases(data)
    {
        if (data.estado_p === 200 )
        {
            this.establecerPropiedades('171,201,231','disable','false'); 
            this.establecerPropiedades('17,20,23','disable','false'); 
            this.getField('clase1sub').setValue(data.data[0].id);
            this.getField('clase1').setValue(data.data[0].id);
            this.cuenta_clase = data.data[0].clase;
            this.cuenta_clase_nombre = data.data[0].descripcion;
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    validarcampos()
    {
        this.getField('manejamovimiento3').setValue(this.getField('maneja_movimiento2sub').getValue());
        //this.getField('manejamovimiento3').setValue(this.getField('maneja_movimiento2sub').getValue());
        this.establecerPropiedades('46,61','disable','false');
        if(this.getField('manejamovimiento3').getValue() === 'N'){
            this.establecerPropiedades('30,32,34,36,38,40,42,44,46','value','N');
            this.establecerPropiedades('30,32,34,36,38,42,44,46,48','disable','true');
            this.establecerPropiedades('47','disable','false')
        }else{
            this.establecerPropiedades('30,32,34,36,38,40,42,44,46','value','');
            this.establecerPropiedades('30,47,48,61','disable','false');
            this.establecerPropiedades('40','disable','true')
        }
    }

    validarcuenta()
    {
        if((this.getField('numerossub').getValue()).toString().length > this.Caracteres){
            this.getField('numerossub').setError(true,'Solo se permite digitar '+this.Caracteres+' números');
            this.establecerPropiedades('231','disable','true');
         }else if((this.getField('numerossub').getValue()).toString().length < this.Caracteres){
             this.getField('numerossub').setError(true,'Se debe digitar '+this.Caracteres+' números');
             this.establecerPropiedades('231','disable','true');
         }else if ((this.getField('numerossub').getValue()).toString().length === this.Caracteres){
             this.establecerPropiedades('231','disable','false');
             if(this.getField('numerossub').valid() && this.getField('maneja_movimiento2sub').valid()){
                let cuenta_final= this.getField('codigo_cuenta1').getValue() + this.getField('numerossub').getValue();
                let datos={ datos: { value: cuenta_final }};
                this.generalFormatPmv = { tipo_servicio: 'maes-cuentas', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successdatosCuenta,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
            }else{
                this.establecerPropiedades('26,28,30,32,34,36,38,40,42,44,46,47,48,51,53,54,57,59,60,61','disable','true');
                this.establecerPropiedades('26,28,30,32,34,36,38,40,42,44,46,51,53,57,59,61','value','')
            }
         } 
    }

    successdatosCuenta(data)
    {
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'La cuenta ya existe, cambie los últimos digitos de la cuenta', 'error');
            this.getField('codigo_cuenta').setValue('');
            this.establecerPropiedades('26,28,30,32,34,36,38,40,42,44,46,47,48,51,53,54,57,59,60,61','disable','true')
            this.establecerPropiedades('26,28,30,32,34,36,38,40,42,44,46,51,53,57,59,61','value','')
            this.eliminarvalid();
        }
        else 
        {
           if(data.estado_p===404) {
                this.llenarencabezado();
                this.getField('TabJaivana_9').setTabNumber(3);
                this.getField('codigo_cuenta').setValue(this.getField('codigo_cuenta1').getValue() + this.getField('numerossub').getValue());
                this.establecerPropiedades('231','disable','true')
                this.alertGeneral.toggle(true, 'Podemos continuar...', 'success');
                this.validarcampos();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    llenarencabezado()
    {
        let datosFicha = [];
        let data2 = 
                {
                    "cuenta": this.getField('codigo_cuenta1').getValue() + this.getField('numeros').getValue(),
                    "nombre": this.getField('nombre1').getValue(),
                };
        datosFicha.push(data2);
        this.getField('lineas').setItemsFichas(datosFicha);
    }

    maneja_tercero()
    {
        if(this.getField('maneja_tercero').getValue() === 'N'){
            this.establecerPropiedades('32,34,36,38,40,42,44,46','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44,46','disable','true');
        }else if(this.getField('maneja_tercero').getValue() === 'S'){
            this.establecerPropiedades('30,32,34,36,38,42,44,46','disable','false');
        }
    }

    cuenta_iva()
    {
        if(this.getField('cuenta_iva').getValue() === 'N'){
            this.establecerPropiedades('34,36,38,40,42,44','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }else if(this.getField('cuenta_iva').getValue() === 'S'){
            this.establecerPropiedades('34,36,38,42,44','value','N');
            this.establecerPropiedades('40','value','S');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }
    }

    cuenta_retefuente()
    {
        if(this.getField('cuenta_retefuente').getValue() === 'N'){
            this.establecerPropiedades('32,36,38,40,42,44','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }else if(this.getField('cuenta_retefuente').getValue() === 'S'){
            this.establecerPropiedades('32,36,38,42,44','value','N');
            this.establecerPropiedades('40','value','S');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }
    }

    cuenta_reteiva()
    {
        if(this.getField('cuenta_reteiva').getValue() === 'N'){
            this.establecerPropiedades('32,34,38,40,42,44','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }else if(this.getField('cuenta_reteiva').getValue() === 'S'){
            this.establecerPropiedades('32,34,38,42,44','value','N');
            this.establecerPropiedades('40','value','S');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }
    }

    cuenta_reteica()
    {
        if(this.getField('cuenta_reteica').getValue() === 'N'){
            this.establecerPropiedades('32,34,36,40,42,44','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }else if(this.getField('cuenta_reteica').getValue() === 'S'){
            this.establecerPropiedades('32,34,36,42,44','value','N');
            this.establecerPropiedades('40','value','S');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }
    }

    cuenta_caja()
    {
        if(this.getField('cuenta_caja').getValue() === 'N'){
            this.establecerPropiedades('32,34,36,38,40,44','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }else if(this.getField('cuenta_caja').getValue() === 'S'){
            this.establecerPropiedades('32,34,36,38,40,44','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }
    }

    cuenta_banco()
    {
        if(this.getField('cuenta_banco').getValue() === 'N'){
            this.establecerPropiedades('32,34,36,38,40,42','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }else if(this.getField('cuenta_banco').getValue() === 'S'){
            this.establecerPropiedades('32,34,36,38,40,42','value','N');
            this.establecerPropiedades('30,32,34,36,38,40,42,44','disable','true');
        }
    }

    validarcampos1()
    {
        if((this.getField('nombre1').valid()) && (this.getField('maneja_tercero').valid()) && (this.getField('cuenta_iva').valid()) && (this.getField('cuenta_retefuente').valid()) 
        && (this.getField('cuenta_reteiva').valid())  && (this.getField('cuenta_reteica').valid())  && (this.getField('cuenta_caja').valid())  && (this.getField('cuenta_banco').valid())  
        && (this.getField('nif').valid())){
            if(this.getField('clase1sub').getValue() === 1 || this.getField('clase1sub').getValue() === 2){
                this.establecerPropiedades('51','disable','false');
            }else{
                this.establecerPropiedades('51','disable','false');
                this.establecerPropiedades('53','value','NINGUNO');
                this.tipo_cartera="N"
            }
            this.establecerPropiedades('54','disable','false')
            this.getField('TabJaivana_9').setNextTab();
        }else{
            this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
            this.establecerPropiedades('51,53,54,57,59,60','disable','true');
            this.establecerPropiedades('51,53,57,59','value','')
            
        }
    }
    
    validarGrabar()
    {
        if(this.getField('estadoactivo_id').valid() && this.getField('detallado').valid()){
            this.getField('codigo_cuenta_texto').setValue(this.getField('codigo_cuenta').getValue())
            let datos = {  datos: { 
                codigo_cuenta:this.getField('codigo_cuenta').getValue(),
                codigo_cuenta_texto:this.getField('codigo_cuenta_texto').getValue(),
                nombre1:this.getField('nombre1').getValue(),
                maneja_tercero:this.getField('maneja_tercero').getValue(),
                maneja_movimiento2:this.getField('maneja_movimiento2sub').getValue(),
                maneja_documento_fuente:this.getField('maneja_documento_fuente').getValue(),
                codigo_nivel:this.codigo_nivel,
                cuenta_banco:this.getField('cuenta_banco').getValue(),
                cuenta_retefuente:this.getField('cuenta_retefuente').getValue(),
                cuenta_reteiva:this.getField('cuenta_reteiva').getValue(),
                cuenta_reteica:this.getField('cuenta_reteica').getValue(),
                cuenta_iva:this.getField('cuenta_iva').getValue(),
                cuenta_caja:this.getField('cuenta_caja').getValue(),
                estado:this.getField('estado').getValue(),
                auxiliar:0.0,
                tipo_fuente:this.tipo_cartera,
                maneja_base:this.getField('maneja_base').getValue(),
                nif:this.getField('nif').getValue(),
                nivel_id:this.nivel_id,
                tipo_cuenta_id:1,
                codigo_alterno:this.getField('codigo_cuenta').getValue(),
                nombre_alterno:this.getField('nombre1').getValue(),
                estadoactivo_id:this.getField('estadoactivo_id').getValue(),
                detallado:this.getField('detallado').getValue(),
                clase1:this.getField('clase1sub').getValue()
              }
            }

            this.generalFormatPmv = { tipo_servicio: 'maes-cuentas', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successcrear,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
        else{
            this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
        }
    }

    successcrear(data)
    {
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'La cuenta fue creada con exito', 'success');
            this.limpiar();
            this.getField('TabJaivana_9').reInit();

        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
               this.validarcampos();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    limpiar(){
      this.getField('TabJaivana_9').reInit();
      this.getField('lineas').setItemsFichas('');
      this.establecerPropiedades('8,11,12,13,171,201,221,231,26,28,30,32,34,36,38,40,42,44,46,51,53,54,57,59,60,61','disable','true');
      this.establecerPropiedades('8,11,12,13,171,201,221,26,28,30,32,34,36,38,40,42,44,46,51,53,57,59,61,62,63','value','');
      this.registroCuentasPadre= {};
      this.Caracteres= 0;
      this.cuenta_clase= 0;
      this.cuenta_clase_nombre= "";
      this.nivel_id= 0;
      this.seguir= false;
      this.codigo_nivel= 0;
      this.opcionAgregar();
    }

    eliminarvalid()
    {   
        this.getField('maneja_movimiento2').valid(false);
        this.getField('maneja_movimiento2sub').valid(false);
        this.getField('clase1').valid(false);
        this.getField('clase1sub').valid(false);
        this.getField('nombre1').valid(false);
        this.getField('maneja_tercero').valid(false);
        this.getField('cuenta_iva').valid(false);
        this.getField('cuenta_retefuente').valid(false);
        this.getField('cuenta_reteiva').valid(false);
        this.getField('cuenta_reteica').valid(false);
        this.getField('maneja_base').valid(false);
        this.getField('cuenta_caja').valid(false);
        this.getField('cuenta_banco').valid(false);
        this.getField('nif').valid(false);
        this.getField('maneja_documento_fuente').valid(false);
        this.getField('tipo_fuente').valid(false);
        this.getField('estadoactivo_id').valid(false);
        this.getField('detallado').valid(false);
        this.getField('estado').valid(false);

    }

    ///////////Modificar Cuenta

    opcionModificar(){
        this.getField('TabJaivana_9').setNextTab();
        this.service_estadosino();
        this.establecerPropiedades('16,17,18','visible','false');
        this.establecerPropiedades('71,72,73,74,76,15,20,22,23','visible','true');
        this.establecerPropiedades('20,22,23','disable','true');
        this.getField('nombre3').setOnChange(this.traerclase);
        this.getField('seleccionar').setClick(this.seleccionar); 
        this.getField('continuar2').setClick(this.validarsubCuenta);
        this.getField('TabJaivana_9').setClick(this.limpiar1);
        this.getField('continuar4').setClick(this.validartipo);
        this.getField('maneja_documento_fuente').setOnChange(this.cambiarTipo);
        this.getField('tipo_fuente').setOnChange(this.tipo);
        this.getField('terminar').setClick(this.actualizarcuenta);
        this.getField('cancelar8').setClick(()=>{this.establecerPropiedades('72,73','value','');});
    }

    seleccionar(){
        if(this.getField('nombre3').getValue() !== ''){
            this.cuenta = this.getField('codigo_cuenta2').getValue();
            this.nombre = this.getField('nombre3').getValue();
            this.llenarencabezado1();
            this.cambiarTipo();
            this.establecerPropiedades('20','disable','false');
            this.getField('contenedorDatos1').handleClose();
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar una cuenta.', 'error');
        }
    }

    limpiar1(){
        this.eliminarvalid();
        this.establecerPropiedades('71,72,73,74,76,15,20,22,23','visible','true')
        this.getField('TabJaivana_9').setTabNumber(2);
        this.establecerPropiedades('20,22','disable','true')
        this.establecerPropiedades('20,62','value','')
        this.establecerPropiedades('','','false')
    }
    
    llenarencabezado1()
    {
        let datosFicha = [];
        let data2 = 
                {
                    "cuenta": this.cuenta,
                    "nombre": this.nombre,
                };
        datosFicha.push(data2);
        this.getField('lineas').setItemsFichas(datosFicha);
    }

    tipo(){
        if(this.getField('tipo_fuente').getValue() !== ''){
            if(this.getField('tipo_fuente').getValue() === 'C'){
                this.getField('tipo_fuente').setValue('CUENTAS POR COBRAR');
            }
            else if (this.getField('tipo_fuente').getValue() === 'P'){
                this.getField('tipo_fuente').setValue('CUENTAS POR PAGAR');
            }
            else if (this.getField('tipo_fuente').getValue() === 'N'){ 
                this.getField('tipo_fuente').setValue('NINGUNO');
            }
        }
    }

    validarsubCuenta(){
        if(this.getField('maneja_movimiento2').valid()){
            this.getField('TabJaivana_9').setTabNumber(4);
            this.establecerPropiedades('49,51,53','visible','true')
        }
    }

    cambiarTipo(){
        if(this.getField('maneja_documento_fuente').getValue() === 'S' && this.getField('clase1').getValue() === 1){
            this.getField('tipo_fuente').setValue('CUENTAS POR COBRAR');
            this.tipo_cartera="C";
        }
        else if (this.getField('maneja_documento_fuente').getValue() === 'S' && this.getField('clase1').getValue() === 2){
            this.getField('tipo_fuente').setValue('CUENTAS POR PAGAR');
            this.tipo_cartera="P";
        }
        else if (this.getField('maneja_documento_fuente').getValue() === 'N'){ 
            this.getField('tipo_fuente').setValue('NINGUNO');
            this.tipo_cartera="N";
        }
        else if (this.getField('maneja_documento_fuente').getValue() === '' ){
            this.getField('tipo_fuente').setValue('')
        }else if (this.getField('maneja_documento_fuente').getValue() === 'S' && this.getField('clase1').getValue() !== 1 && this.getField('clase1').getValue() !== 2){
            this.getField('tipo_fuente').setValue('NINGUNO');
            this.tipo_cartera="N";
        }
    }

    validartipo(){
        if(this.getField('maneja_documento_fuente').valid() && this.getField('tipo_fuente').valid()){
            this.getField('TabJaivana_9').setNextTab();
            this.establecerPropiedades('57,59,60','disable','false');
            this.establecerPropiedades('55,57,59','visible','true')
        }
    }

    actualizarcuenta(){
        if(this.getField('estadoactivo_id').valid() && this.getField('detallado').valid()){
            let datos={ datos: { maneja_movimiento2: this.getField('maneja_movimiento2').getValue(),clase1: this.getField('clase1').getValue(), 
            maneja_documento_fuente: this.getField('maneja_documento_fuente').getValue(),tipo_fuente: this.tipo_cartera, 
            estadoactivo_id: this.getField('estadoactivo_id').getValue(),detallado: this.getField('detallado').getValue(), 
            estado:this.getField('estado').getValue(), codigo_cuenta_texto:this.cuenta }};
                this.generalFormatPmv = { tipo_servicio: 'maes-cuentas', operacion: 'modificarcuenta', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successactualizarcuenta,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
        }
    }

    successactualizarcuenta(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'La cuenta fue modificada con exito', 'success');
            this.limpiar1();

        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
               this.validarcampos();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }



    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
            console.log("this.arrayObjetos.get(parseInt(element)) = ",this.arrayObjetos.get(parseInt(element)));
            console.log("element = ",element);
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true' && this.getField(this.arrayObjetos.get(parseInt(element))) !== undefined) { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else if(this.getField(this.arrayObjetos.get(parseInt(element))) !== undefined){
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true'  && this.getField(this.arrayObjetos.get(parseInt(element))) !== undefined) { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else if(this.getField(this.arrayObjetos.get(parseInt(element))) !== undefined){
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value'  && this.getField(this.arrayObjetos.get(parseInt(element))) !== undefined)
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid'  && this.getField(this.arrayObjetos.get(parseInt(element))) !== undefined)
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else if(this.getField(this.arrayObjetos.get(parseInt(element))) !== undefined){
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }

}
FormJaivana.addController("maes-cuentas",CustomCuentas);
export default CustomCuentas