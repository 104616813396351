import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomModificarArticulos
 * @author: Patricia López Sánchez
 * @updateBy Anderson Acevedo Briñez
 * @version: jdesk_1.01.0001
 **/
class CustomModificarArticulos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.llenarencabezado           = this.llenarencabezado.bind(this);
        this.codigo                     = "";
        this.nombre                     = "";
        this.actualizarPeso             = this.actualizarPeso.bind(this);
        this.successactualizarPeso      = this.successactualizarPeso.bind(this);
        this.actualizarStock            = this.actualizarStock.bind(this);
        this.successactualizarStock     = this.successactualizarStock.bind(this);
        this.actualizarFinancieroMargen = this.actualizarFinancieroMargen.bind(this);
        this.successFinancieroMargen    = this.successFinancieroMargen.bind(this);
        this.actualizarfoco             = this.actualizarfoco.bind(this)
        this.successFoco                = this.successFoco.bind(this);
        this.actualizarprecio           = this.actualizarprecio.bind(this)
        this.successPrecio              = this.successPrecio.bind(this);
        this.actualizarImpuesto         = this.actualizarImpuesto.bind(this);
        this.successImpuesto            = this.successImpuesto.bind(this);
        this.opcionAsignarUbicacionBasica=this.opcionAsignarUbicacionBasica.bind(this);
        this.actualizarUbicacionBasica =this.actualizarUbicacionBasica.bind(this);
        this.successUbicacionBasica =this.successUbicacionBasica.bind(this);
        this.opcion                     = "";
        this.validarPeso                = this.validarPeso.bind(this);
        this.validarStock               = this.validarStock.bind(this);
        this.validarFinanciera          = this.validarFinanciera.bind(this);
        this.validarUbicacionBasica     = this.validarUbicacionBasica.bind(this);
        this.validarFoco                = this.validarFoco.bind(this);
        this.validarImpuesto            = this.validarImpuesto.bind(this);
        this.validarPrecios             = this.validarPrecios.bind(this);
        this.limpiar                    = this.limpiar.bind(this);

    }

    initForm(){
        console.log('Formulario CustomModificarArticulos,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez, @updateBy Anderson Acevedo Briñez')

            this.arrayCampos.set(2,'contenedorDatos1');
            this.arrayCampos.set(3,'codigo_producto');
            this.arrayCampos.set(4,'nombre');
            this.arrayCampos.set(5,'seleccionar');
            this.arrayCampos.set(7,'cancelar');
            this.arrayCampos.set(8,'lineas');
            this.arrayCampos.set(9,'contenedorDatos');
            this.arrayCampos.set(10,'label1');
            this.arrayCampos.set(11,'peso');
            this.arrayCampos.set(12,'aceptar');
            this.arrayCampos.set(14,'cancelar1');
            this.arrayCampos.set(15,'contenedorDatos2');
            this.arrayCampos.set(16,'label2');
            this.arrayCampos.set(17,'minimo');
            this.arrayCampos.set(18,'label3');
            this.arrayCampos.set(19,'maximo');
            this.arrayCampos.set(20,'aceptar1');
            this.arrayCampos.set(22,'cancelar2');
            this.arrayCampos.set(23,'contenedorDatos3');
            this.arrayCampos.set(24,'label4');
            this.arrayCampos.set(25,'precio1');
            this.arrayCampos.set(26,'label5');
            this.arrayCampos.set(27,'financiero');
            this.arrayCampos.set(28,'label6');
            this.arrayCampos.set(29,'rentabilidad');
            this.arrayCampos.set(30,'label7');
            this.arrayCampos.set(31,'precio2');
            this.arrayCampos.set(32,'label8');
            this.arrayCampos.set(33,'financiero1');
            this.arrayCampos.set(34,'label9');
            this.arrayCampos.set(35,'rentabilidad1');
            this.arrayCampos.set(36,'label10');
            this.arrayCampos.set(37,'precio3');
            this.arrayCampos.set(38,'label11');
            this.arrayCampos.set(39,'financiero2');
            this.arrayCampos.set(40,'label12');
            this.arrayCampos.set(41,'rentabilidad2');
            this.arrayCampos.set(42,'label13');
            this.arrayCampos.set(43,'precio4');
            this.arrayCampos.set(44,'label14');
            this.arrayCampos.set(45,'financiero3');
            this.arrayCampos.set(46,'label15');
            this.arrayCampos.set(47,'precio5');
            this.arrayCampos.set(48,'label16');
            this.arrayCampos.set(49,'financiero4');
            this.arrayCampos.set(50,'aceptar2');
            this.arrayCampos.set(51,'label34');
            this.arrayCampos.set(52,'cancelar3');
            this.arrayCampos.set(52,'cancelar3');
            this.arrayCampos.set(53,'contenedorDatos4');
            this.arrayCampos.set(54,'label17');
            this.arrayCampos.set(55,'foco');
            this.arrayCampos.set(56,'aceptar4');
            this.arrayCampos.set(57,'label35');
            this.arrayCampos.set(58,'cancelar4');
            this.arrayCampos.set(59,'contenedorDatos5');
            this.arrayCampos.set(60,'label18');
            this.arrayCampos.set(61,'precio1');
            this.arrayCampos.set(62,'label19');
            this.arrayCampos.set(63,'financieroo');
            this.arrayCampos.set(64,'label20');
            this.arrayCampos.set(65,'rentabilidad0');
            this.arrayCampos.set(66,'label21');
            this.arrayCampos.set(67,'precio2');
            this.arrayCampos.set(68,'label22');
            this.arrayCampos.set(69,'financiero11');
            this.arrayCampos.set(70,'label23');
            this.arrayCampos.set(71,'rentabilidad11');
            this.arrayCampos.set(72,'label24');
            this.arrayCampos.set(73,'precio3');
            this.arrayCampos.set(74,'label25');
            this.arrayCampos.set(75,'financiero22');
            this.arrayCampos.set(76,'label26');
            this.arrayCampos.set(77,'rentabilidad22');
            this.arrayCampos.set(78,'label27');
            this.arrayCampos.set(79,'precio4');
            this.arrayCampos.set(80,'label28');
            this.arrayCampos.set(81,'financiero33');
            this.arrayCampos.set(82,'label29');
            this.arrayCampos.set(83,'precio5');
            this.arrayCampos.set(84,'label31');
            this.arrayCampos.set(85,'financiero44');
            this.arrayCampos.set(86,'aceptar5');
            this.arrayCampos.set(87,'label36');
            this.arrayCampos.set(88,'cancelar5');
            this.arrayCampos.set(89,'contenedorDatos6');
            this.arrayCampos.set(90,'label37');
            this.arrayCampos.set(91,'impuesto1');
            this.arrayCampos.set(92,'aceptar6');
            this.arrayCampos.set(93,'label38');
            this.arrayCampos.set(94,'cancelar6');
            this.arrayCampos.set(100,'contenedorDatos7');
            this.arrayCampos.set(101,'ubicacion');
            this.arrayCampos.set(102,'seccion');
            this.arrayCampos.set(103,'bandeja');
            this.arrayCampos.set(104,'lugar');
            this.arrayCampos.set(105,'aceptar7');
            this.arrayCampos.set(106,'label39');
            this.arrayCampos.set(107,'cancelar7');

            this.arrayObjetos.set(2,'contenedorDatos1');
            this.arrayObjetos.set(3,'codigo_producto');
            this.arrayObjetos.set(4,'nombre');
            this.arrayObjetos.set(5,'seleccionar');
            this.arrayObjetos.set(7,'cancelar');
            this.arrayObjetos.set(8,'lineas');
            this.arrayObjetos.set(9,'contenedorDatos');
            this.arrayObjetos.set(10,'label1');
            this.arrayObjetos.set(11,'peso');
            this.arrayObjetos.set(12,'aceptar');
            this.arrayObjetos.set(14,'cancelar1');
            this.arrayObjetos.set(15,'contenedorDatos2');
            this.arrayObjetos.set(16,'label2');
            this.arrayObjetos.set(17,'minimo');
            this.arrayObjetos.set(18,'label3');
            this.arrayObjetos.set(19,'maximo');
            this.arrayObjetos.set(20,'aceptar1');
            this.arrayObjetos.set(22,'cancelar2');
            this.arrayObjetos.set(23,'contenedorDatos3');
            this.arrayObjetos.set(24,'label4');
            this.arrayObjetos.set(25,'precio11');
            this.arrayObjetos.set(26,'label5');
            this.arrayObjetos.set(27,'financiero');
            this.arrayObjetos.set(28,'label6');
            this.arrayObjetos.set(29,'rentabilidad');
            this.arrayObjetos.set(30,'label7');
            this.arrayObjetos.set(31,'precio22');
            this.arrayObjetos.set(32,'label8');
            this.arrayObjetos.set(33,'financiero1');
            this.arrayObjetos.set(34,'label9');
            this.arrayObjetos.set(35,'rentabilidad1');
            this.arrayObjetos.set(36,'label10');
            this.arrayObjetos.set(37,'precio33');
            this.arrayObjetos.set(38,'label11');
            this.arrayObjetos.set(39,'financiero2');
            this.arrayObjetos.set(40,'label12');
            this.arrayObjetos.set(41,'rentabilidad2');
            this.arrayObjetos.set(42,'label13');
            this.arrayObjetos.set(43,'precio44');
            this.arrayObjetos.set(44,'label14');
            this.arrayObjetos.set(45,'financiero3');
            this.arrayObjetos.set(46,'label15');
            this.arrayObjetos.set(47,'precio55');
            this.arrayObjetos.set(48,'label16');
            this.arrayObjetos.set(49,'financiero4');
            this.arrayObjetos.set(50,'aceptar2');
            this.arrayObjetos.set(51,'label34');
            this.arrayObjetos.set(52,'cancelar3');
            this.arrayObjetos.set(53,'contenedorDatos4');
            this.arrayObjetos.set(54,'label17');
            this.arrayObjetos.set(55,'foco');
            this.arrayObjetos.set(56,'aceptar4');
            this.arrayObjetos.set(57,'label35');
            this.arrayObjetos.set(58,'cancelar4');
            this.arrayObjetos.set(59,'contenedorDatos5');
            this.arrayObjetos.set(60,'label18');
            this.arrayObjetos.set(61,'precio1');
            this.arrayObjetos.set(62,'label19');
            this.arrayObjetos.set(63,'financieroo');
            this.arrayObjetos.set(64,'label20');
            this.arrayObjetos.set(65,'rentabilidad0');
            this.arrayObjetos.set(66,'label21');
            this.arrayObjetos.set(67,'precio2');
            this.arrayObjetos.set(68,'label22');
            this.arrayObjetos.set(69,'financiero11');
            this.arrayObjetos.set(70,'label23');
            this.arrayObjetos.set(71,'rentabilidad11');
            this.arrayObjetos.set(72,'label24');
            this.arrayObjetos.set(73,'precio3');
            this.arrayObjetos.set(74,'label25');
            this.arrayObjetos.set(75,'financiero22');
            this.arrayObjetos.set(76,'label26');
            this.arrayObjetos.set(77,'rentabilidad22');
            this.arrayObjetos.set(78,'label27');
            this.arrayObjetos.set(79,'precio4');
            this.arrayObjetos.set(80,'label28');
            this.arrayObjetos.set(81,'financiero33');
            this.arrayObjetos.set(82,'label29');
            this.arrayObjetos.set(83,'precio5');
            this.arrayObjetos.set(84,'label31');
            this.arrayObjetos.set(85,'financiero44');
            this.arrayObjetos.set(86,'aceptar5');
            this.arrayObjetos.set(87,'label36');
            this.arrayObjetos.set(88,'cancelar5');
            this.arrayObjetos.set(89,'contenedorDatos6');
            this.arrayObjetos.set(90,'label37');
            this.arrayObjetos.set(91,'impuesto1');
            this.arrayObjetos.set(92,'aceptar6');
            this.arrayObjetos.set(93,'label38');
            this.arrayObjetos.set(94,'cancelar6');
            this.arrayObjetos.set(100,'contenedorDatos7');
            this.arrayObjetos.set(101,'ubicacion');
            this.arrayObjetos.set(102,'seccion');
            this.arrayObjetos.set(103,'bandeja');
            this.arrayObjetos.set(104,'lugar');
            this.arrayObjetos.set(105,'aceptar7');
            this.arrayObjetos.set(106,'label39');
            this.arrayObjetos.set(107,'cancelar7');

            this.establecerPropiedades('11,17,19,27,29,33,35,39,41,45,49,55,61,63,65,67,69,71,73,75,77,79,81,83,85,91,101,102,103,104','disable','true');

            this.getField('aceptar').setDisabled(true); 
            this.getField('aceptar1').setDisabled(true); 
            this.getField('aceptar2').setDisabled(true);  
            this.getField('aceptar4').setDisabled(true); 
            this.getField('aceptar5').setDisabled(true); 
            this.getField('aceptar6').setDisabled(true); 
            this.getField('aceptar7').setDisabled(true); 

            this.getField('TabJaivana_61').setDisabledButtonTab(true);
            if(this.props.data[0].opcion_sub_seccion === 1){
                this.establecerPropiedades('9,10,11,12,14','visible','true');
                this.opcionAsignarPeso();
            }else if(this.props.data[0].opcion_sub_seccion === 2){
                this.establecerPropiedades('14,15,16,17,18,19,20,22','visible','true'); 
                this.opcionAgregarStock();
            }else if(this.props.data[0].opcion_sub_seccion === 3){
                this.establecerPropiedades('23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,52','visible','true'); 
                this.opcionAsignarFinancieroMargen();
            }else if(this.props.data[0].opcion_sub_seccion === 4){
                this.establecerPropiedades('53,54,55,56,58','visible','true'); 
                this.opcionDefinirFoco();
            }else if(this.props.data[0].opcion_sub_seccion === 5){
                this.establecerPropiedades('59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,88','visible','true')
                this.opcionAsignarPrecios();
            }else if(this.props.data[0].opcion_sub_seccion === 6){
                this.establecerPropiedades('89,90,91,92,94','visible','true')
                this.opcionAsignarImpuestos();
            }else if(this.props.data[0].opcion_sub_seccion === 7){
                this.establecerPropiedades('100,101,102,103,104,105,107','visible','true')
                this.opcionAsignarUbicacionBasica();
            } 
            this.getField('peso').setOnChange(this.validarPeso);
            this.getField('minimo').setOnChange(this.validarStock);
            this.getField('financiero').setOnChange(this.validarFinanciera);
            this.getField('financiero1').setOnChange(this.validarFinanciera);
            this.getField('financiero2').setOnChange(this.validarFinanciera);
            this.getField('financiero3').setOnChange(this.validarFinanciera);
            this.getField('financiero4').setOnChange(this.validarFinanciera);
            this.getField('rentabilidad').setOnChange(this.validarFinanciera);
            this.getField('rentabilidad1').setOnChange(this.validarFinanciera);
            this.getField('rentabilidad2').setOnChange(this.validarFinanciera);
            this.getField('ubicacion').setOnChange(this.validarUbicacionBasica);
            this.getField('bandeja').setOnChange(this.validarUbicacionBasica);
            this.getField('lugar').setOnChange(this.validarUbicacionBasica);
            this.getField('seccion').setOnChange(this.validarUbicacionBasica);
            this.getField('foco').setOnChange(this.validarFoco);
            this.getField('impuesto1').setOnChange(this.validarImpuesto);
            this.getField('precio1').setOnChange(this.validarPrecios);
            this.getField('precio2').setOnChange(this.validarPrecios);
            this.getField('precio3').setOnChange(this.validarPrecios);
            this.getField('precio4').setOnChange(this.validarPrecios);
            this.getField('precio5').setOnChange(this.validarPrecios);
    }

    validarPrecios(){
        this.getField('aceptar5').setDisabled(false);   
    }

    validarImpuesto(){
        this.getField('aceptar6').setDisabled(false);   
    }

    validarFoco(){
        if(this.getField('foco').getValue() !==''){
            this.getField('aceptar4').setDisabled(false); 
            this.getField('foco').setError(false, '');
  
        }else{
            this.getField('foco').valid();
        }
    }

    validarUbicacionBasica(){
        this.getField('aceptar7').setDisabled(false);   
    }

    validarFinanciera(){
        this.getField('aceptar2').setDisabled(false);   
    }

    validarStock(){
        this.getField('aceptar1').setDisabled(false);   
    }

    validarPeso(){
        this.getField('aceptar').setDisabled(false);   
    }

    opcionAsignarPeso(){
        
        this.opcion="Peso";
        this.establecerPropiedades('2,3,4,5,7','visible','true')

        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.codigo = this.getField('codigo_producto').getValue(); this.nombre = this.getField('nombre').getValue();
                this.getField('contenedorDatos1').handleClose(); 
                this.llenarencabezado();
                this.getField('peso').setDisabled(false);
                this.getField('aceptar').setDisabled(false);
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });

        this.getField('cancelar').setClick(()=>{this.getField('codigo_producto').setValue(''); this.getField('nombre').setValue('')});
        this.getField('cancelar1').setClick(this.limpiar);
        this.getField('aceptar').setClick(this.actualizarPeso)
    }

    opcionAgregarStock(){

        this.opcion= "Stock"
        this.establecerPropiedades('2,3,4,5,7','visible','true')

        this.getField('TabJaivana_61').setNextTab();

        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.codigo = this.getField('codigo_producto').getValue(); this.nombre = this.getField('nombre').getValue();
                this.getField('contenedorDatos1').handleClose(); 
                this.llenarencabezado();
                this.establecerPropiedades('17,19','disable','false');
                this.getField('aceptar1').setDisabled(false);
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });

        this.getField('cancelar').setClick(()=>{this.getField('codigo_producto').setValue(''); this.getField('nombre').setValue('');});
        this.getField('cancelar2').setClick(this.limpiar);
        this.getField('aceptar1').setClick(this.actualizarStock)
    }
    
    opcionAsignarFinancieroMargen(){
        this.opcion= "Financieros"
        this.getField('TabJaivana_61').setNextTab();
        this.establecerPropiedades('2,3,4,5,7','visible','true')

        this.getField('TabJaivana_61').setNextTab();
        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.codigo = this.getField('codigo_producto').getValue(); this.nombre = this.getField('nombre').getValue();
                this.getField('precio11').setDisabled(true); 
                this.getField('precio22').setDisabled(true);   
                this.getField('precio33').setDisabled(true);   
                this.getField('precio44').setDisabled(true);   
                this.getField('precio55').setDisabled(true);   
                this.establecerPropiedades('27,29,33,35,39,41,45,49,55','disable','false');
                this.getField('aceptar2').setDisabled(false);
                this.getField('contenedorDatos1').handleClose(); 
                this.llenarencabezado();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });
        
        this.getField('cancelar').setClick(()=>{this.getField('codigo_producto').setValue(''); this.getField('nombre').setValue('')});
        this.getField('cancelar3').setClick(this.limpiar);
        
        this.getField('aceptar2').setClick(this.actualizarFinancieroMargen)
    }
    
    opcionDefinirFoco(){
        
        this.opcion="Foco";
        this.getField('TabJaivana_61').setNextTab();
        this.establecerPropiedades('2,3,4,5,7','visible','true')
        
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.codigo = this.getField('codigo_producto').getValue(); this.nombre = this.getField('nombre').getValue();
                this.getField('contenedorDatos1').handleClose(); 
                this.getField('foco').setDisabled(false);
                this.getField('aceptar4').setDisabled(false);
                this.llenarencabezado();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });
        
        this.getField('cancelar').setClick(()=>{this.getField('codigo_producto').setValue(''); this.getField('nombre').setValue('')});
        this.getField('cancelar4').setClick(this.limpiar);
        this.getField('aceptar4').setClick(this.actualizarfoco)
    }
    
    
    opcionAsignarPrecios(){
        this.opcion= "Precios";
        this.getField('TabJaivana_61').setNextTab();
        this.establecerPropiedades('2,3,4,5,7','visible','true')
        
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        
        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.codigo = this.getField('codigo_producto').getValue(); this.nombre = this.getField('nombre').getValue();
                this.establecerPropiedades('61,67,73,79,83','disable','false');
                this.getField('aceptar5').setDisabled(false); 

                this.getField('contenedorDatos1').handleClose(); this.llenarencabezado();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });
    
        this.getField('cancelar').setClick(()=>{this.getField('codigo_producto').setValue(''); this.getField('nombre').setValue('')});
        this.getField('cancelar5').setClick(this.limpiar);
        
        this.getField('aceptar5').setClick(this.actualizarprecio)
    }
    
    opcionAsignarImpuestos(){
        this.opcion ="Impuesto";
        this.getField('TabJaivana_61').setNextTab();
        this.establecerPropiedades('2,3,4,5,7','visible','true')
        
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();

        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.codigo = this.getField('codigo_producto').getValue(); this.nombre = this.getField('nombre').getValue();
                this.getField('contenedorDatos1').handleClose();  
                this.getField('impuesto1').setDisabled(false);
                this.getField('aceptar6').setDisabled(false); 
                this.llenarencabezado();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });

        this.getField('cancelar').setClick(()=>{this.getField('codigo_producto').setValue(''); this.getField('nombre').setValue('')});
        this.getField('cancelar6').setClick(this.limpiar);
        this.getField('aceptar6').setClick(this.actualizarImpuesto);
    }

    opcionAsignarUbicacionBasica(){
        this.opcion ="ubicacionBasica";
        this.getField('TabJaivana_61').setNextTab();
        this.establecerPropiedades('2,3,4,5,7','visible','true');
        
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();
        this.getField('TabJaivana_61').setNextTab();

        this.getField('seleccionar').setClick(()=>{ 
            if((this.getField('nombre').getValue() !== '')){
                this.codigo = this.getField('codigo_producto').getValue(); 
                this.nombre = this.getField('nombre').getValue();
                this.getField('contenedorDatos1').handleClose(); 
                this.llenarencabezado();
                this.establecerPropiedades('101,102,103,104','disable','false');
                this.getField('aceptar7').setDisabled(false); 
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un producto', 'error');
            }
        });

        this.getField('cancelar7').setClick(()=>{
            this.getField('lugar').setValue(this.lugar); 
            this.getField('seccion').setValue(this.seccion); 
            this.getField('bandeja').setValue(this.bandeja); 
            this.getField('ubicacion').setValue(this.ubicacion); 
            this.getField('lugar').setError(false,'');
            this.getField('seccion').setError(false,'');
            this.getField('bandeja').setError(false,'');
            this.getField('ubicacion').setError(false,'');
            
            this.establecerPropiedades('89,90,91,92,94,100,101,102,103,104,105,106','visible','false')
            this.establecerPropiedades('2,3,4,5,7','visible','true');
        });
        this.getField('aceptar7').setClick(this.actualizarUbicacionBasica);
    }

    llenarencabezado()
    {
        let datosFicha = [];
        let data2 = 
                {
                    "producto": this.codigo,
                    "nombre": this.nombre,
                };
        datosFicha.push(data2);
        this.getField('lineas').setItemsFichas(datosFicha);
    }

    actualizarPeso(){
        if(this.getField('peso').valid()){
            let datos={ datos: {peso:this.getField('peso').getValue(), value: this.codigo }};
            this.generalFormatPmv = { tipo_servicio: 'maes-modificarproducto', operacion: 'modificarpeso', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successactualizarPeso,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successactualizarPeso(data){
        if (data.estado_p === 200 )
        {
            this.getField('aceptar').setDisabled(true);   
            this.getField('peso').input.focus();
            this.alertGeneral.toggle(true, 'El peso del articulo '+this.nombre+' fue modificado con éxito.', 'success');
            
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualizarStock(){
 
        if((this.getField('minimo').valid() && this.getField('maximo').valid() &&
        this.getField('minimo').getValue() !== '' && this.getField('maximo').getValue() !== '')){

            if(this.getField('maximo').getValue() >= this.getField('minimo').getValue())
            {
                let datos={ datos: {minimo:this.getField('minimo').getValue(), maximo:this.getField('maximo').getValue(),value: this.codigo }};
                this.generalFormatPmv = { tipo_servicio: 'maes-modificarproducto', operacion: 'modificarstock', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successactualizarStock,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
            }else{
                this.alertGeneral.toggle(true, 'El stock máximo debe ser mayor al stock mínimo', 'error');
                
            }
        }
    }

    successactualizarStock(data){
        if (data.estado_p === 200 )
        {
            this.getField('aceptar1').setDisabled(true);   
            this.alertGeneral.toggle(true, 'El stock mínimo y máximo del articulo '+this.nombre+' fueron modificados con éxito.', 'success');
            
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualizarFinancieroMargen(){
        if(this.getField('financiero').valid() && this.getField('financiero1').valid() &&
        this.getField('financiero2').valid() && this.getField('financiero3').valid() && this.getField('financiero4').valid() &&
        this.getField('rentabilidad').valid() && this.getField('rentabilidad1').valid() && this.getField('rentabilidad2').valid() &&
        this.getField('rentabilidad').getValue() !== '' && this.getField('rentabilidad1').getValue() !== '' && this.getField('rentabilidad2').getValue() !== ''){

                let datos={ datos: {financiero:this.getField('financiero').getValue(), financiero1:this.getField('financiero1').getValue(),
                financiero2:this.getField('financiero2').getValue(), financiero3:this.getField('financiero3').getValue(), financiero4:this.getField('financiero4').getValue(),
                rentabilidad:this.getField('rentabilidad').getValue(), rentabilidad1:this.getField('rentabilidad1').getValue(), 
                rentabilidad2:this.getField('rentabilidad2').getValue(), value: this.codigo }};
                this.generalFormatPmv = { tipo_servicio: 'maes-modificarproducto', operacion: 'modificarfinancieromargen', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successFinancieroMargen,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
        }
    }

    successFinancieroMargen(data){
        if (data.estado_p === 200 )
        {
            this.getField('aceptar2').setDisabled(true);   
            this.alertGeneral.toggle(true, 'Los campos financieros y margen del articulo '+this.nombre+' fueron modificados con éxito.', 'success');
            
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }
    
    actualizarfoco(){
        if((this.getField('foco').getValue() !== '')){
            let datos={ datos: {foco:this.getField('foco').getValue(), value: this.codigo }};
            this.generalFormatPmv = { tipo_servicio: 'maes-modificarproducto', operacion: 'modificarfoco', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successFoco,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successFoco(data){
        if (data.estado_p === 200 )
        {
            this.getField('aceptar4').setDisabled(true);   
            this.getField('foco').input.focus();
            this.alertGeneral.toggle(true, 'Se define el FOCO en ('+this.getField('foco').getValue()+') del artículo '+this.nombre+' de manera correcta.', 'success');
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualizarprecio(){

        if(this.getField('precio1').valid() && this.getField('precio2').valid() &&
        this.getField('precio3').valid() && this.getField('precio4').valid() && this.getField('precio5').valid()){

                let datos={ datos: {precio1:this.getField('precio1').getValue(), precio2:this.getField('precio2').getValue(),
                precio3:this.getField('precio3').getValue(), precio4:this.getField('precio4').getValue(), 
                precio5:this.getField('precio5').getValue(), value: this.codigo }};
                this.generalFormatPmv = { tipo_servicio: 'maes-modificarproducto', operacion: 'modificarprecio', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successPrecio,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
        }
    }

    successPrecio(data){
        if (data.estado_p === 200 )
        {
            this.getField('aceptar5').setDisabled(true);   
            this.alertGeneral.toggle(true, 'Los precios del articulo '+this.nombre+' fueron modificados con éxito.', 'success');
            
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualizarImpuesto(){
        if(this.getField('impuesto1').valid() ){

                let datos={ datos: {impuesto1:this.getField('impuesto1').getValue(), value: this.codigo }};
                this.generalFormatPmv = { tipo_servicio: 'maes-modificarproducto', operacion: 'modificarimpuesto', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successImpuesto,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
        }
    }

    successImpuesto(data){
        if (data.estado_p === 200 )
        {
            this.getField('aceptar6').setDisabled(true);   
            this.alertGeneral.toggle(true, 'El impuesto 1 del artículo '+this.nombre+' fue modificado con éxito.', 'success');
            
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualizarUbicacionBasica(){
        if(this.getField('ubicacion').valid() 
        && this.getField('seccion').valid() 
        && this.getField('bandeja').valid() 
        && this.getField('lugar').valid()){
                let datos={
                    datos: {
                        ubicacion:this.getField('ubicacion').getValue(), 
                        seccion:this.getField('seccion').getValue(), 
                        bandeja:this.getField('bandeja').getValue(), 
                        lugar:this.getField('lugar').getValue(), 
                        value: this.codigo 
                    }};
                this.generalFormatPmv = { tipo_servicio: 'maes-modificarproducto', operacion: 'modificarubicacionbasica', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successUbicacionBasica,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
        }
    }

    successUbicacionBasica(data){
        if (data.estado_p === 200 )
        {
            this.getField('aceptar7').setDisabled(true);
            this.alertGeneral.toggle(true, 'La ubicación del artículo '+this.nombre+' fue modificado con éxito.', 'success');
            
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    limpiar(){
        this.establecerPropiedades('2,3,4,5,7','visible','true');
        this.establecerPropiedades('11,17,19,25,27,29,31,33,35,37,39,41,43,45,47,49,55,61,63,65,67,69,71,73,75,77,79,81,83,85,91,101,102,103,104','value','');
        this.eliminarvalid();
        this.establecerPropiedades('11,17,19,27,29,33,35,39,41,45,49,55,61,63,65,67,69,71,73,75,77,79,81,83,85,91,101,102,103,104','disable','true');

        this.getField('aceptar').setDisabled(true); 
        this.getField('aceptar1').setDisabled(true); 
        this.getField('aceptar2').setDisabled(true);  
        this.getField('aceptar4').setDisabled(true); 
        this.getField('aceptar5').setDisabled(true); 
        this.getField('aceptar6').setDisabled(true); 
        this.getField('aceptar7').setDisabled(true); 
    }

    eliminarvalid()
    {   
        this.getField('peso').valid(false); 

        this.getField('minimo').valid(false); 
        this.getField('maximo').valid(false); 

        this.getField('financiero').valid(false);
        this.getField('rentabilidad').valid(false);
        this.getField('financiero1').valid(false);
        this.getField('rentabilidad1').valid(false);
        this.getField('financiero2').valid(false);
        this.getField('rentabilidad2').valid(false);
        this.getField('financiero3').valid(false);
        this.getField('financiero4').valid(false);

        this.getField('foco').valid(false);

        this.getField('precio1').valid(false);
        this.getField('precio2').valid(false);
        this.getField('precio3').valid(false);
        this.getField('precio4').valid(false);
        this.getField('precio5').valid(false);

        this.getField('impuesto1').valid(false);

        this.getField('ubicacion').valid(false);
        this.getField('seccion').valid(false);
        this.getField('bandeja').valid(false);
        this.getField('lugar').valid(false);
    }

    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }
}
FormJaivana.addController("maes-modificarproducto",CustomModificarArticulos);
export default CustomModificarArticulos