import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomManejoOperaciones
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin Bernal
 * @version: jdesk_1.01.0001
 **/
class CustomManejoOperaciones extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.llenarencabezado           = this.llenarencabezado.bind(this);
        this.llenarTipo         =   this.llenarTipo.bind(this);

        this.arrayCampos.set(4,'contenedorDatos1');
        this.arrayCampos.set(5,'codigo_banco');
        this.arrayCampos.set(6,'nombre_banco');
        this.arrayCampos.set(7,'seleccionar');
        this.arrayCampos.set(8,'label1');
        this.arrayCampos.set(9,'codigo_operacion');
        this.arrayCampos.set(10,'descripcion');
        this.arrayCampos.set(11,'tipo');
        this.arrayCampos.set(12,'aceptar');
        this.arrayCampos.set(13,'cancelar');
        this.arrayCampos.set(14,'bancos_id');
        this.arrayCampos.set(20,'contenedorDatos2');
        this.arrayCampos.set(21,'lista1');
        this.arrayCampos.set(23,'codigo_banco2');
        this.arrayCampos.set(24,'nombre_banco2');
        this.arrayCampos.set(25,'seleccionar2');
        this.arrayCampos.set(28,'label2');
        this.arrayCampos.set(29,'cuenta_debito1');
        this.arrayCampos.set(30,'nombre_debito1');
        this.arrayCampos.set(31,'cuenta_credito1');
        this.arrayCampos.set(32,'nombre_credito1');
        this.arrayCampos.set(33,'nit_identificado_unica_sede_afecta_cartera');
        this.arrayCampos.set(34,'nit_identificado_unica_sede_codigo_documento');
        this.arrayCampos.set(35,'nombre_documento1');
        this.arrayCampos.set(36,'nit_identificado_unica_sede_enviar_bolsa_global');
        this.arrayCampos.set(40,'nit_identificado_unica_sede_cuenta_debito');
        this.arrayCampos.set(41,'nit_identificado_unica_sede_cuenta_credito');
        this.arrayCampos.set(42,'oculto2');
        this.arrayCampos.set(43,'aceptar2');
        this.arrayCampos.set(44,'cancelar2');
        this.arrayCampos.set(50,'contenedorDatos3');
        this.arrayCampos.set(51,'lista2');
        this.arrayCampos.set(53,'codigo_banco3');
        this.arrayCampos.set(54,'nombre_banco3');
        this.arrayCampos.set(55,'seleccionar3');
        this.arrayCampos.set(56,'label3');
        this.arrayCampos.set(57,'cuenta_debito2');
        this.arrayCampos.set(60,'nombre_debito2');
        this.arrayCampos.set(61,'cuenta_credito2');
        this.arrayCampos.set(62,'nombre_credito2');
        this.arrayCampos.set(63,'nit_identificado_varias_sedes_afecta_cartera');
        this.arrayCampos.set(64,'nit_identificado_varias_sedes_codigo_documento');
        this.arrayCampos.set(65,'nombre_documento2');
        this.arrayCampos.set(66,'nit_identificado_varias_sedes_enviar_bolsa_global');
        this.arrayCampos.set(67,'oculto3');
        this.arrayCampos.set(68,'aceptar3');
        this.arrayCampos.set(69,'cancelar3');
        this.arrayCampos.set(70,'nit_identificado_varias_sedes_cuenta_debito');
        this.arrayCampos.set(71,'nit_identificado_varias_sedes_cuenta_credito');
        this.arrayCampos.set(80,'contenedorDatos4');
        this.arrayCampos.set(81,'lista3');
        this.arrayCampos.set(83,'codigo_banco4');
        this.arrayCampos.set(84,'nombre_banco4');
        this.arrayCampos.set(85,'seleccionar4');
        this.arrayCampos.set(86,'label4');
        this.arrayCampos.set(87,'cuenta_debito3');
        this.arrayCampos.set(88,'nombre_debito3');
        this.arrayCampos.set(89,'cuenta_credito3');
        this.arrayCampos.set(90,'nombre_credito3');
        this.arrayCampos.set(92,'nit_no_identificado_afecta_cartera');
        this.arrayCampos.set(94,'nit_no_identificado_codigo_documento');
        this.arrayCampos.set(95,'nombre_documento3');
        this.arrayCampos.set(96,'nit_no_identificado_enviar_bolsa_global');
        this.arrayCampos.set(97,'aceptar4');
        this.arrayCampos.set(98,'cancelar4');
        this.arrayCampos.set(99,'cancelar3');
        this.arrayCampos.set(100,'nit_no_identificado_cuenta_debito');
        this.arrayCampos.set(101,'nit_no_identificado_cuenta_credito');

        this.arrayObjetos.set(4,'contenedorDatos1');
        this.arrayObjetos.set(5,'codigo_banco');
        this.arrayObjetos.set(6,'nombre_banco');
        this.arrayObjetos.set(7,'seleccionar');
        this.arrayObjetos.set(8,'label1');
        this.arrayObjetos.set(9,'codigo_operacion');
        this.arrayObjetos.set(10,'descripcion');
        this.arrayObjetos.set(11,'tipo');
        this.arrayObjetos.set(12,'aceptar');
        this.arrayObjetos.set(13,'cancelar');
        this.arrayObjetos.set(14,'bancos_id');
        this.arrayObjetos.set(20,'contenedorDatos2');
        this.arrayObjetos.set(21,'lista1');
        this.arrayObjetos.set(23,'codigo_banco2');
        this.arrayObjetos.set(24,'nombre_banco2');
        this.arrayObjetos.set(25,'seleccionar2');
        this.arrayObjetos.set(28,'label2');
        this.arrayObjetos.set(29,'cuenta_debito1');
        this.arrayObjetos.set(30,'nombre_debito1');
        this.arrayObjetos.set(31,'cuenta_credito1');
        this.arrayObjetos.set(32,'nombre_credito1');
        this.arrayObjetos.set(33,'nit_identificado_unica_sede_afecta_cartera');
        this.arrayObjetos.set(34,'nit_identificado_unica_sede_codigo_documento');
        this.arrayObjetos.set(35,'nombre_documento1');
        this.arrayObjetos.set(36,'nit_identificado_unica_sede_enviar_bolsa_global');
        this.arrayObjetos.set(40,'nit_identificado_unica_sede_cuenta_debito');
        this.arrayObjetos.set(41,'nit_identificado_unica_sede_cuenta_credito');
        this.arrayObjetos.set(42,'oculto2');
        this.arrayObjetos.set(43,'aceptar2');
        this.arrayObjetos.set(44,'cancelar2');
        this.arrayObjetos.set(50,'contenedorDatos3');
        this.arrayObjetos.set(51,'lista2');
        this.arrayObjetos.set(53,'codigo_banco3');
        this.arrayObjetos.set(54,'nombre_banco3');
        this.arrayObjetos.set(55,'seleccionar3');
        this.arrayObjetos.set(56,'label3');
        this.arrayObjetos.set(57,'cuenta_debito2');
        this.arrayObjetos.set(60,'nombre_debito2');
        this.arrayObjetos.set(61,'cuenta_credito2');
        this.arrayObjetos.set(62,'nombre_credito2');
        this.arrayObjetos.set(63,'nit_identificado_varias_sedes_afecta_cartera');
        this.arrayObjetos.set(64,'nit_identificado_varias_sedes_codigo_documento');
        this.arrayObjetos.set(65,'nombre_documento2');
        this.arrayObjetos.set(66,'nit_identificado_varias_sedes_enviar_bolsa_global');
        this.arrayObjetos.set(67,'oculto3');
        this.arrayObjetos.set(68,'aceptar3');
        this.arrayObjetos.set(69,'cancelar3');
        this.arrayObjetos.set(70,'nit_identificado_varias_sedes_cuenta_debito');
        this.arrayObjetos.set(71,'nit_identificado_varias_sedes_cuenta_credito');
        this.arrayObjetos.set(80,'contenedorDatos4');
        this.arrayObjetos.set(81,'lista3');
        this.arrayObjetos.set(83,'codigo_banco4');
        this.arrayObjetos.set(84,'nombre_banco4');
        this.arrayObjetos.set(85,'seleccionar4');
        this.arrayObjetos.set(86,'label4');
        this.arrayObjetos.set(87,'cuenta_debito3');
        this.arrayObjetos.set(88,'nombre_debito3');
        this.arrayObjetos.set(89,'cuenta_credito3');
        this.arrayObjetos.set(90,'nombre_credito3');
        this.arrayObjetos.set(92,'nit_no_identificado_afecta_cartera');
        this.arrayObjetos.set(94,'nit_no_identificado_codigo_documento');
        this.arrayObjetos.set(95,'nombre_documento3');
        this.arrayObjetos.set(96,'nit_no_identificado_enviar_bolsa_global');
        this.arrayObjetos.set(97,'aceptar4');
        this.arrayObjetos.set(98,'cancelar4');
        this.arrayObjetos.set(99,'cancelar3');
        this.arrayObjetos.set(100,'nit_no_identificado_cuenta_debito');
        this.arrayObjetos.set(101,'nit_no_identificado_cuenta_credito');

        this.cargarSegundaVentana   =   this.cargarSegundaVentana.bind(this);
        this.cargarTerceraVentana   =   this.cargarTerceraVentana.bind(this);
        this.cargarCuartaVentana   =   this.cargarCuartaVentana.bind(this);
        this.validarUnique   =   this.validarUnique.bind(this);
        this.successValidarUnique   =   this.successValidarUnique.bind(this);
        this.crear   =   this.crear.bind(this);
        this.successCrear   =   this.successCrear.bind(this);
        this.limpiar    =   this.limpiar.bind(this);
        this.validarUniquePrimerPage =  this.validarUniquePrimerPage.bind(this);
        this.successValidarUniquePrimerPage = this.successValidarUniquePrimerPage.bind(this);
        this.validarCuentasDebitoCreditoPage2    =  this.validarCuentasDebitoCreditoPage2.bind(this);
        this.validarCuentasDebitoCreditoPage3    =  this.validarCuentasDebitoCreditoPage3.bind(this);
        this.validarCuentasDebitoCreditoPage4    =  this.validarCuentasDebitoCreditoPage4.bind(this);
        this.consultarPorId    =  this.consultarPorId.bind(this);
        this.sucessConsultarPorId    =  this.sucessConsultarPorId.bind(this);
        this.actualizarRegistro    =  this.actualizarRegistro.bind(this);
        this.successActualizarRegistro    =  this.successActualizarRegistro.bind(this);

        
    }

    initForm(){
        console.log('Formulario CustomManejoOperaciones,  @version: jdesk_1.01.0001, @author:Anderson Acevedo Briñez, @updateBy Jose Albeiro Marin Bernal')
            
            this.llenarTipo();
            this.getField('operacion').setDisabled(true);
            this.getField('TabJaivana_85').setDisabledButtonTab(true);

            if(this.props.data[0].opcion_sub_seccion === 1){
                this.getField('modal_modificar').setVisible(false);
                this.establecerPropiedades('5,6,10,11,14','value','');
                this.opcionAgregar();
            }else if(this.props.data[0].opcion_sub_seccion === 2){
                this.establecerPropiedades('5,6,9,10,11,12,14','disable','true');
                this.getField('modal_modificar').setVisible(true);
                this.opcionModificar();
            }
            this.getField('label1').setValue('ander')
            this.getField('aceptar').setClick(()=>{
                if(this.getField('operacion').getValue()==='AGREGAR'){
                    this.validarUniquePrimerPage();
                }else{
                    this.cargarSegundaVentana();
                }
            });
            this.getField('cancelar').setClick(()=>{
                if(this.getField('operacion').getValue()==='MODIFICAR'){
                    this.limpiar();
                    this.establecerPropiedades('5,6,9,10,11,12,14','disable','true');
                    this.getField('modal_modificar').setVisible(true);
                }else{
                    this.establecerPropiedades('5,6,9,10,11,14','value','');
                }
                this.getField('tipo').setValue('N');
            });
            //segunda ventana
            this.getField('cancelar2').setClick(()=>{
                this.establecerPropiedades('28,29,30,31,32,33,34,35,36,40,41','value','');
            });
            this.getField('aceptar2').setClick(this.validarCuentasDebitoCreditoPage2);

            //tercera ventana
            this.getField('cancelar3').setClick(()=>{
                this.establecerPropiedades('57,60,61,62,63,64,65,66,67,70,71','value','');
            });
            this.getField('aceptar3').setClick(this.validarCuentasDebitoCreditoPage3);

            //tercera ventana
            this.getField('cancelar4').setClick(()=>{
                this.establecerPropiedades('87,88,89,90,92,94,95,96,97,100,101','value','');
            });
            this.getField('aceptar4').setClick(this.validarCuentasDebitoCreditoPage4);


            //desactivar buscadores codigo banco
            this.getField('codigo_banco2').setDisabled(true);
            this.getField('codigo_banco3').setDisabled(true);
            this.getField('codigo_banco4').setDisabled(true);
            this.getField('nombre_banco2').setDisabled(true);
            this.getField('nombre_banco3').setDisabled(true);
            this.getField('nombre_banco4').setDisabled(true);

            this.getField('TabJaivana_85').setClick(()=>{
                this.limpiar();
                if(this.getField('operacion').getValue()==='MODIFICAR'){
                    this.establecerPropiedades('5,6,9,10,11,12,14','disable','true');
                    this.getField('modal_modificar').setVisible(true);
                    this.opcionModificar();
                }
            });

            this.getField('seleccionar_modal').setClick(()=>{
                if(this.getField('codigo_banco_modificar').valid() && this.getField('nombre_banco_modificar').valid()){
                    if(this.getField('buscador_codigo_operacion').valid() && this.getField('buscador_descripcion').valid()){
                        this.consultarPorId();
                    }
                }
            });

            this.getField('bancos_id').setOnChange(()=>{
                if(this.opcion==='MODIFICAR'){
                    if(this.getField('bancos_id').getValue()!==''){
                        this.getField('buscador_codigo_operacion').setDisabled(false);
                        this.getField('buscador_descripcion').setDisabled(false);
                    }else{
                        this.getField('buscador_codigo_operacion').setDisabled(true);
                        this.getField('buscador_descripcion').setDisabled(true);
                        this.getField('buscador_codigo_operacion').setValue('');
                        this.getField('buscador_descripcion').setValue('');
                    }

                }
            });

            this.getField('nit_identificado_unica_sede_afecta_cartera').setOnChange(()=>{
                    if(this.getField('nit_identificado_unica_sede_afecta_cartera').getValue()===2){
                        this.getField('nit_identificado_unica_sede_enviar_bolsa_global').setValue(1);
                        this.getField('nit_identificado_unica_sede_enviar_bolsa_global').setError(false,'');
                    }
            });
            this.getField('nit_identificado_varias_sedes_afecta_cartera').setOnChange(()=>{
                if(this.getField('nit_identificado_varias_sedes_afecta_cartera').getValue()===2){
                    this.getField('nit_identificado_varias_sedes_enviar_bolsa_global').setValue(1);
                    this.getField('nit_identificado_varias_sedes_enviar_bolsa_global').setError(false,'');
                }
            });
            this.getField('nit_no_identificado_afecta_cartera').setOnChange(()=>{
                if(this.getField('nit_no_identificado_afecta_cartera').getValue()===2){
                    this.getField('nit_no_identificado_enviar_bolsa_global').setValue(1);
                    this.getField('nit_no_identificado_enviar_bolsa_global').setError(false,'');
                }
                    
            });

            this.getField('cancelar_modal').setClick(()=>{
                this.opcionModificar();
            });
    }

    limpiar(){
        
        this.establecerPropiedades('5,6,9,10,11,14','value','');
        this.establecerPropiedades('23,24,28,29,30,31,32,33,34,35,40,41','value','');
        this.establecerPropiedades('57,60,61,62,63,64,65,66,67,70,71','value','');
        this.establecerPropiedades('87,88,89,90,92,94,95,96,97,100,101','value','');
        this.llenarencabezado();
        if(this.getField('operacion').getValue()==='MODIFICAR'){
            this.opcionModificar();

        }
        this.getField('TabJaivana_85').reInit();

      }
    
      validarUniquePrimerPage(){
        if(//this.getField('codigo_banco4').valid()
            //&&  this.getField('nombre_banco4').valid()
              this.getField('nombre_banco').valid()
            &&  this.getField('codigo_operacion').valid()
            &&  this.getField('descripcion').valid()
            &&  this.getField('tipo').valid()){
                let datos={ 
                    datos: {
                        bancos_id:this.getField('bancos_id').getValue(),
                        codigo_operacion:this.getField('codigo_operacion').getValue()
                    }
                };
                this.generalFormatPmv = { tipo_servicio: 'maes-manejooperaciones', operacion: '20', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successValidarUniquePrimerPage,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }

        
    }

    successValidarUniquePrimerPage(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El código ya existe, no se permite duplicar.', 'error'); 
        }
        else 
        {
           if(data.estado_p===404) {
                this.cargarSegundaVentana();
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validarCuentasDebitoCreditoPage4(){
        if(this.getField('nombre_debito3').valid() &&
        this.getField('nombre_credito3').valid() &&  
        this.getField('nombre_documento3').valid()
        &&  this.getField('nombre_documento2').valid()
        &&  this.getField('nit_no_identificado_afecta_cartera').valid()
        &&  this.getField('nit_no_identificado_enviar_bolsa_global').valid())
        
        if (this.getField('cuenta_debito3').getValue() === this.getField('cuenta_credito3').getValue()) {
            this.alertGeneral.toggle(true, 'Cuenta débito y cuenta crédito no pueden ser iguales.', 'error'); 
        }else{
            if(this.getField('operacion').getValue()==='MODIFICAR'){
                this.actualizarRegistro();    
            }else{
                this.validarUnique();
            }
        }
    }

    validarUnique(){
        if(//this.getField('codigo_banco4').valid()
            //&&  this.getField('nombre_banco4').valid()
              this.getField('cuenta_debito3').valid()
            &&  this.getField('nombre_debito3').valid()
            &&  this.getField('cuenta_credito3').valid()
            &&  this.getField('nombre_credito3').valid()
            &&  this.getField('nit_no_identificado_codigo_documento').valid()
            &&  this.getField('nombre_documento3').valid()
            &&  this.getField('nit_no_identificado_enviar_bolsa_global').valid()
            &&  this.getField('nit_no_identificado_cuenta_debito').valid()
            &&  this.getField('nit_no_identificado_cuenta_credito').valid()){
                let datos={ 
                    datos: {
                        bancos_id:this.getField('bancos_id').getValue(),
                        codigo_operacion:this.getField('codigo_operacion').getValue()
                    }
                };
                this.generalFormatPmv = { tipo_servicio: 'maes-manejooperaciones', operacion: '20', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successValidarUnique,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }

        
    }

    successValidarUnique(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El código ya existe, no se permite duplicar.', 'error'); 
        }
        else 
        {
           if(data.estado_p===404) {
                this.crear();
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    crear()
    {
        let datos = {  datos: { 
            bancos_id   :   this.getField('bancos_id').getValue(), 
            codigo_operacion:   this.getField('codigo_operacion').getValue(), 
            descripcion:   this.getField('descripcion').getValue(), 
            tipo    :   this.getField('tipo').getValue(),  
            nit_identificado_unica_sede_cuenta_debito:   this.getField('nit_identificado_unica_sede_cuenta_debito').getValue(), 
            nit_identificado_unica_sede_cuenta_credito:   this.getField('nit_identificado_unica_sede_cuenta_credito').getValue(), 
            nit_identificado_unica_sede_afecta_cartera:   this.getField('nit_identificado_unica_sede_afecta_cartera').getValue(), 
            nit_identificado_unica_sede_codigo_documento:   this.getField('nit_identificado_unica_sede_codigo_documento').getValue(),
            nit_identificado_unica_sede_enviar_bolsa_global: this.getField('nit_identificado_unica_sede_enviar_bolsa_global').getValue(), 
            nit_identificado_varias_sedes_cuenta_debito:   this.getField('nit_identificado_varias_sedes_cuenta_debito').getValue(), 
            nit_identificado_varias_sedes_cuenta_credito:   this.getField('nit_identificado_varias_sedes_cuenta_credito').getValue(), 
            nit_identificado_varias_sedes_afecta_cartera:   this.getField('nit_identificado_varias_sedes_afecta_cartera').getValue(), 
            nit_identificado_varias_sedes_codigo_documento:   this.getField('nit_identificado_varias_sedes_codigo_documento').getValue(), 
            nit_identificado_varias_sedes_enviar_bolsa_global:   this.getField('nit_identificado_varias_sedes_enviar_bolsa_global').getValue(), 
            nit_no_identificado_cuenta_debito:   this.getField('nit_no_identificado_cuenta_debito').getValue(), 
            nit_no_identificado_cuenta_credito:   this.getField('nit_no_identificado_cuenta_credito').getValue(), 
            nit_no_identificado_afecta_cartera:   this.getField('nit_no_identificado_afecta_cartera').getValue(), 
            nit_no_identificado_codigo_documento:   this.getField('nit_no_identificado_codigo_documento').getValue(), 
            nit_no_identificado_enviar_bolsa_global :   this.getField('nit_no_identificado_enviar_bolsa_global').getValue(), 
        }
        }

        this.generalFormatPmv = { tipo_servicio: 'maes-manejooperaciones', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrear,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    actualizarRegistro()
    {
        let datos = {  datos: { 
            bancos_id   :   this.getField('bancos_id').getValue(), 
            codigo_operacion:   this.getField('codigo_operacion').getValue(), 
            descripcion:   this.getField('descripcion').getValue(), 
            tipo    :   this.getField('tipo').getValue(),  
            nit_identificado_unica_sede_cuenta_debito:   this.getField('nit_identificado_unica_sede_cuenta_debito').getValue(), 
            nit_identificado_unica_sede_cuenta_credito:   this.getField('nit_identificado_unica_sede_cuenta_credito').getValue(), 
            nit_identificado_unica_sede_afecta_cartera:   this.getField('nit_identificado_unica_sede_afecta_cartera').getValue(), 
            nit_identificado_unica_sede_codigo_documento:   this.getField('nit_identificado_unica_sede_codigo_documento').getValue(),
            nit_identificado_unica_sede_enviar_bolsa_global: this.getField('nit_identificado_unica_sede_enviar_bolsa_global').getValue(), 
            nit_identificado_varias_sedes_cuenta_debito:   this.getField('nit_identificado_varias_sedes_cuenta_debito').getValue(), 
            nit_identificado_varias_sedes_cuenta_credito:   this.getField('nit_identificado_varias_sedes_cuenta_credito').getValue(), 
            nit_identificado_varias_sedes_afecta_cartera:   this.getField('nit_identificado_varias_sedes_afecta_cartera').getValue(), 
            nit_identificado_varias_sedes_codigo_documento:   this.getField('nit_identificado_varias_sedes_codigo_documento').getValue(), 
            nit_identificado_varias_sedes_enviar_bolsa_global:   this.getField('nit_identificado_varias_sedes_enviar_bolsa_global').getValue(), 
            nit_no_identificado_cuenta_debito:   this.getField('nit_no_identificado_cuenta_debito').getValue(), 
            nit_no_identificado_cuenta_credito:   this.getField('nit_no_identificado_cuenta_credito').getValue(), 
            nit_no_identificado_afecta_cartera:   this.getField('nit_no_identificado_afecta_cartera').getValue(), 
            nit_no_identificado_codigo_documento:   this.getField('nit_no_identificado_codigo_documento').getValue(), 
            nit_no_identificado_enviar_bolsa_global :   this.getField('nit_no_identificado_enviar_bolsa_global').getValue(),
            bancos_operaciones_id :   this.getField('id_bancos_operaciones').getValue()
        }
        }

        this.generalFormatPmv = { tipo_servicio: 'maes-manejooperaciones', operacion: '6', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successActualizarRegistro,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successActualizarRegistro(data)
    {
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El banco operación fue con modificado con éxito', 'success');
            this.limpiar();
            this.establecerPropiedades('5,6,9,10,11,12,14','disable','true');
            this.getField('modal_modificar').setVisible(true);
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    successCrear(data)
    {
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El banco operación fue con creado exito', 'success');
            this.limpiar();
            this.getField('TabJaivana_85').reInit();

        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }


    cargarSegundaVentana(){
        if(this.getField('codigo_banco').valid()
            && this.getField('nombre_banco').valid()
            &&  this.getField('codigo_operacion').valid()
            &&  this.getField('descripcion').valid()
            &&  this.getField('tipo').valid()
            &&  this.getField('bancos_id').valid()){
                this.llenarencabezado();
                this.getField('TabJaivana_85').setDisabledButtonTab(false);
                this.getField('TabJaivana_85').setNextTab();
            }
        
    }
    validarCuentasDebitoCreditoPage2(){
        if(this.getField('nombre_debito1').valid() &&
        this.getField('nombre_credito1').valid() &&  
        this.getField('nit_identificado_unica_sede_afecta_cartera').valid() &&
        this.getField('nit_identificado_unica_sede_enviar_bolsa_global').valid()
        &&  this.getField('nombre_documento1').valid())
        
        if (this.getField('cuenta_debito1').getValue() === this.getField('cuenta_credito1').getValue()) {
            this.alertGeneral.toggle(true, 'Cuenta débito y cuenta crédito no pueden ser iguales.', 'error'); 
        }else{
            this.cargarTerceraVentana();
        }
    }

    cargarTerceraVentana(){
        if(this.getField('codigo_banco2').valid()
            &&  this.getField('nombre_banco2').valid()
            &&  this.getField('cuenta_debito1').valid()
            &&  this.getField('nombre_debito1').valid()
            &&  this.getField('cuenta_credito1').valid()
            &&  this.getField('nombre_credito1').valid()
            &&  this.getField('nit_identificado_unica_sede_afecta_cartera').valid()
            &&  this.getField('nit_identificado_unica_sede_codigo_documento').valid()
            &&  this.getField('nombre_documento1').valid()
            &&  this.getField('nit_identificado_unica_sede_cuenta_debito').valid()
            &&  this.getField('nit_identificado_unica_sede_cuenta_credito').valid()){
                this.getField('TabJaivana_85').setNextTab();
            }
        
    }

    validarCuentasDebitoCreditoPage3(){
        if(this.getField('nombre_debito2').valid() &&
        this.getField('nombre_credito2').valid() &&  
        this.getField('nit_identificado_varias_sedes_afecta_cartera').valid()
        &&  this.getField('nombre_documento2').valid()
        &&  this.getField('nit_identificado_varias_sedes_enviar_bolsa_global').valid())
        
        if (this.getField('cuenta_debito2').getValue() === this.getField('cuenta_credito2').getValue()) {
            this.alertGeneral.toggle(true, 'Cuenta débito y cuenta crédito no pueden ser iguales.', 'error'); 
        }else{
            this.cargarCuartaVentana();
        }
    }

    cargarCuartaVentana(){
        if(this.getField('codigo_banco3').valid()
            &&  this.getField('nombre_banco3').valid()
            &&  this.getField('cuenta_debito2').valid()
            &&  this.getField('nombre_debito2').valid()
            &&  this.getField('cuenta_credito2').valid()
            &&  this.getField('nombre_credito2').valid()
            &&  this.getField('nit_identificado_varias_sedes_afecta_cartera').valid()
            &&  this.getField('nit_identificado_varias_sedes_codigo_documento').valid()
            &&  this.getField('nombre_documento2').valid()
            &&  this.getField('nit_identificado_varias_sedes_enviar_bolsa_global').valid()
            &&  this.getField('nit_identificado_varias_sedes_cuenta_debito').valid()
            &&  this.getField('nit_identificado_varias_sedes_cuenta_credito').valid()){
                this.getField('TabJaivana_85').setNextTab();
            }
        
    }

    llenarTipo()
    {

        let opciones = [];
        let data2 = {
            "data": [
                {
                    "codigo": 'N',
                    "descripcion": 'Ninguno',
                },
                {
                    "codigo": 'P',
                    "descripcion": 'Por Pagar',
                },{
                    "codigo": 'C',
                    "descripcion": 'Recaudo cliente',
                }
            ]
        }
        data2.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.codigo;
            dataOp['text'] = item.descripcion;
            dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
            opciones.push(dataOp);
        });
        this.getField('tipo').setOptions(opciones);
    }

    llenarencabezado()
    {
        let datosFicha = [];
        let data2 = 
                {
                    "codigo": this.getField('codigo_operacion').getValue(),
                    "nombre": this.getField('descripcion').getValue(),
                };
        datosFicha.push(data2);
        this.getField('lista1').setItemsFichas(datosFicha);
        this.getField('lista2').setItemsFichas(datosFicha);
        this.getField('lista3').setItemsFichas(datosFicha);
    }


    opcionAgregar(){
        this.opcion="AGREGAR";
        this.getField('operacion').setValue(this.opcion);
    }

    opcionModificar(){
        this.opcion="MODIFICAR";
        this.getField('buscador_codigo_operacion').setValue('');
        this.getField('buscador_descripcion').setValue('');
        this.getField('codigo_banco_modificar').setValue('');
        this.getField('nombre_banco_modificar').setValue('');
        this.getField('buscador_codigo_operacion').setError(false,'');
        this.getField('buscador_descripcion').setError(false,'');
        this.getField('codigo_banco_modificar').setError(false,'');
        this.getField('nombre_banco_modificar').setError(false,'');
        this.getField('buscador_codigo_operacion').setDisabled(true);
        this.getField('buscador_descripcion').setDisabled(true);
        this.getField('operacion').setValue(this.opcion);
    }

    consultarPorId()
    {
        this.getField('seleccionar_modal').setDisabled(true);
        this.getField('cancelar_modal').setDisabled(true);
        let datos = {  datos: { 
            bancos_operaciones_id   :   this.getField('id_bancos_operaciones').getValue(), 
        }}

        this.generalFormatPmv = { tipo_servicio: 'maes-manejooperaciones', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessConsultarPorId,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    sucessConsultarPorId(data)
    {
        if (data.estado_p === 200 )
        {   
            this.getField('bancos_id').setValue(data.data[0].bancos_id);
            this.getField('codigo_banco').setValue(data.data[0].codigo_banco);
            this.getField('nombre_banco').setValue(data.data[0].nombre_banco);
            this.getField('codigo_operacion').setValue(data.data[0].codigo_operacion);
            this.getField('descripcion').setValue(data.data[0].descripcion);
            this.getField('tipo').setValue(data.data[0].tipo);
            this.getField('nit_identificado_unica_sede_cuenta_debito').setValue(data.data[0].nit_identificado_unica_sede_cuenta_debito);
            this.getField('cuenta_debito1').setValue(data.data[0].cuenta_debito1);
            this.getField('nombre_debito1').setValue(data.data[0].nombre_debito1);
            this.getField('nit_identificado_unica_sede_cuenta_credito').setValue(data.data[0].nit_identificado_unica_sede_cuenta_credito);
            this.getField('cuenta_credito1').setValue(data.data[0].cuenta_credito1);
            this.getField('nombre_credito1').setValue(data.data[0].nombre_credito1);
            this.getField('nit_identificado_unica_sede_afecta_cartera').setValue(data.data[0].nit_identificado_unica_sede_afecta_cartera);
            this.getField('nit_identificado_unica_sede_codigo_documento').setValue(data.data[0].nit_identificado_unica_sede_codigo_documento);
            this.getField('nit_identificado_unica_sede_enviar_bolsa_global').setValue(data.data[0].nit_identificado_unica_sede_enviar_bolsa_global);
            this.getField('nombre_documento1').setValue(data.data[0].nombre_documento1);
            this.getField('nit_identificado_varias_sedes_cuenta_debito').setValue(data.data[0].nit_identificado_varias_sedes_cuenta_debito);
            this.getField('cuenta_debito2').setValue(data.data[0].cuenta_debito2);
            this.getField('nombre_debito2').setValue(data.data[0].nombre_debito2);
            this.getField('nit_identificado_varias_sedes_cuenta_credito').setValue(data.data[0].nit_identificado_varias_sedes_cuenta_credito);
            this.getField('cuenta_credito2').setValue(data.data[0].cuenta_credito2);
            this.getField('nombre_credito2').setValue(data.data[0].nombre_credito2);
            this.getField('nit_identificado_varias_sedes_afecta_cartera').setValue(data.data[0].nit_identificado_varias_sedes_afecta_cartera);
            this.getField('nit_identificado_varias_sedes_codigo_documento').setValue(data.data[0].nit_identificado_varias_sedes_codigo_documento);
            this.getField('nombre_documento2').setValue(data.data[0].nombre_documento2);
            this.getField('nit_identificado_varias_sedes_enviar_bolsa_global').setValue(data.data[0].nit_identificado_varias_sedes_enviar_bolsa_global);
            this.getField('nit_no_identificado_cuenta_debito').setValue(data.data[0].nit_no_identificado_cuenta_debito);
            this.getField('cuenta_debito3').setValue(data.data[0].cuenta_debito3);
            this.getField('nombre_debito3').setValue(data.data[0].nombre_debito3);
            this.getField('nit_no_identificado_cuenta_credito').setValue(data.data[0].nit_no_identificado_cuenta_credito);
            this.getField('cuenta_credito3').setValue(data.data[0].cuenta_credito3);
            this.getField('nombre_credito3').setValue(data.data[0].nombre_credito3);
            
            this.getField('nit_no_identificado_codigo_documento').setValue(data.data[0].nit_no_identificado_codigo_documento);
            this.getField('nombre_documento3').setValue(data.data[0].nombre_documento3);
            this.getField('nit_no_identificado_afecta_cartera').setValue(data.data[0].nit_no_identificado_afecta_cartera);
            this.getField('nit_no_identificado_enviar_bolsa_global').setValue(data.data[0].nit_no_identificado_enviar_bolsa_global); 
            this.getField('codigo_banco2').setValue(data.data[0].codigo_banco);
            this.getField('nombre_banco2').setValue(data.data[0].nombre_banco);
            this.getField('codigo_banco3').setValue(data.data[0].codigo_banco);
            this.getField('nombre_banco3').setValue(data.data[0].nombre_banco);
            this.getField('codigo_banco4').setValue(data.data[0].codigo_banco);
            this.getField('nombre_banco4').setValue(data.data[0].nombre_banco);
            this.getField('codigo_operacion').setDisabled(true);
            this.getField('modal_modificar').handleClose(false);



            this.llenarencabezado();
            this.establecerPropiedades('5,6,10,11,12,14','disable','false');

        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
        this.getField('seleccionar_modal').setDisabled(false);
        this.getField('cancelar_modal').setDisabled(false);
    }
    

    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }
}
FormJaivana.addController("maes-manejooperaciones",CustomManejoOperaciones);
export default CustomManejoOperaciones