import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomConsultarRecalcularTransacciones
 * @author: Anderson Acevedo
 * @version: jdesk_1.01.0003
 **/
class CustomConsultarRecalcularTransacciones extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.procesarRecalcular                 = this.procesarRecalcular.bind(this);
        this.sucessProcesarRecalcular                 = this.sucessProcesarRecalcular.bind(this);
        this.validarfechasConsulta          = this.validarfechasConsulta.bind(this);
        this.limpiar                        = this.limpiar.bind(this);       

        this.mostrarMensajeNoHayDatos       = this.mostrarMensajeNoHayDatos.bind(this);
        this.traerPropiedades =this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades =this.sucesstraerPropiedades.bind(this);
        this.mostrarMensajeAdicional =this.mostrarMensajeAdicional.bind(this);
        this.numero_decimales=0;
        this.fecha_hoy='';
        this.fecha_inicio='';
        this.existe='';
        
    }

    initForm() {
        console.log("Formulario CustomConsultarRecalcularTransacciones,  @version: jdesk_1.01.0003, @author: Anderson Acevedo");
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedades();
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.getField("desde").setValue(this.fecha_inicio);
        this.getField("aceptar").setClick(this.procesarRecalcular);
        this.getField("desde").setOnChange(this.validarfechasConsulta);
        this.getField("hasta").setOnChange(this.validarfechasConsulta);


    }

    traerPropiedades(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'inve-recalculartransacciones', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }
    
    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales=data.data.numero_decimales;
            this.existe=data.data.existe;
            if(this.existe==='S'){
                this.getField('desde').setVisible(true);
                this.getField('hasta').setVisible(true);
                this.getField('aceptar').setVisible(true);
            }else{
                this.mostrarMensajeAdicional('Opción no configurada.');
            }
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }


    validarfechasConsulta(){
        this.limpiar();
        let fecha_actual =new Date().toISOString().split('T')[0];
        let consultar_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];

        if(consultar_desde > consultar_hasta){
            this.getField('aceptar').setDisabled(true);
            this.getField("desde").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }else{
            this.getField('aceptar').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }

        if(consultar_hasta > fecha_actual){
            this.getField('aceptar').setDisabled(true);
            this.getField("hasta").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }else{
            this.getField('aceptar').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }
    
        return true;

    }

    limpiar(){
        this.getField('aceptar').setDisabled(false);
        this.getField('desde').setError(false,'');
        this.getField('hasta').setError(false,'');
    }

    procesarRecalcular(){
        this.getField('desde').setError(false,'');
        this.getField('hasta').setError(false,'');
        this.getField('aceptar').setDisabled(true);
        let errores=0;
        if(this.validarfechasConsulta()===false)
            errores++;
        
        if (errores===0) {
            let datos = { datos: {
                desde:this.getField("desde").getValue(),
                hasta:this.getField("hasta").getValue(),
                sucursal_ingreso :this.sucursal_ingreso 
            }};
            this.mostrarMensajeGenerando();
            this.generalFormatPmv = { tipo_servicio: 'inve-recalculartransacciones', operacion: 'procesar', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessProcesarRecalcular,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }

    }
    
    sucessProcesarRecalcular(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('aceptar').setDisabled(false);
        if(data.estado_p === 200){
            this.mostrarMensajeAdicional('Proceso Terminado.');
        }else {
            if(data.estado_p===404) {
                this.mostrarMensajeNoHayDatos();
            }else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

   

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }



}
FormJaivana.addController("inve-recalculartransacciones", CustomConsultarRecalcularTransacciones);
export default CustomConsultarRecalcularTransacciones;