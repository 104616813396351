import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomInventarioPorSubgrupo
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_jdesk_1.01.0002
 **/
class CustomInventarioPorSubgrupo extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm = this.initForm.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);

    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged'] = this.onSelectionChanged;

    this.gridOptionsComponentes2 = Object.assign({}, this.gridOptions);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes2['onSelectionChanged'] = this.onSelectionChanged;

    this.habilitarCorreo = this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo = this.deshabilitarCorreo.bind(this);
    this.abrirImprimir = this.abrirImprimir.bind(this);

    this.operacion_actual = '';
    this.validarConsultar = this.validarConsultar.bind(this);
    this.successTraerDatosInventario = this.successTraerDatosInventario.bind(this);
    this.traerPropiedades = this.traerPropiedades.bind(this);
    this.sucessTraerPropiedades = this.sucessTraerPropiedades.bind(this);;


    this.isJson = this.isJson.bind(this);
    this.generarExcelTabla3 = this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3 = this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo = this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado = this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo = this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros = this.enviarCorreoTerceros.bind(this);
    this.procesar = this.procesar.bind(this);
    this.sucessTraerDatos = this.sucessTraerDatos.bind(this);
    this.genearModalGlobales = this.genearModalGlobales.bind(this);
    this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
    this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
    this.formatNumberSaldo = this.formatNumberSaldo.bind(this);


    this.nombre_archivo = 'inventario_por_subgrupo';
    this.numero_deciamles = '';
    this.sucursal_ingreso = '';
  }

  initForm() {
    console.log("Formulario CustomInventarioPorSubgrupo,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.traerPropiedades();

    this.getField('imprimir').setClick(() => {
      if(this.getField('select_bodegas').valid() && this.getField('select_tipos_productos').valid()){
        this.nombre_archivo='inventario_por_subgrupo';
        this.abrirImprimir();
      }
   });


    //imprimir
    this.getField('radio_correo').setValue("N");
    this.getField('generar_archivo').setClick(() => this.procesar());

    //para balances globales
    this.getField('ch_excel').setOnChange((props)=>{
      if(this.getField('ch_excel').getValue()==='false'){
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='excel';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pdf').setOnChange((props)=>{
      if(this.getField('ch_pdf').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='pdf';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pantalla').setOnChange((props)=>{
      if(this.getField('ch_pantalla').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.operacion_actual='pantalla';
        this.deshabilitarCorreo();
      }else{
          this.habilitarCorreo();
      }
    });
    
  }

  limpiarCampos() {
    this.getField('rejilla').toggle(false);
    this.getField('imprimir').setDisabled(true);
    this.getField('procesar').setDisabled(false);
  }

  validarConsultar() {

    this.getField('bandeja').setError(false, '');
    this.getField('hasta_seccion').setError(false, '');
    this.getField('desde_seccion').setError(false, '');
    this.getField('gondola').setError(false, '');

    let errores = 0;
    if (this.getField('gondola').getValue() !== '' && this.getField('gondola').getValue() !== ' ') {
      if (!this.getField('gondola').valid())
        errores++;
    }
    if (this.getField('desde_seccion').getValue() !== '' && this.getField('desde_seccion').getValue() !== ' ') {
      if (!this.getField('desde_seccion').valid())
        errores++;
    }
    if (this.getField('hasta_seccion').getValue() !== '' && this.getField('hasta_seccion').getValue() !== ' ') {
      if (!this.getField('hasta_seccion').valid())
        errores++;
    }
    if (this.getField('bandeja').getValue() !== '' && this.getField('bandeja').getValue() !== ' ') {
      if (!this.getField('bandeja').valid())
        errores++;
    }

    if (errores === 0) {
      this.mostrarMensajeGenerando();
      let datos = {
        datos: {
          sucursal_ingreso: this.sucursal_ingreso,
        }
      }
      if (this.getField('gondola').getValue() !== '' && this.getField('gondola').getValue() !== ' ')
        datos.datos.gondola = this.getField('gondola').getValue();
      if (this.getField('desde_seccion').getValue() !== '' && this.getField('desde_seccion').getValue() !== ' ')
        datos.datos.desde_seccion = this.getField('desde_seccion').getValue();
      if (this.getField('hasta_seccion').getValue() !== '' && this.getField('hasta_seccion').getValue() !== ' ')
        datos.datos.hasta_seccion = this.getField('hasta_seccion').getValue();
      if (this.getField('bandeja').getValue() !== '' && this.getField('bandeja').getValue() !== ' ')
        datos.datos.bandeja = this.getField('bandeja').getValue();
      this.mostrarMensajeGenerando();
      this.getField('procesar').setDisabled(true);
      this.getField("rejilla").setTipoServicioOperacion('inve-inventarioporsubgrupo-articulosxgondola');
      this.getField("rejilla").sendRequest(this.successTraerDatosInventario, datos);

    }

  }

  successTraerDatosInventario(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      this.getField('rejilla').toggle(true);
      this.gridOptionsComponentes2['rowData'] = [];
      let configCell = new Map();
      this.gridOptionsComponentes2['rowData'] = data.data;
      configCell.set('seccion', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.seccion) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('bandeja', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.bandeja) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('lugar', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.lugar) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('caras', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.caras) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('cuantos', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cuantos) }, cellStyle: { textAlign: "right" }, width: 110 });

      this.getField('rejilla').initData(this.gridOptionsComponentes2, configCell);
      this.getField('imprimir').setDisabled(false);
    } else if (data.estado_p === 404) {
      //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      this.mostrarMensajeNoHayDatos();
    }
    else {
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }

  currencyFormatterGeneral(number) {
    let decimal = (number + "").split(".")[1];//para manejar los decimales
    if ((decimal !== 0) && (decimal !== undefined)) {
      if (decimal.length > this.numero_decimales) {
        number = number.toFixed(this.numero_decimales); //para que redondea a dos decimales
        decimal = (number + "").split(".")[1];
      }
      return (this.formatNumberSaldo(number) + "," + decimal);
    } else {
      return this.formatNumberSaldo(number);
    }
  }

  formatNumberSaldo(number) {
    return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  onSelectionChanged() {

  }

  //TRAER PROPIEDADES

  traerPropiedades() {
    this.mostrarMensajeGenerando();
    let datos = {
      datos: {
        sucursal_ingreso: this.sucursal_ingreso
      }
    };
    this.generalFormatPmv = { tipo_servicio: 'inve-inventarioporsubgrupo', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method: 'GET',
      body: datos,
      success: this.sucessTraerPropiedades,
      error: this.error_,
      general: this.generalFormatPmv,
      showMessage: false
    });
  }

  sucessTraerPropiedades(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      this.numero_decimales = data.data.numero_decimales_compras;
      //bodegas
      let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
      data.data.bodegas.forEach(item => {
          let dataOp = {}
          dataOp['value'] = item.codigo_bodega;
          dataOp['text'] = item.codigo_bodega+' - '+item.nombre;
          dataOp['campos_cambian'] = { };
          opciones.push(dataOp);
      });
      this.getField('select_bodegas').setOptions(opciones);

      opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
      data.data.tipos_productos.forEach(item => {
          let dataOp = {}
          dataOp['value'] = item.codigo_tipo_producto;
          dataOp['text'] = item.codigo_tipo_producto+' - '+item.nombre_tipo;
          dataOp['campos_cambian'] = { };
          opciones.push(dataOp);
      });
      this.getField('select_tipos_productos').setOptions(opciones);
}
  }

  //funciones para imprimir

  deshabilitarCorreo() {
    this.getField('email').setDisabled(true);
    this.getField('email').setValue('');
    this.getField('email').setError(false, '');
    this.getField('radio_correo').setDisabled(true);
    this.getField('radio_correo').setValue("N");
  }

  habilitarCorreo() {
    this.getField('email').setDisabled(false);
    this.getField('radio_correo').setDisabled(false);
    this.getField('radio_correo').setValue("N");
  }


  abrirImprimir() {

    this.getField('modal_imprimir').handleClickOpen();
    this.getField('email').setValue('');
    this.getField('email').setError(false, '');
    this.getField('radio_correo').setValue("N");
    this.getField('ch_excel').setValueSwitch(false);
    this.getField('ch_pdf').setValueSwitch(false);
    this.getField('ch_pantalla').setValueSwitch(false);
    if(this.boton==='normal')
      this.getField('ch_pantalla').setDisabled(true);
    else
      this.getField('ch_pantalla').setDisabled(false);
  }

  procesar() {
    let errores = 0;
    if (this.operacion_actual !== 'pantalla') {
      if (this.getField('email').valid() === false) {
        errores++;
      }
    }
    if (this.getField('ch_excel').getValue() === 'false' && this.getField('ch_pdf').getValue() === 'false' && this.getField('ch_pantalla').getValue() === 'false') {
      errores++;
      this.getField('ch_excel').setError(true, '* Es necesario seleccionar una opción.');
      this.getField('ch_pdf').setError(true, '* Es necesario seleccionar una opción.');
      this.getField('ch_pantalla').setError(true, '* Es necesario seleccionar una opción.');
      this.operacion_actual = '';
      this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
    }

    if (errores === 0) {
      if (this.operacion_actual !== '') {
        if (this.getField('radio_correo').getValue() === 'S') {
          this.enviarCorreoTerceros();
        } else {
          if (this.operacion_actual === 'pdf') {
            this.generarPdfTabla3();
          } else if (this.operacion_actual === 'pantalla') {
            this.genearModalGlobales();
          } else if (this.operacion_actual === 'excel') {
            this.generarExcelTabla3();
          }
        }
      } else {
        this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
        this.getField('confirmModalCustom').toggle(false);
      }
    }
  }

  genearModalGlobales() {
    this.mostrarMensajeGenerando();
    let datos = { datos:{ }};
    let op='';
    datos.datos = {
      email: this.getField('email').getValue(),
      sucursal_ingreso: this.sucursal_ingreso,
      operacion: this.operacion_actual,
      select_bodegas:this.getField('select_bodegas').getValue(),
      select_tipos_productos:this.getField('select_tipos_productos').getValue(),
    };
    op = 'archivoitems_pag';

    this.getField("rejilla_items_result").setTipoServicioOperacion('inve-inventarioporsubgrupo-'+op.replace('_pag',''));
    this.getField("rejilla_items_result").sendRequest(this.sucessTraerDatos, datos); 

  }

  sucessTraerDatos(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      let datosFicha = [];
      this.lista = data.data.datos_lista;

      let data2 =
      {
        "empresa": this.lista.empresa,
        "titulo": this.lista.titulo,
        "fecha_sistema": this.lista.fecha_sistema,
        "nit": this.lista.nit,
        "razon_social": this.lista.razon_social,
        "direccion": this.lista.direccion,
        "telefono": this.lista.telefono,
        "ciudad": this.lista.ciudad,
      };

      datosFicha.push(data2);
      this.getField('lista_datos').setItemsFichas(datosFicha);
      this.getField('rejilla_items_result').toggle(true);
      this.getField('modal_result').handleClickOpen();
      this.gridOptionsComponentes['rowData'] = [];
      let configCell = new Map();
      this.gridOptionsComponentes['rowData'] = data.data.datos_items;

      configCell.set('cantidad', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: { textAlign: "right" }, width: 110 });
      this.getField('rejilla_items_result').initData(this.gridOptionsComponentes, configCell);
      this.getField('label_total').setValue('Total '+this.lista.nombre_tipo);
      this.getField('total_tipo').setValue(this.currencyFormatterGeneral(this.lista.total_saldo));

    } else if (data.estado_p === 404) {
      //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      this.mostrarMensajeNoHayDatos();
    }
    else {
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }


  enviarCorreoTerceros() {
    this.mostrarMensajeGenerando();
    let datos = { datos:{ }};
    let op='';
    datos.datos = {
      email: this.getField('email').getValue(),
      radio_correo:this.getField('radio_correo').getValue(),
      sucursal_ingreso: this.sucursal_ingreso,
      operacion: this.operacion_actual,
      select_bodegas:this.getField('select_bodegas').getValue(),
      select_tipos_productos:this.getField('select_tipos_productos').getValue(),
    };
    op = 'archivoitems_pag';

    this.generalFormatPmv = { tipo_servicio: 'inve-inventarioporsubgrupo', operacion: op, operacion_tipo: 'consulta' };
    this.service.send(
      {
        endpoint: this.constant.formConfiguration(),
        method: 'GET',
        body: datos,
        success: this.successEnviarCorreo,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage: false
      });
  }

  successEnviarCorreo(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      this.mostrarMensajeEmailEnviado();
    } else {
      this.mostrarMensajeEmailFallo();
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }

  mostrarMensajeEmailEnviado() {
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeEmailFallo() {
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeNoHayDatos() {
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeGenerando() {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
    this.getField('confirmModalCustom').setClickDialog(() => { });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeAdicional(mensaje) {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('confirmModalCustom').setTitleAndContent('Compras', mensaje);
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  generarPdfTabla3() {
    this.mostrarMensajeGenerando();
    let url = this.constant.formConfiguration();
    let op= 'archivoitems_pag';
    let datos2 = {
      email: this.getField('email').getValue(),
      sucursal_ingreso: this.sucursal_ingreso,
      operacion: this.operacion_actual,
      select_bodegas:this.getField('select_bodegas').getValue(),
      select_tipos_productos:this.getField('select_tipos_productos').getValue(),
    };
  
    const myJSON = JSON.stringify(datos2);
    let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"inve-inventarioporsubgrupo","operacion":"' + op + '","operacion_tipo":"consulta"}}';
    fetch(url + datos, {
      method: 'GET',
      headers: new Headers({
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Content-Type": 'application/json'
      })
    })
      .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if (response.status !== 200) {
          return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => {
        if (blob.size > 0) {
          blob.text().then(res => {
            if (this.isJson(res)) {
              this.successEnviarCorreo(JSON.parse(res));
            } else {
              const pdf = new File([blob], this.nombre_archivo + '.pdf', {
                type: 'application/pdf'
              });
              window.open(URL.createObjectURL(pdf));
            }
          });
        } else {
          this.mostrarMensajeNoHayDatos();
        }
      }).catch(err => {
        this.alertGeneral.toggle(true, err, 'error');
      });
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  generarExcelTabla3() {
    this.mostrarMensajeGenerando();
    let url = this.constant.formConfiguration();
    let op= 'archivoitems_pag';
    let datos2 = {
      email: this.getField('email').getValue(),
      sucursal_ingreso: this.sucursal_ingreso,
      operacion: this.operacion_actual,
      select_bodegas:this.getField('select_bodegas').getValue(),
      select_tipos_productos:this.getField('select_tipos_productos').getValue(),
    };

    const myJSON = JSON.stringify(datos2);
    let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"inve-inventarioporsubgrupo","operacion":"' + op + '","operacion_tipo":"consulta"}}';
    fetch(url + datos, {
      method: 'GET',
      headers: new Headers({
        "Authorization": 'Bearer ' + localStorage.getItem('token'),
        "Content-Type": 'application/json'
      })
    })
      .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if (response.status !== 200) {
          return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => {
        if (blob.size > 0) {
          blob.text().then(res => {
            if (this.isJson(res)) {
              this.successEnviarCorreo(JSON.parse(res));
            } else {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = url;
              // the filename you want
              a.download = this.nombre_archivo + '.xlsx';
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(url);
              alert('Se descargo el archivo!');
            }
          });
        } else {
          this.mostrarMensajeNoHayDatos();
        }
      }).catch(err => {
        this.alertGeneral.toggle(true, err, 'error');
      });
  }



}
FormJaivana.addController("inve-inventarioporsubgrupo", CustomInventarioPorSubgrupo);
export default CustomInventarioPorSubgrupo;