import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Anderson Acevedo
 * 
 * @version jdesk_1.01.0002
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomDiasDeInventario extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);

        this.consultarCompra = this.consultarCompra.bind(this);
        this.gridOptionsTbPrincipal = Object.assign({}, this.gridOptions);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onSelectionChanged2 = this.onSelectionChanged2.bind(this);
        this.onSelectionChangedMas = this.onSelectionChangedMas.bind(this);
        this.gridOptionsTbPrincipal['onSelectionChanged'] = this.onSelectionChanged2;

        this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesItems['onSelectionChanged'] = this.onSelectionChanged;
        this.gridOptionsTbMas = Object.assign({}, this.gridOptions);
        this.gridOptionsTbMas['onSelectionChanged'] = this.onSelectionChangedMas;


        this.ocultarCampos = this.ocultarCampos.bind(this);
        this.validarfechas = this.validarfechas.bind(this);

        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
        this.traerPropiedades = this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades = this.sucesstraerPropiedades.bind(this);
        this.successCrearTemporal = this.successCrearTemporal.bind(this);
        this.successDatosPrincipal = this.successDatosPrincipal.bind(this);
        this.arreglarDecimales = this.arreglarDecimales.bind(this);

        
        //imprimir
        this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
        this.abrirImprimir                         =this.abrirImprimir.bind(this);
        this.isJson                         =this.isJson.bind(this);
        this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
        this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
        this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
        this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
        this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
        this.procesar                         =this.procesar.bind(this);
        this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
        this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
        this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
        this.successDatosVerMas                         =this.successDatosVerMas.bind(this);
        this.crearTemporalVerMas                         =this.crearTemporalVerMas.bind(this);
        this.successCrearTemporalVerMas                         =this.successCrearTemporalVerMas.bind(this);

        this.isJson = this.isJson.bind(this);

        this.nombre_archivo = '';
        this.boton = '';

        this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
        this.operacion_actual = '';
        this.sucursal_ingreso = '';
        this.numero_decimales = 2;
        this.fecha_hoy = '';
        this.dias_maximo=0;
        this.archivo_operacion='';
        this.fila_seleccionada=[];
    }

    initForm() {
        console.log('Formulario CustomDiasDeInventario,  @version: jdesk_1.01.0002, @author:Anderson Acevedo');
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedades();
        let hoy = new Date();
        let dia = hoy.getDate();
        let mes = hoy.getMonth() + 1;
        let año = hoy.getFullYear();
        if (mes < 10) {
            mes = '0' + mes;
        }
        if (dia < 10) {
            dia = '0' + dia;
        }
        this.fecha_hoy = año + '-' + mes + '-' + dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.getField('desde').setValue(this.fecha_inicio);
        this.getField("consultar").setClick(this.consultarCompra);


        this.getField("desde").setOnChange(this.validarfechas);
        this.getField("hasta").setOnChange(this.validarfechas);

        this.getField('bodega').setOnChange((props) => {
            this.getField('nombre_bodega').setError(false,'');
            this.ocultarCampos();
        });

        this.getField('codigo_grupo').setOnChange((props) => {
            this.getField('nombre_grupo').setError(false,'');
            this.ocultarCampos();
        });

        this.getField('codigo_subgrupo').setOnChange((props) => {
            this.getField('nombre_subgrupo').setError(false,'');
            this.ocultarCampos();
        });

        this.getField('ch_grupo').setOnChange((props) => {
            if (this.getField('ch_grupo').getValue() === 'false') {
                this.getField('ch_subgrupo').setValueSwitch(false);
                this.getField('codigo_grupo').setVisible(true);
                this.getField('nombre_grupo').setVisible(true);
                this.getField('codigo_subgrupo').setVisible(false);
                this.getField('nombre_subgrupo').setVisible(false);
                this.getField('codigo_grupo').setError(false,'');
                this.getField('codigo_grupo').setValue('');
                this.getField('nombre_grupo').setValue('');
            }else{
                this.getField('codigo_grupo').setVisible(false);
                this.getField('nombre_grupo').setVisible(false);
            }
        });

        this.getField('ch_subgrupo').setOnChange((props) => {
            if (this.getField('ch_subgrupo').getValue() === 'false') {
                this.getField('ch_grupo').setValueSwitch(false);
                this.getField('codigo_subgrupo').setVisible(true);
                this.getField('nombre_subgrupo').setVisible(true);
                this.getField('codigo_grupo').setVisible(false);
                this.getField('nombre_grupo').setVisible(false);
                this.getField('codigo_subgrupo').setError(false,'');
                this.getField('codigo_subgrupo').setValue('');
                this.getField('nombre_subgrupo').setValue('');
            }else{
                this.getField('codigo_subgrupo').setVisible(false);
                this.getField('nombre_subgrupo').setVisible(false);
            }
        });
        this.getField('codigo_grupo').setVisible(false);
        this.getField('nombre_grupo').setVisible(false);
        this.getField('codigo_subgrupo').setVisible(false);
        this.getField('nombre_subgrupo').setVisible(false);


        this.getField("bt_imprimir").setClick(() => {
            this.boton = "bt_imprimir";
            this.archivo_operacion='imprimir_pag'
            this.nombre_archivo = "dias_de_inventario";
            this.abrirImprimir();
        });

        this.getField("bt_ver_mas").setClick(() => {
            if(this.fila_seleccionada.length>0){
                this.boton = "ver_mas";
                this.archivo_operacion='ver_mas_pag'
                this.nombre_archivo = "dias_de_inventario_detalle";
                this.crearTemporalVerMas();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
            }
        });
        //ver más
        this.getField("bt_imprimir_compras").setClick(() => {
            this.boton = "bt_imprimir_compras";
            this.archivo_operacion='imprimir_detalle_pag'
            this.nombre_archivo = "dias_de_inventario_compras";
            this.abrirImprimir();
        });

        this.getField("bt_ver_compras").setClick(() => {
            if(this.fila_seleccionada_mas.length>0){
                this.boton = "bt_ver_compras";
                this.archivo_operacion='ver_compras_pag'
                this.nombre_archivo = "dias_de_inventario_compras";
                this.abrirImprimir();
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar un registro.', 'error');
            }
        });

        //imprimir
        this.getField('radio_correo').setValue("N");
        this.getField('generar_archivo').setClick(() => {
            this.procesar();
        });

        //para balances globales
        this.getField('ch_excel').setOnChange((props) => {
            if (this.getField('ch_excel').getValue() === 'false') {
                this.getField('ch_pdf').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.operacion_actual = 'excel';
                this.habilitarCorreo();
            }
        });
        this.getField('ch_pdf').setOnChange((props) => {
            if (this.getField('ch_pdf').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.operacion_actual = 'pdf';
                this.habilitarCorreo();
            }
        });
        this.getField('ch_pantalla').setOnChange((props) => {
            if (this.getField('ch_pantalla').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pdf').setValueSwitch(false);
                this.operacion_actual = 'pantalla';
                this.deshabilitarCorreo();
            } else {
                this.habilitarCorreo();
            }
        });


    }

    traerPropiedades() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'inve-diasdeinventario', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    sucesstraerPropiedades(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.numero_decimales = data.data.numero_decimales;
            this.dias_maximo=data.data.dias_devolver;
        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



    onSelectionChanged() {

    }

    onSelectionChanged2() {
        this.fila_seleccionada = this.gridOptionsTbPrincipal.api.getSelectedRows();
    }

    onSelectionChangedMas() {
        this.fila_seleccionada_mas = this.gridOptionsTbMas.api.getSelectedRows();
    }


    validarfechas() {
        this.getField('consultar').setDisabled(false);
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];
        this.ocultarCampos();

        this.getField("desde").setError(false, "");
        this.getField("hasta").setError(false, "");
        if (fecha_desde > fecha_actual) {
            this.getField("desde").setError(true, "No puede ser mayor a la fecha actual.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        if (fecha_hasta > fecha_actual) {
            this.getField("hasta").setError(true, "No puede ser mayor a la fecha actual.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        if (fecha_desde > fecha_hasta) {
            this.getField("hasta").setError(true, "No puede ser menor a la fecha desde.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        let fecha = new Date(fecha_actual);
        fecha.setDate(fecha.getDate() - this.dias_maximo);
        fecha = fecha.toISOString().split('T')[0];
        if (fecha_desde < fecha) {
            this.getField("desde").setError(true, "No es posible devolverse más de " + this.dias_maximo + " días.");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        this.getField('consultar').setDisabled(false);
        return true;

    }


    ocultarCampos() {
        this.fila_seleccionada=[];
        this.getField('rejilla').toggle(false);
        this.getField('bt_imprimir').setVisible(false);
        this.getField('bt_ver_mas').setVisible(false);
        this.getField('consultar').setDisabled(false);
    }



    consultarCompra() {
        let errores = 0;
        this.ocultarCampos();
        this.getField('bodega').setError(false,'');
        this.getField('desde').setError(false,'');
        this.getField('hasta').setError(false,'');
        this.getField('codigo_grupo').setError(false,'');
        this.getField('nombre_grupo').setError(false,'');
        this.getField('codigo_subgrupo').setError(false,'');
        this.getField('nombre_subgrupo').setError(false,'');
        if (this.validarfechas() === false)
            errores++;
        if (this.getField('bodega').getValue() !== '' && this.getField('bodega').getValue() !== ' ') {
            if (!this.getField('bodega').valid())
                errores++;
            if (!this.getField('nombre_bodega').valid())
                errores++;
        }

        if (this.getField('ch_subgrupo').getValue() === 'true') {
            if (!this.getField('codigo_subgrupo').valid())
                errores++;
            if (!this.getField('nombre_subgrupo').valid())
                errores++;
        }

        if (this.getField('ch_grupo').getValue() === 'true') {
            if (!this.getField('codigo_grupo').valid())
                errores++;
            if (!this.getField('nombre_grupo').valid())
                errores++;
        }


        if (errores === 0) {
            this.getField("consultar").setDisabled(true);
            let datos = {
                datos: {
                    desde: this.getField("desde").getValue(),
                    hasta: this.getField("hasta").getValue(),
                    sucursal_ingreso: this.sucursal_ingreso,
                }
            };
            if (this.getField('bodega').getValue() !== '' && this.getField('bodega').getValue() !== ' ') 
                datos.datos.bodega=this.getField('bodega').getValue();
            if (this.getField('ch_grupo').getValue() === 'true') 
                datos.datos.codigo_grupo=this.getField('codigo_grupo').getValue();
            if (this.getField('ch_subgrupo').getValue() === 'true') 
                datos.datos.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            this.mostrarMensajeGenerando();
            this.generalFormatPmv = { tipo_servicio: 'inve-diasdeinventario', operacion: 'generardatos', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successCrearTemporal,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successCrearTemporal(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            let datos = {
                datos: {
                    sucursal_ingreso:this.sucursal_ingreso
                }
            };
            this.mostrarMensajeGenerando();
            this.generalFormatPmv = { tipo_servicio: 'inve-diasdeinventario', operacion: 'archivoitems', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successDatosPrincipal,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }

    crearTemporalVerMas() {
        this.mostrarMensajeGenerando();
        let datos = {
            datos: {
                desde:this.fila_seleccionada[0].desde+'',
                hasta:this.fila_seleccionada[0].hasta+'',
                sucursal_ingreso:this.sucursal_ingreso
            }
        };
        if(this.getField('bodega').getValue()!=='' && this.getField('bodega').getValue()!==' ')
            datos.datos.bodega=this.getField('bodega').getValue();
        this.generalFormatPmv = { tipo_servicio: 'inve-diasdeinventario', operacion: 'generardatos_ver_mas', operacion_tipo: 'crear' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'POST',
                body: datos,
                success: this.successCrearTemporalVerMas,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successCrearTemporalVerMas(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.mostrarMensajeGenerando();
            let datos = {
                datos: {
                    desde:this.fila_seleccionada[0].desde+'',
                    hasta:this.fila_seleccionada[0].hasta+'',
                    sucursal_ingreso:this.sucursal_ingreso,
                    operacion:'pantalla'
                }
            };
            if(this.getField('bodega').getValue()!=='' && this.getField('bodega').getValue()!==' ')
                datos.datos.bodega=this.getField('bodega').getValue();
            this.getField("rejilla_ver_mas").setTipoServicioOperacion('inve-diasdeinventario-imprimir_detalle');
            this.getField("rejilla_ver_mas").sendRequest(this.successDatosVerMas, datos); 
        } else {
            this.mostrarMensajeNoHayDatos();
            this.ocultarCampos();
            this.getField('consultar').setDisabled(true);
        }
    }

    successDatosVerMas(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.fila_seleccionada_mas=[];
            this.getField('modal_mas').setLabel(data.data.datos_lista.titulo);
            this.getField('modal_mas').handleClickOpen();
            this.gridOptionsTbMas['rowData'] = data.data.datos_items;
            let configCell = new Map();
            configCell.set('costo', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costo) }, type: 'rightAligned' });
            configCell.set('cantidadu', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.cantidadu) }, type: 'rightAligned' });

            configCell.set('saldo', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.saldo) }, type: 'rightAligned' });
            configCell.set('valorizado', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.valorizado) }, type: 'rightAligned' });
            configCell.set('ventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.ventas) }, type: 'rightAligned' });
            configCell.set('devventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.devventas) }, type: 'rightAligned' });
            configCell.set('contenido', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.contenido) }, type: 'rightAligned' });
            configCell.set('rotacion_mensual', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.rotacion_mensual) }, type: 'rightAligned' });
            configCell.set('rotacion_diaria', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.rotacion_diaria) }, type: 'rightAligned' });
            configCell.set('dias', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.dias) }, type: 'rightAligned' });
            configCell.set('valor_ventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.valor_ventas) }, type: 'rightAligned' });
            configCell.set('costo_ventas', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costo_ventas) }, type: 'rightAligned' });
            configCell.set('valor_compras', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.valor_compras) }, type: 'rightAligned' });

            this.getField('rejilla_ver_mas').initData(this.gridOptionsTbMas, configCell);
            this.getField('rejilla_ver_mas').toggle(true);

        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }

    successDatosPrincipal(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('desde', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.desde) }, type: 'rightAligned' });
            configCell.set('hasta', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.hasta) }, type: 'rightAligned' });

            configCell.set('valor', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.valor) }, type: 'rightAligned' });
            configCell.set('articulos', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.articulos) }, type: 'rightAligned' });
            configCell.set('porcentaje_participacion', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.porcentaje_participacion) }, type: 'rightAligned' });

            this.getField('rejilla').initData(this.gridOptionsTbPrincipal, configCell);
            this.getField('rejilla').toggle(true);
            this.getField('bt_imprimir').setVisible(true);
            this.getField('bt_ver_mas').setVisible(true);
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }


    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > this.numero_decimales) {
                number = number.toFixed(this.numero_decimales); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


    mostrarMensajeAdicional(mensaje) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

    }

    mostrarMensajeNoHayDatos() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo

    mostrarMensajeEmailEnviado() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //modal imprimir

    deshabilitarCorreo() {
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false, '');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo() {
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    abrirImprimir() {

        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false, '');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    procesar() {
        this.archivo_servicio = 'inve-diasdeinventario';
        let errores = 0;
        if (this.getField('radio_correo').getValue() === 'S' || this.operacion_actual !== 'pantalla') {
            if (this.getField('email').valid() === false) {
                errores++;
            }
        }
        if (this.getField('ch_excel').getValue() === 'false' && this.getField('ch_pdf').getValue() === 'false' && this.getField('ch_pantalla').getValue() === 'false') {
            errores++;
            this.getField('ch_excel').setError(true, '* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true, '* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true, '* Es necesario seleccionar una opción.');
            this.operacion_actual = '';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }

        if (errores === 0) {
            if (this.operacion_actual !== '') {
                if (this.getField('radio_correo').getValue() === 'S') {
                    this.enviarCorreoTerceros();
                } else {
                    if (this.operacion_actual === 'pdf') {
                        this.generarPdfTabla3();
                    } else if (this.operacion_actual === 'pantalla') {
                        this.genearModalGlobales();
                    } else if (this.operacion_actual === 'excel') {
                        this.generarExcelTabla3();
                    }
                }
            } else {
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            }
        }
    }

    genearModalGlobales() {
        this.mostrarMensajeGenerando();
        let datos = {
            datos: {
                sucursal_ingreso: this.sucursal_ingreso,
                operacion: this.operacion_actual,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
            }
        }
        if (this.getField('bodega').getValue() !== '' && this.getField('bodega').getValue() !== ' ') 
            datos.datos.bodega=this.getField('bodega').getValue();
        if (this.getField('ch_grupo').getValue() === 'true') {
            datos.datos.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos.datos.nombre_grupo=this.getField('nombre_grupo').getValue();
        }if (this.getField('ch_subgrupo').getValue() === 'true') {
            datos.datos.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos.datos.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
        }if(this.boton==='bt_imprimir_compras'){
            datos.datos.desde=this.fila_seleccionada[0].desde+'';
            datos.datos.hasta=this.fila_seleccionada[0].hasta+'';
            datos.datos.value='notodosloscampos';
        }
        if(this.boton==='bt_ver_compras')
            datos.datos.codigo_articulo=this.fila_seleccionada_mas[0].codigo;
        if (this.boton === 'bt_imprimir') {
            this.generalFormatPmv = { tipo_servicio: 'inve-diasdeinventario', operacion: this.archivo_operacion, operacion_tipo: "consulta" };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.sucessTraerDatos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        } else {
            this.getField("rejilla_items_result").setTipoServicioOperacion('inve-diasdeinventario-' + this.archivo_operacion.replace('_pag', ''));
            this.getField("rejilla_items_result").sendRequest(this.sucessTraerDatos, datos);
        }
    }

    sucessTraerDatos(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField('total_valor').setVisible(false);
            this.getField('total_articulos').setVisible(false);
            this.getField('total_saldo').setVisible(false);
            this.getField('total_inventario').setVisible(false);
            this.getField('total_ventas').setVisible(false);
            

            let datosFicha = [];
            this.lista = data.data.datos_lista;
            let data2 =
            {
                "empresa": this.lista.empresa,
                "nit": this.lista.nit,
                "titulo": this.lista.titulo,
                "fecha_sistema": this.lista.fecha_sistema,
                "clasificacion": this.lista.clasificacion
            };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            if (this.boton === 'bt_imprimir') {
                this.getField('total_valor').setVisible(true);
                this.getField('total_articulos').setVisible(true);
                this.getField('total_articulos').setValue(this.arreglarDecimales(this.lista.total_articulos));
                this.getField('total_valor').setValue(this.arreglarDecimales(this.lista.total_valor));
            } else if(this.boton==='bt_imprimir_compras') {
                this.getField('total_saldo').setVisible(true);
                this.getField('total_inventario').setVisible(true);
                this.getField('total_ventas').setVisible(true);
                this.getField('total_saldo').setValue(this.arreglarDecimales(this.lista.total_saldo));
                this.getField('total_inventario').setValue(this.arreglarDecimales(this.lista.total_inventario));
                this.getField('total_ventas').setValue(this.arreglarDecimales(this.lista.total_ventas));
            }

            this.getField('rejilla_items_result').toggle(true);
            this.getField('modal_result').setLabel('INVENTARIO');
            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
            configCell.set('valor', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.valor) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('articulos', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.articulos) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('porcentaje_participacion', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.porcentaje_participacion) }, cellStyle: { textAlign: "right" }, width: 110 });
            
            configCell.set('dias', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.dias) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('saldo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('costo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('costo_inventario', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo_inventario) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('cantidadu', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidadu) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('ventas', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.ventas) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('rotacion_diaria', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.rotacion_diaria) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('cantidad', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: { textAlign: "right" }, width: 110 });

            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems, configCell);
            
        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }


    enviarCorreoTerceros() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos = {
            datos: {
                sucursal_ingreso: this.sucursal_ingreso,
                operacion: this.operacion_actual,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
            }
        }
        if (this.getField('bodega').getValue() !== '' && this.getField('bodega').getValue() !== ' ') 
            datos.datos.bodega=this.getField('bodega').getValue();
        if (this.getField('ch_grupo').getValue() === 'true') {
            datos.datos.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos.datos.nombre_grupo=this.getField('nombre_grupo').getValue();
        }if (this.getField('ch_subgrupo').getValue() === 'true') {
            datos.datos.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos.datos.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
        }if(this.boton==='bt_imprimir_compras'){
            datos.datos.desde=this.fila_seleccionada[0].desde+'';
            datos.datos.hasta=this.fila_seleccionada[0].hasta+'';
            datos.datos.value='notodosloscampos';
        }
        if(this.boton==='bt_ver_compras')
            datos.datos.codigo_articulo=this.fila_seleccionada_mas[0].codigo;
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
    }

    successEnviarCorreo(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.mostrarMensajeEmailEnviado();
        } else if (data.estado_p === 404) {
            this.mostrarMensajeNoHayDatos();
        } else {
            this.mostrarMensajeEmailFallo();
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla3() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let url = this.constant.formConfiguration();
        let datos2 = {
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
        }
        if (this.getField('bodega').getValue() !== '' && this.getField('bodega').getValue() !== ' ') 
            datos2.bodega=this.getField('bodega').getValue();
        if (this.getField('ch_grupo').getValue() === 'true') {
            datos2.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos2.nombre_grupo=this.getField('nombre_grupo').getValue();
        }if (this.getField('ch_subgrupo').getValue() === 'true') {
            datos2.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos2.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
        }if(this.boton==='bt_imprimir_compras'){
            datos2.desde=this.fila_seleccionada[0].desde+'';
            datos2.hasta=this.fila_seleccionada[0].hasta+'';
            datos2.value='notodosloscampos';
        }
        if(this.boton==='bt_ver_compras')
            datos2.codigo_articulo=this.fila_seleccionada_mas[0].codigo;
        const myJSON = JSON.stringify(datos2);
        let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"' + this.archivo_servicio + '","operacion":"' + this.archivo_operacion + '","operacion_tipo":"consulta"}}';
        fetch(url + datos, {
            method: 'GET',
            headers: new Headers({
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": 'application/json'
            })
        })
            .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if (blob.size > 0) {
                    blob.text().then(res => {
                        if (this.isJson(res)) {
                            this.successEnviarCorreo(JSON.parse(res));
                        } else {
                            const pdf = new File([blob], this.nombre_archivo + '.pdf', {
                                type: 'application/pdf'
                            });
                            window.open(URL.createObjectURL(pdf));
                        }
                    });
                } else {
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true, err, 'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let url = this.constant.formConfiguration();
        let datos2 = {
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
        }
        if (this.getField('bodega').getValue() !== '' && this.getField('bodega').getValue() !== ' ') 
            datos2.bodega=this.getField('bodega').getValue();
        if (this.getField('ch_grupo').getValue() === 'true') {
            datos2.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos2.nombre_grupo=this.getField('nombre_grupo').getValue();
        }if (this.getField('ch_subgrupo').getValue() === 'true') {
            datos2.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos2.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
        }if(this.boton==='bt_imprimir_compras'){
            datos2.desde=this.fila_seleccionada[0].desde+'';
            datos2.hasta=this.fila_seleccionada[0].hasta+'';
            datos2.value='notodosloscampos';
        }
        if(this.boton==='bt_ver_compras')
            datos2.codigo_articulo=this.fila_seleccionada_mas[0].codigo;
        const myJSON = JSON.stringify(datos2);
        let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"' + this.archivo_servicio + '","operacion":"' + this.archivo_operacion + '","operacion_tipo":"consulta"}}';
        fetch(url + datos, {
            method: 'GET',
            headers: new Headers({
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": 'application/json'
            })
        })
            .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if (blob.size > 0) {
                    blob.text().then(res => {
                        if (this.isJson(res)) {
                            this.successEnviarCorreo(JSON.parse(res));
                        } else {
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo + '.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        }
                    });
                } else {
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true, err, 'error');
            });
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }


}
FormJaivana.addController("inve-diasdeinventario", CustomDiasDeInventario);
export default CustomDiasDeInventario