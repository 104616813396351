import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomNotasCartera
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0004
 **/
class CustomNotasCartera extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                           = this.initForm.bind(this);
        this.searchDocument                     = this.searchDocument.bind(this);
        this.successDocument                    = this.successDocument.bind(this);
        this.limpiarTodo                        = this.limpiarTodo.bind(this);
        this.gridOptions2                       = Object.assign({},this.gridOptions)
        this.setValueField                      = this.setValueField.bind(this);
        this.onSelectionChanged                 = this.onSelectionChanged.bind(this);
        this.gridOptions3                       = Object.assign({},this.gridOptions)
        this.ConceptoNotasCartera               = this.ConceptoNotasCartera.bind(this);
        this.successConceptoNotasCartera        = this.successConceptoNotasCartera.bind(this);
        this.setCheckbox                        = this.setCheckbox.bind(this);
        this.dataCount                          = [];
        this.dataToSend                         = [];
        this.cancelAll                          = this.cancelAll.bind(this);
        this.limpiarFormulario                  = this.limpiarFormulario.bind(this);
        this.save                               = this.save.bind(this);
        this.successCount1                      = this.successCount1.bind(this);
        this.successCountsSave                  = this.successCountsSave.bind(this);
        this.showTable                          = this.showTable.bind(this);
        this.saveAll                            = this.saveAll.bind(this);
        this.successPostCountOne                = this.successPostCountOne.bind(this);
        this.formatoFecha                       = this.formatoFecha.bind(this);
    }

    initForm() {
        console.log("Formulario CustomNotasCartera,  @version: jdesk_1.01.0004, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        if(this.getField('buscar_documento'))
            this.getField('buscar_documento').setClick(this.searchDocument);
        this.getField('nit').setCustomSuccess(this.limpiarTodo);
        if(this.getField('debito'))
            this.getField('debito').setOnChange(this.setCheckbox);
        if(this.getField('cancel'))
            this.getField('cancel').setClick(this.cancelAll);
        if(this.getField('boton_grabar'))
            this.getField('boton_grabar').setClick(this.save);
        if(this.getField('grabar_todo'))
            this.getField('grabar_todo').setClick(this.saveAll);
        this.getField('grabar_todo').setDisabled(true);
        this.getField('valor_documento').setDisabled(true);
        this.getField('saldo').setDisabled(true);
        this.getField('valor_total').setDisabled(true);

        this.gridOptions2['onSelectionChanged']= this.onSelectionChanged;
    }
        
    onSelectionChanged=()=> {
        if(this.gridOptions2.rowData.length === 1)
            this.gridOptions2.api.forEachNode(node=> node.rowIndex ? 0 : node.setSelected(true))
        if(this.gridOptions2.api.getSelectedRows().length > 0)
            this.getField('modal_notas').handleClose();
    }

    setValueField(field_id,value){ 
        let field = this.getField(field_id); 
        if(field)
            field.setValue(value);
    }

    searchDocument(){
        if(this.getField('nit').valid() && this.getField('sede').valid()){
            let datos={ datos: {
                nit:this.getField('nit').getValue(),
                sede:this.getField('sede').getValue() 
            }};
            this.generalFormatPmv = { tipo_servicio: 'cont-notascartera', operacion: 'cargardocumentofuente', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint:this.constant.formConfiguration(),
                method:'GET',
                body:datos,
                success:this.successDocument,
                error:this.error_,
                general:this.generalFormatPmv
            });
        }
    }
    
    successDocument(model){
        if(model.data){
            this.getField('valor_total').setError(false,"");

            if( model.data.length === 1){
                let gridCell = new Map();
                gridCell.set('valor',{cellRenderer:function(props){return "".formatoPrecio(props.data['valor'])}, field: 'valor' });
                gridCell.set('saldo',{cellRenderer:function(props){return "".formatoPrecio(props.data['saldo'])}, field: 'saldo' });
                this.gridOptions2['rowData'] = model.data; 
                this.getField('tabla_documento_negativo').initData(this.gridOptions2,gridCell); 
                this.ConceptoNotasCartera();
                this.getField('fieldAlert').toggle(true,"Acción Exitosa","success");
            } else if (model.data.length > 1) {
                let gridCell = new Map();
                gridCell.set('valor',{cellRenderer:function(props){return "".formatoPrecio(props.data['valor'])}, field: 'valor' });
                gridCell.set('saldo',{cellRenderer:function(props){return "".formatoPrecio(props.data['saldo'])}, field: 'saldo' });
                this.gridOptions2['rowData'] = model.data; 
                this.getField('tabla_documento_negativo').initData(this.gridOptions2,gridCell); 
                this.getField('modal_notas').handleClickOpen();
                this.ConceptoNotasCartera();
                this.getField('fieldAlert').toggle(true,"Acción Exitosa","success");
            }
        }
    }

    ConceptoNotasCartera(){
        let datos={ datos: { }};
        this.generalFormatPmv = { tipo_servicio: 'cont-notascartera', operacion: 'conceptonotascartera', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint:this.constant.formConfiguration(),
            method:'GET',
            body:datos,
            success:this.successConceptoNotasCartera,
            error:this.error_,
            general:this.generalFormatPmv
        });
    }

    successConceptoNotasCartera(data){
        if(data.estado_p === 200){

            this.getField("codigo_concepto").setValue(data.data[0].codigo_concepto);
            this.getField("nombre_concepto").setValue(data.data[0].nombre_concepto);
            this.getField("cuenta_concepto").setValue(data.data[0].codigo_cuenta_origen);
            this.getField("debito").setValue(data.data[0].es_debito);
            this.getField("codigo_cuenta_texto").setValue(data.data[0].codigo_cuenta_origen);
            this.getField("detalle_concepto").input.focus();
            this.alertGeneral.toggle(true, 'Solo se puede retornar datos del concepto 0015.', 'success');
        }else{
            let respuesta=Object.values(data.data.errores)
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    limpiarTodo(){
        for(let ref in this.refsJaivana){
            if(ref !== 'nit' && ref !== 'sede' && ref !== 'nombre' && ref !== 'direccion' && this.getField(ref).setValue !== undefined)
                this.getField(ref).setValue("");   
                this.getField("cuenta_documento").setError(false,"");   
                this.getField("cuenta_concepto").setError(false,"");   
        }
    }

    setCheckbox(){
        let value = this.getField('debito').getValue();
        if(value === 'D'){
            this.getField('abono').setVisible(true);
            this.getField('abono').setValue("Abono");
            this.getField('cargo').setVisible(false);
        }else if(value === 'C'){
            this.getField('cargo').setVisible(true);
            this.getField('cargo').setValue("Cargo");
            this.getField('abono').setVisible(false);
        }
    }

    cancelAll(){
        this.dataCount = [];
        this.dataToSend = [];
        this.limpiarFormulario();

        this.getField('nit').setDisabled(false);
        this.getField('codigo_concepto').setDisabled(false);
        this.getField('valor_total').setDisabled(false);
        this.getField('buscar_documento').setDisabled(false);
        this.getField('boton_grabar').setDisabled(false);
        this.getField('detalle_concepto').setDisabled(false);
        this.getField('tabla_cartera').toggle(false);
        this.getField('grabar_todo').setDisabled(true);
    }

    limpiarFormulario(){
        for(let ref in this.refsJaivana){
            if(this.getField(ref).valueDefault && this.getField(ref).valueDefault !== null && this.getField(ref).valueDefault !== undefined && this.getField(ref).valueDefault !== "" && this.setValue){
                this.setValue(this.getField(ref).valueDefault);
                this.getField(ref).setError(false,"");   
            }else if(this.getField(ref).setValue!==undefined){
                this.getField(ref).setValue(""); 
                this.getField(ref).setError(false,"");   
            } 
        }
        
        this.getField('abono').setVisible(false);
        this.getField('abono').setValue("Abono");
        this.getField('cargo').setVisible(false);
        this.getField('cargo').setValue("Cargo");
    }
    
    save(){
        if(this.getField('cuenta_concepto').valid() & this.getField('cuenta_documento').valid() & this.getField('valor_total').valid() & this.dataCount.length === 0){
            this.getField('nit').setDisabled(true);
            this.getField('codigo_concepto').setDisabled(true);
            this.getField('valor_total').setDisabled(true);
            this.getField('buscar_documento').setDisabled(true);
            this.getField('boton_grabar').setDisabled(true);
            this.getField('detalle_concepto').setDisabled(true);
            let datos = { datos:{
                value:this.getField('cuenta_documento').getValue()+''
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-cuentas', operacion: '42', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint:this.constant.formConfiguration(),
                    method:'GET',
                    body:datos,
                    success:this.successCount1,
                    error:this.error_,
                    general:this.generalFormatPmv
                });
        }
    }
    
    successCount1(model){
        if(model.estado_p ===undefined || model.estado_p ===200){
            this.getField('cuenta_concepto').setError(false,'');
            let valorDebito = this.getField('debito').getValue();
            if(valorDebito === 'D')
                model.model[0].valor = -this.getField('valor_total').getValue();
            else
                model.model[0].valor = this.getField('valor_total').getValue();
            this.dataCount.push(model.model[0]);
            let datos = { datos:{
                value:this.getField('cuenta_concepto').getValue()+''
            }};

            this.generalFormatPmv = { tipo_servicio: 'maes-cuentas', operacion: '42', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint:this.constant.formConfiguration(),
                    method:'GET',
                    body:datos,
                    success:this.successCountsSave,
                    error:this.error_,
                    general:this.generalFormatPmv
                });
        }else{
            this.getField('cuenta_concepto').setError(true,'* No se ha encontrado la cuenta.');
            this.dataCount = [];
        }
    }

    successCountsSave(model){
        if(model.estado_p === undefined || model.estado_p === 200){
            this.getField('cuenta_documento').setError(false,'');
            this.getField('nit').setDisabled(true);
            this.getField('codigo_concepto').setDisabled(true);
            this.getField('valor_total').setDisabled(true);
            this.getField('buscar_documento').setDisabled(true);
            this.getField('boton_grabar').setDisabled(true);
            this.getField('detalle_concepto').setDisabled(true);
            let valorDebito = this.getField('debito').getValue();
            if(valorDebito === 'D')
                model.model[0].valor = this.getField('valor_total').getValue();
            else
                model.model[0].valor = -this.getField('valor_total').getValue();
            this.dataCount.push(model.model[0]);
            this.getField('fieldAlert').toggle(true,"Acción Exitosa","success");
            this.getField('grabar_todo').setDisabled(false);
        }else{
            this.getField('cuenta_documento').setError(true,'* No se ha encontrado la cuenta.');
            this.dataCount = [];
        }
        this.showTable(this.dataCount);

    }
    
    showTable(model){
        let gridCell = new Map();
        gridCell.set('valor',{cellRenderer:function(props){return "".formatoPrecio(props.data['valor'])}, field: 'valor' });
        this.gridOptions3['rowData'] = model; 
        this.getField('tabla_cartera').initData(this.gridOptions3,gridCell); 
    }

    formatoFecha(fecha){
        fecha.setDate(fecha.getDate());
        return fecha
      }

    saveAll(){
        let hoy = new Date();        
        let fecha = hoy.toISOString();
        this.getField('sFecha').setValue(fecha.split('T')[0]);

        if(this.dataCount.length === 2){
            let datos = {};
            this.dataToSend = [];
            this.dataCount.map((count,i)=> {
                datos['nCodigoCuenta']= parseInt(count['codigo_cuenta']);
                datos['sCentroCosto']= this.getField('sucursal_documento').getValue()+'';
                datos['nBase']= 0;
                datos['nValor']= parseFloat(count['valor']);
                datos['sCodigoSucursal']= '0101';
                datos['sCodigoDocumento']= 'NC';//'NI';
                datos['sNumeroDocumento']= '0';
                datos['nNit']= this.getField('nit').getValue();
                datos['nNitResponsable']= this.getField('nit').getValue();
                datos['nSede']= parseInt(this.getField('sede').getValue());
                datos['nSedeResponsable']= parseInt(this.getField('sede').getValue());
                datos['sBanco']= '.';
                datos['sDeptoInterno']= '9999';
                datos['sDocumentoNiif']= '.';
                datos['sNiif']= 'N';
                datos['sLlave']=this.getField('id_documento').getValue();
                datos['nCodigoCuentaNiif']= 0;
                datos['sCodigoUsuario']= 'gda';
                datos['sCodigoOrigen']= 'CONTA';
                datos['sItem']= i;
                datos['nValorNiif']= 0;
                datos['sDocumentoNit']= '.';
                datos['sDetalleNiif']= '.';
                datos['sDetalle']= this.getField('detalle_concepto').getValue();
                datos['sTipoFuente']= '.';
                datos['sDocumentoFuente'] = this.getField('numero').getValue();
                datos['sFecha'] = this.getField('sFecha').getValue();
                datos['nTarifa'] = 0;
                this.dataToSend.push(datos);
                datos={};
                return null;
            });
            this.getField('grabar_todo').setDisabled(true);
            let datos1= { datos:{
                id_formulario: this.formId,
                accion: 'servicio',
                campo: 'grabar_todo',
                data: this.dataToSend
            }}
            this.generalFormatPmv = { tipo_servicio: 'cont-notascartera', operacion: 'crearmovimientoconcepto', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint:this.constant.formConfiguration(),
                    method:'POST',
                    body:datos1,
                    success:this.successPostCountOne,
                    error:this.error_,
                    general:this.generalFormatPmv
                }
            );
        }
    }

    successPostCountOne(model){
        if(model.estado_p ===undefined || model.estado_p ===200){
            this.getField('fieldAlert').toggle(true,"Acción Exitosa","success");
            this.dataCount = [];
            this.dataToSend = [];
            this.limpiarFormulario();

            this.getField('nit').setDisabled(false);
            this.getField('codigo_concepto').setDisabled(false);
            this.getField('valor_total').setDisabled(false);
            this.getField('buscar_documento').setDisabled(false);
            this.getField('boton_grabar').setDisabled(false);
            this.getField('detalle_concepto').setDisabled(false);
            this.getField('tabla_cartera').toggle(false);
            this.getField('grabar_todo').setDisabled(true);
        }    

    }

}
FormJaivana.addController("cont-notascartera", CustomNotasCartera);
export default CustomNotasCartera;