import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomBalances
 * @author: Anderson Acevedo
 * @version:  jdesk_1.01.0001.1
 **/



class CustomBalances extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.validarfechas                                          = this.validarfechas.bind(this);
        this.procesar                                               = this.procesar.bind(this);
        this.cargarFechas                                           = this.cargarFechas.bind(this);
        this.limpiarErrores                                         = this.limpiarErrores.bind(this);
        this.validarCampos                                          = this.validarCampos.bind(this);
        this.limpiarCampos                                          = this.limpiarCampos.bind(this);
        this.successDeleteTmp                                       = this.successDeleteTmp.bind(this);
        this.limpiarTemporal                                        = this.limpiarTemporal.bind(this);
        this.generarReporte                                         = this.generarReporte.bind(this);
        this.sucessgenerarReporte                                   = this.sucessgenerarReporte.bind(this);

        this.gridOptionsComponentes                                 = Object.assign({},this.gridOptions);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.gridOptionsComponentesBalanceGeneral                               = Object.assign({},this.gridOptions);
        this.gridOptionsComponentesBalanceGeneral['onSelectionChanged']           = this.onSelectionChanged;
        this.gridOptionsComponentesBalanceNormal                                 = Object.assign({},this.gridOptions);
        this.gridOptionsComponentesBalanceNormal['onSelectionChanged']           = this.onSelectionChanged;
        this.gridOptionsComponentesSucursales                               = Object.assign({},this.gridOptions);
        this.onSelectionChangedSucursales                           =this.onSelectionChangedSucursales.bind(this);
        this.gridOptionsComponentesSucursales['onSelectionChanged']          = this.onSelectionChangedSucursales;
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.operacion='';
        this.opcionBalancesGloblaes                                  = this.opcionBalancesGloblaes.bind(this);
        this.opcionBalancesNormal                                  = this.opcionBalancesNormal.bind(this);

        this.sucessTraerDatos                                  = this.sucessTraerDatos.bind(this);
        this.codigo_sucursal ='';
        this.lista={};
        this.operacion_actual='';
        this.estados_resultados='';
        this.deshabilitarCorreo1                                     = this.deshabilitarCorreo1.bind(this);
        this.habilitarCorreo1                                       = this.habilitarCorreo1.bind(this);
        this.deshabilitarCorreo                                     = this.deshabilitarCorreo.bind(this);
        this.habilitarCorreo                                        = this.habilitarCorreo.bind(this);

        this.enviarCorreoTerceros                                   = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo                                    = this.successEnviarCorreo.bind(this);
        this.genearModalGlobales                                    = this.genearModalGlobales.bind(this);
        this.generarPdfTabla3                                       = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3                                     = this.generarExcelTabla3.bind(this);
        this.isJson                                                 = this.isJson.bind(this);
        this.traerDatosEmpresa                                      = this.traerDatosEmpresa.bind(this);
        this.successTraerDatosEmpresa                               = this.successTraerDatosEmpresa.bind(this);
        this.genearModalNormal                                      = this.genearModalNormal.bind(this);
        this.sucessTraerDatosNormales                               = this.sucessTraerDatosNormales.bind(this);
        this.mostrarMensajeEmailEnviado                             = this.mostrarMensajeEmailEnviado.bind(this);
        this.sucessGenerarDatosNormales                             = this.sucessGenerarDatosNormales.bind(this);
        this.generarDatosNormales                                   = this.generarDatosNormales.bind(this);
        
        this.limpiarTemporalEstados                                 = this.limpiarTemporalEstados.bind(this);
        this.limpiarTemporalGeneral                                 = this.limpiarTemporalGeneral.bind(this);

        this.generarDatosBalanceGeneral                             = this.generarDatosBalanceGeneral.bind(this);
        this.sucessGenerarDatosBalanceGeneral                       = this.sucessGenerarDatosBalanceGeneral.bind(this);
        this.generarModalBalanceGeneral                             = this.generarModalBalanceGeneral.bind(this);
        this.sucessgenerarModalBalanceGeneral                       = this.sucessgenerarModalBalanceGeneral.bind(this);
        this.generarDatosResultadosEstados                          = this.generarDatosResultadosEstados.bind(this);
        this.sucessGenerarDatosResultadosEstados                    = this.sucessGenerarDatosResultadosEstados.bind(this);
        this.operacion_normal='';
        this.intermediarioEstados                                   = this.intermediarioEstados.bind(this);
        this.validarCamposEstados                                   = this.validarCamposEstados.bind(this);
        this.limpiarCamposResultados                                = this.limpiarCamposResultados.bind(this);
        this.traerPropiedadEstados                                  = this.traerPropiedadEstados.bind(this);
        this.successTraerPropiedadEstados                           = this.successTraerPropiedadEstados.bind(this);
        this.generarDatosGastos                                     = this.generarDatosGastos.bind(this);
        this.successTraerPropiedadEstados                           = this.successTraerPropiedadEstados.bind(this);
        this.limpiarTemporalGastos                                  = this.limpiarTemporalGastos.bind(this);
        this.sucessGenerarDatosGastos                               = this.sucessGenerarDatosGastos.bind(this);
        this.sucessGenerarModalVertical                             = this.sucessGenerarModalVertical.bind(this);
        this.sucessGenerarDatosAnalisisVertical                     = this.sucessGenerarDatosAnalisisVertical.bind(this);
        this.generarDatosAnalisisVertical                           = this.generarDatosAnalisisVertical.bind(this);
        this.sucessGenerarModalGP                                   = this.sucessGenerarModalGP.bind(this);
        this.sucessGenerarAnalisisGP                                = this.sucessGenerarAnalisisGP.bind(this);
        this.generarDatosAnalisisGP                                 = this.generarDatosAnalisisGP.bind(this);
        this.limpiarTemporalResumen                                 = this.limpiarTemporalResumen.bind(this);
        this.sucessGenerarModalGastos                               = this.sucessGenerarModalGastos.bind(this);
        this.sucessSucursales                                       = this.sucessSucursales.bind(this);
        this.sucessmodalSucursales                                       = this.sucessmodalSucursales.bind(this);
        this.servicioSucursales                                       = this.servicioSucursales.bind(this);
        this.traerDias                                              = this.traerDias.bind(this);
        this.successTraerDias                                       = this.successTraerDias.bind(this);
        this.traerTotalesGlobales                                       = this.traerTotalesGlobales.bind(this);
        this.successTraerTotalesGlobales                                    = this.successTraerTotalesGlobales.bind(this);
        this.mostrarMensajeNoHayDatos                                    = this.mostrarMensajeNoHayDatos.bind(this);
        this.totalesEstadosResultados                                    = this.totalesEstadosResultados.bind(this);
        this.successTotalesEstadosResultados                                    = this.successTotalesEstadosResultados.bind(this);
        this.dias_maximo=0;
    }



    initForm() {
        console.log("Formulario CustomBalances,  @version:  jdesk_1.01.0001.1, @author: Anderson Acevedo ");
        this.cargarFechas();
        this.getField("fecha").setOnChange(()=>{this.validarfechas()});
        this.getField('ch_sucursales').setValue(false);
        this.getField('ch_cierre').setValue(false);
        this.getField('procesar').setClick(this.validarCampos);
        this.getField('modal_balances_globales').setCloseButton(()=>{this.limpiarCampos();this.getField('procesar').setDisabled(true);this.limpiarTemporal();});
        this.getField('modal_balances').setCloseButton(()=>{this.limpiarCampos();this.getField('procesar').setDisabled(true);this.limpiarTemporal();});
        
        this.getField('modal_estados').setCloseButton(this.limpiarTemporalEstados);
        this.getField('modal_balance_general').setCloseButton(this.limpiarTemporalGeneral);
        this.getField('modal_vertical').setCloseButton(this.limpiarTemporalGeneral);
        this.getField('modal_gp').setCloseButton(this.limpiarTemporalResumen);
        this.getField('modal_gastos1').setCloseButton(this.limpiarTemporalGastos);
        
        this.getField('balance_general').setClick(this.generarDatosBalanceGeneral);
        this.getField('estado_resultados').setClick(this.generarDatosResultadosEstados);
        this.getField('gastos').setClick(this.generarDatosGastos);
        this.getField('generar').setClick(this.validarCamposEstados);
        this.getField('analisis_vertical').setClick(this.generarDatosAnalisisVertical);
        this.getField('analisis_vertical_gp').setClick(this.generarDatosAnalisisGP);
        this.getField('salir').setClick(()=>{this.getField('procesar').setDisabled(true);this.getField('modal_balances').handleClose();this.limpiarTemporal();});
        
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionBalancesGloblaes();
         }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionBalancesNormal();
         }
         this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
         this.getField('radio_correo').setValue("N");
         this.getField('radio_correo1').setValue("N");
         this.getField('procesar').setClick(this.procesar);

         //para balances globales
         this.getField('ch_excel1').setOnChange((props)=>{
            if(this.getField('ch_excel1').getValue()==='false'){
              this.getField('ch_pdf1').setValueSwitch (false);
              this.getField('ch_pantalla1').setValueSwitch (false);
              this.operacion_actual='excel';
              this.habilitarCorreo1();
            }
          });
          this.getField('ch_pdf1').setOnChange((props)=>{
            if(this.getField('ch_pdf1').getValue()==='false'){
              this.getField('ch_excel1').setValueSwitch (false);
              this.getField('ch_pantalla1').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.habilitarCorreo1();
            }
          });
          this.getField('ch_pantalla1').setOnChange((props)=>{
            if(this.getField('ch_pantalla1').getValue()==='false'){
              this.getField('ch_excel1').setValueSwitch (false);
              this.getField('ch_pdf1').setValueSwitch (false);
              this.operacion_actual='pantalla';
              this.deshabilitarCorreo1();
            }else{
                this.habilitarCorreo1();
            }
          });

          //para gastos

          this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
              this.getField('ch_pdf').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='excel';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pdf').setValueSwitch (false);
              this.operacion_actual='pantalla';
              this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
          });
          this.getField('ch_sucursales').setDisabled(true);
            this.getField('codigo_sucursal').setKeyUp((props)=>{
                this.getField('codigo_sucursal').setError(false,'');
                if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
                    this.getField('ch_sucursales').setValueSwitch(false);
                    this.servicioSucursales(props);
                }else{
                    this.getField('ch_sucursales').setValueSwitch(true);
                }
            });

            this.getField('ch_sucursales').setValueSwitch(true);
          this.traerDatosEmpresa();
          this.traerPropiedadEstados();
          this.traerDias();
    }

    onSelectionChangedSucursales(props){
        let datos = this.gridOptionsComponentesSucursales.api.getSelectedRows();
        this.getField("nombre_sucursal").setValue(datos[0].nombre_sucursal);
        this.getField("codigo_sucursal").setValue(datos[0].codigo_sucursal);
        this.getField("modal_sucursales").handleClose();
        
    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.balances_dias_devolver;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    servicioSucursales = (e) => {
        if (this.getField('codigo_sucursal').valid()){
            let keycode = e.keyCode ? e.keyCode : e.which;
            let datos={ datos: { 
                value: this.getField("codigo_sucursal").getValue()
            }};
            switch (keycode) {
            //f9Servicio="43-40_2",enterServicio="43-42_2",botonServicio="43-41_2",
            case 120:
                this.generalFormatPmv = { tipo_servicio: '43', operacion: '40_2', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.sucessmodalSucursales,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
                break;
            case 13:
                this.generalFormatPmv = { tipo_servicio: '43', operacion: '42_2', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.sucessSucursales,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
                break;
            default:
                break;
            }
        }

      };
      
      sucessSucursales(data){
        this.estadoServicioCodigoProducto = false;
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('codigo_sucursal').setValue(data.data[0].codigo_sucursal);
            this.getField('nombre_sucursal').setValue(data.data[0].nombre_sucursal);
        }
      }

      sucessmodalSucursales(data){
        if(data.estado_p === 200){
            this.getField('modal_sucursales').handleClickOpen();
            this.gridOptionsComponentesSucursales['rowData'] = [];
            let configCell = new Map();
            let nuevo_array=[];
            data.data.forEach(item => {
                delete item.sucursales_id;
                nuevo_array.push(item);
            });

            this.gridOptionsComponentesSucursales['rowData'] = nuevo_array;
            this.getField('rejilla_sucursales').initData(this.gridOptionsComponentesSucursales,configCell);
        }
      }

    limpiarCamposResultados(){
        this.getField('email').setValue('');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setError(false,'');
    }

    generarDatosResultadosEstados(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let op='';
        if(this.estados_resultados!== undefined && this.estados_resultados!== null && this.estados_resultados==='S'){
            op='estadosresultadoscuentas'
        }else{
            op='estadosresultadosnormal'
        }
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: op, operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessGenerarDatosResultadosEstados,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessGenerarDatosResultadosEstados(data){
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false);
            this.getField('modal_estados').handleClickOpen();
        }
        else 
        { 
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporalEstados();
        }
    }
    //gastos
    generarDatosGastos(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'gastos', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessGenerarDatosGastos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessGenerarDatosGastos(data){
        if (data.estado_p === 200 )
        {
            let datos={ datos: {
                usuarios_codigo: this.codigo_sucursal
            }};
            this.getField("rejilla_gastos").setTipoServicioOperacion("cont-balances-gastos");
            this.getField("rejilla_gastos").sendRequest(this.sucessGenerarModalGastos, datos);
        }
        else 
        { 
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporalGastos();
        }
    }

    sucessGenerarModalGastos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.getField('modal_gastos1').handleClickOpen();
            this.gridOptionsComponentesBalanceGeneral['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesBalanceGeneral['rowData'] = data.data;
            configCell.set('enero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.enero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('febrero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.febrero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('marzo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.marzo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('abril', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.abril) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('mayo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.mayo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('junio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.junio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('julio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.julio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('agosto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.agosto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('septiembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.septiembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('octubre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.octubre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('noviembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.noviembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('diciembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.diciembre) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_gastos').initData(this.gridOptionsComponentesBalanceGeneral,configCell);
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporalGastos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporalGastos();
        } 
    }

    //analisis vertical
    generarDatosAnalisisVertical(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'analisisvertical', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessGenerarDatosAnalisisVertical,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessGenerarDatosAnalisisVertical(data){
        if (data.estado_p === 200 )
        {
            let datos={ datos: {
                usuarios_codigo: this.codigo_sucursal
            }};
            this.getField("rejilla_analisis_vertical").setTipoServicioOperacion("cont-balances-vertical");
            this.getField("rejilla_analisis_vertical").sendRequest(this.sucessGenerarModalVertical, datos);
        }
        else 
        { 
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporalGeneral();
        }
    }

    sucessGenerarModalVertical(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.getField('modal_vertical').handleClickOpen();
            this.gridOptionsComponentesBalanceGeneral['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesBalanceGeneral['rowData'] = data.data;
            configCell.set('enero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.enero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('penero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.penero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('febrero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.febrero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pfebrero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pfebrero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('marzo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.marzo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pmarzo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pmarzo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('abril', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.abril) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pabril', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pabril) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('mayo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.mayo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pmayo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pmayo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('junio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.junio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pjunio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pjunio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('julio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.julio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pjulio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pjulio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('agosto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.agosto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pagosto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pagosto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('septiembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.septiembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pseptiembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pseptiembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('octubre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.octubre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('poctubre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.poctubre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('noviembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.noviembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pnoviembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pnoviembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('diciembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.diciembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pdiciembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pdiciembre) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_analisis_vertical').initData(this.gridOptionsComponentesBalanceGeneral,configCell);
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            //sTable_byg
            this.limpiarTemporalGeneral();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            //sTable_byg
            this.limpiarTemporalGeneral();
        } 
    }

    //analisis gp
    generarDatosAnalisisGP(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'analisisestados', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessGenerarAnalisisGP,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessGenerarAnalisisGP(data){
        if (data.estado_p === 200 )
        {
            let datos={ datos: {
                usuarios_codigo: this.codigo_sucursal
            }};
            this.getField("rejilla_analisis_gp").setTipoServicioOperacion("cont-balances-gp");
            this.getField("rejilla_analisis_gp").sendRequest(this.sucessGenerarModalGP, datos);
        }
        else 
        { 
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporalResumen();
        }
    }

    sucessGenerarModalGP(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.getField('modal_gp').handleClickOpen();
            this.gridOptionsComponentesBalanceGeneral['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesBalanceGeneral['rowData'] = data.data;
            configCell.set('enero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.enero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('febrero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.febrero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('marzo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.marzo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('abril', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.abril) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('mayo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.mayo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('junio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.junio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('julio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.julio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('agosto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.agosto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('septiembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.septiembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('octubre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.octubre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('noviembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.noviembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('diciembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.diciembre) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_analisis_gp').initData(this.gridOptionsComponentesBalanceGeneral,configCell);
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            //sTable_byg
            this.limpiarTemporalResumen();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            //sTable_byg
            this.limpiarTemporalResumen();
        } 
    }

    //balance general
    generarDatosBalanceGeneral(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'balancegeneral', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessGenerarDatosBalanceGeneral,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessGenerarDatosBalanceGeneral(data){
        if (data.estado_p === 200 )
        {
            this.generarModalBalanceGeneral();
        }
        else 
        { 
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporalGeneral();
        }
    }

    generarModalBalanceGeneral(){
        let datos={ datos: {
            usuarios_codigo: this.codigo_sucursal
        }};
        this.getField("rejilla_generales").setTipoServicioOperacion("cont-balances-balance_general");
        this.getField("rejilla_generales").sendRequest(this.sucessgenerarModalBalanceGeneral, datos);
    }

    sucessgenerarModalBalanceGeneral(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.getField('modal_balance_general').handleClickOpen();
            this.gridOptionsComponentesBalanceGeneral['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesBalanceGeneral['rowData'] = data.data;
            configCell.set('enero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.enero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('febrero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.febrero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('marzo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.marzo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('abril', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.abril) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('mayo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.mayo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('junio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.junio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('julio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.julio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('agosto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.agosto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('septiembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.septiembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('octubre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.octubre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('noviembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.noviembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('diciembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.diciembre) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_generales').initData(this.gridOptionsComponentesBalanceGeneral,configCell);
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporalGeneral();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporalGeneral();
        } 
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    deshabilitarCorreo1(){
        this.getField('email1').setDisabled(true);
        this.getField('email1').setValue('');
        this.getField('email1').setError(false,'');
        this.getField('radio_correo1').setDisabled(true);
        this.getField('radio_correo1').setValue("N");
    }

    habilitarCorreo1(){
        this.getField('email1').setDisabled(false);
        this.getField('radio_correo1').setDisabled(false);
        this.getField('radio_correo1').setValue("N");
    }

    traerPropiedadEstados(){
        let datos={ datos: {sucursal_ingreso: this.codigo_sucursal}};

        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'traerpropiedadestadosresultados', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerPropiedadEstados,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successTraerPropiedadEstados(data){
        if(data.estado_p === 200){ 
            this.estados_resultados=data.data.estados_resultados;
        }else{
            this.estados_resultados="";
            //let respuesta=Object.values(data.data.errores);
            //this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        
        }
    }

    traerDatosEmpresa(){
        let datos={ datos: {
            sucursal_ingreso: this.codigo_sucursal
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'traerinfoempresa', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatosEmpresa,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successTraerDatosEmpresa(data){
        if(data.estado_p === 200){ 
            this.lista=data.data.lista;
        }else{
            this.lista={};
            //let respuesta=Object.values(data.data.errores);
            //this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        
        }
    }

    opcionBalancesGloblaes(){
        this.operacion='globales';
        this.getField('operacion_form').setValue('Balance global');
        this.getField('nivel').setVisible(true);
        this.getField('ch_pdf1').setVisible(true);
        this.getField('ch_excel1').setVisible(true);
        this.getField('ch_pantalla1').setVisible(true);
        this.getField('label_anexo').setVisible(true);
        this.getField('radio_correo1').setVisible(true);
        this.getField('email1').setVisible(true);
    }

    opcionBalancesNormal(){
        this.operacion='normal'
        this.getField('operacion_form').setValue('Balances');
        this.getField('nivel').setVisible(false);
        this.getField('ch_pdf1').setVisible(false);
        this.getField('ch_excel1').setVisible(false);
        this.getField('ch_pantalla1').setVisible(false);
        this.getField('label_anexo').setVisible(false);
        this.getField('radio_correo1').setVisible(false);
        this.getField('email1').setVisible(false);
    }


    validarfechas(){
        this.getField("fecha").setError(false,'');
        this.getField('procesar').setDisabled(false);
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha = new Date(this.getField("fecha").getValue()).toISOString().split('T')[0];


        if(fecha > fecha_actual){
            this.getField("fecha").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        let fecha_aux=new Date(fecha_actual);
        fecha_aux.setDate(fecha_aux.getDate()-this.dias_maximo);
        fecha_aux=fecha_aux.toISOString().split('T')[0];
        if(fecha < fecha_aux){
            this.getField("fecha").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        this.getField('procesar').setDisabled(false); 
        return true;

    }


    cargarFechas(){

        //let fecha = this.getField('fecha').getValue();
        //let fecha_nueva = fecha.split('-',2)[0]+'-'+fecha.split('-',2)[1]+'-01';
        this.getField('fecha').setValue(this.fecha_hoy);
    }


    procesar(){
        this.limpiarErrores();
        this.validarCampos();
    }


    validarCampos(){
        let errores =0;
        let valFechas=this.validarfechas();
        if(this.operacion==='globales'){
            if(this.getField('radio_correo1').getValue()==='S' || this.operacion_actual!=='pantalla'){
                if(this.getField('email1').valid()===false){
                    errores++;
                }
            }
            if(this.getField('ch_excel1').getValue()==='false' && this.getField('ch_pdf1').getValue()==='false' && this.getField('ch_pantalla1').getValue()==='false'){
                errores++;
                this.getField('ch_excel1').setError(true,'* Es necesario seleccionar una opción.');
                this.getField('ch_pdf1').setError(true,'* Es necesario seleccionar una opción.');
                this.getField('ch_pantalla1').setError(true,'* Es necesario seleccionar una opción.');
                this.operacion_actual='';
                this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
            }
        }
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==''){
            if(!this.getField('codigo_sucursal').valid())
                errores++;
            if(!this.getField('nombre_sucursal').valid())
                errores++;
        }
        if(valFechas===false){
            errores++;
        };

        if(errores===0){
            this.generarReporte();
        }
    }

    validarCamposEstados(){
        let errores =0;

        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }

        if(errores===0){
            this.intermediarioEstados();
        }
    }

    intermediarioEstados(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        if(this.operacion_actual!==''){
            this.operacion_normal='balance_estados';
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreoTerceros();
            }else{
                this.nombre_pdf='balancesglobales';
                this.nombre_excel='balancesglobales';
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTabla3();
                }else if(this.operacion_actual==='pantalla'){
                        this.genearModalGlobales();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelTabla3();
                } 
            }
        }else{
            this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
            this.getField('confirmModalCustom').toggle(false);
        }        
    }

    generarReporte(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_sucursales: this.getField('ch_sucursales').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal
        }};
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('nivel').getValue()!=='' && this.getField('nivel').getValue()!=='' )
            datos.datos.nivel=this.getField('nivel').getValue();
        let op=''
        if(this.operacion==='globales')
            op='balancesglobales';
        else
            op='balancesnormal';
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: op, operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessgenerarReporte,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessgenerarReporte(data){
        if (data.estado_p === 200 )
        {
            if(this.operacion==='globales'){
                if(this.operacion_actual!==''){
                    this.operacion_normal='balance_global';
                    if(this.getField('radio_correo1').getValue()==='S'){
                        this.enviarCorreoTerceros();
                    }else{
                        this.nombre_pdf='balancesglobales';
                        this.nombre_excel='balancesglobales';
                        if(this.operacion_actual==='pdf'){
                            this.generarPdfTabla3();
                        }else if(this.operacion_actual==='pantalla'){
                                this.genearModalGlobales();
                        }else if(this.operacion_actual==='excel'){
                            this.generarExcelTabla3();
                        } 
                    }
                }else{
                    this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                    this.getField('confirmModalCustom').toggle(false);
                } 
            }else{
                //normal
                this.generarDatosNormales();
            }
            
        }
        else 
        { 
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.limpiarCampos();
            }
            else {
                //this.limpiarCampos();
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporal();
        }
    }

    enviarCorreoTerceros(){
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_sucursales: this.getField('ch_sucursales').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal,
            operacion: this.operacion_actual
        }};
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        let op=''
        if(this.operacion_normal==='balance_global'){
            if(this.getField('nivel').getValue()!=='' && this.getField('nivel').getValue()!=='' )
                datos.datos.nivel=this.getField('nivel').getValue();
            op='archivoglobales';
            datos.datos.email=this.getField('email1').getValue();
            datos.datos.radio_correo=this.getField('radio_correo1').getValue();
        }else{
            //es resultados estados
            if(this.estados_resultados!== undefined && this.estados_resultados!== null && this.estados_resultados==='S'){
                op='archivoestadoscuentas';
            }else{
                op='archivoestadoscuentas';
            }
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
            if(this.estados_resultados!== undefined && this.estados_resultados!== null)
                datos.datos.estado_resultado=this.estados_resultados;
        }
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
            if(this.operacion_normal!=='balance_estados')
                this.limpiarCampos();
            else
                this.limpiarCamposResultados();
        }else{
            this.mostrarMensajeEmailFallo();
            if(this.operacion_normal!=='balance_estados')
                this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        if(this.operacion_normal!=='balance_estados')
            this.limpiarTemporal();
    }

    generarDatosNormales(){
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_sucursales: this.getField('ch_sucursales').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal
        }};
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'balancesnormal', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.sucessGenerarDatosNormales,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
    }
    
    sucessGenerarDatosNormales(data){
        if(data.estado_p === 200){
            this.genearModalNormal();
        }else if(data.estado_p === 404){
            this.getField('confirmModalCustom').toggle(false);
            this.getField('procesar').setDisabled(false);
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporal();
        }
        else{
            this.getField('confirmModalCustom').toggle(false);
            this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal();
        } 
    }

    genearModalNormal(){
        let datos={ datos: {
            usuarios_codigo: this.codigo_sucursal
        }};
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        this.getField("rejilla_balances").setTipoServicioOperacion("cont-balances-1_balance_normal");
        this.getField("rejilla_balances").sendRequest(this.sucessTraerDatosNormales, datos);
    }

    sucessTraerDatosNormales(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('procesar').setDisabled(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let data2 = 
                    {
                        "empresa": this.lista.nombre_empresa,
                        "nit": this.lista.nit,
                        "direccion": this.lista.direccion,
                        "telefonos": this.lista.telefonos,
                        "operacion": this.getField('operacion_form').getValue(),
                        "fecha": this.getField("fecha").getValue(),
                        codigo_sucursal: this.getField('codigo_sucursal').getValue()
                    };
            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);

            this.getField('modal_balances').handleClickOpen();
            this.gridOptionsComponentesBalanceNormal['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesBalanceNormal['rowData'] = data.data;
            configCell.set('anio_anterior', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.anio_anterior) }, cellStyle: {textAlign:"right"},width: 110, field: 'saldo anterior'});
            configCell.set('enero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.enero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('febrero', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.febrero) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('marzo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.marzo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('abril', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.abril) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('mayo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.mayo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('junio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.junio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('julio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.julio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('agosto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.agosto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('septiembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.septiembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('octubre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.octubre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('noviembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.noviembre) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('diciembre', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.diciembre) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_balances').initData(this.gridOptionsComponentesBalanceNormal,configCell);
        }else if(data.estado_p === 404){
            this.getField('procesar').setDisabled(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporal();
        }
        else{
            this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal();
        } 
    }

    genearModalGlobales(){
        let datos={ datos: {
            fecha: this.getField('fecha').getValue(),
            ch_sucursales: this.getField('ch_sucursales').getValue(),
            ch_cierre: this.getField('ch_cierre').getValue(),
            sucursal_ingreso: this.codigo_sucursal,
            operacion: this.operacion_actual
        }};
        let op='';
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.operacion_normal==='balance_global'){
            if(this.getField('nivel').getValue()!=='' && this.getField('nivel').getValue()!=='' )
                datos.datos.nivel=this.getField('nivel').getValue();
            op='archivoglobales';
        }else{
            //es resultados estados
            if(this.estados_resultados!== undefined && this.estados_resultados!== null && this.estados_resultados==='S'){
                op='archivoestadoscuentas';
            }else{
                op='archivoestadosnormal';
            }
        }

        if(op==='archivoestadoscuentas' || op==='archivoestadosnormal'){
            this.getField("rejilla_estados_r").setTipoServicioOperacion("cont-balances-"+op);
            this.getField("rejilla_estados_r").sendRequest(this.sucessTraerDatos, datos);
        }else if('archivoglobales'){
            this.getField("rejilla_globales").setTipoServicioOperacion("cont-balances-"+op);
            this.getField("rejilla_globales").sendRequest(this.sucessTraerDatos, datos);
        }else{

        }
        
    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('procesar').setDisabled(false);
        if(data.estado_p === 200){ 
            if(this.operacion_normal==='balance_global'){
                this.traerTotalesGlobales();
                let datosFicha = [];
                let data2 = 
                        {
                            "empresa": this.lista.nombre_empresa,
                            "nit": this.lista.nit,
                            "direccion": this.lista.direccion,
                            "telefonos": this.lista.telefonos,
                            "operacion": this.getField('operacion_form').getValue(),
                            "fecha": this.getField("fecha").getValue(),
                            codigo_sucursal: this.getField('codigo_sucursal').getValue()!==""?this.getField('codigo_sucursal').getValue():"Todas"
                        };
                datosFicha.push(data2);
                this.getField('lista_datos_globales').setItemsFichas(datosFicha);

                this.getField('modal_balances_globales').handleClickOpen();
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                this.gridOptionsComponentes['rowData'] = data.data;
                configCell.set('saldo_anterior', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_anterior) }, cellStyle: {textAlign:"right"},width: 110, field: 'saldo anterior'});
                configCell.set('movto_debito1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.movto_debito1) }, cellStyle: {textAlign:"right"},width: 110, field: 'movto débito'});
                configCell.set('movto_credito1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.movto_credito1) }, cellStyle: {textAlign:"right"},width: 110, field: 'movto crédito'});
                configCell.set('movto_debito2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.movto_debito2) }, cellStyle: {textAlign:"right"},width: 110, field: 'movto débito'});
                configCell.set('movto_credito2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.movto_credito2) }, cellStyle: {textAlign:"right"},width: 110, field: 'movto crédito'});
                configCell.set('saldo_final', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo_final) }, cellStyle: {textAlign:"right"},width: 110, field: 'saldo final'});

                this.getField('rejilla_globales').initData(this.gridOptionsComponentes,configCell);
            }else{
                if(this.estados_resultados!== undefined && this.estados_resultados!== null && this.estados_resultados==='S'){
                    //no se traen totales
                    this.getField('label_utilidad1').setVisible(false);
                    this.getField('total_utilidad_bruta_mes').setVisible(false);
                    this.getField('total_utilidad_bruta_acumulado').setVisible(false);
                    this.getField('label_utilidad2').setVisible(false);
                    this.getField('utilidad_antes_impuestos_mes').setVisible(false);
                    this.getField('utilidad_antes_impuestos_acumulado').setVisible(false);
                }else{
                    //traer totales
                    this.totalesEstadosResultados();
                }
                //estados resultados
                this.getField('modal_rejilla_estados').handleClickOpen();
                this.gridOptionsComponentes['rowData'] = [];
                let configCell = new Map();
                configCell.set('mes', {cellStyle: {textAlign:"right"},width: 110});
                configCell.set('acumulado', {cellStyle: {textAlign:"right"},width: 110});
                configCell.set('porcentaje1', {cellStyle: {textAlign:"right"},width: 110});
                configCell.set('porcentaje2', {cellStyle: {textAlign:"right"},width: 110});

                this.gridOptionsComponentes['rowData'] = data.data;
                this.getField('rejilla_estados_r').initData(this.gridOptionsComponentes,configCell);
            }
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
            if(this.operacion_normal!=='balance_estados')
                this.limpiarTemporal();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            if(this.operacion_normal!=='balance_estados')
                this.limpiarTemporal();
        } 
    }

    totalesEstadosResultados(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'archivoestadosnormal_totales', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTotalesEstadosResultados,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successTotalesEstadosResultados(data){
        if(data.estado_p === 200){ 
            this.getField('label_utilidad1').setVisible(true);
            this.getField('total_utilidad_bruta_mes').setVisible(true);
            this.getField('total_utilidad_bruta_acumulado').setVisible(true);
            this.getField('label_utilidad2').setVisible(true);
            this.getField('utilidad_antes_impuestos_mes').setVisible(true);
            this.getField('utilidad_antes_impuestos_acumulado').setVisible(true);
            data.data.forEach(item => {
                if(item.nombre==='TOTAL UTILIDAD BRUTA OPERACIONAL'){
                    this.getField('total_utilidad_bruta_mes').setValue(item.texto1);
                    this.getField('total_utilidad_bruta_acumulado').setValue(item.texto2);
                }else if(item.nombre==='UTILIDAD O PERDIDA NETA ANTES DE IMPUESTOS'){
                    this.getField('utilidad_antes_impuestos_mes').setValue(item.texto1);
                    this.getField('utilidad_antes_impuestos_acumulado').setValue(item.texto2);
                }
            });
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    traerTotalesGlobales(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: 'archivoglobales_totales', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerTotalesGlobales,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successTraerTotalesGlobales(data){
        if(data.estado_p === 200){ 
            this.getField('saldo_anterior').setValue(this.currencyFormatterGeneral(data.data[0].saldo_anterior));
            this.getField('movto_debito1').setValue(this.currencyFormatterGeneral(data.data[0].movto_debito1));
            this.getField('movto_credito1').setValue(this.currencyFormatterGeneral(data.data[0].movto_credito1));
            this.getField('movto_debito2').setValue(this.currencyFormatterGeneral(data.data[0].movto_debito2));
            this.getField('movto_credito2').setValue(this.currencyFormatterGeneral(data.data[0].movto_credito2));
            this.getField('saldo_final').setValue(this.currencyFormatterGeneral(data.data[0].saldo_final));
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }



    limpiarTemporal(){
        let datos={ datos: {
        }};
        let op='';
        if(this.operacion==='globales')
            op='7_1';
        else
            op='7_2';
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: op, operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    limpiarTemporalResumen(){
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: '7_gp', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    
    limpiarTemporalGeneral(){
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: '7_general', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    limpiarTemporalGastos(){
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: '7_gastos', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    limpiarTemporalEstados(){
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-balances', operacion: '7_estados_cuentas', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp(data){
        this.getField('procesar').setDisabled(false)
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    limpiarCampos(){
        this.getField('ch_sucursales').setValueSwitch(true);
        this.getField('ch_cierre').setValueSwitch(false);
        this.getField('codigo_sucursal').setValue('');
        this.getField('nombre_sucursal').setValue('');
        this.getField('email').setValue('');
        this.getField('email1').setValue('');
        this.getField('nivel').setValue('');
        this.getField('radio_correo1').setValue("N");
        this.getField('ch_excel1').setValueSwitch(false);
        this.getField('ch_pdf1').setValueSwitch(false);
        this.getField('ch_pantalla1').setValueSwitch(false);
        this.getField('radio_correo1').setDisabled(false);
        this.getField('email').setDisabled(false);
        this.limpiarErrores();
        this.cargarFechas();
    }

      limpiarErrores(){
        this.getField('ch_sucursales').setError(false,'');
        this.getField('ch_cierre').setError(false,'');
        this.getField('fecha').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('nombre_sucursal').setError(false,'');
        this.getField('nivel').setError(false,'');
        this.getField('email').setError(false,'');
      }

      currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
    
    generarPdfTabla3()
    {       
            let url = this.constant.formConfiguration();
            let datos2= {
                fecha: this.getField('fecha').getValue(),
                ch_sucursales: this.getField('ch_sucursales').getValue(),
                ch_cierre: this.getField('ch_cierre').getValue(),
                sucursal_ingreso: this.codigo_sucursal,
                operacion: this.operacion_actual,
            };
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            let op='';
            if(this.operacion_normal==='balance_global'){
                if(this.getField('nivel').getValue()!=='' && this.getField('nivel').getValue()!=='' )
                    datos2.nivel=this.getField('nivel').getValue();
                op='archivoglobales';
                datos2.email=this.getField('email1').getValue();
            }else{
                //es resultados estados
                if(this.estados_resultados!== undefined && this.estados_resultados!== null && this.estados_resultados==='S'){
                    op='archivoestadoscuentas';
                }else{
                    op='archivoestadoscuentas';
                }
                datos2.email=this.getField('email').getValue();
                if(this.estados_resultados!== undefined && this.estados_resultados!== null)
                    datos2.estado_resultado=this.estados_resultados;
            }

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-balances","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                            if(this.operacion_normal!=='balance_estados'){
                                this.limpiarCampos();
                                this.limpiarTemporal();
                            }
                            else
                                this.limpiarCamposResultados();
                        } 
                    });
                }else{
                    if(this.operacion_normal!=='balance_estados')
                        this.limpiarTemporal();
                    if(this.operacion_normal!=='balance_estados')
                        this.getField('procesar').setDisabled(false);
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                if(this.operacion_normal!=='balance_estados')
                    this.limpiarTemporal();
                if(this.operacion_normal!=='balance_estados')
                    this.getField('procesar').setDisabled(false);
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
            let url = this.constant.formConfiguration();
            let datos2= {
                fecha: this.getField('fecha').getValue(),
                ch_sucursales: this.getField('ch_sucursales').getValue(),
                ch_cierre: this.getField('ch_cierre').getValue(),
                sucursal_ingreso: this.codigo_sucursal,
                operacion: this.operacion_actual
            };
            let op='';
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.operacion_normal==='balance_global'){
                if(this.getField('nivel').getValue()!=='' && this.getField('nivel').getValue()!=='' )
                    datos2.nivel=this.getField('nivel').getValue();
                op='archivoglobales';
                datos2.email=this.getField('email1').getValue();
            }else{
                //es resultados estados
                if(this.estados_resultados!== undefined && this.estados_resultados!== null && this.estados_resultados==='S'){
                    op='archivoestadoscuentas';
                }else{
                    op='archivoestadoscuentas';
                }
                datos2.email=this.getField('email').getValue();
                if(this.estados_resultados!== undefined && this.estados_resultados!== null)
                    datos2.estado_resultado=this.estados_resultados;
            }
            
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-balances","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_excel+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                            if(this.operacion_normal!=='balance_estados'){
                                this.limpiarCampos();
                                this.limpiarTemporal();
                            }else
                                this.limpiarCamposResultados();
                        } 
                    });
                }else{
                    if(this.operacion_normal!=='balance_estados')
                        this.limpiarTemporal();
                    if(this.operacion_normal!=='balance_estados')
                        this.getField('procesar').setDisabled(false);
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                if(this.operacion_normal!=='balance_estados'){
                    this.getField('procesar').setDisabled(false);
                    this.limpiarCampos();
                    this.limpiarTemporal();
                }
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

}
FormJaivana.addController("cont-balances", CustomBalances);
export default CustomBalances;