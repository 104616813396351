import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomArticulos
 * @author: Patricia Lopez Sanchez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomOpcionesJaivana extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                         = this.initForm.bind(this);
        this.servicioTraerPrimero                             = this.servicioTraerPrimero.bind(this);
        this.successServicioTraerPrimero                      = this.successServicioTraerPrimero.bind(this);
        this.servicioTodo                                     = this.servicioTodo.bind(this);
        this.successServicioTodo                              = this.successServicioTodo.bind(this);

        this.gridOptionsComponentes                           = Object.assign({}, this.gridOptions);
        this.onSelectionChanged                               =   this.onSelectionChanged.bind(this);
        this.filaSeleccionada                                 = [];

        this.successDataTable                                 = this.successDataTableOpcionesJaivana.bind(this);
        this.onClickChange                                    = this.handlerClickChange.bind(this);
        this.onClickShowTable                                 = this.handerClickShowTable.bind(this);
        this.onClickNew                                       = this.handlerClickNew.bind(this);
        this.onClickDelete                                    = this.handlerClickDelete.bind(this);
        this.btnCancelar                                      = this.btnCancelar.bind(this);
        this.btnModificar                                     = false;
        this.dataTableArticulosVariosSeleccion                = false;
        this.validarRegistroSeleccionadoModificar             = this.validarRegistroSeleccionadoModificar.bind(this);
        this.mostrarMensajeSeleccionModificar                 = this.mostrarMensajeSeleccionModificar.bind(this);

        this.validarRegistroSeleccionadoEliminar              = this.validarRegistroSeleccionadoEliminar.bind(this);
        this.mostrarMensajeSeleccionEliminar                  = this.mostrarMensajeSeleccionEliminar.bind(this);
        this.confirmarEliminacion                             = this.confirmarEliminacion.bind(this);
        this.prevalidarEliminarIdJdeskRolesOpciones           = this.prevalidarEliminarIdJdeskRolesOpciones.bind(this);
        this.successPrevalidarEliminarIdJdeskRolesOpciones    = this.successPrevalidarEliminarIdJdeskRolesOpciones.bind(this);

        this.validacionUnique                                 = this.validacionUnique.bind(this);
        this.sucessValidarUnico                               = this.sucessValidarUnico.bind(this);
        this.prevalidarEliminarIdJdeskUsuariosOpciones        = this.prevalidarEliminarIdJdeskUsuariosOpciones.bind(this);
        this.successPrevalidarEliminarIdJdeskUsuariosOpciones = this.successPrevalidarEliminarIdJdeskUsuariosOpciones.bind(this);
        this.successEliminarIdJdeskOpciones                   = this.successEliminarIdJdeskOpciones.bind(this);
        this.successCrearJdeskOpcion                          = this.successCrearJdeskOpcion.bind(this);
        this.successModificarOpcionJaivana                    = this.successModificarOpcionJaivana.bind(this);
        this.validarDescripcion                               = this.validarDescripcion.bind(this);
        this.TraerOpcionPrincipal                             = this.TraerOpcionPrincipal.bind(this);
        this.successTraerOpcionJaivana                        = this.successTraerOpcionJaivana.bind(this);
        this.successModificarEstadoOpcionJaivana              = this.successModificarEstadoOpcionJaivana.bind(this);

        this.sobrescribir=false;


    }
    
    initForm(){
        console.log('Formulario CustomOpcionesJaivana,  @version: jdesk_1.01.0001, @author:Jose Albeiro Marin');
        this.servicioTraerPrimero();
        this.getField('btn_cancelar').setClick(this.btnCancelar);
        this.getField('btn_agregar').setClick(() => { this.sobrescribir = true;this.validacionUnique();});
        this.getField('btn_modificar').setClick(() => { this.sobrescribir = false;this.validacionUnique();});
        this.getField('btn_cancelar_modificar').setClick(() => { this.btnModificar = false; this.btnCancelar(); });
        this.getField('modulo_formulario').setOnChange(this.validarDescripcion);
        

    }

    validarDescripcion(){
        if(this.getField('modulo_formulario').getValue() ===''){
            this.getField('principal').setValue('');
            this.getField('seccion').setValue('');
            this.getField('estadosactivos_id').setValue('');
            
        }else{
            this.TraerOpcionPrincipal();
        }
    }

    TraerOpcionPrincipal(){
        let datos={ datos: {
            value:this.getField('descripcion').getValue()
        }}    
        this.generalFormatPmv = { tipo_servicio: 'heus-jdeskopciones', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerOpcionJaivana,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    successTraerOpcionJaivana(data){
        // console.log("data",data);
        this.getField('principal').setValue(data.data[0].nombre_seccion_principal);
        // this.getField("secciones_padre_id").setValue(data.data[0].seccion_principal)
        this.getField('seccion').setValue(data.data[0].nombre_seccion);
        this.getField('estadosactivos_id').setValue(data.data[0].estadosactivos_id);

    }
    successDataTableOpcionesJaivana(data){
        // console.log("dataTablaCrud---> ", data);
        if(data.model && data.model.length>0){

            this.gridOptionsTableDefault['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            this.gridOptionsTableDefault['onSelectionChanged'] =this.onSelectionChanged;
            
            this.getField('tableShowData').initData(this.gridOptionsTableDefault);//esta configuracion se carga en la tabla por defecto del crud

        }else{
            this.getField('tableShowData').toggle(false); 
        }
    }

    onSelectionChanged = () =>{
        this.filaSeleccionada = this.gridOptionsTableDefault.api.getSelectedRows();
        // console.log("entroo",this.filaSeleccionada);
        this.getField("descripcion").setValue(this.filaSeleccionada[0].descripcion);
        this.getField("principal").setValue(this.filaSeleccionada[0].principal);
        this.getField("seccion").setValue(this.filaSeleccionada[0].seccion);
        this.getField("estadosactivos_id").setValue(this.filaSeleccionada[0].estadosactivos_id);
        this.getField("jdeskopciones_id").setValue(this.filaSeleccionada[0].jdeskopciones_id);

        this.dataTableArticulosVariosSeleccion = true;

    }

    handlerClickChange(props) {
        // console.log("props modificar",props)
        this.btnModificar = true;
        this.validarRegistroSeleccionadoModificar();
    }

    validarRegistroSeleccionadoModificar() {
        if (this.dataTableArticulosVariosSeleccion) {
            this.validarModificarArticulosVarios();
        } else {   
            this.mostrarMensajeSeleccionModificar();
        }
    }
    
    validarModificarArticulosVarios() {
        
        this.buttons.get("btn_new").ref.current.setVisible(false);
        this.buttons.get("btn_show").ref.current.setVisible(false);
        this.buttons.get("btn_change").ref.current.setVisible(false);
        this.buttons.get("btn_delete").ref.current.setVisible(false);

        this.getField("descripcion").setValue(this.filaSeleccionada[0].descripcion)
        this.getField("principal").setValue(this.filaSeleccionada[0].principal)
        this.getField('seccion').setValue(this.filaSeleccionada[0].seccion);
        this.getField('estadosactivos_id').setValue(this.filaSeleccionada[0].estadosactivos_id);


        this.getField('descripcion').setDisabled(true);
        this.getField('principal').setDisabled(true);
        this.getField('seccion').setDisabled(true);
        this.getField('estadosactivos_id').setDisabled(false);

        this.getField('btn_modificar').setVisible(true);
        this.getField('btn_cancelar_modificar').setVisible(true);
        this.getField('btn_modificar').setDisabled(false);
        this.getField('btn_cancelar_modificar').setDisabled(false);

        this.gridOptionsTableDefault['rowData'] = [];

        this.getField('tableShowData').initData(this.gridOptionsTableDefault);
        this.getField('tableShowData').toggle(false);
    }

    mostrarMensajeSeleccionModificar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para modificar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    handerClickShowTable(props) {
        this.servicioTodo();
    }

    handlerClickNew(props) {
        // console.log("clickNuevo");
        this.getField('seccion').setDisabled(true);
        this.buttons.get("btn_new").ref.current.setVisible(false);
        this.buttons.get("btn_show").ref.current.setVisible(false);
        this.buttons.get("btn_change").ref.current.setVisible(false);
        this.buttons.get("btn_delete").ref.current.setVisible(false);
        this.getField('descripcion').setValue('');
        this.getField('principal').setValue('');
        this.getField('seccion').setValue('');
        this.getField('estadosactivos_id').setValue('');

        this.getField('descripcion').setDisabled(false);
        this.getField('principal').setDisabled(true);
        this.getField('seccion').setDisabled(true);
        this.getField('estadosactivos_id').setDisabled(false);

        this.getField('btn_agregar').setVisible(true);
        this.getField('btn_cancelar').setVisible(true);
        this.getField('btn_agregar').setDisabled(false);
        this.getField('btn_cancelar').setDisabled(false);

        this.gridOptionsTableDefault['rowData'] = [];

        this.getField('tableShowData').initData(this.gridOptionsTableDefault);
        this.getField('tableShowData').toggle(false);
    }

    handlerClickDelete(props){
        // console.log("IngresoAccioneliminar");
        this.btnEliminar = true;
        this.validarRegistroSeleccionadoEliminar();
    }

    validarRegistroSeleccionadoEliminar(){
        if (this.dataTableArticulosVariosSeleccion) {
            this.confirmarEliminacion();
        } else {   
            this.mostrarMensajeSeleccionEliminar();
        }
    }

    mostrarMensajeSeleccionEliminar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para eliminar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmarEliminacion(){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(this.prevalidarEliminarIdJdeskRolesOpciones);
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');       
        this.getField('confirmModalCustom').toggle(true);
    }

    prevalidarEliminarIdJdeskRolesOpciones(){
        let datos={ datos: {
            jdeskopciones_id:this.filaSeleccionada[0].jdeskopciones_id
        }}    
        this.generalFormatPmv = { tipo_servicio: 'heus-rolesopciones', operacion: '7_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successPrevalidarEliminarIdJdeskRolesOpciones,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    successPrevalidarEliminarIdJdeskRolesOpciones(data){
        if (data.data[0].cuantos === 0){
            this.prevalidarEliminarIdJdeskUsuariosOpciones();

        }else {
            this.alertGeneral.toggle(true,'* Este jdeskopciones_id existe en alguno(s) jdesk_roles_opciones, no se permite eliminar', 'error')
            this.getField('confirmModalCustom').toggle(false);

        }
    }

    prevalidarEliminarIdJdeskUsuariosOpciones(){
        let datos={ datos: {
            jdeskopciones_id:this.filaSeleccionada[0].jdeskopciones_id
        }}    
        this.generalFormatPmv = { tipo_servicio: 'heus-usuariospmvopciones', operacion: '7_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successPrevalidarEliminarIdJdeskUsuariosOpciones,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successPrevalidarEliminarIdJdeskUsuariosOpciones(data){
        if (data.data[0].cuantos === 0){

            let datos={ datos: {jdeskopciones_id:this.filaSeleccionada[0].jdeskopciones_id}};
            this.generalFormatPmv = { tipo_servicio: 'heus-jdeskopciones', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminarIdJdeskOpciones,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else {
            this.alertGeneral.toggle(true,'* Este jdeskopciones_id existe en alguno(s) jdesk_usuarios_pmv_opciones', 'error');
            this.getField('confirmModalCustom').toggle(false);

        }
    }

    successEliminarIdJdeskOpciones(data){
if (data.estado_p === 200) {
    this.getField('confirmModalCustom').toggle(false);
    this.servicioTodo();
}
    }
    btnCancelar() {
        this.getField('confirmModalCustom').toggle(false);

        this.getField('btn_agregar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);

        this.buttons.get("btn_new").ref.current.setDisabled(false);
        this.buttons.get("btn_show").ref.current.setDisabled(false);
        this.buttons.get("btn_delete").ref.current.setDisabled(false);

        this.getField('descripcion').setDisabled(true);
        this.getField('principal').setDisabled(true);
        this.getField('seccion').setDisabled(true);
        this.getField('estadosactivos_id').setDisabled(true);

        this.getField('descripcion').setError(false, '');
        this.getField('principal').setError(false, '');
        this.getField('seccion').setError(false, '');
        this.getField('estadosactivos_id').setError(false, '');

        if (!this.btnModificar) {
            this.getField('descripcion').setValue('');
            this.getField('estadosactivos_id').setValue('');
            this.getField('secciones_padre_id').setValue('')
            this.servicioTraerPrimero();
        }

        this.buttons.get("btn_new").ref.current.setVisible(true);
        this.buttons.get("btn_show").ref.current.setVisible(true);
        this.buttons.get("btn_change").ref.current.setVisible(true);
        this.buttons.get("btn_delete").ref.current.setVisible(true);


        this.gridOptionsTableDefault["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTableDefault);
        this.getField('tableShowData').toggle(false);

        this.dataTableArticulosVariosSeleccion = false;
        this.servicioTodo();

        this.btnModificar = false;
    }

    servicioTraerPrimero() {
        // console.log("entro peticion primero");
        let datos = { datos: {} };

        this.generalFormatPmv = { tipo_servicio: 'heus-jdeskopciones', operacion: '3', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successServicioTraerPrimero,
                error: this.error_,
                general: this.generalFormatPmv
            });

    }

    successServicioTraerPrimero(data) {

        if (data.estado_p === 200 && data.data.length >= 1) {
            // console.log("SucessTraerPrimero",data);
            this.getField('descripcion').setValue(data.data[0].descripcion);
            this.getField('principal').setValue(data.data[0].principal);
            this.getField('seccion').setValue(data.data[0].seccion);
            this.getField('estadosactivos_id').setValue(data.data[0].estadosactivos_id);
        }
    }

    servicioTodo() {
        // console.log("entro peticion service todo")
        let datos = { datos: {} };

        this.generalFormatPmv = { tipo_servicio: 'heus-jdeskopciones', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successServicioTodo,
                error: this.error_,
                general: this.generalFormatPmv
            });

    }

    successServicioTodo(data) {

        if (data.estado_p === 200 && data.data.length >= 1) {
            // console.log("TraerTodo",data);
            let configCell = new Map();
            configCell.set('descripcion', { headerName: 'Descripción'});
            configCell.set('seccion', { headerName: 'Sección'});
            configCell.set('estado_descripcion', { headerName: 'Estado descripción'});

            this.gridOptionsTableDefault['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            this.gridOptionsTableDefault['onSelectionChanged'] =this.onSelectionChanged;
            this.getField('tableShowData').initData(this.gridOptionsTableDefault, configCell);//esta configuracion se carga en la tabla por defecto del crud
        }else{
                this.getField('tableShowData').toggle(false); 
            }
    }

    validacionUnique(){
        if (this.getField('descripcion').valid() &&
            this.getField('principal').valid() && this.getField('seccion').valid() &&
            this.getField('estadosactivos_id').valid()) {
            let operacion= '20';
            let datos = { datos: {} };
            if(this.sobrescribir===false){
                operacion='21';
                datos.datos['jdeskopciones_id'] = this.getField('jdeskopciones_id').getValue();
            }
            datos.datos['principal'] = this.getField('principal').getValue();
            datos.datos['seccion'] = this.getField('seccion').getValue();
            datos.datos['descripcion'] = this.getField('descripcion').getValue();

            this.generalFormatPmv = { tipo_servicio: 'heus-jdeskopciones', operacion: operacion, operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.sucessValidarUnico,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }

    }
    sucessValidarUnico(data){
        if (data.estado_p === 200) {
            if (this.sobrescribir === true){
                let datos={ datos: {
                    jdeskopciones_id: this.getField("jdeskopciones_id").getValue(),
                    estadosactivos_id: this.getField("estadosactivos_id").getValue()
                } };
                this.generalFormatPmv = { tipo_servicio: 'heus-jdeskopciones', operacion: '6_1', operacion_tipo: 'modificar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'PUT',
                        body: datos,
                        success: this.successModificarEstadoOpcionJaivana,
                        error: this.error_,
                        general: this.generalFormatPmv,
                });
            }

        }else if(data.estado_p === 404 && this.sobrescribir===true){
            let datos={ datos: {
                descripcion: this.getField("descripcion").getValue(),
                principal: this.getField("principal").getValue(),
                seccion: this.getField("seccion").getValue(),
                estadosactivos_id: this.getField("estadosactivos_id").getValue()
            } };
            this.generalFormatPmv = { tipo_servicio: 'heus-jdeskopciones', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successCrearJdeskOpcion,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
        }else if(this.sobrescribir === false){
            let datos={ datos: {
                jdeskopciones_id: this.getField("jdeskopciones_id").getValue(),
                descripcion: this.getField("descripcion").getValue(),
                principal: this.getField("principal").getValue(),
                seccion: this.getField("seccion").getValue(),
                estadosactivos_id: this.getField("estadosactivos_id").getValue()
            } };
            this.generalFormatPmv = { tipo_servicio: 'heus-jdeskopciones', operacion: '6_1', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successModificarOpcionJaivana,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
        }
    }
    successCrearJdeskOpcion(data){
        if (data.estado_p === 200) {
            this.sobrescribir=true
            this.btnCancelar();
            
        }
        this.getField('confirmModalCustom').toggle(false);

    }

    successModificarEstadoOpcionJaivana(data){
        if (data.estado_p === 200) {
            this.sobrescribir=true
            this.btnCancelar();
            
        }        
    }

    successModificarOpcionJaivana(data){
        // console.log("succcessModificar",data);
        this.sobrescribir=false
        this.btnCancelar();
    }

}
FormJaivana.addController("heus-jdeskopciones",CustomOpcionesJaivana);
export default CustomOpcionesJaivana