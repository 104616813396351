import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomCuadreDeOtrosCuadresCarteraProveedores
 * @author: Anderson Acevedo
 * @version: jdesk_1.01.0001.1
 **/



class CustomCuadreDeOtrosCuadresCarteraProveedores extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.onSelectionChanged                                     = this.onSelectionChanged.bind(this);
        this.habilitar                                              = this.habilitar.bind(this);
        this.validarfechas                                          = this.validarfechas.bind(this);
        this.procesar                                               = this.procesar.bind(this);
        this.cargarFechas                                           = this.cargarFechas.bind(this);
        this.limpiarErrores                                         = this.limpiarErrores.bind(this);
        this.validarCamposCuadreCaja                                = this.validarCamposCuadreCaja.bind(this);
        this.crearTabla                                             = this.crearTabla.bind(this);
        this.sucessCrearTabla                                       = this.sucessCrearTabla.bind(this);
        this.generarDatosCaja                                       = this.generarDatosCaja.bind(this);
        this.sucessgenerarDatosCaja                                 = this.sucessgenerarDatosCaja.bind(this);
        this.genearModalCaja                                        = this.genearModalCaja.bind(this);
        this.successGenerarModalCaja                                = this.successGenerarModalCaja.bind(this);
        this.limpiarCampos                                          = this.limpiarCampos.bind(this);
        this.gridOptionsComponentes                                 = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                                      = this.formatNumberSaldo.bind(this);
        this.seleccionValor                                         = this.seleccionValor.bind(this);
        this.successDeleteTmp                                       = this.successDeleteTmp.bind(this);
        this.limpiarTemporal                                        = this.limpiarTemporal.bind(this);
        this.generarDatosIniciales                                  = this.generarDatosIniciales.bind(this);
        this.sucessgenerarDatosIniciales                            = this.sucessgenerarDatosIniciales.bind(this);
        this.traerDias                                              = this.traerDias.bind(this);
        this.successTraerDias                                       = this.successTraerDias.bind(this);
        this.dias_maximo=0;

    }



    initForm() {
        console.log("Formulario CustomCuadreDeOtrosCuadresCarteraProveedores,  @version: jdesk_1.01.0001.1, @author: Anderson Acevedo ");
        this.cargarFechas();
        this.getField("fecha_desde").setOnChange(this.validarfechas);
        this.getField("fecha_hasta").setOnChange(this.validarfechas);
        this.getField("fecha_hasta").setDisabled(true);
        this.getField("nombre_cuenta_contable").setOnChange(this.habilitar);
        this.getField("nombre_sucursal").setOnChange(this.habilitar);
        this.getField("ch_niif").setOnChange(this.habilitar);
        this.traerDias();
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;

        this.getField('procesar').setClick(this.procesar);
        //this.getField('modal_caja').setCloseButton(this.limpiarTemporal);
    }

    onSelectionChanged(){
        
    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-cuadredecaja', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.cuadre_caja_dias_devolver;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }



    habilitar(){
        this.getField('procesar').setDisabled(false);
        //.getField('rejilla').toggle(false);

        if(this.getField("nombre_cuenta_contable").getValue() !== ''){
            this.getField("nombre_cuenta_contable").valid(false)
        }
        if(this.getField("nombre_sucursal").getValue() !== ''){
            this.getField("nombre_sucursal").valid(false)
        }
        if(this.getField("nombre_sucursal").getValue() !== ''){
            this.getField("nombre_sucursal").valid(false)
        }
    }

    validarfechas(){
        this.habilitar();
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("fecha_desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("fecha_hasta").getValue()).toISOString().split('T')[0];

        this.getField("fecha_desde").setError(false,"");
        this.getField("fecha_hasta").setError(false,"");

        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_hasta > fecha_actual){
            this.getField("fecha_hasta").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        let fecha=new Date(fecha_actual);
        fecha.setDate(fecha.getDate()-this.dias_maximo);
        fecha=fecha.toISOString().split('T')[0];
        if(fecha_desde < fecha){
            this.getField("fecha_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        this.getField('procesar').setDisabled(false); 
        return true;

    }


    cargarFechas(){

        //let fecha_desde = this.getField('fecha_desde').getValue();
        //let fecha_desde_nueva = fecha_desde.split('-',2)[0]+'-'+fecha_desde.split('-',2)[1]+'-01';
        this.getField('fecha_desde').setValue(this.fecha_hoy);
    }


    procesar(){
        this.limpiarErrores();
        this.validarCamposCuadreCaja();
    }
    validarCamposCuadreCaja(){
        let errores =0;
        
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('cuenta_contable').valid()===false){
            errores++;
        }
        if(this.getField('nombre_cuenta_contable').valid()===false){
            errores++;
        }
        if(this.getField('codigo_sucursal').getValue()!==''){
            if(this.getField('codigo_sucursal').valid()===false 
            || this.getField('nombre_sucursal').valid()===false){
                errores++;
            }
        }
        if(errores===0){
            this.crearTabla();
        }
    }

    crearTabla(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
        }};
            this.generalFormatPmv = { tipo_servicio: 'cont-cuadrescarteraproveedores', operacion: 'creartemporal', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessCrearTabla,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }
    
    sucessCrearTabla(data){
          if (data.estado_p === 200 )
          {
            this.generarDatosIniciales(); 
          }
          else 
          {
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
          }
          
      }

      generarDatosIniciales(){
        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            cuenta_contable:this.getField('cuenta_contable').getValue(),
            ch_niif:this.getField('ch_niif').getValue()
        }};
        if(this.getField('codigo_sucursal').getValue()!=='')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue()
        this.generalFormatPmv = { tipo_servicio: 'cont-cuadrescarteraproveedores', operacion: 'generardatos1', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessgenerarDatosIniciales,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessgenerarDatosIniciales(data){
        if (data.estado_p === 200 )
        {
           this.generarDatosCaja();
        }
        else 
        { 
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                this.limpiarCampos();
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporal();
        }
    }

      generarDatosCaja(){
        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            cuenta_contable:this.getField('cuenta_contable').getValue(),
            ch_niif:this.getField('ch_niif').getValue()
        }};
        if(this.getField('codigo_sucursal').getValue()!=='')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue()
        this.generalFormatPmv = { tipo_servicio: 'cont-cuadrescarteraproveedores', operacion: 'generardatos', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessgenerarDatosCaja,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessgenerarDatosCaja(data){
        if (data.estado_p === 200 )
        {
           this.genearModalCaja();
        }
        else 
        { 
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                this.limpiarCampos();
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.limpiarTemporal();
        }
    }

    limpiarTemporal(){
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-cuadrescarteraproveedores', operacion: '7_1', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp(data){
        //tmp_conta1_codigousuario
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    genearModalCaja(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-cuadrescarteraproveedores', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGenerarModalCaja,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successGenerarModalCaja(data){
        this.limpiarTemporal();
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let data2 = 
                    {
                        "nombre": "Cuadre de caja",
                        "desde-hasta": this.getField('fecha_desde').getValue()+"-"+this.getField('fecha_hasta').getValue(),
                        "codigo_cuenta": this.getField('cuenta_contable').getValue(),
                        "codigo_sucursal": this.getField('codigo_sucursal').getValue()===''?'':this.getField('codigo_sucursal').getValue(),
                    };
            datosFicha.push(data2);

            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('tabla_caja').toggle(true);
            let configCell = new Map();
            configCell.set('valor',{cellStyle: {textAlign:"right"},cellRenderer: this.seleccionValor,field: 'valor'});

            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('tabla_caja').initData(this.gridOptionsComponentes, configCell);
            this.getField('modal_caja').handleClickOpen();
            this.limpiarCampos();
        }else if (data.estado_p === 404){
            this.getField('procesar').setDisabled(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else{
            this.limpiarCampos();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        
    }

    limpiarCampos(){
        this.getField('cuenta_contable').setValue('');
        this.getField('nombre_cuenta_contable').setValue('');
        this.getField('codigo_sucursal').setValue('');
        this.getField('nombre_sucursal').setValue('');
        this.getField('procesar').setDisabled(false);
        this.getField('fecha_desde').setValue(this.fecha_hoy);
        this.getField('fecha_hasta').setValue(this.fecha_hoy);
        this.getField('ch_niif').setValue('false');
        this.limpiarErrores();
        this.cargarFechas();
    }

      limpiarErrores(){
        this.getField('cuenta_contable').setError(false,'');
        this.getField('nombre_cuenta_contable').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('nombre_sucursal').setError(false,'');
      }

    seleccionValor(props){
        let valor = props.data.valor;

        let div = document.createElement('div');
        div.setAttribute("class","divStyle6");
        div.textContent = ""+this.currencyFormatterGeneral(valor);//saldo;
        return this.createElementJaivana(div);
    
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
    

}
FormJaivana.addController("cont-cuadrescarteraproveedores", CustomCuadreDeOtrosCuadresCarteraProveedores);
export default CustomCuadreDeOtrosCuadresCarteraProveedores;