import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomFacturacionElctronica
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0001
 **/
class CustomFacturacionElctronica extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.seleccionar                = this.seleccionar.bind(this);
        this.llenarencabezado           = this.llenarencabezado.bind(this);
        this.habilitar                  = this.habilitar.bind(this);
        this.deshabilitar               = this.deshabilitar.bind(this);
        this.actualizar                 = this.actualizar.bind(this);
        this.successactualizo           = this.successactualizo.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
        this.Dv                         = this.Dv.bind(this);
    }

    initForm(){
        console.log('Formulario CustomFacturacionElctronica,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez')

        this.arrayCampos.set(3,'contenedorDatos1');
        this.arrayCampos.set(4,'nit_buscar');
        this.arrayCampos.set(5,'sede');
        this.arrayCampos.set(6,'verifica1');
        this.arrayCampos.set(7,'nombre');
        this.arrayCampos.set(8,'seleccionar');
        this.arrayCampos.set(10,'cancelar');

        this.arrayObjetos.set(3,'contenedorDatos1');
        this.arrayObjetos.set(4,'nit_buscar');
        this.arrayObjetos.set(5,'sede');
        this.arrayObjetos.set(6,'verifica1');
        this.arrayObjetos.set(7,'nombre');
        this.arrayObjetos.set(8,'seleccionar');
        this.arrayObjetos.set(10,'cancelar');
        
        this.deshabilitar();
        this.establecerPropiedades('3,4,5,6,7,8,10','visible','true')
        this.getField('seleccionar').setClick(this.seleccionar); 
        this.getField('cancelar').setClick(this.limpiar);
        this.getField('actualizar').setClick(this.actualizar);
        this.getField('cancelar1').setClick(this.limpiar);
        this.getField('verifica1').setOnChange(()=>{this.getField('verifica1').valid() ? this.getField('verifica1').setError(false,"") : this.getField('verifica1').valid();})
   
        this.getField('verifica1').setKeyUp(this.Dv);
    }

    seleccionar(){
        if(this.getField('nombre').getValue() !== '' && this.getField('verifica1').valid()){
            this.nit = this.getField('nit_buscar').getValue();
            this.sede = this.getField('sede').getValue();
            this.nombre = this.getField('nombre').getValue();
            this.getField('verifica').setValue(this.getField('verifica1').getValue());
            this.llenarencabezado();
            this.getField('contenedorDatos1').handleClose();
            this.habilitar();
        }else{
            this.alertGeneral.toggle(true, 'Validar la información suministrada.', 'error');
        }
    }

    llenarencabezado()
    {
        let datosFicha = [];
        let data2 = 
                {
                    "nit": this.nit,
                    "sede":this.sede,
                    "dv":this.getField('verifica').getValue()
                };
        datosFicha.push(data2);
        this.getField('lineas').setItemsFichas(datosFicha);
    }

    habilitar(){
        this.getField('recibe_factura_electronica').setDisabled(false) 
        this.getField('email_factura_electronica').setDisabled(false) 
        this.getField('actualizar').setDisabled(false)
    }

    deshabilitar(){
        this.getField('recibe_factura_electronica').setDisabled(true) 
        this.getField('email_factura_electronica').setDisabled(true) 
        this.getField('actualizar').setDisabled(true)
    }
    
    Dv(){
        if (this.getField('verifica1').getValue() !== '' && this.getField('verifica1').getValue() !== ' ') {
            const str = this.getField('verifica1').getValue();
            if (String(str).length > 1) {
                const newStr = String(str).slice(0, (String(str).length-1) * -1);
                this.getField('verifica1').setValue(newStr);
            }
        }
    }

    limpiar(){
        this.establecerPropiedades('3,4,5,6,7,8,10','visible','true');
        this.deshabilitar();
        this.getField('recibe_factura_electronica').valid(false); 
        this.getField('email_factura_electronica').valid(false);
        this.getField('verifica').valid(false); 

        this.getField('nit_buscar').setValue(''); 
        this.getField('sede').setValue(''); 
        this.getField('verifica1').setValue(''); 
        this.getField('verifica1').setError(false,"")
        this.getField('nombre').setValue('');     
        this.getField('email_factura_electronica').setValue(''); 
        this.getField('recibe_factura_electronica').setValue(''); 
    }

    actualizar(){
            if(this.getField('recibe_factura_electronica').valid() && this.getField('email_factura_electronica').valid() && this.getField('verifica').valid()){
            
            let datos={ datos: { recibe_factura_electronica: this.getField('recibe_factura_electronica').getValue(),
            email_factura_electronica:this.getField('email_factura_electronica').getValue().toLowerCase(), 
            verifica: this.getField('verifica').getValue(),nit_buscar: this.nit,sede: this.sede}};
            this.generalFormatPmv = { tipo_servicio: 'maes-facturacionelectronica', operacion: 'modificarfacturaelectronica', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successactualizo,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successactualizo(data){
        if (data.estado_p === 200 )
        { 
            this.limpiar();
            this.alertGeneral.toggle(true, 'El tercero '+this.nombre+' fue modificado con éxito.', 'success');
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
        });
    }
}
FormJaivana.addController("maes-facturacionelectronica",CustomFacturacionElctronica);
export default CustomFacturacionElctronica