import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomHojaDeVidaInventario
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_jdesk_1.01.0002
 **/
class CustomHojaDeVidaInventario extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm = this.initForm.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);

    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged'] = this.onSelectionChanged;

    this.gridOptionsBodega = Object.assign({}, this.gridOptions);
    this.gridOptionsBodega['onSelectionChanged'] = this.onSelectionChanged;

    this.gridOptionsComponentes2 = Object.assign({}, this.gridOptions);
    this.onSelectionChangedPrincipal = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes2['onSelectionChanged'] = this.onSelectionChangedPrincipal;

    this.operacion_actual = '';
    this.validarConsultar = this.validarConsultar.bind(this);
    this.successTraerDatosHoja = this.successTraerDatosHoja.bind(this);
    this.traerPropiedades = this.traerPropiedades.bind(this);
    this.sucessTraerPropiedades = this.sucessTraerPropiedades.bind(this);;


    this.sucessTraerDatos = this.sucessTraerDatos.bind(this);
    this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
    this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);

    this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
    this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
    this.traersaldoBodegas = this.traersaldoBodegas.bind(this);
    this.successSaldosBodegas = this.successSaldosBodegas.bind(this);
    this.validarFechas = this.validarFechas.bind(this);
    this.validarFechas2 = this.validarFechas2.bind(this);
    this.abrirFechas = this.abrirFechas.bind(this);
    this.sucesCrearTemporal = this.sucesCrearTemporal.bind(this);
    this.abrirFiltroSugeridos = this.abrirFiltroSugeridos.bind(this);
    this.validarSugeridos = this.validarSugeridos.bind(this);
    this.sucessTraerDatosSugeridos = this.sucessTraerDatosSugeridos.bind(this);
    this.sucesCrearTemporalSugeridos = this.sucesCrearTemporalSugeridos.bind(this);

    this.nombre_archivo = 'listado_articulos_por_gondola';
    this.numero_deciamles = '';
    this.sucursal_ingreso = '';
    this.operacion = '';
    this.fila_seleccionada = [];
    this.boton = '';
  }

  initForm() {
    console.log("Formulario CustomHojaDeVidaInventario,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.traerPropiedades();

    this.getField('procesar').setClick(() => {
      this.validarConsultar();
    });

    this.getField('bt_saldos_precios').setClick(() => {
      this.boton = 'compras';
      this.traersaldoBodegas();
    });


    this.getField('bt_compras_proveedores').setClick(() => {
      this.boton = 'compras'
      this.abrirFechas();
    });

    this.getField('bt_ventas').setClick(() => {
      this.boton = 'ventas'
      this.abrirFechas();
    });

    this.getField('bt_devoluciones_ventas').setClick(() => {
      this.boton = 'dev_ventas'
      this.abrirFechas();
    });

    this.getField('bt_sugerido').setClick(() => {
      if (this.fila_seleccionada.length > 0) {
        this.boton = 'sugerido'
        this.abrirFiltroSugeridos();
      } else {
        this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro de la tabla.', "error");
      }
    });

    this.getField('bt_sugerido_global').setClick(() => {
        this.boton = 'sugerido_global'
        this.abrirFiltroSugeridos();
    });

    this.getField('bt_sugerido_clasificado').setClick(() => {
        this.boton = 'sugerido_clasificado';
        this.abrirFiltroSugeridos();
    });

    //botones Modal filtro sugerido
    this.getField('bt_aceptar_filtro').setClick(() => { this.validarSugeridos(); });
    this.getField('bt_cancelar_filtro').setClick(() => { this.getField('modal_consultar_sugeridos').handleClose(); });

    //botones Modal fechas
    this.getField('bt_aceptar_fechas').setClick(() => { this.validarFechas(); });
    this.getField('bt_cancelar_fechas').setClick(() => { this.getField('modal_fechas_simple').handleClose(); });

    this.getField('desde').setOnChange(() => {
      this.validarFechas2();
    });
    this.getField('hasta').setOnChange(() => {
      this.validarFechas2();
    });

    this.getField('codigo_grupo').setOnChange(() => {
      this.getField('nombre_grupo').setError(false, '');
    });

    this.getField('codigo_subgrupo').setOnChange(() => {
      this.getField('nombre_subgrupo').setError(false, '');
      this.limpiarCampos();
    });

    this.getField('codigo_marca').setOnChange(() => {
      this.getField('nombre_marca').setError(false, '');
      this.limpiarCampos();
    });

  }

  abrirFiltroSugeridos() {
    
    if (this.boton === 'sugerido') {
      this.getField('radio_clasificacion').setVisible(false);
      this.getField('unidades_comprar').setVisible(true);
      this.getField('modal_consultar_sugeridos').setLabel('Consultar Sugerido');
    } else if (this.boton === 'sugerido_global') {
      this.getField('radio_clasificacion').setVisible(false);
      this.getField('unidades_comprar').setVisible(false);
      this.getField('modal_consultar_sugeridos').setLabel('Consultar Global');
    } else {
      this.getField('radio_clasificacion').setVisible(true);
      this.getField('radio_clasificacion').setValue('G');
      this.getField('unidades_comprar').setVisible(false);
      this.getField('modal_consultar_sugeridos').setLabel('Consultar Clasificación');
    }
    this.getField('modal_consultar_sugeridos').handleClickOpen();
  }

  validarFechas2() {
    if (this.getField('desde').getValue() !== '' && this.getField('desde').getValue() !== ' ') {
      this.getField("desde").setError(false, '');
      this.getField("hasta").setError(false, '');
      let desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
      let hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];
      this.getField('bt_aceptar_fechas').setDisabled(false);
      if (hasta < desde) {
        this.getField("hasta").setError(true, "No puede ser menor a la fecha desde.");
        this.getField('bt_aceptar_fechas').setDisabled(true);
        return false;
      }
      return true;
    } else {
      //se esta cerrando el modal
    }
  }

  validarSugeridos() {
    let errores = 0;
    if (!this.getField('numero_dias').valid())
      errores++;
    else if (this.getField('numero_dias').getValue() === '0') {
      this.alertGeneral.toggle(true, 'Número días debe ser mayor a cero.', 'error');
      errores++;
    }
    if (this.boton === 'sugerido') {
      if (!this.getField('unidades_comprar').valid())
        errores++;
      else if (this.getField('unidades_comprar').getValue() === '0') {
        this.alertGeneral.toggle(true, 'Unidades debe ser mayor a cero.', 'error');
        errores++;
      }


      if (errores === 0) {
        this.mostrarMensajeGenerando();
        let datos = {
          datos: {
            codigo_articulo: this.fila_seleccionada[0].codigo,
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.boton,
            unidades_comprar: this.getField('unidades_comprar').getValue(),
            numero_dias: this.getField('numero_dias').getValue(),
          }
        };
        this.getField("rejilla_sugerido").setTipoServicioOperacion('inve-hojadevida-sugerido');
        this.getField("rejilla_sugerido").sendRequest(this.sucessTraerDatosSugeridos, datos);
      }
    } else {
      if (this.boton === 'sugerido_clasificado' && !this.getField('radio_clasificacion').valid())
        errores++;
      if (errores === 0) {
        this.mostrarMensajeGenerando();
        let datos = {
          datos: {
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.boton,
            numero_dias: this.getField('numero_dias').getValue()
          }
        };
        if (this.boton === 'sugerido_clasificado')
          datos.datos.radio_clasificacion = this.getField('radio_clasificacion').getValue();
        if (this.boton === 'sugerido_global') {
          if (this.getField('codigo_grupo').getValue() !== '' && this.getField('codigo_grupo').getValue() !== ' ')
            datos.datos.codigo_grupo = this.getField('codigo_grupo').getValue();
          if (this.getField('codigo_subgrupo').getValue() !== '' && this.getField('codigo_subgrupo').getValue() !== ' ')
            datos.datos.codigo_subgrupo = this.getField('codigo_subgrupo').getValue();
          if (this.getField('codigo_marca').getValue() !== '' && this.getField('codigo_marca').getValue() !== ' ')
            datos.datos.codigo_marca = this.getField('codigo_marca').getValue();
        }
        this.generalFormatPmv = { tipo_servicio: 'inve-hojadevida', operacion: 'generarsugeridos', operacion_tipo: 'crear' };
        this.service.send(
          {
            endpoint: this.constant.formConfiguration(),
            method: 'POST',
            body: datos,
            success: this.sucesCrearTemporalSugeridos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
          });
      }
    }
  }

  sucesCrearTemporalSugeridos(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      this.mostrarMensajeGenerando();
      let datos = {
        datos: {
          sucursal_ingreso: this.sucursal_ingreso,
          operacion: this.boton,
          unidades_comprar: this.getField('unidades_comprar').getValue(),
          numero_dias: this.getField('numero_dias').getValue(),
        }
      };
      this.getField("rejilla_sugerido").setTipoServicioOperacion('inve-hojadevida-sugerido');
      this.getField("rejilla_sugerido").sendRequest(this.sucessTraerDatosSugeridos, datos);
    } else {
      if (data.estado_p === 404) {
        this.alertGeneral.toggle(true, 'No hay datos', 'error');
      }
      else {
        let respuesta = Object.values(data.data.errores);
        let keys = Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
      }
    }
  }

  validarFechas() {
    let errores = 0;
    if (this.validarFechas2() === false) {
      errores++;
    }
    if (this.boton === 'compras_proveedores') {
      if (errores === 0) {

        this.mostrarMensajeGenerando();
        let datos = {
          datos: {
            codigo_articulo: this.fila_seleccionada[0].codigo,
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: 'compras',
            desde: this.getField('desde').getValue(),
            hasta: this.getField('hasta').getValue(),
          }
        };
        this.getField("rejilla_ventas_compras").setTipoServicioOperacion('inve-hojadevida-comprasmultiple');
        this.getField("rejilla_ventas_compras").sendRequest(this.sucessTraerDatos, datos);
      }
    } else {
      if (this.getField('radio_presentacion').valid()) {
        this.mostrarMensajeGenerando();
        let datos = {
          datos: {
            codigo_articulo: this.fila_seleccionada[0].codigo,
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.boton,
            desde: this.getField('desde').getValue(),
            hasta: this.getField('hasta').getValue(),
            radio_presentacion: this.getField('radio_presentacion').getValue(),
          }
        };
        if (this.getField('radio_presentacion').getValue() === 'diaria') {
          this.getField("rejilla_ventas_compras").setTipoServicioOperacion('inve-hojadevida-comprasmultiple');
          this.getField("rejilla_ventas_compras").sendRequest(this.sucessTraerDatos, datos);
        } else {
          this.generalFormatPmv = { tipo_servicio: 'inve-hojadevida', operacion: 'generarventasxperiodo', operacion_tipo: 'crear' };
          this.service.send(
            {
              endpoint: this.constant.formConfiguration(),
              method: 'POST',
              body: datos,
              success: this.sucesCrearTemporal,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
            });
        }

      }
    }
  }

  sucesCrearTemporal(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      this.mostrarMensajeGenerando();
      let datos = {
        datos: {
          codigo_articulo: this.fila_seleccionada[0].codigo,
          sucursal_ingreso: this.sucursal_ingreso,
          operacion: this.boton,
          desde: this.getField('desde').getValue(),
          hasta: this.getField('hasta').getValue(),
          radio_presentacion: this.getField('radio_presentacion').getValue(),
        }
      };
      this.getField("rejilla_ventas_compras").setTipoServicioOperacion('inve-hojadevida-comprasmultiple');
      this.getField("rejilla_ventas_compras").sendRequest(this.sucessTraerDatos, datos);
    } else {
      if (data.estado_p === 404) {
        this.alertGeneral.toggle(true, 'No hay datos', 'error');
      }
      else {
        let respuesta = Object.values(data.data.errores);
        let keys = Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
      }
    }
  }

  abrirFechas() {
    if (this.fila_seleccionada.length > 0) {
      this.getField('modal_fechas_simple').handleClickOpen();
      if (this.boton === 'compras_proveedores') {
        this.getField('radio_presentacion').setVisible(false);
      } else {
        this.getField('radio_presentacion').setVisible(true);
        this.getField('radio_presentacion').setValue('diaria');
      }
    } else {
      this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro de la tabla.', "error");
    }
  }

  limpiarCampos() {
    this.getField('rejilla').toggle(false);
    this.getField('procesar').setDisabled(false);
    this.getField('bt_saldos_precios').setVisible(false);
    this.getField('bt_compras_proveedores').setVisible(false);
    this.getField('bt_ventas').setVisible(false);
    this.getField('bt_devoluciones_ventas').setVisible(false);
    this.fila_seleccionada = [];
    this.getField('bt_sugerido').setVisible(false);
    this.getField('bt_sugerido_global').setVisible(false);
    this.getField('bt_sugerido_clasificado').setVisible(false);
  }

  validarConsultar() {

    this.getField('codigo_grupo').setError(false, '');
    this.getField('codigo_subgrupo').setError(false, '');
    this.getField('codigo_marca').setError(false, '');

    let errores = 0;
    if (this.getField('codigo_grupo').getValue() !== '' && this.getField('codigo_grupo').getValue() !== ' ') {
      if (!this.getField('codigo_grupo').valid() && !this.getField('nombre_grupo').valid())
        errores++;
    }
    if (this.getField('codigo_subgrupo').getValue() !== '' && !this.getField('codigo_subgrupo').getValue() !== ' ') {
      if (!this.getField('codigo_subgrupo').valid() && !this.getField('nombre_subgrupo').valid())
        errores++;
    }
    if (this.getField('codigo_marca').getValue() !== '' && this.getField('codigo_marca').getValue() !== ' ') {
      if (!this.getField('codigo_marca').valid() && !this.getField('nombre_marca').valid())
        errores++;
    }
    if (errores === 0) {
      this.mostrarMensajeGenerando();
      let datos = {
        datos: {
          sucursal_ingreso: this.sucursal_ingreso,
        }
      }
      if (this.getField('codigo_grupo').getValue() !== '' && this.getField('codigo_grupo').getValue() !== ' ')
        datos.datos.codigo_grupo = this.getField('codigo_grupo').getValue();
      if (this.getField('codigo_subgrupo').getValue() !== '' && this.getField('codigo_subgrupo').getValue() !== ' ')
        datos.datos.codigo_subgrupo = this.getField('codigo_subgrupo').getValue();
      if (this.getField('codigo_marca').getValue() !== '' && this.getField('codigo_marca').getValue() !== ' ')
        datos.datos.codigo_marca = this.getField('codigo_marca').getValue();
      this.mostrarMensajeGenerando();
      this.getField('procesar').setDisabled(true);
      this.getField("rejilla").setTipoServicioOperacion('inve-hojadevida-hoja');
      this.getField("rejilla").sendRequest(this.successTraerDatosHoja, datos);

    }

  }

  successTraerDatosHoja(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      this.getField('rejilla').toggle(true);
      this.gridOptionsComponentes2['rowData'] = [];
      let configCell = new Map();
      this.gridOptionsComponentes2['rowData'] = data.data;
      //configCell.set('cuantos', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cuantos) }, cellStyle: { textAlign: "right" }, width: 110 });

      this.getField('rejilla').initData(this.gridOptionsComponentes2, configCell);
      this.getField('procesar').setDisabled(true);
      this.getField('bt_saldos_precios').setVisible(true);
      this.getField('bt_compras_proveedores').setVisible(true);
      this.getField('bt_ventas').setVisible(true);
      this.getField('bt_devoluciones_ventas').setVisible(true);

      this.getField('bt_sugerido').setVisible(true);
      this.getField('bt_sugerido_global').setVisible(true);
      this.getField('bt_sugerido_clasificado').setVisible(true);
    } else if (data.estado_p === 404) {
      //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      this.mostrarMensajeNoHayDatos();
    }
    else {
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }

  currencyFormatterGeneral(number) {
    let decimal = (number + "").split(".")[1];//para manejar los decimales
    if ((decimal !== 0) && (decimal !== undefined)) {

      if (decimal.length > this.numero_decimales) {
        let aux = number.toFixed(this.numero_decimales); //para que redondea a dos decimales
        decimal = (aux + "").split(".")[1];
      }
      return (this.formatNumberSaldo(number) + "," + decimal);
    } else {
      return this.formatNumberSaldo(number);
    }
  }


  traersaldoBodegas() {
    if (this.fila_seleccionada.length > 0) {
      this.mostrarMensajeGenerando();
      let datos = {
        datos: {
          codigo_articulo: this.fila_seleccionada[0].codigo,
          sucursal_ingreso: this.sucursal_ingreso,
        }
      };

      this.generalFormatPmv = { tipo_servicio: 'inve-hojadevida', operacion: 'traersaldosbodegashoja', operacion_tipo: 'consulta' };
      this.service.send(
        {
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.successSaldosBodegas,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false
        });
    } else {
      this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro de la tabla.', "error");
    }
  }

  successSaldosBodegas(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      let datos = []
      let arraybodegas = Object.entries(data.data.bodegas[0]);
      let saldo_total = 0.0;

      arraybodegas.forEach(element => {
        let arreglo =
        {
          bodega: element[0],
          saldo: element[1]
        }
        saldo_total += element[1];
        datos.push(arreglo);
      });
      datos.sort();

      this.getField('costo_saldos').setValue(this.currencyFormatterGeneral(data.data.costo));
      this.getField('valorizado_saldos').setValue(this.currencyFormatterGeneral(saldo_total * data.data.costo));
      this.getField('precio_de_ventas_saldos2').setValue(this.currencyFormatterGeneral(data.data.precio));
      this.getField('rentabilidad_saldos').setValue(this.currencyFormatterGeneral(data.data.rentabilidad));

      this.getField('modal_saldos').setLabel('Saldos ' + data.data.nombre_articulo);
      this.getField('modal_saldos').handleClickOpen();
      let configCell = new Map();
      configCell.set('saldo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: { textAlign: "right" }, width: 110 });
      this.gridOptionsBodega['rowData'] = [];
      this.getField('rejilla_saldos').initData(this.gridOptionsBodega);
      this.getField('rejilla_saldos').toggle(false);


      this.gridOptionsBodega['rowData'] = datos;
      this.getField('rejilla_saldos').toggle(true);
      this.getField('rejilla_saldos').initData(this.gridOptionsBodega, configCell);

    } else {
      if (data.estado_p === 404) {
        this.alertGeneral.toggle(true, 'No hay datos de bodegas.', 'error');
      }
      else {
        let respuesta = Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }


  formatNumberSaldo(number) {
    return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }

  onSelectionChanged() {

  }

  onSelectionChangedPrincipal() {
    this.fila_seleccionada = this.gridOptionsComponentes2.api.getSelectedRows();
  }

  //TRAER PROPIEDADES

  traerPropiedades() {
    let datos = {
      datos: {
        sucursal_ingreso: this.sucursal_ingreso
      }
    };
    this.generalFormatPmv = { tipo_servicio: 'inve-hojadevida', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send({
      endpoint: this.constant.formConfiguration(),
      method: 'GET',
      body: datos,
      success: this.sucessTraerPropiedades,
      error: this.error_,
      general: this.generalFormatPmv,
      showMessage: false
    });
  }

  sucessTraerPropiedades(data) {
    if (data.estado_p === 200) {
      this.numero_decimales = data.data.numero_decimales_compras;
    }
  }

  sucessTraerDatosSugeridos(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      if(this.boton==='sugerido')
        this.getField("modal_sugerido").setLabel('Sugerido ' + this.fila_seleccionada[0].nombre);
      else if(this.boton==='sugerido_global')
        this.getField("modal_sugerido").setLabel('Sugerido Global');
      else{
        let opcion='';
        if(this.getField('radio_clasificacion').getValue()==='G')
          opcion='Grupos';
        else if(this.getField('radio_clasificacion').getValue()==='S')
          opcion='Subgrupos';
        else
          opcion='Marca';
        this.getField("modal_sugerido").setLabel('Sugerido Clasificado '+opcion);
      }
      this.getField('modal_consultar_sugeridos').handleClose();
      this.getField('modal_sugerido').handleClickOpen();

      this.gridOptionsComponentes['rowData'] = [];
      let configCell = new Map();
      this.gridOptionsComponentes['rowData'] = data.data.data;
      configCell.set('costo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('cantidad', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('total', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.total) }, cellStyle: { textAlign: "right" }, width: 110 });
      
      configCell.set('saldo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('ventas', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.ventas) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('devventas', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.devventas) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('dias_venta', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.dias_venta) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('minimo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.minimo) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('maximo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.maximo) }, cellStyle: { textAlign: "right" }, width: 110 });

      
      if(this.boton==='sugerido'){
        this.getField('dias_de_venta').setVisible(true);
        this.getField('dias_de_venta').setValue('Días de venta:   ' + data.data.sugerido);
     }
      else{
        this.getField('dias_de_venta').setVisible(false);
      }
      this.getField('rejilla_sugerido').initData(this.gridOptionsComponentes, configCell);
    } else if (data.estado_p === 404) {
      //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      this.mostrarMensajeNoHayDatos();
    }
    else {
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }

  sucessTraerDatos(data) {
    this.getField('confirmModalCustom').toggle(false);
    if (data.estado_p === 200) {
      if (this.boton === 'compras')
        this.getField("modal_multiple").setLabel('Compras ' + this.fila_seleccionada[0].nombre);
      else {
        let titulo = '';
        if (this.boton === 'ventas')
          titulo += 'Ventas ';
        else
          titulo += 'Devoluciones en Ventas ';
        if (this.getField('radio_presentacion').getValue() === 'diaria')
          titulo += 'Diarias ';
        else if (this.getField('radio_presentacion').getValue() === 'semanal')
          titulo += 'Semanales ';
        else if (this.getField('radio_presentacion').getValue() === 'mensual')
          titulo += 'Mensuales ';
        else
          titulo += 'Anuales ';

        titulo += this.fila_seleccionada[0].nombre;
        this.getField('modal_fechas_simple').handleClose();
        this.getField("modal_multiple").setLabel(titulo);
      }
      this.getField('modal_multiple').handleClickOpen();

      this.gridOptionsComponentes['rowData'] = [];
      let configCell = new Map();
      this.gridOptionsComponentes['rowData'] = data.data;
      configCell.set('costo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('cantidad', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: { textAlign: "right" }, width: 110 });
      configCell.set('total', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.total) }, cellStyle: { textAlign: "right" }, width: 110 });

      this.getField('rejilla_ventas_compras').initData(this.gridOptionsComponentes, configCell);
    } else if (data.estado_p === 404) {
      //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      this.mostrarMensajeNoHayDatos();
    }
    else {
      let respuesta = Object.values(data.data.errores);
      this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }
  }

  mostrarMensajeNoHayDatos() {
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeGenerando() {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
    this.getField('confirmModalCustom').setClickDialog(() => { });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeAdicional(mensaje) {
    this.getField('confirmModalCustom').toggle(false);
    this.getField('confirmModalCustom').setTitleAndContent('Compras', mensaje);
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

}
FormJaivana.addController("inve-hojadevida", CustomHojaDeVidaInventario);
export default CustomHojaDeVidaInventario;