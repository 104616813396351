import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomGrabacionDePedidos
 * @author: Anderson Acevedo Briñez
 * @updateBy Sebastian Rios Echeverri
 * @version: jdesk_1.01.0002
 **/
class CustomGrabacionDePedidos extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.llenarSelect = this.llenarSelect.bind(this);
    this.suceesLlenarSelect = this.suceesLlenarSelect.bind(this);
    this.comenzarForm = this.comenzarForm.bind(this);
    this.sucessComenzar = this.sucessComenzar.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
    this.formatNumberSaldo                               = this.formatNumberSaldo.bind(this);
    this.primeraVez                               = this.primeraVez.bind(this);
    this.sucessPrimeraVez                               = this.sucessPrimeraVez.bind(this);
    this.setButtonDelete                               = this.setButtonDelete.bind(this);
    this.eliminarRegistro                               = this.eliminarRegistro.bind(this);
    this.confirmDelete                               = this.confirmDelete.bind(this);
    this.refescarTabla                               = this.refescarTabla.bind(this);
    this.successEliminar                               = this.successEliminar.bind(this);
    this.sucessRefrescar                               = this.sucessRefrescar.bind(this);
    this.abrirModalAgregar                               = this.abrirModalAgregar.bind(this);

    this.sucursal_ingreso='';
    this.calcularDescuento                               = this.calcularDescuento.bind(this);
    this.calcularItem                               = this.calcularItem.bind(this);
    this.traerCostoBodega                               = this.traerCostoBodega.bind(this);
    this.sucessTraerCostoBodega                               = this.sucessTraerCostoBodega.bind(this);
    this.sucessAlterno                               = this.sucessAlterno.bind(this);
    this.traerPropiedades                               = this.traerPropiedades.bind(this);
    this.sucessTraerPropiedades                               = this.sucessTraerPropiedades.bind(this);
    this.validacionesAgregar                               = this.validacionesAgregar.bind(this);
    this.autoriza_ordenesc='';

    this.sucessValidaciones                               = this.sucessValidaciones.bind(this);
    this.validacionesAgregar2                               = this.validacionesAgregar2.bind(this);
    this.grabarItem                               = this.grabarItem.bind(this);
    this.sucessGrabarItem                               = this.sucessGrabarItem.bind(this);
    this.recalcularArticulo                               = this.recalcularArticulo.bind(this);
    this.sucessRecalcularArticulo                               = this.sucessRecalcularArticulo.bind(this);
    this.grabarItem2                               = this.grabarItem2.bind(this);
    this.abrirModalCargar                         =this.abrirModalCargar.bind(this);
    this.archivo1='';
    this.archivo_valido=false;
    this.lista={};

    this.validarCuenta                         =this.validarCuenta.bind(this);
    this.sucessValidarCuenta                         =this.sucessValidarCuenta.bind(this);
    this.cargarArchivo                         =this.cargarArchivo.bind(this);
    this.uploadFile                         =this.uploadFile.bind(this);
    this.sucessCargarArchivo                         =this.sucessCargarArchivo.bind(this);
    this.validarfechas                         =this.validarfechas.bind(this);
    this.validarOrden                         =this.validarOrden.bind(this);
    this.validarBodega                         =this.validarBodega.bind(this);
    this.sucessValidarBodega                         =this.sucessValidarBodega.bind(this);
    this.sucessAutorizarOrden                         =this.sucessAutorizarOrden.bind(this);
    this.autorizarOrden                         =this.autorizarOrden.bind(this);
    this.grabarOrden                         =this.grabarOrden.bind(this);
    this.sucessGrabarOrden                         =this.sucessGrabarOrden.bind(this);
    this.sucessGrabarOrden                         =this.sucessGrabarOrden.bind(this);
    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);

    this.allowedExtensions                     =   /(.xlsx|.XLSX)$/i;
    this.consecutivo_grabado='';
    this.operacion_actual='';


    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.fecha_hoy='';
    this.habilitarBtnAgregarItem                         = this.habilitarBtnAgregarItem.bind(this);
    this.formatearCamposAgregarItem                      = this.formatearCamposAgregarItem.bind(this);
    this.limpiarCamposModalAgregar                      = this.limpiarCamposModalAgregar.bind(this);
  
  }

  initForm() {
    console.log("Formulario CustomGrabacionDePedidos,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez, @updateBy: Sebastian Rios Echeverri");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.llenarSelect();
    this.getField('comenzar').setClick(this.comenzarForm);
    this.getField('agregar_item').setClick(this.abrirModalAgregar);
    this.getField('cargar').setClick(this.abrirModalCargar);
    this.primeraVez();
    this.getField('sede').setOnChange((props)=>{
      this.getField('sede').setError(false,'');
      this.getField('nombre').setError(false,'');
      this.getField('responsable_iva').setError(false,'');
      if(this.getField('sede').getValue()!=='' && this.getField('sede').getValue()!==' '){
        this.validarCuenta();
      }
    });


    this.getField('costo').setOnChange((props)=>{
      this.getField('nombre_articulo').setError(false,'');
      this.getField('tarifa_iva').setError(false,'');
      this.getField('responsable_iva').setError(false,'');
      if(this.getField('costo').getValue()!=='' && this.getField('costo').getValue()!==' '){
        this.traerCostoBodega();
      }
    });
    this.getField('descuento').setOnChange(this.calcularDescuento);
    this.getField('precio_sin_iva').setOnChange(this.calcularItem);
    this.getField('cantidad_articulo').setOnChange(this.calcularItem);
    this.getField('impuestos2').setOnChange(this.calcularItem);
    this.getField('cancelar_agregar').setClick(()=>{this.getField('modal_agregar').handleClose()});

    this.getField('grabar_agregar').setClick(this.validacionesAgregar);
    this.getField('archivo').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivo1 = props.target.files[0] : this.archivo1 = '';});
    
    this.getField('aceptar_cargar').setClick(this.cargarArchivo);
    this.getField('cancelar_cargar').setClick(()=>{this.getField('modal_cargar').handleClose()});
    this.getField('fecha_orden').setDisabled(true);
    this.getField('fecha_entrega').setOnChange(this.validarfechas);
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;


    this.getField('grabar').setClick(this.validarOrden);
    this.getField('aceptar_autorizar').setClick(this.autorizarOrden);
    this.getField('cancelar_autorizar').setClick(()=>{this.getField('modal_autorizacion').handleClose()});
    this.traerPropiedades();


    this.getField('documento').setDisabled(true);
    this.getField('sucursal_autorizar').setDisabled(true);
      this.getField('fecha_autorizar').setDisabled(true);

      //imprimir
      this.getField('radio_correo').setValue("N");
      this.getField('generar_archivo').setClick(()=>this.procesar());

      //para balances globales
      this.getField('ch_excel').setOnChange((props)=>{
        if(this.getField('ch_excel').getValue()==='false'){
          this.getField('ch_pdf').setValueSwitch (false);
          this.getField('ch_pantalla').setValueSwitch (false);
          this.operacion_actual='excel';
          this.habilitarCorreo();
        }
      });
      this.getField('ch_pdf').setOnChange((props)=>{
        if(this.getField('ch_pdf').getValue()==='false'){
          this.getField('ch_excel').setValueSwitch (false);
          this.getField('ch_pantalla').setValueSwitch (false);
          this.operacion_actual='pdf';
          this.habilitarCorreo();
        }
      });
      this.getField('ch_pantalla').setOnChange((props)=>{
        if(this.getField('ch_pantalla').getValue()==='false'){
          this.getField('ch_excel').setValueSwitch (false);
          this.getField('ch_pdf').setValueSwitch (false);
          this.operacion_actual='pantalla';
          this.deshabilitarCorreo();
        }else{
            this.habilitarCorreo();
        }
      });
      this.getField('modal_imprimir').setCloseButton(()=>{
        this.primeraVez();
        this.getField('nit').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre').setValue('');
        this.getField('no_orden').setValue('');
        this.getField('observaciones').setValue('.');
        this.getField('fecha_entrega').setValue(this.fecha_hoy);
        this.getField('referencia').setValue('');
        this.getField('responsable_iva').setValue('');
        this.getField('codigo_interno').setValue('.');
        this.getField('total_subtotal').setValue(0);
        this.getField('total_subtotal_visual').setValue(0);
        this.getField('valor_iva').setValue(0);
        this.getField('valor_iva_visual').setValue(0);
        this.getField('impuestos').setValue(0);
        this.getField('impuestos_visual').setValue(0);
        this.getField('total_pedido').setValue(0);
        this.getField('total_pedido_visual').setValue(0);
      });
    this.getField('observaciones').setValue('.');
    this.getField('codigo_interno').setValue('.');
    this.getField('agregar_item').setDisabled(true);
    this.getField('no_orden').setOnChange(this.habilitarBtnAgregarItem);
    this.getField('precio_sin_iva').setOnBlur(()=>{this.getField('precio_sin_iva').setValue("".formatoPrecio(this.getField('precio_sin_iva').getValue()));});
    this.getField('cantidad_articulo').setOnBlur(()=>{this.getField('cantidad_articulo').setValue("".formatoPrecio(this.getField('cantidad_articulo').getValue()));});
    this.getField('impuestos2').setOnBlur(()=>{this.getField('impuestos2').setValue("".formatoPrecio(this.getField('impuestos2').getValue()));});
    this.getField('tarifa_iva').setOnChange(this.formatearCamposAgregarItem);
    this.getField('codigo_articulo').setOnChange(this.limpiarCamposModalAgregar);
  }

  limpiarCamposModalAgregar(){
    this.getField('fila_final_datos').setError(false,'');
    
    this.getField('nombre_articulo').setError(false,'');
    this.getField('tarifa_iva').setError(false,'');
    this.getField('descuento').setError(false,'');
    this.getField('cantidad_articulo').setError(false,'');
    this.getField('impuestos2').setError(false,'');

    this.getField('precio_sin_iva').setError(false,'');
    this.getField('valor_sin_iva_ma_impuestos').setError(false,'');
    this.getField('total_iva').setError(false,'');
    this.getField('saldo1').setError(false,'');
    this.getField('valor_iva_unidad').setError(false,'');
    this.getField('total_impuestos').setError(false,'');
    this.getField('saldo2').setError(false,'');
    this.getField('valor_unidad').setError(false,'');
    this.getField('total_item').setError(false,'');
    this.getField('saldo3').setError(false,'');
    this.getField('costo_bodega').setError(false,'');


    
    this.getField('nombre_articulo').setValue('');
    this.getField('tarifa_iva').setValue('');
    this.getField('descuento').setValue(0);
    this.getField('impuestos2').setValue(0);
    
    
    this.getField('precio_sin_iva').setValue(0);
    this.getField('valor_sin_iva_ma_impuestos').setValue(0);
    this.getField('total_iva').setValue(0);
    this.getField('saldo1').setValue(0);
    this.getField('cantidad_articulo').setValue(0);
    this.getField('valor_iva_unidad').setValue(0);
    this.getField('total_impuestos').setValue(0);
    this.getField('saldo2').setValue(0);
    this.getField('valor_unidad').setValue(0);
    this.getField('total_item').setValue(0);
    this.getField('saldo3').setValue(0);
    this.getField('costo_bodega').setValue(0);
  }
  
  formatearCamposAgregarItem(){
    this.getField('precio_sin_iva').setValue("".formatoPrecio(this.getField('precio_sin_iva').getValue()));
    this.getField('cantidad_articulo').setValue("".formatoPrecio(this.getField('cantidad_articulo').getValue()));
    this.getField('impuestos2').setValue("".formatoPrecio(this.getField('impuestos2').getValue()));

    // Limpiar mensajes de error campos:
    this.getField('descuento').setError(false,'');
    this.getField('precio_sin_iva').setError(false,'');
    this.getField('cantidad_articulo').setError(false,'');
    this.getField('impuestos2').setError(false,'');
  }
  
  habilitarBtnAgregarItem(){
    if (this.getField('no_orden').getValue() !== '' && this.getField('no_orden').getValue() !== ' ') {
      this.getField('agregar_item').setDisabled(false);
      this.getField('comenzar').setDisabled(true);
    }else{
      this.getField('agregar_item').setDisabled(true);
      this.getField('comenzar').setDisabled(false);
    }
  }
  
  deshabilitarCorreo(){
    this.getField('email').setDisabled(true);
    this.getField('email').setValue('');
    this.getField('email').setError(false,'');
    this.getField('radio_correo').setDisabled(true);
    this.getField('radio_correo').setValue("N");
}

habilitarCorreo(){
    this.getField('email').setDisabled(false);
    this.getField('radio_correo').setDisabled(false);
    this.getField('radio_correo').setValue("N");
}

  validarOrden(){
    let errores=0;
    if(this.validarfechas()===false){
        errores++;
    }
    // En las siguientes condiciones, se tuvo que validar si los presentes campos están vacíos, ya que por el error de opciones='bold' algunos campos, tuvieron que quitarle la configuración de que fueran requeridos.
    if(!(this.getField('no_orden').valid() && this.getField('no_orden').getValue() !== ''))
      errores++;
    if(!(this.getField('nit').valid() && this.getField('nit').getValue() !== ''))
      errores++;

    if(!(this.getField('sede').valid() && this.getField('sede').getValue() !== ''))
      errores++;
    if(!(this.getField('nombre').valid() && this.getField('nombre').getValue() !== ''))
      errores++;
    if(this.getField('observaciones').valid() && this.getField('observaciones').getValue() !== ''){
      this.getField('observaciones').setError(false, '');
    }else{
      errores++;
      this.getField('observaciones').setError(true, '* Este campo es requerido');
    }
    if(!(this.getField('responsable_iva').valid() && this.getField('responsable_iva').getValue() !== ''))
      errores++;
    if(!(this.getField('select_direccion').valid() && this.getField('select_direccion').getValue() !== ''))
      errores++;
    if(this.getField('referencia').valid() && this.getField('referencia').getValue() !== ''){
      this.getField('referencia').setError(false, '');
    }else{
      errores++;
      this.getField('referencia').setError(true, '* Este campo es requerido');
    }
    if(this.getField('codigo_interno').valid() && this.getField('codigo_interno').getValue() !== ''){
      this.getField('codigo_interno').setError(false, '');
    }else{
      errores++;
      this.getField('codigo_interno').setError(true, '* Este campo es requerido');
    }
    if(errores===0){
      if(this.getField('total_pedido').getValue()===0){
        this.alertGeneral.toggle(true, 'El total del pedido no puede ser cero.', 'error');
      }else{
        this.validarBodega();
      }
    }else{
      this.alertGeneral.toggle(true, 'Valide que todos los campos estén correctamente diligenciados.', 'error');
    }


  }

  validarfechas(){
    this.getField("fecha_entrega").setError(false,'');
    this.getField('grabar').setDisabled(false);
    let fecha_actual = new Date().toISOString().split('T')[0];
    let fecha_entrega = new Date(this.getField("fecha_entrega").getValue()).toISOString().split('T')[0];


    if(fecha_entrega < fecha_actual){
        this.getField("fecha_entrega").setError(true,"No puede ser menor a la fecha actual.");
        this.getField('grabar').setDisabled(true); 
        return false;
    }

    this.getField('grabar').setDisabled(false); 
    return true;

}

  grabarOrden(){
      let datos={ datos: {
          select_direccion: this.getField('select_direccion').getValue(),
          nit: this.getField('nit').getValue(),
          nombre: this.getField('nombre').getValue(),
          sede: this.getField('sede').getValue(),
          fecha_orden: this.getField('fecha_orden').getValue(),
          fecha_entrega: this.getField('fecha_entrega').getValue(),
          total_pedido: this.getField('total_pedido').getValue(),
          valor_iva: this.getField('valor_iva').getValue(),
          impuestos: this.getField('impuestos').getValue(),
          observaciones: this.getField('observaciones').getValue(),
          referencia: this.getField('referencia').getValue(),
          codigo_interno: this.getField('codigo_interno').getValue(),
          sucursal_ingreso: this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'grabarorden', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarOrden,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  sucessGrabarOrden(data)
  {
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje',data.data.mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        if(data.data.imprimir===true){
          this.getField('modal_autorizacion').handleClose();
          this.consecutivo_grabado=data.data.consecutivo;
          this.abrirImprimir();
        }else{
          this.limpiarCampos();
        }
      }else 
      {
        if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
  }

  abrirImprimir(){
        
        this.getField("modal_imprimir").setLabel("Orden de Compra No. "+this.consecutivo_grabado);
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
  }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.nombre_pdf='orden_compra';
                    this.nombre_excel='orden_compra';
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    genearModalGlobales(){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos={ datos: {
        nit: this.getField('nit').getValue(),
        sede: this.getField('sede').getValue(),
        fecha_orden: this.getField('fecha_orden').getValue(),
        no_orden: this.consecutivo_grabado,
        email: this.getField('email').getValue(),
        sucursal_ingreso: this.sucursal_ingreso,
        operacion: this.operacion_actual
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'archivoitems', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessTraerDatos,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
      
  }

  sucessTraerDatos(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit": this.lista.nit,
                    "direccion": this.lista.direccion,
                    "telefonos": this.lista.telefonos,
                    "ciudad": this.lista.ciudad,
                    "orden_de_compra": this.lista.orden_de_compra,
                    "bodega": this.lista.bodega,
                    "fecha_pedido": this.lista.fecha_pedido,
                    "fecha_entrega": this.lista.fecha_entrega,

                };
            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);

            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            configCell.set('unid_medida', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.unid_medida) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('pendiente', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.pendiente) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('base_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.base_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('proveedor_result').setValue(this.lista.proveedor);
            this.getField('direcccion').setValue(this.lista.direccion_nit);
            this.getField('telefono').setValue(this.lista.telefono_nit);
            this.getField('ciudad').setValue(this.lista.ciudad_nit);
            this.getField('observaciones_result').setValue(this.lista.observa);
            this.getField('valor_extento').setValue(this.currencyFormatterGeneral(this.lista.valor_extento));
            this.getField('valor_grabado').setValue(this.currencyFormatterGeneral(this.lista.valor_grabado));
            this.getField('iva_result').setValue(this.currencyFormatterGeneral(this.lista.iva_result));
            this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total_result));

            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }


    enviarCorreoTerceros(){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos={ datos: {
          nit: this.getField('nit').getValue(),
          sede: this.getField('sede').getValue(),
          fecha_orden: this.getField('fecha_orden').getValue(),
          no_orden: this.consecutivo_grabado,
          email: this.getField('email').getValue(),
          radio_correo: this.getField('radio_correo').getValue(),
          sucursal_ingreso: this.sucursal_ingreso,
          operacion: this.operacion_actual
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'archivoitems', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successEnviarCorreo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }
  
  successEnviarCorreo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
          this.mostrarMensajeEmailEnviado();
      }else{
          this.mostrarMensajeEmailFallo();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      }
  }

  mostrarMensajeEmailEnviado(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeEmailFallo(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeNoHayDatos(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
}

  generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= {
              nit: this.getField('nit').getValue(),
              sede: this.getField('sede').getValue(),
              fecha_orden: this.getField('fecha_orden').getValue(),
              no_orden: this.consecutivo_grabado,
              email: this.getField('email').getValue(),
              sucursal_ingreso: this.sucursal_ingreso,
              operacion: this.operacion_actual
            };
            let op='archivoitems';
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabarpedidos","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= {
              nit: this.getField('nit').getValue(),
              sede: this.getField('sede').getValue(),
              fecha_orden: this.getField('fecha_orden').getValue(),
              no_orden: this.consecutivo_grabado,
              email: this.getField('email').getValue(),
              sucursal_ingreso: this.sucursal_ingreso,
              operacion: this.operacion_actual
            };
            let op='archivoitems';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabarpedidos","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_excel+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }


  autorizarOrden(){
    if (this.getField('numero_autorizacion').valid()) {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
  
      let datos={ datos: {
        documento:this.getField('documento').getValue(),
        sucursal_autorizar:this.getField('sucursal_autorizar').getValue(),
        fecha_autorizar:this.getField('fecha_autorizar').getValue(),
        numero_autorizacion:this.getField('numero_autorizacion').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'autorizarorden', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'PUT',
              body: datos,
              success: this.sucessAutorizarOrden,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }
  }

  sucessAutorizarOrden(data)
  {   
      if (data.estado_p === 200)
      {
          if(data.data.autorizado===true){
            this.grabarOrden();
          }else{
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje',data.data.mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
          }
      }
      else 
      {
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p===404) {
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje',data.data.mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      }
  }

  validarBodega(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);

    let datos={ datos: {
      value:this.getField('select_direccion').getValue()
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: '1_validar_bodega', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarBodega,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  sucessValidarBodega(data)
  {   
      if (data.estado_p === 200)
      {
          if(this.autoriza_ordenesc==='S' || this.autoriza_ordenesc==='' || this.autoriza_ordenesc===undefined){ //igual a S o NUll requiere autorización
            this.getField('confirmModalCustom').toggle(false);
            this.getField('modal_autorizacion').handleClickOpen();
            this.getField('documento').setValue(this.getField('no_orden').getValue());
            this.getField('sucursal_autorizar').setValue(this.sucursal_ingreso);
            this.getField('fecha_autorizar').setValue(this.getField('fecha_orden').getValue());
            this.getField('numero_autorizacion').setError(false,'');
          }else{
            //va directamente a trabar
            this.grabarOrden();
          }
      }
      else 
      {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje','Bodega invalida. No se grabó el pedido');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
  }

  validarCuenta(){
    let datos={ datos: {
      nit:this.getField('nit').getValue(),
      sede:this.getField('sede').getValue(),
      sucursal_ingreso:this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'validarcontable', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarCuenta,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  sucessValidarCuenta(data)
  {   
      if (data.estado_p === 200)
      {

      }
      else 
      {
        this.getField('nit').setValue('');
        this.getField('nombre').setValue('');
        this.getField('sede').setValue('');
         if(data.estado_p===404) {

              this.alertGeneral.toggle(true, 'No hay datos', 'error');
         }
         else {
            this.getField('confirmModalCustom').setTitleAndContent(`El proveedor`, data.data.errores.mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
         }
     }
  }

  cargarArchivo() {
    if(this.archivo1!=='' && this.archivo_valido && this.getField('archivo').valid()){
      if(this.getField('fila_inicial').valid() 
      && this.getField('columna_codigo').valid()
      && this.getField('columna_costo').valid()
      && this.getField('columna_cantidad').valid()
      && this.getField('fila_final_datos').valid()){
      
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            const data = {
                _generales: { "tipo_servicio": 'comp-grabarpedidos', "operacion": 'cargararchivo', "operacion_tipo": 'crear' },
                archivo: this.archivo1,
                ruta_archivo:  this.archivo1.name,
                columna_codigo:this.getField('columna_codigo').getValue(),
                columna_costo:this.getField('columna_costo').getValue(),
                columna_cantidad:this.getField('columna_cantidad').getValue(),
                fila_final_datos:this.getField('fila_final_datos').getValue(),
                fila_inicial:Number(this.getField('fila_inicial').getValue()),
                sucursal_ingreso:this.sucursal_ingreso,
                nit: this.getField('nit').getValue(),
                sede: this.getField('sede').getValue(),
            }
            this.generalFormatPmv = data._generales;
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: data,
                    success: this.sucessCargarArchivo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    formData: true
                }
            );
          }
    }else{
      this.alertGeneral.toggle(true, 'Es necesario cargar un archivo(xlsx).', 'error');
    }
        
}

  sucessCargarArchivo(data)
  {
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200){
        let tamanioItemsExcel = 0;
        let tamanioItemsNoInsertados = 0;
        if (data.data.errores !== undefined && Object.keys(data.data.errores).length >= 1) {
          tamanioItemsExcel = (this.getField('fila_final_datos').getValue() - this.getField('fila_inicial').getValue())+1;
          tamanioItemsNoInsertados = Object.keys(data.data.errores).length;
          
        }
        this.getField('modal_cargar').handleClose();
        this.refescarTabla();
        if (tamanioItemsNoInsertados >= 1) {
          this.getField('confirmModalCustom').setTitleAndContent(`Advertencia`, `DE ${tamanioItemsExcel > 1 ? `${tamanioItemsExcel} ítems en el excel` : `${tamanioItemsExcel} ítem en el excel`}, ${tamanioItemsNoInsertados > 1 ? `${tamanioItemsNoInsertados} ítems no fueron insertados.` : `${tamanioItemsNoInsertados} item no fue insertado.`}`);
          this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
        }
      }
      else 
      {
        if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
  }

  uploadFile(e) {
  // let campo = e.target.id.slice(0, -5);
  this.getField('ruta_archivo').setValue('');
  if (e.target.files.length >= 1) {
      if (!this.allowedExtensions.exec(e.target.files[0].name)) {
          this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .xlsx/.XLSX', 'error');
          this.getField('archivo').setError(true, 'Solo se permiten archivos con extensión .xlsx/.XLSX', 'error');
          // this.getField(`${campo}`).setValue('');
          this.archivo_valido = false;
          this.getField('aceptar_cargar').setDisabled(true);
      }else if (e.target.files[0].name.indexOf(' ') > 0) {
          this.alertGeneral.toggle(true, "La imagen (" + e.target.files[0].name + ") no puede contener espacios.", 'error');
          // this.getField(`${campo}`).setValue('');
          this.archivo_valido = false;
          this.getField('aceptar_cargar').setDisabled(true);
      }else{
          this.getField('aceptar_cargar').setDisabled(false);
          this.getField('ruta_archivo').setValue(e.target.files[0].name );
          this.archivo_valido = true;
      }
  }
}


  onSelectionChanged(){

  }

  validacionesAgregar(){
    if(Number(this.getField('total_item').getValue()!==0)){
        if(this.getField('codigo_articulo').valid() 
        && this.getField('nombre_articulo').valid()
        && this.getField('tarifa_iva').valid() 
        && this.getField('descuento').valid() 
        && this.getField('cantidad_articulo').valid()
        && this.getField('impuestos2').valid()){
          this.getField('grabar_agregar').setDisabled(true);
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
          this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);

            let datos={ datos: {
              sucursal_ingreso:this.sucursal_ingreso,
              select_direccion:this.getField('select_direccion').getValue(),
              costo:this.getField('costo').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessValidaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
          this.alertGeneral.toggle(true, 'Valide que todos los campos estén correctamente diligenciados.', 'error');
        }
    }else{
        this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
    }
      
  }

  validacionesAgregar2(input){
    this.getField('grabar_agregar').setDisabled(true);
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
    this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);

    if(this.getField('codigo_articulo').valid()){
        let datos={ datos: {
              sucursal_ingreso:this.sucursal_ingreso,
              input:input,
              select_direccion:this.getField('select_direccion').getValue(),
              costo:this.getField('costo').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessValidaciones,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
}

  sucessValidaciones(data)
    {
      this.getField('confirmModalCustom').toggle(false);
      this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200)
        { 
            if(data.data.costo_pro!==undefined){
              //pedir el input
              let costo_pro=data.data.costo_pro;
              if(costo_pro===0){
                  this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El costo_pro_'+this.getField('select_direccion').getValue()+' es cero. ¿Está seguro de continuar?');
                  this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                  this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                  this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                  this.getField('confirmModalCustom').toggle(true);
              }else{
                  let valor_costo = Math.abs((costo_pro - this.getField('costo').getValue())/ costo_pro) * 100;
                  let margen_costo_promedio=data.data.margen_costo_promedio;

                  if (valor_costo > margen_costo_promedio) {
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El valor del costo sobrepasa el margen costo entrada. Margen costo entrada= '+margen_costo_promedio+'. Variación='+valor_costo+'. ¿Está seguro de continuar? ');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                  }else{
                    this.validacionesAgregar2(0)
                  }
              }
            }else{
              this.grabarItem();
            }
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    grabarItem(){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      this.getField('grabar_agregar').setDisabled(true);

      let datos={ datos: {
        select_direccion: this.getField('select_direccion').getValue(),
        codigo_articulo: this.getField('codigo_articulo').getValue(),
        cantidad_articulo: this.getField('cantidad_articulo').getValue(),
        nombre_articulo: this.getField('nombre_articulo').getValue(),
        tarifa_iva: this.getField('tarifa_iva').getValue(),
        costo: this.getField('precio_sin_iva').getValue(),
        impuestos_articulo: this.getField('impuestos_articulo').getValue(),
        valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
        valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
        valor_unidad: this.getField('valor_unidad').getValue(),
        total_item: this.getField('total_item').getValue(),
        sucursal_ingreso : this.sucursal_ingreso
      }};
      
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'grabaritem', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarItem,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    grabarItem2(opcion){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      this.getField('grabar_agregar').setDisabled(true);

      let datos={ datos: {
        select_direccion: this.getField('select_direccion').getValue(),
        codigo_articulo: this.getField('codigo_articulo').getValue(),
        cantidad_articulo: this.getField('cantidad_articulo').getValue(),
        nombre_articulo: this.getField('nombre_articulo').getValue(),
        tarifa_iva: this.getField('tarifa_iva').getValue(),
        costo: this.getField('costo').getValue(),
        impuestos_articulo: this.getField('impuestos_articulo').getValue(),
        valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
        valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
        valor_unidad: this.getField('valor_unidad').getValue(),
        total_item: this.getField('total_item').getValue(),
        sucursal_ingreso : this.sucursal_ingreso,
        input:0

      }};
      
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'grabaritem', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.sucessGrabarItem,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    sucessGrabarItem(data){
        this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false);
            if(data.data.input !== undefined){
                //preguntar si desea adicionar
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.mensaje);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.recalcularArticulo() });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.getField('modal_agregar').handleClose();
                this.refescarTabla();
            }
        }
        else 
        { 
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }
  

    recalcularArticulo(){
      this.getField('grabar_agregar').setDisabled(true);
      let datos={ datos: {
        codigo_articulo:this.getField('codigo_articulo').getValue(),
        sucursal_ingreso:this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: '1_articulo', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessRecalcularArticulo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    sucessRecalcularArticulo(data)
    {
      this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200)
        {
          this.getField('cantidad_articulo').setValue("".formatoPrecio(this.getField('cantidad_articulo').getValue()+data.data[0].cantidad));
          this.grabarItem2(0);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

  abrirModalAgregar(){
    this.getField('codigo_articulo').setValue('');
    this.getField('codigo_articulo').setError(false,'');
    this.limpiarCamposModalAgregar();
    
    this.getField('modal_agregar').handleClickOpen();
  }

  abrirModalCargar(){
    if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
        this.getField('archivo').setValue('');
        this.getField('ruta_archivo').setValue('');
        this.getField('fila_inicial').setValue('');
        this.getField('columna_codigo').setValue('');
        this.getField('columna_costo').setValue('');
        this.getField('fila_final_datos').setValue('');


        this.getField('archivo').setError(false,'');
        this.getField('ruta_archivo').setError(false,'');
        this.getField('fila_inicial').setError(false,'');
        this.getField('columna_codigo').setError(false,'');
        this.getField('columna_costo').setError(false,'');
        this.getField('fila_final_datos').setError(false,'');

        this.getField('modal_cargar').handleClickOpen();
      }
  }

  comenzarForm(){
    this.getField('comenzar').setDisabled(true);
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'comenzar', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessComenzar,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  sucessComenzar(data)
    {
        if (data.estado_p === 200)
        {
          this.getField('no_orden').setValue(data.data[0].xvalor);
          this.getField('no_orden').setError(false,'');
          this.getField('rejilla').toggle(true);
          this.refescarTabla();
        }
        else 
        {
            this.getField('rejilla').toggle(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           this.getField('comenzar').setDisabled(false);
       }
    }

    primeraVez(){
      let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'cargarprimeravez', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessPrimeraVez,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
    }

    sucessPrimeraVez(data)
    {
        if (data.estado_p === 200)
        {
          this.gridOptionsComponentes['rowData'] = [];
          let configCell = new Map();
          this.gridOptionsComponentes['rowData'] = data.data;
          configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });

          this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
          this.getField('rejilla').toggle(false);
             
        }
        else 
        {
          this.getField('rejilla').toggle(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }

  }

  setButtonDelete(props) {       
      let button = document.createElement("input");
      button.onclick = () => this.eliminarRegistro(props.data);
      button.setAttribute("id", 'button_delete_' + props.data.codigo_articulo);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("type", "button");
      button.setAttribute("value", "eliminar");
      return this.createElementJaivana(button);
  }
  
  eliminarRegistro(props) {
      this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
      this.getField('confirmModalCustom').setClickDialog(()=>this.confirmDelete(props));
      this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
  }

  confirmDelete(props) {
    this.getField('confirmModalCustom').toggle(false);
    let datos = {
        datos: {
          codigo_articulo:props.codigo_articulo
        }
    };
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: '7_item', operacion_tipo: "eliminar" };
    this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv
        });
  }
  
  successEliminar(data) {
      if(data.estado_p === 200){
        this.refescarTabla();          
      }else{
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
  }

  refescarTabla(){
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'refrescar', operacion_tipo: 'consulta' };
    this.service.send({
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.sucessRefrescar,
        error: this.error_,
        general: this.generalFormatPmv
    });
  }

  sucessRefrescar(data)
    {
        if (data.estado_p === 200)
        {
          this.gridOptionsComponentes['rowData'] = [];
          let configCell = new Map();
          this.gridOptionsComponentes['rowData'] = data.data.result;

          configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });

          this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
          this.getField('rejilla').toggle(true);
          //llenar totales
          this.getField('total_subtotal').setValue(data.data.totales.subtotal.toFixed(2));
          this.getField('valor_iva').setValue(data.data.totales.valoriva.toFixed(2));
          this.getField('impuestos').setValue(data.data.totales.impuestos.toFixed(2));
          this.getField('total_pedido').setValue(data.data.totales.valortotal.toFixed(2));

          this.getField('total_subtotal_visual').setValue(this.currencyFormatterGeneral(data.data.totales.subtotal.toFixed(2)));
          this.getField('valor_iva_visual').setValue(this.currencyFormatterGeneral(data.data.totales.valoriva.toFixed(2)));
          this.getField('impuestos_visual').setValue(this.currencyFormatterGeneral(data.data.totales.impuestos.toFixed(2)));
          this.getField('total_pedido_visual').setValue(this.currencyFormatterGeneral(data.data.totales.valortotal.toFixed(2)));
             
        }
        else 
        {
          this.getField('rejilla').toggle(false);
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }

  }


  llenarSelect(){
      let datos={ datos: {
        sucursal_ingreso: this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'cargarbodegas', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.suceesLlenarSelect,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }

  suceesLlenarSelect(data)
  {
    if (data.estado_p === 200 && data.data.result.length >= 1) {

      let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
      data.data.result.forEach(item => {
          let dataOp = {}
          dataOp['value'] = item.codigo;
          dataOp['text'] = item.cadena;
          dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
          opciones.push(dataOp);
      });
      this.getField('select_direccion').setOptions(opciones);
      if(data.data.data_defecto !== undefined){
          this.getField('select_direccion').setValue(data.data.data_defecto);
      }
    }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'No sé encontrarón direccion validas.', 'error');
        this.getField('plantillas').setVisible(false);
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
   }
  }

  limpiarCampos(){
      
    
  }

  currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(2).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    calcularDescuento(){
        var xcosto = this.getField('costo').getValue();
        var xdescuentoc = this.getField('descuento').getValue();
        var xdescuento = xcosto * (xdescuentoc / 100);
       
        // Acá se valida si el el valor tiene decimales; si tiene decimales, se hace el toFixed de lo contrario, no se hace.
        let decimales = Number.parseFloat(xcosto - xdescuento).toString().split(".")[1];
        if (decimales !== undefined && decimales.length > 2) {
          this.getField('precio_sin_iva').setValue("".formatoPrecio(Number.parseFloat(xcosto - xdescuento).toFixed(2)));
        }else{
          this.getField('precio_sin_iva').setValue("".formatoPrecio(Number.parseFloat(xcosto - xdescuento)));
        }
    }

    calcularItem(){

        let xtarifa_iva = this.getField('tarifa_iva').getValue();
        let ximpuestos1 = this.getField('impuestos2').getValue();
        let xcantidad1 = this.getField('cantidad_articulo').getValue();
        let xcosto1 =  this.getField('precio_sin_iva').getValue();
        let xcosto_mas_impuestos = xcosto1 + ximpuestos1;
        let xvalor_iva = xcosto1 * (xtarifa_iva / 100);

        this.getField('valor_sin_iva_ma_impuestos').setValue(Number.parseFloat(xcosto_mas_impuestos).toFixed(2));
        this.getField('valor_iva_unidad').setValue(Number.parseFloat(xvalor_iva).toFixed(2));
        this.getField('valor_unidad').setValue(Number.parseFloat(xvalor_iva + xcosto_mas_impuestos).toFixed(2));

        //total iva
        let xtotal_iva = xvalor_iva * xcantidad1;
        this.getField('total_iva').setValue(Number.parseFloat(xtotal_iva).toFixed(2));
        //total_impuestos
        let xtotal_impuestos = ximpuestos1 * xcantidad1;
        this.getField('total_impuestos').setValue(Number.parseFloat(xtotal_impuestos).toFixed(2));
        this.getField('impuestos_articulo').setValue(this.getField('impuestos2').getValue());

        //total_item
        let xtotal_item = xtotal_impuestos + xtotal_iva;
        xtotal_item = xtotal_item + (xcosto1 * xcantidad1);
        this.getField('total_item').setValue(Number.parseFloat(xtotal_item).toFixed(2));

    }

    traerPropiedades(){
      let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessTraerPropiedades,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }

    sucessTraerPropiedades(data){
      if (data.estado_p === 200 )
      {
          this.autoriza_ordenesc=data.data.autoriza_ordenesc;
          this.margen_costo_entrada=data.data.margen_costo_entrada;
      }
      else 
      { 
        this.autoriza_ordenesc='';
        this.margen_costo_entrada='';
      }
  }

    traerCostoBodega(){
      let datos={ datos: {
        value:this.getField('select_direccion').getValue(),
        codigo_articulo:this.getField('codigo_articulo').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: '1_bodega_costo', operacion_tipo: 'consulta' };
      this.service.send({
          endpoint: this.constant.formConfiguration(),
          method:'GET',
          body: datos,
          success: this.sucessTraerCostoBodega,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage:false
      });
    }

    sucessTraerCostoBodega(data){
      if (data.estado_p === 200 )
      {
          this.getField('costo_bodega').setValue(data.data[0].costo);
          //validar si es alterno
            let datos={ datos: {
              value:this.getField('select_direccion').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: '1_alterno', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessAlterno,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });

      }
      else 
      { 
          this.getField('costo_bodega').setValue(0);
          if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          }
          else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
      }
  }

  sucessAlterno(data){
    if (data.estado_p === 200 )
    {
        if(data.data[0].total>0){
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El artículo '+this.getField('codigo_articulo').getValue()+'\n '+this.getField('nombre_articulo').getValue()+' \nes alterno. ');
          this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false)});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);

          this.getField('codigo_articulo').setValue('');
          this.getField('nombre_articulo').setValue('');
          this.getField('tarifa_iva').setValue('');
          this.getField('descuento').setValue('');
          this.getField('precio_sin_iva').setValue(0);
          this.getField('costo').setValue('');
        }
    }
    else 
    { 
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
}



}
FormJaivana.addController("comp-grabarpedidos", CustomGrabacionDePedidos);
export default CustomGrabacionDePedidos;