import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @description: CustomGrabacionOrdenDeCompra
 * @author: Mario A. Sepúlveda R.
 * @version: jdesk_1.01.0001
 **/
class CustomGrabacionOrdenDeCompra extends FormJaivana.form {


   constructor(props) {

    super(props);
    this.initForm = this.initForm.bind(this);
    this.arrayObjetos=new Map();
    this.arrayCampos=new Map();
    this.bHayErrores=false;
    this.gridOptionsGrabacionOrdenCompra = Object.assign({},this.gridOptions);
    this.consultarTablaItems = this.consultarTablaItems.bind(this);
    this.successTraerItems = this.successTraerItems.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.setButtonAccion = this.setButtonAccion.bind(this);
    this.confirmEliminar = this.confirmEliminar.bind(this);
    this.validarEliminar = this.validarEliminar.bind(this);
    this.successEliminarItem = this.successEliminarItem.bind(this);
    this.openModalAgregarProducto = this.openModalAgregarProducto.bind(this);
    this.calcularTotales = this.calcularTotales.bind(this);
    this.cerrarModal = this.cerrarModal.bind(this);
    this.limpiarCamposModal = this.limpiarCamposModal.bind(this);
    this.grabarOrden = this.grabarOrden.bind(this);
    this.grabarItem = this.grabarItem.bind(this);
    this.successGrabarOrden = this.successGrabarOrden.bind(this);
    this.validarFechaMenorActual = this.validarFechaMenorActual.bind(this);
    this.validRangoFechas = this.validRangoFechas.bind(this);
    this.traerConsecutivo = this.traerConsecutivo.bind(this);
    this.successTraerConsecutivo = this.successTraerConsecutivo.bind(this);

    this.valid_fecha_orden_compra = true;
    this.valid_fecha_entrega = true;
    this.arrayTablaItemsNuevos = [];
    this.arrConcatenado = [];
    this.tamData = "";
    this.itemsEnviar = [];


   }

   initForm() {
    console.log('Formulario CustomGrabacionOrdenDeCompra,  @version: jdesk_1.01.0001, @author:Mario A. Sepúlveda R');

    this.arrayCampos.set(1,'id');;
    this.arrayCampos.set(2,'nit');
    this.arrayCampos.set(3,'sede');
    this.arrayCampos.set(4,'nombre_tercero');
    this.arrayCampos.set(5,'comenzar');
    this.arrayCampos.set(6,'rejilla');
    this.arrayCampos.set(7,'agregar_producto');
    this.arrayCampos.set(8,'subtotal_');
    this.arrayCampos.set(9,'valor_iva');
    this.arrayCampos.set(10,'observaciones');
    this.arrayCampos.set(11,'total');
    this.arrayCampos.set(12,'fecha_orden_compra');
    this.arrayCampos.set(13,'fecha_entrega');
    this.arrayCampos.set(14,'referencia_');
    this.arrayCampos.set(15,'terceros_id');
    this.arrayCampos.set(16,'grabar');
    this.arrayCampos.set(17,'cancelar');
    this.arrayCampos.set(18,'campo_oculto');
    this.arrayCampos.set(19,'datos_proveedor');
    this.arrayCampos.set(22,'codigo_articulo');
    this.arrayCampos.set(23,'nombre_cod');
    this.arrayCampos.set(24,'cantidad_');
    this.arrayCampos.set(25,'tarifa_iva_');
    this.arrayCampos.set(26,'precio');
    this.arrayCampos.set(27,'total_sin_iva');
    this.arrayCampos.set(28,'total_con_iva');
    this.arrayCampos.set(29,'camposd_');
    this.arrayCampos.set(30,'grabar_modal');
    this.arrayCampos.set(31,'cancelar_modal');
    this.arrayCampos.set(32,'articulo_id');
    this.arrayCampos.set(33,'campo_invisible');
    this.arrayCampos.set(50,'modal_agregar_prod');
//
    this.arrayObjetos.set(1,'id');
    this.arrayObjetos.set(2,'nit');
    this.arrayObjetos.set(3,'sede');
    this.arrayObjetos.set(4,'nombre_tercero');
    this.arrayObjetos.set(5,'comenzar');
    this.arrayObjetos.set(6,'rejilla');
    this.arrayObjetos.set(7,'agregar_producto');
    this.arrayObjetos.set(8,'subtotal_');
    this.arrayObjetos.set(9,'valor_iva');
    this.arrayObjetos.set(10,'observaciones');
    this.arrayObjetos.set(11,'total');
    this.arrayObjetos.set(12,'fecha_orden_compra');
    this.arrayObjetos.set(13,'fecha_entrega');
    this.arrayObjetos.set(14,'referencia_');
    this.arrayObjetos.set(15,'terceros_id');
    this.arrayObjetos.set(16,'grabar');
    this.arrayObjetos.set(17,'cancelar');
    this.arrayObjetos.set(18,'campo_oculto');
    this.arrayObjetos.set(19,'datos_proveedor');
    this.arrayObjetos.set(22,'codigo_articulo');
    this.arrayObjetos.set(23,'nombre_cod');
    this.arrayObjetos.set(24,'cantidad_');
    this.arrayObjetos.set(25,'tarifa_iva_');
    this.arrayObjetos.set(26,'precio');
    this.arrayObjetos.set(27,'total_sin_iva');
    this.arrayObjetos.set(28,'total_con_iva');
    this.arrayObjetos.set(29,'camposd_');
    this.arrayObjetos.set(30,'grabar_modal');
    this.arrayObjetos.set(31,'cancelar_modal');
    this.arrayObjetos.set(32,'articulo_id');
    this.arrayObjetos.set(33,'campo_invisible');
    this.arrayObjetos.set(50,'modal_agregar_prod');

    let codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    //console.log("codigo sucursal",codigo_sucursal);
    this.getField(this.arrayCampos.get(33)).setValue(codigo_sucursal);

    this.getField(this.arrayCampos.get(16)).setDisabled(true);// se desabilita el boton de grabar orden, hasta que se agregue un item nuevo

    this.getField(this.arrayCampos.get(5)).setClick(this.consultarTablaItems);
    this.getField(this.arrayCampos.get(3)).setOnChange(this.limpiarCampos);
    this.getField(this.arrayCampos.get(7)).setClick(this.openModalAgregarProducto);
    this.getField(this.arrayCampos.get(31)).setClick(this.cerrarModal);
    this.getField(this.arrayCampos.get(24)).setOnChange(this.calcularTotales);
    this.getField(this.arrayCampos.get(32)).setOnChange(this.limpiarCamposModal);
    this.getField(this.arrayCampos.get(17)).setClick(this.limpiarCampos);
    this.getField(this.arrayCampos.get(12)).setOnChange(()=>{
        let fecha_orden = this.getField(this.arrayCampos.get(12)).getValue();
        let validFechaOrden = this.validarFechaMenorActual(fecha_orden,true);

        if(!validFechaOrden){
            this.getField(this.arrayObjetos.get(12)).setError(true,'* La fecha debe ser igual o menor por 5 días a la fecha actual');
            this.valid_fecha_orden_compra =false;
        } else {
            this.getField(this.arrayObjetos.get(12)).setError(false,'');
            this.valid_fecha_orden_compra=true;
        }

        if(this.valid_fecha_orden_compra){
            this.validRangoFechas();
        }
    }); 


    this.getField(this.arrayCampos.get(13)).setOnChange(()=>{
        let fecha_entrega = this.getField(this.arrayCampos.get(13)).getValue();
        let validFechaEntrega = this.validarFechaMenorActual(fecha_entrega,false);
        if(validFechaEntrega){
            this.getField(this.arrayObjetos.get(13)).setError(false,'');
            this.valid_fecha_entrega=true;
        }else {
            this.getField(this.arrayObjetos.get(13)).setError(true,'* La fecha debe ser mayor o igual a la fecha actual');
            this.valid_fecha_entrega =false;
        }
        
    });

    this.getField(this.arrayCampos.get(26)).setOnChange(this.calcularTotales);
    this.getField(this.arrayCampos.get(16)).setClick(this.traerConsecutivo);//grabarOrden
    this.getField(this.arrayCampos.get(30)).setClick(this.grabarItem);

   }


   grabarItem(){
    //console.log("entro a grabar item");

    if(this.getField("nit").valid() && this.getField("sede").valid() && this.getField("codigo_articulo").valid() && 
        this.getField("cantidad_").valid() && this.getField("tarifa_iva_").valid() && this.getField("precio").valid()){
        let subtotal = this.getField(this.arrayCampos.get(24)).getValue() * this.getField(this.arrayCampos.get(26)).getValue();
        let valorIva = this.getField(this.arrayCampos.get(26)).getValue() * this.getField(this.arrayCampos.get(24)).getValue() * (this.getField(this.arrayCampos.get(25)).getValue()/100);
/* 
        let datos={ datos: {
            nit:this.getField(this.arrayCampos.get(2)).getValue(),
            sede:this.getField(this.arrayCampos.get(3)).getValue(),
            codigo:this.getField(this.arrayCampos.get(22)).getValue(),
            valor_item: this.getField(this.arrayCampos.get(28)).getValue(),
            subtotal1:subtotal,
            cantidad:this.getField(this.arrayCampos.get(24)).getValue(),
            tarifa_iva:this.getField(this.arrayCampos.get(25)).getValue(),
            valor_iva:valorIva,
        } }; */

        let tam = this.arrayTablaItemsNuevos.length;

        let obj = {
            cantidad:this.getField(this.arrayCampos.get(24)).getValue(),
            codigo:this.getField(this.arrayCampos.get(22)).getValue(),
            itemspedidoscompras_id:`pos${tam+1}`,
            iva:valorIva,
            precio_total_con_iva:this.getField(this.arrayCampos.get(28)).getValue(),
            //referencia:this.getField(this.arrayCampos.get(14)).getValue() !== "" ? this.getField(this.arrayCampos.get(14)).getValue():"0",
            tarifa_iva:this.getField(this.arrayCampos.get(25)).getValue(),
            //precio_sin_iva:this.getField(this.arrayCampos.get(28)).getValue() - valorIva,
            subtotal:subtotal,
            nombre:this.getField(this.arrayCampos.get(23)).getValue(),
        };

        let itemAgregado = this.arrConcatenado.find(item=>item.codigo === obj.codigo);

        //console.log("item: ",itemAgregado);
        if(itemAgregado === undefined ){
            //console.log("se agrego el item");
            this.arrayTablaItemsNuevos.push(obj);

        }else {
            this.alertGeneral.toggle(true,'*El item ya fue agregado a la orden de compra, no se permite duplicar','error');
            //console.log("no se debe agregar el item");

        }

        //console.log("datos a enviar a grabar item ,tam",tam,this.arrayTablaItemsNuevos);

        
        let objItems = {
            nit:'',
            sede:'',
            codigo:'',
            valor_item:'',
            subtotal1:'',
            cantidad:'',
            tarifa_iva:'',
            valor_iva:'',
        } 

        this.itemsEnviar = []; // limpia el array a enviar para volverlo a llenar

        this.arrayTablaItemsNuevos.forEach((item)=>{
            //console.log("item servicio",item);
            objItems = {
                codigo:item.codigo,
                valor_item:Number(item.precio_total_con_iva.toFixed(2)),
                subtotal1:Number(item.subtotal.toFixed(2)),
                cantidad:item.cantidad,
                tarifa_iva:item.tarifa_iva,
                valor_iva:Number(item.iva.toFixed(2)),
            }

            this.itemsEnviar.push(objItems);
            
        });

        this.consultarTablaItems();

        this.cerrarModal();

/*         this.generalFormatPmv = { tipo_servicio: 'maes-grabacionordencompra', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successGrabarItem,
                error: this.error_,
                general: this.generalFormatPmv,
        }); */
    } 

   }

   traerConsecutivo(){

        if(this.getField("fecha_orden_compra").valid() && this.getField("fecha_entrega").valid() && this.getField("observaciones").valid() && 
        this.getField("referencia_").valid() && this.getField(this.arrayCampos.get(33)).valid()){
        
            let datos={ datos: {
                sucursal:this.getField(this.arrayCampos.get(33)).getValue()
            } }; 

        this.generalFormatPmv = { tipo_servicio: 'maes-grabacionordencompra', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerConsecutivo,
                error: this.error_,
                general: this.generalFormatPmv,
        });

/*         this.consultarTablaItems();

        this.cerrarModal(); */

    }
   }

   successTraerConsecutivo(data){
        let num_consecutivo = data.data[0].numero;
        if(data.estado_p === 200){
            this.grabarOrden(num_consecutivo);
        }

   }

   grabarOrden(num_consecutivo){
    if(this.getField("fecha_orden_compra").valid() && this.getField("fecha_entrega").valid() && this.getField("observaciones").valid() && this.getField("referencia_").valid()){
        let datos={ datos: {
            nit:this.getField(this.arrayCampos.get(2)).getValue(),
            sede:parseInt(this.getField(this.arrayCampos.get(3)).getValue()),
            observaciones:this.getField(this.arrayCampos.get(10)).getValue(),
            iva: this.getField(this.arrayCampos.get(9)).getValue(),  
            valor:this.getField(this.arrayCampos.get(11)).getValue(),
            referencia:this.getField(this.arrayCampos.get(14)).getValue(),
            fecha_pedido:this.getField(this.arrayCampos.get(12)).getValue(),
            fecha:this.getField(this.arrayCampos.get(13)).getValue(),
            numero:num_consecutivo,
            productos:this.itemsEnviar
        } };

        //console.log("datos a enviar. consecutivo ",datos,this.itemsEnviar);
 
        this.generalFormatPmv = { tipo_servicio: 'maes-grabacionordencompra', operacion: 'grabarOrdenCompra', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successGrabarOrden,
                error: this.error_,
                general: this.generalFormatPmv,
        }); 
    }
   }

    successGrabarOrden(data){
        if(data.estado_p === 200){
            this.itemsEnviar = [];           
            this.limpiarCampos();
            //console.log("succes grabar :",this.itemsEnviar,this.arrayTablaItemsNuevos);
        }
    }

   validarFechaMenorActual(date,op){
    var x = new Date(date);
    var today = new Date();
    var hoy = new Date();

    hoy.setDate(hoy.getDate()-1);

    if(op){
        if (x <= today)
            return true;
        else
            return false;
    }else{
        if (x >= hoy )
            return true;
        else
            return false;
        }
    }

    validRangoFechas(){
        let fecha_orden_compra = new Date(this.getField(this.arrayCampos.get(12)).getValue());
        let  foc = new Date(fecha_orden_compra.getFullYear()+ "/" + (fecha_orden_compra.getMonth() +1) + "/" + fecha_orden_compra.getDate());
        let fecha_hoy = new Date();
        let  fh = new Date(fecha_hoy.getFullYear() + "/" + (fecha_hoy.getMonth() +1) + "/" + fecha_hoy.getDate() );

        let rango = Math.round(((fh.getTime() - foc.getTime()) / (1000*60*60*24)));

        if(rango > 5){
            this.getField(this.arrayObjetos.get(12)).setError(true,'* La fecha debe ser igual o menor por 5 días a la fecha actual');

        } else {
            this.getField(this.arrayObjetos.get(12)).setError(false,'');
        }
    }


   limpiarCamposModal(){
    this.establecerPropiedades('24,27,28','value','');
   }

   limpiarCampos(){
        this.establecerPropiedades('5','disable','false');
        this.getField(this.arrayCampos.get(6)).toggle(false);  
        this.establecerPropiedades('7,8,9,10,11,12,13,14,16,17','visible','false');
        this.establecerPropiedades('8,9,10,11,12,13,14','value','');
        this.establecerPropiedades('3,4,10,12,13','error',false);
        this.arrayTablaItemsNuevos=[];
        this.arrConcatenado = [];
        this.getField(this.arrayCampos.get(16)).setDisabled(false);// se desabilita el boton de grabar orden, hasta que se agregue un item nuevo

    }

   consultarTablaItems(){

    let tercero_id = this.getField(this.arrayCampos.get(15)).getValue();

    if(tercero_id !== '' && tercero_id !== ' ' && tercero_id !== null && tercero_id !== undefined){
        let datos={ datos: {
            nit: this.getField(this.arrayCampos.get(2)).getValue(),
            sede: parseInt(this.getField(this.arrayCampos.get(3)).getValue()),
        } };

        this.generalFormatPmv = { tipo_servicio: 'maes-grabacionordencompra', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerItems,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });

        this.establecerPropiedades('5','disable','true'); 
    } else{
        this.establecerPropiedades('3,4','error',true);
    }

    }

    successTraerItems(data){
        if( this.arrayTablaItemsNuevos.length === 0 ){
            //console.log("entro a if desabilitar",this.arrayTablaItemsNuevos);
            this.getField(this.arrayCampos.get(16)).setDisabled(true);// se desabilita el boton de grabar orden, hasta que se agregue un item nuevo

        }else{
            //console.log("entro a else, habilitar boton",this.arrayTablaItemsNuevos);
            this.getField(this.arrayCampos.get(16)).setDisabled(false);// se
        }
        if(data.estado_p === 200){
            //console.log("datos de la respuesta",data);

            let arrayDataIva = [];
            let obj = {
                cantidad:0,
                codigo:'',
                itemspedidoscompras_id:0,
                iva:0,
                precio_total_con_iva:0,
                referencia:'',
                tarifa_iva:0,
                precio_sin_iva:0,
                subtotal:0,
                nombre:''
            };
            let total = 0;
            let subtotal = 0;
            let iva = 0;
            this.arrConcatenado = data.data;
            this.tamData = data.data.length;
            if(this.arrayTablaItemsNuevos.length > 0){
                //console.log("entro a concatenar: ",this.arrayTablaItemsNuevos);

                this.arrConcatenado = data.data.concat(this.arrayTablaItemsNuevos);
            }

            //console.log("arreglo concatenado: ",this.arrConcatenado);


            this.arrConcatenado.forEach((item)=>{//
               obj = {
                    precio_sin_iva:(item.precio_total_con_iva / (1 + (item.tarifa_iva/100)))/item.cantidad,
                    cantidad:item.cantidad,
                    codigo:item.codigo,
                    itemspedidoscompras_id:item.itemspedidoscompras_id,
                    iva:item.iva,
                    precio_total_con_iva:item.precio_total_con_iva,
                    //referencia:item.referencia,
                    tarifa_iva:item.tarifa_iva,
                    subtotal:item.subtotal,
                    nombre:item.nombre
                }

                total += item.precio_total_con_iva;
                subtotal += item.subtotal;//subtotal += obj.precio_sin_iva;
                iva += item.iva;
                arrayDataIva.push(obj);

            });

            this.getField(this.arrayCampos.get(11)).setValue(total.toFixed(2));
            this.getField(this.arrayCampos.get(8)).setValue(subtotal.toFixed(2));
            this.getField(this.arrayCampos.get(9)).setValue(iva.toFixed(2));
            //this.getField(this.arrayCampos.get(14)).setValue(data.data[0].referencia);//

            //console.log('total,subtototal,iva '+total+','+subtotal+','+iva);

            this.establecerPropiedades('7,8,9,10,11,12,13,14,16,17','visible','true');//,8,9,10,11,12,13,14,16,17

            let configCell = new Map();

            configCell.set('codigo', {width: 140, field: 'codigo' });

            configCell.set('precio_sin_iva', {cellRenderer:(props) => {return "".formatoPrecio(props.data.precio_sin_iva.toFixed(2)); }, cellStyle: {textAlign:"right"},width: 140, field: 'precio_sin_iva'});//configCell.set('cupo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.cupo.toFixed(2)); }, cellStyle: {backgroundColor: "rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'},width: 100, field: 'cupo'});
            configCell.set('cantidad', {width: 100, cellStyle: {textAlign:"center"}, field: 'cantidad' });
            configCell.set('tarifa_iva', { width: 100, cellStyle: {textAlign:"center"}, field: 'tarifa_iva' });

            configCell.set('precio_total_con_iva', {cellRenderer:(props) => {return "".formatoPrecio(props.data.precio_total_con_iva.toFixed(2)); }, cellStyle: {textAlign:"right"},width: 170, field: 'precio_total_con_iva'});//configCell.set('cupo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.cupo.toFixed(2)); }, cellStyle: {backgroundColor: "rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'},width: 100, field: 'cupo'});

            configCell.set('accion', { cellRenderer: this.setButtonAccion, width: 120, sortable: false, filter: false, field: 'accion' });

            this.getField(this.arrayCampos.get(6)).toggle(true);      
            this.gridOptionsGrabacionOrdenCompra['rowData'] = arrayDataIva;
            this.getField(this.arrayCampos.get(6)).initData(this.gridOptionsGrabacionOrdenCompra,configCell);

        } else if(data.estado_p === 404){
            if(this.arrayTablaItemsNuevos.length > 0){
                //

            let arrayDataIva = [];
            let obj = {
                cantidad:0,
                codigo:'',
                itemspedidoscompras_id:0,
                iva:0,
                precio_total_con_iva:0,
                referencia:'',
                tarifa_iva:0,
                precio_sin_iva:0,
                subtotal:0,
                nombre:''
            };
            let total = 0;
            let subtotal = 0;
            let iva = 0;
            //this.arrConcatenado = data.data;
            this.tamData = 0;

            this.arrConcatenado = this.arrayTablaItemsNuevos;

            //console.log("arreglo concatenado: ",this.arrConcatenado);


            this.arrConcatenado.forEach((item)=>{//
               obj = {
                    precio_sin_iva:(item.precio_total_con_iva / (1 + (item.tarifa_iva/100)))/item.cantidad,
                    cantidad:item.cantidad,
                    codigo:item.codigo,
                    itemspedidoscompras_id:item.itemspedidoscompras_id,
                    iva:item.iva,
                    precio_total_con_iva:item.precio_total_con_iva,
                    //referencia:item.referencia,
                    tarifa_iva:item.tarifa_iva,
                    subtotal:item.subtotal,
                    nombre:item.nombre
                }

                total += item.precio_total_con_iva;
                subtotal += item.subtotal;//subtotal += obj.precio_sin_iva;
                iva += item.iva;
                arrayDataIva.push(obj);

            });

            this.getField(this.arrayCampos.get(11)).setValue(total.toFixed(2));
            this.getField(this.arrayCampos.get(8)).setValue(subtotal.toFixed(2));
            this.getField(this.arrayCampos.get(9)).setValue(iva.toFixed(2));
    
            //console.log('arra concatenaado ',this.arrConcatenado);
            //this.arrConcatenado === [] ? this.getField(this.arrayCampos.get(14)).setValue(this.arrConcatenado[0].referencia):this.getField(this.arrayCampos.get(14)).setValue("0");//

            this.establecerPropiedades('7,8,9,10,11,12,13,14,16,17','visible','true');//,8,9,10,11,12,13,14,16,17

            let configCell = new Map();

            configCell.set('codigo', {width: 140, field: 'codigo' });

            configCell.set('precio_sin_iva', {cellRenderer:(props) => {return "".formatoPrecio(props.data.precio_sin_iva.toFixed(2)); }, cellStyle: {textAlign:"right"},width: 140, field: 'precio_sin_iva'});//configCell.set('cupo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.cupo.toFixed(2)); }, cellStyle: {backgroundColor: "rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'},width: 100, field: 'cupo'});
            configCell.set('cantidad', {width: 100, cellStyle: {textAlign:"center"}, field: 'cantidad' });
            configCell.set('tarifa_iva', { width: 100, cellStyle: {textAlign:"center"}, field: 'tarifa_iva' });

            configCell.set('precio_total_con_iva', {cellRenderer:(props) => {return "".formatoPrecio(props.data.precio_total_con_iva.toFixed(2)); }, cellStyle: {textAlign:"right"},width: 170, field: 'precio_total_con_iva'});//configCell.set('cupo', {cellRenderer:(props) => {return "".formatoPrecio(props.data.cupo.toFixed(2)); }, cellStyle: {backgroundColor: "rgb(238,238,238)",textAlign:"right",borderRadius:"5px",color:'rgb(0, 86, 201)'},width: 100, field: 'cupo'});

            configCell.set('accion', { cellRenderer: this.setButtonAccion, width: 120, sortable: false, filter: false, field: 'accion' });

            this.getField(this.arrayCampos.get(6)).toggle(true);      
            this.gridOptionsGrabacionOrdenCompra['rowData'] = arrayDataIva;
            this.getField(this.arrayCampos.get(6)).initData(this.gridOptionsGrabacionOrdenCompra,configCell);
                //
            } else{
                //console.log("entro a else 404");
                this.gridOptionsGrabacionOrdenCompra['rowData'] = [];
                this.establecerPropiedades('7,8,9,10,11,12,13,14,16,17','visible','true');//,8,9,10,11,12,13,14,16,17
                this.getField(this.arrayCampos.get(11)).setValue(0);
                this.getField(this.arrayCampos.get(8)).setValue(0);
                this.getField(this.arrayCampos.get(9)).setValue(0);
                this.getField(this.arrayCampos.get(6)).toggle(false); 
                //this.habilitarCamposAgregar();
            }
        }
    }

    setButtonAccion(props) {
        let fragment = document.createDocumentFragment();
        let id = props.data.itemspedidoscompras_id;
        let button1 = document.createElement("input");
        button1.onclick = () => this.confirmEliminar(props);
        button1.setAttribute("id", 'button_delete_' + id);
        //button1.setAttribute("class", "buttonStyle");
        button1.setAttribute("type", "button");
        //button.classList.add(hover);
        button1.setAttribute("value", "Eliminar");
        button1.setAttribute("class","buttonStyle2");

        fragment.appendChild(button1);
        
        return this.createElementJaivana(fragment);
    }


    confirmEliminar(props){

        let id = props.data.itemspedidoscompras_id;//this.getField(this.arrayCampos.get(1)).getValue();

        //console.log("id a eliminar",id);
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(()=>this.validarEliminar(id));
        this.getField('confirmModalCustom').toggle(true);
    }

    validarEliminar(id){
        //let id_eliminar = parseInt(id);
        //console.log("id a eliminar: "+id);
        if(id !== '' && id !== ' ' && id !== null && id !== undefined){
            if(Number.isInteger(id)){
                let datos={ datos: {
                    grabacionorden_id:id,
                } };
    
                //console.log("valor entero a eliminar: ",datos);
    
                this.generalFormatPmv = { tipo_servicio: 'maes-grabacionordencompra', operacion: '7', operacion_tipo: 'eliminar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'DELETE',
                        body: datos,
                        success: this.successEliminarItem,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        //showMessage:false
                }); 
            }else {
                let pos = "";
                //console.log("valor string a eliminar: ",id);
                this.arrConcatenado.forEach((item,index)=>{
                    if(item.itemspedidoscompras_id === id){
                        pos=index;
                        //delete(this.arrConcatenado[pos]);
                        //delete(this.arrayTablaItemsNuevos[pos-this.tamData]);// no funciona ya que el elemento eliminado sigue apareciendo como un undefined
                        this.arrayTablaItemsNuevos.splice(pos-this.tamData,1);
                        this.itemsEnviar.splice(pos-1,1);
                        //this.itemsEnviar = [];
                        //console.log("posicion real a eliminar,tam data:",this.arrayTablaItemsNuevos,pos,this.itemsEnviar[pos-1],this.itemsEnviar);

                    }
                });
/* 
                this.itemsEnviar.forEach(()=>{
                    
                }); */

                this.consultarTablaItems();


            }
        }
        this.getField('confirmModalCustom').toggle(false);
    }

    successEliminarItem(data){
        this.limpiarCampos();
        if(data.estado_p === 200 ){
            //console.log("elimino exitosamente");
            this.consultarTablaItems();
        }
    }

    openModalAgregarProducto(){
        let datos = [];
        let obj = {
            nit:this.getField(this.arrayCampos.get(2)).getValue(),
            sede:this.getField(this.arrayCampos.get(3)).getValue(),
            nombre:this.getField(this.arrayCampos.get(4)).getValue(),
        }

        datos.push(obj);
        this.getField(this.arrayCampos.get(19)).setItemsFichas(datos);


        this.establecerPropiedades('22,23,24,25,26,27,28,30,31','visible','true');//
        this.getField("modal_agregar_prod").handleClickOpen();
    }

    calcularTotales(){
        let total_sin_iva = 0;
        let total_producto = 0;
        let articulo_id = this.getField(this.arrayCampos.get(32)).getValue();
        let precio = this.getField(this.arrayCampos.get(26)).getValue();
        let tarifa_iva = this.getField(this.arrayCampos.get(25)).getValue();
        if(articulo_id !== '' && articulo_id !== ' ' && articulo_id !== null && articulo_id !== undefined){
            total_sin_iva = this.getField(this.arrayCampos.get(24)).getValue() * precio;
            total_producto =total_sin_iva + (total_sin_iva * (tarifa_iva)/100) ;

            //console.log("totalsin iva: "+total_sin_iva+", totalcon iva: "+total_producto);

            this.getField(this.arrayCampos.get(27)).setValue(total_sin_iva.toFixed(2));
            this.getField(this.arrayCampos.get(28)).setValue(total_producto.toFixed(2));
        }
    }

    cerrarModal(){
        this.limpiarCamposModal();
        this.getField(this.arrayCampos.get(50)).handleClose();

    }




   establecerPropiedades(sObjetos,sPropiedad,sValor) 
   {
      const array = sObjetos.split(',');
      array.forEach((element) => 
      {
               //console.log(element);
               //console.log(this.arrayObjetos.get(parseInt(element)));
               if(sPropiedad==='disable') 
               { 
                   if(sValor==='true') { 
                       this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                   }
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                   }   
               }
               else if(sPropiedad==='visible') 
               { 
                   if(sValor==='true') { 
                       this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                   }
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                   }   
               }
               else if(sPropiedad==='value')
               { 
                   this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
               }  
               else if(sPropiedad==='valid')
               { 
                   if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                      //ok
                   }
                   else {
                       this.bHayErrores=true;
                   }
               }
               else if(sPropiedad==='foco')
               { 
                   if(sValor==='rejilla') { } 
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                   }
               }else if(sPropiedad === 'error'){
                if(sValor === false){
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'')
                } else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(true,'* Este campo es requerido','error')

                }
            }
     });     
  }

   cargarCampos(data) 
   {
          this.getField('id').setValue(data.data[0].id);
          this.getField('nit').setVale(data.data[0].nit);
          this.getField('sede').setVale(data.data[0].sede);
          this.getField('nombre_tercero').setVale(data.data[0].nombre_tercero);
          this.getField('comenzar').setVale(data.data[0].comenzar);
          this.getField('rejilla').setVale(data.data[0].rejilla);
          this.getField('agregar_producto').setVale(data.data[0].agregar_producto);
          this.getField('subtotal_').setVale(data.data[0].subtotal_);
          this.getField('valor_iva').setVale(data.data[0].valor_iva);
          this.getField('observaciones').setVale(data.data[0].observaciones);
          this.getField('total').setVale(data.data[0].total);
          this.getField('fecha_orden_compra').setVale(data.data[0].fecha_orden_compra);
          this.getField('fecha_entrega').setVale(data.data[0].fecha_entrega);
          this.getField('referencia_').setVale(data.data[0].referencia_);
          this.getField('campo_').setVale(data.data[0].campo_);
          this.getField('grabar').setVale(data.data[0].grabar);
          this.getField('cancelar').setVale(data.data[0].cancelar);
          this.getField('campo_oculto').setVale(data.data[0].campo_oculto);
          this.getField('datos_proveedor').setVale(data.data[0].datos_proveedor);

          //this.getField('nit_proveedor').setVale(data.data[0].nit_proveedor);
          //this.getField('sede_prov').setVale(data.data[0].sede_prov);
          //this.getField('nombre_provee').setVale(data.data[0].nombre_provee);
          this.getField('codigo_articulo').setVale(data.data[0].codigo_articulo);
          this.getField('nombre_cod').setVale(data.data[0].nombre_cod);
          this.getField('cantidad_').setVale(data.data[0].cantidad_);
          this.getField('tarifa_iva_').setVale(data.data[0].tarifa_iva_);
          this.getField('precio').setVale(data.data[0].precio);
          this.getField('total_sin_iva').setVale(data.data[0].total_sin_iva);
          this.getField('total_con_iva').setVale(data.data[0].total_con_iva);
          this.getField('camposd_').setVale(data.data[0].camposd_);
          this.getField('grabar_modal').setVale(data.data[0].grabar_modal);
          this.getField('cancelar_modal').setVale(data.data[0].cancelar_modal);
          this.getField('articulo_id').setVale(data.data[0].articulo_id);
          this.getField('modal_agregar_prod').setVale(data.data[0].modal_agregar_prod);

   }

}
FormJaivana.addController('maes-grabacionordencompra',CustomGrabacionOrdenDeCompra);
export default CustomGrabacionOrdenDeCompra;
