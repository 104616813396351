/**
 * @author Juan Camilo Villa 
 * @version jdesk_1.01.0004
 *  @description: CustomActivosFijosAjustes
 */

import FormJaivana from 'dashboard_jaivana_v1';

class CustomActivosFijosAjustes extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.btn_guardar = this.btn_guardar.bind(this);
        this.successGuardar = this.successGuardar.bind(this);
        this.limpiarCampos = this.limpiarCampos.bind(this);
        this.consultarActivosFijosAjustes = this.consultarActivosFijosAjustes.bind(this);
        this.successConsultarActivosFijosAjustes = this.successConsultarActivosFijosAjustes.bind(this);
        this.setButtonTableActivosFijosAjustes = this.setButtonTableActivosFijosAjustes.bind(this);
        this.accionVerImagen = this.accionVerImagen.bind(this);
        this.accionVerArchivo = this.accionVerArchivo.bind(this);
        this.mostrarMensajeCargando = this.mostrarMensajeCargando.bind(this);
        this.allowedExtensions = /(.txt|.TXT)$/i;
        this.allowedImagesExtensions = /(.jpg|.jpeg|.png|.JPG|.JPEG|.PNG)$/i;
        this.archivoTXT = "";
        this.archivoImagen = "";
        this.gridOptionsActivosFijosAjustes = Object.assign({}, this.gridOptions);
        this.validarEncabezado = this.validarEncabezado.bind(this);

        this.traerPrimero = this.traerPrimero.bind(this);
        this.successtraerPrimero = this.successtraerPrimero.bind(this);
        this.traerUltimo = this.traerUltimo.bind(this);
        this.successtraerUltimo = this.successtraerUltimo.bind(this);
        this.traerPorId = this.traerPorId.bind(this);
        this.successtraerPorId = this.successtraerPorId.bind(this);
        this.llenar = this.llenar.bind(this);

        this.accionEliminar = this.accionEliminar.bind(this);
        this.successEliminar = this.successEliminar.bind(this);
        this.confirmarEliminacionRegistro = this.confirmarEliminacionRegistro.bind(this);

        

        this.crearBotonTablaItemModal = this.crearBotonTablaItemModal.bind(this);
        this.accionEliminarItemModal = this.accionEliminarItemModal.bind(this);
        this.setButtonTableModal = this.setButtonTableModal.bind(this);
        this.accionEliminarItemModal = this.accionEliminarItemModal.bind(this);
        
        ///Modal
        
        this.crearBotonTabla = this.crearBotonTabla.bind(this);
        this.gridOptionsActivosFijosAjustesModal = Object.assign({}, this.gridOptions);
        this.arrayItems = [];
        this.btn_agregar_item = this.btn_agregar_item.bind(this);
        this.limpiarCamposModal = this.limpiarCamposModal.bind(this);
        
        //PDF y Excel
        this.sucursal_ingreso = '';
        this.btn_imprimir = this.btn_imprimir.bind(this);
        this.nombre_archivo_reporte = '';
        this.generarPdfTabla3 = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3 = this.generarExcelTabla3.bind(this);
        this.procesar = this.procesar.bind(this);
        this.isJson = this.isJson.bind(this);
        this.enviarCorreoTerceros = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo = this.successEnviarCorreo.bind(this);
        this.habilitarCorreo = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo = this.deshabilitarCorreo.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeEmailEnviado = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo = this.mostrarMensajeEmailFallo.bind(this);
        this.abrirImprimir = this.abrirImprimir.bind(this);
        this.operacion_actual = '';



    }

    initForm() {


        this.getField("btn_abrir_modal_item").setClick(() => this.validarEncabezado);
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        this.consultarActivosFijosAjustes();
        this.getField('rejilla').toggle(false);
        this.getField('rejilla_modal').toggle(false);
        console.log('Formulario CustomActivosFijosAjustes,  @version: jdesk_1.01.0004, @author:Juan Camilo Villa Osorio')
        this.getField("btn_guardar").setClick(this.btn_guardar);
        
        ///PDf y excel
        this.getField("btn_imprimir").setClick(this.btn_imprimir);
        this.getField('generar_archivo').setClick(()=>this.procesar());



        this.getField("btn_agregar_item").setClick(this.btn_agregar_item);


        this.getField("btn_abrir_modal_item").setClick(() => this.validarEncabezado());

        this.getField('archivos').setOnChange((props) => {
            (props.target.files[0] !== undefined && props.target.files[0] !== " ") ?
            this.archivoTXT = props.target.files[0]: this.archivoTXT = "";


        });
        this.getField('imagenes').setOnChange((props) => {
            ((props.target.files[0] !== undefined && props.target.files[0] !== " ")) ?
            this.archivoImagen = props.target.files[0]: this.archivoImagen = "";

        });

        this.getField('modal_agregar_item').setCloseButton(this.limpiarCamposModal);

        ///Pdf y excel

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.traerUltimo();

    }


    btn_guardar() {
        if ((this.getField('archivos').getValue() !== "" && this.getField('archivos').getValue() !== null) && this.getField('fechasistema').valid() &&
            (this.getField('imagenes').getValue() !== "" && this.getField('imagenes').getValue() !== null) && this.getField('concepto_id').valid() &&
            this.getField('bodegaorigen_id').valid() && this.getField('detalle').valid()) {

            if (this.arrayItems.length > 0) {
                if (!this.allowedExtensions.exec(this.archivoTXT.name)) {
                    this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .txt/.TXT', 'error');
                    this.getField('archivos').setError(false, "Formato incorrecto");
                } else if (this.archivoTXT.name.indexOf(' ') > 0) {
                    this.alertGeneral.toggle(true, 'El archivo (' + this.archivoTXT.name + ') No puede contener espacios.', 'error');
                    this.getField('archivos').setError(false);
                } else if (!this.allowedImagesExtensions.exec(this.archivoImagen.name)) {
                    this.alertGeneral.toggle(true, 'Solo se permiten imágenes con extensión .jpeg/.jpg/.png', 'error');
                    this.getField('imagenes').setError(false, "Formato incorrecto");
                } else if (this.archivoImagen.name.indexOf(' ') > 0) {
                    this.alertGeneral.toggle(true, 'La imagen (' + this.archivoImagen.name + ') No puede contener espacios.', 'error');
                    /* this.alertGeneral.toggle(true, 'La imagen ' + '(' + this.archivoImagen.name + ') ' + 'No puede contener espacios.', 'error'); */
                    this.getField('imagenes').setError(false, 'Contiene espacios');
                } else {
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está enviando la información... `);
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm('.'); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    const data = {
                        _generales: { "tipo_servicio": "serlog-activosfijosajustes", "operacion": "subirarchivo", "operacion_tipo": "crear" },
                        archivo: this.archivoTXT,
                        imagen: this.archivoImagen,
                        nombre_carpeta: 'img_activos_fijos_ajustes',
                        nombre_tabla: 'activos_fijos_ajustes',
                        detalle: this.getField('detalle').getValue(),
                        fechasistema: this.getField('fechasistema').getValue(),
                        concepto_id: this.getField('concepto_id').getValue(),
                        bodegaorigen_id: this.getField('bodegaorigen_id').getValue(),
                        items: JSON.stringify(this.arrayItems)
                    }

                    let url = this.constant.formConfiguration();
                    this.generalFormat = data._generales;
                    this.service.send({
                        endpoint: url,
                        method: 'POST',
                        body: data,
                        success: this.successGuardar,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: true,
                        showMessage: false
                    });
                }

            } else {
                this.alertGeneral.toggle(true, 'Debe agregar al menos 1 ítem', 'error');
            }


        } else {
            this.alertGeneral.toggle(true, 'Debe llenar todos los campos', 'error');
        }


    }

    /**
     * Contiene la respuesta de la petición, si es exitoso se limpian los campos
     * de lo contrario, se mostrará un mensaje de error.
     * @param {La fila seleccionada en la tabla} props: Contiene la respuesta de la petición 
     */
    successGuardar(props) {
        this.getField("confirmModalCustom").setVisibleConfirm(false);
        if (props.estado_p === 200) {
            this.limpiarCampos();
            this.consultarActivosFijosAjustes();
            this.getField('modal_agregar_item').handleClose();
            this.arrayItems = [];
            /* this.alertGeneral.toggle(true, "Envío exitoso", ); */
        } else {
            this.alertGeneral.toggle(true, props.data.mensaje, 'error');
        }
        this.getField('confirmModalCustom').toggle(false);
    }
        /**
         * Limpia todos los campos una vez se envíe el formulario
         */
    limpiarCampos() {
        this.archivoTXT = '';
        this.archivoImagen = '';
        this.getField('archivos').handleClickDelete();
        this.getField('imagenes').handleClickDelete();
        this.getField('detalle').setValue('');
        this.getField('fechasistema').setValue("");
        this.getField('concepto_id').setValue("");
        this.getField('bodegaorigen_id').setValue("");
        this.gridOptionsActivosFijosAjustes['rowData'] = [];
        this.gridOptionsActivosFijosAjustesModal['rowData'] = [];
        this.getField('rejilla').initData(this.gridOptionsActivosFijosAjustes);
        this.getField('rejilla').toggle(false);
        this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosAjustesModal);
        this.getField('rejilla_modal').toggle(false);
    }


    /**
     * Valida si el formulario del encabezado está lleno
     */
    validarEncabezado() {

        /* this.getField('modal_agregar_item').handleClickOpen(); */

     if (this.getField("archivos").valid() && this.getField("detalle").valid() &&
            this.getField("fechasistema").valid() && this.getField("imagenes").valid() &&
            this.getField("concepto_id").valid() && this.getField("bodegaorigen_id").valid()) {

                if (!this.allowedExtensions.exec(this.archivoTXT.name)) {
                    this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .txt/.TXT', 'error');
                    this.getField('archivos').setError(false, "Formato incorrecto");
                } else if (this.archivoTXT.name.indexOf(' ') > 0) {
                    this.alertGeneral.toggle(true, 'El archivo (' + this.archivoTXT.name + ') No puede contener espacios.', 'error');
                    this.getField('archivos').setError(false);
                } else if (!this.allowedImagesExtensions.exec(this.archivoImagen.name)) {
                    this.alertGeneral.toggle(true, 'Solo se permiten imágenes con extensión .jpeg/.jpg/.png', 'error');
                    this.getField('imagenes').setError(false, "Formato incorrecto");
                } else if (this.archivoImagen.name.indexOf(' ') > 0) {
                    this.alertGeneral.toggle(true, 'La imagen (' + this.archivoImagen.name + ') No puede contener espacios.', 'error');
                    this.getField('imagenes').setError(false, 'Contiene espacios');
                }else{
                    this.getField('modal_agregar_item').handleClickOpen();
                }

        } else {
            this.alertGeneral.toggle(true, 'Debe llenar todos los campos', 'error');
        }

/*         if (this.getField("archivos").valid() && this.getField("detalle").valid() &&
            this.getField("fechasistema").valid() && this.getField("imagenes").valid() &&
            this.getField("concepto_id").valid() && this.getField("bodegaorigen_id").valid()) {

            this.getField('modal_agregar_item').handleClickOpen()
        } else {
            this.alertGeneral.toggle(true, 'Debe llenar todos los campos', 'error');
        } */

    }


    /**
     * Consulta los encabezados existentes
     */
    consultarActivosFijosAjustes() {
        this.datosConsultar = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosajustes', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successConsultarActivosFijosAjustes,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    /**
     * Arma la tabla con el resultado de la petición
     * @param {Respuesta de la petición} data 
     */
    successConsultarActivosFijosAjustes(data) {
        if (data.estado_p === 200) {
            this.getField('btn_imprimir').setVisible(true);
            let configCell = new Map();
            this.gridOptionsActivosFijosAjustes['rowData'] = data.data;
            configCell.set('detalle', { headerName: 'Detalle' });
            configCell.set('fechasistema', { headerName: 'Fecha' });
            configCell.set('imagenes', { headerName: 'Imágenes' });
            configCell.set('archivos', { headerName: 'Archivos' });
            configCell.set('accion', { cellRenderer: this.setButtonTableActivosFijosAjustes, headerName: 'Acción', width: 600, sortable: false, filter: false });
            this.getField('rejilla').initData(this.gridOptionsActivosFijosAjustes, configCell);

        } else {
            this.gridOptionsActivosFijosAjustes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsActivosFijosAjustes);
            this.getField('rejilla').toggle(false);
            this.getField('btn_imprimir').setVisible(false);
        }

    }

    /**
     * Establece la creación del botón en la tabla
     * @param {Contiene los valores de la fila seleccionada en la tabla} props 
     */
    setButtonTableActivosFijosAjustes(props) {
        try {
            let fragment = document.createDocumentFragment();
            let buttonImage = this.crearBotonTabla(props.data, 'Ver Imagen');
            let buttonArchive = this.crearBotonTabla(props.data, 'Archivos');
            let buttonDelete = this.crearBotonTabla(props.data, 'Eliminar');
            fragment.appendChild(buttonImage);
            fragment.appendChild(buttonArchive);
            fragment.appendChild(buttonDelete);
            return this.createElementJaivana(fragment)
        } catch (error) {

        }
    }

    crearBotonTabla(data, nombreBoton) {
        let button = document.createElement('input');
        button.onclick = () => { this.accionVerImagen(data) };
        switch (nombreBoton) {
            case 'Ver Imagen':
                if (data.imagenes !== undefined && data.imagenes !== null && data.imagenes !== "") {
                    button.setAttribute("class", "buttonStyleImg");
                    button.onclick = () => { this.accionVerImagen(data) };
                } else {
                    button.setAttribute("class", "buttonStyleImgDisabled");
                    button.setAttribute("disable", true);
                }
                break;
            case 'Archivos':
                if (data.archivos !== undefined && data.archivos !== null && data.archivos !== "") {
                    button.setAttribute("class", "buttonStyleActivos");
                    button.onclick = () => { this.accionVerArchivo(data) };
                } else {
                    button.setAttribute("class", "buttonStyleImgDisabled2");
                    button.setAttribute("disable", true);
                }
                break;
            default:
                button.setAttribute("class", "buttonStyleImg3");
                button.onclick = () => { this.confirmarEliminacionRegistro(data) };
                break;
        }
        button.setAttribute('value', nombreBoton);
        button.setAttribute('type', 'button');
        button.setAttribute("class", "buttonStyle2");
        button.setAttribute("id", `buttonVerImagen_${data.id}`);
        return button;

    }

    /**
     * Establece la creación del botón eliminar de la tabla de los items de la modal
     * @param {Contiene los valores de la fila seleccionada en la tabla} props 
     */
    setButtonTableModal(props) {
        try {
            let fragment = document.createDocumentFragment();
            let buttonDelete = this.crearBotonTablaItemModal(props, 'Eliminar');
            fragment.appendChild(buttonDelete);
            return this.createElementJaivana(fragment)
        } catch (error) {

        }
    }

    crearBotonTablaItemModal(data, nombreBoton) {
        let button = document.createElement('input');
        button.onclick = () => { this.accionEliminarItemModal(data) };

        button.setAttribute("class", "buttonDeleteModal");
        button.setAttribute('value', nombreBoton);
        button.setAttribute('type', 'button');
        button.setAttribute("class", "buttonStyle2");
        button.setAttribute("id", "buttonDeleteModal1");
        /* button.setAttribute("id", `buttonVerImagen_${data.id}`); */
        return button;

    }

    accionEliminarItemModal(data){

        /* let arrayCopy = [...this.arrayItems]; */
        let index = this.arrayItems.indexOf(data.data);
        this.arrayItems.splice(index,1);
        this.gridOptionsActivosFijosAjustesModal['rowData'] = this.arrayItems;
        if (this.arrayItems.length > 0) {
            let configCell = new Map();
            configCell.set('accion', { cellRenderer: this.setButtonTableModal, headerName: 'Acción', width: 600, sortable: false, filter: false });
            this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosAjustesModal, configCell);
        } else {
            this.gridOptionsActivosFijosAjustesModal['rowData'] = [];
            this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosAjustesModal);
            this.getField('rejilla_modal').toggle(false);
        }




    }

    confirmarEliminacionRegistro(data) {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(() => this.accionEliminar(data));
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ 
            this.getField('confirmModalCustom').toggle(false);
             });
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField('confirmModalCustom').toggle(true);
    }

    accionEliminar(data){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está eliminando el registro... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); 
        this.getField("confirmModalCustom").setButtonConfirm('.');
        this.getField('confirmModalCustom').toggle(true);
        let arrayNameImage = (data.imagenes.split('/'));
        let nameImage = arrayNameImage[arrayNameImage.length -1];
        let arrayNameArchive = (data.archivos.split('/'));
        let nameArchive = arrayNameArchive[arrayNameArchive.length -1];
        
        const datos = {
            nombre_archivo: nameArchive,
            nombre_imagen: nameImage,
            concepto_id: Number(data.concepto_id),
            activos_fijos_ajustes_id : Number(data.activos_fijos_ajustes_id)
        }


        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosajustes', operacion: 'eliminar_ajustes', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv,
                formData: true,
                showMessage: false
        });


    }

    successEliminar(data){
        this.getField("confirmModalCustom").setButtonConfirm('.');
        if (data.estado_p === 200) {
            /* this.getField('confirmModalCustom').toggle(false); */
            this.consultarActivosFijosAjustes();
            this.limpiarCampos();
            this.limpiarCamposModal();
        }
        this.getField('confirmModalCustom').toggle(false);
            
    }

    traerPrimero() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosajustes', operacion: '3', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerPrimero,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }
    
    successtraerPrimero(data) {
        if (data.estado_p === 200) {
            this.llenar(data.data);
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error obteniendo el registro ', 'error');
            }
        }
    }

    traerUltimo() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosajustes', operacion: '4', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerUltimo,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successtraerUltimo(data) {
        if (data.estado_p === 200) {
            this.llenar(data.data);
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro el último registro.', 'error');
            }

        }
    }

    traerPorId() {
/*         let id_kardex= (this.getField('activos_fijos_kardex_id').getValue() === null 
        || this.getField('activos_fijos_kardex_id').getValue() === ''
        || this.getField('activos_fijos_kardex_id').getValue() === ' ') 
        ? parseInt(localStorage.getItem('activos_fijos_kardex_id'))
        : this.getField('activos_fijos_kardex_id').getValue() ; */
        let id_ajuste = this.getField('activos_fijos_ajustes_id').getValue();
        if (id_ajuste !== null && id_ajuste !== '' &&
            id_ajuste !== ' ') {
            let datos = {
                datos: {
                    activos_fijos_ajustes_id: id_ajuste
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosajustes', operacion: '2', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successtraerPorId,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successtraerPorId(data) {
        if (data.estado_p === 200) {
            this.llenar(data.data)
        }
    }



    llenar(data){
/*         this.getField('activos_fijos_ajustes_id').setValue(data[0].activos_fijos_ajustes_id);
        this.getField('archivos').setValue(data[0].archivos);
        this.getField('bodegaorigen_id').setValue(data[0].bodegaorigen_id);
        this.getField('concepto_id').setValue(data[0].concepto_id);
        this.getField('detalle').setValue(data[0].detalle);
        this.getField('fechasistema').setValue(data[0].fechasistema);
        this.getField('imagenes').setValue(data[0].imagenes); */

    }




    /**
     * Acción del botón ver imagen para ver la imagen cargada
     */
    accionVerImagen(data) {
        this.mostrarMensajeCargando();
        let nameImage = data.imagenes;
        let url = this.constant.formConfiguration();
        let urlImage = 'http://calidad.jaivanaweb.co:8084/remote.php/dav/files/admin/img_logisticos_activos_fijos_ajustes/' + nameImage;
        let datos = `?body={"datos":{"url":"${urlImage}"},"generales":{"tipo_servicio":"serlog-activosfijosajustes","operacion":"url_imagen","operacion_tipo":"consulta"}}`;

        fetch(url + datos, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();

                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .catch(error => {
            })
            .then(stream => new Response(stream))
            .catch(error => {
            })
            .then(response => {
                return response.blob()
            })
            .catch(error => {
            })
            .then(blob => {
                if (blob.size > 0) {
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",

                    });
                    window.open(URL.createObjectURL(image))
                } else {
                    this.alertGeneral.toggle(true, 'No hay datos', 'error');
                }
                this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
            })
            .catch(err => {

            });

    }


    /**
     * 
     * @param {Mensaje que se mostrará al usuario cuando se está cargando una imagen ó un archivo} mensaje 
     */
    mostrarMensajeCargando() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, ¡Se está cargando la vista previa! `);
        this.getField("confirmModalCustom").setVisibleCancel(true);
        this.getField("confirmModalCustom").setButtonConfirm('.');
        this.getField('confirmModalCustom').toggle(true);

    }

    /**
     * Botón para ver el archivo de la tabla
     * @param {El objeto seleccionado en la tabla} data 
     */
    accionVerArchivo(data) {
        this.mostrarMensajeCargando();
        let operation = "url_archivo";
        let nameArchivo = data.archivos;
        let urlArchivo = 'http://calidad.jaivanaweb.co:8084/remote.php/dav/files/admin/archivos_logisticos_activos_fijos_ajustes/' + nameArchivo;
        
        let urlLocal = this.constant.formConfiguration();
        let datos = `?body={"datos":{"url":"${urlArchivo}"},"generales":{"tipo_servicio":"serlog-activosfijosajustes","operacion":"${operation}","operacion_tipo":"consulta"}}`;

        fetch(urlLocal + datos, {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();

                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .catch(error => {

            })
            .then(stream => new Response(stream))
            .catch(error => {

            })
            .then(response => {
                return response.blob()
            })
            .catch(error => {

            })
            .then(blob => {

                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                // the filename you want
                a.download = nameArchivo;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
            })
            .catch(err => {

                return console.error(err);
            });
    }

    /// Funciones de la modal_item



    /**
     * Acción del botón Cargar Item.
     * Almacena los items que se van a ir agregando
     */
    btn_agregar_item() {
        if (this.getField("codigo_activo").getValue() !== "" && this.getField("nombre_activo").getValue() !== "" &&
            /* this.getField("bodega_id").getValue() !== "" && */ this.getField("detalle_item").getValue() !== "") {
                

            let arrayTemp = [];
            let item = {
                "detalle": this.getField("detalle_item").getValue(),
                /* "bodega": this.getField("bodega_id").getValue(), */
                "activo_id": this.getField('activos_fijos_basicos_logisticos_id').getValue(), 
                "serialactivo": this.getField('codigo_activo').getValue(),
                "nombre": this.getField("nombre_activo").getValue(),
                "codigo": this.getField("codigo_activo").getValue()
            };
            this.arrayItems.push(item);

            let configCell = new Map();

            arrayTemp = [...this.arrayItems];
            this.gridOptionsActivosFijosAjustesModal['rowData'] = arrayTemp;

            configCell.set('id', { headerName: 'activo_id' });
            configCell.set('detalle', { headerName: 'Detalle' });
            configCell.set('serialactivo', { headerName: 'Serial Activo' });
            /* configCell.set('codigo', { headerName: 'Código' }); */
            configCell.set('nombre', { headerName: 'Nombre de Activo' });
            configCell.set('accion', { cellRenderer: this.setButtonTableModal, headerName: 'Acción', width: 200, sortable: false, filter: false });
            this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosAjustesModal, configCell);

            this.limpiarCamposModal();
            /* item = {}; */

        } else {
            /*             this.gridOptionsActivosFijosAjustesModal['rowData'] = [];
                        this.getField('rejilla').initData(this.gridOptionsActivosFijosAjustesModal);
                        this.getField('rejilla_modal').toggle(false); */
            this.alertGeneral.toggle(true, 'Debe llenar todos los campos', 'error');
        }
    }

    btn_imprimir(){
        this.getField('modal_imprimir').handleClickOpen();
    }


    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        
        this.getField('email').setDisabled(false);
    }

    generarPdfTabla3()
    {       this.nombre_archivo_reporte = 'reporte_activos_fijos_ajustes';
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='imprimir';

            let datos2={
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual,
                sucursal_ingreso: this.sucursal_ingreso

            }

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"serlog-activosfijosajustes","operacion":"'+op+'","operacion_tipo":"consulta"}}';

            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo_reporte+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='imprimir';
            let datos2={
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual,
                sucursal_ingreso: this.sucursal_ingreso
            }
            this.nombre_archivo_reporte = 'reporte_activos_fijos_ajustes';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"serlog-activosfijosajustes","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo_reporte+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    enviarCorreoTerceros(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos={datos:{
            operacion : this.operacion_actual,
            email : this.getField('email').getValue(),
            radio_correo : this.getField('radio_correo').getValue(),
            operacion_form: this.operacion_form+'',
            sucursal_ingreso:this.sucursal_ingreso,
        }}
        

        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosajustes', operacion: 'imprimir', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    procesar(){
        
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){// Se comenta la validación de que sea diferente, ya que en la tarea de corrección "JQJD-1480" pidieron que si en "radio_correo" está seleccionado la opción de "NO", no fuera requerido digitar el correo, ya que en la tabla siempre va a ir 2 ítems, lo que significa que el pdf sólo va a tener una página.
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf ó Excel', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        /* this.getField('consultar').setDisabled(true); */
    
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }


    /**
     * Limpia los campos que están en la modal
     */
    limpiarCamposModal() {
        this.getField("codigo_activo").setValue("");
        this.getField("nombre_activo").setValue("");
        /* this.getField("bodega_id").setValue(""); */
        this.getField("detalle_item").setValue("");
        /* this.getField('rejilla_modal').initData(this.gridOptionsActivosFijosAjustesModal) */
            /* this.arrayItems = []; */
    }


}

FormJaivana.addController("serlog-activosfijosajustes", CustomActivosFijosAjustes);
export default CustomActivosFijosAjustes