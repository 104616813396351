import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Cristian Ciro
 * 
 * @version jdesk_1.01.0002
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomConsultaDeCompras extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);

        this.consultarCompra            = this.consultarCompra.bind(this);
        this.successDatosCompra         = this.successDatosCompra.bind(this);
        this.gridOptionsTbPrincipal     = Object.assign({},this.gridOptions);
        this.gridOptionsTbCompras       = Object.assign({},this.gridOptions);
        this.gridOptionsTbCompras2      = Object.assign({},this.gridOptions);
        this.gridOptionsRetencionesFletes      = Object.assign({},this.gridOptions);
        this.gripOptionsNovedades      = Object.assign({},this.gridOptions);
        
        this.onSelectionChangedRowTableMov      = this.onSelectionChangedRowTableMov.bind(this);
        this.onSelectionChangedRowTableMov2      = this.onSelectionChangedRowTableMov2.bind(this);
        this.onSelectionChanged      = this.onSelectionChanged.bind(this);

        this.gridOptionsTbCompras['onSelectionChanged']= this.onSelectionChanged;
        this.gridOptionsTbCompras2['onSelectionChanged']= this.onSelectionChanged;
        this.gridOptionsRetencionesFletes['onSelectionChanged']= this.onSelectionChanged;
        this.gripOptionsNovedades['onSelectionChanged']= this.onSelectionChanged;

        this.nitChange                  = this.nitChange.bind(this);
        this.codigoSucursalChange       = this.codigoSucursalChange.bind(this);
        this.ocultarCampos              = this.ocultarCampos.bind(this);
        this.validateDate               = this.validateDate.bind(this);

        this.openModal                  = this.openModal.bind(this);
        this.successPdf                 = this.successPdf.bind(this);
        this.successDocSop              = this.successDocSop.bind(this);
        this.successDocSopTipoDoc       = this.successDocSopTipoDoc.bind(this);
        this.enviarDocSoporte           = this.enviarDocSoporte.bind(this);
        this.mostrarMensajeNoHayDatos   = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
        this.traerNovedades                                  = this.traerNovedades.bind(this);
        this.sucessTraerDatosNovedades                                  = this.sucessTraerDatosNovedades.bind(this)
        this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this)

        this.isJson                     = this.isJson.bind(this);

        this.nombreArchivo              = "compra";

        this.validNit                   = false;
        this.validCodigoSucursal        = false;
        this.validNumero                = false;

        this.operacion_actual_imprimir  = "";
        this.selectedProceso            = [];

        this.enviarInforme              = this.enviarInforme.bind(this);
        this.accionOperacion            = "";

        this.successDiasDiferencia      = this.successDiasDiferencia.bind(this);
        this.diasDiferencia             = 30;

        this.successTotales             = this.successTotales.bind(this);
        this.successTotalesDoc          = this.successTotalesDoc.bind(this);
        this.successTotalesTipos        = this.successTotalesTipos.bind(this);
        this.enviar_correo=false;
        this.currencyFormatterGeneral   =   this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo   =   this.formatNumberSaldo.bind(this);
        this.sucursal_ingreso='';
        this.numero_decimales_compras=2;
    }

    initForm(){
        console.log('Formulario CustomConsultaDeCompras,  @version: jdesk_1.01.0002, @author:Cristian Ciro, @Modificado por Anderson Acevedo');
        this.sucursal_ingreso = '1101';

        let date = new Date();
        //let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        //let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        let firstDay = new Date(date.getFullYear(),
        date.getMonth(), 1);
 
        let lastDay = new Date(date.getFullYear(),
        date.getMonth(), this.daysInMonth(date.getMonth() + 1,
            date.getFullYear()));

        this.getField("desde").setValue(firstDay.getFullYear()+"-"+(firstDay.getMonth() + 1<10?"0"+(firstDay.getMonth() + 1):firstDay.getMonth() + 1)+"-"+(firstDay.getDate() < 10?"0"+firstDay.getDate():firstDay.getDate()));
        this.getField("hasta").setValue(lastDay.getFullYear()+"-"+(lastDay.getMonth() + 1<10?"0"+(lastDay.getMonth() + 1):lastDay.getMonth() + 1)+"-"+(lastDay.getDate() < 10?"0"+lastDay.getDate():lastDay.getDate()));

        this.getField("consultar").setClick(this.consultarCompra);

       this.getField("numero").setKeyUp(()=>{
            this.ocultarCampos();
            if(this.getField("numero").getValue()!==''){
                this.validNumero=true;
                this.getField("numero").valid();
            }else{
                this.getField("numero").setError(false,'');
                this.validNumero=false;
            }
         });
        this.getField("desde").setOnChange(this.validateDate);
        this.getField("hasta").setOnChange(this.validateDate);
        this.getField("nit").setKeyUp(this.nitChange);
        this.getField("codigo_sucursal").setKeyUp(this.codigoSucursalChange);


        this.getField("btn_reimprimir").setClick(() => {
            this.accionOperacion = "5_1";
            this.nombreArchivo = "compra";
            this.openModal();
        });
        this.getField("btn_resumen").setClick(() => {
            this.accionOperacion = "5_2";
            this.nombreArchivo = "resumen_compras";
            this.openModal();
        });

        this.getField("btn_articulos").setClick(() => {
            this.accionOperacion = "5_3";
            this.nombreArchivo = "articulo_compras";
            this.openModal();
        });

        
        this.getField("btn_documentos").setClick(() => {
            this.accionOperacion = "5_4";
            this.nombreArchivo = "documentos_compras";
            this.openModal();
        });

        this.getField("btn_tipos").setClick(() => {
            this.accionOperacion = "5_5";
            this.nombreArchivo = "tipo_compras";
            this.openModal();
        });

        this.getField("btn_listado").setClick(() => {
            this.accionOperacion = "5_6";
            this.nombreArchivo = "listado_compras";
            this.openModal();
        });

        this.getField("btn_resumen2").setClick(() => {
            this.accionOperacion = "5_7";
            this.nombreArchivo = "resumen_general";
            this.openModal();
        });

        this.getField("bt_ver_novedad").setClick(() => {
            if(this.selectedProceso.length>0){
                this.traerNovedades();
            }else{
                this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
            }       
        });

        this.getField("btn_doc_soporte").setClick(() => {
            if(this.selectedProceso.length>0){
                this.accionOperacion = "5_8";
                this.openModal();
            }else{
                this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
            }
            
        });

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual_imprimir='excel';
                this.getField('email').setDisabled(false);
                this.getField('radio_correo').setDisabled(false);
            }else{
                this.getField('ch_pantalla').setValueSwitch(true);
                this.getField('email').setError(false,'');
            }
        });
        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual_imprimir='pdf';
                this.getField('email').setDisabled(false);
                this.getField('radio_correo').setDisabled(false);
            }else{
                this.getField('ch_pantalla').setValueSwitch(true);
                this.getField('email').setError(false,'');
            }
        });
        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual_imprimir='pantalla';
                this.getField('email').setDisabled(true);
                this.getField('email').setValue('');
                this.getField('email').setError(false,'');
                this.getField('radio_correo').setDisabled(true);
            }else{
                this.getField('ch_pdf').setValueSwitch(true);
                this.getField('email').setDisabled(false);
                this.getField('radio_correo').setDisabled(false);
            }
        });

        this.getField("btn_resumen2").setDisabled(false);
        this.getField("btn_articulos").setDisabled(false);
        this.getField("btn_documentos").setDisabled(false);
        this.getField("btn_tipos").setDisabled(false);
        this.getField("btn_listado").setDisabled(false);
        this.getField("btn_doc_soporte").setDisabled(false);

        this.getField("btn_aceptar").setClick(this.enviarInforme);

        let body = {
            datos:{
                
            }
        }

        this.generalFormatPmv = { tipo_servicio: 'comp-consultacomprasconfactor', operacion: "traerpropiedades", operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: body,
            success: this.successDiasDiferencia,
            error: this.error_,
            showMessage: false,
            general: this.generalFormatPmv
        });

    } 

    traerNovedades(){
        let datos={
            datos: {
                numero:this.selectedProceso[0].numero,
                fecha:this.selectedProceso[0].fecha,
                codigo_sucursal:this.selectedProceso[0].codigo_sucursal,
                nit: this.selectedProceso[0].nit,
                sede: this.selectedProceso[0].sede,
                sucursal_usuario: this.sucursal_ingreso,
                fecha_inicial:this.getField('desde').getValue(),
                fecha_final:this.getField('hasta').getValue(),
            }
        }
        this.mostrarMensajeGenerando();
        this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: '5_10', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatosNovedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    
      sucessTraerDatosNovedades(data){
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p === 200){ 
                let datosFicha = [];
                let lista=data.data.datos_lista;
                let data2 = 
                    {
                        "empresa": lista.nombre_xml,
                        "nit": lista.nit_xml,
                        "fechas_consulta": lista.fechas_consulta,
                        "fecha_informe": lista.fecha_informe,
                        "usuario": lista.usuario,
                    };
                datosFicha.push(data2);
                this.getField('lista_novedades').setItemsFichas(datosFicha);
                this.getField('modal_novedades').setLabel('Novedades compra número '+this.xnumero);
                this.getField('modal_novedades').handleClickOpen();
    
                let configCell = new Map();
                configCell.set('id', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.id) }, hide:true});
    
                configCell.set('sede_compra', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede_compra) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad_ajuste', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_ajuste) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad_compra', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_compra) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad_orden', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_orden) }, cellStyle: {textAlign:"right"},width: 110});
    
               
                this.getField('rejilla_novedades').toggle(true);
                this.gripOptionsNovedades['rowData'] = [];
                this.gripOptionsNovedades['rowData'] = data.data.datos;
                this.getField('rejilla_novedades').initData(this.gripOptionsNovedades,configCell);
                
            }else if(data.estado_p === 404){
                //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                this.mostrarMensajeAdicional(data.data.mensaje);
            }
            else{
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            } 
        }

    successDiasDiferencia(data){
        if(data.estado_p === 200){
            this.numero_decimales_compras=data.data.numero_decimales_compras;
            this.diasDiferencia=data.data.dias_devolver;
        }
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    onSelectionChangedRowTableMov = () =>{
        this.selectedProceso = this.gridOptionsTbPrincipal.api.getSelectedRows();
        this.getField("btn_reimprimir").setDisabled(false);
        this.getField("btn_resumen").setDisabled(false);

        /*
        String xproveedor = visor.getRegistro().get("nit").toString();
        String xsede = visor.getRegistro().get("sede").toString();
        String xfecha_compra = visor.getRegistro().get("fecha").toString();
        String xnumero_compra = visor.getRegistro().get("numero").toString();
        String xsucursal = visor.getRegistro().get("codigo_sucursal").toString();
        */
    }

    onSelectionChanged(){

    }

    onSelectionChangedRowTableMov2 = () =>{

    }

    enviarInforme(){

        let valid = false, valid2 = false;

        if(this.getField("ch_pdf").getValue() === "true" || this.getField("ch_pantalla").getValue() === "true" || this.getField("ch_excel").getValue() === "true"){
            valid = true;
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar una opción (pdf, excel o pantalla).', 'error');
        }

        if(this.getField("ch_pdf").getValue() === "true" || this.getField("ch_excel").getValue() === "true"){
            if(!this.getField("email").valid()){
               valid = false; 
            }
        }

        if(this.accionOperacion === "5_7"){
            if(this.getField("select_clasificaciones").valid()){
                valid2 = true;
            }else{
                valid2 = false;
            }
        }else{
            valid2 = true;
        }

        if(valid && valid2){

            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Por favor espere, se está generando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(true); 
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            this.getField("btn_aceptar").setDisabled(true);
            let body = {
                datos:{
                    nit: this.accionOperacion === "5_1"? this.selectedProceso[0].nit:this.getField("nit").getValue(),//this.selectedProceso[0].nit,
                    sede: this.accionOperacion === "5_1"? this.selectedProceso[0].sede:this.getField("sede").getValue(),
                    fecha_inicial: this.getField("desde").getValue(),
                    fecha_final: this.getField("hasta").getValue(),
                    numero: this.accionOperacion === "5_1"? this.selectedProceso[0].numero:this.getField("numero").getValue(),
                    codigo_sucursal: this.accionOperacion === "5_1" || this.accionOperacion === "5_2"? this.selectedProceso[0].codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                    sucursal_usuario: JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal,
                    radio_correo: this.getField("radio_correo").getValue(),
                    email: this.getField("email").getValue(),
                    operacion_archivo: this.operacion_actual_imprimir,
                    campo:this.getField("select_clasificaciones").getValue(),
                    fecha:this.accionOperacion === "5_2"? this.selectedProceso[0].fecha:"",
                }
            }

            if(this.accionOperacion === '5_1'){
                body.datos.fecha = this.selectedProceso[0].fecha;
            }

            if(this.operacion_actual_imprimir === "pdf" || this.operacion_actual_imprimir === "excel"){
                this.enviar_correo=false;
                let url = this.constant.formConfiguration();
                let operacionAux = this.accionOperacion;
                if(this.accionOperacion !== "5_1" && this.accionOperacion !== "5_2")
                    operacionAux = this.accionOperacion+"_pag";

                const myJSON = JSON.stringify(body.datos);
                let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-consultadecompras","operacion":"'+operacionAux+'","operacion_tipo":"crear"}}';
                fetch(url+datos,{
                method:'GET', 
                headers:new Headers({
                        "Authorization": 'Bearer '+localStorage.getItem('token'), 
                        "Content-Type":'application/json'})})
                .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if(response.status!==200){
                return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                start(controller) {
                return pump();
                function pump() {
                        return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;}
                        controller.enqueue(value);
                        return pump();});}}})})
                .then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    if(blob.size>0){
                        blob.text().then(res=>{
                            this.getField("btn_aceptar").setDisabled(false);
                            if(this.isJson(res)){
                                this.enviar_correo=true;
                                this.successPdf(JSON.parse(res));
                            }else{
                                if(this.operacion_actual_imprimir === "pdf"){
                                    const pdf = new File([blob], this.nombreArchivo+'.pdf', {
                                        type:'application/pdf'});
                                    window.open(URL.createObjectURL(pdf));

                                }else{
                                    const url = window.URL.createObjectURL(blob);
                                    const a = document.createElement('a');
                                    a.style.display = 'none';
                                    a.href = url;
                                    // the filename you want
                                    a.download = this.nombreArchivo+'.xlsx';
                                    document.body.appendChild(a);
                                    a.click();
                                    window.URL.revokeObjectURL(url);
                                    alert('Se descargo el archivo!');
                                }
                            } 
                        });
                    }else{
                        this.mostrarMensajeNoHayDatos();
                        this.getField("btn_aceptar").setDisabled(false);
                    }
                }).catch(err => {
                    this.getField("btn_aceptar").setDisabled(false);
                });
            }else{

                if(this.accionOperacion === '5_3'){
                    this.getField("rejilla_articulos").setTipoServicioOperacion("comp-consultadecompras-"+this.accionOperacion);

                    this.getField("rejilla_articulos").sendRequest(this.successPdf, body);
                }else if(this.accionOperacion === '5_4'){
                    this.getField("rejilla_documentos").setTipoServicioOperacion("comp-consultadecompras-"+this.accionOperacion);

                    this.getField("rejilla_documentos").sendRequest(this.successPdf, body);
                    
                }else if(this.accionOperacion === '5_5'){
                    this.getField("rejilla_tipos").setTipoServicioOperacion("comp-consultadecompras-"+this.accionOperacion);

                    this.getField("rejilla_tipos").sendRequest(this.successPdf, body);
                    
                }else if(this.accionOperacion === '5_6'){
                    this.getField("rejilla_listado").setTipoServicioOperacion("comp-consultadecompras-"+this.accionOperacion);

                    this.getField("rejilla_listado").sendRequest(this.successPdf, body);
                    
                }else if(this.accionOperacion === '5_7'){
                    this.getField("rejilla_resumen_general").setTipoServicioOperacion("comp-consultadecompras-"+this.accionOperacion);

                    this.getField("rejilla_resumen_general").sendRequest(this.successPdf, body);
                    
                }else{
                    this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: this.accionOperacion, operacion_tipo: 'crear' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: body,
                        success: this.successPdf,
                        error: this.error_,
                        general: this.generalFormatPmv
                    });
                }
                //this.getField("modal_tablas4").handleClickOpen();
                /*this.getField("rejilla_documentos").setTipoServicioOperacion("comp-consultadecompras-5_4");

                this.getField("rejilla_documentos").sendRequest(this.successPdf, body);*/

                
            }
        }
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setVisibleConfirm(false);
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No recargue la página, por favor espere, se está generando la información...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setVisibleConfirm(true);
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    successPdf(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField("btn_aceptar").setDisabled(false);
        if(data.estado_p === 200){
            if(this.enviar_correo){
                this.mostrarMensajeAdicional(data.data.mensaje);
                this.enviar_correo=false;
            }else{
                if(data.data !== undefined && data.data !== null ){
                    if(this.accionOperacion === '5_1'){
                        //let cartones = 0;

                        this.gridOptionsTbCompras['rowData'] = data.data[0]["items"];
                        this.gridOptionsTbCompras['onSelectionChanged']= this.onSelectionChangedRowTableMov2;

                        let configCell = new Map();

                        configCell.set('xnombre',{cellRenderer:(props) => {return props.data.xnombre},field:'nombre_articulo'});
                        configCell.set('cantidad',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.cantidad)},type: 'rightAligned',field:'cantidad'});
                        configCell.set('pacas',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.pacas)},type: 'rightAligned',field:'pacas'});
                        configCell.set('tarifa_iva',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.tarifa_iva)},type: 'rightAligned',field:'tarifa_iva'});
                        configCell.set('valor_iva',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.valor_iva)},type: 'rightAligned',field:'valor_iva'});
                        configCell.set('valor_item',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.valor_item)},type: 'rightAligned',field:'valor_item'});
                        configCell.set('peso',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.peso*props.data.cantidad/1000.0)},type: 'rightAligned',field:'peso_kg'});
                        configCell.set('costo',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.costo)},type: 'rightAligned'});

                        this.getField('rejilla_compras').initData(this.gridOptionsTbCompras,configCell);

                        let datosFicha = [];
                        let data2 = 
                        {                  
                            "empresa": data.data[0]["parametros"].empresa_xml,
                            "direccion": data.data[0]["parametros"].direccion_xml,
                            "telefono": data.data[0]["parametros"].telefono_xml,
                            "ciudad": data.data[0]["parametros"].ciudad_xml ,
                            "nit": data.data[0]["parametros"].nit_xml,
                            "sucursal": data.data[0]["parametros"].cadena_sucursal,
                            "proveedor": data.data[0]["parametros"].nproveedor,
                            "nit_proveedor": data.data[0]["parametros"].nit+"-"+data.data[0]["parametros"].sede,
                            "direccion_proveedor":data.data[0]["parametros"].direccion,
                            "telefono_proveedor":data.data[0]["parametros"].telefono,
                            "ciudad_proveedor":data.data[0]["parametros"].ciudad,
                            "numero_compra": data.data[0]["parametros"].numero_compra

                        };

                        datosFicha.push(data2);
                        this.getField('lista_datos').setItemsFichas(datosFicha);


                        this.getField("reteiva").setValue("".formatoPrecio(data.data[0]["parametros"].reteiva.toFixed(this.numero_decimales_compras)));
                        this.getField("reteica").setValue("".formatoPrecio(data.data[0]["parametros"].reteica.toFixed(this.numero_decimales_compras)));
                        this.getField("fletes").setValue("".formatoPrecio(data.data[0]["parametros"].fletes.toFixed(this.numero_decimales_compras)));
                        this.getField("base_exenta").setValue("".formatoPrecio(data.data[0]["parametros"].basee.toFixed(this.numero_decimales_compras)));
                        this.getField("base_gravada").setValue("".formatoPrecio(data.data[0]["parametros"].baseg.toFixed(this.numero_decimales_compras)));
                        this.getField("cree").setValue("".formatoPrecio(data.data[0]["parametros"].cree.toFixed(this.numero_decimales_compras)));
                        this.getField("iva").setValue("".formatoPrecio(data.data[0]["parametros"].total_iva.toFixed(this.numero_decimales_compras)));
                        this.getField("aproximacion").setValue("".formatoPrecio(data.data[0]["parametros"].aproximacion.toFixed(this.numero_decimales_compras)));
                        this.getField("retencion").setValue("".formatoPrecio(data.data[0]["parametros"].retencion.toFixed(this.numero_decimales_compras)));
                        this.getField("total").setValue("".formatoPrecio(data.data[0]["parametros"].valor_total.toFixed(this.numero_decimales_compras)));

                        this.getField("fecha1").setValue(data.data[0]["parametros"].fecha1);
                        this.getField("fecha2").setValue(data.data[0]["parametros"].fecha2);
                        this.getField("fecha3").setValue(data.data[0]["parametros"].fecha3);
                        this.getField("desc1").setValue("".formatoPrecio(data.data[0]["parametros"].desc1.toFixed(this.numero_decimales_compras)));
                        this.getField("desc2").setValue("".formatoPrecio(data.data[0]["parametros"].desc2.toFixed(this.numero_decimales_compras)));
                        this.getField("desc3").setValue("".formatoPrecio(data.data[0]["parametros"].desc3.toFixed(this.numero_decimales_compras)));


                        this.getField("modal_tablas").handleClickOpen();
                    }

                    if(this.accionOperacion === '5_2'){
                        this.getField("modal_resumen").handleClickOpen();
                        let datos = [];

                        data.data.datos_lista["array_resumen"].forEach((item) => {
                            datos.push(item);
                        });

                        /*data.data[0]["items"]["resumen_1"].forEach((item) => {
                            datos.push(item);
                        });*/

                        let datosFicha = [];
                        let data2 = 
                        {                  
                            "empresa": data.data.datos_lista.empresa,
                            "direccion": data.data.datos_lista.direccion_xml,
                            "telefono": data.data.datos_lista.telefono_xml,
                            "nit": data.data.datos_lista.nit

                        };

                        datosFicha.push(data2);
                        this.getField('lista_datos2').setItemsFichas(datosFicha);
                        this.getField('fecha_resumen').setValue(data.data.datos_lista.fecha_resumen);

                        this.getField('valor_fletes_resumen').setValue(data.data.datos_lista.valor_fletes_resumen);
                        this.getField('valor_aproximacion_resumen').setValue(data.data.datos_lista.valor_aproximacion_resumen);
                        this.getField('valor_total_resumen').setVisible(true);
                        this.getField('valor_total_resumen').setValue(data.data.datos_lista.valor_total_resumen.toFixed(this.numero_decimales_compras));
                        this.getField('valor_numero_facturas_resumen').setVisible(true);
                        this.getField('valor_numero_facturas_resumen').setValue(data.data.datos_lista.valor_numero_facturas_resumen.toFixed(this.numero_decimales_compras));
                        

                        this.gridOptionsTbCompras['rowData'] = datos;
                        this.gridOptionsTbCompras2['rowData'] = data.data.datos_lista["array_retenciones"];
                        
                        let configCell = new Map();
                        configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.iva) }, cellStyle: {textAlign:"right"},width: 110});
                        configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
                        configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
                        configCell.set('base', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.base) }, cellStyle: {textAlign:"right"},width: 110});
                        configCell.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"},width: 110});
                                        //retenciones fletes
                        if(data.data.datos_lista.array_retenciones_fletes.length>0){
                            
                            this.getField('label_ff').setVisible(true);
                            this.getField('rejilla_retenciones_fletes').toggle(true);
                            this.gridOptionsRetencionesFletes['rowData'] = [];
                            this.gridOptionsRetencionesFletes['rowData'] = data.data.datos_lista.array_retenciones_fletes;
                            this.getField('rejilla_retenciones_fletes').initData(this.gridOptionsRetencionesFletes,configCell);
                        }else{
                            this.getField('label_ff').setVisible(false);
                        }

                        this.getField('rejilla_resumen').initData(this.gridOptionsTbCompras,configCell);
                        this.getField('rejilla_retenciones').initData(this.gridOptionsTbCompras2,configCell);
                        this.getField('valor_numero_facturas_resumen').setVisible(true);
                    }

                    if(this.accionOperacion === '5_3'){

                        let datosFicha = [];
                        let data2 = 
                        {                  
                            "empresa": data.data.datos_lista.empresa,
                            "nit": data.data.datos_lista.nit_xml,
                            "fechas_consulta": data.data.datos_lista.fechas_consulta,
                            "usuario": data.data.datos_lista.usuario,
                            "fecha_informe": data.data.datos_lista.fecha_sistema

                        };

                        datosFicha.push(data2);
                        this.getField('lista_datos3').setItemsFichas(datosFicha);

                        this.gridOptionsTbCompras['rowData'] = data.data.datos;
                        
                        let configCell = new Map();
                        configCell.set('cantidad',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.cantidad)},type: 'rightAligned',field:'cantidad'});
                        configCell.set('costo',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.costo)},type: 'rightAligned',field:'costo'});
                        configCell.set('valor',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.valor)},type: 'rightAligned',field:'valor'});

                        this.getField('rejilla_articulos').initData(this.gridOptionsTbCompras,configCell);
                        
                        let body = {
                            datos:{
                                fecha_inicial: this.getField("desde").getValue(),
                                fecha_final: this.getField("hasta").getValue(),
                                sucursal_usuario: JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal,
                            }
                        }
                
                        this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "5_3_totales", operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: body,
                            success: this.successTotales,
                            error: this.error_,
                            showMessage: false,
                            general: this.generalFormatPmv
                        });
                        /*this.getField("total_cantidad_general").setValue(''.formatoPrecio(cant.toFixed(this.numero_decimales_compras)));
                        this.getField("total_costo_general").setValue(''.formatoPrecio(costo.toFixed(this.numero_decimales_compras)));
                        this.getField("total_general").setValue(''.formatoPrecio(valor.toFixed(this.numero_decimales_compras)));*/

                        this.getField("modal_tablas3").handleClickOpen();
                    }

                    if(this.accionOperacion === '5_4'){

                        let datosFicha = [];
                        let data2 = 
                        {                  
                            "empresa": data.data.datos_lista.empresa,
                            "nit": data.data.datos_lista.nit_xml,
                            "fechas_consulta": data.data.datos_lista.fechas_consulta,
                            "usuario": data.data.datos_lista.usuario,
                            "fecha_informe": data.data.datos_lista.fecha_informe

                        };

                        datosFicha.push(data2);
                        this.getField('lista_datos4').setItemsFichas(datosFicha);

                        this.gridOptionsTbCompras['rowData'] = data.data.datos;
                        this.gridOptionsTbCompras['onSelectionChanged']= this.onSelectionChangedRowTableMov2;
                        
                        let configCell = new Map();
                        configCell.set('cantidad',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.cantidad)},type: 'rightAligned',field:'cantidad'});
                        configCell.set('costo',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.costo)},type: 'rightAligned'});
                        configCell.set('costo_item',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.costo_item)},type: 'rightAligned'});
                        let body = {
                            datos:{
                                nit: this.getField("nit").getValue(),//this.selectedProceso[0].nit,
                                fecha_inicial: this.getField("desde").getValue(),
                                fecha_final: this.getField("hasta").getValue(),
                                numero: this.getField("numero").getValue(),
                                codigo_sucursal: this.getField("codigo_sucursal").getValue()
                            }
                        }
                
                        this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "5_4_totales", operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: body,
                            success: this.successTotalesDoc,
                            error: this.error_,
                            showMessage: false,
                            general: this.generalFormatPmv
                        });
                        /*this.getField("total_costo").setValue(''.formatoPrecio(valor));
                        this.getField("total_costo_adm").setValue(''.formatoPrecio(valor_adm));*/

                        this.getField('rejilla_documentos').initData(this.gridOptionsTbCompras,configCell);
                        this.getField("modal_tablas4").handleClickOpen();
                    }

                    if(this.accionOperacion === '5_5'){
                        this.gridOptionsTbCompras['rowData'] = data.data.datos;
                        this.gridOptionsTbCompras['onSelectionChanged']= this.onSelectionChangedRowTableMov2;

                        let datosFicha = [];
                        let data2 = 
                        {                  
                            "empresa": data.data.datos_lista.nombre_xml,
                            "direccion": data.data.datos_lista.direccion_xml,
                            "telefono": data.data.datos_lista.telefono_xml,
                            "ciudad": data.data.datos_lista.ciudad_xml ,
                            "nit": data.data.datos_lista.nit_xml,
                            "fechas_consulta": data.data.datos_lista.fechas_consulta,
                            "usuario": data.data.datos_lista.usuario

                        };

                        datosFicha.push(data2);
                        this.getField('lista_datos5').setItemsFichas(datosFicha);
                        
                        let configCell = new Map();
                        configCell.set('total',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.total)},type: 'rightAligned'});
                        configCell.set('sede',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.sede)},type: 'rightAligned'});

                        this.getField('rejilla_tipos').initData(this.gridOptionsTbCompras,configCell);

                        let body = {
                            datos:{
                                nit: this.getField("nit").getValue(),//this.selectedProceso[0].nit,
                                fecha_inicial: this.getField("desde").getValue(),
                                fecha_final: this.getField("hasta").getValue(),
                                numero: this.getField("numero").getValue(),
                                codigo_sucursal: this.getField("codigo_sucursal").getValue()
                            }
                        }
                
                        this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "5_5_totales", operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: body,
                            success: this.successTotalesTipos,
                            error: this.error_,
                            showMessage: false,
                            general: this.generalFormatPmv
                        });

                        /*this.getField("total_costo_tipos").setValue(''+this.currencyFormatterGeneral(total1.toFixed(this.numero_decimales_compras)));
                        this.getField("total_costo_adm_tipos").setValue(''+this.currencyFormatterGeneral(total2.toFixed(this.numero_decimales_compras)));*/


                        this.getField("modal_tablas5").handleClickOpen();
                    }

                    if(this.accionOperacion === '5_6'){
                        this.gridOptionsTbCompras['rowData'] = data.data.datos;
                        this.gridOptionsTbCompras['onSelectionChanged']= this.onSelectionChangedRowTableMov2;

                        let datosFicha = [];
                        let data2 = 
                        {                  
                            "empresa": data.data.datos_lista.empresa_xml,
                            "nit": data.data.datos_lista.nit_xml,
                            "fechas_consulta": data.data.datos_lista.fechas_consulta,
                            "fecha_sistema": data.data.datos_lista.fecha_sistema,
                            "usuario": data.data.datos_lista.usuario,

                        };

                        datosFicha.push(data2);
                        this.getField('lista_datos6').setItemsFichas(datosFicha);
                        
                        let configCell = new Map();
                        configCell.set('cantidad',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.cantidad)},type: 'rightAligned'});
                        configCell.set('valor_unitario',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.valor_unitario)},type: 'rightAligned'});
                        configCell.set('total',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.total)},type: 'rightAligned'});
                        
                        let total_costo_total=data.data.datos_lista.total_costo_total;
                        let decimal = (total_costo_total + "").split(".")[1];//para manejar los decimales
                        if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
                            total_costo_total=total_costo_total.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                        }
                        this.getField('total_listado').setValue(total_costo_total);
                        this.getField('rejilla_listado').initData(this.gridOptionsTbCompras,configCell);
                        this.getField("modal_tablas6").handleClickOpen();
                    }

                    if(this.accionOperacion === '5_7'){
                        this.gridOptionsTbCompras['rowData'] = data.data.datos;
                        this.gridOptionsTbCompras['onSelectionChanged']= this.onSelectionChangedRowTableMov2;
                        
                        let datosFicha = [];
                        let data2 = 
                        {                  
                            "empresa": data.data.datos_lista.nombre_xml,
                            "nit": data.data.datos_lista.nit_xml,
                            "fechas_consulta": data.data.datos_lista.fechas_consulta,
                            "fecha_informe": data.data.datos_lista.fecha_informe,
                            "usuario": data.data.datos_lista.usuario,
                        };

                        datosFicha.push(data2);
                        this.getField('lista_datos7').setItemsFichas(datosFicha);

                        let configCell = new Map();
                        //configCell.set('valor_1',{field:'cantidad'});
                        let campo2 = "";
                        let campo3 = "";
                        if(this.getField("select_clasificaciones").getValue() === "grupo" || this.getField("select_clasificaciones").getValue() === "Grupo"){
                            campo2 = "nombre_grupo";
                            campo3 = "Grupo";
                        }else if(this.getField("select_clasificaciones").getValue() === "bodega" || this.getField("select_clasificaciones").getValue() === "Bodega"){
                            campo2 = "nombre"; 
                            campo3 = "Bodega";
                        }else{
                            campo2 = "nombre"; 
                            campo3 = "Nit";
                        }
                        configCell.set('valor_1',{cellRenderer:(props) => {return props.data.valor_1},field:campo3});
                        if(campo3 === "Nit"){
                            configCell.set('sede',{cellRenderer:(props) => {return props.data.sede},hide: false});
                        }else{
                            configCell.set('sede',{cellRenderer:(props) => {return props.data.sede},hide: true});
                        }
                        configCell.set('valor_2',{cellRenderer:(props) => {return props.data.valor_2},field:campo2});
                        configCell.set('vr_compras',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.vr_compras)},type: 'rightAligned',field:'vr_compras'});
                        configCell.set('vr_devcompras',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.vr_devcompras)},type: 'rightAligned',field:'vr_devcompras'});
                        configCell.set('neto',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.neto)},type: 'rightAligned',field:'neto'});

                        let total_costo_total=data.data.datos_lista.total_neto;
                        let decimal = (total_costo_total + "").split(".")[1];//para manejar los decimales
                        if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
                            total_costo_total=total_costo_total.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                        }
                        this.getField('total_resumen_general').setValue(total_costo_total);
                        this.getField('rejilla_resumen_general').initData(this.gridOptionsTbCompras,configCell);
                        let detalle='';
                        if(this.getField("select_clasificaciones").getValue() === "grupo" || this.getField("select_clasificaciones").getValue() === "Grupo"){
                            detalle='grupo'
                        }else if(this.getField("select_clasificaciones").getValue() === "bodega" || this.getField("select_clasificaciones").getValue()==='Bodega'){
                            detalle='bodega';
                        }else{
                            detalle='proveedor';
                        }
                        this.getField('modal_tablas7').setLabel('Resumen de compras por '+detalle);
                        this.getField("modal_tablas7").handleClickOpen();
                    }
                }
            }
        }else{
            this.mostrarMensajeNoHayDatos();
        }
    }

    openModal(){

        this.getField("radio_correo").setValue("N");
        this.getField('email').setDisabled(true);
        this.getField('radio_correo').setDisabled(true);
        this.getField('ch_pantalla').setValueSwitch(true);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_excel').setValueSwitch (false);

        this.operacion_actual_imprimir='pantalla';
        this.getField("btn_aceptar").setDisabled(false);
        if(this.accionOperacion === "5_8"){
            this.getField("btn_doc_soporte").setDisabled(true);
            this.getField("btn_reimprimir").setDisabled(true);
            this.getField("btn_resumen").setDisabled(true);
            this.getField("btn_resumen2").setDisabled(true);
            this.getField('bt_ver_novedad').setDisabled(true);
            this.getField("btn_articulos").setDisabled(true);
            this.getField("btn_documentos").setDisabled(true);
            this.getField("btn_tipos").setDisabled(true);
            this.getField("btn_listado").setDisabled(true);

            let body = {
                datos:{
                    nit: this.selectedProceso[0].nit,
                    sede: this.selectedProceso[0].sede
                }
            }
    
            this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "1_2", operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: body,
                success: this.successDocSop,
                error: this.error_,
                showMessage: false,
                general: this.generalFormatPmv
            });

        }else{
            if(this.accionOperacion === "5_7"){
                let opciones = [
                    {value:" ",text:"Ninguno"},
                    {value:"Grupo",text:"Grupo"},
                    {value:"Nit,sede",text:"Proveedor"},
                    {value:"Bodega",text:"Bodega"}
                ];
                this.getField("select_clasificaciones").setOptions(opciones);
                this.getField("select_clasificaciones").setVisible(true);
    
            }else{
                this.getField("select_clasificaciones").setVisible(false);
            }
            this.getField("modal_reimprimir").handleClickOpen();
        }
    }

    successTotales(data){
        if(data.estado_p === 200){
            if(data.data[0].cantidad !== null){
                this.getField("total_cantidad_general").setValue(''.formatoPrecio(data.data[0].cantidad.toFixed(this.numero_decimales_compras)));
                this.getField("total_costo_general").setValue(''.formatoPrecio((data.data[0].valor/data.data[0].cantidad).toFixed(this.numero_decimales_compras)));
                this.getField("total_general").setValue(''.formatoPrecio(data.data[0].valor.toFixed(this.numero_decimales_compras)));
            }else{
                this.getField("total_cantidad_general").setValue("0");
                this.getField("total_costo_general").setValue("0");
                this.getField("total_general").setValue("0");
            }
        }else{
            this.getField("total_cantidad_general").setValue("0");
            this.getField("total_costo_general").setValue("0");
            this.getField("total_general").setValue("0");
        }
    }

    successTotalesDoc(data){
        if(data.estado_p === 200){
            this.getField("total_costo").setValue(''.formatoPrecio(data.data[0].vr_item_total.toFixed(this.numero_decimales_compras)));
        }else{
            this.getField("total_costo").setValue("0");
        }
    }

    successTotalesTipos(data){
        if(data.estado_p === 200){
            this.getField("total_costo_tipos").setValue(''.formatoPrecio(data.data[0].total.toFixed(this.numero_decimales_compras)));
        }else{
            this.getField("total_costo_tipos").setValue("0");
        }
    }

    successDocSop(data){
        this.getField("btn_reimprimir").setDisabled(false);
        this.getField("btn_doc_soporte").setDisabled(false);
        this.getField("btn_resumen").setDisabled(false);
        this.getField("btn_resumen2").setDisabled(false);
        this.getField("bt_ver_novedad").setDisabled(false);
        this.getField("btn_articulos").setDisabled(false);
        this.getField("btn_documentos").setDisabled(false);
        this.getField("btn_tipos").setDisabled(false);
        this.getField("btn_listado").setDisabled(false);
        if(data.estado_p === 200){
            let regimen = data.data[0].regimen;

            if(regimen !== "RS"){
                this.getField('confirmModalCustom').setTitleAndContent('Error Documento soporte', 'No se puede enviar Documento Soporte, el proveedor debe ser régimen simplificado.');
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField("confirmModalCustom").setVisibleConfirm(true);
                this.getField("confirmModalCustom").setButtonCancel(`Confirmar`);
                this.getField('confirmModalCustom').toggle(true);
            }else{
                if(this.selectedProceso[0].estado_enviada === 'e' && this.selectedProceso[0].numero_documento_soporte !== null){
                    let body = {
                        datos:{
                            sucursal: this.selectedProceso[0].codigo_sucursal,
                            nit: this.selectedProceso[0].nit,
                            sede: this.selectedProceso[0].sede,
                            documento_equivalente_id: this.selectedProceso[0].documento_equivalente_id,
                            numero_documento_soporte: this.selectedProceso[0].numero_documento_soporte,
                            estado_enviada: this.selectedProceso[0].estado_enviada,
                            sucursal_usuario: this.sucursal_ingreso
                        }
                    }
            
                    this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "5_8", operacion_tipo: 'crear' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: body,
                        success: this.successDocSopTipoDoc,
                        error: this.error_,
                        showMessage: false,
                        general: this.generalFormatPmv
                    });
                }else{
                    this.getField('confirmModalCustom').setTitleAndContent('Documento soporte', '¿Desea genera el documento soporte para la compra # '+ this.selectedProceso[0].numero_documento_soporte +'?');
                    this.getField('confirmModalCustom').setClickDialog(() => {this.enviarDocSoporte(this.selectedProceso[0].numero_documento_soporte)});
                    this.getField("confirmModalCustom").setVisibleCancel(false);
                    this.getField("confirmModalCustom").setButtonConfirm(`Si`);
                    this.getField("confirmModalCustom").setButtonCancel(`No`);
                    this.getField('confirmModalCustom').toggle(true);
                }
            }
        }else{

        }
    }

    enviarDocSoporte(numero){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Documento soporte', 'Espere mientras se procesa el documento de la compra # '+ this.selectedProceso[0].numero_documento_soporte);
        this.getField("confirmModalCustom").setVisibleCancel(true);
        this.getField("confirmModalCustom").setVisibleConfirm(true);
        this.getField('confirmModalCustom').toggle(true);
        let body = {
            datos:{
                sucursal: this.selectedProceso[0].codigo_sucursal,
                nit: this.selectedProceso[0].nit,
                sede: this.selectedProceso[0].sede,
                documento_equivalente_id: this.selectedProceso[0].documento_equivalente_id,
                numero_documento_soporte: this.selectedProceso[0].numero_documento_soporte,
                estado_enviada: this.selectedProceso[0].estado_enviada,
                fecha: this.selectedProceso[0].fecha,
                numero: this.selectedProceso[0].numero,
            }
        }

        this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "5_9", operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: body,
            success: this.successDocSopTipoDoc,
            error: this.error_,
            showMessage: true,
            general: this.generalFormatPmv
        });


    }

    successDocSopTipoDoc(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.data.url!==undefined){
            window.open(data.data.url, '_blank').focus();
        }else{
            //nothing
        }
    }

    validateDate(){

        let fecha_actual = new Date().toISOString().split('T')[0];

        let fechaInicial = new Date(this.getField('desde').getValue()).getTime();
        let fechaFinal = new Date(this.getField('hasta').getValue()).getTime();

        this.getField('desde').setError(false,"");
        this.getField('hasta').setError(false,"");

        this.ocultarCampos();

        let dias = Math.round((fechaFinal-fechaInicial)/(24*60*60*1000));
        let fecha_desde = new Date(this.getField('desde').getValue()).toISOString().split('T')[0];
        let fecha_hasta= new Date(this.getField('hasta').getValue()).toISOString().split('T')[0];
        if(fecha_desde > fecha_actual){
            this.getField('consultar').setDisabled(true);
            this.getField('desde').setError(true, "¡Fecha desde debe ser menor o igual a fecha actual!");
            return false;
        }else{
            if (fecha_desde > fecha_hasta) {
                this.getField('desde').setError(true, "¡Fecha desde debe ser menor o igual a fecha hasta!");
                this.getField('consultar').setDisabled(true);
                return false;
            } else if (fechaFinal >= fechaInicial) {
                if(dias > this.diasDiferencia){
                    this.getField('consultar').setDisabled(true);
                    this.getField('desde').setError(true, "¡La diferencia de dias no puede ser mayor a "+this.diasDiferencia+"!");
                    return false;   
                }
                this.getField('consultar').setDisabled(false);
                return true;
            }
        }
        this.getField('consultar').setDisabled(false);
        return true;
    }

    nitChange(props){        
        this.ocultarCampos();
        if(this.getField("nit").getValue() === ""){
            this.getField("nit").setError(false,"");
            this.getField("nombre_consulta").setError(false,"");
            this.validNit = false;
        }else{
            this.validNit = true;
            this.getField("nit").valid();
            this.getField('nit').changeKeyUp(props);
        }
    }

    ocultarCampos(){
        this.getField("consultar").setDisabled(false);
        this.getField("btn_reimprimir").setVisible(false);
        this.getField("btn_doc_soporte").setVisible(false);
        this.getField("btn_articulos").setVisible(false);
        this.getField("btn_resumen").setVisible(false);
        this.getField("btn_resumen2").setVisible(false);
        this.getField("bt_ver_novedad").setVisible(false);
        this.getField("btn_documentos").setVisible(false);
        this.getField("btn_tipos").setVisible(false);
        this.getField("btn_listado").setVisible(false);
        this.getField('rejilla').toggle(false);
    }

    codigoSucursalChange(props){
        this.ocultarCampos();
        if(this.getField("codigo_sucursal").getValue() === ""){
            this.getField("codigo_sucursal").setError(false,"");
            this.getField("nombre_sucursal").setError(false,"");
            this.validCodigoSucursal = false;
        }else{
            this.validCodigoSucursal = true;
            this.getField("codigo_sucursal").valid();
            this.getField('codigo_sucursal').changeKeyUp(props);
        }
    }


    consultarCompra(){

        let validNameNit = false;
        let validSucursal = false;
        let validNum = false;

        if(this.validNit){
            if(this.getField("nombre_consulta").valid()){
                validNameNit = true;
            }
        }else{
            validNameNit = true;
        }

        if(this.validCodigoSucursal){
            if(this.getField("nombre_sucursal").valid()){
                validSucursal = true;
            }
        }else{
            validSucursal = true;
        }

        if(this.validNumero){
            if(this.getField("numero").valid()){
                validNum = true;
            }
        }else{
            validNum = true;
        }


        if(validNameNit && validSucursal && validNum){
            this.getField("consultar").setDisabled(true);
            let datos = { 
                datos: {
                    desde: this.getField("desde").getValue(),
                    hasta:this.getField("hasta").getValue(),
                    numero:this.getField("numero").getValue(),
                    nit: this.validNit?this.getField("nit").getValue():"",
                    codigo_sucursal: this.validCodigoSucursal?this.getField("codigo_sucursal").getValue():""
                }
            };

            this.getField("rejilla").setTipoServicioOperacion("comp-consultadecompras-consultar");

            this.getField("rejilla").sendRequest(this.successDatosCompra, datos);
            /*this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: 'consultar', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successDatosCompra,
                error: this.error_,
                general: this.generalFormatPmv
            });*/
        }
    }

    successDatosCompra(data){
        if(data.estado_p === 200){
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            this.gridOptionsTbPrincipal['onSelectionChanged']= this.onSelectionChangedRowTableMov;
            
            let configCell = new Map();

            configCell.set('valor',{cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor)},type: 'rightAligned',field:'valor'});
            configCell.set('basee',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.basee)},type: 'rightAligned',field:'basee'});
            configCell.set('baseg',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.baseg)},type: 'rightAligned',field:'baseg'});
            configCell.set('iva',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.iva)},type: 'rightAligned',field:'iva'});
            configCell.set('retencion',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.retencion)},type: 'rightAligned',field:'retencion'});
            configCell.set('porrete',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.porrete)},type: 'rightAligned',field:'porrete'});
            configCell.set('fletes',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.fletes)},type: 'rightAligned',field:'fletes'});
            configCell.set('aproxima',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.aproxima)},type: 'rightAligned',field:'aproxima'});
            configCell.set('impuestos',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.impuestos)},type: 'rightAligned',field:'impuestos'});
            configCell.set('reteiva',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.reteiva)},type: 'rightAligned',field:'reteiva'});
            configCell.set('reteica',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.reteica)},type: 'rightAligned',field:'reteica'});
            configCell.set('cree',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.cree)},type: 'rightAligned',field:'cree'});
            configCell.set('valor_descargue',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.valor_descargue)},type: 'rightAligned',field:'valor_descargue'});
            configCell.set('peso_kg',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.peso_kg)},type: 'rightAligned',field:'peso_kg'});
            configCell.set('cartones',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.cartones)},type: 'rightAligned',field:'cartones'});
            configCell.set('desc1',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.desc1)},type: 'rightAligned'});
            configCell.set('desc2',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.desc2)},type: 'rightAligned'});
            configCell.set('desc3',{cellRenderer:(props) => {return''+this.currencyFormatterGeneral(props.data.desc3)},type: 'rightAligned'});

            this.getField('rejilla').initData(this.gridOptionsTbPrincipal,configCell);

            this.getField("btn_reimprimir").setVisible(true);
            this.getField("btn_doc_soporte").setVisible(true);
            this.getField("btn_articulos").setVisible(true);
            this.getField("btn_resumen").setVisible(true);
            this.getField("btn_resumen2").setVisible(true);
            this.getField("bt_ver_novedad").setVisible(true);
            this.getField("btn_documentos").setVisible(true);
            this.getField("btn_tipos").setVisible(true);
            this.getField("btn_listado").setVisible(true);

            this.getField("btn_reimprimir").setDisabled(true);
            this.getField("btn_resumen").setDisabled(true);
        }else{
            this.mostrarMensajeNoHayDatos();
            this.ocultarCampos();
            this.getField('consultar').setDisabled(true);
        }
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleConfirm(false); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
        return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }



}
FormJaivana.addController("comp-consultadecompras",CustomConsultaDeCompras);
export default CustomConsultaDeCompras