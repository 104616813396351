import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomConsultaComprasConFactor
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 **/
class CustomConsultaComprasConFactor extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);

    this.onSelectionChangedPrincipal                                  = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChangedPrincipal;

    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsArticulos = Object.assign({}, this.gridOptions);
    this.gridOptionsArticulos['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsResumen = Object.assign({}, this.gridOptions);
    this.gridOptionsResumen['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsRetenciones = Object.assign({}, this.gridOptions);
    this.gridOptionsRetenciones['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsRetencionesFletes = Object.assign({}, this.gridOptions);
    this.gridOptionsRetencionesFletes['onSelectionChanged']           = this.onSelectionChanged;
    this.gripOptionsNovedades = Object.assign({}, this.gridOptions);
    this.gripOptionsNovedades['onSelectionChanged']           = this.onSelectionChanged;


    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.sucessconsultarDevoluciones                                  = this.sucessconsultarDevoluciones.bind(this);
    this.consultarDevoluciones                                  = this.consultarDevoluciones.bind(this);
    this.validarConsultar                                  = this.validarConsultar.bind(this);
    this.validarCamposFecha                                  = this.validarCamposFecha.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
    
    this.ocultarCampos                                  = this.ocultarCampos.bind(this);
    this.validarConcepto                                  = this.validarConcepto.bind(this);


    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);
    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.generarResumen                         =this.generarResumen.bind(this);
    this.generarDocumento                         =this.generarDocumento.bind(this);
    this.generarPorTipoProducto                         =this.generarPorTipoProducto.bind(this);
    this.traerDocSoporte                         =this.traerDocSoporte.bind(this);
    this.sucessTraerDocSoporte                         =this.sucessTraerDocSoporte.bind(this);
    this.sucessTraerDatosResumen                         =this.sucessTraerDatosResumen.bind(this);
    this.sucessTraerDatosArticulos                                  = this.sucessTraerDatosArticulos.bind(this);
    this.sucessTraerDatosDocumentos                                  = this.sucessTraerDatosDocumentos.bind(this);
    this.sucessTraerDatosTipos                                  = this.sucessTraerDatosTipos.bind(this);
    this.sucessTraerDatosListado                                  = this.sucessTraerDatosListado.bind(this);
    this.sucessTraerDatosResumenGeneral                                  = this.sucessTraerDatosResumenGeneral.bind(this);

    this.abrirTiposDocumentos                                  = this.abrirTiposDocumentos.bind(this);
    this.successTotalesArticulos                                  = this.successTotalesArticulos.bind(this);
    this.successTotalesDoc                                  = this.successTotalesDoc.bind(this);
    this.successTotalesTipos                                  = this.successTotalesTipos.bind(this);
    this.traerNovedades                                  = this.traerNovedades.bind(this);
    this.sucessTraerDatosNovedades                                  = this.sucessTraerDatosNovedades.bind(this);

    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.fecha_inicio='';
    this.dias_maximo='';
    this.sucursal_ingreso='';

    this.xsucursal = '';
    this.xnumero = '';
    this.xfecha = '';
    this.xnit = '';
    this.nproveedor = '';
    this.xsede = '';
    this.extension='txt';
    this.documento_equivalente_id = '';
    this.numero_documento_soporte = '';
    this.estado_enviada = '';
    this.docsopacomp=false;

    this.fila_seleccionada=[];
    this.boton='';
    this.nombre_pdf='';
    this.nombre_excel='';
    this.archivo_servicio='';
    this.archivo_operacion='';
    this.codigo_documento='';
    this.recargar_totales=true;
  }
  
  initForm() {
    console.log("Formulario CustomConsultaComprasConFactor,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

    //nuevo para consulta
    this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
    this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);
    this.getField('consultar').setClick(this.validarConsultar);

    this.getField('nit').setOnChange(()=>{
        this.getField('sede').setError(false,'');
        this.getField('nombre').setError(false,'');
        this.ocultarCampos();
    });
    this.getField('codigo_sucursal').setOnChange(()=>{
        this.getField('nombre_sucursal').setError(false,'');
        this.ocultarCampos();
        
    });

    this.getField('numero').setOnChange(()=>{
        this.ocultarCampos();
    });


    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('consultar_desde').setValue(this.fecha_inicio);
    //botones principal
    this.getField('bt_reimprimir').setClick(()=>{
        
        if(this.fila_seleccionada.length>0){
            this.boton='compras';
            this.nombre_archivo='compras';
            
            this.archivo_servicio='comp-grabarcompras';
            this.archivo_operacion='archivoitems';
            this.abrirImprimir();
        }
        else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    });
    this.getField('bt_articulos').setClick(()=>{
        this.boton='articulos';
        this.nombre_archivo='comprasxgrupo';
        this.archivo_servicio='comp-consultadecompras';
        this.archivo_operacion='5_3_pag';
        this.abrirImprimir();
    });

    this.getField('bt_resumen').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.boton='resumen';
            this.nombre_archivo='resumen_compra_'+this.xfecha;
            this.archivo_servicio='comp-consultadecompras';
            this.archivo_operacion='5_2';
            this.abrirImprimir();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    });


    this.getField('bt_documentos').setClick(()=>{
            this.boton='documentos';
            this.nombre_archivo='compras_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();
            this.archivo_servicio='comp-consultadecompras';
            this.archivo_operacion='5_4_pag';
            this.abrirImprimir();
    });

    this.getField('bt_tipos').setClick(()=>{
        this.boton='tipos';
        this.nombre_archivo='dev_compras_x_tipo_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();
        this.archivo_servicio='comp-consultadecompras';
        this.archivo_operacion='5_5_pag';
        this.abrirImprimir();
    });

    this.getField('bt_listado').setClick(()=>{
        this.boton='listado';
        this.nombre_archivo='dev_compras_x_tipo_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();
        this.archivo_servicio='comp-consultadecompras';
        this.archivo_operacion='5_6_pag';
        this.abrirImprimir();
    });

    this.getField('bt_resumen_general').setClick(()=>{
        this.boton='resumen_general';
        this.nombre_archivo='compras_resumen_general';
        this.archivo_servicio='comp-consultadecompras';
        this.archivo_operacion='5_7_pag';
        this.abrirImprimir();
    });

    this.getField('bt_doc_soporte').setClick(()=>{
        if(this.fila_seleccionada.length>0)
            this.traerDocSoporte();
        else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    });

    this.getField('bt_ver_novedad').setClick(()=>{
        if(this.fila_seleccionada.length>0)
            this.traerNovedades();
        else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    });


    //imprimir
    this.getField('radio_correo').setValue("N");
    this.getField('generar_archivo').setClick(()=>this.procesar());

    //para balances globales
    this.getField('ch_excel').setOnChange((props)=>{
      if(this.getField('ch_excel').getValue()==='false'){
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='excel';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pdf').setOnChange((props)=>{
      if(this.getField('ch_pdf').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='pdf';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pantalla').setOnChange((props)=>{
      if(this.getField('ch_pantalla').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.operacion_actual='pantalla';
        this.deshabilitarCorreo();
      }else{
          this.habilitarCorreo();
      }
    });


    //modal tipos documentos
    this.getField('bt_cancelar_documento').setClick(()=>{this.getField('modal_tipos_documentos').handleClose();});
    this.getField('bt_aceptar_documento').setClick(()=>{ this.validarConcepto();});
  }

  traerNovedades(){
    let datos={
        datos: {
            numero:this.xnumero,
            fecha:this.xfecha,
            codigo_sucursal:this.xsucursal,
            nit: this.xnit,
            sede: this.xsede,
            sucursal_usuario: this.sucursal_ingreso,
            fecha_inicial:this.getField('consultar_desde').getValue(),
            fecha_final:this.getField('consultar_hasta').getValue(),
        }
    }
    this.mostrarMensajeGenerando();
    this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: '5_10', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerDatosNovedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  sucessTraerDatosNovedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": lista.nombre_xml,
                    "nit": lista.nit_xml,
                    "fechas_consulta": lista.fechas_consulta,
                    "fecha_informe": lista.fecha_informe,
                    "usuario": lista.usuario,
                };
            datosFicha.push(data2);
            this.getField('lista_novedades').setItemsFichas(datosFicha);
            this.getField('modal_novedades').setLabel('Novedades compra número '+this.xnumero);
            this.getField('modal_novedades').handleClickOpen();

            let configCell = new Map();
            configCell.set('id', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.id) }, hide:true});

            configCell.set('sede_compra', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede_compra) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad_ajuste', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_ajuste) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad_compra', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_compra) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad_orden', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_orden) }, cellStyle: {textAlign:"right"},width: 110});

           
            this.getField('rejilla_novedades').toggle(true);
            this.gripOptionsNovedades['rowData'] = [];
            this.gripOptionsNovedades['rowData'] = data.data.datos;
            this.getField('rejilla_novedades').initData(this.gripOptionsNovedades,configCell);
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeAdicional(data.data.mensaje);
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

  abrirTiposDocumentos(data){
    this.getField('confirmModalCustom').toggle(false);
    
    if(data.estado_p === 200){  
        this.getField('modal_tipos_documentos').handleClickOpen();
        let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
        data.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.codigo_documento;
            dataOp['text'] = item.codigo_documento+' '+item.nombre;
            dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
            opciones.push(dataOp);
        });
        this.getField('select_documentos').setOptions(opciones);
        this.getField('label1_tp').setValue('Compra #: '+this.xnumero);
        this.getField('label2_tp').setValue('Nit. '+this.xnit+' Sede: '+this.xsede+'  '+this.nproveedor);
        this.getField('label3_tp').setValue('Sucursal: '+this.xsucursal);
    }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    }

    
   

  }

    validarConcepto(){
        if(this.getField('select_documentos').valid()){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                xnumero:this.xnumero,
                xfecha:this.xfecha,
                xsucursal:this.xsucursal,
                xnit:this.xnit,
                xsede:this.xsede,
                documento_equivalente_id:this.documento_equivalente_id,
                estado_enviada:this.estado_enviada,
                numero_documento_soporte:this.numero_documento_soporte,
                sucursal_ingreso:this.sucursal_ingreso,
                input:'0',
                select_documentos:this.getField('select_documentos').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-consultacomprasconfactor', operacion: 'generardocsoporte', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerDocSoporte,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }

    }

    traerDocSoporte(){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                xnumero:this.xnumero,
                xfecha:this.xfecha,
                xsucursal:this.xsucursal,
                xnit:this.xnit,
                
                xsede:this.xsede,
                documento_equivalente_id:this.documento_equivalente_id,
                estado_enviada:this.estado_enviada,
                numero_documento_soporte:this.numero_documento_soporte,
                sucursal_ingreso:this.sucursal_ingreso
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-consultacomprasconfactor', operacion: 'generardocsoporte', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerDocSoporte,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    sucessTraerDocSoporte(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            if(data.data.input!==undefined){
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Documento Soporte', data.data.mensaje_mostrar);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    //this.getField('modal_conceptos').handleClickOpen();
                    if(this.xsucursal!==null && this.xsucursal!==undefined &&
                        this.xsucursal!==null ){
                            this.mostrarMensajeGenerando();
                            let datos={ datos: {
                                xsucursal:this.xsucursal,
                                documento_equivalente_id:this.documento_equivalente_id,
                            }};
                            this.generalFormatPmv = { tipo_servicio: 'comp-consultacomprasconfactor', operacion: 'traertiposdocumentos', operacion_tipo: 'consulta' };
                            this.service.send(
                            {
                                    endpoint: this.constant.formConfiguration(),
                                    method:'GET',
                                    body: datos,
                                    success: this.abrirTiposDocumentos,
                                    error: this.error_,
                                    general: this.generalFormatPmv,
                                    showMessage: false
                            });
                        }else{
                            this.mostrarMensajeAdicional('Registro no tiene bien configurado el el codigo_sucursal.')
                        }
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm('SI'); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField("confirmModalCustom").setButtonCancel('NO');
                this.getField('confirmModalCustom').toggle(true);
            }else if(data.data.mensaje_mostrar!==undefined){
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
            }else if(data.data.mensaje_mostrar_2!==undefined){
                ///es cuando va y envia a la dian y hace un update antes, entonces es necesario refrescar
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar_2);
                this.getField('modal_tipos_documentos').handleClose();  
            }else if(data.data.url!==undefined){
                window.open(data.data.url, '_blank').focus();
            }else{
                //nothing
            }
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



  ocultarCampos(){
    this.fila_seleccionada=[];
    this.getField('consultar').setDisabled(false);
    this.getField('bt_reimprimir').setVisible(false);
    this.getField('bt_articulos').setVisible(false);
    this.getField('bt_resumen').setVisible(false);
    this.getField('bt_documentos').setVisible(false);
    this.getField('bt_listado').setVisible(false);
    this.getField('bt_tipos').setVisible(false);
    this.getField('bt_doc_soporte').setVisible(false);
    this.getField('bt_ver_novedad').setVisible(false);
    this.getField('bt_resumen_general').setVisible(false);

    this.getField('tabla_compras').toggle(false);
    
  }

  traerPropiedades(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'comp-consultacomprasconfactor', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
}

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
            this.dias_maximo=data.data.dias_devolver;
            this.docsopacomp=data.data.docsopacomp;

            if(this.docsopacomp)
                this.getField('bt_doc_soporte').setDisabled(false);
            else
            this.getField('bt_doc_soporte').setDisabled(true);
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        this.validarCamposFecha();
    }

  validarCamposFecha(){
    this.ocultarCampos();
    this.getField('consultar').setDisabled(false);
    this.getField("consultar_desde").setError(false,'');
    this.getField("consultar_hasta").setError(false,'');
    let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
    let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
    let fecha_actual = new Date().toISOString().split('T')[0];

    if(fechaFinal > fecha_actual){
        this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
        this.getField('consultar').setDisabled(true);
        return false;
    }

    if (fechaInicial > fechaFinal) {
        this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
        this.getField('consultar').setDisabled(true);
        return false;
    } 

    

    let diferencia=new Date(fechaFinal).getTime()-new Date(fechaInicial).getTime();
    diferencia = diferencia / 1000 / 60 / 60 / 24;
    if(diferencia >this.dias_maximo){
        this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
        this.getField('consultar').setDisabled(true); 
        return false;
    }

    this.getField('consultar_hasta').setError(false,"");
    return true;
}

  validarConsultar(){
    this.getField('tabla_compras').toggle(false);
    this.getField('nit').setError(false,'');
    this.getField('sede').setError(false,'');
    this.getField('numero').setError(false,'');
    this.getField('codigo_sucursal').setError(false,'');
    this.getField('nombre_sucursal').setError(false,'');

    let errores=0;
    if(!this.getField('consultar_hasta').valid())
        errores++;
    if(this.validarCamposFecha()===false)
        errores++;
    if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
        if(!this.getField('nit').valid())
            errores++;
        else if(!this.getField('sede').valid())
            errores++;
    }
    if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' '){
        if(!this.getField('numero').valid())
            errores++;
    }
    

    if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
        if(!this.getField('codigo_sucursal').valid())
            errores++;
        if(!this.getField('nombre_sucursal').valid())
            errores++;
    }
    
    if(errores===0){
        this.consultarDevoluciones();
    }

  }

  onSelectionChanged(){
    
  }

  onSelectionChangedPrincipal(){
    this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
    if(this.fila_seleccionada.length>0){
        this.xsucursal = this.fila_seleccionada[0].codigo_sucursal;
        this.xnumero= this.fila_seleccionada[0].numero;
        this.xfecha = this.fila_seleccionada[0].fecha;
        this.xnit = this.fila_seleccionada[0].nit;
        this.xsede = this.fila_seleccionada[0].sede;
        this.nproveedor = this.fila_seleccionada[0].nproveedor;
        
        this.documento_equivalente_id = this.fila_seleccionada[0].referencia;
        this.numero_documento_soporte = this.fila_seleccionada[0].numero_documento_soporte;
        this.estado_enviada = this.fila_seleccionada[0].estado_enviada;
    }
  }



  consultarDevoluciones(){
        this.fila_seleccionada=[];
        this.mostrarMensajeGenerando();
        this.getField('consultar').setDisabled(true);
        let datos={ datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
            sucursal_ingreso:this.sucursal_ingreso,
        }};
        if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
            datos.datos.nit=this.getField('nit').getValue();
            datos.datos.sede=this.getField('sede').getValue();
        }
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
            datos.datos.numero=this.getField('numero').getValue();

        this.getField("tabla_compras").setTipoServicioOperacion("comp-consultacomprasconfactor-consultar");
        this.getField("tabla_compras").sendRequest(this.sucessconsultarDevoluciones, datos);

    }

    sucessconsultarDevoluciones(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.getField('bt_tipos').setVisible(true);
            this.getField('bt_doc_soporte').setVisible(true);
            this.getField('bt_ver_novedad').setVisible(true);
            this.getField('bt_reimprimir').setVisible(true);
            this.getField('bt_articulos').setVisible(true);
            this.getField('bt_resumen').setVisible(true);
            this.getField('bt_documentos').setVisible(true);
            this.getField('bt_listado').setVisible(true);
            this.getField('bt_resumen_general').setVisible(true);
            
            
            let configCell = new Map();
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('basee', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.basee) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('baseg', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.baseg) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('retencion', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.retencion) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('fletes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.fletes) }, cellStyle: {textAlign:"right"},width: 110});


            this.getField('tabla_compras').toggle(true);

            
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('tabla_compras').initData(this.gridOptionsComponentes,configCell);
            
            
        }else{
            this.ocultarCampos();
            if(data.estado_p===404) {
                this.getField('consultar').setDisabled(true);
                this.mostrarMensajeNoHayDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);
    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }
    
      mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }

    //modal imprimir

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    abrirImprimir(){
            
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
        if(this.boton==='resumen_general'){
            let opciones = [
                {value:" ",text:"Ninguno"},
                {value:"Grupo",text:"Grupo"},
                {value:"Nit,sede",text:"Proveedor"},
                {value:"Bodega",text:"Bodega"}
            ];
            this.getField("select_clasificaciones").setOptions(opciones);
            this.getField('select_clasificaciones').setVisible(true)
            
        }else
            this.getField('select_clasificaciones').setVisible(false)
  }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(this.boton==='resumen_general'){
            if(!this.getField('select_clasificaciones').valid())
                errores++;
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    genearModalGlobales(){
    this.recargar_totales=true;
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos = {datos: {}};
      datos.datos= this.retornarBodyDatos();

        if(this.boton==='compras'){
            this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerDatos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else if(this.boton==='resumen'){
            this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerDatosResumen,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else if(this.boton==='articulos'){
            this.getField("rejilla_articulos").setTipoServicioOperacion(this.archivo_servicio+'-'+this.archivo_operacion.replaceAll('_pag',''));
            this.getField("rejilla_articulos").sendRequest(this.sucessTraerDatosArticulos, datos);
        }else if(this.boton==='documentos'){
            this.getField("rejilla_documentos").setTipoServicioOperacion(this.archivo_servicio+'-'+this.archivo_operacion.replaceAll('_pag',''));
            this.getField("rejilla_documentos").sendRequest(this.sucessTraerDatosDocumentos, datos);
        }
        else if(this.boton==='tipos'){
            this.getField("rejilla_tipos").setTipoServicioOperacion(this.archivo_servicio+'-'+this.archivo_operacion.replaceAll('_pag',''));
            this.getField("rejilla_tipos").sendRequest(this.sucessTraerDatosTipos, datos);
        }else if(this.boton==='listado'){
            this.getField("rejilla_listado").setTipoServicioOperacion(this.archivo_servicio+'-'+this.archivo_operacion.replaceAll('_pag',''));
            this.getField("rejilla_listado").sendRequest(this.sucessTraerDatosListado, datos);
        }
        else if(this.boton==='resumen_general'){
            this.getField("rejilla_general").setTipoServicioOperacion(this.archivo_servicio+'-'+this.archivo_operacion.replaceAll('_pag',''));
            this.getField("rejilla_general").sendRequest(this.sucessTraerDatosResumenGeneral, datos);
        }
      
      
  }
  sucessTraerDatosResumenGeneral(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let detalle='';
            if(this.getField("select_clasificaciones").getValue() === "grupo" || this.getField("select_clasificaciones").getValue() === "Grupo"){
                detalle='grupo'
            }else if(this.getField("select_clasificaciones").getValue() === "bodega" || this.getField("select_clasificaciones").getValue()==='Bodega'){
                detalle='bodega';
            }else{
                detalle='proveedor';
            }
            this.getField('modal_general').setLabel('Resumen de compras por '+detalle);

            let datosFicha = [];
            let lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": lista.nombre_xml,
                    "nit": lista.nit_xml,
                    "fechas_consulta": lista.fechas_consulta,
                    "fecha_informe": lista.fecha_informe,
                    "usuario": lista.usuario,
                };
            datosFicha.push(data2);

            this.getField('modal_general').handleClickOpen();
            let configCell = new Map();
            let campo2 = "";
            let campo3 = "";
            if(this.getField("select_clasificaciones").getValue() === "grupo" || this.getField("select_clasificaciones").getValue() === "Grupo"){
                campo2 = "nombre_grupo";
                campo3 = "Grupo";
            }else if(this.getField("select_clasificaciones").getValue() === "bodega" || this.getField("select_clasificaciones").getValue() === "Bodega"){
                campo2 = "nombre"; 
                campo3 = "Bodega";
            }else{
                campo2 = "nombre"; 
                campo3 = "Nit";
            }
            configCell.set('valor_1',{cellRenderer:(props) => {return props.data.valor_1},field:campo3});
            if(campo3 === "Nit"){
                configCell.set('sede',{cellRenderer:(props) => {return props.data.sede},hide: false});
            }else{
                configCell.set('sede',{cellRenderer:(props) => {return props.data.sede},hide: true});
            }

            configCell.set('valor_2',{cellRenderer:function(props){return props.data.valor_2},field:campo2});
            configCell.set('vr_compras', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vr_compras) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vr_devcompras', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vr_devcompras) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('neto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.neto) }, cellStyle: {textAlign:"right"},width: 110});

            //resumen--->
            this.getField('lista_general').setItemsFichas(datosFicha);
            let total_costo_total=lista.total_neto;
            let decimal = (total_costo_total + "").split(".")[1];//para manejar los decimales
            if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
                total_costo_total=total_costo_total.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
            }
            this.getField('total_general').setValue(total_costo_total);
            this.getField('rejilla_general').toggle(true);
            this.gridOptionsArticulos['rowData'] = [];
            this.gridOptionsArticulos['rowData'] = data.data.datos;
            this.getField('rejilla_general').initData(this.gridOptionsArticulos,configCell);
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

  sucessTraerDatosListado(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){ 
        let datosFicha = [];
        let lista=data.data.datos_lista;
        let data2 = 
            {
                "empresa": lista.empresa_xml,
                "nit": lista.nit_xml,
                "fechas_consulta": lista.fechas_consulta,
                "fecha_informe": lista.fecha_informe,
                "usuario": lista.usuario,
            };

        datosFicha.push(data2);
        this.getField('modal_listado').handleClickOpen();
        let configCell = new Map();
        configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
        configCell.set('valor_unitario', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_unitario) }, cellStyle: {textAlign:"right"},width: 110});
        configCell.set('total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total) }, cellStyle: {textAlign:"right"},width: 110});

        //resumen--->
        this.getField('lista_listado').setItemsFichas(datosFicha);
        let total_costo_total=lista.total_costo_total;
        let decimal = (total_costo_total + "").split(".")[1];//para manejar los decimales
        if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
            total_costo_total=total_costo_total.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
        }
        this.getField('total_listado').setValue(total_costo_total);
        this.getField('rejilla_listado').toggle(true);
        this.gridOptionsArticulos['rowData'] = [];
        this.gridOptionsArticulos['rowData'] = data.data.datos;
        this.getField('rejilla_listado').initData(this.gridOptionsArticulos,configCell);
        
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.mostrarMensajeNoHayDatos();
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
}

  sucessTraerDatosTipos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": lista.nombre_xml,
                    "nit": lista.nit_xml,
                    "fechas_consulta": lista.fechas_consulta,
                    "fecha_informe": lista.fecha_informe,
                    "usuario": lista.usuario,
                };

            datosFicha.push(data2);
            this.getField('modal_tipos').handleClickOpen();

            let configCell = new Map();
            configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total) }, cellStyle: {textAlign:"right"},width: 110});

            if(this.recargar_totales){
                let body = {
                    datos:{
                        nit: this.getField("nit").getValue(),//this.selectedProceso[0].nit,
                        fecha_inicial: this.getField("consultar_desde").getValue(),
                        fecha_final: this.getField("consultar_hasta").getValue(),
                        numero: this.getField("numero").getValue(),
                        codigo_sucursal: this.getField("codigo_sucursal").getValue()
                    }
                }
        
                this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "5_5_totales", operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: body,
                    success: this.successTotalesTipos,
                    error: this.error_,
                    showMessage: false,
                    general: this.generalFormatPmv
                });
            }
            this.recargar_totales=false;
            this.getField('lista_tipos').setItemsFichas(datosFicha);
            this.getField('rejilla_documentos').toggle(true);
            this.gridOptionsArticulos['rowData'] = [];
            this.gridOptionsArticulos['rowData'] = data.data.datos;
            this.getField('rejilla_tipos').initData(this.gridOptionsArticulos,configCell);
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    successTotalesTipos(data){
        if(data.estado_p === 200){
            let total_costo_total=data.data[0].total;
            let decimal = (total_costo_total + "").split(".")[1];//para manejar los decimales
            if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
                total_costo_total=total_costo_total.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
            }
            this.getField('total_costo_total_tipos').setValue(total_costo_total);
        }else{
            this.getField('total_costo_total_tipos').setValue(0);
        }
    }

  sucessTraerDatosDocumentos(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){ 
        let datosFicha = [];
        let lista=data.data.datos_lista;
        let data2 = 
            {
                "empresa": lista.empresa,
                "nit": lista.nit_xml,
                "fechas_consulta": lista.fechas_consulta,
                "usuario": lista.usuario,
                "fecha_informe": lista.fecha_informe
            };

        datosFicha.push(data2);
        this.getField('modal_documentos').handleClickOpen();

        let configCell = new Map();
        configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
        configCell.set('costo_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_item) }, cellStyle: {textAlign:"right"},width: 110});
        configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});

        if(this.recargar_totales){
            let body = {
                datos:{
                    nit: this.getField("nit").getValue(),
                    fecha_inicial: this.getField("consultar_desde").getValue(),
                    fecha_final: this.getField("consultar_hasta").getValue(),
                    numero: this.getField("numero").getValue(),
                    codigo_sucursal: this.getField("codigo_sucursal").getValue()
                }
            }
    
            this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "5_4_totales", operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: body,
                success: this.successTotalesDoc,
                error: this.error_,
                showMessage: false,
                general: this.generalFormatPmv
            });
        }
        this.recargar_totales=false;
        this.getField('lista_documentos').setItemsFichas(datosFicha);
        
        this.getField('rejilla_documentos').toggle(true);
        this.gridOptionsArticulos['rowData'] = [];
        this.gridOptionsArticulos['rowData'] = data.data.datos;
        this.getField('rejilla_documentos').initData(this.gridOptionsArticulos,configCell);
        
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.mostrarMensajeNoHayDatos();
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
}

successTotalesDoc(data){
    if(data.estado_p === 200){
        let total_costo_total=data.data[0].vr_item_total;
        let decimal = (total_costo_total + "").split(".")[1];//para manejar los decimales
        if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
            total_costo_total=total_costo_total.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
        }
        this.getField('total_costo_total_articulos').setValue(total_costo_total);
    }else{
        this.getField('total_costo_total_articulos').setValue(0);
    }
}

  sucessTraerDatosArticulos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": lista.empresa,
                    "nit": lista.nit_xml,
                    "fechas_consulta": lista.fechas_consulta,
                    "fecha_informe": lista.fecha_sistema,
                    "usuario": lista.usuario,
                };
            datosFicha.push(data2);
            this.getField('modal_articulos').handleClickOpen();

            let configCell = new Map();
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});

            if(this.recargar_totales){
                let body = {
                    datos:{
                        fecha_inicial: this.getField("consultar_desde").getValue(),
                        fecha_final: this.getField("consultar_hasta").getValue(),
                        sucursal_usuario: this.sucursal_ingreso,
                    }
                }
                this.generalFormatPmv = { tipo_servicio: 'comp-consultadecompras', operacion: "5_3_totales", operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: body,
                    success: this.successTotalesArticulos,
                    error: this.error_,
                    showMessage: false,
                    general: this.generalFormatPmv
                });
            }
            this.recargar_totales=false;
            this.getField('lista_articulos').setItemsFichas(datosFicha);
            this.getField('rejilla_articulos').toggle(true);
            this.gridOptionsArticulos['rowData'] = [];
            this.gridOptionsArticulos['rowData'] = data.data.datos;
            this.getField('rejilla_articulos').initData(this.gridOptionsArticulos,configCell);
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    successTotalesArticulos(data){
        if(data.estado_p === 200){
            let total_cantidad=data.data[0].cantidad;
            let total_costo_unitario=data.data[0].valor/data.data[0].cantidad;
            let total_costo_total=data.data[0].valor;
            let decimal = (total_cantidad + "").split(".")[1];//para manejar los decimales
            if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
                total_cantidad=total_cantidad.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
            }
            decimal = (total_costo_unitario + "").split(".")[1];//para manejar los decimales
            if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
                total_costo_unitario=total_costo_unitario.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
            }
            decimal = (total_costo_total + "").split(".")[1];//para manejar los decimales
            if(decimal !== undefined && decimal.length>this.numero_decimales_compras){
                total_costo_total=total_costo_total.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
            }
            this.getField('total_cantidad').setValue(total_cantidad);
            this.getField('total_costo_unitario').setValue(total_costo_unitario);
            this.getField('total_costo_total').setValue(total_costo_total);
        }else{
            this.getField('total_cantidad').setValue(0);
            this.getField('total_costo_unitario').setValue(0);
            this.getField('total_costo_total').setValue(0);
        }
    }

  sucessTraerDatosResumen(data){
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){ 
          let datosFicha = [];
          let lista=data.data.datos_lista;
          let data2 = 
              {
                  "empresa": lista.empresa,
                  "nit": lista.nit,
                  "direccion": lista.direccion_xml,
                  "telefono": lista.telefono_xml,
                  "fecha_resumen": lista.fecha_resumen,
              };

          datosFicha.push(data2);

          this.getField('modal_resumen').handleClickOpen();
          let configCell = new Map();
          configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"}});
          configCell.set('base', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.base) }, cellStyle: {textAlign:"right"},width: 110});
          configCell.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"}});


          //resumen--->
          this.getField('list_resumen').setItemsFichas(datosFicha);
          this.getField('fecha_resumen').setValue(lista.fecha_resumen);
          this.getField('rejilla_resumen').toggle(true);
          this.gridOptionsResumen['rowData'] = [];
          this.gridOptionsResumen['rowData'] = lista.array_resumen;
          this.getField('rejilla_resumen').initData(this.gridOptionsResumen,configCell);

          //retenciones
          if(lista.array_retenciones.length>0){
                this.getField('rejilla_retenciones').toggle(true);
                this.gridOptionsRetenciones['rowData'] = [];
                this.gridOptionsRetenciones['rowData'] = lista.array_retenciones;
                this.getField('rejilla_retenciones').initData(this.gridOptionsRetenciones,configCell);

                this.getField('valor_fletes_resumen').setValue(lista.valor_fletes_resumen);
          }else{
            this.getField('label_titulo2').setVisible(false);
          }

           //retenciones fletes
            if(lista.array_retenciones_fletes.length>0){
                this.getField('label_ff').setVisible(true);
                this.getField('rejilla_retenciones_fletes').toggle(true);
                this.gridOptionsRetencionesFletes['rowData'] = [];
                this.gridOptionsRetencionesFletes['rowData'] = lista.array_retenciones_fletes;
                this.getField('rejilla_retenciones_fletes').initData(this.gridOptionsRetencionesFletes,configCell);
            }else{
                this.getField('label_ff').setVisible(false);
            }

            this.getField('valor_aproximacion_resumen').setValue(lista.valor_aproximacion_resumen);
            this.getField('valor_total_resumen').setValue(lista.valor_total_resumen);
            this.getField('valor_numero_facturas_resumen').setValue(lista.valor_numero_facturas_resumen);
 
          
    }else if(data.estado_p === 404){
        //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        this.mostrarMensajeNoHayDatos();
    }
    else{
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
    } 
}


  sucessTraerDatos(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "sucursal": this.lista.sucursal,
                    "direccion": this.lista.direccion_xml,
                    "telefono": this.lista.telefono_xml,
                    "nit": this.lista.nit_xml,
                    "ciudad": this.lista.ciudad_xml,
                    "devolucion_nro": this.lista.devolucion_nro,
                    "compra_nro": this.lista.compra_nro,
                    "fecha_compra": this.lista.fecha_compra,
                    "fecha_vence": this.lista.fecha_vence,
                    "orden_compra_no": this.lista.orden_compra_no,
                    "usuario": this.lista.usuario,
                    "bodega": this.lista.bodega,
                };


            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);

            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);


            this.getField('proveedor_result').setValue(this.lista.proveedor);
            this.getField('direcccion').setValue(this.lista.direccion_nit);
            this.getField('telefono').setValue(this.lista.telefono_nit);
            this.getField('ciudad').setValue(this.lista.ciudad_nit);

            this.getField('retencion_result').setValue(this.currencyFormatterGeneral(this.lista.retencion));
            this.getField('valor_exento_result').setValue(this.currencyFormatterGeneral(this.lista.valor_exento));
            this.getField('reteiva_result').setValue(this.currencyFormatterGeneral(this.lista.reteiva));
            this.getField('valor_grabado_result').setValue(this.currencyFormatterGeneral(this.lista.grabado));

            this.getField('reteica_result').setValue(this.currencyFormatterGeneral(this.lista.retencionica));
            this.getField('cree_result').setValue(this.currencyFormatterGeneral(this.lista.cree));
            this.getField('aproximacion_result').setValue(this.currencyFormatterGeneral(this.lista.aproximacion));
            this.getField('iva_result').setValue(this.currencyFormatterGeneral(this.lista.total_iva));
            this.getField('fletes_result').setValue(this.currencyFormatterGeneral(this.lista.fletes));
            this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total));
            
            this.getField('fecha1').setValue(this.lista.fecha1);
            this.getField('desc1').setValue(this.currencyFormatterGeneral(this.lista.desc1));
            this.getField('fecha2').setValue(this.lista.fecha2);
            this.getField('desc2').setValue(this.currencyFormatterGeneral(this.lista.desc2));
            this.getField('fecha3').setValue(this.lista.fecha3);
            this.getField('desc3').setValue(this.currencyFormatterGeneral(this.lista.desc3));
            
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }


    enviarCorreoTerceros(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos = {datos: {}};
        datos.datos= this.retornarBodyDatos();

        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
  
  successEnviarCorreo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
      }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
      }else{
          this.mostrarMensajeEmailFallo();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      }
  }

  retornarBodyDatos(){
    let datos2= {};
    if(this.boton==='compras'){
        datos2= {
            factura_numero:this.xnumero,
            xfecha:this.xfecha,
            xsucursal:this.xsucursal,
            nit: this.xnit,
            sede: this.xsede,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual,
        };
    }else if(this.boton==='articulos'){
        datos2= {
            fecha_inicial:this.getField('consultar_desde').getValue(),
            fecha_final:this.getField('consultar_hasta').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            email: this.getField('email').getValue(),
            sucursal_usuario: this.sucursal_ingreso,
            operacion_archivo: this.operacion_actual,
        };
    }else if(this.boton==='resumen'){
        datos2= {
            fecha:this.xfecha,
            codigo_sucursal:this.xsucursal,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_usuario: this.sucursal_ingreso,
            operacion_archivo: this.operacion_actual,
        };
    }else if(this.boton==='documentos'){
        datos2= {
            fecha_inicial:this.getField('consultar_desde').getValue(),
            fecha_final:this.getField('consultar_hasta').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_usuario: this.sucursal_ingreso,
            operacion_archivo: this.operacion_actual,
        };

        if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
            datos2.nit=this.getField('nit').getValue();
            datos2.sede=this.getField('sede').getValue();
        }
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
            datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
            datos2.numero=this.getField('numero').getValue();
    }
    else if(this.boton==='tipos'){
        datos2= {
            fecha_inicial:this.getField('consultar_desde').getValue(),
            fecha_final:this.getField('consultar_hasta').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_usuario: this.sucursal_ingreso,
            operacion_archivo: this.operacion_actual,
        };

        if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
            datos2.nit=this.getField('nit').getValue();
            datos2.sede=this.getField('sede').getValue();
        }
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
            datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
            datos2.numero=this.getField('numero').getValue();
    }else if(this.boton==='listado'){
        datos2= {
            fecha_inicial:this.getField('consultar_desde').getValue(),
            fecha_final:this.getField('consultar_hasta').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_usuario: this.sucursal_ingreso,
            operacion_archivo: this.operacion_actual,
        };
    }else if(this.boton==='resumen_general'){
        datos2= {
            fecha_inicial:this.getField('consultar_desde').getValue(),
            fecha_final:this.getField('consultar_hasta').getValue(),
            campo:this.getField('select_clasificaciones').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_usuario: this.sucursal_ingreso,
            operacion_archivo: this.operacion_actual,
        };
        if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
            datos2.nit=this.getField('nit').getValue();
            datos2.sede=this.getField('sede').getValue();
        }
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
            datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
            datos2.numero=this.getField('numero').getValue();
    }
    return datos2;

  }

  generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2=this.retornarBodyDatos();
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+this.archivo_operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2=this.retornarBodyDatos();
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+this.archivo_operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarTxt()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
                xsucursal: this.xsucursal,
                xfecha: this.xfecha,
                xnumero: this.xnumero,
                nit: this.xnit,
                sede: this.xsede,
                sucursal_ingreso: this.sucursal_ingreso,
            };
            let op='archivoitemstxt';
            this.nombre_archivo='detalle_devcompra_'+this.xnumero;

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabardevolucion","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarResumen()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
              xsucursal: this.xsucursal,
              xfecha: this.xfecha,
              sucursal_ingreso: this.sucursal_ingreso,
            };
            let op='archivoresumentxt';
            this.nombre_archivo='resumen_compra_'+this.xfecha;

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-consultacomprasconfactor","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarDocumento()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
              consultar_desde: this.getField('consultar_desde').getValue(),
              consultar_hasta: this.getField('consultar_hasta').getValue(),
              sucursal_ingreso: this.sucursal_ingreso,
            };

            if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
                datos2.nit=this.getField('nit').getValue();
                datos2.sede=this.getField('sede').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
                datos2.numero=this.getField('numero').getValue();


            let op='archivodocumentotxt';
            this.nombre_archivo='dev_compras_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-consultacomprasconfactor","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarPorTipoProducto()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
                consultar_desde:this.getField('consultar_desde').getValue(),
                consultar_hasta:this.getField('consultar_hasta').getValue(),
                sucursal_ingreso:this.sucursal_ingreso,
            };
            if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
                datos2.nit=this.getField('nit').getValue();
                datos2.sede=this.getField('sede').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
                datos2.numero=this.getField('numero').getValue();


            let op='archivoportipoproductotxt';
            this.nombre_archivo='dev_compras_x_tipo_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-consultacomprasconfactor","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }


   
}


FormJaivana.addController("comp-consultacomprasconfactor", CustomConsultaComprasConFactor);
export default CustomConsultaComprasConFactor;