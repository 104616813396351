import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @description: CustomPoblacionesGeograficos
 * @author: Mario A. Sepúlveda R.
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomPoblacionesGeograficos extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm = this.initForm.bind(this);
       this.arrayObjetos=new Map();
       this.arrayCampos=new Map();
       this.bHayErrores=false;
       this.gridOptionsPoblaciones = Object.assign({},this.gridOptions);


       this.consultarPoblacion=this.consultarPoblacion.bind(this);
       this.habilitarConsultar=this.habilitarConsultar.bind(this);
       this.successTraerDatos=this.successTraerDatos.bind(this);
       this.setButtonAccion=this.setButtonAccion.bind(this);
       this.habilitarCamposAgregar=this.habilitarCamposAgregar.bind(this);
       this.ocultarCampos=this.ocultarCampos.bind(this);
       this.limpiarCampos=this.limpiarCampos.bind(this);
       this.validarGuardar=this.validarGuardar.bind(this);
       this.successNoExistePoblacion=this.successNoExistePoblacion.bind(this);
       this.validarEliminar=this.validarEliminar.bind(this);
       this.actualizarPrincipal=this.actualizarPrincipal.bind(this);
       this.successValidarEliminar=this.successValidarEliminar.bind(this);

       this.successEliminarPoblacion=this.successEliminarPoblacion.bind(this);
       this.confirmEliminar=this.confirmEliminar.bind(this);
       this.succesGuardarPoblaciones=this.succesGuardarPoblaciones.bind(this);
       this.successActualizar=this.successActualizar.bind(this);

       
       this.idEliminar = 0;

    }

    initForm() {

       console.log('Formulario CustomPoblacionesGeograficos,  @version: jdesk_1.01.0001, @author:Mario A. Sepúlveda R, @updateBy Jose Albeiro Marin Bernal')


       this.arrayCampos.set(1,'id');
       this.arrayCampos.set(2,'codigo_ciudad');
       this.arrayCampos.set(3,'nombre_ciudad');
       this.arrayCampos.set(4,'consultar');
       this.arrayCampos.set(5,'rejilla');
       this.arrayCampos.set(6,'codigo_poblacion');
       this.arrayCampos.set(7,'nombre_poblacion');
       this.arrayCampos.set(8,'guardar');
       this.arrayCampos.set(9,'cancelar');
       this.arrayCampos.set(10,'ciudad_id');
       this.arrayCampos.set(11,'principal');

       this.arrayObjetos.set(1,'id');
       this.arrayObjetos.set(2,'codigo_ciudad');
       this.arrayObjetos.set(3,'nombre_ciudad');
       this.arrayObjetos.set(4,'consultar');
       this.arrayObjetos.set(5,'rejilla');
       this.arrayObjetos.set(6,'codigo_poblacion');
       this.arrayObjetos.set(7,'nombre_poblacion');
       this.arrayObjetos.set(8,'guardar');
       this.arrayObjetos.set(9,'cancelar');
       this.arrayObjetos.set(10,'ciudad_id');
       this.arrayObjetos.set(11,'principal');


       this.establecerPropiedades('4','disable','true');
       this.establecerPropiedades('2','visible','true');
       this.establecerPropiedades('3','disable','false');

       this.getField('ciudad_id').setOnChange(this.habilitarConsultar);
       this.getField('consultar').setClick(this.consultarPoblacion);
       this.getField('cancelar').setClick(()=>{ 
       this.establecerPropiedades('5','foco','rejilla');
        
        this.ocultarCampos();
       });
       this.getField('guardar').setClick(this.validarGuardar);
       this.getField('codigo_poblacion').setOnChange(()=>{
            this.establecerPropiedades('8','disable','false');
       });


    }

    habilitarConsultar() {
        let pais_id = this.getField('ciudad_id').getValue();
        if(pais_id !== '' && pais_id !== undefined && pais_id !== null){
            this.establecerPropiedades('4','disable','false');
        } else {
            this.establecerPropiedades('4','disable','true');
            this.ocultarCampos();
            this.getField(this.arrayCampos.get(5)).toggle(false);

        }
    }


    //*** definir los botones y los success
    consultarPoblacion() {
        
        let ciudad_id = this.getField('ciudad_id').getValue();

        let datos={ datos: {
            ciudad_id: ciudad_id
        } };
        this.generalFormatPmv = { tipo_servicio: 'maes-poblaciones', operacion: '20_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDatos(data) {
        this.establecerPropiedades('4','disable','true');

        if(data.estado_p === 200) {

            let configCell = new Map();

            configCell.set('accion', { cellRenderer: this.setButtonAccion, width: 415, sortable: false, filter: false, field: 'accion' });

            this.getField(this.arrayCampos.get(5)).toggle(true);      
            this.gridOptionsPoblaciones['rowData'] = data.data;
            this.getField(this.arrayCampos.get(5)).initData(this.gridOptionsPoblaciones,configCell);

        } else {
            this.getField(this.arrayCampos.get(5)).toggle(false); 
            this.habilitarCamposAgregar();

        }
    }

    setButtonAccion(props) {
        let fragment = document.createDocumentFragment();
        let id = props.data.id;
        let button1 = document.createElement("input");
        button1.onclick = () => this.confirmEliminar(props);
        button1.setAttribute("id", 'button_delete_' + id);
        //button1.setAttribute("class", "buttonStyle");
        button1.setAttribute("type", "button");
        //button.classList.add(hover);
        button1.setAttribute("value", "Eliminar");
        button1.setAttribute("class","buttonStyle2");

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        button2.onclick = () => this.habilitarCamposAgregar(props);
        button2.setAttribute("id", 'button_add_' + id);
        //button2.setAttribute("class", "buttonStyle");
        button2.setAttribute("type", "button");
        //button.classList.add(hover);
        button2.setAttribute("value", "Agregar");
        button2.setAttribute("class","buttonStyle2");

        fragment.appendChild(button1);
        fragment.appendChild(button2);


        //tercer boton 
        let button3 = document.createElement("input");
        button3.onclick = () => this.actualizarPrincipal(props);
        button3.setAttribute("id", 'button_add_' + id);
        //button2.setAttribute("class", "buttonStyle");
        button3.setAttribute("type", "button");
        //button.classList.add(hover);
        button3.setAttribute("value", "Cambiar a principal");
        button3.setAttribute("class", "buttonStyle5");

        fragment.appendChild(button1);
        fragment.appendChild(button2);
        fragment.appendChild(button3);
        return this.createElementJaivana(fragment);

    }

    habilitarCamposAgregar(){
        this.establecerPropiedades('6,7,8,9','visible','true');
        //this.getField(this.arrayCampos.get(6)).input.focus();
        this.establecerPropiedades('6','foco');
    }

    validarGuardar(){
        let codigo = this.getField(this.arrayCampos.get(6)).getValue();
        let nombre = this.getField(this.arrayCampos.get(7)).getValue();
        if(codigo !== '' && codigo !== undefined && codigo !== null  && this.getField(this.arrayCampos.get(6)).valid() && this.getField(this.arrayCampos.get(7)).valid() &&
            nombre !== '' && nombre !== undefined && nombre !== null){
        
            let datos={ datos: {
                codigo_poblacion: codigo
            } };
            this.generalFormatPmv = { tipo_servicio: 'maes-poblaciones', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successNoExistePoblacion,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            });
        }
    }


    successNoExistePoblacion(data){
        if((data.estado_p === 404) && (data.data.length === 0)){
            let ciudad_id = this.getField(this.arrayCampos.get(10)).getValue();

            let datos={ datos: {
                ciudad_id: ciudad_id,
                codigo_poblacion:this.getField(this.arrayCampos.get(6)).getValue(),
                nombre_poblacion:this.getField(this.arrayCampos.get(7)).getValue(),
                principal:'N'
            } };

            this.generalFormatPmv = { tipo_servicio: 'maes-poblaciones', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.succesGuardarPoblaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage:false
            });

        } else if (data.estado_p === 200){
            this.alertGeneral.toggle(true,'Los campos Código Población ya existen, no se permite duplicar',"error");
            this.establecerPropiedades('6','foco');
            this.establecerPropiedades('8','disable','true');


        }
    }

    succesGuardarPoblaciones(data){
        if(data.estado_p === 200){
            this.consultarPoblacion();
            this.limpiarCampos();
            this.establecerPropiedades('6,7,8,9','visible','false');
            this.establecerPropiedades('5','foco','rejilla');
        }
    }


    actualizarPrincipal(props){
        let idNuevo = props.data.id;
    
        if(props.data.principal === 'N'){

        let datos={ datos: {
            id:idNuevo,
            principal:'S'
        } };
        
        this.generalFormatPmv = { tipo_servicio: 'maes-poblaciones', operacion: '6', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successActualizar,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage:false
        });
        } else {
            this.alertGeneral.toggle(true,'Esta población ya está configurada como principal','error');
        }
    }

    successActualizar(data){
        if(data.estado_p === 200){
            this.consultarPoblacion();
        }
    }

    confirmEliminar(props){

        this.idEliminar = props.data.id;//this.getField(this.arrayCampos.get(1)).getValue();

        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.validarEliminar);
        this.getField('confirmModalCustom').toggle(true);
    }

    validarEliminar(){
        this.limpiarCampos();
        this.getField('confirmModalCustom').toggle(false);

        if(this.idEliminar !== '' && this.idEliminar !== undefined && this.idEliminar !== null){
            let datos={ datos: {
                id:this.idEliminar
            } };

            this.generalFormatPmv = { tipo_servicio: 'maes-poblaciones', operacion: '20_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successValidarEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            });

        }
    }

    successValidarEliminar(data) {
        if((data.estado_p === 200) && (data.data[0].cuantos === 0)){
            let datos={ datos: {
                id:this.idEliminar
            } };
            this.generalFormatPmv = { tipo_servicio: 'maes-poblaciones', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminarPoblacion,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage:false
            });

        }else if(data.data[0].cuantos > 0){
            this.alertGeneral.toggle(true,'Esta población existe en transportadoras_poblaciones y/o items_planilla, No se permite Eliminar',"error");
        }
    }

    successEliminarPoblacion(data){
        this.idEliminar = 0;

        if(data.estado_p === 200){
            this.consultarPoblacion();
        }
    }

    ocultarCampos(){
        this.establecerPropiedades('6,7,8,9','visible','false');
        this.establecerPropiedades('4','disable','true');
        this.limpiarCampos();
    }

    limpiarCampos(){
        this.establecerPropiedades('6,7','value','');
        this.getField(this.arrayCampos.get(6)).setError(false,'');
        this.getField(this.arrayCampos.get(7)).setError(false,'');
    }


    //*** fin  los botones y los success



    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                //console.log(element);
                //console.log(this.arrayObjetos.get(parseInt(element)));
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }


   cargarCampos(data) 
   {
          this.getField('id').setValue(data.data[0].id);
          this.getField('codigo_ciudad').setValue(data.data[0].codigo_ciudad);
          this.getField('nombre_ciudad').setValue(data.data[0].nombre_ciudad);
          this.getField('consultar').setValue(data.data[0].consultar);
          this.getField('rejilla').setValue(data.data[0].rejilla);
          this.getField('codigo_poblacion').setValue(data.data[0].codigo_poblacion);
          this.getField('nombre_poblacion').setValue(data.data[0].nombre_poblacion);
          this.getField('guardar').setValue(data.data[0].guardar);
          this.getField('cancelar').setValue(data.data[0].cancelar);
          this.getField('ciudad_id').setValue(data.data[0].ciudad_id);
          this.getField('principal').setValue(data.data[0].principal);

   }

}
FormJaivana.addController('maes-poblaciones',CustomPoblacionesGeograficos);
export default CustomPoblacionesGeograficos;


