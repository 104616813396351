import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAjustesInventario
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 * @UpdateBy Sebastian Rios E.
 **/
class CustomAjustesInventario extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                         = this.initForm.bind(this);
        this.gridOptionsComponentes           = Object.assign({}, this.gridOptions);
        this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
        this.gridOptionsComponentes1          = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1['onSelectionChanged']           = this.onSelectionChanged;

        this.gridOptionsBodega                = Object.assign({}, this.gridOptions);
        this.camposvisiblesgrabarajuste       = this.camposvisiblesgrabarajuste.bind(this);
        this.validartablaubicaciones          = this.validartablaubicaciones.bind(this);
        this.successAjuste                    = this.successAjuste.bind(this);
        this.pantalla                         ='';
        this.ubicacionbodegas                 = this.ubicacionbodegas.bind(this);
        this.successAjusteubicacion           = this.successAjusteubicacion.bind(this);
        this.validarconcepto                  = this.validarconcepto.bind(this);
        this.successValidarConcepto           = this.successValidarConcepto.bind(this);
        this.creartemporalAjustes             = this.creartemporalAjustes.bind(this);
        this.successCrearTemporal             = this.successCrearTemporal.bind(this);
        this.successMostrarTabla              = this.successMostrarTabla.bind(this);
        this.consultar                        = this.consultar.bind(this);
        this.successConsultar                 = this.successConsultar.bind(this);
        this.agregar_articulo                 = this.agregar_articulo.bind(this);
        this.successWms                       = this.successWms.bind(this);
        this.ubicaiones                       = this.ubicaiones.bind(this);
        this.successUbicacionesWms            = this.successUbicacionesWms.bind(this);
        this.successBodegaWms                 = this.successBodegaWms.bind(this);
        this.successZonaWms                   = this.successZonaWms.bind(this);
        this.cargarRack                       = this.cargarRack.bind(this);
        this.successRackwms                   = this.successRackwms.bind(this);
        this.cargarColumna                    = this.cargarColumna.bind(this);
        this.successColumnawms                = this.successColumnawms.bind(this);
        this.tipoubicacion                    = this.tipoubicacion.bind(this);
        this.successTipoUbicacion             = this.successTipoUbicacion.bind(this);
        this.cerrarModalUbicaciones           = this.cerrarModalUbicaciones.bind(this);
        this.saldosubicacion                  = this.saldosubicacion.bind(this);
        this.successSaldosUbicacion           = this.successSaldosUbicacion.bind(this);
        this.traersaldoBodegas                = this.traersaldoBodegas.bind(this);
        this.successSaldosBodegas             = this.successSaldosBodegas.bind(this);
        this.actualizarcosto                  = this.actualizarcosto.bind(this);
        this.successActualizarCosto           = this.successActualizarCosto.bind(this);
        this.calcularvalores                  = this.calcularvalores.bind(this);
        this.grabarArticulo                   = this.grabarArticulo.bind(this); 
        this.successGrabarItem                = this.successGrabarItem.bind(this);
        this.limpiarArticulos                 = this.limpiarArticulos.bind(this);
        this.refrescar                        = this.refrescar.bind(this);
        this.successActualizarTotales         = this.successActualizarTotales.bind(this);
        this.setButtonDelete                  = this.setButtonDelete.bind(this);
        this.eliminar                         = this.eliminar.bind(this);
        this.confirmDelete                    = this.confirmDelete.bind(this);
        this.successEliminar                  = this.successEliminar.bind(this);
        this.cantidadtablaChange              = this.cantidadtablaChange.bind(this);
        this.successActualizarCantidad        = this.successActualizarCantidad.bind(this);
        this.traercodigo                      = this.traercodigo.bind(this);
        this.successTraerDatos                = this.successTraerDatos.bind(this);
        this.cantidad ="";
        this.grabarAjuste                     = this.grabarAjuste.bind(this);
        this.successTraerconsecutivo          = this.successTraerconsecutivo.bind(this);
        this.validartablatransaccion          = this.validartablatransaccion.bind(this);
        this.successValidarTransaccion        = this.successValidarTransaccion.bind(this);
        this.llenarselectTransacciones        = this.llenarselectTransacciones.bind(this);
        this.successllenarTransaccion         = this.successllenarTransaccion.bind(this);
        this.mostrarcampos                    = this.mostrarcampos.bind(this);
        this.cerrarModalTransacciones         = this.cerrarModalTransacciones.bind(this);
        this.successTraerconcepto             = this.successTraerconcepto.bind(this);
        this.aceptartransaccion               = this.aceptartransaccion.bind(this);
        this.successtraerdoc                  = this.successtraerdoc.bind(this);
        this.traeritem                        = this.traeritem.bind(this);
        this.validacionesAgregar              = this.validacionesAgregar.bind(this);
        this.validacionesAgregar2             = this.validacionesAgregar2.bind(this);
        this.sucessValidaciones               = this.sucessValidaciones.bind(this);
        this.limpiarTodo                      = this.limpiarTodo.bind(this);
        this.selectedRows = [];

        //listado ajustes -- Ander
        this.listadoAjuste                    = this.listadoAjuste.bind(this);
        this.camposVisiblesListadoAjuste                    = this.camposVisiblesListadoAjuste.bind(this);
        this.abrirImprimir  = this.abrirImprimir.bind(this);
        this.eliminarTemporal  = this.eliminarTemporal.bind(this);
        this.sucessEliminarTemporal  = this.sucessEliminarTemporal.bind(this);
        this.mostrarMensajeEmailEnviado  = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo  = this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeNoHayDatos  = this.mostrarMensajeNoHayDatos.bind(this);
        this.generarPdfTabla3  = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3  = this.generarExcelTabla3.bind(this);
        this.enviarCorreoTerceros  = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo  = this.successEnviarCorreo.bind(this);
        this.isJson  = this.isJson.bind(this);
        this.validarfechas  = this.validarfechas.bind(this);
        this.traerDias  = this.traerDias.bind(this);
        this.successTraerDias  = this.successTraerDias.bind(this);
        this.procesar  = this.procesar.bind(this);
        this.validarCamposLista  = this.validarCamposLista.bind(this);
        this.generarModalLista  = this.generarModalLista.bind(this);
        this.sucessTraerDatos  = this.sucessTraerDatos.bind(this);
        this.habilitarCorreo  = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo  = this.deshabilitarCorreo.bind(this);
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                               = this.formatNumberSaldo.bind(this);
        this.limpiarcamposGrabar                               = this.limpiarcamposGrabar.bind(this);
        this.mostrarMensajeAdicional  = this.mostrarMensajeAdicional.bind(this);
        this.cargarTabla  = this.cargarTabla.bind(this);
        this.traerPropiedades  = this.traerPropiedades.bind(this);
        this.sucessTraerPropiedades  = this.sucessTraerPropiedades.bind(this);


        this.dias_maximo_lista='';
        this.entro_lista=false;
        this.iva_articulo='N';
        this.pide_ubicacion=false;
        this.prioridad_barras='N';
        
        this.operacion_actual='';
        this.fecha_hoy='';
        this.fecha_inicio='';
        this.ch_todos='T';
        this.ch_todos_descripcion='Todos';
        this.boton_selecionado='';
        this.nombre_archivo='';
        this.sucursal_ingreso='';
        this.onSelectionChangedInicio                                  = this.onSelectionChangedInicio.bind(this);

        this.gridOptionsComponentesItems           = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChangedInicio;

        this.gridOptionsComponentesDetallado          = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesDetallado['onSelectionChanged']           = this.onSelectionChangedInicio;

        this.gridOptionsComponentesArticulo          = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesArticulo['onSelectionChanged']           = this.onSelectionChangedInicio;

        this.gridOptionsComponentesArtxGrupo          = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesArtxGrupo['onSelectionChanged']           = this.onSelectionChangedInicio;
        //listado ajustes final

        ////////////////////////Aquí empieza Consulta de Ajustes/////////////////////////
        // Variables de tablas:
        this.gridOptionsFacturacAjustes                 = Object.assign({},this.gridOptions);
        this.onSelectionChangedConsultar                                  = this.onSelectionChangedConsultar.bind(this);
        this.gridOptionsFacturacAjustes['onSelectionChanged']           = this.onSelectionChangedConsultar;
        
        // Variables Globales:
        this.operacion_actualAjustesInventario          = '';
        this.filaSeleccionadaConsultaAjustes            = [];
        
        // Funciones:
        this.initConsultaAjustes                        = this.initConsultaAjustes.bind(this);
        this.camposVisiblesConsultaAjustes              = this.camposVisiblesConsultaAjustes.bind(this);
        this.limpiarCamposConsultaAjustes               = this.limpiarCamposConsultaAjustes.bind(this);
        this.btnConsultarAjustesInventario              = this.btnConsultarAjustesInventario.bind(this);
        this.successBtnConsultarAjustesInventario       = this.successBtnConsultarAjustesInventario.bind(this);
        this.validarfechasConsulta                       = this.validarfechasConsulta.bind(this);
        this.gridOptionsComponentesConsultarModal          = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentesConsultarModal['onSelectionChanged']           = this.onSelectionChangedInicio;
        this.llenarSelectBodegas              = this.llenarSelectBodegas.bind(this);
        this.sucessLlenarSelectBodegas              = this.sucessLlenarSelectBodegas.bind(this);
        /////////////////////////Aquí termina Consulta de Ajustes/////////////////////////

        // AJustar referencia
        this.camposajustesReferencia                      = this.camposajustesReferencia.bind(this);
        this.ajustesReferencia                            = this.ajustesReferencia.bind(this);
        this.habilitarCampos                            = this.habilitarCampos.bind(this);
        this.deshabilitarCampos                            = this.deshabilitarCampos.bind(this);
        this.borrarDatos                            = this.borrarDatos.bind(this);
        this.borrarErroresCampos                            = this.borrarErroresCampos.bind(this);
        this.validarAjustarRefe                            = this.validarAjustarRefe.bind(this);
        this.realizarAjuste                            = this.realizarAjuste.bind(this);
        this.sucessAjustar                            = this.sucessAjustar.bind(this);
        this.lasucursal='';
        
        //////////////////cruce ajusts/////////////////////////////////////////////////////////
        this.cruceAjuste                    = this.cruceAjuste.bind(this);
        this.gridOptionsComponentes2        = Object.assign({},this.gridOptions);
        this.gridOptionsCruces              = Object.assign({},this.gridOptions);
        this.traerAjuste                    = this.traerAjuste.bind(this);
        this.traerItemAjuste                = this.traerItemAjuste.bind(this);
        this.successTraerAjuste             = this.successTraerAjuste.bind(this);
        this.successItem                    = this.successItem.bind(this);
        this.items                          ={};
        this.modificarItem                  = this.modificarItem.bind(this);
        this.grabarAjustecruce              = this.grabarAjustecruce.bind(this);
        this.successGrabarAjuste            = this.successGrabarAjuste.bind(this);
        this.limpiarCruce                   = this.limpiarCruce.bind(this);
        this.successCambiarEstado           = this.successCambiarEstado.bind(this);
        this.cambiarestado                  = this.cambiarestado.bind(this);
        this.selectBodega                   = this.selectBodega.bind(this);
        this.successSelectBodega            = this.successSelectBodega.bind(this);
        this.primerRegistroBodegas          = "";// Se crea esta variable, ya que en la tarea de corrección "JQJD-1480" se tuvo que cambiar la funcionalidad del campo select "Bodega"
        this.limpiarcampos                  = this.limpiarcampos.bind(this);
        this.validarCodigo                  = this.validarCodigo.bind(this);
        this.sucessValidarCodigo                  = this.sucessValidarCodigo.bind(this);
        this.traerInfoArticulo                  = this.traerInfoArticulo.bind(this);
        this.sucessTraerArticulo                  = this.sucessTraerArticulo.bind(this);
        this.changeKeyUpCustom                  = this.changeKeyUpCustom.bind(this);

        //saber si esta limpiando la tabla
        this.limpiando_tabla=false;
        this.idsucursal=0;
        this.cambiar_prioridad=false;
        this.operacion_seleccionada='';
        this.costo_guardado=0;
    }

    initForm() {
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.getField('operacion_form').setValue('Grabación de Ajustes');
            this.grabacionAjustes();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.getField('operacion_form').setValue('Consulta de Ajustes');
            this.initConsultaAjustes();
        }else if(this.props.data[0].opcion_sub_seccion === 3){
            this.entro_lista=true;
            this.getField('operacion_form').setValue('Listado de Ajustes');
            this.listadoAjuste();
            this.boton_selecionado='';
        }else if(this.props.data[0].opcion_sub_seccion === 4){
            this.getField('operacion_form').setValue('Cruce de Ajustes');
            this.cruceAjuste();
            this.boton_selecionado='';
        }else if(this.props.data[0].opcion_sub_seccion === 5){
            this.getField('operacion_form').setValue('Ajustes de Referencias');
            this.ajustesReferencia();
            this.boton_selecionado='';
        }    

        this.traerDias();

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });
    }

    onSelectionChanged(){
        //nothing
    }

    //correciones ander (consulta de ajustes)
    llenarSelectBodegas(valor){
        let op='';
        if(valor===0){
            op='cargarbodegas';
        }else{
            op='cargarbodegas2';
        }
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario
            }};
            
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessLlenarSelectBodegas,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });

    }

    sucessLlenarSelectBodegas(data){
        let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
        if(data.estado_p===200){
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.nombre;
                dataOp['text'] = item.nombre;
                dataOp['campos_cambian'] = '';
                opciones.push(dataOp);
            });
        }else {
            if(data.estado_p===404) {

            }else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
        this.getField('select_bodega1').setOptions(opciones);
    }

    //aqui empieza operacion 5
    ajustesReferencia(){
        console.log("Formulario CustomAjustesInventario,  @version: jdesk_1.01.0002, @author: Anderson acevedo briñez, @UpdateBy: Sebastian Rios E.");
        this.camposajustesReferencia();
        this.deshabilitarCampos();
        this.getField('modal_imprimir').setCloseButton(()=>{
            this.borrarDatos();
            // this.deshabilitarCampos(); // Se comenta ya que en la tarea de corrección "JQJD-1480" se tuvo que cambiar la funcionalidad del campo select "Bodega"
            this.getField('select_bodega_ajuste').setValue(this.primerRegistroBodegas);
            this.getField('value2').setValue(this.primerRegistroBodegas);// Se setea el primer registro que trae el servicio que lista el select de bodega, ya que en la tarea de corrección "JQJD-1480" se tuvo que cambiar la funcionalidad del campo select "Bodega"
            this.boton_selecionado='';
        });
        /*this.getField('select_bodega_ajuste').setOnChange(()=>{
            if(this.getField('select_bodega_ajuste').getValue()!==' ' && this.getField('select_bodega_ajuste').getValue()!==''){
                this.habilitarCampos();
            }else{
                this.deshabilitarCampos();
            }
            this.borrarDatos();
        });*/
        // Se replica este setOnChange para el campo "value2" y se comenta el anteriror, ya que como se  cambió la forma en como se dibuja el select "Bodega", cambia la funcionalidad de dicho campo. 
        this.getField('value2').setOnChange(()=>{
            if(this.getField('select_bodega_ajuste').getValue()!==' ' && this.getField('select_bodega_ajuste').getValue()!==''){
                this.habilitarCampos();
            }else{
                this.deshabilitarCampos();
            }
            this.borrarDatos();
        });
        this.getField('bt_ajuste').setClick(this.validarAjustarRefe);
        this.getField('generar_archivo').setClick(()=>this.procesar());
        this.nombre_archivo='ajustes';
        this.selectBodega();

    }

    selectBodega(){
        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarbodegas2', operacion_tipo: 'consulta'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: this.datosAgregar,
                success: this.successSelectBodega,
                error: this.error_,
                general: this.generalFormatPmv,
            }
        );
    }

    successSelectBodega(data){
        // console.log('data successSelectBodega:\n',data);
        if (data.estado_p === 200 && data.data.length >= 1) {
            let opciones = [];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.codigo_bodega;
                dataOp['text'] = item.nombre;
                dataOp['campos_cambian'] = { value2: item.codigo_bodega };
                opciones.push(dataOp);
            });
            this.getField('select_bodega_ajuste').setOptions(opciones);
            // console.log('codigo_bodega:\n',data.data[0].codigo_bodega);
            this.primerRegistroBodegas = data.data[0].codigo_bodega;
            this.getField('value2').setValue(this.primerRegistroBodegas);
        }
    }
    
    validarAjustarRefe(){
        if(this.getField('select_bodega_ajuste').valid() &&
        this.getField('codigo_articulo_origen').valid() &&
        this.getField('nombre_articulo_origen').valid() &&
        this.getField('saldo_origen').valid() &&
        this.getField('cantidad_origen').valid() &&
        this.getField('codigo_articulo_destino').valid() &&
        this.getField('nombre_articulo_destino').valid() &&
        this.getField('saldo_destino').valid() &&
        this.getField('cantidad_destino').valid()
        ){
            if(this.getField('codigo_articulo_origen').getValue()===this.getField('codigo_articulo_destino').getValue()){
                this.alertGeneral.toggle(true, 'Los artículos origen y destino son iguales.', 'error')
            }else{
                if(Number(this.getField('cantidad_origen').getValue())<=0.0){
                    this.alertGeneral.toggle(true, 'Cantidad origen inválida.', 'error')
                }else{
                    if(Number(this.getField('cantidad_destino').getValue())<=0.0){
                        this.alertGeneral.toggle(true, 'Cantidad destino inválida.', 'error')
                    }else{
                        this.realizarAjuste();
                    }
                }
            }
        }
    }

    realizarAjuste(){
        this.mostrarMensajeGuardando();
        let datos = { datos: {
            sucursal_ingreso:this.sucursal_ingreso,
            select_bodega_ajuste:this.getField('select_bodega_ajuste').getValue(),
            codigo_articulo_origen:this.getField('codigo_articulo_origen').getValue(),
            cantidad_origen:this.getField('cantidad_origen').getValue(),
            codigo_articulo_destino:this.getField('codigo_articulo_destino').getValue(),
            cantidad_destino:this.getField('cantidad_destino').getValue(),

        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'hacerajuste', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.sucessAjustar,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    sucessAjustar(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {    
            this.boton_selecionado='hacer_ajuste';
            this.getField("numero2").setValue(data.data.consecutivo);
            this.getField("concepto_cod").setValue(data.data.concepto_ajuste);
            this.lasucursal=data.data.lasucursal;
            this.abrirImprimir();
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    habilitarCampos(){
        this.getField('codigo_articulo_origen').setDisabled(false);
        this.getField('cantidad_origen').setDisabled(false);
        this.getField('codigo_articulo_destino').setDisabled(false);
        this.getField('cantidad_destino').setDisabled(false);
        this.getField('bt_ajuste').setDisabled(false);
    }

    deshabilitarCampos(){
        this.getField('codigo_articulo_origen').setDisabled(true);
        this.getField('cantidad_origen').setDisabled(true);
        this.getField('codigo_articulo_destino').setDisabled(true);
        this.getField('cantidad_destino').setDisabled(true);
        this.getField('bt_ajuste').setDisabled(true);
    }

    borrarDatos(){
        this.getField('codigo_articulo_origen').setValue('');
        this.getField('nombre_articulo_origen').setValue('');
        this.getField('saldo_origen').setValue('');
        this.getField('cantidad_origen').setValue(0);
        this.getField('codigo_articulo_destino').setValue('');
        this.getField('nombre_articulo_destino').setValue('');
        this.getField('saldo_destino').setValue('');
        this.getField('cantidad_destino').setValue(0);

       this.borrarErroresCampos();
    }

    borrarErroresCampos(){
        this.getField('select_bodega_ajuste').setError(false,'');
        this.getField('codigo_articulo_origen').setError(false,'');
        this.getField('nombre_articulo_origen').setError(false,'');
        this.getField('saldo_origen').setError(false,'');
        this.getField('cantidad_origen').setError(false,'');
        this.getField('codigo_articulo_destino').setError(false,'');
        this.getField('nombre_articulo_destino').setError(false,'');
        this.getField('saldo_destino').setError(false,'');
        this.getField('cantidad_destino').setError(false,'');
    }

    camposajustesReferencia(){
        this.getField('select_bodega_ajuste').setVisible(true);
        this.getField('codigo_articulo_origen').setVisible(true);
        this.getField('nombre_articulo_origen').setVisible(true);
        this.getField('saldo_origen').setVisible(true);
        this.getField('cantidad_origen').setVisible(true);
        this.getField('codigo_articulo_destino').setVisible(true);
        this.getField('nombre_articulo_destino').setVisible(true);
        this.getField('saldo_destino').setVisible(true);
        this.getField('cantidad_destino').setVisible(true);
        this.getField('bt_ajuste').setVisible(true);
    }

    //aquí termina operacion 5

    grabacionAjustes(){
        console.log("Formulario CustomAjustesInventario,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.creartemporalAjustes();
        this.validartablaubicaciones();
        this.traerPropiedades();
        //deshabilitar campos
        this.getField('subtotal').setDisabled(true);
        this.getField('valor_iva').setDisabled(true);
        this.getField('impuestos').setDisabled(true);
        this.getField('valor_ajuste').setDisabled(true);

        this.getField('valor_iva1').setDisabled(true);
        this.getField('total_iva').setDisabled(true);
        this.getField('valor_iva_unidad').setDisabled(true);
        this.getField('total_impuestos').setDisabled(true);
        this.getField('valor_unidad').setDisabled(true);
        this.getField('total_item').setDisabled(true);



        //this.camposvisiblesgrabarajuste();
        this.getField("nombre_bodega").setOnChange(()=>{
            this.limpiarcamposGrabar();
            if(this.gridOptionsComponentes1['rowData'].length>1 && this.limpiando_tabla===false){
                this.limpiando_tabla=true;
                this.creartemporalAjustes();
            }
            if(this.getField("nombre_bodega").getValue()!==''){
                this.ubicacionbodegas();
            }
        });
        this.getField("nombre_c").setOnChange(()=>{
            this.limpiarcamposGrabar();
            if(this.gridOptionsComponentes1['rowData'].length>1 && this.limpiando_tabla===false){
                this.limpiando_tabla=true;
                this.creartemporalAjustes();
            }
            if(this.getField("nombre_c").getValue()!==''){
                this.validarconcepto();
            }
        });
        this.getField("nombre").setOnChange(()=>{
            if(this.gridOptionsComponentes1['rowData'].length>1 && this.limpiando_tabla===false){
                this.limpiando_tabla=true;
                this.creartemporalAjustes();
            }
            this.limpiarcamposGrabar();
        });
        this.getField('iniciar').setClick(this.consultar);
        this.getField('agregar_articulo').setClick(this.agregar_articulo);
        this.getField('ubicacionB').setClick(this.ubicaiones);
        this.getField('select_zona').setOnChange(this.cargarRack);
        this.getField('select_rack').setOnChange(this.cargarColumna);
        this.getField('select_columna').setOnChange(this.tipoubicacion);
        this.getField('aceptar').setClick(this.cerrarModalUbicaciones);
        this.getField('cancelar1').setClick(()=>{this.getField('modal_ubicacion').handleClose();});
        this.getField("nombre_articulo").setOnChange(()=>{
            if(this.getField("nombre_articulo").getValue()!==''){
                this.saldosubicacion();
            }
        });
        //sobreescrivir keyups
        this.getField('codigo_articulo').setKeyUp((e)=>{this.changeKeyUpCustom(e)});


        this.getField('codigo_articulo').setOnChange((props)=>{
            this.getField('nombre_articulo').setValue('');
            this.getField('tarifa').setValue('');
            this.getField('cantidad').setValue('0');
            this.getField('costo').setValue('0');
            this.getField('impuesto').setValue('0');
           
            this.getField('valor_iva1').setValue('0');
            this.getField('total_iva').setValue('0');
            this.getField('valor_iva_unidad').setValue('0');
            this.getField('total_impuestos').setValue('0');
            this.getField('valor_unidad').setValue('0');
            this.getField('total_item').setValue('0');
            this.getField('precio').setValue('0');

            this.getField('rejilla_bodega').toggle(false);
            this.getField('rejilla_ubicacion').toggle(false);
            if(this.pide_ubicacion)
                this.getField('ubicacion').setValue('');
        });

        this.getField("codigo_articulo_aux").setOnChange(()=>{
            if(this.getField('codigo_articulo_aux').getValue()!=='' && (this.prioridad_barras!=='S' || this.cambiar_prioridad===false))
              this.validarCodigo();
        });

        this.getField("codigo_articulo").setCustomSuccess((props)=>{
            if(props.length<=1 && this.operacion_seleccionada!=='F9'){
                if(this.prioridad_barras==='S'){
                    this.cambiar_prioridad=true;
                    this.validarCodigo();
                }else{
                    this.cambiar_prioridad=false;
                }
            }else{
                this.cambiar_prioridad=false;
            }
          });


        this.getField("cantidad").setOnChange(()=>{
            if(this.iva_articulo === 'N'){
                this.xiva = 0;
            }else{
                this.xiva = this.getField('tarifa').getValue()
            }

            this.getField('valor_iva1').setValue(0);
            this.getField('valor_iva_unidad').setValue(0);
            this.getField('valor_unidad').setValue(0);
            this.getField('total_iva').setValue(0);
            this.getField('total_impuestos').setValue(0);
            this.getField('total_item').setValue(0);

            this.calcularvalores();
        });


        this.getField("costo").setOnChange(this.calcularvalores);
        this.getField("cancelar").setClick(()=>{this.getField('modal_articulos').handleClose();});
        this.getField("cancelar2").setClick(()=>{this.getField('modal_transacciones').handleClose();});
        this.getField('grabar_producto').setClick(this.grabarArticulo);
        this.getField('grabar').setClick(this.grabarAjuste);
        this.getField("doc_fuente").setOnChange(this.mostrarcampos);
        this.getField("numero1").setOnBlur(this.aceptartransaccion);
        this.getField('aceptar1').setClick(this.cerrarModalTransacciones);
        this.getField('generar_archivo').setClick(()=>this.procesar());
        this.getField('modal_imprimir').setCloseButton(()=>{
            this.limpiarTodo();
            this.grabacionAjustes();
        });
    }

    changeKeyUpCustom = (e) =>{
        let keycode = (e.keyCode ? e.keyCode : e.which);
        //keycode 120 = f9_servicio;
        //keycode 13 = enter_servicio;
        if(keycode === 120){
            this.operacion_seleccionada = "F9";
        }else{
            this.operacion_seleccionada = "ENTER-BOTON"; 
        }
        this.getField("codigo_articulo").changeKeyUp(e);
    }

    traerPropiedades(){
        let datos={ datos: {
          sucursal_ingreso:this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
      }
  
      sucessTraerPropiedades(data){
        if (data.estado_p === 200 )
        {
            this.prioridad_barras=data.data.prioridad_barras;
        }
    }

    validarCodigo(){
        if(this.getField("codigo_articulo").valid()){
           let datos = { datos: {
               codigo_articulo:this.getField("codigo_articulo").getValue(),
               sucursal_ingreso:this.sucursal_ingreso,
           }};
           this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'codigoauxiliar', operacion_tipo: 'consulta' };
           this.service.send(
           {
               endpoint: this.constant.formConfiguration(),
               method:'GET',
               body: datos,
               success: this.sucessValidarCodigo,
               error: this.error_,
               general: this.generalFormatPmv,
               showMessage:false
           });
       }
     }
   
     sucessValidarCodigo(data){
         if (data.estado_p === 200 )
         {
             this.getField('codigo_articulo').setValue(data.data.codigo);
             this.traerInfoArticulo();
         }else {
             if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
             }
             else {
               let respuesta=Object.values(data.data.errores);
               this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
             }
         }
     }

     traerInfoArticulo(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
          value: this.getField('codigo_articulo').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '42', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerArticulo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
      }
        
    sucessTraerArticulo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.getField('nombre_articulo').setValue(data.data[0].nombre);
            this.getField('tarifa').setValue(data.data[0].vivac);

            this.getField('codigoA').setValue(data.data[0].codigo_interno);
            //this.getField('contenido').setValue(data.data[0].contenido);
            //this.getField('presentacion').setValue(data.data[0].presenta);
            this.getField('precio').setValue(data.data[0].precio1);
            //this.getField('impuestos2').setValue(data.data[0].impuesto1);
            this.costo_guardado=data.data[0].costo;
            this.getField('descuento').setValue(0);
            this.actualizarcosto();
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    camposvisiblesgrabarajuste(){
        this.getField("bodega").setVisible(true)
        this.getField("nombre_bodega").setVisible(true)
        this.getField("concepto_cod").setVisible(true)
        this.getField("nombre_c").setVisible(true)
        this.getField("nit").setVisible(true)
        this.getField("sede").setVisible(true)
        this.getField("nombre").setVisible(true)
        this.getField("iniciar").setVisible(true)
        this.getField("detalle").setVisible(true)
        this.getField("consecutivo").setVisible(true)
        this.getField("subtotal").setVisible(true)
        this.getField("valor_iva").setVisible(true)
        this.getField("impuestos").setVisible(true)
        this.getField("valor_ajuste").setVisible(true)
        this.getField("grabar").setVisible(true)
        this.getField("grabar").setVisible(true)
        this.getField('rejilla').toggle(true);
    }


    creartemporalAjustes(){
        let datos = { datos: {
            sucursal:this.codigo_sucursal,
            codigo_usuario:this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'creartemporal', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successCrearTemporal,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successCrearTemporal(data){
        if (data.estado_p === 200 )
        {
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargartabla', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successMostrarTabla,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    successMostrarTabla(data){
        this.limpiando_tabla=false;
        if(data.estado_p===200){
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data;
            if(this.gridOptionsComponentes1['rowData'].length<=1){
                this.getField('bodega').setDisabled(false);
            }
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});

            configCell.set('cantidad', { cellRenderer: function (props) { return props.data.cantidad }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cantidadtablaChange, sortable: true, filter: false, field: 'cantidad' });
            configCell.set('eliminar', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);
            if(this.pantalla === "TRANSACCION"){
                this.refrescar();
            }
        }else {
            if(data.estado_p===404) {
                this.getField('rejilla').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }
    
    setButtonDelete(props) {
        if(props.data.codigo_articulo !== "."){
            let codigo_articulo = props.data.codigo_articulo;
            let button = document.createElement("input");
            button.onclick = () => this.eliminar(codigo_articulo);
            button.setAttribute("codigo_articulo", 'button_delete_' + codigo_articulo);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("type", "button");
            button.setAttribute("value", "eliminar");
            return this.createElementJaivana(button);
        }
    }

    eliminar(codigo_articulo) {
        this.codigo_articulo = codigo_articulo;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDelete);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDelete() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
                codigo_articulo: this.codigo_articulo,
                codigo_usuario:this.codigo_usuario
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'eliminar_articulo', operacion_tipo: "eliminar" };// parametros del servicio
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv //se envia el generales de arriba
        });
    }

    cargarTabla(){
        this.gridOptionsComponentes1['rowData'] = [];
        this.getField('rejilla').initData(this.gridOptionsComponentes1);
        this.getField('rejilla').toggle(false);
        this.refrescar();
        let datos = { datos: {
            codigo_usuario:this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargartabla', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successMostrarTabla,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successEliminar(data) {
        if(data.estado_p === 200){
            this.gridOptionsComponentes1['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes1);
            this.getField('rejilla').toggle(false);
            this.refrescar();
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargartabla', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successMostrarTabla,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
          let keys=Object.keys(data.data.mensaje);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
        }
    }

    cantidadtablaChange(props) {
        if (props.data.codigo_articulo==='.')
            return;
        let seguir = this.getField('rejilla').cellValid(props.oldValue, props.newValue, /^-?(\d{1,9})(\.\d{1,2})?$/,11);
        let codigo_articulo = props.data.codigo_articulo;
        if (isNaN(props.data.cantidad) || seguir===false) {
            if(props.newValue > 9999999){

                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `El valor debe estar entre las longitudes (1,999999999)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `La columna cantidad no tiene un formato correspondiente al tipo (Numérico)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }
            this.cargarTabla();
        }else if(seguir){       
            let valor_actual = props.newValue;
            let totales = (props.data.costo * parseFloat(valor_actual));
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario,
                codigo_articulo:codigo_articulo,
                cantidad: parseFloat(valor_actual),
                total_item: parseFloat(totales)
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'modificarcantidadtabla', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successActualizarCantidad,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    
    successActualizarCantidad(data){
        if(data.estado_p === 200){
            this.gridOptionsComponentes1['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes1);
            this.getField('rejilla').toggle(false);
            this.refrescar();
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargartabla', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successMostrarTabla,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
          let keys=Object.keys(data.data.mensaje);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
        }
    }

    validartablaubicaciones(){

        let datos = { datos: {}};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'tablaubicaciones', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successAjuste,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successAjuste(data){
        if (data.estado_p === 200 )
        {
            if(data.data[0].existe === 1){
                this.pantalla = 'AJUSTESUBICACION';
                this.camposvisiblesgrabarajuste();
                this.ubicacionbodegas();
                this.llenarSelectBodegas(1);
            }else if(data.data[0].existe === 0){
                this.pantalla = 'TRANSACCION';
                this.validartablatransaccion();
                this.llenarSelectBodegas(0);
            }

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    ubicacionbodegas(){
        if(this.getField("nombre_bodega").getValue() !== "" ){
            this.getField("nombre_bodega").setError(false,'');
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField("bodega").getValue(),
                pantalla:this.pantalla
            }};

            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'ubicacionbodegas', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successAjusteubicacion,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successAjusteubicacion(data){
        if (data.estado_p === 200 )
        {
            //console.log("ajueste ubicacion validar que hacer aqui")
        }else{
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La bodega ' + this.getField("bodega").getValue() + ' -  ' + this.getField("nombre_bodega").getValue() + ' '+data.data.errores.mensaje, 'error');
                this.getField("nombre_bodega").setValue("");
                this.getField("bodega").setValue("");
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    validartablatransaccion(){
        let datos = { datos: {}};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'tablatransaccion', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarTransaccion,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successValidarTransaccion(data){
        if (data.estado_p === 200 )
        {
            if(data.data[0].existe === 1){
                this.getField('modal_transacciones').handleClickOpen();
                this.getField('rejilla').toggle(false);
                this.llenarselectTransacciones();
            }else if(data.data[0].existe === 0){
                this.bloqiearcampos();
            }
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    llenarselectTransacciones(){
        let datos = { datos: {}};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargartransaccion', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successllenarTransaccion,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successllenarTransaccion(data){
        if (data.estado_p === 200 )
        {

            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.nombre;
                dataOp['text'] = item.nombre;
                dataOp['campos_cambian'] = { maneja_bodega: item.maneja_bodega_destino, doc_fuente: item.maneja_doc_origen};
                opciones.push(dataOp);
            });
            this.getField('select_transaccion').setOptions(opciones);

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    mostrarcampos(){
        if(this.getField("doc_fuente").getValue() === 'S'){
            this.getField("label19").setVisible(true);
            this.getField("numero1").setVisible(true);
        }else{
            this.getField("label19").setVisible(false);
            this.getField("numero1").setVisible(false);
        }

        if(this.getField("maneja_bodega").getValue() === 'S'){
            this.getField("label18").setVisible(true);
            this.getField("select_bodega1").setVisible(true);
        }else{
            this.getField("label18").setVisible(false);
            this.getField("select_bodega1").setVisible(false);
        }
    }

    cerrarModalTransacciones(){
        if(this.getField("maneja_bodega").getValue() === 'S'){
            if(this.getField("select_bodega1").getValue() === ''){
                this.alertGeneral.toggle(true, 'Debe llenar todos los campos.', 'error');
                this.paso= false;
            }else{
                this.paso= true;
            }
        }else{
            this.paso= true;
        }
        if(this.getField("doc_fuente").getValue() === 'S'){
            if(this.getField("numero1").getValue() === ''){
                this.alertGeneral.toggle(true, 'Debe llenar todos los campos.', 'error');
                this.paso= false;
            }else{
                this.paso=true
            }
        }else{
            this.paso= true;
        }

        if(this.paso){
            let codigo = this.getField("select_transaccion").getValue().substring(0, this.getField("select_transaccion").getValue().indexOf(" - "));
            let datos = { datos: {
                select_transaccion:codigo
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'traerconcepto', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerconcepto,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    aceptartransaccion(){
        let datos = { datos: {
            documento:this.getField("numero1").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'traerdocumenorigen', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successtraerdoc,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successtraerdoc(data){
        if (data.estado_p === 200 )
        {
            if(data.data.length > 0){
                this.getField("numero1").setValue("");
                let configCell = new Map();
                this.gridOptionsComponentes['rowData'] = data.data;
                this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                    this.selectedRows = [];
                    this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
                    this.getField("numero1").setValue(this.selectedRows[0].numero);
                    this.getField("numero2").setValue(this.selectedRows[0].numero);
                    this.getField('detalle').setValue(this.selectedRows[0].detalle);
                };
                this.getField('rejilla_documento').toggle(true);
                this.getField('rejilla_documento').initData(this.gridOptionsComponentes, configCell);
            }
            this.paso=false;
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    successTraerconcepto(data){
        if (data.estado_p === 200 )
        {
            this.concepto = data.data[0].concepto_ajuste;
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario,
                concepto_cod:this.concepto
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'validarconcepto', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarConcepto,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    validarconcepto(){
        if(this.getField("nombre_c").getValue() !== "" && this.pantalla === "AJUSTESUBICACION"){
            this.getField("nombre_c").setError(false,'');
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario,
                concepto_cod:this.getField("concepto_cod").getValue() 
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'validarconcepto', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarConcepto,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successValidarConcepto(data){
        if (data.estado_p === 200 )
        {
            if(this.pantalla === "AJUSTESUBICACION"){
                this.afecta_costo = data.data.afecta_costo;
                this.alertGeneral.toggle(true, 'El concepto '+ this.getField("concepto_cod").getValue() +' - '+  this.getField("nombre_c").getValue() +' '+ data.data.mensaje, 'warning');
            }else{
                this.nombre_concepto = this.getField("select_transaccion").getValue().substring(this.getField("select_transaccion").getValue().indexOf(" - ") + 3);
                this.afecta_costo = data.data.afecta_costo;
                this.getField("concepto_cod").setValue(this.concepto);
                this.getField("nombre_c").setValue(this.nombre_concepto);
                this.getField("bodega").setValue("");
                this.getField("sede").setValue("");
                this.getField("concepto_cod").setDisabled(true);
                this.getField("nombre_c").setDisabled(true);
                this.getField("iniciar").setDisabled(true);
                this.consultar();
                this.traeritem();
                this.camposvisiblesgrabarajuste();
                this.alertGeneral.toggle(true, 'El concepto '+ this.getField("concepto_cod").getValue() +' - '+  this.getField("nombre_c").getValue() +' '+ data.data.mensaje, 'warning');
                this.getField('modal_transacciones').handleClose();
            }
        }else {
            this.afecta_costo = "";
            this.alertGeneral.toggle(true, 'El concepto '+ this.getField("concepto_cod").getValue() +' - '+  this.getField("nombre_c").getValue() +' '+ data.data.errores.mensaje, 'error');
            this.getField("nombre_c").setValue("");
            this.getField("concepto_cod").setValue("");
        }
    }

    traeritem(){
        this.gridOptionsComponentes1['rowData'] = [];
        this.getField('rejilla').initData(this.gridOptionsComponentes1);
        this.getField('rejilla').toggle(false);

        let datos = { datos: {
            codigo_usuario:this.codigo_usuario,
            sucursal:this.selectedRows[0].codigo_sucursal,
            numero:this.getField("numero1").getValue(),
            fecha:this.selectedRows[0].fecha,
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargararticulo', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successCrearTemporal,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    consultar(){
        if(this.getField("bodega").valid() && this.getField("nombre_bodega").valid() && this.getField("concepto_cod").valid()
         && this.getField("nombre_c").valid() && this.getField("nit").valid() && this.getField("sede").valid() && this.getField("nombre").valid()){
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                codigo_usuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'consultar', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successConsultar,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else if(this.pantalla === "TRANSACCION"){
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                codigo_usuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarconceptoTra', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successConsultar,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe diligenciar todos los campos ', 'error');
        }
    }

    successConsultar(data){
        if (data.estado_p === 200 )
        {
            if(this.pantalla === "TRANSACCION"){
                this.getField("consecutivo").setValue(data.data[0].numero);
            }else if(this.pantalla === "AJUSTESUBICACION"){
                this.getField("consecutivo").setValue(data.data.numero);
            }
            this.getField("agregar_articulo").setVisible(true);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    agregar_articulo(){
        if(this.pantalla === "TRANSACCION"){
            if(this.getField("bodega").valid() && this.getField("nombre_bodega").valid() && this.getField("concepto_cod").valid()
            && this.getField("nombre_c").valid() && this.getField("nit").valid() && this.getField("sede").valid() && this.getField("nombre").valid()){
                this.getField('modal_articulos').handleClickOpen();
            }
        }else{ 
            this.getField('modal_articulos').handleClickOpen();
            //esto para limpiar la variable cuando pide modificar un articulo existente
            this.entro=false;
            this.getField('codigo_articulo').setDisabled(false);
            this.getField("descuento").setDisabled(true);
            this.getField("impuesto").setDisabled(true);
            this.getField("impuesto").setValue(0);
            this.getField("cantidad").setValue(0);
            this.getField("costo").setValue(0);
            this.getField("descuento").setValue(0);
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField("bodega").getValue()
            }};

            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'wms', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successWms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successWms(data){
        if (data.estado_p === 200 )
        {
            if(data.data.ubicacion!==undefined){
                this.pide_ubicacion=true;
                this.getField('ubicacionB').setVisible(true);
                this.getField('ubicacion').setDisabled(true);
            }else{
                this.pide_ubicacion=false;
                this.getField('ubicacionB').setVisible(false);
                this.getField('ubicacion').setValue('999999999999');
            }
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    ubicaiones(){
        this.getField('modal_ubicacion').handleClickOpen();
        let datos = { datos: {
            sucursal:this.codigo_sucursal
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarsucursal', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successUbicacionesWms,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successUbicacionesWms(data){
        if (data.estado_p === 200 )
        {
            this.idsucursal=data.data[0].id;
            this.getField('select_sucursal').setValue(data.data[0].nombre);
            this.getField('select_sucursal').setDisabled(true);
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField("bodega").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarbodega', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successBodegaWms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    successBodegaWms(data){
        if (data.estado_p === 200 )
        {
            this.getField('select_bodega').setValue(data.data[0].nombre);
            this.getField('select_bodega').setDisabled(true);
            let datos = { datos: {
                id:this.idsucursal
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarzona', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successZonaWms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    successZonaWms(data){
        if (data.estado_p === 200 )
        {

            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.nombre;
                dataOp['campos_cambian'] = { zona: item.codigo};
                opciones.push(dataOp);
            });
            this.getField('select_zona').setOptions(opciones);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    cargarRack(){
        if(this.getField("select_zona").getValue() !== ""){
            let datos = { datos: {
                select_zona:this.getField("select_zona").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarrack', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successRackwms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successRackwms(data) {
        if (data.estado_p === 200 )
        {
            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.numero;
                dataOp['campos_cambian'] = { rack: item.numero };
                opciones.push(dataOp);
            });
            this.getField('select_rack').setOptions(opciones);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    cargarColumna(){
        if(this.getField("select_rack").getValue() !== ""){
            let datos = { datos: {
                select_rack:this.getField("select_rack").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarcolumna', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successColumnawms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successColumnawms(data){
        if (data.estado_p === 200 )
        {
            this.wms_nuevo_rack_id=data.data[0].wms_nuevo_rack_id;
            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.columna_numero;
                dataOp['text'] = item.columna_numero;
                dataOp['campos_cambian'] = { columna: item.columna_numero };
                opciones.push(dataOp);
            });
            this.getField('select_columna').setOptions(opciones);

            let data2 ={"data": []};

            for (let index = 1; index <= data.data[0].niveles; index++) {
                data2.data.push(index)
            }

            let opciones2 = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data2.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item;
                dataOp['text'] = item;
                dataOp['campos_cambian'] = { nivel:item};
                opciones2.push(dataOp);
            });
            this.getField('select_nivel').setOptions(opciones2);
            
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    tipoubicacion(){
        if(this.getField("select_columna").getValue() !== ""){
            let datos = { datos: {
                id:this.wms_nuevo_rack_id,
                select_columna:this.getField("select_columna").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'tipoubicacion', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTipoUbicacion,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successTipoUbicacion(data){
        if (data.estado_p === 200 )
        {
            this.getField('select_ubicacion').setValue(data.data[0].tipo);
            this.getField('clase').setValue(data.data[0].tipo);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    cerrarModalUbicaciones(){
        if(this.getField('zona').getValue() !== "" && this.getField('rack').getValue() !== "" && this.getField('columna').getValue() !== ""
         && this.getField('select_nivel').getValue() !== ""){
            let ubicacion_codigo =  this.getField('zona').getValue() + "-" + this.getField('bodega').getValue() + "-" + this.getField('rack').getValue() + "-" + this.getField('columna').getValue() + "-" + this.getField('select_nivel').getValue();
            this.getField('modal_ubicacion').handleClose();
            this.getField('ubicacion').setValue(ubicacion_codigo);
        }else{
            this.alertGeneral.toggle(true, 'Faltan datos.', 'error');
        }
    }

    saldosubicacion(){
        if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid()){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            this.traersaldoBodegas();
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField("bodega").getValue(),
                codigo_articulo:this.getField("codigo_articulo").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'traersaldosubicacion', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successSaldosUbicacion,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successSaldosUbicacion(data){
        this.getField('confirmModalCustom').toggle(false);
        
        if (data.estado_p === 200 )
        {
            //this.traercodigo();
            let configCell = new Map();
            let datos=[]

            data.data.forEach(element => {
                let arreglo=
                {
                    ubicacion: element.ubicacion,
                    saldo: element.cantidad,
                    reservado: element.cantidad_reservada!==null &&  element.cantidad_reservada!=='null'?element.cantidad_reservada:0,
                }
        
                datos.push(arreglo);
            });
            if(datos.length>0){
                configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('reservado', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.reservado) }, cellStyle: {textAlign:"right"},width: 110});
                this.gridOptionsComponentes1['rowData'] = datos;
                this.getField('rejilla_ubicacion').toggle(true);
                this.getField('rejilla_ubicacion').initData(this.gridOptionsComponentes1, configCell);
            }else{
                this.getField('rejilla_ubicacion').toggle(false);
            }
        }else {
            //this.traercodigo();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos de ubicación.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traersaldoBodegas(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'traersaldosbodegas', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successSaldosBodegas,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successSaldosBodegas(data){
        if (data.estado_p === 200 )
        {
            let datos=[]
            data.data.bodega.forEach(element => {
            let arreglo=
                {
                    bodega: element.bodega,
                    saldo: element.saldo
                }
            
                datos.push(arreglo);
            });


            let arraybodegas = Object.entries(data.data.bodegas[0]);
            arraybodegas.forEach(element => {
                let arreglo=
                {
                    bodega: element[0],
                    saldo: element[1]
                }
            
                datos.push(arreglo);
            });
            let configCell = new Map();

            this.gridOptionsBodega['rowData'] = [];
            this.getField('rejilla_bodega').initData(this.gridOptionsBodega);
            this.getField('rejilla_bodega').toggle(false);
            
            this.gridOptionsBodega['rowData'] = datos;
            this.gridOptionsBodega['paginationPageSize']=5; //cantidad de datos que quiero mostrar por cada pagina
            //this.gridOptionsBodega['domLayout'] = 'autoHeight';// organiza el tamaño de la tabla
            this.getField('rejilla_bodega').toggle(true);
            this.getField('rejilla_bodega').initData(this.gridOptionsBodega, configCell);

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos de bodegas.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    actualizarcosto(){
            this.mostrarMensajeGenerando();
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField("bodega").getValue(),
                codigo_articulo:this.getField("codigo_articulo").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'traercosto', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successActualizarCosto,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successActualizarCosto(data){
        if (data.estado_p === 200)
        {
            this.getField('confirmModalCustom').toggle(false);
            if (this.getField("cantidad").getValue() < 0) {
                this.getField("costo").setDisabled(true);
                if(data.data.costo >= 0){
                    this.getField('costo').setValue(data.data.costo);
                }
            }else{
                this.getField("costo").setDisabled(false);
                if(data.data.costo!==undefined)
                    this.getField('costo').setValue(data.data.costo);
                else
                    this.getField('costo').setValue(0);
            }
            this.iva_articulo=data.data.iva;
        }else {
            this.getField('costo').setValue(this.costo_guardado);
            this.iva_articulo='N';
            if(data.estado_p===404) {
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                this.getField('confirmModalCustom').toggle(false);

                let respuesta=Object.values(data.data.errores.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    calcularvalores(){
        if(this.pantalla === "AJUSTESUBICACION" ){
            if (this.getField("cantidad").valid() && this.getField("costo").valid()) {
                if(this.getField("costo").valid()){
                    let xtarifa_iva = this.xiva;
                    let ximpuestos1 = 0;
                    let xcantidad1 = this.getField('cantidad').getValue();
                    let xcosto1 = this.getField('costo').getValue();
                    let xcosto_mas_impuestos = xcosto1 + ximpuestos1;
                    let xvalor_iva = xcosto1 * (xtarifa_iva / 100);
    
                    this.getField('valor_iva1').setValue(xcosto_mas_impuestos.toFixed(2));
                    this.getField('valor_iva_unidad').setValue(xvalor_iva.toFixed(2));
                    this.getField('valor_unidad').setValue((xvalor_iva + xcosto_mas_impuestos).toFixed(2));
                    
                    //total iva
                    let xtotal_iva = xvalor_iva * xcantidad1;
                    this.getField('total_iva').setValue(xtotal_iva.toFixed(2));
                    
                    //total_impuestos
                    let xtotal_impuestos = ximpuestos1 * xcantidad1;
                    this.getField('total_impuestos').setValue(xtotal_impuestos.toFixed(2));
                    
                    //total_item
                    let xtotal_item = xtotal_impuestos + xtotal_iva;
                    xtotal_item = xtotal_item + (xcosto1 * xcantidad1);
                    this.getField('total_item').setValue(xtotal_item.toFixed(2))
                }
            }
        }else{
            if(this.getField("costo").valid()){
                let xtarifa_iva = this.xiva;
                let ximpuestos1 = 0;
                let xcantidad1 = this.getField('cantidad').getValue();
                let xcosto1 = this.getField('costo').getValue();
                let xcosto_mas_impuestos = xcosto1 + ximpuestos1;
                let xvalor_iva = xcosto1 * (xtarifa_iva / 100);

                this.getField('valor_iva1').setValue(xcosto_mas_impuestos.toFixed(2));
                this.getField('valor_iva_unidad').setValue(xvalor_iva.toFixed(2));
                this.getField('valor_unidad').setValue((xvalor_iva + xcosto_mas_impuestos).toFixed(2));
                
                //total iva
                let xtotal_iva = xvalor_iva * xcantidad1;
                this.getField('total_iva').setValue(xtotal_iva.toFixed(2));
                
                //total_impuestos
                let xtotal_impuestos = ximpuestos1 * xcantidad1;
                this.getField('total_impuestos').setValue(xtotal_impuestos.toFixed(2));
                
                //total_item
                let xtotal_item = xtotal_impuestos + xtotal_iva;
                xtotal_item = xtotal_item + (xcosto1 * xcantidad1);
                this.getField('total_item').setValue(xtotal_item.toFixed(2));
            }
        }
    }

    grabarArticulo(){
        if(this.pantalla === "AJUSTESUBICACION"){
            if(this.getField("ubicacion").valid()){
                if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid() && this.getField("valor_iva1").valid() 
                && this.getField("cantidad").valid() && this.getField("costo").valid() && this.getField("valor_iva_unidad").valid()
                && this.getField("valor_unidad").valid() && this.getField("total_iva").valid() && this.getField("total_impuestos").valid()
                && this.getField("total_item").valid() ){
                    if((this.getField("cantidad").getValue() < 0 && this.getField("costo").getValue() === 0) || 
                    (this.getField("cantidad").getValue() > 0 && this.getField("costo").getValue() > 0)){
                        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                        let datos = { datos: {
                            sucursal:this.codigo_sucursal,
                            bodega:this.getField("bodega").getValue(),
                            codigo_articulo:this.getField("codigo_articulo").getValue(),
                            nombre_articulo:this.getField("nombre_articulo").getValue(),
                            ubicacion:this.getField("ubicacion").getValue(),
                            cantidad:this.getField("cantidad").getValue(),
                            select_columna:this.getField("columna").getValue(),
                            select_nivel:this.getField("nivel").getValue(),
                            select_ubicacion:this.getField("clase").getValue(),
                            tarifa:this.getField("tarifa").getValue(),
                            costo:this.getField("costo").getValue(),
                            valor_iva1:this.getField("valor_iva1").getValue(),
                            valor_iva_unidad:this.getField("valor_iva_unidad").getValue(),
                            valor_unidad:this.getField("valor_unidad").getValue(),
                            total_item:this.getField("total_item").getValue(),
                            impuestos:this.getField("total_impuestos").getValue(),
                            codigo_usuario:this.codigo_usuario
                        }};
                        
                        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'grabaritem', operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: this.successGrabarItem,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false,
                        });    
                    }else{
                        this.alertGeneral.toggle(true, 'Si la cantidad es mayor a 0 debe ingresar el costo mayor a 0.', 'error');
                    }
                }
            }else{
                this.alertGeneral.toggle(true, 'Debe ingresar una ubicacion.', 'error');
            }
        }else{
            if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid() && this.getField("valor_iva1").valid() 
            && this.getField("cantidad").valid() && this.getField("costo").valid() && this.getField("valor_iva_unidad").valid()
            && this.getField("valor_unidad").valid() && this.getField("total_iva").valid() && this.getField("total_impuestos").valid()
            && this.getField("total_item").valid() ){
                if((this.getField("cantidad").getValue() < 0 && this.getField("costo").getValue() === 0) || 
                (this.getField("cantidad").getValue() > 0 && this.getField("costo").getValue() > 0)){
                    this.validacionesAgregar();   
                }else{
                    this.alertGeneral.toggle(true, 'Si la cantidad es mayor a 0 debe ingresar el costo mayor a 0.', 'error');
                }
            }
        }
    }

    grabarArticulo2(){
        if(this.pantalla === "AJUSTESUBICACION"){
            if(this.getField("ubicacion").valid()){
                if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid() && this.getField("valor_iva1").valid() 
                && this.getField("cantidad").valid() && this.getField("costo").valid() && this.getField("valor_iva_unidad").valid()
                && this.getField("valor_unidad").valid() && this.getField("total_iva").valid() && this.getField("total_impuestos").valid()
                && this.getField("total_item").valid() ){
                    if((this.getField("cantidad").getValue() < 0 && this.getField("costo").getValue() === 0) || 
                    (this.getField("cantidad").getValue() > 0 && this.getField("costo").getValue() > 0)){
                        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                        let datos = { datos: {
                            sucursal:this.codigo_sucursal,
                            bodega:this.getField("bodega").getValue(),
                            codigo_articulo:this.getField("codigo_articulo").getValue(),
                            nombre_articulo:this.getField("nombre_articulo").getValue(),
                            ubicacion:this.getField("ubicacion").getValue(),
                            cantidad:this.getField("cantidad").getValue(),
                            select_columna:this.getField("columna").getValue(),
                            select_nivel:this.getField("nivel").getValue(),
                            select_ubicacion:this.getField("clase").getValue(),
                            tarifa:this.getField("tarifa").getValue(),
                            costo:this.getField("costo").getValue(),
                            valor_iva1:this.getField("valor_iva1").getValue(),
                            valor_iva_unidad:this.getField("valor_iva_unidad").getValue(),
                            valor_unidad:this.getField("valor_unidad").getValue(),
                            total_item:this.getField("total_item").getValue(),
                            impuestos:this.getField("total_impuestos").getValue(),
                            codigo_usuario:this.codigo_usuario,
                            input:'0',
                        }};
                        
                        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'grabaritem', operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: this.successGrabarItem,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false,
                        });    
                    }else{
                        this.alertGeneral.toggle(true, 'Si la cantidad es mayor a 0 debe ingresar el costo mayor a 0.', 'error');
                    }
                }
            }else{
                this.alertGeneral.toggle(true, 'Debe ingresar una ubicacion.', 'error');
            }
        }else{
            if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid() && this.getField("valor_iva1").valid() 
            && this.getField("cantidad").valid() && this.getField("costo").valid() && this.getField("valor_iva_unidad").valid()
            && this.getField("valor_unidad").valid() && this.getField("total_iva").valid() && this.getField("total_impuestos").valid()
            && this.getField("total_item").valid() ){
                if((this.getField("cantidad").getValue() < 0 && this.getField("costo").getValue() === 0) || 
                (this.getField("cantidad").getValue() > 0 && this.getField("costo").getValue() > 0)){
                    this.validacionesAgregar();   
                }else{
                    this.alertGeneral.toggle(true, 'Si la cantidad es mayor a 0 debe ingresar el costo mayor a 0.', 'error');
                }
            }
        }
    }

    successGrabarItem(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            if(data.data.input!==undefined){
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Ajustes', data.data.mensaje_mostrar);
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.getField('costo').setValue(data.data.costo);
                    this.getField('cantidad').setValue(data.data.cantidad);
                    this.grabarArticulo2();
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm('SI'); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField("confirmModalCustom").setButtonCancel('NO');
                this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); this.getField('codigo_articulo').setValue('');});
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.gridOptionsComponentes1['rowData'] = [];
                this.getField('rejilla').initData(this.gridOptionsComponentes1);
                this.getField('rejilla').toggle(false);
                let datos = { datos: {
                    codigo_usuario:this.codigo_usuario
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargartabla', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successMostrarTabla,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

                //si boton-->ubicacionB esta visible entonces al borrar limpia la ubicación
                if(this.pide_ubicacion)
                    this.getField('ubicacion').setValue('');
                this.limpiarArticulos();
                this.refrescar();
            }
        }else {
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
                if(data.data.errores.mensaje!==undefined)
                    this.mostrarMensajeAdicional(data.data.errores.mensaje);
                this.limpiarArticulos();
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    limpiarArticulos(){
        this.cantidad = "";
        this.getField("codigo_articulo").setDisabled(false);
        this.getField("codigo_articulo").setValue("");
        this.getField("codigo_articulo").setError(false,"");
        this.getField("nombre_articulo").setValue("");
        this.getField("cantidad").setValue(0);
        this.getField("descuento").setValue(0);
        this.getField("impuesto").setValue(0);
        this.getField("select_columna").setValue("");
        this.getField("select_nivel").setValue("");
        this.getField("tarifa").setValue(0);
        this.getField("costo").setValue(0);
        this.getField("valor_iva1").setValue(0);
        this.getField("valor_iva_unidad").setValue(0);
        this.getField("valor_unidad").setValue(0);
        this.getField("total_item").setValue(0);
        this.getField("precio").setValue(0);
        this.getField('rejilla_ubicacion').toggle(false);
        this.getField('rejilla_bodega').toggle(false);
    }

    refrescar(){
        let datos = { datos: {
            codigo_usuario:this.codigo_usuario,
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'refrescar', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successActualizarTotales,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successActualizarTotales(data){
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false);
            this.getField("subtotal").setValue(data.data[0].xtotal)
            this.getField("valor_iva").setValue(data.data[1].xivatotal)
            this.getField("impuestos").setValue(data.data[2].ximpuestostotal)
            this.getField("valor_ajuste").setValue(data.data[3].xvalortotal)

            if(data.data[4].total > 0){
                if(this.pantalla === "TRANSACCION"){
                    this.getField("bodega").setDisabled(false);
                }else{
                    this.getField("bodega").setDisabled(true);
                }
            }else{
                this.getField("bodega").setDisabled(false);
            }
        }else {
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traercodigo(){
        if(this.getField('codigoA').getValue() !== '' ){
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario,
                codigo_articulo: this.getField('codigoA').getValue(),
                ubicacion:this.getField("ubicacion").getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'existe_articulo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        } 
   }

   successTraerDatos(data){
        if (data.estado_p === 200 )
        {
            if(this.entro===false){
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El código '+data.data[0].codigo_articulo+' ya existe, ¿Desea modificarlo?');
                this.getField('confirmModalCustom').setClickDialog(()=>{this.entro=true; this.llenarcampos(data);});
                this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
                this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); this.getField('codigo_articulo').setValue('');});
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField('confirmModalCustom').toggle(true);    
            }        
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
   }

   llenarcampos(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField("codigo_articulo").setDisabled(true);
        this.getField("codigo_articulo").setValue(data.data[0].codigo_articulo);
        this.getField("nombre_articulo").setValue(data.data[0].nombre);
        this.getField("tarifa").setValue(data.data[0].tarifa_iva);
        this.getField("cantidad").setValue(data.data[0].cantidad);
        this.cantidad = data.data[0].cantidad;
        this.getField("costo").setValue(data.data[0].costo);
        this.getField('impuestos').setValue(data.data[0].impuestos)
        this.getField("valor_iva1").setValue(data.data[0].valor_iva);
        this.getField("total_item").setValue(data.data[0].total_item);
        this.getField("ubicacion").setValue(data.data[0].ubicacion);      
        this.getField("valor_iva_unidad").setValue(data.data[0].subtotal1);
        this.getField("valor_unidad").setValue(data.data[0].subtotal2);

    }

    grabarAjuste(){
        if(this.getField("bodega").valid() && this.getField("nombre_bodega").valid() && this.getField("concepto_cod").valid()
        && this.getField("nombre_c").valid() && this.getField("nit").valid() && this.getField("sede").valid() 
        && this.getField("nombre").valid()){
            if(this.getField("detalle").getValue().length>0 && !this.getField("detalle").valid())
                return;
            else
                this.getField("detalle").setError(false,'');
            if(this.gridOptionsComponentes1['rowData'].length<=0){
                this.mostrarMensajeAdicional("No hay items para grabar.");
                return;
            }
            if(Number(this.getField("valor_ajuste").getValue())===0){
                this.mostrarMensajeAdicional("El valor del ajuste no puede ser cero.");
                return;
            }

            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            let datos = { datos: {
                pantalla: this.pantalla,
                sucursal:this.codigo_sucursal,
                codigo_usuario:this.codigo_usuario,
                concepto_cod: this.getField("concepto_cod").getValue(),
                bodega: this.getField("bodega").getValue(),
                consecutivo: this.getField("consecutivo").getValue(),
                nombre_c: this.getField("nombre_c").getValue(),
                detalle: this.getField("detalle").getValue().length>0?this.getField("detalle").getValue():'.',
                nit: this.getField("nit").getValue(),
                sede: this.getField("sede").getValue(),
                impuestos: this.getField("impuestos").getValue(),
                valor_iva: this.getField("valor_iva").getValue(),
                valor_ajuste: this.getField("valor_ajuste").getValue(),
                afecta_costo: this.afecta_costo,
                doc_fuente: this.getField("doc_fuente").getValue(),
                maneja_bodega: this.getField("maneja_bodega").getValue(),
                numero:this.getField("numero2").getValue(),
            }};

            if(this.selectedRows.length>0){
                datos.datos.codigo_sucursal=this.selectedRows[0].codigo_sucursal;
                datos.datos.fecha=this.selectedRows[0].fecha;

            }
        
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'grabar_ajuste', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successTraerconsecutivo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe diligenciar todos los campos ', 'error');
        }
    }

    successTraerconsecutivo(data){
        this.getField('confirmModalCustom').toggle(false); 
        if (data.estado_p === 200 )
        {    if(data.data.mensaje_mostrar!==undefined)
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
            
            this.getField("numero2").setValue(data.data.numero);   
            this.boton_selecionado='grabar_ajuste';
            this.abrirImprimir();
            //this.limpiarTodo();
        }else{
            this.alertGeneral.toggle(true,'Error Ajuste no grabado.', 'error');  
            if(data.data.mensaje_mostrar!==undefined)
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar)
            else if(data.data.errores.mensaje!==undefined)
                this.mostrarMensajeAdicional(data.data.errores.mensaje);
        }
    }

    limpiarTodo(){
        this.getField("bodega").setValue("");
        this.getField("nombre_bodega").setValue("");
        this.getField("concepto_cod").setValue("");
        this.getField("nombre_c").setValue("");
        this.getField("nit").setValue("");
        this.getField("sede").setValue("");
        this.getField("nombre").setValue("");
        this.getField("detalle").setValue("");
        this.getField("consecutivo").setValue("");
        this.getField("total_item").setValue(0);
        this.getField("subtotal").setValue(0)
        this.getField("valor_iva").setValue(0)
        this.getField("impuestos").setValue(0)
        this.getField("valor_ajuste").setValue(0)
        this.creartemporalAjustes();
    }

    validacionesAgregar(){
        if(Number(this.getField('total_item').getValue()!==0)){
            this.getField('grabar').setDisabled(true);
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
              let datos={ datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField('bodega').getValue(),
                costo:this.getField('costo').getValue(),
                codigo_articulo:this.getField('codigo_articulo').getValue()
              }};
              this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.sucessValidaciones,
                      error: this.error_,
                      general: this.generalFormatPmv,
                      showMessage: false
              });
        }else{
            this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
        }    
    }

    validacionesAgregar2(input){
        this.getField('grabar').setDisabled(true);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        if(this.getField('codigo_articulo').valid()){
            let datos={ datos: {
                  sucursal:this.codigo_sucursal,
                  input:input,
                  bodega:this.getField('bodega').getValue(),
                  costo:this.getField('costo').getValue(),
                  codigo_articulo:this.getField('codigo_articulo').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessValidaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    sucessValidaciones(data)
    {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('grabar').setDisabled(false);
        if (data.estado_p === 200)
        { 
            if(data.data.costo_pro!==undefined){
                //pedir el input
                let costo_pro=data.data.costo_pro;
                if(costo_pro===0){
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El costo_pro_'+this.getField('select_direccion').getValue()+' es cero. ¿Está seguro de continuar?');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                }else{
                    let valor_costo = Math.abs((costo_pro - this.getField('costo').getValue())/ costo_pro) * 100;
                    let margen_costo_promedio=data.data.margen_costo_promedio;
                    if (valor_costo > margen_costo_promedio) {
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El valor del costo sobrepasa el margen costo entrada. Margen costo entrada= '+margen_costo_promedio+'. Variación='+valor_costo+'. ¿Está seguro de continuar? ');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    }else{
                    this.validacionesAgregar2(0)
                    }
                }
            }else{
                return true;
            }
        }
        else 
        {
            if(data.estado_p===404) {
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    let datos = { datos: {
                        sucursal:this.codigo_sucursal,
                        bodega:this.getField("bodega").getValue(),
                        codigo_articulo:this.getField("codigo_articulo").getValue(),
                        nombre_articulo:this.getField("nombre_articulo").getValue(),
                        ubicacion:this.getField("ubicacion").getValue(),
                        cantidad:this.getField("cantidad").getValue(),
                        select_columna:this.getField("select_columna").getValue(),
                        select_nivel:this.getField("select_nivel").getValue(),
                        tarifa:this.getField("tarifa").getValue(),
                        costo:this.getField("costo").getValue(),
                        valor_iva1:this.getField("valor_iva1").getValue(),
                        valor_iva_unidad:this.getField("valor_iva_unidad").getValue(),
                        valor_unidad:this.getField("valor_unidad").getValue(),
                        total_item:this.getField("total_item").getValue(),
                        codigo_usuario:this.codigo_usuario
                    }};
                    
                    this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'grabaritem', operacion_tipo: 'consulta' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successGrabarItem,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false,
                    });
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

/////////////////////////aqui termina grabar Ajuste//////////////////////////////////////////////////////////////////////////

/////////////////////////Aquí empieza Consulta de Ajustes/////////////////////////
    
    initConsultaAjustes(){
        console.log("Formulario Consulta de Ajustes,  @version: jdesk_1.01.0002, @author: Sebastian Rios Echeverri");
        this.camposVisiblesConsultaAjustes();
        this.getField("consultar_desde").setValue(this.fecha_inicio);
        this.getField("consultar_desde").setOnChange(this.validarfechasConsulta);
        this.getField("consultar_hasta").setOnChange(this.validarfechasConsulta);
        this.getField("consultar").setClick(this.btnConsultarAjustesInventario);
        // this.getField("codigo_concepto").setKeyUp(()=>{this.getField("codigo_concepto_ajuste").getValue() === '' ? this.getField("codigo_concepto_ajuste").setError(false,'') : this.getField("codigo_concepto_ajuste").valid();});
        // this.getField("codigo_sucursal").setKeyUp(()=>{this.getField("codigo_sucursal").getValue() === '' ? this.getField("codigo_sucursal").setError(false,'') : this.getField("codigo_sucursal").valid();});
        this.getField('radio_correo').setValue("N");
        this.getField("modal_ajusteinventario").setCloseButton(()=>{this.filaSeleccionadaConsultaAjustes={};this.limpiarCamposConsultaAjustes();});
        this.getField("bt_salir_consultar").setClick(()=>{this.filaSeleccionadaConsultaAjustes={};this.getField("modal_ajusteinventario").handleClose();this.limpiarCamposConsultaAjustes();});
        this.getField("ver_presentacion").setClick(()=>{this.validarImprimir();});
        this.getField('generar_archivo').setClick(()=>this.procesar());
    }

    validarImprimir(){
        if(this.filaSeleccionadaConsultaAjustes.length>0){
            this.boton_selecionado='ver_presentacion';
            this.nombre_archivo='ajuste_'+this.filaSeleccionadaConsultaAjustes[0].numero;
            this.abrirImprimir();
        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        }
    }

    validarfechasConsulta(){
        this.getField('consultar').setDisabled(false);
        //let fecha_actual =new Date().toISOString().split('T')[0];
        let consultar_desde = new Date(this.getField("consultar_desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("consultar_hasta").getValue()).toISOString().split('T')[0];

        this.getField("consultar_desde").setError(false,"");
        this.getField("consultar_hasta").setError(false,"");

        if(consultar_desde > consultar_hasta){
            this.getField('consultar').setDisabled(true);
            this.getField("consultar_hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }

        let fecha=new Date();
        fecha.setDate(fecha.getDate()-this.dias_maximo);
        fecha=fecha.toISOString().split('T')[0];
        if(consultar_desde < fecha){
            this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('consultar').setDisabled(true);
            return false;
        }
        return true;

    }

    btnConsultarAjustesInventario(){
        this.getField('numero').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('nombre_sucursal').setError(false,'');
        this.getField('codigo_concepto_ajuste').setError(false,'');
        this.getField('nombre_concepto').setError(false,'');
        this.getField('consultar_desde').setError(false,'');
        this.getField('consultar_hasta').setError(false,'');
        let errores=0;
        if(this.validarfechasConsulta()===false)
            errores++;
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
            if(!this.getField('codigo_sucursal').valid())
                errores++;
            if(!this.getField('nombre_sucursal').valid())
                errores++;
        }
        if(this.getField('codigo_concepto_ajuste').getValue()!=='' && this.getField('codigo_concepto_ajuste').getValue()!==' '){
            if(!this.getField('codigo_concepto_ajuste').valid())
                errores++;
            if(!this.getField('nombre_concepto').valid())
                errores++;
        }
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' '){
            if(!this.getField('numero').valid())
                errores++;
        }

        if (errores===0) {
            let datos = { datos: {
                consultar_desde:this.getField("consultar_desde").getValue(),
                consultar_hasta:this.getField("consultar_hasta").getValue(),
                codigo_concepto_ajuste:this.getField("codigo_concepto_ajuste").getValue(),
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                numero:this.getField("numero").getValue()
            }};
            this.mostrarMensajeGenerando();
            this.getField("tb_facturacajustes").setTipoServicioOperacion('inve-ajustesinventario-consultarajustinventario');
            this.getField("tb_facturacajustes").sendRequest(this.successBtnConsultarAjustesInventario, datos);


        }
    }

    onSelectionChangedConsultar(){
        this.filaSeleccionadaConsultaAjustes = this.gridOptionsFacturacAjustes.api.getSelectedRows();  
    }

    successBtnConsultarAjustesInventario(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.getField("modal_ajusteinventario").handleClickOpen();
            this.getField("tb_facturacajustes").toggle(true);
            this.gridOptionsFacturacAjustes["rowData"] = data.data;
            let configCell = new Map();
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('basee', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.basee) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('baseg', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.baseg) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.iva) }, cellStyle: {textAlign:"right"},width: 110});

            this.getField('tb_facturacajustes').initData(this.gridOptionsFacturacAjustes,configCell);

        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    limpiarCamposConsultaAjustes(){
        this.getField("consultar_desde").setValue(this.fecha_inicio);
        this.getField("consultar_hasta").setValue(this.fecha_hoy);
        this.getField("numero").setValue('');
        this.getField("codigo_concepto_ajuste").setValue('');
        this.getField("nombre_concepto").setValue('');
        this.getField("codigo_sucursal").setValue('');
        this.getField("nombre_sucursal").setValue('');
        this.getField("consultar").setDisabled(false);
    }

    camposVisiblesConsultaAjustes(){
        this.getField("consultar_desde").setVisible(true);
        this.getField("consultar_hasta").setVisible(true);
        this.getField("numero").setVisible(true);
        this.getField("codigo_concepto_ajuste").setVisible(true);
        this.getField("nombre_concepto").setVisible(true);
        this.getField("codigo_sucursal").setVisible(true);
        this.getField("nombre_sucursal").setVisible(true);
        this.getField("consultar").setVisible(true);
    }

    /////////////////////////Aquí termina Consulta de Ajustes///////////


    //ander -------------------------->


    listadoAjuste(){
        console.log("Formulario CustomAjustesInventario,  @version: jdesk_1.01.0002, @author: Anderson acevedo briñez");
        this.camposVisiblesListadoAjuste();
        this.getField('modal_imprimir').setCloseButton(()=>{
            this.eliminarTemporal();
            this.limpiarCamposListadosAjustes();
          });
        this.getField('consultar_desde').setValue(this.fecha_inicio);
        this.getField('ch_todos').setValueSwitch(true);
        this.getField('ch_pendientes').setValueSwitch(false);
        this.getField('ch_cruzados').setValueSwitch(false);
        this.getField('ch_todos').setOnChange((props)=>{
            if(this.getField('ch_todos').getValue()==='false'){
              this.getField('ch_pendientes').setValueSwitch (false);
              this.getField('ch_cruzados').setValueSwitch (false);
              this.ch_todos='T';
              this.ch_todos_descripcion='Todos';
            }
        });
        this.getField('ch_pendientes').setOnChange((props)=>{
            if(this.getField('ch_pendientes').getValue()==='false'){
              this.getField('ch_todos').setValueSwitch (false);
              this.getField('ch_cruzados').setValueSwitch (false);
              this.ch_todos='P';
              this.ch_todos_descripcion='Pendientes por cruzar';
            }
        });
        this.getField('ch_cruzados').setOnChange((props)=>{
            if(this.getField('ch_cruzados').getValue()==='false'){
              this.getField('ch_pendientes').setValueSwitch (false);
              this.getField('ch_todos').setValueSwitch (false);
              this.ch_todos='C';
              this.ch_todos_descripcion='Cruzados';
            }
        });
        this.getField('bt_general').setClick(()=>{this.boton_selecionado='general';this.validarCamposLista()});
        this.getField('bt_detallado').setClick(()=>{this.boton_selecionado='detallado';this.validarCamposLista()});
        this.getField('bt_articulo').setClick(()=>{this.boton_selecionado='articulos';this.validarCamposLista()});
        this.getField('bt_articulo_grupo').setClick(()=>{this.boton_selecionado='articulo_x_grupo';this.validarCamposLista()});

        this.getField("consultar_desde").setOnChange(this.validarfechas);
        this.getField("consultar_hasta").setOnChange(this.validarfechas);
        this.getField('generar_archivo').setClick(()=>this.procesar());

    }
    onSelectionChangedInicio(){ 

    }


    deshabilitarCorreo(){
    this.getField('email').setDisabled(true);
    this.getField('email').setValue('');
    this.getField('email').setError(false,'');
    this.getField('radio_correo').setDisabled(true);
    this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }
    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }



    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'/*  || this.operacion_actual!=='pantalla' */){// Se comenta la validación de que sea diferente a pantalla, ya que en la tarea de corrección "JQJD-1480" pidieron que si en "radio_correo" está seleccionado la opción de "NO", no fuera requerido digitar el correo, ya que en la tabla siempre va a ir 2 ítems, lo que significa que el pdf sólo va a tener una página.
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    validarCamposLista(){
        this.getField('consultar_desde').setError(false,'');
        this.getField('consultar_hasta').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('nombre_sucursal').setError(false,'');
        this.getField('codigo_concepto_ajuste').setError(false,'');
        this.getField('nombre_concepto').setError(false,'');

        let errores=0;
        if(this.validarfechas()===false)
            errores++;
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
            if(!this.getField('codigo_sucursal').valid())
                errores++;
            if(!this.getField('nombre_sucursal').valid())
                errores++;
        }
        if(this.getField('codigo_concepto_ajuste').getValue()!=='' && this.getField('codigo_concepto_ajuste').getValue()!==' '){
            if(!this.getField('codigo_concepto_ajuste').valid())
                errores++;
            if(!this.getField('nombre_concepto').valid())
                errores++;
        }

        if(this.getField('ch_todos').getValue()==='false' && this.getField('ch_pendientes').getValue()==='false' && this.getField('ch_cruzados').getValue()==='false'){
            errores++;
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Todos,pendientes por curzar o cruzados.', 'error');
        }


        if(errores===0){
            if(this.boton_selecionado==='general')
                this.generarModalLista();
            else
                this.abrirImprimir();
        }

    }

    generarModalLista(){
        this.mostrarMensajeGenerando();
        let enviarPeticion=true;
        let op='';
        let datos={ datos: {
            consultar_desde: this.getField('consultar_desde').getValue(),
            consultar_hasta: this.getField('consultar_hasta').getValue(),
            estado_ch: this.ch_todos,
            sucursal_ingreso:this.sucursal_ingreso,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual
        }};
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' ){
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            datos.datos.nombre_sucursal=this.getField('nombre_sucursal').getValue();
        }
        if(this.getField('codigo_concepto_ajuste').getValue()!=='' && this.getField('codigo_concepto_ajuste').getValue()!=='' ){
            datos.datos.codigo_concepto_ajuste=this.getField('codigo_concepto_ajuste').getValue();
            datos.datos.nombre_concepto=this.getField('nombre_concepto').getValue();
        }
        if(this.boton_selecionado==='general'){
            op='consultar_general';
            enviarPeticion=false;
            this.getField("tabla_consulta").setTipoServicioOperacion('inve-ajustesinventario-'+op);
            this.getField("tabla_consulta").sendRequest(this.sucessTraerDatos, datos);
        }
        else if(this.boton_selecionado==='detallado'){
            op='consultar_detallado';
            enviarPeticion=false;
            this.getField("tabla_detallado").setTipoServicioOperacion('inve-ajustesinventario-'+op);
            this.getField("tabla_detallado").sendRequest(this.sucessTraerDatos, datos);
        }
        else if(this.boton_selecionado==='articulos'){
            op='consultar_articulo';
            enviarPeticion=false;
            this.getField("tabla_articulo").setTipoServicioOperacion('inve-ajustesinventario-'+op);
            this.getField("tabla_articulo").sendRequest(this.sucessTraerDatos, datos);
        }
        else if(this.boton_selecionado==='articulo_x_grupo'){
            op='consultar_articulo_x_grupo';
            enviarPeticion=false;
            this.getField("tabla_articulo_x_grupo").setTipoServicioOperacion('inve-ajustesinventario-'+op);
            this.getField("tabla_articulo_x_grupo").sendRequest(this.sucessTraerDatos, datos);
        }
        else if(this.boton_selecionado==='ver_presentacion'){
            datos.datos={};
            op='detalleconsultajuste';
            datos.datos.numero=this.filaSeleccionadaConsultaAjustes[0].numero;
            datos.datos.codigo_sucursal=this.filaSeleccionadaConsultaAjustes[0].codigo_sucursal;
            datos.datos.fecha=this.filaSeleccionadaConsultaAjustes[0].fecha;
            datos.datos.codigo_concepto_ajuste=this.filaSeleccionadaConsultaAjustes[0].concepto+'';
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
        }else if(this.boton_selecionado==='grabar_ajuste'){
            datos.datos={};
            op='detalleconsultajuste';
            datos.datos.numero=this.getField("numero2").getValue();
            datos.datos.codigo_sucursal=this.codigo_sucursal;
            datos.datos.fecha=this.fecha_hoy;
            datos.datos.codigo_concepto_ajuste=this.getField("concepto_cod").getValue();
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
        }else if(this.boton_selecionado==='grabar_ajuste_cruce'){
            datos.datos={};
            op='detalleconsultajuste';
            datos.datos.numero=this.getField("numero3").getValue();
            datos.datos.codigo_sucursal=this.getField("codigo_sucursal1").getValue();
            datos.datos.fecha=this.fecha_hoy;
            datos.datos.codigo_concepto_ajuste=this.getField("codigo_concepto_ajuste").getValue();
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
        }else if(this.boton_selecionado==='hacer_ajuste'){
            datos.datos={};
            op='archivoajusteref';
            datos.datos.numero=this.getField("numero2").getValue();
            datos.datos.codigo_sucursal=this.lasucursal;
            datos.datos.fecha=this.fecha_hoy;
            datos.datos.codigo_concepto_ajuste=this.getField("concepto_cod").getValue();
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
        }
        else{
            op='';
            enviarPeticion=false;
        }
        if(enviarPeticion){
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
              let datosFicha = [];
              this.lista=data.data.datos_lista;
              let data2 = 
                  {
                      "empresa": this.lista.empresa,
                      "razon_social": this.lista.razon_social,
                      "nit":this.lista.nit,
                      "direccion": this.lista.direccion,
                      "telefono": this.lista.telefono,
                      "ciudad": this.lista.ciudad,
                      "sucursal": this.lista.sucursal,
                      "bodega": this.lista.bodega,
                      "concepto": this.lista.concepto,
                      "cliente": this.lista.cliente,
                      "proveedor": this.lista.proveedor,
                      "titulo": this.lista.titulo,
                      "fechas_consulta":this.lista.fechas_consulta,
                      "numero": this.lista.numero,
                      "fecha": this.lista.fecha,
                      "referencia": this.lista.referencia,
                      "detalle": this.lista.detalle,
                      "opcion": (this.getField('operacion_form').getValue()!=='Grabación de Ajustes' && this.getField('operacion_form').getValue()!=='Consulta de Ajustes')?this.ch_todos_descripcion:'',

                  };
              datosFicha.push(data2);
              this.getField('lista_datos_general').setItemsFichas(datosFicha);

              if(this.boton_selecionado==='general'){
                let configCell = new Map();
                this.getField('tabla_consulta').toggle(true);
                this.gridOptionsComponentesItems['rowData'] = [];
                this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
                this.getField('num_facturas').setVisible(true);
                this.getField('total_valor').setVisible(true);
                this.getField('num_facturas').setValue(this.currencyFormatterGeneral(data.data.total_facturas));
                this.getField('total_valor').setValue(this.currencyFormatterGeneral(data.data.total_valor));
                configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
                this.getField('tabla_consulta').initData(this.gridOptionsComponentesItems,configCell);
              }else{
                this.getField('num_facturas').setVisible(false);
                this.getField('total_valor').setVisible(false);
              }
              if(this.boton_selecionado==='detallado'){
                let configCell = new Map();
                this.getField('tabla_detallado').toggle(true);
                this.gridOptionsComponentesDetallado['rowData'] = [];
                this.gridOptionsComponentesDetallado['rowData'] = data.data.datos_items;
                this.getField('total_general').setVisible(true);
                this.getField('total_general').setValue(this.currencyFormatterGeneral(this.lista.total_general));
                configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});

                configCell.set('contenido', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.contenido) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cartones', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cartones) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
                this.getField('tabla_detallado').initData(this.gridOptionsComponentesDetallado,configCell);
              }else{
                this.getField('total_general').setVisible(false);
              }
              if(this.boton_selecionado==='articulos'){
                let configCell = new Map();
                this.getField('tabla_articulo').toggle(true);
                this.gridOptionsComponentesArticulo['rowData'] = [];
                this.gridOptionsComponentesArticulo['rowData'] = data.data.datos_items;
                this.getField('tabla_articulo').initData(this.gridOptionsComponentesArticulo,configCell);
              }else{

              }
              if(this.boton_selecionado==='articulo_x_grupo'){
                let configCell = new Map();
                this.getField('tabla_articulo_x_grupo').toggle(true);
                this.gridOptionsComponentesArtxGrupo['rowData'] = [];
                this.gridOptionsComponentesArtxGrupo['rowData'] = data.data.datos_items;
                this.getField('label2').setVisible(true);
                this.getField('total_cantidad').setVisible(true);
                this.getField('total_costo_promedio').setVisible(true);
                this.getField('total_costo').setVisible(true);
                configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});


                this.getField('total_cantidad').setValue(this.currencyFormatterGeneral(this.lista.total_cantidad));
                this.getField('total_costo_promedio').setValue(this.currencyFormatterGeneral(this.lista.costo_promedio));
                this.getField('total_costo').setValue(this.currencyFormatterGeneral(this.lista.total_costo));
                this.getField('tabla_articulo_x_grupo').initData(this.gridOptionsComponentesArtxGrupo,configCell);

              }else{
                this.getField('label2').setVisible(false);
                this.getField('total_cantidad').setVisible(false);
                this.getField('total_costo_promedio').setVisible(false);
                this.getField('total_costo').setVisible(false);
              }
              if(this.boton_selecionado==='ver_presentacion' || this.boton_selecionado === 'grabar_ajuste'  || this.boton_selecionado==='hacer_ajuste' || this.boton_selecionado === 'grabar_ajuste_cruce' ){
                let configCell = new Map();
                if(this.boton_selecionado==='hacer_ajuste'){
                    this.getField('tabla_ajuste_ref').toggle(true);
                    configCell.set('subtotal', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal) }, cellStyle: {textAlign:"right"},width: 110});
                }else{
                    this.getField('tabla_consultar').toggle(true);
                    configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) },field: 'tarifa_iva', cellStyle: {textAlign:"right"},width: 110});
                    configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
                }
                   
                this.gridOptionsComponentesConsultarModal['rowData'] = [];
                this.gridOptionsComponentesConsultarModal['rowData'] = data.data.datos_items;
                this.getField('valor_exento').setVisible(true);
                this.getField('valor_grabado').setVisible(true);
                this.getField('total_iva_consultar').setVisible(true);
                this.getField('valor_total').setVisible(true);
                this.getField('valor_exento').setValue(this.currencyFormatterGeneral(this.lista.basee));
                this.getField('valor_grabado').setValue(this.currencyFormatterGeneral(this.lista.baseg));
                this.getField('total_iva_consultar').setValue(this.currencyFormatterGeneral(this.lista.iva));
                this.getField('valor_total').setValue(this.currencyFormatterGeneral(this.lista.valor));

                configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('codigo', { headerName: 'Código'});
                configCell.set('costo', { headerName: 'Subtotal'});
                configCell.set('nombre', { headerName: 'Nombre_articulo'});
                configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
                if(this.boton_selecionado==='hacer_ajuste'){
                    this.getField('tabla_ajuste_ref').initData(this.gridOptionsComponentesConsultarModal,configCell);
                }else{
                    this.getField('tabla_consultar').initData(this.gridOptionsComponentesConsultarModal,configCell);
                }
              }else{
                this.getField('valor_exento').setVisible(false);
                this.getField('valor_grabado').setVisible(false);
                this.getField('total_iva_consultar').setVisible(false);
                this.getField('valor_total').setVisible(false);
              }

              this.getField('modal_general').handleClickOpen();
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    validarfechas(){
        this.getField('bt_general').setDisabled(false);
        this.getField('bt_detallado').setDisabled(false);
        this.getField('bt_articulo').setDisabled(false);
        this.getField('bt_articulo_grupo').setDisabled(false);
        //let fecha_actual =new Date().toISOString().split('T')[0];
        let consultar_desde = new Date(this.getField("consultar_desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("consultar_hasta").getValue()).toISOString().split('T')[0];

        this.getField("consultar_desde").setError(false,"");
        this.getField("consultar_hasta").setError(false,"");

        if(consultar_desde > consultar_hasta){
            this.getField('bt_general').setDisabled(true);
            this.getField('bt_detallado').setDisabled(true);
            this.getField('bt_articulo').setDisabled(true);
            this.getField('bt_articulo_grupo').setDisabled(true);
            this.getField("consultar_hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }

        let fecha=new Date();
        let dias=0;
        if(this.entro_lista){
            fecha.setDate(fecha.getDate()-this.dias_maximo_lista);
            dias=this.dias_maximo_lista;
        }else{
            fecha.setDate(fecha.getDate()-this.dias_maximo);
            dias=this.dias_maximo;
        }
        fecha=fecha.toISOString().split('T')[0];
        if(consultar_desde < fecha){
            this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+dias+" días.");
            this.getField('bt_general').setDisabled(true);
            this.getField('bt_detallado').setDisabled(true);
            this.getField('bt_articulo').setDisabled(true);
            this.getField('bt_articulo_grupo').setDisabled(true);
            return false;
        }
        return true;

    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.ajustes_de_inventario;
            this.dias_maximo_lista=data.data.lista_ajustes_de_inventario;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    eliminarTemporal(){
        let datos={ datos: {}};
        let op=''
        if(this.boton_selecionado==='general')
            op='ninguna';
        else if(this.boton_selecionado==='detallado' || this.boton_selecionado==='articulos')
            op='7_aj';
        else if(this.boton_selecionado==='articulo_x_grupo')
            op='7_ajgr';
        else
            op='ninguna';
        if(op!=='ninguna'){
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: op, operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.sucessEliminarTemporal,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    sucessEliminarTemporal(data){
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCamposListadosAjustes();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGuardando(){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    limpiarCamposListadosAjustes(){
        this.getField('consultar_desde').setValue(this.fecha_inicio);
        this.getField('consultar_hasta').setValue(this.fecha_hoy);
        this.getField('codigo_sucursal').setValue('');
        this.getField('nombre_sucursal').setValue('');
        this.getField('codigo_concepto_ajuste').setValue('');
        this.getField('nombre_concepto').setValue('');
        this.getField('ch_todos').setValueSwitch(true);
        this.getField('ch_pendientes').setValueSwitch(false);
        this.getField('ch_cruzados').setValueSwitch(false);
        this.boton_selecionado='';
        this.nombre_archivo='';
        this.ch_todos='T'
    }

    camposVisiblesListadoAjuste(){
        this.getField('consultar_desde').setVisible(true);
        this.getField('consultar_hasta').setVisible(true);
        this.getField('codigo_sucursal').setVisible(true);
        this.getField('nombre_sucursal').setVisible(true);
        this.getField('codigo_concepto_ajuste').setVisible(true);
        this.getField('nombre_concepto').setVisible(true);
        this.getField('ch_todos').setVisible(true);
        this.getField('ch_pendientes').setVisible(true);
        this.getField('ch_cruzados').setVisible(true);
        this.getField('bt_general').setVisible(true);
        this.getField('bt_detallado').setVisible(true);
        this.getField('bt_articulo').setVisible(true);
        this.getField('bt_articulo_grupo').setVisible(true);

    }

    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    generarPdfTabla3()
    {       
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={
                consultar_desde: this.getField('consultar_desde').getValue(),
                consultar_hasta: this.getField('consultar_hasta').getValue(),
                estado_ch: this.ch_todos,
                sucursal_ingreso:this.sucursal_ingreso,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' ){
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                datos2.nombre_sucursal=this.getField('nombre_sucursal').getValue();
            }
            if(this.getField('codigo_concepto_ajuste').getValue()!=='' && this.getField('codigo_concepto_ajuste').getValue()!=='' ){
                datos2.codigo_concepto_ajuste=this.getField('codigo_concepto_ajuste').getValue();
                datos2.nombre_concepto=this.getField('nombre_concepto').getValue();
            }
            if(this.boton_selecionado==='detallado'){
                op='consultar_detallado_pag';
                this.nombre_archivo='listado_ajustes';
            }
            else if(this.boton_selecionado==='articulos'){
                op='consultar_articulo_pag';
                this.nombre_archivo='listado_articulos_ajustes';
            }
            else if(this.boton_selecionado==='articulo_x_grupo'){
                op='consultar_articulo_x_grupo_pag';
                this.nombre_archivo='comprasxgrupo';
            }
            else if(this.boton_selecionado==='ver_presentacion'){
                datos2={};
                op='detalleconsultajuste';
                datos2.numero=this.filaSeleccionadaConsultaAjustes[0].numero;
                datos2.codigo_sucursal=this.filaSeleccionadaConsultaAjustes[0].codigo_sucursal;
                datos2.fecha=this.filaSeleccionadaConsultaAjustes[0].fecha;
                datos2.codigo_concepto_ajuste=this.filaSeleccionadaConsultaAjustes[0].concepto+'';
                datos2.sucursal_ingreso=this.sucursal_ingreso;
                datos2.operacion=this.operacion_actual;
                datos2.email=this.getField('email').getValue();
                datos2.radio_correo=this.getField('radio_correo').getValue();
                this.nombre_archivo='ajustes';
            }else if(this.boton_selecionado==='grabar_ajuste'){
                datos2={};
                op='detalleconsultajuste';
                datos2.numero=this.getField("numero2").getValue();
                datos2.codigo_sucursal=this.codigo_sucursal;
                datos2.fecha=this.fecha_hoy;
                datos2.codigo_concepto_ajuste=this.getField("concepto_cod").getValue();
                datos2.sucursal_ingreso=this.codigo_sucursal;
                datos2.operacion=this.operacion_actual;
                datos2.email=this.getField('email').getValue();
                datos2.radio_correo=this.getField('radio_correo').getValue();
                this.nombre_archivo='ajustes';
            }else if(this.boton_selecionado==='hacer_ajuste'){
                datos2={};
                op='archivoajusteref';
                datos2.numero=this.getField("numero2").getValue();
                datos2.codigo_sucursal=this.lasucursal;
                datos2.fecha=this.fecha_hoy;
                datos2.codigo_concepto_ajuste=this.getField("concepto_cod").getValue();
                datos2.sucursal_ingreso=this.sucursal_ingreso;
                datos2.operacion=this.operacion_actual;
            }else if(this.boton_selecionado==='grabar_ajuste_cruce'){
                datos2={};
                op='detalleconsultajuste';
                datos2.numero=this.getField("numero3").getValue();
                datos2.codigo_sucursal=this.getField("codigo_sucursal1").getValue();
                datos2.fecha=this.fecha_hoy;
                datos2.codigo_concepto_ajuste=this.getField("codigo_concepto_ajuste").getValue();
                datos2.sucursal_ingreso=this.codigo_sucursal;
                datos2.operacion=this.operacion_actual;
                datos2.email=this.getField('email').getValue();
                datos2.radio_correo=this.getField('radio_correo').getValue();
                this.nombre_archivo='ajustes';
            }
            else
                op='';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-ajustesinventario","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={
                consultar_desde: this.getField('consultar_desde').getValue(),
                consultar_hasta: this.getField('consultar_hasta').getValue(),
                estado_ch: this.ch_todos,
                sucursal_ingreso:this.sucursal_ingreso,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' ){
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                datos2.nombre_sucursal=this.getField('nombre_sucursal').getValue();
            }
            if(this.getField('codigo_concepto_ajuste').getValue()!=='' && this.getField('codigo_concepto_ajuste').getValue()!=='' ){
                datos2.codigo_concepto_ajuste=this.getField('codigo_concepto_ajuste').getValue();
                datos2.nombre_concepto=this.getField('nombre_concepto').getValue();
            }
            if(this.boton_selecionado==='detallado'){
                op='consultar_detallado_pag';
                this.nombre_archivo='listado_ajustes';
            }
            else if(this.boton_selecionado==='articulos'){
                op='consultar_articulo_pag';
                this.nombre_archivo='listado_articulos_ajustes';
            }
            else if(this.boton_selecionado==='articulo_x_grupo'){
                op='consultar_articulo_x_grupo_pag';
                this.nombre_archivo='comprasxgrupo';
            }
            else if(this.boton_selecionado==='ver_presentacion'){
                datos2={};
                op='detalleconsultajuste';
                datos2.numero=this.filaSeleccionadaConsultaAjustes[0].numero;
                datos2.codigo_sucursal=this.filaSeleccionadaConsultaAjustes[0].codigo_sucursal;
                datos2.fecha=this.filaSeleccionadaConsultaAjustes[0].fecha;
                datos2.codigo_concepto_ajuste=this.filaSeleccionadaConsultaAjustes[0].concepto+'';
                datos2.sucursal_ingreso=this.sucursal_ingreso;
                datos2.operacion=this.operacion_actual;
                datos2.email=this.getField('email').getValue();
                datos2.radio_correo=this.getField('radio_correo').getValue();
                this.nombre_archivo='ajustes';
            }else if(this.boton_selecionado==='grabar_ajuste'){
                datos2={};
                op='detalleconsultajuste';
                datos2.numero=this.getField("numero2").getValue();
                datos2.codigo_sucursal=this.codigo_sucursal;
                datos2.fecha=this.fecha_hoy;
                datos2.codigo_concepto_ajuste=this.getField("concepto_cod").getValue();
                datos2.sucursal_ingreso=this.codigo_sucursal;
                datos2.operacion=this.operacion_actual;
                datos2.email=this.getField('email').getValue();
                datos2.radio_correo=this.getField('radio_correo').getValue();
                this.nombre_archivo='ajustes';
            }else if(this.boton_selecionado==='hacer_ajuste'){
                datos2={};
                op='archivoajusteref';
                datos2.numero=this.getField("numero2").getValue();
                datos2.codigo_sucursal=this.lasucursal;
                datos2.fecha=this.fecha_hoy;
                datos2.codigo_concepto_ajuste=this.getField("concepto_cod").getValue();
                datos2.sucursal_ingreso=this.sucursal_ingreso;
                datos2.operacion=this.operacion_actual;
            }else if(this.boton_selecionado==='grabar_ajuste_cruce'){
                datos2={};
                op='detalleconsultajuste';
                datos2.numero=this.getField("numero3").getValue();
                datos2.codigo_sucursal=this.getField("codigo_sucursal1").getValue();
                datos2.fecha=this.fecha_hoy;
                datos2.codigo_concepto_ajuste=this.getField("codigo_concepto_ajuste").getValue();
                datos2.sucursal_ingreso=this.codigo_sucursal;
                datos2.operacion=this.operacion_actual;
                datos2.email=this.getField('email').getValue();
                datos2.radio_correo=this.getField('radio_correo').getValue();
                this.nombre_archivo='ajustes';
            }
            else
                op='';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-ajustesinventario","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let op='';
        let datos={ datos: {
            consultar_desde: this.getField('consultar_desde').getValue(),
            consultar_hasta: this.getField('consultar_hasta').getValue(),
            estado_ch: this.ch_todos,
            sucursal_ingreso:this.sucursal_ingreso,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual
        }};
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' ){
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            datos.datos.nombre_sucursal=this.getField('nombre_sucursal').getValue();
        }
        if(this.getField('codigo_concepto_ajuste').getValue()!=='' && this.getField('codigo_concepto_ajuste').getValue()!=='' ){
            datos.datos.codigo_concepto_ajuste=this.getField('codigo_concepto_ajuste').getValue();
            datos.datos.nombre_concepto=this.getField('nombre_concepto').getValue();
        }
        if(this.boton_selecionado==='detallado'){
            op='consultar_detallado_pag';
        }
        else if(this.boton_selecionado==='articulos'){
            op='consultar_articulo_pag';
        }
        else if(this.boton_selecionado==='articulo_x_grupo'){
            op='consultar_articulo_x_grupo_pag';
        }
        else if(this.boton_selecionado==='ver_presentacion'){
            datos.datos={};
            op='detalleconsultajuste';
            datos.datos.numero=this.filaSeleccionadaConsultaAjustes[0].numero;
            datos.datos.codigo_sucursal=this.filaSeleccionadaConsultaAjustes[0].codigo_sucursal;
            datos.datos.fecha=this.filaSeleccionadaConsultaAjustes[0].fecha;
            datos.datos.codigo_concepto_ajuste=this.filaSeleccionadaConsultaAjustes[0].concepto+'';
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
        }else if(this.boton_selecionado==='grabar_ajuste'){
            datos.datos={};
            op='detalleconsultajuste';
            datos.datos.numero=this.getField("numero2").getValue();
            datos.datos.codigo_sucursal=this.codigo_sucursal;
            datos.datos.fecha=this.fecha_hoy;
            datos.datos.codigo_concepto_ajuste=this.getField("concepto_cod").getValue();
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
        }else if(this.boton_selecionado==='hacer_ajuste'){
            datos.datos={};
            op='archivoajusteref';
            datos.datos.numero=this.getField("numero2").getValue();
            datos.datos.codigo_sucursal=this.lasucursal;
            datos.datos.fecha=this.fecha_hoy;
            datos.datos.codigo_concepto_ajuste=this.getField("concepto_cod").getValue();
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
        }else if(this.boton_selecionado==='grabar_ajuste_cruce'){
            datos.datos={};
            op='detalleconsultajuste';
            datos.datos.numero=this.getField("numero3").getValue();
            datos.datos.codigo_sucursal=this.getField("codigo_sucursal1").getValue();
            datos.datos.fecha=this.fecha_hoy;
            datos.datos.codigo_concepto_ajuste=this.getField("codigo_concepto_ajuste").getValue();
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
        }
        else
            op='';
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }


    //termina ander
   
    
    ////////////////////////////cruce ajustes///////////////////////////////////////////
    cruceAjuste(){
        
        console.log("Formulario CustomAjustesInventario,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        this.getField("codigo_sucursal1").setVisible(true);
        this.getField("nombre_sucursal1").setVisible(true);
        this.getField("nombre_sucursal1").setOnChange(this.limpiarcampos);
        this.getField("fecha1").setVisible(true);
        this.getField("fecha1").setOnChange(this.limpiarcampos);
        this.getField("numero3").setVisible(true);
        this.getField("numero3").setOnChange(this.limpiarcampos);
        this.getField("bodega1").setVisible(true);
        this.getField("nombre_bodega1").setVisible(true);
        this.getField("concepto_cod1").setVisible(true);
        this.getField("nombre_c1").setVisible(true);
        this.getField("bodega1").setDisabled(true);
        this.getField("nombre_bodega1").setDisabled(true);
        this.getField("concepto_cod1").setDisabled(true);
        this.getField("nombre_c1").setDisabled(true);
        this.getField("detalle").setVisible(true)
        this.getField("subtotal").setVisible(true)
        this.getField("valor_iva").setVisible(true)
        this.getField("impuestos").setVisible(true)
        this.getField("valor_ajuste").setVisible(true)
        this.getField("subtotal").setDisabled(true);
        this.getField("valor_iva").setDisabled(true);
        this.getField("impuestos").setDisabled(true);
        this.getField("valor_ajuste").setDisabled(true);
        this.getField("cruzar").setVisible(true)
        this.getField('cruzar').setClick(()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Confirmación', `¿De verdad desea grabar los ajustes?`);
            this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.grabarAjustecruce();
            });
            this.getField('confirmModalCustom').setClickCancelDialog(() => {
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            
        });
        this.getField('generar_archivo').setClick(()=>this.procesar());
        this.getField('modal_imprimir').setCloseButton(()=>{
            this.limpiarCruce();
            this.cruceAjuste();
        });

        this.getField("numero3").setOnBlur(this.traerAjuste);
        this.getField("bodega1").setOnChange(this.traerItemAjuste);

        this.valor_baseg=0;
        this.disponible1=0;
        this.valor_iva=0;
        this.impuestos=0;
        this.valor_basee=0;
        this.total=0;
        this.error=false;

    }
   
    traerAjuste(){
        if(this.getField('nombre_sucursal1').getValue() !== '' && this.getField('fecha1').getValue() !== '' && this.getField('numero3').valid()){
            let datos = { datos: {
                codigo_sucursal1: this.getField('codigo_sucursal1').getValue(),
                numero3: this.getField('numero3').getValue(),
                fecha1: this.getField('fecha1').getValue()
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'buscarajusteconcidencia', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerAjuste,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.getField('numero3').setError(true,'este campo es requerido.')
        }
    }

    successTraerAjuste(data){
        if (data.estado_p === 200 )
        {
            this.getField('modal_ajuste').handleClickOpen();

            this.gridOptionsComponentes2['rowData'] = [];
            let configCell = new Map();
            configCell.set('numero', { headerName: 'Número'});
            this.getField('rejilla_ajuste').toggle(true);

            this.gridOptionsComponentes2['rowData'] = data.data;
            this.gridOptionsComponentes2['onSelectionChanged'] = ()=>{
                this.selectedRows = [];
                this.selectedRows = this.gridOptionsComponentes2.api.getSelectedRows();
                this.getField("numero3").setValue(this.selectedRows[0].numero);
                this.getField("concepto_cod1").setValue(this.selectedRows[0].proveedor);
                this.getField("codigo_concepto_ajuste").setValue(this.selectedRows[0].proveedor);
                this.getField("nombre_c1").setValue(this.selectedRows[0].nproveedor);
                this.getField("bodega1").setValue(this.selectedRows[0].bodega);
                this.getField("nombre_bodega1").setValue(this.selectedRows[0].nombre_bodega);
                this.getField("detalle").setValue(this.selectedRows[0].detalle);
                this.getField("subtotal").setValue(this.selectedRows[0].subtotal);
                this.getField("valor_ajuste").setValue(this.selectedRows[0].valor);
                this.getField("impuestos").setValue(this.selectedRows[0].imp01);
                this.getField("valor_iva").setValue(this.selectedRows[0].iva);
                this.getField("modal_ajuste").handleClose();
            };
            this.getField("detalle").setError(false,"");
            this.getField("bodega1").setError(false,"");
            this.getField('rejilla_ajuste').initData(this.gridOptionsComponentes2,configCell);
        }
        else 
        {
            if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'Este ajuste no existe, no pertenece a la sucursal, no coincide con la fecha o no está pendiente.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traerItemAjuste(){
        if(this.getField("bodega1").getValue() !== ""){
            let datos = { datos: {
                codigo_sucursal1: this.getField('codigo_sucursal1').getValue(),
                codigo_usuario: this.codigo_usuario,
                numero3: this.getField('numero3').getValue(),
                fecha1: this.getField('fecha1').getValue()
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargaritemAjuste', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successItem,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successItem(data){
        if (data.estado_p === 200 )
        {
            this.getField("subtotal").setValue(0);
            this.getField("valor_iva").setValue(0);
            this.getField("impuestos").setValue(0);
            this.getField("valor_ajuste").setValue(0);

            this.gridOptionsCruces['rowData'] = [];
            let configCell = new Map();
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla1').initData(this.gridOptionsCruces,configCell);
            this.getField('rejilla1').toggle(false);
            
            this.items = data.data;
            this.getField('rejilla1').toggle(true);
            this.gridOptionsCruces['rowData'] = data.data;
            configCell.set('codigo', { headerName: 'Código'});
            configCell.set('disponible', { cellRenderer: function (props) { return props.data.disponible }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.modificarItem, sortable: true, filter: false, field: 'disponible' });
            this.getField('rejilla1').initData(this.gridOptionsCruces,configCell);

            this.valor_baseg=0;
            this.disponible1=0;
            this.valor_iva=0;
            this.impuestos=0;
            this.valor_basee=0;
            this.total=0;

            data.data.forEach((item) => {
                                    
                if(item.tarifa_iva === 0){
                    this.valor_basee += (item.costo * parseFloat(item.disponible));
                    this.valor_iva += (item.valor_iva * parseFloat(item.disponible));
                    this.impuestos += (item.impuestos * parseFloat(item.disponible));
                    this.total += item.valor_item;
                    this.valor_baseg = 0;
                    
                }

                else if(item.tarifa_iva !== 0){
                    this.valor_baseg += (item.costo * parseFloat(item.disponible));
                    this.valor_iva += (item.valor_iva * parseFloat(item.disponible));
                    this.impuestos += (item.impuestos * parseFloat(item.disponible));
                    this.total += item.valor_item;
                    this.valor_basee = 0;
                }

            })

            this.valores_globales = {
                valor_basee: this.valor_basee,
                disponible: parseFloat(this.disponible1.toFixed(2)),
                valor_iva: this.valor_iva,
                impuestos: this.impuestos,
                valor_baseg: this.valor_baseg,
                valor_ajustes: this.total
            }

            this.getField("subtotal").setValue(this.valor_baseg.toFixed(2));
            this.getField("valor_iva").setValue(this.valor_iva.toFixed(2));
            this.getField("impuestos").setValue(this.impuestos.toFixed(2));
            this.getField("valor_ajuste").setValue(this.total.toFixed(2));
                            
        }
        else 
        {
            if(data.estado_p===404) {
                this.getField('rejilla1').toggle(true);
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    modificarItem(props){
        let seguir = this.getField('rejilla1').cellValid(props.oldValue, props.newValue, /^(-?\d{1,13})(\.\d{1,2})?$/);
        if (isNaN(props.data.cantidad) || seguir===false) {

            this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `La columna disponible solo admite 13 números y 2 decimales`);
            this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); let data = [];
            let posicion = (props.node.rowIndex+1);
            let objetoParcial = {};
            let objetoFinal = {};
            let cont = 0;
            this.items.forEach((item) => {
                cont++;
                objetoParcial = {
                    codigo: item.codigo,
                    nombre: item.nombre,
                    tarifa_iva: item.tarifa_iva,
                    cantidad: item.cantidad,
                    disponible: cont === posicion? Number(props.oldValue) : Number(item.disponible),
                    costo: item.costo,
                    impuestos: item.impuestos,
                    valor_iva: item.valor_iva,
                    proveedor: item.proveedor,
                    bodega: item.bodega,
                    tipo_producto: item.tipo_producto,
                    valor_item: item.valor_item,
                    pendiente: item.pendiente
                };
                data.push(objetoParcial);
                objetoFinal = {
                    data,
                    estado_p: 200
                }
            });

            this.successItem(objetoFinal); });
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
            this.getField('confirmModalCustom').toggle(true);
        }
        else if(seguir){   
            let codigo_articulo = props.data.codigo;
            let cantidad = props.data.cantidad;
            let pendiente = props.data.pendiente;
            let cantidad_permitida = cantidad - pendiente;
            
            let valor = props.newValue;
            if (valor <= cantidad_permitida) {
                
                let valor_item=(props.data.costo+props.data.valor_iva+props.data.impuestos)*valor;
                let disponible = valor;
                let posicion = (props.node.rowIndex+1);
                let data = [];
                let objetoParcial = {};
                let objetoFinal = {};
                let cont = 0;
                this.items.forEach((item) => {
                    cont++;
                    objetoParcial = {
                        codigo: item.codigo,
                        nombre: item.nombre,
                        tarifa_iva: item.tarifa_iva,
                        cantidad: item.cantidad,
                        disponible: item.codigo === codigo_articulo && cont === posicion? Number(disponible) : Number(item.disponible),
                        costo: item.costo,
                        impuestos: item.impuestos,
                        valor_iva: item.valor_iva,
                        proveedor: item.proveedor,
                        bodega: item.bodega,
                        tipo_producto: item.tipo_producto,
                        valor_item: item.codigo === codigo_articulo && cont === posicion ? valor_item : item.valor_item,
                        pendiente: item.pendiente
                    };
                    data.push(objetoParcial);
                    objetoFinal = {
                        data,
                        estado_p: 200
                    }
                });

                this.successItem(objetoFinal);

            } else {
                this.getField('confirmModalCustom').setTitleAndContent('Error', `La cantidad máxima que puede devolver del artículo ` + codigo_articulo + ` es ` + cantidad_permitida);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); 
                let posicion = (props.node.rowIndex+1);
                let data = [];
                let objetoParcial = {};
                let objetoFinal = {};
                let cont = 0;
                this.items.forEach((item) => {
                    cont++;
                    objetoParcial = {
                        codigo: item.codigo,
                        nombre: item.nombre,
                        tarifa_iva: item.tarifa_iva,
                        cantidad: item.cantidad,
                        disponible: cont === posicion? Number(props.oldValue) : Number(item.disponible),
                        costo: item.costo,
                        impuestos: item.impuestos,
                        valor_iva: item.valor_iva,
                        proveedor: item.proveedor,
                        bodega: item.bodega,
                        tipo_producto: item.tipo_producto,
                        valor_item: item.valor_item,
                        pendiente: item.pendiente
                    };
                    data.push(objetoParcial);
                    objetoFinal = {
                        data,
                        estado_p: 200
                    }
                });

                this.successItem(objetoFinal);});
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
                this.error = false;
            }
        }
    }
  
    grabarAjustecruce(){
        if(this.error === false) 
        if(this.getField("bodega1").valid() && this.getField("nombre_bodega1").valid() && this.getField("concepto_cod1").valid()
        && this.getField("nombre_c1").valid() &&  this.getField("detalle").valid()){
            
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            let datos = { datos: {
                pantalla: this.pantalla,
                codigo_sucursal1:this.getField("codigo_sucursal1").getValue(),
                codigo_usuario:this.codigo_usuario,
                concepto_cod: this.getField("concepto_cod1").getValue(),
                bodega: this.getField("bodega1").getValue(),
                nombre_c: this.getField("nombre_c1").getValue(),
                numero3: this.getField("numero3").getValue(),
                fecha1: this.getField("fecha1").getValue(),
                detalle: this.getField("detalle").getValue(),
                impuestos: this.getField("impuestos").getValue(),
                valor_iva: this.getField("valor_iva").getValue(),
                valor_ajuste: this.getField("valor_ajuste").getValue(),
                items: this.items,
                valores_globales:this.valores_globales

            }};
        
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'grabar_cruce', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successGrabarAjuste,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe diligenciar todos los campos ', 'error');
        }
    }

    successGrabarAjuste(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {    
            this.numero_consecutivo = data.data.numero;
            this.getField('confirmModalCustom').setTitleAndContent('Grabación Ajuste', `Ajuste de cruce grabado con el número ` + this.numero_consecutivo);
            this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false);this.cambiarestado();});
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
            this.getField('confirmModalCustom').toggle(true);
        }else{
            this.alertGeneral.toggle(true,'Error: '+data.data.errores.mensaje, 'error');
            this.getField('confirmModalCustom').toggle(false);       
        }
    }

    cambiarestado(){
        let datos = { datos: {
            sucursal: this.getField("codigo_sucursal1").getValue(),
            numero3: this.getField("numero3").getValue(),
            fecha1: this.getField("fecha1").getValue()
        }};
    
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cambiar_estado', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successCambiarEstado,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successCambiarEstado(data){
        if (data.estado_p === 200 )
        {    
            this.getField('confirmModalCustom').toggle(false);
            this.getField("numero3").setValue(this.numero_consecutivo);       
            this.boton_selecionado='grabar_ajuste_cruce';
            this.abrirImprimir();
        }else{
            this.alertGeneral.toggle(true,'Error Ajuste no grabado.', 'error');
            this.getField('confirmModalCustom').toggle(false);       
        }
    }

    limpiarCruce(){
        this.getField("codigo_sucursal1").setValue("");
        this.getField("nombre_sucursal1").setValue("");
        this.getField("bodega1").setValue("");
        this.getField("numero3").setValue("");
        this.getField("nombre_bodega1").setValue("");
        this.getField("concepto_cod1").setValue("");
        this.getField("nombre_c1").setValue("");
        this.getField("detalle").setValue("");
        this.getField("subtotal").setValue(0)
        this.getField("valor_iva").setValue(0)
        this.getField("impuestos").setValue(0)
        this.getField("valor_ajuste").setValue(0)

        this.gridOptionsCruces['rowData'] = [];
        let configCell = new Map();
        this.getField('rejilla1').initData(this.gridOptionsCruces,configCell);
        this.getField('rejilla1').toggle(false);
            

    }

    limpiarcamposGrabar(){
            this.gridOptionsCruces['rowData'] = [];
            let configCell = new Map();
            this.getField('rejilla1').initData(this.gridOptionsCruces,configCell);
            this.getField('rejilla1').toggle(false);
            this.getField("agregar_articulo").setVisible(false);
            this.getField("consecutivo").setValue("");
            this.getField("detalle").setValue("");
            this.getField("detalle").setError(false,'');
            this.getField("subtotal").setValue(0)
            this.getField("valor_iva").setValue(0)
            this.getField("impuestos").setValue(0)
            this.getField("valor_ajuste").setValue(0)
    }

    limpiarcampos(){
        if(this.getField("nombre_bodega1").getValue() !== ""){

            this.gridOptionsCruces['rowData'] = [];
            let configCell = new Map();
            this.getField('rejilla1').initData(this.gridOptionsCruces,configCell);
            this.getField('rejilla1').toggle(false);

            this.getField("bodega1").setValue("");
            this.getField("nombre_bodega1").setValue("");
            this.getField("concepto_cod1").setValue("");
            this.getField("nombre_c1").setValue("");
            this.getField("detalle").setValue("");
            this.getField("subtotal").setValue(0)
            this.getField("valor_iva").setValue(0)
            this.getField("impuestos").setValue(0)
            this.getField("valor_ajuste").setValue(0)
    
        }
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

}
FormJaivana.addController("inve-ajustesinventario", CustomAjustesInventario);
export default CustomAjustesInventario;