import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomReteicaCompras
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0001
 **/
class CustomReteicaCompras extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;

    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.sucessconsultarDatos                                  = this.sucessconsultarDatos.bind(this);
    this.consultarDatos                                  = this.consultarDatos.bind(this);
    this.validarConsultar                                  = this.validarConsultar.bind(this);
    this.validarCamposFecha                                  = this.validarCamposFecha.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
    this.mostrarMensajeGuardando                                  = this.mostrarMensajeGuardando.bind(this);
    this.ocultarCampos                                  = this.ocultarCampos.bind(this);

    this.validarLiquidarFactura                                  = this.validarLiquidarFactura.bind(this);
    this.successLiquidarFactura                                  = this.successLiquidarFactura.bind(this);
    this.validarContabilizar                                  = this.validarContabilizar.bind(this);
    this.sucessContabilizar                                  = this.sucessContabilizar.bind(this);
    this.validarLiquidarTodo                                  = this.validarLiquidarTodo.bind(this);
    this.sucessLiquidarTodo                                  = this.sucessLiquidarTodo.bind(this);


    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.fecha_inicio='';
    this.dias_maximo='';
    this.fila_seleccionada=[];
  }
  
  initForm() {
    console.log("Formulario CustomReteicaCompras,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    //nuevo para consulta
    this.getField('consultar_desde').setOnChange(()=>{this.validarCamposFecha();});
    this.getField('consultar_hasta').setOnChange(()=>{this.validarCamposFecha();});
    this.getField('consultar').setClick(this.validarConsultar);

    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('consultar_desde').setValue(this.fecha_inicio);

    //botones
    this.getField('bt_liquidar_factura').setClick(()=>{this.validarLiquidarFactura();});
    this.getField('bt_liquidar_todo').setClick(()=>{this.validarLiquidarTodo();});
    this.getField('bt_contabilizar').setClick(()=>{this.validarContabilizar();});


  }

  sucessLiquidarTodo(data) {
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar);
        this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);this.consultarDatos();});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }

  validarLiquidarTodo(){
        this.mostrarMensajeGuardando();
        let datos={ datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-reteicacompras', operacion: 'liquidarfacturatodos', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessLiquidarTodo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
  }


  sucessContabilizar(data) {
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar);
        this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);this.consultarDatos();});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }

  validarContabilizar(){
    if(this.fila_seleccionada.length>0){
        this.mostrarMensajeGuardando();
        let datos={ datos: {
          consultar_desde:this.getField('consultar_desde').getValue(),
          consultar_hasta:this.getField('consultar_hasta').getValue(),
          id_factura:this.fila_seleccionada[0].id+''
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-reteicacompras', operacion: 'contabilizar', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessContabilizar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }else{
        this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    }
  }

  successLiquidarFactura(data) {
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p === 200){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar);
        this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);this.consultarDatos();});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }else {
      if(data.estado_p===404) {
        this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
      }
      else {
        let respuesta=Object.values(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
      }
    }
  }

  validarLiquidarFactura(){
    if(this.fila_seleccionada.length>0){
        let datos={ datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
            id_factura:this.fila_seleccionada[0].id+''
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-reteicacompras', operacion: 'liquidarfactura', operacion_tipo: 'modificar' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.successLiquidarFactura,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
    }else{
        this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    }
  }

    ocultarCampos(){
        this.fila_seleccionada=[];
        this.getField('bt_liquidar_factura').setVisible(false);
        this.getField('bt_liquidar_todo').setVisible(false);
        this.getField('bt_contabilizar').setVisible(false);
        this.getField('tabla_reteica').toggle(false);
    }

  traerPropiedades(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'comp-reteicacompras', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
    }

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
            this.dias_maximo=data.data.reteica_compras_dias_devolver;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        this.validarCamposFecha();
    }

  validarCamposFecha(){
    this.getField('consultar').setDisabled(false);
    this.getField("consultar_desde").setError(false,'');
    this.getField("consultar_hasta").setError(false,'');
    
    this.ocultarCampos();
    let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
    let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
    let fecha_actual = new Date().toISOString().split('T')[0];
    if (fechaInicial > fechaFinal) {
        this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
        this.getField('consultar').setDisabled(true);
        return false;
    } 

    if(fechaFinal > fecha_actual){
        this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
        this.getField('consultar').setDisabled(true);
        return false;
    }
    if(fechaFinal > fecha_actual){
        this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
        this.getField('consultar').setDisabled(true);
        return false;
    }
    let fecha=new Date(fecha_actual);
    fecha.setDate(fecha.getDate()-this.dias_maximo);
    fecha=fecha.toISOString().split('T')[0];
    if(fechaInicial < fecha){
        this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
        this.getField('consultar').setDisabled(true); 
        return false;
    }

    this.getField('consultar_hasta').setError(false,"");
    return true;
    }

  validarConsultar(){
    this.ocultarCampos();

    let errores=0;
    if(this.validarCamposFecha()===false)
        errores++;
    
    if(errores===0){
        this.consultarDatos();
    }

  }

  onSelectionChanged(){
    this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
  }

  consultarDatos(){
        this.fila_seleccionada=[]
        this.mostrarMensajeGenerando();
        this.getField('consultar').setDisabled(true);
        let datos={ datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
        }};
        this.getField("tabla_reteica").setTipoServicioOperacion("comp-reteicacompras-consultar");
        this.getField("tabla_reteica").sendRequest(this.sucessconsultarDatos, datos);
    }

    sucessconsultarDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            let configCell = new Map();
            configCell.set('valor_compra', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_compra) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('basee', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.basee) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('baseg', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.baseg) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('rete_ica', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.rete_ica) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('id', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.id) }, hide: true});

            this.getField('tabla_reteica').toggle(true);

            //Se oculta segun lo hablado con don juan, ya que son botones que no se usan
            //this.getField('bt_liquidar_factura').setVisible(true);
            //this.getField('bt_liquidar_todo').setVisible(true);
            //this.getField('bt_contabilizar').setVisible(true);
            this.gridOptionsComponentes['rowData'] = [];
            
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('tabla_reteica').initData(this.gridOptionsComponentes,configCell);
            
        }else{
            this.ocultarCampos();
            if(data.estado_p===404) {
                this.mostrarMensajeNoHayDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);
    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }


    mostrarMensajeGuardando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
   
}


FormJaivana.addController("comp-reteicacompras", CustomReteicaCompras);
export default CustomReteicaCompras;