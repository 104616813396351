import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomCuadreDeCajaEspecifico
 * @author: Anderson Acevedo
 * @version: jdesk_1.01.0001.1
 **/



class CustomCuadreDeCajaEspecifico extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.onSelectionChanged                                     = this.onSelectionChanged.bind(this);
        this.habilitar                                              = this.habilitar.bind(this);
        this.validarfechas                                          = this.validarfechas.bind(this);
        this.procesar                                               = this.procesar.bind(this);
        this.cargarFechas                                           = this.cargarFechas.bind(this);
        this.limpiarErrores                                         = this.limpiarErrores.bind(this);
        this.validarCamposCuadreCaja                                = this.validarCamposCuadreCaja.bind(this);
        this.crearTabla                                             = this.crearTabla.bind(this);
        this.sucessCrearTabla                                       = this.sucessCrearTabla.bind(this);
        this.generarDatosCaja                                       = this.generarDatosCaja.bind(this);
        this.genearModalCaja                                        = this.genearModalCaja.bind(this);
        this.sucessGenearModalCaja                                  = this.sucessGenearModalCaja.bind(this);
        this.limpiarCampos                                          = this.limpiarCampos.bind(this);
        this.gridOptionsComponentes                                 = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes2                                 = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.gridOptionsComponentes2['onSelectionChanged']           = this.onSelectionChanged;
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                                      = this.formatNumberSaldo.bind(this);
        this.seleccionValor                                         = this.seleccionValor.bind(this);
        this.successDeleteTmp                                       = this.successDeleteTmp.bind(this);
        this.limpiarTemporal                                        = this.limpiarTemporal.bind(this);
        this.deshabilitarCorreo                                     = this.deshabilitarCorreo.bind(this);
        this.habilitarCorreo                                        = this.habilitarCorreo.bind(this);
        this.operacion_actual                                       = '';
        this.fecha_hoy='';
        this.fecha_actual='';
        this.nombre_pdf='';
        this.nombre_excel='';
        this.enviarCorreoTerceros                                   = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo                            = this.successEnviarCorreo.bind(this);
        this.generarPdfTabla3                                       = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3                                     = this.generarExcelTabla3.bind(this);
        this.seleccionValorCredito                                  = this.seleccionValorCredito.bind(this);
        this.isJson                                                 = this.isJson.bind(this);
        this.sucessGenerarDatosCaja                                 = this.sucessGenerarDatosCaja.bind(this);
        this.mostrarMensajeEmailEnviado                             = this.mostrarMensajeEmailEnviado.bind(this);
        this.traerDias                                              = this.traerDias.bind(this);
        this.successTraerDias                                       = this.successTraerDias.bind(this);
        this.dias_maximo=0;

    }



    initForm() {
        console.log("Formulario CustomCuadreDeCajaEspecifico,  @version: jdesk_1.01.0001.1, @author: Anderson Acevedo ");
        this.cargarFechas();
        this.getField("fecha_desde").setOnChange(this.validarfechas);
        this.getField("fecha_hasta").setOnChange(this.validarfechas);
        this.getField("fecha_hasta").setDisabled(true);
        this.getField("ch_niif").setOnChange(this.habilitar);
        this.getField('radio_correo').setValue("N");
        
        this.getField('fecha_dia').setDisabled(true);
        this.getField('saldo_anterior').setDisabled(true);
        this.getField('total_dia_anterior').setDisabled(true);
        this.getField('total_caja').setDisabled(true);
        this.getField('total_salidas_de_caja').setDisabled(true);
        this.getField('total_saldo_caja').setDisabled(true);
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        this.traerDias();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;

        this.getField('procesar').setClick(this.procesar);
        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
              this.getField('ch_pdf').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='excel';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pdf').setValueSwitch (false);
              this.operacion_actual='pantalla';
              this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
          });
          this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-cuadredecaja', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.cuadre_caja_dias_devolver;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    onSelectionChanged(){
        
    }


    habilitar(){
 
    }

    validarfechas(){
        this.habilitar();
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("fecha_desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("fecha_hasta").getValue()).toISOString().split('T')[0];

        this.getField("fecha_desde").setError(false,"");
        this.getField("fecha_hasta").setError(false,"");

        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_hasta > fecha_actual){
            this.getField("fecha_hasta").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }
        
        let fecha=new Date(fecha_actual);
        fecha.setDate(fecha.getDate()-this.dias_maximo);
        fecha=fecha.toISOString().split('T')[0];
        if(fecha_desde < fecha){
            this.getField("fecha_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        this.getField('procesar').setDisabled(false); 
        return true;

    }


    cargarFechas(){

        //let fecha_desde = this.getField('fecha_desde').getValue();
        //let fecha_desde_nueva = fecha_desde.split('-',2)[0]+'-'+fecha_desde.split('-',2)[1]+'-01';
        this.getField('fecha_desde').setValue(this.fecha_hoy);
    }


    procesar(){
        this.limpiarErrores();
        this.validarCamposCuadreCaja();
    }
    validarCamposCuadreCaja(){
        let errores =0;
        
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            this.crearTabla();
        }
    }

    crearTabla(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
        }};
            this.generalFormatPmv = { tipo_servicio: 'cont-cuadresespecificos', operacion: 'creartemporal', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessCrearTabla,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    sucessCrearTabla(data){
        if (data.estado_p === 200 )
        {
            this.generarDatosCaja();
        }
        else 
        {
          this.limpiarCampos();
          this.getField('procesar').setDisabled(false);
          this.getField('confirmModalCustom').toggle(false);
          if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
          }
        }
        
    }

    generarDatosCaja(){
        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            ch_niif:this.getField('ch_niif').getValue()
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-cuadresespecificos', operacion: 'generardatos', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessGenerarDatosCaja,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    sucessGenerarDatosCaja(data){
          if (data.estado_p === 200 )
          {
                if(this.operacion_actual!==''){
                    this.operacion_normal='cuentas_con_terceros';
                    if(this.getField('radio_correo').getValue()==='S'){
                        this.enviarCorreoTerceros();
                    }else{
                        this.nombre_pdf='otroscuadresespecificos';
                        this.nombre_excel='otroscuadresespecificos';
                        if(this.operacion_actual==='pdf'){
                            this.generarPdfTabla3();
                        }else if(this.operacion_actual==='pantalla'){
                          this.genearModalCaja();
                        }else if(this.operacion_actual==='excel'){
                          this.generarExcelTabla3();
                        } 
                    }
                  }else{
                    this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                    this.getField('confirmModalCustom').toggle(false);
                  } 
          }
          else 
          {
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                this.limpiarCampos();
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
          }
          
      }

      genearModalCaja(){
        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            ch_niif:this.getField('ch_niif').getValue(),
            operacion: this.operacion_actual,
            email:this.getField('email').getValue(),
            sucursal_ingreso:this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-cuadresespecificos', operacion: 'generarreporte', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessGenearModalCaja,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    generarPdfTabla3()
    {       
            let url = this.constant.formConfiguration();
            let operacion='generarreporte';
            let datos2= {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                ch_niif:this.getField('ch_niif').getValue(),
                operacion: this.operacion_actual,
                email:this.getField('email').getValue(),
                sucursal_ingreso:this.codigo_sucursal
            };
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-cuadresespecificos","operacion":"'+operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                            this.limpiarCampos();
                            this.limpiarTemporal();
                        } 
                    });
                }else{
                    this.getField('procesar').setDisabled(false);
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
            }).catch(err => {
                this.limpiarTemporal();
                this.getField('procesar').setDisabled(false);
                this.alertGeneral.toggle(true,err,'error')
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
            let url = this.constant.formConfiguration();
            let datos2= {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                ch_niif:this.getField('ch_niif').getValue(),
                operacion: this.operacion_actual,
                email:this.getField('email').getValue(),
                sucursal_ingreso:this.codigo_sucursal
            };
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-cuadresespecificos","operacion":"generarreporte","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                this.limpiarCampos();
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_excel+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                            this.limpiarCampos();
                            this.limpiarTemporal();
                        } 
                    });
                }else{
                    this.limpiarTemporal();
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
            }).catch(err => {
                this.limpiarCampos();
                this.limpiarTemporal();
                this.alertGeneral.toggle(true,err,'error')
            });
    }

    limpiarTemporal(){
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-cuadresespecificos', operacion: '7_1', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp(data){
        //tmp_conta1_codigousuario
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    sucessGenearModalCaja(data){
        this.limpiarTemporal();
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let data2 = 
                    {
                        "empresa": data.data.lista.nombre_empresa,
                        "nit": data.data.lista.nit,
                        "direccion": data.data.lista.direccion,
                        "pbx": data.data.lista.pbx,
                        "operacion": 'Otros Cuadres(específicos)',
                        //"desde-hasta": this.getField("fecha_desde").getValue().replaceAll('-','/')+"-"+this.getField("fecha_hasta").getValue().replaceAll('-','/'),
                    };
            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('fecha_dia').setValue(this.getField('fecha_desde').getValue().replaceAll('-','/'));
            this.getField('saldo_anterior').setValue(this.currencyFormatterGeneral(data.data.lista.saldo_anterior));
            this.getField('total_dia_anterior').setValue(this.currencyFormatterGeneral(data.data.lista.total_dia_anterior));
            this.getField('total_caja').setValue(this.currencyFormatterGeneral(data.data.lista.total_caja));
            this.getField('total_salidas_de_caja').setValue(this.currencyFormatterGeneral(data.data.lista.total_salidas));
            this.getField('total_saldo_caja').setValue(this.currencyFormatterGeneral(data.data.lista.total_saldo));
            

            this.getField('tabla_caja').toggle(true);
            let configCell = new Map();
            configCell.set('valor',{cellStyle: {textAlign:"right"},cellRenderer: this.seleccionValor,field: 'valor'});
            configCell.set('valor_credito',{cellStyle: {textAlign:"right"},cellRenderer: this.seleccionValorCredito,field: 'valor_credito'});
            this.gridOptionsComponentes['rowData'] = data.data.datos_cajas;
            this.getField('tabla_caja').initData(this.gridOptionsComponentes, configCell);

            this.getField('tabla_conceptos').toggle(true);
            let configCell2 = new Map();
            configCell2.set('valor',{cellStyle: {textAlign:"right"},cellRenderer: this.seleccionValor,field: 'valor'});
            this.gridOptionsComponentes2['rowData'] = data.data.conceptos;
            this.getField('tabla_conceptos').initData(this.gridOptionsComponentes2, configCell2);


            this.getField('modal_caja').handleClickOpen();

            this.limpiarCampos();
        }else if (data.estado_p === 404){
            this.getField('procesar').setDisabled(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else{
            this.limpiarCampos();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        
    }

    limpiarCampos(){
        this.getField('radio_correo').setValue("N");
        this.getField('procesar').setDisabled(false);
        this.getField('fecha_desde').setValue(this.fecha_hoy);
        this.getField('fecha_hasta').setValue(this.fecha_hoy);
        this.getField('ch_niif').setValue(false);
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setValue('');
        this.habilitarCorreo();
        this.limpiarErrores();
        this.cargarFechas();
    }

      limpiarErrores(){
        this.getField('radio_correo').setError(false,'');
        this.getField('email').setError(false,'');
      }

    seleccionValor(props){
        let valor = props.data.valor;

        let div = document.createElement('div');
        div.setAttribute("class","divStyle6");
        div.textContent = ""+this.currencyFormatterGeneral(valor);//saldo;
        return this.createElementJaivana(div);
    
    }

    seleccionValorCredito(props){
        let valor = props.data.valor_credito;

        let div = document.createElement('div');
        div.setAttribute("class","divStyle6");
        if(!isNaN(valor))
            div.textContent = ""+this.currencyFormatterGeneral(valor);//saldo;
        else
            div.textContent = "";//saldo;
        return this.createElementJaivana(div);
    
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    enviarCorreoTerceros(){

        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            ch_niif:this.getField('ch_niif').getValue(),
            operacion: this.operacion_actual,
            email:this.getField('email').getValue(),
            sucursal_ingreso:this.codigo_sucursal,
            radio_correo: this.getField('radio_correo').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-cuadresespecificos', operacion: 'generarreporte', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successEnviarCorreo,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
    }
    
    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
            this.limpiarCampos();
        }else{
            this.mostrarMensajeEmailFallo();
            this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        this.limpiarTemporal();
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    

}
FormJaivana.addController("cont-cuadresespecificos", CustomCuadreDeCajaEspecifico);
export default CustomCuadreDeCajaEspecifico;