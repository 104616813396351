import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomEliminarDocumentos
 * @author: Santiago Hernández
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomEliminarDocumentos extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);

        this.abrirTbPrincipal           = this.abrirTbPrincipal.bind(this);
        this.successMostrarTbPrincipal  = this.successMostrarTbPrincipal.bind(this);
        this.gridOptionsTbPrincipal     = Object.assign({},this.gridOptions);
        this.crearBotones               = this.crearBotones.bind(this);
        this.crearBotonProcesar         = this.crearBotonProcesar.bind(this);
        this.accionProcesar             = this.accionProcesar.bind(this);
        this.successBuscar              = this.successBuscar.bind(this);        
        this.arrayTbPrincipal           = [];
        this.arrayTbPrincipalCopia      = [];
        this.crearBotones2              = this.crearBotones2.bind(this);    
        this.abrirTbPrincipal2          = this.abrirTbPrincipal2.bind(this);  
        this.crearBotonProcesar2        = this.crearBotonProcesar2.bind(this); 
        this.LimpiarCampos              = this.LimpiarCampos.bind(this);
        this.suma                       = 0;
        this.clickButtonEliminar        = this.clickButtonEliminar.bind(this);
        this.estadoTabla                = false;
        this.successEliminar            = this.successEliminar.bind(this);
    }

    initForm(){
        console.log('Formulario CustomEliminarDocumentos,  @version: jdesk_1.01.0001, @author:Santiago Hernández N, @updateBy Jose Albeiro Marin')      

        this.getField('documentos_id').setOnChange(this.abrirTbPrincipal);    
        this.getField('codigo_documento').setOnChange(this.LimpiarCampos);
        this.getField('eliminar').setDisabled(true);
        this.getField('eliminar').setClick(this.clickButtonEliminar);
 
    }
    
    LimpiarCampos(){
        this.getField('tb_base_modificacion').toggle(false);
        this.arrayTbPrincipal = [];
        this.arrayTbPrincipalCopia = [];    
        this.getField('eliminar').setDisabled(true);        
    }


    abrirTbPrincipal(){
        if (this.getField('nombre_documento').getValue() !== '' && this.getField('documentos_id').getValue() !== '') {            
            let datos = { datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-eliminardocumentos', operacion: 'buscarxtablaorigen', operacion_tipo: 'consulta' };
            this.service.send(
            {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successMostrarTbPrincipal,
                  error: this.error_,
                  general: this.generalFormatPmv
            });
        }else{

        }

    }

    successMostrarTbPrincipal(data){
        if(data.estado_p === 200){
            this.arrayTbPrincipal = data.data;
            this.getField('tb_base_modificacion').toggle(true);
            this.gridOptionsTbPrincipal['rowData'] = this.arrayTbPrincipal;
            let configCell = new Map();
            configCell.set('tabla', { cellRenderer: (props) => { return props.data.tabla_destino } });
            configCell.set('accion', { cellRenderer: (e) => { return this.crearBotones(e) }, field: 'accion', width: 250 });
            this.getField('tb_base_modificacion').initData(this.gridOptionsTbPrincipal,configCell);            
        }else{
            this.getField('tb_base_modificacion').toggle(false);
        }
    }

        /**
     * Se encarga de establecer el estilo de los botones de acuerdo al estado de los registros de la tabla
     * @param {La información que contiene el registro seleccionado} props 
     * @returns Un objeto de tipo fragment que contiene los dos botones (Buscar y modificar)
     * para renderizarlo en la tabla.
     */
    crearBotones(props) {
        let fragment = document.createDocumentFragment();
        let button = this.crearBotonProcesar(props.data, 'Buscar');
        let text1 = this.crearBotonProcesar(props.data, 'Text1');

        button.setAttribute("class", "buttonStyle2");                       
   
        fragment.appendChild(button);
        fragment.appendChild(text1);

        return this.createElementJaivana(fragment);
    }

    crearBotonProcesar(data, boton) {
        let button = document.createElement('input');
        switch (boton) {

            case 'Buscar':
                button.setAttribute("id", `boton_buscar_${data.id}`);
                button.setAttribute("class", "buttonStyle");
                button.setAttribute("type", "button");
                button.setAttribute("value", "Buscar");
                button.onclick = () => { this.accionProcesar(data, boton) };
                break;

            case 'Text1':
                button.setAttribute("class","textStyle1");
                button.setAttribute("type", "text");
                button.setAttribute("disabled", false);
                //button.onblur  = () => { this.accionProcesar2(data, boton) };                  
                break;


            default:
            break;
        }

        
        return button;

    }

    accionProcesar(data, boton) {
        this.id = data.id;         
        if (boton === 'Buscar'){
            let datos = { datos: {
                tabla_destino: data.tabla_destino,
                campo_destino_1: data.campo_destino_1,
                codigo_documento: this.getField('codigo_documento').getValue()
            }};
              this.generalFormatPmv = { tipo_servicio: 'cont-eliminardocumentos', operacion: 'buscarxtabladestino', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.successBuscar,
                      error: this.error_,
                      general: this.generalFormatPmv
              });

        }
    }

    successBuscar(data){
        if(data.estado_p === 200){
            this.arrayTbPrincipalCopia = [];
            this.arrayTbPrincipal.forEach(item => {
                if (item.id===this.id){
                    item["text1"]=data.data[0].xcuantos  
                    item["text2"]=data.data[0].count               
                }
                this.arrayTbPrincipalCopia.push(item);
            });

            this.abrirTbPrincipal2(this.arrayTbPrincipalCopia);
        
        }else{
            this.alertGeneral.toggle(true, 'No hay datos relacionados para documentos', 'error');
        }
    }


    abrirTbPrincipal2(data){
        this.suma=0;
        this.estadoTabla = false;
        this.tabla_origen = data[0].tabla_origen;
        this.campo_origen_1 = data[0].campo_origen_1;
        data.forEach(item => {        
            if (item.text1!==undefined){
                this.suma = this.suma + item.text1;                
            }else{
                this.estadoTabla = true;
            }
        });


        this.getField('tb_base_modificacion').toggle(true);
        this.gridOptionsTbPrincipal['rowData'] = data;
        let configCell = new Map();
        configCell.set('tabla', { cellRenderer: (props) => { return props.data.tabla_destino } });
        configCell.set('accion', { cellRenderer: (e) => { return this.crearBotones2(e) }, field: 'accion', width: 250 });
        this.getField('tb_base_modificacion').initData(this.gridOptionsTbPrincipal,configCell);
        this.getField('eliminar').setDisabled(false);
    }


    crearBotones2(props){
        let fragment = document.createDocumentFragment();
        let button = this.crearBotonProcesar2(props.data, 'Buscar');
        let text1 = this.crearBotonProcesar2(props.data, 'Text1');

        button.setAttribute("class", "buttonStyle2");                       
        
        fragment.appendChild(button);
        fragment.appendChild(text1);

        return this.createElementJaivana(fragment); 
    }

    crearBotonProcesar2(data, boton) {
        let button = document.createElement('input');
        switch (boton) {

            case 'Buscar':
                button.setAttribute("id", `boton_buscar_${data.id}`);
                button.setAttribute("class", "buttonStyle");
                button.setAttribute("type", "button");
                button.setAttribute("value", "Buscar");
                button.onclick = () => { this.accionProcesar(data, boton) };
                break;

            case 'Text1':
                button.setAttribute("class","textStyle1");
                button.setAttribute("type", "text");
                button.setAttribute("disabled", false);
                if (data.text1!==undefined){
                    button.setAttribute("value", data.text1);  
                } 
                break;

            default:
            break;
        }

        
        return button;
    }

    clickButtonEliminar(){
        if (this.estadoTabla===true){
            this.alertGeneral.toggle(true, 'Debe realizar todas las busquedas.', 'error');
        }else{
            // console.log('SUMA',this.suma)
            if (this.suma===0){
                let datos = { datos: {
                    tabla_origen: this.tabla_origen,
                    campo_origen_1: this.campo_origen_1,
                    codigo_documento: this.getField('codigo_documento').getValue()
                }};  
                
                this.generalFormatPmv = { tipo_servicio: 'cont-eliminardocumentos', operacion: '7', operacion_tipo: 'eliminar' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'DELETE',
                        body: datos,
                        success: this.successEliminar,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
    
            }else{
                this.alertGeneral.toggle(true, 'Los documentos existen en las tablas relacionadas. No se permite eliminar', 'error');
            }
        }

    }

    successEliminar(data){
        if(data.estado_p === 200){
            this.suma=0;
            this.alertGeneral.toggle(true, 'Registros eliminados con éxito', 'success');
            this.getField('eliminar').setDisabled(true);
            this.LimpiarCampos()
            this.getField('codigo_documento').setValue('');
            this.getField('nombre_documento').setValue('');
        }else{
            this.alertGeneral.toggle(true, 'Registros no eliminados.', 'error');
        }
    }


}
FormJaivana.addController("cont-eliminardocumentos",CustomEliminarDocumentos);
export default CustomEliminarDocumentos