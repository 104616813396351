import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomConsultarTraslados
 * @author: Santiago Hernández N
 * @version: jdesk_1.01.0002
 **/
class CustomConsultarTraslados extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                                       = this.initForm.bind(this);
        this.traerDias                                                      = this.traerDias.bind(this);
        this.successTraerDias                                               = this.successTraerDias.bind(this);
        this.validarfechasConsulta                                          = this.validarfechasConsulta.bind(this);
        this.btnConsultarTraslados                                          = this.btnConsultarTraslados.bind(this);
        this.successBtnConsultarTraslados                                   = this.successBtnConsultarTraslados.bind(this);
        this.mostrarMensajeGenerando                                        = this.mostrarMensajeGenerando.bind(this);
        this.gridOptionsFacturacTraslados                                   = Object.assign({},this.gridOptions);
        this.mostrarMensajeNoHayDatos                                       = this.mostrarMensajeNoHayDatos.bind(this);
        this.currencyFormatterGeneral                                       = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                                              = this.formatNumberSaldo.bind(this);
        this.onSelectionChangedConsultar                                    = this.onSelectionChangedConsultar.bind(this);
        this.gridOptionsFacturacTraslados['onSelectionChanged']             = this.onSelectionChangedConsultar;
        this.filaSeleccionadaConsultaTraslados                              = [];
        this.limpiarCamposConsulta                                          = this.limpiarCamposConsulta.bind(this);
        this.validarImprimir                                                = this.validarImprimir.bind(this);
        this.boton_selecionado                                              ='';
        this.nombre_archivo                                                 ='';
        this.abrirImprimir                                                  = this.abrirImprimir.bind(this);
        this.procesar                                                       = this.procesar.bind(this);
        this.habilitarCorreo                                                = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                                             = this.deshabilitarCorreo.bind(this);
        this.enviarCorreo                                                   = this.enviarCorreo.bind(this);
        this.successEnviarCorreo                                            = this.successEnviarCorreo.bind(this);
        this.mostrarMensajeEmailEnviado                                     = this.mostrarMensajeEmailEnviado.bind(this);
        this.generarPdfTabla                                                = this.generarPdfTabla.bind(this);
        this.isJson                                                         = this.isJson.bind(this);
        this.generarExcelTabla                                              = this.generarExcelTabla.bind(this);
        this.generarModalLista                                              = this.generarModalLista.bind(this);
        this.sucessTraerDatos                                               = this.sucessTraerDatos.bind(this);
        this.gridOptionsComponentesConsultarModal                           = Object.assign({}, this.gridOptions);
        this.validarImprimirGrupos                                          = this.validarImprimirGrupos.bind(this);
        this.sucessTraerDatosGrupos                                         = this.sucessTraerDatosGrupos.bind(this);
        this.limpiartemporales                                              = this.limpiartemporales.bind(this);
        this.successLimpiarTemporales                                       = this.successLimpiarTemporales.bind(this);
        this.validarTraslado                                                = this.validarTraslado.bind(this);
        this.successValidarTraslado                                         = this.successValidarTraslado.bind(this);
        this.aceptarTraslado                                                = this.aceptarTraslado.bind(this);
        this.successAceptarTraslado                                         = this.successAceptarTraslado.bind(this);
        this.cancelarSeleccion                                              = this.cancelarSeleccion.bind(this);
        this.aceptarSeleccion                                               = this.aceptarSeleccion.bind(this);
        this.validarWms                                                     = this.validarWms.bind(this);
        this.successValidarWms                                              = this.successValidarWms.bind(this);
        this.validarWmsConfiguracionXml                                     = this.validarWmsConfiguracionXml.bind(this);
        this.successValidarWmsConfigXml                                     = this.successValidarWmsConfigXml.bind(this);
        this.enviarWms                                                      = this.enviarWms.bind(this);
        this.successEnvioWms                                                = this.successEnvioWms.bind(this);
    }

    initForm() {
        console.log("Formulario CustomConsultarTraslados,  @version: jdesk_1.01.0002, @author: Santiago Hernández N.");

        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        /*this.fecha_inicio=año+'-'+mes+'-01';
        this.getField("consultar_desde").setValue(this.fecha_inicio);*/

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });
        this.traerDias();
        this.getField("consultar_desde").setOnChange(this.validarfechasConsulta);
        this.getField("consultar_hasta").setOnChange(this.validarfechasConsulta);
        this.getField("btn_consultar").setClick(this.btnConsultarTraslados);
        this.getField('radio_correo').setValue("N");
        this.getField("modal_trasladoinventario").setCloseButton(()=>{this.filaSeleccionadaConsultaTraslados={};this.limpiarCamposConsulta();});
        //this.getField("bt_salir_consultar").setClick(()=>{this.filaSeleccionadaConsultaTraslados={};this.getField("modal_trasladoinventario").handleClose();this.limpiarCamposConsulta();});
        this.getField("ver_presentacion").setClick(()=>{this.validarImprimir();});
        this.getField("imprimir_grupos").setClick(()=>{this.validarImprimirGrupos();});
        this.getField('generar_archivo').setClick(()=>this.procesar());

        this.getField('aceptar_traslado').setDisabled(true);
        this.validarTraslado();
        this.getField('aceptar_traslado').setClick(()=>this.aceptarTraslado());
        this.getField('cancelar_seleccion').setClick(()=>this.cancelarSeleccion());
        this.getField('aceptar_seleccion').setClick(()=>this.aceptarSeleccion());

        //this.getField('enviar_wms').setDisabled(true);
        this.validarWms();
        this.getField('enviar_wms').setClick(()=>this.enviarWms());
    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.consulta_de_traslados;
            //this.dias_maximo_lista=data.data.lista_ajustes_de_inventario;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    validarfechasConsulta(){
        this.getField('btn_consultar').setDisabled(false);

        let consultar_desde = new Date(this.getField("consultar_desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("consultar_hasta").getValue()).toISOString().split('T')[0];

        this.getField("consultar_desde").setError(false,"");
        this.getField("consultar_hasta").setError(false,"");

        if(consultar_desde > consultar_hasta){
            this.getField('btn_consultar').setDisabled(true);
            this.getField("consultar_hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }

        let fecha=new Date();
        fecha.setDate(fecha.getDate()-this.dias_maximo);
        fecha=fecha.toISOString().split('T')[0];
        if(consultar_desde < fecha){
            this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('btn_consultar').setDisabled(true);
            return false;
        }
        return true;

    }



    btnConsultarTraslados(){
        this.getField('numero').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('nombre_sucursal').setError(false,'');
        this.getField('consultar_desde').setError(false,'');
        this.getField('consultar_hasta').setError(false,'');
        let errores=0;
        if(this.validarfechasConsulta()===false)
            errores++;
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
            if(!this.getField('codigo_sucursal').valid())
                errores++;
            if(!this.getField('nombre_sucursal').valid())
                errores++;
        }
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' '){
            if(!this.getField('numero').valid())
                errores++;
        }

        
        if (errores===0) {
            let datos = { datos: {
                consultar_desde:this.getField("consultar_desde").getValue(),
                consultar_hasta:this.getField("consultar_hasta").getValue(),
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                numero:this.getField("numero").getValue()
            }};
            this.mostrarMensajeGenerando();
            this.getField("tb_facturactraslados").setTipoServicioOperacion('inve-consultatraslados-consultartraslados');
            this.getField("tb_facturactraslados").sendRequest(this.successBtnConsultarTraslados, datos);
        }

    }

    onSelectionChangedConsultar(){
        this.filaSeleccionadaConsultaTraslados = this.gridOptionsFacturacTraslados.api.getSelectedRows();  
    }

    successBtnConsultarTraslados(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.getField("modal_trasladoinventario").handleClickOpen();
            this.getField("tb_facturactraslados").toggle(true);
            this.gridOptionsFacturacTraslados["rowData"] = data.data;
            let configCell = new Map();
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_flete', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_flete) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('tb_facturactraslados').initData(this.gridOptionsFacturacTraslados,configCell);

        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    limpiarCamposConsulta(){
        this.getField("consultar_desde").setValue(this.fecha_hoy);
        this.getField("consultar_hasta").setValue(this.fecha_hoy);
        this.getField("numero").setValue('');
        this.getField("codigo_sucursal").setValue('');
        this.getField("nombre_sucursal").setValue('');
        this.getField("btn_consultar").setDisabled(false);
    }

    validarImprimir(){
        if(this.filaSeleccionadaConsultaTraslados.length>0){
            this.boton_selecionado='ver_presentacion';
            this.nombre_archivo='traslado_'+this.filaSeleccionadaConsultaTraslados[0].numero;
            this.abrirImprimir();
        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        }
    }
    validarImprimirGrupos(){
        if(this.filaSeleccionadaConsultaTraslados.length>0){
            this.boton_selecionado='imprimir_grupos';
            this.nombre_archivo='traslado_'+this.filaSeleccionadaConsultaTraslados[0].numero;
            this.abrirImprimir();
        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        }
    }



    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreo();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla();
                    }else if(this.operacion_actual==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    enviarCorreo(){        
        if (this.boton_selecionado==='ver_presentacion'){
            this.mostrarMensajeGenerando();
            let op='';
            let datos={ datos: { } };
    
            datos.datos={};
            op='consultatraslado';
            datos.datos.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            datos.datos.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            datos.datos.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
    
    
            this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successEnviarCorreo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        } else if (this.boton_selecionado==='imprimir_grupos'){
            this.mostrarMensajeGenerando();
            let op='';
            let datos={ datos: { } };
    
            datos.datos={};
            op='consultatrasladogrupos';
            datos.datos.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            datos.datos.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            datos.datos.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;
            datos.datos.bodega_destino=this.filaSeleccionadaConsultaTraslados[0].bodega_destino;
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
    
    
            this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successEnviarCorreo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{

        }


    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla(){

        if (this.boton_selecionado==='ver_presentacion'){
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};
    
            op='consultatraslado';
            datos2.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            datos2.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            datos2.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;
            datos2.sucursal_ingreso=this.sucursal_ingreso;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
    
    
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultatraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
        }else if (this.boton_selecionado==='imprimir_grupos'){
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};
    
            op='consultatrasladogrupos';
            datos2.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            datos2.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            datos2.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;
            datos2.bodega_destino=this.filaSeleccionadaConsultaTraslados[0].bodega_destino;
            datos2.sucursal_ingreso=this.sucursal_ingreso;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
    
    
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultatraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
        }else{

        }
    
    
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla(){
        
        if (this.boton_selecionado==='ver_presentacion'){
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};
    
            op='consultatraslado';
            datos2.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            datos2.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            datos2.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;
            datos2.sucursal_ingreso=this.sucursal_ingreso;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
    
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultatraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo exitosamente!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
        }else if (this.boton_selecionado==='imprimir_grupos'){
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};
    
            op='consultatrasladogrupos';
            datos2.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            datos2.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            datos2.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;
            datos2.bodega_destino=this.filaSeleccionadaConsultaTraslados[0].bodega_destino;
            datos2.sucursal_ingreso=this.sucursal_ingreso;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
    
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultatraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo exitosamente!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
        }else{

        }


    }

    generarModalLista(){

        
        if (this.boton_selecionado==='ver_presentacion'){
            this.mostrarMensajeGenerando();
            let op='';
            let datos={ datos: {}};
            op='consultatraslado';
            datos.datos.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            datos.datos.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            datos.datos.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;            
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
    
            this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else if (this.boton_selecionado==='imprimir_grupos'){
            this.mostrarMensajeGenerando();
            let op='';
            let datos={ datos: {}};
    
            op='consultatrasladogrupos';
            datos.datos.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            datos.datos.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            datos.datos.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;
            datos.datos.bodega_destino=this.filaSeleccionadaConsultaTraslados[0].bodega_destino;
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
    
            this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatosGrupos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{

        }

    }

    sucessTraerDatos(data){

        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.getField('modal_general').handleClickOpen();

            let datosFicha = [];
            this.lista=data.data.datos_lista;

            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit":this.lista.nit,
                    "direccion": this.lista.direccion,
                    "telefono": this.lista.telefono,
                    "ciudad": this.lista.ciudad,
                    "fecha": this.lista.fecha,
                    "numero": this.lista.numero,                    
                    "sucursal": this.lista.sucursal,
                    "bodega_origen": this.lista.bodega_origen,  
                    "nombre_bodega_origen": this.lista.nombre_bodega_origen,  
                    "bodega_destino": this.lista.bodega_destino,  
                    "nombre_bodega_destino": this.lista.nombre_bodega_destino,                  
                    "título": this.lista.titulo,
                    "fechas_consulta":this.lista.fechas_consulta,
                    "referencia": this.lista.referencia,
                    "detalle": this.lista.detalle,

                    "opcion": this.ch_todos_descripcion

                };
            datosFicha.push(data2);
            
            this.getField('lista_datos_general').setItemsFichas(datosFicha);

            this.getField('valor_total').setValue(new Intl.NumberFormat().format(parseFloat(this.lista.valor_total)));    
            this.getField('peso_total').setValue(this.lista.peso_total);

            

            let configCell = new Map();
            
                        
            this.gridOptionsComponentesConsultarModal['rowData'] = [];
            this.gridOptionsComponentesConsultarModal['rowData'] = data.data.datos_items; 
   
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('tabla_consultar').toggle(true);  
            this.getField('tabla_consultar').initData(this.gridOptionsComponentesConsultarModal,configCell);

            
            
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    sucessTraerDatosGrupos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit":this.lista.nit,
                    "direccion": this.lista.direccion,
                    "telefono": this.lista.telefono,
                    "ciudad": this.lista.ciudad,
                    "fecha": this.lista.fecha,
                    "numero": this.lista.numero,                    
                    "sucursal": this.lista.sucursal,             
                    "título": this.lista.titulo,
                    "fechas_consulta":this.lista.fechas_consulta,
                    "referencia": this.lista.referencia,
                    "referencia_2": this.lista.referencia2,
                    "grupo": this.lista.grupo,
                    "nombre_grupo": this.lista.ngrupo,
                    "opcion": this.ch_todos_descripcion

                };
            datosFicha.push(data2);
            this.getField('lista_datos_general_grupo').setItemsFichas(datosFicha);
            this.getField('peso_total_unidades').setValue(this.lista.total_unidades);
            this.getField('peso_total_cartones').setValue(this.lista.total_cartones);

            let configCell = new Map();

                        
            this.gridOptionsComponentesConsultarModal['rowData'] = [];
            this.gridOptionsComponentesConsultarModal['rowData'] = data.data.datos_items;
            configCell.set('cartones', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xcartones) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('unidades', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xunidades) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('tabla_consultar_grupo').toggle(true);  
            this.getField('tabla_consultar_grupo').initData(this.gridOptionsComponentesConsultarModal,configCell);


            this.getField('modal_general_grupos').handleClickOpen();
            this.limpiartemporales();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
            this.limpiartemporales();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiartemporales();
        } 
    }

    limpiartemporales(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: 'limpiartemporales', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successLimpiarTemporales,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successLimpiarTemporales(data){

        if(data.estado_p === 200){ 
            this.alertGeneral.toggle(true, 'Registros eliminados con éxito.', 'success');            
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al limpiar las temporales.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 

    }

    validarTraslado(){
        let datos={ datos: {
            
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: 'validartraslado', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarTraslado,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successValidarTraslado(data){
        if(data.estado_p === 200){ 
            if (data.data[0].xson>0){
                this.getField('aceptar_traslado').setDisabled(false);
            }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al validar si acepta traslado.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    aceptarTraslado(){
        if(this.filaSeleccionadaConsultaTraslados.length>0){
            this.boton_selecionado='aceptar_traslado';
            this.nombre_archivo='traslado_'+this.filaSeleccionadaConsultaTraslados[0].numero;
            this.numero=this.filaSeleccionadaConsultaTraslados[0].numero;
            this.fecha=this.filaSeleccionadaConsultaTraslados[0].fecha;
            this.codigo_sucursal=this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal;
            this.estado=this.filaSeleccionadaConsultaTraslados[0].estado;
            

            if (this.filaSeleccionadaConsultaTraslados[0].estado==='P'){
                                
                //this.getField('modal_trasladoinventario').handleClose();
                this.getField('modal_seleccion').handleClickOpen();


            }else{
                this.alertGeneral.toggle(true,'Este traslado ya ha sido recibido.',"error");
            }
        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        }
    }


    aceptarSeleccion(){

        if (this.getField('radiobuttontraslado').getValue() !== ''){
            let datos={ datos: {
                numero:this.numero,
                fecha:this.fecha,
                codigo_sucursal:this.codigo_sucursal,
                estado:this.estado,
                radio_seleccion:this.getField('radiobuttontraslado').getValue(),
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: 'aceptartraslado', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successAceptarTraslado,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar una opción.',"error");
        }
    }

    successAceptarTraslado(data){
        if(data.estado_p === 200){         
            this.alertGeneral.toggle(true, 'Registro actualizado con éxito.', 'success');
            this.getField('modal_seleccion').handleClose();
            this.btnConsultarTraslados();
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al actualizar registro.', 'error');
            this.getField('modal_seleccion').handleClose();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.getField('modal_seleccion').handleClose();
        } 
    }

    cancelarSeleccion(){
        this.getField('modal_seleccion').handleClose();
    }

    validarWms(){
        let datos={ datos: {
            codigo_sucursal:this.sucursal_ingreso,
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: 'validarwms', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarWms,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successValidarWms(data){
        if(data.estado_p === 200){ 
            if (data.data[0].xson>0){
                this.getField('enviar_wms').setVisible(true);
                this.validarWmsConfiguracionXml();
            }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al validar maneja_wms.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    validarWmsConfiguracionXml(){
        let datos={ datos: {
            sucursal_ingreso: this.sucursal_ingreso,
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: 'validarconfiguracionxmlwms', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarWmsConfigXml,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successValidarWmsConfigXml(data){
        if(data.estado_p === 200){ 
            if (data.data[0].xson>0){
                this.getField('enviar_wms').setDisabled(false);
            }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'Error al validar maneja_wms.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    enviarWms(){
        if(this.filaSeleccionadaConsultaTraslados.length>0){
            this.boton_selecionado='aceptar_traslado';
            this.nombre_archivo='traslado_'+this.filaSeleccionadaConsultaTraslados[0].numero;

            let datos={ datos: {
                numero:this.filaSeleccionadaConsultaTraslados[0].numero,
                codigo_sucursal: this.filaSeleccionadaConsultaTraslados[0].codigo_sucursal,
                sucursal_ingreso: this.sucursal_ingreso,
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: 'enviarawms', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successEnvioWms,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });

        }else{
            this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        } 
    }

    successEnvioWms(data){
        if(data.estado_p === 200){ 
            this.alertGeneral.toggle(true, 'Se envió correctamente el traslado a WMS externo.', 'success');
            //this.getField('modal_trasladoinventario').handleClose();
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true,'No se pudo eviar a WMS Externo.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }     
    }

    


    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }
    
    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    
    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }



    
}
FormJaivana.addController("inve-consultatraslados", CustomConsultarTraslados);
export default CustomConsultarTraslados;