import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomActualizarNiif
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomTrasladosFacturacion extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                               = this.initForm.bind(this);
        this.gridOptionsComponentes                 = Object.assign({},this.gridOptions);
        this.cargarSelectSucursal                   = this.cargarSelectSucursal.bind(this);
        this.successCargarSelect                    = this.successCargarSelect.bind(this);
        this.consultar                              = this.consultar.bind(this);
        this.successTraerNegativos                  = this.successTraerNegativos.bind(this);
        this.traerdatos                             = this.traerdatos.bind(this);
        this.successTraerDatos                      = this.successTraerDatos.bind(this);
        this.estado=""  
        this.eliminar                               = this.eliminar.bind(this);
        this.confirmDelete                          = this.confirmDelete.bind(this);
        this.successEliminar                        = this.successEliminar.bind(this);
        this.successMostrarTabla                    = this.successMostrarTabla.bind(this);
        this.validarBodegas                         = this.validarBodegas.bind(this);
        this.successvalidarbodegas                  = this.successvalidarbodegas.bind(this);
        this.validarSaldo                           = this.validarSaldo.bind(this);
        this.successValidarSaldo                    = this.successValidarSaldo.bind(this);
        this.armaritems                             = this.armaritems.bind(this);
        this.successArmarItems                      = this.successArmarItems.bind(this);
        this.grabartraslado                         = this.grabartraslado.bind(this);
        this.successGrabar                          = this.successGrabar.bind(this);
        this.procesar                               = this.procesar.bind(this);
        this.generarPdf                             = this.generarPdf.bind(this);
        this.isJson                                 = this.isJson.bind(this);
        this.mostrarMensajeNoHayDatos               = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeGenerando                = this.mostrarMensajeGenerando.bind(this);
        this.generarModalLista                      = this.generarModalLista.bind(this);
        this.sucessTraerDatos                       = this.sucessTraerDatos.bind(this);
        this.currencyFormatterGeneral               = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                      = this.formatNumberSaldo.bind(this);
        this.enviarCorreoTerceros                   = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo                    = this.successEnviarCorreo.bind(this);
        this.generarExcel                           = this.generarExcel.bind(this);
        this.generarModalLista                      = this.generarModalLista.bind(this);
        this.mostrarMensajeEmailEnviado             = this.mostrarMensajeEmailEnviado.bind(this);
        this.deshabilitarCorreo                     = this.deshabilitarCorreo.bind(this);
        this.habilitarCorreo                        = this.habilitarCorreo.bind(this);
        this.gridOptionsComponentesConsultarModal   = Object.assign({}, this.gridOptions);

    }

    initForm() {
        console.log("Formulario CustomTrasladosFacturacion,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.cargarSelectSucursal();
        this.getField("detallado").setClick(()=>{this.estado="detallado"; this.consultar();});
        this.getField("resumido").setClick(()=>{this.estado="resumido"; this.consultar();});
        this.getField("eliminar_item").setClick(this.eliminar);
        this.getField("trasladar").setClick(this.validarBodegas);
        this.getField('generar_archivo').setClick(()=>this.procesar());
        this.getField('modal_imprimir').setCloseButton(()=>{
            this.initForm();
        });

        
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        this.fecha_hoy=año+'-'+mes+'-'+dia;

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });

    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    cargarSelectSucursal(){
        let datos = { datos: {
            select_sucursal: this.codigo_sucursal
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'traersucursales', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarSelect,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    
    successCargarSelect(data){
        if (data.estado_p === 200){

            let sucursales = []
            sucursales = data.data[0].valor.split(",");

            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            sucursales.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item;
                dataOp['text'] = item;
                dataOp['campos_cambian'] = { };
                opciones.push(dataOp);
            });
            this.getField('select_sucursal').setOptions(opciones);
        }else{
            if(data.estado_p===404) {
                let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
                this.getField('select_sucursal').setOptions(opciones);
            }
            else {
               let respuesta=Object.values(data.data.errores);
               let keys=Object.keys(data.data.errores);
               this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
       }
    }

    consultar(){
        if(this.getField('select_sucursal').getValue() !== ""){
            let datos = { datos: {
                select_sucursal: this.codigo_sucursal
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'hayNegativos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerNegativos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar una sucursal.', 'error');
        }
    }

    successTraerNegativos(data){
        if (data.estado_p === 200){

            if(data.data[0].total > 0){
                this.traerdatos();
            }else{
                this.alertGeneral.toggle(true, 'No hay saldos pendientes por trasladar.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                this.getField('rejilla').initData(this.gridOptionsComponentes);
                this.getField('rejilla').toggle(false);
                this.getField('codigo').setValue("");
            }
        }else{
            if(data.estado_p===404) {
                let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
                this.getField('select_sucursal').setOptions(opciones);
            }
            else {
               let respuesta=Object.values(data.data.errores);
               let keys=Object.keys(data.data.errores);
               this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
       }
    }

    traerdatos(){
        let op = "";
        if(this.estado === "detallado"){
            op ='consultadetalle'
        }else{
            op = 'consultaresumido'
        }
        let datos = { datos: {
            select_sucursal: this.codigo_sucursal,
            codigousuario: this.codigo_usuario
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDatos(data){
        if (data.estado_p === 200 || data.estado_p===404){
            let datos = { datos: {
                codigousuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'cargartabla', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successMostrarTabla,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');            
       }
    }

    eliminar() {
        if(this.getField("codigo").getValue() !== ""){
            this.codigo_articulo = this.getField("codigo").getValue();
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(this.confirmDelete);
            this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField('confirmModalCustom').toggle(true);
        }else{
            this.alertGeneral.toggle(true, 'Por favor, seleccione un registro.', 'error');
        }
    }

    confirmDelete() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
                codigo: this.codigo_articulo,
                codigousuario:this.codigo_usuario
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'eliminar_articulo', operacion_tipo: "eliminar" };// parametros del servicio
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successEliminar(data) {
        if(data.estado_p === 200){
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes);
            this.getField('rejilla').toggle(false);
            this.getField('codigo').setValue("");
            let datos = { datos: {
                codigousuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'cargartabla', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successMostrarTabla,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
          let keys=Object.keys(data.data.mensaje);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
        }
    }

    successMostrarTabla(data) {
        if(data.estado_p === 200){

            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);

        }else{
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay saldos pendientes por trasladar.', 'error');
                this.gridOptionsComponentes['rowData'] = [];
                this.getField('rejilla').initData(this.gridOptionsComponentes);
                this.getField('rejilla').toggle(false);
            }
            else {
               let respuesta=Object.values(data.data.errores);
               let keys=Object.keys(data.data.errores);
               this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    validarBodegas(){

        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let datos = { datos: {
            select_sucursal: this.codigo_sucursal,
            codigousuario: this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'validarBodegas', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successvalidarbodegas,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successvalidarbodegas(data){
        if(data.estado_p === 200){

            this.bodega_origen= data.data.bodega_origen;
            this.bodega_destino= data.data.bodega_destino;
            this.validarSaldo();

        }else{
            this.getField('confirmModalCustom').toggle(false);

            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje) - ' + respuesta, 'error');
            }
            else {
               let respuesta=Object.values(data.data.mensaje);
               let keys=Object.keys(data.data.mensaje);
               this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    validarSaldo(){

        let datos = { datos: {
            bodega_origen: this.bodega_origen,
            select_sucursal: this.codigo_sucursal,
            codigousuario: this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'validarSaldos', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarSaldo,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successValidarSaldo(data){
        if(data.estado_p === 200){

            this.armaritems();

        }else{
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                let respuesta=data.data.errores.mensaje;
                this.alertGeneral.toggle(true, 'Error (mensaje) - ' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores.mensaje);
                let keys=Object.keys(data.data.errores.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    armaritems(){
        let datos = { datos: {
            bodega_origen: this.bodega_origen,
            bodega_destino: this.bodega_destino,
            select_sucursal: this.codigo_sucursal,
            fecha: this.fecha_hoy,
            codigousuario: this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'armar_items', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successArmarItems,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successArmarItems(data){
        if(data.estado_p === 200){
            this.arrayvalor= data.data.total_traslado;
            this.consecutivo= data.data.consecutivo;
            let total = 0;
            this.arrayvalor.forEach(item => {
                total = total + item;
            });
            this.valor =total.toFixed(2);

            this.grabartraslado();

        }else{
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                let respuesta=data.data.errores.mensaje;
                this.alertGeneral.toggle(true, 'Error (mensaje) - ' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores.mensaje);
                let keys=Object.keys(data.data.errores.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    grabartraslado(){
        let datos = { datos: {
            bodega_origen: this.bodega_origen,
            bodega_destino: this.bodega_destino,
            select_sucursal: this.codigo_sucursal,
            fecha: this.fecha_hoy,
            total: parseFloat(this.valor),
            consecutivo: this.consecutivo,
            codigousuario: this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'traslado', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successGrabar,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successGrabar(data){
        if(data.estado_p === 200){
            this.getField('confirmModalCustom').toggle(false);
            this.getField('modal_imprimir').handleClickOpen();
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes);
            this.getField('rejilla').toggle(false);


        }else{
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                let respuesta=data.data.mensaje;
                this.alertGeneral.toggle(true, 'Error (mensaje) - ' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores.mensaje);
                let keys=Object.keys(data.data.errores.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdf();
                    }else if(this.operacion_actual==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcel();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            numero: this.consecutivo,
            select_sucursal:this.getField('select_sucursal').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            fecha: this.fecha_hoy,
            operacion: this.operacion_actual
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'imprimir', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdf()
    {       
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2={
            numero: this.consecutivo,
            select_sucursal:this.getField('select_sucursal').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            fecha: this.fecha_hoy,
            operacion: this.operacion_actual
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-trasladosfacturacion","operacion":"imprimir","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarModalLista(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            numero: this.consecutivo,
            select_sucursal:this.getField('select_sucursal').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            fecha: this.fecha_hoy,
            operacion: this.operacion_actual
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosfacturacion', operacion: 'imprimir', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerDatos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });  
    }
      
    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "sucursal": this.lista.sucursal,
                    "traslado_número": this.consecutivo,
                    "bodega_origen": this.lista.bodega_origen,
                    "bodega_destino": this.lista.bodega_destino,
                    "fecha": this.lista.fecha,
                    "detalle": this.lista.detalle

                };
            datosFicha.push(data2);
            this.getField('lista_datos_general').setItemsFichas(datosFicha);
            
            let configCell = new Map();
            configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) },field: 'tarifa_iva', cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('tabla_consultar').toggle(true);
                
            this.gridOptionsComponentesConsultarModal['rowData'] = [];
            this.gridOptionsComponentesConsultarModal['rowData'] = data.data.datos_items;
            this.getField('valor_total').setVisible(true);
            this.getField('peso_total').setVisible(true);
            this.getField('valor_total').setValue(this.currencyFormatterGeneral(this.lista.valor));
            this.getField('peso_total').setValue(this.currencyFormatterGeneral(this.lista.peso));

            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('codigo', { headerName: 'Código'});

            this.getField('tabla_consultar').initData(this.gridOptionsComponentesConsultarModal,configCell);
            this.getField('modal_general').handleClickOpen();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    generarExcel()
    {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2={
            numero: this.consecutivo,
            select_sucursal:this.getField('select_sucursal').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            fecha: this.fecha_hoy,
            operacion: this.operacion_actual
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-trasladosfacturacion","operacion":"imprimir","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'trasladoFacturacion.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }
  
}
FormJaivana.addController("inve-trasladosfacturacion", CustomTrasladosFacturacion);
export default CustomTrasladosFacturacion;