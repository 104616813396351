/**
 * @description Custom con funciones adicionales al formulario con código: maes-terceros, ID Formulario: 60, Sección: padre: Maestros(id: 1)- hijo: Terceros(id: 14)
 * @author Sebastian Rios Echeverri
 * @version jdesk_1.01.0001
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomTerceros extends FormJaivana.form {


    constructor(props) {

        super(props);
        // Variables de tablas:
        this.gridOptionsTerceros                 =   Object.assign({},this.gridOptions);
       
        // Variables Globales:
        this.arrayObjetos                        =   new Map();
        this.arrayCampos                         =   new Map();
        this.bHayErrores                         =   false;
        this.bHayError                           =   false;
        this.datosAgregar                        =   { datos:{}};
       
        // Funciones:
        this.initForm                            =   this.initForm.bind(this);
        this.validarDatosFrm                     =   this.validarDatosFrm.bind(this);
        this.concatenarNombre                    =   this.concatenarNombre.bind(this);
        this.validarCamposApellidos              =   this.validarCamposApellidos.bind(this);
        this.tipoDocumento                       =   this.tipoDocumento.bind(this);
        this.calcularDigitoNit                   =   this.calcularDigitoNit.bind(this);
        this.traerSedePorNit                     =   this.traerSedePorNit.bind(this);
        this.successTraerSedePorNit              =   this.successTraerSedePorNit.bind(this);
        this.successCrearTercero                 =   this.successCrearTercero.bind(this);
        this.traerDepartamentos                  =   this.traerDepartamentos.bind(this);
        this.successTraerDepartamentos           =   this.successTraerDepartamentos.bind(this);
        this.traerCiudades                       =   this.traerCiudades.bind(this);
        this.successTraerCiudades                =   this.successTraerCiudades.bind(this);
        this.traerBarrios                        =   this.traerBarrios.bind(this);
        this.successTraerBarrios                 =   this.successTraerBarrios.bind(this);
        this.cambioBarrio                        =   this.cambioBarrio.bind(this);
    }

    initForm() {

        console.log('Formulario CustomTerceros,  @version: jdesk_1.01.0001, @author: Sebastian Rios Echeverri');

        this.arrayCampos.set(1, '.');
        this.arrayCampos.set(2, 'contenedorDatosBasicos');
        this.arrayCampos.set(76, 'label_tipo_doc_basicos');
        this.arrayCampos.set(77, 'tipo_documento_id');
        this.arrayCampos.set(78, 'label_documento_basicos');
        this.arrayCampos.set(79, 'nit_basicos');
        this.arrayCampos.set(80, 'label_dv_basicos');
        this.arrayCampos.set(81, 'verifica_basicos');
        this.arrayCampos.set(82, 'label_sede_basicos');
        this.arrayCampos.set(83, 'sede_basicos');
        this.arrayCampos.set(3, 'label_primer_apellido');
        this.arrayCampos.set(4, 'ape1');
        this.arrayCampos.set(5, 'label_segundo_apellido');
        this.arrayCampos.set(6, 'ape2');
        this.arrayCampos.set(7, 'label_primer_nombre');
        this.arrayCampos.set(8, 'nom1');
        this.arrayCampos.set(9, 'label_segundo_nombre');
        this.arrayCampos.set(10, 'nom2');
        this.arrayCampos.set(11, 'label_nombre_completo');
        this.arrayCampos.set(12, 'nombre');
        this.arrayCampos.set(13, 'label_nombre_negocio');
        this.arrayCampos.set(84, 'otro_nombre');
        // this.arrayCampos.set(14, 'codigo_interno');
        this.arrayCampos.set(15, 'cancelar_basicos');
        this.arrayCampos.set(16, 'nombre_tipo_documento');
        this.arrayCampos.set(19, 'label_pais');
        this.arrayCampos.set(20, 'codigo_pais');
        this.arrayCampos.set(22, 'paises_id');
        this.arrayCampos.set(23, 'label_departamento');
        this.arrayCampos.set(24, 'codigo_departamento_geografico');
        this.arrayCampos.set(26, 'departamento_geografico_id');
        this.arrayCampos.set(27, 'label_ciudad');
        this.arrayCampos.set(28, 'codigo_ciudad');
        this.arrayCampos.set(30, 'ciudad_id');
        this.arrayCampos.set(31, 'label_barrio');
        this.arrayCampos.set(32, 'codigo_barrio');
        this.arrayCampos.set(34, 'barrio_id');
        this.arrayCampos.set(35, 'label_direccion');
        this.arrayCampos.set(36, 'direccion');
        this.arrayCampos.set(38, 'labelespaciopestana2');
        this.arrayCampos.set(41, 'label_telefonos');
        this.arrayCampos.set(42, 'telefonos');
        this.arrayCampos.set(43, 'label_fax');
        this.arrayCampos.set(44, 'faxes');
        this.arrayCampos.set(45, 'label_email_factuacion');
        this.arrayCampos.set(46, 'email_factura_electronica');
        this.arrayCampos.set(47, 'label_email_secundario_facturacion');
        this.arrayCampos.set(48, 'email_secundario_facturacion_electronica');
        this.arrayCampos.set(49, 'cancelar_contacto');
        this.arrayCampos.set(50, 'labelespaciopestana3');
        this.arrayCampos.set(51, 'continuar_contacto');
        this.arrayCampos.set(53, 'label_vendedor');
        this.arrayCampos.set(54, 'codigo_vendedor');
        this.arrayCampos.set(56, 'vendedor_id');
        this.arrayCampos.set(57, 'label_tipo_negocio');
        this.arrayCampos.set(58, 'codigo_negocio');
        this.arrayCampos.set(60, 'negocio_id');
        this.arrayCampos.set(61, 'label_canal');
        this.arrayCampos.set(62, 'canal_cliente');
        this.arrayCampos.set(63, 'descripcion_cliente');
        this.arrayCampos.set(64, 'canalesclientes_id');
        this.arrayCampos.set(65, 'label_oficio');
        this.arrayCampos.set(66, 'codigo_oficio');
        this.arrayCampos.set(67, 'nombre_oficio');
        this.arrayCampos.set(68, 'oficio_id');
        this.arrayCampos.set(69, 'label_observaciones1');
        this.arrayCampos.set(70, 'observacion');
        this.arrayCampos.set(71, 'label_observaciones2');
        this.arrayCampos.set(72, 'observaciones');
        this.arrayCampos.set(74, 'labelespaciopestana4');
        this.arrayCampos.set(75, 'continuar_cliente');
        this.arrayCampos.set(85, 'contenedorIdentificacion');
        this.arrayCampos.set(88, 'label_documento');
        this.arrayCampos.set(89, 'nit');
        this.arrayCampos.set(90, 'label_dv');
        this.arrayCampos.set(91, 'verifica');
        this.arrayCampos.set(92, 'label_sede');
        this.arrayCampos.set(93, 'sede');
        this.arrayCampos.set(94, 'cancelar_identificacion');
        this.arrayCampos.set(95, 'labelespaciopestana5');
        this.arrayCampos.set(96, 'finalizar_identificacion');
        this.arrayObjetos.set(200, '.');
        this.arrayObjetos.set(201, '.');

        this.arrayObjetos.set(1, '.');
        this.arrayObjetos.set(2, 'contenedorDatosBasicos');
        this.arrayObjetos.set(76, 'label_tipo_doc_basicos');
        this.arrayObjetos.set(77, 'tipo_documento_id');
        this.arrayObjetos.set(78, 'label_documento_basicos');
        this.arrayObjetos.set(79, 'nit_basicos');
        this.arrayObjetos.set(80, 'label_dv_basicos');
        this.arrayObjetos.set(81, 'verifica_basicos');
        this.arrayObjetos.set(82, 'label_sede_basicos');
        this.arrayObjetos.set(83, 'sede_basicos');
        this.arrayObjetos.set(3, 'label_primer_apellido');
        this.arrayObjetos.set(4, 'ape1');
        this.arrayObjetos.set(5, 'label_segundo_apellido');
        this.arrayObjetos.set(6, 'ape2');
        this.arrayObjetos.set(7, 'label_primer_nombre');
        this.arrayObjetos.set(8, 'nom1');
        this.arrayObjetos.set(9, 'label_segundo_nombre');
        this.arrayObjetos.set(10, 'nom2');
        this.arrayObjetos.set(11, 'label_nombre_completo');
        this.arrayObjetos.set(12, 'nombre');
        this.arrayObjetos.set(13, 'label_nombre_negocio');
        this.arrayObjetos.set(84, 'otro_nombre');
        // this.arrayObjetos.set(14, 'codigo_interno');
        this.arrayObjetos.set(15, 'cancelar_basicos');
        this.arrayObjetos.set(16, 'nombre_tipo_documento');
        this.arrayObjetos.set(17, 'continuar_basicos');
        this.arrayObjetos.set(18, 'contenedorDatosGeograficos');
        this.arrayObjetos.set(19, 'label_pais');
        this.arrayObjetos.set(20, 'codigo_pais');
        this.arrayObjetos.set(22, 'paises_id');
        this.arrayObjetos.set(23, 'label_departamento');
        this.arrayObjetos.set(24, 'codigo_departamento_geografico');
        this.arrayObjetos.set(26, 'departamento_geografico_id');
        this.arrayObjetos.set(27, 'label_ciudad');
        this.arrayObjetos.set(28, 'codigo_ciudad');
        this.arrayObjetos.set(30, 'ciudad_id');
        this.arrayObjetos.set(31, 'label_barrio');
        this.arrayObjetos.set(32, 'codigo_barrio');
        this.arrayObjetos.set(34, 'barrio_id');
        this.arrayObjetos.set(35, 'label_direccion');
        this.arrayObjetos.set(36, 'direccion');
        this.arrayObjetos.set(37, 'cancelar_geograficos');
        this.arrayObjetos.set(38, 'labelespaciopestana2');
        this.arrayObjetos.set(39, 'continuar_geograficos');
        this.arrayObjetos.set(40, 'contenedorDatosContacto');
        this.arrayObjetos.set(41, 'label_telefonos');
        this.arrayObjetos.set(42, 'telefonos');
        this.arrayObjetos.set(43, 'label_fax');
        this.arrayObjetos.set(44, 'faxes');
        this.arrayObjetos.set(45, 'label_email_factuacion');
        this.arrayObjetos.set(46, 'email_factura_electronica');
        this.arrayObjetos.set(47, 'label_email_secundario_facturacion');
        this.arrayObjetos.set(48, 'email_secundario_facturacion_electronica');
        this.arrayObjetos.set(49, 'cancelar_contacto');
        this.arrayObjetos.set(50, 'labelespaciopestana3');
        this.arrayObjetos.set(51, 'continuar_contacto');
        this.arrayObjetos.set(52, 'contenedorDatosBasicosCliente');
        this.arrayObjetos.set(53, 'label_vendedor');
        this.arrayObjetos.set(54, 'codigo_vendedor');
        this.arrayObjetos.set(55, 'nombre_vendedor');
        this.arrayObjetos.set(56, 'vendedor_id');
        this.arrayObjetos.set(57, 'label_tipo_negocio');
        this.arrayObjetos.set(58, 'codigo_negocio');
        this.arrayObjetos.set(59, 'nombre_negocio_clientes');
        this.arrayObjetos.set(60, 'negocio_id');
        this.arrayObjetos.set(61, 'label_canal');
        this.arrayObjetos.set(62, 'canal_cliente');
        this.arrayObjetos.set(63, 'descripcion_cliente');
        this.arrayObjetos.set(64, 'canalesclientes_id');
        this.arrayObjetos.set(65, 'label_oficio');
        this.arrayObjetos.set(66, 'codigo_oficio');
        this.arrayObjetos.set(67, 'nombre_oficio');
        this.arrayObjetos.set(68, 'oficio_id');
        this.arrayObjetos.set(69, 'label_observaciones1');
        this.arrayObjetos.set(70, 'observacion');
        this.arrayObjetos.set(71, 'label_observaciones2');
        this.arrayObjetos.set(72, 'observaciones');
        this.arrayObjetos.set(73, 'cancelar_cliente');
        this.arrayObjetos.set(74, 'labelespaciopestana4');
        this.arrayObjetos.set(75, 'continuar_cliente');
        this.arrayObjetos.set(85, 'contenedorIdentificacion');
        this.arrayObjetos.set(88, 'label_documento');
        this.arrayObjetos.set(89, 'nit');
        this.arrayObjetos.set(90, 'label_dv');
        this.arrayObjetos.set(91, 'verifica');
        this.arrayObjetos.set(92, 'label_sede');
        this.arrayObjetos.set(93, 'sede');
        this.arrayObjetos.set(94, 'cancelar_identificacion');
        this.arrayObjetos.set(95, 'labelespaciopestana5');
        this.arrayObjetos.set(96, 'finalizar_identificacion');
        this.arrayObjetos.set(200, '.');
        this.arrayObjetos.set(201, '.');

        this.getField('continuar_basicos').setClick(() => { this.validarDatosFrm("continuar_basicos"); });
        this.getField('continuar_geograficos').setClick(() => { this.validarDatosFrm("continuar_geograficos"); });
        this.getField('continuar_contacto').setClick(() => { this.validarDatosFrm("continuar_contacto"); });
        this.getField('continuar_cliente').setClick(() => { this.validarDatosFrm("continuar_cliente"); });
        this.getField('cancelar_basicos').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('cancelar_geograficos').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('cancelar_contacto').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('cancelar_cliente').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('cancelar_identificacion').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('finalizar_identificacion').setClick(() => { this.validarDatosFrm("finalizar_identificacion"); });
        this.getField('nom1').setOnChange(this.concatenarNombre);
        this.getField('nom2').setOnChange(this.concatenarNombre);
        this.getField('ape1').setOnChange(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
        this.getField('ape2').setOnChange(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
        this.getField('nombre').setOnChange(() => { this.getField('nombre').getValue().length >= 1 ? this.getField('nombre').setError(false, "") : this.getField('nombre').valid(); });
        this.getField('nombre').setKeyUp(() => { this.getField('otro_nombre').setValue(this.getField('nombre').getValue());});
        this.getField('tipo_documento_id').setOnChange(() => { this.getField('tipo_documento_id').getValue() !== '' ? this.getField('tipo_documento_id').setError(false, '') : this.getField('tipo_documento_id').valid();});
        this.getField('nombre_tipo_documento').setOnChange(this.tipoDocumento);
        this.getField('nit_basicos').setOnChange(()=>{this.getField('verifica_basicos').setValue(''); this.getField('sede_basicos').setValue('');});
        this.getField('nit').setOnChange(()=>{this.getField('verifica').setValue(''); this.getField('sede').setValue('');});
        this.getField('nit_basicos').setOnBlur(()=>{this.calcularDigitoNit('nit_basicos');});
        this.getField('nit').setOnBlur(()=>{this.calcularDigitoNit('nit');});
        
        this.establecerPropiedades('4,6,8,10','value','.');

        // Campos deshabilitados al abrir el formulario:
        this.establecerPropiedades('12,26,30,34,36','disable','true');

        // Deshabilitar y habilitar campos de búsqueda de la pestaña "Datos Geográficos"
        this.getField('paises_id').setOnChange(this.traerDepartamentos);
        this.getField('departamento_geografico_id').setOnChange(this.traerCiudades);
        this.getField('ciudad_id').setOnChange(this.traerBarrios);
        this.getField('barrio_id').setOnChange(this.cambioBarrio);
    }


    //*** definir los botones y los success
    cambioBarrio(){
        if (this.getField('paises_id').valid() && this.getField('departamento_geografico_id').valid() && 
        this.getField('ciudad_id').valid() && this.getField('barrio_id').valid()) {
            this.getField('direccion').setDisabled(false); 
        }else{
            this.getField('direccion').setDisabled(true); this.getField('direccion').setValue('');
        }
    }
    
    traerBarrios(){
        if (this.getField('paises_id').valid() && this.getField('departamento_geografico_id').valid() && 
        this.getField('ciudad_id').valid()) {
            let datos={ 
                datos: {
                    ciudad_id: this.getField('ciudad_id').getValue()
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-barrios', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerBarrios,
                    error: this.error_,
                    general: this.generalFormatPmv/* ,
                    showMessage: false */
            });
        }else{
            this.getField('barrio_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('barrio_id').setDisabled(true);
            this.getField('direccion').setDisabled(true);
            this.getField('barrio_id').setError(false,'');
            this.getField('direccion').setValue('');
            this.getField('direccion').setError(false,'');
        }
    }

    successTraerBarrios(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('barrio_id').setDisabled(false);
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.barrios_id;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {codigo_barrio: item.codigo_barrio};
                opciones.push(dataOp);
            });
            this.getField('barrio_id').setOptions(opciones);
            this.getField('barrio_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un pais que se habia seleccionado con anterioridad, no se setee el mismo departamento, sino que solo liste el select con los departamentos correspondientes al país seleccionado.
            this.getField('barrio_id').setError(false,'');
        } else {
            this.getField('barrio_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
        }
    }
    
    traerCiudades(){
        if (this.getField('paises_id').valid() && this.getField('departamento_geografico_id').valid()) {
            let datos={ 
                datos: {
                    departamento_geografico_id: this.getField('departamento_geografico_id').getValue()
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerCiudades,
                    error: this.error_,
                    general: this.generalFormatPmv/* ,
                    showMessage: false */
            });
        }else{
            this.getField('ciudad_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('barrio_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('ciudad_id').setDisabled(true);
            this.getField('barrio_id').setDisabled(true);
            this.getField('direccion').setDisabled(true);
            this.getField('ciudad_id').setError(false,'');
            this.getField('barrio_id').setError(false,'');
            this.getField('direccion').setValue('');
            this.getField('direccion').setError(false,'');
        }
    }

    successTraerCiudades(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('ciudad_id').setDisabled(false);
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.ciudades_id;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {codigo_ciudad: item.codigo_ciudad};
                opciones.push(dataOp);
            });
            this.getField('ciudad_id').setOptions(opciones);
            this.getField('ciudad_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un pais que se habia seleccionado con anterioridad, no se setee el mismo departamento, sino que solo liste el select con los departamentos correspondientes al país seleccionado.
            this.getField('ciudad_id').setError(false,'');
        } else {
            this.getField('ciudad_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
        }
    }
    
    traerDepartamentos(){
        // console.log('Paises_id:\n',this.getField('paises_id').getValue());
        if (this.getField('paises_id').getValue() !== '' && this.getField('paises_id').getValue() !== ' ') {
            let datos={ 
                datos: {
                    pais_id: this.getField('paises_id').getValue()
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-dptosgeografico', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerDepartamentos,
                    error: this.error_,
                    general: this.generalFormatPmv/* ,
                    showMessage: false */
            });
        }else{
            this.getField('departamento_geografico_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('ciudad_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('barrio_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('departamento_geografico_id').setDisabled(true);
            this.getField('ciudad_id').setDisabled(true);
            this.getField('barrio_id').setDisabled(true);
            this.getField('direccion').setDisabled(true);
            this.getField('departamento_geografico_id').setError(false,'');
            this.getField('ciudad_id').setError(false,'');
            this.getField('barrio_id').setError(false,'');
            this.getField('direccion').setValue('');
            this.getField('direccion').setError(false,'');
        }
    }

    successTraerDepartamentos(data){
        // console.log('data successTraerDepartamentos:\n',data);
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('departamento_geografico_id').setDisabled(false);
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.departamentos_geograficos_id;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {codigo_departamento_geografico: item.codigo_departamento_geografico};
                opciones.push(dataOp);
            });
            this.getField('departamento_geografico_id').setOptions(opciones);
            this.getField('departamento_geografico_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un pais que se habia seleccionado con anterioridad, no se setee el mismo departamento, sino que solo liste el select con los departamentos correspondientes al país seleccionado.
            this.getField('departamento_geografico_id').setError(false,'');
        } else {
            this.getField('departamento_geografico_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
        }
    }
    
    traerSedePorNit(campo_nit){
        if (this.getField(`${campo_nit}`).valid()) {
            let datos={ 
                datos: {
                    nit: this.getField(`${campo_nit}`).getValue()
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: (data)=>{this.successTraerSedePorNit(data,campo_nit)},
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successTraerSedePorNit(data,campo_nit){
        // console.log('Data successTraerSedePorNit:\n',data);
        // console.log('campo_nit successTraerSedePorNit:\n',campo_nit);
        if (data.estado_p === 200 && data.data[0].sede >= 1) {
            campo_nit === 'nit_basicos' ? this.getField('sede_basicos').setValue(data.data[0].sede) : this.getField('sede').setValue(data.data[0].sede);
        }else if (data.estado_p === 404 && data.data.length === 0) {
            campo_nit === 'nit_basicos' ? this.getField('sede_basicos').setValue(1) : this.getField('sede').setValue(this.getField('sede_basicos').getValue());
        }
    }
    
    calcularDigitoNit(campo_nit){
        if (this.getField(`${campo_nit}`).valid()) {
            let digito = "".calcularDigitoVerificacion(this.getField(`${campo_nit}`).getValue());
            campo_nit === 'nit_basicos' ? this.getField('verifica_basicos').setValue(digito) : this.getField('verifica').setValue(digito);
            this.traerSedePorNit(campo_nit);
        }
    }
    
    tipoDocumento() {
        if (this.getField('nombre_tipo_documento').getValue() === 'NIT') {
            this.getField('nombre').setDisabled(false);
            this.getField('nom1').setDisabled(true);
            this.getField('nom1').setError(false, '');
            this.getField('nom2').setDisabled(true);
            this.getField('nom2').setError(false, '');
            this.getField('ape1').setDisabled(true);
            this.getField('ape1').setError(false, '');
            this.getField('ape2').setDisabled(true);
            this.getField('ape2').setError(false, '');
            this.getField('nombre').setValue('');
            this.getField('nombre').setError(false, '');
            this.getField('nom1').setValue('.');
            this.getField('nom2').setValue('.');
            this.getField('ape1').setValue('.');
            this.getField('ape2').setValue('.');
            this.getField('otro_nombre').setValue('');
        } else {
            this.getField('nombre').setDisabled(true);
            this.getField('nom1').setDisabled(false);
            this.getField('nom1').setError(false, '');
            this.getField('nom2').setDisabled(false);
            this.getField('nom2').setError(false, '');
            this.getField('ape1').setDisabled(false);
            this.getField('ape1').setError(false, '');
            this.getField('ape2').setDisabled(false);
            this.getField('ape2').setError(false, '');
            this.getField('nombre').setValue('');
            this.getField('nombre').setError(false, '');
            this.getField('nom1').setValue('.');
            this.getField('nom2').setValue('.');
            this.getField('ape1').setValue('.');
            this.getField('ape2').setValue('.');
            this.getField('otro_nombre').setValue('.');
        }
    }
    
    validarCamposApellidos() {
        if ((this.getField('ape1').getValue() !== '' && this.getField('ape1').getValue() !== '.' && this.getField('ape1').valid() && this.getField('ape2').valid()) ||
            (this.getField('ape2').getValue() !== '' && this.getField('ape2').getValue() !== '.' && this.getField('ape2').valid() && this.getField('ape1').valid())) {
            this.getField('ape1').setError(false, '');
            this.getField('ape2').setError(false, '');
        } else if (((this.getField('ape1').getValue()).replace(/\u0020/g, '') === '' || this.getField('ape1').getValue() === '.') &&
            ((this.getField('ape2').getValue()).replace(/\u0020/g, '') === '' || this.getField('ape2').getValue() === '.')) {
            this.getField('ape1').valid();
            this.getField('ape2').valid();
        }
    }
    
    concatenarNombre() {
        let nom1 = this.getField('nom1').getValue();
        let nom2 = this.getField('nom2').getValue();
        let ape1 = this.getField('ape1').getValue();
        let ape2 = this.getField('ape2').getValue();
        let unionCampos = '';
        let arrayNombreFinal = [];
        let arrayUnionCampos = `${nom1},${nom2},${ape1},${ape2}`.split(/\u002C/g);// Se crea un array  partir de las comas (,), la expresión regular que tiene el "split" es del carácter coma.
        arrayUnionCampos.forEach((item) => {// La idea con este forEach es crear un arreglo sólo con nombres y apellidos con un valor diferente a punto (.)
            if (item.replace(/\u0020/g, '') !== '' && item.replace(/\u0020/g, '') !== '.') {// El replace se hace para eliminar los espacios
                arrayNombreFinal.push(item);
            }
        });
        let concatenarNombre = '';
        arrayNombreFinal.forEach((item) => {// Lo que se hace con este forEach es crear una cadena con los nombres y apellidos que están en el arreglo "arrayNombreFinal"
            concatenarNombre += `${item} `;
        });
        unionCampos = concatenarNombre.substring(0, concatenarNombre.length - 1);// Se quita el último carácter de la cadena, que para este caso sería un espacio.

        let estado = true;
        if (this.getField('nom1').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('nom2').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('ape1').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('ape2').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (unionCampos.replace(/\u0020/g, '') !== '' && estado) {
            estado = true;
        }else{
            estado= false;
        }

        if (estado) {
            this.getField('nombre').setValue(unionCampos);
        } else if (this.getField('nombre').getValue() !== '') {
            this.getField('nombre').setValue('');
            this.getField('nombre').setError(false, '');
        }
    }
    
    validarDatosFrm(boton) {
        switch (boton) {
            case "continuar_basicos":
                // this.getField('TabJaivana_60').setNextTab();//Temporal
                if(this.getField('tipo_documento_id').valid() && this.getField('nit_basicos').valid() && 
                this.getField('verifica_basicos').valid() && this.getField('sede_basicos').valid() && 
                this.getField('ape1').valid() && this.getField('ape2').valid() && 
                this.getField('nom1').valid() && this.getField('nom2').valid() && 
                this.getField('nombre').valid() && this.getField('otro_nombre').valid()/*  &&
                this.getField('codigo_interno').valid() */){
                    if (this.getField('nombre_tipo_documento').getValue() === 'NIT') {
                        this.getField('TabJaivana_60').setNextTab();
                    }
                    if (this.getField('nombre_tipo_documento').getValue() !== 'NIT') {
                        if ((this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
                            this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '')) {
                                    this.getField('TabJaivana_60').setNextTab();
                        } else {
                            this.getField('confirmModalCustom').setTitleAndContent('Error', `Mínimo debe ir un nombre con un apellido`)
                            this.getField('confirmModalCustom').setClickDialog(() => {
                                this.getField('confirmModalCustom').toggle(false);
                                if (this.getField('ape1').getValue() === '.' && this.getField('ape2').getValue() === '.') {
                                    this.getField('ape1').setError(true, '* Este campo es requerido.');
                                    this.getField('ape2').setError(true, '* Este campo es requerido.');
                                }
                                if (this.getField('nom1').getValue() === '.' && this.getField('nom2').getValue() === '.') {
                                    this.getField('nom1').setError(true, '* Este campo es requerido.');
                                    this.getField('nom2').setError(true, '* Este campo es requerido.');
                                }
                            });
                            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                            this.getField('confirmModalCustom').toggle(true);
                        }
                    }
                }
                break;

            case "continuar_geograficos":
                // this.getField('TabJaivana_60').setNextTab();//Temporal
                if(this.getField('paises_id').valid() && this.getField('departamento_geografico_id').valid() && 
                this.getField('ciudad_id').valid() && this.getField('barrio_id').valid() && this.getField('direccion').valid() &&
                this.getField('codigo_departamento_geografico').valid() && this.getField('codigo_pais').valid() &&
                this.getField('departamento_geografico_id').valid() && this.getField('codigo_ciudad').valid() && 
                this.getField('codigo_barrio').valid()){
                    this.getField('TabJaivana_60').setNextTab();
                }
                break;

            case "continuar_contacto":
                // this.getField('TabJaivana_60').setNextTab();//Temporal
                if(this.getField('telefonos').valid() && this.getField('faxes').valid() && this.getField('email_factura_electronica').valid() && 
                this.getField('email_secundario_facturacion_electronica').valid()){
                    this.getField('TabJaivana_60').setNextTab();
                }
                break;

            case "continuar_cliente":
                // this.getField('TabJaivana_60').setNextTab();//Temporal
                if(this.getField('codigo_vendedor').valid() && this.getField('nombre_vendedor').valid() && this.getField('vendedor_id').valid() && 
                this.getField('codigo_negocio').valid() && this.getField('nombre_negocio_clientes').valid() && this.getField('negocio_id').valid() && 
                this.getField('canal_cliente').valid() && this.getField('descripcion_cliente').valid() && this.getField('canalesclientes_id').valid() && 
                this.getField('codigo_oficio').valid() && this.getField('nombre_oficio').valid() && this.getField('oficio_id').valid() && 
                this.getField('observacion').valid() && this.getField('observaciones').valid()){
                    this.getField('nit').setValue(this.getField('nit_basicos').getValue());
                    this.calcularDigitoNit('nit');
                    this.getField('TabJaivana_60').setNextTab();
                }
                break;

            case "cancelar":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.establecerPropiedades('4,6,8,10,12,16,17,20,22,24,26,28,30,32,34,36,37,39,42,44,46,48,49,51,54,55,56,58,59,60,62,63,64,66,67,68,70,72,77,79,81,83,84,89,91,93','value,error','false');
                    this.establecerPropiedades('22,26,30,34','error','false');
                    this.getField('TabJaivana_60').reInit();
                    this.getField('confirmModalCustom').toggle(false); 
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "finalizar_identificacion":
                this.getField('confirmModalCustom').setTitleAndContent(`Documento: ${this.getField('nit').getValue()}`, `DV: ${this.getField('verifica').getValue()}, Sede: ${this.getField('sede').getValue()}`);
                this.getField('confirmModalCustom').setClickDialog(() => {
                    if (this.getField('nit').valid() && this.getField('verifica').valid() && this.getField('sede').valid()) {
                        this.datosAgregar={ datos:{}};
                        this.datosAgregar.datos['tipo_documento_id']=this.getField('tipo_documento_id').getValue();
                        this.datosAgregar.datos['ape1']=this.getField('ape1').getValue();
                        this.datosAgregar.datos['ape2']=this.getField('ape2').getValue();
                        this.datosAgregar.datos['nom1']=this.getField('nom1').getValue();
                        this.datosAgregar.datos['nom2']=this.getField('nom2').getValue();
                        this.datosAgregar.datos['otro_nombre']=this.getField('otro_nombre').getValue();
                        this.datosAgregar.datos['nombre']=this.getField('nombre').getValue();
                        this.datosAgregar.datos['codigo_pais']=this.getField('codigo_pais').getValue();
                        this.datosAgregar.datos['paises_id']=this.getField('paises_id').getValue();
                        this.datosAgregar.datos['codigo_departamento_geografico']=this.getField('codigo_departamento_geografico').getValue();
                        this.datosAgregar.datos['departamento_geografico_id']=this.getField('departamento_geografico_id').getValue();
                        this.datosAgregar.datos['codigo_ciudad']=this.getField('codigo_ciudad').getValue();
                        this.datosAgregar.datos['ciudad_id']=this.getField('ciudad_id').getValue();
                        this.datosAgregar.datos['codigo_barrio']=this.getField('codigo_barrio').getValue();
                        this.datosAgregar.datos['barrio_id']=this.getField('barrio_id').getValue();
                        this.datosAgregar.datos['direccion']=this.getField('direccion').getValue();
                        this.datosAgregar.datos['telefonos']=this.getField('telefonos').getValue();
                        this.datosAgregar.datos['faxes']=this.getField('faxes').getValue();
                        this.datosAgregar.datos['email_factura_electronica']=this.getField('email_factura_electronica').getValue();
                        this.datosAgregar.datos['email_secundario_facturacion_electronica']=this.getField('email_secundario_facturacion_electronica').getValue();
                        this.datosAgregar.datos['codigo_vendedor']=this.getField('codigo_vendedor').getValue();
                        this.datosAgregar.datos['vendedor_id']=this.getField('vendedor_id').getValue();
                        this.datosAgregar.datos['codigo_negocio']=this.getField('codigo_negocio').getValue();
                        this.datosAgregar.datos['negocio_id']=this.getField('negocio_id').getValue();
                        this.datosAgregar.datos['canal_cliente']=this.getField('canal_cliente').getValue();
                        this.datosAgregar.datos['codigo_oficio']=this.getField('codigo_oficio').getValue();
                        this.datosAgregar.datos['observacion']=this.getField('observacion').getValue();
                        this.datosAgregar.datos['observaciones']=this.getField('observaciones').getValue();
                        this.datosAgregar.datos['nit']=this.getField('nit').getValue();
                        this.datosAgregar.datos['verifica']=this.getField('verifica').getValue();
                        this.datosAgregar.datos['sede']=this.getField('sede').getValue();
                        let datos=this.datosAgregar;
                            this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: '5', operacion_tipo: 'crear' };
                            this.service.send(
                            {
                                    endpoint: this.constant.formConfiguration(),
                                    method:'POST',
                                    body: datos,
                                    success: this.successCrearTercero,
                                    error: this.error_,
                                    general: this.generalFormatPmv
                            });
                    }
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;

            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }

    successCrearTercero(data){
        // console.log('Este es el data de successCrearTercero:\n',data);
        if (data.estado_p === 200 ){
            this.alertGeneral.toggle(true, 'El Tercero se creó correctamente', 'success');
            this.establecerPropiedades('4,6,8,10,12,16,17,20,22,24,26,28,30,32,34,36,37,39,42,44,46,48,49,51,54,55,56,58,59,60,62,63,64,66,67,68,70,72,77,79,81,83,84,89,91,93','value,error','false');
            this.establecerPropiedades('22,26,30,34','error','false');
            this.getField('TabJaivana_60').reInit();
        }else{
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }else {

                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    //*** fin  los botones y los success

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }
}
FormJaivana.addController('maes-terceros', CustomTerceros);
export default CustomTerceros;