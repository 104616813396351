import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomConfigurarDocumento extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                           = this.initForm.bind(this);
        this.gridOptions                        = Object.assign({},this.gridOptions);
        this.agregarcuenta                      = this.agregarcuenta.bind(this);
        this.deshabilitar                       = this.deshabilitar.bind(this);
        this.deshabilitar1                      = this.deshabilitar1.bind(this);
        this.successTraerDatos                  = this.successTraerDatos.bind(this);
        this.traerDocumentoEquivalente          = this.traerDocumentoEquivalente.bind(this);
        this.successtraerDocumentoEquivalente   = this.successtraerDocumentoEquivalente.bind(this);
        this.limpiarDocuemtoEquivalente         = this.limpiarDocuemtoEquivalente.bind(this);
        this.cargarCuentas                      = this.cargarCuentas.bind(this);
        this.successcargarCuentas               = this.successcargarCuentas.bind(this);
        this.eliminar_cuenta                    = this.eliminar_cuenta.bind(this);
        this.successeliminar_cuenta             = this.successeliminar_cuenta.bind(this);
        this.guadarDocumento                    = this.guadarDocumento.bind(this);
        this.successguadarDocumento             = this.successguadarDocumento.bind(this);
        this.validarnumero                      = this.validarnumero.bind(this);
        this.errores                            = 0;
        this.limpiarerrores                     = this.limpiarerrores.bind(this);
    }

    initForm(){
        console.log('Formulario CustomConfigurarDocumento,  @version: jdesk_10.0.001, @author:Patricia López Sánchez')
        this.getField('radio_cuentas').setValue("cuenta_total");
        
        this.getField('codigo_cuenta').setDisabled(true);
        this.getField('agregar_cuenta').setDisabled(true);
        this.getField('eliminar_cuenta').setDisabled(true);
        this.getField('guardar').setDisabled(true);

        this.getField('nombre_documento').setOnChange(this.deshabilitar)
        this.getField('centro_costo_nombre').setOnChange(this.deshabilitar1)
        this.getField('numero_inicial').setKeyUp(this.validarnumero)
        this.getField('numero_final').setKeyUp(this.validarnumero)
        this.getField('fecha_inicial').setOnChange(this.validarFechas)
        this.getField('fecha_final').setOnChange(this.validarFechas)
        this.getField("agregar_cuenta").setClick(this.agregarcuenta);
        this.getField("eliminar_cuenta").setClick(this.eliminar_cuenta);
        this.getField("guardar").setClick(this.guadarDocumento);
    } 

    deshabilitar(){
        if(this.getField('nombre_documento').getValue() === ''){

            let fecha_actual = new Date().toISOString().split('T')[0];
            this.getField('codigo_cuenta').setDisabled(true);
            this.getField('agregar_cuenta').setDisabled(true);
            this.getField('eliminar_cuenta').setDisabled(true);
            this.getField('guardar').setDisabled(true);

            this.getField('rejilla').toggle(false);
            this.getField('centro_costo_codigo').setValue('');
            this.getField('centro_costo_nombre').setValue('');
            this.getField('radio_operacion').setValue('');
            this.getField('radio_contabilizar').setValue('');
            this.getField('clave_tecnica').setValue('');
            this.getField('prefijo').setValue('');
            this.getField('numero_resolucion').setValue('');
            this.getField('numero_inicial').setValue('');
            this.getField('numero_final').setValue('');
            this.getField('codigo_documento1').setValue('');
            this.getField('nombre_documento1').setValue('');
            this.getField('codigo_cuenta').setValue('');
            this.getField('nombre_cuenta').setValue('');
            this.getField('fecha_inicial').setValue(fecha_actual);
            this.getField('fecha_final').setValue(fecha_actual);
            this.limpiarerrores();
        }
    }

    deshabilitar1(){
        if(this.getField('centro_costo_nombre').getValue() !== ''){
            this.getField('codigo_cuenta').setDisabled(false);
            this.getField('agregar_cuenta').setDisabled(false);
            this.getField('eliminar_cuenta').setDisabled(false);
            this.getField('guardar').setDisabled(false);
            this.traerDocumentoEquivalente();
        }else{
            let fecha_actual = new Date().toISOString().split('T')[0];
            this.getField('codigo_cuenta').setDisabled(true);
            this.getField('agregar_cuenta').setDisabled(true);
            this.getField('eliminar_cuenta').setDisabled(true);
            this.getField('guardar').setDisabled(true);

            this.getField('rejilla').toggle(false);
            this.getField('radio_operacion').setValue('');
            this.getField('radio_contabilizar').setValue('');
            this.getField('clave_tecnica').setValue('');
            this.getField('prefijo').setValue('');
            this.getField('numero_resolucion').setValue('');
            this.getField('numero_inicial').setValue('');
            this.getField('numero_final').setValue('');
            this.getField('codigo_documento1').setValue('');
            this.getField('nombre_documento1').setValue('');
            this.getField('codigo_cuenta').setValue('');
            this.getField('nombre_cuenta').setValue('');
            this.getField('fecha_inicial').setValue(fecha_actual);
            this.getField('fecha_final').setValue(fecha_actual);
            this.limpiarerrores();
        }
    }

    validarnumero(){
        if (this.getField('numero_inicial').valid() && this.getField('numero_final').valid()) {
            if(this.getField("numero_inicial").getValue() > this.getField("numero_final").getValue()){
                this.getField("numero_inicial").setError(true,'El número inicial no puede ser mayor al número final.')
                this.errores++
            }else{
                this.errores=0;
                this.getField("numero_inicial").setError(false,'');
            }
            if(this.getField("numero_final").getValue() < this.getField("numero_inicial").getValue()){
                this.getField("numero_final").setError(true,'El número final no puede ser menor al número inicial.')
                this.errores++
                return false
            }else{
                this.errores=0;
                this.getField("numero_final").setError(false,'')
            }
            return true
        }else{
            //Se realiza este codigo para limpiar el error de los campos numero para que despues nos muestre el error de validacion desde el modelo 
            this.getField('numero_inicial').setError(false,'');
            this.getField('numero_final').setError(false,'');
            this.getField('numero_inicial').valid();
            this.getField('numero_final').valid();
        }
        
    }

    validarFechas(){
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("fecha_inicial").getValue());
        let fecha_hasta = new Date(this.getField("fecha_final").getValue());

        if(fecha_desde > fecha_actual){
            this.getField("fecha_inicial").setError(true,"No puede ser mayor a la fecha final.");
            return false;
        }else{
            this.getField("fecha_inicial").setError(false,"");
        }
    
        if(fecha_hasta < fecha_desde){
            this.getField("fecha_final").setError(true,"No puede ser menor a la fecha inicial.");
            return false;
        }else{
            this.getField("fecha_final").setError(false,"");
        }
        return true;  
    }

    agregarcuenta(){
        if(this.getField('codigo_documento').valid() && this.getField('centro_costo_codigo').valid() && this.getField('codigo_documento1').valid()
         && this.getField('codigo_cuenta').valid() && this.getField('nombre_cuenta').valid()){
            let datos = { datos: {
                id:this.getField('id').getValue(),
                codigo_documento: this.getField('codigo_documento').getValue(),
                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                codigo_cuenta:this.getField('codigo_cuenta').getValue(),
                radio_cuentas:this.getField('radio_cuentas').getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'cont-configdocumentosoporte', operacion: 'validarexiste', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        } 
    }

    successTraerDatos(data){
        if (data.estado_p === 200 )
        {
            let numberEncabezadoArray = Object.keys(data.data);
            let mensaje ="";
            numberEncabezadoArray.forEach(item => {                
                if (item === "errores"){
                    mensaje =data.data.errores;
                }
            });

            this.getField('codigo_cuenta').setValue('');
            this.getField('nombre_cuenta').setValue('');

            if(mensaje !== ''){
                this.alertGeneral.toggle(true,'Error '+mensaje.mensaje,'error');
            }else{
                this.cargarCuentas();
            }
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true,'No hay datos','error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traerDocumentoEquivalente(){
        if(this.getField('centro_costo_nombre').getValue() !== ''){
            this.getField('confirmModalCustom').setTitleAndContent('¡Informativo!', ' Por favor espere. Se está consultando la información del documento.');
            this.getField('confirmModalCustom').setClickDialog(() => {});
            this.getField("confirmModalCustom").setVisibleCancel('.');
            this.getField("confirmModalCustom").setButtonConfirm(`.`);
            this.getField('confirmModalCustom').toggle(true)
            let datos = { datos: {
                codigo_documento: this.getField('codigo_documento').getValue(),
                centro_costo_codigo:this.getField('centro_costo_codigo').getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'cont-configdocumentosoporte', operacion: 'traerDocumentoEquivalente', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successtraerDocumentoEquivalente,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successtraerDocumentoEquivalente(data){
        if (data.estado_p === 200 )
        {
            this.getField('id').setValue(data.data[0].id)
            if (data.data[0].tipo_operacion === 1) {
                this.getField('radio_operacion').setValue('1');
            } else {
                this.getField('radio_operacion').setValue('2');
            }
            if (data.data[0].contabilizar === 'S') {
                this.getField('radio_contabilizar').setValue('S');
            } else {
                this.getField('radio_contabilizar').setValue('N');
            }
            
            this.getField('clave_tecnica').setValue(data.data[0].llave_tecnica);
            this.getField('prefijo').setValue(data.data[0].prefijo_documento);
            this.getField('numero_resolucion').setValue(data.data[0].numero_resolucion);
            this.getField('numero_inicial').setValue(data.data[0].ninicio);
            this.getField('numero_final').setValue(data.data[0].nfinal);
            this.getField('codigo_documento1').setValue(data.data[0].documento_nota);
            this.getField('nombre_documento1').setValue(data.data[0].nombre);
            this.getField('fecha_inicial').setValue(data.data[0].finicio);
            this.getField('fecha_final').setValue(data.data[0].ffinal);
        
            this.cargarCuentas();
        }
        else 
        {
            if(data.estado_p===404) {
                this.getField('id').setValue(0)
                this.getField('confirmModalCustom').toggle(false)
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    cargarCuentas(){
        let datos = { datos: {
            codigo_documento: this.getField('codigo_documento').getValue(),
            centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
            radio_cuentas:this.getField('radio_cuentas').getValue()
        }};
    
        this.generalFormatPmv = { tipo_servicio: 'cont-configdocumentosoporte', operacion: 'cargarCuentas', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successcargarCuentas,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successcargarCuentas(data){
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false)
            let configCell = new Map();
            this.getField('rejilla').toggle(true);
            this.gridOptions['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptions,configCell);
        }
        else 
        {
            if(data.estado_p===404) {
                this.getField('confirmModalCustom').toggle(false)
                this.getField('rejilla').toggle(false);
            }
            else {
                this.getField('confirmModalCustom').toggle(false)
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    limpiarDocuemtoEquivalente() {
        let fecha_actual = new Date().toISOString().split('T')[0];
        this.getField('codigo_cuenta').setDisabled(true);
        this.getField('agregar_cuenta').setDisabled(true);
        this.getField('eliminar_cuenta').setDisabled(true);
        this.getField('guardar').setDisabled(true);

        this.getField('rejilla').toggle(false);
        this.getField('codigo_documento').setValue('');
        this.getField('nombre_documento').setValue('');
        this.getField('centro_costo_codigo').setValue('');
        this.getField('centro_costo_nombre').setValue('');
        this.getField('radio_operacion').setValue('');
        this.getField('radio_contabilizar').setValue('');
        this.getField('clave_tecnica').setValue('');
        this.getField('prefijo').setValue('');
        this.getField('numero_resolucion').setValue('');
        this.getField('numero_inicial').setValue('');
        this.getField('numero_final').setValue('');
        this.getField('codigo_documento1').setValue('');
        this.getField('nombre_documento1').setValue('');
        this.getField('codigo_cuenta').setValue('');
        this.getField('nombre_cuenta').setValue('');
        this.getField('fecha_inicial').setValue(fecha_actual);
        this.getField('fecha_final').setValue(fecha_actual);

        this.limpiarerrores();
    }

    limpiarerrores(){
        this.getField('codigo_documento').setError(false,'');
        this.getField('nombre_documento').setError(false,'');
        this.getField('centro_costo_codigo').setError(false,'');
        this.getField('centro_costo_nombre').setError(false,'');
        this.getField('radio_operacion').setError(false,'');
        this.getField('radio_contabilizar').setError(false,'');
        this.getField('clave_tecnica').setError(false,'');
        this.getField('prefijo').setError(false,'');
        this.getField('numero_resolucion').setError(false,'');
        this.getField('numero_inicial').setError(false,'');
        this.getField('numero_final').setError(false,'');
        this.getField('codigo_documento1').setError(false,'');
        this.getField('nombre_documento1').setError(false,'');
        this.getField('codigo_cuenta').setError(false,'');
        this.getField('nombre_cuenta').setError(false,'');
    }

    eliminar_cuenta(){
        if(this.getField('codigo_cuenta').getValue() !== '' && this.getField('nombre_cuenta').getValue() !== ''){
            let datos = { datos: {
                codigo_documento: this.getField('codigo_documento').getValue(),
                codigo_cuenta: this.getField('codigo_cuenta').getValue(),
                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                radio_cuentas:this.getField('radio_cuentas').getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'cont-configdocumentosoporte', operacion: 'eliminar_cuenta', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successeliminar_cuenta,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Error debe seleccionar una cuenta.', 'error');
        }
    }

    successeliminar_cuenta(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'La cuenta se elimino con exito.', 'success');
            this.getField('codigo_cuenta').setValue('');
            this.getField('nombre_cuenta').setValue('');
            this.cargarCuentas();
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, data.data.mensaje, 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    guadarDocumento(){
        let valFechas=this.validarFechas();
        if(valFechas===false){
          this.errores++;
        }else{
            this.errores=0;
            let numero = this.validarnumero();
            if(numero===false){
                this.errores++;
            }else{
                this.errores=0;
            }
        }
        if(this.errores===0){
            if(this.getField('codigo_documento').valid() && this.getField('centro_costo_codigo').valid() &&
            this.getField('radio_operacion').valid() && this.getField('radio_contabilizar').valid() && this.getField('clave_tecnica').valid()
            && this.getField('prefijo').valid() && this.getField('numero_resolucion').valid() && this.getField('numero_inicial').valid()
            && this.getField('numero_final').valid() && this.getField('codigo_documento1').valid() && this.getField('fecha_inicial').valid()
            && this.getField('fecha_final').valid()){
                this.getField('confirmModalCustom').setTitleAndContent('¡Informativo!', ' Por favor espere. Se está guardando la información del documento.');
                this.getField('confirmModalCustom').setClickDialog(() => {});
                this.getField("confirmModalCustom").setVisibleCancel('.');
                this.getField("confirmModalCustom").setButtonConfirm(`.`);
                this.getField('confirmModalCustom').toggle(true)

                let datos = { datos: {
                    id:this.getField('id').getValue(),
                    codigo_documento: this.getField('codigo_documento').getValue(),
                    centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                    radio_operacion:this.getField('radio_operacion').getValue(),
                    radio_contabilizar:this.getField('radio_contabilizar').getValue(),
                    clave_tecnica:this.getField('clave_tecnica').getValue(),
                    prefijo:this.getField('prefijo').getValue(),
                    numero_resolucion:this.getField('numero_resolucion').getValue(),
                    numero_inicial:this.getField('numero_inicial').getValue(),
                    numero_final:this.getField('numero_final').getValue(),
                    codigo_documento1:this.getField('codigo_documento1').getValue(),
                    fecha_inicial:this.getField('fecha_inicial').getValue(),
                    fecha_final:this.getField('fecha_final').getValue()
                }};
            
                this.generalFormatPmv = { tipo_servicio: 'cont-configdocumentosoporte', operacion: 'guadarDocumento', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successguadarDocumento,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }else{
            this.alertGeneral.toggle(true, 'Existen campos con errores','error');
        }
    }

    successguadarDocumento(data){
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'El documento se actualizo con exito.', 'success');
            this.limpiar=true;
            this.limpiarDocuemtoEquivalente();
        }
        else 
        {
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error', 'Error al guardar el documento');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }
}
FormJaivana.addController("cont-configdocumentosoporte",CustomConfigurarDocumento);
export default CustomConfigurarDocumento