import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomPuntosHistoria
 * @author: Patricia Lopez Sanchez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001.1
 **/
class CustomPuntosHistoria extends FormJaivana.form {
    constructor(props) {
        
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.gridOptions                    = Object.assign({},this.gridOptions);
        this.gridOptionsOpciones            = Object.assign({},this.gridOptionsOpciones);
        this.gridOptionsOperaciones            = Object.assign({},this.gridOptionsOperaciones);
        this.llenarCantidadTablas           = this.llenarCantidadTablas.bind(this);
        this.total_tablas                   = this.total_tablas.bind(this);
        this.totalopcion                    = this.totalopcion.bind(this);
        this.totaloperaciones               = this.totaloperaciones.bind(this);
        this.total_riesgo                   = this.total_riesgo.bind(this);
        this.total_esfuerzo                 = this.total_esfuerzo.bind(this);
        this.limpiar                        = this.limpiar.bind(this);
        this.llenar_rejilla                 = this.llenar_rejilla.bind(this);
        this.llenar_rejilla2                = this.llenar_rejilla2.bind(this);
        this.llenar_rejilla3                = this.llenar_rejilla3.bind(this);
        this.crear_opcion_desarrollo        = this.crear_opcion_desarrollo.bind(this);
        this.crear_opcion2                  = this.crear_opcion2.bind(this);
        this.crear_operaciones              = this.crear_operaciones.bind(this);
        this.successCrearOpcion             = this.successCrearOpcion.bind(this);
        this.successCrearOpciones           = this.successCrearOpciones.bind(this);
        this.successCrearOperaciones        = this.successCrearOperaciones.bind(this);
        this.successCrearPuntoHistoria      = this.successCrearPuntoHistoria.bind(this);
        this.limpiar_formulario             = this.limpiar_formulario.bind(this);
        this.setButtonDeleteOpcion          = this.setButtonDeleteOpcion.bind(this);
        this.eliminarOpcion                 = this.eliminarOpcion.bind(this);
        this.confirmDeleteCustom            = this.confirmDeleteCustom.bind(this);
        this.successDelete                  = this.successDelete.bind(this);
        this.setButtonDeleteOperaciones     = this.setButtonDeleteOperaciones.bind(this);
        this.eliminarOperaciones            = this.eliminarOperaciones.bind(this);
        this.confirmDeleteOperacionesCustom = this.confirmDeleteOperacionesCustom.bind(this);
        this.successDeleteoperaciones       = this.successDeleteoperaciones.bind(this);
        this.cantidadtablaChange            = this.cantidadtablaChange.bind(this);
        this.cantidadopcionesChange         = this.cantidadopcionesChange.bind(this);
        this.cantidadoperacionesChange      = this.cantidadoperacionesChange.bind(this);
        this.camposvisibles                 = this.camposvisibles.bind(this);
        this.actualizar                     = this.actualizar.bind(this);
        this.successActualizar              = this.successActualizar.bind(this);
        this.validar_formulario             = this.validar_formulario.bind(this);
        this.successValidar                 = this.successValidar.bind(this);                
        this.riesgo=0;
        this.esfuerzo=0;
        this.opciones=[];
        this.operaciones=[];
    }   

    initForm(){
     
        console.log('Formulario CustomPuntosHistoria,  @version: jdesk_1.01.0001.1, @author: Patricia Lopez Sanchez, @updateBy Jose Albeiro Marin')
        this.llenarCantidadTablas();

        this.getField('opciones').setDisabled(true);
        this.getField('operaciones').setDisabled(true);
        this.getField('cantidad_opcion').setDisabled(true);
        this.getField('cantidad_operacion').setDisabled(true);
        this.getField('agregar2').setDisabled(true);
        this.getField('agregar3').setDisabled(true);

        this.getField('cantidad_tablas').setOnChange(this.total_tablas);
        this.getField('cantidad_opcion').setOnChange(this.totalopcion);
        this.getField('cantidad_operacion').setOnChange(this.totaloperaciones);
        this.getField('total_riesgo').setDisabled(true);
        this.getField('total_esfuerzo').setDisabled(true);
        this.getField('total_puntoshistoria').setDisabled(true);
     
        this.getField('total_tablas').setOnChange(this.total_tablas);
        this.getField('total_opciones').setOnChange(this.totalopcion);
        this.getField('total_operaciones').setOnChange(this.totaloperaciones);
                
        this.getField('total_riesgo').setOnChange(this.total_riesgo);
        this.getField('total_esfuerzo').setOnChange(this.total_esfuerzo);

        this.getField('agregar1').setClick(this.validar_formulario);
        this.getField('agregar2').setClick(this.crear_opcion2);
        this.getField('agregar3').setClick(this.crear_operaciones);

        this.getField('finalizar').setClick(this.actualizar);
    }

    llenarCantidadTablas(){
        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
        for (let index = 0; index < 101; index++) {
            let dataOp = {}
            dataOp['value'] = index;
            dataOp['text'] = index
            dataOp['campos_cambian'] = {total_tablas: this.getField('cantidad_tablas').getValue()===0?this.getField('total_tablas').setValue(0):0.5 };
            opciones.push(dataOp);
        }
        this.getField('cantidad_tablas').setOptions(opciones);
    }

    total_tablas(){
        this.getField('total_historia_tablas').setValue(this.getField('cantidad_tablas').getValue()*this.getField('total_tablas').getValue());
    }

    totalopcion(){
        this.getField('total_historia_opciones').setValue(this.getField('cantidad_opcion').getValue()*this.getField('total_opciones').getValue());
        if(this.getField('cantidad_opcion').getValue() === 0){
            this.getField('cantidad_opcion').setError(false, '');
        }
    }

    totaloperaciones(){
        this.getField('total_historia_operaciones').setValue(this.getField('cantidad_operacion').getValue()*this.getField('total_operaciones').getValue());
        if(this.getField('cantidad_operacion').getValue() === 0){
            this.getField('cantidad_operacion').setError(false, '');
        }
    }

    total_riesgo(){
        //se restan los valores anteriores de riesgo y ezfuerzo
        this.getField('total_puntoshistoria').setValue(this.getField('total_puntoshistoria').getValue() - this.riesgo)
        //se suman los nuevos valores de riesgo y ezfuerzo
        this.getField('total_puntoshistoria').setValue(this.getField('total_puntoshistoria').getValue() + this.getField('total_riesgo').getValue())
    
        this.riesgo=this.getField('total_riesgo').getValue();
   
    }
    
    total_esfuerzo(){
        //se restan los valores anteriores de riesgo y ezfuerzo
        this.getField('total_puntoshistoria').setValue(this.getField('total_puntoshistoria').getValue()  - this.esfuerzo)
        //se suman los nuevos valores de riesgo y ezfuerzo
        this.getField('total_puntoshistoria').setValue(this.getField('total_puntoshistoria').getValue() + this.getField('total_esfuerzo').getValue())
    
        this.esfuerzo=this.getField('total_esfuerzo').getValue();
   
    }

    validar_formulario(){
        if (this.getField('formulario').valid() && this.getField('cantidad_tablas').getValue() !==''){
            let datos = { datos: {
                formulario:this.getField('formulario').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: '20', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.successValidar,
                      error: this.error_,
                      general: this.generalFormatPmv
              });
        }else{
            this.getField('cantidad_tablas').setError(true, '* Este campo es requerido.');

        }
    }

    successValidar(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'Error el formulario que intentas crear ya existe en la tabla.' , 'error');
        }else if(data.estado_p === 404) {
            this.crear_opcion_desarrollo();
        }  
    }

    crear_opcion_desarrollo(){
        if (this.getField('formulario').valid() && this.getField('cantidad_tablas').valid()){
            let datos = { datos: {
                formulario:this.getField('formulario').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'crear_opcion', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.successCrearOpcion,
                      error: this.error_,
                      general: this.generalFormatPmv
              });
        }
    }

    successCrearOpcion(data){
        
        if (data.estado_p === 200) {

            // this.getField('formulario').setDisabled(true);
            this.getField('jestimacionopciondesarrollo_id').setValue(data.data[0].jaivana_estimacion_opcion_desarrollo_id);
            this.nombre_formulario=this.getField('formulario').getValue();

            let datos = { datos: {
                cantidad_tablas:this.getField('cantidad_tablas').getValue(),
                total_tablas:this.getField('total_tablas').getValue(),
                total_riesgo:0,
                total_esfuerzo:0,
                jestimacionopciondesarrollo_id:this.getField('jestimacionopciondesarrollo_id').getValue(),
                riesgo:1,
                esfuerzo:1,
                total: this.getField('total_historia_tablas').getValue()
            }};

            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: '5', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCrearPuntoHistoria,
                error: this.error_,
                general: this.generalFormatPmv
            });

        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }  
    }

    successCrearPuntoHistoria(data){
        if (data.estado_p === 200) {

            this.limpiar();

            let datos = { datos: {
                jestimacionopciondesarrollo_id:this.getField('jestimacionopciondesarrollo_id').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: '1_6', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.llenar_rejilla,
                      error: this.error_,
                      general: this.generalFormatPmv
              });
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    llenar_rejilla(data){
        if (data.estado_p === 200) {
            this.total_historia= data.data[0].total_global;
            this.idprincipal=data.data[0].id;
            let nuevoarray=[];
            let datos = {
                opcion_desarrollo : data.data[0].opcion_desarrollo,
                cantidad_tabla : data.data[0].cantidad_tabla,
                puntoshistoria_tablas : data.data[0].puntoshistoria_tablas,
                total_global : data.data[0].total_global
            }

            nuevoarray.push(datos)
            this.getField('total_puntoshistoria').setValue(this.total_opciones+this.total_operaciones+this.total_historia+this.riesgo+this.esfuerzo); 

            let configCell = new Map();
            configCell.set('cantidad_tabla', { cellRenderer: function (props) { return props.data.cantidad_tabla }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cantidadtablaChange, sortable: true, filter: false, field: 'cantidad_tabla' });
            this.getField('rejilla_principal').toggle(true);
            this.gridOptions['rowData'] = nuevoarray;
            this.getField('rejilla_principal').initData(this.gridOptions,configCell);

        }else{
            this.getField('rejilla_principal').toggle(false);
        }
        
    }

    cantidadtablaChange(props) {
        let seguir = this.getField('rejilla_principal').cellValid(props.oldValue, props.newValue, /^0*(?:[0-9][0-9]?|100)$/);

        if (isNaN(props.data.cantidad_tabla) || seguir===false) {
            // console.log("entro");
            if(props.newValue > 100){

                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `El valor debe estar entre las longitudes (0,100)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }else{

                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `La columna cantidad_tabla no tiene un formato correspondiente al tipo (Numérico)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }

            let valor_actual = props.oldValue;
            let totales = (props.data.puntoshistoria_tablas * parseFloat(valor_actual));
            let datos = { datos: {
                puntos_historia_id:this.idprincipal,
                cantidad_tablas: parseFloat(valor_actual),
                total: parseFloat(totales)
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'modificarcantidadtabla', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successCrearPuntoHistoria,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }else if(seguir){        
            let valor_actual = props.newValue;
            let totales = (props.data.puntoshistoria_tablas * parseFloat(valor_actual));
            let datos = { datos: {
                puntos_historia_id:this.idprincipal,
                cantidad_tablas: parseFloat(valor_actual),
                total: parseFloat(totales)
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'modificarcantidadtabla', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successCrearPuntoHistoria,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

        }
    }
    
    ////se llena la tabla jaivana_opcion_puntoshistoria
    crear_opcion2(){
        if(this.getField('opciones').valid() && this.getField('cantidad_opcion').valid()){
            let existe=false;

            if(this.opciones.length > 0){
                this.opciones.forEach(opcion => {
                    if(opcion.nombre===this.getField('nombre_opcion').getValue())
                      existe=true;
                });
            }

            if(existe){
                this.alertGeneral.toggle(true, 'Error, la opción '+this.getField('nombre_opcion').getValue()+' ya fue agregada.', 'error');
            }else{
                let datos = { datos: {
                    cantidad_opcion:this.getField('cantidad_opcion').getValue(),
                    total_opciones:this.getField('total_opciones').getValue(),
                    total_historia_opciones:this.getField('total_historia_opciones').getValue(),
                    puntos_historia_id:this.idprincipal,
                    opciones:parseInt(this.getField('opciones').getValue())
                }};
        
                this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: '5_1', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successCrearOpciones,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
            }
        }
    }

    successCrearOpciones(data){
        if (data.estado_p === 200) {
            this.getField('opciones').setValue("");
            this.getField('cantidad_opcion').setValue("");
            this.getField('total_opciones').setValue("");
            this.getField('total_historia_opciones').setValue("");

            let datos = { datos: {
                puntos_historia_id:this.idprincipal
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'traeropcion', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.llenar_rejilla2,
                      error: this.error_,
                      general: this.generalFormatPmv
              });
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    llenar_rejilla2(data){
        if (data.estado_p === 200) {
            this.total_opciones=0;

            let opcion={};
            this.opciones=[];
            for (let i = 0; i < data.data.length; i++) {
                this.total_opciones = this.total_opciones + data.data[i].total; 
                opcion={
                    nombre:data.data[i].opciones
                }
                this.opciones.push(opcion);
            }

            this.getField('total_puntoshistoria').setValue(this.total_opciones+this.total_operaciones+this.total_historia+this.riesgo+this.esfuerzo); 

            let configCell = new Map();
            configCell.set('cantidad_opciones', { cellRenderer: function (props) { return props.data.cantidad_opciones }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cantidadopcionesChange, sortable: true, filter: false, field: 'cantidad_opciones' });
            configCell.set('eliminar', { cellRenderer: this.setButtonDeleteOpcion, sortable: false, filter: false, field: 'eliminar' });
            this.getField('rejilla_opciones').toggle(true);
            this.gridOptionsOpciones['rowData'] = data.data;
            this.getField('rejilla_opciones').initData(this.gridOptionsOpciones,configCell);

        }else{
            this.getField('rejilla_opciones').toggle(false);
        }
        
    }
        
    cantidadopcionesChange(props) {
        // console.log("props",props);
        let seguir = this.getField('rejilla_opciones').cellValid(props.oldValue, props.newValue, /^0*(?:[0-9][0-9]?|100)$/);

        if (isNaN(props.data.cantidad_opciones) || seguir===false) {
            if(props.newValue > 100){

                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `El valor debe estar entre las longitudes (0,100)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }else{

                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `La columna cantidad_opciones no tiene un formato correspondiente al tipo (Numérico)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }

            let valor_actual = props.oldValue;
            let totales = (props.data.puntoshistoria_opciones * parseFloat(valor_actual));
                        let datos = { datos: {
                puntos_historia_id: props.data.id,
                cantidad_opcion: parseFloat(valor_actual),
                total_historia_opciones: totales
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'modificarcantidadopcion', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successCrearOpciones,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

        }else if(seguir){

            let valor_actual = props.newValue;
            let totales = (props.data.cantidad_opciones * props.data.puntoshistoria_opciones);
            let datos = { datos: {
                puntos_historia_id: props.data.id,
                cantidad_opcion: parseFloat(valor_actual),
                total_historia_opciones: totales
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'modificarcantidadopcion', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successCrearOpciones,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }
        
    setButtonDeleteOpcion(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.eliminarOpcion(id);
        button.setAttribute("id", 'button_Add_' + id);
        div.setAttribute('id', 'div_Add_' + id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    eliminarOpcion(id) {
        this.id_delete = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDeleteCustom);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDeleteCustom() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = { datos: {
            id_opcion: this.id_delete
        }};
        this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'eliminar', operacion_tipo: 'eliminar' };
        this.service.send(
        {
              endpoint: this.constant.formConfiguration(),
              method:'DELETE',
              body: datos,
              success: this.successDelete,
              error: this.error_,
              general: this.generalFormatPmv
        });
    }

    successDelete(data) {
        if (data.estado_p === 200) {
            this.opciones=[];
            this.successCrearOpciones(data);

        }
    }

    ///se llena la tabla operaciones 
    crear_operaciones(){
        if(this.getField('operaciones').valid() && this.getField('cantidad_operacion').valid()){

            let existe1=false;

            if(this.operaciones.length > 0){
                this.operaciones.forEach(operacion => {
                    if(operacion.nombre===this.getField('nombre_operacion').getValue())
                      existe1=true;
                });
            }

            if(existe1){
                this.alertGeneral.toggle(true, 'Error, la operación '+this.getField('nombre_operacion').getValue()+' ya fue agregada.', 'error');
            }else{
                let datos = { datos: {
                    cantidad_operacion:this.getField('cantidad_operacion').getValue(),
                    total_operaciones:this.getField('total_operaciones').getValue(),
                    total_historia_operaciones:this.getField('total_historia_operaciones').getValue(),
                    puntos_historia_id:this.idprincipal,
                    operaciones:parseInt(this.getField('operaciones').getValue())
                }};
        
                this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: '5_2', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successCrearOperaciones,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
            }
        }
    }

    successCrearOperaciones(data){
        if (data.estado_p === 200) {
            this.getField('operaciones').setValue("");
            this.getField('cantidad_operacion').setValue("");
            this.getField('total_operaciones').setValue("");
            this.getField('total_historia_operaciones').setValue("");

            let datos = { datos: {
                puntos_historia_id:this.idprincipal
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'traeroperacion', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.llenar_rejilla3,
                      error: this.error_,
                      general: this.generalFormatPmv
              });
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    llenar_rejilla3(data){
        if (data.estado_p === 200) {
            this.camposvisibles();

            this.total_operaciones=0;
            let operacion={};
            this.operaciones=[];

            for (let i = 0; i < data.data.length; i++) {
                this.total_operaciones = this.total_operaciones + data.data[i].total;
                operacion={
                    nombre:data.data[i].operaciones
                }
                this.operaciones.push(operacion);  
            }

            this.getField('total_puntoshistoria').setValue(this.total_opciones+this.total_operaciones+this.total_historia+this.riesgo+this.esfuerzo); 

            let configCell = new Map();
            configCell.set('cantidad_operaciones', { cellRenderer: function (props) { return props.data.cantidad_operaciones }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cantidadoperacionesChange, sortable: true, filter: false, field: 'cantidad_operaciones' });
            configCell.set('eliminar', { cellRenderer: this.setButtonDeleteOperaciones, sortable: false, filter: false, field: 'eliminar' });
            this.getField('rejilla_operaciones').toggle(true);
            this.gridOptionsOperaciones['rowData'] = data.data;
            this.getField('rejilla_operaciones').initData(this.gridOptionsOperaciones,configCell);

        }else{
            this.getField('rejilla_operaciones').toggle(false);
        }
        
    }
        
    cantidadoperacionesChange(props) {
        // console.log("props",props);
        let seguir = this.getField('rejilla_operaciones').cellValid(props.oldValue, props.newValue, /^0*(?:[0-9][0-9]?|100)$/);
        
        if (isNaN(props.data.cantidad_operaciones) || seguir===false) {
            if(props.newValue > 100){

                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `El valor debe estar entre las longitudes (0,100)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }else{

                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `La columna cantidad_operaciones no tiene un formato correspondiente al tipo (Numérico)`);
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }

            let valor_actual = props.oldValue;
            let totales = (props.data.puntoshistoria_operaciones * parseFloat(valor_actual));
                        let datos = { datos: {
                puntos_historia_id: props.data.id,
                cantidad_operacion: parseFloat(valor_actual),
                total_historia_operaciones: totales
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'modificarcantidadoperaciones', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successCrearOperaciones,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }else if(seguir){

            let valor_actual = props.newValue;
            let totales = (props.data.cantidad_operaciones * props.data.puntoshistoria_operaciones);
            let datos = { datos: {
                puntos_historia_id: props.data.id,
                cantidad_operacion: parseFloat(valor_actual),
                total_historia_operaciones: totales
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'modificarcantidadoperaciones', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successCrearOperaciones,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }
        
    setButtonDeleteOperaciones(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.eliminarOperaciones(id);
        button.setAttribute("id", 'button_Add_' + id);
        div.setAttribute('id', 'div_Add_' + id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    eliminarOperaciones(id) {
        this.id_delete = id;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDeleteOperacionesCustom);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDeleteOperacionesCustom() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = { datos: {
            id_operacion: this.id_delete
        }};
        this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'eliminaroperacion', operacion_tipo: 'eliminar' };
        this.service.send(
        {
              endpoint: this.constant.formConfiguration(),
              method:'DELETE',
              body: datos,
              success: this.successDeleteoperaciones,
              error: this.error_,
              general: this.generalFormatPmv
        });
    }

    successDeleteoperaciones(data) {
        if (data.estado_p === 200) {
            this.operaciones=[];
            this.successCrearOperaciones(data);
        }
    } 

    actualizar() {
        if(this.getField('riesgo').valid() && this.getField('esfuerzo').valid()){
            let datos = { datos: {
                puntos_historia_id: this.idprincipal,
                riesgo:parseInt(this.getField('riesgo').getValue()),
                total_riesgo:this.getField('total_riesgo').getValue(),
                esfuerzo:parseInt(this.getField('esfuerzo').getValue()),
                total_esfuerzo:this.getField('total_esfuerzo').getValue(),
                total:this.getField('total_puntoshistoria').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'heus-puntoshistoria', operacion: 'actualizar', operacion_tipo: 'modificar' };
            this.service.send(
            {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.successActualizar,
                  error: this.error_,
                  general: this.generalFormatPmv
            });
        }
    }

    successActualizar(data) {
        if (data.estado_p === 200) {
            this.limpiar_formulario();
        }
    }  
    
    camposvisibles(){
        this.getField('label6').setVisible(true);
        this.getField('riesgo').setVisible(true);
        this.getField('total_riesgo').setVisible(true);
        this.getField('label7').setVisible(true);
        this.getField('esfuerzo').setVisible(true);
        this.getField('total_esfuerzo').setVisible(true);
        this.getField('label9').setVisible(true);
        this.getField('total_puntoshistoria').setVisible(true);
        this.getField('label10').setVisible(true);
        this.getField('finalizar').setVisible(true);
    }

    limpiar(){
        this.getField('cantidad_tablas').setValue('');
        this.getField('total_tablas').setValue('');
        this.getField('total_historia_tablas').setValue(''); 
        this.getField('cantidad_tablas').setDisabled(true);
        this.getField('total_tablas').setDisabled(true);
        this.getField('total_historia_tablas').setDisabled(true);
        this.getField('agregar1').setDisabled(true);

        this.getField('opciones').setDisabled(false);
        this.getField('operaciones').setDisabled(false);
        this.getField('cantidad_opcion').setDisabled(false);
        this.getField('cantidad_operacion').setDisabled(false);
        this.getField('agregar2').setDisabled(false);
        this.getField('agregar3').setDisabled(false);
    }

    limpiar_formulario(){
        this.getField('rejilla_principal').toggle(false);
        this.getField('rejilla_opciones').toggle(false);
        this.getField('rejilla_operaciones').toggle(false);

        this.getField('jestimacionopciondesarrollo_id').setValue('');
        this.getField('cantidad_tablas').setValue('');
        this.getField('formulario').setValue('');
        this.getField('cantidad_opcion').setValue('');
        this.getField('cantidad_operacion').setValue('');
        this.getField('total_tablas').setValue('');
        this.getField('total_opciones').setValue('');
        this.getField('total_operaciones').setValue('');
        this.getField('total_riesgo').setValue('');
        this.getField('total_esfuerzo').setValue('');
        this.getField('opciones').setValue('');
        this.getField('operaciones').setValue('');
        this.getField('riesgo').setValue('');
        this.getField('esfuerzo').setValue('');
        this.getField('total').setValue('');  
        this.getField('label6').setVisible(false);
        this.getField('riesgo').setVisible(false);
        this.getField('total_riesgo').setVisible(false);
        this.getField('label7').setVisible(false);
        this.getField('esfuerzo').setVisible(false);
        this.getField('total_esfuerzo').setVisible(false);
        this.getField('label9').setVisible(false);
        this.getField('total_puntoshistoria').setVisible(false);
        this.getField('label10').setVisible(false);
        this.getField('finalizar').setVisible(false);

        this.getField('cantidad_tablas').setDisabled(false);
        this.getField('total_tablas').setDisabled(false);
        this.getField('total_historia_tablas').setDisabled(false);
        this.getField('agregar1').setDisabled(false);

        this.getField('opciones').setDisabled(true);
        this.getField('operaciones').setDisabled(true);
        this.getField('cantidad_opcion').setDisabled(true);
        this.getField('cantidad_operacion').setDisabled(true);
        this.getField('agregar2').setDisabled(true);
        this.getField('agregar3').setDisabled(true);

        this.opciones=[];
        this.operaciones=[];
    }
    
}

FormJaivana.addController("heus-puntoshistoria",CustomPuntosHistoria);
export default CustomPuntosHistoria