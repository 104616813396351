import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomDevolucionCompras
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0001
 **/
class CustomDevolucionCompras extends FormJaivana.form {
  constructor(props) {
    ///funciones legalizar-------------->
    super(props);
    this.initForm                          = this.initForm.bind(this);

    //funciones y propiedades grabar compras---->
    this.initGrabarCompras                          = this.initGrabarCompras.bind(this);

    
    this.generarTxt = this.generarTxt.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.llenarSelect = this.llenarSelect.bind(this);
    this.suceesLlenarSelect = this.suceesLlenarSelect.bind(this);
    this.comenzarForm = this.comenzarForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
    this.onSelectionChangedPrincipal                                  = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChangedPrincipal;

    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsComponentes1 = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentes1['onSelectionChanged']           = this.onSelectionChanged;

    this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
    this.formatNumberSaldo                               = this.formatNumberSaldo.bind(this);
    this.primeraVez                               = this.primeraVez.bind(this);
    this.sucessPrimeraVez                               = this.sucessPrimeraVez.bind(this);
    this.crearBotonTabla                               = this.crearBotonTabla.bind(this);
    this.accionProcesar                               = this.accionProcesar.bind(this);
    this.eliminarRegistro                               = this.eliminarRegistro.bind(this);
    this.confirmDelete                               = this.confirmDelete.bind(this);
    this.refescarTabla                               = this.refescarTabla.bind(this);
    this.successEliminar                               = this.successEliminar.bind(this);
    this.sucessRefrescar                               = this.sucessRefrescar.bind(this);
    this.abrirModalAgregar                               = this.abrirModalAgregar.bind(this);

    this.sucursal_ingreso='';
    this.calcularDescuento                               = this.calcularDescuento.bind(this);
    this.calcularItem                               = this.calcularItem.bind(this);
    this.traerCostoBodega                               = this.traerCostoBodega.bind(this);
    this.sucessTraerCostoBodega                               = this.sucessTraerCostoBodega.bind(this);
    this.sucessAlterno                               = this.sucessAlterno.bind(this);
    this.traerPropiedades                               = this.traerPropiedades.bind(this);
    this.sucessTraerPropiedades                               = this.sucessTraerPropiedades.bind(this);
    this.validacionesAgregar                               = this.validacionesAgregar.bind(this);
    this.autoriza_ordenesc='';

    this.grabarItem                               = this.grabarItem.bind(this);
    this.sucessGrabarItem                               = this.sucessGrabarItem.bind(this);
    this.grabarItem2                               = this.grabarItem2.bind(this);
    this.lista={};

    this.validarCuenta                         =this.validarCuenta.bind(this);
    this.sucessValidarCuenta                         =this.sucessValidarCuenta.bind(this);
    this.validarGrabar                         =this.validarGrabar.bind(this);
    this.validarBodega                         =this.validarBodega.bind(this);
    this.sucessValidarBodega                         =this.sucessValidarBodega.bind(this);

    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);

    this.consecutivo_grabado='';
    this.operacion_actual='';


    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);

    this.fecha_hoy='';
    this.xdetalle='';


    //nuevas
    this.mostrarMensajeAdicional                         =this.mostrarMensajeAdicional.bind(this);
    this.llenarSelectRete                         =this.llenarSelectRete.bind(this);
    this.suceesLlenarSelectRete                         =this.suceesLlenarSelectRete.bind(this);
    this.traerImpuestosNit                         =this.traerImpuestosNit.bind(this);
    this.sucessTraerImpuestos                         =this.sucessTraerImpuestos.bind(this);
    this.validarReteIva                         =this.validarReteIva.bind(this);
    this.validarReteICA                         =this.validarReteICA.bind(this);
    this.validarRetefuente                         =this.validarRetefuente.bind(this);
    this.validarCree                         =this.validarCree.bind(this);
    this.gripOptionesDocumentos = Object.assign({}, this.gridOptions);
    this.onSelectionChangedDocumentos                                = this.onSelectionChangedDocumentos.bind(this);
    this.gripOptionesDocumentos['onSelectionChanged']           = this.onSelectionChangedDocumentos;
    this.gripOptionesUbicaciones= Object.assign({}, this.gridOptions);
    this.onSelectionChangedUbicaciones                              = this.onSelectionChangedUbicaciones.bind(this);
    this.gripOptionesUbicaciones['onSelectionChanged']           = this.onSelectionChangedUbicaciones;
    this.gridOptionsBodega= Object.assign({}, this.gridOptions);
    this.gridOptionsBodega['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsProductosProveedor= Object.assign({}, this.gridOptions);
    this.gridOptionsProductosProveedor['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsAuditoriaDiferencias= Object.assign({}, this.gridOptions);
    this.gridOptionsAuditoriaDiferencias['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsAuditoriaFaltantes= Object.assign({}, this.gridOptions);
    this.gridOptionsAuditoriaFaltantes['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsAuditoriaDemas= Object.assign({}, this.gridOptions);
    this.gridOptionsAuditoriaDemas['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsImpArti= Object.assign({}, this.gridOptions);
    this.gridOptionsImpArti['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsOtrosImp= Object.assign({}, this.gridOptions);
    this.gridOptionsOtrosImp['onSelectionChanged']           = this.onSelectionChanged;


    this.gridOptionsImpuestosGeneral= Object.assign({}, this.gridOptions);
    this.gridOptionsImpuestosGeneral['onSelectionChanged']           = this.onSelectionChanged;

    this.onSelectionChangedFacturas                                 = this.onSelectionChangedFacturas.bind(this);
    this.gripOptionesFacturas = Object.assign({}, this.gridOptions);
    this.gripOptionesFacturas['onSelectionChanged']           = this.onSelectionChangedFacturas;
    

    this.validarDocumento                                  = this.validarDocumento.bind(this);
    this.calcularNeto                                  = this.calcularNeto.bind(this);

    this.modificarUbicacion                                  = this.modificarUbicacion.bind(this);
    this.sucessCambiarUbicacion                                  = this.sucessCambiarUbicacion.bind(this);
    this.cambiarUbicacion                                  = this.cambiarUbicacion.bind(this);
    this.traerUbicaciones                                  = this.traerUbicaciones.bind(this);


    this.saldosubicacion                                  = this.saldosubicacion.bind(this);
    this.successSaldosUbicacion                                  = this.successSaldosUbicacion.bind(this);
    this.traersaldoBodegas                                  = this.traersaldoBodegas.bind(this);
    this.successSaldosBodegas                                  = this.successSaldosBodegas.bind(this);
    this.successSalcalcularPrecioSugeridodosBodegas                                  = this.calcularPrecioSugerido.bind(this);
    this.mostrarMensajeValidandoInformacion                                  = this.mostrarMensajeValidandoInformacion.bind(this);


    this.successEliminarProveedores                                  = this.successEliminarProveedores.bind(this);
    this.confirmDeleteProveedores                                  = this.confirmDeleteProveedores.bind(this);
    this.eliminarRegistroProveedor                                  = this.eliminarRegistroProveedor.bind(this);
    this.setButtonDelete                                  = this.setButtonDelete.bind(this);
    this.sucessConsultarProductosProveedor                                  = this.sucessConsultarProductosProveedor.bind(this);
    this.consultarProductosProveedor                                  = this.consultarProductosProveedor.bind(this);


    this.sucessModificarProductoProveedor                                  = this.sucessModificarProductoProveedor.bind(this);
    this.modificarProductoProveedor                                  = this.modificarProductoProveedor.bind(this);
    this.sucessConsultarExisteProductoProveedor                                  = this.sucessConsultarExisteProductoProveedor.bind(this);
    this.consultarExisteProductoProveedor                                  = this.consultarExisteProductoProveedor.bind(this);
    this.setButtonBotonesTabla                                  = this.setButtonBotonesTabla.bind(this);
    this.traerInfoArticulo                         =this.traerInfoArticulo.bind(this);
    this.sucessTraerArticulo                         =this.sucessTraerArticulo.bind(this);
    this.sucessTraerArticulo2                         =this.sucessTraerArticulo2.bind(this);
    this.validarImpuestos                         =this.validarImpuestos.bind(this);
    this.validarCodigo                         =this.validarCodigo.bind(this);
    this.sucessValidarCodigo                         =this.sucessValidarCodigo.bind(this);
    this.crearProductoProveedor                         =this.crearProductoProveedor.bind(this);
    this.sucessCrearProductoProveedor                         =this.sucessCrearProductoProveedor.bind(this);
    this.sucessTraerImpuestosArticulos                         =this.sucessTraerImpuestosArticulos.bind(this);
    this.traerImpuestosArticulo                         =this.traerImpuestosArticulo.bind(this);
    this.calcularFactor                         =this.calcularFactor.bind(this);
    this.abrirOtrosImpuestos                         =this.abrirOtrosImpuestos.bind(this);

    this.consultarOtrosImpuestos                         =this.consultarOtrosImpuestos.bind(this);
    this.sucessTraerOtrosImpuestos                         =this.sucessTraerOtrosImpuestos.bind(this);
    this.setButtonDeleteImp                         =this.setButtonDeleteImp.bind(this);
    this.eliminarRegistroImp                         =this.eliminarRegistroImp.bind(this);
    this.confirmDeleteImp                         =this.confirmDeleteImp.bind(this);
    this.successEliminarImp                         =this.successEliminarImp.bind(this);
    this.limpiarCamposImp                         =this.limpiarCamposImp.bind(this);
    this.traerValoresSwitch                         =this.traerValoresSwitch.bind(this);
    this.successTraerValoresSwitch                         =this.successTraerValoresSwitch.bind(this);

    this.modificarImpArt                         =this.modificarImpArt.bind(this);
    this.successModificarImpArt                         =this.successModificarImpArt.bind(this);
    this.crearImpArt                         =this.crearImpArt.bind(this);
    this.successCrearImpArt                         =this.successCrearImpArt.bind(this);
    this.validarSiExisteImpArt                         =this.validarSiExisteImpArt.bind(this);
    this.successValidarSiExisteImpArt                         =this.successValidarSiExisteImpArt.bind(this);
    this.sucessModificarPrecio                         =this.sucessModificarPrecio.bind(this);
    this.actualizarPrecio                         =this.actualizarPrecio.bind(this);
    this.validarCodigoAlterno                         =this.validarCodigoAlterno.bind(this);
    this.traerCostoBodegaYAdmn                         =this.traerCostoBodegaYAdmn.bind(this);
    this.sucessTraerCostoBodegaYAdmn                         =this.sucessTraerCostoBodegaYAdmn.bind(this);

    this.validarInactivosYRepetidos                         =this.validarInactivosYRepetidos.bind(this);
    this.sucessValidarInactivosYRepetidos                         =this.sucessValidarInactivosYRepetidos.bind(this);
    this.traerConsecutivoGrabar                         =this.traerConsecutivoGrabar.bind(this);
    this.sucessTraerConsecutivoGrabar                         =this.sucessTraerConsecutivoGrabar.bind(this);

    this.grabarDevolucion                         =this.grabarDevolucion.bind(this);
    this.successGrabarDevolucion                         =this.successGrabarDevolucion.bind(this);

  
    this.succesImpuestosGeneral                         =this.succesImpuestosGeneral.bind(this);
    this.abrirImpuestosGeneral                         =this.abrirImpuestosGeneral.bind(this);
    this.abrirModificarCantidad                   =this.abrirModificarCantidad.bind(this);
    this.modificarCantidad                         =this.modificarCantidad.bind(this);
    this.successModificarCantidad                   =this.successModificarCantidad.bind(this);
    this.traerConsecutivo                         =this.traerConsecutivo.bind(this);
    this.sucessTraerConsecutivo                   =this.sucessTraerConsecutivo.bind(this);
    
    this.successTraerFactura                   =this.successTraerFactura.bind(this);
    this.validarNumFactura                         =this.validarNumFactura.bind(this);
    this.validarFacturaSeleccionada                   =this.validarFacturaSeleccionada.bind(this);
    this.successCargarFactura                         =this.successCargarFactura.bind(this);
    this.cargarFactura                   =this.cargarFactura.bind(this);
    this.limpiarCamposValores                   =this.limpiarCamposValores.bind(this);
    this.cargarUbicaciones                   =this.cargarUbicaciones.bind(this);
    this.successCargarUbicaciones                   =this.successCargarUbicaciones.bind(this);
    this.validarSaldoUbicacion                   =this.validarSaldoUbicacion.bind(this);
    this.sucessValidarSaldoUbicacion                   =this.sucessValidarSaldoUbicacion.bind(this);
    this.validarSaldosUbicacion                   =this.validarSaldosUbicacion.bind(this);
    this.successValidarSaldosUbicacion                   =this.successValidarSaldosUbicacion.bind(this);
    this.limpiarCamposSinFactura                   =this.limpiarCamposSinFactura.bind(this);
    this.changeKeyUpCustom                  = this.changeKeyUpCustom.bind(this);


    //propiedades
    this.existe_ubicaciones='';
    this.retefuente='';
    this.reteiva='';
    this.reteica='';
    this.tarifa_reteica='';
    this.tarifa_cree_compras='';
    this.tarifa_reteiva='';
    this.proveedor_tipo_dias_vencimiento='';
    this.dias_vence_proveedor=0;
    this.wms_externo_bodega='';
    this.wms='';
    this.wms_nuevo='';
    this.facturacion_electronica_propia='';
    this.row_documento=[];
    this.row_ubicacion=[];
    this.row_facturas=[];
    this.fila_seleccionada=[];
    this.requiere_orden_compra='';
    this.precio_incluye_iva='';
    this.regimen='';
    this.calcula_iva_item='';
    this.xsucursal='';
    this.lasucursal='';
    this.existe_impuestos=false;
    this.existe_impuestos_articulo=false;
    this.cons_impart=false;

    this.costo_por_bodega='';
    this.costo_administrativo='';
    this.numero_decimales_compras=0;
    this.calculando_descuento=false;

    //auxiliares
    this.valor_anterior='0';
    this.modificando='false';
    this.count=0;

    this.xfecha='';
    this.xnumero='';
    this.xsucursal='';

    this.extension='txt';
    this.calculando_descuento=false;

    this.calculo_factor=false;
    this.fecha_emision_actual='';
    this.calcular_cree_automatico='';
    this.array_direcciones=[];
    //controlar que solo muestre una vez el mensaje al inicio
    this.mostrar_configuracion_ivalida=true;
    //saber cuandos registros hay en la tabla
    this.num_registros=0;
    //saber si esta refrescando la tabla
    this.limpiando=false;

    this.prioridad_barras='N';
    this.operacion_seleccionada='';
    this.num_ubicaciones=0;

    this.datos_articulo_aux=[];
    this.respuesta_completa=true;
  }
  
  initForm() {
    console.log("Formulario CustomDevolucionCompras,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.initGrabarCompras();


    //funciones nuevas para devolucion compras

    //--->modal detalle
    this.getField('bt_detalle').setClick(()=>{
      this.getField('modal_modificar_detalle').handleClickOpen();
      this.getField('detalle').setValue(this.xdetalle);
    });
    this.getField('bt_cancelar_detalle').setClick(()=>{this.getField('modal_modificar_detalle').handleClose();});
    this.getField('bt_modificar_detalle').setClick(()=>{
        if(this.getField('detalle').valid()){
          this.xdetalle=this.getField('detalle').getValue();
          this.getField('modal_modificar_detalle').handleClose();
      }
    }); 
    // modal facturas
    this.getField('bt_cargar_factura').setClick(()=>{this.validarNumFactura();});
    this.getField('bt_aceptar_factura').setClick(()=>{this.validarFacturaSeleccionada();});
    this.getField('bt_cancelar_factura').setClick(()=>{this.getField('modal_facturas').handleClose();});
  }

  limpiarCamposValores(){
    this.getField('total_subtotal').setValue(0);
    this.getField('valor_iva').setValue(0);
    this.getField('impuestos').setValue(0);
    this.getField('total_pedido').setValue(0);
    this.getField('reteiva').setValue('');
    this.getField('select_retencion').setValue(' ');
    this.getField('retenido').setValue('');
    this.getField('tarifa_reteica').setValue('');
    this.getField('reteica').setValue('');
    this.getField('ajuste').setValue('');
    this.getField('valor_neto').setValue('');
    this.getField('cree').setValue('');
  }

  cargarFactura(){
    this.mostrarMensajeValidandoInformacion();
    let op='cargarfactura';
    let datos={ datos: {
        xsucursal:this.xsucursal,
        sucursal_ingreso:this.sucursal_ingreso,
        nit:this.getField('nit').getValue(),
        sede:this.getField('sede').getValue(),
        factura_numero:this.getField('factura_numero').getValue(),
        xfecha:this.getField('xfecha').getValue(),//fecha factura
        select_direccion:this.getField('select_direccion').getValue(),
        existe_ubicaciones:this.existe_ubicaciones,
    }};
    if(this.existe_ubicaciones===false)
      op='cargarfacturasinubicacion';
    this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: op, operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successCargarFactura,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  successCargarFactura(data){
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200 )
      {    
        this.refescarTabla();
      }else {
          if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
          }
          else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
      }
  }

  validarFacturaSeleccionada(){
    if(this.row_facturas.length>0){
      this.getField('factura_numero').setError(false,'');
      //si la bodega existe en el array lo setea,sino queda el ultimo seteado
      this.array_direcciones.forEach((element)=>{
        if(element.codigo===this.row_facturas[0].bodega){
          this.getField('select_direccion').setValue(this.row_facturas[0].bodega);
        }
      });
      this.getField('factura_numero').setValue(this.row_facturas[0].numero);
      this.getField('xfecha').setValue(this.row_facturas[0].fecha);
      this.getField('modal_facturas').handleClose();
      this.cargarFactura();
    }else{
      this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    }
  }

  validarNumFactura(){
    if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('select_direccion').valid()){
      if(this.getField('factura_numero').getValue().toString().length>0){
        if(this.getField('factura_numero').valid()){
          this.limpiarCamposValores();
          this.mostrarMensajeGenerando();
          let datos={ datos: {
              xsucursal:this.xsucursal,
              sucursal_ingreso:this.sucursal_ingreso,
              nit:this.getField('nit').getValue(),
              sede:this.getField('sede').getValue(),
              factura_numero:this.getField('factura_numero').getValue(),
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'traerfactura', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successTraerFactura,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
        }
      }else{
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Cargar Factura', 'No ha proporcionado un número de factura de compra. ¿Desea ver todas las facturas de este proveedor?');
        this.getField('confirmModalCustom').setClickDialog(() => { 
            this.getField('confirmModalCustom').toggle(false);
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                xsucursal:this.xsucursal,
                sucursal_ingreso:this.sucursal_ingreso,
                nit:this.getField('nit').getValue(),
                sede:this.getField('sede').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'traerfactura', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerFactura,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("SI"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField("confirmModalCustom").setButtonCancel("NO")
        this.getField('confirmModalCustom').toggle(true);
      }
    }
    
  }

  successTraerFactura(data){
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200 )
      {   
        if(data.data.mensaje_mostrar!==undefined){
          this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
          this.limpiarCamposSinFactura();
        }else if(data.data.array_facturas!==undefined){
          //mostrar  tabla con facturas
          this.getField('devolucion_numero').setValue(data.data.consecutivo);
          this.getField('modal_facturas').handleClickOpen();

          this.row_facturas=[];
          this.getField("rejilla_facturas").toggle(true);
          this.gripOptionesFacturas["rowData"] = data.data.array_facturas;
          let configCell = new Map();
          //configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
          this.getField('rejilla_facturas').initData(this.gripOptionesFacturas,configCell);
        }else{
          this.getField('devolucion_numero').setValue(data.data.consecutivo);
          this.getField('factura_numero').setError(false,'');
          //si la bodega existe en el array lo setea,sino queda el ultimo seteado
          this.array_direcciones.forEach((element)=>{
            if(element.codigo===data.data.bodega){
              this.getField('select_direccion').setValue(data.data.bodega);
            }
          });
          this.getField('xfecha').setValue(data.data.fecha_factura);
          this.cargarFactura();
        }
      }else {
          if(data.estado_p===404) {
            if(data.data.mensaje_mostrar!==undefined)
              this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
            else
              this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
          }
          else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
      }
  }


    //custom de gracioón de compras funciones---->

    initGrabarCompras() {
        ///console.log("Formulario CustomGrabacionDeCompras,  @version: jdesk_jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
        //this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        //bloqueo campos no editables
        this.getField('retenido').setDisabled(true);
        this.getField('reteiva').setDisabled(true);
        this.getField('tarifa_reteica').setDisabled(true);
        this.getField('reteica').setDisabled(true);
        this.getField('cree').setDisabled(true);

        //si cambia la factura validar si hay mas de un registro, si es así limpiar campos
        this.getField('factura_numero').setOnChange(()=>{
          this.getField('factura_numero').setError(false,'');
          if(this.num_registros>1 && this.limpiando===false) 
            this.limpiarCamposSinFactura();
        });


        this.getField('nit').setError(false,'');
        this.traerPropiedades();
        this.llenarSelectRete();
        this.getField('bt_reteiva').setClick(this.validarReteIva);
        this.getField('bt_reteica').setClick(this.validarReteICA);
        this.getField('bt_retefuente').setClick(this.validarRetefuente);
        this.getField('bt_cree').setClick(this.validarCree);
        this.getField('ajuste').setOnChange(()=>{this.getField('ajuste').setError(false,'');this.calcularNeto();});
    

        //select ubicaciones
        this.getField('select_ubicacion_agregar').setOnChange(()=>{
          this.getField('ubicacion_agregar').setError(false,'');
          if(this.getField('select_ubicacion_agregar').getValue()!=='' && this.getField('select_ubicacion_agregar').getValue()!==' ')
            this.getField('ubicacion_agregar').setValue(this.getField('select_ubicacion_agregar').getValue());
          else
            this.getField('ubicacion_agregar').setValue('');
        });

    
    
        //actualizar precio
        this.getField('bt_actualizar_precio').setClick(this.actualizarPrecio);
    
  
    
        //otros impuestos
        this.getField('bt_impuestos_articulo').setClick(this.abrirOtrosImpuestos);
        this.getField('impuesto_id').setOnChange(this.traerValoresSwitch);
        this.getField('bt_cancelar_impuesto').setClick(this.limpiarCamposImp);
        this.getField('bt_aceptar_impuesto').setClick(this.validarSiExisteImpArt);
  
        
        
        //documentos
        this.getField('bt_aceptar_documentos').setClick(this.validarDocumento);
        this.getField('bt_cancelar_documentos').setClick(()=>{this.getField('modal_documentos').handleClose()});
    
        //ubicaciones
        this.getField('bt_aceptar_ubicacion').setClick(this.modificarUbicacion);
        this.getField('bt_cancelar_ubicacion').setClick(()=>{this.getField('modal_ubicaciones').handleClose()});
    
    
        this.getField('valor_neto').setOnChange((props)=>{ this.getField('valor_neto').setError(false,'');});
    
    
        //botones tabla principal
        this.getField('cambiar_ubicacion').setClick(()=>{this.cambiarUbicacion();});
        this.getField('agregar_item').setClick(this.abrirModalAgregar);
        this.getField('bt_impuestos').setClick(()=>{this.abrirImpuestosGeneral();})
        
    
        this.getField('select_retencion').setOnChange(this.validarRetefuente);
        this.getField('select_direccion').setOnChange(()=>{
          if(this.getField('select_direccion').getValue()!=='' && this.getField('select_direccion').getValue()!==' ')
            this.validarBodega();
        });
    
        this.getField('comenzar').setClick(this.comenzarForm);
    
        this.getField('sede').setOnChange((props)=>{
          this.getField('sede').setError(false,'');
          this.getField('nombre').setError(false,'');
          this.getField('responsable_iva').setError(false,'');
          if(this.getField('sede').getValue()!=='' && this.getField('sede').getValue()!==' '){
            this.validarCuenta();
          }
        });
        this.getField('codigo_articulo').setOnChange((props)=>{
          this.getField('nombre_articulo').setValue('');
          this.getField('descuento').setValue('0');
          this.getField('precio_sin_iva').setValue('0');
          this.getField('impuestos2').setValue('0');
          this.getField('cantidad_articulo').setValue('0');

          this.getField('descuento').setError(false,'');
          this.getField('precio_sin_iva').setError(false,'');
          this.getField('impuestos2').setError(false,'');
          this.getField('cantidad_articulo').setError(false,'');
          this.getField('descuento').setDisabled(false);
          this.respuesta_completa=false;
        });


        this.getField('nombre_articulo').setOnChange((props)=>{
          if(this.getField('nombre_articulo').getValue()==='' || this.getField('nombre_articulo').getValue()===' '){
            this.getField('rejilla_ubicacion').toggle(false);
            this.getField('rejilla_bodega').toggle(false);
            this.getField('rejilla_impuestos_articulo').toggle(false);
            //limpiar select select_ubicacion_agregar
            this.getField('select_ubicacion_agregar').setVisible(false);
            this.getField('select_ubicacion_agregar').setError(false,'');
              this.getField('ubicacion_agregar').setError(false,'');
            if(this.existe_ubicaciones===true && this.wms==='S' && this.wms_nuevo==='S'){
              this.getField('select_ubicacion_agregar').setValue(' ');
              this.getField('ubicacion_agregar').setValue('');
            }
          }
        });
    
        this.getField('costo').setOnChange((props)=>{
    
            this.getField('nombre_articulo').setError(false,'');
            this.getField('tarifa_iva').setError(false,'');
            this.getField('responsable_iva').setError(false,'');
            if(this.getField('costo').getValue()!=='' && this.getField('costo').getValue()!==' '){
              this.validarCodigoAlterno();
              this.count+=1;
            }
        });

        //sobreescrivir keyups
        this.getField('codigo_articulo').setKeyUp((e)=>{this.changeKeyUpCustom(e)});
        
        this.getField("codigo_articulo_aux").setOnChange(()=>{
          if(this.getField('codigo_articulo_aux').getValue()!=='' && this.prioridad_barras==='S' && this.cambiar_prioridad!==false){
              this.validarCodigo();
          }else if(this.prioridad_barras!=='S'){
              //aquí no tiene prioridad entonces trae directamente el artículo
              if(this.getField('codigo_articulo_aux').getValue()!=='' && this.respuesta_completa===true && this.datos_articulo_aux.length<=1){
                this.sucessTraerArticulo2(this.datos_articulo_aux);
              }else if(this.datos_articulo_aux.length>1 && this.getField("codigo_articulo_aux").getValue()===this.getField("codigo_articulo").getValue()){
                this.sucessTraerArticulo2(this.datos_articulo_aux);
              }
          }
        });

        this.getField("codigo_articulo").setCustomSuccess((props)=>{
          this.datos_articulo_aux=props;
          this.respuesta_completa=true;
          if(this.prioridad_barras!=='S'){
            //para forzar a consumir de nuevo el onchange
            this.getField("codigo_articulo_aux").setValue('cod_aux');
          }
          if(props.length<=1 && this.operacion_seleccionada!=='F9'){
              if(this.prioridad_barras==='S'){
                  this.cambiar_prioridad=false;
                  this.validarCodigo();
              }else{
                  this.cambiar_prioridad=true;
              }
          }else{
              this.cambiar_prioridad=true;
          }
        });
    
        this.getField('descuento').setOnBlur(this.calcularDescuento);
        this.getField('precio_sin_iva').setOnChange(()=>{
            //se pone en falso ya que se cambio el costo
            this.calculo_factor=false;
            if(this.calculando_descuento===false && this.valor_anterior!==this.getField('precio_sin_iva').getValue()){
              this.getField('descuento').setDisabled(false);
              this.getField('descuento').setValue(0);
            }else{
              this.valor_anterior=this.getField('precio_sin_iva').getValue();
            }
            this.calcularItem();
        });
        this.getField('rentabilidad').setOnChange(()=>{this.calcularPrecioSugerido();});
        this.getField('cantidad_articulo').setOnChange(this.calcularItem);
        this.getField('impuestos2').setOnChange(this.calcularItem);
        this.getField('cancelar_agregar').setClick(()=>{this.getField('modal_agregar').handleClose()});
        this.getField('grabar_agregar').setClick(this.validacionesAgregar);
    
        //modal productos proveedor
        this.getField('br_consultar_proveedor').setClick(this.consultarProductosProveedor);
        this.getField('bt_procesar_proveedor').setClick(this.consultarExisteProductoProveedor);
    
        //grabar orden
        this.getField('grabar').setClick(this.validarGrabar);
      
    
          //imprimir
          this.getField('radio_correo').setValue("N");
          this.getField('generar_archivo').setClick(()=>this.procesar());
    
          //para balances globales
          this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
              this.getField('ch_pdf').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='excel';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pdf').setValueSwitch (false);
              this.operacion_actual='pantalla';
              this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
          });
          this.getField('modal_imprimir').setCloseButton(()=>{
            this.limpiarCampos();
            this.getField('nit').setValue('');
            this.getField('sede').setValue('');
            this.getField('nombre').setValue('');
            this.getField('responsable_iva').setValue('');
          });
      }

      changeKeyUpCustom = (e) =>{
        let keycode = (e.keyCode ? e.keyCode : e.which);
        //keycode 120 = f9_servicio;
        //keycode 13 = enter_servicio;
        if(keycode === 120){
            this.operacion_seleccionada = "F9";
        }else{
            this.operacion_seleccionada = "ENTER-BOTON"; 
        }
        this.getField("codigo_articulo").changeKeyUp(e);
    }
    
      traerConsecutivo(){
        this.mostrarMensajeValidandoInformacion();
        let datos={ datos: {
            xsucursal:this.xsucursal,
            sucursal_ingreso:this.sucursal_ingreso,
            select_direccion:this.getField('select_direccion').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: '1_consecutivo_dev', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerConsecutivo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    
      sucessTraerConsecutivo(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('devolucion_numero').setValue('');
          if (data.estado_p === 200 )
          {    
            this.getField('devolucion_numero').setValue(data.data[0].xvalor);
          }else {
              if(data.estado_p===404) {

                //this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
                //si no trae no es posible grabar la devolución
                //esta validación es para que muestre este mensaje solo una vez.
                if(this.mostrar_configuracion_ivalida){
                  this.mostrar_configuracion_ivalida=false;
                  this.mostrarMensajeAdicional("La sucursal "+this.sucursal_ingreso+" no está configurada para grabar devolución.");
                }
                this.getField('grabar').setDisabled(true);
              }
              else {
                  let respuesta=Object.values(data.data.errores);
                  let keys=Object.keys(data.data.errores);
                  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
              }
          }
      }
      //nuevas funciones
    
      abrirImpuestosGeneral(){
        this.mostrarMensajeGenerando();
        this.getField('bt_impuestos').setDisabled(true);
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: '1_impuestos_general', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.succesImpuestosGeneral,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
      }
    
      succesImpuestosGeneral(data)
      {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('bt_impuestos').setDisabled(false);
        if(data.estado_p===200){
          let configCell = new Map();
          this.gridOptionsImpuestosGeneral['rowData'] = data.data;
          configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
    
          this.getField('rejilla_impuestos_general').toggle(true);
          this.getField('rejilla_impuestos_general').initData(this.gridOptionsImpuestosGeneral, configCell);
          this.getField('modal_impuestos_general').handleClickOpen();
        }else if(data.estado_p === 404){
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      }
    
    
      onSelectionChangedFacturas(){
        this.row_facturas = this.gripOptionesFacturas.api.getSelectedRows();
      }
  

    
      grabarDevolucion(){
        this.getField('grabar').setDisabled(true);
        this.mostrarMensajeGuardando();
        
        let op='';
        let datos={ datos: {  }};

        datos={ datos: {
          xsucursal : this.xsucursal,
          nit : this.getField('nit').getValue(),
          nombre : this.getField('nombre').getValue(),
          sede : this.getField('sede').getValue(),
          sucursal_ingreso : this.sucursal_ingreso,
          select_direccion : this.getField('select_direccion').getValue(),
          existe_ubicaciones:this.existe_ubicaciones,
          devolucion_numero : this.getField('devolucion_numero').getValue(),  
          factura_numero : this.getField('factura_numero').getValue(),
          detalle:this.xdetalle,
          valor_neto:this.getField('valor_neto').getValue(),
          valor_iva:this.getField('valor_iva').getValue().replaceAll('.','').replaceAll(',','.'),
          impuestos:this.getField('impuestos').getValue().replaceAll('.','').replaceAll(',','.'),
        }};
        op='grabardev';
        
        if(this.getField('id_doc').getValue()!=='' && this.getField('id_doc').getValue()!==' ')
            datos.datos.id_doc=this.getField('id_doc').getValue();
    
        if(this.getField('retenido').getValue()!=='' && this.getField('retenido').getValue()!==' '){
          datos.datos.retenido=this.getField('retenido').getValue();
          datos.datos.select_retencion=this.getField('select_retencion').getValue();
        }
        if(this.getField('ajuste').getValue()!=='' && this.getField('ajuste').getValue()!==' '){
          datos.datos.ajuste=this.getField('ajuste').getValue();
        }
        if(this.getField('reteiva').getValue()!=='' && this.getField('reteiva').getValue()!==' '){
          datos.datos.reteiva=this.getField('reteiva').getValue();
        }
        if(this.getField('reteica').getValue()!=='' && this.getField('reteica').getValue()!==' '){
          datos.datos.reteica=this.getField('reteica').getValue();
        }
        if(this.getField('cree').getValue()!=='' && this.getField('cree').getValue()!==' '){
          datos.datos.cree=this.getField('cree').getValue();
        }
          
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion:  op, operacion_tipo: 'crear' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.successGrabarDevolucion,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
      }
    
      successGrabarDevolucion(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('grabar').setDisabled(false);
        if(data.estado_p === 200){
          //this.mostrarMensajeAdicional(data.data.mensaje);
          this.alertGeneral.toggle(true, data.data.mensaje, 'success');
          this.xfecha=data.data.xfecha;
          this.xnumero=data.data.xnumero;
          this.xsucursal=data.data.xsucursal;
          this.extension='txt';
          this.generarTxt();
          this.abrirImprimir();
        }else {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
          }
        }
      }
    

      traerConsecutivoGrabar(){
        //se vuelve a validar para traer el ultimo consecutivo por si este cambio
        this.mostrarMensajeValidandoInformacion();
        let datos={ datos: {
            xsucursal:this.xsucursal,
            sucursal_ingreso:this.sucursal_ingreso,
            select_direccion:this.getField('select_direccion').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: '1_consecutivo_dev', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerConsecutivoGrabar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    
      sucessTraerConsecutivoGrabar(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('devolucion_numero').setValue('');
          if (data.estado_p === 200 )
          {    
            this.getField('devolucion_numero').setValue(data.data[0].xvalor);
            this.validarInactivosYRepetidos();
          }else {
              if(data.estado_p===404) {
                  this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
              }
              else {
                  let respuesta=Object.values(data.data.errores);
                  let keys=Object.keys(data.data.errores);
                  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
              }
          }
      }
    

      validarInactivosYRepetidos(){
        this.getField('grabar').setDisabled(true);
        this.getField('confirmModalCustom').toggle(false);
        this.mostrarMensajeValidandoInformacion();
          let datos={ datos: {
            xsucursal:this.xsucursal,
            nit:this.getField('nit').getValue(),
            sede:this.getField('sede').getValue(),
            devolucion_numero:this.getField('devolucion_numero').getValue(),
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'inactivosyrepetidos', operacion_tipo: 'consulta' };
          this.service.send({
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessValidarInactivosYRepetidos,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
          });
      }
    
    
      sucessValidarInactivosYRepetidos(data)
      {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('grabar').setDisabled(false);
        if(data.estado_p===200){
          if(data.data.mensaje_mostrar!==undefined){
            this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
          }else{
            if(this.existe_ubicaciones){
              this.validarSaldosUbicacion();
            }else{
              this.grabarDevolucion();
            }
          }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    
      }

      validarSaldosUbicacion(){
        this.getField('grabar').setDisabled(true);
        this.getField('confirmModalCustom').toggle(false);
        this.mostrarMensajeValidandoInformacion();
          let datos={ datos: {
            select_direccion:this.getField('select_direccion').getValue(),
            sucursal_ingreso:this.sucursal_ingreso,
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'validartodossaldosubi', operacion_tipo: 'consulta' };
          this.service.send({
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successValidarSaldosUbicacion,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
          });
      }

      successValidarSaldosUbicacion(data)
      {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('grabar').setDisabled(false);
        if(data.estado_p===200){
          if(data.data.mensaje_mostrar!==undefined){
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Devolución en Compras', data.data.mensaje_mostrar);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setButtonConfirm("Aceptar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
            this.getField('confirmModalCustom').toggle(true);
          }else{
             this.grabarDevolucion();
          }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    
      }
    

      actualizarPrecio(){
        if(this.getField('codigo_articulo').valid() &&
          this.getField('nombre_articulo').valid() &&
          this.getField('precio_sin_iva').valid() &&
          this.getField('tarifa_iva').valid() &&
          this.getField('rentabilidad').valid() &&
          this.getField('precio').valid()){
            this.getField('bt_actualizar_precio').setDisabled(true);
            let datos={ datos: {
              codigo_articulo: this.getField('codigo_articulo').getValue(),
              precio_sin_iva: this.getField('precio_sin_iva').getValue(),
              tarifa_iva: this.getField('tarifa_iva').getValue(),
              rentabilidad: this.getField('rentabilidad').getValue(),
              precio: this.getField('precio').getValue(),
              sucursal_ingreso:this.sucursal_ingreso
    
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'actualizarprecio', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.sucessModificarPrecio,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
      }
    
      sucessModificarPrecio(data) {
        this.getField('bt_actualizar_precio').setDisabled(false);
        if(data.estado_p === 200){
          this.mostrarMensajeAdicional(data.data.mensaje);         
        }else {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
          }
        }
      }
    
      modificarImpArt(id){
        this.getField('bt_aceptar_impuesto').setDisabled(true);
        let datos={ datos: {
            tarifa_impuesto: this.getField('tarifa_impuesto').getValue(),
            valor_impuesto: this.getField('valor_impuesto').getValue(),
            por_porcentaje: this.getField('ch_por_porcentaje').getValue()==='true'?"S":"N",
            en_compras: this.getField('ch_compras').getValue()==='true'?"S":"N",
            en_ventas: this.getField('ch_ventas').getValue()==='true'?"S":"N",
            afecta_costo: this.getField('ch_afecta_costo').getValue()==='true'?"S":"N",
            value: this.getField('codigo_articulo').getValue(),
            id_articulos_impuestos: id,
            impuesto_id: this.getField('impuesto_id').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '6', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successModificarImpArt,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    successModificarImpArt(data) {
      this.getField('bt_aceptar_impuesto').setDisabled(false);
      if(data.estado_p === 200){
        this.limpiarCamposImp();
        this.consultarOtrosImpuestos();          
      }else {
        if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
      }
      }
    
    
      crearImpArt(){
        this.getField('bt_aceptar_impuesto').setDisabled(true);
          let datos={ datos: {
            tarifa_impuesto: this.getField('tarifa_impuesto').getValue(),
            valor_impuesto: this.getField('valor_impuesto').getValue(),
            por_porcentaje: this.getField('ch_por_porcentaje').getValue()==='true'?"S":"N",
            en_compras: this.getField('ch_compras').getValue()==='true'?"S":"N",
            en_ventas: this.getField('ch_ventas').getValue()==='true'?"S":"N",
            afecta_costo: this.getField('ch_afecta_costo').getValue()==='true'?"S":"N",
            value: this.getField('codigo_articulo').getValue(),
            impuesto_id: this.getField('impuesto_id').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrearImpArt,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    
      successCrearImpArt(data) {
        this.getField('bt_aceptar_impuesto').setDisabled(false);
        if(data.estado_p === 200){
          this.limpiarCamposImp();
          this.consultarOtrosImpuestos();           
        }else {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
          }
        }
      }
    
      validarSiExisteImpArt(){
          this.getField('bt_aceptar_impuesto').setDisabled(true);
          if(this.getField('codigo_impuesto').valid() 
          && this.getField('nombre_impuesto').valid()
          && this.getField('tarifa_impuesto').valid() 
          && this.getField('valor_impuesto').valid() 
          && this.getField('impuesto_id')!==' '){
            
            let datos = { datos: {
              impuesto_id:this.getField("impuesto_id").getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '1_existe', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarSiExisteImpArt,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
          }
      }
      
      successValidarSiExisteImpArt(data){
        this.getField('bt_aceptar_impuesto').setDisabled(false);
        if(data.estado_p===200){
              this.getField('confirmModalCustom').setTitleAndContent('Crear', 'Confirma que desea modificar el registro?');
              this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);this.modificarImpArt(data.data[0].id)});  
              this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
              this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
              this.getField('confirmModalCustom').toggle(true);
        }else if(data.estado_p === 404){
            this.getField('confirmModalCustom').setTitleAndContent('Crear', 'Confirma que desea agregar el registro?');
            this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);this.crearImpArt()});  
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      }
    
    
      traerValoresSwitch(){
        this.getField('nombre_impuesto').setError(false,'');
        if(this.getField('impuesto_id').getValue()!=='' && this.getField('impuesto_id').getValue()!==' '){
          this.getField('codigo_impuesto').setDisabled(true);
          let datos = { datos: {
            id_impuestos:this.getField("impuesto_id").getValue(),
            value:this.getField("codigo_articulo").getValue(),
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-impuestos', operacion: '1_1', operacion_tipo: 'consulta' };
          this.service.send({
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successTraerValoresSwitch,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
          });
        }
    }
    
    successTraerValoresSwitch(data){
      if(data.estado_p===200){
            this.getField('ch_compras').setValueSwitch(data.data[0].en_compras==='S'?true:false);
            this.getField('ch_ventas').setValueSwitch(data.data[0].en_ventas==='S'?true:false);
            this.getField('ch_afecta_costo').setValueSwitch(data.data[0].afecha_costo==='S'?true:false);
            this.getField('tarifa_impuesto').setValue(data.data[0].tarifa);
            this.getField('valor_impuesto').setValue(data.data[0].valor);
            if(data.data[0].por_porcentaje==='N'){
              this.getField('ch_por_valor').setValueSwitch(true);
              this.getField('ch_por_porcentaje').setValueSwitch(false);
            }
            else{
              this.getField('ch_por_porcentaje').setValueSwitch(true);
              this.getField('ch_por_valor').setValueSwitch(false);
            }
            if (data.data[0].por_porcentaje==='S') {
                this.getField('tarifa_impuesto').setDisabled(false);
                this.getField('valor_impuesto').setValue(0);
                this.getField('valor_impuesto').setDisabled(true);
            } else {
                this.getField('tarifa_impuesto').setDisabled(true);
                this.getField('tarifa_impuesto').setValue(0);
                this.getField('valor_impuesto').setDisabled(false);
            }
      }else if(data.estado_p === 404){
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          //this.mostrarMensajeNoHayDatos();
      }else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
    }
    
      limpiarCamposImp(){
        this.getField('bt_aceptar_impuesto').setDisabled(false);
        this.getField('codigo_impuesto').setDisabled(false);
        this.getField('tarifa_impuesto').setDisabled(true);
        this.getField('valor_impuesto').setDisabled(true);
        this.getField('codigo_impuesto').setValue('');
        this.getField('nombre_impuesto').setValue('');
        this.getField('tarifa_impuesto').setValue('');
        this.getField('valor_impuesto').setValue('');
        this.getField('ch_por_porcentaje').setValueSwitch(true);
        this.getField('ch_por_valor').setValueSwitch(false);
        this.getField('ch_compras').setValueSwitch(false);
        this.getField('ch_ventas').setValueSwitch(false);
        this.getField('ch_afecta_costo').setValueSwitch(false);
        this.getField('ch_por_porcentaje').setDisabled(true);
        this.getField('ch_por_valor').setDisabled(true);
        this.getField('ch_compras').setDisabled(true);
        this.getField('ch_ventas').setDisabled(true);
        this.getField('ch_afecta_costo').setDisabled(true);
      }
    
      consultarOtrosImpuestos(){
          if(this.getField('codigo_articulo').valid() &&
          this.getField('nombre_articulo').valid()){
            let datos = { datos: {
              value:this.getField("codigo_articulo").getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '1_todos', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerOtrosImpuestos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
          }
      }

      abrirOtrosImpuestos(){
        if(this.cons_impart){
          if(this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid()){
            this.getField('modal_otros_impuestos').setLabel('Otros impuestos: '+this.getField('codigo_articulo').getValue());
            this.getField('modal_otros_impuestos').handleClickOpen();
            this.limpiarCamposImp();
            this.consultarOtrosImpuestos();
          }
        }else{
          this.mostrarMensajeAdicional("Opción no autorizada! (CONS_IMPART) ");
        }
      }
    
      sucessTraerOtrosImpuestos(data){
        if(data.estado_p===200){
              let configCell = new Map();
              this.gridOptionsOtrosImp['rowData'] = data.data;
              configCell.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('accion', { cellRenderer: this.setButtonDeleteImp, width: 250, sortable: false, filter: false });
    
              this.getField('rejilla_impuestos_creados').toggle(true);
              this.getField('rejilla_impuestos_creados').initData(this.gridOptionsOtrosImp, configCell);
        }else if(data.estado_p === 404){
            this.getField('rejilla_impuestos_creados').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            //this.mostrarMensajeNoHayDatos();
        }else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      }
    
      setButtonDeleteImp(props) {       
        let button = document.createElement("input");
        button.onclick = () => this.eliminarRegistroImp(props.data);
        button.setAttribute("id", 'button_delete_' + props.data.id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "eliminar");
        return this.createElementJaivana(button);
      }
    
      eliminarRegistroImp(props) {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(() => { this.confirmDeleteImp(props) });
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
        this.getField('confirmModalCustom').toggle(true);
      }
    
      confirmDeleteImp(props) {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
              id_articulos_impuestos: props.id,
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'comp-articulosimpuestos', operacion: '7', operacion_tipo: "eliminar" };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successEliminarImp,
                error: this.error_,
                general: this.generalFormatPmv
            });
      }
    
      successEliminarImp(data) {
          if(data.estado_p === 200){
            this.limpiarCamposImp();
            this.consultarOtrosImpuestos();         
          }else {
            if(data.estado_p===404) {
             this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
      }
    
      calcularFactor(){
        if(this.calculo_factor===false){
          let xcosto1 = this.getField('precio_sin_iva').getValue();
          let xtarifa = this.getField('tarifa_iva').getValue();
          xcosto1 = xcosto1 / (1 + (xtarifa / 100));
          this.getField('precio_sin_iva').setValue(xcosto1);
          this.calculo_factor=true;
        }
      }
    
      traerImpuestosArticulo(){
        if(this.getField('nombre_articulo').getValue()!=='' && this.getField('nombre_articulo').getValue()!==' ' && Number(this.getField('total_item').getValue())!==0){
          if(this.getField('nit').valid() &&
          this.getField('sede').valid() &&
          this.getField('nombre').valid()){
            if(this.getField('precio_sin_iva').getValue()>0){
                let datos = { datos: {
                  codigo_articulo:this.getField("codigo_articulo").getValue(),
                  cantidad_articulo: this.getField("cantidad_articulo").getValue(),
                  precio_sin_iva: this.getField("precio_sin_iva").getValue(),
                }};
          
                this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarretencionesitems', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerImpuestosArticulos,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
                });
            }
          }
        }else{
          this.getField('xotros_impuestos').setValue(0);
          this.getField('xt_otros_impuestos').setValue(0);
          this.calcularItem();
        }
        
      }
    
      sucessTraerImpuestosArticulos(data){
        if(data.estado_p===200){
              let configCell = new Map();
              this.gridOptionsImpArti['rowData'] = data.data.impuestos;
              configCell.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total) }, cellStyle: {textAlign:"right"},width: 110});
    
              this.getField('rejilla_impuestos_articulo').toggle(true);
              this.getField('rejilla_impuestos_articulo').initData(this.gridOptionsImpArti, configCell);
              this.getField('xt_otros_impuestos').setValue(data.data.total_impuestos);
              this.getField('xotros_impuestos').setValue(data.data.total_impuestos_unidad);
              
              
        }else if(data.estado_p === 404){
            this.getField('rejilla_impuestos_articulo').toggle(false);
            this.getField('xt_otros_impuestos').setValue(0)
            this.getField('xotros_impuestos').setValue(0);
            //this.mostrarMensajeNoHayDatos();
        }else {
          this.getField('rejilla_impuestos_articulo').toggle(true);
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          this.getField('xt_otros_impuestos').setValue(0)
          this.getField('xotros_impuestos').setValue(0);
        }
        this.calcularItem();
      }
  
      
    
      consultarExisteProductoProveedor(){
        if(this.getField('nit_proveedor').valid() &&
        this.getField('sede_proveedor').valid()  &&
        this.getField('nombre_proveedor').valid() &&
        this.getField('codigo_articulo_proveedor').valid() && 
        this.getField('nombre_articulo_proveedor').valid() &&
        this.getField('articulo_proveedor').valid()){
           let datos = { datos: {
            nit_proveedor:this.getField("nit_proveedor").getValue(),
            codigo_articulo_proveedor:this.getField('codigo_articulo_proveedor').getValue()
           }};
           this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '1_existe', operacion_tipo: 'consulta' };
           this.service.send(
           {
               endpoint: this.constant.formConfiguration(),
               method:'GET',
               body: datos,
               success: this.sucessConsultarExisteProductoProveedor,
               error: this.error_,
               general: this.generalFormatPmv,
               showMessage:false
           });
       }
     }
    
     sucessConsultarExisteProductoProveedor(data){
         if (data.estado_p === 200 )
         {
            if(data.data[0].cuantos>0){
              this.modificarProductoProveedor();
            }else{
              this.crearProductoProveedor();
            }
         }else {
             if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
             }
             else {
               let respuesta=Object.values(data.data.errores);
               this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
             }
         }
      }
    
      modificarProductoProveedor(){
    
        let datos={ datos: {
            nit_proveedor: this.getField('nit_proveedor').getValue(),
            codigo_articulo_proveedor: this.getField('codigo_articulo_proveedor').getValue(),
            articulo_proveedor: this.getField('articulo_proveedor').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '6', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.sucessModificarProductoProveedor,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    sucessModificarProductoProveedor(data) {
      if(data.estado_p === 200){
        this.getField('articulo_proveedor').setValue('');
        this.getField('codigo_articulo_proveedor').setValue('');
        this.getField('nombre_articulo_proveedor').setValue('');
        this.consultarProductosProveedor();          
      }else {
        if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
      }
      }
    
    
      crearProductoProveedor(){
    
          if(this.getField('codigo_articulo_proveedor').valid() && this.getField('nombre_articulo_proveedor').valid()
          && this.getField('articulo_proveedor').valid()){
              let datos={ datos: {
                nit_proveedor: this.getField('nit_proveedor').getValue(),
                codigo_articulo_proveedor: this.getField('codigo_articulo_proveedor').getValue(),
                articulo_proveedor: this.getField('articulo_proveedor').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessCrearProductoProveedor,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
          }
            
      }
    
      sucessCrearProductoProveedor(data) {
        if(data.estado_p === 200){
          this.getField('articulo_proveedor').setValue('');
          this.getField('codigo_articulo_proveedor').setValue('');
          this.getField('nombre_articulo_proveedor').setValue('');
          this.consultarProductosProveedor();          
        }else {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
          }
        }
      }
    
    
      consultarProductosProveedor(){
        if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid()){
           let datos = { datos: {
            nit_proveedor:this.getField("nit_proveedor").getValue(),
           }};
           this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '1_articulo_proveedor', operacion_tipo: 'consulta' };
           this.service.send(
           {
               endpoint: this.constant.formConfiguration(),
               method:'GET',
               body: datos,
               success: this.sucessConsultarProductosProveedor,
               error: this.error_,
               general: this.generalFormatPmv,
               showMessage:false
           });
       }
     }
    
      sucessConsultarProductosProveedor(data){
         if (data.estado_p === 200 )
         {
            this.gridOptionsProductosProveedor['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsProductosProveedor['rowData'] = data.data;
            configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 250, sortable: false, filter: false });
    
            this.getField('rejilla_articulos_proveedor').initData(this.gridOptionsProductosProveedor,configCell);
            this.getField('rejilla_articulos_proveedor').toggle(true);
         }else {
            this.getField('rejilla_articulos_proveedor').toggle(false);
             if(data.estado_p===404) {
              this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
             }
             else {
               let respuesta=Object.values(data.data.errores);
               this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
             }
         }
      }
    
      setButtonDelete(props) {       
        let button = document.createElement("input");
        button.onclick = () => this.eliminarRegistroProveedor(props.data);
        button.setAttribute("id", 'button_delete_' + props.data.codigo_articulo);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "eliminar");
        return this.createElementJaivana(button);
      }
    
      eliminarRegistroProveedor(props) {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(() => { this.confirmDeleteProveedores(props) });
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
        this.getField('confirmModalCustom').toggle(true);
      }
    
      confirmDeleteProveedores(props) {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
              nit_proveedor: props.nit,
              codigo_articulo_proveedor: props.codigo_articulo,
              articulo_proveedor: props.articulo_proveedor
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'comp-productosproveedores', operacion: '7', operacion_tipo: "eliminar" };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successEliminarProveedores,
                error: this.error_,
                general: this.generalFormatPmv
            });
      }
    
      successEliminarProveedores(data) {
          if(data.estado_p === 200){
            this.consultarProductosProveedor();          
          }else {
            if(data.estado_p===404) {
             this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
      }
    
    
      validarCodigo(){
         if(this.getField("codigo_articulo").valid()){
            let datos = { datos: {
                nit:this.getField("nit").getValue(),
                codigo_articulo:this.getField("codigo_articulo").getValue(),
                con_codigo_proveedor:this.getField('con_codigo_proveedor').getValue(), //se deja quemado ya que en este formulario no se valida articulo proveedor
                sucursal_ingreso:this.sucursal_ingreso,
                existe_ubicaciones:this.existe_ubicaciones,
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'codigoauxiliar', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessValidarCodigo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }
      }
    
      sucessValidarCodigo(data){
          if (data.estado_p === 200 )
          {
              this.getField('codigo_articulo').setValue(data.data.xcodigo);
              this.traerInfoArticulo();
          }else {
              if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
              }
              else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
              }
          }
      }
    
      abrirModalAgregarArticuloProveedor(){
          this.getField('nit_proveedor').setDisabled(true);
          this.getField('articulo_proveedor').setDisabled(true);
          this.getField('nit_proveedor').setError(false,'');
          this.getField('sede_proveedor').setError(false,'');
          this.getField('nombre_proveedor').setError(false,'');
          this.getField('articulo_proveedor').setError(false,'');
          this.getField('codigo_articulo_proveedor').setError(false,'');
          this.getField('nombre_articulo_proveedor').setError(false,'');
          this.getField('rejilla_articulos_proveedor').toggle(false);
          this.getField('nit_proveedor').setValue(this.getField('nit').getValue());
          this.getField('sede_proveedor').setValue(this.getField('sede').getValue());
          this.getField('nombre_proveedor').setValue(this.getField('nombre').getValue());
          this.getField('articulo_proveedor').setValue(this.getField('codigo_articulo').getValue());
          this.getField('modal_articulo_proveedores').handleClickOpen();
          this.consultarProductosProveedor();
      }
    
    
    
      calcularPrecioSugerido(){
    
        let xrenta = this.getField('rentabilidad').getValue();
        let xcosto = this.getField('precio_sin_iva').getValue();
        let xtarifa_iva = this.getField('tarifa_iva').getValue();
        if(xrenta>=0 && xcosto>0 && xtarifa_iva>0){
          let xprecio = (xcosto) / ((100 - xrenta) / 100);
          if (this.precio_incluye_iva==='S') {
              xprecio = xprecio * (1 + (xtarifa_iva / 100));
          }
          //precio_sugerido
          let decimal = (xprecio + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2)
            xprecio=xprecio.toFixed(this.numero_decimales_compras);
          }
          this.getField('precio').setValue(Number(xprecio));
        }
      }

      cargarUbicaciones(){
        if(this.wms==='S' && this.wms_nuevo==='S'){
          this.num_ubicaciones=0;
          this.getField('select_ubicacion_agregar').setVisible(true);
          if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid()){
              let datos = { datos: {
                  sucursal_ingreso:this.sucursal_ingreso,
                  select_direccion:this.getField("select_direccion").getValue(),
                  codigo_articulo:this.getField("codigo_articulo").getValue()
              }};
              this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'cargarubicaciones', operacion_tipo: 'consulta' };
              this.service.send(
              {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successCargarUbicaciones,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage:false
              });
          }
        }else{
          this.getField('select_ubicacion_agregar').setVisible(false);
        }
      }

      successCargarUbicaciones(data){
        let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
        if (data.estado_p === 200 )
        { 
          data.data.forEach(item => {
              let dataOp = {}
              dataOp['value'] = item.ubicacion_codigo;
              dataOp['text'] = item.ubicacion_codigo;
              dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
              opciones.push(dataOp);
          });
          this.num_ubicaciones=data.data.length;
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos de ubicación.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
        this.getField('select_ubicacion_agregar').setOptions(opciones);
    }
    
      saldosubicacion(){
        if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid()){
            let datos = { datos: {
                sucursal:this.sucursal_ingreso,
                bodega:this.getField("select_direccion").getValue(),
                codigo_articulo:this.getField("codigo_articulo").getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'traersaldosubicacion', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successSaldosUbicacion,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }
      }
    
      successSaldosUbicacion(data){
          if (data.estado_p === 200 )
          {
            let configCell = new Map();
            let datos=[]

            data.data.forEach(element => {
                let arreglo=
                {
                    ubicacion: element.ubicacion,
                    saldo: element.saldo,
                    reservado: element.cantidad_reservada!==null &&  element.cantidad_reservada!=='null'?element.cantidad_reservada:0,
                }
        
                datos.push(arreglo);
            });
            if(datos.length>0){
                configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('reservado', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.reservado) }, cellStyle: {textAlign:"right"},width: 110});
                this.gridOptionsComponentes1['rowData'] = datos;
                this.getField('rejilla_ubicacion').toggle(true);
                this.getField('rejilla_ubicacion').initData(this.gridOptionsComponentes1, configCell);
            }else{
                this.getField('rejilla_ubicacion').toggle(false);
            }
          }else {
              if(data.estado_p===404) {
                  //this.alertGeneral.toggle(true, 'No hay datos de ubicación.', 'error');
                  this.mostrarMensajeAdicional('No hay datos de ubicación.');
              }
              else {
                  let respuesta=Object.values(data.data.errores);
                  this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
              }
          }
      }
    
      traersaldoBodegas(){
          let datos = { datos: {
              bodega:this.getField("select_direccion").getValue(),
              codigo_articulo:this.getField("codigo_articulo").getValue()
          }};
          
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'traersaldosbodegas', operacion_tipo: 'consulta' };
          this.service.send(
          {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successSaldosBodegas,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
          });
      }
    
      successSaldosBodegas(data){
          if (data.estado_p === 200 )
          {
              let datos=[]
              data.data.bodega.forEach(element => {
              let arreglo=
                  {
                      bodega: element.bodega,
                      saldo: element.saldo
                  }
              
                  datos.push(arreglo);
              });
             
              let arraybodegas = Object.entries(data.data.bodegas[0]);
              arraybodegas.forEach(element => {
                  let arreglo=
                  {
                      bodega: element[0],
                      saldo: element[1]
                  }
              
                  datos.push(arreglo);
              });
              let configCell = new Map();
              configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
              this.gridOptionsBodega['rowData'] = [];
              this.getField('rejilla_bodega').initData(this.gridOptionsBodega);
              this.getField('rejilla_bodega').toggle(false);
              
              this.gridOptionsBodega['rowData'] = datos;
              this.gridOptionsBodega['paginationPageSize']=15; //cantidad de datos que quiero mostrar por cada pagina
              //this.gridOptionsBodega['domLayout'] = 'autoHeight';// organiza el tamaño de la tabla
              this.getField('rejilla_bodega').toggle(true);
              this.getField('rejilla_bodega').initData(this.gridOptionsBodega, configCell);
    
          }else {
              if(data.estado_p===404) {
                  this.alertGeneral.toggle(true, 'No hay datos de bodegas.', 'error');
              }
              else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
              }
          }
      }
  
    
    
      modificarUbicacion(){
        if(this.getField('select_ubicaciones').valid()){
            let datos={ datos: {
              codigo_articulo:this.fila_seleccionada[0].codigo_articulo,
              cantidad_articulo:this.fila_seleccionada[0].cantidad,
              sucursal_ingreso:this.sucursal_ingreso,
              select_ubicaciones:this.getField('select_ubicaciones').getValue(),
              select_direccion:this.getField('select_direccion').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: '6_ubicacion', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.sucessCambiarUbicacion,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
      }
    
    
      sucessCambiarUbicacion(data)
      {   
          if (data.estado_p === 200)
          {
            if(data.data.mensaje_mostrar!==undefined){
              this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
            }else{
              this.getField('modal_ubicaciones').handleClose();
              this.refescarTabla();
            } 
          }
          else 
          {
            if(data.estado_p===404) {
                  this.alertGeneral.toggle(true, 'No hay datos', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
      }
    
      cambiarUbicacion(){
        if(this.fila_seleccionada.length>0){
          if(this.wms!=='S'){
            this.mostrarMensajeAdicional("La bodega " + this.getField('select_direccion').getValue() + " no está configurada como wms.");
          }else{
            if(this.fila_seleccionada[0].codigo_articulo!=='.'){
                this.mostrarMensajeGenerando();
                let datos={ datos: {
                  select_direccion:this.getField('select_direccion').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_ubicaciones_pag', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.traerUbicaciones,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
          }
        }else{
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
        }
      }
    
      traerUbicaciones(data)
      {   
          this.getField('confirmModalCustom').toggle(false);
          if (data.estado_p === 200)
          {
              this.getField('select_ubicaciones').setValue(' ');
              this.getField('modal_ubicaciones').handleClickOpen();
              this.getField('nombre_registro').setValue(this.fila_seleccionada[0].nombre);
              let opciones = [{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }];
              data.data.forEach(item => {
                  let dataOp = {}
                  dataOp['value'] = item.ubicacion;
                  dataOp['text'] = item.ubicacion;
                  dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
                  opciones.push(dataOp);
                  if(item.ubicacion===this.fila_seleccionada[0].ubicacion)
                  this.getField('select_ubicaciones').setValue(item.ubicacion);
              });
              opciones.sort();
              this.getField('select_ubicaciones').setOptions(opciones);
              
          }
          else 
          {
            if(data.estado_p===404) {
                this.mostrarMensajeNoHayDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
          }
      }
    
      validarDocumento(){
        if(this.row_documento.length>0){
          let mensaje='Selecciono el documento nro. ' + this.row_documento[0].numero_documento + ' del proveedor ' + this.getField('nit').getValue()
          + ' ¿Desea relacionarlo con la compra actual?';
          this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
          this.getField('confirmModalCustom').setClickDialog(() => { 
            this.getField('id_doc').setValue(this.row_documento[0].id);
            this.row_documento=[];
            this.getField('modal_documentos').handleClose();
            this.getField('confirmModalCustom').toggle(false);
          });
          this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
        }else{
          this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
        }
      }
    
    
      onSelectionChangedDocumentos(){
        this.row_documento = this.gripOptionesDocumentos.api.getSelectedRows();
      }
      onSelectionChangedUbicaciones(){
        this.row_ubicacion = this.gripOptionesUbicaciones.api.getSelectedRows();
      }
  
    
      validarBodega(){
        //this.mostrarMensajeGenerando();
        let datos={ datos: {
          value:this.getField('select_direccion').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: '1_validar_bodega', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessValidarBodega,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    
    
    
      sucessValidarBodega(data)
      {   
          if (data.estado_p === 200)
          {
              
              this.wms=data.data[0].wms;
              this.wms_externo_bodega=data.data[0].wms_externo;
          }
          else 
          {
            this.getField('select_direccion').setValue('');
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje','Bodega invalida. No se grabó el pedido');
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
      }

    
      validarReteICA(){
        if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
          if(this.reteica==='S'){
            if(Number(this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.'))!==0){
              let xvalor = this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.');
              let xtarifa = this.tarifa_reteica;
              let xvalorreteica = 0;
              xvalorreteica = xvalor * (xtarifa / 100);
    
              let decimal = (xvalorreteica + "").split(".")[1];//para manejar los decimales
              if ((decimal !== 0) && (decimal !== undefined)) {
                if(decimal.length>this.numero_decimales_compras)
                xvalorreteica=xvalorreteica.toFixed(this.numero_decimales_compras);
              }
              this.getField('tarifa_reteica').setValue(''.formatoPrecio(xtarifa));
              this.getField('reteica').setValue(xvalorreteica);
              this.calcularNeto();
            }else{
              this.mostrarMensajeAdicional('El total saldo de la devolución es cero, por tanto no se puede realizar la operación: RETEICA');
            }
          }else{
            this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula ReteICA');
          }
        }
      }
    
      validarCree(){
        try {
          if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
            if(this.tarifa_cree_compras!=='' && this.tarifa_cree_compras!==' ' && this.tarifa_cree_compras!==undefined && Number(this.tarifa_cree_compras>0)){
              if(Number(this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.'))!==0){
                let xvalor = this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.');
                let xtarifa = this.tarifa_cree_compras;
                let xvalorcree = 0;
                xvalorcree = xvalor * (xtarifa / 100);
                let decimal = (xvalorcree + "").split(".")[1];//para manejar los decimales
                if ((decimal !== 0) && (decimal !== undefined)) {
                  if(decimal.length>this.numero_decimales_compras)
                  xvalorcree=xvalorcree.toFixed(this.numero_decimales_compras);
                }
                this.getField('cree').setValue(xvalorcree);
                this.calcularNeto();
              }else{
                this.mostrarMensajeAdicional('El total saldo la devolución es cero, por tanto no se puede realizar la operación: CREE');

              }
            }else{
              this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula retención CREE');
            }
          }
        } catch (error) {
          this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula retención CREE');
        }
        
      }
    
      validarRetefuente(){
        if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
          if(this.retefuente==='S' && this.getField('select_retencion').getValue()!==''){
            if(Number(this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.'))!==0){
              let xvalor = Number(this.getField('total_subtotal').getValue().replaceAll('.','').replaceAll(',','.'));
              let xtarifa = Number(this.getField('select_retencion').getValue());
              let xvalorretencion = 0;
        
              xvalorretencion = xvalor * (xtarifa / 100);
      
              let decimal = (xvalorretencion + "").split(".")[1];//para manejar los decimales
              if ((decimal !== 0) && (decimal !== undefined)) {
                if(decimal.length>this.numero_decimales_compras)
                  xvalorretencion=xvalorretencion.toFixed(this.numero_decimales_compras);
              }
              this.getField('retenido').setValue(Number(xvalorretencion));
            }else{
              this.mostrarMensajeAdicional('El total saldo de la devolución es cero, por tanto no se puede realizar la operación: RETEFUENTE');
            }
          }else if(this.getField('select_retencion').getValue()!=='0' && this.getField('select_retencion').getValue()!==''){
            this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula Retefuente');
            this.getField('select_retencion').setValue('0');
          }
          this.calcularNeto();
        }else{
          if(this.getField('select_retencion').getValue()!=='0' && this.getField('select_retencion').getValue()!==''){
            this.getField('select_retencion').setValue('0');
          }
        }
        
      }
    
      validarReteIva(){
        if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
          if(this.reteiva==='S'){
            if(Number(this.getField('valor_iva').getValue().replaceAll('.','').replaceAll(',','.'))!==0){
              let xvalor = this.getField('valor_iva').getValue().replaceAll('.','').replaceAll(',','.');
              let xtarifa = this.tarifa_reteiva;
              let xvalorreteiva = 0;
              xvalorreteiva = xvalor * (xtarifa / 100);
    
              let decimal = (xvalorreteiva + "").split(".")[1];//para manejar los decimales
              if ((decimal !== 0) && (decimal !== undefined)) {
                if(decimal.length>this.numero_decimales_compras)
                xvalorreteiva=xvalorreteiva.toFixed(this.numero_decimales_compras);
              }
              this.getField('reteiva').setValue(xvalorreteiva);
              this.calcularNeto();
            }else{
              this.mostrarMensajeAdicional('El valor IVA de la devolución es cero, por tanto no se puede realizar la operación: RETEIVA');
            }
          }else{
            this.mostrarMensajeAdicional('Al proveedor '+this.getField('nit').getValue()+' no se le calcula ReteIVA');
          }
        }
      }
    
      traerImpuestosNit(){
        let datos={ datos: {
          nit: this.getField('nit').getValue(),
          sede: this.getField('sede').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: '1_impuestos', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerImpuestos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    sucessTraerImpuestos(data)
    {
        if (data.estado_p === 200 && data.data.length >= 1) {
          this.retefuente=data.data[0].retefuente;
          this.reteiva=data.data[0].reteiva;
          this.reteica=data.data[0].reteica;
          this.tarifa_reteica=data.data[0].tarifa_reteica;
          this.tarifa_cree_compras=data.data[0].tarifa_cree_compras;
          this.proveedor_tipo_dias_vencimiento=data.data[0].proveedor_tipo_dias_vencimiento;
          this.dias_vence_proveedor=data.data[0].dias_vence_proveedor;
          this.regimen=data.data[0].regimen;
          this.calcula_iva_item=data.data[0].calcula_iva_item;
          //llenar los datos del nit por si cambiaron
          this.getField('nombre').setValue(data.data[0].nombre);
          this.getField('responsable_iva').setValue(data.data[0].responsable_iva);

          if(this.calcular_cree_automatico==='S')
            this.validarCree();
          
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'No sé encontrarón los impuestos para el NIT.', 'error');
        }else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    
      llenarSelectRete(){
          let datos={ datos: {
            sucursal_ingreso: this.sucursal_ingreso
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarretenciones', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.suceesLlenarSelectRete,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
      }
    
      suceesLlenarSelectRete(data)
      {
        if (data.estado_p === 200 && data.data.length >= 1) {
    
          let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
          data.data.forEach(item => {
              let dataOp = {}
              dataOp['value'] = item.valor;
              dataOp['text'] = item.valor;
              dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
              opciones.push(dataOp);
          });
          opciones.sort();
          this.getField('select_retencion').setOptions(opciones);
    
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'No sé encontrarón retenciones validas.', 'error');
        }else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
      }
      }
    
    
      //fin nuevas
      deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }
    
      validarGrabar(){
        let errores=0;
        this.getField('grabar').setDisabled(true);

        if(!this.getField('nit').valid())
          errores++;
        if(!this.getField('sede').valid())
          errores++;
        if(!this.getField('nombre').valid())
          errores++;
        if(!this.getField('responsable_iva').valid())
          errores++;
        if(!this.getField('select_direccion').valid())
          errores++;
        if(!this.getField('valor_neto').valid())
          errores++;
        if(!this.getField('factura_numero').valid())
          errores++;
        if(!this.getField('devolucion_numero').valid())
          errores++;
    
        if(errores===0){
          if(this.getField('total_pedido').getValue()===0){
            this.alertGeneral.toggle(true, 'El total del pedido no puede ser cero.', 'error');
            this.getField('grabar').setDisabled(false);
          }else if(this.xdetalle.trim()===''){
            this.mostrarMensajeAdicional('El necesario proporcionar un detalle.');
            //this.alertGeneral.toggle(true, 'El necesario proporcionar un detalle.', 'error');
            this.getField('grabar').setDisabled(false);
          }else{
            this.validarImpuestos();
          }
        }else{
          this.mostrarMensajeAdicional('Debe llenar todos los campos.');
          this.getField('grabar').setDisabled(false);
        }
      }
    
      validarImpuestos(){
        let impuestos='';
          if (this.getField('retenido').getValue().toString().length === 0 && this.retefuente==='S') {
            impuestos = impuestos + "Retención en la Fuente, ";
          }
          if (this.getField('reteiva').getValue().toString().length === 0 && this.reteiva==='S' && this.getField('valor_iva').getValue()!==0) {
              impuestos = impuestos + "ReteIVA, ";
          }
          if (this.getField('reteica').getValue().toString().length === 0 && this.reteica==='S') {
              impuestos = impuestos + "ReteICA, ";
          }
          if (this.getField('cree').getValue().toString().length === 0 && this.tarifa_cree_compras!=='' && this.tarifa_cree_compras!==' ' && this.tarifa_cree_compras!==undefined && Number(this.tarifa_cree_compras>0)) {
              impuestos = impuestos + "Retención CREE, ";
          }
          if (impuestos.length > 0) {
              this.getField('grabar').setDisabled(false);
              impuestos=impuestos.substring(0,impuestos.length-2);
              let mensaje= "Al proveedor " + this.getField('nit').getValue() + " se le calcula: " + impuestos
                      + ".¿Desea continuar la grabación de la compra sin calcular los impuestos?";
              this.getField('confirmModalCustom').setTitleAndContent('Mensaje',mensaje);
              this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.traerConsecutivoGrabar();});
              this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
              this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
              this.getField('confirmModalCustom').toggle(true);
    
          } else {
              this.traerConsecutivoGrabar();
          }
      }
  
    
    
      abrirImprimir(){
            
            this.getField("modal_imprimir").setLabel("Devolución No. "+this.xnumero);
            this.getField('modal_imprimir').handleClickOpen();
            this.getField('email').setValue('');
            this.getField('email').setError(false,'');
            this.getField('radio_correo').setValue("N");
            this.getField('ch_excel').setValueSwitch(false);
            this.getField('ch_pdf').setValueSwitch(false);
            this.getField('ch_pantalla').setValueSwitch(false);
            this.getField('email').setDisabled(false);
      }
    
        procesar(){
            let errores =0;
            if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
                if(this.getField('email').valid()===false){
                    errores++;
                }
            }
            if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
                errores++;
                this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
                this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
                this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
                this.operacion_actual='';
                this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
            }
            
            if(errores===0){
                if(this.operacion_actual!==''){
                    if(this.getField('radio_correo').getValue()==='S'){
                        this.enviarCorreoTerceros();
                    }else{
                        this.nombre_pdf='devcompra';
                        this.nombre_excel='devcompra';
                        if(this.operacion_actual==='pdf'){
                            this.generarPdfTabla3();
                        }else if(this.operacion_actual==='pantalla'){
                                this.genearModalGlobales();
                        }else if(this.operacion_actual==='excel'){
                            this.generarExcelTabla3();
                        } 
                    }
                }else{
                    this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                    this.getField('confirmModalCustom').toggle(false);
                } 
            }
        }
    
        genearModalGlobales(){
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
          this.getField('confirmModalCustom').setClickDialog(()=>{});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
          let datos={ datos: {
            xnumero:this.xnumero,
            xfecha:this.xfecha,
            xsucursal:this.xsucursal,
            nit: this.getField('nit').getValue(),
            sede: this.getField('sede').getValue(),
            email: this.getField('email').getValue(),
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'archivoitems', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.sucessTraerDatos,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
          
      }
    
      sucessTraerDatos(data){
          this.getField('confirmModalCustom').toggle(false);
          if(data.estado_p === 200){ 
                let datosFicha = [];
                this.lista=data.data.datos_lista;
                let data2 = 
                    {
                        "empresa": this.lista.empresa,
                        "sucursal": this.lista.sucursal,
                        "direccion": this.lista.direccion_xml,
                        "telefono": this.lista.telefono_xml,
                        "nit": this.lista.nit_xml,
                        "ciudad": this.lista.ciudad_xml,
                        "devolucion_nro": this.lista.devolucion_nro,
                        "de_la_compra_nro": this.lista.de_la_compra_nro,
                        "fecha": this.lista.fecha,
                        "f_documento": this.lista.f_documento,
                        "bodega": this.lista.bodega,
    
                    };
    
                datosFicha.push(data2);
                this.getField('lista_datos').setItemsFichas(datosFicha);
                this.getField('rejilla_items_result').toggle(true);
                this.getField('modal_result').setLabel('Devolución de compra '+this.xnumero);
                this.getField('modal_result').handleClickOpen();
                this.gridOptionsComponentesItems['rowData'] = [];
                let configCell = new Map();
                this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
                //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
                configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
              
                this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);
    
    
                this.getField('proveedor_result').setValue(this.lista.proveedor);
                this.getField('direcccion').setValue(this.lista.direccion_nit);
                this.getField('telefono').setValue(this.lista.telefono_nit);
                this.getField('ciudad').setValue(this.lista.ciudad_nit);
                this.getField('detalle_result').setValue(this.lista.detalle);
    
                this.getField('retencion_result').setValue(this.currencyFormatterGeneral(this.lista.retencion));
                this.getField('valor_exento_result').setValue(this.currencyFormatterGeneral(this.lista.valor_exento));
                this.getField('reteiva_result').setValue(this.currencyFormatterGeneral(this.lista.reteiva));
                this.getField('valor_grabado_result').setValue(this.currencyFormatterGeneral(this.lista.grabado));
    
                this.getField('reteica_result').setValue(this.currencyFormatterGeneral(this.lista.retencionica));
                this.getField('cree_result').setValue(this.currencyFormatterGeneral(this.lista.cree));
                this.getField('aproximacion_result').setValue(this.currencyFormatterGeneral(this.lista.aproximacion));
                this.getField('iva_result').setValue(this.currencyFormatterGeneral(this.lista.total_iva));
                this.getField('fletes_result').setValue(this.currencyFormatterGeneral(this.lista.fletes));
                this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total));
    
                
          }else if(data.estado_p === 404){
              //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
              this.mostrarMensajeNoHayDatos();
          }
          else{
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
          } 
      }
    
    
        enviarCorreoTerceros(){
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
          this.getField('confirmModalCustom').setClickDialog(()=>{});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
          let datos={ datos: {
            xnumero:this.xnumero,
            xfecha:this.xfecha,
            xsucursal:this.xsucursal,
            nit: this.getField('nit').getValue(),
            sede: this.getField('sede').getValue(),
            email: this.getField('email').getValue(),
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual,
            radio_correo: this.getField('radio_correo').getValue(),
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'archivoitems', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successEnviarCorreo,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
      }
      
      successEnviarCorreo(data){
          this.getField('confirmModalCustom').toggle(false);
          if(data.estado_p === 200){
              this.mostrarMensajeEmailEnviado();
          }else{
              this.mostrarMensajeEmailFallo();
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
          }
      }
    
      generarPdfTabla3()
        {       
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
          this.getField('confirmModalCustom').setClickDialog(()=>{});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
                let url = this.constant.formConfiguration();
                let datos2= {
                  xnumero:this.xnumero,
                  xfecha:this.xfecha,
                  xsucursal:this.xsucursal,
                  nit: this.getField('nit').getValue(),
                  sede: this.getField('sede').getValue(),
                  email: this.getField('email').getValue(),
                  sucursal_ingreso: this.sucursal_ingreso,
                  operacion: this.operacion_actual,
                };
                let op='archivoitems';
                const myJSON = JSON.stringify(datos2);
                let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabardevolucion","operacion":"'+op+'","operacion_tipo":"consulta"}}';
                fetch(url+datos,{
                method:'GET', 
                headers:new Headers({
                        "Authorization": 'Bearer '+localStorage.getItem('token'), 
                        "Content-Type":'application/json'})})
                .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if(response.status!==200){
                return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                start(controller) {
                return pump();
                function pump() {
                        return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;}
                        controller.enqueue(value);
                        return pump();});}}})})
                .then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    if(blob.size>0){
                        blob.text().then(res=>{
                            if(this.isJson(res)){
                                this.successEnviarCorreo(JSON.parse(res));
                            }else{
                                const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                                    type:'application/pdf'});
                                window.open(URL.createObjectURL(pdf));
                            } 
                        });
                    }else{
                        this.mostrarMensajeNoHayDatos();
                    }
                }).catch(err => {
                  this.alertGeneral.toggle(true,err,'error');
                });
        }
    
        isJson(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
    
        generarExcelTabla3()
        {
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
          this.getField('confirmModalCustom').setClickDialog(()=>{});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
                let url = this.constant.formConfiguration();
                let datos2= {
                  xnumero:this.xnumero,
                  xfecha:this.xfecha,
                  xsucursal:this.xsucursal,
                  nit: this.getField('nit').getValue(),
                  sede: this.getField('sede').getValue(),
                  email: this.getField('email').getValue(),
                  sucursal_ingreso: this.sucursal_ingreso,
                  operacion: this.operacion_actual,
                };
                let op='archivoitems';
    
                const myJSON = JSON.stringify(datos2);
                let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabardevolucion","operacion":"'+op+'","operacion_tipo":"consulta"}}';
                fetch(url+datos,{
                method:'GET', 
                headers:new Headers({
                        "Authorization": 'Bearer '+localStorage.getItem('token'), 
                        "Content-Type":'application/json'})})
                .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if(response.status!==200){
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                start(controller) {
                return pump();
                function pump() {
                        return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;}
                        controller.enqueue(value);
                        return pump();});}}})})
                .then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    if(blob.size>0){
                        blob.text().then(res=>{
                            if(this.isJson(res)){
                                this.successEnviarCorreo(JSON.parse(res));
                            }else{
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.style.display = 'none';
                                a.href = url;
                                // the filename you want
                                a.download = this.nombre_excel+'.xlsx';
                                document.body.appendChild(a);
                                a.click();
                                window.URL.revokeObjectURL(url);
                                alert('Se descargo el archivo!');
                            } 
                        });
                    }else{
                        this.mostrarMensajeNoHayDatos();
                    }
                }).catch(err => {
                    this.alertGeneral.toggle(true,err,'error');
                });
        }
    
    
    
      validarCuenta(){
        let datos={ datos: {
          nit:this.getField('nit').getValue(),
          sede:this.getField('sede').getValue(),
          sucursal_ingreso:this.sucursal_ingreso
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabarpedidos', operacion: 'validarcontable', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessValidarCuenta,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    
      sucessValidarCuenta(data)
      {   
          if (data.estado_p === 200)
          {
            this.traerImpuestosNit();
          }
          else 
          {
            this.getField('nit').setValue('');
            this.getField('nombre').setValue('');
            this.getField('sede').setValue('');
             if(data.estado_p===404) {
    
                  this.alertGeneral.toggle(true, 'No hay datos', 'error');
             }
             else {
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', data.data.errores.mensaje);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);});
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
             }
         }
      }
    
    
      onSelectionChangedPrincipal(){
        this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
      }
    
      onSelectionChanged(){
    
      }
    
      validacionesAgregar(){
          if(Number(this.getField('total_item').getValue()!==0)){
              if(this.getField('codigo_articulo').valid() 
              && this.getField('nombre_articulo').valid()
              && this.getField('tarifa_iva').valid() 
              && this.getField('descuento').valid() 
              && this.getField('cantidad_articulo').valid()
              && this.getField('impuestos2').valid()){
                if(this.existe_ubicaciones && !this.getField('ubicacion_agregar').valid()){
                    if(this.num_ubicaciones>0)
                      return ;
                    else{
                      this.mostrarMensajeAdicional('No hay ubicaciones para el artículo '+this.getField('codigo_articulo').getValue()+'.');
                      return ;
                    }
                }
                //no valida el costopro 
                this.validarSaldoUbicacion();
              }
          }else{
              this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
          }
      }

      validarSaldoUbicacion(){
        this.getField('confirmModalCustom').toggle(false);
        this.mostrarMensajeValidandoInformacion();
        if(this.wms==='S' && this.existe_ubicaciones){
            let datos={ datos: {
              sucursal_ingreso:this.sucursal_ingreso,
              select_direccion:this.getField('select_direccion').getValue(),
              costo:this.getField('costo').getValue(),
              codigo_articulo:this.getField('codigo_articulo').getValue(),
              existe_ubicaciones:this.existe_ubicaciones,
              cantidad_articulo:this.getField('cantidad_articulo').getValue(),
              ubicacion_agregar:this.getField('ubicacion_agregar').getValue(),
            }};
            this.mostrarMensajeValidandoInformacion();
            this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'validarsaldoubicacion', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessValidarSaldoUbicacion,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
          this.grabarItem();
        } 
      }

      sucessValidarSaldoUbicacion(data){
          this.getField('confirmModalCustom').toggle(false);
          this.getField('grabar_agregar').setDisabled(false);
            if (data.estado_p === 200)
            { 
              if(data.data.mensaje_mostrar!==undefined){
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
              }else{
                this.grabarItem();
              }
            }
            else 
            {
               if(data.estado_p===404) {
                    this.alertGeneral.toggle(true, 'No hay datos', 'error');
               }
               else {
                  if(data.data.mensaje_mostrar!==undefined){
                    this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
                  }else{
                    let respuesta=Object.values(data.data.errores);
                    let keys=Object.keys(data.data.errores);
                    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
                  }
               }
           }
      }
    
    
        grabarItem(){
          this.mostrarMensajeGuardando();
          this.getField('grabar_agregar').setDisabled(true);
          //en este servicio también valida si ya éxiste el articulo
          let datos={};
          if(this.existe_ubicaciones){
            datos={ datos: {
              ubicacion_agregar:this.getField('ubicacion_agregar').getValue(),
              select_direccion: this.getField('select_direccion').getValue(),
              codigo_articulo: this.getField('codigo_articulo').getValue(),
              cantidad_articulo: this.getField('cantidad_articulo').getValue(),
              nombre_articulo: this.getField('nombre_articulo').getValue(),
              tarifa_iva: this.getField('tarifa_iva').getValue(),
              precio_sin_iva: this.getField('precio_sin_iva').getValue(),
              impuestos2: this.getField('impuestos2').getValue(),
              valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
              valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
              total_item: this.getField('total_item').getValue(),
              sucursal_ingreso : this.sucursal_ingreso,
              existe_ubicaciones:this.existe_ubicaciones,
            }};
          }else{
            datos={ datos: {
              ubicacion_agregar:this.getField('ubicacion_agregar').getValue(),
              select_direccion: this.getField('select_direccion').getValue(),
              codigo_articulo: this.getField('codigo_articulo').getValue(),
              cantidad_articulo: this.getField('cantidad_articulo').getValue(),
              nombre_articulo: this.getField('nombre_articulo').getValue(),
              tarifa_iva: this.getField('tarifa_iva').getValue(),
              precio_sin_iva: this.getField('precio_sin_iva').getValue(),
              impuestos2: this.getField('impuestos2').getValue(),
              valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
              valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
              total_item: this.getField('total_item').getValue(),
              sucursal_ingreso : this.sucursal_ingreso,
              existe_ubicaciones:this.existe_ubicaciones,
              xt_otros_impuestos:this.getField('xt_otros_impuestos').getValue(),
              xotros_impuestos:this.getField('xotros_impuestos').getValue(),
              numero_item:this.getField('numero_item').getValue(),
            }};
          }
          
          let array_impuestos=this.gridOptionsImpArti['rowData'];
          if(this.existe_ubicaciones===false && array_impuestos!==undefined && array_impuestos.length>0){
            datos.datos.array_impuestos=array_impuestos;
          } 
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'grabaritem', operacion_tipo: 'crear' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.sucessGrabarItem,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
        }
    
        grabarItem2(opcion){
          this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
          this.getField('confirmModalCustom').setClickDialog(()=>{});  
          this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
          this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
          this.getField('confirmModalCustom').toggle(true);
          this.getField('grabar_agregar').setDisabled(true);
    
          let datos={};
          if(this.existe_ubicaciones){
            datos={ datos: {
              ubicacion_agregar:this.getField('ubicacion_agregar').getValue(),
              select_direccion: this.getField('select_direccion').getValue(),
              codigo_articulo: this.getField('codigo_articulo').getValue(),
              cantidad_articulo: this.getField('cantidad_articulo').getValue(),
              nombre_articulo: this.getField('nombre_articulo').getValue(),
              tarifa_iva: this.getField('tarifa_iva').getValue(),
              precio_sin_iva: this.getField('precio_sin_iva').getValue(),
              impuestos2: this.getField('impuestos2').getValue(),
              valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
              valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
              total_item: this.getField('total_item').getValue(),
              sucursal_ingreso : this.sucursal_ingreso,
              existe_ubicaciones:this.existe_ubicaciones,
            }};
          }else{
            datos={ datos: {
              ubicacion_agregar:this.getField('ubicacion_agregar').getValue(),
              select_direccion: this.getField('select_direccion').getValue(),
              codigo_articulo: this.getField('codigo_articulo').getValue(),
              cantidad_articulo: this.getField('cantidad_articulo').getValue(),
              nombre_articulo: this.getField('nombre_articulo').getValue(),
              tarifa_iva: this.getField('tarifa_iva').getValue(),
              precio_sin_iva: this.getField('precio_sin_iva').getValue(),
              impuestos2: this.getField('impuestos2').getValue(),
              valor_iva_unidad: this.getField('valor_iva_unidad').getValue(),
              valor_sin_iva_ma_impuestos: this.getField('valor_sin_iva_ma_impuestos').getValue(),
              total_item: this.getField('total_item').getValue(),
              sucursal_ingreso : this.sucursal_ingreso,
              existe_ubicaciones:this.existe_ubicaciones,
              xt_otros_impuestos:this.getField('xt_otros_impuestos').getValue(),
              xotros_impuestos:this.getField('xotros_impuestos').getValue(),
              numero_item:this.getField('numero_item').getValue(),
            }};
          }
          let array_impuestos=this.gridOptionsImpArti['rowData'];
          if(this.existe_ubicaciones===false && array_impuestos!==undefined && array_impuestos.length>0){
            datos.datos.array_impuestos=array_impuestos;
          } 
          
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'grabaritem', operacion_tipo: 'crear' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.sucessGrabarItem,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
        }
    
        sucessGrabarItem(data){
            this.getField('grabar_agregar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if (data.estado_p === 200 )
            {
                //limpiar select select_ubicacion_agregar
                this.getField('select_ubicacion_agregar').setVisible(false);
                this.getField('modal_agregar').handleClose();
                this.refescarTabla();
            }
            else 
            { 
              if(data.data.mensaje_mostrar!==undefined)
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
              else{
                if(data.estado_p===404) {
                    this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
                }
                else {
                    let respuesta=Object.values(data.data.errores);
                    let keys=Object.keys(data.data.errores);
                    this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
                }
              }
            }
        }
      
    
      abrirModalAgregar(){
        this.modificando='false';

        if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid()){
            this.getField('codigo_articulo').setError(false,'');
            this.getField('nombre_articulo').setError(false,'');
            this.getField('tarifa_iva').setError(false,'');
            this.getField('descuento').setError(false,'');
            this.getField('cantidad_articulo').setError(false,'');
            this.getField('impuestos2').setError(false,'');
            //this.getField('presentacion').setError(false,'');
      
            this.getField('precio_sin_iva').setError(false,'');
            this.getField('valor_sin_iva_ma_impuestos').setError(false,'');
            this.getField('total_iva').setError(false,'');
            this.getField('valor_iva_unidad').setError(false,'');
            this.getField('total_impuestos').setError(false,'');
            this.getField('valor_unidad').setError(false,'');
            this.getField('total_item').setError(false,'');
            this.getField('costo_bodega').setError(false,'');
            //limpiar impuestos
            this.gridOptionsImpArti['rowData']=[];
      
            //this.getField('presentacion').setValue('');
            this.getField('codigo_articulo').setValue('');
            this.getField('nombre_articulo').setValue('');
            this.getField('tarifa_iva').setValue('');
            this.getField('descuento').setValue(0);
            this.getField('cantidad_articulo').setValue(0);
            this.getField('impuestos2').setValue(0);    
      
            this.getField('precio_sin_iva').setValue(0);
            this.getField('valor_sin_iva_ma_impuestos').setValue(0);
            this.getField('total_iva').setValue(0);
            this.getField('valor_iva_unidad').setValue(0);
            this.getField('total_impuestos').setValue(0);
            this.getField('valor_unidad').setValue(0);
            this.getField('total_item').setValue(0);
            this.getField('costo_bodega').setValue(0);
            this.getField('modal_agregar').handleClickOpen();
  
            this.getField('xt_otros_impuestos').setValue(0);
            this.getField('xotros_impuestos').setValue(0);
            this.getField('numero_item').setValue(0);
            this.getField('costo_administrativo').setValue(0);
            this.getField('bodega').setValue(this.getField('select_direccion').getValue());
            if(this.wms==='S'){
              this.getField('ubicacion_agregar').setDisabled(false);
            }else{
              this.getField('ubicacion_agregar').setDisabled(true);/// No editable
            }
            if(this.existe_ubicaciones===true && this.wms==='S' && this.wms_nuevo==='S'){
              this.getField('select_ubicacion_agregar').setValue(' ');
              this.getField('ubicacion_agregar').setValue('');
              this.getField('ubicacion_agregar').setDisabled(true);//no editable
            }else{
              this.getField('ubicacion_agregar').setValue('999999999999');
            }
        }
      }
    
      limpiarCampos(){
              this.primeraVez();
              //this.getField('nit').setValue('');
              //this.getField('sede').setValue('');
              //this.getField('nombre').setValue('');
            
              this.getField('label_numero').setVisible(true);
              this.getField('factura_numero').setVisible(true);
              //para decidir si mostrar numero factura o select documentos
              this.getField('factura_numero').setDisabled(false);
    
              this.getField('total_subtotal').setValue(0);
              this.getField('valor_iva').setValue(0);
              this.getField('impuestos').setValue(0);
              this.getField('total_pedido').setValue(0);
              this.getField('factura_numero').setValue('');
              this.getField('reteiva').setValue('');
              this.getField('select_retencion').setValue(' ');
              this.getField('retenido').setValue('');
              this.getField('tarifa_reteica').setValue('');
              this.getField('reteica').setValue('');
              this.getField('ajuste').setValue('');
              this.getField('valor_neto').setValue('');
              this.getField('cree').setValue('');
              //limpiar auxiliares
    
              //auxiliares
              this.modificando='false';
              this.xfecha='';
              this.xnumero='';
              this.xsucursal=this.lasucursal;
              this.extension='txt';
              this.xdetalle='';
    
              this.calculo_factor=false;
              this.fila_seleccionada=[];
    
              //descuentos ( se guardan porque al cerrar el modal se limpian los campos)
      }

      limpiarCamposSinFactura(){
        this.limpiando=true;
        this.primeraVez();
        //this.getField('nit').setValue('');
        //this.getField('sede').setValue('');
        //this.getField('nombre').setValue('');
      
        this.getField('label_numero').setVisible(true);
        this.getField('factura_numero').setVisible(true);
        //para decidir si mostrar numero factura o select documentos
        this.getField('factura_numero').setDisabled(false);

        this.getField('total_subtotal').setValue(0);
        this.getField('valor_iva').setValue(0);
        this.getField('impuestos').setValue(0);
        this.getField('total_pedido').setValue(0);
        //this.getField('factura_numero').setValue('');
        this.getField('reteiva').setValue('');
        this.getField('select_retencion').setValue(' ');
        this.getField('retenido').setValue('');
        this.getField('tarifa_reteica').setValue('');
        this.getField('reteica').setValue('');
        this.getField('ajuste').setValue('');
        this.getField('valor_neto').setValue('');
        this.getField('cree').setValue('');
        //limpiar auxiliares

        //auxiliares
        this.modificando='false';
        this.xfecha='';
        this.xnumero='';
        this.xsucursal=this.lasucursal;
        this.extension='txt';
        this.xdetalle='';

        this.calculo_factor=false;
        this.fila_seleccionada=[];
    }
    
      comenzarForm(){
        if(this.getField('select_direccion').valid()){
              this.limpiarCampos();
              this.getField('nit').setError(false,'');
              this.getField('sede').setError(false,'');
              this.getField('nombre').setError(false,'');
              this.refescarTabla();
        }
        
      }
    
    
    
        primeraVez(){
          this.mostrarMensajeGenerando();
          let datos={ datos: {
            sucursal_ingreso:this.sucursal_ingreso,
            xsucursal:this.xsucursal,
            existe_ubicaciones:this.existe_ubicaciones,
            select_direccion:this.getField('select_direccion').getValue()
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'cargarprimeravez', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.sucessPrimeraVez,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
        }
    
        sucessPrimeraVez(data)
        {
           this.getField('confirmModalCustom').toggle(false);
            this.limpiando=false;
            if (data.estado_p === 200)
            { 
              this.gridOptionsComponentes['rowData'] = [];
              let configCell = new Map();
              this.gridOptionsComponentes['rowData'] = data.data;
              /*configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('accion', { cellRenderer: this.setButtonBotonesTabla, width: 250, sortable: false, filter: false });   
                     */
              this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
              this.getField('rejilla').toggle(false);
              this.refescarTabla();  
              this.traerConsecutivo();
            }
            else 
            {
               if(data.estado_p===404) {
                    this.alertGeneral.toggle(true, 'No hay datos', 'error');
               }
               else {
                  let respuesta=Object.values(data.data.errores);
                  let keys=Object.keys(data.data.errores);
                  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
               }
           }
    
      }
    
      setButtonBotonesTabla(props){
        if(props.data.codigo_articulo!=='.'){
          let fragment = document.createDocumentFragment();
          let buttonModificar = this.crearBotonTabla(props, `Modificar`);
          let buttonEliminar = this.crearBotonTabla(props, 'Eliminar');
          buttonModificar.setAttribute("class","buttonStyle2");
          buttonEliminar.setAttribute("class","buttonStyle2");
          fragment.appendChild(buttonModificar);
          fragment.appendChild(buttonEliminar);
          return this.createElementJaivana(fragment);
        }
    }
    
    crearBotonTabla(data, boton) {
      let button = document.createElement('input');
      switch (boton) {
          case 'Modificar':
              button.setAttribute("id", `buttonModificar${data}`);
              break;
          case 'Eliminar':
              button.setAttribute("id", `buttonEliminar_${data}`);
              break;
          default:
              break;
      }
      button.onclick = () => {this.accionProcesar(data, boton) };
      button.setAttribute("value", boton);
      button.setAttribute("type", "button");
      return button;
    }
    
    accionProcesar(data, boton) {
      if (boton === 'Eliminar') {
          this.eliminarRegistro(data);
      }else if (boton === 'Modificar'){
          this.count=0;
          this.modificando='true';
          this.id_modificar=data.data.id
          this.abrirModificarCantidad(data);
      }
    }
      
      traerInfoArticulo(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
          value: this.getField('codigo_articulo').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '42', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerArticulo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
      }
        
        sucessTraerArticulo(data){
          this.getField('confirmModalCustom').toggle(false);
          if(data.estado_p === 200){ 
            this.getField('nombre_articulo').setValue(data.data[0].nombre);
            this.getField('tarifa_iva').setValue(data.data[0].vivac);
            //this.getField('contenido').setValue(data.data[0].contenido);
            //this.getField('presentacion').setValue(data.data[0].presenta);
            this.getField('precio').setValue(data.data[0].precio1);
            this.getField('rentabilidad').setValue(data.data[0].rentabilidad);
            //this.getField('impuestos2').setValue(data.data[0].impuesto1);
            this.getField('costo').setValue(data.data[0].costo);
            this.getField('descuento').setValue(0);
            
            if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
              this.getField('impuestos2').setValue(data.data[0].impuesto1);
              this.traerImpuestosArticulo();
            }else if(this.existe_ubicaciones===false){
              this.getField('impuestos2').setValue(data.data[0].impuesto1);
              this.calcularItem();
            }else{
              this.calcularItem();
            }
            
          }else if(data.estado_p === 404){
              this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
          }
          else{
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
          } 
        }

        sucessTraerArticulo2(data){
          this.getField('confirmModalCustom').toggle(false);
          if(data.length>0){
            //eso es para evitar consumir dos veces el servicio traer articulo cuando prioridad barras esta en N
            //ya que no sería necesari, se guardo la respuesta dle primer servicio y con eso lleno los datos
            if(this.getField('codigo_articulo').getValue()!==''){
                data.forEach((element)=>{
                    if(element.codigo_interno===this.getField('codigo_articulo').getValue()){
                      data[0]=element;
                      return;
                    }
                });
            }
            this.getField('nombre_articulo').setValue(data[0].nombre);
            this.getField('tarifa_iva').setValue(data[0].vivac);
            //this.getField('contenido').setValue(data[0].contenido);
            //this.getField('presentacion').setValue(data[0].presenta);
            this.getField('precio').setValue(data[0].precio1);
            this.getField('rentabilidad').setValue(data[0].rentabilidad);
            //this.getField('impuestos2').setValue(data[0].impuesto1);
            this.getField('costo').setValue(data[0].costo);
            this.getField('descuento').setValue(0);
            
            if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
              this.getField('impuestos2').setValue(data[0].impuesto1);
              this.traerImpuestosArticulo();
            }else if(this.existe_ubicaciones===false){
              this.getField('impuestos2').setValue(data[0].impuesto1);
              this.calcularItem();
            }else{
              this.calcularItem();
            }
            
          }else{
              this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
          }
        }
    
      eliminarRegistro(props) {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(()=>this.confirmDelete(props.data));
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }
    
      confirmDelete(props) {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
              codigo_articulo :props.codigo_articulo,
              existe_ubicaciones:this.existe_ubicaciones,
              numero_item:props.numero_item,
              origen :props.origen,
              select_direccion:this.getField('select_direccion').getValue()
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: '7_item', operacion_tipo: "eliminar" };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successEliminar,
                error: this.error_,
                general: this.generalFormatPmv
            });
      }
      
      successEliminar(data) {
          if(data.estado_p === 200){
            this.refescarTabla();          
          }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
      }
    
      refescarTabla(){
        let datos={ datos: {
          sucursal_ingreso:this.sucursal_ingreso,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'refrescar', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessRefrescar,
            error: this.error_,
            general: this.generalFormatPmv
        });
      }
    
      calcularNeto(){
        //xneto = (xneto - xretefuente - xreteiva - xreteica - xcree) + xajustes;
        let xneto = (this.getField('total_pedido').getValue().replaceAll('.','').replaceAll(',','.')- this.getField('retenido').getValue() - this.getField('reteiva').getValue() - this.getField('reteica').getValue() - this.getField('cree').getValue()) +  this.getField('ajuste').getValue();
        let decimal = (xneto + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
          if(decimal.length>this.numero_decimales_compras)
          xneto=Number(xneto).toFixed(this.numero_decimales_compras);
        }
        this.getField('valor_neto').setValue(xneto);
      }
    
      sucessRefrescar(data)
        {
            if (data.estado_p === 200)
            {
              this.gridOptionsComponentes['rowData'] = [];
              let configCell = new Map();
              this.num_registros=data.data.result.length;
              this.gridOptionsComponentes['rowData'] = data.data.result;
              configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
              configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
    
              if(this.existe_ubicaciones){
                configCell.set('otros_impuestos', {cellRenderer:(props) => {return ""+props.data.otros_impuestos } , hide: true});
                configCell.set('t_otros_impuestos', {cellRenderer:(props) => {return ""+props.data.t_otros_impuestos } , hide: true});
                configCell.set('numero_item', {cellRenderer:(props) => {return ""+props.data.numero_item } , hide: true});
              }else{
                configCell.set('ubicacion', {cellRenderer:(props) => {return ""+props.data.ubicacion } , hide: true});

                configCell.set('otros_impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.otros_impuestos) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('t_otros_impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.t_otros_impuestos) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('numero_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.numero_item) }, cellStyle: {textAlign:"right"},width: 110});
              }
    
              configCell.set('accion', { cellRenderer: this.setButtonBotonesTabla, width: 250, sortable: false, filter: false });
              this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
              this.getField('rejilla').toggle(true);
            
              //llenar totales
              this.getField('total_subtotal').setValue(this.currencyFormatterGeneral(data.data.totales.subtotal));
              this.getField('valor_iva').setValue(this.currencyFormatterGeneral(data.data.totales.valoriva));
              this.getField('impuestos').setValue(this.currencyFormatterGeneral(data.data.totales.impuestos));
              this.getField('total_pedido').setValue(this.currencyFormatterGeneral(data.data.totales.valortotal));

              if (this.getField('total_pedido').getValue().replaceAll('.','').replaceAll(',','.') > 0) {
                  this.getField('select_direccion').setDisabled(true);
              } else {
                  this.getField('select_direccion').setDisabled(false);
              }
              let xneto=data.data.totales.valortotal;
              this.getField('valor_neto').setValue(xneto);
    
    
              //antes de calcular neto actualizo los impuestos seleccionados
              if(this.getField('retenido').getValue()!=='' && this.getField('retenido').getValue()!==' '){
                this.validarRetefuente();
              }
              if(this.getField('reteiva').getValue()!=='' && this.getField('reteiva').getValue()!==' '){
                this.validarReteIva();
              }
              if(this.getField('reteica').getValue()!=='' && this.getField('reteica').getValue()!==' '){
                this.validarReteICA();
              }
              if(this.getField('cree').getValue()!=='' && this.getField('cree').getValue()!==' '){
                this.validarCree();
              }
              this.getField('ajuste').setValue('');
              
              this.calcularNeto();
              
            }
            else 
            {
              this.getField('rejilla').toggle(false);
               if(data.estado_p===404) {
                    this.alertGeneral.toggle(true, 'No hay datos', 'error');
               }
               else {
                  let respuesta=Object.values(data.data.errores);
                  let keys=Object.keys(data.data.errores);
                  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
               }
           }
    
      }

      llenarSelect(){
          let datos={ datos: {
            sucursal_ingreso: this.sucursal_ingreso
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabarcompras', operacion: 'cargarbodegas', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.suceesLlenarSelect,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
      }
    
    suceesLlenarSelect(data){
        if (data.estado_p === 200 && data.data.result.length >= 1) {
          this.array_direcciones=data.data.result;
          let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
          data.data.result.forEach(item => {
              let dataOp = {}
              dataOp['value'] = item.codigo;
              dataOp['text'] = item.cadena;
              dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
              opciones.push(dataOp);
          });
          this.getField('select_direccion').setOptions(opciones);
          if(data.data.data_defecto !== undefined){
              this.getField('select_direccion').setValue(data.data.data_defecto);
          }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'No sé encontrarón direccion validas.', 'error');
            this.getField('plantillas').setVisible(false);
        }else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
       }

      this.primeraVez();
    }
    
        calcularDescuento(){
          this.getField('descuento').setError(false,'');
            if(this.getField('descuento').valid()){
              this.calculando_descuento=true;
              var xcosto = this.getField('precio_sin_iva').getValue();
              var xdescuentoc = this.getField('descuento').getValue();
              var xdescuento = xcosto * (xdescuentoc / 100);

              let valor_descuento=xcosto - xdescuento;
              let decimal = (valor_descuento + "").split(".")[1];//para manejar los decimales
              if ((decimal !== 0) && (decimal !== undefined)) {
                if(decimal.length>this.numero_decimales_compras)
                valor_descuento=Number(valor_descuento).toFixed(this.numero_decimales_compras);
              }
              this.getField('precio_sin_iva').setValue(valor_descuento);
              if(xdescuento>0)
                this.getField('descuento').setDisabled(true);
              if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
                this.traerImpuestosArticulo();
              }
              this.calculando_descuento=false;
          }
        }
    
        calcularItem(){
            let xtarifa_iva = this.getField('tarifa_iva').getValue();
            let ximpuestos1 = this.getField('impuestos2').getValue();
            let xcantidad1 = this.getField('cantidad_articulo').getValue();
            let xcosto1 =  this.getField('precio_sin_iva').getValue();
            let xcosto_mas_impuestos = xcosto1 + ximpuestos1;
            let xvalor_iva = xcosto1 * (xtarifa_iva / 100);
    
            
            this.getField('valor_sin_iva_ma_impuestos').setValue(Number.parseFloat(xcosto_mas_impuestos).toFixed(this.numero_decimales_compras));
            this.getField('valor_iva_unidad').setValue(Number.parseFloat(xvalor_iva).toFixed(this.numero_decimales_compras));
            this.getField('valor_unidad').setValue(Number.parseFloat(xvalor_iva + xcosto_mas_impuestos).toFixed(this.numero_decimales_compras));
    
            //total iva
            let xtotal_iva = xvalor_iva * xcantidad1;
            this.getField('total_iva').setValue(Number.parseFloat(xtotal_iva).toFixed(this.numero_decimales_compras));
    
            //datos otros impuestos y total
            let xtotalOtrosImpuestos = this.getField('xt_otros_impuestos').getValue();
    
    
            //total_impuestos
            let xtotal_impuestos=0;
            if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
                xtotal_impuestos = (ximpuestos1 * xcantidad1) + xtotalOtrosImpuestos;
                this.getField('total_impuestos').setValue(Number.parseFloat(xtotal_impuestos).toFixed(this.numero_decimales_compras));
            }else{
                xtotal_impuestos = ximpuestos1 * xcantidad1;
                this.getField('total_impuestos').setValue(Number.parseFloat(xtotal_impuestos).toFixed(this.numero_decimales_compras));
            }
            
    
            //total_item
            let xtotal_item = xtotal_impuestos + xtotal_iva;
            xtotal_item = xtotal_item + (xcosto1 * xcantidad1);
            this.getField('total_item').setValue(Number.parseFloat(xtotal_item).toFixed(this.numero_decimales_compras));
    
            //total item sin impuestos
            
            this.calcularPrecioSugerido();
        }
    
        traerPropiedades(){
          let datos={ datos: {
            sucursal_ingreso:this.sucursal_ingreso
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
          this.service.send({
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessTraerPropiedades,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
          });
        }
    
        sucessTraerPropiedades(data){
          if (data.estado_p === 200 )
          {
              this.existe_ubicaciones=data.data.ubicaciones;
    
              this.tarifa_reteiva=data.data.reteiva;
              this.precio_incluye_iva=data.data.precio_incluye_iva;
              this.wms_nuevo=data.data.wms_nuevo;
              this.lasucursal=data.data.lasucursal;
              this.xsucursal=data.data.lasucursal;
              this.existe_impuestos=data.data.existe_impuestos;
              this.existe_impuestos_articulo=data.data.existe_impuestos_articulo;
              this.cons_impart=data.data.cons_impart;
              this.costo_administrativo=data.data.costo_administrativo;
              this.costo_por_bodega=data.data.costo_por_bodega;
              this.numero_decimales_compras=data.data.numero_decimales_compras;
              this.calcular_cree_automatico=data.data.calcular_cree_automatico;
              this.prioridad_barras=data.data.prioridad_barras;

              //quitar
             
              if(this.existe_impuestos_articulo && this.existe_impuestos)
                this.getField('bt_impuestos_articulo').setDisabled(false);
              else
                this.getField('bt_impuestos_articulo').setDisabled(true);
    
              
              if(this.existe_ubicaciones){
                this.getField('bt_impuestos_articulo').setVisible(false);
                this.getField('bt_retefuente').setVisible(true);
                this.getField('bt_impuestos').setVisible(false);

                
                this.getField('cambiar_ubicacion').setVisible(true);
                this.getField('precio').setDisabled(true);
                this.getField('rentabilidad').setDisabled(true);

                //aquí en devolver no se muestran
                this.getField('precio').setVisible(false);
                this.getField('rentabilidad').setVisible(false);
                this.getField('bt_actualizar_precio').setVisible(false);
  
              }else{
                this.getField('cambiar_ubicacion').setVisible(false);
                this.getField('bt_retefuente').setVisible(true);
                this.getField('bt_impuestos').setVisible(true);

                //aquí en devolver no se muestran
                this.getField('precio').setVisible(false);
                this.getField('rentabilidad').setVisible(false);
                this.getField('rentabilidad').setVisible(false);
                this.getField('bt_actualizar_precio').setVisible(false);
                this.getField('bt_impuestos_articulo').setVisible(false);
                this.getField('ubicacion_agregar').setVisible(false);
    
              }
              this.getField('con_codigo_proveedor').setValue('N');//se deja quemado ya que en este formulario no se valida articulo proveedor

              /*if(data.data.pedido_con_cod_proveedor==='S' && this.existe_ubicaciones===false){
                this.getField('confirmModalCustom').setTitleAndContent('Trabaja con codigo proveedor?',);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.getField('con_codigo_proveedor').setValue('S'); });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("Si"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
              }*/
              //establecer setOnblur
              if(this.existe_ubicaciones===false && this.existe_impuestos && this.existe_impuestos_articulo){
                  this.getField("cantidad_articulo").setOnBlur(this.traerImpuestosArticulo);
                  this.getField('precio_sin_iva').setOnBlur(this.traerImpuestosArticulo);
              }
          }
          else 
          { 
            this.autoriza_ordenesc='';
            this.margen_costo_entrada='';
          }
          this.llenarSelect();
      }
    
        traerCostoBodega(){
          this.mostrarMensajeGenerando();
          let datos={ datos: {
            select_direccion:this.getField('select_direccion').getValue(),
            codigo_articulo:this.getField('codigo_articulo').getValue(),
            sucursal_ingreso:this.sucursal_ingreso,
            existe_ubicaciones:this.existe_ubicaciones,
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: '1_bodega_costo', operacion_tipo: 'consulta' };
          this.service.send({
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessTraerCostoBodega,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
          });
        }
    
        sucessTraerCostoBodega(data){
          this.getField('confirmModalCustom').toggle(false);
          if (data.estado_p === 200 )
          { 
            let costo=data.data[0].costo;
            let decimal = (costo + "").split(".")[1];//para manejar los decimales
            if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras)
                costo=Number(costo).toFixed(this.numero_decimales_compras);
            }
            this.getField('precio_sin_iva').setValue(costo);
          }
          else 
          { 
              this.getField('precio_sin_iva').setValue(0);
              if(data.estado_p===404) {
                  this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
              }
              else {
                  let respuesta=Object.values(data.data.errores);
                  let keys=Object.keys(data.data.errores);
                  this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
              }
          }
      }
    
      traerCostoBodegaYAdmn(){
        let op='';
        if(this.costo_por_bodega==='S')
            op='1_costo_adiministractivo';
        else if(this.calcula_iva_item==='S')
            op='1_costo_calcula_iva';
        else
          op='1_costo_normal';
        let datos={ datos: {
          value:this.costo_administrativo==='S'?'a.costo_adm_'+this.getField('select_direccion').getValue():'a.costo'+this.getField('select_direccion').getValue(),
          value2:this.getField('codigo_articulo').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: op, operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerCostoBodegaYAdmn,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
      }
    
      sucessTraerCostoBodegaYAdmn(data){
        if (data.estado_p === 200 )
        {
            this.getField('precio_sin_iva').setValue(data.data.costo_menos_impuesto);
            if(this.costo_administrativo==='S')
              this.getField('costo_administrativo').setValue(data.data.costo_menos_impuesto);
        }
        else 
        { 
            this.getField('precio_sin_iva').setValue(0);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
      }
    
      validarCodigoAlterno(){
          //validar si es alterno
          let datos={ datos: {
            value:this.getField('codigo_articulo').getValue()
          }};
          this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '1_alterno', operacion_tipo: 'consulta' };
          this.service.send({
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.sucessAlterno,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
          });
      }
    
      sucessAlterno(data){
        if (data.estado_p === 200 )
        {
            if(data.data[0].total>0){
              this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El artículo '+this.getField('codigo_articulo').getValue()+'\n '+this.getField('nombre_articulo').getValue()+' \nes alterno. ');
              this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false)});  
              this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
              this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
              this.getField('confirmModalCustom').toggle(true);
    
              this.getField('codigo_articulo').setValue('');
              this.getField('nombre_articulo').setValue('');
              this.getField('tarifa_iva').setValue('');
              this.getField('descuento').setValue('');
              this.getField('precio_sin_iva').setValue(0);
              this.getField('costo').setValue('');
            }else{
              //sino es alterno no limpia entonces pasa a traer los demás datos.
              if(this.existe_ubicaciones===true){//
                  this.cargarUbicaciones();
                  this.saldosubicacion();
                  if(this.costo_por_bodega==='S' && ((this.modificando==='true' && this.count>1) || this.modificando==='false'))
                    this.traerCostoBodega();
              }else{ 
                if((this.modificando==='true' && this.count>1) || this.modificando==='false'){
                  //this.traerCostoBodegaYAdmn();  //cambia un poco así que se utilizará el otro personalizado para este from
                  this.traerCostoBodega();
                }
              }
              this.traersaldoBodegas();
            }
        }
        else 
        { 
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
      }


    abrirModificarCantidad(props){
      this.getField('label_nueva_cantidad').setValue("Ingrese la cantidad de "+props.data.nombre+" que desee devolver: ");
      //this.getField('label_precio_actual').setLabel("".concat('El precio actual es : $',("".formatoPrecio(this.row_precios[0].precio1)) , '. Ingrese el nuevo precio:'));

      this.getField('modal_modificar_cantidad').handleClickOpen();
      this.getField('nueva_cantidad').setValue(props.data.cantidad);
      this.getField('bt_cancelar_cantidad').setClick(()=>{this.getField('modal_modificar_cantidad').handleClose();});
      this.getField('bt_modificar_cantidad').setClick(()=>{this.modificarCantidad(props);});
    }

    modificarCantidad(props){
      if(this.getField('nueva_cantidad').valid()){
        this.getField('modal_modificar_cantidad').setDisabled(true);
        this.mostrarMensajeGuardando();
          let datos={ datos: {
            codigo_articulo:props.data.codigo_articulo,
            nueva_cantidad:this.getField('nueva_cantidad').getValue(),       
            existe_ubicaciones:this.existe_ubicaciones ,
          }};
          if(this.existe_ubicaciones===false)
            datos.datos.numero_item=props.data.numero_item;
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'modificarcantidad', operacion_tipo: 'modificar' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.successModificarCantidad,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
      }
    }

    successModificarCantidad(data) {
      this.getField('confirmModalCustom').toggle(false);
      this.getField('modal_modificar_cantidad').setDisabled(false);
      if(data.estado_p === 200){
          this.getField('modal_modificar_cantidad').handleClose();
          this.refescarTabla();
      }else {
        if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
      }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }


    mostrarMensajeEmailEnviado(){
      this.getField('confirmModalCustom').toggle(false);
      this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }
  
    mostrarMensajeEmailFallo(){
      this.getField('confirmModalCustom').toggle(false);
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGuardando(){
      this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }
    

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeValidandoInformacion(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    generarTxt()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
              xsucursal: this.xsucursal,
              xfecha: this.xfecha,
              xnumero: this.xnumero,
              nit:this.getField('nit').getValue(),
              nombre:this.getField('nombre').getValue(),
              sede:this.getField('sede').getValue(),
              sucursal_ingreso: this.sucursal_ingreso,
            };
            let op='archivoitemstxt';
            this.nombre_archivo='detalle_devcompra_'+this.xnumero;

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabardevolucion","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }


}


FormJaivana.addController("comp-grabardevolucion", CustomDevolucionCompras);
export default CustomDevolucionCompras;