import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomActualizarNiif
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomActualizarNiif extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.modificarNiif = this.modificarNiif.bind(this);
    this.successModificarNiif= this.successModificarNiif.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.gridOptionsAnio = Object.assign({},this.gridOptions);
    this.limpiarTabla = this.limpiarTabla.bind(this);
    
    this.anio='';

  }

  initForm() {
    console.log("Formulario CustomActualizarNiif,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez, @updateBy Jose Albeiro Marin");
    this.getField('actualizar').setClick(this.modificarNiif);
    this.getField('anio').setOnChange(this.limpiarTabla);
    this.getField('mes').setOnChange(this.limpiarTabla);


  }
  limpiarTabla() {
    if (this.getField('anio').getValue() !== ''  || this.getField('mes').getValue() !== '') {
      this.getField('rejilla').toggle(false);
    } else {
      this.getField('rejilla').toggle(true);
    }
  }

  modificarNiif(){
    this.anio = this.getField('anio').getValue()
    this.mes = this.getField('nombre_mes').getValue()
    if(this.getField('anio').valid() && this.getField('mes').valid()){
      this.getField('actualizar').setDisabled(true);
      let datos = { datos: {
        anio: this.getField('anio').getValue(),
        mes:  this.getField('mes').getValue()
      }};
      this.generalFormatPmv = { tipo_servicio: 'cont-actualizarniif', operacion: '6', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successModificarNiif,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
  }

  successModificarNiif(data){
    if (data.estado_p === 200){
        this.limpiarCampos();
        let datos = Object.keys(data.data);
        // console.log("dataMostrar",datos);
        let existe = false;
        for (const key of datos) {
          if(key==='mensaje'){
            existe=true;
         }
        }
        if(existe === false){
          // console.log("entro",datos)
          let detalle = 'Se actualizaron '+datos.length+' registros para el mes de '+ this.mes;
          this.getField('rejilla').toggle(true)
  
          const ArraysMostrar = [];
          let dataMostrar = {anio:this.anio, detalle:detalle};
          ArraysMostrar.push(dataMostrar);
          // console.log("dataTbla",ArraysMostrar)
          let configCell = new Map();
          this.gridOptionsAnio['rowData'] = ArraysMostrar;
          this.getField('rejilla').initData(this.gridOptionsAnio, configCell);
        }else{
          // console.log("viene mensaje")
          this.getField('rejilla').toggle(false)

        }

        }
        else 
        {
          this.getField('rejilla').toggle(false)
           if(data.estado_p===404) {

           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
       this.getField('actualizar').setDisabled(false);
  }

  limpiarCampos(){
    this.getField('anio').setValue('');
    this.getField('mes').setValue('');
  }

}
FormJaivana.addController("cont-actualizarniif", CustomActualizarNiif);
export default CustomActualizarNiif;