/**
 * @description Custom con funciones adicionales al formulario con código: maes-direccionclientes, ID Formulario: 71, Sección: padre: Maestros(id: 1)- hijo: Terceros(id: 14)
 * @author Sebastian Rios Echeverri
 * @version jdesk_1.01.0001
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomDireccionesClientesSerLog extends FormJaivana.form {


    constructor(props) {

        super(props);
        // Variables de tablas:
        this.gridOptionsDireccionesClientes      =   Object.assign({},this.gridOptions);
       
        // Variables Globales:
        this.arrayObjetos                        =   new Map();
        this.arrayCampos                         =   new Map();
        this.bHayErrores                         =   false;
        this.bHayError                           =   false;
        this.datosAgregar                        =   { datos:{}};
        this.estadoMostrarCampos                 =   ""; // Esta variable va a servir para saber si se muestran los campos, ya sea si el usuario hace clic en eliminar (No mostraría los campos, en caso de que en la función "successDireccionClientes" estado_p sea igual a 404) o si hace clic en seleccionar (Sí mostraría los campos, en caso de que en la función "successDireccionClientes" estado_p sea igual a 404).
       
        // Funciones:
        this.initForm                            =   this.initForm.bind(this);
        this.validarBtnFrm                       =   this.validarBtnFrm.bind(this);
        this.successDireccionClientes            =   this.successDireccionClientes.bind(this);
        this.setButtonTbDireccionCliente         =   this.setButtonTbDireccionCliente.bind(this);
        this.crearBotonTabla                     =   this.crearBotonTabla.bind(this);
        this.accionProcesar                      =   this.accionProcesar.bind(this);
        this.eliminarItemConfirmar               =   this.eliminarItemConfirmar.bind(this);
        this.eliminarItem                        =   this.eliminarItem.bind(this);
        this.successEliminarItem                 =   this.successEliminarItem.bind(this);
        this.crearDireccionesclientes            =   this.crearDireccionesclientes.bind(this);
        this.successCrearDireccionesclientes     =   this.successCrearDireccionesclientes.bind(this);
        this.successCiudadDireccion              =   this.successCiudadDireccion.bind(this);
    }

    initForm() {

        console.log('Formulario CustomDireccionesClientesSerLog,  @version: jdesk_1.01.0001, @author: Sebastian Rios Echeverri');

        this.arrayCampos.set(1, '.');
        this.arrayCampos.set(2, 'nit');
        this.arrayCampos.set(3, 'sede');
        this.arrayCampos.set(4, 'nombre');
        this.arrayCampos.set(5, 'btn_seleccionar');
        this.arrayCampos.set(6, 'tb_direccionesclientes');
        this.arrayCampos.set(7, 'btn_agregar');
        this.arrayCampos.set(8, 'ciudad');
        this.arrayCampos.set(9, 'nciudad');
        this.arrayCampos.set(10, 'direccion');
        this.arrayCampos.set(11, 'telefonos');
        this.arrayCampos.set(12, 'ncliente');
        this.arrayCampos.set(13, 'detalle');
        this.arrayCampos.set(14, '.');
        this.arrayCampos.set(15, 'btn_aceptar');
        this.arrayCampos.set(16, 'btn_cancelar');
        this.arrayCampos.set(25, 'tercero_id');
        this.arrayCampos.set(26, 'ciudad_id');
        this.arrayCampos.set(50, '.');
        this.arrayCampos.set(51, '.');

        this.arrayObjetos.set(1, '.');
        this.arrayObjetos.set(2, 'nit');
        this.arrayObjetos.set(3, 'sede');
        this.arrayObjetos.set(4, 'nombre');
        this.arrayObjetos.set(5, 'btn_seleccionar');
        this.arrayObjetos.set(6, 'tb_direccionesclientes');
        this.arrayObjetos.set(7, 'btn_agregar');
        this.arrayObjetos.set(8, 'ciudad');
        this.arrayObjetos.set(9, 'nciudad');
        this.arrayObjetos.set(10, 'direccion');
        this.arrayObjetos.set(11, 'telefonos');
        this.arrayObjetos.set(12, 'ncliente');
        this.arrayObjetos.set(13, 'detalle');
        this.arrayObjetos.set(14, '.');
        this.arrayObjetos.set(15, 'btn_aceptar');
        this.arrayObjetos.set(16, 'btn_cancelar');
        this.arrayObjetos.set(25, 'tercero_id');
        this.arrayObjetos.set(26, 'ciudad_id');
        this.arrayObjetos.set(50, '.');
        this.arrayObjetos.set(51, '.');

        this.getField('btn_seleccionar').setClick(()=>{this.estadoMostrarCampos = "Seleccionar"; this.validarBtnFrm('seleccionar');});
        this.getField('nit').setOnChange(()=>{this.validarBtnFrm('ocultarTb');this.getField('btn_seleccionar').setDisabled(false)});
        this.getField('btn_agregar').setClick(()=>{this.validarBtnFrm('mostrarCampos');this.getField('btn_agregar').setVisible(false)});
        this.getField('btn_cancelar').setClick(()=>{this.validarBtnFrm('cancelar');});
        this.getField('btn_aceptar').setClick(()=>{this.validarBtnFrm('btn_aceptar');});
    }


    //*** definir los botones y los success
    successCiudadDireccion(data){
        // console.log('Data de successCiudadDireccion:\n',data);
        if(data.estado_p === 200 && data.data.length >= 1){
            this.alertGeneral.toggle(true, 'Los campos ciudad, dirección ya existen, no se permite duplicar', "error");
        }else if(data.estado_p === 404){
            this.crearDireccionesclientes();
        }
    }
    
    crearDireccionesclientes(){
        this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.datosAgregar.datos['nciudad']= this.getField('nciudad').getValue();
        this.datosAgregar.datos['ciudad']= this.getField('ciudad').getValue();
        this.datosAgregar.datos['ciudad_id']= this.getField('ciudad_id').getValue();
        this.datosAgregar.datos['detalle']= this.getField('detalle').getValue();
        this.datosAgregar.datos['direccion']= this.getField('direccion').getValue();
        this.datosAgregar.datos['ncliente']= this.getField('ncliente').getValue();
        this.datosAgregar.datos['nit']= this.getField('nit').getValue();
        this.datosAgregar.datos['sede']= this.getField('sede').getValue();
        this.datosAgregar.datos['telefonos']= this.getField('telefonos').getValue();
        this.datosAgregar.datos['tercero_id']= this.getField('tercero_id').getValue();
        this.generalFormatPmv = {tipo_servicio: 'maes-direccionclientes', operacion: '5', operacion_tipo: 'crear'};
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: this.datosAgregar,
                success: this.successCrearDireccionesclientes,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }
    
    successCrearDireccionesclientes(data){
        // console.log('Data de crear:\n',data);
        if (data.estado_p === 200) {
            this.validarBtnFrm('seleccionar');
            this.establecerPropiedades('8,9,10,11,12,13,26','value,error','false');
            this.establecerPropiedades('7,8,9,10,11,12,13,15,16','visible','false');
        }
    }
    
    successDireccionClientes(data){
        if(data.estado_p === 200 && data.data.length >= 1){
            // console.log('Este es el data:\n',data);
            this.getField('tb_direccionesclientes').toggle(true);
    
            let configCell = new Map();
            this.gridOptionsDireccionesClientes['rowData'] = data.data;
            configCell.set('direccion', {headerName: 'Dirección'});
            configCell.set('telefonos', {headerName: 'Teléfonos'});
            configCell.set('accion', {cellRenderer:this.setButtonTbDireccionCliente, headerName: 'Acción', sortable: false, filter: false/* , width:400 */});
            this.getField('tb_direccionesclientes').initData(this.gridOptionsDireccionesClientes, configCell);
            this.establecerPropiedades('7','visible','true');
        }else{
            if (this.estadoMostrarCampos === 'Eliminar'){
                this.estadoMostrarCampos = "";
            }else if (this.estadoMostrarCampos === 'Seleccionar'){
                this.validarBtnFrm('mostrarCampos');
            }
        }
    }
    
    setButtonTbDireccionCliente(props, btn){
        let fragment = document.createDocumentFragment();
        let buttonVerDetalle = this.crearBotonTabla(props.data, 'Ver Detalle');
        let buttonVerMas = this.crearBotonTabla(props.data, 'Ver Más');
        let buttonEliminar = this.crearBotonTabla(props.data, 'Eliminar');
        buttonVerDetalle.setAttribute("class","buttonStyle2");
        buttonVerMas.setAttribute("class","buttonStyle2");
        buttonEliminar.setAttribute("class","buttonStyle2");
        fragment.appendChild(buttonVerDetalle);
        fragment.appendChild(buttonVerMas);
        fragment.appendChild(buttonEliminar);
        return this.createElementJaivana(fragment);
    }

    /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Ver Detalle" ó "Ver Más" o "Eliminar"} boton 
      * @returns el botón con su respectiva configuración
      */
    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Ver Detalle':
                button.setAttribute("id", `buttonVerDetalle_${data.direccionesclientes_id}`);
                break;
            case 'Ver Más':
                button.setAttribute("id", `buttonVerMas_${data.direccionesclientes_id}`);
                break;
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.direccionesclientes_id}`);
                break;

            default:
                break;
        }
        button.onclick = () => {this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }
    
    /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click ("Ver Detalle" ó "Ver Más" o "Eliminar")} boton 
      */
    accionProcesar(data, boton) {
        if (boton === 'Eliminar') {
            this.eliminarItemConfirmar(data, 'Eliminar');
        }else if (boton === 'Ver Detalle'){
            this.getField('confirmModalCustom').setTitleAndContent(`${boton.toUpperCase()}`, `${data.detalle}`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }else if (boton === 'Ver Más'){
            this.getField('confirmModalCustom').setTitleAndContent(`${boton.toUpperCase()}`, `Ciudad: ${data.ciudad}; Nombre Ciudad: ${data.nombre_ciudad}; Nombre: ${data.nombre}; Dirección: ${data.direccion}; Teléfonos: ${data.telefonos}; Detalle: ${data.detalle}`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    eliminarItemConfirmar(data, boton) {
        // console.log('Este es el data de eliminar:\n',data);
        this.getField('confirmModalCustom').setTitleAndContent(`${boton}`, '¿Desea realizar esta operación?')
        this.getField('confirmModalCustom').setClickDialog(() => {this.eliminarItem(data.direccionesclientes_id)});
        this.getField("confirmModalCustom").setVisibleCancel(false);// Para mostrar el Cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`${boton}`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminarItem(direccionesclientes_id) {
        this.getField('confirmModalCustom').toggle(false);
        this.estadoMostrarCampos = "Eliminar";
        if (direccionesclientes_id !== "") {
            this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
            this.datosAgregar.datos['transportadoras_id']= direccionesclientes_id;
            this.generalFormatPmv = { tipo_servicio: 'maes-direccionclientes', operacion: '7', operacion_tipo: 'eliminar'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: this.datosAgregar,
                    success: this.successEliminarItem,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    /**
     * Esta función refresca la tabla una vez se elimine el registro
     * @param {encapsula la respuesta del servicio} data 
     */
    successEliminarItem(data){
        if (data.estado_p === 200) {
            this.validarBtnFrm('seleccionar');
        }
    }
    
    validarBtnFrm(boton) {
        switch (boton) {
            case "seleccionar":
                this.getField('btn_seleccionar').setDisabled(true);
                this.validarBtnFrm('ocultarTb');
                this.establecerPropiedades('8,9,10,11,12,13,26','value,error','false');
                if (this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid() && 
                this.getField('tercero_id').valid()) {
                    this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
                    this.datosAgregar.datos['nit']=this.getField('nit').getValue();
                    this.datosAgregar.datos['sede']=this.getField('sede').getValue();
                    this.datosAgregar.datos['tercero_id']=this.getField('tercero_id').getValue();
                    this.generalFormatPmv = { tipo_servicio: 'maes-direccionclientes', operacion: '20', operacion_tipo: 'consulta'};
                    this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: this.datosAgregar,
                            success: this.successDireccionClientes,
                            error: this.error_,
                            general: this.generalFormatPmv
                        }
                    );
                }
                break;

            case "ocultarTb":
                this.gridOptionsDireccionesClientes['rowData'] = [];
                this.getField('tb_direccionesclientes').initData(this.gridOptionsDireccionesClientes);
                this.getField('tb_direccionesclientes').toggle(false);
                this.establecerPropiedades('7,8,9,10,11,12,13,15,16','visible','false');
                break;

            case "mostrarCampos":
                this.establecerPropiedades('8,9,10,11,12,13,15,16','visible','true');
                this.establecerPropiedades('8,9,10,11,12,13,26','value,error','false');
                break;

            case "cancelar":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.validarBtnFrm('ocultarTb');
                    this.establecerPropiedades('2,3,4,25','value,error','false');
                    this.estadoMostrarCampos = "";
                    this.getField('confirmModalCustom').toggle(false); 
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "btn_aceptar":
                if (this.getField('nit').valid() && this.getField('sede').valid() && this.getField('nombre').valid() && 
                this.getField('ciudad').valid() && this.getField('nciudad').valid() && this.getField('direccion').valid() && 
                this.getField('telefonos').valid() && this.getField('ncliente').valid() && this.getField('detalle').valid() && 
                this.getField('tercero_id').valid() && this.getField('ciudad_id').valid()) {
                    this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
                    this.datosAgregar.datos['ciudad']=this.getField('ciudad').getValue();
                    this.datosAgregar.datos['direccion']=this.getField('direccion').getValue();
                    this.generalFormatPmv = { tipo_servicio: 'maes-direccionclientes', operacion: '21', operacion_tipo: 'consulta'};
                    this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: this.datosAgregar,
                            success: this.successCiudadDireccion,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                        }
                    );
                }
                break;

            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }
    //*** fin  los botones y los success

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }
}
FormJaivana.addController('serlog-direccionclientes', CustomDireccionesClientesSerLog);
export default CustomDireccionesClientesSerLog;