import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomReclasificarTerceros
 * @author: Santiago Hernández Neira.
**/

class CustomReclasificarTerceros extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.validarTerceros                = this.validarTerceros.bind(this);
        this.mostrarTabla                   = this.mostrarTabla.bind(this);
        this.successTbTodos                 = this.successTbTodos.bind(this);
        this.gridOptionsTodos               = Object.assign({},this.gridOptions);
        this.procesar                       = this.procesar.bind(this);
        this.successprocesar                = this.successprocesar.bind(this);
        this.gridOptionsTmp                 = Object.assign({},this.gridOptions);
        this.successDropTmp                 = this.successDropTmp.bind(this);
        this.mostrarTablaTmp                = this.mostrarTablaTmp.bind(this);
        this.successMostrarTb               = this.successMostrarTb.bind(this);
        this.actualizar                     = this.actualizar.bind(this);
        this.successactualizar              = this.successactualizar.bind(this);
    }

    initForm(){
        console.log('Formulario CustomReclasificarTerceros,  @version: jdesk_1.01.0001.1, @author:Santiago Hernández N.');   
        this.getField('tercero_id_origen').setOnChange(this.validarTerceros);
        this.getField('tercero_id_destino').setOnChange(this.validarTerceros);     
        this.getField('procesar').setDisabled(true);
        this.getField('actualizar').setDisabled(true);
        this.mostrarTabla();

        this.getField('procesar').setClick(this.procesar);
        this.getField('actualizar').setClick(this.actualizar);

    }

    validarTerceros(){

        if ((this.getField('tercero_id_origen').getValue()!== '') && (this.getField('tercero_id_destino').getValue()!=='') && (this.getField('tercero_id_origen').getValue()===this.getField('tercero_id_destino').getValue())){
            this.alertGeneral.toggle(true, 'Los terceros no pueden ser iguales.', 'error');
            this.getField('actualizar').setDisabled(true);
        }else if ((this.getField('tercero_id_origen').getValue()!== '') && (this.getField('tercero_id_destino').getValue() ==='')){
            this.getField('procesar').setDisabled(false);
            this.getField('actualizar').setDisabled(true);
        }else if ((this.getField('tercero_id_destino').getValue()!=='') && (this.getField('tercero_id_destino').getValue() !=='')){
            this.getField('procesar').setDisabled(false);
            this.getField('actualizar').setDisabled(false);
        }

        if(this.getField('tercero_id_origen').getValue() === ''){
            this.getField('procesar').setDisabled(true);
            this.getField('actualizar').setDisabled(true);
        }
        this.getField('rejilla_tmp').toggle(false);

    }

    mostrarTabla(){
        
        let datos = {datos: {}}
        this.generalFormatPmv = { tipo_servicio: 'cont-espreclasificarterceros', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successTbTodos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: true,
            }
        );

    }


    successTbTodos(data){
        if (data.estado_p === 200 && data.model && data.model.length >= 1) {
            this.getField('rejilla').toggle(true)
            this.gridOptionsTodos['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptionsTodos);
            this.gridOptionsTodos.api.paginationGoToPage(0);
        }else{
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            this.getField('rejilla').toggle(false)
        }
    }

    procesar(){
        if(this.getField('tercero_origen').getValue() !== ""){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                
            });
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            let datos = {  datos: { 
                tercero_origen:this.getField('tercero_origen').getValue(),
                sede_origen:this.getField('sede_origen').getValue(),
            }
            }


            this.generalFormatPmv = { tipo_servicio: 'cont-espreclasificarterceros', operacion: 'procesar', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successprocesar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Por favor ingrese un tercero origen. `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                
            });
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    successprocesar(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 ){
            var lengtherrores = 0;
            if (Object.keys(data.data).length===2){
                lengtherrores = Object.keys(data.data.errores).length;
            }

            if (lengtherrores>0) {
                this.getField('confirmModalCustom').setTitleAndContent('RECLASIFICACIÓN TERCEROS', `${'!!! '+Object.values(data.data.errores)+' !!!'}`);
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                    this.getField('rejilla').toggle(false);
                    this.mostrarTabla();
                    this.mostrarTablaTmp();

        
                });
                this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);    
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('RECLASIFICACIÓN TERCEROS', `${'!!! RECLASIFICACIÓN TERCEROS EXITOSA !!!'}`);
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                    this.getField('rejilla').toggle(false);
                    this.mostrarTabla();
                    this.mostrarTablaTmp();
                    
                });
                this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }
            


        } else {
            var length = Object.keys(data.data.errores).length;
            if (length>0){
                this.getField('confirmModalCustom').setTitleAndContent('RECLASIFICACIÓN TERCEROS', `${'!!! '+Object.values(data.data.errores)+' !!!'}`);
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                    
                });
                this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);    


            }else{
                this.alertGeneral.toggle(true, 'Error en RECLASIFICACIÓN TERCEROS.', 'error');    
            }
        }   
    }



    mostrarTablaTmp(){

        let datos = {datos: {}}
        this.generalFormatPmv = { tipo_servicio: 'cont-espreclasificarterceros', operacion: 'mostrartablaTmp', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successMostrarTb,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: true,
            }
        );

    }

    successMostrarTb(data){
        if (data.estado_p === 200 ){

            this.getField('rejilla_tmp').toggle(true)
            this.gridOptionsTmp['rowData'] = data.data.tmp;
            this.getField('rejilla_tmp').initData(this.gridOptionsTmp);
            this.gridOptionsTmp.api.paginationGoToPage(0);

            let datos = {  datos: { 
                }
            }

            this.generalFormatPmv = { tipo_servicio: 'cont-espreclasificarterceros', operacion: 'droptmp', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successDropTmp,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Error al eliminar tmp.', 'error');

            let datos = {  datos: { 
                }
            }

            this.generalFormatPmv = { tipo_servicio: 'cont-espreclasificarterceros', operacion: 'droptmp', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successDropTmp,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }


    successDropTmp(data){
        if (data.estado_p === 200 ){
            this.alertGeneral.toggle(true, 'Reclasificación terceros éxitosa.', 'success');
        }else{
            this.alertGeneral.toggle(true, 'Error al eliminar tmp.', 'error');
        }
    }

    actualizar(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickDialog(()=>{
            


        });
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let datos = {  datos: { 
            tercero_origen:this.getField('tercero_origen').getValue(),
            sede_origen:this.getField('sede_origen').getValue(),
            tercero_destino:this.getField('tercero_destino').getValue(),
            sede_destino:this.getField('sede_destino').getValue(),
          }
        }


        this.generalFormatPmv = { tipo_servicio: 'cont-espreclasificarterceros', operacion: 'actualizar', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successactualizar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successactualizar(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200){
            this.procesar();
        }else{
            this.procesar();
        }
    }



}
FormJaivana.addController("cont-espreclasificarterceros",CustomReclasificarTerceros);
export default CustomReclasificarTerceros