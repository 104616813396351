import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomConsultarPedidos
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0004
 **/
class CustomConsultarPedidos extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                                           = this.initForm.bind(this);

        // Variables de tablas:
        this.gridOptionsTablaPedidos                               = Object.assign({}, this.gridOptions);
        this.gridOptionsTablaItems                                 = Object.assign({}, this.gridOptions);
        this.gridOptionsBodegasItem                                = Object.assign({}, this.gridOptions);
        this.onSelectionChangedTbCodProd                           = this.onSelectionChangedTbCodProd.bind(this);
        this.gridOptionsModalCodigoProducto                        = Object.assign({}, this.gridOptions);
        this.gridOptionsModalCodigoProducto["onSelectionChanged"]  = this.onSelectionChangedTbCodProd;

        // Variables Globales:
        this.seguir                                                = true;
        this.urlPdfGenerarPedido                                   = '';
        this.recalculaPrecio                                       = '';// Esta variable se va a usar para determinar si el pedido seleccionado en la modal "Favoritos" se le debe recalcular el precio o no.
        this.estadoModalFavorios                                   = true;// Esta variable de estado de tipo booleano, va a servir para saber si se ejecuta la función searchTercero(), si se ejecuta los servcios de la modal favoritos no se ejecuta, si se da click desde el botón "Productos" si se ejecuta
        this.arrayTemporalFavoritos                                = []; // Este array vamos a almacenar los items de la respuesta del servicio "pedidos-traertodopornumero" para poderlo usar en diferentes funciones
        this.nuevoArray                                            = [];
        this.modificarArray                                        = [];
        this.estadoPesoGrm                                         = true;// Esta variable de estado va a controlar el valor que se setea en el campo peso de la modal producto.
        this.itemEnEdicion                                         = '';
        this.bodegaIngreso                                         = '';
        this.bodegaSeleccionada                                    = '';
        this.setBodega                                             = '';
        this.codigoNegocio                                         = '';
        this.codigoProductoRecalculaPrecio                         = '';
        this.contadorRecalculaPrecio                               = 0;
        this.descuentoItemRecalculaPrecio                          = 0;
        this.cantidadItemRecalculaPrecio                           = 0;
        this.itemsArrayPediosRecalculaPrecio                       = [];
        this.estadoServicioCodigoProducto                          = true;// Esta variable global de tipo booleana va a manejar el estado del campo de búsqueda "Código Producto", como los servicios de "Enter" y "f9" se están consumiendo desde custom, esta variable va a servir; para que cuando se modifique el campo "Código Producto" después de haberse consumido uno de los 3 servicios, se limpien los campos relacionados a este.
        this.arrayAuxiliarCodigoProducto                           = [];// Esta variable global de tipo arreglo es la que va a contener el data de la respuesta de los servicios que se consumen en el campo de búsqueda (B) "Código Producto" con los campos específicos y que sólo que quieren mostrar
        this.btnCerrarModal                                        = false;
        this.estadoModalListar                                     = false;
        this.cantidadDespacho                                      = false;
        this.arrayTemporal                                         = [];
        this.auxiliarArray                                         = [];
        this.dAgregado                                             = [];
        this.TipoNegociosProspecto                                 = '';
        this.loadTabla                                             = false;
        this.valorPedido                                           = 0;
        this.totalIvaItems                                         = 0;
        this.subtotalItems                                         = 0;
        this.cantidad                                              = 0;
        this.arrayDataCheckBox                                     = [];
        this.estadoAbrirModalProducto                              = true;// Esta variable de es estado tipo boleano es la que va a determinar si abre la modal de "Producto" si el usuario hace clic sobre el botón "Productos" deberia abrirse la modal, pero si se hace clic sobre el botón "Modificar" de la tabla principal no debería abrirse la modal de "Producto" sino que se debe quedar en la modal del cliente ("Pedidos Mercancía")
        this.valueDireccion                                        = "" // Esta variable se crea para obtener el valor de dirección con que viene por defecto el pedido a modificar para que una vez se consuma el servicio "terceros-direccionescliente" que está en la función "selectDirecciones"; se setee dicho valor.
        this.codigo                                                = "";
        // Funciones:
        this.consultarPedido                                       = this.consultarPedido.bind(this);
        this.successTablaConsultar                                 = this.successTablaConsultar.bind(this);
        this.mensajeErrorFecha                                     = this.mensajeErrorFecha.bind(this);
        this.habilitarBotonConsultar                               = this.habilitarBotonConsultar.bind(this);
        this.crearBotones                                          = this.crearBotones.bind(this);
        this.crearBotonTabla                                       = this.crearBotonTabla.bind(this);
        this.accionBoton                                           = this.accionBoton.bind(this);
        this.eliminarItemTablaItem                                 = this.eliminarItemTablaItem.bind(this);
        this.eliminarItemTabla                                     = this.eliminarItemTabla.bind(this);
        this.currencyFormatterPrecioSinIva                         = this.currencyFormatterPrecioSinIva.bind(this);
        this.formatNumberPrecioSinIva                              = this.formatNumberPrecioSinIva.bind(this);
        this.currencyFormatterPrecioTotal                          = this.currencyFormatterPrecioTotal.bind(this);
        this.formatNumberPrecioTotal                               = this.formatNumberPrecioTotal.bind(this);
        this.currencyFormatterPrecio                               = this.currencyFormatterPrecio.bind(this);
        this.currencyFormatterPrecioSinIvaConDescuento             = this.currencyFormatterPrecioSinIvaConDescuento.bind(this);
        this.currencyFormatterValorIva                             = this.currencyFormatterValorIva.bind(this);
        this.formatterPrecio                                       = this.formatterPrecio.bind(this);
        this.formatterPrecioSinIvaConDescuento                     = this.formatterPrecioSinIvaConDescuento.bind(this);
        this.formatterValorIva                                     = this.formatterValorIva.bind(this);
        this.crearBotonesTablaItem                                 = this.crearBotonesTablaItem.bind(this);
        this.isJson                                                = this.isJson.bind(this);
        this.generarPdf                                            = this.generarPdf.bind(this);
        this.procesar                                              = this.procesar.bind(this);
        this.mostrarMensajeNoHayDatos                              = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeEmailFallo                              = this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeEmailEnviado                            = this.mostrarMensajeEmailEnviado.bind(this);
        this.successEnviarCorreo                                   = this.successEnviarCorreo.bind(this);
        this.enviarCorreoTerceros                                  = this.enviarCorreoTerceros.bind(this);
        this.funcionClickModificarTbPedidos                        = this.funcionClickModificarTbPedidos.bind(this);
        this.successFuncionClickModificarTbPedidos                 = this.successFuncionClickModificarTbPedidos.bind(this);
        this.successTerceroClienteNitSede                          = this.successTerceroClienteNitSede.bind(this);
        this.validarRecalculaPrecio                                = this.validarRecalculaPrecio.bind(this);
        this.loadTablaItems                                        = this.loadTablaItems.bind(this);
        this.clearCamposProducto                                   = this.clearCamposProducto.bind(this);
        this.confirmCloseAlertModal                                = this.confirmCloseAlertModal.bind(this);
        this.setDiasVigencia                                       = this.setDiasVigencia.bind(this);
        this.selectDirecciones                                     = this.selectDirecciones.bind(this);
        this.successDirecciones                                    = this.successDirecciones.bind(this);
        this.retornaBodegasPorCodigoFacturacion                    = this.retornaBodegasPorCodigoFacturacion.bind(this);
        this.successBodegasCodFac                                  = this.successBodegasCodFac.bind(this);
        this.successLoadPreciosClienteRecalculaPrecio              = this.successLoadPreciosClienteRecalculaPrecio.bind(this);
        this.successLoadItemPrecioRecalculaPrecio                  = this.successLoadItemPrecioRecalculaPrecio.bind(this);
        this.dibujarTablaRecalculaPrecio                           = this.dibujarTablaRecalculaPrecio.bind(this);
        //this.enterServicioCodigoProducto                           = this.enterServicioCodigoProducto.bind(this);
        //this.modalCampoBCodigoProducto                             = this.modalCampoBCodigoProducto.bind(this);
        //this.successEnterServicioCodigoProducto                    = this.successEnterServicioCodigoProducto.bind(this);
        this.loadPreciosCliente                                    = this.loadPreciosCliente.bind(this);
        this.servicioArticuloCodigoArticulo                        = this.servicioArticuloCodigoArticulo.bind(this);
        this.modificarItemTablaItem                                = this.modificarItemTablaItem.bind(this);
        this.successLoadPreciosCliente                             = this.successLoadPreciosCliente.bind(this);
        this.recalcularPreciosCantidad                             = this.recalcularPreciosCantidad.bind(this);
        this.validarDespachoMinimo                                 = this.validarDespachoMinimo.bind(this);
        this.successValidarDespachoMinimo                          = this.successValidarDespachoMinimo.bind(this);
        this.loadItemPrecio                                        = this.loadItemPrecio.bind(this);
        this.successLoadItemPrecio                                 = this.successLoadItemPrecio.bind(this);
        this.cleanValid                                            = this.cleanValid.bind(this);
        this.cleanRecalcularCant                                   = this.cleanRecalcularCant.bind(this);
        this.conservaBodegaSeleccionada                            = this.conservaBodegaSeleccionada.bind(this);
        this.mensajeErrorNomBodega                                 = this.mensajeErrorNomBodega.bind(this);
        this.recalcularPreciosLista                                = this.recalcularPreciosLista.bind(this);
        this.cleanRecalcular                                       = this.cleanRecalcular.bind(this);
        this.recalcularPrecios                                     = this.recalcularPrecios.bind(this);
        this.validarDescuentoMaximo                                = this.validarDescuentoMaximo.bind(this);
        this.successValidarDescuentoMax                            = this.successValidarDescuentoMax.bind(this);
        this.llamarModalListar                                     = this.llamarModalListar.bind(this);
        this.validarCostosItem                                     = this.validarCostosItem.bind(this);
        this.agregarItems                                          = this.agregarItems.bind(this);
        this.aplicarDescuentoBloque                                = this.aplicarDescuentoBloque.bind(this);
        this.successDescuentoBloque                                = this.successDescuentoBloque.bind(this);
        this.openModalProducto                                     = this.openModalProducto.bind(this);
        this.setTipoNegocioProspecto                               = this.setTipoNegocioProspecto.bind(this);
        this.closeModalProductos                                   = this.closeModalProductos.bind(this);
        this.searchTercero                                         = this.searchTercero.bind(this);
        this.successSearchTercero                                  = this.successSearchTercero.bind(this);
        this.setPrecioListaProspectos                              = this.setPrecioListaProspectos.bind(this);
        this.setSucursalesProspectos                               = this.setSucursalesProspectos.bind(this);
        this.fechaEntrega                                          = this.fechaEntrega.bind(this);
        this.successFechaEntrega                                   = this.successFechaEntrega.bind(this);
        this.toUpdate                                              = this.toUpdate.bind(this);// En el custom: "CustomPedidos.js" esta función se llama: "savePedido"
        this.successToUpdate                                       = this.successToUpdate.bind(this);
        this.loadBodegasItem                                       = this.loadBodegasItem.bind(this);
        this.successLoadBodegasItem                                = this.successLoadBodegasItem.bind(this);
        this.clearCampos                                           = this.clearCampos.bind(this);
        this.valueOrdenCotizacion                                  = this.valueOrdenCotizacion.bind(this);
        this.rowSelectedPedido                                     = this.rowSelectedPedido.bind(this);
        this.agregarDescuentoItem                                  = this.agregarDescuentoItem.bind(this);
        this.validacionPrecioLista                                 = false; // Esta variable de tipo booleana va a servir para controlar el mensaje de error para el campo "Precio Lista", si está en true; va a limpiar el mensaje de error que está en la función cleanValid(), de lo contrario; no va a mostrar
        this.TraerListaClientes                                    = this.TraerListaClientes.bind(this);
        this.successTraerListaClientes                             = this.successTraerListaClientes.bind(this);
        this.SucursalesPendientes                                  = this.SucursalesPendientes.bind(this);
        this.successSucursalesPendientes                           = this.successSucursalesPendientes.bind(this);
        this.CargarCiudad                                          = this.CargarCiudad.bind(this);
        this.successCargarCiudad                                   = this.successCargarCiudad.bind(this);
        this.crearPedido                                           = this.crearPedido.bind(this);
        this.successCostosItem                                     = this.successCostosItem.bind(this);
        this.modalCampoBCodigoProducto                             = this.modalCampoBCodigoProducto.bind(this);
        
    }

    initForm() {
        console.log("Formulario CustomConsultarPedidos,  @version: jdesk_1.01.0004, @author: Patricia Lopez Sanchez");
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        this.TraerListaClientes();
        this.getField('consultar').setClick(this.consultarPedido);
        this.getField('fecha').setOnChange(this.mensajeErrorFecha);
        this.getField('generar_archivo').setClick(() => this.procesar());

        this.getField('radio_correo').setOnChange((props) => {
            if (this.getField('radio_correo').getValue() === 'N') {
                this.getField('email').setDisabled(true);
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
            } else {
                this.getField('email').setDisabled(false);
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
            }
        });

        //this.getField('codigo_producto').setKeyUp(this.enterServicioCodigoProducto);
        this.getField('codigo_id_producto').setOnChange(this.loadPreciosCliente);
        this.getField('cantidad_item_1273').setOnChange(this.recalcularPreciosCantidad);
        this.getField("cantidad_item_1273").setOnBlur(this.validarDespachoMinimo);
        this.getField('cantidad_item_1273').setOnChange(this.cleanValid);
        this.getField("cantidad_item_1273").setOnChange(this.cleanRecalcularCant);
        this.getField("detalle_item").setOnChange(this.cleanValid);
        this.getField("codigo_producto").setCustomSuccess(this.modalCampoBCodigoProducto);
        this.getField("nombre_producto").setOnChange(()=> {if(this.getField("nombre_producto").getValue() !== ""){
            this.validacionPrecioLista = true; this.cleanValid();
        }else{
            this.clearCamposProducto();
        }});
        //this.getField("nombre_producto").setOnChange(this.clearCamposProducto);
        this.getField('codigo_bodega_item').setOnChange(this.recalcularPreciosCantidad);
        this.getField("codigo_bodega_item").setOnChange(this.loadItemPrecio);
        this.getField("codigo_bodega_item").setOnChange(this.conservaBodegaSeleccionada);
        this.getField('nombre_bodega').setOnChange(this.mensajeErrorNomBodega);
        this.getField("precio_lista").setOnChange(this.recalcularPreciosLista);
        this.getField("precio_lista").setOnChange(this.cleanRecalcular);
        this.getField("precio_lista").setOnChange(this.cleanValid);
        this.getField("descuento_item").setOnChange(this.recalcularPrecios);
        this.getField("descuento_item").setOnBlur(this.validarDescuentoMaximo);
        this.getField('codigo_grupo_producto').setOnChange(this.llamarModalListar);
        this.getField("boton_agregar_item").setClick(this.validarCostosItem);
        this.getField("aplicar_descuento").setClick(this.aplicarDescuentoBloque);
        //this.getField("tipo_negocio_id").setCustomSuccess(this.setTipoNegocioProspecto);
        this.getField("boton_aceptar").setClick(this.closeModalProductos);
        this.getField("sede_tercero").setOnChange(this.cleanValid);
        this.getField("nombre_tercero").setOnChange(this.searchTercero);
        this.getField("observaciones").setOnChange(this.cleanValid);
        this.getField("boton_productos").setClick(this.openModalProducto);
        this.getField("numero_de_item").setOnChange(this.fechaEntrega);
        this.getField("clase_de_pedido").setOnChange(this.cleanValid);
        this.getField("orden_de_compra").setOnChange(this.cleanValid);
        this.getField("btn_actualizar").setClick(this.toUpdate);// En el custom: "CustomPedidos.js" esta función se llama: "savePedido"
        this.getField("orden_cotizacion").setOnChange(this.valueOrdenCotizacion);
        this.getField('modal_producto').setCloseButton(this.crearPedido);
        this.getField('modal_pedidos_mercancia').setCloseButton(this.clearCampos);
    }
        
    rowSelectedPedido() {
        this.arrayDataCheckBox = this.gridOptionsTablaItems.api.getSelectedRows();
        let ItemsDescuento = this.arrayDataCheckBox;
        this.agregarDescuentoItem(ItemsDescuento);
    }

    agregarDescuentoItem(ItemsDescuento) {
        this.dAgregado = ItemsDescuento;
    }

    habilitarBotonConsultar() {

        this.getField('pedidos').toggle(false);
        if (this.seguir) {
            this.getField('consultar').setDisabled(false);
        } else {
            this.getField('consultar').setDisabled(true);
        }

        this.getField('sede').setError(false, '');
    }

    consultarPedido() {

        if (this.getField('nombre_vendedor').getValue() !== '' ||
            this.getField('sede').getValue() !== '' ||
            this.getField('numero').getValue() !== '' ||
            this.getField('fecha').getValue() !== '') {
            this.seguir = true;
        }
        
        this.mensajeErrorFecha();

        if (this.getField('nit').getValue() !== '' && this.getField('sede').getValue() === '') {
            this.seguir = false;
            this.getField('sede').setError(true, '*Este campo es requerido');
        }

        if (this.seguir) {

            let datos={ datos: { } };

            datos.datos={};
            datos.datos.fecha=this.getField('fecha').getValue();

            if(this.getField('numero').getValue() !== ''){
                datos.datos.numero=this.getField('numero').getValue();
            }else{
                this.getField('numero').setError(false,'');
            }
            if(this.getField('vendedor').getValue() !== ''){
                datos.datos.vendedor=this.getField('vendedor').getValue();
            }else{
                this.getField('vendedor').setError(false,'');
            }
            if(this.getField('nit').getValue() !== ''){
                datos.datos.nit=this.getField('nit').getValue();
            }else{
                this.getField('nit').setError(false,'');
            }
            if(this.getField('sede').getValue() !== ''){
                datos.datos.sede=this.getField('sede').getValue();
            }else{
                this.getField('sede').setError(false,'');
            }

            //this.generalFormatPmv.nombre_accion = '1273-Servicio_consultar_pedidos_traer_informacion_pedidos_boton';
            this.generalFormatPmv = { tipo_servicio: 'fact-consultarpedidos', operacion: 'traerinformacionpedidos', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successTablaConsultar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
                });
        }
    }

    successTablaConsultar(data) {
        if (data.estado_p === 200) {
            if (data.data.data.length >= 1) {
                this.getField('pedidos').toggle(true);
                this.gridOptionsTablaPedidos["rowData"] = data.data.data;
                let configCell = new Map();
                configCell.set('accion', { cellRenderer: this.crearBotones, sortable: false, filter: false, field: 'Acción', width: 225 });// Sortable y filter sirven para quitar los iconos que aparecen en los campos de las tablas de filtrar y organizar por orden alfabético y numérico, las propiedades sortable y filter = false son las que me ayudan a controlarlo.
                configCell.set('valor', { cellRenderer: (props) => {return "".formatoPrecio(props.data.valor.toFixed(2))}});// return "".formatoPrecio, se usa para darle formato númerico a los valores de la columna especificada
                this.getField('pedidos').initData(this.gridOptionsTablaPedidos, configCell);
            } else {
                this.getField('pedidos').toggle(false)
            }
        } else {
            this.getField('pedidos').toggle(false)
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    crearBotones(props) {
        let fragment = document.createDocumentFragment();
        let btnExportarPdf = this.crearBotonTabla(props.data, 'Exportar PDF');
        let btnModificarCotizaciones = this.crearBotonTabla(props.data, 'Modificar');
        btnExportarPdf.setAttribute("class", "buttonStyle2");
        if (props.data.tipo !== 'COTIZACIÓN' && props.data.tipo !== 'COTIZACION' && props.data.tipo !== 'cotización' && props.data.tipo !== 'cotizacion') {
            btnModificarCotizaciones.setAttribute("class", "buttonStyle2Disabled");
            btnModificarCotizaciones.setAttribute("disabled", true);
        } else if (props.data.tipo === 'COTIZACIÓN' || props.data.tipo === 'COTIZACION' || props.data.tipo === 'cotización' || props.data.tipo === 'cotizacion') {
            btnModificarCotizaciones.setAttribute("class", "buttonStyle2");
        }
        fragment.appendChild(btnExportarPdf);
        fragment.appendChild(btnModificarCotizaciones);
        return this.createElementJaivana(fragment);
    }

    /**
    * Establece la configuración del botón.
    * @param {La información que contiene el registro seleccionado} data 
    * @param {El botón seleccionado: Puede ser "Exportar PDF" ó otro que se renderice} boton 
    * @returns el botón con su respectiva configuración
    */
    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Exportar PDF':
                button.setAttribute("id", `boton_exportar_pdf_${data.numero}`);
                break;
            case 'Modificar':
                button.setAttribute("id", `boton_modificar_cotizaciones_${data.numero}`);
                break;
            case 'Eliminar Tabla Item':
                button.setAttribute("id", `boton_eliminar_tbItem_${data.codigo}`);
                break;
            case 'Modificar Tabla Item':
                button.setAttribute("id", `boton_modificar_tbItem_${data.codigo}`);
                break;
            default:
                break;
        }
        button.onclick = () => { this.accionBoton(data, boton) };
        if (boton === 'Eliminar Tabla Item') {
            button.setAttribute("value", 'Eliminar');
        } else if (boton === 'Modificar Tabla Item') {
            button.setAttribute("value", 'Modificar');
        } else {
            button.setAttribute("value", boton);
        }
        button.setAttribute("type", "button");
        return button;
    }
    
    /**
    * @param {La información que contiene el registro seleccionado} data 
    * @param {El botón al cual se le dió click} boton 
    */
    accionBoton(data, boton) {
        if (boton === 'Exportar PDF') {
            this.getField('confirmModalCustom').setTitleAndContent('Exportar PDF', `¿Desea descargar PDF?`);
            this.getField('confirmModalCustom').setClickDialog(() => {
                this.getField('modal_imprimir').handleClickOpen();
                this.datos = data;
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
                this.getField('radio_correo').setValue("N");
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setVisibleCancel(false); // "false" Para mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`${boton}`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
        if (boton === 'Modificar') {
            this.getField('confirmModalCustom').setTitleAndContent('Modificar', `¿Desea modificar este ítem?`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.funcionClickModificarTbPedidos(data); });
            this.getField("confirmModalCustom").setVisibleCancel(false); // "false" Para mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`${boton}`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
        if (boton === 'Eliminar Tabla Item') {
            this.eliminarItemTablaItem(data, 'Eliminar');
        }
        if (boton === 'Modificar Tabla Item') {
            this.modificarItemTablaItem(
                data.codigo,
                data.nombre,
                data.bodega,
                data.nombre_bodega_item,
                data.valor_iva,
                data.peso,
                data.precio,
                data.descuento,
                data.cantidad,
                data.codigo_unidad,
                data.detalle,
                data.tarifa_iva,
                data.precio_sin_iva,
                data.precio_mas_iva,
                data.precio_total,
                data.precio_sin_iva_con_descuento
            );
        }
    }

    eliminarItemTablaItem(data, boton) {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?')
        this.getField('confirmModalCustom').setClickDialog(() => { this.eliminarItemTabla(data) });
        this.getField("confirmModalCustom").setVisibleCancel(false);// Para mostrar el Cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm(`${boton}`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    eliminarItemTabla(data) {
        this.getField('confirmModalCustom').toggle(false);
        let indexItem = this.nuevoArray.indexOf(data);
        this.nuevoArray.splice(indexItem, 1);
        if (this.nuevoArray.length >= 1) {
            this.gridOptionsTablaItems["rowData"] = this.nuevoArray;
            let configCell = new Map();
            configCell.set('codigo', { field: "codigo", width: 110 });
            configCell.set('descuento', { field: "descuento", width: 120 });
            configCell.set('precio_sin_iva', {field: "precio sin iva",valueFormatter: this.currencyFormatterPrecioSinIva,});
            configCell.set('precio_total', {field: "precio total",valueFormatter: this.currencyFormatterPrecioTotal,});
            configCell.set('precio', {field:"precio",valueFormatter:this.currencyFormatterPrecio});
            configCell.set('precio_sin_iva_con_descuento', {field:"precio_sin_iva_con_descuento",valueFormatter:this.currencyFormatterPrecioSinIvaConDescuento});
            configCell.set('valor_iva', {field:"valor_iva",valueFormatter:this.currencyFormatterValorIva});
            configCell.set('nombre', { field: "nombre", width: 260 });
            configCell.set('cantidad', { field: "cantidad", width: 110 });
            configCell.set('aplicar_descuento', {headerCheckboxSelection: true,headerCheckboxSelectionFilteredOnly: true,checkboxSelection: true,field: "Descuento en bloque",});
            configCell.set('accion', { cellRenderer: this.crearBotonesTablaItem, sortable: false, filter: false, field: "Acción", width: 244});
            this.gridOptionsTablaItems["suppressRowClickSelection"] = true;
            this.gridOptionsTablaItems["rowSelection"] = "multiple";
            this.gridOptionsTablaItems["enableRangeSelection"] = true;
            this.gridOptionsTablaItems["enableCellChangeFlash"] = true;
            this.getField("descuento_general").setVisible(true);
            this.getField("aplicar_descuento").setVisible(true);
            this.getField("tabla_item").initData(this.gridOptionsTablaItems,configCell);
            this.gridOptionsTablaItems["onSelectionChanged"] = this.rowSelectedPedido;
        } else {
            this.getField('tabla_item').toggle(false);
        }
    }

    modificarItemTablaItem(
        codigo,
        nombre,
        bodega,
        nombre_bodega_item,
        valor_iva,
        peso,
        precio,
        descuento,
        cantidad,
        codigo_unidad,
        detalle,
        tarifa_iva,
        precio_sin_iva,
        precio_mas_iva,
        precio_total,
        precio_sin_iva_con_descuento
    ) {
        /**
         * Funcion que se ejecuta al dar click al boton, y que recibe todas las propiedades del objeto/ítem
         *  */
        if (this.modificarArray.length === 0) {
            //primero verificamos si el arrayModificar esta vacio, de lo contrario, no se puede modificar otro ítem
            this.getField("codigo_producto").setDisabled(true);
            this.getField("codigo_producto").setValue(codigo);
            this.getField("nombre_producto").setValue(nombre);
            this.getField("valor_iva").setValue(valor_iva);
            if (peso === undefined || peso === '' || peso === null) {
                this.estadoPesoGrm = false;
                this.loadPreciosCliente();
            } else {
                this.getField("peso_grm").setValue(peso);
            }
            this.getField("precio_lista").setValue(precio);
            this.getField("descuento_item").setValue(descuento);
            this.getField("cantidad_item_1273").setValue(cantidad);
            this.getField("unidad").setValue(codigo_unidad);
            this.getField("detalle_item").setValue(detalle);
            this.getField("tarifa_iva_item").setValue(tarifa_iva);
            this.getField("precio_facturacion").setValue(precio_sin_iva_con_descuento/* precio_sin_iva */);
            this.getField("valor_total_item").setValue(precio_total);
            this.getField("precio_sin_iva_con_descuento").setValue(precio_sin_iva_con_descuento);
            this.getField("precio_sin_iva").setValue(precio_sin_iva);
            this.getField("precio_mas_iva").setValue(precio_mas_iva);
            this.getField("valor_iva").setValue(valor_iva);
            this.getField('boton_agregar_item').setDisabled(false);
            this.itemEnEdicion = this.getField("nombre_producto").getValue();

            this.nuevoArray.forEach((item) => {
                if (item.codigo !== this.getField("codigo_producto").getValue()) {
                    this.modificarArray.push(item); //se carga en el arrayModificar el ítem que se esta modificando, para evitar hacer otras acciones en la tabla
                }
            });
            this.nuevoArray = this.modificarArray;//agregamos nuevamente el ítem modificado al arrayPrincipal y se recarga la Tabla
            if (this.modificarArray.length > 0) {
                this.gridOptionsTablaItems["rowData"] = this.modificarArray;
                let configCell = new Map();
                configCell.set('codigo', { field: "codigo", width: 110 });
                configCell.set('descuento', { field: "descuento", width: 120 });
                configCell.set('precio_sin_iva', {field: "precio sin iva",valueFormatter: this.currencyFormatterPrecioSinIva,});
                configCell.set('precio_total', {field: "precio total",valueFormatter: this.currencyFormatterPrecioTotal,});
                configCell.set('precio', {field:"precio",valueFormatter:this.currencyFormatterPrecio});
                configCell.set('precio_sin_iva_con_descuento', {field:"precio_sin_iva_con_descuento",valueFormatter:this.currencyFormatterPrecioSinIvaConDescuento});
                configCell.set('valor_iva', {field:"valor_iva",valueFormatter:this.currencyFormatterValorIva});
                configCell.set('nombre', { field: "nombre", width: 260 });
                configCell.set('cantidad', { field: "cantidad", width: 110 });
                configCell.set('aplicar_descuento', {headerCheckboxSelection: true,headerCheckboxSelectionFilteredOnly: true,checkboxSelection: true,field: "Descuento en bloque",});
                configCell.set('accion', { cellRenderer: this.crearBotonesTablaItem, sortable: false, filter: false, field: "Acción", width: 244});
                this.gridOptionsTablaItems["suppressRowClickSelection"] = true;
                this.gridOptionsTablaItems["rowSelection"] = "multiple";
                this.gridOptionsTablaItems["enableRangeSelection"] = true;
                this.gridOptionsTablaItems["enableCellChangeFlash"] = true;
                this.getField("descuento_general").setVisible(true);
                this.getField("aplicar_descuento").setVisible(true);
                this.getField("tabla_item").initData(this.gridOptionsTablaItems,configCell);
                this.gridOptionsTablaItems["onSelectionChanged"] = this.rowSelectedPedido;
            } else {
                this.getField("tabla_item").toggle(false);
                this.getField("tabla_bodega").toggle(false);
            }
        } else {
            this.alertGeneral.toggle(true, "Ya está modificando un ítem", "error");
        }
    }

    crearBotonesTablaItem(props) {
        let fragment = document.createDocumentFragment();
        let btnEliminarTablaItem = this.crearBotonTabla(props.data, 'Eliminar Tabla Item');
        let btnModificarTablaItem = this.crearBotonTabla(props.data, 'Modificar Tabla Item');
        btnEliminarTablaItem.setAttribute("class", "buttonStyle2");
        btnModificarTablaItem.setAttribute("class", "buttonStyle2");
        fragment.appendChild(btnEliminarTablaItem);
        fragment.appendChild(btnModificarTablaItem);
        return this.createElementJaivana(fragment);
    }

    currencyFormatterPrecioTotal(data) {
        let decimal = (data.data.precio_total + "").split(".")[1];
        if (decimal !== 0 && decimal !== undefined) {
            return (this.formatNumberPrecioTotal(data.data.precio_total) + "." + decimal);
        } else {
            return this.formatNumberPrecioTotal(data.data.precio_total);
        }
    }

    formatNumberPrecioTotal(number) {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    currencyFormatterPrecioSinIva(data) {
        let decimal = (data.data.precio_sin_iva + "").split(".")[1];
        if (decimal !== 0 && decimal !== undefined) {
            return (this.formatNumberPrecioSinIva(data.data.precio_sin_iva) + "." + decimal);
        } else {
            return this.formatNumberPrecioSinIva(data.data.precio_sin_iva);
        }
    }

    formatNumberPrecioSinIva(number) {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    currencyFormatterPrecio(data){
        let decimal = (data.data.precio + "").split(".")[1];
        if (decimal !== 0 && decimal !== undefined) {
          return (this.formatterPrecio(data.data.precio) + "." + decimal);
        } else {
          return this.formatterPrecio(data.data.precio);
        }
    }

    currencyFormatterPrecioSinIvaConDescuento(data){
        let decimal = (data.data.precio_sin_iva_con_descuento + "").split(".")[1];
        if (decimal !== 0 && decimal !== undefined) {
          return (this.formatterPrecioSinIvaConDescuento(data.data.precio_sin_iva_con_descuento) + "." + decimal);
        } else {
          return this.formatterPrecioSinIvaConDescuento(data.data.precio_sin_iva_con_descuento);
        }
    }

    currencyFormatterValorIva(data){
        let decimal = (data.data.valor_iva + "").split(".")[1];
        if (decimal !== 0 && decimal !== undefined) {
          return (this.formatterValorIva(data.data.valor_iva) + "." + decimal);
        } else {
          return this.formatterValorIva(data.data.valor_iva);
        }
    }

    formatterPrecio(number){
        return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    formatterPrecioSinIvaConDescuento(number){
        return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    formatterValorIva(number){
        return Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    mensajeErrorFecha() {
        let fechaActual = new Date();
        let fechaCampo = new Date(this.getField('fecha').getValue());

        if (fechaCampo > fechaActual) {

            this.getField('consultar').setDisabled(true);
            this.getField('fecha').setError(true, "*La fecha debe ser menor o igual a la fecha actual");
            this.seguir = false;

        } else if (fechaCampo <= fechaActual) {

            this.habilitarBotonConsultar();
            this.getField('consultar').setDisabled(false);
            this.getField('fecha').setError(false, "");
            this.seguir = true;
        }
        this.habilitarBotonConsultar();
    }
     
    onSelectionChangedTbCodProd(){
        // let filaSelecionada   =  this.gridOptionsModalCodigoProducto.api.getSelectedRows();
        // console.log("Este es el mensaje de onSelectionChangedTbCodProd: ", filaSelecionada[0]);
        this.getField('modal_codigo_producto').handleClose();
        this.arrayAuxiliarCodigoProducto = [];
        this.gridOptionsModalCodigoProducto['rowData'] = [];
        this.getField('tb_modal_codigo_producto').initData(this.gridOptionsModalCodigoProducto);
    }

    funcionClickModificarTbPedidos(data) {
        this.getField('nit_tercero').setError(false,"");
        this.getField('sede_tercero').setError(false,"");
        this.getField('nombre_tercero').setError(false,"");
        this.getField('codigo_ciudad').setError(false,"");
        this.getField('codigo_vendedor').setError(false,"");
        this.getField('observaciones').setError(false,"");
        this.getField('orden_de_compra').setError(false,"");
        if (this.getField('valor_pedido').getValue() === ''){
            this.getField('valor_pedido').setValue(data.valor);
        }
        if (this.getField('nombre_sucursal').getValue() === ''){
            this.getField('nombre_sucursal').setValue(data.detalle);
        }
        if (this.getField('codigo_facturacion').getValue() === ''){
            this.getField('codigo_facturacion').setValue(data.codigo_facturacion);
        }
        if (this.getField('codigo_facturacion_actual').getValue() === ''){
            this.getField('codigo_facturacion_actual').setValue(data.codigo_facturacion);
        }        
        if (this.getField('numero_pedido_item_modificar').getValue() === ''){
            this.getField('numero_pedido_item_modificar').setValue(data.numero);
        }        
        // this.bodegaIngreso = data.bodegas_autorizadas;// Se comenta esta línea; ya que se va a dejar la bodega que trae el registro a modificar, por defecto
        let fechaLocal = new Date();
        let diasVigencia = 30;
        this.setDiasVigencia(fechaLocal, diasVigencia);

        this.estadoAbrirModalProducto = false;
        this.getField('confirmModalCustom').toggle(false);
        this.recalculaPrecio = 'N'; // Se quema en "N", ya que se indicó que no se recalcule el precio
        let datos = { datos:{
            value: data.numero,
        }};
        //this.generalFormat.nombre_accion = '1273_s_pedidos_traertodopornumero';
        this.generalFormatPmv = { tipo_servicio: 'fact-consultarpedidos', operacion: 'traertodopornumero', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successFuncionClickModificarTbPedidos,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successFuncionClickModificarTbPedidos(data) { // En el custom "CustomPedidos.js" esta función se llama: "successFuncionClickSeleccionarTablaModalFavoritos"
        // console.log('Este es el data de successFuncionClickModificarTbPedidos:\n',data);
        if (data.estado_p === 200) {
            this.estadoModalFavorios = false;
            this.getField('estado_pedido_original').setValue(data.data[0].pedido.estado);
            this.getField('nit_tercero').setValue(data.data[0].pedido.nit);
            this.getField('sede_tercero').setValue(data.data[0].pedido.sede);
            this.getField('nombre_tercero').setValue(data.data[0].pedido.nombre);// Al setear este campo, automáticamente se ejecuta la función searchTercero()
            this.valueDireccion = data.data[0].pedido.direccion_entrega; // Se hace de esta forma, ya que si seteo el campo después de ejecutar la función "selectDirecciones" que es la que me trae las direcciones, no va a servir ya que el succes de dicha función solo se ejecuta después de terminar de compilar la función actual (successFuncionClickModificarTbPedidos), por esa razón se almacena en una variable global.
            this.selectDirecciones();
            this.getField('codigo_ciudad').setValue(data.data[0].pedido.ciudad);
            this.getField('campo_carga_ciudades1_codigo').setValue(data.data[0].pedido.ciudad); // Se le pasa el código ciudad con que viene el pedido a modificar originalmente al campo de carga para que se consuma el servicio "ciudades1-codigo" y posterior a ello; setear en el campo "Nombre Ciudad" la ciudad correspondiente al codigo
            this.CargarCiudad();
            this.getField('codigo_vendedor').setValue(data.data[0].pedido.vendedor);
            if (data.data[0].pedido.detalle !== '' && data.data[0].pedido.detalle !== null) {
                this.getField('observaciones').setValue(data.data[0].pedido.detalle);// En realidad lo que se está modificando lo que se manda en "observaciones", es el campo "detalle" de la tabla "Pedidos", entonces, cuando se abre la modal, en vez de setear lo que llega en "observación", se setea lo que llega en "detalle"
            }else{
                this.getField("observaciones").setValue(".");
            }
            if (data.data[0].pedido.orden_compra !== '' && data.data[0].pedido.orden_compra !== null) {
                this.getField('orden_de_compra').setValue(data.data[0].pedido.orden_compra);
            }else{
                this.getField("orden_de_compra").setValue(".");
            }
            this.getField('lista_precios').setValue(data.data[0].pedido.lista_precios);
            this.getField('campo_carga_modal_favoritos').setValue(this.getField('codigo_vendedor').getValue());
            this.SucursalesPendientes();
            this.bodegaIngreso = data.data[0].pedido.bodega; // El valor almacenado en esta variable es el que se va a setear en el select de "Código Bodega" una vez se consuma el servicio "pedidos-bodegas_porcodigofacturacion" que está en la función "retornaBodegasPorCodigoFacturacion"
            this.retornaBodegasPorCodigoFacturacion();
            this.getField("clase_de_pedido").setValue("1EST");//Acá quemamos por custom el código clase pedido "1EST" para que el campo select "Clase de Pedido" quede cargado con el valor de "ESTANDAR" (Así lo solicitaron)
            this.arrayTemporalFavoritos = data.data[0].items;
            
            let datos = { datos:{
                nit: this.getField('nit_tercero').getValue(),
                sede: this.getField('sede_tercero').getValue(),
            }};
            //this.generalFormat.nombre_accion = '1273_s_terceros_clientenitsede';
            this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: 'clientenitsede', operacion_tipo: 'consulta' };
            
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successTerceroClienteNitSede,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
        this.estadoModalFavorios = true;
    }
    
    selectDirecciones() {
        if (this.getField("sede_tercero").getValue() !== "" && this.getField("nit_tercero").getValue() !== "") {
            let datos = { datos:{
                sede: this.getField("sede_tercero").getValue(),
                nit: this.getField("nit_tercero").getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-terceros', operacion: 'direccionescliente', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successDirecciones,
                error: this.error_,
                general: this.generalFormatPmv,
            });
        }
    }

    successDirecciones(data) {
        if (data.data.length >= 1) {
            let opciones = [];
            data.data.forEach((item) => {
                let dataOp = {};
                dataOp["value"] = item.direccion;
                dataOp["text"] = item.direccion /*+ " - " + item.nciudad*/;
                dataOp["campos_cambian"] = { codigo_ciudad: item.codigo_ciudad, nombre_ciudad: item.nciudad, };
                opciones.push(dataOp);
            });
            this.getField("direccion").setOptions(opciones);
            if (this.valueDireccion !== '') {
                this.getField("direccion").setValue(this.valueDireccion);
            }
        } else {
            let opciones = [{ text: "Ninguno", value: "", campos_cambian: {} }];
            this.getField("direccion").setOptions(opciones);
        }
    }
    
    retornaBodegasPorCodigoFacturacion() {
        if (this.getField("codigo_facturacion").getValue() !== '' && this.getField("codigo_facturacion").getValue() !== ' ') {
            let datos = { datos:{
                value: this.getField("codigo_facturacion").getValue(),
            }};
            //this.generalFormat.nombre_accion = "1273_s_select_bodegas_cod_fac";
            this.generalFormatPmv = { tipo_servicio: 'fact-consultarpedidos', operacion: 'bodegas_porcodigofacturacion', operacion_tipo: 'consulta' };

            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successBodegasCodFac,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successBodegasCodFac(data) {
        if (data.data.data.length >= 1) {
            let opciones = [{ text: "Ninguno", value: "", campos_cambian: { nombre_bodega: '' } }];
            data.data.data.forEach((item) => {
                let dataOp = {};
                dataOp["value"] = item.codigo_bodega;
                dataOp["text"] = item.codigo_bodega /*+ " - " + item.nciudad*/;
                dataOp["campos_cambian"] = { nombre_bodega: item.nombre, };
                opciones.push(dataOp);
            });
            this.getField("codigo_bodega_item").setOptions(opciones);
            if (this.bodegaIngreso !== '' && this.bodegaSeleccionada === '') {
                let bodega = [];
                if (this.setBodega === '') {
                    bodega = this.bodegaIngreso.split(",");
                    this.setBodega = bodega[0];
                    this.getField("codigo_bodega_item").setValue(this.setBodega);
                    data.data.data.forEach((item) => {
                        if (item.codigo_bodega === bodega[0]) {
                            this.getField("nombre_bodega").setValue(item.nombre);
                            this.getField('nombre_bodega').setError(false, '');
                        } else if (this.getField('nombre_bodega').getValue() === '') {
                            this.getField('nombre_bodega').setError(true, 'Vuelve a seleccionar un código bodega');
                        }
                    });
                } else if (this.setBodega !== '') {
                    this.getField("codigo_bodega_item").setValue(this.setBodega);
                    data.data.data.forEach((item) => {
                        if (item.codigo_bodega === this.setBodega) {
                            this.getField("nombre_bodega").setValue(item.nombre);
                            this.getField('nombre_bodega').setError(false, '');
                        } else if (this.getField('nombre_bodega').getValue() === '') {
                            this.getField('nombre_bodega').setError(true, 'Vuelve a seleccionar un código bodega');
                        }
                    });
                }
            } else if (this.bodegaSeleccionada !== '') {
                let setBodegaSelecionada = this.bodegaSeleccionada.split(",");
                this.getField("codigo_bodega_item").setValue(setBodegaSelecionada[0]);
                data.data.data.forEach((item) => {
                    if (item.codigo_bodega === setBodegaSelecionada[0]) {
                        this.getField("nombre_bodega").setValue(item.nombre);
                    }
                });
            }
            if (this.estadoAbrirModalProducto === true) {
                this.getField("modal_producto").handleClickOpen();// Se abre la modal acá ya que se pidió que cuando se hiciea clic en el botón "modificar" se abriera la pantalla de clientes (Modal "Pedidos Mercancía") y para poder abrir la modal de "Producto" el usuario debe hacer clic sobre el botón "Productos" y se abre despúes de que se liste el select option de "Código Bodega"
                this.getField("cantidad_item_1273").setError(false, "");
                this.getField("descuento_item").setError(false, "");
                this.getField("precio_lista").setError(false, "");
            }
        } else {
            let opciones = [{ text: "Ninguno", value: "", campos_cambian: {} }];
            this.getField("codigo_bodega_item").setOptions(opciones);
            this.getField("nombre_bodega").setValue('');
        }
    }

    successTerceroClienteNitSede(data) {
        if (data.estado_p === 200) {
            // console.log('Este es el data que quiero ver: ',data);
            this.getField('telefono').setValue(data.data[0].telefonos);
            this.getField('tasa_cambio').setValue(data.data[0].tasa_cambio);
            this.getField("precios").setValue(data.data[0].precios);
            this.getField("codigo_negocio").setValue(data.data[0].codigo_negocio);
            this.getField("tasa_de_cambio").setValue(this.getField("tasa_cambio").getValue());
            this.getField("sucursal_origen").setValue(data.data[0].sucursal_origen);
            this.getField("sucursal_cartera").setValue(data.data[0].sucursal_cartera);
        }
        this.validarRecalculaPrecio();
    }

    validarRecalculaPrecio() {
        if (this.recalculaPrecio === 'N') {
            let itemsPedios = [];
            for (let i = 0; i < this.arrayTemporalFavoritos.length; i++) {
                let ObjetoItem = {
                    codigo: this.arrayTemporalFavoritos[i].codigo,
                    nombre: this.arrayTemporalFavoritos[i].nombre,
                    cantidad: this.arrayTemporalFavoritos[i].cantidad,
                    descuento: this.arrayTemporalFavoritos[i].descuento,
                    precio_sin_iva: this.arrayTemporalFavoritos[i].precio_sin_iva,
                    precio_total: this.arrayTemporalFavoritos[i].precio_total,
                    bodega: this.arrayTemporalFavoritos[i].codigo_bodega,
                    nombre_bodega_item: this.getField('nombre_bodega').getValue(),
                    valor_iva: this.arrayTemporalFavoritos[i].valor_iva,
                    peso: '', // Se quema por custom vacío ('') porque en el servicio: "pedidosfavoritos-traertodopornumero" no trae dicho parámetro (peso), por esa razon mas adelante se consume el servicio articulo-codigoarticulo siempre y cuando el parametro peso no venga con algún valor.
                    precio: this.arrayTemporalFavoritos[i].precio_base,
                    codigo_unidad: this.arrayTemporalFavoritos[i].codigo_unidad,
                    detalle: this.arrayTemporalFavoritos[i].detalle,
                    tarifa_iva: this.arrayTemporalFavoritos[i].tarifa_iva,
                    precio_sin_iva_con_descuento: this.arrayTemporalFavoritos[i].precio_sin_iva_con_descuento,
                    precio_mas_iva: this.arrayTemporalFavoritos[i].precio_mas_iva,
                    tasa_de_cambio: this.getField('tasa_cambio').getValue(),
                    descuento_bloque: false,
                    codigo_facturacion: this.getField('codigo_facturacion').getValue(),
                    subtotal: this.arrayTemporalFavoritos[i].precio_sin_iva,
                    totalIvaItems: this.arrayTemporalFavoritos[i].valor_iva,
                };
                itemsPedios.push(ObjetoItem);
            }
            this.nuevoArray = itemsPedios;
            this.getField('modal_pedidos_mercancia').handleClickOpen();
            this.crearPedido();// Al abrirse la modal principal los campos "Subtotal", "valor Iva", "Valor Pedido" y "Número de Items" quedan vacios ya que sólo se setan cuando se cierra la modal de "Producto" entonces por esa razón se llama esta función (crearPedido) acá para que me calcule los valores que van es estos campos.
            this.loadTablaItems(this.nuevoArray);
        } else if (this.recalculaPrecio === 'S') {
            if (this.contadorRecalculaPrecio < this.arrayTemporalFavoritos.length) {
                this.codigoProductoRecalculaPrecio = this.arrayTemporalFavoritos[this.contadorRecalculaPrecio].codigo;
                this.cantidadItemRecalculaPrecio = this.arrayTemporalFavoritos[this.contadorRecalculaPrecio].cantidad;
                this.descuentoItemRecalculaPrecio = this.arrayTemporalFavoritos[this.contadorRecalculaPrecio].descuento;
                let datos = { datos:{
                    codigo: this.arrayTemporalFavoritos[this.contadorRecalculaPrecio].codigo,
                    codigo_facturacion: this.getField("codigo_facturacion").getValue(),
                    precios: this.getField("precios").getValue(),
                    nit: this.getField("nit_tercero").getValue(),
                    sede: this.getField("sede_tercero").getValue(),
                }};
                //this.generalFormat.nombre_accion = "1273_s_articulo_codigoarticulo";
                this.generalFormatPmv = { tipo_servicio: 'fact-articulos', operacion: 'codigoarticulo', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method: "GET",
                    body: datos,
                    success: this.successLoadPreciosClienteRecalculaPrecio,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage :false
                });
            }
        }
    }

    successLoadPreciosClienteRecalculaPrecio(data) {
        if (data.estado_p === 200) {
            this.getField("precio_lista").setValue(data.data[0].precio);

            let datos = { datos:{
                codigo: this.codigoProductoRecalculaPrecio,
                codigo_facturacion: this.getField("codigo_facturacion").getValue(),
                descuento: this.descuentoItemRecalculaPrecio,
                cantidad: this.cantidadItemRecalculaPrecio,
                precio: this.getField("precio_lista").getValue(),
                nit: this.getField('nit_tercero').getValue(),
                sede: this.getField('sede_tercero').getValue(),
            }};
            // console.log('Este es el body: ',body);
            //this.generalFormat.nombre_accion = "1273_s_retorna_items_pedido_precio";
            this.generalFormatPmv = { tipo_servicio: 'most-terceros', operacion: 'itempedidopreciosivapreferente', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successLoadItemPrecioRecalculaPrecio,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false,
            });
        }
    }

    successLoadItemPrecioRecalculaPrecio(data) {
        //si el calculo es correcto, se setean los valores retornado por el servicio en los campos
        if (data.estado_p === 200) {
            let ObjetoItem = {
                precio_total: data.data.precio_total,
                precio_sin_iva: data.data.precio_sin_iva,
            }
            this.itemsArrayPediosRecalculaPrecio.push(ObjetoItem);
            // console.log('this.itemsArrayPediosRecalculaPrecio: ',this.itemsArrayPediosRecalculaPrecio, '\nEste es el data: ',data);
            this.dibujarTablaRecalculaPrecio();
            this.contadorRecalculaPrecio += 1;
            if (this.contadorRecalculaPrecio < this.arrayTemporalFavoritos.length) {
                this.validarRecalculaPrecio();
            } else {
                this.contadorRecalculaPrecio = 0;
            }
        }
    }

    crearPedido() {
        this.getField("btn_actualizar").setDisabled(true);
        this.confirmCloseAlertModal();
        this.valorPedido = 0;
        this.totalIvaItems = 0;
        this.subtotalItems = 0;
        this.cantidad = 0;
        let mensaje = '';
        //al llamar a esta funcion, se envian los datos del pedido a un servicio para ser creado
        if (this.nuevoArray !== "") {
            let numero_de_items = this.nuevoArray.length;
            let valor_pedido = 0;
            let subtotalSinIva = 0;
            let totalIvaItems = 0;
            this.nuevoArray.forEach((item) => {
                //Se recorre el array
                //y se acumulan en las variables los totales de los items
                valor_pedido += item.precio_total;
                subtotalSinIva += item.precio_sin_iva_con_descuento * item.cantidad;
                totalIvaItems += (((item.precio_sin_iva_con_descuento * item.cantidad) * item.tarifa_iva)/100);
                //this.validarPreciosItems(item);
            });
            if (this.itemEnEdicion !== '') {
                mensaje = `Terminado con éxito, no guardó el último ítem ${this.itemEnEdicion}, que estaba modificando`;
            } else {
                mensaje = "Terminado con éxito"
            }
            this.getField("btn_actualizar").setDisabled(false);
            this.getField("confirmModalCustom").setTitleAndContent("Calcular Totales", mensaje); //Antes de ser eliminado, se ejecuta la función que pide confirmacion
            this.getField("confirmModalCustom").setClickDialog(this.confirmCloseAlertModal);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.getField("confirmModalCustom").toggle(true);
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.alertGeneral.toggle(false, 'Procesando... por favor espere', "warning");
            this.getField("subtotal").setValue(subtotalSinIva.toFixed(2)); //por ultimo los seteamos en los campos respectivos
            this.getField("valor_iva_final").setValue(totalIvaItems.toFixed(2));
            this.getField("valor_pedidos_mercancia").setValue(valor_pedido.toFixed(2));
            this.getField("numero_de_item").setValue(numero_de_items);
        }
    }

    confirmCloseAlertModal() {
        this.arrayAuxiliarCodigoProducto = [];
        this.gridOptionsModalCodigoProducto['rowData'] = [];
        this.getField('tb_modal_codigo_producto').initData(this.gridOptionsModalCodigoProducto);
        this.getField("confirmModalCustom").toggle(false);
    }
    
    setDiasVigencia(fecha, dias) {
        let fechaVigencia = new Date();
        fechaVigencia.setDate(fecha.getDate() + dias);
        let vigencia = fechaVigencia.toISOString();
        this.getField("fecha_vencimiento").setValue(vigencia.split("T")[0]);
        this.getField("fecha_validez").setValue(vigencia.split("T")[0]);
    }


    loadTablaItems(data) {
        // console.log('Este es el data de loadTablaItems',data);
        // this.getField("modal_producto").handleClickOpen(); // Se deja comentado ya que para el formulario Consultar Pedidos se solicitó mostrar primero la pantalla del cliente (Modal "Pedidos Mercancía"), para poder ver la modal de "Producto" toca hacer clic en el botón "Productos"
        this.getField('codigo_producto').setError(false, "");
        this.getField('precio_lista').setError(false, "");
        this.getField('descuento_item').setError(false, "");
        this.getField('cantidad_item_1273').setError(false, "");
        this.getField('detalle_item').setError(false, "");
        this.getField('tarifa_iva_item').setError(false, "");
        //si el array2 esta vacio, quiere decir que el codigo ingresado no esta en el array principal y se procede a agregarlo
        /**
        * El Array es cargado en la tabla, junto con botones adicionales para las respectivas funciones
        *  */
        //El array global es cargado y actualizado con cada objeto que contiene la informacion de
        //los articulos agregados desde el formulario
        this.getField("codigo_producto").setDisabled(false); // se habilita el campo codigo
        this.modificarArray = []; //se limpia el array modificar, si este array esta lleno, quiere decir que en ese momento se esta modificando un ítem y no se podria agregar uno nuevo
        this.gridOptionsTablaItems["rowData"] = data; //y se recarga la tabla
        let configCell = new Map();
        //precio sin iva      y     precio total    ------> para dar formato de número
        configCell.set('codigo', { field: "codigo", width: 110 });
        configCell.set('descuento', { field: "descuento", width: 120 });
        configCell.set('precio_sin_iva', {field: "precio sin iva",valueFormatter: this.currencyFormatterPrecioSinIva,});
        configCell.set('precio_total', {field: "precio total",valueFormatter: this.currencyFormatterPrecioTotal,});
        configCell.set('precio', {field:"precio",valueFormatter:this.currencyFormatterPrecio});
        configCell.set('precio_sin_iva_con_descuento', {field:"precio_sin_iva_con_descuento",valueFormatter:this.currencyFormatterPrecioSinIvaConDescuento});
        configCell.set('valor_iva', {field:"valor_iva",valueFormatter:this.currencyFormatterValorIva});
        configCell.set('nombre', { field: "nombre", width: 260 });
        configCell.set('cantidad', { field: "cantidad", width: 110 });
        configCell.set('aplicar_descuento', {headerCheckboxSelection: true,headerCheckboxSelectionFilteredOnly: true,checkboxSelection: true,field: "Descuento en bloque",});
        configCell.set('accion', { cellRenderer: this.crearBotonesTablaItem, sortable: false, filter: false, field: "Acción", width: 244 });
        this.gridOptionsTablaItems["suppressRowClickSelection"] = true;
        this.gridOptionsTablaItems["rowSelection"] = "multiple";
        this.gridOptionsTablaItems["enableRangeSelection"] = true;
        this.gridOptionsTablaItems["enableCellChangeFlash"] = true;
        this.getField("descuento_general").setVisible(true);
        this.getField("aplicar_descuento").setVisible(true);
        this.getField("tabla_item").initData(this.gridOptionsTablaItems, configCell);
        this.gridOptionsTablaItems["onSelectionChanged"] = this.rowSelectedPedido;
        this.clearCamposProducto(); //una vez se carga un ítem, se limpian los campos para una nueva busqueda
    }

    clearCamposProducto() {
        //cuando se llama esta funcion, se limpan los campos del modal productos
        this.getField("tarifa_iva_item").setValue("");
        this.getField("peso_grm").setValue("");
        this.getField("precio_lista").setValue("");
        this.getField("unidad").setValue("");
        this.getField("precio_facturacion").setValue("");
        this.getField("valor_total_item").setValue("");
        this.getField("descuento_item").setValue(0);
        this.getField("cantidad_item_1273").setValue("");
        this.getField("precio_sin_iva_con_descuento").setValue("");
        this.getField("precio_sin_iva").setValue("");
        this.getField("precio_mas_iva").setValue("");
        this.getField("detalle_item").setValue(".");
    }

    dibujarTablaRecalculaPrecio() {
        if (this.itemsArrayPediosRecalculaPrecio.length === this.arrayTemporalFavoritos.length) {
            let itemsPedios = [];
            for (let i = 0; i < this.arrayTemporalFavoritos.length; i++) {
                let ObjetoItem = {
                    codigo: this.arrayTemporalFavoritos[i].codigo,
                    nombre: this.arrayTemporalFavoritos[i].nombre,
                    cantidad: this.arrayTemporalFavoritos[i].cantidad,
                    descuento: this.arrayTemporalFavoritos[i].descuento,
                    precio_sin_iva: this.itemsArrayPediosRecalculaPrecio[i].precio_sin_iva,
                    precio_total: this.itemsArrayPediosRecalculaPrecio[i].precio_total,
                    bodega: this.arrayTemporalFavoritos[i].codigo_bodega,
                    nombre_bodega_item: this.getField('nombre_bodega').getValue(),
                    valor_iva: this.arrayTemporalFavoritos[i].valor_iva,
                    peso: '', // Se quema por custom vacío ('') porque en el servicio: "pedidosfavoritos-traertodopornumero" no trae dicho parámetro (peso), por esa razon mas adelante se consume el servicio articulo-codigoarticulo siempre y cuando el parametro peso no venga con algún valor.
                    precio: this.arrayTemporalFavoritos[i].precio_base,
                    codigo_unidad: this.arrayTemporalFavoritos[i].codigo_unidad,
                    detalle: this.arrayTemporalFavoritos[i].detalle,
                    tarifa_iva: this.arrayTemporalFavoritos[i].tarifa_iva,
                    precio_sin_iva_con_descuento: this.arrayTemporalFavoritos[i].precio_sin_iva_con_descuento,
                    precio_mas_iva: this.arrayTemporalFavoritos[i].precio_mas_iva,
                    tasa_de_cambio: this.getField('tasa_cambio').getValue(),
                    descuento_bloque: false,
                    codigo_facturacion: this.getField('codigo_facturacion').getValue(),
                    subtotal: this.arrayTemporalFavoritos[i].precio_sin_iva,
                    totalIvaItems: this.arrayTemporalFavoritos[i].valor_iva,
                };
                itemsPedios.push(ObjetoItem);
            }
            this.nuevoArray = itemsPedios;
            this.getField('modal_pedidos_mercancia').handleClickOpen();
            this.loadTablaItems(this.nuevoArray);
            this.retornaBodegasPorCodigoFacturacion();
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setButtonConfirm("Aceptar");
            this.getField('confirmModalCustom').setTitleAndContent('Recalcular Precio', `Se recalcularon los precios de este pedido`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false) });
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    loadPreciosCliente() {
        //cuando se llama esta funcion, se buscan los precios de lista para este cliente en especifico
        if (this.getField("codigo_id_producto").getValue() !== "" && this.codigo !== this.getField("codigo_id_producto").getValue() && this.getField("codigo_producto").getValue() !== "" && this.estadoPesoGrm === true) {
            this.codigo = this.getField("codigo_id_producto").getValue();
            this.servicioArticuloCodigoArticulo();
        } else if (this.estadoPesoGrm === false && this.getField("codigo_producto").getValue() !== '' && this.getField("codigo_facturacion").getValue() !== '' &&
            this.getField("precios").getValue() !== '' && this.getField("nit_tercero").getValue() !== '' && this.getField("sede_tercero").getValue()) {
            this.servicioArticuloCodigoArticulo();
        }
    }

    servicioArticuloCodigoArticulo() {
        let datos = { datos:{
            codigo: this.getField("codigo_producto").getValue(),
            codigo_facturacion: this.getField("codigo_facturacion").getValue(),
            precios: this.getField("precios").getValue(),
            nit: this.getField("nit_tercero").getValue(),
            sede: this.getField("sede_tercero").getValue(),
        }};
        //this.generalFormat.nombre_accion = "1273_s_articulo_codigoarticulo";
        this.generalFormatPmv = { tipo_servicio: 'fact-articulos', operacion: 'codigoarticulo', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: "GET",
            body: datos,
            success: this.successLoadPreciosCliente,
            error: this.error_,
            general: this.generalFormatPmv,
            //showMessage :false
        });
    }

    successLoadPreciosCliente(data) {
        //si el cliente tiene una lista de precios, se setean los campos de ese articulo con la informacion de esa lista
        if (data.estado_p === 200) {
            if (this.estadoPesoGrm === true) {
                this.getField("tarifa_iva_item").setValue(data.model[0].tarifa_iva);
                this.getField("peso_grm").setValue(data.model[0].peso);
                this.getField("precio_lista").setValue(data.model[0].precio);
                this.getField("unidad").setValue(data.model[0].unidad);
                if (this.getField("precio_lista").getValue() !== '' && this.getField("precio_lista").getValue() !== 0){
                    this.validacionPrecioLista = true;
                }else{
                    this.validacionPrecioLista = false;
                }
            } else if (this.estadoPesoGrm === false) {
                this.getField("peso_grm").setValue(data.model[0].peso);
            }
        }
        this.getField('cantidad_item_1273').input.focus();
        this.estadoPesoGrm = true;
    }    
    

    recalcularPreciosCantidad() {
        //si se cambia la cantidad de items de una articulo, se limpian los totales y se recalculan
        this.getField("precio_facturacion").setValue("");
        this.getField("valor_total_item").setValue("");
    }

    validarDespachoMinimo() {
        if (this.getField('codigo_producto').getValue() !== '' && this.getField('cantidad_item_1273').getValue() !== '' &&
        this.getField('cantidad_item_1273').getValue() >= 0.01 && this.getField('codigo_bodega_item').getValue() !== '') {
            let datos = { datos:{
                codigo_producto: this.getField("codigo_producto").getValue(),
                cantidad: this.getField("cantidad_item_1273").getValue(),
                codigo_bodega: this.getField("codigo_bodega_item").getValue(),
                sucursal_ingreso: this.sucursal_ingreso,
            }};
            //this.generalFormat.nombre_accion = "1273_s_unidad_min_despacho_item";
            this.generalFormatPmv = { tipo_servicio: 'fact-articulos', operacion: 'validarunidadminimadespacho', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successValidarDespachoMinimo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successValidarDespachoMinimo(data) {
        if (data.estado_p === 200) {
            this.cantidadDespacho = true;
            this.loadItemPrecio();
            this.cleanValid();
        } else {
            this.cantidadDespacho = false;
            this.getField("boton_agregar_item").setDisabled(true);
            this.getField('cantidad_item_1273').setValue('');
            this.getField('precio_facturacion').setValue('');
            this.getField('valor_total_item').setValue('');
            this.getField('cantidad_item_1273').input.focus();
            this.getField('confirmModalCustom').setTitleAndContent('Cantidad', data.data.mensaje);
            this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false);});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    cleanValid() {
        this.getField("valor_iva_final").setError(false, "");
        this.getField("numero_de_item").setError(false, "");
        this.getField("valor_pedidos_mercancia").setError(false, "");
        this.getField("orden_cotizacion").setError(false, "");
        this.getField("observaciones").setError(false, "");
        this.getField("clase_de_pedido").setError(false, "");
        this.getField("orden_de_compra").setError(false, "");
        this.getField("detalle_item").setError(false, "");

        if(this.getField("descuento_item").valid()){
            this.getField("descuento_item").setError(false, "");
        }else{
            this.getField("boton_agregar_item").setDisabled(true);
        }
        
        if (this.validacionPrecioLista === true && this.getField("precio_lista").valid()) {
            this.getField("precio_lista").setError(false, "");
        }else{
            this.getField("boton_agregar_item").setDisabled(true);
        }

        this.getField("codigo_producto").setError(false, "");
        this.getField("nit_tercero").setError(false, "");
        this.getField("sede_tercero").setError(false, "");

        if (this.getField("detalle_item").valid() && this.cantidadDespacho === true && this.getField("cantidad_item_1273").valid() 
            && this.getField("descuento_item").valid() && this.getField("precio_lista").valid()) {
            this.getField("boton_agregar_item").setDisabled(false);
            this.getField("cantidad_item_1273").setError(false, "");
        } else {
            this.getField("boton_agregar_item").setDisabled(true);
        }
    }

    cleanRecalcularCant() {
        this.getField("valor_total_item").setValue("");
        this.getField("precio_facturacion").setValue("");
        this.getField("boton_agregar_item").setDisabled(true);
    }

    loadItemPrecio() {
        //si se llama esta funcion, se envian los datos a un servicio que calcula los totales
        if (this.getField("nombre_producto").getValue() !== "" && this.getField("nombre_producto").valid() &&
            this.getField("cantidad_item_1273").getValue() >= 0.01 && this.getField("codigo_producto").valid() &&
            this.getField("precio_lista").valid()) {
            this.validacionPrecioLista = true;
            let datos = { datos:{
                codigo: this.getField("codigo_producto").getValue(),
                codigo_facturacion: this.getField("codigo_facturacion").getValue(),
                descuento: this.getField("descuento_item").getValue(),
                cantidad: this.getField("cantidad_item_1273").getValue(),
                precio: this.getField("precio_lista").getValue(),
                nit: this.getField('nit_tercero').getValue(),
                sede: this.getField('sede_tercero').getValue(),
            }};
            //this.generalFormat.nombre_accion = "1273_s_retorna_items_pedido_precio";
            this.generalFormatPmv = { tipo_servicio: 'most-terceros', operacion: 'itempedidopreciosivapreferente', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successLoadItemPrecio,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false,
            });
        } else if (this.getField("precio_lista").getValue() === 0 || this.getField("precio_lista").getValue() === null ||
        this.getField("precio_lista").getValue() === '' || this.getField("precio_lista").getValue() === ' '){
            this.getField("precio_lista").valid();
            this.getField("precio_lista").input.focus();
            this.validacionPrecioLista = false;
        }
    }

    successLoadItemPrecio(data) {
        //si el calculo es correcto, se setean los valores retornado por el servicio en los campos
        // console.log('Data successLoadItemPrecio:\n',data);
        if (data.estado_p === 200) {
            this.getField("precio_facturacion").setValue(data.data.precio_sin_iva_con_descuento/*precio_sin_iva*/);
            this.getField("valor_total_item").setValue(data.data.precio_total);
            this.getField("precio_sin_iva_con_descuento").setValue(data.data.precio_sin_iva_con_descuento);
            this.getField("precio_sin_iva").setValue(data.data.precio_sin_iva);
            this.getField("precio_mas_iva").setValue(data.data.precio_con_iva);
            this.getField("valor_iva").setValue(data.data.valor_iva);
            this.loadBodegasItem();
            this.cleanValid();
        }
    }

    conservaBodegaSeleccionada() {
        if (this.getField("codigo_bodega_item").getValue() !== '') {
            this.bodegaSeleccionada = this.getField("codigo_bodega_item").getValue();
        }
    }

    mensajeErrorNomBodega() {
        if (this.getField('nombre_bodega').getValue() !== '' && this.getField('nombre_bodega').getValue() !== ' ') {
            this.getField('nombre_bodega').setError(false, 'Vuelve a seleccionar un código bodega');
        } else {
            this.getField('nombre_bodega').setError(true, 'Vuelve a seleccionar un código bodega');
        }
    }

    recalcularPreciosLista() {
        //Si se cambia el precio de lista, se limpian los totales y se recalculan
        this.getField("descuento_item").setValue(0);
        this.getField("cantidad_item_1273").setValue("");
        this.getField("precio_facturacion").setValue("");
        this.getField("valor_total_item").setValue("");
    }

    cleanRecalcular() {
        this.getField("cantidad_item_1273").setValue("");
        this.getField("valor_total_item").setValue("");
        this.getField("precio_facturacion").setValue("");
    }

    recalcularPrecios() {
        //Si se cambia el descuento, se limpian los totales y se recalculan
        this.getField("cantidad_item_1273").setValue("");
        this.getField("precio_facturacion").setValue("");
        this.getField("valor_total_item").setValue("");
        this.cleanValid();
        this.cleanRecalcular();
    }

    validarDescuentoMaximo() {
        if (this.getField("nit_tercero").valid() && this.getField("sede_tercero").valid() && this.getField("descuento_item").valid() &&
            this.getField("codigo_producto").valid() && this.getField("precio_lista").valid()) {
            let datos = { datos: {
                nit: this.getField("nit_tercero").getValue(),
                sede: this.getField("sede_tercero").getValue(),
                descuento: this.getField("descuento_item").getValue(),
                codigo: this.getField("codigo_producto").getValue(),
            }};
            //this.generalFormat.nombre_accion = "1273_s_valida_descuento_maximo";
            this.generalFormatPmv = { tipo_servicio: 'fact-articulos', operacion: 'validardescuentomaximopedidos', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successValidarDescuentoMax,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage :false
            });
        }
    }

    successValidarDescuentoMax(data) {
        if (data.estado_p !== 200) {
            let key = Object.keys(data.data);
            let buscar = "descuento_maximo";
            let entro = false;

            key.forEach((item) => {
                if (item === buscar) {
                    entro=true;
                }
            });

            if(entro){
                this.getField("confirmModalCustom").setTitleAndContent("Descuento Maximo Permitido",`${data.data.descuento_maximo}%`); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
            }else{
                this.getField("confirmModalCustom").setTitleAndContent("Descuento Maximo",`${data.data.errores.descuento}`); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
            }
            this.getField("confirmModalCustom").setClickDialog(this.confirmCloseAlertModal);
            this.getField("confirmModalCustom").toggle(true);
            this.getField("confirmModalCustom").setVisibleCancel(true);
        }
    }

    llamarModalListar() {
        if (this.getField('codigo_grupo_producto').getValue() !== '')
            this.estadoModalListar = false;
    }


    validarCostosItem() {
        let datos = { datos:{
            codigo_producto:this.getField("codigo_producto").getValue(),
            precio_sin_iva_con_descuento:this.getField("precio_lista").getValue(),
            codigo_bodega:this.getField("codigo_bodega_item").getValue()
        }};
        //this.generalFormat.nombre_accion = "1273_s_terceros_clientenitsede";
        this.generalFormatPmv = { tipo_servicio: 'fact-consultarpedidos', operacion: 'validarcosto', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: "GET",
            body: datos,
            success: this.successCostosItem,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false,
        });
    }

    successCostosItem(data){
        if (data.estado_p === 200) {
            this.agregarItems();
        }else{
            this.alertGeneral.toggle(true, "No hay datos", "error");
        }
    }

    agregarItems() {
        /**
          * Se crea un arreglo que permite almacenar en un array los datos
          * de cada articulo agregago, este array se carga en una tabla
          */
        if (this.getField("detalle_item").getValue() !== "" && this.getField("cantidad_item_1273").getValue() !== "" &&
            this.getField("cantidad_item_1273").getValue() !== 0 && this.getField("codigo_producto").getValue() !== "" &&
            this.getField("precio_lista").getValue() !== "" && this.getField("precio_lista").getValue() !== 0 &&
            this.getField("valor_total_item").valid() && this.getField("tarifa_iva_item").valid()) {
            this.cantidadDespacho = false;
            this.itemEnEdicion = '';
            this.getField("detalle_item").setError(false, "");
            this.getField("cantidad_item_1273").setError(false, "");
            this.getField("descuento_item").setError(false, "");
            this.getField("precio_lista").setError(false, "");
            this.getField("codigo_producto").setError(false, "");
            this.getField("boton_agregar_item").setDisabled(true);
            let subtotalSinIva = this.getField("precio_sin_iva").getValue() * this.getField("cantidad_item_1273").getValue();
            let totalIvaItems = this.getField("valor_iva").getValue() * this.getField("cantidad_item_1273").getValue();
            let ObjetoItem = {
                codigo: this.getField("codigo_producto").getValue(),
                nombre: this.getField("nombre_producto").getValue(),
                cantidad: this.getField("cantidad_item_1273").getValue(),
                descuento: this.getField("descuento_item").getValue(),
                precio_sin_iva: this.getField("precio_sin_iva").getValue(),
                precio_total: this.getField("valor_total_item").getValue(),
                bodega: this.getField("codigo_bodega_item").getValue(),
                nombre_bodega_item: this.getField("nombre_bodega").getValue(),
                valor_iva: this.getField("valor_iva").getValue(),
                peso: this.getField("peso_grm").getValue(),
                precio: this.getField("precio_lista").getValue(),
                codigo_unidad: this.getField("unidad").getValue(),
                detalle: this.getField("detalle_item").getValue(),
                tarifa_iva: this.getField("tarifa_iva_item").getValue(),
                precio_sin_iva_con_descuento: this.getField("precio_sin_iva_con_descuento").getValue(),
                precio_mas_iva: this.getField("precio_mas_iva").getValue(),
                tasa_de_cambio: this.getField("tasa_de_cambio").getValue(),
                descuento_bloque: 0,
                codigo_facturacion: this.getField("codigo_facturacion").getValue(),
                subtotal: subtotalSinIva,
                totalIvaItems: totalIvaItems,
            };
            if (this.getField("codigo_producto").valid() && this.getField("nombre_producto").getValue() !== "" && this.getField("cantidad_item_1273").valid()) {
                this.arrayTemporal.push(ObjetoItem); //el ítem agregado se carga primero en un array temporal
                let codigoArray1 = this.getField("codigo_producto").getValue(); //el codigo del articulo se guarda en un array
                let codigoArray2 = []; // se crea un array vacio
                this.nuevoArray.forEach((item) => {
                    //se recorre el array principal, y se busca si el codigo del articulo en el array1 ya se encuentra en el array principal
                    if (item.codigo === codigoArray1) {
                        codigoArray2.push(item); // si es asi, se carga ese codigo en el array2
                    }
                });
                if (codigoArray2.length === 0) {
                    this.nuevoArray.push(ObjetoItem);
                    this.loadTablaItems(this.nuevoArray);
                    this.getField("codigo_producto").setValue(""); //se limpian los campos de codigo y nombre
                    this.getField("nombre_producto").setValue("");
                } else {
                    //si el array2 es diferente de 0, quiere decir que el articulo ya fue agregado y se muestra el mensaje
                    this.alertGeneral.toggle(true, "El ítem ya fue agregado", "error");
                }
            } else {
                //si el objeto esta vacio, la tabla no se muestra
                //this.getField('tabla_item').toggle(false);
                ObjetoItem = {};
            }
        } else if (this.getField("codigo_producto").getValue() === "") {
            this.getField("codigo_producto").setError(true, "* Este campo es requerido.");
            if (this.itemEnEdicion === '') {
                this.alertGeneral.toggle(true, "Faltan datos", "error");
            }
            this.getField("boton_agregar_item").setDisabled(true);
        } else if (this.getField("precio_lista").getValue() === "" || this.getField("precio_lista").getValue() === 0) {
            this.getField("precio_lista").setError(true, "* Este campo es requerido.");
            this.alertGeneral.toggle(true, "Faltan datos", "error");
            this.getField("boton_agregar_item").setDisabled(true);
        } else if (this.getField("detalle_item").getValue() === "") {
            this.getField("detalle_item").setError(true, "* Este campo es requerido.");
            this.alertGeneral.toggle(true, "Faltan datos", "error");
            this.getField("boton_agregar_item").setDisabled(true);
        } else if (this.getField("cantidad_item_1273").getValue() === "" || this.getField("cantidad_item_1273").getValue() === 0) {
            this.getField("cantidad_item_1273").setError(true, "* Este campo es requerido.");
            this.alertGeneral.toggle(true, "Faltan datos", "error");
            this.getField("boton_agregar_item").setDisabled(true);
        }
    }


    aplicarDescuentoBloque() {
        if (this.dAgregado.length > 0 && this.itemEnEdicion === '') {
            if (this.getField("descuento_general").valid()) {
                let valorDescuento = this.getField("descuento_general").getValue();
                this.auxiliarArray = [];
                this.nuevoArray.forEach((items) => {
                    let objectItems = {};
                    objectItems = Object.assign({}, items);
                    this.auxiliarArray.push(objectItems);
                });
                this.dAgregado.forEach((item, post) => {
                    this.auxiliarArray.forEach((item2) => {
                        if (item.codigo === item2.codigo) {
                            item2.descuento_bloque = 1;
                            item2.descuento = valorDescuento;
                        }else{
                            item2.descuento_bloque = 0;
                            item2.descuento = 0;
                        }
                    });
                });

                let datos = { datos:{
                    productos: this.auxiliarArray
                }};
                //this.generalFormat.nombre_accion = "1273_s_actualiza_descuento_productos";
                this.generalFormatPmv = { tipo_servicio: 'fact-articulos', operacion: 'actualizarprecioitems', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method: "PUT",
                    body: datos,
                    success: this.successDescuentoBloque,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage :false
                });
            }
        } else if (this.itemEnEdicion !== '') {
            this.alertGeneral.toggle(true, "Esta modificando un ítem", "error");
        } else {
            this.alertGeneral.toggle(true, "No ha seleccionado ningún ítem", "error");
        }
    }

    successDescuentoBloque(data) {
        if (data.estado_p === 200) {
            this.nuevoArray = data.data;
            this.openModalProducto();
            this.dAgregado = [];
            this.getField("descuento_general").setValue(0);
        }
    }

    openModalProducto() {
        //Si se llama esta funcion, se abre el modal, siempre y cuando, la tasa de cambio sea diferente a vacio
        //this.getField('codigo_producto').input.focus();
        this.estadoAbrirModalProducto = true;
        this.getField("cantidad_item_1273").setError(false, "");
        this.getField("descuento_item").setError(false, "");
        this.getField("precio_lista").setError(false, "");

        this.itemEnEdicion = '';
        this.modificarArray = [];
        if (this.TipoNegociosProspecto !== "") {
            this.getField("codigo_negocio").setValue(this.TipoNegociosProspecto);
        }
        if (this.getField("nit_tercero").valid() && this.getField("sede_tercero").valid() && this.getField("nombre_tercero").valid() &&
            this.getField("direccion").valid() && this.getField("telefono").valid() && this.getField("codigo_ciudad").valid() &&
            this.getField("nombre_ciudad").valid() && this.getField("codigo_vendedor").valid() && this.getField("nombre_vendedor_pedidos_mercancia").valid() &&
            this.getField("tasa_cambio").getValue() !== "") {
            this.getField("tasa_de_cambio").setValue(this.getField("tasa_cambio").getValue());
            this.getField("codigo_producto").setDisabled(false); // si el campo de codigo esta bloqueado, se habilita
            if (this.nuevoArray.length > 0) {
                //Si el arrayPricipal tiene items agregados, se busca nuevamente
                //this.searchTercero();//la informacion del cliente para poder seguir agregando items
                if (this.codigoNegocio !== "") {
                    this.getField("codigo_negocio").setValue(this.codigoNegocio);
                }
                this.loadTabla = true;
                this.loadTablaItems(this.nuevoArray);
                this.clearCamposProducto(); //una vez se carga un ítem, se limpian los campos para una nueva busqueda 
            } else {
                //si el array esta vacio, solo se busca la informacion del cliente y se abre el modal, la tabla no se muestra
                //this.searchTercero();
                this.getField("descuento_general").setVisible(false);
                this.getField("aplicar_descuento").setVisible(false);
                if (this.codigoNegocio !== "") {
                    this.getField("codigo_negocio").setValue(this.codigoNegocio);
                }
                this.getField('codigo_producto').setError(false, "");
                this.getField('precio_lista').setError(false, "");
                this.getField('descuento_item').setError(false, "");
                this.getField('cantidad_item_1273').setError(false, "");
                this.getField('detalle_item').setError(false, "");
                this.getField('tarifa_iva_item').setError(false, "");
            }
            this.retornaBodegasPorCodigoFacturacion();
        }
        this.getField('detalle_item').setError(false, "");
        this.getField('descuento_general').setError(false, "");
        this.getField('detalle_item').setValue('.');
        this.getField("lista_precios").setValue(this.getField("precios").getValue());
    }

    setTipoNegocioProspecto(data) {
        if (data.length >= 1) {
            this.TipoNegociosProspecto = data[0].codigo_negocio;
        } else {
            this.alertGeneral.toggle(true, "No existe un código de Negocio Válido", "error");
            this.TipoNegociosProspecto = "";
        }
    }

    closeModalProductos() {
        if (this.itemEnEdicion === '') {
            this.btnCerrarModal = true;
            this.crearPedido();
            this.getField("modal_producto").handleClose();
        } else {
            this.alertGeneral.toggle(true, "Esta Editando un ítem", "error");
        }
        //Se cierra el modal al dar click en boton aceptar
    }

    searchTercero() {
        if (this.estadoModalFavorios === true) {// Esta condición se hace para saber si la función searchTercero() fue llamada desde el botón "modificar" o no; para así saber que datos setear en lo campos de la modal principal "Pedidos Mercancía"
            //servicio que consulta los datos del Cliente
            this.getField("clase_de_pedido").setValue("1EST");
            if (this.getField("nit_tercero").getValue() !== "" && this.getField("sede_tercero").getValue() !== "" && this.getField("nombre_tercero").getValue() !== "") {
                this.getField("nit_tercero").setError(false, "* Este campo es requerido.");
                this.getField("sede_tercero").setError(false, "* Este campo es requerido.");
                this.getField("nombre_tercero").setError(false, "* Este campo es requerido.");

                let datos = { datos:{
                    nit: this.getField("nit_tercero").getValue(),
                    sede: this.getField("sede_tercero").getValue(),
                }};
                //this.generalFormat.nombre_accion = "1273_s_terceros_clientenitsede";
                this.generalFormatPmv = { tipo_servicio: 'most-terceros', operacion: 'clientenitsede', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method: "GET",
                    body: datos,
                    success: this.successSearchTercero,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false,
                });
            } else if (this.getField("nit_tercero").getValue() === "" || this.getField("sede_tercero").getValue() === "") {
                this.getField("nit_tercero").setError(true, "* Este campo es requerido.");
                this.getField("sede_tercero").setError(true, "* Este campo es requerido.");
            }
        }
    }

    successSearchTercero(data) {
        //si el cliente tiene datos, se setean en los campos respectivos
        this.getField("tasa_de_cambio").setValue(this.getField("tasa_cambio").getValue());
        if (data.estado_p === 200) {
            this.getField("direccion").setValue(data.model[0].direccion);
            this.getField("telefono").setValue(data.model[0].telefonos);
            this.getField("codigo_ciudad").setValue(data.model[0].codigo_ciudad);
            if (this.getField('codigo_ciudad').getValue() !== '' && this.getField('codigo_ciudad').getValue() !== ' ') {
                this.getField('codigo_ciudad').setError(false, "");
            }
            this.getField("nombre_ciudad").setValue(data.model[0].nciudad);
            this.getField("codigo_vendedor").setValue(data.model[0].codigo_vendedor);
            if (this.getField("codigo_vendedor").getValue() !== '' && this.getField("codigo_vendedor").getValue() !== ' ') {
                this.getField("codigo_vendedor").setError(false, "");
            }
            this.getField("nombre_vendedor_pedidos_mercancia").setValue(data.model[0].nvendedor);
            this.getField("tasa_cambio").setValue(data.model[0].tasa_cambio);
            this.getField("precios").setValue(data.model[0].precios);
            this.codigoNegocio = data.model[0].codigo_negocio;
            this.getField("codigo_negocio").setValue(data.model[0].codigo_negocio);
            this.getField("tasa_de_cambio").setValue(this.getField("tasa_cambio").getValue());
            this.getField("sucursal_origen").setValue(data.model[0].sucursal_origen);
            this.getField("sucursal_cartera").setValue(data.model[0].sucursal_cartera);
            this.selectDirecciones();
        }
    }

    setPrecioListaProspectos(data) {
        if (data.length <= 1) {
            this.getField("precios").setValue(data.lista_precios_pendientes);
        } else {
            this.alertGeneral.toggle(true, "No existe una lista de precios configurada", "error");
        }
    }

    setSucursalesProspectos(data) {
        if (data.length >= 1) {
            this.getField("sucursal_cartera").setValue(data[0].sucursal_cartera);
            this.getField("sucursal_origen").setValue(data[0].sucursal_origen);
        } else {
            this.alertGeneral.toggle(true, "No existen sucursales cartera y origen configuradas", "error");
        }
    }

    fechaEntrega() {
        if (parseInt(this.getField("numero_de_item").getValue()) >= 1) {
            let datos = { datos:{
                numero_items: parseInt(this.getField("numero_de_item").getValue()),
                nit: this.getField("nit_tercero").getValue(),
                sede: this.getField("sede_tercero").getValue(),
            }};

            //this.generalFormat.nombre_accion = "1273_s_servicio_pedidos_calcular_fecha_entrega";
            this.generalFormatPmv = { tipo_servicio: 'fact-consultarpedidos', operacion: 'calcularfechaentrega', operacion_tipo: 'consulta' };

            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successFechaEntrega,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        } else {
            this.getField("fecha_entrega").setValue("");
        }
    }

    successFechaEntrega(data) {
        if (data.estado_p === 200) {
            this.getField("fecha_entrega").setValue(data.data[0].fecha_entrega);
        }
    }

    toUpdate() {
        // console.log('Campo Observaciones:\n',this.getField("observaciones").getValue());
        //Si se llama a esta funcion, se preparan los array para ser enviado al servicio que crea finalmente el pedido
        this.itemEnEdicion = '';
        if (this.getField("observaciones").valid() && this.getField("subtotal").valid() && this.getField("valor_pedidos_mercancia").valid() &&
            this.getField("numero_de_item").valid() && this.getField("clase_de_pedido").valid() &&
            this.getField("orden_de_compra").valid()) {
            //si hay valores en este campo, se crean el Json del servicio
            //this.getField("btn_actualizar").setDisabled(true);
            let objetoDatos = {
                nit: this.getField("nit_tercero").getValue(),
                numero: this.getField("numero_pedido_item_modificar").getValue(),
                sede: this.getField("sede_tercero").getValue(),
                nombre: this.getField("nombre_tercero").getValue(),
                direccion: this.getField("direccion").getValue(),
                codigo_ciudad: this.getField("codigo_ciudad").getValue(),
                valor: this.getField("valor_pedidos_mercancia").getValue(),
                codigo_vendedor: this.getField("codigo_vendedor").getValue(),
                estado: this.getField('estado_pedido_original').getValue(),// Se deja el valor de estado que tiene el pedido original
                observaciones: this.getField("observaciones").getValue(),
                vence: this.getField("fecha_vencimiento").getValue(),
                vigencia: this.getField("fecha_validez").getValue(),
                lista_precios: this.getField("precios").getValue(),
                codigo_facturacion: this.getField("codigo_facturacion").getValue(),
                fdespacho: this.getField("fecha_entrega").getValue(),
                sucursal_origen: this.getField("sucursal_origen").getValue(),
                sucursal_cartera: this.getField("sucursal_cartera").getValue(),
                fecha_entrega: this.getField("fecha_entrega").getValue(),
                direccion_entrega: this.getField("direccion").getValue(),
                clase_pedido: this.getField("clase_de_pedido").getValue(),
                orden_compra: this.getField("orden_de_compra").getValue(),
                tasa_cambio: this.getField("tasa_cambio").getValue(),
            };
            let datos = { datos: {
                //se envia el objeto con los datos principales del pedido y el array con los datos de los items agregados
                pedido: objetoDatos,
                items: this.nuevoArray,
            }};
            //this.generalFormat.nombre_accion = "1273_s_pedidos_modificar";
            this.generalFormatPmv = { tipo_servicio: 'fact-articulos', operacion: 'modificar', operacion_tipo: 'modificar' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successToUpdate,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage :false
            });
        }
    } //si el pedido es creado con exito, se limpian todos los campos

    successToUpdate(data) {
        this.getField("btn_actualizar").setDisabled(false);
        if (data.estado_p === 200) {
            this.getField("confirmModalCustom").setTitleAndContent("Actualizar Pedido", data.data.mensaje); //antes de ser eliminado, se ejecuta la funcion que pide confirmacion
            this.getField("confirmModalCustom").setClickDialog(() => {
                this.confirmCloseAlertModal();
                this.getField("email").setValue(data.data.email)
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
                this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                let datos={ datos: {
                    nit: this.getField("nit_tercero").getValue(),
                    sede: this.getField("sede_tercero").getValue(),
                    codigo_vendedor: this.getField("codigo_vendedor").getValue(),
                    fecha_orden: this.getField("fecha_entrega").getValue(),
                    estado: this.getField('estado_pedido_original').getValue(),
                    numero: this.getField("numero_pedido_item_modificar").getValue(),
                    email: this.getField('email').getValue(),
                    radio_correo: "S",
                    sucursal_ingreso: this.sucursal_ingreso,
                    operacion: 'pdf'
                }};
                this.generalFormatPmv = { tipo_servicio: 'fact-consultarpedidos', operacion: 'generarpdf', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successEnviarCorreo,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
                this.clearCampos();
                this.clearCamposProducto();
                this.getField("nit_tercero").setValue("");
                this.getField("sede_tercero").setValue("");
                this.getField("nombre_tercero").setValue("");
                this.getField("observaciones").setValue(".");
                this.getField("subtotal").setValue("");
                this.getField("valor_iva_final").setValue("");
                this.getField("valor_pedidos_mercancia").setValue("");
                this.getField("numero_de_item").setValue("");
                this.getField("clase_de_pedido").setValue("");
                this.getField("orden_de_compra").setValue(".");
                this.getField("orden_cotizacion").setValue("");
                this.gridOptionsBodegasItem["rowData"] = []; //se limpia la tabla
                this.getField("tabla_bodega").toggle(false); //se oculta la tabla
                this.nuevoArray = []; //y se limpa el array principal para volver a ser cargados con un pedido nuevo
                let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
                this.getField("direccion").setOptions(opciones);
                let fechaLocal = new Date();
                let diasVigencia = 30;
                this.setDiasVigencia(fechaLocal, diasVigencia);
                this.getField('modal_pedidos_mercancia').handleClose();
                this.consultarPedido();
            });
            this.getField("confirmModalCustom").toggle(true);
            this.getField("confirmModalCustom").setVisibleCancel(true);
        }
    }

    loadBodegasItem() {
        //al llamar a esta funcion, se buscan las cantidades en las bodegas de ese ítem requerido
        if (this.getField("codigo_facturacion").valid() && this.getField("nombre_producto").getValue() !== "") {
            let datos = { datos:{
                codigo: this.getField("codigo_producto").getValue(),
                codigo_facturacion: this.getField("codigo_facturacion").getValue(),
            }};
            //this.generalFormat.nombre_accion = "1273_s_pedidos_bodegasmostrar";
            this.generalFormatPmv = { tipo_servicio: 'most-pedidos', operacion: 'bodegasmostrar', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method: "GET",
                body: datos,
                success: this.successLoadBodegasItem,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false,
            });
        }
    }

    successLoadBodegasItem(data) {
        //si el ítem tiene existencia en las bodegas, se carga la tabla de bodegas_items
        //cargar tabla
        if (data.estado_p === 200) {
            this.gridOptionsBodegasItem["rowData"] = data.model;
            this.getField("tabla_bodega").initData(this.gridOptionsBodegasItem);
        } else {
            //de lo contrario, no se muestra la tabla de bodegas_item
            this.getField("tabla_bodega").toggle(false);
        }
    }

    clearCampos() {
        // cuando se llama esta funcion, se limpan los campos del modal principal
        let opciones = [{ text: "Ninguno", value: " ", campos_cambian: {} }];
        this.getField("direccion").setOptions(opciones);
        this.getField("telefono").setValue("");
        this.getField("codigo_ciudad").setValue("");
        this.getField("nombre_ciudad").setValue("");
        this.getField("codigo_vendedor").setValue("");
        this.getField("nombre_vendedor_pedidos_mercancia").setValue("");
        this.getField("tasa_cambio").setValue("");
        this.getField("precios").setValue("");
        this.getField("codigo_negocio").setValue("");
        this.getField("tasa_de_cambio").setValue("");
        this.getField("observaciones").setValue(".");
        this.getField("subtotal").setValue("");
        this.getField("valor_iva_final").setValue("");
        this.getField("numero_de_item").setValue("");
        this.getField("valor_pedidos_mercancia").setValue("");
        this.getField("numero_pedido_item_modificar").setValue("");
        this.getField('estado_pedido_original').setValue("");
        this.nuevoArray = [];
        this.TipoNegociosProspecto = "";
        this.codigoNegocio = "";
        this.bodegaSeleccionada = '';
        this.itemsArrayPediosRecalculaPrecio = [];
        this.contadorRecalculaPrecio = 0;
        this.itemEnEdicion = '';
        this.valueDireccion = '';
    }

    valueOrdenCotizacion() {
        //se setea el estado del pedido en el campo, segun lo seleccionado en el select
        this.cleanValid();
        if (this.getField("orden_cotizacion").getValue() !== "") {
            this.getField("estado_pedido").setValue(this.getField("orden_cotizacion").getValue());
        } else {
            this.getField("estado_pedido").setValue("");
        }
    }

    TraerListaClientes() {
        //al llamar a esta funcion, se buscan las cantidades en las bodegas de ese ítem requerido
        let datos = { datos:{}};
        this.generalFormatPmv = { tipo_servicio: 'fact-consultarpedidos', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: "GET",
            body: datos,
            success: this.successTraerListaClientes,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false,
        });
    }

    successTraerListaClientes(data) {
        if (data.estado_p === 200) {
            this.setPrecioListaProspectos(data.data);
        } else {
            this.alertGeneral.toggle(true, "No se encontro lista precios", "error");
        }
    }

    SucursalesPendientes() {
        //al llamar a esta funcion, se buscan las cantidades en las bodegas de ese ítem requerido
        let datos = { datos:{
            value: this.getField("campo_carga_modal_favoritos").getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'most-vendedores', operacion: 'codigo', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: "GET",
            body: datos,
            success: this.successSucursalesPendientes,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false,
        });
    }

    successSucursalesPendientes(data) {
        if (data.estado_p === 200) {
            this.getField("nombre_vendedor_pedidos_mercancia").setValue(data.data[0].nombre);
            this.setSucursalesProspectos(data.data);
        } else {
            this.alertGeneral.toggle(true, "No se encontro lista precios", "error");
        }
    }

    CargarCiudad() {
        //al llamar a esta funcion, se buscan las cantidades en las bodegas de ese ítem requerido
        let datos = { datos:{
            value: this.getField("campo_carga_ciudades1_codigo").getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: '42', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: "GET",
            body: datos,
            success: this.successCargarCiudad,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false,
        });
    }

    successCargarCiudad(data) {
        if (data.estado_p === 200) {
            this.getField("nombre_ciudad").setValue(data.data[0].nombre);
        } else {
            this.alertGeneral.toggle(true, "No se encontro lista precios", "error");
        }
    }

    //imprimir------->

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.generarPdf();
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }
    
    enviarCorreoTerceros(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos={ datos: {
            nit: this.datos.nit,
            sede: this.datos.sede,
            codigo_vendedor: this.datos.codigo_vendedor,
            fecha_orden: this.datos.fecha,
            estado: this.datos.estado,
            numero: this.datos.numero,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: 'pdf'
        }};
        this.generalFormatPmv = { tipo_servicio: 'fact-consultarpedidos', operacion: 'generarpdf', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else{
            this.mostrarMensajeEmailFallo();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdf()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{/*Se hace este setClickDialog para especificar que el click en este confirmModalCustom no hace nada, no tiene ninguna acción*/});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
        let url = this.constant.formConfiguration();
        let datos2= {
            nit: this.datos.nit,
            sede: this.datos.sede,
            codigo_vendedor: this.datos.codigo_vendedor,
            estado: this.datos.estado,
            fecha_orden: this.datos.fecha,
            numero: this.datos.numero,
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: 'pdf'
        };
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"fact-consultarpedidos","operacion":"generarpdf","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], 'Pedido.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    
        /**
     * Esta función es la que va a construir la modal del campo de búsqueda "Código Producto" cuando se realice una búsqueda por concidencia de nombre (f9)
     * Se construye desde custom; ya que el data de la respuesta del servicio, responde con muchos parámetros que no se quieren mostrar en la tabla la modal y cómo también se muestran los campos saldos en la tabla yeste son
     * dinámicos, esta función se encarga de construir un arreglo con estos campos dinámicos para después dibujarlo en una tabla, dónde sólo se van a mostrar un total de 10 columnas en los saldos
     * @param {Contiene la respues de los servicios que se consumen en el campo de búsqueda} data 
     */
    modalCampoBCodigoProducto(data) {
        this.getField("codigo_productomodalcodigo_producto").handleClose();
        //console.log('Este es el data de modalCampoBCodigoProducto:\n',data);
        this.arrayAuxiliarCodigoProducto = [];
        this.gridOptionsModalCodigoProducto['rowData'] = [];
        this.getField('tb_modal_codigo_producto').initData(this.gridOptionsModalCodigoProducto);
        this.estadoServicioCodigoProducto = false;
        if (data.length > 1) {
            let numberEncabezadoArray = Object.keys(data[0]);// Se hace para obtener las claves de los objetos con que responde el servicio, la idea de ello; es obtener los nombres de los saldos
            let bodegasPrint = (numberEncabezadoArray.length - 25);// Se hace para determinar la cantidad de columnas con saldo existen
            //console.log('numberEncabezadoArray: ',numberEncabezadoArray);
            //console.log('bodegasPrint: ',bodegasPrint);

            let encabezado1 = numberEncabezadoArray[25];
            let encabezado2 = numberEncabezadoArray[26];
            let encabezado3 = numberEncabezadoArray[27];
            let encabezado4 = numberEncabezadoArray[28];
            let encabezado5 = numberEncabezadoArray[29];
            let encabezado6 = numberEncabezadoArray[30];
            let encabezado7 = numberEncabezadoArray[31];
            let encabezado8 = numberEncabezadoArray[32];
            let encabezado9 = numberEncabezadoArray[33];
            let encabezado10 = numberEncabezadoArray[33];

            let encabezado_campo_id = numberEncabezadoArray[0];
            let encabezado_campo_codigo = numberEncabezadoArray[1];
            let encabezado_campo_nombre = numberEncabezadoArray[2];
            let encabezado_campo_precio = numberEncabezadoArray[3];
            let encabezado_campo_precio2 = numberEncabezadoArray[4];
            let encabezado_campo_precio3 = numberEncabezadoArray[5];

            if (bodegasPrint === 1) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);// Se hace para obtener todos los valores de cada objeto de la respuesta del servicio posición por posición dentro del arreglo de objetos
                    let colum1CodigoProducto = numberValuesArray[25];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, dataOp);// Se hace para unificar los objetos construidos de forma independiente; dentro de un sólo objeto (objetoFinal), para finalmente posicionarlos dentro del arreglo global "this.arrayAuxiliarCodigoProducto"
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint === 2) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint === 3) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];
                    let colum3CodigoProducto = numberValuesArray[27];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;
                    let column3 = {};
                    column3[encabezado3] = colum3CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint === 4) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];
                    let colum3CodigoProducto = numberValuesArray[27];
                    let colum4CodigoProducto = numberValuesArray[28];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;
                    let column3 = {};
                    column3[encabezado3] = colum3CodigoProducto;
                    let column4 = {};
                    column4[encabezado4] = colum4CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint === 5) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];
                    let colum3CodigoProducto = numberValuesArray[27];
                    let colum4CodigoProducto = numberValuesArray[28];
                    let colum5CodigoProducto = numberValuesArray[29];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;
                    let column3 = {};
                    column3[encabezado3] = colum3CodigoProducto;
                    let column4 = {};
                    column4[encabezado4] = colum4CodigoProducto;
                    let column5 = {};
                    column5[encabezado5] = colum5CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint === 6) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];
                    let colum3CodigoProducto = numberValuesArray[27];
                    let colum4CodigoProducto = numberValuesArray[28];
                    let colum5CodigoProducto = numberValuesArray[29];
                    let colum6CodigoProducto = numberValuesArray[30];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;
                    let column3 = {};
                    column3[encabezado3] = colum3CodigoProducto;
                    let column4 = {};
                    column4[encabezado4] = colum4CodigoProducto;
                    let column5 = {};
                    column5[encabezado5] = colum5CodigoProducto;
                    let column6 = {};
                    column6[encabezado6] = colum6CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint === 7) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];
                    let colum3CodigoProducto = numberValuesArray[27];
                    let colum4CodigoProducto = numberValuesArray[28];
                    let colum5CodigoProducto = numberValuesArray[29];
                    let colum6CodigoProducto = numberValuesArray[30];
                    let colum7CodigoProducto = numberValuesArray[31];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;
                    let column3 = {};
                    column3[encabezado3] = colum3CodigoProducto;
                    let column4 = {};
                    column4[encabezado4] = colum4CodigoProducto;
                    let column5 = {};
                    column5[encabezado5] = colum5CodigoProducto;
                    let column6 = {};
                    column6[encabezado6] = colum6CodigoProducto;
                    let column7 = {};
                    column7[encabezado7] = colum7CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, column7, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint === 8) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];
                    let colum3CodigoProducto = numberValuesArray[27];
                    let colum4CodigoProducto = numberValuesArray[28];
                    let colum5CodigoProducto = numberValuesArray[29];
                    let colum6CodigoProducto = numberValuesArray[30];
                    let colum7CodigoProducto = numberValuesArray[31];
                    let colum8CodigoProducto = numberValuesArray[32];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;
                    let column3 = {};
                    column3[encabezado3] = colum3CodigoProducto;
                    let column4 = {};
                    column4[encabezado4] = colum4CodigoProducto;
                    let column5 = {};
                    column5[encabezado5] = colum5CodigoProducto;
                    let column6 = {};
                    column6[encabezado6] = colum6CodigoProducto;
                    let column7 = {};
                    column7[encabezado7] = colum7CodigoProducto;
                    let column8 = {};
                    column8[encabezado8] = colum8CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, column7, column8, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint === 9) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];
                    let colum3CodigoProducto = numberValuesArray[27];
                    let colum4CodigoProducto = numberValuesArray[28];
                    let colum5CodigoProducto = numberValuesArray[29];
                    let colum6CodigoProducto = numberValuesArray[30];
                    let colum7CodigoProducto = numberValuesArray[31];
                    let colum8CodigoProducto = numberValuesArray[32];
                    let colum9CodigoProducto = numberValuesArray[33];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;
                    let column3 = {};
                    column3[encabezado3] = colum3CodigoProducto;
                    let column4 = {};
                    column4[encabezado4] = colum4CodigoProducto;
                    let column5 = {};
                    column5[encabezado5] = colum5CodigoProducto;
                    let column6 = {};
                    column6[encabezado6] = colum6CodigoProducto;
                    let column7 = {};
                    column7[encabezado7] = colum7CodigoProducto;
                    let column8 = {};
                    column8[encabezado8] = colum8CodigoProducto;
                    let column9 = {};
                    column9[encabezado9] = colum9CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, column7, column8, column9, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }
            if (bodegasPrint >= 10) {
                for (let i = 0; i < data.length; i++) {
                    let numberValuesArray = Object.values(data[i]);
                    let colum1CodigoProducto = numberValuesArray[25];
                    let colum2CodigoProducto = numberValuesArray[26];
                    let colum3CodigoProducto = numberValuesArray[27];
                    let colum4CodigoProducto = numberValuesArray[28];
                    let colum5CodigoProducto = numberValuesArray[29];
                    let colum6CodigoProducto = numberValuesArray[30];
                    let colum7CodigoProducto = numberValuesArray[31];
                    let colum8CodigoProducto = numberValuesArray[32];
                    let colum9CodigoProducto = numberValuesArray[33];
                    let colum10CodigoProducto = numberValuesArray[33];

                    let valor_campo_id = numberValuesArray[0];
                    let valor_campo_codigo = numberValuesArray[1];
                    let valor_campo_nombre = numberValuesArray[2];
                    let valor_campo_precio = numberValuesArray[3];
                    let valor_campo_precio2 = numberValuesArray[4];
                    let valor_campo_precio3 = numberValuesArray[5];

                    let dataOp = {
                        tarifa_iva: data[i].tarifa_iva,
                        presenta: data[i].presenta,
                        referencia: data[i].referencia,
                        codigo_marca: data[i].codigo_marca,
                        marca: data[i].marca,
                        codigo_linea: data[i].codigo_linea,
                        nombre_linea: data[i].nombre_linea,
                        codigo_grupo: data[i].codigo_grupo,
                        nombre_grupo: data[i].nombre_grupo,
                        codigo_subgrupo: data[i].codigo_subgrupo,
                        nombre_subgrupo: data[i].nombre_subgrupo,
                        estado_descripcion: data[i].estado_descripcion,
                        estado: data[i].estado,
                        codigo_clasificacion: data[i].codigo_clasificacion,
                    }
                    let column1 = {};
                    column1[encabezado1] = colum1CodigoProducto;
                    let column2 = {};
                    column2[encabezado2] = colum2CodigoProducto;
                    let column3 = {};
                    column3[encabezado3] = colum3CodigoProducto;
                    let column4 = {};
                    column4[encabezado4] = colum4CodigoProducto;
                    let column5 = {};
                    column5[encabezado5] = colum5CodigoProducto;
                    let column6 = {};
                    column6[encabezado6] = colum6CodigoProducto;
                    let column7 = {};
                    column7[encabezado7] = colum7CodigoProducto;
                    let column8 = {};
                    column8[encabezado8] = colum8CodigoProducto;
                    let column9 = {};
                    column9[encabezado9] = colum9CodigoProducto;
                    let column10 = {};
                    column10[encabezado10] = colum10CodigoProducto;

                    let campo_id = {};
                    campo_id[encabezado_campo_id] = valor_campo_id;
                    let campo_codigo = {};
                    campo_codigo[encabezado_campo_codigo] = valor_campo_codigo;
                    let campo_nombre = {};
                    campo_nombre[encabezado_campo_nombre] = valor_campo_nombre;
                    let campo_precio = {};
                    campo_precio[encabezado_campo_precio] = valor_campo_precio;
                    let campo_precio2 = {};
                    campo_precio2[encabezado_campo_precio2] = valor_campo_precio2;
                    let campo_precio3 = {};
                    campo_precio3[encabezado_campo_precio3] = valor_campo_precio3;
                    let objetoFinal = Object.assign(campo_id, campo_codigo, campo_nombre, campo_precio, campo_precio2, campo_precio3, column1, column2, column3, column4, column5, column6, column7, column8, column9, column10, dataOp);
                    this.arrayAuxiliarCodigoProducto.push(objetoFinal);
                }
                //console.log('this.arrayAuxiliarCodigoProducto:\n',this.arrayAuxiliarCodigoProducto);
            }

            if (this.arrayAuxiliarCodigoProducto.length >= 1) {
                this.getField('tb_modal_codigo_producto').toggle(true);
                this.gridOptionsModalCodigoProducto['rowData'] = [];
                this.gridOptionsModalCodigoProducto['rowData'] = this.arrayAuxiliarCodigoProducto;
                this.getField('modal_codigo_producto').handleClickOpen();
                this.getField('tb_modal_codigo_producto').initData(this.gridOptionsModalCodigoProducto);
            }
        }else {
            this.estadoServicioCodigoProducto = false;
            this.getField('codigo_producto').setValue(data[0].codigo);
            this.getField('nombre_producto').setValue(data[0].nombre);
            this.getField('codigo_id_producto').setValue(data[0].codigo);
            this.getField('codigo_grupo_producto').setValue(data[0].codigo_grupo);
        }
    }

    mostrarMensajeEmailEnviado(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
    }
    
    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

}
FormJaivana.addController("fact-consultarpedidos", CustomConsultarPedidos);
export default CustomConsultarPedidos;