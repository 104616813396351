import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomModificarDatosTercero
 * @author: Patricia Lopez Sanchez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomModificarDatosTercero extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.seleccionar                = this.seleccionar.bind(this);
        this.llenarencabezado           = this.llenarencabezado.bind(this);
        this.limpiar1                   = this.limpiar1.bind(this);
        this.service_estadosino         = this.service_estadosino.bind(this);
        this.successTraerEstado         = this.successTraerEstado.bind(this);
        this.tipoCliente                = this.tipoCliente.bind(this);
        this.actualizarManejoCliente    = this.actualizarManejoCliente.bind(this);
        this.successactualizarCliente   = this.successactualizarCliente.bind(this);
        this.traeridvendedor            = this.traeridvendedor.bind(this);
        this.successtraeridvendedor     = this.successtraeridvendedor.bind(this);
        this.traeridvendedor1           = this.traeridvendedor1.bind(this);
        this.successtraeridvendedor1    = this.successtraeridvendedor1.bind(this);
        this.traeridcobrador            = this.traeridcobrador.bind(this);
        this.successtraeridcobrador     = this.successtraeridcobrador.bind(this);
        this.traeridnegocio             = this.traeridnegocio.bind(this);
        this.successtraeridnegocio      = this.successtraeridnegocio.bind(this);
        this.idvendedor                 = true;
        this.idvendedor1                = true;
        this.idcobrador                 = true;
        this.idnegocio                  = true;
        this.habilitar                  = this.habilitar.bind(this);
        this.deshabilitar               = this.deshabilitar.bind(this);
        this.fechaVence                 = this.fechaVence.bind(this);
        this.actualizarProveedor        = this.actualizarProveedor.bind(this);
        this.successactualizarProveedor = this.successactualizarProveedor.bind(this);
        this.tarifa                     = this.tarifa.bind(this);
        this.tipoCliente1               = this.tipoCliente1.bind(this);
        this.regimen1                   = this.regimen1.bind(this);
        this.datoslegales               = this.datoslegales.bind(this);
        this.successdatoslegales        = this.successdatoslegales.bind(this);
        this.datosadicionales1          = this.datosadicionales1.bind(this);
        this.successdatosadicionales    = this.successdatosadicionales.bind(this);
        this.listaPrecios               = this.listaPrecios.bind(this);
        this.successTraerListaPrecios   = this.successTraerListaPrecios.bind(this);
    

    }

    initForm(){
        console.log('Formulario CustomModificarDatosTercero,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez, @updateBy Jose Albeiro Marin')

        this.arrayCampos.set(97,'contenedorDatos1');
        this.arrayCampos.set(98,'nit_buscar');
        this.arrayCampos.set(100,'nombre3');
        this.arrayCampos.set(101,'seleccionar');
        this.arrayCampos.set(103,'cancelar8');
        this.arrayCampos.set(104,'sede3');

        this.arrayObjetos.set(97,'contenedorDatos1');
        this.arrayObjetos.set(98,'nit_buscar');
        this.arrayObjetos.set(100,'nombre3');
        this.arrayObjetos.set(101,'seleccionar');
        this.arrayObjetos.set(103,'cancelar8');
        this.arrayObjetos.set(104,'sede3');

        this.establecerPropiedades('97,98,100,101,103,104','visible','true')
        this.getField('seleccionar').setClick(this.seleccionar); 

        this.getField('TabJaivana_68').setDisabledButtonTab(true);
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.manejoClientes();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.datosproveedores();
        }else if(this.props.data[0].opcion_sub_seccion === 3){
            this.datosLegales();
        }else if(this.props.data[0].opcion_sub_seccion === 4){
            this.datosAdicionales();
        }
        this.getField('cancelar8').setClick(this.limpiar1);
    }
    listaPrecios(){
        if(this.getField('nombre3').getValue() !==''){
            let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'maes-listaprecios', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerListaPrecios,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }
    successTraerListaPrecios(data){
        if (data.estado_p === 200)
        {
            if(data.model.length>0){
             let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.codigo;
             dataOp['text'] = item.codigo;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp);
            })
            this.getField('precios').setOptions(opciones);
            this.getField('precios1').setOptions(opciones);
            }
            this.getField('precio').getValue() !== '' ? this.getField('precios').setValue(this.getField('precio').getValue()) : this.getField('precios').setValue(' ');
            this.getField('precio1').getValue() !== '' ? this.getField('precios1').setValue(this.getField('precio1').getValue()) : this.getField('precios1').setValue(' ');
        }
        else 
        {
           if(data.estado_p===404) {
                // console.log('no hay datos...');
           }
           else {
            //   console.log(data);
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
            //   console.log('Errores:'+respuesta);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    seleccionar(){
        if(this.getField('nombre3').getValue() !== ''){
            this.nit = this.getField('nit_buscar').getValue();
            this.sede = this.getField('sede3').getValue();
            this.nombre = this.getField('nombre3').getValue();
            this.llenarencabezado();
            this.getField('contenedorDatos1').handleClose();
            this.habilitar();
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un tercero.', 'error');
        }
    }

    manejoClientes(){
        this.deshabilitar();
        this.tipoCliente();
        this.service_estadosino();
        this.getField('nombre3').setOnChange(this.listaPrecios)
        this.getField('nombre_vendedor').setOnChange(this.traeridvendedor);
        this.getField('nombre_vendedor1').setOnChange(this.traeridvendedor1);
        this.getField('nombre_negocio_clientes').setOnChange(this.traeridnegocio);
        this.getField('nombre_cobrador').setOnChange(this.traeridcobrador);
        this.getField('aceptar').setClick(this.actualizarManejoCliente);
        this.getField('nuevo').setClick(this.limpiar1);
    }

    datosproveedores(){
        this.deshabilitar();
        this.getField('TabJaivana_68').setNextTab();
        this.service_estadosino();
        this.fechaVence();
        this.tarifa();
        this.getField('reteica').setOnChange(this.tarifa);
        this.getField('nuevo1').setClick(this.limpiar1);
        this.getField('actualizar').setClick(this.actualizarProveedor);
    }

    datosLegales(){
        this.deshabilitar();
        this.getField('TabJaivana_68').setNextTab();
        this.getField('TabJaivana_68').setNextTab();
        this.service_estadosino();
        this.tipoCliente1();
        this.regimen1();
        this.getField('nuevo2').setClick(this.limpiar1);
        this.getField('actualizar1').setClick(this.datoslegales);

    }

    datosAdicionales(){
        this.deshabilitar();
        this.getField('TabJaivana_68').setNextTab();
        this.getField('TabJaivana_68').setNextTab();
        this.getField('TabJaivana_68').setNextTab();
        this.getField('TabJaivana_68').setNextTab();
        this.service_estadosino();
        this.getField('nuevo3').setClick(this.limpiar1);
        this.getField('actualizar2').setClick(this.datosadicionales1);
    }

    llenarencabezado()
    {
        let datosFicha = [];
        let data2 = 
                {
                    "nit": this.nit,
                    "nombre": this.nombre,
                    "sede":this.sede
                };
        datosFicha.push(data2);
        this.getField('lineas').setItemsFichas(datosFicha);
    }

    service_estadosino()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: '1', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerEstado,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerEstado(data)
    {
        if (data.estado_p === 200)
        {
            if(data.model && data.model.length>0){
             let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.estadossino_siglas;
             dataOp['text'] = item.estadossino_siglas;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('maneja_parciales_mercancia').setOptions(opciones);
             this.getField('maneja_tasa_cambio').setOptions(opciones);
             this.getField('cliente_exterior').setOptions(opciones);
             this.getField('retefuente').setOptions(opciones);
             this.getField('reteiva').setOptions(opciones);
             this.getField('reteica').setOptions(opciones);
             this.getField('calcula_iva_item').setOptions(opciones);
             this.getField('proveedor_exterior').setOptions(opciones);
             this.getField('responsable_iva').setOptions(opciones);
             this.getField('autoretenedor').setOptions(opciones);
             this.getField('venta_contado').setOptions(opciones);
             this.getField('cifin').setOptions(opciones);
             this.getField('remisionar').setOptions(opciones);
             }
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    tipoCliente(){

        let tipo = [
            {
                tipo:'N'
            },
            {
                tipo:'E'
            }
        ]
        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             tipo.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.tipo;
             dataOp['text'] = item.tipo;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('tipo').setOptions(opciones);
    }
    
    tipoCliente1(){

        let tipo = [
            {
                tipo:'N',
                text:'Natural'
            },
            {
                tipo:'J',
                text:'Jurídica'
            }
        ]
        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             tipo.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.tipo;
             dataOp['text'] = item.text;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('tipo_persona').setOptions(opciones);
    }

    regimen1(){

        let tipo = [
            {
                tipo:'RC',
                text:'Régimen Común'
            },
            {
                tipo:'GC',
                text:'Gran Contribuyente'
            },
            {
                tipo:'RS',
                text:'Régimen Simplificado'
            },
            {
                tipo:'EE',
                text:'Empresa del Estado'
            }
        ]
        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             tipo.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.tipo;
             dataOp['text'] = item.text;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('regimen').setOptions(opciones);
    }
    traeridvendedor(){
        if(this.getField('nombre_vendedor').getValue() !== ''){
            if (this.idvendedor === true) {
                let datos={ datos: {value:this.getField('codigo_vendedor').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-vendedores', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successtraeridvendedor,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
                
            }
        }
    }

    successtraeridvendedor(data){
        if (data.estado_p === 200 )
        {
            this.getField('vendedor_id').setValue(data.data[0].vendedores_id)
            this.idvendedor=false;
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }
    
    traeridvendedor1(){
        if(this.getField('nombre_vendedor1').getValue() !== ''){
            if (this.idvendedor1 === true) {
                let datos={ datos: {value:this.getField('codigo_vendedor1').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-vendedores', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successtraeridvendedor1,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
                
            }
        }
    }

    successtraeridvendedor1(data){
        if (data.estado_p === 200 )
        {
            this.getField('vendedor1_id').setValue(data.data[0].vendedores_id)
            this.idvendedor1=false;
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }
        
    traeridnegocio(){
        if(this.getField('nombre_negocio_clientes').getValue() !== ''){
            if (this.idnegocio === true) {
                let datos={ datos: {value:this.getField('codigo_negocio').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-negocios', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successtraeridnegocio,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
                
            }
        }
    }

    successtraeridnegocio(data){
        if (data.estado_p === 200 )
        {
            this.getField('negocio_id').setValue(data.data[0].negocios_id)
            this.idnegocio=false;
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }
        
    traeridcobrador(){
        if(this.getField('nombre_cobrador').getValue() !== ''){
            if (this.idcobrador === true) {
                let datos={ datos: {value:this.getField('codigo_cobrador').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-vendedores', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successtraeridcobrador,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
                
            }
        }
    }

    successtraeridcobrador(data){
        if (data.estado_p === 200 )
        {
            this.getField('cobrador_id').setValue(data.data[0].vendedores_id)
            this.idcobrador=false;
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    fechaVence(){

        let tipo = [
            {
                valor:'.',
                tipo:'.'
            },
            {
                valor:'FE',
                tipo:'Fecha Emisión'
            },
            {
                valor:'FR',
                tipo:'Fecha Recepción'
            }
        ]
        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             tipo.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.valor;
             dataOp['text'] = item.tipo;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('proveedor_tipo_dias_vencimiento').setOptions(opciones);
    }

    tarifa(){
        if(this.getField('reteica').getValue() === 'S'){
            this.getField('tarifa_reteica').setDisabled(false);
        }else if(this.getField('reteica').getValue() === 'N' || this.getField('reteica').getValue() === ''){
            this.getField('tarifa_reteica').setValue('0');
            this.getField('tarifa_reteica').setDisabled(true);
        }
    }

    actualizarManejoCliente(){
            if(this.getField('codigo_vendedor').valid() && this.getField('nombre_vendedor').valid() && this.getField('codigo_vendedor1').valid()
            && this.getField('nombre_vendedor1').valid() && this.getField('codigo_ruta').valid() && this.getField('nombre_ruta').valid()
            && this.getField('codigo_negocio').valid() && this.getField('nombre_negocio_clientes').valid() && this.getField('codigo_cobrador').valid()
            && this.getField('nombre_cobrador').valid() && this.getField('contactoc').getValue() && this.getField('nivel_autorizacion').valid()
            && this.getField('dias_vence').valid() && this.getField('dias').valid() && this.getField('cupo').valid() && this.getField('dias_visita_mes').valid()
            && this.getField('mercaderista').valid() && this.getField('mercaderista1').valid() && this.getField('anexo').valid()
            && this.getField('descuento_lista').valid() && this.getField('precios').valid() && this.getField('precios1').valid()
            && this.getField('tarifa_cree_ventas').valid() && this.getField('tipo').valid() && this.getField('contablec').valid()
            && this.getField('nombre_cuenta').valid() && this.getField('maneja_parciales_mercancia').valid() && this.getField('maneja_tasa_cambio').valid()
            && this.getField('cliente_exterior').valid() && this.getField('cobrador_id').valid() && this.getField('vendedor_id').valid() 
            && this.getField('negocio_id').valid() && this.getField('vendedor1_id').valid()){
                
            
            let datos={ datos: { codigo_vendedor: this.getField('codigo_vendedor').getValue(),
            codigo_vendedor1: this.getField('codigo_vendedor1').getValue(), tipo: this.getField('tipo').getValue(),
            precios1: this.getField('precios1').getValue(), mercaderista1: this.getField('mercaderista1').getValue(),
            codigo_ruta: this.getField('codigo_ruta').getValue(), cupo: this.getField('cupo').getValue(),
            dias_visita_mes: this.getField('dias_visita_mes').getValue(), maneja_tasa_cambio: this.getField('maneja_tasa_cambio').getValue(),
            precios: this.getField('precios').getValue(), vendedor1_id: this.getField('vendedor1_id').getValue(),
            nivel_autorizacion: this.getField('nivel_autorizacion').getValue(), codigo_cobrador: this.getField('codigo_cobrador').getValue(),
            maneja_parciales_mercancia: this.getField('maneja_parciales_mercancia').getValue(),
            dias: this.getField('dias').getValue(), anexo: this.getField('anexo').getValue(), dias_vence: this.getField('dias_vence').getValue(),
            contactoc: this.getField('contactoc').getValue(), contablec: this.getField('contablec').getValue(),
            descuento_lista: this.getField('descuento_lista').getValue(), codigo_negocio: this.getField('codigo_negocio').getValue(),
            mercaderista: this.getField('mercaderista').getValue(), tarifa_cree_ventas: this.getField('tarifa_cree_ventas').getValue(),
            cliente_exterior: this.getField('cliente_exterior').getValue(),cobrador_id: this.getField('cobrador_id').getValue(),
            vendedor_id: this.getField('vendedor_id').getValue(), negocio_id: this.getField('negocio_id').getValue(),
            nit_buscar: this.nit,sede3: this.sede}};
            this.generalFormatPmv = { tipo_servicio: 'maes-datosterceros', operacion: 'modificarmanejoclientes', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successactualizarCliente,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successactualizarCliente(data){
        if (data.estado_p === 200 )
        { 
            this.getField('codigo_vendedor').input.focus();
            this.alertGeneral.toggle(true, 'El tercero '+this.nombre+' fue modificado con éxito.', 'success');
            this.limpiar1();

        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }


    limpiar1(){

            this.idvendedor  = true;
            this.idvendedor1 = true;
            this.idcobrador  = true;
            this.idnegocio   = true;
            this.establecerPropiedades('97,98,100,101,103','visible','true')  
            this.establecerPropiedades('98,100,104','value,error','false')
            this.getField('codigo_vendedor').valid(false); 
            this.getField('nombre_vendedor').valid(false); 
            this.getField('codigo_vendedor1').valid(false);
            this.getField('nombre_vendedor1').valid(false); 
            this.getField('codigo_ruta').valid(false); 
            this.getField('nombre_ruta').valid(false);
            this.getField('codigo_negocio').valid(false);
            this.getField('nombre_negocio_clientes').valid(false); 
            this.getField('codigo_cobrador').valid(false);
            this.getField('nombre_cobrador').valid(false); 
            this.getField('contactoc').valid(false); 
            this.getField('nivel_autorizacion').valid(false);
            this.getField('dias_vence').valid(false); 
            this.getField('dias').valid(false); 
            this.getField('cupo').valid(false); 
            this.getField('dias_visita_mes').valid(false);
            this.getField('mercaderista').valid(false); 
            this.getField('mercaderista1').valid(false); 
            this.getField('anexo').valid(false);
            this.getField('descuento_lista').valid(false); 
            this.getField('precios').valid(false); 
            this.getField('precios1').valid(false);
            this.getField('tarifa_cree_ventas').valid(false); 
            this.getField('tipo').valid(false); 
            this.getField('contablec').valid(false);
            this.getField('nombre_cuenta').valid(false); 
            this.getField('maneja_parciales_mercancia').valid(false); 
            this.getField('maneja_tasa_cambio').valid(false);
            this.getField('cliente_exterior').valid(false); 
            this.getField('cobrador_id').valid(false); 
            this.getField('vendedor_id').valid(false); 
            this.getField('negocio_id').valid(false); 
            this.getField('vendedor1_id').valid(false);

            
            this.getField('nit_buscar').setValue(''); 
            this.getField('sede3').setValue(''); 
            this.getField('nombre3').setValue('');
            this.getField('codigo_vendedor').setValue(''); 
            this.getField('nombre_vendedor').setValue(''); 
            this.getField('codigo_vendedor1').setValue('');
            this.getField('nombre_vendedor1').setValue(''); 
            this.getField('codigo_ruta').setValue(''); 
            this.getField('nombre_ruta').setValue('');
            this.getField('codigo_negocio').setValue('');
            this.getField('nombre_negocio_clientes').setValue(''); 
            this.getField('codigo_cobrador').setValue('');
            this.getField('nombre_cobrador').setValue(''); 
            this.getField('contactoc').setValue(''); 
            this.getField('nivel_autorizacion').setValue('');
            this.getField('dias_vence').setValue(''); 
            this.getField('dias').setValue(''); 
            this.getField('cupo').setValue(''); 
            this.getField('dias_visita_mes').setValue('');
            this.getField('mercaderista').setValue(''); 
            this.getField('mercaderista1').setValue(''); 
            this.getField('anexo').setValue('');
            this.getField('descuento_lista').setValue(''); 
            this.getField('precios').setValue(''); 
            this.getField('precios1').setValue('');
            this.getField('tarifa_cree_ventas').setValue(''); 
            this.getField('tipo').setValue(''); 
            this.getField('contablec').setValue('');
            this.getField('nombre_cuenta').setValue(''); 
            this.getField('maneja_parciales_mercancia').setValue(''); 
            this.getField('maneja_tasa_cambio').setValue('');
            this.getField('cliente_exterior').setValue(''); 
            this.getField('cobrador_id').setValue(''); 
            this.getField('vendedor_id').setValue(''); 
            this.getField('negocio_id').setValue(''); 
            this.getField('vendedor1_id').setValue('');

            //campos de la pestaña proveedor
            this.getField('tope').setValue('');
            this.getField('retefuente').setValue('');
            this.getField('reteiva').setValue('');
            this.getField('reteica').setValue('');
            this.getField('tarifa_reteica').setValue('');
            this.getField('contactop').setValue('');
            this.getField('contablep').setValue('');
            this.getField('dias_vence_proveedor').setValue('');
            this.getField('tarifa_cree_compras').setValue('');
            this.getField('calcula_iva_item').setValue('');
            this.getField('proveedor_exterior').setValue('');
            this.getField('proveedor_tipo_dias_vencimiento').setValue('');
            this.getField('nombre_cuenta1').setValue('');

            this.getField('tope').valid(false);
            this.getField('retefuente').valid(false);
            this.getField('reteiva').valid(false);
            this.getField('reteica').valid(false);
            this.getField('tarifa_reteica').valid(false);
            this.getField('contactop').valid(false);
            this.getField('contablep').valid(false);
            this.getField('dias_vence_proveedor').valid(false);
            this.getField('tarifa_cree_compras').valid(false);
            this.getField('calcula_iva_item').valid(false);
            this.getField('proveedor_exterior').valid(false);
            this.getField('proveedor_tipo_dias_vencimiento').valid(false);
            this.getField('nombre_cuenta1').valid(false);

            //campos de pestaña datos legales
            this.getField('codigo_actividad_economica').setValue('');
            this.getField('nombre_actividad').setValue('');
            this.getField('regimen').setValue('');
            this.getField('responsable_iva').setValue('');
            this.getField('autoretenedor').setValue('');
            this.getField('codigo_grupo_empresarial').setValue('');
            this.getField('nombre_grupo_empresarial').setValue('');
            this.getField('codigo_actividad_interna').setValue('');
            this.getField('nombre_actividad_interna').setValue('');
            this.getField('tipo_persona').setValue('');
            this.getField('base_reteiva').setValue('');
            this.getField('venta_contado').setValue('');

            this.getField('codigo_actividad_economica').valid(false);
            this.getField('nombre_actividad').valid(false);
            this.getField('regimen').valid(false);
            this.getField('responsable_iva').valid(false);
            this.getField('autoretenedor').valid(false);
            this.getField('codigo_grupo_empresarial').valid(false);
            this.getField('nombre_grupo_empresarial').valid(false);
            this.getField('codigo_actividad_interna').valid(false);
            this.getField('nombre_actividad_interna').valid(false);
            this.getField('tipo_persona').valid(false);
            this.getField('base_reteiva').valid(false);
            this.getField('venta_contado').valid(false);

            
            //campos datos adicionales
            this.getField('apartado').setValue('');
            this.getField('codigo_oficio').setValue('');
            this.getField('nombre_oficio').setValue('');
            this.getField('bancos').setValue('');
            this.getField('dia_corte').setValue('');
            this.getField('cuentas').setValue('');
            this.getField('ciudad_radicacion').setValue('');
            this.getField('nombre_ciudad').setValue('');
            this.getField('web').setValue('');
            this.getField('direccion_radicacion').setValue('');
            this.getField('email').setValue('');
            this.getField('observaciones_radicacion').setValue('');
            this.getField('contacto1').setValue('');
            this.getField('contacto2').setValue('');
            this.getField('contacto3').setValue('');
            this.getField('contacto4').setValue('');
            this.getField('cifin').setValue('');
            this.getField('remisionar').setValue('');
            this.getField('factor_pagare').setValue('');
            this.getField('estado_credito').setValue('');

            this.getField('apartado').valid(false);
            this.getField('codigo_oficio').valid(false);
            this.getField('nombre_oficio').valid(false);
            this.getField('bancos').valid(false);
            this.getField('dia_corte').valid(false);
            this.getField('cuentas').valid(false);
            this.getField('ciudad_radicacion').valid(false);
            this.getField('nombre_ciudad').valid(false);
            this.getField('web').valid(false);
            this.getField('direccion_radicacion').valid(false);
            this.getField('email').valid(false);
            this.getField('observaciones_radicacion').valid(false);
            this.getField('contacto1').valid(false);
            this.getField('contacto2').valid(false);
            this.getField('contacto3').valid(false);
            this.getField('contacto4').valid(false);
            this.getField('cifin').valid(false);
            this.getField('remisionar').valid(false);
            this.getField('factor_pagare').valid(false);
            this.getField('estado_credito').valid(false);

            this.deshabilitar();
    }

    deshabilitar(){
        this.getField('codigo_vendedor').setDisabled(true); 
        this.getField('nombre_vendedor').setDisabled(true); 
        this.getField('codigo_vendedor1').setDisabled(true);
        this.getField('nombre_vendedor1').setDisabled(true); 
        this.getField('codigo_ruta').setDisabled(true); 
        this.getField('nombre_ruta').setDisabled(true);
        this.getField('codigo_negocio').setDisabled(true);
        this.getField('nombre_negocio_clientes').setDisabled(true);
        this.getField('codigo_cobrador').setDisabled(true);
        this.getField('nombre_cobrador').setDisabled(true); 
        this.getField('contactoc').setDisabled(true); 
        this.getField('nivel_autorizacion').setDisabled(true);
        this.getField('dias_vence').setDisabled(true); 
        this.getField('dias').setDisabled(true); 
        this.getField('cupo').setDisabled(true); 
        this.getField('dias_visita_mes').setDisabled(true);
        this.getField('mercaderista').setDisabled(true); 
        this.getField('mercaderista1').setDisabled(true); 
        this.getField('anexo').setDisabled(true);
        this.getField('descuento_lista').setDisabled(true); 
        this.getField('precios').setDisabled(true); 
        this.getField('precios1').setDisabled(true);
        this.getField('tarifa_cree_ventas').setDisabled(true); 
        this.getField('tipo').setDisabled(true); 
        this.getField('contablec').setDisabled(true);
        this.getField('nombre_cuenta').setDisabled(true); 
        this.getField('maneja_parciales_mercancia').setDisabled(true); 
        this.getField('maneja_tasa_cambio').setDisabled(true);
        this.getField('cliente_exterior').setDisabled(true); 
        this.getField('cobrador_id').setDisabled(true); 
        this.getField('vendedor_id').setDisabled(true); 
        this.getField('negocio_id').setDisabled(true); 
        this.getField('vendedor1_id').setDisabled(true);
        this.getField('aceptar').setDisabled(true);

        //campos de la pestaña proveedor
        this.getField('tope').setDisabled(true);
        this.getField('retefuente').setDisabled(true);
        this.getField('reteiva').setDisabled(true);
        this.getField('reteica').setDisabled(true);
        this.getField('tarifa_reteica').setDisabled(true);
        this.getField('contactop').setDisabled(true);
        this.getField('contablep').setDisabled(true);
        this.getField('dias_vence_proveedor').setDisabled(true);
        this.getField('tarifa_cree_compras').setDisabled(true);
        this.getField('calcula_iva_item').setDisabled(true);
        this.getField('proveedor_exterior').setDisabled(true);
        this.getField('proveedor_tipo_dias_vencimiento').setDisabled(true);
        this.getField('nombre_cuenta1').setDisabled(true);
        this.getField('actualizar').setDisabled(true);

        //campos de la pestaña datos legales
        this.getField('codigo_actividad_economica').setDisabled(true);
        this.getField('nombre_actividad').setDisabled(true);
        this.getField('regimen').setDisabled(true);
        this.getField('responsable_iva').setDisabled(true);
        this.getField('autoretenedor').setDisabled(true);
        this.getField('codigo_grupo_empresarial').setDisabled(true);
        this.getField('nombre_grupo_empresarial').setDisabled(true);
        this.getField('codigo_actividad_interna').setDisabled(true);
        this.getField('nombre_actividad_interna').setDisabled(true);
        this.getField('tipo_persona').setDisabled(true);
        this.getField('base_reteiva').setDisabled(true);
        this.getField('venta_contado').setDisabled(true);
        this.getField('actualizar1').setDisabled(true);

        //campos datos adicionales
        this.getField('apartado').setDisabled(true);
        this.getField('codigo_oficio').setDisabled(true);
        this.getField('nombre_oficio').setDisabled(true);
        this.getField('bancos').setDisabled(true);
        this.getField('dia_corte').setDisabled(true);
        this.getField('cuentas').setDisabled(true);
        this.getField('ciudad_radicacion').setDisabled(true);
        this.getField('nombre_ciudad').setDisabled(true);
        this.getField('web').setDisabled(true);
        this.getField('direccion_radicacion').setDisabled(true);
        this.getField('email').setDisabled(true);
        this.getField('observaciones_radicacion').setDisabled(true);
        this.getField('contacto1').setDisabled(true);
        this.getField('contacto2').setDisabled(true);
        this.getField('contacto3').setDisabled(true);
        this.getField('contacto4').setDisabled(true);
        this.getField('cifin').setDisabled(true);
        this.getField('remisionar').setDisabled(true);
        this.getField('factor_pagare').setDisabled(true);
        this.getField('estado_credito').setDisabled(true);
        this.getField('actualizar2').setDisabled(true);

    }

    habilitar(){
        this.getField('codigo_vendedor').setDisabled(false) 
        this.getField('nombre_vendedor').setDisabled(false) 
        this.getField('codigo_vendedor1').setDisabled(false)
        this.getField('nombre_vendedor1').setDisabled(false) 
        this.getField('codigo_ruta').setDisabled(false) 
        this.getField('nombre_ruta').setDisabled(false)
        this.getField('codigo_negocio').setDisabled(false)
        this.getField('nombre_negocio_clientes').setDisabled(false) 
        this.getField('codigo_cobrador').setDisabled(false)
        this.getField('nombre_cobrador').setDisabled(false) 
        this.getField('contactoc').setDisabled(false) 
        this.getField('nivel_autorizacion').setDisabled(false)
        this.getField('dias_vence').setDisabled(false) 
        this.getField('dias').setDisabled(false) 
        this.getField('cupo').setDisabled(false) 
        this.getField('dias_visita_mes').setDisabled(false)
        this.getField('mercaderista').setDisabled(false) 
        this.getField('mercaderista1').setDisabled(false) 
        this.getField('anexo').setDisabled(false)
        this.getField('descuento_lista').setDisabled(false) 
        this.getField('precios').setDisabled(false) 
        this.getField('precios1').setDisabled(false)
        this.getField('tarifa_cree_ventas').setDisabled(false) 
        this.getField('tipo').setDisabled(false) 
        this.getField('contablec').setDisabled(false)
        this.getField('nombre_cuenta').setDisabled(false) 
        this.getField('maneja_parciales_mercancia').setDisabled(false) 
        this.getField('maneja_tasa_cambio').setDisabled(false)
        this.getField('cliente_exterior').setDisabled(false) 
        this.getField('cobrador_id').setDisabled(false) 
        this.getField('vendedor_id').setDisabled(false) 
        this.getField('negocio_id').setDisabled(false) 
        this.getField('vendedor1_id').setDisabled(false)
        this.getField('aceptar').setDisabled(false)

        //campos de la pestaña proveedor
        this.getField('tope').setDisabled(false);
        this.getField('retefuente').setDisabled(false);
        this.getField('reteiva').setDisabled(false);
        this.getField('reteica').setDisabled(false);
        this.getField('contactop').setDisabled(false);
        this.getField('contablep').setDisabled(false);
        this.getField('dias_vence_proveedor').setDisabled(false);
        this.getField('tarifa_cree_compras').setDisabled(false);
        this.getField('calcula_iva_item').setDisabled(false);
        this.getField('proveedor_exterior').setDisabled(false);
        this.getField('proveedor_tipo_dias_vencimiento').setDisabled(false);
        this.getField('nombre_cuenta1').setDisabled(false);
        this.getField('actualizar').setDisabled(false);

        //campos de la pestaña datos legales
        this.getField('codigo_actividad_economica').setDisabled(false);
        this.getField('nombre_actividad').setDisabled(false);
        this.getField('regimen').setDisabled(false);
        this.getField('responsable_iva').setDisabled(false);
        this.getField('autoretenedor').setDisabled(false);
        this.getField('codigo_grupo_empresarial').setDisabled(false);
        this.getField('nombre_grupo_empresarial').setDisabled(false);
        this.getField('codigo_actividad_interna').setDisabled(false);
        this.getField('nombre_actividad_interna').setDisabled(false);
        this.getField('tipo_persona').setDisabled(false);
        this.getField('base_reteiva').setDisabled(false);
        this.getField('venta_contado').setDisabled(false);
        this.getField('actualizar1').setDisabled(false);

        //campos datos adicionales
        this.getField('apartado').setDisabled(false);
        this.getField('codigo_oficio').setDisabled(false);
        this.getField('nombre_oficio').setDisabled(false);
        this.getField('bancos').setDisabled(false);
        this.getField('dia_corte').setDisabled(false);
        this.getField('cuentas').setDisabled(false);
        this.getField('ciudad_radicacion').setDisabled(false);
        this.getField('nombre_ciudad').setDisabled(false);
        this.getField('web').setDisabled(false);
        this.getField('direccion_radicacion').setDisabled(false);
        this.getField('email').setDisabled(false);
        this.getField('observaciones_radicacion').setDisabled(false);
        this.getField('contacto1').setDisabled(false);
        this.getField('contacto2').setDisabled(false);
        this.getField('contacto3').setDisabled(false);
        this.getField('contacto4').setDisabled(false);
        this.getField('cifin').setDisabled(false);
        this.getField('remisionar').setDisabled(false);
        this.getField('factor_pagare').setDisabled(false);
        this.getField('estado_credito').setDisabled(false);
        this.getField('actualizar2').setDisabled(false);

    }

    actualizarProveedor(){
            if(this.getField('tope').valid() && this.getField('retefuente').valid() && this.getField('reteiva').valid() &&
            this.getField('reteica').valid() && this.getField('tarifa_reteica').valid() && this.getField('contactop').valid() &&
            this.getField('contablep').valid() && this.getField('dias_vence_proveedor').valid() && this.getField('tarifa_cree_compras').valid() &&
            this.getField('calcula_iva_item').valid() && this.getField('proveedor_exterior').valid() && this.getField('proveedor_tipo_dias_vencimiento').valid() &&
            this.getField('nombre_cuenta1').valid() ){
            
            let datos={ datos: { tope: this.getField('tope').getValue(),retefuente: this.getField('retefuente').getValue(), 
            reteiva: this.getField('reteiva').getValue(),reteica: this.getField('reteica').getValue(), 
            tarifa_reteica: String(this.getField('tarifa_reteica').getValue()),contactop: this.getField('contactop').getValue(), 
            contablep: this.getField('contablep').getValue(),dias_vence_proveedor: this.getField('dias_vence_proveedor').getValue(), 
            tarifa_cree_compras: this.getField('tarifa_cree_compras').getValue(), calcula_iva_item: this.getField('calcula_iva_item').getValue(), 
            proveedor_exterior: this.getField('proveedor_exterior').getValue(),proveedor_tipo_dias_vencimiento: this.getField('proveedor_tipo_dias_vencimiento').getValue(),
            nit_buscar: this.nit,sede3: this.sede}};
            this.generalFormatPmv = { tipo_servicio: 'maes-datosterceros', operacion: 'modificarproveedores', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successactualizarProveedor,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successactualizarProveedor(data){
        if (data.estado_p === 200 )
        { 
            this.getField('tope').input.focus();
            this.alertGeneral.toggle(true, 'El tercero '+this.nombre+' fue modificado con éxito.', 'success');
            this.limpiar1();
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    datoslegales(){
            if(this.getField('codigo_actividad_economica').valid() && this.getField('nombre_actividad').valid() && this.getField('regimen').valid() &&
            this.getField('responsable_iva').valid() && this.getField('autoretenedor').valid() && this.getField('codigo_grupo_empresarial').valid() &&
            this.getField('nombre_grupo_empresarial').valid() && this.getField('codigo_actividad_interna').valid() && this.getField('nombre_actividad_interna').valid() &&
            this.getField('tipo_persona').valid() && this.getField('base_reteiva').valid() && this.getField('base_reteiva').valid() && this.getField('venta_contado').valid()){
            
            let datos={ datos: { codigo_actividad_economica: this.getField('codigo_actividad_economica').getValue(),regimen:this.getField('regimen').getValue(), 
            responsable_iva: this.getField('responsable_iva').getValue(),autoretenedor: this.getField('autoretenedor').getValue(), 
            codigo_grupo_empresarial: String(this.getField('codigo_grupo_empresarial').getValue()),codigo_actividad_interna: this.getField('codigo_actividad_interna').getValue(), 
            tipo_persona: this.getField('tipo_persona').getValue(),base_reteiva: String(this.getField('base_reteiva').getValue()),
            venta_contado: this.getField('venta_contado').getValue(),nit_buscar: this.nit,sede3: this.sede}};
            this.generalFormatPmv = { tipo_servicio: 'maes-datosterceros', operacion: 'modificardatoslegales', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successdatoslegales,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successdatoslegales(data){
        if (data.estado_p === 200 )
        { 
            this.getField('codigo_actividad_economica').input.focus();
            this.alertGeneral.toggle(true, 'El tercero '+this.nombre+' fue modificado con éxito.', 'success');
            this.limpiar1();

        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    datosadicionales1(){
            if(this.getField('apartado').valid() && this.getField('codigo_oficio').valid() && this.getField('nombre_oficio').valid() &&
            this.getField('bancos').valid() && this.getField('dia_corte').valid() && this.getField('cuentas').valid() && this.getField('ciudad_radicacion').valid() &&
            this.getField('nombre_ciudad').valid() && this.getField('web').valid() && this.getField('direccion_radicacion').valid() &&
            this.getField('email').valid() && this.getField('observaciones_radicacion').valid() && this.getField('contacto1').valid() && this.getField('contacto2').valid() &&
            this.getField('contacto3').valid() && this.getField('contacto4').valid() && this.getField('cifin').valid() && this.getField('remisionar').valid()
            && this.getField('factor_pagare').valid() && this.getField('estado_credito').valid()){
            
            let datos={ datos: { apartado: this.getField('apartado').getValue(),codigo_oficio:this.getField('codigo_oficio').getValue(), 
            bancos: this.getField('bancos').getValue(),dia_corte: this.getField('dia_corte').getValue(), email: this.getField('email').getValue(),
            cuentas: this.getField('cuentas').getValue(),ciudad_radicacion: this.getField('ciudad_radicacion').getValue(), contacto2: this.getField('contacto2').getValue(),
            web: this.getField('web').getValue(),direccion_radicacion: this.getField('direccion_radicacion').getValue(), contacto3: this.getField('contacto3').getValue(),
            observaciones_radicacion: this.getField('observaciones_radicacion').getValue(),contacto1: this.getField('contacto1').getValue(),
            contacto4: this.getField('contacto4').getValue(), cifin: this.getField('cifin').getValue(), remisionar: this.getField('remisionar').getValue(),
            factor_pagare: this.getField('factor_pagare').getValue(),estado_credito: this.getField('estado_credito').getValue(),nit_buscar: this.nit,sede3: this.sede}};
            this.generalFormatPmv = { tipo_servicio: 'maes-datosterceros', operacion: 'modificardatosadicionales', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successdatosadicionales,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successdatosadicionales(data){
        if (data.estado_p === 200 )
        { 
            this.getField('apartado').input.focus();
            this.alertGeneral.toggle(true, 'El tercero '+this.nombre+' fue modificado con éxito.', 'success');
            this.limpiar1();

        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }



    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
        });
    }
}
FormJaivana.addController('maes-datosterceros', CustomModificarDatosTercero);
export default CustomModificarDatosTercero;