import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomListadoTraslados
 * @author: Santiago Hernández N
 * @version: jdesk_1.01.0002
 **/
class CustomListadoTraslados extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                                       = this.initForm.bind(this);
        this.imprimirModal                                                  = this.imprimirModal.bind(this);
        this.validarBoton                                                   = this.validarBoton.bind(this);
        this.traerDias                                                      = this.traerDias.bind(this);
        this.successTraerDias                                               = this.successTraerDias.bind(this);
        this.validarfechasConsulta                                          = this.validarfechasConsulta.bind(this);
        this.habilitarCorreo                                                = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                                             = this.deshabilitarCorreo.bind(this);
        this.procesar                                                       = this.procesar.bind(this);
        this.enviarCorreo                                                   = this.enviarCorreo.bind(this);
        this.successEnviarCorreo                                            = this.successEnviarCorreo.bind(this);
        this.mostrarMensajeGenerando                                        = this.mostrarMensajeGenerando.bind(this);
        this.mostrarMensajeNoHayDatos                                       = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeEmailEnviado                                     = this.mostrarMensajeEmailEnviado.bind(this);
        this.botonSeleccionado                                              = '';
        this.generarPdfTabla                                                = this.generarPdfTabla.bind(this);
        this.generarExcelTabla                                              = this.generarExcelTabla.bind(this);
        this.generarModalLista                                              = this.generarModalLista.bind(this);
        this.sucessTraerDatos                                               = this.sucessTraerDatos.bind(this);   
        this.gridOptionsFacturacTraslados                                   = Object.assign({},this.gridOptions);
        this.currencyFormatterGeneral                                       = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                                              = this.formatNumberSaldo.bind(this);
        this.sucessTraerDatosLista                                          = this.sucessTraerDatosLista.bind(this);
        this.sucessTraerDatosListaDetallado                                 = this.sucessTraerDatosListaDetallado.bind(this);
        this.gridOptionsFacturacTrasladosDetallado                          = Object.assign({},this.gridOptions);
        this.sucessTraerDatosDetallado                                      = this.sucessTraerDatosDetallado.bind(this);
    }

    initForm() {
        console.log("Formulario CustomListadoTraslados,  @version: jdesk_1.01.0002, @author: Santiago Hernández N. @update by: Patricia Lopez");

        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.getField("consultar_desde").setValue(this.fecha_inicio);

        this.getField('ch_todos').setOnChange((props)=>{
            if(this.getField('ch_todos').getValue()==='false'){
                this.getField('ch_pendientes').setValueSwitch (false);
                this.getField('ch_aceptados').setValueSwitch (false);
                this.getField('ch_aceptados_inconclusos').setValueSwitch (false);
                this.operacion_actual='todos';               
            }else{
                this.operacion_actual='';
            }
        });

        this.getField('ch_pendientes').setOnChange((props)=>{
            if(this.getField('ch_pendientes').getValue()==='false'){
                this.getField('ch_todos').setValueSwitch (false);
                this.getField('ch_aceptados').setValueSwitch (false);
                this.getField('ch_aceptados_inconclusos').setValueSwitch (false);
                this.operacion_actual='pendientes';                
            }else{
                this.operacion_actual='';
            }
        });

        this.getField('ch_aceptados').setOnChange((props)=>{
            if(this.getField('ch_aceptados').getValue()==='false'){
                this.getField('ch_pendientes').setValueSwitch (false);
                this.getField('ch_todos').setValueSwitch (false);
                this.getField('ch_aceptados_inconclusos').setValueSwitch (false);
                this.operacion_actual='aceptados';
            }else{
                this.operacion_actual='';
            }
        });

        this.getField('ch_aceptados_inconclusos').setOnChange((props)=>{
            if(this.getField('ch_aceptados_inconclusos').getValue()==='false'){
                this.getField('ch_pendientes').setValueSwitch (false);
                this.getField('ch_todos').setValueSwitch (false);
                this.getField('ch_aceptados').setValueSwitch (false);
                this.operacion_actual='aceptados_inconclusos';
            }else{
                this.operacion_actual='';
            }
        });

        this.getField('generar').setClick(()=>this.validarBoton('generar'));
        this.getField('detallado').setClick(()=>this.validarBoton('detallado'));

        this.traerDias();
        this.getField("consultar_desde").setOnChange(this.validarfechasConsulta);
        this.getField("consultar_hasta").setOnChange(this.validarfechasConsulta);



        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual_imprimir='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual_imprimir='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual_imprimir='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });

        this.getField('imprimir').setClick(()=>this.procesar());

        this.getField('modal_consultar').setCloseButton(()=>{
            if(this.getField('nombre_sucursal').getValue()===''){
                this.getField('codigo_sucursal').setValue("")
                this.getField('codigo_sucursal').setError(false,"")
            }
        });
        this.getField('modal_consultar_detallado').setCloseButton(()=>{
            if(this.getField('nombre_sucursal').getValue()===''){
                this.getField('codigo_sucursal').setValue("")
                this.getField('codigo_sucursal').setError(false,"")
            }
        });

        this.getField('modal_imprimir').setCloseButton(()=>{
            if(this.getField('nombre_sucursal').getValue()===''){
                this.getField('codigo_sucursal').setValue("")
                this.getField('codigo_sucursal').setError(false,"")
            }
        });

    }


    validarBoton(boton){
        this.botonSeleccionado = boton;
        this.imprimirModal();
    }

    imprimirModal(){
        if (this.operacion_actual !== '' && this.operacion_actual !== undefined){
            this.getField("modal_imprimir").handleClickOpen();
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar una opción.', 'error');
        }           
    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-listadotraslados', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.listado_de_traslados;
            //this.dias_maximo_lista=data.data.lista_ajustes_de_inventario;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    validarfechasConsulta(){
        this.getField('generar').setDisabled(false);
        this.getField('detallado').setDisabled(false);
        //let fecha_actual =new Date().toISOString().split('T')[0];
        let consultar_desde = new Date(this.getField("consultar_desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("consultar_hasta").getValue()).toISOString().split('T')[0];

        this.getField("consultar_desde").setError(false,"");
        this.getField("consultar_hasta").setError(false,"");

        if(consultar_desde > consultar_hasta){
            this.getField('generar').setDisabled(true);
            this.getField('detallado').setDisabled(true);
            this.getField("consultar_hasta").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }

        let fecha=new Date();
        fecha.setDate(fecha.getDate()-this.dias_maximo);
        fecha=fecha.toISOString().split('T')[0];
        if(consultar_desde < fecha){
            this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('generar').setDisabled(true);
            this.getField('detallado').setDisabled(true);
            return false;
        }
        return true;
    }

    
    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual_imprimir!=='pantalla'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual_imprimir='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual_imprimir!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreo();
                }else{
                    if(this.operacion_actual_imprimir==='pdf'){
                        this.generarPdfTabla();
                    }else if(this.operacion_actual_imprimir==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual_imprimir==='excel'){
                        this.generarExcelTabla();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    enviarCorreo(){
        if (this.botonSeleccionado==='generar'){
            this.mostrarMensajeGenerando();
            let op='';
            let datos={ datos: { } };
    
            datos.datos={};
            op='general';
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion='general';
            datos.datos.operacion_imprimir=this.operacion_actual_imprimir;
            datos.datos.estado=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
            datos.datos.consultar_desde=this.getField('consultar_desde').getValue();
            datos.datos.consultar_hasta=this.getField('consultar_hasta').getValue();
            

            if (this.getField('nombre_sucursal').getValue()!==''){
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue()
            }
    
            this.generalFormatPmv = { tipo_servicio: 'inve-listadotraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successEnviarCorreo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        } else if (this.botonSeleccionado==='detallado'){
            
            this.mostrarMensajeGenerando();
            let op='';
            let datos={ datos: { } };
    
            datos.datos={};
            op='detallado';
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion='detallado';
            datos.datos.operacion_imprimir=this.operacion_actual_imprimir;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
            datos.datos.consultar_desde=this.getField('consultar_desde').getValue();
            datos.datos.consultar_hasta=this.getField('consultar_hasta').getValue();
            datos.datos.estado=this.operacion_actual;

            if (this.getField('nombre_sucursal').getValue()!==''){
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue()
            }
    
            this.generalFormatPmv = { tipo_servicio: 'inve-listadotraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successEnviarCorreo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
        }
    }


    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla(){
        if (this.botonSeleccionado==='generar'){
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};


            op='general';
            datos2.sucursal_ingreso=this.sucursal_ingreso;
            datos2.operacion='general';
            datos2.operacion_imprimir=this.operacion_actual_imprimir;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
            datos2.consultar_desde=this.getField('consultar_desde').getValue();
            datos2.consultar_hasta=this.getField('consultar_hasta').getValue();
            datos2.estado=this.operacion_actual;

            
            if (this.getField('nombre_sucursal').getValue()!==''){
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                datos2.nombre_sucursal=this.getField('nombre_sucursal').getValue();
            }
   
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-listadotraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], 'ListadoTrasladosTotal.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });

        } else if (this.botonSeleccionado==='detallado'){
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};


            op='detallado';
            datos2.sucursal_ingreso=this.sucursal_ingreso;
            datos2.operacion='detallado';
            datos2.operacion_imprimir=this.operacion_actual_imprimir;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
            datos2.consultar_desde=this.getField('consultar_desde').getValue();
            datos2.consultar_hasta=this.getField('consultar_hasta').getValue();
            datos2.estado=this.operacion_actual;

            
            if (this.getField('nombre_sucursal').getValue()!==''){
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                datos2.nombre_sucursal=this.getField('nombre_sucursal').getValue();
            }
   
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-listadotraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], 'ListadoTrasladosDetallado.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
        }
    }

    generarExcelTabla(){

        if (this.botonSeleccionado==='generar'){
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};


            op='general';
            datos2.sucursal_ingreso=this.sucursal_ingreso;
            datos2.operacion='general';
            datos2.operacion_imprimir=this.operacion_actual_imprimir;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
            datos2.consultar_desde=this.getField('consultar_desde').getValue();
            datos2.consultar_hasta=this.getField('consultar_hasta').getValue();
            datos2.estado=this.operacion_actual;

            
            if (this.getField('nombre_sucursal').getValue()!==''){
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                datos2.nombre_sucursal=this.getField('nombre_sucursal').getValue();
            }



            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-listadotraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = 'ListadoTrasladosTotal.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo exitosamente!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
        }else if (this.botonSeleccionado==='detallado'){


            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};


            op='detallado';
            datos2.sucursal_ingreso=this.sucursal_ingreso;
            datos2.operacion='detallado';
            datos2.operacion_imprimir=this.operacion_actual_imprimir;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
            datos2.consultar_desde=this.getField('consultar_desde').getValue();
            datos2.consultar_hasta=this.getField('consultar_hasta').getValue();
            datos2.estado=this.operacion_actual;

            
            if (this.getField('nombre_sucursal').getValue()!==''){
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                datos2.nombre_sucursal=this.getField('nombre_sucursal').getValue();
            }



            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-listadotraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = 'ListadoTrasladosDetallado.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo exitosamente!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });

        }
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarModalLista(){
        if (this.botonSeleccionado==='generar'){
            this.mostrarMensajeGenerando();
            let op='';
            let datos={ datos: {}};

            op='general';
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion='general';
            datos.datos.operacion_imprimir=this.operacion_actual_imprimir;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
            datos.datos.consultar_desde=this.getField('consultar_desde').getValue();
            datos.datos.consultar_hasta=this.getField('consultar_hasta').getValue();
            datos.datos.estado=this.operacion_actual;

                        
            if (this.getField('nombre_sucursal').getValue()!==''){
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue()
            }


            this.generalFormatPmv = { tipo_servicio: 'inve-listadotraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatosLista,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else if (this.botonSeleccionado==='detallado'){
            this.mostrarMensajeGenerando();
            let op='';
            let datos={ datos: {}};

            op='detallado';
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion='detallado';
            datos.datos.operacion_imprimir=this.operacion_actual_imprimir;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
            datos.datos.consultar_desde=this.getField('consultar_desde').getValue();
            datos.datos.consultar_hasta=this.getField('consultar_hasta').getValue();
            datos.datos.estado=this.operacion_actual;

                        
            if (this.getField('nombre_sucursal').getValue()!==''){
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue()
            }


            this.generalFormatPmv = { tipo_servicio: 'inve-listadotraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatosListaDetallado,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

        }
    }

    sucessTraerDatosListaDetallado(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 

            let datosFicha = [];
            this.lista=data.data.datos_lista;

            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit":this.lista.nit,
                    "sucursal": this.getField("codigo_sucursal").getValue() === ""? "TODAS" : this.getField("codigo_sucursal").getValue() + " - "+ this.getField('nombre_sucursal').getValue(),
                    "fecha": this.getField("consultar_desde").getValue() +" - " +this.getField("consultar_hasta").getValue(),                   
                    "opcion": this.operacion_actual.toLowerCase(),                   
                };
            datosFicha.push(data2);
            this.getField('lista_consultar_detallado').setItemsFichas(datosFicha);

            this.mostrarMensajeGenerando();
            let datos={ datos: {}};
    
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.operacion_imprimir=this.operacion_actual_imprimir;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
            datos.datos.consultar_desde=this.getField('consultar_desde').getValue();
            datos.datos.consultar_hasta=this.getField('consultar_hasta').getValue();

            if (this.getField('nombre_sucursal').getValue()!==''){
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue()
            }


            this.getField("tabla_consultar_detallado").setTipoServicioOperacion('inve-listadotraslados-consultardetallado');
            this.getField("tabla_consultar_detallado").sendRequest(this.sucessTraerDatosDetallado, datos);

        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    sucessTraerDatosDetallado(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.getField("modal_consultar_detallado").handleClickOpen();
            this.getField("tabla_consultar_detallado").toggle(true);
            this.gridOptionsFacturacTrasladosDetallado["rowData"] = data.data;
            let configCell = new Map();

            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});            

           this.getField('tabla_consultar_detallado').initData(this.gridOptionsFacturacTrasladosDetallado,configCell);
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    sucessTraerDatosLista(data){

        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 

            let datosFicha = [];
            this.lista=data.data.datos_lista;

            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit":this.lista.nit,
                    "sucursal": this.getField("codigo_sucursal").getValue() === "" ? "TODAS" : this.getField("codigo_sucursal").getValue() + " - "+ this.getField('nombre_sucursal').getValue(),
                    "fecha": this.getField("consultar_desde").getValue() +" - " +this.getField("consultar_hasta").getValue(),                   
                    "opcion": this.operacion_actual.toUpperCase(),         
                };
            datosFicha.push(data2);
            this.getField('lista_consultar').setItemsFichas(datosFicha);
            this.getField("traslados").setValue(this.lista.traslados);
            this.getField("total").setValue(this.currencyFormatterGeneral(this.lista.valor_total));

            this.mostrarMensajeGenerando();
            let datos={ datos: {}};
    
            datos.datos.sucursal_ingreso=this.sucursal_ingreso;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.operacion_imprimir=this.operacion_actual_imprimir;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
            datos.datos.consultar_desde=this.getField('consultar_desde').getValue();
            datos.datos.consultar_hasta=this.getField('consultar_hasta').getValue();

            if (this.getField('nombre_sucursal').getValue()!==''){
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue()
            }


            this.getField("tabla_consultar").setTipoServicioOperacion('inve-listadotraslados-consultartodos');
            this.getField("tabla_consultar").sendRequest(this.sucessTraerDatos, datos);
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 



    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            
            this.getField("modal_consultar").handleClickOpen();
            this.getField("tabla_consultar").toggle(true);
            this.gridOptionsFacturacTraslados["rowData"] = data.data;
            let configCell = new Map();
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});            
            this.getField('tabla_consultar').initData(this.gridOptionsFacturacTraslados, configCell);
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 

    }

    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }
    
    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    
    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
}

FormJaivana.addController("inve-listadotraslados", CustomListadoTraslados);
export default CustomListadoTraslados;  