import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CstomListadoPorCodigo
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 **/
class CstomListadoPorCodigo extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.traerinformacion                                       = this.traerinformacion.bind(this);
        this.success_traerinformacion                               = this.success_traerinformacion.bind(this);
        this.negativos                                              = this.negativos.bind(this);
        this.success_negativos                                      = this.success_negativos.bind(this);
        this.agotados                                               = this.agotados.bind(this);
        this.success_agotados                                       = this.success_agotados.bind(this);
        this.gridOptionsComponentes1                                = Object.assign({}, this.gridOptions);
        this.formatNumberSaldo                                      = this.formatNumberSaldo.bind(this);
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.procesar                                               = this.procesar.bind(this);
        this.success_procesar                                       = this.success_procesar.bind(this);
        this.elegir_precio                                          = this.elegir_precio.bind(this);
        this.limpiar                                                = this.limpiar.bind(this);
        this.confirmSaldosUnificados                                = this.confirmSaldosUnificados.bind(this);
        this.saldos_unificados                                      = "";
        this.operacion1                                             = "";
        this.operacion2                                             = "";
        this.operacion                                              = "";
        this.costo1                                                 = "";
        this.total_registros                                        = "";
        this.ch_solo_saldos                                         = false;
        this.abrirImprimir                                          = this.abrirImprimir.bind(this);
        this.abrirImprimir                                          = this.abrirImprimir.bind(this);
        this.procesararchivo                                        = this.procesararchivo.bind(this);
        this.habilitarCorreo                                        = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                                     = this.deshabilitarCorreo.bind(this);
        this.enviarCorreo                                           = this.enviarCorreo.bind(this);
        this.successEnviarCorreo                                    = this.successEnviarCorreo.bind(this);
        this.mostrarMensajeEmailEnviado                             = this.mostrarMensajeEmailEnviado.bind(this);
        this.generarPdfTabla                                        = this.generarPdfTabla.bind(this);
        this.isJson                                                 = this.isJson.bind(this);
        this.generarExcelTabla                                      = this.generarExcelTabla.bind(this);
    }

    initForm() {
        console.log("Formulario CstomListadoPorCodigo,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerinformacion();
        this.getField('negativos').setClick(this.negativos);
        this.getField('agotados').setClick(this.agotados);
        this.getField('agotados').setClick(this.agotados);
        this.getField("nombre_bodega").setOnChange(this.limpiar);
        this.getField("nombre_grupo").setOnChange(this.limpiar);
        this.getField("nombre_subgrupo").setOnChange(this.limpiar);
        this.getField("nombre_marca").setOnChange(this.limpiar);
        this.getField("imprimir").setDisabled(true);
        this.getField("procesar").setClick(() => {
            if((this.getField("bodega").getValue() !== ""  && this.getField("nombre_bodega").getValue() !== "") || this.getField('ch_bodegas').getValue()==='true'){
                let bodega ="";
                if(this.costo_por_bodega === 'S'){
                    bodega =this.getField("bodega").getValue();
                }
                if(this.costo_promedio_linea === 'S'){
                    if ( bodega !== "") {
                        this.campo_costo = "costo_pro_";
                    } else {
                        this.campo_costo = "costo_pro_1";
                    }
                }
                if(this.costo_unificado === 'S'){
                    this.campo_costo = "costo_unificado";
                }
                this.getField("modal_elegir_precio").handleClickOpen();
            }else{
                this.alertGeneral.toggle(true, 'Error debe proporcionar una bodega o seleccionar Todas las Bodegas', 'error');
                this.getField('nombre_bodega').setError(true,'*Este campo es requerido');
            }
        });

        this.getField("aceptar").setClick(this.elegir_precio);
        this.getField("cancelar").setClick(()=>{ this.getField("modal_elegir_precio").handleClose();});
        this.getField("imprimir").setClick(this.abrirImprimir);
        this.getField('generar_archivo').setClick(this.procesararchivo);

        this.getField('ch_bodegas').setOnChange((props)=>{
            if(this.getField('ch_bodegas').getValue()==='false'){
                this.operacion2='bodegas_todas';
                this.getField("bodega").setDisabled(true);
                this.getField("ch_solo_saldos").setDisabled(true);
                this.getField("bodega").setValue("");
                this.getField("nombre_bodega").setValue("");
            }else{
                this.operacion2='';
                this.getField("ch_solo_saldos").setDisabled(false);
                this.getField("bodega").setDisabled(false);
            }
        });

        this.getField('ch_saldo_cero').setOnChange((props)=>{
            if(this.getField('ch_saldo_cero').getValue()==='false'){
                this.operacion1='saldo_cero';
            }else{
                this.operacion1='';
            }
        });

        this.getField('costo').setOnChange((props)=>{
            if(this.getField('costo').getValue()==='false'){
                this.costo1=this.campo_costo;
            }else{
                this.costo1="";
            }
        });

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_solo_saldos').setOnChange((props)=>{
            if(this.getField('ch_solo_saldos').getValue()==='false'){
                this.ch_solo_saldos=true;
            }else{
                this.ch_solo_saldos=false;
            }
        });

    }

    limpiar(){
        this.gridOptionsComponentes1['rowData'] = [];            
        let configCell = new Map();
        this.getField('rejilla').initData(this.gridOptionsComponentes1,configCell);
        this.getField('rejilla').toggle(false)
        if(this.getField('nombre_bodega').getValue() !== ""){
            this.getField('nombre_bodega').setError(false,'');
        }
        if(this.getField('nombre_grupo').getValue() !== ""){
            this.getField('nombre_grupo').setError(false,'');
        }
        if(this.getField('nombre_subgrupo').getValue() !== ""){
            this.getField('nombre_subgrupo').setError(false,'');
        }
        if(this.getField('nombre_marca').getValue() !== ""){
            this.getField('nombre_marca').setError(false,'');
        }
        this.getField('codigo_articulo_desde').setError(false,'');
        this.getField('codigo_articulo_hasta').setError(false,'');

        this.getField("total_costo").setValue("0");
        this.getField("total_valor").setValue("0");
        this.getField("total_saldo").setValue("0");
    }

    traerinformacion(){
        let datos={ datos: {
            sucursal_ingreso:this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-listadoporcodigo', operacion: 'informacion', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.success_traerinformacion,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    success_traerinformacion(data){
        if (data.estado_p === 200)
        {
            this.mostrar_pantalla = data.data.mostrar_pantalla;
            this.costo_por_bodega = data.data.costo_por_bodega;
            this.mostrar_tarifa_iva = data.data.mostrar_tarifa_iva;
            this.costo_promedio_linea = data.data.costo_promedio_linea;
            this.elsaldo2 = data.data.elsaldo2;
            this.unifica_saldos = data.data.unifica_saldos;
            this.ordenar_listado_inventario = data.data.ordenar_listado_inventario;
            this.Supermercado = data.data.Supermercado;
            this.Bodega = data.data.Bodega;
            this.costo_unificado = data.data.costo_unificado;

        }else 
        {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    negativos(){
        this.limpiar();
        if(this.getField('bodega').valid() && this.getField('nombre_bodega').valid()){
            this.mostrarMensajeGenerando();
            let datos = { datos: {
                bodega: this.getField('bodega').getValue(),
                costo_unificado:this.costo_unificado,
                costo_promedio_linea:this.costo_promedio_linea,
                codigo_grupo:this.getField('codigo_grupo').getValue(),
                nombre_grupo:this.getField('nombre_grupo').getValue(),
                codigo_subgrupo:this.getField('codigo_subgrupo').getValue(),
                nombre_subgrupo:this.getField('nombre_subgrupo').getValue(),
                codigo_marca:this.getField('codigo_marca').getValue(),
                nombre_marca:this.getField('nombre_marca').getValue(),
            }};

            this.getField("rejilla").setTipoServicioOperacion("inve-listadoporcodigo-negativos");
            this.getField("rejilla").sendRequest(this.success_negativos, datos);
        }
    }

    success_negativos(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200)
        {
            this.getField("imprimir").setDisabled(false);
            this.opcion_impresion = 'N';
            this.getField('nombre_grupo').setError(false,'');
            this.getField('nombre_subgrupo').setError(false,'');
            this.getField('nombre_marca').setError(false,'');
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data.datos;
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set(2, {cellRenderer:(props) => {
                let arrayTemp = Object.values(props);
                return ""+this.currencyFormatterGeneral(arrayTemp[3]);
            }, cellStyle: {textAlign:"right"},width: 110});            
            
            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);

            this.getField("total_costo").setValue(data.data.totales[0].total_costo.toFixed(2));
            this.getField("total_valor").setValue(data.data.totales[0].total_valor.toFixed(2));
            this.getField("total_saldo").setValue(data.data.totales[0].total_saldo.toFixed(2));
        }else 
        {
            this.getField('rejilla').toggle(false);
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    
    agotados(){
        this.limpiar();
        this.mostrarMensajeGenerando();
        let datos = { datos: {
            bodega: this.getField('bodega').getValue(),
            costo_unificado:this.costo_unificado,
            Supermercado:this.Supermercado,
            Bodega:this.Bodega,
            codigo_grupo:this.getField('codigo_grupo').getValue(),
            nombre_grupo:this.getField('nombre_grupo').getValue(),
            codigo_subgrupo:this.getField('codigo_subgrupo').getValue(),
            nombre_subgrupo:this.getField('nombre_subgrupo').getValue(),
            codigo_marca:this.getField('codigo_marca').getValue(),
            nombre_marca:this.getField('nombre_marca').getValue(),
        }};

        this.getField("rejilla").setTipoServicioOperacion("inve-listadoporcodigo-agotados");
        this.getField("rejilla").sendRequest(this.success_agotados, datos);
    }

    success_agotados(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200)
        {
            this.getField("imprimir").setDisabled(false);
            this.opcion_impresion = 'A';
            this.getField('nombre_grupo').setError(false,'');
            this.getField('nombre_subgrupo').setError(false,'');
            this.getField('nombre_marca').setError(false,'');
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data.datos;
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set(3, {cellRenderer:(props) => {
                let arrayTemp = Object.values(props);
                return ""+this.currencyFormatterGeneral(arrayTemp[4]);
            }, cellStyle: {textAlign:"right"},width: 110}); 

            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);

            this.getField("total_costo").setValue(data.data.totales.total_costo);
            this.getField("total_valor").setValue(data.data.totales.total_valor);
            this.getField("total_saldo").setValue(data.data.totales.total_saldo);
        }else 
        {
            this.getField('rejilla').toggle(false);
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    
    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
        return Number(number).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
    
    elegir_precio(){
        this.limpiar();
        if (this.costo1 !== "" && this.getField("precio").getValue() !== "") {
            this.campo = this.costo1 + this.getField("bodega").getValue() + " as costo,precio" + this.getField("precio").getValue() + " as precio";
        } else if (this.costo1 !== "") {
            this.campo = this.costo1  + this.getField("bodega").getValue() + " as costo";//costo
        } else if (this.getField("precio").getValue() !== "") {
            this.campo = "precio" + this.getField("precio").getValue() + " as precio";
        } else {
            this.campo = this.campo_costo + this.getField("bodega").getValue() + " as costo";
        }
        this.getField("modal_elegir_precio").handleClose();
        this.procesar();
    }

    procesar(){
        if ((this.elsaldo2 !== "null" || this.unifica_saldos !== "null") && this.getField('bodega').getValue() === "1") {
            this.confirmSaldosUnificados();
        }
        if((this.getField("bodega").getValue() !== ""  && this.getField("nombre_bodega").getValue() !== "") || this.getField('ch_bodegas').getValue()==='true'){
            this.mostrarMensajeGenerando();
            let datos = { datos: {
                bodega: this.getField('bodega').getValue(),
                costo_unificado:this.costo_unificado,
                costo_promedio_linea:this.costo_promedio_linea,
                costo_por_bodega:this.costo_por_bodega,
                mostrar_pantalla:this.mostrar_pantalla,
                ordenar_listado_inventario:this.ordenar_listado_inventario,
                mostrar_tarifa_iva:this.mostrar_tarifa_iva,
                codigo_grupo:this.getField('codigo_grupo').getValue(),
                nombre_grupo:this.getField('nombre_grupo').getValue(),
                codigo_subgrupo:this.getField('codigo_subgrupo').getValue(),
                nombre_subgrupo:this.getField('nombre_subgrupo').getValue(),
                codigo_marca:this.getField('codigo_marca').getValue(),
                nombre_marca:this.getField('nombre_marca').getValue(),
                codigo_articulo_desde:this.getField('codigo_articulo_desde').getValue(),
                codigo_articulo_hasta:this.getField('codigo_articulo_hasta').getValue(),
                consulta:this.campo,
                operacion:this.operacion2,
                operacion1:this.operacion1,
                campo_saldo:this.saldos_unificados
            }};
            
            this.getField("rejilla").setTipoServicioOperacion("inve-listadoporcodigo-procesar");
            this.getField("rejilla").sendRequest(this.success_procesar, datos);
        }
    }

    success_procesar(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200)
        {
            this.getField("imprimir").setDisabled(false);
            this.opcion_impresion = 'P';
            this.getField('nombre_grupo').setError(false,'');
            this.getField('nombre_subgrupo').setError(false,'');
            this.getField('nombre_marca').setError(false,'');
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data.datos;
            if(this.getField("bodega").getValue() !== ""){
                configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});            
                configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});            
                configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});            
                configCell.set('precio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.precio) }, cellStyle: {textAlign:"right"},width: 110});            
            }
            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);

            this.getField("total_costo").setValue(data.data.totales[0].total_costo.toFixed(2));
            this.getField("total_valor").setValue(data.data.totales[0].total_valor.toFixed(2));
            this.getField("total_saldo").setValue(data.data.totales[0].total_saldo.toFixed(2));
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    
    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    procesararchivo(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' ){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreo();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    enviarCorreo(){        
        this.mostrarMensajeGenerando();
        let datos={ datos: { } };
        datos.datos.opcion_impresion= this.opcion_impresion;
        if(this.opcion_impresion === 'P'){

            datos.datos.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos.datos.bodega= this.getField('bodega').getValue();
            datos.datos.ch_solo_saldos=this.ch_solo_saldos;
            datos.datos.costo_unificado=this.costo_unificado;
            datos.datos.costo_promedio_linea=this.costo_promedio_linea;
            datos.datos.costo_por_bodega=this.costo_por_bodega;
            datos.datos.mostrar_tarifa_iva=this.mostrar_tarifa_iva;
            datos.datos.total_registros=this.total_registros;
            datos.datos.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos.datos.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos.datos.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos.datos.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos.datos.codigo_marca=this.getField('codigo_marca').getValue();
            datos.datos.nombre_marca=this.getField('nombre_marca').getValue();
            datos.datos.codigo_articulo_desde=this.getField('codigo_articulo_desde').getValue();
            datos.datos.codigo_articulo_hasta=this.getField('codigo_articulo_hasta').getValue();
            datos.datos.consulta=this.campo;
            datos.datos.operacion2=this.operacion2;
            datos.datos.operacion1=this.operacion1;
            datos.datos.campo_saldo=this.saldos_unificados
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();

        }else if(this.opcion_impresion === 'A'){

            datos.datos.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.bodega= this.getField('bodega').getValue();
            datos.datos.costo_unificado=this.costo_unificado;
            datos.datos.Supermercado=this.Supermercado;
            datos.datos.ch_solo_saldos=this.ch_solo_saldos;
            datos.datos.Bodega=this.Bodega;
            datos.datos.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos.datos.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos.datos.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos.datos.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos.datos.codigo_marca=this.getField('codigo_marca').getValue();
            datos.datos.nombre_marca=this.getField('nombre_marca').getValue();
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();

        }else if(this.opcion_impresion === 'N'){
            datos.datos.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.ch_solo_saldos=this.ch_solo_saldos;
            datos.datos.bodega= this.getField('bodega').getValue();
            datos.datos.costo_unificado=this.costo_unificado;
            datos.datos.costo_promedio_linea=this.costo_promedio_linea;
            datos.datos.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos.datos.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos.datos.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos.datos.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos.datos.codigo_marca=this.getField('codigo_marca').getValue();
            datos.datos.nombre_marca=this.getField('nombre_marca').getValue();
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
        }

        this.generalFormatPmv = { tipo_servicio: 'inve-listadoporcodigo', operacion: 'imprimir_pag', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla(){
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2={};
        datos2.opcion_impresion=this.opcion_impresion;
        if(this.opcion_impresion === 'P'){

            datos2.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos2.sucursal= this.codigo_sucursal;
            datos2.total_registros=this.total_registros;
            datos2.ch_solo_saldos=this.ch_solo_saldos;
            datos2.bodega= this.getField('bodega').getValue();
            datos2.costo_unificado=this.costo_unificado;
            datos2.costo_promedio_linea=this.costo_promedio_linea;
            datos2.costo_por_bodega=this.costo_por_bodega;
            datos2.mostrar_tarifa_iva=this.mostrar_tarifa_iva;
            datos2.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos2.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos2.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos2.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos2.codigo_marca=this.getField('codigo_marca').getValue();
            datos2.nombre_marca=this.getField('nombre_marca').getValue();
            datos2.codigo_articulo_desde=this.getField('codigo_articulo_desde').getValue();
            datos2.codigo_articulo_hasta=this.getField('codigo_articulo_hasta').getValue();
            datos2.consulta=this.campo;
            datos2.operacion1=this.operacion1;
            datos2.campo_saldo=this.saldos_unificados
            datos2.operacion2=this.operacion2;
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();

        }else if(this.opcion_impresion === 'A'){

            datos2.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.bodega= this.getField('bodega').getValue();
            datos2.ch_solo_saldos=this.ch_solo_saldos;
            datos2.costo_unificado=this.costo_unificado;
            datos2.Supermercado=this.Supermercado;
            datos2.Bodega=this.Bodega;
            datos2.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos2.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos2.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos2.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos2.codigo_marca=this.getField('codigo_marca').getValue();
            datos2.nombre_marca=this.getField('nombre_marca').getValue();
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();

        }else if(this.opcion_impresion === 'N'){
            datos2.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos2.ch_solo_saldos=this.ch_solo_saldos;
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.bodega= this.getField('bodega').getValue();
            datos2.costo_unificado=this.costo_unificado;
            datos2.costo_promedio_linea=this.costo_promedio_linea;
            datos2.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos2.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos2.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos2.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos2.codigo_marca=this.getField('codigo_marca').getValue();
            datos2.nombre_marca=this.getField('nombre_marca').getValue();
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.operacion1=this.operacion1;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-listadoporcodigo","operacion":"imprimir_pag","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                if(this.opcion_impresion === 'P' && this.getField("bodega").getValue() === ""){
                    this.mostrarMensajePresentacionBodegas();
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla(){
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2={};
        datos2.opcion_impresion= this.opcion_impresion;
        if(this.opcion_impresion === 'P'){

            datos2.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos2.sucursal= this.codigo_sucursal;
            datos2.bodega= this.getField('bodega').getValue();
            datos2.ch_solo_saldos=this.ch_solo_saldos;
            datos2.costo_unificado=this.costo_unificado;
            datos2.total_registros=this.total_registros;
            datos2.costo_promedio_linea=this.costo_promedio_linea;
            datos2.costo_por_bodega=this.costo_por_bodega;
            datos2.mostrar_tarifa_iva=this.mostrar_tarifa_iva;
            datos2.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos2.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos2.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos2.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos2.codigo_marca=this.getField('codigo_marca').getValue();
            datos2.nombre_marca=this.getField('nombre_marca').getValue();
            datos2.codigo_articulo_desde=this.getField('codigo_articulo_desde').getValue();
            datos2.codigo_articulo_hasta=this.getField('codigo_articulo_hasta').getValue();
            datos2.consulta=this.campo;
            datos2.operacion2=this.operacion2;
            datos2.operacion1=this.operacion1;
            datos2.campo_saldo=this.saldos_unificados
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();

        }else if(this.opcion_impresion === 'A'){

            datos2.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos2.sucursal= this.codigo_sucursal;
            datos2.bodega= this.getField('bodega').getValue();
            datos2.costo_unificado=this.costo_unificado;
            datos2.Supermercado=this.Supermercado;
            datos2.ch_solo_saldos=this.ch_solo_saldos;
            datos2.Bodega=this.Bodega;
            datos2.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos2.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos2.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos2.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos2.codigo_marca=this.getField('codigo_marca').getValue();
            datos2.nombre_marca=this.getField('nombre_marca').getValue();
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();

        }else if(this.opcion_impresion === 'N'){
            datos2.nombre_bodega=this.getField("nombre_bodega").getValue();
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.ch_solo_saldos=this.ch_solo_saldos;
            datos2.bodega= this.getField('bodega').getValue();
            datos2.costo_unificado=this.costo_unificado;
            datos2.costo_promedio_linea=this.costo_promedio_linea;
            datos2.codigo_grupo=this.getField('codigo_grupo').getValue();
            datos2.nombre_grupo=this.getField('nombre_grupo').getValue();
            datos2.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
            datos2.nombre_subgrupo=this.getField('nombre_subgrupo').getValue();
            datos2.codigo_marca=this.getField('codigo_marca').getValue();
            datos2.nombre_marca=this.getField('nombre_marca').getValue();
            datos2.operacion1=this.operacion1;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-listadoporcodigo","operacion":"imprimir_pag","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = this.nombre_archivo+'.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo exitosamente!');
                    } 
                });
            }else{
                if(this.opcion_impresion === 'P' && this.getField("bodega").getValue() === "")
                    this.mostrarMensajePresentacionBodegas();
                else
                    this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    confirmSaldosUnificados(){
        this.getField('confirmModalCustom').setTitleAndContent('Saldo','¿Desea unificar los saldos de las bodegas 1 y 2?');
        this.getField('confirmModalCustom').setClickDialog(() => { this.saldos_unificados='(saldo1+saldo2)'});
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);       
    }

    mostrarMensajePresentacionBodegas(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Esta impresión está disponible cuando se incluye sólo una bodega.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    
    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
}
FormJaivana.addController("inve-listadoporcodigo", CstomListadoPorCodigo);
export default CstomListadoPorCodigo;