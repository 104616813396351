import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomActivosFijosBodegas
 * @author: Juan Camilo Villa Osorio
 * @version: jdesk_1.01.0002
 **/
class CustomActivosFijosBodegas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.arrayObjetos = new Map();
        this.arrayCampos = new Map();
        this.crearRegistro = this.crearRegistro.bind(this);
        this.successCrear = this.successCrear.bind(this);
        this.actualiarRegistro = this.actualiarRegistro.bind(this);
        this.successActualizar = this.successActualizar.bind(this);

        this.traerPrimero = this.traerPrimero.bind(this);
        this.successtraerPrimero = this.successtraerPrimero.bind(this);
        this.traerUltimo = this.traerUltimo.bind(this);
        this.successtraerUltimo = this.successtraerUltimo.bind(this);
        this.traerPorId = this.traerPorId.bind(this);
        this.successtraerPorId = this.successtraerPorId.bind(this);
        this.validarRegistroEliminar = this.validarRegistroEliminar.bind(this);
        this.succesvalidarRegistroEliminar = this.succesvalidarRegistroEliminar.bind(this);
        this.eliminarPorId = this.eliminarPorId.bind(this);
        this.successeliminarPorId = this.successeliminarPorId.bind(this);
        this.btnModificar = false;
        this.onClickChange = this.handlerClickChange.bind(this);
        this.onClickShowTable = this.handerClickShowTable.bind(this);
        this.onClickNew = this.handlerClickNew.bind(this);
        this.onClickDelete = this.handlerClickDelete.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.registro_seleccionado = false;
        this.mostrarMensajeSeleccionModificar = this.mostrarMensajeSeleccionModificar.bind(this);
        this.mostrarMensajeSeleccionEliminar = this.mostrarMensajeSeleccionEliminar.bind(this);
        this.successValidarUniqueRegistro = this.successValidarUniqueRegistro.bind(this);
        this.traerTodos = this.traerTodos.bind(this);
        this.successtraerTodos = this.successtraerTodos.bind(this);
        this.confirmarEliminacionRegistro = this.confirmarEliminacionRegistro.bind(this);
        this.abrirImprimir = this.abrirImprimir.bind(this);
        this.chargeSelectCiudad = this.chargeSelectCiudad.bind(this);
        this.successResponsechargeSelectCiudad = this.successResponsechargeSelectCiudad.bind(this);
        this.cargarSelectEstadosActivos = this.cargarSelectEstadosActivos.bind(this);
        this.chargeSelectDepto = this.chargeSelectDepto.bind(this);
        this.successResponsechargeSelectDepto = this.successResponsechargeSelectDepto.bind(this);
        this.validarDescripcion = this.validarDescripcion.bind(this);
        this.successValidarDescripciónUniqueRegistro = this.successValidarDescripciónUniqueRegistro.bind(this);


        //imprimir
        this.isJson                         =this.isJson.bind(this);
            this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
            this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
            this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
            this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
            this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
            this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
            this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
            this.procesar                         =this.procesar.bind(this);
            this.mostrarMensajeAdicional                         =this.mostrarMensajeAdicional.bind(this);
            this.mostrarMensajeGenerando                         =this.mostrarMensajeGenerando.bind(this);



        this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
        this.llenar = this.llenar.bind(this);
        this.traer_por_id = true;
        this.operacion_actual = '';
        this.nombre_archivo = 'listado_activos_fijos_bodegas';
        this.sucursal_ingreso ='';
        this.arrayEstadosActivos = [{'id': 1, 'value': 'Activa'}, {'id': 2, 'value': 'Inactiva'},{'id': 3, 'value': 'Suspendida'},{'id': 4, 'value': 'Bloqueada'}];
        this.estado_ciudades = false;
        this.llenarCiudad = false;

    }


    initForm() {
        console.log('Formulario CustomActivosFijosBodegas,  @version: jdesk_1.01.0004, @author:Juan Camilo Villa Osorio,')
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.arrayCampos.set(1, 'activos_fijos_bodegas_id');
        this.arrayCampos.set(2, 'codigo');
        this.arrayCampos.set(3, 'descripcion');
        this.arrayCampos.set(4, 'estadoactivo_id');
        this.arrayCampos.set(5, 'tipo');
        this.arrayCampos.set(6, 'fechacreacion');
        this.arrayCampos.set(7, 'departamento_geografico_id_1');
        this.arrayCampos.set(8, 'ciudad_id_1');
        this.arrayCampos.set(9, 'sede');
        this.arrayCampos.set(10, 'btn_imprimir');
        this.arrayCampos.set(11, 'label_form');


        this.arrayCampos.set(30, 'btn_agregar');
        this.arrayCampos.set(31, 'btn_cancelar');
        this.arrayCampos.set(32, 'btn_modificar');
        this.arrayCampos.set(33, 'btn_cancelar_modificar');
        //this.arrayCampos.set(34,'operacion');

        this.arrayObjetos.set(1, 'activos_fijos_bodegas_id');
        this.arrayObjetos.set(2, 'codigo');
        this.arrayObjetos.set(3, 'descripcion');
        this.arrayObjetos.set(4, 'estadoactivo_id');
        this.arrayObjetos.set(5, 'tipo');
        this.arrayObjetos.set(6, 'fechacreacion');
        this.arrayObjetos.set(7, 'departamento_geografico_id_1');
        this.arrayObjetos.set(8, 'ciudad_id_1');
        this.arrayObjetos.set(9, 'sede');
        this.arrayObjetos.set(10, 'btn_imprimir');
        this.arrayObjetos.set(11, 'label_form');


        this.arrayObjetos.set(30, 'btn_agregar');
        this.arrayObjetos.set(31, 'btn_cancelar');
        this.arrayObjetos.set(32, 'btn_modificar');
        this.arrayObjetos.set(33, 'btn_cancelar_modificar');
        //this.arrayObjetos.set(34,'operacion');
        this.establecerPropiedades('1,2,3,4,5,7,8,9,10,11', 'visible', 'false')
        this.establecerPropiedades('1,2,3,4,5,6,7,8,9,10,11', 'disable', 'true')

        //inicio form
        this.establecerPropiedades('2,3,4,5,7,8,9,11', 'visible', 'true');
        /* this.traerPrimero(); */
        this.chargeSelectDepto();
        this.getField('departamento_geografico_id_1').setOnChange( this.chargeSelectCiudad);



        this.getField('btn_cancelar').setClick(() => {
            this.traer_por_id = false;
            this.btnCancelarRegistro();
        });
        this.getField('btn_agregar').setClick(() => {
            if (this.getField("ciudad_id_1").valid() && this.getField("codigo").valid() &&
            this.getField("departamento_geografico_id_1").valid() && this.getField("descripcion").valid() &&
            this.getField("estadoactivo_id").valid() && this.getField("fechacreacion").valid()&&
            this.getField("sede").valid() && this.getField("tipo").valid())
            /* this.getField('ciudad_id_1').setValue(''); */
                this.validacionUnique();
               /* this.crearRegistro(); */
                
        });

        this.getField('btn_modificar').setClick(() => {
            if (this.getField('codigo').valid() && this.getField('descripcion').valid() && this.getField('estadoactivo_id').valid())
                /* this.validacionUnique(); */
                this.actualiarRegistro();
        });

        this.getField('btn_cancelar_modificar').setClick(() => {
            this.traer_por_id = false;
            this.btnModificar = false;
            this.btnCancelarRegistro();
        });

        this.getField('btn_imprimir').setClick(this.abrirImprimir);
        //imprimir
        this.getField('radio_correo').setValue("N");
        this.getField('generar_archivo').setDisabled(false);
        this.getField('ch_excel').setDisabled(false);
        this.getField('ch_pdf').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);

        this.getField('generar_archivo').setClick(() => this.procesar());

        this.getField('radio_correo').setOnChange((props) => {
            if (this.getField('radio_correo').getValue() === 'N') {
                this.getField('email').setDisabled(true);
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
            } else {
                this.getField('email').setDisabled(false);
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
            }
        });
        //para EXcel y PDF
        this.getField('ch_excel').setOnChange((props) => {
            if (this.getField('ch_excel').getValue() === 'false') {
                this.getField('ch_pdf').setValueSwitch(false);
                /* this.getField('ch_pantalla').setValueSwitch(false); */
                this.operacion_actual = 'excel';
                this.getField('radio_correo').setValue("N");
            }
        });
        this.getField('ch_pdf').setOnChange((props) => {
            if (this.getField('ch_pdf').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                /* this.getField('ch_pantalla').setValueSwitch(false); */
                this.operacion_actual = 'pdf';
                this.getField('radio_correo').setValue("N");
            }
        });
/*         this.getField('ch_pantalla').setOnChange((props) => {
            if (this.getField('ch_pantalla').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pdf').setValueSwitch(false);
                this.operacion_actual = 'pantalla';
                this.getField('radio_correo').setValue("N");
            } else {
                this.getField('radio_correo').setValue("N");
            }
        }); */
        this.getField('modal_imprimir').setCloseButton(() => {
            //nada
        });

        this.cargarSelectEstadosActivos();
        this.traerUltimo();


    }



    chargeSelectDepto() {
        let datos = {
            datos: {}
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-dptosgeografico', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: datos,
            success: this.successResponsechargeSelectDepto,
            error: this.error_,
            general: this.generalFormatPmv
        });

        
    }
    chargeSelectCiudad() {
        if (this.estado_ciudades === true) {
            let idDepto = this.getField("departamento_geografico_id_1").getValue();
                if (idDepto  !== '' && idDepto !== ' ' && idDepto !== 120) {
                    if(!this.btnModificar) {
                        this.getField('ciudad_id_1').setValue(' ');
                    }
                    this.btnModificar = false;
                    let datos = {
                        datos: { departamento_geografico_id: parseInt(idDepto) }
                    };
                    this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: '1_1', operacion_tipo: 'consulta' };
                    this.service.send({
                        endpoint: this.constant.formConfiguration(),
                        method: 'GET',
                        body: datos,
                        success: this.successResponsechargeSelectCiudad,
                        error: this.error_,
                        general: this.generalFormatPmv
                    });
                }else{
                    let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
                    this.getField('ciudad_id_1').setValue(' ');
                    this.getField('ciudad_id_1').setOptions(opciones);
                }  
        } 
    }


    successResponsechargeSelectDepto(data) {        
        if (data.estado_p === 200) {
            let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.departamentos_geograficos_id;
                dataOp['text'] = item.nombre;
                /* dataOp['campos_cambian'] = { rack: item.id }; */
                opciones.push(dataOp);
            });
            this.getField('departamento_geografico_id_1').setOptions(opciones);
            this.traerPrimero();
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No hay departamentos', 'error');
                let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
                this.getField('departamento_geografico_id_1').setValue(' ');
                this.getField('departamento_geografico_id_1').setOptions(opciones);


            } else {
                let respuesta = Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
        }

    }
    successResponsechargeSelectCiudad(data) {      
        if (data.estado_p === 200) {
            let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.ciudades_id;
                dataOp['text'] = item.nombre;
                /* dataOp['campos_cambian'] = { rack: item.id }; */
                opciones.push(dataOp);
            });
            this.getField('ciudad_id_1').setOptions(opciones);
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No hay ciudades para este departamento .', 'error');
                let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
                this.getField('ciudad_id_1').setValue(' ');
                this.getField('ciudad_id_1').setOptions(opciones);


            } else {
                this.alertGeneral.toggle(true, 'Error Cargando las ciudaded', 'error');
            }
        }

    }
    cargarSelectEstadosActivos() {

        if (this.arrayEstadosActivos.length > 0) {
            let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
            this.arrayEstadosActivos.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.value;
                /* dataOp['campos_cambian'] = { rack: item.id }; */
                opciones.push(dataOp);
            });
            this.getField('estadoactivo_id').setOptions(opciones);
        } 


    }

    abrirImprimir() {
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false, '');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        /* this.getField('ch_pantalla').setValueSwitch(false); */
    }

    onSelectionChanged() {
        let filaSeleccionada = this.gridOptionsTableDefault.api.getSelectedRows();
        if (filaSeleccionada.length > 0) {
            this.llenar(filaSeleccionada);
            this.registro_seleccionado = true;
        } else {
            this.registro_seleccionado = false;
        }
    }

    handerClickShowTable() {
        this.llenarCiudad = true;
        this.registro_seleccionado = false;
        this.traer_por_id = false;
        /* this.traerPrimero(); */
        this.traerTodos();

    }

    handlerClickChange() {
        this.btnModificar = true;
        if (this.registro_seleccionado) {
            this.registro_seleccionado = false;
            this.establecerPropiedades('4,5,7,8,9,32,33', 'disable', 'false');
            this.establecerPropiedades('32,33', 'visible', 'true');
            this.getField('btn_new').setVisible(false);
            this.getField('btn_show').setVisible(false);
            this.getField('btn_change').setVisible(false);
            this.getField('btn_delete').setVisible(false);
            this.getField('rejilla').toggle(false);
            this.getField('btn_imprimir').setVisible(false);

            this.getField('departamento_geografico_id_1').setValue(this.getField('departamento_geografico_id_1').getValue());


        } else {
            this.mostrarMensajeSeleccionModificar();
        }
    }

    handlerClickNew() {
        this.btnModificar = false;
        this.registro_seleccionado = false;
        this.establecerPropiedades('2,3,4,5,6,7,8,9,30,31', 'disable', 'false');
        this.establecerPropiedades('30,31', 'visible', 'true');
        this.establecerPropiedades('1,2,3,4,5,6,7,8,9', 'value', '');


        this.getField('btn_new').setVisible(false);
        this.getField('btn_show').setVisible(false);
        this.getField('btn_change').setVisible(false);
        this.getField('btn_delete').setVisible(false);
        this.getField('rejilla').toggle(false);
        this.getField('btn_imprimir').setVisible(false);
        this.getField('ciudad_id_1').setValue(' ');
        let opciones = [{ 'text': 'Ninguno', 'value': ' ', 'campos_cambian': {} }];
        this.getField('ciudad_id_1').setOptions(opciones);

    }

    handlerClickDelete() {
        this.traer_por_id = false;
        if (this.registro_seleccionado) {
            this.confirmarEliminacionRegistro();
            this.registro_seleccionado = false;
        } else {
            this.mostrarMensajeSeleccionEliminar();
        }
    }

    mostrarMensajeSeleccionModificar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para modificar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeSeleccionEliminar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para eliminar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    btnCancelarRegistro() {
        this.getField('confirmModalCustom').toggle(false);
        this.registro_seleccionado = false;
        this.getField('btn_agregar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.getField('btn_new').setDisabled(false);
        this.getField('btn_show').setDisabled(false);
        this.getField('btn_delete').setDisabled(false);

        this.establecerPropiedades('1,2,3,4,5,6,7,8,9', 'disable', 'true');
        this.establecerPropiedades('1,2,3,4,5,6,7,8,9', 'value', '');
        this.establecerPropiedades('1,2,3,4,5,6,7,8,9', 'error', 'false');
        if (this.traer_por_id)
            this.traerPorId();
        else
            this.traerUltimo();
        this.traerTodos();


        this.getField('btn_new').setVisible(true);
        this.getField('btn_show').setVisible(true);
        this.getField('btn_change').setVisible(true);
        this.getField('btn_delete').setVisible(true);

/*         this.gridOptionsTableDefault["rowData"] = [];
        this.getField('rejilla').initData(this.gridOptionsTableDefault);
        this.getField('rejilla').toggle(false); */
        this.btnModificar = false;
    }

    validacionUnique() {
        if (this.getField('codigo').getValue() !== '' && this.getField('codigo').getValue() !== ' ' && 
        this.getField('descripcion').getValue() !== '' && this.getField('descripcion').getValue() !== ' '
        ) {
            let datos = {
                datos: {
                    codigo: this.getField('codigo').getValue(),
                    descripcion: this.getField('descripcion').getValue(),
                }
            };
            let operacion = '20';
            
/*             if (this.btnModificar) {
                datos.datos['activos_fijos_bodegas_id'] = this.getField('activos_fijos_bodegas_id').getValue();
                operacion = '20_modificar';
            } */
            this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: operacion, operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successValidarUniqueRegistro,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
        } 
    }

    successValidarUniqueRegistro(data) {
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'Este código ya se encuentra asignado a un registro', 'error');
        } else if(data.estado_p === 404){
            this.validarDescripcion();

        }
    }

        validarDescripcion(){
            if ( this.getField('descripcion').getValue() !== '' && this.getField('descripcion').getValue() !== ' ') {
                let datos = {
                    datos: {
                        descripcion: this.getField('descripcion').getValue(),
                    }
                };
    
            
                this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: '21', operacion_tipo: 'consulta' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'GET',
                        body: datos,
                        success: this.successValidarDescripciónUniqueRegistro,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });
            } 
    
        }

        successValidarDescripciónUniqueRegistro(data) {
            if (data.estado_p === 200) {
                this.alertGeneral.toggle(true, 'Esta descripción ya se encuentra asignada', 'error');
            } else {
                if (data.estado_p === 404) {
/*                     if (this.btnModificar) {
                        this.actualiarRegistro();
                    } else { */
                        this.crearRegistro();
                    /* } */
                }
                else {
                    this.alertGeneral.toggle(true, 'Error validando la descripción', 'error');
                }
    
            }
        }

    traerPorId() {
        let id_bodega = (this.getField('activos_fijos_bodegas_id').getValue() === null ||
        this.getField('activos_fijos_bodegas_id').getValue() !== '' || 
        this.getField('activos_fijos_bodegas_id').getValue() !== ' ' || 
        !isNaN(this.getField('activos_fijos_bodegas_id').getValue()) )
        ?  parseInt(localStorage.getItem('activos_fijos_bodegas_id')):
        this.getField('activos_fijos_bodegas_id').getValue();


        if (id_bodega !== '' && id_bodega !== ' ' && id_bodega !== null && !isNaN(id_bodega) ) {
            let datos = {
                datos: {
                    activos_fijos_bodegas_id: id_bodega
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: '2', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successtraerPorId,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }
    }

    successtraerPorId(data) {
        if (data.estado_p === 200) {
            this.llenar(data.data)
        } /* else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error obteniendo el registro', 'error');
            }
        } */
    }

    confirmarEliminacionRegistro() {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(this.eliminarPorId);
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ 
            this.getField('confirmModalCustom').toggle(false);
            this.registro_seleccionado = true;
             });
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField('confirmModalCustom').toggle(true);
    }

    validarRegistroEliminar() {
        /* this.getField('confirmModalCustom').toggle(false); */
        this.eliminarPorId();
    }

    succesvalidarRegistroEliminar(data) {
        if (data.data[0].cuantos === 0) {
            this.eliminarPorId();

        } else {
            this.alertGeneral.toggle(true, '* Este registro ya está siendo utilizado en otro formulario, no se puede eliminar', 'error')
            this.getField('confirmModalCustom').toggle(false);

        }
    }

    eliminarPorId() {
        let datos = {
            datos: {
                activos_fijos_bodegas_id: this.getField('activos_fijos_bodegas_id').getValue()
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successeliminarPorId,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage : false
            });
    }

    successeliminarPorId(data) {
        if (data.estado_p === 200) {
            this.getField('confirmModalCustom').toggle(false);
            this.traerPrimero();
            this.traerTodos();
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } else if (data.estado_p === 502) {
                /* let respuesta = Object.values(data.data.errores); */
                let mensajeError = ' Este registro se está usando en otro formulario, no se puede eliminar'  
                this.alertGeneral.toggle(true, mensajeError, 'error');
                this.getField('confirmModalCustom').toggle(false);
            
            } else {
                this.alertGeneral.toggle(true, 'No se pudo eliminar el registro', 'error');
                this.getField('confirmModalCustom').toggle(false);
            }
        }
    }

    crearRegistro() {
        if (!isNaN(parseInt(this.getField("ciudad_id_1").getValue())) && !isNaN(parseInt(this.getField("departamento_geografico_id_1").getValue()))
          &&  this.getField("ciudad_id_1").valid() && this.getField("departamento_geografico_id_1").valid() 
          && this.getField("codigo").valid() && this.getField("descripcion").valid() && !isNaN(parseInt(this.getField("estadoactivo_id").getValue()))
        && this.getField('fechacreacion').valid() && this.getField('sede').valid() && this.getField('tipo').valid() ) {

            let datos = {
                datos: {
                    ciudad_id: parseInt(this.getField("ciudad_id_1").getValue()),
                    codigo: this.getField("codigo").getValue(),
                    departamento_geografico_id: parseInt(this.getField("departamento_geografico_id_1").getValue()),
                    descripcion: this.getField("descripcion").getValue(),
                    estadoactivo_id: parseInt(this.getField("estadoactivo_id").getValue()),
                    fechacreacion: this.getField("fechacreacion").getValue(),
                    sede: this.getField("sede").getValue(),
                    tipo: this.getField("tipo").getValue(),
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successCrear,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });

        }
    }

    successCrear(data) {
        if (data.estado_p === 200) {
            this.getField('activos_fijos_bodegas_id').setValue(data.data[0].activos_fijos_bodegas_id);
            this.traer_por_id = true;
            this.btnCancelarRegistro();
            this.traerUltimo()
        } else {
            if (data.estado_p === 502) {
                let respuesta = Object.values(data.data.errores);
                let mensajeError = 'Error ';
                if (respuesta.toString().includes('"activos_fijos_bodegas_descripcion_key"')) {
                   mensajeError = ' Esta descripción ya está asociada a un registro'  
                }else{
                    mensajeError = 'El código ya está asociado a un registro';
                } 
                this.alertGeneral.toggle(true, mensajeError , 'error');
            
            }

        }
    }

    actualiarRegistro() {
        if (this.getField('activos_fijos_bodegas_id').getValue() !== '' && this.getField('activos_fijos_bodegas_id').getValue() !== ' ' && this.getField('activos_fijos_bodegas_id').getValue() !== null
        && this.getField("ciudad_id_1").valid() && this.getField("departamento_geografico_id_1").valid() 
        && this.getField('fechacreacion').valid() && this.getField('sede').valid() && this.getField('tipo').valid() 
        && this.getField('descripcion').valid() 
        && !isNaN(parseInt(this.getField("ciudad_id_1").getValue())) && !isNaN(parseInt(this.getField("departamento_geografico_id_1").getValue())) )  {
            let datos = {
                datos: {
                    codigo: this.getField("codigo").getValue(),
                    ciudad_id: parseInt(this.getField("ciudad_id_1").getValue()),
                    departamento_geografico_id: parseInt(this.getField("departamento_geografico_id_1").getValue()),
                    descripcion: this.getField("descripcion").getValue(),
                    estadoactivo_id: parseInt(this.getField("estadoactivo_id").getValue()),
                    fechacreacion: this.getField("fechacreacion").getValue(),
                    sede: this.getField("sede").getValue(),
                    tipo: this.getField("tipo").getValue(),
                    activos_fijos_bodegas_id: this.getField('activos_fijos_bodegas_id').getValue()
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successActualizar,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successActualizar(data) {
        if (data.estado_p === 200) {
            this.getField('activos_fijos_bodegas_id').setValue(data.data[0].activos_fijos_bodegas_id);
            localStorage.setItem('activos_fijos_bodegas_id', this.getField('activos_fijos_bodegas_id').getValue().toString())
            this.traer_por_id = true;
            this.btnCancelarRegistro();
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se pudo actualizar el registro', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error actualizando el registro', 'error');
            }
        }
    }


    traerTodos() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerTodos,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successtraerTodos(data) {
        if (data.estado_p === 200) {
            //armar tabla
            let configCell = new Map();
            configCell.set('estadoactivo_id', { cellRenderer: (props) => { return "" + props.data.estadoactivo_id }, hide: true });
            configCell.set('activos_fijos_bodegas_id', { cellRenderer: (props) => { return "" + props.data.activos_fijos_bodegas_id }, hide: true });
            configCell.set('nombre_departamento', { cellRenderer: (props) => { return "" + props.data.nombre_departamento }, headerName: 'Departamento' });
            configCell.set('nombre_ciudad', { cellRenderer: (props) => { return "" + props.data.nombre_ciudad }, headerName: 'Ciudad' });



            this.gridOptionsTableDefault['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            this.gridOptionsTableDefault['onSelectionChanged'] = this.onSelectionChanged;
            this.getField('rejilla').initData(this.gridOptionsTableDefault, configCell);
            this.getField('btn_imprimir').setVisible(true);
            this.getField('btn_imprimir').setDisabled(false);
            this.registro_seleccionado = false;
        }
        else {
            this.getField('btn_imprimir').setVisible(false);
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {

                this.alertGeneral.toggle(true, 'Error al traer todos los registros', 'error');
            }
        }
    }

    traerPrimero() {
        this.llenarCiudad = true;
        this.estado_ciudades = true;
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: '3', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerPrimero,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successtraerPrimero(data) {
        if (data.estado_p === 200) {
            this.llenar(data.data);
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro el registro.', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error Obteniendo el registro', 'error');
            }
        }
    }

    traerUltimo() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: '4', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerUltimo,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successtraerUltimo(data) {
        if (data.estado_p === 200) {
            this.llenar(data.data);
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
/*             else {
                let respuesta = Object.values(data.data.errores);
                let keys = Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
            } */
        }
    }

    llenar(data) {

        this.getField('activos_fijos_bodegas_id').setValue(data[0].activos_fijos_bodegas_id);
        this.getField('codigo').setValue(data[0].codigo);
        this.getField('descripcion').setValue(data[0].descripcion);
        this.getField('tipo').setValue(data[0].tipo);
        this.getField('estadoactivo_id').setValue(data[0].estadoactivo_id);
        this.getField('fechacreacion').setValue(data[0].fechacreacion);
        if(this.llenarCiudad === true){
            this.estado_ciudades = false;
        }
        this.getField('departamento_geografico_id_1').setValue(data[0].departamento_geografico_id);
        this.estado_ciudades = true;
        this.getField('ciudad_id_1').setValue(data[0].ciudad_id);
        this.getField('sede').setValue(data[0].sede);
        if(this.llenarCiudad === true){
            let opciones = [{ 'text': data[0].nombre_ciudad, 'value': data[0].ciudad_id, 'campos_cambian': {} }];
                this.getField('ciudad_id_1').setOptions(opciones);

        }
    }




    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
        });
    }

    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > 2) {
                decimal = (Number(number).toFixed(2) + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


    //imprimir------->

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false'/*  && this.getField('ch_pantalla').getValue()==='false' */){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            /* this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.'); */
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf o Excel.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.nombre_pdf='compra';
                    this.nombre_excel='compra';
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                        //nothing
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }


    enviarCorreoTerceros(){
      this.mostrarMensajeGenerando();
      let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso,
        email:this.getField('email').getValue(),
        radio_correo:this.getField('radio_correo').getValue(),
        operacion:this.operacion_actual,
      }};
      this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosbodegas', operacion: 'imprimir_bodegas', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successEnviarCorreo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }
  
  successEnviarCorreo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
          this.mostrarMensajeEmailEnviado();
      }else{
          this.mostrarMensajeEmailFallo();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      }
  }

  mostrarMensajeEmailEnviado(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeEmailFallo(){
    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
  }

  mostrarMensajeNoHayDatos(){
    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
    this.getField('confirmModalCustom').toggle(true);
}

  generarPdfTabla3()
    {       
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                sucursal_ingreso:this.sucursal_ingreso,
                operacion:this.operacion_actual,
            };

            let op='imprimir_bodegas';
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"serlog-activosfijosbodegas","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            console.log('nombre archivo ', this.nom);
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
                sucursal_ingreso:this.sucursal_ingreso,
                operacion:this.operacion_actual,
/*                 email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(), */
            };
            let op='imprimir_bodegas';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"serlog-activosfijosbodegas","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }


    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Compras', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

}
FormJaivana.addController("serlog-activosfijosbodegas", CustomActivosFijosBodegas);
export default CustomActivosFijosBodegas