import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomGrabacionTraslados
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 * @UpdateBy Sebastian Rios E.
 **/

class CustomGrabacionTraslados extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                         = this.initForm.bind(this);
        this.gridOptionsComponentes           = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1          = Object.assign({}, this.gridOptions);
        this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
        this.gridOptionsComponentes1['onSelectionChanged']           = this.onSelectionChanged;

        this.gridOptionsRejillaInformativa          = Object.assign({}, this.gridOptions);
        this.gridOptionsRejillaInformativa['onSelectionChanged']           = this.onSelectionChanged;

        this.gridOptionsUbicacionesSaldos       = Object.assign({}, this.gridOptions);
        this.gridOptionsUbicacionesSaldos['onSelectionChanged']           = this.onSelectionChanged;


        this.gridOptionsBodega                = Object.assign({}, this.gridOptions);
        this.gridOptions                      = Object.assign({}, this.gridOptions);
        this.onSelectionChangedPrincipal                                  = this.onSelectionChangedPrincipal.bind(this);
        this.gridOptions['onSelectionChanged']           = this.onSelectionChangedPrincipal;


        this.gridOptionsConsultarModal        = Object.assign({}, this.gridOptions);
        this.validartablaubicaciones          = this.validartablaubicaciones.bind(this);
        this.gridOptionsConsultarModal['onSelectionChanged']           = this.onSelectionChanged;


        this.successAjuste                    = this.successAjuste.bind(this);
        this.pantalla                         = '';
        this.ubicacionbodegas                 = this.ubicacionbodegas.bind(this);
        this.successAjusteubicacion           = this.successAjusteubicacion.bind(this);
        this.ubicacionbodegas2                = this.ubicacionbodegas2.bind(this);
        this.successAjusteubicacion2          = this.successAjusteubicacion2.bind(this);
        this.creartemporalAjustes             = this.creartemporalAjustes.bind(this);
        this.successCrearTemporal             = this.successCrearTemporal.bind(this);
        this.successMostrarTabla              = this.successMostrarTabla.bind(this);
        this.consultar                        = this.consultar.bind(this);
        this.successConsultar                 = this.successConsultar.bind(this);
        this.agregar_articulo                 = this.agregar_articulo.bind(this);
        this.successWms                       = this.successWms.bind(this);
        this.ubicaiones                       = this.ubicaiones.bind(this);
        this.successUbicacionesWms            = this.successUbicacionesWms.bind(this);
        this.successBodegaWms                 = this.successBodegaWms.bind(this);
        this.successZonaWms                   = this.successZonaWms.bind(this);
        this.cargarRack                       = this.cargarRack.bind(this);
        this.successRackwms                   = this.successRackwms.bind(this);
        this.cargarColumna                    = this.cargarColumna.bind(this);
        this.successColumnawms                = this.successColumnawms.bind(this);
        this.tipoubicacion                    = this.tipoubicacion.bind(this);
        this.successTipoUbicacion             = this.successTipoUbicacion.bind(this);
        this.cerrarModalUbicaciones           = this.cerrarModalUbicaciones.bind(this);
        this.saldosubicacion                  = this.saldosubicacion.bind(this);
        this.successSaldosUbicacion           = this.successSaldosUbicacion.bind(this);
        this.traersaldoBodegas                = this.traersaldoBodegas.bind(this);
        this.successSaldosBodegas             = this.successSaldosBodegas.bind(this);
        this.actualizarcosto                  = this.actualizarcosto.bind(this);
        this.successActualizarCosto           = this.successActualizarCosto.bind(this);
        this.calcularvalores                  = this.calcularvalores.bind(this);
        this.grabarArticulo                   = this.grabarArticulo.bind(this); 
        this.successGrabarItem                = this.successGrabarItem.bind(this);
        this.limpiarArticulos                 = this.limpiarArticulos.bind(this);
        this.refrescar                        = this.refrescar.bind(this);
        this.successActualizarTotales         = this.successActualizarTotales.bind(this);
        this.setButtonDelete                  = this.setButtonDelete.bind(this);
        this.eliminar                         = this.eliminar.bind(this);
        this.confirmDelete                    = this.confirmDelete.bind(this);
        this.successEliminar                  = this.successEliminar.bind(this);
        this.cantidadtablaChange              = this.cantidadtablaChange.bind(this);
        this.successActualizarCantidad        = this.successActualizarCantidad.bind(this);
        this.traercodigo                      = this.traercodigo.bind(this);
        this.successTraerDatos                = this.successTraerDatos.bind(this);
        this.cantidad ="";
        this.grabarTraslado                   = this.grabarTraslado.bind(this);
        this.successTraerconsecutivo          = this.successTraerconsecutivo.bind(this);
        this.validartablatransaccion          = this.validartablatransaccion.bind(this);
        this.successValidarTransaccion        = this.successValidarTransaccion.bind(this);
        this.aceptartransaccion               = this.aceptartransaccion.bind(this);
        this.successtraerdoc                  = this.successtraerdoc.bind(this);
        this.traeritem                        = this.traeritem.bind(this);
        this.validacionesAgregar              = this.validacionesAgregar.bind(this);
        this.validacionesAgregar2             = this.validacionesAgregar2.bind(this);
        this.sucessValidaciones               = this.sucessValidaciones.bind(this);
        this.limpiarTodo                      = this.limpiarTodo.bind(this);

        this.mostrarMensajeEmailEnviado       = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo         = this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeNoHayDatos         = this.mostrarMensajeNoHayDatos.bind(this);
        this.generarPdfTabla3                 = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3               = this.generarExcelTabla3.bind(this);
        this.enviarCorreoTerceros             = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo              = this.successEnviarCorreo.bind(this);
        this.isJson                           = this.isJson.bind(this);
        this.procesar                         = this.procesar.bind(this);
        this.traerbodega                      = this.traerbodega.bind(this);
        this.successTraerBodega               = this.successTraerBodega.bind(this);
        this.traerwmsexterno                  = this.traerwmsexterno.bind(this);
        this.successTraerwmsexterno           = this.successTraerwmsexterno.bind(this);
        this.habilitarcampos                  = this.habilitarcampos.bind(this);
        this.validarflete                     = this.validarflete.bind(this);    
        this.agregardetalle                   = this.agregardetalle.bind(this);
        this.successCargarTraslado            = this.successCargarTraslado.bind(this);
        this.guardarRecibo                    = this.guardarRecibo.bind(this);
        this.succesGrabarTraslado             = this.succesGrabarTraslado.bind(this);
        this.generarModalLista                = this.generarModalLista.bind(this);
        this.sucessTraerDatos                 = this.sucessTraerDatos.bind(this);
        this.currencyFormatterGeneral         = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                = this.formatNumberSaldo.bind(this);
        this.actualizarDocOrigen              = this.actualizarDocOrigen.bind(this);
        this.sucessActualizarDoc              = this.sucessActualizarDoc.bind(this);
        this.validarWmsExterno                = this.validarWmsExterno.bind(this);
        this.successValidarWmsExterno         = this.successValidarWmsExterno.bind(this);
        this.limpiarCamposMAgregarArticulo    = this.limpiarCamposMAgregarArticulo.bind(this);
        this.sendCargarTabla                  = this.sendCargarTabla.bind(this);
        this.consultaCantidadItem             = this.consultaCantidadItem.bind(this);
        this.successConsultaCantidadItem      = this.successConsultaCantidadItem.bind(this);
        this.disabledCamposBodega             = this.disabledCamposBodega.bind(this);

        //ander
        this.limpiarCampos             = this.limpiarCampos.bind(this);
        this.limpiando_tabla=false;
        this.validarCodigo                  = this.validarCodigo.bind(this);
        this.sucessValidarCodigo                  = this.sucessValidarCodigo.bind(this);
        this.traerInfoArticulo                  = this.traerInfoArticulo.bind(this);
        this.sucessTraerArticulo                  = this.sucessTraerArticulo.bind(this);
        this.mostrarMensajeValidandoInformacion =this.mostrarMensajeValidandoInformacion.bind(this);
        this.traerPropiedades =this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades =this.sucesstraerPropiedades.bind(this);
        this.mostrarMensajeAdicional  = this.mostrarMensajeAdicional.bind(this);
        this.pide_ubicacion=false;
        //esto se debería traer desde un servicio
        this.numero_decimales=2;
        this.seleccionando_doc_origen=false;
        this.row_principal=[];

        
        this.paso                             = false;
        this.selectedRows = [];
        this.wms_externo_destino = "";
        this.wms_externo_origen = "";
        this.fecha_hoy='';
        this.precio1 = 0;
        this.entro = false;

        this.habilitarBtnInicar = this.habilitarBtnInicar.bind(this);
        this.servicioConsultar = this.servicioConsultar.bind(this);
        this.servicioEliminarTbTemp = this.servicioEliminarTbTemp.bind(this);
        this.successServicioEliminarTbTemp = this.successServicioEliminarTbTemp.bind(this);
        this.doc_origen = '';
        this.sucursal = '';
        this.validarItemsPendientesTraslado = this.validarItemsPendientesTraslado.bind(this);
        this.successValidarItemsPendientesTraslado = this.successValidarItemsPendientesTraslado.bind(this);
        
    }

    initForm() {
        console.log("Formulario CustomGrabacionTraslados,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez, @update: Sebastian Rios E.");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.sucursal = `${this.codigo_sucursal} ${JSON.parse(localStorage.getItem('sucursal_ingreso')).nombre}`;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.wms_externo_destino ="N";
        this.wms_externo_origen ="N";
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.creartemporalAjustes();
        this.sendCargarTabla();
        this.validartablaubicaciones();
        this.getField("nombre_bodega_origen").setOnChange(this.ubicacionbodegas);
        this.getField("nombre_bodega_destino").setOnChange(this.ubicacionbodegas2);
        this.getField('iniciar').setClick(this.consultar);
        this.getField('agregar_articulo').setClick(this.agregar_articulo);
        this.getField("doc_origen").setOnBlur(()=>{
            if(this.getField('bodega_destino').valid() && this.getField('nombre_bodega_destino').valid()){ // Se hace esta validación, ya que en escritorio cuando se setea una bodega destino, se bloquea el campo documento origen y no permite digitar ningún valor en dicho campo.
                this.aceptartransaccion();
                if (this.getField('doc_origen').getValue() === '') {
                    let items=0;
                    this.gridOptions['rowData'].forEach((element)=>{
                        if(element.codigo_articulo!=='.')
                            items++;
                    });
                    if (items > 0) {
                        this.getField('confirmModalCustom').setTitleAndContent('Traslados de Mercancía', `Traslado no maneja documento ORIGEN, ¿Desea limpiar la tabla?.`);
                        this.getField('confirmModalCustom').setClickDialog(() => {
                            this.getField('confirmModalCustom').toggle(false);
                            if (this.getField('consecutivo').getValue() !== '')
                                this.getField('agregar_articulo').setVisible(true);
                            this.servicioEliminarTbTemp();
                            this.sendCargarTabla();
                        });
                        this.getField('confirmModalCustom').setClickCancelDialog(() => {
                            this.getField("doc_origen").setValue(this.doc_origen);
                            this.getField('confirmModalCustom').toggle(false);
                        });
                        this.getField("confirmModalCustom").setVisibleCancel(false);
                        this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`);
                        this.getField("confirmModalCustom").setButtonCancel(`Cancelar`);
                        this.getField('confirmModalCustom').toggle(true);
                    }
                }else{
                    this.getField('agregar_articulo').setVisible(false);
                }
            }
        });
        this.getField('aceptar1').setClick(()=>{
            if(this.selectedRows.length>0){
                this.seleccionando_doc_origen=true;
                this.getField("bodega_origen").setValue(this.selectedRows[0].bodega_destino);
                // this.getField("valor").setValue(this.selectedRows[0].valor);
                //this.getField("detalle").setValue(this.selectedRows[0].detalle);
                this.getField("doc_origen").setValue(this.selectedRows[0].numero);
                
                this.validarItemsPendientesTraslado();
            }else
                this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
        });
        this.getField('aceptar4').setClick(this.agregardetalle);
        this.getField("cancelar2").setClick(()=>{this.selectedRows=[];this.getField('doc_origen').setValue('');this.getField('modal_transacciones').handleClose();});
        this.getField('ubicacionB').setClick(this.ubicaiones);
        this.getField('select_zona').setOnChange(this.cargarRack);
        this.getField('select_rack').setOnChange(this.cargarColumna);
        this.getField('select_columna').setOnChange(this.tipoubicacion);
        this.getField('aceptar').setClick(this.cerrarModalUbicaciones);
        this.getField('cancelar1').setClick(()=>{this.getField('modal_ubicacion').handleClose();});
        this.getField("nombre_articulo").setOnChange(()=>{
            if (!this.entro) {
                this.saldosubicacion();
            }
        });
        this.getField("cantidad").setOnBlur(()=>{
            if(this.iva_articulo === 'N'){
                this.xiva = 0;
            }else{
                this.xiva = this.getField('tarifa').getValue()
            }

            this.getField('valor_iva1').setValue(0);
            this.getField('valor_iva_unidad').setValue(0);
            this.getField('valor_unidad').setValue(0);
            this.getField('total_iva').setValue(0);
            this.getField('total_impuestos').setValue(0);
            this.getField('total_item').setValue(0);
            this.calcularvalores();
        });

        this.getField("costo").setOnBlur(this.calcularvalores);
        this.getField("impuesto").setOnBlur(this.calcularvalores);
        this.getField("cancelar").setClick(()=>{this.getField('modal_articulos').handleClose();});
        this.getField('grabar_producto').setClick(this.grabarArticulo);
        this.getField('grabar').setClick(this.grabarTraslado);
        this.getField('aceptar3').setClick(this.validarWmsExterno);
        this.getField('aceptar5').setClick(this.guardarRecibo);
        this.getField("cancelar3").setClick(()=>{this.getField('modal_wms_externo').handleClose();});
        this.getField("cancelar4").setClick(()=>{this.getField('modal_entrada').handleClose();this.limpiarTodo()});
        this.getField("cancelar5").setClick(()=>{this.getField('modal_recibo').handleClose()});
        this.getField("flete").setValue(0);
        this.getField('generar_archivo').setClick(()=>this.procesar());
        this.getField('modal_imprimir').setCloseButton(()=>{
            if (this.getField('doc_origen').getValue() !== '' && this.getField('doc_origen').getValue() !== '.' && 
            this.getField('doc_origen').getValue() !== undefined && this.getField('doc_origen').getValue() !== null) {
                this.actualizarDocOrigen();
            }else{
                this.limpiarTodo();
                this.initForm();
            }
        });
        // this.getField('modal_entrada').setCloseButton(this.limpiarTodo);

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });
        this.getField('valor').setOnChange(this.disabledCamposBodega);


        this.getField('bodega_origen').setOnChange(()=>{
            this.getField('nombre_bodega_origen').setError(false,'');
            this.limpiarCampos();
        });
        this.getField('bodega_destino').setOnChange(()=>{
            this.getField('nombre_bodega_destino').setError(false,'');
            this.limpiarCampos();
        });
        this.getField('doc_origen').setOnChange(()=>{
            this.getField('doc_origen').setError(false,'');
            if(this.seleccionando_doc_origen===false)
                this.selectedRows=[];
            
            // this.limpiarCampos();
        });
        this.getField('nit').setOnChange(()=>{
            this.getField('sede').setError(false,'');
            this.getField('nombre').setError(false,'');
            this.limpiarCampos();
        });
        this.gridOptions['rowData'] = [];

        this.getField('codigo_articulo').setOnChange((props)=>{

            this.getField('nombre_articulo').setValue('');
            this.getField('tarifa').setValue('');
            this.getField('descuento').setValue('0');
            this.getField('valor_iva1').setValue('0');
            this.getField('total_iva').setValue('0');
           
            this.getField('cantidad').setValue('0');
            this.getField('valor_iva_unidad').setValue('0');
            this.getField('total_impuestos').setValue('0');
            this.getField('costo').setValue('0');
            this.getField('valor_unidad').setValue('0');
            this.getField('total_item').setValue('0');
            this.getField('impuesto').setValue('0');
            this.getField('precio').setValue('0');

            this.getField('rejilla_bodega').toggle(false);
            this.getField('rejilla_ubicacion').toggle(false);

            this.getField('nombre_articulo').setError(false,'');
            this.getField('cantidad').setError(false,'');
            this.getField('costo').setError(false,'');
        });

        this.getField("codigo_articulo_aux").setOnChange(()=>{
            if(this.getField('codigo_articulo_aux').getValue()!==''){
                this.entro = false;
                this.validarCodigo();
            }
        });

        this.getField("transportadora").setOnChange(()=>{this.getField('nombre_transportadora').setError(false,'')});
        this.getField("numero_guia").setKeyUp(()=>{
            if(this.getField("numero_guia").getValue()!==''){
                if(!this.getField("numero_guia").valid()){
                    return;
                }
            }else{
                this.getField("numero_guia").setError(false,'');
            }
        });
        /* this.getField("transportadora").setKeyUp(()=>{
            if(this.getField("transportadora").getValue()!==''){
                if(!this.getField("transportadora").valid()){
                    return;
                }
            }else{
                this.getField("transportadora").setError(false,'');
            }
        }); */


        this.getField('modal_transacciones').setCloseButton(()=>{
            if(this.seleccionando_doc_origen===false){
                this.selectedRows=[];
                this.getField('doc_origen').setValue('');
            }
        });
        this.getField('consecutivo').setOnChange(this.habilitarBtnInicar);
    }

    validarItemsPendientesTraslado(){
        this.mostrarMensajeValidandoInformacion();
        if(this.getField("doc_origen").getValue() !== "" && this.getField("doc_origen").getValue() !== " "){
            this.doc_origen = this.getField("doc_origen").getValue();
            let datos = { datos: {
                doc_origen:this.getField("doc_origen").getValue(),
                numero:this.selectedRows[0].numero,
                fecha:this.selectedRows[0].fecha,
                sucursal:this.selectedRows[0].sucursal,
            }};
            if(this.getField("sucursal_wms").valid() && this.getField("numero_wms").valid()){
                datos.datos['bodega_origen'] = this.getField("bodega_origen").getValue();
                datos.datos['sucursal_wms'] = this.getField("sucursal_wms").getValue();
            }
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'validaritemspendientestraslado', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarItemsPendientesTraslado,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    successValidarItemsPendientesTraslado(data){
        if (data.estado_p === 200 ) {
            this.getField('confirmModalCustom').toggle(false);
            this.traerbodega();
        }else if(data.estado_p===404) {
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Traslados de mercancía', `${data.data.mensaje}`);
            this.getField('confirmModalCustom').setClickDialog(() => { 
                this.getField('confirmModalCustom').toggle(false); 
            });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        } else {
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores.mensaje);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
    }
    
    servicioEliminarTbTemp(){
        let datos = { datos: {
            codigo_usuario:this.codigo_usuario
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'eliminartbtemp', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'DELETE',
            body: datos,
            success: this.successServicioEliminarTbTemp,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successServicioEliminarTbTemp(data){
        this.gridOptions['rowData'] = [];
        this.getField('rejilla').initData(this.gridOptions);
        this.getField('rejilla').toggle(false);
        this.getField('detalle').setValue('');
        this.getField('valor').setValue('');
    }
    
    servicioConsultar(){
        let datos = { datos: {
            sucursal:this.codigo_sucursal,
            codigo_usuario:this.codigo_usuario/* ,
            bodega_destino:this.getField("bodega_destino").getValue() */
        }};

        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'consultar', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successConsultar,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }
    
    habilitarBtnInicar(){
        if (this.getField('consecutivo').getValue() !== '') {
            this.getField('iniciar').setDisabled(true);
        }else{
            this.getField('iniciar').setDisabled(false);
            this.gridOptions['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptions);
            this.getField('rejilla').toggle(false);
        }
    }
    
    traerPropiedades(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'comp-trasladosinventario', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
        }
    
    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales=data.data.numero_decimales;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    validarCodigo(){
        if(this.getField("codigo_articulo").valid()){
            this.mostrarMensajeValidandoInformacion();
            let datos = { datos: {
                codigo_articulo:this.getField("codigo_articulo").getValue(),
                sucursal_ingreso:this.codigo_sucursal,
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'codigoauxiliar', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessValidarCodigo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
       }
     }
   
     sucessValidarCodigo(data){
        this.getField('confirmModalCustom').toggle(false);
         if (data.estado_p === 200 )
         {
             this.getField('codigo_articulo').setValue(data.data.codigo);
             this.traerInfoArticulo();
         }else {
             if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
             }
             else {
               let respuesta=Object.values(data.data.errores);
               this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
             }
         }
     }

     traerInfoArticulo(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
          value: this.getField('codigo_articulo').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '42', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerArticulo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
      }
        
    sucessTraerArticulo(data){
        if(data.estado_p === 200){ 
            this.getField('nombre_articulo').setValue(data.data[0].nombre);
            this.getField('tarifa').setValue(data.data[0].vivac);

            this.getField('codigoA').setValue(data.data[0].codigo_interno);
            //this.getField('contenido').setValue(data.data[0].contenido);
            //this.getField('presentacion').setValue(data.data[0].presenta);
            this.precio1=data.data[0].precio1;
            let decimal = (this.precio1 + "").split(".")[1];//para manejar los decimales
              if ((decimal !== 0) && (decimal !== undefined)) {
                if(decimal.length>this.numero_decimales)
                this.precio1=Number(this.precio1).toFixed(this.numero_decimales);
            }
            this.getField('precio').setValue(Number(this.precio1));
            //this.getField('impuestos2').setValue(data.data[0].impuesto1);
            this.getField('costo').setValue(data.data[0].costo);
            this.getField('descuento').setValue(0);
            this.actualizarcosto();
        }else if(data.estado_p === 404){
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
        }
        else{
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    limpiarCampos(){
        this.getField('flete').setError(false,'');
        this.getField('consecutivo').setError(false,'');
        this.getField('detalle').setError(false,'');
        this.getField('valor').setError(false,'');
        this.getField('numero_guia').setError(false,'');
        this.getField('fecha_guia').setError(false,'');
        this.getField('transportadora').setError(false,'');
        this.getField('nombre_transportadora').setError(false,'');
        this.getField('agregar_articulo').setVisible(false);

        //liimpiar campos al cambiar los buscadores principales
        this.getField('flete').setValue(0);
        this.getField('consecutivo').setValue('');
        this.getField('detalle').setValue('');
        this.getField('valor').setValue(0);
        this.getField('numero_guia').setValue('');
        this.getField('fecha_guia').setValue(this.fecha_hoy);
        this.getField('transportadora').setValue('');
        this.getField('nombre_transportadora').setValue('');

        //limpiar tabla
        let items=0;
        this.gridOptions['rowData'].forEach((element)=>{
            if(element.codigo_articulo!=='.')
                items++;
        });
        if(this.limpiando_tabla===false && items>0){
            this.limpiando_tabla=true;
            this.creartemporalAjustes();
        }

    }

    onSelectionChanged(){
        //nothing
    }

    onSelectionChangedPrincipal(){
        this.row_principal = this.gridOptions.api.getSelectedRows();
    }

    disabledCamposBodega(){
        if (this.getField('valor').getValue() !== '' && this.getField('valor').getValue() !== ' ' && this.getField('valor').getValue() > 0) {
            this.getField('bodega_origen').setDisabled(true);
            this.getField('bodega_destino').setDisabled(true);
            this.getField('valor').setError(false, '');
        }else{
            this.getField('bodega_origen').setDisabled(false);
            this.getField('bodega_destino').setDisabled(false);
        }
    }
    
    /**
     * Se crea esta función ya que este servicio se está consumiendo en muchas partes del código, y en pro a optimizar líneas de código,
     * se reduce a tener dicha petición en una función.
     */
    sendCargarTabla(){
        this.mostrarMensajeGenerando();
        let datos = { datos: {
            codigo_usuario:this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'cargartabla', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successMostrarTabla,
            error: this.error_,
            general: this.generalFormatPmv
        });    
    }
    
    agregardetalle(){
        if(this.getField("numero_entrada").getValue() !== ""){
            this.mostrarMensajeGenerando();
            this.getField('detalle').setValue((this.selectedRows.length>0?this.selectedRows[0].detalle+" ":"")+this.getField('detalle').getValue()+" Entrada: "+this.getField("numero_entrada").getValue());
            let datos = { datos: {
                laentrada:this.getField("numero_entrada").getValue(),
                codigo_usuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'cargartraslado', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCargarTraslado,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successCargarTraslado(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            
            this.getField('modal_entrada').handleClose();
            if (data.data.length >= 1) {
                this.getField('modal_informativo').handleClickOpen();
                this.getField('rejilla_informativa').toggle(true);
                let configCell = new Map();
                this.gridOptionsRejillaInformativa['rowData'] = data.data;
                this.getField('rejilla_informativa').initData(this.gridOptionsRejillaInformativa, configCell);
            }
            this.paso=false;
            //this.sendCargarTabla();
            this.limpiando_tabla=true;
            //this.creartemporalAjustes();
            this.refrescar();
            this.getField('bodega_origen').setDisabled(true);
            this.sendCargarTabla();
        }else if(data.estado_p === 404){
            this.limpiando_tabla=false;
            //this.alertGeneral.toggle(true, 'No hay datos.', 'error'); 
        }else {
            this.limpiando_tabla=false;
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    validarflete(){
        if (this.getField("flete").getValue() !== null) {
            if (this.getField("flete").getValue() < 0) {
                this.alertGeneral.toggle(true, "El valor del flete debe ser mayor o igual a cero.",'error');
                return false;
            }

            if (this.getField("transito_origen").getValue() != null && this.getField("transito_destino").getValue() != null 
            && ((this.getField("transito_origen").getValue() === "S" && this.getField("transito_destino").getValue() === "S") 
            || (this.getField("transito_origen").getValue() === "N" && this.getField("transito_destino").getValue() ===  "N") 
            || (this.getField("transito_origen").getValue() === "N" && this.getField("transito_destino").getValue() ===  "N"))
            && this.getField("flete").getValue() > 0) {
                this.alertGeneral.toggle(true, "La bodega de origen debe ser de transito y la bodega destino NO debe ser de transito, el valor del flete debe ser igual a cero. ", 'error');
                return false;
            }
        }
        return true;
    }

    creartemporalAjustes(){
        let wms='N'
        if(this.wms_externo_origen === "S" && this.wms_externo_destino === "S")
            wms='S';
        /*if(this.wms_externo_origen === "S" && this.wms_externo_destino === "S"){
            let datos = { datos: {
                numero: this.numero_wms, 
                wmsdatos: "S", 
                datoswms:this.datos_wms,
                codigo_sucursal: this.selectedRows.length === 0 ? "": this.selectedRows[0].codigo_sucursal,
                fecha: this.selectedRows.length === 0 ? "":this.selectedRows[0].fecha,
                codigo_usuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'creartemporal', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successCrearTemporal,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            
            });
        }*/
        let datos = { datos: {
            sucursal:this.codigo_sucursal,
            codigo_usuario:this.codigo_usuario,
            wmsdatos: wms,
            datoswms:this.datos_wms,
            numero: this.numero_wms, 
            codigo_sucursal: this.selectedRows.length === 0 ? "": this.selectedRows[0].sucursal,
            fecha: this.selectedRows.length === 0 ? "":this.selectedRows[0].fecha,
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'creartemporal', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successCrearTemporal,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successCrearTemporal(data){
        if (data.estado_p === 200 )
        {
            
            this.getField('modal_wms_externo').handleClose();
            /*if(this.wms_externo_origen === "S" && this.wms_externo_destino === "S"){
                this.getField('rejilla_wms').toggle(true);
                this.getField('rejilla').toggle(false);
                let configCell = new Map();
                this.gridOptions['rowData'] = data.data;
                configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('impuestos', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.impuestos) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});

                configCell.set('cantidad', { cellRenderer: function (props) { return props.data.cantidad }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.consultaCantidadItem, sortable: true, filter: false, field: 'cantidad' });
                configCell.set('eliminar', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
                this.getField('rejilla_wms').initData(this.gridOptions, configCell);
                this.limpiando_tabla=false;
                this.datos_wms=[];
            }else{
                this.sendCargarTabla();
                this.refrescar();
            }*/
            // this.sendCargarTabla();
            this.refrescar();
            this.datos_wms=[];
        }else {
            this.gridOptions['rowData'] = [];
            this.getField('rejilla_wms').toggle(false);
            this.getField('rejilla').toggle(false);
            if (data.data.errores !== undefined && data.data.errores !== null) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    successMostrarTabla(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p===200){
            this.getField('rejilla_wms').toggle(false);
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptions['rowData'] = data.data;
            configCell.set('cantidad', { cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.consultaCantidadItem, sortable: true, filter: false, field: 'cantidad' });
            configCell.set('eliminar', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"}});
            configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"}});
            configCell.set('subtotal1', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal1) }, cellStyle: {textAlign:"right"}});
            configCell.set('subtotal2', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.subtotal2) }, cellStyle: {textAlign:"right"}});
            configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"}});
            configCell.set('valor_flete', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_flete) }, cellStyle: {textAlign:"right"}});
            this.getField('rejilla').initData(this.gridOptions, configCell);
            if(this.pantalla === "TRASLADOS"){
                this.refrescar();
            }
        }else {
            if(data.estado_p===404) {
                this.getField('rejilla').toggle(false);
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }
    
    setButtonDelete(props) {
        if(props.data.codigo_articulo !== "."){
            let codigo_articulo = props.data.codigo_articulo;
            let button = document.createElement("input");
            button.onclick = () => this.eliminar(codigo_articulo);
            button.setAttribute("codigo_articulo", 'button_delete_' + codigo_articulo);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("type", "button");
            button.setAttribute("value", "eliminar");
            return this.createElementJaivana(button);
        }else{

        }
    }

    eliminar(codigo_articulo) {
        this.codigo_articulo = codigo_articulo;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(()=>{
            this.getField('confirmModalCustom').toggle(false); 
            this.confirmDelete();
        });
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDelete() {
        let datos = {
            datos: {
                codigo_articulo: this.codigo_articulo,
                codigo_usuario:this.codigo_usuario
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'eliminar_articulo', operacion_tipo: "eliminar" };// parametros del servicio
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv //se envia el generales de arriba
        });
    }

    successEliminar(data) {
        if(data.estado_p === 200){
            this.gridOptions['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptions);
            this.getField('rejilla').toggle(false);
            this.sendCargarTabla();
            this.refrescar();
        }else{
          let keys=Object.keys(data.data.mensaje);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
        }
    }

    consultaCantidadItem(props){
        if (props.data.codigo_articulo==='.')
            return;
        let seguir = this.getField('rejilla').cellValid(props.oldValue, props.newValue, /^(\d{1,7})(\.\d{1,2})?$/);

        if (isNaN(props.data.cantidad) || seguir===false) {
            if(props.newValue > 9999999.99){

                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `El valor debe estar entre las longitudes (1,9999999.99)`);
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.sendCargarTabla();
                    this.refrescar();
                    this.getField('confirmModalCustom').toggle(false); 
                });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }else{

                // console.log('props:\n',props);
                // this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `La columna cantidad no tiene un formato correspondiente al tipo (Numérico)`);
                this.getField('confirmModalCustom').setTitleAndContent('Formato Incorrecto', `El valor: "${props.newValue}" ingresado en la columna cantidad no tiene un formato correspondiente al tipo (Numérico)`);
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.sendCargarTabla();
                    this.refrescar();
                    this.getField('confirmModalCustom').toggle(false); 
                });
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setButtonConfirm(`Ok`);
                this.getField('confirmModalCustom').toggle(true);
            }
        }else if(seguir){
            this.mostrarMensajeValidandoInformacion();
            if(this.selectedRows.length>0){
                let datos = { datos: {
                    numero: this.selectedRows[0].numero,
                    fecha: this.selectedRows[0].fecha,
                    codigo_sucursal: this.selectedRows[0].sucursal,
                    codigo_articulo: props.data.codigo_articulo
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'cantidaditem', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: (data)=>{
                        this.successConsultaCantidadItem(data,props);
                    },
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }else{
                this.cantidadtablaChange(props);
            }
        }
    }

    successConsultaCantidadItem(data,props){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p=== 200){
            if (Number(props.newValue) > Number(data.data.total)) {
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', `El artículo ${props.data.nombre} sólo tiene pendiente una cantidad de ${data.data.total}. ¿Desea continuar?`);
                this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.cantidadtablaChange(props);
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField('confirmModalCustom').setClickCancelDialog(() => {
                    this.sendCargarTabla();
                    this.refrescar();
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.cantidadtablaChange(props);
            }
        }else if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No encontró cantidad de ítems.', 'error');
        } else {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
    
    cantidadtablaChange(props) {
        this.mostrarMensajeGuardando();
        let codigo_articulo = props.data.codigo_articulo;
        let valor_actual = props.newValue;
        let ubicacion = props.data.ubicacion;
        let bodega = this.getField("bodega_origen").getValue();
        let datos = { datos: {
            codigo_usuario:this.codigo_usuario,
            codigo_articulo:codigo_articulo,
            cantidad: parseFloat(valor_actual),
            ubicacion: ubicacion,
            bodega: bodega,
            sucursal: this.codigo_sucursal,
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'modificarcantidadtabla', operacion_tipo: 'modificar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'PUT',
            body: datos,
            success: this.successActualizarCantidad,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }
    
    successActualizarCantidad(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.gridOptions['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptions);
            this.getField('rejilla').toggle(false);
            this.sendCargarTabla();
            this.refrescar();
        }else if(data.estado_p===404) {
            this.getField('confirmModalCustom').setTitleAndContent('Traslados', `${data.data.mensaje}`);
            this.getField('confirmModalCustom').setClickDialog(() => { 
                this.getField('confirmModalCustom').toggle(false); 
                this.sendCargarTabla();
                this.refrescar();
            });
            this.getField("confirmModalCustom").setVisibleCancel(true); 
            this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setButtonConfirm("OK");
            this.getField('confirmModalCustom').toggle(true);
        }else{
          let keys=Object.keys(data.data.mensaje);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
        }
    }

    validartablaubicaciones(){
        this.mostrarMensajeGenerando();
        let datos = { datos: {}};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'tablaubicaciones', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successAjuste,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successAjuste(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            if(data.data[0].existe === 1){
                // console.log("existe es igual a 1");
                this.pantalla = 'TRASLADOSUBICACION';
                this.ubicacionbodegas();
                this.habilitarcampos();
            }else if(data.data[0].existe === 0){
                // console.log("existe es igual a 0");
                this.pantalla = 'TRASLADOS';
                this.getField("numero_guia").setDisabled(true);
                this.getField("fecha_guia").setDisabled(true);
                this.getField("transportadora").setDisabled(true);
                this.getField("nombre_transportadora").setDisabled(true);
                this.validartablatransaccion();
            }

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    habilitarcampos(){
        if(this.pantalla === "TRASLADOSUBICACION"){
            // console.log("TRASLADOSUBICACION");
            this.getField("concepto_cod").setDisabled(true);
            this.getField("nombre_c").setDisabled(true);
        }else{
            this.getField("concepto_cod").setDisabled(false);
            this.getField("nombre_c").setDisabled(false);
            this.getField("flete").setDisabled(true);
            this.getField("numero_guia").setDisabled(true);
            this.getField("fecha_guia").setDisabled(true);
            this.getField("transportadora").setDisabled(true);
            this.getField("transportadora").setDisabled(true);
            this.getField("nombre_transportadora").setDisabled(true);

        }
    }

    ubicacionbodegas(){
        if(this.getField("nombre_bodega_origen").getValue() !== "" ){
            this.getField("nombre_bodega_origen").setError(false,'');
            if(this.getField("bodega_origen").getValue() !== this.getField("bodega_destino").getValue()){
                this.mostrarMensajeGenerando();
                let datos = { datos: {
                    sucursal:this.codigo_sucursal,
                    bodega:this.getField("bodega_origen").getValue(),
                    bodega_destino:this.getField("bodega_destino").getValue(),
                    pantalla:this.pantalla
                }};
    
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'ubicacionbodegas', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successAjusteubicacion,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }else{
                this.alertGeneral.toggle(true, 'Las bodegas no pueden ser iguales.', 'error');
                this.getField('bodega_origen').setValue('');
                this.getField('nombre_bodega_origen').setValue('');
            }
        }
    }

    successAjusteubicacion(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {

            if(this.paso){
                this.traeritem();
                if(data.data.obligaentrada === "S"){
                    this.getField('modal_entrada').handleClickOpen();
                }else{
                    this.sendCargarTabla();
                }
                this.paso = false;
            }else{
                this.wms_externo_origen = data.data.wms_externo;
            }
        }else{
            this.seleccionando_doc_origen=false;
            if(data.estado_p===404) {
                if(this.paso){
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La bodega ' + this.getField("bodega_origen").getValue() + ' -  ' + this.getField("nombre_bodega_origen").getValue() + ' '+data.data.errores.mensaje);
                    this.getField('confirmModalCustom').setClickDialog(() => { 
                        this.getField('doc_origen').setValue(''); 
                        this.getField('detalle').setValue('');
                        this.getField('valor').setValue('');
                        if (this.getField('consecutivo').getValue() !== '')
                            this.getField('agregar_articulo').setVisible(true);
                        this.getField('confirmModalCustom').toggle(false); 
                    });
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);

                }else{
                    //this.alertGeneral.toggle(true, 'La bodega ' + this.getField("bodega_origen").getValue() + ' -  ' + this.getField("nombre_bodega_origen").getValue() + ' '+data.data.errores.mensaje, 'error');
                    this.getField("nombre_bodega_origen").setValue("");
                    this.getField("bodega_origen").setValue("");
                    this.mostrarMensajeAdicional('La bodega ' + this.getField("bodega_origen").getValue() + ' -  ' + this.getField("nombre_bodega_origen").getValue() + ' '+data.data.errores.mensaje);
                }
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    ubicacionbodegas2(){
        if(this.getField("nombre_bodega_destino").getValue() !== "" ){
            this.getField("nombre_bodega_destino").setError(false,'');
            if(this.getField("bodega_destino").getValue() !== this.getField("bodega_origen").getValue()){
                this.mostrarMensajeGenerando();
                let datos = { datos: {
                    sucursal:this.codigo_sucursal,
                    bodega:this.getField("bodega_destino").getValue(),
                    bodega_destino:this.getField("bodega_destino").getValue(),
                    pantalla:this.pantalla
                }};
    
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'ubicacionbodegas', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successAjusteubicacion2,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }else{
                this.alertGeneral.toggle(true, 'Las bodegas no pueden ser iguales.', 'error');
                this.getField('bodega_origen').setValue('');
                this.getField('nombre_bodega_origen').setValue('');
            }
        }else{
            this.getField('doc_origen').setDisabled(false);
        }
    }

    successAjusteubicacion2(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            this.wms_externo_destino = data.data.wms_externo;
            //if(this.pantalla === "TRASLADOSUBICACION" && this.wms_externo_destino === "S" && this.wms_externo_origen === "S"){
            if(this.pantalla === "TRASLADOSUBICACION" && this.wms_externo_destino === "S"){
                this.getField('modal_wms_externo').handleClickOpen();
                this.getField('doc_origen').setDisabled(true);
            }else{
                this.getField('doc_origen').setDisabled(false);
            }
        }else{
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La bodega ' + this.getField("bodega_destino").getValue() + ' -  ' + this.getField("nombre_bodega_destino").getValue() + ' '+data.data.errores.mensaje, 'error');
                this.getField("nombre_bodega_destino").setValue("");
                this.getField("bodega_destino").setValue("");
                this.getField('detalle').setValue('');
                this.getField('valor').setValue('');
                if (this.getField('consecutivo').getValue() !== '')
                    this.getField('agregar_articulo').setVisible(true);
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    validartablatransaccion(){
        let datos = { datos: {}};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'tablatransaccion', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarTransaccion,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successValidarTransaccion(data){
        if (data.estado_p === 200 )
        {
            if(data.data[0].existe === 1){
                this.getField('rejilla').toggle(true);
            }
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }
    
    aceptartransaccion(){
        if(this.getField("doc_origen").getValue() !== "" && this.getField("doc_origen").getValue() !== " "){
            this.doc_origen = this.getField("doc_origen").getValue();
            let datos = { datos: {
                doc_origen:this.getField("doc_origen").getValue(),
                pantalla:this.pantalla
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'traerdocumenorigen', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successtraerdoc,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successtraerdoc(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            if(data.data.length === 1){
                this.getField('modal_wms_externo').handleClose();
                this.getField("doc_origen").setValue("");
                this.getField("doc_origen").setValue(data.data[0].numero);
                this.getField("bodega_origen").setValue(data.data[0].bodega_destino);
                this.getField("nit").setValue(data.data[0].nit);
                this.getField("sede").setValue(data.data[0].sede);
                this.getField("nombre").setValue(data.data[0].nombre);
                // this.getField("valor").setValue(data.data[0].valor);
                this.getField("detalle").setValue(data.data[0].detalle);
                this.seleccionando_doc_origen=true;//se selecciona el que éxiste
                this.selectedRows = [
                    {
                        "numero": data.data[0].numero,
                        "fecha": data.data[0].fecha,
                        "valor": data.data[0].valor,
                        "bodega_origen": data.data[0].bodega_origen,
                        "bodega_destino": data.data[0].bodega_destino,
                        "usuario": data.data[0].usuario,
                        "sucursal": data.data[0].codigo_sucursal,
                        "h_sistema": data.data[0].h_sistema,
                        "contable": data.data[0].contable,
                        "costo_promedio": data.data[0].costo_promedio,
                        "detalle": data.data[0].detalle,
                        "fecha_sistema": data.data[0].fecha_sistema,
                        "nit": data.data[0].nit,
                        "sede": data.data[0].sede,
                        "estado": data.data[0].estado,
                        "codigo_concepto": data.data[0].codigo_concepto,
                        "doc_origen": data.data[0].doc_origen,
                        "fecha_doc_origen": data.data[0].fecha_doc_origen,
                        "suc_doc_origen": data.data[0].suc_doc_origen,
                        "peso": data.data[0].peso,
                        "pedido": data.data[0].pedido,
                        "id": data.data[0].id,
                        "ciudad_entrega": data.data[0].ciudad_entrega,
                        "direccion_entrega": data.data[0].direccion_entrega,
                        "tipo_pedido": data.data[0].tipo_pedido,
                        "aviso_de_recibo": data.data[0].aviso_de_recibo,
                        "ficha": data.data[0].ficha,
                        "valor_flete": data.data[0].valor_flete,
                        "fecha_guia": data.data[0].fecha_guia,
                        "guia": data.data[0].guia,
                        "nit_transportador": data.data[0].nit_transportador,
                    }
                ];
                this.validarItemsPendientesTraslado();
            }else if(data.data.length > 1){
                this.seleccionando_doc_origen=false;//se inicializa el falso
                this.getField('modal_wms_externo').handleClose();
                this.getField('modal_transacciones').handleClickOpen();
                this.getField("doc_origen").setValue("");
                let configCell = new Map();
                this.gridOptionsComponentes['rowData'] = data.data;
                this.gridOptionsComponentes['onSelectionChanged'] = ()=>{
                    this.selectedRows = [];
                    //this.seleccionando_doc_origen=true;
                    this.selectedRows = this.gridOptionsComponentes.api.getSelectedRows();
                };
                configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor)}, cellStyle: {  textAlign: "right"}});
                this.getField('rejilla_documento').toggle(true);
                // this.gridOptionsComponentes.enableCellTextSelection = true;
                this.getField('rejilla_documento').initData(this.gridOptionsComponentes, configCell);
                
            }
            this.paso=true;
        }else {
            this.seleccionando_doc_origen=false;//se inicializa el falso
            if(data.estado_p===404) {
                // this.alertGeneral.toggle(true, 'No hay datos.', 'error');
                this.getField('confirmModalCustom').setTitleAndContent('Traslados de mercancía', `${data.data.mensaje}`);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('doc_origen').setValue(''); 
                    if (this.getField('consecutivo').getValue() !== ''){
                        this.servicioEliminarTbTemp();
                        this.sendCargarTabla();
                        this.getField('agregar_articulo').setVisible(true); 
                    }
                    this.getField('confirmModalCustom').toggle(false); 
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traerbodega(){
        this.mostrarMensajeGenerando();
        let datos = { datos: {
            value:this.getField("bodega_origen").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-bodegas', operacion: '42_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successTraerBodega,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successTraerBodega(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            //esto porque despues va y refresca la tabla y se consumiria doble
            this.limpiando_tabla=true;
            
            this.getField("bodega_origen").setError(false,"");
            this.getField("transito_origen").setValue(data.data[0].transito);
            if(this.getField('nombre').getValue()!=='')
                this.consultar();
            this.getField("nombre_bodega_origen").setValue(data.data[0].nombre_bodega);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }


    traeritem(){
        this.creartemporalAjustes();
        this.getField('modal_transacciones').handleClose();
        this.gridOptions['rowData'] = [];
        this.getField('rejilla').initData(this.gridOptions);
        this.getField('rejilla').toggle(false);

        // Esta validación se comenta porque en escritorio no existe en la lógica desde el campo "documento origen" que restrinja "wms_externo_origen" y "wms_externo_destino", la función en escritorio que hace la inserción a la tabla se llama "cargarTraslado" en la clase "TrasladoUbicacion"
        // if(this.wms_externo_origen !== "S" && this.wms_externo_destino !== "S"){
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario,
                sucursal:this.selectedRows[0].sucursal,
                numero:this.getField("doc_origen").getValue(),
                fecha:this.selectedRows[0].fecha,
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'cargararticulo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successCrearTemporal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        // }
    }

    consultar(){
        if(this.pantalla === "TRASLADOS"){
            if(this.getField("bodega_origen").valid() && this.getField("nombre_bodega_origen").valid() && this.getField("bodega_destino").valid()
              && this.getField("nombre_bodega_destino").valid() && this.getField("concepto_cod").valid() && 
              this.getField("nombre_c").valid() && this.getField("nit").valid() && this.getField("sede").valid() && this.getField("nombre").valid()){
                this.mostrarMensajeGenerando();
                this.servicioConsultar();
            }else{
                this.alertGeneral.toggle(true, 'Debe diligenciar todos los campos ', 'error');
            }
        }else{
            if(this.getField("bodega_origen").valid() && this.getField("nombre_bodega_origen").valid() /* && this.getField("bodega_destino").valid()
            && this.getField("nombre_bodega_destino").valid() && this.getField("nit").valid() && this.getField("sede").valid() && this.getField("nombre").valid() */){
                this.mostrarMensajeGenerando();
                this.servicioConsultar();
            }else{
                this.alertGeneral.toggle(true, 'Debe diligenciar todos los campos ', 'error');
            }
        }
    }

    successConsultar(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            if(this.pantalla === "TRASLADOS"){
                this.getField("consecutivo").setValue(data.data.numero);
                this.getField("agregar_articulo").setVisible(true);
            }else{
                this.getField("consecutivo").setValue(data.data.numero);
                if(this.getField("doc_origen").getValue() === ""){
                    this.getField("agregar_articulo").setVisible(true);
                }
            }
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }
/////////////////// agregar articulo //////////////////
    limpiarCamposMAgregarArticulo(){
        this.getField('codigo_articulo').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('tarifa').setValue('');
        this.getField('descuento').setValue('');
        this.getField('valor_iva1').setValue('');
        this.getField('total_iva').setValue('');
        this.getField('cantidad').setValue('');
        this.getField('valor_iva_unidad').setValue('');
        this.getField('total_impuestos').setValue('');
        this.getField('costo').setValue('');
        this.getField('valor_unidad').setValue('');
        this.getField('total_item').setValue('');
        this.getField('impuesto').setValue('');
        this.getField('ubicacion').setValue('');
        this.getField('precio').setValue('');

        this.getField('codigo_articulo').setError(false,'');
        this.getField('nombre_articulo').setError(false,'');
        this.getField('tarifa').setError(false,'');
        this.getField('descuento').setError(false,'');
        this.getField('valor_iva1').setError(false,'');
        this.getField('total_iva').setError(false,'');
        this.getField('cantidad').setError(false,'');
        this.getField('valor_iva_unidad').setError(false,'');
        this.getField('total_impuestos').setError(false,'');
        this.getField('costo').setError(false,'');
        this.getField('valor_unidad').setError(false,'');
        this.getField('total_item').setError(false,'');
        this.getField('impuesto').setError(false,'');
        this.getField('ubicacion').setError(false,'');
        this.getField('precio').setError(false,'');
    }

    agregar_articulo(){
        this.entro = false;
        this.doc_origen = '';
        if (!(this.getField('doc_origen').getValue() !== '')) {
            this.getField('codigo_articulo').setDisabled(false);
            if(this.pantalla === "TRASLADOS"){
                if(this.getField("bodega_origen").valid() && this.getField("nombre_bodega_origen").valid() && 
                this.getField("bodega_destino").valid() && this.getField("nombre_bodega_destino").valid() && this.getField("concepto_cod").valid()
                && this.getField("nombre_c").valid() && this.getField("nit").valid() && this.getField("sede").valid() && this.getField("nombre").valid()){
                    this.getField('modal_articulos').handleClickOpen();
                }
            }else{ 
                this.limpiarCamposMAgregarArticulo();
                this.getField('modal_articulos').handleClickOpen();
                this.getField("descuento").setDisabled(true);
                this.getField("impuesto").setDisabled(true);
                this.getField("impuesto").setValue(0);
                this.getField("descuento").setValue(0);
    
    
                let datos = { datos: {
                    sucursal:this.codigo_sucursal,
                    bodega:this.getField("bodega_origen").getValue()
                }};
    
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'wms', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successWms,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }
        }else{
            this.getField('confirmModalCustom').setTitleAndContent('Traslados de Mercancía', `Traslado maneja documento ORIGEN, no puede AGREGAR.`);
            this.getField('confirmModalCustom').setClickDialog(() => {
                this.getField('confirmModalCustom').toggle(false); 
            });
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`);
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    successWms(data){
        if (data.estado_p === 200 )
        {
            if(data.data.ubicacion!==undefined){
                this.pide_ubicacion=true;
                this.getField('ubicacionB').setVisible(true);
                this.getField('ubicacion').setDisabled(true);
            }else{
                this.pide_ubicacion=false;
                this.getField('ubicacionB').setVisible(false);
                this.getField('ubicacion').setValue('999999999999');
            }
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    ubicaiones(){
        this.getField('modal_ubicacion').handleClickOpen();
        let datos = { datos: {
            sucursal:this.codigo_sucursal
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarsucursal', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successUbicacionesWms,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successUbicacionesWms(data){
        if (data.estado_p === 200 )
        {
            this.idsucursal=data.data[0].id;
            this.getField('select_sucursal').setValue(data.data[0].nombre);
            this.getField('select_sucursal').setDisabled(true);
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField("bodega_origen").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarbodega', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successBodegaWms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    successBodegaWms(data){
        if (data.estado_p === 200 )
        {
            this.getField('select_bodega').setValue(data.data[0].nombre);
            this.getField('select_bodega').setDisabled(true);
            let datos = { datos: {
                id:14//this.idsucursal
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarzona', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successZonaWms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    successZonaWms(data){
        if (data.estado_p === 200 )
        {

            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.nombre;
                dataOp['campos_cambian'] = { zona: item.codigo};
                opciones.push(dataOp);
            });
            this.getField('select_zona').setOptions(opciones);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    cargarRack(){
        if(this.getField("select_zona").getValue() !== ""){
            let datos = { datos: {
                select_zona:this.getField("select_zona").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarrack', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successRackwms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successRackwms(data) {
        if (data.estado_p === 200 )
        {
            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.numero;
                dataOp['campos_cambian'] = { rack: item.numero };
                opciones.push(dataOp);
            });
            this.getField('select_rack').setOptions(opciones);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    cargarColumna(){
        if(this.getField("select_rack").getValue() !== ""){
            let datos = { datos: {
                select_rack:this.getField("select_rack").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'cargarcolumna', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successColumnawms,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successColumnawms(data){
        if (data.estado_p === 200 )
        {
            this.wms_nuevo_rack_id=data.data[0].wms_nuevo_rack_id;
            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.columna_numero;
                dataOp['text'] = item.columna_numero;
                dataOp['campos_cambian'] = { columna: item.columna_numero };
                opciones.push(dataOp);
            });
            this.getField('select_columna').setOptions(opciones);

            let data2 ={"data": []};

            for (let index = 1; index < data.data[0].niveles; index++) {
                data2.data.push(index)
            }

            let opciones2 = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data2.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item;
                dataOp['text'] = item;
                dataOp['campos_cambian'] = { nivel:item};
                opciones2.push(dataOp);
            });
            this.getField('select_nivel').setOptions(opciones2);
            
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    tipoubicacion(){
        if(this.getField("select_columna").getValue() !== ""){
            let datos = { datos: {
                id:this.wms_nuevo_rack_id,
                select_columna:this.getField("select_columna").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-ajustesinventario', operacion: 'tipoubicacion', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTipoUbicacion,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successTipoUbicacion(data){
        if (data.estado_p === 200 )
        {
            this.getField('select_ubicacion').setValue(data.data[0].tipo);
            this.getField('clase').setValue(data.data[0].tipo);
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    cerrarModalUbicaciones(){
        if(this.getField('zona').getValue() !== "" && this.getField('rack').getValue() !== "" && this.getField('columna').getValue() !== ""
         && this.getField('select_nivel').getValue() !== ""){
            let ubicacion_codigo =  this.getField('zona').getValue() + "-" + this.getField('bodega_origen').getValue() + "-" + this.getField('rack').getValue() + "-" + this.getField('columna').getValue() + "-" + this.getField('select_nivel').getValue();
            this.getField('modal_ubicacion').handleClose();
            this.getField('ubicacion').setValue(ubicacion_codigo);
        }else{
            this.alertGeneral.toggle(true, 'Faltan datos.', 'error');
        }
    }

    saldosubicacion(){
        if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").getValue() !== '' && this.getField("nombre_articulo").getValue() !== ' '){
            this.traersaldoBodegas();
            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField("bodega_origen").getValue(),
                codigo_articulo:this.getField("codigo_articulo").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'traersaldosubicacion', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successSaldosUbicacion,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    successSaldosUbicacion(data){
        
        if (data.estado_p === 200 )
        {
            this.traercodigo();
            let configCell = new Map();
            this.gridOptionsUbicacionesSaldos['rowData'] = [];
            this.getField('rejilla_ubicacion').initData(this.gridOptionsUbicacionesSaldos);
            this.getField('rejilla_ubicacion').toggle(false);
            let datos=[];
            data.data.forEach(element => {
                let arreglo=
                {
                    ubicacion: element.ubicacion,
                    saldo: element.cantidad === undefined ? element.saldo : element.cantidad,
                    cantidad_reservada: element.cantidad_reservada,
                }
        
                datos.push(arreglo);
            });

            this.gridOptionsUbicacionesSaldos['rowData'] = [];
            this.getField('rejilla_ubicacion').initData(this.gridOptionsUbicacionesSaldos);
            this.getField('rejilla_ubicacion').toggle(false);

            this.gridOptionsUbicacionesSaldos['rowData'] = datos;
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla_ubicacion').toggle(true);
            this.getField('rejilla_ubicacion').initData(this.gridOptionsUbicacionesSaldos, configCell);
        }else {
            this.traercodigo();
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos de ubicación.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    traersaldoBodegas(){
        this.mostrarMensajeGenerando();
        let datos = { datos: {
            bodega:this.getField("bodega_origen").getValue(),
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'traersaldosbodegas', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successSaldosBodegas,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successSaldosBodegas(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            let datos=[]
            data.data.bodega.forEach(element => {
            let arreglo=
                {
                    bodega: element.bodega,
                    saldo: element.saldo
                }
            
                datos.push(arreglo);
            });


            let arraybodegas = Object.entries(data.data.bodegas[0]);
            arraybodegas.forEach(element => {
                let arreglo=
                {
                    bodega: element[0],
                    saldo: element[1]
                }
            
                datos.push(arreglo);
            });
            let configCell = new Map();

            this.gridOptionsBodega['rowData'] = [];
            this.getField('rejilla_bodega').initData(this.gridOptionsBodega);
            this.getField('rejilla_bodega').toggle(false);
            
            this.gridOptionsBodega['rowData'] = datos;
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla_bodega').toggle(true);
            this.getField('rejilla_bodega').initData(this.gridOptionsBodega, configCell);

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos de bodegas.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    actualizarcosto(){

            let datos = { datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField("bodega_origen").getValue(),
                codigo_articulo:this.getField("codigo_articulo").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'traercosto', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successActualizarCosto,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successActualizarCosto(data){
        if (data.estado_p === 200)
        {
            this.getField('costo').setValue(data.data.costo);

            this.iva_articulo=data.data.iva;
        }else {
            this.iva_articulo='N';
            if(data.estado_p===404) {
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
                this.getField('confirmModalCustom').toggle(false);

                let respuesta=Object.values(data.data.errores.mensaje);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    calcularvalores(){
        this.getField('total_item').setError(false,'');
        this.getField('valor_unidad').setError(false,'');
        this.getField('total_impuestos').setError(false,'');
        this.getField('valor_iva_unidad').setError(false,'');
        this.getField('total_iva').setError(false,'');
        this.getField('valor_iva1').setError(false,'');
        if(this.pantalla === "TRASLADOSUBICACION"){

            if (this.getField("cantidad").valid() && this.getField("costo").valid()) {
                if(this.getField("costo").valid()){
                    let xtarifa_iva = this.getField('tarifa').getValue();
                    let ximpuestos1 = this.getField('impuesto').getValue();
                    let xcantidad1 = this.getField('cantidad').getValue();
                    let xcosto1 = this.getField('costo').getValue();
                    let xcosto_mas_impuestos = xcosto1 + ximpuestos1;
                    let xvalor_iva = xcosto1 * (xtarifa_iva / 100);
    
                    this.getField('valor_iva1').setValue(xcosto_mas_impuestos.toFixed(this.numero_decimales));
                    this.getField('valor_iva_unidad').setValue(xvalor_iva.toFixed(this.numero_decimales));
                    this.getField('valor_unidad').setValue((xvalor_iva + xcosto_mas_impuestos).toFixed(this.numero_decimales));
                    
                    //total iva
                    let xtotal_iva = xvalor_iva * xcantidad1;
                    this.getField('total_iva').setValue(xtotal_iva.toFixed(this.numero_decimales));
                    
                    //total_impuestos
                    let xtotal_impuestos = ximpuestos1 * xcantidad1;
                    this.getField('total_impuestos').setValue(xtotal_impuestos.toFixed(this.numero_decimales));
                    
                    //total_item
                    let xtotal_item = xtotal_impuestos + xtotal_iva;
                    xtotal_item = xtotal_item + (xcosto1 * xcantidad1);
                    this.getField('total_item').setValue(xtotal_item.toFixed(this.numero_decimales));
                }
            }
        }else{
            if(this.getField("costo").valid()){
                let xtarifa_iva = this.xiva;
                let ximpuestos1 = this.getField('impuesto').getValue();
                let xcantidad1 = this.getField('cantidad').getValue();
                let xcosto1 = this.getField('costo').getValue();
                let xcosto_mas_impuestos = xcosto1 + ximpuestos1;
                let xvalor_iva = xcosto1 * (xtarifa_iva / 100);

                this.getField('valor_iva1').setValue(xcosto_mas_impuestos.toFixed(this.numero_decimales));
                this.getField('valor_iva_unidad').setValue(xvalor_iva.toFixed(this.numero_decimales));
                this.getField('valor_unidad').setValue((xvalor_iva + xcosto_mas_impuestos).toFixed(this.numero_decimales));
                
                //total iva
                let xtotal_iva = xvalor_iva * xcantidad1;
                this.getField('total_iva').setValue(xtotal_iva.toFixed(this.numero_decimales));
                
                //total_impuestos
                let xtotal_impuestos = ximpuestos1 * xcantidad1;
                this.getField('total_impuestos').setValue(xtotal_impuestos.toFixed(this.numero_decimales));
                
                //total_item
                let xtotal_item = xtotal_impuestos + xtotal_iva;
                xtotal_item = xtotal_item + (xcosto1 * xcantidad1);
                this.getField('total_item').setValue(xtotal_item.toFixed(this.numero_decimales));
            }
        }
    }

    grabarArticulo(){
        if(this.getField("ubicacion").valid() && this.pantalla === "TRASLADOSUBICACION"){
            if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid() && this.getField("valor_iva1").valid() 
            && this.getField("cantidad").valid() && this.getField("costo").valid() && this.getField("valor_iva_unidad").valid()
            && this.getField("valor_unidad").valid() && this.getField("total_iva").valid() && this.getField("total_impuestos").valid()
            && this.getField("total_item").valid() && this.getField("ubicacion").valid() ){
                if((this.getField("cantidad").getValue() < 0 && this.getField("costo").getValue() === 0) || 
                (this.getField("cantidad").getValue() > 0 && this.getField("costo").getValue() > 0)){
                    this.validacionesAgregar();   
                }else{
                    this.alertGeneral.toggle(true, 'Si la cantidad es mayor a 0 debe ingresar el costo mayor a 0.', 'error');
                }
            }
        }else{
            if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo").valid() && this.getField("valor_iva1").valid() 
            && this.getField("cantidad").valid() && this.getField("costo").valid() && this.getField("valor_iva_unidad").valid()
            && this.getField("valor_unidad").valid() && this.getField("total_iva").valid() && this.getField("total_impuestos").valid()
            && this.getField("total_item").valid()  && this.getField("ubicacion").valid()){
                this.validacionesAgregar();   
            }
        }
    }

    successGrabarItem(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            this.gridOptions['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptions);
            this.getField('rejilla').toggle(false);
            this.sendCargarTabla();
            // Acá no se coloca la función "this.refrescar();", ya que en la función "this.limpiarArticulos();" la están llamando.
            if(this.pide_ubicacion)
                this.getField('ubicacion').setValue('');
            this.limpiarArticulos();
        }else {
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                //this.alertGeneral.toggle(true, 'Error (mensaje)' + data.data.errores.mensaje, 'error');
                // this.mostrarMensajeAdicional(data.data.mensaje)
                // this.getField('confirmModalCustom').setTitleAndContent('Traslados', `El artículo no tiene saldo en la ubicación: ${this.getField('ubicacion').getValue()}.`);
                this.getField('confirmModalCustom').setTitleAndContent('Traslados', `${data.data.mensaje}`);
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); 
                this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setButtonConfirm("OK");
                this.getField('confirmModalCustom').toggle(true);
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    limpiarArticulos(){
        this.cantidad = "";
        this.getField("codigo_articulo").setDisabled(false);
        this.getField("codigo_articulo").setValue("");
        this.getField("nombre_articulo").setValue("");
        this.getField("cantidad").setValue(0);
        this.getField("descuento").setValue(0);
        this.getField("impuesto").setValue(0);
        this.getField("select_columna").setValue("");
        this.getField("select_nivel").setValue("");
        this.getField("tarifa").setValue(0);
        this.getField("costo").setValue(0);
        this.getField("valor_iva1").setValue(0);
        this.getField("valor_iva_unidad").setValue(0);
        this.getField("valor_unidad").setValue(0);
        this.getField("total_item").setValue(0);
        this.getField("total_impuestos").setValue(0);
        this.getField("precio").setValue(0);
        this.getField('rejilla_ubicacion').toggle(false);
        this.getField('rejilla_bodega').toggle(false);
        this.getField("codigo_articulo").setError(false,"");
        this.refrescar();
    }

    refrescar(){
        let datos = { datos: {
            codigo_usuario:this.codigo_usuario,
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'refrescar', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successActualizarTotales,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successActualizarTotales(data){
        this.limpiando_tabla=false;
        if (data.estado_p === 200 )
        {
            this.getField('confirmModalCustom').toggle(false);
            let total=data.data.total
            let decimal = (total + "").split(".")[1];//para manejar los decimales
              if ((decimal !== 0) && (decimal !== undefined)) {
                if(decimal.length>this.numero_decimales)
                total=total.toFixed(this.numero_decimales);
            }
            this.getField("valor").setValue(total);

            if(data.data.total > 0){
                /* if(this.pantalla === "TRASLADOS"){
                    this.getField("bodega_origen").setDisabled(true);
                }else{
                    this.getField("bodega_origen").setDisabled(false);
                } */
            }else{
                this.getField("bodega_origen").setDisabled(false);
            }
        }else {
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traercodigo(){
        if(this.getField('codigoA').getValue() !== '' ){
            this.mostrarMensajeValidandoInformacion();
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario,
                codigo_articulo: this.getField('codigoA').getValue(),
                ubicacion:this.getField("ubicacion").getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'existe_articulo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
   }

   successTraerDatos(data){
        if (data.estado_p === 200 )
        {           
            this.getField('confirmModalCustom').toggle(false);
            this.entro=false;
            if (data.data.length >= 1) {
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El código '+data.data[0].codigo_articulo+' ya existe, ¿Desea modificarlo?');
                this.getField('confirmModalCustom').setClickDialog(()=>{this.entro=true; this.llenarcampos(data);});
                this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField('confirmModalCustom').toggle(true);
                this.validarcancelarconfirmar();
            }
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
   }

   llenarcampos(data){
        // console.log('Data que quiero ver:\n',data);
        this.getField('confirmModalCustom').toggle(false);
    
        this.getField("codigo_articulo").setDisabled(true);
        this.getField("codigo_articulo").setValue(data.data[0].codigo_articulo);
        this.getField("tarifa").setValue(data.data[0].tarifa_iva);
        this.getField("cantidad").setValue(data.data[0].cantidad);
        this.cantidad = data.data[0].cantidad;
        this.getField("costo").setValue(data.data[0].costo);
        this.getField('impuesto').setValue(data.data[0].impuestos)
        this.getField("valor_iva1").setValue(data.data[0].valor_iva);
        this.getField("total_item").setValue(data.data[0].total_item);
        this.getField("ubicacion").setValue(data.data[0].ubicacion);      
        this.getField("valor_iva_unidad").setValue(data.data[0].subtotal1);
        this.getField("valor_unidad").setValue(data.data[0].subtotal2);
        this.getField("nombre_articulo").setValue(data.data[0].nombre);
        this.getField("precio").setValue(Number(this.precio1));

    }


    validarcancelarconfirmar(){
        if(this.entro=== false){
            this.limpiarA();
        }
    }

    limpiarA(){
        this.getField("codigo_articulo").setError(false,"");
        this.getField("cantidad").setValue(0);
        this.getField("select_columna").setValue("");
        this.getField("select_nivel").setValue("");
        this.getField("tarifa").setValue(0);
        this.getField("descuento").setValue(0);
        this.getField("costo").setValue(0);
        this.getField("valor_iva1").setValue(0);
        this.getField("total_iva").setValue(0);
        this.getField("valor_iva_unidad").setValue(0);
        this.getField("valor_unidad").setValue(0);
        this.getField("total_item").setValue(0);
        this.getField("total_impuestos").setValue(0);
        this.getField("impuesto").setValue(0);
        this.getField("precio").setValue(0);
    }

    grabarTraslado(){
        if(this.pantalla === 'TRASLADOS'){
            // console.log('Entró a la función que consume el servicio grabar_traslado');
            if(this.getField("bodega_origen").valid() && this.getField("nombre_bodega_origen").valid() && 
            this.getField("bodega_destino").valid() && this.getField("nombre_bodega_destino").valid() && this.getField("concepto_cod").valid()
            && this.getField("nombre_c").valid() && this.getField("nit").valid() && this.getField("sede").valid() 
            && this.getField("nombre").valid() &&  this.getField("detalle").valid() && this.getField("consecutivo").valid()){
                
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
    
                let datos = { datos: {
                    pantalla: this.pantalla,
                    sucursal:this.codigo_sucursal,
                    codigo_usuario:this.codigo_usuario,
                    concepto_cod: this.getField("concepto_cod").getValue(),
                    bodega_origen: this.getField("bodega_origen").getValue(),
                    bodega_destino: this.getField("bodega_destino").getValue(),
                    consecutivo: this.getField("consecutivo").getValue(),
                    nombre_c: this.getField("nombre_c").getValue(),
                    detalle: this.getField("detalle").getValue(),
                    nit: this.getField("nit").getValue(),
                    sede: this.getField("sede").getValue(),
                    valor: this.getField("valor").getValue(),
                    // afecta_costo: this.afecta_costo,// Se comenta ya que la variable global "this.afecta_costo" no se le está asigando ningún valor, en ninguna parte del código lo que indica que no tine utilidad.
                    doc_fuente: this.selectedRows.length === 0 ? "" : this.getField("doc_origen").getValue(),
                    codigo_sucursal: this.selectedRows.length === 0 ? "": this.selectedRows[0].sucursal,
                    fecha: this.selectedRows.length === 0 ? "":this.selectedRows[0].fecha,
                    xtipo:"X"
    
                }};
            
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'grabar_traslado', operacion_tipo: 'crear' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successTraerconsecutivo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }else{
                this.alertGeneral.toggle(true, 'Debe diligenciar todos los campos ', 'error');
            }
        }else{
            // console.log('Entró a la segunda validación\n',this.pantalla);
            this.getField("numero_guia").setError(false,'');
            this.getField("transportadora").setError(false,'');
            this.getField("nombre_transportadora").setError(false,'');
            if(this.getField("bodega_origen").valid() && this.getField("nombre_bodega_origen").valid() && 
            this.getField("bodega_destino").valid() && this.getField("nombre_bodega_destino").valid() && 
            this.getField("nit").valid() && this.getField("sede").valid() && this.getField("nombre").valid() &&
            this.getField("flete").valid() && this.getField("consecutivo").getValue() !== '' && this.getField("consecutivo").getValue() !== ' ' &&
            this.getField("detalle").valid() && this.getField("valor").valid() && this.getField("valor").getValue() >= 0.01){
                if(this.getField("numero_guia").getValue()!==''){
                    if(!this.getField("numero_guia").valid()){
                        return;
                    }
                }

                if(this.getField("transportadora").getValue()!==''){
                    if(this.getField("transportadora").valid() && this.getField("nombre_transportadora").valid()){

                    }else{
                        return;
                    }
                }


                if(this.validarflete){
                    let datos = { datos: {
                        sucursal: this.codigo_sucursal,
                        bodega_origen: this.getField("bodega_origen").getValue(),
                        pantalla: this.pantalla,
                        codigo_usuario:this.codigo_usuario,
                    }};

                    this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'aviso_recibo', operacion_tipo: 'consulta' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successTraerconsecutivo,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });
                }
            }else{
                if (!(this.getField('valor').getValue() !== '' && this.getField('valor').getValue() !== ' ' && this.getField('valor').getValue() >= 0.01)) {
                    this.getField('confirmModalCustom').setTitleAndContent('Error', `Debe agregar al menos un artículo, para que valor sea diferente de vacío.`);
                    this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
                    this.getField("confirmModalCustom").setClickDialog(()=>{
                        this.getField('confirmModalCustom').toggle(false);
                    });
                    this.getField("confirmModalCustom").setButtonConfirm('OK');// Para renombrar el botón de "confirmar" en los ConfirmDialog – pop
                    this.getField('confirmModalCustom').toggle(true);
                }else{
                    this.alertGeneral.toggle(true, 'Debe diligenciar todos los campos, si el campo vacío es consecutivo dar clic al botón iniciar. ', 'error');
                }
            }
        }
    }

    successTraerconsecutivo(data){
        if (data.estado_p === 200 )
        {    
            this.getField('confirmModalCustom').setTitleAndContent('Confirmación', '¿Confirma que desea grabar el traslado?');
            this.getField('confirmModalCustom').setClickDialog(() => { 
                if (this.pantalla === "TRASLADOS") {
                    this.getField("consecutivo").setValue(data.data.numero);
                    this.getField('confirmModalCustom').toggle(false);       
                    this.boton_selecionado='grabar_Traslado';
                    this.abrirImprimir();
                }else{
                    if (data.data.aviso_recibo === "S") {
                        this.getField('confirmModalCustom').toggle(false);       
                        this.getField('modal_recibo').handleClickOpen();
                        this.getField('aviso_recibo').setValue('000');//valor defecto seteado en escritorio
                    } else {
                        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
    
                        this.mostrarMensajeGuardando();
                        let datos = { datos: {
                            pantalla: this.pantalla,
                            sucursal:this.codigo_sucursal,
                            codigo_usuario:this.codigo_usuario,
                            concepto_cod: this.getField("concepto_cod").getValue(),
                            bodega_origen: this.getField("bodega_origen").getValue(),
                            bodega_destino: this.getField("bodega_destino").getValue(),
                            consecutivo: this.getField("consecutivo").getValue(),
                            nombre_c: this.getField("nombre_c").getValue(),
                            detalle: this.getField("detalle").getValue(),
                            nit: this.getField("nit").getValue(),
                            sede: this.getField("sede").getValue(),
                            valor: this.getField("valor").getValue(),
                            // afecta_costo: this.afecta_costo,// Se comenta ya que la variable global "this.afecta_costo" no se le está asigando ningún valor, en ninguna parte del código lo que indica que no tine utilidad.
                            doc_fuente: this.getField("doc_origen").getValue() === '' ? ".":this.getField("doc_origen").getValue(),
                            codigo_sucursal: this.selectedRows.length === 0 ? "": this.selectedRows[0].sucursal,
                            fecha: this.selectedRows.length === 0 ? "":this.selectedRows[0].fecha,
                            numero_guia:this.getField("numero_guia").getValue() ===''?'.':this.getField("numero_guia").getValue(),
                            fecha_guia:this.getField("fecha_guia").getValue(),
                            transportadora:this.getField("transportadora").getValue() === '' ? '.':this.getField("transportadora").getValue(),
                            fecha1:this.getField("fecha").getValue(),
                            flete:this.getField("flete").getValue()
            
                        }};
                    
                        // console.log('Este es el body que viaja en el servicio:\n',datos);
                        
                        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'grabar_traslado', operacion_tipo: 'crear' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'POST',
                            body: datos,
                            success: this.succesGrabarTraslado,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                        });
                    }
                }
            });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonCancel('Cancelar');// Para renombrar el botón de "cancelar"
            this.getField("confirmModalCustom").setButtonConfirm('Aceptar');
            this.getField('confirmModalCustom').toggle(true);
        }else if (data.estado_p === 404){
            this.getField('confirmModalCustom').setTitleAndContent('Error', `${data.data.errores.mensaje}`);
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setVisibleConfirm(false);
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('OK');
            this.getField('confirmModalCustom').toggle(true);
        }else{
            this.alertGeneral.toggle(true,data.data.errores.mensaje, 'error');
            this.getField('confirmModalCustom').toggle(false);       
        }
    }

    succesGrabarTraslado(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {    
            this.getField("consecutivo").setValue(data.data.numero);
            this.getField('confirmModalCustom').toggle(false);       
            this.boton_selecionado='grabar_Traslado';
            this.abrirImprimir();
        }else if (data.estado_p === 502) {
            this.getField('confirmModalCustom').setTitleAndContent('Error', `${data.data.errores.mensaje}`);
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setVisibleConfirm(false);
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('OK');
            this.getField('confirmModalCustom').toggle(true);
        }else{
            this.alertGeneral.toggle(true,data.data.errores.mensaje, 'error');
            this.getField('confirmModalCustom').toggle(false);       
        }
    }

    guardarRecibo(){
        if (this.getField("aviso_recibo").getValue() !== "") {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            this.recibo = this.getField("aviso_recibo").getValue();
            this.getField('modal_recibo').handleClose();
            let datos = { datos: {
                pantalla: this.pantalla,
                sucursal:this.codigo_sucursal,
                codigo_usuario:this.codigo_usuario,
                concepto_cod: this.getField("concepto_cod").getValue(),
                bodega_origen: this.getField("bodega_origen").getValue(),
                bodega_destino: this.getField("bodega_destino").getValue(),
                consecutivo: this.getField("consecutivo").getValue(),
                nombre_c: this.getField("nombre_c").getValue(),
                detalle: this.getField("detalle").getValue(),
                nit: this.getField("nit").getValue(),
                sede: this.getField("sede").getValue(),
                valor: this.getField("valor").getValue(),
                // afecta_costo: this.afecta_costo,// Se comenta ya que la variable global "this.afecta_costo" no se le está asigando ningún valor, en ninguna parte del código lo que indica que no tine utilidad.
                doc_fuente: this.selectedRows.length === 0 ? "" : this.getField("doc_origen").getValue(),
                codigo_sucursal: this.selectedRows.length === 0 ? "": this.selectedRows[0].sucursal,
                fecha: this.selectedRows.length === 0 ? "":this.selectedRows[0].fecha,
                numero_guia:this.getField("numero_guia").getValue()=== "" ? ".":this.getField("numero_guia").getValue(),
                fecha_guia:this.getField("fecha_guia").getValue(),
                transportadora:this.getField("transportadora").getValue() === "" ? ".":this.getField("transportadora").getValue(),
                recibo: this.recibo,
                flete:this.getField("flete").getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'grabar_traslado', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.succesGrabarTraslado,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe ingresar el número de recibo. ', 'error');
        }
    }

    limpiarTodo(){
        this.getField("bodega_origen").setValue("");
        this.getField("nombre_bodega_origen").setValue("");
        this.getField("bodega_destino").setValue("");
        this.getField("nombre_bodega_destino").setValue("");
        this.getField("concepto_cod").setValue("");
        this.getField("nombre_c").setValue("");
        this.getField("nit").setValue("");
        this.getField("sede").setValue("");
        this.getField("nombre").setValue("");
        this.getField("detalle").setValue("");
        this.getField("consecutivo").setValue("");
        this.getField("flete").setValue("");
        this.getField("valor").setValue(0);
        this.getField("numero_guia").setValue("");
        this.getField("transportadora").setValue("");
        this.getField("nombre_transportadora").setValue("");
        this.getField("doc_origen").setValue("");
        this.getField("doc_origen").setDisabled(false);
        this.getField("agregar_articulo").setVisible(false);
        this.gridOptions['rowData'] = [];
        let configCell = new Map();
        this.getField('rejilla').initData(this.gridOptions,configCell);
        this.getField('rejilla').toggle(false);
        this.gridOptions['rowData'] = []
        this.getField('rejilla_wms').initData(this.gridOptions,configCell);
        this.getField('rejilla_wms').toggle(false);
        this.initForm();
    }

    validacionesAgregar(){
        if(Number(this.getField('total_item').getValue()!==0)){
            this.getField('grabar').setDisabled(true);
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
              let datos={ datos: {
                sucursal:this.codigo_sucursal,
                bodega:this.getField('bodega_origen').getValue(),
                costo:this.getField('costo').getValue(),
                codigo_articulo:this.getField('codigo_articulo').getValue()
              }};
              this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.sucessValidaciones,
                      error: this.error_,
                      general: this.generalFormatPmv,
                      showMessage: false
              });
        }else{
            this.alertGeneral.toggle(true, 'El total del item no puede ser cero.', 'error');
        }    
    }

    validacionesAgregar2(input){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('grabar').setDisabled(true);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        if(this.getField('codigo_articulo').valid()){
            let datos={ datos: {
                  sucursal:this.codigo_sucursal,
                  input:input,
                  bodega:this.getField('bodega_origen').getValue(),
                  costo:this.getField('costo').getValue(),
                  codigo_articulo:this.getField('codigo_articulo').getValue()
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessValidaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    sucessValidaciones(data)
    {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('grabar').setDisabled(false);
        if (data.estado_p === 200)
        { 
            if(data.data.costo_pro!==undefined){
                //pedir el input
                let costo_pro=data.data.costo_pro;
                if(costo_pro===0){
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El costo_pro_'+this.getField('bodega_origen').getValue()+' es cero. ¿Está seguro de continuar?');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                }else{
                    let valor_costo = Math.abs((costo_pro - this.getField('costo').getValue())/ costo_pro) * 100;
                    let margen_costo_promedio=data.data.margen_costo_promedio;
                    if (valor_costo > margen_costo_promedio) {
                        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El valor del costo sobrepasa el margen costo entrada. Margen costo entrada= '+margen_costo_promedio+'. Variación='+this.currencyFormatterGeneral(valor_costo)+'. ¿Está seguro de continuar? ');
                        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                    }else{
                        this.validacionesAgregar2(0)
                    }
                }
            }else{
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                let datos = { datos: {
                    sucursal:this.codigo_sucursal,
                    bodega:this.getField("bodega_origen").getValue(),
                    codigo_articulo:this.getField("codigo_articulo").getValue(),
                    nombre_articulo:this.getField("nombre_articulo").getValue(),
                    ubicacion:this.getField("ubicacion").getValue(),
                    cantidad:this.getField("cantidad").getValue(),
                    select_columna:this.getField("columna").getValue(),
                    select_nivel:this.getField("nivel").getValue(),
                    select_ubicacion:this.getField("clase").getValue(),
                    tarifa:this.getField("tarifa").getValue(),
                    costo:this.getField("costo").getValue(),
                    valor_iva1:this.getField("valor_iva1").getValue(),
                    valor_iva_unidad:this.getField("valor_iva_unidad").getValue(),
                    valor_unidad:this.getField("valor_unidad").getValue(),
                    total_item:this.getField("total_item").getValue(),
                    impuestos:this.getField("impuesto").getValue(),
                    codigo_usuario:this.codigo_usuario,
                    modifica:this.entro
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'grabaritem', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successGrabarItem,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            }
        }
        else 
        {
            if(data.estado_p===404) {

                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    let datos = { datos: {
                        sucursal:this.codigo_sucursal,
                        bodega:this.getField("bodega_origen").getValue(),
                        codigo_articulo:this.getField("codigo_articulo").getValue(),
                        nombre_articulo:this.getField("nombre_articulo").getValue(),
                        ubicacion:this.getField("ubicacion").getValue(),
                        cantidad:this.getField("cantidad").getValue(),
                        select_columna:this.getField("columna").getValue(),
                        select_nivel:this.getField("nivel").getValue(),
                        select_ubicacion:this.getField("clase").getValue(),
                        tarifa:this.getField("tarifa").getValue(),
                        costo:this.getField("costo").getValue(),
                        valor_iva1:this.getField("valor_iva1").getValue(),
                        valor_iva_unidad:this.getField("valor_iva_unidad").getValue(),
                        valor_unidad:this.getField("valor_unidad").getValue(),
                        total_item:this.getField("total_item").getValue(),
                        impuestos:this.getField("impuesto").getValue(),
                        codigo_usuario:this.codigo_usuario
                    }};
                    
                    this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'grabaritem', operacion_tipo: 'consulta' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successGrabarItem,
                        error: this.error_,
                        general: this.generalFormatPmv
                    });
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    validarWmsExterno(){
        if(this.getField("sucursal_wms").valid() && this.getField("numero_wms").valid()){
            let datos = { datos: {
                sucursal:this.getField("sucursal_wms").getValue()
            }};
            this.mostrarMensajeGenerando();
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'traerestadosucursal', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarWmsExterno,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else if(!(this.getField("numero_wms").getValue() !== '' && this.getField("numero_wms").getValue() !== ' ')){
            this.getField('confirmModalCustom').setTitleAndContent('Sucursal wms externos', `Sucursal Invalida!`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('OK');// Para renombrar el botón de "confirmar" en los ConfirmDialog – pop
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    
    successValidarWmsExterno(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            if (!(data.data[0].estado === 'A')) {
                this.getField('confirmModalCustom').setTitleAndContent('Sucursal wms externos', `Sucursal Inactiva!`);
                this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setButtonConfirm('OK');// Para renombrar el botón de "confirmar" en los ConfirmDialog – pop
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.traerwmsexterno();
            }
        }else if (data.estado_p === 404) {
            this.getField('confirmModalCustom').setTitleAndContent('Sucursal wms externos', `Sucursal Invalida!`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('OK');// Para renombrar el botón de "confirmar" en los ConfirmDialog – pop
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    
    traerwmsexterno(){
        if(this.getField("sucursal_wms").valid() && this.getField("numero_wms").valid()){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            let datos = { datos: {
                sucursal:this.getField("sucursal_wms").getValue(),
                bodega:this.getField("bodega_origen").getValue(),
                numero:this.getField("numero_wms").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'traerdatoswms', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerwmsexterno,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successTraerwmsexterno(data){
        if (data.estado_p === 200 )
        {    
            this.mostrarMensajeGenerando();
            this.datos_wms=data.data.data;
            this.numero_wms = this.getField("numero_wms").getValue();
            this.getField("doc_origen").setDisabled(true);
            let datos = { datos: {
                bodega_origen:this.getField("bodega_origen").getValue(),
                sucursal_wms:this.getField("sucursal_wms").getValue(),
                doc_origen:this.getField("numero_wms").getValue(),
                pantalla:this.pantalla
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'traerdocumenorigen', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successtraerdoc,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else if (data.estado_p === 404) {
            this.getField('confirmModalCustom').toggle(false);

            this.getField('confirmModalCustom').setTitleAndContent('Error', `${data.data.mensaje}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('OK');// Para renombrar el botón de "confirmar" en los ConfirmDialog – pop
            this.getField('confirmModalCustom').toggle(true);
        }else{
            // this.alertGeneral.toggle(true,'Error con datos wms', 'error');
            this.getField('confirmModalCustom').toggle(false);       

            this.getField('confirmModalCustom').setTitleAndContent(`${data.data.errores.mensaje === 'Connection refused: connect' ? 'Conexión Rehusada' : 'Error'}`, `${data.data.errores.mensaje === 'Connection refused: connect' ? `URL no configurada para la sucursal: ${this.getField('sucursal_wms').getValue()}` : data.data.errores.mensaje}`);
            this.getField("confirmModalCustom").setVisibleCancel(true); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField("confirmModalCustom").setButtonConfirm('OK');// Para renombrar el botón de "confirmar" en los ConfirmDialog – pop
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    /////////////////////////////// impresion archivo ///////////////////////////////////////////////////////////////////

      generarModalLista(){
        this.mostrarMensajeGenerando();
        let enviarPeticion=true;
        let op='';
        let datos={ datos: { }};
        /*if(this.getField('concepto_cod').getValue()!=='' && this.getField('concepto_cod').getValue()!=='' ){
            datos.datos.codigo_concepto_ajuste=this.getField('concepto_cod').getValue();
            datos.datos.nombre_concepto=this.getField('nombre_c').getValue();
        }*/
        if(this.boton_selecionado==='grabar_Traslado'){
            op='consultatraslado';
            datos.datos.numero=this.getField("consecutivo").getValue();
            datos.datos.codigo_sucursal=this.codigo_sucursal;
            datos.datos.fecha=this.fecha_hoy;
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
        }
        else{
            op='';
            enviarPeticion=false;
        }
        if(enviarPeticion){
            this.mostrarMensajeGenerando();
            this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: op, operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
            {
                "empresa": this.lista.empresa,
                "razon_social": this.lista.razon_social,
                "nit":this.lista.nit,
                "sucursal": this.sucursal,
                "bodega_origen": this.lista.bodega_origen,
                "bodega_destino": this.lista.bodega_destino,
                "traslado_numero": this.lista.referencia,
                "fecha": this.lista.fecha,
                "detalle": this.lista.detalle
            };
            datosFicha.push(data2);
            this.getField('lista_datos_general').setItemsFichas(datosFicha);
            
            let configCell = new Map();
            this.getField('tabla_consultar').toggle(true);
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});

            this.gridOptionsConsultarModal['rowData'] = [];
            this.gridOptionsConsultarModal['rowData'] = data.data.datos_items;
            this.getField('total_valor').setVisible(true);
            this.getField('total_valor').setValue(this.currencyFormatterGeneral(this.lista.valor_total));
            this.getField('peso_total').setValue(this.currencyFormatterGeneral(this.lista.peso_total));

            configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('total_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.total_item) }, cellStyle: {textAlign:"right"},width: 110});
            
            this.getField('tabla_consultar').initData(this.gridOptionsConsultarModal,configCell);

            this.getField('modal_general').handleClickOpen();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    eliminarTemporal(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'eliminartabla', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.sucessEliminarTemporal,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    sucessEliminarTemporal(data){
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCamposListadosAjustes();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGuardando(){
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
            this.getField('confirmModalCustom').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    generarPdfTabla3()
    {       
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let op='';
        let datos2={}
        /*if(this.getField('concepto_cod').getValue()!=='' && this.getField('concepto_cod').getValue()!=='' ){
            datos2.codigo_concepto_ajuste=this.getField('concepto_cod').getValue();
            datos2.nombre_concepto=this.getField('nombre_c').getValue();
        }*/
        if(this.boton_selecionado==='grabar_Traslado'){
            op='consultatraslado';
            datos2.numero=this.getField("consecutivo").getValue();
            datos2.codigo_sucursal=this.codigo_sucursal;
            datos2.fecha=this.fecha_hoy;
            datos2.sucursal_ingreso=this.codigo_sucursal;
            datos2.operacion=this.operacion_actual;
            datos2.email=this.getField('email').getValue();
            datos2.radio_correo=this.getField('radio_correo').getValue();
            this.nombre_archivo='ajustes';
        }
        else
            op='';

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultatraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let op='';
            let datos2={};

            /*if(this.getField('concepto_cod').getValue()!=='' && this.getField('concepto_cod').getValue()!=='' ){
                datos2.codigo_concepto_ajuste=this.getField('concepto_cod').getValue();
                datos2.nombre_concepto=this.getField('nombre_c').getValue();
            }*/
            if(this.boton_selecionado==='grabar_Traslado'){
                op='consultatraslado';

                datos2.numero=this.getField("consecutivo").getValue();
                datos2.codigo_sucursal=this.codigo_sucursal;
                datos2.fecha=this.fecha_hoy;
                datos2.sucursal_ingreso=this.codigo_sucursal;
                datos2.operacion=this.operacion_actual;
                datos2.email=this.getField('email').getValue();
                datos2.radio_correo=this.getField('radio_correo').getValue();
                this.nombre_archivo='ajustes';
            }
            else
                op='';

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-consultatraslados","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let op='';
        let datos={ datos: {}};
        /*if(this.getField('concepto_cod').getValue()!=='' && this.getField('concepto_cod').getValue()!=='' ){
            datos.datos.codigo_concepto_ajuste=this.getField('concepto_cod').getValue();
            datos.datos.nombre_concepto=this.getField('nombre_c').getValue();
        }*/
        if(this.boton_selecionado==='grabar_Traslado'){
            datos.datos={};
            op='consultatraslado';
            datos.datos.numero=this.getField("consecutivo").getValue();
            datos.datos.codigo_sucursal=this.codigo_sucursal;
            datos.datos.fecha=this.fecha_hoy;
            datos.datos.sucursal_ingreso=this.codigo_sucursal;
            datos.datos.operacion=this.operacion_actual;
            datos.datos.email=this.getField('email').getValue();
            datos.datos.radio_correo=this.getField('radio_correo').getValue();
        }
        else
            op='';


        this.generalFormatPmv = { tipo_servicio: 'inve-consultatraslados', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales 
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>this.numero_decimales){
              decimal=(Number(number).toFixed(this.numero_decimales) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    actualizarDocOrigen(){ 
        let wms='N'
        if(this.wms_externo_origen === "S" && this.wms_externo_destino === "S")
            wms='S';

        
        let datos={ datos: {
            doc_fuente: this.getField("doc_origen").getValue() === "" ? this.getField("consecutivo").getValue(): this.getField("doc_origen").getValue(),
            codigo_sucursal: this.selectedRows.length === 0 ? this.codigo_sucursal: this.selectedRows[0].sucursal,
            fecha: this.selectedRows.length === 0 ? this.fecha_hoy:this.selectedRows[0].fecha,
            sucursal:this.codigo_sucursal,
            wmsdatos: wms,
            consecutivo: this.getField("consecutivo").getValue(),
            pantalla:this.pantalla
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosinventario', operacion: 'actualizarDocOrigen', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessActualizarDoc,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    sucessActualizarDoc(data){
        if(data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se actualiza estado de la factura.', 'success');
            this.limpiarTodo();
            this.initForm();
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'Error al cambiar estado del externo.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
            this.limpiarTodo();
            this.initForm();
        }
    }

    mostrarMensajeValidandoInformacion(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

}
FormJaivana.addController("inve-trasladosinventario", CustomGrabacionTraslados);
export default CustomGrabacionTraslados;