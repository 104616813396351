import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomInactivarProductos
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomInactivarProductos extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.consultarSaldoBodega                  = this.consultarSaldoBodega.bind(this);
    this.successconsultarSaldoBodega           = this.successconsultarSaldoBodega.bind(this);    
    this.successInactivarArticulo = this.successInactivarArticulo.bind(this);
    this.inactivarArticulo = this.inactivarArticulo.bind(this);
    this.registrarLog = this.registrarLog.bind(this);
    this.successRegistrarLog = this.successRegistrarLog.bind(this);
    
    this.dibujarTabla = this.dibujarTabla.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.listado_bodegas=[];
    this.datos_bodega_existencia=[];
    this.existencias=0;
    this.datos_anteriores='';
    this.datos_nuevos='';
    this.user_id='';
    this.codigo_sucursal='';
    
  }

  initForm() {
    console.log("Formulario CustomInactivarProductos,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez, @updateBy Jose Albeiro Marin Bernal");
    this.user_id = JSON.parse(localStorage.getItem('res')).id;
    this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    this.getField('seleccionar').setClick(this.consultarSaldoBodega);
    this.getField('cancelar').setClick((props)=>{
      this.getField('codigo_articulo').setValue('');
      this.getField('nombre_articulo').setValue('');
      this.getField('articulo_id').setValue('');
    });
    this.getField('detalle').setDisabled(true);
    this.getField('total_existencias').setDisabled(true);

    this.getField('aceptar').setClick(this.inactivarArticulo);
    this.getField('seleccionar').setDisabled(true);
    this.getField('aceptar').setDisabled(true);
    this.getField('cancelar').setDisabled(true);
    this.getField('articulo_id').setOnChange((props) => { 
                      if(this.getField('articulo_id').getValue()==='' || this.getField('articulo_id').getValue()===' '){
                        this.limpiarCampos();
                        this.getField('seleccionar').setDisabled(true);
                      }else{
                        this.getField('seleccionar').setDisabled(false);

                      }});

    this.getField('detalle').setVisible(false);
    this.getField('aceptar').setVisible(false);
    this.getField('cancelar').setVisible(false);
    this.getField('total_existencias').setVisible(false);
  }

  limpiarCampos(){
      this.getField('tabla_bodegas').toggle(false);
      this.getField('detalle').setValue('');
      this.getField('total_existencias').setValue(0);
      this.getField('detalle').setDisabled(true);
      this.getField('aceptar').setDisabled(true);
      this.getField('cancelar').setDisabled(true);
      this.getField('detalle').setError(false, "");
      this.getField('codigo_articulo').setDisabled(false);
      this.getField('seleccionar').setDisabled(false);
      this.getField('detalle').setVisible(false);
      this.getField('aceptar').setVisible(false);
      this.getField('cancelar').setVisible(false);
      this.getField('total_existencias').setVisible(false);
    
  }


  consultarSaldoBodega(){
    this.datos_bodega_existencia=[];
    this.getField('total_existencias').setValue(0);
    this.existencias=0;
    if(this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid()   && this.getField('articulo_id').valid()){
      this.getField('seleccionar').setDisabled(true);
      let datos = { datos: {
        articulos_id: this.getField('articulo_id').getValue(),
        codigo_sucursal: this.codigo_sucursal
      }};

      this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: 'traersaldosbodegas', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successconsultarSaldoBodega,
                error: this.error_,
                general: this.generalFormatPmv
        });
        
    }
  }


  successconsultarSaldoBodega(data){
    if(data.estado_p === 200){ 
      this.datos_bodega_existencia=data.data;
      this.getField('detalle').setVisible(true);
      this.getField('aceptar').setVisible(true);
      this.getField('cancelar').setVisible(true);
      this.getField('total_existencias').setVisible(true);
      this.dibujarTabla();
    }else {
      this.getField('tabla_bodegas').toggle(false);
      this.getField('codigo_articulo').setDisabled(false);
      this.getField('seleccionar').setDisabled(false);
      this.getField('detalle').setVisible(false);
      this.getField('aceptar').setVisible(false);
      this.getField('cancelar').setVisible(false);
      this.getField('total_existencias').setVisible(false);
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
   }
  }

  dibujarTabla(){

    if(this.datos_bodega_existencia.length>0){
        
        this.datos_bodega_existencia.forEach(element => {
            this.existencias+=element.existencia;
        });
        this.getField('tabla_bodegas').toggle(true);
        let configCell = new Map();
        this.gridOptionsComponentes['rowData'] = this.datos_bodega_existencia;
        this.getField('tabla_bodegas').initData(this.gridOptionsComponentes, configCell);
        this.getField('total_existencias').setValue(this.existencias);
        this.getField('codigo_articulo').setDisabled(true);
        this.getField('seleccionar').setDisabled(true);

    }else{
      this.getField('total_existencias').setValue(0);
    }
    if(this.getField('total_existencias').getValue()===0){
      this.getField('detalle').setDisabled(false);
      this.getField('aceptar').setDisabled(false);
      this.getField('cancelar').setDisabled(false);
    }else{
      this.getField('detalle').setDisabled(true);
      this.getField('aceptar').setDisabled(true);
      this.getField('cancelar').setDisabled(false);
    }

  }

  inactivarArticulo(){
    if(this.getField('codigo_articulo').valid()&&
      this.getField('nombre_articulo').valid()&&
      this.getField('articulo_id').valid() &&
      this.getField('detalle').valid()){
        let datos = { datos: {
          registro_id:this.getField('articulo_id').getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-inactivarproductos', operacion: '6', operacion_tipo: 'modificar' };
        this.datos_anteriores=this.generalFormatPmv;
        this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'PUT',
                  body: datos,
                  success: this.successInactivarArticulo,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
    }
  }

  successInactivarArticulo(data) 
  {

    if (data.estado_p === 200)
    {
        this.datos_nuevos=this.getField('detalle').getValue();//detalle va al campo datos_nuevo
        this.registrarLog();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  registrarLog(){
    if(this.datos_anteriores!=='' &&
      this.datos_nuevos!==''){
        let datos = { datos: {
          nombre_tabla:'articulo',
          operacion:'INACTIVAR',
          registro_id:this.getField('articulo_id').getValue(),
          datos_anteriores:JSON.stringify(this.datos_anteriores),
          datos_nuevos:this.datos_nuevos,
          fecha_modificacion:'now()',
          usuario_id:this.user_id
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'maes-inactivarproductos', operacion: '5', operacion_tipo: 'crear' };
        
        
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successRegistrarLog,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }else{
      this.alertGeneral.toggle(true, 'Error no se recuperaron registros al actualizar.', 'error');
    }
  }

  successRegistrarLog(data) 
  {
   
    if (data.estado_p === 200)
    {
        this.getField('codigo_articulo').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('articulo_id').setValue('');
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

}
FormJaivana.addController("maes-inactivarproductos", CustomInactivarProductos);
export default CustomInactivarProductos;