import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomInformesPorTipoProducto
 * @author: Anderson Acevedo
 * @version: jdesk_1.01.0002
 **/
class CustomInformesPorTipoProducto extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.consultarInforme                 = this.consultarInforme.bind(this);
        this.validarfechasConsulta          = this.validarfechasConsulta.bind(this);
        this.successDatosInforme             = this.successDatosInforme.bind(this);
        this.gridOptionsTbPrincipal         = Object.assign({},this.gridOptions);
        this.gridOptionsTbPrincipal['onSelectionChanged'] = this.onSelectionChanged;

        this.gridOptionsConsultarModal      = Object.assign({},this.gridOptions);
        this.gridOptionsConsultarModal['onSelectionChanged'] = this.onSelectionChanged;
        this.limpiar                        = this.limpiar.bind(this);       
        this.currencyFormatterGeneral       = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo              = this.formatNumberSaldo.bind(this); 
        this.abrirImprimir                  = this.abrirImprimir.bind(this);
        this.procesar                       = this.procesar.bind(this);
        this.habilitarCorreo                = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo             = this.deshabilitarCorreo.bind(this);
        this.mostrarMensajeEmailEnviado     = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo       = this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeNoHayDatos       = this.mostrarMensajeNoHayDatos.bind(this);
        this.generarPdfTabla3               = this.generarPdfTabla.bind(this);
        this.generarExcelTabla3             = this.generarExcelTabla.bind(this);
        this.enviarCorreoTerceros           = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo            = this.successEnviarCorreo.bind(this);
        this.isJson                         = this.isJson.bind(this);
        this.generarModalLista              = this.generarModalLista.bind(this);
        this.sucessTraerDatos               = this.sucessTraerDatos.bind(this);
        this.limpiarTodo                    = this.limpiarTodo.bind(this);
        this.traerPropiedades =this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades =this.sucesstraerPropiedades.bind(this);
        this.arreglarDecimales =this.arreglarDecimales.bind(this);

        this.numero_decimales=0;
        this.dias_maximo=0;
        this.fecha='';
        this.selectedRows = [];
        this.nombre_archivo='';


    }

    initForm() {
        console.log("Formulario CustomAsentarOrdenesProduccion,  @version: jdesk_1.01.0002, @author: Anderson Acevedo");
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedades();
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';
        this.getField("desde").setValue(this.fecha_inicio);
        this.getField("consultar").setClick(this.consultarInforme);
        this.getField("desde").setOnChange(this.validarfechasConsulta);
        this.getField("hasta").setOnChange(this.validarfechasConsulta);
        this.getField("radio_tipo").setOnChange(this.limpiar);
        this.getField("radio_tipo").setValue('ajustes');

        this.getField('imprimir').setDisabled(true);
        this.getField("imprimir").setClick(this.abrirImprimir);


        this.getField('generar_archivo').setClick(()=>this.procesar());

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });

    }

    traerPropiedades(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'inve-informesportipodeproducto', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }
    
    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales=data.data.numero_decimales;
            this.dias_maximo=data.data.dias_devolver;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    limpiarTodo(){
        this.getField("numero").setValue("");
        this.getField("producto").setValue("");
        this.getField("codigo_sucursal").setValue("");
    }

    validarfechasConsulta(){
        this.limpiar();
        let fecha_actual =new Date().toISOString().split('T')[0];
        let consultar_desde = new Date(this.getField("desde").getValue()).toISOString().split('T')[0];
        let consultar_hasta = new Date(this.getField("hasta").getValue()).toISOString().split('T')[0];

        if(consultar_desde > consultar_hasta){
            this.getField('consultar').setDisabled(true);
            this.getField("desde").setError(true,"No puede ser menor a la fecha desde.");
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }

        if(consultar_hasta > fecha_actual){
            this.getField('consultar').setDisabled(true);
            this.getField("hasta").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("hasta").setError(false,"");
        }
        
        let diferencia=new Date(consultar_hasta).getTime()-new Date(consultar_desde).getTime();
        diferencia = diferencia / 1000 / 60 / 60 / 24;
        if(diferencia >this.dias_maximo){
            this.getField("desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('consultar').setDisabled(true); 
            return false;
        }else{
            this.getField('consultar').setDisabled(false);
            this.getField("desde").setError(false,"");
        }
        return true;

    }

    limpiar(){
        this.getField('consultar').setDisabled(false);
        this.getField('imprimir').setDisabled(true);
        this.getField('rejilla').toggle(false)
    }

    consultarInforme(){
  
        this.getField('radio_tipo').setError(false,'');
        this.getField('desde').setError(false,'');
        this.getField('hasta').setError(false,'');
        let errores=0;
        if(this.validarfechasConsulta()===false)
            errores++;
        if(!this.getField('radio_tipo').valid())
            errores++;

        
        if (errores===0) {
            let datos = { datos: {
                desde:this.getField("desde").getValue(),
                hasta:this.getField("hasta").getValue(),
                radio_tipo:this.getField("radio_tipo").getValue(),
                value:'todosloscampos',
                operacion:'pantalla',
                sucursal_ingreso:this.sucursal_ingreso
            }};
            this.mostrarMensajeGenerando();
            this.getField("rejilla").setTipoServicioOperacion("inve-informesportipodeproducto-archivoitems");
            this.getField("rejilla").sendRequest(this.successDatosInforme, datos);
        }

    }
    
    successDatosInforme(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('consultar').setDisabled(true);
        if(data.estado_p === 200){
            this.getField('imprimir').setDisabled(false);
            let configCell = new Map();
            configCell.set('cantidad', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('costo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('valor_item', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('peso_total', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.peso_total) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('contenido', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.contenido) }, cellStyle: { textAlign: "right" }, width: 110 });
            
            configCell.set('valor_sin_iva', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.valor_sin_iva) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('costo_total', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo_total) }, cellStyle: { textAlign: "right" }, width: 110 });
            
            
            this.gridOptionsTbPrincipal['rowData'] = data.data.datos_items;            
            this.getField('rejilla').initData(this.gridOptionsTbPrincipal,configCell);
        }else{
            this.mostrarMensajeNoHayDatos();
            this.getField('rejilla').toggle(false);
        }
    }

    currencyFormatterGeneral(number)
    {

        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal!==undefined && decimal.length>2){
                number=number.toFixed(this.numero_decimales);
            }
        decimal = (number + "").split(".")[1];//para manejar los decimales
        return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
        return this.formatNumberSaldo(number); }
             
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
        let opcion=this.getField('radio_tipo').getValue();
        if(opcion==='ajustes')
            this.nombre_archivo='informe_tipop_ajustes';
        else if(opcion==='ventas')
            this.nombre_archivo='informe_tipop_ventas';
        else if(opcion==='devoluciones')
            this.nombre_archivo='informe_tipop_devventas';
        else if(opcion==='traslados')
            this.nombre_archivo='informe_tipop_traslados';
        else if(opcion==='ordenes')
            this.nombre_archivo='informe_tipop_ordenesp';

    }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla();
                    }else if(this.operacion_actual==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos = { datos: {
            desde:this.getField("desde").getValue(),
            hasta:this.getField("hasta").getValue(),
            radio_tipo:this.getField("radio_tipo").getValue(),
            operacion:this.operacion_actual,
            sucursal_ingreso:this.sucursal_ingreso,
            email:this.getField('email').getValue(),
            radio_correo:this.getField('radio_correo').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-informesportipodeproducto', operacion: "archivoitems_pag", operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla()
    {       
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 =  {
            desde:this.getField("desde").getValue(),
            hasta:this.getField("hasta").getValue(),
            radio_tipo:this.getField("radio_tipo").getValue(),
            operacion:this.operacion_actual,
            sucursal_ingreso:this.sucursal_ingreso,
            email:this.getField('email').getValue(),
        };

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-informesportipodeproducto","operacion":"archivoitems_pag","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close(); 
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla()
    {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 =  {
            desde:this.getField("desde").getValue(),
            hasta:this.getField("hasta").getValue(),
            radio_tipo:this.getField("radio_tipo").getValue(),
            operacion:this.operacion_actual,
            sucursal_ingreso:this.sucursal_ingreso,
            email:this.getField('email').getValue(),
        };
        
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-informesportipodeproducto","operacion":"archivoitems_pag","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = this.nombre_archivo+'.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarModalLista(){
        this.mostrarMensajeGenerando();
        let datos = { datos: {
            desde:this.getField("desde").getValue(),
            hasta:this.getField("hasta").getValue(),
            radio_tipo:this.getField("radio_tipo").getValue(),
            operacion:this.operacion_actual,
            sucursal_ingreso:this.sucursal_ingreso,
            email:this.getField('email').getValue(),
        }};

        this.getField("rejilla_items_result").setTipoServicioOperacion("inve-informesportipodeproducto-archivoitems");
        this.getField("rejilla_items_result").sendRequest(this.sucessTraerDatos, datos);
        
    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
            {
                "empresa": this.lista.empresa,
                "nit": this.lista.nit,
                "titulo":this.lista.titulo,
                "fecha_sistema": this.lista.fecha_sistema,
            };
            datosFicha.push(data2);

            this.getField('lista_datos').setItemsFichas(datosFicha);
            let configCell = new Map();
            configCell.set('cantidad', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('costo', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('valor_item', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('peso_total', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.peso_total) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('contenido', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.contenido) }, cellStyle: { textAlign: "right" }, width: 110 });
            
            configCell.set('valor_sin_iva', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.valor_sin_iva) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('costo_total', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.costo_total) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('peso', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.peso) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('valor', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.valor) }, cellStyle: { textAlign: "right" }, width: 110 });
            configCell.set('total_sin_iva', { cellRenderer: (props) => { return "" + this.currencyFormatterGeneral(props.data.total_sin_iva) }, cellStyle: { textAlign: "right" }, width: 110 });


            this.getField('rejilla_items_result').toggle(true);

            this.gridOptionsConsultarModal['rowData'] = [];
            this.gridOptionsConsultarModal['rowData'] = data.data.datos_items;
            this.getField('total_costo').setVisible(true);
            this.getField('total_valor').setVisible(true);
            this.getField('total_cantidad').setVisible(true);
            this.getField('total_peso').setVisible(true);

            this.getField('total_costo').setValue(this.arreglarDecimales(this.lista.total_costo));
            this.getField('total_valor').setValue(this.arreglarDecimales(this.lista.total_valor));
            this.getField('total_cantidad').setValue(this.arreglarDecimales(this.lista.total_cantidad));
            this.getField('total_peso').setValue(this.arreglarDecimales(this.lista.total_peso));


            configCell.set('cantidad_por_unidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_por_unidad.toFixed(this.numero_decimales)) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla_items_result').initData(this.gridOptionsConsultarModal,configCell);
            this.getField('modal_result').handleClickOpen();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    onSelectionChanged(){

    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales);
        }
        return numero_valor;
    }
   

}
FormJaivana.addController("inve-informesportipodeproducto", CustomInformesPorTipoProducto);
export default CustomInformesPorTipoProducto;