import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomDocumentosRecibidos
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 **/
class CustomDocumentosRecibidos extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);

    this.onSelectionChangedPrincipal                                  = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChangedPrincipal;

    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.onSelectionChangedXml                                  = this.onSelectionChangedXml.bind(this);
    this.gridOptionsXml = Object.assign({}, this.gridOptions);
    this.gridOptionsXml['onSelectionChanged']           = this.onSelectionChangedXml;
    
    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.sucessconsultarDocumentosRecibidos                                  = this.sucessconsultarDocumentosRecibidos.bind(this);
    this.consultarDocumentosRecibidos                                  = this.consultarDocumentosRecibidos.bind(this);
    this.validarConsultar                                  = this.validarConsultar.bind(this);
    this.validarCamposFecha                                  = this.validarCamposFecha.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);

    this.ocultarCampos                                  = this.ocultarCampos.bind(this);

    this.isJson                         =this.isJson.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);

    this.sucessTraerDatosDocumentos                         =this.sucessTraerDatosDocumentos.bind(this);
    this.arreglarDecimales                         =this.arreglarDecimales.bind(this);
    this.verEventos                         =this.verEventos.bind(this);
    this.succesVerEventos                         =this.succesVerEventos.bind(this);
    this.verXml                         =this.verXml.bind(this);
    this.succesVerXml                         =this.succesVerXml.bind(this);
    this.verPdf                         =this.verPdf.bind(this);
    this.successVerPdf                         =this.successVerPdf.bind(this);


    this.enviarReclamo                         =this.enviarReclamo.bind(this);
    this.succesEnviarReclamo                         =this.succesEnviarReclamo.bind(this);
    this.enviarConcepto                         =this.enviarConcepto.bind(this);
    this.setColorEstado                         =this.setColorEstado.bind(this);
    this.succesOperacion                         =this.succesOperacion.bind(this);
    this.generarUrlsCufe                         =this.generarUrlsCufe.bind(this);
    this.createRequest                         =this.createRequest.bind(this);
    this.successCufe2                         =this.successCufe2.bind(this);

    

    this.gridOptionsEventos = Object.assign({}, this.gridOptions);
    this.gridOptionsEventos['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsRetenciones = Object.assign({}, this.gridOptions);
    this.gridOptionsRetenciones['onSelectionChanged']           = this.onSelectionChanged;
    
    this.gridOptionsArticulos = Object.assign({}, this.gridOptions);
    this.gridOptionsArticulos['onSelectionChanged']           = this.onSelectionChanged;


    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.fecha_inicio='';
    this.dias_maximo='';
    this.sucursal_ingreso='';


    this.fila_seleccionada=[];
    this.boton='';
    this.nombre_archivo='';
    this.documento_servicio='';
    this.documento_operacion='';
    this.opeacion_ch='TODO';

    //reclamos
    this.index=0;
    this.registro={};
    this.num_doc = '';
    this.nit_doc = '';
    this.estado_reclamo = '';
    this.estado_acuse = '';
    this.estado_aceptacion = '';
    this.estado_recibo = '';

    //pantallas autorizadas
    this.env_acuse=false;
    this.env_recibo=false;
    this.env_acep=false;
    this.env_recl=false;
    this.facturacion_electronica_propia='';
    this.api_descargar_desde_dian='';
  }
  
  initForm() {
    console.log("Formulario CustomDocumentosRecibidos,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

    //nuevo para consulta
    this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
    this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);
    this.getField('consultar').setClick(this.validarConsultar);

    this.getField('nit').setOnChange(()=>{
        this.getField('sede').setError(false,'');
        this.getField('nombre').setError(false,'');
        this.ocultarCampos();
    });
    this.getField('pedido').setOnChange(()=>{
        this.ocultarCampos();
        
    });

    this.getField('numero').setOnChange(()=>{
        this.ocultarCampos();
    });


    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('consultar_desde').setValue(this.fecha_inicio);


    //imprimir
    this.getField('radio_correo').setValue("N");
    this.getField('generar_archivo').setClick(()=>this.procesar());

    //para balances globales
    this.getField('ch_todo').setValueSwitch(true);
    this.getField('ch_todo').setOnChange((props)=>{
      if(this.getField('ch_todo').getValue()==='false'){
        this.ocultarCampos();
        this.getField('ch_cr').setValueSwitch (false);
        this.getField('ch_co').setValueSwitch (false);
        this.opeacion_ch='TODO';
      }else{
        this.getField('ch_todo').setValueSwitch(true);
      }
    });
    this.getField('ch_co').setOnChange((props)=>{
      if(this.getField('ch_co').getValue()==='false'){
        this.ocultarCampos();
        this.getField('ch_cr').setValueSwitch (false);
        this.getField('ch_todo').setValueSwitch (false);
        this.opeacion_ch='CO';
      }else{
        this.getField('ch_co').setValueSwitch(true);
      }
    });
    this.getField('ch_cr').setOnChange((props)=>{
      if(this.getField('ch_cr').getValue()==='false'){
        this.ocultarCampos();
        this.getField('ch_co').setValueSwitch (false);
        this.getField('ch_todo').setValueSwitch (false);
        this.opeacion_ch='CR';
      }else{
        this.getField('ch_cr').setValueSwitch(true);
      }
    });


    //botones----------->
    this.getField('bt_enviar_acuse').setClick(()=>{
        if(this.env_acuse===false){
            this.mostrarMensajeAdicional('OPCIÓN NO AUTORIZADA! (ENV_ACUSE)');
            return;
        }
        if(this.fila_seleccionada.length>0){
            this.documento_servicio='comp-documentosrecibidos';
            this.documento_operacion='enviaracuse';
            this.enviarOperacion();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro o más.', 'error');
    });

    this.getField('bt_enviar_recibo').setClick(()=>{
        if(this.env_recibo===false){
            this.mostrarMensajeAdicional('OPCIÓN NO AUTORIZADA! (ENV_RECIBO)');
            return;
        }
        if(this.fila_seleccionada.length>0){
            this.documento_servicio='comp-documentosrecibidos';
            this.documento_operacion='enviarrecibo';
            this.enviarOperacion();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro o más.', 'error');
    });

    this.getField('bt_enviar_aceptacion_expresa').setClick(()=>{
        if(this.env_acep===false){
            this.mostrarMensajeAdicional('OPCIÓN NO AUTORIZADA! (ENV_ACEP)');
            return;
        }
        if(this.fila_seleccionada.length>0){
            this.documento_servicio='comp-documentosrecibidos';
            this.documento_operacion='enviaraceptacion';
            this.enviarOperacion();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro o más.', 'error');
    });

    this.getField('bt_enviar_reclamo').setClick(()=>{
        if(this.env_recl===false){
            this.mostrarMensajeAdicional('OPCIÓN NO AUTORIZADA! (ENV_RECL)');
            return;
        }
        if(this.fila_seleccionada.length>0){
            this.documento_servicio='comp-documentosrecibidos';
            this.documento_operacion='enviarreclamo';
            this.index=0;
            this.enviarConcepto();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro o más.', 'error');
    });

    this.getField('bt_ver_eventos').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.documento_servicio='cont-consultadocumentos';
            this.documento_operacion='1_1';
            this.verEventos();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro.', 'error');
    });

    this.getField('bt_ver_xml').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.documento_servicio='cont-consultadocumentos';
            this.documento_operacion='1_2';
            this.verXml();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro.', 'error');
    });

    this.getField('bt_ver_doc_recibido').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.documento_servicio='comp-documentosrecibidos';
            this.documento_operacion='1_xmldocrecibidos';
            this.verXml();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro.', 'error');
    });

    this.getField('bt_ver_pdf').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.documento_servicio='comp-documentosrecibidos';
            this.documento_operacion='verpdf';
            this.verPdf();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro.', 'error');
    });

    this.getField('bt_descargar_por_cufe').setClick(()=>{this.getField('modal_cufe').handleClickOpen();});
    this.getField('cancelar_cufe').setClick(()=>{this.getField('modal_cufe').handleClose();});
    this.getField('aceptar_cufe').setClick(()=>{this.generarUrlsCufe();});

    //modal conceptos
    this.getField('bt_cancelar_concepto').setClick(()=>{this.enviarReclamo(1);});
    this.getField('bt_aceptar_concepto').setClick(()=>{this.enviarReclamo(0);});
  }
  successCufe2(data){
    this.getField('confirmModalCustom').toggle(true);
    this.totalResponse += 1;
    this.responseCufe += data.data.mensaje+',';
    if(this.totalResponse === this.totalRequest){
        this.alertGeneral.toggle(true,this.responseCufe,'success');
        this.getField("modal_cufe").handleClose();
    }

    if(data.data.mensaje!==undefined)
        this.mostrarMensajeAdicional(data.data.mensaje);

    
}

  generarUrlsCufe(){
    if(this.getField('text_cufe').valid()){
        let cufes=this.getField('text_cufe').getValue().split("\n");
        for(let i=0; i<cufes.length; i++){
            let item = cufes[i];
            if(item !== ''){
                this.mostrarMensajeGenerando();
                let url = this.facturacion_electronica_propia+this.api_descargar_desde_dian+item;
                this.createRequest(url,this.successCufe2);
            }else
                this.totalRequest -= 1;
        }
    }
  }

  createRequest(url, successR){
    let xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
        xhr.setRequestHeader('Content-Type','application/json');
        xhr.onload = function()
        {
            const decoder = new TextDecoder("UTF-8");//new TextDecoder("iso-8859-1");
            const buffer = xhr.response;//new DataView(xhr.response);
            let data ={};
            if(buffer !== undefined && buffer !== null && buffer.byteLength > 0)
            {
                try
                {
                    data = JSON.parse(decoder.decode(buffer));
                    data["estado_p"] = xhr.status;
                    
                }
                 catch (err)
                {

                }
            }else{
                data['data'] = {
                    mensaje: "Error descargando archivo"
                };
                data["estado_p"] = xhr.status;
            }
            successR(data);
        }
        xhr.send({});
}

    enviarReclamo(opcion){
        if(opcion===0){
            if(this.getField('select_conceptos').valid()){
                let array_aux=[];
                this.registro=this.fila_seleccionada.at(this.index);
                this.registro.codigo_reclamo=this.getField('select_conceptos').getValue();
                array_aux.push(this.registro);
                let datos={ datos: {
                    array_registros:array_aux,
                    sucursal_ingreso:this.sucursal_ingreso,
                }};
                this.generalFormatPmv = { tipo_servicio: this.documento_servicio, operacion: this.documento_operacion, operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.succesEnviarReclamo,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }else{
            this.getField('modal_conceptos').handleClose();
            this.index+=1;
            this.enviarConcepto();
        }
        
    }

    succesEnviarReclamo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.getField('modal_conceptos').handleClose();
            if(data.data.mensaje_mostrar!==undefined){
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    if(data.data.mensaje_mostrar_2!==undefined){
                        this.getField('confirmModalCustom').toggle(false);
                        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar_2);
                        this.getField('confirmModalCustom').setClickDialog(() => { 
                            this.getField('confirmModalCustom').toggle(false);
                            this.index+=1;
                            this.enviarConcepto();
                        });
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                    }else{
                        this.index+=1;
                        this.enviarConcepto();
                    }
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else if(data.data.mensaje_mostrar_2!==undefined){
                ///es cuando va y envia a la dian y hace un update antes, entonces es necesario refrescar
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar_2);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    this.index+=1;
                    this.enviarConcepto();
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                //nothing
            }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    enviarConcepto(){
        if(this.index<=this.fila_seleccionada.length-1){
            this.registro=this.fila_seleccionada.at(this.index);
            this.num_doc = this.registro.numero_documento;
            this.nit_doc = this.registro.nit_documento;
            this.estado_reclamo = this.registro.estado_reclamo_dian;
            this.estado_acuse = this.registro.estado_acuse_dian;
            this.estado_aceptacion = this.registro.estado_aceptacion_dian;
            this.estado_recibo = this.registro.estado_recibo_dian;

            if (this.estado_acuse==="a" && this.estado_recibo==="a" && this.estado_reclamo==="." && this.estado_aceptacion===".") {
                this.getField('modal_conceptos').setLabel("RECLAMO PARA DOCUMENTO #"+this.num_doc);
                this.getField('modal_conceptos').handleClickOpen();
            }else{
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El reclamo Dian para el documento #' + this.num_doc + ' no puede ser enviado.');
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    this.index+=1;
                    this.enviarConcepto();
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);

            }
        }else{
            this.consultarDocumentosRecibidos();
        }
    }


    verPdf(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            id_doc:this.fila_seleccionada[0].id,
            sucursal_ingreso:this.sucursal_ingreso,
        }};
        this.generalFormatPmv = { tipo_servicio: this.documento_servicio, operacion: this.documento_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successVerPdf,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successVerPdf(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            window.open(data.data.url, '_blank', 'noreferrer');
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

  verXml(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            id:this.fila_seleccionada[0].id
        }};
        this.generalFormatPmv = { tipo_servicio: this.documento_servicio, operacion: this.documento_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesVerXml,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    succesVerXml(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.getField('modal_xml').setLabel("XML Documento #"+data.data[0].numero_documento);
            this.getField('modal_xml').handleClickOpen();

            this.getField('rejilla_xml').toggle(true);
            let configCell = new Map();
            this.gridOptionsXml['rowData'] = data.data;
            this.getField('rejilla_xml').initData(this.gridOptionsXml, configCell);
            this.getField('factura_xml').setVisible(true)
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

  verEventos(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            id:this.fila_seleccionada[0].id
        }};
        this.generalFormatPmv = { tipo_servicio: this.documento_servicio, operacion: this.documento_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesVerEventos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    succesVerEventos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.getField('modal_eventos').handleClickOpen();
            this.getField('rejilla_eventos').toggle(true);
            let configCell = new Map();
            this.gridOptionsEventos['rowData'] = data.data;
            this.getField('rejilla_eventos').initData(this.gridOptionsEventos, configCell);
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    enviarOperacion(){
        this.mostrarMensajeGenerando()
        let datos={ datos: {
            array_registros:this.fila_seleccionada,
            sucursal_ingreso:this.sucursal_ingreso,
        }};
        this.generalFormatPmv = { tipo_servicio: this.documento_servicio, operacion: this.documento_operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesOperacion,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    succesOperacion(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            if(data.data.mensaje_mostrar!==undefined){
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    if(data.data.mensaje_mostrar_2!==undefined){
                        this.getField('confirmModalCustom').toggle(false);
                        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar_2);
                        this.getField('confirmModalCustom').setClickDialog(() => { 
                            this.getField('confirmModalCustom').toggle(false);
                            this.consultarDocumentosRecibidos();
                        });
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                    }else{
                        this.consultarDocumentosRecibidos();
                    }
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else if(data.data.mensaje_mostrar_2!==undefined){
                ///es cuando va y envia a la dian y hace un update antes, entonces es necesario refrescar
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar_2);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    this.consultarDocumentosRecibidos();
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                //nothing
            }
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }

    sucessTraerDatosDocumentos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": lista.empresa,
                    "nit_xml":  lista.nit_xml,
                    "titulo": lista.titulo,
                    "fecha_informe": lista.fecha_informe,
                    "usuario": lista.usuario,
                };

            datosFicha.push(data2);
            this.getField('modal_documentos').handleClickOpen();

            let configCell = new Map();
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_item) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});

            //resumen--->
            this.getField('lista_documentos').setItemsFichas(datosFicha);
            this.getField('total_general_documentos').setValue(this.arreglarDecimales(lista.total_general));
            this.getField('rejilla_documentos').toggle(true);
            this.gridOptionsArticulos['rowData'] = [];
            this.gridOptionsArticulos['rowData'] = data.data.datos_items;
            this.getField('rejilla_documentos').initData(this.gridOptionsArticulos,configCell);
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


  ocultarCampos(){
    this.fila_seleccionada=[];
    this.getField('consultar').setDisabled(false);
    this.getField('bt_enviar_acuse').setVisible(false);
    this.getField('bt_enviar_recibo').setVisible(false);
    this.getField('bt_enviar_aceptacion_expresa').setVisible(false);
    this.getField('bt_enviar_reclamo').setVisible(false);
    this.getField('bt_ver_eventos').setVisible(false);
    this.getField('bt_ver_xml').setVisible(false);
    this.getField('bt_ver_pdf').setVisible(false);
    this.getField('bt_descargar_por_cufe').setVisible(false);
    this.getField('bt_ver_doc_recibido').setVisible(false);
    this.getField('tabla_documentos').toggle(false);
    
  }

  traerPropiedades(){
    let datos={ datos: {
        sucursal_ingreso:this.sucursal_ingreso,
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-documentosrecibidos', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
}

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
            this.dias_maximo=data.data.consulta_devoluciones_dias_devolver;
            this.docsopacomp=data.data.docsopacomp;
            this.env_acuse=data.data.env_acuse;
            this.env_recibo=data.data.env_recibo;
            this.env_acep=data.data.env_acep;
            this.env_recl=data.data.env_recl;
            this.facturacion_electronica_propia=data.data.facturacion_electronica_propia;
            this.api_descargar_desde_dian=data.data.api_descargar_desde_dian;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        this.validarCamposFecha();
    }

  validarCamposFecha(){
        this.ocultarCampos();
        this.getField('consultar').setDisabled(false);
        this.getField("consultar_desde").setError(false,'');
        this.getField("consultar_hasta").setError(false,'');
        let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
        let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
        let fecha_actual = new Date().toISOString().split('T')[0];

        if (fechaInicial > fechaFinal) {
            this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
            this.getField('consultar').setDisabled(true);
            return false;
        } 

        if(fechaFinal > fecha_actual){
            this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        let diferencia=new Date(fechaFinal).getTime()-new Date(fechaInicial).getTime();
        diferencia = diferencia / 1000 / 60 / 60 / 24;
        if(diferencia >this.dias_maximo){
            this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('consultar').setDisabled(true); 
            return false;
        }

        this.getField('consultar_hasta').setError(false,"");
        return true;
    }

  validarConsultar(){
    this.getField('tabla_documentos').toggle(false);
    this.getField('nit').setError(false,'');
    this.getField('sede').setError(false,'');
    this.getField('numero').setError(false,'');
    this.getField('pedido').setError(false,'');

    let errores=0;
    if(!this.getField('consultar_hasta').valid())
        errores++;
    if(this.validarCamposFecha()===false)
        errores++;
    if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
        if(!this.getField('nit').valid())
            errores++;
        else if(!this.getField('sede').valid())
            errores++;
    }
    if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' '){
        if(!this.getField('numero').valid())
            errores++;
    }
    if(this.getField('pedido').getValue()!=='' && this.getField('pedido').getValue()!==' '){
        if(!this.getField('pedido').valid())
            errores++;
    }
    
    if(errores===0){
        this.consultarDocumentosRecibidos();
    }

  }

  onSelectionChanged(){
    
  }
  onSelectionChangedXml(){
    let fila = this.gridOptionsXml.api.getSelectedRows();
    this.getField('factura_xml').setValue(fila[0].factura_xml);
  }


  onSelectionChangedPrincipal(){
    this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
  }



  consultarDocumentosRecibidos(){
        this.fila_seleccionada=[];
        this.mostrarMensajeGenerando();
        this.getField('consultar').setDisabled(true);
        let datos={ datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
            sucursal_ingreso:this.sucursal_ingreso,
            opcion:this.opeacion_ch,
        }};
        if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
            datos.datos.nit=this.getField('nit').getValue();
            datos.datos.sede=this.getField('sede').getValue();
        }
        if(this.getField('pedido').getValue()!=='' && this.getField('pedido').getValue()!==' ')
            datos.datos.pedido=this.getField('pedido').getValue();
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
            datos.datos.numero=this.getField('numero').getValue();

        this.getField("tabla_documentos").setTipoServicioOperacion("comp-documentosrecibidos-consultar");
        this.getField("tabla_documentos").sendRequest(this.sucessconsultarDocumentosRecibidos, datos);

    }
    setColorEstado(estado,params){
        if(estado==='.'){
            params["backgroundColor"] = 'rgb(37,96,232)';  //azul
            params["color"] = 'rgb(255,255,255)';
        }else if(estado==='p'){ //AMARILLO
            params["backgroundColor"] = 'rgb(238,238,0)';  
            params["color"] = 'rgb(0,0,0)';
        }else if(estado==='a'){//verde
            params["backgroundColor"] = 'rgb(0,238,0)';  
            params["color"] = 'rgb(0,0,0)';
        }else if(estado==='e'){//rojo
            params["backgroundColor"] = 'rgb(238,0,0)';  
            params["color"] = 'rgb(0,0,0)';
        }
        return params;
    }

    sucessconsultarDocumentosRecibidos(data){
        this.getField('confirmModalCustom').toggle(false);
        this.fila_seleccionada=[];
        if(data.estado_p === 200){
            this.getField('bt_enviar_acuse').setVisible(true);
            this.getField('bt_enviar_recibo').setVisible(true);
            this.getField('bt_enviar_aceptacion_expresa').setVisible(true);
            this.getField('bt_enviar_reclamo').setVisible(true);
            this.getField('bt_ver_eventos').setVisible(true);
            this.getField('bt_ver_xml').setVisible(true);
            this.getField('bt_ver_pdf').setVisible(true);
            this.getField('bt_descargar_por_cufe').setVisible(true);
            this.getField('bt_ver_doc_recibido').setVisible(true);
            this.gridOptionsComponentes["rowSelection"] = "multiple";
            
            let configCell = new Map();
            configCell.set('valor_sin_impuesto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_sin_impuesto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_con_impuesto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_con_impuesto) }, cellStyle: {textAlign:"right"},width: 110});
 
            configCell.set('estado_acuse_dian', {  cellStyle : params =>  this.setColorEstado(params.data.estado_acuse_dian,params)});
            configCell.set('estado_recibo_dian', {  cellStyle : params =>  this.setColorEstado(params.data.estado_recibo_dian,params)});
            configCell.set('estado_aceptacion_dian', {  cellStyle : params =>  this.setColorEstado(params.data.estado_aceptacion_dian,params)});
            configCell.set('estado_reclamo_dian', {  cellStyle : params =>  this.setColorEstado(params.data.estado_reclamo_dian,params)});


            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('tabla_documentos').toggle(true);
            this.getField('tabla_documentos').initData(this.gridOptionsComponentes,configCell);
            
            
        }else{
            this.ocultarCampos();
            if(data.estado_p===404) {
                this.mostrarMensajeNoHayDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }
    
      mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }


    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGuardando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
   }


   
}


FormJaivana.addController("comp-documentosrecibidos", CustomDocumentosRecibidos);
export default CustomDocumentosRecibidos;