import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Anderson Acevedo
 * 
 * @version jdesk_1.01.0002
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomCostear extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm = this.initForm.bind(this);

        this.consultarCompra = this.consultarCompra.bind(this);
        this.gridOptionsTbPrincipal = Object.assign({}, this.gridOptions);
        this.gridOptionsIVa = Object.assign({}, this.gridOptions);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptionsIVa['onSelectionChanged'] = this.onSelectionChanged;



        this.ocultarCampos = this.ocultarCampos.bind(this);
        this.recalcular = this.recalcular.bind(this);
        this.sucessRecalcular = this.sucessRecalcular.bind(this);

        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);
        this.traerPropiedades = this.traerPropiedades.bind(this);
        this.sucesstraerPropiedades = this.sucesstraerPropiedades.bind(this);
        this.successCrearTemporal = this.successCrearTemporal.bind(this);
        this.successDatosPrincipal = this.successDatosPrincipal.bind(this);
        this.arreglarDecimales = this.arreglarDecimales.bind(this);
        this.procesar = this.procesar.bind(this);
        this.sucessProcesar = this.sucessProcesar.bind(this);


        this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
        this.sucursal_ingreso = '';
        this.numero_decimales = 2;
        this.dias_maximo=0;
    }

    initForm() {
        console.log('Formulario CustomCostear,  @version: jdesk_1.01.0002, @author:Anderson Acevedo');
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedades();
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.ocultarCampos();
        this.getField("select_anio").setValue(año);
        this.getField("select_mes").setValue(hoy.getMonth());


        this.getField("consultar").setClick(this.consultarCompra);
        this.getField("recalcular").setClick(this.recalcular);
        this.getField("procesar").setClick(this.procesar);
        this.getField("select_anio").setOnChange(this.ocultarCampos);
        this.getField("select_mes").setOnChange(this.ocultarCampos);
        
    }

    procesar(){
        this.getField('procesar').setDisabled(true);
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            sucursal_ingreso:this.sucursal_ingreso
        }};
            this.generalFormatPmv = { tipo_servicio: 'inve-costear', operacion: 'procesar', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessProcesar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }
    
    sucessProcesar(data){
        this.getField('confirmModalCustom').toggle(false);
          if (data.estado_p === 200 ){
            if(data.data.mensaje_mostrar!==undefined){
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
            }else{
                this.getField('modal_result').handleClickOpen();
                if(data.data.tarifa_iva.length>0){
                    this.gridOptionsIVa['rowData'] = data.data.tarifa_iva;
                    let configCell = new Map();
                    configCell.set('iva', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.iva) }, type: 'rightAligned' });
                    configCell.set('xcosto', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.xcosto) }, type: 'rightAligned' });

                    this.getField('rejilla_iva').initData(this.gridOptionsIVa, configCell);
                    this.getField('rejilla_iva').toggle(true);
                }else{
                    this.getField('rejilla_iva').toggle(false);
                }
                
                this.getField('exento').setValue(data.data.exentos);
                this.getField('excluido').setValue(data.data.excluidos);
            }
          }else 
          {
            this.getField('procesar').setDisabled(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
          }
          
      }

    recalcular(){
        this.getField('procesar').setDisabled(true);
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            sucursal_ingreso:this.sucursal_ingreso
        }};
            this.generalFormatPmv = { tipo_servicio: 'inve-costear', operacion: 'recalcular', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.sucessRecalcular,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }
    
    sucessRecalcular(data){
        this.getField('confirmModalCustom').toggle(false);
          if (data.estado_p === 200 ){
            let datos = {
                datos: {
                    sucursal_ingreso:this.sucursal_ingreso
                }
            };
            this.mostrarMensajeGenerando();
            this.getField("rejilla").setTipoServicioOperacion('inve-costear-archivoitems');
            this.getField("rejilla").sendRequest(this.successDatosPrincipal, datos);
          }else 
          {
            this.getField('procesar').setDisabled(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
          }
          
      }

    traerPropiedades() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'inve-costear', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.sucesstraerPropiedades,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    sucesstraerPropiedades(data) {
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200) {
            this.numero_decimales = data.data.numero_decimales;
            this.dias_maximo=data.data.dias_devolver;

            let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.anios.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.anio;
            dataOp['text'] = item.anio;
            dataOp['campos_cambian'] = { };
            opciones.push(dataOp); })
            this.getField('select_anio').setOptions(opciones);

            opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
            data.data.meses.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.num;
            dataOp['text'] = item.mes;
            dataOp['campos_cambian'] = { };
            opciones.push(dataOp); })
            this.getField('select_mes').setOptions(opciones);

        } else if (data.estado_p === 404) {
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }

    }



    onSelectionChanged() {

    }

    ocultarCampos() {
        this.fila_seleccionada=[];
        this.getField('rejilla').toggle(false);
        this.getField('consultar').setDisabled(false);
        this.getField('recalcular').setDisabled(true);
        this.getField('procesar').setDisabled(true);
    }



    consultarCompra() {
        let errores = 0;
        this.ocultarCampos();
        this.getField('select_anio').setError(false,'');
        this.getField('select_mes').setError(false,'');
        if (!this.getField('select_anio').valid())
            errores++;
        if (!this.getField('select_mes').valid())
            errores++;

        if (errores === 0) {
            this.getField("consultar").setDisabled(true);
            let datos = {
                datos: {
                    select_anio: this.getField("select_anio").getValue(),
                    select_mes: this.getField("select_mes").getValue(),
                    sucursal_ingreso: this.sucursal_ingreso,
                }
            };
            this.mostrarMensajeGenerando();
            this.generalFormatPmv = { tipo_servicio: 'inve-costear', operacion: 'generardatos', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: datos,
                    success: this.successCrearTemporal,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successCrearTemporal(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            let datos = {
                datos: {
                    sucursal_ingreso:this.sucursal_ingreso
                }
            };
            this.mostrarMensajeGenerando();
            this.getField("rejilla").setTipoServicioOperacion('inve-costear-archivoitems');
            this.getField("rejilla").sendRequest(this.successDatosPrincipal, datos);
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }


    successDatosPrincipal(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.gridOptionsTbPrincipal['rowData'] = data.data;
            let configCell = new Map();
            configCell.set('cantidad', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.cantidad) }, type: 'rightAligned' });
            configCell.set('costo', { cellRenderer: (props) => { return '' + this.currencyFormatterGeneral(props.data.costo) }, type: 'rightAligned' });

            this.getField('rejilla').initData(this.gridOptionsTbPrincipal, configCell);
            this.getField('rejilla').toggle(true);
            this.getField('recalcular').setDisabled(false);
            this.getField('procesar').setDisabled(false);
        } else {
            this.mostrarMensajeNoHayDatos();
            this.getField('consultar').setDisabled(true);
        }
    }


    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > this.numero_decimales) {
                number = number.toFixed(this.numero_decimales); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


    mostrarMensajeAdicional(mensaje) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

    }

    mostrarMensajeNoHayDatos() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }


}
FormJaivana.addController("inve-costear", CustomCostear);
export default CustomCostear