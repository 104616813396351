import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomArticulos
 * @author: Patricia Lopez Sanchez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomArticulos extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.validarcampos              = this.validarcampos.bind(this);
        this.validarcampos1             = this.validarcampos1.bind(this);
        this.validarcampos2             = this.validarcampos2.bind(this);
        this.validarcampos3             = this.validarcampos3.bind(this);
        this.validarcampos4             = this.validarcampos4.bind(this);
        this.validarcampos5             = this.validarcampos5.bind(this);
        this.cargariva                  = this.cargariva.bind(this);
        this.validarbarras              = this.validarbarras.bind(this);
        this.successTraerBarra          = this.successTraerBarra.bind(this);
        this.validarbarras2             = this.validarbarras2.bind(this);
        this.successTraerBarra2         = this.successTraerBarra2.bind(this);
        this.codigo                     = this.codigo.bind(this);
        this.successCodigo              = this.successCodigo.bind(this);
        this.CrearArticulo              = this.CrearArticulo.bind(this);
        this.successArticulo            = this.successArticulo.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
        this.validarCodigoBarrasPrincipal   = this.validarCodigoBarrasPrincipal.bind(this);
        this.confirmarLimpiar           = this.confirmarLimpiar.bind(this);
    }
    
    initForm(){
        console.log('Formulario CustomArticulos,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez, @updateBy Jose Albeiro Marin')
        this.getField('aceptar').setClick(this.validarcampos);
        this.getField('cancelar').setClick(this.confirmarLimpiar);
        this.getField('aceptar1').setClick(this.validarcampos1);
        this.getField('cancelar1').setClick(this.confirmarLimpiar);
        this.getField('aceptar2').setClick(this.validarcampos2);
        this.getField('cancelar2').setClick(this.confirmarLimpiar);
        this.getField('aceptar3').setClick(this.validarcampos3);
        this.getField('cancelar3').setClick(this.confirmarLimpiar);
        this.getField('continuar').setClick(this.validarcampos4);
        this.getField('cancelar4').setClick(this.confirmarLimpiar);
        this.getField('continuar1').setClick(this.validarcampos5);
        this.getField('cancelar5').setClick(this.confirmarLimpiar);
        this.getField('continuar2').setClick(()=>{if(this.getField('barras').valid()){this.validarbarras()}});
        this.getField('cancelar6').setClick(this.confirmarLimpiar);
        this.getField('continuar3').setClick(this.CrearArticulo);
        this.getField('cancelar7').setClick(this.confirmarLimpiar);
        this.getField('codigo_cat2').setDisabled(true)
        this.getField('codigo_cat3').setDisabled(true)
        this.getField('codigo_cat4').setDisabled(true)
        this.getField('unidad_id').setOnChange(()=>{if(this.getField('unidad_id').getValue() !== ''){this.getField('unidad_id').valid(false);}})
        this.getField('codigo_grupo').setOnChange(this.getField('nombre_grupo').setValue(''));
        this.getField('codigo_subgrupo').setOnChange(this.getField('nombre_subgrupo').setValue(''));
        this.getField('codigo_marca').setOnChange(()=>{this.getField('nombre_marca').setValue('');this.getField('marca_id').setValue('')});
        this.getField('codigo_clasificacion').setOnChange(this.getField('nombre_clasificacion').setValue(''));
        this.getField('codigo_cat1').setOnChange(()=>{this.getField('nombre_categoria1').setValue('');this.getField('categoria1_id').setValue('')});
        this.getField('codigo_cat2').setOnChange(()=>{this.getField('nombre_categoria2').setValue('');this.getField('categoria2_id').setValue('')});
        this.getField('codigo_cat3').setOnChange(()=>{this.getField('nombre_categoria3').setValue('');this.getField('categoria3_id').setValue('')});
        this.getField('codigo_cat4').setOnChange(()=>{this.getField('nombre_categoria4').setValue('');this.getField('categoria4_id').setValue('')});
        this.getField('nombre_categoria1').setOnChange(()=>{if(this.getField('nombre_categoria1').getValue() !== ''){this.getField('codigo_cat2').setDisabled(false)}else{
            this.getField('codigo_cat2').setDisabled(true)
            this.getField('codigo_cat2').setValue('');
            this.getField('codigo_cat2').setError(false,'');
        }})
        this.getField('nombre_categoria2').setOnChange(()=>{if(this.getField('nombre_categoria2').getValue() !== ''){this.getField('codigo_cat3').setDisabled(false)}else{
            this.getField('codigo_cat3').setDisabled(true)
            this.getField('codigo_cat3').setValue('');
            this.getField('codigo_cat3').setError(false,'');
            
        }})
        this.getField('nombre_categoria3').setOnChange(()=>{if(this.getField('nombre_categoria3').getValue() !== ''){this.getField('codigo_cat4').setDisabled(false)}else{
            this.getField('codigo_cat4').setDisabled(true)
            this.getField('codigo_cat4').setValue('');
            this.getField('codigo_cat4').setError(false,'');
        }})
        this.getField('tarifas_retencion').setOnChange(()=>{if(this.getField('tarifas_retencion').getValue() !== ''){this.getField('tarifas_retencion').valid(false);}})
        this.getField('codigo_iva').setOnChange(()=>{this.getField('iva_ventas').setValue('');
        this.getField('iva_compras').setValue('');this.getField('iva').setValue('')});
        this.getField('iva_ventas').setOnChange(this.cargariva);
        this.getField('producto_importado').setOnChange(()=>{if(this.getField('producto_importado').getValue() !== ''){this.getField('producto_importado').valid(false);}})
        this.getField('TabJaivana_52').setClick(this.limpiar);
        this.getField('barras').setOnChange(this.validarCodigoBarrasPrincipal);
    }
    validarCodigoBarrasPrincipal(){
        this.getField('continuar2').setDisabled(false);   
    }

    validarcampos(){
        if (this.getField('nombre').valid() && this.getField('alias').valid()) {
            this.getField('TabJaivana_52').setNextTab()
        }
    }

    validarcampos1(){
        if (this.getField('codigo_grupo').valid() && this.getField('codigo_subgrupo').valid() && this.getField('codigo_subgrupo_1').valid() &&
        this.getField('codigo_marca').valid()  && this.getField('codigo_clasificacion').valid() &&
        this.getField('nombre_grupo').valid() && this.getField('nombre_subgrupo').valid() && this.getField('nombre_marca').valid() && 
        this.getField('nombre_clasificacion').valid() && this.getField('marca_id').valid()) {
            this.getField('TabJaivana_52').setNextTab()
        }
    }
    
    validarcampos2(){
        if (this.getField('nombre_categoria4').valid() && this.getField('categoria1_id').valid() && 
        this.getField('categoria2_id').valid() && this.getField('categoria3_id').valid() && this.getField('categoria4_id').valid()) {
            this.getField('TabJaivana_52').setNextTab()
        }
    }
    
    validarcampos3(){
        if (this.getField('unidad_id').valid() && this.getField('presenta').valid() && this.getField('empaque').valid() &&
        this.getField('contenido').valid() && this.getField('peso').valid()) {
            this.getField('TabJaivana_52').setNextTab()
        }
    }
    
    validarcampos4(){
        if (this.getField('tarifas_retencion').valid() && this.getField('codigo_iva').valid() && this.getField('codigo_tipo_producto').valid() &&
        this.getField('nombre_tipo').valid() && this.getField('iva_ventas').valid() && 
        this.getField('iva_compras').valid() && this.getField('iva').valid() && this.getField('gravado').valid() && 
        this.getField('exento').valid() && this.getField('excluido').valid() && this.getField('tipo_producto_id').valid()) {
            this.getField('TabJaivana_52').setNextTab()
        }
    }

    validarcampos5(){
        if (this.getField('producto_importado').valid() && this.getField('partida_arancelaria').valid()) {
            this.getField('TabJaivana_52').setNextTab()
        }
    }

    cargariva(){
        if (this.getField('iva_ventas').getValue() !== 0) {
            this.getField('iva').setValue('S')
        }else{
            this.getField('iva').setValue('N')
        }
    }

    validarbarras(){
        let datos={ datos: {barras: this.getField('barras').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerBarra,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successTraerBarra(data){
        if (data.estado_p === 200 )
        {
            this.getField('continuar2').setDisabled(true);   
            this.getField('barras').input.focus();
            this.alertGeneral.toggle(true, 'El Código de barras ya existe en la tabla artículo', 'error');
            
        }
        else 
        {
           if(data.estado_p===404) {
                this.validarbarras2();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    validarbarras2(){
        let datos={ datos: {barras: this.getField('barras').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '20_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerBarra2,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successTraerBarra2(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El Código de barras ya existe en la tabla barras artículo', 'error');
        }
        else 
        {
           if(data.estado_p===404) {
                this.codigo();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }
    
    codigo(){
        let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: 'generarcodigo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successCodigo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
            });
    }

    successCodigo(data){
        if (data.estado_p === 200 )
        {
            if(data.data.codigo_nuevo === ""){
                this.getField('TabJaivana_52').setNextTab()
            }else{
                this.alertGeneral.toggle(true, 'Último código creado ' + data.data.codigo_anterior, 'success');
                this.getField('codigo_interno').setValue(String(data.data.codigo_nuevo));
                this.getField('TabJaivana_52').setNextTab()
            }
        }
        else 
        {
           if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    CrearArticulo(){
        if (this.getField('codigo_interno').valid() && this.getField('codigo_alterno').valid()) {
            this.getField('vivac').setValue(this.getField('iva_ventas').getValue());
            this.getField('viva').setValue(this.getField('iva_ventas').getValue());
            this.datosAgregar={ datos:{}};
            this.datosAgregar.datos['nombre']=this.getField('nombre').getValue();
            this.datosAgregar.datos['codigo_subgrupo']=this.getField('codigo_subgrupo').getValue();
            this.datosAgregar.datos['codigo_grupo']=this.getField('codigo_grupo').getValue();
            this.datosAgregar.datos['codigo_interno']=this.getField('codigo_interno').getValue();
            this.datosAgregar.datos['peso']=this.getField('peso').getValue();
            this.datosAgregar.datos['codigo_subgrupo_1']=this.getField('codigo_subgrupo_1').getValue();
            this.datosAgregar.datos['exento']=this.getField('exento').getValue();
            this.datosAgregar.datos['barras']=this.getField('barras').getValue();
            this.datosAgregar.datos['codigo_marca']=this.getField('codigo_marca').getValue();
            this.datosAgregar.datos['partida_arancelaria']=this.getField('partida_arancelaria').getValue();
            this.datosAgregar.datos['codigo_clasificacion']=this.getField('codigo_clasificacion').getValue();
            this.datosAgregar.datos['marca_id']=this.getField('marca_id').getValue();
            this.datosAgregar.datos['gravado']=this.getField('gravado').getValue();
            this.datosAgregar.datos['alias']=this.getField('alias').getValue();
            this.datosAgregar.datos['excluido']=this.getField('excluido').getValue();
            this.datosAgregar.datos['contenido']=this.getField('contenido').getValue();
            this.datosAgregar.datos['codigo_tipo_producto']=this.getField('codigo_tipo_producto').getValue();
            this.datosAgregar.datos['tarifas_retencion']=this.getField('tarifas_retencion').getValue();
            this.datosAgregar.datos['vivac']=this.getField('vivac').getValue();
            this.datosAgregar.datos['producto_importado']=this.getField('producto_importado').getValue();
            this.datosAgregar.datos['viva']=this.getField('viva').getValue();
            this.datosAgregar.datos['codigo_iva']=this.getField('codigo_iva').getValue();
            this.datosAgregar.datos['empaque']=this.getField('empaque').getValue();
            this.datosAgregar.datos['iva']=this.getField('iva').getValue();
            this.datosAgregar.datos['presenta']=this.getField('presenta').getValue();
            this.datosAgregar.datos['codigo_cat1']=this.getField('codigo_cat1').getValue();
            this.datosAgregar.datos['codigo_cat2']=this.getField('codigo_cat2').getValue();
            this.datosAgregar.datos['codigo_cat3']=this.getField('codigo_cat3').getValue();
            this.datosAgregar.datos['codigo_cat4']=this.getField('codigo_cat4').getValue();
            this.datosAgregar.datos['categoria1_id']=this.getField('categoria1_id').getValue();
            this.datosAgregar.datos['categoria2_id']=this.getField('categoria2_id').getValue();
            this.datosAgregar.datos['categoria3_id']=this.getField('categoria3_id').getValue();
            this.datosAgregar.datos['categoria4_id']=this.getField('categoria4_id').getValue();
            this.datosAgregar.datos['tipo_producto_id']=this.getField('tipo_producto_id').getValue();
            this.datosAgregar.datos['codigo_alterno']=this.getField('codigo_alterno').getValue();
            let datos=this.datosAgregar;
                this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '5', operacion_tipo: 'crear' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successArticulo,
                        error: this.error_,
                        general: this.generalFormatPmv
                });
        }
    }

    successArticulo(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'El Articulo se creo correctamente', 'success');
            this.limpiar();
        }
        else 
        {
           if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    confirmarLimpiar(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
        this.getField('confirmModalCustom').setClickDialog(this.limpiar);
        this.getField('confirmModalCustom').toggle(true);

    }

    limpiar(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('TabJaivana_52').reInit();
        this.getField('nombre').setValue('');
        this.getField('alias').setValue('');
        this.getField('codigo_grupo').setValue('');
        this.getField('codigo_subgrupo').setValue('');
        this.getField('codigo_subgrupo_1').setValue('');
        this.getField('codigo_marca').setValue('');
        this.getField('marca_id').setValue('');
        this.getField('codigo_clasificacion').setValue('');
        this.getField('nombre_grupo').setValue('');
        this.getField('nombre_subgrupo').setValue('');
        this.getField('nombre_marca').setValue('');
        this.getField('marca_id').setValue('');
        this.getField('nombre_clasificacion').setValue('');
        this.getField('codigo_cat1').setValue('');
        this.getField('codigo_cat2').setValue('');
        this.getField('codigo_cat3').setValue('');
        this.getField('codigo_cat4').setValue('');
        this.getField('nombre_categoria1').setValue('');
        this.getField('categoria1_id').setValue('');
        this.getField('nombre_categoria2').setValue('');
        this.getField('categoria2_id').setValue('');
        this.getField('nombre_categoria3').setValue('');
        this.getField('categoria3_id').setValue('');
        this.getField('nombre_categoria4').setValue('');
        this.getField('categoria4_id').setValue('');
        this.getField('unidad_id').setValue('');
        this.getField('presenta').setValue('');
        this.getField('empaque').setValue('');
        this.getField('contenido').setValue('1');
        this.getField('peso').setValue('1');
        this.getField('nombre_tipo').setValue('');
        this.getField('gravado').setValue('');
        this.getField('exento').setValue('');
        this.getField('excluido').setValue('');
        this.getField('codigo_iva').setValue('');
        this.getField('codigo_tipo_producto').setValue('');
        this.getField('tarifas_retencion').setValue('');
        this.getField('tipo_producto_id').setValue('');
        this.getField('iva_ventas').setValue('');
        this.getField('iva_compras').setValue('');
        this.getField('iva').setValue('');
        this.getField('producto_importado').setValue('');
        this.getField('partida_arancelaria').setValue('');
        this.getField('barras').setValue('');   
        this.getField('codigo_interno').setValue(''); 
        this.getField('codigo_alterno').setValue('');      
        
        this.getField('nombre').valid(false);
        this.getField('alias').valid(false);
        this.getField('codigo_grupo').valid(false);
        this.getField('codigo_subgrupo').valid(false);
        this.getField('codigo_subgrupo_1').valid(false);
        this.getField('codigo_marca').valid(false);
        this.getField('marca_id').valid(false);
        this.getField('codigo_clasificacion').valid(false);
        this.getField('nombre_grupo').valid(false);
        this.getField('nombre_subgrupo').valid(false);
        this.getField('nombre_marca').valid(false);
        this.getField('marca_id').valid(false);
        this.getField('nombre_clasificacion').valid(false);
        this.getField('codigo_cat1').valid(false);
        this.getField('codigo_cat2').valid(false);
        this.getField('codigo_cat3').valid(false);
        this.getField('codigo_cat4').valid(false);
        this.getField('nombre_categoria1').valid(false);
        this.getField('categoria1_id').valid(false);
        this.getField('nombre_categoria2').valid(false);
        this.getField('categoria2_id').valid(false);
        this.getField('nombre_categoria3').valid(false);
        this.getField('categoria3_id').valid(false);
        this.getField('nombre_categoria4').valid(false);
        this.getField('categoria4_id').valid(false);
        this.getField('unidad_id').valid(false);
        this.getField('presenta').valid(false);
        this.getField('empaque').valid(false);
        this.getField('contenido').valid(false);
        this.getField('peso').valid(false);
        this.getField('nombre_tipo').valid(false);
        this.getField('gravado').valid(false);
        this.getField('exento').valid(false);
        this.getField('excluido').valid(false);
        this.getField('codigo_iva').valid(false);
        this.getField('codigo_tipo_producto').valid(false);
        this.getField('tarifas_retencion').valid(false);
        this.getField('tipo_producto_id').valid(false);
        this.getField('iva_ventas').valid(false);
        this.getField('iva_compras').valid(false);
        this.getField('iva').valid(false);
        this.getField('producto_importado').valid(false);
        this.getField('partida_arancelaria').valid(false);
        this.getField('barras').valid(false);
        this.getField('codigo_interno').valid(false);
        this.getField('codigo_alterno').valid(false);

    }
}
FormJaivana.addController("maes-articulo",CustomArticulos);
export default CustomArticulos