import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
 * @author Mario A. Sepúlveda R.
 * @version  jdesk_1.01.0001
 * @modified 25-07-2023
*/
/**
 * CustomProcesar
**/
class CustomProcesar extends FormJaivana.form {


   constructor(props) {

       super(props);
       this.initForm = this.initForm.bind(this);
       this.arrayObjetos=new Map();
       this.arrayCampos=new Map();
       this.bHayErrores=false;
       this.procesarPresupuesto = this.procesarPresupuesto.bind(this);
       this.successProcesarPresupuesto = this.successProcesarPresupuesto.bind(this);
       this.gridOptionsProcesar = Object.assign({},this.gridOptions);
       this.ocultarTabla = this.ocultarTabla.bind(this);
       this.traerAnios = this.traerAnios.bind(this);
       this.succeTraerAnios = this.succeTraerAnios.bind(this);
       this.traerMeses = this.traerMeses.bind(this);
       this.successTraerMeses = this.successTraerMeses.bind(this);
   }

   initForm(){
    console.log('Formulario CustomProcesar,  @version: jdesk_1.01.0001, @author:Mario A. Sepúlveda R.')

    this.arrayCampos.set(1,'id');
    this.arrayCampos.set(2,'anio_');
    this.arrayCampos.set(3,'mes');
    this.arrayCampos.set(4,'acumulado');
    this.arrayCampos.set(5,'procesar');
    this.arrayCampos.set(6,'tabla_procesar');


    this.arrayObjetos.set(1,'id');
    this.arrayObjetos.set(2,'anio_');
    this.arrayObjetos.set(3,'mes');
    this.arrayObjetos.set(4,'acumulado');
    this.arrayObjetos.set(5,'procesar');
    this.arrayObjetos.set(6,'tabla_procesar');

    this.traerAnios();
    this.traerMeses();
    this.getField(this.arrayCampos.get(2)).setOnChange(this.ocultarTabla);
    this.getField(this.arrayCampos.get(3)).setOnChange(this.ocultarTabla);
    this.getField(this.arrayCampos.get(4)).setOnChange(this.ocultarTabla);
    this.getField(this.arrayCampos.get(5)).setClick(this.procesarPresupuesto);

   }

   traerAnios(){
    let datos={ datos: {} };

    //console.log('datos del body:-->',datos);
    this.generalFormatPmv = { tipo_servicio: 'cont-procesar', operacion: '1_1', operacion_tipo: 'consulta' };
    this.service.send(
    {
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.succeTraerAnios,
        error: this.error_,
        general: this.generalFormatPmv
    });
   }

   succeTraerAnios(data){
    if(data.estado_p === 200){
        //console.log('data respuesta: ',data);
        let opciones = [{'value':' ','text':'Ninguno','campos_cambian':{}}]; 
        data.data.forEach(item => { 
            let dataOp = {}; 
            dataOp['value'] = parseInt(item.anio); 
            dataOp['text'] = item.anio; 
            dataOp['campos_cambian'] = {}; 
            opciones.push(dataOp); 

        }); 
        this.getField(this.arrayCampos.get(2)).setOptions(opciones);
    }
   }

   traerMeses(){
    let datos={ datos: {} };

    //console.log('datos del body:-->',datos);
    this.generalFormatPmv = { tipo_servicio: 'cont-procesar', operacion: '1_2', operacion_tipo: 'consulta' };
    this.service.send(
    {
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.successTraerMeses,
        error: this.error_,
        general: this.generalFormatPmv
    });
   }

   successTraerMeses(data){
    if(data.estado_p === 200){
        //console.log('data: -->',data);
        let opciones = [{'value':' ','text':'Ninguno','campos_cambian':{}}]; 
        //let contiene = false; 
        data.data.forEach(item => { 
            let dataOp = {}; 
            dataOp['value'] = parseInt(item.numeromes); 
            dataOp['text'] = item.mes; 
            dataOp['campos_cambian'] = {}; 
            opciones.push(dataOp); 
        }); 
        this.getField(this.arrayCampos.get(3)).setOptions(opciones);

    }
   }

   ocultarTabla(){
    this.getField(this.arrayCampos.get(6)).toggle(false);
    this.getField(this.arrayCampos.get(5)).setDisabled(false);
   }

   procesarPresupuesto(){
    let anio = this.getField(this.arrayCampos.get(2)).getValue();
    let mes = this.getField(this.arrayCampos.get(3)).getValue();
    //let acumulado = this.getField(this.arrayCampos.get(4)).getValue();

    if(anio !== '' && mes !== '' && anio !== ' ' && mes !== ' ' && anio !== undefined && mes !== undefined && anio !== null && mes !== null
        && this.getField(this.arrayCampos.get(2)).valid() && this.getField(this.arrayCampos.get(3)).valid()){
        
        this.establecerPropiedades('2,3,4,5','disable','true');

        let datos={ datos: {
            anio: this.getField(this.arrayCampos.get(2)).getValue(),
            mes:this.getField(this.arrayCampos.get(3)).getValue(),
            acumulado:this.getField(this.arrayCampos.get(4)).getValue()
        } };
    
        //console.log('datos del body:-->',datos);
        this.generalFormatPmv = { tipo_servicio: 'cont-procesar', operacion: 'procesar', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successProcesarPresupuesto,
                error: this.error_,
                general: this.generalFormatPmv
        });
    } else {
        //console.log("no son validos los campos");
    }

   }

   successProcesarPresupuesto(data){
    //this.getField(this.arrayCampos.get(5)).setDisabled(false);
    this.establecerPropiedades('2,3,4','disable','false');
    if(data.estado_p === 200){
        //console.log('RESPUESTA: -->',data);

        let configCell = new Map();

            configCell.set('codigo_cuenta', { headerName:'Código cuenta'});
            configCell.set('maneja_movimiento', { headerName:'Maneja movimiento'});
            configCell.set('presupuesto',{cellRenderer:function(props){return''.formatoPrecio(props.data.presupuesto)},field:'presupuesto'});
            configCell.set('ejecutado',{cellRenderer:function(props){return''.formatoPrecio(props.data.ejecutado)},field:'ejecutado'});
            configCell.set('variacion',{headerName:'Variación', cellRenderer:function(props){return''.formatoPrecio(props.data.variacion)},field:'variacion'});

            this.getField(this.arrayCampos.get(6)).toggle(true);      
            this.gridOptionsProcesar['rowData'] = data.data;
            this.getField(this.arrayCampos.get(6)).initData(this.gridOptionsProcesar,configCell);
    }
   }

   /** fin*/

   establecerPropiedades(sObjetos,sPropiedad,sValor) 
   {
      const array = sObjetos.split(',');
      array.forEach((element) => 
      {
               //console.log(element);
               //console.log(this.arrayObjetos.get(parseInt(element)));
               if(sPropiedad==='disable') 
               { 
                   if(sValor==='true') { 
                       this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                   }
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                   }   
               }
               else if(sPropiedad==='visible') 
               { 
                   if(sValor==='true') { 
                       this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                   }
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                   }   
               }
               else if(sPropiedad==='value')
               { 
                   this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
               }  
               else if(sPropiedad==='valid')
               { 
                   if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                      //ok
                   }
                   else {
                       this.bHayErrores=true;
                   }
               }
               else if(sPropiedad==='foco')
               { 
                   if(sValor==='rejilla') { } 
                   else {
                       this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                   }
               }
     });     
  }



   cargarCampos(data){
    this.getField('id').setvalue(data.data[0].id);
    this.getField('anio_').setvalue(data.data[0].anio_);
    this.getField('mes').setvalue(data.data[0].mes);
    this.getField('acumulado').setvalue(data.data[0].acumulado);
    this.getField('procesar').setvalue(data.data[0].procesar);
    this.getField('tabla_procesar').setvalue(data.data[0].tabla_procesar);
    
   }





}

FormJaivana.addController('cont-procesar',CustomProcesar);
export default CustomProcesar;