import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomActivosFijosResponsables
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 **/
class CustomActivosFijosResponsables extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.arrayObjetos = new Map();
        this.arrayCampos = new Map();
        this.crearRegistro = this.crearRegistro.bind(this);
        this.successCrear = this.successCrear.bind(this);
        this.actualiarRegistro = this.actualiarRegistro.bind(this);
        this.successActualizar = this.successActualizar.bind(this);

        this.traerPrimero = this.traerPrimero.bind(this);
        this.successtraerPrimero = this.successtraerPrimero.bind(this);
        this.traerUltimo = this.traerUltimo.bind(this);
        this.successtraerUltimo = this.successtraerUltimo.bind(this);
        this.traerPorId = this.traerPorId.bind(this);
        this.successtraerPorId = this.successtraerPorId.bind(this);
        this.validarRegistroEliminar = this.validarRegistroEliminar.bind(this);
        this.succesvalidarRegistroEliminar = this.succesvalidarRegistroEliminar.bind(this);
        this.eliminarPorId = this.eliminarPorId.bind(this);
        this.successeliminarPorId = this.successeliminarPorId.bind(this);
        this.btnModificar = false;
        this.onClickChange = this.handlerClickChange.bind(this);
        this.onClickShowTable = this.handerClickShowTable.bind(this);
        this.onClickNew = this.handlerClickNew.bind(this);
        this.onClickDelete = this.handlerClickDelete.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.registro_seleccionado = false;
        this.mostrarMensajeSeleccionModificar = this.mostrarMensajeSeleccionModificar.bind(this);
        this.mostrarMensajeSeleccionEliminar = this.mostrarMensajeSeleccionEliminar.bind(this);
        this.successValidarUniqueRegistro = this.successValidarUniqueRegistro.bind(this);
        this.traerTodos = this.traerTodos.bind(this);
        this.successtraerTodos = this.successtraerTodos.bind(this);
        this.confirmarEliminacionRegistro = this.confirmarEliminacionRegistro.bind(this);
        this.abrirImprimir = this.abrirImprimir.bind(this);


        //imprimir
        this.isJson = this.isJson.bind(this);
        this.generarExcelTabla3 = this.generarExcelTabla3.bind(this);
        this.generarPdfTabla3 = this.generarPdfTabla3.bind(this);
        this.mostrarMensajeNoHayDatos = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeEmailFallo = this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeEmailEnviado = this.mostrarMensajeEmailEnviado.bind(this);
        this.successEnviarCorreo = this.successEnviarCorreo.bind(this);
        this.enviarCorreoTerceros = this.enviarCorreoTerceros.bind(this);
        this.procesar = this.procesar.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);
        this.mostrarMensajeGenerando = this.mostrarMensajeGenerando.bind(this);

        this.gridOptionsTableDefault['onSelectionChanged'] = this.onSelectionChanged;

        this.currencyFormatterGeneral = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo = this.formatNumberSaldo.bind(this);
        this.llenar = this.llenar.bind(this);
        this.traer_por_id = true;
        this.operacion_actual = '';
        this.nombre_archivo = 'listado_activos_fijos_responsables';
        this.sucursal_ingreso = '';

    }

    initForm() {
        console.log('Formulario CustomActivosFijosResponsables,  @version: jdesk_1.01.0004, @author:Juan Camilo Villa Osorio')
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.arrayCampos.set(1, 'codigo_responsable');
        this.arrayCampos.set(2, 'nombre_responsable');
        this.arrayCampos.set(3, 'estadoactivo_id');
        this.arrayCampos.set(4, 'estado');
        this.arrayCampos.set(7, 'label_form');

        this.arrayCampos.set(5, 'btn_imprimir');
        this.arrayCampos.set(6, 'activos_fijos_responsables_id');

        this.arrayCampos.set(30, 'btn_agregar');
        this.arrayCampos.set(31, 'btn_cancelar');
        this.arrayCampos.set(32, 'btn_modificar');
        this.arrayCampos.set(33, 'btn_cancelar_modificar');
        //this.arrayCampos.set(34,'operacion');

        this.arrayObjetos.set(1, 'codigo_responsable');
        this.arrayObjetos.set(2, 'nombre_responsable');
        this.arrayObjetos.set(3, 'estadoactivo_id');
        this.arrayObjetos.set(4, 'estado');
        this.arrayObjetos.set(5, 'btn_imprimir');
        this.arrayObjetos.set(6, 'activos_fijos_responsables_id');
        this.arrayObjetos.set(7, 'label_form');


        this.arrayObjetos.set(30, 'btn_agregar');
        this.arrayObjetos.set(31, 'btn_cancelar');
        this.arrayObjetos.set(32, 'btn_modificar');
        this.arrayObjetos.set(33, 'btn_cancelar_modificar');
        //this.arrayObjetos.set(34,'operacion');
        this.establecerPropiedades('1,2,3,4,5,7', 'visible', 'false')
        this.establecerPropiedades('1,2,3,4,5,7', 'disable', 'true')

        //inicio form
        this.establecerPropiedades('1,2,3,7', 'visible', 'true');



        this.getField('btn_cancelar').setClick(() => {
            this.traer_por_id = false;
            this.btnCancelarRegistro();
        });
        this.getField('btn_agregar').setClick(() => {
            if (this.getField('codigo_responsable').valid() && this.getField('nombre_responsable').valid() && this.getField('estadoactivo_id').valid())
                this.validacionUnique();
        });

        this.getField('btn_modificar').setClick(() => {
            if (this.getField('codigo_responsable').valid() && this.getField('nombre_responsable').valid() && this.getField('estadoactivo_id').valid())
                this.validacionUnique();
        });

        this.getField('btn_cancelar_modificar').setClick(() => {
            this.traer_por_id = false;
            this.btnModificar = false
            this.btnCancelarRegistro();
        });

        this.getField('btn_imprimir').setClick(this.abrirImprimir);
        //imprimir
        this.getField('radio_correo').setValue("N");
        this.getField('generar_archivo').setDisabled(false);
        this.getField('ch_excel').setDisabled(false);
        this.getField('ch_pdf').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);

        this.getField('generar_archivo').setClick(() => this.procesar());

        this.getField('radio_correo').setOnChange((props) => {
            if (this.getField('radio_correo').getValue() === 'N') {
                this.getField('email').setDisabled(true);
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
            } else {
                this.getField('email').setDisabled(false);
                this.getField('email').setValue('');
                this.getField('email').setError(false, '');
            }
        });
        //para balances globales
        this.getField('ch_excel').setOnChange((props) => {
            if (this.getField('ch_excel').getValue() === 'false') {
                this.getField('ch_pdf').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.operacion_actual = 'excel';
                this.getField('radio_correo').setValue("N");
            }
        });
        this.getField('ch_pdf').setOnChange((props) => {
            if (this.getField('ch_pdf').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.operacion_actual = 'pdf';
                this.getField('radio_correo').setValue("N");
            }
        });
        this.getField('ch_pantalla').setOnChange((props) => {
            if (this.getField('ch_pantalla').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pdf').setValueSwitch(false);
                this.operacion_actual = 'pantalla';
                this.getField('radio_correo').setValue("N");
            } else {
                this.getField('radio_correo').setValue("N");
            }
        });
        this.getField('modal_imprimir').setCloseButton(() => {
            //nada
        });

        this.traerUltimo();

    }

    abrirImprimir() {
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false, '');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
    }

    onSelectionChanged() {
        let filaSeleccionada = this.gridOptionsTableDefault.api.getSelectedRows();
        if (filaSeleccionada.length > 0) {
            this.llenar(filaSeleccionada);
            this.registro_seleccionado = true;
        } else {
            this.registro_seleccionado = false;
        }
    }

    handerClickShowTable() {
        this.registro_seleccionado = false;
        this.traer_por_id = false;
        this.traerTodos();
    }

    handlerClickChange() {
        this.btnModificar = true;
        if (this.registro_seleccionado) {
            this.registro_seleccionado = false;
            this.establecerPropiedades('2,3,32,33', 'disable', 'false');
            this.establecerPropiedades('32,33', 'visible', 'true');
            this.getField('btn_new').setVisible(false);
            this.getField('btn_show').setVisible(false);
            this.getField('btn_change').setVisible(false);
            this.getField('btn_delete').setVisible(false);
            this.getField('rejilla').toggle(false);
            this.getField('btn_imprimir').setVisible(false);
        } else {
            this.mostrarMensajeSeleccionModificar();
        }
    }

    handlerClickNew() {
        this.btnModificar = false;
        this.registro_seleccionado = false;
        this.establecerPropiedades('1,2,3,30,31', 'disable', 'false');
        this.establecerPropiedades('30,31', 'visible', 'true');
        this.establecerPropiedades('1,2,3,4', 'value', '');

        this.getField('btn_new').setVisible(false);
        this.getField('btn_show').setVisible(false);
        this.getField('btn_change').setVisible(false);
        this.getField('btn_delete').setVisible(false);
        this.getField('rejilla').toggle(false);
        this.getField('btn_imprimir').setVisible(false);

    }

    handlerClickDelete() {
        this.traer_por_id = false;
        if (this.registro_seleccionado) {
            this.confirmarEliminacionRegistro();
        } else {
            this.mostrarMensajeSeleccionEliminar();
        }
    }

    mostrarMensajeSeleccionModificar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para modificar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeSeleccionEliminar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para eliminar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    btnCancelarRegistro() {
        this.getField('confirmModalCustom').toggle(false);
        this.registro_seleccionado = false;
        this.getField('btn_agregar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.getField('btn_new').setDisabled(false);
        this.getField('btn_show').setDisabled(false);
        this.getField('btn_delete').setDisabled(false);

        this.establecerPropiedades('1,2,3', 'disable', 'true');
        this.establecerPropiedades('1,2,3,4', 'value', '');
        this.establecerPropiedades('1,2,3,4', 'error', 'false');
        if (this.traer_por_id)
            this.traerPorId();
        else
            this.traerUltimo();
        this.traerTodos();


        this.getField('btn_new').setVisible(true);
        this.getField('btn_show').setVisible(true);
        this.getField('btn_change').setVisible(true);
        this.getField('btn_delete').setVisible(true);

        this.gridOptionsTableDefault["rowData"] = [];
        this.getField('rejilla').initData(this.gridOptionsTableDefault);
        this.getField('rejilla').toggle(false);
        this.btnModificar = false;
    }

    validacionUnique() {
        let datos = {
            datos: {
                codigo_responsable: this.getField('codigo_responsable').getValue()
            }
        };
        let operacion = '20';
        if (this.btnModificar) {
            datos.datos['activos_fijos_responsables_id'] = this.getField('activos_fijos_responsables_id').getValue();
            operacion = '20_modificar';
        }
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: operacion, operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successValidarUniqueRegistro,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });

    }

    successValidarUniqueRegistro(data) {
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'Este código ya se encuentra asignado a un registro.', 'error');
        }
        else {
            if (data.estado_p === 404) {
                if (this.btnModificar) {
                    this.actualiarRegistro();
                } else {
                    this.crearRegistro();
                }
            }
            else {
                this.alertGeneral.toggle(true, 'Error validando el código', 'error');
            }
        }
    }

    traerPorId() {
        let datos = {
            datos: {
                activos_fijos_responsables_id: this.getField('activos_fijos_responsables_id').getValue()
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: '2', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerPorId,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successtraerPorId(data) {
        if (data.estado_p === 200) {
            this.llenar(data.data)
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error obteniendo el registro', 'error');
            }
        }
    }

    confirmarEliminacionRegistro() {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(this.validarRegistroEliminar);
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
        this.getField('confirmModalCustom').toggle(true);
    }

    validarRegistroEliminar() {
        this.getField('confirmModalCustom').toggle(false);
        let datos={ datos: {
            activos_fijos_responsables_id:this.getField('activos_fijos_responsables_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: '7_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesvalidarRegistroEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    succesvalidarRegistroEliminar(data) {
        if (data.data[0].cuantos === 0) {
            this.eliminarPorId();

        } else {
            this.alertGeneral.toggle(true, '* Este registro ya está siendo utilizado en otro formulario, no se puede eliminar', 'error')
            this.getField('confirmModalCustom').toggle(false);

        }
    }

    eliminarPorId() {
        let datos = {
            datos: {
                activos_fijos_responsables_id: this.getField('activos_fijos_responsables_id').getValue()
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'DELETE',
                body: datos,
                success: this.successeliminarPorId,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successeliminarPorId(data) {
        if (data.estado_p === 200) {
            this.getField('confirmModalCustom').toggle(false);
            this.traerPrimero();
            this.traerTodos();
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'Error eliminando el registro', 'error');
                this.getField('confirmModalCustom').toggle(false);
            }
            else {
                this.alertGeneral.toggle(true, 'Error eliminando el registro', 'error');
            }
        }
    }

    crearRegistro() {
        let datos = {
            datos: {
                codigo_responsable: this.getField('codigo_responsable').getValue(),
                nombre_responsable: this.getField('nombre_responsable').getValue(),
                estadoactivo_id: this.getField('estadoactivo_id').getValue(),
                estado: this.getField('estado').getValue(),
            }
        }
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'POST',
                body: datos,
                success: this.successCrear,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successCrear(data) {
        if (data.estado_p === 200) {
            this.getField('activos_fijos_responsables_id').setValue(data.data[0].activos_fijos_responsables_id);
            this.traer_por_id = true;
            this.btnCancelarRegistro();
            this.traerUltimo();
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se pudo crear el registro', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error creando el registro', 'error');
            }
        };
    }

    actualiarRegistro() {
        if (this.getField('activos_fijos_responsables_id').getValue() !== '' && this.getField('activos_fijos_responsables_id').getValue() !== ' ') {
            let datos = {
                datos: {
                    codigo_responsable: this.getField('codigo_responsable').getValue(),
                    nombre_responsable: this.getField('nombre_responsable').getValue(),
                    estadoactivo_id: this.getField('estadoactivo_id').getValue(),
                    estado: (this.getField('estado').getValue() === 'Inactivo')? 'I': 'A',
                    /* estado: this.getField('estado').getValue(), */
                    activos_fijos_responsables_id: this.getField('activos_fijos_responsables_id').getValue()
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successActualizar,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successActualizar(data) {
        if (data.estado_p === 200) {
            this.getField('activos_fijos_responsables_id').setValue(data.data[0].activos_fijos_responsables_id);
            this.traer_por_id = true;
            this.btnCancelarRegistro();
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se pudo actualizar el registro', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error actualizando el registro', 'error');
            }
        };
    }

    //metodos conceptos

    traerTodos() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerTodos,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successtraerTodos(data) {
        this.registro_seleccionado = false;
        if (data.estado_p === 200) {
            //armar tabla
            let configCell = new Map();
            configCell.set('estadoactivo_id', { cellRenderer: (props) => { return "" + props.data.estadoactivo_id }, hide: true });
            configCell.set('activos_fijos_responsables_id', { cellRenderer: (props) => { return "" + props.data.activos_fijos_responsables_id }, hide: true });
            configCell.set('codigo_responsable', { cellRenderer: (props) => { return "" + props.data.codigo_responsable }, headerName: 'Código'});
            configCell.set('nombre_responsable', { cellRenderer: (props) => { return "" + props.data.nombre_responsable }, headerName: 'Nombre'});



            this.gridOptionsTableDefault['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            this.getField('rejilla').initData(this.gridOptionsTableDefault, configCell);
            this.getField('btn_imprimir').setVisible(true);
            this.getField('btn_imprimir').setDisabled(false);
            this.registro_seleccionado = false;
        }
        else {
            this.getField('btn_imprimir').setVisible(false);
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontraron los registros.', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error obteniendo los registros', 'error');
            }
        }
    }

    traerPrimero() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: '3', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerPrimero,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    
    successtraerPrimero(data) {
        if (data.estado_p === 200) {
            this.llenar(data.data);
        } else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro el registro.', 'error');
            }
            else {
                this.alertGeneral.toggle(true, 'Error Obteniendo el registro', 'error');
            }
        }
    }
    
    llenar(data) {
        this.getField('codigo_responsable').setValue(data[0].codigo_responsable);
        this.getField('nombre_responsable').setValue(data[0].nombre_responsable);
        this.getField('estado').setValue(data[0].estado);
        this.getField('estadoactivo_id').setValue(data[0].estadoactivo_id);
        this.getField('activos_fijos_responsables_id').setValue(data[0].activos_fijos_responsables_id);
    }

    traerUltimo() {
        let datos = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: '4', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successtraerUltimo,
                error: this.error_,
                general: this.generalFormatPmv
            });
    }

    successtraerUltimo(data) {
        if (data.estado_p === 200) {

            this.llenar(data.data);
        }
        else {
            if (data.estado_p === 404) {
                this.alertGeneral.toggle(true, 'No se encontro el registro.', 'error');
            }

        }
    }

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
        });
    }

    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > 2) {
                decimal = (Number(number).toFixed(2) + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


    //imprimir------->

    procesar() {
        let errores = 0;
        if (this.getField('radio_correo').getValue() === 'S') {
            if (this.getField('email').valid() === false) {
                errores++;
            }
        }
        if (this.getField('ch_excel').getValue() === 'false' && this.getField('ch_pdf').getValue() === 'false' && this.getField('ch_pantalla').getValue() === 'false') {
            errores++;
            this.getField('ch_excel').setError(true, '* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true, '* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true, '* Es necesario seleccionar una opción.');
            this.operacion_actual = '';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf o Excel.', 'error');
        }

        if (errores === 0) {
            if (this.operacion_actual !== '') {
                if (this.getField('radio_correo').getValue() === 'S') {
                    this.enviarCorreoTerceros();
                } else {
                    this.nombre_pdf = 'compra';
                    this.nombre_excel = 'compra';
                    if (this.operacion_actual === 'pdf') {
                        this.generarPdfTabla3();
                    } else if (this.operacion_actual === 'pantalla') {
                        //nothing
                    } else if (this.operacion_actual === 'excel') {
                        this.generarExcelTabla3();
                    }
                }
            } else {
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            }
        }
    }


    enviarCorreoTerceros() {
        this.mostrarMensajeGenerando();
        let datos = {
            datos: {
                sucursal_ingreso: this.sucursal_ingreso,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual,
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'serlog-activosfijosresponsable', operacion: 'archivoitems', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
    }

    successEnviarCorreo(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.mostrarMensajeEmailEnviado();
        } else {
            this.mostrarMensajeEmailFallo();
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    mostrarMensajeEmailEnviado() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeNoHayDatos() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    generarPdfTabla3() {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 = {
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual,
        };

        let op = 'archivoitems';
        const myJSON = JSON.stringify(datos2);
        let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"serlog-activosfijosresponsable","operacion":"' + op + '","operacion_tipo":"consulta"}}';
        fetch(url + datos, {
            method: 'GET',
            headers: new Headers({
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": 'application/json'
            })
        })
            .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if (blob.size > 0) {
                    blob.text().then(res => {
                        if (this.isJson(res)) {
                            this.successEnviarCorreo(JSON.parse(res));
                        } else {
                            const pdf = new File([blob], this.nombre_archivo + '.pdf', {
                                type: 'application/pdf'
                            });
                            window.open(URL.createObjectURL(pdf));
                        }
                    });
                } else {
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true, err, 'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3() {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2 = {
            sucursal_ingreso: this.sucursal_ingreso,
            operacion: this.operacion_actual,
        };
        let op = 'archivoitems';

        const myJSON = JSON.stringify(datos2);
        let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"serlog-activosfijosresponsable","operacion":"' + op + '","operacion_tipo":"consulta"}}';
        fetch(url + datos, {
            method: 'GET',
            headers: new Headers({
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": 'application/json'
            })
        })
            .then(response => {
                this.getField('confirmModalCustom').toggle(false);
                if (response.status !== 200) {
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                        return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                                if (done) {
                                    controller.close();
                                    return;
                                }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if (blob.size > 0) {
                    blob.text().then(res => {
                        if (this.isJson(res)) {
                            this.successEnviarCorreo(JSON.parse(res));
                        } else {
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo + '.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        }
                    });
                } else {
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true, err, 'error');
            });
    }


    mostrarMensajeAdicional(mensaje) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Compras', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

}
FormJaivana.addController("serlog-activosfijosresponsable", CustomActivosFijosResponsables);
export default CustomActivosFijosResponsables