import React from 'react'
import ReactDOM from 'react-dom/client'
import JaivanaDashboard from 'dashboard_jaivana_v1'
import register from './registerServiceWorker';
import Constant from './constant/Constant';
//import Txt from './constant/archivo.txt';
import Properties from './constant/config.properties';

function readTextFile(file,name) {
    const readerFile = new XMLHttpRequest();
    readerFile.open("GET", file, false);
    readerFile.onreadystatechange = function ()
    {
      const allText = readerFile.responseText;
      let paramsProperties = [];
      if(allText){//si se lee un archivo txt o .properties use un separados especial para leer con split() --> retorna array
        const configProperties = allText.split('\n');
        configProperties.map(item =>{
          let arrayProperties = item.split('=');
          let param = arrayProperties[0];
          let value = arrayProperties[1];
          paramsProperties[param] = value;
          return null
        });
      }
      Constant.setParams(name,paramsProperties);
    }
    readerFile.send(null);
}
readTextFile(Properties,'properties');//Para leer un archivo .txt o .properties descomente esta linea e importe el archivo como se ve en el ejemplo con  Txt

function loadFile() {
  /**Funcion para leer un Json si se requiere, descomentar la linea 39 --> loadFile() para usar */
  const text =  require('../src/constant/configuracion.json');
  Constant.setParams('json',text);
}
loadFile();


function importAll(r) {
  return r.keys().map(r);
}

const custom = importAll(require.context('./controllersForms/', false, /\.(js)$/));
if(!custom)console.log('No customs signed');

Constant.setParams('modulos','todos');
Constant.setParams('prefijo','jdesk');
Constant.setParams('prefijologin','jdesklogin');
const params = Constant.getParams();

if(params.properties) //Si se lee un archivo .properties se muestra como array de propiedades
  console.log(params);

const root = ReactDOM.createRoot(document.getElementById('rootprincipal'));
root.render(
  <JaivanaDashboard 
    loginBase={Constant.getParams().loginBase}
    keyEmpresa={Constant.getParams().keyEmpresa}
    baseUrl={Constant.getParams().baseUrl}
    modulos={Constant.getParams().modulos}
    prefijo={Constant.getParams().prefijo}
    prefijologin={Constant.getParams().prefijologin}
  />
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

register();
