import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomGrabarOrdenProduccion
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 **/
class CustomGrabarOrdenProduccion extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.gridOptionsComponentes         = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1        = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes2        = Object.assign({}, this.gridOptions);
        this.gridOptionsConsultarModal      = Object.assign({}, this.gridOptions);  
        this.consecutivo                    = this.consecutivo.bind(this);
        this.sucessConsecutivo              = this.sucessConsecutivo.bind(this);
        this.procesar                       = this.procesar.bind(this);                          
        this.successdespiece                = this.successdespiece.bind(this);     
        this.creartemporal                  = this.creartemporal.bind(this);                     
        this.successcreartemporal           = this.successcreartemporal.bind(this);                     
        this.mostrartabla                   = this.mostrartabla.bind(this);                     
        this.successmostrartabla            = this.successmostrartabla.bind(this);                     
        this.realizarorden                  = this.realizarorden.bind(this);                     
        this.successrealizarorden           = this.successrealizarorden.bind(this);          
        this.currencyFormatterGeneral       = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo              = this.formatNumberSaldo.bind(this);    
        this.setButtonDelete                = this.setButtonDelete.bind(this);
        this.eliminar                       = this.eliminar.bind(this);
        this.confirmDelete                  = this.confirmDelete.bind(this);
        this.successEliminar                = this.successEliminar.bind(this);       
        this.setButtonModificar             = this.setButtonModificar.bind(this);
        this.Modificar                      = this.Modificar.bind(this);   
        this.saldototal                     = this.saldototal.bind(this);
        this.successSaldoTotal              = this.successSaldoTotal.bind(this);
        this.traerSaldos                    = this.traerSaldos.bind(this);
        this.successtraerSaldos             = this.successtraerSaldos.bind(this);
        this.traerUbicacion                 = this.traerUbicacion.bind(this);
        this.successtraerUbicacion          = this.successtraerUbicacion.bind(this);
        this.calcular_item                  = this.calcular_item.bind(this);
        this.calcular_total                 = this.calcular_total.bind(this);
        this.cargar_factor                  = this.cargar_factor.bind(this);
        this.successcargarFactor            = this.successcargarFactor.bind(this);
        this.traercosto                     = this.traercosto.bind(this);
        this.successtraercosto              = this.successtraercosto.bind(this);
        this.abrir_modal                    = this.abrir_modal.bind(this);
        this.abrir_modal                    = this.abrir_modal.bind(this);
        this.validacionesAgregar            = this.validacionesAgregar.bind(this);
        this.validacionesAgregar2           = this.validacionesAgregar2.bind(this);
        this.sucessValidaciones             = this.sucessValidaciones.bind(this);
        this.successGrabarItem              = this.successGrabarItem.bind(this);
        this.cargarUbicaciones              = this.cargarUbicaciones.bind(this);
        this.successcargarUbicaciones       = this.successcargarUbicaciones.bind(this);
        this.grabarorden                    = this.grabarorden.bind(this);
        this.successGrabarOrden             = this.successGrabarOrden.bind(this);
        this.mostrarMensajeEmailEnviado     = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo       = this.mostrarMensajeEmailFallo.bind(this);
        this.mostrarMensajeNoHayDatos       = this.mostrarMensajeNoHayDatos.bind(this);
        this.generarPdfTabla3               = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3             = this.generarExcelTabla3.bind(this);
        this.enviarCorreoTerceros           = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo            = this.successEnviarCorreo.bind(this);
        this.isJson                         = this.isJson.bind(this);
        this.imprimir                       = this.imprimir.bind(this);
        this.generarModalLista              = this.generarModalLista.bind(this);
        this.sucessTraerDatos               = this.sucessTraerDatos.bind(this);
        this.abrirImprimir                  = this.abrirImprimir.bind(this);
        this.habilitarCorreo                = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo             = this.deshabilitarCorreo.bind(this);
        this.limpiar                        = this.limpiar.bind(this);
        this.traercodigo                    = this.traercodigo.bind(this);
        this.successTraerDatos              = this.successTraerDatos.bind(this);
        this.entro                          = false;
    }

    initForm() {
        console.log("Formulario CustomGrabarOrdenProduccion,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        this.getField("fecha").setDisabled(true);
        this.getField("hora").setDisabled(true);
        this.getField("agregar").setDisabled(true);
        this.getField("agregar").setClick(()=>{this.getField("modal_articulos").handleClickOpen(); this.accion="agregar";this.abrir_modal()});
        this.getField("grabar_agregar").setClick(this.validacionesAgregar);
        this.getField("nombre_sucursal").setOnChange(()=>{if(this.getField("nombre_sucursal").getValue() !== ""){
                this.consecutivo();
                this.getField("nombre_sucursal").setError(false,"")
            }else if(this.comenzo === true){
                this.getField("procesar").setDisabled(false);
                this.getField("agregar").setDisabled(true);
                this.getField("grabar_orden").setDisabled(true);
                this.getField("numero").setValue("");
                this.limpiar();
            }else if(this.getField("nombre_sucursal").getValue() === ""){
                this.getField("procesar").setDisabled(false);
                this.getField("agregar").setDisabled(true);
                this.getField("grabar_orden").setDisabled(true);
                this.getField("numero").setValue("");
                this.limpiar();
            }
        });
        this.getField("nombre_bodega").setOnChange(()=>{if(this.getField("nombre_bodega").getValue() !== ""){
                this.getField("nombre_bodega").setError(false,"")
            }else if(this.comenzo === true){
                this.getField("procesar").setDisabled(false);
                this.getField("agregar").setDisabled(true);
                this.getField("grabar_orden").setDisabled(true);
                this.limpiar();
            }else if(this.getField("nombre_bodega").getValue() === ""){
                this.getField("procesar").setDisabled(false);
                this.getField("agregar").setDisabled(true);
                this.getField("grabar_orden").setDisabled(true);
                this.limpiar();
            }
        });
        this.getField("nombre_articulo").setOnChange(()=>{if(this.getField("nombre_articulo").getValue() !== ""){
                this.getField("nombre_articulo").setError(false,"")
            }else if(this.comenzo === true){
                this.getField("nombre_articulo").setError(false,"")
                this.getField("agregar").setDisabled(true);
                this.getField("procesar").setDisabled(false);
                this.getField("grabar_orden").setDisabled(true);
                this.limpiar();
            }else if(this.getField("nombre_articulo").getValue() === ""){
                this.getField("procesar").setDisabled(false);
                this.getField("agregar").setDisabled(true);
                this.getField("grabar_orden").setDisabled(true);
                this.limpiar();
            }
        });
        this.getField("factor").setOnBlur(()=>{
            if(this.getField("nombre_articulo1").getValue() !== ""){
                this.getField("cantidad_unidad1").setValue(this.getField("cantidad_unidad").getValue());
                this.calcular_item();
            }
        });
        this.getField("cantidad_total").setOnChange(()=>{
            if(this.getField("nombre_articulo1").getValue() !== ""){
                this.getField("cantidad_tota1").setValue(this.getField("cantidad_total").getValue());
                this.calcular_total();
            }
        });
        this.getField("nombre_articulo1").setOnChange(()=>{
            if(this.getField("nombre_articulo1").getValue() !== "" ){
                this.getField("nombre_articulo1").setError(false,"")
            }
            if(this.getField("nombre_articulo1").getValue() !== "" && this.entro === false){
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                this.abrir_modal();
            }else{
                this.limpiarcampo();
            }
        });
        this.getField("grabar_orden").setDisabled(true);
        this.getField("grabar_orden").setClick(()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje', '¿Confirma que desea grabar esta orden de producción?');
            this.getField('confirmModalCustom').setClickDialog(() => {this.grabarorden()});
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        });
        this.getField("codigo_sucursal").setValue(this.codigo_sucursal);
        this.consecutivo();
        this.creartemporal();
        this.getField('procesar').setClick(this.procesar);
        this.getField('modal_articulos').setCloseButton(()=>{
            this.limpiarcampos();
            this.mostrartabla();
        });
        this.getField('cancelar_agregar').setClick(()=>{
            this.getField('modal_articulos').handleClose()
            this.limpiarcampos();
            this.mostrartabla();
        });
        this.getField('generar_archivo').setClick(()=>this.imprimir());
        this.getField('modal_imprimir').setCloseButton(()=>{
            this.limpiarTodo();
        });

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pantalla').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='pantalla';
                this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });

        this.comenzo=false;

        this.getField("cantidad").setOnChange(()=>{if(this.comenzo === true){this.limpiar();
                this.getField("procesar").setDisabled(false);
                this.getField("agregar").setDisabled(true);
                this.getField("grabar_orden").setDisabled(true);
                this.limpiar();
            }else{
                this.getField("procesar").setDisabled(false);
                this.getField("agregar").setDisabled(true);
                this.getField("grabar_orden").setDisabled(true);
                this.limpiar();
            }
        });

        this.getField('select_ubicacion').setOnChange(()=>{
            if(this.getField('select_ubicacion').getValue() === ''){
                this.getField('ubicacion').setValue("");
            }
        });
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    consecutivo(){
        let datos={ datos: {
            codigo_sucursal:this.getField("codigo_sucursal").getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'consecutivo', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessConsecutivo,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    sucessConsecutivo(data){
        if(data.estado_p === 200){
            this.getField("numero").setValue(data.data[0].xnumero);
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No se encontro consecutivo asociado a la sucursal '+this.codigo_sucursal, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    creartemporal(){
        let datos={ datos: {
            codigo_usuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'creartemp', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successcreartemporal,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successcreartemporal(data){
        if(data.estado_p === 502){
            this.alertGeneral.toggle(true, 'Error al crearla tabla.', 'error');
        }else{
            this.mostrartabla();
        }
    }

    mostrartabla(){
        let datos={ datos: {
            codigo_usuario:this.codigo_usuario
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'mostrartabla', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successmostrartabla,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successmostrartabla(data){
        if(data.estado_p === 200){
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            configCell.set('factor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.factor.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('cantxund', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantxund.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('cantidad_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_total.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('costo_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_item.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});            
            configCell.set('eliminar', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
            configCell.set('modificar', { cellRenderer: this.setButtonModificar, width: 110, sortable: false, filter: false, field: 'Modificar' });

            this.gridOptionsComponentes['rowData'] = data.data;
            this.items = data.data;
            this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);

        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }


        
    setButtonDelete(props) {
        if(props.data.codigo !== "."){
            let codigo_articulo = props.data.codigo;
            let button = document.createElement("input");
            button.onclick = () => this.eliminar(codigo_articulo);
            button.setAttribute("codigo_articulo", 'button_delete_' + codigo_articulo);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("type", "button");
            button.setAttribute("value", "eliminar");
            return this.createElementJaivana(button);
        }
    }

    eliminar(codigo_articulo) {
        this.codigo_articulo = codigo_articulo;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDelete);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDelete() {
        this.getField('confirmModalCustom').toggle(false);
        let datos = {
            datos: {
                codigo_producto: this.codigo_articulo,
                codigo_usuario:this.codigo_usuario
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'eliminar_articulo', operacion_tipo: "eliminar" };// parametros del servicio
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv //se envia el generales de arriba
        });
    }

    successEliminar(data) {
        if(data.estado_p === 200){
            this.gridOptionsComponentes['rowData'] = [];
            this.getField('rejilla').initData(this.gridOptionsComponentes);
            this.mostrartabla();
            this.saldototal();
        }else{
          let keys=Object.keys(data.data.mensaje);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
        }
    }

    setButtonModificar(props) {
        if(props.data.codigo !== "."){
            let codigo = props.data.codigo;
            let nombre = props.data.nombre;
            let factor = props.data.factor;
            let cantxund = props.data.cantxund;
            let cantidad_total = props.data.cantidad_total;
            let costo = props.data.costo;
            let costo_item = props.data.costo_item;
            let ubicacion = props.data.ubicacion;
            let button = document.createElement("input");
            button.onclick = () => this.Modificar(codigo,nombre,factor,cantxund,cantidad_total,costo,costo_item,ubicacion);
            button.setAttribute("codigo", 'button_delete_' + codigo);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("type", "button");
            button.setAttribute("value", "Modificar");
            return this.createElementJaivana(button);
        }
    }

    Modificar(codigo,nombre,factor,cantxund,cantidad_total,costo,costo_item,ubicacion) {
        this.getField('confirmModalCustom').toggle(false);
        this.accion="modificar";
        this.getField("modal_articulos").handleClickOpen();
        this.getField("codigo_articulo").setValue(codigo);
        this.getField("nombre_articulo1").setValue(nombre);
        this.getField("codigo_articulo").setDisabled(true);
        this.entro=false;
        this.getField("costo").setValue(costo);
        this.getField("cantidad_unidad").setValue(cantxund);
        this.getField("factor").setValue(factor);
        this.getField("cantidad_total").setValue(cantidad_total);
        this.getField("valor_item").setValue(costo_item);
        this.getField("ubicacion").setValue(ubicacion);
        this.traerSaldos();
        this.cargarUbicaciones();
    }

    procesar(){
        if(this.getField("codigo_sucursal").valid() && this.getField("nombre_sucursal").valid() &&
        this.getField("bodega").valid() && this.getField("nombre_bodega").valid() && this.getField("numero").valid() && 
        this.getField("codigo_producto").valid() && this.getField("nombre_articulo").valid() && this.getField("cantidad").valid()){
            let datos = { datos: {
                codigo_producto:this.getField("codigo_producto").getValue()
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'esdespiece', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successdespiece,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }
    }

    successdespiece(data){
        if(data.estado_p === 200){
            if(data.data[0].esdespiece === "S"){
                this.alertGeneral.toggle(true, 'El producto a producir '+this.getField("nombre_articulo").getValue()+' no es compuesto. ', 'error');
            }else{
                this.comenzo=true;
                this.realizarorden();
            }
        }else if(data.estado_p ===404){
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    realizarorden(){
        let datos = { datos: {
            codigo_producto:this.getField("codigo_producto").getValue(),
            cantidad:this.getField("cantidad").getValue(),
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.codigo_sucursal,
            codigo_usuario:this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'realizarorden', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successrealizarorden,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successrealizarorden(data){
        if(data.estado_p === 200){
            this.wms_bodega= data.data.wms_bodega;
            this.wms_nuevo= data.data.wms_nuevo;
            this.lasucursal= data.data.lasucursal;
            this.mostrartabla();
            this.saldototal();
            this.getField("agregar").setDisabled(false);
            this.getField("procesar").setDisabled(true);
            this.getField("grabar_orden").setDisabled(false);
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    saldototal(){
        let datos = { datos: {
            codigo_usuario:this.codigo_usuario
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'saldo_total', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successSaldoTotal,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successSaldoTotal(data){
        if(data.estado_p === 200){
            this.total = data.data[0].valor;
            this.getField("total").setValue(this.currencyFormatterGeneral(data.data[0].valor));
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    traerSaldos(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.lasucursal,
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'traersaldos', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successtraerSaldos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successtraerSaldos(data){
        if(data.estado_p === 200){
            this.gridOptionsComponentes1['rowData'] = [];
            this.getField('rejilla_bodega').toggle(true);
            let configCell = new Map();
            configCell.set('saldo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.saldo.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});
            this.gridOptionsComponentes1['rowData'] = data.data.saldo_bodegas;
            this.getField('rejilla_bodega').initData(this.gridOptionsComponentes1, configCell);
            this.traerUbicacion();
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    traerUbicacion(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.lasucursal,
            codigo_articulo:this.getField("codigo_articulo").getValue(),
            bodega_wms:this.wms_bodega,
            wms_nuevo:this.wms_nuevo
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'traerubicacion', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successtraerUbicacion,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successtraerUbicacion(data){
        if(data.estado_p === 200){
            this.getField('rejilla_ubicacion').toggle(true);
            this.gridOptionsComponentes2['rowData'] = [];
            let configCell = new Map();
            configCell.set('saldo', {cellRenderer:(props) => { return ""+this.currencyFormatterGeneral(props.data.saldo)}, cellStyle: {textAlign:"right"},width: 110});
            this.gridOptionsComponentes2['rowData'] = data.data.saldos_ubicacion;
            this.getField('rejilla_ubicacion').initData(this.gridOptionsComponentes2, configCell);
        }else if(data.estado_p ===404){
            let respuesta=data.data.errores.mensaje;
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    calcular_item() {
        //calular el valor del item
        
        let xcosto = this.getField("costo").getValue();
        let xcantidad = this.getField("cantidad_unidad1").getValue();
        let xfactor = this.getField("factor").getValue();
        //Armar los calculos
        let xcosto1 = 0.0, xcantidad1 = 0.0;
        let xfactor1 = 1.0;
        let xcanttotal = 0.0;
        let xvalor_item = 0.0;
        xfactor1 =xfactor;
        xcantidad1 = xcantidad;
        xcosto1 = xcosto;
        xcanttotal = this.getField("cantidad").getValue() * xfactor1 * xcantidad1;
        xvalor_item = xcanttotal * xcosto1;

        this.getField("cantidad_total").setValue(Number.parseFloat(xcanttotal.toFixed(4)));
        this.getField("cantidad_total").setError(false,"");
        this.getField("valor_item").setValue(Number.parseFloat(xvalor_item.toFixed(4)));
        this.getField("cantidad_tota1").setValue(Number.parseFloat(xcanttotal.toFixed(4)));
        this.getField("valor_item1").setValue(Number.parseFloat(xvalor_item.toFixed(4)));
        if(this.getField("valor_item").valid()){
            this.getField("valor_item").setError(false,"");
        }
    }


    calcular_total() {
        //calular el valor del item
        
        let xcosto = this.getField("costo").getValue();
        let xcantidad_total = this.getField("cantidad_tota1").getValue();
        let xfactor = this.getField("factor").getValue();

        //Armar los calculos
        let xcosto1 = 0.0, xcantidad1 = 0.0;
        let xfactor1 = 1.0;
        let xcanttotal = 0.0;
        let xvalor_item = 0.0;

        xfactor1 =xfactor;
        xcanttotal = xcantidad_total;
        xcosto1 = xcosto;

        xcantidad1 = xcanttotal/(this.getField("cantidad").getValue() * xfactor1);
        xvalor_item = xcanttotal * xcosto1;
        
        this.getField("cantidad_unidad").setValue(Number.parseFloat(xcantidad1.toFixed(4)));
        this.getField("cantidad_unidad").setError(false,"");
        this.getField("valor_item").setValue(Number.parseFloat(xvalor_item.toFixed(4)));
        this.getField("cantidad_unidad1").setValue(Number.parseFloat(xcantidad1.toFixed(4)));
        this.getField("valor_item1").setValue(Number.parseFloat(xvalor_item.toFixed(4)));
        if(this.getField("valor_item").valid()){
            this.getField("valor_item").setError(false,"");
        }
        
    }

    abrir_modal(){
        
        this.getField("codigo_articulo").setDisabled(false);
        this.getField("nombre_articulo1").setDisabled(false);

        if(this.accion==="agregar" && this.getField("nombre_articulo1").getValue() !== ""){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está grabando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            this.traerSaldos();
            this.cargar_factor(); 
            if(this.wms_bodega === "S" && this.wms_nuevo==="S"){
                this.cargarUbicaciones();
            }
        }else if(this.accion === "modificar"){
            this.traercodigo();
        }else{
            this.getField("costo").setValue(0);
            this.getField("costo").setError(false,"");
            this.getField("cantidad_unidad").setValue(0);
            this.getField("cantidad_unidad").setError(false,"");
            this.getField("factor").setValue(0);
            this.getField("factor").setError(false,"");
            this.getField("cantidad_total").setValue(0);
            this.getField("cantidad_total").setError(false,"");
            this.getField("valor_item").setValue(0);
            this.getField("valor_item").setError(false,"");

            if(this.wms_bodega === "S" && this.wms_nuevo==="S"){
                this.getField("ubicacion").setDisabled(true);
                this.getField("select_ubicacion").setDisabled(false);
                this.getField("ubicacion").setValue("."); 
            }else{
                this.getField("ubicacion").setValue("999999999999"); 
                this.getField("ubicacion").setDisabled(false);
                this.getField("select_ubicacion").setDisabled(true);
            }
            this.getField("valor_item").setError(false,"");

        }
    }

    cargar_factor(){
        let datos = { datos: {
            codigo_articulo:this.getField("codigo_articulo").getValue(),
            codigo_producto:this.getField("codigo_producto").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'cargarfactor', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successcargarFactor,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successcargarFactor(data){
        this.traercosto();
        if(data.estado_p === 200){
            this.getField("cantidad_unidad").setValue(data.data[0].cantidad);
            this.getField("factor").setValue(data.data[0].factor);
        }else if(data.estado_p ===404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    traercosto(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.codigo_sucursal,
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'traercosto', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successtraercosto,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successtraercosto(data){
        this.traercodigo();
        if(data.estado_p === 200){
            this.getField("costo").setValue(data.data.costo);
            this.getField("costo").setError(false,"");
            this.getField("cantidad_unidad").setValue("1,0000");
            this.getField("cantidad_unidad").setError(false,"");
            this.getField("factor").setValue(1);
            this.getField("factor").setError(false,"");
            this.getField("cantidad_total").setError(false,"");
            this.getField("valor_item").setValue(0);
            this.getField("valor_item").setError(false,"");
        }else if(data.estado_p ===404){
            let respuesta=data.data.errores;
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    validacionesAgregar(){
        if(this.getField("codigo_articulo").valid() && this.getField("nombre_articulo1").valid() && this.getField("costo").valid()
        && this.getField("cantidad_unidad").valid() && this.getField("factor").valid() && this.getField("cantidad_total").valid()
        ){
            if(this.getField("ubicacion").valid()){
                if(this.wms_bodega === "S" && this.wms_nuevo === "S"){
                    if(this.getField("select_ubicacion").valid()){
                        this.getField('grabar_agregar').setDisabled(true);
                        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
                        this.getField('confirmModalCustom').setClickDialog(()=>{});  
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                        let datos={ datos: {
                            codigo_sucursal:this.codigo_sucursal,
                            bodega:this.getField('bodega').getValue(),
                            costo:this.getField('costo').getValue(),
                            codigo_articulo:this.getField('codigo_articulo').getValue()
                        }};
                        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: this.sucessValidaciones,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                        });
                    }else if(this.accion === "modificar"){
                        this.getField("select_ubicacion").setError(false,"");
                        this.getField('grabar_agregar').setDisabled(true);
                        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
                        this.getField('confirmModalCustom').setClickDialog(()=>{});  
                        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                        this.getField('confirmModalCustom').toggle(true);
                        let datos={ datos: {
                            codigo_sucursal:this.codigo_sucursal,
                            bodega:this.getField('bodega').getValue(),
                            costo:this.getField('costo').getValue(),
                            codigo_articulo:this.getField('codigo_articulo').getValue()
                        }};
                        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: this.sucessValidaciones,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                        });
                    }
                }else{
                    this.getField('grabar_agregar').setDisabled(true);
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
                    this.getField('confirmModalCustom').setClickDialog(()=>{});  
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    let datos={ datos: {
                        codigo_sucursal:this.codigo_sucursal,
                        bodega:this.getField('bodega').getValue(),
                        costo:this.getField('costo').getValue(),
                        codigo_articulo:this.getField('codigo_articulo').getValue()
                    }};
                    this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.sucessValidaciones,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });  
                }
            }else{
                this.alertGeneral.toggle(true, 'Debe seleccionar una ubicación.', 'error');
            }
        }
    }

    validacionesAgregar2(input){
        this.getField('grabar_agregar').setDisabled(true);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        if(this.getField('codigo_articulo').valid()){
            let datos={ datos: {
                  sucursal:this.codigo_sucursal,
                  input:input,
                  bodega:this.getField('bodega').getValue(),
                  costo:this.getField('costo').getValue(),
                  codigo_articulo:this.getField('codigo_articulo').getValue(),
                  codigo_usuario:this.codigo_usuario
            }};
            this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'enviarcorreo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessValidaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    sucessValidaciones(data)
    {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('grabar_agregar').setDisabled(false);
        if (data.estado_p === 200 && data.data.paso === "")
        { 
            if(data.data.costo_pro!==undefined){
                //pedir el input
                let costo_pro=data.data.costo_pro;
                if(costo_pro===0){
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El costo_pro_'+this.getField('bodega').getValue()+' es cero. ¿Está seguro de continuar?');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                }else{
                    let valor_costo = Math.abs((costo_pro - this.getField('costo').getValue())/ costo_pro) * 100;
                    let margen_costo_promedio=data.data.margen_costo_promedio;
                    if (valor_costo > margen_costo_promedio) {
                    this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'El valor del costo sobrepasa el margen costo entrada. Margen costo entrada= '+margen_costo_promedio.toFixed(1)+'. Variación='+valor_costo.toFixed(4)+'. ¿Está seguro de continuar? ');
                    this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false);this.validacionesAgregar2(0) });
                    this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    }else{
                    this.validacionesAgregar2(0)
                    }
                }
            }else{
                return true;
            }
        }
        else 
        {
            if(data.estado_p===200 && data.data.paso==="true") {
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                let datos = { datos: {
                    sucursal:this.codigo_sucursal,
                    bodega:this.getField("bodega").getValue(),
                    codigo_articulo:this.getField("codigo_articulo").getValue(),
                    nombre_articulo1:this.getField("nombre_articulo1").getValue(),
                    ubicacion:this.getField("ubicacion").getValue(),
                    cantidad_unidad:String(this.getField("cantidad_unidad").getValue()),
                    costo:String(this.getField("costo").getValue()),
                    factor:String(this.getField("factor").getValue()),
                    cantidad_total:String(this.getField("cantidad_total").getValue()),
                    valor_item:String(this.getField("valor_item").getValue()),                
                    pantalla:"orden",
                    accion:this.accion,
                    codigo_usuario:this.codigo_usuario
                }};
                
                this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'grabaritem', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successGrabarItem,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
                });
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    successGrabarItem(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.saldototal();
            this.limpiarcampos();
            this.alertGeneral.toggle(true, 'Registro grabado. ', 'success');
        }else if(data.estado_p ===404){
            let respuesta=data.data.errores;
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    limpiarcampos(){
        this.getField("codigo_articulo").setDisabled(false);
        this.getField("codigo_articulo").setValue("");
        this.getField("nombre_articulo1").setValue("");
        this.getField("costo").setValue(0);
        this.getField("cantidad_unidad").setValue(0);
        this.getField("factor").setValue(0);
        this.getField("cantidad_total").setValue(0);
        this.getField("valor_item").setValue(0);
        this.getField("valor_item").setError(false,"");
        this.getField("ubicacion").setValue("");
        let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
        this.getField('select_ubicacion').setOptions(opciones);  
        this.getField('select_ubicacion').setValue('');           
        this.gridOptionsComponentes1['rowData'] = [];
        this.getField('rejilla_bodega').toggle(false);
        this.gridOptionsComponentes2['rowData'] = [];
        this.getField('rejilla_ubicacion').toggle(false);
    }

    limpiarcampo(){
        this.getField("nombre_articulo1").setError(false,"");
        this.getField("costo").setValue(0);
        this.getField("cantidad_unidad").setValue(0);
        this.getField("factor").setValue(0);
        this.getField("cantidad_total").setValue(0);
        this.getField("valor_item").setValue(0);
        this.getField("valor_item").setError(false,"");
        if(this.wms_bodega === "S" && this.wms_nuevo==="S"){
            this.getField("ubicacion").setError(false,"");
            this.getField("ubicacion").setValue("");
            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            this.getField('select_ubicacion').setOptions(opciones);  
            this.getField('select_ubicacion').setValue('');     
            this.getField("select_ubicacion").setError(false,"");
        }
        this.gridOptionsComponentes1['rowData'] = [];
        this.getField('rejilla_bodega').toggle(false);
        this.gridOptionsComponentes2['rowData'] = [];
        this.getField('rejilla_ubicacion').toggle(false);
    }

    cargarUbicaciones(){
        let datos = { datos: {
            bodega:this.getField("bodega").getValue(),
            codigo_sucursal:this.lasucursal,
            codigo_articulo:this.getField("codigo_articulo").getValue()
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'cargarubicaciones', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successcargarUbicaciones,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage:false
        });
    }

    successcargarUbicaciones(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.id;
                dataOp['text'] = item.ubicacion_codigo;
                dataOp['campos_cambian'] = { ubicacion: item.ubicacion_codigo};
                opciones.push(dataOp);
            });
            this.getField('select_ubicacion').setOptions(opciones);

        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No hay datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores.mensaje);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    grabarorden(){
        if(this.getField("codigo_sucursal").valid() && this.getField("nombre_sucursal").valid() && this.getField("bodega").valid()
        && this.getField("codigo_producto").valid() && this.getField("nombre_articulo").valid() && this.getField("cantidad").valid()
        && this.getField("total").valid()){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            let datos = { datos: {
                codigo_sucursal:this.getField("codigo_sucursal").getValue(),
                bodega:this.getField("bodega").getValue(),
                codigo_producto:this.getField("codigo_producto").getValue(),
                cantidad:this.getField("cantidad").getValue(),
                wms_nuevo:this.wms_nuevo,
                total:this.total,
                fecha:this.getField("fecha").getValue() + " "+this.getField("hora").getValue(),
                bodega_wms:this.wms_bodega,
                codigo_usuario:this.codigo_usuario
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'grabarorden', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGrabarOrden,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        }
    }

    successGrabarOrden(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.entro = false;
            this.numero_consecutivo=data.data.consecutivo;
            this.alertGeneral.toggle(true, 'DATOS GRABADOS CORRECTAMENTE. ', 'success');
            this.boton_selecionado='grabar_orden';
            if(this.getField("total").getValue() === "0"  && this.items.length === 1){
                this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La orden de producción número: '+this.numero_consecutivo + ' se grabó correctamente, el informe no se genera al no tener ítems asociados.');
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); this.limpiarTodo() });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else{
                this.abrirImprimir();
            }
        }else if(data.estado_p ===404){
            let respuesta=data.data.errores.mensaje;
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error '+respuesta, 'error');
        }
    }

    imprimir(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.generarModalLista();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            codigo_sucursal: this.getField('codigo_sucursal').getValue(),
            sucursal_ingreso:this.codigo_sucursal,
            fecha: this.getField('fecha').getValue() +" "+this.getField('hora').getValue(),
            numero: this.numero_consecutivo,
            codigo_producto:this.getField('codigo_producto').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: "imprimir", operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla3()
    {       
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2={
            codigo_sucursal: this.getField('codigo_sucursal').getValue(),
            sucursal_ingreso:this.codigo_sucursal,
            fecha: this.getField('fecha').getValue() +" "+this.getField('hora').getValue(),
            numero: this.numero_consecutivo,
            codigo_producto:this.getField('codigo_producto').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual
        }

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-grabarordenproduccion","operacion":"imprimir","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2={
            codigo_sucursal: this.getField('codigo_sucursal').getValue(),
            sucursal_ingreso:this.codigo_sucursal,
            fecha: this.getField('fecha').getValue() +" "+this.getField('hora').getValue(),
            numero: this.numero_consecutivo,
            codigo_producto:this.getField('codigo_producto').getValue(),
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual
        }
        
        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-grabarordenproduccion","operacion":"imprimir","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'OrdenProduccion.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    generarModalLista(){
        this.mostrarMensajeGenerando();
        let datos={ datos: { 
            codigo_sucursal: this.getField('codigo_sucursal').getValue(),
            sucursal_ingreso:this.codigo_sucursal,
            fecha: this.getField('fecha').getValue() +" "+this.getField('hora').getValue(),
            numero: this.numero_consecutivo,
            codigo_producto:this.getField('codigo_producto').getValue(),
            operacion:this.operacion_actual
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: "imprimir", operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessTraerDatos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
        
    }

    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
            {
                "empresa": this.lista.empresa,
                "razon_social": this.lista.razon_social,
                "nit":this.lista.nit,
                "sucursal": this.lista.sucursal + " " + this.getField('nombre_sucursal').getValue(),
                "direccion": this.lista.direccion,
                "bodega_origen": this.lista.bodega_origen,
                "bodega_destino": this.lista.bodega_destino,
                "orden_de_produccion_numero": this.lista.orden_numero,
                "fecha_orden": this.lista.fecha,
                "detalle": this.lista.detalle,
                "articulo_a_producir": this.getField('codigo_producto').getValue() +" "+ this.lista.nombre_producto,
                "cantidad_a_producir": this.lista.cantidad_presupuestada.toFixed(2),
            };
            datosFicha.push(data2);
            this.getField('lista_datos_general').setItemsFichas(datosFicha);
            
            let configCell = new Map();
            this.getField('tabla_consultar').toggle(true);
            //configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});

            this.gridOptionsConsultarModal['rowData'] = [];
            this.gridOptionsConsultarModal['rowData'] = data.data.datos_items;
            this.getField('total_valor').setVisible(true);
            this.getField('total_valor').setValue(this.currencyFormatterGeneral(this.lista.costo_total));

            configCell.set('cantidad_por_unidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_por_unidad.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('factor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.factor.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad_presupuestada', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad_presupuestada.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Cantidad Total' });
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Costo Unitario' });
            configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item.toFixed(4)) }, cellStyle: {textAlign:"right"},width: 110, headerName: 'Costo item' });
            
            this.getField('tabla_consultar').initData(this.gridOptionsConsultarModal,configCell);

            this.getField('modal_general').handleClickOpen();
        }else if(data.estado_p === 404){
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }


    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            this.getField('confirmModalCustom').setClickDialog(()=>{});  
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    limpiarTodo(){
        this.getField("codigo_producto").setValue("");
        this.getField("nombre_articulo").setValue("");
        this.getField("codigo_sucursal").setValue("");
        this.getField("nombre_sucursal").setValue("");
        this.getField("bodega").setValue("");
        this.getField("nombre_bodega").setValue("");
        this.getField("cantidad").setValue("");
        this.getField("total").setValue("");
        this.gridOptionsComponentes['rowData'] = [];
        this.getField('rejilla').toggle(false);
        this.initForm();
    }

    limpiar(){
        this.comenzo=false;
        this.getField("total").setValue("");
        this.gridOptionsComponentes['rowData'] = [];
        this.getField('rejilla').toggle(false);
    }

    traercodigo(){
        if(this.getField('codigo_articulo').getValue() !== '' ){
            let datos = { datos: {
                codigo_usuario:this.codigo_usuario,
                codigo_articulo: this.getField('codigo_articulo').getValue(),
                ubicacion:this.getField("ubicacion").getValue()
            }};
        
            this.generalFormatPmv = { tipo_servicio: 'inve-grabarordenproduccion', operacion: 'existe_articulo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
   }

   successTraerDatos(data){
        if (data.estado_p === 200 )
        {           
            this.getField('confirmModalCustom').toggle(false);
            if (data.data.length >= 1) {
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'El código '+data.data[0].codigo+' ya existe, ¿Desea modificarlo?');
                this.getField('confirmModalCustom').setClickDialog(()=>{this.entro=true; this.Modificar(data.data[0].codigo,data.data[0].nombre,data.data[0].factor,data.data[0].cantxund,data.data[0].cantidad_total,data.data[0].costo,data.data[0].costo_item,data.data[0].ubicacion)});
                this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
                this.getField('confirmModalCustom').setClickCancelDialog(() => {
                    this.validarcancelarconfirmar();
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField('confirmModalCustom').toggle(true);
            }
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
   }

   validarcancelarconfirmar(){
        if(this.entro=== false){
            this.limpiarcampos();
        }
    }
}
FormJaivana.addController("inve-grabarordenproduccion", CustomGrabarOrdenProduccion);
export default CustomGrabarOrdenProduccion;