/**
 * @description Custom con funciones adicionales al formulario con código: cont-cajadiario, ID Formulario: 151, Sección: padre: Libros Oficiales(id: 35)- hijo: Contabilidad(id: 5)
 * @author Sebastian Rios Echeverri
 * @version jdesk_1.01.0001.1
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomFacturaciones extends FormJaivana.form {


    constructor(props) {

        super(props);
        // Variables de tablas:
        this.gridOptionsActivosFijosGarantias      =   Object.assign({},this.gridOptions);
       
        // Variables Globales:
        this.arrayObjetos                           =   new Map();
        this.arrayCampos                            =   new Map();
        this.operacion_actual                       =   '';
       
        // Funciones:
        this.initForm                               =   this.initForm.bind(this);
        this.accionCamposFrm                        =   this.accionCamposFrm.bind(this);
        this.btnConsultarPdf                        =   this.btnConsultarPdf.bind(this);
        this.successBtnConsultarPdf                 =   this.successBtnConsultarPdf.bind(this);
        this.btnConsultarExcel                      =   this.btnConsultarExcel.bind(this);
        this.successBtnConsultarExcel               =   this.successBtnConsultarExcel.bind(this);
        this.isJson                                 =   this.isJson.bind(this);
        this.limpiarCampos                          =   this.limpiarCampos.bind(this);
    }

    initForm() {

        console.log('Formulario: Caja Diario,  @version: jdesk_1.01.0001.1, @author: Sebastian Rios Echeverri');

        this.arrayCampos.set(1, '.');
        this.arrayCampos.set(2, 'fecha');
        this.arrayCampos.set(3, 'sucursales');
        this.arrayCampos.set(4, 'todas');
        this.arrayCampos.set(5, 'por_fecha');
        this.arrayCampos.set(6, 'niif');
        this.arrayCampos.set(7, 'centro_costo');
        this.arrayCampos.set(8, 'consultar');
        this.arrayCampos.set(9, '.');
        this.arrayCampos.set(10, 'ch_pdf');
        this.arrayCampos.set(11, 'ch_excel');
        this.arrayCampos.set(12, 'radio_correo');
        this.arrayCampos.set(13, 'email');


        this.arrayObjetos.set(1, '.');
        this.arrayObjetos.set(2, 'fecha');
        this.arrayObjetos.set(3, 'sucursales');
        this.arrayObjetos.set(4, 'todas');
        this.arrayObjetos.set(5, 'por_fecha');
        this.arrayObjetos.set(6, 'niif');
        this.arrayObjetos.set(7, 'centro_costo');
        this.arrayObjetos.set(8, 'consultar');
        this.arrayObjetos.set(9, '.');
        this.arrayObjetos.set(10, 'ch_pdf');
        this.arrayObjetos.set(11, 'ch_excel');
        this.arrayObjetos.set(12, 'radio_correo');
        this.arrayObjetos.set(13, 'email');


        this.getField('radio_correo').setValue("N");
        this.accionCamposFrm('primer_dia_mes');
        this.getField('ch_todas').setValueSwitch(true);
        this.getField('sucursales').setOnChange(() => { this.accionCamposFrm('sucursales'); });
        this.getField('consultar').setClick(() => { this.accionCamposFrm('consultar'); });
        this.getField('ch_niif').setOnChange(() => { this.accionCamposFrm('ch_niif'); });
        this.getField('ch_pdf').setOnChange(() => { this.accionCamposFrm('ch_pdf'); });
        this.getField('ch_excel').setOnChange(() => { this.accionCamposFrm('ch_excel'); });
        this.getField('radio_correo').setOnChange(() => { this.accionCamposFrm('radio_correo'); });
        this.getField('ch_todas').setOnChange(() => { this.accionCamposFrm('ch_todas'); });
        this.getField('centro_costo').setKeyUp(() => { this.accionCamposFrm('centro_costo'); });
    }


    //*** definir los botones y los success
    limpiarCampos(){
        this.accionCamposFrm('primer_dia_mes');
        this.getField('sucursales').setValue('');
        this.getField('ch_todas').setValueSwitch(true);
        this.getField('ch_todas').setDisabled(false);
        this.getField('ch_por_fecha').setValueSwitch(false);
        this.getField('ch_niif').setValueSwitch(false);
        this.getField('centro_costo').setDisabled(false);
        this.getField('centro_costo').setValue('');
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('radio_correo').setValue("N");
        this.getField('email').setValue('');
    }
    
    btnConsultarExcel(operacion){
        // console.log("btnConsultarExcel");
        this.getField('confirmModalCustom').setTitleAndContent('¡Informativo!', ' Por favor espere. Si el archivo es demasiado grande, al finalizar se enviara el anexo en EXCEL al correo: '+ this.getField('email').getValue());
        this.getField('confirmModalCustom').setClickDialog(() => {});
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        
        let url = this.constant.formConfiguration();
        let datosAgregar = {};
        datosAgregar['fecha'] = this.getField('fecha').getValue();
        datosAgregar['sucursales'] = this.getField('sucursales').getValue();
        datosAgregar['centro_costo'] = this.getField('centro_costo').getValue();
        datosAgregar['ch_todas'] = this.getField('ch_todas').getValue();
        datosAgregar['ch_por_fecha'] = this.getField('ch_por_fecha').getValue();
        datosAgregar['ch_niif'] = this.getField('ch_niif').getValue();
        datosAgregar['ch_pdf'] = this.getField('ch_pdf').getValue();
        datosAgregar['ch_excel'] = this.getField('ch_excel').getValue();
        datosAgregar['radio_correo'] = this.getField('radio_correo').getValue();
        datosAgregar['email'] = this.getField('email').getValue();
        datosAgregar['operacion'] = this.operacion_actual;

        this.limpiarCampos();
        const myJSON = JSON.stringify(datosAgregar);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-cajadiario","operacion":"'+operacion+'","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'
            })
        })
        .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            this.getField('consultar').setDisabled(false);
            // console.log('Excel 1');
            if(response.status!==200){
                this.limpiarCampos();
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;
                }
                controller.enqueue(value);
                return pump();});
            }}})
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successBtnConsultarExcel(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'otroscuadresespecificos.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                        this.getField('confirmModalCustom').toggle(false);
                        this.limpiarCampos();
                    } 
                });
            }else{
                // this.limpiarTemporal();
                // console.log('excel no hay datos');
                // this.alertGeneral.toggle(true,'No hay datos','error');
                this.getField('confirmModalCustom').setTitleAndContent('¡Advertencia!', 'El documento no tiene páginas');
                this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false);});
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }
            // this.getField('confirmModalCustom').toggle(false);
            this.getField('consultar').setDisabled(false);
            // console.log('Excel 2');
        }).catch(err => {
            this.limpiarCampos();
            this.alertGeneral.toggle(true,err,'error');
            // console.log('Excel 3');
        });
    }
    
    successBtnConsultarExcel(data){
        // console.log("data successBtnConsultarExcel:\n",data);
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true,data.data.mensaje,'success');
        }else{
            this.alertGeneral.toggle(true,data.data.mensaje,'error');
        }
    }
    
    btnConsultarPdf(operacion){
        // console.log("btnConsultarPdf");
        if (operacion !== '' && this.operacion_actual !== '') {
            this.getField('confirmModalCustom').setTitleAndContent('¡Informativo!', ' Por favor espere. Si el archivo es demasiado grande, al finalizar se enviará el anexo en PDF al correo: '+ this.getField('email').getValue());
            this.getField('confirmModalCustom').setClickDialog(() => {});
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            let url = this.constant.formConfiguration();
            let datosAgregar = {};
            datosAgregar['fecha'] = this.getField('fecha').getValue();
            datosAgregar['sucursales'] = this.getField('sucursales').getValue();
            datosAgregar['centro_costo'] = this.getField('centro_costo').getValue();
            datosAgregar['ch_todas'] = this.getField('ch_todas').getValue();
            datosAgregar['ch_por_fecha'] = this.getField('ch_por_fecha').getValue();
            datosAgregar['ch_niif'] = this.getField('ch_niif').getValue();
            datosAgregar['ch_pdf'] = this.getField('ch_pdf').getValue();
            datosAgregar['ch_excel'] = this.getField('ch_excel').getValue();
            datosAgregar['radio_correo'] = this.getField('radio_correo').getValue();
            datosAgregar['email'] = this.getField('email').getValue();
            datosAgregar['operacion'] = this.operacion_actual;
            // console.log(datosAgregar);

            this.limpiarCampos();
            const myJSON = JSON.stringify(datosAgregar);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-cajadiario","operacion":"'+operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
                method:'GET', 
                headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'
                })
            })
            .then(response => {
                // console.log("Entro 1");
                this.getField('confirmModalCustom').toggle(false);
                this.getField('consultar').setDisabled(false);
                if(response.status!==200){
                    // console.log("Entro 2");
                    this.limpiarCampos();
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    }}
                })
            })
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    // console.log("Entro 3");
                    blob.text().then(res=>{
                        // console.log("Entro 4");
                        if(this.isJson(res)){
                            // console.log("Entro 5:\n",JSON.parse(res));
                            this.successBtnConsultarPdf(JSON.parse(res));
                            this.getField('consultar').setDisabled(false);
                        }else{
                            // console.log("Entro 6");
                            const pdf = new File([blob], 'otroscuadresespecificos.pdf', {
                                type:'application/pdf'
                            });
                            window.open(URL.createObjectURL(pdf));
                            this.getField('confirmModalCustom').toggle(false);
                            this.limpiarCampos();
                        } 
                    });
                }else{
                    // console.log("Entro 7");
                    // console.log('pdf no hay datos');
                    // this.alertGeneral.toggle(true,'No hay datos','error');
                    this.getField('confirmModalCustom').setTitleAndContent('¡Advertencia!', 'El documento no tiene páginas');
                    this.getField('confirmModalCustom').setClickDialog(() => {this.getField('confirmModalCustom').toggle(false);});
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                }
                // this.getField('confirmModalCustom').toggle(false);
                this.getField('consultar').setDisabled(false);
            }).catch(err => {
                // console.log("Entro 8");
                this.limpiarCampos();
                this.alertGeneral.toggle(true,err,'error');
            });
        }
    }

    successBtnConsultarPdf(data){
        // console.log("data successBtnConsultarPdf:\n",data);
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true,data.data.mensaje,'success');
        }else{
            this.alertGeneral.toggle(true,data.data.mensaje,'error');
        }
    }
    
    isJson(str) {
        // console.log('Entró a isJson:\n',str);
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    accionCamposFrm(campo){
        switch (campo) {
            case "sucursales":
                this.getField('sucursales').setKeyUp((props)=>{
                    this.getField('sucursales').setError(false,'');
                    if(this.getField('sucursales').getValue()!=='' && this.getField('sucursales').getValue()!==' '){
                      this.getField('ch_todas').setDisabled(true);
                      this.getField('ch_todas').setValueSwitch(false);
                      this.getField('sucursales').valid();
                    }else{
                      this.getField('ch_todas').setDisabled(false);
                      this.getField('ch_todas').setValueSwitch(true);
                    }
                });
                break;
        
            case "consultar":
                this.getField('consultar').setDisabled(true);
                const validar = ()=>{
                    if (this.getField('ch_niif').getValue() === 'false') {
                        if (this.getField('ch_todas').getValue() === 'false') {
                            if (this.getField('fecha').valid() && this.getField('sucursales').valid()/*  && this.getField('centro_costo').valid() */) {
                                this.operacion_actual === 'pdf' ? this.btnConsultarPdf('1_1') : this.btnConsultarExcel('1_1');
                            }else{
                                this.getField('consultar').setDisabled(false);
                            }
                        }else if (this.getField('ch_todas').getValue() === 'true'){
                            if (this.getField('fecha').valid()/*  && this.getField('centro_costo').valid() */) {
                                this.operacion_actual === 'pdf' ?  this.btnConsultarPdf('1_1') : this.btnConsultarExcel('1_1');
                            }else{
                                this.getField('consultar').setDisabled(false);
                            }
                        }
                    } else {
                        if (this.getField('ch_todas').getValue() === 'false') {
                            if (this.getField('fecha').valid() && this.getField('sucursales').valid()) {
                                this.operacion_actual === 'pdf' ? this.btnConsultarPdf('1_2') : this.btnConsultarExcel('1_2');
                            }else{
                                this.getField('consultar').setDisabled(false);
                            }
                        }else if (this.getField('ch_todas').getValue() === 'true'){
                            if (this.getField('fecha').valid()) {
                                this.operacion_actual === 'pdf' ? this.btnConsultarPdf('1_2') : this.btnConsultarExcel('1_2');
                            }else{
                                this.getField('consultar').setDisabled(false);
                            }
                        }
                    }
                };
                if (this.getField('fecha').valid()) {
                    if (!(this.getField('ch_pdf').getValue()==='false' && this.getField('ch_excel').getValue()==='false')) {
                        if(this.getField('radio_correo').valid()){
                            if(this.getField('email').valid()){
                                if (this.getField('ch_pdf').getValue() === 'false') {
                                    this.operacion_actual = 'excel';
                                }else if (this.getField('ch_excel').getValue()==='false'){
                                    this.operacion_actual = 'pdf';
                                }
                                validar();
                            }else{
                                this.getField('consultar').setDisabled(false);
                            }
                        }else{
                            this.getField('consultar').setDisabled(false);
                        }
                    }else{
                        this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf o Excel.', 'error');
                        this.getField('consultar').setDisabled(false);
                    }
                }else{
                    this.getField('consultar').setDisabled(false);
                }
                break;
        
            case "primer_dia_mes":
                let fechaActual = new Date();
                let mes = fechaActual.getMonth() + 1;
                let anio = fechaActual.getFullYear();
                let fechaDia1 = anio+"-"+mes+"-1";
                let cambioFormato = Date.parse(fechaDia1);
                let fecha = new Date(cambioFormato);
                let campoFecha = fecha.toISOString();
                this.getField('fecha').setValue(campoFecha.split('T')[0]);
                break;
        
            case "ch_niif":
                if (this.getField('ch_niif').getValue() === 'false') {
                    this.getField('centro_costo').setValue('');
                    this.getField('centro_costo').setError(false,'');
                    this.getField('centro_costo').setDisabled(true);
                }else if (this.getField('ch_niif').getValue() === 'true'){
                    this.getField('centro_costo').setValue('');
                    this.getField('centro_costo').setError(false,'');
                    this.getField('centro_costo').setDisabled(false);
                }
                break;
        
            case "ch_pdf":
                if(this.getField('ch_pdf').getValue()==='false'){
                    this.getField('ch_excel').setValueSwitch(false);
                    this.operacion_actual='pdf';
                    this.accionCamposFrm('habilitar_correo');
                }
                break;
                
            case "ch_excel":
                if(this.getField('ch_excel').getValue()==='false'){
                    this.getField('ch_pdf').setValueSwitch(false);
                    this.operacion_actual='excel';
                    this.accionCamposFrm('habilitar_correo');
                }
                break;
                
            case "deshabilitar_correo":
                this.getField('email').setDisabled(true);
                this.getField('email').setValue('');
                this.getField('email').setError(false,'');
                this.getField('radio_correo').setDisabled(true);
                this.getField('radio_correo').setValue("N");
                break;
                
            case "habilitar_correo":
                this.getField('email').setDisabled(false);
                this.getField('radio_correo').setDisabled(false);
                this.getField('radio_correo').setValue("N");
                break;
                
            case "radio_correo":
                this.getField('email').setValue('');
                this.getField('email').setError(false,'');
                /* if(this.getField("radio_correo").getValue()==='S'){
                    this.getField('email').setDisabled(false);
                }else{
                    this.getField('email').setDisabled(true);
                    this.getField('email').setValue('');
                    this.getField('email').setError(false,'');
                } */
                break;
                
            case "ch_todas":
                this.getField('sucursales').setValue("");
                this.getField('sucursales').setError(false,"");
                break;
                
            case "centro_costo":
                this.getField('centro_costo').getValue() === '' ? this.getField('centro_costo').setError(false,'') : this.getField('centro_costo').valid();
                break;
        
            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }
    //*** fin  los botones y los success

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }
}
FormJaivana.addController('cont-cajadiario', CustomFacturaciones);
export default CustomFacturaciones;