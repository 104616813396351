import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomLiquidacionIva
 * @author: Anderson Acevedo
 * @version:  jdesk_1.01.0001.1
 **/



class CustomLiquidacionIva extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.validarfechas                                          = this.validarfechas.bind(this);
        this.cargarFechas                                           = this.cargarFechas.bind(this);
        this.limpiarErrores                                         = this.limpiarErrores.bind(this);
        this.crearTabla                                             = this.crearTabla.bind(this);
        this.sucessCrearTabla                                       = this.sucessCrearTabla.bind(this);
        this.limpiarCampos                                          = this.limpiarCampos.bind(this);
        this.successDeleteTmp                                       = this.successDeleteTmp.bind(this);
        this.limpiarTemporal                                        = this.limpiarTemporal.bind(this);
        this.validarCampos                                 = this.validarCampos.bind(this);
        this.successvalidarCampos                          = this.successvalidarCampos.bind(this);
        this.gridOptionsComponentes                                 = Object.assign({},this.gridOptions);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
        this.traerPropiedadLiquidarIva                                  = this.traerPropiedadLiquidarIva.bind(this);
        this.successTraerPropiedadLiquidarIva                           = this.successTraerPropiedadLiquidarIva.bind(this);
        this.liquidar_iva_cuentas="";
        this.codigo_sucursal ='';
        this.traerDias                                              = this.traerDias.bind(this);
        this.successTraerDias                                       = this.successTraerDias.bind(this);
        this.dias_maximo=0;

    }



    initForm() {
        console.log("Formulario CustomLiquidacionIva,  @version:  jdesk_1.01.0001.1, @author: Anderson Acevedo ");
        this.cargarFechas();
        this.getField("fecha_desde").setOnChange(this.validarfechas);
        this.getField("fecha_hasta").setOnChange(this.validarfechas);
        this.traerDias();
        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;

        this.getField("procesar").setClick(this.validarCampos);
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.traerPropiedadLiquidarIva();
        this.getField('nombre_sucursal').setOnChange(()=>{
                this.getField('rejilla').toggle(false);
        });
    }

    traerDias(){
        let datos={ datos: {
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-liquidacioniva', operacion: 'traerdias', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDias,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successTraerDias(data){
        if(data.estado_p === 200){ 
            this.dias_maximo=data.data.liquidacion_iva_dias_devolver;
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    traerPropiedadLiquidarIva(){
        let datos={ datos: {sucursal_ingreso: this.codigo_sucursal}};

        this.generalFormatPmv = { tipo_servicio: 'cont-liquidacioniva', operacion: 'traerpropiedadliquidariva', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerPropiedadLiquidarIva,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successTraerPropiedadLiquidarIva(data){
        if(data.estado_p === 200){ 
            this.liquidar_iva_cuentas=data.data.liquidar_iva_cuentas;
        }else{
            this.liquidar_iva_cuentas="";
        }
    }

    validarCampos(){
        this.getField('rejilla').toggle(false);
        let errores=0;
        this.limpiarErrores();
        let valFechas=this.validarfechas();
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==''){
            if(!this.getField('codigo_sucursal').valid())
                errores++;
            if(!this.getField('nombre_sucursal').valid())
                errores++;
        }
        if(valFechas===false){
            errores++;
        };

        if(errores===0){



            if(this.liquidar_iva_cuentas!== '' && this.liquidar_iva_cuentas==='S'){
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                this.getField('procesar').setDisabled(true);
                let datos={ datos: {
                    fecha_desde:this.getField('fecha_desde').getValue(),
                    fecha_hasta:this.getField('fecha_hasta').getValue()
                }};
                if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
                    datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                this.generalFormatPmv = { tipo_servicio: 'cont-liquidacioniva', operacion: 'ivacuentas', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successvalidarCampos,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }else{
                this.crearTabla();
            }
        }
    }
    onSelectionChanged(){
        
    }
    

    successvalidarCampos(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('procesar').setDisabled(false);
        if(data.estado_p === 200){ 
            this.getField('rejilla').toggle(true);
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            configCell.set('vr_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vr_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('vr_base', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.vr_base) }, cellStyle: {textAlign:"right"},width: 110});
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
            if(this.liquidar_iva_cuentas!== '' && this.liquidar_iva_cuentas==='S'){
            
            }else{
                this.limpiarTemporal();
            }

        }else if(data.estado_p === 404){
            this.getField('rejilla').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            if(this.liquidar_iva_cuentas!== '' && this.liquidar_iva_cuentas==='S'){
            
            }else{
                this.limpiarTemporal();
            }
        }
        else{
            this.getField('rejilla').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            if(this.liquidar_iva_cuentas!== '' && this.liquidar_iva_cuentas==='S'){
            
            }else{
                this.limpiarTemporal();
            }
        } 
    }


    validarfechas(){
        let fecha_actual = new Date().toISOString().split('T')[0];
        let fecha_desde = new Date(this.getField("fecha_desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("fecha_hasta").getValue()).toISOString().split('T')[0];

        this.getField("fecha_desde").setError(false,"");
        this.getField("fecha_hasta").setError(false,"");
        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_hasta > fecha_actual){
            this.getField("fecha_hasta").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }
        
        let fecha=new Date(fecha_actual);
        fecha.setDate(fecha.getDate()-this.dias_maximo);
        fecha=fecha.toISOString().split('T')[0];
        if(fecha_desde < fecha){
            this.getField("fecha_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        this.getField('procesar').setDisabled(false); 
        return true;

    }


    cargarFechas(){

        //let fecha_desde = this.getField('fecha_desde').getValue();
        //let fecha_desde_nueva = fecha_desde.split('-',2)[0]+'-'+fecha_desde.split('-',2)[1]+'-01';
        this.getField('fecha_desde').setValue(this.fecha_hoy);
    }

    crearTabla(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('procesar').setDisabled(true);
        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue()
        }};
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!=='' )
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        this.generalFormatPmv = { tipo_servicio: 'cont-liquidacioniva', operacion: 'iva', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessCrearTabla,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    sucessCrearTabla(data){
          if (data.estado_p === 200 )
          {
            let datos={ datos: { }};
            this.generalFormatPmv = { tipo_servicio: 'cont-liquidacioniva', operacion: '1_iva_pag', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successvalidarCampos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
          }
          else 
          {
            
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.limpiarCampos();
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
          }
          
      }

    limpiarTemporal(){
        let datos={ datos: {
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-liquidacioniva', operacion: '7_1', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
        });
    }

    successDeleteTmp(data){
        //tmp_conta1_codigousuario
        if (data.estado_p === 200){
            //this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    limpiarCampos(){
        this.getField('nombre_sucursal').setValue('');
        this.getField('codigo_sucursal').setValue('');
        this.getField('procesar').setDisabled(false);
        this.getField('fecha_desde').setValue(this.fecha_hoy);
        this.getField('fecha_hasta').setValue(this.fecha_hoy);
        this.limpiarErrores();
        this.cargarFechas();
    }

      limpiarErrores(){
        this.getField('nombre_sucursal').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('fecha_desde').setError(false,'');
        this.getField('fecha_hasta').setError(false,'');
      }

      currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
    

}
FormJaivana.addController("cont-liquidacioniva", CustomLiquidacionIva);
export default CustomLiquidacionIva;