import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomValorizarInventario
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 **/
class CustomValorizarInventario extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                   = this.initForm.bind(this);
        this.gridOptionsComponentes1                    = Object.assign({}, this.gridOptions);
        this.valorizarinventario                        = this.valorizarinventario.bind(this);
        this.sucessvalorizarinventario                  = this.sucessvalorizarinventario.bind(this);
        this.formatNumberSaldo                          = this.formatNumberSaldo.bind(this);
        this.currencyFormatterGeneral                   = this.currencyFormatterGeneral.bind(this);
        this.abrirImprimir                              = this.abrirImprimir.bind(this);
        this.procesar                                   = this.procesar.bind(this);
        this.habilitarCorreo                            = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                         = this.deshabilitarCorreo.bind(this);
        this.enviarCorreo                               = this.enviarCorreo.bind(this);
        this.successEnviarCorreo                        = this.successEnviarCorreo.bind(this);
        this.mostrarMensajeEmailEnviado                 = this.mostrarMensajeEmailEnviado.bind(this);
        this.generarPdfTabla                            = this.generarPdfTabla.bind(this);
        this.isJson                                     = this.isJson.bind(this);
        this.generarExcelTabla                          = this.generarExcelTabla.bind(this);
        this.gridOptionsComponentesConsultarModal       = Object.assign({}, this.gridOptions);
        this.limpiar                                    = this.limpiar.bind(this);

    }

    initForm() {

        console.log("Formulario CustomValorizarInventario,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.getField("procesar").setClick(this.valorizarinventario);
        this.getField("imprimir").setClick(this.abrirImprimir);
        this.getField("imprimir").setDisabled(true);
        this.getField("nombre_clasificacion").setOnChange(this.limpiar);
        this.getField("nombre_campo_base").setOnChange(this.limpiar);
        this.getField("nombre_bodega").setOnChange(this.limpiar);
        this.getField('generar_archivo').setClick(()=>this.procesar());
        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
                this.getField('ch_pdf').setValueSwitch (false);
                this.operacion_actual='excel';
                this.habilitarCorreo();
            }
        });

        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
                this.getField('ch_excel').setValueSwitch (false);
                this.operacion_actual='pdf';
                this.habilitarCorreo();
            }
        });
    }

    valorizarinventario(){  
        if (this.getField('clasificacion').valid() && this.getField('nombre_clasificacion').valid() && this.getField('campo_base').valid() && 
        this.getField('nombre_campo_base').valid() && this.getField('bodega').valid()) {
            if( !this.getField('bodega').getValue().includes(',')){
                if(this.getField('nombre_bodega').valid()){
                    this.mostrarMensajeGenerando();
                    let datos={ datos: {
                        clasificacion:this.getField('clasificacion').getValue(),
                        campo_base:this.getField('campo_base').getValue(),
                        bodega:this.getField('bodega').getValue(),
                        sql:this.getField('sql').getValue(),
                        tabla:this.getField('tabla').getValue(),
                        campo_nombre:this.getField('campo_nombre').getValue()
                    }};
                    this.generalFormatPmv = { tipo_servicio: 'inve-valorizarinventario', operacion: 'procesar', operacion_tipo: 'consulta' };
                    this.service.send({
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.sucessvalorizarinventario,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });
                }
            }else{
                this.mostrarMensajeGenerando();
                let datos={ datos: {
                    clasificacion:this.getField('clasificacion').getValue(),
                    campo_base:this.getField('campo_base').getValue(),
                    bodega:this.getField('bodega').getValue(),
                    sql:this.getField('sql').getValue(),
                    tabla:this.getField('tabla').getValue(),
                    campo_nombre:this.getField('campo_nombre').getValue()
                }};
                this.generalFormatPmv = { tipo_servicio: 'inve-valorizarinventario', operacion: 'procesar', operacion_tipo: 'consulta' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessvalorizarinventario,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }
    }

    sucessvalorizarinventario(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200 )
        {
            this.getField("procesar").setDisabled(true);
            this.getField('imprimir').setDisabled(false);
            this.getField('rejilla').toggle(true);
            this.getField('valor_total').setVisible(true);
            this.getField('valor_total').setValue(data.data.total.toFixed(2));
            let configCell = new Map();
            this.gridOptionsComponentes1['rowData'] = data.data.datos;
            configCell.set('xvalor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.xvalor)}, cellStyle: {textAlign:"right"},width: 110,headerName: this.getField('campo_base').getValue()});
            this.getField('rejilla').initData(this.gridOptionsComponentes1, configCell);
        }else {
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.mensaje);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
        }
    }

    currencyFormatterGeneral(number)
    {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
          if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>2){
              decimal=(Number(number).toFixed(2) + "").split(".")[1];
            }
          return (this.formatNumberSaldo(number) + "," + decimal);
          } else {
          return this.formatNumberSaldo(number); }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    limpiar(){
        this.getField('valor_total').setVisible(false);
        this.getField('procesar').setDisabled(false);
        this.getField('imprimir').setDisabled(true);
        this.getField('email').setError(false,'');
        this.gridOptionsComponentes1['rowData'] = [];            
        let configCell = new Map();
        this.getField('rejilla').initData(this.gridOptionsComponentes1,configCell);
        this.getField('rejilla').toggle(false)
        if(this.getField('nombre_clasificacion').getValue() !== ""){
            this.getField('nombre_clasificacion').setError(false,'');
        }
        if(this.getField('nombre_campo_base').getValue() !== ""){
            this.getField('nombre_campo_base').setError(false,'');
        }
        if(this.getField('nombre_bodega').getValue() !== ""){
            this.getField('nombre_bodega').setError(false,'');
        }
    }

    abrirImprimir(){
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'){
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' ){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: PDF o Excel.', 'error');
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreo();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    enviarCorreo(){        
        this.mostrarMensajeGenerando();
        let datos={ datos: { } };

        datos.datos={};
        datos.datos.operacion=this.operacion_actual;
        datos.datos.email=this.getField('email').getValue();
        datos.datos.radio_correo=this.getField('radio_correo').getValue();
        datos.datos.clasificacion=this.getField('clasificacion').getValue();
        datos.datos.campo_base=this.getField('campo_base').getValue();
        datos.datos.bodega=this.getField('bodega').getValue();
        datos.datos.sql=this.getField('sql').getValue();
        datos.datos.tabla=this.getField('tabla').getValue();
        datos.datos.campo_nombre=this.getField('campo_nombre').getValue()

        this.generalFormatPmv = { tipo_servicio: 'inve-valorizarinventario', operacion: 'imprimir', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    generarPdfTabla(){

        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2={};

        datos2.operacion=this.operacion_actual;
        datos2.email=this.getField('email').getValue();
        datos2.radio_correo=this.getField('radio_correo').getValue();
        datos2.clasificacion=this.getField('clasificacion').getValue();
        datos2.campo_base=this.getField('campo_base').getValue();
        datos2.bodega=this.getField('bodega').getValue();
        datos2.sql=this.getField('sql').getValue();
        datos2.tabla=this.getField('tabla').getValue();
        datos2.campo_nombre=this.getField('campo_nombre').getValue();
        datos2.sucursal_ingreso=this.sucursal_ingreso;


        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-valorizarinventario","operacion":"imprimir","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
        return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf));
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });    
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla(){
        
        this.mostrarMensajeGenerando();
        let url = this.constant.formConfiguration();
        let datos2={};

        datos2.operacion=this.operacion_actual;
        datos2.email=this.getField('email').getValue();
        datos2.radio_correo=this.getField('radio_correo').getValue();
        datos2.clasificacion=this.getField('clasificacion').getValue();
        datos2.campo_base=this.getField('campo_base').getValue();
        datos2.bodega=this.getField('bodega').getValue();
        datos2.sql=this.getField('sql').getValue();
        datos2.tabla=this.getField('tabla').getValue();
        datos2.campo_nombre=this.getField('campo_nombre').getValue();
        datos2.sucursal_ingreso=this.sucursal_ingreso;

        const myJSON = JSON.stringify(datos2);
        let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-valorizarinventario","operacion":"imprimir","operacion_tipo":"consulta"}}';
        fetch(url+datos,{
        method:'GET', 
        headers:new Headers({
                "Authorization": 'Bearer '+localStorage.getItem('token'), 
                "Content-Type":'application/json'})})
        .then(response => {
        this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
        start(controller) {
        return pump();
        function pump() {
                return reader.read().then(({ done, value }) => {
                if (done) {
                    controller.close();
                    return;}
                controller.enqueue(value);
                return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreo(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = this.nombre_archivo+'.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo exitosamente!');
                    } 
                });
            }else{
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true,err,'error');
        });
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    
    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
}
FormJaivana.addController("inve-valorizarinventario", CustomValorizarInventario);
export default CustomValorizarInventario;