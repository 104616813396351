import FormJaivana from "dashboard_jaivana_v1";

/**
 * @author Santiago Hernández
 * Custom  Importar Datos
**/

class CustomImportarDatos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                               = this.initForm.bind(this);
        this.uploadFile                             = this.uploadFile.bind(this);
        this.traerUrl                               = this.traerUrl.bind(this);
        this.succesTraerIp                          = this.succesTraerIp.bind(this);
        this.gridOptionsCarga                       = Object.assign({}, this.gridOptions);
        this.successData                            = this.successData.bind(this);
        this.url                                    = '';
        this.url_subir                              = '';
        this.path                                   = '';
        this.estadoUF                               = ''; 
        this.allowedExtensions                      = /(.xlsx|.XLSX)$/i;
        this.enviar                                 = true;
    }

    initForm() {

        console.log("Formulario CustomImportarDatos,  @version: jdesk_1.01.0004, @author: Santiago Hernández");
        this.traerUrl();
        this.getField('upload_nextcloud').setOnChange((props) => { this.estadoUF = 'nextcloud'; this.uploadFile(props); });
    }

    traerUrl() {
        let datos={datos:{}};
        this.generalFormatPmv = { tipo_servicio:  'cont-importardatos', operacion: 'ipsubirarchivo', operacion_tipo: 'consulta' };
        this.service.send(
        {
           endpoint: this.constant.formConfiguration(),
           method:'GET',
           body: datos,
           success: this.succesTraerIp,
           error: this.error_,
           general: this.generalFormatPmv,
           showMessage: false
        });
    }

    succesTraerIp(data) {
        if (data.estado_p === 200) {
            this.url_subir = data.data.url_subir_archivo;
            this.path = data.data.path_subir_archivo;
            this.url = `${this.url_subir}/${this.path}`;
        }
    }

    uploadFile(e) {
        if (this.url_subir !== '' && this.url_subir !== ' ' && this.url_subir !== undefined && this.path !== '' && this.path !== ' ' && this.path !== undefined && this.estadoUF !== '') {
            let file = e.target.files[0];
            if(!this.allowedExtensions.exec(file.name)){
                this.alertGeneral.toggle(true,'Solo se permiten archivos con extensión .xlsx','error'); 
                this.enviar = false;
            }else{
                if(file.name.indexOf(' ') > 0){
                    this.alertGeneral.toggle(true,"el archivo ("+file.name+") no puede contener espacios.",'error');
                    this.enviar = false;
                }else{
                    this.enviar = true;
                }
            }
            
            if (this.enviar === true) {                
                this.getField('tabla_error').toggle(false);

                const data = {
                    _generales: { "tipo_servicio": "subirarchivos", "operacion": this.estadoUF },
                    archivo: file//this.getField('subir_archivo').getValue(),
                }
    
                this.generalFormat = data._generales;
                this.service.send(
                    {
                        endpoint: this.url,
                        method: 'POST',
                        body: data,
                        success: this.successData,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: true,
                        showMessage: false
                    });
            }
        } else {
            this.alertGeneral.toggle(true, `La URL para procesar el archivo es inválida`, "error");
            this.estadoUF = '';
        }
    }

    successData(data) {
        this.getField('confirmModalCustom').toggle(false);
        this.estadoUF = '';
        
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Archivo cargado con éxito a la carpeta de recibidos de nextcloud.', "success");

        }else{
            this.alertGeneral.toggle(true, 'Archivo NO fue cargado con éxito a la carpeta de recibidos de nextcloud.', "error");
        }
    }

}

FormJaivana.addController("cont-importardatos", CustomImportarDatos);
export default CustomImportarDatos;