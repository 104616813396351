import FormJaivana from "dashboard_jaivana_v1";

/**

 *

 * @author Crristian Ciro

 * @UpdateBy Anderson Acevedo

/** 

 * Custom  Asignar Planilla terceros

 **/

class CustomAsignarPlanillaTerceros extends FormJaivana.form {

    constructor(props) {

        super(props);

        this.initForm                           = this.initForm.bind(this);

        this.traerDatos                         = this.traerDatos.bind(this);

        this.setButtonCambiar                   = this.setButtonCambiar.bind(this);

        this.successTraerDatos                  = this.successTraerDatos.bind(this);

        this.gridOptionsTbPrincipal             = Object.assign({},this.gridOptions);

        this.onSelectionChanged                             =   this.onSelectionChanged.bind(this);

        this.gridOptionsTbPrincipal['onSelectionChanged']         =   this.onSelectionChanged;

        this.enviarArchivo                      = this.enviarArchivo.bind(this);

        this.successCrear                       = this.successCrear.bind(this);

        this.successSubirArchivo                = this.successSubirArchivo.bind(this);

        this.eliminarPlanilla                   = this.eliminarPlanilla.bind(this);

        this.successEliminar                    = this.successEliminar.bind(this);

        this.limpiarCampos                      = this.limpiarCampos.bind(this);

        this.successEliminarArchivo             = this.successEliminarArchivo.bind(this);

        this.id                                 = 0;

        this.archivoUf                          = "";

        this.url_servidor=''

        this.traerUrlSubirArchivos= this.traerUrlSubirArchivos.bind(this);

        this.succesTraerUrlSubirArchivos= this.succesTraerUrlSubirArchivos.bind(this);

        this.limpiarCamposCrear =   this.limpiarCamposCrear.bind(this);

        this.eliminar   =   this.eliminar.bind(this);

        this.cancelarDistribucion                        = this.cancelarDistribucion.bind(this);

        this.guardarDistribucion                         = this.guardarDistribucion.bind(this);

        //this.successGuardarDistribucion                  = this.successGuardarDistribucion.bind(this);

        this.configurarDistribucion                      = this.configurarDistribucion.bind(this);

        this.reglaDistribucion                           = {};

        this.arrayTemporalDistribucion                   = [];

        this.nuevoArrayDistribucion                      = [];

        this.loadTablaItemsDistribucion                  = this.loadTablaItemsDistribucion.bind(this);

        this.crearBotonesTablaItemDistribucion           = this.crearBotonesTablaItemDistribucion.bind(this);

        this.crearBotonTabla                             = this.crearBotonTabla.bind(this);

        this.gridOptionsTablaItemsDistribucion           = Object.assign({},this.gridOptions);

        this.accionBotonDistribucion                     = this.accionBotonDistribucion.bind(this);

        this.eliminarItemTablaItemDistribucion           = this.eliminarItemTablaItemDistribucion.bind(this);

        this.eliminarItemTablaDistribucion               = this.eliminarItemTablaDistribucion.bind(this);

        this.cerrarModalDistribucion                     = this.cerrarModalDistribucion.bind(this);

        this.terminoDistribucion                         = false;

        this.cerrarDistribucion                          = this.cerrarDistribucion.bind(this);
        this.setButtonVerDistribuciuon                   = this.setButtonVerDistribuciuon.bind(this);  
        this.verDistribucion                             = this.verDistribucion.bind(this);
        this.successMostrarDistribucion                  = this.successMostrarDistribucion.bind(this);
        this.gridOptionsTbDistribucion                   = Object.assign({},this.gridOptions);
        this.setButtonModificarDistribucion              = this.setButtonModificarDistribucion.bind(this);
        this.validarDistribucion                         = this.validarDistribucion.bind(this);
        this.cerrarModalModificarDistribucion            = this.cerrarModalModificarDistribucion.bind(this);
        this.cancelarModificarDistribucion               = this.cancelarModificarDistribucion.bind(this);
        this.guardarModificarDistribucion                = this.guardarModificarDistribucion.bind(this);
        this.successModificarDistribucion                = this.successModificarDistribucion.bind(this);   
        this.setButtonEliminarDistribucion               = this.setButtonEliminarDistribucion.bind(this);
        this.eliminarDistribucion                        = this.eliminarDistribucion.bind(this);
        this.successEliminarDistribucion                 = this.successEliminarDistribucion.bind(this);
        //this.eliminarDistribucionTotal                 = this.eliminarDistribucionTotal.bind(this);
        //this.successEliminarDistribucionTotal          = this.successEliminarDistribucionTotal.bind(this);
        this.traerDistribucionArchivo                    = this.traerDistribucionArchivo.bind(this);
        this.successTraerDistribucion                    = this.successTraerDistribucion.bind(this);
    }

    initForm() {

        console.log("Formulario CustomAsignarPlanillaTerceros,  @version: jdesk_1.01.0001, @author: Cristian Ciro,@updateBy Anderson Acevedo");

        this.traerUrlSubirArchivos();

        this.getField("tercero_id").setOnChange(this.traerDatos);

        this.getField("btnAceptar").setClick(this.enviarArchivo);

        this.getField("btnCancelar").setClick(this.limpiarCampos);

        this.getField("maneja_documento_fuente").setValue("N");

        this.getField("maneja_distribucion").setValue("N");

        this.getField('maneja_distribucion').setOnChange(this.configurarDistribucion);

        this.getField('btn_cancelar_distribucion').setClick(this.cancelarDistribucion);

        this.getField('btn_guardar_distribucion').setClick(this.guardarDistribucion);

        this.getField('btn_cerrar_distribucion').setClick(this.cerrarDistribucion);

        this.getField('btn_guardar_distribucion2').setClick(this.guardarModificarDistribucion);
        this.getField('btn_cancelar_distribucion2').setClick(this.cancelarModificarDistribucion);

        this.getField('archivo').setOnChange((props) => { 
            if(props.target.files[0] !== undefined && props.target.files[0] !== " " ){
                this.archivoUf = props.target.files[0];
                /*this.uploadFile(props);*/ 
                let array=props.target.files[0].name.split('.');
                this.extension=array[array.length-1];
                if(this.extension==='XLSX' || this.extension==='xlsx'){
                    this.getField('archivo').setError(false, '');
                }else{
                    this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                }
            }else{
                this.archivoUf = "";
                this.getField('archivo').setError(false, '');
            }
        });
        this.getField('modal_registrar_distribucion').setCloseButton(this.cerrarModalDistribucion);
        this.getField('modal_distribucion').setCloseButton(this.cerrarModalModificarDistribucion);
        //this.getField('btn_eliminar_distribucion2').setClick(this.eliminarDistribucionTotal);

        this.getField('btn_traer_distribucion').setClick(this.traerDistribucionArchivo);
    }

    cerrarModalModificarDistribucion(){
        this.getField('posicion2').setVisible(false);
        this.getField('tipo2').setVisible(false);
        this.getField('detalle_distribucion2').setVisible(false);
        this.getField('porcentaje2').setVisible(false);
        this.getField('btn_guardar_distribucion2').setVisible(false);
        this.getField('btn_cancelar_distribucion2').setVisible(false);
        this.getField('detalle').setValue('');

    }

    onSelectionChanged(){

        //let rowDataTable = this.gridOptionsTbPrincipal.api.getSelectedRows();

    }

    limpiarCampos(){

        this.getField("detalle").setValue("");

        this.getField("archivo").handleClickDelete(this);

        this.getField("categoria").setValue("");

        this.getField("estadosactivos_id").setValue("");

        this.getField("nombre").setValue("");

        this.getField("sede").setValue("");

        this.getField("nit").setValue("");

        this.getField("codigo_documento").setValue("");

        this.getField("nombre_documento").setValue("");

        this.getField("documentos_id").setValue("");

        this.getField("codigo_dptointerno").setValue("");

        this.getField("nombre_dptointerno").setValue("");

        this.getField("departamento_interno_id").setValue("");

        this.getField("maneja_documento_fuente").setValue("");

        this.getField("maneja_distribucion").setValue("");

        this.getField("detalle").setValue("");

        this.getField("archivo").setValue("");

        this.getField('resumen_archivos').toggle(false);

        this.getField("nit").setError(false,'');

        this.getField("sede").setError(false,'');

        this.getField("nombre").setError(false,'');

        this.getField("categoria").setError(false,'');

        this.getField("estadosactivos_id").setError(false,'');

        this.getField("codigo_documento").setError(false,'');

        this.getField("nombre_documento").setError(false,'');

        this.getField("codigo_dptointerno").setError(false,'');

        this.getField("nombre_dptointerno").setError(false,'');

        this.getField("maneja_documento_fuente").setError(false,'');

        this.getField("maneja_distribucion").setError(false,'');

        this.getField("detalle").setError(false,'');

        this.getField("archivo").setError(false,'');

        this.getField('maneja_documento_fuente').setValue('N');

        this.getField('maneja_distribucion').setValue('N');

        this.archivoUf = "";

    }

    limpiarCamposCrear(){

        this.getField("detalle").setValue("");

        this.getField("archivo").handleClickDelete(this);

        this.getField("categoria").setValue("");

        this.getField("estadosactivos_id").setValue("");

        this.getField('resumen_archivos').toggle(false);

        this.getField("codigo_documento").setValue("");

        this.getField("nombre_documento").setValue("");

        this.getField("documentos_id").setValue("");

        this.getField("codigo_dptointerno").setValue("");

        this.getField("nombre_dptointerno").setValue("");

        this.getField("departamento_interno_id").setValue("");

        this.getField("maneja_documento_fuente").setValue("");

        this.getField("maneja_distribucion").setValue("");

        this.getField("detalle").setValue("");

        this.getField("archivo").setValue("");

        this.getField("categoria").setError(false,'');

        this.getField("estadosactivos_id").setError(false,'');

        this.getField("codigo_documento").setError(false,'');

        this.getField("nombre_documento").setError(false,'');

        this.getField("codigo_dptointerno").setError(false,'');

        this.getField("nombre_dptointerno").setError(false,'');

        this.getField("maneja_documento_fuente").setError(false,'');

        this.getField("maneja_distribucion").setError(false,'');

        this.getField("detalle").setError(false,'');

        this.getField("archivo").setError(false,'');

        this.getField('maneja_documento_fuente').setValue('N');

        this.getField('maneja_distribucion').setValue('N');

        this.archivoUf = "";

    }

    traerDatos(){

        this.limpiarCamposCrear();

        if(this.getField("tercero_id").getValue() !== ""){

            let datos = { datos: {tercero_id:this.getField("tercero_id").getValue()}};

            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '1_2', operacion_tipo: 'consulta' };

            this.service.send(

                {

                    endpoint: this.constant.formConfiguration(),

                    method:'GET',

                    body: datos,

                    success: this.successTraerDatos,

                    error: this.error_,

                    general: this.generalFormatPmv

                });

        }else{

            this.getField('resumen_archivos').toggle(false);

        }

    }

    /**

     insert into formularios_jdesk (codigo,modulo,nombre_formulario,version) values ('cont-asignarplanilla','contabilidad-plantillaselectronicas','Asignar Planilla Terceros',1);

insert into secciones_formularios_jdesk (secciones_id,formulario_id,orden) values (18,78,1);

     */

    successTraerDatos(data){

        if(data.estado_p === 200){

            this.getField('resumen_archivos').toggle(true);

            this.gridOptionsTbPrincipal['rowData'] = data.data;

            let configCell = new Map();

            configCell.set('accion', { cellRenderer: this.setButtonCambiar, width: 110, sortable: false, filter: false, field: 'accion' });
            configCell.set('ver_mas', { cellRenderer: this.setButtonVerDistribuciuon, width: 110, sortable: false, filter: false, field: 'ver_mas' });


            this.getField('resumen_archivos').initData(this.gridOptionsTbPrincipal,configCell);   

        }else{

            this.getField('resumen_archivos').toggle(false);

        }

        //this.limpiarCampos();

    }

    setButtonCambiar(props) {

        let id = props.data.id;

        let nombre_archivo = props.data.nombre_archivo;

        let button = document.createElement("input");

        button.onclick = () => this.eliminar(id, nombre_archivo);

        

        button.setAttribute("id", 'button_delete_' + id);

        button.setAttribute("class", "buttonStyle");

        button.setAttribute("type", "button");

        button.setAttribute("value", "Eliminar");

        return this.createElementJaivana(button);

    }

    eliminar(id,nombre_archivo){

        this.idEliminar = id;

        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');

        this.getField('confirmModalCustom').setClickDialog(()=>{this.eliminarPlanilla(id,nombre_archivo)});

        this.getField('confirmModalCustom').toggle(true);

    }

    eliminarPlanilla(id, nombre_archivo){

        this.getField('confirmModalCustom').toggle(false);

        this.id = id;

        if(nombre_archivo === '.'){

            let datos = { 

                datos: {

                    id: this.id,

                }

            };

            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '7', operacion_tipo: 'eliminar' };

            this.service.send(

                {

                    endpoint: this.constant.formConfiguration(),

                    method:'DELETE',

                    body: datos,

                    success: this.successEliminar,

                    error: this.error_,

                    general: this.generalFormatPmv

                });

        }else{

            if(this.url_servidor!=='' && this.url_servidor!==''){

                let datos = { 

                    datos: {

                        nombre_archivo: nombre_archivo,

                    }

                };

                let url = this.url_servidor+'/xlsx/api/protected';

                this.generalFormatPmv = { tipo_servicio:  'eliminararchivo', operacion: 'eliminar_xlsx', operacion_tipo: 'eliminar' };

                this.service.send(

                    {

                        endpoint:url,

                        method: 'DELETE',

                        body: datos,

                        success: this.successEliminarArchivo,

                        error: this.error_,

                        general: this.generalFormatPmv,

                        showMessage: false

                    });

            }else{

                this.alertGeneral.toggle(true, 'Url del servidor mal configurada.', 'error'); 

            }

        }

    }

    successEliminarArchivo(data){

        if(data.estado_p === 200){

            let datos = { 

                datos: {

                    id: this.id,

                }

            };

            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '7', operacion_tipo: 'eliminar' };

            this.service.send(

                {

                    endpoint: this.constant.formConfiguration(),

                    method:'DELETE',

                    body: datos,

                    success: this.successEliminar,

                    error: this.error_,

                    general: this.generalFormatPmv

                });

        }

    }

    successEliminar(data){

        if(data.estado_p === 200){

            this.traerDatos();

        }

    }

    setButtonVerDistribuciuon(props){
        let id = props.data.id;

        let button = document.createElement("input");
        button.onclick = () => this.verDistribucion(id);
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ver Distribución");
        if (props.data.maneja_distribucion === "N" || props.data.maneja_distribucion === 'N'){
            button.setAttribute("disabled", "true");
            button.setAttribute("hidden", "true");
            button.setAttribute("class", "buttonDisabled");
        }
        return this.createElementJaivana(button);
    }

    verDistribucion(id){
        this.plantillas_electronicas_gastos_id = id;
        this.distribucionactualizada = id; 
        let datos = { datos: {id:id}};
        this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: 'traerdistribucion', operacion_tipo: 'consulta' };
        this.service.send(
        {
           endpoint: this.constant.formConfiguration(),
           method:'GET',
           body: datos,
           success: this.successMostrarDistribucion,
           error: this.error_,
           general: this.generalFormatPmv,
           showMessage: false
        });
    }

    successMostrarDistribucion(data){
        if (data.estado_p === 200 ){
            this.getField('modal_distribucion').handleClickOpen();                
            this.getField('rejilla_visualizar_distribucion').toggle(true);
            let configCell = new Map();
            configCell.set('modificar', { cellRenderer: this.setButtonModificarDistribucion, width: 110, sortable: false, filter: false, field: 'modificar' });
            configCell.set('eliminar', { cellRenderer: this.setButtonEliminarDistribucion, width: 110, sortable: false, filter: false, field: 'eliminar' });
            this.gridOptionsTbDistribucion['rowData'] = data.data;        
            this.getField('rejilla_visualizar_distribucion').initData(this.gridOptionsTbDistribucion,configCell);                   
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'Error', 'No hay datos');
                this.getField('rejilla_visualizar_distribucion').toggle(false);
                this.getField('modal_distribucion').handleClose();     
            }else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
              this.getField('rejilla_visualizar_distribucion').toggle(false);
              this.getField('modal_distribucion').handleClose();
            }
        }
    }

    setButtonModificarDistribucion(props){        
        let button = document.createElement("input");
        button.onclick = () => this.validarDistribucion(props);
        button.setAttribute("id", 'button_modificar_' + props.data.id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Modificar");
        return this.createElementJaivana(button);
    }

    validarDistribucion(props){
        this.getField('posicion2').setValue(props.data.posicion);
        let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
        opciones.push({
            value:"NUMERICO",
            text:"NUMERICO",
            campos_cambian:{}
        }); 

        opciones.push({
            value:"TEXTO",
            text:"TEXTO",
            campos_cambian:{}
        }); 

        opciones.push({
            value:"FECHA",
            text:"FECHA",
            campos_cambian:{}
        });

        this.getField('tipo2').setOptions(opciones);
        this.getField('detalle_distribucion2').setValue(props.data.detalle);
        this.getField('porcentaje2').setValue(props.data.porcentaje);
        this.getField('id_distribucion').setValue(props.data.id);
        
        this.getField('posicion2').setVisible(true);
        this.getField('tipo2').setVisible(true);
        this.getField('detalle_distribucion2').setVisible(true);
        this.getField('porcentaje2').setVisible(true);
        this.getField('btn_guardar_distribucion2').setVisible(true);
        this.getField('btn_cancelar_distribucion2').setVisible(true);
    }


    cancelarModificarDistribucion(){
        this.getField('posicion2').setVisible(false);
        this.getField('tipo2').setVisible(false);
        this.getField('detalle_distribucion2').setVisible(false);
        this.getField('porcentaje2').setVisible(false);
        this.getField('btn_guardar_distribucion2').setVisible(false);
        this.getField('btn_cancelar_distribucion2').setVisible(false);
        this.getField('detalle').setValue('');
    }

    guardarModificarDistribucion(){

       
        if (this.getField('posicion2').getValue()!=='' && this.getField('tipo2').getValue()!=='' &&this.getField('tipo2').getValue()!=='Ninguno'&& 
        this.getField('tipo2').getValue()!==' '&& this.getField('tipo2').getValue()!== undefined && this.getField('tipo2').getValue()!== null &&
        this.getField('detalle_distribucion2').getValue()!==''&&this.getField('porcentaje2').getValue()!==''){
            let datos = { 
                datos: {
                    id:this.getField('id_distribucion').getValue(),
                    posicion:this.getField('posicion2').getValue(),
                    tipo:this.getField('tipo2').getValue(),
                    detalle:this.getField('detalle_distribucion2').getValue(),
                    porcentaje2:this.getField('porcentaje2').getValue(),                    
                }
            };
            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: 'actualizardistribucion', operacion_tipo: 'modificar'};
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successModificarDistribucion,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, "Debe seleccionar los campos de forma correcta.", "error");
        }

    }

    successModificarDistribucion(data){
        if (data.estado_p === 200 ) {
            this.getField('posicion2').setVisible(false);
            this.getField('tipo2').setVisible(false);
            this.getField('detalle_distribucion2').setVisible(false);
            this.getField('porcentaje2').setVisible(false);
            this.getField('btn_guardar_distribucion2').setVisible(false);
            this.getField('btn_cancelar_distribucion2').setVisible(false);
            this.getField('detalle').setValue('');
            

            this.verDistribucion(this.distribucionactualizada);


        }else{
            this.alertGeneral.toggle(true, "Error al actualizar la distribución.", "error");
        }
    }

    setButtonEliminarDistribucion(props){
        let button = document.createElement("input");
        button.onclick = () => this.eliminarDistribucion(props);
        button.setAttribute("id", 'button_eliminar_' + props.data.id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    eliminarDistribucion(props){
        this.idEliminarDistribucion = props.data.id;
        let datos = { 
            datos: {
                id: this.idEliminarDistribucion,
            }
        };    
        this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: 'eliminardistribucion', operacion_tipo: 'eliminar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'DELETE',
            body: datos,
            success: this.successEliminarDistribucion,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }


    successEliminarDistribucion(data){
        if (data.estado_p === 200 ) {        
            this.verDistribucion(this.distribucionactualizada);
        }else{
            this.alertGeneral.toggle(true, "Error al actualizar la distribución.", "error");    
        }    
    }

    enviarArchivo(){

        if(this.getField("nit").valid()  && this.getField("sede").valid() && this.getField("nombre").valid() && this.getField("categoria").valid() && 

        this.getField("estadosactivos_id").valid() && this.getField("codigo_documento").valid() && this.getField("nombre_documento").valid() && 

        this.getField("codigo_dptointerno").valid() && this.getField("nombre_dptointerno").valid() && this.getField("maneja_documento_fuente").valid() && this.getField("maneja_distribucion").valid() && 

        this.getField("detalle").valid() && this.getField("nit").valid()){

            

            if(this.archivoUf !== ""){

                if(this.url_servidor!=='' && this.url_servidor!==''){

                    if(this.extension==='XLSX' || this.extension==='xlsx'){

                        const data = {

                            _generales: {"tipo_servicio":"subirarchivos","operacion":"xlsx_plantilla_terceros","operacion_tipo": "crear"},

                            archivo: this.archivoUf

                        }

                        let url = this.url_servidor+'/xlsx/api/protected';

                        this.generalFormat = data._generales;

                        this.service.send(

                            {

                                endpoint:url,

                                method: 'POST',

                                body: data,

                                success: this.successSubirArchivo,

                                error: this.error_,

                                general: this.generalFormat,

                                formData: true,

                                showMessage: false

                            });

                    }else{

                        this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');

                    }

                }else{

                    this.alertGeneral.toggle(true, 'Url del servidor mal configurada.', 'error'); 

                }  

            }else{

                let datos = { 

                    datos: {

                        tercero_id:this.getField("tercero_id").getValue(),

                        nit:this.getField("nit").getValue(),

                        ruta_archivo:'.',

                        nombre_archivo:'.',

                        detalle:this.getField("detalle").getValue(),

                        contabilidad_plantillas_categorias_id:this.getField("contabilidad_plantillas_categorias_id").getValue(),

                        documentos_id:this.getField("documentos_id").getValue(),

                        departamento_interno_id:this.getField("departamento_interno_id").getValue(),

                        maneja_documento_fuente:this.getField("maneja_documento_fuente").getValue(),

                        maneja_distribucion:this.getField("maneja_distribucion").getValue()

                    }

                };

                this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '5', operacion_tipo: 'crear' };

                if(this.getField("maneja_distribucion").getValue()==='S'){

                    datos['items'] = this.nuevoArrayDistribucion;

                }

                this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: 'crearplantilla', operacion_tipo: 'crear' };

                this.service.send(

                    {

                        endpoint: this.constant.formConfiguration(),

                        method:'POST',

                        body: datos,

                        success: this.successCrear,

                        error: this.error_,

                        general: this.generalFormatPmv

                    });

            }

        }

    }

    successSubirArchivo(data){
        

        if(data.estado_p === 200){

            let datos = { 

                datos: {

                    tercero_id:this.getField("tercero_id").getValue(),

                    nit:this.getField("nit").getValue(),

                    ruta_archivo:data.data[0].ruta_archivo,

                    nombre_archivo:data.data[0].nombre_archivo,

                    contabilidad_plantillas_categorias_id:this.getField("contabilidad_plantillas_categorias_id").getValue(),

                    documentos_id:this.getField("documentos_id").getValue(),

                    departamento_interno_id:this.getField("departamento_interno_id").getValue(),

                    maneja_documento_fuente:this.getField("maneja_documento_fuente").getValue(),

                    maneja_distribucion:this.getField("maneja_distribucion").getValue(),

                    detalle:this.getField("detalle").getValue()

                }

            };

            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: '5', operacion_tipo: 'crear' };

            if(this.getField("maneja_distribucion").getValue()==='S'){

                datos['items'] = this.nuevoArrayDistribucion;

            }

            

            this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: 'crearplantilla', operacion_tipo: 'crear' };

            this.service.send(

                {

                    endpoint: this.constant.formConfiguration(),

                    method:'POST',

                    body: datos,

                    success: this.successCrear,

                    error: this.error_,

                    general: this.generalFormatPmv

                });

        }else{

            let respuesta=Object.values(data.data.errores);

			let keys=Object.keys(data.data.errores);

			this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');

        }

    }

    successCrear(data){
        this.getField("maneja_distribucion").setValue("N");
        this.getField("maneja_distribucion").setDisabled(false);
        this.gridOptionsTablaItemsDistribucion["rowData"] = [];   
        this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion);
        this.getField("rejilla_distribucion").toggle(false);
        this.reglaDistribucion         = {};
        this.arrayTemporalDistribucion = [];
        this.nuevoArrayDistribucion    = [];
        if(data.estado_p === 200){

            this.traerDatos();

            this.limpiarCamposCrear();

        }

    }

    traerUrlSubirArchivos(){

        let datos={ 

            datos: {}

        };

        this.generalFormatPmv = { tipo_servicio: 'maes-integrararchivos', operacion: 'obtenerurl', operacion_tipo: 'consulta' };

        this.service.send(

        {

                endpoint: this.constant.formConfiguration(),

                method:'GET',

                body: datos,

                success: this.succesTraerUrlSubirArchivos,

                error: this.error_,

                general: this.generalFormatPmv

        });

      }

    

      succesTraerUrlSubirArchivos(data){

          if (data.estado_p === 200 )

          {

              this.url_servidor=data.data[0].url_subir_archivo

          }

          else 

          {

            this.url_servidor='';

          }

      }

      configurarDistribucion(){

        if (this.getField('maneja_distribucion').getValue()==='S'){
            if(this.archivoUf !== ""){            
                this.loadTablaItemsDistribucion(this.nuevoArrayDistribucion);
                this.getField('modal_registrar_distribucion').handleClickOpen();
                let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
                opciones.push({
                    value:"NUMERICO",
                    text:"NUMERICO",
                    campos_cambian:{}
                }); 
                opciones.push({
                    value:"TEXTO",
                    text:"TEXTO",
                    campos_cambian:{}
                }); 
                opciones.push({
                    value:"FECHA",
                    text:"FECHA",
                    campos_cambian:{}
                });
    
                this.getField('tipo').setOptions(opciones);
                this.getField('posicion').setValue('');
                this.getField('detalle').setValue('');
                this.getField('porcentaje').setValue('');
            
            }else {
                this.alertGeneral.toggle(true, 'Debe cargar el archivo.', 'error');      
                this.getField("maneja_distribucion").setValue("N");          
            }
        }else{
            this.gridOptionsTablaItemsDistribucion["rowData"] = [];   
            this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion);
            this.getField("rejilla_distribucion").toggle(false);
            this.getField("btn_cerrar_distribucion").setVisible(false);
        }

    }

    cancelarDistribucion(){

        this.getField('modal_registrar_distribucion').handleClose();

        this.getField('posicion').setValue('');

        this.getField('tipo').setValue('');

        this.getField('detalle_distribucion').setValue('');

        this.getField('porcentaje').setValue('');

        this.nuevoArrayDistribucion = [];

        this.gridOptionsTablaItemsDistribucion["rowData"] = [];   

        this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion);

        this.getField("rejilla_distribucion").toggle(false);

        this.getField('maneja_distribucion').setValue('N');

        this.getField("btn_cerrar_distribucion").setVisible(false);

        this.terminoDistribucion = false;

    }

    guardarDistribucion(){

        if (this.getField('posicion').getValue()!==''&&this.getField('tipo').getValue()!==''&&this.getField('detalle_distribucion').getValue()!==''&&this.getField('porcentaje').getValue()!==''){

            this.reglaDistribucion = {};

            this.reglaDistribucion[`posicion`] = (this.getField('posicion').getValue());

            this.reglaDistribucion[`tipo`] = (this.getField('tipo').getValue());

            this.reglaDistribucion[`detalle_distribucion`] = (this.getField('detalle_distribucion').getValue());

            this.reglaDistribucion[`porcentaje`] = (this.getField('porcentaje').getValue());

    

            this.arrayTemporalDistribucion.push(this.reglaDistribucion); //el ítem agregado se carga primero en un array temporal

            let campoArray1 = this.reglaDistribucion.posicion; //el campo se guarda en un array

            let campoArray2 = []; // se crea un array vacio

            this.nuevoArrayDistribucion.forEach((item) => {

                //se recorre el array principal, y se busca si el campo en el array1 ya se encuentra en el array principal

                if (item.posicion === campoArray1) {

                    campoArray2.push(item); // si es asi, se carga ese campo en el array2

                }

            });

    

    

            if (campoArray2.length === 0) {

                this.nuevoArrayDistribucion.push(this.reglaDistribucion);

                this.loadTablaItemsDistribucion(this.nuevoArrayDistribucion);

                this.reglaDistribucion = {};

            } else {

                this.reglaDistribucion = {};

                //si el array2 es diferente de 0, quiere decir que el campo ya fue agregado y se muestra el mensaje

                this.alertGeneral.toggle(true, "El campo ya fue agregado", "error");

            }

        }else{

            this.alertGeneral.toggle(true, "Debe seleccionar los campos de forma correcta.", "error");

        }

        /*let datos = { datos: {

            //plantillas_electronicas_gastos_id: this.getField('plantillas_electronicas_gastos_id').getValue(),

            posicion:this.getField('posicion').getValue(),

            tipo:this.getField('tipo').getValue(),

            detalle:this.getField('detalle_distribucion').getValue(),

            porcentaje:this.getField('porcentaje').getValue(),

        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-asignarplanilla', operacion: 'guardardistribucion', operacion_tipo: 'crear' };

        this.service.send(

        {

                endpoint: this.constant.formConfiguration(),

                method:'POST',

                body: datos,

                success: this.successGuardarDistribucion,

                error: this.error_,

                general: this.generalFormatPmv,

                showMessage: false

        });*/

    }

    /*successGuardarDistribucion(data){


        if (data.estado_p === 200) {

        }else{

        }

    }*/

    loadTablaItemsDistribucion(data){

        if (data.length>0){
            this.gridOptionsTablaItemsDistribucion["rowData"] = data; //y se recarga la tabla

            let configCell = new Map();

            configCell.set('accion', { cellRenderer: this.crearBotonesTablaItemDistribucion, sortable: false, filter: false, field: "Acción"});

            this.gridOptionsTablaItemsDistribucion["onGridReady"] = (event) => event.api.sizeColumnsToFit();        

            this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion,configCell);

            this.getField("btn_cerrar_distribucion").setVisible(true);

        }else{
            this.gridOptionsTablaItemsDistribucion["rowData"] = [];   

            this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion);

            this.getField("rejilla_distribucion").toggle(false);

            this.getField("btn_cerrar_distribucion").setVisible(false);

            

        }

    }

    crearBotonesTablaItemDistribucion(props){

        let fragment = document.createDocumentFragment();

        let btnEliminarTablaItemDistribucion = this.crearBotonTabla(props.data, 'Eliminar Tabla Item');

        btnEliminarTablaItemDistribucion.setAttribute("class","buttonStyle2");

        fragment.appendChild(btnEliminarTablaItemDistribucion);

        return this.createElementJaivana(fragment);

    }

    crearBotonTabla(data,boton){

        let button = document.createElement('input');

        switch (boton) {

            case 'Eliminar Tabla Item':

                button.setAttribute("id", `boton_eliminar_tbItem_${data.posicion}`);

                break;

            default:

                break;

        }

        button.onclick = () => { this.accionBotonDistribucion(data, boton) };

        if (boton === 'Eliminar Tabla Item') {

            button.setAttribute("value", 'Eliminar');

        }else {

            button.setAttribute("value", boton);

        }

        button.setAttribute("type", "button");

        return button;

    }

    accionBotonDistribucion(data,boton){

        if (boton === 'Eliminar Tabla Item') {

            this.eliminarItemTablaItemDistribucion(data, 'Eliminar');

        }

    }

    eliminarItemTablaItemDistribucion(data,boton){

        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?')

        this.getField('confirmModalCustom').setClickDialog(() => { this.eliminarItemTablaDistribucion(data) });

        this.getField("confirmModalCustom").setVisibleCancel(false);// Para mostrar el Cancelar en los ConfirmDialog

        this.getField("confirmModalCustom").setButtonConfirm(`${boton}`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup

        this.getField('confirmModalCustom').toggle(true);

    }

    eliminarItemTablaDistribucion(data){

        this.getField('confirmModalCustom').toggle(false);

        let indexItem = this.nuevoArrayDistribucion.indexOf(data);

        this.nuevoArrayDistribucion.splice(indexItem, 1);

        if (this.nuevoArrayDistribucion.length >= 1) {

            this.gridOptionsTablaItemsDistribucion["rowData"] = this.nuevoArrayDistribucion;

            let configCell = new Map();

            configCell.set('accion', { cellRenderer: this.crearBotonesTablaItemDistribucion, sortable: false, filter: false, field: "Acción"});

            this.gridOptionsTablaItemsDistribucion["onGridReady"] = (event) => event.api.sizeColumnsToFit();   

            this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion,configCell);

            this.getField("btn_cerrar_distribucion").setVisible(true);

        } else {

            this.gridOptionsTablaItemsDistribucion["rowData"] = [];   

            this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion);

            this.getField("rejilla_distribucion").toggle(false);

            this.getField("btn_cerrar_distribucion").setVisible(false);

        }

    }

    cerrarModalDistribucion(){

        if (this.terminoDistribucion === false){

            this.getField('posicion').setValue('');

            this.getField('tipo').setValue('');

            this.getField('detalle_distribucion').setValue('');

            this.getField('porcentaje').setValue('');

            this.nuevoArrayDistribucion = [];

            this.gridOptionsTablaItemsDistribucion["rowData"] = [];   

            this.getField("rejilla_distribucion").initData(this.gridOptionsTablaItemsDistribucion);

            this.getField("rejilla_distribucion").toggle(false);

            this.getField('maneja_distribucion').setValue('N');

            this.getField("btn_cerrar_distribucion").setVisible(false);

            this.terminoDistribucion = false;

        } 

    }

    cerrarDistribucion(){

        this.terminoDistribucion=true;

        this.getField('maneja_distribucion').setValue('S');

        this.getField('maneja_distribucion').setDisabled(true);

        this.getField('modal_registrar_distribucion').handleClose();

    }

    /*eliminarDistribucionTotal(){

        let datos = { 
            datos: {
                plantillas_electronicas_gastos_id: this.plantillas_electronicas_gastos_id,
            }
        };    
        this.generalFormatPmv = { tipo_servicio:  'cont-asignarplanilla', operacion: 'eliminardistribuciongeneral', operacion_tipo: 'eliminar' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method:'DELETE',
            body: datos,
            success: this.successEliminarDistribucionTotal,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successEliminarDistribucionTotal(data){
        if (data.estado_p === 200) {
            this.getField('modal_distribucion').handleClose();                
            this.getField('rejilla_visualizar_distribucion').toggle(false);
            this.gridOptionsTbDistribucion['rowData'] = [];    
            this.getField('posicion2').setVisible(false);
            this.getField('tipo2').setVisible(false);
            this.getField('detalle_distribucion2').setVisible(false);
            this.getField('porcentaje2').setVisible(false);
            this.getField('btn_guardar_distribucion2').setVisible(false);
            this.getField('btn_cancelar_distribucion2').setVisible(false);
            this.getField('detalle').setValue('');  
        }else{
            this.alertGeneral.toggle(true, "Error al eliminar la distribución.", "error");
        }
    }*/

    traerDistribucionArchivo(){
        if(this.archivoUf !== ""){
            if(this.url_servidor!=='' && this.url_servidor!==''){
                if(this.extension==='XLSX' || this.extension==='xlsx'){
                    const data = {
                        _generales: {"tipo_servicio":"subirarchivos","operacion":"xlsx_plantilla_traer_distribucion","operacion_tipo": "crear"},
                        archivo: this.archivoUf,
                        nombre_archivo: this.nombre_archivo
                    }

                    let url = this.url_servidor+'/xlsx/api/protected';
                    this.generalFormat = data._generales;
                    this.service.send({
                        endpoint:url,
                        method: 'POST',
                        body: data,
                        success: this.successTraerDistribucion,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: true,
                        showMessage: false
                    });
                }else{
                    this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx.');
                }
            }else{
                this.alertGeneral.toggle(true, 'Url del servidor mal configurada.', 'error'); 
            }  
        }else {
            this.alertGeneral.toggle(true, 'Debe cargar el archivo.', 'error');                
        }
    }


    successTraerDistribucion(data){

        if (data.estado_p === 200) {
            this.nuevoArrayDistribucion = data.data;
            this.loadTablaItemsDistribucion(this.nuevoArrayDistribucion);
        }else{
            this.alertGeneral.toggle(true, 'Error al cargar la distribución.', 'error');
        }

    }
}

FormJaivana.addController("cont-asignarplanilla", CustomAsignarPlanillaTerceros);
export default CustomAsignarPlanillaTerceros;