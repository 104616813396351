import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomModificarArticuloDesdeArchivo
 * @author: Santiago Hernández N.
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
class CustomModificarArticuloDesdeArchivo extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                                 = this.initForm.bind(this);
        this.archivoUf                                                = "";
        this.enviarArchivo                                            = this.enviarArchivo.bind(this);
        this.url_servidor                                             = "";
        this.successSubirArchivo                                      = this.successSubirArchivo.bind(this);      
        this.gridOptionsTableGeneral                                  = Object.assign({},this.gridOptions);

        this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
        this.onSelectionChangedPrincipal                                  = this.onSelectionChangedPrincipal.bind(this);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChangedPrincipal;
 

        this.modificar                                                = this.modificar.bind(this);
        this.filaSeleccionada                                         = [];
        this.generarExcelTabla3                                 = this.generarExcelTabla3.bind(this);
        this.isJson                                 = this.isJson.bind(this);
        this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
        this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);

        this.traerPropiedades = this.traerPropiedades.bind(this);
        this.sucessTraerPropiedades = this.sucessTraerPropiedades.bind(this);

        this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeAdicional                         =this.mostrarMensajeAdicional.bind(this);
        this.columnas=[];
        this.numero_decimales=0;
        this.array_aux=[];
        this.sucursal_ingreso='';
        this.nombre_archivo='listado_articulos_modificados';

        //imprimir
        this.abrirImprimir  = this.abrirImprimir.bind(this);
        this.mostrarMensajeEmailEnviado  = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo  = this.mostrarMensajeEmailFallo.bind(this);
        this.procesar  = this.procesar.bind(this);
        this.enviarCorreoTerceros                      = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo               = this.successEnviarCorreo.bind(this);

    }

    initForm() {

        console.log('Formulario CustomModificarArticuloDesdeArchivo,  @version: jdesk_1.01.0001, @author:Santiago Hernández N.  @updateBy: Jose Albeiro Marin');
        
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        this.traerPropiedades();
        this.getField("btn_actualizar").setClick(this.abrirImprimir);
        this.getField('btn_cancelar').setDisabled(true);
        this.getField("btn_cancelar").setClick(()=>{
            this.archivoUf = "";
            this.getField('btn_actualizar').setDisabled(true);
            this.getField('btn_cancelar').setDisabled(true);
            this.getField('archivo').setError(false, '');
            this.getField('archivo').setValue('');
            this.getField('nombre_archivo').setValue('');
            this.getField('rejilla').toggle(false);
        });
        this.getField('btn_actualizar').setDisabled(true);

        this.getField('archivo').setOnChange((props) => {
            this.getField('btn_actualizar').setDisabled(true);
            if(props.target.files[0] !== undefined && props.target.files[0] !== " " ){
                this.archivoUf = props.target.files[0];

                let array=props.target.files[0].name.split('.');
                this.extension= array[array.length-1];
                
                
                if(this.extension==='XLSX' || this.extension==='xlsx' || this.extension==='xls' || this.extension==='XLS'){
                    if (props.target.files[0].name.indexOf(' ') > 0) {
                        this.alertGeneral.toggle(true, "El archivo (" + props.target.files[0].name + ") no puede contener espacios.", 'error');
                    }else{
                        this.getField('archivo').setError(false, '');
                        this.getField('nombre_archivo').setValue(props.target.files[0].name);
                        this.enviarArchivo();
                    }
                }else{
                    this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx o xls.');
                    this.getField('nombre_archivo').setValue(props.target.files[0].name);
                }
            }else{
                this.archivoUf = "";
                this.getField('btn_actualizar').setDisabled(true);
                this.getField('archivo').setError(false, '');
            }
              
        });
        this.getField('radio_correo').setOnChange(()=>{
            if(this.getField("radio_correo").getValue()==='S'){
              this.getField('email').setDisabled(false);
            }else{
              this.getField('email').setDisabled(true);
              this.getField('email').setValue('');
              this.getField('email').setError(false,'');
            }
        })

        this.getField('generar_archivo').setClick(()=>this.procesar());

    }

    traerPropiedades() {
        let datos = {
          datos: {
            sucursal_ingreso: this.sucursal_ingreso
          }
        };
        this.generalFormatPmv = { tipo_servicio: 'inve-modificardesdearchivo', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
        this.service.send({
          endpoint: this.constant.formConfiguration(),
          method: 'GET',
          body: datos,
          success: this.sucessTraerPropiedades,
          error: this.error_,
          general: this.generalFormatPmv,
          showMessage: false
        });
      }
    
      sucessTraerPropiedades(data) {
        if (data.estado_p === 200) {
          this.numero_decimales = data.data.numero_decimales;
        }
      }

    onSelectionChangedPrincipal(){

    }
    

    enviarArchivo(){
            if(this.archivoUf !== ""){
                if(this.extension==='XLSX' || this.extension==='xlsx' || this.extension==='xls' || this.extension==='XLS'){
                    const data = {
                        _generales: {"tipo_servicio":"inve-modificardesdearchivo","operacion":"leer_archivo","operacion_tipo": "crear"},
                        archivo: this.archivoUf,
                        nombre_archivo: this.getField('nombre_archivo').getValue(),
                    }
                    this.generalFormat = data._generales;
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'POST',
                        body: data,
                        success: this.successSubirArchivo,
                        error: this.error_,
                        general: this.generalFormat,
                        formData: true,
                        showMessage: false
                    });
                }else{
                    this.getField('archivo').setError(true, '* El archivo a subir tiene que tener extensión xlsx o xls.');
                } 
            }         
         
    }


    successSubirArchivo(data){
        this.getField('rejilla').toggle(false);
        this.array_aux=[];
        if (data.estado_p === 200) {
            this.getField('btn_cancelar').setDisabled(false);
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data.datos;
            let columnas=[];
            if(data.data.datos.length>0){
                columnas=Object.keys(data.data.datos[0]);
                columnas.forEach((element)=>{
                    if(data.data.tipos[element] ==='N'){
                        configCell.set(element, {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data[element]) }, cellStyle: {textAlign:"right"},width: 110});
                    }
                });
            }
            this.array_aux=data.data;
           
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
            this.getField('rejilla').toggle(true);
            this.getField('btn_actualizar').setDisabled(false)
        }else if (data.estado_p === 404) {
            this.alertGeneral.toggle(true, 'No hay datos', 'error');
        } else {
            let respuesta = Object.values(data.data.errores);
            let keys = Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + keys + ' - ' + respuesta, 'error');
        }
    }

    modificar(){
        
        if(this.getField('radio_correo').getValue()==='S'){
            this.enviarCorreoTerceros();
        }else{
            this.generarExcelTabla3();
        }
    }

    generarExcelTabla3(data)
    {
            this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2 = {
                items:this.array_aux.datos,
                columnas:this.array_aux.tipos,
                sucursal_ingreso :this.sucursal_ingreso,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: 'excel'
            };

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"inve-modificardesdearchivo","operacion":"modificar_articulos","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');

                            this.mostrarMensajeAdicional('Se modificarion los datos correctamente.');
                            this.archivoUf = "";
                            this.getField('btn_actualizar').setDisabled(true);
                            this.getField('btn_cancelar').setDisabled(true);
                            this.getField('archivo').setError(false, '');
                            this.getField('archivo').setValue('');
                            this.getField('nombre_archivo').setValue('');
                            this.getField('rejilla').toggle(false);
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    
    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales){
                number=number.toFixed(this.numero_decimales); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S'/*  || this.operacion_actual!=='pantalla' */){// Se comenta la validación de que sea diferente a pantalla, ya que en la tarea de corrección "JQJD-1480" pidieron que si en "radio_correo" está seleccionado la opción de "NO", no fuera requerido digitar el correo, ya que en la tabla siempre va a ir 2 ítems, lo que significa que el pdf sólo va a tener una página.
            if(this.getField('email').valid()===false)
                errores++;
        }
        if(errores===0){
            if(this.operacion_actual!==''){
                this.modificar();
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            items:this.array_aux.datos,
            columnas:this.array_aux.tipos,
            sucursal_ingreso :this.sucursal_ingreso,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual
        }};
        this.generalFormatPmv = { tipo_servicio: 'inve-modificardesdearchivo', operacion: 'modificar_articulos', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else if(data.estado_p ===404){
            this.mostrarMensajeNoHayDatos();
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }


    abrirImprimir(){
        //this.getField('btn_actualizar').setDisabled(true);
        let contiene_codigo=false;
        Object.keys(this.array_aux.tipos).forEach((element)=>{
            if(element==='codigo'){
                contiene_codigo=true;
            }
        });
        if(contiene_codigo){
            this.getField('modal_imprimir').handleClickOpen();
            this.getField('email').setValue('');
            this.getField('email').setError(false,'');
            this.getField('email').setDisabled(false);
            this.getField('radio_correo').setValue('N');
        }else{
           this.mostrarMensajeAdicional("No contiene la columna [codigo]");
           this.getField('btn_actualizar').setDisabled(true);
        }
    }
    

}

FormJaivana.addController("inve-modificardesdearchivo", CustomModificarArticuloDesdeArchivo);
export default CustomModificarArticuloDesdeArchivo