import FormJaivana from 'dashboard_jaivana_v1';

/**
 *
 * @author Cristian Ferney Ciro Maya
 * @modified 19-04-2023
*/
/**
 * CustomProcesarPagos
**/
class CustomProcesarPagos extends FormJaivana.form {


   constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);

        this.successPagosCompras        = this.successPagosCompras.bind(this);
        this.gridOptionsCompras         = Object.assign({}, this.gridOptions);
        this.successOtrosPagosCompras   = this.successOtrosPagosCompras.bind(this);

        this.onSelectionChangedRowTableDefault  = this.onSelectionChangedRowTableDefault.bind(this);

        this.anularPago                 = this.anularPago.bind(this);
        this.finalizarProceso           = this.finalizarProceso.bind(this);
        this.successFinalizarProceso    = this.successFinalizarProceso.bind(this);
        this.confirmFinalizarProceso    = this.confirmFinalizarProceso.bind(this);
        this.successEjecutarFuncion     = this.successEjecutarFuncion.bind(this);
    }

    initForm() {
       console.log('CustomProcesarPagos,  @version: jdesk_1.01.0001, @author: Cristian Ferney Ciro Maya');

        if(this.props.data.anular){
            this.getField("detalle").setVisible(true);
            this.getField("btn_aceptar").setVisible(true);
        }else{
            this.getField("btn_anular").setVisible(true);
            this.getField("btn_espera").setVisible(true);
            this.getField("btn_aplicado").setVisible(true);
        }

       let datos={ datos: {
            numero:this.props.data.numero
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-pagoscompras', operacion: '2_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successPagosCompras,
                error: this.error_,
                general: this.generalFormatPmv,
        });

        this.getField("btn_anular").setClick(this.anularPago);
        this.getField("btn_aceptar").setClick(this.finalizarProceso);
        this.getField("btn_aplicado").setClick(this.finalizarProceso);
    }

    onSelectionChangedRowTableDefault = () => {}

    finalizarProceso(){
        let seguir = false;
        if(this.props.data.anular){
            if(this.getField("detalle").valid()){
                seguir = true;
            }
        }else{
            seguir = true;
        }

        if(seguir){
            this.getField('confirmModalCustom').setTitleAndContent('Finalizar', 'Desea finalizar el proceso?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.confirmFinalizarProceso(); });
            this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('Aceptar'); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    confirmFinalizarProceso(){
        this.getField('confirmModalCustom').toggle(false);
        if(this.props.data.anular){
            let datos={ datos: {
                numero: this.props.data.numero,
                detalle: this.getField("detalle").getValue(),
                fecha: this.props.data.fecha,
                codigo_sucursal:this.props.data.codigo_sucursal
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'proc-pagoscompras', operacion: '5_1', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successEjecutarFuncion,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
        }else{
            let datos={ datos: {
                proceso_id: this.props.data.proceso_id,
                procesos_actividades_id: this.props.data.actividad_id,
                procesos_actividades_configuracion_id: this.props.data.actividad_configuracion_id,
                tabla:".",
                data:{
                }
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'proc-procesos', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successFinalizarProceso,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
        }
    }

    successEjecutarFuncion(data){

        if(data.estado_p === 200){
            let datos={ datos: {
                proceso_id: this.props.data.proceso_id,
                procesos_actividades_id: this.props.data.actividad_id,
                procesos_actividades_configuracion_id: this.props.data.actividad_configuracion_id,
                tabla:".",
                data:{
                }
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'proc-procesos', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successFinalizarProceso,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
        }
    }

    successFinalizarProceso(data){
        if(data.estado_p === 200){
            let datos={ datos: {
                id: this.props.data.proceso_id,
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'proc-procesos', operacion: '7', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successCloseEstage,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });
        }
    }


    anularPago(){
        let datos={ datos: {
            proceso_id: this.props.data.proceso_id,
            procesos_actividades_id: this.props.data.actividad_id,
            procesos_actividades_configuracion_id: this.props.data.actividad_configuracion_id,
            tabla:".",
            data:{
                anular:true,
                numero:this.props.data.numero
            }
        }};

        this.generalFormatPmv = { tipo_servicio: 'proc-procesos', operacion: '6', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successCloseEstage,
                error: this.error_,
                general: this.generalFormatPmv,
        });
    }

    successPagosCompras(data){
        if(data.estado_p === 200){  

            this.getField("total").setVisible(true);
            this.getField("descuentos").setVisible(true);
            this.getField("valor_neto").setVisible(true);

            this.getField("numero").setValue(data.data[0].numero);
            this.getField("fecha").setValue(data.data[0].fecha);
            this.getField("valor").setValue("".formatoPrecio(parseInt(data.data[0].valor).toFixed(2)));
            this.getField("provedor").setValue(data.data[0].nit);
            this.getField("sede").setValue(data.data[0].sede);
            this.getField("nombre_provedor").setValue(data.data[0].beneficiario);
            this.getField("banco").setValue(data.data[0].banco);
            this.getField("nombre_banco").setValue(data.data[0].nombre_banco);
            this.getField("para_el").setValue(data.data[0].para);
            this.getField("valor_neto").setValue("".formatoPrecio(parseInt(data.data[0].valor).toFixed(2)));


            this.getField('documentos').toggle(true);
            let configCell = new Map();
            this.gridOptionsCompras['rowData'] = data.data;
            this.gridOptionsCompras['onSelectionChanged'] = this.onSelectionChangedRowTableDefault;

            this.getField('documentos').initData(this.gridOptionsCompras,configCell);
            let valor_documento = 0;
            data.data.forEach(item => {
                valor_documento += item.valor_documento;
            });

            this.getField("total").setValue("".formatoPrecio(parseInt(valor_documento).toFixed(2)));

            let datos={ datos: {
                numero:this.props.data.numero
            }};
    
            this.generalFormatPmv = { tipo_servicio: 'proc-pagoscompras', operacion: '2_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successOtrosPagosCompras,
                    error: this.error_,
                    general: this.generalFormatPmv,
            });

        }else{
            this.getField('documentos').toggle(false);
        }
    }

    successOtrosPagosCompras(data){
        if(data.estado_p === 200){
            if(data.data[0].otros === null)
                this.getField("descuentos").setValue("".formatoPrecio(parseInt("0").toFixed(2)));
            else
                this.getField("descuentos").setValue("".formatoPrecio(parseInt(data.data[0].otros).toFixed(2)));
        }else{
            this.getField("descuentos").setValue("".formatoPrecio(parseInt("0").toFixed(2)));
        }
    }


}
FormJaivana.addController('proc-pagoscompras',CustomProcesarPagos);
export default CustomProcesarPagos;