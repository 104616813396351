import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomClonarPantallasAutorizadas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.aceptar                    = this.aceptar.bind(this);
        this.successcrear               = this.successcrear.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
        this.agregarpantalla            = this.agregarpantalla.bind(this);
        this.successdatospantalla       = this.successdatospantalla.bind(this);
    }

    initForm(){
        console.log('Formulario CustomClonarPantallasAutorizadas,  @version: jdesk_10.0.001, @author:Patricia López Sánchez')

        this.getField('aceptar').setClick(this.aceptar);
        this.getField('cancelar').setClick(this.limpiar);
    }

    aceptar(){
        if(this.getField('usuarios_nombre').valid() && this.getField('usuarios_nombre1').valid()){
            if(this.getField('usuarios_codigo').getValue() === this.getField('usuarios_codigo1').getValue()){
                this.alertGeneral.toggle(true, 'El usuario destino no puede ser el mismo usuario origen.', 'error');
            }else{
                let datos = {  datos: { 
                    usuarios_codigo1:this.getField('usuarios_codigo1').getValue(),
                    usuarios_codigo:this.getField('usuarios_codigo').getValue(),
                  }
                }
                this.generalFormatPmv = { tipo_servicio: 'heus-clonarpermisosusuarios', operacion: '20', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successdatospantalla,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }
        }
    }

    successdatospantalla(data){
        if (data.estado_p === 200 )
        {
            this.agregarpantalla();
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'El usuario destino ya cuenta con esas pantallas.', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    agregarpantalla(){
            let datos = {  datos: { 
                    usuarios_codigo1:this.getField('usuarios_codigo1').getValue(),
                    usuarios_codigo:this.getField('usuarios_codigo').getValue(),
                  }
                }
                this.generalFormatPmv = { tipo_servicio: 'heus-clonarpermisosusuarios', operacion: '5', operacion_tipo: 'crear' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successcrear,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successcrear(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Registros insertados con éxito', 'success');
            this.limpiar();

        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
               this.validarcampos();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    limpiar(){
        this.getField('usuarios_nombre').valid(false);
        this.getField('usuarios_nombre1').valid(false);
        this.getField('usuarios_codigo').valid(false);
        this.getField('usuarios_codigo1').valid(false);
        this.getField('usuarios_nombre').setValue('');
        this.getField('usuarios_nombre1').setValue('');
        this.getField('usuarios_codigo').setValue('');
        this.getField('usuarios_codigo1').setValue('');
    }

}
FormJaivana.addController("heus-clonarpermisosusuarios",CustomClonarPantallasAutorizadas);
export default CustomClonarPantallasAutorizadas