import FormJaivana from "dashboard_jaivana_v1";
/**
 *
 * @author Anderson Acevedo Briñez
 */
/**
 * Custom  Conceptos pago
 **/
class CustomConceptosCobro extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.consultarCuentas                  = this.consultarCuentas.bind(this);
    this.successConsultarCuentas           = this.successConsultarCuentas.bind(this);
    this.guardarConcepto                     = this.guardarConcepto.bind(this);
    this.successGuardarConcepto              = this.successGuardarConcepto.bind(this);
    this.gridOptionsTodos = Object.assign({}, this.gridOptions);
    this.setButtonDelete = this.setButtonDelete.bind(this);
    this.eliminarCuenta = this.eliminarCuenta.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.successEliminar = this.successEliminar.bind(this);
    this.validarMovimiento = this.validarMovimiento.bind(this);
    this.successValidarCodigoCuenta = this.successValidarCodigoCuenta.bind(this);
    this.validarCodigoCuenta = this.validarCodigoCuenta.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);

  }

  initForm() {
    console.log("Formulario CustomConceptosCobro,  @version: jdesk_10.0.001, @author: Anderson Acevedo Briñez");
    this.getField('guardar').setClick(this.validarCodigoCuenta);
    this.consultarCuentas();
    this.getField('movimiento').setOnChange(this.validarMovimiento);
    this.getField('nombre_cuenta').setDisabled(true);
    this.getField('codigo_cuenta').setOnChange(()=>{this.getField('codigo_cuenta').setError(false,'');this.getField('nombre_cuenta').setError(false,'');this.getField('estadoactivo_id').setValue('')});


  }

  validarMovimiento(){
      if(this.getField('movimiento').getValue() !=='' && this.getField('movimiento').getValue()==='N'){
        this.limpiarCampos();
        this.alertGeneral.toggle(true, "Solo pueden seleccionar cuentas que maneja_movimiento='S'", "error");

      }
  }

  consultarCuentas(){
    let datos = { datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'maes-conceptoscobro', operacion: '1_1', operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successConsultarCuentas,
              error: this.error_,
              general: this.generalFormatPmv
      });
  }

  successConsultarCuentas(data){
    if(data.estado_p === 200){ 
      this.getField('taba_conceptos_pago').toggle(true);
      let configCell = new Map();
      configCell.set('eliminar', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'eliminar' });
      this.gridOptionsTodos['rowData'] = data.data;
      this.getField('taba_conceptos_pago').initData(this.gridOptionsTodos, configCell);
    
    }else{
      this.getField('taba_conceptos_pago').toggle(false);
    }
  }

  validarCodigoCuenta(){
    if(this.getField('codigo_cuenta').valid() && this.getField('nombre_cuenta').valid()   && this.getField('estadoactivo_id').valid() && this.getField('cuenta_id').valid()){

      let datos = { datos: {
        value: this.getField('codigo_cuenta').getValue()
      }};


      this.generalFormatPmv = { tipo_servicio: 'maes-conceptoscobro', operacion: '20', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarCodigoCuenta,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
  }

  successValidarCodigoCuenta(data){
    if(data.estado_p === 200){ 
      this.alertGeneral.toggle(true, 'La cuenta ingresada ya existe, no se permite duplicar', "error");

    }else if(data.estado_p === 404){
      this.guardarConcepto();
    }
  }

  guardarConcepto(){

      
      let datos = { 
        datos: {
          codigo_cuenta: Number(this.getField('codigo_cuenta').getValue()),
          cuenta_id: this.getField('cuenta_id').getValue(),
          estadoactivo_id: this.getField('estadoactivo_id').getValue()
        }
      };
    
      this.generalFormatPmv = { tipo_servicio: 'maes-conceptoscobro', operacion: '5', operacion_tipo: 'crear' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'POST',
              body: datos,
              success: this.successGuardarConcepto,
              error: this.error_,
              general: this.generalFormatPmv
      });
    
  }

  successGuardarConcepto(data){ 
    if(data.estado_p === 200){
      this.limpiarCampos();
      this.consultarCuentas();
    } else{
       if(data.estado_p===404) {
           
       }
       else {
         let respuesta=Object.values(data.data.errores);
         let keys=Object.keys(data.data.errores);
         this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }
  }

  setButtonDelete(props) {
      let id = props.data.id;
      let button = document.createElement("input");
      button.onclick = () => this.eliminarCuenta(id);
      button.setAttribute("id", 'button_delete_' + id);
      button.setAttribute("class", "buttonStyle");
      button.setAttribute("type", "button");
      button.setAttribute("value", "eliminar");
      return this.createElementJaivana(button);
  }

  eliminarCuenta(id) {
    this.ids = id;
    this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
    this.getField('confirmModalCustom').setClickDialog(this.confirmDelete);
    this.getField('confirmModalCustom').toggle(true);
  }

  confirmDelete() {
    this.getField('confirmModalCustom').toggle(false);
    let datos = {
        datos: {
            id: this.ids,
        }
    };
    this.generalFormatPmv = { tipo_servicio: 'maes-conceptoscobro', operacion: '7', operacion_tipo: "eliminar" };// parametros del servicio
    this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method: 'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv //se envia el generales de arriba
        });
  }


  successEliminar(data) {
    if(data.estado_p === 200){
      this.limpiarCampos();
      this.consultarCuentas();
    }else{
        this.alertGeneral.toggle(true, 'El registro no pudo ser eliminado.', 'error');
    }
  }

  limpiarCampos(){
    this.getField('codigo_cuenta').setValue('');
    this.getField('nombre_cuenta').setValue('');
    this.getField('cuenta_id').setValue('');
    this.getField('estadoactivo_id').setValue('');
    this.getField('movimiento').setValue('');
    this.getField('nombre_cuenta').setError(false,'');
  }

}
FormJaivana.addController("maes-conceptoscobro", CustomConceptosCobro);
export default CustomConceptosCobro;