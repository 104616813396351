import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: Custom Configurar Presupuesto 
 * @author: Mario A. Sepúlveda R.
 * @version: jdesk_1.01.0001
 **/
class CustomConfigurarPresupuesto extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.arrayObjetos = new Map();
        this.arrayCampos = new Map();
        this.bHayErrores = false;
        this.initForm = this.initForm.bind(this);
        this.limpiarCamposModalAdd = this.limpiarCamposModalAdd.bind(this);
        this.limpiarCamposModificar = this.limpiarCamposModificar.bind(this);
        this.confirmModalDialog = this.confirmModalDialog.bind(this);
        this.eliminarRegistro = this.eliminarRegistro.bind(this);
        this.confirmModificar = this.confirmModificar.bind(this);
        this.consultarTabla = this.consultarTabla.bind(this);
        this.successConsultarTabla = this.successConsultarTabla.bind(this);
        this.gridOptionsPresupuesto = Object.assign({},this.gridOptions);
        this.onSelectionChangedCuenta=this.onSelectionChangedCuenta.bind(this);
        this.gridOptionsPresupuesto['onSelectionChanged']= this.onSelectionChangedCuenta;
        this.limpiarTabla = this.limpiarTabla.bind(this);
        this.agregarCuentaPresupuesto = this.agregarCuentaPresupuesto.bind(this);
        this.successCrearCuentaPresupuesto = this.successCrearCuentaPresupuesto.bind(this);
        this.validarRelacionAnioCuentaUnico = this.validarRelacionAnioCuentaUnico.bind(this);
        this.successValidarRelacion = this.successValidarRelacion.bind(this);
        this.eliminarRegistro = this.eliminarRegistro.bind(this);
        this.successEliminar = this.successEliminar.bind(this);
        this.successTraerPresupuesto = this.successTraerPresupuesto.bind(this);
        this.actualizarTotal = this.actualizarTotal.bind(this);
        this.abrirModalAgregar = this.abrirModalAgregar.bind(this);
        this.modificarPresupuesto = this.modificarPresupuesto.bind(this);
        this.successModificar = this.successModificar.bind(this);
        this.filaSeleccionada = [];
        this.total = 0;
    }

    initForm() {
        console.log("Formulario CustomConfigurarPresupuesto, @version: jdesk_1.01.0001, @author: Mario A. Sepúlveda R.");

        this.arrayCampos.set(1, 'id');
        this.arrayCampos.set(2, 'anio_');
        this.arrayCampos.set(3, 'tabla_cuentas');
        this.arrayCampos.set(4, 'adicionar');
        this.arrayCampos.set(5, 'modificar');
        this.arrayCampos.set(6, 'eliminar');
        this.arrayCampos.set(7, 'modal_adicionar');
        this.arrayCampos.set(8, 'anio_agregar');
        this.arrayCampos.set(9, 'codigo_cuenta_');
        this.arrayCampos.set(10, 'nombre');
        this.arrayCampos.set(11, 'agregar');
        this.arrayCampos.set(12, 'campo_oculto');
        this.arrayCampos.set(13, 'cancelar');
        this.arrayCampos.set(14, 'modal_modificar');
        this.arrayCampos.set(15, 'lista_datos');
        this.arrayCampos.set(16, 'enero');
        this.arrayCampos.set(17, 'febrero');
        this.arrayCampos.set(18, 'marzo');
        this.arrayCampos.set(19, 'abril');
        this.arrayCampos.set(20, 'mayo');
        this.arrayCampos.set(21, 'junio');
        this.arrayCampos.set(22, 'julio');
        this.arrayCampos.set(23, 'agosto');
        this.arrayCampos.set(24, 'septiembre');
        this.arrayCampos.set(25, 'octubre');
        this.arrayCampos.set(26, 'noviembre');
        this.arrayCampos.set(27, 'diciembre');
        this.arrayCampos.set(28, 'actualizar');
        this.arrayCampos.set(29, 'cancelar_modificar');
        this.arrayCampos.set(30, 'total');

        this.arrayObjetos.set(1, 'id');
        this.arrayObjetos.set(2, 'anio_');
        this.arrayObjetos.set(3, 'tabla_cuentas');
        this.arrayObjetos.set(4, 'adicionar');
        this.arrayObjetos.set(5, 'modificar');
        this.arrayObjetos.set(6, 'eliminar');
        this.arrayObjetos.set(7, 'modal_adicionar');
        this.arrayObjetos.set(8, 'anio_agregar');
        this.arrayObjetos.set(9, 'codigo_cuenta_');
        this.arrayObjetos.set(10, 'nombre');
        this.arrayObjetos.set(11, 'agregar');
        this.arrayObjetos.set(12, 'campo_oculto');
        this.arrayObjetos.set(13, 'cancelar');
        this.arrayObjetos.set(14, 'modal_modificar');
        this.arrayObjetos.set(15, 'lista_datos');
        this.arrayObjetos.set(16, 'enero');
        this.arrayObjetos.set(17, 'febrero');
        this.arrayObjetos.set(18, 'marzo');
        this.arrayObjetos.set(19, 'abril');
        this.arrayObjetos.set(20, 'mayo');
        this.arrayObjetos.set(21, 'junio');
        this.arrayObjetos.set(22, 'julio');
        this.arrayObjetos.set(23, 'agosto');
        this.arrayObjetos.set(24, 'septiembre');
        this.arrayObjetos.set(25, 'octubre');
        this.arrayObjetos.set(26, 'noviembre');
        this.arrayObjetos.set(27, 'diciembre');
        this.arrayObjetos.set(28, 'actualizar');
        this.arrayObjetos.set(29, 'cancelar_modificar');
        this.arrayObjetos.set(30, 'total');

        this.getField(this.arrayCampos.get(4)).setClick(() => {this.abrirModalAgregar()});

        //se detecta el cambio de cada uno de los campos del mes en el modal de modificar y se envia el campo respectivo como parametro para calcular el total 
        this.getField(this.arrayCampos.get(16)).setOnChange(()=>this.actualizarTotal(16));
        this.getField(this.arrayCampos.get(17)).setOnChange(()=>this.actualizarTotal(17));
        this.getField(this.arrayCampos.get(18)).setOnChange(()=>this.actualizarTotal(18));
        this.getField(this.arrayCampos.get(19)).setOnChange(()=>this.actualizarTotal(19));
        this.getField(this.arrayCampos.get(20)).setOnChange(()=>this.actualizarTotal(20));
        this.getField(this.arrayCampos.get(21)).setOnChange(()=>this.actualizarTotal(21));
        this.getField(this.arrayCampos.get(22)).setOnChange(()=>this.actualizarTotal(22));
        this.getField(this.arrayCampos.get(23)).setOnChange(()=>this.actualizarTotal(23));
        this.getField(this.arrayCampos.get(24)).setOnChange(()=>this.actualizarTotal(24));
        this.getField(this.arrayCampos.get(25)).setOnChange(()=>this.actualizarTotal(25));
        this.getField(this.arrayCampos.get(26)).setOnChange(()=>this.actualizarTotal(26));
        this.getField(this.arrayCampos.get(27)).setOnChange(()=>this.actualizarTotal(27));

        this.getField(this.arrayCampos.get(13)).setClick(this.limpiarCamposModalAdd);
        this.getField(this.arrayCampos.get(5)).setClick(this.confirmModificar);
        this.getField(this.arrayCampos.get(29)).setClick(this.limpiarCamposModificar);
        this.getField(this.arrayCampos.get(6)).setClick(this.confirmModalDialog);
        this.getField(this.arrayCampos.get(11)).setClick(this.validarRelacionAnioCuentaUnico);
        this.getField(this.arrayCampos.get(2)).setOnChange(()=>{this.limpiarTabla();
            this.establecerPropiedades('4,5,6','visible','false');
            this.consultarTabla();
        });

        this.getField(this.arrayCampos.get(28)).setClick(this.modificarPresupuesto);        
    }

    modificarPresupuesto(){
        let ene = this.getField(this.arrayCampos.get(16)).valid();
        let feb = this.getField(this.arrayCampos.get(17)).valid();
        let mar = this.getField(this.arrayCampos.get(18)).valid();
        let abr = this.getField(this.arrayCampos.get(19)).valid();
        let may = this.getField(this.arrayCampos.get(20)).valid();
        let jun = this.getField(this.arrayCampos.get(21)).valid();
        let jul = this.getField(this.arrayCampos.get(22)).valid();
        let ago = this.getField(this.arrayCampos.get(23)).valid();
        let sep = this.getField(this.arrayCampos.get(24)).valid();
        let oct = this.getField(this.arrayCampos.get(25)).valid();
        let nov = this.getField(this.arrayCampos.get(26)).valid();
        let dic = this.getField(this.arrayCampos.get(27)).valid();
        
        if(ene && feb && mar && abr && may && jun && jul && ago && sep && oct && nov && dic){
            this.getField(this.arrayCampos.get(28)).setDisabled(true);

            let datos={ datos: {
                id: this.getField(this.arrayCampos.get(1)).getValue(),
                ene:this.getField(this.arrayCampos.get(16)).getValue(),
                feb:this.getField(this.arrayCampos.get(17)).getValue(),
                mar:this.getField(this.arrayCampos.get(18)).getValue(),
                abr:this.getField(this.arrayCampos.get(19)).getValue(),
                may:this.getField(this.arrayCampos.get(20)).getValue(),
                jun:this.getField(this.arrayCampos.get(21)).getValue(),
                jul:this.getField(this.arrayCampos.get(22)).getValue(),
                ago:this.getField(this.arrayCampos.get(23)).getValue(),
                sep:this.getField(this.arrayCampos.get(24)).getValue(),
                oct:this.getField(this.arrayCampos.get(25)).getValue(),
                nov:this.getField(this.arrayCampos.get(26)).getValue(),
                dic:this.getField(this.arrayCampos.get(27)).getValue(),
            } };


            this.generalFormatPmv = { tipo_servicio: 'cont-configurarpresupuesto', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successModificar,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:true
                });

        }
    }

    successModificar(data){
        this.getField(this.arrayCampos.get(28)).setDisabled(false);

        if(data.estado_p === 200){
            //console.log('modificado con exito',data);
            this.getField(this.arrayCampos.get(14)).handleClose();
            this.consultarTabla();
        }
    }

    abrirModalAgregar(){
        let arrFicha = [{
                "año":this.getField(this.arrayCampos.get(2)).getValue()
            }];
            this.getField(this.arrayCampos.get(8)).setItemsFichas(arrFicha);
        this.getField(this.arrayCampos.get(7)).handleClickOpen();//this.getField(this.arrayCampos.get(8)).setValue(this.getField(this.arrayCampos.get(2)).getValue());
    }

    actualizarTotal(campo){
        let campoMes = this.getField(this.arrayCampos.get(campo)).getValue();
        if(campoMes >= 0 && campoMes !== '' && campoMes !== ''){
            if(campoMes === 0 )
                this.getField(this.arrayCampos.get(campo)).setError(false,'');

            let total = this.calcularTotal();
            //console.log('total retornado: -> ',total);
            this.getField(this.arrayCampos.get(30)).setValue(total);
        }
    }

    calcularTotal(){
        let total = 0;
        let arrObj = [{
            enero:this.getField(this.arrayCampos.get(16)).getValue(),
            febrero:this.getField(this.arrayCampos.get(17)).getValue(),
            marzo:this.getField(this.arrayCampos.get(18)).getValue(),
            abril:this.getField(this.arrayCampos.get(19)).getValue(),
            mayo:this.getField(this.arrayCampos.get(20)).getValue(),
            junio:this.getField(this.arrayCampos.get(21)).getValue(),
            julio:this.getField(this.arrayCampos.get(22)).getValue(),
            agosto:this.getField(this.arrayCampos.get(23)).getValue(),
            septiembre:this.getField(this.arrayCampos.get(24)).getValue(),
            octubre:this.getField(this.arrayCampos.get(25)).getValue(),
            noviembre:this.getField(this.arrayCampos.get(26)).getValue(),
            diciembre:this.getField(this.arrayCampos.get(27)).getValue()
        }];
        //console.log("obj:->",arrObj);

        for(let key in arrObj[0]){
            total += arrObj[0][key];
        }

        return total;
        
    }

    consultarTabla(){
        let anio = this.getField(this.arrayCampos.get(2)).getValue();
        if(anio !== '' && anio !== ' ' && anio !== undefined && anio !== null){
            this.getField(this.arrayCampos.get(2)).setDisabled(true); // se deshabilita el campo select de año mientras el servicio responde 
            let datos={ datos: {
                anio: this.getField(this.arrayCampos.get(2)).getValue()
            } };

            this.generalFormatPmv = { tipo_servicio: 'cont-configurarpresupuesto', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successConsultarTabla,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successConsultarTabla(data){
        this.getField(this.arrayCampos.get(2)).setDisabled(false); // se vuelve a habilitar el select de año
        this.establecerPropiedades('4,5,6','visible','true');
        if(data.estado_p === 200){
            this.limpiarTabla();

            //console.log("Datos: ->",data);

            let configCell = new Map();

            //configCell.set('accion', { cellRenderer: this.setButtonAccion, width: 415, sortable: false, filter: false, field: 'accion' });
            configCell.set('anio', {headerName:'Año'});
            configCell.set('codigo_cuenta', {headerName:'Código cuenta'});
            configCell.set('nombre_cuenta', {headerName:'Nombre cuenta'});
            this.getField(this.arrayCampos.get(3)).toggle(true);  
            this.gridOptionsPresupuesto['onSelectionChanged']= this.onSelectionChangedCuenta;
    
            this.gridOptionsPresupuesto['rowData'] = data.data;
            this.getField(this.arrayCampos.get(3)).initData(this.gridOptionsPresupuesto,configCell);

        }else {
            this.limpiarTabla();
        }
    }

    onSelectionChangedCuenta(){
        this.filaSeleccionada = this.gridOptionsPresupuesto.api.getSelectedRows();  
        let datosFicha = [{
            "año":this.filaSeleccionada[0].anio,
            "codigo_cuenta":this.filaSeleccionada[0].codigo_cuenta,
            "nombre_cuenta":this.filaSeleccionada[0].nombre_cuenta
        }];
        //console.log('props del registro seleccionado',this.filaSeleccionada);
        this.getField(this.arrayCampos.get(15)).setItemsFichas(datosFicha);
        this.getField(this.arrayCampos.get(1)).setValue(this.filaSeleccionada[0].id);
    }

    validarRelacionAnioCuentaUnico(){
        let codigo_cuenta = this.getField(this.arrayCampos.get(9))
        let nombre_cuenta = this.getField(this.arrayCampos.get(10));

        if(codigo_cuenta.valid() && nombre_cuenta.valid()){
            this.getField(this.arrayCampos.get(11)).setDisabled(true);
            
            let datos={ datos: {
                anio: this.getField(this.arrayCampos.get(2)).getValue(),
                codigo_cuenta:codigo_cuenta.getValue() //: this.getField(this.arrayCampos.get(9)).getValue()
            } };

            this.generalFormatPmv = { tipo_servicio: 'cont-configurarpresupuesto', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successValidarRelacion,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false
                });
        }
            
    }

    successValidarRelacion(data){
        if(data.estado_p === 200 && data.data[0].cuantos === 0){
            this.agregarCuentaPresupuesto();
        }else if(data.data[0].cuantos !== 0){
            this.alertGeneral.toggle(true,'La relación año con código cuenta ya existe. No se permite duplicar ','error');
            this.getField(this.arrayCampos.get(11)).setDisabled(false);

        }
    }

    agregarCuentaPresupuesto(){
        let codigo_cuenta = this.getField(this.arrayCampos.get(9));
        let nombre_cuenta = this.getField(this.arrayCampos.get(10));
        if(codigo_cuenta.valid() && nombre_cuenta.valid()){
            this.getField(this.arrayCampos.get(11)).setDisabled(true);
            
            let datos={ datos: {
                anio: this.getField(this.arrayCampos.get(2)).getValue(),
                codigo_cuenta:codigo_cuenta.getValue() //: this.getField(this.arrayCampos.get(9)).getValue()
            } };

            this.generalFormatPmv = { tipo_servicio: 'cont-configurarpresupuesto', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successCrearCuentaPresupuesto,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

        }
    }

    successCrearCuentaPresupuesto(data){
        this.getField(this.arrayCampos.get(11)).setDisabled(false);
        if(data.estado_p === 200){
            this.getField(this.arrayCampos.get(7)).handleClose();
            this.consultarTabla();
        }
    }


    limpiarCamposModalAdd() {
        this.getField(this.arrayCampos.get(7)).handleClose();
        this.establecerPropiedades('9,10,12', 'value', '');
        this.establecerPropiedades('9,10,12', 'error', 'false')
    }


    limpiarCamposModificar() {
        this.getField(this.arrayCampos.get(14)).handleClose(()=>{
            this.establecerPropiedades('16,17,18,19,20,21,22,23,24,25,26,27,30', 'value', 0);
            this.establecerPropiedades('16,17,18,19,20,21,22,23,24,25,26,27,30', 'error', 'false')
        });
    }

    confirmModificar(){ // consulta el presupuesto que se tiene en los campos de mes
        //let idRegistro = this.getField(this.arrayCampos.get(1)).getValue();

        if(this.filaSeleccionada.length > 0 ){
            //consumir el servicio que trae el presupuesto
            
            let datos={ datos: {
                anio: this.getField(this.arrayCampos.get(2)).getValue(),
                codigo_cuenta: `${this.filaSeleccionada[0].codigo_cuenta}` //: this.getField(this.arrayCampos.get(9)).getValue()
            } };

            this.generalFormatPmv = { tipo_servicio: 'cont-configurarpresupuesto', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successTraerPresupuesto,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:true
                });


            this.getField(this.arrayCampos.get(14)).handleClickOpen();
        }else{
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje','No se ha seleccionado nigún registro para modificar');
            this.getField("confirmModalCustom").setButtonConfirm(`OK`); 
            this.getField("confirmModalCustom").setVisibleCancel(true);// para que No se muestre el boton de cancelar
            this.getField('confirmModalCustom').setClickDialog(()=>this.getField('confirmModalCustom').toggle(false));
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    successTraerPresupuesto(data){
        if(data.estado_p === 200){
            let {enero,febrero,marzo,abril,
                mayo,junio,julio,agosto,
                septiembre,octubre,noviembre,diciembre} = data.data[0];

            let total = 0;
            for(let key in data.data[0]){ // se suma el total del presupuesto de todos los meses y se setea en el campo total
                total += data.data[0][key];
            }

            this.getField(this.arrayCampos.get(16)).setValue(enero);
            this.getField(this.arrayCampos.get(17)).setValue(febrero);
            this.getField(this.arrayCampos.get(18)).setValue(marzo);
            this.getField(this.arrayCampos.get(19)).setValue(abril);
            this.getField(this.arrayCampos.get(20)).setValue(mayo);
            this.getField(this.arrayCampos.get(21)).setValue(junio);
            this.getField(this.arrayCampos.get(22)).setValue(julio);
            this.getField(this.arrayCampos.get(23)).setValue(agosto);
            this.getField(this.arrayCampos.get(24)).setValue(septiembre);
            this.getField(this.arrayCampos.get(25)).setValue(octubre);
            this.getField(this.arrayCampos.get(26)).setValue(noviembre);
            this.getField(this.arrayCampos.get(27)).setValue(diciembre);
            this.getField(this.arrayCampos.get(30)).setValue(total);

        }
    }


    confirmModalDialog(){
        //let idRegistro = this.getField(this.arrayCampos.get(1)).getValue();
        if(this.filaSeleccionada.length > 0 ){ //this.getField(this.arrayCampos.get(1)) && idRegistro.getValue() > 0

        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.eliminarRegistro);
        this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); 
        this.getField("confirmModalCustom").setVisibleCancel(false);// para que se muestre el boton de cancelar
        this.getField('confirmModalCustom').toggle(true);

        }else{
            this.getField('confirmModalCustom').setTitleAndContent('Mensaje','No se ha seleccionado nigún registro para eliminar');
            this.getField("confirmModalCustom").setButtonConfirm(`OK`); 
            this.getField("confirmModalCustom").setVisibleCancel(true);// para que No se muestre el boton de cancelar
            this.getField('confirmModalCustom').setClickDialog(()=>this.getField('confirmModalCustom').toggle(false));
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    eliminarRegistro(){
        this.getField('confirmModalCustom').toggle(false);
        let idRegistro = this.getField(this.arrayCampos.get(1)).getValue();
        if(idRegistro !== 0){
            this.getField(this.arrayCampos.get(2)).setDisabled(true);


            let datos={ datos: {
                id:this.getField(this.arrayCampos.get(1)).getValue()    
            } };

            this.generalFormatPmv = { tipo_servicio: 'cont-configurarpresupuesto', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'DELETE',
                    body: datos,
                    success: this.successEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

        }
    }

    successEliminar(data){
        this.getField(this.arrayCampos.get(2)).setDisabled(false);
        if(data.estado_p === 200){
            this.consultarTabla();
        }
    }

    limpiarTabla(){
        this.filaSeleccionada = [];
        this.getField(this.arrayCampos.get(3)).toggle(false);
    }


    // termina la lógica del formulario

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            //console.log(element);
            //console.log(this.arrayObjetos.get(parseInt(element)));
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            } else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }


    cargarCampos(data) {
        this.getField('id').setValue(data.data[0].id);
        this.getField('anio_').setValue(data.data[0].anio_);
        this.getField('tabla_cuentas').setValue(data.data[0].tabla_cuentas);
        this.getField('adicionar').setValue(data.data[0].adicionar);
        this.getField('modificar').setValue(data.data[0].modificar);
        this.getField('eliminar').setValue(data.data[0].eliminar);
        this.getField('modal_adicionar').setValue(data.data[0].modal_adicionar);
        this.getField('anio_agregar').setValue(data.data[0].anio_agregar);
        this.getField('codigo_cuenta_').setValue(data.data[0].codigo_cuenta_);
        this.getField('nombre').setValue(data.data[0].nombre);
        this.getField('agregar').setValue(data.data[0].agregar);
        this.getField('campo_oculto').setValue(data.data[0].campo_oculto);
        this.getField('cancelar').setValue(data.data[0].cancelar);
        this.getField('modal_modificar').setValue(data.data[0].modal_modificar);
        this.getField('lista_datos').setValue(data.data[0].lista_datos);
        this.getField('enero').setValue(data.data[0].enero);
        this.getField('febrero').setValue(data.data[0].febrero);
        this.getField('marzo').setValue(data.data[0].marzo);
        this.getField('abril').setValue(data.data[0].abril);
        this.getField('mayo').setValue(data.data[0].mayo);
        this.getField('junio').setValue(data.data[0].junio);
        this.getField('julio').setValue(data.data[0].julio);
        this.getField('agosto').setValue(data.data[0].agosto);
        this.getField('septiembre').setValue(data.data[0].septiembre);
        this.getField('octubre').setValue(data.data[0].octubre);
        this.getField('noviembre').setValue(data.data[0].noviembre);
        this.getField('diciembre').setValue(data.data[0].diciembre);
        this.getField('actualizar').setValue(data.data[0].actualizar);
        this.getField('cancelar_modificar').setValue(data.data[0].cancelar_modificar);
        this.getField('total').setValue(data.data[0].total);

    }


}
FormJaivana.addController("cont-configurarpresupuesto", CustomConfigurarPresupuesto);
export default CustomConfigurarPresupuesto;