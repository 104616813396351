import FormJaivana from 'dashboard_jaivana_v1';

/**
 * @description: CustomNotasProveedores
 * @author: Mario A. Sepúlveda R.
 * @version: jdesk_1.01.0001
 **/
class CustomNotasProveedores extends FormJaivana.form {


   constructor(props) {

    super(props);
    this.initForm = this.initForm.bind(this);
    this.sucursal_ingreso='';
    this.notasProveedores                               = this.notasProveedores.bind(this);
    this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
    this.formatNumberSaldo                               = this.formatNumberSaldo.bind(this);
    this.mostrarMensajeValidandoInformacion                                  = this.mostrarMensajeValidandoInformacion.bind(this);
    this.mostrarMensajeAdicional                         =this.mostrarMensajeAdicional.bind(this);
    this.mostrarMensajeGenerando                         =this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeGuardando                         =this.mostrarMensajeGuardando.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.onSelectionChanged                         =this.onSelectionChanged.bind(this);


    this.eliminarRegistro                               = this.eliminarRegistro.bind(this);
    this.successInfoArticulo                               = this.successInfoArticulo.bind(this);
    this.infoArticulo                                  = this.infoArticulo.bind(this);
    this.traerItemNotaAcumulable                         =this.traerItemNotaAcumulable.bind(this);
    this.accionProcesar                         =this.accionProcesar.bind(this);
    this.crearBotonTabla                         =this.crearBotonTabla.bind(this);
    this.setButtonBotonesTabla                         =this.setButtonBotonesTabla.bind(this);
    this.ocultarNotaAcumubale                         =this.ocultarNotaAcumubale.bind(this);
    this.mostrarNotaAcumuable                               = this.mostrarNotaAcumuable.bind(this);
    this.refrescarNotaAcumulable                               = this.refrescarNotaAcumulable.bind(this);
    this.calcularPrecioFinal                                  = this.calcularPrecioFinal.bind(this);
    this.calcularPorcentajeDescuento                         =this.calcularPorcentajeDescuento.bind(this);
    this.calularValorDescuento                         =this.calularValorDescuento.bind(this);
    this.successValidarNotaAcumulable                         =this.successValidarNotaAcumulable.bind(this);
    this.validarNotaAcumulable                         =this.validarNotaAcumulable.bind(this);
    this.agregarNotaAcumulable                         =this.agregarNotaAcumulable.bind(this);

    this.mostrarNotas                                  = this.mostrarNotas.bind(this);
    this.sucessTraerPropiedades                         =this.sucessTraerPropiedades.bind(this);
    this.traerPropiedades                         =this.traerPropiedades.bind(this);
    this.successValidarNotaAcumulable                         =this.successValidarNotaAcumulable.bind(this);
    this.validarNotaAcumulable                         =this.validarNotaAcumulable.bind(this);
    this.agregarNotaAcumulable                         =this.agregarNotaAcumulable.bind(this);
    this.sucessGrabarGrupo                         =this.sucessGrabarGrupo.bind(this);
    this.grabarGrupo                         =this.grabarGrupo.bind(this);
    this.calcularPorcentajeDescuentoAjuste                         =this.calcularPorcentajeDescuentoAjuste.bind(this);
    this.calcularDescuentoAjuste                         =this.calcularDescuentoAjuste.bind(this);
    this.mostrarAjusteAlPrecio                         =this.mostrarAjusteAlPrecio.bind(this);
    this.OcultarAjusteAlPrecio                         =this.OcultarAjusteAlPrecio.bind(this);
    this.calcularPrecioAjuste                         =this.calcularPrecioAjuste.bind(this);

    this.limpiarAjustesPrecio                         =this.limpiarAjustesPrecio.bind(this);
    this.limpiarNotaAcumulable                         =this.limpiarNotaAcumulable.bind(this);

    this.traerDescuentosActivos                         =this.traerDescuentosActivos.bind(this);
    this.successTraerDescuentosActivos                         =this.successTraerDescuentosActivos.bind(this);
    this.limpiarCamposArticulo                         =this.limpiarCamposArticulo.bind(this);
    this.ocultarSinDescuento                         =this.ocultarSinDescuento.bind(this);
    this.mostrarSinDescuento                         =this.mostrarSinDescuento.bind(this);
    this.calcularTotal                         =this.calcularTotal.bind(this);
    this.validarGrabarNotasDescuentos                         =this.validarGrabarNotasDescuentos.bind(this);
    this.grabarNota                         =this.grabarNota.bind(this);
    this.successGrabarNota                         =this.successGrabarNota.bind(this);
    this.limpiarCampos                         =this.limpiarCampos.bind(this);
    this.mostrarSimple                         =this.mostrarSimple.bind(this);

    this.mostrarNotasP                         =this.mostrarNotasP.bind(this);
    this.diferencia                         =this.diferencia.bind(this);
    this.calcularItems                         =this.calcularItems.bind(this);
    this.recalcular                         =this.recalcular.bind(this);
    this.refrescarNotasP                         =this.refrescarNotasP.bind(this);
    this.successConsultarFactura                         =this.successConsultarFactura.bind(this);
    this.consultarFactura                         =this.consultarFactura.bind(this);
    this.validarModificar                         =this.validarModificar.bind(this);
    this.validarAgregar                         =this.validarAgregar.bind(this);
    this.definirCosto                         =this.definirCosto.bind(this);
    this.adicionarArticulo                         =this.adicionarArticulo.bind(this);
    this.limpiarCamposP                         =this.limpiarCamposP.bind(this);
    this.successGrabarNotaP                         =this.successGrabarNotaP.bind(this);
    this.grabarNotaP                         =this.grabarNotaP.bind(this);

    this.generarTxt                         =this.generarTxt.bind(this);
    this.mostrarConsultar1                         =this.mostrarConsultar1.bind(this);
    this.validarConsultar                         =this.validarConsultar.bind(this);
    this.validarCamposFecha                         =this.validarCamposFecha.bind(this);
    this.sucessConsultarNotasProveedores                         =this.sucessConsultarNotasProveedores.bind(this);
    this.consultarNotasProveedores                         =this.consultarNotasProveedores.bind(this);
    this.onSelectionChangedConsulta                         =this.onSelectionChangedConsulta.bind(this);
    this.traerPropiedadesSimple                         =this.traerPropiedadesSimple.bind(this);
    this.sucessTraerPropiedadesSimple                         =this.sucessTraerPropiedadesSimple.bind(this);
    this.validarReImprimir                         =this.validarReImprimir.bind(this);

    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.isJson                         =this.isJson.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.confirmarAbrirImprimir                         =this.confirmarAbrirImprimir.bind(this);

    
    this.imprimirListado                         =this.imprimirListado.bind(this);
    this.limpiarCamposConsultar                         =this.limpiarCamposConsultar.bind(this);

    this.successValidarSaldos                         =this.successValidarSaldos.bind(this);
    this.enviarGrabarNotap                         =this.enviarGrabarNotap.bind(this);



    
    this.gridOptionsNotasAcumulables = Object.assign({}, this.gridOptions);
    this.gridOptionsNotasAcumulables['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsDescuentos = Object.assign({}, this.gridOptions);
    this.gridOptionsDescuentos['onSelectionChanged']           = this.onSelectionChanged;
    this.gridOptionsNotasP = Object.assign({}, this.gridOptions);
    this.gridOptionsNotasP['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsSinSaldo = Object.assign({}, this.gridOptions);
    this.gridOptionsSinSaldo['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsConsulta = Object.assign({}, this.gridOptions);
    this.onSelectionChangedConsulta                         =this.onSelectionChangedConsulta.bind(this);
    this.gridOptionsConsulta['onSelectionChanged']           = this.onSelectionChangedConsulta;

    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsComponentesLista = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesLista['onSelectionChanged']           = this.onSelectionChanged;


    //propiedades
    this.numero_decimales_compras='';
    this.notas='';
    this.mostrar_pantalla='';
    this.notas_simples='';
    this.array_datos=[];
    this.array_descuentos=[];
    this.lasucursal='';
    this.costo_promedio_linea='';
    this.costo_por_bodega='';
    this.row_consulta={};

    this.registro_aux={};

    this.xfecha='';
    this.xnumero='';
    this.xfecha='';
    this.nombre_archivo='';
    this.descuento_activo=0;
    this.fecha_hoy='';
    this.fecha_inicio='';
    this.row_consulta=[];
    this.extension='';
    this.codigo_usuario ='';
    this.dias_maximo='';
    this.mostrar='';
    this.mod_valor=false;
    this.sucursal_factura='';
   }

   initForm() {
        console.log('Formulario CustomNotasProveedores,  @version: jdesk_1.01.0002, @author:Anderson Acevedo Briñez');
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.fecha_inicio=año+'-'+mes+'-01';

        if(this.props.data[0].opcion_sub_seccion === 1){
            this.notasProveedores();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.mostrarConsultar1();
        }


   }

   limpiarCamposConsultar(){
        this.getField('consultar_desde').setValue(this.fecha_inicio);
        this.getField('consultar_hasta').setValue(this.fecha_hoy);
        this.getField('numero_consulta').setValue('');
        this.getField('nit_consulta').setValue('');
        this.getField('sede_consulta').setValue('');
        this.getField('nombre_consulta').setValue('');
        this.getField('codigo_sucursal').setValue('');
        this.getField('nombre_sucursal').setValue('');
   }

   imprimirListado()
    {
        this.extension='listnota';
        //Se dejara como txt
        this.extension='txt';
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
                sucursal_ingreso: this.sucursal_ingreso,
                consultar_desde:this.getField('consultar_desde').getValue(),
                consultar_hasta:this.getField('consultar_hasta').getValue(),
            };

            if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
                datos2.nit=this.getField('nit_consulta').getValue();
                datos2.sede=this.getField('sede_consulta').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero_consulta').getValue()!=='' && this.getField('numero_consulta').getValue()!==' ')
                datos2.numero_consulta=this.getField('numero_consulta').getValue();


            let op='listadonotasp';
            this.nombre_archivo='listado_notasp_'+this.codigo_usuario;

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-notasproveedores","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

   validarReImprimir(){
    if(this.row_consulta.length>0){
        this.xsucursal=this.row_consulta[0].codigo_sucursal+'';
        this.xfecha=this.row_consulta[0].fecha;
        this.xnumero=this.row_consulta[0].numero+'';
        this.extension='nota';
        //Santiago dijo que quedaran como .txt
        this.extension='txt';
        this.generarTxt();
      }else{
          this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
      }
   }

   traerPropiedadesSimple(){
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send({
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.sucessTraerPropiedadesSimple,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage:false
    });
  }

  sucessTraerPropiedadesSimple(data){
        if (data.estado_p === 200 )
        {
            this.notas=data.data.notas;
            this.mostrar_pantalla=data.data.mostrar_pantalla;
            this.notas_simples=data.data.notas_simples;
            this.lasucursal=data.data.lasucursal;
            this.costo_promedio_linea=data.data.costo_promedio_linea;
            this.costo_por_bodega=data.data.costo_por_bodega;
            this.numero_decimales_compras=data.data.numero_decimales_compras;
            this.dias_maximo=data.data.notas_proveedores_dias_devolver;
        }
        else 
        { 
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }



   onSelectionChangedConsulta(){
     this.row_consulta = this.gridOptionsConsulta.api.getSelectedRows();

   }

   consultarNotasProveedores(){
        this.mostrarMensajeGenerando();
        this.row_consulta=[];
        this.getField('consultar').setDisabled(true);
        let datos={ datos: {
            sucursal_ingreso: this.sucursal_ingreso,
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),

        }};
        if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
            datos.datos.nit=this.getField('nit_consulta').getValue();
            datos.datos.sede=this.getField('sede_consulta').getValue();
        }
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('numero_consulta').getValue()!=='' && this.getField('numero_consulta').getValue()!==' ')
            datos.datos.numero_consulta=this.getField('numero_consulta').getValue();

        this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: 'consultar', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessConsultarNotasProveedores,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    sucessConsultarNotasProveedores(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('consultar').setDisabled(false);
        if(data.estado_p === 200){
            this.getField('rejilla_notas_proveedores').toggle(true);
            this.gridOptionsConsulta['rowData'] = [];
            let configCell = new Map();

            configCell.set('valor_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_total) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('porcentaje', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.porcentaje) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('descto_articulo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.descto_articulo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo_quedo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_quedo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.iva) }, cellStyle: {textAlign:"right"},width: 110});

            this.gridOptionsConsulta['rowData'] = data.data;
            this.getField('rejilla_notas_proveedores').initData(this.gridOptionsConsulta,configCell);
            this.getField('modal_consulta').handleClickOpen();
        }else{
            //this.ocultarCampos();
            if(data.estado_p===404) {
                //this.alertGeneral.toggle(true, 'No hay datos', 'error');
                this.mostrarMensajeNoHayDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

   validarCamposFecha(){
        this.fila_seleccionada={}
        this.getField('consultar').setDisabled(false);
        let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
        let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
        let fecha_actual = new Date().toISOString().split('T')[0];
        if (fechaInicial > fechaFinal) {
            this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
            this.getField('consultar').setDisabled(true);
            return false;
        } 

        if(fechaFinal > fecha_actual){
            this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        let diferencia=new Date(fechaFinal).getTime()-new Date(fechaInicial).getTime();
        diferencia = diferencia / 1000 / 60 / 60 / 24;
        if(diferencia >this.dias_maximo){
            this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('consultar').setDisabled(true); 
            return false;
        }
        

        this.getField('consultar_desde').setError(false,"");
        this.getField('consultar_hasta').setError(false,"");
        return true;
    }

    validarConsultar(){
        this.getField('numero_consulta').setError(false,'');
        this.getField('nit_consulta').setError(false,'');
        this.getField('sede_consulta').setError(false,'');
        this.getField('nombre_consulta').setError(false,'');
        this.getField('codigo_sucursal').setError(false,'');
        this.getField('nombre_sucursal').setError(false,'');
    
        let errores=0;
        if(!this.getField('consultar_hasta').valid())
            errores++;
        if(this.validarCamposFecha()===false)
            errores++;
        if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
            if(!this.getField('nit_consulta').valid())
                errores++;
            if(!this.getField('sede_consulta').valid())
                errores++;
            if(!this.getField('nombre_consulta').valid())
                errores++;
        }
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
            if(!this.getField('codigo_sucursal').valid())
                errores++;
            if(!this.getField('nombre_sucursal').valid())
                errores++;
        }
        if(this.getField('numero_consulta').getValue()!=='' && this.getField('numero_consulta').getValue()!==' '){
            if(!this.getField('numero_consulta').valid())
                errores++;
        }

        if(errores===0){
            this.consultarNotasProveedores();
        }
    
    }

   mostrarConsultar1(){
        this.getField('tipo_opcion').setValue('notasp');
        this.traerPropiedadesSimple();
        this.getField('consultar_desde').setVisible(true);
        this.getField('consultar_hasta').setVisible(true);
        this.getField('numero_consulta').setVisible(true);
        this.getField('nit_consulta').setVisible(true);
        this.getField('sede_consulta').setVisible(true);
        this.getField('nombre_consulta').setVisible(true);
        this.getField('codigo_sucursal').setVisible(true);
        this.getField('nombre_sucursal').setVisible(true);
        this.getField('consultar').setVisible(true);

        
        this.getField('consultar_desde').setValue(this.fecha_inicio);
        this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
        this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);

        this.getField('consultar').setClick(this.validarConsultar);
        //modal
        this.getField('bt_reimprimir').setClick(()=>{this.validarReImprimir(); });
        this.getField('bt_imprimir_listado').setClick(()=>{this.mostrar='lista';this.imprimirListado();this.confirmarAbrirImprimir(); });
        this.getField('bt_presentacion').setClick(()=>{this.mostrar='nota';this.confirmarAbrirImprimir(); });
        this.getField('generar_archivo').setClick(()=>{this.procesar()});
        this.getField('bt_salir').setClick(()=>{this.getField('modal_consulta').handleClose();});
        this.getField('modal_consulta').setCloseButton(()=>{this.limpiarCamposConsultar(); });
        //imprimir
        this.getField('radio_correo').setValue("N");
        this.getField('radio_correo').setOnChange(()=>{this.getField('email').setError(false,'');});

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
              this.getField('ch_pdf').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='excel';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pdf').setValueSwitch (false);
              this.operacion_actual='pantalla';
              this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
          });
  
   }

   deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

   


   onSelectionChanged(){


   }
   grabarNotaP(){
    let errores=0;
    if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('detalle').valid()){
        if(this.getField('numero_factura').getValue()!==''){
            if(this.getField('numero_factura').valid() &&  this.getField('bodega').valid()
             && this.getField('valor_factura').valid() && this.getField('fecha_factura').valid()
             && this.getField('porcentaje_factura').valid() && this.getField('valor_notap').valid()){
                
            }else{
                errores++;
                return;
            }
        }
        if (this.getField('total_descuento_p').getValue() <= 0.0) {
            this.mostrarMensajeAdicional("No hay datos para grabar.");
            errores++;
            return;
        }
        if(this.costo_promedio_linea==='S'){
            this.mostrarMensajeValidandoInformacion();
            let codigo='';
            this.array_datos.forEach((element)=>{
                if(element.valor_descto>0)
                    codigo+=element.codigo+',';
            });
            let datos={ 
                datos: {
                    value:codigo,
                    bodega: this.getField('bodega').getValue()
                }
            };
            this.generalFormatPmv = { tipo_servicio:  'comp-notasproveedores', operacion: '1_saldos_articulos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarSaldos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false,
            });
            
        }else{
            //termina de validar
            if(errores===0){
                this.enviarGrabarNotap();    
            } 
        }  
    }
   }

   enviarGrabarNotap(){
        let datos={ datos: {
            nit: this.getField('nit').getValue(),
            sede: this.getField('sede').getValue(),
            nombre: this.getField('nombre').getValue(),
            detalle: this.getField('detalle').getValue(),
            sucursal_ingreso:this.sucursal_ingreso,
            bodega: this.getField('bodega').getValue(),
            total_descuento_p:this.getField('total_descuento_p').getValue(),
        }};
        if(this.getField('numero_factura').getValue()!=='' && this.getField('fecha_factura').getValue()!==''){
            datos.datos.numero_factura=this.getField('numero_factura').getValue();
            datos.datos.valor_factura=this.getField('valor_factura').getValue();
            datos.datos.fecha_factura=this.getField('fecha_factura').getValue();
            datos.datos.porcentaje_factura=this.getField('porcentaje_factura').getValue();
            datos.datos.sucursal_factura=this.sucursal_factura;
        }else{
            datos.datos.valor_factura = this.getField('total_descuento_p').getValue();
            datos.datos.numero_factura = "999999";
            datos.datos.fecha_factura = "2000/01/01";
            datos.datos.sucursal_factura = ".";
            datos.datos.porcentaje_factura = 0.0;
        }
        datos.datos.array_datos=this.array_datos;
        this.mostrarMensajeGuardando();
        this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: 'grabarnotap', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successGrabarNotaP,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        }); 
   }


   successValidarSaldos(data){
        if (data.estado_p === 200 )
        {
            //mostar datos que no tienen saldo
            let array_aux=data.data;
            if(array_aux.length>0){
                this.getField('modal_sin_saldo').handleClickOpen();
                this.gridOptionsSinSaldo['rowData'] = [];
                let configCell = new Map();
                this.gridOptionsSinSaldo['rowData'] = array_aux;
                this.getField('rejilla_sin_saldo').toggle(true);
                this.getField('rejilla_sin_saldo').initData(this.gridOptionsSinSaldo, configCell);
                this.mostrarMensajeAdicional('Los siguientes artículos no tienen saldo. La nota no puede ser asentada.');
            }else{
                this.enviarGrabarNotap();
            }
        }
        else 
        {
            if(data.estado_p===404) {
                //no hay repetidos
                this.enviarGrabarNotap();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

   successGrabarNotaP(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, 'Nota grabada con éxito.', 'success');
            this.xfecha=data.data.xfecha;
            this.xnumero=data.data.xnumero;
            this.xfecha=data.data.xfecha;
            this.xsucursal=data.data.xsucursal;

            this.extension='txt';
            this.generarTxt();
            this.abrirImprimir();
            this.limpiarCamposP();
        }
        else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }


   limpiarCamposP(){
        this.getField('nit').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre').setValue('');
        this.getField('detalle').setValue('');
        this.getField('numero_factura').setValue('');
        this.getField('fecha_factura').setValue('');
        this.getField('valor_notap').setValue('');
        this.getField('valor_factura').setValue('');
        this.getField('porcentaje_factura').setValue('');
        this.getField('bodega').setValue('');
        this.getField('total_descuento_p').setValue('');

        this.getField('nit').setError(false,'');
        this.getField('sede').setError(false,'');
        this.getField('nombre').setError(false,'');
        this.getField('detalle').setError(false,'');
        this.getField('numero_factura').setError(false,'');
        this.getField('fecha_factura').setError(false,'');
        this.getField('valor_notap').setError(false,'');
        this.getField('valor_factura').setError(false,'');
        this.getField('porcentaje_factura').setError(false,'');
        this.getField('bodega').setError(false,'');
        this.getField('total_descuento_p').setError(false,'');


        this.array_datos=[];
        this.refrescarNotasP();
   }

   validarModificar(){
        if( this.getField('nuevo_descuento').valid()){
            this.array_datos.forEach((element)=>{
                if(element.codigo===this.registro_aux.codigo){
                    this.array_datos.pop(this.registro_aux);
                    this.registro_aux.valor_descto=this.getField('nuevo_descuento').getValue();
                    this.array_datos.push(this.registro_aux);
                    this.getField('modal_modificar_descuento').handleClose();
                    this.refrescarNotasP();
                    return;
                }
            }); 
        }
   }

   validarAgregar(){
       if( this.getField('codigo_articulo_n').valid()
       && this.getField('nombre_articulo_n').valid()
       && this.getField('costo_articulo_n').valid()
       && this.getField('valor_del_descuento').valid()
       ){
            //validar si existe
            let existe=false;
            this.array_datos.forEach((element)=>{
                if(element.codigo===this.getField('codigo_articulo_n').getValue())
                    existe=true;
            });
            if(existe){
                this.mostrarMensajeAdicional('Este artículo ya existe en el listado');
            }else{
                let objeto={
                    sucursal:this.lasucursal,
                    codigo:this.getField('codigo_articulo_n').getValue(),
                    cantidad:0,
                    precio_sin_iva:this.getField('costo_articulo_n').getValue(),
                    valor_total:this.getField('costo_articulo_n').getValue(),
                    valor_descto:this.getField('valor_del_descuento').getValue(),
                    nombre:this.getField('nombre_articulo_n').getValue(),
                };
                this.array_datos.push(objeto);
                this.getField('modal_adicionar').handleClose();
                this.refrescarNotasP();

            }
       }
    
   }

   definirCosto(){
        if (this.costo_por_bodega==='S') {
            if (this.costo_promedio_linea==='S') {
                this.getField('variable_costo').setValue('costo_pro_' + this.getField('bodega').getValue());
            } else {
                this.getField('variable_costo').setValue('costo' + this.getField('bodega').getValue());
            }
        } else {
            this.getField('variable_costo').setValue('costo');
        }
   }

   adicionarArticulo(){
        if(this.getField('bodega').valid()){
            this.getField('modal_adicionar').handleClickOpen();
            this.definirCosto();
        }
   }

   consultarFactura(){
        if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('numero_factura').valid()){
            this.array_datos=[];
            this.getField('valor_notap').setValue(0);
            this.getField('porcentaje_factura').setValue(1);
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                nit:this.getField('nit').getValue(),
                sede:this.getField('sede').getValue(),
                numero_factura:this.getField('numero_factura').getValue(),
                sucursal_ingreso:this.sucursal_ingreso
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: '1_factura', operacion_tipo: 'consulta' };
            this.service.send({
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successConsultarFactura,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage:false
            });
        } 
   }

   
   successConsultarFactura(data)
  {
    this.getField('confirmModalCustom').toggle(false);
    if(data.estado_p===200){
        if(data.data.mensaje_mostrar!==undefined)
            this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
        else{
            data.data.datos_nuevos.forEach((element)=>{
                this.sucursal_factura=element.sucursal;
                this.array_datos.push(element);
            });
        }
        this.getField('fecha_factura').setValue(data.data.xfecha);
        this.getField('valor_factura').setValue(data.data.valor_factura);
        this.getField('bodega').setError(false,'');
        this.getField('bodega').setValue(data.data.xbodega);
        this.getField('bt_consultar').setDisabled(true);
    }else if(data.estado_p === 404){

        this.mostrarMensajeAdicional('Factura '+this.getField('numero_factura').getValue()+' no pertenece al proveedor.');
    }else {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
    this.refrescarNotasP();

  }

   refrescarNotasP(){
        this.getField('total_descuento_p').setError(false,'');
        if(this.array_datos.length>0){
            this.gridOptionsNotasP['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsNotasP['rowData'] = this.array_datos;
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('precio_sin_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.precio_sin_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_descto', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_descto) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_total) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('accion', { cellRenderer: this.setButtonBotonesTabla, width: 250, sortable: false, filter: false }); 
            
            this.getField('rejilla_notas_p').toggle(true);
            this.getField('rejilla_notas_p').initData(this.gridOptionsNotasP, configCell);
            let valor_descto=0;
            this.array_datos.forEach((element)=>{
                valor_descto+=element.valor_descto;
            });
            this.getField('total_descuento_p').setValue(valor_descto);
        }else{
            this.getField('rejilla_notas_p').toggle(false);
            this.getField('total_descuento_p').setValue(0);
        }
   }

   recalcular() {
    let descto = '';
    let vr_porcentaje = 0;
    if(this.getField('porcentaje_factura').getValue()!=='')
        this.getField('porcentaje_factura').valid();
    if(this.mod_valor===false){
        descto =    this.getField('porcentaje_factura').getValue();
        vr_porcentaje = descto/100;
        let array_aux=[];

        this.array_datos.forEach((element)=>{
            let objeto=element;
            objeto.valor_descto = objeto.precio_sin_iva*objeto.cantidad*vr_porcentaje
            array_aux.push(objeto);
        });
        this.array_datos=array_aux;
        this.refrescarNotasP();
    }
    this.mod_valor=false;

}


   calcularItems(xvalor){
        let array_aux=[];
        this.array_datos.forEach((element)=>{
            let objeto=element;
            objeto.valor_descto = objeto.cantidad!==0?xvalor:0;
            array_aux.push(objeto);
        });
        this.array_datos=array_aux;
        this.refrescarNotasP();
   }


   diferencia(){
        this.getField('valor_notap').setError(false,'');
        if(this.getField('valor_notap').getValue()!=='' && this.getField('valor_factura').getValue()!==''){
            let xvalor = 0;
            xvalor = this.getField('valor_notap').getValue();
            xvalor = this.getField('valor_notap').getValue()===''?0:xvalor /(this.getField('valor_factura').getValue());
            xvalor = xvalor * 100.0;
            let decimal = (xvalor + "").split(".")[1];//para manejar los decimales
            if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>this.numero_decimales_compras)
            xvalor=Number(xvalor).toFixed(this.numero_decimales_compras);
            }
    
            this.mod_valor=true;
            this.getField('porcentaje_factura').setValue(xvalor);
            if (this.costo_promedio_linea==='S') {
                this.calcularItems(this.getField('valor_notap').getValue());
            } else {
                this.getField('porcentaje_factura').setValue(1);
            }
        }
        
   }

   reiniciarCampos(){
        this.getField('detalle').setValue('');
        this.getField('fecha_factura').setValue('');
        this.getField('valor_notap').setValue('');
        this.getField('valor_factura').setValue('');
        this.getField('porcentaje_factura').setValue('');
        this.getField('bodega').setValue('');
        this.getField('rejilla_notas_p').toggle(false);
        this.gridOptionsNotasP['rowData'] = [];
        this.getField('total_nota_final').setValue(0);
        this.getField('bt_consultar').setDisabled(false);
    }

   mostrarNotasP(){
        this.getField('tipo_opcion').setValue('notasp');
        this.getField('nit').setVisible(true);
        this.getField('sede').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('detalle').setVisible(true);
        this.getField('numero_factura').setVisible(true);
        this.getField('bt_consultar').setVisible(true);
        this.getField('fecha_factura').setVisible(true);
        this.getField('valor_factura').setDisabled(true);
        this.getField('total_descuento_p').setDisabled(true);

        this.getField('label_iva_incluido').setDisabled(true);
        this.getField('label_iva_incluido').setVisible(true);
        this.getField('label_iva_incluido').setValue('Valor nota (Iva incluido)');
        this.getField('valor_notap').setVisible(true);
        this.getField('valor_factura').setVisible(true);
        this.getField('porcentaje_factura').setVisible(true);
        this.getField('bodega').setVisible(true);
        this.getField('bt_agregar_articulo').setVisible(true);
        this.getField('total_descuento_p').setVisible(true);
        this.getField('bt_grabar_notap').setVisible(true);
        this.getField('bt_cancelar_notap').setVisible(true);
        this.getField('label_res_iva').setVisible(false);
        //funciones --> 

        this.getField('sede').setOnChange(()=>{
            this.reiniciarCampos();
            this.getField('label_res_iva').setVisible(false);
            this.getField('sede').setError(false,'');
            if(this.getField('nombre').getValue()!=='' ){
                if(this.getField('responsable_iva').getValue()==='S'){
                this.getField('label_iva_incluido').setValue('Valor nota (Iva incluido)');
                }else{
                    this.getField('label_iva_incluido').setValue('Valor nota (Sin Iva)');
                    this.getField('label_res_iva').setVisible(true);
                }
            }
        });
        this.getField('numero_factura').setOnChange(()=>{this.reiniciarCampos();});
        
        this.getField('valor_notap').setOnChange(this.diferencia);
        this.getField('valor_notap').setOnBlur(()=>{
            if(this.getField('valor_notap').getValue()>this.getField('valor_factura').getValue()){
                this.mostrarMensajeAdicional('El valor del descuento no puede ser mayor al valor de la factura.');
                this.getField('valor_notap').setValue(0);
            }
        });
        this.getField('porcentaje_factura').setOnChange(this.recalcular);
        this.getField('bt_consultar').setClick(()=>{this.consultarFactura();});
        this.getField('bt_agregar_articulo').setClick(()=>{this.adicionarArticulo();});
        this.getField('bt_cancelar_adicion').setClick(()=>{this.getField('modal_adicionar').handleClose();});
        this.getField('bt_aceptar_adicion').setClick(()=>{this.validarAgregar();});

        this.getField('bt_cancelar_descuento').setClick(()=>{this.getField('modal_modificar_descuento').handleClose();});
        this.getField('bt_modificar_descuento').setClick(()=>{this.validarModificar();});

        /*this.getField("codigo_articulo_n").setCustomSuccess(()=>{  
            if(this.getField('nombre_articulo_n').getValue()!=='')
                this.infoArticulo();
        });*/

        
        this.getField("codigo_articulo_aux").setOnChange(()=>{
            if(this.getField('codigo_articulo_aux').getValue()!=='')
                this.infoArticulo();
        });
        this.getField("codigo_articulo_n").setOnChange(()=>{
            this.limpiarCamposArticulo();
        });

        //imprimir
        this.getField('radio_correo').setValue("N");
        this.getField('radio_correo').setOnChange(()=>{this.getField('email').setError(false,'');});

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
            this.getField('ch_pdf').setValueSwitch (false);
            this.getField('ch_pantalla').setValueSwitch (false);
            this.operacion_actual='excel';
            this.habilitarCorreo();
            }
        });
        this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
            this.getField('ch_excel').setValueSwitch (false);
            this.getField('ch_pantalla').setValueSwitch (false);
            this.operacion_actual='pdf';
            this.habilitarCorreo();
            }
        });
        this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
            this.getField('ch_excel').setValueSwitch (false);
            this.getField('ch_pdf').setValueSwitch (false);
            this.operacion_actual='pantalla';
            this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
        });
        this.getField('generar_archivo').setClick(()=>{this.procesar()});

        ///botones finales
        this.getField('bt_cancelar_notap').setClick(()=>{this.limpiarCamposP();});
        this.getField('bt_grabar_notap').setClick(()=>{this.grabarNotaP();});


   }

   //imcompleto--> falta completar se dio proridad a sumatec
   mostrarSimple(){
        this.getField('tipo_opcion').setValue('simple');
        this.getField('nit').setVisible(true);
        this.getField('sede').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('detalle').setVisible(true);
        this.getField('codigo_articulo').setVisible(true);
        this.getField('nombre_articulo').setVisible(true);
        this.getField('costo_articulo').setVisible(true);
        this.getField('valor_descuento_unidad').setVisible(true);
        this.getField('cantidad_nota_simple').setVisible(true);
        this.getField('bt_agregar_nota_simple').setVisible(true);

        //funciones
        this.getField("codigo_articulo").setCustomSuccess(this.infoArticulo);
        this.getField("codigo_articulo").setOnChange(()=>{
            this.limpiarCamposArticulo();
        });

        this.getField('costo_articulo').setOnChange(()=>{
            
        });
        
   }

   grabarNota(){
          let datos={ datos: {
            nit: this.getField('nit').getValue(),
            sede: this.getField('sede').getValue(),
            nombre: this.getField('nombre').getValue(),
            detalle: this.getField('detalle').getValue(),
            sucursal_ingreso:this.sucursal_ingreso,
            tipo_opcion: this.getField('tipo_opcion').getValue(),
            total_nota_final:this.getField('total_nota_final').getValue(),
        }};

        
        if(this.getField('tipo_opcion').getValue()==='nota_acumulable'){
            datos.datos.fecha_inicio=this.getField('fecha_inicio').getValue();
            datos.datos.hora_inicio=this.getField('hora_inicio').getValue();
            datos.datos.minutos_inicio=this.getField('minutos_inicio').getValue();
            datos.datos.fecha_fin=this.getField('fecha_fin').getValue();
            datos.datos.hora_fin=this.getField('hora_fin').getValue();
            datos.datos.minutos_fin=this.getField('minutos_fin').getValue();
        }
        this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: 'grabarnota', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successGrabarNota,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
   }

   limpiarCampos(){
     this.getField('nit').setValue('');
     this.getField('sede').setValue('');
     this.getField('nombre').setValue('');
     this.getField('detalle').setValue('');
   }

   successGrabarNota(data){
        if(data.estado_p === 200){
            this.getField('ch_nota_acumulable').setValueSwitch(false);
            this.getField('ch_ajuste_al_precio').setValueSwitch(false);
            this.getField('ch_nota_sin_descuento').setValueSwitch(false);
             //falta imprimir el txt
            this.limpiarCampos();
        }else {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
   }


   validarGrabarNotasDescuentos(){
    if(this.getField('nit').valid() && this.getField('sede').valid() && this.getField('detalle').valid()){
        if(this.array_datos.size()<=0 || this.getField('total_nota_final').getValue()<=0){
            this.mostrarMensajeAdicional("No hay datos para grabar");
        }else{
            if(this.getField('tipo_opcion').getValue()==='nota_acumulable'){
                if(this.getField('fecha_inicio').valid()
                && this.getField('hora_inicio').valid()
                && this.getField('minutos_inicio').valid()
                && this.getField('fecha_fin').valid()
                && this.getField('hora_fin').valid()
                && this.getField('minutos_fin').valid()){
                    this.grabarNota();
                }
            }else{
                this.grabarNota();
            }
        }
    }
   }

   calcularTotal(){
        let cantidad = this.getField('cantidad_sin_descuento').getValue();
        let valor = this.getField('valor_sin_descuento').getValue();
        this.getField('total_sin_descuento').setValue(cantidad * valor);
   }

   mostrarSinDescuento(){
        this.ocultarNotaAcumubale();
        this.OcultarAjusteAlPrecio();
        this.getField('codigo_articulo').setError(false,'');
        this.getField('nombre_articulo').setError(false,'');
        this.getField('saldo_articulo').setError(false,'');
        this.getField('valor_sin_descuento').setError(false,'');
        this.getField('cantidad_sin_descuento').setError(false,'');
        this.getField('total_sin_descuento').setError(false,'');

        this.getField('tipo_opcion').setValue('sin_descuento');
        this.getField('codigo_articulo').setVisible(true);
        this.getField('nombre_articulo').setVisible(true);
        this.getField('saldo_articulo').setVisible(true);
        this.getField('valor_sin_descuento').setVisible(true);
        this.getField('cantidad_sin_descuento').setVisible(true);
        this.getField('total_sin_descuento').setVisible(true);
        this.getField('bt_agregar_sin_descuento').setVisible(true);

        this.array_datos=[];
        this.refrescarNotaAcumulable();

    }

    ocultarSinDescuento(){
        this.getField('codigo_articulo').setVisible(false);
        this.getField('nombre_articulo').setVisible(false);
        this.getField('saldo_articulo').setVisible(false);
        this.getField('valor_sin_descuento').setVisible(false);
        this.getField('cantidad_sin_descuento').setVisible(false);
        this.getField('total_sin_descuento').setVisible(false);
        this.getField('bt_agregar_sin_descuento').setVisible(false);
    }

   limpiarCamposArticulo(){
    this.getField('nombre_articulo').setError(false,'');
    this.getField('nombre_articulo_n').setError(false,'');
        if(this.getField('tipo_opcion').getValue()==='nota_acumulable'){
            this.getField('nombre_articulo').setValue('');
            this.getField('tarifa_iva').setValue('');
            this.getField('precio_articulo').setValue('');
            this.getField('precio_antes_de_iva').setValue('');
            this.calcularPrecioFinal();
        }else if(this.getField('tipo_opcion').getValue()==='ajuste_precio'){
            this.getField('nombre_articulo').setValue('');
            this.getField('precio_articulo').setValue('');
            this.getField('viva').setValue('');
            this.getField('tarifa_iva').setValue('');
            this.getField('saldo_articulo').setValue('');
            this.getField('costo_articulo').setValue('');
            this.getField('precio_antes_de_iva').setValue('');
            this.getField('label_ajustes_activos').setVisible(false);
            this.getField('rejilla_ajustes_activos').toggle(false);
        }else if(this.getField('tipo_opcion').getValue()==='sin_descuento'){
            this.getField('nombre_articulo').setValue('');
            this.getField('costo_articulo').setValue('');
        }else if(this.getField('tipo_opcion').getValue()==='notasp'){
            this.getField('nombre_articulo_n').setValue('');
            this.getField('costo_articulo_n').setValue('');
            this.getField('valor_del_descuento').setError(false,'');
            this.getField('costo_articulo_n').setError(false,'');
        }
   }

   traerDescuentosActivos(){
        this.mostrarMensajeGenerando();
        let datos={ datos: {
            codigo_articulo: this.getField('codigo_articulo').getValue(),
            sucursal_ingreso:this.lasucursal,
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: '1_descuentosactivos', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDescuentosActivos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    
  }
    
  successTraerDescuentosActivos(data){
      this.getField('confirmModalCustom').toggle(false);
      this.descuento_activo=0;
      if(data.estado_p === 200){ 
        this.array_descuentos=data.data;
        this.array_descuentos.forEach((element)=>{
            this.descuento_activo+=element.valor_descuento;
        });
        this.getField('label_ajustes_activos').setVisible(true);
        

        let configCell = new Map();
        this.gridOptionsDescuentos['rowData'] = this.array_descuentos;
        configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
        this.getField('rejilla_ajustes_activos').toggle(true);
        this.getField('rejilla_ajustes_activos').initData(this.gridOptionsDescuentos, configCell);

      }else if(data.estado_p === 404){
            this.getField('label_ajustes_activos').setVisible(false);
            this.getField('rejilla_ajustes_activos').toggle(false);
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
          this.array_descuentos=[];
      }
      else{
            this.getField('label_ajustes_activos').setVisible(false);
            this.getField('rejilla_ajustes_activos').toggle(false);
          this.array_descuentos=[];
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
      this.calcularPrecioAjuste();
    }

   limpiarNotaAcumulable(){
        this.getField('codigo_articulo').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('costo_articulo').setValue('');
        this.getField('tarifa_iva').setValue('');
        this.getField('precio_antes_de_iva').setValue('');
        this.getField('precio_articulo').setValue('');
        this.getField('saldo_articulo').setValue('');

        this.getField('porcentaje_descuento_unitario').setValue(0);
        this.getField('valor_descuento_unitario').setValue(0);
        this.getField('precio_final').setValue(0);
   }

   limpiarAjustesPrecio(){
        this.getField('codigo_articulo').setValue('');
        this.getField('nombre_articulo').setValue('');
        this.getField('costo_articulo').setValue('');
        this.getField('tarifa_iva').setValue('');
        this.getField('precio_antes_de_iva').setValue('');
        this.getField('precio_articulo').setValue('');
        this.getField('saldo_articulo').setValue('');

        this.getField('cantidad_articulo').setValue(0);
        this.getField('valor_unitario').setValue(0);
        this.getField('precio_final_ajuste').setValue(0);

   }

   agregarAjusteAlPrecio(){
    if(this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid() 
    && this.getField('cantidad_articulo').valid()
    && this.getField('valor_unitario').valid()
    && this.getField('precio_final_ajuste').valid()
    && this.getField('precio_final_ajuste_precio').valid()){
        if (this.getField('total_descuento').getValue() > this.getField('costo_articulo').getValue()) {
            this.mostrarMensajeAdicional("El descuento es mayor que el costo del artículo.")
            return false;
        }
        if (this.getField('valor_ajuste') <= 0.0) {
            this.mostrarMensajeAdicional("Descuento inválido.");
            return false;
        }
        if (this.getField('saldo_articulo').getValue() <= 0) {
            this.mostrarMensajeAdicional("No hay saldo para generar la nota.");
            return false;
        }
        this.validarNotaAcumulable();
    }
}

   calcularPrecioAjuste(){
        let costo = 0;
        let precio = 0;
        let renta = 0;
        let descuento = 0;
        let iva = 0;

        costo = this.getField('costo_articulo').getValue();
        precio = this.getField('precio_antes_de_iva').getValue();
        precio = precio - this.descuento_activo;
        descuento = this.getField('valor_unitario').getValue();
        iva = this.getField('tarifa_iva').getValue();
        renta = 100 * ((precio - costo) / costo);
        costo = costo - descuento;
        precio = costo * (renta / 100) + costo;
        precio = precio * (1 + (iva / 100));
        this.getField('precio_final_ajuste').setValue(precio);
        this.getField('valor_nota').setValue(descuento);
        this.calcularDescuentoAjuste();
   }

   infoArticulo(){
    this.mostrarMensajeGenerando();
    let op='';
    
    let datos={ datos: {
      codigo_articulo: this.getField('codigo_articulo').getValue(),
      tipo_opcion:this.getField('tipo_opcion').getValue(),
      variable_costo:this.getField('variable_costo').getValue(),
      sucursal_ingreso:this.sucursal_ingreso,
    }};
    if(this.getField('tipo_opcion').getValue()==='notasp' || this.getField('tipo_opcion').getValue()==='simple' ){
        op='codigoauxiliar';
        datos.datos.codigo_articulo=this.getField('codigo_articulo_n').getValue();
    }else{
        op='infoarticulo';
    }
    this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: op, operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successInfoArticulo,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
  }
    
  successInfoArticulo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
        if(this.getField('tipo_opcion').getValue()==='nota_acumulable'){
            this.getField('codigo_articulo').setValue(data.data[0].codigo);
            this.getField('nombre_articulo').setValue(data.data[0].nombre);
            this.getField('tarifa_iva').setValue(data.data[0].vivac);
            this.getField('precio_articulo').setValue(data.data[0].precio1);
            this.getField('precio_antes_de_iva').setValue(data.data[0].precio1 / (1.0 + (data.data[0].viva / 100.0)));
            this.calcularPrecioFinal();
        }else if(this.getField('tipo_opcion').getValue()==='ajuste_precio'){
            this.getField('codigo_articulo').setValue(data.data[0].codigo);
            this.getField('nombre_articulo').setValue(data.data[0].nombre);
            this.getField('precio_articulo').setValue(data.data[0].precio1);
            this.getField('viva').setValue(data.data[0].viva);
            this.getField('tarifa_iva').setValue(data.data[0].vivac);
            this.getField('saldo_articulo').setValue(data.data[0].saldo);
            this.getField('costo_articulo').setValue(data.data[0].costo);
            this.getField('precio_antes_de_iva').setValue(data.data[0].precio1 / (1.0 + (data.data[0].viva / 100.0)));

            this.getField('valor_unitario').setValue(0);
            this.getField('precio_final_ajuste').setValue(0);
            this.getField('porcentaje_ajuste').setValue(0);
            this.getField('valor_ajuste').setValue(0);
            this.getField('cantidad_articulo').setValue(data.data[0].saldo);

            this.traerDescuentosActivos();
        }else if(this.getField('tipo_opcion').getValue()==='sin_descuento'){
            this.getField('codigo_articulo').setValue(data.data[0].codigo);
            this.getField('nombre_articulo').setValue(data.data[0].nombre);
            this.getField('saldo_articulo').setValue(data.data[0].saldo);
            this.getField('cantidad_sin_descuento').setValue(data.data[0].saldo);
        }else if(this.getField('tipo_opcion').getValue()==='simple'){
            this.getField('codigo_articulo').setValue(data.data[0].codigo);
            this.getField('nombre_articulo').setValue(data.data[0].nombre);
            this.getField('costo_articulo').setValue(data.data[0].costo);
        }else if(this.getField('tipo_opcion').getValue()==='notasp'){
            this.getField('codigo_articulo_n').setValue(data.data.codigo);
            this.getField('nombre_articulo_n').setValue(data.data.nombre);
            let costo=data.data.costo;
            let decimal = (costo + "").split(".")[1];//para manejar los decimales
            if ((decimal !== 0) && (decimal !== undefined)) {
            if(decimal.length>this.numero_decimales_compras)
            costo=Number(costo).toFixed(this.numero_decimales_compras);
            }
            this.getField('costo_articulo_n').setValue(costo);
            this.getField('valor_del_descuento').setValue(0);
        }
      }else if(data.estado_p === 404){
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos sobre el articulo.', 'error');
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
    }


    calcularPorcentajeDescuentoAjuste(){
        let valor_descuento = 0;
        let precio_sin_iva = 0;
        let xiva = 0;
        let xprecio = 0;
        let descuento = 0;

        xiva = this.getField('tarifa_iva').getValue();
        xprecio = this.getField('precio_articulo').getValue();
        valor_descuento = this.getField('valor_ajuste').getValue();
        precio_sin_iva = xprecio / (1.0 + (xiva / 100.0));
        descuento = 100 * (valor_descuento / precio_sin_iva);
        this.getField('porcentaje_ajuste').setValue(descuento);
    }

   calcularDescuentoAjuste(){
        let precio_final = 0;
        let precio_inicial = 0;
        let valor_descuento_total = 0;
        let valor_descuento_tabla = 0;
        let valor_descuento_nota = 0;
        let cantidad = 0;

        cantidad = this.getField('cantidad_articulo').getValue();
        valor_descuento_nota = this.getField('valor_unitario').getValue();
        let tarifa_iva=this.getField('tarifa_iva').getValue();
        let preciofinal1=this.getField('precio_final_ajuste').getValue();
        precio_final = preciofinal1 / (1.0 + (tarifa_iva / 100.0));

        precio_inicial = this.getField('precio_antes_de_iva').getValue();
        valor_descuento_total = precio_inicial - precio_final;

        this.array_descuentos.forEach((element)=>{            
            valor_descuento_tabla += element.valor;
        });
        this.getField('total_descuento').setValue(valor_descuento_total);
        //valor_descuento = valor_descuento - valor_descuento_antes;
        this.getField('valor_ajuste').setValue(valor_descuento_total - valor_descuento_tabla);
        this.calcularPorcentajeDescuentoAjuste();
        this.getField('precio_final_ajuste_precio').setValue(this.getField('precio_final_ajuste').getValue());
        this.getField('total_nota').setValue(cantidad * valor_descuento_nota);
   }

    mostrarAjusteAlPrecio(){
    this.ocultarSinDescuento();
    this.ocultarNotaAcumubale();
    this.getField('bt_agregar_grupo').setError(false,'');
    this.getField('codigo_articulo').setError(false,'');
    this.getField('nombre_articulo').setError(false,'');
    this.getField('costo_articulo').setError(false,'');
    this.getField('tarifa_iva').setError(false,'');
    this.getField('precio_antes_de_iva').setError(false,'');
    this.getField('precio_articulo').setError(false,'');

    this.getField('saldo_articulo').setError(false,'');
    this.getField('cantidad_articulo').setError(false,'');
    this.getField('valor_unitario').setError(false,'');
    this.getField('precio_final_ajuste').setError(false,'');
    this.getField('porcentaje_ajuste').setError(false,'');
    this.getField('total_descuento').setError(false,'');
    this.getField('precio_final_ajuste_precio').setError(false,'');
    this.getField('cantidad_nota').setError(false,'');
    this.getField('valor_nota').setError(false,'');
    this.getField('total_nota').setError(false,'');


    this.getField('tipo_opcion').setValue('ajuste_precio');
    this.getField('rejilla_notas_acumuladas').toggle(false);
    this.getField('bt_agregar_grupo').setVisible(true);
    this.getField('codigo_articulo').setVisible(true);
    this.getField('nombre_articulo').setVisible(true);
    this.getField('costo_articulo').setVisible(true);
    this.getField('tarifa_iva').setVisible(true);
    this.getField('precio_antes_de_iva').setVisible(true);
    this.getField('precio_articulo').setVisible(true);

    this.getField('saldo_articulo').setVisible(true);
    this.getField('cantidad_articulo').setVisible(true);
    this.getField('valor_unitario').setVisible(true);
    this.getField('precio_final_ajuste').setVisible(true);
    this.getField('label_ajuste_al_precio').setVisible(true);
    this.getField('porcentaje_ajuste').setVisible(true);
    this.getField('valor_ajuste').setVisible(true);
    this.getField('label_ajustes_activos').setVisible(false);
    this.getField('rejilla_ajustes_activos').toggle(false);
    this.getField('label_precio_final_ajustes').setVisible(true);
    this.getField('total_descuento').setVisible(true);
    this.getField('precio_final_ajuste_precio').setVisible(true);
    this.getField('label_valor_nota').setVisible(true);
    this.getField('cantidad_nota').setVisible(true);
    this.getField('valor_nota').setVisible(true);
    this.getField('total_nota').setVisible(true);
    this.getField('bt_agregar_ajuste').setVisible(true);
    this.array_datos=[];
    this.refrescarNotaAcumulable();

    }

    OcultarAjusteAlPrecio(){
        this.getField('rejilla_notas_acumuladas').toggle(false);
        this.getField('bt_agregar_grupo').setVisible(false);
        this.getField('codigo_articulo').setVisible(false);
        this.getField('nombre_articulo').setVisible(false);
        this.getField('costo_articulo').setVisible(false);
        this.getField('tarifa_iva').setVisible(false);
        this.getField('precio_antes_de_iva').setVisible(false);
        this.getField('precio_articulo').setVisible(false);

        this.getField('saldo_articulo').setVisible(false);
        this.getField('cantidad_articulo').setVisible(false);
        this.getField('valor_unitario').setVisible(false);
        this.getField('precio_final_ajuste').setVisible(false);
        this.getField('label_ajuste_al_precio').setVisible(false);
        this.getField('porcentaje_ajuste').setVisible(false);
        this.getField('valor_ajuste').setVisible(false);
        this.getField('label_ajustes_activos').setVisible(false);
        this.getField('rejilla_ajustes_activos').toggle(false);
        this.getField('label_precio_final_ajustes').setVisible(false);
        this.getField('total_descuento').setVisible(false);
        this.getField('precio_final_ajuste_precio').setVisible(false);
        this.getField('label_valor_nota').setVisible(false);
        this.getField('cantidad_nota').setVisible(false);
        this.getField('valor_nota').setVisible(false);
        this.getField('total_nota').setVisible(false);
        this.getField('bt_agregar_ajuste').setVisible(false);

    }

   grabarGrupo(){
    if(this.getField('descuento_grupo').valid()){
        if((this.getField('codigo_grupo').getValue()!=='' && this.getField('nombre_grupo').getValue()!=='' ) ||
        (this.getField('codigo_subgrupo').getValue()!=='' && this.getField('nombre_subgrupo').getValue()!=='' )){
        
            this.mostrarMensajeGuardando();
            let datos={ datos: {
                descuento_grupo:this.getField('descuento_grupo').getValue(),
            }};
            let op='';
            if(this.getField('codigo_grupo').getValue()!=='' && this.getField('nombre_grupo').getValue()!==''){
                datos.datos.codigo_grupo=this.getField('codigo_grupo').getValue();
                op='1_grupo'
            }else if(this.getField('codigo_subgrupo').getValue()!=='' && this.getField('nombre_subgrupo').getValue()!==''){
                datos.datos.codigo_subgrupo=this.getField('codigo_subgrupo').getValue();
                op='1_subgrupo'
            }
            this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: op, operacion_tipo: 'consultar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessGrabarGrupo,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un grupo o subgrupo.', 'error');
        }
    }
   }

   sucessGrabarGrupo(data) {
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.getField('modal_grabar_grupo').handleClose();
            this.array_datos=data.data;
            this.refrescarNotaAcumulable();
        }else {
        if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
        }
    }

   notasProveedores(){
        this.traerPropiedades();
   }

   traerPropiedades(){
    let datos={ datos: {
      sucursal_ingreso:this.sucursal_ingreso
    }};
    this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send({
        endpoint: this.constant.formConfiguration(),
        method:'GET',
        body: datos,
        success: this.sucessTraerPropiedades,
        error: this.error_,
        general: this.generalFormatPmv,
        showMessage:false
    });
  }

    sucessTraerPropiedades(data){
        if (data.estado_p === 200 )
        {
            this.notas=data.data.notas;
            this.mostrar_pantalla=data.data.mostrar_pantalla;
            this.notas_simples=data.data.notas_simples;
            this.lasucursal=data.data.lasucursal;
            this.costo_promedio_linea=data.data.costo_promedio_linea;
            this.costo_por_bodega=data.data.costo_por_bodega;
            this.numero_decimales_compras=data.data.numero_decimales_compras;
            //decidir que campos mostrar
            
            //se decidio que se iba a dar prioridad a las pantallas de sumatec
            //por tanto notas descuentos y notas simple estan imcompletas
            if(this.notas==='descuento'){
                //notas descuento falta el xml al momento de imprimir
                this.mostrarNotas();
            }else if(this.mostrar_pantalla==='E' || this.notas_simples==='S'){
                this.mostrarSimple();
            }else{
                //notas p
                this.mostrarNotasP();
            }
        }
        else 
        { 
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    mostrarNotas(){
        this.getField('bt_grabar').setDisabled(true);//ya que falta el archivo xml al grabar
        this.getField('nit').setVisible(true);
        this.getField('sede').setVisible(true);
        this.getField('nombre').setVisible(true);
        this.getField('detalle').setVisible(true);
        this.getField('label_opciones').setVisible(true);
        this.getField('ch_nota_acumulable').setVisible(true);
        this.getField('ch_ajuste_al_precio').setVisible(true);
        this.getField('ch_nota_sin_descuento').setVisible(true);
        this.getField('total_nota_final').setVisible(true);
        this.getField('bt_grabar').setVisible(true);

        this.getField('ch_nota_acumulable').setOnChange((props)=>{
            if(this.getField('ch_nota_acumulable').getValue()==='false'){
              this.getField('ch_ajuste_al_precio').setValueSwitch (false);
              this.getField('ch_nota_sin_descuento').setValueSwitch (false);
              this.mostrarNotaAcumuable();
            }else{
                this.ocultarNotaAcumubale();
            }
          });
          this.getField('ch_ajuste_al_precio').setOnChange((props)=>{
            if(this.getField('ch_ajuste_al_precio').getValue()==='false'){
                this.getField('ch_nota_acumulable').setValueSwitch (false);
                this.getField('ch_nota_sin_descuento').setValueSwitch (false);
                this.mostrarAjusteAlPrecio();
            }else{
                this.OcultarAjusteAlPrecio();
            }
          });
          this.getField('ch_nota_sin_descuento').setOnChange((props)=>{
            if(this.getField('ch_nota_sin_descuento').getValue()==='false'){
                this.getField('ch_nota_acumulable').setValueSwitch (false);
                this.getField('ch_ajuste_al_precio').setValueSwitch (false);
                this.mostrarSinDescuento();
            }else{
                this.ocultarSinDescuento();
            }
          });
        //funcionalidades
        this.getField('bt_agregar_grupo').setClick(()=>{this.getField('modal_grabar_grupo').handleClickOpen()});
        this.getField('bt_grabar_grupo').setClick(()=>{this.grabarGrupo();});
        this.getField("codigo_subgrupo").setOnChange(()=>{
            this.getField("nombre_subgrupo").setError(false,'')
            if(this.getField('codigo_grupo').getValue()!==''){
                this.getField("codigo_grupo").setValue('');
                this.getField("nombre_grupo").setValue('');
            }
        });
        this.getField("codigo_grupo").setOnChange(()=>{
            this.getField("nombre_grupo").setError(false,'')
            if(this.getField('codigo_grupo').getValue()!==''){
                this.getField("codigo_subgrupo").setValue('');
                this.getField("nombre_subgrupo").setValue('');
            }
        });

        this.getField("codigo_articulo").setCustomSuccess(()=>{
             this.infoArticulo();
        });
        this.getField("codigo_articulo").setOnChange(()=>{
            this.limpiarCamposArticulo();

        });

        this.getField('costo_articulo').setOnChange(()=>{
            this.getField('precio_antes_de_iva').setValue(this.getField('precio_articulo').getValue()/ (1.0 + (this.getField('viva').getValue() / 100.0)));
            if(this.getField('tipo_opcion').getValue()==='nota_acumulable')
                this.calularValorDescuento();
            else if(this.getField('tipo_opcion').getValue()==='ajuste_precio'){
                this.calcularDescuentoAjuste();
            }else if(this.getField('tipo_opcion').getValue()==='sin_descuento'){
                this.calcularTotal();
            }
        });
        //ajuste de precio
        this.getField('cantidad_articulo').setOnChange(()=>{
            this.getField('cantidad_nota').setValue(this.getField('cantidad_articulo').getValue());
            this.calcularPrecioAjuste();
        });
        this.getField('valor_unitario').setOnChange(()=>{this.calcularPrecioAjuste();});
        this.getField('precio_final_ajuste').setOnChange(()=>{this.calcularDescuentoAjuste();});

        //sin descuento
        this.getField('cantidad_sin_descuento').setOnChange(()=>{this.calcularTotal();
        });
        this.getField('valor_sin_descuento').setOnChange(()=>{this.calcularTotal();
        });

        this.getField('porcentaje_descuento_unitario').setOnChange(this.calularValorDescuento);
        this.getField('valor_descuento_unitario').setOnChange(this.calcularPorcentajeDescuento);
        this.getField('bt_agregar_nota_acumulable').setClick(this.agregarNotaAcumulable);
        this.getField('bt_agregar_ajuste').setClick(this.agregarAjusteAlPrecio);

        //boton grabar
        this.getField('bt_grabar').setClick(this.validarGrabarNotasDescuentos);
    }



    agregarNotaAcumulable(){
        if(this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid() 
        && this.getField('porcentaje_descuento_unitario').valid()
        && this.getField('valor_descuento_unitario').valid()
        && this.getField('precio_final').valid()){
            this.validarNotaAcumulable();
        }
    }

    validarNotaAcumulable(){
        this.mostrarMensajeValidandoInformacion();
        let datos={ datos: {
            sucursal_ingreso: this.sucursal_ingreso,
            codigo_articulo :this.getField('codigo_articulo').getValue()
          }};
          this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: 'validarnotaacumulable', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successValidarNotaAcumulable,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
    }

    successValidarNotaAcumulable(data)
    {
    this.getField('confirmModalCustom').toggle(false);
      if (data.estado_p === 200) {
        if(data.data.mensaje_mostrar!==undefined)
            this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
        else{
            this.array_datos.forEach((elemento)=>{
                if(elemento.codigo===this.getField('codigo_articulo').getValue())
                    this.array_datos.pop(elemento);
            });
            let objeto={};
            if(this.getField('tipo_opcion').getValue()==='nota_acumulable'){
                objeto={
                    codigo: this.getField('codigo_articulo').getValue(),
                    nombre: this.getField('nombre_articulo').getValue(),
                    cantidad: 0,
                    precio: this.getField('precio_articulo').getValue(),
                    porcentaje: this.getField('porcentaje_descuento_unitario').getValue(),
                    valor_descuento: this.getField('valor_descuento_unitario').getValue(),
                    valor_nota: 0,
                    precio_final: 0,
                };
                this.limpiarNotaAcumulable();
            }else if(this.getField('tipo_opcion').getValue()==='ajuste_precio'){
                objeto={
                    codigo: this.getField('codigo_articulo').getValue(),
                    nombre: this.getField('nombre_articulo').getValue(),
                    cantidad: this.getField('cantidad_articulo').getValue(),
                    precio: this.getField('precio_articulo').getValue(),
                    porcentaje: this.getField('porcentaje_ajuste').getValue(),
                    valor_descuento: this.getField('valor_ajuste').getValue(),
                    valor_nota: this.getField('valor_unitario').getValue(),
                    precio_final: this.getField('precio_final_ajuste').getValue(),
                };
                this.limpiarAjustesPrecio();
            }
            this.array_datos.push(objeto);
            this.refrescarNotaAcumulable();
        }
      }else if(data.estado_p === 404){
          this.alertGeneral.toggle(true, 'No hay datos.', 'error');
      }else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
     }
    }

    calularValorDescuento(){
        this.getField('porcentaje_descuento_unitario').setError(false,'');
        let xiva = 0;
        let xprecio = 0;
        let descuento = 0;
        xiva = this.getField('tarifa_iva').getValue();
        xprecio = this.getField('precio_articulo').getValue();
        descuento = this.getField('porcentaje_descuento_unitario').getValue();

        let precio_sin_iva = xprecio / (1.0 + (xiva / 100.0));

        let valor_descuento = precio_sin_iva * (descuento / 100.0);
        let decimal = (valor_descuento + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
          if(decimal.length>this.numero_decimales_compras)
          valor_descuento=Number(valor_descuento).toFixed(this.numero_decimales_compras);
        }
        this.getField('valor_descuento_unitario').setValue(valor_descuento);
        this.calcularPrecioFinal();
    }

    calcularPorcentajeDescuento(){
        this.getField('valor_descuento_unitario').setError(false,'');
        let valor_descuento = 0;
        let precio_sin_iva = 0;
        let xiva = 0;
        let xprecio = 0;
        let descuento = 0;

        xiva =this.getField('tarifa_iva').getValue();
        xprecio = this.getField('precio_articulo').getValue();
        valor_descuento = this.getField('valor_descuento_unitario').getValue();
        precio_sin_iva = xprecio / (1.0 + (xiva / 100.0));
        descuento = 100 * (valor_descuento / precio_sin_iva);

        let decimal = (descuento + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
          if(decimal.length>this.numero_decimales_compras)
          descuento=Number(descuento).toFixed(this.numero_decimales_compras);
        }
        this.getField('porcentaje_descuento_unitario').setValue(descuento)
        this.calcularPrecioFinal();
    }

    calcularPrecioFinal(){
        let xiva = 0;
        let xprecio = 0;
        let descuento = 0;
        xiva = this.getField('tarifa_iva').getValue();
        xprecio = this.getField('precio_articulo').getValue();
        descuento = this.getField('valor_descuento_unitario').getValue();

        let precio_sin_iva = xprecio / (1.0 + (xiva / 100.0));
        let valor_descuento = precio_sin_iva * (descuento / 100.0);
        precio_sin_iva = precio_sin_iva - valor_descuento;
        let valor_final=precio_sin_iva * (1 + (xiva / 100));
        let decimal = (valor_final + "").split(".")[1];//para manejar los decimales

        if ((decimal !== 0) && (decimal !== undefined)) {
          if(decimal.length>this.numero_decimales_compras)
          valor_final=Number(valor_final).toFixed(this.numero_decimales_compras);
        }
        this.getField('precio_final').setValue(valor_final);
    }

    mostrarNotaAcumuable(){
        //limpiar errores
        this.ocultarSinDescuento();
        this.OcultarAjusteAlPrecio();
        this.getField('fecha_inicio').setError(false,'');
        this.getField('hora_inicio').setError(false,'');
        this.getField('minutos_inicio').setError(false,'');
        this.getField('fecha_fin').setError(false,'');
        this.getField('hora_fin').setError(false,'');
        this.getField('minutos_fin').setError(false,'');
        this.getField('bt_agregar_grupo').setError(false,'');
        this.getField('codigo_articulo').setError(false,'');
        this.getField('nombre_articulo').setError(false,'');
        this.getField('costo_articulo').setError(false,'');
        this.getField('tarifa_iva').setError(false,'');
        this.getField('precio_antes_de_iva').setError(false,'');
        this.getField('precio_articulo').setError(false,'');
        this.getField('porcentaje_descuento_unitario').setError(false,'');
        this.getField('valor_descuento_unitario').setError(false,'');
        this.getField('precio_final').setError(false,'');


        this.getField('tipo_opcion').setValue('nota_acumulable');
        this.getField('fecha_inicio').setVisible(true);
        this.getField('hora_inicio').setVisible(true);
        this.getField('label_inicio').setVisible(true);
        this.getField('minutos_inicio').setVisible(true);
        this.getField('fecha_fin').setVisible(true);
        this.getField('hora_fin').setVisible(true);
        this.getField('label_fin').setVisible(true);
        this.getField('minutos_fin').setVisible(true);
        this.getField('rejilla_notas_acumuladas').toggle(false);
        this.getField('bt_agregar_grupo').setVisible(true);
        this.getField('codigo_articulo').setVisible(true);
        this.getField('nombre_articulo').setVisible(true);
        this.getField('costo_articulo').setVisible(true);
        this.getField('tarifa_iva').setVisible(true);
        this.getField('precio_antes_de_iva').setVisible(true);
        this.getField('precio_articulo').setVisible(true);
        this.getField('label_descuento_unitario').setVisible(true);
        this.getField('porcentaje_descuento_unitario').setVisible(true);
        this.getField('valor_descuento_unitario').setVisible(true);
        this.getField('precio_final').setVisible(true);
        this.getField('bt_agregar_nota_acumulable').setVisible(true);
        this.array_datos=[];
        this.refrescarNotaAcumulable();

    }

    ocultarNotaAcumubale(){
        this.getField('fecha_inicio').setVisible(false);
        this.getField('hora_inicio').setVisible(false);
        this.getField('label_inicio').setVisible(false);
        this.getField('minutos_inicio').setVisible(false);
        this.getField('fecha_fin').setVisible(false);
        this.getField('hora_fin').setVisible(false);
        this.getField('label_fin').setVisible(false);
        this.getField('minutos_fin').setVisible(false);
        this.getField('rejilla_notas_acumuladas').toggle(false);
        this.getField('bt_agregar_grupo').setVisible(false);
        this.getField('codigo_articulo').setVisible(false);
        this.getField('nombre_articulo').setVisible(false);
        this.getField('costo_articulo').setVisible(false);
        this.getField('tarifa_iva').setVisible(false);
        this.getField('precio_antes_de_iva').setVisible(false);
        this.getField('precio_articulo').setVisible(false);
        this.getField('label_descuento_unitario').setVisible(false);
        this.getField('porcentaje_descuento_unitario').setVisible(false);
        this.getField('valor_descuento_unitario').setVisible(false);
        this.getField('precio_final').setVisible(false);
        this.getField('bt_agregar_nota_acumulable').setVisible(false);
    }


  
    refrescarNotaAcumulable(){
        if(this.array_datos.length>0){
            this.gridOptionsNotasAcumulables['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsNotasAcumulables['rowData'] = this.array_datos;
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('precio', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.precio) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('porcentaje', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.porcentaje) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_nota', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_nota) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('precio_final', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.precio_final) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('accion', { cellRenderer: this.setButtonBotonesTabla, width: 250, sortable: false, filter: false }); 
            
            this.getField('rejilla_notas_acumuladas').toggle(true);
            this.getField('rejilla_notas_acumuladas').initData(this.gridOptionsNotasAcumulables, configCell);
            let total_nota=0;
            this.array_datos.forEach((element)=>{
                total_nota+=element.valor_nota*element.cantidad;
            });
            this.getField('total_nota_final').setValue(total_nota);
        }else{
            this.getField('rejilla_notas_acumuladas').toggle(false);
            this.getField('total_nota_final').setValue(0);
        }


    }





    setButtonBotonesTabla(props){
        let fragment = document.createDocumentFragment();
        let buttonModificar = this.crearBotonTabla(props, `Modificar`);
        let buttonEliminar = this.crearBotonTabla(props, 'Eliminar');
        buttonModificar.setAttribute("class","buttonStyle2");
        buttonEliminar.setAttribute("class","buttonStyle2");
        fragment.appendChild(buttonModificar);
        fragment.appendChild(buttonEliminar);
        return this.createElementJaivana(fragment);
    }
    
    crearBotonTabla(data, boton) {
      let button = document.createElement('input');
      switch (boton) {
          case 'Modificar':
              button.setAttribute("id", `buttonModificar${data}`);
              break;
          case 'Eliminar':
              button.setAttribute("id", `buttonEliminar_${data}`);
              break;
          default:
              break;
      }
      button.onclick = () => {this.accionProcesar(data, boton) };
      button.setAttribute("value", boton);
      button.setAttribute("type", "button");
      return button;
    }
    
    accionProcesar(props, boton) {
      if (boton === 'Eliminar') {
          this.eliminarRegistro(props);
      }else if (boton === 'Modificar'){
            if(this.getField('tipo_opcion').getValue()==='notasp'){
                this.getField('modal_modificar_descuento').handleClickOpen();
                this.registro_aux=props.data;
            }else{
                this.traerItemNotaAcumulable(props.data);
            }
      }
    }

    traerItemNotaAcumulable(data){
        if(this.getField('tipo_opcion').getValue()==='nota_acumulable'){
            this.getField('codigo_articulo').setValue(data.codigo);
            this.getField('porcentaje_descuento_unitario').setValue(data.descuento);
            this.getField('valor_descuento_unitario').setValue(data.valor_descuento);
            this.getField('precio_final').setValue(data.precio_final);
        }else if(this.getField('tipo_opcion').getValue()==='ajuste_precio'){
            this.getField('codigo_articulo').setValue(data.codigo);
            this.getField('cantidad_articulo').setValue(data.cantidad);
            this.getField('valor_unitario').setValue(data.valor_nota);
            this.getField('valor_ajuste').setValue(data.valor_descuento);
            this.getField('precio_final_ajuste').setValue(data.precio_final);

            this.getField('cantidad_nota').setValue(this.getField('cantidad_articulo').getValue());
            this.getField('precio_final_ajuste').setValue(this.getField('precio_final_ajuste').getValue());
        }else if(this.getField('tipo_opcion').getValue()==='sin_descuento'){
            this.getField('codigo_articulo').setValue(data.codigo);
            this.getField('cantidad_sin_descuento').setValue(data.cantidad);
            this.getField('valor_sin_descuento').setValue(data.valor_nota);
            this.getField('total_sin_descuento').setValue(data.precio_final);
        }
        this.infoArticulo();
    }


    eliminarRegistro(props) {
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(()=>this.confirmDelete(props.data));
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
      }
    
      confirmDelete(props) {
        this.getField('confirmModalCustom').toggle(false);
        this.array_datos.pop(props);
        if(this.getField('tipo_opcion').getValue()==='notasp'){
            this.refrescarNotasP();
        }else{
            this.refrescarNotaAcumulable();
        }
       
      }


      



   currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeGuardando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeValidandoInformacion(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }



    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarTxt()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
              xsucursal: this.xsucursal,
              xfecha: this.xfecha,
              xnumero: this.xnumero,
              sucursal_ingreso: this.sucursal_ingreso,
            };
            let op='archivoitemsnotasp';
            this.nombre_archivo='NC'+this.xnumero;

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-notasproveedores","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    successValidar(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            
        }else if(data.estado_p === 404){
            this.alertGeneral.toggle(true, 'No hay datos.', 'error');
        }else{
            this.mostrarMensajeEmailFallo();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    //imprimir

    confirmarAbrirImprimir(){
        if(this.mostrar==='nota'){
            if(this.row_consulta.length>0){
                this.xsucursal=this.row_consulta[0].codigo_sucursal+'';
                this.xfecha=this.row_consulta[0].fecha;
                this.xnumero=this.row_consulta[0].numero+'';
                this.nombre_archivo='NC'+this.xnumero;
                // this.getField("modal_imprimir").setLabel("Orden de Compra No. "+this.consecutivo_grabado);
                this.getField("modal_imprimir").setLabel("Imprimir notas proveedores número: "+this.row_consulta[0].numero);
                this.getField('modal_imprimir').handleClickOpen();
                this.getField('email').setValue('');
                this.getField('email').setError(false,'');
                this.getField('radio_correo').setValue("N");
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pdf').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.getField('email').setDisabled(false);
            }else{
                this.alertGeneral.toggle(true,'Es necesario seleccionar un registro de la tabla.',"error");
            }
        }else{
            //lista
            this.getField("modal_imprimir").setLabel("Imprimir lista notas proveedores");
            this.getField('modal_imprimir').handleClickOpen();
            this.getField('email').setValue('');
            this.getField('email').setError(false,'');
            this.getField('radio_correo').setValue("N");
            this.getField('ch_excel').setValueSwitch(false);
            this.getField('ch_pdf').setValueSwitch(false);
            this.getField('ch_pantalla').setValueSwitch(false);
            this.getField('email').setDisabled(false);
        }
      }


      procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla' ){ // Se comenta la validación "que sea diferente a pantalla" ya que en la tarea "JQJD-1430" solicitaron que "Para la presente modal no se debe solicitar email como obligatorio teniendo en cuenta que los informes que genera no son tan extensos y por ende no afectaría el tamaño máximo permitido por el kafka, únicamente que requiera el correo cuando el usuario seleccione la opción de que desea que el informe le llegue por este medio.".
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.nombre_pdf='orden_compra';
                    this.nombre_excel='orden_compra';
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }
    genearModalGlobales(){
        this.mostrarMensajeGenerando();
        let datos={};
        let op='';
        if(this.mostrar==='nota'){
            datos={ datos: {
                xfecha: this.xfecha,
                xsucursal: this.xsucursal,
                xnumero:    this.xnumero,
                sucursal_ingreso: this.sucursal_ingreso,
                operacion: this.operacion_actual,
                tipo_opcion:this.getField('tipo_opcion').getValue(),
            }};
            op='archivoitems';
        }else{
            //lista
            datos={ datos: {
                sucursal_ingreso: this.sucursal_ingreso,
                consultar_desde:this.getField('consultar_desde').getValue(),
                consultar_hasta:this.getField('consultar_hasta').getValue(),
                operacion: this.operacion_actual,
            }};
            if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
                datos.datos.nit=this.getField('nit_consulta').getValue();
                datos.datos.sede=this.getField('sede_consulta').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero_consulta').getValue()!=='' && this.getField('numero_consulta').getValue()!==' ')
                datos.datos.numero_consulta=this.getField('numero_consulta').getValue();
            op='archivolistadonotasp';
        }
        this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }
  
    sucessTraerDatos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "razon_social": this.lista.razon_social,
                    "nit_xml": this.lista.nit_xml,
                    "numero": this.lista.numero,
                    "fecha_nota": this.lista.fecha_nota,
                    "codigo_sucursal": this.lista.codigo_sucursal,
                    "nit": this.lista.nit,
                    "nproveedor": this.lista.nproveedor,
                    "factura": this.lista.factura,
                    "fechas_consulta": this.lista.fechas_consulta,
                    "fecha_sistema": this.lista.fecha_sistema,

                };
            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            if(this.mostrar==='lista'){
                this.getField('rejilla_items_result').toggle(false);

                this.getField('rejilla_listado_notas').toggle(true);
                this.getField('modal_result').setLabel("Listado de notas proveedores")
                this.getField('modal_result').handleClickOpen();
                this.gridOptionsComponentesLista['rowData'] = [];
                let configCell = new Map();
                this.gridOptionsComponentesLista['rowData'] = data.data.datos_items;
                configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
                configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});
                this.getField('valor_total_result').setVisible(false);
                this.getField('rejilla_listado_notas').initData(this.gridOptionsComponentesLista,configCell);
            }else{
                this.getField('rejilla_listado_notas').toggle(false);

                this.getField('rejilla_items_result').toggle(true);
                this.getField('modal_result').setLabel("NOTA PROVEEDOR")
                this.getField('modal_result').handleClickOpen();
                this.gridOptionsComponentesItems['rowData'] = [];
                let configCell = new Map();
                this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
                configCell.set('descuento', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.descto_articulo) }, cellStyle: {textAlign:"right"},width: 110});
                this.getField('valor_total_result').setVisible(true);
                this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total));
                this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);
            }
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }
  
  
    enviarCorreoTerceros(){
        this.mostrarMensajeGenerando();
        let datos={};
        let op='';

        if(this.mostrar==='nota'){
            datos={ datos: {
                xfecha: this.xfecha,
                xsucursal: this.xsucursal,
                xnumero:    this.xnumero,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                sucursal_ingreso: this.sucursal_ingreso,
                operacion: this.operacion_actual,
                tipo_opcion:this.getField('tipo_opcion').getValue(),
            }};
            op='archivoitems';
        }else{
            //lista
            datos={ datos: {
                sucursal_ingreso: this.sucursal_ingreso,
                consultar_desde:this.getField('consultar_desde').getValue(),
                consultar_hasta:this.getField('consultar_hasta').getValue(),
                operacion: this.operacion_actual,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
            }};
            if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
                datos.datos.nit=this.getField('nit_consulta').getValue();
                datos.datos.sede=this.getField('sede_consulta').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero_consulta').getValue()!=='' && this.getField('numero_consulta').getValue()!==' ')
                datos.datos.numero_consulta=this.getField('numero_consulta').getValue();
            op='archivolistadonotasp';
        }
        this.generalFormatPmv = { tipo_servicio: 'comp-notasproveedores', operacion: op, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    successEnviarCorreo(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
        }else{
            this.mostrarMensajeEmailFallo();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }
  
    mostrarMensajeEmailEnviado(){
      this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }
  
    mostrarMensajeEmailFallo(){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
      this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
    }

    generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= {};
            let op='';
            if(this.mostrar==='nota'){
                datos2= {
                    xfecha: this.xfecha,
                    xsucursal: this.xsucursal,
                    xnumero:    this.xnumero,
                    email: this.getField('email').getValue(),
                    radio_correo: this.getField('radio_correo').getValue(),
                    sucursal_ingreso: this.sucursal_ingreso,
                    operacion: this.operacion_actual,
                    tipo_opcion:this.getField('tipo_opcion').getValue(),
                };
                op='archivoitems';
            }else{
                //lista

                datos2= {
                    sucursal_ingreso: this.sucursal_ingreso,
                    consultar_desde:this.getField('consultar_desde').getValue(),
                    consultar_hasta:this.getField('consultar_hasta').getValue(),
                    email: this.getField('email').getValue(),
                    radio_correo: this.getField('radio_correo').getValue(),
                    operacion: this.operacion_actual,
                };

                if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
                    datos2.nit=this.getField('nit_consulta').getValue();
                    datos2.sede=this.getField('sede_consulta').getValue();
                }
                if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                    datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                if(this.getField('numero_consulta').getValue()!=='' && this.getField('numero_consulta').getValue()!==' ')
                    datos2.numero_consulta=this.getField('numero_consulta').getValue();
                op='archivolistadonotasp';
            }
            
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-notasproveedores","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= {};
            let op='';
            if(this.mostrar==='nota'){
                datos2= {
                    xfecha: this.xfecha,
                    xsucursal: this.xsucursal,
                    xnumero:    this.xnumero,
                    email: this.getField('email').getValue(),
                    radio_correo: this.getField('radio_correo').getValue(),
                    sucursal_ingreso: this.sucursal_ingreso,
                    operacion: this.operacion_actual,
                    tipo_opcion:this.getField('tipo_opcion').getValue(),
                };
                op='archivoitems';
            }else{
                //lista

                datos2= {
                    sucursal_ingreso: this.sucursal_ingreso,
                    consultar_desde:this.getField('consultar_desde').getValue(),
                    consultar_hasta:this.getField('consultar_hasta').getValue(),
                    email: this.getField('email').getValue(),
                    radio_correo: this.getField('radio_correo').getValue(),
                    operacion: this.operacion_actual,
                };

                if(this.getField('nit_consulta').getValue()!=='' && this.getField('nit_consulta').getValue()!==' '){
                    datos2.nit=this.getField('nit_consulta').getValue();
                    datos2.sede=this.getField('sede_consulta').getValue();
                }
                if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                    datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
                if(this.getField('numero_consulta').getValue()!=='' && this.getField('numero_consulta').getValue()!==' ')
                    datos2.numero_consulta=this.getField('numero_consulta').getValue();
                op='archivolistadonotasp';
            }

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-notasproveedores","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    abrirImprimir(){
        this.mostrar='nota';
        this.getField("modal_imprimir").setLabel("Notas proveedores número: "+this.xnumero);
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
  }




}
FormJaivana.addController('comp-notasproveedores',CustomNotasProveedores);
export default CustomNotasProveedores;
