import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomConfigurarDocumentosPredefinidos
 * @author: Santiago Hernández N.
 * @updateBy Sebastian Rios E.
 * @version: jdesk_1.01.0002
 **/
class CustomConfigurarDocumentosPredefinidos extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                                   = this.initForm.bind(this);
        this.abrirtabla                                 = this.abrirtabla.bind(this);
        this.successAbrirTabla                          = this.successAbrirTabla.bind(this);
        this.gridOptionsComponentes                     = Object.assign({},this.gridOptions);
        this.setCrearButtonRejilla                      = this.setCrearButtonRejilla.bind(this); 
        this.DatosEliminar                              = this.DatosEliminar.bind(this);
        this.DatosVerMas                                = this.DatosVerMas.bind(this);
        this.DatosAgregar                               = this.DatosAgregar.bind(this);
        this.successDatosVerMas                         = this.successDatosVerMas.bind(this);
        this.validarAgregar                             = this.validarAgregar.bind(this);
        this.cancelarTab1                               = this.cancelarTab1.bind(this);
        this.agregarFormula                             = this.agregarFormula.bind(this);
        this.cancelarTab2                               = this.cancelarTab2.bind(this);
        this.probarFormula                              = this.probarFormula.bind(this);
        this.limpiar                                    = this.limpiar.bind(this);
        this.successEliminar                            = this.successEliminar.bind(this);
        this.grabar                                     = this.grabar.bind(this);
        this.successcrear                               = this.successcrear.bind(this);
        this.successAbrirModal                          = this.successAbrirModal.bind(this);
        this.formula                                    = '';
        this.nuevoArray                                 = [];
        this.validarFormula                             = this.validarFormula.bind(this);
        this.gridOptionsDianVariable                    = Object.assign({},this.gridOptions);
        this.accionChange                               = this.accionChange.bind(this);
        this.calcularFormula                            = this.calcularFormula.bind(this);

        this.gridOptionsComponentesPredefinidos         = Object.assign({},this.gridOptions);
        this.validarResultado                           = this.validarResultado.bind(this);
        this.buttonDeleteRegistroConfirmar              = this.buttonDeleteRegistroConfirmar.bind(this);
        this.successButtonDeleteRegistroConfirmar       = this.successButtonDeleteRegistroConfirmar.bind(this);
        this.plantillas_electronicas_gastos_id          = 0;
        this.crearBotonesTbVerMas                       = this.crearBotonesTbVerMas.bind(this);
        this.accionBtnTbVerMas                          = this.accionBtnTbVerMas.bind(this);
        this.accionProcesarBtnTbVerMas                  = this.accionProcesarBtnTbVerMas.bind(this);
        this.modificarPEGP                              = this.modificarPEGP.bind(this);
        this.successModificarPEGP                       = this.successModificarPEGP.bind(this);
        this.pegpId                                     = 0;
        this.validarCamposAgregarFormula                = this.validarCamposAgregarFormula.bind(this);
        this.validarCamposModificarFormula              = this.validarCamposModificarFormula.bind(this);
        this.disableCamposAgregar                       = this.disableCamposAgregar.bind(this);
        this.validaFormula                              = '';
        this.validaFormulaBase                          = '';
        this.validaFormulaTarifa                        = '';



        
    }

    initForm() {
        console.log("Formulario CustomConfigurarDocumentosPredefinidos,  @version: jdesk_1.01.0002, @author: Santiago Hernández N, @updateBy: Sebastian Rios E.");
        this.getField('nombre').setOnChange(this.abrirtabla);
        this.getField('agregar').setDisabled(true);
        this.getField('agregar').setClick(this.validarAgregar);
        this.getField('cancelar').setDisabled(true);
        this.getField('cancelar').setClick(this.cancelarTab1);
        this.getField('cancelar1').setClick(this.cancelarTab2);    
        this.getField('agregar1').setClick(this.agregarFormula);
        this.getField('probar').setDisabled(true);
        this.getField('probar').setClick(this.probarFormula);
        this.getField('TabJaivana_87').setClick(this.limpiar);
        this.getField('agregar2').setClick(this.validarCamposAgregarFormula);
        this.getField('formula').setOnChange(this.validarFormula);
        this.getField('calcular').setClick(this.calcularFormula);   
        this.getField('formula2').setOnChange(this.validarResultado) 
        this.getField('modificar').setClick(this.validarCamposModificarFormula);
    }

    
    disableCamposAgregar(valor){
        this.getField('cuenta_contable').setDisabled(valor);
        this.getField('centro_costo_codigo').setDisabled(valor);
        this.getField('nit2').setDisabled(valor);
        this.getField('codigo_dptointerno').setDisabled(valor);
    }
    
    validarCamposModificarFormula(){
        const validarCamposModificar = ()=>{
            if (this.getField('cuenta_contable').valid() && this.getField('nombre_cuenta_contable').valid() &&
            this.getField('centro_costo_codigo').valid() && this.getField('centro_costo_nombre').valid() &&
            this.getField('nit2').valid() && this.getField('sede2').valid() &&
            this.getField('nombre2').valid() && this.getField('codigo_dptointerno').valid() &&
            this.getField('nombre_dptointerno').valid() && this.getField('variable').valid() &&
            this.getField('formula').valid() && this.getField('cuentas_id').valid() &&
            this.getField('centros_costo_id').valid() && this.getField('tercero_id2').valid() && this.getField('departamento_interno_id').valid() && 
            this.getField('formula_basee').getValue() !== ''  && this.getField('formula_basee').getValue() !== '' &&
            this.getField('formula_tarifa').getValue() !== ' '  && this.getField('formula_tarifa').getValue() !== ' '){
                return true;
            }else{
                this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
                return false;
            }
        };

        const validacionFrmTarifaNo = ()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Fórmula Tarifa', `¿Desea modificar la fórmula para el campo "tarifa"?`);
            this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "tarifa".');
                this.getField('formula').setValue(this.getField('formula_tarifa').getValue());
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField('confirmModalCustom').setClickCancelDialog(() => {
                this.validaFormulaTarifa = this.getField('formula_tarifa').getValue();
                this.getField('confirmModalCustom').toggle(false);
                if (this.validaFormula !== '' && this.validaFormulaBase !== '' && this.validaFormulaTarifa!== '' &&
                this.validaFormula !== ' ' && this.validaFormulaBase !== ' ' && this.validaFormulaTarifa!== ' ' &&
                validarCamposModificar()) {
                    this.modificarPEGP();
                    return true;
                }
            });
            this.getField('confirmModalCustom').toggle(true);
        };

        const validacionFrmBaseNo = ()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Fórmula Base', `¿Desea modificar la fórmula para el campo "base"?`);
            this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "base".');
                this.getField('formula').setValue(this.getField('formula_basee').getValue());
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField('confirmModalCustom').setClickCancelDialog(() => {
                this.validaFormulaBase = this.getField('formula_basee').getValue();
                this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "tarifa".');
                this.getField('confirmModalCustom').toggle(false);
                validacionFrmTarifaNo();
            });
            this.getField('confirmModalCustom').toggle(true);
        };

        if (this.validaFormula !== '' && this.validaFormula !== ' ') {
            if (this.validaFormulaBase !== '' && this.validaFormulaBase !== ' ') {
                if (!(this.validaFormulaTarifa !== '' && this.validaFormulaTarifa !== ' ')) {
                    if (validarCamposModificar()){
                        this.disableCamposAgregar(true);
                        this.validaFormulaTarifa = this.getField('formula').getValue();
                    }
                }
            }else if (validarCamposModificar()){
                this.disableCamposAgregar(true);
                this.validaFormulaBase = this.getField('formula').getValue();
                validacionFrmTarifaNo();
            }
        }else if (validarCamposModificar()){
            this.disableCamposAgregar(true);
            this.validaFormula = this.getField('formula').getValue();
            validacionFrmBaseNo();
        }

        if (this.validaFormula !== '' && this.validaFormulaBase !== '' && this.validaFormulaTarifa!== '' &&
        this.validaFormula !== ' ' && this.validaFormulaBase !== ' ' && this.validaFormulaTarifa!== ' ' &&
        validarCamposModificar()) {
            this.modificarPEGP();
        }
    }
    
    modificarPEGP(){
        /* if (this.getField('cuenta_contable').valid() && this.getField('nombre_cuenta_contable').valid() &&
        this.getField('centro_costo_codigo').valid() && this.getField('centro_costo_nombre').valid() &&
        this.getField('nit2').valid() && this.getField('sede2').valid() &&
        this.getField('nombre2').valid() && this.getField('codigo_dptointerno').valid() &&
        this.getField('nombre_dptointerno').valid() && this.getField('variable').valid() &&
        this.getField('formula').valid() && this.getField('cuentas_id').valid() &&
        this.getField('centros_costo_id').valid() && this.getField('tercero_id2').valid() &&
        this.getField('departamento_interno_id').valid()) {
            let datos = {  
                datos: { 
                
                    plantillas_electronicas_gastos_id: this.plantillas_electronicas_gastos_id,
                    nit2: this.getField('nit2').getValue(),
                    sede2: this.getField('sede2').getValue(),
                    tercero_id2: this.getField('tercero_id2').getValue(),
                    variable: this.getField('variable').getValue(),
                    formula: this.getField('formula').getValue(),
                    cuentas_id: this.getField('cuentas_id').getValue(),
                    centros_costo_id: this.getField('centros_costo_id').getValue(),
                    departamento_interno_id: this.getField('departamento_interno_id').getValue(),
                    id: this.pegpId

                }
            }

            this.generalFormatPmv = { tipo_servicio: 'cont-configdocumenpredefinidos', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successModificarPEGP,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
        } */

        let datos = {  
            datos: { 
            
                plantillas_electronicas_gastos_id: this.plantillas_electronicas_gastos_id,
                nit2: this.getField('nit2').getValue(),
                sede2: this.getField('sede2').getValue(),
                tercero_id2: this.getField('tercero_id2').getValue(),
                variable: this.getField('variable').getValue(),
                formula: this.validaFormula,
                formula_basee: this.validaFormulaBase,
                formula_tarifa: this.validaFormulaTarifa,
                cuentas_id: this.getField('cuentas_id').getValue(),
                centros_costo_id: this.getField('centros_costo_id').getValue(),
                departamento_interno_id: this.getField('departamento_interno_id').getValue(),
                id: this.pegpId

            }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-configdocumenpredefinidos', operacion: '6', operacion_tipo: 'modificar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'PUT',
            body: datos,
            success: this.successModificarPEGP,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successModificarPEGP(data){
        // console.log('data successModificarPEGP:\n',data);
        if (data.estado_p === 200 ) {
            this.limpiar();
            this.getField('TabJaivana_87').reInit();
        } else {
            this.alertGeneral.toggle(true, 'Error al crear la plantilla electronica gastos predefinidos.', 'error');
        } 
    }
    
    validarResultado(){
        this.getField('resultado').setValue('')
    }
    calcularFormula(){
        if (this.getField('formula2').getValue().includes('iva')){
            this.alertGeneral.toggle(true, 'Debe darle un valor a la variable.', 'error');
        }else{
            try {
                let formula = this.getField('formula2').getValue();
                this.formula =+  this.formula      
                // this.getField('formula').setValue(this.getField('formula2').getValue());// Se comenta esta línea, ya que en la tarea "JQJD-905" solicitaron que "A la hora de guardar la formula no guardar el valor asignado a la variable si no guardar como tal el nombre de la variable en el campo formula"
                
                // eslint-disable-next-line no-eval
                let resultado = Math.round(eval(formula));
                this.getField('resultado').setValue(resultado);
                this.getField('formula2').setError(false, "");  

                if(isNaN(resultado) || resultado ===Infinity){
                    this.getField('resultado').setValue('No se puede dividir entre cero');
                    this.alertGeneral.toggle(true, 'No se puede dividir entre cero.', 'error');
                }

            } catch (error) {
                this.alertGeneral.toggle(true, 'Error al calcular la fórmula. Verifíque por favor.', 'error');
                this.getField('formula2').setError(true, "* Verifíque la formula por favor");  
            }

        
        }

    }

    validarFormula(){
        if (this.getField('formula').getValue()===''){
            this.formula = '';
            this.getField('probar').setDisabled(true);
        }else{
            this.getField('probar').setDisabled(false);// En la tarea "JQJD-1207" solicitaron que "A la hora de probar la formula habilitar el botón así no hayan puesto ninguna variable ya que muchas veces las van a copiar y probar directamente sin el usuario haber puesto alguna variable".
            this.formula = this.getField('formula').getValue();
        }
    }

    abrirtabla(){
        if (this.getField('nombre').getValue()!==''){

            let datos={ datos: {nit1: this.getField('nit1').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'cont-configdocumenpredefinidos', operacion: 'getcategoriaxnit', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successAbrirTabla,
                error: this.error_,
                general: this.generalFormatPmv 
            });

        }else{
            // this.getField('nit1').setError(true, "* Este campo es requerido");  
            this.getField('rejilla').toggle(false);
            
            this.getField('agregar').setDisabled(true);
            this.getField('cancelar').setDisabled(true);
        }
        this.plantillas_electronicas_gastos_id = 0;
        this.pegpId = 0 ;
        this.getField('rejillapredefinidos').toggle(false);
    }

    successAbrirTabla(data){
        if (data.estado_p === 200 ) {
            // this.establecerPropiedades('6,8','visible','true')
            // this.getField('agregar').setVisible(true);// Se comenta ya que en la tarea "JQJD-1365" se solicitó que el botón "agregar" fuera en la tabla principal del formulario.
            this.getField('cancelar').setVisible(true);

            this.plantillas_electronicas_gastos_id = data.data[0].plantillas_electronicas_gastos_id;

            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data;
            configCell.set('Accion', { cellRenderer: this.setCrearButtonRejilla, field: 'Acción' });
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);

            this.getField('agregar').setDisabled(false);
            this.getField('cancelar').setDisabled(false);
        }else{
            this.alertGeneral.toggle(true, 'Error al consultar la categoria de contabilización.', 'error');
            //this.getField('cancelar').setDisabled(false);
            this.getField('rejilla').toggle(false);


            this.getField('agregar').setDisabled(true);
            this.getField('cancelar').setDisabled(true);

            this.gridOptionsComponentesPredefinidos['rowData'] = [];
            this.getField('rejillapredefinidos').initData(this.gridOptionsComponentesPredefinidos);
            this.getField('rejillapredefinidos').toggle(false);
        }
    }

    setCrearButtonRejilla(props){
        let fragment = document.createDocumentFragment();
        let id = props.data.plantillas_electronicas_gastos_id;
        let button1 = document.createElement("input");
        button1.onclick = () => {
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField('confirmModalCustom').setClickDialog(() => {this.DatosEliminar(props);});
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel(`Cancelar`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        };
        button1.setAttribute("id", 'button_delete_' + id);
        //button1.setAttribute("class", "buttonStyle");
        button1.setAttribute("type", "button");
        //button.classList.add(hover);
        button1.setAttribute("value", "Eliminar");
        button1.setAttribute("class", "buttonStyle2");

        //segundo boton de la rejilla
        let button2 = document.createElement("input");
        button2.onclick = () => this.DatosVerMas(props);
        button2.setAttribute("id", 'button_add_' + id);
        //button2.setAttribute("class", "buttonStyle");
        button2.setAttribute("type", "button");
        //button.classList.add(hover);
        button2.setAttribute("value", "Ver más");
        button2.setAttribute("class", "buttonStyle2");

        //tercer botón de la rejilla
        let button3 = document.createElement("input");
        button3.onclick = () => this.DatosAgregar(props);
        button3.setAttribute("id", 'button_agregar_' + id);
        //button3.setAttribute("class", "buttonStyle");
        button3.setAttribute("type", "button");
        //button.classList.add(hover);
        button3.setAttribute("value", "Agregar");
        button3.setAttribute("class", "buttonStyle2");

        fragment.appendChild(button1);
        fragment.appendChild(button2);
        fragment.appendChild(button3);
        return this.createElementJaivana(fragment);
    }

    DatosAgregar(data){
        // console.log('data DatosAgregar:\n',data);
        if (data !== '' && data !== undefined) {
            this.plantillas_electronicas_gastos_id = data.data.plantillas_electronicas_gastos_id;
        }
        this.validarAgregar();
    }
    
    DatosEliminar(data)  {
        let datos={ datos: {id: data.data.plantillas_electronicas_gastos_id }};
        this.generalFormatPmv = { tipo_servicio:  'cont-configdocumenpredefinidos', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'DELETE',
            body: datos,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successEliminar(data){
        if (data.estado_p === 200 ) {
            this.abrirtabla();
        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar la plantilla electronica gastos.', 'error');
        }
        this.getField('confirmModalCustom').toggle(false);
    }

    DatosVerMas(data)  {        
        if (data !== '' && data !== undefined) {
            this.plantillas_electronicas_gastos_id = data.data.plantillas_electronicas_gastos_id;
        }
        this.gridOptionsComponentesPredefinidos['rowData'] = [];
        this.getField('rejillapredefinidos').initData(this.gridOptionsComponentesPredefinidos);
        this.getField('rejillapredefinidos').toggle(false);
        let datos={datos: {plantillas_electronicas_gastos_id: this.plantillas_electronicas_gastos_id}};
        this.generalFormatPmv = { tipo_servicio:  'cont-configdocumenpredefinidos', operacion: 'getpegpxnit', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successDatosVerMas,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successDatosVerMas(data){
        if (data.estado_p === 200 ) {
            this.getField('rejillapredefinidos').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentesPredefinidos['rowData'] = data.data;
            configCell.set('accion', { cellRenderer: this.crearBotonesTbVerMas, headerName: 'Acción', width: 244, sortable: false, filter: false });// Sortable y filter sirven para quitar los iconos que aparecen en los campos de las tablas de filtrar y organizar por orden alfabético y numérico, las propiedades sortable y filter = a false son las que me ayudan a controlarlo.
            configCell.set('formula', { headerName: 'Fórmula'});
            configCell.set('formula_basee', { headerName: 'Fórmula Base'});
            configCell.set('formula_tarifa', { headerName: 'Fórmula Tarifa'});
            this.getField('rejillapredefinidos').initData(this.gridOptionsComponentesPredefinidos,configCell);


        } else {
            this.alertGeneral.toggle(true, 'Error al cargar la plantilla electronica gastos predefinidos.', 'error');
        }
    }

    crearBotonesTbVerMas(props){
        // console.log('props crearBotonesTbVerMas:\n',props);
        let fragment = document.createDocumentFragment();
        let button = this.accionBtnTbVerMas(props.data, 'Eliminar');
        let buttonIntegrar = this.accionBtnTbVerMas(props.data, 'Modificar');
        button.setAttribute("class","buttonStyle2");
        buttonIntegrar.setAttribute("class","buttonStyle2");
        fragment.appendChild(button);
        fragment.appendChild(buttonIntegrar);
        return this.createElementJaivana(fragment);
    }

    /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser eliminar ó modificar} boton 
      * @returns el botón con su respectiva configuración
      */
    accionBtnTbVerMas(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Eliminar':
                button.setAttribute("id", `boton_eliminar_${data.id}`);
                break;
            case 'Modificar':
                button.setAttribute("id", `boton_modificar_${data.id}`);
                break;

            default:
                this.alertGeneral.toggle(true, 'Este botón no pertenece a ninguna acción.', 'error');
                break;
        }
        button.onclick = () => { this.accionProcesarBtnTbVerMas(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click (Eliminar o Modificar)} boton 
      */
    accionProcesarBtnTbVerMas(data, boton) {
        if (boton === 'Eliminar') {
            this.getField('confirmModalCustom').setTitleAndContent("Eliminar", "¿Desea realizar esta operación?");
            this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setClickDialog(()=>{this.buttonDeleteRegistroConfirmar(data.id);});
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel(`Cancelar`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        } else if (boton === 'Modificar'){
            // console.log('data Modificar:\n',data);
            if (data !== '' && data !== undefined) {
                this.getField('cuenta_contable').setValue(data.codigo_cuenta);
                this.getField('nombre_cuenta_contable').setValue(data.nombre_cuenta);
                this.getField('centro_costo_codigo').setValue(data.codigo_centro_costo);
                this.getField('centro_costo_nombre').setValue(data.nombre_centro_costo);
                this.getField('nit2').setValue(data.nit);
                this.getField('sede2').setValue(data.sede);
                this.getField('nombre2').setValue(data.nombre_tercero);
                this.getField('codigo_dptointerno').setValue(data.codigo_departamento_interno);
                this.getField('nombre_dptointerno').setValue(data.nombre_departamento_interno);
                this.getField('variable').setValue(data.variable);
                this.getField('formula').setValue(data.formula);
                this.getField('formula_basee').setValue(data.formula_basee);
                this.getField('formula_tarifa').setValue(data.formula_tarifa);
                this.getField('cuentas_id').setValue(data.cuentas_id);
                this.getField('centros_costo_id').setValue(data.centros_costo_id);
                this.getField('tercero_id2').setValue(data.tercero_id);
                this.getField('departamento_interno_id').setValue(data.depto_interno_id);
                this.pegpId = data.id;

                this.getField('agregar2').setVisible(false);
                this.getField('modificar').setVisible(true);
                this.disableCamposAgregar(false);
                this.getField('TabJaivana_87').setNextTab();

                this.getField('confirmModalCustom').setTitleAndContent('Fórmula Valor', `¿Desea modificar la fórmula para el campo "valor"?`);
                this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "valor".');
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField('confirmModalCustom').setClickCancelDialog(() => {
                    this.getField('confirmModalCustom').toggle(false);
                    this.validarCamposModificarFormula();
                });
                this.getField('confirmModalCustom').toggle(true);
            }
        }
    }

    buttonDeleteRegistroConfirmar(id){
        // console.log('id buttonDeleteRegistroConfirmar:\n',id);
        this.getField("confirmModalCustom").toggle(false);
        if (id >= 1) {
            let datos={ datos: {id: id }};
            this.generalFormatPmv = { tipo_servicio: 'cont-configdocumenpredefinidos', operacion: '7_1', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successButtonDeleteRegistroConfirmar,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }

    successButtonDeleteRegistroConfirmar(data){
        // console.log('successButtonDeleteRegistroConfirmar',data);
        if (data.estado_p === 200) {
            this.gridOptionsComponentesPredefinidos['rowData'] = [];
            this.getField('rejillapredefinidos').initData(this.gridOptionsComponentesPredefinidos,);
            this.getField('rejillapredefinidos').toggle(false);
            this.DatosVerMas();
        } else {
            this.alertGeneral.toggle(true, 'Error al eliminar la plantillas electronicas gastos predefinidos.', 'error');
        }
    }

    validarAgregar(){
        this.getField('agregar2').setVisible(true);
        this.getField('modificar').setVisible(false);
        this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "valor".');
        this.disableCamposAgregar(false);
        this.getField('TabJaivana_87').setNextTab();
    }

    agregarFormula(){        
        if(this.getField('formula').getValue() === '.'){
            this.getField('formula').setValue("")
        }
        let campoFormula = this.getField('formula').getValue();
        const RegExPattern = /^([a-zA-Z0-9]+(\u002B|\u002D|\u002F|\u002A){1})+$/;
        const formatoFormula = ((campoFormula.match(RegExPattern)) && (campoFormula!=='')) ? true : false;
        // console.log('formatoFormula con expresion:\n',formatoFormula);
        const operador = ()=>{
            if(this.getField('variable').getValue()!==''){
                this.getField('formula').setError(false, "");  
                this.formula =  this.formula + this.getField('variable').getValue();
    
                this.getField('formula').setValue(this.formula);
                this.getField('probar').setDisabled(false);
            }else{
                this.getField('variable').setError(true, "* Este campo es requerido");              
            }
        }
        if (this.getField('formula').getValue() !== '' && formatoFormula === false) {
            this.alertGeneral.toggle(true, 'Debe digitar un operador (+ - / *) para agregar otra variable.', 'error');
        }else if ((this.getField('formula').getValue() !== '' && formatoFormula) || 
        (this.getField('formula').getValue() === '' && formatoFormula === false)){
            operador();
        }
    }

    probarFormula(){
        if(this.getField('formula').getValue()!==''){
            this.getField('formula').setError(false, "");
            
            let datos={ datos: { }};
            this.generalFormatPmv = { tipo_servicio:  'cont-dianvariables', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successAbrirModal,
                error: this.error_,
                general: this.generalFormatPmv
            });
  
        }else{
            this.formula='';
            this.getField('formula').setError(true, "* Este campo es requerido");    
        }
    }

    successAbrirModal(data){
        this.nuevoArray = [];
        this.gridOptionsDianVariable['rowData']=[];

        this.getField('rejilla_dian_variables').initData(this.gridOptionsDianVariable);
        if (data.estado_p === 200 ) {

            this.getField('modal_probar').handleClickOpen();

            if (this.getField('formula2').getValue() === '' || this.getField('formula2').getValue() === ' ') {
                this.getField('formula2').setValue(this.getField('formula').getValue());
            }else if (this.formula !== '' && this.formula !== undefined && this.formula !== null) {// Se implementa esta validación, ya que en la tarea "JQJD-905" solicitaron que "A la hora de guardar la formula no guardar el valor asignado a la variable si no guardar como tal el nombre de la variable en el campo formula" 
                this.getField('formula2').setValue(this.formula);
            }
            
            if(this.getField('formula2').getValue() !==''){
                this.calcularFormula();
            }
            this.getField('formula2').setError(false, "");  
            this.getField('resultado').setError(false, "");  
            data.data.forEach(item => {                
                if (this.getField('formula2').getValue().includes(item.variable)){
                    this.nuevoArray.push(item);
                }
            });
               
            let size = this.nuevoArray.length;

            if (size>0){
                
                this.gridOptionsDianVariable['rowData'] = this.nuevoArray;
                let configCell = new Map();      
                // console.log('Data Jose: ',data);
                // console.log('this.nuevoArray jose: ',this.nuevoArray);
                this.nuevoArray.forEach((item)=>{
                    if (this.getField('formula2').getValue().includes(item.variable)) {
                        configCell.set('valor', { cellRenderer: function (props) { return props.data.valor }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.accionChange, sortable: true, filter: false, field: 'valor' });
                    }else{
                        configCell.set('valor', { cellRenderer: function (props) { return props.data.valor }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: false, onCellValueChanged: this.accionChange, sortable: true, filter: false, field: 'valor' });
                    }
                });
                this.getField('rejilla_dian_variables').toggle(true);
                this.getField('rejilla_dian_variables').initData(this.gridOptionsDianVariable,configCell);
        
            }else{

                this.getField('rejilla_dian_variables').toggle(false);
            
            }

        } else {
            this.alertGeneral.toggle(true, 'Error al traer dian_variables.', 'error');
        } 
    }

    accionChange(props){
        let seguir = this.getField('rejilla_dian_variables').cellValid(props.oldValue, props.newValue, /^[0-9]+$/);

        
        if (seguir) {

            let variable = props.data.variable;
            //let valor_viejo = props.oldValue;
            let valor_actual = props.newValue;            
            let copia_formula = this.getField('formula2').getValue();
            this.formula = copia_formula.replaceAll(variable,valor_actual);
            this.getField('formula2').setValue(this.formula);
            // this.getField('formula').setValue(this.formula);// Se comenta esta línea, ya que en la tarea "JQJD-905" solicitaron que "A la hora de guardar la formula no guardar el valor asignado a la variable si no guardar como tal el nombre de la variable en el campo formula" 
            this.probarFormula();
        
        }
    }

    validarCamposAgregarFormula(){
        const validarCamposAgregar = ()=>{
            if (this.getField('cuenta_contable').valid() && this.getField('centro_costo_codigo').valid() && this.getField('codigo_dptointerno').valid() && this.getField('nit2').valid() && this.getField('variable').valid() && this.getField('formula').valid()){
                return true;
            }else{
                this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
                return false;
            }
        };

        const validacionFrmTarifaNo = ()=>{
            this.getField('confirmModalCustom').setTitleAndContent('Fórmula Tarifa', `¿Desea definir fórmula para el campo "tarifa"?`);
            this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "tarifa".');
                this.getField('variable').setValue('');
                this.getField('formula').setValue('');
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField('confirmModalCustom').setClickCancelDialog(() => {
                this.validaFormulaTarifa = '.';
                this.getField('confirmModalCustom').toggle(false);
                if (this.validaFormula !== '' && this.validaFormulaBase !== '' && this.validaFormulaTarifa!== '' &&
                this.validaFormula !== ' ' && this.validaFormulaBase !== ' ' && this.validaFormulaTarifa!== ' ' &&
                validarCamposAgregar()) {
                    this.grabar();
                    return true;
                }
            });
            this.getField('confirmModalCustom').toggle(true);
        };

        if (this.validaFormula !== '' && this.validaFormula !== ' ') {
            if (this.validaFormulaBase !== '' && this.validaFormulaBase !== ' ') {
                if (!(this.validaFormulaTarifa !== '' && this.validaFormulaTarifa !== ' ')) {
                    if (validarCamposAgregar()){
                        this.disableCamposAgregar(true);
                        this.validaFormulaTarifa = this.getField('formula').getValue();
                    }
                }
            }else if (validarCamposAgregar()){
                this.disableCamposAgregar(true);
                this.validaFormulaBase = this.getField('formula').getValue();
                validacionFrmTarifaNo();
            }
        }else if (validarCamposAgregar()){
            this.disableCamposAgregar(true);
            this.validaFormula = this.getField('formula').getValue();
            this.getField('confirmModalCustom').setTitleAndContent('Fórmula Base', `¿Desea definir fórmula para el campo "base"?`);
            this.getField("confirmModalCustom").setVisibleCancel(false); // true para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setVisibleConfirm(false);// true para no mostrar el botón de confirmar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('SI');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setButtonCancel(`NO`);// Para renombrar el botón de "cancelar" en los ConfirmDialog – popup
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "base".');
                this.getField('variable').setValue('');
                this.getField('formula').setValue('');
                this.getField('confirmModalCustom').toggle(false);
            });
            this.getField('confirmModalCustom').setClickCancelDialog(() => {
                this.validaFormulaBase = '.';
                this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "tarifa".');
                this.getField('confirmModalCustom').toggle(false);
                validacionFrmTarifaNo();
            });
            this.getField('confirmModalCustom').toggle(true);

        }

        if (this.validaFormula !== '' && this.validaFormulaBase !== '' && this.validaFormulaTarifa!== '' &&
        this.validaFormula !== ' ' && this.validaFormulaBase !== ' ' && this.validaFormulaTarifa!== ' ' &&
        validarCamposAgregar()) {
            this.grabar();
        }
    }
    
    grabar(){
        /* this.getField('formula2').setValue(this.getField('formula').getValue());
        if(this.getField('formula2').getValue() !==''){
            this.calcularFormula();
            this.getField('formula2').setError(false, "");  
        }   */      
        // let campoFormula = this.getField('formula').getValue();
        // const RegExPattern = /^([a-zA-Z0-9]+|[a-zA-Z0-9]+(\u002B|\u002D|\u002F|\u002A){1}[a-zA-Z0-9]+)+$/;
        // const formatoFormula = ((campoFormula.match(RegExPattern)) && (campoFormula!=='')) ? true : false;
        /* if (this.getField('cuenta_contable').valid() && this.getField('centro_costo_codigo').valid() && this.getField('codigo_dptointerno').valid() && this.getField('nit2').valid() && this.getField('variable').valid() && this.getField('formula').valid()){
            // if(formatoFormula){

                let datos = {  
                    datos: { 
                    
                        nit2:this.getField('nit2').getValue(),
                        sede2:this.getField('sede2').getValue(),
                        plantillas_electronicas_gastos_id:this.plantillas_electronicas_gastos_id,
                        cuentas_id:this.getField('cuentas_id').getValue(),
                        centros_costo_id:this.getField('centros_costo_id').getValue(),
                        variable:this.getField('variable').getValue(),
                        formula:this.getField('formula').getValue(),
                        tercero_id2:this.getField('tercero_id2').getValue(),
                        departamento_interno_id:this.getField('departamento_interno_id').getValue(),

                    }
                }

                this.generalFormatPmv = { tipo_servicio: 'cont-configdocumenpredefinidos', operacion: '5', operacion_tipo: 'crear' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successcrear,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
            // }else{
            // //   this.alertGeneral.toggle(true, 'Debe probar la fórmula para continuar.', 'error');
            //   this.alertGeneral.toggle(true, 'Valide que la fórmula tenga un formato correcto.', 'error');
            // }
        }else{
            this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
        } */

        let datos = {  
            datos: { 
            
                nit2:this.getField('nit2').getValue(),
                sede2:this.getField('sede2').getValue(),
                plantillas_electronicas_gastos_id:this.plantillas_electronicas_gastos_id,
                cuentas_id:this.getField('cuentas_id').getValue(),
                centros_costo_id:this.getField('centros_costo_id').getValue(),
                variable:this.getField('variable').getValue(),
                formula:this.validaFormula,
                formula_basee:this.validaFormulaBase,
                formula_tarifa:this.validaFormulaTarifa,
                tercero_id2:this.getField('tercero_id2').getValue(),
                departamento_interno_id:this.getField('departamento_interno_id').getValue(),

            }
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-configdocumenpredefinidos', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successcrear,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successcrear(data){
        if (data.estado_p === 200 ) {
            this.limpiar();
            this.getField('TabJaivana_87').reInit();
        } else {
            this.alertGeneral.toggle(true, 'Error al crear la plantilla electronica gastos predefinidos.', 'error');
        } 
    }

    cancelarTab1(){
        this.getField('nit1').setValue('');
        this.getField('nombre').setValue('');
        this.getField('sede1').setValue('');
        this.getField('nit1').setError(false, "");  
        this.getField('agregar').setVisible(false);
        this.getField('cancelar').setVisible(false);
    }

    cancelarTab2(){
        this.getField('centros_costo_id').setValue('');
        this.getField('cuentas_id').setValue('');
        this.getField('formula').setValue('');
        this.getField('formula_basee').setValue('');
        this.getField('formula_tarifa').setValue('');
        this.getField('variable').setValue('');
        this.getField('centro_costo_nombre').setValue('');
        this.getField('centro_costo_codigo').setValue('');
        this.getField('nombre_cuenta_contable').setValue('');
        this.getField('cuenta_contable').setValue('');
        this.getField('codigo_dptointerno').setValue('');
        this.getField('nombre_dptointerno').setValue('');
        this.getField('departamento_interno_id').setValue('');

        this.getField('cuenta_contable').setError(false, "");  
        this.getField('centro_costo_codigo').setError(false, "");
        this.getField('codigo_dptointerno').setError(false, "");

        this.formula='';
        this.nuevoArray=[];

        this.validaFormula = '';
        this.validaFormulaBase = '';
        this.validaFormulaTarifa = '';
        this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "valor".');
        this.disableCamposAgregar(false);
    }


    limpiar(){
        //this.getField('nit1').setValue('');
        //this.getField('nombre').setValue('');
        //this.getField('sede1').setValue('');
        //this.abrirtabla();
        this.DatosVerMas();        
        //this.getField('agregar').setVisible(false);
        //this.getField('cancelar').setVisible(false);
        this.getField('nit1').setError(false, "");  
        
        this.getField('centros_costo_id').setValue('');
        this.getField('cuentas_id').setValue('');
        this.getField('formula').setValue('');
        this.getField('formula_basee').setValue('');
        this.getField('formula_tarifa').setValue('');
        this.getField('variable').setValue('');
        this.getField('centro_costo_nombre').setValue('');
        this.getField('centro_costo_codigo').setValue('');
        this.getField('nombre_cuenta_contable').setValue('');
        this.getField('cuenta_contable').setValue('');

        this.getField('codigo_dptointerno').setValue('');
        this.getField('nombre_dptointerno').setValue('');
        this.getField('departamento_interno_id').setValue('');

        this.getField('cuenta_contable').setError(false, "");  
        this.getField('centro_costo_codigo').setError(false, "");
        this.getField('codigo_dptointerno').setError(false, "");
        this.formula=''; 
        this.getField('TabJaivana_87').reInit();
        this.getField('formula_actual').setValue('Usted está definiendo la fórmula para el campo "valor".');
        this.nuevoArray=[];
        //this.getField('rejilla').toggle(false);
        this.validaFormula = '';
        this.validaFormulaBase = '';
        this.validaFormulaTarifa = '';
    }

}
FormJaivana.addController("cont-configdocumenpredefinidos", CustomConfigurarDocumentosPredefinidos);
export default CustomConfigurarDocumentosPredefinidos;