import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomModificarFechaDeFactura
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0001
 **/
class CustomModificarFechaDeFactura extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;

    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
    this.mostrarMensajeGuardando                                  = this.mostrarMensajeGuardando.bind(this);
    this.limpiarCampos                                  = this.limpiarCampos.bind(this);
    this.consultarFactura                                  = this.consultarFactura.bind(this);
    this.sucessConsultarFactura                                  = this.sucessConsultarFactura.bind(this);
    this.modificarFactura                                  = this.modificarFactura.bind(this);
    this.sucessModificarFactura                                  = this.sucessModificarFactura.bind(this);



    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.fecha_inicio='';
    this.dias_maximo='';
    this.fila_seleccionada=[];
  }
  
  initForm() {
    console.log("Formulario CustomModificarFechaDeFactura,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    //nuevo para consulta
    this.getField('numero_factura').setOnBlur(()=>{this.consultarFactura();});
    this.getField('sede').setOnChange(()=>{this.consultarFactura();});
    this.getField('numero_factura').setOnChange(()=>{this.getField('fecha_factura').setValue('')});
    this.getField('nit').setOnChange(()=>{this.getField('sede').setError(false,'');this.getField('nombre').setError(false,'');});

    this.getField('bt_cancelar').setClick(()=>{this.limpiarCampos();});
    this.getField('bt_aceptar').setClick(()=>{this.modificarFactura();});

    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('fecha_factura').setValue('');
    this.getField('fecha_factura').setDisabled(true);


  }

  sucessModificarFactura(data) {
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar);
        this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);this.consultarFactura();});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Ok"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }else {
        if(data.estado_p===404) {
          this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else {
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
        }
      }
    }

    modificarFactura(){
      if(this.getField('numero_factura').valid() && 
      this.getField('nit').valid() &&
      this.getField('sede').valid() &&
      this.getField('nombre').valid() &&
      this.getField('fecha_factura').valid() &&
      this.getField('fecha_real_factura').valid()){
        let anio_desde=this.getField('fecha_factura').getValue().split('-')[0];
        let anio_destino=this.getField('fecha_real_factura').getValue().split('-')[0];
          if(anio_desde===anio_destino){
            this.mostrarMensajeGuardando();
            let datos={ datos: {
                nit:this.getField('nit').getValue(),
                sede:this.getField('sede').getValue(),
                nombre:this.getField('nombre').getValue(),
                fecha_factura:this.getField('fecha_factura').getValue(),
                fecha_real_factura:this.getField('fecha_real_factura').getValue(),
                numero_factura:this.getField('numero_factura').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-modificarfechafactura', operacion: 'modificarfecha', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.sucessModificarFactura,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
          }else{
            this.mostrarMensajeAdicional('La fecha real de la factura tiene que estar en el mismo año de la factura.');
          }
      }
    }

  consultarFactura(){
    this.getField('fecha_factura').setValue('');
    if(this.getField('sede').getValue()!=='' && this.getField('sede').getValue()!==' '
    && this.getField('numero_factura').getValue()!=='' && this.getField('numero_factura').getValue()!==' '){
      if(!this.getField('numero_factura').valid())
        return;
        let datos={ datos: {
          nit:this.getField('nit').getValue(),
          sede:this.getField('sede').getValue(),
          numero_factura:this.getField('numero_factura').getValue(),
        }};
        this.generalFormatPmv = { tipo_servicio: 'comp-modificarfechafactura', operacion: '1_factura', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessConsultarFactura,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }else{
        this.getField('fecha_factura').setValue('');
      }
    
    }

    sucessConsultarFactura(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
          this.getField('fecha_factura').setValue(data.data[0].fecha);
          this.getField('fecha_real_factura').setValue(this.fecha_hoy);
        }else if(data.estado_p === 404){
            this.getField('fecha_factura').setValue('');
            this.mostrarMensajeAdicional("No existe factura con la información suministrada.")
        }
        else{
            this.getField('fecha_factura').setValue('');
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    limpiarCampos(){
        this.getField('numero_factura').setValue('');
        this.getField('nit').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre').setValue('');
        this.getField('fecha_factura').setValue('');
        this.getField('fecha_real_factura').setValue(this.fecha_hoy);
        this.getField('numero_factura').setError(false,'');
        this.getField('nit').setError(false,'');
        this.getField('sede').setError(false,'');
        this.getField('nombre').setError(false,'');
        this.getField('fecha_factura').setError(false,'');
        this.getField('fecha_real_factura').setError(false,'');
    }

  traerPropiedades(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'comp-modificarfechafactura', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
    }

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }


  onSelectionChanged(){

  }

    //generales---------->

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);
    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }


    mostrarMensajeGuardando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
   
}


FormJaivana.addController("comp-modificarfechafactura", CustomModificarFechaDeFactura);
export default CustomModificarFechaDeFactura;