import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Patricia Lopez Sanchez
 * 
 * @version pmv_1.0.0001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomConceptosPago extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.validarmanejamovimiento    = this.validarmanejamovimiento.bind(this); 
        this.validarexiste              = this.validarexiste.bind(this); 
        this.successValidar             = this.successValidar.bind(this);
        this.guardar                    = this.guardar.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
        this.successGrabar              = this.successGrabar.bind(this);
        this.mostrardatos               = this.mostrardatos.bind(this);
        this.successMostrar             = this.successMostrar.bind(this);
        this.setButtonEliminar          = this.setButtonEliminar.bind(this);
        this.Eliminar                   = this.Eliminar.bind(this);
        this.ConfirmDelete              = this.ConfirmDelete.bind(this);
        this.successEliminar            = this.successEliminar.bind(this);
        this.datosAgregar               = { datos:{}};
        this.gridOptionsComponentes     = Object.assign({},this.gridOptions);
    }

    initForm(){
      console.log('Formulario CustomConceptosPago,  @version: jdesk_10.0.001, @author:Patricia López Sánchez')
    
            this.arrayCampos.set(1,'conceptospago_id');
            this.arrayCampos.set(2,'codigo');
            this.arrayCampos.set(3,'nombre_cuenta');
            this.arrayCampos.set(4,'agregar');
            this.arrayCampos.set(5,'estadoactivo_id');
            this.arrayCampos.set(6,'cuenta_id');
            this.arrayCampos.set(7,'rejilla2');
            this.arrayCampos.set(8,'movimiento');
     
            this.arrayObjetos.set(1,'conceptospago_id');
            this.arrayObjetos.set(2,'codigo');
            this.arrayObjetos.set(3,'nombre_cuenta');
            this.arrayObjetos.set(4,'agregar');
            this.arrayObjetos.set(5,'estadoactivo_id');
            this.arrayObjetos.set(6,'cuenta_id');
            this.arrayObjetos.set(7,'rejilla2');
            this.arrayObjetos.set(8,'movimiento');

            this.getField('nombre_cuenta').setDisabled(true);
            this.getField('codigo_cuenta').setOnChange(()=>{this.getField('codigo_cuenta').setError(false,'');this.getField('nombre_cuenta').setError(false,'');this.getField('estadoactivo_id').setValue('');});
            this.getField('movimiento').setOnChange(this.validarmanejamovimiento);
            this.getField('agregar').setClick(this.validarexiste);
            this.mostrardatos();
    } 

    mostrardatos(){
     let datos = { datos: {}};
     this.generalFormatPmv = { tipo_servicio: 'maes-conceptospago', operacion: '1_1', operacion_tipo: 'consulta' };
       this.service.send(
       {
               endpoint: this.constant.formConfiguration(),
               method:'GET',
               body: datos,
               success: this.successMostrar,
               error: this.error_,
               general: this.generalFormatPmv
       });
    }

    successMostrar(data){
        if(data.estado_p === 200){ 
          this.getField('rejilla2').toggle(true);
          let configCell = new Map();
          this.gridOptionsComponentes['rowData'] = data.data;
          configCell.set('Eliminar', { cellRenderer: this.setButtonEliminar, width: 110, sortable: false, filter: false, field: 'Eliminar' });
          this.getField('rejilla2').initData(this.gridOptionsComponentes, configCell);
        }else if (data.estado_p === 404){
          this.getField('rejilla2').toggle(false);
        }
    }

    setButtonEliminar(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = () => this.Eliminar(id);
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }
  
    Eliminar(id) {
      this.ids = id;
      this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
      this.getField('confirmModalCustom').setClickDialog(this.ConfirmDelete);
      this.getField('confirmModalCustom').toggle(true);
    }
  
    ConfirmDelete() {
      this.getField('confirmModalCustom').toggle(false);
      let datos = {
          datos: {
            conceptospago_id: this.ids,
          }
      };
      this.generalFormatPmv = { tipo_servicio: 'maes-conceptospago', operacion: '7', operacion_tipo: "eliminar" };
      this.service.send(
          {
              endpoint: this.constant.formConfiguration(),
              method: 'DELETE',
              body: datos,
              success: this.successEliminar,
              error: this.error_,
              general: this.generalFormatPmv //se envia el generales de arriba
          });
    }
  
  
    successEliminar(data) {
      if(data.estado_p === 200){ 
        this.mostrardatos();
      }else if(data.estado_p === 404){
        this.alertGeneral.toggle(true, 'El registro no pudo ser eliminado', "error");
      }
       
    }

    validarmanejamovimiento()
    {
      if(this.getField('movimiento').getValue() === 'N'){
        this.limpiar();
        this.alertGeneral.toggle(true, 'Solo pueden seleccionar cuentas que maneja_movimiento=’S’', 'error');
      }
    }
    
    limpiar(){
      this.getField('codigo_cuenta').setValue('')
      this.getField('nombre_cuenta').setValue('')
      this.getField('estadoactivo_id').setValue('');
      this.getField('cuenta_id').setValue('')
      this.getField('movimiento').setValue('')
    }

    validarexiste(){
        if((this.getField('codigo_cuenta').valid()) && (this.getField('nombre_cuenta').valid()) && (this.getField('cuenta_id').valid()) 
        && (this.getField('estadoactivo_id').valid()) ){
           
        let datos = { datos: {
          codigo_cuenta: parseInt(this.getField('codigo_cuenta').getValue())
        }};

          this.generalFormatPmv = { tipo_servicio: 'maes-conceptospago', operacion: '20', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successValidar,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
        } 
        else 
        { 
          this.alertGeneral.toggle(true, 'Error en los datos ', 'error');  
        }
    }

    successValidar(data){
      if(data.estado_p === 200){ 
        this.alertGeneral.toggle(true, 'La cuenta ingresada ya existe, no se permite duplicar', "error");
  
      }else if(data.estado_p === 404){
        this.guardar();
      }
    }

    guardar(){
      let sNombreCampo='';
      sNombreCampo=this.arrayCampos.get(5);
      this.datosAgregar.datos[sNombreCampo]=this.getField('estadoactivo_id').getValue(); 
      sNombreCampo=this.arrayCampos.get(6);
      this.datosAgregar.datos[sNombreCampo]=this.getField('cuenta_id').getValue(); 
      this.datosAgregar.datos['codigo_cuenta']=parseInt(this.getField('codigo_cuenta').getValue());

      let datos=this.datosAgregar;
      this.generalFormatPmv = { tipo_servicio: 'maes-conceptospago', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successGrabar,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successGrabar(data) 
    {
      if (data.estado_p === 200)
      {
          this.limpiar();
          this.mostrardatos();
      }
      else 
      {
         if(data.estado_p===404) {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
         }
         else {
           let respuesta=Object.values(data.data.errores);
           let keys=Object.keys(data.data.errores);
           this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
          }
      };
  }

    cargarCampos(data) 
    {
           this.getField('conceptospago_id').setValue(data.data[0].conceptospago_id);
           this.getField('codigo_cuenta').setValue(data.data[0].codigo_cuenta);
           this.getField('estados_activos').setValue(data.data[0].estados_activos);
           this.getField('cuenta_id').setValue(data.data[0].cuenta_id);
   }

}
FormJaivana.addController("maes-conceptospago",CustomConceptosPago);
export default CustomConceptosPago