import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Mario A. Sepúlveda R.
 * @version jdesk_1.01.0001
 * @modified 06-07-2023
 * @update Jose Albeiro Marin
 * @modified 16-06-2023
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomActivosFijosImagenes extends FormJaivana.form {
   constructor(props) {
       super(props);
       this.initForm                                                                = this.initForm.bind(this);
       // 1.0 configuracion para polizas
       this.opcionPolizas                                                           = this.opcionPolizas.bind(this);
       this.habilitarBtnConsultar                                                   = this.habilitarBtnConsultar.bind(this);
       this.btnConsultarPoliza                                                      = this.btnConsultarPoliza.bind(this);
       this.successTbTodosPorActivosFijosPolizas_id                                 = this.successTbTodosPorActivosFijosPolizas_id.bind(this);
       // 1.0 funciones para renderizar la tabla, boton y seleccionar registro dentro de 1.0 poliza
       this.gridOptionsTodosPorActivosFijosPolizas_id                               = Object.assign({},this.gridOptions);
       this.gridOptionsComponentes                                                  =   Object.assign({},this.gridOptions);
       this.setButtonTbTodosPorActivosFijosPolizas_id                               = this.setButtonTbTodosPorActivosFijosPolizas_id.bind(this);
       this.crearBotonTabla                                                         = this.crearBotonTabla.bind(this);
       this.accionProcesar                                                          = this.accionProcesar.bind(this);
       this.clicBtnAgregar                                                          = this.clicBtnAgregar.bind(this);
       // 1.0 funciones para agregar una imagen nueva con sus respectivas validaciones
       this.validarArchivosImagenes                                                 = this.validarArchivosImagenes.bind(this);
       this.habilitarBtnAceptar                                                     = this.habilitarBtnAceptar.bind(this);       
       this.clicBtnAceptarCrearActivosFijosImagenes                                 = this.clicBtnAceptarCrearActivosFijosImagenes.bind(this);
       this.successBtnAceptarCrearActivosFijosImagenes                              = this.successBtnAceptarCrearActivosFijosImagenes.bind(this);
       this.clicBtnConfirmEliminar                                                  = this.clicBtnConfirmEliminar.bind(this);
       this.successBtnConfirmEliminar                                               = this.successBtnConfirmEliminar.bind(this);
       this.refrescarUpLoadFileImagen                                               = this.refrescarUpLoadFileImagen.bind(this);
       // 1.0 funciones para la confirmacion y la acción del botón cancelar
       this.confirmacionCancelar                                                    = this.confirmacionCancelar.bind(this);
       this.btnCancelar                                                             = this.btnCancelar.bind(this);
       // variables globales
       this.estadoValidarArchivosImagenes                                           = false;
       this.activo_fijo_imagen                                                      = "";
       this.archivoImagen                                                           = "";
       this.allowedExtensions                                                       = /(.jpg|.jpeg|.png|.JPG|.JPEG|.PNG)$/i;
       this.columna                                                                 = "";
       this.id                                                                      = 0;

       // 2.0 configuración para garantias
       this.opcionGarantias                                                         = this.opcionGarantias.bind(this);
       this.habilitarBtnConsultarGarantias                                          = this.habilitarBtnConsultarGarantias.bind(this);
       this.btnConsultarGarantia                                                    = this.btnConsultarGarantia.bind(this);
       this.successTbTodosPorActivosFijosGarantias_id                               = this.successTbTodosPorActivosFijosGarantias_id.bind(this);
       // 2.0 funciones para renderizar la tabla, boton y seleccionar registro dentro de 2.0 GARANTÍAS
       this.gridOptionsTodosPorActivosFijosGarantias_id                             = Object.assign({},this.gridOptions);
       this.setButtonTbTodosPorActivosFijosGarantias_id                             = this.setButtonTbTodosPorActivosFijosGarantias_id.bind(this);
       this.crearBotonTablaGarantias                                                = this.crearBotonTablaGarantias.bind(this);
       this.accionProcesarGarantias                                                 = this.accionProcesarGarantias.bind(this);
       this.clicBtnAgregarGarantias                                                 = this.clicBtnAgregarGarantias.bind(this);
       // 2.0 funciones para agregar una imagen nueva con sus respectivas validaciones
       this.validarArchivosImagenesGarantias                                        = this.validarArchivosImagenesGarantias.bind(this);
       this.habilitarBtnAceptarGarantias                                            = this.habilitarBtnAceptarGarantias.bind(this);
       this.clicBtnAceptarCrearActivosFijosImagenesGarantias                        = this.clicBtnAceptarCrearActivosFijosImagenesGarantias.bind(this);
       this.successBtnAceptarCrearActivosFijosImagenesGarantias                     = this.successBtnAceptarCrearActivosFijosImagenesGarantias.bind(this);
       this.clicBtnConfirmEliminarGarantias                                         = this.clicBtnConfirmEliminarGarantias.bind(this);
       this.successBtnConfirmEliminarGarantias                                      = this.successBtnConfirmEliminarGarantias.bind(this);
       this.refrescarUpLoadFileImagenGarantias                                      = this.refrescarUpLoadFileImagenGarantias.bind(this);
       // 2.0 funciones para la confirmacion y la acción del botón cancelar
       this.confirmacionCancelarGarantias                                           = this.confirmacionCancelarGarantias.bind(this);
       this.btnCancelarGarantias                                                    = this.btnCancelarGarantias.bind(this);

       // 3.0 configuración para seriales
       this.opcionSeriales                                                          = this.opcionSeriales.bind(this);
       this.habilitarBtnConsultarSeriales                                           = this.habilitarBtnConsultarSeriales.bind(this);
       this.btnConsultarSeriales                                                    = this.btnConsultarSeriales.bind(this);
       this.successTbTodosPorActivosFijosSeriales_id                                = this.successTbTodosPorActivosFijosSeriales_id.bind(this);
       // 3.0 funciones para renderizar la tabla, boton y seleccionar registro dentro de 3.0 SERIALES
       this.gridOptionsTodosPorActivosFijosSeriales_id                              = Object.assign({},this.gridOptions);
       this.setButtonTbTodosPorActivosFijosSeriales_id                              = this.setButtonTbTodosPorActivosFijosSeriales_id.bind(this);
       this.crearBotonTablaSeriales                                                 = this.crearBotonTablaSeriales.bind(this);
       this.accionProcesarSeriales                                                  = this.accionProcesarSeriales.bind(this);
       this.clicBtnAgregarSeriales                                                  = this.clicBtnAgregarSeriales.bind(this);
       // 3.0 funciones para agregar una imagen nueva con sus respectivas validaciones
       this.validarArchivosImagenesSeriales                                         = this.validarArchivosImagenesSeriales.bind(this);
       this.habilitarBtnAceptarSeriales                                             = this.habilitarBtnAceptarSeriales.bind(this);
       this.clicBtnAceptarCrearActivosFijosImagenesSeriales                         = this.clicBtnAceptarCrearActivosFijosImagenesSeriales.bind(this);
       this.successBtnAceptarCrearActivosFijosImagenesSeriales                      = this.successBtnAceptarCrearActivosFijosImagenesSeriales.bind(this);
       this.clicBtnConfirmEliminarSeriales                                          = this.clicBtnConfirmEliminarSeriales.bind(this);
       this.successBtnConfirmEliminarSeriales                                       = this.successBtnConfirmEliminarSeriales.bind(this);
       this.refrescarUpLoadFileImagenSeriales                                       = this.refrescarUpLoadFileImagenSeriales.bind(this);
       // 3.0 funciones para la confirmacion y la acción del botón cancelar
       this.confirmacionCancelarSeriales                                            = this.confirmacionCancelarSeriales.bind(this);
       this.btnCancelarSeriales                                                     = this.btnCancelarSeriales.bind(this);

       // 4.0 configuración para ASIGNACIONES
       this.opcionAsignaciones                                                      = this.opcionAsignaciones.bind(this);
       this.habilitarBtnConsultarAsignaciones                                       = this.habilitarBtnConsultarAsignaciones.bind(this);
       this.btnConsultarAsignaciones                                                = this.btnConsultarAsignaciones.bind(this);
       this.successTbTodosPorActivosFijosAsignaciones_id                            = this.successTbTodosPorActivosFijosAsignaciones_id.bind(this);
       // 4.0 funciones para renderizar la tabla, boton y seleccionar registro dentro de 4.0 ASIGNACIONES
       this.gridOptionsTodosPorActivosFijosAsignaciones_id                          = Object.assign({},this.gridOptions);
       this.setButtonTbTodosPorActivosFijosAsignaciones_id                          = this.setButtonTbTodosPorActivosFijosAsignaciones_id.bind(this);
       this.crearBotonTablaAsignaciones                                             = this.crearBotonTablaAsignaciones.bind(this);
       this.accionProcesarAsignaciones                                              = this.accionProcesarAsignaciones.bind(this);
       this.clicBtnAgregarAsignaciones                                              = this.clicBtnAgregarAsignaciones.bind(this);
       // 4.0 funciones para agregar una imagen nueva con sus respectivas validaciones
       this.validarArchivosImagenesAsignaciones                                     = this.validarArchivosImagenesAsignaciones.bind(this);
       this.habilitarBtnAceptarAsignaciones                                         = this.habilitarBtnAceptarAsignaciones.bind(this);
       this.clicBtnAceptarCrearActivosFijosImagenesAsignaciones                     = this.clicBtnAceptarCrearActivosFijosImagenesAsignaciones.bind(this);
       this.successBtnAceptarCrearActivosFijosImagenesAsignaciones                  = this.successBtnAceptarCrearActivosFijosImagenesAsignaciones.bind(this);
       this.clicBtnConfirmEliminarAsignaciones                                      = this.clicBtnConfirmEliminarAsignaciones.bind(this);
       this.successBtnConfirmEliminarAsignaciones                                   = this.successBtnConfirmEliminarAsignaciones.bind(this);
       this.refrescarUpLoadFileImagenAsignaciones                                   = this.refrescarUpLoadFileImagenAsignaciones.bind(this);
       // 4.0 funciones para la confirmacion y la acción del botón cancelar
       this.confirmacionCancelarAsignaciones                                        = this.confirmacionCancelarAsignaciones.bind(this);
       this.btnCancelarAsignaciones                                                 = this.btnCancelarAsignaciones.bind(this);
       this.opcionBasicos                                                           = this.opcionBasicos.bind(this);


       this.consultarActivos                                                        = this.consultarActivos.bind(this);
       this.sucessConsultarActivos                                                  = this.sucessConsultarActivos.bind(this);
       this.setButtonTbActivosFijosImagenes                                         = this.setButtonTbActivosFijosImagenes.bind(this);
       this.accionProcesarImangenes                                                 = this.accionProcesarImangenes.bind(this);
       this.crearBotonTablaImagenes                                                 = this.crearBotonTablaImagenes.bind(this);
       this.mostrarAgregar                                                          = this.mostrarAgregar.bind(this);
       this.ocultarAgregar                                                          = this.ocultarAgregar.bind(this);
       this.generarImagen                                                           = this.generarImagen.bind(this);
       this.clicBtnConfirmEliminarImagenes                                          = this.clicBtnConfirmEliminarImagenes.bind(this);
       this.successBtnConfirmEliminarImagenes                                       = this.successBtnConfirmEliminarImagenes.bind(this);
       this.guardarImagen                                                           = this.guardarImagen.bind(this);
       this.successGuardarImagen                                                    = this.successGuardarImagen.bind(this);
       this.ocultarCampos                                                           = this.ocultarCampos.bind(this);
       this.validarUnique                                                           = this.validarUnique.bind(this);
       this.sucessValidarUnique                                                     = this.sucessValidarUnique.bind(this);
       this.validarImagenExistentePolizas                                           = this.validarImagenExistentePolizas.bind(this);
       this.successValidarImagenExistentePolizas                                    = this.successValidarImagenExistentePolizas.bind(this);
       this.validarImagenExistenteGarantias                                         = this.validarImagenExistenteGarantias.bind(this);
       this.successValidarImagenExistenteGarantias                                  = this.successValidarImagenExistenteGarantias.bind(this);
       this.validarImagenExistenteSeriales                                          = this.validarImagenExistenteSeriales.bind(this);
       this.successValidarImagenExistenteSeriales                                   = this.successValidarImagenExistenteSeriales.bind(this);
       this.validarImagenExistenteAsignaciones                                      = this.validarImagenExistenteAsignaciones.bind(this);
       this.successValidarImagenExistenteAsignaciones                               = this.successValidarImagenExistenteAsignaciones.bind(this);
       this.validarImagenExistenteBasicos                                           = this.validarImagenExistenteBasicos.bind(this);
       this.successValidarImagenExistenteBasicos                                    = this.successValidarImagenExistenteBasicos.bind(this);
    
    }

   initForm() {

        console.log('Formulario CustomActivosFijosImagenes,  @version: jdesk_1.01.0001, @author:Mario A. Sepúlveda R. @update:Jose Albeiro Marin');
        
        
        if(this.props.data[0].opcion_sub_seccion === 1){                  
            this.opcionPolizas();
         }else if(this.props.data[0].opcion_sub_seccion === 2){  
            this.opcionGarantias();
         }else if(this.props.data[0].opcion_sub_seccion === 3){            
            this.opcionSeriales();
         }else if(this.props.data[0].opcion_sub_seccion === 4){          
            this.opcionAsignaciones();
         }else if(this.props.data[0].opcion_sub_seccion === 5){          
            this.opcionBasicos();
         }   
         
   }
   // 1.0 todo lo que voy a visualizar en el case 1 de PÓLIZAS
   opcionPolizas(){
        this.getField('nombre_operacion').setValue('PÓLIZAS');
        this.getField('nombre_operacion').setVisible(true);
        this.getField('numero_poliza').setVisible(true);
        this.getField('nit_tercero_poliza').setVisible(true);
        this.getField('sede_tercero_poliza').setVisible(true);
        this.getField('nombre_tercero_poliza').setVisible(true);
        this.getField('btn_consultar_poliza').setVisible(true);
        this.getField('btn_consultar_poliza').setDisabled(true);
        this.getField('activosfijospolizas_id').setOnChange(this.habilitarBtnConsultar);
        this.getField('btn_consultar_poliza').setClick(this.btnConsultarPoliza);
        this.getField('btn_aceptar').setClick(this.validarImagenExistentePolizas);
        this.getField('btn_cancelar').setClick(this.confirmacionCancelar);

        // Para obtner el binario de las imagenes cargadas pólizas        
        this.getField('activo_fijo_imagen').setOnChange((props) => {this.habilitarBtnAceptar(); this.validarArchivosImagenes(props); props.target !== '' && props.target !== undefined ? this.archivoImagen = props.target.files[0] : this.archivoImagen = '';});

        //habilitar campos se usa en la funcion 1.3.4-1.2 -> habilitarBtnAceptar
        this.getField('numero_poliza').setOnChange(this.habilitarBtnAceptar);
        this.getField('nit_tercero_poliza').setOnChange(this.habilitarBtnAceptar);
        this.getField('sede_tercero_poliza').setOnChange(this.habilitarBtnAceptar);
        this.getField('nombre_tercero_poliza').setOnChange(this.habilitarBtnAceptar);
   }
   // 1.1 habilita el botón CONSULTAR SI ESTA SETEADO DE FORMA CORRECTA
   habilitarBtnConsultar() {

        if (this.getField('activosfijospolizas_id').getValue() !== '' && this.getField('numero_poliza').valid() && this.getField('nombre_tercero_poliza').valid()) {

            this.getField('btn_consultar_poliza').setDisabled(false);

        } else {

            this.getField('btn_consultar_poliza').setDisabled(true);
            this.getField('tb_todosporactivosfijospolizas_id').toggle(false);
            this.getField('activo_fijo_imagen').setVisible(false);
            this.getField('btn_aceptar').setVisible(false);
            this.getField('btn_cancelar').setVisible(false);
            this.getField('activo_fijo_imagen').setValue('');
            this.getField('activo_fijo_imagen').setError(false, '');
        }
    }
    // 1.2 FUNCIONALIDAD PARA EL EVENTO DEL BOTON CONSULTAR
    btnConsultarPoliza() {

        if (this.getField('activosfijospolizas_id').getValue() !== '' && this.getField('numero_poliza').valid() && this.getField('nombre_tercero_poliza').valid()) {
      
            this.getField('btn_consultar_poliza').setDisabled(true);
            let datos = { datos: {} };
            datos.datos['activosfijospolizas_id'] = this.getField('activosfijospolizas_id').getValue();
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijospolizas', operacion: '2_1', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successTbTodosPorActivosFijosPolizas_id,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    //showMessage: false,
                }
            );
        }else {
            this.alertGeneral.toggle(true,'* Verifique los datos ingresados','error');
        }
    }
    // 1.3-1 RESULTADO DEPENDIENDO EL ESTADO_P SE RENDERIZA LA TABLA
    successTbTodosPorActivosFijosPolizas_id(data) {
        
        if (data.estado_p === 200 && data.model && data.model.length >= 1) {
            
            let configCell = new Map();
            this.gridOptionsTodosPorActivosFijosPolizas_id['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            configCell.set('numero_poliza', { headerName: 'Número de Póliza'});
            configCell.set('detalle', { headerName: 'Detalle'});
            configCell.set('imagen_url', { headerName: 'Imagen URL'});
            configCell.set('ruta_imagen', { headerName: 'Ruta Imagen'});
            configCell.set('accion', { cellRenderer: this.setButtonTbTodosPorActivosFijosPolizas_id, headerName: 'Acción', sortable: false, filter: false });
            //se carga la configuracion de la tabla agregada en el modelo adicional se agrega el configcell el cual me permite renderizar
            //botones dentro de la columna asiganada accion
            this.getField('tb_todosporactivosfijospolizas_id').initData(this.gridOptionsTodosPorActivosFijosPolizas_id,configCell);
            this.gridOptionsTodosPorActivosFijosPolizas_id.api.deselectAll();
            this.habilitarBtnAceptar();

        }else if (data.estado_p === 404) {//si no encuentra datos, no renderiza la tabla
            
            this.alertGeneral.toggle(true,'No hay registros relacionados con el Número Póliza: '+this.getField('numero_poliza').getValue()+' seleccionado','error');
            this.getField('tb_todosporactivosfijospolizas_id').toggle(false);

        }
    }
    // 1.3-2 RENDERIZAR BOTÓN AGREGAR, ELIMINAR Y VER IMAGEN EN LA TABLA CUANDO LA FUNCION successTbTodosPorActivosFijosPolizas_id SE VA POR EL ESTADO_P 200
    setButtonTbTodosPorActivosFijosPolizas_id(props){

        let fragment = document.createDocumentFragment();
        let buttonAgregar = this.crearBotonTabla(props.data, 'Agregar');
        let buttonEliminar = this.crearBotonTabla(props.data, 'Eliminar');
        let buttonVerImagen = this.crearBotonTabla(props.data, 'Ver Imagen');
        // buttonAgregar.setAttribute("class","buttonStyle2");
        buttonEliminar.setAttribute("class","buttonStyle2");
        buttonVerImagen.setAttribute("class","buttonStyle2");
        fragment.appendChild(buttonAgregar);
        fragment.appendChild(buttonEliminar);
        fragment.appendChild(buttonVerImagen);
        
        return this.createElementJaivana(fragment);
    }
      /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      **/
    // 1.3-3 Ejecución antes de Ejecutar accionProcesar dependiendo el clic dado a el botón renderizado
    crearBotonTabla(data, boton) {
        
        let button = document.createElement('input');
        switch (boton) {
            case 'Agregar':
                button.setAttribute("id", `buttonAgregar_${data.id}`);
                if (data.ruta_imagen !== 'No hay imagen relacionada') {
                    button.setAttribute("class","buttonStyleActivos");
                    button.setAttribute("disabled", true);
                }else{
                    button.setAttribute("class","buttonStyle2");
                }
                break;
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.id}`);
                if (data.ruta_imagen === 'No hay imagen relacionada') {
                    button.setAttribute("disabled", true);
                    button.setAttribute("hidden", true);
                }
                break;
            case 'Ver Imagen':
                button.setAttribute("id", `buttonVerImagen_${data.id}`);
                if (data.ruta_imagen === 'No hay imagen relacionada') {
                    button.setAttribute("disabled", true);
                    button.setAttribute("hidden", true);
                }
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }
    // 1.3.4 Aqui implemento las acciones que pueden ejecutar al oprimir los botones renderizados en la tabla
    accionProcesar(data, boton) {
        
        if (boton === 'Agregar') {

                this.archivoImagen= "";
                this.getField('activo_fijo_imagen').setValue('');
                this.getField('activo_fijo_imagen').setError(false, '');
                this.habilitarBtnAceptar();
                this.columna= data.imagen_url;
                this.id= data.activosfijospolizas_id;
                this.clicBtnAgregar();
                      

        }else if (boton === 'Eliminar') {

            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.clicBtnConfirmEliminar(data) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.refrescarUpLoadFileImagen();
            this.getField('confirmModalCustom').toggle(true);
            
        } else if (boton === 'Ver Imagen') {

            this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.refrescarUpLoadFileImagen();
            this.getField('confirmModalCustom').toggle(true);

            let url = this.constant.formConfiguration();
            let datos=`?body={"datos":{"nombre_tabla":"activosfijos_polizas","id":${data.activosfijospolizas_id},"ruta_imagen":"${data.ruta_imagen}","imagen_url":"${data.imagen_url}"},"generales":{"tipo_servicio":"maes-activosfijosimagenes","operacion":"traerimagenurlimagenes","operacion_tipo":"consulta"}}`;
            fetch(url+datos,{
                method:'get',
                headers: new Headers({
                'Authorization': 'Bearer '+localStorage.getItem("token"),
                'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if(response.status!==200){                    
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                    return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            }).then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    if(blob.size>0){
                        const image = new File([blob], "name.jpeg", {
                            type: "image/jpeg",
                        });
                        window.open(URL.createObjectURL(image))
                    }else{
                        this.alertGeneral.toggle(true,'No hay datos','error');
                    }
                    this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
                })
                .catch(err => console.error(err));
                this.operacion="";
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    // 1.3.4-1 habilitar campos cuando se le da clic en el botón agregar,de acuerdo al registro seleecionado dentro de la tabla en este caso lo manejaremos como un servicio POST
    clicBtnAgregar(){

            this.getField('activo_fijo_imagen').setVisible(true);
            this.getField('btn_aceptar').setVisible(true);
            this.getField('btn_cancelar').setVisible(true);

    }
    /* 1.3.4-1.1 aqui valido el archivo cargado en el campo UF lo hace de forma automatica con un setOnChange sobre el campo y obteniendo las props - utilizo las funciones:
    this.validarArchivosImagenes*/
    validarArchivosImagenes(props){

        if (props.target.files.length >=1 ){

            if (!this.allowedExtensions.exec(props.target.files[0].name)){

                this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .jpeg/.jpg/.png','error');
                this.estadoValidarArchivosImagenes = false;
                this.getField('btn_aceptar').setDisabled(true);
                this.getField('activo_fijo_imagen').setError(true, '* Valide el archivo adjunto.');
                
            }else if (props.target.files[0].name.indexOf(' ') > 0) {

                this.alertGeneral.toggle(true, "La imagen (" + props.target.files[0].name + ") no puede contener espacios.", 'error');
                this.estadoValidarArchivosImagenes = false;
                this.getField('btn_aceptar').setDisabled(true);
                this.getField('activo_fijo_imagen').setError(true, '* Valide el archivo adjunto.');
            }else{
                this.estadoValidarArchivosImagenes = true;
            }
        }
    }
    /* 1.3.4-1.2 si los campos de la condición if pasan la validación me habilita el botón ACEPTAR de lo contrario el botón permanece inactivo,
    lo controlo de varias maneras para no dejar pasar errores */
    habilitarBtnAceptar(){
        
        if (this.getField('numero_poliza').getValue() !== '' && this.getField('nit_tercero_poliza').getValue() !== '' && this.getField('sede_tercero_poliza').getValue() !== '' &&
        this.getField('nombre_tercero_poliza').getValue() !== '' && this.getField('activosfijospolizas_id').getValue() !== '' && this.getField('activo_fijo_imagen').getValue() !== '' && this.getField('activo_fijo_imagen').valid()){
            
            this.getField('btn_aceptar').setDisabled(false);

        }else{

            this.getField('btn_aceptar').setDisabled(true);   
        }
    }

    validarImagenExistentePolizas(){
        let datos={ datos: {
            value:this.archivoImagen.name
        }}    
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: '25', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarImagenExistentePolizas,
                error: this.error_,
                general: this.generalFormatPmv
        });

    }

    successValidarImagenExistentePolizas(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'Ya existe una imagen con este nombre. ¡Por favor verificar!', 'error');
        }else if(data.estado_p === 404){
            this.clicBtnAceptarCrearActivosFijosImagenes();

        }
    }
    /* 1.3.4-1.3 En esta funcion se consume el servicio que me actualiza la ruta_imagen desde aqui tambien toma la logica en el
    maestros.resource para subir la imagen al nextcloud */
    clicBtnAceptarCrearActivosFijosImagenes(){
        
        if (this.getField('numero_poliza').getValue() !== '' && this.getField('nit_tercero_poliza').getValue() !== '' && this.getField('sede_tercero_poliza').getValue() !== '' &&
        this.getField('nombre_tercero_poliza').getValue() !== '' && this.getField('activosfijospolizas_id').getValue() !== '' && this.getField('activo_fijo_imagen').getValue() !== '' && this.getField('activo_fijo_imagen').valid() && this.estadoValidarArchivosImagenes && this.archivoImagen) {
            
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            const data = {
                _generales: {"tipo_servicio":"maes-activosfijosimagenes","operacion":"5","operacion_tipo": "crear"},
                archivo: this.archivoImagen,
                nombre_carpeta: 'img_activos_fijos_polizas',
                nombre_columna: this.columna,
                nombre_tabla: 'activosfijos_polizas',
                id: this.id,
            }
            
            let url = this.constant.formConfiguration();
            this.generalFormat = data._generales;
            this.service.send(
                {
                    endpoint:url,
                    method: 'POST',
                    body: data,
                    success: this.successBtnAceptarCrearActivosFijosImagenes,
                    error: this.error_,
                    general: this.generalFormat,
                    formData: true,
                    showMessage: false
                }
            );
        }else if (this.estadoValidarArchivosImagenes === false) {

            this.getField('activo_fijo_imagen').setError(true, '* Valide el archivo adjunto.');
            this.habilitarBtnAceptar();
        }
    }
    // 1.3.4-1.4  Respuesta del servicio que responde al momento de actualizar el campo de la imagen adicionada en la ruta_imagen correspondiente
    successBtnAceptarCrearActivosFijosImagenes(data){
        
        if (data.estado_p === 200){

            this.archivoImagen= "";
            this.btnConsultarPoliza();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar la tabla visible del registro consultado
            this.refrescarUpLoadFileImagen();

                            
        }else{           
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
        this.getField('confirmModalCustom').toggle(false);// cierra el confirmDialog despues de que responde estado_p 200 el servicio
    }
    // 1.3.4-1.5 refresar los campos visibles para cargar e insertar la imagen (activo_fijo_imagen)
    refrescarUpLoadFileImagen(){
        
        this.getField('btn_aceptar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('activo_fijo_imagen').setVisible(false);
        this.getField('activo_fijo_imagen').setValue('');
        this.getField('activo_fijo_imagen').setError(false, '');
        this.archivoImagen= "";
        this.columna= "";
    }
    // 1.3.5 Confirmación para la acción del botón cancelar abriendo ventana informativa de confirmación
    confirmacionCancelar(){
    
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta acción?`)
        this.getField('confirmModalCustom').setClickDialog(() => { 
            this.btnCancelar();
            this.getField('confirmModalCustom').toggle(false); 
        });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    // 1.3.5-1 Eliminar la imagen seleccionado dentro de la tabla en este caso lo manejaremos como un servicio UPDATE
    clicBtnConfirmEliminar(data) {
        
        if (data.activosfijospolizas_id !== '') {
            let datos = { datos: {
                
                nombre_tabla : 'activosfijos_polizas',
                id : data.activosfijospolizas_id,
                imagen_url : data.imagen_url,
                ruta_imagen : data.ruta_imagen,

            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: 'ActualizarEliminarImagen', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successBtnConfirmEliminar,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    // 1.3.5-1.1 Respuesta del servicio que responde al momento de actualizar el campo de la imagen eliminada
    successBtnConfirmEliminar(data) {

        if (data.estado_p === 200) {

            this.btnConsultarPoliza();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar la tabla visible del registro consultado
            this.getField('confirmModalCustom').toggle(false);
        }
    }
    //1.4 Acción para el botón cancelar
    btnCancelar() {

        //Ocultar campos
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_aceptar').setVisible(false);        
        this.getField('activo_fijo_imagen').setVisible(false);
        //Limpiar campos
        this.getField('activo_fijo_imagen').setValue('');
        this.getField('nombre_tercero_poliza').setValue('');
        this.getField('sede_tercero_poliza').setValue('');
        this.getField('nit_tercero_poliza').setValue('');
        this.getField('numero_poliza').setValue('');
        this.getField('activosfijospolizas_id').setValue('');        
        this.getField('activosfijos_polizas_id').setValue('');
        //Borrar mensajes de error en los campos
        this.getField('activo_fijo_imagen').setError(false, '');
        this.getField('nombre_tercero_poliza').setError(false, '');
        this.getField('sede_tercero_poliza').setError(false, '');
        this.getField('nit_tercero_poliza').setError(false, '');
        this.getField('numero_poliza').setError(false, '');
        this.getField('activosfijospolizas_id').setError(false, '');
        this.getField('activosfijos_polizas_id').setError(false, '');
        // ocultar tabla
        this.gridOptionsTodosPorActivosFijosPolizas_id["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTodosPorActivosFijosPolizas_id);
        this.getField('tableShowData').toggle(false);
        // Restablecer variables
        this.estadoValidarArchivosImagenes= false;
        this.activo_fijo_imagen= "";
        this.archivoImagen = "";
        this.columna = "";
        this.id = 0;       
    }
   // 2.0 todo lo que voy a visualizar en el case 2 de GARANTÍAS 
   opcionGarantias(){
    this.getField('nombre_operacion').setValue('GARANTÍAS');
    this.getField('nombre_operacion').setVisible(true);
    this.getField('detalle_reclamacion').setVisible(true);
    this.getField('btn_consultar_garantia').setVisible(true);
    this.getField('btn_consultar_garantia').setDisabled(true);
    this.getField('activosfijos_garantias_id').setOnChange(this.habilitarBtnConsultarGarantias);
    this.getField('btn_consultar_garantia').setClick(this.btnConsultarGarantia);
    this.getField('btn_aceptar_garantias').setClick(this.validarImagenExistenteGarantias);
    this.getField('btn_cancelar_garantias').setClick(this.confirmacionCancelarGarantias);

    // Para obtner el binario de las imagenes cargadas Garantías
    this.getField('activo_fijo_imagen_garantias').setOnChange((props) => {this.habilitarBtnAceptarGarantias(); this.validarArchivosImagenesGarantias(props); props.target !== '' && props.target !== undefined ? this.archivoImagen = props.target.files[0] : this.archivoImagen = '';});
    

    //habilitar campos se usa en la funcion 2.3.4-1.2 -> habilitarBtnAceptarGarantias
    this.getField('detalle_reclamacion').setOnChange(this.habilitarBtnAceptarGarantias);
    
    

   }
   // 2.1 habilita el botón CONSULTAR - GARANTÍAS SI ESTA SETEADO DE FORMA CORRECTA
   habilitarBtnConsultarGarantias() {

    if (this.getField('activosfijos_garantias_id').getValue() !== '' && this.getField('detalle_reclamacion').getValue() !== '' &&this.getField('detalle_reclamacion').valid()) {

        this.getField('btn_consultar_garantia').setDisabled(false);

        } else {

            this.getField('btn_consultar_garantia').setDisabled(true);
            this.getField('tb_todosporactivosfijosgarantias_id').toggle(false);
            this.getField('activo_fijo_imagen_garantias').setVisible(false);
            this.getField('btn_aceptar_garantias').setVisible(false);
            this.getField('btn_cancelar_garantias').setVisible(false);
            this.getField('activo_fijo_imagen_garantias').setValue('');
            this.getField('activo_fijo_imagen_garantias').setError(false, '');
        }
    }
    // 2.2 FUNCIONALIDAD PARA EL EVENTO DEL BOTON CONSULTAR -GARANTÍAS
    btnConsultarGarantia() {

    if (this.getField('activosfijos_garantias_id').getValue() !== '' && this.getField('detalle_reclamacion').getValue() !== '' &&this.getField('detalle_reclamacion').valid()) {
  
        this.getField('btn_consultar_garantia').setDisabled(true);
        let datos = { datos: {} };
        datos.datos['activosfijos_garantias_id'] = this.getField('activosfijos_garantias_id').getValue();
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosgarantias', operacion: '2_1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successTbTodosPorActivosFijosGarantias_id,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage: false,
            });
        }else {
            this.alertGeneral.toggle(true,'* Verifique los datos ingresados','error');
        }
    }
    // 2.3-1 RESULTADO DEPENDIENDO EL ESTADO_P SE RENDERIZA LA TABLA
    successTbTodosPorActivosFijosGarantias_id(data) {
    
    if (data.estado_p === 200 && data.model && data.model.length >= 1) {
        
            let configCell = new Map();
            this.gridOptionsTodosPorActivosFijosGarantias_id['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud        
            configCell.set('detalle_reclamacion', { headerName: 'Detalle Reclamación'});
            configCell.set('imagen_url', { headerName: 'Imagen URL'});
            configCell.set('ruta_imagen', { headerName: 'Ruta Imagen'});
            configCell.set('accion', { cellRenderer: this.setButtonTbTodosPorActivosFijosGarantias_id, headerName: 'Acción', sortable: false, filter: false });
            //se carga la configuracion de la tabla agregada en el modelo adicional se agrega el configcell el cual me permite renderizar
            //botones dentro de la columna asiganada accion
            this.getField('tb_todosporactivosfijosgarantias_id').initData(this.gridOptionsTodosPorActivosFijosGarantias_id,configCell);
            this.gridOptionsTodosPorActivosFijosGarantias_id.api.deselectAll();
            this.habilitarBtnAceptarGarantias();

        }else if (data.estado_p === 404) {//si no encuentra datos, no renderiza la tabla
        
            this.alertGeneral.toggle(true,'No hay registros relacionados con el Detalle de Reclamación: '+this.getField('detalle_reclamacion').getValue()+' seleccionado','error');
            this.getField('tb_todosporactivosfijosgarantias_id').toggle(false);
        }
    }
    // 2.3-2 RENDERIZAR BOTÓN AGREGAR, ELIMINAR Y VER IMAGEN EN LA TABLA CUANDO LA FUNCION successTbTodosPorActivosFijosGarantias_id SE VA POR EL ESTADO_P 200
    setButtonTbTodosPorActivosFijosGarantias_id(props){

            let fragment = document.createDocumentFragment();
            let buttonAgregar = this.crearBotonTablaGarantias(props.data, 'Agregar');
            let buttonEliminar = this.crearBotonTablaGarantias(props.data, 'Eliminar');
            let buttonVerImagen = this.crearBotonTablaGarantias(props.data, 'Ver Imagen');
            // buttonAgregar.setAttribute("class","buttonStyle2");
            buttonEliminar.setAttribute("class","buttonStyle2");
            buttonVerImagen.setAttribute("class","buttonStyle2");
            fragment.appendChild(buttonAgregar);
            fragment.appendChild(buttonEliminar);
            fragment.appendChild(buttonVerImagen);

            return this.createElementJaivana(fragment);
    }
      /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      **/
    // 2.3-3 Ejecución antes de Ejecutar accionProcesarGarantias dependiendo el clic dado a el botón renderizado
    crearBotonTablaGarantias(data, boton) {
        
        let button = document.createElement('input');
        switch (boton) {
            case 'Agregar':
                button.setAttribute("id", `buttonAgregar_${data.id}`);
                if (data.ruta_imagen !== 'No hay imagen relacionada') {
                    button.setAttribute("class","buttonStyleActivos");
                    button.setAttribute("disabled", true);
                }else{
                    button.setAttribute("class","buttonStyle2");
                }
                break;
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.id}`);
                if (data.ruta_imagen === 'No hay imagen relacionada') {
                    button.setAttribute("disabled", true);
                    button.setAttribute("hidden", true);
                }
                break;
            case 'Ver Imagen':
                button.setAttribute("id", `buttonVerImagen_${data.id}`);
                if (data.ruta_imagen === 'No hay imagen relacionada') {
                    button.setAttribute("disabled", true);
                    button.setAttribute("hidden", true);
                }
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesarGarantias(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }
    // 2.3.4 Aqui implemento las acciones que pueden ejecutar al oprimir los botones renderizados en la tabla
    accionProcesarGarantias(data, boton) {
        
        if (boton === 'Agregar') {

            this.archivoImagen= "";
            this.getField('activo_fijo_imagen_garantias').setValue('');
            this.getField('activo_fijo_imagen_garantias').setError(false, '');
            this.habilitarBtnAceptarGarantias();
            this.columna= data.imagen_url;
            this.id= data.activosfijos_garantias_id;
            this.clicBtnAgregarGarantias();
    
            
            

        }else if (boton === 'Eliminar') {

            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.clicBtnConfirmEliminarGarantias(data) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.refrescarUpLoadFileImagenGarantias();
            this.getField('confirmModalCustom').toggle(true);
            
        } else if (boton === 'Ver Imagen') {

            this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.refrescarUpLoadFileImagenGarantias();
            this.getField('confirmModalCustom').toggle(true);

            let url = this.constant.formConfiguration();
            let datos=`?body={"datos":{"nombre_tabla":"activosfijos_garantias","id":${data.activosfijos_garantias_id},"ruta_imagen":"${data.ruta_imagen}","imagen_url":"${data.imagen_url}"},"generales":{"tipo_servicio":"maes-activosfijosimagenes","operacion":"traerimagenurlimagenes","operacion_tipo":"consulta"}}`;
            fetch(url+datos,{
                method:'get',
                headers: new Headers({
                'Authorization': 'Bearer '+localStorage.getItem("token"),
                'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if(response.status!==200){                    
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                    return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            }).then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    if(blob.size>0){
                        const image = new File([blob], "name.jpeg", {
                            type: "image/jpeg",
                        });
                        window.open(URL.createObjectURL(image))
                    }else{
                        this.alertGeneral.toggle(true,'No hay datos','error');
                    }
                    this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
                })
                .catch(err => console.error(err));
                this.operacion="";
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    // 2.3.4-1 habilitar campos cuando se le da clic en el botón agregar,de acuerdo al registro seleccionado dentro de la tabla en este caso lo manejaremos como un servicio POST
    clicBtnAgregarGarantias(){

            this.getField('activo_fijo_imagen_garantias').setVisible(true);
            this.getField('btn_aceptar_garantias').setVisible(true);
            this.getField('btn_cancelar_garantias').setVisible(true);

    }
    /* 2.3.4-1.1 aqui valido el archivo cargado en el campo UF lo hace de forma automatica con un setOnChange sobre el campo y obteniendo las props - utilizo las funciones:
    this.validarArchivosImagenesGarantias*/
    validarArchivosImagenesGarantias(props){

        if (props.target.files.length >=1 ){

            if (!this.allowedExtensions.exec(props.target.files[0].name)){

                this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .jpeg/.jpg/.png','error');
                this.estadoValidarArchivosImagenes = false;
                this.getField('btn_aceptar_garantias').setDisabled(true);
                this.getField('activo_fijo_imagen_garantias').setError(true, '* Valide el archivo adjunto.');
                
            }else if (props.target.files[0].name.indexOf(' ') > 0) {

                this.alertGeneral.toggle(true, "La imagen (" + props.target.files[0].name + ") no puede contener espacios.", 'error');
                this.estadoValidarArchivosImagenes = false;
                this.getField('btn_aceptar_garantias').setDisabled(true);
                this.getField('activo_fijo_imagen_garantias').setError(true, '* Valide el archivo adjunto.');
            }else{
                this.estadoValidarArchivosImagenes = true;
            }
        }
    }
    /* 2.3.4-1.2 si los campos de la condición if pasan la validación me habilita el botón ACEPTAR de GARANTÍAS de lo contrario el botón permanece inactivo,
    lo controlo de varias maneras para no dejar pasar errores */
    habilitarBtnAceptarGarantias(){
        
        if (this.getField('activosfijos_garantias_id').getValue() !== '' && this.getField('detalle_reclamacion').getValue() !== '' &&
        this.getField('activo_fijo_imagen_garantias').getValue() !== '' && this.getField('activo_fijo_imagen_garantias').valid()){
            
            this.getField('btn_aceptar_garantias').setDisabled(false);

        }else{

            this.getField('btn_aceptar_garantias').setDisabled(true);   
        }
    }

    validarImagenExistenteGarantias(){
        let datos={ datos: {
            value:this.archivoImagen.name
        }}    
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: '24', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarImagenExistenteGarantias,
                error: this.error_,
                general: this.generalFormatPmv
        });

    }

    successValidarImagenExistenteGarantias(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'Ya existe una imagen con este nombre. ¡Por favor verificar!', 'error');
        }else if(data.estado_p === 404){
            this.clicBtnAceptarCrearActivosFijosImagenesGarantias();

        }
    }
    /* 2.3.4-1.3 En esta funcion se consume el servicio que me actualiza la ruta_imagen desde aqui tambien toma la logica en el
    maestros.resource para subir la imagen al nextcloud */
    clicBtnAceptarCrearActivosFijosImagenesGarantias(){
        
        if (this.getField('activosfijos_garantias_id').getValue() !== '' && this.getField('detalle_reclamacion').getValue() !== '' && this.getField('activo_fijo_imagen_garantias').getValue() !== ''
        && this.getField('activo_fijo_imagen_garantias').valid() && this.estadoValidarArchivosImagenes && this.archivoImagen) {
            
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            const data = {
                _generales: {"tipo_servicio":"maes-activosfijosimagenes","operacion":"5","operacion_tipo": "crear"},
                archivo: this.archivoImagen,
                nombre_carpeta: 'img_activos_fijos_garantias',
                nombre_columna: this.columna,
                nombre_tabla: 'activosfijos_garantias',
                id: this.id,
            }
            
            let url = this.constant.formConfiguration();
            this.generalFormat = data._generales;
            this.service.send(
                {
                    endpoint:url,
                    method: 'POST',
                    body: data,
                    success: this.successBtnAceptarCrearActivosFijosImagenesGarantias,
                    error: this.error_,
                    general: this.generalFormat,
                    formData: true,
                    showMessage: false
                }
            );
        }else if (this.estadoValidarArchivosImagenes === false) {

            this.getField('activo_fijo_imagen_garantias').setError(true, '* Valide el archivo adjunto.');
            this.habilitarBtnAceptarGarantias();
        }
    }
    // 2.3.4-1.4  Respuesta del servicio que responde al momento de actualizar el campo de la imagen adicionada en la ruta_imagen correspondiente
    successBtnAceptarCrearActivosFijosImagenesGarantias(data){
        
            if (data.estado_p === 200){
    
                this.archivoImagen= "";
                this.btnConsultarGarantia();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar la tabla visible del registro consultado
                this.refrescarUpLoadFileImagenGarantias();
                                
            }else{           
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.getField('confirmModalCustom').toggle(false);// cierra el confirmDialog despues de que responde estado_p 200 el servicio
    }
    // 2.3.4-1.5 refresar los campos visibles para cargar e insertar la imagen (activo_fijo_imagen_garantias)
    refrescarUpLoadFileImagenGarantias(){
            
            this.getField('btn_aceptar_garantias').setVisible(false);
            this.getField('btn_cancelar_garantias').setVisible(false);
            this.getField('activo_fijo_imagen_garantias').setVisible(false);
            this.getField('activo_fijo_imagen_garantias').setValue('');
            this.getField('activo_fijo_imagen_garantias').setError(false, '');
            this.archivoImagen= "";
            this.columna= "";
    }
    // 2.3.5 Confirmación para la acción del botón cancelar abriendo ventana informativa de confirmación
    confirmacionCancelarGarantias(){
    
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta acción?`)
        this.getField('confirmModalCustom').setClickDialog(() => { 
            this.btnCancelarGarantias();
            this.getField('confirmModalCustom').toggle(false); 
        });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }    
    // 2.3.5-1 Eliminar la imagen seleccionado dentro de la tabla en este caso lo manejaremos como un servicio UPDATE
    clicBtnConfirmEliminarGarantias(data) {
        
        if (data.activosfijos_garantias_id !== '') {
            let datos = { datos: {
                
                nombre_tabla : 'activosfijos_garantias',
                id : data.activosfijos_garantias_id,
                imagen_url : data.imagen_url,
                ruta_imagen : data.ruta_imagen,

            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: 'ActualizarEliminarImagen', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successBtnConfirmEliminarGarantias,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    // 2.3.5-1.1 Respuesta del servicio que responde al momento de actualizar el campo de la imagen eliminada
    successBtnConfirmEliminarGarantias(data) {

        if (data.estado_p === 200) {

            this.btnConsultarGarantia();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar la tabla visible del registro consultado
            this.getField('confirmModalCustom').toggle(false);
        }
    }
    // 2.4 Acción para el botón cancelar
    btnCancelarGarantias() {

        //Ocultar campos
        this.getField('btn_cancelar_garantias').setVisible(false);
        this.getField('btn_aceptar_garantias').setVisible(false);        
        this.getField('activo_fijo_imagen_garantias').setVisible(false);
        //Limpiar campos
        this.getField('activo_fijo_imagen_garantias').setValue('');        
        this.getField('detalle_reclamacion').setValue('');
        this.getField('activosfijos_garantias_id').setValue('');        
        
        //Borrar mensajes de error en los campos
        this.getField('activo_fijo_imagen_garantias').setError(false, '');        
        this.getField('detalle_reclamacion').setError(false, '');
        this.getField('activosfijos_garantias_id').setError(false, '');        
        // ocultar tabla
        this.gridOptionsTodosPorActivosFijosGarantias_id["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTodosPorActivosFijosGarantias_id);
        this.getField('tableShowData').toggle(false);
        // Restablecer variables
        this.estadoValidarArchivosImagenes= false;
        this.activo_fijo_imagen= "";
        this.archivoImagen = "";
        this.columna = "";
        this.id = 0;     
    }
    // 3.0 todo lo que voy a visualizar en el case 3 de SERIALES 
    opcionSeriales(){
        this.getField('nombre_operacion').setValue('SERIALES');
        this.getField('nombre_operacion').setVisible(true);
        this.getField('numero_serie').setVisible(true);
        this.getField('modelo').setVisible(true);
        this.getField('btn_consultar_seriales').setVisible(true);
        this.getField('btn_consultar_seriales').setDisabled(true);
        this.getField('activosfijos_seriales_id').setOnChange(this.habilitarBtnConsultarSeriales);
        this.getField('btn_consultar_seriales').setClick(this.btnConsultarSeriales);
        this.getField('btn_aceptar_seriales').setClick(this.validarImagenExistenteSeriales);
        this.getField('btn_cancelar_seriales').setClick(this.confirmacionCancelarSeriales);

        // Para obtner el binario de las imagenes cargadas Garantías
        this.getField('activo_fijo_imagen_seriales').setOnChange((props) => {this.habilitarBtnAceptarSeriales(); this.validarArchivosImagenesSeriales(props); props.target !== '' && props.target !== undefined ? this.archivoImagen = props.target.files[0] : this.archivoImagen = '';});
        

        //habilitar campos se usa en la funcion 3.3.4-1.2 -> habilitarBtnAceptarSeriales
        this.getField('numero_serie').setOnChange(this.habilitarBtnAceptarSeriales);
        this.getField('modelo').setOnChange(this.habilitarBtnAceptarSeriales);
    
    

   }
   // 3.1 habilita el botón CONSULTAR - SERIALES SI ESTA SETEADO DE FORMA CORRECTA
   habilitarBtnConsultarSeriales() {

    if (this.getField('activosfijos_seriales_id').getValue() !== '' && this.getField('numero_serie').getValue() !== '' &&this.getField('numero_serie').valid() && this.getField('modelo').getValue() !== '' &&this.getField('modelo').valid()) {

        this.getField('btn_consultar_seriales').setDisabled(false);

        } else {

            this.getField('btn_consultar_seriales').setDisabled(true);
            this.getField('tb_todosporactivosfijosseriales_id').toggle(false);
            this.getField('activo_fijo_imagen_seriales').setVisible(false);
            this.getField('btn_aceptar_seriales').setVisible(false);
            this.getField('btn_cancelar_seriales').setVisible(false);
            this.getField('activo_fijo_imagen_seriales').setValue('');
            this.getField('activo_fijo_imagen_seriales').setError(false, '');
        }
    }
    // 3.2 FUNCIONALIDAD PARA EL EVENTO DEL BOTON CONSULTAR - SERIALES
    btnConsultarSeriales() {

    if (this.getField('activosfijos_seriales_id').getValue() !== '' && this.getField('numero_serie').getValue() !== '' &&this.getField('numero_serie').valid() && this.getField('modelo').getValue() !== '' &&this.getField('modelo').valid()) {
  
        this.getField('btn_consultar_seriales').setDisabled(true);
        let datos = { datos: {} };
        datos.datos['activosfijos_seriales_id'] = this.getField('activosfijos_seriales_id').getValue();
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosseriales', operacion: '2_1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successTbTodosPorActivosFijosSeriales_id,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage: false,
            });
        }else {
            this.alertGeneral.toggle(true,'* Verifique los datos ingresados','error');
        }
    }
    // 3.3-1 RESULTADO DEPENDIENDO EL ESTADO_P SE RENDERIZA LA TABLA
    successTbTodosPorActivosFijosSeriales_id(data) {
    
    if (data.estado_p === 200 && data.model && data.model.length >= 1) {
        
            let configCell = new Map();
            this.gridOptionsTodosPorActivosFijosSeriales_id['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud        
            configCell.set('numero_serie', { headerName: 'Número de Serie'});
            configCell.set('modelo', { headerName: 'Modelo'});
            configCell.set('imagen_url', { headerName: 'Imagen URL'});
            configCell.set('ruta_imagen', { headerName: 'Ruta Imagen'});
            configCell.set('accion', { cellRenderer: this.setButtonTbTodosPorActivosFijosSeriales_id, headerName: 'Acción', sortable: false, filter: false });
            //se carga la configuracion de la tabla agregada en el modelo adicional se agrega el configcell el cual me permite renderizar
            //botones dentro de la columna asiganada accion
            this.getField('tb_todosporactivosfijosseriales_id').initData(this.gridOptionsTodosPorActivosFijosSeriales_id,configCell);
            this.gridOptionsTodosPorActivosFijosSeriales_id.api.deselectAll();
            this.habilitarBtnAceptarSeriales();

        }else if (data.estado_p === 404) {//si no encuentra datos, no renderiza la tabla
        
            this.alertGeneral.toggle(true,'No hay registros relacionados con el Número de Serie '+this.getField('numero_serie').getValue()+' seleccionado','error');
            this.getField('tb_todosporactivosfijosseriales_id').toggle(false);
        }
    }
    // 3.3-2 RENDERIZAR BOTÓN AGREGAR, ELIMINAR Y VER IMAGEN EN LA TABLA CUANDO LA FUNCION successTbTodosPorActivosFijosSeriales_id SE VA POR EL ESTADO_P 200
    setButtonTbTodosPorActivosFijosSeriales_id(props){

            let fragment = document.createDocumentFragment();
            let buttonAgregar = this.crearBotonTablaSeriales(props.data, 'Agregar');
            let buttonEliminar = this.crearBotonTablaSeriales(props.data, 'Eliminar');
            let buttonVerImagen = this.crearBotonTablaSeriales(props.data, 'Ver Imagen');
            // buttonAgregar.setAttribute("class","buttonStyle2");
            buttonEliminar.setAttribute("class","buttonStyle2");
            buttonVerImagen.setAttribute("class","buttonStyle2");
            fragment.appendChild(buttonAgregar);
            fragment.appendChild(buttonEliminar);
            fragment.appendChild(buttonVerImagen);

            return this.createElementJaivana(fragment);
    }
      /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      **/
    // 3.3-3 Ejecución antes de Ejecutar accionProcesarSeriales dependiendo el clic dado a el botón renderizado
    crearBotonTablaSeriales(data, boton) {
        
        let button = document.createElement('input');
        switch (boton) {
            case 'Agregar':
                button.setAttribute("id", `buttonAgregar_${data.id}`);
                if (data.ruta_imagen !== 'No hay imagen relacionada') {
                    button.setAttribute("class","buttonStyleActivos");
                    button.setAttribute("disabled", true);
                }else{
                    button.setAttribute("class","buttonStyle2");
                }

                break;
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.id}`);
                if (data.ruta_imagen === 'No hay imagen relacionada') {
                    button.setAttribute("disabled", true);
                    button.setAttribute("hidden", true);
                }
                break;
            case 'Ver Imagen':
                button.setAttribute("id", `buttonVerImagen_${data.id}`);
                if (data.ruta_imagen === 'No hay imagen relacionada') {
                    button.setAttribute("disabled", true);
                    button.setAttribute("hidden", true);
                }
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesarSeriales(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }
    // 3.3.4 Aqui implemento las acciones que pueden ejecutar al oprimir los botones renderizados en la tabla
    accionProcesarSeriales(data, boton) {
        
        if (boton === 'Agregar') {

            this.archivoImagen= "";
            this.getField('activo_fijo_imagen_seriales').setValue('');
            this.getField('activo_fijo_imagen_seriales').setError(false, '');
            this.habilitarBtnAceptarSeriales();
            this.columna= data.imagen_url;
            this.id= data.activosfijos_seriales_id;
            this.clicBtnAgregarSeriales();
            


        }else if (boton === 'Eliminar') {

            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.clicBtnConfirmEliminarSeriales(data) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.refrescarUpLoadFileImagenSeriales();
            this.getField('confirmModalCustom').toggle(true);
            
        } else if (boton === 'Ver Imagen') {

            this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.refrescarUpLoadFileImagenSeriales();
            this.getField('confirmModalCustom').toggle(true);

            let url = this.constant.formConfiguration();
            let datos=`?body={"datos":{"nombre_tabla":"activosfijos_seriales","id":${data.activosfijos_seriales_id},"ruta_imagen":"${data.ruta_imagen}","imagen_url":"${data.imagen_url}"},"generales":{"tipo_servicio":"maes-activosfijosimagenes","operacion":"traerimagenurlimagenes","operacion_tipo":"consulta"}}`;
            fetch(url+datos,{
                method:'get',
                headers: new Headers({
                'Authorization': 'Bearer '+localStorage.getItem("token"),
                'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if(response.status!==200){                    
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                    return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            }).then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    if(blob.size>0){
                        const image = new File([blob], "name.jpeg", {
                            type: "image/jpeg",
                        });
                        window.open(URL.createObjectURL(image))
                    }else{
                        this.alertGeneral.toggle(true,'No hay datos','error');
                    }
                    this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
                })
                .catch(err => console.error(err));
                this.operacion="";
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    // 3.3.4-1 habilitar campos cuando se le da clic en el botón agregar,de acuerdo al registro seleccionado dentro de la tabla en este caso lo manejaremos como un servicio POST
    clicBtnAgregarSeriales(){

            this.getField('activo_fijo_imagen_seriales').setVisible(true);
            this.getField('btn_aceptar_seriales').setVisible(true);
            this.getField('btn_cancelar_seriales').setVisible(true);

    }
    /* 3.3.4-1.1 aqui valido el archivo cargado en el campo UF lo hace de forma automatica con un setOnChange sobre el campo y obteniendo las props - utilizo las funciones:
    this.validarArchivosImagenesSeriales*/
    validarArchivosImagenesSeriales(props){

        

        if (props.target.files.length >=1 ){

            if (!this.allowedExtensions.exec(props.target.files[0].name)){

                this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .jpeg/.jpg/.png','error');
                this.estadoValidarArchivosImagenes = false;
                this.getField('btn_aceptar_seriales').setDisabled(true);
                this.getField('activo_fijo_imagen_seriales').setError(true, '* Valide el archivo adjunto.');
                
            }else if (props.target.files[0].name.indexOf(' ') > 0) {

                this.alertGeneral.toggle(true, "La imagen (" + props.target.files[0].name + ") no puede contener espacios.", 'error');
                this.estadoValidarArchivosImagenes = false;
                this.getField('btn_aceptar_seriales').setDisabled(true);
                this.getField('activo_fijo_imagen_seriales').setError(true, '* Valide el archivo adjunto.');
            }else{
                this.estadoValidarArchivosImagenes = true;
            }
        }
    }
    /* 3.3.4-1.2 si los campos de la condición if pasan la validación me habilita el botón ACEPTAR de SERIALES de lo contrario el botón permanece inactivo,
    lo controlo de varias maneras para no dejar pasar errores */
    habilitarBtnAceptarSeriales(){
        
        if (this.getField('activosfijos_seriales_id').getValue() !== '' && this.getField('numero_serie').getValue() !== '' && this.getField('modelo').getValue() !== '' &&this.getField('modelo').valid() &&
        this.getField('activo_fijo_imagen_seriales').getValue() !== '' && this.getField('activo_fijo_imagen_seriales').valid()){
            
            this.getField('btn_aceptar_seriales').setDisabled(false);

        }else{

            this.getField('btn_aceptar_seriales').setDisabled(true);   
        }
    }

    validarImagenExistenteSeriales(){
        let datos={ datos: {
            value:this.archivoImagen.name
        }}    
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: '23', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarImagenExistenteSeriales,
                error: this.error_,
                general: this.generalFormatPmv
        });

    }

    successValidarImagenExistenteSeriales(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'Ya existe una imagen con este nombre. ¡Por favor verificar!', 'error');
        }else if(data.estado_p === 404){
            this.clicBtnAceptarCrearActivosFijosImagenesSeriales();

        }
    }
    /* 3.3.4-1.3 En esta funcion se consume el servicio que me actualiza la ruta_imagen desde aqui tambien toma la logica en el
    maestros.resource para subir la imagen al nextcloud */
    clicBtnAceptarCrearActivosFijosImagenesSeriales(){
        
        if (this.getField('activosfijos_seriales_id').getValue() !== '' && this.getField('numero_serie').getValue() !== '' && this.getField('modelo').getValue() !== '' &&this.getField('modelo').valid() &&
        this.getField('activo_fijo_imagen_seriales').getValue() !== '' && this.getField('activo_fijo_imagen_seriales').valid() && this.estadoValidarArchivosImagenes && this.archivoImagen) {
            
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            const data = {
                _generales: {"tipo_servicio":"maes-activosfijosimagenes","operacion":"5","operacion_tipo": "crear"},
                archivo: this.archivoImagen,
                nombre_carpeta: 'img_activos_fijos_seriales',
                nombre_columna: this.columna,
                nombre_tabla: 'activosfijos_seriales',
                id: this.id,
            }
            
            let url = this.constant.formConfiguration();
            this.generalFormat = data._generales;
            this.service.send(
                {
                    endpoint:url,
                    method: 'POST',
                    body: data,
                    success: this.successBtnAceptarCrearActivosFijosImagenesSeriales,
                    error: this.error_,
                    general: this.generalFormat,
                    formData: true,
                    showMessage: false
                }
            );
        }else if (this.estadoValidarArchivosImagenes === false) {

            this.getField('activo_fijo_imagen_seriales').setError(true, '* Valide el archivo adjunto.');
            this.habilitarBtnAceptarSeriales();
        }
    }
    // 3.3.4-1.4  Respuesta del servicio que responde al momento de actualizar el campo de la imagen adicionada en la ruta_imagen correspondiente
    successBtnAceptarCrearActivosFijosImagenesSeriales(data){
        
            if (data.estado_p === 200){
    
                this.archivoImagen= "";
                this.btnConsultarSeriales();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar la tabla visible del registro consultado
                this.refrescarUpLoadFileImagenSeriales();
                                
            }else{           
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.getField('confirmModalCustom').toggle(false);// cierra el confirmDialog despues de que responde estado_p 200 el servicio
    }
    // 3.3.4-1.5 refresar los campos visibles para cargar e insertar la imagen (activo_fijo_imagen_seriales)
    refrescarUpLoadFileImagenSeriales(){
            
            this.getField('btn_aceptar_seriales').setVisible(false);
            this.getField('btn_cancelar_seriales').setVisible(false);
            this.getField('activo_fijo_imagen_seriales').setVisible(false);
            this.getField('activo_fijo_imagen_seriales').setValue('');
            this.getField('activo_fijo_imagen_seriales').setError(false, '');
            this.archivoImagen= "";
            this.columna= "";
    }
    // 3.3.5 Confirmación para la acción del botón cancelar abriendo ventana informativa de confirmación
    confirmacionCancelarSeriales(){
    
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta acción?`)
        this.getField('confirmModalCustom').setClickDialog(() => { 
            this.btnCancelarSeriales();
            this.getField('confirmModalCustom').toggle(false); 
        });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }    
    // 3.3.5-1 Eliminar la imagen seleccionado dentro de la tabla en este caso lo manejaremos como un servicio UPDATE
    clicBtnConfirmEliminarSeriales(data) {
        
        if (data.activosfijos_seriales_id !== '') {
            let datos = { datos: {
                
                nombre_tabla : 'activosfijos_seriales',
                id : data.activosfijos_seriales_id,
                imagen_url : data.imagen_url,
                ruta_imagen : data.ruta_imagen,

            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: 'ActualizarEliminarImagen', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successBtnConfirmEliminarSeriales,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    // 3.3.5-1.1 Respuesta del servicio que responde al momento de actualizar el campo de la imagen eliminada
    successBtnConfirmEliminarSeriales(data) {

        if (data.estado_p === 200) {

            this.btnConsultarSeriales();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar la tabla visible del registro consultado
            this.getField('confirmModalCustom').toggle(false);
        }
    }
    // 3.4 Acción para el botón cancelar
    btnCancelarSeriales() {

        //Ocultar campos
        this.getField('btn_cancelar_seriales').setVisible(false);
        this.getField('btn_aceptar_seriales').setVisible(false);        
        this.getField('activo_fijo_imagen_seriales').setVisible(false);
        //Limpiar campos
        this.getField('activo_fijo_imagen_seriales').setValue('');        
        this.getField('numero_serie').setValue('');
        this.getField('modelo').setValue('');
        this.getField('activosfijos_seriales_id').setValue('');        
        
        //Borrar mensajes de error en los campos
        this.getField('activo_fijo_imagen_seriales').setError(false, '');        
        this.getField('numero_serie').setError(false, '');
        this.getField('modelo').setError(false, '');
        this.getField('activosfijos_seriales_id').setError(false, '');        
        // ocultar tabla
        this.gridOptionsTodosPorActivosFijosSeriales_id["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTodosPorActivosFijosSeriales_id);
        this.getField('tableShowData').toggle(false);
        // Restablecer variables
        this.estadoValidarArchivosImagenes= false;
        this.activo_fijo_imagen= "";
        this.archivoImagen = "";
        this.columna = "";
        this.id = 0;     
    }
    // 4.0 todo lo que voy a visualizar en el case 4 de ASIGNACIONES
    opcionAsignaciones(){
        this.getField('nombre_operacion').setValue('ASIGNACIONES');
        this.getField('nombre_operacion').setVisible(true);
        this.getField('detalle_asignacion').setVisible(true);
        this.getField('nit_tercero_asignacion').setVisible(true);
        this.getField('sede_tercero_asignacion').setVisible(true);
        this.getField('nombre_tercero_asignacion').setVisible(true);
        this.getField('btn_consultar_asignaciones').setVisible(true);
        this.getField('btn_consultar_asignaciones').setDisabled(true);
        this.getField('activosfijos_asignaciones_id').setOnChange(this.habilitarBtnConsultarAsignaciones);
        this.getField('btn_consultar_asignaciones').setClick(this.btnConsultarAsignaciones);
        this.getField('btn_aceptar_asignaciones').setClick(this.validarImagenExistenteAsignaciones);
        this.getField('btn_cancelar_asignaciones').setClick(this.confirmacionCancelarAsignaciones);

        // Para obtner el binario de las imagenes cargadas Asignaciones
        this.getField('activo_fijo_imagen_asignaciones').setOnChange((props) => {this.habilitarBtnAceptarAsignaciones(); this.validarArchivosImagenesAsignaciones(props); props.target !== '' && props.target !== undefined ? this.archivoImagen = props.target.files[0] : this.archivoImagen = '';});
        

        //habilitar campos se usa en la funcion 4.3.4-1.2 -> habilitarBtnAceptarAsignaciones
        this.getField('detalle_asignacion').setOnChange(this.habilitarBtnAceptarAsignaciones);
        this.getField('nit_tercero_asignacion').setOnChange(this.habilitarBtnAceptarAsignaciones);
        this.getField('sede_tercero_asignacion').setOnChange(this.habilitarBtnAceptarAsignaciones);
        this.getField('nombre_tercero_asignacion').setOnChange(this.habilitarBtnAceptarAsignaciones);
    
    

   }

   //visualizar opciON Básicos
   opcionBasicos(){
    this.getField('nombre_operacion').setValue('BÁSICOS');
    this.getField('nombre_operacion').setVisible(true);
    this.getField('activofijo').setVisible(true);
    this.getField('descripcion').setVisible(true);
    this.getField('descripcion').setDisabled(true);
    this.getField('consultar_basicos').setVisible(true);
    this.getField('consultar_basicos').setDisabled(true);

    this.getField('consultar_basicos').setClick(this.consultarActivos);
    this.getField('agregar_producto').setClick(this.mostrarAgregar);
    this.getField('aceptar_basicos').setClick(this.validarUnique);
    this.getField('cancelar_basicos').setClick(this.ocultarAgregar);

    // Para obtner el binario de las imagenes cargadas Asignaciones
    this.getField('imagen1_url').setOnChange((props) => {this.habilitarBtnAceptarAsignaciones(); this.validarArchivosImagenesAsignaciones(props); props.target !== '' && props.target !== undefined ? this.archivoImagen = props.target.files[0] : this.archivoImagen = '';});

    this.getField('activofijo_id').setOnChange((props) => {
        if(this.getField('activofijo_id').getValue()!=='' && this.getField('activofijo_id').getValue()!==' '){
            this.getField('consultar_basicos').setDisabled(false);
        }else{
            this.getField('consultar_basicos').setDisabled(true);
        }
    });

    this.getField('activofijo').setOnChange((props) => {
        this.ocultarCampos();
    });
    

    //habilitar campos se usa en la funcion 4.3.4-1.2 -> habilitarBtnAceptarAsignaciones



}

    ocultarCampos(){
        this.ocultarAgregar();
        this.getField('agregar_producto').setVisible(false);
        this.getField('rejilla').toggle(false);
    }

   // 4.1 habilita el botón CONSULTAR - SERIALES SI ESTA SETEADO DE FORMA CORRECTA
   habilitarBtnConsultarAsignaciones() {

    if (this.getField('activosfijos_asignaciones_id').getValue() !== '' && this.getField('detalle_asignacion').getValue() !== '' && this.getField('detalle_asignacion').valid() && this.getField('nit_tercero_asignacion').getValue() !== '' && this.getField('nit_tercero_asignacion').valid()
    && this.getField('sede_tercero_asignacion').getValue() !== '' && this.getField('sede_tercero_asignacion').valid() && this.getField('nombre_tercero_asignacion').getValue() !== '' && this.getField('nombre_tercero_asignacion').valid()) {

        this.getField('btn_consultar_asignaciones').setDisabled(false);

        } else {

            this.getField('btn_consultar_asignaciones').setDisabled(true);
            this.getField('tb_todosporactivosfijosasignaciones_id').toggle(false);
            this.getField('activo_fijo_imagen_asignaciones').setVisible(false);
            this.getField('btn_aceptar_asignaciones').setVisible(false);
            this.getField('btn_cancelar_asignaciones').setVisible(false);
            this.getField('activo_fijo_imagen_asignaciones').setValue('');
            this.getField('activo_fijo_imagen_asignaciones').setError(false, '');
        }
    }
    // 4.2 FUNCIONALIDAD PARA EL EVENTO DEL BOTON CONSULTAR - ASIGNACIONES
    btnConsultarAsignaciones() {

    if (this.getField('activosfijos_asignaciones_id').getValue() !== '' && this.getField('detalle_asignacion').getValue() !== '' &&this.getField('detalle_asignacion').valid() && this.getField('nit_tercero_asignacion').getValue() !== '' &&this.getField('nit_tercero_asignacion').valid()
    && this.getField('sede_tercero_asignacion').getValue() !== '' && this.getField('sede_tercero_asignacion').valid() && this.getField('nombre_tercero_asignacion').getValue() !== '' && this.getField('nombre_tercero_asignacion').valid()) {
  
        this.getField('btn_consultar_asignaciones').setDisabled(true);
        let datos = { datos: {} };
        datos.datos['activosfijos_asignaciones_id'] = this.getField('activosfijos_asignaciones_id').getValue();
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosasignaciones', operacion: '2_1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successTbTodosPorActivosFijosAsignaciones_id,
                error: this.error_,
                general: this.generalFormatPmv,
                //showMessage: false,
            });
        }else {
            this.alertGeneral.toggle(true,'* Verifique los datos ingresados','error');
        }
    }
    // 4.3-1 RESULTADO DEPENDIENDO EL ESTADO_P SE RENDERIZA LA TABLA
    successTbTodosPorActivosFijosAsignaciones_id(data) {
    
    if (data.estado_p === 200 && data.model && data.model.length >= 1) {
        
            let configCell = new Map();
            this.gridOptionsTodosPorActivosFijosAsignaciones_id['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud        
            configCell.set('detalle', { headerName: 'Detalle de Asignación'});
            configCell.set('fecha_asignacion', { headerName: 'Fecha de Asignación'});
            configCell.set('fecha_retiro', { headerName: 'Fecha de Retiro'});
            configCell.set('fecha_vencimiento', { headerName: 'Fecha de Vencimiento'});
            configCell.set('imagen_url', { headerName: 'Imagen URL'});
            configCell.set('ruta_imagen', { headerName: 'Ruta Imagen'});
            configCell.set('accion', { cellRenderer: this.setButtonTbTodosPorActivosFijosAsignaciones_id, headerName: 'Acción', sortable: false, filter: false });
            //se carga la configuracion de la tabla agregada en el modelo adicional se agrega el configcell el cual me permite renderizar
            //botones dentro de la columna asiganada accion
            this.getField('tb_todosporactivosfijosasignaciones_id').initData(this.gridOptionsTodosPorActivosFijosAsignaciones_id,configCell);
            this.gridOptionsTodosPorActivosFijosAsignaciones_id.api.deselectAll();
            this.habilitarBtnAceptarAsignaciones();

        }else if (data.estado_p === 404) {//si no encuentra datos, no renderiza la tabla
        
            this.alertGeneral.toggle(true,'No hay registros relacionados con el Número de Serie '+this.getField('detalle_asignacion').getValue()+' seleccionado','error');
            this.getField('tb_todosporactivosfijosasignaciones_id').toggle(false);
        }
    }
    // 4.3-2 RENDERIZAR BOTÓN AGREGAR, ELIMINAR Y VER IMAGEN EN LA TABLA CUANDO LA FUNCION successTbTodosPorActivosFijosAsignaciones_id SE VA POR EL ESTADO_P 200
    setButtonTbTodosPorActivosFijosAsignaciones_id(props){

            let fragment = document.createDocumentFragment();
            let buttonAgregar = this.crearBotonTablaAsignaciones(props.data, 'Agregar');
            let buttonEliminar = this.crearBotonTablaAsignaciones(props.data, 'Eliminar');
            let buttonVerImagen = this.crearBotonTablaAsignaciones(props.data, 'Ver Imagen');
            // buttonAgregar.setAttribute("class","buttonStyle2");
            buttonEliminar.setAttribute("class","buttonStyle2");
            buttonVerImagen.setAttribute("class","buttonStyle2");
            fragment.appendChild(buttonAgregar);
            fragment.appendChild(buttonEliminar);
            fragment.appendChild(buttonVerImagen);

            return this.createElementJaivana(fragment);
    }
      /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      **/
    // 4.3-3 Ejecución antes de Ejecutar accionProcesarAsignaciones dependiendo el clic dado a el botón renderizado
    crearBotonTablaAsignaciones(data, boton) {
        
        let button = document.createElement('input');
        switch (boton) {
            case 'Agregar':
                button.setAttribute("id", `buttonAgregar_${data.id}`);
                if (data.ruta_imagen !== 'No hay imagen relacionada') {
                    button.setAttribute("class","buttonStyleActivos");
                    button.setAttribute("disabled", true);
                }else{
                    button.setAttribute("class","buttonStyle2");
                }
                break;
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.id}`);
                if (data.ruta_imagen === 'No hay imagen relacionada') {
                    button.setAttribute("disabled", true);
                    button.setAttribute("hidden", true);
                }
                break;
            case 'Ver Imagen':
                button.setAttribute("id", `buttonVerImagen_${data.id}`);
                if (data.ruta_imagen === 'No hay imagen relacionada') {
                    button.setAttribute("disabled", true);
                    button.setAttribute("hidden", true);
                }
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesarAsignaciones(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }
    // 4.3.4 Aqui implemento las acciones que pueden ejecutar al oprimir los botones renderizados en la tabla
    accionProcesarAsignaciones(data, boton) {
        
        if (boton === 'Agregar') {

            this.archivoImagen= "";
            this.getField('activo_fijo_imagen_asignaciones').setValue('');
            this.getField('activo_fijo_imagen_asignaciones').setError(false, '');
            this.habilitarBtnAceptarAsignaciones();
            this.columna= data.imagen_url;
            this.id= data.activosfijos_asignaciones_id;
            this.clicBtnAgregarAsignaciones();
            

        }else if (boton === 'Eliminar') {

            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.clicBtnConfirmEliminarAsignaciones(data) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.refrescarUpLoadFileImagenAsignaciones();
            this.getField('confirmModalCustom').toggle(true);
            
        } else if (boton === 'Ver Imagen') {

            this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.refrescarUpLoadFileImagenAsignaciones();
            this.getField('confirmModalCustom').toggle(true);

            let url = this.constant.formConfiguration();
            let datos=`?body={"datos":{"nombre_tabla":"activosfijos_asignaciones","id":${data.activosfijos_asignaciones_id},"ruta_imagen":"${data.ruta_imagen}","imagen_url":"${data.imagen_url}"},"generales":{"tipo_servicio":"maes-activosfijosimagenes","operacion":"traerimagenurlimagenes","operacion_tipo":"consulta"}}`;
            fetch(url+datos,{
                method:'get',
                headers: new Headers({
                'Authorization': 'Bearer '+localStorage.getItem("token"),
                'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if(response.status!==200){                    
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                    return pump();
                        function pump() {
                            return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                                controller.enqueue(value);
                                return pump();
                            });
                        }
                    }
                })
            }).then(stream => new Response(stream))
                .then(response => response.blob())
                .then(blob => {
                    if(blob.size>0){
                        const image = new File([blob], "name.jpeg", {
                            type: "image/jpeg",
                        });
                        window.open(URL.createObjectURL(image))
                    }else{
                        this.alertGeneral.toggle(true,'No hay datos','error');
                    }
                    this.getField('confirmModalCustom').toggle(false); // cuando responda 200 debe ir en false
                })
                .catch(err => console.error(err));
                this.operacion="";
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }
    // 4.3.4-1 habilitar campos cuando se le da clic en el botón agregar,de acuerdo al registro seleccionado dentro de la tabla en este caso lo manejaremos como un servicio POST
    clicBtnAgregarAsignaciones(){

            this.getField('activo_fijo_imagen_asignaciones').setVisible(true);
            this.getField('btn_aceptar_asignaciones').setVisible(true);
            this.getField('btn_cancelar_asignaciones').setVisible(true);

    }
    /* 4.3.4-1.1 aqui valido el archivo cargado en el campo UF lo hace de forma automatica con un setOnChange sobre el campo y obteniendo las props - utilizo las funciones:
    this.validarArchivosImagenesAsignaciones*/
    validarArchivosImagenesAsignaciones(props){

        if (props.target.files.length >=1 ){

            if (!this.allowedExtensions.exec(props.target.files[0].name)){

                this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .jpeg/.jpg/.png','error');
                this.estadoValidarArchivosImagenes = false;
                this.getField('btn_aceptar_asignaciones').setDisabled(true);
                this.getField('activo_fijo_imagen_asignaciones').setError(true, '* Valide el archivo adjunto.');
                
            }else if (props.target.files[0].name.indexOf(' ') > 0) {

                this.alertGeneral.toggle(true, "La imagen (" + props.target.files[0].name + ") no puede contener espacios.", 'error');
                this.estadoValidarArchivosImagenes = false;
                this.getField('btn_aceptar_asignaciones').setDisabled(true);
                this.getField('activo_fijo_imagen_asignaciones').setError(true, '* Valide el archivo adjunto.');
            }else{
                this.estadoValidarArchivosImagenes = true;
            }
        }
    }
    /* 4.3.4-1.2 si los campos de la condición if pasan la validación me habilita el botón ACEPTAR de ASIGNACIONES de lo contrario el botón permanece inactivo,
    lo controlo de varias maneras para no dejar pasar errores */
    habilitarBtnAceptarAsignaciones(){
        
        if (this.getField('activosfijos_asignaciones_id').getValue() !== '' && this.getField('detalle_asignacion').getValue() !== '' && this.getField('nit_tercero_asignacion').getValue() !== '' && this.getField('nit_tercero_asignacion').valid() &&
        this.getField('sede_tercero_asignacion').getValue() !== '' && this.getField('sede_tercero_asignacion').valid() && this.getField('nombre_tercero_asignacion').getValue() !== '' && this.getField('nombre_tercero_asignacion').valid() && 
        this.getField('activo_fijo_imagen_asignaciones').getValue() !== '' && this.getField('activo_fijo_imagen_asignaciones').valid()){
            
            this.getField('btn_aceptar_asignaciones').setDisabled(false);

        }else{

            this.getField('btn_aceptar_asignaciones').setDisabled(true);   
        }
    }

    validarImagenExistenteAsignaciones(){
        let datos={ datos: {
            value:this.archivoImagen.name
        }}    
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: '22', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarImagenExistenteAsignaciones,
                error: this.error_,
                general: this.generalFormatPmv
        });

    }

    successValidarImagenExistenteAsignaciones(data){
        if (data.estado_p === 200) {
            this.alertGeneral.toggle(true, 'Ya existe una imagen con este nombre. ¡Por favor verificar!', 'error');
        }else if(data.estado_p === 404){
            this.clicBtnAceptarCrearActivosFijosImagenesAsignaciones();

        }
    }
    /* 4.3.4-1.3 En esta funcion se consume el servicio que me actualiza la ruta_imagen desde aqui tambien toma la logica en el
    maestros.resource para subir la imagen al nextcloud */
    clicBtnAceptarCrearActivosFijosImagenesAsignaciones(){
        
        if (this.getField('activosfijos_asignaciones_id').getValue() !== '' && this.getField('detalle_asignacion').getValue() !== '' && this.getField('nit_tercero_asignacion').getValue() !== '' && this.getField('nit_tercero_asignacion').valid() &&
        this.getField('sede_tercero_asignacion').getValue() !== '' && this.getField('sede_tercero_asignacion').valid() && this.getField('nombre_tercero_asignacion').getValue() !== '' && this.getField('nombre_tercero_asignacion').valid() &&
        this.getField('activo_fijo_imagen_asignaciones').getValue() !== '' && this.getField('activo_fijo_imagen_asignaciones').valid() && this.estadoValidarArchivosImagenes && this.archivoImagen) {
            
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            const data = {
                _generales: {"tipo_servicio":"maes-activosfijosimagenes","operacion":"5","operacion_tipo": "crear"},
                archivo: this.archivoImagen,
                nombre_carpeta: 'img_activos_fijos_asignaciones',
                nombre_columna: this.columna,
                nombre_tabla: 'activosfijos_asignaciones',
                id: this.id,
            }
            
            let url = this.constant.formConfiguration();
            this.generalFormat = data._generales;
            this.service.send(
                {
                    endpoint:url,
                    method: 'POST',
                    body: data,
                    success: this.successBtnAceptarCrearActivosFijosImagenesAsignaciones,
                    error: this.error_,
                    general: this.generalFormat,
                    formData: true,
                    showMessage: false
                }
            );
        }else if (this.estadoValidarArchivosImagenes === false) {

            this.getField('activo_fijo_imagen_asignaciones').setError(true, '* Valide el archivo adjunto.');
            this.habilitarBtnAceptarAsignaciones();
        }
    }
    // 4.3.4-1.4  Respuesta del servicio que responde al momento de actualizar el campo de la imagen adicionada en la ruta_imagen correspondiente
    successBtnAceptarCrearActivosFijosImagenesAsignaciones(data){
        
            if (data.estado_p === 200){
    
                this.archivoImagen= "";
                this.btnConsultarAsignaciones();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar la tabla visible del registro consultado
                this.refrescarUpLoadFileImagenAsignaciones();
                                
            }else{           
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
            this.getField('confirmModalCustom').toggle(false);// cierra el confirmDialog despues de que responde estado_p 200 el servicio
    }
    // 4.3.4-1.5 refresar los campos visibles para cargar e insertar la imagen (activo_fijo_imagen_asignaciones)
    refrescarUpLoadFileImagenAsignaciones(){
            
            this.getField('btn_aceptar_asignaciones').setVisible(false);
            this.getField('btn_cancelar_asignaciones').setVisible(false);
            this.getField('activo_fijo_imagen_asignaciones').setVisible(false);
            this.getField('activo_fijo_imagen_asignaciones').setValue('');
            this.getField('activo_fijo_imagen_asignaciones').setError(false, '');
            this.archivoImagen= "";
            this.columna= "";
    }
    // 4.3.5 Confirmación para la acción del botón cancelar abriendo ventana informativa de confirmación
    confirmacionCancelarAsignaciones(){
    
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta acción?`)
        this.getField('confirmModalCustom').setClickDialog(() => { 
            this.btnCancelarAsignaciones();
            this.getField('confirmModalCustom').toggle(false); 
        });
        this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("Confirmar"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }    
    // 4.3.5-1 Eliminar la imagen seleccionado dentro de la tabla en este caso lo manejaremos como un servicio UPDATE
    clicBtnConfirmEliminarAsignaciones(data) {
        
        if (data.activosfijos_asignaciones_id !== '') {
            let datos = { datos: {
                
                nombre_tabla : 'activosfijos_asignaciones',
                id : data.activosfijos_asignaciones_id,
                imagen_url : data.imagen_url,
                ruta_imagen : data.ruta_imagen,

            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: 'ActualizarEliminarImagen', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successBtnConfirmEliminarAsignaciones,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    // 4.3.5-1.1 Respuesta del servicio que responde al momento de actualizar el campo de la imagen eliminada
    successBtnConfirmEliminarAsignaciones(data) {

        if (data.estado_p === 200) {

            this.btnConsultarAsignaciones();//implemento nuevamente la función despues de que me responda estado_p 200 para refrescar la tabla visible del registro consultado
            this.getField('confirmModalCustom').toggle(false);
        }
    }
    // 4.4 Acción para el botón cancelar
    btnCancelarAsignaciones() {

        //Ocultar campos
        this.getField('btn_cancelar_asignaciones').setVisible(false);
        this.getField('btn_aceptar_asignaciones').setVisible(false);        
        this.getField('activo_fijo_imagen_asignaciones').setVisible(false);
        //Limpiar campos
        this.getField('activo_fijo_imagen_asignaciones').setValue('');        
        this.getField('detalle_asignacion').setValue('');
        this.getField('nit_tercero_asignacion').setValue('');
        this.getField('sede_tercero_asignacion').setValue('');
        this.getField('nombre_tercero_asignacion').setValue('');
        this.getField('activosfijos_asignaciones_id').setValue('');        
        
        //Borrar mensajes de error en los campos
        this.getField('activo_fijo_imagen_asignaciones').setError(false, '');        
        this.getField('detalle_asignacion').setError(false, '');
        this.getField('nit_tercero_asignacion').setError(false, '');
        this.getField('sede_tercero_asignacion').setError(false, '');
        this.getField('nombre_tercero_asignacion').setError(false, '');        
        this.getField('activosfijos_asignaciones_id').setError(false, '');        
        // ocultar tabla
        this.gridOptionsTodosPorActivosFijosAsignaciones_id["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTodosPorActivosFijosAsignaciones_id);
        this.getField('tableShowData').toggle(false);
        // Restablecer variables
        this.estadoValidarArchivosImagenes= false;
        this.activo_fijo_imagen= "";
        this.archivoImagen = "";
        this.columna = "";
        this.id = 0;     
    }

    consultarActivos(){
        let datos={ datos: {
            activofijo_id:this.getField('activofijo_id').getValue()
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: '1_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.sucessConsultarActivos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
      }
    
      sucessConsultarActivos(data){
        if (data.estado_p === 200 )
        {
          let configCell = new Map();
          configCell.set('archivo_url',{cellStyle: {field:"Imagen"}});
          configCell.set('accion', { cellRenderer: this.setButtonTbActivosFijosImagenes, width: 80, sortable: false, filter: false, field: 'acción' });
    
          this.getField('rejilla').toggle(true)
          this.gridOptionsComponentes['rowData'] = data.data;
          this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
          this.ocultarAgregar();
        }
        else 
        {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            this.ocultarAgregar();
            this.mostrarAgregar();
            this.getField('rejilla').toggle(false)
          }
          else {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }  
      }


      setButtonTbActivosFijosImagenes(props) {
        let fragment = document.createDocumentFragment();
        let buttonEliminar = this.crearBotonTablaImagenes(props.data, 'Eliminar');
        let buttonVerMas = this.crearBotonTablaImagenes(props.data, 'Ver más');
        buttonEliminar.setAttribute("class","buttonStyle2");
        buttonVerMas.setAttribute("class","buttonStyle2");

        fragment.appendChild(buttonEliminar);
        fragment.appendChild(buttonVerMas);
        return this.createElementJaivana(fragment);
    }

    crearBotonTablaImagenes(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.id}`);
                break;

            case 'Ver más':
                button.setAttribute("id", `buttonVerMas_${data.id}`);
                break;
            default:
                break;
        }
        button.onclick = () => { this.accionProcesarImangenes(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    accionProcesarImangenes(data, boton) {
        if (boton === 'Eliminar') {
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.clicBtnConfirmEliminarImagenes(data) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.getField('confirmModalCustom').toggle(true);
        } else if (boton === 'Ver más') {
            this.generarImagen(data.activosfijos_imagenes_id,data.imagen);
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    mostrarAgregar(){
        this.getField('agregar_producto').setVisible(true);
        this.getField('agregar_producto').setDisabled(true);
        this.getField('imagen1_url').setVisible(true);
        this.getField('aceptar_basicos').setVisible(true);
        this.getField('cancelar_basicos').setVisible(true);

    }

    ocultarAgregar(){
        this.getField('agregar_producto').setVisible(true);
        this.getField('agregar_producto').setDisabled(false);
        this.getField('imagen1_url').setVisible(false);
        this.getField('aceptar_basicos').setVisible(false);
        this.getField('cancelar_basicos').setVisible(false);

        this.getField('imagen1_url').setVisible(false);
        this.getField('imagen1_url').setValue('');
        this.getField('imagen1_url').setError(false, '');
        this.archivoImagen= "";
        this.columna= "";
        this.estadoValidarArchivosImagenes= false;

    }

    generarImagen(id,imagen){
        this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.refrescarUpLoadFileImagenGarantias();
            this.getField('confirmModalCustom').toggle(true);

            let url = this.constant.formConfiguration();
            let datos=`?body={"datos":{"nombre_tabla":"activosfijos_imagenes","id":${id},"ruta_imagen":"${imagen}","imagen_url":"${imagen}"},"generales":{"tipo_servicio":"maes-activosfijosimagenes","operacion":"traerimagenurlimagenes","operacion_tipo":"consulta"}}`;
            fetch(url+datos,{
            method:'get',
            headers: new Headers({
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json'
        })})
        .then(response => {
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                return pump();
                function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    return pump();
                    });
                }
                }
            })
            }).then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",
                    });
                    window.open(URL.createObjectURL(image))
                }else{
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
                this.getField('confirmModalCustom').toggle(false);
            })
            .catch(err => console.error(err));
            this.operacion="";
    }

    clicBtnConfirmEliminarImagenes(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.activosfijos_imagenes_id !== '') {
            let datos = { datos: {
                
                nombre_tabla : 'activosfijos_imagenes',
                id : data.activosfijos_imagenes_id,
                imagen_url : data.imagen,
                ruta_imagen : data.imagen,

            }};
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: 'ActualizarEliminarImagen', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'PUT',
                body: datos,
                success: this.successBtnConfirmEliminarImagenes,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }
    }
    // 1.3.5-1.1 Respuesta del servicio que responde al momento de actualizar el campo de la imagen eliminada
    successBtnConfirmEliminarImagenes(data) {
        if (data.estado_p === 200) {
            this.consultarActivos();
        }else 
        {
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        } 
    }

    guardarImagen(){
        if (this.estadoValidarArchivosImagenes && this.archivoImagen) {
            
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            const data = {
                _generales: {"tipo_servicio":"maes-activosfijosimagenes","operacion":"5","operacion_tipo": "crear"},
                archivo: this.archivoImagen,
                nombre_carpeta: 'img_activos_fijos_imagenes',
                nombre_columna: 'archivo_url',
                nombre_tabla: 'activosfijos_imagenes',
                id: this.getField('activofijo_id').getValue(),
            }
            
            let url = this.constant.formConfiguration();
            this.generalFormat = data._generales;
            this.service.send(
                {
                    endpoint:url,
                    method: 'POST',
                    body: data,
                    success: this.successGuardarImagen,
                    error: this.error_,
                    general: this.generalFormat,
                    formData: true,
                    showMessage: false
                }
            );
        }else if (this.estadoValidarArchivosImagenes === false) {

            this.getField('activo_fijo_imagen_asignaciones').setError(true, '* Valide el archivo adjunto.');
            this.habilitarBtnAceptarAsignaciones();
        }
    }
    // 4.3.4-1.4  Respuesta del servicio que responde al momento de actualizar el campo de la imagen adicionada en la ruta_imagen correspondiente
    successGuardarImagen(data){
        this.getField('confirmModalCustom').toggle(false);// cierra el confirmDialog despues de que responde estado_p 200 el servicio
        if (data.estado_p === 200){
            this.consultarActivos();         
        }else{           
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
}

validarUnique(data){
    let datos={ datos: {
        activofijo_id:this.getField('activofijo_id').getValue(),
        value:this.archivoImagen.name
    }};
    this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: '20', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucessValidarUnique,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
  }

  sucessValidarUnique(data){
    if (data.estado_p === 200 )
    {
        this.alertGeneral.toggle(true, 'Ya éxiste la relación activosfijos_id con archivo_url', 'error');
    }
    else 
    {
      if(data.estado_p===404) {
        this.validarImagenExistenteBasicos();
      }
      else {
        let respuesta=Object.values(data.data.errores);
        let keys=Object.keys(data.data.errores);
        this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }  
  }
  validarImagenExistenteBasicos(){
    let datos={ datos: {
        value:this.archivoImagen.name
    }}    
    this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosimagenes', operacion: '25', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successValidarImagenExistenteBasicos,
            error: this.error_,
            general: this.generalFormatPmv
    });

}

successValidarImagenExistenteBasicos(data){
    if (data.estado_p === 200) {
        this.alertGeneral.toggle(true, 'Ya existe una imagen con este nombre. ¡Por favor verificar!', 'error');
    }else if(data.estado_p === 404){
        this.guardarImagen();

    }
}

}

FormJaivana.addController('maes-activosfijosimagenes',CustomActivosFijosImagenes);
export default CustomActivosFijosImagenes;