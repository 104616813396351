import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomFacturacionElctronica
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0001
 **/
class CustomManejoBodegas extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.service_estadosino         = this.service_estadosino.bind(this);
        this.successTraerEstado         = this.successTraerEstado.bind(this);
        this.validarFecha               = this.validarFecha.bind(this);
        this.errorfecha                 = false;
        this.validarselect              = this.validarselect.bind(this);
        this.successactualizo           = this.successactualizo.bind(this);
        this.successconsulta            = this.successconsulta.bind(this);
        this.limpiar                    = this.limpiar.bind(this);
    }

    initForm(){
        console.log('Formulario CustomFacturacionElctronica,  @version: jdesk_1.01.0001, @author:Patricia López Sánchez')

        this.service_estadosino();
        this.getField('fecha_creacion').setOnChange(this.validarFecha);
        this.getField('cancelar').setClick(()=>{this.validarBtnFrm('cancelar');});
        this.getField('continuar').setClick(()=>{this.validarBtnFrm('aceptar');});
        this.getField('cancelar1').setClick(()=>{this.validarBtnFrm('cancelar1');});
        this.getField('continuar1').setClick(()=>{this.validarBtnFrm('aceptar1');});
        this.getField('cancelar2').setClick(()=>{this.validarBtnFrm('cancelar2');});
        this.getField('continuar2').setClick(()=>{this.validarBtnFrm('aceptar2');});
        this.getField('cancelar3').setClick(()=>{this.validarBtnFrm('cancelar3');});
        this.getField('continuar3').setClick(()=>{this.validarBtnFrm('aceptar3');});
        this.getField('cancelar4').setClick(()=>{this.validarBtnFrm('cancelar4');});
        this.getField('continuar4').setClick(()=>{this.validarBtnFrm('aceptar4');});
        this.getField('estadoajustes_id').setOnChange(()=>{this.validarselect('ajustes');})
        this.getField('estadotraslados_id').setOnChange(()=>{this.validarselect('traslados');})
        this.getField('estadoventas_id').setOnChange(()=>{this.validarselect('ventas');})
        this.getField('estadocompras_id').setOnChange(()=>{this.validarselect('compras');})
        this.getField('estadoconsignacion_id').setOnChange(()=>{this.validarselect('consignacion');})
        this.getField('estadotransito_id').setOnChange(()=>{this.validarselect('transito');})
        this.getField('estadoweb_id').setOnChange(()=>{this.validarselect('web');})
        this.getField('estadowms_id').setOnChange(()=>{this.validarselect('wms');})
        this.getField('TabJaivana_90').setClick(this.limpiar);
    }

    service_estadosino()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: '1', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerEstado,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerEstado(data)
    {
        if (data.estado_p === 200)
        {
            if(data.model && data.model.length>0){
             let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.estadossino_id;
             dataOp['text'] = item.estadossino_descripcion;
             dataOp['campos_cambian'] = { };
             opciones.push(dataOp); })
             this.getField('estadoajustes_id').setOptions(opciones);
             this.getField('estadotraslados_id').setOptions(opciones);
             this.getField('estadoventas_id').setOptions(opciones);
             this.getField('estadocompras_id').setOptions(opciones);
             this.getField('estadoconsignacion_id').setOptions(opciones);
             this.getField('estadotransito_id').setOptions(opciones);
             this.getField('estadoweb_id').setOptions(opciones);
             this.getField('estadowms_id').setOptions(opciones);
             this.getField('wms_nuevo').setOptions(opciones);
             this.getField('wms_externo').setOptions(opciones);

             let opciones1 = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.estadossino_siglas;
             dataOp['text'] = item.estadossino_descripcion;
             dataOp['campos_cambian'] = { };
             opciones1.push(dataOp); })
             this.getField('remoto').setOptions(opciones1);
             this.getField('wa').setOptions(opciones1);

            }
        }
        else 
        {
           if(data.estado_p===404) {
                // console.log('no hay datos...');
           }
           else {
            //   console.log(data);
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
            //   console.log('Errores:'+respuesta);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validarBtnFrm(boton) {
        switch (boton) {
            case "aceptar":
                if(this.getField('codigo_bodega').valid() && this.getField('nombre').valid() && this.getField('estadoajustes_id').valid() &&
                this.getField('ajustes').valid() && this.getField('estadotraslados_id').valid() && this.getField('traslados').valid() &&
                this.getField('estadoventas_id').valid() && this.getField('ventas').valid() && this.getField('estadocompras_id').valid() &&
                this.getField('compras').valid() && this.getField('estadoactivo_id').valid() && this.errorfecha === false){
                    this.nombre=this.getField('nombre').getValue();

                    let datos={ datos:{codigo_bodega: this.getField('codigo_bodega').getValue()}}; 
                    this.generalFormatPmv = {tipo_servicio: 'maes-manejobodegas', operacion: '20', operacion_tipo: 'consulta'};
                    this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: this.successconsulta,
                            error: this.error_,
                            general: this.generalFormatPmv
                        }
                    );
                }
                break;

            case "aceptar1":
                if(this.getField('codigo_sucursal').valid() && this.getField('nombre_sucursal').valid()){
                    this.getField('TabJaivana_90').setNextTab();
                }
                break;

            case "aceptar2":
                if(this.getField('estadoconsignacion_id').valid() && this.getField('estadotransito_id').valid() && this.getField('estadoweb_id').valid() &&
                this.getField('consignacion').valid() && this.getField('transito').valid() && this.getField('web').valid() &&
                this.getField('remoto').valid()){
                    if(this.getField('nombre3').getValue() !== ''){
                        if(this.getField('nit_buscar').valid() && this.getField('sede').valid() && this.getField('nombre3').valid()){
                            this.getField('TabJaivana_90').setNextTab();
                        }
                    }else{
                        this.getField('nit_buscar').setValue('.');
                        this.getField('nit').setValue('.');
                        this.getField('nit_buscar').valid(false);
                        this.getField('sede').setValue(0);
                        this.getField('TabJaivana_90').setNextTab();
                    }
                }
                break;

            case "aceptar3":
            if(this.getField('estadowms_id').valid() && this.getField('wms_nuevo').valid() &&
            this.getField('wms_externo').valid() && this.getField('wms').valid()){
                this.getField('TabJaivana_90').setNextTab();
            }
            break;

            case "cancelar":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    let fecha = new Date();
                    let fecha1  =   fecha.toISOString();
                    let fecha2 = fecha1.split('T')[0];

                    this.getField('codigo_bodega').setValue('');
                    this.getField('nombre').setValue('');
                    this.getField('estadoajustes_id').setValue('');
                    this.getField('ajustes').setValue('');
                    this.getField('estadotraslados_id').setValue('');
                    this.getField('traslados').setValue('');
                    this.getField('estadoventas_id').setValue('');
                    this.getField('ventas').setValue('');
                    this.getField('estadocompras_id').setValue('');
                    this.getField('compras').setValue('');
                    this.getField('estadoactivo_id').setValue('');
                    this.getField('fecha_creacion').setValue(fecha2);

                    this.getField('codigo_bodega').valid(false);
                    this.getField('nombre').valid(false);
                    this.getField('estadoajustes_id').valid(false);
                    this.getField('ajustes').valid(false);
                    this.getField('estadotraslados_id').valid(false);
                    this.getField('traslados').valid(false);
                    this.getField('estadoventas_id').valid(false);
                    this.getField('ventas').valid(false);
                    this.getField('estadocompras_id').valid(false);
                    this.getField('compras').valid(false);
                    this.getField('estadoactivo_id').valid(false);
                    this.getField('fecha_creacion').valid(false);
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); 
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`);
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "cancelar1":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.getField('codigo_sucursal').valid(false);
                    this.getField('nombre_sucursal').valid(false);

                    this.getField('codigo_sucursal').setValue('');
                    this.getField('nombre_sucursal').setValue('');
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); 
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); 
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "cancelar2":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.getField('estadoconsignacion_id').setValue('');
                    this.getField('consignacion').setValue('');
                    this.getField('estadotransito_id').setValue('');
                    this.getField('transito').setValue('');
                    this.getField('estadoweb_id').setValue('');
                    this.getField('web').setValue('');
                    this.getField('remoto').setValue('');
                    this.getField('nit_buscar').setValue('');
                    this.getField('sede').setValue('');
                    this.getField('nombre3').setValue('');

                    this.getField('estadoconsignacion_id').valid(false);
                    this.getField('consignacion').valid(false);
                    this.getField('estadotransito_id').valid(false);
                    this.getField('transito').valid(false);
                    this.getField('estadoweb_id').valid(false);
                    this.getField('web').valid(false);
                    this.getField('remoto').valid(false);
                    this.getField('nit_buscar').valid(false);
                    this.getField('sede').valid(false);
                    this.getField('nombre3').valid(false);
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); 
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); 
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "cancelar3":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.getField('estadowms_id').valid(false);
                    this.getField('wms_nuevo').valid(false);
                    this.getField('wms_externo').valid(false);
                    this.getField('wms').valid(false);

                    this.getField('estadowms_id').setValue('');
                    this.getField('wms_nuevo').setValue('');
                    this.getField('wms_externo').setValue('');
                    this.getField('wms').setValue('');
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); 
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); 
                this.getField('confirmModalCustom').toggle(true);
                break;

                case "cancelar4":
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                    this.getField('confirmModalCustom').setClickDialog(() => {
                        this.getField('clase').valid(false);
                        this.getField('tipo').valid(false);
                        this.getField('wa').valid(false);
    
                        this.getField('clase').setValue('');
                        this.getField('tipo').setValue('');
                        this.getField('wa').setValue('');
                        this.getField('confirmModalCustom').toggle(false);
                    });
                    this.getField("confirmModalCustom").setVisibleCancel(false); 
                    this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); 
                    this.getField('confirmModalCustom').toggle(true);
                    break;

            case "aceptar4":
                if (this.getField('clase').valid() && this.getField('tipo').valid() && this.getField('wa').valid()) {
                    this.datosAgregar={ datos:{}}; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
                    this.datosAgregar.datos['codigo_bodega']= this.getField('codigo_bodega').getValue();
                    this.datosAgregar.datos['nombre']= this.getField('nombre').getValue();
                    this.datosAgregar.datos['estadoajustes_id']= this.getField('estadoajustes_id').getValue();
                    this.datosAgregar.datos['ajustes']= this.getField('ajustes').getValue();
                    this.datosAgregar.datos['estadotraslados_id']= this.getField('estadotraslados_id').getValue();
                    this.datosAgregar.datos['traslados']= this.getField('traslados').getValue();
                    this.datosAgregar.datos['estadoventas_id']= this.getField('estadoventas_id').getValue();
                    this.datosAgregar.datos['ventas']= this.getField('ventas').getValue();
                    this.datosAgregar.datos['estadocompras_id']= this.getField('estadocompras_id').getValue();
                    this.datosAgregar.datos['compras']= this.getField('compras').getValue();
                    this.datosAgregar.datos['estadoactivo_id']= this.getField('estadoactivo_id').getValue();
                    this.datosAgregar.datos['fecha_creacion']= this.getField('fecha_creacion').getValue();
                    this.datosAgregar.datos['codigo_sucursal']= this.getField('codigo_sucursal').getValue();
                    this.datosAgregar.datos['estadoconsignacion_id']= this.getField('estadoconsignacion_id').getValue();
                    this.datosAgregar.datos['consignacion']= this.getField('consignacion').getValue();
                    this.datosAgregar.datos['estadotransito_id']= this.getField('estadotransito_id').getValue();
                    this.datosAgregar.datos['transito']= this.getField('transito').getValue();
                    this.datosAgregar.datos['estadoweb_id']= this.getField('estadoweb_id').getValue();
                    this.datosAgregar.datos['web']= this.getField('web').getValue();
                    this.datosAgregar.datos['remoto']= this.getField('remoto').getValue();
                    this.datosAgregar.datos['sede']= this.getField('sede').getValue();
                    this.datosAgregar.datos['estadowms_id']= this.getField('estadowms_id').getValue();
                    this.datosAgregar.datos['wms_nuevo']= this.getField('wms_nuevo').getValue();
                    this.datosAgregar.datos['wms_externo']= this.getField('wms_externo').getValue();
                    this.datosAgregar.datos['wms']= this.getField('wms').getValue();
                    this.datosAgregar.datos['clase']= this.getField('clase').getValue();
                    this.datosAgregar.datos['tipo']= this.getField('tipo').getValue();
                    this.datosAgregar.datos['wa']= this.getField('wa').getValue();
                    this.datosAgregar.datos['sucursal_id']= this.getField('sucursal_id').getValue();
                    this.datosAgregar.datos['estado']= this.getField('estado').getValue();
                    this.datosAgregar.datos['nit']= this.getField('nit').getValue();
                    
                    this.generalFormatPmv = {tipo_servicio: 'maes-manejobodegas', operacion: '5', operacion_tipo: 'crear'};
                    this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'POST',
                            body: this.datosAgregar,
                            success: this.successactualizo,
                            error: this.error_,
                            general: this.generalFormatPmv
                        }
                    );                    
                }
                break;

            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }

    validarselect(campo) {
        switch (campo) {
            case "ajustes":
                if(this.getField('estadoajustes_id').getValue() === 1){
                    this.getField('ajustes').setValue('S') 
                }else if(this.getField('estadoajustes_id').getValue() === 2){
                    this.getField('ajustes').setValue('N') 
                }else{
                    this.getField('ajustes').setValue('') 
                }
            break

            case "traslados":
                if(this.getField('estadotraslados_id').getValue() === 1){
                    this.getField('traslados').setValue('S') 
                }else if(this.getField('estadotraslados_id').getValue() === 2){
                    this.getField('traslados').setValue('N') 
                }else{
                    this.getField('traslados').setValue('') 
                }
            break

            case "ventas":
                if(this.getField('estadoventas_id').getValue() === 1){
                    this.getField('ventas').setValue('S') 
                }else if(this.getField('estadoventas_id').getValue() === 2){
                    this.getField('ventas').setValue('N') 
                }else{
                    this.getField('ventas').setValue('')
                }
            break

            case "compras":
                if(this.getField('estadocompras_id').getValue() === 1){
                    this.getField('compras').setValue('S') 
                }else if(this.getField('estadocompras_id').getValue() === 2){
                    this.getField('compras').setValue('N') 
                }else{
                    this.getField('compras').setValue('')
                }
            break

            case "consignacion":
                if(this.getField('estadoconsignacion_id').getValue() === 1){
                    this.getField('consignacion').setValue('S') 
                }else if(this.getField('estadoconsignacion_id').getValue() === 2){
                    this.getField('consignacion').setValue('N') 
                }else{
                    this.getField('consignacion').setValue('')
                }
            break

            case "transito":
                if(this.getField('estadotransito_id').getValue() === 1){
                    this.getField('transito').setValue('S') 
                }else if(this.getField('estadotransito_id').getValue() === 2){
                    this.getField('transito').setValue('N') 
                }else{
                    this.getField('transito').setValue('')
                }
            break

            case "web":
                if(this.getField('estadoweb_id').getValue() === 1){
                    this.getField('web').setValue('S') 
                }else if(this.getField('estadoweb_id').getValue() === 2){
                    this.getField('web').setValue('N') 
                }else{
                    this.getField('web').setValue('')
                }
            break

            case "wms":
                if(this.getField('estadowms_id').getValue() === 1){
                    this.getField('wms').setValue('S') 
                }else if(this.getField('estadowms_id').getValue() === 2){
                    this.getField('wms').setValue('N') 
                }else{
                    this.getField('wms').setValue('')
                }
            break
            default:
            break;
        }
    }

    validarFecha(){
        let fechadesde = new Date(this.getField('fecha_creacion').getValue());
        let fecha = new Date();
        let fecha1  =   fecha.toISOString();
        let fecha2 = fecha1.split('T')[0];
        
        let fechadesde1  =   fechadesde.toISOString();
        let fechadesde2 = fechadesde1.split('T')[0];

        if (fechadesde2 > fecha2) {
            this.getField('fecha_creacion').setError(true, "¡La fecha debe ser menor ó igual a la fecha actual!");
            this.errorfecha = true;
        } else if (fecha2 >= fechadesde2) {
            this.getField('fecha_creacion').setError(false, "");
            if (fecha2 !== '' && this.getField('fecha_creacion').getValue().replace(/\u0020/g,'') !== '') {
                let fechaDesdeFormateada = this.getField('fecha_creacion').getValue();
                let fechaHastaoFormateada = fecha;
                let fechaRango = new Date(fechaHastaoFormateada) - new Date(fechaDesdeFormateada);
                let diferencia = fechaRango / 1000 / 31 / 60 / 24;

                if (Math.sign(diferencia) === -1) {
                    diferencia = Math.round(parseInt(diferencia.toString().slice(1)));
                }

                if (diferencia > 60) {
                    this.getField('fecha_creacion').setError(true, "¡La fecha debe ser igual o inferior a 30 días a la fecha actual!");
                    this.errorfecha = true;
                }else{
                    this.errorfecha = false;
                }
            }
        }
    }

    successconsulta(data){
        if (data.estado_p === 200 )
        { 
            this.alertGeneral.toggle(true, 'La bodega '+this.getField('codigo_bodega').getValue()+' ya existe.', 'error');
        }
        else 
        {
           if(data.estado_p===404) {
                this.getField('TabJaivana_90').setNextTab();
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    successactualizo(data){
        if (data.estado_p === 200 )
        { 
            this.alertGeneral.toggle(true, 'La bodega '+this.nombre+' fue creada con éxito.', 'success');
            this.limpiar();
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    
    limpiar(){
        let fecha = new Date();
        let fecha1  =   fecha.toISOString();
        let fecha2 = fecha1.split('T')[0];
        this.getField('TabJaivana_90').reInit();
        this.getField('codigo_bodega').setValue('');
        this.getField('nombre').setValue('');
        this.getField('estadoajustes_id').setValue('');
        this.getField('ajustes').setValue('');
        this.getField('estadotraslados_id').setValue('');
        this.getField('traslados').setValue('');
        this.getField('estadoventas_id').setValue('');
        this.getField('ventas').setValue('');
        this.getField('estadocompras_id').setValue('');
        this.getField('compras').setValue('');
        this.getField('estadoactivo_id').setValue('');
        this.getField('fecha_creacion').setValue(fecha2);
        this.getField('codigo_sucursal').setValue('');
        this.getField('nombre_sucursal').setValue('');
        this.getField('estadoconsignacion_id').setValue('');
        this.getField('consignacion').setValue('');
        this.getField('estadotransito_id').setValue('');
        this.getField('transito').setValue('');
        this.getField('estadoweb_id').setValue('');
        this.getField('web').setValue('');
        this.getField('remoto').setValue('');
        this.getField('nit_buscar').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre3').setValue('');
        this.getField('estadowms_id').setValue('');
        this.getField('wms_nuevo').setValue('');
        this.getField('wms_externo').setValue('');
        this.getField('wms').setValue('');
        this.getField('clase').setValue('');
        this.getField('tipo').setValue('');
        this.getField('wa').setValue('');

        this.getField('estadoconsignacion_id').valid(false);
        this.getField('consignacion').valid(false);
        this.getField('estadotransito_id').valid(false);
        this.getField('transito').valid(false);
        this.getField('estadoweb_id').valid(false);
        this.getField('web').valid(false);
        this.getField('remoto').valid(false);
        this.getField('nit_buscar').valid(false);
        this.getField('sede').valid(false);
        this.getField('nombre3').valid(false);
        this.getField('codigo_bodega').valid(false);
        this.getField('nombre').valid(false);
        this.getField('estadoajustes_id').valid(false);
        this.getField('ajustes').valid(false);
        this.getField('estadotraslados_id').valid(false);
        this.getField('traslados').valid(false);
        this.getField('estadoventas_id').valid(false);
        this.getField('ventas').valid(false);
        this.getField('estadocompras_id').valid(false);
        this.getField('compras').valid(false);
        this.getField('estadoactivo_id').valid(false);
        this.getField('fecha_creacion').valid(false);
        this.getField('codigo_sucursal').valid(false);
        this.getField('nombre_sucursal').valid(false);
        this.getField('estadowms_id').valid(false);
        this.getField('wms_nuevo').valid(false);
        this.getField('wms_externo').valid(false);
        this.getField('wms').valid(false);  
        this.getField('clase').valid(false);
        this.getField('tipo').valid(false);
        this.getField('wa').valid(false);

    }

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
        });
    }
}
FormJaivana.addController("maes-manejobodegas",CustomManejoBodegas);
export default CustomManejoBodegas