import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @author Cristian Ciro
 * @updateBy Sebastian Rios Echeverri
 * @updated 29-05-2024
 * @updated 21-06-2024
 * @version jdesk_1.01.0002
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomShopify extends FormJaivana.form {

    constructor(props) {

        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.abrirTbPrincipal           = this.abrirTbPrincipal.bind(this);
        this.successMostrarTbPrincipal  = this.successMostrarTbPrincipal.bind(this);

        this.gridOptionsTbPrincipal     = Object.assign({},this.gridOptions);
        this.gridOptionsTbProductos     = Object.assign({},this.gridOptions);
        this.gridOptionsTbProductos2    = Object.assign({},this.gridOptions);
        this.gridOptionsTbPrincipal['onSelectionChanged']= this.onSelectionChangedRowTableMov;
        this.gridOptionsTbProductos['onSelectionChanged']= this.onSelectionChangedRowTableMov;
        this.onSelectionChangedRowTableMov = this.onSelectionChangedRowTableMov.bind(this);

        this.setButtonBuscarNit         = this.setButtonBuscarNit.bind(this);
        this.setButtonIntegrar          = this.setButtonIntegrar.bind(this);
        this.setButtonCambiar           = this.setButtonCambiar.bind(this);
        this.setButtonCiudad            = this.setButtonCiudad.bind(this);
        this.setButtonVerMas            = this.setButtonVerMas.bind(this);
        this.setButtonCrearTercero      = this.setButtonCrearTercero.bind(this);
        this.modalCrearTercero          = this.modalCrearTercero.bind(this);
        this.successVerMas              = this.successVerMas.bind(this);
        this.verMas                     = this.verMas.bind(this);

        this.datosDefecto               = {};
        this.traerDatosDefecto          = this.traerDatosDefecto.bind(this);
        this.successDatosDefecto        = this.successDatosDefecto.bind(this);
        this.successDatosNegocio        = this.successDatosNegocio.bind(this);
        this.successDatosVendedor       = this.successDatosVendedor.bind(this);
        this.traerCodigoPais            = this.traerCodigoPais.bind(this);
        this.successTraerCodigoPais     = this.successTraerCodigoPais.bind(this);
        this.DatosModal                 = this.DatosModal.bind(this);
        this.successTraerMercadoLibreProducto   = this.successTraerMercadoLibreProducto.bind(this);
        this.DatosModalNit              = this.DatosModalNit.bind(this);
        this.idRegistro                 = 0;
        this.setButtonProducto          = this.setButtonProducto.bind(this);
        this.setButtonEliminarProducto  = this.setButtonEliminarProducto.bind(this);
        this.asignarProducto            = this.asignarProducto.bind(this);
        this.agregarProducto            = this.agregarProducto.bind(this);
        this.idProducto                 = 0;
        this.contador                   = 0;
        this.sizeData                   = 0;

        this.contadorProductos          = 0;
        this.totalProductos             = 0;

        this.countTotal                 = 0;
        this.calcularDigitoNit          =   this.calcularDigitoNit.bind(this);
        this.nombre_completo            = this.nombre_completo.bind(this);
        this.asignarNit                 = this.asignarNit.bind(this);
        this.crearTercero               = this.crearTercero.bind(this);
        this.successCrearTercero                = this.successCrearTercero.bind(this);
        this.validarFecha                       = this.validarFecha.bind(this);
        this.traerDepartamentos                  =   this.traerDepartamentos.bind(this);
        this.successTraerDepartamentos           =   this.successTraerDepartamentos.bind(this);
        this.traerCiudades                       =   this.traerCiudades.bind(this);
        this.successTraerCiudades                =   this.successTraerCiudades.bind(this);
        this.nombre_usuario_tercero     = "";
        this.cambiarDocumento           = this.cambiarDocumento.bind(this);

        this.integrarPedido             = this.integrarPedido.bind(this);
        this.successIntegrarPedido      = this.successIntegrarPedido.bind(this);
        this.armarFactura               = this.armarFactura.bind(this);
        this.traerDatosCiudad           = this.traerDatosCiudad.bind(this);
        this.successDatosCiudad         = this.successDatosCiudad.bind(this);
        this.onBtNext2                  = this.onBtNext2.bind(this);
        this.onBtPrevius2               = this.onBtPrevius2.bind(this);
        this.onKeyUpText                = this.onKeyUpText.bind(this);
        this.openModalProductos         = this.openModalProductos.bind(this);
        this.eliminarProducto           = this.eliminarProducto.bind(this);
        this.enviarIntegrarPedido               = this.enviarIntegrarPedido.bind(this);
        this.traerDatosPago                     = this.traerDatosPago.bind(this);
        this.successDatosPago                   = this.successDatosPago.bind(this);
        this.setButtonPago                      = this.setButtonPago.bind(this);
        this.traerPaymentId                     = this.traerPaymentId.bind(this);
        this.successTraerPaymentId              = this.successTraerPaymentId.bind(this);

        this.n1                         = ".";
        this.n2                         = ".";
        this.a1                         = ".";
        this.a2                         = ".";

        /*
        this.recalcular                 = this.recalcular.bind(this);

        this.sqlFilterId                = this.sqlFilterId.bind(this);
        this.valuesFilterModifiedId     = this.valuesFilterModifiedId.bind(this);

        this.filterTextLoweCase         = "";

        this.filterId                   = false;
        this.filterPaqueteId            = false;

        this.mostrarMensajeGenerando    = this.mostrarMensajeGenerando.bind(this);*/
    }

    onBtNext2(){
    }

    onBtPrevius2(){
    }

    onKeyUpText(value){
    }

    initForm(){
        console.log('Formulario CustomShopify,  @version: jdesk_1.01.0002, @author:Cristian Ciro, @updateBy: Santiago Hernández N.');
        this.getField("actualizar").setVisible(false);
        this.getField("actualizar").setClick(this.abrirTbPrincipal);
        this.getField("btn_asignar_producto").setClick(this.asignarProducto);
        this.getField("btn_agregar_producto").setClick(this.agregarProducto);
        this.getField("btn_relacionar_producto").setClick(() => {this.getField("modal_items").handleClose()});
        this.getField('documento_tercero').setOnChange(()=>{this.getField('verifica_basicos').setValue('');});
        this.getField('documento_tercero').setOnBlur(()=>{this.calcularDigitoNit();});
        this.getField("btn_asignar_nit").setClick(this.asignarNit);
        this.getField("btn_crear_tercero").setClick(this.crearTercero);
        this.getField("fecha_nacimiento").setOnChange(this.validarFecha);

        this.getField('paises_id').setOnChange(this.traerDepartamentos);
        this.getField('departamento_geografico_id').setOnChange(this.traerCiudades);
        this.getField("tipo_documento_id").setOnChange(this.cambiarDocumento);

        this.getField('tabla_pedidos').setOnBtnNext(this.onBtNext2);
        this.getField('tabla_pedidos').setOnBtnPrevius(this.onBtPrevius2);
        this.getField('tabla_pedidos').setKeyUpText(this.onKeyUpText)
        
        
        /*this.getField("recalcular").setClick(this.recalcular);


        

        */
        this.traerDatosDefecto();
        this.getField("bt_consultar").setClick(() => {
            this.abrirTbPrincipal();
        });

        this.getField("consultar_desde").setOnChange(() => {
            this.getField("actualizar").setVisible(false);
            this.getField('tabla_pedidos').toggle(false);
        });
        
    } 

    abrirTbPrincipal(){
        this.getField("bt_consultar").setDisabled(true);
        this.getField("actualizar").setDisabled(true);
        this.getField('tabla_pedidos').toggle(false);
        this.getField("actualizar").setVisible(false);
        //let fechaActual = new Date();
        let fechaActual = new Date(this.getField("consultar_desde").getValue());
        let fechaActualFormateada = fechaActual.toISOString().split('T')[0];

        let fechaActual2 = new Date(this.getField("consultar_desde").getValue());
        fechaActual2.setDate(fechaActual2.getDate() + 1)
        let fechaActualFormateada2 = fechaActual2.toISOString().split('T')[0];

        let datos = { datos: {
            fecha_desde: fechaActualFormateada.replace('/','-'),
            fecha_hasta: fechaActualFormateada2.replace('/','-')
        }};
        this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '2_1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successMostrarTbPrincipal,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successMostrarTbPrincipal(data){
        this.getField("bt_consultar").setDisabled(false);
        this.getField("actualizar").setDisabled(false);
        this.getField("actualizar").setVisible(true);
        this.getField("tabla_pedidos").disabledButtons(false);
        if(data.estado_p === 200){
            this.arrayTbPrincipal = data.data;

            this.getField('tabla_pedidos').setTtotalPg(Math.ceil(data.data[0].total_reg/50)-1);

            this.getField('tabla_pedidos').toggle(true);
            this.gridOptionsTbPrincipal['rowData'] = this.arrayTbPrincipal;
            let configCell = new Map();
            configCell.set('numero_pedido',{cellRenderer:function(props){return props.data.numero_pedido},type: 'rightAligned',field:'numero_pedido'});
            configCell.set('total_compra',{cellRenderer:function(props){return''.formatoPrecio(props.data.total_compra.toFixed(2))},type: 'rightAligned',field:'total_compra'});
            configCell.set('total_descuento',{cellRenderer:function(props){return''.formatoPrecio(props.data.total_descuento.toFixed(2))},type: 'rightAligned',field:'total_descuento'});
            configCell.set('total',{cellRenderer:function(props){return''.formatoPrecio(props.data.total.toFixed(2))},type: 'rightAligned',field:'total'});
            configCell.set('total_envio',{cellRenderer:function(props){return''.formatoPrecio(props.data.total_envio.toFixed(2))},type: 'rightAligned',field:'total_envio'});
            configCell.set('total_iva',{cellRenderer:function(props){return''.formatoPrecio(props.data.total_iva!== undefined ?props.data.total_iva.toFixed(2):0.00)},type: 'rightAligned',field:'total_iva'});
            configCell.set('total_sin_iva',{cellRenderer:function(props){return''.formatoPrecio(props.data.total_sin_iva!== undefined ?props.data.total_sin_iva.toFixed(2):0.00)},type: 'rightAligned',field:'total_sin_iva'});
            configCell.set('integrar', { cellRenderer: this.setButtonIntegrar, width: 110, sortable: false, filter: false, field: 'integrar' });
            configCell.set('cliente', { cellRenderer: this.setButtonBuscarNit, width: 110, sortable: false, filter: false, field: 'cliente' });
            configCell.set('pago', { cellRenderer: this.setButtonPago, width: 110, sortable: false, filter: false, field: 'pago' });
            configCell.set('ciudad', { cellRenderer: this.setButtonCiudad, width: 110, sortable: false, filter: false, field: 'ciudad' });
            configCell.set('estado', { cellRenderer: this.setButtonVerMas, width: 110, sortable: false, filter: false, field: 'estado' });
            configCell.set('productos', { cellRenderer: this.setButtonCambiar, width: 110, sortable: false, filter: false, field: 'productos' });
            configCell.set('crear_tercero', { cellRenderer: this.setButtonCrearTercero, width: 110, sortable: false, filter: false, field: 'crear_tercero' });
            configCell.set("id",{
                filter: "agNumberColumnFilter",
                filterParams: {
                    filterOptions: ['contains'],
                    buttons: ['apply'],
                    suppressAndOrCondition: true,
                    closeOnApply: true,
                    textCustomComparator:  (filter, value, filterText) => {
                        this.filterTextLoweCase = filterText.toLowerCase();
                        return true;
                    },
                    debounceMs: 200,
                    maxNumConditions: 1,
                }
            });

            configCell.set("paquete_id",{
                filter: "agNumberColumnFilter",
                filterParams: {
                    filterOptions: ['contains'],
                    buttons: ['apply'],
                    suppressAndOrCondition: true,
                    closeOnApply: true,
                    textCustomComparator:  (filter, value, filterText) => {
                        this.filterTextLoweCase = filterText.toLowerCase();
                        return true;
                    },
                    debounceMs: 200,
                    maxNumConditions: 1,
                }
            });

            this.gridOptionsTbPrincipal.onFilterChanged = (event) => this.sqlFilterId(event);
            this.gridOptionsTbPrincipal.onFilterModified = (event) => this.valuesFilterModifiedId(event);
            this.gridOptionsTbPrincipal.enableCellTextSelection = true;

            this.getField('tabla_pedidos').initData(this.gridOptionsTbPrincipal,configCell);
            this.getField("actualizar").setDisabled(false);
            this.getField("recalcular").setDisabled(false);
            
        }else{
            this.getField('tabla_pedidos').toggle(false);
            this.getField("actualizar").setDisabled(false);
            this.getField("recalcular").setDisabled(true);
        }
        this.getField('confirmModalCustom').toggle(false);       
    }

    onSelectionChangedRowTableMov(){

    }

    setButtonIntegrar(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.setAttribute("class", "buttonStyle");

        if(props.data.estado_venta === "pending" || props.data.integrado){
            button.setAttribute('class','buttonDisabled');
        }else{
            button.onclick = ()=>{this.integrarPedido(props.data);};
        }
        
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("type", "button");
        button.setAttribute("value", "Integrar");
        return this.createElementJaivana(button);
    }

    setButtonBuscarNit(props) {
        let id = props.data.id;
        let existe_tercero = props.data.existe_tercero;
        let button = document.createElement("input");
        button.onclick = ()=>{this.getField('modal_buscar_nit').handleClickOpen(); this.DatosModalNit(id)};//this.getField('modal_items').handleClickOpen(); this.DatosModal(items)};        
        button.setAttribute("class", "buttonStyle");
        
        if(props.data.estado_venta === "cancelled" || props.data.integrado || existe_tercero){
            button.setAttribute('disabled',true);
            button.setAttribute('class','buttonDisabled');
        }

        button.setAttribute("id", 'button_search_nit_' + id);
        button.setAttribute("type", "button");
        button.setAttribute("value", "Cliente");
        return this.createElementJaivana(button);
    }

    setButtonCiudad(props) {
        let id = props.data.usuario_id;
        let direccion_entrega = props.data.direccion_entrega;
        
        let button = document.createElement("input");
        button.onclick = ()=>{this.traerDatosCiudad(id,direccion_entrega)};
        
        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ciudad");
        return this.createElementJaivana(button);
    }

    setButtonPago(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.traerDatosPago(id)};
        
        button.setAttribute("id", 'button_pago_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Pago");
        return this.createElementJaivana(button);
    }

    setButtonVerMas(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.verMas(id)};
        
        button.setAttribute("id", 'button_estado_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Estado");
        return this.createElementJaivana(button);
    }

    verMas(orden){

        let datos = { datos: {
            orden_id: parseInt(orden)
        }};
        this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '2_3', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successVerMas,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successVerMas(data){
        if(data.estado_p === 200){
            this.getField("text_estado").setValue(data.data[0].estado);
            this.getField("text_logistica").setValue(data.data[0].tipo_logistica);
            this.getField("modal_estado").handleClickOpen();
        }
    }

    setButtonCambiar(props) {
        let items = props.data.items;
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.getField('modal_items').handleClickOpen(); this.DatosModal(items, id, false)};

        button.setAttribute("id", 'button_delete_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Productos");
        return this.createElementJaivana(button);
    }

    setButtonCrearTercero(props){
        let id = props.data.id;
        let existe_tercero = props.data.existe_tercero;
        let button = document.createElement("input");
        button.onclick = ()=>{this.modalCrearTercero(props.data);};
        button.setAttribute("class", "buttonStyle");

        if(props.data.estado_venta === "cancelled" || props.data.integrado || existe_tercero){
            button.setAttribute('disabled',true);
            button.setAttribute('class','buttonDisabled');
        }
        
        button.setAttribute("id", 'button_create_tercero_' + id);
        button.setAttribute("type", "button");
        button.setAttribute("value", "Crear Tercero");
        return this.createElementJaivana(button); 
    }

    modalCrearTercero(datos){
        if(datos.nit !== undefined){
            this.alertGeneral.toggle(true,"Este registro ya tiene asignado un nit.","error");
        }else{

            this.getField("nombre_completo_tercero").setDisabled(true);
            this.getField("primer_nombre_tercero").setDisabled(false);
            this.getField("segundo_nombre_tercero").setDisabled(false);
            this.getField("primer_ape_tercero").setDisabled(false);
            this.getField("segundo_ape_tercero").setDisabled(false);

            this.idRegistro = datos.id;
            if(datos.usuario_nombre !== undefined){
                this.getField("nombre_completo_tercero").setValue(datos.usuario_nombre);
                this.nombre_usuario_tercero = datos.usuario_nombre;
            }

            this.n1 = datos.usuario_nombre1;
            this.n2 = datos.usuario_nombre2;
            this.a1 = datos.usuario_apellido1;
            this.a2 = datos.usuario_apellido2;

            this.getField("primer_nombre_tercero").setValue(datos.usuario_nombre1);
            this.getField("segundo_nombre_tercero").setValue(datos.usuario_nombre2);
            this.getField("primer_ape_tercero").setValue(datos.usuario_apellido1);
            this.getField("segundo_ape_tercero").setValue(datos.usuario_apellido2);
            

            this.getField("primer_nombre_tercero").setOnBlur(() => {this.nombre_completo("primer_nombre_tercero")});
            this.getField("segundo_nombre_tercero").setOnBlur(() => {this.nombre_completo("segundo_nombre_tercero")});
            this.getField("primer_ape_tercero").setOnBlur(() => {this.nombre_completo("primer_ape_tercero")});
            this.getField("segundo_ape_tercero").setOnBlur(() => {this.nombre_completo("segundo_ape_tercero")});

            /*this.getField("primer_nombre_tercero").setValue(".");
            this.getField("segundo_nombre_tercero").setValue(".");
            this.getField("primer_ape_tercero").setValue(".");
            this.getField("segundo_ape_tercero").setValue(".");*/

            this.getField('ciudad_id').setDisabled(true);
            this.getField('direccion').setDisabled(true);
            this.getField('departamento_geografico_id').setDisabled(true);
            this.getField('departamento_geografico_id').setError(false,'');
            this.getField('paises_id').setError(false,'');
            this.getField('ciudad_id').setError(false,'');
            this.getField('direccion').setValue(datos.usuario_direccion);
            this.getField('direccion').setError(false,'');

            this.getField("telefonos").setValue(datos.usuario_telefono);
            this.getField("email").setValue(datos.usuario_correo);
            /*this.getField("telefonos").setValue(this.datosDefecto.telefono !== undefined?this.datosDefecto.telefono:"");
            this.getField("email").setValue(this.datosDefecto.correo !== undefined?this.datosDefecto.correo:"");*/
            this.getField("codigo_vendedor").setValue(this.datosDefecto.codigo_vendedor !== undefined?this.datosDefecto.codigo_vendedor:"");
            this.getField("codigo_negocio").setValue(this.datosDefecto.codigo_negocio !== undefined?this.datosDefecto.codigo_negocio:"");
            this.getField("nombre_vendedor").setValue(this.datosDefecto.nombre_vendedor !== undefined?this.datosDefecto.nombre_vendedor:"");
            this.getField("nombre_negocio").setValue(this.datosDefecto.nombre_negocio !== undefined?this.datosDefecto.nombre_negocio:"");

            this.traerCodigoPais();
            this.getField("modal_crear_tercero").handleClickOpen();
        }
    }

    traerDatosDefecto(){
        let datos={ 
            datos: {} 
        };
        this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '2_4', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successDatosDefecto,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successDatosDefecto(data){
        if(data.estado_p === 200){
            this.datosDefecto = data.data[0];

            let datos={ 
                datos: {
                    value:this.datosDefecto.codigo_vendedor
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-vendedores', operacion: '41_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successDatosVendedor,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });

            let datos2={ 
                datos: {
                    value:this.datosDefecto.codigo_negocio
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-negocios', operacion: '41', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos2,
                    success: this.successDatosNegocio,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successDatosVendedor(data){
        if(data.estado_p === 200){
            this.datosDefecto.nombre_vendedor = data.data[0].nombre;
        }
    }

    successDatosNegocio(data){
        if(data.estado_p === 200){
            this.datosDefecto.nombre_negocio = data.data[0].nombre;
        }
    }

    traerCodigoPais(){
        //let datos = { datos: {}};
        /*this.generalFormatPmv = { tipo_servicio: 'int-mercadolibre', operacion: 'traer_codigo_pais', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerCodigoPais,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );*/
    }

    successTraerCodigoPais(data){
        if (data.estado_p === 200) {
            this.getField('paises_id').setValue(data.data.codigo_pais);
        }else{
            this.alertGeneral.toggle(true,"Error al traer codigo país.","error");
        }
    }

    DatosModal(items, id, realizaRequest){
        this.idRegistro = id;

        if(realizaRequest){
            for(let i=0; i<items.length; i++){
                let datos = {
                    datos:{
                        id_mercadolibre_producto: items[i].id,
                    }
                }
        
                this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '1_2', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: (data) => {this.successTraerMercadoLibreProducto(data,items,i)},
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                }); 
            }
        }else{

            items.forEach(item => {
                if(item.producto_jaivana !== null && item.producto_jaivana !== undefined){
                    let splitItem = item.producto_jaivana.split(" ");
                    let newItem = "";
                    let newCant = "";
                    let entra = true;
                    if(splitItem.length > 1){
                        for(let i=0; i<splitItem.length; i++){
                            if(entra){
                                newCant += splitItem[i]+",";
                            }else{
                                newItem += splitItem[i]+",";
                            }
                            entra = !entra;
                        }
        
                        newItem = newItem.substring(0, newItem.length-1);
                        newCant = newCant.substring(0, newCant.length-1);
                        item.producto_jaivana = newItem;
                        item.cantidad_producto_jaivana = newCant;
                    }else{
                        if(item.cantidad_producto_jaivana === null || item.cantidad_producto_jaivana === undefined)
                            item.cantidad_producto_jaivana="1";
                    }
                }
            });

            let configCell = new Map();
            configCell.set('producto', { cellRenderer: this.setButtonProducto, width: 110, sortable: false, filter: false, field: 'producto' });
            //this.getField('tabla_items').toggle(true);
            this.gridOptionsTbProductos['rowData'] = items;

            configCell.set('precio_unitario', {cellRenderer:function(props){return''.formatoPrecio(props.data.precio_unitario.toFixed(2))},type: 'rightAligned',field:'precio_unitario'});
            this.getField('tabla_items').initData(this.gridOptionsTbProductos,configCell);
        }
    }

    successTraerMercadoLibreProducto(data, items, index){
        let configCell = new Map();
        if(data.estado_p === 200){
            items[index].producto_jaivana = data.data[0].producto_jaivana;
            items[index].nombre_producto_jaivana = data.data[0].nombre_producto_jaivana;
        }
        configCell.set('producto', { cellRenderer: this.setButtonProducto, width: 110, sortable: false, filter: false, field: 'producto' });
        //this.getField('tabla_items').toggle(true);
        this.gridOptionsTbProductos['rowData'] = items;

        configCell.set('precio_unitario', {cellRenderer:function(props){return''.formatoPrecio(props.data.precio_unitario.toFixed(2))},type: 'rightAligned',field:'precio_unitario'});
        this.getField('tabla_items').initData(this.gridOptionsTbProductos,configCell);
    }

    DatosModalNit(id){
        this.idRegistro = id;
    }

    setButtonProducto(props) {
        let id = props.data.id;
        let button = document.createElement("input");
        button.onclick = ()=>{this.openModalProductos(props.data);};

        button.setAttribute("id", 'button_agregar_prod_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Producto");
        return this.createElementJaivana(button);
    }

    openModalProductos(data){
        let datosProductos = [];
        this.idProducto = data.id;

        if(data.producto_jaivana !== null){
            let datosCodigo = data.producto_jaivana !== null?data.producto_jaivana.split(","):[];
            let datosNombre = data.nombre_producto_jaivana !== null && data.nombre_producto_jaivana !== undefined?data.nombre_producto_jaivana.split(","):[];
            let datosCantidad = data.cantidad_producto_jaivana.split(","); 
            for(let i=0; i<datosCodigo.length; i++){
                if(datosCodigo[i] !== ""){
                    let objProducto = {};
                    objProducto.producto_jaivana = datosCodigo[i];
                    objProducto.nombre_producto_jaivana = datosNombre[i];
                    objProducto.cantidad_producto_jaivana = datosCantidad[i];
                    datosProductos.push(objProducto);
                }
            }  
        }
        this.gridOptionsTbProductos2['rowData'] = datosProductos;
        if(datosProductos.length > 0){
            let configCell = new Map();
            configCell.set('accion', { cellRenderer: this.setButtonEliminarProducto, width: 110, sortable: false, filter: false, field: 'accion' });
            //this.getField('tabla_items').toggle(true);
            this.getField('tabla_productos_jaivana').initData(this.gridOptionsTbProductos2,configCell);
        }
        this.getField("modal_buscar_producto").handleClickOpen();
    }

    setButtonEliminarProducto(props) {
        let id = props.data.nombre_producto_jaivana;
        let button = document.createElement("input");
        button.onclick = ()=>{this.eliminarProducto(id)};

        button.setAttribute("id", 'button_eliminar_prod_' + id);
        button.setAttribute("class", "buttonStyle");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        return this.createElementJaivana(button);
    }

    eliminarProducto(nombre){
        let itemsNuevosTabla = this.gridOptionsTbProductos2['rowData'];
        let nuevoArray = [];
        itemsNuevosTabla.forEach((item) => {
            if(item.nombre_producto_jaivana !== nombre){
                nuevoArray.push(item);
            }
        });

        if(nuevoArray.length === 0){
            let dataNuevo2 = {};
            dataNuevo2['producto_jaivana'] = "";
            dataNuevo2['nombre_producto_jaivana'] = "";
            dataNuevo2['cantidad_producto_jaivana'] = "";
            nuevoArray.push(dataNuevo2);
        }

        let configCell = new Map();
        configCell.set('accion', { cellRenderer: this.setButtonEliminarProducto, width: 110, sortable: false, filter: false, field: 'accion' });
        //this.getField('tabla_items').toggle(true);
        this.gridOptionsTbProductos2['rowData'] = nuevoArray;
        this.getField('tabla_productos_jaivana').initData(this.gridOptionsTbProductos2,configCell);
    }

    agregarProducto(){
        if(this.getField("nombre_articulo").valid() & this.getField("cantidad_articulo").valid()){
            let itemTabla = this.gridOptionsTbProductos2['rowData'] === undefined || this.gridOptionsTbProductos2['rowData'] === null ?[]:this.gridOptionsTbProductos2['rowData'];
            
            let dataNuevo2 = {};

            let findItem = false;

            itemTabla.forEach(item => {
                if(item.producto_jaivana === this.getField("codigo_articulo").getValue()){
                    item.cantidad_producto_jaivana = parseInt(item.cantidad_producto_jaivana) + this.getField("cantidad_articulo").getValue();
                    item.nombre_producto_jaivana = this.getField("nombre_articulo").getValue();
                    findItem = true;
                }
            });

            if(!findItem){
                dataNuevo2['producto_jaivana'] = this.getField("codigo_articulo").getValue();
                dataNuevo2['nombre_producto_jaivana'] = this.getField("nombre_articulo").getValue();
                dataNuevo2['cantidad_producto_jaivana'] = this.getField("cantidad_articulo").getValue();
                itemTabla.push(dataNuevo2);
            }

            let dataFinal = [];

            itemTabla.forEach(item => {
                if(item.producto_jaivana !== "" && item.nombre_producto_jaivana !== ""){
                    dataFinal.push(item);
                }
            });

            
            let configCell = new Map();
            configCell.set('accion', { cellRenderer: this.setButtonEliminarProducto, width: 110, sortable: false, filter: false, field: 'accion' });
            //this.getField('tabla_items').toggle(true);
            this.gridOptionsTbProductos2['rowData'] = dataFinal;
    
            this.getField('tabla_productos_jaivana').initData(this.gridOptionsTbProductos2,configCell);

            this.getField("codigo_articulo").setValue("");
            this.getField("nombre_articulo").setValue("");
            this.getField("cantidad_articulo").setValue("");
            
        }
    }

    asignarProducto(){
        let itemsNuevosTabla = this.gridOptionsTbProductos2['rowData'] === undefined?[]:this.gridOptionsTbProductos2['rowData'];
        let nombre_producto = "";
        let codigo_producto = "";
        let cantidad_producto = "";
        let continuar = true;
        itemsNuevosTabla.forEach((item) => {
            if(item.nombre_producto_jaivana !== undefined && item.nombre_producto_jaivana !== null){
                nombre_producto += item.nombre_producto_jaivana +",";
                codigo_producto += item.producto_jaivana+",";
                cantidad_producto += parseInt(item.cantidad_producto_jaivana)+",";
            }else{
                continuar = false;
            }
        });

        if(continuar){
            nombre_producto = nombre_producto.substring(0, nombre_producto.length-1);
            codigo_producto = codigo_producto.substring(0, codigo_producto.length-1);
            cantidad_producto = cantidad_producto.substring(0, cantidad_producto.length-1);
            let dataNuevo = {};
            let itemTabla = [];
            let arrayNuevo = [];
            this.arrayTbPrincipal.forEach((item) => {
                let dataItem = item;
                if(item.id === this.idRegistro){
                    item.items.forEach((prod) => {
                        if(prod.id === this.idProducto){
                            prod['producto_jaivana'] = codigo_producto;
                            prod['nombre_producto_jaivana'] = nombre_producto;
                            prod['cantidad_producto_jaivana'] = cantidad_producto;
                        }
                        //arrayNuevo2.push(dataNuevo2);
                    })
                    //dataItem['items'] = arrayNuevo2;
                    itemTabla = dataItem['items'];
                }
                arrayNuevo.push(dataItem);
            });
            dataNuevo['data'] = arrayNuevo;
            dataNuevo['estado_p'] = 200;
            this.getField("modal_buscar_producto").handleClose();
            this.DatosModal(itemTabla, this.idRegistro, false);
        }else{
            this.alertGeneral.toggle(true,"Algun producto esta incompleto.","error");
        }
    }

    calcularDigitoNit(){
        if (this.getField("documento_tercero").valid()) {
            let digito = "".calcularDigitoVerificacion(this.getField("documento_tercero").getValue());
            this.getField('verifica_basicos').setValue(digito);
        }
    }

    nombre_completo(campo){

        this.n1 = this.getField("primer_nombre_tercero").getValue().trim() === "." || this.getField("primer_nombre_tercero").getValue().trim() === ""?"":this.getField("primer_nombre_tercero").getValue().trim();
        this.n2 = this.getField("segundo_nombre_tercero").getValue().trim() === "." || this.getField("segundo_nombre_tercero").getValue().trim() === ""?"":this.getField("segundo_nombre_tercero").getValue().trim();
        this.a1 = this.getField("primer_ape_tercero").getValue().trim() === "." || this.getField("primer_ape_tercero").getValue().trim() === ""?"":this.getField("primer_ape_tercero").getValue().trim();
        this.a2 = this.getField("segundo_ape_tercero").getValue().trim() === "." || this.getField("segundo_ape_tercero").getValue().trim() === ""?"":this.getField("segundo_ape_tercero").getValue().trim();

        if(this.n2 === "" && this.n1 === "" && this.a1 === "" && this.a2 === ""){
            this.getField("nombre_completo_tercero").setValue(this.nombre_usuario_tercero);
        }else
            this.getField("nombre_completo_tercero").setValue(this.n1+" "+this.n2+" "+this.a1+" "+this.a2);
    }

    asignarNit(){
        if(this.getField("sede_nit").valid()){
            let dataNuevo = {};
            let arrayNuevo = [];
            let usuario_id = 0;
            this.arrayTbPrincipal.forEach((item) => {
                if(item.id === this.idRegistro){
                    //let dataNuevo2 = item;
                    usuario_id = item.usuario_id;
                }
            });

            this.arrayTbPrincipal.forEach((item) => {
                if(item.usuario_id === usuario_id){
                    let dataNuevo2 = item;
                    dataNuevo2['nit'] = this.getField("nitsx_sede").getValue()+"-"+this.getField("sede_nit").getValue();
                    arrayNuevo.push(dataNuevo2);
                }else
                    arrayNuevo.push(item);
            });

            dataNuevo['data'] = arrayNuevo;
            dataNuevo['estado_p'] = 200;
            this.getField("modal_buscar_nit").handleClose();
            this.successMostrarTbPrincipal(dataNuevo);
        }
    }

    crearTercero(){
        if(
            this.getField("tipo_documento_id").valid() &
            this.getField("documento_tercero").valid() &
            this.getField("primer_nombre_tercero").valid() &
            this.getField("segundo_nombre_tercero").valid() &
            this.getField("primer_ape_tercero").valid() &
            this.getField("segundo_ape_tercero").valid() &
            this.getField("nombre_completo_tercero").valid() &
            this.getField("paises_id").valid() &
            this.getField("departamento_geografico_id").valid() &
            this.getField("ciudad_id").valid() &
            this.getField("direccion").valid() &
            this.getField("telefonos").valid() &
            this.getField("email").valid() &
            this.getField("verifica_basicos").valid() &
            this.getField("sexo").valid() &
            this.getField("fecha_nacimiento").valid() &
            this.getField("nombre_vendedor").valid() &
            this.getField("codigo_vendedor").valid() &
            this.getField("nombre_negocio").valid() &
            this.getField("tipo_persona").valid() &
            this.getField("codigo_negocio").valid()
        ){
            let continuar = false;
            if(this.getField("primer_nombre_tercero").getValue() !== '.'){
                if(this.getField("primer_ape_tercero").getValue() !== '.' || this.getField("segundo_ape_tercero").getValue() !== '.'){
                    continuar = true;
                }else{
                    continuar = false;
                    this.alertGeneral.toggle(true,'Error: Debe contener mínimo un nombre y apellido.','error')
                }
            }else{
                if(this.validarFecha()){
                    continuar = true;
                }
            }
            if(continuar){
                this.getField("btn_crear_tercero").setDisabled(true);
                    let datos = {
                        datos:{
                            documento_tercero: this.getField('documento_tercero').getValue(),
                            nombre_completo_tercero: this.getField('nombre_completo_tercero').getValue(),
                            telefonos: this.getField('telefonos').getValue(),
                            email: this.getField('email').getValue(),
                            ciudad_id: this.getField('ciudad_id').getValue(),
                            departamento_geografico_id: this.getField('departamento_geografico_id').getValue(),
                            paises_id: this.getField('paises_id').getValue(),
                            direccion: this.getField('direccion').getValue(),
                            tipo_documento_id: this.getField('tipo_documento_id').getValue(),
                            verifica_basicos: this.getField('verifica_basicos').getValue(),
                            codigo_vendedor: this.getField('codigo_vendedor').getValue(),
                            primer_nombre_tercero: this.getField('primer_nombre_tercero').getValue(),
                            segundo_nombre_tercero: this.getField('segundo_nombre_tercero').getValue(),
                            primer_ape_tercero: this.getField('primer_ape_tercero').getValue(),
                            segundo_ape_tercero: this.getField('segundo_ape_tercero').getValue(),
                            fecha_nacimiento: this.getField('fecha_nacimiento').getValue(),
                            sexo: this.getField('sexo').getValue(),
                            tipo_persona: this.getField("tipo_persona").getValue(),
                            codigo_negocio: this.getField("codigo_negocio").getValue()
                        }
                    };
                    this.generalFormatPmv = { tipo_servicio: 'int-mercadolibre', operacion: '5_1', operacion_tipo: 'crear' };
                    this.service.send(
                    {
                            endpoint: this.constant.formConfiguration(),
                            method:'POST',
                            body: datos,
                            success: this.successCrearTercero,
                            error: this.error_,
                            general: this.generalFormatPmv
                    });
            }
        }
    }

    validarFecha(){
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("fecha_nacimiento").getValue());

        this.getField("fecha_nacimiento").setError(false,"");

        if(fecha_desde.getTime() === fecha_actual.getTime()){
            this.getField("fecha_nacimiento").setError(true,"No puede ser mayor o igual a la fecha actual.");
            return false;
        }

        if(fecha_desde.getTime() > fecha_actual.getTime()){
            this.getField("fecha_nacimiento").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }

        return true;
    }

    successCrearTercero(data){
        this.getField("btn_crear_tercero").setDisabled(false);
        if(data.estado_p === 200){
            let dataNuevo = {};
            let arrayNuevo = [];
            let usuario_id = 0;
            this.arrayTbPrincipal.forEach((item) => {
                if(item.id === this.idRegistro){
                    //let dataNuevo2 = item;
                    usuario_id = item.usuario_id;
                }
            });

            this.arrayTbPrincipal.forEach((item) => {
                if(item.usuario_id === usuario_id){
                    let dataNuevo2 = item;
                    dataNuevo2['nit'] = this.getField("documento_tercero").getValue()+"-1";
                    arrayNuevo.push(dataNuevo2);
                }else
                    arrayNuevo.push(item);
            });

            dataNuevo['data'] = arrayNuevo;
            dataNuevo['estado_p'] = 200;
            this.getField("modal_crear_tercero").handleClose();
            this.successMostrarTbPrincipal(dataNuevo);
        }
    }

    traerCiudades(){
        if (this.getField('paises_id').valid() && this.getField('departamento_geografico_id').valid()) {
            let datos={ 
                datos: {
                    codigo_departamento: this.getField('departamento_geografico_id').getValue()
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerCiudades,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else{
            this.getField('ciudad_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('ciudad_id').setDisabled(true);
            this.getField('direccion').setDisabled(true);
            this.getField('ciudad_id').setError(false,'');
            //this.getField('direccion').setValue('');
            //this.getField('direccion').setError(false,'');
        }
    }

    successTraerCiudades(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('ciudad_id').setDisabled(false);
            this.getField('direccion').setDisabled(false);
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.codigo_ciudad;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {codigo_ciudad: item.codigo_ciudad};
                opciones.push(dataOp);
            });
            this.getField('ciudad_id').setOptions(opciones);
            this.getField('ciudad_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un pais que se habia seleccionado con anterioridad, no se setee el mismo departamento, sino que solo liste el select con los departamentos correspondientes al país seleccionado.
            this.getField('ciudad_id').setError(false,'');
        } else {
            this.getField('ciudad_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
        }
    }


    traerDepartamentos(){
        if (this.getField('paises_id').getValue() !== '' && this.getField('paises_id').getValue() !== ' ') {
            let datos={ 
                datos: {
                    codigo_pais: this.getField('paises_id').getValue()
                } 
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-dptosgeografico', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerDepartamentos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else{
            this.getField('departamento_geografico_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('ciudad_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
            this.getField('departamento_geografico_id').setDisabled(true);
            this.getField('ciudad_id').setDisabled(true);
            this.getField('direccion').setDisabled(true);
            this.getField('departamento_geografico_id').setError(false,'');
            this.getField('ciudad_id').setError(false,'');
            //this.getField('direccion').setValue('');
            //this.getField('direccion').setError(false,'');
        }
    }

    successTraerDepartamentos(data){
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('departamento_geografico_id').setDisabled(false);
            let opciones = [{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }];
            data.data.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.codigo_departamento_geografico;
                dataOp['text'] = `${item.nombre}`;
                dataOp['campos_cambian'] = {codigo_departamento_geografico: item.codigo_departamento_geografico};
                opciones.push(dataOp);
            });
            this.getField('departamento_geografico_id').setOptions(opciones);
            this.getField('departamento_geografico_id').setValue('');// Se coloca esta línea para que cuando el usuario vuelva a seleccionar un pais que se habia seleccionado con anterioridad, no se setee el mismo departamento, sino que solo liste el select con los departamentos correspondientes al país seleccionado.
            this.getField('departamento_geografico_id').setError(false,'');
        } else {
            this.getField('departamento_geografico_id').setOptions([{ text: 'Ninguno', value: ' ', 'campos_cambian': {} }]);
        }
    }

    cambiarDocumento(){
        if(this.getField("tipo_documento_id").getValue() === "1"){
            this.getField("nombre_completo_tercero").setDisabled(false);
            this.getField("primer_nombre_tercero").setDisabled(true);
            this.getField("segundo_nombre_tercero").setDisabled(true);
            this.getField("primer_ape_tercero").setDisabled(true);
            this.getField("segundo_ape_tercero").setDisabled(true);

            this.getField("primer_nombre_tercero").setValue(".");
            this.getField("segundo_nombre_tercero").setValue(".");
            this.getField("primer_ape_tercero").setValue(".");
            this.getField("segundo_ape_tercero").setValue(".");
            this.getField("tipo_persona").setValue("J");

        }else{
            this.getField("nombre_completo_tercero").setValue(this.nombre_usuario_tercero);

            this.getField("primer_nombre_tercero").setValue(this.n1);
            this.getField("segundo_nombre_tercero").setValue(this.n2);
            this.getField("primer_ape_tercero").setValue(this.a1);
            this.getField("segundo_ape_tercero").setValue(this.a2);

            this.getField("nombre_completo_tercero").setDisabled(true);
            this.getField("primer_nombre_tercero").setDisabled(false);
            this.getField("segundo_nombre_tercero").setDisabled(false);
            this.getField("primer_ape_tercero").setDisabled(false);
            this.getField("segundo_ape_tercero").setDisabled(false);
            this.getField("tipo_persona").setValue("N");

        }
    }

    integrarPedido(data){
        this.contadorProductos = 0;
        if(data.nit !== undefined && data.nit !== null){
            let seguir2 = true;
            data.items.forEach((items) => {
                if(items.producto_jaivana !== undefined && items.producto_jaivana !== null && items.nombre_producto_jaivana !== undefined && items.nombre_producto_jaivana !== null
                    && items.producto_jaivana !== "" && items.nombre_producto_jaivana !== ""){
                    }else{
                        seguir2 = true;
                    }
            });

            if(seguir2){
                let items_productos = [];
                let seguir = true;
                //let datos = {};
                this.totalProductos = data.items.length;
                let total_discount = data.total_compra - data.total;
    
                data.items.forEach((items) => {
                    let item = {};
                    if(items.producto_jaivana !== undefined && items.producto_jaivana !== null){// && items.nombre_producto_jaivana !== undefined && items.nombre_producto_jaivana !== null
                    //){&& items.producto_jaivana !== "" && items.nombre_producto_jaivana !== ""){
    
                        let prods = items.producto_jaivana.split(",");
                        let cantidades = items.cantidad_producto_jaivana !== undefined?items.cantidad_producto_jaivana.split(","):["1"];
    
                        let precio_unit = parseFloat(items.precio_unitario)/*prods.length*/;                      
                        for(let i=0; i<prods.length; i++){
                            item = {
                                id_mercadolibre_producto:items.id,
                                nombre_mercadolibre_producto:items.nombre,
                                cantidad:parseInt(cantidades[i])*items.cantidad,
                                precio_unitario : ((precio_unit/prods.length)  + (parseFloat(data.total_envio) / (parseInt(cantidades[i]))) - ((total_discount/this.totalProductos) / prods.length)/items.cantidad)/(parseInt(cantidades[i])),
                                producto_jaivana:prods[i],
                                nombre_producto_jaivana:items.nombre_producto_jaivana !== null && items.nombre_producto_jaivana !== undefined?items.nombre_producto_jaivana.split(",")[i]:"",
                                estado_jaivana:"I"
                            }
        
                            items_productos.push(item);
                        }
                        
                        this.enviarIntegrarPedido(data,null,items_productos);
                    }else{
                        item = {
                            id_mercadolibre_producto:items.id,
                            nombre_mercadolibre_producto:items.nombre,
                            cantidad:parseInt(items.cantidad),
                            precio_unitario : parseFloat(items.precio_unitario)  + (parseFloat(data.total_envio)/this.totalProductos) - (total_discount/this.totalProductos)/items.cantidad,
                            producto_jaivana:"",
                            nombre_producto_jaivana:"",
                            estado_jaivana:"I"
                        }
    
                        items_productos.push(item);
    
                        let datos = {
                            datos:{
                                value: items.producto_jaivana,
                            }
                        }
    
                        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: '42', operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: (data2) => {this.enviarIntegrarPedido(data,data2,items_productos)},
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                        });
    
                        // Realizar Peticion
                        // this.traerProductoRelacionado
                        
    
                        //seguir = false;
                        //this.alertGeneral.toggle(true, 'Error: Falta digitar el producto a uno de los items.', 'error');
                    }
                });
                if(seguir){
    
                    
                }
            }else{
                this.alertGeneral.toggle(true, 'Error: Falta configurar algun producto.', 'error');
            }
        }else{
            this.alertGeneral.toggle(true, 'Error: Falta digitar el nit desde la columna cliente.', 'error');
        }
    }

    armarFactura(data, data2){        
        if(data2.estado_p === 200){
            let items_productos = [];
            this.totalProductos = 1;

            let datosTabla = data;
            

            let totalCompra = 0;
            let totalPago = 0;
            //let tablaRows = this.gridOptionsTbPrincipal['rowData'];
            let total_discount = data.total_compra - data.total;

            data2.data.forEach(products => {
                totalCompra += products.total_compra;
                totalPago += products.total_pago;

                products.items.forEach((items) => {
                    let item = {};
                    if(items.producto_jaivana !== undefined && items.producto_jaivana !== null){
            
                        let prods = items.producto_jaivana.split(",");
                        let cantidades = items.cantidad_producto_jaivana !== undefined ?items.cantidad_producto_jaivana.split(","):[items.cantidad];

                        let precio_unit = parseFloat(items.precio_unitario)/prods.length;

                        for(let i=0; i<prods.length; i++){
                            
                            item = {
                                id_mercadolibre_producto:items.id,
                                nombre_mercadolibre_producto:items.nombre,
                                cantidad:parseInt(cantidades[i])*items.cantidad,
                                precio_unitario : (precio_unit  + (parseFloat(data.total_envio) / (parseInt(cantidades[i])*items.cantidad)) - (total_discount/this.totalProductos))/(parseInt(cantidades[i])*items.cantidad),
                                producto_jaivana:prods[i],
                                nombre_producto_jaivana:items.nombre_producto_jaivana !== null && items.nombre_producto_jaivana !== undefined?items.nombre_producto_jaivana.split(",")[i]:"",
                                estado_jaivana:"I"
                            }
                
                            items_productos.push(item);

                        }                             
                    }
                });
            });

            datosTabla.total_compra = totalCompra;
            datosTabla.total_pago = totalPago;
            datosTabla.id = datosTabla.paquete_id;


            this.enviarIntegrarPedido(datosTabla,null,items_productos);
        }else{
            this.alertGeneral.toggle(true, 'Error en la consulta, no se puede integrar.', 'error');
        }
    }

    enviarIntegrarPedido(data,resp,items_productos){
        this.contadorProductos++;

        if(resp !== null){
            if(resp.estado_p === 200){
                items_productos[items_productos.length - 1].producto_jaivana = resp.data[0].codigo_interno;
                items_productos[items_productos.length - 1].nombre_producto_jaivana = resp.data[0].nombre;
            }/*else{
                this.contadorProductos = 0;
                this.alertGeneral.toggle(true, 'Error: Falta digitar el producto a uno de los items.', 'error');
            }*/
        }

        if(this.contadorProductos === this.totalProductos){
            let datos = {
                datos:{
                    numero_pedido_mercadolibre:(data.numero_pedido),
                    metodo_pago:(data.metodo_de_pago),
                    ciudad_entrega:(data.ciudad_entrega),
                    telefono_entrega:(data.telefono_entrega),
                    id_mercadolibre:parseInt(data.id),
                    total_compra:parseFloat(data.total),
                    total_pago :parseFloat(data.total_pago),
                    estado:data.estado_venta,
                    usuario_id:parseInt(data.usuario_id),
                    usuario_nombre:data.usuario_nombre,
                    fecha_pedido:data.fecha_pedido,
                    ciudad:"Prueba",
                    codigo_sucursal:JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal,
                    nit:data.nit.split("-")[0],
                    sede:parseInt(data.nit.split("-")[1]),
                    estado_jaivana:"I",
                    pedido_jaivana:".",
                    direccion_entrega:data.direccion_entrega,
                    items: items_productos
                }
            };
            this.traerPaymentId(datos);
            /*this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '5_2', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successIntegrarPedido,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });*/
        }
    }

    traerPaymentId(dataSer){
        let datos = { datos: {orden_id: dataSer.datos.id_mercadolibre}};
        this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '2_5', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: (data) => {this.successTraerPaymentId(data,dataSer)},
                error: this.error_,
                general: this.generalFormatPmv
        });
    }


    successTraerPaymentId(data,dataSer){
        let payment_id = '';
        if(data.estado_p === 200){
            if(data.data[0].transactions[0] === null || data.data[0].transactions[0].payment_id === undefined){
                this.alertGeneral.toggle(true,'Error al traer payment_id', 'error');
            }else{
                payment_id=data.data[0].transactions[0].payment_id;
                dataSer.datos['observacion']=payment_id;

                this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '5_2', operacion_tipo: 'crear' };
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: dataSer,
                    success: this.successIntegrarPedido,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
                });
            }                
        }else{
            this.alertGeneral.toggle(true,'Error al traer payment_id', 'error');
        }
    }

    successIntegrarPedido(data){
        if(data.estado_p === 200){
            //this.getField('tabla_pedidos').toggle(false);
            this.getField('confirmModalCustom').setTitleAndContent('PEDIDO SHOPIFY INTEGRADO', 'El pedido fue integrado exitosamente con número: '+data.data.numero_pedido+'.');
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField("confirmModalCustom").setVisibleConfirm(false);
            this.getField("confirmModalCustom").setClickDialog(()=>{
                this.getField('confirmModalCustom').toggle(false);
                this.getField('tabla_pedidos').toggle(false);
                this.abrirTbPrincipal();
                //this.getField('tabla_pedidos').toggle(false);
                //this.abrirTbPrincipal();
            });
            this.getField("confirmModalCustom").setButtonConfirm('Confirmar');
            this.getField('confirmModalCustom').toggle(true);


        }else if (data.estado_p === 502){
                this.getField('confirmModalCustom').setTitleAndContent('NO SE PUDO REALIZAR LA INTEGRACIÓN', 'Verificar por favor el pedido, los productos y el tercero.');
                this.getField("confirmModalCustom").setVisibleCancel(true);
                this.getField("confirmModalCustom").setVisibleConfirm(false);
                this.getField("confirmModalCustom").setClickDialog(()=>{
                    this.getField('confirmModalCustom').toggle(false);
                    //this.getField('tabla_pedidos').toggle(false);
                    //this.abrirTbPrincipal();
                });
                this.getField("confirmModalCustom").setButtonConfirm('Ok');
                this.getField('confirmModalCustom').toggle(true);

        }else{
            this.alertGeneral.toggle(true,data.data.errores.mensaje, 'error');
        }
    }

    traerDatosCiudad(id,direccion_entrega){
        if(direccion_entrega === null || direccion_entrega === undefined){
            this.getField("text_direccion_entrega").setValue('No registra.');
        } else {
            this.getField("text_direccion_entrega").setValue(direccion_entrega);
        } 

        let datos = { datos: {usuario_id: id}};
        this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '2_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successDatosCiudad,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDatosCiudad(data){
        if(data.estado_p === 200){
            if(data.data[0].customers[0].addresses[0] === null || data.data[0].customers[0].addresses[0] === undefined)
                this.getField("text_ciudad").setValue('No registra.');
            else
                this.getField("text_ciudad").setValue(data.data[0].customers[0].addresses[0]['city']);
        }else{
            this.getField("text_ciudad").setValue('No registra.');
        }
        this.getField('modal_ciudad').handleClickOpen()
    }

    traerDatosPago(id){
        let datos = { datos: {orden_id: id}};
        this.generalFormatPmv = { tipo_servicio: 'int-shopify', operacion: '2_5', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successDatosPago,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDatosPago(data){
        if(data.estado_p === 200){
            if(data.data[0].transactions[0] === null || data.data[0].transactions[0].payment_id === undefined)
                this.getField("text_pago").setValue('No registra.');
            else
                this.getField("text_pago").setValue(data.data[0].transactions[0].payment_id);
        }else{
            this.getField("text_pago").setValue('No registra.');
        }
        this.getField('modal_pago').handleClickOpen()
    }
}

FormJaivana.addController("int-shopify",CustomShopify);
export default CustomShopify