import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAuditorias
 * @author: Patricia Lopez Sanchez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001.1
 **/
class CustomAuditorias extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.validarfechas                  = this.validarfechas.bind(this); 
        this.clientes                       = this.clientes.bind(this); 
        this.successgenerarModal            = this.successgenerarModal.bind(this); 
        this.proveedores                    = this.proveedores.bind(this); 
        this.successgenerarModalRejilla     = this.successgenerarModalRejilla.bind(this); 
        this.gridOptionsComponentes         = Object.assign({},this.gridOptions);
        this.limpiar                        = this.limpiar.bind(this); 
        this.deletetabla                    = this.deletetabla.bind(this); 
        this.inicio=0;
        this.currencyFormattercontabilidad  = this.currencyFormattercontabilidad.bind(this); 
        this.currencyFormatterdiferencia    = this.currencyFormatterdiferencia.bind(this); 
        this.currencyFormattermodulo        = this.currencyFormattermodulo.bind(this); 
        this.formatNumberSaldo              = this.formatNumberSaldo.bind(this); 
        this.successgenerarModalproveedores = this.successgenerarModalproveedores.bind(this);
        this.successdrop                    = this.successdrop.bind(this);
        this.iniciarfecha                   = this.iniciarfecha.bind(this);
    }

    initForm() {
        console.log("Formulario CustomAuditorias,  @version: jdesk_1.01.0001.1, @author: Patricia Lopez Sanchez. @updateBy Jose Albeiro Marin");
        
        this.getField("empleados").setDisabled(true);
        this.iniciarfecha();
        this.getField("fecha_desde").setOnChange(this.validarfechas);
        this.getField("centro_costo_codigo").setOnBlur(()=>{this.getField("centro_costo_codigo").setError(false,'');});
        this.getField("nombre_cuenta_contable").setOnChange(this.limpiar);
        this.getField("centro_costo_nombre").setOnChange(this.limpiar);
        this.getField("clientes").setClick(this.clientes);
        this.getField("proveedores").setClick(this.proveedores);
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;

    }
    
    iniciarfecha(){
        let fechaActual = new Date();
        let mes =   fechaActual.getMonth() + 1;
        let anio    =   fechaActual.getFullYear();
        let fechaDia1   =   anio+"-"+mes+"-1";
        let cambioFormato = Date.parse(fechaDia1);
        let fecha = new Date(cambioFormato);
        let campoFecha  =   fecha.toISOString();
        this.getField('fecha_desde').setValue(campoFecha.split('T')[0]);
    }

    validarfechas(){
        this.limpiarfechas();
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("fecha_desde").getValue());

        this.getField("fecha_desde").setError(false,"");

        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            return false;
        }
        return true;

    }

//generarinformecliente
    clientes(){
        if(this.getField("cuenta_contable").valid()){
            if(this.getField('nombre_cuenta_contable').getValue()!==''){

                if(this.getField("centro_costo_codigo").getValue() !== '' && this.getField('centro_costo_nombre').getValue()===''){
                    this.getField('centro_costo_codigo').setError(true,'* Es necesario realizar la busqueda.');
                }else{
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
                    
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    let datos = {};
                    
                    if(this.getField("centro_costo_codigo").getValue() !==''){
                        datos={ datos: {
                            fecha_desde:this.getField('fecha_desde').getValue(),
                            cuenta_contable:this.getField("cuenta_contable").getValue(),
                            centro_costo_codigo:this.getField("centro_costo_codigo").getValue(),
                            usuario: this.codigo_usuario
                        }};
                    }else{
                        datos={ datos: {
                            fecha_desde:this.getField('fecha_desde').getValue(),
                            cuenta_contable:this.getField("cuenta_contable").getValue(),
                            centro_costo_codigo:'',
                            usuario: this.codigo_usuario
                        }};
                    }
                    
                    this.generalFormatPmv = { tipo_servicio: 'cont-especialesauditoria', operacion: 'generarinformecliente', operacion_tipo: 'consulta' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successgenerarModal,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });   
                }
            }else{
                this.getField('cuenta_contable').setError(true,'* Es necesario realizar la busqueda.');
            }
        }
    }
            
    successgenerarModal(data){
        if (data.estado_p === 200 )
        {
            this.getField("label2").setVisible(true);
            let datos = { datos: {
                usuario: this.codigo_usuario
            }};
            this.getField("rejilla").setTipoServicioOperacion("cont-especialesauditoria-auditoriaClientes");
            this.getField("rejilla").sendRequest(this.successgenerarModalRejilla, datos);
            }
        else 
        {
            if(data.estado_p===404) {
                this.getField("label2").setVisible(false);
                this.getField("label3").setVisible(false);
                this.limpiar();
                this.getField('confirmModalCustom').toggle(false);
            }
            else {
                this.getField("label2").setVisible(false);
                this.getField("label3").setVisible(false);
                this.limpiar();
                this.getField('confirmModalCustom').toggle(false);
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    successgenerarModalRejilla(data){
        if (data.estado_p === 200 )
        {
            
            this.getField("total_contabilidad").setVisible(true);
            this.getField("total_diferencia").setVisible(true);
            this.getField("total_modulo").setVisible(true);
            let contTempCont = 0;

            data.data.forEach((item)=>{
                contTempCont += item.contabilidad;
    
            });
            // console.log("contTempCont:\n",contTempCont);
            this.getField('total_contabilidad').setValue(contTempCont.toFixed(2));

            let contTempMod = 0;

            data.data.forEach((item)=>{
                contTempMod += item.modulo;
            });
            // console.log("contTempDif:\n",contTempDif);
            this.getField('total_modulo').setValue(contTempMod.toFixed(2)); 
            
            this.getField('total_diferencia').setValue(((this.getField('total_contabilidad').getValue())-(this.getField('total_modulo').getValue())).toFixed(2)); 

            this.getField('confirmModalCustom').toggle(false);
            this.inicio=1;
            this.getField("clientes").setDisabled(true);
            this.getField("proveedores").setDisabled(true);

            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            configCell.set('contabilidad',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercontabilidad});
            configCell.set('diferencia',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterdiferencia});
            configCell.set('modulo', {cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattermodulo,headerName: 'Módulo'});
            this.getField('rejilla').toggle(true);
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);

        }
        else 
        {
            if(data.estado_p===404) {
                this.getField("label2").setVisible(false);
                this.getField("label3").setVisible(false);
                this.limpiar();
                this.getField('confirmModalCustom').toggle(false);
            }
            else {
                this.getField("label2").setVisible(false);
                this.getField("label3").setVisible(false);
                this.limpiar();
                this.getField('confirmModalCustom').toggle(false);
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    formatNumberSaldo(number)
    {
        let valor=Number(number).toString().split('.');
        return valor[0]
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    currencyFormattercontabilidad(data)
    {
            let decimal = (data.data.contabilidad + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.contabilidad) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.contabilidad); }
    }

    currencyFormattermodulo(data)
    {
            let decimal = (data.data.modulo + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.modulo) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.modulo); }
    }

    currencyFormatterdiferencia(data)
    {
            let decimal = (data.data.diferencia + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.diferencia) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.diferencia); }
    }

    proveedores(){
        if(this.getField("cuenta_contable").valid()){
            if(this.getField('nombre_cuenta_contable').getValue()!==''){
                if(this.getField("centro_costo_codigo").getValue() !== '' && this.getField('centro_costo_nombre').getValue()===''){
                    this.getField('centro_costo_codigo').setError(true,'* Es necesario realizar la busqueda.');
                }else{
                    this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
            
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    let datos = {};
                    
                    if(this.getField("centro_costo_codigo").getValue() !==''){
                        datos={ datos: {
                            fecha_desde:this.getField('fecha_desde').getValue(),
                            cuenta_contable:this.getField("cuenta_contable").getValue(),
                            centro_costo_codigo:this.getField("centro_costo_codigo").getValue(),
                            usuario: this.codigo_usuario
                        }};
                    }else{
                        datos={ datos: {
                            fecha_desde:this.getField('fecha_desde').getValue(),
                            cuenta_contable:this.getField("cuenta_contable").getValue(),
                            centro_costo_codigo:'',
                            usuario: this.codigo_usuario
                        }};
                    }
                    
                    this.generalFormatPmv = { tipo_servicio: 'cont-especialesauditoria', operacion: 'generarinformeproveedor', operacion_tipo: 'consulta' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successgenerarModalproveedores,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    });
                }
            }else{
                this.getField('cuenta_contable').setError(true,'* Es necesario realizar la busqueda.');
            }
        }
    }

    successgenerarModalproveedores(data){
        if (data.estado_p === 200 )
        {
            this.getField("label3").setVisible(true);
            let datos = { datos: {
                usuario: this.codigo_usuario
            }};
            this.getField("rejilla").setTipoServicioOperacion("cont-especialesauditoria-auditoriaproveedores");
            this.getField("rejilla").sendRequest(this.successgenerarModalRejilla, datos);
        }
        else 
        {
            if(data.estado_p===404) { 
                this.getField("label2").setVisible(false);
                this.getField("label3").setVisible(false);
                this.limpiar();
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
                this.getField("label2").setVisible(false);
                this.getField("label3").setVisible(false);
                this.limpiar();
                this.getField('confirmModalCustom').toggle(false);
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    limpiar(){
        if(this.inicio>0){
            if(this.getField("centro_costo_nombre").getValue() === '' || this.getField("nombre_cuenta_contable").getValue() === ''){
                this.inicio=0;
                this.getField('rejilla').toggle(false);
                this.getField("clientes").setDisabled(false);
                this.getField("proveedores").setDisabled(false);
                this.getField("label2").setVisible(false);
                this.getField("label3").setVisible(false);
                this.getField("total_contabilidad").setVisible(false);
                this.getField("total_diferencia").setVisible(false);
                this.getField("total_modulo").setVisible(false);
                this.deletetabla();
            }
        }
    }

    limpiarfechas(){
        if(this.inicio>0){
            this.inicio=0;
            this.getField('rejilla').toggle(false);
            this.getField("clientes").setDisabled(false);
            this.getField("proveedores").setDisabled(false);
            this.getField("label2").setVisible(false);
            this.getField("label3").setVisible(false);
    
            this.deletetabla();
        }
    }

    deletetabla(){
        let datos = { datos: {
            usuario: this.codigo_usuario
        }};

        this.generalFormatPmv = { tipo_servicio: 'cont-especialesauditoria', operacion: 'eliminar', operacion_tipo: 'eliminar' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'DELETE',
            body: datos,
            success: this.successdrop,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successdrop(data){
        if (data.estado_p === 200 )
        {
            this.alertGeneral.toggle(true, data.data.mensaje, 'success');
        }
        else 
        {
          if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se pudo eliminar la tabla.', 'error');
          }
          else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
      }

}
FormJaivana.addController("cont-especialesauditoria", CustomAuditorias);
export default CustomAuditorias;