import FormJaivana from 'dashboard_jaivana_v1';
/**
 *
 * @author Santiago Hernández N.
 *
 * @version jdesk_10.0.001
 */
/**
 * Custom con funciones adicionales al Formulario
 **/
class CustomSubirArchivos extends FormJaivana.form {
    constructor(props) {
        
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.datosAgregar               = {datos:{}};
        this.datosCambiarNombre         = {datos:{}};

        this.localStorageData           = [];// Se declara esta variable que va a contener el arreglo con toda la información del usuario logueado.
        this.validarHoras               = this.validarHoras.bind(this);
        this.successValidarHoras        = this.successValidarHoras.bind(this);
        this.validarfechas              = this.validarfechas.bind(this);
        this.nombre_archivo             = "";
        this.archivoUf                  = "";
        this.suma_horas                 = 0;
        this.grabar_archivo             = this.grabar_archivo.bind(this);
        this.successData                = this.successData.bind(this);
        this.allowedExtensions          = /(.xlsx|.XLSX)$/i;
        this.cancelar_archivo           = this.cancelar_archivo.bind(this);
        this.enviar                     = false
        this.successGrabar              = this.successGrabar.bind(this);
        this.successModificarNombre     = this.successModificarNombre.bind(this);

    }   

    initForm(){
     
        console.log('Formulario CustomSubirArchivos,  @version: jdesk_10.0.001, @author:Santiago Hernández N. @update Patricia Lopez Sanchez')
        this.getField('usuario').setDisabled(true);      
        this.cancelar = false;
        this.getField('fecha_contabilizacion').setDisabled(true);
        this.getField('hora_contabilizacion').setDisabled(true);

        this.localStorageData = JSON.parse(localStorage.getItem("res"));
        this.codigo_usuario=this.localStorageData.codigo;
        this.email_usuario=this.localStorageData.email;
        this.getField('usuario').setValue(this.codigo_usuario);
        this.getField('email_envio_notificaciones').setValue(this.email_usuario);

        this.getField('nhoras').setOnChange(this.validarHoras);
        this.getField('fecha_contabilizacion').setOnChange(this.validarfechas);

        this.getField("btn_grabar").setClick(this.grabar_archivo);
        this.getField("btn_cancelar").setClick(this.cancelar_archivo);

        this.getField('archivo').setOnChange((props) => { 
            props.target.files[0] !== undefined && props.target.files[0] !== " "  ?  
            this.archivoUf = props.target.files[0] : this.archivoUf = ""; /*this.uploadFile(props);*/ 
            if(parseInt(this.getField('nhoras').getValue())===0){
                this.validarfechas();
            }else{
                this.getField("btn_grabar").setDisabled(false);
                this.getField("btn_cancelar").setDisabled(false);
            }
        });

        this.getField("btn_grabar").setDisabled(true);
        this.getField("btn_cancelar").setDisabled(true);


    }


    validarHoras(){
        this.getField('fecha_contabilizacion').setError(false, "");
        if ((this.getField('nhoras').getValue()!==null) && (this.getField('nhoras').getValue()!=="")){
            let datos = { datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-archivosconfigsubir', operacion: '1_2', operacion_tipo: 'consulta' };
              this.service.send(
              {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.successValidarHoras,
                      error: this.error_,
                      general: this.generalFormatPmv
              });
        }
    }

    successValidarHoras(data){
        
            this.suma_horas = parseInt(this.getField('nhoras').getValue())+parseInt(data.data[0].hora_sistema)
            this.hora_sistema = data.data[0].hora_sistema;

            if (parseInt(this.getField('nhoras').getValue())!==0){
                this.getField('fecha_contabilizacion').setDisabled(true);
                this.getField('hora_contabilizacion').setDisabled(true);
                this.getField("btn_grabar").setDisabled(false);
                this.getField("btn_cancelar").setDisabled(false);
            }else{
                this.cancelar = false;
                this.getField('fecha_contabilizacion').setDisabled(false);
                this.getField('hora_contabilizacion').setDisabled(false);
            }
        
    }

    validarfechas(){
        if(this.cancelar === false){
            let fecha = new Date(this.getField('fecha_contabilizacion').getValue());
            let fecha1  =   fecha.toISOString();
            let fecha2 = fecha1.split('T')[0];
            let fechadesde = new Date(); 
    
            let fechadesde1  =   fechadesde.toISOString();
            let fechadesde2 = fechadesde1.split('T')[0];
            if (fecha2 <= fechadesde2) {
                this.getField("btn_grabar").setDisabled(true);
                this.getField('fecha_contabilizacion').setError(true, "¡La fecha debe ser mayor a la fecha actual!");
                return false
            }else if (fecha2 > fechadesde2){
                this.getField('fecha_contabilizacion').setError(false, "");
                if (fechadesde !== '' && this.getField('fecha_contabilizacion').getValue().replace(/\u0020/g,'') !== '') {
                    let fechaDesdeFormateada = fechadesde;
                    let fechaHastaoFormateada = this.getField('fecha_contabilizacion').getValue();
                    let fechaRango = new Date(fechaDesdeFormateada) - new Date(fechaHastaoFormateada);
                    let diferencia = fechaRango / 1000 / 31 / 60 / 24;
                    if (Math.sign(diferencia) === -1) {
                        diferencia = Math.round(parseInt(diferencia.toString().slice(1)));
                        this.getField("btn_grabar").setDisabled(false);
                        this.getField("btn_cancelar").setDisabled(false);
                        return true;
                    }
                    if (diferencia > 60 ) {
                        this.getField('fecha_contabilizacion').setError(true, "¡La fecha debe ser menor a 31 días!");
                        this.getField("btn_grabar").setDisabled(true);
                        return false
                    }
                }
            }
        }
    }

    grabar_archivo(){
        if(this.suma_horas > 24){
            this.alertGeneral.toggle(true, 'La sumatoria de horas da ' + this.suma_horas + ', supera las 24 horas del día. Si desea escoger otro día seleccione 00:00:00', 'error');
        }else{
            this.nombre_archivo = this.archivoUf.name;
            if (this.archivoUf !== "" && this.archivoUf !== " " && this.getField("usuario").valid() && this.getField("email_envio_notificaciones").valid() && this.getField("detalle").valid() && this.getField("referencia").valid()){                
                
                if(parseInt(this.getField('nhoras').getValue())===0 && this.validarfechas()){
                    if(!this.allowedExtensions.exec(this.archivoUf.name)){
                        this.alertGeneral.toggle(true,'Solo se permiten archivos con extensión .xlsx/.XLSX','error');
                        this.enviar= true;
                    }
                    if(this.archivoUf.name.indexOf(' ') > 0){
                        this.alertGeneral.toggle(true,"El archivo ("+this.archivoUf.name+") no puede contener espacios.",'error');
                        this.enviar = true;
                    } 
                    this.getField('confirmModalCustom').setTitleAndContent('Informativo', `No recargue la página, por favor espere, se está enviando la información... `);
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    
                    if (this.enviar===false){
                        let file = this.archivoUf;
                        const data = {
                            _generales: {"tipo_servicio":"cont-archivosconfigsubir","operacion":"subirarchivo","operacion_tipo": "crear"},
                            archivo: file
                        }
                        let url = this.constant.formConfiguration();
                        this.generalFormat = data._generales;
                        this.service.send(
                            {
                                endpoint:url,
                                method: 'POST',
                                body: data,
                                success: this.successData,
                                error: this.error_,
                                general: this.generalFormat,
                                formData: true,
                                showMessage: false
                            });
                    }else{
                        this.enviar= false
                    } 
                }else if(parseInt(this.getField('nhoras').getValue()) !==0 && this.getField("nhoras").getValue() !==''){
                    if(!this.allowedExtensions.exec(this.archivoUf.name)){
                        this.alertGeneral.toggle(true,'Solo se permiten archivos con extensión .xlsx/.XLSX','error');
                        this.enviar= true;
                    }
                    if(this.archivoUf.name.indexOf(' ') > 0){
                        this.alertGeneral.toggle(true,"El archivo ("+this.archivoUf.name+") no puede contener espacios.",'error');
                        this.enviar = true;
                    } 

                    this.getField('confirmModalCustom').setTitleAndContent('Informativo', `No recargue la página, por favor espere, se está enviando la información... `);
                    this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                    this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                    this.getField('confirmModalCustom').toggle(true);
                    
                    if (this.enviar===false){
                        let file = this.archivoUf;
                        const data = {
                            _generales: {"tipo_servicio":"cont-archivosconfigsubir","operacion":"subirarchivo","operacion_tipo": "crear"},
                            archivo: file
                        }
                        let url = this.constant.formConfiguration();
                        this.generalFormat = data._generales;
                        this.service.send(
                            {
                                endpoint:url,
                                method: 'POST',
                                body: data,
                                success: this.successData,
                                error: this.error_,
                                general: this.generalFormat,
                                formData: true,
                                showMessage: false
                            });
                    }else{
                        this.enviar= false
                    } 
                }else if (this.getField("nhoras").getValue() ===''){
                    this.getField('nhoras').setError(true, 'Este campo es requerido')
                }
            }else if (this.archivoUf === undefined || this.archivoUf === " " || this.archivoUf === null || this.archivoUf === ""){
                this.alertGeneral.toggle(true,'Debe cargar el archivo','error');
            }
        }
    }

    successData(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.getField("btn_grabar").setDisabled(true);
            this.getField("btn_cancelar").setDisabled(true);
            let fechadesde = new Date();
    
            let fechadesde1  =   fechadesde.toISOString();
            let fechadesde2 = fechadesde1.split('T')[0];

            let hora_contabilizacion = String( parseFloat(this.getField('hora_contabilizacion').getValue())+ parseFloat(this.getField('nhoras').getValue())) +":"+ this.getField('hora_contabilizacion').getValue().split(':')[1];

            this.datosAgregar.datos['usuario']=this.getField('usuario').getValue();
            this.datosAgregar.datos['email_envio_notificaciones']=this.getField('email_envio_notificaciones').getValue();
            this.datosAgregar.datos['detalle']=this.getField('detalle').getValue();
            this.datosAgregar.datos['referencia']=this.getField('referencia').getValue();
            this.datosAgregar.datos['nhoras']=parseInt(this.getField('nhoras').getValue());
            this.datosAgregar.datos['fecha_contabilizacion']=this.getField('fecha_contabilizacion').getValue();
            this.datosAgregar.datos['hora_contabilizacion']=hora_contabilizacion;
            this.datosAgregar.datos['estado']='R';
            this.datosAgregar.datos['nombre_archivo']=this.nombre_archivo;
            this.datosAgregar.datos['fecha_ultima_actualizacion']=fechadesde2;
            this.datosAgregar.datos['detalle_ultima_actualizacion']=this.getField('detalle').getValue();

            let datos=this.datosAgregar;
            this.generalFormatPmv = { tipo_servicio: 'cont-archivosconfigsubir', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successGrabar,
                error: this.error_,
                general: this.generalFormatPmv
            });

        }else {
            this.getField("btn_grabar").setDisabled(true);
            this.getField("btn_cancelar").setDisabled(true);

            this.archivoUf = "";
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    successGrabar(data){

        if (data.estado_p === 200) {
            
            this.datosCambiarNombre.datos['nombre_archivo']=this.nombre_archivo;
            this.datosCambiarNombre.datos['id']=data.data[0].archivosconfiguracionsubir_id;
        
            let datos=this.datosCambiarNombre;
            this.generalFormatPmv = { tipo_servicio: 'cont-archivosconfigsubir', operacion: 'renombrararchivo', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successModificarNombre,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else{
            this.alertGeneral.toggle(true,'Error al crear el archivo en la base de datos','error');
        }
    }

    successModificarNombre(data){
        if (data.estado_p === 200) {
            this.cancelar_archivo();
        }else{
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
        
    }

    cancelar_archivo(){
        let fecha1  =   new Date().toISOString();
        let fecha2 = fecha1.split('T')[0];
        this.getField("detalle").setValue("");
        this.getField("referencia").setValue("");
        this.getField("nhoras").setValue("");
        this.cancelar = true;
        this.getField("fecha_contabilizacion").setValue(fecha2);
        this.getField("hora_contabilizacion").setValue(this.hora_sistema);
        this.getField('fecha_contabilizacion').setDisabled(true);
        this.getField('hora_contabilizacion').setDisabled(true);
        this.getField("detalle").setError(false,"");
        this.getField("referencia").setError(false,"");
        this.getField("nhoras").setError(false,"");
    }

}

FormJaivana.addController("cont-archivosconfigsubir",CustomSubirArchivos);
export default CustomSubirArchivos