import FormJaivana from 'dashboard_jaivana_v1';

class CustomRelacionConceptosDocsVendGastos extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.consultarDocsVend = this.consultarDocsVend.bind(this);
        this.succesMostrarTabla = this.succesMostrarTabla.bind(this);
        this.gridOptionsTbResumen = Object.assign({}, this.gridOptions);
        this.crearBotonesTablaItem = this.crearBotonesTablaItem.bind(this);
        this.crearBotonTabla = this.crearBotonTabla.bind(this);
        this.accionBoton = this.accionBoton.bind(this);
        this.successEliminar = this.successEliminar.bind(this);
        this.limpiarCampos = this.limpiarCampos.bind(this);
        this.uploadTable = this.uploadTable.bind(this);
        this.guardarNuevo = this.guardarNuevo.bind(this);
        this.successCrear = this.successCrear.bind(this);
        this.ocultarTabla = this.ocultarTabla.bind(this);
        this.habilitarNuevo = this.habilitarNuevo.bind(this);
        this.id_eliminar = 0;

    }

    initForm() {

        console.log('Asociar Concepto a Documentos vendedores, @version: 1.01.0002, @author:Cristian F Ciro, @updateBy:Patricia López');
        this.getField('btn_consultar').setClick(this.consultarDocsVend);
        this.getField('cancelar').setClick(this.limpiarCampos);
        this.getField('btn_guardar').setClick(this.guardarNuevo);
        this.getField('documento').setOnChange(this.ocultarTabla);
        this.getField('btn_agregar_nuevo').setClick(this.habilitarNuevo);
    }

    habilitarNuevo(){
        this.getField('btn_agregar_nuevo').setVisible(false);
        this.getField('concepto_buscador').setVisible(true);
        this.getField('descripcion_conce').setVisible(true);
        this.getField('orden').setVisible(true);
        this.getField('tarifa').setVisible(true);
        this.getField('btn_guardar').setVisible(true);
        this.getField('cancelar').setVisible(true);
        this.getField('concepto_buscador').setError(false, '');
    }

    ocultarTabla() {
        this.getField('btn_consultar').setDisabled(false);
        this.limpiarCampos();
        this.getField('btn_agregar_nuevo').setVisible(false);
        this.getField('tabla_resumen').toggle(false);
        this.getField('documento').setError(false, '');
        this.getField('concepto_buscador').setError(false,'');

    }

    consultarDocsVend() {
        if (this.getField('documento').valid() && this.getField('nombre_documento').valid()) {
            this.getField('btn_consultar').setDisabled(true);
            let body = {
                datos:{
                    vendedores_tipos_documentos_id: this.getField('id_docto').getValue()
                }
            };

            this.generalFormatPmv = { tipo_servicio: 'maes-asociarconceptosdocvend', operacion: '1_1', operacion_tipo: 'consulta'};
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: body,
                    success: this.succesMostrarTabla,
                    error: this.error_,
                    general: this.generalFormatPmv
                }
            );
        }
    }

    succesMostrarTabla(data) {
        this.limpiarCampos();

        if (data.estado_p === 200) {
            let configCell = new Map();
            //console.log('entro a mostrar tabla');
            this.gridOptionsTbResumen['rowData'] = [];
            this.getField('tabla_resumen').toggle(true);
            configCell.set('descripcion', {headerName:'Descripción'});
            configCell.set('tarifa_n', {cellRenderer:  (props) => {return "".formatoPrecio(props.data.tarifa_n); }, field:'tarifa_n'});
            configCell.set('accion', { cellRenderer: this.crearBotonesTablaItem, sortable: false, filter: false, field: "Acción", width: 244 });
            this.gridOptionsTbResumen['rowData'] = data.data;
            this.getField('tabla_resumen').initData(this.gridOptionsTbResumen, configCell);
        } else {
            this.getField('btn_agregar_nuevo').setVisible(true);
            this.getField('tabla_resumen').toggle(false);
        }
    }

    crearBotonesTablaItem(props) {
        // console.log('Estas son las props de la modal crearBotones:\n', props);
        let fragment = document.createDocumentFragment();
        let btnModificarTablaItem = this.crearBotonTabla(props.data, 'Nuevo');
        let btnEliminarTablaItem = this.crearBotonTabla(props.data, 'Eliminar');
        btnModificarTablaItem.setAttribute("class","buttonStyle2");
        btnEliminarTablaItem.setAttribute("class","buttonStyle2");
        fragment.appendChild(btnModificarTablaItem);
        fragment.appendChild(btnEliminarTablaItem);
        return this.createElementJaivana(fragment);
    }

    crearBotonTabla(data, boton) {
        // console.log('este es el data que quiero ver: ', data);
        let button = document.createElement('input');
        switch (boton) {
            case 'Nuevo':
                button.setAttribute("id", `boton_modificar_cotizaciones_${data.id}`);
                break;
            case 'Eliminar':
                button.setAttribute("id", `boton_eliminar_tbItem_${data.id}`);
                break;
            default:
                break;
        }
        button.onclick = () => { this.accionBoton(data, boton) };
        if (boton === 'Eliminar') {
            button.setAttribute("value", 'Eliminar');
        } else if (boton === 'Nuevo') {
            button.setAttribute("value", 'Nuevo');
        } else {
            button.setAttribute("value", boton);
        }
        button.setAttribute("type", "button");
        return button;
    }

    accionBoton(data, boton) {
        if (boton === 'Nuevo') {
            this.getField('concepto_buscador').setVisible(true);
            this.getField('descripcion_conce').setVisible(true);
            this.getField('orden').setVisible(true);
            this.getField('tarifa').setVisible(true);
            this.getField('btn_guardar').setVisible(true);
            this.getField('cancelar').setVisible(true);
            this.getField('concepto_buscador').setError(false, '');
            //this.eliminarItemTablaItem(data, 'Eliminar');
        }
        if (boton === 'Eliminar') {
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', `Desea realizar esta operación?`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.confirmDelete(data); });
            //this.getField('confirmModalCustom').setClickDialog(this.confirmDeleteCustom);
            this.getField("confirmModalCustom").setVisibleCancel(false); // "false" Para mostrar el botón de cancelar en los ConfirmDialog
            //this.getField("confirmModalCustom").setButtonConfirm(`${boton}`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    confirmDelete(data) {
        this.getField('confirmModalCustom').toggle(false);
        //console.log('hace la peticion de eliminar', data.id);
        let body = {
            datos: {
                vendedores_gastos_conceptos_documentos_relacion_id: data.id
            }
        };

        this.generalFormatPmv = { tipo_servicio: 'maes-asociarconceptosdocvend', operacion: '7', operacion_tipo: 'eliminar'};

        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: "DELETE",
            body: body,
            success: this.successEliminar,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage :false
        });
    }

    successEliminar(data) {
        if(data.estado_p === 200){
            this.uploadTable();

        }else{
            this.alertGeneral.toggle(true,'No es posible borrarlo ya que está asociado a un registro de otro formulario. ','error');
        }
        //console.log('entro a success eliminar');
        this.limpiarCampos();
    }

    uploadTable() {
        this.consultarDocsVend();
    }

    guardarNuevo() {
        let seguir = false;
        let seguirOrden = false;

        if (this.getField('tarifa').valid()) {//&& !isNaN(this.getField('tarifa').getValue())
            seguir = true;
        } else {
            seguir = false;
        }

        if (this.getField('orden').valid()) {
            seguirOrden = true;
        } else {
            seguirOrden = false;
        }

        if (seguir && seguirOrden) {

            if (this.getField('concepto_buscador').valid() && this.getField('descripcion_conce').valid()) {
                let body = {
                    datos:{
                        vendedores_tipos_documentos_id: this.getField('id_docto').getValue(),
                        conceptos_documentos_vendedores_gastos_id: this.getField('id_concep').getValue(),
                        orden_n: parseInt(this.getField('orden').getValue()),
                        tarifa_n: parseFloat(this.getField('tarifa').getValue()),
                    }
                };

                this.generalFormatPmv = { tipo_servicio: 'maes-asociarconceptosdocvend', operacion: '5', operacion_tipo: 'crear'};
 
                this.service.send({
                    endpoint: this.constant.formConfiguration(),
                    method: "POST",
                    body: body,
                    success: this.successCrear,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage :false
                }); 
            }
        }
    }

    successCrear(data) {
        if (data.estado_p === 200) {
            this.uploadTable();
            this.limpiarCampos();
        }else{
            this.alertGeneral.toggle(true,'Ya existe un concepto con el mismo código asociado al documento. ','error');
        }
    }

    limpiarCampos() {
        this.getField('concepto_buscador').setVisible(false);
        this.getField('descripcion_conce').setVisible(false);
        this.getField('orden').setVisible(false);
        this.getField('tarifa').setVisible(false);
        this.getField('cancelar').setVisible(false);
        this.getField('btn_guardar').setVisible(false);
        this.getField('concepto_buscador').setValue('');
        this.getField('descripcion_conce').setValue('');
        this.getField('orden').setValue('');
        this.getField('tarifa').setValue(0);
        this.getField('concepto_buscador').setError(false, '');
        this.getField('descripcion_conce').setError(false, '');
        this.getField('orden').setError(false, '');
        this.getField('tarifa').setError(false, '');
    }
}

FormJaivana.addController('maes-asociarconceptosdocvend', CustomRelacionConceptosDocsVendGastos);
export default CustomRelacionConceptosDocsVendGastos;