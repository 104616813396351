import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: Custom con funciones adicionales al formulario con código: inve-listadocodigoalterno, ID Formulario: 236, Sección: padre: Inventario(id: 38)- hijo: Informes(id: 46)
 * @author: Sebastian Rios Echeverri
 * @version: jdesk_1.01.0002
 **/
class CustomListadoCodigosAlternos extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                               = this.initForm.bind(this);
        // Variables de tablas:
        this.gridOptionsLsCodAlter                  = Object.assign({}, this.gridOptions);

        // Variables Globales:
        this.sucursal_ingreso                       = '';
        this.operacion_actual                       = '';
        this.archivo_servicio                       = 'inve-listadocodigoalterno';
        this.numero_decimales_codalternos           = 2;
        this.nombre_archivo                         = 'ListadoCodigosAlternos';
        this.arrayFinal                             = [];

        // Funciones:
        this.currencyFormatterGeneral               = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                      = this.formatNumberSaldo.bind(this);
        this.mostrarMensajeGenerando                = this.mostrarMensajeGenerando.bind(this);
        this.abrirImprimir                          = this.abrirImprimir.bind(this);
        this.habilitarCorreo                        = this.habilitarCorreo.bind(this);
        this.deshabilitarCorreo                     = this.deshabilitarCorreo.bind(this);
        this.procesar                               = this.procesar.bind(this);
        this.enviarCorreoTerceros                   = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreo                    = this.successEnviarCorreo.bind(this);
        this.generarPdfTabla3                       = this.generarPdfTabla3.bind(this);
        this.genearModalGlobales                    = this.genearModalGlobales.bind(this);
        this.successGenearModalGlobales             = this.successGenearModalGlobales.bind(this);
        this.generarExcelTabla3                     = this.generarExcelTabla3.bind(this);
        this.isJson                                 = this.isJson.bind(this);
        this.mostrarMensajeNoHayDatos               = this.mostrarMensajeNoHayDatos.bind(this);
        this.mostrarMensajeEmailEnviado             = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo               = this.mostrarMensajeEmailFallo.bind(this);
        this.codigosAlternos                        = this.codigosAlternos.bind(this);
        this.successCodigosAlternos                 = this.successCodigosAlternos.bind(this);
        this.habilitarCamposFrm                     = this.habilitarCamposFrm.bind(this);
        this.armarArray                             = this.armarArray.bind(this);
        this.codigoFieldSelection                   = this.codigoFieldSelection.bind(this);
        this.nombreFieldSelection                   = this.nombreFieldSelection.bind(this);
        this.cantidadFieldSelection                 = this.cantidadFieldSelection.bind(this);
        this.costoFieldSelection                    = this.costoFieldSelection.bind(this);
        this.precioFieldSelection                   = this.precioFieldSelection.bind(this);
    }


    initForm() {
        //Versión de CUSTOM:
        console.log('Formulario CustomListadoCodigosAlternos,  @version: jdesk_1.01.0002, @author: Sebastian Rios Echeverri');
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

        this.habilitarCamposFrm();
        
        //para balances globales
        this.getField('ch_excel').setOnChange(() => {
            if (this.getField('ch_excel').getValue() === 'false') {
                this.getField('ch_pdf').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.operacion_actual = 'excel';
                this.habilitarCorreo();
            }
        });
        this.getField('ch_pdf').setOnChange(() => {
            if (this.getField('ch_pdf').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pantalla').setValueSwitch(false);
                this.operacion_actual = 'pdf';
                this.habilitarCorreo();
            }
        });
        this.getField('ch_pantalla').setOnChange(() => {
            if (this.getField('ch_pantalla').getValue() === 'false') {
                this.getField('ch_excel').setValueSwitch(false);
                this.getField('ch_pdf').setValueSwitch(false);
                this.operacion_actual = 'pantalla';
                this.deshabilitarCorreo();
            } else {
                this.habilitarCorreo();
            }
        });
        //imprimir
        this.getField('radio_correo').setValue("N");
        this.getField('generar_archivo').setClick(() => { this.procesar(); });
    }

    armarArray(data){
        let encabezado = data.data.encabezado;
        let items = data.data.items;
        this.arrayFinal = [];
        encabezado.forEach((encabezado) => {
            let arrayItems = [];
            items.map((items) => {
                if (encabezado.codigo === items.codigo_cabecera) {
                    arrayItems.push(items);
                }
            });
            encabezado['items'] = arrayItems;
            this.arrayFinal.push(encabezado);
        });
        // console.log('this.arrayFinal:\n',this.arrayFinal);
        this.arrayFinal.length >= 1 ? this.habilitarCamposFrm() : this.alertGeneral.toggle(true, 'Error en la construcción de los datos.', 'error');
    }
    
    habilitarCamposFrm(){
        this.getField('label_rsult').setVisible(true);
        this.getField('ch_excel').setVisible(true);
        this.getField('ch_pdf').setVisible(true);
        this.getField('ch_pantalla').setVisible(true);
        this.getField('radio_correo').setVisible(true);
        this.getField('email').setVisible(true);
        this.getField('generar_archivo').setVisible(true);
    }
    
    codigosAlternos(){
        this.datosConsultar = { datos: {} };
        this.generalFormatPmv = { tipo_servicio: 'inve-listadocodigoalterno', operacion: 'codigosalternos', operacion_tipo: 'consulta' };
        this.service.send({
            endpoint: this.constant.formConfiguration(),
            method: 'GET',
            body: this.datosConsultar,
            success: this.successCodigosAlternos,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successCodigosAlternos(data){
        if (data.estado_p === 200 && data.data.hasOwnProperty("encabezado") && data.data.hasOwnProperty("items")) {
            this.armarArray(data);
        }else{
            this.alertGeneral.toggle(true, 'Error al consultar la tabla codigos_alternos', 'error');
        }
    }
    
    //mensajes correo

    mostrarMensajeEmailEnviado() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo() {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    generarExcelTabla3() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let url = this.constant.formConfiguration();
        let datos2 = {
            sucursal_ingreso: this.sucursal_ingreso,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
        };
        let archivo_operacion = 'estadocodigosalternos';
        const myJSON = JSON.stringify(datos2);
        let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"' + this.archivo_servicio + '","operacion":"' + archivo_operacion + '","operacion_tipo":"consulta"}}';
        fetch(url + datos, {
            method: 'GET',
            headers: new Headers({
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": 'application/json'
            })
        })
        .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if (response.status !== 200) {
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            })
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if (blob.size > 0) {
                blob.text().then(res => {
                    if (this.isJson(res)) {
                        this.successEnviarCorreo(JSON.parse(res));
                    } else {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = this.nombre_archivo + '.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                    }
                });
            } else {
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true, err, 'error');
        });
    }

    generarPdfTabla3() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let url = this.constant.formConfiguration();
        let datos2 = {
            sucursal_ingreso: this.sucursal_ingreso,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
        };
        const myJSON = JSON.stringify(datos2);
        let archivo_operacion = 'estadocodigosalternos';
        let datos = '?body={"datos":' + myJSON + ',"generales":{"tipo_servicio":"' + this.archivo_servicio + '","operacion":"' + archivo_operacion + '","operacion_tipo":"consulta"}}';
        fetch(url + datos, {
            method: 'GET',
            headers: new Headers({
                "Authorization": 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": 'application/json'
            })
        })
        .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if (response.status !== 200) {
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                            if (done) {
                                controller.close();
                                return;
                            }
                            controller.enqueue(value);
                            return pump();
                        });
                    }
                }
            })
        })
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if (blob.size > 0) {
                blob.text().then(res => {
                    if (this.isJson(res)) {
                        this.successEnviarCorreo(JSON.parse(res));
                    } else {
                        const pdf = new File([blob], this.nombre_archivo + '.pdf', {
                            type: 'application/pdf'
                        });
                        window.open(URL.createObjectURL(pdf));
                    }
                });
            } else {
                this.mostrarMensajeNoHayDatos();
            }
        }).catch(err => {
            this.alertGeneral.toggle(true, err, 'error');
        });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    genearModalGlobales() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos = { datos: {} }
        datos.datos = {
            sucursal_ingreso: this.sucursal_ingreso,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
        };
        let archivo_operacion = 'estadocodigosalternos';
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successGenearModalGlobales,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }
        );
    }

    successGenearModalGlobales(data) {
        // console.log('data successGenearModalGlobales:\n',data);
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            let datosLista = data.data.datos_lista;
            this.gridOptionsLsCodAlter['rowData'] = [];
            this.getField('tb_modal_pantalla').initData(this.gridOptionsLsCodAlter);
            this.getField('tb_modal_pantalla').toggle(false);

            let datosFicha = [];
            let data2 = {
                "nombre_empresa": datosLista.nombre_empresa,
                "nit_empresa": datosLista.nit_empresa,
                "fecha_hora": datosLista.fecha_hora
            };
            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);

            this.getField("modal_pantalla").setLabel(datosLista.titulo_informe);
            this.getField('modal_pantalla').handleClickOpen();
            let configCell = new Map();

            let encabezado = data.data.encabezado;
            let items = data.data.items;
            this.arrayFinal = [];
            encabezado.forEach((encabezado) => {
                this.arrayFinal.push(encabezado);
                items.map((items) => {
                    let objetoItems = {};
                    if (encabezado.codigo === items.codigo_cabecera) {
                        objetoItems['codigo'] = items.codigo;
                        objetoItems['nombre'] = items.nombre;
                        objetoItems['cantidad'] = items.cantidad_componente;
                        objetoItems['costo'] = items.costo;
                        this.arrayFinal.push(objetoItems);
                    }
                });
            });
            // console.log('this.arrayFinal:\n',this.arrayFinal);
            this.gridOptionsLsCodAlter['rowData'] = this.arrayFinal;
            configCell.set('codigo', {cellRenderer: this.codigoFieldSelection, headerName: 'Código'});
            configCell.set('nombre', {cellRenderer: this.nombreFieldSelection, headerName: 'nombre'});
            configCell.set('cantidad', {cellRenderer: this.cantidadFieldSelection, headerName: 'cantidad', cellStyle: { textAlign: "right" }});
            configCell.set('costo', {cellRenderer: this.costoFieldSelection, headerName: 'costo', cellStyle: { textAlign: "right" }});
            configCell.set('precio1', {cellRenderer: this.precioFieldSelection, headerName: 'precio', cellStyle: { textAlign: "right" }});

            this.getField('tb_modal_pantalla').toggle(true);
            this.getField('tb_modal_pantalla').initData(this.gridOptionsLsCodAlter, configCell);
        } else if (data.estado_p === 404) {
            this.mostrarMensajeNoHayDatos();
        } else {
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    codigoFieldSelection(props) {
        let div = document.createElement('div');
        if (props.data.cantidad === undefined && props.data.precio1 !== undefined) {
            div.setAttribute("class", "divStyle8");
        }else{
            div.setAttribute("class", "divStyle9");
        }
        div.textContent = props.data.codigo;
        return this.createElementJaivana(div);
    }

    nombreFieldSelection(props) {
        let div = document.createElement('div');
        if (props.data.cantidad === undefined && props.data.precio1 !== undefined) {
            div.setAttribute("class", "divStyle8");
        }
        div.textContent = props.data.nombre;
        return this.createElementJaivana(div);
    }

    cantidadFieldSelection(props) {
        let div = document.createElement('div');
        if (props.data.cantidad === undefined && props.data.precio1 !== undefined) {
            div.setAttribute("class", "divStyle8");
            div.textContent = " ";
        }else{
            div.textContent = props.data.cantidad;
        }
        return this.createElementJaivana(div);
    }

    costoFieldSelection(props) {
        let div = document.createElement('div');
        if (props.data.cantidad === undefined && props.data.precio1 !== undefined) {
            div.setAttribute("class", "divStyle8");
        }
        div.textContent = this.currencyFormatterGeneral(props.data.costo);
        return this.createElementJaivana(div);
    }

    precioFieldSelection(props) {
        let div = document.createElement('div');
        if (props.data.cantidad === undefined && props.data.precio1 !== undefined) {
            div.setAttribute("class", "divStyle8");
            div.textContent = this.currencyFormatterGeneral(props.data.precio1);
        }else{
            div.textContent = " ";
        }
        return this.createElementJaivana(div);
    }

    enviarCorreoTerceros() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        let datos = { datos: {} };
        datos.datos = {
            sucursal_ingreso: this.sucursal_ingreso,
            email: this.getField('email').getValue(),
            radio_correo: this.getField('radio_correo').getValue(),
            operacion: this.operacion_actual,
        };
        let archivo_operacion = 'estadocodigosalternos';
        this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: archivo_operacion, operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successEnviarCorreo,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
    }

    successEnviarCorreo(data) {
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200) {
            this.mostrarMensajeEmailEnviado();
        } else {
            this.mostrarMensajeEmailFallo();
            let respuesta = Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    procesar() {
        let errores = 0;
        if (this.getField('radio_correo').getValue() === 'S' || this.operacion_actual !== 'pantalla') {
            if (this.getField('email').valid() === false) {
                errores++;
            }
        }
        if (this.getField('ch_excel').getValue() === 'false' && this.getField('ch_pdf').getValue() === 'false' && this.getField('ch_pantalla').getValue() === 'false') {
            errores++;
            this.getField('ch_excel').setError(true, '* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true, '* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true, '* Es necesario seleccionar una opción.');
            this.operacion_actual = '';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }

        if (errores === 0) {
            if (this.operacion_actual !== '') {
                if (this.getField('radio_correo').getValue() === 'S') {
                    this.enviarCorreoTerceros();
                } else {
                    this.nombre_pdf = 'devcompra';
                    this.nombre_excel = 'devcompra';
                    if (this.operacion_actual === 'pdf') {
                        this.generarPdfTabla3();
                    } else if (this.operacion_actual === 'pantalla') {
                        this.genearModalGlobales();
                    } else if (this.operacion_actual === 'excel') {
                        this.generarExcelTabla3();
                    }
                }
            } else {
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            }
        }
    }

    //Inicio modal ver presentación
    deshabilitarCorreo() {
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false, '');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo() {
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    abrirImprimir() {
        this.getField('email').setValue('');
        this.getField('email').setError(false, '');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
    }

    //Fin modal ver presentación

    mostrarMensajeNoHayDatos() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
    
    mostrarMensajeGenerando() {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(() => { });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);

    }

    currencyFormatterGeneral(number) {
        let decimal = (number + "").split(".")[1];//para manejar los decimales
        if ((decimal !== 0) && (decimal !== undefined)) {
            if (decimal.length > this.numero_decimales_codalternos) {
                number = number.toFixed(this.numero_decimales_codalternos); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
            }
            return (this.formatNumberSaldo(number) + "," + decimal);
        } else {
            return this.formatNumberSaldo(number);
        }
    }

    formatNumberSaldo(number) {
        return Number(number).toFixed(this.numero_decimales_codalternos).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }
}

FormJaivana.addController('inve-listadocodigoalterno', CustomListadoCodigosAlternos);
export default CustomListadoCodigosAlternos;