import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomConsultaDevoluciones
 * @author: Anderson Acevedo Briñez
 * @version: jdesk_1.01.0002
 **/
class CustomConsultaDevoluciones extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.onSelectionChanged                                  = this.onSelectionChanged.bind(this);

    this.onSelectionChangedPrincipal                                  = this.onSelectionChangedPrincipal.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChangedPrincipal;

    this.gridOptionsComponentesItems = Object.assign({}, this.gridOptions);
    this.gridOptionsComponentesItems['onSelectionChanged']           = this.onSelectionChanged;

    this.formatNumberSaldo                                  = this.formatNumberSaldo.bind(this);
    this.currencyFormatterGeneral                                  = this.currencyFormatterGeneral.bind(this);
    this.sucessconsultarDevoluciones                                  = this.sucessconsultarDevoluciones.bind(this);
    this.consultarDevoluciones                                  = this.consultarDevoluciones.bind(this);
    this.validarConsultar                                  = this.validarConsultar.bind(this);
    this.validarCamposFecha                                  = this.validarCamposFecha.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.sucesstraerPropiedades                                  = this.sucesstraerPropiedades.bind(this);
    this.mostrarMensajeGenerando                                  = this.mostrarMensajeGenerando.bind(this);
    this.mostrarMensajeNoHayDatos                                  = this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeAdicional                                  = this.mostrarMensajeAdicional.bind(this);
    
    this.ocultarCampos                                  = this.ocultarCampos.bind(this);
    this.validarConcepto                                  = this.validarConcepto.bind(this);


    this.habilitarCorreo                         =this.habilitarCorreo.bind(this);
    this.deshabilitarCorreo                         =this.deshabilitarCorreo.bind(this);
    this.abrirImprimir                         =this.abrirImprimir.bind(this);
    this.isJson                         =this.isJson.bind(this);
    this.generarExcelTabla3                         =this.generarExcelTabla3.bind(this);
    this.generarPdfTabla3                         =this.generarPdfTabla3.bind(this);
    this.mostrarMensajeNoHayDatos                         =this.mostrarMensajeNoHayDatos.bind(this);
    this.mostrarMensajeEmailFallo                         =this.mostrarMensajeEmailFallo.bind(this);
    this.mostrarMensajeEmailEnviado                         =this.mostrarMensajeEmailEnviado.bind(this);
    this.successEnviarCorreo                         =this.successEnviarCorreo.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.procesar                         =this.procesar.bind(this);
    this.sucessTraerDatos                         =this.sucessTraerDatos.bind(this);
    this.genearModalGlobales                         =this.genearModalGlobales.bind(this);
    this.enviarCorreoTerceros                         =this.enviarCorreoTerceros.bind(this);
    this.generarResumen                         =this.generarResumen.bind(this);
    this.generarDocumento                         =this.generarDocumento.bind(this);
    this.generarPorTipoProducto                         =this.generarPorTipoProducto.bind(this);
    this.traerDocSoporte                         =this.traerDocSoporte.bind(this);
    this.sucessTraerDocSoporte                         =this.sucessTraerDocSoporte.bind(this);
    this.sucessTraerDatosTipos                         =this.sucessTraerDatosTipos.bind(this);
    this.sucessTraerDatosDocumentos                         =this.sucessTraerDatosDocumentos.bind(this);
    this.sucessTraerDatosResumen                         =this.sucessTraerDatosResumen.bind(this);
    this.retornarBodyDatos                         =this.retornarBodyDatos.bind(this);
    this.arreglarDecimales                         =this.arreglarDecimales.bind(this);

    
    this.gridOptionsResumen = Object.assign({}, this.gridOptions);
    this.gridOptionsResumen['onSelectionChanged']           = this.onSelectionChanged;

    this.gridOptionsRetenciones = Object.assign({}, this.gridOptions);
    this.gridOptionsRetenciones['onSelectionChanged']           = this.onSelectionChanged;
    
    this.gridOptionsArticulos = Object.assign({}, this.gridOptions);
    this.gridOptionsArticulos['onSelectionChanged']           = this.onSelectionChanged;


    this.numero_decimales_compras=0;
    this.fecha_hoy='';
    this.fecha_inicio='';
    this.dias_maximo='';
    this.sucursal_ingreso='';

    this.xsucursal = '';
    this.xnumero = '';
    this.xfecha = '';
    this.xnit = '';
    this.xsede = '';
    this.extension='txt';
    this.numero_compra = '';
    this.numero_documento_soporte = '';
    this.estado_enviada = '';
    this.docsopacomp=false;

    this.fila_seleccionada=[];
    this.boton='';
    this.nombre_archivo='';
    this.archivo_servicio='';
    this.archivo_operacion='';
  }
  
  initForm() {
    console.log("Formulario CustomConsultaDevoluciones,  @version: jdesk_1.01.0002, @author: Anderson Acevedo Briñez");
    this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;

    //nuevo para consulta
    this.getField('consultar_desde').setOnChange(this.validarCamposFecha);
    this.getField('consultar_hasta').setOnChange(this.validarCamposFecha);
    this.getField('consultar').setClick(this.validarConsultar);

    this.getField('nit').setOnChange(()=>{
        this.getField('sede').setError(false,'');
        this.getField('nombre').setError(false,'');
        this.ocultarCampos();
    });
    this.getField('codigo_sucursal').setOnChange(()=>{
        this.getField('nombre_sucursal').setError(false,'');
        this.ocultarCampos();
        
    });

    this.getField('numero').setOnChange(()=>{
        this.ocultarCampos();
    });


    this.traerPropiedades();
    let hoy= new Date();
    let dia=hoy.getDate();
    let mes=hoy.getMonth()+1;
    let año=hoy.getFullYear();
    if(mes<10){
        mes='0'+mes;
    }
    if(dia<10){
        dia='0'+dia;
    }
    this.fecha_hoy=año+'-'+mes+'-'+dia;
    this.fecha_inicio=año+'-'+mes+'-01';
    this.getField('consultar_desde').setValue(this.fecha_inicio);
    //botones principal
    this.getField('bt_ver_mas').setClick(()=>{
        if(this.fila_seleccionada.length>0)
            this.generarTxt();
        else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    });
    this.getField('bt_ver_presentacion').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.boton='compras';
            this.nombre_archivo='compras'+this.xfecha;
            this.archivo_servicio='comp-grabardevolucion';
            this.archivo_operacion='archivoitems';
            this.abrirImprimir();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    });

    this.getField('bt_resumen').setClick(()=>{
        if(this.fila_seleccionada.length>0){
            this.boton='resumen';
            this.nombre_archivo='resumen_devolucion_'+this.xfecha;
            this.archivo_servicio='comp-consultadevoluciones';
            this.archivo_operacion='archivoresumen';
            this.abrirImprimir();
        }else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    });
    
    this.getField('bt_documentos').setClick(()=>{
            this.boton='documentos';
            this.nombre_archivo='devoluciones_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();
            this.archivo_servicio='comp-consultadevoluciones';
            this.archivo_operacion='archivodocumento';
            this.abrirImprimir();
    });

    this.getField('bt_tipos').setClick(()=>{
        this.boton='tipos';
        this.nombre_archivo='dev_compras_x_tipo_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();
        this.archivo_servicio='comp-consultadevoluciones';
        this.archivo_operacion='archivoportipoproducto';
        this.abrirImprimir();
    });

    this.getField('bt_doc_soporte').setClick(()=>{
        if(this.fila_seleccionada.length>0)
            this.traerDocSoporte();
        else
            this.alertGeneral.toggle(true, 'Es necesario seleccionar un registro', 'error');
    });


    //imprimir
    this.getField('radio_correo').setValue("N");
    this.getField('generar_archivo').setClick(()=>this.procesar());

    //para balances globales
    this.getField('ch_excel').setOnChange((props)=>{
      if(this.getField('ch_excel').getValue()==='false'){
        this.getField('ch_pdf').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='excel';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pdf').setOnChange((props)=>{
      if(this.getField('ch_pdf').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pantalla').setValueSwitch (false);
        this.operacion_actual='pdf';
        this.habilitarCorreo();
      }
    });
    this.getField('ch_pantalla').setOnChange((props)=>{
      if(this.getField('ch_pantalla').getValue()==='false'){
        this.getField('ch_excel').setValueSwitch (false);
        this.getField('ch_pdf').setValueSwitch (false);
        this.operacion_actual='pantalla';
        this.deshabilitarCorreo();
      }else{
          this.habilitarCorreo();
      }
    });

    //modal conceptos
    this.getField('bt_cancelar_concepto').setClick(()=>{this.getField('modal_conceptos').handleClose();});
    this.getField('bt_aceptar_concepto').setClick(()=>{this.validarConcepto();});



  }

    sucessTraerDatosTipos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": lista.empresa,
                    "nit": lista.nit_xml,
                    "titulo": lista.titulo,
                    "fecha_informe": lista.fecha_informe,
                    "usuario": lista.usuario,
                };

            datosFicha.push(data2);
            this.getField('modal_tipos').handleClickOpen();

            let configCell = new Map();
            configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_total', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_total) }, cellStyle: {textAlign:"right"},width: 110});

            //resumen--->
            this.getField('lista_tipos').setItemsFichas(datosFicha);
           

            this.getField('total_costo_total_tipos').setValue(this.arreglarDecimales(lista.total_general));
            this.getField('rejilla_tipos').toggle(true);
            this.gridOptionsArticulos['rowData'] = [];
            this.gridOptionsArticulos['rowData'] = data.data.datos_items;
            this.getField('rejilla_tipos').initData(this.gridOptionsArticulos,configCell);
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    arreglarDecimales(numero){
        let numero_valor=Number(numero);
        let decimales=numero_valor.toString().split('.')[1];
        if(decimales!==undefined && decimales.length>2){
            numero_valor=numero_valor.toFixed(this.numero_decimales_compras);
        }
        return numero_valor;
    }

    sucessTraerDatosDocumentos(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": lista.empresa,
                    "nit":  lista.nit_xml,
                    "titulo": lista.titulo,
                    "fecha_informe": lista.fecha_informe,
                    "usuario": lista.usuario,
                };

            datosFicha.push(data2);
            this.getField('modal_documentos').handleClickOpen();

            let configCell = new Map();
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo_item) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});

            //resumen--->
            this.getField('lista_documentos').setItemsFichas(datosFicha);
            this.getField('total_general_documentos').setValue(this.arreglarDecimales(lista.total_general));
            this.getField('rejilla_documentos').toggle(true);
            this.gridOptionsArticulos['rowData'] = [];
            this.gridOptionsArticulos['rowData'] = data.data.datos_items;
            this.getField('rejilla_documentos').initData(this.gridOptionsArticulos,configCell);
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

  sucessTraerDatosResumen(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": lista.empresa,
                    "nit": lista.nit_xml,
                    "direccion": lista.direccion_xml,
                    "pbx": lista.pbx,
                    "fecha_resumen": lista.fecha_resumen,
                };

            datosFicha.push(data2);
            this.getField('modal_resumen').setLabel('RESUMEN DEVOLUCIONES EN COMPRAS DEL DÍA '+lista.fecha_resumen);
            this.getField('modal_resumen').handleClickOpen();
            let configCell = new Map();
            configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('tarifa_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('base', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.base) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('tarifa', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.tarifa) }, cellStyle: {textAlign:"right"},width: 110});


            //resumen--->
            this.getField('list_resumen').setItemsFichas(datosFicha);
            this.getField('fecha_resumen').setValue(lista.fecha_resumen);
            this.getField('rejilla_resumen').toggle(true);
            this.gridOptionsResumen['rowData'] = [];
            this.gridOptionsResumen['rowData'] = data.data.datos_items.array_resumen;
            this.getField('rejilla_resumen').initData(this.gridOptionsResumen,configCell);

            //retenciones
            if(data.data.datos_items.retenciones.length>0){
                    this.getField('rejilla_retenciones').toggle(true);
                    this.gridOptionsRetenciones['rowData'] = [];
                    this.gridOptionsRetenciones['rowData'] = data.data.datos_items.retenciones;
                    this.getField('rejilla_retenciones').initData(this.gridOptionsRetenciones,configCell);

            }else{
                this.getField('label_titulo2').setVisible(false);
            }

            this.getField('valor_fletes_resumen').setValue(this.arreglarDecimales(lista.valor_fletes));
            this.getField('valor_aproximacion_resumen').setValue(this.arreglarDecimales(lista.aprox_peso));
            this.getField('valor_total_resumen').setValue(this.arreglarDecimales(lista.total_compras));
            this.getField('valor_numero_facturas_resumen').setValue(this.arreglarDecimales(lista.numero_facturas));
    
            
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.mostrarMensajeNoHayDatos();
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        } 
    }

    retornarBodyDatos(){
        let datos2= {};
        if(this.boton==='compras'){
            datos2= {
                xnumero:this.xnumero,
                xfecha:this.xfecha,
                xsucursal:this.xsucursal,
                nit: this.xnit,
                sede: this.xsede,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                sucursal_ingreso: this.sucursal_ingreso,
                operacion: this.operacion_actual
            };
        }else if(this.boton==='resumen'){
            datos2= {
                xsucursal: this.xsucursal,
                xfecha: this.xfecha,
                xnumero: this.xnumero,
                nit: this.xnit,
                sede: this.xsede,
                sucursal_ingreso: this.sucursal_ingreso,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual,

            };
        }else if(this.boton==='documentos'){
            datos2= {
                consultar_desde: this.getField('consultar_desde').getValue(),
                consultar_hasta: this.getField('consultar_hasta').getValue(),
                sucursal_ingreso: this.sucursal_ingreso,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual,
            };
            if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
                datos2.nit=this.getField('nit').getValue();
                datos2.sede=this.getField('sede').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
                datos2.numero=this.getField('numero').getValue();
        }
        else if(this.boton==='tipos'){
            datos2= {
                consultar_desde:this.getField('consultar_desde').getValue(),
                consultar_hasta:this.getField('consultar_hasta').getValue(),
                sucursal_ingreso:this.sucursal_ingreso,
                email: this.getField('email').getValue(),
                radio_correo: this.getField('radio_correo').getValue(),
                operacion: this.operacion_actual,
            };

            if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
                datos2.nit=this.getField('nit').getValue();
                datos2.sede=this.getField('sede').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
                datos2.numero=this.getField('numero').getValue();
        }
        return datos2;

    }

    validarConcepto(){
        if(this.getField('select_conceptos').valid()){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                xnumero:this.xnumero,
                xfecha:this.xfecha,
                xsucursal:this.xsucursal,
                xnit:this.xnit,
                xsede:this.xsede,
                numero_compra:this.numero_compra,
                estado_enviada:this.estado_enviada,
                numero_documento_soporte:this.numero_documento_soporte,
                sucursal_ingreso:this.sucursal_ingreso,
                input:'0',
                concepto:this.getField('select_conceptos').getValue(),
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-consultadevoluciones', operacion: 'generardocsoporte', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerDocSoporte,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }

    }

    traerDocSoporte(){
        if(this.numero_compra.trim()!==''){
            this.mostrarMensajeGenerando();
            let datos={ datos: {
                xnumero:this.xnumero,
                xfecha:this.xfecha,
                xsucursal:this.xsucursal,
                xnit:this.xnit,
                xsede:this.xsede,
                numero_compra:this.numero_compra,
                estado_enviada:this.estado_enviada,
                numero_documento_soporte:this.numero_documento_soporte,
                sucursal_ingreso:this.sucursal_ingreso
            }};
            this.generalFormatPmv = { tipo_servicio: 'comp-consultadevoluciones', operacion: 'generardocsoporte', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerDocSoporte,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else{
            this.mostrarMensajeAdicional('La devolucion no está asociada a una compra!. No se puede continuar.');
        }
    }

    sucessTraerDocSoporte(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            if(data.data.input!==undefined){
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('NOTA AJUSTE', data.data.mensaje_mostrar);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    this.getField('modal_conceptos').handleClickOpen();
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
            }else if(data.data.mensaje_mostrar!==undefined){
                this.mostrarMensajeAdicional(data.data.mensaje_mostrar);
            }else if(data.data.mensaje_mostrar_2!==undefined){
                ///es cuando va y envia a la dian y hace un update antes, entonces es necesario refrescar
                this.getField('confirmModalCustom').toggle(false);
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', data.data.mensaje_mostrar_2);
                this.getField('confirmModalCustom').setClickDialog(() => { 
                    this.getField('confirmModalCustom').toggle(false);
                    this.consultarDevoluciones();
                });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                
                this.getField('modal_conceptos').handleClose();  
            }else if(data.data.url!==undefined){
                window.open(data.data.url, '_blank').focus();
            }else{
                //nothing
            }
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }



  ocultarCampos(){
    this.fila_seleccionada=[];
    this.getField('consultar').setDisabled(false);
    this.getField('bt_ver_mas').setVisible(false);
    this.getField('bt_ver_presentacion').setVisible(false);
    this.getField('bt_resumen').setVisible(false);
    this.getField('bt_documentos').setVisible(false);
    this.getField('bt_tipos').setVisible(false);
    this.getField('bt_doc_soporte').setVisible(false);
    this.getField('tabla_devoluciones').toggle(false);
    
  }

  traerPropiedades(){
    let datos={ datos: {}};
    this.generalFormatPmv = { tipo_servicio: 'comp-consultadevoluciones', operacion: 'traerpropiedades', operacion_tipo: 'consulta' };
    this.service.send(
    {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.sucesstraerPropiedades,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
    });
    
}

    sucesstraerPropiedades(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){  
            this.numero_decimales_compras=data.data.numero_decimales_compras;
            this.dias_maximo=data.data.consulta_devoluciones_dias_devolver;
            this.docsopacomp=data.data.docsopacomp;

            if(this.docsopacomp)
                this.getField('bt_doc_soporte').setDisabled(false);
            else
            this.getField('bt_doc_soporte').setDisabled(true);
        }else if(data.estado_p === 404){
            //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }
        else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        this.validarCamposFecha();
    }

  validarCamposFecha(){
        this.ocultarCampos();
        this.getField('consultar').setDisabled(false);
        this.getField("consultar_desde").setError(false,'');
        this.getField("consultar_hasta").setError(false,'');
        let fechaInicial = new Date(this.getField('consultar_desde').getValue()).toISOString().split('T')[0];
        let fechaFinal = new Date(this.getField('consultar_hasta').getValue()).toISOString().split('T')[0];
        let fecha_actual = new Date().toISOString().split('T')[0];

        if (fechaInicial > fechaFinal) {
            this.getField('consultar_hasta').setError(true, "¡Fecha hasta, debe ser mayor o igual a fecha desde!");
            this.getField('consultar').setDisabled(true);
            return false;
        } 

        if(fechaFinal > fecha_actual){
            this.getField('consultar_hasta').setError(true,"¡Fecha hasta, no puede ser mayor a la fecha actual!");
            this.getField('consultar').setDisabled(true);
            return false;
        }

        let diferencia=new Date(fechaFinal).getTime()-new Date(fechaInicial).getTime();
        diferencia = diferencia / 1000 / 60 / 60 / 24;
        if(diferencia >this.dias_maximo){
            this.getField("consultar_desde").setError(true,"No es posible devolverse más de "+this.dias_maximo+" días.");
            this.getField('consultar').setDisabled(true); 
            return false;
        }

        this.getField('consultar_hasta').setError(false,"");
        return true;
    }

  validarConsultar(){
    this.getField('tabla_devoluciones').toggle(false);
    this.getField('nit').setError(false,'');
    this.getField('sede').setError(false,'');
    this.getField('numero').setError(false,'');
    this.getField('codigo_sucursal').setError(false,'');
    this.getField('nombre_sucursal').setError(false,'');

    let errores=0;
    if(!this.getField('consultar_hasta').valid())
        errores++;
    if(this.validarCamposFecha()===false)
        errores++;
    if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
        if(!this.getField('nit').valid())
            errores++;
        else if(!this.getField('sede').valid())
            errores++;
    }
    if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' '){
        if(!this.getField('numero').valid())
            errores++;
    }
    

    if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' '){
        if(!this.getField('codigo_sucursal').valid())
            errores++;
        if(!this.getField('nombre_sucursal').valid())
            errores++;
    }
    
    if(errores===0){
        this.consultarDevoluciones();
    }

  }

  onSelectionChanged(){
    
  }

  onSelectionChangedPrincipal(){
    this.fila_seleccionada = this.gridOptionsComponentes.api.getSelectedRows();
    if(this.fila_seleccionada.length>0){
        this.xsucursal = this.fila_seleccionada[0].codigo_sucursal;
        this.xnumero= this.fila_seleccionada[0].numero;
        this.xfecha = this.fila_seleccionada[0].fecha;
        this.xnit = this.fila_seleccionada[0].nit;
        this.xsede = this.fila_seleccionada[0].sede;

        this.numero_compra = this.fila_seleccionada[0].referencia;
        this.numero_documento_soporte = this.fila_seleccionada[0].numero_documento_soporte;
        this.estado_enviada = this.fila_seleccionada[0].estado_enviada;
    }
  }



  consultarDevoluciones(){
        this.fila_seleccionada=[];
        this.mostrarMensajeGenerando();
        this.getField('consultar').setDisabled(true);
        let datos={ datos: {
            consultar_desde:this.getField('consultar_desde').getValue(),
            consultar_hasta:this.getField('consultar_hasta').getValue(),
            sucursal_ingreso:this.sucursal_ingreso,
        }};
        if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
            datos.datos.nit=this.getField('nit').getValue();
            datos.datos.sede=this.getField('sede').getValue();
        }
        if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
            datos.datos.codigo_sucursal=this.getField('codigo_sucursal').getValue();
        if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
            datos.datos.numero=this.getField('numero').getValue();

        this.getField("tabla_devoluciones").setTipoServicioOperacion("comp-consultadevoluciones-consultar");
        this.getField("tabla_devoluciones").sendRequest(this.sucessconsultarDevoluciones, datos);

    }

    sucessconsultarDevoluciones(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.getField('bt_tipos').setVisible(true);
            this.getField('bt_doc_soporte').setVisible(true);
            this.getField('bt_ver_mas').setVisible(true);
            this.getField('bt_ver_presentacion').setVisible(true);
            this.getField('bt_resumen').setVisible(true);
            this.getField('bt_documentos').setVisible(true);
            
            
            let configCell = new Map();
            configCell.set('valor', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('sede', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.sede) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('basee', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.basee) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('baseg', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.baseg) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('retencion', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.retencion) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('fletes', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.fletes) }, cellStyle: {textAlign:"right"},width: 110});


            this.getField('tabla_devoluciones').toggle(true);

            
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('tabla_devoluciones').initData(this.gridOptionsComponentes,configCell);
            
            
        }else{
            this.ocultarCampos();
            if(data.estado_p===404) {
                this.mostrarMensajeNoHayDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
              if(decimal.length>this.numero_decimales_compras){
                number=number.toFixed(this.numero_decimales_compras); //para que redondea a dos decimales
                decimal = (number + "").split(".")[1];
              }
              return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
              return this.formatNumberSaldo(number); 
            }
    }

    formatNumberSaldo(number)
    {
            return Number(number).toFixed(this.numero_decimales_compras).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    mostrarMensajeGenerando(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está generando la información... `);
        this.getField('confirmModalCustom').setClickDialog(()=>{});  
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
        this.getField('consultar').setDisabled(true);
    
    }

    mostrarMensajeNoHayDatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'La consulta no arrojo datos.');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    //mensajes correo

    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }
    
      mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
      }

    //modal imprimir

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }
    
    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    abrirImprimir(){
            
        this.getField("modal_imprimir").setLabel("Imprimir");
        this.getField('modal_imprimir').handleClickOpen();
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setValue("N");
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('email').setDisabled(false);
  }

    procesar(){
        let errores =0;
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        
        if(errores===0){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.nombre_pdf='devcompra';
                    this.nombre_excel='devcompra';
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                            this.genearModalGlobales();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelTabla3();
                    } 
                }
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            } 
        }
    }

    genearModalGlobales(){
        if(this.boton==='documentos')
                this.archivo_operacion='archivodocumento';
        else if(this.boton==='tipos')
                this.archivo_operacion='archivoportipoproducto';
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos={datos:{}}
      datos.datos= this.retornarBodyDatos();
      if(this.boton==='compras'){
          this.generalFormatPmv = { tipo_servicio: 'comp-grabardevolucion', operacion: 'archivoitems', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.sucessTraerDatos,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
        }else if(this.boton==='resumen'){
            this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucessTraerDatosResumen,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }else if(this.boton==='documentos'){
            this.getField("rejilla_documentos").setTipoServicioOperacion(this.archivo_servicio+'-'+this.archivo_operacion);
            this.getField("rejilla_documentos").sendRequest(this.sucessTraerDatosDocumentos, datos);
        }
        else if(this.boton==='tipos'){
            this.getField("rejilla_tipos").setTipoServicioOperacion(this.archivo_servicio+'-'+this.archivo_operacion);
            this.getField("rejilla_tipos").sendRequest(this.sucessTraerDatosTipos, datos);
        }
      
      
  }

  sucessTraerDatos(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){ 
            let datosFicha = [];
            this.lista=data.data.datos_lista;
            let data2 = 
                {
                    "empresa": this.lista.empresa,
                    "sucursal": this.lista.sucursal,
                    "direccion": this.lista.direccion_xml,
                    "telefono": this.lista.telefono_xml,
                    "nit": this.lista.nit_xml,
                    "ciudad": this.lista.ciudad_xml,
                    "devolucion_nro": this.lista.devolucion_nro,
                    "de_la_compra_nro": this.lista.de_la_compra_nro,
                    "fecha_compra": this.lista.fecha,
                    "f_documento": this.lista.f_documento,
                    "bodega": this.lista.bodega,

                };

            datosFicha.push(data2);
            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('rejilla_items_result').toggle(true);

            this.getField('modal_result').handleClickOpen();
            this.gridOptionsComponentesItems['rowData'] = [];
            let configCell = new Map();
            this.gridOptionsComponentesItems['rowData'] = data.data.datos_items;
            //con ubicacion-->codigo,xnombre,alias,ubicacion_bodega,cantidad,subtota1,tarifa_iva,valor_iva,valor_item
            configCell.set('cantidad', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.cantidad) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('costo', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.costo) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_iva', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_iva) }, cellStyle: {textAlign:"right"},width: 110});
            configCell.set('valor_item', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_item) }, cellStyle: {textAlign:"right"},width: 110});
          
            this.getField('rejilla_items_result').initData(this.gridOptionsComponentesItems,configCell);


            this.getField('proveedor_result').setValue(this.lista.proveedor);
            this.getField('direcccion').setValue(this.lista.direccion_nit);
            this.getField('telefono').setValue(this.lista.telefono_nit);
            this.getField('ciudad').setValue(this.lista.ciudad_nit);

            this.getField('retencion_result').setValue(this.currencyFormatterGeneral(this.lista.retencion));
            this.getField('valor_exento_result').setValue(this.currencyFormatterGeneral(this.lista.valor_exento));
            this.getField('reteiva_result').setValue(this.currencyFormatterGeneral(this.lista.reteiva));
            this.getField('valor_grabado_result').setValue(this.currencyFormatterGeneral(this.lista.grabado));

            this.getField('reteica_result').setValue(this.currencyFormatterGeneral(this.lista.retencionica));
            //this.getField('cree_result').setValue(this.currencyFormatterGeneral(this.lista.cree));
            this.getField('aproximacion_result').setValue(this.currencyFormatterGeneral(this.lista.aproximacion));
            this.getField('iva_result').setValue(this.currencyFormatterGeneral(this.lista.total_iva));
            this.getField('fletes_result').setValue(this.currencyFormatterGeneral(this.lista.fletes));
            this.getField('valor_total_result').setValue(this.currencyFormatterGeneral(this.lista.valor_total));

            
      }else if(data.estado_p === 404){
          //this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
          this.mostrarMensajeNoHayDatos();
      }
      else{
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      } 
  }


    enviarCorreoTerceros(){
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
      let datos = {datos: {}};
      datos.datos= this.retornarBodyDatos();
      if(this.boton==='documentos')
            this.archivo_operacion='archivodocumento_pag';
        else if(this.boton==='tipos')
            this.archivo_operacion='archivoportipoproducto_pag';
      this.generalFormatPmv = { tipo_servicio: this.archivo_servicio, operacion: this.archivo_operacion, operacion_tipo: 'consulta' };
      this.service.send(
      {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successEnviarCorreo,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage: false
      });
  }
  
  successEnviarCorreo(data){
      this.getField('confirmModalCustom').toggle(false);
      if(data.estado_p === 200){
          this.mostrarMensajeEmailEnviado();
      }else{
          this.mostrarMensajeEmailFallo();
          let respuesta=Object.values(data.data.errores);
          this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
      }
  }

  generarPdfTabla3()
    {       
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= this.retornarBodyDatos();
            const myJSON = JSON.stringify(datos2);
            if(this.boton==='documentos')
                this.archivo_operacion='archivodocumento_pag';
            else if(this.boton==='tipos')
                this.archivo_operacion='archivoportipoproducto_pag';
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+this.archivo_operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_archivo+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
              this.alertGeneral.toggle(true,err,'error');
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    generarExcelTabla3()
    {
      this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está guardando la información... `);
      this.getField('confirmModalCustom').setClickDialog(()=>{});  
      this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
      this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
      this.getField('confirmModalCustom').toggle(true);
            let url = this.constant.formConfiguration();
            let datos2= this.retornarBodyDatos();
            if(this.boton==='documentos')
                this.archivo_operacion='archivodocumento_pag';
            else if(this.boton==='tipos')
                this.archivo_operacion='archivoportipoproducto_pag';
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"'+this.archivo_servicio+'","operacion":"'+this.archivo_operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreo(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarTxt()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
                xsucursal: this.xsucursal,
                xfecha: this.xfecha,
                xnumero: this.xnumero,
                nit: this.xnit,
                sede: this.xsede,
                sucursal_ingreso: this.sucursal_ingreso,
            };
            let op='archivoitemstxt';
            this.nombre_archivo='detalle_devcompra_'+this.xnumero;

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-grabardevolucion","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarResumen()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
              xsucursal: this.xsucursal,
              xfecha: this.xfecha,
              sucursal_ingreso: this.sucursal_ingreso,
            };
            let op='archivoresumentxt';
            this.nombre_archivo='resumen_dev_'+this.xfecha;

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-consultadevoluciones","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarDocumento()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
              consultar_desde: this.getField('consultar_desde').getValue(),
              consultar_hasta: this.getField('consultar_hasta').getValue(),
              sucursal_ingreso: this.sucursal_ingreso,
            };

            if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
                datos2.nit=this.getField('nit').getValue();
                datos2.sede=this.getField('sede').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
                datos2.numero=this.getField('numero').getValue();


            let op='archivodocumentotxt';
            this.nombre_archivo='dev_compras_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-consultadevoluciones","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    generarPorTipoProducto()
    {
      this.mostrarMensajeGenerando();
            let url = this.constant.formConfiguration();
            let datos2= {
                consultar_desde:this.getField('consultar_desde').getValue(),
                consultar_hasta:this.getField('consultar_hasta').getValue(),
                sucursal_ingreso:this.sucursal_ingreso,
            };
            if(this.getField('nit').getValue()!=='' && this.getField('nit').getValue()!==' '){
                datos2.nit=this.getField('nit').getValue();
                datos2.sede=this.getField('sede').getValue();
            }
            if(this.getField('codigo_sucursal').getValue()!=='' && this.getField('codigo_sucursal').getValue()!==' ')
                datos2.codigo_sucursal=this.getField('codigo_sucursal').getValue();
            if(this.getField('numero').getValue()!=='' && this.getField('numero').getValue()!==' ')
                datos2.numero=this.getField('numero').getValue();


            let op='archivoportipoproductotxt';
            this.nombre_archivo='dev_compras_x_tipo_'+this.getField('consultar_desde').getValue()+'_'+this.getField('consultar_hasta').getValue();

            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"comp-consultadevoluciones","operacion":"'+op+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successValidar(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_archivo+'.'+this.extension;
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                        } 
                    });
                }else{
                    this.mostrarMensajeNoHayDatos();
                }
            }).catch(err => {
                this.alertGeneral.toggle(true,err,'error');
            });
    }

    mostrarMensajeAdicional(mensaje){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setClickCancelDialog (()=>{ this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog â€“ popup
        this.getField('confirmModalCustom').toggle(true);
    }


   
}


FormJaivana.addController("comp-consultadevoluciones", CustomConsultaDevoluciones);
export default CustomConsultaDevoluciones;