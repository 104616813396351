/**
 * @description: Custom con funciones adicionales al formulario con código: AutorizPedidos, ID Formulario: 528, Sección: padre: Facturación(id: 37) - hijo: Pedidos(id: 56)
 * @author: Jose Albeiro Marin
 * @updateBy: Sebastian Rios Echverri
 * @version: 1.01.0036
 * @updateBy @updateBy: Anderson Acevedo.
 * @version: 1.01.0004    jdesk
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomAutorizaciones extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm = this.initForm.bind(this);
        this.traerPendientes = this.traerPendientes.bind(this);
        this.gridOptionsAutorizaciones = Object.assign({}, this.gridOptions);
        this.setButtonPedidosItems = this.setButtonPedidosItems.bind(this);
        this.openModal = this.openModal.bind(this);
        this.traeNumeroPedido = this.traeNumeroPedido.bind(this);
        this.successPedidoPorEstado = this.successPedidoPorEstado.bind(this);
        this.successTraerPendientes = this.successTraerPendientes.bind(this);
        this.gridOptionsItemPedido = Object.assign({}, this.gridOptions);
        this.setModalVerMas = this.setModalVerMas.bind(this);
        this.openModalVerMas = this.openModalVerMas.bind(this);
        this.setModalAutorizar = this.setModalAutorizar.bind(this);
        this.openModalAutorizar = this.openModalAutorizar.bind(this);
        this.cerrarModalVerMas = this.cerrarModalVerMas.bind(this);
        this.traerCostoBodega = this.traerCostoBodega.bind(this);
        this.successTraerCostoBodega = this.successTraerCostoBodega.bind(this);
        this.traerExistenciaBodega = this.traerExistenciaBodega.bind(this);
        this.successTraerExistenciaBodega = this.successTraerExistenciaBodega.bind(this);
        this.ModificarPedidoProducto = this.ModificarPedidoProducto.bind(this);
        this.cerrarModal = this.cerrarModal.bind(this);
        this.setModalRechazar = this.setModalRechazar.bind(this);
        this.openModalRechazar = this.openModalRechazar.bind(this);
        this.cerrarModalRechazar = this.cerrarModalRechazar.bind(this);
        this.cerrarModalPedidos = this.cerrarModalPedidos.bind(this);
        this.rechazarPedidoProducto = this.rechazarPedidoProducto.bind(this);
        this.successRechazarPedidoProducto = this.successRechazarPedidoProducto.bind(this);
        this.concatenarMargen = [];
        this.dataConMargen = [];
        this.cambiarCantidad = this.cambiarCantidad.bind(this);
        this.cambiarCosto = this.cambiarCosto.bind(this);
        this.successAutorizarPedidoProducto = this.successAutorizarPedidoProducto.bind(this);
        this.ocultarTabla = this.ocultarTabla.bind(this);
        this.grabar = this.grabar.bind(this);
        this.mostrarMensajeAdicional = this.mostrarMensajeAdicional.bind(this);

        this.sucursal_ingreso = '';
        this.nombre_archivo_reporte = 'pedido';
        this.email_imprimir = '';
    }
    initForm() {
        //Versión de CUSTOM:
        console.log("Autorizaciones @jdesk_1.01.0004, @author: Jose Albeiro Marin, @updateBy: Sebastian Rios Echverri, @updateBy: Anderson Acevedo");
        this.sucursal_ingreso = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;


        //nuevas funciones
        this.getField('grabar').setClick(() => this.getField('modal_imprimir').handleClickOpen());

        this.getField("generar_archivo").setClick(() => {
            if (this.getField('email_imprimir').valid()) {
                this.email_imprimir = this.getField('email_imprimir').getValue();
                this.getField('modal_imprimir').handleClose();
                this.grabar();
            }
        });

        //funciones anteriores
        this.getField('consultar').setClick(this.traerPendientes);
        //this.getField('grabar').setSuccess(this.cerrarModalPedidos);
        //this.getField('cargar_costo_bodega').setCustomSuccess(this.traerCostoBodega);
        this.getField("bt_volver").setClick(this.cerrarModalVerMas);
        this.getField('bt_aceptar_autorizar').setClick(this.ModificarPedidoProducto);
        this.getField("bt_cancelar_autorizar").setClick(this.cerrarModal);
        this.getField("bt_cancelar_rechazar").setClick(this.cerrarModalRechazar);
        this.getField("bt_aceptar_rechazar").setClick(this.rechazarPedidoProducto);
        this.getField("estado").setOnChange(this.ocultarTabla);

        this.getField('modal_pedido_items').setCloseButton(() => {
            this.getField('tb_autorizaciones').toggle(false);
            this.traerPendientes();
        });

    }
    //nuevas funciones-------------->


    grabar() {
        if (this.getField('numero_pedido').valid()) {
            this.getField('grabar').setDisabled(true);
            let datos = {
                datos: {
                    numero_pedido: this.getField('numero_pedido').getValue(),
                    sucursal_ingreso: this.sucursal_ingreso,
                    email_imprimir: this.email_imprimir,
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'fact-autorizaciones', operacion: 'actualizar', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.cerrarModalPedidos,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    //funciones anteriores--------->

    ocultarTabla() {
        this.getField('tb_autorizaciones').toggle(false);
    }
    cerrarModalPedidos(data) {
        this.getField('grabar').setDisabled(false);
        this.getField('modal_pedido_items').handleClose();
        this.getField('tb_autorizaciones').toggle(false);
    }
    traerPendientes() {// funcion traerPendientes permite consumir el servicio traer_todos_por_estado ingresando como dato de entrada el estado para traer todos los registros con ese parametro 
        if (this.getField('estado').getValue() !== '') {
            this.getField('consultar').setDisabled(true);
            //528_permite_traer_todos_por_estado
            let datos = {
                datos: {
                    estado: this.getField("estado").getValue()
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'fact-autorizaciones', operacion: 'traertodosporestado', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successTraerPendientes,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false,
                });
        } else {
            this.mostrarMensajeAdicional('Es necesario seleccionar un estado.');
        }
    }
    successTraerPendientes(data) {//si encuentra datos, se cargan a la tabla y se crea una nueva accion de abrir modal ver mas 
        // console.log("data",data);//h
        this.getField('consultar').setDisabled(false);
        if (data.estado_p === 200) {
            this.gridOptionsAutorizaciones['rowData'] = data.model;
            let configCell = new Map();
            configCell.set('accion', { cellRenderer: this.setButtonPedidosItems, field: 'Accion' });
            configCell.set('sede', { cellRenderer: '', field: 'sede' });
            configCell.set('codigo_sucursal', { cellRenderer: '', field: 'codigo_sucursal' });
            configCell.set('tipo', { cellRenderer: '', field: 'tipo' });
            configCell.set('vendedor', { cellRenderer: '', field: 'vendedor' });
            configCell.set('nit', { cellRenderer: '', field: 'nit' });
            configCell.set('numero_pedido', { cellRenderer: '', field: 'numero_pedido' });
            configCell.set('hora_pedido', { cellRenderer: '', field: 'hora_pedido' });
            configCell.set('fecha', { cellRenderer: '', field: 'fecha' });


            this.getField('tb_autorizaciones').initData(this.gridOptionsAutorizaciones, configCell);
            //this.gridOptionsAutorizaciones.api.sizeColumnsToFit();


        } else {
            this.getField('tb_autorizaciones').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    setButtonPedidosItems(props) {//accion del boton pedidosItems
        let button = document.createElement("input");
        button.onclick = () => { this.getField("numero_pedido").setValue(props.data.numero_pedido); this.traeNumeroPedido(); }//ejecuta la funcion traernumeroPedido con el fin de abrir la modal cuando el servicio "" que dibuja la tabla ya se haya ejecutado.
        button.setAttribute("id", `button_aprove_${props.data.nit}`);
        button.setAttribute("class", "buttonStyle");//,"width:100%; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ver mas");
        return this.createElementJaivana(button);
    }
    openModal() {//permite abrir el modal asociado a pedidos_por_autorizar_traernumeropedidoporestado
        this.getField("modal_pedido_items").setLabel("Pedidos Items");
        this.getField('modal_pedido_items').handleClickOpen();//abre modal y ejecuta la funcion traernumeroPedido
        this.gridOptionsAutorizaciones.api.deselectAll();//desselecciona la fila seleccionada para evitar seteos no deseados            
    }
    traeNumeroPedido() {
        // this.getField("tb_item_pedido").toggle(false);
        //this.gridOptionsItemPedido['rowData'] = [];
        /* let configCell = new Map();
        this.getField('tb_item_pedido').initData(this.gridOptionsItemPedido, configCell);  */

        if (this.getField("estado").getValue() !== '' && this.getField("numero_pedido").getValue() !== '') {
            //528_trae_numerodepedidoporestado
            let datos = {
                datos: {
                    estado: this.getField("estado").getValue(),
                    numero_pedido: this.getField("numero_pedido").getValue(),
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'fact-autorizaciones', operacion: 'traernumerodepedidoporestado', operacion_tipo: 'consulta' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'GET',
                    body: datos,
                    success: this.successPedidoPorEstado,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false,
                });
        }
    }
    successPedidoPorEstado(data) {//si encuentra datos, se cargan a la tabla, inicializamos un array vacio concatenarMargen[] el cual vamos a recorrer para traer el precio_sin_iva_con_descuento y el costo para calcular el margen 
        // console.log("data",this.concatenarMargen);
        if (data.estado_p === 200) {
            this.concatenarMargen = data.data;
            this.dataConMargen = data.data;
            this.concatenarMargen.forEach(item => {
                let psicd = item.precio_sin_iva_con_descuento;
                let costo = item.costo;
                let margen = ((psicd - costo) / psicd) * 100;
                let redondear = margen.toFixed(2);
                item["margen"] = redondear;

            });
            this.gridOptionsItemPedido['rowData'] = this.concatenarMargen;
            let configCell = new Map();
            /*             
            configCell.set("estado", { cellRenderer: '', field: 'estado'});
            configCell.set("numero_pedido", { cellRenderer: '', field: 'numero_pedido'});
            configCell.set("codigo", { cellRenderer: '', field: 'codigo'});
            configCell.set("narticulo", { cellRenderer: '', field: 'narticulo'});
            configCell.set("bodega", { cellRenderer: '', field: 'bodega'});
            configCell.set('cantidad', { cellRenderer: function (props) { return props.data.cantidad }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarCantidad, sortable: false, filter: false, field: 'cantidad'});
            configCell.set('precio_sin_iva_con_descuento', { cellRenderer: function (props) { return props.data.precio_sin_iva_con_descuento }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarCosto, sortable: false, filter: false, field: 'precio_sin_iva_con_descuento'});
            configCell.set("tarifa_iva", { cellRenderer: '', field: 'tarifa_iva'});
            configCell.set("ver_mas", { cellRenderer: this.setModalVerMas, field: 'ver_mas',width:200});
            configCell.set("autorizar", { cellRenderer: this.setModalAutorizar, field: 'autorizar',width:200});
            configCell.set("rechazar", { cellRenderer: this.setModalRechazar, field: 'rechazar',width:200});
              */

            configCell.set("estado", { cellRenderer: '', field: 'estado' });
            configCell.set("numero_pedido", { cellRenderer: '', field: 'numero_pedido' });
            configCell.set("codigo", { cellRenderer: '', field: 'codigo' });
            configCell.set("narticulo", { cellRenderer: '', field: 'narticulo' });
            configCell.set("bodega", { cellRenderer: '', field: 'bodega' });
            configCell.set('cantidad', { cellRenderer: function (props) { return props.data.cantidad }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarCantidad, sortable: false, filter: false, field: 'cantidad' });
            configCell.set('precio_sin_iva_con_descuento', { cellRenderer: function (props) { return props.data.precio_sin_iva_con_descuento }, cellStyle: { backgroundColor: "rgb(238,238,238)", textAlign: "right", borderRadius: "5px", color: 'rgb(0, 86, 201)' }, editable: true, onCellValueChanged: this.cambiarCosto, sortable: false, filter: false, field: 'precio_sin_iva_con_descuento' });
            configCell.set("tarifa_iva", { cellRenderer: '', field: 'tarifa_iva' });
            configCell.set("ver_mas", { cellRenderer: this.setModalVerMas, field: 'ver_mas' });
            configCell.set("autorizar", { cellRenderer: this.setModalAutorizar, field: 'autorizar' });
            configCell.set("rechazar", { cellRenderer: this.setModalRechazar, field: 'rechazar' });
            this.getField('tb_item_pedido').initData(this.gridOptionsItemPedido, configCell);

            //this.gridOptionsItemPedido.api.sizeColumnsToFit();
            /* 
            const allColumnIds = [];
            
            this.gridOptionsItemPedido.columnApi.getAllColumns().forEach((column) => {
                allColumnIds.push(column.getId());
            });
            this.gridOptionsItemPedido.columnApi.autoSizeColumns(allColumnIds, true); 

            */
            let itemRechazado = 0;
            let itemAutorizado = 0;
            data.data.forEach((item) => {
                if (item.estado === 'R') {
                    itemRechazado += 1;
                }
                if (item.estado === 'A') {
                    itemAutorizado += 1;
                }
            });
            this.getField('items_totales').setValue(data.data.length);
            this.getField('items_autorizados').setValue(itemAutorizado);
            this.getField('items_rechazados').setValue(itemRechazado);
            this.openModal();
        } else {
            this.getField('tb_item_pedido').toggle(false);
        }

    }
    cambiarCantidad(props) {// funcion que permite modificar la cantidad del registro seleccionado
        let seguir = this.getField('tb_item_pedido').cellValid(props.oldValue, props.newValue, /^(\d{1,15})(\.\d{1,2})?$/, undefined, 9999999, 1);
        if (seguir) {
            let valor_actual = props.newValue;
            //528_permite_modificar_cantidad

            let datos = {
                datos: {
                    numero_pedido: props.data.numero_pedido,
                    codigo: props.data.codigo,
                    cantidad1: parseFloat(valor_actual),
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'fact-autorizaciones', operacion: 'modificarcantidad', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.traeNumeroPedido,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        } else if (seguir !== null)
            this.traeNumeroPedido();

    }
    cambiarCosto(props) {// funcion que me permite modificar el costo del registro seleccionado
        let seguir = this.getField('tb_item_pedido').cellValid(props.oldValue, props.newValue, /^(\d{1,15})(\.\d{1,2})?$/, undefined, 9999999, 1);
        if (seguir) {
            let valor_actual = props.newValue;
            //528_permite_modificar_costo

            let datos = {
                datos: {
                    numero_pedido: props.data.numero_pedido,
                    codigo: props.data.codigo,
                    precio_sin_iva_con_descuento1: parseFloat(valor_actual),
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'fact-autorizaciones', operacion: 'modificarprecio', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.traeNumeroPedido,
                    error: this.error_,
                    general: this.generalFormatPmv
                });

        } else if (seguir !== null)
            this.traeNumeroPedido();

    }
    setModalVerMas(props) {////accion del boton ver mas
        let button = document.createElement("input");
        button.onclick = () => this.openModalVerMas(props.data);
        button.setAttribute("id", 'button_aprove_');
        button.setAttribute("class", "buttonStyle");//,"width:100%; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ver más");
        return this.createElementJaivana(button);
    }
    openModalVerMas(data) {//funcion que permite abrir el modal ver mas y consumir dos funciones para traer los parametros costo bodegas y existencias
        this.getField("modal_ver_mas").setLabel("Pedidos Pendientes");
        this.getField('modal_ver_mas').handleClickOpen();
        this.getField("p_codigo").setValue(data.codigo);
        this.getField("p_bodega").setValue(data.bodega);
        this.getField("pedido_modal_ver_mas").setValue(data.numero_pedido);
        this.getField("nit_modal_ver_mas").setValue(this.getField("nit").getValue());
        this.getField("sede_modal_ver_mas").setValue(this.getField("sede").getValue());
        this.getField("nombre_modal_ver_mas").setValue(this.getField("ncliente").getValue());
        this.getField("codigo_producto_modal_ver_mas").setValue(data.codigo);
        this.getField("nombre_producto").setValue(data.narticulo);
        this.getField("pedido_modal_ver_mas").setValue(data.numero_pedido);
        this.traerCostoBodega();
        this.traerExistenciaBodega();

    }
    traerCostoBodega() {//funcion que  consume el servicio articulo-traerCostoBodega para el campo costo bodegaen relacion a los campos codigo y bodega de la tabla gridOptionsItemPedido
        //528_permite_traer_costo_bodega
        let datos = {
            datos: {
                p_codigo: this.getField("p_codigo").getValue(),
                p_bodega: this.getField("p_bodega").getValue(),
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: 'traercostobodega', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successTraerCostoBodega,
                error: this.error_,
                general: this.generalFormatPmv,
            });

    }
    successTraerCostoBodega(data) {
        // console.log("successTraerCostoBodega", data);
        if (data.estado_p === 200) {
            this.getField("costo_bodega").setValue(data.data[0].costo_producto);
        }
    }
    traerExistenciaBodega() {//funcion que me permite consumir el servicio en relacion a los campos codigo y bodega de la tabla gridOptionsItemPedido
        //528_permite_traer_existencia_bodega
        let datos = {
            datos: {
                p_codigo: this.getField("p_codigo").getValue(),
                p_bodega: this.getField("p_bodega").getValue(),
            }
        };
        this.generalFormatPmv = { tipo_servicio: 'maes-articulo', operacion: 'traerexistenciabodega', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: datos,
                success: this.successTraerExistenciaBodega,
                error: this.error_,
                general: this.generalFormatPmv,
            });
    }
    successTraerExistenciaBodega(data) {
        // console.log("traerExistenciaBodega", data);
        if (data.estado_p === 200) {
            this.getField("existencias").setValue(data.data[0].existencia);
        }
    }
    cerrarModalVerMas() {//funcion para cerrar modal ver mas 
        this.getField('modal_ver_mas').handleClose();
    }
    setModalAutorizar(props) {//accion del boton ver mas
        let button = document.createElement('input');
        button.setAttribute("id", 'id_autorizar');
        button.setAttribute("value", "Autorizar");
        button.setAttribute("type", "button");
        if (props.data.estado === 'A') {//si el estado es igual 'A' se activa los botones ver mas y el boton rechazar y se desactiva el boton autorizar
            button.setAttribute("class", "buttonDisabled");
            //button.setAttribute("disabled", true);
        } else {
            button.onclick = () => this.openModalAutorizar(props.data);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("value", "Autorizar");
        }
        return this.createElementJaivana(button);
    }
    openModalAutorizar(data) { // al abrir el modal se setean los campos de la tabla gridOptionsItemPedido y tambien en relacion a la tabla gridOptionsAutorizacionessss
        this.getField("modal_autorizar").setLabel("Autorizar");
        this.getField('modal_autorizar').handleClickOpen();
        this.getField("pedido_modal_autorizar").setValue(data.numero_pedido);
        this.getField("nit_modal_autorizar").setValue(this.getField("nit").getValue());
        this.getField("sede_modal_autorizar").setValue(this.getField("sede").getValue());
        this.getField("nombre_modal_autorizar").setValue(this.getField("ncliente").getValue());
        this.getField("codigo_producto_modal_autorizar").setValue(data.codigo);
        this.getField("nombre_producto_modal_autorizar").setValue(data.narticulo);
        this.getField("id").setValue(data.id);

    }
    cerrarModal() {
        this.getField('modal_autorizar').handleClose();

    }
    ModificarPedidoProducto(data) {
        //528_permite_modificar_autorizarpedidoproducto
        if (this.getField('detalle').valid()) {
            this.getField('bt_aceptar_autorizar').setDisabled(true);
            let datos = {
                datos: {
                    id: this.getField('id').getValue(),
                    detalle: this.getField('detalle').getValue(),
                    estado: "A",
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'fact-autorizaciones', operacion: 'autorizarpedidoproducto', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successAutorizarPedidoProducto,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }

    }
    successAutorizarPedidoProducto(data) {
        this.getField('bt_aceptar_autorizar').setDisabled(false);
        if (data.estado_p === 200) {
            // console.log("autorizados",data);
            this.cerrarModal();
            this.traeNumeroPedido();
        }
    }
    setModalRechazar(props) {
        let button = document.createElement('input');
        button.setAttribute("id", 'id_rechazar');
        button.setAttribute("value", "Rechazar");
        button.setAttribute("type", "button");
        if (props.data.estado === 'R') {
            button.setAttribute("class", "buttonDisabled");
            button.setAttribute("disabled", true);
        } else {
            button.onclick = () => this.openModalRechazar(props.data);
            button.setAttribute("class", "buttonStyle");
            button.setAttribute("value", "Rechazar");
        }
        return this.createElementJaivana(button);
    }
    openModalRechazar(data) {
        this.getField("modal_rechazar").setLabel("Rechazar");
        this.getField('modal_rechazar').handleClickOpen();
        this.getField("pedido_modal_rechazar").setValue(data.numero_pedido);
        this.getField("nit_modal_rechazar").setValue(this.getField("nit").getValue());
        this.getField("sede_modal_rechazar").setValue(this.getField("sede").getValue());
        this.getField("nombre_modal_rechazar").setValue(this.getField("ncliente").getValue());
        this.getField("codigo_producto_modal_rechazar").setValue(data.codigo);
        this.getField("nombre_producto_modal_rechazar").setValue(data.narticulo);
        this.getField("id").setValue(data.id);

    }
    cerrarModalRechazar() {
        this.getField('modal_rechazar').handleClose();
    }
    rechazarPedidoProducto() {//funcion que permite rechazar pedido producto
        //528_permite_actualizar_rechazar_pedido_producto
        if (this.getField('dellate_modal_rechazar').valid()) {
            this.getField('bt_aceptar_rechazar').setDisabled(true);
            let datos = {
                datos: {
                    id: this.getField('id').getValue(),
                    detalle: this.getField('dellate_modal_rechazar').getValue(),
                    estado: "R",
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'fact-autorizaciones', operacion: 'rechazarpedidoproducto', operacion_tipo: 'modificar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'PUT',
                    body: datos,
                    success: this.successRechazarPedidoProducto,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }


    }
    successRechazarPedidoProducto(data) {
        this.getField('bt_aceptar_rechazar').setDisabled(false);
        if (data.estado_p === 200) {
            this.cerrarModalRechazar();
            this.traeNumeroPedido();
        }
    }

    mostrarMensajeAdicional(mensaje) {
        this.getField('confirmModalCustom').toggle(false);
        this.getField('confirmModalCustom').setTitleAndContent('Compras', mensaje);
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }
}
FormJaivana.addController("fact-autorizaciones", CustomAutorizaciones);
export default CustomAutorizaciones;