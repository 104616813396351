/**
 * @description Custom con funciones adicionales al formulario con código: maes-activosfijosasignaciones, ID Formulario: 106, Sección: padre: Maestros(id: 1)- hijo: Activos Fijos(id: 24)
 * @author Anderson Acevedo Briñez
 * @version jdesk_1.01.0001
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomActivosFijosAsignaciones extends FormJaivana.form {


    constructor(props) {

        super(props);
        // Variables de tablas:
        this.gridOptionsActivosFijosGarantias      =   Object.assign({},this.gridOptions);
       
        // Variables Globales:
        this.arrayObjetos                          =   new Map();
        this.arrayCampos                           =   new Map();
        this.bHayErrores                           =   false;
        this.bHayError                             =   false;
        this.datosAgregar                          =   { datos:{}};
        // this.archivo                               =   true; // Esta variable global booleana, va a servir para que cuando se guarde un registro nuevo o se haga click en el botón cancelar, el usuario tenga que volver a cargar una nueva imagen, ya que con setearla vacío, no se limpia.
        this.archivoImagen1                        =   "";
        this.archivoImagen2                        =   "";
        this.archivoImagen3                        =   "";
        this.archivoImagen4                        =   "";
        this.archivoImagen5                        =   "";
        this.allowedExtensions                     =   /(.jpg|.jpeg|.png|.JPG|.JPEG|.PNG)$/i;
        this.estadoUploadFila                      =   false; // Esta variable booleana va a servir para validar si el archivo cargado en los campos UF tienen el formato correcto para dejar grabar o no.
       
        // Funciones:
        this.initForm                              =   this.initForm.bind(this);
        this.validarBtnFrm                         =   this.validarBtnFrm.bind(this);
        this.dibujarTbActivosFijosAsignaciones        =   this.dibujarTbActivosFijosAsignaciones.bind(this);
        this.successTbActivosFijosAsignaciones        =   this.successTbActivosFijosAsignaciones.bind(this);
        this.setButtonTbActivosFijosAsginaciones      =   this.setButtonTbActivosFijosAsginaciones.bind(this);
        this.crearBotonTabla                       =   this.crearBotonTabla.bind(this);
        this.accionProcesar                        =   this.accionProcesar.bind(this);
        this.guardarActivosFijosGarantias          =   this.guardarActivosFijosGarantias.bind(this);
        this.successGuardarActivosFijosGarantias   =   this.successGuardarActivosFijosGarantias.bind(this);
        this.validarEliminar                       =   this.validarEliminar.bind(this);
        this.successEliminar                       =   this.successEliminar.bind(this);
        this.btnAgregar                            =   this.btnAgregar.bind(this);
        this.uploadFile                            =   this.uploadFile.bind(this);
        this.validarCrear                            =   this.validarCrear.bind(this);
        this.validarModificar                            =   this.validarModificar.bind(this);
        this.datos_anteriores='';
        this.modificarRegistro=   this.modificarRegistro.bind(this);
        this.successModificarRegistro=   this.successModificarRegistro.bind(this);
        this.generarImagen=   this.generarImagen.bind(this);
    }

    initForm() {

        console.log('Formulario CustomActivosFijosAsignaciones,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez');

        this.arrayCampos.set(1, '.');
        this.arrayCampos.set(2, 'tb_activosfijosasignaciones');
        this.arrayCampos.set(3, 'btn_agregar');
        this.arrayCampos.set(4, 'nit_buscar');
        this.arrayCampos.set(5, 'sede');
        this.arrayCampos.set(6, 'nombre');
        this.arrayCampos.set(7, 'codigo_activo_fijo');
        this.arrayCampos.set(8, 'descripcion_activo_fijo');
        this.arrayCampos.set(9, 'descripcion_area');
        this.arrayCampos.set(10, 'codigo_sucursal');
        this.arrayCampos.set(11, 'nombre_sucursal');
        this.arrayCampos.set(12, 'fecha_asignacion');
        this.arrayCampos.set(13, 'fecha_vencimiento');
        this.arrayCampos.set(14, 'fecha_retiro');
        this.arrayCampos.set(15, 'detalle');
        this.arrayCampos.set(20, 'documento1_url');
        this.arrayCampos.set(21, 'activosfijos_id');
        this.arrayCampos.set(22, 'activos_fijos_area_id');
        this.arrayCampos.set(23, 'terceros_id');
        this.arrayCampos.set(24, 'sucursales_id');
        this.arrayCampos.set(25, 'btn_aceptar');
        this.arrayCampos.set(26, 'btn_cancelar');


        this.arrayObjetos.set(1, '.');
        this.arrayObjetos.set(2, 'tb_activosfijosasignaciones');
        this.arrayObjetos.set(3, 'btn_agregar');
        this.arrayObjetos.set(4, 'nit_buscar');
        this.arrayObjetos.set(5, 'sede');
        this.arrayObjetos.set(6, 'nombre');
        this.arrayObjetos.set(7, 'codigo_activo_fijo');
        this.arrayObjetos.set(8, 'descripcion_activo_fijo');
        this.arrayObjetos.set(9, 'descripcion_area');
        this.arrayObjetos.set(10, 'codigo_sucursal');
        this.arrayObjetos.set(11, 'nombre_sucursal');
        this.arrayObjetos.set(12, 'fecha_asignacion');
        this.arrayObjetos.set(13, 'fecha_vencimiento');
        this.arrayObjetos.set(14, 'fecha_retiro');
        this.arrayObjetos.set(15, 'detalle');
        this.arrayObjetos.set(20, 'documento1_url');
        this.arrayObjetos.set(21, 'activosfijos_id');
        this.arrayObjetos.set(22, 'activos_fijos_area_id');
        this.arrayObjetos.set(23, 'terceros_id');
        this.arrayObjetos.set(24, 'sucursales_id');
        this.arrayObjetos.set(25, 'btn_aceptar');
        this.arrayObjetos.set(26, 'btn_cancelar');
        
        this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20,25,26', 'visible', 'false');
        this.dibujarTbActivosFijosAsignaciones();
        this.getField('btn_agregar').setClick(this.btnAgregar);
        this.getField('btn_cancelar').setClick(() => { this.validarBtnFrm('cancelar'); });
        this.getField('btn_aceptar').setClick(() => { this.validarBtnFrm('aceptar'); });
        this.getField('fecha_asignacion').setOnChange(() => { this.validarBtnFrm('finicio'); });
        this.getField('fecha_vencimiento').setOnChange(() => { this.validarBtnFrm('validarfechas'); });
        this.getField('documento1_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen1 = props.target.files[0] : this.archivoImagen1 = '';});
        /* this.getField('imagen2_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen2 = props.target.files[0] : this.archivoImagen2 = '';});
        this.getField('imagen3_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen3 = props.target.files[0] : this.archivoImagen3 = '';});
        this.getField('imagen4_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen4 = props.target.files[0] : this.archivoImagen4 = '';});
        this.getField('imagen5_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen5 = props.target.files[0] : this.archivoImagen5 = '';}); */
        this.getField('btn_aceptar_modificar').setClick(this.validarModificar);
        this.getField('btn_cancelar_modificar').setClick(()=>{this.getField('modal_modificar').handleClose()});
        this.getField('estado_retiro').setDisabled(true);
   
    }


    //*** definir los botones y los success
    uploadFile(e) {
        // let campo = e.target.id.slice(0, -5);
        if (e.target.files.length >= 1) {
            if (!this.allowedExtensions.exec(e.target.files[0].name)) {
                this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .jpeg/.jpg/.png', 'error');
                // this.getField(`${campo}`).setValue('');
                this.estadoUploadFila = false;
            }else if (e.target.files[0].name.indexOf(' ') > 0) {
                this.alertGeneral.toggle(true, "La imagen (" + e.target.files[0].name + ") no puede contener espacios.", 'error');
                // this.getField(`${campo}`).setValue('');
                this.estadoUploadFila = false;
            }else{
                this.estadoUploadFila = true;
            }
        }
    }

    btnAgregar() {
        this.establecerPropiedades('3', 'visible', 'false');
        this.getField('btn_aceptar').setDisabled(false);
        this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20,25,26', 'visible', 'true');
        this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20', 'value,error', 'false');
    }
    modificarRegistro(){
        this.getField('btn_aceptar_modificar').setDisabled(true);
        let detalle=this.datos_anteriores.detalle+', Entregado por la persona el '+this.getField('fecha_retiro').getValue();
        let datos={ datos: {
            activosfijos_asignaciones_id: this.datos_anteriores.activosfijos_asignaciones_id,
            fecha_retiro:this.getField('fecha_retiro').getValue(), 
            detalle: detalle
        }};
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosasignaciones', operacion: '6', operacion_tipo: 'modificar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successModificarRegistro,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successModificarRegistro(data){
        if (data.estado_p === 200 )
        {
            this.getField('modal_modificar').handleClose();
            this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20,25,26', 'visible', 'false');
            this.dibujarTbActivosFijosAsignaciones();
        }
        else 
        {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
        this.getField('btn_aceptar_modificar').setDisabled(false);
    }


    guardarActivosFijosGarantias(data) {
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.alertGeneral.toggle(true, '* La relación de los campos código activo fijo, fecha desde y fecha hasta ya existe, No se permite duplicar', 'error');
            this.getField('btn_aceptar').setDisabled(false);
        } else if (data.estado_p === 404) {
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            const data = {
                _generales: { "tipo_servicio": 'maes-activosfijosasignaciones', "operacion": '5', "operacion_tipo": 'crear' },
                archivo: this.archivoImagen1,
                fecha_asignacion: this.getField('fecha_asignacion').getValue(),
                fecha_vencimiento: this.getField('fecha_vencimiento').getValue(),
                detalle: this.getField('detalle').getValue(),
                activosfijos_id: Number(this.getField('activosfijos_id').getValue()),
                activos_fijos_area_id:  Number(this.getField('activos_fijos_area_id').getValue()),
                terceros_id:  Number(this.getField('terceros_id').getValue()),
                sucursales_id:  Number(this.getField('sucursales_id').getValue()),
                documento1_url:  this.archivoImagen1.name,
            }
            this.generalFormatPmv = data._generales;
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: data,
                    success: this.successGuardarActivosFijosGarantias,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    formData: true
                }
            );
        }
    }

    successGuardarActivosFijosGarantias(data) {
        this.getField('btn_aceptar').setDisabled(false);
        if (data.estado_p === 200) {
            this.getField('confirmModalCustom').toggle(false);
            this.gridOptionsActivosFijosGarantias['rowData'] = [];
            this.getField('tb_activosfijosasignaciones').initData(this.gridOptionsActivosFijosGarantias);
            this.getField('tb_activosfijosasignaciones').toggle(false);
            this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20,25,26', 'visible', 'false');
            this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20', 'value,error', 'false');
            this.dibujarTbActivosFijosAsignaciones();
        }else{
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    dibujarTbActivosFijosAsignaciones() {
        this.datosAgregar = { datos: {} }; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosasignaciones', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: this.datosAgregar,
                success: this.successTbActivosFijosAsignaciones,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successTbActivosFijosAsignaciones(data) {
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.getField('tb_activosfijosasignaciones').toggle(true);

            let configCell = new Map();
            this.gridOptionsActivosFijosGarantias['rowData'] = data.data;
            configCell.set('area', { headerName: 'Área'});
            configCell.set('accion', { cellRenderer: this.setButtonTbActivosFijosAsginaciones, headerName: 'Acción',width:360, sortable: false, filter: false });
            configCell.set('fecha_asignacion', { headerName: 'Desde' });
            configCell.set('fecha_vencimiento', { headerName: 'Hasta' });
            configCell.set('detalle', { headerName: 'Detalle' });
            this.getField('tb_activosfijosasignaciones').initData(this.gridOptionsActivosFijosGarantias, configCell);
            this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20,25,26', 'visible', 'false');
            this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20', 'value,error', 'false');
            this.establecerPropiedades('3', 'visible', 'true');
        } else if (data.estado_p === 404) {
            this.establecerPropiedades('3', 'disable', 'false');
            this.establecerPropiedades('3', 'visible', 'true');
            this.gridOptionsActivosFijosGarantias['rowData'] = [];
            this.getField('tb_activosfijosasignaciones').initData(this.gridOptionsActivosFijosGarantias);
            this.getField('tb_activosfijosasignaciones').toggle(false);
        } else {
            this.gridOptionsActivosFijosGarantias['rowData'] = [];
            this.getField('tb_activosfijosasignaciones').initData(this.gridOptionsActivosFijosGarantias);
            this.getField('tb_activosfijosasignaciones').toggle(false);
        }
    }

    setButtonTbActivosFijosAsginaciones(props) {
        let fragment = document.createDocumentFragment();
        let buttonEliminar = this.crearBotonTabla(props.data, 'Eliminar');
        let buttonVerMas = this.crearBotonTabla(props.data, 'Ver más');
        let buttonDevolver = this.crearBotonTabla(props.data, 'Devolver');
        buttonEliminar.setAttribute("class","buttonStyle2");
        buttonVerMas.setAttribute("class","buttonStyle2");
        buttonDevolver.setAttribute("class","buttonStyle2");

        fragment.appendChild(buttonEliminar);
        fragment.appendChild(buttonVerMas);
        fragment.appendChild(buttonDevolver);
        return this.createElementJaivana(fragment);
    }

    /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      */
    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.id}`);
                break;

            case 'Ver más':
                button.setAttribute("id", `buttonVerMas_${data.id}`);
                break;

            case 'Devolver':
                button.setAttribute("id", `buttonDevolver_${data.id}`);
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click ("Eliminar", "Ver más")} boton 
      */
    accionProcesar(data, boton) {
        if (boton === 'Eliminar') {
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.validarEliminar(data.activosfijos_asignaciones_id) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.getField('confirmModalCustom').toggle(true);
        } else if (boton === 'Ver más') {
            this.datos_anteriores=data;
            this.generarImagen();
        }else if (boton === 'Devolver') {
            this.getField('modal_modificar').handleClickOpen();
            this.getField('fecha_retiro').setValue(data.fecha_retiro);
            if(data.fecha_retiro !==null)
                this.getField('estado_retiro').setValue('Entregado por la persona el: ');
            else
                this.getField('estado_retiro').setValue('Seleccione una fecha para devolver: ');
            this.datos_anteriores=data;
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    generarImagen(){
        this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let url = this.constant.formConfiguration();
        let datos=`?body={"datos":{"activosfijos_asignaciones_id":${this.datos_anteriores.activosfijos_asignaciones_id}},
        "generales":{"tipo_servicio":"maes-activosfijosasignaciones","operacion":"traerimagenurl1","operacion_tipo":"consulta"}}`;
        fetch(url+datos,{
            method:'get',
            headers: new Headers({
            'Authorization': 'Bearer '+localStorage.getItem("token"),
            'Content-Type': 'application/json'
        })})
        .then(response => {
            if(response.status!==200){
                return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
                start(controller) {
                return pump();
                function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;
                    }
                    controller.enqueue(value);
                    return pump();
                    });
                }
                }
            })
            }).then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",
                    });
                    window.open(URL.createObjectURL(image))
                }else{
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
                this.getField('confirmModalCustom').toggle(false);
            })
            .catch(err => console.error(err));
            this.operacion="";
    }

    validarEliminar(id) {
        this.getField('confirmModalCustom').toggle(false);
        if (id !== '') {
            let datos = {
                datos: {
                    activosfijos_asignaciones_id: id
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosasignaciones', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'DELETE',
                    body: datos,
                    success: this.successEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successEliminar(data) {
        if (data.estado_p === 200) {
            this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20,25,26', 'visible', 'false');
            this.dibujarTbActivosFijosAsignaciones();
        }
    }

    validarBtnFrm(boton) {
        switch (boton) {
            case "cancelar":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.getField('documento1_url').setValue('');
                    this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20,25,26', 'visible', 'false');
                    this.establecerPropiedades('4,5,6,7,8,9,10,11,12,13,15,20', 'value,error', 'false');
                    this.gridOptionsActivosFijosGarantias['rowData'] = [];
                    this.getField('tb_activosfijosasignaciones').initData(this.gridOptionsActivosFijosGarantias);
                    this.getField('tb_activosfijosasignaciones').toggle(false);
                    this.dibujarTbActivosFijosAsignaciones();
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "aceptar":
                this.validarCrear();
                break;
                case "validarfechas":
                    if (this.getField('fecha_asignacion').getValue() !== '' && this.getField('fecha_vencimiento').getValue() !== '') {
                        let fechaInicial = new Date(this.getField('fecha_asignacion').getValue()).toISOString().split('T')[0];
                        let fechaFinal = new Date(this.getField('fecha_vencimiento').getValue()).toISOString().split('T')[0];
                        if (fechaInicial >= fechaFinal) {
                            this.getField('fecha_vencimiento').setError(true, "¡Fecha hasta, debe ser mayor a fecha desde!");
                            return false;
                        } else if (fechaFinal > fechaInicial) {
                            this.getField('fecha_vencimiento').setError(false, "");
                            return true;
                        }
                    }
                    break;
                case "finicio":
                    if (this.getField('fecha_asignacion').getValue() !== '') {
                        this.getField('fecha_asignacion').setError(false, "");
                        let fechaActual = new Date();
                        let fechaCampo = new Date(this.getField('fecha_asignacion').getValue());
                        let fechaActualFormateada = fechaActual.toISOString().split('T')[0];
                        let fechaCampoFormateada = fechaCampo.toISOString().split('T')[0];
                        if ((fechaCampoFormateada <= fechaActualFormateada) && ((fechaActualFormateada.replaceAll('-','') - fechaCampoFormateada.replaceAll('-','')) <= 50000 || (fechaCampoFormateada === fechaActualFormateada))) {
                            this.validarBtnFrm('validarfechas');
                            return true;
                        } else {
                            this.getField('fecha_asignacion').setError(true, "¡La fecha debe ser igual o inferior a 5 años de la fecha actual!");
                            return false;
                        }
                    }
                    break;
            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }

    //*** fin  los botones y los success

    validarModificar(){
        if(this.getField('fecha_retiro').valid()){
            let fechaActual = new Date().toISOString().split('T')[0];
            let fechaRetiro = new Date(this.getField('fecha_retiro').getValue()).toISOString().split('T')[0];
            if((fechaRetiro>fechaActual)===false){
                if(this.getField('fecha_retiro').getValue()>=this.datos_anteriores.desde){
                    this.modificarRegistro();
                }else{
                    this.alertGeneral.toggle(true, 'La fecha retiro no puede ser menor a la fecha de asignación.', 'error');
                }
                
            }else{
                this.alertGeneral.toggle(true, 'La fecha retiro no puede ser mayor al día actual.', 'error');
            }
        }
    }

    validarCrear(){
        let errores=0;
        if (!(this.validarBtnFrm('finicio') && this.validarBtnFrm('validarfechas') && this.getField('fecha_vencimiento').valid()))
            errores++
        if(!this.getField('nit_buscar').valid())
            errores++;
        if(!this.getField('nombre').valid())
            errores++;
        if(!this.getField('sede').valid())
            errores++;
        if(!this.getField('codigo_activo_fijo').valid())
            errores++;
        if(!this.getField('descripcion_activo_fijo').valid())
            errores++;
        if(!this.getField('descripcion_area').valid()){
            errores++;
        }else{
            if(this.getField('activos_fijos_area_id').getValue()!=='' && this.getField('activos_fijos_area_id').getValue()!==' '){
                this.getField('descripcion_area').setError(false,'');
            }else{
                this.getField('descripcion_area').setError(true,'Es necesario buscar y seleccionar un area.');
                errores++;
            }
        }     
        if(!this.getField('codigo_sucursal').valid())
            errores++;
        if(!this.getField('nombre_sucursal').valid())
            errores++;
        if(!this.getField('detalle').valid())
            errores++;

        if(errores===0){
            if (this.archivoImagen1 !== '' && this.estadoUploadFila && this.getField('documento1_url').valid()) {
                // this.archivo = true;
                this.getField('btn_aceptar').setDisabled(true);
                this.getField('documento1_url').setError(false, '');
                let datosAgregar = { datos: {} }; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
                datosAgregar.datos['activosfijos_id'] = this.getField('activosfijos_id').getValue();
                datosAgregar.datos['fecha_asignacion'] = this.getField('fecha_asignacion').getValue();
                datosAgregar.datos['terceros_id'] = this.getField('terceros_id').getValue();
                this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosasignaciones', operacion: '20', operacion_tipo: 'consulta' };
                this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method: 'GET',
                        body: datosAgregar,
                        success: this.guardarActivosFijosGarantias,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                    }
                );
            } else if (!(this.getField('documento1_url').valid())){
                this.alertGeneral.toggle(true, 'Cargue por lo menos la primer imagen.', 'error');
                this.getField('documento1_url').setError(true, '* Este campo es requerido.');
                // this.archivo = false;
            } else if (this.estadoUploadFila === false){
                this.getField('documento1_url').setError(true, '* Valide el archivo adjunto.');
            }
        }else {
            this.alertGeneral.toggle(true, 'Valide que los campos estén correctamente diligenciados.', 'error');
        }
    }

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }
}
FormJaivana.addController('maes-activosfijosasignaciones', CustomActivosFijosAsignaciones);
export default CustomActivosFijosAsignaciones;