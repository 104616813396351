import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomConfiguracionBasica
 * @author: Anderson Acevedo Briñez
 * @updateBy Jose Albeiro Marin
 * @updateBy Sebastian Rios Echeverri
 * @version: jdesk_1.01.0001
 **/
class CustomConfiguracionBasica extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                                       = this.initForm.bind(this);
        this.arrayObjetos                                   = new Map();
        this.arrayCampos                                    = new Map();
        this.crearFormato                                   = this.crearFormato.bind(this);
        this.successcrear                                   = this.successcrear.bind(this);
        this.actualiarFormato                               = this.actualiarFormato.bind(this);
        this.successActualizarFormato                        = this.successActualizarFormato.bind(this);
        this.formulario='';
        this.traerTodosFormatos                             = this.traerTodosFormatos.bind(this);
        this.successTraerTodosFormatos                      = this.successTraerTodosFormatos.bind(this);
        this.traerPrimeroFormatos                           = this.traerPrimeroFormatos.bind(this);
        this.successTraerPrimeroFormatos                    = this.successTraerPrimeroFormatos.bind(this);
        this.traerPorIdFormatos                             = this.traerPorIdFormatos.bind(this);
        this.successTraerPorIdFormatos                      = this.successTraerPorIdFormatos.bind(this);
        this.validarFormatoEliminar                         = this.validarFormatoEliminar.bind(this);
        this.succesValidarFormatoEliminar                   = this.succesValidarFormatoEliminar.bind(this);
        this.eliminarPorIdFormatos                          = this.eliminarPorIdFormatos.bind(this);
        this.successEliminarPorIdFormatos                   = this.successEliminarPorIdFormatos.bind(this);
        this.btnModificar                                   = false;
        this.onClickChange                                  = this.handlerClickChange.bind(this);
        this.onClickShowTable                               = this.handerClickShowTable.bind(this);
        this.onClickNew                                     = this.handlerClickNew.bind(this);
        this.onClickDelete                                  = this.handlerClickDelete.bind(this);
        this.onSelectionChanged                             =   this.onSelectionChanged.bind(this);
        this.registro_seleccionado=false;
        this.mostrarMensajeSeleccionModificar               =   this.mostrarMensajeSeleccionModificar.bind(this);
        this.mostrarMensajeSeleccionEliminar                =   this.mostrarMensajeSeleccionEliminar.bind(this);
        this.successValidarUniqueFormatos                   =   this.successValidarUniqueFormatos.bind(this);
        this.traerTodosConceptos                            =   this.traerTodosConceptos.bind(this);
        this.successTraerTodosConceptos                     =   this.successTraerTodosConceptos.bind(this);
        this.traerPrimeroConceptos                          =   this.traerPrimeroConceptos.bind(this);
        this.successTraerPrimeroConceptos                   =   this.successTraerPrimeroConceptos.bind(this);
        this.validacionUniqueConceptos                      =   this.validacionUniqueConceptos.bind(this);
        this.successValidarUniqueConceptos                  =   this.successValidarUniqueConceptos.bind(this);
        this.traerPorIdConceptos                            =   this.traerPorIdConceptos.bind(this);
        this.successTraerPorIdConceptos                     =   this.successTraerPorIdConceptos.bind(this);
        this.confirmarEliminacionConceptos                  =   this.confirmarEliminacionConceptos.bind(this);
        this.successEliminarConceptos                       =   this.confirmarEliminacionConceptos.bind(this);
        this.validarConceptosEliminar                       =   this.validarConceptosEliminar.bind(this);
        this.succesValidarConceptoEliminar                 =   this.succesValidarConceptoEliminar.bind(this);
        this.eliminarPorIdConceptos                         =   this.eliminarPorIdConceptos.bind(this);
        this.successEliminarPorIdConceptos                  =   this.successEliminarPorIdConceptos.bind(this);
        this.crearConcepto                                  =   this.crearConcepto.bind(this);
        this.successcrearconcepto                           =   this.successcrearconcepto.bind(this);
        this.actualiarConceptos                             =   this.actualiarConceptos.bind(this);
        this.successActualizarConceptos                     =   this.successActualizarConceptos.bind(this);
        this.allowedExtensions                              = /(.XSD|.xsd)$/i;
        this.currencyFormatterGeneral                       = this.currencyFormatterGeneral.bind(this);
        this.formatNumberSaldo                              = this.formatNumberSaldo.bind(this);
    }

    initForm(){
        console.log('Formulario CustomConfiguracionBasica,  @version: jdesk_1.01.0001, @author:Anderson Acevedo Briñez, @updateBy: Sebastian Rios Echeverri')

        this.arrayCampos.set(1,'codigo_formato');
        this.arrayCampos.set(2,'nombre_formato');
        this.arrayCampos.set(3,'archivo_fisico');
        this.arrayCampos.set(18,'valor_cuantias_menores');
        this.arrayCampos.set(4,'formatos_id');
        this.arrayCampos.set(5,'codigo_concepto');
        this.arrayCampos.set(6,'nombre_concepto');
        this.arrayCampos.set(7,'conceptos_id');
        this.arrayCampos.set(8,'naturaleza_concepto');
        this.arrayCampos.set(30,'btn_agregar');
        this.arrayCampos.set(31,'btn_cancelar');
        this.arrayCampos.set(32,'btn_modificar');
        this.arrayCampos.set(33,'btn_cancelar_modificar');
        //this.arrayCampos.set(34,'operacion');

        this.arrayObjetos.set(1,'codigo_formato');
        this.arrayObjetos.set(2,'nombre_formato');
        this.arrayObjetos.set(3,'archivo_fisico');
        this.arrayObjetos.set(18,'valor_cuantias_menores');
        this.arrayObjetos.set(4,'formatos_id');
        this.arrayObjetos.set(5,'codigo_concepto');
        this.arrayObjetos.set(6,'nombre_concepto');
        this.arrayObjetos.set(7,'conceptos_id');
        this.arrayObjetos.set(8,'naturaleza_concepto');
        this.arrayObjetos.set(30,'btn_agregar');
        this.arrayObjetos.set(31,'btn_cancelar');
        this.arrayObjetos.set(32,'btn_modificar');
        this.arrayObjetos.set(33,'btn_cancelar_modificar');
        //this.arrayObjetos.set(34,'operacion');
        this.establecerPropiedades('1,2,3,18,4,5,6,8','visible','false')
        this.establecerPropiedades('1,2,3,18,4,5,6,8','disable','true')
        if(this.props.data[0].opcion_sub_seccion === 1){
            this.opcionFormatos();
        }else if(this.props.data[0].opcion_sub_seccion === 2){
            this.opcionConceptos();
        }
        
        this.getField('btn_cancelar').setClick(()=>{
            if(this.formulario==='formatos'){
                this.btnCancelarFormatos();
            }else{
                this.btnCancelarFormatos();
            }
        });
        this.getField('btn_agregar').setClick(()=>{
            if(this.formulario==='formatos'){
                //valida expresion
                if(this.getField('codigo_formato').valid()&&this.getField('nombre_formato').valid()&&this.getField('archivo_fisico').valid()&&this.getField('valor_cuantias_menores').valid())
                    this.validacionUniqueFormatos();
            }else{
                if(this.getField('codigo_concepto').valid()&&this.getField('nombre_concepto').valid() && this.getField('naturaleza_concepto').valid())
                    this.validacionUniqueConceptos();
            }
        });
        this.getField('btn_modificar').setClick(()=>{
            if(this.formulario==='formatos'){
                //valida expresion
                if(this.getField('codigo_formato').valid()&&this.getField('nombre_formato').valid()&&this.getField('archivo_fisico').valid()&&this.getField('valor_cuantias_menores').valid())
                    this.validacionUniqueFormatos();
            }else{
                if(this.getField('codigo_concepto').valid()&&this.getField('nombre_concepto').valid() && this.getField('naturaleza_concepto').valid())
                    this.validacionUniqueConceptos();
            }
        });
        this.getField('btn_cancelar_modificar').setClick(()=>{
            this.btnModificar = false
            if(this.formulario==='formatos'){
                this.btnCancelarFormatos();
            }else{
                this.btnCancelarFormatos();
            }
        });

        this.getField('archivo_fisico').setOnChange(()=>{
            if(!this.getField('archivo_fisico').valid()){
                if(this.getField('archivo_fisico').getValue()!=='')
                    this.getField('archivo_fisico').setError(true,'* El valor no cumple con el formato deseado , solo permite extensión .XSD o .xsd')
                else
                    this.getField('archivo_fisico').setError(false,'') 
            } 
        });

    } 

    onSelectionChanged = () =>{
        this.registro_seleccionado=true;
        let filaSeleccionada = this.gridOptionsTableDefault.api.getSelectedRows();
        if(this.formulario==='formatos'){    
            this.getField("codigo_formato").setValue(filaSeleccionada[0].codigo_formato);
            this.getField("nombre_formato").setValue(filaSeleccionada[0].nombre_formato);
            this.getField("archivo_fisico").setValue(filaSeleccionada[0].archivo_fisico);
            this.getField("valor_cuantias_menores").setValue(filaSeleccionada[0].valor_cuantias_menores === null || filaSeleccionada[0].valor_cuantias_menores === undefined || filaSeleccionada[0].valor_cuantias_menores === "" ? 0 : filaSeleccionada[0].valor_cuantias_menores);
            this.getField("formatos_id").setValue(filaSeleccionada[0].id);
        }else{
            this.getField("codigo_concepto").setValue(filaSeleccionada[0].codigo_concepto);
            this.getField("nombre_concepto").setValue(filaSeleccionada[0].nombre_concepto);
            this.getField("conceptos_id").setValue(filaSeleccionada[0].id);
            this.getField("naturaleza_concepto").setValue(filaSeleccionada[0].naturaleza_concepto);
        }
    }

    handerClickShowTable() {
        this.registro_seleccionado=false;
        if(this.formulario==='formatos'){    
            this.traerTodosFormatos();
        }else{
            this.traerTodosConceptos();
        }
        
    }

    handlerClickChange() {
        this.btnModificar   = true;
        if(this.formulario==='formatos'){
            if(this.registro_seleccionado){
                this.registro_seleccionado=false;
                this.establecerPropiedades('1,2,3,18','disable','false');
                this.establecerPropiedades('32,33','visible','true');
                this.buttons.get("btn_new").ref.current.setVisible(false);
                this.buttons.get("btn_show").ref.current.setVisible(false);
                this.buttons.get("btn_change").ref.current.setVisible(false);
                this.buttons.get("btn_delete").ref.current.setVisible(false);
                this.getField('tableShowData').toggle(false);
            }else{
                this.mostrarMensajeSeleccionModificar();
            }
        }else{
            if(this.registro_seleccionado){
                this.registro_seleccionado=false;
                this.establecerPropiedades('5,6,8','disable','false');
                this.establecerPropiedades('32,33','visible','true');
                this.buttons.get("btn_new").ref.current.setVisible(false);
                this.buttons.get("btn_show").ref.current.setVisible(false);
                this.buttons.get("btn_change").ref.current.setVisible(false);
                this.buttons.get("btn_delete").ref.current.setVisible(false);
                this.getField('tableShowData').toggle(false);
            }else{
                this.mostrarMensajeSeleccionModificar();
            }
        }
    }

    handlerClickNew() {
        this.btnModificar   = false;
        this.registro_seleccionado=false;
        if(this.formulario==='formatos'){
            this.establecerPropiedades('1,2,3,18','disable','false');
            this.establecerPropiedades('30,31','visible','true');
            this.establecerPropiedades('1,2,3,18','value','');
        }else{
            this.establecerPropiedades('5,6,8','disable','false');
            this.establecerPropiedades('30,31','visible','true');
            this.establecerPropiedades('5,6,8','value','');
        }
        this.buttons.get("btn_new").ref.current.setVisible(false);
        this.buttons.get("btn_show").ref.current.setVisible(false);
        this.buttons.get("btn_change").ref.current.setVisible(false);
        this.buttons.get("btn_delete").ref.current.setVisible(false);
        this.getField('tableShowData').toggle(false);
        
    }

    handlerClickDelete() {
        if(this.formulario==='formatos'){   
            if(this.registro_seleccionado){
                this.confirmarEliminacionFormatos();
                this.registro_seleccionado=false;
            } else{
                this.mostrarMensajeSeleccionEliminar();
            }
        }else{
            if(this.registro_seleccionado){
                this.confirmarEliminacionConceptos();
                this.registro_seleccionado=false;
            } else{
                this.mostrarMensajeSeleccionEliminar();
            }
        }
        
    }

    mostrarMensajeSeleccionModificar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para modificar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeSeleccionEliminar() {
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'No se ha seleccionado ningún registro para eliminar');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    btnCancelarFormatos() {
        this.getField('confirmModalCustom').toggle(false);

        this.getField('btn_agregar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.buttons.get("btn_new").ref.current.setDisabled(false);
        this.buttons.get("btn_show").ref.current.setDisabled(false);
        this.buttons.get("btn_delete").ref.current.setDisabled(false);
        if(this.formulario==='formatos'){
            this.getField('archivo_fisico').setError(false,'');
            this.getField('valor_cuantias_menores').setError(false,'');
            this.getField('nombre_formato').setError(false,'');            
            this.getField('codigo_formato').setError(false,'');            
            this.establecerPropiedades('1,2,3,18,4', 'disable', 'true');
            this.establecerPropiedades('1,2,3,18,4','value','');
            this.establecerPropiedades('1,2,3,18,4','error','false');
            this.traerPrimeroFormatos();
            this.traerTodosFormatos();
        }else{
            this.establecerPropiedades('5,6,8', 'disable', 'true');
            this.establecerPropiedades('5,6','value','');
            this.establecerPropiedades('5,6,8','error','false');
            this.traerPrimeroConceptos();
            this.traerTodosConceptos();
        }
        
        
        this.buttons.get("btn_new").ref.current.setVisible(true);
        this.buttons.get("btn_show").ref.current.setVisible(true);
        this.buttons.get("btn_change").ref.current.setVisible(true);
        this.buttons.get("btn_delete").ref.current.setVisible(true);
        this.gridOptionsTableDefault["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTableDefault);
        this.getField('tableShowData').toggle(false);
        this.btnModificar = false;
    }

    btnCancelarFormatosOperacion() {
        this.getField('confirmModalCustom').toggle(false);

        this.getField('btn_agregar').setVisible(false);
        this.getField('btn_cancelar').setVisible(false);
        this.getField('btn_modificar').setVisible(false);
        this.getField('btn_cancelar_modificar').setVisible(false);
        this.buttons.get("btn_new").ref.current.setDisabled(false);
        this.buttons.get("btn_show").ref.current.setDisabled(false);
        this.buttons.get("btn_delete").ref.current.setDisabled(false);
        if(this.formulario==='formatos'){
            this.establecerPropiedades('1,2,3,18,4', 'disable', 'true');
            this.establecerPropiedades('1,2,3,18','value','');
            this.establecerPropiedades('1,2,3,18,4','error','false');
            this.traerPorIdFormatos();
            this.establecerPropiedades('4','value','');
            this.traerTodosFormatos();
        }else{  
            this.establecerPropiedades('5,6,8', 'disable', 'true');
            this.establecerPropiedades('5,6','value','');
            this.establecerPropiedades('5,6','error','false');
            this.traerPorIdConceptos();
            this.establecerPropiedades('7','value','');
        }
        this.buttons.get("btn_new").ref.current.setVisible(true);
        this.buttons.get("btn_show").ref.current.setVisible(true);
        this.buttons.get("btn_change").ref.current.setVisible(true);
        this.buttons.get("btn_delete").ref.current.setVisible(true);
        this.gridOptionsTableDefault["rowData"] = [];
        this.getField('tableShowData').initData(this.gridOptionsTableDefault);
        this.getField('tableShowData').toggle(false);
        
        this.btnModificar = false;
    }


    opcionFormatos(){
        this.formulario='formatos';
        //this.getField('operacion').setValue('Formatos');
        this.establecerPropiedades('1,2,3,18','visible','true');
        this.traerPrimeroFormatos();
    }

    opcionConceptos(){
        this.formulario='conceptos';
        //this.getField('operacion').setValue('Conceptos');
        this.establecerPropiedades('5,6,8','visible','true');
        this.traerPrimeroConceptos();
        
    }

    traerTodosFormatos()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '1_formatos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTodosFormatos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerTodosFormatos(data)
    {
        if (data.estado_p === 200)
        {
            let configCell = new Map();
            configCell.set('codigo_concepto', {cellRenderer:function(props){return props.data.codigo_concepto}, hide: true, field: 'codigo_concepto' });
            configCell.set('nombre_concepto', {cellRenderer:function(props){return props.data.nombre_concepto}, hide: true, field: 'nombre_concepto' });
            configCell.set('valor_cuantias_menores', {cellRenderer:(props) => {return ""+this.currencyFormatterGeneral(props.data.valor_cuantias_menores === null || props.data.valor_cuantias_menores === undefined || props.data.valor_cuantias_menores === "" ? 0 : props.data.valor_cuantias_menores) }, cellStyle: {textAlign:"right"},width: 110});

            this.gridOptionsTableDefault['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            this.gridOptionsTableDefault['onSelectionChanged'] =this.onSelectionChanged;
            this.getField('tableShowData').initData(this.gridOptionsTableDefault,configCell);
            

        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerPrimeroFormatos()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '3_formatos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerPrimeroFormatos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerPrimeroFormatos(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_formato').setValue(data.data[0].codigo_formato);
            this.getField('nombre_formato').setValue(data.data[0].nombre_formato);
            this.getField('archivo_fisico').setValue(data.data[0].archivo_fisico);
            this.getField('valor_cuantias_menores').setValue(data.data[0].valor_cuantias_menores === null || data.data[0].valor_cuantias_menores === undefined || data.data[0].valor_cuantias_menores === "" ? 0 : data.data[0].valor_cuantias_menores);
            this.getField('formatos_id').setValue(data.data[0].id);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validacionUniqueFormatos()
    {
        let datos={ datos: {
            codigo_formato:this.getField('codigo_formato').getValue()
        }};
        let operacion='20_formatos';
        if(this.btnModificar){
            datos.datos['formatos_id']=this.getField('formatos_id').getValue();
            operacion='20_formatos_modificar';
        } 
        this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarUniqueFormatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
        
    }

    successValidarUniqueFormatos(data)
    {
        if (data.estado_p === 200)
        {
            this.alertGeneral.toggle(true, 'Código formato ya se encuentra asignado a un formato.', 'error');
        }
        else 
        {
           if(data.estado_p===404) {
                if(this.btnModificar){
                    this.actualiarFormato();
                }else{
                    this.crearFormato();
                }
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerPorIdFormatos()
    {
       let datos={ datos: {
            formatos_id:this.getField('formatos_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '2_formatos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerPorIdFormatos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerPorIdFormatos(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_formato').setValue(data.data[0].codigo_formato);
            this.getField('nombre_formato').setValue(data.data[0].nombre_formato);
            this.getField('archivo_fisico').setValue(data.data[0].archivo_fisico);
            this.getField('valor_cuantias_menores').setValue(data.data[0].valor_cuantias_menores === null || data.data[0].valor_cuantias_menores === undefined || data.data[0].valor_cuantias_menores === "" ? 0 : data.data[0].valor_cuantias_menores);
            this.getField('formatos_id').setValue(data.data[0].id);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    confirmarEliminacionFormatos(){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(this.validarFormatoEliminar);
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');       
        this.getField('confirmModalCustom').toggle(true);
    }

    validarFormatoEliminar(){
        this.getField('confirmModalCustom').toggle(false);
        let datos={ datos: {
            formatos_id:this.getField('formatos_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '7_1_formatos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesValidarFormatoEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    succesValidarFormatoEliminar(data){
        if (data.data[0].cuantos === 0){
            this.eliminarPorIdFormatos();

        }else {
            this.alertGeneral.toggle(true,'* Este exoformatoid existe en alguno(s) exogena_formatos_conceptos_columnas, no se permite eliminar', 'error')
            this.getField('confirmModalCustom').toggle(false);

        }
    }

    eliminarPorIdFormatos()
    {
       let datos={ datos: {
            formatos_id:this.getField('formatos_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '7_formatos', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminarPorIdFormatos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successEliminarPorIdFormatos(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('confirmModalCustom').toggle(false);
            this.traerPrimeroFormatos();
            this.traerTodosFormatos();
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
                this.getField('confirmModalCustom').toggle(false);
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    crearFormato(){
        let datos = {  datos: { 
            codigo_formato:this.getField('codigo_formato').getValue(),
            nombre_formato:this.getField('nombre_formato').getValue(),
            archivo_fisico:this.getField('archivo_fisico').getValue(),
            valor_cuantias_menores:this.getField('valor_cuantias_menores').getValue(),
            }
        }
        this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '5_formatos', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successcrear,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successcrear(data)
    {
        if (data.estado_p === 200 )
        {
            this.getField('formatos_id').setValue(data.data[0].formatos_id);
            this.btnCancelarFormatosOperacion();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualiarFormato(){
        if(this.getField('formatos_id').getValue()!=='' && this.getField('formatos_id').getValue()!==' '){
            let datos = {  datos: { 
                    codigo_formato:this.getField('codigo_formato').getValue(),
                    nombre_formato:this.getField('nombre_formato').getValue(),
                    archivo_fisico:this.getField('archivo_fisico').getValue(),
                    valor_cuantias_menores:this.getField('valor_cuantias_menores').getValue(),
                    formatos_id:this.getField('formatos_id').getValue()
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '6_formatos', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successActualizarFormato,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successActualizarFormato(data){
        if (data.estado_p === 200 )
        {
            this.getField('formatos_id').setValue(data.data[0].formatos_id);
            this.btnCancelarFormatosOperacion();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    //metodos conceptos

    traerTodosConceptos()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '1_conceptos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTodosConceptos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerTodosConceptos(data)
    {
        if (data.estado_p === 200)
        {
            //armar tabla
            let configCell = new Map();
            configCell.set('codigo_formato', {cellRenderer:function(props){return props.data.codigo_formato}, hide: true, field: 'codigo_formato' });
            configCell.set('nombre_formato', {cellRenderer:function(props){return props.data.nombre_formato}, hide: true, field: 'nombre_formato' });
            configCell.set('archivo_fisico', {cellRenderer:function(props){return props.data.archivo_fisico}, hide: true, field: 'archivo_fisico' });
            configCell.set('valor_cuantias_menores', {cellRenderer:function(props){return props.data.valor_cuantias_menores}, hide: true, field: 'valor_cuantias_menores' });
           
            this.gridOptionsTableDefault['rowData'] = data.model;//los datos del servicio se cargan en la tabla por defecto del crud
            this.gridOptionsTableDefault['onSelectionChanged'] =this.onSelectionChanged;
            this.getField('tableShowData').initData(this.gridOptionsTableDefault,configCell);
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerPrimeroConceptos()
    {
       let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '3_conceptos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerPrimeroConceptos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerPrimeroConceptos(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_concepto').setValue(data.data[0].codigo_concepto);
            this.getField('nombre_concepto').setValue(data.data[0].nombre_concepto);
            this.getField('conceptos_id').setValue(data.data[0].id);
            this.getField('naturaleza_concepto').setValue(data.data[0].naturaleza_concepto)
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validacionUniqueConceptos()
    {
       let datos={ datos: {
            codigo_concepto:this.getField('codigo_concepto').getValue()
       }};
       let operacion='20_conceptos';
       if(this.btnModificar){
            datos.datos['conceptos_id']=this.getField('conceptos_id').getValue();
            operacion='20_conceptos_modificar';
       } 
        this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: operacion, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successValidarUniqueConceptos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }

    successValidarUniqueConceptos(data)
    {
        if (data.estado_p === 200)
        {
            this.alertGeneral.toggle(true, 'Código concepto ya se encuentra asignado a un concepto.', 'error');
        }
        else 
        {
           if(data.estado_p===404) {
                if(this.btnModificar){
                    this.actualiarConceptos();
                }else{
                    this.crearConcepto();
                }
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    traerPorIdConceptos()
    {
       let datos={ datos: {
            conceptos_id:this.getField('conceptos_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '2_conceptos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerPorIdConceptos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successTraerPorIdConceptos(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_concepto').setValue(data.data[0].codigo_concepto);
            this.getField('nombre_concepto').setValue(data.data[0].nombre_concepto);
            this.getField('conceptos_id').setValue(data.data[0].id);
            this.getField('naturaleza_concepto').setValue(data.data[0].naturaleza_concepto);
            this.traerTodosConceptos();
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    confirmarEliminacionConceptos(){
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar','Está seguro de realizar esta acción?');
        this.getField('confirmModalCustom').setClickDialog(this.validarConceptosEliminar);
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField("confirmModalCustom").setButtonConfirm('Confirmar');       
        this.getField('confirmModalCustom').toggle(true);
    }

    validarConceptosEliminar(){
        this.getField('confirmModalCustom').toggle(false);
        let datos={ datos: {
            conceptos_id:this.getField('conceptos_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '7_1_conceptos', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.succesValidarConceptoEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    succesValidarConceptoEliminar(data){
        if (data.data[0].cuantos === 0){
            this.eliminarPorIdConceptos();

        }else {
            this.alertGeneral.toggle(true,'* Este exoconceptoid existe en alguno(s) exogena_formatos_conceptos_columnas, no se permite eliminar', 'error')
            this.getField('confirmModalCustom').toggle(false);

        }
    }

    eliminarPorIdConceptos()
    {
       let datos={ datos: {
            conceptos_id:this.getField('conceptos_id').getValue()
       }};
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '7_conceptos', operacion_tipo: 'eliminar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successEliminarPorIdConceptos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
    }

    successEliminarPorIdConceptos(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('confirmModalCustom').toggle(false);
            this.traerPrimeroConceptos();
            this.traerTodosConceptos();
        }
        else 
        {
           if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'No se encontro.', 'error');
                this.getField('confirmModalCustom').toggle(false);
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    crearConcepto(){
        let datos = {  datos: { 
            codigo_concepto:this.getField('codigo_concepto').getValue(),
            nombre_concepto:this.getField('nombre_concepto').getValue(),
            naturaleza_concepto:this.getField('naturaleza_concepto').getValue()
            }
        }
        this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '5_conceptos', operacion_tipo: 'crear' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successcrearconcepto,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successcrearconcepto(data)
    {
        if (data.estado_p === 200 )
        {
            this.getField('conceptos_id').setValue(data.data[0].conceptos_id);
            this.btnCancelarFormatosOperacion();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    actualiarConceptos(){
        if(this.getField('conceptos_id').getValue()!=='' && this.getField('conceptos_id').getValue()!==' '){
            let datos = {  datos: { 
                    codigo_concepto:this.getField('codigo_concepto').getValue(),
                    nombre_concepto:this.getField('nombre_concepto').getValue(),
                    naturaleza_concepto:this.getField('naturaleza_concepto').getValue(),
                    conceptos_id:this.getField('conceptos_id').getValue()
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-configuracionbasica', operacion: '6_conceptos', operacion_tipo: 'modificar' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'PUT',
                    body: datos,
                    success: this.successActualizarConceptos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successActualizarConceptos(data){
        if (data.estado_p === 200 )
        {
            this.getField('conceptos_id').setValue(data.data[0].conceptos_id);
            this.btnCancelarFormatosOperacion();
        }
        else 
        {
           if(data.estado_p===404) {
               this.alertGeneral.toggle(true, 'Error', 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }



    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
                else if (sPropiedad === 'error') {
                    if (sValor === 'false') {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                    }
                }
      });     
   }

   currencyFormatterGeneral(number)
   {
       let decimal = (number + "").split(".")[1];//para manejar los decimales
         if ((decimal !== 0) && (decimal !== undefined)) {
           if(decimal.length>2){
             decimal=(Number(number).toFixed(2) + "").split(".")[1];
           }
         return (this.formatNumberSaldo(number) + "," + decimal);
         } else {
         return this.formatNumberSaldo(number); }
   }

   formatNumberSaldo(number)
   {
           return Number(number).toString().split('.')[0]
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
   }

}
FormJaivana.addController("cont-configuracionbasica",CustomConfiguracionBasica);
export default CustomConfiguracionBasica