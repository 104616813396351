import FormJaivana from 'dashboard_jaivana_v1';
/**
 * @description: CustomDatosSagrilaf
 * @author: Anderson Acevedo Briñe
 * @updateBy 
 * @version: jdesk_1.01.0001
 **/
class CustomDatosSagrilaf extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                   = this.initForm.bind(this);
        this.arrayObjetos               = new Map();
        this.arrayCampos                = new Map();
        this.codigo_usuario                     = "";
        this.gridOptionsTerceros = Object.assign({},this.gridOptions);
        this.agregarDatos             = this.agregarDatos.bind(this);
        this.successAgregarDatos      = this.successAgregarDatos.bind(this);
        this.validarUnique            = this.validarUnique.bind(this);
        this.successValidarUnique     = this.successValidarUnique.bind(this);
        this.consultarDatosSagrilaf   = this.consultarDatosSagrilaf.bind(this);
        this.successConsultarDatosSagrilaf   = this.successConsultarDatosSagrilaf.bind(this);
        this.opcionesAgregar    =this.opcionesAgregar.bind(this);
        this.validarTercero     =this.validarTercero.bind(this);
    }

    initForm(){
            console.log('Formulario CustomDatosSagrilaf,  @version: jdesk_1.01.0001, @author:Anderson Acevedo Briñez')
            this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
            this.arrayCampos.set(2,'linea1');
            this.arrayCampos.set(3,'label1');
            this.arrayCampos.set(4,'nit');
            this.arrayCampos.set(5,'label2');
            this.arrayCampos.set(6,'sede');
            this.arrayCampos.set(7,'label3');
            this.arrayCampos.set(8,'nombre');
            this.arrayCampos.set(9,'label4');
            this.arrayCampos.set(10,'observaciones');
            this.arrayCampos.set(11,'label5');
            this.arrayCampos.set(12,'fecha');
            this.arrayCampos.set(13,'modificar');
            this.arrayCampos.set(14,'tercero_id');
            this.arrayCampos.set(15,'usuario');
            this.arrayCampos.set(19,'linea2');
            this.arrayCampos.set(20,'label11');
            this.arrayCampos.set(21,'nit2');
            this.arrayCampos.set(22,'label22');
            this.arrayCampos.set(23,'sede2');
            this.arrayCampos.set(24,'consultar');
            this.arrayCampos.set(25,'rejilla');

            this.arrayObjetos.set(2,'linea1');
            this.arrayObjetos.set(3,'label1');
            this.arrayObjetos.set(4,'nit');
            this.arrayObjetos.set(5,'label2');
            this.arrayObjetos.set(6,'sede');
            this.arrayObjetos.set(7,'label3');
            this.arrayObjetos.set(8,'nombre');
            this.arrayObjetos.set(9,'label4');
            this.arrayObjetos.set(10,'observaciones');
            this.arrayObjetos.set(11,'label5');
            this.arrayObjetos.set(12,'fecha');
            this.arrayObjetos.set(13,'modificar');
            this.arrayObjetos.set(14,'tercero_id');
            this.arrayObjetos.set(15,'usuario');
            this.arrayObjetos.set(19,'linea2');
            this.arrayObjetos.set(20,'label11');
            this.arrayObjetos.set(21,'nit2');
            this.arrayObjetos.set(22,'label22');
            this.arrayObjetos.set(23,'sede2');
            this.arrayObjetos.set(24,'consultar');
            this.arrayObjetos.set(25,'rejilla');
            let hoy= new Date();
            let dia=hoy.getDate();
            let mes=hoy.getMonth()+1;
            let año=hoy.getFullYear();
            if(mes<10){
                mes='0'+mes;
            }
            if(dia<10){
                dia='0'+dia;
            }
            this.getField('fecha').setValue(año+'-'+mes+'-'+dia);

            this.establecerPropiedades('2,3,4,5,6,7,8,9,10,11,12,13,14,15,19,20,21,22,23,24','visible','false')
            this.getField('TabJaivana_72').setDisabledButtonTab(true);
            if(this.props.data[0].opcion_sub_seccion === 1){
                this.opcionesAgregar();
            }else if(this.props.data[0].opcion_sub_seccion === 2){
                this.opcionesConsultar();
            }
            this.getField('tercero_id').setOnChange(this.validarTercero);
            this.getField('modificar').setClick(this.validarUnique);
            this.getField('consultar').setClick(this.consultarDatosSagrilaf);

    }


    validarTercero(){
        if(this.getField('tercero_id').getValue()!=='' && this.getField('tercero_id').getValue()!==' '){
            if(this.opcion==='Agregar'){
                this.getField('modificar').setDisabled(false);   
            }else{
                this.getField('consultar').setDisabled(false); 
            }
        }else{
            if(this.opcion==='Agregar'){
                this.getField('modificar').setDisabled(true);   
            }else{
                this.getField('consultar').setDisabled(true); 
                this.getField('rejilla').toggle(false);
            }  
        }
        
    }

    opcionesAgregar(){
        this.opcion="Agregar";
        this.getField('modificar').setDisabled(true);
        this.establecerPropiedades('2,3,4,5,6,7,8,9,10,11,12,13','visible','true')
    }

    opcionesConsultar(){
        this.getField('TabJaivana_72').setNextTab();

        this.opcion= "Consultar"
        this.establecerPropiedades('19,20,21,22,23,24','visible','true')
        this.getField('consultar').setDisabled(true);
    }

    agregarDatos(){
        if(this.getField('nit').valid()&&
            this.getField('sede').valid()){
            let datos={ 
                datos: {
                    tercero_id:this.getField('tercero_id').getValue(), 
                    usuario: this.codigo_usuario ,
                    observaciones: this.getField('observaciones').getValue(), 
                    fecha: this.getField('fecha').getValue(),
                    nit:this.getField('nit').getValue(),
                    sede:this.getField('sede').getValue()
                }};
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerossagrilaf', operacion: '5', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successAgregarDatos,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successAgregarDatos(data){
        if (data.estado_p === 200 )
        {
            this.getField('nit').input.focus();
            this.getField('nit').setValue('');
            this.getField('nombre').setValue('');  
            this.getField('sede').setValue('');      
            this.getField('tercero_id').setValue('');    
            this.getField('observaciones').setValue('');  
        }
        else 
        {
           if(data.estado_p===404) {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
           else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    validarUnique(){
        if(this.getField('nit').valid()&&
            this.getField('sede').valid()
            && this.getField('nombre').valid()
            && this.getField('observaciones').valid()
            & this.getField('fecha').valid()){
            let datos={ 
                datos: {
                    tercero_id:this.getField('tercero_id').getValue(),  
                    fecha: this.getField('fecha').getValue()
                }};
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerossagrilaf', operacion: '20', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successValidarUnique,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }

    successValidarUnique(data){
        if (data.estado_p === 200 )
        {
            this.getField('modificar').setDisabled(true);
            this.alertGeneral.toggle(true, 'Ya éxiste la relación fecha y tercero', 'error');   
        }
        else 
        {
            if(data.estado_p===404) {
                this.agregarDatos();
            }
            else {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    consultarDatosSagrilaf(){
        if(this.getField('nit2').valid()&&
            this.getField('sede2').valid()){
            let datos={ 
                datos: {
                    tercero_id:this.getField('tercero_id').getValue(),  
                }};
            this.generalFormatPmv = { tipo_servicio: 'maes-tercerossagrilaf', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successConsultarDatosSagrilaf,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }
    }

    successConsultarDatosSagrilaf(data){
        if (data.estado_p === 200 )
        {
            this.getField('consultar').setDisabled(true);
            this.getField('rejilla').toggle(true);
            let array=[];
            data.data.forEach(element=>{
                let dato={
                    nombre:element.nombre,
                    fecha:element.fecha,
                    usuario:element.usuario,
                    observaciones:element.observaciones
                }
                array.push(dato);
            });
            let configCell = new Map();
            
            this.gridOptionsTerceros['rowData'] = array;
            this.getField('rejilla').initData(this.gridOptionsTerceros, configCell);
        }
        else 
        {
            this.getField('rejilla').toggle(false);
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        };
    }

    establecerPropiedades(sObjetos,sPropiedad,sValor) 
    {
       const array = sObjetos.split(',');
       array.forEach((element) => 
       {
                if(sPropiedad==='disable') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                    }   
                }
                else if(sPropiedad==='visible') 
                { 
                    if(sValor==='true') { 
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                    }
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                    }   
                }
                else if(sPropiedad==='value')
                { 
                    this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
                }  
                else if(sPropiedad==='valid')
                { 
                    if(this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                       //ok
                    }
                    else {
                        this.bHayErrores=true;
                    }
                }
                else if(sPropiedad==='foco')
                { 
                    if(sValor==='rejilla') { } 
                    else {
                        this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                    }
                }
      });     
   }
}
FormJaivana.addController("maes-tercerossagrilaf",CustomDatosSagrilaf);
export default CustomDatosSagrilaf