import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAsignaPermisos
 * @author: Anderson Acevedo Briñez
 * @updateBy 
 * @version: jdesk_1.01.0001
 **/
class CustomAsignaPermisos extends FormJaivana.form {
  constructor(props) {
    super(props);
    this.initForm                          = this.initForm.bind(this);
    this.successAgregarPermiso = this.successAgregarPermiso.bind(this);
    this.agregarPermiso = this.agregarPermiso.bind(this);
    this.limpiarCampos = this.limpiarCampos.bind(this);
    this.gridOptionsComponentes = Object.assign({}, this.gridOptions);
    this.consultarPermisosUsuarios = this.consultarPermisosUsuarios.bind(this);
    this.successConsultarPermisosUsuarios = this.successConsultarPermisosUsuarios.bind(this);
    this.ids=0;
    this.setButtonDelete = this.setButtonDelete.bind(this);
    this.eliminarPermiso = this.eliminarPermiso.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
    this.validarUnique = this.validarUnique.bind(this);
    this.succesConsultaUnique = this.succesConsultaUnique.bind(this);
    this.mostrarCampos = this.mostrarCampos.bind(this);
    this.onSelectionChanged                             =   this.onSelectionChanged.bind(this);
    this.gridOptionsComponentes['onSelectionChanged']         =   this.onSelectionChanged;
    this.limpiarCamposCrear =   this.limpiarCamposCrear.bind(this);
    this.successEliminar  = this.successEliminar.bind(this);
    
  }

  initForm() {
    console.log("Formulario CustomAsignaPermisos,  @version: jdesk_1.01.0001, @author: Anderson Acevedo Briñez");
    this.limpiarCampos();
    this.getField('cargar').setDisabled(true);
    this.getField('cargar').setClick(this.consultarPermisosUsuarios);
    this.getField('agregar').setClick(this.validarUnique);
    this.getField('cancelar').setClick(this.limpiarCampos);
    this.getField('nombre').setOnChange((props)=>{
        if(this.getField('nombre').getValue()==='' || this.getField('nombre').getValue()===' '){
          this.getField('cargar').setDisabled(true);
        }else{
          this.getField('cargar').setDisabled(false);
        }
        this.limpiarCamposCrear();
    });

  }

  onSelectionChanged()
  {

  }

  consultarPermisosUsuarios(){

      if(this.getField('codigou').valid() && this.getField('nombre').valid()){
        let datos = { datos: {
          codigou:this.getField('codigou').getValue()
        }};

        this.generalFormatPmv = { tipo_servicio: 'heus-permisosjaivana', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successConsultarPermisosUsuarios,
                error: this.error_,
                general: this.generalFormatPmv
        });
      }
   }

   successConsultarPermisosUsuarios(data){
       if(data.estado_p === 200){ 
         this.getField('rejilla').toggle(true);
         let configCell = new Map();
         this.gridOptionsComponentes['rowData'] = data.data;
         configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
         this.getField('rejilla').initData(this.gridOptionsComponentes, configCell);
         this.mostrarCampos();
       }else if (data.estado_p === 404){
         this.getField('rejilla').toggle(false);
       }
   }

  limpiarCampos(){
    this.getField('codigop').setValue('');
    this.getField('descripcion').setValue('');
    this.getField('codigop').setVisible(false);
    this.getField('descripcion').setVisible(false);
    this.getField('agregar').setVisible(false);
    this.getField('cancelar').setVisible(false);
    this.getField('codigou').setValue('');
    this.getField('nombre').setValue('');
    this.getField('rejilla').toggle(false);
    this.getField('codigop').setError(false,'');
    this.getField('descripcion').setError(false,'');
    
  }

  limpiarCamposCrear(){
    this.getField('codigop').setValue('');
    this.getField('descripcion').setValue('');
    this.getField('codigop').setVisible(false);
    this.getField('descripcion').setVisible(false);
    this.getField('agregar').setVisible(false);
    this.getField('cancelar').setVisible(false);
    this.getField('rejilla').toggle(false);
    this.getField('codigop').setError(false,'');
    this.getField('descripcion').setError(false,'');
    
  }

  mostrarCampos(){
    this.getField('codigop').setValue('');
    this.getField('descripcion').setValue('');
    this.getField('codigop').setVisible(true);
    this.getField('descripcion').setVisible(true);
    this.getField('agregar').setVisible(true);
    this.getField('cancelar').setVisible(true);
    this.getField('cargar').setDisabled(true);
    this.getField('codigop').setError(false,'');
    this.getField('descripcion').setError(false,'');
  }


  validarUnique(){

    if(this.getField('codigop').valid()&&
      this.getField('nombre').valid()&&
      this.getField('codigou').valid() &&
	    this.getField('descripcion').valid() 

      ){
        let datos={ 
            datos: {
              codigou:this.getField('codigou').getValue(),  
              codigop:this.getField('codigop').getValue()
            }};
        this.generalFormatPmv = { tipo_servicio: 'heus-permisosjaivana', operacion: '20', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.succesConsultaUnique,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
  }

  succesConsultaUnique(data){
      if (data.estado_p === 200 )
      {
        this.alertGeneral.toggle(true, 'El usuario ya cuenta con el permiso.', 'error');
      }
      else 
      {
        if(data.estado_p===404){
          this.agregarPermiso();
        }else{
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
      };
  }

  agregarPermiso(){

    if(this.getField('codigop').valid()&&
    this.getField('nombre').valid()&&
    this.getField('codigou').valid() &&
    this.getField('descripcion').valid()){
        let datos = { datos: {
          codigop:this.getField('codigop').getValue(),
          codigou:this.getField('codigou').getValue(),
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'heus-permisosjaivana', operacion: '5', operacion_tipo: 'crear' };
        this.datos_anteriores=this.generalFormatPmv;
        this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'POST',
                  body: datos,
                  success: this.successAgregarPermiso,
                  error: this.error_,
                  general: this.generalFormatPmv
          });
      }
  }

  successAgregarPermiso(data) 
  {

    if (data.estado_p === 200)
    {
        this.limpiarCamposCrear();
        this.consultarPermisosUsuarios();
    }
    else 
    {
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

  setButtonDelete(props) {
    let id = props.data.permisos_jaivana_id;
    let button = document.createElement("input");
    button.onclick = () => this.eliminarPermiso(id);
    button.setAttribute("id", 'button_delete_' + id);
    button.setAttribute("class", "buttonStyle");
    button.setAttribute("type", "button");
    button.setAttribute("value", "eliminar");
    return this.createElementJaivana(button);
  }

  eliminarPermiso(id) {
    this.ids = id;
    this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
    this.getField('confirmModalCustom').setClickDialog(this.confirmDelete);
    this.getField('confirmModalCustom').toggle(true);
  }

  confirmDelete() {
  this.getField('confirmModalCustom').toggle(false);
  let datos = {
      datos: {
        permisos_jaivana_id: this.ids,
      }
  };
  this.generalFormatPmv = { tipo_servicio: 'heus-permisosjaivana', operacion: '7', operacion_tipo: "eliminar" };// parametros del servicio
  this.service.send(
      {
          endpoint: this.constant.formConfiguration(),
          method: 'DELETE',
          body: datos,
          success: this.successEliminar,
          error: this.error_,
          general: this.generalFormatPmv //se envia el generales de arriba
      });
  }

  successEliminar(data) {
    if(data.estado_p === 200){
      this.consultarPermisosUsuarios();
    }else{
      let respuesta=Object.values(data.data.errores);
      let keys=Object.keys(data.data.errores);
      this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
    }
  }

}
FormJaivana.addController("heus-permisosjaivana", CustomAsignaPermisos);
export default CustomAsignaPermisos;