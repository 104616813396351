import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomAuditorias
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0001.1
 **/
class CustomInicializarDocumentos extends FormJaivana.form {
    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.traerDatos                     = this.traerDatos.bind(this); 
        this.successgenerarModal            = this.successgenerarModal.bind(this); 
        this.gridOptionsComponentes         = Object.assign({},this.gridOptions);
        this.limpiar                        = this.limpiar.bind(this); 
        this.limpiar_todo                   = this.limpiar_todo.bind(this); 
        this.actualizar                     = this.actualizar.bind(this); 
        this.successActualizar              = this.successActualizar.bind(this); 
        this.traerNumeroMod                 = this.traerNumeroMod.bind(this); 
        this.successtraerNumeroMod          = this.successtraerNumeroMod.bind(this); 
        this.setButtonDeleteOperaciones     = this.setButtonDeleteOperaciones.bind(this);
        this.eliminarOperaciones            = this.eliminarOperaciones.bind(this);
        this.confirmDeleteOperacionesCustom = this.confirmDeleteOperacionesCustom.bind(this);
        this.successDeleteoperaciones       = this.successDeleteoperaciones.bind(this);
        this.insertarLog                    = this.insertarLog.bind(this);
        this.successinsertarLog             = this.successinsertarLog.bind(this);
        this.datos                          = this.datos.bind(this);
        this.setButtonVermas                = this.setButtonVermas.bind(this);
        this.Vermas                         = this.Vermas.bind(this);
        this.successVermas                  = this.successVermas.bind(this);
        this.successValidarAnio             = this.successValidarAnio.bind(this);
        this.successConsultarCodigoDoc      = this.successConsultarCodigoDoc.bind(this);
    }

    initForm() {
        console.log("Formulario CustomInicializarDocumentos,  @version: jdesk_1.01.0001.01, @author: Patricia Lopez Sanchez");
        
        this.traerDatos();
        this.getField("codigo_documento").setOnChange(this.limpiar);
        this.getField("codigo_sucursal").setOnChange(this.limpiar);
        this.getField("nombre_doc").setOnChange(this.traerNumeroMod);
        this.getField("modificable").setOnChange(this.datos);
        this.getField("actualizar").setClick(this.actualizar);
        this.getField("cancelar").setClick(this.limpiar_todo);
        this.user_id = JSON.parse(localStorage.getItem('res')).id;
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    }

    limpiar_todo(){
        this.getField("codigo_sucursal").setValue('');
        this.getField("nombre_sucursal").setValue('');
        this.getField("codigo_documento").setValue('');
        this.getField("nombre_documento").setValue('');
        this.getField("numero").setValue('');
        this.getField("modificable").setValue('');

        this.getField("codigo_sucursal").setError(false,'');
        this.getField("nombre_sucursal").setError(false,'');
        this.getField("codigo_documento").setError(false,'');
        this.getField("nombre_documento").setError(false,'');
        this.getField("numero").setError(false,'');
        this.getField("modificable").setError(false,'');
    }

    datos(){
        if(this.getField("modificable").getValue() === 'N'){ 
            this.getField("numero").setDisabled(true)
            this.getField("numero").setError(false,'');
        }else if(this.getField("modificable").getValue() === 'S'){
            this.getField("numero").setDisabled(false)
            this.getField("numero").setError(false,'');
        }
        this.datos_anteriores={
            numero:this.getField("numero").getValue()
        }
    }

    traerDatos(){
        let datos = {datos: {}};    

        this.generalFormatPmv = { tipo_servicio: 'cont-especialesinicializardoc', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successgenerarModal,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });   
    }
            
    successgenerarModal(data){
        if (data.estado_p === 200 )
        {
            this.gridOptionsComponentes['rowData'] = [];
            let configCell = new Map();
            configCell.set('eliminar', { cellRenderer: this.setButtonDeleteOperaciones, sortable: false, filter: false, field: 'eliminar' });
            configCell.set('ver_mas', { cellRenderer: this.setButtonVermas, sortable: false, filter: false, field: 'ver_mas' });
            this.getField('rejilla').toggle(true);
            this.gridOptionsComponentes['rowData'] = data.data;
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
        }
        else 
        {
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }
        
    setButtonVermas(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.Vermas(id);
        button.setAttribute("id", 'button_Add_' + id);
        div.setAttribute('id', 'div_Add_' + id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Ver Mas");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    Vermas(id) {
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `); 
        this.getField('confirmModalCustom').setClickDialog(()=>{});    
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let datos = { datos: {
            registro_id: id,
            usuario_id:this.user_id
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-especialesinicializardoc', operacion: '1_2', operacion_tipo: 'consulta' };
        this.service.send(
        {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successVermas,
              error: this.error_,
              general: this.generalFormatPmv
        });
    }

    successVermas(data){
        this.getField('confirmModalCustom').toggle(false);

        if (data.estado_p === 200) {
            this.getField('confirmModalCustom').setTitleAndContent('Datos Anteriores', 'Datos anteriores: '+data.data[0].datos_anteriores+' modificado por: '+data.data[0].codigo);
            this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);});
            this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField('confirmModalCustom').toggle(true);
        }else if(data.estado_p === 404){
            this.getField('confirmModalCustom').setTitleAndContent('Informativo', 'El registro no ha sido modificado. ');
            this.getField('confirmModalCustom').setClickDialog(()=>{this.getField('confirmModalCustom').toggle(false);});
            this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
            this.getField("confirmModalCustom").setVisibleCancel(true);
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    setButtonDeleteOperaciones(props) {
        let id = props.data.id;
        let div = document.createElement("div");
        let button = document.createElement("input");
        button.onclick = () => this.eliminarOperaciones(props);
        button.setAttribute("id", 'button_Add_' + id);
        div.setAttribute('id', 'div_Add_' + id);
        button.setAttribute("class", "buttonStyle");//,"font-weight: 500;line-height: 1.75;font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;border-radius: 4px;font-size: 0.875rem; box-shadow:0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12) ;width:100%;text-transform: uppercase; height: 100%; background-color:#303f9f;color:#fff;border:1px solid #303f9f;padding-left:5px;padding-rigth:5px;padding-top:2px;padding-bottom:2px;border-radius:3px; cursor:pointer");
        button.setAttribute("type", "button");
        button.setAttribute("value", "Eliminar");
        div.appendChild(button);
        return this.createElementJaivana(div);
    }

    eliminarOperaciones(props) {
        this.id_delete = props.data.id;
        this.codigo_doc=props.data.codigo_documento;
        this.sucursal=props.data.codigo_sucursal;
        this.getField('confirmModalCustom').setTitleAndContent('Eliminar', 'Desea realizar esta operación?');
        this.getField('confirmModalCustom').setClickDialog(this.confirmDeleteOperacionesCustom);
        this.getField('confirmModalCustom').setButtonConfirm('Confirmar');
        this.getField("confirmModalCustom").setVisibleCancel(false);
        this.getField('confirmModalCustom').toggle(true);
    }

    confirmDeleteOperacionesCustom() {
        this.getField('confirmModalCustom').toggle(false);

        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se está validando la información... `); 
        this.getField('confirmModalCustom').setClickDialog(()=>{});    
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);

        let datos = { datos: {
            sucursal: this.codigo_sucursal
        }};
        this.generalFormatPmv = { tipo_servicio: 'cont-especialesinicializardoc', operacion: '1_3', operacion_tipo: 'consulta' };
        this.service.send(
        {
              endpoint: this.constant.formConfiguration(),
              method:'GET',
              body: datos,
              success: this.successValidarAnio,
              error: this.error_,
              general: this.generalFormatPmv,
              showMessage:false
        });
    }
    
    successValidarAnio(data){
        if (data.estado_p === 200) {
            if(data.data[0].valor !== null){
                let datos = { datos: {
                    anio: parseInt(data.data[0].valor),
                    codigo_documento:this.codigo_doc
                }};
                this.generalFormatPmv = { tipo_servicio: 'cont-especialesinicializardoc', operacion: 'validarEliminar', operacion_tipo: 'consulta' };
                this.service.send(
                {
                      endpoint: this.constant.formConfiguration(),
                      method:'GET',
                      body: datos,
                      success: this.successConsultarCodigoDoc,
                      error: this.error_,
                      general: this.generalFormatPmv,
                      showMessage:false
                });
            }else{
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, 'Error, la sucursal no tiene configurado el año en que inicio la contabilización.', 'error');
            }
        }else {
            if(data.estado_p === 404){
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, 'Error, la sucursal no tiene configurado el año en que inicio la contabilización.', 'error');
            } else if(data.estado_p === 502){
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, 'Error en la transacción', 'error');
            }

        }
    }

    successConsultarCodigoDoc(data){
        if (data.estado_p === 200) {
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Error, el código documento no se permite eliminar, existe en la tabla '+data.data.aniomovimiento, 'error');
        }else {
            if(data.estado_p === 404){
                let datos = { datos: {
                sucursales_documentos_id: this.id_delete,
                codigo_sucursal:this.sucursal
                }};
                this.generalFormatPmv = { tipo_servicio: 'cont-especialesinicializardoc', operacion: '7', operacion_tipo: 'eliminar' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'DELETE',
                    body: datos,
                    success: this.successDeleteoperaciones,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage:false 
                });
            } else if(data.estado_p === 502){
                this.getField('confirmModalCustom').toggle(false);
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }

        }
    }

    successDeleteoperaciones(data) {
        if (data.estado_p === 200) {
            this.getField('confirmModalCustom').toggle(false);

            this.getField("codigo_sucursal").setValue('');
            this.getField("nombre_sucursal").setValue('');
            this.getField("codigo_documento").setValue('');
            this.getField("nombre_documento").setValue('');
            this.getField("numero").setValue('');
            this.getField("modificable").setValue('');

            this.traerDatos(data);

            this.getField("codigo_sucursal").setError(false,'');
            this.getField("nombre_sucursal").setError(false,'');
            this.getField("codigo_documento").setError(false,'');
            this.getField("nombre_documento").setError(false,'');
            this.getField("numero").setError(false,'');
            this.getField("modificable").setError(false,'');
        }else{
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Error en la transacción', 'error');
        }
    } 

    actualizar(){
        if(this.getField("codigo_sucursal").valid() && this.getField("nombre_sucursal").valid() && this.getField("codigo_documento").valid() 
         && this.getField("nombre_documento").valid() && this.getField("numero").valid() && this.getField("modificable").valid() ){

            let numero = 0;
            if(this.getField("numero").getValue() !== 0){
                numero= this.getField("numero").getValue() - 1
            }
            let datos = {datos: {
                numero: numero,
                codigo_sucursal: this.getField("codigo_sucursal").getValue(),
                codigo_documento: this.getField("codigo_documento").getValue()
            }};    

            this.generalFormatPmv = { tipo_servicio: 'cont-especialesinicializardoc', operacion: '6', operacion_tipo: 'modificar' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'PUT',
                body: datos,
                success: this.successActualizar,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            }); 
        }
    }

    successActualizar(data){
        if (data.estado_p === 200 )
        {
            this.insertarLog(data);
        }
        else 
        {
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    limpiar(){
        if(this.getField("nombre_sucursal").getValue() === '' || this.getField("nombre_documento").getValue() === ''){
            this.getField("numero").setValue('');
            this.getField("modificable").setValue('');
        }
        if(this.getField("codigo_sucursal").getValue() !== '' ){
            this.getField("codigo_sucursal").setError(false,'')
        }
        if(this.getField("codigo_documento").getValue() !== '' ){
            this.getField("codigo_documento").setError(false,'')
        }
    }

    insertarLog(data){
        let numero=0;
        if (this.getField("numero").getValue() !== 0) {
            numero=this.getField("numero").getValue() - 1
        }
        this.datos_nuevos={
            numero:numero
        }
        let datos = {datos: {
            nombre_tabla: 'sucursales_documentos',
            operacion: 'Actualizar consecutivo',
            registro_id: data.data[0].sucursales_documentos_id,
            datos_anteriores: JSON.stringify(this.datos_anteriores),
            datos_nuevos: JSON.stringify(this.datos_nuevos),
            fecha_modificacion: 'now()',
            usuario_id: this.user_id
        }};    

        this.generalFormatPmv = { tipo_servicio: 'cont-especialesinicializardoc', operacion: '5', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successinsertarLog,
            error: this.error_,
            general: this.generalFormatPmv,
            showMessage: false
        });
    }

    successinsertarLog(data){
        if (data.estado_p === 200 )
        {
            this.getField("codigo_sucursal").setValue('');
            this.getField("nombre_sucursal").setValue('');
            this.getField("codigo_documento").setValue('');
            this.getField("nombre_documento").setValue('');
            this.getField("numero").setValue('');
            this.getField("modificable").setValue('');

            this.traerDatos();

            this.getField("codigo_sucursal").setError(false,'');
            this.getField("nombre_sucursal").setError(false,'');
            this.getField("codigo_documento").setError(false,'');
            this.getField("nombre_documento").setError(false,'');
            this.getField("numero").setError(false,'');
            this.getField("modificable").setError(false,'');
        }
        else 
        {
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

    traerNumeroMod(){
        if(this.getField("codigo_sucursal").valid() && this.getField("nombre_sucursal").valid() && this.getField("codigo_documento").valid() 
         && this.getField("nombre_documento").valid()){
            let datos = {datos: {
                codigo_sucursal: this.getField("codigo_sucursal").getValue(),
                codigo_documento: this.getField("codigo_documento").getValue()
            }};    

            this.generalFormatPmv = { tipo_servicio: 'cont-especialesinicializardoc', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successtraerNumeroMod,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });   
        }
    }

    successtraerNumeroMod(data){
        if (data.estado_p === 200 )
        {
            this.getField("numero").setValue(data.data[0].numero);
            this.getField("modificable").setValue(data.data[0].modificable);
        }
        else 
        {
            if(data.estado_p===404) {
            this.alertGeneral.toggle(true, 'No se encontro.', 'error');
            }
            else {
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' + respuesta, 'error');
            }
        }
    }

}
FormJaivana.addController("cont-especialesinicializardoc", CustomInicializarDocumentos);
export default CustomInicializarDocumentos;