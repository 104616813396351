/**
 * @description Custom con funciones adicionales al formulario con código: maes-activosfijosgarantias, ID Formulario: 106, Sección: padre: Maestros(id: 1)- hijo: Activos Fijos(id: 24)
 * @author Sebastian Rios Echeverri
 * @version jdesk_1.01.0001
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomFacturaciones extends FormJaivana.form {


    constructor(props) {

        super(props);
        // Variables de tablas:
        this.gridOptionsActivosFijosGarantias      =   Object.assign({},this.gridOptions);
       
        // Variables Globales:
        this.arrayObjetos                          =   new Map();
        this.arrayCampos                           =   new Map();
        this.bHayErrores                           =   false;
        this.bHayError                             =   false;
        this.datosAgregar                          =   { datos:{}};
        // this.archivo                               =   true; // Esta variable global booleana, va a servir para que cuando se guarde un registro nuevo o se haga click en el botón cancelar, el usuario tenga que volver a cargar una nueva imagen, ya que con setearla vacío, no se limpia.
        this.archivoImagen1                        =   "";
        this.archivoImagen2                        =   "";
        this.archivoImagen3                        =   "";
        this.archivoImagen4                        =   "";
        this.archivoImagen5                        =   "";
        this.allowedExtensions                     =   /(.jpg|.jpeg|.png|.JPG|.JPEG|.PNG)$/i;
        this.estadoUploadFila                      =   false; // Esta variable booleana va a servir para validar si el archivo cargado en los campos UF tienen el formato correcto para dejar grabar o no.
       
        // Funciones:
        this.initForm                              =   this.initForm.bind(this);
        this.validarBtnFrm                         =   this.validarBtnFrm.bind(this);
        this.dibujarTbActivosFijosGarantias        =   this.dibujarTbActivosFijosGarantias.bind(this);
        this.successTbActivosFijosGarantias        =   this.successTbActivosFijosGarantias.bind(this);
        this.setButtonTbActivosFijosGarantias      =   this.setButtonTbActivosFijosGarantias.bind(this);
        this.crearBotonTabla                       =   this.crearBotonTabla.bind(this);
        this.accionProcesar                        =   this.accionProcesar.bind(this);
        this.guardarActivosFijosGarantias          =   this.guardarActivosFijosGarantias.bind(this);
        this.successGuardarActivosFijosGarantias   =   this.successGuardarActivosFijosGarantias.bind(this);
        this.validarEliminar                       =   this.validarEliminar.bind(this);
        this.successEliminar                       =   this.successEliminar.bind(this);
        this.btnAgregar                            =   this.btnAgregar.bind(this);
        this.uploadFile                            =   this.uploadFile.bind(this);
    }

    initForm() {

        console.log('Formulario CustomFacturaciones,  @version: jdesk_1.01.0001, @author: Sebastian Rios Echeverri');

        this.arrayCampos.set(1, '.');
        this.arrayCampos.set(2, 'tb_activosfijosgarantias');
        this.arrayCampos.set(3, 'btn_agregar');
        this.arrayCampos.set(4, 'fecha_inicio');
        this.arrayCampos.set(5, 'fecha_final');
        this.arrayCampos.set(6, 'detalle_reclamacion');
        this.arrayCampos.set(7, 'imagen1_url');
        /* this.arrayCampos.set(8, 'imagen2_url');
        this.arrayCampos.set(9, 'imagen3_url');
        this.arrayCampos.set(10, 'imagen4_url');
        this.arrayCampos.set(11, 'imagen5_url'); */
        this.arrayCampos.set(17, 'btn_aceptar');
        this.arrayCampos.set(18, '.');
        this.arrayCampos.set(19, 'btn_cancelar');
        this.arrayCampos.set(20, 'codigo_activo_fijo');
        this.arrayCampos.set(21, 'descripcion_activo_fijo');
        this.arrayCampos.set(22, 'activosfijos_id');
        this.arrayCampos.set(24, 'modal_vermas');
        this.arrayCampos.set(25, 'vista_imagen');


        this.arrayObjetos.set(1, '.');
        this.arrayObjetos.set(2, 'tb_activosfijosgarantias');
        this.arrayObjetos.set(3, 'btn_agregar');
        this.arrayObjetos.set(4, 'fecha_inicio');
        this.arrayObjetos.set(5, 'fecha_final');
        this.arrayObjetos.set(6, 'detalle_reclamacion');
        this.arrayObjetos.set(7, 'imagen1_url');
        /* this.arrayObjetos.set(8, 'imagen2_url');
        this.arrayObjetos.set(9, 'imagen3_url');
        this.arrayObjetos.set(10, 'imagen4_url');
        this.arrayObjetos.set(11, 'imagen5_url'); */
        this.arrayObjetos.set(17, 'btn_aceptar');
        this.arrayObjetos.set(18, '.');
        this.arrayObjetos.set(19, 'btn_cancelar');
        this.arrayObjetos.set(20, 'codigo_activo_fijo');
        this.arrayObjetos.set(21, 'descripcion_activo_fijo');
        this.arrayObjetos.set(22, 'activosfijos_id');
        this.arrayObjetos.set(24, 'modal_vermas');
        this.arrayObjetos.set(25, 'vista_imagen');



        this.dibujarTbActivosFijosGarantias();
        this.getField('btn_agregar').setClick(this.btnAgregar);
        this.getField('btn_cancelar').setClick(() => { this.validarBtnFrm('cancelar'); });
        this.getField('btn_aceptar').setClick(() => { this.validarBtnFrm('aceptar'); });
        this.getField('fecha_inicio').setOnChange(() => { this.validarBtnFrm('finicio'); });
        this.getField('fecha_final').setOnChange(() => { this.validarBtnFrm('validarfechas'); });
        this.getField('imagen1_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen1 = props.target.files[0] : this.archivoImagen1 = '';});
        /* this.getField('imagen2_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen2 = props.target.files[0] : this.archivoImagen2 = '';});
        this.getField('imagen3_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen3 = props.target.files[0] : this.archivoImagen3 = '';});
        this.getField('imagen4_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen4 = props.target.files[0] : this.archivoImagen4 = '';});
        this.getField('imagen5_url').setOnChange((props) => {this.uploadFile(props); props.target !== '' && props.target !== undefined ? this.archivoImagen5 = props.target.files[0] : this.archivoImagen5 = '';}); */
    }


    //*** definir los botones y los success
    uploadFile(e) {
        // console.log('Variable e de la función uploadFile:\n',e);
        // console.log('Variable e.target.id de la función uploadFile:\n',e.target.id.slice(0, -5));
        // let campo = e.target.id.slice(0, -5);
        if (e.target.files.length >= 1) {
            if (!this.allowedExtensions.exec(e.target.files[0].name)) {
                this.alertGeneral.toggle(true, 'Solo se permiten archivos con extensión .jpeg/.jpg/.png', 'error');
                // this.getField(`${campo}`).setValue('');
                this.estadoUploadFila = false;
            }else if (e.target.files[0].name.indexOf(' ') > 0) {
                this.alertGeneral.toggle(true, "La imagen (" + e.target.files[0].name + ") no puede contener espacios.", 'error');
                // this.getField(`${campo}`).setValue('');
                this.estadoUploadFila = false;
            }else{
                this.estadoUploadFila = true;
            }
        }
    }

    btnAgregar() {
        this.establecerPropiedades('3', 'visible', 'false');
        this.getField('btn_aceptar').setDisabled(false);
        this.establecerPropiedades('4,5,6,7,17,19,20,21', 'visible', 'true');
        this.establecerPropiedades('4,5,6,7,17,19,20,21', 'value,error', 'false');
    }

    guardarActivosFijosGarantias(data) {
        // console.log('Data guardarActivosFijosGarantias:\n', data);
        // console.log('this.getField("imagen1_url").getValue():\n', this.getField('imagen1_url').getValue().path);
        if (data.estado_p === 200 && data.data.length >= 1) {
            this.alertGeneral.toggle(true, '* La relación de los campos código activo fijo, fecha desde y fecha hasta ya existe, No se permite duplicar', 'error');
            this.getField('btn_aceptar').setDisabled(false);
        } else if (data.estado_p === 404) {
            this.getField('confirmModalCustom').setTitleAndContent('Cargando', `¡Se está enviando la información!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
            const data = {
                _generales: { "tipo_servicio": 'maes-activosfijosgarantias', "operacion": '5', "operacion_tipo": 'crear' },
                archivo: this.archivoImagen1,
                fecha_inicio: this.getField('fecha_inicio').getValue(),
                fecha_final: this.getField('fecha_final').getValue(),
                detalle_reclamacion: this.getField('detalle_reclamacion').getValue(),
                activosfijos_id: this.getField('activosfijos_id').getValue()
            }
            this.generalFormatPmv = data._generales;
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'POST',
                    body: data,
                    success: this.successGuardarActivosFijosGarantias,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    formData: true
                }
            );
            // console.log('imagen1_url...:\n', this.getField('imagen1_url').getValue());
        }
    }

    successGuardarActivosFijosGarantias(data) {
        this.getField('btn_aceptar').setDisabled(false);
        if (data.estado_p === 200) {
            this.gridOptionsActivosFijosGarantias['rowData'] = [];
            this.getField('tb_activosfijosgarantias').initData(this.gridOptionsActivosFijosGarantias);
            this.getField('tb_activosfijosgarantias').toggle(false);
            this.establecerPropiedades('3,4,5,6,7,17,19,20,21', 'visible', 'false');
            this.establecerPropiedades('4,5,6,7,20,21,22', 'value,error', 'false');
            this.dibujarTbActivosFijosGarantias();
        }
        this.getField('confirmModalCustom').toggle(false);
    }

    dibujarTbActivosFijosGarantias() {
        this.datosAgregar = { datos: {} }; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosgarantias', operacion: '1', operacion_tipo: 'consulta' };
        this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method: 'GET',
                body: this.datosAgregar,
                success: this.successTbActivosFijosGarantias,
                error: this.error_,
                general: this.generalFormatPmv
            }
        );
    }

    successTbActivosFijosGarantias(data) {
        if (data.estado_p === 200 && data.data.length >= 1) {
            // console.log('Data successTbActivosFijosGarantias:\n', data);
            this.getField('tb_activosfijosgarantias').toggle(true);

            let configCell = new Map();
            this.gridOptionsActivosFijosGarantias['rowData'] = data.data;
            configCell.set('accion', { cellRenderer: this.setButtonTbActivosFijosGarantias, headerName: 'Acción', sortable: false, filter: false });
            configCell.set('fecha_inicio', { headerName: 'Desde' });
            configCell.set('fecha_final', { headerName: 'Hasta' });
            configCell.set('detalle_reclamacion', { headerName: 'Detalle' });
            this.getField('tb_activosfijosgarantias').initData(this.gridOptionsActivosFijosGarantias, configCell);
            this.establecerPropiedades('4,5,6,7,17,19,20,21', 'visible', 'false');
            this.establecerPropiedades('4,5,6,7,20,21,22', 'value,error', 'false');
            this.establecerPropiedades('3', 'visible', 'true');
        } else if (data.estado_p === 404) {
            this.establecerPropiedades('3', 'disable', 'false');
            this.establecerPropiedades('3', 'visible', 'true');
            this.gridOptionsActivosFijosGarantias['rowData'] = [];
            this.getField('tb_activosfijosgarantias').initData(this.gridOptionsActivosFijosGarantias);
            this.getField('tb_activosfijosgarantias').toggle(false);
        } else {
            this.gridOptionsActivosFijosGarantias['rowData'] = [];
            this.getField('tb_activosfijosgarantias').initData(this.gridOptionsActivosFijosGarantias);
            this.getField('tb_activosfijosgarantias').toggle(false);
        }
    }

    setButtonTbActivosFijosGarantias(props) {
        let fragment = document.createDocumentFragment();
        let buttonEliminar = this.crearBotonTabla(props.data, 'Eliminar');
        let buttonVerMas = this.crearBotonTabla(props.data, 'Ver Imagen');
        buttonEliminar.setAttribute("class","buttonStyle2");
        buttonVerMas.setAttribute("class","buttonStyle2");
        fragment.appendChild(buttonEliminar);
        fragment.appendChild(buttonVerMas);
        return this.createElementJaivana(fragment);
    }

    /**
      * Establece la configuración del botón.
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón seleccionado: Puede ser "Seleccionar"} boton 
      * @returns el botón con su respectiva configuración
      */
    crearBotonTabla(data, boton) {
        let button = document.createElement('input');
        switch (boton) {
            case 'Eliminar':
                button.setAttribute("id", `buttonEliminar_${data.id}`);
                break;

            case 'Ver Imagen':
                button.setAttribute("id", `buttonVerMas_${data.id}`);
                break;

            default:
                break;
        }
        button.onclick = () => { this.accionProcesar(data, boton) };
        button.setAttribute("value", boton);
        button.setAttribute("type", "button");
        return button;
    }

    /**
      * @param {La información que contiene el registro seleccionado} data 
      * @param {El botón al cual se le dió click ("Eliminar", "Ver Imagen")} boton 
      */
    accionProcesar(data, boton) {
        // console.log('data accionProcesar:\n', data);
        if (boton === 'Eliminar') {
            this.getField('confirmModalCustom').setTitleAndContent('Eliminar', '¿Desea realizar esta operación?');
            this.getField('confirmModalCustom').setClickDialog(() => { this.validarEliminar(data.id) });
            this.getField("confirmModalCustom").setVisibleCancel(false);
            this.getField("confirmModalCustom").setButtonConfirm(`Confirmar`);
            this.getField('confirmModalCustom').toggle(true);
        } else if (boton === 'Ver Imagen') {
            /* this.getField('confirmModalCustom').setTitleAndContent('Imagénes', `imagen1_url: ${data.imagen1_url}; imagen2_url: ${data.imagen2_url}; imagen3_url: ${data.imagen3_url}; imagen4_url: ${data.imagen4_url}; imagen5_url: ${data.imagen5_url}`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true); */

            /* this.getField('vista_imagen').setDisabled(true);
            this.getField('vista_imagen').setValue('https://9rswfqxn.s3.amazonaws.com/banner_prom_BannerPromociones.jpg');
            // this.getField('vista_imagen').setValue(data.imagen1_url); 
            this.getField('modal_vermas').handleClickOpen(); */

            this.getField('confirmModalCustom').setTitleAndContent('Un momento', `¡Se está cargando la vista previa!`)
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm('.');// Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);

            // console.log('data.id:\n',data.id);

            let url = this.constant.formConfiguration();
            let datos=`?body={"datos":{"id":${data.id}},"generales":{"tipo_servicio":"maes-activosfijosgarantias","operacion":"traerimagenurl1","operacion_tipo":"consulta"}}`;
            fetch(url+datos,{
                method:'get',
                headers: new Headers({
                    'Authorization': 'Bearer '+localStorage.getItem("token"),
                    'Content-Type': 'application/json'
                })
            })
            .then(response => {
                if(response.status!==200){
                    return '';
                }
                const reader = response.body.getReader();
                return new ReadableStream({
                    start(controller) {
                    return pump();
                    function pump() {
                        return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }
                        controller.enqueue(value);
                        return pump();
                        });
                    }
                    }
                })
            }).then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    // console.log('blob:\n',blob);
                    const image = new File([blob], "name.jpeg", {
                        type: "image/jpeg",
                    });
                    window.open(URL.createObjectURL(image))
                }else{
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
                this.getField('confirmModalCustom').toggle(false);
            })
            .catch(err => console.error(err));
            this.operacion="";
        } else {
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `Ninguna acción pertenece a la acción de este botón`);
            this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
            this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
            this.getField("confirmModalCustom").setButtonConfirm(`Ok`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
            this.getField('confirmModalCustom').toggle(true);
        }
    }

    validarEliminar(id) {
        this.getField('confirmModalCustom').toggle(false);
        if (id !== '') {
            let datos = {
                datos: {
                    activosfijos_garantias_id: id
                }
            };
            this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosgarantias', operacion: '7', operacion_tipo: 'eliminar' };
            this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method: 'DELETE',
                    body: datos,
                    success: this.successEliminar,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }
    }

    successEliminar(data) {
        if (data.estado_p === 200) {
            // console.log('data successEliminar:\n', data);
            this.dibujarTbActivosFijosGarantias();
        }
    }

    validarBtnFrm(boton) {
        switch (boton) {
            case "cancelar":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.getField('imagen1_url').setValue('');
                    this.establecerPropiedades('3,4,5,6,7,17,19,20,21', 'visible', 'false');
                    this.establecerPropiedades('4,5,6,7,20,21,22', 'value,error', 'false');
                    this.gridOptionsActivosFijosGarantias['rowData'] = [];
                    this.getField('tb_activosfijosgarantias').initData(this.gridOptionsActivosFijosGarantias);
                    this.getField('tb_activosfijosgarantias').toggle(false);
                    this.dibujarTbActivosFijosGarantias();
                    this.getField('confirmModalCustom').toggle(false);
                });
                this.getField("confirmModalCustom").setVisibleCancel(false); // Para mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "aceptar":
                if (this.validarBtnFrm('finicio') && this.validarBtnFrm('validarfechas') && this.getField('fecha_final').valid() &&
                    this.getField('detalle_reclamacion').valid() && this.getField('codigo_activo_fijo').valid() &&
                    this.getField('descripcion_activo_fijo').valid() && this.getField('activosfijos_id').getValue() !== '') {
                    if (this.archivoImagen1 !== '' && this.estadoUploadFila && this.getField('imagen1_url').valid()) {
                        // this.archivo = true;
                        // console.log('imagen1_url:\n', this.getField('imagen1_url').getValue());
                        this.getField('btn_aceptar').setDisabled(true);
                        this.getField('imagen1_url').setError(false, '');
                        let datosAgregar = { datos: {} }; // Se coloca esta línea a pesar de que en el constructor se inicializa de la misma forma; ya que se debe limbiar dicha variable global, para construir nuevamente el body del servicio y esto, porqué, es una vabiable que se puede usar desde cualquier función que hace una petición a x servicio.
                        datosAgregar.datos['activosfijos_id'] = this.getField('activosfijos_id').getValue();
                        datosAgregar.datos['fecha_inicio'] = this.getField('fecha_inicio').getValue();
                        datosAgregar.datos['fecha_final'] = this.getField('fecha_final').getValue();
                        this.generalFormatPmv = { tipo_servicio: 'maes-activosfijosgarantias', operacion: '20', operacion_tipo: 'consulta' };
                        this.service.send(
                            {
                                endpoint: this.constant.formConfiguration(),
                                method: 'GET',
                                body: datosAgregar,
                                success: this.guardarActivosFijosGarantias,
                                error: this.error_,
                                general: this.generalFormatPmv,
                                showMessage: false
                            }
                        );
                    } else if (!(this.getField('imagen1_url').valid())){
                        // this.alertGeneral.toggle(true, 'Cargue por lo menos la primer imagen.', 'error');
                        this.alertGeneral.toggle(true, 'Cargue la imagen.', 'error');
                        this.getField('imagen1_url').setError(true, '* Este campo es requerido.');
                        // this.archivo = false;
                    } else if (this.estadoUploadFila === false){
                        this.getField('imagen1_url').setError(true, '* Valide el archivo adjunto.');
                    }
                } else {
                    this.alertGeneral.toggle(true, 'Valide que los campos estén correctamente diligenciados.', 'error');
                }
                break;

            case "validarfechas":
                if (this.getField('fecha_inicio').getValue() !== '' && this.getField('fecha_final').getValue() !== '') {
                    let fechaInicial = new Date(this.getField('fecha_inicio').getValue()).toISOString().split('T')[0];
                    let fechaFinal = new Date(this.getField('fecha_final').getValue()).toISOString().split('T')[0];
                    if (fechaInicial >= fechaFinal) {
                        this.getField('fecha_final').setError(true, "¡Fecha hasta, debe ser mayor a fecha desde!");
                        return false;
                    } else if (fechaFinal > fechaInicial) {
                        this.getField('fecha_final').setError(false, "");
                        return true;
                    }
                }
                break;

            case "finicio":
                if (this.getField('fecha_inicio').getValue() !== '') {
                    this.getField('fecha_inicio').setError(false, "");
                    let fechaActual = new Date();
                    let fechaCampo = new Date(this.getField('fecha_inicio').getValue());
                    let fechaActualFormateada = fechaActual.toISOString().split('T')[0];
                    let fechaCampoFormateada = fechaCampo.toISOString().split('T')[0];

                    // Hacemos la validación dónde la fecha ingresada por el usuario sólo puede ser un día inferior al día de la fecha actual o el mismo día del mismo:
                    // if ((fechaCampoFormateada <= fechaActualFormateada) && ((fechaActualFormateada.split('-')[0] - fechaCampoFormateada.split('-')[0]) <= 5 || (fechaCampoFormateada === fechaActualFormateada))) {
                    if ((fechaCampoFormateada <= fechaActualFormateada) && ((fechaActualFormateada.replaceAll('-','') - fechaCampoFormateada.replaceAll('-','')) <= 50000 || (fechaCampoFormateada === fechaActualFormateada))) {
                        this.validarBtnFrm('validarfechas');
                        return true;
                    } else {
                        this.getField('fecha_inicio').setError(true, "¡La fecha debe ser igual o inferior a 5 años de la fecha actual!");
                        return false;
                    }
                }
                break;

            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
                this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }

    //*** fin  los botones y los success

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false, '');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
            else if (sPropiedad === 'foco') {
                if (sValor === 'rejilla') { }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).input.focus();
                }
            }
        });
    }
}
FormJaivana.addController('maes-activosfijosgarantias', CustomFacturaciones);
export default CustomFacturaciones;