/**
 * @description: CustomModificarTerceros
 * @author: Patricia Lopez Sanchez
 * @updateBy Jose Albeiro Marin
 * @version: jdesk_1.01.0001
 **/
import FormJaivana from 'dashboard_jaivana_v1';
class CustomModificarTerceros extends FormJaivana.form {


    constructor(props) {

        super(props);
        // Variables de tablas:
        this.gridOptionsTerceros                 =   Object.assign({},this.gridOptions);
       
        // Variables Globales:
        this.arrayObjetos                        =   new Map();
        this.arrayCampos                         =   new Map();
        this.bHayErrores                         =   false;
        this.bHayError                           =   false;
        this.datosAgregar                        =   { datos:{}};
       
        // Funciones:
        this.initForm                            =   this.initForm.bind(this);
        this.validarDatosFrm                     =   this.validarDatosFrm.bind(this);
        this.concatenarNombre                    =   this.concatenarNombre.bind(this);
        this.validarCamposApellidos              =   this.validarCamposApellidos.bind(this);
        this.tipoDocumento                       =   this.tipoDocumento.bind(this);
        this.successModificarTercero             =   this.successModificarTercero.bind(this);
        this.service_departamento                =   this.service_departamento.bind(this);
        this.successTraerdepartamento            =   this.successTraerdepartamento.bind(this);
        this.service_cuidades                    =   this.service_cuidades.bind(this);
        this.successTraerCiudades                =   this.successTraerCiudades.bind(this);
        this.service_barrios                     =   this.service_barrios.bind(this);
        this.successTraerBarrios                 =   this.successTraerBarrios.bind(this);
        this.seleccionar                         =   this.seleccionar.bind(this);
        this.limpiarError                        =   this.limpiarError.bind(this);
        this.limpiar                             =   this.limpiar.bind(this);
        this.cargar_depto                        =   this.cargar_depto.bind(this);
        this.cargar_ciudad                       =   this.cargar_ciudad.bind(this);
        this.cargar_barrio                       =   this.cargar_barrio.bind(this);
        this.direccion                           =   this.direccion.bind(this);
        this.limpiarDir                          =   true;
        this.cargarId                            =   this.cargarId.bind(this);
        this.sucesscargarId                      =   this.sucesscargarId.bind(this);
        this.cargarIdCiudad                      =   this.cargarIdCiudad.bind(this);
        this.sucesscargarIdCiudad                =   this.sucesscargarIdCiudad.bind(this);
        this.despues=true;
        this.despues1=true;
        this.validarVendedor                    =   this.validarVendedor.bind(this);
        this.successVendedor                    =   this.successVendedor.bind(this);
        this.vendedor_id=true;
        this.validarNegocio                    =   this.validarNegocio.bind(this);
        this.successNegocio                    =   this.successNegocio.bind(this);
        this.negocio_id=true;

    }

    initForm() {

        console.log('Formulario CustomModificarTerceros,  @version: jdesk_1.01.0001, @author: Patricia López Sánchez, @updateBy Jose Albeiro Marin');

        this.arrayCampos.set(1, 'nitsx_sedes_id');
        this.arrayCampos.set(2, 'contenedorDatosBasicos');
        this.arrayCampos.set(77, 'tipo_documento_id');
        this.arrayCampos.set(20, 'tipo_identificacion');
        this.arrayCampos.set(79, 'nit_basicos');
        this.arrayCampos.set(81, 'verifica_basicos');
        this.arrayCampos.set(83, 'sede_basicos');
        this.arrayCampos.set(4, 'ape1');
        this.arrayCampos.set(6, 'ape2');
        this.arrayCampos.set(8, 'nom1');
        this.arrayCampos.set(10, 'nom2');
        this.arrayCampos.set(12, 'nombre');
        this.arrayCampos.set(84, 'otro_nombre');
        this.arrayCampos.set(15, 'cancelar_basicos');
        this.arrayCampos.set(16, 'nombre_tipo_documento');
        this.arrayCampos.set(20, 'codigo_pais');
        this.arrayCampos.set(22, 'paises_id');
        this.arrayCampos.set(24, 'codigo_departamento_geografico'); 
        this.arrayCampos.set(25, 'codigo_depto');
        this.arrayCampos.set(26, 'departamento_geografico_id');
        this.arrayCampos.set(28, 'codigo_ciudad');
        this.arrayCampos.set(30, 'ciudad_id');
        this.arrayCampos.set(32, 'codigo_barrio');
        this.arrayCampos.set(34, 'barrio_id');
        this.arrayCampos.set(36, 'direccion');
        this.arrayCampos.set(42, 'telefonos');
        this.arrayCampos.set(44, 'faxes');
        this.arrayCampos.set(46, 'email_factura_electronica');
        this.arrayCampos.set(48, 'email_secundario_facturacion_electronica');
        this.arrayCampos.set(49, 'cancelar_contacto');
        this.arrayCampos.set(51, 'continuar_contacto');
        this.arrayCampos.set(54, 'codigo_vendedor');
        this.arrayCampos.set(56, 'vendedor_id');
        this.arrayCampos.set(58, 'codigo_negocio');
        this.arrayCampos.set(60, 'negocio_id');
        this.arrayCampos.set(62, 'canal_cliente');
        this.arrayCampos.set(63, 'descripcion_cliente');
        this.arrayCampos.set(66, 'codigo_oficio');
        this.arrayCampos.set(67, 'nombre_oficio');
        this.arrayCampos.set(70, 'observacion');
        this.arrayCampos.set(72, 'observaciones');
        this.arrayCampos.set(75, 'continuar_cliente');
        this.arrayCampos.set(85, 'contenedorIdentificacion');
        this.arrayCampos.set(89, 'nit');
        this.arrayCampos.set(91, 'verifica');
        this.arrayCampos.set(93, 'sede');
        this.arrayCampos.set(94, 'cancelar_identificacion');
        this.arrayCampos.set(96, 'finalizar_identificacion');
        this.arrayCampos.set(97, 'contenedorDatos1');
        this.arrayCampos.set(98, 'nit_buscar');
        this.arrayCampos.set(100, 'nombre3');
        this.arrayCampos.set(101, 'seleccionar');
        this.arrayCampos.set(103, 'cancelar8');

        this.arrayObjetos.set(1, 'nitsx_sedes_id');
        this.arrayObjetos.set(2, 'contenedorDatosBasicos');
        this.arrayObjetos.set(77, 'tipo_documento_id');
        this.arrayObjetos.set(20, 'tipo_identificacion');
        this.arrayObjetos.set(79, 'nit_basicos');
        this.arrayObjetos.set(81, 'verifica_basicos');
        this.arrayObjetos.set(83, 'sede_basicos');
        this.arrayObjetos.set(4, 'ape1');
        this.arrayObjetos.set(6, 'ape2');
        this.arrayObjetos.set(8, 'nom1');
        this.arrayObjetos.set(10, 'nom2');
        this.arrayObjetos.set(12, 'nombre');
        this.arrayObjetos.set(84, 'otro_nombre');
        this.arrayObjetos.set(15, 'cancelar_basicos');
        this.arrayObjetos.set(16, 'nombre_tipo_documento');
        this.arrayObjetos.set(17, 'continuar_basicos');
        this.arrayObjetos.set(18, 'contenedorDatosGeograficos');
        this.arrayObjetos.set(20, 'codigo_pais');
        this.arrayObjetos.set(22, 'paises_id');
        this.arrayObjetos.set(24, 'codigo_departamento_geografico');
        this.arrayObjetos.set(25, 'codigo_depto');
        this.arrayObjetos.set(26, 'departamento_geografico_id');
        this.arrayObjetos.set(28, 'codigo_ciudad');
        this.arrayObjetos.set(30, 'ciudad_id');
        this.arrayObjetos.set(32, 'codigo_barrio');
        this.arrayObjetos.set(34, 'barrio_id');
        this.arrayObjetos.set(36, 'direccion');
        this.arrayObjetos.set(37, 'cancelar_geograficos');
        this.arrayObjetos.set(39, 'continuar_geograficos');
        this.arrayObjetos.set(40, 'contenedorDatosContacto');
        this.arrayObjetos.set(42, 'telefonos');
        this.arrayObjetos.set(44, 'faxes');
        this.arrayObjetos.set(46, 'email_factura_electronica');
        this.arrayObjetos.set(48, 'email_secundario_facturacion_electronica');
        this.arrayObjetos.set(49, 'cancelar_contacto');
        this.arrayObjetos.set(51, 'continuar_contacto');
        this.arrayObjetos.set(52, 'contenedorDatosBasicosCliente');
        this.arrayObjetos.set(54, 'codigo_vendedor');
        this.arrayObjetos.set(55, 'nombre_vendedor');
        this.arrayObjetos.set(56, 'vendedor_id');
        this.arrayObjetos.set(58, 'codigo_negocio');
        this.arrayObjetos.set(59, 'nombre_negocio_clientes');
        this.arrayObjetos.set(60, 'negocio_id');
        this.arrayObjetos.set(62, 'canal_cliente');
        this.arrayObjetos.set(63, 'descripcion_cliente');
        this.arrayObjetos.set(66, 'codigo_oficio');
        this.arrayObjetos.set(67, 'nombre_oficio');
        this.arrayObjetos.set(70, 'observacion');
        this.arrayObjetos.set(72, 'observaciones');
        this.arrayObjetos.set(73, 'cancelar_cliente');
        this.arrayObjetos.set(75, 'continuar_cliente');
        this.arrayObjetos.set(85, 'contenedorIdentificacion');
        this.arrayObjetos.set(89, 'nit');
        this.arrayObjetos.set(91, 'verifica');
        this.arrayObjetos.set(93, 'sede');
        this.arrayObjetos.set(94, 'cancelar_identificacion');
        this.arrayObjetos.set(96, 'finalizar_identificacion');
        this.arrayObjetos.set(97, 'contenedorDatos1');
        this.arrayObjetos.set(98, 'nit_buscar');
        this.arrayObjetos.set(100, 'nombre3');
        this.arrayObjetos.set(101, 'seleccionar');
        this.arrayObjetos.set(103, 'cancelar8');

        this.establecerPropiedades('1,2,4,6,8,10,77,79,81,83,4,6,8,10,12,17,84','disable','true')
        this.establecerPropiedades('97,98,100,101,103','visible','true')

        this.getField('seleccionar').setClick(this.seleccionar);   

        this.getField('continuar_basicos').setClick(() => { this.validarDatosFrm("continuar_basicos"); });
        this.getField('continuar_geograficos').setClick(() => { this.validarDatosFrm("continuar_geograficos"); });
        this.getField('continuar_contacto').setClick(() => { this.validarDatosFrm("continuar_contacto"); });
        this.getField('continuar_cliente').setClick(() => { this.validarDatosFrm("continuar_cliente"); });
        this.getField('cancelar_basicos').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('cancelar_geograficos').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('cancelar_contacto').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('cancelar_cliente').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('cancelar_identificacion').setClick(() => { this.validarDatosFrm("cancelar"); });
        this.getField('finalizar_identificacion').setClick(() => { this.validarDatosFrm("finalizar_identificacion"); });
        this.getField('nom1').setOnChange(this.concatenarNombre);
        this.getField('nom2').setOnChange(this.concatenarNombre);
        this.getField('ape1').setOnChange(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
        this.getField('ape2').setOnChange(() => { this.concatenarNombre(); this.validarCamposApellidos(); });
        this.getField('nombre').setOnChange(() => { this.getField('nombre').getValue().length >= 1 ? this.getField('nombre').setError(false, "") : this.getField('nombre').valid(); });
        this.getField('nombre').setKeyUp(() => { this.getField('otro_nombre').setValue(this.getField('nombre').getValue());});
        this.getField('tipo_documento_id').setOnChange(() => { this.getField('tipo_documento_id').getValue() !== '' ? this.getField('tipo_documento_id').setError(false, '') : this.getField('tipo_documento_id').valid();});
        this.getField('nombre_tipo_documento').setOnChange(this.tipoDocumento);
        this.getField('nit_buscar').setOnChange(this.limpiarError);   
        this.getField('cancelar8').setClick(this.limpiar);
        this.getField('nombre3').setOnChange(() => { this.despues=true; this.despues1=true; this.vendedor_id = true; this.negocio_id=true; }); 
        this.getField('paises_id').setOnChange(this.cargar_depto);  
        this.getField('codigo_departamento_geografico').setOnChange(this.cargar_ciudad);   
        this.getField('codigo_ciudad').setOnChange(this.cargar_barrio);   
        this.getField('codigo_barrio').setOnChange(this.direccion)
        this.getField('codigo_depto').setOnChange(this.cargarId) 
        this.getField('codigo_ciud').setOnChange(this.cargarIdCiudad);
        this.getField('departamento_geografico_id').setOnChange(this.service_cuidades); 
        this.getField('ciudad_id').setOnChange(this.service_barrios); 

        this.getField('nombre_vendedor').setOnChange(this.validarVendedor); 
        this.getField('nombre_negocio_clientes').setOnChange(this.validarNegocio); 

        
        this.establecerPropiedades('4,6,8,10','value','.');
        this.establecerPropiedades('24,28,32,36','disable','false');
    }

    cargarId(){
        if(  this.getField('codigo_depto').getValue() !==''){
            let datos={ datos: {value: this.getField('codigo_depto').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-dptosgeografico', operacion: '42', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucesscargarId,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }
    sucesscargarId(data){

        if (data.estado_p === 200)
        {
            this.despues=false;
            this.getField('departamento_geografico_id').setValue(data.data[0].departamentos_geograficos_id)

        }
        else 
        {
           if(data.estado_p===404) {

        }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    cargarIdCiudad(){
        if(  this.getField('codigo_ciud').getValue() !==''){
            let datos={ datos: {value: this.getField('codigo_ciud').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: '42', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.sucesscargarIdCiudad,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
        }
    }
    sucesscargarIdCiudad(data){

        if (data.estado_p === 200)
        {
            // console.log("data",data)
            this.despues1=false;
            this.getField('ciudad_id').setValue(data.data[0].ciudades_id)

        }
        else 
        {
           if(data.estado_p===404) {
            
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    validarVendedor(){
        // console.log("vendedor_id",this.vendedor_id)
        if(this.vendedor_id === true){
            if(this.getField('nombre_vendedor').getValue() !==''){
                let datos={ datos: {value: this.getField('codigo_vendedor').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-vendedores', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successVendedor,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }
    }

    successVendedor(data){
        if (data.estado_p === 200 )
        {
            // console.log("dataVendedor",data)
            this.getField('vendedor_id').setValue(data.data[0].vendedores_id);
            // console.log("barrio_id",data.data[0].barrio_id)
            // this.alertGeneral.toggle(true, 'Rspondio', 'success');
            this.vendedor_id = false
            // console.log('vendedor_id',this.vendedor_id )
        }else{
           if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');       
        }else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    validarNegocio(){
        // console.log("negocio_id",this.negocio_id)
        if(this.negocio_id === true){
            if(this.getField('nombre_negocio_clientes').getValue() !==''){
                let datos={ datos: {value: this.getField('codigo_negocio').getValue()}};
                this.generalFormatPmv = { tipo_servicio: 'maes-negocios', operacion: '42', operacion_tipo: 'consulta' };
                this.service.send(
                {
                        endpoint: this.constant.formConfiguration(),
                        method:'GET',
                        body: datos,
                        success: this.successNegocio,
                        error: this.error_,
                        general: this.generalFormatPmv,
                        showMessage: false
                });
            }
        }
    }

    successNegocio(data){
        if (data.estado_p === 200 )
        {
            // console.log("dataNegocio",data)
            this.getField('negocio_id').setValue(data.data[0].negocios_id);
            // console.log("barrio_id",data.data[0].barrio_id)
            // this.alertGeneral.toggle(true, 'Rspondio', 'success');
            this.negocio_id = false
            // console.log('negocio_id',this.negocio_id )
        }else{
           if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');       
        }else {
             let respuesta=Object.values(data.data.errores);
             let keys=Object.keys(data.data.errores);
             this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        };
    }

    limpiarError(){
        this.getField('nom1').setError(false, "");
        this.getField('nom2').setError(false, "");
        this.getField('ape1').setError(false, ""); 
        this.getField('ape2').setError(false, "");
        this.getField('tipo_documento_id').setError(false, "");
        this.getField('nombre').setError(false, "");
        this.getField('codigo_pais').setError(false, ""); 
        this.getField('codigo_departamento_geografico').setError(false, "");
        this.getField('codigo_ciudad').setError(false, "");
        this.getField('codigo_barrio').setError(false, "");
    }

    seleccionar(){
        if(this.getField('nombre3').getValue() !== ''){
            this.getField('contenedorDatos1').handleClose();
            this.establecerPropiedades('17,77','disable','false');
            this.getField('paises_id').setOnChange(this.cargar_depto);   
            this.getField('barrio_id').setOnChange(this.direccion)
            this.limpiarDir = true;
        }else{
            this.alertGeneral.toggle(true, 'Debe seleccionar un tercero.', 'error');
        }
    }

    cargar_depto(){
        if(this.getField('paises_id').getValue() !== ''){
            this.getField('codigo_departamento_geografico').setDisabled(false); 
            this.service_departamento() 
        } else{
            this.getField('codigo_departamento_geografico').setDisabled(true); 
            this.getField('codigo_ciudad').setDisabled(true); 
            this.getField('codigo_barrio').setDisabled(true); 
            this.getField('direccion').setDisabled(true); 
            this.getField('direccion').setValue('');
            this.getField('codigo_departamento_geografico').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            this.getField('codigo_ciudad').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            this.getField('codigo_barrio').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            this.getField('paises_id').setError(false,"");
            this.getField('codigo_departamento_geografico').setError(false,"");
            if (!this.limpiarDir) { /// se limpia la direccion
                this.getField('direccion').setValue(''); 
            }
        }
    }

    cargar_ciudad(){
        if(this.getField('departamento_geografico_id').getValue() !== '' && this.getField('codigo_departamento_geografico').getValue() !== ''){
            this.getField('codigo_ciudad').setDisabled(false);
            this.despues=false;
            this.despues1=false;
            if (!this.limpiarDir) { /// se limpia la direccion
                this.getField('direccion').setValue(''); 
            }
        }  else{
            this.getField('codigo_ciudad').setDisabled(true); 
            this.getField('codigo_barrio').setDisabled(true); 
            this.getField('direccion').setDisabled(true); 
            this.getField('direccion').setValue('');

            this.getField('codigo_ciudad').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            this.getField('codigo_barrio').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            this.getField('codigo_ciudad').setError(false,"");
            this.getField('ciudad_id').setValue('');
            this.getField('barrio_id').setValue('');
            if (!this.limpiarDir) { /// se limpia la direccion
                this.getField('direccion').setValue(''); 
            }
        } 
    }

    cargar_barrio(){
        if(this.getField('ciudad_id').getValue() !== '' && this.getField('codigo_ciudad').getValue() !== ''){
            this.getField('codigo_barrio').setDisabled(false);
            this.despues1=false;
            if (!this.limpiarDir) { /// se limpia la direccion
                this.getField('direccion').setValue(''); 
            }
            // this.getField('codigo_barrio').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
        } else{
            this.getField('codigo_barrio').setDisabled(true); 
            this.getField('direccion').setDisabled(true); 
            this.getField('direccion').setValue('');
            this.getField('codigo_barrio').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            this.getField('direccion').setDisabled(true); 
            this.getField('codigo_barrio').setError(false,"");
            this.getField('barrio_id').setValue('');

            if (!this.limpiarDir) { /// se limpia la direccion
                this.getField('direccion').setValue(''); 
            }
        }
    }

    direccion(){
        if(this.getField('barrio_id').getValue() !== '' && this.getField('codigo_barrio').getValue() !== ''){
            this.getField('direccion').setDisabled(false)
            if (!this.limpiarDir) { /// se limpia la direccion
                this.getField('direccion').setValue(''); 
            }
        } else{
            this.getField('direccion').setDisabled(true); 
            if (!this.limpiarDir) { /// se limpia la direccion
                this.getField('direccion').setValue(''); 
            }
        }
    }

    service_departamento()
    {
       let datos={ datos: {pais_id: this.getField('paises_id').getValue()}};
            this.generalFormatPmv = { tipo_servicio: 'maes-dptosgeografico', operacion: '1_1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerdepartamento,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    successTraerdepartamento(data)
    {
        this.getField('codigo_ciudad').setOptions([{ 'text': 'Ninguno', 'value': '', 'campos_cambian': {} }]);

        if (data.estado_p === 200)
        {
            if(data.model.length>0){
             let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.codigo_departamento_geografico;
             dataOp['text'] = item.nombre;
             dataOp['campos_cambian'] = { departamento_geografico_id: item.departamentos_geograficos_id};
             opciones.push(dataOp); })
             this.getField('codigo_departamento_geografico').setOptions(opciones);

             this.getField('codigo_depto').getValue() !== '' ? this.getField('codigo_departamento_geografico').setValue(this.getField('codigo_depto').getValue()) : this.getField('codigo_departamento_geografico').setValue(' ');
             }else{
                this.getField('codigo_departamento_geografico').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
             }
        }
        else 
        {
           if(data.estado_p===404) {
            this.getField('codigo_departamento_geografico').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            // this.getField('direccion').setDisabled(true); 
            // this.getField('direccion').setValue('');
            
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }
    
    service_cuidades()
    {
        if(this.despues===false){
            if(this.getField('departamento_geografico_id').getValue() !==''){
               let datos={ datos: {departamento_geografico_id: this.getField('departamento_geografico_id').getValue()}};
                 this.generalFormatPmv = { tipo_servicio: 'maes-ciudades1', operacion: '1_1', operacion_tipo: 'consulta' };
                 this.service.send(
                 {
                         endpoint: this.constant.formConfiguration(),
                         method:'GET',
                         body: datos,
                         success: this.successTraerCiudades,
                         error: this.error_,
                         general: this.generalFormatPmv,
                         showMessage: false
                 });
             }
        }
    }

    successTraerCiudades(data)
    {
        if (data.estado_p === 200)
        {
            if(data.model.length>0){
             let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.codigo_ciudad;
             dataOp['text'] = item.nombre;
             dataOp['campos_cambian'] = {ciudad_id : item.ciudades_id };
             opciones.push(dataOp); })
             this.getField('codigo_ciudad').setOptions(opciones);

             this.getField('codigo_ciud').getValue() !== '' ? this.getField('codigo_ciudad').setValue(this.getField('codigo_ciud').getValue()) : this.getField('codigo_ciudad').setValue(' ');
             
            }else{
                this.getField('codigo_ciudad').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
             }
        }
        else 
        {
           if(data.estado_p===404) {
                this.getField('codigo_ciudad').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
                // this.getField('direccion').setDisabled(true); 
                // this.getField('direccion').setValue('');

           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

        
    service_barrios()
    {
        if(this.despues1===false){
            if(this.getField('ciudad_id').getValue() !==''){
                        let datos={ datos: {ciudad_id: this.getField('ciudad_id').getValue()}};
                             this.generalFormatPmv = { tipo_servicio: 'maes-barrios', operacion: '1_1', operacion_tipo: 'consulta' };
                             this.service.send(
                             {
                                     endpoint: this.constant.formConfiguration(),
                                     method:'GET',
                                     body: datos,
                                     success: this.successTraerBarrios,
                                     error: this.error_,
                                     general: this.generalFormatPmv,
                                     showMessage: false
                                     
                             });
                    }

        }        
    }

    successTraerBarrios(data)
    {
        if (data.estado_p === 200)
        {
            this.getField('codigo_barrio').setDisabled(false);
            if(data.model && data.model.length>0){
             let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
             data.data.forEach(item => {
             let dataOp = {}
             dataOp['value'] = item.codigo_barrio;
             dataOp['text'] = item.nombre;
             dataOp['campos_cambian'] = { barrio_id : item.barrios_id };
             opciones.push(dataOp); })
             this.getField('codigo_barrio').setOptions(opciones);

             this.getField('codigo_barr').getValue() !== '' ? this.getField('codigo_barrio').setValue(this.getField('codigo_barr').getValue()) : this.getField('codigo_barrio').setValue(' ');
             }else{
                this.getField('codigo_barrio').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
             }
        }
        else 
        {
           if(data.estado_p===404) {
            this.getField('codigo_barrio').setOptions([{ text: 'Ninguno', value: '', 'campos_cambian': {} }]);
            // this.getField('codigo_barrio').setDisabled(false);
            //  this.getField('direccion').setDisabled(true); 
            // this.getField('direccion').setValue('');
           }
           else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
           }
       }
    }

    tipoDocumento() {
            if (this.getField('nombre_tipo_documento').getValue() === 'NIT') {
                // this.getField('otro_nombre').setDisabled(false);
                this.getField('nombre').setDisabled(false);
                this.getField('nom1').setDisabled(true);
                this.getField('nom1').setError(false, '');
                this.getField('nom2').setDisabled(true);
                this.getField('nom2').setError(false, '');
                this.getField('ape1').setDisabled(true);
                this.getField('ape1').setError(false, '');
                this.getField('ape2').setDisabled(true);
                this.getField('ape2').setError(false, '');
                this.getField('otro_nombre').setError(false, '');
    
                this.getField('nom1').setValue('.');
                this.getField('nom2').setValue('.');
                this.getField('ape1').setValue('.');
                this.getField('ape2').setValue('.');
                this.getField('tipo_identificacion').setValue('N');
                if (this.getField('nombre').getValue() !== '' && this.getField('nombre').getValue() !== ' ') {
                    this.getField('otro_nombre').setValue(this.getField('nombre').getValue());
                }else{
                    this.getField('otro_nombre').setValue('');
                }
                // this.getField('nombre').setValue(this.getField('otro_nombre').getValue());
                // this.getField('nombre').getValue();
                
            } else if (this.getField('nombre_tipo_documento').getValue() === 'CEDULA DE CIUDADANIA'){
                this.getField('otro_nombre').setDisabled(true);
                this.getField('nombre').setDisabled(true);
                this.getField('nom1').setDisabled(false);
                this.getField('nom1').setError(false, '');
                this.getField('nom2').setDisabled(false);
                this.getField('nom2').setError(false, '');
                this.getField('ape1').setDisabled(false);
                this.getField('ape1').setError(false, '');
                this.getField('ape2').setDisabled(false);
                this.getField('ape2').setError(false, '');
                this.getField('otro_nombre').setError(false, '');
                this.getField('otro_nombre').setValue('.');
                this.getField('tipo_identificacion').setValue('C');
                // this.getField('otro_nombre').setValue(this.getField('nombre').getValue());
            }else{
                this.getField('otro_nombre').setDisabled(true);
                this.getField('nom1').setDisabled(false);
                this.getField('nom1').setError(false, '');
                this.getField('nom2').setDisabled(false);
                this.getField('nom2').setError(false, '');
                this.getField('ape1').setDisabled(false);
                this.getField('ape1').setError(false, '');
                this.getField('ape2').setDisabled(false);
                this.getField('ape2').setError(false, '');
                this.getField('otro_nombre').setError(false, '');
                this.getField('otro_nombre').setValue('.');
                this.getField('nombre').setDisabled(true);

                this.getField('nom1').setValue('.');
                this.getField('nom2').setValue('.');
                this.getField('ape1').setValue('.');
                this.getField('ape2').setValue('.');
                this.getField('nombre').setValue(this.getField('otro_nombre').getValue());
            }
    }
    
    validarCamposApellidos() {
        if ((this.getField('ape1').getValue() !== '' && this.getField('ape1').getValue() !== '.' && this.getField('ape1').valid() && this.getField('ape2').valid()) ||
            (this.getField('ape2').getValue() !== '' && this.getField('ape2').getValue() !== '.' && this.getField('ape2').valid() && this.getField('ape1').valid())) {
            this.getField('ape1').setError(false, '');
            this.getField('ape2').setError(false, '');
        } else if (((this.getField('ape1').getValue()).replace(/\u0020/g, '') === '' || this.getField('ape1').getValue() === '.') &&
            ((this.getField('ape2').getValue()).replace(/\u0020/g, '') === '' || this.getField('ape2').getValue() === '.')) {
            this.getField('ape1').valid();
            this.getField('ape2').valid();
        }
    }
    
    concatenarNombre() {
        let nom1 = this.getField('nom1').getValue();
        let nom2 = this.getField('nom2').getValue();
        let ape1 = this.getField('ape1').getValue();
        let ape2 = this.getField('ape2').getValue();
        let unionCampos = '';
        let arrayNombreFinal = [];
        let arrayUnionCampos = `${nom1},${nom2},${ape1},${ape2}`.split(/\u002C/g);
        arrayUnionCampos.forEach((item) => {
            if (item.replace(/\u0020/g, '') !== '' && item.replace(/\u0020/g, '') !== '.') {
                arrayNombreFinal.push(item);
            }
        });
        let concatenarNombre = '';
        arrayNombreFinal.forEach((item) => {
            concatenarNombre += `${item} `;
        });
        unionCampos = concatenarNombre.substring(0, concatenarNombre.length - 1);

        let estado = true;
        if (this.getField('nom1').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('nom2').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('ape1').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (this.getField('ape2').valid() && estado) {
            estado = true;
        }else{
            estado= false;
        }
        if (unionCampos.replace(/\u0020/g, '') !== '' && estado) {
            estado = true;
        }else{
            estado= false;
        }

        if (this.getField('nombre_tipo_documento').getValue() !== 'NIT') {
            if (estado) {
                this.getField('nombre').setValue(unionCampos);
            } else if (this.getField('nombre').getValue() !== '') {
                this.getField('nombre').setValue('');
                this.getField('nombre').setError(false, '');
            }
        }
    }
    
    validarDatosFrm(boton) {
        switch (boton) {
            case "continuar_basicos":
                if(this.getField('tipo_documento_id').valid() && this.getField('nit_basicos').valid() && 
                this.getField('verifica_basicos').valid() && this.getField('sede_basicos').valid() && 
                this.getField('ape1').valid() && this.getField('ape2').valid() && 
                this.getField('nom1').valid() && this.getField('nom2').valid() && 
                this.getField('nombre').valid() && this.getField('otro_nombre').valid()){
                    if (this.getField('nombre_tipo_documento').getValue() === 'NIT') {
                        this.getField('TabJaivana_66').setNextTab();
                    }
                    if (this.getField('nombre_tipo_documento').getValue() !== 'NIT') {
                        if ((this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
                            this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom1').getValue() !== '.' && (this.getField('nom1').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape2').getValue() !== '.' && (this.getField('ape2').getValue()).replace(/\u0020/g, '') !== '') ||
                            (this.getField('nom2').getValue() !== '.' && (this.getField('nom2').getValue()).replace(/\u0020/g, '') !== '' &&
                                this.getField('ape1').getValue() !== '.' && (this.getField('ape1').getValue()).replace(/\u0020/g, '') !== '')) {
                                    this.getField('TabJaivana_66').setNextTab();
                        } else {
                            this.getField('confirmModalCustom').setTitleAndContent('Error', `Mínimo debe ir un nombre con un apellido`)
                            this.getField('confirmModalCustom').setClickDialog(() => {
                                this.getField('confirmModalCustom').toggle(false);
                                if (this.getField('ape1').getValue() === '.' && this.getField('ape2').getValue() === '.') {
                                    this.getField('ape1').setError(true, '* Este campo es requerido.');
                                    this.getField('ape2').setError(true, '* Este campo es requerido.');
                                }
                                if (this.getField('nom1').getValue() === '.' && this.getField('nom2').getValue() === '.') {
                                    this.getField('nom1').setError(true, '* Este campo es requerido.');
                                    this.getField('nom2').setError(true, '* Este campo es requerido.');
                                }
                            });
                            this.getField("confirmModalCustom").setVisibleCancel(true);
                            this.getField("confirmModalCustom").setButtonConfirm(`Aceptar`); 
                            this.getField('confirmModalCustom').toggle(true);
                        }
                    }
                    // this.getField('codigo_departamento_geografico').setValue('');
                    this.getField('codigo_depto').setValue(''); 
                    this.getField('codigo_ciud').setValue('');
                    this.getField('codigo_barr').setValue(''); 
                    this.limpiarDir = false;
                }
                break;

            case "continuar_geograficos":
                if(this.getField('codigo_pais').valid() && this.getField('codigo_departamento_geografico').valid() &&  this.getField('codigo_ciudad').valid()  && 
                this.getField('codigo_barrio').valid() &&  this.getField('direccion').valid()){
                    this.getField('TabJaivana_66').setNextTab();
                }
                break;

            case "continuar_contacto":
                if(this.getField('telefonos').valid() && this.getField('faxes').valid() && this.getField('email_factura_electronica').valid() && 
                this.getField('email_secundario_facturacion_electronica').valid()){
                    this.getField('TabJaivana_66').setNextTab();
                }
                break;

            case "continuar_cliente":
                if(this.getField('codigo_vendedor').valid() && this.getField('nombre_vendedor').valid() && this.getField('vendedor_id').valid() && 
                this.getField('codigo_negocio').valid() && this.getField('nombre_negocio_clientes').valid() && this.getField('negocio_id').valid() && 
                this.getField('canal_cliente').valid() && this.getField('descripcion_cliente').valid() && 
                this.getField('codigo_oficio').valid() && this.getField('nombre_oficio').valid() && 
                this.getField('observacion').valid() && this.getField('observaciones').valid()){
                    this.getField('TabJaivana_66').setNextTab();
                }
                break;

            case "cancelar":
                this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `¿Desea realizar esta operación?`)
                this.getField('confirmModalCustom').setClickDialog(() => {
                    this.getField('TabJaivana_66').reInit();
                    this.getField('confirmModalCustom').toggle(false); 
                    this.limpiar()
                    this.limpiarDir = true;
                });
                this.getField("confirmModalCustom").setVisibleCancel(false);
                this.getField("confirmModalCustom").setButtonConfirm(`confirmar`);
                this.getField('confirmModalCustom').toggle(true);
                break;

            case "finalizar_identificacion":
                if (this.getField('nit').valid() && this.getField('verifica').valid() && this.getField('sede').valid()) {
                    this.datosAgregar={ datos:{}};
                    this.datosAgregar.datos['nitsx_sedes_id']=this.getField('nitsx_sedes_id').getValue();
                    this.datosAgregar.datos['tipo_documento_id']=this.getField('tipo_documento_id').getValue();
                    this.datosAgregar.datos['tipo_identificacion']=this.getField('tipo_identificacion').getValue();
                    this.datosAgregar.datos['ape1']=this.getField('ape1').getValue();
                    this.datosAgregar.datos['ape2']=this.getField('ape2').getValue();
                    this.datosAgregar.datos['nom1']=this.getField('nom1').getValue();
                    this.datosAgregar.datos['nom2']=this.getField('nom2').getValue();
                    this.datosAgregar.datos['otro_nombre']=this.getField('otro_nombre').getValue();
                    this.datosAgregar.datos['nombre']=this.getField('nombre').getValue();
                    this.datosAgregar.datos['codigo_pais']=this.getField('codigo_pais').getValue();
                    this.datosAgregar.datos['paises_id']=this.getField('paises_id').getValue();
                    this.datosAgregar.datos['codigo_departamento_geografico']=this.getField('codigo_departamento_geografico').getValue();
                    this.datosAgregar.datos['departamento_geografico_id']=this.getField('departamento_geografico_id').getValue();
                    this.datosAgregar.datos['codigo_ciudad']=this.getField('codigo_ciudad').getValue();
                    this.datosAgregar.datos['ciudad_id']=this.getField('ciudad_id').getValue();
                    this.datosAgregar.datos['codigo_barrio']=this.getField('codigo_barrio').getValue();
                    this.datosAgregar.datos['barrio_id']=this.getField('barrio_id').getValue();
                    this.datosAgregar.datos['direccion']=this.getField('direccion').getValue();
                    this.datosAgregar.datos['telefonos']=this.getField('telefonos').getValue();
                    this.datosAgregar.datos['faxes']=this.getField('faxes').getValue();
                    this.datosAgregar.datos['email_factura_electronica']=this.getField('email_factura_electronica').getValue().toLowerCase();
                    this.datosAgregar.datos['email_secundario_facturacion_electronica']=this.getField('email_secundario_facturacion_electronica').getValue().toLowerCase();
                    this.datosAgregar.datos['codigo_vendedor']=this.getField('codigo_vendedor').getValue();
                    this.datosAgregar.datos['vendedor_id']=this.getField('vendedor_id').getValue();
                    this.datosAgregar.datos['codigo_negocio']=this.getField('codigo_negocio').getValue();
                    this.datosAgregar.datos['negocio_id']=this.getField('negocio_id').getValue();
                    this.datosAgregar.datos['canal_cliente']=this.getField('canal_cliente').getValue();
                    this.datosAgregar.datos['codigo_oficio']=this.getField('codigo_oficio').getValue();
                    this.datosAgregar.datos['observacion']=this.getField('observacion').getValue();
                    this.datosAgregar.datos['observaciones']=this.getField('observaciones').getValue();
                    this.datosAgregar.datos['nit']=this.getField('nit').getValue();
                    this.datosAgregar.datos['verifica']=this.getField('verifica').getValue();
                    this.datosAgregar.datos['sede']=this.getField('sede').getValue();
                    let datos=this.datosAgregar;
                        this.generalFormatPmv = { tipo_servicio: 'maes-modificarterceros', operacion: '6', operacion_tipo: 'modificar' };
                        this.service.send(
                        {
                                endpoint: this.constant.formConfiguration(),
                                method:'PUT',
                                body: datos,
                                success: this.successModificarTercero,
                                error: this.error_,
                                general: this.generalFormatPmv
                        });
                }
                break;

            default:
                this.getField('confirmModalCustom').setTitleAndContent('Error', `A este botón no se le ha asignado ninguna pestaña`)
                this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
                this.getField("confirmModalCustom").setVisibleCancel(true); 
                this.getField("confirmModalCustom").setButtonConfirm("OK");
                this.getField('confirmModalCustom').toggle(true);
                break;
        }
    }

    successModificarTercero(data){
        if (data.estado_p === 200 ){
            this.limpiarDir=true;
            this.alertGeneral.toggle(true, 'El Tercero se modifico correctamente', 'success');
            this.establecerPropiedades('1,77,79,81,83,4,6,8,10,84,20,22,24,26,28,30,32,34,36,42,44,46,48,54,56,58,60,62,63,66,67,70,72,89,91,93','value,error','false')
            this.getField('TabJaivana_66').reInit();
            this.establecerPropiedades('97,98,100,101,103','visible','true')
        }else{
            if(data.estado_p===404) {
                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }else {

                let respuesta=Object.values(data.data.errores);
                let keys=Object.keys(data.data.errores);
                this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
            }
        }
    }

    limpiar(){
        this.limpiarError();
        this.establecerPropiedades('97,98,100,101,103','visible','true')  
        this.establecerPropiedades('1,77,79,81,83,4,6,8,10,12,84','disable','true')
        this.establecerPropiedades('1,77,79,81,83,4,6,8,10,84,20,22,24,26,28,30,32,34,36,42,44,46,48,54,56,58,60,62,63,66,67,70,72,89,91,93,98,100','value,error','false')
    }

    //*** fin  los botones y los success

    establecerPropiedades(sObjetos, sPropiedad, sValor) {
        const array = sObjetos.split(',');
        array.forEach((element) => {
            if (sPropiedad === 'disable') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setDisabled(false);
                }
            }
            else if (sPropiedad === 'visible') {
                if (sValor === 'true') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(true);
                }
                else {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setVisible(false);
                }
            }
            else if (sPropiedad === 'error') {
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'value') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue(sValor);
            }
            else if (sPropiedad === 'value,error') {
                this.getField(this.arrayObjetos.get(parseInt(element))).setValue('');
                if (sValor === 'false') {
                    this.getField(this.arrayObjetos.get(parseInt(element))).setError(false,'');
                }
            }
            else if (sPropiedad === 'valid') {
                if (this.getField(this.arrayObjetos.get(parseInt(element))).isValid()) {
                    //ok
                }
                else {
                    this.bHayErrores = true;
                }
            }
        });
    }
}
FormJaivana.addController('maes-modificarterceros', CustomModificarTerceros);
export default CustomModificarTerceros;