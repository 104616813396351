import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomConsultaCuentas
 * @author: Santiago Hernández N.
* @updateBy Anderson Acevedo
 * @version: jdesk_1.01.0001
 **/



class CustomConsultaCuentas extends FormJaivana.form {
    constructor(props) {


        super(props);
        this.initForm                                               = this.initForm.bind(this);
        this.informe                                                = this.informe.bind(this);
        this.soloMovimientos                                        = this.soloMovimientos.bind(this);
        this.otrasConsultasxTercero                                 = this.otrasConsultasxTercero.bind(this);
        this.llenarImpresoras                                       = this.llenarImpresoras.bind(this);
        this.cargarFechas                                           = this.cargarFechas.bind(this);
        this.validarfechas                                          = this.validarfechas.bind(this);     
        this.habilitarSelect                                        = this.habilitarSelect.bind(this); 
        this.procesar                                               = this.procesar.bind(this);   
        this.successcreartmpcontabilidadconsultacuentassintercero   = this.successcreartmpcontabilidadconsultacuentassintercero.bind(this);
        this.successTraerTmp                                        = this.successTraerTmp.bind(this);
        this.successTraerTmp1                                       = this.successTraerTmp1.bind(this);
        this.successTraerTmp2                                       = this.successTraerTmp2.bind(this);
        this.gridOptionsComponentes                                 = Object.assign({}, this.gridOptions);
        this.gridOptionsComponentes1                                = Object.assign({}, this.gridOptions);
        this.limpiarTemporal                                        = this.limpiarTemporal.bind(this);
        this.successDeleteTmp                                       = this.successDeleteTmp.bind(this);
        this.limpiarTemporal1                                       = this.limpiarTemporal1.bind(this);
        this.successDeleteTmp1                                      = this.successDeleteTmp1.bind(this);
        this.limpiarTemporal2                                       = this.limpiarTemporal2.bind(this);
        this.successDeleteTmp2                                      = this.successDeleteTmp2.bind(this);
        this.limpiarTemporal2                                       = this.limpiarTemporal2.bind(this);
        this.successDeleteTmp2                                      = this.successDeleteTmp2.bind(this);
        this.limpiarTemporal3                                       = this.limpiarTemporal3.bind(this);
        this.successDeleteTmp3                                      = this.successDeleteTmp3.bind(this);
        this.limpiarTemporal4                                       = this.limpiarTemporal4.bind(this);
        this.successDeleteTmp4                                      = this.successDeleteTmp4.bind(this);
        this.limpiarTemporal5                                       = this.limpiarTemporal5.bind(this);
        this.successDeleteTmp5                                      = this.successDeleteTmp5.bind(this);
        this.habilitar                                              = this.habilitar.bind(this);
        this.operacion_actual                                       = '';
        this.limpiarCampos                                          = this.limpiarCampos.bind(this);
        this.enviarCorreoTerceros                                   = this.enviarCorreoTerceros.bind(this);
        this.successEnviarCorreoTerceros                            = this.successEnviarCorreoTerceros.bind(this);
        this.generarPdfTabla3                                       = this.generarPdfTabla3.bind(this);
        this.generarExcelTabla3                                     = this.generarExcelTabla3.bind(this);
        this.generarModalTerceros                                   = this.generarModalTerceros.bind(this);
        this.successGenerarModalTerceros                            = this.successGenerarModalTerceros.bind(this);
        this.crearTabla                                             = this.crearTabla.bind(this);
        this.sucessCrearTabla                                       = this.sucessCrearTabla.bind(this);
        this.eliminarTemporalTerceros                               = this.eliminarTemporalTerceros.bind(this);
        this.successEliminarTemporalTerceros                        = this.successEliminarTemporalTerceros.bind(this);
        this.procesarCuentasConTercero                              = this.procesarCuentasConTercero.bind(this);
        this.limpiarErrores                                         = this.limpiarErrores.bind(this);
        this.generarDatosTerceros                                   = this.generarDatosTerceros.bind(this);
        this.sucessGenerarDatosTerceros                             = this.sucessGenerarDatosTerceros.bind(this);
        this.seleccionTotalCreditos                                 = this.seleccionTotalCreditos.bind(this);
        this.seleccionTotalDebitos                                  = this.seleccionTotalDebitos.bind(this);
        this.seleccionTotalSaldo                                    = this.seleccionTotalSaldo.bind(this);
        this.generarPdf                                             = this.generarPdf.bind(this);
        this.generarExcel                                           = this.generarExcel.bind(this);
        this.procesarCuentasSinTercero                              = this.procesarCuentasSinTercero.bind(this);
        this.sucessCrearTablaTmpSinTerceros                         = this.sucessCrearTablaTmpSinTerceros.bind(this);
        this.soloMovimientosdetalladoterceronormal                  = this.soloMovimientosdetalladoterceronormal.bind(this);
        this.successTraerDatos                                      = this.successTraerDatos.bind(this);
        this.successTraerDatosAcumulado                             = this.successTraerDatosAcumulado.bind(this);
        this.successconsultarmovimientoacumuladoxtercero            = this.successconsultarmovimientoacumuladoxtercero.bind(this);
        this.consultar                                              = this.consultar.bind(this);
        this.aceptar                                                = this.aceptar.bind(this);
        this.generarModalSoloMovimiento                             = this.generarModalSoloMovimiento.bind(this);
        this.successGenerarModalSoloMovimiento                      = this.successGenerarModalSoloMovimiento.bind(this);
        this.generarExcelSoloMovimiento                             = this.generarExcelSoloMovimiento.bind(this);
        this.generarPdfTablaSoloMovimiento                          = this.generarPdfTablaSoloMovimiento.bind(this);
        this.enviarCorreoSoloMovimiento                             = this.enviarCorreoSoloMovimiento.bind(this);
        this.successEnviarCorreoSoloMovimiento                      = this.successEnviarCorreoSoloMovimiento.bind(this);
        this.creartablaacumuladoxtercero                            = this.creartablaacumuladoxtercero.bind(this);
        this.fecha_hoy='';
        this.fecha_actual='';
        this.operacion_movimiento='tercero_acumulado';
        this.formatNumberSaldo                                      = this.formatNumberSaldo.bind(this);
        this.currencyFormatterdebito                                = this.currencyFormatterdebito.bind(this);
        this.currencyFormattercredito                               = this.currencyFormattercredito.bind(this);
        this.currencyFormattersaldo                                 = this.currencyFormattersaldo.bind(this);
        this.successconsultarmovimientoacumuladoxtercerosinsaldo    = this.successconsultarmovimientoacumuladoxtercerosinsaldo.bind(this); 
        this.successconsultarmovimientoacumuladoxsucursal           = this.successconsultarmovimientoacumuladoxsucursal.bind(this); 
        this.successconsultarmovimientodetalladocentrocosto         = this.successconsultarmovimientodetalladocentrocosto.bind(this); 
        this.successTraerTmp3                                       = this.successTraerTmp3.bind(this);
        this.validarMovimientoTerceroSinSaldo                       = this.validarMovimientoTerceroSinSaldo.bind(this);
        this.currencyFormattertarifa                                = this.currencyFormattertarifa.bind(this);
        this.currencyFormatterbase                                  = this.currencyFormatterbase.bind(this);
        this.creartablaacumuladoxtercerosinsaldo                    = this.creartablaacumuladoxtercerosinsaldo.bind(this);
        this.creartablaacumuladoxsucursal                           = this.creartablaacumuladoxsucursal.bind(this);
        this.creartabladetalladocentrocosto                         = this.creartabladetalladocentrocosto.bind(this);
        this.successTraerTmp4                                       = this.successTraerTmp4.bind(this);
        this.successTraerTmp5                                       = this.successTraerTmp5.bind(this);
        this.validarMovimientoAcumuladoSucursal                     = this.validarMovimientoAcumuladoSucursal.bind(this);
        this.crearTablaSaldosGlobales                               = this.crearTablaSaldosGlobales.bind(this);
        this.sucessCrearTablaSaldosGlobales                         = this.sucessCrearTablaSaldosGlobales.bind(this);
        this.successTraerTmpSaldosGlobales                          = this.successTraerTmpSaldosGlobales.bind(this);
        this.limpiarTemporal6                                       = this.limpiarTemporal6.bind(this);
        this.successDeleteTmp6                                      = this.successDeleteTmp6.bind(this);
        this.limpiarTemporal7                                       = this.limpiarTemporal7.bind(this);
        this.successDeleteTmp7                                      = this.successDeleteTmp7.bind(this);
        this.limpiarTemporal8                                       = this.limpiarTemporal8.bind(this);
        this.successDeleteTmp8                                      = this.successDeleteTmp8.bind(this);
        this.limpiarTemporal9                                       = this.limpiarTemporal9.bind(this);
        this.successDeleteTmp9                                      = this.successDeleteTmp9.bind(this);
        this.nombre_pdf='';
        this.nombre_excel='';
        this.operacion_normal='';
        this.currencyFormatterdebito                                = this.currencyFormatterdebito.bind(this);
        this.generarModalSaldosGlobales                             = this.generarModalSaldosGlobales.bind(this);
        this.successGenerarSaldosGlobales                           = this.successGenerarSaldosGlobales.bind(this);
        this.currencyFormattercontabilidad                          = this.currencyFormattercontabilidad.bind(this);
        this.mostrar1                                               = this.mostrar1.bind(this);
        this.mostrar                                                = this.mostrar.bind(this);
        this.validarOtrascuentasxtercero                            = this.validarOtrascuentasxtercero.bind(this);
        this.successTraerDatoscuentaxtercero                        = this.successTraerDatoscuentaxtercero.bind(this);
        this.successDatoscuentaxtercero                             = this.successDatoscuentaxtercero.bind(this);
        this.otrasconsultasxtercero                                 = this.otrasconsultasxtercero.bind(this);
        this.successGenerarModalPorTercero                          = this.successGenerarModalPorTercero.bind(this);
        this.generarModalPorTercero                                 = this.generarModalPorTercero.bind(this);
        this.currencyFormattervalor                                 = this.currencyFormattervalor.bind(this);
        this.successTraerDatoscuentaxtercerodetallado               = this.successTraerDatoscuentaxtercerodetallado.bind(this);
        this.successDatoscuentaxtercerodetallado                    = this.successDatoscuentaxtercerodetallado.bind(this);
        this.successDatosmovimientoxtercero                         = this.successDatosmovimientoxtercero.bind(this);
        this.successGenerarModalMovimientosDetalladosXTerceros      = this.successGenerarModalMovimientosDetalladosXTerceros.bind(this);
        this.eliminarTabla                                          = this.eliminarTabla.bind(this);
        this.eliminar                                               = this.eliminar.bind(this);
        this.enviarCorreoCuentasSinTerceros                         = this.enviarCorreoCuentasSinTerceros.bind(this);
        this.successEnviarCorreoCuentasSinTerceros                  = this.successEnviarCorreoCuentasSinTerceros.bind(this);
        this.gridOptionsComponentes['onSelectionChanged']           = this.onSelectionChanged;
        this.deshabilitarCorreo                                     = this.deshabilitarCorreo.bind(this);
        this.habilitarCorreo                                        = this.habilitarCorreo.bind(this);
        this.elegirTablaEliminar                                    = this.elegirTablaEliminar.bind(this);
        this.elegirTablaEliminarMovimientos                         = this.elegirTablaEliminarMovimientos.bind(this);
        this.mostrarModalInformativoEmail                           = this.mostrarModalInformativoEmail.bind(this);
        this.isJson                                                 = this.isJson.bind(this);
        this.mostrarMensajeEmailEnviado                             = this.mostrarMensajeEmailEnviado.bind(this);
        this.mostrarMensajeEmailFallo                               = this.mostrarMensajeEmailFallo.bind(this);
        this.currencyFormatterGeneral                               = this.currencyFormatterGeneral.bind(this);
    }



    initForm() {
        console.log("Formulario CustomConsultaCuentas,  @version: jdesk_1.01.0001, @author: Santiago Hernández N., @updateBy Anderson Acevedo ");
        this.cargarFechas();
        this.informe();
        this.soloMovimientos();
        this.otrasConsultasxTercero();
        this.llenarImpresoras();
        this.getField('radio_correo').setValue("N");
        this.getField("fecha_desde").setOnChange(this.validarfechas);
        this.getField("fecha_hasta").setOnChange(this.validarfechas);
        this.getField("nombre_cuenta_contable").setOnChange(this.habilitar);
        this.getField("nombre").setOnChange(this.habilitar);
        this.getField("centro_costo_nombre").setOnChange(this.habilitar);
        this.getField("ch_niif").setOnChange(this.habilitar);
        this.getField('total_debitos_con_terceros').setDisabled(true);
        this.getField('total_creditos_con_terceros').setDisabled(true);
        this.getField('total_debitos_sin_tercero').setDisabled(true);
        this.getField('total_creditos_sin_terceros').setDisabled(true);
        this.getField('total_creditos_solo_movimiento').setDisabled(true);
        this.getField('total_debitos_solo_movimiento').setDisabled(true);
        this.getField('total_contabilidad').setDisabled(true);
        this.getField('total_valor').setDisabled(true);
        this.getField('total_debitos').setDisabled(true);
        this.getField('total_creditos').setDisabled(true);

        let hoy= new Date();
        let dia=hoy.getDate();
        let mes=hoy.getMonth()+1;
        let año=hoy.getFullYear();
        if(mes<10){
            mes='0'+mes;
        }
        if(dia<10){
            dia='0'+dia;
        }
        this.fecha_hoy=año+'-'+mes+'-'+dia;
        this.getField("informe").setOnChange(this.habilitarSelect);

        this.getField('solo_movimientos').setVisible(false);
        this.getField('otras_consultas_terceros').setVisible(false);

        this.getField('ch_excel').setOnChange((props)=>{
            if(this.getField('ch_excel').getValue()==='false'){
              this.getField('ch_pdf').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='excel';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pdf').setOnChange((props)=>{
            if(this.getField('ch_pdf').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pantalla').setValueSwitch (false);
              this.operacion_actual='pdf';
              this.habilitarCorreo();
            }
          });
          this.getField('ch_pantalla').setOnChange((props)=>{
            if(this.getField('ch_pantalla').getValue()==='false'){
              this.getField('ch_excel').setValueSwitch (false);
              this.getField('ch_pdf').setValueSwitch (false);
              this.operacion_actual='pantalla';
              this.deshabilitarCorreo();
            }else{
                this.habilitarCorreo();
            }
          });

        this.getField('procesar').setClick(this.procesar);
        this.getField('aceptar').setClick(this.aceptar);
        this.getField('modal_sin_terceros').setCloseButton(this.limpiarTemporal);
        this.getField('modal_terceros').setCloseButton(this.eliminarTemporalTerceros);
        this.getField('modal_solo_movimiento').setCloseButton(this.eliminarTabla);
        this.getField('modal_saldosglobales').setCloseButton(this.limpiarTemporal6);
        this.getField('modal_movimiento_detallado_tercero').setCloseButton(this.limpiarTemporal9);
        this.getField('modal_cuentas_terceros').setCloseButton(this.eliminar);
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
    }

    onSelectionChanged(){
        
    }

    deshabilitarCorreo(){
        this.getField('email').setDisabled(true);
        this.getField('email').setValue('');
        this.getField('email').setError(false,'');
        this.getField('radio_correo').setDisabled(true);
        this.getField('radio_correo').setValue("N");
    }

    habilitarCorreo(){
        this.getField('email').setDisabled(false);
        this.getField('radio_correo').setDisabled(false);
        this.getField('radio_correo').setValue("N");
    }

    eliminarTabla(){
        if(this.operacion_movimiento==='tercero_acumulado'){
            this.limpiarTemporal2();
        }
        else if(this.operacion_movimiento==='tercero_normal'){
            this.limpiarTemporal1(); 
        }             
        else if(this.operacion_movimiento==='tercero_acumulado_sin_saldo'){
            this.limpiarTemporal3();
        }
        else if(this.operacion_movimiento==='acumulado_sucursal'){
            this.limpiarTemporal4();
        }
        else if(this.operacion_movimiento==='centro_costo'){
            this.limpiarTemporal5();
        }  
    }
    
    eliminar(){
        if(this.operacion_normal==='cuentas_por_tercero_detallado'){
            this.limpiarTemporal8();
        }
        else if(this.operacion_normal==='cuentas_por_tercero'){
            this.limpiarTemporal7();
        }
    }

    habilitar(){
        this.getField('procesar').setDisabled(false);
        //.getField('rejilla').toggle(false);

        if(this.getField("nombre_cuenta_contable").getValue() !== ''){
            this.getField("nombre_cuenta_contable").valid(false)
        }
        if(this.getField("nombre").getValue() !== ''){
            this.getField("nombre").valid(false)
        } 
        if(this.getField("centro_costo_nombre").getValue() !== ''){
            this.getField("centro_costo_nombre").valid(false)
        }
        if(this.getField("centro_costo_nombre").getValue() !== ''){
            this.getField("centro_costo_nombre").valid(false)
        }
    }

    validarfechas(){
        this.habilitar();
        let fecha_actual = new Date();
        let fecha_desde = new Date(this.getField("fecha_desde").getValue()).toISOString().split('T')[0];
        let fecha_hasta = new Date(this.getField("fecha_hasta").getValue()).toISOString().split('T')[0];

        this.getField("fecha_desde").setError(false,"");
        this.getField("fecha_hasta").setError(false,"");

        if(fecha_desde > fecha_actual){
            this.getField("fecha_desde").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_hasta > fecha_actual){
            this.getField("fecha_hasta").setError(true,"No puede ser mayor a la fecha actual.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }

        if(fecha_desde > fecha_hasta){
            this.getField("fecha_hasta").setError(true,"No puede ser menor a la fecha desde.");
            this.getField('procesar').setDisabled(true); 
            return false;
        }
        this.getField('procesar').setDisabled(false); 
        return true;

    }


    cargarFechas(){

        let fecha_desde = this.getField('fecha_desde').getValue();
        let fecha_desde_nueva = fecha_desde.split('-',2)[0]+'-'+fecha_desde.split('-',2)[1]+'-01';
        this.getField('fecha_desde').setValue(fecha_desde_nueva);
    }

    informe(){
        let informe = [
            {
                informe:'Consulta cuenta sin tercero'
            },
            {
                informe:'Consultar tercero'
            },
            {
                informe:'Solo movimientos'
            },
            {
                informe:'Saldos globales por tercero'
            },
            {
                informe:'Otras consultas por tercero'
            }
        ]


        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
        informe.forEach(item => {
        let dataOp = {}
        dataOp['value'] = item.informe;
        dataOp['text'] = item.informe;
        dataOp['campos_cambian'] = { };
        opciones.push(dataOp); })
        this.getField('informe').setOptions(opciones);
    }


    soloMovimientos(){
        let solo_movimientos = [
            {
                solo_movimientos:'Movimiento detallado por tercero normal'
            },
            {
                solo_movimientos:'Movimiento detallado acumulado por tercero'
            },
            {
                solo_movimientos:'Movimiento detallado acumulado por tercero sin saldo anterior'
            },
            {
                solo_movimientos:'Movimiento detallado acumulado por sucursal'
            },
            {
                solo_movimientos:'Movimiento detallado por centro de costo'
            }
        ]


        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
        solo_movimientos.forEach(item => {
        let dataOp = {}
        dataOp['value'] = item.solo_movimientos;
        dataOp['text'] = item.solo_movimientos;
        dataOp['campos_cambian'] = { };
        opciones.push(dataOp); })
        this.getField('solo_movimientos').setOptions(opciones);
    }

    otrasConsultasxTercero(){
        let otras_consultas_terceros = [
            {
                otras_consultas_terceros:'Movimiento detallado por tercero'
            },
            {
                otras_consultas_terceros:'Cuentas por tercero'
            },
            {
                otras_consultas_terceros:'Cuentas por tercero detallado'
            }
        ]


        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
        otras_consultas_terceros.forEach(item => {
        let dataOp = {}
        dataOp['value'] = item.otras_consultas_terceros;
        dataOp['text'] = item.otras_consultas_terceros;
        dataOp['campos_cambian'] = { };
        opciones.push(dataOp); })
        this.getField('otras_consultas_terceros').setOptions(opciones);
    }

    llenarImpresoras()
    {
        let opciones = [{'text':'Ninguno','value':' ','campos_cambian':{}}];
        let data2 = {
            "data": [
                {
                    "codigo": '1',
                    "descripcion": 'Hp 1',
                },
                {
                    "codigo": '2',
                    "descripcion": 'Dell 1',
                },
                {
                    "codigo": '3',
                    "descripcion": 'Dell 2',
                }
            ]
        }
        data2.data.forEach(item => {
            let dataOp = {}
            dataOp['value'] = item.codigo;
            dataOp['text'] = item.descripcion;
            dataOp['campos_cambian'] = {/* separador_select: item.valor */ };
            opciones.push(dataOp);
        });
        this.getField('select_impresora').setOptions(opciones);
    }

    habilitarSelect(){
        this.habilitar();
        let informe = this.getField('informe').getValue();

        if (informe !== ''){

            if(informe === 'Solo movimientos'){
                this.getField('solo_movimientos').setVisible(true);
                this.getField('otras_consultas_terceros').setVisible(false);
            }else if (informe === 'Otras consultas por tercero'){
                this.getField('otras_consultas_terceros').setVisible(true);
                this.getField('solo_movimientos').setVisible(false);
            }else{
                this.getField('solo_movimientos').setVisible(false);
                this.getField('otras_consultas_terceros').setVisible(false);
            }

        }else{

            this.getField('solo_movimientos').setVisible(false);
            this.getField('otras_consultas_terceros').setVisible(false);
        
        }
    }

    procesar(){
        let seleccion = this.getField('informe').getValue();
        this.limpiarErrores();
        if (this.getField('informe').valid()){

            if(seleccion === 'Consulta cuenta sin tercero'){
                this.validarCamposCuentasSinTercero();
            }else if (seleccion === 'Consultar tercero'){
                this.procesarCuentasConTercero();
            }else if(seleccion === 'Solo movimientos'){
                this.mostrar()
            }else if(seleccion === 'Saldos globales por tercero'){
                if(this.validarMovimientoTerceroSaldosGlobales()===0){
                    this.getField('procesar').setDisabled(true);
                    this.crearTablaSaldosGlobales();
                }
            }else if(seleccion === 'Otras consultas por tercero'){
                this.mostrar1()
            }
        }

    }
    procesarCuentasConTercero(){
        let errores =0;
        
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('cuenta_contable').valid()===false){
            errores++;
        }
        if(this.getField('nombre_cuenta_contable').valid()===false){
            errores++;
        }
        if(this.getField('nit_buscar').valid()===false){
            errores++;
        }
        if(this.getField('nombre').valid()===false){
            errores++;
        }
        if(this.getField('sede').valid()===false){
            errores++;
        }
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
        if(this.getField('email').valid()===false){
            errores++;
        }
        }
        if(this.getField('centro_costo_codigo').getValue()!==''){
            if(this.getField('centro_costo_codigo').valid()===false 
            || this.getField('centro_costo_nombre').valid()===false){
                errores++;
            }
        }
        
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }
        if(errores===0){
            this.crearTabla();
        }
    }

    mostrar(){
        let errores =0;
        if(!this.getField("solo_movimientos").valid()){
            errores++;
        }else{
            let opcion_movimiento = this.getField("solo_movimientos").getValue();
            if(opcion_movimiento==='Movimiento detallado acumulado por tercero'){
                this.operacion_movimiento='tercero_acumulado';
                errores=this.validarMovimientoAcumuladoTercero();
            }else if(opcion_movimiento==='Movimiento detallado por tercero normal'){
                this.operacion_movimiento='tercero_normal';
                errores=this.validarCamposNormal();
            }else if(opcion_movimiento==='Movimiento detallado acumulado por tercero sin saldo anterior'){
                this.operacion_movimiento='tercero_acumulado_sin_saldo';
                errores=this.validarMovimientoTerceroSinSaldo();
            }else if(opcion_movimiento==='Movimiento detallado acumulado por sucursal'){
                this.operacion_movimiento='acumulado_sucursal';
                errores=this.validarMovimientoAcumuladoSucursal();
            }else if(opcion_movimiento==='Movimiento detallado por centro de costo'){
                this.operacion_movimiento='centro_costo';
                errores=this.validarMovimientoAcumuladoSucursal();
            }else{
                errores++;
                this.alertGeneral.toggle(true, 'Opcion de movimientos sin configurar.', 'error');
            }
        }
        if(errores===0){
            this.soloMovimientosdetalladoterceronormal()
        }
    }

    validarCamposNormal(){
        let errores =0;
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf, Excel o Pantalla.', 'error');
        }
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(!this.getField("cuenta_contable").valid())
            errores++;
        if(!this.getField("nombre_cuenta_contable").valid())
            errores++;
        if(!this.getField("centro_costo_codigo").valid())
            errores++;
        if(!this.getField("centro_costo_nombre").valid())
            errores++;
        return errores;
    }

    validarMovimientoAcumuladoTercero(){
        let errores=0;
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf, Excel o Pantalla.', 'error');
        }
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('cuenta_contable').getValue()!==''){
            if(!this.getField('cuenta_contable').valid())
                errores++;
            if(!this.getField('nombre_cuenta_contable').valid())
                errores++;
        }
        if(!this.getField("centro_costo_codigo").valid())
            errores++;
        if(!this.getField("centro_costo_nombre").valid())
            errores++;
        return errores;
    }

    validarMovimientoTerceroSinSaldo(){
        let errores=0;
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf, Excel o Pantalla.', 'error');
        }
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('cuenta_contable').getValue()!==''){
            if(!this.getField('cuenta_contable').valid())
                errores++;
            if(!this.getField('nombre_cuenta_contable').valid())
                errores++;
        }

        if(this.getField("centro_costo_codigo").getValue()!==''){
            if(!this.getField("centro_costo_codigo").valid())
            errores++;
            if(!this.getField("centro_costo_nombre").valid())
                errores++;
        }

        return errores;
    }

    validarMovimientoTerceroSaldosGlobales(){
        let errores=0;
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf, Excel o Pantalla.', 'error');
        }
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }

        if(!this.getField('cuenta_contable').valid())
            errores++;
        if(!this.getField('nombre_cuenta_contable').valid())
            errores++;

        if(this.getField("centro_costo_codigo").getValue()!==''){
            if(!this.getField("centro_costo_codigo").valid())
            errores++;
            if(!this.getField("centro_costo_nombre").valid())
                errores++;
        }

        return errores;
    }

    validarMovimientoAcumuladoSucursal(){
        let errores=0;
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf, Excel o Pantalla.', 'error');
        }
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('cuenta_contable').getValue()!==''){
            if(!this.getField('cuenta_contable').valid())
                errores++;
            if(!this.getField('nombre_cuenta_contable').valid())
                errores++;
        }

        return errores;
    }
    
    validarOtrascuentasxtercero(){
        let errores=0;
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf, Excel o Pantalla.', 'error');
        }
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }

        if(!this.getField('nit_buscar').valid())
            errores++;
        if(!this.getField('nombre').valid())
            errores++;

        if(!this.getField('sede').valid())
            errores++;

        if(this.getField("centro_costo_codigo").getValue()!==''){
            if(!this.getField("centro_costo_codigo").valid())
            errores++;
            if(!this.getField("centro_costo_nombre").valid())
                errores++;
        }

        return errores;
    }

    crearTabla(){
        this.getField('procesar').setDisabled(true);
        this.mostrarModalInformativoEmail();
        let datos={ datos: {
        }};
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'creartablatemporalterceros', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessCrearTabla,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }
    
    sucessCrearTabla(data){
          if (data.estado_p === 200 )
          {
            this.generarDatosTerceros(); 
          }
          else 
          {
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
          }
          
      }

      generarDatosTerceros(){
        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            cuenta_contable:Number(this.getField('cuenta_contable').getValue()),
            nit_buscar:this.getField('nit_buscar').getValue(),
            sede:this.getField('sede').getValue(),
            ch_niif:this.getField('ch_niif').getValue()
        }};
        if(this.getField('centro_costo_codigo').getValue()!=='')
            datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'generardatosterceros', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.sucessGenerarDatosTerceros,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
        });
    }
    
    sucessGenerarDatosTerceros(data){
          if (data.estado_p === 200 )
          {
            if(this.operacion_actual!==''){
                this.operacion_normal='cuentas_con_terceros';
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoTerceros();
                }else{
                    this.nombre_pdf='cuentasconterceros';
                    this.nombre_excel='cuentasconterceros';
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTabla3();
                    }else if(this.operacion_actual==='pantalla'){
                      this.generarModalTerceros();
                    }else if(this.operacion_actual==='excel'){
                      this.generarExcelTabla3();
                    } 
                }
                
              }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
              }
          }
          else 
          { 
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
              let respuesta=Object.values(data.data.errores);
              let keys=Object.keys(data.data.errores);
              this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
              }
              this.eliminarTemporalTerceros();
          }
      }

    successcreartmpcontabilidadconsultacuentassintercero(data){
        if (data.estado_p === 200){
            let datos={ datos: {
                ch_niif:this.getField('ch_niif').getValue()
            }};
            this.getField("rejilla").setTipoServicioOperacion("cont-consultadecuentas-1");
            this.getField("rejilla").sendRequest(this.successTraerTmp, datos);
            /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '1', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTmp,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
        }else{
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Verifique su información, error al procesar.', 'error');
            this.getField('procesar').setDisabled(false);
        }
    }

    successTraerTmp(data){
        this.getField('confirmModalCustom').toggle(false);
        if (data.estado_p === 200){
            let datosFicha = [];
            let data2 = 
                    {
                        "nombre": data.data.lista.nombre_empresa,
                        "operacion": data.data.lista.operacion,
                        "fecha_actual": data.data.lista.fecha_actual,
                        "desde-hasta": this.getField("fecha_desde").getValue().replaceAll('-','/')+"-"+this.getField("fecha_hasta").getValue().replaceAll('-','/'),
                        "codigo_cuenta": this.getField('cuenta_contable').getValue(),
                        "usuario": data.data.lista.usuario
                    };
            if (this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                data2.codigo_centro_costo=this.getField('centro_costo_codigo').getValue();
             else
                data2.codigo_centro_costo='Todos';
            datosFicha.push(data2);        

            this.getField('lista_datos_sin_terceros').setItemsFichas(datosFicha);
            this.getField('rejilla').toggle(true);
            let configCell = new Map();
            this.gridOptionsComponentes['rowData'] = data.data.datos;
            this.getField('modal_sin_terceros').handleClickOpen();
            this.getField('total_debitos_sin_tercero').setValue(data.data.lista.total_debitos.toFixed(2));
            this.getField('total_creditos_sin_terceros').setValue(data.data.lista.total_creditos.toFixed(2));
            this.getField('total_debitos_sin_tercero').setVisible(true);
            this.getField('total_creditos_sin_terceros').setVisible(true);
            configCell.set('debitos', { cellRenderer: this.seleccionTotalDebitos, width: 200, field: 'débitos' });
            configCell.set('creditos', { cellRenderer: this.seleccionTotalCreditos, width: 200, field: 'créditos' });
            configCell.set('saldo', { cellRenderer: this.seleccionTotalSaldo, width: 200, field: 'saldo' });
            //configCell.set('accion', { cellRenderer: this.setButtonDelete, width: 110, sortable: false, filter: false, field: 'Eliminar' });
            this.getField('rejilla').initData(this.gridOptionsComponentes,configCell);
            this.limpiarCampos();
        }else{
            this.limpiarCampos();
            this.getField('confirmModalCustom').toggle(false);
            this.getField('rejilla').toggle(false); 
            this.getField('procesar').setDisabled(false);
            this.alertGeneral.toggle(true, 'Error al consultar tmp_contabilidad_consulta_cuentas_uuid.', 'error');
            this.limpiarTemporal();
        }
    }
  

    successTraerTmp1(data){
        if (data.estado_p === 200 )
            {
                if(this.operacion_actual!==''){
                    if(this.getField('radio_correo').getValue()==='S'){
                        this.enviarCorreoSoloMovimiento();
                    }else{
                        if(this.operacion_actual==='pdf'){
                        this.generarPdfTablaSoloMovimiento();
                        }else if(this.operacion_actual==='pantalla'){
                        this.generarModalSoloMovimiento();
                        }else if(this.operacion_actual==='excel'){
                        this.generarExcelSoloMovimiento();
                        } 
                    }
                    
                }else{
                    this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                    this.getField('confirmModalCustom').toggle(false);
                }
            }
            else 
            {
                this.limpiarCampos();
                this.getField('rejilla_solo_movimiento').toggle(false);
                this.getField('procesar').setDisabled(false);
                this.getField('confirmModalCustom').toggle(false);
                this.alertGeneral.toggle(true, 'Error al consultar tmp_uuid.', 'error');
                this.limpiarTemporal1();
            }
    }

    limpiarTemporal2(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_3', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp2,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    limpiarTemporal(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    
    limpiarTemporal3(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_4', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp3,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
        
    limpiarTemporal4(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_5', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp4,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp4(data){
        //tmp_acumuladoxsucursal_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    limpiarTemporal5(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_6', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp5,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp5(data){
        //tmp_detalladocentrocosto_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    successTraerTmp2(data){
        if (data.estado_p === 200){
            if(this.operacion_actual!==''){
                if(this.getField('radio_correo').getValue()==='S'){
                    this.enviarCorreoSoloMovimiento();
                }else{
                    if(this.operacion_actual==='pdf'){
                        this.generarPdfTablaSoloMovimiento();
                    }else if(this.operacion_actual==='pantalla'){
                        this.generarModalSoloMovimiento();
                    }else if(this.operacion_actual==='excel'){
                        this.generarExcelSoloMovimiento();
                    } 
                }
                
            }else{
                this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
                this.getField('confirmModalCustom').toggle(false);
            }
        }
        else 
        {
            this.limpiarCampos();
            this.getField('rejilla_solo_movimiento').toggle(false);
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Error al consultar tmp_uuid.', 'error');
            this.limpiarCampos();
            this.limpiarTemporal2();
        }
    }

    limpiarTemporal1(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_1', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp1,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }
    
    successDeleteTmp(data){
        this.getField('procesar').setDisabled(false);
        //tmp_contabilidad_consulta_cuentas_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    successDeleteTmp1(data){
        //tmp_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    successDeleteTmp2(data){
        //tmp_solo_movimiento_acumulado_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    successDeleteTmp3(data){
        //tmp_acumuladoxtercerosinsaldo_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    enviarCorreoTerceros(){

        let datos = { datos: {
          fecha_desde:this.getField('fecha_desde').getValue(),
          fecha_hasta:this.getField('fecha_hasta').getValue(),
          cuenta_contable:this.getField('cuenta_contable').getValue(),
          operacion:this.operacion_actual,
          nit_buscar:this.getField('nit_buscar').getValue(),
          sede:this.getField('sede').getValue(),
          radio_correo:this.getField('radio_correo').getValue(),
          email:this.getField('email').getValue(),
        }};
        
        let operacion1='';
        if(this.operacion_normal==='cuentas_con_terceros'){
            operacion1='generaroperacionterceros_pag';
            if(this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
        }
        else if(this.operacion_normal==='cuentas_saldos_globales'){
            operacion1='generaroperacionsaldosglobales_pag';
            if(this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
        }
        else if(this.operacion_normal==='cuentas_por_tercero'){
            operacion1='generaroperacioncuentasxtercero_pag';
            if(this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
        }
        else if(this.operacion_normal==='cuentas_por_tercero_detallado'){
            operacion1='generaroperacioncuentasxtercerodetallado_pag';
            if(this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
        }
        else if(this.operacion_normal==='movimiento_detallado_por_tercero'){
            operacion1='generaroperacionmovimientoxtercero_pag';
            datos = { datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue(),
            }};
        }

        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: operacion1, operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successEnviarCorreoTerceros,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
    }
    
    successEnviarCorreoTerceros(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){
            this.mostrarMensajeEmailEnviado();
            this.limpiarCampos();
        }else{
            this.limpiarCampos();
            this.mostrarMensajeEmailFallo();
            this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        this.elegirTablaEliminar();
    }

    generarModalMovimientosDetalladosXTerceros(){
        let datos = { datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue()
        }};
        this.getField("tabla_movimiento_detallado_tercero").setTipoServicioOperacion("cont-consultadecuentas-generaroperacionmovimientoxtercero");
        this.getField("tabla_movimiento_detallado_tercero").sendRequest(this.successGenerarModalMovimientosDetalladosXTerceros, datos);
        /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'generaroperacionmovimientoxtercero', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGenerarModalMovimientosDetalladosXTerceros,
                error: this.error_,
                general: this.generalFormatPmv
        });*/
    }

    successGenerarModalMovimientosDetalladosXTerceros(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            let datosFicha = [];
            let data2 = 
                    {
                        "nombre": data.data.lista.nombre_empresa,
                        "operacion": data.data.lista.operacion,
                        "fecha_actual": data.data.lista.fecha_actual,
                        "desde-hasta": data.data.lista.fechasdesdehasta,
                        "codigo_cuenta": data.data.lista.codigo_cuenta,
                        "usuario": data.data.lista.usuario,
                        "codigo_centro_costo": data.data.lista.centro_costo_codigo===null?'Todos':data.data.lista.centro_costo_codigo,
                    };
            datosFicha.push(data2);

            this.getField('lista_movimiento_detallado_tercero').setItemsFichas(datosFicha);
            this.getField('total_debitos_mov').setValue(data.data.lista.total_debitos);
            this.getField('total_debitos_mov').setVisible(true);
            this.getField('total_creditos_mov').setValue(data.data.lista.total_creditos);
            this.getField('total_creditos_mov').setVisible(true);
            this.getField('tabla_movimiento_detallado_tercero').toggle(true);
            let configCell = new Map();
            configCell.set('debitos',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterdebito,field: 'débitos'});
            configCell.set('creditos',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercredito,field: 'créditos'});

            this.gridOptionsComponentes['rowData'] = data.data.datos;
            this.getField('tabla_movimiento_detallado_tercero').initData(this.gridOptionsComponentes, configCell);
            this.getField('modal_movimiento_detallado_tercero').handleClickOpen();
            this.limpiarCampos();
        }else if (data.estado_p === 404){
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.limpiarTemporal9();
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else{
            this.limpiarCampos();
            this.limpiarTemporal9();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        
    }

    generarModalTerceros(){
        let datos = { datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                nit_buscar:this.getField('nit_buscar').getValue(),
                sede:this.getField('sede').getValue(),
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue(),
                ch_niif:this.getField('ch_niif').getValue()
        }};
        this.getField("tabla_terceros").setTipoServicioOperacion("cont-consultadecuentas-generaroperacionterceros");
        this.getField("tabla_terceros").sendRequest(this.successGenerarModalTerceros, datos);
        /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'generaroperacionterceros', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successGenerarModalTerceros,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
    }

    successGenerarModalTerceros(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            //llenar lista
            //nombre,operacion,fecha_actual,desde-hasta,sucursales,usuario
            let datosFicha = [];
            let data2 = 
                    {
                        "nombre": data.data.lista.nombre_empresa,
                        "operacion": data.data.lista.operacion,
                        "fecha_actual": data.data.lista.fecha_actual,
                        "desde-hasta": data.data.lista.fechasdesdehasta,
                        "codigo_cuenta": data.data.lista.codigo_cuenta,
                        "usuario": data.data.lista.usuario,
                        "nit": data.data.lista.nit,
                        "sede": data.data.lista.sede
                    };
            if (this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                    data2.codigo_centro_costo=this.getField('centro_costo_codigo').getValue();
             else
                    data2.codigo_centro_costo='Todos';
            datosFicha.push(data2);

            this.getField('lista_datos').setItemsFichas(datosFicha);
            this.getField('total_debitos_con_terceros').setValue(data.data.lista.total_debitos);
            this.getField('total_creditos_con_terceros').setValue(data.data.lista.total_creditos);

            this.getField('total_debitos_con_terceros').setVisible(true);
            this.getField('total_creditos_con_terceros').setVisible(true);
            this.getField('tabla_terceros').toggle(true);
            let configCell = new Map();
            configCell.set('debitos',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterdebito,field: 'débitos'});
            configCell.set('creditos',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercredito,field: 'créditos'});
            configCell.set('saldo',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattersaldo});

            this.gridOptionsComponentes['rowData'] = data.data.datos;
            this.getField('tabla_terceros').initData(this.gridOptionsComponentes, configCell);
            this.getField('modal_terceros').handleClickOpen();
            this.limpiarCampos();
        }else if (data.estado_p === 404){
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.eliminarTemporalTerceros();
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else{
            this.limpiarCampos();
            this.eliminarTemporalTerceros();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }

    mostrarModalInformativoEmail(){
        if(this.operacion_actual==='pantalla')
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere, se esta generando la información... `);
        else
            this.getField('confirmModalCustom').setTitleAndContent('Advertencia', `No recargue la página, por favor espere. Si el archivo es demasiado grande, al finalizar se enviará el documento adjunto al correo: `+this.getField('email').getValue());
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("."); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    generarPdfTabla3()
    {       
            let url = this.constant.formConfiguration();
            let operacion='';
            if(this.operacion_normal==='cuentas_con_terceros')
                operacion='generaroperacionterceros_pag';
            else if(this.operacion_normal==='cuentas_saldos_globales')
                operacion='generaroperacionsaldosglobales_pag';
            else if(this.operacion_normal==='cuentas_por_tercero')
                operacion='generaroperacioncuentasxtercero_pag';
            else if(this.operacion_normal==='cuentas_por_tercero_detallado')
                operacion='generaroperacioncuentasxtercerodetallado_pag';
            else if(this.operacion_normal==='movimiento_detallado_por_tercero')
                operacion='generaroperacionmovimientoxtercero_pag';
            let datos2= {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue(),
                ch_niif:this.getField('ch_niif').getValue()
            };
            if(!(this.operacion_normal==='movimiento_detallado_por_tercero')){
                datos2.nit_buscar=this.getField('nit_buscar').getValue();
                datos2.sede=this.getField('sede').getValue();
            } 
            if((this.operacion_normal==='cuentas_saldos_globales' || this.operacion_normal==='cuentas_por_tercero' || this.operacion_normal==='cuentas_por_tercero_detallado'
                || this.operacion_normal==='movimiento_detallado_por_tercero' || this.operacion_normal==='cuentas_con_terceros')&& this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                datos2.centro_costo_codigo=this.getField('centro_costo_codigo').getValue();
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-consultadecuentas","operacion":"'+operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreoTerceros(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], this.nombre_pdf+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                            this.limpiarCampos();
                            this.elegirTablaEliminar();
                        } 
                    });
                }else{
                    this.elegirTablaEliminar();
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
            }).catch(err => {
                this.elegirTablaEliminar();
                this.alertGeneral.toggle(true,err,'error')
            });
    }

    elegirTablaEliminar(){
        this.getField('procesar').setDisabled(false);
        if(this.operacion_normal==='cuentas_con_terceros')
            this.eliminarTemporalTerceros();
        else if(this.operacion_normal==='cuentas_saldos_globales')
            this.limpiarTemporal6();
        else if(this.operacion_normal==='cuentas_por_tercero')
            this.limpiarTemporal7();
        else if(this.operacion_normal==='cuentas_por_tercero_detallado')
            this.limpiarTemporal8();
        else if(this.operacion_normal==='movimiento_detallado_por_tercero')
            this.limpiarTemporal9();
    }

    generarExcelTabla3()
    {
            let url = this.constant.formConfiguration();
            let operacion='';
            if(this.operacion_normal==='cuentas_con_terceros')
                operacion='generaroperacionterceros_pag';
            else if(this.operacion_normal==='cuentas_saldos_globales')
                operacion='generaroperacionsaldosglobales_pag';
            else if(this.operacion_normal==='cuentas_por_tercero')
                operacion='generaroperacioncuentasxtercero_pag';
            else if(this.operacion_normal==='cuentas_por_tercero_detallado')
                operacion='generaroperacioncuentasxtercerodetallado_pag';
            else if(this.operacion_normal==='movimiento_detallado_por_tercero')
                operacion='generaroperacionmovimientoxtercero_pag';
            let datos2= {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue(),
                ch_niif:this.getField('ch_niif').getValue()
            };
            if(!(this.operacion_normal==='movimiento_detallado_por_tercero')){
                datos2.nit_buscar=this.getField('nit_buscar').getValue();
                datos2.sede=this.getField('sede').getValue();
            } 
            if((this.operacion_normal==='cuentas_saldos_globales' || this.operacion_normal==='cuentas_por_tercero' || this.operacion_normal==='cuentas_por_tercero_detallado'
            || this.operacion_normal==='movimiento_detallado_por_tercero' || this.operacion_normal==='cuentas_con_terceros')&& this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                datos2.centro_costo_codigo=this.getField('centro_costo_codigo').getValue();
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-consultadecuentas","operacion":"'+operacion+'","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                this.limpiarCampos();
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreoTerceros(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = this.nombre_excel+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                            this.limpiarCampos();
                            this.elegirTablaEliminar();
                        } 
                    });
                }else{
                    this.limpiarCampos();
                    this.elegirTablaEliminar();
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
            }).catch(err => {
                this.limpiarCampos();
                this.elegirTablaEliminar();
                this.alertGeneral.toggle(true,err,'error')
            });
    }

    eliminarTemporalTerceros(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_2', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successEliminarTemporalTerceros,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successEliminarTemporalTerceros(data){
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    limpiarCampos(){
        this.getField('radio_correo').setValue("N");
        this.getField('cuenta_contable').setValue('');
        this.getField('nombre_cuenta_contable').setValue('');
        this.getField('nit_buscar').setValue('');
        this.getField('sede').setValue('');
        this.getField('nombre').setValue('');
        this.getField('centro_costo_codigo').setValue('');
        this.getField('centro_costo_nombre').setValue('');
        this.getField('email').setValue('');
        this.getField('informe').setValue('');
        this.getField('select_impresora').setValue('');
        this.getField('solo_movimientos').setValue('');
        this.getField('otras_consultas_terceros').setValue('');
        this.getField('ch_excel').setValueSwitch(false);
        this.getField('ch_niif').setValueSwitch(false);
        this.getField('ch_pdf').setValueSwitch(false);
        this.getField('ch_pantalla').setValueSwitch(false);
        this.getField('procesar').setDisabled(false);
        this.getField('fecha_desde').setValue(this.fecha_hoy);
        this.getField('fecha_hasta').setValue(this.fecha_hoy);
        this.habilitarCorreo();

        this.limpiarErrores();
        this.cargarFechas();
    }

      limpiarErrores(){
        this.getField('radio_correo').setError(false,'');
        this.getField('cuenta_contable').setError(false,'');
        this.getField('nombre_cuenta_contable').setError(false,'');
        this.getField('nit_buscar').setError(false,'');
        this.getField('informe').setError(false,'');
        this.getField('sede').setError(false,'');
        this.getField('nombre').setError(false,'');
        this.getField('centro_costo_codigo').setError(false,'');
        this.getField('centro_costo_nombre').setError(false,'');
        this.getField('email').setError(false,'');
        this.getField('select_impresora').setError(false,'');
      }

      validarCamposCuentasSinTercero(){
        let errores =0;
        
        let valFechas=this.validarfechas();
        if(valFechas===false){
            errores++;
        };
        if(this.getField('cuenta_contable').valid()===false){
            errores++;
        }
        if(this.getField('nombre_cuenta_contable').valid()===false){
            errores++;
        }
        if(this.getField('radio_correo').getValue()==='S' || this.operacion_actual!=='pantalla'){
            if(this.getField('email').valid()===false){
                errores++;
            }
        }
        if(this.getField('centro_costo_codigo').getValue()!==''){
            if(this.getField('centro_costo_codigo').valid()===false 
            || this.getField('centro_costo_nombre').valid()===false){
                errores++;
            }
        }
        
        if(this.getField('ch_excel').getValue()==='false' && this.getField('ch_pdf').getValue()==='false' && this.getField('ch_pantalla').getValue()==='false'){
            errores++;
            this.getField('ch_excel').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pdf').setError(true,'* Es necesario seleccionar una opción.');
            this.getField('ch_pantalla').setError(true,'* Es necesario seleccionar una opción.');
            this.operacion_actual='';
            this.alertGeneral.toggle(true, 'Es necesario seleccionar una opción: Pdf,Excel o Pantalla.', 'error');
        }

        if (this.getField('maneja_movimiento').getValue()!=='S' && errores===0){        
            this.alertGeneral.toggle(true, 'Debe elegir una cuenta con maneja_movimiento = S', 'error');
            errores++;
        }
        if(errores===0){
            this.procesarCuentasSinTercero();
        }
    }

   procesarCuentasSinTercero(){
        this.mostrarModalInformativoEmail();
        if (this.getField("informe").getValue()==='Consulta cuenta sin tercero'){
            this.getField('centro_costo_codigo').setError(false, "");
            this.getField('procesar').setDisabled(true);
            let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'creartablatemporalcuentassinterceros', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.sucessCrearTablaTmpSinTerceros,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
            //radio_correo:this.getField('radio_correo').getValue(),
            //email:this.getField('email').getValue(),
        }else{
            this.alertGeneral.toggle(true, 'Informe no configurado para procesar.', 'error');
        }
    }

    sucessCrearTablaTmpSinTerceros(data){
        if (data.estado_p === 200){           
            if (this.getField('radio_correo').getValue()==='S'){
                if (this.getField('email').valid()){
                    let datos = {  
                        datos: { 
                            ch_niif:this.getField('ch_niif').getValue(),
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            codigo_cuenta:this.getField('cuenta_contable').getValue(),                                            
                            operacion_actual:this.operacion_actual,
                            radio_correo:this.getField('radio_correo').getValue(),
                            email:this.getField('email').getValue(),
                        
                        }
                    }
                    if (this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                        datos.datos.centro_de_costo=this.getField('centro_costo_codigo').getValue()
                    this.enviarCorreoCuentasSinTerceros(datos);

                }else{
                    this.getField('procesar').setDisabled(false);
                    this.getField('confirmModalCustom').toggle(false);
                    this.alertGeneral.toggle(true, 'Debe completar todos los campos', 'error');
                }
            }else{
                let datos = {  
                    datos: {                                     
                        ch_niif:this.getField('ch_niif').getValue(),
                        fecha_inicial:this.getField('fecha_desde').getValue(),
                        fecha_final:this.getField('fecha_hasta').getValue(),
                        codigo_cuenta:this.getField('cuenta_contable').getValue(),
                        operacion_actual:this.operacion_actual,
                        email:this.getField('email').getValue()
                    }
                }
                if (this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!=='')
                    datos.datos.centro_de_costo=this.getField('centro_costo_codigo').getValue() 
                if (this.operacion_actual==='pantalla'){
                    this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadconsultacuentassintercero', operacion_tipo: 'crear' };
                    this.service.send(
                    {
                        endpoint: this.constant.formConfiguration(),
                        method:'POST',
                        body: datos,
                        success: this.successcreartmpcontabilidadconsultacuentassintercero,
                        error: this.error_,
                        general: this.generalFormatPmv
                    });
                }else if (this.operacion_actual==='pdf'){
                    datos.datos.ch_niif=this.getField('ch_niif').getValue()
                    this.generarPdf(datos.datos);
                }else if (this.operacion_actual==='excel'){
                    datos.datos.ch_niif=this.getField('ch_niif').getValue()
                    this.generarExcel(datos.datos);
                }
            }
        }else{
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false); 
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Error al crear la tabla temporal.', 'error');
        }
    }

    generarExcel(datos){
        let url = this.constant.formConfiguration();
        const myJSON = JSON.stringify(datos);
        let datos2='{"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-consultadecuentas","operacion":"tmpcontabilidadconsultacuentassintercero","operacion_tipo":"crear"}}';
        fetch(url,{
           method:'POST', 
           body:datos2,
           headers:new Headers({
                 "Authorization": 'Bearer '+localStorage.getItem('token'), 
                 "Content-Type":'application/json'})})
        .then(response => {
         this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            this.limpiarCampos();
           return '';
        }   
        const reader = response.body.getReader();
        return new ReadableStream({
           start(controller) {
           return pump();
           function pump() {
                 return reader.read().then(({ done, value }) => {
                 if (done) {
                    controller.close();
                    return;}
                 controller.enqueue(value);
                 return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreoCuentasSinTerceros(JSON.parse(res));
                    }else{
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = url;
                        // the filename you want
                        a.download = 'cuentassinterceros.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        alert('Se descargo el archivo!');
                        this.limpiarCampos();
                        this.limpiarTemporal();
                    } 
                });
            }else{
                this.limpiarCampos();
                this.getField('procesar').setDisabled(false);
                this.limpiarTemporal();
                this.alertGeneral.toggle(true,'No hay datos','error');
            }
        }).catch(err => { 
            this.getField('procesar').setDisabled(false);
            this.limpiarTemporal();
            this.alertGeneral.toggle(true,err,'error')
        });
    }

    generarPdf(datos){
        let url = this.constant.formConfiguration();
        const myJSON = JSON.stringify(datos);
        let datos2='{"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-consultadecuentas","operacion":"tmpcontabilidadconsultacuentassintercero","operacion_tipo":"crear"}}';
        fetch(url,{
           method:'POST', 
           body:datos2,
           headers:new Headers({
                 "Authorization": 'Bearer '+localStorage.getItem('token'), 
                 "Content-Type":'application/json'})})
        .then(response => {
         this.getField('confirmModalCustom').toggle(false);
        if(response.status!==200){
            this.limpiarCampos();
           return '';
        }
        const reader = response.body.getReader();
        return new ReadableStream({
           start(controller) {
           return pump();
           function pump() {
                 return reader.read().then(({ done, value }) => {
                 if (done) {
                    controller.close();
                    return;}
                 controller.enqueue(value);
                 return pump();});}}})})
        .then(stream => new Response(stream))
        .then(response => response.blob())
        .then(blob => {
            if(blob.size>0){
                blob.text().then(res=>{
                    if(this.isJson(res)){
                        this.successEnviarCorreoCuentasSinTerceros(JSON.parse(res));
                    }else{
                        const pdf = new File([blob], 'cuentassinterceros.pdf', {
                            type:'application/pdf'});
                        window.open(URL.createObjectURL(pdf))
                        this.limpiarCampos();
                        this.limpiarTemporal();
                    } 
                });
            }else{
                this.limpiarCampos();
                this.getField('procesar').setDisabled(false);
                this.limpiarTemporal();
                this.alertGeneral.toggle(true,'No hay datos','error');
            }
        }).catch(err => { 
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.limpiarTemporal();
            this.alertGeneral.toggle(true,err,'error')
        });
    }

    soloMovimientosdetalladoterceronormal(){
        if (this.getField("solo_movimientos").valid()&&this.getField("solo_movimientos").getValue()==='Movimiento detallado por tercero normal') {
            this.mostrarModalInformativoEmail();
            this.getField('procesar').setDisabled(true); 
            
            let datos = {  datos: { }}
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'creartemporal', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv
            });
        }else if (this.getField("solo_movimientos").valid()&&this.getField("solo_movimientos").getValue()==='Movimiento detallado acumulado por tercero') {
            this.getField('modal_movimiento_acumuladox_tercero').handleClickOpen();
            if (this.getField('cuenta_contable').getValue() !== '') {
                this.getField('cuenta_contable1').setValue(this.getField('cuenta_contable').getValue());
                this.getField('cuenta_contable2').setValue(this.getField('cuenta_contable').getValue());
            }
            this.getField('cuenta_contable1').setVisible(true);
            this.getField('nombre_cuenta_contable1').setVisible(true);
            this.getField('cuenta_contable2').setVisible(true);
            this.getField('nombre_cuenta_contable2').setVisible(true);
            this.getField('aceptar').setVisible(true);  
        }else if (this.getField("solo_movimientos").valid()&&this.getField("solo_movimientos").getValue()==='Movimiento detallado acumulado por tercero sin saldo anterior') {
            this.getField('modal_movimiento_acumuladox_tercero').handleClickOpen();
            if (this.getField('cuenta_contable').getValue() !== '') {
                this.getField('cuenta_contable1').setValue(this.getField('cuenta_contable').getValue());
                this.getField('cuenta_contable2').setValue(this.getField('cuenta_contable').getValue());
            }
            this.getField('cuenta_contable1').setVisible(true);
            this.getField('nombre_cuenta_contable1').setVisible(true);
            this.getField('cuenta_contable2').setVisible(true);
            this.getField('nombre_cuenta_contable2').setVisible(true);
            this.getField('aceptar').setVisible(true);  
        }else if (this.getField("solo_movimientos").valid()&&this.getField("solo_movimientos").getValue()==='Movimiento detallado acumulado por sucursal') {
            this.getField('modal_movimiento_acumuladox_tercero').handleClickOpen();
            if (this.getField('cuenta_contable').getValue() !== '') {
                this.getField('cuenta_contable1').setValue(this.getField('cuenta_contable').getValue());
                this.getField('cuenta_contable2').setValue(this.getField('cuenta_contable').getValue());
            }
            this.getField('cuenta_contable1').setVisible(true);
            this.getField('nombre_cuenta_contable1').setVisible(true);
            this.getField('cuenta_contable2').setVisible(true);
            this.getField('nombre_cuenta_contable2').setVisible(true);
            this.getField('aceptar').setVisible(true);  
        }else if (this.getField("solo_movimientos").valid()&&this.getField("solo_movimientos").getValue()==='Movimiento detallado por centro de costo') {
            this.getField('modal_movimiento_acumuladox_tercero').handleClickOpen();
            if (this.getField('cuenta_contable').getValue() !== '') {
                this.getField('cuenta_contable1').setValue(this.getField('cuenta_contable').getValue());
                this.getField('cuenta_contable2').setValue(this.getField('cuenta_contable').getValue());
            }
            this.getField('cuenta_contable1').setVisible(true);
            this.getField('nombre_cuenta_contable1').setVisible(true);
            this.getField('cuenta_contable2').setVisible(true);
            this.getField('nombre_cuenta_contable2').setVisible(true);
            this.getField('aceptar').setVisible(true);  
        }else{
            this.alertGeneral.toggle(true, 'Solo movimientos no configurado para procesar.', 'error');
        }  
    }

    successTraerDatos(data){
        if (data.estado_p === 200){
            let datos = {}
                if (this.getField('ch_niif').getValue() === 'true') {
                   datos = {  
                        datos: { 
                            fecha_desde:this.getField('fecha_desde').getValue(),
                            fecha_hasta:this.getField('fecha_hasta').getValue(),
                            cuenta_contable:this.getField('cuenta_contable').getValue(),
                            centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                            niif:'niif',
                            operacion:this.operacion_actual,
                            radio_correo:this.getField('radio_correo').getValue()
                        }
                    }
                }else{
                   datos = {  
                        datos: { 
                            fecha_desde:this.getField('fecha_desde').getValue(),
                            fecha_hasta:this.getField('fecha_hasta').getValue(),
                            cuenta_contable:this.getField('cuenta_contable').getValue(),
                            centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                            operacion:this.operacion_actual,
                            radio_correo:this.getField('radio_correo').getValue()
                        }
                    }
                }
                this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadconsultacuentassolomovimiento', operacion_tipo: 'consulta' };
                this.service.send(
                {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTmp1,
                    error: this.error_,
                    general: this.generalFormatPmv
                });
        }else{
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Verifique su información, error al procesar.', 'error');
        }
    }


    generarModalSoloMovimiento(){
        
            let datos={ datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                operacion_movimiento:this.operacion_movimiento,
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue()
            }};
        if(this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!==''
             && (this.operacion_movimiento!=='acumulado_sucursal' && this.operacion_movimiento!=='centro_costo'))
            datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
            if(this.operacion_movimiento === 'tercero_normal' || this.operacion_movimiento ==='tercero_acumulado'){
                this.getField("rejilla_solo_movimiento").setTipoServicioOperacion("cont-consultadecuentas-generaroperacionsolomovimientos");
                this.getField("rejilla_solo_movimiento").sendRequest(this.successGenerarModalSoloMovimiento, datos);
            }else if(this.operacion_movimiento==='tercero_acumulado_sin_saldo' || this.operacion_movimiento==='acumulado_sucursal'){
                if(this.operacion_movimiento==='acumulado_sucursal')
                    datos.datos.sucursal_ingreso=this.codigo_sucursal;
                this.getField("rejilla_solo_movimiento").setTipoServicioOperacion("cont-consultadecuentas-generaroperacionsolomovimientos");
                this.getField("rejilla_solo_movimiento").sendRequest(this.successGenerarModalSoloMovimiento, datos);
            }else if(this.operacion_movimiento==='centro_costo'){
                this.getField("rejilla_solo_movimiento_centro_costo").setTipoServicioOperacion("cont-consultadecuentas-generaroperacionsolomovimientos");
                this.getField("rejilla_solo_movimiento_centro_costo").sendRequest(this.successGenerarModalSoloMovimiento, datos);
            }
    }

    successGenerarModalSoloMovimiento(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            //llenar lista
            //nombre,operacion,fecha_actual,desde-hasta,sucursales,usuario
            let datosFicha = [];
            let data2 = 
                    {
                        "nombre": data.data.lista.nombre_empresa,
                        "operacion": data.data.lista.operacion,
                        "fecha_actual": data.data.lista.fecha_actual,
                        "desde-hasta": data.data.lista.fechasdesdehasta,
                        "codigo_cuenta": data.data.lista.codigo_cuenta,
                        "codigo_centro_costo": data.data.lista.centro_costo_codigo===null?'Todos':data.data.lista.centro_costo_codigo,
                        "codigo_usuario": data.data.lista.codigo_usuario,
                    };
            if(this.operacion_movimiento==='acumulado_sucursal'){
                data2.sucursal=data.data.lista.sucursal;
                delete data2.codigo_centro_costo;
            }
            datosFicha.push(data2);
            this.getField('lista_datos_solo_movimiento').setItemsFichas(datosFicha);
            this.getField('total_debitos_solo_movimiento').setValue(data.data.lista.total_debitos.toFixed(2));
            this.getField('total_creditos_solo_movimiento').setValue(data.data.lista.total_creditos.toFixed(2));
            this.getField('total_debitos_solo_movimiento').setVisible(true);
            this.getField('total_creditos_solo_movimiento').setVisible(true);
            let configCell = new Map();
            if(this.operacion_movimiento!=='centro_costo'){
                this.getField('rejilla_solo_movimiento').toggle(true);
                this.getField('rejilla_solo_movimiento_centro_costo').toggle(false);
                configCell.set('base',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterbase});
                configCell.set('tarifa',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattertarifa});
            }else{
                this.getField('rejilla_solo_movimiento').toggle(false);
                this.getField('rejilla_solo_movimiento_centro_costo').toggle(true);
            }
            
            configCell.set('debitos',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterdebito,field: 'débitos'});
            configCell.set('creditos',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercredito,field: 'créditos'});

            this.gridOptionsComponentes['rowData'] = data.data.datos;
            if(this.operacion_movimiento!=='centro_costo')
                this.getField('rejilla_solo_movimiento').initData(this.gridOptionsComponentes, configCell);
            else
                this.getField('rejilla_solo_movimiento_centro_costo').initData(this.gridOptionsComponentes, configCell);
            this.getField('modal_solo_movimiento').handleClickOpen();
            this.limpiarCampos();
        }else if (data.estado_p === 404){
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.eliminarTabla();
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else{
            this.limpiarCampos();
            this.eliminarTabla();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
    }


    generarPdfTablaSoloMovimiento()
    {
            let url = this.constant.formConfiguration();
            let datos2= {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                operacion_movimiento:this.operacion_movimiento,
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue(),
                ch_niif:this.getField('ch_niif').getValue()
            };

            if(this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!==''
             && (this.operacion_movimiento!=='acumulado_sucursal' && this.operacion_movimiento!=='centro_costo'))
                datos2.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
            if(this.operacion_movimiento==='acumulado_sucursal')
                    datos2.sucursal_ingreso=this.codigo_sucursal;
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-consultadecuentas","operacion":"generaroperacionsolomovimientos_pag","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                this.limpiarCampos();
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreoSoloMovimiento(JSON.parse(res));
                        }else{
                            const pdf = new File([blob], 'solo_movimiento_'+this.operacion_movimiento+'.pdf', {
                                type:'application/pdf'});
                            window.open(URL.createObjectURL(pdf));
                            this.limpiarCampos();
                            this.elegirTablaEliminarMovimientos();
                        } 
                    });
                }else{
                    this.limpiarCampos();
                    this.elegirTablaEliminarMovimientos();
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
            }).catch(err => { 
                this.limpiarCampos();
                this.elegirTablaEliminarMovimientos();
                this.alertGeneral.toggle(true,err,'error')
            });
    }

    elegirTablaEliminarMovimientos(){
        this.getField('procesar').setDisabled(false);
        if(this.operacion_movimiento==='tercero_acumulado')
            this.limpiarTemporal2();
        else if(this.operacion_movimiento==='tercero_normal')
            this.limpiarTemporal1();
        else if(this.operacion_movimiento==='tercero_acumulado_sin_saldo')
            this.limpiarTemporal3();
        else if(this.operacion_movimiento==='acumulado_sucursal')
            this.limpiarTemporal4();
        else if(this.operacion_movimiento==='centro_costo')
            this.limpiarTemporal5();
    }

    generarExcelSoloMovimiento()
    {
            let url = this.constant.formConfiguration();
            let datos2= {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                operacion_movimiento:this.operacion_movimiento,
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue(),
                ch_niif:this.getField('ch_niif').getValue()
            };
            if(this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!==''
             && (this.operacion_movimiento!=='acumulado_sucursal' && this.operacion_movimiento!=='centro_costo'))
                datos2.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
            if(this.operacion_movimiento==='acumulado_sucursal')
                datos2.sucursal_ingreso=this.codigo_sucursal;
            const myJSON = JSON.stringify(datos2);
            let datos='?body={"datos":'+myJSON+',"generales":{"tipo_servicio":"cont-consultadecuentas","operacion":"generaroperacionsolomovimientos_pag","operacion_tipo":"consulta"}}';
            fetch(url+datos,{
            method:'GET', 
            headers:new Headers({
                    "Authorization": 'Bearer '+localStorage.getItem('token'), 
                    "Content-Type":'application/json'})})
            .then(response => {
            this.getField('confirmModalCustom').toggle(false);
            if(response.status!==200){
                this.limpiarCampos();
            return '';
            }
            const reader = response.body.getReader();
            return new ReadableStream({
            start(controller) {
            return pump();
            function pump() {
                    return reader.read().then(({ done, value }) => {
                    if (done) {
                        controller.close();
                        return;}
                    controller.enqueue(value);
                    return pump();});}}})})
            .then(stream => new Response(stream))
            .then(response => response.blob())
            .then(blob => {
                if(blob.size>0){
                    blob.text().then(res=>{
                        if(this.isJson(res)){
                            this.successEnviarCorreoSoloMovimiento(JSON.parse(res));
                        }else{
                            const url = window.URL.createObjectURL(blob);
                            const a = document.createElement('a');
                            a.style.display = 'none';
                            a.href = url;
                            // the filename you want
                            a.download = 'solo_movimiento_'+this.operacion_movimiento+'.xlsx';
                            document.body.appendChild(a);
                            a.click();
                            window.URL.revokeObjectURL(url);
                            alert('Se descargo el archivo!');
                            this.limpiarCampos();
                            this.elegirTablaEliminarMovimientos();
                        } 
                    });
                }else{
                    this.limpiarCampos();
                    this.elegirTablaEliminarMovimientos();
                    this.alertGeneral.toggle(true,'No hay datos','error');
                }
            }).catch(err => { 
                this.limpiarCampos();
                this.elegirTablaEliminarMovimientos();
                this.alertGeneral.toggle(true,err,'error')
            });
    }

    enviarCorreoCuentasSinTerceros(datos){
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadconsultacuentassintercero', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successEnviarCorreoCuentasSinTerceros,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage:false
          });
    }
    
    successEnviarCorreoCuentasSinTerceros(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.limpiarCampos();
            this.mostrarMensajeEmailEnviado();
        }else{
            this.limpiarCampos();
            this.mostrarMensajeEmailFallo();
            this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        if(this.operacion_movimiento==='tercero_acumulado')
            this.limpiarTemporal();
    }

    enviarCorreoSoloMovimiento(){
        let datos={ datos: {
            fecha_desde:this.getField('fecha_desde').getValue(),
            fecha_hasta:this.getField('fecha_hasta').getValue(),
            cuenta_contable:this.getField('cuenta_contable').getValue(),
            //centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
            operacion:this.operacion_actual,
            operacion_movimiento: this.operacion_movimiento,
            radio_correo:this.getField('radio_correo').getValue(),
            email:this.getField('email').getValue(),
        }};
        if(this.getField('centro_costo_codigo').getValue()!=='' && this.getField('centro_costo_nombre').getValue()!==''
             && (this.operacion_movimiento!=='acumulado_sucursal' && this.operacion_movimiento!=='centro_costo'))
            datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
        if(this.operacion_movimiento==='acumulado_sucursal')
            datos.datos.sucursal_ingreso=this.codigo_sucursal;

        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'generaroperacionsolomovimientos_pag', operacion_tipo: 'consulta' };
          this.service.send(
          {
                  endpoint: this.constant.formConfiguration(),
                  method:'GET',
                  body: datos,
                  success: this.successEnviarCorreoSoloMovimiento,
                  error: this.error_,
                  general: this.generalFormatPmv,
                  showMessage: false
          });
    }
    
    successEnviarCorreoSoloMovimiento(data){
        this.getField('confirmModalCustom').toggle(false);
        if(data.estado_p === 200){ 
            this.limpiarCampos();
            this.mostrarMensajeEmailEnviado();
        }else{
            this.limpiarCampos();
            this.mostrarMensajeEmailFallo();
            this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        this.elegirTablaEliminarMovimientos();
    }

    successTraerDatosAcumulado(data){
        if (data.estado_p === 200){
            this.consultar()
        }else{
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Verifique su información, error al procesar.', 'error');
        }
    }

    creartablaacumuladoxtercero(){
        this.getField('procesar').setDisabled(true); 
        this.mostrarModalInformativoEmail(); 
        let datos = {  datos: { }}
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'creartemporalacumuladoportercero', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successTraerDatosAcumulado,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    creartablaacumuladoxtercerosinsaldo(){
        this.getField('procesar').setDisabled(true); 
        this.mostrarModalInformativoEmail();
        let datos = {  datos: { }}
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'creartemporalacumuladoxtercerosinsaldo', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successTraerDatosAcumulado,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    creartablaacumuladoxsucursal(){
        this.getField('procesar').setDisabled(true); 
        this.mostrarModalInformativoEmail();  
        let datos = {  datos: { }}
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'creartemporalacumuladoxsucursal', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successTraerDatosAcumulado,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    creartabladetalladocentrocosto(){
        this.getField('procesar').setDisabled(true); 
        this.mostrarModalInformativoEmail();
        let datos = {  datos: { }}
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'creartemporaldetalladocentrocosto', operacion_tipo: 'crear' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'POST',
            body: datos,
            success: this.successTraerDatosAcumulado,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    consultar(){
        this.getField('modal_movimiento_acumuladox_tercero').handleClose();
        if(this.operacion_movimiento==='tercero_acumulado'){
            let datos = {}
            if (this.getField('ch_niif').getValue() === 'true') {
               datos = {  
                    datos: { 
                        fecha_inicial:this.getField('fecha_desde').getValue(),
                        fecha_final:this.getField('fecha_hasta').getValue(),
                        centro_de_costo:this.getField('centro_costo_codigo').getValue(),
                        niif:'niif',
                        cuenta1:this.cuenta1,
                        cuenta2:this.cuenta2
                    }
                }
            }else{
               datos = {  
                    datos: { 
                        fecha_inicial:this.getField('fecha_desde').getValue(),
                        fecha_final:this.getField('fecha_hasta').getValue(),
                        centro_de_costo:this.getField('centro_costo_codigo').getValue(),
                        cuenta1:this.cuenta1,
                        cuenta2:this.cuenta2
                    }
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadconsultaacumuladoxtercero', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                //success: this.successconsultarmovimientoacumuladoxtercero,
                success: this.successTraerTmp2,
                error: this.error_,
                general: this.generalFormatPmv
            });
            this.alertGeneral.toggle(true, 'Se creo la tabla temporal.', 'success');
        }else if(this.operacion_movimiento==='tercero_acumulado_sin_saldo'){
            let datos = {}
            if (this.getField('centro_costo_codigo').getValue() !== '') {
                if (this.getField('ch_niif').getValue() === 'true') {
                   datos = {  
                        datos: { 
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            centro_de_costo:this.getField('centro_costo_codigo').getValue(),
                            niif:'niif',
                            cuenta1:this.cuenta1,
                            cuenta2:this.cuenta2
                        }
                    }
                }else{
                   datos = {  
                        datos: { 
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            centro_de_costo:this.getField('centro_costo_codigo').getValue(),
                            cuenta1:this.cuenta1,
                            cuenta2:this.cuenta2
                        }
                    }
                }  
            }else{
                if (this.getField('ch_niif').getValue() === 'true') {
                    datos = {  
                         datos: { 
                             fecha_inicial:this.getField('fecha_desde').getValue(),
                             fecha_final:this.getField('fecha_hasta').getValue(),
                             niif:'niif',
                             cuenta1:this.cuenta1,
                             cuenta2:this.cuenta2
                         }
                     }
                 }else{
                    datos = {  
                         datos: { 
                             fecha_inicial:this.getField('fecha_desde').getValue(),
                             fecha_final:this.getField('fecha_hasta').getValue(),
                             cuenta1:this.cuenta1,
                             cuenta2:this.cuenta2
                         }
                     }
                 }  
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadconsultaacumuladoxtercerosinsaldo', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                //success: this.successconsultarmovimientoacumuladoxtercerosinsaldo,
                success: this.successTraerTmp3,
                error: this.error_,
                general: this.generalFormatPmv
            });
            this.alertGeneral.toggle(true, 'Se creo la tabla temporal.', 'success');
        }else if (this.operacion_movimiento==='acumulado_sucursal'){
            let datos = {}
                if (this.getField('ch_niif').getValue() === 'true') {
                   datos = {  
                        datos: { 
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            niif:'niif',
                            cuenta1:this.cuenta1,
                            cuenta2:this.cuenta2,
                            sucursal_ingreso:this.codigo_sucursal
                        }
                    }
                }else{
                   datos = {  
                        datos: { 
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            cuenta1:this.cuenta1,
                            cuenta2:this.cuenta2,
                            sucursal_ingreso:this.codigo_sucursal
                        }
                    }
                }  
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadconsultaacumuladoxsucursal', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                //success: this.successconsultarmovimientoacumuladoxsucursal,
                success: this.successTraerTmp4,
                error: this.error_,
                general: this.generalFormatPmv
            });
            this.alertGeneral.toggle(true, 'Se creo la tabla temporal.', 'success');
        }else if (this.operacion_movimiento==='centro_costo'){
            let datos = {}
                if (this.getField('ch_niif').getValue() === 'true') {
                   datos = {  
                        datos: { 
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            niif:'niif',
                            cuenta1:this.cuenta1,
                            cuenta2:this.cuenta2,
                            sucursal_ingreso:this.codigo_sucursal
                        }
                    }
                }else{
                   datos = {  
                        datos: { 
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            cuenta1:this.cuenta1,
                            cuenta2:this.cuenta2,
                            sucursal_ingreso:this.codigo_sucursal
                        }
                    }
                }  
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadconsultadetalladocentrocosto', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                //success: this.successconsultarmovimientodetalladocentrocosto,
                success: this.successTraerTmp5,
                error: this.error_,
                general: this.generalFormatPmv
            });
            this.alertGeneral.toggle(true, 'Se creo la tabla temporal.', 'success');
        }else{
            this.alertGeneral.toggle(true, 'Sin configurar.', 'success');
            this.getField('confirmModalCustom').toggle(false);
        }
    }

    successconsultarmovimientoacumuladoxtercero(data){
        if (data.estado_p === 200){
            let datos={ datos: {}};
            this.getField("rejilla_solo_movimiento").setTipoServicioOperacion("cont-consultadecuentas-1_2");
            this.getField("rejilla_solo_movimiento").sendRequest(this.successTraerTmp2, datos);
            /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '1_2', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTmp2,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
        }else{
            this.limpiarCampos();
            this.limpiarTemporal2();
            this.getField('confirmModalCustom').toggle(false); 
            this.alertGeneral.toggle(true, 'Verifique su información, error al procesar.', 'error');

        }
    }

    successconsultarmovimientoacumuladoxtercerosinsaldo(data){
        if (data.estado_p === 200){
            let datos={ datos: {}};
            this.getField("rejilla_solo_movimiento").setTipoServicioOperacion("cont-consultadecuentas-1_3");
            this.getField("rejilla_solo_movimiento").sendRequest(this.successTraerTmp3, datos);
            /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '1_3', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTmp3,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
        }else{
            this.limpiarCampos();
            this.limpiarTemporal3();
            this.getField('confirmModalCustom').toggle(false); 
            this.alertGeneral.toggle(true, 'Verifique su información, error al procesar.', 'error');

        }
    }

    successconsultarmovimientoacumuladoxsucursal(data){
        if (data.estado_p === 200){
            let datos={ datos: {}};
            this.getField("rejilla_solo_movimiento").setTipoServicioOperacion("cont-consultadecuentas-1_4");
            this.getField("rejilla_solo_movimiento").sendRequest(this.successTraerTmp4, datos);
            /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '1_4', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTmp4,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
        }else{
            this.limpiarCampos();
            this.limpiarTemporal4();
            this.getField('confirmModalCustom').toggle(false); 
            this.alertGeneral.toggle(true, 'Verifique su información, error al procesar.', 'error');

        }
    }

    successconsultarmovimientodetalladocentrocosto(data){
        if (data.estado_p === 200){
            let datos={ datos: {}};
            this.getField("rejilla_solo_movimiento").setTipoServicioOperacion("cont-consultadecuentas-1_5");
            this.getField("rejilla_solo_movimiento").sendRequest(this.successTraerTmp5, datos);
            /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '1_5', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTmp5,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
        }else{
            this.limpiarCampos();
            this.limpiarTemporal5();
            this.getField('confirmModalCustom').toggle(false); 
            this.alertGeneral.toggle(true, 'Verifique su información, error al procesar.', 'error');

        }
    }

    successTraerTmp3(data){
        if(data.estado_p === 200){
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreoSoloMovimiento();
            }else{
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTablaSoloMovimiento();
                }else if(this.operacion_actual==='pantalla'){
                    this.generarModalSoloMovimiento();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelSoloMovimiento();
                } 
            }
        }else if (data.estado_p === 404){
            this.limpiarCampos();
            this.getField('confirmModalCustom').toggle(false);
            this.getField('procesar').setDisabled(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporal3();
        }else{
            this.getField('confirmModalCustom').toggle(false);
            this.limpiarCampos();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.getField('procesar').setDisabled(false);
            this.limpiarTemporal3();
        }
    }

    successTraerTmp4(data){
        if(data.estado_p === 200){
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreoSoloMovimiento();
            }else{
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTablaSoloMovimiento();
                }else if(this.operacion_actual==='pantalla'){
                    this.generarModalSoloMovimiento();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelSoloMovimiento();
                } 
            }
        } else if (data.estado_p === 404){
            this.limpiarCampos();
            this.limpiarTemporal4();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else{
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal4();
        }

    }

    successTraerTmp5(data){
        if(data.estado_p === 200){
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreoSoloMovimiento();
            }else{
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTablaSoloMovimiento();
                }else if(this.operacion_actual==='pantalla'){
                    this.generarModalSoloMovimiento();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelSoloMovimiento();
                } 
            }
        } else if (data.estado_p === 404){
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarCampos();
            this.limpiarTemporal5();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
        }else{
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            this.limpiarCampos();
            this.limpiarTemporal5();
        }

    }

    aceptar(){
        if(this.getField('nombre_cuenta_contable1').valid() && this.getField('nombre_cuenta_contable2').valid()){
            this.cuenta1 = this.getField('cuenta_contable1').getValue();
            this.cuenta2 = this.getField('cuenta_contable2').getValue();
            if(this.cuenta1===this.cuenta2)
                this.getField('cuenta_contable').setValue(this.cuenta1);
            else{
                this.getField('cuenta_contable').setValue(this.cuenta1+','+this.cuenta2);
            }
            if(this.operacion_movimiento==='tercero_acumulado'){
                this.creartablaacumuladoxtercero();
            }
            if(this.operacion_movimiento==='tercero_acumulado_sin_saldo'){
                this.creartablaacumuladoxtercerosinsaldo();
            }if(this.operacion_movimiento==='acumulado_sucursal'){
                this.creartablaacumuladoxsucursal();
            }if(this.operacion_movimiento==='centro_costo'){
                this.creartabladetalladocentrocosto();
            }
        }
    }

    crearTablaSaldosGlobales(){
        this.getField('procesar').setDisabled(true);
        this.mostrarModalInformativoEmail();
        let datos={};
            if (this.getField('centro_costo_codigo').getValue() !== '') {
                if (this.getField('ch_niif').getValue() === 'true') {
                    datos = {  
                         datos: { 
                             fecha_inicial:this.getField('fecha_desde').getValue(),
                             fecha_final:this.getField('fecha_hasta').getValue(),
                             cuenta_contable:Number(this.getField('cuenta_contable').getValue()),
                             niif:'niif',
                             centro_costo:this.getField('centro_costo_codigo').getValue()
                         }
                     }
                }else{
                    datos = {  
                         datos: { 
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            cuenta_contable:Number(this.getField('cuenta_contable').getValue()),
                            centro_costo:this.getField('centro_costo_codigo').getValue()
                        }
                    }
                }
            }else{
                if (this.getField('ch_niif').getValue() === 'true') {
                    datos = {  
                         datos: { 
                             fecha_inicial:this.getField('fecha_desde').getValue(),
                             fecha_final:this.getField('fecha_hasta').getValue(),
                             cuenta_contable:Number(this.getField('cuenta_contable').getValue()),
                             niif:'niif',
                         }
                     }
                }else{
                    datos = {  
                         datos: { 
                            fecha_inicial:this.getField('fecha_desde').getValue(),
                            fecha_final:this.getField('fecha_hasta').getValue(),
                            cuenta_contable:Number(this.getField('cuenta_contable').getValue()),
                        }
                    }
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadsaldosglobales', operacion_tipo: 'crear' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'POST',
                    body: datos,
                    success: this.successTraerTmpSaldosGlobales,
                    error: this.error_,
                    general: this.generalFormatPmv,
                    showMessage: false
            });
    }

    sucessCrearTablaSaldosGlobales(data){
        if (data.estado_p === 200 )
        {
            let datos={ datos: {}};
            this.getField("tabla_saldosglobales").setTipoServicioOperacion("cont-consultadecuentas-1_6");
            this.getField("tabla_saldosglobales").sendRequest(this.successTraerTmpSaldosGlobales, datos);
            /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '1_6', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successTraerTmpSaldosGlobales,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
        }
        else 
        {
            this.limpiarCampos();
            this.getField('confirmModalCustom').toggle(false);
            this.getField('procesar').setDisabled(false);
            let keys=data.data.mensaje;
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys, 'error');
            this.limpiarTemporal6();
        };
        
    }

    successTraerTmpSaldosGlobales(data){
        if (data.estado_p === 200){
            this.operacion_normal='cuentas_saldos_globales';
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreoTerceros();
            }else{
                this.nombre_pdf='cuentassaldosglobales';
                this.nombre_excel='cuentassaldosglobales';
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTabla3();
                }else if(this.operacion_actual==='pantalla'){
                    this.generarModalSaldosGlobales();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelTabla3();
                } 
            }
        }
        else if (data.estado_p === 404){
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporal6();
        }else{
            this.limpiarCampos();
            let respuesta=Object.values(data.data.errores);
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal6();
        }
    }

    generarModalSaldosGlobales(){
        let datos = { datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                cuenta_contable:this.getField('cuenta_contable').getValue(),
                operacion:this.operacion_actual,
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue()
        }};
        if(this.getField('centro_costo_codigo').getValue()!=='')
            datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
            this.getField("tabla_saldosglobales").setTipoServicioOperacion("cont-consultadecuentas-generaroperacionsaldosglobales");
            this.getField("tabla_saldosglobales").sendRequest(this.successGenerarSaldosGlobales, datos);
        /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'generaroperacionsaldosglobales', operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGenerarSaldosGlobales,
                error: this.error_,
                general: this.generalFormatPmv
        });*/
    }

    successGenerarSaldosGlobales(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('procesar').setDisabled(false);
        if(data.estado_p === 200){ 
            //llenar lista
            let datosFicha = [];
            let data2 = 
                    {
                        "nombre": data.data.lista.nombre_empresa,
                        "operacion": data.data.lista.operacion,
                        "fecha_actual": data.data.lista.fecha_actual,
                        "desde-hasta": data.data.lista.fechasdesdehasta,
                        "codigo_cuenta": data.data.lista.codigo_cuenta,
                        "usuario": data.data.lista.usuario,
                        "codigo_centro_costo": data.data.lista.centro_costo_codigo === null?'Todos':data.data.lista.centro_costo_codigo,
                    };
            datosFicha.push(data2);
            this.getField('lista_datos_globales').setItemsFichas(datosFicha);
            this.getField('total_contabilidad').setValue(data.data.lista.total_contabilidad.toFixed(2));
            this.getField('total_contabilidad').setVisible(true);
            this.getField('tabla_saldosglobales').toggle(true);
            let configCell = new Map();
            configCell.set('contabilidad',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercontabilidad});

            this.gridOptionsComponentes['rowData'] = data.data.datos;
            this.getField('tabla_saldosglobales').initData(this.gridOptionsComponentes, configCell);
            this.getField('modal_saldosglobales').handleClickOpen();
            this.limpiarCampos();
        }else if (data.estado_p === 404){
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.limpiarTemporal6();
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
        }else{
            this.limpiarCampos();
            this.limpiarTemporal6();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
        }
        
    }

    limpiarTemporal6(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_7', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp6,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp6(data){
        //tmp_saldoglobal_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    mostrar1(){
        let errores =0;
        if(!this.getField("otras_consultas_terceros").valid()){
            errores++;
        }else{
            let opcion_consultas = this.getField("otras_consultas_terceros").getValue();
            if(opcion_consultas==='Movimiento detallado por tercero'){
                errores =this.validarCamposNormal();
            }else if(opcion_consultas==='Cuentas por tercero'){
                errores=this.validarOtrascuentasxtercero();
            }else if(opcion_consultas==='Cuentas por tercero detallado'){
                errores=this.validarOtrascuentasxtercero();
            }else{
                errores++;
                this.alertGeneral.toggle(true, 'Opcion otras consultas sin configurar.', 'error');
            }
        }
        if(errores===0){
            this.otrasconsultasxtercero()
        }
    }

    otrasconsultasxtercero(){
        this.mostrarModalInformativoEmail();
        this.getField('procesar').setDisabled(true);
        if (this.getField("otras_consultas_terceros").valid() && this.getField("otras_consultas_terceros").getValue()==='Movimiento detallado por tercero') {
            let datos = {}
            if (this.getField('ch_niif').getValue() === 'true') {
                datos = {  
                     datos: { 
                        fecha_desde:this.getField('fecha_desde').getValue(),
                        fecha_hasta:this.getField('fecha_hasta').getValue(),
                        centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                        niif:'niif',
                        cuenta_contable:this.getField('cuenta_contable').getValue()
                    }
                }
            }else{
                datos = {  
                     datos: { 
                        fecha_desde:this.getField('fecha_desde').getValue(),
                        fecha_hasta:this.getField('fecha_hasta').getValue(),
                        centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                        cuenta_contable:this.getField('cuenta_contable').getValue(),
                    }
                }
            }
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadmovimientoxtercero', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                success: this.successDatosmovimientoxtercero,
                error: this.error_,
                general: this.generalFormatPmv
            });      
        }else if (this.getField("otras_consultas_terceros").valid() && this.getField("otras_consultas_terceros").getValue()==='Cuentas por tercero') {
            let datos = {}
                if (this.getField('centro_costo_codigo').getValue() !== '') {
                    if (this.getField('ch_niif').getValue() === 'true') {
                       datos = {  
                            datos: { 
                                fecha_desde:this.getField('fecha_desde').getValue(),
                                fecha_hasta:this.getField('fecha_hasta').getValue(),
                                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                                niif:'niif',
                                nit_buscar:this.getField('nit_buscar').getValue(),
                                sede:this.getField('sede').getValue()
                            }
                        }
                    }else{
                       datos = {  
                            datos: { 
                                fecha_desde:this.getField('fecha_desde').getValue(),
                                fecha_hasta:this.getField('fecha_hasta').getValue(),
                                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                                nit_buscar:this.getField('nit_buscar').getValue(),
                                sede:this.getField('sede').getValue()
                            }
                        }
                    }
                }else{
                    if (this.getField('ch_niif').getValue() === 'true') {
                        datos = {  
                             datos: { 
                                 fecha_desde:this.getField('fecha_desde').getValue(),
                                 fecha_hasta:this.getField('fecha_hasta').getValue(),
                                 niif:'niif',
                                 nit_buscar:this.getField('nit_buscar').getValue(),
                                 sede:this.getField('sede').getValue()
                             }
                         }
                    }else{
                        datos = {  
                             datos: { 
                                 fecha_desde:this.getField('fecha_desde').getValue(),
                                 fecha_hasta:this.getField('fecha_hasta').getValue(),
                                 nit_buscar:this.getField('nit_buscar').getValue(),
                                 sede:this.getField('sede').getValue()
                             }
                         }
                    }
                }
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadcuentasxtercero', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                //success: this.successTraerDatoscuentaxtercero,
                success: this.successDatoscuentaxtercero,
                error: this.error_,
                general: this.generalFormatPmv
            });        
        }else if (this.getField("otras_consultas_terceros").valid() && this.getField("otras_consultas_terceros").getValue()==='Cuentas por tercero detallado') {
            let datos = {}
                if (this.getField('centro_costo_codigo').getValue() !== '') {
                    if (this.getField('ch_niif').getValue() === 'true') {
                       datos = {  
                            datos: { 
                                fecha_desde:this.getField('fecha_desde').getValue(),
                                fecha_hasta:this.getField('fecha_hasta').getValue(),
                                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                                niif:'niif',
                                nit_buscar:this.getField('nit_buscar').getValue(),
                                sede:this.getField('sede').getValue()
                            }
                        }
                    }else{
                       datos = {  
                            datos: { 
                                fecha_desde:this.getField('fecha_desde').getValue(),
                                fecha_hasta:this.getField('fecha_hasta').getValue(),
                                centro_costo_codigo:this.getField('centro_costo_codigo').getValue(),
                                nit_buscar:this.getField('nit_buscar').getValue(),
                                sede:this.getField('sede').getValue()
                            }
                        }
                    }
                }else{
                    if (this.getField('ch_niif').getValue() === 'true') {
                        datos = {  
                             datos: { 
                                 fecha_desde:this.getField('fecha_desde').getValue(),
                                 fecha_hasta:this.getField('fecha_hasta').getValue(),
                                 niif:'niif',
                                 nit_buscar:this.getField('nit_buscar').getValue(),
                                 sede:this.getField('sede').getValue()
                             }
                         }
                    }else{
                        datos = {  
                             datos: { 
                                 fecha_desde:this.getField('fecha_desde').getValue(),
                                 fecha_hasta:this.getField('fecha_hasta').getValue(),
                                 nit_buscar:this.getField('nit_buscar').getValue(),
                                 sede:this.getField('sede').getValue()
                             }
                         }
                    }
                }
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: 'tmpcontabilidadcuentasxtercerodetallado', operacion_tipo: 'crear' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'POST',
                body: datos,
                //success: this.successTraerDatoscuentaxtercerodetallado,
                success: this.successDatoscuentaxtercerodetallado,
                error: this.error_,
                general: this.generalFormatPmv
            });    
        }else{
            this.alertGeneral.toggle(true, 'Otras consultas no configurado para procesar.', 'error');
            this.getField('confirmModalCustom').toggle(false);  
            this.getField('procesar').setDisabled(false);
        }  
    }

    successTraerDatoscuentaxtercero(data){
        if(data.estado_p === 200){ 
            let datos={ datos: {}};
            this.getField("tabla_cuentas_terceros").setTipoServicioOperacion("cont-consultadecuentas-1_7");
            this.getField("tabla_cuentas_terceros").sendRequest(this.successDatoscuentaxtercero, datos);
            /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '1_7', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successDatoscuentaxtercero,
                    error: this.error_,
                    general: this.generalFormatPmv
            });*/
        }else
        {
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.getField('confirmModalCustom').toggle(false);
            this.limpiarTemporal7();
        };
    }

    successDatoscuentaxtercero(data){
        if (data.estado_p === 200){
            this.operacion_normal='cuentas_por_tercero';
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreoTerceros();
            }else{
                this.nombre_pdf='cuentasportercero';
                this.nombre_excel='cuentasportercero';
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTabla3();
                }else if(this.operacion_actual==='pantalla'){
                    this.generarModalPorTercero();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelTabla3();
                } 
            }
        }
        else if (data.estado_p === 404){
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporal7();
        }else{
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores)
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal7();
        }
    }

    generarModalPorTercero(){
        let datos = { datos: {
                fecha_desde:this.getField('fecha_desde').getValue(),
                fecha_hasta:this.getField('fecha_hasta').getValue(),
                nit_buscar:this.getField('nit_buscar').getValue(),
                sede:this.getField('sede').getValue(),
                operacion:this.operacion_actual,
                radio_correo:this.getField('radio_correo').getValue(),
                email:this.getField('email').getValue()
        }};
        if(this.getField('centro_costo_codigo').getValue()!=='')
            datos.datos.centro_costo_codigo=this.getField('centro_costo_codigo').getValue()
        if(this.operacion_normal==='cuentas_por_tercero_detallado'){
            this.getField("cuentas_tercero_detallado").setTipoServicioOperacion("cont-consultadecuentas-generaroperacioncuentasxtercerodetallado");
            this.getField("cuentas_tercero_detallado").sendRequest(this.successGenerarModalPorTercero, datos);
        }
        else if(this.operacion_normal==='cuentas_por_tercero'){
            this.getField("tabla_cuentas_terceros").setTipoServicioOperacion("cont-consultadecuentas-generaroperacioncuentasxtercero");
            this.getField("tabla_cuentas_terceros").sendRequest(this.successGenerarModalPorTercero, datos);
        }    
        /*this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: operacion1, operacion_tipo: 'consulta' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successGenerarModalPorTercero,
                error: this.error_,
                general: this.generalFormatPmv
        });*/
    }

    successGenerarModalPorTercero(data){
        this.getField('confirmModalCustom').toggle(false);
        this.getField('procesar').setDisabled(false);
        if(data.estado_p === 200){ 
            //llenar lista
            let datosFicha = [];
            let data2 = 
                    {
                        "nombre": data.data.lista.nombre_empresa,
                        "operacion": data.data.lista.operacion,
                        "fecha_actual": data.data.lista.fecha_actual,
                        "desde-hasta": data.data.lista.fechasdesdehasta,
                        "nit": data.data.lista.nit,
                        "sede": data.data.lista.sede,
                        "usuario": data.data.lista.usuario,
                        "codigo_centro_costo": data.data.lista.centro_costo_codigo===null?'Todos':data.data.lista.centro_costo_codigo,
                    };
            datosFicha.push(data2);
            this.getField('lista_cuentas_tercero').setItemsFichas(datosFicha);
            if(this.operacion_normal==='cuentas_por_tercero_detallado'){
                this.getField('cuentas_tercero_detallado').toggle(true);
                this.getField('total_debitos').setVisible(true);
                this.getField('total_creditos').setVisible(true);
                this.getField('total_debitos').setValue(data.data.lista.total_debitos.toFixed(2));
                this.getField('total_creditos').setValue(data.data.lista.total_creditos.toFixed(2));

                this.getField('total_valor').setVisible(false);
                this.getField('tabla_cuentas_terceros').toggle(false);
                let configCell = new Map();
                configCell.set('debitos',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormatterdebito,field: 'débitos'});
                configCell.set('creditos',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattercredito,field: 'créditos'});
                this.gridOptionsComponentes['rowData'] = data.data.datos;
                this.getField('cuentas_tercero_detallado').initData(this.gridOptionsComponentes, configCell);
                this.getField('modal_cuentas_terceros').handleClickOpen();
            }

            else if(this.operacion_normal==='cuentas_por_tercero'){
                this.getField('cuentas_tercero_detallado').toggle(false);
                this.getField('total_debitos').setVisible(false);
                this.getField('total_creditos').setVisible(false);

                this.getField('total_valor').setValue(data.data.lista.total_valor.toFixed(2));
                this.getField('total_valor').setVisible(true);
                this.getField('tabla_cuentas_terceros').toggle(true);
                let configCell = new Map();
                configCell.set('valor',{cellStyle: {textAlign:"right"},valueFormatter:this.currencyFormattervalor});
                this.gridOptionsComponentes['rowData'] = data.data.datos;
                this.getField('tabla_cuentas_terceros').initData(this.gridOptionsComponentes, configCell);
                this.getField('modal_cuentas_terceros').handleClickOpen();

            }
            this.limpiarCampos();
        }else if (data.estado_p === 404){
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.elegirTablaEliminar();
        }else{
            this.limpiarCampos();
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.elegirTablaEliminar();
        }
    }

    limpiarTemporal7(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_8', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp7,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp7(data){
        this.getField('procesar').setDisabled(false);
        //tmp_contatercero_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    
    successTraerDatoscuentaxtercerodetallado(data){
        if(data.estado_p === 200){ 
            let datos={ datos: {}};
            this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '1_8', operacion_tipo: 'consulta' };
            this.service.send(
            {
                    endpoint: this.constant.formConfiguration(),
                    method:'GET',
                    body: datos,
                    success: this.successDatoscuentaxtercerodetallado,
                    error: this.error_,
                    general: this.generalFormatPmv
            });
        }else
        {
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            let respuesta=Object.values(data.data.errores);
            this.getField('confirmModalCustom').toggle(false);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal8();
        };
    }

    successDatoscuentaxtercerodetallado(data){
        this.getField('procesar').setDisabled(false);
        if(data.estado_p === 200){ 
            this.operacion_normal='cuentas_por_tercero_detallado';
            if(this.getField('radio_correo').getValue()==='S'){
                this.enviarCorreoTerceros();
            }else{
                this.nombre_pdf='cuentasportercerodetallado';
                this.nombre_excel='cuentasportercerodetallado';
                if(this.operacion_actual==='pdf'){
                    this.generarPdfTabla3();
                }else if(this.operacion_actual==='pantalla'){
                    this.generarModalPorTercero();
                }else if(this.operacion_actual==='excel'){
                    this.generarExcelTabla3();
                } 
            }
        }else if (data.estado_p === 404){
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            this.limpiarTemporal8();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
        }else{
            this.limpiarCampos();
            this.getField('procesar').setDisabled(false);
            this.getField('confirmModalCustom').toggle(false);
            let respuesta=Object.values(data.data.errores);
            this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            this.limpiarTemporal8();
        }
    }

    limpiarTemporal8(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_9', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp8,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp8(data){
        //tmp_contatercerod_uuid
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    successDatosmovimientoxtercero(data){
        if (data.estado_p === 200 )
        {
          if(this.operacion_actual!==''){
              this.operacion_normal='movimiento_detallado_por_tercero';
              if(this.getField('radio_correo').getValue()==='S'){
                  this.enviarCorreoTerceros();
              }else{
                  this.nombre_pdf='movimientoportercerodetallado';
                  this.nombre_excel='movimientoportercerodetallado';
                  if(this.operacion_actual==='pdf'){
                      this.generarPdfTabla3();
                  }else if(this.operacion_actual==='pantalla'){
                    this.generarModalMovimientosDetalladosXTerceros();
                  }else if(this.operacion_actual==='excel'){
                    this.generarExcelTabla3();
                  } 
              }
              
            }else{
              this.alertGeneral.toggle(true, 'Error al validar la operación.', 'error');
              this.getField('confirmModalCustom').toggle(false);
            }
        }
        else 
        {
            this.limpiarCampos();
            this.getField('confirmModalCustom').toggle(false);
            this.getField('procesar').setDisabled(false);
            if(data.estado_p===404) {
                this.alertGeneral.toggle(true, 'La consulta no arrojo datos.', 'error');
            }
            else {
                let respuesta=Object.values(data.data.errores);
                this.alertGeneral.toggle(true, 'Error ' + respuesta, 'error');
            }
            this.limpiarTemporal9();
        };
        
    }


    limpiarTemporal9(){
        let datos={ datos: {}};
        this.generalFormatPmv = { tipo_servicio: 'cont-consultadecuentas', operacion: '7_0', operacion_tipo: 'eliminar' };
        this.service.send(
        {
                endpoint: this.constant.formConfiguration(),
                method:'DELETE',
                body: datos,
                success: this.successDeleteTmp9,
                error: this.error_,
                general: this.generalFormatPmv
        });
    }

    successDeleteTmp9(data){
        //tmp_contabilidad
        if (data.estado_p === 200){
            this.alertGeneral.toggle(true, 'Se limpió la tabla temporal con éxito.', 'success');
        }else{
            this.limpiarCampos();
            this.alertGeneral.toggle(true, 'Error al limpiar la tabla temporal.', 'error');    
        }
    }

    seleccionTotalDebitos(props){
        let debitos = props.data.debitos;

        let div = document.createElement('div');
        div.setAttribute("class", "divStyle6");
        div.textContent = ""+this.currencyFormatterGeneral(debitos);//debitos;
        return this.createElementJaivana(div);
    
    }

    seleccionTotalCreditos(props){

        let creditos = props.data.creditos;

        let div = document.createElement('div');
        div.setAttribute("class","divStyle6");
        div.textContent = ""+this.currencyFormatterGeneral(creditos);//creditos;
        return this.createElementJaivana(div);
    }

    seleccionTotalSaldo(props){
        let saldo = props.data.saldo;

        let div = document.createElement('div');
        div.setAttribute("class","divStyle6");
        div.textContent = ""+this.currencyFormatterGeneral(saldo);//saldo;
        return this.createElementJaivana(div);
    
    }

    formatNumberSaldo(number)
    {
            return Number(number).toString().split('.')[0]
             .toString()
             .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    }

    currencyFormatterGeneral(number)
    {
            let decimal = (number + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(number) + "," + decimal);
             } else {
             return this.formatNumberSaldo(number); }
    }

    currencyFormatterdebito(data)
    {
            let decimal = (data.data.debitos + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.debitos) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.debitos); }
    }
    currencyFormattercontabilidad(data)
    {
            let decimal = (data.data.contabilidad + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.contabilidad) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.contabilidad); }
    }

    currencyFormattervalor(data)
    {
            let decimal = (data.data.valor + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.valor) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.valor); }
    }

    currencyFormattercredito(data)
    {
            let decimal = (data.data.creditos + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.creditos) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.creditos); }
    }

    currencyFormattersaldo(data)
    {
            let decimal = (data.data.saldo + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.saldo) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.saldo); }
    }

    currencyFormatterbase(data)
    {
            let decimal = (data.data.base + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.base) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.base); }
    }

    currencyFormattertarifa(data)
    {
            let decimal = (data.data.tarifa + "").split(".")[1];//para manejar los decimales
             if ((decimal !== 0) && (decimal !== undefined)) {
             return (this.formatNumberSaldo(data.data.tarifa) + "," + decimal);
             } else {
             return this.formatNumberSaldo(data.data.tarifa); }
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }


    mostrarMensajeEmailEnviado(){
        this.getField('confirmModalCustom').setTitleAndContent('Mensaje', 'Email enviado exitosamente...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    mostrarMensajeEmailFallo(){
        this.getField('confirmModalCustom').setTitleAndContent('Advertencia', 'Error al enviar el email...');
        this.getField('confirmModalCustom').setClickDialog(() => { this.getField('confirmModalCustom').toggle(false); });
        this.getField("confirmModalCustom").setVisibleCancel(true); // Para no mostrar el botón de cancelar en los ConfirmDialog
        this.getField("confirmModalCustom").setButtonConfirm("OK"); // Para renombrar el botón de "confirmar" en los ConfirmDialog – popup
        this.getField('confirmModalCustom').toggle(true);
    }

    

}
FormJaivana.addController("cont-consultadecuentas", CustomConsultaCuentas);
export default CustomConsultaCuentas;