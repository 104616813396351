import FormJaivana from "dashboard_jaivana_v1";
/**
 * @description: CustomTrasladosRapidos
 * @author: Patricia Lopez Sanchez
 * @version: jdesk_1.01.0002
 **/
class CustomTrasladosRapidos extends FormJaivana.form {

    constructor(props) {
        super(props);
        this.initForm                       = this.initForm.bind(this);
        this.gridOptionsComponentes         = Object.assign({}, this.gridOptions);
        //this.impresoras                     = this.impresoras.bind(this);
        //this.successImpresora               = this.successImpresora.bind(this);
        this.habilitarCampos                = this.habilitarCampos.bind(this);
        this.deshabilitarCampos             = this.deshabilitarCampos.bind(this);
        this.validarCodigo                  = this.validarCodigo.bind(this);
        this.successTraerDatos              = this.successTraerDatos.bind(this);
        this.consecutivo                    = this.consecutivo.bind(this);
        this.successconsecutivo             = this.successconsecutivo.bind(this);
        this.grabar                         = this.grabar.bind(this);
        this.successgrabar                  = this.successgrabar.bind(this);
        this.limpiar                        = this.limpiar.bind(this);
        this.limpiar1                       = this.limpiar1.bind(this);
    }

    initForm() {
        console.log("Formulario CustomGrabacionTraslados,  @version: jdesk_1.01.0002, @author: Patricia Lopez Sanchez");
        this.codigo_sucursal = JSON.parse(localStorage.getItem('sucursal_ingreso')).codigo_sucursal;
        this.codigo_usuario = JSON.parse(localStorage.getItem('res')).codigo;
        //this.impresoras();
        this.getField('aceptar').setClick(this.habilitarCampos);
        this.getField('cancelar').setClick(this.deshabilitarCampos);
        this.getField("codigo_articulo").setOnChange(this.limpiar1);
        this.getField("nombre_articulo").setOnChange(this.validarCodigo);
        this.getField("grabar").setClick(this.grabar);
    }

    /*impresoras(){//// se inhabilita por el momento ya que no se va a enviar a imprimir solo se va a grabar el traslado
        let datos = { datos: {
            codigo_sucursal:this.codigo_sucursal
        }};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosrapidos', operacion: 'impresora', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successImpresora,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successImpresora(data){
        if (data.estado_p === 200)
        {
            let opciones = [{'text':'Ninguno','value':'','campos_cambian':{}}];
            data.data.datos.forEach(item => {
                let dataOp = {}
                dataOp['value'] = item.impresorra;
                dataOp['text'] = item.nombre_impresora;
                dataOp['campos_cambian'] = {};
                opciones.push(dataOp);
            });
            this.getField('impresoras').setOptions(opciones);
        }else 
        {
          let respuesta=Object.values(data.data.errores);
          let keys=Object.keys(data.data.errores);
          this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }*/

    habilitarCampos(){
        if(this.getField('detalle').valid()){
            this.getField('consecutivo').setVisible(true);
            this.getField('codigo_vendedor').setVisible(true);
            this.getField('nombre_vendedor').setVisible(true);
            this.getField('codigo_vendedor').setValue(".");
            this.getField('grabar').setVisible(true);
            this.getField('codigo_articulo').setVisible(true);
            this.getField('nombre_articulo').setVisible(true);
            this.getField('precio').setVisible(true);
            this.getField('saldo_supermercado').setVisible(true);
            this.getField('saldo_bodega').setVisible(true);
            this.getField('cantidad').setVisible(true);
            this.getField('item').setVisible(true);
            this.getField('capacidad').setVisible(true);
            this.getField('grabar').setVisible(true);
            this.getField('cancelar').setVisible(true);

            this.getField('precio').setDisabled(true);
            this.getField('saldo_supermercado').setDisabled(true);
            this.getField('saldo_bodega').setDisabled(true);
            this.getField('item').setDisabled(true);
            this.getField('capacidad').setDisabled(true);
            
            //this.getField('impresoras').setDisabled(true);
            this.getField('detalle').setDisabled(true);
            this.getField('aceptar').setDisabled(true);
            this.consecutivo();
        }
    }

    deshabilitarCampos(){
        this.getField('codigo_vendedor').setVisible(false);
        this.getField('nombre_vendedor').setVisible(false);
        this.getField('codigo_vendedor').setValue(".");
        this.getField('consecutivo').setVisible(false);
        this.getField('codigo_articulo').setVisible(false);
        this.getField('nombre_articulo').setVisible(false);
        this.getField('precio').setVisible(false);
        this.getField('saldo_supermercado').setVisible(false);
        this.getField('saldo_bodega').setVisible(false);
        this.getField('cantidad').setVisible(false);
        this.getField('item').setVisible(false);
        this.getField('capacidad').setVisible(false);
        this.getField('grabar').setVisible(false);
        this.getField('cancelar').setVisible(false);
        
        //this.getField('impresoras').setDisabled(false);
        this.getField('detalle').setDisabled(false);
        this.getField('aceptar').setDisabled(false);

        //this.getField('impresoras').setValue("");
        this.getField('detalle').setValue("");
        this.getField('nombre_vendedor').setValue("");
        this.getField('consecutivo').setValue("0");
        this.getField('codigo_articulo').setValue("");
        this.getField('nombre_articulo').setValue("");
        this.getField('precio').setValue("0");
        this.getField('saldo_supermercado').setValue("0");
        this.getField('saldo_bodega').setValue("0");
        this.getField('cantidad').setValue("0");
        this.getField('item').setValue("0");
        this.getField('capacidad').setValue("0");

        this.getField('precio').setError(false,"");
        this.getField('saldo_supermercado').setError(false,"");
        this.getField('saldo_bodega').setError(false,"");
        this.getField('cantidad').setError(false,"");
        this.getField('item').setError(false,"");
        this.getField('capacidad').setError(false,"");
        this.getField('nombre_vendedor').setError(false,"");
        this.getField('codigo_articulo').setError(false,"");
        this.getField('codigo_vendedor').setError(false,"");
        this.getField('codigo_vendedor').setValue(".");

    }

    consecutivo(){
        let datos = { datos: {}};
        
        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosrapidos', operacion: 'consecutivo', operacion_tipo: 'consulta' };
        this.service.send(
        {
            endpoint: this.constant.formConfiguration(),
            method:'GET',
            body: datos,
            success: this.successconsecutivo,
            error: this.error_,
            general: this.generalFormatPmv
        });
    }

    successconsecutivo(data){
        if (data.estado_p === 200)
        {
            this.getField('consecutivo').setValue(data.data[0].xnumero);
        }else 
        {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    validarCodigo(){
        if(this.getField('nombre_articulo').getValue() !== ""){
            let datos = { datos: {
                codigo_articulo: this.getField('codigo_articulo').getValue(),
                codigo_sucursal:this.codigo_sucursal
            }};
            
            this.generalFormatPmv = { tipo_servicio: 'inve-trasladosrapidos', operacion: 'traerarticulo', operacion_tipo: 'consulta' };
            this.service.send(
            {
                endpoint: this.constant.formConfiguration(),
                method:'GET',
                body: datos,
                success: this.successTraerDatos,
                error: this.error_,
                general: this.generalFormatPmv,
                showMessage: false
            });
        }
    }

    successTraerDatos(data){
        if (data.estado_p === 200)
        {
            this.vivac = data.data.data[0].vivac;
            this.costo = data.data.data[0].costo;
            this.costo_promedio_linea = data.data.costo_promedio_linea;
            this.bodega_super = data.data.bodega_super;
            this.bodega_bodega = data.data.bodega_bodega;
            this.getField('codigo_articulo').setValue(data.data.data[0].codigo);
            this.getField('precio').setValue(data.data.data[0].precio1);
            this.getField('saldo_supermercado').setValue(data.data.data[0].saldo_super);
            this.getField('saldo_bodega').setValue(data.data.data[0].saldo_bodega);
            this.getField('capacidad').setValue(data.data.data[0].capacidad);
        }else 
        {
            let respuesta=Object.values(data.data.errores);
            let keys=Object.keys(data.data.errores);
            this.alertGeneral.toggle(true, 'Error (mensaje)' +keys + ' - ' + respuesta, 'error');
        }
    }

    grabar(){
        if(this.getField('codigo_vendedor').valid() && this.getField('codigo_articulo').valid() && this.getField('nombre_articulo').valid()
        && this.getField('saldo_supermercado').valid() && this.getField('precio').valid()){
            if(this.getField('cantidad').valid() && this.getField('cantidad').getValue() > 0){
                if(this.costo !== "" && this.costo >= 0.0){
                    if(this.getField('saldo_bodega').getValue() >= this.getField('cantidad').getValue()){
                        let datos = { datos: {
                            codigo_articulo: this.getField('codigo_articulo').getValue(),
                            cantidad:this.getField('cantidad').getValue(),
                            bodega_super:this.bodega_super,
                            bodega_bodega:this.bodega_bodega,
                            items:this.getField('item').getValue(),
                            costo_promedio_linea:this.costo_promedio_linea,
                            costo:this.costo,
                            vivac:this.vivac,
                            codigo_vendedor:this.getField('codigo_vendedor').getValue(),
                            consecutivo:this.getField('consecutivo').getValue(),
                            fecha:'now()',
                            detalle:this.getField('detalle').getValue(),
                            usuario:this.codigo_usuario,
                            codigo_sucursal:this.codigo_sucursal

                        }};
                        
                        this.generalFormatPmv = { tipo_servicio: 'inve-trasladosrapidos', operacion: 'grabaritem', operacion_tipo: 'consulta' };
                        this.service.send(
                        {
                            endpoint: this.constant.formConfiguration(),
                            method:'GET',
                            body: datos,
                            success: this.successgrabar,
                            error: this.error_,
                            general: this.generalFormatPmv,
                            showMessage: false
                        });
                    }else{
                        this.getField('saldo_bodega').setError(true,"El valor minimo permitido es: 1");
                        this.alertGeneral.toggle(true, 'El traslado del artículo '+this.getField('codigo_articulo').getValue()+ ' no puede ser asentado porque generaría saldos negativos en el inventario.', 'error');
                    }
                }else{
                    this.alertGeneral.toggle(true, 'Valor de costo inválido para este artículo.', 'error');
                }
            }else{
                this.alertGeneral.toggle(true, 'Valor de cantidad inválido.', 'error');
            }
        }else{
            this.alertGeneral.toggle(true, 'Debe realizar la busqueda de los campos de forma correcta.', 'error');
        }
    }

    successgrabar(data){
        if (data.estado_p === 200)
        {
            this.getField('item').setValue(this.getField('item').getValue()+1);
            this.getField('consecutivo').setValue(data.data.consecutivo);
            this.limpiar();
        }else 
        {            
            let respuesta=data.data.errores;
            this.alertGeneral.toggle(true,"No se pudo grabar el item. "+ respuesta, 'error');
        }
    }

    limpiar(){       
        this.getField('codigo_articulo').setValue("");
        this.getField('nombre_articulo').setValue("");
        this.getField('precio').setValue("0");
        this.getField('saldo_supermercado').setValue("0");
        this.getField('saldo_bodega').setValue("0");
        this.getField('cantidad').setValue("0");
        this.getField('capacidad').setValue("0");

        this.getField('precio').setError(false,"");
        this.getField('saldo_supermercado').setError(false,"");
        this.getField('saldo_bodega').setError(false,"");
        this.getField('cantidad').setError(false,"");
        this.getField('item').setError(false,"");
        this.getField('capacidad').setError(false,"");
    }

    limpiar1(){  
        if(this.getField('nombre_articulo').getValue() === "") {
            this.getField('precio').setValue("0");
            this.getField('saldo_supermercado').setValue("0");
            this.getField('saldo_bodega').setValue("0");
            this.getField('cantidad').setValue("0");
            this.getField('capacidad').setValue("0");

            this.getField('precio').setError(false,"");
            this.getField('saldo_supermercado').setError(false,"");
            this.getField('saldo_bodega').setError(false,"");
            this.getField('cantidad').setError(false,"");
            this.getField('item').setError(false,"");
            this.getField('capacidad').setError(false,"");
        }   
    }
}
FormJaivana.addController("inve-trasladosrapidos", CustomTrasladosRapidos);
export default CustomTrasladosRapidos;